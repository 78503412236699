<template>
  <div class="industries-modal-edit-professions is-column">
    <div class="is-align-items-center is-full-width">
      <bc-autocomplete
        v-model="input"
        :keep-first="true"
        :options="matches"
        class="industries-modal-edit-professions__autocomplete is-full-width"
        icon="tool"
        :label="$t('generics.professions')"
        name="professions"
        placeholder="Créer un nouveau métier"
        @keydown.native.enter="addDomain">
        <template #option="{ option }">
          <bc-dropdown-item
            :text="option.name"
            class="industries-modal-edit-professions__option font-size-s is-expanded">
          </bc-dropdown-item>
        </template>
      </bc-autocomplete>
    </div>
    <div class="industries-modal-edit-professions__wrapper is-flex-wrap">
      <bc-tag
        v-for="(profession, index) in professions"
        :key="index"
        class="industries-modal-edit-professions__tag"
        deletable
        @click="removeDomain(profession)">
        {{ profession.name }}
      </bc-tag>
    </div>
  </div>
</template>

<script>
  import BcTag from '@/ui-kit/components/BcTag/BcTag';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';

  export default {
    name: 'industries-modal-edit-professions',
    components: { BcDropdownItem, BcAutocomplete, BcTag },
    props: {
      professions: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        input: '',
      };
    },
    computed: {
      matches() {
        if (!this.input) {
          return this.professions;
        }

        return this.professions.filter(profession => profession.name.toLowerCase().indexOf(this.input.toLowerCase()) === 0);
      },
    },
    methods: {
      addDomain() {
        this.$emit('update-value', {
          professions: [{ name: this.input }, ...this.professions],
        });

        this.input = '';
      },
      removeDomain(profession) {
        this.$emit('update-value', {
          professions: this.professions.filter(l => l.name.toLowerCase() !== profession.name.toLowerCase()),
        });

        this.$emit('profession-deleted', profession)
      },
    },
  };
</script>

<style lang="scss" scoped>
  .industries-modal-edit-professions {
    &__autocomplete {
      margin-bottom: 10px;
    }

    &__option {
      line-height: 15px;
      padding: 10px 20px;
    }

    &__wrapper {
      margin: -5px;
    }

    &__tag {
      margin: 5px;
    }
  }
</style>
