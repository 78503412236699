export const isLinkedinCompanyUrl = /^(http[s]?:\/\/)?([a-z]{2,3}\.)?linkedin\.com\/company\/.+$/;
export const isLinkedinSchoolUrl = /^(http[s]?:\/\/)?([a-z]{2,3}\.)?linkedin\.com\/school\/.+$/;
export const isLinkedinSlug = /^[a-zA-Z0-9À-ž.+\-_%&]+$/i;
export const isWttjCompanyUrl = /^(http[s]?:\/\/)?([a-z]{2,3}\.)?welcometothejungle\.com\/[a-z]{2}\/companies\/.+$/;
export const isWttjCompanySlug = /^[a-zA-Z0-9À-ž.+\-_%&]+$/i;
export const isTwitterProfileUrl = /^(http[s]?:\/\/)?([a-z]{2,3}\.)?twitter\.com\/.+$/;
export const isTwitterProfileSlug = /^[a-zA-Z0-9À-ž.+\-_%]+$/i;
export const isLinkedinPersonUrl = /^(http[s]?:\/\/)?([a-z]{2,3}\.)?linkedin\.com\/in\/.+$/;
export const isSocieteDotComCompanyUrl = /^(http[s]?:\/\/)?([a-z]{2,3}\.)?societe\.com\/societe\/.+$/;
export const isSocieteDotComCompanySlug = /^[a-zA-Z0-9À-ž.+\-_%&]+$/i;


export default {
  isLinkedinCompanyUrl,
  isLinkedinSchoolUrl,
  isWttjCompanyUrl,
  isTwitterProfileUrl,
  isLinkedinSlug,
  isWttjCompanySlug,
  isTwitterProfileSlug,
  isLinkedinPersonUrl,
  isSocieteDotComCompanySlug,
  isSocieteDotComCompanyUrl,
};
