<template>
  <div
    class="panel-opportunity-notes flex flex-col"
    @click.stop>
    <p class="m-[20px] mb-[5px] flex justify-center rounded-[5px] border border-blue-200 bg-white py-[10px] text-blue-800 shadow-[0_2px_4px_0_rgba(29,57,94,0.10)]">
      Notes
    </p>
    <panel-view-timeline
      :profile="profile"
      user-type="hunt"
      @note-saved="$emit('note-saved')">
    </panel-view-timeline>
  </div>
</template>

<script>
  import PanelViewTimeline from '@/components/Panel/PanelView/PanelViewTimeline';

  export default {
    name: 'panel-opportunity-notes',
    components: { PanelViewTimeline },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-opportunity-notes {
    width: 100%;
  }
</style>
