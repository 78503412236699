export const buildJobStatusTag = (query) => {
  const status = {
    draft: 'Brouillon',
    online: 'Publié',
    offline: 'Non Publié'
  };

  return {
    name: status[query],
    query,
    type: 'status'
  }
};

export default {
  buildJobStatusTag
}
