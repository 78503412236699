<template>
  <modal-feature
    feature="customFields"
    :title="$t('features.create-custom-fields')"
    @close-modal="$emit('close-modal')"
  >
    <template #content>
      <p>{{ $t('features.add-custom-fields') }}</p>
    </template>
    <template #logo>
      <img src="@/assets/logo/feature-custom-fields.png" alt="custom fields feature logo" />
    </template>
  </modal-feature>
</template>

<script>
import ModalFeature from './ModalFeature.vue';

export default {
  name: 'modal-feature-custom-fields',
  components: {
    ModalFeature,
  },
}
</script>
