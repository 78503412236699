<template>
  <div class="process-column">
    <div
      :class="[
        `process-column__header--${step.categoryKey}`,
        { 'process-column__header--first': isFirst },
        { 'process-column__header--last': isLast },
      ]"
      :style="{ ...(step.style && step.style.header) }"
      class="process-column__header"
    >
      <div class="process-column__title">
        <p>
          {{ step.name }}
        </p>
        <router-link v-if="step.iconLink" :to="step.iconLink">
          <i alt="" class="icon-external-link"/>
        </router-link>
      </div>
      <p class="process-column__title">
        {{ activeProcessCount }}
      </p>
    </div>
    <div
      ref="process-column__body"
      :class="[
        { [`process-column__body--${step.categoryKey}--hover`]: isHover && isDragging && hoverId === step.stepKey && !readOnly },
        `process-column__body--${step.categoryKey}`
      ]"
      class="process-column__body"
    >
      <slot></slot>
      <draggable
        :id="step.stepKey"
        v-model="currentProcesses"
        :class="{ 'process-column__step--disabled': readOnly || step.stepKey.includes('_Candidature') }"
        :disabled="readOnly || step.stepKey.includes('_Candidature')"
        :ghost-class="'process-card--ghost'"
        animation="200"
        class="process-column__step"
        group="process"
        handle=".is-draggable"
        @end="$emit('dragging', false)"
        @start="handleDraggingStart"
        @dragenter.native="setIsHover"
        @dragleave.native="unsetIsHover"
        @dragover.native="dragover"
        @mouseenter.native="setIsHover"
        @mouseleave.native="unsetIsHover"
      >
        <router-link
          v-for="process in currentProcesses"
          :key="process._id"
          :class="{ 'process-column__link--forbidden': !process.processedAt }"
          :event="isHoverCandidateBtns || isOptionsHover ? 'hover' : 'click'"
          :to="panelRoute(process)"
          class="process-column__link"
          @dragstart.native="handleDragStart($event, process)"
        >
          <component
            :is="cardType"
            :is-draggable="!readOnly && handleDraggable(process) && !isFirstStep(process)"
            :process="process"
            :step="step"
            class="card process-column__card list-group-item"
            @on-options-hover="onOptionsHover"
            @on-action="onAction($event, process)"
            @process-update="$emit('process-update', $event)"
            @profile-updated="$emit('profile-updated', $event)"
            @set-modal-type="$emit('set-modal-type', $event)"
          >
            <div
              v-if="isFirstStep(process)"
              class="mt-2 items-center gap-[11px]"
              @mouseenter="isHoverCandidateBtns = true"
              @mouseleave="isHoverCandidateBtns = false"
            >
              <bc-button iconLeft="check" @click="acceptProcess(process)">
                {{ $t('generics.accept') }}
              </bc-button>
              <button class="text-sm font-bold text-blue-500" @click="rejectProcess(process)">
                {{ $t('generics.reject') }}
              </button>
            </div>
          </component>
        </router-link>
        <div
          v-if="oldProcesses.length > 0"
          class="process-column__step process-column__step--old"
          @dragenter="setIsHover"
          @dragleave="unsetIsHover"
          @dragover="dragover"
          @mouseenter="setIsHover"
          @mouseleave="unsetIsHover"
        >
          <draggable
            v-model="oldProcesses"
            :class="{ 'process-column__step--disabled': readOnly }"
            :disabled="readOnly"
            animation="200"
            class="process-column__step"
            ghost-class="process-card--ghost"
            group="process"
            handle=".is-draggable"
            @end="$emit('dragging', false)"
            @start="$emit('dragging', true)"
            @dragenter.native="setIsHover"
            @dragleave.native="unsetIsHover"
            @dragover.native="dragover"
            @mouseenter.native="setIsHover"
            @mouseleave.native="unsetIsHover"
          >
            <router-link
              v-for="oldProcess in oldProcesses"
              :key="oldProcess._id"
              :to="panelRoute(oldProcess)"
              class="process-column__link"
            >
              <component
                :is="cardType"
                :key="oldProcess._id"
                :process="oldProcess"
                :step="step"
                class="card process-column__card list-group-item"
                @process-update="$emit('process-update', $event)"
                @profile-updated="$emit('profile-updated', $event)"
                @set-modal-type="$emit('set-modal-type', $event)"
              >
              </component>
            </router-link>
          </draggable>
        </div>
      </draggable>
      <div
        v-if="showAmount && isCandidateFitler"
        class="process-column__amount"
      >
        <div :class="`process-column__amount--${step.categoryKey}`">
          <span class="process-column__amount-number body-m--medium"
          >&nbsp;{{ formattedAmount(totalAmount) }} €</span
          >
        </div>
      </div>
    </div>

    <modal
      v-if="displayModal !== ''"
      @close-modal="closeModal">
      <modal-process-abort-reason
        v-if="displayModal === 'abortProcessReason'"
        :isLoading="isActionLoading"
        @confirm-action="processActions.abortProcess.action($event)"
        @cancel-action="closeModal"
      ></modal-process-abort-reason>
      <modal-process-commission-custom
        v-else-if="displayModal === 'commissionCustom'"
        @confirm-action="setCommissionCustom($event)"
        @cancel-action="closeModal"
      />
      <modal-process-date
        v-else-if="displayModal === 'next-interview' || displayModal === 'next-presentation' || displayModal === 'integration-date'"
        :type="displayModal === 'next-interview' ? 'interview' : displayModal === 'integration-date' ? 'integration' : 'presentation'"
        @cancel-action="closeModal"
        @confirm-action="setProcessScheduledDate">
      </modal-process-date>
      <modal-process-action-confirmation
        v-else
        :confirmText="processActions[displayModal].confirmText"
        :isLoading="isActionLoading"
        :text="processActions[displayModal].text"
        :title="processActions[displayModal].title"
        :type="processActions[displayModal].type"
        @confirm-action="processActions[displayModal].action({ confirm: true })"
        @cancel-action="closeModal">
      </modal-process-action-confirmation>
    </modal>
  </div>
</template>

<script lang="ts">
  import { mapActions } from 'vuex';
  import { mapStores } from 'pinia';
  import { useProcessesStore } from '@/store/pinia/processes';
  import { updateProcessCustom } from '@/api/processes-custom';
  import debounce from 'debounce';
  import draggable from 'vuedraggable';
  import ProcessMixin from '@/mixins/process';

  import BcButton from '@/ui-kit/components/BcButton';

  import ModalProcessFormDeal from '@/components/Modal/ModalProcess/ModalProcessFormDeal';
  // Process cards
  import ProcessCardCoder from '@/components/Process/ProcessCard/ProcessCardCoder';
  import ProcessCardCompany from '@/components/Process/ProcessCard/ProcessCardCompany';
  import ProcessCardJob from '@/components/Process/ProcessCard/ProcessCardJob';
  import ProcessCardHunt from '@/components/Process/ProcessCard/ProcessCardHunt';
  import ProcessesCard from '@/components/Processes/ProcessesCard';

  import { modalTypes } from '@/macros/processes/process';
  // import { getCurrentProcesses, getOldProcesses, sortByFailedAt } from '@/helpers/processes';
  import { getOldProcesses } from '@/helpers/processes';
  import ProcessesCardCustom from '../Processes/ProcessesCardCustom';
  import ProcessesCardCustomJob from '../Processes/ProcessesCardCustomJob';

  import Modal from '@/components/Modal/Modal';
  import ModalProcessActionConfirmation
    from '@/components/Modal/ModalProcess/ModalProcessActionConfirmation';
  import ModalProcessAbortReason from '@/components/Modal/ModalProcess/ModalProcessAbortReason';
  import ModalProcessCommissionCustom
    from '@/components/Modal/ModalProcess/ModalProcessCommissionCustom';
  import ModalProcessDate from '@/components/Modal/ModalProcess/ModalProcessDate.vue';

  export default {
    name: 'process-column-custom',
    components: {
      ModalProcessDate,
      Modal,
      ModalProcessActionConfirmation,
      ModalProcessAbortReason,
      ModalProcessCommissionCustom,
      ModalProcessFormDeal,
      BcButton,
      draggable,
      'process-card-coders': ProcessCardCoder,
      'process-card-companies': ProcessCardCompany,
      'process-card-job': ProcessCardJob,
      'process-card-hunt': ProcessCardHunt,
      'processes-card': ProcessesCard,
      'processes-card-custom': ProcessesCardCustom,
      'processes-card-custom-job': ProcessesCardCustomJob,
      'process-card-suggestions-coders': ProcessCardCoder,
    },
    mixins: [ProcessMixin],
    props: {
      step: {
        type: Object,
        default: () => ({
          name: '',
          categoryKey: '',
          stepKey: '',
          processes: [],
          count: 0,
          icon: '',
          iconLink: '',
          email: false,
        }),
      },
      categoryIndex: {
        type: Number,
      },
      stepName: {
        type: String,
        default: '',
      },
      cardType: {
        type: String,
        required: true,
      },
      isDragging: {
        type: Boolean,
        default: false,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      showAmount: {
        type: Boolean,
        default: false,
      },
      totalAmount: {
        type: Number,
        default: 0,
      },
      isFirst: {
        type: Boolean,
      },
      isLast: {
        type: Boolean,
      },
    },
    data() {
      return {
        isHover: false,
        modalType: '',
        hoverId: undefined,
        generalCardTypes: ['processes-card-custom', 'processes-card-custom-job'],
        isHoverCandidateBtns: false,
        isOptionsHover: false,
        displayModal: '',
        selectedProcess: {},
        processActions: {
          deleteProcess: {
            key: 'deleteProcess',
            title: this.$t('generics.delete-process-warning'),
            text: this.$t('generics.delete-process-warning-content'),
            action: this.deleteProcess,
            type: 'delete',
          },
          abortProcess: {
            key: 'abortProcess',
            title: this.$t('process.confirm-abort'),
            text: '',
            confirmText: this.$t('process.yes-abort-process'),
            action: this.abortProcess,
            type: 'abort',
          },
          unabortProcess: {
            key: 'unabortProcess',
            title: this.$t('process.confirm-unabort'),
            text: '',
            confirmText: this.$t('process.yes-activate-process'),
            action: this.unabortProcess,
            type: 'abort',
          },
          commissionCustom: {
            key: 'commissionCustom',
            title: 'toto',
            action: this.setCommissionCustom,
            type: 'custom-modal',
          },
          setInterviewDate: {
            key: 'next-interview',
            action: this.setProcessScheduledDate,
            type: 'set-interview-date',
          },
          setPresentationDate: {
            key: 'next-presentation',
            action: this.setProcessScheduledDate,
            type: 'set-presentation-date',
          },
        },
        isActionLoading: false,
      };
    },
    computed: {
      ...mapStores(useProcessesStore),
      isSuperUser() {
        return this.$store.getters['user/isSuperUser'];
      },
      profile() {
        return this.$store.state.user.profile;
      },
      shouldSendMailOnFailure() {
        return this.$store.state.user.profile?._organization?.configuration?.process?.failureEmail;
      },
      isCandidateFitler() {
        return this.$route.query['selected-view'] === 'candidates';
      },
      configurationProcess() {
        return this.$store.getters['user/configurationProcess'];
      },
      isCandidateStep() {
        return this.categoryIndex === 0 && this.configurationProcess.categories[0]?.steps[0]?.key === this.step.stepKey;
      },
      careerIsActive() {
        return this.profile?._organization?.configuration?.career?.activated;
      },
      currentProcesses: {
        get() {
          // const currentProcesses = getCurrentProcesses(this.step.processes);
          // return sortByFailedAt(currentProcesses);
          return this.step.processes;
        },
        set(newProcesses) {
          const newProcess = newProcesses.find(process => !this.currentProcesses.includes(process));

          if (newProcess) {
            this.manageProcess(newProcess);
          }

          this.$emit('input', {
            stepName: this.stepName,
            processes: [...newProcesses, ...this.oldProcesses],
          });
        },
      },
      oldProcesses: {
        get() {
          return getOldProcesses(this.step.processes);
        },
        set(newProcesses) {
          const newProcess = newProcesses.find(process => !this.oldProcesses.includes(process));

          if (newProcess) {
            this.manageProcess(newProcess);
          }

          this.$emit('input', {
            stepName: this.stepName,
            processes: [...this.currentProcesses, ...newProcesses],
          });
        },
      },
      activeProcessCount() {
        if (this.step.categoryKey === 'failed') {
          return this.step.processes?.length || 0;
        } else {
          return this.step.processes.filter((process) => !process.abortedAt)?.length || 0;
        }
      },
    },
    methods: {
      ...mapActions({
        updateCustomJob: 'customJobs/updateCustomJob',
        setModal: 'app/setModal',
        setParams: 'app/setParams',
        setIsPanel: 'app/setIsPanel',
      }),
      onOptionsHover(value) {
        this.isOptionsHover = value;
      },
      onAction(action, process) {
        this.selectedProcess = process;
        if (action === 'abort-process') {
          this.abortProcess();
        }
        if (action === 'unabort-process') {
          this.unabortProcess();
        }
        if (action === 'delete') {
          this.deleteProcess();
        }
        if (action === 'commission-custom') {
          this.setCommissionCustom();
        }
        if (action === 'next-presentation' || action === 'next-interview' || action === 'integration-date') {
          this.setProcessScheduledDate({ action });
        }
      },
      closeModal() {
        this.displayModal = '';
      },
      onActionSuccess(res) {
        this.closeModal();
        this.$_updateCoder(res.data._coder);
        // this.$router.go(-1);
      },
      async abortProcess({ confirm, reason } = {}) {
        if (!confirm) {
          let modal = this.processActions.abortProcess.key;
          if (this.configurationProcess.failureReason && !this.isCandidateStep) {
            modal += 'Reason';
          }
          this.displayModal = modal;
          return;
        }
        try {
          this.isActionLoading = true;

          const data = await this.processesStore.onAbort({
            processId: this.selectedProcess?._id,
            reason,
          });
          this.$emit('abort-process', this.selectedProcess._id);

          if (this.isCandidateStep && this.step.email || this.shouldSendMailOnFailure) {
            this.openEmailPanel({
              coder: this.selectedProcess._coder,
              customJob: this.selectedProcess._customJob,
            });
          }

          this.onActionSuccess({ data });
          this.isActionLoading = false;
        } catch (error) {
          this.isActionLoading = false;
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      async unabortProcess({ confirm } = {}) {
        if (!confirm) {
          this.displayModal = this.processActions.unabortProcess.key;
          return;
        }
        try {
          const data = await this.processesStore.onUnabort({ processId: this.selectedProcess._id });
          if (data?._customJob?._id) {
            await this.updateCustomJob({
              id: data._customJob._id,
              nbActiveProcesses: data._customJob.nbActiveProcesses,
              withActions: false,
            });
          }
          this.$emit('unabort-process', this.selectedProcess._id);
          this.onActionSuccess({ data });
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      async deleteProcess({ confirm } = {}) {
        if (!confirm) {
          this.displayModal = this.processActions.deleteProcess.key;
          return;
        }
        try {
          const data = await this.processesStore.onDelete({ processId: this.selectedProcess._id });
          if (data?._customJob) {
            await this.updateCustomJob({
              id: data._customJob._id,
              nbActiveProcesses: data._customJob.nbActiveProcesses,
              withActions: false,
            });
          }

          this.$emit('delete-process', this.selectedProcess._id);

          this.onActionSuccess({ data });
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      async setCommissionCustom({ confirm, commissionCustom } = {}) {
        if (!confirm) {
          this.setParams({
            processCustom: this.selectedProcess,
          });
          this.displayModal = this.processActions.commissionCustom.key;
          return;
        }
        try {
          const { data } = await updateProcessCustom({
            id: this.selectedProcess._id,
            commissionCustom: +commissionCustom,
          });
          this.$emit('process-update', { updatedProcess: data });
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err?.message,
          });
        } finally {
          this.setParams({});
          this.closeModal();
        }
      },
      async setProcessScheduledDate({ confirm, date, action } = {}) {
        if (!confirm) {
          this.displayModal = action;
          return;
        }

        const keys = {
          'next-interview': 'interviewedAt',
          'next-presentation': 'introducedAt',
          'integration-date': 'integratedAt',
        };

        const key = keys[this.displayModal] || 'introducedAt';

        try {
          const { data } = await updateProcessCustom({
            id: this.selectedProcess._id,
            [key]: date,
          });
          this.$emit('process-update', {
            updatedProcess: {
              ...this.selectedProcess,
              [key]: data[key],
            },
          });
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        } finally {
          this.setParams({});
          this.closeModal();
        }
      },
      handleDragStart: debounce(function(evt, process) {
        if (!this.generalCardTypes.includes(this.cardType)) {
          return;
        }
        if (!this.handleDraggable(process)) {
          this.$emit('drag-warning', evt, process);
        }
      }, 200),
      handleDraggable(process) {
        if (!this.generalCardTypes.includes(this.cardType)) {
          return true;
        }
        if (process.coderCompaniesCustomJobs) {
          return Object.keys(process.coderCompaniesCustomJobs).length === 1;
        }
        if (process.codersActiveProcesses) {
          return Object.keys(process.codersActiveProcesses).length === 1;
        }
        return false;
      },
      handleDraggingStart(event) {
        this.$emit('dragging', true);
        this.$emit('card-dragged', event);
      },
      manageProcess(newProcess) {
        if (modalTypes[this.stepName]) {
          this.$emit('set-modal-type', {
            process: newProcess,
            type: modalTypes[this.stepName].modalName,
          });

          if (!modalTypes[this.stepName].isBlocking) {
            this.$emit('move-process', {
              processId: newProcess._id,
              stepName: this.stepName,
              step: this.step,
            });
          }
        } else {
          this.$emit('move-process', {
            processId: newProcess._id,
            stepName: this.stepName,
            step: this.step,
          });
        }
      },
      opportunityUrl(process) {
        if (process?._customJob?._id) {
          return {
            ...this.$route,
            params: {
              id: process._customJob._id,
            },
            query: {
              ...this.$route.query,
              type: 'hunt',
            },
          };
        } else {
          return {
            ...this.$route,
            params: {
              id: process?._company?._id,
            },
            query: {
              ...this.$route.query,
              type: 'companies',
            },
          };
        }
        ;
      },
      candidateUrl(process) {
        return {
          ...this.$route,
          params: {
            id: process?._coder?._id,
          },
          query: {
            ...this.$route.query,
            type: 'coders',
          },
        };
      },
      handleRoute() {
        if (this.isOptionsHover) {
          return false;
        }
      },
      panelRoute(process) {
        if (this.$route.params.id) {
          if (this.$route.query.type === 'process-kanban') {
            if (this.$route.query.subtype === 'coders') {
              return this.opportunityUrl(process);
            } else if ((this.$route.query.subtype === 'hunt' || this.$route.query.subtype === 'companies')) {
              return this.candidateUrl(process);
            }
          }

          if (this.$route.query.type === 'coders') {
            return this.opportunityUrl(process);
          } else if ((this.$route.query.type === 'hunt' || this.$route.query.type === 'companies')) {
            return this.candidateUrl(process);
          }
        }

        const id = this.$route.query['selected-view'] === 'opportunities' ? process._customJob._id : process._coder._id;
        const type = this.$route.query['selected-view'] === 'opportunities' ? 'hunt' : 'coders';

        const processesPanel = {
          name: 'ProcessesPanel',
          params: {
            id,
          },
          query: {
            ...this.$route.query,
            type,
            category: 'process',
            context: 'processes',
          },
        };

        const processPanel = {
          ...this.$route,
          params: {
            id: process._id,
          },
          query: {
            ...this.$route.query,
            type: 'process',
            typing: true,
          },
        };

        if (this.$route.name === 'Processes') {
          return processesPanel;
        }

        return processPanel;
      },
      setIsHover() {
        this.isHover = true;
      },
      unsetIsHover() {
        this.isHover = false;
      },
      dragover(event) {
        this.setIsHover();
        if (event.target.id !== this.hoverId) {
          this.hoverId = event.target.id;
        }
        this.$emit('dragover', event);
      },
      formattedAmount(amount) {
        // return (amount * 1000).toLocaleString();
        return amount.toLocaleString();
      },
      acceptProcess(process) {
        this.$emit('accept-process', { process, step: this.step });
        setTimeout(() => {
          this.isHoverCandidateBtns = false;
        }, 100);
      },
      rejectProcess(process) {
        this.$emit('reject-process', { process, step: this.step });
        setTimeout(() => {
          this.isHoverCandidateBtns = false;
        }, 100);
      },
      openEmailPanel({ coder, customJob, actionId }) {
        this.setParams({
          coder,
          customJob,
          actionId,
          emails: [coder.email, ...(coder.emails || [])].filter(Boolean),
          isCompany: false,
          isApplication: true,
        });
        this.setIsPanel(true);
        this.setModal('modal-email-send-form');
      },
      numberOfCustomJobs(process: {
        coderCompaniesCustomJobs: Record<string, { '_company': any, customJobs: any[] }>
      }) {
        return Object.values(process?.coderCompaniesCustomJobs || {}).flatMap((customJobsByCompany) => customJobsByCompany.customJobs).length;
      },
      isFirstStep(process) {
        return this.careerIsActive && this.isFirst && this.categoryIndex === 0 && !process.rejectedAt && !process.abortedAt;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .process-column {
    flex-direction: column;
    position: relative;

    &__header {
      padding: 15px;
      justify-content: space-between;
      text-transform: uppercase;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      background: $color-white;
      border-bottom: 1px solid rgba($color-blue-dark-cello, 0.2);
      z-index: 1;
      height: 47.5px;
      border-radius: inherit;

      &--first {
        border-top-left-radius: 5px;
      }

      &--last {
        border-top-right-radius: 5px;
      }

      &--beforeCandidatePresentation {
        background: $color-primary;
      }

      &--beforeProcessRecruitment {
        background: $color-secondary;
      }

      &--afterProcessRecruitment {
        background: $color-success;
      }

      &--failed {
        background: $color-error;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }

    &__title {
      color: white;

      a {
        color: white;
        margin-left: 10px;
        height: 16.35px;
      }
    }

    &__body {
      flex-grow: 1;
      transition: 0.15s;
      flex-direction: column;
      padding-top: 5px;
      overflow: auto;
      padding-bottom: 55px;

      &--beforeCandidatePresentation {
        background: rgba($color-primary, 0.05);

        &--hover {
          background: rgba($color-primary, 0.2);
        }
      }

      &--beforeProcessRecruitment {
        background: rgba($color-secondary, 0.05);

        &--hover {
          background: rgba($color-secondary, 0.2);
        }
      }

      &--afterProcessRecruitment {
        background: rgba($color-success, 0.05);

        &--hover {
          background: rgba($color-success, 0.2);
        }
      }

      &--failed {
        background: rgba($color-error, 0.05);

        &--hover {
          background: rgba($color-error, 0.2);
        }
      }
    }

    &__step {
      flex-direction: column;
      width: 100%;
      flex-grow: 1;

      &--old {
        margin-top: 10px;
        flex-grow: 1;
        height: 100%;
        padding: 10px 0;
        border-top: 1px solid rgba($color-blue-dodger, 0.3);
      }

      &--disabled {
        cursor: initial;
        user-select: none;
      }
    }

    &__link {
      justify-content: center;
    }

    &__card {
      margin: 5px 10px;
    }

    &__amount {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-transform: uppercase;
      background: $color-white;
      color: $color-secondary;
      border-top: 1px solid rgba($color-secondary, 0.1);
      z-index: 2;
      border-right: 1px solid $color-blue-light;

      &-number {
        color: $color-tertiary;
        width: 100%;
        text-align: right;
      }

      &--beforeCandidatePresentation {
        background: rgba($color-primary, 0.10);
        width: 100%;
        height: 100%;
        padding: 15px 20px;
      }

      &--beforeProcessRecruitment {
        background: rgba($color-secondary, 0.10);
        width: 100%;
        height: 100%;
        padding: 15px 20px;
      }

      &--afterProcessRecruitment {
        background: rgba($color-success, 0.10);
        width: 100%;
        height: 100%;
        padding: 15px 20px;
      }

      &--failed {
        background: rgba($color-error, 0.10);
        width: 100%;
        height: 100%;
        padding: 15px 20px;
      }
    }


  }

  .process-card {
    &--ghost {
      display: none;
    }
  }
</style>
