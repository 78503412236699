<template>
  <div
    v-click-outside="cancelCreate"
    class="panel-process-create">
    <panel-process-create-header></panel-process-create-header>
    <div class="panel-process-create__body">
      <div class="panel-process-create__container">
        <panel-process-create-wrapper
          v-for="(process, index) in processes"
          :key="index"
          :index="index"
          :process="process"
          :processes="processes"
          :selected-coders="selectedCoders"
          :show-companies="configurationFramework.showCompanies"
          class="panel-process-create__wrapper"
          @process-removed="removeProcess"
          @process-company-set="setProcessCompany"
          @process-custom-job-set="setProcessCustomJob"
          @process-status-set="setProcessStatus"
          @process-coder-set="setProcessCoder">
        </panel-process-create-wrapper>
      </div>
    </div>
    <panel-process-create-footer
      :is-process-complete="isProcessComplete"
      :processes="processes"
      :selected-coders="selectedCoders"
      class="panel-process-create__footer"
      @cancel="cancelCreate">
    </panel-process-create-footer>
    <modal
      v-if="isModalOpen"
      @close-modal="continueToEdit">
      <modal-leave-edit-confirmation
        @continue-edit="continueToEdit"
        @leave-edit="confirmCancelEdit">
      </modal-leave-edit-confirmation>
    </modal>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import { getCustomJobs } from '@/api/custom-jobs';
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation.js';
  //
  import PanelProcessCreateHeader
    from '@/components/Panel/PanelProcess/PanelProcessCreate/PanelProcessCreateHeader';
  import PanelProcessCreateFooter
    from '@/components/Panel/PanelProcess/PanelProcessCreate/PanelProcessCreateFooter';
  import coders from '@/api/coders';
  import company from '@/api/company';
  import Modal from '@/components/Modal/Modal';
  import ModalLeaveEditConfirmation
    from '@/components/Modal/ModalLeaveEditConfirmation/ModalLeaveEditConfirmation';
  import PanelProcessCreateWrapper
    from '@/components/Panel/PanelProcess/PanelProcessCreate/PanelProcessCreateWrapper';

  export default {
    name: 'panel-process-create',
    components: {
      PanelProcessCreateWrapper,
      ModalLeaveEditConfirmation,
      Modal,
      PanelProcessCreateFooter,
      PanelProcessCreateHeader,
    },
    mixins: [PanelNavigation],
    mounted() {
      if (!this.configurationFramework.showCompanies) {
        this.setProcessCompany({ index: 0, company: this.organization._company });
      }
    },
    data() {
      return {
        processes: [
          {
            _company: {},
            _customJob: {},
            _coder: {},
            introductionSalary: 0,
            status: -1,
          },
        ],
        isLoaded: false,
        isModalOpen: false,
        clickOutsideTarget: '',
        selectedCoder: null,
      };
    },
    computed: {
      ...mapState({
        selectedCards: state => state.card.selectedCards,
      }),
      ...mapGetters({
        configurationFramework: 'user/configurationFramework',
        organization: 'user/organization',
      }),
      isProcessComplete() {
        const lastProcess = this.processes[this.processes.length - 1];
        const isCompanySet = Boolean(lastProcess._company?._id);
        const isCoderSet = Boolean(lastProcess._coder?._id) || this.selectedCoders.length > 0;

        return isCoderSet && isCompanySet;
      },
      selectedCoders() {
        if (this.$route.query.from === 'multi-select' && this.selectedCards.length) {
          return this.selectedCards;
        }
        return []
      }
    },
    methods: {
      cancelCreate(event = {}) {
        if (this.$route.query['unsaved-changes']) {
          this.clickOutsideTarget = event.target?.className || '';

          this.isModalOpen = true;
        } else {
          if (event.target?.className) {
            this.closePanel();
          } else {
            this.goPrevious();
          }
        }
      },
      confirmCancelEdit() {
        if (this.clickOutsideTarget === 'overlay') {
          this.closePanel();
        } else {
          this.goPrevious();
        }
      },
      continueToEdit() {
        this.clickOutsideTarget = '';

        this.isModalOpen = false;
      },
      addProcess() {
        this.processes.push({
          _company: {},
          _customJob: {},
          _coder: this.$route.params.id ? this.processes[0]?._coder : {},
          introductionSalary: this.$route.params.id ? this.processes[0]?._coder.minimumPay?.yearly || 0 : 0,
          status: -1,
        });
      },
      removeProcess(index) {
        this.processes.splice(index, 1);
      },
      setProcessCompany({ index, company }) {
        this.processes[index]._company = company;
      },
      setProcessCoder({ index, coder }) {
        this.selectedCoder = coder;
        this.processes[index]._coder = coder;
      },
      setProcessCustomJob({ index, customJob }) {
        if (this.$route.params.id && !this.processes[0]?._coder?.minimumPay?.yearly && customJob?.salaryMin) {
          if (customJob.salaryMin >= 0 || customJob.salaryMax >= 0) {
            const salary = customJob.salaryMin || customJob.salaryMax;

            if (this.processes[index].introductionSalary !== salary) {
              this.processes[index].introductionSalary = salary;
            }
          }
        }

        this.processes[index]._customJob = customJob;
      },
      setProcessStatus({ index, status }) {
        this.processes[index].status = status;
      },
      async getCoderProfile(id) {
        try {
          const { data } = await coders.getCoderProfile(id);

          this.processes[0].introductionSalary = data?.minimumPay?.yearly || 0;
          this.processes[0]._coder = data;

          this.$nextTick(() => {
            this.isLoaded = true;
          });
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      async getCompany(id) {
        try {
          const { data } = await company.getPartnerProfile(id);

          this.processes[0]._company = data;

          this.$nextTick(() => {
            this.isLoaded = true;
          });
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      async getCustomJobs(companyId, customJobId) {
        try {
          const { data } = await getCustomJobs({ companyId, active: true });

          const findedCustomJob = data.jobs.find(customJob => customJob._id === customJobId)
          this.processes[0]._customJob = findedCustomJob;

          this.$nextTick(() => {
            this.isLoaded = true;
          });
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      }
    },
    async created() {
      const id = this.$route.params.id;
      if (!id) {
        return;
      }
      if (this.$route.query.from === 'coders' || this.$route.query.from === 'suggestions-coders') {
        this.getCoderProfile(id);
      }
      if (this.$route.query.from === 'companies') {
        this.getCompany(id);
      }
      if (this.$route.query.from === 'hunt') {
        const companyId = this.$route.query['company-id'];
        await this.getCompany(companyId);
        this.getCustomJobs(companyId, id);
      }
    },
    watch: {
      processes: {
        handler() {
          if (this.isLoaded) {
            this.$router.replace({
              query: {
                ...this.$route.query,
                coderId: this.selectedCoder?._id,
                'unsaved-changes': true,
              },
            });
          }
        },
        immediate: false,
        deep: true,
      },
    },
    beforeDestroy() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          'unsaved-changes': undefined,
        },
      });
    },
  };
</script>

<style lang=scss scoped>
  .panel-process-create {
    flex-direction: column;
    height: 100%;
    background: $color-white;
    z-index: 1;

    &__body {
      width: 100%;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      overflow: auto;
    }

    &__container {
      padding: 30px;
      flex-direction: column;
      align-items: center;
    }

    &__button {
      flex-shrink: 0;
    }

    &__footer {
      box-shadow: 0 3px 6px rgba($color-black, 0.15);
    }
  }
</style>
