import { createProcessCustom } from './useCases/createProcessCustom';
import { getProcessesCustom } from './useCases/getProcessesCustom';
import {
  updateProcessCustomStep as updateProcessCustomStepUseCase,
} from './useCases/updateProcessCustomStep';
import { abortProcessCustom as abortProcessCustomUseCase } from './useCases/abortProcessCustom';

const get = (params) => {
  return getProcessesCustom(params);
};

const create = (process) => {
  return createProcessCustom(process);
};

const getProcessesByCandidateId = async({ id }) => {
  return getProcessesCustom({ resource: 'coder', coderId: id });
};

const updateProcessCustomStepStatus = async({ id, categoryKey, stepKey }) => {
  return updateProcessCustomStepUseCase({ id, categoryKey, stepKey });
};

const abortProcessCustom = async({ id, reason, rejected }) => {
  return abortProcessCustomUseCase({ id, reason, rejected });
};

export const createProcess = (process) => {
  return createProcessCustom(process);
};

export const processCustomController = {
  get,
  create,
  getProcessesByCandidateId,
  createProcess,
  updateProcessCustomStepStatus,
  abortProcessCustom,
};
