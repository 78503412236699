import { getTags, getTagTypes, getOrganizationsWithTags } from '@/api/tags';
import { tagController } from '@/managers/tags/controller';

export default {
  namespaced: true,
  state: {
    technos: [],
    tagTypes: [],
    tagTypesOptions: [],
    organizationsWithTags: [],
    organizationsWithTagsOptions: [],
  },
  actions: {
    async getTags({ commit }) {
      const { data } = await getTags();

      commit('setTechnos', data);
    },
    async createTag({ commit }, { type, name }) {
      const tag = await tagController.create({ type, name });

      commit('setTechnos', [tag]);

      return tag;
    },
    async editTag({ commit }, { id, name }) {
      await tagController.edit({ id, name });

      commit('updateTechno', { _id: id, name });
    },
    async deleteTag({ commit }, { id }) {
      await tagController.remove(id);

      commit('deleteTechno', id);
    },
    async getTagTypes({ commit }) {
      const { data } = await getTagTypes();
      commit('setTagTypes', data);
      commit('setTagTypesOptions', data);
    },
    async getOrganizationsWithTags({ commit }) {
      const { data } = await getOrganizationsWithTags();
      commit('setOrganizationsWithTags', data);
      commit('setOrganizationsWithTagsOptions', data);
    },
  },
  mutations: {
    setTagTypes(state, tagTypes) {
      state.tagTypes = tagTypes;
    },
    setTagTypesOptions(state, tagTypes) {
      state.tagTypesOptions = [{name: "Tous", value: null}, ...tagTypes.map(item => ({
        name: item.name,
        value: item._id,
      }))];
    },
    setOrganizationsWithTags(state, organizationsWithTags) {
      state.organizationsWithTags = organizationsWithTags;
    },
    setOrganizationsWithTagsOptions(state, organizationsWithTags) {
      state.organizationsWithTagsOptions = [{name: "Toutes", value: null}, ...organizationsWithTags.map(item => ({
        name: item.name,
        value: item._id,
      }))];
    },
    setTechnos(state, technos) {
      state.technos = [...technos, ...state.technos];
    },
    updateTechno(state, techno) {
      const index = state.technos.findIndex(t => t._id === techno._id);

      state.technos[index].name = techno.name;
    },
    deleteTechno(state, id) {
      state.technos = state.technos.filter(techno => techno._id !== id);
    },
  },
};
