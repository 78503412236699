<template>
  <div
    key="overlay"
    class="overlay"
    :style="{ zIndex: zIndex, 'background-color': disableBackground ? 'transparent' : 'rgba(29, 57, 94, 0.55)' }"
    @click.once.self="clickFn">
    <slot></slot>
  </div>
</template>

<script>
  // TODO Créer un composant overlay personnalisable
  export default {
    name: 'overlay',
    props: {
      zIndex: Number,
      clickFn: {
        type: Function,
        required: false,
        default: () => {}
      },
      disableBackground: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style lang="scss" scoped>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(29, 57, 94, 0.55);
    @include transition(all 0.3s ease);
    z-index: z('panel-overlay');
  }
</style>
