<template>
  <div class="separator">
    <hr class="separator__vertical" :style="barStyles" />
  </div>
</template>

<script>
export default {
  name: 'separator',
  props: {
    barStyles: {
      type: [Object, String],
      default: () => ({}),
    }
  }
}
</script>

<style lang="scss" scoped>
.separator {
  align-items: center;
  height: 100%;

  & > hr {
    width: 1px;
    background: $color-blue-medium;
    height: 60%;
  }
}
</style>
