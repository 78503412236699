export const validateId = id => {
  if (id) {
    return;
  }

  throw new Error('L\'id envoyé en paramètre n\'est pas valide');
};

export default {
  validateId,
};
