import {i18n} from '../../i18n/i18n'

export const prospectionStatusCategoryApi = {
  unsigned: 1,
  signed: 2,
  failed: 3,
};

export const oldProspectionStatus = {
  notSupported: 1,
  toProspect: 2,
  toQualify: 3,
  gcsSent: 4,
  forLater: 5,
  denounced: 6,
  standBy: 7,
  liquidated: 8,
  signed: 9,
  sourcing: 10,
  processed: 11,
  contractToRenew: 12,
}

export const oldProspectionStatusTrads = {
  [oldProspectionStatus.notSupported]: i18n.t(`companies.steps.unsigned`),
  [oldProspectionStatus.toProspect]: i18n.t(`companies.steps.to-prospect`),
  [oldProspectionStatus.toQualify]: i18n.t(`companies.steps.unsigned`),
  [oldProspectionStatus.gcsSent]: i18n.t(`companies.steps.unsigned`),
  [oldProspectionStatus.forLater]: i18n.t(`companies.steps.unsigned`),
  [oldProspectionStatus.denounced]: i18n.t(`companies.steps.unsigned`),
  [oldProspectionStatus.standBy]: i18n.t(`companies.steps.unsigned`),
  [oldProspectionStatus.liquidated]: i18n.t(`companies.steps.unsigned`),
  [oldProspectionStatus.signed]: i18n.t(`companies.steps.unsigned`),
  [oldProspectionStatus.sourcing]: i18n.t(`companies.steps.unsigned`),
  [oldProspectionStatus.processed]: i18n.t(`companies.steps.unsigned`),
  [oldProspectionStatus.contractToRenew]: i18n.t(`companies.steps.unsigned`),
}

export const prospectionStatusCategoryLabel = {
  [prospectionStatusCategoryApi.unsigned]: i18n.t(`prospection.unsigned`),
  [prospectionStatusCategoryApi.signed]: i18n.t(`prospection.signed`),
  [prospectionStatusCategoryApi.failed]: i18n.t(`prospection.failed`),
}

export const prospectionStatusCategoryColor = {
  [prospectionStatusCategoryApi.unsigned]: {
    color: '#3865FD',
    backgroundColor: '#3865FD1A',
  },
  [prospectionStatusCategoryApi.signed]: {
    color: '#3DCF8E',
    backgroundColor: '#3DCF8E1A',
  },
  [prospectionStatusCategoryApi.failed]: {
    color: '#E6525A',
    backgroundColor: '#E6525A1A',
  },
}

export const getProspectingStatusCategory = (categories, status) => {
  return categories?.find(category => {
    return !!category?.steps?.find(step => step.key === status)
  })?.key;
}

export default {
  prospectionStatusCategoryApi,
};
