<template>
  <div class="custom-jobs-contract-dates flex gap-[20px]">
    <v-date-picker
      v-model="startDateValue"
      :locale="locale"
      :max-date="endDateValue ? endDateValue : undefined"
      :popover="{ visibility: 'click', positionFixed: true }"
      class="custom-jobs-contract-dates__input flex max-w-[calc(50%-10px)] basis-1/2-gap-3.5"
      @input="onUpdateStartDate"
    >
      <template #default="{togglePopover}">
        <bc-input
          :label="$t('companies.account-management.contract-start-date')"
          :labelPlaceholder="false"
          :placeholder="$t('companies.account-management.contract-start-date')"
          :valid="!!startDateValue"
          :value="startDateFormat"
          class="custom-jobs-contract-dates__input w-full"
          icon="calendar"
          icon-left="calendar"
          name="contract-start-date"
          @input="onUpdateStartDateInput"
          @focus="togglePopover"
        />
      </template>
    </v-date-picker>
    <v-date-picker
      v-model="endDateValue"
      :locale="locale"
      :min-date="startDateFormat ? startDateFormat : undefined"
      :popover="{ visibility: 'click', positionFixed: true }"
      class="custom-jobs-contract-dates__input flex max-w-[calc(50%-10px)] basis-1/2-gap-3.5"
      @input="onUpdateEndDate"
    >
      <template #default="{togglePopover}">
        <bc-input
          :label="$t('companies.account-management.contract-end-date')"
          :labelPlaceholder="false"
          :placeholder="$t('companies.account-management.contract-end-date')"
          :valid="!!endDateValue"
          :value="endDateFormat"
          class="custom-jobs-contract-dates__input w-full"
          icon="calendar"
          icon-left="calendar"
          name="contract-end-date"
          @input="onUpdateEndDateInput"
          @focus="togglePopover"
        />
      </template>
    </v-date-picker>
  </div>
</template>

<script>
  import moment from 'moment/min/moment-with-locales';
  import locale from '@/mixins/i18n/locale';
  import BcInput from '@/ui-kit/components/BcInput/BcInput';

  export default {
    name: 'custom-jobs-contract-dates',
    components: {
      BcInput,
    },
    mixins: [locale],
    props: {
      startDate: {
        type: [Date, String],
      },
      endDate: {
        type: [Date, String],
      },
    },
    mounted() {
      this.startDateValue = (this.startDate || '').split('T')[0];
      this.endDateValue = (this.endDate || '').split('T')[0];
    },
    data() {
      return {
        startDateValue: undefined,
        endDateValue: undefined,
      };
    },
    computed: {
      startDateFormat() {
        if (!this.startDateValue) return undefined;
        return moment(this.startDateValue).format('L');
      },
      endDateFormat() {
        if (!this.endDateValue) return undefined;
        return moment(this.endDateValue).format('L');
      },
    },
    methods: {
      onUpdateStartDate(date) {
        this.startDateValue = moment(date).format().split('T')[0];
      },
      onUpdateEndDate(date) {
        this.endDateValue = moment(date).format().split('T')[0];
      },
      onUpdateStartDateInput(date) {
        if (!date) {
          this.startDateValue = undefined;
        }
      },
      onUpdateEndDateInput(date) {
        if (!date) {
          this.endDateValue = undefined;
        }
      },
    },
    watch: {
      startDateValue(value) {
        this.$emit('select-date', { contractStartDate: value });
      },
      endDateValue(value) {
        this.$emit('select-date', { contractEndDate: value });
      },
    },
  };
</script>
