<template>
  <div class="panel-hunt-process-rpo">
    <div class="panel-hunt-process-rpo__wrapper">
      <div class="panel-hunt-process-rpo__body">
        <process-column
          v-for="(step, stepName, index) in steps"
          :key="index"
          :is-dragging="isDragging"
          :read-only="getProcessColumnDisabledStatus(stepName)"
          :step="{
            ...step,
            name: columnNames[index],
            style: columnStyle[index],
          }"
          :step-name="stepName"
          :style="{ color: step.color }"
          card-type="process-card-hunt"
          class="panel-hunt-process-rpo__step"
          @dragging="isDragging = $event"
          @input="updateProcesses"
          @card-dragged="getEditableProcessColumn"
          @move-process="moveProcess"
          @process-update="updateProcess"
          @profile-updated="$emit('profile-updated', $event)"
          @set-modal-type="setModalType($event)">
        </process-column>
        <component
          :is="modalType"
          :is-modal-open="isModalOpen"
          :process="modalProcess"
          class="panel-hunt-process-rpo__modal"
          @close-modal="closeModal"
          @move-process="moveProcessCard"
          @process-update="updateProcess"
          @profile-updated="$emit('profile-updated', $event)">
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
//
import ProcessColumn from '@/components/Process/ProcessColumn';
import BcButton from '@/ui-kit/components/BcButton';
// Steps Modals
import ModalProcessNewOffer from '@/components/Modal/ModalProcess/ModalProcessNewOffer';
import ModalProcessDeleteConfirmation from '@/components/Modal/ModalProcess/ModalProcessDeleteConfirmation';
import ModalProcessStartValidation from '@/components/Modal/ModalProcess/ModalProcessStartValidation';
import ModalProcessOnboardingValidation from '@/components/Modal/ModalProcess/ModalProcessOnboardingValidation';
// Footers
import PanelCoderProcessFooter from '@/components/Panel/PanelCoder/PanelCoderProcess/PanelCoderProcessFooter';
import PanelJobProcessFooter from '@/components/Panel/PanelJob/PanelJobProcess/PanelJobProcessFooter';
import PanelHuntProcessFooter from '@/components/Panel/PanelHunt/PanelHuntProcessFooter';
import PanelCompanyProcessFooter from '@/components/Panel/PanelCompany/PanelCompanyProcess/PanelCompanyProcessFooter';

import moment from 'moment/min/moment-with-locales';
import { processController } from '@/managers/processes/controller';
import { modalTypes, processStatus } from '@/macros/processes/process';
import { bluecodersCompanyId } from '@/macros/utils';
import colors from '@/ui-kit/assets/scss/exports/_colors.module.scss';
import { getStepNameByProcessStatus } from '@/managers/processes/formatters/processDeserializers';

export default {
  name: 'panel-hunt-process-rpo',
  components: {
    PanelCoderProcessFooter,
    PanelJobProcessFooter,
    PanelHuntProcessFooter,
    PanelCompanyProcessFooter,
    ModalProcessOnboardingValidation,
    ModalProcessStartValidation,
    ModalProcessDeleteConfirmation,
    ModalProcessNewOffer,
    BcButton,
    ProcessColumn,
  },
  props: {
    profile: {
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      isDragging: false,
      isDraggingForbidden: false,
      isModalOpen: false,
      modalType: '',
      modalProcess: {},
      steps: {
        introduced: {
          name: this.$i18n.t('companies.panel-hunt-process.presentation'),
          query: ['pending', 'introduced'],
          color: colors['secondary-light'],
          processes: [],
        },
        processed: {
          name: this.$i18n.t('generics.process'),
          query: ['processed'],
          color: colors['secondary'],
          processes: [],
        },
        finalised: {
          name: this.$i18n.t('companies.panel-hunt-process.final'),
          query: ['finalised', 'offered'],
          color: colors['primary-dark'],
          processes: [],
        },
        dealt: {
          name: this.$i18n.t('process.waiting-to-start'),
          query: ['dealt'],
          color: colors['primary'],
          processes: [],
        },
        onboarding: {
          name: this.$i18n.t('generics.onboarding'),
          query: ['onboarding'],
          color: colors['primary-light'],
          processes: [],
        },
        validated: {
          name: this.$i18n.t('companies.panel-hunt-process.validate'),
          query: ['validated'],
          color: colors['deal'],
          processes: [],
        },
      },
      cards: {
        pending: {
          color: colors['secondary-light'],
          text: this.$i18n.t('companies.panel-hunt-process.pending'),
          options: [
            {
              text: this.$i18n.t('companies.panel-hunt-process.confirm-presentation'),
              action: async id => {
                const updatedProcess = await processController.move(
                  id,
                  'introduced'
                );

                this.updatePanelProfile({
                  processState: updatedProcess._coder.processState,
                });
                this.$emit('profile-updated', {
                  processState: updatedProcess._coder.processState,
                });
                this.$emit('process-update', {
                  updatedProcess: updatedProcess,
                  stepName: 'pending',
                });
              },
            },
            {
              text: this.$i18n.t('companies.panel-hunt-process.failure'),
              action: async id => this.failedProcess(id),
            },
          ],
        },
        introduced: {
          color: colors['secondary-light'],
          text: this.$i18n.t('companies.panel-hunt-process.present'),
          options: [
            {
              text: this.$i18n.t('companies.panel-hunt-process.cancel-presentation'),
              action: async id => {
                const updatedProcess = await processController.move(
                  id,
                  'pending'
                );

                this.updatePanelProfile({
                  processState: updatedProcess._coder.processState,
                });
                this.$emit('profile-updated', {
                  processState: updatedProcess._coder.processState,
                });
                this.$emit('process-update', {
                  updatedProcess: updatedProcess,
                  stepName: 'introduced',
                });
              },
            },
            {
              text: this.$i18n.t('companies.panel-hunt-process.failure'),
              action: async id => this.failedProcess(id),
            },
          ],
        },
        processed: {
          color: colors['secondary'],
          text: this.$i18n.t('generics.processing-cap'),
        },
        finalised: {
          color: colors['primary-dark'],
          text: this.$i18n.t('companies.panel-hunt-process.no-offer-yet'),
          options: [
            {
              text: this.$i18n.t(
                'companies.panel-hunt-process.an-offer-has-been-received'
              ),
              action: () => {
                this.setModalType({
                  process: this.process,
                  type: modalTypes['offered'].modalName,
                });
              },
            },
            {
              text: this.$i18n.t('companies.panel-hunt-process.failure'),
              action: async id => this.failedProcess(id),
            },
          ],
        },
        offered: {
          color: colors['primary-dark'],
          text: this.$i18n.t('companies.panel-hunt-process.offer'),
          options: [
            {
              text: this.$i18n.t('companies.panel-hunt-process.edit-an-offer'),
              action: () => {
                this.setModalType({
                  process: this.process,
                  type: modalTypes['offered'].modalName,
                });
              },
            },
            {
              text: this.$i18n.t('companies.panel-hunt-process.failure'),
              action: async id => this.failedProcess(id),
            },
          ],
        },
        dealt: {
          color: colors['primary'],
          text:this.$i18n.t('companies.panel-hunt-process.not-started'),
          options: [
            {
              text: this.$i18n.t('companies.panel-hunt-process.validate-start'),
              action: () => {
                this.setModalType({
                  process: this.process,
                  type: modalTypes['onboarding'].modalName,
                });
              },
            },
            {
              text: this.$i18n.t('companies.panel-hunt-process.drop'),
              action: async id => this.failedProcess(id),
            },
          ],
        },
        failed: {
          color: colors['error'],
          text: this.$i18n.t('companies.panel-hunt-process.failure'),
          options: [
            {
              text: this.$i18n.t(
                'companies.panel-hunt-process.cancel-process-failure'
              ),
              action: async id => this.unfailedProcess(id),
            },
          ],
        },
        drop: {
          color: colors['error'],
          text: this.$i18n.t('companies.panel-hunt-process.drop'),
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      cardActive: 'cardActive',
      isPL: 'user/isPL',
    }),
    options() {
      return {
        ...(this.$route.query['process-starts'] && {
          from: moment(this.$route.query['process-starts'], 'D')
            .startOf('day')
            .toISOString(),
        }),
        ...(this.$route.query['process-ends'] && {
          to: moment(this.$route.query['process-ends'], 'D')
            .endOf('day')
            .toISOString(),
        }),
        ...(this.$route.query['selected-coaches'] && {
          coachIds: this.$route.query['selected-coaches'].join(','),
        }),
      };
    },
    columnNames() {
      if (this.isPL) {
        return [
          this.$i18n.t('generics.sourcing'),
          this.$i18n.t('process.r1'),
          this.$i18n.t('process.r2'),
          this.$i18n.t('process.customer-presentation'),
          this.$i18n.t('generics.onboarding'),
          this.$i18n.t('companies.panel-hunt-process.validate'),
        ];
      }
      return [
        this.$i18n.t('companies.panel-hunt-process.presentation'),
        this.$i18n.t('generics.process'),
        this.$i18n.t('companies.panel-hunt-process.final'),
        this.$i18n.t('process.waiting-to-start'),
        this.$i18n.t('generics.onboarding'),
        this.$i18n.t('companies.panel-hunt-process.validate'),
      ];
    },
    columnStyle() {
      return [
        {},
        {},
        {},
        {
          ...(this.isPL && { header: { 'font-size': '12px' } }),
        },
        {},
        {},
      ];
    },
  },
  methods: {
    ...mapActions([
      'updatePanelProfile',
      'updateCard',
      'unshiftCard',
      'removeCard',
    ]),
    getProcesses() {
      const steps = Object.entries(this.steps);

      steps.forEach(([stepName, step]) => {
        this.getProcessesByColumn(stepName, step);
      });
    },
    async getProcessesByColumn(stepName, step) {
      try {
        const options = {
          resource: 'hunt',
          id: this.profile._id || this.$route.params.id,
          steps: step.query,
          ...this.options,
        };

        const { data } = await processController.get(options);

        this.steps[stepName].processes = data;
      } catch (error) {
        this.$toast.show({
          type: 'error',
          message: this.$t('toast.error-occured'),
        });
      }
    },
    getStepByQuery(query) {
      const [stepName] = Object.entries(this.steps).find(([, values]) => {
        return values.query.includes(query);
      });

      return this.steps[stepName];
    },
    getEditableProcessColumn(process) {
      if (this.isDragging && process?.item?.className?.includes('forbidden')) {
        this.isDraggingForbidden = true;
      }
    },
    getProcessColumnDisabledStatus(columnName) {
      return (
        columnName !== 'introduced' &&
        columnName !== 'processed' &&
        this.isDraggingForbidden
      );
    },
    updateProcesses({ stepName, processes }) {
      this.steps[stepName].processes = processes;
    },
    updateProcess({ updatedProcess, stepName: queryName }) {
      if (queryName === processStatus.onboarding) {
        this.getProcesses();
      }

      // Get step by query
      const step = this.getStepByQuery(queryName);

      // Get process by id in step processes
      const processIndex = step.processes.findIndex(
        process => process._id === updatedProcess._id
      );

      this.$set(step.processes, processIndex, updatedProcess);

      this.updatePanelProfile({
        processState: updatedProcess._coder.processState,
        coachProcessState: updatedProcess._coder.coachProcessState,
      });
      this.$emit('profile-updated', {
        processState: updatedProcess._coder.processState,
        coachProcessState: updatedProcess._coder.coachProcessState,
      });
      // this.$_manageCoderInMyOldProcessesList(updatedProcess);

      if (this.cardActive >= 0) {
        this.updateCard({
          index: this.cardActive,
          content: updatedProcess._coder,
        });
      }
    },

    async moveProcess({ processId, stepName }) {
      try {
        const updatedProcess = await processController.move(
          processId,
          stepName
        );

        this.updateProcess({ updatedProcess, stepName });
      } catch (error) {
        this.$toast.show({
          type: 'error',
          message: this.$t('toast.error-occured'),
        });
      }
    },
    moveProcessCard({ process, fromStep, toStep }) {
      const fromStepProcesses = this.getStepByQuery(fromStep).processes;
      const toStepProcesses = this.getStepByQuery(toStep).processes;

      const alreadyMoved = toStepProcesses.find(p => p._id === process._id);

      if (!alreadyMoved) {
        const processIndex = fromStepProcesses.findIndex(
          p => p._id === process._id
        );

        fromStepProcesses.splice(processIndex, 1);
        toStepProcesses.unshift(process);
      }
    },

    // MODAL
    setModalType(event) {
      if (
        event.type !== 'modal-process-form-deal' ||
        (event.type === 'modal-process-form-deal' &&
          event.process._company._id === bluecodersCompanyId)
      ) {
        this.isModalOpen = true;
        this.modalProcess = event.process;
        this.modalType = event.type;
        this.$router.replace({
          query: {
            ...this.$route.query,
            typing: true,
          },
        });
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.$router.replace({
        query: {
          ...this.$route.query,
          typing: undefined,
        },
      });
    },
    async failedProcess(id) {
      const step = getStepNameByProcessStatus(this.process.status);
      const updatedProcess = await processController.move(id, 'failed');

      this.updatePanel(updatedProcess, step);
    },
    async unfailedProcess(id) {
      const step = getStepNameByProcessStatus(this.process.status);
      const updatedProcess = await processController.move(id, 'unfailed');

      this.updatePanel(updatedProcess, step);
    },
    updatePanel(updatedProcess, step) {
      this.$emit('profile-updated', {
        processState: updatedProcess._coder.processState,
        coachProcessState: updatedProcess._coder.coachProcessState,
      });
      this.$emit('process-update', {
        updatedProcess: updatedProcess,
        stepName: step,
      });
    },
  },
  watch: {
    'profile._id': {
      handler() {
        this.getProcesses();
      },
      immediate: true,
    },
    '$route.query': {
      handler(to, from = {}) {
        const isDifferentProcessStarts =
          to['process-starts'] !== from['process-starts'];
        const isDifferentProcessEnds =
          to['process-ends'] !== from['process-ends'];
        const isDifferentSelectedCoaches =
          to['selected-coaches'] !== from['selected-coaches'];

        if (
          isDifferentProcessEnds ||
          isDifferentProcessStarts ||
          isDifferentSelectedCoaches
        ) {
          this.getProcesses();
        }
      },
      deep: true,
    },
    isDragging() {
      if (!this.isDragging && this.isDraggingForbidden) {
        this.isDraggingForbidden = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$border: 1px solid rgba($color-primary, 0.3);

.panel-hunt-process-rpo {
  width: 100%;
  padding: 10px;

  &__wrapper {
    flex-direction: column;
    border: $border;
    width: 100%;
    border-radius: $border-radius-s;
    height: 100%;
    overflow: auto;
  }

  &__body {
    overflow: auto;
    height: 100%;
    border-bottom: $border;
  }

  &__step {
    width: 100%;
    border-right: $border;

    &:last-of-type {
      border-right: 0;
    }
  }

  &__button {
    background-color: transparent;
    width: calc(100% - 20px);
    margin: 10px;
  }

  &__modal {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
