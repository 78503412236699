<script lang="ts" setup>
  import { CampaignAction } from '@/domains/models/Campaign';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
  import { useI18n } from '@/i18n/i18n';
  import { capitalize } from '@/utils/stringTransform';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';

  defineProps<{
    action: Partial<Pick<CampaignAction, '_id'>> & Pick<CampaignAction, 'name' | 'icon'>
    isDraggable?: boolean;
    isActive?: boolean;
  }>();

  const { t } = useI18n();
  const emit = defineEmits<{
    (e: 'delete'): void;
  }>();

  const settingsOptions = [
    // {
    //   key: 'duplicate',
    //   label: t('generics.edit'),
    //   icon: 'edit-3',
    // },
    {
      key: 'delete',
      label: t('generics.delete'),
      icon: 'trash',
      event: () => {
        emit('delete');
      },
    },
  ];
</script>

<template>
  <button
    :class="{ 'border-blue-500 text-blue-500': isActive, 'border-blue-200 text-blue-800': !isActive }"
    class="items-center gap-2.5 rounded-[10px] border border-blue-200 bg-white px-[20px] py-[14px]">
    <span
      :class="`icon-${action.icon}`"
      class="flex size-[30px] shrink-0 items-center justify-center rounded-full bg-[#dbe2ff]">
    </span>
    <p class="flex grow text-left font-bold first-letter:capitalize">{{ action.name }}</p>
    <BcDropdown
      :close-dropdown-at-select="false"
      :force-direction="true"
      :is-scrollable="false"
      :options="settingsOptions"
      position="bottom-left">
      <template #label="{isDropdownOpen}">
        <button
          v-if="isDraggable"
          :class="{ '!text-blue-500': isDropdownOpen}"
          class="icon-more-vertical text-blue-800">
        </button>
      </template>
      <template #option="{option}">
        <BcDropdownItem
          :icon="option.icon"
          :text="capitalize($t(option.label))"
          class="option is-expanded"
          @click.native="option.event()">
        </BcDropdownItem>
      </template>
    </BcDropdown>
  </button>
</template>

<style lang="scss" scoped>
  .option {
    &:deep(.bc-dropdown-item__text) {
      @apply text-red-500 #{!important};
    }

    &:deep(.bc-dropdown-item__icon) {
      @apply text-red-500 #{!important};
    }
  }
</style>
