<template>
  <div class="panel-organization-configuration-features">
    <panel-organization-configuration-item title="Features">
      <div class="panel-organization-configuration-features__content">
        <div
          class="panel-organization-configuration-features__item"
          v-for="(_feature, key) of featuresFiltred"
          :key="key">
          <div
            class="panel-organization-configuration-features__item">
            <div>
              {{ mapFeatureNames[key] }}
            </div>
            <bc-toggle :id="key" type="default" v-model="features[key]"></bc-toggle>
          </div>
        </div>
        <div class="panel-organization-configuration-features__item">
          <div>Payé</div>
          <bc-toggle id="paid-toggle" type="default" v-model="paid"></bc-toggle>
        </div>
        <div v-if="!paid" class="panel-organization-configuration-features__item">
          <div>TrialEndsAt</div>
          <input type="date" v-model="trialEndsAt" @change="updateTrialsEndDate" />
        </div>
      </div>
    </panel-organization-configuration-item>
  </div>
</template>

<script>
import moment from 'moment/min/moment-with-locales';
import {
  updateConfigurationOrganizationByKey,
  editOrganization,
  mapFeatureNames,
} from '@/api/organizations';
import organizationsMacros from '@/macros/organizations';

import BcToggle from '@/ui-kit/components/BcToggle/BcToggle';
import PanelOrganizationConfigurationItem from './PanelOrganizationConfigurationItem';

export default {
  name: 'panel-organization-configuration-features',
  components: {
    BcToggle,
    PanelOrganizationConfigurationItem,
  },
  props: {
    profile: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      features: {},
      paid: false,
      mapFeatureNames,
      featuresFilters: ['extensionSkillsCrawl', 'career'],
      trialEndsAt: '',
    };
  },
  mounted() {
    if (this.profile?.configuration?.features) {
      this.features = this.profile.configuration.features;
    }
    if (this.profile?.subscription?.type === organizationsMacros.type.paid) {
      this.paid = true;
    }
    if (this.profile.subscription.trialEndsAt) {
      this.trialEndsAt = this.profile.subscription.trialEndsAt.split('T')[0];
    }
  },
  computed: {
    featuresFiltred() {
      const features = { ...this.features };
      this.featuresFilters.forEach(key => {
        delete features[key];
      });
      return features;
    }
  },
  methods: {
    async updateFeaturesConfig(features) {
      try {
        await updateConfigurationOrganizationByKey({
          key: 'features',
          organizationId: this.profile._id,
          ...features,
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async resetFeatures() {
      const features = Object.keys(this.features).reduce((acc, value) => {
        return { ...acc, [value]: false };
      }, {});
      this.features = features;
    },
    async handlePaid(paid) {
      try {
        await editOrganization({
          id: this.profile._id,
          data: {
            subscription: {
              ...this.profile.subscription,
              type: paid ? organizationsMacros.type.paid : organizationsMacros.type.free,
              startedAt: paid ? new Date() : undefined,
            },
          },
        });
        if (!paid) {
          await this.resetFeatures();
        }
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async updateTrialsEndDate(e) {
      try {
        await editOrganization({
          id: this.profile._id,
          data: {
            subscription: {
              ...this.profile.subscription,
              type: organizationsMacros.type.free,
              startedAt: undefined,
              trialEndsAt: moment(e.target.value).toDate(),
            }
          }
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },

  },
  watch: {
    features: {
      handler(newValue) {
        this.updateFeaturesConfig(newValue);
      },
      deep: true,
    },
    paid(newValue) {
      this.handlePaid(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-organization-configuration-features {
  &__content {
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  &__item {
    width: 100%;
    justify-content: space-between;
  }
}
</style>
