<template>
  <transition
    appear
    name="panel">
    <overlay
      :z-index="200">
      <div
        ref="panel"
        :class="panelContainerClass"
        class="panel__container">
        <component
          :is="panelProfile"
          class="panel__component">
        </component>
      </div>
    </overlay>
  </transition>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Overlay from '@/components/UIKit/Overlay';
  import PanelPokeProfile from '@/components/Panel/PanelPoke/PanelPokeProfile';
  import PanelError from '@/components/Panel/PanelError';
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import PanelCoderProfile from '@/components/Panel/PanelCoder/PanelCoder.vue';
  import PanelJobProfile from '@/components/Panel/PanelJob/PanelJobProfile';
  import PanelJobEdit from '@/components/Panel/PanelJob/PanelJobEdit/PanelJobEdit';
  import PanelCoachProfile from '@/components/Panel/PanelCoach/PanelCoachProfile';
  import PanelCoachEdit from '@/components/Panel/PanelCoach/PanelCoachEdit/PanelCoachEdit';
  import PanelChat from '@/components/Panel/PanelChat/PanelChat';
  import PanelFavoriteList from '@/components/Panel/PanelFavoriteList/PanelFavoriteList';
  import PanelProcessKanban from '@/components/Panel/PanelProcessKanban/PanelProcessKanban';
  import PanelCompany from '@/components/Panel/PanelCompany/PanelCompany';
  // getters
  import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
  import PanelProcessCreate from '@/components/Panel/PanelProcess/PanelProcessCreate';
  import PanelProcessProfile from '@/components/Panel/PanelProcess/PanelProcessProfile';
  import PanelCustomJobs from '@/components/Panel/PanelCustomJobs/PanelCustomJobs';
  import PanelOrganization from '@/components/Panel/PanelOrganization/PanelOrganization';
  import PanelTag from '@/components/Panel/PanelTag/PanelTag/PanelTag';
  import PanelTagReplace from '@/components/Panel/PanelTag/PanelTagReplace/PanelTagReplace';
  import PanelTagPush from '@/components/Panel/PanelTag/PanelTagPush/PanelTagPush';
  import PanelTagCreate from '@/components/Panel/PanelTag/PanelTagCreate/PanelTagCreate';
  import PanelCoderCreate from '@/components/Panel/PanelCoder/PanelCoderCreate.vue';
  import PanelCompanyCreate from '@/components/Panel/PanelCompany/PanelCompanyCreate.vue';
  import PanelOpportunity from '@/components/Panel/PanelOpportunity/PanelOpportunity.vue';
  import PanelOpportunityCreate
    from '@/components/Panel/PanelOpportunity/PanelOpportunityCreate.vue';
  import PanelReminder from '@/components/Panel/PanelReminder/PanelReminder.vue';


  const panelSizes = {
    small: ['tags-replace', 'tags-push', 'tags-create'],
    medium: ['favorite-coder-list', 'favorite-job-list', 'favorite-company-list', 'favorite-contact-list', 'favorite-coder-and-contact-list'],
  };
  /**
   * The panel which is called when the $route is nested
   */
  export default {
    name: 'panel',
    components: {
      Overlay,
      coders: PanelCoderProfile,
      jobs: PanelJobProfile,
      hunt: PanelOpportunity,
      opportunities: PanelCustomJobs,
      partners: PanelCompany,
      companies: PanelCompany,
      coaches: PanelCoachProfile,
      process: PanelProcessProfile,
      'panel-error': PanelError,
      'my-profile': PanelCoachProfile,
      'edit-profile': PanelCoachEdit,
      'edit-coach': PanelCoachEdit,
      pokes: PanelPokeProfile,
      // 'edit-coder': PanelCoderEdit,
      'create-coder': PanelCoderCreate,
      'edit-job': PanelJobEdit,
      'create-job': PanelJobEdit,
      'create-contact': PanelCoderCreate,
      'create-company': PanelCompanyCreate,
      'favorite-coder-list': PanelFavoriteList,
      'favorite-job-list': PanelFavoriteList,
      'favorite-company-list': PanelFavoriteList,
      'favorite-contact-list': PanelFavoriteList,
      'favorite-coder-and-contact-list': PanelFavoriteList,
      'create-process': PanelProcessCreate,
      chat: PanelChat,
      'suggestions-coders': PanelCoderProfile,
      organizations: PanelOrganization,
      'create-custom-jobs': PanelOpportunityCreate,
      // 'edit-custom-jobs': PanelCustomJobsEdit,
      tags: PanelTag,
      'tags-replace': PanelTagReplace,
      'tags-push': PanelTagPush,
      'tags-create': PanelTagCreate,
      'process-kanban': PanelProcessKanban,
    },
    /**
     * We import the closePanel Function to give it to the overlay props
     */
    mixins: [PanelNavigation],
    created() {
      this.setWindowWidth();
      /**
       * Add a listener to determinate the windowWidth to set if the Panel should be mobile style of desktop style
       */
      window.addEventListener('resize', this.setWindowWidth);
      /**
       *  Create an event listener to handle an alert on edit panel
       */
      window.addEventListener('beforeunload', this.handler);
    },
    mounted() {
      const panel = document.querySelector('.panel__component');

      disableBodyScroll(panel);
    },
    computed: {
      ...mapGetters({
        profileId: 'user/profileId',
      }),
      /***
       * The component inside the panel will be defined by the route.query.type
       */
      panelProfile() {
        return this.$options.components[this.$route.query.type] ? this.$route.query.type : 'panel-error';
      },
      panelContainerClass() {
        if (panelSizes.small.includes(this.$route.query.type)) {
          return 'panel__container-small';
        }

        if (panelSizes.medium.includes(this.$route.query.type)) {
          return 'panel__container-medium';
        }

        return 'panel__container-large';
      },
    },
    watch: {
      panelProfile(value) {
        if (!['create-process', 'create-custom-jobs'].includes(value)) {
          this.clearSessionStorage();
        }
      },
    },
    beforeDestroy() {
      /**
       * Remove the listener before destroying the component
       */
      window.removeEventListener('resize', this.setWindowWidth);

      window.removeEventListener('beforeunload', this.handler);

      this.$store.dispatch('setPanelProfile', {});

      this.clearSessionStorage();

      clearAllBodyScrollLocks();
    },
    methods: {
      setWindowWidth() {
        this.$store.dispatch('setWindowWidth', window.innerWidth);
      },
      clearSessionStorage() {
        sessionStorage.removeItem(`selected-company-${this.profileId}`);
        sessionStorage.removeItem(`selected-custom-job-${this.profileId}`);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel__container {
    position: absolute;
    top: 0;
    left: 0;
    background: $color-gray-mystic;
    height: 100%;
    width: 100%;
    overflow: auto;
    flex-direction: column;
    z-index: 100;
    transition: 0.3s;
    @include bp('tablet') {
      max-height: 100vh;
      left: auto;
      right: 0;
      overflow: hidden;
    }

    &-large {
      @include bp('desktop') {
        max-width: 80rem;
      }

      @include bp('large-desktop') {
        max-width: calc(100% - 15rem)
      }
    }

    &-medium {
      @include bp('desktop') {
        max-width: 40rem;
      }
    }

    &-small {
      @include bp('desktop') {
        max-width: 28rem;
      }
    }
  }

  .panel-enter {
    transition: 0.3s;
    opacity: 0;
  }

  .panel-leave-active {
    transition-delay: 0.15s;
    opacity: 0;
  }

  .panel-enter .panel__container,
  .panel-leave-active .panel__container {
    transform: translateX(100%);
  }
</style>
