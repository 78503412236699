<script setup lang="ts">
interface Props {
  defaultImageUrl: string;
  url?: string;
  alt?: string;
}
const props = withDefaults(defineProps<Props>(), {
  alt: 'description of image',
  url: '',
});

function replaceByDefault(event: Event) {
  const target = event.target as HTMLImageElement;
  target.src = props.defaultImageUrl;
}
</script>

<template>
  <img
    :src="url"
    :alt="alt"
    class="h-full w-full"
    @error="replaceByDefault"
  />
</template>
