<template>
  <views class="favorite-list col-xs-12 is-align-items-center">
    <views-tabs :tabs="tabs">
      <template #header>
        <button-back class="h-[36px] self-center" @click="onGoBack">
          {{ $t('generics.back-to-lists') }}
        </button-back>
      </template>
      <template #content>
        <div class="favorite-list__buttons gap-3">
          <ButtonEmail
            v-if="isCoder || isContact"
            @click="setListId"
          />
          <bc-button
            icon-left="edit-3"
            @click="openFavoriteListEdit">
            {{ $t('generics.edit-the-list') }}
          </bc-button>
        </div>
      </template>
    </views-tabs>
    <views-container>
      <searchbar-coders
        v-if="$route.query['list-type'] === 'coder'"
        :results-count="queryCount"
        :list-id="$route.query['list-id']"
        coders-searchbar>
      </searchbar-coders>
      <router-view></router-view>
      <searchbar-partners
        v-if="$route.query['list-type'] === 'company'"
        :results-count="queryCount"
        :list-id="$route.query['list-id']"
        partners-searchbar>
      </searchbar-partners>
      <bc-spinner
        v-if="!isLoaded"
        class="favorite-list__spinner">
      </bc-spinner>
      <div
        v-else
        class="is-align-items-center is-column">
        <coders-grid
          v-if="$route.query['list-type'] === 'coder'"
          :is-checked="isAllCardsSelected"
          class="hidden-xs"
          @checked="selectAllCards">
        </coders-grid>
        <companies-grid
          v-else-if="$route.query['list-type'] === 'company'"
          :is-checked="isAllCardsSelected"
          class="hidden-xs"
          @checked="selectAllCards">
        </companies-grid>
        <ContactsGrid
          v-if="$route.query['list-type'] === 'contact'"
          :is-checked="isAllCardsSelected"
          class="hidden-xs"
          @checked="selectAllCards">
        </ContactsGrid>
        <card-list
          v-if="$route.query['list-type'] === 'coder' || $route.query['list-type'] === 'company' || $route.query['list-type'] === 'contact'"
          :cards="cards"
          :is-loaded="true"
          class="pagination__card-list">
          <template #card="{ card }">
            <card
              :card-content="card"
              :card-type="cardTypeName">
              <component
                :is="cardComponentName"
                :card-content="card"
                :is-selected="getIsSelectedStatus(card._id)"
                context="list"
                @checked="setSelectedCards($event, card)">
              </component>
            </card>
          </template>
        </card-list>
        <card-list
          v-else
          :cards="cards"
          :is-loaded="true"
          :response-obj="cardTypeName"
          class="pagination__card-list">
        </card-list>
      </div>
      <bc-pagination
        :current.sync="currentPage"
        :per-page="36"
        :total="queryCount"
        class="favorite-list__pagination">
      </bc-pagination>
      <multiselect-footer
        v-if="selectedCards.length"
        class="is-fixed is-bottom is-left is-full-width is-justify-content-center is-align-items-center is-column">
      </multiselect-footer>
    </views-container>
  </views>
</template>

<script lang="ts">
  import { mapActions, mapGetters, mapState } from 'vuex';
  import Views from '@/components/Views/Views';
  import TitleSection from '@/components/Title/TitleSection';
  import ButtonDefault from '@/legacy/ui-kit/components/Button/BcButton';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import BcPagination from '@/ui-kit/components/BcPagination';
  import CardList from '@/components/CardList/CardList';
  import SearchbarCoders from '@/components/Searchbar/SearchbarCoders/SearchbarCoders';
  import SearchbarJobs from '@/components/Searchbar/SearchbarJobs/SearchbarJobs';
  import SearchbarPartners from '@/components/Searchbar/SearchbarPartners/SearchbarPartners';
  import SearchbarContacts from '@/components/Searchbar/SearchbarContacts/SearchbarContacts';

  import locations_helpers from '@/common-old/utils/locations_helpers';
  import salary from '@/common-old/macros/salary';

  import moment from 'moment/min/moment-with-locales';
  import CodersGrid from '@/components/Coders/CodersGrid';
  import ContactsGrid from '@/components/Contacts/ContactsGrid';
  import CompaniesGrid from '@/components/Companies/CompaniesGrid';
  import MultiselectFooter from '@/components/Multiselect/MultiselectFooterLegacy';
  import Card from '@/components/Card/Card';
  import CardCoders from '@/components/Card/CardCoders';
  import CardContacts from '@/components/Card/CardContacts';
  import CardCompanies from '@/components/Card/CardCompanies';
  import MultiselectMixin from '@/mixins/Multiselect/multiselect';
  import ViewsTopbar from '@/components/Views/ViewsTopbar';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import ViewsContainer from '@/components/Views/ViewsContainer';
  import ViewsTabs from '@/components/Views/ViewsTabs';
  import ButtonBack from '@/components/Button/ButtonBack';
  import ButtonEmail from '@/components/Button/ButtonEmail';
  import { searchParamToString } from '@/utils/typeConversion';
  import { codersLookup, contactsLookup } from '@/api/coders';

  const typeMap = {
    coder: {
      type: 'coders',
      card: 'card-coders',
      searchbar: 'searchbar-coders',
      list: 'favorite-coder-list',
    },
    company: {
      type: 'companies',
      card: 'card-companies',
      searchbar: 'searchbar-partners',
      list: 'favorite-company-list',
    },
    contact: {
      type: 'contacts',
      card: 'card-contacts',
      searchbar: 'searchbar-contacts',
      list: 'favorite-contact-list',
    },
  };

  export default {
    name: 'favorite-list',
    components: {
      Views,
      ViewsContainer,
      ViewsTabs,
      BcButton,
      ViewsTopbar,
      CardCoders,
      CardCompanies,
      CardContacts,
      Card,
      MultiselectFooter,
      CompaniesGrid,
      CodersGrid,
      ContactsGrid,
      ButtonDefault,
      SearchbarCoders,
      TitleSection,
      BcSpinner,
      BcPagination,
      CardList,
      SearchbarJobs,
      SearchbarPartners,
      SearchbarContacts,
      ButtonBack,
      ButtonEmail,
    },
    mixins: [MultiselectMixin],
    data() {
      return {
        isLoaded: true,
        currentPage: parseInt(this.$route.query.page) || 1,
      };
    },
    computed: {
      ...mapState({
        cards: state => state.card.cards,
        sort: state => state.card.sort,
        queryCount: state => state.card.count,
        globalMetadata: state => state.user.metadata,
        technos: state => state.tags.technos,
      }),
      ...mapGetters({
        lists: 'myVisibleLists',
      }),
      tabs() {
        return [
          {
            key: 'favorite-list-name',
            label: this.list.name || this.formatParamsName(this.$route.params.name) || '-',
            to: { path: `/favorite-list/${this.$route.params.name}`, query: this.$route.query },
            count: this.metadata,
          },
        ];
      },
      salaryRange() {
        return {
          ...this.$route.query.contracts && this.$route.query.contracts === 'freelance' && {
            salaryMin: salary.salaryMinFilterFreelance,
            salaryMax: salary.salaryMaxFilterFreelance,
          },
          ...this.$route.query.contracts && this.$route.query.contracts === 'cdi' && {
            salaryMin: salary.salaryMin,
            salaryMax: salary.salaryMax,
          },
        };
      },
      parameters() {
        return {
          page: this.$route.query.page ? this.$route.query.page : 1,
          ...(this.$route.query.status !== 'all-status' || this.$route.query.status !== 'all') && { status: this.$route.query.status },
          ...this.$route.query['list-id'] && { listId: this.$route.query['list-id'] },
          ...this.$route.query['job-id'] && { jobId: this.$route.query['job-id'] },
          ...this.$route.query.professions && { professions: this.$route.query.professions },
          ...this.$route.query.technos && { technos: this.$route.query.technos },
          ...this.$route.query.search && { query: this.$route.query.search },
          ...this.$route.query.contracts && this.$route.query.contracts !== 'all' && (this.$route?.query?.['list-type'] !== 'job' || !this.$route.name?.includes('Jobs')) && { contracts: this.$route.query.contracts },
          ...this.$route.query.contracts && this.$route.query.contracts !== 'all' && (this.$route?.query?.['list-type'] === 'job' || this.$route.name?.includes('Jobs')) && { contract: this.$route.query.contracts },
          ...this.$route.query.experiences && { experiences: this.$route.query.experiences },
          ...this.$route.query.roles && { roles: this.$route.query.roles },
          ...this.$route.query.domains && { domains: this.$route.query.domains },
          ...this.$route.query.locations && { locations: JSON.stringify(locations_helpers.getLocationsId(this.$route.query.locations)) },
          ...this.$route.query.distance && { distance: this.$route.query.distance },
          ...this.$route.query.remotes && { remotes: this.$route.query.remotes },
          ...this.$route.query.phone && { phone: true },
          ...this.$route.query.cv && { cv: true },
          ...this.$route.query.rating && { lastRating: Number(this.$route.query.rating) },
          ...this.$route.query['last-coach-activity-from'] && { lastCoachActivityFrom: moment(this.$route.query['last-coach-activity-from'], 'D-MM-YYYY').toDate() },
          ...this.$route.query['last-coach-activity-to'] && { lastCoachActivityTo: moment(this.$route.query['last-coach-activity-to'], 'D-MM-YYYY').toDate() },
          ...this.$route.query['salary-min'] && Number(this.$route.query['salary-min']) > this.salaryRange.salaryMin && { 'salaryMin': this.$route.query['salary-min'] },
          ...this.$route.query['salary-max'] && Number(this.$route.query['salary-max']) < this.salaryRange.salaryMax && { 'salaryMax': this.$route.query['salary-max'] },
          ...this.sort.field !== 'default' && this.sort.direction !== 'default' && {
            'sortField': this.sort.field,
            'sortDirection': this.sort.direction,
          },
        };
      },
      list() {
        return ((this.lists || []).find(list => (list || {})._id === this.$route.query['list-id']) || {});
      },
      metadata() {
        return this.globalMetadata === this.queryCount ? this.globalMetadata : this.queryCount;
      },
      listType() {
        return searchParamToString(this.$route.query['list-type']);
      },
      cardTypeName() {
        return typeMap[this.listType]?.type;
      },
      cardComponentName() {
        return typeMap[this.listType]?.card;
      },
      isCoder() {
        return this.$route.query['list-type'] === 'coder';
      },
      isContact() {
        return this.$route.query['list-type'] === 'contact';
      }
    },
    created() {
      this.setCount(1);
      this.getResources();
    },
    beforeDestroy() {
      this.resetSelectedCards();
    },
    methods: {
      ...mapActions([
        'setEditedProfile',
        'resetCards',
        'getCards',
        'setCount',
        'getCountCards',
      ]),
      ...mapActions({
        setSelectedCoders: 'emails/setSelectedCoders',
        setEmailIsLoading: 'emails/setIsLoading',
      }),
      goNewCoder() {
        this.setEditedProfile({});

        this.$router.push({
          name: `${this.$route.name}Panel`,
          params: {
            id: 'new-coder',
          },
          query: {
            ...this.$route.query,
            type: 'create-coder',
          },
        });
      },
      async getResources() {
        this.isLoaded = false;

        try {
          this.resetCards();
          const cardsParameters = {
            coder: {
              page: this.$route.query.page,
              call: 'coders/lookup',
              parameters: this.parameters,
              responseKeyName: 'coders',
            },
            job: {
              page: this.$route.query.page,
              call: 'jobs/lookup',
              parameters: this.parameters,
              responseKeyName: 'jobs',
            },
            company: {
              page: this.$route.query.page,
              call: 'partners/lookup',
              parameters: this.parameters,
              responseKeyName: 'companies',
            },
            contact: {
              page: this.$route.query.page,
              call: 'contacts/lookup',
              parameters: this.parameters,
              responseKeyName: 'coders',
            },
          };
          const params = cardsParameters[this.listType];
          const response = await this.getCards(params);

          if (params.call === 'coders/lookup') {
            const currentCount = response.data?.count;
            if (this.currentPage == 1 || this.queryCount <= currentCount) {
              const { data } = await this.getCountCards({
                page: this.currentPage,
                call: 'coders/lookup/count',
                parameters: this.parameters,
                responseKeyName: 'coders',
              });
              this.setCount(data.count);
            }
          }
          if (params.call === 'contacts/lookup') {
            const currentCount = response.data?.count;
            if (this.currentPage == 1 || this.queryCount <= currentCount) {
              const { data } = await this.getCountCards({
                page: this.currentPage,
                call: 'contacts/lookup/count',
                parameters: this.parameters,
                responseKeyName: 'coders',
              });
              this.setCount(data.count);
            }
          }

          this.isLoaded = true;
        } catch (error) {
          this.$emit('error', error);

          this.isLoading = true;
        }
      },
      openFavoriteListEdit() {
        this.$router.push({
          name: this.$route.name.includes('Panel') ? this.$route.name : `${this.$route.name}Panel`,
          params: {
            ...this.$route.params,
            id: this.$route.query['list-id'],
          },
          query: {
            ...this.$route.query,
            type: typeMap[this.listType].list,
            subtype: 'edit-favorite-list',
          },
        });
      },
      onGoBack() {
        const path = '/favorite-lists';
        this.$router.push({
          path,
          params: { name: undefined },
          query: {
            'list-type': this.listType,
          },
        });
      },
      formatParamsName(name) {
        return name.split('-').map(str => `${str[0]?.toUpperCase?.() || ''}${str.substring(1)}`).join(' ');
      },
      async setListId() {
        try {
          this.setEmailIsLoading(true);

          let response = null;
          if (this.listType === 'contact') {
            response = await contactsLookup({ listId: this.$route.query['list-id'], withoutPagination: true });
          } else if (this.listType === 'coder') {
            response = await codersLookup({ listId: this.$route.query['list-id'], withoutPagination: true });
          }
          const list = response?.data;
          if (list?.coders?.length) {
            this.setSelectedCoders(list.coders)
          }

          this.setEmailIsLoading(false);
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err?.message,
          });
          this.setEmailIsLoading(false);
        }
      },
    },
    watch: {
      $route: {
        handler(to = {}, from = {}) {
          // const isDifferentQueries = Object.keys(to.query).some(key => to.query[key] !== from.query[key]);
          const isOpeningPanel = Boolean(!(from.params || {}).id && (to.params || {}).id);
          const isClosingPanel = Boolean((from.params || {}).id && !(to.params || {}).id);
          const isInPanel = Boolean((from.params || {}).id && (to.params || {}).id);
          const pageChange = from.query.page !== to.query.page;

          if (!isOpeningPanel && !isClosingPanel && (!isInPanel || (isInPanel && pageChange))) {
            this.getResources();
            this.currentPage = parseInt(to.query.page) || 1;
          }
        },
        deep: true,
      },
      sort() {
        this.currentPage = 1;
        this.getResources();
      },
      currentPage(newPage) {
        this.$router.push({ ...this.$route, query: { ...this.$route.query, page: newPage } });

        this.$store.dispatch('setSelectedCards', []);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .favorite-list {
    @include page_grid();

    &__title {
      min-height: 30px;
    }

    &__subtitle {
      position: relative;
      top: 1px;
      color: #3D5476;
    }

    &__buttons {
      margin-left: auto;
    }


    &__searchbar {
      margin-bottom: 20px;
    }

    &__filters {
      margin-bottom: 20px;
    }

    &__button {
      position: absolute;
      right: 0;
      flex-shrink: 0;
    }

    &__pagination {
      margin: 20px 0;
    }

    &__spinner {
      margin: 100px 0;
    }

    &__card-list {
      margin-bottom: 100px;
    }

    &__spinner, &__pagination, &__card-list {
      justify-content: center;
    }
  }
</style>
