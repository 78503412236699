<template>
  <div
    v-click-outside="closeDropdown"
    :class="{'button-dropdown--opened': isOpenDropdown}"
    :style="{ backgroundColor: (dropdownItems[dropdownActive] || {}).activeBackgroundColor }"
    class="button-dropdown"
    @click="setIsOpenDropdown">
    <div
      v-if="dropdownText"
      class="button-dropdown__wrapper">
      <p class="button-dropdown__text" :style="{ color: (dropdownItems[dropdownActive] || {}).textColor }">
        {{ dropdownText }}
      </p>
      <div
        :class="[{ 'icon-chevron-left--reverse': isOpenDropdown && iconType === 'icon-chevron-left' }, iconType]"
        class="button-dropdown__icon"
        :style="{ color: (dropdownItems[dropdownActive] || {}).textColor }"
      >
      </div>
    </div>
    <div
      v-else-if="iconType"
      class="button-dropdown__wrapper"
    >
      <div
        class="button-dropdown__icon"
        :class="`icon-${iconType}`"
        :style="{ color: (dropdownItems[dropdownActive] || {}).textColor }"
      >
      </div>
    </div>
    <transition-dropdown class="button-dropdown__transition">
      <div
        v-if="isOpenDropdown"
        class="button-dropdown__dropdown">
        <ul class="button-dropdown__list">
          <li
            v-for="(item, index) in dropdownItems"
            :key="index"
            :class="{ 'button-dropdown__item--hidden': item.disabled }"
            :style="{color: item.textColor}"
            class="button-dropdown__item"
            @click="selectItem(item, index), setIsOpenDropdown(), item.action()">
            {{ item.name }}
          </li>
        </ul>
      </div>
    </transition-dropdown>
  </div>
</template>

<script>

  import TransitionDropdown from '@/components/UIKit/Transition/TransitionDropdown';
  import { mapState } from 'vuex';

  export default {
    components: {
      TransitionDropdown,
    },
    name: 'button-dropdown',
    data() {
      return {
        isOpenDropdown: false,
      };
    },
    methods: {
      setIsOpenDropdown() {
          return this.isOpenDropdown = !this.isOpenDropdown;
      },
      closeDropdown() {
        this.isOpenDropdown = false;
      },
      selectItem(item, index) {
        if (this.dropdownActive !== index) {
          this.$emit('select', { item, index });
        }
        this.closeDropdown();
      },
    },
    computed: {
      ...mapState({
        profileRole: store => store.user.profile.role,
      }),
    },
    props: {
      iconType: {
        type: String,
        default: 'icon-chevron-left',
      },
      dropdownText: {
        type: String,
        default: '',
      },
      dropdownActive: {
        type: Number,
        default: 0,
      },
      dropdownItems: {
        type: Array,
        default: () => ({}),
      },
      borderRadius: {
        type: String,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .button-dropdown {
    position: relative;
    min-width: 35px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    border: 1px solid $color-blue-medium;

    //&--opened {
    //  border-radius: 5px;
    //}

    &:hover {
      cursor: pointer;
    }

    &__wrapper {
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 10px;
    }

    &__text {
      font-size: $font-size-s;
      color: $color-white;
    }

    &__icon {
      color: $color-white;
      font-size: $font-size-xl;
      transition: 0.3s ease;
    }

    &__dropdown {
      position: absolute;
      top: calc(100% + 10px);
      right: 1px;
      min-width: 100%;
    }

    &__list {
      flex-direction: column;
      align-items: center;
      background-color: white;
      border-radius: 5px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, .25);
      overflow: hidden;
      z-index: 2;
      height: 100%;
      width: 100%;
    }

    &__item {
      text-align: center;
      font-size: $font-size-xs;
      color: $color-blue-light-gray;
      padding: 11px 20px;
      width: 100%;
      white-space: nowrap;
      list-style: none;

      &:not(:first-child) {
        border-top: 1px solid $color-gray-mystic;
      }

      &:hover {
        color: $color-white !important;
        background: $color-blue !important;
      }

      &--hidden {
        display: none;
      }
    }

    &__transition {
      z-index: 2;
    }
  }

  .icon-chevron-left {
    transform: rotate(270deg);
    position: absolute;
    right: 10px;
    font-size: $font-size-xxs;

    &--reverse {
      transition: 0.3s;
      transform: rotate(90deg);
    }
  }
</style>
