<template>
  <tag-default
    :bc-background-color="typeStyle.backgroundColor"
    :bc-background-color-opacity="typeStyle.backgroundColorOpacity"
    :bc-cross="canClose"
    :bc-cross-color="typeStyle.crossColor"
    :bc-icon="showIcon && typeStyle.icon || customIcon"
    :bc-icon-color="typeStyle.iconColor"
    :bc-text-color="typeStyle.textColor"
    :bc-text-color-opacity="typeStyle.textColorOpacity"
    :class="{ tag__close: canClose }"
    bc-icon-size="12px"
    class="tag"
    @click.native.prevent.stop="$emit('click', $event)">
    <div class="flex items-center gap-2">
      <div v-if="avatar" class="absolute ml--2 pl-1">
        <bc-avatar
          :src="avatar"
          size="xxs"
          @error="$emit('error', $event)"/>
      </div>
      <slot />
      <div 
      v-if="!$slots.default"
      :class="{ 'ml-4 items-center': avatar }">
        {{ formatedText }} {{ unit ? unit : '' }}
      </div>
    </div>
  </tag-default>
</template>

<script>
  import { mapState } from 'vuex';
  import searchbar from '@/common-old/mixins/Searchbar/searchbar';
  import TagDefault from '@/legacy/ui-kit/components/Tag/BcTag';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';

  export default {
  name: 'tag',
  components: {
    TagDefault,
    BcAvatar,
  },
  mixins: [searchbar],
  props: {
    text: {
      type: [String, Number],
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      default: '',
    },
    canClose: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: String,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    avatar: {
      type: [String, Object],
    },
    textLimit: {
      type: Number,
    },
    customIcon: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      windowWidth: (state) => state.app.windowWidth,
    }),
    isPanel() {
      return this.$route.params?.id;
    },
    limitText() {
      if (this.windowWidth < 1300) {
        return 6;
      }
      if (this.windowWidth < 1400) {
        return 8;
      }
      if (this.windowWidth < 1500) {
        return 10;
      }
      if (this.windowWidth < 1600) {
        return 12;
      }
      return 20;
    },
    formatedText() {
      const text = `${this.text} ${this.unit ? this.unit : ''}`;
      if (this.textLimit && text.length > this.textLimit) {
        return text.substring(0, this.textLimit) + '...';
      }
      if (this.windowWidth < 700 && this.type === 'location') {
        return text.substring(0, 10) + '...';
      }
      if (this.windowWidth < 1300 && this.type === 'location') {
        return text.substring(0, 20) + '...';
      }
      return text;
    },
    typeStyle() {
      if (this.tagsStyleMap[this.type]) {
        return this.tagsStyleMap[this.type];
      }
      if (this.type === 'emergency') {
        return this.tagsStyleMap[this.emergencyMap[this.text]];
      }
      return {
        backgroundColor: '#EDF0FF',
        backgroundColorOpacity: 1,
        icon: this.icon,
        iconColor: '#375276',
        crossColor: '#375276',
        textColor: '#375276',
        textColorOpacity: 1,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .tag {
    height: 20px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.03em;

    &__close:hover {
      cursor: pointer;
    }

    :deep() {
      .bc-tag__icon {
        margin-right: 5px !important;
      }
    }
  }
</style>
