<script setup lang="ts">
import { computed, ref, defineEmits, defineProps, withDefaults, onMounted, nextTick } from 'vue';
import { useStore } from '@/store';
import { uploadFile } from '@/api/upload';

import { useToast } from '@/ui-kit/components/BcToast';
import { useI18n } from '@/i18n/i18n';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcInput from '@/ui-kit/components/BcInput/BcInput';
import BcTextEditor from '@/ui-kit/components/BcTextEditor/BcTextEditor';
import EmailVariablesList from '@/components/Email/EmailVariablesList';
import EmailCopyAutocomplete from '@/components/Email/EmailCopyAutocomplete';
import ModalConfirm from '@/components/Modal/ModalConfirm';
import EmailAttachmentList from './EmailAttachmentList.vue';
import { EventData } from './EmailAttachment.vue';

type CC = {
  name?: string;
  email?: string;
  address?: string;
}

interface Props {
  template?: {
    _id: string;
    name: string;
    slug: string;
    senderName: string;
    subject: string;
    cc: CC[];
    body: string;
    default: boolean;
    activated: boolean;
    attachments: { filename: string; path: string; }[];
  }
  disabled: boolean;
}

interface ActionParams {
  icon: string;
  title: string;
  content: string;
  cancelText: string;
  confirmText: string;
  onConfirmAction: () => void,
}

const props = withDefaults(defineProps<Props>(), {
  template: undefined,
  disabled: false,
});

const emits = defineEmits<{
  (e: 'on-close'): void,
}>();

const store = useStore();
const toast = useToast();
const i18n = useI18n();

const isModalOpen = ref(false);
const isActionConfirmed = ref(false);
const isLoading = ref(false);
const files = ref<File[]>([]);
const uploadInputRef = ref();
const attachments = ref<{ filename: string; path: string; }[]>(props.template?.attachments || []);
const MAX_SIZE = 25_164_007;

const isEditing = computed(() => {
  return props.template?._id !== undefined;
});

const templatesVariables = computed(() => {
  return store.state.emails.templatesVariables;
});

const form = ref({
  templateName: '',
  senderName: '',
  subject: '',
  body: '',
  cc: [] as CC[],
});

onMounted(async() => {
  await store.dispatch('emails/getTemplatesVariables');

  // on edit
  if (isEditing.value && props.template) {
    form.value.templateName = props.template.name;
    form.value.subject = props.template.subject;
    form.value.body = props.template.body;
    form.value.cc = props.template.cc;
    return
  }

});

const isFormValid = computed(() => {
  return form.value.templateName && form.value.subject && form.value.body;
});

const getAttachments = async() => {
  let _attachments = [...attachments.value];
  if (files.value.length) {
    const promises = files.value.map(file => {
      const dataToSend = new FormData();
      dataToSend.append('file', file);
      return uploadFile({ file: dataToSend });
    });
    const results = await Promise.all(promises);
    _attachments = [..._attachments, ...results.map(result => ({
      filename: result.data.fileName,
      path: result.data.fileUrl,
    }))];
  }
  return _attachments;
}

const onSave = async() => {
  try {
    if (!isFormValid.value) {
      return;
    }
    isLoading.value = true;

    const attachementsMerged = await getAttachments();

    if (isEditing.value && props.template) {
      if (props.template.default && !isActionConfirmed.value) {
        nextTick(() => {
          isModalOpen.value = true;
        });
        return;
      }

      await store.dispatch('emails/editTemplate', {
        id: props.template._id,
        name: form.value.templateName,
        subject: form.value.subject,
        cc: form.value.cc,
        body: form.value.body,
        attachments: attachementsMerged,
      });
    } else {
      await store.dispatch('emails/createTemplate', {
        name: form.value.templateName,
        subject: form.value.subject,
        cc: form.value.cc,
        body: form.value.body,
        attachments: attachementsMerged,
      });
    }

    onClose();

  } catch (err: any) {
    toast.show({
      type: 'error',
      message: err?.response?.data?.message || err?.message,
    });
  } finally {
    isLoading.value = false;
    onCloseModal();
  }

}

const onUpload = () => {
  uploadInputRef.value.click();
}

const onUploadSelect = (e: any) => {
  const file = e.target.files[0];
  if (file.size > MAX_SIZE) {
    toast.show({
      type: 'error',
      message: i18n.t('email.attachment-limit'),
    });
    return;
  }
  files.value = [...files.value, file];
}

const onRemoveFile = ({ file, attachment }: EventData) => {
  if (file) {
    files.value = files.value.filter(f => f.name !== file.name && f.size !== file.size && f.lastModified !== file.lastModified);
  }
  if (attachment) {
    attachments.value = attachments.value.filter(a => a.path !== attachment.path);
  }
}

const onClose = () => {
  emits('on-close');
}

const onCloseModal = () => isModalOpen.value = false;

const onConfirmEdit = () => {
  isActionConfirmed.value = true;
  onSave();
}

const actionParams: Record<string, ActionParams> = {
  edit: {
    icon: '📝',
    title: 'Voulez-vous vraiment modifier ce template ?',
    content: 'Ce template est commun à tous les utilisateurs de votre espace. Voulez-vous réellement le modifier ?',
    cancelText: 'Non, annuler cette action',
    confirmText: 'Oui, modifier',
    onConfirmAction: onConfirmEdit,
  }
}

const onSelectCc = (option: CC) => {
  form.value.cc = [...form.value.cc, option];
}

const onRemoveCc = (option: CC) => {
  form.value.cc = [...form.value.cc.filter(opt => opt.address !== option.address)];
}

</script>

<template>
  <div class="email-template-form is-column w-full">
    <div class="is-column min-h-[85vh] p-5">
      <!-- Template name -->
      <div class="mt-2">
        <BcInput
          v-model="form.templateName"
          name="templateName"
          class="is-full-width"
          :label="$t('email.template-name')"
          :label-placeholder="false"
          :valid="!!form.templateName"
          :disabled="disabled || (template || {}).default"
          required
        />
      </div>

      <EmailVariablesList class="mt-4" :variables="templatesVariables" />

      <!-- CC -->
      <EmailCopyAutocomplete
        class="mt-5"
        :cc="form.cc"
        @on-select="onSelectCc"
        @on-remove="onRemoveCc"
      />

      <!-- Subject -->
      <div class="mt-4">
        <BcInput
          v-model="form.subject"
          name="subject"
          class="is-full-width"
          :label="$t('email.object')"
          :label-placeholder="false"
          :valid="!!form.subject"
          :disabled="disabled"
          required
        />
      </div>

      <div class="is-column mt-5">
        <BcTextEditor
          class="is-full-width"
          :label="$t('email.body')"
          :disabled="disabled"
          v-model="form.body"
          required
        />
        <div class="is-column">
          <BcButton
            class="mt-2"
            icon-left="attachment"
            type="outlined"
            @click="onUpload"
          >
            {{ $t('email.add-an-attachment') }}
          </BcButton>
          <input ref="uploadInputRef" type="file" multiple style="display: none;" @change="onUploadSelect" />
          <EmailAttachmentList
            class="mt-3"
            :attachments="attachments"
            :files="files"
            @on-remove="onRemoveFile"
          />
        </div>
      </div>
    </div>
    <div class="min-h-[75px] w-full items-center justify-center gap-2 border-t border-blue-100">
      <BcButton icon-left="cross" type="outlined" @click="onClose">
        {{ $t('generics.cancel') }}
      </BcButton>
      <BcButton
        icon-left="check"
        :disabled="!isFormValid || isLoading || disabled"
        @click="onSave"
      >
        {{ $t('generics.save') }}
      </BcButton>
    </div>

    <ModalConfirm
      :isOpen="isModalOpen === true"
      :icon="actionParams.edit.icon"
      :title="actionParams.edit.title"
      :content="actionParams.edit.content"
      :cancelText="actionParams.edit.cancelText"
      :confirmText="actionParams.edit.confirmText"
      color="success"
      @on-confirm="actionParams.edit.onConfirmAction"
      @close="onCloseModal"
    />
  </div>
</template>
