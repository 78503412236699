<template>
  <div class="panel-job-informations col-xs-12">
    <panel-view-stack-job
      class="panel-job-informations__stack"
      :is-loading="isLoading"
      :technos-primary="profile._technosPrimary"
      :technos-secondary="profile._technosSecondary">
    </panel-view-stack-job>
    <panel-view-description
      :description="profile.description"
      :is-loading="isLoading">
    </panel-view-description>
  </div>
</template>

<script>
  import PanelViewStackJob from '@/components/Panel/PanelView/PanelViewStackJob';
  import { mapState } from 'vuex';
  import PanelViewDescription from '@/components/Panel/PanelView/PanelViewContent/PanelViewDescription';

  export default {
    components: {
      PanelViewDescription,
      PanelViewStackJob
    },
    name: 'panel-job-informations',
    computed: {
      ...mapState({
        profile: state => state.panel.profile
      })
    },
    props: {
      /**
       * Define if the content-loading should appear or not
       */
      isLoading: {
        type: Boolean,
        default: true
      }
    }
  };
</script>

<style scoped lang="scss">
  .panel-job-informations {
    padding: 30px 50px;
    flex-shrink: 0;
    &__stack {
      margin-bottom: 40px;
    }
  }
</style>
