/**
 * Directive to redirect to a default image if there is an error when the app will attempt to load the image
 * This error may be cause by a mistake in the URL or an expired image
 */
/* eslint-disable */
export default {
  inserted: (el) => {
  },
  bind: (el, binding) => {
    const { value } = binding;
    // We declare our variables
    const defaultLoading = require('@/assets/img/default-avatar.svg');
    const defaultError = require('@/assets/img/default-avatar.svg');
    let loading = defaultLoading;
    let err = defaultError;
    // We declare a variable which will contain the src of the element binded to
    let original = el.src;

    let img = new Image();

    if (typeof value === 'string') {
      loading = value;
      err = value;
    }

    if (value instanceof Object) {
      loading = value.loading || defaultLoading;
      err = value.error || defaultError;
    }

    img.src = original;
    el.src = loading;
    // If the image is good, the src will not be replaced
    img.onload = () => {
      el.src = original;
    };
    // If there is an error when the image was loaded, the src will be replaced
    img.onerror = () => {
      el.src = err;
    };
  }
};
