import { Process } from '@/domains/models/Process';
import { ref } from 'vue';
import { useApi } from '@/composables/api/useApi';
import { createProcessCustom } from '@/managers/processes-custom/useCases/createProcessCustom';
import { getProcessesCustom } from '@/managers/processes-custom/useCases/getProcessesCustom';
import { updateProcessCustomStep } from '@/managers/processes-custom/useCases/updateProcessCustomStep';
import { abortProcessCustom } from '@/managers/processes-custom/useCases/abortProcessCustom';
import { unabortProcessCustom } from '@/managers/processes-custom/useCases/unabortProcessCustom';
import { GET_PROCESSES, GET_PROCESSES_COUNT } from '@/composables/api/routes';

const processes = ref<Process[]>([]);

export function useProcessesApi () {
  const { del, get, getList, patch, post, loading, isRequestSuccessful, requestError } = useApi();


  const getProcessesByCandidateId = async ({ id }: { id: string }) => {
    processes.value =
      (await getProcessesCustom({
        resource: 'coder',
        coderId: id,
      })).data ?? [];
  };

  async function getProcessesByResource ({ opportunityId, companyId, page, stepKey, onlyActive }: { opportunityId?: string, companyId?: string, page: number, stepKey?: string, onlyActive?: boolean}) {
    const data = await get<{ processes: Process[]; isLastPage: boolean }>(GET_PROCESSES(), {
      customJobId: opportunityId,
      companyId: companyId,
      page: page,
      stepKey,
      onlyActive: !stepKey && onlyActive
    });
    return data;
  }
  async function getProcessesCountByResource ({ opportunityId, companyId, stepKey, onlyActive }: { opportunityId?: string, companyId?: string, stepKey?: string, onlyActive?: boolean}) {
    const data = await get<{ count: number;  }>(GET_PROCESSES_COUNT(), {
      customJobId: opportunityId,
      companyId: companyId,
      stepKey,
      onlyActive: !stepKey && onlyActive
    });
    return data?.count ?? 0;
  }

  async function createProcess(params: {
    coderId: string;
    customJobId: string;
    companyId: string;
  }) {
    const process = (await createProcessCustom(params)).data;
    if (process) processes.value.push(process);
  }

  async function updateProcessStatus(
    processId: string,
    status: { categoryKey: string; key: string }
  ) {
    processes.value = processes.value.map((process) => {
      if (process._id === processId) {
        process.historySteps.push({
          categoryKey: status.categoryKey,
          stepKey: status.key,
        });
      }
      return process;
    });
    await updateProcessCustomStep({
      id: processId,
      categoryKey: status.categoryKey,
      stepKey: status.key,
    });
  }

  async function abortProcess({
    processId,
    reason,
  }: {
    processId: string;
    reason: string;
  }) {
    processes.value = processes.value.map((process) => {
      if (process._id === processId) {
        process.abortedAt = new Date().toISOString();
      }
      return process;
    });
    await abortProcessCustom({
      id: processId,
      reason,
    });
  }

  async function unabortProcess({ processId }: { processId: string }) {
    processes.value = processes.value.map((process) => {
      if (process._id === processId) {
        delete process.abortedAt
      }
      return process;
    });
    await unabortProcessCustom({
      id: processId,
    });
  }

  return {
    processes,
    getProcessesByCandidateId,
    getProcessesByResource,
    getProcessesCountByResource,
    createProcess,
    updateProcessStatus,
    abortProcess,
    unabortProcess,
  };
}
