<template>
  <div
    :class="{
      'icon-alert-circle-full': isHover,
      'icon-alert-circle': !isHover,
    }"
    v-tooltip="{
      placement,
      content,
      html,
      classes,
    }"
    @mouseout="isHover = false"
    @mouseover="isHover = true">
  </div>
</template>

<script>
export default {
  name: 'warning-cerlce',
  props: {
    content: {
      type: String,
    },
    html: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    classes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isHover: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.warning-cercle {

}
</style>
