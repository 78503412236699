<template>
  <div class="items-center flex h-5 gap-[5px] text-[12px] bg-neutral-150 rounded-full px-2.5 py-[1px]">
    <img :src="linkedinInvitationIcon" alt="icon" class="size-[10px]" />
    <BcSpinner v-if="loading" size="small" color="secondary" class="min-w-8 items-center justify-center" />
    <template v-else-if="relationStatus === LinkedinRelationStatus.Connected">
      <span class="text-[#0B66C2] leading-[18px] font-medium whitespace-nowrap">{{ relationStatusText }}</span>
      <span class="text-blue-900 leading-[18px]">•</span>
      <span class="text-blue-400 leading-[18px] text-[9px]">{{ t('linkedin.first') }}</span>
    </template>
    <template v-else>
      <span class="text-[#A4AEBD] leading-[18px] font-medium whitespace-nowrap">{{ relationStatusText }}</span>
      <button class="p-[3px] rounded-full cursor-pointer hover:bg-blue-500 hover:text-white text-[#A4AEBD]"
      v-tooltip="
            t('linkedin.refresh-relation-status')
          "
        @click="$emit('refresh')">
        <MvIcon name="refresh-cw-alt" size="12px" />
      </button>
    </template>
  </div>
</template>

<script setup lang="ts">
import linkedinInvitationIcon from '@/assets/logo/linkedin-invitation.svg';
import { useI18n } from '@/i18n/i18n';
import { computed } from 'vue';
import MvIcon from '@/ui-kit/components/MvIcon/MvIcon.vue';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
import { LinkedinRelationStatus } from '@/domains/models/Linkedin';

const props = defineProps<{
  relationStatus: LinkedinRelationStatus;
  loading: boolean;
}>();

const { t } = useI18n();

const relationStatusText = computed(() => {
  const relationStatusTextMap = {
    [LinkedinRelationStatus.Connected]: t('linkedin.connected'),
    [LinkedinRelationStatus.Pending]: t('linkedin.pending'),
    [LinkedinRelationStatus.NotConnected]: t('linkedin.not-connected'),
  };
  return relationStatusTextMap[props.relationStatus];
});

const emit = defineEmits<{
  (event: 'refresh'): void;
}>();

</script>
