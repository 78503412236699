<template>
  <div class="panel-view-timeline is-column is-full-width is-align-items-center">
    <div v-if="$route.query.type !== 'jobs'" class="is-column is-full-width">
      <div v-if="!isCreatingNote" class="is-column is-full-width">
        <div class="
            panel-view-timeline__container
            is-full-width is-justify-content-space-between
          ">
          <NotesActions v-if="canCreateNote && isOnNotesTab" :profile="profile" :type="$route.query.type"
            @on-action="onNotesAction" />
          <NotesReminderButton v-else-if="isOnRemindersTab" :id="profile._id"
            :profile-types="profile?.profileTypes ?? []" :type="$route.query.type" @reminder-date="onReminderDate" />
        </div>
      </div>
      <TimelineItemWrapper v-else-if="canCreateNote" :action="noteTypeAction" class="is-full-width mb-5">
        <div :class="{ 'panel-view-timeline__editor': noteType !== 'reminder' }" class="is-full-width">
          <TimelineEditor :is-new-note="userType.includes('coders')" :noteType="noteType"
            :noteTypeTracking="noteTypeTracking" :profile="profile" :reminderDate="reminderDate"
            @close="e => setIsCreatingNote(e, false)" @note-saved="handleNoteSaved">
          </TimelineEditor>
        </div>
      </TimelineItemWrapper>
      <div v-if="qualifications.length > 0 && isOnNotesTab" class="
          panel-view-timeline__qualifications
          is-column is-align-items-center is-full-width
        ">
        <div v-for="(qualification, index) in showedQualifications" :key="qualification._id" class="relative w-full">
          <i
            class="icon-pin absolute -right-2.5 -top-2.5 z-10 flex size-6 items-center justify-center rounded-full bg-orange-500 text-sm text-white" />
          <QualificationsItem :action="qualification" :can-edit-qualif="!urgencyEditable" :class="{
            'pb-5': qualificationsCount === 0 && index === showedQualifications.length - 1,
          }" :is-new-note="userType.includes('coders')" :profile="profile" :qualification="qualification._note"
            class="panel-view-timeline__qualifications-item is-full-width pb-2" @note-unpinned="unpinNote"
            @note-deleted="deleteQualification" @note-edited="editQualification" />
        </div>
        <button v-if="qualificationsCount > 0" class="
            panel-view-timeline__qualifications-button
            is-underline
            font-size-s
            mb-2
            p-2
            text-orange-500
          " data-button-see-more @click="increaseDisplayedQualifications">
          {{ $t('notes.see-more-pinned-notes') }} ({{
            qualificationsCount
          }})
        </button>
      </div>
      <div v-if="process.length > 0" class="
          panel-view-timeline__qualifications
          is-column is-align-items-center is-full-width
        ">
        <ProcessItem v-for="processes in showedProcess" :key="processes._id" :process="processes"
          class="panel-view-timeline__qualifications-item" @note-deleted="deleteProcess" @note-edited="editProcess">
        </ProcessItem>
        <button v-if="ProcessesCount > 0" class="
            panel-view-timeline__qualifications-button
            is-underline
            font-size-s
          " data-button-see-more @click="increaseDisplayedProcesses">
          {{ $t('companies.panel-view-timeline.see-old-processes') }} ({{ ProcessesCount }})
        </button>
      </div>
    </div>
    <PanelTimeline v-if="profile._id ?? $route.params.id" :id="profile._id ?? $route.params.id"
      :identifier="timelineIdentifier" :page="timelinePage" :profile="profile" :timeline="timeline"
      :user-type="userType" @note-deleted="deleteNote" @note-edited="editNote" @note-pinned="pinNote"
      @timeline-incomplete="incrementPage" @timeline-loaded="loadTimeline">
    </PanelTimeline>
    <slot :update="editQualification"></slot>
  </div>
</template>

<script lang="ts">
import { mapGetters, mapState } from 'vuex'; //
import PanelTimeline from '@/components/Panel/PanelTimeline';
import TimelineEditor from '@/components/Timeline/TimelineEditor.vue';
import TimelineItemWrapper from '@/components/Timeline/TimelineItemWrapper';
import { noteController } from '@/managers/notes/controller';
import QualificationsItem from '@/components/Qualifications/QualificationsItem';
import NotesActions from '@/components/Notes/NotesActions.vue';
import ProcessItem from '@/components/ProcessRecruitment/ProcessItem';
import notes, { hasQualificationsWithRating } from '@/common-old/macros/notes'; //
import MetadatasMixin from '@/mixins/metadatas'; //
import pipe from '@/macros/pipe';
import { myGarden } from '@/macros/favoriteLists/favoriteLists';
import { mapActions } from 'pinia';
import { useNotesTemplatesStore } from '@/store/pinia/notesTemplates';
import { http } from '@/api/index.js';

import macros from '@/macros/macros';
import NotesReminderButton from '@/components/Notes/NotesReminderButton.vue';
import { useLinkedinRelationsStore } from '@/store/pinia/linkedinRelations';
import { getLinkedinSlug } from '@/formatters/linkedin';
import { useTimelineRefresh } from '@/composables/useTimelineRefresh';
import { useUnipileAccountsStore } from '@/store/pinia/unipileAccounts';
import { storeToRefs } from 'pinia';
import { isValidObjectId } from '@/helpers/mongodb';

const routes = {
  companies: 'partners',
  partners: 'partners',
  coders: 'coders',
  contacts: 'contacts',
  hunt: 'custom-jobs',
  opportunities: 'custom-jobs',
  jobs: 'jobs',
  pokes: 'pokes',
  process: 'process',
  'suggestions-coders': 'coders',
};

export default {
  name: 'panel-view-timeline',
  components: {
    NotesReminderButton,
    QualificationsItem,
    TimelineEditor,
    TimelineItemWrapper,
    PanelTimeline,
    ProcessItem,
    NotesActions,
  },
  mixins: [MetadatasMixin],
  props: {
    userType: {
      type: String,
      default: '',
    },
    profile: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const unipileAccountsStore = useUnipileAccountsStore();
    const { linkedInAccount } = storeToRefs(unipileAccountsStore);
    return {
      linkedInAccount,
    };
  },
  data() {
    return {
      noteType: undefined,
      isCreatingNote: false,
      qualifications: [],
      process: [],
      displayedQualifications: 1,
      displayedProcesses: 1,
      timeline: [],
      timelinePage: 1,
      timelineIdentifier: +new Date(),
      noteTypeTracking: false,
      reminderDate: undefined as { date: Date, profileType: string } | undefined,
    };
  },
  computed: {
    ...mapGetters({
      cardActive: 'cardActive',
    }),
    ...mapState({
      coachData: store => store.user.profile,
    }),
    profileId() {
      return this.profile._id ?? this.$route.params.id;
    },
    noteTypeAction() {
      if (this.noteType === notes.type.note.name) {
        return { icon: 'pen-square' };
      }
      if (this.noteType === notes.type.call.name) {
        return { icon: 'phone' };
      }
      if (this.noteType === notes.type.email.name) {
        return { icon: 'mail' };
      }
      if (this.noteType === notes.type.linkedin.name) {
        return { source: 'linkedin' };
      }
      if (this.noteType === notes.type.whatsapp.name) {
        return { source: 'whatsapp' };
      }
      if (this.noteType === notes.type.reminder.name) {
        return { icon: 'calendar-circle-plus' };
      }
      return { icon: 'pen-square' };
    },
    hasGoogleWidgets() {
      const configurationProcess = this.$store.getters['user/configurationProcess'];
      if (!configurationProcess) {
        return false;
      }
      return configurationProcess.hasGoogleWidgets;
    },
    urgencyEditable() {
      if (this.timeline && this.timeline.length > 0 && this.timeline[0]._customJob) {
        return this.timeline[0]._customJob._owners.map(owner => owner._id || owner).includes(this.coachData._id);
      }

      return false;
    },
    isOnNotesTab() {
      return this.$route.query['timeline-type'] === macros.timeline.types.notes || !this.$route.query['timeline-type'] || this.$route.query['timeline-type'] === macros.timeline.types.all;
    },
    isOnRemindersTab() {
      return this.$route.query['timeline-type'] === macros.timeline.types.reminders;
    },
    showedQualifications() {
      return this.qualifications.filter(
        (contact, index) => index < this.displayedQualifications,
      );
    },
    showedProcess() {
      return this.process.filter(
        (contact, index) => index < this.displayedProcesses,
      );
    },
    timelineType() {
      return this.$route.query['timeline-type'];
    },
    canCreateNote() {
      return (
        this.$route.params.id !== 'profile' &&
        this.$route.query.type !== 'coaches'
      );
    },
    qualificationsCount() {
      return this.qualifications.length - this.showedQualifications.length;
    },
    ProcessesCount() {
      return this.process.length - this.showedProcess.length;
    },
    hasQualifications() {
      return hasQualificationsWithRating.includes(this.$route.query.type) && this.$route.query['timeline-type'] === 'notes';
    },
    tooltip() {
      return `
              <div class="is-flex is-column font-size-s ml-4 is-text-align-left">
              <p>${this.$i18n.t('companies.panel-view-timeline.infos')}:</p>
                <ul class="mt-3">
                    <li>${this.$i18n.t('companies.panel-view-timeline.the-qualification')}</li>
                    <li>${this.$i18n.t('companies.panel-view-timeline.candidate-salary')}</li>
                    <li>${this.$i18n.t('companies.panel-view-timeline.date-to-remind-candidate')}</li>
                    <li>${this.$i18n.t('companies.panel-view-timeline.an-evaluation-note')}</li>
                </ul>
                <p class="mt-4">${this.$i18n.t('companies.panel-view-timeline.for-report-exchange')} <strong>${this.$i18n.t('companies.panel-view-timeline.text')}</strong></p>
                <p class="mt-4">${this.$i18n.t('companies.panel-view-timeline.for-report-message')}<strong>${this.$i18n.t('companies.panel-view-timeline.channel')}</strong></p>
              </div>
          `;
    },
  },
  watch: {
    profileId: {
      async handler() {
        this.resetTimelineAndQualifications();
      },
      immediate: true,
    },
    linkedInAccount() {
      this.getLinkedinMessages();
    },
    timelineType() {
      this.resetInfiniteScroll();
    },
    isOnNotesTab() {
      this.displayedQualifications = 1;
      this.getQualifications();
    },
  },
  beforeCreate() {
    this.$store.dispatch('emptyViewList');
  },
  beforeDestroy() {
    this.$store.dispatch('emptyViewList');
  },
  created() {
    this.getNotesTemplates();
    const { setRefreshCallback } = useTimelineRefresh();
    setRefreshCallback(this.refreshTimeline);
  },
  methods: {
    ...mapActions(useNotesTemplatesStore, ['fetchNotesTemplates']),
    ...mapActions(useLinkedinRelationsStore, ['addOrRemoveRelation']),
    async getNotesTemplates() {
      await this.fetchNotesTemplates({ all: true, coachId: null, sorted: true });
    },
    async refreshTimeline() {
      this.resetInfiniteScroll();
      await this.getQualifications();
    },
    async unpinNote(note) {
      const qualificationIndex = this.qualifications.findIndex(qualification => qualification._note?._id === note._id);
      this.qualifications.splice(qualificationIndex, 1);

      await this.resetInfiniteScroll();
    },
    async pinNote(note) {
      const timelineIndex = this.timeline.findIndex(action => action._note?._id === note._id);
      this.timeline.splice(timelineIndex, 1);

      await this.getQualifications();
    },
    onNotesAction(type) {
      if (type !== notes.type.reminder.name) {
        this.onReminderDate(undefined);
      }
      this.noteType = type;
      this.setIsCreatingNote(null, true, type !== 'call');
    },
    onReminderDate(date?: Date, profileType?: string) {
      this.resetInfiniteScroll();
    },
    async resetTimelineAndQualifications() {
      this.resetInfiniteScroll();
      this.displayedQualifications = 1;
      this.displayedProcesses = 1;

      if (this.isOnNotesTab && !['new-coder', 'new-contact'].includes(this.profileId)) {
        this.getQualifications();
      }
      console.log('this.profileId', this.profileId);
      this.getLinkedinMessages();
    },
    async getLinkedinMessages() {
      if (isValidObjectId(this.profileId)) {
        try {
          if (!!this.linkedInAccount) {
            const { hasNewMessagesOrActions, isRelation } = (await http.post('/unipile/messages/sync', {
              coderId: this.profileId
            }))?.data;

            if (hasNewMessagesOrActions) this.resetInfiniteScroll();

            if (isRelation !== undefined) {
              this.addOrRemoveRelation(getLinkedinSlug(this.profile?.social?.linkedin), isRelation);
            }
          }
        } catch (error) {
          console.error('Failed to fetch LinkedIn messages:', error);
        }
      }
    },
    async getQualifications() {
      const {
        data: { qualifications },
      } = await noteController.get({
        userType: routes[this.$route.query.type],
        id: this.profile?._id ?? this.$route.params.id,
        type: 'qualifications',
      });

      this.qualifications = qualifications;
    },
    increaseDisplayedQualifications() {
      const count = 2;

      this.displayedQualifications += count;
    },
    increaseDisplayedProcesses() {
      const count = 2;

      this.displayedProcesses += count;
    },
    incrementPage() {
      ++this.timelinePage;
    },
    setIsCreatingNote(e, value, isTracking) {
      if (e) {
        e.stopPropagation();
      }

      this.isCreatingNote = value;

      if (value) {
        this.noteTypeTracking = isTracking;
      }
    },
    editQualification(note) {
      if (note) {
        const index = this.qualifications.findIndex(
          qualification => qualification._note?._id === note._id,
        );

        this.$set(this.qualifications, index, {
          ...this.qualifications[index],
          _note: {
            ...this.qualifications[index]._note,
            ...note,
          },
        });

        if (this.cardActive >= 0) {
          this.$store.dispatch('updateCard', {
            index: this.cardActive,
            content: {
              lastRating: {
                _coach: note._coach,
                ...(note.rating && { rating: note.rating }),
                ...(note.urgency && { urgency: note.urgency }),
              },
            },
          });
        }
        this.$emit('qualification-created', note);
      }
    },
    editProcess(note) {
      if (note) {
        const index = this.process.findIndex(
          process => process._id === note._id,
        );

        if (note.type === notes.type.process.api) {
          this.$set(this.process, index, { ...this.process[index], ...note });

          if (this.cardActive >= 0) {
            this.$store.dispatch('updateCard', {
              index: this.cardActive,
              content: {
                lastRating: {
                  _coach: note._coach,
                  ...(note.rating && { rating: note.rating }),
                  ...(note.urgency && { urgency: note.urgency }),
                },
              },
            });
          }
        } else {
          this.process.splice(index, 1);

          const actionIndex = this.timeline.findIndex(
            action => action?._note?._id === note?._id,
          );

          this.timeline[actionIndex]._note = note;
        }
        this.$emit('qualification-created', note);
      }
    },
    editNote(action) {
      const editedNoteIndex = this.timeline.findIndex(
        a => a._id === action._id,
      );

      this.timeline[editedNoteIndex]._note = {
        ...this.timeline[editedNoteIndex]._note,
        ...action.note,
      };
    },
    async handleNoteSaved(note) {
      if (note?.pin) {
        await this.getQualifications();
        this.$emit('qualification-created', note);
      } else {
        this.handleUpdate(note);
        this.resetInfiniteScroll();
      }
    },
    deleteQualification(id) {
      const index = this.qualifications.findIndex(
        qualification => qualification._note?._id === id,
      );
      this.qualifications.splice(index, 1);

      if (this.cardActive >= 0) {
        this.$store.dispatch('updateCard', {
          index: this.cardActive,
          content: {
            lastRating: {},
          },
        });
      }

      this.deleteNote(id);
    },
    deleteProcess(id) {
      const index = this.process.findIndex(process => process._id === id);

      if (index >= 0) {
        this.process.splice(index, 1);
      }

      if (this.cardActive >= 0) {
        this.$store.dispatch('updateCard', {
          index: this.cardActive,
          content: {
            lastRating: {},
          },
        });
      }

      this.deleteNote(id);
    },
    deleteNote(id) {
      const index = this.timeline.findIndex(qualification => {
        return qualification && qualification._note && qualification._note?._id === id;
      });
      if (index >= 0) {
        this.timeline.splice(index, 1);
        this.resetInfiniteScroll();
      }
    },
    loadTimeline(timeline) {
      this.timeline = timeline;
    },
    resetInfiniteScroll() {
      this.timeline = [];
      this.timelinePage = 1;
      this.timelineIdentifier += 1;
    },
    handleUpdate(note) {
      if (
        this.$route.query.type === 'coders' ||
        this.$route.query.type === 'suggestions-coders'
      ) {
        this.$_updateCandidatePipeMetadatas(
          this.profile.coachProcessState,
          note._coder.coachProcessState,
        );
      }

      this.handleCard(note);
    },
    handleCard(note) {
      if (
        ((note._note || {}).type === notes.type.qualification.api &&
          this.$route.name.includes('CandidatePipe') &&
          this.$route.query.step !== 'to-process') ||
        this.$route.params.name === myGarden
      ) {
        this.$store.dispatch('removeCard', this.$route.params.id);
      } else {
        this.$store.dispatch('updateCard', {
          index: this.cardActive,
          content: {
            ...(this.$route.query.type === 'coders' && {
              coachProcessState: { status: pipe.expected.toProcess },
              processState: { status: pipe.expected.toProcess },
            }),
            lastRating: {
              _coach: note._coach,
              rating: note.rating,
            },
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-view-timeline {
  padding: 15px 30px;

  div:first-child {
    button.bc-button__primary:nth-child(1) {
      margin-right: 8px;
    }
  }

  &__icon {
    z-index: 1;
    margin-bottom: 10px;

    @include bp('tablet') {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  &__container {
    align-items: start;

    .bc-button--alarm {
      margin-left: 15px;
    }
  }

  &__wrapper {
    margin-left: auto;
    background: $color-grey-2;
    border: 1px solid $color-grey-2;
  }

  &__qualifications {}

  &__button {
    padding: 12px 15px;
    color: $color-grey-4;
    transition: 0.1s;

    &:hover {
      background: $color-grey-3;
    }

    &--active,
    &--active:hover {
      color: $color-secondary;
      background: $color-white;
    }

    &:first-of-type {
      border-top-left-radius: $border-radius-s;
      border-bottom-left-radius: $border-radius-s;
    }

    &:last-of-type {
      border-top-right-radius: $border-radius-s;
      border-bottom-right-radius: $border-radius-s;
    }
  }

  &__qualifications-button {
    &:hover {
      text-decoration: none;
    }
  }

  &__qualifications-item {
    margin-top: 10px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__editor {
    border: 1px solid $color-primary;
    border-radius: $border-radius-m;
  }

  &__info {
    border: 1px solid $color-secondary;
    border-radius: 100%;
    height: 18px;
    width: 18px;
    font-size: 10px;
    font-weight: 600;
    color: $color-secondary;

    &:hover {
      cursor: pointer;
      background: $color-secondary;
      color: $color-white;
    }
  }
}
</style>
