<template>
  <div class="inbox-card-list">
    <bc-pagination
      :current.sync="currentPage"
      :total="count"
      class="inbox-card-list__pagination">
    </bc-pagination>
    <bc-spinner
      class="inbox-card-list__spinner"
      v-if="!isLoaded">
    </bc-spinner>
    <div
      class="is-align-items-center is-column is-full-width"
      v-else>
      <companies-grid></companies-grid>
      <card-list
        :cards="cards"
        :is-loaded="isLoaded"
        :response-obj="'partners'"
        class="inbox-card-list__list">
      </card-list>
    </div>
    <bc-pagination
      :current.sync="currentPage"
      :total="count"
      class="inbox-card-list__pagination"
      v-if="isLoaded">
    </bc-pagination>
    <router-view></router-view>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import BcPagination from '@/ui-kit/components/BcPagination';
  import CardList from '@/components/CardList/CardList';
  import CompaniesGrid from '@/components/Companies/CompaniesGrid';

  export default {
    name: 'inbox-card-list',
    components: { CompaniesGrid, BcPagination, BcSpinner, CardList },
    data() {
      return {
        isLoaded: true,
        currentPage: parseInt(this.$route.query.page) || 1,
      };
    },
    computed: {
      ...mapState({
        cards: state => state.card.cards,
        count: state => state.card.count,
        metadata: state => state.user.metadata,
      }),
    },
    methods: {
      ...mapActions({
        'getCards': 'getCards',
        'resetCards': 'resetCards',
        'setCount': 'setCount',
        updateMetadataByKey: 'user/updateMetadataByKey',
      }),
      async getResources() {
        this.isLoaded = false;
        this.resetCards();
        await this.getCards({
          call: 'partners/subscriptions',
          page: this.$route.query.page,
          responseKeyName: 'partners',
        });

        if (this.count !== this.metadata.newPartners) {
          this.updateMetadataByKey({
            key: 'newPartners',
            value: this.count,
          });
        }

        this.isLoaded = true;
      },
    },
    watch: {
      $route: {
        handler(to = {}, from = {}) {
          if (to?.params?.type !== from?.params?.type || Number(to?.query?.page) !== Number(from?.query?.page)) {
            this.setCount(1);
            this.getResources();
            this.currentPage = parseInt(to.query.page) || 1;
          }
        },
        deep: true,
        immediate: true,
      },
      currentPage(newPage) {
        this.$router.push({ ...this.$route, query: { ...this.$route.query, page: newPage } });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .inbox-card-list {
    width: 100%;
    flex-direction: column;
    align-items: center;

    &__pagination {
      margin: 20px 0;
    }

    &__spinner {
      margin: 100px 0;
    }
  }
</style>
