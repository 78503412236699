<template>
  <form-modal-container name="WTTJ" :is-loading="isLoading">
    <ValidationObserver
      class="wttj-form-modal"
      ref="wttj-form-modal"
      tag="form"
      v-slot="{ valid: isFormValid }">
      <title-section position="left" :title="'INFOS OBLIGATOIRE'"></title-section>

      <!-- TITLE -->
      <ValidationProvider
        v-slot="{ errors, valid }"
        :rules="{ required: true, min: 2 }"
        :name="$t('avatar-dropdown.job-title')"
        slim>
        <bc-input
          required
          ref="title"
          v-model="additionalInfos.title"
          :class="{ invalid: errors.length > 0 }"
          :error="errors[0]"
          :valid="valid && additionalInfos.title.length > 0"
          class="panel-coder-edit-informations__input panel-coder-edit-informations__input--half"
          icon="suitcase"
          :label="$t('avatar-dropdown.job-title')"
          name="title"
          type="text">
        </bc-input>
      </ValidationProvider>

      <!-- DEPARTMENTS -->
      <select-container class="wttj-form-modal__select" :label="$t('generics.department')">
        <bc-dropdown
          class="font-size-s"
          :is-scrollable="true"
          :options="departments"
          :required="true"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              type="button"
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="(additionalInfos.selectedDepartment || {}).name">
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              @click.native="selectDepartment(option, index)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
      </select-container>

      <!-- PROFESSIONS -->
      <select-container class="wttj-form-modal__select" :label="$t('generics.professions')" icon="award">
        <bc-dropdown
          class="font-size-s"
          :is-scrollable="true"
          :limit="3"
          :options="professions"
          :required="true"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              type="button"
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="(additionalInfos.selectedProfession.fullname_translations || {})[$i18n.locale]">
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.fullname_translations[$i18n.locale]"
              @click.native="selectProfession(option, index)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
      </select-container>

      <!-- LOCATION-->
      <location-autocomplete
        style="margin-top: -14px;"
        :selected="additionalInfos.selectedLocations"
        @select-locations="selectLocations"
        placeholder="Exemple: Paris"
        required
      />

      <!-- CONTRACT TYPES -->
      <select-container class="wttj-form-modal__select" :label="$t('companies.account-management.contract-type')" icon="file">
        <bc-dropdown
          class="font-size-s"
          :is-scrollable="true"
          :limit="3"
          :options="contractTypes"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              type="button"
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="additionalInfos.selectedContract.name">
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              @click.native="selectContract(option, index)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
      </select-container>

      <!-- LANGUAGES -->
      <select-container class="wttj-form-modal__select" :label="$t('topbar.language')">
        <bc-dropdown
          class="font-size-s"
          :is-scrollable="true"
          :limit="3"
          :options="languages"
          :required="true"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              type="button"
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="additionalInfos.selectedLanguage.name">
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              @click.native="selectLanguage(option, index)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
      </select-container>

      <title-section position="left" :title="$t('custom-jobs.description-job-offer')"></title-section>
      <div class="is-column">
        <p class="wttj-form-modal__label">{{ $t('jobboards.job-description') }} <span class="wttj-form-modal__label--required">*</span></p>
        <bc-text-editor v-model="additionalInfos.description"></bc-text-editor>
      </div>
      <div class="is-column">
        <p class="wttj-form-modal__label">{{ $t('jobboards.profile-description') }} <span class="wttj-form-modal__label--required">*</span></p>
        <bc-text-editor v-model="additionalInfos.profile"></bc-text-editor>
      </div>

      <div class="wttj-form-modal__button-container">
        <bc-button
          @click="$emit('cancel', $event)"
          :disabled="isFormLoading"
          type="outlined"
        >
          {{ $t('generics.cancel') }}
        </bc-button>
        <bc-button
          color="success"
          :disabled="!isFormValid || !checkValues"
          :is-loading="isFormLoading"
          @click="onSubmit"
        >
          {{ isEdit ? $t('jobboards.edit-offer', { jobboard: 'WTTJ' }) : $t('jobboards.publish-offer', { jobboard: 'WTTJ' }) }}
        </bc-button>
      </div>
    </ValidationObserver>
  </form-modal-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { publish } from '@/api/job-multipost';
import { getWttjProfessionsList, getWttjLanguages, getWttjDepartmentsList } from '@/api/jobboards/wttj';
import { apiContracts } from '@/api/custom-jobs';
import FormModalContainer from './FormModalContainer';
import BcInput from '@/ui-kit/components/BcInput/BcInput';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
import BcTextEditor from '@/ui-kit/components/BcTextEditor/BcTextEditor';
import SelectContainer from '@/components/Select/SelectContainer';
import TitleSection from '@/components/Title/TitleSection';
import LocationAutocomplete from '@/components/Location/LocationAutocomplete';

export default {
  name: 'wttj-form-modal',
  components: {
    ValidationObserver,
    ValidationProvider,
    BcInput,
    BcButton,
    BcDropdown,
    BcDropdownItem,
    BcDropdownTrigger,
    BcTextEditor,
    FormModalContainer,
    SelectContainer,
    TitleSection,
    LocationAutocomplete,
  },
  props: {
    customJob: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getLanguages();
    await this.getProfessions();
    await this.getDepartments();

    if (this.customJob?.jobMultiposting?.wttj?.additionalInfos) {
      this.additionalInfos = this.customJob.jobMultiposting.wttj.additionalInfos;
      this.additionalInfos.selectedDepartment = this.additionalInfos.selectedDepartment ? this.additionalInfos.selectedDepartment : this.departments[0];
    } else {
      this.additionalInfos.selectedProfession = this.professions[0];
      this.additionalInfos.selectedDepartment = this.departments[0];
      const findedLanguage = this.languages.find(language => language.reference === this.$i18n.locale);
      this.additionalInfos.selectedLanguage = findedLanguage ? findedLanguage : this.languages[0];

      this.additionalInfos.title = this.customJob.title;
      this.additionalInfos.selectedLocations = this.customJob.locations;
      this.additionalInfos.description = this.customJob.description;

      if (this.contractMap[this.customJob.contract]) {
        this.additionalInfos.selectedContract = this.contractTypes.find(contract =>
          contract.reference === this.contractMap[this.customJob.contract]
        );
      } else {
        this.additionalInfos.selectedContract = this.contractTypes[0];
      }
    }
    this.isLoading = false;
  },
  data() {
    return {
      apiContracts,
      isLoading: false,
      isFormLoading: false,
      departments: [],
      professions: [],
      languages: [],
      additionalInfos: {
        title: '',
        description: '',
        profile: '',
        selectedDepartment: {},
        selectedProfession: {},
        selectedLanguage: {},
        selectedContract: {},
        selectedLocations: [],
      },
    }
  },
  computed: {
    ...mapGetters({
      configurationJobMultiposting: 'user/configurationJobMultiposting',
    }),
    checkValues() {
      for (const key in this.additionalInfos) {
        if (typeof this.additionalInfos[key] === 'string' && !this.additionalInfos[key]) {
          return false;
        }
        if (Array.isArray(this.additionalInfos[key]) && !this.additionalInfos[key].length) {
          return false;
        } else if (typeof this.additionalInfos[key] === 'object' && !Object.keys(this.additionalInfos[key]).length) {
          return false;
        }
      }
      return true;
    },
    contractTypes() {
      return [
        { reference: 'FULL_TIME', name: this.$t('contracts.full-time') },
        { reference: 'PART_TIME', name: this.$t('contracts.part-time') },
        { reference: 'TEMPORARY', name: this.$t('contracts.cdd') },
        { reference: 'FREELANCE', name: this.$t('contracts.freelance') },
        { reference: 'INTERNSHIP', name: this.$t('contracts.stage') },
        { reference: 'APPRENTICESHIP', name: this.$t('contracts.apprenticeship') },
      ]
    },
    contractMap() {
      return {
        [this.apiContracts.cdi]: 'FULL_TIME',
        [this.apiContracts.cdd]: 'TEMPORARY',
        [this.apiContracts.freelance]: 'FREELANCE',
        [this.apiContracts.stage]: 'INTERNSHIP',
        [this.apiContracts.alternance]: 'APPRENTICESHIP',
      }
    }
  },
  methods: {
    async onSubmit() {
      this.isFormLoading = true;
      try {
        this.cleanSpacing();
        const { data } = await publish({
          jobboard: 'wttj',
          customJobId: this.customJob._id,
          additionalInfos: this.additionalInfos,
        });
        this.isFormLoading = false;
        this.$emit('update', data, 'WTTJ');
      } catch (err) {
        this.isFormLoading = false;
        this.$toast.show({
          type: 'error',
          message: this.$t('jobboards.published-error'),
        });
      }
    },
    async getProfessions() {
      try {
        const { data: professions } = await getWttjProfessionsList({ perPage: 200 });
        professions.sort((a, b) => a.fullname_translations[this.$i18n.locale].localeCompare(b.fullname_translations[this.$i18n.locale]));
        this.professions = professions;
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async getDepartments() {
      try {
        const { data: departments } = await getWttjDepartmentsList({ perPage: 200 });
        this.departments = departments;
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async getLanguages() {
      try {
        const { data: languages } = await getWttjLanguages();
        this.languages = languages;
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    cleanSpacing() {
      this.additionalInfos.description = this.additionalInfos.description.replaceAll('<p>&nbsp;</p>', '');
      this.additionalInfos.profile = this.additionalInfos.profile.replaceAll('<p>&nbsp;</p>', '');
    },
    selectLanguage(option) {
      this.additionalInfos.selectedLanguage = option;
    },
    selectProfession(option) {
      this.additionalInfos.selectedProfession = option;
    },
    selectLocations(locations) {
      this.additionalInfos.selectedLocations = locations;
    },
    selectContract(option) {
      this.additionalInfos.selectedContract = option;
    },
    selectDepartment(department) {
      this.additionalInfos.selectedDepartment = department;
    }
  },
  watch: {
    isLoading(value) {
      if (!value) {
        this.$nextTick(() => {
          this.$refs['wttj-form-modal']?.validate?.();
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wttj-form-modal {
  flex-direction: column;
  gap: 35px;
  padding: 20px 0px;

  &__select {
    flex-direction: column;
  }

  &__button-container {
    justify-content: center;
    gap: 10px;
  }

  &__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-blue-dark-cello;
    margin-bottom: 10px;

    &--required {
      color: $color-red-mandy;
    }
  }
}
</style>
