export const apiPersonality = {
  commander: 1,
  processor: 2,
  conqueror: 3,
  consensual: 4,
};

export const personalityOptions = {
  commander: {
    api: apiPersonality.commander,
    name: 'Commandeur',
  },
  conqueror: {
    api: apiPersonality.conqueror,
    name: 'Conquérant',
  },
  consensual: {
    api: apiPersonality.consensual,
    name: 'Consensuel',
  },
  processor: {
    api: apiPersonality.processor,
    name: 'Processeur',
  },
};

export default {
  apiPersonality,
  personalityOptions,
};
