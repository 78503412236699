<template>
  <div class="card-favorite-lists-rpo">
    <div
      class="card-favorite-lists-rpo__wrapper"
      @click.exact="openList"
      @click.meta.exact="openListInNewTab">
      <card-favorite-lists-infos
        :id="cardContent._id"
        :locked="cardContent.locked"
        :author="cardContent.author"
        :elements-count="cardContent.elementsCount"
        :list-type="cardContent.type"
        :name="cardContent.name"
        :subscribers="cardContent.subscribers"
        :updated-at="cardContent.updatedAt">
      </card-favorite-lists-infos>
    </div>
  </div>
</template>

<script>
  import CardFavoriteListsInfos from '@/components/Card/CardFavoriteLists/CardFavoriteListsInfos';
  import { apiRights } from '@/macros/favoriteLists/favoriteLists';
  import { mapState } from 'vuex';
  import favoriteListsNotifications from '@/mixins/FavoriteLists/favoriteListsNotifications';

  export default {
    name: 'card-favorite-lists-rpo',
    components: {
      CardFavoriteListsInfos,
    },
    mixins: [favoriteListsNotifications],
    props: {
      cardContent: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        favoriteListType: ['coder', 'job', 'company', 'contact'],
      };
    },
    computed: {
      ...mapState({
        user: state => state.user.profile,
        cards: state => state.card.cards,
      }),
      userSubscriberInList() {
        return this.cardContent?.subscribers?.find(subscriber => subscriber?._coach._id === this.user._id) || {};
      },
      isListFollowedByUser() {
        return this.userSubscriberInList.notifications;
      },
    },
    methods: {
      openList() {
        this.$router.push({
          name: 'FavoriteList',
          params: {
            name: this.cardContent.name.toLowerCase().replace(/ /g, '-'),
          },
          query: {
            page: 1,
            'list-type': this.favoriteListType[this.cardContent.type - 1],
            'list-id': this.cardContent._id,
          },
        });
      },
      openListInNewTab() {
        const route = this.$router.resolve({
          name: 'FavoriteList',
          params: {
            name: this.cardContent.name.toLowerCase().replace(/ /g, '-'),
          },
          query: {
            page: 1,
            'list-type': this.favoriteListType[this.cardContent.type - 1],
            'list-id': this.cardContent._id,
          },
        });

        window.open(route.href, '_blank');
      },
    },
  };
</script>

<style lang="scss">
  .card-favorite-lists-rpo {
    &:hover {
      .card-favorite-lists-rpo-settings__dragdrop {
        opacity: 1;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .card-favorite-lists-rpo {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    padding-left: 67px;
    height: 56px;

    &__wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    &__container {
      margin-right: 30px;
    }
  }
</style>
