<script lang="ts" setup>
import { Opportunity } from '@/domains/models/Opportunity';
import { defineEmits, defineProps, withDefaults, computed } from 'vue';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
import imgFallback from '@/assets/img/default-avatar-company-2.svg';
import { useRoute } from 'vue-router/composables';
import BcButton from '@/ui-kit/components/BcButton';
import ButtonFindCandidates from '@/components/Button/ButtonFindCandidates.vue';
import { RouterLink } from 'vue-router';
import { getCustomJobReporting } from '@/api/custom-jobs';
import { useLaunchDarklyStore } from '@/store/pinia/launchDarkly';

const route = useRoute();

interface Props {
  opportunity: Opportunity;
}

const props = withDefaults(defineProps<Props>(), {
  opportunity: () => ({}) as Opportunity,
});

const launchDarklyStore = useLaunchDarklyStore();
const isGenerateReportVisible = computed(() => launchDarklyStore.getFlag('enableOpportunityReporting'));

defineEmits<{
  (e: 'edit-opportunity', value: boolean): void;
}>();

const getReporting = async () => {
  try {
    const response = await getCustomJobReporting(props.opportunity._id);

    const blob = await response.data;
    // Forcer le type MIME pour le blob
    const file = new Blob([blob], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const url = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    // Forcer l'extension .xlsx
    link.download = `rapport-${props.opportunity._id}-${props.opportunity._company?.name}-${props.opportunity.title}-${new Date().toLocaleDateString('fr-FR')}.xlsx`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Download failed:', error);
    throw error;
  }
};

const setAlternativeImg = event => {
  event.target.src = imgFallback;
};
</script>

<template>
  <div
    class="panel-opportunity-top-view relative flex w-full flex-row items-center gap-[18px] bg-white px-2 pt-5">
    <BcAvatar
      :src="opportunity?._company?.logoUrl"
      class="size-[80px] shrink-0 rounded-full border-2 border-blue-300"
      @error="setAlternativeImg" />
    <div class="absolute -right-4 -top-1 flex gap-2.5">
      <BcButton
        v-if="isGenerateReportVisible"
        icon-left="note"
        size="extra-small"
        @click="getReporting">
        {{ $t('generics.generate-report') }}
      </BcButton>
      <ButtonFindCandidates
        v-if="!opportunity.isSpontaneous"
        :job="opportunity"
        class="h-[30px] rounded-3xl" />
      <BcButton icon-left="edit-3" size="extra-small" @click="$emit('edit-opportunity', true)" />
    </div>
    <div class="flex w-full flex-col items-start">
      <p class="text-md text-blue-800">{{ opportunity.title }}</p>
      <RouterLink
        :to="{
          name: route.name,
          params: { id: opportunity._company?._id },
          query: { ...route.query, type: 'companies' },
        }"
        class="!text-blue-500 hover:underline">
        <p class="text-md">{{ opportunity._company?.name }}</p>
      </RouterLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.panel-opportunity-top-view {
  &:deep() {
    .mv-button-rounded__button {
      padding: 0 20px;
      height: 100%;
    }
  }
}
</style>
