<script lang="ts" setup>
  import MvFilter from '@/ui-kit/components/MvFilter/MvFilter.vue';
  import { computed, ref } from 'vue';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete.vue';
  import { useRoute, useRouter } from 'vue-router/composables';
  import BcTag from '@/ui-kit/components/BcTag/BcTag.vue';
  import { useCampaignsStore } from '@/store/pinia/campaigns';

  type TypeSuggestion = {
    _id: string;
    name: string;
    textColor: string;
    backgroundColor: string;
  };

  const route = useRoute();
  const router = useRouter();
  const campaignsStore = useCampaignsStore();

  const searchbarInput = ref<string>('');
  const isLoading = ref<boolean>(false);

  const suggestions = computed(() => campaignsStore.configuration?.types ?? []);
  const suggestionsColorMap: Omit<TypeSuggestion, '_id'>[] = [
    {
      name: 'Linkedin',
      textColor: '#FFFFFF',
      backgroundColor: '#0B66C2',
    },
    {
      name: 'Linkedin Recruiter',
      textColor: '#7143F0',
      backgroundColor: '#DAD9FC',
    },
    {
      name: 'Linkedin Sales',
      textColor: '#7143F0',
      backgroundColor: '#DAD9FC',
    },
    {
      name: 'Mail',
      textColor: '#F78E00',
      backgroundColor: '#FBEEDB',
    },
    {
      name: 'Mixte',
      textColor: '#1D385D',
      backgroundColor: '#E8EBEE',
    },
  ];

  function getTypeTextColor(name: string) {
    return suggestionsColorMap.find(suggestion => suggestion.name === name)?.textColor;
  }

  function getTypeBackgroundColor(name: string) {
    return suggestionsColorMap.find(suggestion => suggestion.name === name)?.backgroundColor;
  }

  const filteredSuggestions = computed<TypeSuggestion[]>(() => suggestions.value.filter(obj => obj.name.toLowerCase().indexOf(searchbarInput.value.toLowerCase()) === 0));

  const routeQueryCampaignsTypes = computed(() => route.query['campaigns-type'] ? route.query['campaigns-type']?.split(',') : []);
  const selectedCampaignsTypes = computed(() => suggestions.value.filter(suggestion => routeQueryCampaignsTypes.value.includes(suggestion._id)));

  function handleSelect(suggestion: TypeSuggestion) {
    const isAlreadySelected = selectedCampaignsTypes.value.some(campaignsType => campaignsType._id === suggestion._id);
    if (isAlreadySelected) {
      removeCampaignsType(suggestion);
    } else {
      router.push({
        ...route,
        query: {
          ...route.query,
          ['campaigns-type']: [...selectedCampaignsTypes.value.map(campaignsType => campaignsType._id), suggestion._id].join(','),
        },
      });
    }
  }

  function getIsSelectedStatus(suggestion: Pick<TypeSuggestion, 'key'>) {
    return selectedCampaignsTypes.value.some(campaignsType => campaignsType._id === suggestion._id);
  }

  function removeCampaignsType(suggestion: TypeSuggestion) {
    const selectedCampaignsTypeValues: TypeSuggestion[] = selectedCampaignsTypes.value.filter(campaignsType => campaignsType._id !== suggestion._id);

    router.push({
      ...route,
      query: {
        ...route.query,
        ['campaigns-type']: selectedCampaignsTypeValues.length ? selectedCampaignsTypeValues.map(campaignsType => campaignsType._id).join(',') : undefined,
      },
    });
  }
</script>

<template>
  <mv-filter
    :count="selectedCampaignsTypes.length"
    align="left"
    class="campaigns-filters-type"
    label="Type">
    <div class="flex w-full min-w-[400px] max-w-[575px] flex-col gap-2.5 p-[15px]">
      <bc-autocomplete
        v-model="searchbarInput"
        :is-dropdown-full-width="true"
        :keep-open="true"
        :loading="isLoading"
        :open-on-focus="true"
        :options="filteredSuggestions"
        :placeholder="$t('generics.search-placeholder', { object: $t('generics.a-type')})"
        class="min-w-[200px]"
        label="Type"
        name="type"
        v-bind="$attrs"
        @select="handleSelect"
        v-on="$listeners">
        <template #option="{ option }">
          <bc-dropdown-item
            :is-selected="getIsSelectedStatus(option)"
            :text="option.name"
            class="w-full">
          </bc-dropdown-item>
        </template>
      </bc-autocomplete>
      <div class="flex flex-wrap gap-2.5">
        <BcTag
          v-for="type in selectedCampaignsTypes"
          :key="type._id"
          :style="{
            color: getTypeTextColor(type.name),
            background: getTypeBackgroundColor(type.name),
          }"
          class="h-[20px]"
          deletable
          size="small"
          type="rounded"
          @click="removeCampaignsType(type)">
          {{ type.name }}
        </BcTag>
      </div>
    </div>
  </mv-filter>
</template>

