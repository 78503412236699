<script lang="ts" setup>
  import CardList from '@/components/CardList/CardList.vue';
  import Card from '@/components/Card/Card.vue';
  import { useStore } from '@/store';
  import { useRouter, useRoute } from 'vue-router/composables';
  import ProspectsCard from '@/components/Prospects/ProspectsCard.vue';

  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const props = defineProps<{
    cards: any[]
  }>();

  // const cards = store.state.card.cards;
  const selectedCards = store.state.card.selectedCards;

  type CampaignCard = {
    _id: string;
  }

  function setSelectedCards(value: boolean, card: CampaignCard) {
    const selectedCardsIds: string[] = selectedCards.map((card: CampaignCard) => card._id);

    if (!value && selectedCardsIds.includes(card._id)) {
      store.dispatch('setSelectedCards', selectedCards.filter((c: CampaignCard) => c._id !== card._id));
    } else if (value && !selectedCardsIds.includes(card._id)) {
      store.dispatch('setSelectedCards', [...selectedCards, card]);
    }
  }

  function getIsSelectedStatus(id: string) {
    return selectedCards.map((card: CampaignCard) => card._id).includes(id);
  }

  function goToCoder(id: string) {
    console.log('id', id);
    router.push({
      name: 'ProspectsPanel',
      params: {
        id,
      },
      query: {
        ...route.query,
        type: 'coders',
      },
    });
  }
</script>

<template>
  <div>
    <card-list
      ref="cardList"
      :cards="props.cards"
      :is-loaded="true"
      class="pagination__card-list">
      <template #card="{ card }">
        <Card
          :card-content="card"
          @click.native="goToCoder(card.coder._id)">
          <ProspectsCard
            :card-content="card"
            :is-selected="getIsSelectedStatus(card._id)"
            context="search-dbprospects"
            @checked="setSelectedCards($event, card)"
          >
          </ProspectsCard>
        </Card>
      </template>
    </card-list>
  </div>
</template>
