<script lang="ts" setup>
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import BcInput from '@/ui-kit/components/BcInput/BcInput.vue';
  import { computed, inject, ref } from 'vue';
  import ProvideKeys from '@/components/Campaigns/CampaignsProvideKeys';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete.vue';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
  import { capitalize } from '@/utils/stringTransform';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { Tab } from '@/components/Campaigns/CampaignsTypes';
  import { CampaignTemplate, CampaignType } from '@/domains/models/Campaign';
  import BcTag from '@/ui-kit/components/BcTag/BcTag.vue';
  import { useCampaignsStore } from '@/store/pinia/campaigns';
  import { useMutation } from '@tanstack/vue-query';
  import { createCampaign, editCampaign } from '@/api/campaigns';
  import { useStore } from '@/store';

  const props = defineProps<{
    tabs: Tab[];
    activeTab: Tab;
    activeTabIndex: number;
    nextTab: Tab;
  }>();

  const store = useStore();

  const emit = defineEmits<{
    (e: 'next'): void;
  }>();

  const { templateDraft, setTemplateDraftValue } = inject(ProvideKeys.templateDraftKey);
  const campaignsStore = useCampaignsStore();

  const searchInput = ref('');
  const matches = computed(() => campaignsStore.configuration.types.filter((type: CampaignType) => type.name?.toLowerCase()?.includes(searchInput.value.toLowerCase()) || type.slug?.toLowerCase()?.includes(searchInput.value.toLowerCase())));
  const selectedTypes = computed<CampaignType[]>(() => templateDraft.value.campaignTypes?.length ? templateDraft.value.campaignTypes : []);

  function createNewType() {
    console.log('createNewType');
  }

  function isTypeSelected(id: string): boolean {
    return templateDraft.value.campaignType?._id === id;
  }

  function setTemplateType(type: Pick<CampaignTemplate, '_id'>) {
    if (templateDraft.value?.campaignTypes?.some((t: Pick<CampaignTemplate, '_id'>) => t._id === type._id)) {
      templateDraft.value.campaignTypes = templateDraft.value?.campaignTypes.filter((t: Pick<CampaignTemplate, '_id'>) => t._id !== type._id);
    } else {
      templateDraft.value.campaignTypes = [type];
    }
  }

  const isNextDisabled = computed<boolean | (() => boolean)>(() => !templateDraft.value.name || !templateDraft.value.campaignTypes?.length);

  const createCampaignMutation = useMutation({
    mutationKey: ['campaigns', 'create'],
    mutationFn: async(campaign: { name: string, type: string }) => {
      const response = await createCampaign({
        name: campaign.name,
        _campaignTypes: campaign.type ? [campaign.type] : [],
      });

      setTemplateDraftValue(response.data);

      const backgroundCardIndex = await store.dispatch('updateCardFromId', {
        id: response.data._id,
        content: response.data,
      });

      if (backgroundCardIndex < 0) {
        await store.dispatch('unshiftCard', response.data);
      }

      return response.data;
    },
    onSuccess: campaign => {
      templateDraft.value = {
        ...templateDraft.value,
        ...campaign,
      };
      emit('next');
    },
  });

  const editCampaignMutation = useMutation({
    mutationKey: ['campaigns', 'edit'],
    mutationFn: async(campaign: { name: string, type: string }) => {
      const response = await editCampaign(templateDraft.value._id, {
        name: campaign.name,
      });

      setTemplateDraftValue(response.data);

      await store.dispatch('updateCardFromId', {
        id: templateDraft.value._id,
        content: {
          name: campaign.name,
        },
      });

      return response.data;
    },
    onSuccess: campaign => {
      templateDraft.value = {
        ...templateDraft.value,
        ...campaign,
      };
      emit('next');
    },

  });

  async function handleNextButton() {
    if (templateDraft.value._id) {
      await editCampaignMutation.mutateAsync({
        name: templateDraft.value.name,
        type: templateDraft.value.campaignTypes[0]._id,
      });
      return;
    }
    await createCampaignMutation.mutateAsync({
      name: templateDraft.value.name,
      type: templateDraft.value.campaignTypes[0]._id,
    });
  }
</script>

<template>
  <ValidationObserver
    ref="observer"
    class="flex w-full grow flex-col gap-[20px] py-[30px] pl-[30px]"
    tag="form"
    @submit.prevent>
    <ValidationProvider
      v-slot="{ errors, valid }"
      :rules="{ required: true }"
      name="name"
      slim>
      <bc-input
        ref="name"
        v-model="templateDraft.name"
        :class="{ invalid: errors.length > 0 }"
        :error="errors[0]"
        :label="$t('campaigns.name')"
        :label-placeholder="false"
        :placeholder="$t('campaigns.name')"
        :required="true"
        :valid="valid"
        class="campaigns-create-settings__dropdown w-full max-w-[430px]"
        name="name"
        type="text">
      </bc-input>
    </ValidationProvider>
    <div class="flex flex-col gap-2.5">
      <bc-autocomplete
        v-model="searchInput"
        :disabled="!!selectedTypes.length"
        :is-scrollable="false"
        :keep-first="true"
        :label="capitalize($t('campaigns.type-of-campaign'))"
        :limit="8"
        :options="matches"
        :placeholder="capitalize($t('campaigns.type-of-campaign'))"
        class="w-full max-w-[430px]"
        is-dropdown-full-width
        name="type-of-campaign"
        open-on-focus
        required
        @select="setTemplateType">
        <template #empty>
          <bc-dropdown-item
            :text="capitalize($t('generics.no-resource-found', { resource: 'type' }))"
            class="border-b border-blue-100">
          </bc-dropdown-item>
        </template>
        <template #option="{ option }">
          <bc-dropdown-item
            :is-selected="isTypeSelected(option._id)"
            :text="option.name"
            class="border-b border-blue-100">
          </bc-dropdown-item>
        </template>
        <template
          v-if="searchInput"
          #footer>
          <button
            class="is-full-width font-size-m is-secondary is-justify-content-center mx-auto py-3 text-blue-800 hover:bg-blue-100 hover:text-blue-500"
            type="button"
            @click.prevent.stop="createNewType">
            <p class="first-letter:capitalize">{{ $t('generics.add') }} "{{ searchInput }}"</p>
          </button>
        </template>
      </bc-autocomplete>
      <div
        v-if="selectedTypes.length"
        class="flex flex-wrap gap-2.5">
        <BcTag
          v-for="type in selectedTypes"
          :key="type._id"
          :deletable="true"
          class="!h-[20px] rounded-full !bg-[#0B66C2] px-3 py-1 !text-white"
          size="small"
          @click="setTemplateType(type)">
          {{ type.name }}
        </BcTag>
      </div>
    </div>
    <BcButton
      :disabled="isNextDisabled"
      class="mt-auto !w-full max-w-[300px]"
      size="small"
      @click="handleNextButton"
    >
      {{ $t('generics.next') }}
    </BcButton>
  </ValidationObserver>
</template>

<style lang="scss" scoped>
  .campaigns-create-settings {

  }
</style>
