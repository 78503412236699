<template>
  <div class="multiselect-footer" :class="{ 'left-[-330px]': hasActiveResumeUpload && hasActiveMessages }">
    <p class="multiselect-footer__text font-weight-bold is-secondary-dark mb-3">
      {{ footerText }}
    </p>
    <div class="is-align-items-center gap-3">
      <div v-if="isOnRemindersPage">
        <RemindersButtonsArchive v-if="!cardsArchived" @click.stop.native="archive" />
        <RemindersButtonsTransfer
          v-tooltip="t('candidates.transfer-this-reminder')"
          @click.stop.native="modal = 'transfer'" />
        <RemindersButtonsUnarchive v-if="cardsArchived" @unarchive="unarchive" />
        <RemindersButtonsLater v-if="!cardsArchived" @postpone="postponeReminder" />
        <RemindersButtonsDelete
          v-if="cardsArchived"
          v-tooltip="t('candidates.delete-this-reminder')"
          @click.stop.native="modal = 'confirmDelete'" />
      </div>
      <BcButton
        v-if="shouldDisplayRemoveFromListButton"
        data-button-remove-from-list
        icon-left="minus"
        type="outlined"
        @click="removeFromListSelectedCards">
        {{ t('companies.panel-company.remove-from-the-list') }}
      </BcButton>
      <BcButton
        v-if="isOnRemindersPage"
        class="ml-2.5 rounded-full"
        icon-left="plus"
        size="large"
        @click="openFavoriteListsPanel" />
      <BcButton v-else icon-left="addtolist" @click="openFavoriteListsPanel">
        {{ t('companies.panel-company.add-to-a-list') }}
      </BcButton>
      <BcButton
        v-if="isOnDatabaseCodersPage"
        icon-left="process-plus"
        @click.native="createProcess">
        {{ t('process.create-a-process') }}
      </BcButton>
      <ButtonEmail :is-company="isCompanyOrCompanyListPage" />
      <BcButton type="outlined" @click="resetSelectedCards">
        {{ t('generics.cancel') }}
      </BcButton>
    </div>
    <BcModal :active.sync="isModalOpen">
      <RemindersDeleteModal
        v-if="modal === 'confirmDelete'"
        @cancel="closeModal"
        @confirm="deleteReminder" />
      <RemindersTransferModal
        v-if="modal === 'transfer'"
        @cancel="closeModal"
        @confirm="confirmTransfer" />
    </BcModal>
  </div>
</template>

<script setup lang="ts">
import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
import favoriteLists from '@/api/favoriteLists';
import BcModal from '@/ui-kit/components/BcModal/BcModal.vue';
import RemindersTransferModal from '@/components/Reminders/RemindersTransferModal.vue';
import RemindersDeleteModal from '@/components/Reminders/RemindersDeleteModal.vue';

import RemindersButtonsTransfer from '@/components/Reminders/RemindersButtons/RemindersButtonsTransfer.vue';
import RemindersButtonsLater from '@/components/Reminders/RemindersButtons/RemindersButtonsLater.vue';
import RemindersButtonsDelete from '@/components/Reminders/RemindersButtons/RemindersButtonsDelete.vue';
import RemindersButtonsArchive from '@/components/Reminders/RemindersButtons/RemindersButtonsArchive.vue';
import RemindersButtonsUnarchive from '@/components/Reminders/RemindersButtons/RemindersButtonsUnarchive.vue';
import ButtonEmail from '@/components/Button/ButtonEmail';
import suggestionsHttp from '@/api/suggestions';

import suggestionsMacros from '@/macros/suggestions';
import { ref, computed } from 'vue';
import { useToast } from '@/ui-kit/components/BcToast';
import { useStore } from '@/store';
import { useI18n } from '@/i18n/i18n';
import { useRoute, useRouter } from 'vue-router/composables';

const emit = defineEmits<{
  (e: 'archived'): void;
  (e: 'unarchived'): void;
}>();

const store = useStore();
const route = useRoute();
const router = useRouter();
const toast = useToast();

const { t, tc } = useI18n();

const modal = ref<string>();
const isModalOpen = computed(() => !!modal.value);

const selectedCards = computed(() => store.state.card.selectedCards);
const hasActiveResumeUpload = computed(() => store.getters['resumeHistories/hasActiveResumeUpload']);
const hasActiveMessages = computed(() => store.getters['messageHistories/hasActiveMessages']);

const footerText = computed(() => {
  return selectedCards.value.length > 1
    ? ` ${t('companies.account-management.selection-of')} ${selectedCards.value.length} ${tc('generics.profile', 2)} :`
    : `${t('companies.account-management.selection-of')} ${selectedCards.value.length} ${tc('generics.profile', 1)} :`;
});

const isOnDatabaseCodersPage = computed(() => {
  return route.name === 'Coders' || route.name === 'CodersPanel';
});

const isOnRemindersPage = computed(() => {
  return route.name?.includes('Reminders');
});

const isOnListPage = computed(() => {
  return route.name?.includes('FavoriteList');
});

const isCompanyOrCompanyListPage = computed(() => {
  return route.name?.includes('Partners') || (route.name?.includes('FavoriteList') && route.query['list-type'] === 'company');
});

const favoriteList = computed(() => {
  const isOnSourcingPage = route.name?.includes('CandidatePipe');
  const isOnCodersListPage = isOnListPage.value && route.query['list-type'] === 'coder';
  const isOnFailureCodersPage =
    route.name === 'FailureCoders' || route.name === 'FailureCodersPanel';
  if (
    isOnSourcingPage ||
    isOnDatabaseCodersPage.value ||
    isOnCodersListPage ||
    isOnRemindersPage.value ||
    isOnFailureCodersPage
  )
    return 'favorite-coder-list';

  if (route.name?.includes('Contact')) return 'favorite-contact-list';

  return 'favorite-company-list';
});

const shouldDisplayRemoveFromListButton = computed(() => {
  return isOnListPage.value;
});

const cardsArchived = computed(() => {
  return selectedCards.value.every(selectedCard => selectedCard.archived === true);
});

function closeModal() {
  modal.value = undefined;
}

async function updateSelectedCards({
  call,
  params,
  errorMessage,
  successMessage,
  informationMessage,
  removeSelectedCards,
}) {
  let count = 0;

  store.dispatch('setIsRequestProcessing', true);

  for (const index in selectedCards.value) {
    try {
      const { data } = await call(index, params);

      if (removeSelectedCards) {
        removeSelectedCards(selectedCards.value[index]);
      } else if (data) {
        store.dispatch('updateCardFromId', {
          id: selectedCards.value[index]._id,
          content: data,
        });
      }
      ++count;
    } catch (error) {
      toast.show({
        title: errorMessage,
        message: `${error?.response?.data?.message} - id: ${selectedCards.value[index]._id}`,
        type: 'error',
        icon: 'cross',
      });
    }
  }

  store.dispatch('setIsRequestProcessing', false);

  closeModal();

  if (count) {
    toast.show({
      title: t('generics.success'),
      message: `${count} ${successMessage}`,
      type: 'success',
      icon: 'check',
    });
  } else {
    toast.show({
      title: tc('generics.information', 0),
      message: informationMessage,
      type: 'info',
      icon: 'info-full',
    });
  }
}

async function removeFromListSelectedCards() {
  await updateSelectedCards({
    call: removeResourceFromList,
    errorMessage: t(
      'companies.account-management.error-when-changing-interest-relative-to-selected-coder',
    ),
    successMessage: t('companies.account-management.coders-skipped-successfully'),
    informationMessage: t('companies.account-management.cannot-ignore-these-coders'),
  });

  resetSelectedCards();
}

function transferCoder(index, { coachId }) {
  const suggestion = selectedCards.value[index];
  return suggestionsHttp.transferSuggestion(suggestion._id, coachId);
}

function deleteCoderReminder(index) {
  const suggestion = selectedCards.value[index];
  return suggestionsHttp.rejectSuggestion(suggestion._id);
}

function postponeCoderReminder(index, { nbDays }) {
  const suggestion = selectedCards.value[index];

  return suggestionsHttp.postponeSuggestion(suggestion._id, nbDays);
}

function removeSelectedReminders(suggestion) {
  store.dispatch('removeCard', suggestion._id);

  if (suggestion.status === suggestionsMacros.status.todo.value) {
    store.dispatch('removeSuggestionFromToDoSuggestions', suggestion._id);
  }
}

async function archive() {
  await updateSelectedCards({
    call: archiveReminder,
    errorMessage: t('reminders.error-archiving'),
    successMessage: t('reminders.successfully-archived'),
    informationMessage: t('reminders.unable-to-archive'),
    removeSelectedCards: removeSelectedReminders,
  });
  closeModal();
  emit('archived');
}

function archiveReminder(index) {
  const suggestion = selectedCards.value[index];
  return suggestionsHttp.archiveSuggestion(suggestion._id);
}

async function unarchive({ nbDays }) {
  await updateSelectedCards({
    call: index => unarchiveSuggestion(index, nbDays),
    errorMessage: t('reminders.error-reactivating'),
    successMessage: t('reminders.successfully-reactivated'),
    informationMessage: t('reminders.unable-to-reactivate'),
    removeSelectedCards: removeSelectedReminders,
  });
  closeModal();
  emit('unarchived');
}

function unarchiveSuggestion(index, nbDays) {
  const suggestion = selectedCards.value[index];
  return suggestionsHttp.unarchiveSuggestion(suggestion._id, nbDays);
}

async function confirmTransfer(coach) {
  await updateSelectedCards({
    call: transferCoder,
    params: { coachId: coach._id },
    errorMessage: t('reminders.error-forwarding'),
    successMessage: t('reminders.successfully-forwarded'),
    informationMessage: t('reminders.unable-to-forward'),
    removeSelectedCards: removeSelectedReminders,
  });

  resetSelectedCards();
  closeModal();
}

async function postponeReminder(option) {
  await updateSelectedCards({
    call: postponeCoderReminder,
    params: { nbDays: option.nbDays },
    errorMessage: t('reminders.error-postponing'),
    successMessage: t('reminders.successfully-postponed'),
    informationMessage: t('reminders.unable-to-postpone'),
    ...(route.query.filter !== 'postponed' && {
      removeSelectedCards: removeSelectedReminders,
    }),
  });

  resetSelectedCards();
}

async function deleteReminder() {
  closeModal();
  await updateSelectedCards({
    call: deleteCoderReminder,
    errorMessage: t('reminders.error-deleting'),
    successMessage: t('reminders.successfully-deleted'),
    informationMessage: t('reminders.unable-to-delete'),
    removeSelectedCards: removeSelectedReminders,
  });

  resetSelectedCards();
}

function removeResourceFromList(index) {
  const card = selectedCards.value[index];

  if (isOnListPage.value) {
    store.dispatch('removeCard', card._id);
  }

  if (['coder', 'contact'].includes(route.query['list-type'])) {
    return favoriteLists.removeCoderFromFavoriteList({
      listId: route.query['list-id'],
      coderId: card._id,
    });
  }

  return favoriteLists.removeCompanyFromFavoriteList({
    listId: route.query['list-id'],
    companyId: card._id,
  });
}

function openFavoriteListsPanel() {
  router.push({
    name: `${route.name}Panel`,
    params: {
      id: 'favorite-lists',
    },
    query: {
      ...route.query,
      type: favoriteList.value,
      subtype: 'add-resources',
    },
  });
}

function resetSelectedCards() {
  store.dispatch('setSelectedCards', []);
}

function createProcess() {
  router.push({
    ...route,
    name: `${route.name}Panel`,
    params: {
      id: 'panel',
    },
    query: {
      ...route.query,
      type: 'create-process',
      from: 'multi-select',
    },
  });
}
</script>

<style lang="scss" scoped>
.multiselect-footer {
  background: linear-gradient(0deg, white 70%, transparent);
  z-index: 2;
  padding: 20px 0 20px;

  &__link {
    &:hover {
      text-decoration: initial;
    }
  }
}
</style>
