<script lang="ts" setup>
  import { computed } from 'vue';
  import { useLinkedinTemplatesStore } from '@/store/pinia/linkedinTemplates';
  import { useStore } from '@/store';
  import { useQuery } from '@tanstack/vue-query';
  import CampaignsConditionsDropdown
    from '@/components/Campaigns/CampaignsConditions/CampaignsConditionsDropdown.vue';
  import {
    CampaignNextCondition,
    CampaignNextConditionKey,
    CampaignStep,
  } from '@/components/Campaigns/CampaignsTypes';

  const templatesStore = useLinkedinTemplatesStore();
  const store = useStore();
  const profile = computed(() => store.state.user.profile);

  const props = defineProps<{
    step: CampaignStep
  }>();

  const emit = defineEmits<{
    (e: 'update-step', value: CampaignStep): void
  }>();

  const availableConditions: CampaignNextConditionKey[] = ['DELAY', 'PROFILE_VISITED', 'IMMEDIATELY_AFTER_STEP'];

  useQuery({
    queryKey: ['fetch-linkedin-templates'],
    queryFn: async() => {
      await templatesStore.fetchLinkedinTemplates({ coachId: profile.value._id, all: null });

      return [];
    },
  });

  useQuery({
    queryKey: ['fetch-email-variables'],
    queryFn: async() => {
      return store.dispatch('emails/getTemplatesVariables');
    },
  });

  function handleUpdateCondition({ condition, index }: {
    condition: CampaignNextCondition,
    index: number
  }) {
    const nextConditions = [...props.step.nextConditions];
    nextConditions[index] = condition;

    emit('update-step', {
      ...props.step,
      nextConditions,
    });
  }

  function handleAddCondition(condition: CampaignNextCondition) {
    emit('update-step', {
      ...props.step,
      nextConditions: [...props.step.nextConditions, condition],
    });
  }

  function handleRemoveCondition(condition: CampaignNextCondition) {
    emit('update-step', {
      ...props.step,
      nextConditions: props.step.nextConditions.filter(selectedCondition => selectedCondition.key !== condition.key),
    });
  }
</script>

<template>
  <div class="flex max-w-[600px] flex-col">
    <div class="mb-3.5 flex gap-2.5">
      <i
        class="icon-send flex size-[30px] items-center justify-center rounded-full bg-[#dbe2ff]"/>
      <h1 class="text-xl text-blue-800">{{ $t('campaigns.steps.linkedin-profile-visit') }}</h1>
    </div>
    <div
      class="mb-[20px] flex w-full max-w-[600px] gap-2.5 rounded-xl bg-orange-100 px-[20px] py-3.5">
      <i class="icon-chat-info text-orange-500"/>
      <p class="text-sm text-orange-500">{{
          $t('campaigns.linkedin-invitation-warning-message')
        }}</p>
    </div>
    <CampaignsConditionsDropdown
      :available-conditions="availableConditions"
      :selected-conditions="step.nextConditions"
      @add-condition="handleAddCondition"
      @update-condition="handleUpdateCondition"
      @remove-condition="handleRemoveCondition"
    />
  </div>
</template>

<style lang="scss" scoped>

</style>
