import type { App } from 'vue';
import { MvIcon } from './components';

const mapKeys = <T, K extends string>(object: Record<K, T>, iterate: (value: T, key: K) => string): Record<string, T> => {
    const result: Record<string, T> = {};

    Object.entries(object).forEach(([key, value]) => {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            // @ts-ignore
            const newKey = iterate(value, key as K);
            // @ts-ignore
            result[newKey] = value;
        }
    });

    return result;
};
export default {
    install: (app: App) => {
        app.component('MvIcon', MvIcon);
        /**
         * Cast imported svg to string
         */
        const modules = import.meta.glob('../../ui-kit/assets/icons/*.svg', {
            query: '?raw',
            import: 'default',
        }) as unknown as Record<string, () => Promise<string>>;
        
        // Get the name of the icon without extension

        const getModuleName = (_: any, key: string) => {
            const path = key.split(/[/.]+/g);
            return path[path.length - 2];
        };
        // Makes a map of all the icons indexed by name without extension
        const icons = mapKeys(modules, getModuleName);

        app.prototype.$marvinIcons = icons;
    },
};

export { MvIcon };
