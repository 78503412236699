<template>
  <div class="dashboard-modules-activity-filters">
    <filters-dropdown-coach
      :has-none-option="false"
      :selected-coach-id="selectedCoachId"
      class="mr-3 dashboard-modules-activity-filters__filter"
      @update:selected-coach-id="handleCoachSelection">
    </filters-dropdown-coach>
    <filters-dropdown-periods
      class="dashboard-modules-activity-filters__filter"
      :query-name="`${this.moduleName}Period`"
      @select="setSelectedPeriod">
    </filters-dropdown-periods>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import FiltersDropdownCoach from '@/components/Filters/FiltersDropdown/FiltersDropdownCoach';
  import FiltersDropdownPeriods from '@/components/Filters/FiltersDropdown/FiltersDropdownPeriods';

  export default {
    name: 'dashboard-modules-filters',
    components: { FiltersDropdownPeriods, FiltersDropdownCoach },
    props: {
      moduleName: {
        type: String,
        default: 'default',
      },
    },
    computed: {
      ...mapState({
        coaches: state => state.coaches?.coaches,
        profile: state => state.user.profile,
      }),
      selectedCoachId() {
        return this.$route.query[`${this.moduleName}CoachId`];
      },
    },
    methods: {
      handleCoachSelection(coachId) {
        this.$router.push({
          query: {
            ...this.$route.query,
            [`${this.moduleName}CoachId`]: coachId,
          },
        });
      },
      setSelectedPeriod(option) {
        this.$router.push({
          query: {
            ...this.$route.query,
            [`${this.moduleName}Period`]: option.query,
            [`${this.moduleName}Start`]: option.from,
            [`${this.moduleName}End`]: option.to,
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .dashboard-modules-activity-filters {
    &__filter {
      font-size: $font-size-s;
    }
  }
</style>
