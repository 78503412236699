
export const domains = {
  frontendIntegrator: { api: 7, name: 'markets.front-end-integration', query: 'frontendIntegrator' },
  frontendDeveloper: { api: 2, name: 'markets.frontendDeveloper', query: 'frontendDeveloper' },
  backendDeveloper: { api: 3, name: 'markets.backendDeveloper', query: 'backendDeveloper' },
  fullstackFrontend: { api: 5, name: 'markets.fullstackFrontend', query: 'fullstackFrontend' },
  fullstackBackend: { api: 6, name: 'markets.fullstackBackend', query: 'fullstackBackend' },
  systemNetwork: { api: 11, name: 'markets.systemNetwork', query: 'systemNetwork' },
  devops: { api: 12, name: 'markets.devops', query: 'devops' },
  dba: { api: 13, name: 'markets.dba', query: 'dba' },
  security: { api: 14, name: 'markets.security', query: 'security' },
  support: { api: 15, name: 'markets.support', query: 'support' },
  native: { api: 21, name: 'markets.native', query: 'native' },
  crossPlatform: { api: 22, name: 'markets.crossPlatform', query: 'crossPlatform' },
  science: { api: 31, name: 'markets.science', query: 'science' },
  engineering: { api: 32, name: 'markets.engineering', query: 'engineering' },
  analysis: { api: 33, name: 'markets.analysis', query: 'analysis' },
  machineLearning: { api: 34, name: 'markets.machineLearning', query: 'machineLearning' },
  designer: { api: 41, name: 'markets.designer', query: 'designer' },
  projectManagement: { api: 46, name: 'markets.projectManagement', query: 'projectManagement' },
  qa: { api: 45, name: 'markets.qa', query: 'qa' },
  software: { api: 51, name: 'markets.software', query: 'software' },
  embedded: { api: 52, name: 'markets.embedded', query: 'embedded' },
  cto: { name: 'markets.cto', api: 61, query: 'cto' },
  vpOfEngineering: { name: 'markets.vpOfEngineering', api: 62, query: 'vpOfEngineering' },
  engineeringManager: { name: 'markets.engineeringManager', api: 63, query: 'engineeringManager' },
  cpo: { name: 'markets.cpo', api: 64, query: 'cpo' },
  vpOfProduct: { name: 'markets.vpOfProduct', api: 65, query: 'vpOfProduct' },
  other: { name: 'markets.other', api: 99, query: 'other' },
  fullstack: { api: 4, name: 'markets.fullstack', query: 'fullstack' }, // Legacy
};

const domainsCategories = {
  management: {
    name: 'markets.management',
    icon: 'partners',
    domains: [
      domains.cto,
      domains.vpOfEngineering,
      domains.engineeringManager,
      domains.cpo,
      domains.vpOfProduct,
      domains.other,
    ],
  },
  web: {
    name: 'markets.web',
    icon: 'web',
    domains: [
      domains.frontendIntegrator,
      domains.frontendDeveloper,
      domains.backendDeveloper,
      domains.fullstackFrontend,
      domains.fullstackBackend,
      domains.fullstack,
    ],
  },
  softwareEmbedded: {
    name: 'markets.sofware-embedded',
    icon: 'software',
    domains: [
      domains.software,
      domains.embedded,
    ],
  },
  product: {
    name: 'markets.product',
    icon: 'product',
    domains: [
      domains.designer,
      domains.projectManagement,
      domains.qa,
    ],
  },
  infra: {
    name: 'markets.infra',
    icon: 'infra',
    domains: [
      domains.systemNetwork,
      domains.devops,
      domains.dba,
      domains.security,
      domains.support,
    ],
  },
  mobile: {
    name: 'markets.mobile',
    icon: 'mobile',
    domains: [
      domains.native,
      domains.crossPlatform,
    ],
  },
  dataIA: {
    name: 'markets.data-ia',
    icon: 'data-ia',
    domains: [
      domains.science,
      domains.engineering,
      domains.analysis,
      domains.machineLearning,
    ],
  },
};

export const getDomainFromApiValue = apiValue => Object.values(domains).find(domain => domain.api === apiValue);

export const getDomainFromName = name => Object.values(domains).find(domain => domain.name === name);

export const getDomainCategory = apiValue => Object.values(domainsCategories)
  .find(category => category.domains.find(domain => domain.api === apiValue));

export const getDomainsCategories = () => Object.values(domainsCategories);

export const getProfileDomainsCategories = () => getDomainsCategories().map(category => ({
  ...category,
  domains: category.domains.filter(domain => domain !== domains.fullstack),
}));
