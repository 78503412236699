<template>
  <div class="processes-form-deal-alerts is-column">
    <div
      :key="process._id"
      class="is-row is-align-items-center processes-form-deal-alerts__alerts"
      v-for="process in formDealToDo">
      <div class="icon-alert processes-form-deal-alerts__alerts-icon"></div>
      <p>
        {{ $t('candidates.you-have-the') }}
        <router-link
          :to="{
            name: 'ProcessesPanel',
            params: {
              id: process._id,
            },
            query: {
              ...$route.query,
              type: 'process',
              'modal-type': 'modal-process-form-deal'
            }
          }"
          class="processes-form-deal-alerts__alerts-link">
          {{ $t('candidates.form-deal') }}
        </router-link>
        {{ $t('candidates.of') }} {{ process._coder.firstName }} {{ process._coder.lastName }} {{ $t('candidates.to-finish') }}
      </p>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'processes-form-deal-alerts',
    props: {
      formDealToDo: {
        type: Array,
        default: () => ([]),
      },
    },
  };
</script>
<style scoped lang=scss>
  .processes-form-deal-alerts {
    &__alerts {
      margin-top: 5px;
      color: $color-secondary;
    }

    &__alerts-link {
      @include font-bold();
      color: $color-primary;
    }

    &__alerts-icon {
      color: $color-warning;
      margin-right: 5px;
    }
  }
</style>
