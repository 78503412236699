<template>
  <component
    :is="panelCoachEditCaptionComponent"
    v-bind="$attrs"
    v-on="$listeners">
  </component>
</template>

<script>
  import PanelEditCoachCaptionCoach
    from '@/components/Panel/PanelCoach/PanelCoachEdit/PanelCoachEditCaption/PanelCoachEditCaptionCoach';
  import PanelEditCoachCaptionRpo
    from '@/components/Panel/PanelCoach/PanelCoachEdit/PanelCoachEditCaption/PanelCoachEditCaptionRpo';

  export default {
    name: 'panel-coach-edit-caption',
    inheritAttrs: false,
    components: {
      PanelEditCoachCaptionRpo,
      PanelEditCoachCaptionCoach,
    },
    computed: {
      panelCoachEditCaptionComponent() {
        return this.$store.getters['user/isSuperUser'] ? 'panel-edit-coach-caption-coach' : 'panel-edit-coach-caption-rpo';
      },
    },
  };
</script>
