import { http } from '@/api/index';
import { i18n } from '../i18n/i18n';

export const apiContracts = {
  cdi: 1,
  freelance: 2,
  stage: 3,
  alternance: 4,
  cdd: 5,
  consultant: 6,
  interim: 7,
  intermittent: 8,
};

export const salaryTypes = {
  yearly: 1,
  monthly: 2,
  weekly: 3,
  daily: 4,
  fixed: 5,
};

export const salaryTypesNew = {
  grossPerYear: 'grossPerYear',
  grossPerMonth: 'grossPerMonth',
  netPerMonth: 'netPerMonth',
  htPerDay: 'htPerDay',
  percentage: 'percentage',
}

export const contractDuration = {
  fullTime: 1,
  partTime: 2,
}

export const salaryTypesRange = {
  [salaryTypes.yearly]: {
    min: 1,
    max: 500000,
    step: 1000,
  },
  [salaryTypes.monthly]: {
    min: 1,
    max: 40000,
    step: 100,
  },
  [salaryTypes.weekly]: {
    min: 1,
    max: 10000,
    step: 10,
  },
  [salaryTypes.daily]: {
    min: 1,
    max: 2000,
    step: 2,
  },
  [salaryTypes.fixed]: {
    min: 1,
    max: 500000,
    step: 1000,
  },
}

export const contractsLabels = {
  [apiContracts.cdi]: i18n.t('contracts.cdi'),
  [apiContracts.freelance]: i18n.t('contracts.freelance'),
  [apiContracts.stage]: i18n.t('contracts.stage'),
  [apiContracts.alternance]: i18n.t('contracts.alternance'),
  [apiContracts.cdd]: i18n.t('contracts.cdd'),
  [apiContracts.consultant]: i18n.t('contracts.consultant'),
  [apiContracts.interim]: i18n.t('contracts.interim'),
  [apiContracts.intermittent]: i18n.t('contracts.intermittent'),
}

export const contractDurationLabels = {
  [contractDuration.fullTime]: i18n.t('contracts.full-time'),
  [contractDuration.partTime]: i18n.t('contracts.part-time'),
}

export const salaryLabels = {
  [salaryTypes.yearly]: i18n.t('generics.yearly'),
  [salaryTypes.monthly]: i18n.t('generics.monthly'),
  [salaryTypes.weekly]: i18n.t('generics.weekly'),
  [salaryTypes.daily]: i18n.t('generics.daily'),
  [salaryTypes.fixed]: i18n.t('generics.fixed'),
}

export const salaryLabelsShort = {
  [salaryTypes.yearly]: i18n.tc('generics.in-year', 1),
  [salaryTypes.monthly]: i18n.tc('generics.in-month', 1),
  [salaryTypes.weekly]: i18n.tc('generics.in-week', 1),
  [salaryTypes.daily]: i18n.tc('generics.in-day', 1),
  [salaryTypes.fixed]: i18n.t('generics.fixed'),
}

export const salaryTypesLabels = {
  [salaryTypesNew.grossPerYear]: i18n.t('salary-types.grossPerYear'),
  [salaryTypesNew.grossPerMonth]: i18n.t('salary-types.grossPerMonth'),
  [salaryTypesNew.netPerMonth]: i18n.t('salary-types.netPerMonth'),
  [salaryTypesNew.htPerDay]: i18n.t('salary-types.htPerDay'),
  [salaryTypesNew.percentage]: i18n.t('salary-types.percentage'),
}

export const descriptionTypes = {
  organization: 'organization',
  client: 'client',
  custom: 'custom',
}

export const getCustomJobs = (params) => {
  return http.get('/custom-jobs', {
    params: {
      ids: params?.ids,
      coachId: params?.coachId,
      teamId: params?.teamId,
      companyId: params?.companyId,
      active: params?.active,
      professionIds: params?.professionIds,
      skillIds: params?.skillIds,
      professionsOperator: params?.professionsOperator,
      skillsOperator: params?.skillsOperator,
      locations: params?.locations,
      distance: params?.distance,
      priority: params?.priority,
      page: params?.page,
      companiesIds: params?.companiesIds,
      searchInput: params?.searchInput,
      customFields: params?.customFields,
      uniqueids: params?.uniqueids,
    }
  })
}

export const getCustomJobsById = (id) => {
  if (!id) return;
  return http.get(`/custom-jobs/${id}`)
}

export const getCustomJobKpis = (id) => {
  if (!id) return;
  return http.get(`/custom-jobs/${id}/kpis`);
}

export const createCustomJob = (params) => {
  return http.post('/custom-jobs', {
    companyId: params.companyId,
    employeeId: params?.employeeId,
    customFields: params?.customFields,
    owners: params?.owners, // CoachID
    title: params?.title,
    description: params?.description,
    profileDescription: params?.profileDescription,
    ...params.descriptionType && { descriptionType: params?.descriptionType },
    contract: params?.contract,
    contractDuration: params?.contractDuration,
    contractStartDate: params?.contractStartDate || '',
    contractEndDate: params?.contractEndDate || '',
    salaryType: params?.salaryType, // salaryTypes: { yearly: 1, monthly: 2, weekly: 3, daily: 4, fixed: 5,
    salaryMin: params?.salaryMin,
    salaryMax: params?.salaryMax,
    duration: params?.duration,
    professionIds: params?.professionIds,
    skillIds: params?.skillIds,
    locations: params?.locations,
    active: params?.active,
    adPublished: params?.adPublished,
    budget: params?.budget,
  })
}

export const updateCustomJob = ({ id, ...params }) => {
  const { withActions = true } = params;

  let locations = params?.locations;
  if (locations && locations.length && typeof locations[0] !== 'string') {
    locations = locations.map(location => location.placeId);
  }

  return http.patch(`/custom-jobs/${id}`, {
    companyId: params?.companyId,
    employeeId: params?.employeeId,
    owners: params?.owners,
    customFields: params?.customFields,
    title: params?.title,
    description: params?.description,
    descriptionType: params?.descriptionType,
    profileDescription: params?.profileDescription,
    customCompanyDescription: params?.customCompanyDescription,
    contract: params?.contract,
    contractDuration: params?.contractDuration,
    contractStartDate: params?.contractStartDate || '',
    contractEndDate: params?.contractEndDate || '',
    salaryType: params?.salaryType,
    salaryMin: params?.salaryMin,
    salaryMax: params?.salaryMax,
    duration: params?.duration,
    professionIds: params?.professionIds,
    skillIds: params?.skillIds,
    locations,
    active: params?.active,
    adPublished: params?.adPublished,
    closeOptions: params?.closeOptions,
    budget: params?.budget,
    withActions,
  })
}

export const deleteCustomJob = (id) => {
  return http.delete(`/custom-jobs/${id}`)
}

export const createCustomJobPost = ({ id, data }) => {
  if (!id) return;
  return http.post(`/custom-jobs/${id}/post-descriptions`, data);
}

export const duplicateCustomJob = (id) => {
  return http.post(`/custom-jobs/${id}/duplicate`);
}

export const getCustomJobPosts = (id) => {
  return http.get(`/custom-jobs/${id}/post-descriptions`);
}

export const removeCustomJobPost = (id) => {
  return http.delete(`/post-descriptions/${id}`);
}

export const updateCustomJobPosition = ({ id, ...params }) => {
  return http.patch(`custom-jobs/${id}/position`, {
    positions: params?.positions,
  });
}

export const getCustomJobReporting = (id) => {
  return http.get(`/custom-jobs/${id}/reporting`, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Disposition': `attachment; filename="rapport-${id}.xlsx"`,
    },
  });
}
