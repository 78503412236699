<template>
  <div class="panel-view-pdf">
    <object
      :data="pdfUrl"
      class="panel-view-pdf__object" type="application/pdf">
      <iframe :src="pdfUrl"></iframe>
    </object>
  </div>
</template>

<script>

  export default {
    name: 'panel-view-pdf',
    props: {
      /**
       * @return {String}
       * The pdf url as a string
       */
      pdfUrl: {
        type: String,
        default: '',
      },
      /**
       * @return {String}
       * Message to display when pdf is not defined
       */
      emptyMessage: {
        type: String,
        default: 'Aucun document à afficher',
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-view-pdf {
    height: 100%;
    width: 100%;

    &__text {
      width: inherit;
      margin-top: 70px;
      justify-content: center;
      font-size: $font-size-m;
      color: $color-blue-heavy-dark;
      display: flex;
      @include bp('tablet') {
        margin-top: 20px;
      }
    }

    &__object {
      width: 100%;
      height: 100%;
    }
  }
</style>
