import { ComputedRef, InjectionKey } from 'vue';
import {
  DetailsCount,
  ProfileDetailsCount,
  SelectedFields,
} from '@/components/Panel/PanelCompany/PanelCompanyModal/types';
import { Company } from '@/domains/models/Company';

const selectedFields = Symbol() as InjectionKey<SelectedFields>;
const profileDetailsCount = Symbol() as InjectionKey<ProfileDetailsCount>;
const mergeProfiles = Symbol() as InjectionKey<{
  leftProfile: ComputedRef<Company>;
  rightProfile: ComputedRef<Company>;
}>;

export default {
  selectedFields,
  profileDetailsCount,
  mergeProfiles,
};
