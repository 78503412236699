import { http } from './index';

export const getHuntJobs = async(hunt) => {
  const huntId = hunt.call;

  return http.get(`/hunt/${huntId}/jobs`);
};

export const getHuntPosts = id => {
  return http.get(`/hunt/${id}/post-descriptions`);
};

export const getCompanyOpportunities = id => {
  return http.get(`/hunt/company/${id}`);
};

export const createHuntPost = ({ id, data }) => {
  return http.post(`/hunt/${id}/post-descriptions`, data);
};

export const removeHuntPost = id => {
  return http.delete(`/post-descriptions/${id}`);
};

export default {
  getHuntJobs,
  getHuntPosts,
  getCompanyOpportunities,
  createHuntPost,
  removeHuntPost,
};
