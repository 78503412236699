<template>
  <div
    class="card-coders is-full-width is-column is-overflow-hidden border-radius-m is-relative"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
    @click.exact="openPanel({ id: cardContent._id, type: 'coders',  context })"
    @click.meta.exact="
      openPanelInNewTab({ id: cardContent._id, type: 'coders',  context })
    ">
    <div class="card-coders__container is-full-width is-align-items-start is-relative">
      <div class="card-coders__wrapper card-coders__checkbox is-align-items-center" @click.stop>
        <bc-checkbox :id="cardContent._id" :value="isSelected" @input="$emit('checked', $event)">
        </bc-checkbox>
      </div>
      <div class="card-coders__wrapper is-full-width is-column card-coders__id">
        <div class="is-secondary-dark is-align-items-center is-full-width gap-[10px]">
          <bc-avatar
            :src="cardContent.pictureUrl"
            class="card-coders__avatar is-unshrink"
            size="s"
            @error="setAlternativeImg">
          </bc-avatar>
          <div class="card-coders__name is-column is-align-items-start">
            <p
              v-tooltip="fullName"
              class="card-coders__text body-l--medium is-ellipsis is-full-width">
              {{ fullName }}
            </p>
            <p
              v-if="cardContent.title"
              v-tooltip="cardContent.title"
              class="card-coders__text body-l is-ellipsis is-full-width">
              {{ profileTitle }}
            </p>
          </div>
        </div>
      </div>
      <div class="card-coders__wrapper card-coders__contact-info is-align-items-center hidden-xs">
        <card-tags :tags="contactInfos" class="card-coders__tag-wrapper" reference="contact-info">
          <template #tag="{ tag }">
            <tag v-tooltip="{ content: tag.name }" :icon="tag.type" :text="tag.name">
              {{ tag.name }}
            </tag>
          </template>
          <template #remaining="{ remaining }">
            <tag :show-icon="false" :text="`+${remaining}`"></tag>
          </template>
        </card-tags>
      </div>
      <div class="card-coders__wrapper card-coders__link is-align-items-center hidden-xs hidden-sm">
        <a
          v-if="(cardContent.social || {}).linkedin"
          v-tooltip="$t('companies.panel-company-contracts.open-the-linkedin-profile')"
          :href="(cardContent.social || {}).linkedin"
          target="_blank"
          @click.stop>
          <img
            alt="logo linkedin"
            class="card-coders__icon"
            src="@/assets/logo/logo_linkedin.svg"/>
        </a>
        <a
          v-else-if="cardContent.cvUrl"
          v-tooltip="$t('companies.panel-company-contracts.open-the-cv')"
          :href="cardContent.cvUrl"
          class="font-weight-bold font-size-xl"
          target="_blank"
          @click.stop>
          {{ $t('panel-coder-edit.cv') }}
        </a>
      </div>
      <div
        class="card-coders__wrapper card-coders__company is-align-items-center hidden-xs overflow-hidden">
        <div
          v-if="companiesInfo.length"
          class="is-secondary-dark is-align-items-center w-full overflow-hidden">
          <bc-avatar
            v-for="(company, index) in companiesInfo.slice(0,2)"
            v-tooltip="companiesInfo.length > 1 ? company.name : ''"
            :class="{ '-ml-2': companiesInfo.length > 1 && index, 'mr-2': companiesInfo.length === 1 }"
            :src="company.logoUrl"
            class="card-coders__avatar is-unshrink bg-white"
            size="s"
            @error="setAlternativeCompanyImg">
          </bc-avatar>
          <v-popover
            :delay="{ hide: 500 }"
            :trigger="'click hover'"
            class="z-10 -ml-2">
            <p
              v-if="companiesInfo.length > 2"
              class="flex size-[30px] items-center justify-center rounded-full bg-blue-800 text-white">
              +{{ companiesInfo?.slice(2)?.length }}
            </p>
            <template #popover>
              <div class="-ml-2 flex flex-col gap-1 p-[10px]">
                <div
                  v-for="company in companiesInfo.slice(2)"
                  class="flex cursor-pointer justify-center px-2 hover:underline"
                  @click="openPanel({
                    id: company._id,
                    type: 'companies',
                  })">
                  <div class="flex gap-1 text-white">
                    <ImageWithDefault
                      :default-image-url="defaultCompanyAvatarTooltip"
                      :url="company.logoUrl || defaultCompanyAvatarTooltip"
                      alt="companyLogo"
                      class="!h-[20px] !w-[20px] rounded-full"
                    />
                    {{ company.name }}
                  </div>
                </div>
              </div>
            </template>
          </v-popover>
          <div
            v-if="companiesInfo.length === 1"
            class="card-coders__name is-column is-align-items-start ml-2 overflow-hidden hover:underline"
            @click.stop>
            <p
              v-tooltip="companiesInfo[0].name"
              class="card-coders__text font-weight-bold font-size-m is-ellipsis"
              @click.exact="
                openPanel({
                  id: companiesInfo[0]._id,
                  type: 'companies',
                })
              ">
              {{ companiesInfo[0].name }}
            </p>
          </div>
        </div>
      </div>
      <div class="card-coders__wrapper card-coders__professions is-align-items-center hidden-xs">
        <card-tags :tags="professions" class="card-coders__tag-wrapper" reference="professions">
          <template #tag="{ tag }">
            <tag v-tooltip="{ content: tag.name }" :text="tag.name" type="professions">
              {{ tag.name }}
            </tag>
          </template>
          <template #remaining="{ remaining }">
            <tag :show-icon="false" :text="`+${remaining}`" type="professions"></tag>
          </template>
        </card-tags>
      </div>
      <div class="card-coders__wrapper card-coders__locations hidden-xs hidden-sm">
        <card-tags :tags="locations" class="card-coders__tag-wrapper" reference="locations">
          <template #tag="{ tag }">
            <tag
              v-tooltip="{ content: [...new Set([tag.fullAddress])].join(', ') }"
              :text="tag.fullAddress"
              :text-limit="13"
              type="location">
            </tag>
          </template>
          <template #remaining="{ remaining }">
            <tag :show-icon="false" :text="`+${remaining}`" type="location"></tag>
          </template>
        </card-tags>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { usePanelNavigation } from '@/composables/usePanelNavigation';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import CardTags from '@/components/Card/CardTags';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
  import Tag from '@/components/Tag/Tag';
  import { formatPlaceDetailsToBaseLocation } from '@/formatters/locations';
  import { computed, ref } from 'vue';
  import defaultCompanyAvatarTooltip from '@/assets/img/default-avatar-company-3.svg';
  import ImageWithDefault from '@/components/Image/ImageWithDefault.vue';

  const props = withDefaults(
    defineProps<{
      cardContent: any;
      isSelected: boolean;
      context: string;
    }>(),
    {
      cardContent: {},
      isSelected: false,
      context: '',
    },
  );

  const { openPanel, openPanelInNewTab } = usePanelNavigation();

  const isHover = ref(false);

  const fullName = computed(() => `${props.cardContent.firstName} ${props.cardContent.lastName}`);
  const contactInfos = computed(() => {
    const phones = props.cardContent.phones ? props.cardContent.phones.map(phone => ({
      name: phone,
      type: 'phone',
    })) : [];
    const emails = props.cardContent.emails ? props.cardContent.emails.map(email => ({
      name: email,
      type: 'mail',
    })) : [];

    return [
      { name: props.cardContent.phone, type: 'phone' },
      { name: props.cardContent.email, type: 'mail' },
      ...phones,
      ...emails,
    ].filter(e => e).filter(contactInfo => !!contactInfo.name);
  });
  const professions = computed(() => props.cardContent._professions || []);

  const companiesInfo = computed(() => {
    return props.cardContent.companies;
  });

  const locations = computed(() =>
    props.cardContent?.locations?.map(formatPlaceDetailsToBaseLocation),
  );
  const profileTitle = computed(() =>
    props.cardContent.title === 'New coder' ? '' : props.cardContent.title,
  );

  function setAlternativeImg(event: Event) {
    event.target.src = require('@/assets/img/default-avatar.svg');
  }

  function setAlternativeCompanyImg(event) {
    event.target.src = require('@/assets/img/default-avatar-company.svg');
  }
</script>

<style lang="scss" scoped>
  .card-coders {
    height: 58px;

    &:hover {
      cursor: pointer;
    }

    &__container {
      height: 100%;
      padding: 10px 30px;
      flex-direction: column;
      align-items: center;

      @include bp('tablet') {
        flex-direction: row;
      }
    }

    &__wrapper {
      margin: 10px;
      max-height: 100%;
      width: 100%;

      @include bp('tablet') {
        margin: 0 10px;
        width: initial;
      }
    }

    &__id {
      @include contacts-grid-name;
    }

    &__name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__link {
      @include contacts-grid-cv;
      color: $color-success-light;

      a {
        color: $color-success-light;

        &:hover {
          color: $color-secondary-dark;
        }
      }
    }

    &__contact-info {
      @include contacts-grid-contact-info;
    }

    &__professions {
      @include contacts-grid-professions;
    }

    &__company {
      @include contacts-grid-company;
    }

    &__locations {
      @include contacts-grid-locations;
    }

    &__text {
      max-width: 100%;
    }

    &__tag-wrapper {
      width: 100%;
    }

    &__tag {
      max-width: 100%;
      border-radius: 30px;
      color: #3d5476;
      font-weight: 500;
      padding: 4px 8px;
      font-size: 13px;

      &.is-unshrink {
        max-width: initial;
      }
    }

    &__rating-avatar {
      margin-right: 5px;
    }

    &__icon {
      height: 20px;

      &:hover {
        filter: brightness(0.7);
      }
    }

    &__status {
      height: 5px;
      background: $color-grey-4;
      z-index: 2;

      &--processed {
        background: $color-success;
      }
    }

    &__count {
      background: $color-grey-3;
      color: $color-grey-5;
      height: 28px;
      width: 28px;
    }

    &__buttons {
      align-items: center;
      height: calc(100% - 5px);
      z-index: 1;

      &--pool {
        right: 55%;
      }

      &--pipe {
        right: 5%;
      }
    }

    &__date {
      font-size: 14px;
      color: #1d395e;
    }

    &:deep() {
      .ratings {
        margin: 0;
      }
    }
  }
</style>
