<template>
  <div class="domains-dropdown-content is-column border-radius-s font-size-s">
    <div class="domains-dropdown-content__container is-overflow-hidden is-column is-flex-wrap">
      <div
        :key="index"
        class="domains-dropdown-content__wrapper is-column is-flex-wrap"
        v-for="(domainsCategory, index) in domainsCategories">
        <div
          :class="[`icon-${domainsCategory.icon}`]"
          @click="multiChoice && $emit('click', domainsCategory.domains)"
          class="domains-dropdown-content__title is-expanded"
        >
          {{ $t(domainsCategory.name) }}
        </div>
        <div class="domains-dropdown-content__options is-column">
          <button
            :class="{'domains-dropdown-content__option--left-item': isLeftItem(domain),
                     'domains-dropdown-content__option--right-item': !isLeftItem(domain),
                     'domains-dropdown-content__option--selected': values.includes(domain.name)}"
            :key="domainIndex"
            @click="$emit('click', [domain])"
            class="domains-dropdown-content__option"
            type="button"
            v-for="(domain, domainIndex) in domainsCategory.domains">
            {{ $t(domain.name) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'domains-dropdown-content',
    props: {
      values: {
        type: Array,
        default: () => [],
      },
      options: {
        type: Array,
        default: () => [],
      },
      multiChoice: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      domainsCategories() {
        return this.options;
      },
    },
    methods: {
      isLeftItem(domain) {
        const domainCategoryIndex = this.domainsCategories.findIndex(category => category.domains.find(d => d.api === domain.api));
        const totalLength = this.domainsCategories.length;

        return domainCategoryIndex < totalLength / 2;
      },
    },
  };
</script>

<style lang=scss scoped>
  .domains-dropdown-content {
    background: $color-white;
    @include bp("tablet") {
      min-width: 550px;
      max-height: 750px;
    }

    &__container {
      margin: 0 -15px;
    }

    &__wrapper {
      margin: 0 15px 20px;

      @include bp("tablet") {
        width: calc(50% - 30px);
      }

      &:hover {
        & > .domains-dropdown-content__title {
          color: $color-primary;
        }
      }
    }

    &__title {
      padding: 15px 20px;
      border-bottom: 1px solid $color-grey;
      margin-bottom: 10px;

      &:before {
        margin-right: 10px;
      }
    }

    &__option {
      padding: 7.5px 0 7.5px 45px;

      &--left-item {
        border-top-right-radius: $border-radius-m;
        border-bottom-right-radius: $border-radius-m;
      }

      &--right-item {
        border-top-left-radius: $border-radius-m;
        border-bottom-left-radius: $border-radius-m;
      }

      &:hover {
        background: rgba($color-primary, 0.5);
        color: $color-white;
      }

      &--selected {
        background-color: $color-primary;
        color: $color-white;

        &:hover {
          background: $color-primary;
          color: $color-white;
        }
      }
    }
  }
</style>
