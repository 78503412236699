import contacts from '@/api/contacts';

export default {
  actions: {
    async getContactProfile({ commit }, id) {
      const response = await contacts.getContactProfile(id);

      commit('setPanelProfile', response.data);

      return response;
    },
    async updateContactProfile(context, { id, obj }) {
      return await contacts.updateContact({ id, obj });
    },
    async createContact(context, { id, obj }) {
      return await contacts.createContact({ id, obj });
    },
    async deleteContact({ commit }, id) {
      const response = await contacts.deleteContact(id);

      commit('deleteViewListItem', id);

      return response;
    },
    async inviteContact(context, { id, email }) {
      const response = await contacts.inviteContact({ id, email });

      return response;
    }
  }
};
