<template>
  <div class="jobboard-stats">
    <div class="jobboard-stats__header">
      <div class="jobboard-stats__header-item">
        <div>
          <i class="icon-user"></i>
        </div>
        <p><b>0</b> candidatures au total</p>
      </div>

      <div class="jobboard-stats__header-item">
        <div>
          <i class="icon-user"></i>
        </div>
        <p><b>0</b> candidatures acceptées</p>
      </div>

      <div class="jobboard-stats__header-item">
        <div>
          <i class="icon-user"></i>
        </div>
        <p><b>0</b> candidatures en attente</p>
      </div>

      <div class="jobboard-stats__header-item">
        <div>
          <i class="icon-user"></i>
        </div>
        <p><b>0</b> candidatures refusées</p>
      </div>

      <div class="jobboard-stats__header-item">
        <div>
          <i class="icon-user"></i>
        </div>
        <p><b>0</b> candidats recrutés</p>
      </div>
    </div>
    <div class="jobboard-stats__content">
      <h3 class="mb-4">Origine des candidatures</h3>
      <div class="jobboard-stats__stats">
        <div
          v-for="jobboard in activatedChannels"
          :key="jobboard.id"
        >
          <div class="jobboard-stats__stats--title">
            <span class="jobboard-stats__dot"></span>
            <p class="capitalize">{{ jobboard.title }}</p>
          </div>
          <div>
            0
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'jobboard-stats',
  computed: {
    ...mapState({
      activatedChannels: state => state.xtramile.activatedChannels,
    }),
  }
}
</script>

<style lang="scss" scoped>
.jobboard-stats {
  flex-direction: column;
  min-height: 239px;
  border-radius: 5px;
  background: $color-neutre-3;
  position: relative;

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    height: 52px;
    background: $color-blue-light;
    width: 100%;
    border-radius: 5px;
    align-items: center;

    &-item {
      gap: 10px;
      width: 20%;
      justify-content: center;
      border-right: 1px solid $color-blue-medium;

      & > div {
        width: 30px;
        height: 30px;
        background: white;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        color: $color-primary;
      }

      & > p {
        max-width: 105px;
        font-size: 14px;
        color: $color-blue-dark-cello;
      }
    }
  }

  &__content {
    flex-direction: column;
    margin-top: 50px;
    padding: 20px 15px;

    & > h3 {
      color: $color-tertiary;
      font-size: 16px;
      font-weight: 700;
    }
  }

  &__stats {
    flex-direction: column;
    gap: 10px;
    width: 500px;
    height: 200px;
    flex-wrap: wrap;

    & > div {
      width: 184px;
      justify-content: space-between;
      gap: 30px;
    }

    &--title {
      align-items: center;
      gap: 5px;

      & > p {
        color: $color-tertiary;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  &__dot {
    width: 8px;
    height: 8px;
    background: #FFCD00;
    border-radius: 100%;
  }
}
</style>
