<script lang="ts" setup>
import { defineProps, withDefaults, ref, computed } from 'vue';
import gcalendar from '@/assets/logo/google-calendar.png';
import { useI18n } from '@/i18n/i18n';
import { useRoute } from 'vue-router/composables';
import outlookcalendar from '@/assets/logo/outlook_calendar.png';
import { getGmailLink, getGoogleCalendarEventLink, getMicrosoftCalendarEventLink, getOutlookLink } from '@/helpers/quicklinks';
import { useStore } from '@/store';
import { Candidate } from '@/domains/models/Candidate';
import { useClipboard } from '@vueuse/core';
import TransitionFadeTranslateUp from '@/components/UIKit/Transition/TransitionFadeTranslateUp.vue';

interface Props {
  value: string;
  icon?: string;
  isPro?: boolean;
  iconUrl?: string;
  isEmail?: boolean;
  company: Candidate;
}

const i18n = useI18n();
const route = useRoute();
const store = useStore();

const props = withDefaults(defineProps<Props>(), {
  value: '',
  isEmail: false,
});

const hasGoogleWidgets = computed(() => {
  return store.getters['user/configurationProcess'].hasGoogleWidgets;
});

const hasMicrosoftWidgets = computed(() => {
  return store.getters['user/configurationProcess'].hasMicrosoftWidgets;
});
const isOpen = ref(false);
const googleCalendarLink = computed(() => getGoogleCalendarEventLink({
  i18n,
  routeFullPath: route.fullPath,
  profileName: `${props.company.firstName} ${props.company.lastName}`,
  phone: props.company.phone,
  email: props.company.email,
  type: 'invitation',
  organizationName: store.state.user._organization?.name,
}));
const gmailLink = computed(() =>
  getGmailLink({ emailTo: props.company.email })
);
const outlookLink = computed(() => {
  return getOutlookLink({ emailTo: props.company.email });
});
const microsoftCalendarLink = computed(() => 
  getMicrosoftCalendarEventLink({ 
    emailTo: props.company?.email || "" 
  })
);
const { copy, copied } = useClipboard();
</script>

<template>
  <div @click="isOpen = !isOpen" :class="{
      'cursor-pointer': isEmail,
    }" class="panel-coder-contacts-social relative flex basis-1/2 items-center text-blue-800">
    <i v-if="icon" :class="`icon-${icon}`" size="13" class="mr-1">
    </i>
    <img v-if="iconUrl" :src="iconUrl" alt="icon" class="mr-1 size-[13px]" />
    <p class="truncate text-xs font-medium">
      {{ value }}
    </p>
    <span v-if="isPro" class="ml-1.5 rounded-lg bg-blue-100 px-2.5 text-xs">
      Pro
    </span>
    <TransitionFadeTranslateUp>
      <ul v-click-outside="() => isOpen = false" v-if="isOpen && isEmail"
        class="absolute left-0 top-full z-10 mt-1.5 overflow-hidden rounded-md bg-white text-xs shadow-md">
        <li @click="copy(value)"
          class="flex cursor-pointer items-center rounded-t-md border-b border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white">
          <span class="icon-copy"></span>
          <p class="ml-2">{{ $t('companies.account-management.copy-email') }}</p>
        </li>
        <a v-if="hasGoogleWidgets" :href="gmailLink" target="_blank">
          <li
            class="flex cursor-pointer items-center border-b border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white">
            <span class="icon-mail"></span>
            <p class="ml-2">{{ $t('coder.quicklinks.gmail') }}</p>
          </li>
        </a>
        <a v-if="hasMicrosoftWidgets" :href="outlookLink" target="_blank">
          <li
            class="flex cursor-pointer items-center border-b border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white">
            <span class="icon-mail"></span>
            <p class="ml-2">{{ $t('coder.quicklinks.outlook') }}</p>
          </li>
        </a>
        <a v-if="hasGoogleWidgets" :href="googleCalendarLink" target="_blank">
          <li class="flex cursor-pointer items-center border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white">
            <img class="size-[13px]" :src="gcalendar" alt="gcalendar" />
            <p class="ml-2">{{ $t('coder.quicklinks.google-calendar') }}</p>
          </li>
        </a>
        <a v-if="hasMicrosoftWidgets" :href="microsoftCalendarLink" target="_blank">
          <li class="flex cursor-pointer items-center border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white">
            <img class="size-[13px]" :src="outlookcalendar" alt="outlookcalendar" />
            <p class="ml-2">{{ $t('coder.quicklinks.outlook-calendar') }}</p>
          </li>
        </a>
      </ul>
    </TransitionFadeTranslateUp>
  </div>
</template>
