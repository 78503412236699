<template>
  <div class="jobboard-publish-item">
    <div class="jobboard-publish-item__label">
      <img :src="logo" />
      <p>{{ label }}</p>
    </div>
    <div class="jobboard-publish-item__content">
      <p v-if="!available" class="jobboard-publish-item__coming-soon">
        {{ $t('generics.coming-soon') }}
      </p>
      <div v-else-if="!enabled">
        <router-link class="jobboard-publish-item__connect-link" to="/configuration/jobboards">
          {{ $t('jobboards.connect_my_account', { jobboard: '' }) }}
        </router-link>
      </div>
      <div
        v-else
        class="jobboard-publish-item__toggle"
        style="flex: 1;"
      >
        <div class="jobboard-publish-item__buttons">
          <bc-button
            v-if="checked"
            iconCenter="edit-3"
            :disabled="disabled"
            @click="$emit('edit', $event)"
          ></bc-button>
          <bc-button
            color="error"
            iconCenter="trash"
            :disabled="disabled"
            @click="$emit('delete', $event)"
          ></bc-button>
        </div>
        <bc-spinner v-if="isLoading"></bc-spinner>
        <label
          :for="`jobboard-publish-item-${id}`"
          class="publish-custom-job__label"
          :class="{
            'publish-custom-job__label--active': checked,
            'publish-custom-job__label--inactive': !checked,
            'publish-custom-job__label--disabled': disabled,
          }">
          {{ $t('custom-jobs.distribute-my-offer') }}
        </label>
        <bc-toggle
          :id="`jobboard-publish-item-${id}`"
          :ref="`jobboard-publish-item-${id}`"
          type="default"
          :value="checked"
          :disabled="disabled"
          @input="handleInput"
        >
        </bc-toggle>
      </div>
    </div>
  </div>
</template>

<script>
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import BcToggle from '@/ui-kit/components/BcToggle/BcToggle';

export default {
  name: 'jobboard-publish-item',
  components: {
    BcButton,
    BcSpinner,
    BcToggle,
  },
  props: {
    value: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    logo: {
      type: String,
    },
    available: {
      type: Boolean,
      default: true,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    this.handleChecked(this.value);
  },
  data() {
    return {
      id: Date.now(),
      checked: false,
    }
  },
  methods: {
    handleChecked(newValue) {
      const refId = `jobboard-publish-item-${this.id}`;
      this.checked = newValue;
      if (!this.$refs[refId]) {
        return;
      }
      this.$refs[refId].$el.children[1].value = newValue;
      this.$refs[refId].$el.children[1].checked = newValue;
    },
    handleInput() {
      this.handleChecked(this.checked);
      this.$emit('input', !this.checked);
    }
  },
  watch: {
    value(newValue) {
      this.handleChecked(newValue);
    },
  }
}
</script>

<style lang="scss" scoped>
.jobboard-publish-item {
  width: 100%;
  padding: 15px 20px;
  align-items: center;
  justify-content: space-between;

  &__label {
    align-items: center;

    & > img {
      width: 27px;
      height: 27px;
    }

    & > p {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $color-tertiary;
      margin-left: 20px;
    }
  }

  &__content {
    align-items: center;
  }

  &__coming-soon {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: $color-blue-dodger;
    border-radius: 30px;
    padding: 2px 8px;
    background: rgba(60, 128, 247, 0.1);
  }

  &__toggle {
    align-items: center;
    justify-content: center;
    gap: 10px;
    &:hover {
      cursor: pointer;
    }
  }

  &__buttons {
    gap: 10px;
    padding: 0px 15px;
    margin-right: 10px;
    border-right: 1px solid #1d395e32;

    & button {
      width: 32px;
      height: 32px;
      padding: 5px;
    }
  }

  &__connect-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: $color-primary;
  }
}

.publish-custom-job {
  &__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    align-items: center;

    &--disabled {
      color: $color-blue-cadet;
      opacity: 0.5;
    }
    &--active {
      color: $color-green-shamrock;
    }
    &--inactive {
      color: $color-red-mandy;
    }
    &--disabled {
      opacity: 0.5;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__warning {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-top: 15px;
    color: $color-blue-dark-cello;
  }
}
</style>
