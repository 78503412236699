import moment from 'moment/min/moment-with-locales';
import { apiStatus, limitDateToSetOld } from '@/macros/processes/process';
import { apiRoles } from '@/macros/coaches/roles';

export default {
  props: {
    process: {
      type: Object,
      default: () => ({
        _coder: {
          firstName: '',
          lastName: '',
        },
        _coach: {
          firstName: '',
          lastName: '',
        },
        date: '',
        status: 1,
      }),
    },
  },
  computed: {
    // Presenters
    name() {
      let fullname = `${((this.process || {})._coder || {}).firstName} ${((this.process || {})._coder || {}).lastName?.toUpperCase()}`;
      if (fullname.length > 20) {
        fullname = fullname.substring(0, 20)
      }
      return fullname
    },
    profile() {
      return this.$store.state.user.profile || {};
    },
    processDateKeyToUse() {
      return this.process.lastCoachActivity;
    },
    date() {
      return this.getDateFromNow(this.processDateKeyToUse);
    },

    // Validators
    isAuthorized() {
      return (this.process._coach || {})._id === this.profile._id || this.profile.role === apiRoles.businessManager || this.profile.role === apiRoles.product;
    },
    isPending() {
      return this.process.status === apiStatus.pending;
    },
    isOld() {
      return moment(this.process.date).isBefore(moment().subtract(limitDateToSetOld, 'months'));
    },
    isFailed() {
      return this.isOld || this.process.failedAt;
    },
    isAborted() {
      return this.process.abortedAt
    },
    isDealt() {
      return this.process.status === apiStatus.validated;
    },
  },
  methods: {
    getDateFromNow(date) {
      return moment(date).fromNow();
    },
    $_processCard_updateProcess(process) {
      this.$emit('process-update', process);
    },
  },
};
