<template>
  <div
    :style="avatarStyle"
    class="bc-avatar"
    v-bind="$attrs"
    v-on="listeners">
    <img
      :alt="bcImgAlt"
      :src="bcImgSrc"
      :style="imgStyle"
      @error="$emit('error', $event)"
      class="bc-avatar__img"
      v-if="bcImgSrc && bcImgAlt">
    <div
      :class="`icon-${bcIcon}`"
      class="bc-avatar__icon"
      v-else-if="bcIcon">
    </div>
  </div>
</template>

<script>
  import colors_helpers from '@/legacy/ui-kit/utils/colors_helpers';

  export default {
    name: 'legacy-bc-avatar',
    inheritAttrs: false,
    props: {
      bcImgSrc: {
        type: String,
        default: ''
      },
      bcImgAlt: {
        type: String,
        default: ''
      },
      bcImgSize: {
        type: String,
        default: '100%'
      },
      bcImgPosition: {
        type: String,
        default: ''
      },
      bcImgRight: {
        type: String,
        default: ''
      },
      bcImgTop: {
        type: String,
        default: ''
      },
      bcIcon: {
        type: String,
        default: ''
      },
      bcAvatarBackgroundColor: {
        type: String,
        default: 'white'
      },
      bcAvatarBackgroundColorOpacity: {
        type: Number,
        default: 1
      },
      bcAvatarRadius: {
        type: String,
        default: '50%'
      },
      bcAvatarSize: {
        type: String,
        default: '70px'
      },

    },
    computed: {
      listeners() {
        return {};
      },
      avatarStyle() {
        return {
          background: colors_helpers.$_checkValidColor(this.bcAvatarBackgroundColor, this.bcAvatarBackgroundColorOpacity),
          borderRadius: this.bcAvatarRadius,
          width: this.bcAvatarSize,
          height: this.bcAvatarSize
        };
      },
      imgStyle() {
        return {
          width: this.bcImgSize,
          height: this.bcImgSize,
          ...this.bcImgPosition && { position: this.bcImgPosition },
          ...this.bcImgRight && { right: this.bcImgRight },
          ...this.bcImgTop && { top: this.bcImgTop }
        };
      }
    },
  };
</script>

<style scoped lang="scss">
  .bc-avatar {
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    & img {
      object-fit: contain;
    }
  }
</style>
