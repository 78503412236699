<script lang="ts" setup>
  import { Company, CompanyBasicSocialInfo, CompanyContactInfo } from '@/domains/models/Company';
  import { computed, defineEmits, defineProps, ref, watch, withDefaults } from 'vue';
  import { ValidationObserver, ValidationProvider, Validator } from 'vee-validate';
  import BcInputPhone from '@/ui-kit/components/BcInputPhone/BcInputPhone.vue';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { isPhoneNumber } from '@/common-old/macros/regex';
  import BcInput from '@/ui-kit/components/BcInput/BcInput.vue';
  import { isLinkedinCompanyUrl, isLinkedinSlug, isWttjCompanyUrl } from '@/macros/socials/regex';
  import { linkedinCompanyUrl, wttjCompanyUrl } from '../../../../macros/socials/links';
  import companyApi from '@/api/company';
  import { useStore } from '@/store';

  interface Props {
    company: Company;
    isCreating?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    isCreating: false,
  });
  const emit = defineEmits<{
    (e: 'close-edit'): void,
    (e: 'update-company', value: CompanyContactInfo & CompanyBasicSocialInfo): void
  }>();
  Validator.extend('isPhoneValid', {
    validate: value => !value,
    getMessage: '', // TODO figure out way to use i18n in this scope -> $t('toast.phone-is-invalid')
  });
  const closeEdit = () => {
    emit('close-edit');
  };

  const store = useStore();

  const editedProfile = ref<Company>({
    phone: props.company.phone || '',
    phones: props.company.phones || [''],
    email: props.company.email || '',
    emails: props.company.emails || [''],
    social: {
      linkedin: props.company.social?.linkedin || '',
      welcomeToTheJungle: props.company.social?.welcomeToTheJungle || '',
      website: props.company.social?.website || '',
    },
  });
  const isPhoneValid = ref<boolean>(true);
  const isPhoneValidOrEmpty = computed(() => {
    return isPhoneValid.value || !editedProfile.value.phone;
  });
  const selectedCards = computed(() => {
    return store.state.card.selectedCards;
  });

  const getFormattedLinkedin = url => {
    return url?.split('/company/')?.pop();
  };
  const getFormattedWTTJ = (url) => {
    return url?.split('/companies/')?.pop()?.replace('/', '');
  };
  const companyLinkedinRegex = isLinkedinCompanyUrl;
  const companyWttjRegex = isWttjCompanyUrl;
  const linkedin = computed({
    get() {
      return companyLinkedinRegex.test(editedProfile.value.social?.linkedin || '')
          ? getFormattedLinkedin(editedProfile.value.social?.linkedin || '')
          : '';
    },
    set(value) {
      if (!value) {
        if (editedProfile.value.social) {
          editedProfile.value.social.linkedin = '';
        } else {
          editedProfile.value.social = {
            linkedin: '',
          };
        }
      } else {
        const linkedin = companyLinkedinRegex.test(value) ? `${linkedinCompanyUrl}${getFormattedLinkedin(value)}` : `${linkedinCompanyUrl}${value}`;
        if (editedProfile.value.social) {
          editedProfile.value.social.linkedin = linkedin;
        } else {
          editedProfile.value.social = {
            linkedin,
          };
        }
      }
    },
  });

  const welcomeToTheJungle = computed({
    get() {
      return companyWttjRegex.test(editedProfile.value.social?.welcomeToTheJungle || '')
          ? getFormattedWTTJ(editedProfile.value.social?.welcomeToTheJungle || '')
          : '';
    },
    set(value) {
      if (!value) {
        if (editedProfile.value.social) {
          editedProfile.value.social.welcomeToTheJungle = '';
        } else {
          editedProfile.value.social = {
            welcomeToTheJungle: '',
          };
        }
      } else {
        const welcomeToTheJungle = companyWttjRegex.test(value) ? `${wttjCompanyUrl}${getFormattedWTTJ(value)}` : `${wttjCompanyUrl}${value}`;
        if (editedProfile.value.social) {
          editedProfile.value.social.welcomeToTheJungle = welcomeToTheJungle;
        } else {
          editedProfile.value.social = {
            welcomeToTheJungle,
          };
        }
      }
    },
  });

  const purgedProfile = computed(() => ({
    ...editedProfile.value.email && { email: editedProfile.value.email },
    ...!editedProfile.value.email && { email: '' },
    ...editedProfile.value.phone && { phone: editedProfile.value.phone },
    ...!editedProfile.value.phone && { phone: '' },
    ...editedProfile.value.phones?.length && { phones: editedProfile.value.phones.filter(phone => phone) },
    ...editedProfile.value.emails?.length && { emails: editedProfile.value.emails.filter(email => email) },
    social: {
      ...editedProfile.value?.social?.website && { website: editedProfile.value?.social?.website },
      ...editedProfile.value?.social?.website === '' && { website: '' },
      ...linkedin.value && { linkedin: `${linkedinCompanyUrl}${linkedin.value}` },
      ...linkedin.value === '' && { linkedin: '' },
      ...welcomeToTheJungle.value && { welcomeToTheJungle: `${wttjCompanyUrl}${welcomeToTheJungle.value}` },
      ...welcomeToTheJungle.value === '' && { welcomeToTheJungle: '' },
    },
  }));

  const saveCompany = async() => {
    if (isPhoneValidOrEmpty.value) {
      const { data } = await companyApi.updatePartner({
        id: props.company._id,
        obj: purgedProfile.value,
      });

      // To update the emails on selected cards
      if (selectedCards.value?.length) {
        const findedCompanyIndex = selectedCards.value.findIndex((card: any) => card._id === props.company._id);
        if (findedCompanyIndex !== -1) {
          const newCards = [...selectedCards.value];
          newCards[findedCompanyIndex] = {
            ...selectedCards.value[findedCompanyIndex],
            email: data.email,
            emails: data.emails,
          }
          store.dispatch('setSelectedCards', newCards);
        }
      }
      emit('update-company', data);
      closeEdit();
    }
  };

  watch(() => editedProfile.value, () => {
    if (props.isCreating) {
      emit('update-company', purgedProfile.value);
    }
  }, { deep: true });
</script>

<template>
  <div class="relative flex w-full flex-row items-start gap-8 rounded-md bg-neutral-200 px-5 py-4">
    <ValidationObserver
      ref="observer"
      class="flex w-full flex-col gap-2.5"
      tag="form"
      @submit.prevent>
      <div class="flex flex-col items-start">
        <div class="relative flex w-full flex-row flex-wrap items-start gap-x-3.5 gap-y-2.5">
          <ValidationProvider
            v-slot="{ errors, valid }"
            :rules="{ required: false, email: true }"
            name="Email"
            slim>
            <bc-input
              ref="email"
              v-model="editedProfile.email"
              :class="{ invalid: errors.length > 0 }"
              :error="errors[0]"
              :label="$t('contacts.email')"
              :required="false"
              :valid="valid && !!editedProfile.email"
              class="w-full max-w-1/2-gap-3.5"
              icon="mail"
              name="email"
              type="text">
            </bc-input>
          </ValidationProvider>
          <ValidationProvider
            v-for="(email, index) in editedProfile.emails"
            :key="`email-${index}`"
            v-slot="{ errors, valid }"
            :name="$t('contacts.email')"
            :rules="{ required: false, email: true }"
            slim>
            <bc-input
              ref="email"
              v-model="editedProfile.emails[index]"
              :class="{ invalid: errors.length > 0 }"
              :error="errors[0]"
              :label="`${$t('contacts.email')} pro`"
              :label-placeholder="false"
              :placeholder="`${$t('contacts.email')} pro`"
              :required="false"
              :valid="valid && editedProfile.emails[index]"
              class="w-full max-w-1/2-gap-3.5"
              icon="mail"
              name="email"
              type="text">
            </bc-input>
          </ValidationProvider>
        </div>
        <button
          class="mt-2.5 text-sm text-blue-500 hover:underline"
          @click="editedProfile.emails.push('')">
          + {{ $t('generics.add-mail') }}
        </button>
      </div>
      <div class="flex flex-col items-start">
        <div class="relative flex w-full flex-row flex-wrap items-start gap-x-3.5 gap-y-2.5">
          <ValidationProvider
            ref="phoneProvider"
            v-slot="{ errors }"
            :name="$t('contacts.phone')"
            :rules="{ required: false, isPhoneValid: !isPhoneValidOrEmpty }"
            slim>
            <BcInputPhone
              ref="phone"
              v-model="editedProfile.phone"
              :class="{ invalid: errors.length > 0 }"
              :error="errors[0]"
              :label="$t('contacts.phone')"
              :label-placeholder="false"
              :placeholder="$t('contacts.phone')"
              :required="false"
              class="max-w-1/2-gap-3.5"
              icon="phone"
              name="phone"
              type="text"
              @error="isPhoneValid = false"
              @valid="isPhoneValid = true"
              @keydown.native.enter.prevent>
            </BcInputPhone>
          </ValidationProvider>
          <ValidationProvider
            v-for="(phone, index) in editedProfile.phones"
            :key="index"
            :ref="`phoneProvider-${index}`"
            v-slot="{ errors }"
            :name="$t('contacts.phone')"
            :rules="{
              required: false,
              regex: isPhoneNumber,
              isPhoneValid: !isPhoneValid,
            }"
            slim>
            <BcInputPhone
              ref="phone"
              v-model="editedProfile.phones[index]"
              :class="{ invalid: errors.length > 0 }"
              :error="errors[0]"
              :label="`${$t('contacts.phone')} pro`"
              :label-placeholder="false"
              :placeholder="`${$t('contacts.phone')} pro`"
              :required="false"
              class="max-w-1/2-gap-3.5"
              icon="phone"
              name="phone"
              type="text"
              @keydown.native.enter.prevent>
            </BcInputPhone>
          </ValidationProvider>
        </div>
        <button
          class="mt-2.5 text-sm text-blue-500 hover:underline"
          @click="editedProfile.phones.push('')">
          + {{ $t('generics.add-phone') }}
        </button>
      </div>
      <ValidationProvider
        v-slot="{ errors, valid }"
        :rules="{ required: false, regex: isLinkedinSlug }"
        name="LinkedIn"
        slim>
        <bc-input
          v-model="linkedin"
          :class="{ invalid: errors.length > 0 }"
          :error="errors[0]"
          :label="$t('generics.linkedin')"
          :placeholder="$t('generics.linkedin')"
          :required="false"
          :valid="valid && !!linkedin"
          class="w-full"
          icon="linkedin"
          name="linkedin"
          type="text">
          <template #prepend="{ isFocus }">
            <div class="-mr-1 h-full min-h-[40px] shrink-0 items-center rounded-l-[5px] bg-blue-500 p-2.5 text-sm text-white">
              {{ linkedinCompanyUrl }}
            </div>
          </template>
        </bc-input>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors, valid }"
        :rules="{ required: false, regex: isLinkedinSlug }"
        name="LinkedIn"
        slim>
        <bc-input
          v-model="welcomeToTheJungle"
          :class="{ invalid: errors.length > 0 }"
          :error="errors[0]"
          :label="$t('panel-company-edit.wtj')"
          :placeholder="$t('panel-company-edit.wtj')"
          :required="false"
          :valid="valid && !!welcomeToTheJungle"
          class="w-full max-w-full"
          icon="welcome"
          name="wttj"
          type="text">
          <template #prepend="{ isFocus }">
            <p class="-mr-1 flex h-full min-h-[40px] shrink-0 items-center rounded-l-[5px] bg-blue-500 p-2.5 text-sm text-white">
              {{ wttjCompanyUrl }}
            </p>
          </template>
        </bc-input>
      </ValidationProvider>
      <ValidationProvider
        ref="websiteProvider"
        v-slot="{ errors, valid }"
        :rules="{ required: false, notUnique: false }"
        name="URL"
        slim>
        <bc-input
          ref="website"
          v-model="editedProfile.social.website"
          :class="{ invalid: errors.length > 0 }"
          :error="errors[0]"
          :label="$t('panel-company-edit.web-site')"
          :label-placeholder="false"
          :required="false"
          :valid="valid && !!editedProfile.social.website"
          class="panel-company-edit-social__input panel-company-edit-social__input--half"
          icon="website"
          name="website"
          type="text">
        </bc-input>
      </ValidationProvider>
      <div
        v-if="!isCreating"
        class="ml-auto flex flex-row gap-2.5">
        <BcButton
          icon-left="cross"
          size="small"
          type="outlined"
          @click.native="closeEdit">
          {{ $t('global.cancel') }}
        </BcButton>
        <BcButton
          icon-left="check"
          native-type="submit"
          size="small"
          @click.native="saveCompany">
          {{ $t('global.save') }}
        </BcButton>
      </div>
    </ValidationObserver>
  </div>
</template>

<style lang="scss" scoped>
  .panel-coder-edit-coordinates {
    &__prepend {
      background: $color-primary;
      color: $color-white;
      height: 40px;
      padding: 0 10px;
      align-items: center;
      margin-right: -15px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      &--empty {
        background: $color-blue-medium;
      }
    }
  }
</style>
