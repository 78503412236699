<script lang="ts" setup>
  import { Candidate } from '@/domains/models/Candidate';
  import { defineEmits, defineProps, ref } from 'vue';
  import PanelDocumentsView from '@/components/Panel/PanelDocumentsView.vue';
  import documentsController from '@/managers/documents/controller';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { useI18n } from '@/i18n/i18n';
  import BcModal from '@/ui-kit/components/BcModal/BcModal.vue';
  import PanelCoderDocumentsModalDelete
    from '@/components/Panel/PanelCoder/PanelCoderDocuments/PanelCoderDocumentsModal/PanelCoderDocumentsModalDelete.vue';
  import { Document } from '@/domains/models/Document';

  interface Props {
    candidate: Candidate;
  }

  const props = defineProps<Props>();
  const emits = defineEmits<{ (e: 'update-candidate', value: Candidate): void }>();
  const toast = useToast();
  const i18n = useI18n();

  // remove document
  const isModalOpen = ref<boolean>(false);
  const selectedDocument = ref<Document | null>(null);

  const openDeleteModal = (document: Document) => {
    selectedDocument.value = document;
    isModalOpen.value = true;
  };

  const removeDocument = async() => {
    try {
      const filteredDocuments = props.candidate._documents.filter(doc => selectedDocument.value._id !== doc._id);

      await documentsController.removeCoderDocument(selectedDocument.value._id);

      emits('update-candidate', { _documents: filteredDocuments });

      isModalOpen.value = false;
    } catch (error) {
      toast.show({
        message: i18n.t('toast.error-occured'),
        type: 'error',
        icon: 'cross',
      });
    }
  };
</script>

<template>
  <div class="w-full">
    <bc-modal :active.sync="isModalOpen">
      <panel-coder-documents-modal-delete
        @cancel-delete="isModalOpen = false"
        @confirm-delete="removeDocument">
      </panel-coder-documents-modal-delete>
    </bc-modal>
    <PanelDocumentsView
      :documents="candidate?._documents"
      class="w-full"
      @open-delete-modal="openDeleteModal"
      @update-candidate="$emit('update-candidate', $event)"
    />
  </div>
</template>

<style scoped></style>
