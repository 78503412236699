<template>
  <div class="processes-view-filter">
    <bc-dropdown :options="options" is-expanded class="processes-view-filter__dropdown">
      <template #label="{ isDropdownOpen }">
        <bc-dropdown-trigger class="processes-view-filter__dropdown--trigger" :is-arrow="true"
          :is-dropdown-open="isDropdownOpen" :text="selectedOption?.name" :is-filter="true" icon="sliders">
        </bc-dropdown-trigger>
      </template>
      <template #option="{ activeIndex, index, option }">
        <bc-dropdown-item :is-focus="activeIndex === index" :text="option.name" :title="option.name"
          class="processes-view-filter__dropdown-item font-size-s" @click.native="selectOption(option)">
        </bc-dropdown-item>
      </template>
    </bc-dropdown>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@/i18n/i18n';
import { useStore } from '@/store';
import BcDropdown from '@/ui-kit/components/BcDropdown';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';

const store = useStore();
const route = useRoute();
const router = useRouter();
const { tc } = useI18n();

const configurationProcess = computed(() => store.getters['user/configurationProcess']);

const options = computed(() => {
  return [
    {
      name: tc('generics.candidate-cap', 2),
      value: 'candidates',
    },
    {
      name: tc('generics.opportunity', 2),
      value: 'opportunities',
    },
  ];
});

const selectedOption = ref(options.value.find((option) => option.value === route.query['selected-view']));


watch(selectedOption, () => {
  if (selectedOption.value) {
    router.replace({
      query: {
        ...route.query,
        'selected-view': selectedOption.value.value,
      },
    });
  }
});

function selectOption(option) {
  selectedOption.value = option;
}

if (!route.query['selected-view']) {
  selectedOption.value = configurationProcess.value.candidatesProcessesDefault ? options[0] : options[1];
}

</script>

<style lang="scss" scoped>
.processes-view-filter {
  &__dropdown {
    min-width: 137px;
    margin-right: 10px;
  }
}
</style>
