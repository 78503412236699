<template>
  <div class="panel-poke-timeline is-full-width">
    <panel-view-timeline user-type="pokes"></panel-view-timeline>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import PanelViewTimeline from '@/components/Panel/PanelView/PanelViewTimeline';

  export default {
    name: 'panel-poke-timeline',
    components: { PanelViewTimeline },
    computed: {
      ...mapState({
        timeline: state => state.panel.viewList,
      }),
    },
    beforeCreate() {
      this.$store.dispatch('emptyViewList');
    },
    beforeDestroy() {
      this.$store.dispatch('emptyViewList');
    },
  };
</script>

<style lang="scss" scoped>
  .panel-poke-timeline {
    width: 100%;
  }
</style>
