<template>
  <td @click="isEditable = true" :class="columnType.toLowerCase()">
    <template v-if="canEdit">
      <p v-if="!isEditable">
        <span v-if="content">{{ content }}</span>
        <span v-else class="cell-placeholder">{{ $t(placeholder) }}</span>
      </p>
      <input
        v-else
        :maxlength="columnParams.maxLength"
        v-model="inputValue"
        @blur="dataInput"
        v-focus />
    </template>
    <template v-else>
      <p>
        {{ content }}
      </p>
    </template>
  </td>
</template>

<script>
import TableCellMixin from '../../../mixins/Table/tableCell';

export default {
  $inheritAttrs: false,
  mixins: [TableCellMixin],
  name: 'stringcell',
  data() {
    return {
      isEditable: false,
      inputValue: '',
    };
  },
  props: {
    editFromModal: {
      type: Boolean,
    },
    columnParams: {
      type: Object,
    },
    placeholder: {
      type: String
    }
  },
  mounted() {
    this.populateData();
  },

  methods: {
    dataInput(e) {
      this.isEditable = false;

      if (this.job[this.columnType.toLowerCase()] !== e.target.value) {
        const cellData = {
          columnName: this.columnType.toLowerCase(),
          data: this.inputValue,
        };

        if (!this.job) {
          return this.$emit('update-cell-data', cellData);
        }

        this.$emit('update-cell-data', { ...cellData, jobId: this.job._id });
        this.populateData();
      }
    },
    populateData() {
      const columnLowerCase = this.columnType.toLowerCase();

      if (this.editFromModal) {
        if (this.inputValue?.length) return this.inputValue;
      }

      if (columnLowerCase === 'remote') {
        if (this.job.remote !== null) {
          return (this.inputValue = this.job.remote);
        }

        return '';
      }

      if (this.job[columnLowerCase]) {
        if (this.inputValue?.length > 40) {
          this.inputValue = `${this.job[columnLowerCase].substr(0, 40)}...`;
          return this.inputValue;
        }

        this.inputValue = this.job[columnLowerCase];
        return this.job[columnLowerCase];
      }

      return this.inputValue;
    },
  },
  computed: {
    content() {
      return this.populateData();
    },
  },
};
</script>

<style lang="scss" scoped>
.poste,
.remote {
  max-width: 18vw;
  width: 18vw;
  max-height: 100%;
  height: 100%;
  overflow-wrap: break-word;
  input {
    width: 100%;
    height: 100%;
  }
}

td {
  input {
    width: inherit;
    border: 1px solid $color-primary;
    color: $color-primary;
    background-color: white;
    padding: 13px;
    font-family: 'Roboto';
    font-size: 16px;
  }
}

.remote {
  width: 5vw;
}
</style>
