export const reminderOptions = [
  {
    localeString: 'hunt.in-two-days',
    nbDays: 2,
  },
  {
    localeString: 'hunt.in-one-week',
    nbDays: 7,
  },
  {
    localeString: 'hunt.in-three-months',
    nbDays: 90,
  },
  {
    localeString: 'hunt.in-six-months',
    nbDays: 180,
  },
  {
    localeString: 'hunt.in-nine-months',
    nbDays: 270,
  },
  {
    localeString: 'generics.custom',
    code: 'customNbDays',
  },
] as const
