import { getLocationAutocomplete } from '@/api/location';

export default {
  state: {
    locationsSuggestions: []
  },
  actions: {
    async getLocationsAutocomplete({ commit }, { query, types }) {
      const response = await getLocationAutocomplete(query, types);

      commit('setLocationsSuggestions', response.data);

      return response;
    },
    emptyLocationsSuggestions({ commit }) {
      commit('emptyLocationsSuggestions');
    }
  },
  mutations: {
    setLocationsSuggestions(state, data) {
      const locationsMapped = data.predictions.map(location => ({
        ...location,
        name: location.description
      }));
      state.locationsSuggestions = locationsMapped.slice(0, 5);
    },
    emptyLocationsSuggestions(state) {
      state.locationsSuggestions = [];
    }
  }
};
