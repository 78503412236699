<template>
  <div class="indeed">
    <JobboardConfigItem
      :channel="channel"
      :subscriptionModel="subscriptionModel"
      :applicationMode="applicationMode"
      :applicationForwardingType="applicationForwardingType"
      :disabledSubscriptions="disabledSubscriptions"
      :disabledApplications="disabledApplications"
      :is-valid="isValid"
      :has-mixed="isXtramile"
      @update-subscription-model="updateSubscriptionModel"
      @update-application-mode="updateApplicationMode"
      @update-application-forwarding-type="updateApplicationForwardingType"
      @cancel="$emit('cancel', $event)"
      @submit="onSubmit">
      <template #subscriptionContent>
        <div class="is-column is-full-width">
          <div v-if="subscriptionModel && (subscriptionModel === subscriptionsModels.sponsored || subscriptionModel === 'mixed')" class="mt-5 pt-3 is-full-width is-column">
            <ContractIdField
              :label="$t('generics.login')"
              :placeholder="`Indeed ${$t('generics.login').toLowerCase()}`"
              :warning="$t('jobboards.warning-login-indeed')"
              v-model="contractId"
            />
          </div>
          <div v-if="!isXtramile" class="mt-4 is-column">
            <div class="is-column">
              <label class="indeed__label">{{ $t('generics.auto-broadcast') }}</label>
              <div>
                <bc-toggle id="indeed-auto" v-model="autoPublish" :disabled="true"></bc-toggle>
                <p class="ml-3 indeed__activated">
                  {{ $t('generics.activated') }}
                </p>
                <WarningCercle
                  class="ml-3"
                  :content="$t('jobboards.jobboard-auto-warning', { jobboard: 'Indeed' })"
                />
              </div>
            </div>
          </div>
          <div v-if="isXtramile && applicationMode === applicationModes.direct" class="is-full-width is-column mt-5 flex-col gap-8 pt-3">
            <ContractIdField
              :label="'DIRECT_APPLY_API_KEY'"
              :placeholder="`Indeed DIRECT_APPLY_API_KEY`"
              :warning="$t('jobboards.warning-login-indeed-apikey')"
              v-model="DIRECT_APPLY_API_KEY"
            />
            <ContractIdField
              :label="'DIRECT_APPLY_API_SECRET'"
              :placeholder="`Indeed DIRECT_APPLY_API_SECRET`"
              :warning="$t('jobboards.warning-login-indeed-apikey')"
              v-model="DIRECT_APPLY_API_SECRET"
            />
          </div>
        </div>
      </template>
    </JobboardConfigItem>
  </div>
</template>

<script>
import { subscriptionsModels, applicationModes } from '@/api/xtramile';
import BcToggle from '@/ui-kit/components/BcToggle/BcToggle';
import WarningCercle from '@/components/WarningCercle/WarningCercle';
import ContractIdField from '@/components/Jobboard/JobboardConfigChannel/Fields/ContractIdField';
import JobboardConfigItem from '@/components/Jobboard/JobboardConfig/JobboardConfigItem';

export default {
  name: 'indeed',
  components: {
    BcToggle,
    WarningCercle,
    ContractIdField,
    JobboardConfigItem,
  },
  props: {
    channel: {
      type: Object,
      default: () => ({}),
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    disabledSubscriptions: {
      type: Array,
      default: () => [],
    },
    disabledApplications: {
      type: Array,
      default: () => [],
    },
    isXtramile: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.isXtramile && this.values?.params) {
      this.subscriptionModel = this.values.params.subscriptionModel;
      this.applicationMode = this.values.params.applicationMode;
      this.applicationForwardingType = this.values.params.applicationForwardingType;

      this.contractId = this.values.params.accountParams.CONTRACT_ID;
      this.DIRECT_APPLY_API_KEY = this.values.params.accountParams.DIRECT_APPLY_API_KEY;
      this.DIRECT_APPLY_API_SECRET = this.values.params.accountParams.DIRECT_APPLY_API_SECRET;
    }
    if (!this.isXtramile) {
      this.subscriptionModel = this.subscriptionsModels.organic;
      this.applicationMode = this.applicationModes.direct;
    }
  },
  data() {
    return {
      subscriptionsModels,
      applicationModes,
      contractId: '',
      DIRECT_APPLY_API_KEY: '',
      DIRECT_APPLY_API_SECRET: '',
      subscriptionModel: '',
      applicationMode: '',
      applicationForwardingType: '',
      autoPublish: true,
    }
  },
  computed: {
    isSubscriptionValid() {
      if (!this.subscriptionModel) return false;
      if (this.subscriptionModel === this.subscriptionsModels.sponsored) {
        return !!this.contractId;
      }
      return true;
    },
    isApplicationValid() {
      if (!this.applicationMode) return false;
      return true;
    },
    isValid() {
      return this.isSubscriptionValid && this.isApplicationValid;
    }
  },
  methods: {
    updateSubscriptionModel(key) {
      this.subscriptionModel = key;
    },
    updateApplicationMode(key) {
      this.applicationMode = key;
    },
    updateApplicationForwardingType(value) {
      this.applicationForwardingType = value;
    },
    onSubmit() {
      if (!this.isXtramile) {
        this.$emit('submit', {
          isXtramile: false,
          jobboard: 'indeed',
          credentials: {},
          params: {
            autoPublish: true,
          },
        })
        return
      }
      this.$emit('submit', {
        channelId: this.channel.id,
        subscriptionModel: this.subscriptionModel,
        applicationMode: this.applicationMode,
        applicationForwardingType: this.applicationForwardingType,
        accountParams: {
          CONTRACT_ID: this.contractId,
          DIRECT_APPLY_API_KEY: this.DIRECT_APPLY_API_KEY,
          DIRECT_APPLY_API_SECRET: this.DIRECT_APPLY_API_SECRET,
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.indeed {
  &__label {
    color: $color-blue-heavy-dark;
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  p {
    font-size: 15px;
    font-weight: 400;
  }

  &__activated {
    color: $color-green-shamrock;
  }

  &__disabled {
    color: $color-red-mandy;
  }
}
</style>
