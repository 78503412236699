<template>
  <bc-modal :active="isOpen" @close="$emit('close', $event)">
    <div class="modal-confirm">
      <h3 v-if="icon">{{ icon }}</h3>
      <h1>{{ title }}</h1>
      <p v-if="content">{{ content }}</p>
      <div class="modal-confirm__content">
        <slot name="content"></slot>
      </div>
      <div class="modal-confirm__footer">
        <bc-button
          class="flex-1"
          @click.native.stop="$emit('close', $event)"
          type="outlined"
        >
          {{ cancelText }}
        </bc-button>
        <bc-button
          class="flex-1"
          :disabled="isSubmitDisabled"
          @click.native.stop="handleConfirm"
          :color="color"
        >
          {{ confirmText }}
        </bc-button>
      </div>
    </div>
  </bc-modal>
</template>

<script>
import BcModal from '@/ui-kit/components/BcModal';
import BcButton from '@/ui-kit/components/BcButton';

export default {
  name: 'modal-confirm',
  components: {
    BcModal,
    BcButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    icon: {
      type: String,
    },
    isSubmitDisabled: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
    },
    confirmText: {
      type: String,
    },
    color: {
      type: String,
      default: 'error',
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('on-confirm');
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-confirm {
  display: flex;
  max-width: 540px;
  flex-direction: column;
  padding: 50px;

  & > h1 {
    color: $color-tertiary;
    font-weight: 700;
    margin: 10px 0;
    font-size: 24px;
    line-height: 138.9%;
  }

  & > h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 138.9%;
  }

  & > p {
    color: $color-tertiary-2;
    font-size: 16px;
    font-weight: 500;
  }

  &__content {
    min-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-weight: 500;
    font-size: 16px;
    color: $color-tertiary-2;

    &-list {
      list-style-type: decimal;
      text-align: left;
      padding: 10px;

      & > li {
        margin: 5px 0;
      }
    }
  }

  &__footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}

.flex-1 {
  flex: 1;
}
</style>
