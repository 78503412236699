<script lang="ts" setup>
  import { Candidate } from '@/domains/models/Candidate';
  import { Profession } from '@/domains/models/Profession';
  import { Salary } from '@/domains/models/Salary';
  import { Skill } from '@/domains/models/Skill';
  import { computed, defineEmits, defineProps } from 'vue';
  import { useI18n } from '@/i18n/i18n';
  import { useStore } from '@/store';
  import PanelCoderDetailsLine from './PanelCoderDetailsLine.vue';
  import Tag from '@/components/Tag/Tag';
  import { formatPlaceDetailsToBaseLocation } from '@/formatters/locations';
  import { getContractObjectFromApiValue } from '@/common-old/macros/contracts';

  const i18n = useI18n();
  const store = useStore();

  interface Props {
    candidate: Candidate;
  }

  const props = defineProps<Props>();
  const user = store.state.user.profile;
  const customFields =
    user._organization.configuration?.candidate?.customFields?.filter(
      customField => customField.activated,
    ) || [];

  defineEmits<{
    (e: 'edit-candidate', value: boolean): void;
  }>();

  const isCandidate = computed(() => !!props.candidate.profileTypes?.includes('candidate'));

  const locations = computed<string[]>(() => {
    return (
      props.candidate.locations?.map(location => formatPlaceDetailsToBaseLocation(location, 'locality')) || ['']
    );
  });

  const professions = computed<Profession[]>(() => {
    return props.candidate._professions || [];
  });

  const skills = computed<Skill[]>(() => {
    return [
      ...(props.candidate?._technos1 || []),
      ...(props.candidate?._technos2 || []),
      ...(props.candidate?._technos3 || []),
    ];
  });

  const getCurrentSalaryTagValue = (salary: Salary, prepend: string) => {
    const baseSalary = `${prepend}: ${salary.salary} ${i18n.t(
      `salary-types.${[salary.salaryType]}`,
    )}`;

    if (salary.variable) {
      return `${baseSalary} + ${salary.variable} ${i18n.t(`salary-types.${[salary.variableType || salary.salaryType]}`)}`;
    }

    return baseSalary;
  };
  const currentSalaries = computed(() => {
    return props.candidate.currentSalary?.map(salary => ({
      value: getCurrentSalaryTagValue(salary, i18n.t('generics.current')),
      ...(salary.benefit && { benefit: `${salary.benefit}` }),
    }));
  });

  const getWantedSalaryTagValue = (salary: Salary, prepend: string) => {
    const wantedSalary = `${prepend}: ${salary.min}-${salary.max} ${i18n.t(`salary-types.${[salary.salaryType]}`)}`;

    if (salary.variable) {
      return `${wantedSalary} + ${salary.variable} ${i18n.t(`salary-types.${[salary.variableType || salary.salaryType]}`)}`;
    }

    return wantedSalary;
  };
  const wantedSalaries = computed(() => {
    return props.candidate.salaryWanted?.map(salary => ({
      value: getWantedSalaryTagValue(salary, i18n.t('generics.desired')),
      ...(salary.benefit && { benefit: `${salary.benefit}` }),
    }));
  });

  const contracts = computed(() => {
    return props.candidate.contracts.length
      ? props.candidate.contracts?.map(contract => i18n.t(getContractObjectFromApiValue(contract)?.name))
      : '';
  });
</script>

<template>
  <div class="relative flex w-full flex-col flex-wrap rounded-md bg-neutral-200 p-2.5 text-sm">
    <PanelCoderDetailsLine v-if="candidate.uniqueid" :title="$t('generics.uniqueid')" icon="user">
      <div class="wrap flex justify-end gap-2.5">
        <Tag :text="candidate.uniqueid" icon="user"/>
      </div>
    </PanelCoderDetailsLine>
    <PanelCoderDetailsLine v-if="locations.length" :title="$t('generics.location')" icon="map-pin">
      <div class="wrap flex flex-wrap justify-end gap-2.5">
        <Tag
          v-for="location in locations"
          :key="location.placeId"
          :text="location.completeAddress ? location.completeAddress : location.fullAddress"
          type="location"/>
      </div>
    </PanelCoderDetailsLine>
    <PanelCoderDetailsLine
      v-if="professions.length"
      :title="$t('generics.professions')"
      icon="award">
      <div class="flex flex-wrap justify-end gap-2.5">
        <Tag
          v-for="(profession, index) in professions.slice(0, 5)"
          :key="`${profession._id}-${index}`"
          :text="profession.name"
          icon="award"
          type="professions"/>
        <Tag
          v-if="professions.length > 5"
          v-tooltip.bottom="{
            content: professions
              ?.slice(5)
              .map(profession => profession.name)
              .join(', '),
          }"
          :text="`+${professions?.slice(5).length}`"
          icon="award"
          type="professions"/>
      </div>
    </PanelCoderDetailsLine>
    <template v-if="isCandidate">
      <PanelCoderDetailsLine
        v-if="skills.length" :title="$t('panel-coder-edit.skills')"
        icon="tool">
        <div class="flex flex-wrap justify-end gap-2.5">
          <Tag
            v-for="(skill, index) in skills.slice(0, 5)"
            :key="`${skill._id}-${index}`"
            :text="skill.name"
            icon="tool"
            type="skills"/>
          <Tag
            v-if="skills.length > 5"
            v-tooltip.bottom="{
              content: skills
                ?.slice(5)
                .map(skill => skill.name)
                .join(', '),
              autoHide: false,
            }"
            :text="`+${skills?.slice(5).length}`"
            icon="tool"
            type="skills"/>
        </div>
      </PanelCoderDetailsLine>
      <PanelCoderDetailsLine v-if="contracts.length !== 0" :title="$t('panel-coder-edit.contract')" icon="file">
        <div class="flex flex-wrap justify-end gap-2.5">
          <Tag
            v-for="contract in contracts"
            :key="contract"
            :text="contract"
            icon="file"
            type="contracts"/>
        </div>
      </PanelCoderDetailsLine>
      <PanelCoderDetailsLine
        v-for="(currentSalary, index) in currentSalaries"
        :key="`current-salary-${index}`"
        :title="
          index > 0 ? `${$t('generics.current-salary')} ${index}` : $t('generics.current-salary')
        "
        icon="salary">
        <div class="flex flex-wrap justify-end gap-2.5">
          <Tag :text="currentSalary.value" type="salary"/>
          <Tag v-if="currentSalary.benefit" :text="currentSalary.benefit" type="benefits"/>
        </div>
      </PanelCoderDetailsLine>
      <PanelCoderDetailsLine
        v-for="(wantedSalary, index) in wantedSalaries"
        :key="`wanted-salary-${index}`"
        :title="
          index > 0 ? `${$t('generics.desired-salary')} ${index}` : $t('generics.desired-salary')
        "
        icon="salary">
        <div class="flex flex-wrap justify-end gap-2.5">
          <Tag :text="wantedSalary.value" type="salary"/>
          <Tag v-if="wantedSalary.benefit" :text="wantedSalary.benefit" type="benefits"/>
        </div>
      </PanelCoderDetailsLine>
      <PanelCoderDetailsLine
        v-for="(customField, index) in customFields"
        v-show="candidate?.customFields?.[customField.key] != null"
        :key="`custom-field-${index}`"
        :icon="customField.icon"
        :title="customField.name">
        <div class="flex flex-wrap justify-end gap-2.5">
          <Tag
            v-tooltip.bottom="customField.name"
            :text="
              customField.key === 'experience'
                ? `${candidate?.customFields?.[customField.key]} ${$tc(
                    'global.year',
                    candidate?.customFields?.[customField.key]
                  )}`
                : Array.isArray(candidate?.customFields?.[customField.key])
                  ? candidate?.customFields?.[customField.key]?.map(value => `${value} ${customField.unit ? customField.unit : ''}`)?.join(' - ')
                  : candidate?.customFields?.[customField.key]
            "
            :type="customField.key"/>
        </div>
      </PanelCoderDetailsLine>
    </template>
  </div>
</template>
