<template>
  <div class="filter-coders-custom-fields">
    <filter-content
      v-for="customFieldsFilter in customFieldsFilters"
      :key="customFieldsFilter.key"
      :label="customFieldsFilter.name"
      :icon="customFieldsFilter.icon">
      <bc-custom-field
        placeholder="--"
        :class="[
          customFieldsFilter.type !== 'single-select'
            ? 'is-full-width'
            : 'searchbar-coders-custom-fields__item',
        ]"
        :type="customFieldsFilter.type"
        :is-filter="true"
        :custom-field="customFieldsFilter"
        :selected="selected[customFieldsFilter.key] || []"
        :with-label="false"
        @on-update="onUpdateCustomField($event, customFieldsFilter)" />
    </filter-content>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, defineProps, defineEmits } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { useStore } from '@/store';
import debounce from 'debounce';

import BcCustomField from '@/ui-kit/components/BcCustomField/BcCustomField';
import { CUSTOM_FIELDS_MULTISELECT_FILTERS } from '@/ui-kit/utils/customFields';
import FilterContent from '@/components/Filter/FilterContent';

const props = defineProps<{
  emitOnly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:filters', filters: Record<string, any>): void;
  (e: 'count', value: { key: string; count: number }): void;
  (e: 'on-select', value: { key: string; data: any; icon: string; replace?: boolean }): void;
  (e: 'on-unselect', value: { key: string; data: any[]; icon: string }): void;
}>();

const route = useRoute();
const router = useRouter();
const store = useStore();

const selected = ref({});

const configurationCandidate = computed(() => store.getters['user/configurationCandidate']);

const customFieldsFilters = computed(() => 
  (configurationCandidate.value.customFields || []).filter(
    customField => customField.showFilter && customField.key !== 'remote'
  )
);

const onSelect = ({ key, data, replace }: { key: string; data: any; replace?: boolean }) => {
  const queries: Record<string, any> = {};
  const cfKey = `cf-${key}`;
  if (replace) {
    queries[cfKey] = Array.isArray(data) ? data.join('-r-') : data;
  }
  emit('count', { key: cfKey, count: 1 });

  if (props.emitOnly) {
    emit('update:filters', queries);
  } else {
    router.push({
      query: {
        ...route.query,
        page: 1,
        ...queries,
      },
    });
  }
};

const resetFilter = (key: string) => {
  emit('count', { key, count: 0 });
  
  if (props.emitOnly) {
    emit('update:filters', {
      [key]: undefined
    });
  } else {
    router.push({
      query: {
        ...route.query,
        page: 1,
        [key]: undefined,
      },
    });
  }
};

const handleMultiselect = ({ key, value, icon }: { key: string; value: any; icon: string }) => {
  selected.value = {
    ...selected.value,
    [key]: [value],
  };

  if (props.emitOnly) {
    emit('update:filters', {
      [`cf-${key}`]: value
    });
  } else {
    router.push({
      query: {
        ...route.query,
        page: 1,
        [`cf-${key}`]: value,
      },
    });
  }
  emit('on-select', { key, data: value, icon, replace: true });
};

const onUpdateCustomField = debounce(({ key, value }: { key: string; value: any }, customFieldsFilter: any) => {
  if (CUSTOM_FIELDS_MULTISELECT_FILTERS.includes(customFieldsFilter.type)) {
    return handleMultiselect({ key, value, icon: customFieldsFilter.icon });
  }
  selected.value = {
    ...selected.value,
    [key]: value,
  };
  onSelect({ key, data: value, icon: customFieldsFilter.icon, replace: true });
}, 300);

const formatQueryValue = (queryValue = '') => {
  let value = queryValue;
  if (queryValue.includes(',')) {
    value = queryValue.split(',');
  } else if (queryValue.includes('-r-')) {
    value = queryValue.split('-r-');
  }
  return value;
};

const checkHasCfInQuery = (query: Record<string, any>) => {
  return Object.keys(query).some(key => key.startsWith('cf-'));
};

const syncSelectedWithQuery = (query: Record<string, any>) => {
  Object.keys(query).forEach(key => {
    if (key.startsWith('cf-')) {
      let value = query[key];
      value = formatQueryValue(value);

      const customField = customFieldsFilters.value.find(cf => cf.key === key.replace('cf-', ''));
      if (customField?.showFilter && customField?.type === 'cursor') {
        if (+value[0] === customField.min && +value[1] === customField.max) {
          return resetFilter(key);
        }
      }

      emit('count', { key, count: value ? 1 : 0 });
      selected.value = {
        ...selected.value,
        [`${key.replace('cf-', '')}`]: value || undefined,
      };
    }
  });
  Object.keys(selected.value).forEach(key => {
    if (!query[`cf-${key}`]) {
      emit('count', { key, count: 0 });
      selected.value = {
        ...selected.value,
        [key]: undefined,
      };
    }
  });
};

watch(() => route.query, (query, prevQuery) => {
  if (checkHasCfInQuery(query)) {
    syncSelectedWithQuery(query);
  } else if (checkHasCfInQuery(prevQuery)) {
    Object.keys(prevQuery).forEach(key => {
      emit('count', { key, count: 0 });
      selected.value = {};
    });
  } else {
    selected.value = {};
  }
}, { deep: true });
</script>

<style lang="scss" scoped>
.filter-coders-custom-fields {
  flex-direction: column;
  width: 100%;

  &__wrapper {
    padding: 20px 50px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  }

  &__item {
    width: 420px;
  }
}
</style>
