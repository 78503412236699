export const Modals = {
  uploadCodersResume: "uploadCodersResume",
  'modal-feature-drop-cv': 'modal-feature-drop-cv',
  'modal-feature-career': 'modal-feature-career',
  'modal-feature-share-processes': 'modal-feature-share-processes',
  'modal-feature-edit-processes-b2c': 'modal-feature-edit-processes-b2c',
  'modal-feature-add-team-member': 'modal-feature-add-team-member',
  'modal-feature-custom-fields': 'modal-feature-custom-fields',
  'modal-feature-custom-notes': 'modal-feature-custom-notes',
  'modal-feature-last-activity': 'modal-feature-last-activity',
  'modal-feature-db-limit': 'modal-feature-db-limit',
  'modal-feature-multipost': 'modal-feature-multipost',
  'modal-coders-deduplication': 'modal-coders-deduplication',
  'modal-email-send-form': 'modal-email-send-form',
  'modal-email-template-form': 'modal-email-template-form',
  'modal-templates-note': 'modal-templates-note',
  'modal-linkedin-send-form': 'modal-linkedin-send-form',
  'modal-templates-linkedin': 'modal-templates-linkedin',
}

export default {
  namespaced: true,
  state: {
    isLoading: true,
    modal: '',
    isModalClosing: false,
    isHoverSmallWins: false,
    isPanel: false,
    params: {},
    windowWidth: 0,
  },
  actions: {
    setIsLoading({ commit }, value) {
      commit('setIsLoading', value);
    },
    setModal({ commit }, value) {
      if (value in Modals || value === '') {
        commit('setModal', value)
        if (!value) {
          commit('setIsModalClosing', true);
          commit('setParams', {});
          commit('setIsPanel', false);
          setTimeout(() => commit('setIsModalClosing', false), 100);
        }
      }
    },
    setParams({ commit }, value) {
      commit('setParams', value);
    },
    setIsHoverSmallWins({ commit }, value) {
      commit('setIsHoverSmallWins', value);
    },
    setWindowWidth({ commit }, value) {
      commit('setWindowWidth', value);
    },
    setIsPanel({ commit }, value) {
      commit('setIsPanel', value);
    },
  },
  mutations: {
    setIsLoading(state, value) {
      state.isLoading = value;
    },
    setModal(state, value) {
      state.modal = value;
    },
    setIsHoverSmallWins(state, value) {
      state.isHoverSmallWins = value;
    },
    setIsModalClosing(state, value) {
      state.isModalClosing = value;
    },
    setParams(state, value = {}) {
      // if (Object.keys(value).length === 0) {
      //   state.params = {};
      //   return
      // }
      state.params = {
        ...state.params,
        ...value,
      };
    },
    setWindowWidth(state, value) {
      state.windowWidth = value;
    },
    setIsPanel(state, value) {
      state.isPanel = value;
    }
  },
};
