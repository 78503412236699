const baseUris = {
  PROD: 'https://app.marvinrecruiter.com/api/website',
  PREPROD: 'https://preprod.marvinrecruiter.com/api/website',
  REVIEW: 'https://review.marvinrecruiter.com/api/website',
  DEV: 'http://localhost:8084/api/website'
};

const hostnames = {
  'app.marvinrecruiter.com': baseUris.PROD,
  'preprod.marvinrecruiter.com': baseUris.PREPROD,
  'review.marvinrecruiter.com': baseUris.REVIEW,
}

const hostname = window.location.hostname;

export const getWebsiteJobsUri = (organizationName) => {
  const baseUri = hostnames[hostname] || baseUris.DEV;
  let uri = `${baseUri}/jobs/${organizationName.toLowerCase()}`;
  return uri;
}
