<template>
  <button-default
    :bc-box-shadow-color-opacity="0.1"
    :bc-box-shadow-color-opacity-hover="0.15"
    @click.native="resetSearchQueries"
    bc-background-color="white"
    bc-box-shadow="0 3px 6px"
    bc-box-shadow-color="black"
    bc-box-shadow-color-hover="black"
    bc-icon="refresh"
    bc-icon-margin="10px"
    bc-padding="8px 15px"
    bc-radius="50px"
    bc-text-color="blue-dark-cello"
    class="searchbar-buttons-reset">
    {{ $t('companies.account-management.reinitialize') }}
  </button-default>
</template>

<script>
  // TODO move or refacto to ui-kit
  import ButtonDefault from '@/legacy/ui-kit/components/Button/BcButton';

  export default {
    name: 'searchbar-buttons-reset',
    components: { ButtonDefault },
    methods: {
      /**
       * This methods will trigger a new $route with no queries and empty the state searchQueries
       */
      resetSearchQueries() {
        this.$store.dispatch('resetSearchbarFilters');
        this.$store.dispatch('resetSearchbarFiltersTags');

        this.$emit('on-reset');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .searchbar-buttons-reset {
    font-weight: $font-weight-light;
    font-size: $font-size-xxs;
    text-transform: uppercase;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  }
</style>
