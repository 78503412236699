<template>
  <div class="welcometothejungle">
    <JobboardConfigItem
      :channel="channel"
      :subscriptionModel="subscriptionModel"
      :applicationMode="applicationMode"
      :applicationForwardingType="applicationForwardingType"
      :disabledSubscriptions="disabledSubscriptions"
      :disabledApplications="disabledApplications"
      :is-valid="isValid"
      @update-subscription-model="updateSubscriptionModel"
      @update-application-mode="updateApplicationMode"
      @update-application-forwarding-type="updateApplicationForwardingType"
      @cancel="$emit('cancel', $event)"
      @submit="onSubmit">
      <template #subscriptionContent>
        <div class="mt-5 pt-3 is-full-width">
          <ContractIdField
            :label="'ORGANIZATION_REFERENCE'"
            :placeholder="`Welcometothejungle ORGANIZATION_REFERENCE`"
            :warning="$t('jobboards.warning-login-welcometothejungle')"
            v-model="ORGANIZATION_REFERENCE"
          />
        </div>
      </template>
    </JobboardConfigItem>
  </div>
</template>

<script>
import { subscriptionsModels, applicationModes } from '@/api/xtramile';
import ContractIdField from '@/components/Jobboard/JobboardConfigChannel/Fields/ContractIdField';
import JobboardConfigItem from '@/components/Jobboard/JobboardConfig/JobboardConfigItem';

export default {
  name: 'welcometothejungle',
  components: {
    ContractIdField,
    JobboardConfigItem,
  },
  props: {
    channel: {
      type: Object,
      default: () => ({}),
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    disabledSubscriptions: {
      type: Array,
      default: () => [],
    },
    disabledApplications: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    if (this.values?.params) {
      this.subscriptionModel = this.values.params.subscriptionModel;
      this.applicationMode = this.values.params.applicationMode;
      this.applicationForwardingType = this.values.params.applicationForwardingType;
      this.ORGANIZATION_REFERENCE = this.values.params.accountParams.ORGANIZATION_REFERENCE;
    }
  },
  data() {
    return {
      subscriptionsModels,
      applicationModes,
      ORGANIZATION_REFERENCE: '',
      subscriptionModel: '',
      applicationMode: '',
      applicationForwardingType: '',
    }
  },
  computed: {
    isSubscriptionValid() {
      if (!this.subscriptionModel) return false;
      return !!this.ORGANIZATION_REFERENCE;
    },
    isApplicationValid() {
      if (!this.applicationMode) return false;
      return true;
    },
    isValid() {
      return this.isSubscriptionValid && this.isApplicationValid;
    }
  },
  methods: {
    updateSubscriptionModel(key) {
      this.subscriptionModel = key;
    },
    updateApplicationMode(key) {
      this.applicationMode = key;
    },
    updateApplicationForwardingType(value) {
      this.applicationForwardingType = value;
    },
    onSubmit() {
      this.$emit('submit', {
        channelId: this.channel.id,
        subscriptionModel: this.subscriptionModel,
        applicationMode: this.applicationMode,
        applicationForwardingType: this.applicationForwardingType,
        accountParams: {
          ORGANIZATION_REFERENCE: this.ORGANIZATION_REFERENCE,
        }
      })
    }
  }
}
</script>
