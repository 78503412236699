<template>
  <select-container :label="label" icon="folder">
    <bc-dropdown
      :id="$attrs.id"
      :class="{ 'salary-types-select__dropdown--secondary': isVariable }"
      :is-scrollable="true"
      :limit="3"
      :options="salaryOptions"
      class="salary-types-select__dropdown"
      is-expanded>
      <template #label="{ isDropdownOpen }">
        <bc-dropdown-trigger
          :is-arrow="true"
          :is-dropdown-open="isDropdownOpen"
          :text="selectedSalary.label"
          type="button">
        </bc-dropdown-trigger>
      </template>
      <template #option="{ activeIndex, index, option }">
        <bc-dropdown-item
          :is-focus="activeIndex === index"
          :text="option.label"
          @click.native="selectSalary(option, index)">
        </bc-dropdown-item>
      </template>
    </bc-dropdown>
  </select-container>
</template>

<script>
  import { salaryTypesLabels } from '@/api/custom-jobs';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
  import SelectContainer from '@/components/Select/SelectContainer';

  export default {
    name: 'salary-types-select',
    components: {
      BcDropdown,
      BcDropdownItem,
      BcDropdownTrigger,
      SelectContainer,
    },
    props: {
      selected: {
        type: String,
        required: false,
      },
      placeholder: {
        type: String,
      },
      label: {
        type: String,
        default: '',
      },
      isVariable: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      if (this.selected) {
        this.selectedSalary = {
          apiCode: this.selected,
          label: this.salaryTypesLabels[this.selected],
        };
      } else {
        this.selectSalary(this.salaryOptions[0]);
      }
    },
    data() {
      return {
        selectedSalary: {},
        salaryTypesLabels,
      };
    },
    computed: {
      salaryOptions() {
        let options = Object.entries(this.salaryTypesLabels);
        if (this.placeholder) {
          options = [[0, this.placeholder], ...options];
        }

        const mappedOptions = options.map(([apiCode, label]) => ({
          apiCode,
          label,
        }));

        return this.isVariable ? mappedOptions : mappedOptions.filter(opt => opt.apiCode !== 'percentage');
      },
    },
    methods: {
      selectSalary(option) {
        this.selectedSalary = option;
        this.$emit('select', option);
      },
    },
    watch: {
      selected(type) {
        this.selectedSalary = {
          apiCode: type,
          label: this.salaryTypesLabels[type],
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .salary-types-select {
    &__dropdown {
      width: 100%;
      min-width: 262px;

      :deep() {
        .bc-dropdown-trigger {
          height: auto;
        }

        button {
          background: $color-primary;
          color: white;

          &:hover {
            color: white;
          }
        }
      }
      &--secondary {
        :deep() {
          .bc-dropdown-trigger {
            background: white;
            color: $color-secondary-dark;

            &:hover {
              color: $color-primary;
            }
          }
        }
      }

    }
  }
</style>
