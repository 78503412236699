export let timelineCoders = {
  201: {
    description: 'A coder subscribed to the platform',
    icon: 'user-plus',
    author: {
      type: 'coder',
      after: 'timeline.registered-on-the-platform',
    }
  },
  202: {
    description: 'A coder completed his profile',
    icon: 'check',
    author: {
      type: 'coder',
      after: 'timeline.edited-their-profile',
    }
  },
  203: {
    description: 'A coder saw a job',
    icon: 'eye',
    author: {
      type: 'coder',
      after: 'timeline.saw',
    },
    directObject: {
      type: 'job',
      after: 'timeline.of',
    },
    from: {
      type: 'company'
    }
  },
  204: {
    description: 'A coder favorited a job',
    icon: 'star-full',
    author: {
      type: 'coder',
      after: 'timeline.has-favorited',
    },
    directObject: {
      type: 'job'
    }
  },
  205: {
    description: 'A coder poked a job',
    icon: 'arrow-right',
    author: {
      type: 'coder',
      after: 'timeline.is-interested-in',
    },
    directObject: {
      type: 'job'
    }
  },
  206: {
    description: 'A coder changed his status',
    icon: 'edit',
    author: {
      type: 'coder',
      after: 'timeline.changed-their-status',
    }
  },
  207: {
    description: 'A coder pokeback',
    icon: 'arrow-right',
    author: {
      type: 'coder',
      after: 'timeline.pokeback',
    },
    directObject: {
      type: 'job'
    }
  },
  208: {
    description: 'A coder made a feedback',
    icon: 'note',
    author: {
      type: 'coder',
      after: 'timeline.gave-feedback',
    }
  },
  209: {
    description: 'A coder merged',
    icon: 'note',
    author: {
      type: 'coder',
      after: 'timeline.registered-when-his-profile-already-existed',
    }
  },
  210: {
    description: 'A coder has set his password',
    icon: 'note',
    author: {
      type: 'coder',
      after: 'timeline.has-set-their-password-for-the-platform',
    }
  },
  211: {
    description: 'A coder has validated his account',
    icon: 'check',
    author: {
      type: 'coder',
      after: 'timeline.validated-his-account',
    }
  },
  212: {
    description: 'A coder has archived a chat',
    icon: 'trash',
    author: {
      type: 'coder',
      after: 'timeline.archived-this',
    },
    directObject: {
      type: 'poke'
    }
  },
  213: {
    description: 'A coder has unarchived a chat',
    icon: 'trash',
    author: {
      type: 'coder',
      after: 'timeline.unarchived-this',
    },
    directObject: {
      type: 'poke'
    }
  },
  214: {
    description: 'A coder has deleted a chat',
    icon: 'trash',
    author: {
      type: 'coder',
      after: 'timeline.deleted-this',
    },
    directObject: {
      type: 'poke'
    }
  },
  215: {
    description: 'A coder has been merged',
    icon: 'minimize-2',
    author: {
      type: 'coder',
      after: 'timeline.was-merged',
    },
  },
  216: {
    description: 'A coder visited bluecoders',
    icon: 'eye',
    author: {
      type: 'coder',
      after: 'timeline.has-visited-bluecoders-since',
    },
  },
  217: {
    description: 'Coder is interesting',
    icon: 'thumbs-up',
    author: {
      type: 'coach',
      after: 'timeline.is-interested-by',
    },
    directObject: {
      type: 'coder',
    },
  },
  218: {
    description: 'Coder is not interesting',
    icon: 'thumbs-down',
    author: {
      type: 'coach',
      after: 'timeline.is-not-interested-by',
    },
    directObject: {
      type: 'coder',
    },
  },
  220: {
    description: 'Marvin follow has detected some changes on a candidate profile',
    icon: 'watch',
    author: {
      type: 'coach',
      after: 'timeline.detected-changes-in-the-linkedin-profile-of',
    },
    directObject: {
      type: 'coder',
    },
  },
  223: {
    description: 'A coder completed the subscription typeform',
    icon: 'tiptap-checklist',
    author: {
      type: 'coder',
      after: 'timeline.has-completed-the-registration-typeform',
    },
  },
  231: {
    name: 'coderUploadCv',
    icon: 'upload',
    description: 'Quand un candidat upload son cv depuis une plateforme d\'opportunités',
    author: {
      type: 'coder',
    }
  },
  232: {
    name: 'coderApplyJob',
    icon: 'send',
    description: 'Quand un candidat postule depuis une plateforme d\'opportunités',
    author: {
      type: 'coder',
    },
    directObject: {
      type: 'job'
    }
  },
};
