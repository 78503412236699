// TODO Corriger les imports de state.professions.professions et state.sizes.sizes sur Coders & Partners

export const sizes = [
  {
    type: 0,
    name: 'panel-company-edit.size-options-0',
  },
  {
    type: 1,
    name: 'panel-company-edit.size-options-1',
  },
  {
    type: 2,
    name: 'panel-company-edit.size-options-2',
  },
  {
    type: 3,
   name:  'panel-company-edit.size-options-3',
  },
  {
    type: 4,
    name: 'panel-company-edit.size-options-4',
  },
  {
    type: 5,
    name: 'panel-company-edit.size-options-5'
  },
  {
    type: 6,
    name: 'panel-company-edit.size-options-6',
  },
  {
    type: 7,
    name: 'panel-company-edit.size-options-7',
  },
  {
    type: 8,
    name: 'panel-company-edit.size-options-8',
  },
  {
    type: 9,
    name: 'panel-company-edit.size-options-9',
  }
];

export default sizes;
