import favoriteLists from '@/api/favoriteLists';

export const setListNotifications = (id, notifications) => {
  const execute = (id, notifications) => {
    try {
      return favoriteLists.updateFavoriteListNotifications(id, notifications);
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    return execute(id, notifications);
  } catch (error) {
    throw error;
  }
};

export default {
  setListNotifications,
};
