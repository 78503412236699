<template>
  <div
    v-click-outside="manageClosePanel"
    class="panel-tag"
    @click.stop>
    <panel-tag-header
      :tag="tag"
      class="panel-tag__header"
      @tag-deleted="deleteTag">
    </panel-tag-header>
    <panel-tag-body :tag="tag"></panel-tag-body>
    <panel-categories
      :categories="categories"
      class="panel-tag__categories">
    </panel-categories>
    <panel-view>
      <component
        v-if="!isLoading"
        :is="categoryComponent"
        :tag="tag"
        @get-tag="getTag"
      />
    </panel-view>
  </div>
</template>

<script>
  import { getTagById } from '@/api/tags';
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import PanelCategories from '@/components/Panel/PanelCategoriesOldStyle';
  import PanelView from '@/components/Panel/PanelView';
  import PanelTagHeader from '@/components/Panel/PanelTag/PanelTag/PanelTagHeader';
  import PanelTagBody from '@/components/Panel/PanelTag/PanelTag/PanelTagBody';
  import PanelTagView from '@/components/Panel/PanelTag/PanelTag/PanelTagView';
  import PanelTagGraph from '@/components/Panel/PanelTag/PanelTag/PanelTagGraph';

  export default {
    name: 'panel-tag',
    components: {
      PanelTagView,
      PanelTagBody,
      PanelTagHeader,
      PanelView,
      PanelCategories,
      PanelTagGraph,
    },
    mixins: [PanelNavigation],
    data() {
      return {
        categoryComponents: {
          detail: 'panel-tag-view',
          graph: 'panel-tag-graph',
        },
        tag: {},
        isLoading: true,
      };
    },
    computed: {
      categories() {
        const categories = [
          {
            name: "Détail",
            url: 'detail',
          },
          {
            name: "Graph",
            url: 'graph',
          },
        ]
        
        return categories;
      },
      categoryComponent() {
        if (this.categoryComponents[this.$route.query.category]) {
          return this.categoryComponents[this.$route.query.category];
        }
        return 'panel-tag-view';
      }
    },
    methods: {
      async getTag() {
        try {
          const { data } = await getTagById(this.$route.params.id);

          this.tag = data;
          this.isLoading = false;
        } catch (error) {
          this.$toast.show({
            title: 'Accès refusé',
            message: 'Seul les utilisateurs avec le role god sont autorisés à accéder à la ressource',
            type: 'error',
            icon: 'cross',
          });
          this.isLoading = false;
        }
      },
      async deleteTag() {
        // Display success message
        this.$toast.show({
          type: 'success',
          message: this.$t('tags.the-tag-has-been-deleted-successfully'),
        })

        this.closePanel()
      },
    },
    watch: {
      $route: {
        handler() {
          this.getTag();
        },
        immediate: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-tag {
    height: 100%;
    flex-direction: column;

    &__header {
      order: -2;
    }

    &__categories {
      order: -1;
      @include bp('tablet') {
        order: 0
      }
    }
  }
</style>
