
<script setup lang="ts">
import { ref } from 'vue';
import InfiniteLoading from 'vue-infinite-loading';
import { useIntersectionObserver } from '@vueuse/core';
import { useI18n } from '@/i18n/i18n';
import BcSpinner from '@/ui-kit/components/BcSpinner';


const emit = defineEmits<{
  (e: 'infinite', $state: any): void,
}>();

const { t } = useI18n();

const stateIfReady = ref();


function handleInfinite($state) {
  if (targetIsVisible.value) {
    emit('infinite', $state);
  }
  else {
    stateIfReady.value = $state;
  }
}


const infiniteLoading = ref<any>(null)
const targetIsVisible = ref(false)

const { stop } = useIntersectionObserver(
  infiniteLoading,
  ([{ isIntersecting }], observerElement) => {
    targetIsVisible.value = isIntersecting
    if (isIntersecting && stateIfReady.value) {
      emit('infinite', stateIfReady.value);
      stateIfReady.value = undefined;
    } else if (isIntersecting && infiniteLoading.value?.scrollParent) {
      if (infiniteLoading.value?.scrollParent?.scrollTop === 0) {
        infiniteLoading.value?.scrollParent.scroll({ top: 10 });
      }
    }
  },
)
</script>



<template>
  <InfiniteLoading v-bind="$attrs" ref="infiniteLoading" class="flex flex-col justify-center" @infinite="handleInfinite">
    <template #no-results>
      <slot name="no-results">
        <p class="flex w-full grow items-center rounded-md bg-neutral-200 px-5 py-4 text-blue-800">
          {{ t('generics.no-result') }}
        </p>
      </slot>
    </template>

    <template #no-more>
      <slot name="no-more">
        <div />
      </slot>
    </template>

    <template #spinner>
      <slot name="spinner">
        <BcSpinner class="mt-2 flex w-full justify-center" />
      </slot>
    </template>


  </InfiniteLoading>
</template>

<style scoped>

:deep(.infinite-status-prompt) {
  width:100%;
  display: flex;
}
</style>
