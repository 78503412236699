<template>
  <div v-if="!isSyncLoading && connectedChannelsSubscriptions.length" class="jobboard-publish-list">
    <div class="jobboard-publish-list__list">
      <jobboard-publish-item
        v-for="(channelSubscription, index) in connectedChannelsSubscriptions"
        :id="channelSubscription.id"
        :key="channelSubscription.id"
        :class="{ 'jobboard-publish-list--separator': index !== connectedChannelsSubscriptions.length - 1 }"
        :channel-subscription-config="channelSubscriptionsConfig[channelSubscription.id]"
        :name="channelSubscription.connectorName"
        :value="getJobboardSyncValue(channelSubscription)"
        :label="getJobboardName(channelSubscription)"
        :logo="channelSubscription.logoUrl"
        :available="channelSubscription.available"
        :enabled="isEnabled(channelSubscription.id, channelSubscription.channelId, channelSubscription.connectorName)"
        :auto-publish="isAutoPublish(channelSubscription.id, channelSubscription.connectorName)"
        :disabled="checkIsDisabled(channelSubscription)"
        :is-loading="isLoading[channelSubscription.key]"
        :status="(publicationStatus[channelSubscription.id] || {}).status"
        :editable="isEditable(channelSubscription)"
        :warning-message="hasAutopublish(channelSubscription) && customJob.adPublished ? $t('jobboards.auto-publish-warning') : ''"
        :show-subscription-mode="hasMultipleChannelsSubscriptions(channelSubscription.connectorName)"
        @input="handleTogglePublish($event, channelSubscription)"
        @edit="handleEdit(channelSubscription)"
        @delete="handleDelete(channelSubscription)"
      >
        <div v-if="channelSubscription.connectorName === 'indeed__xtramile'">
          <bc-checkbox
            id="indeed__xtramile-custom-sponsored"
            :value="isJobSponsored"
            @input="handleCheckSponsored(channelSubscription)"
          >
            <span>Sponsored</span>
          </bc-checkbox>
        </div>
      </jobboard-publish-item>
    </div>
    <div>
      <router-link class="jobboard-publish-list__see-more" to="/configuration/jobboards">
        {{ $t('generics.see-more-jobboards') }}
      </router-link>
    </div>
    <bc-modal :active="showRecap" :closable="false" @update:active="showRecap = false">
      <custom-jobs-recap
        :customJob="customJob"
        :isLoading="isPublishLoading"
        @cancel="cancelPublish"
        @submit="onPublishJob"
      ></custom-jobs-recap>
    </bc-modal>
    <bc-modal :active="modalSubmitForm !== ''" @close="closeModal">
      <component
        :is="`${modalSubmitForm}-form-modal`"
        :custom-job="customJob"
        :is-edit="isEdit"
        @update="handleSubmitUpdate"
        @cancel="closeModal"
      />
    </bc-modal>
    <modal-delete-item
      :is-open="warningModal.title !== ''"
      :title="warningModal.title"
      :confirm-text="warningModal.confirmText"
      @on-confirm="warningModal.confirmAction"
      @close="closeWarningModal"
    >
      <template #content>
        <p>{{ warningModal.content }}</p>
      </template>
    </modal-delete-item>
    <div
      v-if="!configurationFeatures.multidiffusion"
      class="jobboard-publish-list__feature-lock"
      @click="showFeatureModal"
    ></div>
  </div>
  <div v-else-if="isSyncLoading" class="jobboard-publish-list__sync-loader mx-auto">
    <bc-spinner></bc-spinner>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { syncJob, remove, updateStatus } from '@/api/job-multipost';
import { Modals } from '@/store/modules/app';
import { publish, getPublicationsStatus, unpublish, publishedStatus, publicationStatus, updateJob } from '@/api/xtramile';
import { publish as publishMultipost } from '@/api/job-multipost';
import { descriptionTypes } from '@/api/custom-jobs';
import { removeTags } from '@/helpers/strings';

import ModalDeleteItem from '@/components/Modal/ModalDeleteItem';
import BcModal from '@/ui-kit/components/BcModal/BcModal';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
// import WttjFormModal from '@/components/Jobboard/FormModal/WttjFormModal';
import HelloworkFormModal from '@/components/Jobboard/FormModal/HelloworkFormModal';
import JobboardPublishItem from './JobboardPublishItem';
import CustomJobsRecap from '@/components/CustomJobs/CustomJobsRecap';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';

export default {
  name: 'jobboard-publish-list',
  components: {
    BcModal,
    BcSpinner,
    ModalDeleteItem,
    JobboardPublishItem,
    CustomJobsRecap,
    HelloworkFormModal,
    BcCheckbox,
  },
  props: {
    customJob: {
      type: Object,
      default: () => ({}),
    },
    jobboardModal: {
      type: String,
      default: '',
    },
    isJobPublished: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    if (!this.configurationFeatures.multidiffusion) {
      return;
    }
    this.isSyncLoading = true;

    await this.getActivatedChannels();
    await this.getActivatedChannelsSubscriptions();

    if (this.configurationIntegrations?.xtramile?.enabled) {
      this.getPublicationsStatus();
      await this.updateXtramileJob();
    }

    this.syncJob();
  },
  data() {
    return {
      customJobSync: undefined,
      xtramileJob: {
        custom: {
          sponsored: 'yes',
        }
      },
      warningModal: {
        title: '',
        content: '',
        confirmText: '',
        confirmAction: () => {},
      },
      modalSubmitForm: '',
      showRecap: false,
      selectedChannelSubscription: undefined,
      isLoading: {},
      isPublishLoading: false,
      isSyncLoading: false,
      isEdit: false,
      publicationStatus: {},
      editableList: ['hellowork'],
      errorsMessages: {
        "salaryMin should be at least 19000": this.$i18n.t('jobboards.apec-salary-min')
      },
    }
  },
  computed: {
    ...mapState({
      activatedChannels: state => state.xtramile.activatedChannels,
      activatedChannelsSubscriptions: state => state.xtramile.activatedChannelsSubscriptions,
    }),
    ...mapGetters({
      organization: 'user/organization',
      configurationFeatures: 'user/configurationFeatures',
      configurationIntegrations: 'user/configurationIntegrations',
      configurationJobMultiposting: 'user/configurationJobMultiposting',
    }),
    profile() {
      return this.$store.state.user.profile;
    },
    list() {
      return [
        {
          key: 'wttj',
          label: 'Welcome to the jungle',
          logo: require('@/assets/logo/wttj_logo.png'),
          available: true,
          enabled: this.configurationJobMultiposting?.wttj?.enabled || false,
          published: this.customJob?.jobMultiposting?.wttj?.status === 'published' || false,
          disabled: this.isDisabled || !this.careerWebsiteActivated,
        },
      ]
    },
    isJobSponsored() {
      if (this.xtramileJob?.custom?.sponsored) {
        return this.xtramileJob?.custom?.sponsored === 'yes'
      }
      return true;
    },
    careerWebsiteActivated() {
      return this.organization.configuration?.career?.activated || false;
    },
    isDisabled() {
      return !this.customJob.active || !this.customJob.adPublished;
    },
    channelSubscriptions() {
      return this.configurationIntegrations.xtramile?.channelSubscriptions;
    },
    connectedChannelsSubscriptions() {
      return this.activatedChannelsSubscriptions.filter(channelSubscription => this.isEnabled(channelSubscription.id, channelSubscription.channelId, channelSubscription.connectorName));
    },
    companyDescription() {
      if (this.customJob.descriptionType === descriptionTypes.client) {
        return this.customJob._company?.description;
      } else if (this.customJob.descriptionType === descriptionTypes.custom) {
        return this.customJob.customCompanyDescription;
      }
      return this.customJob._organization?.description;
    },
    channelSubscriptionsConfig() {
      return this.configurationIntegrations?.xtramile?.channelSubscriptions || {};
    },
    websiteName() {
      return this.profile?._organization?.configuration?.integrations?.job_multiposting?.website?.params?.websiteName;
    },
  },
  methods: {
    ...mapActions({
      setModal: 'app/setModal',
      getActivatedChannels: 'xtramile/getActivatedChannels',
      getActivatedChannelsSubscriptions: 'xtramile/getActivatedChannelsSubscriptions',
    }),
    getJobboardName(channelSubscription = {}) {
      if (channelSubscription?.title?.toLowerCase() === 'website') {
        return this.websiteName;
      }
      return channelSubscription?.title;
    },
    getJobboardSyncValue(channelSubscription) {
      if (!this.customJob.adPublished) {
        return false;
      }
      if (this.hasAutopublish(channelSubscription)) {
        return this.customJob.adPublished;
      }
      if (this.isDeduplicationSent(channelSubscription)) {
        return false;
      }
      return this.isPublished(channelSubscription);
    },
    checkIsDisabled(channelSubscription) {
      if (this.hasAutopublish(channelSubscription)) {
        return true;
      }
      if (this.isJobboardPending(channelSubscription)) {
        return true;
      }
      return this.isDisabled;
    },
    isDeduplicationSent(channelSubscription = {}) {
      return [publicationStatus.DEPUBLICATION_SENT].includes((this.publicationStatus[channelSubscription.id] || {}).status);
    },
    isJobboardPending(channelSubscription = {}) {
      return [publicationStatus.DEPUBLICATION_PENDING, publicationStatus.PENDING].includes((this.publicationStatus[channelSubscription.id] || {}).status);
    },
    hasAutopublish(channelSubscription = {}) {
      return this.configurationJobMultiposting?.[channelSubscription.connectorName]?.params?.autoPublish || channelSubscription.connectorName === 'indeed__xtramile';
    },
    isPublished(channelSubscription) {
      if (channelSubscription.isInternal) {
        return this.customJob.jobMultiposting?.[channelSubscription.connectorName]?.status === 'published';
      }
      return publishedStatus.includes(this.publicationStatus[channelSubscription.id]?.status);
    },
    isEditable(jobboard) {
      return this.editableList.includes(jobboard.connectorName);
    },
    async onPublishJob(customJob) {
      try {
        this.isPublishLoading = true;

        const { data: updatedCustomJob } = await publish({
          customJobId: customJob._id,
          channelId: this.selectedChannelSubscription.channelId,
          channelSubscriptionId: this.selectedChannelSubscription.id,
        });
        const publication = updatedCustomJob.xtramilePublications[this.selectedChannelSubscription.id]
        this.publicationStatus = {
          ...this.publicationStatus,
          [this.selectedChannelSubscription.id]: {
            ...this.publicationStatus[this.selectedChannelSubscription.id] || {},
            status: publication.status,
          }
        }
        this.isPublishLoading = false;
        this.$emit('on-update', updatedCustomJob);
        this.closeModal();
      } catch (err) {
        const errors = this.getTradErrors(err.response?.data?.errors);
        let errorsMsg = '';
        if (errors?.length) {
          errorsMsg += errors.map(error => `${Object.keys(error.constraints).map(k => error.constraints[k])}`).join(', ');
        }

        if (this.selectedChannelSubscription?.connectorName === 'indeed__xtramile') {
          this.$emit('on-job-unpublish');
        }

        this.showRecap = false;
        this.isPublishLoading = false;
        this.$toast.show({
          type: 'error',
          message: errorsMsg || err.response?.data?.message || err.message,
          duration: 6000,
        });
      }
    },
    async unpublish(channelSubscription = {}) {
      try {
        if (channelSubscription.isInternal) {
          await this.updateStatus(channelSubscription.connectorName, 'unpublished');
          this.publicationStatus = {
            ...this.publicationStatus,
            [this.selectedChannelSubscription.id]: {
              ...this.publicationStatus[this.selectedChannelSubscription.id] || {},
              status: undefined,
            }
          }
          return;
        }

        const publicationId = this.customJob?.xtramilePublications?.[channelSubscription.id]?.publicationId
        if (!publicationId) {
          throw new Error('publicationId not found');
        }

        this.isPublishLoading = true;

        const { data } = await unpublish({
          publicationId,
          customJobId: this.customJob._id,
          channelId: channelSubscription.channelId,
          channelSubscriptionId: channelSubscription.id,
        });

        this.publicationStatus = {
          ...this.publicationStatus,
          [this.selectedChannelSubscription.id]: {
            ...this.publicationStatus[this.selectedChannelSubscription.id] || {},
            status: data.status,
          }
        }
        this.isPublishLoading = false;
        this.closeModal();
      } catch (err) {
        this.isPublishLoading = false;
        this.$toast.show({
          type: 'error',
          message: err.response?.data?.message || err.message,
        });
      }
    },
    async getPublicationsStatus() {
      if (!this.customJob?._id) {
        return;
      }

      try {
        const { data } = await getPublicationsStatus({ customJobId: this.customJob._id });
        this.publicationStatus = data;
      } catch (err) {
        this.$emit('failed-status');
      }
    },
    async syncJob() {
      if (!this.customJob?._id) {
        return;
      }

      try {
        const { data: customJobSync } = await syncJob(this.customJob._id);
        this.isSyncLoading = false;
        this.$emit('on-update', customJobSync);
      } catch (err) {
        this.isSyncLoading = false;
      }
    },
    handleEdit(channelSubscription = {}) {
      // this.showRecap
      const key = channelSubscription.key || channelSubscription.connectorName;
      this.selectedChannelSubscription = this.connectedChannelsSubscriptions.find(channelSub => channelSub.connectorName === key);
      this.modalSubmitForm = key;
      this.isEdit = true;
    },
    handleDelete(channelSubscription = {}) {
      this.warningModal.confirmAction = this.removeJobPost.bind(this, channelSubscription.key);
      this.warningModal.title = this.$t('jobboards.remove-from', { job: this.customJob.title, from: channelSubscription.label });
    },
    checkMandatoryFields() {
      const missingFields = [];
      if (!this.customJob.locations?.length) {
        missingFields.push(this.$t('generics.location'))
      }
      if (!this.customJob.description) {
        missingFields.push(this.$t('generics.description'))
      }
      if (!this.customJob.profileDescription) {
        missingFields.push(this.$t('generics.profileDescription'))
      }
      return { isValid: !missingFields.length, missingFields }
    },
    handleTogglePublish(active, channelSubcription, force = false) {
      if (!force && (!this.configurationFeatures.multidiffusion || this.hasAutopublish(channelSubcription))) {
        return;
      }

      if (active && removeTags(this.companyDescription).length < 200) {
        this.$toast.show({
          type: 'error',
          message: this.$t('jobboards.min-company-description'),
          duration: 4000,
        });
        return;
      }

      this.selectedChannelSubscription = channelSubcription;

      if (!active && (this.publicationStatus[channelSubcription.id]?.status || this.isPublished(channelSubcription))) {
        this.warningModal.confirmAction = this.unpublish.bind(this, channelSubcription);
        this.warningModal.confirmText = this.$t('jobboards.yes-disable');
        this.warningModal.title = this.$t('jobboards.disable-from', { job: this.customJob.title, from: channelSubcription.connectorName });
        return;
      }

      const { isValid, missingFields } = this.checkMandatoryFields();
      if (!isValid) {
        this.$toast.show({
          type: 'error',
          message: this.$t('jobboards.missing-fields', { fields: missingFields.join(', ')?.toLowerCase() }),
          duration: 6000,
        })
        return;
      }

      if (channelSubcription.connectorName === 'website') {
        this.handleSubmitWebsite(this.customJob);
        return;
      }

      if (channelSubcription.isInternal) {
        this.modalSubmitForm = channelSubcription.connectorName;
        return;
      }

      this.showRecap = true;
    },
    hasSponsored(channelSubscription) {
      if (this.publicationStatus[channelSubscription.id].custom && 'sponsored' in this.publicationStatus[channelSubscription.id].custom) {
        return Boolean(this.publicationStatus[channelSubscription.id].custom.sponsored);
      }
      return true;
    },
    async handleSubmitWebsite(customJob) {
      try {
        await publishMultipost({
          jobboard: 'website',
          customJobId: customJob._id,
        });
        this.handleSubmitUpdate(customJob, 'website')
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        })
      }
    },
    handleSubmitUpdate(updatedCustomJob, jobboard) {
      this.publicationStatus = {
        ...this.publicationStatus,
        [this.selectedChannelSubscription.id]: {
          ...this.publicationStatus[this.selectedChannelSubscription.id] || {},
          status: 'published',
        }
      }

      this.$emit('on-update', updatedCustomJob);
      if (jobboard === this.jobboardModal) {
        this.$emit('on-autopublish', jobboard);
      }
      this.closeModal();
      this.$toast.show({
        type: 'success',
        message: this.$t('jobboards.published-success', { jobboard }),
      })
    },
    async updateStatus(jobboard, status) {
      this.isLoading = { [jobboard]: true }
      try {
        const { data } = await updateStatus({ customJobId: this.customJob._id, jobboard, status });
        this.isLoading = { [jobboard]: false }
        this.closeWarningModal();
        this.$emit('on-update', data);
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.response?.data?.message || err.message,
        });
        this.isLoading = { [jobboard]: false }
      }
    },
    async removeJobPost(jobboard) {
      this.isLoading = { [jobboard]: true }
      try {
        const { data } = await remove({ customJobId: this.customJob._id, jobboard });
        this.isLoading = { [jobboard]: false }
        this.closeWarningModal();
        this.$emit('on-update', data);
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
        this.isLoading = { [jobboard]: false }
      }
    },
    cancelPublish() {
      if (this.selectedChannelSubscription?.connectorName === 'indeed__xtramile') {
        this.$emit('on-job-unpublish');
      }
      this.closeModal();
    },
    closeModal() {
      this.showRecap = false;
      this.modalSubmitForm = '';
      this.isEdit = false;
      this.closeWarningModal();
    },
    closeWarningModal() {
      this.warningModal = { title: '', confirmText: '', content: '', confirmAction: () => {} }
    },
    showFeatureModal() {
      this.setModal(Modals['modal-feature-multipost']);
    },
    isEnabled(channelSubscriptionId, channelId, name) {
      const channel = this.activatedChannels.find(channel => channel.id === channelId);
      if (channel?.isInternal) {
        return this.configurationIntegrations?.job_multiposting?.[name]?.enabled && this.configurationIntegrations?.job_multiposting?.[name]?.activated;
      }
      return this.channelSubscriptions?.[channelSubscriptionId]?.response?.id !== undefined;
    },
    isAutoPublish(channelId, name) {
      const channel = this.activatedChannels.find(channel => channel.id === channelId);
      if (channel?.isInternal) {
        return this.configurationIntegrations?.job_multiposting?.[name]?.params?.autoPublish;
      }
      return false;
    },
    getTradErrors(errors = []) {
      return errors.map(error => {
        let constraints = error.constraints;
        Object.keys(error.constraints).forEach(key => {
          if (this.errorsMessages[error.constraints[key]]) {
            constraints[key] = this.errorsMessages[error.constraints[key]]
          }
        });
        return {
          ...error,
          constraints,
        }
      });
    },
    async handleCheckSponsored(channelSubcription = {}) {
      try {

        let sponsored = 'yes';
        if (this.xtramileJob?.custom?.sponsored) {
          sponsored = this.xtramileJob.custom.sponsored === 'yes';
        }

        const { data } = await updateJob({
          customJobId: this.customJob._id,
          connectorName: channelSubcription.connectorName,
          fields: {
            custom: {
              sponsored: sponsored === 'yes' ? 'no' : 'yes',
            }
          }
        });

        this.xtramileJob = data;
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async updateXtramileJob() {
      if (!this.customJob._id) return;

      const { isValid } = this.checkMandatoryFields();
      if (!isValid) return;

      try {
        const { data } = await updateJob({ customJobId: this.customJob._id });
        if (data?.id) {
          this.xtramileJob = data;
        }
      } catch (err) {
        // No need to show err to users during silent sync
        console.log('LOGS-- updateXtramileJob err', err);
      }
    },
    hasMultipleChannelsSubscriptions(connectorName = '') {
      const channelsSubscriptions = this.connectedChannelsSubscriptions.filter(channelSub => channelSub.connectorName === connectorName);
      return channelsSubscriptions?.length > 1;
    }
  },
  watch: {
    jobboardModal(value) {
      this.selectedChannelSubscription = this.connectedChannelsSubscriptions.find(channelSub => channelSub.connectorName === value);
      this.modalSubmitForm = value;
    },
    isJobPublished: {
      handler(isPublished) {
        const indeedXtramile = this.connectedChannelsSubscriptions.find(channelSub => channelSub.connectorName === 'indeed__xtramile');
        if (indeedXtramile?.id && isPublished) {
          this.handleTogglePublish(isPublished, indeedXtramile, true);
        }
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.jobboard-publish-list {
  position: relative;
  flex-direction: column;
  height: 100%;
  flex: 1;

  &__list {
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid $color-blue-medium;
    background: $color-neutre-4;
  }

  &__see-more {
    color: $color-primary;
    text-decoration: underline;
    margin-top: 10px;
  }

  &--separator {
    border-bottom: 1px solid $color-blue-light;
  }

  &__feature-lock {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #f2f4fd 0%, #fafbff 100%);
    opacity: 0.5;
  }

  &__sync-loader {
    margin-top: 20px;
    justify-content: center;
    align-items: center;
  }
}
</style>
