export default {
  methods: {
    $_displayInfoToast(title, message) {
      this.$toast.show({
        ...title && { title },
        type: 'info',
        message: message,
        duration: 5000,
      });
    },
    $_displayConfirmToast(title, message) {
      this.$toast.show({
        ...title && { title },
        type: 'success',
        message: message,
        duration: 5000,
      });
    },
    $_displayErrorToast(title, error) {
      this.$toast.show({
        ...title && { title },
        type: 'error',
        message: error?.response?.data?.message,
      });
    }
  }
};

