<template>
  <div v-tooltip="actionTooltip" class="date-badge-with-tooltip flex-shrink-0">
    {{ actionDateFromNow }}
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@/i18n/i18n';
import { format, formatDistanceToNowStrict, parseISO } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { computed } from 'vue';
const { t, locale } = useI18n();


const props = defineProps<{
  date: string;
}>();

const actionCreationDate = computed(() => {
  return format(parseISO(props.date), 'd MMMM yyyy', {
    locale: locale.value === 'fr' ? fr : enUS
  });
});

const actionTooltip = computed(() => {
  return {
    content: `${t('companies.panel-view-timeline.the')} ${actionCreationDate.value}`,
  };
});

const actionDateFromNow = computed(() => {
  return formatDistanceToNowStrict(parseISO(props.date), {
    addSuffix: true,
    locale: locale.value === 'fr' ? fr : enUS,
  });
});
</script>

<style scoped lang="scss">
.date-badge-with-tooltip {
  background-color: $color-blue-light;
  color: $color-tertiary;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: $font-size-xs;
  padding: 5px 10px;
  margin-left: 5px;
}
</style>
