<template>
  <div class="panel-organization-multicast-item">
    <div class="panel-organization-multicast-item__label">
      <div class="panel-organization-multicast-item__logo">
        <img :src="logo" :class="logoClass" />
      </div>
      <p class="ml-2">{{ label }}</p>
    </div>
    <div class="panel-organization-multicast-item__toggle">
      <button v-if="$listeners['on-edit']" @click="onEdit" class="mr-5">
        <i class="icon-edit-3"></i>
      </button>
      <bc-toggle
        :id="`panel-organization-multicast-item-${id}`"
        :ref="`panel-organization-multicast-item-${id}`"
        type="default"
        :value="checked"
        @input="handleInput">
      </bc-toggle>
    </div>
  </div>
</template>

<script>
import BcToggle from '@/ui-kit/components/BcToggle/BcToggle';

export default {
  name: 'panel-organization-multicast-item',
  components: {
    BcToggle,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    logo: {
      type: String,
    },
    logoClass: {
      type: String
    }
  },
  mounted() {
    this.handleChecked(this.value);
  },
  data() {
    return {
      id: Date.now(),
      checked: false,
    }
  },
  methods: {
    handleChecked(newValue) {
      const refId = `panel-organization-multicast-item-${this.id}`;
      this.checked = newValue;
      if (!this.$refs[refId]) {
        return;
      }
      this.$refs[refId].$el.children[1].value = newValue;
      this.$refs[refId].$el.children[1].checked = newValue;
    },
    handleInput() {
      this.handleChecked(this.checked);
      this.$emit('input', !this.checked);
    },
    onEdit() {
      this.$emit('on-edit');
    },
  },
  watch: {
    value(newValue) {
      this.handleChecked(newValue);
    },
  }
};
</script>

<style lang="scss" scoped>
.panel-organization-multicast-item {
  width: 100%;
  height: 52px;
  justify-content: space-between;
  padding: 0 15px;

  &__label {
    align-items: center;

    & > p {
      color: $color-tertiary;
      font-size: 16px;
      font-weight: 700;
    }
  }

  &__logo {
    border: 1px solid $color-blue-light;
    border-radius: 100%;
    & > img {
      height: 27px;
    }
  }

  &__toggle {
    align-items: center;

    & .icon-edit-3 {
      color: $color-blue-heavy-dark;

      &:hover {
        color: $color-secondary;
      }
    }
  }
}
</style>
