<template>
  <div
    :class="{'searchbar-coders--open': isActiveFilter}"
    class="searchbar-coders">
    <button-default
      v-if="isActiveFilter"
      bc-background-color="transparent"
      bc-icon="cross"
      bc-icon-size="20px"
      bc-padding="0px"
      bc-text-color="blue-cadet"
      class="searchbar-coders__icon hidden-md hidden-sm"
      @click.native="closeSearchbar">
    </button-default>
    <div class="searchbar-coders__wrapper">
      <searchbar-autocomplete-coders
        class="searchbar-coders__autocomplete"
        @on-delete="updateRouteQueries"
        @on-reset="resetSearchbar"
        @on-select="addTag($event), updateRouteQueries()">
      </searchbar-autocomplete-coders>
    </div>
    <keep-alive>
      <searchbar-filters
        v-if="isActiveFilter"
        class="searchbar-coders__filters"
        @on-reset="resetSearchbar"
        @on-delete="updateRouteQueries">
        <searchbar-professions-autocomplete
          :label="$t('generics.professions')"
          class="searchbar-filters__wrapper"
          @on-select="addTag(buildProfessionsTag($event)), updateRouteQueries()">
        </searchbar-professions-autocomplete>
        <searchbar-skills-autocomplete
          :label="$tc('generics.skill', 2)"
          class="searchbar-filters__wrapper"
          @on-select="onSelectSkills($event), updateRouteQueries()">
        </searchbar-skills-autocomplete>
        <searchbar-filters-location
          :placeholder="$t('companies.account-management.filter-by-region-department-cities')"
          class="searchbar-filters__wrapper"
          @on-select="addTag($event), updateRouteQueries()">
        </searchbar-filters-location>
        <searchbar-filters-contract
          class="searchbar-filters__wrapper"
          @on-select="replaceTag($event), updateRouteQueries()"
          @on-unselect="setTags($event), updateRouteQueries()">
        </searchbar-filters-contract>
        <!-- <searchbar-coders-salary-type
          class="searchbar-filters__wrapper"
          @on-select="onSelectSalaryType($event), updateRouteQueries()"
          @on-unselect="setTags($event), updateRouteQueries()"
        >
        </searchbar-coders-salary-type>
        <searchbar-coders-remuneration
          v-if="salaryType"
          class="searchbar-filters__wrapper"
          @on-select="replaceTag($event), updateRouteQueries()"
          @on-unselect="setTags($event), updateRouteQueries()">
        </searchbar-coders-remuneration>
        <searchbar-coders-remuneration-wanted
          v-if="salaryType"
          class="searchbar-filters__wrapper"
          @on-select="replaceTag($event), updateRouteQueries()"
          @on-unselect="setTags($event), updateRouteQueries()">
        </searchbar-coders-remuneration-wanted> -->
        <searchbar-coders-custom-fields
          @on-select="onSelectCustomFields($event), updateRouteQueries()"
          @on-unselect="onUnselectCustomFields($event), updateRouteQueries()"
        >
        </searchbar-coders-custom-fields>
        <searchbar-filters-phone
          class="searchbar-filters__wrapper"
          @on-select="addTag($event), updateRouteQueries()"
          @on-unselect="removeTag($event), updateRouteQueries()">
        </searchbar-filters-phone>
        <searchbar-filters-ref
          class="searchbar-filters__wrapper"
          @on-select="addTag($event), updateRouteQueries()"
          @on-unselect="removeTag($event), updateRouteQueries()">
        </searchbar-filters-ref>
        <searchbar-filters-last-coach-activity
          :key="`activity-${identifier}`"
          class="searchbar-filters__wrapper"
          @on-select="replaceTag($event), updateRouteQueries()"
          @on-unselect="removeTag($event), updateRouteQueries()">
        </searchbar-filters-last-coach-activity>
        <searchbar-filters-rating
          :key="`rating-${identifier}`"
          class="searchbar-filters__wrapper"
          @on-select="replaceTag($event), updateRouteQueries()"
          @on-unselect="removeTag($event), updateRouteQueries()">
        </searchbar-filters-rating>
      </searchbar-filters>
    </keep-alive>
    <searchbar-filters-result
      v-if="hasFilters"
      :label="$tc('generics.candidates-in-db', resultsCount)"
      @on-reset="handleReset"
    ></searchbar-filters-result>
  </div>
</template>

<script>
  import SearchbarFiltersLastCoachActivity
    from '@/components/Searchbar/SearchbarFilters/SearchbarFiltersLastCoachActivity';
  import { mapGetters, mapState } from 'vuex';
  // helpers
  import SearchbarCoderDispatcher from '@/helpers/searchbar/dispatcher';
  import { mapLocationQuery, splitQuery } from '@/helpers/url/decode_url';
  import { buildCoderStatusTag } from '@/helpers/searchbar/factories/codersTag';
  import {
    buildContractTag,
    buildCVTag,
    buildDomainTag,
    buildEmailTag,
    buildExperienceTag,
    buildLastCoachActivityTag,
    buildLinkedinTag,
    buildLocationsTag,
    buildPhoneTag,
    buildProfessionsTag,
    buildRatingTag,
    buildRemoteTag,
    buildRoleTag,
    buildSalaryTag,
    buildSearchTag,
    buildTechnosTag,
    buildCustomFieldTag,
  } from '@/helpers/searchbar/factories';
  // components
  import SearchbarFiltersLocation
    from '@/components/Searchbar/SearchbarFilters/SearchbarFiltersLocation';
  import SearchbarFilters from '@/components/Searchbar/SearchbarFilters';
  import ButtonDefault from '@/legacy/ui-kit/components/Button/BcButton';
  import SearchbarFiltersContract
    from '@/components/Searchbar/SearchbarFilters/SearchbarFiltersContract';
  import SearchbarFiltersPhone from '@/components/Searchbar/SearchbarFilters/SearchbarFiltersPhone';
  import SearchbarAutocompleteCoders
    from '@/components/Searchbar/SearchbarCoders/SearchbarAutocompleteCoders';
  import SearchbarFiltersRef from '@/components/Searchbar/SearchbarFilters/SearchbarFiltersRef';
  import moment from 'moment/min/moment-with-locales';
  import SearchbarFiltersRating
    from '@/components/Searchbar/SearchbarFilters/SearchbarFiltersRating';
  import SearchbarProfessionsAutocomplete from '@/components/Searchbar/SearchbarProfessions/SearchbarProfessionsAutocomplete';
  import SearchbarSkillsAutocomplete from '@/components/Searchbar/SearchbarSkills/SearchbarSkillsAutocomplete';
  import SearchbarCodersCustomFields from '@/components/Searchbar/SearchbarCoders/SearchbarCodersCustomFields';
  import SearchbarFiltersResult from '@/components/Searchbar/SearchbarFiltersResult';
  // import SearchbarCodersRemuneration from '@/components/Searchbar/SearchbarCoders/SearchbarCodersRemuneration';
  // import SearchbarCodersRemunerationWanted from '@/components/Searchbar/SearchbarCoders/SearchbarCodersRemunerationWanted';
  // import SearchbarCodersSalaryType from '@/components/Searchbar/SearchbarCoders/SearchbarCodersSalaryType';

  export default {
    name: 'searchbar-coders',
    components: {
      SearchbarFiltersRating,
      SearchbarFiltersLastCoachActivity,
      SearchbarFiltersRef,
      SearchbarAutocompleteCoders,
      SearchbarFiltersPhone,
      SearchbarFiltersContract,
      SearchbarFiltersLocation,
      ButtonDefault,
      SearchbarFilters,
      SearchbarProfessionsAutocomplete,
      SearchbarCodersCustomFields,
      SearchbarSkillsAutocomplete,
      SearchbarFiltersResult,
      // SearchbarCodersRemuneration,
      // SearchbarCodersRemunerationWanted,
      // SearchbarCodersSalaryType,
    },
    mixins: [SearchbarCoderDispatcher],
    props: {
      resultsCount: {
        type: Number,
      },
      listId: {
        type: String,
      },
    },
    data() {
      return {
        identifier: +new Date(),
        mapComplexQuery: {
          search: {
            queryBuilder: splitQuery,
            tagBuilder: buildSearchTag,
          },
          locations: {
            queryBuilder: mapLocationQuery,
            tagBuilder: buildLocationsTag,
          },
          status: {
            queryBuilder: splitQuery,
            tagBuilder: buildCoderStatusTag,
          },
          experiences: {
            queryBuilder: splitQuery,
            tagBuilder: buildExperienceTag,
          },
          roles: {
            queryBuilder: splitQuery,
            tagBuilder: buildRoleTag,
          },
          domains: {
            queryBuilder: splitQuery,
            tagBuilder: buildDomainTag,
          },
          remotes: {
            queryBuilder: splitQuery,
            tagBuilder: buildRemoteTag,
          },
          lastCoachActivity: {},
        },
        mapSimpleQuery: {
          contracts: {
            tagBuilder: buildContractTag,
          },
          phone: {
            tagBuilder: buildPhoneTag,
          },
          cv: {
            tagBuilder: buildCVTag,
          },
          email: {
            tagBuilder: buildEmailTag,
          },
          linkedin: {
            tagBuilder: buildLinkedinTag,
          },
          rating: {
            tagBuilder: buildRatingTag,
          },
        },
        salaryType: undefined,
      };
    },
    computed: {
      ...mapState({
        isActiveFilter: state => state.searchbar.isActiveFilter,
        technos: state => state.tags.technos,
        domains: state => state.domains.domains,
        isAppReady: state => !state.app.isLoading,
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags,
      }),
      ...mapGetters({
        searchQueries: 'searchbarFiltersComputed',
        customFieldsSelected: 'customFieldsSelected',
      }),
      routeQueries() {
        return {
          ...this.searchQueries,
        };
      },
      filtersQuery() {
        return [
          'professions',
          'professionsNames',
          'technos',
          'technosNames',
          'locations',
          'contracts',
          'phone',
          'email',
          'cv',
          'linkedin',
          'last-coach-activity-from',
          'last-coach-activity-to',
          'rating',
          'currentSalaryMin',
          'currentSalaryMax',
          'salaryWantedMin',
          'salaryWantedMax',
          'search',
          'uniqueids',
          'distance',
          'companies',
        ]
      },
      hasFilters() {
        return Object.keys(this.$route.query)
        .filter(key => this.$route.query[key])
        .some(key => this.filtersQuery.includes(key) || key.startsWith('cf-'));
      },
    },
    watch: {
      isAppReady: {
        handler(to) {
          if (to) {
            this.setupTags();
          }
        },
        immediate: true,
      },
      hasFilters: {
        handler(value) {
          this.$emit('has-filter', value);
        },
        immediate: true,
      }
    },
    methods: {
      buildProfessionsTag,
      resetSearchbar() {
        this.identifier = +new Date();
        this.updateRouteQueries();
      },
      setupTags() {
        const mapQueries = Object.keys(this.$route.query);
        const filteredMapQueries = mapQueries.filter(key => this.$route.query[key]);

        if (filteredMapQueries.includes('salary-min') || filteredMapQueries.includes('salary-max')) {
          const unit = this.$route.query.contracts === 'cdi' ? 'K€' : '€/jour';
          const range = [this.$route.query['salary-min'], this.$route.query['salary-max']];
          const tag = buildSalaryTag({ unit, range });

          this.addTag(tag);
        }

        if (filteredMapQueries.includes('technos')) {
          const technosIds = splitQuery(this.$route.query.technos);
          const technos = technosIds.map(id => this.technos.find(techno => techno._id === id)).filter(Boolean);

          technos.forEach(techno => {
            this.addTag(buildTechnosTag(techno));
          });
        }

        if (filteredMapQueries.includes('professions')) {
          const professionsIds = splitQuery(this.$route.query.professions);
          const professionsNames = splitQuery(this.$route.query.professionsNames);

          professionsIds.forEach((professionId, i) => {
            this.addTag(buildProfessionsTag({
              _id: professionId,
              name: professionsNames[i],
            }));
          });
        }

        if (filteredMapQueries.includes('last-coach-activity-from') || filteredMapQueries.includes('last-coach-activity-to')) {
          const from = this.$route.query['last-coach-activity-from'] ? moment(this.$route.query['last-coach-activity-from'], 'D-MM-YYYY') : undefined;
          const to = this.$route.query['last-coach-activity-to'] ? moment(this.$route.query['last-coach-activity-to'], 'D-MM-YYYY') : undefined;
          const lastActivityTag = buildLastCoachActivityTag({ from, to });

          this.addTag(lastActivityTag);
        }

        filteredMapQueries.forEach(value => {
          if (this.mapComplexQuery[value]) {
            const queries = this.mapComplexQuery[value].queryBuilder(this.$route.query[value]);

            queries.forEach(q => {
              this.addTag(this.mapComplexQuery[value].tagBuilder(q));
            });
          }

          if (this.mapSimpleQuery[value]) {
            this.addTag(this.mapSimpleQuery[value].tagBuilder(this.$route.query[value]));
          }
        });
      },
      updateRouteQueries() {
        const checkedQuery = this.checkCustomFieldsQuery(query, this.routeQueries);
        const query = {
          ...checkedQuery,
          ...this.routeQueries,
          page: 1,
        };

        this.$router.push({
          name: this.$route.name,
          query,
        }).catch(() => {
        });
      },
      closeSearchbar() {
        this.$store.dispatch('resetAndCloseSearchBar');
      },
      onSelectCustomFields(customField) {
        const hasKey = this.customFieldsSelected.find(filter => filter.key === customField.key);
        if (customField.replace && hasKey) {
          return this.replaceCustomFieldTag(buildCustomFieldTag(customField));
        }
        this.addTag(buildCustomFieldTag(customField));
      },
      onUnselectCustomFields(customField) {
        let index = this.customFieldsSelected.findIndex(filter => filter.name === customField.value);
        if (Array.isArray(customField.data)) {
          const finded = this.customFieldsSelected.find(filter => !customField.data.includes(filter.name));
          if (finded) {
            index = this.customFieldsSelected.findIndex(filter => filter.name === finded.name);
          }
        }
        this.removeTag(index);
      },
      onSelectSkills(skill) {
        this.addTag(buildTechnosTag({
          ...skill,
          icon: 'tool',
          type: 'techno',
        }));
      },
      checkCustomFieldsQuery(query, routeQueries) {
        const q = {...query};
        const hasCfInRouteQueries = Object.keys(routeQueries).some(routeQuery => routeQuery.startsWith('cf-'));
        Object.keys(q).forEach(key => {
          if (!hasCfInRouteQueries && key.startsWith('cf-')) {
            q[key] = undefined;
          }
        });
        return q;
      },
      onSelectSalaryType(salaryType) {
        if (salaryType.apiCode === 0) {
          this.salaryType = undefined;
        } else {
          this.salaryType = salaryType;
        }
      },
      handleReset() {
        const query = { ...this.$route.query, page: 1 };
        for (const key in query) {
          if (this.filtersQuery.includes(key) || key.startsWith('cf-') || key === 'salaryType') {
            Reflect.deleteProperty(query, key);
          }
          if (key === 'companies') {
            Reflect.deleteProperty(query, 'companiesNames');
          }
        }
        this.$router.push({
          query,
        })
      },
    },
    beforeDestroy() {
      this.closeSearchbar();
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-coders {
    flex-direction: column;
    z-index: 3;
    @include bp('tablet') {
      position: relative;
    }

    &__icon {
      position: absolute !important;
      top: 15px;
      right: 15px;
    }

    &--open {
      display: block;
      position: fixed;
      background: $color-white;
      overflow: auto;
      top: 0;
      left: 0;
      height: 100vh;
      min-height: 100vh;
      width: 100%;
      padding: 85px 15px 50px;
      @include bp('tablet') {
        display: flex;
        position: initial;
        overflow: initial;
        height: initial;
        min-height: initial;
        padding: initial;
        background: initial;
      }
    }

    &__autocomplete {
      // margin-bottom: 20px;
    }

    &__wrapper {
      // margin-bottom: -10px;
      @include bp('tablet') {
        padding: 0;
      }
    }

    &__input {
      width: 100%;
    }

    &__filters {
      margin: 10px -15px 50px;
      @include bp('tablet') {
        margin: 0;
        border-radius: 4px;
      }
    }
  }
</style>
