<template>
  <div class="views-topbar-create-processes">
    <views-topbar-create-trigger
      class="views-topbar-create-processes__trigger"
      icon="process-plus"
      :text="$t('generics.process-cap')"
      @click.native="createProcess">
    </views-topbar-create-trigger>
  </div>
</template>

<script>
import ViewsTopbarCreateTrigger from '@/components/Views/ViewsTopbar/ViewsTopbarCreate/ViewsTopbarCreateTrigger';

export default {
  name: 'views-topbar-create-processes',
  components: { ViewsTopbarCreateTrigger },
  methods: {
    createProcess() {
      this.$router.push({
        ...this.$route,
        name: `${this.$route.name}Panel`,
        params: {
          id: 'panel',
        },
        query: {
          ...this.$route.query,
          type: 'create-process',
        },
      });
    },
  },
};
</script>

<style
  lang="scss"
  scoped>
.views-topbar-create-processes {
  max-width: 100%;
  padding-left: 20px;
  padding-top: 8px;

  &__link {
    color: $color-secondary;
    padding: 5px;
    cursor: pointer;

    &--error {
      color: $color-error;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__trigger {
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }
  }
}
</style>
