<template>
  <panel-header class="panel-view-header">
    <div class="panel-view-header__nav">
      <bc-button
        @click.native="closePanel"
        bc-background-color="transparent"
        bc-icon="cross"
        bc-icon-size="16px"
        bc-padding="0"
        bc-text-color="gray-silver-chalice"
        bc-text-color-hover="black"
        class="panel-view-header__button">
      </bc-button>
      <bc-button
        @click.native="goBack"
        bc-background-color="transparent"
        bc-icon="arrow-left"
        bc-icon-size="16px"
        bc-padding="0"
        bc-text-color="gray-silver-chalice"
        bc-text-color-hover="black"
        class="panel-view-header__button">
      </bc-button>
    </div>
    <p class="panel-view-header__text">
      {{ categoryTitle }}
    </p>
  </panel-header>
</template>

<script>
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import PanelHeader from '@/components/Panel/PanelHeader';
  import BcButton from '@/legacy/ui-kit/components/Button/BcButton';

  /**
   * The Panel header when you click on my profile
   */
  export default {
    name: 'panel-view-header',
    components: {
      BcButton,
      PanelHeader
    },
    /**
     * We import the closePanel function to give it to the button cross
     */
    mixins: [PanelNavigation],
    computed: {
      categoryTitle() {
        return this.$route.query.category;
      }
    },
    methods: {
      /**
       * The method to go on the previous Panel
       */
      goBack() {
        this.goPrevious();
        this.closeCategory();
      },
      /**
       * Close the Panel when we go back;
       */
      closeCategory() {
        this.$store.dispatch('setActiveCategory', false);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .panel-view-header {
    min-height: 50px;
    z-index: 10;
    position: fixed;
    &__nav {
      align-items: center;
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &__button {
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    &__text {
      font-size: $font-size-m;
      color: $color-gray-silver-chalice;
      text-transform: uppercase;
      margin: auto;
    }
  }
</style>
