<template>
  <div class="panel-view-stack-job col-xs-12" v-if="technosPrimary.length > 0 || technosSecondary.length > 0">
    <title-section
      class="panel-view-stack-job__title"
      color="blue"
      position="left"
      :title="$t('companies.account-management.issues-stack-skills')">
    </title-section>
    <div class="panel-view-stack-job__row">
      <div class="panel-view-stack-job__stars">
        <technos-star class="panel-view-stack-job__icon"></technos-star>
        <technos-star class="panel-view-stack-job__icon"></technos-star>
      </div>
      <div class="panel-view-stack-job__tags">
        <placeholder-lines
          :height="15"
          :max-elements="3"
          :max-width="70"
          :primary-color="'#2c4278'"
          :rows-number="1"
          :secondary-color="'#3e5eab'"
          class="panel-view-stack-job__placeholder"
          v-if="isLoading">
        </placeholder-lines>
        <technos-tag
          :key="index"
          :text="techno.name"
          class="panel-view-stack-job__tag"
          v-else
          v-for="(techno, index) in technosPrimary">
        </technos-tag>
      </div>
    </div>
    <div class="panel-view-stack-job__row">
      <div class="panel-view-stack-job__stars">
        <technos-star class="panel-view-stack-job__icon"></technos-star>
        <technos-star
          :color="'transparent'"
          class="panel-view-stack-job__icon">
        </technos-star>
      </div>
      <div class="panel-view-stack-job__tags">
        <placeholder-lines
          :height="15"
          :max-elements="3"
          :max-width="70"
          :primary-color="'#2c4278'"
          :rows-number="1"
          :secondary-color="'#3e5eab'"
          class="panel-view-stack-job__placeholder"
          v-if="isLoading">
        </placeholder-lines>
        <technos-tag
          :key="index"
          :text="techno.name"
          class="panel-view-stack-job__tag"
          v-else
          v-for="(techno, index) in technosSecondary">
        </technos-tag>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleSection from '@/components/Title/TitleSection';
  import TechnosStar from '@/components/UIKit/Technos/TechnosStar';
  import TechnosTag from '@/components/UIKit/Tag/Tag';
  import PlaceholderLines from '@/components/UIKit/Placeholder/PlaceholderLines';

  export default {
    name: 'panel-view-stack-job',
    props: {
      /**
       * This is the array which will be associate with two stars
       */
      technosPrimary: [Array, Object],
      /**
       * This is the array which will be associate with one star
       */
      technosSecondary: Array,
      /**
       * Define if the content-loading should appear or not
       */
      isLoading: {
        type: Boolean,
        default: true,
      },
    },
    components: {
      PlaceholderLines,
      TitleSection,
      TechnosStar,
      'technos-tag': TechnosTag,
    },
  };
</script>

<style lang="scss" scoped>
  .panel-view-stack-job {
    flex-shrink: 0;

    &__icon {
      margin-right: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &__row {
      margin-bottom: 15px;
      align-items: center;
      flex-direction: column;
      @include bp('tablet') {
        flex-direction: row;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__tags {
      flex-wrap: wrap;
      justify-content: center;
      @include bp('tablet') {
        justify-content: flex-start;
      }
    }

    &__title {
      margin-bottom: 30px;
    }

    &__stars {
      margin-bottom: 10px;
      @include bp('tablet') {
        margin-right: 45px;
        margin-bottom: 0;
      }
    }

    &__tag {
      margin: 5px;
    }

    &__placeholder {
      min-height: 30px;
    }
  }
</style>
