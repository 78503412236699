<template>
  <views-tabs :tabs="tabs">
    <template #content>
      <slot></slot>
    </template>
  </views-tabs>
</template>

<script>
import ViewsTabs from '@/components/Views/ViewsTabs';

export default {
  name: 'admin-tabs',
  components: {
    ViewsTabs,
  },
  props: {
    organizationsCount: {
      type: Number,
    },
    industriesCount: {
      type: Number,
    },
    tagsCount: {
      type: Number,
    },
  },
  computed: {
    tabs() {
      return [
        {
          key: 'organizations',
          label: this.$i18n.t('topbar.base-of-organizations'),
          to: '/organizations',
          count: this.organizationsCount,
        },
        {
          key: 'industries',
          label: this.$i18n.t('topbar.base-of-industries'),
          to: '/industries',
          count: this.industriesCount,
        },
        {
          key: 'integrations',
          label: 'Integrations',
          to: '/integrations',
        },
        {
          key: 'tags',
          label: this.$i18n.t('topbar.base-of-tags'),
          to: '/tags',
          count: this.tagsCount,
        },
        {
          key: 'logs-activities',
          label: this.$i18n.t('topbar.logs-activities'),
          to: '/logs-activities',
        },
        {
          key: 'extension',
          label: 'Extension',
          to: '/extension',
        },
        {
          key: 'admin-emails',
          label: 'Emails',
          to: '/admin-emails',
        },
        {
          key: 'migrations',
          label: 'Migrations',
          to: '/migrations',
        },
      ];
    },
  },
}
</script>
