<template>
  <div class="panel-header-nav flex items-center gap-[5px]">
    <ButtonClose @click="goClose"/>
    <ButtonBack
      v-if="isBackVisible"
      @click="goBack"/>
  </div>
</template>

<script lang="ts" setup>
  import { usePanelNavigation } from '@/composables/usePanelNavigation';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { useStore } from '@/store';
  import { onUnmounted, watch } from 'vue';
  import ButtonBack from '@/components/Button/ButtonBack.vue';
  import ButtonClose from '@/components/Button/ButtonClose.vue';

  const route = useRoute();
  const router = useRouter();
  const store = useStore();
  const { closePanel } = usePanelNavigation();

  const props = withDefaults(defineProps<{
    onClose?: () => void;
    onBack?: () => void;
    isBackVisible?: boolean;
  }>(), {
    isBackVisible: true,
  });

  function goClose() {
    if (props.onClose) {
      props.onClose();
    } else {
      closePanel();
    }
  }

  function goBack() {
    if (props.onBack) {
      props.onBack();
    } else {
      router.go(-1);
      store.dispatch('setActiveCategory', false);
    }
  }

  watch(
    () => route,
    () => {
      store.dispatch('setPersistedRoute', { path, params, query, name });
    },
  );

  onUnmounted(() => {
    store.dispatch('setPersistedRoute', {});
  });
</script>
