<template>
  <div class="organizations-modal-edit-industries is-column">
    <div class="is-align-items-center is-full-width">
      <bc-autocomplete
        v-model="input"
        :keep-first="true"
        :open-on-focus="true"
        :options="matches"
        class="organizations-modal-edit-industries__autocomplete is-full-width"
        icon="tool"
        label="Industries"
        name="industries-actual"
        placeholder="Ajouter une industrie"
        @select="handleSelect"
        @keydown.native.delete="removeLastIndustry">
        <template #empty>
          <bc-dropdown-item
            class="organizations-modal-edit-industries__option font-size-s is-expanded"
            text="Aucune industrie n'a été trouvée">
          </bc-dropdown-item>
        </template>
        <template #option="{ option }">
          <bc-dropdown-item
            :text="option.name"
            class="organizations-modal-edit-industries__option font-size-s is-expanded">
          </bc-dropdown-item>
        </template>
      </bc-autocomplete>
    </div>
    <div class="organizations-modal-edit-industries__wrapper is-flex-wrap">
      <bc-tag
        v-for="(industry, index) in industries"
        :key="index"
        class="organizations-modal-edit-industries__tag"
        deletable
        @click="removeIndustry(industry)">
        {{ industry.name }}
      </bc-tag>
    </div>
  </div>
</template>

<script>
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcTag from '@/ui-kit/components/BcTag/BcTag';

  export default {
    name: 'organizations-modal-edit-industries',
    components: { BcTag, BcDropdownItem, BcAutocomplete },
    props: {
      industriesList: {
        type: Array,
        default: () => [],
      },
      industries: {
        type: Array,
        default: () => [],
      }
    },
    data() {
      return {
        input: '',
      };
    },
    computed: {
      industriesAlreadySelected() {
        return this.industriesList.filter(a1 => this.industries.map(a2 => a2._id).indexOf(a1._id) < 0);
      },
      matches() {
        if (!this.input) {
          return this.industriesAlreadySelected;
        }

        return this.industriesAlreadySelected.filter(hobby => hobby.name.toLowerCase().indexOf(this.input.toLowerCase()) === 0);
      },
    },
    methods: {
      handleSelect(industry) {
        this.addIndustry(industry);

        this.input = '';
      },
      addIndustry(industry) {
        this.$emit('update-value', {
          _industries: [...this.industries, industry],
        });
      },
      removeIndustry(industry) {
        this.$emit('update-value', {
          _industries: this.industries.filter(l => l._id !== industry._id),
        });
      },
      removeLastIndustry() {
        if (this.input !== '' || this.industries.length === 0) {
          return;
        }

        const filteredIndustrys = this.industries;
        filteredIndustrys.pop();

        this.$emit('update-value', {
          industries: filteredIndustrys,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .organizations-modal-edit-industries {
    &__autocomplete {
      margin-bottom: 10px;
    }

    &__option {
      line-height: 15px;
      padding: 10px 20px;
    }

    &__wrapper {
      margin: -5px;
    }

    &__tag {
      margin: 5px;
    }
  }
</style>
