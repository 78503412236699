<script setup lang="ts">
import { defineProps, withDefaults, defineEmits, computed } from 'vue';
import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
import MvIcon from '@/ui-kit/components/MvIcon/MvIcon.vue';

interface Props {
  title: string;
  iconName?: string;
  canDelete?: boolean;
  canDuplicate?: boolean;
  disabled?: boolean;
  isDefaultTemplate?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  canDelete: false,
  canDuplicate: false,
  disabled: false,
  isDefaultTemplate: false,
});

const emits = defineEmits<{
  (e: 'on-close'): void,
  (e: 'on-duplicate'): void,
  (e: 'on-delete'): void,
}>();

const hasActions = computed(() => {
  return props.canDelete || props.canDuplicate;
});

const onClose = () => emits('on-close');
</script>

<template>
  <div class="modal-linkedin-header">
    <button @click="onClose">
      <span class="icon-cross"></span>
    </button>
    <p  class="mr-2">{{ title }}</p><MvIcon v-if="iconName" :name="iconName" />
    <div v-if="hasActions" class="modal-linkedin-header__actions">
      <BcButton
        v-if="canDuplicate"
        icon-center="file-copy"
        size="small"
        type="outlined"
        :disabled="disabled"
        @click="$emit('on-duplicate')"
      />
      <BcButton
        v-if="canDelete"
        :class="{ 'opacity-50': isDefaultTemplate }"
        icon-center="trash"
        size="small"
        color="error"
        :disabled="!isDefaultTemplate && disabled"
        @click="!isDefaultTemplate ? $emit('on-delete') : undefined"
        v-tooltip="{ content: isDefaultTemplate ? $t('email.default-template-warning') : '' }"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-linkedin-header {
  min-height: 50px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $color-blue-light;

  & > button {
    position: absolute;
    left: 18px;
    align-items: center;
    color: $color-blue-heavy-dark;
  }
  & > p {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: $color-blue-dark-cello;
  }

  &__actions {
    position: absolute;
    right: 20px;
    gap: 10px;
  }
}
</style>
