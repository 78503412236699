<template>
  <views-tabs :tabs="tabs">
    <template #content>
      <slot></slot>
    </template>
  </views-tabs>
</template>

<script>
import { mapGetters } from 'vuex';
import ViewsTabs from '@/components/Views/ViewsTabs';

export default {
  name: 'opportunities-tabs',
  components: {
    ViewsTabs,
  },
  computed: {
    ...mapGetters({
      coachRessourceCount: 'metadata/coachRessourceCount',
      ressourceCount: 'metadata/ressourceCount',
    }),
    profile() {
      return this.$store.state.user.profile;
    },
    tabs() {
      return [
        {
          key: 'opportunities',
          label: this.$i18n.t('global.opportunities'),
          to: { path: '/pipe/hunt', query: { 'selected-pipe': 'personal-pipe', 'job-active': 'true', coachId: this.profile._id, ...this.$route.query } },
          count: this.coachRessourceCount.customJob,
          icon: 'briefcase',
        },
        {
          key: 'applications',
          label: this.$i18n.t('generics.applications'),
          to: { path: '/pipe/applications' },
          count: this.ressourceCount.applications,
          icon: 'file-cv',
        },
        {
          key: 'process',
          label: this.$i18n.t('process.my-processes'),
          to: { path: '/pipe/processes', query: {
            'selected-pipe': 'personal-pipe',
            'processes-selected-coaches': this.profile._id,
            ...this.$route.query,
            'selected-view': 'candidates',
          }},
          icon: 'kanban',
        },
      ];
    },
  },
}
</script>
