<script lang="ts" setup>
  import { defineEmits, defineProps, ref } from 'vue';
  import PanelDocumentsSimpleView from '@/components/Panel/PanelDocumentsSimpleView.vue';
  import { Document } from '@/domains/models/Document';
  import PanelCompanyContractsModalDelete
    from '@/components/Panel/PanelCompany/PanelCompanyContracts/PanelCompanyContractsModalDelete.vue';
  import BcModal from '@/ui-kit/components/BcModal/BcModal.vue';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { useI18n } from '@/i18n/i18n';
  import contractsController from '@/managers/contracts/controller';

  interface Props {
    documents: Document[];
  }

  const props = defineProps<Props>();
  const emits = defineEmits<{ (e: 'update-company', value: Document[]): void }>();
  const toast = useToast();
  const i18n = useI18n();

  // remove document
  const isModalOpen = ref<boolean>(false);
  const selectedDocument = ref<Document | null>(null);

  const openDeleteModal = (document: Document) => {
    selectedDocument.value = document;
    isModalOpen.value = true;
  };

  const removeDocument = async() => {
    try {
      const filteredDocuments = props.documents.filter(doc => selectedDocument.value._id !== doc._id);
      await contractsController.removeCompanyContract(selectedDocument.value._id);

      emits('update-company', filteredDocuments);

      isModalOpen.value = false;

      selectedDocument.value = null;
    } catch (error) {
      console.log(error);
      toast.show({
        message: i18n.t('toast.error-occured'),
        type: 'error',
        icon: 'cross',
      });
    }
  };
</script>

<template>
  <div class="w-full">
    <bc-modal :active.sync="isModalOpen">
      <panel-company-contracts-modal-delete
        @cancel-delete="isModalOpen = false"
        @confirm-delete="removeDocument">
      </panel-company-contracts-modal-delete>
    </bc-modal>
    <PanelDocumentsSimpleView
      :documents="documents"
      class="w-full"
      @open-delete-modal="openDeleteModal"
      @update-company="$emit('update-company', $event)"
    />
  </div>
</template>

<style scoped></style>
