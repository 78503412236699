<template>
  <mv-filter
    :label="$t('generics.status')"
    icon="tag"
    :count="selectedStatus && 1"
  >
    <filter-content
      class="filter-companies-status__content"
      :label="$t('generics.status')"
      icon="tag"
    >
      <mv-select
        :value="selectedStatus"
        :items="items"
        :selected-label="selectedStatusText"
        :color="(prospectionStatusCategoryColor[prospectingStatusCategory] || {}).color"
        :backgroundColor="(prospectionStatusCategoryColor[prospectingStatusCategory] || {}).backgroundColor"
        @select="handleSelect"
      ></mv-select>
    </filter-content>
  </mv-filter>
</template>

<script>

import MvFilter from '@/ui-kit/components/MvFilter/MvFilter';
import MvSelect from '@/ui-kit/components/MvSelect/MvSelect';
import FilterContent from '@/components/Filter/FilterContent';
import { mapGetters } from 'vuex';
import { prospectionStatusCategoryColor } from '@/macros/companies/prospectionStatus';

export default {
  name: 'filter-companies-status',
  components: {
    MvFilter,
    MvSelect,
    FilterContent,
  },
  data() {
    return {
      prospectionStatusCategoryColor
    }
  },
  computed: {
    ...mapGetters('user', ['configurationProspection']),
    selectedStatus() {
      return this.$route.query.status;
    },
    selectedStatusText() {
      if (!this.selectedStatus) {
        return this.items[0].label;
      }
      return this.items.find(item => item.value === this.selectedStatus)?.label;
    },
    items() {
      return [{ label: this.$i18n.t('companies.steps.every'), value: '' }]
        .concat(this.configurationProspection?.categories?.map(category => category?.steps
        ?.map(step => ({
          label: step.name,
          value: step.key
        })))
        ?.flat() || [])
    },
    prospectingStatusCategory() {
      return this.configurationProspection?.categories?.find(category => {
        return !!category?.steps?.find(step => step.key === this.selectedStatus)
      })?.key;
    },
  },
  methods: {
    handleSelect(status) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          status: status ? status : undefined,
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-companies-status {
  &__content {
    min-width: 218px;
  }
}
</style>
