<script lang="ts" setup>
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
  import { computed } from 'vue';
  import { useI18n } from '@/i18n/i18n';

  const i18n = useI18n();

  defineProps<{
    label: string
  }>();

  interface SortOption {
    name: string;
    icon: string;
    value: string;
  }

  defineEmits<{
    (e: 'sort', value: SortOption): void
  }>();


  const sortOptions = computed<SortOption[]>(() => {
    return [
      {
        name: i18n.t('generics.default'),
        icon: 'refresh',
        value: 'default'
      },
      {
        name: i18n.t('generics.sort-ascending'),
        icon: 'sort-ascending',
        value: 'asc'
      },
      {
        name: i18n.t('generics.sort-descending'),
        icon: 'sort-descending',
        value: 'desc'
      },
    ];
  });

</script>

<template>
  <bc-dropdown
    :close-dropdown-at-select="true"
    :options="sortOptions"
    style="color: inherit;"
  >
    <template #label="{ isDropdownOpen }">
      <div class="cursor-pointer hover:text-blue-500">
        <p class="is-uppercase font-size-s">
          {{ label }}
        </p>
        <div class="flex flex-col ml-2 items-center justify-center">
          <i class="text-[7px] icon-chevron-up"/>
          <i class="text-[7px] icon-chevron-down"/>
        </div>
      </div>
    </template>
    <template #option="{ activeIndex, index, option }">
      <bc-dropdown-item
        :icon="option.icon"
        :is-focus="activeIndex === index"
        :text="`${option.name}`"
        class="w-full min-w-[165px]"
        @click.native="$emit('sort', option)"
      >
      </bc-dropdown-item>
    </template>
  </bc-dropdown>
</template>

<style lang="scss" scoped>

</style>
