<template>
  <div class="panel-process-create-company">
    <h2 class="panel-process-create-company__title">
      <span class="panel-process-create-company__icon icon-partners"></span>
      {{ $tc('generics.company', 1) }} <span class="ml-1 text-red-500">*</span>
    </h2>
    <div v-if="company._id" style="width: 100%;">
      <panel-process-create-selected :show-close-btn="!fromCompany" @on-close="$emit('cancel-hunt')">
        <bc-avatar
          size="s"
          :alt="company.name"
          :src="company.logoUrl"
          @error="setAlternativeImg"
          class="panel-process-create-company__logo"
        >
        </bc-avatar>
        {{ company.name }}
      </panel-process-create-selected>
    </div>
    <bc-autocomplete
      v-else
      ref="companyDropdown"
      placeholder="Écrivez le nom de l’entreprise"
      v-model="autocomplete"
      :is-scrollable="false"
      :options="options"
      class="panel-process-create-company__dropdown is-full-width"
      keep-first
      name="autocomplete"
      @input="getSuggestions"
      @select="
        event => {
          emptySuggestions();
          setSelectedCompany(event);
        }
      "
      @click.stop.native
    >
      <template #option="{ option }">
        <bc-dropdown-item :text="option.name"></bc-dropdown-item>
      </template>
      <template #footer>
        <button
          v-if="!isCompanyAlreadyExisting && autocomplete.length >= 2"
          class="panel-process-create-company__item is-full-width pt-3 pb-3 font-size-m is-secondary is-justify-content-center mr-auto ml-auto"
          @click="createCompanyAndSetSelectedCompany"
        >
          <span class="icon-companies-plus mr-2"></span>
          {{ $t('process-create.suggestion') }} "{{ autocomplete }}"
        </button>
      </template>
    </bc-autocomplete>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'debounce';
import { searchPartners } from '@/api/confidentiality';
import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import company from '@/api/company';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
import PanelProcessCreateSelected from './PanelProcessCreateSelected';

export default {
  name: 'panel-process-create-company',
  components: {
    BcAvatar,
    BcDropdownItem,
    BcAutocomplete,
    PanelProcessCreateSelected
  },
  props: {
    selectedCompany: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    const selectedCompany = sessionStorage.getItem(`selected-company-${this.profileId}`);
    if (selectedCompany) {
      const parsedCompany = JSON.parse(selectedCompany)
      this.company = parsedCompany;
      this.$emit('company-selected', parsedCompany);
    } else {
      this.company = this.selectedCompany;
    }
    this.setFocusOnDropdown();
  },
  data() {
    return {
      company: {},
      isLoading: false,
      isNoResults: false,
      options: [],
      autocomplete: ''
    };
  },
  computed: {
    ...mapGetters({
      profileId: 'user/profileId',
    }),
    isCompanyAlreadyExisting() {
      return this.options.some(
        option => option.name?.toLowerCase() === this.autocomplete?.toLowerCase()
      );
    },
    fromCompany() {
      return this.$route.query.from === 'companies' || this.$route.query.from === 'hunt'
    }
  },
  methods: {
    getSuggestions: debounce(async function(value) {
      if (value) {
        this.setIsLoading(true);
        try {
          const response = await searchPartners(value);

          this.options = response.data;

          this.isNoResults = response.data.length === 0;
        } catch (error) {
          this.isNoResults = true;

          this.options = [];
        }
        this.setIsLoading(false);
      }
    }, 500),
    setIsLoading(value) {
      this.isLoading = value;
    },
    checkValue(value) {
      if (value === '') {
        this.options = [];
      }
    },
    emptySuggestions() {
      this.autocomplete = '';

      this.options = [];
    },
    setFocusOnDropdown() {
      this.$refs?.companyDropdown?.$refs?.input?.$refs?.input?.focus();
    },
    setSelectedCompany(company) {
      sessionStorage.setItem(`selected-company-${this.profileId}`, JSON.stringify(company));
      this.$emit('company-selected', company);
    },
    async createCompanyAndSetSelectedCompany() {
      try {
        const { data } = await company.createPartner({
          name: this.autocomplete
        });

        this.setSelectedCompany(data);

        this.$toast.show({
          type: 'success',
          title: this.$i18n.t('toast.congratulations'),
          message: this.$i18n.t('toast.company-successfully-added'),
        });
      } catch (error) {
        this.$toast.show({
          type: 'error',
          message: error?.response?.data?.message || error,
        });
      }
    },
    setAlternativeImg(event) {
      event.target.src = require('@/assets/img/default-avatar-company.svg');
    },
  },
  watch: {
    selectedCompany(value) {
      this.company = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-process-create-company {
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 15px 0;

  &__title {
    display: flex;
    color: #344f74;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    font-size: $font-size-s;
    line-height: 17px;
    align-items: center;
    margin-bottom: 15px;
  }

  &__item {
    &:hover {
      background: $color-primary;
      color: $color-white;
    }
  }

  &__icon {
    display: inline-flex;
    font-size: $font-size-l;
    align-items: center;
    margin-right: 10px;
  }

  &__wrapper {
    align-items: center;
  }

  &__logo {
    overflow: hidden;
    margin-right: 10px;
  }

  &__name {
    font-size: $font-size-s;
    line-height: 18px;
    color: #344f74;
  }
}
</style>
