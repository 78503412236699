<template>
  <div
    class="process-column">
    <div
      :style="{ ...step.style && step.style.header }"
      class="process-column__header">
      <p class="process-column__title">
        {{ step.name }}
      </p>
      <p class="process-column__count">
        {{ step.processes.length }}
      </p>
    </div>
    <div
      :class="{ 'process-column__body--hover': isHover && isDragging && !readOnly }"
      class="process-column__body">
      <slot></slot>
      <draggable
        v-model="currentProcesses"
        :class="{ 'process-column__step--disabled': readOnly }"
        :disabled="readOnly"
        animation="200"
        class="process-column__step"
        :ghost-class="'process-card--ghost'"
        group="process"
        handle=".is-draggable"
        @end="$emit('dragging', false)"
        @start="handleDraggingStart"
        @dragenter.native="setIsHover"
        @dragleave.native="unsetIsHover"
        @dragover.native="setIsHover"
        @mouseenter.native="setIsHover"
        @mouseleave.native="unsetIsHover">
        <router-link
          v-for="process in currentProcesses"
          :key="process._id"
          :class="{'process-column__link--forbidden': !process.processedAt}"
          :to="panelRoute(process)"
          class="process-column__link">
          <component
            :is="cardType"
            :process="process"
            :step="step"
            class="process-column__card list-group-item"
            @process-update="$emit('process-update', $event)"
            @profile-updated="$emit('profile-updated', $event)"
            @set-modal-type="$emit('set-modal-type', $event)">
          </component>
        </router-link>
        <div
          v-if="oldProcesses.length > 0"
          class="process-column__step process-column__step--old"
          @dragenter="setIsHover"
          @dragleave="unsetIsHover"
          @dragover="setIsHover"
          @mouseenter="setIsHover"
          @mouseleave="unsetIsHover">
          <draggable
            v-model="oldProcesses"
            :class="{ 'process-column__step--disabled': readOnly }"
            :disabled="readOnly"
            animation="200"
            class="process-column__step"
            ghost-class="process-card--ghost"
            group="process"
            handle=".is-draggable"
            @end="$emit('dragging', false)"
            @start="$emit('dragging', true)"
            @dragenter.native="setIsHover"
            @dragleave.native="unsetIsHover"
            @dragover.native="setIsHover"
            @mouseenter.native="setIsHover"
            @mouseleave.native="unsetIsHover">
            <router-link
              v-for="oldProcess in oldProcesses"
              :key="oldProcess._id"
              :to="panelRoute(oldProcess)"
              class="process-column__link">
              <component
                :is="cardType"
                :key="oldProcess._id"
                :process="oldProcess"
                :step="step"
                class="process-column__card list-group-item"
                @process-update="$emit('process-update', $event)"
                @profile-updated="$emit('profile-updated', $event)"
                @set-modal-type="$emit('set-modal-type', $event)">
              </component>
            </router-link>
          </draggable>
        </div>
      </draggable>
      <div
        v-if="step.amount >= 0 && this.isSuperUser"
        class="process-column__amount">
        Total&nbsp;:<span class="process-column__amount-number">&nbsp;{{ formattedAmount(step.amount) }} €</span>
      </div>
    </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';
  import ModalProcessFormDeal from '@/components/Modal/ModalProcess/ModalProcessFormDeal';
  // Process cards
  import ProcessCardCoder from '@/components/Process/ProcessCard/ProcessCardCoder';
  import ProcessCardCompany from '@/components/Process/ProcessCard/ProcessCardCompany';
  import ProcessCardJob from '@/components/Process/ProcessCard/ProcessCardJob';
  import ProcessCardHunt from '@/components/Process/ProcessCard/ProcessCardHunt';
  import ProcessesCard from '@/components/Processes/ProcessesCard';

  import { modalTypes } from '@/macros/processes/process';
  import { getCurrentProcesses, getOldProcesses, sortByFailedAt } from '@/helpers/processes';

  export default {
    name: 'process-column',
    components: {
      ModalProcessFormDeal,
      draggable,
      'process-card-coders': ProcessCardCoder,
      'process-card-companies': ProcessCardCompany,
      'process-card-job': ProcessCardJob,
      'process-card-hunt': ProcessCardHunt,
      'processes-card': ProcessesCard,
    },
    props: {
      step: {
        type: Object,
        default: () => ({
          name: '',
          count: 0,
        }),
      },
      stepName: {
        type: String,
        default: '',
      },
      cardType: {
        type: String,
        required: true,
      },
      isDragging: {
        type: Boolean,
        default: false,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isHover: false,
        modalType: '',
      };
    },
    computed: {
      isSuperUser() {
        return this.$store.getters['user/isSuperUser']
      },
      currentProcesses: {
        get() {
          const currentProcesses = getCurrentProcesses(this.step.processes);

          return sortByFailedAt(currentProcesses);
        },
        set(newProcesses) {
          const newProcess = newProcesses.find(process => !this.currentProcesses.includes(process));

          if (newProcess) {
            this.manageProcess(newProcess);
          }

          this.$emit('input', {
            stepName: this.stepName,
            processes: [...newProcesses, ...this.oldProcesses],
          });
        },
      },
      oldProcesses: {
        get() {
          return getOldProcesses(this.step.processes);
        },
        set(newProcesses) {
          const newProcess = newProcesses.find(process => !this.oldProcesses.includes(process));

          if (newProcess) {
            this.manageProcess(newProcess);
          }

          this.$emit('input', {
            stepName: this.stepName,
            processes: [...this.currentProcesses, ...newProcesses],
          });
        },
      },
    },
    methods: {
      handleDraggingStart(event) {
        this.$emit('dragging', true);
        this.$emit('card-dragged', event);
      },
      manageProcess(newProcess) {
        if (modalTypes[this.stepName]) {
          this.$emit('set-modal-type', {
            process: newProcess,
            type: modalTypes[this.stepName].modalName,
          });

          if (!modalTypes[this.stepName].isBlocking) {
            this.$emit('move-process', { processId: newProcess._id, stepName: this.stepName });
          }
        } else {
          this.$emit('move-process', { processId: newProcess._id, stepName: this.stepName });
        }
      },
      panelRoute(process) {
        const processesPanel = {
          name: 'ProcessesPanel',
          params: {
            id: process._coder._id,
          },
          query: {
            ...this.$route.query,
            type: 'coders',
            category: 'process',
            context: 'processes',
          },
        };

        const processPanel = {
          ...this.$route,
          params: {
            id: process._id,
          },
          query: {
            ...this.$route.query,
            type: 'process',
            typing: true,
          },
        };

        if (this.$route.name === 'Processes') {
          return processesPanel;
        }

        return processPanel;
      },
      setIsHover() {
        this.isHover = true;
      },
      unsetIsHover() {
        this.isHover = false;
      },
      formattedAmount(amount) {
        const formated = amount * 1000
        return Number.isInteger(formated) ? formated : formated.toFixed(2)
      },
    },
  };
</script>

<style lang="scss" scoped>
  .process-column {
    flex-direction: column;

    &__header {
      padding: 15px;
      justify-content: space-between;
      text-transform: uppercase;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      background: $color-white;
      border-bottom: 1px solid rgba($color-blue-dark-cello, 0.2);
      z-index: 1;
      min-height: 47.5px;
    }

    &__body {
      flex-grow: 1;
      background: rgba($color-blue-dark-cello, 0.1);
      transition: 0.15s;
      flex-direction: column;
      padding: 5px 0 50px 0;
      overflow: auto;

      &--hover {
        background: rgba($color-blue-dark-cello, 0.2);
      }
    }

    &__step {
      flex-direction: column;
      width: 100%;
      flex-grow: 1;

      &--old {
        margin-top: 10px;
        flex-grow: 1;
        height: 100%;
        padding: 10px 0;
        border-top: 1px solid rgba($color-blue-dodger, 0.3);
      }

      &--disabled {
        cursor: initial;
        user-select: none;
      }
    }

    &__link {
      justify-content: center;
    }

    &__card {
      margin: 5px 10px;
    }

    &__amount {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 15px 20px;
      text-transform: uppercase;
      background: $color-white;
      color: $color-secondary;
      border-top: 1px solid rgba($color-secondary, 0.1);
      z-index: 2;

      &-number {
        color: $color-primary;
      }
    }
  }

  .process-card {
    &--ghost {
      display: none;
    }
  }
</style>
