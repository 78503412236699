// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getMessaging, getToken } from 'firebase/messaging';
import { getEnv } from '@/helpers/env';

export const isSupported = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window;


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC8ItmjZYUAmjBi464L_-YgHkep-sujXVU',
  authDomain: 'marvin-293012.firebaseapp.com',
  projectId: 'marvin-293012',
  storageBucket: 'marvin-293012.appspot.com',
  messagingSenderId: '163634611738',
  appId: '1:163634611738:web:6b940fe4a0c687694cf3a3',
  measurementId: 'G-RXDET3BXQ7',
};

export const vapidKey = 'BAr1Vr0BPlB2v3CMrz64CLI8a6QFm_tDvrLTlG9inLC6ZNdrheI8hjpxeNN0elVRUiDT3supejJF1dBJRmXRZhI';

// Initialize Firebase
const app = navigator.serviceWorker ? initializeApp(firebaseConfig) : null;
export const db = app ? getFirestore(app) : null;
export const messaging = app ? getMessaging(app) : null;

const ENV = getEnv();
const IS_PROD = ENV === 'PROD';

const collections = {
  notifications: `notifications-${ENV}`,
  resumeHistories: `resume-histories-${ENV}`,
  messageHistories: `message-histories-${ENV}`,
  parsingResumeHistories: `parsing-resume-histories-${ENV}`,
};

export async function getCoachNotifications({
  coachId,
  lastVisibleCandidatesList,
  lastVisibleWithoutCandidatesList,
  filter,
} = {
  coachId: '',
  lastVisibleCandidatesList: undefined,
  lastVisibleWithoutCandidatesList: undefined,
  filter: ['candidates', 'withoutCandidates'],
}) {
  if (!db || !coachId) return;

  const notificationsCol = collection(db, `${collections.notifications}/${coachId}/list`);

  let notificationsCandidates;
  let notificationsWithoutCandidates;
  let notificationsCandidatesList = [];
  let notificationsWithoutCandidatesList = [];

  if (filter.includes('candidates')) {
    if (lastVisibleCandidatesList) {
      notificationsCandidates = await getDocs(query(notificationsCol, where('type', '==', 'apply-from-career'), orderBy('createdAt', 'desc'), startAfter(lastVisibleCandidatesList), limit(IS_PROD ? 30 : 10)));
    } else {
      notificationsCandidates = await getDocs(query(notificationsCol, where('type', '==', 'apply-from-career'), orderBy('createdAt', 'desc'), limit(30)));
    }
    notificationsCandidatesList = notificationsCandidates.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }

  if (filter.includes('withoutCandidates')) {
    if (lastVisibleWithoutCandidatesList) {
      notificationsWithoutCandidates = await getDocs(query(notificationsCol, where('type', '!=', 'apply-from-career'), orderBy('type', 'asc'), orderBy('createdAt', 'desc'), startAfter(lastVisibleWithoutCandidatesList), limit(20)));
    } else {
      notificationsWithoutCandidates = await getDocs(query(notificationsCol, where('type', '!=', 'apply-from-career'), orderBy('type', 'asc'), orderBy('createdAt', 'desc'), limit(IS_PROD ? 20 : 10)));
    }
    notificationsWithoutCandidatesList = notificationsWithoutCandidates.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }

  return {
    lastVisibleCandidatesList: notificationsCandidates ? notificationsCandidates.docs[notificationsCandidates.docs.length-1] : undefined,
    lastVisibleWithoutCandidatesList: notificationsWithoutCandidates ? notificationsWithoutCandidates.docs[notificationsWithoutCandidates.docs.length-1] : undefined,
    notificationsCandidatesList,
    notificationsWithoutCandidatesList,
  };
}

export async function listenCoachNotification({ coachId }, onChange) {
  if (!db) return;

  const collectionRef = collection(db, `${collections.notifications}/${coachId}/list`);
  const queryRef = await query(collectionRef, orderBy('createdAt', 'desc'), limit(1));
  const observer = onSnapshot(queryRef, querySnapshot => {
    querySnapshot.docChanges().forEach(change => {
      onChange({ id: change.doc.id, type: change.type, data: change.doc.data() });
    });
  });
  return observer;
}

export async function markNotificationAsRead({ coachId, notificationId }) {
  if (!db) return;

  const docRef = doc(db, `${collections.notifications}/${coachId}/list/${notificationId}`);
  await updateDoc(docRef, {
    read: true,
  });
}

export async function setCoachNotificationsConfig({ coachId, config }) {
  if (!db) return;

  const docRef = doc(db, `${collections.notifications}/${coachId}`);
  await setDoc(docRef, {
    config: {
      pushNotificationToken: config.pushNotificationToken,
    },
  });
}

export function setCoachNotificationsToken({ coachId }) {
  if (!isSupported() || !messaging) return;
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      getToken(messaging, { vapidKey }).then(async(currentToken) => {
        if (currentToken) {
          await setCoachNotificationsConfig({
            coachId,
            config: {
              pushNotificationToken: currentToken,
            },
          });
        } else {
          console.log('Can not get token');
        }
      });
    } else {
      console.log('Do not have permission!');
    }
  });
}

// Resume Histories
export async function getResumeHistories({ coachId }) {
  if (!db || !coachId) return;
  
  const resumeHistoriesCol = collection(db, `${collections.resumeHistories}/${coachId}/list`);
  
  const resumeHistoriesSnapshot = await getDocs(query(resumeHistoriesCol, orderBy('startedAt', 'desc'), limit(10)));

  const resumeHistoriesList = resumeHistoriesSnapshot.docs.map(doc => doc.data());
  return resumeHistoriesList;
}

export async function listenResumeHistories({ coachId }, onChange) {
  if (!db) return;
  const resumeHistoriesCol = collection(db, `${collections.resumeHistories}/${coachId}/list`);
  const queryRef = await query(resumeHistoriesCol, orderBy('startedAt', 'desc'), limit(1))
  const observer = onSnapshot(queryRef, querySnapshot => {
    querySnapshot.docChanges().forEach(change => {
      onChange({ id: change.doc.id, type: change.type, data: change.doc.data() });
    });
  });
  return observer;
}

export async function setResumeClosedAt({ coachId, groupId }) {
  if (!db || !coachId || !groupId) return;

  const docRef = doc(db, `${collections.resumeHistories}/${coachId}/list/${groupId}`);
  await updateDoc(docRef, {
    closedAt: new Date(),
    active: false,
  });
}

// Message Histories
export async function getMessageHistories({ coachId }) {
  if (!db || !coachId) return;
  const messageHistoriesCol = collection(db, `${collections.messageHistories}/${coachId}/list`);
  const messageHistoriesSnapshot = await getDocs(query(messageHistoriesCol, orderBy('startedAt', 'desc'), limit(10)));
  const messageHistoriesList = messageHistoriesSnapshot.docs.map(doc => doc.data());
  return messageHistoriesList;
}

export async function listenMessageHistories({ coachId }, onChange) {
  if (!db) return;
  const messageHistoriesCol = collection(db, `${collections.messageHistories}/${coachId}/list`);
  const queryRef = await query(messageHistoriesCol, orderBy('startedAt', 'desc'), limit(1))
  const observer = onSnapshot(queryRef, querySnapshot => {
    querySnapshot.docChanges().forEach(change => {
      onChange({ id: change.doc.id, type: change.type, data: change.doc.data() });
    });
  });
  return observer;
}

export async function setMessageClosedAt({ coachId, groupId }) {
  if (!db || !coachId || !groupId) return;

  const docRef = doc(db, `${collections.messageHistories}/${coachId}/list/${groupId}`);
  await updateDoc(docRef, {
    closedAt: new Date(),
    active: false,
  });
}

// Parsing resume histories
export async function getParsingResumeHistories({ coachId }) {
  if (!db || !coachId) return;
  
  const resumeHistoriesCol = collection(db, `${collections.parsingResumeHistories}/${coachId}/list`);  
  
  const resumeHistoriesSnapshot = await getDocs(query(resumeHistoriesCol, orderBy('startedAt', 'desc'), limit(10)));
  
  const resumeHistoriesList = resumeHistoriesSnapshot.docs.map(doc => doc.data());
  return resumeHistoriesList;
}

export async function listenParsingResumeHistories({ coachId }, onChange) {
  if (!db) return;
  const resumeHistoriesCol = collection(db, `${collections.parsingResumeHistories}/${coachId}/list`);
  const queryRef = await query(resumeHistoriesCol, orderBy('startedAt', 'desc'), limit(1))
  const observer = onSnapshot(queryRef, querySnapshot => {
    querySnapshot.docChanges().forEach(change => {
      onChange({ id: change.doc.id, type: change.type, data: change.doc.data() });
    });
  });
  return observer;
}

export async function setParsingResumeClosedAt({ coachId, groupId }) {
  if (!db || !coachId || !groupId) return;

  const docRef = doc(db, `${collections.parsingResumeHistories}/${coachId}/list/${groupId}`);
  await updateDoc(docRef, {
    closedAt: new Date(),
    active: false,
  });
}