<template>
  <div class="panel-job-process-footer">
    <div class="is-column">
      <div class="is-row">
        <bc-dropdown
          :bc-dropdown-border-color-opacity="0.3"
          :bc-dropup="true"
          :bc-is-multiple="true"
          :bc-is-props-value="true"
          :bc-options="coachesOptions"
          :value="selectedCoaches"
          bc-dropdown-border-color="blue-dodger"
          bc-dropdown-border-radius="4px"
          bc-input-font-size="14px"
          bc-input-padding="10px 30px"
          bc-input-padding-focus="10px 40px 10px 30px"
          bc-item-font-color="blue-cello"
          bc-item-font-size="13px"
          bc-options-border-top-radius="5px"
          bc-options-max-height="300px"
          bc-placeholder="Nom du coach"
          class="panel-job-process-footer__dropdown"
          data-dropdown-coach
          @change="setSelectedCoaches">
        </bc-dropdown>
        <v-date-picker
          v-model="range"
          :locale="locale"
          :max-date="new Date()"
          :popover="{ visibility: 'click' }"
          class="panel-job-process-footer__date-picker"
          mode="range"
          @input="setProcessDateRange">
          <template v-slot="{ togglePopover }">
            <bc-button
              color="primary"
              icon-left="calendar"
              type="outlined"
              @click="togglePopover">
            </bc-button>
          </template>
        </v-date-picker>
        <bc-button
          v-if="isPeriodSet"
          class="panel-coder-process-footer__button"
          color="primary"
          type="flat"
          @click.stop="resetDateRange">
          Depuis toujours
        </bc-button>
      </div>
      <processes-form-deal-alerts :form-deal-to-do="formDealToDo"></processes-form-deal-alerts>
    </div>
  </div>
</template>

<script>
  import BcDropdown from '@/legacy/ui-kit/components/Dropdown/BcDropdown';
  import BcButton from '@/ui-kit/components/BcButton';

  import { mapState } from 'vuex';
  import moment from 'moment/min/moment-with-locales';
  import ProcessesFormDealAlerts from '@/components/Processes/ProcessesFormDealAlerts';
  import locale from '@/mixins/i18n/locale';

  export default {
    name: 'panel-job-process-footer',
    components: { ProcessesFormDealAlerts, BcDropdown, BcButton },
    mixins: [locale],
    props: {
      formDealToDo: {
        type: Array,
        default: () => ([]),
      },
    },
    data: () => ({
      range: {
        end: undefined,
        start: undefined,
      },
    }),
    computed: {
      ...mapState({
        coaches: state => state.coaches.coaches,
      }),
      coachesOptions() {
        const coaches = this.coaches.map(coach => ({
          name: coach.firstName,
          query: coach._id,
        }));

        return [
          {
            name: 'Tous les membres',
            query: 'all',
          },
          ...coaches.sort((a, b) => a.name.localeCompare(b.name)),
        ];
      },
      selectedCoaches() {
        const selectedCoaches = this.coaches.filter(coach => (this.$route.query['selected-coaches'] || []).some(id => id === coach._id));

        return selectedCoaches.length > 0 ? selectedCoaches.map(coach => coach.firstName) : 'Tous les membres';
      },
      isPeriodSet() {
        return (this.$route.query['process-ends'] || this.$route.query['process-starts']);
      },
    },
    methods: {
      setSelectedCoaches(coach) {
        if (coach.query === 'all') {
          this.$router.push({
            query: {
              ...this.$route.query,
              'selected-coaches': undefined,
            },
          });
        } else if ((this.$route.query['selected-coaches'] || []).includes(coach.query)) {
          this.$router.push({
            query: {
              ...this.$route.query,
              'selected-coaches': (this.$route.query['selected-coaches'] || []).filter(id => id !== coach.query),
            },
          });
        } else {
          this.$router.push({
            query: {
              ...this.$route.query,
              'selected-coaches': [...(this.$route.query['selected-coaches'] || []), coach.query],
            },
          });
        }
      },
      setProcessDateRange(date) {
        if (date) {
          if (!this.$route.query['process-starts'] || !this.$route.query['process-ends']) {
            this.$router.replace({
              ...this.$route,
              query: {
                ...this.$route.query,
                'process-starts': moment(date.start).format('D'),
                'process-ends': moment(date.end).format('D'),
              },
            });
          } else {
            this.$router.push({
              ...this.$route,
              query: {
                ...this.$route.query,
                'process-starts': moment(date.start).format('D'),
                'process-ends': moment(date.end).format('D'),
              },
            });
          }
        }
      },
      resetDateRange() {
        this.$router.replace({
          ...this.$route,
          query: {
            ...this.$route.query,
            'process-starts': undefined,
            'process-ends': undefined,
          },
        });

        this.range.end = undefined;
        this.range.start = undefined;
      },
    },
    created() {
      if (this.$route.query['process-starts'] && this.$route.query['process-ends']) {
        this.range.start = moment(this.$route.query['process-starts'], 'D').toDate();
        this.range.end = moment(this.$route.query['process-ends'], 'D').toDate();
      }
    },
    destroyed() {
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          'process-starts': undefined,
          'process-ends': undefined,
          'selected-coaches': undefined,
        },
      });
    },
  };
</script>

<style lang="scss" scoped>
  .panel-job-process-footer {
    padding: 10px 20px;
    background: $color-white;

    &__dropdown {
      max-width: 200px;
      margin-right: 20px;
    }

    &__date-picker {
      & :deep() {
        input {
          outline: none;
          box-shadow: none;
          border: 1px solid rgba($color-blue-dodger, 0.3);
          text-align: center;
          color: $color-blue-dodger;
          font-size: $font-size-s;
          padding: 5px 10px;
          transition: 0.3s;

          &:focus {
            border-color: $color-blue-dodger;
          }
        }

        .vc-grid-cell {
          display: block;
        }
      }
    }

    &__button {
      margin-left: 20px;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
    }
  }
</style>
