<template>
  <modal-delete-item
    :only-content="true"
    :title="$t('generics.delete-candidate-warning')"
    @on-confirm="confirmDelete"
    @close="cancelDelete">
    <template #content>
      <p>{{ $t('generics.delete-candidate-warning-content') }}</p>
    </template>
  </modal-delete-item>
</template>

<script>
import ModalDeleteItem from '@/components/Modal/ModalDeleteItem';

export default {
  name: 'panel-coder-modal-delete',
  components: {
    ModalDeleteItem,
  },
  methods: {
    cancelDelete() {
      this.$emit('close-modal');
    },
    async confirmDelete() {
      this.$emit('confirm-delete');
    },
  },
};
</script>
