<template>
  <div class="panel-categories-item-new-style">
    <button-default
      :bc-background-color="isActive ? category.backgroundColorActive : category.backgroundColor"
      :bc-border-color="isActive ? category.borderColorActive : category.borderColor"
      :bc-icon="category.icon"
      :bc-opacity="category.opacity"
      :bc-radius="isNotMobile ? '0': '5px'"
      :bc-size-text="category.textSize"
      :bc-text-color="isActive ? category.textColorActive : category.textColor"
      :disabled="category.disabled"
      @click="changeCategory"
      bc-icon-margin="10px"
      class="panel-categories-item-new-style__button"
      v-if="!isNotMobile"
      v-tooltip.native="category.tooltip">
      {{ category.name }}
    </button-default>
    <button-default
      :bc-background-color="isActive ? category.backgroundColorActive : category.backgroundColor"
      :bc-border-color="isActive ? category.borderColorActive : category.borderColor"
      :bc-icon="category.icon"
      :bc-opacity="category.opacity"
      :bc-radius="isNotMobile ? '0': '5px'"
      :bc-size-text="category.textSize"
      :bc-text-color="isActive ? category.textColorActive : category.textColor"
      :disabled="category.disabled"
      @click="changeCategory"
      bc-icon-margin="10px"
      class="panel-categories-item-new-style__button"
      v-else-if="category.disabled"
      v-tooltip.native="category.tooltip">
      {{ category.name }}
    </button-default>
    <button-default
      :bc-background-color="isActive ? category.backgroundColorActive : category.backgroundColor"
      :bc-border-color="isActive ? category.borderColorActive : category.borderColor"
      :bc-icon="category.icon"
      :bc-opacity="category.opacity"
      :bc-size-text="category.textSize"
      :bc-text-color="isActive ? category.textColorActive : category.textColor"
      :disabled="category.disabled"
      @click="changeCategory"
      bc-icon-margin="10px"
      bc-radius="0"
      class="panel-categories-item-new-style__button"
      v-else
      v-tooltip.native="category.tooltip">
      {{ category.name }}
    </button-default>
  </div>
</template>

<script>
  // TODO move or refacto to ui-kit
  import ButtonDefault from '@/legacy/ui-kit/components/Button/BcButton';

  /**
   * This component is the category item of the Panel. It changes the $route when we click on it
   */
  export default {
    name: 'panel-categories-item-new-style',
    components: { ButtonDefault },
    props: {
      /**
       * The category which will be show
       */
      category: Object,
      /**
       * The index of the category
       */
      categoryIndex: Number,
      /**
       * Indicate if the category is unique, which will means that the style will be different.
       */
      isSingle: Boolean,
      /**
       * Indicate if the categories should be display in phone style or in desktop style
       */
      isNotMobile: Boolean
    },
    computed: {
      /**
       * Indicate if the category should be blue or not
       */
      isActive() {
        return this.$route.query.category === this.category.url || (!this.$route.query.category && this.categoryIndex === 0);
      },
      /**
       * Indicate if the category is disabled
       */
      isDisabled() {
        return this.category.disabled;
      }
    },
    methods: {
      changeCategory() {
        /**
         * Change the category with another route and set the activeCategory state to true if the category is not disabled
         */
        if (!this.isDisabled) {
          this.$router.push({
            name: this.$route.name,
            params: this.$route.params,
            query: { ...this.$route.query, category: this.category.url }
          });
          this.$store.dispatch('setActiveCategory', true);
        }
      }
    },
    beforeDestroy() {
      /**
       * Set to false the activeCategory state before destroying the component to reinitialize the Panel
       */
      this.$store.dispatch('setActiveCategory', false);
    }
  };
</script>

<style scoped lang="scss">
  .panel-categories-item-new-style {
    width: 100%;
    &__button {
      padding: 10px 15px;
      width: 100%;
      text-transform: uppercase;
      justify-content: center;
      @include bp('tablet') {
        border: 0px !important;
      }
    }
  }
</style>
