<template>
  <views class="dashboard">
    <views-container>
      <div class="dashboard__container is-column">
        <!-- <dashboard-onboarding
          v-if="isOnboardingVisible"
          class="mb-5">
        </dashboard-onboarding> -->
        <!-- <dashboard-modules></dashboard-modules> -->
      </div>
    </views-container>
    <bc-modal
      v-if="isNewProfile"
      :active="isOpenRegisterModal">
      <dashboard-register
        :profile="profile"
      >
      </dashboard-register>
    </bc-modal>
    <router-view></router-view>
  </views>
</template>
<script>
  import Views from '@/components/Views/Views';
  // import DashboardOnboarding from '@/components/Dashboard/DashboardOnboarding';
  import ViewsContainer from '@/components/Views/ViewsContainer';
  // import DashboardModules from '@/components/Dashboard/DashboardModules';
  import BcModal from '@/ui-kit/components/BcModal';
  import DashboardRegister from '@/components/Dashboard/DashboardModal/DashboardRegister';

  export default {
    name: 'dashboard',
    components: {
      // DashboardModules,
      ViewsContainer,
      // DashboardOnboarding,
      Views,
      BcModal,
      DashboardRegister,
    },
    data() {
      return {
        isOpenRegisterModal: true,
        isNewProfile: false,
        profile: {
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          company: '',
        },
      };
    },
    mounted() {
      this.profileReturned();
    },
    computed: {
      firstName() {
        return this.$store.state.user.profile?.firstName;
      },
      title() {
        return this.firstName ? this.$i18n.t('view-top-bar.message.hello', { msg: this.firstName }) : this.$i18n.t('view-top-bar.message.hello-undefined');
      },
      onboarding() {
        return this.$store.state.user.onboarding;
      },
      isOnboardingFinished() {
        // return (this.onboarding.coder && this.onboarding.partner && this.onboarding.hunt && this.onboarding.process);
        return false;
      },
      isOnboardingClosed() {
        // return false;
        return this.$store.state.user.profile?.onboarding?.finishedAt;
      },
      isOnboardingVisible() {
        return !this.isOnboardingFinished && !this.isOnboardingClosed;
      },
    },
    watch: {
      location(newValue) {
        if (newValue) {
          this.getLocationSuggestions(newValue);

          this.updateDealBilling('partnerAddress', newValue);
          this.autoSaveBilling('partnerAddress', newValue);
        } else {
          this.$store.dispatch('emptyLocationsSuggestions');
        }
      },
      openModalRegister() {
        if (this.profile) {
          this.isOpenRegisterModal = true;
        }
      },
    },
    methods: {
      profileReturned() {
        if (this.$route.query.needRegister) {
          this.isNewProfile = true;
          return this.isOpenRegisterModal = true;
        }

        this.isNewProfile = false;
        return this.isOpenRegisterModal = false;
      },
      getProfile() {
        this.profileReturned();
        return this.profile;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .dashboard {
  }
</style>
