<template>
  <div class="panel-categories-new-style">
    <panel-categories-item
      class="panel-categories-new-style__item"
      v-for="(category, index) in categories"
      :key="index"
      :category="category"
      :category-index="index"
      :is-not-mobile="windowWidth > 767"
      :is-single="categories.length === 1">
    </panel-categories-item>
  </div>
</template>

<script>
  import PanelCategoriesItem from '@/components/Panel/PanelCategories/PanelCategoriesItemNewStyle';
  import { mapState } from 'vuex';
  // TODO Refacto ce composant à cause des nouveaux style de Panel
  export default {
    name: 'panel-categories-new-style',
    components: { PanelCategoriesItem },
    /**
     * This component will be the container for the Panel categories
     */
    props: {
      /**
       * The categories which will be loop
       */
      categories: Array
    },
    computed: {
      ...mapState({
        windowWidth: state => state.panel.windowWidth
      })
    }

  };
</script>

<style scoped lang="scss">
  .panel-categories-new-style {
    background: $color-white;
    flex-shrink: 0;
    padding: 10px 15px;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid rgba($color-blue-cornflower, 0.3);
    @include bp('tablet') {
      padding: 0;
      border-top: 1px solid rgba($color-blue-cornflower, 0.3);
    }
    &__item {
      width: 100%;
      margin-right: 20px;
      &:last-of-type {
        margin-right: 0;
      }
      @include bp('tablet') {
        margin-right: 0;
      }
    }
  }
</style>
