import { http } from '@/api/index';

export const getLocationAutocomplete = (input) => {
  return http.get('/gmaps-autocomplete', {
    params: {
      input,
    }
  });
};

export const getLocationByPlaceId = (placeId) => {
  return http.get('/gmaps-placeid', {
    params: {
      placeId,
    }
  });
};

export default {
  getLocationAutocomplete,
  getLocationByPlaceId,
};
