import { CampaignAction, CampaignActionKey, CampaignProspectAction } from '@/domains/models/Campaign';

type ActionWithLabel = CampaignAction & {
  label: string;
};

type ProspectActionWithLabel = CampaignProspectAction & {
  campaignAction: CampaignAction & {
    label: string;
  };
};

export const addActionLabels = (actions: CampaignAction[], getTranslatedName: (action: CampaignAction) => string): ActionWithLabel[] => {
  const typeCounts = new Map<CampaignActionKey, number>();

  return actions.reduce<ActionWithLabel[]>((acc, action) => {
    const currentCount = (typeCounts.get(action.type) || 0) + 1;
    typeCounts.set(action.type, currentCount);

    const translatedName = getTranslatedName(action);
    const label = currentCount > 1 
      ? `${translatedName} ${currentCount}`
      : translatedName;

    return [...acc, { ...action, label }];
  }, []);
};

export const addProspectActionLabels = (prospectActions: CampaignProspectAction[], getTranslatedName: (action: CampaignAction) => string): ProspectActionWithLabel[] => {
  const typeCounts = new Map<CampaignActionKey, number>();

  return prospectActions.reduce<ProspectActionWithLabel[]>((acc, prospectAction) => {
    const currentCount = (typeCounts.get(prospectAction.campaignAction.type) || 0) + 1;
    typeCounts.set(prospectAction.campaignAction.type, currentCount);

    const translatedName = getTranslatedName(prospectAction.campaignAction);
    const label = currentCount > 1 
      ? `${translatedName} ${currentCount}`
      : translatedName;

    return [...acc, {
      ...prospectAction,
      campaignAction: {
        ...prospectAction.campaignAction,
        label
      }
    }];
  }, []);
}; 