import { http } from '@/api/index';

export const getPartnerProfile = partnerId => {
  if (!partnerId || partnerId === 'panel') return;
  return http.get(`/partners/${partnerId}`);
};

export const createPartner = newPartner => {
  return http.post('/partners', newPartner);
};

export const updatePartner = partner => {
  const { id, obj } = partner;

  return http.patch(`/partners/${id}`, obj);
};

export const getPartnerJobs = partner => {
  // TODO Modifier le params en objet avec des keys plus précises
  const { call, parameters } = partner;

  return http.get(`/partners/${call}/jobs`, {
    ...parameters && {
      params: {
        exclude: parameters.exclude,
        status: parameters.status,
      },
    },
  });
};

export const getPartnerHunts = ({ companyId }) => {
  return http.get(`/hunt/company/${companyId}`, {
    params: {
      openHuntsOnly: true,
    },
  });
};

export const getPartnerContacts = id => {
  return http.get(`/partners/${id}/contacts`);
};

export const updatePartnerStatus = partner => {
  const { id, status } = partner;

  return http.put(`/partners/${id}/${status}`);
};

export const deletePartner = partnerId => {
  return http.delete(`/partners/${partnerId}`);
};

export const partnersAutocomplete = (query, listId) => {
  return http.get('/partners/autocomplete', { params: { query, listId } });
};

export const getPartnerBySocials = ({ website, linkedin, wttj, name }) => http.get('/partner', {
  params: {
    website,
    linkedin,
    wttj,
    ...name && { name },
  },
});

export const setPartnerOwner = (partnerId, coachId) => {
  return http.patch(`/partners/${partnerId}/ownership`, { coachId });
};

export const setPartnerAccountManager = (partnerId, coachId) => {
  return http.patch(`/partners/${partnerId}/account-manager`, { coachId });
};


export const setPartnerProspectingStatus = ({ partnerId, status }) => {
  return http.patch(`/partners/${partnerId}/prospecting`, { status });
};

export const getPartnersProspectingPipe = ({ coachIds, status, sort, teamIds, page }) => {
  return http.get('/partners/prospects', {
    params: {
      ...coachIds && { coachIds },
      ...teamIds && { teamIds },
      status,
      sort,
      page,
    },
  });
};


export const getPartnersProspectingPipeCount = ({ coachIds, status, sort, teamIds, page }) => {
  return http.get('/partners/prospects/count', {
    params: {
      ...coachIds && { coachIds },
      ...teamIds && { teamIds },
      status,
    },
  });
};

export const getCompanySubscriptions = () => {
  return http.get('/partners/subscriptions');
};

export const removeCompanyFromPipe = id => {
  return http.delete(`/partners/${id}/ownership`);
};

export const getCompanyContacts = id => {
  return http.get(`partners/${id}/company_contacts`);
};

export const mergeCompanies = ({ companyToKeep, companyToDelete, fieldsToMerge }) => {
  return http.put('/partners/merge', {
    companyToKeep,
    companyToDelete,
    fieldsToMerge,
  });
};

export const getCompaniesDeduplication = ({ name, linkedin, emails, phones, website }) => {
  return http.get(`/partners/deduplication`, {
    params: {
      name,
      linkedin,
      emails,
      phones,
      website,
    },
  });
};

export default {
  getPartnerProfile,
  createPartner,
  updatePartner,
  getPartnerJobs,
  getPartnerContacts,
  updatePartnerStatus,
  deletePartner,
  partnersAutocomplete,
  getPartnerBySocials,
  setPartnerOwner,
  getPartnersProspectingPipe,
  getPartnersProspectingPipeCount,
  setPartnerProspectingStatus,
  getCompanySubscriptions,
  removeCompanyFromPipe,
  getCompanyContacts,
  mergeCompanies,
  getCompaniesDeduplication,
};
