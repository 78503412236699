<script lang="ts" setup>
import { Company } from '@/domains/models/Company';
import type { Ref } from 'vue';
import { computed, defineEmits, defineProps, ref, set as $set, watch, withDefaults, nextTick } from 'vue';
import { RouterLink } from 'vue-router';
import MvAccordion from '@/ui-kit/components/MvAccordion/MvAccordion.vue';
import MvAccordionTab from '@/ui-kit/components/MvAccordionTab/MvAccordionTab.vue';
import { useStore } from '@/store';
import { useQuery } from '@tanstack/vue-query';
import { useRoute, useRouter } from 'vue-router/composables';
import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
import { Process } from '@/domains/models/Process';
import PanelCarousel from '../PanelCarousel.vue';
import ProcessDropdown from '@/components/Process/ProcessDropdown.vue';
import { Opportunity } from '@/domains/models/Opportunity';
import { useI18n } from '@/i18n/i18n';
import PanelOpportunityTop from '@/components/Panel/PanelOpportunity/PanelOpportunityTop.vue';
import PanelOpportunityDetails from '@/components/Panel/PanelOpportunity/PanelOpportunityDetails.vue';
import PanelOpportunityProcess from '@/components/Panel/PanelOpportunity/PanelOpportunityProcess.vue';
import postsController from '@/managers/posts/controller';
import PanelOpportunityPostDescription from '@/components/Panel/PanelOpportunity/PanelOpportunityPostDescription.vue';
import PanelOpportunityCompanyDescription from '@/components/Panel/PanelOpportunity/PanelOpportunityCompanyDescription.vue';
import { descriptionTypes } from '@/api/custom-jobs';
import PanelOpportunityProfileDescription from '@/components/Panel/PanelOpportunity/PanelOpportunityProfileDescription.vue';
import PanelOpportunityMultidiffusion from '@/components/Panel/PanelOpportunity/PanelOpportunityMultidiffusion.vue';
import PanelOpportunityStatistics from '@/components/Panel/PanelOpportunity/PanelOpportunityStatistics.vue';
import PanelOpportunityDocuments from '@/components/Panel/PanelOpportunity/PanelOpportunityDocuments.vue';
import { useElementBounding, useScroll } from '@vueuse/core';
import { useProcessesApi } from '@/composables/api/useProcessesApi';
import { useProcessesFilter } from '@/composables/useProcessesFilter';
import ViewsTabs from '@/components/Views/ViewsTabs';

const props = withDefaults(
  defineProps<{
    profile: Opportunity;
  }>(),
  {
    profile: () => ({}) as Opportunity,
  },
);
const emits = defineEmits<{
  (e: 'edit-opportunity', value: boolean): void;
  (e: 'update-opportunity', value: Company): void;
  (e: 'update-process', value: Process): void;
}>();

const processesApi = useProcessesApi();

const { statusFilterOptions, processStatusFilter } = useProcessesFilter();
const { t } = useI18n();

const route = useRoute();
const router = useRouter();
const opportunityId = computed(() => route.params.id);

const viewTabs = [
  {
    key: 'profil-documents',
    icon: 'companies',
    label: t('generics.profile-document'),
    onClick: () => {
      activeViewTab.value = 'profil-documents';
      router.replace({
        query: {
          ...route.query,
          'view-tab': 'profil-documents',
        },
      });
    },
  },
  {
    key: 'multidiffusion',
    icon: 'share-2',
    label: t('generics.multicast'),
    onClick: () => {
      activeViewTab.value = 'multidiffusion';
      router.replace({
        query: {
          ...route.query,
          'view-tab': 'multidiffusion',
        },
      });
    },
  },
  {
    key: 'processes',
    icon: 'kanban',
    label: 'Process',
    onClick: () => {
      activeViewTab.value = 'processes';
      router.replace({
        query: {
          ...route.query,
          'view-tab': 'processes',
        },
      });
    },
  },
];

const viewTabsKeys = viewTabs.map(tab => tab.key);
const activeViewTab = ref(viewTabsKeys.includes(route.query['view-tab'] || '') ?  route.query['view-tab'] : viewTabs[0].key);

const activeTabs = ref<number[]>([]);

const store = useStore();

const accordion = ref<HTMLDivElement | null>(null);
const accordionBoundary = useElementBounding(accordion);

  const container = ref<HTMLDivElement | null>(null);
  const { y: containerScroll } = useScroll(container);

const organization = store.state.user?.profile?._organization;

const page = ref(1);
const identifier = ref(+new Date());
const processes = ref<Process[]>([]);

async function loadMoreProcesses($state: any) {
  const data = await processesApi.getProcessesByResource({
    opportunityId: opportunityId.value,
    page: page.value,
    stepKey: processStatusFilter.value?.key,
  });
  if (!data) {
    $state.error();
    return;
  }
  const { processes: pageProcesses, isLastPage } = data;

  if (processes.value?.length > 0) activeTabs.value.push(1);

  if (pageProcesses?.length) {
    const uniqueNewProcesses = pageProcesses.filter(
      newProcess => !processes.value.some(existingProcess => existingProcess._id === newProcess._id)
    );
    processes.value = [...processes.value, ...uniqueNewProcesses];
    if (!isLastPage) page.value++;
    await nextTick();
    $state.loaded();
  }
  if (!pageProcesses?.length || isLastPage) {
    $state.complete();
  }
}

async function getCount() {
  processesCount.value = await processesApi.getProcessesCountByResource({
    opportunityId: opportunityId.value,
    stepKey: processStatusFilter.value?.key,
  });
}

async function resetProcesses() {
  processes.value = [];
  page.value = 1;
  identifier.value = +new Date();
  await getCount();
}

const isCreatingProcess = ref(false);
const isEditingDetails: Ref<boolean> = ref(false);
const isEditingDescription: Ref<boolean> = ref(false);
const isEditingCompanyDescription: Ref<boolean> = ref(false);
const isEditingProfileDescription: Ref<boolean> = ref(false);

const descriptionCount = computed(() => {
  return props.profile.description ? 1 : 0;
});
const profileDescriptionCount = computed(() => {
  return props.profile.profileDescription ? 1 : 0;
});
const companyDescriptionCount = computed(() => {
  if (props.profile?.descriptionType === descriptionTypes.client) {
    return props.profile?._company.description ? 1 : 0;
  } else if (props.profile?.descriptionType === descriptionTypes.custom) {
    return props.profile?.customCompanyDescription ? 1 : 0;
  }
  return props.profile?._organization?.description ? 1 : 0;
});

const details = computed(() => [
  ...(props.profile?.locations || []),
  ...(props.profile?._professions || []),
  ...(props.profile?._skills || []),
  ...(props.profile?.contract ? [props.profile?.contract] : []),
  ...((props.profile?.contractStartDate && props.profile?.contractEndDate) ||
  props.profile?.contractDuration
    ? [
        (props.profile?.contractStartDate && props.profile?.contractEndDate) ||
          props.profile?.contractDuration,
      ]
    : []),
  ...(props.profile?.budget?.min && props.profile?.budget?.max
    ? [props.profile?.budget?.min && props.profile?.budget?.max]
    : []),
  ...(props.profile?._employees?.length ? props.profile?._employees : []),
  ...(props.profile?._employees?.length ? props.profile?._employees : []),
  ...(Object.keys(props.profile?.customFields || {}).map(key => props.profile?.customFields[key]) ||
    []),
]);
const detailsCount = computed(() => {
  return details.value?.length || 0;
});

const updateProcess = ({ index, process }) => {
  $set(processes.value, index, { ...processes.value[index], ...process });
};
const removeProcessFromProcesses: ({ id }: { id: string }) => void = ({ id }) => {
  processes.value = processes.value.filter(process => process._id !== id);
};

const defaultOption = {
  name: t('processes.all-processes'),
  key: undefined,
  categoryKey: 'all',
};
processStatusFilter.value = defaultOption;

const postDescriptions = ref<Document[]>([]);
const postDescriptionsCount = computed(() => {
  return postDescriptions?.value?.length || 0;
});

const { refetch: refetchPostDescriptions } = useQuery({
  refetchOnWindowFocus: false,
  queryKey: ['GET_POST_DESCRIPTIONS', opportunityId.value],
  queryFn: async () => {
    try {
      const { data } = await postsController.getCustomJobPosts(opportunityId.value);
      postDescriptions.value = data;

      if (postDescriptions.value?.length > 0) {
        activeTabs.value.push(2);
      }

      return postDescriptions.value;
    } catch (e) {
      console.error(e);
    }
  },
});
const uploadDocument = ref<HTMLInputElement | null>(null);
const triggerUploadButton = () => {
  uploadDocument.value?.click();
};
const addPostDescription = async e => {
  if (e.target.files[0]) {
    const { data } = await postsController.createCustomJobPost({
      id: opportunityId.value,
      file: e.target.files[0],
    });

    postDescriptions.value.unshift(data);
  }
};

const configurationFeatures = store.getters['user/configurationFeatures'];

const multidiffusionCount = computed(() => [...(props.profile?.adPublished ? [1] : [])].length);

const isStatisticsActive = computed(() => organization?.configuration?.multiposting?.showStats);
const isStatisticsVisible = computed(
  () => props.profile?._id && configurationFeatures.multidiffusion && isStatisticsActive.value,
);

const processesCount = ref(0);

getCount();

watch(
  () => props.profile?._id,
  () => {
    if (!props.profile?._id) return;
    const initialActiveTabs = [
      detailsCount.value,
      1,
      postDescriptionsCount.value,
      companyDescriptionCount.value,
      descriptionCount.value,
      profileDescriptionCount.value,
      multidiffusionCount.value,
    ];
    activeTabs.value = initialActiveTabs
      .map((_, index) => index)
      .filter(index => initialActiveTabs[index] > 0);
  },
  { immediate: true },
);
watch(
  () => [route.params.id, processStatusFilter.value],
  () => {
    resetProcesses();
    refetchPostDescriptions();
  },
);

watch(() => activeViewTab.value, () => {
  if (activeViewTab.value !== 'processes' && processes.value?.length) {
    resetProcesses();
  }
});
</script>

<template>
  <div
    ref="container"
    class="flex max-h-full min-h-full flex-col overflow-auto bg-white pb-5">
    <PanelCarousel is-new-style>
      <PanelOpportunityTop
        :opportunity="profile"
        @update-opportunity="$emit('update-opportunity', $event)" />
    </PanelCarousel>

    <div class="mt--4">
      <ViewsTabs :tabs="viewTabs" :forRoute="false" :active="activeViewTab"></ViewsTabs>
    </div>

    <MvAccordion
      :key="profile._id"
      ref="accordion"
      :active-index.sync="activeTabs"
      class="mt-4 flex flex-col gap-2.5 px-[20px]"
      multiple>
      <MvAccordionTab v-show="activeViewTab === viewTabs[0].key">
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('generics.details') }}
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white">
              {{ detailsCount }}
            </div>
            <div
              v-show="!isEditingDetails"
              class="absolute right-0 flex gap-2.5 bg-white pl-2.5"
              @click.stop>
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="
                  () => {
                    isEditingDetails = true;
                    activeTabs.push(0);
                  }
                ">
                <i class="icon-edit-3 text-2xl" />
              </button>
            </div>
          </div>
        </template>
        <PanelOpportunityDetails
          :is-editing="isEditingDetails"
          :opportunity="profile"
          @close-edit="isEditingDetails = false"
          @update-opportunity="$emit('update-opportunity', $event)" />
      </MvAccordionTab>
      <MvAccordionTab v-show="activeViewTab === viewTabs[2].key">
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold"> Process </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white">
              {{ processesCount }}
            </div>
            <div class="absolute right-0 flex items-center gap-2.5 bg-white pl-2.5" @click.stop>
              <ProcessDropdown
                :active-tabs="activeTabs"
                :items="statusFilterOptions"
                :parent-height="accordionBoundary.height"
                :scroll-position="containerScroll"
                :value="processStatusFilter"
                class="min-w-[160px]"
                @select="processStatusFilter = $event">
                <template #icon>
                  <span class="icon-equalizer mr-2 text-blue-800"></span>
                </template>
              </ProcessDropdown>
              <RouterLink
                :to="{
                  name: $route.name,
                  params: {
                    id: profile._id,
                  },
                  query: {
                    ...route.query,
                    type: 'process-kanban',
                    subtype: 'hunt',
                  },
                }">
                <BcButton
                  class="!h-[32px] !text-sm !font-medium"
                  color="success"
                  icon-left="kanban"
                  size="extra-small">
                  {{ $t('generics.kanban') }}
                </BcButton>
              </RouterLink>
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="
                  () => {
                    isCreatingProcess = true;
                    activeTabs.push(1);
                  }
                ">
                <i class="icon-plus-circle text-3xl" />
              </button>
            </div>
          </div>
        </template>
        <PanelOpportunityProcess
          v-if="activeViewTab === viewTabs[2].key"
          :is-editing="isCreatingProcess"
          :opportunity="profile"
          :processes="processes"
          class="mt-2"
          :identifier="identifier"
          @close-edit="isCreatingProcess = false"
          @create-process="
            () => {
              resetProcesses();
              isCreatingProcess = false;
            }
          "
          @update-process="updateProcess"
          @remove-process="removeProcessFromProcesses"
          @load-more-processes="loadMoreProcesses" />
      </MvAccordionTab>
      <MvAccordionTab
        v-show="activeViewTab === viewTabs[0].key"
        :class="{ 'panel-coder-profile__documents': postDescriptionsCount > 0 }"
      >
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold"> Documents </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white">
              {{ postDescriptionsCount }}
            </div>
            <div class="absolute right-0 flex gap-2.5 bg-white pl-2.5" @click.stop>
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="triggerUploadButton">
                <i class="icon-plus-circle text-3xl" />
              </button>
              <input
                ref="uploadDocument"
                accept=".doc,.docx,application/pdf"
                class="hidden"
                name="document"
                type="file"
                @change="addPostDescription" />
            </div>
          </div>
        </template>
        <PanelOpportunityDocuments
          :documents="postDescriptions"
          @update-opportunity="refetchPostDescriptions" />
      </MvAccordionTab>
      <MvAccordionTab v-show="activeViewTab === viewTabs[1].key">
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('companies.panel-company.company-description') }}
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white">
              {{ companyDescriptionCount }}
            </div>
            <div
              v-show="!isEditingCompanyDescription"
              class="absolute right-0 flex gap-2.5 bg-white pl-2.5"
              @click.stop>
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="
                  () => {
                    isEditingCompanyDescription = true;
                  }
                ">
                <i class="icon-edit-3 text-2xl" />
              </button>
            </div>
          </div>
        </template>
        <PanelOpportunityCompanyDescription
          :is-editing="isEditingCompanyDescription"
          :opportunity="profile"
          @close-edit="isEditingCompanyDescription = false"
          @update-opportunity="$emit('update-opportunity', $event)" />
      </MvAccordionTab>
      <MvAccordionTab v-show="activeViewTab === viewTabs[1].key">
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('custom-jobs.description-job-offer') }}
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white">
              {{ descriptionCount }}
            </div>
            <div
              v-show="!isEditingDescription"
              class="absolute right-0 flex gap-2.5 bg-white pl-2.5"
              @click.stop>
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="
                  () => {
                    isEditingDescription = true;
                    activeTabs.push(4);
                  }
                ">
                <i class="icon-edit-3 text-2xl" />
              </button>
            </div>
          </div>
        </template>
        <PanelOpportunityPostDescription
          :is-editing="isEditingDescription"
          :opportunity="profile"
          @close-edit="isEditingDescription = false"
          @update-opportunity="$emit('update-opportunity', $event)" />
      </MvAccordionTab>
      <MvAccordionTab v-show="activeViewTab === viewTabs[1].key">
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $tc('generics.wanted-profile-cap') }}
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white">
              {{ profileDescriptionCount }}
            </div>
            <div
              v-show="!isEditingProfileDescription"
              class="absolute right-0 flex gap-2.5 bg-white pl-2.5"
              @click.stop>
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="
                  () => {
                    isEditingProfileDescription = true;
                    activeTabs.push(5);
                  }
                ">
                <i class="icon-edit-3 text-2xl" />
              </button>
            </div>
          </div>
        </template>
        <PanelOpportunityProfileDescription
          :is-editing="isEditingProfileDescription"
          :opportunity="profile"
          @close-edit="isEditingProfileDescription = false"
          @update-opportunity="$emit('update-opportunity', $event)" />
      </MvAccordionTab>
      <MvAccordionTab v-show="activeViewTab === viewTabs[1].key">
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold"> Multidiffusion </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white">
              {{ multidiffusionCount }}
            </div>
            <div
              :class="{
                'bg-green-100 text-green-500': profile?.adPublished,
                'bg-red-200 text-red-500': !profile?.adPublished,
              }"
              class="ml-2 flex h-5 min-w-4 shrink-0 items-center justify-center rounded-3xl px-[10px] text-xs font-normal text-green-500">
              {{ profile?.adPublished ? $t('generics.online') : $t('generics.offline') }}
            </div>
          </div>
        </template>
        <PanelOpportunityMultidiffusion
          :opportunity="profile"
          @update-opportunity="$emit('update-opportunity', $event)" />
      </MvAccordionTab>
      <MvAccordionTab v-show="activeViewTab === viewTabs[1].key" v-if="isStatisticsVisible">
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('generics.statistics') }}
            </b>
          </div>
        </template>
        <PanelOpportunityStatistics />
      </MvAccordionTab>
    </MvAccordion>
  </div>
</template>

<style lang="scss">
.panel-coder-profile__documents > .p-toggleable-content {
  min-height: 640px;
}
</style>
