import { http } from '@/api/index';

export const cleanCompaniesLinkedinUrl = () => {
  return http.post('/migration/clean-companies-linkedin-urls');
};

export const syncXtramileJobs = ({
  organizationId,
  channelSubscriptionId,
}: {
  organizationId: string;
  channelSubscriptionId: string;
}) => {
  return http.post('/migration/sync-xtramile-jobs', {
    organizationId,
    channelSubscriptionId,
  });
};

export default {
  cleanCompaniesLinkedinUrl,
  syncXtramileJobs,
};
