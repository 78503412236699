<template>
  <div class="custom-jobs-recap">
    <div class="custom-jobs-recap__header">
      <p class="custom-jobs-recap__title"> Récapitulatif de votre annonce </p>
      <div class="custom-jobs-recap__details">
        <picture-opportunity
          v-if="configurationFramework.showCompanies"
          :imgSrc="company ? company.logoUrl : ''"
          class="custom-jobs-recap__picture">
        </picture-opportunity>
        <div class="custom-jobs-recap__names">
          <p class="custom-jobs-recap__job-name">{{ customJob.title }}</p>
          <p
            v-if="configurationFramework.showCompanies && company"
            class="custom-jobs-recap__company-name"
            @click="
              openPanel({ id: company._id, type: 'companies', category: 'my-notes' })
            ">
            {{ company.name }}
          </p>
        </div>
      </div>
    </div>
    <div class="custom-jobs-recap__content">
      <custom-jobs-details :custom-job="customJob" />
    </div>
    <div class="custom-jobs-recap__footer">
      <bc-button
        class="custom-jobs-recap__full"
        type="outlined"
        @click="$emit('cancel', $event)">
        {{ $t('generics.no-cancel-this-action') }}
      </bc-button>
      <bc-button
        class="custom-jobs-recap__full"
        color="success"
        :is-loading="isLoading"
        @click="$emit('submit', customJob)">
        {{ $t('custom-jobs.distribute-my-offer') }}
      </bc-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { contractsLabels, salaryLabels, salaryLabelsShort, contractDurationLabels, salaryTypesLabels, descriptionTypes } from '@/api/custom-jobs';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import PictureOpportunity from '@/components/UIKit/Picture/PictureOpportunity';
import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
import CustomJobsDetails from './CustomJobsDetails';
import moment from 'moment/min/moment-with-locales';

export default {
  name: 'custom-jobs-recap',
  components: {
    BcButton,
    PictureOpportunity,
    CustomJobsDetails,
  },
  mixins: [PanelNavigation],
  props: {
    customJob: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contractsLabels,
      salaryLabels,
      salaryLabelsShort,
      contractDurationLabels,
    }
  },
  computed: {
    ...mapGetters({
      configurationFramework: 'user/configurationFramework',
      configurationJob: 'user/configurationJob',
      configurationCareer: 'user/configurationCareer',
    }),
    company() {
      if (this.configurationCareer.anonymize) {
        return this.customJob._organization;
      }
      return this.customJob._company;
    },
    professionsList() {
      if (!this.customJob._professions) {
        return [];
      }
      return this.customJob._professions;
    },
    skillsList() {
      if (!this.customJob._skills) {
        return [];
      }
      return this.customJob._skills;
    },
    contractStartDate() {
      return `${this.$i18n.t('generics.start')} ${moment(this.customJob.contractStartDate).format('L')}`;
    },
    contractEndDate() {
      return `${this.$i18n.t('generics.end')} ${moment(this.customJob.contractEndDate).format('L')}`;
    },
    companyDescription() {
      if (this.customJob.descriptionType === descriptionTypes.client) {
        return this.customJob._company.description;
      } else if (this.customJob.descriptionType === descriptionTypes.custom) {
        return this.customJob.customCompanyDescription;
      }
      return this.customJob._organization?.description;
    },
  },
  methods: {
    getSalaryWithType(salary, type, prefix = '') {
      if (!salary) return ''
      return `${prefix} ${salary} ${salaryTypesLabels[type] || ''}`
    },
    setAlternativeImg(event) {
      event.target.src = require('@/assets/img/default-avatar.svg');
    },
  }
};
</script>

<style lang="scss" scoped>
.custom-jobs-recap {
  width: 647px;
  height: 100%;
  padding: 20px 26px;
  flex-direction: column;
  overflow: auto;
  max-height: 800px;

  &__header {
    padding: 20px 40px;
    flex-direction: column;
  }

  &__title {
    color: $color-tertiary;
    font-size: $font-size-xxl;
    font-weight: $font-weight-bold;
  }

  &__details {
    margin-top: 20px;
    gap: 10px;
  }

  &__names {
    flex-direction: column;
    gap: 5px;
  }

  &__job-name {
    color: $color-tertiary;
  }

  &__company-name {
    color: $color-primary;

    &:hover {
      cursor: pointer;
    }
  }

  &__content {
    flex-direction: column;
    gap: 10px;

    &-details {
      flex-direction: column;
      width: 100%;
      padding: 10px 0px;

      &:deep() {
        .panel-profile-item {
          padding: 3px 20px;
          min-height: inherit;
        }
      }
    }
  }

  &__footer {
    padding: 20px;
    margin-top: 10px;
    gap: 20px;
  }

  &__full {
    flex: 1;
  }

  &__description {
    flex-direction: column;
    padding: 20px 40px;
  }
}
</style>
