<script lang="ts" setup>
  import { Component, computed, ref, set as $set, watch } from 'vue';
  import { CampaignNextCondition, CampaignStep, Tab } from '@/components/Campaigns/CampaignsTypes';
  import CampaignsCreateStepsItem
    from '@/components/Campaigns/CampaignsCreate/CampaignsCreateSteps/CampaignsCreateStepsItem.vue';
  import { useI18n } from '@/i18n/i18n';
  import { capitalize } from '@/utils/stringTransform';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { useMutation } from '@tanstack/vue-query';
  import { Campaign, CampaignAction, CampaignTemplate, DelayData } from '@/domains/models/Campaign';
  import draggable from 'vuedraggable';
  import { useCampaignsActions } from '@/components/Campaigns/composables/useCampaignsActions';
  import {
    useCampaignsNextConditions,
  } from '@/components/Campaigns/composables/useCampaignsNextConditions';
  import { createCampaignAction, deleteCampaignAction, editCampaignAction } from '@/api/campaigns';

  const props = defineProps<{
    selectedTemplate: CampaignTemplate;
    campaign: Campaign
    tabs: Tab[];
    activeTab: Tab;
    activeTabIndex: number;
    nextTab: Tab;
  }>();
  const { t } = useI18n();

  const emit = defineEmits<{
    (e: 'next'): void;
    (e: 'update-template'): void;
    (e: 'clear-selected-template'): void;
  }>();

  const { steps } = useCampaignsActions();
  const { availableConditions } = useCampaignsNextConditions({
    conditions: [],
  });

  function transformStepConditionToNextCondition(condition: {
    conditionType: string;
    delay?: DelayData;
  }): CampaignNextCondition {
    const foundCondition = availableConditions.value.find(c => c.key === condition.conditionType);
    if (!foundCondition) {
      throw new Error(`Could not find condition with key ${condition.conditionType}`);
    }

    return {
      ...foundCondition,
      ...(condition.delay && { delay: condition.delay }),
    };
  }

  function transformActionToStep(step: CampaignAction): CampaignAction & CampaignStep {
    let data: { message?: string; object?: string } = {};

    if (step.messageTemplate?.body || step.emailTemplate?.body) {
      data.message = step.messageTemplate?.body ?? step.emailTemplate?.body ?? '';
    }

    if (step.messageTemplate?.subject || step.emailTemplate?.subject) {
      data.object = step.messageTemplate?.subject ?? step.emailTemplate?.subject ?? '';
    }

    const baseStep = steps.value.find(action => action.key === step.type);
    if (!baseStep) {
      throw new Error(`Could not find step with key ${step.type}`);
    }

    return {
      ...baseStep,
      ...step,
      data,
      ...step?.nextValidation && { 
        nextConditions: step.nextValidation?.conditions.map(condition => transformStepConditionToNextCondition(condition)) 
      },
    };
  }

  const selectedSteps = ref<(CampaignStep & {
    active?: boolean
  })[]>(props.selectedTemplate?.campaignActions ? props.selectedTemplate?.campaignActions.map((action: CampaignAction) => transformActionToStep(action)) : props.campaign.actions?.map((action: CampaignAction) => transformActionToStep(action)) ?? []);

  const selectedStepIndex = ref<number>(0);
  const selectedStep = computed(() => selectedSteps.value.length && selectedStepIndex.value >= 0 ? selectedSteps.value?.[selectedStepIndex.value] : null);

  const addStepButton = computed(() => ({
    name: capitalize(t('generics.add-resource', { resource: t('generics.a-step') })),
    icon: 'plus',
  }));
  const isOpen = ref(false);

  function toggleOpen() {
    isOpen.value = !isOpen.value;
  }

  function setSelectedStepIndex(index: number) {
    selectedStepIndex.value = index;
  }

  function addStep(key: CampaignStep['key']) {
    const stepToPush: CampaignStep | undefined = steps.value.find(action => action.key === key);

    if (stepToPush) {
      selectedSteps.value.push(stepToPush);
      setSelectedStepIndex(selectedSteps.value.length - 1);
      isOpen.value = false;
    }
  }

  function removeStep(index: number) {
    selectedSteps.value.splice(index, 1);
  }

  function handleUpdateStep(step: CampaignStep) {
    $set(selectedSteps.value, selectedStepIndex.value, { ...step, localUpdated: true });
  }

  function saveStepIndex(e) {
    setSelectedStepIndex(e.newIndex);
  }

  async function createCampaignsCall() {
    const stepsToCreate = selectedSteps.value.filter((step: CampaignStep) => !step._id);
    for (const step of stepsToCreate) {
      await createCampaignAction(props.campaign._id, {
        type: step.key,
        nextValidation: {
          operator: 'OR',
          conditions: step.nextConditions.map(condition => ({
            conditionType: condition.key,
            ...condition.key === 'DELAY' ? { delay: condition.delay } : {},
          })),
        },
        ...step.data?.message && {
          body: step.data.message,
        },
        ...step.data?.object && {
          subject: step.data.object,
        },
      });
    }
  }

  async function deleteCampaignActionsCall() {
    const existingStepsIds = props.campaign?.actions?.map((step: CampaignStep) => step._id);
    const stepsToDelete = existingStepsIds.filter((id: string) => !selectedSteps.value.some((selectedStep: CampaignStep) => selectedStep._id === id));

    for (const stepId of stepsToDelete) {
      await deleteCampaignAction(props.campaign._id, stepId);
    }
  }

  async function editCampaignActionsCall() {
    const stepsToUpdate = selectedSteps.value.filter((step: CampaignStep) => step._id && step.localUpdated);
    for (const step of stepsToUpdate) {
      await editCampaignAction(step._id, {
        type: step.key,
        nextValidation: {
          operator: 'OR',
          conditions: step.nextConditions.map(condition => ({
            conditionType: condition.key,
            ...condition.key === 'DELAY' ? { delay: condition.delay } : {},
          })),
        },
        ...step.data?.message && {
          body: step.data.message,
        },
        ...step.data?.object && {
          subject: step.data.object,
        },
      });
    }
  }

  const createCampaignActionMutation = useMutation({
    mutationKey: ['create-campaign-action', props.campaign?._id ?? ''],
    mutationFn: async() => {
      await createCampaignsCall();

      await deleteCampaignActionsCall();

      await editCampaignActionsCall();

      const existingStepsIds = props.campaign?.actions?.map((step: CampaignStep) => step._id);
      const stepsToDelete = existingStepsIds.filter((id: string) => !selectedSteps.value.some((selectedStep: CampaignStep) => selectedStep._id === id));

      for (const stepId of stepsToDelete) {
        await deleteCampaignAction(props.campaign._id, stepId);
      }

      emit('clear-selected-template');
      emit('update-template');
    },
  });

  async function handleNextButton() {
    await createCampaignActionMutation.mutateAsync();
    emit('next');
  }

  watch(() => selectedSteps.value.length, (to, from) => {
    if (from === 0 && to === 1) {
      selectedStepIndex.value = 0;
    }
  });
</script>

<template>
  <div class="flex grow">
    <div
      class="flex w-full max-w-[345px] grow flex-col gap-[5px] border border-blue-100 bg-neutral-100 p-[20px]">
      <draggable
        v-model="selectedSteps"
        animation="200"
        class="flex flex-col gap-[5px]"
        @end="saveStepIndex">
        <CampaignsCreateStepsItem
          v-for="(step, index) in selectedSteps"
          :key="`${step.key}-${index}`"
          :action="step"
          :is-active="selectedStepIndex === index"
          :is-draggable="true"
          @delete="removeStep(index)"
          @click.native="setSelectedStepIndex(index)"/>
      </draggable>
      <div
        v-click-outside="() => isOpen = false"
        class="relative flex w-full flex-col"
      >
        <CampaignsCreateStepsItem
          :action="addStepButton"
          :is-draggable="false"
          class="w-full !border-0 !bg-blue-100 !text-blue-500"
          @click.native="toggleOpen"/>
        <transition name="slide-fade">
          <div
            v-show="isOpen"
            class="mt-2 flex-col overflow-hidden rounded border-blue-100 shadow"
          >
            <button
              v-for="step in steps"
              :key="step.key"
              :disabled="step.upcoming"
              class="relative flex h-10 w-full items-center gap-2.5 border-b border-blue-100 bg-white p-[20px] last:border-b-0 hover:enabled:bg-blue-100 hover:enabled:text-blue-500 disabled:cursor-default"
              @click="addStep(step.key)">
              <div :class="{ 'opacity-50': step.upcoming }" class="flex grow items-center gap-2.5">
              <i
                :class="`icon-${step.icon}`"
                :style="{
                  background: step.backgroundColor,
                  color: step.iconColor,
                }"
                class="flex size-[15px] items-center justify-center rounded-full text-[7px]"/>
              <p class="flex-start flex grow">{{ step.name }}</p>
              </div>
              <div v-if="step.upcoming" class="absolute right-[-42px] bottom-[-19px] bg-red-500 py-[1px] w-[100px] text-center rotate-[-30deg] origin-bottom-left justify-center">
                <span class="text-xs font-medium text-white whitespace-nowrap">{{ $t('generics.soon') }}</span>
              </div>
            </button>
          </div>
        </transition>
      </div>
      <BcButton
        :disabled="!selectedSteps.length"
        class="mt-auto !w-full max-w-[300px]"
        size="small"
        @click.native="handleNextButton"
      >
        {{ $t('generics.next') }}
      </BcButton>
    </div>
    <div class="flex grow flex-col p-[20px]">
      <component
        :is="selectedStep.component"
        v-if="selectedStep"
        :step="selectedStep"
        @update-step="handleUpdateStep"/>
    </div>
  </div>
</template>
