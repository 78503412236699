import useCases from '@/managers/documents/useCases';

const createCoderDocument = ({ id, type, file }) => {
  return useCases.createCoderDocument({ id, type, file });
};

const removeCoderDocument = id => {
  return useCases.removeCoderDocument(id);
};

export default {
  createCoderDocument,
  removeCoderDocument,
};
