<template>
  <bc-slider
    class="is-full-width"
    type="range"
    isInputDisplayed
    :value="selectedValue"
    :min="customField.min"
    :max="customField.max"
    :step="increment"
    ticks
    @input="handleInput"
  ></bc-slider>
</template>

<script>
import BcSlider from '@/ui-kit/components/BcSlider/BcSlider';

export default {
  name: 'bc-custom-field-range',
  components: {
    BcSlider,
  },
  props: {
    customField: {
      type: Object,
      required: true,
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    if (this.selected?.length) {
      this.selectedValue = this.selected;
    } else if (this.customField.defaultValue?.length) {
      this.selectedValue = this.customField.defaultValue;
    } else {
      this.selectedValue = [this.customField.min, this.customField.max];
    }
  },
  data() {
    return {
      selectedValue: [],
    };
  },
  computed: {
    increment() {
      return this.customField.increment || Math.floor(this.customField.max * 0.05);
    }
  },
  methods: {
    handleInput(value) {
      this.selectedValue = value;
      this.$emit('on-update', { key: this.customField.key, value });
    },
  },
  watch: {
    selected(value) {
      this.selectedValue = value.length ? value.map(v => Number(v)) : [this.customField.min, this.customField.max];
    }
  }
};
</script>
