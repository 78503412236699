<template>
  <views>
    <div class="is-column prospection-pipe is-full-width">
      <CompaniesTabs>
        <ProspectionPipeFilters class="prospection-pipe-coach__filters mr-auto" />
      </CompaniesTabs>
      <ViewsContainer>
        <div class="prospection-pipe__body is-justify-content-center is-relative">
          <div v-if="isLoadingSteps" class="prospection-pipe__loader is-absolute">
            <BcSpinner class="prospection-pipe__spinner" size="large" />
          </div>
          <div class="prospection-pipe__container">
            <div class="prospection-pipe__board">
              <div
                v-for="step in steps"
                :key="step.status"
                class="prospection-pipe__wrapper">
                <ProspectionPipeColumn
                  :step="step"
                  class="prospection-pipe__step" />
              </div>
            </div>
          </div>
        </div>
      </ViewsContainer>
      <router-view />
    </div>
  </views>
</template>

<script setup lang="ts">
import Views from '@/components/Views/Views.vue';
import ProspectionPipeFilters from '@/components/ProspectionPipe/ProspectionPipeFilters.vue';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
import ProspectionPipeColumn from '@/components/ProspectionPipe/ProspectionPipeColumn.vue';
import ViewsContainer from '@/components/Views/ViewsContainer.vue';
import CompaniesTabs from '@/views/Tabs/CompaniesTabs.vue';
import { ref, computed, watch } from 'vue';
import { useStore } from '@/store';


const store = useStore();

const isLoadingSteps = ref(true);
const steps = ref<{name: string, status: any, category: any}[]>([]);

const configurationProspection = computed(() => store.getters['user/configurationProspection']);

watch(
  () => configurationProspection.value,
  (from, to) => {
    if (JSON.stringify(from) === JSON.stringify(to)) return;
    isLoadingSteps.value = true;
    steps.value = configurationProspection.value?.categories?.flatMap(category =>
      category?.steps?.map(step => ({
        name: step.name,
        status: step.key,
        category: category.key,
      }))
    );
    isLoadingSteps.value = false;
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
$border: 1px solid rgba($color-primary, 0.3);

.prospection-pipe {
  flex-direction: column;
  width: 100%;

  &__body {
    height: calc(100vh - 180px);
    border-radius: $border-radius-s;
  }

  &__filters {
    z-index: 1;
  }

  &__loader {
    margin: auto;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba($color-secondary, 0.3);
    z-index: 2;
  }

  &__spinner {
    z-index: 3;
  }

  &__container {
    flex-grow: 1;
    width: 100%;
    justify-content: space-between;
    overflow: auto;
  }

  &__board {
    border: $border;
    border-radius: $border-radius-s;
    flex-shrink: 0;
    overflow: hidden;
  }

  &__wrapper {
    position: relative;
    flex-direction: column;
    min-width: 300px;
    max-width: 300px;
    border-right: $border;

    &:last-of-type {
      border-right: 0;
    }
  }

  &__step {
    flex-grow: 1;
    height: 100%;
    max-width: 300px;
  }
}
</style>

