<template>
  <div>
    <BcDropdown
      :close-dropdown-at-select="true"
      :is-hoverable="true"
      :is-scrollable="false"
      :options="laterOptions"
      class="reminders-buttons-later__dropdown is-expanded"
      position="bottom-left">
      <template #label>
        <BcButton
          class="reminders-buttons-later__button"
          color="warning-dark"
          data-reminders-later-button
          icon-left="clock"
          size="large" />
      </template>
      <template #option="{ option }">
        <v-date-picker
          v-if="option.code === 'customNbDays'"
          v-model="customDate"
          :locale="locale"
          :min-date="minDate"
          :popover="{ visibility: 'hover', positionFixed: true, placement: 'left' }"
          class="w-full">
          <template #default="{ togglePopover }">
            <BcDropdownItem
              :text="option.text"
              class="reminders-buttons-later__dropdown-item"
              @click.native="
                () => {
                  togglePopover();
                  emitLater(option);
                }
              " />
            <BcDropdownItem
              :text="option.text"
              class="reminders-buttons-later__dropdown-item"
              @click.native="
                () => {
                  togglePopover();
                  emitLater(option);
                }
              " />
          </template>
        </v-date-picker>
        <BcDropdownItem
          v-else
          :text="option.text"
          class="reminders-buttons-later__dropdown-item"
          @click.native="emitLater(option)" />
      </template>
    </BcDropdown>
  </div>
</template>
<script setup lang="ts">
import moment from 'moment/min/moment-with-locales';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import { useI18n } from '@/i18n/i18n';
import { reminderOptions } from '@/macros/reminders/reminderOptions';
import { ref, watch } from 'vue';

const emit = defineEmits<{
  (e: 'postpone', value: { nbDays: number }): void;
}>();

const { t, locale } = useI18n();

const customDate = ref(undefined);
const minDate = ref(undefined);
const laterOptions = ref(
  reminderOptions.map(option => ({
    ...option,
    text: t(option.localeString),
  })),
);

watch(customDate, date => {
  const nbDays = moment(date).diff(new Date(), 'days') + 1;
  emitLater({ nbDays });
});

function emitLater(option) {
  if (option.code === 'customNbDays') {
    return;
  }
  emit('postpone', { nbDays: option.nbDays || 90 });
}
</script>
<style lang="scss" scoped>
.reminders-buttons-later {
  &__button {
    margin-left: 5px;
    margin-right: 5px;
    border-radius: $border-radius-rounded;
    width: 36px;
    height: 36px;
  }

  &__dropdown-item {
    &:hover,
    &--focus {
      background-color: $color-secondary;
    }
  }
}
</style>
