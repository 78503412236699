<script lang="ts" setup>
  interface Props {
    modelValue: number;
    min?: number;
    max?: number;
    unitLabel: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    min: 0,
    max: 999,
  });

  const emit = defineEmits<{
    (e: 'update:modelValue', value: number): void
  }>();

  const increment = () => {
    if (props.modelValue < props.max) {
      emit('update:modelValue', props.modelValue + 1);
    }
  };

  const decrement = () => {
    if (props.modelValue > props.min) {
      emit('update:modelValue', props.modelValue - 1);
    }
  };
</script>

<template>
  <div class="relative inline-flex items-center text-blue-800 hover:text-blue-500">
    <div
      class="flex max-h-[40px] w-full items-center justify-between rounded border border-blue-200 bg-white p-[5px]"
    >
      <span class="px-[20px]">
        {{ modelValue }} {{ unitLabel }}
      </span>
      <div class="ml-2 flex flex-col gap-1">
        <button
          class="flex h-[13px] w-[32px] items-center justify-center rounded-t bg-blue-100 text-blue-400 hover:text-blue-800 focus:outline-none"
          @click="increment"
        >
          <i class="icon-chevron-up text-[10px]"/>
        </button>
        <button
          class="flex h-[12px] w-[32px] items-center justify-center rounded-b bg-blue-100 text-blue-400 hover:text-blue-800 focus:outline-none"
          @click="decrement"
        >
          <i class="icon-chevron-down text-[10px]"/>
        </button>
      </div>
    </div>
  </div>
</template>
