<template>
  <div class="panel-process-create-wrapper is-column">
    <div v-if="showCompanies" class="panel-process-create-wrapper__wrapper card">
      <bc-button
        v-if="index > 0"
        :bc-background-color-opacity="0"
        :bc-text-color-opacity="0.5"
        :bc-text-color-opacity-hover="1"
        bc-box-shadow-hover="none"
        bc-icon="cross"
        bc-icon-size="16px"
        bc-text-color="blue-dark-cello"
        bc-text-color-hover="blue-dark-cello"
        class="panel-process-create-wrapper__icon"
        @click.stop="removeProcess(index)">
      </bc-button>
      <panel-process-create-company
        :selected-company="process._company"
        @company-selected="setProcessCompany(index, $event)"
        @cancel-hunt="cancelHunt(index)"
      >
      </panel-process-create-company>
    </div>
    <div class="panel-process-create-wrapper__wrapper card">
      <panel-process-create-custom-job
        :processes="processes"
        :selected-company="process._company"
        :selected-custom-job="process._customJob"
        @on-selected="setProcessCustomJob(index, $event)"
        @cancel-selected="setProcessCustomJob(index, {})"
      >
      </panel-process-create-custom-job>
    </div>
    <div class="panel-process-create-wrapper__wrapper card">
      <panel-process-create-coder
        :selected-coder="process._coder"
        :selected-coders="selectedCoders"
        @coder-selected="setProcessCoder(index, $event)"
        @on-close="removeCoder(index)"
      >
      </panel-process-create-coder>
    </div>
  </div>
</template>

<script>
  // import PanelProcessCreateIntroducing from '@/components/Panel/PanelProcess/PanelProcessCreate/PanelProcessCreateIntroducing';
  import PanelProcessCreateCustomJob
    from '@/components/Panel/PanelProcess/PanelProcessCreate/PanelProcessCreateCustomJob';
  import PanelProcessCreateCompany
    from '@/components/Panel/PanelProcess/PanelProcessCreate/PanelProcessCreateCompany';
  import BcButton from '@/legacy/ui-kit/components/Button/BcButton';
  import PanelProcessCreateCoder
    from '@/components/Panel/PanelProcess/PanelProcessCreate/PanelProcessCreateCoder';

  export default {
    name: 'panel-process-create-wrapper',
    components: {
      PanelProcessCreateCoder,
      // PanelProcessCreateIntroducing,
      PanelProcessCreateCustomJob,
      PanelProcessCreateCompany,
      BcButton,
    },
    props: {
      process: {
        type: Object,
        default: () => ({}),
      },
      processes: {
        type: Array,
        default: () => [],
      },
      index: {
        type: Number,
        default: 0,
      },
      selectedCoders: {
        type: Array,
        default: () => [],
        required: false,
      },
      showCompanies: {
        type: Boolean,
        default: true,
      },
    },
    mounted() {
      if (this.$route.params.id) {
        this.readonly.coder = true;
      }
    },
    data() {
      return {
        readonly: {
          coder: false,
        }
      }
    },
    methods: {
      removeProcess(index) {
        this.$emit('process-removed', index);
      },
      setProcessCompany(index, company) {
        this.$emit('process-company-set', { index, company });
      },
      setProcessHunt(index, hunt) {
        this.$emit('process-hunt-set', { index, hunt });
      },
      setProcessCustomJob(index, customJob) {
        this.$emit('process-custom-job-set', { index, customJob });
      },
      setProcessStatus(index, status) {
        this.$emit('process-status-set', { index, status });
      },
      setProcessCoder(index, coder) {
        this.$emit('process-coder-set', { index, coder });
      },
      removeCoder(index) {
        this.$emit('process-coder-set', { index, coder: {} });
      },
      cancelHunt(index) {
        this.setProcessHunt(index, {});
        this.setProcessCompany(index, {});
      }
    },
  };
</script>

<style lang="scss" scoped>
  .panel-process-create-wrapper {

    &__wrapper {
      padding: 15px 20px;
      border-radius: 2px;
      background: $color-neutre-4;
      min-width: 500px;
      flex-direction: column;
      margin-bottom: 30px;
      position: relative;
    }


    &__icon {
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
</style>
