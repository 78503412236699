<template>
  <views class="failure-coders col-xs-12 is-align-items-center">
    <candidates-failed-tabs>
      <filters-dropdown-coach
        :with-inactive="true"
        :has-none-option="true"
        :selected-coach-id="selectedCoachId"
        class="dashboard-modules-activity-filters__filter"
        @update:selected-coach-id="handleCoachSelection">
      </filters-dropdown-coach>
    </candidates-failed-tabs>
    <views-container>
      <router-view></router-view>
      <coders-empty
        v-if="isLoaded && cards.length === 0"
        class="failure-coders__empty"
        :title="$t('hunt.candidates-in-failure-empty')">
      </coders-empty>
      <div
        v-else
        class="is-column">
        <bc-spinner
          v-if="!isLoaded"
          class="failure-coders__spinner">
        </bc-spinner>
        <div
          v-else
          class="is-align-items-center is-column">
          <coders-grid
            :is-checked="isAllCardsSelected"
            class="hidden-xs"
            @checked="selectAllCards">
          </coders-grid>
          <card-list
            ref="cardList"
            :cards="cards"
            :is-loaded="true"
            class="pagination__card-list">
            <template #card="{ card }">
              <card
                :card-content="card"
                card-type="coders">
                <card-coders
                  :card-content="card"
                  :is-selected="getIsSelectedStatus(card._id)"
                  context="search-dbcoders"
                  @checked="setSelectedCards($event, card)">
                </card-coders>
              </card>
            </template>
          </card-list>
        </div>
        <bc-pagination
          :current.sync="currentPage"
          :total="queryCount"
          class="failure-coders__pagination">
        </bc-pagination>
        <multiselect-footer
          v-if="selectedCards.length"
          class="failure-coders__footer is-fixed is-bottom is-left is-full-width is-justify-content-center is-align-items-center is-column">
        </multiselect-footer>
      </div>
    </views-container>
  </views>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import moment from 'moment/min/moment-with-locales';

  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import BcPagination from '@/ui-kit/components/BcPagination';
  import CardList from '@/components/CardList/CardList';
  import CodersGrid from '@/components/Coders/CodersGrid';
  import CardCoders from '@/components/Card/CardCoders';
  import Card from '@/components/Card/Card';
  import MultiselectFooter from '@/components/Multiselect/MultiselectFooterLegacy';
  import MultiselectMixin from '@/mixins/Multiselect/multiselect';
  import Views from '@/components/Views/Views';
  import ViewsContainer from '@/components/Views/ViewsContainer';
  import CodersEmpty from '@/components/Coders/CodersEmpty';
  import locations_helpers from '@/common-old/utils/locations_helpers';
  import CandidatesFailedTabs from '@/views/Tabs/CandidatesFailedTabs';
  import FiltersDropdownCoach from '@/components/Filters/FiltersDropdown/FiltersDropdownCoach';

  export default {
    name: 'failure-coders',
    components: {
      CodersEmpty,
      Views,
      ViewsContainer,
      MultiselectFooter,
      Card,
      CardCoders,
      CodersGrid,
      BcSpinner,
      BcPagination,
      CardList,
      CandidatesFailedTabs,
      FiltersDropdownCoach,
    },
    mixins: [MultiselectMixin],
    data() {
      return {
        isLoaded: true,
        currentPage: parseInt(this.$route.query.page) || 1,
      };
    },
    computed: {
      ...mapState({
        cards: state => state.card.cards,
        cardsToRemoveFromList: state => state.card.cardsToRemoveFromList,
        queryCount: state => state.card.count,
        coaches: state => state.coaches?.coaches,
      }),
      selectedCoachId() {
        return this.$route.query.coachId;
      },
      parameters() {
        const params = {
          page: this.$route.query.page ? this.$route.query.page : 1,
          ...this.$route.query['job-id'] && { jobId: this.$route.query['job-id'] },
          ...this.$route.query.status !== 'all' && { status: this.$route.query.status },
          ...this.$route.query.technos && { technos: this.$route.query.technos },
          ...this.$route.query.search && { query: this.$route.query.search },
          ...this.$route.query.contracts && this.$route.query.contracts !== 'all' && { contractCurrent: this.$route.query.contracts },
          ...this.$route.query.experiences && { experiences: this.$route.query.experiences },
          ...this.$route.query.roles && { roles: this.$route.query.roles },
          ...this.$route.query.professions && { professions: this.$route.query.professions },
          ...this.$route.query.locations && { locations: JSON.stringify(locations_helpers.getLocationsId(this.$route.query.locations)) },
          ...this.$route.query.distance && { distance: this.$route.query.distance },
          ...this.$route.query.remotes && { remotes: this.$route.query.remotes },
          ...this.$route.query.phone && { phone: true },
          ...this.$route.query.cv && { cv: true },
          ...this.$route.query.email && { email: true },
          ...this.$route.query.linkedin && { linkedin: true },
          ...this.$route.query.rating && { lastRating: Number(this.$route.query.rating) },
          ...this.$route.query.coachId && { coachId: this.$route.query.coachId },
          ...this.$route.query['last-coach-activity-from'] && { lastCoachActivityFrom: moment(this.$route.query['last-coach-activity-from'], 'D-MM-YYYY').toDate() },
          ...this.$route.query['last-coach-activity-to'] && { lastCoachActivityTo: moment(this.$route.query['last-coach-activity-to'], 'D-MM-YYYY').toDate() },
          salaryMin: this.$route.query['salary-min'] && Number(this.$route.query['salary-min']) > this.salaryRange.salaryMin ? this.$route.query['salary-min'] : undefined,
          salaryMax: this.$route.query['salary-max'] && Number(this.$route.query['salary-max']) < this.salaryRange.salaryMax ? this.$route.query['salary-max'] : undefined,
        };

        const customFieldsQuery = Object.keys(this.$route.query).filter((key) => key.startsWith('cf-'));
        if (customFieldsQuery.length) {
          const obj = {};
          customFieldsQuery.forEach(key => {
            obj[key.split('cf-')[1]] = this.$route.query[key];
          });
          params['customFields'] = JSON.stringify(obj);
        }

        return params;
      },
    },
    created() {
      this.setCount(0);
      this.getCoders();
    },
    beforeDestroy() {
      this.resetSelectedCards();
    },
    watch: {
      $route: {
        handler(to = {}, from = {}) {
          // const isDifferentQueries = Object.keys(to.query).some(key => to.query[key] !== from.query[key]);
          const isOpeningPanel = Boolean(!(from.params || {}).id && (to.params || {}).id);
          const isClosingPanel = Boolean((from.params || {}).id && !(to.params || {}).id);
          const isInPanel = Boolean((from.params || {}).id && (to.params || {}).id);
          const isEditProcess = (from.query || {}).type === 'process'
          const pageChange = from.query.page !== to.query.page;

          if (!isOpeningPanel && !isClosingPanel && (!isInPanel || (isInPanel && pageChange))) {
            this.getCoders();
            this.currentPage = parseInt(to.query.page) || 1;
          }

          if (isEditProcess) {
            this.getCoders();
          }

          if (isClosingPanel && this.cardsToRemoveFromList.length) {
            const cardsToRemoveFromList = [...this.cardsToRemoveFromList];
            for (const cardToRemoveFromList of cardsToRemoveFromList) {
              this.removeCardFromList(cardToRemoveFromList);
            }
          }
        },
        deep: true,
      },
      currentPage(newPage) {
        this.$router.push({ ...this.$route, query: { ...this.$route.query, page: newPage } });

        this.$store.dispatch('setSelectedCards', []);
      },
    },
    methods: {
      ...mapActions([
        'setEditedProfile',
        'resetCards',
        'getCards',
        'setCount',
        'removeCardFromList',
      ]),
      async getCoders() {
        this.isLoaded = false;

        try {
          this.resetCards();
          await this.getCards({
            page: this.$route.query.page,
            call: 'coders-failure/lookup',
            parameters: this.parameters,
            responseKeyName: 'coders',
          });
          this.isLoaded = true;
        } catch (error) {
          this.$emit('error', error);

          this.isLoaded = true;
        }
      },
      handleCoachSelection(coachId) {
        this.$router.push({
          query: {
            ...this.$route.query,
            coachId: coachId,
          },
        });
      }
    },
  };
</script>

<style lang="scss" scoped>
  .failure-coders {
    @include page_grid();

    &__title {
      min-height: 30px;
    }

    &__subtitle {
      position: relative;
      top: 1px;
      color: #3D5476;
    }

    &__searchbar {
      margin-bottom: 20px;
    }

    &__filters {
      margin-bottom: 20px;
    }

    &__pagination {
      margin: 20px 0;
    }

    &__spinner {
      margin: 100px 0;
    }

    &__card-list {
      margin-bottom: 100px;
    }

    &__spinner, &__pagination, &__card-list {
      justify-content: center;
    }
  }
</style>
