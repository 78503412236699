<template>
  <div class="app-bar-avatar">
    <bc-menu
      :show="showMenu"
      position="left"
      @click="showMenu = !showMenu"
      :contentStyle="contentStyle"
    >
      <bc-avatar
        ref="coach-avatar"
        :key="profile._id"
        :placeholder="userInitials"
        :src="profile.pictureUrl"
        class="app-bar-avatar__avatar is-unshrink"
        :class="{ 'app-bar-avatar__avatar--active': showMenu }">
      </bc-avatar>
      <div
        class="app-bar-avatar__wrapper is-column ml-2 is-unshrink is-flex is-justify-content-center">
        <p class="is-full-width font-weight-bold font-size-s is-ellipsis is-text-align-left">
          {{ profile.firstName }} {{ profile.lastName }}
        </p>
        <p v-if="profile.job" class="is-full-width font-size-xs is-ellipsis is-text-align-left">
          {{ jobLabel }}
        </p>
      </div>
      <template #content>
        <div class="app-bar-avatar__content">
          <div class="app-bar-avatar__account">
            <div>
              <bc-avatar
                :key="profile._id"
                :placeholder="userInitials"
                :src="profile.pictureUrl"
                size="s"
                class="app-bar-avatar__picture is-unshrink">
              </bc-avatar>
              <div
                class="app-bar-avatar__wrapper is-column ml-2 is-unshrink is-flex is-justify-content-center">
                <p
                  class="is-full-width font-weight-bold font-size-s is-ellipsis is-text-align-left">
                  {{ profile.firstName }} {{ profile.lastName }}
                </p>
                <p class="is-full-width is-ellipsis font-size-xs is-text-align-left">
                  {{ profile.email }}
                </p>
              </div>
            </div>
            <div>
              <router-link :to="profileLink" class="app-bar-avatar__account-btn">
                {{ $t('generics.see-my-account') }}
              </router-link>
            </div>
          </div>

          <div class="app-bar-avatar__items">
            <router-link
              to="/configuration/team"
              class="app-bar-avatar__link"
              @click.native="showMenu = false"
            >
              {{ $t('generics.configuration') }}
            </router-link>
            <router-link
              :to="{ path: '/history-actions', query: { id: profileId }}"
              class="app-bar-avatar__link"
              @click.native="showMenu = false"
            >
              {{ $t('activities.history-of-my-actions-cap') }}
            </router-link>
          </div>
          <div class="app-bar-avatar__disconnect">
            <button @click="logout">
              {{ $t('generics.log-out') }}
            </button>
          </div>
        </div>
      </template>
    </bc-menu>
  </div>
</template>

<script>
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import BcMenu from '@/ui-kit/components/BcMenu';
  import { removeToken } from '@/managers/token/useCases/removeToken';

  export default {
    name: 'app-bar-avatar',
    components: {
      BcAvatar,
      BcMenu,
    },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
      options: {
        type: Array,
        default: () => [],
      },
      isHover: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      const ref = this.$refs['coach-avatar'];
      if (ref) {
        this.coachAvatarPosition = ref.$el.getClientRects();
      }
    },
    data() {
      return {
        showMenu: false,
        coachAvatarPosition: {},
      };
    },
    computed: {
      contentStyle() {
        return { left: `-${this.coachAvatarPosition.width || 47}px`, top: `calc(100% + 10px)` };
      },
      userInitials() {
        return `${this.profile.firstName?.charAt(0)}${this.profile.lastName?.charAt(0)}`;
      },
      profileLink() {
        return {
          path: `${this.$route.path}/profile`,
          query: { ...this.$route.query, type: 'edit-coach' },
        };
      },
      profileId() {
        return this.$store.state.user.profile._id;
      },
      jobLabel() {
        return this.profile.job.replace('Membre de', '');
      },
    },
    methods: {
      async logout() {
        removeToken();
        await this.$store.dispatch('user/cleanProfile');
        await this.$router.replace('/auth/logout');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-bar-avatar {
    flex: 1;

    &__container {
      position: relative;
    }

    &__content {
      width: 250px;
      flex-direction: column;
    }

    &__items {
      flex-direction: column;
      border-bottom: 1px solid $color-blue-medium;

      & > a {
        height: 39px;
        align-items: center;
        padding: 10px 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $color-tertiary;

        &:hover {
          background: $color-blue-light;
          text-decoration: none;
          color: $color-primary;
        }
      }
    }

    &__disconnect {
      & > button {
        width: 100%;
        height: 39px;
        align-items: center;
        padding: 10px 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $color-red-mandy;

        &:hover {
          text-decoration: none;
          background: rgba(230, 82, 90, 0.05);
        }
      }
    }

    &__account {
      flex-direction: column;
      padding: 20px;
      border-bottom: 1px solid $color-blue-medium;

      &-btn {
        display: flex;
        width: 210px;
        height: 36px;
        background: $color-neutre-4;
        border: 1px solid $color-blue-medium;
        border-radius: 30px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: $color-primary;
        align-items: center;
        justify-content: center;
        margin-top: 15px;

        &:hover {
          background: $color-blue-light;
        }
      }
    }

    &__wrapper {
      flex-grow: 1;
      max-width: calc(100% - 65px);
    }

    &__avatar {
      height: 46px;
      width: 46px;
      border: 1px solid transparent;

      &--active {
        border: 1px solid #FFFFFF;
      }
    }

    &__picture {
      width: 30px;
      height: 30px;
    }

    &__trigger {
      padding: 10px 15px 10px 10px;
      transition: 0.3s;
      align-items: stretch;

      &--active {
        background: $color-white;
      }
    }

    &__options {
      width: 100%;
      padding: 10px 0 0 55px;
      font-size: $font-size-s;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 1px;
        margin: 0 auto;
        height: calc(100% - 20px);
        top: 15px;
        background: rgba($color-secondary, 0.4);
        left: 35px;
        z-index: -1;
      }
    }

    &__link {
      color: $color-secondary;
      padding: 5px;
      cursor: pointer;

      &--error {
        color: $color-error;
      }

      overflow-wrap: normal;
    }
  }
</style>
