<script lang="ts" setup>
  import EmailVariablesList from '@/components/Email/EmailVariablesList.vue';
  import { computed } from 'vue';
  import { capitalize, transformNewlinesToParagraphsTag } from '@/utils/stringTransform';
  import { useLinkedinTemplatesStore } from '@/store/pinia/linkedinTemplates';
  import CampaignsTemplatesDropdown
    from '@/components/Campaigns/CampaignsTemplates/CampaignsTemplatesDropdown.vue';
  import { useStore } from '@/store';
  import { useQuery } from '@tanstack/vue-query';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import CampaignsConditionsDropdown
    from '@/components/Campaigns/CampaignsConditions/CampaignsConditionsDropdown.vue';
  import {
    CampaignNextCondition,
    CampaignNextConditionKey,
    CampaignStep,
  } from '@/components/Campaigns/CampaignsTypes';
  import { LinkedinTemplate } from '@/domains/models/LinkedinTemplate';
  import MvTextArea from '@/ui-kit/components/MvTextArea/MvTextArea.vue';
  import {
    useLinkedinTemplateVariables,
  } from '@/components/Campaigns/composables/useLinkedinTemplateVariables';
  import { useI18n } from '@/i18n/i18n';
  import BcInput from '@/ui-kit/components/BcInput/BcInput.vue';

  const { t } = useI18n();
  const templatesStore = useLinkedinTemplatesStore();
  const store = useStore();
  const profile = computed(() => store.state.user.profile);

  const props = defineProps<{
    step: CampaignStep
  }>();

  const emit = defineEmits<{
    (e: 'update-step', value: CampaignStep): void
  }>();

  const { linkedinTemplateVariables } = useLinkedinTemplateVariables({ restrictToFirstAndLastName: true });
  const availableConditions: CampaignNextConditionKey[] = ['DELAY', 'PROFILE_VISITED', 'IMMEDIATELY_AFTER_STEP'];
  // const availableConditions: CampaignNextConditionKey[] = ['MESSAGE_RECEIVED', 'DELAY', 'PROFILE_VISITED', 'IMMEDIATELY_AFTER_STEP'];

  const isError = computed(() => text.value.length > 200);

  const templates = computed(() => templatesStore.items);
  const selectedTemplate = computed(() => templates.value.find(template => template.body === text.value && template.subject === object.value));

  const text = computed(() => props.step.data?.message ?? '');
  const object = computed({
    get() {
      return props.step.data?.object ?? '';
    },
    set(value: string) {
      handleUpdateObject(value);
    },
  });

  useQuery({
    queryKey: ['fetch-linkedin-templates'],
    queryFn: async() => {
      await templatesStore.fetchLinkedinTemplates({ coachId: profile.value._id, all: null });

      return [];
    },
  });

  useQuery({
    queryKey: ['fetch-email-variables'],
    queryFn: async() => {
      return store.dispatch('emails/getTemplatesVariables');
    },
  });


  function onSelectTemplate(template: LinkedinTemplate) {
    emit('update-step', {
      ...props.step,
      data: {
        ...props.step.data ?? {},
        object: template.subject,
        message: template.body,
      },
    });
  }

  function handleUpdateText(text: string) {
    emit('update-step', {
      ...props.step,
      data: {
        ...props.step.data ?? {},
        message: text,
      },
    });
  }

  function handleUpdateObject(object: string) {
    emit('update-step', {
      ...props.step,
      data: {
        ...props.step.data ?? {},
        object,
      },
    });
  }

  function handleUpdateCondition({ condition, index }: {
    condition: CampaignNextCondition,
    index: number
  }) {
    const nextConditions = [...props.step.nextConditions];
    nextConditions[index] = condition;

    emit('update-step', {
      ...props.step,
      nextConditions,
    });
  }

  function handleAddCondition(condition: CampaignNextCondition) {
    emit('update-step', {
      ...props.step,
      nextConditions: [...props.step.nextConditions, condition],
    });
  }

  function handleRemoveCondition(condition: CampaignNextCondition) {
    emit('update-step', {
      ...props.step,
      nextConditions: props.step.nextConditions.filter(selectedCondition => selectedCondition.key !== condition.key),
    });
  }
</script>

<template>
  <div class="flex max-w-[600px] flex-col">
    <div class="mb-3.5 flex gap-2.5">
      <i
        class="icon-send flex size-[30px] items-center justify-center rounded-full bg-[#dbe2ff]"/>
      <h1 class="text-xl text-blue-800">{{ $t('campaigns.steps.linkedin-inmail-recruiter') }}</h1>
    </div>
    <div
      class="mb-[20px] flex w-full max-w-[600px] gap-2.5 rounded-xl bg-orange-100 px-[20px] py-3.5">
      <i class="icon-chat-info text-orange-500"/>
      <p class="text-sm text-orange-500">{{
          $t('campaigns.linkedin-invitation-warning-message')
        }}</p>
    </div>
    <EmailVariablesList
      :label="capitalize($t('generics.usable-variables-resource', {resource: $t('generics.on-resource', {resource: 'Linkedin'}) }))"
      :variables="linkedinTemplateVariables"
      class="mb-2.5"
    />
    <div class="flex flex-col gap-2.5">
      <div class="flex items-end">
        <label
          class="flex flex-col items-start justify-start gap-1 pl-2.5"
          for="object">
        <span class="text-xs text-blue-400">{{
            capitalize($t('generics.object-of-resource', { resource: 'message' }))
          }}</span>
        </label>
        <CampaignsTemplatesDropdown
          :profile="profile"
          :selected-template="selectedTemplate"
          :templates-options="templates"
          class="ml-auto"
          @select-template="onSelectTemplate"/>
      </div>
      <BcInput
        v-model="object"
        :label-placeholder="false"
        class="!mt-0 w-full"
        name="object"/>
      <div class="flex w-full items-end justify-between">
        <label
          class="pl-2.5 text-xs text-blue-400"
          for="linkedin-invitation-message">
          {{ $t('email.body') }}
        </label>
      </div>
      <MvTextArea
        :value="text"
        :show-error="isError"
        :error="t('linkedin.form-validation.body-max-length', { max: 1900 })"
        :max-length="1900"
        @input="handleUpdateText"
      />
    </div>
    <div class="mt-[20px] flex">
      <CampaignsConditionsDropdown
        :available-conditions="availableConditions"
        :selected-conditions="step.nextConditions"
        @add-condition="handleAddCondition"
        @update-condition="handleUpdateCondition"
        @remove-condition="handleRemoveCondition"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
