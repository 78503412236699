<script lang="ts" setup>
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { CampaignTemplate } from '@/domains/models/Campaign';
  import { usePanelStore } from '@/store/pinia/panel';
  import CampaignsCreateView from '@/components/Campaigns/CampaignsCreate/CampaignsCreateView.vue';

  const props = defineProps<{
    templates: CampaignTemplate[];
    selectedTemplate: CampaignTemplate;
  }>();

  const panelStore = usePanelStore();

  const emit = defineEmits<{
    (e: 'select', value: string): void;
  }>();

  function setSelectedTemplate(value: string) {
    emit('select', value);
  }

  function goStartCampaign() {
    panelStore.openModal(CampaignsCreateView, {
      selectedTemplate: props.selectedTemplate,
    });
  }
</script>

<template>
  <div class="flex flex-col gap-2.5 px-[20px] py-[30px]">
    <h3 class="text-xl font-bold first-letter:capitalize">{{ $t('campaigns.from-a-template') }}</h3>
    <div class="flex flex-wrap justify-center gap-5">
      <button
        v-for="template in templates"
        :key="template.key"
        :class="{ 'border-blue-500': selectedTemplate?.key === template.key }"
        :name="template.name"
        class="flex flex-col overflow-hidden rounded border border-[#E1E4ED]"
        type="button"
        @click="setSelectedTemplate(template.key)">
        <div class="relative overflow-hidden border-b border-[#E1E4ED] bg-neutral-200">
          <div class="h-[190px] w-[280px]">
            <img
              :src="template.pictureUrl"
              alt="template image"
              class="absolute -inset-x-[2px] -top-[2px] h-[194px] !w-[290px] max-w-[284px]"/>
          </div>
        </div>
        <p
          :class="{
            'text-blue-500': selectedTemplate?.key === template.key,
            'text-blue-800': selectedTemplate?.key !== template.key,
          }"
          class="flex items-center justify-center p-4 font-bold">
          {{ template.name }}
        </p>
      </button>
    </div>
    <bc-button
      :disabled="!selectedTemplate?.key"
      class="mt-2.5 first-letter:capitalize"
      icon-left="plus"
      @click.native="goStartCampaign">
      <p class="first-letter:capitalize">{{ $t('campaigns.start-the-campaign') }}</p>
    </bc-button>
  </div>
</template>

<style lang="scss" scoped>

</style>
