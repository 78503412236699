import { computed, ref } from 'vue';
import { linkedinMacros } from '@/macros/linkedin';
import { 
  useLinkedinBaseForm, 
  type BaseForm, 
  type LinkedinTemplateTypeEnum 
} from './useLinkedinBaseForm';

export type LinkedinTemplateForm = BaseForm;

interface TemplateVariable {
  _id: string;
  name: string;
  slug: string;
  source: string;
  createdAt: string;
  updatedAt: string;
  approximateLength?: number;
}

const DEFAULT_VARIABLE_LENGTH = 15;

function calculateApproximateLength(text: string, variables: TemplateVariable[]): number {
  let approximateLength = text.length;
  
  // Find all variables in the text using regex
  const matches = text.match(/{{[^}]+}}/g) || [];
  
  for (const match of matches) {
    const variableName = match;
    const variable = variables.find(v => v.name === variableName);
    
    // Subtract the length of the variable placeholder
    approximateLength -= match.length;
    
    // Add the approximate length of the variable value
    approximateLength += variable?.approximateLength || DEFAULT_VARIABLE_LENGTH;
  }
  
  return approximateLength;
}

export function useLinkedinTemplateForm(
  initialForm?: Partial<LinkedinTemplateForm>,
  templateVariables: TemplateVariable[] = []
) {
  const form = ref<LinkedinTemplateForm>({
    name: '',
    type: linkedinMacros.templates.type.message,
    subject: '',
    body: '',
    attachmentsToUpload: [],
    ...initialForm
  });

  const approximateBodyLength = computed(() => calculateApproximateLength(form.value.body, templateVariables));
  const approximateSubjectLength = computed(() => calculateApproximateLength(form.value.subject, templateVariables));

  const { 
    currentRules,
    fieldErrors,
    fieldWarnings,
    errors,
  } = useLinkedinBaseForm(
    form,
    approximateBodyLength,
    approximateSubjectLength
  );

  // A template is possibly valid if it has no errors
  // Length warnings are okay since they're based on approximations
  const isPossiblyValid = computed(() => errors.value.length === 0);

  return {
    form,
    isPossiblyValid,
    fieldErrors,
    fieldWarnings,
    currentRules,
    approximateBodyLength,
    approximateSubjectLength,
  };
} 