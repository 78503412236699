<script setup>
import { usePanelNavigation } from '@/composables/usePanelNavigation';
import PanelOpportunityHeader from '@/components/Panel/PanelOpportunity/PanelOpportunityHeader.vue';
import PanelOpportunityNotes from '@/components/Panel/PanelOpportunity/PanelOpportunityNotes.vue';
import PanelOpportunityCreateForm from '@/components/Panel/PanelOpportunity/PanelOpportunityCreateForm.vue';

const { closePanel } = usePanelNavigation();
  const manageClosePanel = (event) => {
    if (event.target?.className === 'icon-close' || event.target?.className === 'overlay') {
      closePanel();
    }
  };
</script>

<template>
  <div
    v-click-outside="manageClosePanel"
    class="flex max-h-full shrink-0 grow flex-col items-center justify-center"
  >
    <PanelOpportunityHeader
      :is-creating="true"
      class="shrink-0"
    />
    <div class="flex max-h-[calc(100%-50px)] w-full grow">
      <div class="flex w-1/2 flex-col">
        <PanelOpportunityCreateForm/>
      </div>
      <div class="flex w-1/2 flex-col overflow-y-auto bg-neutral-100">
        <div class="absolute z-10 size-full bg-white/50"></div>
        <PanelOpportunityNotes/>
      </div>
    </div>
  </div>
</template>

