<template>
  <div class="panel-edit-avatar">
    <div class="panel-edit-avatar__wrapper is-relative">
      <TransitionFade>
        <BcSpinner v-if="isLoading" class="panel-edit-avatar__spinner" size="large" />
        <BcAvatar
          v-else
          :src="pictureUrl"
          alt="avatar"
          class="panel-edit-avatar__img border-radius-rounded"
          size="xl"
          data-test="avatar"
          @error="setAlternativeImg"
          @click.native="triggerUploadButton" />
      </TransitionFade>
      <input
        ref="uploadAvatar"
        accept="image/x-png,image/gif,image/jpeg"
        class="hidden"
        name="avatar"
        type="file"
        @change="uploadFile" />
      <BcButton
        class="panel-edit-avatar__button"
        data-upload-button
        icon-left="upload"
        @click.native="triggerUploadButton" />
    </div>
  </div>
</template>

<script setup lang="ts">
import TransitionFade from '@/components/UIKit/Transition/TransitionFade.vue';
import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
import upload from '@/api/upload';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
import { computed, ref, withDefaults, defineEmits, defineProps, defineExpose } from 'vue';
import { useStore } from '@/store';
import { useToast } from '@/ui-kit/components/BcToast';

const props = withDefaults(
  defineProps<{
    profile: any;
    editedProfile: any;
    isAvatarRequired?: boolean;
    isCompany?: boolean;
  }>(),
  {
    isAvatarRequired: false,
    isCompany: false,
  }
);

const emit = defineEmits<{
  (e: 'update-value', value: Record<string, any>): void;
}>();

const uploadAvatar = ref<HTMLInputElement | null>(null);
const isLoading = ref(false);
const store = useStore();
const toast = useToast();

const pictureUrl = computed({
  get() {
    const pictureUrlKey = props.isCompany ? 'logoUrl' : 'pictureUrl';

    const referenceProfile =
      typeof props.editedProfile[pictureUrlKey] === 'string' &&
      props.editedProfile[pictureUrlKey] !== props.profile[pictureUrlKey]
        ? props.editedProfile[pictureUrlKey]
        : props.profile[pictureUrlKey];

    return (referenceProfile || '').trim();
  },
  set(value) {
    const pictureUrlKey = props.isCompany ? 'logoUrl' : 'pictureUrl';

    emit('update-value', {
      [pictureUrlKey]: value,
    });
  },
});

function triggerUploadButton() {
  uploadAvatar.value?.click();
}

async function uploadFile(e: Event) {
  try {
    isLoading.value = true;
    const file = (e.target as HTMLInputElement).files?.[0];
    if (!file) {
      throw new Error('No file found');
    }

    const dataToSend = new FormData();

    dataToSend.append('file', file);
    dataToSend.append('uploadType', 'picture');

    const response = await upload.upload({
      id: props.profile._id ? props.profile._id : store.state.user.profile._id,
      file: dataToSend,
    });

    const key = props.isCompany ? 'logoUrl' : 'pictureUrl';

    emit('update-value', {
      [key]: response.data.fileUrl,
    });
  } catch (error) {
    toast.show({
      type: 'error',
      message: error?.response?.data?.message || error,
    });
  } finally {
    isLoading.value = false;
  }
}

function setAlternativeImg(event: Event) {
  (event.target as HTMLImageElement).src = props.isCompany
    ? require('@/assets/img/default-avatar-company.svg')
    : require('@/assets/img/default-avatar.svg');
}

defineExpose({ uploadFile, pictureUrl });
</script>

<style lang="scss" scoped>
.panel-edit-avatar {
  flex-direction: column;
  align-items: center;

  &__wrapper {
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__img {
    cursor: pointer;
    overflow: hidden;

    & :deep() {
      img {
        object-fit: cover;
      }
    }
  }

  &__button {
    position: absolute;
    right: -15px;
    bottom: -5px;
  }
}
</style>
