<template>
  <div
    ref="processDropdownRef" v-click-outside="handleClickOutside"
    class="process-dropdown relative">
    <label v-if="label" class="process-dropdown__label">
      {{ label }}
    </label>
    <button
      :class="[
      [isOpen ? '!bg-blue-100' : currentBackgroundColor],
      [isOpen ? '!text-blue-500' : currentColor],
      ...[value?.categoryKey ? 'px-[20px]' : 'px-2.5'],
    ]"
      class="flex w-full cursor-pointer items-center justify-between rounded-md border border-blue-200 py-1.5 text-sm text-blue-800"
      @click.prevent="handleClick">
      <span class="flex text-sm font-normal">
        <span :class="{ 'process-dropdown__icon--active': isOpen }" class=" flex items-center">
          <slot name="icon"></slot>
        </span>
        {{ currentSelectedLabel }}
      </span>
      <span class="ml-2">
        <i
          :class="{
          'icon-chevron-up': isOpen,
          'icon-chevron-down': !isOpen,
        }" class="process-dropdown__icon"></i>
      </span>
    </button>
    <portal v-if="isOpen" to="mv-select">
      <div
        :style="dropdownStyle"
        class="process-dropdown__items z-10 flex max-h-[400px] w-max flex-col overflow-auto bg-white">
        <slot name="header"></slot>
        <ul
          v-for="item in items" v-show="isOpen" :key="item.key"
          class="w-full border-b border-blue-200 pb-2.5 pt-2 last:pb-1"
          @mouseenter="isHoverItems = true"
          @mouseleave="isHoverItems = false">
          <p v-if="item.name" class="px-5 py-2 text-sm font-medium text-blue-800">
            {{ item.name }}
          </p>
          <li
            v-for="step in item.steps" :key="step.key" :class="[
            {
              'process-dropdown__selected': isSelected(step),
            },
            `${processCategoryColor[item.key]?.hoverBackgroundColor ??
            'hover:bg-blue-100'}`,
            `${processCategoryColor[item.key]?.hoverColor || 'hover:text-blue-500'}`,
          ]" class="cursor-pointer px-5 py-2 text-sm font-normal text-blue-800"
            @click="handleClickItem(step)">
            {{ step.name }}
          </li>
        </ul>
      </div>
    </portal>
  </div>
</template>

<script lang="ts" setup>
  import { ProcessCustomCategoriesEnum } from '@/macros/processesCustom/process';
  import { computed, defineEmits, defineProps, ref, watch } from 'vue';
  import { useWindowScroll, useWindowSize, watchDebounced } from '@vueuse/core';
  import {
    ConfigurationProcessCategory,
    ConfigurationProcessCategoryStep,
  } from '@/domains/models/Organization';

  const props = defineProps<{
    items: ConfigurationProcessCategory[];
    value: { name: string; categoryKey: string; key: string | undefined };
    label?: string;
    selectedLabel?: string;
    backgroundColor?: string;
    color?: string;
    activeTabs?: number[];
    parentHeight?: any;
    scrollPosition?: any;
  }>();

  const emit = defineEmits<{
    (e: 'select', value: any): void;
  }>();

  const processCategoryColor = {
    [ProcessCustomCategoriesEnum.beforeCandidatePresentation]: {
      color: 'text-blue-500',
      hoverColor: 'hover:text-blue-500',
      backgroundColor: 'bg-blue-100',
      hoverBackgroundColor: 'hover:bg-blue-100',
    },
    [ProcessCustomCategoriesEnum.beforeProcessRecruitment]: {
      color: 'text-purple-550',
      hoverColor: 'hover:text-purple-550',
      backgroundColor: 'bg-purple-50',
      hoverBackgroundColor: 'hover:bg-purple-50',
    },
    [ProcessCustomCategoriesEnum.afterProcessRecruitment]: {
      color: 'text-green-550',
      hoverColor: 'hover:text-green-550',
      backgroundColor: 'bg-green-50',
      hoverBackgroundColor: 'hover:bg-green-50',
    },
    failed: {
      color: 'text-red-500',
      hoverColor: 'hover:text-red-500',
      backgroundColor: 'bg-red-50',
      hoverBackgroundColor: 'hover:bg-red-50',
    },
  };

  const isOpen = ref(false);
  const isHoverItems = ref(false);
  const position = ref<DOMRect>();
  const processDropdownRef = ref<HTMLInputElement | null>(null);


  const currentColor = computed(() => processCategoryColor[props.value?.categoryKey]?.color || 'text-blue-800');
  const currentBackgroundColor = computed(() => processCategoryColor[props.value?.categoryKey]?.backgroundColor || 'bg-white');

  const { height } = useWindowSize();
  const windowScrollPosition = useWindowScroll();

  const setPosition = () => {
    position.value = processDropdownRef.value?.getBoundingClientRect();
  };

  const currentSelectedLabel = computed(() => {
    if (props.selectedLabel) return props.selectedLabel;
    return props.value?.name;
  });
  const dropdownStyle = computed(() => {
    if (!position.value) return {};
    return {
      left: `${position.value.left}px`,
      ...(position.value.bottom + position.value.y) < height.value && { top: `${windowScrollPosition.y.value + position.value.bottom + 5}px` },
      ...(position.value.bottom + position.value.y) > height.value && { bottom: `${(height.value - position.value.top - windowScrollPosition.y.value) + 5}px` },
    };
  });

  watch(() => isOpen.value, (val) => {
    if (val) {
      setPosition();
    }
  });

  watchDebounced(() => props.parentHeight, () => {
    setPosition();
  }, { debounce: 50, maxWait: 1000 });

  watch(() => props.scrollPosition, () => {
    setPosition();
  });

  const handleClick = () => {
    if (!position.value?.width) setPosition();
    isOpen.value = !isOpen.value;
  };
  const handleClickOutside = () => {
    if (isHoverItems.value) return;
    isOpen.value = false;
  };
  const handleClickItem = (item: ConfigurationProcessCategoryStep) => {
    isOpen.value = false;
    emit('select', item);
  };
  const isSelected = (item: ConfigurationProcessCategoryStep) => {
    return props.value?.key === item?.key;
  };
</script>

<style lang="scss" scoped>
  .process-dropdown {
    width: 100%;
    flex-direction: column;
    position: relative;

    &__items {
      position: absolute;
      z-index: 9999999999999;
      background: white;
      height: auto;
      list-style-type: none;
      border: 1px solid $color-blue-light;
      box-shadow: 0px 2px 4px rgba(29, 57, 94, 0.1);
      border-radius: 5px;

      & > li {
        padding: 10px 20px;
        min-height: 39px;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: $color-tertiary;

        &:hover {
          cursor: pointer;
          background: $color-blue-light;
          color: $color-primary;
        }
      }

      &--absolute {
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        margin-top: 5px;
      }
    }

    &__border {
      border-bottom: 1px solid $color-blue-medium;
    }

    &__selected {
      color: $color-primary !important;
    }

    &__icon {
      font-size: 10px;

      &--active {
        img {
          filter: invert(40%) sepia(98%) saturate(4418%) hue-rotate(220deg) brightness(100%) contrast(98%);
        }
      }
    }

    &__label {
      color: $color-blue-heavy-dark;
      font-size: $font-size-xs;
      font-weight: 400;
      margin-bottom: 5px;
      margin-left: 10px;
    }
  }
</style>
