<template>
  <div class="timeline-item gap-5">
    <div class="size-[35px] shrink-0 bg-blue-200 rounded-full items-center justify-center">
      <img src="@/assets/logo/linkedin-message.svg" class="text-base text-blue-500" />
    </div>
    <div class="timeline-item__card flex-col w-full rounded-[10px] bg-white border border-blue-200">
      <div class="timeline-item__header px-2.5 py-[5px] justify-between items-center">
        <div class="flex items-center gap-2">
          <PersonAvatar :person="sender.person" :default-avatar-src="sender.defaultAvatarSrc" />
          <span class="text-xs">
            <TimelinePersonLink :person="sender.person" :type="sender.type" />
            {{ isCoachSender ? t('messages.sent-linkedin-message-to') : t('messages.replied-to') }}
            <TimelinePersonLink :person="recipient.person" :type="recipient.type" />
          </span>
        </div>
        <DateBadgeWithTooltip :date="action.createdAt" />
      </div>
      <p class="p-5 text-sm whitespace-pre-line border-t border-blue-200" @note-pinned="handlePinNote">
        {{ action._message?.messageData?.text }}
      </p>
      <div v-if="action._message?.messageData?.attachments?.length" class="px-5 pb-5 flex flex-wrap gap-2">
        <div v-for="attachment in action._message.messageData.attachments" :key="attachment.id" class="flex items-center gap-2 p-2 bg-neutral-100 rounded">
          <span class="text-xs">{{ attachment.file_name ?? attachment.type }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import notes from '@/common-old/macros/notes';
import { noteController } from '@/managers/notes/controller.js';
import DateBadgeWithTooltip from '@/components/Badge/DateBadgeWithTooltip.vue';
import PersonAvatar from '@/components/Avatar/PersonAvatar.vue';
import { computed } from 'vue';
import { useI18n } from '@/i18n/i18n';
import TimelinePersonLink from '@/components/Timeline/TimelinePersonLink.vue';
import defaultAvatarSrc from '@/assets/img/default-avatar.svg';

interface Action {
  _id: string;
  _note: {
    _id: string;
  };
  _coach?: Person;
  _coder?: Person;
  _message: {
    messageData?: {
      id: string;
      text: string;
      is_sender: 0 | 1;
      attachments: {
        id: string;
        file_name?: string;
        mimetype?: string;
        size: any;
        sticker?: boolean;
        type: string;
        unavailable: boolean;
        url: string
      }[]
    };
  };
  createdAt: string;
}

interface Person {
  _id: string;
  firstName: string;
  lastName: string;
  pictureUrl?: string;
}

const props = defineProps<{
  action: Action
}>();

const emit = defineEmits<{
  (e: 'note-pinned', data: unknown): void;
}>();

const { t, tc } = useI18n();

const coach = computed(() => {
  return props.action._coach;
});
const coder = computed(() => props.action._coder);

const isCoachSender = computed(() => {
  return !!props.action._message?.messageData?.is_sender;
});

const sender = computed<{ person?: Person, type: 'coaches' | 'coders', defaultAvatarSrc?: string }>(() => ({
  person: isCoachSender.value ? coach.value : coder.value,
  type: isCoachSender.value ? 'coaches' : 'coders',
  defaultAvatarSrc: isCoachSender.value ? null : defaultAvatarSrc
}));

const recipient = computed<{ person?: Person, type: 'coaches' | 'coders' }>(() => ({
  person: isCoachSender.value ? coder.value : coach.value,
  type: isCoachSender.value ? 'coders' : 'coaches'
}));

// Methods
const handlePinNote = async () => {
  const data = await noteController.editNote({
    id: props.action._note._id,
    note: {
      type: notes.type.echange.name,
      pin: true,
    },
  });

  emit('note-pinned', data);
};
</script>
