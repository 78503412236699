export default {
  namespaced: true,
  state() {
    return {
      isExpired: false,
    };
  },
  actions: {
    setIsExpired({ commit }, value) {
      commit('setIsExpired', value);
    },
  },
  mutations: {
    setIsExpired(state, value) {
      state.isExpired = value;
    },
  },
};
