<template>
  <div class="panel-coder-documents-modal-delete border-radius-s is-align-items-center is-column">
    <div class="panel-coder-documents-modal-delete__header">
      <p class="panel-coder-documents-modal-delete__text">
        {{ $t('hunt.do-you-realy-want-to-delete-this-document') }}
      </p>
    </div>
    <div>
      <bc-button
        @click="cancelDelete"
        class="panel-coder-documents-modal-delete__button"
        type="outlined">
        {{ $t('hunt.cancel-the-delete') }}
      </bc-button>
      <bc-button
        @click="confirmDelete"
        class="panel-coder-documents-modal-delete__button"
        color="error">
        {{ $t('hunt.delete-this-document') }}
      </bc-button>
    </div>
  </div>
</template>

<script>
  import BcButton from '@/ui-kit/components/BcButton/BcButton';

  export default {
    name: 'panel-coder-documents-modal-delete',
    components: { BcButton },
    methods: {
      confirmDelete() {
        this.$emit('confirm-delete');
      },
      cancelDelete() {
        this.$emit('cancel-delete');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-coder-documents-modal-delete {
    justify-content: space-around;
    padding: 30px;
    min-width: 320px;

    &__header {
      flex-direction: column;
      text-align: center;
      margin-bottom: 20px;
    }

    &__text {
      color: $color-secondary;
      line-height: 17px;
    }

    &__button {
      font-size: $font-size-s;
      min-width: 150px;
      justify-content: center;
      margin: 0 5px;
    }
  }
</style>
