<template>
  <div
    :class="[
      cardContent.deletedAt ? 'hidden' : '',
      isFullWidth ? 'w-full' : '',
    ]"
    class="card border-radius-m"
    @click="$emit('click', $event)">
    <slot
      v-if="$slots.default"
      :class="{
        'card--highlighted': isHighlighted,
      }">
    </slot>
    <component
      v-else
      :is="currentComponent"
      :card-content="cardContent"
      :card-index="cardIndex"
      :card-props="cardProps"
      :card-type="cardType"
      :class="{
        'card--highlighted': isHighlighted,
        'card--bordered': isSelectedIntoTheJobList,
      }">
    </component>
  </div>
</template>

<script setup lang="ts">
import { Component, computed, defineProps, withDefaults } from 'vue';
import { useStore } from '@/store';
import { useRoute } from 'vue-router/composables';
import CardCoders from '@/components/Card/CardCoders.vue';
import CardPokes from '@/components/Card/CardPokes.vue';
import CardJobs from '@/components/Card/CardJobs.vue';
import CardContacts from '@/components/Card/CardContacts.vue';
import CardCompanies from '@/components/Card/CardCompanies.vue';
import CardCoaches from '@/components/Card/CardCoaches.vue';
import CardChat from '@/components/Card/CardChat.vue';
import CardFavoriteLists from '@/components/Card/CardFavoriteLists.vue';

const props = withDefaults(
  defineProps<{
    cardContent: any;
    cardType?: string;
    cardIndex?: number;
    isLoading?: boolean;
    cardProps?: any;
    isFullWidth?: boolean;
  }>(),
  {
    isLoading: true,
    isFullWidth: true,
  }
);

const store = useStore();
const route = useRoute();
const viewList = computed(() => store.state.panel.viewList);
const jobIdFromTheNewPoke = computed(() => store.state.pokes.newPoke.jobId);

const currentComponent = computed(() => {
  const componentFromType: Record<string, Component> = {
    coders: CardCoders,
    'jobs-list': CardJobs,
    jobs: CardJobs,
    pokes: pokeComponent.value,
    contacts: CardContacts,
    coaches: CardCoaches,
    companies: CardCompanies,
    partners: CardCompanies,
    favorites: favoriteTypeComponent.value,
    'favorite-lists': CardFavoriteLists,
  };
  return props.cardType ? componentFromType[props.cardType] : undefined;
});
const pokeComponent = computed(() => (props.cardContent.forced ? CardPokes : CardChat));

const favoriteTypeComponent = computed(() => {
  const cardResource = [CardCoders, CardJobs, CardCompanies, CardContacts, CardPokes];
  return cardResource[props.cardContent.type - 1];
});

const isHighlighted = computed(
  () => props.cardContent?._id === route.params.id
);
const isSelectedIntoTheJobList = computed(() => {
  if (props.cardType === 'jobs-list' && props.cardIndex) {
    return viewList.value[props.cardIndex]._id === jobIdFromTheNewPoke.value;
  }
  return false;
});
</script>

<style lang="scss" scoped>
.card {
  align-items: center;
  margin-bottom: 5px;
  flex-shrink: 0;
  transition: 0.3s;
  background: $color-neutre-4;
  border: 1px solid $color-blue-medium;
  border-radius: 5px;

  @include bp('tablet') {
    align-items: stretch;
  }

  &--overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    @include transition(all 0.3s ease);
    z-index: 3;
  }

  &--highlighted {
    z-index: 3;
  }

  &--bordered {
    border: 2px solid $color-blue-havelock;
    border-radius: 4px;
  }
}
</style>
