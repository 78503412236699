<template>
  <div
    ref="searchbarTags"
    class="searchbar-filters-tags">
    <div class="searchbar-filters-tags__container">
      <p
        v-if="searchbarFiltersTags.length === 0"
        class="searchbar-filters-tags__text">
        {{ $t('companies.account-management.none-criteria-choose') }}
      </p>
      <tag-default
        v-else
        class="searchbar-filters-tags__tag"
        v-for="(tag, tagIndex) in searchbarFiltersTags"
        :key="tagIndex"
        :bc-cross="true"
        :prefix="tag.prefix"
        :bc-icon="tagsStyleMap[tag.type].icon || tag.icon"
        :bc-background-color="tagsStyleMap[tag.type].backgroundColor"
        :bc-icon-color="tagsStyleMap[tag.type].iconColor"
        :bc-cross-color="tagsStyleMap[tag.type].crossColor"
        :bc-background-color-opacity="tagsStyleMap[tag.type].backgroundColorOpacity"
        bc-icon-size="12px"
        :disabled="tag.disabled"
        :bc-text-color="tagsStyleMap[tag.type].textColor"
        :bc-text-color-opacity="tagsStyleMap[tag.type].textColorOpacity"
        @click.native="deleteTag(tagIndex)">
        {{ getTagLabel(tag) }}
      </tag-default>
    </div>
  </div>
</template>

<script>
  // TODO move or refacto to ui-kit
  import { mapState } from 'vuex';
  import TagDefault from '@/legacy/ui-kit/components/Tag/BcTag';
  import searchbar from '@/common-old/mixins/Searchbar/searchbar';

  export default {
    /**
     * The component which will display the tags
     */
    name: 'searchbar-filters-tags',
    components: { TagDefault },
    mixins: [searchbar],
    data() {
      return {
        searchbarTagsContainerWidth: 0
      };
    },
    computed: {
      ...mapState({
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags,
      })
    },
    methods: {
      /**
       * The method to set the searchbar tags width
       */
      setSearchbarTagsWidth() {
        this.searchbarTagsContainerWidth = this.$refs.searchbarTags ? this.$refs.searchbarTags.offsetWidth : 0;
      },
      /**
       * The method to delete a tag
       */
      deleteTag(index) {
        this.$store.dispatch('spliceSearchbarFiltersTags', index);

        this.$emit('on-delete');
      },
      getTagLabel(tag) {
        if (tag.type === 'rating') {
          return `${this.$t(tag.name)} ${tag.query} ${tag.query < 5 ? this.$t('panel-coder-edit.and-empty-plus') : ''}`;
        }
        if (tag.type === 'lastCoachActivity') {
          const {fromLabel, toLabel} = tag.query;
          return `${this.$t(tag.name)}: ${this.transformActivityLabel(fromLabel)} - ${this.transformActivityLabel(toLabel)}`
        }
        return this.$t(tag.name)
      },
      transformActivityLabel(label) {
        return label.startsWith('timeline.') ? this.$t(label): label
      }
    },
    mounted() {
      /**
       * Set the searchbarTagsWidth when the component is mounted
       */
      this.$nextTick(() => {
        this.setSearchbarTagsWidth();
        window.addEventListener('resize', this.setSearchbarTagsWidth);
      });
    },
    /**
     * Destroy the searchbarTagsWidth when the component is destroyed
     */
    beforeDestroy() {
      window.removeEventListener('resize', this.setSearchbarTagsWidth);
    }
  };
</script>

<style scoped lang="scss">
  .searchbar-filters-tags {
    width: 100%;
    &__container {
      flex-wrap: wrap;
    }
    &__tag {
      max-width: 100%;
      border-radius: 30px;
      color: #3D5476;
      font-weight: 500;
      font-size: 13px;
      margin-right: 8px;
      font-size: $font-size-xxs;
      font-weight: $font-weight-bold;

      &.is-unshrink {
        max-width: initial;
      }

      &--domain {
        background: rgba($color-primary-dark, 0.2);
        color: $color-primary-dark;
      }
    }
    &__text {
      font-size: $font-size-xxs;
      font-weight: $font-weight-bold;
    }
  }
</style>
