<template>
  <div
    class="card-informations-tags"
    ref="cardInfosTags">
    <div>
      <bc-tag
        bc-background-color="purple-dark"
        bc-text-color="white"
        ref="domains"
        class="card-informations-tags__tag card-informations-tags__tag--rounded"
        v-if="domains && domains.length > 0">
        {{ domains[0] }}
      </bc-tag>
      <bc-tag
        bc-background-color="purple-dark"
        bc-text-color="white"
        class="card-informations-tags__tag card-informations-tags__tag--rounded"
        v-if="domains && domains.length > 1"
        v-tooltip="{ content: domainsRemainingTooltip }">
        +{{ domains.length - 1 }}
      </bc-tag>
    </div>
    <bc-tag
      :key="index"
      bc-background-color="blue-hawkes"
      bc-text-color="blue-cello"
      class="card-informations-tags__tag"
      v-for="(techno, index) in technosShowed">
      {{ techno.name }}
    </bc-tag>
    <bc-tag
      bc-background-color="blue-hawkes"
      bc-text-color="blue-cello"
      class="card-informations-tags__tag"
      v-if="technosRemaining.length > 0"
      v-tooltip="{ content: technosRemainingTooltip }">
      +{{ technosRemaining.length }}
    </bc-tag>
  </div>
</template>

<script>
  // TODO move or refacto to ui-kit
  import BcTag from '@/legacy/ui-kit/components/Tag/BcTag';

  export default {
    components: { BcTag },
    name: 'card-informations-tags',
    props: {
      tags: {
        type: Array,
        default: () => []
      },
      domains: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      technosContainerWidth: 0,
      technosShowed: [],
      technosRemaining: []
    }),
    created() {
      window.addEventListener('resize', this.setTechnosContainerWidth);
    },
    mounted() {
      this.setTechnosContainerWidth();
    },
    computed: {
      technosRemainingTooltip() {
        return this.technosRemaining.map(techno => techno.name).join(', ');
      },
      domainsRemainingTooltip() {
        return this.domains ? this.domains.slice(1).join(', ') : [];
      },
    },

    methods: {
      setTechnosContainerWidth() {
        this.technosContainerWidth = this.$refs.cardInfosTags.offsetWidth;
      },
      setTechnosArrays() {
        const margin = 5 * 2;

        const technos = this.tags.length > 0 ? [...this.tags] : [];
        const technosShowed = [];
        let technosContentWidth = (((this.$refs.domains || {}).$el || {}).clientWidth + margin) || 0;
        for (let i = 0;
             technosContentWidth < this.technosContainerWidth && technosShowed.length < technos.length;
             i++) {
          technosShowed.push(technos[i]);
          technosContentWidth = technosContentWidth + (((technos[i] || {}).name || '').length * 7.5 + 70);
        }
        if (technosContentWidth > this.technosContainerWidth) {
          technosShowed.pop();

          this.technosShowed = technosShowed;
          this.technosRemaining = technos.splice(technosShowed.length);
        } else {
          this.technosShowed = technos;
        }
      }
    },
    watch: {
      technosContainerWidth: {
        handler() {
          this.setTechnosArrays();
        },
        immediate: true
      },
      tags() {
        this.setTechnosArrays();
      },
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.setTechnosContainerWidth);
    }
  };
</script>

<style lang="scss" scoped>
  .card-informations-tags {
    max-width: 100%;
    width: 100%;
    overflow: hidden;

    &__tag {
      justify-content: center;
      align-items: center;
      margin: 0 5px;
      padding: 7.5px 15px;
      border-radius: 4px;
      white-space: nowrap;
      font-size: $font-size-xs;
      &:first-child {
        margin-left: 0;
      }

      &--rounded {
        border-radius: 30px;
      }
    }
  }
</style>
