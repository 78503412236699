<template>
  <div class="custom-jobs-dropdown-menu" v-on="$listeners">
    <bc-dropdown
      :options="menuOptions"
      class="custom-jobs-dropdown-menu__dropdown"
      :close-dropdown-at-select="true"
      :is-open="isOpen"
      position="bottom-left">
      <template #label="{ isDropdownOpen }">
        <button
          class="custom-jobs-dropdown-menu__button"
          :class="{ 'custom-jobs-dropdown-menu__button--open': isDropdownOpen }"
        >
          <i class="icon-settings"></i>
        </button>
      </template>
      <template #option="{ activeIndex, index, option }">
        <bc-dropdown-item
          :is-focus="activeIndex === index"
          :is-selected="false"
          :text="option.name"
          :title="option.name"
          class="custom-jobs-dropdown-menu__dropdown--item"
          :icon="option.icon"
          :color="option.color"
          @click.native.stop="handleClickItem(option)">
        </bc-dropdown-item>
      </template>
    </bc-dropdown>
  </div>
</template>

<script>
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';

export default {
  name: 'custom-jobs-dropdown-menu',
  components: {
    BcDropdown,
    BcDropdownItem,
  },
  props: {
    isCustomJobOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: true,
    }
  },
  computed: {
    menuOptions() {
      const options = [
        { key: 'duplicate', name: this.$t('generics.duplicate'), icon: 'file-copy' },
        { key: 'delete', name: this.$t('generics.delete'), icon: 'trash', color: 'danger' },
      ];
      if (this.isCustomJobOpen) {
        options[2] = {
          key: 'close-opportunity',
          name: this.$t('custom-jobs.close-opportunity'),
          icon: 'x-circle',
        };
      }
      return options;
    },
  },
  methods: {
    handleClickItem(option) {
      this.isOpen = false;
      this.$emit('on-action', option.key)
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-jobs-dropdown-menu {
  height: 20px;

  &__button {
    color: $color-blue-heavy-dark;

    &--open {
      color: $color-primary;
    }
  }

  &__dropdown {
    &--item {
      min-width: 207px;;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $color-tertiary;

      &--color:hover {
        :deep() {
          .bc-dropdown-item__text {
            color: white;
          }
        }
      }
    }
  }
}
</style>
