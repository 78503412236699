import { getTeams } from '@/api/teams';

export default {
  namespaced: true,
  state: {
    teams: [],
  },
  actions: {
    async getTeams({ commit }) {
      const { data } = await getTeams();

      commit('setTeams', data);
    },
  },
  mutations: {
    setTeams(state, teams) {
      state.teams = [...teams, ...state.teams];
    },
  },
};
