<template>
  <div class="modal-email-template-form">
    <ModalEmailHeader
      :title="params.emailTemplate ? $t('email.template-edition') : $t('email.template-creation')"
      :canDelete="!!params.emailTemplate"
      :canDuplicate="false"
      :disabled="params.disabled"
      :isDefaultTemplate="params.isDefaultTemplate"
      @on-close="onClose"
      @on-delete="onDeleteTemplate"
    />
    <div class="modal-email-template-form__content">
      <EmailTemplateForm
        :template="params.emailTemplate"
        :disabled="params.disabled && !params.emailTemplate?.default"
        @on-close="onClose"
      />
    </div>
    <ModalDeleteItem
      :is-open="isModalOpen"
      :title="$t('email.template-delete-confirm')"
      @on-confirm="onConfirmDelete"
      @close="onCloseModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import EmailTemplateForm from '@/components/Email/EmailTemplateForm.vue';
import ModalEmailHeader from '@/components/Modal/ModalEmail/ModalEmailHeader';
import ModalDeleteItem from '@/components/Modal/ModalDeleteItem';

export default {
  name: 'modal-email-template-form',
  components: {
    EmailTemplateForm,
    ModalEmailHeader,
    ModalDeleteItem,
  },
  data() {
    return {
      isModalOpen: false,
      confirmDelete: false,
    }
  },
  computed: {
    ...mapState('app', ['params']),
  },
  methods: {
    ...mapActions({
      deleteTemplate: 'emails/deleteTemplate',
    }),
    onClose() {
      this.$emit('close-modal');
    },
    async onDeleteTemplate() {
      if (!this.confirmDelete) {
        this.isModalOpen = true;
        return;
      }

      try {
        const templateId = this.params?.emailTemplate?._id;
        if (!templateId) {
          throw new Error('invalid template id');
        }

        await this.deleteTemplate({ id: templateId });
        this.onClose();
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err?.response?.data?.message || err.message,
        })
      }
    },
    onCloseModal() {
      this.isModalOpen = false;
    },
    async onConfirmDelete() {
      this.confirmDelete = true;
      await this.onDeleteTemplate();
      this.confirmDelete = false;
      this.isModalOpen = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-email-template-form {
  position: relative;
  flex-direction: column;
  width: 640px;
  overflow: auto;
}
</style>
