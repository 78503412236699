<template>
  <div class="panel-organization-configuration-schedules">
    <panel-organization-configuration-item title="Schedules">
      <div
        v-for="(_, key) in schedules"
        :key="key"
        class="panel-organization-configuration-schedules__item">
        <div>{{ key }}</div>
        <bc-toggle :id="key" type="default" v-model="schedules[key]"></bc-toggle>
      </div>
    </panel-organization-configuration-item>
  </div>
</template>

<script>
import {
  updateConfigurationOrganizationByKey,
} from '@/api/organizations';
import PanelOrganizationConfigurationItem from './PanelOrganizationConfigurationItem';

export default {
  name: 'panel-organization-configuration-schedules',
  components: {
    PanelOrganizationConfigurationItem,
  },
  props: {
    profile: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      schedules: {},
    }
  },
  computed: {},
  methods: {
    async updateConfig(key, data) {
      try {
        await updateConfigurationOrganizationByKey({
          key,
          organizationId: this.profile._id,
          ...data,
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
  },
  watch: {
    schedules: {
      handler(newValue) {
        this.updateConfig('schedules', newValue);
      },
      deep: true,
    },
  }
};
</script>

<style lang="scss" scoped>
.panel-organization-configuration-schedules {
}
</style>
