<template>
  <div class="website">
    <JobboardConfigItem
      :channel="channel"
      :subscriptionModel="subscriptionModel"
      :applicationMode="applicationMode"
      :hideSubscriptions="true"
      :disabledApplications="['usesEmailApply', 'usesDirectApply']"
      :is-valid="isValid"
      @update-subscription-model="updateSubscriptionModel"
      @update-application-mode="updateApplicationMode"
      @cancel="$emit('cancel', $event)"
      @submit="onSubmit">
      <template #subscriptionContent>
        <div class="is-column is-full-width">
          <div class="mt-4 is-column">
            <div class="is-column">
              <label class="website__label">Nom du site web (facultatif)</label>
              <div>
                <bc-input
                  v-model="websiteName"
                  name="websiteName"
                  class="w-full mt--1"
                  :valid="true"
                  placeholder=""
                ></bc-input>
              </div>
            </div>
          </div>
          <div class="mt-4 is-column">
            <label class="website__label">Flux XML</label>
            <div class="website__content mt--1">
              <div class="website__url">
                <input :value="websiteUrl" :disabled="true" />
                <button
                  v-tooltip="{
                    content: isCopiedUrl ? $t('generics.copied') : undefined,
                    hideOnTargetClick: false,
                    trigger: 'manual',
                    show: isCopiedUrl,
                  }"
                  v-clipboard:copy="websiteUrl"
                  v-clipboard:success="setIsCopiedUrl">
                  <i class="icon-clipboard-2"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-4 is-column">
            <div class="is-column">
              <label class="website__label">{{ $t('generics.auto-broadcast') }}</label>
              <div>
                <bc-toggle id="website-auto" v-model="autoPublish"></bc-toggle>
                <p class="ml-3" :class="{ 'website__activated': autoPublish }">
                  {{ autoPublish ? $t('generics.activated') : $t('generics.disabled') }}
                </p>
                <WarningCercle
                  class="ml-3"
                  :content="$t('jobboards.jobboard-auto-warning', { jobboard: 'Website' })"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #applicationMode>
        <div v-if="applicationMode === applicationModes.direct" class="mt-5 pt-3 is-full-width is-column">
          <div>
            <bc-input
              v-model="apiKey"
              name="apiKey"
              class="w-full mt--1"
              :valid="true"
              placeholder=""
            ></bc-input>
          </div>
        </div>
      </template>
    </JobboardConfigItem>
  </div>
</template>

<script>
import { subscriptionsModels, applicationModes } from '@/api/xtramile';
import BcInput from '@/ui-kit/components/BcInput/BcInput';
import BcToggle from '@/ui-kit/components/BcToggle/BcToggle';
import WarningCercle from '@/components/WarningCercle/WarningCercle';
// import ContractIdField from '@/components/Jobboard/JobboardConfigChannel/Fields/ContractIdField';
import JobboardConfigItem from '@/components/Jobboard/JobboardConfig/JobboardConfigItem';

import { getWebsiteJobsUri } from '@/helpers/url/website_url';
import { mapGetters } from 'vuex';

export default {
  name: 'website',
  components: {
    BcInput,
    BcToggle,
    WarningCercle,
    // ContractIdField,
    JobboardConfigItem,
  },
  props: {
    channel: {
      type: Object,
      default: () => ({}),
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    disabledSubscriptions: {
      type: Array,
      default: () => [],
    },
    disabledApplications: {
      type: Array,
      default: () => [],
    },
    isXtramile: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.subscriptionModel = this.subscriptionsModels.organic;
    this.applicationMode = this.values?.params?.applicationMode || this.applicationModes.direct;
    this.apiKey = this.values?.credentials?.apiKey || '';
    this.websiteName = this.values?.params?.websiteName || '';
    this.autoPublish = this.values?.params?.autoPublish || false;
  },
  data() {
    return {
      isCopiedUrl: false,
      subscriptionsModels,
      applicationModes,
      contractId: '',
      DIRECT_APPLY_API_KEY: '',
      subscriptionModel: '',
      applicationMode: '',
      autoPublish: false,
      websiteName: '',
      apiKey: '',
    }
  },
  computed: {
    ...mapGetters({
      organization: 'user/organization',
    }),
    isSubscriptionValid() {
      if (!this.subscriptionModel) return false;
      if (this.subscriptionModel === this.subscriptionsModels.sponsored) {
        return !!this.contractId;
      }
      return true;
    },
    isApplicationValid() {
      if (!this.applicationMode) return false;
      return true;
    },
    isValid() {
      return this.isSubscriptionValid && this.isApplicationValid;
    },
    websiteUrl() {
      return getWebsiteJobsUri(this.sanitizeName(this.organization.name)) || 'http://apps.marvinrecruiter.com/api/website/nomOrga';
    },
  },
  methods: {
    sanitizeName(name) {
      return name
        .normalize('NFD') // Transforme en caractères décomposés
        .replace(/[\u0300-\u036f]/g, '') // Supprime les accents
        .replace(/\s+/g, ''); // Supprime les espaces
    },
    setIsCopiedUrl() {
      this.isCopiedUrl = true;
      setTimeout(() => {
        this.isCopiedUrl = false;
      }, 2300);
    },
    updateSubscriptionModel(key) {
      this.subscriptionModel = key;
    },
    updateApplicationMode(key) {
      this.applicationMode = key;
    },
    onSubmit() {
      this.$emit('submit', {
        isXtramile: false,
        jobboard: 'website',
        credentials: {
          apiKey: this.apiKey,
        },
        params: {
          autoPublish: this.autoPublish,
          websiteName: this.websiteName,
          applicationMode: this.applicationMode,
        },
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.website {
  &__label {
    color: $color-blue-heavy-dark;
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  p {
    font-size: 15px;
    font-weight: 400;
  }

  &__activated {
    color: $color-green-shamrock;
  }

  &__disabled {
    color: $color-red-mandy;
  }

  &__content {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
  }

  &__url {
    background: white;
    border: 1px solid $color-blue-medium;
    border-radius: 5px;
    overflow: hidden;
    & > span,
    & > input {
      padding: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $color-blue-dark-cello;
      opacity: 0.5;
      padding-right: 50px;

      &::placeholder, &:disabled {
        color: $color-blue-heavy-dark;
        background: white;
      }
    }
    & > input {
      width: 500px;
      padding-right: 10px;
      opacity: 1;
    }
    & > button {
      border-left: 1px solid $color-blue-medium;
      padding: 10px;
    }
  }
}
</style>
