<template>
  <div class="process-item border-radius-s is-full-width is-relative is-column">
    <div class="process-item__icon icon-star-full font-size-xxl is-absolute is-left is-top"></div>
    <div class="process-item__header">
      <bc-avatar
        :src="coach.pictureUrl"
        alt="coach"
        class="process-item__picture"
        size="s">
      </bc-avatar>
      <div class="is-align-items-center">
        <p class="process-item__text is-align-items-center font-size-xs">
          <span class="process-item__text--blue">
            {{ coach.firstName }} {{ coach.lastName }}
          </span>
          a fait un
          <span class="process-item__text--blue font-weight-bold">process de recrutement</span>
          de {{ name }}
        </p>
      </div>
      <div
        class="process-item__date font-size-xs"
        v-tooltip="qualificationTooltip">
        {{ qualificationFromNow }}
      </div>
    </div>
    <div class="is-column is-full-width">
      <div
        :class="{ 'process-item__container--expanded': isExpanded || isEditing }"
        class="process-item__container is-full-width">
        <bc-rich-text-editor-bubble
          :is-editable="isEditing"
          :key="process.content"
          @blur="setIsTyping()"
          @focus="setIsTyping(true)"
          class="font-size-s process-item__editor"
          ref="textarea"
          v-model="formattedContent">
        </bc-rich-text-editor-bubble>
      </div>
      <a
        @click="isExpanded = true"
        class="process-item__see-more is-underline font-size-s"
        v-show="canBeExpanded && !isExpanded && !isEditing">
        {{ $t('companies.panel-view-timeline.see-more') }}
      </a>
    </div>
    <div
      class="process-item__editor-container is-column is-full-width"
      v-if="isEditing">
      <div class="process-item__editor-wrapper is-column is-align-items-start">
        <div class="process-item__caption is-full-width is-align-items-center is-justify-content-space-between">
          <h2 class="process-item__title font-weight-bold font-size-m">
            TYPE DE NOTE
          </h2>
        </div>
        <div class="process-item__types border-radius-s is-overflow-hidden">
          <button
            :class="{ 'process-item__type--active': type === macros.types.qualification.name }"
            @click="type = macros.types.qualification.name"
            class="process-item__type font-size-xs border-radius-s"
            v-if="$route.query.type !== 'contacts'">
            Qualification
          </button>
          <button
            :class="{ 'process-item__type--active': type === macros.types.message.name }"
            @click="type = macros.types.message.name"
            class="process-item__type font-size-xs border-radius-s">
            Message envoyé
          </button>
        </div>
      </div>
      <div
        class="process-item__editor-wrapper process-item__checkboxes is-flex-wrap is-align-items-center"
        v-if="type === macros.types.message.name">
        <bc-checkbox
          class="process-item__checkbox"
          id="message"
          v-model="sms">
          <label
            class="process-item__label font-size-s icon-chat is-flex is-align-items-center"
            for="message">
            SMS
          </label>
        </bc-checkbox>
        <bc-checkbox
          class="process-item__checkbox"
          id="voice-message"
          v-model="call">
          <label
            class="process-item__label font-size-s icon-phone is-flex is-align-items-center"
            for="voice-message">
            Message vocal
          </label>
        </bc-checkbox>
        <bc-checkbox
          class="process-item__checkbox"
          id="email"
          v-model="email">
          <label
            class="process-item__label font-size-s icon-mail is-flex is-align-items-center"
            for="email">
            Email
          </label>
        </bc-checkbox>
        <bc-checkbox
          class="process-item__checkbox"
          id="linkedin"
          v-model="linkedin">
          <label
            class="process-item__label font-size-s icon-linkedin is-flex is-align-items-center"
            for="linkedin">
            Linkedin
          </label>
        </bc-checkbox>
      </div>
    </div>
    <div
      class="process-item__wrapper is-right is-bottom is-absolute"
      v-if="isEditing && isEditable">
      <button
        @click="cancelEdit"
        class="icon-cross process-item__button process-item__button--red is-align-items-center is-justify-content-center border-radius-rounded font-size-xs"
        data-button-cancel>
      </button>
      <button
        @click="editNote"
        class="icon-check process-item__button process-item__button--blue is-align-items-center is-justify-content-center border-radius-rounded font-size-xs"
        data-button-submit>
      </button>
    </div>
    <div
      class="process-item__wrapper is-right is-bottom is-absolute"
      v-else-if="isEditable">
      <button
        @click="goEditNote"
        class="icon-edit process-item__button process-item__button--blue is-align-items-center is-justify-content-center border-radius-rounded font-size-xs"
        data-button-edit>
      </button>
      <button
        @click="goDeleteNote"
        class="icon-trash process-item__button process-item__button--red is-align-items-center is-justify-content-center border-radius-rounded font-size-xs"
        data-button-delete>
      </button>
    </div>
    <bc-modal :active.sync="isModalOpen">
      <template>
        <div class="process-item__modal is-column is-align-items-center">
          <h2 class="process-item__modal-title is-uppercase font-size-l">
            Supprimer ce process ?
          </h2>
          <p class="process-item__modal-text">
            En supprimant ce process, vous la perdrez définitivement.
          </p>
          <p class="process-item__modal-text">
            Cette action est irréversible.
          </p>
          <div class="process-item__modal-wrapper">
            <bc-button
              @click="isModalOpen = false"
              class="process-item__modal-button"
              color="error">
              {{ $t('generics.cancel') }}
            </bc-button>
            <bc-button
              @click="deleteNote"
              class="process-item__modal-button"
              data-button-validate-delete>
              {{ $t('generics.validate') }}
            </bc-button>
          </div>
        </div>
      </template>
    </bc-modal>
  </div>
</template>

<script>
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import BcRichTextEditorBubble from '@/ui-kit/components/BcRichTextEditor/BcRichTextEditorBubble';
  import { noteController } from '@/managers/notes/controller';
  import BcModal from '@/ui-kit/components/BcModal/BcModal';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import { resourceType } from '@/common-old/macros/notes';
  import NotesEditorMixin from '@/mixins/Notes/notes';
  import { getMediaNameFromApiValue, getTypeNameFromApiValue } from '@/managers/notes/formatters/noteDeserializers';

  import moment from 'moment/min/moment-with-locales';
  import { apiRoles } from '@/macros/coaches/roles';
  import BcCheckbox from '@/legacy/ui-kit/components/Checkbox/BcCheckbox';

  const hasRightsToEditOtherprocess = [
    apiRoles.businessManager,
    apiRoles.mentor,
    apiRoles.lead,
  ];

  export default {
    name: 'process-item',
    components: {
      BcCheckbox,
      BcButton,
      BcModal,
      BcRichTextEditorBubble,
      BcAvatar,
    },
    mixins: [NotesEditorMixin],
    props: {
      process: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        content: this.process.content || '',
        rating: this.process.hasOwnProperty('rating') ? this.process.rating : null,
        urgency: this.process.hasOwnProperty('urgency') ? this.process.urgency : null,
        type: this.process?.type ? getTypeNameFromApiValue(this.process) : '',
        media: this.process?.media?.length > 0 ? getMediaNameFromApiValue(this.process) : [],
        isEditing: false,
        isModalOpen: false,
        isExpanded: false,
        textareaNode: {},
      };
    },
    computed: {
      canBeExpanded() {
        return (this.textareaNode || {}).scrollHeight > (this.textareaNode || {}).clientHeight || false;
      },
      qualificationTooltip() {
        return {
          content: `Le ${this.qualificationCreationDate}`,
        };
      },
      qualificationCreationDate() {
        return moment(this.process.createdAt).format('do');
      },
      qualificationFromNow() {
        return moment(this.process.createdAt).fromNow();
      },
      formattedContent: {
        get() {
          return this.formatContent(this.content);
        },
        set(value) {
          this.content = value;
        },
      },
      resourceToQualify() {
        return resourceType[this.$route.query.type];
      },
      coach() {
        return (this.process || {})._coach || {};
      },
      name() {
        if (this.$route.query.type === 'coders') {
          return `${this.process._coder.firstName} ${this.process._coder.lastName}`;
        }

        if (this.$route.query.type === 'hunt') {
          return `${this.process._hunt.poste}`;
        }

        return this.$route.query.type === 'companies' ? (this.process._company || {}).name : (this.process.j || {}).name;
      },
      isDifferentRating() {
        return this.rating >= 0 && this.rating !== this.process.rating;
      },
      isDifferentUrgency() {
        return this.urgency >= 0 && this.urgency !== this.process.urgency;
      },
      user() {
        return this.$store.state.user.profile;
      },
      isQualificationAuthor() {
        return this.user._id === ((this.process || {})._coach || {})._id;
      },
      isAuthorPresale() {
        return this.process?._coach?.role === apiRoles.presales;
      },
      canEditOtherCoachesprocess() {
        return hasRightsToEditOtherprocess.includes(this.user.role);
      },
      isEditable() {
        return this.isQualificationAuthor || (this.canEditOtherCoachesprocess && this.isAuthorPresale);
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.setTextareaNode();
      });
    },
    watch: {
      content() {
        this.saveNoteToLocalStorage();
      },
      rating() {
        this.saveNoteToLocalStorage();
      },
      'process.content'() {
        this.content = this.process.content;
      },
      'process.rating'() {
        this.rating = this.process.rating;
      },
      'process.urgency'() {
        this.urgency = this.process.urgency;
      },
    },
    methods: {
      setTextareaNode() {
        this.textareaNode = (this.$refs.textarea.$refs.reference || {}).$el;
      },
      formatContent(text) {
        const formattedText = `<p>${text.replace(/\r?\n|\rro/g, '</p><p>')}`;

        return formattedText.replace(/^\s*<p\s*\/?>|<p\s*\/?>\s*$/g, '');
      },
      setIsEditing(value) {
        this.isEditing = value;
      },
      setRating(value) {
        this.rating = value;
      },
      setUrgency(value) {
        this.urgency = value;
      },
      goDeleteNote() {
        this.isModalOpen = true;
      },
      goEditNote() {
        this.setDefaultNoteToSavedNote();

        this.setIsEditing(true);
      },
      setIsTyping(value) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            typing: value,
          },
        });
      },
      cancelEdit() {
        this.content = (this.process || {}).content;
        this.rating = (this.process || {}).rating;
        this.urgency = (this.process || {}).urgency;

        this.clearSavedNote();

        this.setIsEditing(false);
      },
      async editNote() {
        const data = await noteController.editNote({
          id: this.process._id,
          note: {
            ...this.type && { type: this.type },
            ...this.content && { content: this.content },
            ...this.isDifferentRating && { rating: this.rating },
            ...this.isDifferentUrgency && { urgency: this.urgency },
            ...this.media.length > 0 && { media: this.media },
          },
        });

        this.clearSavedNote();

        this.$emit('note-edited', { ...data, _coach: this.coach });

        this.setIsEditing(false);
      },
      setDefaultNoteToSavedNote() {
        if (localStorage.getItem(`note-${(this.process || {})._id}`)) {
          const { content, rating, urgency } = JSON.parse(localStorage.getItem(`note-${(this.process || {})._id}`));

          this.content = content || '';
          this.rating = rating || undefined;
          this.urgency = urgency || undefined;
        }
      },
      saveNoteToLocalStorage() {
        localStorage.setItem(`note-${(this.process || {})._id}`, JSON.stringify({
          content: this.content,
          ...this.rating && { rating: this.rating },
          ...this.urgency && { urgency: this.urgency },
        }));
      },
      clearSavedNote() {
        localStorage.removeItem(`note-${(this.process || {})._id}`);
      },
      deleteNote() {
        try {
          noteController.remove(this.process._id);

          this.$emit('note-deleted', this.process._id);
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: error.message || error.response.data,
          });
        }
      },
    },
  };
</script>

<style lang=scss scoped>
  .process-item {
    border: 2px solid $color-primary;
    background: $color-white;
    padding: 5px 5px 20px 40px;
    margin-left: 20px;

    &__icon {
      color: $color-primary;
      left: -35px;
    }

    &__header {
      margin-left: -37.5px;
    }

    &__picture {
      margin-right: 10px;
    }

    &__see-more {
      margin: -15px auto 15px 0;
      color: $color-primary;
    }

    &__text {
      color: $color-secondary;

      &--blue {
        color: $color-primary;
      }
    }

    &__date {
      background-color: $color-blue-light;
      color: $color-tertiary;
      border-radius: 20px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      padding: 5px 10px;
      margin-left: auto;
    }

    &__wrapper {
      margin: 0 -5px;
      right: 15px;
      bottom: 22.5px;
    }

    &__rating {
      align-items: center;
    }

    &__container {
      max-height: calc(3em + 2.5em);
      margin-bottom: 15px;

      &--expanded {
        max-height: initial;
      }
    }

    &__editor {
      & :deep() {
        .bc-rich-text-editor-bubble__textarea {
          overflow: hidden;
        }
      }
    }

    &__editor-container {
      padding: 10px;
      border-top: 1px solid rgba($color-primary, 0.5);
      @include bp("tablet") {
        padding: 20px 0;
      }
    }

    &__editor-wrapper {
      margin-bottom: 25px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__button {
      height: 25px;
      width: 25px;
      color: $color-white;
      margin: 0 5px;

      &--red {
        background: $color-error;
      }

      &--blue {
        background: $color-primary;
      }
    }

    &__modal {
      padding: 30px;
      color: $color-secondary;
    }

    &__modal-wrapper {
      margin-top: 20px;
    }

    &__modal-title {
      margin-top: 0;
    }

    &__modal-button {
      margin: 0 10px;
    }

    &__title {
      color: $color-secondary;
      margin: 0;
    }

    &__types {
      margin-top: 15px;
      background: rgba($color-primary, 0.1);
    }

    &__type {
      padding: 5px;
      color: $color-primary;
      border: 1px solid transparent;
      @include bp("tablet") {
        padding: 10px 20px;
      }

      &:hover {
        border: 1px solid $color-primary;
      }

      &--active {
        border: 1px solid $color-primary;
        background: $color-white;
      }
    }

    &__checkboxes {
      margin: 0.5em -1.5em;
    }

    &__checkbox {
      margin: 0.5em 1.5em;
    }

    &__label {
      cursor: pointer;

      &:before {
        margin-right: 5px;
        font-size: 18px;
      }
    }
  }
</style>
