<template>
  <panel-process-details-card
    :title="$t('companies.panel-hunt-process.the-candidate')"
    icon="user"
    cardStyle="flex-direction: row;"
  >
    <panel-process-details-contact
      :email="coder.email"
      :name="`${coder.firstName} ${coder.lastName}`"
      :phone="coder.phone"
      :title="coder.title"
      class="panel-process-details-coder__contact"
    >
    </panel-process-details-contact>
    <router-link
      :to="{
        ...$route,
        params: {
          id: coder._id
        },
        query: {
          ...$route.query,
          type: 'coders'
        }
      }"
      class="panel-process-details-coder__link"
    >
      <bc-button class="panel-process-details-coder__button">
        {{ $t('companies.panel-hunt-process.see-the-profile') }}
      </bc-button>
    </router-link>
  </panel-process-details-card>
</template>

<script>
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import PanelProcessDetailsContact from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsContact';
import PanelProcessDetailsCard from './PanelProcessDetailsCard.vue';

export default {
  name: 'panel-process-details-coder',
  components: {
    PanelProcessDetailsContact,
    BcButton,
    PanelProcessDetailsCard
  },
  props: {
    coder: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-process-details-coder {
  flex-direction: column;

  &__link {
    display: flex;
    flex-shrink: 0;
    align-items: center;
  }

  &__container {
    padding: 15px 20px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  &__button {
    flex-shrink: 0;
    margin-left: 17.5px;
  }
}
</style>
