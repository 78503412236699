<template>
  <div class="filters-dropdown-period flex items-center gap-2.5">
    <button
      v-if="isResetButtonDisplayed"
      class="border-b border-blue-800 text-blue-800 text-sm hover:border-blue-500 hover:text-blue-500"
      @click="resetFilters">
      <i class="icon-refresh-cw"></i>
      <span>&nbsp;&nbsp;{{ $t('generics.reinitialize') }}</span>
    </button>
    <bc-dropdown
      :options="periodOptions"
      class="filters-dropdown-period__dropdown"
      is-popper>
      <template #label="{ isDropdownOpen }">
        <bc-dropdown-trigger
          :is-arrow="true"
          :is-dropdown-open="isDropdownOpen"
          :is-filter="true"
          :text="selectedPeriod"
          class="filters-dropdown-period__filter"
          icon="clock">
        </bc-dropdown-trigger>
      </template>
      <template #option="{ activeIndex, index, option }">
        <v-date-picker
          v-if="option.code === 'customNbDays'"
          v-model="customDateRange"
          :locale="locale"
          :max-date="maxDate"
          :popover="{ visibility: 'hover', positionFixed: true, placement: 'left' }"
          class="reminders-buttons-later__date-picker"
          is-range
        >
          <template v-slot="{ togglePopover }">
            <div @mouseenter="togglePopover">
              <bc-dropdown-item
                :is-focus="activeIndex === index"
                :text="option.name"
                :title="option.name"
                class="filters-dropdown-period__option font-size-s"
              >
              </bc-dropdown-item>
            </div>
          </template>
        </v-date-picker>
        <bc-dropdown-item
          v-else
          :is-focus="activeIndex === index"
          :text="option.name"
          :title="option.name"
          class="filters-dropdown-period__option font-size-s"
          @click.native="setSelectedPeriod(option)">
        </bc-dropdown-item>
      </template>
    </bc-dropdown>
  </div>
</template>

<script>
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
  import moment from 'moment/min/moment-with-locales';
  import locale from '@/mixins/i18n/locale';
  import { format, parse, parseISO } from 'date-fns';
  import { enUS, fr } from 'date-fns/locale';

  export default {
    name: 'filters-dropdown-period',
    props: {
      queryName: {
        type: String,
        default: 'period-selected',
      },
      isDefaultSelectedPeriod: {
        type: Boolean,
        default: true,
      },
      customPeriodQueryStart: {
        type: String,
        default: 'activityStart',
      },
      customPeriodQueryEnd: {
        type: String,
        default: 'activityEnd',
      },
      isFutureDate: {
        type: Boolean,
        default: false,
      },
      hasResetButton: {
        type: Boolean,
        default: false,
      }
    },
    mixins: [locale],
    components: {
      BcDropdownTrigger,
      BcDropdown,
      BcDropdownItem,
    },
    mounted() {
      if (this.isDefaultSelectedPeriod && !this.$route.query[this.queryName]) {
        this.setSelectedPeriod(this.periodOptions[0]);
      }
      if (!this.isFutureDate) {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        this.maxDate = tomorrow;
      }
    },
    data() {
      return {
        customDateRange: undefined,
        maxDate: undefined,
      };
    },
    computed: {
      periodOptions() {
        return [
          {
            name: this.$i18n.t('process.week'),
            from: moment().startOf('week').format('DD-MM-YYYY'),
            to: moment().endOf('week').format('DD-MM-YYYY'),
            query: 'week',
          },
          {
            name: this.$i18n.t('process.month'),
            from: moment().startOf('month').format('DD-MM-YYYY'),
            to: moment().endOf('month').format('DD-MM-YYYY'),
            query: 'month',
          },
          {
            name: this.$i18n.t('process.trimester'),
            from: moment().startOf('quarter').format('DD-MM-YYYY'),
            to: moment().endOf('quarter').format('DD-MM-YYYY'),
            query: 'quarter',
          },
          {
            name: this.$i18n.t('generics.custom'),
            code: 'customNbDays',
            query: 'custom',
          },
        ];
      },
      selectedPeriod() {
        if (this.$route.query?.[this.customPeriodQueryStart] && this.$route.query?.[this.customPeriodQueryEnd]) {
          const queryStart = parse(this.$route.query[this.customPeriodQueryStart], 'dd-MM-yyyy', new Date())?.toISOString();
          const queryEnd = parse(this.$route.query[this.customPeriodQueryEnd], 'dd-MM-yyyy', new Date())?.toISOString();
          return `${format(parseISO(queryStart), 'dd-MM-yyyy', { locale: this.$i18n.locale === 'fr' ? fr : enUS })} - ${format(parseISO(queryEnd), 'dd-MM-yyyy', { locale: this.$i18n.locale === 'fr' ? fr : enUS })}`;
        }
        return this.$i18n.t('generics.temporal-filter');
      },
      isResetButtonDisplayed() {
        return this.hasResetButton && this.$route.query?.[this.customPeriodQueryStart] && this.$route.query?.[this.customPeriodQueryEnd]
      }
    },
    methods: {
      setSelectedPeriod(period) {
        this.customDateRange = undefined;
        this.$emit('select', period);
      },
      resetFilters() {
        this.customDateRange = undefined;
        this.$emit('select', {});
      },
    },
    watch: {
      customDateRange(newRange) {
        if (!newRange) return;

        this.$emit('select', {
          from: moment(newRange.start).format('DD-MM-YYYY'),
          to: moment(newRange.end).format('DD-MM-YYYY'),
          query: 'custom',
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .filters-dropdown-period {
    &__filter {
      justify-content: space-between;
      align-items: center;
      min-width: 215px;
    }

    &__option {
      min-width: 215px;
    }
  }
</style>
