<template>
  <div class="auth-magic is-full-width is-justify-content-center">
    <transition-fade>
      <div
        v-if="isLoading"
        class="auth-magic__loader is-fixed is-top is-left is-justify-content-center is-align-items-center">
        <bc-spinner size="large"></bc-spinner>
      </div>
    </transition-fade>
    <transition-fade>
      <auth-magic-email-sent
        v-if="user.firstName && user.lastName"
        :email="email"
        :user="user"
        @is-fetching="setIsLoading">
      </auth-magic-email-sent>
      <auth-magic-login
        v-else
        :email.sync="email"
        @is-fetching="setIsLoading"
        @user-fetched="setUser">
      </auth-magic-login>
    </transition-fade>
    <auth-magic-loader v-if="isRouteToken"></auth-magic-loader>
  </div>
</template>

<script>
  import AuthMagicLogin from '@/components/Auth/AuthMagic/AuthMagicLogin';
  import AuthMagicEmailSent from '@/components/Auth/AuthMagic/AuthMagicEmailSent';
  import TransitionFade from '@/components/UIKit/Transition/TransitionFade';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import AuthMagicLoader from '@/components/Auth/AuthMagic/AuthMagicLoader';

  export default {
    name: 'auth-magic',
    components: { AuthMagicLoader, BcSpinner, TransitionFade, AuthMagicEmailSent, AuthMagicLogin },
    data() {
      return {
        isLoading: false,
        email: '',
        user: {
          firstName: '',
          lastName: '',
        },
      };
    },
    computed: {
      component() {
        return 'auth-magic-login';
      },
      isRouteToken() {
        return !!this.$route.query.token;
      },
    },
    methods: {
      setUser(user) {
        this.user = user;
      },
      setIsLoading(value) {
        this.isLoading = value;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .auth-magic {
    flex-grow: 1;

    &__loader {
      height: 100vh;
      width: 100vw;
      background: rgba($color-secondary-dark, 0.25);
      z-index: 999;
    }
  }
</style>
