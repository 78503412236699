import Vue from 'vue';
import timeline, { getGlobalTimeline, getUserTimeline } from '@/api/timeline';

export default {
  state: {
    timeline: [],
  },
  actions: {
    async getUserTimeline({ commit }, { id, userType, page, coachesActivities, usersActivities, processesActivities }) {
      const response = await getUserTimeline({
        id,
        userType,
        page,
        ...coachesActivities && { coachesActivities },
        ...usersActivities && { usersActivities },
        ...processesActivities && { processesActivities },

      });

      commit('setTimeline', response.data.timeline);

      return response;
    },
    async getGlobalTimeline({ commit }, { page }) {
      const response = await getGlobalTimeline(page);

      commit('setTimeline', response.data.timeline);

      return response;
    },
    async deleteNote(context, id) {
      return await timeline.deleteNote(id);
    },
    unshiftAction({ commit }, action) {
      commit('unshiftAction', action);
    },
    updateAction({ commit }, action) {
      commit('updateAction', action);
    },
    deleteAction({ commit }, action) {
      commit('deleteAction', action);
    },
    resetTimeline({ commit }) {
      commit('resetTimeline');
    },
  },
  mutations: {
    setTimeline(state, timeline) {
      state.timeline = [...state.timeline, ...timeline];
    },
    updateAction(state, action) {
      const index = state.timeline.findIndex(o => o._id === action.id);

      Vue.set(state.timeline, index, { ...state.timeline[index], ...action.content });
    },
    unshiftAction(state, action) {
      state.timeline.unshift(action);
    },
    deleteAction(state, id) {
      const isInTimeline = state.timeline.some(timeline => timeline._id === id);

      if (isInTimeline) {
        state.timeline = state.timeline.filter(timeline => timeline._id !== id);
      }
    },
    resetTimeline(state) {
      state.timeline = [];
    },
  },
};
