export const trialChannelsList = [
  {
      "createdBy": null,
      "updatedAt": "2023-07-24T13:09:52.795Z",
      "id": "ecd82277-aa77-4d60-b1a7-f4ca678578e3",
      "connectorName": "hellowork",
      "outputType": "XML_CLOUD",
      "title": "Hellowork",
      "description": "Agrégateur généraliste",
      "url": "https://www.hellowork.com/",
      "logoUrl": "https://xtramilefileshare.blob.core.windows.net/xtramile-prod-public-fileshare/assets/channels/hellowork.png",
      "allowsEmailApply": false,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": false,
      "allowsCredit": true,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "CONTRACT_ID": {
                  "type": "string",
                  "description": "L'identifiant du contrat qui vous permet de publier des annonces payantes. Nécessaire uniquement si vous sélectionnez 'crédit' comme méthode de pricing."
              },
              "X_AGENCE": {
                  "type": "string",
                  "description": "Nom de l'agence, utilisé pour des besoins de statistiques par Hellowork. Par défaut, nous utiliserons office.title."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-04-05T15:38:39.640Z",
      "id": "9bd5d63d-08e2-494f-84a2-99eda7d819b3",
      "connectorName": "adzuna",
      "outputType": "XML_CLOUD",
      "title": "Adzuna",
      "description": "Nous cherchons des offres d'emploi sur des milliers de sites pour vous éviter de le faire",
      "url": "www.adzuna.fr",
      "logoUrl": "https://xtramilefileshare.blob.core.windows.net/xtramile-prod-public-fileshare/assets/channels/adzuna.png",
      "allowsEmailApply": false,
      "allowsUrlApply": true,
      "allowsDirectApply": true,
      "allowsCpc": true,
      "allowsOrganic": true,
      "allowsCredit": false,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "CONTRACT_ID": {
                  "type": "string",
                  "description": "L'identifiant du contrat qui vous permet de publier des annonces payantes. Nécessaire uniquement si vous sélectionnez 'cpc' comme méthode de pricing."
              },
              "DIRECT_APPLY_TYPE": {
                  "enum": [
                      "Webhook",
                      "JobContactEmail"
                  ],
                  "type": "string",
                  "description": "Nécessaire si vous sélectionnez 'directApply' comme méthode de réception du CV. Choisissez 'Webhook' pour recevoir les CV par requêtes HTTP, 'JobContactEmail' pour les recevoir par e-mail."
              },
              "X_DIRECT_APPLY_API_KEY": {
                  "type": "string",
                  "description": "Clé API fournie par Adzuna à l'entité responsable de la diffusion des offres d'emploi, afin de mettre en place l'option 'directApply'. Par défaut, nous utiliserons la clé API de Xtramile."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-05-30T08:39:13.933Z",
      "id": "912f670d-f3b4-4cb6-9390-b0c34838bc69",
      "connectorName": "jobrapido",
      "outputType": "XML_CLOUD",
      "title": "Jobrapido",
      "description": "Agrégateur généraliste",
      "url": "https://fr.jobrapido.com",
      "logoUrl": "https://xtramilefileshare.blob.core.windows.net/xtramile-prod-public-fileshare/assets/channels/jobrapido.png",
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": true,
      "allowsCpc": true,
      "allowsOrganic": true,
      "allowsCredit": false,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "CONTRACT_ID": {
                  "type": "string",
                  "description": "L'identifiant du contrat qui vous permet de publier des annonces payantes. Nécessaire uniquement si vous sélectionnez 'cpc' comme méthode de pricing."
              },
              "DIRECT_APPLY_TYPE": {
                  "enum": [
                      "Webhook",
                      "JobContactEmail"
                  ],
                  "type": "string",
                  "description": "Nécessaire si vous sélectionnez 'directApply' comme méthode de réception du CV. Choisissez 'Webhook' pour recevoir les CV par requêtes HTTP, 'JobContactEmail' pour les recevoir par e-mail."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-07-19T21:36:04.923Z",
      "id": "2d782825-1345-45d2-8abe-24096bbd9437",
      "connectorName": "cadremploi",
      "outputType": "XML_CLOUD",
      "title": "Cadremploi",
      "description": "Site de recrutement dédié aux cadres",
      "url": "https://www.cadremploi.fr",
      "logoUrl": "https://xtramilefileshare.blob.core.windows.net/xtramile-prod-public-fileshare/assets/channels/cadremploi.png",
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": false,
      "allowsCredit": true,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [
              "CLIENT_PAR",
              "CLIENT_POUR"
          ],
          "properties": {
              "CLIENT_PAR": {
                  "type": "string",
                  "description": "Le code Cadremploi.fr du client déposant. Vous devez contacter Cadremploi pour l'obtenir."
              },
              "CLIENT_POUR": {
                  "type": "string",
                  "description": "Le code Cadremploi.fr du client destinataire. Vous devez contacter Cadremploi pour l'obtenir."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-05-30T08:39:14.056Z",
      "id": "48040cc0-ae6c-4edf-b513-437173c0a081",
      "connectorName": "jooble",
      "outputType": "XML_CLOUD",
      "title": "Jooble",
      "description": "Agrégateur généraliste",
      "url": "https://jooble.org/",
      "logoUrl": "https://xtramilefileshare.blob.core.windows.net/xtramile-prod-public-fileshare/assets/channels/jooble.png",
      "allowsEmailApply": false,
      "allowsUrlApply": true,
      "allowsDirectApply": true,
      "allowsCpc": true,
      "allowsOrganic": false,
      "allowsCredit": false,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "CONTRACT_ID": {
                  "type": "string",
                  "description": "L'identifiant du contrat qui vous permet de publier des annonces payantes. Nécessaire uniquement si vous sélectionnez 'cpc' comme méthode de pricing."
              },
              "DIRECT_APPLY_TYPE": {
                  "enum": [
                      "Webhook",
                      "JobContactEmail"
                  ],
                  "type": "string",
                  "description": "Nécessaire si vous sélectionnez 'directApply' comme méthode de réception du CV. Choisissez 'Webhook' pour recevoir les CV par requêtes HTTP, 'JobContactEmail' pour les recevoir par e-mail."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-07-24T13:09:52.832Z",
      "id": "b77e6007-cda0-4812-bbec-31413eb532bf",
      "connectorName": "fashionjobs",
      "outputType": "XML_CLOUD",
      "title": "Fashionjobs",
      "description": "Agrégateur généraliste",
      "url": "https://www.fashionjobs.com/",
      "logoUrl": "https://xtramilefileshare.blob.core.windows.net/xtramile-prod-public-fileshare/assets/channels/fashionjobs.png",
      "allowsEmailApply": false,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": false,
      "allowsCredit": true,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "CONTRACT_ID": {
                  "type": "string",
                  "description": "L'identifiant du contrat qui vous permet de publier des annonces payantes. Nécessaire uniquement si vous sélectionnez 'crédit' comme méthode de pricing."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-04-05T15:38:39.640Z",
      "id": "4c45b3fe-bb3c-43a3-9891-c8c930a0afeb",
      "connectorName": "talent",
      "outputType": "XML_CLOUD",
      "title": "Talent.com",
      "description": "Agrégateur généraliste",
      "url": "https://neuvoo.fr/",
      "logoUrl": "https://xtramilefileshare.blob.core.windows.net/xtramile-prod-public-fileshare/assets/channels/talent.png",
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": true,
      "allowsCpc": true,
      "allowsOrganic": true,
      "allowsCredit": false,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "CONTRACT_ID": {
                  "type": "string",
                  "description": "L'identifiant du contrat qui vous permet de publier des annonces payantes. Nécessaire uniquement si vous sélectionnez 'cpc' comme méthode de pricing."
              },
              "DIRECT_APPLY_TYPE": {
                  "enum": [
                      "Webhook",
                      "JobContactEmail"
                  ],
                  "type": "string",
                  "description": "Nécessaire si vous sélectionnez 'directApply' comme méthode de réception du CV. Choisissez 'Webhook' pour recevoir les CV par requêtes HTTP, 'JobContactEmail' pour les recevoir par e-mail."
              },
              "X_PUBLISHER": {
                  "type": "string",
                  "description": "Nom de l'entité responsable pour la diffusion des offres d'emploi. Par défaut, nous utiliserons la valeur 'Xtramile'."
              },
              "X_PUBLISHER_URL": {
                  "type": "string",
                  "description": "Site internet de l'entité responsable de la diffusion des offres d'emploi. Par défaut, nous utiliserons la valeur 'https://www.xtramile.io'."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-07-24T13:09:52.738Z",
      "id": "a9b281ce-cc79-4955-bf28-62f692e6f4f1",
      "connectorName": "apec",
      "outputType": "API_SOAP",
      "title": "Apec",
      "description": "Agrégateur généraliste",
      "url": "https://www.apec.org/",
      "logoUrl": "https://xtramilefileshare.blob.core.windows.net/xtramile-prod-public-fileshare/assets/channels/apec.png",
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": true,
      "allowsCredit": false,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [
              "NAF_CODE",
              "RECRUITER_ID",
              "NUMERO_DOSSIER"
          ],
          "properties": {
              "SALT": {
                  "type": "string"
              },
              "ATS_ID": {
                  "type": "string"
              },
              "NAF_CODE": {
                  "type": "string"
              },
              "PASSWORD": {
                  "type": "string"
              },
              "STATUS_JOB": {
                  "enum": [
                      "CADRE_PRIVE",
                      "CADRE_PUBLIC",
                      "AGENT_DE_MAITRISE"
                  ],
                  "type": "string"
              },
              "RECRUITER_ID": {
                  "type": "string"
              },
              "NUMERO_DOSSIER": {
                  "type": "string"
              },
              "DEPLACEMENT_ZONE": {
                  "enum": [
                      "AUCUN",
                      "MAISON",
                      "DEPARTEMENT",
                      "REGIONAL",
                      "NATIONAL",
                      "UE",
                      "HORS_UE"
                  ],
                  "type": "string"
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-08-02T17:33:40.784Z",
      "id": "6683684c-9fa2-4a0a-8572-faf26b373064",
      "connectorName": "joblift",
      "outputType": "XML_CLOUD",
      "title": "Joblift",
      "description": "Joblift: all jobs on one platform",
      "url": "https://joblift.com/",
      "logoUrl": "https://xtramilefileshare.blob.core.windows.net/xtramile-prod-public-fileshare/assets/channels/joblift.png",
      "allowsEmailApply": false,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": true,
      "allowsOrganic": true,
      "allowsCredit": false,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "CONTRACT_ID": {
                  "type": "string",
                  "description": "L'identifiant du contrat qui vous permet de publier des annonces payantes. Nécessaire uniquement si vous sélectionnez 'cpc' comme méthode de pricing."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-08-07T18:50:14.014Z",
      "id": "aeaf483b-a73f-49e9-ace5-2db228e8e2ed",
      "connectorName": "jobintree",
      "outputType": "XML_CLOUD",
      "title": "Jobintree",
      "description": "Agrégateur généraliste",
      "url": "https://www.jobintree.com/",
      "logoUrl": "https://xtramilefileshare.blob.core.windows.net/xtramile-prod-public-fileshare/assets/channels/jobintree.png",
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": true,
      "allowsOrganic": false,
      "allowsCredit": false,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "CONTRACT_ID": {
                  "type": "string",
                  "description": "L'identifiant du contrat qui vous permet de publier des annonces payantes. Nécessaire uniquement si vous sélectionnez 'cpc' comme méthode de pricing."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-08-10T11:35:32.443Z",
      "id": "d3456138-e74f-4fa4-a0a4-f8b4422429c1",
      "connectorName": "holeest",
      "outputType": "XML_CLOUD",
      "title": "Holeest",
      "description": "https://www.holeest.com/fr/",
      "url": "https://www.holeest.com/fr/",
      "logoUrl": "https://xtramilefileshare.blob.core.windows.net/xtramile-prod-public-fileshare/assets/channels/holeest.png",
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": true,
      "allowsOrganic": true,
      "allowsCredit": false,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "X_PUBLISHER": {
                  "type": "string"
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-08-23T14:40:20.705Z",
      "id": "c992feab-f75d-408f-b5cb-86a97aff6d13",
      "connectorName": "welcometothejungle",
      "outputType": "API_REST",
      "title": "Welcome to the Jungle",
      "description": "Discover the backstage of your future company",
      "url": "https://www.welcometothejungle.com/",
      "logoUrl": null,
      "allowsEmailApply": false,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": false,
      "allowsCredit": true,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [
              "ORGANIZATION_REFERENCE"
          ],
          "properties": {
              "ORGANIZATION_REFERENCE": {
                  "type": "string",
                  "description": "L'identifiant de l'organisation, fourni par Welcometothejungle pendant le processus d'onboarding."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-08-31T10:34:35.725Z",
      "id": "9e2e446b-3ec4-4f63-8b1f-64e8636f280d",
      "connectorName": "staffsante",
      "outputType": "XML_CLOUD",
      "title": "Staffsanté",
      "description": "Référence pour vos recrutements dans le domaine de la santé",
      "url": "https://www.staffsante.fr/",
      "logoUrl": null,
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": false,
      "allowsCredit": true,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "CONTRACT_ID": {
                  "type": "string",
                  "description": "L'identifiant du contrat qui vous permet de publier des annonces payantes. Nécessaire uniquement si vous sélectionnez 'crédit' comme méthode de pricing."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-09-13T12:36:15.091Z",
      "id": "38a78330-d003-4fec-83a7-edf4199bd4ea",
      "connectorName": "yupeek",
      "outputType": "XML_CLOUD",
      "title": "Yupeek",
      "description": "https://my.yupeek.com/",
      "url": "https://my.yupeek.com/",
      "logoUrl": null,
      "allowsEmailApply": true,
      "allowsUrlApply": false,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": false,
      "allowsCredit": true,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [
              "COMPANY_ID"
          ],
          "properties": {
              "COMPANY_ID": {
                  "type": "string",
                  "description": "L'identifiant de l'entreprise, fourni par Yupeek pendant le processus d'onboarding."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-09-13T12:36:15.184Z",
      "id": "ca37774b-7bcf-4132-afed-adca040e7b4a",
      "connectorName": "engagementjeunes",
      "outputType": "XML_CLOUD",
      "title": "Engagement Jeunes",
      "description": "https://www.engagement-jeunes.com/",
      "url": "https://www.engagement-jeunes.com/",
      "logoUrl": null,
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": true,
      "allowsCredit": false,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {},
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-09-13T12:36:15.218Z",
      "id": "08fe7f73-7568-4340-8a11-6f5afecbb1c4",
      "connectorName": "emploisoignant",
      "outputType": "XML_CLOUD",
      "title": "Emploi Soignant",
      "description": "https://www.emploisoignant.com/",
      "url": "https://www.emploisoignant.com/",
      "logoUrl": null,
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": false,
      "allowsCredit": true,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "CONTRACT_ID": {
                  "type": "string",
                  "description": "L'identifiant du contrat qui vous permet de publier des annonces payantes. Nécessaire uniquement si vous sélectionnez 'crédit' comme méthode de pricing."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-09-13T12:36:15.263Z",
      "id": "0b5b4e1f-e704-4022-919c-0590dc7fcde3",
      "connectorName": "glassdoor",
      "outputType": "XML_CLOUD",
      "title": "Glassdoor",
      "description": "https://www.glassdoor.com/",
      "url": "https://www.glassdoor.com/",
      "logoUrl": null,
      "allowsEmailApply": true,
      "allowsUrlApply": false,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": true,
      "allowsCredit": false,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {},
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-08-02T17:33:40.685Z",
      "id": "ea8425ef-713d-42d6-be32-7a17bea5245f",
      "connectorName": "meteojob",
      "outputType": "XML_CLOUD",
      "title": "Meteojob",
      "description": "Meteojob est un jobboard généraliste",
      "url": "https://www.meteojob.com/",
      "logoUrl": "https://xtramilefileshare.blob.core.windows.net/xtramile-prod-public-fileshare/assets/channels/meteojob.png",
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": false,
      "allowsCredit": true,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "CONTRACT_ID": {
                  "type": "string",
                  "description": "L'identifiant du contrat qui vous permet de publier des annonces payantes. Nécessaire uniquement si vous sélectionnez 'crédit' comme méthode de pricing."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-08-09T08:21:07.671Z",
      "id": "f2856e1f-adc7-47ee-a19d-02afa0f4a439",
      "connectorName": "leboncoin",
      "outputType": "API_REST",
      "title": "Leboncoin",
      "description": "Leboncoin",
      "url": "https://www.leboncoin.fr/",
      "logoUrl": "https://xtramilefileshare.blob.core.windows.net/xtramile-prod-public-fileshare/assets/channels/leboncoin.png",
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": false,
      "allowsCredit": true,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [
              "CLIENT_ID"
          ],
          "properties": {
              "CLIENT_ID": {
                  "type": "number",
                  "description": "L'identifiant Morpheus, fourni par Leboncoin pendant le processus d'onboarding."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-08-23T14:40:20.749Z",
      "id": "ece9bf2a-8a60-411a-a590-7bf2749f96b1",
      "connectorName": "goldenbees",
      "outputType": "XML_CLOUD",
      "title": "Goldenbees",
      "description": "Agrégateur généraliste",
      "url": "https://www.goldenbees.fr/",
      "logoUrl": null,
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": true,
      "allowsOrganic": false,
      "allowsCredit": false,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "CONTRACT_ID": {
                  "type": "string",
                  "description": "L'identifiant du contrat qui vous permet de publier des annonces payantes. Nécessaire uniquement si vous sélectionnez 'cpc' comme méthode de pricing."
              }
          }
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-09-13T12:36:15.327Z",
      "id": "68ff1e07-fda3-4091-a40b-7e04849a37e0",
      "connectorName": "jobijoba",
      "outputType": "XML_CLOUD",
      "title": "JobiJoba",
      "description": "https://www.jobijoba.com/",
      "url": "https://www.jobijoba.com/",
      "logoUrl": null,
      "allowsEmailApply": false,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": true,
      "allowsOrganic": false,
      "allowsCredit": false,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "CONTRACT_ID": {
                  "type": "string",
                  "description": "L'identifiant du contrat qui vous permet de publier des annonces payantes. Nécessaire uniquement si vous sélectionnez 'cpc' comme méthode de pricing."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-05-30T08:39:14.111Z",
      "id": "3b6d8e4c-c2fe-4d59-98ed-d069fd55be77",
      "connectorName": "monster",
      "outputType": "XML_CLOUD",
      "title": "Monster",
      "description": "Agrégateur généraliste",
      "url": "https://www.monster.fr/",
      "logoUrl": "https://xtramilefileshare.blob.core.windows.net/xtramile-prod-public-fileshare/assets/channels/monster.png",
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": true,
      "allowsCpc": true,
      "allowsOrganic": true,
      "allowsCredit": true,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "CONTRACT_ID": {
                  "type": "string",
                  "description": "L'identifiant du contrat qui vous permet de publier des annonces payantes. Nécessaire uniquement si vous sélectionnez 'cpc' ou 'crédit' comme méthode de pricing."
              },
              "DIRECT_APPLY_TYPE": {
                  "enum": [
                      "Webhook",
                      "JobContactEmail"
                  ],
                  "type": "string",
                  "description": "Nécessaire si vous sélectionnez 'directApply' comme méthode de réception du CV. Choisissez 'Webhook' pour recevoir les CV par requêtes HTTP, 'JobContactEmail' pour les recevoir par e-mail."
              },
              "X_DIRECT_APPLY_API_KEY": {
                  "type": "string",
                  "description": "Clé API fournie par Monster à l'entité responsable de la diffusion des offres d'emploi, afin de mettre en place l'option 'directApply'. Par défaut, nous utiliserons la clé API de Xtramile."
              },
              "X_PUBLISHER": {
                  "type": "string",
                  "description": "Nom de l'entité responsable pour la diffusion des offres d'emploi. Par défaut, nous utiliserons la valeur 'Xtramile'."
              },
              "X_PUBLISHER_URL": {
                  "type": "string",
                  "description": "Site internet de l'entité responsable de la diffusion des offres d'emploi. Par défaut, nous utiliserons la valeur 'https://www.xtramile.io'."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-05-26T12:03:52.984Z",
      "id": "6f985d94-0213-4bff-8076-19532b679cb5",
      "connectorName": "indeed",
      "outputType": "XML_CLOUD",
      "title": "indeed",
      "description": "Sur Indeed, accédez à des millions d'offres demploi.",
      "url": "www.indeed.com",
      "logoUrl": "https://xtramilefileshare.blob.core.windows.net/xtramile-prod-public-fileshare/assets/channels/indeed.png",
      "allowsEmailApply": true,
      "allowsUrlApply": false,
      "allowsDirectApply": true,
      "allowsCpc": false,
      "allowsOrganic": false,
      "allowsCredit": false,
      "allowsSponsored": true,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "CONTRACT_ID": {
                  "type": "string",
                  "description": "L'identifiant du contrat qui vous permet de publier des annonces payantes. Nécessaire uniquement si vous sélectionnez 'sponsored' comme méthode de pricing."
              },
              "DIRECT_APPLY_TYPE": {
                  "enum": [
                      "Webhook",
                      "JobContactEmail"
                  ],
                  "type": "string",
                  "description": "Nécessaire si vous sélectionnez 'directApply' comme méthode de réception du CV. Choisissez 'Webhook' pour recevoir les CV par requêtes HTTP, 'JobContactEmail' pour les recevoir par e-mail."
              },
              "DIRECT_APPLY_API_KEY": {
                  "type": "string",
                  "description": "Nécessaire si vous sélectionnez 'directApply' comme méthode de réception du CV. Clé API fournie par Indeed à chaque client, pour son propre compte, afin de mettre en place l'option 'directApply'."
              },
              "X_PUBLISHER": {
                  "type": "string",
                  "description": "Nom de l'entité responsable pour la diffusion des offres d'emploi. Par défaut, nous utiliserons la valeur 'Xtramile'."
              },
              "X_PUBLISHER_URL": {
                  "type": "string",
                  "description": "Site internet de l'entité responsable de la diffusion des offres d'emploi. Par défaut, nous utiliserons la valeur 'https://www.xtramile.io'."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-10-20T08:12:05.986Z",
      "id": "3a5c824a-8b6a-415e-b434-ee47d1c360a1",
      "connectorName": "vdab",
      "outputType": "API_SOAP",
      "title": "VDAB",
      "description": "VDAB is the employment service of Flanders.",
      "url": "https://www.vdab.be/",
      "logoUrl": null,
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": true,
      "allowsCredit": false,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {
              "SUPPLIER_ID": {
                  "type": "string"
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-10-20T08:12:06.033Z",
      "id": "493dc3ef-45e0-4dbf-97fc-aa793b6d225f",
      "connectorName": "uitzendbureaunl",
      "outputType": "XML_CLOUD",
      "title": "Uitzendbureau.nl",
      "description": "Grootste vacaturesite voor werk via een uitzendbureau! Vacatures van alle uitzendbureaus voor flexibel werken, tijdelijk werk of vaste baan.",
      "url": "https://www.uitzendbureau.nl/",
      "logoUrl": null,
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": true,
      "allowsCredit": false,
      "allowsSponsored": true,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {},
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-10-20T08:12:06.059Z",
      "id": "ae09fb98-314a-44b4-ab83-0ecc85b26f11",
      "connectorName": "stepstone",
      "outputType": "XML_CLOUD",
      "title": "Stepstone",
      "description": "The Stepstone Group is one of the world's leading job platforms.",
      "url": "https://stepstone.be/",
      "logoUrl": null,
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": false,
      "allowsCredit": false,
      "allowsSponsored": true,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {},
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-10-20T08:12:06.086Z",
      "id": "0f14effa-fbc8-40ba-b567-f6e7066c44f3",
      "connectorName": "ictjob.be",
      "outputType": "API_XML",
      "title": "ictjob.be",
      "description": "ictjob.be est un site emploi innovant spécialisé sur le marché de l’emploi IT en Belgique.\n",
      "url": "https://www.ictjob.be/",
      "logoUrl": null,
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": false,
      "allowsCredit": false,
      "allowsSponsored": true,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [
              "HAS_HTML_IN_JOB_FIELDS"
          ],
          "properties": {
              "RECRUITER_USERNAME": {
                  "type": "string",
                  "description": "Nom d'utilisateur du compte recruteur donné par ictjob.be"
              },
              "RECRUITER_PASSWORD": {
                  "type": "string",
                  "description": "Mot de passe du compte recruteur donné par ictjob.be"
              },
              "HAS_HTML_IN_JOB_FIELDS": {
                  "type": "boolean",
                  "description": "Défini sur true si les champs de description du poste vacant sont déjà formatés avec des balises HTML."
              }
          },
          "additionalProperties": false
      }
  },
  {
      "createdBy": null,
      "updatedAt": "2023-10-23T10:07:25.738Z",
      "id": "be8e2724-8f41-4283-9229-ef8412ac2d5e",
      "connectorName": "jobat.be",
      "outputType": "XML_CLOUD",
      "title": "jobat.de",
      "description": "Offres d'emploi en Wallonie, Bruxelles et Flandres",
      "url": "https://www.jobat.be/",
      "logoUrl": null,
      "allowsEmailApply": true,
      "allowsUrlApply": true,
      "allowsDirectApply": false,
      "allowsCpc": false,
      "allowsOrganic": false,
      "allowsCredit": true,
      "allowsSponsored": false,
      "accountParamsJsonSchema": {
          "type": "object",
          "required": [],
          "properties": {},
          "additionalProperties": false
      }
  }
]
