<template>
  <filter-content :label="remoteConfig.name" :icon="remoteConfig.icon">
    <div class="filter-coders-remote__radios">
      <bc-checkbox
        id="full-time"
        name="full-time"
        @input="handleCheck('100%')"
        :value="selectedTab.includes('100%')">
        {{ $t('panel-coder-edit.full-time') }}
      </bc-checkbox>
      <bc-checkbox
        id="partial"
        name="partial"
        @input="handleCheck('50%')"
        :value="selectedTab.includes('50%')">
        {{ $t('panel-coder-edit.partial') }}
      </bc-checkbox>
      <bc-checkbox
        id="possible"
        name="possible"
        @input="handleCheck(possible)"
        :value="selectedTab.some(v => possible?.includes(v))">
        {{ $t('panel-coder-edit.possible') }}
      </bc-checkbox>
    </div>
  </filter-content>
</template>

<script setup lang="ts">
import { computed, ref, defineProps, defineEmits, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { useStore } from '@/store';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
import FilterContent from '@/components/Filter/FilterContent';

const props = defineProps<{
  emitOnly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:filters', filters: Record<string, any>): void;
  (e: 'count', value: { key: string; count: number }): void;
}>();

const route = useRoute();
const router = useRouter();
const store = useStore();

const configurationCandidate = computed(() => store.getters['user/configurationCandidate']);

const localSelected = ref<string | undefined>();

onMounted(() => {
  if (props.emitOnly && route.query['cf-remote']) {
    localSelected.value = route.query['cf-remote'].toString();
  }
});

const selected = computed(() => props.emitOnly ? localSelected.value : route.query['cf-remote']);

const selectedTab = computed(() => {
  if (!selected.value) return [];
  return selected.value.toString().split(',');
});

const remoteConfig = computed(() => 
  configurationCandidate.value?.customFields?.find(cf => cf.key === 'remote') || {}
);

const possible = computed(() => {
  const options = [...remoteConfig.value.options].filter(opt => opt !== '50%');
  options.shift();
  options.pop();
  return options.join(',');
});

const count = computed(() => 
  (selectedTab.value.includes('100%') ? 1 : 0) +
  (selectedTab.value.includes('50%') ? 1 : 0) +
  (selectedTab.value.some(v => possible.value?.includes(v)) ? 1 : 0)
);

const updateValue = (value: string) => {
  let results = selectedTab.value;
  const valueTab = value.split(',');
  if (valueTab.some(v => results.includes(v))) {
    results = results.filter(v => !valueTab.includes(v));
  } else {
    results = [...results, ...valueTab];
  }
  return results.join(',');
};

const handleCheck = (value: string) => {
  const results = updateValue(value);
  const newValue = results || undefined;
  
  if (props.emitOnly) {
    localSelected.value = newValue;
    emit('update:filters', {
      'cf-remote': newValue
    });
  } else {
    router.push({
      query: {
        ...route.query,
        page: 1,
        'cf-remote': newValue,
      },
    });
  }
};

watch(count, (value) => {
  emit('count', { key: 'cf-remote', count: value });
}, { immediate: true });
</script>

<style lang="scss" scoped>
.filter-coders-remote {
  &__radios {
    display: flex;
    gap: 15px;
    margin-top: 5px;
  }
}
</style>
