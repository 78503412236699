<template>
  <div class="panel-favorite-list-manage-resource-lists-item">
    <div
      class="panel-favorite-list-manage-resource-lists-item__row">
      <div
        v-tooltip="tooltip"
        class="panel-favorite-list-manage-resource-lists-item__wrapper">
        <bc-checkbox
          :disabled="!hasRightsToEditList"
          :label="list.name"
          :value="list.isListed || isAllSelectedCardsListed"
          bc-checkbox-border-color="blue-dark-cello"
          bc-checkbox-check-color="blue-dodger"
          bc-label-color="#1D395E"
          bc-label-font-size="16px"
          bc-label-padding="0 0 0 20px"
          class="panel-favorite-list-manage-resource-lists-item__checkbox"
          @input="setIsListed($event)">
        </bc-checkbox>
      </div>

      <bc-avatar
        :bc-img-src="listDetails.ownerPictureUrl"
        bc-avatar-size="24px"
        bc-img-alt="owner avatar"
        class="panel-favorite-list-manage-resource-lists-item__avatar">
      </bc-avatar>

      <p
        class="panel-favorite-list-manage-resource-lists-item__text panel-favorite-list-manage-resource-lists-item__text--blue">
        {{ listDetails.count }}
      </p>

      <v-popover
        id="panel-favorite-list-manage-resource-lists-item"
        :trigger="list.subscribers.length > 0 ? 'hover click' : ''">
        <p
          class="panel-favorite-list-manage-resource-lists-item__text panel-favorite-list-manage-resource-lists-item__text--grey">
          {{ $tc('generics.share-count', listDetails.share) }}
        </p>
        <template
          slot="popover">
          <div class="panel-favorite-list-manage-resource-lists-item__shared">
            <p
              v-for="(subscriber, index) in list.subscribers"
              :key="index"
              class="panel-favorite-list-manage-resource-lists-item__shared-name">
              {{ subscriber.firstName }} {{ subscriber.lastName }}
            </p>
          </div>
        </template>
      </v-popover>

      <div
        v-if="!list.locked"
        class="panel-favorite-list-manage-resource-lists-item__settings"
        @click="goToEditList(list._id)">
        <div class="icon-settings"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import BcCheckbox from '@/legacy/ui-kit/components/Checkbox/BcCheckbox';
  import BcAvatar from '@/legacy/ui-kit/components/Avatar/BcAvatar';
  // macros
  import favoriteListsMacros from '@/macros/favoriteLists/favoriteLists';

  const dispatcherNameToAdd = ['addCoderToFavoriteList', 'addJobToFavoriteList', 'addCompanyToFavoriteList', 'addCoderToFavoriteList'];
  const dispatcherNameToRemove = ['deleteCoderFromFavoriteList', 'removeJobFromFavoriteList', 'removeCompanyFromFavoriteList', 'deleteCoderFromFavoriteList'];

  const i18nEntitiesCount = [
  'generics.candidates-count',
  'generics.jobs-count',
  'generics.companies-count',
  'generics.contacts-count',
  ];

export default {
    name: 'panel-favorite-list-manage-resource-lists-item',
    components: { BcAvatar, BcCheckbox },
    props: {
      list: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        resourceName: [this.$i18n.t('companies.panel-company.candidate'), this.$i18n.t('companies.panel-company.job'), this.$i18n.t('companies.panel-company.company'), this.$i18n.t('companies.panel-company.contact')],
      };
    },
    computed: {
      ...mapState({
        user: state => state.user.profile,
        selectedCards: state => state.card.selectedCards,
      }),
      ...mapGetters(['cardActive']),
      listOwner() {
        return this.list.author;
      },
      i18nEntityCountKey() {
        return i18nEntitiesCount[this.list.type - 1];
      },
      listDetails() {
        return {
          ...this.listOwner && { ownerPictureUrl: this.listOwner.pictureUrl },
          count: this.$i18n.tc(this.i18nEntityCountKey, this.list.elementsCount),
          share: this.list.subscribers.length - 1,
        };
      },
      hasRightsToEditList() {
        const user = [this.list.author, ...this.list.subscribers].filter(e => e).find(subscriber => subscriber._id === this.user?._id);

        return user && user.role !== favoriteListsMacros.apiRights.reader;
      },
      tooltip() {
        return {
          content: !this.hasRightsToEditList ? this.$i18n.t('companies.panel-company.only-administrators-can-modifiate-list') : '',
        };
      },
      isAllSelectedCardsListed() {
        if (!this.selectedCards.length) {
          return;
        }

        return this.selectedCards?.every(card => card?._lists?.some(list => list._id === this.list._id));
      },
    },
    methods: {
      setIsListed(val) {
        if (val) {
          if (this.selectedCards.length) {
            this.addMultipleResourcesToList();
          } else {
            this.addResourceToList(this.list._id, this.list.name);
          }
        } else {
          if (this.selectedCards.length) {
            this.removeMultipleResourcesToList();
          } else {
            this.deleteResourceFromList(this.list._id, this.list.name);
          }
        }
      },
      async addResourceToList(listId, listName) {
        try {
          await this.$store.dispatch(dispatcherNameToAdd[this.list.type - 1], {
            listId,
            resourceId: this.$route.params.id,
            ...this.cardActive > -1 && { cardIndex: this.cardActive },
          });

          this.$emit('resource-added-to-list');

          this.$toast.show({
            message: `${this.resourceName[this.list.type - 1]} ` + this.$i18n.t('companies.panel-company.added-to-the-list') + ` ${listName}`,
            icon: 'check',
          });
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      async deleteResourceFromList(listId, listName) {
        try {
          await this.$store.dispatch(dispatcherNameToRemove[this.list.type - 1], {
            listId,
            resourceId: this.$route.params.id,
            cardIndex: this.cardActive,
          });

          this.$emit('resource-removed-to-list');

          this.$toast.show({
            message: `${this.resourceName[this.list.type - 1]} `+ this.$i18n.t('companies.panel-company.removed-from-the-list') + ` ${listName}`,
            type: 'success',
          });
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      updateBackgroundCards(card, data) {
        this.$store.dispatch('updateCardFromId', {
          id: card._id,
          content: data,
        });

        this.$store.dispatch('updateSelectedCardFromId', {
          id: card._id,
          content: data,
        });
      },
      async addMultipleResourcesToList() {
        let selectedCardsToAdd = this.selectedCards.filter(card => !card._lists?.some(list => list._id === this.list._id));
        let count = 0;

        // Replace suggestions by coders on Reminders Panel
        if (this.$route.name === 'RemindersPanel') {
          selectedCardsToAdd = selectedCardsToAdd.map(card => card._coder);
        }

        this.$store.dispatch('setIsRequestProcessing', true);

        for (const card of selectedCardsToAdd) {
          try {
            const { data } = await this.$store.dispatch(dispatcherNameToAdd[this.list.type - 1], {
              listId: this.list._id,
              resourceId: card._id,
            });

            this.updateBackgroundCards(card, data);

            this.$emit('resource-added-to-list');

            ++count;
          } catch (error) {
            this.$toast.show({
              title: this.$i18n.t('toast.error'),
              type: 'error',
              message: error?.response?.data?.message || error,
            });
          }
        }

        this.$store.dispatch('setIsRequestProcessing', false);

        if (count) {
          this.$toast.show({
            title: this.$i18n.t('toast.success'),
            message: `${count} ${this.resourceName[this.list.type - 1].toLowerCase()}${count > 1 ? 's' : ''}` + " " + this.$i18n.t('companies.panel-company.added-to-the-list') + " " + `« ${this.list.name} »`,
            icon: 'check',
            type: 'success',
          });
        } else {
          this.$toast.show({
            title: this.$i18n.tc('generics.information', 1),
            message: this.$i18n.t('companies.panel-company.every') + ` ${this.resourceName[this.list.type - 1]} ` + this.$i18n.t('companies.panel-company.are-already-on-the-list') + ' ' + `« ${this.list.name} »`,
            icon: 'info-full',
            type: 'info',
          });
        }
      },
      async removeMultipleResourcesToList() {
        const selectedCardsToAdd = this.selectedCards.filter(card => card._lists?.some(list => list._id === this.list._id));
        let count = 0;

        this.$store.dispatch('setIsRequestProcessing', true);

        for (const card of selectedCardsToAdd) {
          try {
            const { data } = await this.$store.dispatch(dispatcherNameToRemove[this.list.type - 1], {
              listId: this.list._id,
              resourceId: card._id,
            });

            this.updateBackgroundCards(card, data);

            this.$emit('resource-removed-to-list');

            ++count;
          } catch (error) {
            this.$toast.show({
              title: this.$i18n.t('toast.error'),
              type: 'error',
              message: error?.response?.data?.message || error,
            });
          }
        }

        this.$store.dispatch('setIsRequestProcessing', false);

        if (count) {
          this.$toast.show({
            title: this.$i18n.t('toast.success'),
            message: `${count} ${this.resourceName[this.list.type - 1].toLowerCase()}${count > 1 ? 's' : ''} `+ this.$i18n.t('companies.panel-company.removed-from-the-list').toLowerCase() + ` « ${this.list.name} »`,
            icon: 'check',
            type: 'success',
          });
        } else {
          this.$toast.show({
            title: this.$i18n.tc('generics.information', 1),
            message: this.$i18n.t('companies.panel-company.every')  + ` ${this.resourceName[this.list.type - 1]} `+ this.$i18n.t('companies.panel-company.are-not-on-the-list') + ` « ${this.list.name} »`,
            icon: 'info-full',
            type: 'info',
          });
        }
      },
      goToEditList(listId) {
        this.$router.push({
          query: {
            ...this.$route.query,
            subtype: 'edit-favorite-list',
            'list-id': listId,
          },
        });
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-favorite-list-manage-resource-lists-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;

    &__default {
      flex-direction: column;
      text-align: center;
    }

    &__default-primary-text {
      color: $color-blue-dark-cello;
      line-height: 19px;
      margin-bottom: 10px;
    }

    &__default-secondary-text {
      color: $color-blue-dodger;
      text-decoration: underline;
      text-align: center;
      line-height: 19px;

      &:hover {
        cursor: pointer;
      }
    }

    &__row {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      min-height: 56px;
      justify-content: flex-start;
      padding: 10px 0;
      border-bottom: 1px solid #3C80F74D;
    }

    &__wrapper {
      margin-right: auto;
    }

    &__text {
      font-size: $font-size-m;
      text-align: right;
      line-height: 19px;
      margin-left: 20px;

      &--blue {
        color: $color-blue-dodger;
      }

      &--grey {
        color: $color-blue-dark-cello;
        opacity: 50%;
      }
    }

    &__avatar {
      overflow: hidden;
    }

    &__settings {
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      width: 50px;
      height: 35px;
      border: 1px solid #1D395E4D;
      border-radius: 5px;

      &:hover {
        cursor: pointer;

        .icon-settings {
          opacity: 0.9;
        }
      }
    }

    &__shared {
      flex-direction: column;
    }
  }

  .icon-settings {
    font-size: 20px;
    color: #1D395E;
    opacity: 0.5;
    transition: opacity 300ms ease;
  }

</style>
