import { TimelineType } from '@/domains/models/Timeline';

const timeline: {
  types: Record<TimelineType, TimelineType>;
} = {
  types: {
    all: 'all',
    notes: 'notes',
    activities: 'activities',
    reminders: 'reminders',
  },
};

export default {
  timeline,
};
