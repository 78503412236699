<template>
  <div class="process-timeline-item-validated">
    <div class="is-column is-full-width">
      <div
        :class="{ 'process-timeline-item-validated__container--expanded': isExpanded }"
        class="process-timeline-item-validated__container is-full-width">
        <bc-rich-text-editor-bubble
          :is-editable="false"
          class="process-timeline-item-validated__editor is-full-width font-size-s border-radius-m"
          placeholder="Commencer à écrire"
          ref="textarea"
          v-autosize="formattedContent"
          v-model="formattedContent"
          v-if="formattedContent">
        </bc-rich-text-editor-bubble>
      </div>
      <a
        @click="isExpanded = true"
        class="process-timeline-item-validated__see-more is-underline font-size-s"
        v-show="canBeExpanded && !isExpanded">
        {{ $t('companies.panel-view-timeline.see-more') }}
      </a>
      <div class="process-timeline-item-validated__evaluation">
        <p class="process-timeline-item-validated__evaluation-text">
          {{ $t('candidates.onboarding-assessment') }}
        </p>
        <span
          :class="{ 'process-timeline-item-validated__evaluation-star--full': i <= rating }"
          :key="i"
          class="process-timeline-item-validated__evaluation-star"
          v-for="i in 5">
          <i class="icon-star"/>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import timelineItemContent from '@/mixins/Timeline/timelineItemContent';
  import BcRichTextEditorBubble
    from '@/ui-kit/components/BcRichTextEditor/BcRichTextEditorBubble';

  export default {
    name: 'process-timeline-item-validated',
    components: { BcRichTextEditorBubble },
    props: {
      action: {
        type: Object,
        default: () => ({}),
      },
    },
    mixins: [timelineItemContent],
    data() {
      return {
        content: this.action?._process?.validated?.content || '',
      };
    },
    computed: {
      rating() {
        return this.action?._process?.validated?.rating;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/ui-kit/assets/scss/elements/_icons.scss';

  %fill-star {
    .icon-star {
      @extend .icon-star-full;
    }
  }

  %empty-star {
    .icon-star {
      @extend .icon-star
    }
  }

  .process-timeline-item-validated {
    padding: 10px 15px;
    border-top: 1px solid $color-gray-geyser;

    &__container {
      max-height: calc(3em + 2.5em);
      margin-bottom: 15px;

      &--expanded {
        max-height: initial;
      }
    }

    &__start {
      font-size: $font-size-s;
      color: $color-secondary;
      font-style: italic;
      font-weight: $font-weight-bold;
      margin-bottom: 8px;
    }

    &__editor {
      resize: none;
      min-height: 3em;
      color: $color-secondary-light;
      font-family: $font-family;

      & :deep() {
        .bc-rich-text-editor-bubble__textarea {
          overflow: hidden;
        }
      }
    }

    &__see-more {
      margin-top: -15px;
      color: $color-primary;
      margin-right: auto;
    }

    &__evaluation {
      align-items: center;
      margin-bottom: 10px;

      &-text {
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        margin-right: 15px;
      }

      &-star {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        font-size: 20px;
        color: $color-deal;

        &--empty {
          @extend %empty-star;
        }

        &--full {
          @extend %fill-star;
        }
      }
    }
  }
</style>
