<template>
  <div class="modal-linkedin-send-form">
    <ModalLinkedinHeader
      :title="title"
      :icon-name="iconName"
      @on-close="onClose"
    />
    <div v-if="isLinkedinLoading" class="modal-linkedin-send-form__content min-h-[50%] items-center justify-center">
      <BcSpinner />
    </div>
    <div v-else class="modal-linkedin-send-form__content">
      <LinkedinSendForm
        :coder="params.coder"
        :customJob="params.customJob"
        :action-id="params.actionId"
        :linkedin-message-type="params.linkedinMessageType"
        :warning-message="params.warningMessage"
        :selected-cards="selectedCoders"
        :selected-custom-jobs="selectedCustomJobs"
        @on-close="onClose"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from '@/store';
import { useI18n } from '@/i18n/i18n';
import LinkedinSendForm from '@/components/Linkedin/LinkedinSendForm.vue';
import ModalLinkedinHeader from '@/components/Modal/ModalLinkedin/ModalLinkedinHeader.vue';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import { useTimelineRefresh } from '@/composables/useTimelineRefresh';

const iconAndTitle = {
  LINKEDIN_INMAIL: { icon: 'linkedin-inmail', title: 'linkedin.send-an-inmail' },
  LINKEDIN_INVITATION: { icon: 'linkedin-invitation', title: 'linkedin.send-an-invitation' },
  LINKEDIN_MESSAGE: { icon: 'linkedin-message', title: 'linkedin.send-a-message' },
} as const;

const store = useStore();
const { refreshTimeline } = useTimelineRefresh();
const { t} = useI18n();
const params = computed(() => store.state.app.params);
const selectedCoders = computed(() => store.getters['emails/selectedCoders']);
const selectedCustomJobs = computed(() => store.getters['emails/selectedCustomJobs']);
const isLinkedinLoading = computed(() => store.getters['emails/isLoading']);

const iconName = computed(() => iconAndTitle[params.value.linkedinMessageType]?.icon);
const title = computed(() => t(iconAndTitle[params.value.linkedinMessageType]?.title));

const emit = defineEmits<{
  (e: 'close-modal'): void
}>();

async function onClose() {
  await refreshTimeline();
  emit('close-modal');
}
</script>

<style lang="scss" scoped>
.modal-linkedin-send-form {
  position: relative;
  flex-direction: column;
  width: 640px;
  overflow: auto;
}
</style>
