<template>
  <bc-dropdown :options="coachOptions" class="filters-dropdown-coach__dropdown">
    <template #label="{ isDropdownOpen }">
      <bc-dropdown-trigger
        :is-arrow="true"
        :is-dropdown-open="isDropdownOpen"
        :text="selectedCoachFilter.name"
        :is-filter="true"
        :icon="icon">
      </bc-dropdown-trigger>
    </template>
    <template #option="{ activeIndex, index, option }">
      <bc-dropdown-item
        :is-focus="activeIndex === index"
        :text="option.name"
        :title="option.name"
        @change="$emit('update:selected-coach-id', option.query)"
        @click.native="$emit('update:selected-coach-id', option.query)">
      </bc-dropdown-item>
    </template>
  </bc-dropdown>
</template>

<script setup lang="ts">
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger.vue';
import { getFilterablePoolCoach } from '@/macros/prospectionPipe/dropdowns';
import { computed, withDefaults, defineProps, defineEmits } from 'vue';
import { useStore } from '@/store';
import { useI18n } from '@/i18n/i18n';

const props = withDefaults(
  defineProps<{
    selectedCoachId?: string;
    hasNoneOption?: boolean;
    withInactive?: boolean;
    icon?: string;
  }>(),
  {
    hasNoneOption: true,
    withInactive: false,
    icon: 'user',
  }
);
const emit = defineEmits<{
  (e: 'update:selected-coach-id', id: string | undefined): void;
}>();
const store = useStore();
const coaches = computed(() => store.state.coaches?.coaches);
const { t } = useI18n();

function coachFullName(coach) {
  let fullName = coach.name || `${coach.firstName} ${coach.lastName}`;

  return coach.enabled
    ? fullName
    : `${coach.lastName === 'Désactivé' ? coach.email : fullName} - ${t('generics.disabled')}`;
}

const defaultOption = {
  name: t('process.everyone'),
  query: undefined,
};
const coachOptions = computed<{ name: string; query: string; enabled?: boolean }[]>(() => {
  const filteredCoaches = coaches.value
    .filter(coach => getFilterablePoolCoach(coach, props.withInactive))
    .map(coach => ({
      name: coachFullName(coach),
      query: coach._id,
      enabled: coach.enabled,
    }));

  return [
    defaultOption,
    ...filteredCoaches.sort((a, b) => a.name.localeCompare(b.name) && b.enabled - a.enabled),
  ];
});
const selectedCoachFilter = computed(() => {
  return (
    coachOptions.value.find(coach => coach.query === props.selectedCoachId) ?? {
      name: t('process.user-not-found'),
      query: props.selectedCoachId,
    }
  );
});
</script>

<style lang="scss" scoped>
.filters-dropdown-coach {
  div:first-child {
    button.bc-button__primary:nth-child(1) {
      margin-right: 8px;
    }
  }

  &__container {
    padding-left: 55px;
  }

  &__list {
    padding: 0;
  }

  &__wrapper {
    margin-left: auto;
    background: $color-grey-2;
    border: 1px solid $color-grey-2;
  }

  &__dropdown {
    min-width: 180px;
    margin-right: 10px;

    &--large {
      min-width: 210px;
    }

    &-item {
      width: 100%;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__button {
    padding: 12px 15px;
    color: $color-grey-4;
    transition: 0.1s;

    &:hover {
      background: $color-grey-3;
    }

    &--active,
    &--active:hover {
      color: $color-secondary;
      background: $color-white;
    }

    &:first-of-type {
      border-top-left-radius: $border-radius-s;
      border-bottom-left-radius: $border-radius-s;
    }

    &:last-of-type {
      border-top-right-radius: $border-radius-s;
      border-bottom-right-radius: $border-radius-s;
    }
  }
}
</style>
