<template>
  <div class="panel-favorite-list-manage-resource-body">
    <title-section
      class="panel-favorite-list-manage-resource-body__title"
      position="left"
      :title="$t('topbar.my-lists-cap')">
    </title-section>
    <bc-input
      :bc-input-border-opacity="0.3"
      bc-input-border-color="blue-dark-cello"
      bc-input-border-color-focus="blue-dark-cello"
      bc-input-color-focus="blue-dark-cello"
      bc-input-padding="10px 20px"
      class="panel-favorite-list-manage-resource-body__input"
      name="searchbar_list"
      :placeholder="$t('topbar.search-list')"
      type="text"
      v-model="searchbarInput">
    </bc-input>
    <panel-favorite-list-manage-resource-lists>
    </panel-favorite-list-manage-resource-lists>
  </div>
</template>

<script>
  import TitleSection from '@/components/Title/TitleSection';
  import BcInput from '@/legacy/ui-kit/components/Input/BcInputNew';
  //
  import PanelFavoriteListManageResourceLists
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListManageResource/PanelFavoriteListManageResourceLists';

  export default {
    name: 'panel-favorite-list-manage-resource-body',
    components: { PanelFavoriteListManageResourceLists, BcInput, TitleSection },
    data() {
      return {
        searchbarInput: this.$route.query['search-lists'] || ''
      };
    },
    watch: {
      searchbarInput(value) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            'search-lists': value
          }
        });
      }
    }
  };
</script>

<style lang=scss scoped>
  .panel-favorite-list-manage-resource-body {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;
    height: 100%;
    @include bp('desktop') {
      padding: 0 50px 0;
    }
    &__title {
      margin-bottom: 15px;
    }

    &__container {
      position: relative;
      margin-top: 15px;
      height: 100%;
      overflow-y: scroll;
    }

    &__container-spinner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

</style>
