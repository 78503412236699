<script lang="ts" setup>
import { Campaign } from '@/domains/models/Campaign';
import { useI18n } from '@/i18n/i18n';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { useCampaignsActions } from '@/components/Campaigns/composables/useCampaignsActions';
import { getCampaignProspectsCount } from '@/api/campaigns';
import { ref, watchEffect } from 'vue';
import { addActionLabels } from '@/utils/campaignActions';

const { t } = useI18n();

const route = useRoute();
const router = useRouter();

const { steps } = useCampaignsActions();
const currentActionId = computed(() => route.query['currentActionId']);

const props = defineProps<{ campaign: Campaign }>();

interface Status {
  key: string | undefined;
  label: string;
  count: number;
  icon?: string;
  iconColor?: string;
}

const counts = ref<{ total: number, finished: number } & Record<string, number>>({ total: 0, finished: 0 });

watchEffect(async () => {
  if (props.campaign._id) {
    try {
      const response = await getCampaignProspectsCount(props.campaign._id);
      counts.value = response.data;
    } catch (error) {
      console.error('Failed to fetch prospect counts:', error);
    }
  }
});

const statuses = computed<Status[]>(() => [
  {
    key: undefined,
    label: t('campaigns.steps.all'),
    count: counts.value.total ?? 0,
  },
  ...addActionLabels(props.campaign.actions, (action) => {
    const step = steps.value.find(step => step.key === action.type);
    return typeof step?.name === 'function' ? step.name() : (step?.name ?? '');
  }).map(action => {
    const step = steps.value.find(step => step.key === action.type);
    return {
      key: action._id,
      label: action.label,
      count: counts.value[action._id] ?? 0,
      icon: step?.icon,
      iconColor: step?.iconColor,
    };
  }),
  {
    key: 'FINISHED',
    label: t('campaigns.steps.finished'),
    count: counts.value.finished,
    icon: 'check',
    iconColor: '#3865FD',
  },
]);

function handleClickTab(campaignActionId?: string) {
  if (!campaignActionId) delete route.query['currentActionId'];
  router.replace({ query: { ...route.query, currentActionId: campaignActionId } });
}

</script>

<template>
  <div class="flex rounded-lg h-[60px] bg-blue-100 text-blue-400">
    <button v-for="status in statuses" :key="status.key ?? 'all'" :class="{
      'bg-white text-blue-800 shadow border border-solid': status.key === currentActionId
    }"
      class="flex min-w-[130px] max-w-[200px] border-blue-200 text-sm flex-col items-center justify-center gap-[5px] py-2 px-2.5 rounded-[5px]"
      @click="() => handleClickTab(status.key)">
      <div class="flex h-5 items-center gap-[5px] px-2.5 bg-blue-100 rounded-full">
        <span v-if="status.icon" class="text-[13px]" :class="`icon-${status.icon}`"
          :style="status.key === currentActionId && status.iconColor ? { color: '#3865FD' } : undefined" />
        <!-- :class="{
            icon-{status}
            'text-blue-400': status.key === currentActionId,
            'text-blue-100': status.key !== currentActionId
          }" -->
        <span class="font-bold">{{ status.count }}</span>
      </div>
      <p class="text-xs">{{ status.label }}</p>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.shadow {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}
</style>
