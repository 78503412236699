<template>
  <mv-searchbar
    v-model="search"
    :title="$tc('generics.skill', 2)"
    :is-loading="isLoading"
    :suggestions="suggestions"
    :placeholder="$t('companies.account-management.filter-by-skills')"
    @input="getSuggestions"
    @select="handleSelect"
  >
    <template #results="{ suggestion, index }">
      <searchbar-item>
        <div>
          <i class="icon-tool mr-2"></i>
        </div>
        <div class="body-m is-ellipsis" v-html="getTextHint(suggestion.name, search)"></div>
      </searchbar-item>
    </template>
  </mv-searchbar>
</template>

<script>
import debounce from 'debounce';
import { getTagsAutocomplete } from '@/api/tags';
import MvSearchbar from '@/ui-kit/components/MvSearchbar/MvSearchbar';
import SearchbarItem from '@/components/Searchbar/SearchbarItem';
import searchbarMixins from '@/mixins/searchbar';

export default {
  name: 'searchbar-skills-autocomplete',
  mixins: [searchbarMixins],
  components: {
    MvSearchbar,
    SearchbarItem,
  },
  data() {
    return {
      search: '',
      isLoading: false,
      suggestions: [],
    }
  },
  computed: {
    technos() {
      return this.$route.query.technos;
    },
    technosNames() {
      return this.$route.query.technosNames;
    },
  },
  methods: {
    handleSelect(skill) {
      this.$router.push({
        query: {
          ...this.$route.query,
          technos: this.technos ? `${this.technos},${skill._id}` : skill._id,
          technosNames: this.technosNames ? `${this.technosNames},${skill.name}` : skill.name,
        },
      });
    },
    getSuggestions: debounce(async function(name) {
      this.isLoading = true;
      try {
        const { data } = await getTagsAutocomplete({ name })
        this.suggestions = data;
        this.isLoading = false;
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        })
        this.isLoading = false;
      }
    }, 300),
  },
};
</script>
