<template>
  <div
    v-click-outside="clickOutsideConfig"
    class="bc-autocomplete is-relative"
    :class="{ 'is-column': !filterOptions.length, 'bc-autocomplete__filter': filterOptions.length }">
    <bc-input
      ref="input"
      v-model="input"
      v-bind="$attrs"
      :error="error"
      :icon="icon"
      :label="label"
      :label-placeholder="labelPlaceholder"
      :loading="loading"
      :name="name"
      :placeholder="placeholder"
      :valid="valid"
      autocomplete="off"
      :class="{ 'bc-autocomplete__input-filter': filterOptions.length }"
      type="text"
      @blur="blur"
      @focus="focus"
      @keydown.native.down.prevent="manageKeyArrows('down')"
      @keydown.native.enter.prevent="selectElement(options[pointer])"
      @keydown.native.tab="manageKeyTab"
      @keydown.native.up.prevent="manageKeyArrows('up')"
      @keyup.native.esc.prevent="isDropdownOpen = false">
      <template
        v-if="$slots.prepend"
        #prepend>
        <!-- @slot The slot for left content inside the input -->
        <slot name="prepend"></slot>
      </template>
    </bc-input>
    <bc-dropdown
      v-if="filterOptions.length"
      :disabled="$attrs.disabled"
      :emit-object="true"
      :options="filterOptions"
      class="bc-autocomplete__filter-dropdown-wrapper"
      @change="onFilterOption"
      @on-toggle="isDropdownFilterOpen = $event">
      <template #label>
        <div
          :class="{
            'bc-autocomplete__filter-dropdown--focus': isFocus || isDropdownFilterOpen && !$attrs.disabled,
            'bc-autocomplete__filter-dropdown--hover': isHover && !$attrs.disabled,
            'bc-autocomplete__filter-dropdown--disabled': $attrs.disabled,
            'bc-autocomplete__filter-dropdown--complete': !$attrs.disabled,
          }"
          class="bc-autocomplete__filter-dropdown is-relative">
          <p class="is-flex font-size-xs">
            {{ selectedFilter.name }}
          </p>
          <i
            :class="{
              'icon-chevron-down': !isDropdownFilterOpen,
              'icon-chevron-up': isDropdownFilterOpen,
            }"
            class="bc-autocomplete__icon is-flex is-align-items-center">
          </i>
        </div>
      </template>
      <template #option="{ option, index, activeIndex }">
        <bc-dropdown-item
          :is-focus="activeIndex === index"
          :text="option.name"
          class="bc-autocomplete__option is-expanded"
          @click.native="setActiveOption(option)">
        </bc-dropdown-item>
      </template>
    </bc-dropdown>
    <transition name="fade-select">
      <div
        v-show="isDropdownOpen && (options.length > 0 || hasEmptySlot || hasHeaderSlot || hasFooterSlot)"
        ref="dropdown"
        :class="{
          'bc-autocomplete__dropdown--scroll': isScrollable,
          'bc-autocomplete__dropdown--opened-top': !isListInViewportVertically || forceDropup,
          'is-full-width': isDropdownFullWidth,
          'bc-autocomplete__dropdown--fixed-width': !isDropdownFullWidth,
          'is-right': isDropdownRight,
        }"
        class="bc-autocomplete__dropdown is-absolute is-column border-radius-s border border-blue-100 shadow">
        <div class="bc-autocomplete__wrapper is-column is-full-width">
          <div
            v-if="hasHeaderSlot"
            class="bc-autocomplete__item">
            <!-- @slot The slot for the header inside the dropdown menu -->
            <slot name="header"></slot>
          </div>
          <div
            v-for="(option, index) in computedOptions"
            :key="index"
            :class="{ 'bc-autocomplete__item--hover': pointer === index }"
            class="bc-autocomplete__item"
            @click.stop="selectElement(option)"
            @mouseenter="setPointer(index)">
            <!-- @slot The slot for the Options of the dropdown -->
            <slot
              :index="index"
              :option="option"
              :is-active="pointer === index"
              name="option">
            </slot>
          </div>
          <div
            v-if="options.length === 0 && hasEmptySlot"
            class="bc-autocomplete__item is-disabled">
            <!-- @slot The slot for "no results" -->
            <slot name="empty"></slot>
          </div>
          <div
            v-if="hasFooterSlot"
            class="bc-autocomplete__item">
            <!-- @slot The slot for the footer inside the dropdown menu -->
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import BcDropdown from '../BcDropdown/BcDropdown';
  import BcDropdownItem from '../BcDropdown/BcDropdownItem';
  import BcInput from '../BcInput/BcInput';

  import clickOutside from '../../directives/clickOutside';
  import dropdown from '../../mixins/dropdown';

  export default {
    name: 'bc-autocomplete',
    components: { BcInput, BcDropdown, BcDropdownItem },
    directives: { clickOutside },
    mixins: [dropdown],
    inheritAttrs: false,
    props: {
      /**
       * The value of the input
       */
      value: {
        type: String,
        default: '',
      },
      /**
       * The name of the input
       */
      name: {
        type: String,
        required: true,
      },
      /**
       * The placeholder of the input
       */
      placeholder: {
        type: String,
        default: '',
      },
      /**
       * The label of the input
       */
      label: {
        type: String,
        default: '',
      },
      /**
       * The icon of the input
       */
      icon: {
        type: String,
        default: '',
      },
      /**
       * Open the dropdown on input focus
       */
      openOnFocus: {
        type: Boolean,
        default: false,
      },
      /**
       * The Array that contains the content of the options of the dropdown menu
       */
      options: {
        type: Array,
        default: () => [],
      },
      /**
       * Set the dropdown as scrollable
       */
      isScrollable: {
        type: Boolean,
        default: true,
      },
      /**
       * Force the dropdown menu to open above the input
       */
      forceDropup: {
        type: Boolean,
        default: false,
      },
      /**
       * Set a limit of options to display
       */
      limit: {
        type: Number,
        default: null,
      },
      /**
       * Set the pointer automatically on the first option
       */
      keepFirst: {
        type: Boolean,
        default: false,
      },
      /**
       * Keep the dropdown open after a select
       */
      keepOpen: {
        type: Boolean,
        default: false,
      },
      /**
       * Display a loader in the input
       */
      loading: {
        type: Boolean,
        default: false,
      },
      /**
       * Set the input as valid
       */
      valid: {
        type: Boolean,
        default: false,
      },
      /**
       * Set the input as wrong and display the error message
       */
      error: {
        type: String,
        default: '',
      },
      /**
       * Set the label as placeholder
       */
      labelPlaceholder: {
        type: Boolean,
        default: false,
      },
      /**
       * Set the dropdown width to the input width (will put width 100% on dropdown)
       */
      isDropdownFullWidth: {
        type: Boolean,
        default: true,
      },
      isMultiSelect: {
        type: Boolean,
        default: false,
      },
      isDropdownRight: {
        type: Boolean,
        default: false,
      },
      forceCloseDropdown: {
        type: Boolean,
        default: false,
      },
      filterOptions: {
        type: Array,
        default: () => [],
      },
      selectedFilter: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        isFocus: false,
        isHover: false,
        isDropdownOpen: false,
        isDropdownFilterOpen: false,
        isListInViewportVertically: true,
        pointer: null,
        clickOutsideConfig: {
          handler:this.handler,
          middleware: this.clickOutside,
          events: ["click","dblclick"],
        }
      };
    },
    computed: {
      input: {
        get() {
          return this.value;
        },
        set(value) {

          /**
           v-model -> Emit the value of the input
           *
           * @event input
           * @type {string}
           */
          this.$emit('input', value);

          /**
           v-model -> Emit that the input as changed, so the user is typing
           *
           * @event typing
           */
          this.$emit('typing', value);
        },
      },
      hasEmptySlot() {
        return !!this.$slots.empty;
      },
      hasHeaderSlot() {
        return !!this.$slots.header;
      },
      hasFooterSlot() {
        return !!this.$slots.footer;
      },
      computedOptions() {
        return this.limit ? this.options.slice(0, this.limit) : this.options;
      },
    },
    watch: {
      isDropdownOpen(to) {
        this.$emit('dropdown', to);
        if (!to) {
          this.$nextTick(() => this.pointer = null);
        }
      },
      value(to) {
        if (!this.isDropdownOpen && to) {
          this.isDropdownOpen = true;
        } else if (!to && !this.openOnFocus) {
          this.closeDropdown();
        }
      },
      options() {
        if (this.keepFirst) {
          this.setPointer(0);
        }
      },
      forceCloseDropdown(to) {
        this.isDropdownOpen = !to;
      },
    },
    created() {
      if (typeof window !== 'undefined') {
        window.addEventListener('resize', this.calcDropdownInViewportVertical);
      }
    },
    beforeDestroy() {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.calcDropdownInViewportVertical);
      }
    },
    methods: {
      focus() {
        if (this.openOnFocus) {
          this.isDropdownOpen = true;
        }

        /**
         * focus -> Emit the focus event
         *
         * @event focus
         */
        this.$emit('focus');
      },
      async blur() {
        /**
         * blur -> Emit the blur event
         *
         * @event blur
         */
        this.$emit('blur');

        if (!this.options.length && !this.hasFooterSlot && !this.hasHeaderSlot) {
          this.isDropdownOpen = false;
        }
      },
      closeDropdown() {
        this.isDropdownOpen = false;
      },
      closeDropdownFilter() {
        this.isDropdownFilterOpen = false;
      },
      setPointer(index) {
        this.pointer = index;
      },
      selectElement(option, closeDropdown = !this.keepOpen) {
        if (option === undefined || !this.isDropdownOpen) {
          return;
        }

        /**
         * select -> Emit the value of the option
         *
         * @event select
         */
        this.$emit('select', option);

        if (this.isMultiSelect) {
          return
        }

        closeDropdown && this.$nextTick(() => {
          this.closeDropdown();
        });
      },
      calcDropdownInViewportVertical() {
        this.$nextTick(() => {
          if (this.$refs.dropdown === undefined) {
            return;
          }

          const rect = this.$refs.dropdown.getBoundingClientRect();

          this.isListInViewportVertically = (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight));
        });
      },
      manageKeyArrows(direction) {
        const setIndex = () => {
          let index = this.options.indexOf(this.options[this.pointer]) + sum;

          index = index > this.options.length - 1 ? this.options.length - 1 : index;
          index = index < 0 ? 0 : index;

          this.setPointer(index);

          return index;
        };

        const sum = direction === 'down' ? 1 : -1;

        if (this.isDropdownOpen) {
          let index = setIndex();

          this.setScroll(index, 'div.bc-autocomplete__item:not(.is-disabled)');
        } else {
          this.isDropdownOpen = true;

          if (this.keepFirst && direction === 'down') {
            this.setPointer(0);
          }
        }
      },
      manageKeyTab() {
        this.closeDropdown();
      },

      clickOutside() {
        this.$emit('on-dropdown-close');
        this.isDropdownOpen = false
      },
      onFilterOption(option) {
        console.log('onFilterOption option', option)
      },
      setActiveOption(option) {
        this.$emit('on-select-filter', option);
      },
    },
  };
</script>

<style lang=scss scoped>
  .bc-autocomplete {
    &__dropdown {
      z-index: 9999991;
      background: $color-white;
      bottom: auto;
      top: calc(100% + 5px);
      flex-direction: row;

      &--scroll {
        overflow: auto;
        max-height: 600px;
      }

      &--opened-top {
        top: auto;
        bottom: calc(100% - 5px);
      }

      &--fixed-width {
        max-width: 350px;
      }
    }

    &__input-filter {
      width: 100%;

      &:deep() {
        .bc-input__wrapper {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    &__filter {
      align-items: center;
    }

    &__filter-dropdown-wrapper {
    }

    &__filter-dropdown {
      padding: 12px 10px;
      background: $color-primary;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      color: $color-white;
      min-height: 41px;

      &:hover {
        cursor: pointer;
      }

      &--disabled {
        pointer-events: none;
      }

      &--focus {
        background: $color-blue-light;
        color: $color-primary;
      }
    }

    &__icon {
      font-size: 10px;
      margin-left: 10px;
    }

    &__item {
      height: auto;
      &:not(.is-disabled) {
        cursor: pointer;
      }

      &:first-of-type {
        border-top-left-radius: $border-radius-s;
        border-top-right-radius: $border-radius-s;
        overflow: hidden;
      }

      &:last-of-type {
        border-bottom-left-radius: $border-radius-s;
        border-bottom-right-radius: $border-radius-s;
        overflow: hidden;
      }

      &--hover {
        background: $color-blue-light;
        color: $color-primary;
      }
    }
  }

  .is-right {
    right: 0;
    min-width: 500px;
  }
</style>
