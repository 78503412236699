<template>
  <div class="panel-custom-jobs-caption">
    <placeholder-panel-carousel v-if="isLoading"></placeholder-panel-carousel>
    <div v-else class="panel-custom-jobs-caption__container">
      <picture-opportunity
        v-if="configurationFramework.showCompanies"
        :imgSrc="job._company ? job._company.logoUrl : ''"
        class="panel-custom-jobs-caption__picture">
      </picture-opportunity>
      <div v-else class="panel-custom-jobs-caption__picture--default">
        <i class="icon-briefcase"></i>
      </div>
      <div class="panel-custom-jobs-caption__infos col-xs-12">
        <div class="panel-custom-jobs-caption__caption">
          <div class="panel-custom-jobs-caption__name-wrapper" :style="{ 'height': configurationFramework.showCompanies ? '6vh' : '3vh' }">
            <p class="panel-custom-jobs-caption__about--poste">
              {{ job.title }}
            </p>
            <div v-if="configurationFramework.showCompanies" class="panel-custom-jobs-caption__about">
              <p
                v-if="job._company"
                class="panel-custom-jobs-caption__title panel-custom-jobs-caption__name"
                @click="
                  openPanel({ id: job._company._id, type: 'companies' })
                ">
                {{ job._company.name }}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <tag
            v-for="location in baseLocations.slice(0, 1)"
            :key="location.place_id"
            type="location"
            :text="location.text" />
          <tag
            v-if="baseLocations.length > 1"
            v-tooltip="{
              content: baseLocations
                .slice(1)
                .map((location, i) => `${i > 0 ? '<br />' : ''}${location.text}`),
            }"
            class="ml-2"
            :text="`+${baseLocations.slice(1).length}`"
            type="location"
          />
        </div>
      </div>
      <div v-if="!job.isSpontaneous">
        <button-find-candidates :job="job"></button-find-candidates>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
import PictureOpportunity from '@/components/UIKit/Picture/PictureOpportunity';
import PlaceholderPanelCarousel from '@/components/UIKit/Placeholder/PlaceholderPanel/PlaceholderPanelCarousel';
import Tag from '@/components/Tag/Tag';
import ButtonFindCandidates from '@/components/Button/ButtonFindCandidates';
import { formatPlaceDetailsToBaseLocation } from '@/formatters/locations';

export default {
  name: 'panel-custom-jobs-caption',
  components: {
    PlaceholderPanelCarousel,
    PictureOpportunity,
    Tag,
    ButtonFindCandidates,
  },
  mixins: [PanelNavigation],
  props: {
    job: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPictureError: false,
    };
  },
  computed: {
    ...mapGetters({
      configurationFramework: 'user/configurationFramework',
    }),
    baseLocations() {
      return this.job?.locations?.map(formatPlaceDetailsToBaseLocation) || [];
    },
  },
  methods: {
    setAlternativeImg(event) {
      event.target.src = require('@/assets/img/default-avatar.svg');
      this.isPictureError = true;
    },
  },
};
</script>

<style lang="scss">
.panel-custom-jobs-caption {
  flex-shrink: 0;

  &__container {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 25px;
    margin: 0 22px;

    @include bp('tablet') {
      flex-direction: row;
    }
  }

  &__other-coaches {
    flex-direction: column;

    &-name {
      line-height: 16px;
    }
  }

  &__infos {
    align-items: center;

    @include bp('tablet') {
      align-items: flex-start;
    }
  }

  &__picture {
    &--default {
      width: 73px;
      height: 55px;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      background: #E1EBFD;
      border-radius: 100%;

      & > i {
        font-size: 24px;
        color: #3C80F7;
      }
    }
  }

  &__caption {
    width: 457px;
    align-items: center;
  }

  &__name {
    justify-content: center;
    align-items: center;

    @include bp('tablet') {
      width: 100%;
      justify-content: flex-start;
      flex-direction: row !important;
    }

    &-wrapper {
      width: 100%;
      flex-direction: column;
      margin-right: 10px;
      border-bottom: 1px solid $color-blue-medium;

      .panel-custom-jobs-caption__about {
        margin-top: 4px;

        &--poste {
          margin-right: 20px;
          color: $color-tertiary;
        }

        &--profils {
          display: flex;
          font-weight: 300;
          width: 10vw;
        }
      }
    }
  }

  &__title {
    width: fit-content;
    color: $color-primary;
    margin-bottom: 5px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    &--disabled {
      text-decoration: initial !important;
      cursor: initial !important;
    }
  }

  &__loading {
    margin: 0 70px;

    @include bp('tablet') {
      margin: 0 20px;
    }
  }
}
</style>
