<script lang="ts" setup>
  import { Company } from '@/domains/models/Company';
  import { defineEmits, defineProps } from 'vue';
  import { useI18n } from '@/i18n/i18n';
  import { Opportunity } from '@/domains/models/Opportunity';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
  import PanelCompanyOpportunitiesItem
    from '@/components/Panel/PanelCompany/PanelCompanyOpportunities/PanelCompanyOpportunitiesItem.vue';

  const i18n = useI18n();

  interface Props {
    opportunities: Opportunity[];
    company: Company;
    isLoading?: boolean;
    activeTab?: number;
    opportunitiesTabs?: {
      label: string,
      query: { active: boolean } | null
    }[];
  }

  const props = defineProps<Props>();
  const emits = defineEmits<{
    (e: 'set-active-tab', value: number): void,
  }>();
</script>

<template>
  <div class="flex w-full flex-col">
    <div class="mb-2 mr-auto flex min-h-[36px] rounded-md bg-blue-100">
      <button
        v-for="(tab, index) in opportunitiesTabs"
        :key="index"
        :class="[
          {
            'rounded-md !border-blue-200 bg-white text-blue-800 shadow-[0_2px_4px_0_rgba(29,57,94,0.10)]': activeTab === index,
          }
        ]"
        class="min-w-[64px] items-center justify-center border border-transparent text-sm text-blue-400"
        @click="$emit('set-active-tab', index)">
        {{ tab.label }}
      </button>
    </div>
    <div
      v-if="!opportunities?.length"
      class="mb-2.5 flex w-full grow items-center rounded-md bg-neutral-200 p-2.5 text-sm text-blue-800">
      <p>
        {{ $tc('global.opportunity', 0) }}
      </p>
    </div>
    <div
      v-else
      class="flex w-full flex-col">
      <BcSpinner
        v-if="isLoading"
        class="m-auto w-10"/>
      <div
        v-else
        class="mb-2.5 flex flex-col gap-2">
        <PanelCompanyOpportunitiesItem
          v-for="opportunity in opportunities"
          :key="opportunity._id"
          :company="company"
          :opportunity="opportunity">
        </PanelCompanyOpportunitiesItem>
      </div>
    </div>
  </div>
</template>
