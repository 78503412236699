<script setup lang="ts">
import { computed } from 'vue';
import { DelayData } from '@/domains/models/Campaign';
import BcInput from '@/ui-kit/components/BcInput/BcInput.vue';
import CampaignsConditionsDelayNumber from '@/components/Campaigns/CampaignsConditions/CampaignsConditionsDelayNumber.vue';
import { useI18n } from '@/i18n/i18n';

interface Props {
  modelValue: DelayData;
}

const props = defineProps<Props>();
const { t, tc } = useI18n();

const emit = defineEmits<{
  (e: 'update:modelValue', value: DelayData): void
}>();

function updateConfig(updates: Partial<DelayData>) {
  emit('update:modelValue', {
    ...props.modelValue,
    ...updates,
  });
}

const formattedDelay = computed(() => {
  const parts: string[] = [];
  if (props.modelValue.days) {
    parts.push(`${props.modelValue.days} ${tc('generics.days', props.modelValue.days)}`);
  }
  if (props.modelValue.hours) {
    parts.push(`${props.modelValue.hours}h`);
  }
  if (props.modelValue.minutes) {
    parts.push(`${props.modelValue.minutes}m`);
  }
  return parts.length ? `In ${parts.join(' ')}` : 'Immediately';
});
</script>

<template>
  <div class="relative inline-flex items-center gap-4 text-blue-800">
    <CampaignsConditionsDelayNumber :model-value="modelValue.days || 0" class="shrink-0"
      :unit-label="tc('generics.days', modelValue.days || 0)"
      @update:modelValue="updateConfig({ days: $event || undefined })" />

    <CampaignsConditionsDelayNumber :model-value="modelValue.hours || 0" class="shrink-0"
      :unit-label="tc('generics.hours', modelValue.hours || 0)"
      @update:modelValue="updateConfig({ hours: $event || undefined })" />

      <CampaignsConditionsDelayNumber :model-value="modelValue.minutes || 0" class="shrink-0"
      :unit-label="tc('generics.minutes', modelValue.minutes || 0)"
      @update:modelValue="updateConfig({ minutes: $event || undefined })" />
  </div>
</template>