<script lang="ts" setup>
  import PanelProcessKanbanCoderHeader
    from '@/components/Panel/PanelProcessKanban/PanelProcessKanbanCoderHeader.vue';
  import PanelProcessCustom from '@/components/Panel/PanelProcessCustom.vue';
  import { coderController } from '@/managers/coders/controller';
  import { useQuery } from '@tanstack/vue-query';
  import { useRoute } from 'vue-router/composables';

  const route = useRoute();

  const { data: candidate } = useQuery({
    queryKey: ['GET_CODER_PROFILE', route.params.id],
    queryFn: async() => {
      const candidate = await coderController.getProfile(route.params.id);
      return candidate.data;
    },
  });
</script>

<template>
  <div class="flex grow flex-col">
    <PanelProcessKanbanCoderHeader :candidate="candidate"/>
    <PanelProcessCustom
      :profile="candidate"
      class="flex max-h-full grow"
      type="coder"
    />
  </div>
</template>
