<template>

  <div class="linkedin-send-form flex-col h-fit w-full">
    <div v-if="false"
      class="bg-gradient-to-r from-[#E7EBFF] from-0% to-[#F3F5FF] to-100%">
      <span class="flex w-full items-center p-5 text-blue-600 text-sm font-bold leading-4">
        ⚠️ {{ t('linkedin.current-inmail-balance') }} 
      </span>
      <BcSpinner v-if="loading" size="small" color="secondary" class="items-center justify-center" />
    </div>
    <div class="flex-col min-h-[65vh] p-5 gap-4">
      <div class="w-full justify-end">
        <div v-show="props.linkedinMessageType === linkedinMacros.templates.type.inmail" class="flex-1 items-center gap-2.5">
          <MvIcon name="linkedin-inmail-balance" size="30" />
          <p class="text-[#1D395E] text-sm leading-4">
            {{ t('linkedin.current-inmail-balance') }}: <br/>
            <span v-if="!loading" class="font-bold">{{ InmailCreditBalance }}</span>
            <BcSpinner v-else size="small" color="secondary" class="items-center min-h-4" />
          </p>
        </div>
        <BcDropdown :is-scrollable="true" :options="templatesOptions" position="bottom-left">
          <template #label>
            <MvSelect :as-button="true" :selected-label="selectedTemplate?.name || t('generics.select-a-template')"
              :value="selectedTemplate?.value" icon="file" label-size="l" required>
            </MvSelect>
          </template>
          <template #option="{ activeIndex, index, option }">
            <BcDropdownItem :is-focus="activeIndex === index" class="relative flex w-full"
              @click.native="onSelectTemplate(option._id)">
              <div class="flex w-full">
                <p class="w-full truncate">{{ option.name }}</p>
                <BcAvatar
                  v-tooltip="{ trigger: 'hover', content: option.default ? `${t('generics.created-by')} ${profile._organization?.name}` : `${t('generics.created-by')} ${option?.coach?.firstName} ${option?.coach?.lastName}` }"
                  :src="option.default ? profile._organization?.logoUrl : option?.coach?.pictureUrl"
                  class="ml-3 shrink-0" :placeholder="getPersonInitials(option?.coach)" size="xs" />
              </div>
            </BcDropdownItem>
          </template>
          <template #custom>
            <div class="relative flex w-60 h-10 items-center cursor-default p-5 bg-neutral-100">
              <p class="text-blue-800">{{ t('templates.no-template') }}</p>
            </div>
          </template>
        </BcDropdown>
      </div>
      <!-- Template name -->

      <EmailVariablesList :variables="templatesVariablesFiltered" />

      <BcInput v-if="currentRules.allowSubject" v-model="form.subject" name="subject" class="w-full"
        :label="$t('linkedin.inmail-subject')" :label-placeholder="false"
        :valid="!!form.subject && !fieldErrors.subject" :error="fieldErrors.subject"
        :required="currentRules.requiresSubject" />

      <MvTextArea v-model="form.body" :label="bodyLabel"
        :max-length="currentRules.maxBodyLength" :required="currentRules.requiresBody" />
      <small v-if="currentRules.maxBodyLength" class="text-blue-400 mt-1">{{ preview.body.length }}/{{ currentRules.maxBodyLength }}</small>
      <p v-if="!!form.body" class="text-red-500 mt-[5px] font-size-s">
        {{ fieldErrors.body }}
      </p>

      <div v-if="currentRules.allowAttachments" class="flex-col">
        <div class="flex gap-2">
          <BcButton class="mt-2" icon-left="attachment" type="outlined" @click="onUpload">
            {{ $t('email.add-an-attachment') }}
          </BcButton>
        </div>
        <input ref="uploadInputRef" type="file" multiple style="display: none;" @change="onUploadSelect" />
        <EmailAttachmentList class="mt-3" :attachments="attachments" :files="form.attachmentsToUpload"
          @on-remove="onRemoveFile" />
        <p v-if="fieldErrors.uploadError" class="text-red-500 mt-[5px] font-size-s">
          {{ fieldErrors.uploadError }}
        </p>
      </div>
    </div>
    <div v-if="form.body || form.subject" class="linkedin-send-form__content">
      <LinkedinPreview v-if="form.body || form.subject" :subject-preview="preview.subject" :body-preview="preview.body"
        :attachments="attachments" :type="props.linkedinMessageType" :files="form.attachmentsToUpload" />
    </div>
    <div v-if="missingVariables.length" class="is-column mb-[100px] gap-2 py-2 text-center">
      <p v-for="variable in missingVariables" :key="variable" class="px-5 text-sm">
        ⚠️ {{ t('linkedin.unable-to-send-message-because-the-variable') }}
        <span class="text-red-500">{{ variable }}*</span>
        {{ t('email.has-no-value') }}
      </p>
      <p v-if="warningMessage && missingVariables.length" class="px-5 text-sm">{{ warningMessage }}</p>
    </div>
    <div v-if="missingUnknownVariables.length" class="is-column mb-[100px] gap-2 py-2 text-center">
      <p v-for="variable in missingUnknownVariables" :key="variable" class="px-5 text-sm">
        ⚠️ {{ t('linkedin.an-unknown-variable-has-been-used') }}
        <span class="text-red-500">{{ variable }}</span>
        {{ t('linkedin.please-check-this-is-not-a-typo') }}
      </p>
    </div>
    <div class="min-h-[75px] w-full items-center justify-center gap-2 border-t border-blue-100">
      <BcButton icon-left="cross" type="outlined" :disabled="isSubmitting" @click="$emit('on-close')">
        {{ $t('generics.cancel') }}
      </BcButton>
      <BcButton icon-left="check" :disabled="!isValid || isSubmitting || !!missingVariables.length"
        @click="handleLinkedinSend">
        {{ $t('generics.send') }}
      </BcButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useStore } from '@/store';
import { useToast } from '@/ui-kit/components/BcToast';
import { useI18n } from '@/i18n/i18n';
import { useApi } from '@/composables/api/useApi';
import { uploadFile } from '@/api/upload';

import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
import BcButton from '@/ui-kit/components/BcButton';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
import BcInput from '@/ui-kit/components/BcInput';
import MvSelect from '@/ui-kit/components/MvSelect/MvSelect.vue';
import MvIcon from '@/ui-kit/components/MvIcon/MvIcon.vue';
import EmailAttachmentList from '@/components/Email/EmailAttachmentList.vue';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
import { getPersonInitials } from '@/mixins/getInitials';
import EmailVariablesList from '@/components/Email/EmailVariablesList.vue';
import { useLinkedinTemplatesStore } from '@/store/pinia/linkedinTemplates';
import LinkedinPreview from '@/components/Linkedin/LinkedinPreview.vue';
import { linkedinMacros, LinkedinTemplateTypeEnum } from '@/macros/linkedin';
import { useLinkedinSendForm } from '@/composables/form/useLinkedinSendForm';
import MvTextArea from '@/ui-kit/components/MvTextArea/MvTextArea.vue';
import { useLinkedinInmailApi } from '@/composables/api/useLinkedinInmailApi';

interface Props {
  actionId?: string
  coder: {
    _id: string;
    [key: string]: any;
  }
  customJob: {
    _id?: string;
    _company?: any;
    [key: string]: any;
  }
  warningMessage?: string
  linkedinMessageType: LinkedinTemplateTypeEnum
}

const props = withDefaults(defineProps<Props>(), {
  coder: () => ({ _id: '' }),
  customJob: () => ({}),
});

const emit = defineEmits(['on-close']);

const store = useStore();
const linkedinTemplatesStore = useLinkedinTemplatesStore();
const toast = useToast();
const { t } = useI18n();
const { post, requestError } = useApi();

const { getInmailCreditBalance, loading } = useLinkedinInmailApi();

const InmailCreditBalance = ref<number>();

// Data properties
const isSubmitting = ref(false);

interface Attachment {
  filename: string;
  path: string;
}

interface Coach {
  firstName: string;
  lastName: string;
  pictureUrl: string;
}

interface Template {
  _id: string;
  name: string;
  subject: string;
  body: string;
  attachments: Attachment[];
  attachmentsToUpload: File[];
  activated: boolean;
  default: boolean;
  _coach: string;
  coach?: Coach;
  value?: any;
  type: LinkedinTemplateTypeEnum;
}

const attachments = ref<Attachment[]>([]);
const selectedTemplate = ref<Template | null>(null);
const selectedCustomJob = ref({});
const uploadInputRef = ref<HTMLInputElement | null>(null);

// Computed properties
const profile = computed(() => store.state.user.profile);
const templates = computed(() => linkedinTemplatesStore.items);
const templatesVariables = computed(() => store.state.emails.templatesVariables || []);

const templatesVariablesFiltered = computed(() => {
  if (!props.customJob?._id && !selectedCustomJob.value?._id) {
    return templatesVariables.value.filter(template => template.source === 'coder');
  }
  return templatesVariables.value;
});

const messageVariables = computed(() => {
  return templatesVariablesFiltered.value.map(variable => ({
    ...variable,
    value: getVariableValue(variable)
  }));
});

function getVariableValue(variable: { source: string; slug: string }) {
  switch (variable.source) {
    case 'coder':
      return getNestedValue(props.coder, variable.slug);
    case 'customJob':
      return getNestedValue(props.customJob, variable.slug);
    case 'company':
      return getNestedValue(props.customJob?._company || {}, variable.slug);
    default:
      return undefined;
  }
}

function getNestedValue(obj: any, path: string): any {
  return path.split('.').reduce((acc, part) => acc?.[part], obj);
}

const { 
  form, 
  isValid, 
  fieldErrors,
  currentRules,
  preview,
  missingVariables,
  missingUnknownVariables,
} = useLinkedinSendForm(
  { name: 'Message from Coder Panel', type: props.linkedinMessageType },
  messageVariables
);

const templatesOptions = computed(() => {
  const activatedTemplates = (templates.value as Template[]).filter(template => template.activated && template.type === props.linkedinMessageType);

  const userCustomTemplates = activatedTemplates.filter(
    template => !template.default && template._coach === profile.value._id
  );

  const defaultTemplates = activatedTemplates.filter(
    template => template.default
  );

  const othersCustomTemplates = activatedTemplates.filter(
    template => !template.default && template._coach !== profile.value._id
  ).sort((a, b) => {
    const aName = a.coach?.lastName || '';
    const bName = b.coach?.lastName || '';
    return aName.localeCompare(bName);
  });

  return [
    ...userCustomTemplates,
    ...defaultTemplates,
    ...othersCustomTemplates,
  ];
});

const onSelectTemplate = async (id: string) => {
  const template = (templates.value as Template[]).find(template => template._id === id);
  if (!template) {
    return;
  }
  selectedTemplate.value = template;
  attachments.value = template?.attachments || [];
  form.value.subject = props.linkedinMessageType === linkedinMacros.templates.type.inmail ? template.subject : '';
  form.value.body = template.body;
};

const onUpload = () => {
  uploadInputRef.value?.click();
};

const onUploadSelect = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const file = target.files?.[0];
  if (!file) return;

  if (file.size > MAX_SIZE) {
    toast.show({
      type: 'error',
      message: t('linkedin.form-validation.attachment-limit'),
    });
    return;
  }
  form.value.attachmentsToUpload = [...form.value.attachmentsToUpload, file];
};

const onRemoveFile = ({ file, attachment }: RemoveFileEvent) => {
  if (file) {
    form.value.attachmentsToUpload = form.value.attachmentsToUpload.filter(f => f.name !== file.name && f.size !== file.size && f.lastModified !== file.lastModified);
  }
  if (attachment) {
    attachments.value = attachments.value.filter(a => a.path !== attachment.path);
  }
};


const bodyLabel = computed(() => {
  const bodyLabels: Record<LinkedinTemplateTypeEnum, string> = {
    [linkedinMacros.templates.type.message]: t('linkedin.message-body'),
    [linkedinMacros.templates.type.invitation]: t('linkedin.invitation-body'),
    [linkedinMacros.templates.type.inmail]: t('linkedin.inmail-body'),
  };
  return bodyLabels[form.value.type];
});

async function getAttachments(): Promise<Attachment[]> {
  if (!form.value.attachmentsToUpload.length) {
    return attachments.value;
  }

  const uploadPromises = form.value.attachmentsToUpload.map(async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const result = await uploadFile({ file: formData });
    return result;
  });

  const uploads = await Promise.all(uploadPromises);

  if (uploads.length) {
    const newAttachments = uploads.map(upload => ({
      filename: upload.data.fileName,
      path: upload.data.fileUrl,
    }));
    attachments.value = [...attachments.value, ...newAttachments];
    form.value.attachmentsToUpload = [];
  }

  return attachments.value;
};

async function handleLinkedinSend() {
  isSubmitting.value = true;
  console.log('sending');
  const currentAttachments = currentRules.value.allowAttachments ? await getAttachments() : [];

  console.log('linkedinMessageType', props.linkedinMessageType);
  if (props.linkedinMessageType === linkedinMacros.templates.type.message) {
    await post<any>(`/linkedin/messages`, {
      coderId: props.coder._id, message: {
        body: preview.value.body,
        attachments: currentAttachments,
      }
    }, t('linkedin.message-sent-successfully'), t('linkedin.error-sending-message'))
  }

  if (props.linkedinMessageType === linkedinMacros.templates.type.invitation) {
    await post<any>(`/linkedin/invitations`, {
      coderId: props.coder._id, message: {
        body: preview.value.body,
      }
    }, t('linkedin.invitation-sent-successfully'), t('linkedin.error-sending-invitation'))
  }

  if (props.linkedinMessageType === linkedinMacros.templates.type.inmail) {
    await post<any>(`/linkedin/inmails`, {
      coderId: props.coder._id, message: {
        subject: preview.value.subject,
        body: preview.value.body,
        attachments: currentAttachments,
      }
    }, t('linkedin.inmail-sent-successfully'), t('linkedin.error-sending-inmail'))
  }

  if (!requestError.value) {
    emit('on-close');
  }
  isSubmitting.value = false
}

async function getInmailBalance() {
  InmailCreditBalance.value = await getInmailCreditBalance();
}

// Lifecycle hooks
onMounted(async () => {
  // await getConfiguration();
  await store.dispatch('emails/getTemplatesVariables');
  await linkedinTemplatesStore.fetchLinkedinTemplates({ all: true });
  await getInmailBalance();
});
</script>

<style lang="scss" scoped>
.linkedin-send-form {
  flex-direction: column;
  width: 100%;
  overflow: auto;

  &__content {
    flex-direction: column;
    height: 100%;
    padding: 20px;
    gap: 15px;
  }

  &__footer {
    width: 100%;
    min-height: 75px;
    gap: 10px;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $color-blue-light;
  }

  &__warning {
    justify-content: center;
    align-items: center;
    margin-top: 5%;
  }

  &:deep() {
    .bc-dropdown-item__text {
      width: 100%;
    }
  }
}
</style>
