export const status = {
  todo: {
    value: 1,
    api: 'todo',
    name: 'À traiter',
  },
  accepted: {
    value: 2,
    api: 'accepted',
    name: 'Accepté',
  },
  qualified: {
    value: 4,
    api: 'qualified',
    name: 'Qualifié',
  },
  postponed: {
    value: 9,
    api: 'postponed',
    name: 'Remis à plus tard',
  },
  transfered: {
    value: 8,
    api: 'transfered',
    name: 'Transféré',
    hidden: true,
  },
  rejected: {
    value: 7,
    api: 'rejected',
    name: 'Rejeté',
    hidden: true,
  },
  contact: {
    value: 3,
    api: 'contact',
    name: 'Contacté',
    hidden: true,
  },
  process: {
    value: 5,
    api: 'process',
    name: 'En process',
    hidden: true,
  },
  deal: {
    value: 6,
    api: 'deal',
    name: 'Deal',
    hidden: true,
  },
};

export const categories = {
  inbox: {
    value: 1,
    api: 'inbox',
    name: 'candidates.incoming'
  },
  recommendation: {
    value: 2,
    api: 'recommendation',
    name: 'candidates.recommendations'
  },
  reminder: {
    value: 3,
    api: 'reminder',
    name: 'candidates.reminders'
  },
};

export const sources = {
  tech: {
    value: 1,
    api: 'tech',
  },
  inbox: {
    value: 2,
    api: 'inbox',
  },
  coach: {
    value: 3,
    api: 'coach',
  },
};

export const filters = {
  todo: 'todo',
  postponed: 'postponed',
  archived: 'archived',
}

export default {
  status,
  sources,
  categories,
  filters,
};
