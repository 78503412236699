<template>
  <div class="organizations-modal-create-user is-column is-align-items-center">
    <p class="organizations-modal-create-user__title font-size-xl mb-4 font-weight-bold">
      Ajouter un utilisateur
    </p>
    <div class="organizations-modal-create-user__body is-column">
      <bc-input
        ref="first-name"
        v-model="firstName"
        :label-placeholder="false"
        class="organizations-modal-create-user__input mb-3"
        label="Prénom"
        name="first-name"
        required
        type="text">
      </bc-input>
      <bc-input
        ref="lastName"
        v-model="lastName"
        :label-placeholder="false"
        class="organizations-modal-create-user__input mb-3"
        label="Nom"
        name="last-name"
        required
        type="text">
      </bc-input>
      <bc-input
        ref="email"
        v-model="email"
        :label-placeholder="false"
        class="organizations-modal-create-user__input"
        label="Email"
        name="email"
        required
        type="text">
      </bc-input>
    </div>
    <hr class="organizations-modal-create-user__separator mt-5 mb-5 full-width"/>
    <div class="organizations-modal-create-user__wrapper is-full-width is-justify-content-center">
      <bc-button
        class="organizations-modal-create-user__button mr-4"
        data-button-cancel
        type="outlined"
        @click="$emit('close-modal')">
        {{ $t('generics.cancel') }}
      </bc-button>
      <bc-button
        class="organizations-modal-create-user__button"
        color="success"
        data-button-validate
        @click="createUser">
        Créer l'utilisateur
      </bc-button>
    </div>
  </div>
</template>

<script>
  import { createCoach } from '@/api/coaches';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import BcInput from '@/ui-kit/components/BcInput/BcInput';

  export default {
    name: 'organizations-modal-create-user',
    components: { BcInput, BcButton },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        isLoading: true,
        firstName: '',
        lastName: '',
        email: '',
      };
    },
    methods: {
      async createUser() {
        try {
          const { data } = await createCoach({
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            organizationId: this.profile._id,
          });

          this.$emit('profile-updated', data);
        } catch (error) {
          this.$toast.show({
            title: 'Erreur lors de la création d\'un utilisateur',
            type: 'error',
            message: error?.response?.data?.message || error,
          });
        }
      },
    },
  };
</script>

<style lang=scss scoped>
  .organizations-modal-create-user {
    padding: 30px;
    width: 100%;
    max-width: 600px;

    @include bp('tablet') {
      min-width: 600px;
    }

    &__title {
      color: $color-secondary;
    }

    &__separator {
      width: 100%;
      border: 0;
      border-top: 1px solid rgba($color-secondary, 0.2);
    }
  }
</style>
