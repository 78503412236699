<template>
  <div
    ref="processCard"
    :class="{
      'processes-card--warning': warningOnCard,
      'is-draggable processes-card--draggable': isDraggable,
    }"
    class="processes-card">
    <div class="processes-card__coach">
      <bc-avatar
        :key="process._coder.firstName"
        v-tooltip="coachTooltip"
        :alt="process._coder.firstName"
        :placeholder="getCoachInitials()"
        :src="process._coach.pictureUrl"
        bc-avatar-size="20px"
        size="xs">
      </bc-avatar>
    </div>
    <div class="is-align-items-center">
      <p class="processes-card__name body-m--medium">
        {{ name }}
      </p>
      <process-card-source-logo :source="process.source" />
    </div>
    <process-custom-card-tags :process="process"></process-custom-card-tags>
    <div
      v-if="configurationFramework.showCompanies && process.coderCompaniesCustomJobs"
      class="processes-card__container">
      <div
        v-for="(companyCustomJobs, key) of process.coderCompaniesCustomJobs"
        :key="key"
        class="processes-card__wrapper">
        <bc-avatar-legacy
          v-tooltip.bottom="getCoderCompanyCustomJobs(companyCustomJobs)"
          :bc-img-alt="companyCustomJobs._company.name"
          :bc-img-src="getCompanyLogo(companyCustomJobs._company)"
          bc-avatar-size="20px"
          class="processes-card__logo"
          @error="setAlternativeImg">
        </bc-avatar-legacy>
      </div>
    </div>
    <div v-if="jobsList.length" class="is-align-items-center">
      <div class="processes-card__wrapper">
        <div class="processes-card__job">
          {{ jobsList[0].title }}
        </div>
      </div>
      <div
        v-if="jobsList.length > 1"
        v-tooltip.bottom="{ content: jobsListRest.map(job => job.title).join('<br />') }"
        class="processes-card__job--counter">
        <span>+{{ jobsListRest.length }}</span>
      </div>
    </div>
    <slot />
    <div class="is-flex is-align-items-center gap-1" :class="{ 'mt-2': !!$slots.default }">
      <p class="processes-card__date body-s">
        {{ daysSinceLastActivityLabel }}
      </p>
      <span class="processes-card__divider"></span>
      <bc-dropdown
        v-if="settingsOptions.length"
        :is-scrollable="true"
        :is-popper="true"
        :options="settingsOptions"
        :portal-position="{ left: $refs['processCard']?.getBoundingClientRect()?.left }"
        :portal-width="$refs['processCard']?.getBoundingClientRect()?.width"
        boundaries-element="portal"
        class="custom-jobs-contract-select__dropdown">
        <template #label="{ isDropdownOpen }">
          <button
            :class="{ 'text-blue-500': isDropdownOpen }"
            class="icon-settings text-blue-400"
            @click.prevent>
          </button>
        </template>
        <template #option="{ activeIndex, index, option }">
          <bc-dropdown-item
            :icon="option.icon"
            :is-focus="activeIndex === index"
            :text="option.label"
            class="hover:cursor-pointer"
            @click.native.prevent="setModal(option.key)">
          </bc-dropdown-item>
        </template>
      </bc-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BcAvatarLegacy from '@/legacy/ui-kit/components/Avatar/BcAvatar';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
import moment from 'moment/min/moment-with-locales';
import { apiStatus } from '@/macros/processes/process';
import { bluecodersCompanyId } from '@/macros/utils';
import ProcessCardSourceLogo from '@/components/Processes/ProcessCardSourceLogo';
import ProcessCustomCardTags from '@/components/Process/ProcessCard/ProcessCustomCardTags';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
import BcButton from '@/ui-kit/components/BcButton/index.js';
import BcModal from '@/ui-kit/components/BcModal/index.js';

const isHiddenStatus = [apiStatus.dealt, apiStatus.onboarding, apiStatus.validated];

export default {
  name: 'processes-card-custom',
  components: {
    BcModal, BcButton,
    BcDropdown,
    BcDropdownItem,
    BcAvatarLegacy,
    BcAvatar,
    ProcessCardSourceLogo,
    ProcessCustomCardTags,
  },
  props: {
    process: {
      type: Object,
      default: () => ({}),
    },
    step: {
      type: Object,
      default: () => ({}),
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modal: null,
    };
  },
  computed: {
    ...mapGetters({
      configurationFramework: 'user/configurationFramework',
      configurationProcess: 'user/configurationProcess',
    }),
    hasAmount() {
      return this.configurationProcess.hasAmount;
    },
    isSuperUser() {
      return this.$store.getters['user/isSuperUser'];
    },
    isOrganizationException() {
      return this.$store.getters['user/isOrganizationException'];
    },
    isStatusDisplayed() {
      return (this.isSuperUser || this.isOrganizationException) && !isHiddenStatus.includes(this.process.status);
    },
    name() {
      let fullname = `${(this.process._coder || {}).firstName} ${(this.process._coder || {}).lastName}`;
      if (fullname.length > 20) {
        fullname = fullname.substring(0, 20);
      }
      return fullname;
    },
    amount() {
      if (this.process.amountBilled) {
        return this.process.amountBilled.toLocaleString();
      }
      return this.process.estimatedBilledAmount?.toLocaleString() || 0;
    },
    coachTooltip() {
      return {
        content: `${this.process._coach.firstName} ${this.process._coach.lastName}`,
        classes: 'tooltip-pre',
      };
    },
    today() {
      return moment().startOf('day');
    },
    date() {
      if (this.process.status === apiStatus.dealt) {
        return this.process.formDeal?.sentAt
          ? moment(this.process.formDeal?.dealInfo?.acceptedAt).add(1, 'day')
          : moment(this.process.dealtAt).add(1, 'day');
      }

      return moment(this.process.updatedAt);
    },
    daysSinceLastActivityNumber() {
      return this.today.diff(moment(this.process.lastCoachActivity), 'days') + 1;
    },
    daysSinceLastActivityLabel() {
      return moment(this.process.lastCoachActivity).fromNow();
    },
    warningOnCard() {
      return (
        !(this.process.formDeal || {}).sentAt &&
        this.process.status === apiStatus.dealt &&
        this.step.name === 'attente de start' &&
        !this.process.failedAt &&
        this.process?._company?._id !== bluecodersCompanyId
      );
    },
    jobsList() {
      if (!this.configurationFramework.showCompanies && this.process.coderCompaniesCustomJobs) {
        return Object.values(this.process.coderCompaniesCustomJobs).map((company) => {
          return company.customJobs.filter(Boolean);
        }).flat();
      }
      return [];
    },
    jobsListRest() {
      return [...this.jobsList].splice(1);
    },
    settingsOptions() {
      return [
        ...(this.configurationProcess.displayNextInterview
          ? [
              {
                label: this.$t('process.presentation-date'),
                icon: 'clock',
                key: 'next-presentation',
              },
              {
                label: this.$t('process.interview-date'),
                icon: 'headphones',
                key: 'next-interview',
              },
              {
                label: this.$t('process.integration-date'),
                icon: 'partners',
                key: 'integration-date',
              },
            ]
          : []),
        ...(this.configurationProcess?.hasAmount
          ? [
              {
                key: 'commission-custom',
                label: this.$t('generics.edit-amount'),
                icon: 'coin-alt',
              },
            ]
          : [])
      ];
    },
  },
  methods: {
    getCompanyLogo(company) {
      if (!(company || {}).logoUrl) {
        return require('@/assets/img/default-avatar-company.svg');
      }
      return company.logoUrl;
    },
    setAlternativeImg(event) {
      event.target.src = require('@/assets/img/default-avatar-company.svg');
    },
    getCoachInitials() {
      return `${(this.process._coach || {}).firstName?.charAt(0)}${(
        this.process._coach || {}
      ).lastName?.charAt(0)}`.trim();
    },
    getCoderCompanyCustomJobs(companyCustomJobs) {
      const customJobs = companyCustomJobs.customJobs?.filter(Boolean);

    return `<div style="display: flex; flex-direction: column">
      <p style="text-align:center;font-weight: bold">${companyCustomJobs._company.name}</p>
      <div style="display: flex; flex-direction: column; ${customJobs.length ? 'margin-top:5px' : ''}">
        ${customJobs.map(customJob => (
        `<p>${customJob?.title}</p>`
      )).join('')}
      </div>
    </div>`;
    },
    setModal(modal) {
      this.$emit('on-action', modal);
    },
  },
};
</script>

<style lang="scss" scoped>
.processes-card {
  border-radius: 10px;
  padding: 15px;
  background: $color-white;
  flex-direction: column;
  position: relative;
  min-width: 190px;

  &__job {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 5px;
    color: $color-primary;

    &--counter {
      background: $color-blue-dark-cello;
      align-items: center;
      justify-self: center;
      width: 20px;
      height: 20px;
      color: white;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      border-radius: 30px;

      &:hover {
        background: #3b80f7;
      }

      & > span {
        width: 100%;
        text-align: center;
      }
    }
  }

  &__name {
    font-weight: $font-weight-medium;
    font-size: $font-size-s;
    line-height: 17px;
    max-width: calc(100% - 20px);
    color: $color-secondary-light;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__text {
    display: flex;
    font-size: $font-size-s;
    color: $color-primary;
    line-height: 17px;
    align-items: center;

    &--small {
      font-size: $font-size-xs;
      margin-right: 5px;
    }
  }

  &__container {
    margin-top: 5px;
    flex-wrap: wrap;
    gap: 5px;
  }

  &__wrapper {
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 0 -5px;
  }

  &__coach {
    position: absolute;
    right: 10px;
    top: 12.5px;
  }

  &__logo {
    border-radius: 100%;
    overflow: hidden;
    margin: 2.5px;
  }

  &__count {
    text-align: center;
    background: rgba($color-purple-medium, 0.2);
    font-size: $font-size-xxs;
    color: $color-blue-dark-cello;
    line-height: 12px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }

  &__tooltip {
    white-space: pre;
  }

  &__divider {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    width: 100%;
    align-items: center;

    &:before {
      content: '';
      background: $color-primary;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }
  }

  &__date {
    flex-shrink: 0;
    line-height: 1.5em;
    color: $color-primary;
  }

  &--warning {
    border: 1px solid $color-warning;
  }

  &--draggable {
    &:hover {
      cursor: grab;

      .process-card-coder__icon {
        opacity: 1;
      }
    }
  }

  &__status {
    margin-top: -1px;
    height: 10px;
    width: 10px;

    &--caution {
      background: $color-favorite;
    }

    &--warning {
      background: $color-error;
    }

    &--ok {
      background: $color-success;
    }
  }
}
</style>
