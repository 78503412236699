import domains from '@/api/professions';
import { validateName } from '@/managers/professions/validators/validateName';

export const createProfession = async({ name }) => {
  const validate = ({ name }) => {
    try {
      validateName(name);
    } catch (error) {
      throw error;
    }
  };

  const execute = ({ name }) => {
    try {
      return domains.createTag({ name });
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    validate({ name });

    const { data } = await execute({
      name,
    });

    return data;
  } catch (error) {
    throw error;
  }
};
