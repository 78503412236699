<template>
  <div class="custom-jobs-filters">
    <div class="custom-jobs-filters__wrapper">
      <div class="custom-jobs-filters__container">
        <filter-professions></filter-professions>
        <filter-skills></filter-skills>
        <filter-custom-jobs-more></filter-custom-jobs-more>
      </div>
      <div class="custom-jobs-filters__container">
        <!-- status -->
        <bc-dropdown
          :is-scrollable="false"
          :options="jobStatusOptions"
          class="custom-jobs-filters__dropdown"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="statusName"
              :is-filter="true"
              icon="briefcase">
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              :title="option.name"
              class="hunt__dropdown-item"
              @click.native="setSelectedJobStatus(option)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>

        <!-- coaches -->
        <bc-dropdown
          v-if="coaches.length"
          :options="pipeContentOptions"
          class="filter-dropdown"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="pipeContentNames"
              :is-filter="true"
              icon="user">
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="`${option.name}`"
              @click.native="setSelectedFilter(option)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>

        <!-- priority -->
        <bc-dropdown
          :is-scrollable="false"
          :options="sortOptions"
          class="custom-jobs-filters__dropdown"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="sortName"
              :is-filter="true"
              icon="sliders">
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              :title="option.name"
              class="hunt__dropdown-item"
              @click.native="setSelectedSort(option)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
      </div>
    </div>

    <searchbar-filters-result
      v-if="hasFilters"
      :label="$tc('generics.opportunities-in-db', resultsCount)"
      @on-reset="handleReset"
    ></searchbar-filters-result>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
import SearchbarCoderDispatcher from '@/helpers/searchbar/dispatcher';
import FilterProfessions from '@/components/Filter/FilterProfessions';
import FilterSkills from '@/components/Filter/FilterSkills';
import { getFilterablePoolCoach } from '@/macros/prospectionPipe/dropdowns';
import FilterCustomJobsMore from '../Filter/CustomJobs/FilterCustomJobsMore.vue';
import SearchbarFiltersResult from '@/components/Searchbar/SearchbarFiltersResult';

export default {
  name: 'custom-jobs-filters',
  components: {
    BcDropdown,
    BcDropdownItem,
    BcDropdownTrigger,
    FilterProfessions,
    FilterSkills,
    // FilterLocations,
    FilterCustomJobsMore,
    SearchbarFiltersResult,
  },
  mixins: [SearchbarCoderDispatcher],
  props: {
    resultsCount: {
      type: Number,
    },
  },
  mounted() {
    const sorted = localStorage.getItem('custom-jobs-sort-option');
    if (sorted) {
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          priority: JSON.parse(sorted).value,
        },
      });
    }
  },
  data() {
    return {
      searchProfession: '',
      jobStatusOptions: [
        {
          name: this.$t('custom-jobs.all-opportunities'),
          value: undefined,
        },
        {
          name: this.$t('custom-jobs.opened-opportunities'),
          value: 'true',
        },
        {
          name: this.$t('custom-jobs.closed-opportunities'),
          value: 'false',
        },
      ],
      jobsStatus: '',
      sortOptions: [
        {
          name: this.$t('generics.custom'),
          value: undefined,
        },
        {
          name: this.$t('generics.priority-asc'),
          value: 'asc',
        },
        {
          name: this.$t('generics.priority-desc'),
          value: 'desc',
        },
      ],
      sort: '',
    };
  },
  methods: {
    setSelectedJobStatus(option) {
      this.$emit('on-select-status', option);
      this.jobsStatus = option.name;
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          ['job-active']: option.value,
        },
      });
    },
    setSelectedSort(option) {
      this.$emit('on-select-sort', option);
      localStorage.setItem('custom-jobs-sort-option', JSON.stringify(option));
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          priority: option.value,
        },
      });
    },
    tagsOnReset() {
      this.updateRouteQueries();
    },
    updateRouteQueries() {
      this.$router
        .push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            ...this.routeQueries,
          },
        })
        .catch(() => {});
    },
    setSelectedFilter(option) {
      if (option.type === 'global-pipe') {
        return this.setGlobalPipe();
      }
      this.setSelectedCoaches(option);
    },
    setGlobalPipe() {
      this.$router.push({
        query: {
          ...this.$route.query,
          coachId: undefined,
          'selected-pipe': 'global-pipe',
        },
      });
    },
    setSelectedCoaches(coach) {
      this.$router.push({
        query: {
          ...this.$route.query,
          coachId: coach.query,
          'selected-pipe': 'personal-pipe',
        },
      });
    },
    setSelectedPipe(pipe) {
      if (pipe.type === 'personal-pipe') {
        this.$router.push({
          query: {
            ...this.$route.query,
            'selected-pipe': pipe.type,
            coachId: pipe.query ? pipe.query : this.coachesOptions[0].query,
          },
        });
      } else {
        this.$router.push({
          query: {
            ...this.$route.query,
            'selected-pipe': pipe.type,
            coachId: undefined,
          },
        });
      }
    },
    handleReset() {
      const query = { ...this.$route.query, page: 1 };
      for (const key in query) {
        if (this.filtersQuery.includes(key) || key.startsWith('cf-')) {
          Reflect.deleteProperty(query, key);
        }
      }
      this.$router.push({
        query,
      });
    },
  },
  computed: {
    ...mapState({
      coaches: state => state.coaches.coaches,
      profile: state => state.user.profile,
      onboarding: state => state.user.onboarding,
      metadata: state => state.user.metadata,
    }),
    ...mapGetters({
      searchQueries: 'searchbarFiltersComputed',
    }),
    filtersQuery() {
      return [
        'professions',
        'professionsNames',
        'technos',
        'technosNames',
        'locations',
        'uniqueids',
      ]
    },
    hasFilters() {
      return Object.keys(this.$route.query)
      .filter(key => this.$route.query[key])
      .some(key => this.filtersQuery.includes(key) || key.startsWith('cf-'));
    },
    isSuperUser() {
      return this.$store.getters['user/isSuperUser'];
    },
    isCompanyCreated() {
      return this.onboarding.partner || this.metadata.partners;
    },
    isIndividualFilterSelected() {
      return this.$route.query['selected-pipe'] === 'personal-pipe';
    },
    routeQueries() {
      return {
        ...this.searchQueries,
      };
    },
    statusName() {
      if (!this.$route.query['job-active']) {
        return this.$t('custom-jobs.all-opportunities');
      }
      return this.$route.query['job-active'] === 'true'
        ? this.$t('custom-jobs.opened-opportunities')
        : this.$t('custom-jobs.closed-opportunities');
    },
    sortName() {
      if (!this.$route.query.priority) {
        return this.sortOptions[0].name;
      }
      return this.sortOptions.find(sort => sort.value === this.$route.query.priority).name;
    },
    selectedPipe() {
      return (
        (this.pipeOptions.find(option => option.type === this.$route.query['selected-pipe']) || {})
          .name || this.$i18n.t('process.everyone')
      );
    },
    pipeContentOptions() {
      return [
        {
          name: this.$i18n.t('process.everyone'),
          type: 'global-pipe',
          query: null,
        },
        ...this.coachesOptions,
      ];
    },
    coachesOptions() {
      let coaches = [];
      if (this.isSuperUser) {
        coaches = this.coaches
          .filter(coach => getFilterablePoolCoach(coach))
          .map(coach => ({
            name: `${coach.firstName} ${coach.lastName}`,
            query: coach._id,
          }));
      } else {
        coaches = this.coaches
          .filter(coach => getFilterablePoolCoach(coach))
          .map(coach => ({
            name: `${coach.firstName} ${coach.lastName}`,
            query: coach._id,
          }));
      }
      return [...coaches.sort((a, b) => a.name.localeCompare(b.name))];
    },
    pipeContentNames() {
      return this.coachNames;
    },
    coachNames() {
      let coachIndex = this.pipeContentOptions.findIndex(
        coach => coach.query === this.$route.query.coachId
      );
      coachIndex = coachIndex >= 0 ? coachIndex : 0;

      return this.pipeContentOptions[coachIndex]?.name || '';
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-jobs-filters {
  display: block;

  &__container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__tags-container {
    margin-top: 15px;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #23395b;
  }

  .bc-dropdown {
    min-width: 150px;
  }
}
</style>
