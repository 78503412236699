const domainsDropdownBehaviorMixin = {
  data: () => ({
    selectedDomains: [],
    isDomainsRequired: false,
  }),
  methods: {
    $_checkIfDomainIsSelected(domain) {
      return this.selectedDomains.includes(domain.query);
    },

    $_selectDomain(domain) {
      this.selectedDomains.push(domain.query);
    },

    $_unselectDomain(domain) {
      const domainIndex = this.selectedDomains.indexOf(domain.query);
      if (!this.isDomainsRequired || (this.isDomainsRequired && this.selectedDomains.length > 1)) {
        this.selectedDomains.splice(domainIndex, 1);
      }
    },

    $_toggleSelectedDomain(domain) {
      if (this.$_checkIfDomainIsSelected(domain)) {
        this.$_unselectDomain(domain);
      } else {
        this.$_selectDomain(domain);
      }
    },

    $_selectCategoryDomains(domains) {
      if (domains.every(domain => this.$_checkIfDomainIsSelected(domain))) {
        domains.forEach(domain => this.$_unselectDomain(domain));
      } else {
        domains.forEach(domain => {
          if (!this.$_checkIfDomainIsSelected(domain)) {
            this.$_selectDomain(domain);
          }
        });
      }
    },

    selectDomains(domains = []) {
      if (domains.length === 1) {
        this.$_toggleSelectedDomain(domains[0]);
      } else {
        this.$_selectCategoryDomains(domains);
      }
    },
  },
};

export default domainsDropdownBehaviorMixin;
