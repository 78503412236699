<template>
  <h1 class="panel-process-create-header">
    {{ $t('process-create.add-a-process') }}
  </h1>
</template>

<script>

  export default {
    name: 'panel-process-create-header'
  };
</script>

<style lang=scss scoped>
  .panel-process-create-header {
    padding: 20px;
    background: $color-white;
    width: 100%;
    justify-content: center;
    text-align: center;
    color: $color-blue-dark-cello;
    font-size: $font-size-xl;
    line-height: 19px;
    font-weight: 500;
    border-bottom: 1px solid rgba($color-blue-dark-cello, 0.3);
  }
</style>
