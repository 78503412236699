import { http } from '@/api/index';

export const getDomains = () => {
  return http.get('/domains');
};

export const createDomain = ({ name, industryId }) => {
  return http.post('/domains', {
    name,
    industry: industryId,
  });
};

export const editDomain = ({ id, name }) => {
  return http.patch(`/domains/${id}`, {
    name,
  });
};

export const deleteDomain = id => {
  return http.delete(`/domains/${id}`);
};

export default {
  getDomains,
  createDomain,
  editDomain,
  deleteDomain,
};
