<script setup lang="ts">
import { ref } from 'vue';
import { usePanelNavigation } from '@/composables/usePanelNavigation';
import PanelCoderCreateHeader from '@/components/Panel/PanelCoder/PanelCoderCreate/PanelCoderCreateHeader.vue';
import PanelCoderNotes from '@/components/Panel/PanelCoder/PanelCoderNotes.vue';
import PanelCoderCreateForm from '@/components/Panel/PanelCoder/PanelCoderCreate/PanelCoderCreateForm.vue';
import { ProfileType } from '@/domains/models/Candidate';
import { useRoute } from 'vue-router/composables';
import { searchParamToString } from '@/utils/typeConversion';

const { closePanel } = usePanelNavigation();
const route = useRoute();
  const manageClosePanel = (event) => {
    if (event.target?.className === 'icon-close' || event.target?.className === 'overlay') {
      closePanel();
    }
  };

  const queryTypeToProfileTypesMap: Record<string, ProfileType[]> = {
    'create-coder': ['candidate'],
    'create-contact': ['contact'],
  };

  const profileTypes = ref<ProfileType[]>(queryTypeToProfileTypesMap[searchParamToString(route.query.type)] ?? ['candidate']);
</script>

<template>
  <div
    v-click-outside="manageClosePanel"
    class="flex max-h-full shrink-0 grow flex-col items-center justify-center"
  >
    <PanelCoderCreateHeader
      :profileTypes="profileTypes"
      @update:profile-types="profileTypes = $event"
      class="shrink-0"
    />
    <div class="flex max-h-[calc(100%-55px)] w-full grow">
      <div class="flex w-1/2 flex-col">
        <PanelCoderCreateForm :profile-types="profileTypes" />
      </div>
      <div class="relative flex w-1/2 flex-col overflow-y-auto bg-neutral-100">
        <div class="absolute z-10 size-full bg-white/50"></div>
        <PanelCoderNotes/>
      </div>
    </div>
  </div>
</template>

