<template>
  <div style="overflow:auto;">
    <div ref="processescolumns" class="processes-columns-custom">
      <div v-for="(category, categoryIndex) in configurationProcess.categories" :key="category.key" ref="columnWrapper"
        class="processes-columns-custom__wrapper">
        <div class="processes-columns-custom__body">
          <ProcessColumnCustomAggregated v-for="(step, stepIndex) in category.steps" :key="step.key"
            class="processes-columns-custom__step" :categoryIndex="categoryIndex" :is-dragging="isDragging"
            :class="{ 'hidden': step.key.includes('_Candidature') }"
            :processes="processesByStepKey[step.key] ?? []" :column-metadata="columnsMetadata?.[step.key]"
            :read-only="readonly" :step="{
              name: step.name,
              categoryKey: category.key,
              stepKey: step.key,
              email: step.email,
              style: headerStyle
            }" :step-name="step.name" :style="{ color: categoriesColor[category.key] }" :identifier="identifier"
            :show-amount="configurationProcess.hasAmount && showAmount" :is-first="stepIndex === 0"
            :is-last="stepIndex === category.steps.length - 1"
            @load-more-processes="$emit('load-more-processes', $event, step.key)"
            @move-process="$emit('move-process', { ...$event, stepKeyTo: step.key })"
            @accept-process="$emit('accept-process', $event)" @reject-process="$emit('reject-process', $event)"
            @card-dragged="$emit('card-dragged', $event)" @delete-process="$emit('delete-process', $event)"
            @abort-process="$emit('abort-process', $event)" @unabort-process="$emit('unabort-process', $event)"
            @process-update="$emit('process-update', { ...$event, stepKeyFrom: step.key, stepKeyTo: step.key })"
            @set-modal-type="$emit('set-modal-type', $event)" @dragging="$emit('dragging', $event)" @dragover="dragover"
            @drag-warning="$emit('drag-warning')">
            <slot name="content" :categoryIndex="categoryIndex" :stepIndex="stepIndex"></slot>
          </ProcessColumnCustomAggregated>
          <slot name="body"></slot>
        </div>
      </div>

      <div v-if="showFailed" class="processes-columns-custom__wrapper">
        <div class="processes-columns-custom__body">
          <ProcessColumnCustomAggregated class="processes-columns-custom__step" :read-only="readonly"
            :processes="processesByStepKey['failed'] ?? []" :identifier="identifier"
            :column-metadata="columnsMetadata?.failed" :step="{
              name: $t('config.failedProcessRecruitment'),
              categoryKey: 'failed',
              stepKey: 'failed',
              style: headerStyle,
              icon: openInNew,
              iconLink: `/failure-coders?page=1${selectedCoachId ? '&coachId=' + selectedCoachId : ''}`
            }" step-name="failed" :style="{ color: categoriesColor.failed }"
            :show-amount="configurationProcess.hasAmount && showAmount"
            @load-more-processes="$emit('load-more-processes', $event, 'failed')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import ProcessColumnCustomAggregated from '@/components/Process/ProcessColumnCustomAggregated.vue';
import colors from '@/ui-kit/assets/scss/exports/_colors.module.scss';
import openInNew from '@/assets/img/open_in_new.svg';
import { Process } from '@/domains/models/Process';
import { computed, onMounted, reactive, ref } from 'vue';
import { useStore } from '@/store';

const props = withDefaults(defineProps<{
  isDragging: boolean;
  identifier: number;
  processesByStepKey: Record<string, any>;
  columnsMetadata?: Record<string, any>;
  readonly?: boolean;
  showAmount?: boolean;
  showFailed?: boolean;
  selectedCoachId?: string;
}>(),
  {
    readonly: false,
    showAmount: false,
    showFailed: false,
  });

const columnWrapper = ref<HTMLElement[] | null>(null);
const headerHeight = ref<string | undefined>();
const previousDragId = ref<string | undefined>();
const categoriesColor = ref({
  beforeCandidatePresentation: colors['secondary'],
  beforeProcessRecruitment: colors['primary'],
  afterProcessRecruitment: colors['green-bright'],
  failed: colors['red-mandy']
});

const store = useStore();
const configurationProcess = computed(() => store.getters['user/configurationProcess']);

const headerStyle = computed(() => {
  return {
    header: {
      height: headerHeight.value,
      alignItems: 'center'
    }
  };
});

function calculateHeaderHeight() {
  if (!columnWrapper.value) return
  let maxOffsetHeight = 0
  for (const wrapper of columnWrapper.value) {
    for (const column of wrapper.children[0].children) {
      const header = column.children[0]
      if (header.offsetHeight > maxOffsetHeight) {
        maxOffsetHeight = header.offsetHeight
      }
    }
  }
  headerHeight.value = maxOffsetHeight + 'px';
}

function dragover(event) {
  if (props.readonly || !props.isDragging) {
    return;
  }
  if (event.target.id !== previousDragId.value && event.screenX <= 700) {
    event.target.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    previousDragId.value = event.target.id;
  }
}

onMounted(() => {
  calculateHeaderHeight();
});
</script>

<style lang="scss" scoped>
$border: 1px solid $color-blue-light;

.processes-columns-custom {
  border-radius: $border-radius-s;
  padding: 20px 0px 10px 0px;

  &__wrapper {
    flex-direction: column;
    border: $border;
    width: 100%;
    border-radius: $border-radius-s;
    height: 100%;
    margin: 0 15px;
  }

  &__body {
    height: 100%;
    border-bottom: $border;
    border-radius: $border-radius-s;
  }

  &__step {
    width: 100%;
    border-right: $border;
    min-width: 240px;

    &:last-of-type {
      border-right: 0;
    }
  }

  &__button {
    background-color: transparent;
    width: calc(100% - 20px);
    margin: 10px;
  }

  &__modal {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
