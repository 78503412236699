<template>
  <mv-filter
    :label="$t('generics.location')"
    :should-close="shouldClose"
    icon="map-pin"
    :count="selected.length"
    align="right"
  >
    <filter-content
      class="filter-companies-location__content"
      :label="$t('generics.location')"
      icon="map-pin"
    >
      <location-autocomplete
        :selected="selected"
        :selected-distance="selectedDistance"
        :show-label="false"
        :with-distance="true"
        @select-locations="onSelect"
        @select-distance="onSelectDistance"
        @close="onClose"
        placeholder="Exemple: Paris" />
    </filter-content>
  </mv-filter>
</template>

<script>
import MvFilter from '@/ui-kit/components/MvFilter/MvFilter';
import FilterContent from '@/components/Filter/FilterContent';
import LocationAutocomplete from '@/components/Location/LocationAutocomplete.vue';

export default {
  name: 'filter-companies-location',
  components: {
    MvFilter,
    FilterContent,
    LocationAutocomplete,
  },
  data() {
    return {
      shouldClose: false,
    }
  },
  computed: {
    selected() {
      if (!this.$route.query.locations) {
        return [];
      }
      const locations = (this.$route.query.locations || []).split('|');
      return locations.map(location => {
        const details = location.split('+');
        return {
          description: details[0],
          place_id: details[1],
        }
      });
    },
    selectedDistance() {
      return this.$route.query.distance || 0;
    },
  },
  methods: {
    onSelect(value) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          locations: value.length ? value.map(v => `${v.description}+${v.place_id}`).join('|') : undefined,
        }
      })
    },
    onSelectDistance(value) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          distance: value,
        }
      })
    },
    onClose() {
      this.shouldClose = true;
      setTimeout(() => {
        this.shouldClose = false;
      }, 100)
    },
  }
};
</script>

<style lang="scss" scoped>
.filter-companies-location {
  &__content {
    min-width: 574px;

    &:deep() {
      .bc-input {
        margin-top: 0;
      }
    }
  }
}
</style>
