import { getChannels, getActivatedChannels, getActivatedChannelsSubscriptions, publishedStatus } from '@/api/xtramile';

// TODO: to rename later from "xtramile" to "jobboards"
export default {
  namespaced: true,
  state: {
    internalJobboards: [
      {
        connectorName: "hellowork",
        title: "Hellowork",
        isInternal: true,
      },
      {
        connectorName: "indeed",
        title: "Indeed",
        isInternal: true,
      },
      {
        connectorName: "website",
        title: "Website",
        isInternal: true,
      }
    ],
    channels: [],
    activatedChannels: [],
    activatedChannelsSubscriptions: [],
    publishedJobboardsList: [],
    colors: {
      wttj: '#FFCD00',
      hellowork: '#000000',
      indeed: '#1254F1',
      apec: '#202756',
      cadremploi: '#16A9E3',
      monster: '#794DBF',
      jobijoba: '#ff2781',
      leboncoin: '#ec5b13',
      joblift: '#fbde68',
      meteojob: '#01B4D4',
      jobintree: '#0c5d3a',
      jobrapido: '#FC2D79',
      fashionjobs: '#030303',
      adzuna: '#269D39',
      jooble: '#0B71D9',
      talent: '#691F74',
      goldenbees: '#ffbf00',
      staffsante: '#FF6633',
      yupeek: '#2c5692',
      welcometothejungle: '#ffcc00',
      engagementjeunes: '#001230',
      emploisoignant: '#0079c2',
      linkedin: '#0a66c2',
      career: '#3865FD',
    },
  },
  getters: {
    activatedChannelsMap: (state) => {
      return state.activatedChannels.reduce((acc, val) => {
        return {
          ...acc,
          [val.id]: val,
        }
      }, {});
    },
    activatedChannelsSubscriptionsMap: (state) => {
      return state.activatedChannelsSubscriptions.reduce((acc, val) => {
        const channelId = val.channelId;
        if (!acc[channelId]) {
          return {
            ...acc,
            [channelId]: [val],
          }
        }
        return {
          ...acc,
          [channelId]: [...acc[channelId], val]
        }
      }, {});
    },
    publishedJobboardsList: (state) => {
      return state.publishedJobboardsList;
    }
  },
  actions: {
    async getChannels({ commit, state }) {
      const { data: channels } = await getChannels()
      let channelsMapped = channels.map(channel => ({
        ...channel,
        title: `${channel.title[0].toUpperCase()}${channel.title.substring(1)}`
      }))

      channelsMapped = [...state.internalJobboards, ...channelsMapped];

      channelsMapped.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
      commit('setChannels', channelsMapped);
      return channelsMapped;
    },
    async getActivatedChannels({ commit, state }) {
      const { data: channels } = await getActivatedChannels()
      let channelsMapped = channels.map(channel => ({
        ...channel,
        title: `${channel.title[0].toUpperCase()}${channel.title.substring(1)}`
      }))

      channelsMapped = [...state.internalJobboards, ...channelsMapped];

      channelsMapped.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
      commit('setActivatedChannels', channelsMapped);
      return channelsMapped;
    },
    async getActivatedChannelsSubscriptions({ commit, state }) {
      const { data: channelsSubscriptions } = await getActivatedChannelsSubscriptions();
      let channelsMapped = channelsSubscriptions.map(channel => ({
        ...channel,
        title: `${channel.title[0]?.toUpperCase?.()}${channel.title.substring(1)}`
      }))

      channelsMapped = [...state.internalJobboards, ...channelsMapped];

      channelsMapped.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });

      commit('setActivatedChannelsSubscriptions', channelsMapped);
      return channelsMapped;
    },
    getPublishedJobboardsList({ commit, getters }, { customJob, configurationJobMultiposting }) {
      let list = [];

      if (!customJob.adPublished) {
        commit('setPublishedJobboardsList', list);
        return list;
      }

      // jobMultiposting without autopublish
      let multiposting = Object.keys(customJob?.jobMultiposting || []).filter(key => customJob.jobMultiposting[key]?.status === 'published');

      // if autopublish, sync with customjob adPublished
      if (configurationJobMultiposting) {
        Object.keys(configurationJobMultiposting).forEach(key => {
          if (configurationJobMultiposting[key]?.enabled && configurationJobMultiposting[key]?.params?.autoPublish) {
            multiposting = [...multiposting, key];
          }
        });
      }

      // Clean duplications
      multiposting = [...new Set(multiposting)];

      // xtramile publications
      const xtramile = Object.keys(customJob?.xtramilePublications || [])
        .filter(id => publishedStatus.includes(customJob.xtramilePublications[id]?.status))
        .map(id => getters.activatedChannelsMap[id]?.connectorName)
        .filter(Boolean);

      list = [...xtramile, ...multiposting];
      commit('setPublishedJobboardsList', list);

      return list;
    },
  },
  mutations: {
    setChannels(state, data) {
      state.channels = data;
    },
    setActivatedChannels(state, data) {
      state.activatedChannels = data;
    },
    setActivatedChannelsSubscriptions(state, data) {
      state.activatedChannelsSubscriptions = data;
    },
    setPublishedJobboardsList(state, data) {
      state.publishedJobboardsList = data;
    }
  }
}
