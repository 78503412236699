export const media = {
  sms: {
    name: 'sms',
    icon: 'icon-chat',
    api: 1,
  },
  call: {
    name: 'call',
    icon: 'icon-phone',
    api: 2,
  },
  email: {
    name: 'email',
    icon: 'icon-mail',
    api: 3,
  },
  linkedin: {
    name: 'linkedin',
    icon: 'icon-linkedin',
    api: 4,
  },
};

export const type = {
  qualification: {
    name: 'qualification',
    api: 1,
  },
  message: {
    name: 'message',
    api: 2,
  },
  other: {
    name: 'other',
    api: 3,
  },
  process: {
    name: 'processCustom',
    api: 4
  },
  echange: {
    name: 'echange',
    api: 5
  },
  note: {
    api: 10,
    name: 'note',
  },
  call: {
    api: 11,
    name: 'call',
  },
  email: {
    api: 12,
    name: 'email',
  },
  linkedin: {
    api: 13,
    name: 'linkedin',
  },
  whatsapp: {
    api: 14,
    name: 'whatsapp',
  },
  reminder: {
    api: 15,
    name: 'reminder',
  }
};

export const authorType = {
  guest: {
    name: 'guest',
    api: 0,
  },
  coder: {
    name: 'coder',
    api: 1,
  },
  contact: {
    name: 'contact',
    api: 2,
  },
  coach: {
    name: 'coach',
    api: 3,
  },
  god: {
    name: 'god',
    api: 4,
  },
};

export const resourceType = {
  coders: 'Candidat',
  jobs: 'Job',
  companies: 'Partner',
  hunt: 'hunt'
};

export const hasQualificationsWithRating = ['coders', 'suggestions-coders'];

export const getMediaFromApiValue = apiValue => Object.values(media).find(m => m.api === apiValue);
export default { media, type, authorType, getMediaFromApiValue, hasQualificationsWithRating, resourceType };
