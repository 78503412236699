<script setup lang="ts">
import { withDefaults, defineProps, defineEmits, computed } from 'vue';
import BcButton from '@/ui-kit/components/BcButton/BcButton';

import { useStore } from '@/store';

const props = withDefaults(
  defineProps<{
    simple?: boolean;
    isCompany?: boolean;
  }>(),
  {
    simple: false,
    isCompany: false,
  }
);

const emits = defineEmits<{
  (e: 'click'): void,
}>();

const store = useStore();

const selectedCards = computed(() => {
  return store.state.card.selectedCards;
});

const onPress = () => {
  store.dispatch('app/setIsPanel', true);
  if (props.isCompany) {
    store.dispatch('app/setParams', { isCompany: true });
    store.dispatch('emails/setSelectedCompanies', selectedCards.value);
  } else {
    store.dispatch('emails/setSelectedCoders', selectedCards.value);
    store.dispatch('app/setParams', { isCompany: false });
  }
  store.dispatch('app/setModal', 'modal-email-send-form');
  emits('click');
}

</script>

<template>
  <bc-button
    :icon-left="!simple ? 'mail-arrow-up' : ''"
    :icon-center="simple ? 'mail-arrow-up' : ''"
    color="warning-gradient"
    v-bind="$attrs"
    v-on="$listeners"
    @click="onPress"
  >
    <span v-if="!simple">{{ $t('email.send-a-mail') }}</span>
  </bc-button>
</template>
