import { useApi } from './useApi';

export function useLinkedinInmailApi() {
  const { get, loading, requestError } = useApi();

  async function getInmailCreditBalance() {
    const data = await get<number | undefined>('/linkedin/inmail_balance');
    return data;
  }

  return {
    getInmailCreditBalance,
    loading,
    requestError,
  };
} 