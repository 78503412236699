<template>
  <div class="contacts-grid is-full-width">
    <div class="contacts-grid__container is-full-width">
      <bc-checkbox
        id="checkbox"
        :value="isChecked"
        class="contacts-grid__text contacts-grid__checkbox is-uppercase font-size-s"
        @input="$emit('checked', $event)">
      </bc-checkbox>
      <grid-sort-select
        class="contacts-grid__text contacts-grid__name"
        :label="`${$t('generics.identity')}`"
        @sort="handleSort('firstName', $event)"
      />
      <p
        class="contacts-grid__text contacts-grid__contact-info is-uppercase font-size-s hidden-xs hidden-sm">
        {{ $t('generics.contact-info') }}
      </p>
      <p
        class="contacts-grid__text contacts-grid__link is-uppercase font-size-s hidden-xs hidden-sm">
        {{ $t('companies.account-management.in-cv-upper') }}
      </p>
      <p
        class="contacts-grid__text contacts-grid__company is-uppercase font-size-s hidden-xs hidden-sm">
        {{ $tc('generics.company', 1) }}
      </p>
      <p class="contacts-grid__text contacts-grid__professions is-uppercase font-size-s hidden-xs">
        {{ $t('companies.account-management.professions-upper') }}
      </p>
      <grid-sort-select
        class="contacts-grid__text contacts-grid__locations is-uppercase font-size-s hidden-xs hidden-sm"
        :label="`${$t('companies.account-management.locations-upper')}`"
        @sort="handleSort('location', $event)"
      />
    </div>
  </div>
</template>

<script>
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
import GridSortSelect from '@/components/Grid/GridSortSelect.vue';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';

export default {
  name: 'contacts-grid',
  components: { BcDropdown, GridSortSelect, BcCheckbox },
  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
    showArrivalDate: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async handleSort(field, sortOption) {
      await this.$store.dispatch('setSort', {
        field,
        direction: sortOption.value
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.contacts-grid {
  padding: 25px 30px 10px 30px;

  &__checkbox {
    background: $color-white;
  }

  &__text {
    color: $color-blue-heavy-dark;
    margin: 0 0.6rem;
  }

  &__name {
    @include contacts-grid-name;
  }

  &__contact-info {
    @include contacts-grid-contact-info;
  }
  &__link {
    @include contacts-grid-cv;
  }

  &__company {
    @include contacts-grid-company;
  }

  &__professions {
    @include contacts-grid-professions;
  }

  &__locations {
    @include contacts-grid-locations;
  }
}
</style>
