<template>
  <div class="views-topbar is-justify-content-center">
    <div class="views-topbar__container is-full-width is-align-items-center">
      <h5
        v-if="title"
        class="
          font-weight-bold font-size-xl
          is-secondary is-flex is-align-items-center
          mr-1
        "
      >
        {{ title }}
      </h5>
      <h5
        v-if="subtitle"
        class="
          views-topbar__subtitle
          font-size-s
          is-secondary is-flex is-align-items-center
        "
      >
        ({{ subtitle }})
      </h5>
      <slot></slot>
      <views-topbar-create v-if="showCreate" :class="{ 'ml-auto': !$slots.default }"></views-topbar-create>
    </div>
  </div>
</template>

<script>
  import ViewsTopbarCreate from '@/components/Views/ViewsTopbar/ViewsTopbarCreate';

  export default {
    name: 'views-topbar',
    components: { ViewsTopbarCreate },
    props: {
      title: {
        type: [String, Number],
        default: ''
      },
      subtitle: {
        type: [String, Number],
        default: '',
      },
      showCreate: {
        type: Boolean,
        default: false
      }
    },
  };
</script>

<style lang="scss" scoped>
  .views-topbar {
    height: 75px;
    width: 100%;
    background: $color-white;
    margin-bottom: 30px;
    padding: 10px 2.5%;
    z-index: 4;

    &__container {
      @include page_view();
    }

    &__subtitle {
      position: relative;
      top: 1px;
    }
  }
</style>
