<template>
  <component
    :is="cardFavoriteComponent"
    v-bind="$attrs"
    v-on="$listeners">
  </component>
</template>

<script>
  import CardFavoriteListsCoach from '@/components/Card/CardFavoriteLists/CardFavoriteListsCoach';
  import CardFavoriteListsRpo from '@/components/Card/CardFavoriteLists/CardFavoriteListsRpo';

  export default {
    name: 'card-favorite-lists',
    inheritAttrs: false,
    components: {
      CardFavoriteListsRpo,
      CardFavoriteListsCoach,
    },
    computed: {
      cardFavoriteComponent() {
        return this.$store.getters['user/isSuperUser'] ? 'card-favorite-lists-coach' : 'card-favorite-lists-rpo';
      },
    },

  };
</script>
