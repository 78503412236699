<template>
  <div class="panel-favorite-list-type">
    <title-section
      class="panel-favorite-list-type__title"
      position="left"
      :title="$t('companies.account-management.list-type')">
    </title-section>
    <bc-dropdown
      class="panel-favorite-list-type__dropdown"
      @change="updateListType"
      :disabled="isDropdownDisabled"
      :options="typeOptions"
      :emit-object="true"
      :close-dropdown-at-select="true">
      <template #label="{ isDropdownOpen }">
        <bc-dropdown-trigger
          :disabled="isDropdownDisabled"
          :is-arrow="true"
          :is-dropdown-open="isDropdownOpen"
          :text="listPlaceholder"
          class="panel-favorite-list-type__label">
        </bc-dropdown-trigger>
      </template>:
      <template #option="{ option, index, activeIndex }">
        <bc-dropdown-item
          :is-focus="activeIndex === index"
          :text="option.name"
          @click.native="updateListType(option)"
          class="panel-favorite-list-type__options is-expanded font-size-s">
        </bc-dropdown-item>
      </template>
    </bc-dropdown>
  </div>
</template>
<script>
  import listMacros from '@/macros/favoriteLists/favoriteLists'
  //
  import TitleSection from '@/components/Title/TitleSection';
  import BcDropdown from "@/ui-kit/components/BcDropdown/BcDropdown";
  import BcDropdownTrigger from "@/ui-kit/components/BcDropdown/BcDropdownTrigger";
  import BcDropdownItem from "@/ui-kit/components/BcDropdown/BcDropdownItem";

  export default {
    name: "panel-favorite-list-type",
    components: { BcDropdownItem, BcDropdownTrigger, BcDropdown, TitleSection },
    props: {
      isDropdownDisabled: {
        type: Boolean,
        default: false
      },
      listPlaceholder: {
        type: String,
        default: listMacros.placeholder.coder
      }
    },
    computed: {
      typeOptions() {
        return listMacros.listTypeOptions;
      }
    },
    methods: {
      updateListType($event) {
        this.$emit('select', $event);
      }
    },
  };
</script>
<style scoped lang=scss>
  .panel-favorite-list-type {
    flex-direction: column;

    &__title {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__dropdown, &__options {
      width: 300px;
    }
  }
</style>
