import { computed, Ref } from 'vue';
import { useI18n } from '@/i18n/i18n';
import { linkedinMacros } from '@/macros/linkedin';

export type LinkedinTemplateTypeEnum = 'LINKEDIN_MESSAGE' | 'LINKEDIN_INVITATION' | 'LINKEDIN_INMAIL';

export interface ValidationRules {
  maxBodyLength: number;
  requiresBody: boolean;
  allowSubject: boolean;
  maxSubjectLength?: number;
  requiresSubject?: boolean;
  allowAttachments: boolean;
}

export interface ValidationErrors {
  templateName?: string;
  subject?: string;
  body?: string;
  isRequired?: string;
  uploadError?: string;
}

export interface ValidationWarnings {
  subject?: string;
  body?: string;
}

export interface BaseForm {
  name: string;
  type: LinkedinTemplateTypeEnum;
  subject: string;
  body: string;
  attachmentsToUpload: File[];
}

export const MAX_SIZE = 20; // in MB

export const validationRules: Record<LinkedinTemplateTypeEnum, ValidationRules> = {
  [linkedinMacros.templates.type.message]: {
    allowSubject: false,
    maxBodyLength: 8000,
    requiresBody: true,
    allowAttachments: true,
  },
  [linkedinMacros.templates.type.invitation]: {
    allowSubject: false,
    requiresBody: false,
    maxBodyLength: 200,
    allowAttachments: false,
  },
  [linkedinMacros.templates.type.inmail]: {
    allowSubject: true,
    requiresSubject: true,
    requiresBody: true,
    maxBodyLength: 1900,
    maxSubjectLength: 200,
    allowAttachments: false,
  }
};

export function useLinkedinBaseForm(
  form: Ref<BaseForm>,
  bodyLength: Ref<number>,
  subjectLength: Ref<number>,
) {
  const { t } = useI18n();

  const currentRules = computed<ValidationRules>(() => validationRules[form.value.type]);

  const fieldErrors = computed<ValidationErrors>(() => {
    const errors: ValidationErrors = {};

    // Validate template name
    if (!form.value.name.trim()) {
      errors.isRequired = t('linkedin.form-validation.template-name-required');
    }

    // Validate required fields
    if (currentRules.value.requiresSubject && !form.value.subject.trim()) {
      errors.isRequired = t('linkedin.form-validation.subject-required');
    }

    if (currentRules.value.requiresBody && !form.value.body.trim()) {
      errors.isRequired = t('linkedin.form-validation.body-required');
    }

    // Validate attachments size
    const totalSize = form.value.attachmentsToUpload.reduce((acc, file) => acc + file.size, 0);
    if (totalSize > MAX_SIZE * 1024 * 1024) {
      errors.uploadError = t('linkedin.form-validation.max-size', { max: MAX_SIZE });
    }

    return errors;
  });

  const fieldWarnings = computed<ValidationWarnings>(() => {
    const warnings: ValidationWarnings = {};

    // Length warnings
    if (currentRules.value.maxSubjectLength && subjectLength.value > currentRules.value.maxSubjectLength) {
      warnings.subject = t('linkedin.form-validation.subject-max-length', { max: currentRules.value.maxSubjectLength });
    }

    if (bodyLength.value > currentRules.value.maxBodyLength) {
      warnings.body = t('linkedin.form-validation.body-max-length', { max: currentRules.value.maxBodyLength });
    }

    return warnings;
  });

  const errors = computed(() => Object.values(fieldErrors.value));
  const warnings = computed(() => Object.values(fieldWarnings.value));

  return {
    currentRules,
    fieldErrors,
    fieldWarnings,
    errors,
    warnings,
  };
} 