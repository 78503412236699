<template>
  <ValidationProvider
    :rules="{ required: isError }"
    ref="provider"
    slim
    v-slot="{ errors }">
    <div
      :class="{ 'invalid': errors.length > 0 }"
      class="panel-job-edit-stack col-xs-12">
      <title-section
        class="panel-job-edit-stack__title"
        position="left"
        :title="$t('companies.account-management.issues-stack-skills')">
      </title-section>
      <panel-edit-technos
        :errors-array="errors"
        :stars-blue="2"
        :stars-transparent="0"
        :tags="isDifferentFromOrigin(profile._technosPrimary, editedProfile._technosPrimary)"
        :total-technos="userStack"
        @blur="checkTechnosLength"
        @techno-added="addTechno('_technosPrimary', $event)"
        @techno-deleted="deleteTechno('_technosPrimary', $event)"
        class="panel-job-edit-stack__row">
      </panel-edit-technos>
      <panel-edit-technos
        :errors-array="errors"
        :stars-blue="1"
        :stars-transparent="1"
        :tags="isDifferentFromOrigin(profile._technosSecondary, editedProfile._technosSecondary)"
        :total-technos="userStack"
        @blur="checkTechnosLength"
        @techno-added="addTechno('_technosSecondary', $event)"
        @techno-deleted="deleteTechno('_technosSecondary', $event)"
        class="panel-job-edit-stack__row">
      </panel-edit-technos>
      <p
        class="panel-job-edit-stack__text panel-job-edit-stack__text--error"
        v-if="errors.length > 0">
        Veuillez spécifier au moins une techno.
      </p>
    </div>
  </ValidationProvider>
</template>

<script>
  import TitleSection from '@/components/Title/TitleSection';
  import PanelEditTechnos from '@/components/Panel/PanelEdit/PanelEditTechnos';
  import { ValidationProvider } from 'vee-validate';

  export default {
    name: 'panel-job-edit-stack',
    components: {
      PanelEditTechnos,
      TitleSection,
      ValidationProvider
    },
    props: {
      profile: {
        type: Object,
        default: () => ({})
      },
      editedProfile: {
        type: Object,
        default: () => ({})
      },
      requiredStack: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      userStack() {
        return [
          ...this.isDifferentFromOrigin(((this.profile || {})._technosSecondary || []), (this.editedProfile || {})._technosSecondary),
          ...this.isDifferentFromOrigin(((this.profile || {})._technosPrimary || []), (this.editedProfile || {})._technosPrimary)
        ];
      },
      isError() {
        if (!this.requiredStack) {
          return this.requiredStack;
        }
        return this.userStack.length === 0;
      }
    },
    methods: {
      addTechno(key, techno) {
        const referenceArray = this.isDifferentFromOrigin(this.profile[key], this.editedProfile[key]) || [];

        this.$emit('update-value', {
          [key]: [...referenceArray, techno]
        });
      },
      deleteTechno(key, techno) {
        const referenceArray = this.isDifferentFromOrigin(this.profile[key], this.editedProfile[key]);
        const newTechnoArray = (referenceArray || []).filter(t => t._id !== techno._id);

        this.$emit('update-value', {
          [key]: newTechnoArray
        });
      },
      isDifferentFromOrigin(origin = [], edited) {
        return Array.isArray(edited) && (edited.length !== origin.length || [...origin, ...edited].some(item => !origin.includes(item._id) || !edited.includes(item._id))) ? edited : origin;
      },
      checkTechnosLength() {
        if (this.isError) {
          this.$refs.provider.applyResult({
            errors: ['Veuillez spécifier au moins une techno'],
            valid: false,
            failedRules: {}
          });
        }
      }
    },
    watch: {
      userStack(to, from) {
        if (to.length > 0 && (from || []).length === 0) {
          this.$refs.provider.applyResult({
            errors: [],
            valid: true,
            failedRules: {}
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .panel-job-edit-stack {
    $this: &;

    &__row {
      margin-bottom: 15px;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex-grow: 1;
      @include bp('tablet') {
        flex-wrap: nowrap;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__title {
      margin-bottom: $panel-title-margin-bottom;
    }


    &__text {
      font-size: $font-size-xs;

      &--error {
        color: $color-red-amaranth;
        margin-top: 10px;
        justify-content: center;
      }
    }
  }
</style>
