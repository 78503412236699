export const setAlternativeImg = ({ event, type = 'coders' }) => {
    event.target.src = type === 'coders' ? require('@/assets/img/default-avatar.svg') : require('@/assets/img/default-avatar-company-2.svg');
};

export default {
    methods: {
        setAlternativeImg(event) {
            event.target.src = require('@/assets/img/default-avatar.svg');
        },
    },
};
