import { Ref, ref } from 'vue';
import { AxiosResponse } from 'axios';
import { useDebounceFn } from '@vueuse/core';

export function useAutocomplete<T>({ getSuggestionsFn }: {
  getSuggestionsFn: (query: string) => Promise<AxiosResponse<T[]>>;
}): {
  suggestions: Ref<T[]>;
  inputValue: Ref<string>;
  getSuggestions: (query: string) => void;
} {
  const inputValue = ref('');
  const suggestions: Ref<T[]> = ref([]);

  const debouncedRequest = useDebounceFn(async(value: string) => {
    const { data } = await getSuggestionsFn(value);

    suggestions.value = data;
  }, 500);

  async function handleSuggestions(value: string) {
    await debouncedRequest(value);
  }

  return {
    suggestions,
    inputValue,
    getSuggestions: handleSuggestions,
  };
}
