<template>
  <BcCustomFieldContainer
    :label="withLabel ? name ? name : customField.name : undefined"
    :icon="withLabel ? customField.icon : undefined"
    :unit="withLabel ? customField.unit : undefined"
  >
    <component
      :is="`bc-custom-field-${componentType}`"
      :custom-field="customField"
      :is-filter="isFilter"
      :selected="selected"
      :multiselected="multiselected"
      @on-update="handleUpdate"
      v-bind="$attrs"
    />
  </BcCustomFieldContainer>
</template>

<script>
import customFields from '../../utils/customFields';
import BcCustomFieldContainer from './BcCustomFieldContainer';
import BcCustomFieldSingleSelect from './BcCustomFieldSingleSelect';
import BcCustomFieldMultiSelect from './BcCustomFieldMultiSelect';
import BcCustomFieldRange from './BcCustomFieldRange';
import BcCustomFieldNumber from './BcCustomFieldNumber';
import BcCustomFieldNumberWithUnit from './BcCustomFieldNumberWithUnit';
import BcCustomFieldCursor from './BcCustomFieldCursor';
import BcCustomFieldLink from './BcCustomFieldLink';
import BcCustomFieldString from './BcCustomFieldString';


export default {
  name: 'bc-custom-field',
  components: {
    BcCustomFieldContainer,
    BcCustomFieldSingleSelect,
    BcCustomFieldMultiSelect,
    BcCustomFieldRange,
    BcCustomFieldNumber,
    BcCustomFieldNumberWithUnit,
    BcCustomFieldCursor,
    BcCustomFieldLink,
    BcCustomFieldString,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return Object.keys(customFields).includes(value);
      },
    },
    customField: {
      type: Object,
      required: true,
    },
    selected: {
      type: [String, Array, Number],
    },
    multiselected: {
      type: Array,
    },
    name: {
      type: String,
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
    withLabel: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    componentType() {
      if (this.isFilter && customFields[this.type]?.filter) {
        return customFields[this.type]?.filter;
      }
      return this.type;
    }
  },
  methods: {
    handleUpdate(evt) {
      this.$emit('on-update', evt);
    },
  }
};
</script>
