export const validateId = id => {
  if (id) {
    return;
  }

  throw new Error('L\'id envoyé est invalide');
};

export default {
  validateId,
};
