<template>
  <div>DISCONNECTING...</div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router/composables';
import { useStore } from '@/store';
import { removeToken } from '@/managers/token/useCases/removeToken';

const router = useRouter()
const store = useStore()
onMounted(async () => {
  try {
    removeToken();
    await store.dispatch('user/cleanProfile');
  } finally {
    router.push('/auth/login')
  }
})
</script>
