<template>
  <div
    :data-id="customJob._id"
    class="custom-jobs-card card"
    :class="{
      'custom-jobs-card--isopened': customJob.active,
      'custom-jobs-card--isclosed': !customJob.active,
    }"
    @click="handleClick">
    <div class="custom-jobs-card__body">
      <button
        v-if="!onlyInfo"
        class="handle"
        :class="{ 'handle--active': isDraggable, 'handle--disabled': !isDraggable }">
        <i class="icon-more-vertical-double" />
      </button>
      <div
        class="custom-jobs-card__content"
        :class="{ 'ml-6': !onlyInfo, 'ml-2': onlyInfo }"
      >
        <div>
          <div v-if="configurationFramework.showCompanies" class="custom-jobs-card__content--company w-1/10">
            <bc-avatar
              :src="(customJob._company || {}).logoUrl"
              class="custom-jobs-card__company-logo is-unshrink"
              size="s"
              @error="setAlternativeImg">
            </bc-avatar>
          </div>
          <div class="custom-jobs-card__content--container w-4/5">
            <div class="custom-jobs-card__content--items">

              <h5 v-if="configurationFramework.showCompanies" class="custom-jobs-card__companyName">{{ companyName }}</h5>
              <separator v-if="configurationFramework.showCompanies" barStyles="height: 15px; background: #1D395E;" />
              <p>
                <span class="custom-jobs-card__job-title">{{ title }}</span>
              </p>

              <div class="custom-jobs-card__content-block">
                <tag
                  v-if="customJob.customFields && customJob.customFields.emergency"
                  :text="customJob.customFields.emergency"
                  type="emergency"
                />
              </div>

              <div v-if="customFieldNbProfile" class="custom-jobs-card__content-block">
                <tag :icon="customFieldNbProfileConfig.icon" :text="customFieldNbProfile" />
              </div>
            </div>
            <div ref="container" class="flex flex-nowrap space-x-3">
              <!-- Location -->
              <div ref="locations" v-if="baseLocations.length && !isBlockDisabled('locations')" class="custom-jobs-card__content-block">
                <tag
                  v-for="location in baseLocations.slice(0, 1)"
                  :key="location.place_id"
                  :text="setShortedText(location.fullAddress)"
                  v-tooltip="{
                    content:
                      location.fullAddress.length > textMaxLength
                        ? location.fullAddress
                        : undefined,
                  }"
                  type="location" />
                <tag
                  v-if="baseLocations.length > 1"
                  :text="`+${baseLocations.slice(1).length}`"
                  type="location"
                  v-tooltip="{
                    content: baseLocations
                      .slice(1)
                      .map((location, i) => `${i > 0 ? '<br />' : ''}${location.fullAddress}`),
                  }" />
              </div>

              <!-- Professions -->
              <div ref="professions" v-if="customJob._professions.length && !isBlockDisabled('professions')" class="flex flex-nowrap space-x-2">
                <tag
                  v-for="profession in customJob._professions.slice(0, 1)"
                  :key="profession._id"
                  :text="profession.name"
                  type="professions" />
                <tag
                  v-if="customJob._professions.length > 1"
                  :text="`+${customJob._professions.slice(1).length}`"
                  type="professions"
                  v-tooltip="{
                    content: customJob._professions
                      .slice(1)
                      .map((p, i) => `${i > 0 ? '<br />' : ''}${p.name}`),
                  }" />
              </div>

              <div ref="experience" v-if="customFieldExperience && !isBlockDisabled('experience')" class="custom-jobs-card__content-block">
                <tag
                  :icon="customFieldExperience.icon"
                  :text="customFieldExperience.value" />
              </div>

              <!-- Contract -->
              <div ref="contract" v-if="customJob.contract && !isBlockDisabled('contract')" class="custom-jobs-card__content-block">
                <tag
                  :text="contractsLabels[customJob.contract]"
                  type="contracts" />
              </div>

              <div ref="salary" v-if="showSalaryRange && !isBlockDisabled('salary')" class="custom-jobs-card__content-block">
                <tag :text="salaryRange" type="salary" />
              </div>

              <!-- Custom Fields -->
              <div ref="customFields" v-if="customFieldsFiltred.length && !isBlockDisabled('customFields')" class="custom-jobs-card__content-block">
                <tag
                  v-for="customField in customFieldsFiltred" :key="customField.key"
                  :icon="customField.icon"
                  :text="customField.value" />
              </div>

              <!-- Skills -->
              <div ref="skills" v-if="customJob._skills.length && !isBlockDisabled('skills')" class="flex flex-nowrap space-x-2">
                <tag
                  v-for="skill in customJob._skills.slice(0, skillsSlice)"
                  :key="skill._id"
                  :text="customJob._skills.length > 1 ? setShortedText(skill.name) : skill.name"
                  v-tooltip="{
                    content:
                      customJob._skills.length > 1 && skill.name.length > textMaxLength
                        ? skill.name
                        : undefined,
                  }"
                  type="technos" />
                <tag
                  v-if="customJob._skills.length > skillsSlice"
                  :text="`+${customJob._skills.slice(skillsSlice).length}`"
                  type="technos"
                  v-tooltip="{
                    content: customJob._skills
                      .slice(skillsSlice)
                      .map((p, i) => `${i > 0 ? ' ' : ''}${p.name}`),
                  }" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!customJob.isSpontaneous" class="custom-jobs-card__multipost">
        <div class="custom-jobs-card__publish-status" :class="{ 'is-online': customJob.adPublished, 'is-offline': !customJob.adPublished }">
          <div v-if="jobboardsPublishList.length" class="custom-jobs-card__jobboard-container">
            <div
              v-for="jobboard in jobboardsPublishList.slice(0, 3)"
              :key="jobboard"
              v-tooltip.bottom="{ html: true, content: `<div style='display: block;'>${renderJobboardTooltip([jobboard])}</div>` }"
              class="custom-jobs-card__jobboard"
              :style="{
                'background-image': 'url('+getLogo(jobboard)+')',
                'background-size': 'cover',
              }"
            >
            </div>
            <div
              v-if="jobboardsPublishList.length > 3"
              v-tooltip.bottom="{
                html: true, content: `<div style='display: block;'>${renderJobboardTooltip(jobboardsPublishList)}</div>`
              }"
              class="custom-jobs-card__list-counter"
            >
              +{{ jobboardsPublishList.slice(3).length }}
            </div>
          </div>
          <p
            :class="{ 'is-online': customJob.adPublished, 'is-offline': !customJob.adPublished }"
          >
            {{ publishedStatus }}
          </p>
        </div>
      </div>
    </div>

    <div class="custom-jobs-card__footer pl-6">
      <OwnersList
        class="ml-4 mr-5"
        :owners="customJob._owners" />
      <div class="custom-jobs-card__stats">
        <custom-jobs-card-stat
          v-if="careerIsActive"
          :count="customJob.nbCandidateProcesses"
          icon="presentation"
          :label="$tc('process.application-to-be-processed', customJob.nbCandidateProcesses)"
        ></custom-jobs-card-stat>
        <custom-jobs-card-stat
          :count="customJob.nbActiveProcesses"
          icon="arrow-dot-down"
          :label="$tc('process.candidates-in-process', customJob.nbActiveProcesses)"
        ></custom-jobs-card-stat>
        <custom-jobs-card-stat
          :count="customJob.nbRecruitedProcesses"
          icon="partners"
          :label="$tc('process.candidates-recruited', customJob.nbRecruitedProcesses)"
        ></custom-jobs-card-stat>
      </div>
      <div v-if="!onlyInfo" class="custom-jobs-card__social-buttons">
        <button-find-candidates
          :job="customJob"
          @mouseenter="isHover = true"
          @mouseleave="isHover = false"
        ></button-find-candidates>
      </div>
      <div class="ml-2 mr-5">
        <custom-jobs-dropdown-menu
          v-if="!customJob.isSpontaneous && !onlyInfo"
          class="custom-jobs-card__dropdown-menu"
          :is-custom-job-open="customJob.active"
          @mouseenter="isHover = true"
          @mouseleave="isHover = false"
          @on-action="$emit('on-action', $event, customJob)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { contractsLabels, salaryTypesLabels } from '@/api/custom-jobs';

import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
import Tag from '@/components/Tag/Tag';
import CustomJobsDropdownMenu from '@/components/CustomJobs/CustomJobsDropdownMenu';
import CustomJobsCardStat from '@/components/CustomJobs/CustomJobsCardStat';
// import CustomJobsStat from '@/components/CustomJobs/CustomJobsStat';
import ButtonFindCandidates from '@/components/Button/ButtonFindCandidates';
import Separator from '@/components/Separator/Separator';
import { formatPlaceDetailsToBaseLocation } from '@/formatters/locations';
import CustomJobsCardOwnersList from '@/components/CustomJobs/CustomJobsCardOwnersList.vue';
import OwnersList from '@/components/UIKit/OwnersList.vue';

export default {
  name: 'custom-jobs-card',
  components: {
    OwnersList,
    Tag,
    BcAvatar,
    CustomJobsDropdownMenu,
    CustomJobsCardStat,
    // CustomJobsStat,
    ButtonFindCandidates,
    Separator,
    CustomJobsCardOwnersList
},
  props: {
    customJob: {
      type: Object,
      default: () => ({}),
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    windowWidth: {
      type: Number,
      default: 0,
    },
    onlyInfo: {
      type: Boolean,
      default: false,
    },
    showOwner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contractsLabels,
      textMaxLength: 12,
      skillsSlice: 1,
      isHover: false,
      containerWidth: 0,
      resizeObserver: null,
      blockWidths: {
        locations: 0,
        professions: 0,
        experience: 0,
        contract: 0,
        salary: 0,
        customFields: 0,
        skills: 0,
        multipost: 0
      },
      spacing: 10, // Spacing between blocks
      disabledBlocks: [] // Tracks which blocks are disabled
    };
  },
  mounted() {
    this.initializeResizeObserver();
  },
  beforeDestroy() {
    // Clean up observer
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  watch: {
    blockWidths: {
      deep: true,
      handler() {
        this.updateDisabledBlocks();
      }
    },
    containerWidth: {
      handler() {
        this.updateDisabledBlocks();
      }
    }
  },
  computed: {
    ...mapGetters('user', ['configurationJob', 'configurationFramework', 'configurationJobMultiposting']),
    ...mapState({
      activatedChannelsSubscriptions: state => state.xtramile.activatedChannelsSubscriptions,
    }),
    profile() {
      return this.$store.state.user.profile;
    },
    careerIsActive() {
      return this.profile?._organization?.configuration?.career?.activated;
    },
    websiteName() {
      return this.profile?._organization?.configuration?.integrations?.job_multiposting?.website?.params?.websiteName;
    },
    companyName() {
      return this.customJob._company?.name || '';
    },
    baseLocations() {
      return (this.customJob.locations || []).map(formatPlaceDetailsToBaseLocation);
    },
    title() {
      if (this.customJob.isSpontaneous) {
        return this.formatTitle(this.customJob.title);
      }
      return this.customJob.title;
    },
    statusLabel() {
      return this.customJob.active
        ? this.$t('companies.panel-company.open')
        : this.$t('companies.panel-company.closed');
    },
    publishedStatus() {
      return this.customJob.adPublished ? this.$t('generics.online') : this.$t('generics.offline');
    },
    budget() {
      return this.customJob.budget || {};
    },
    salaryRange() {
      const salary = [this.budget.min >= 1 ? this.budget.min : undefined, this.budget.max];
      return `${this.getSalaryWithType(salary.filter(Boolean).join('-'), this.budget.salaryType)}${this.getSalaryWithType(this.budget.variable, this.budget.variableType, ' +')}`
    },
    showSalaryRange() {
      return this.budget.min && this.budget.max;
    },
    owner() {
      return this.customJob._owners[0];
    },
    customFieldExperience() {
      return this.customFields.find(cf => cf.key === 'experience');
    },
    customFieldsFiltred() {
      return this.customFields.filter(cf => cf.key !== 'experience');
    },
    customFields() {
      return this.customFieldsConfig.map((customField) => {
        if (Array.isArray(this.customJob.customFields?.[customField.key])) {
          const value = this.customJob.customFields[customField.key]
            .map(value => `${value} ${customField.unit}`)
            .join(' - ')
          return { value, ...customField }
        }
        const value = this.customJob.customFields?.[customField.key];
        return { value, ...customField }
      }).filter(cf => cf.value);
    },
    customFieldsConfig() {
      return this.configurationJob?.customFields?.filter?.(cf => cf.key === 'remote' || cf.key === 'experience');
    },
    customFieldNbProfileConfig() {
      return this.configurationJob?.customFields?.find?.(cf => cf.key === 'nb-profils');
    },
    customFieldNbProfile() {
      return this.customJob.customFields?.['nb-profils'];
    },
    activatedChannelsSubscriptionsMap() {
      return this.activatedChannelsSubscriptions.reduce((acc, val) => {
        return {
          ...acc,
          [val.id]: val,
        }
      }, {});
    },
    jobboardsPublishList() {
      if (!this.customJob.adPublished) {
        return []
      }

      // jobMultiposting without autopublish
      let multiposting = Object.keys(this.customJob?.jobMultiposting || []).filter(key => this.customJob.jobMultiposting[key]?.status === 'published');

      // if autopublish, sync with customjob adPublished
      if (this.configurationJobMultiposting) {
        Object.keys(this.configurationJobMultiposting).forEach(key => {
          if (this.configurationJobMultiposting[key]?.activated && this.configurationJobMultiposting[key]?.enabled && this.configurationJobMultiposting[key]?.params?.autoPublish) {
            multiposting = [...multiposting, key];
          }
        });
      }

      // Clean duplications
      multiposting = [...new Set(multiposting)];

      // xtramile publications
      const xtramile = Object.keys(this.customJob?.xtramilePublications || [])
        .filter(id => this.customJob.xtramilePublications[id]?.status)
        .map(id => this.activatedChannelsSubscriptionsMap[id]?.connectorName)
        .filter(Boolean);

      return [...xtramile, ...multiposting];
    },
  },
  methods: {
    handleClick() {
      if (this.isHover) return;
      this.$emit('click', this.customJob);
    },
    getLogo(jobboard) {
      try {
        return new URL(`../../assets/logo/jobboards/${jobboard}.png`, import.meta.url).href;
      } catch (err) {
        return undefined;
      }
    },
    setAlternativeImg(event) {
      event.target.src = require('@/assets/img/default-avatar-company-2.svg');
    },
    setCoachAlternativeImg(event) {
      event.target.src = require('@/assets/img/default-avatar.svg');
    },
    getOwnerInitials() {
      return `${this.owner.firstName?.charAt(0)}${this.owner.lastName?.charAt(0)}`;
    },
    clickActiveProcessesStat() {
      this.$emit('click-active-processes', this.customJob);
    },
    setShortedText(text) {
      // if (text.length > this.textMaxLength) {
      //   return text.slice(0, this.textMaxLength) + '...';
      // }
      return text;
    },
    formatTitle(title) {
      return title.length > 45 ? title.substring(0, 45) + '...' : title;
    },
    getSalaryWithType(salary, type, prefix = '') {
      if (!salary) return ''
      return `${prefix} ${salary} ${salaryTypesLabels[type] || ''}`
    },
    renderCoachTooltip(list) {
      return list.map(coach => {
        return `
          <div style="display: flex; gap: 5px;">
            <img style="width: 20px; height: 20px; border-radius: 100%;" src="${coach.pictureUrl}" />
            <p>${coach.firstName} ${coach.lastName}</p>
          </div>
        `
      }).join('');
    },
    renderJobboardTooltip(list) {
      return list.map(jobboard => {
        let jobboardName = jobboard;
        if (jobboard === 'website') {
          jobboardName = this.websiteName;
        }
        return `
          <div style="display: flex; gap: 5px; margin-bottom: 5px;">
            <img style="width: 20px; height: 20px; border-radius: 100%;" src="${this.getLogo(jobboard)}" />
            <p style="text-transform: capitalize;">${jobboardName}</p>
          </div>
        `
      }).join('');
    },
    initializeResizeObserver() {
      this.updateWidth();

      // Set up resize observer
      this.resizeObserver = new ResizeObserver(() => {
        this.updateWidth();
      });

      if (this.$refs.container) {
        this.resizeObserver.observe(this.$refs.container);
      }
      // Observe each content block
      Object.keys(this.blockWidths).forEach(block => {
        if (this.$refs[block]) {
          this.resizeObserver.observe(this.$refs[block]);
        }
      });
    },

    updateWidth() {
      if (this.$refs.container) {
        this.containerWidth = this.$refs.container.offsetWidth;
      }
      // Update each block width
      Object.keys(this.blockWidths).forEach(block => {
        if (this.$refs[block]) {
          this.blockWidths[block] = this.$refs[block].offsetWidth;
        }
      });
    },
    updateDisabledBlocks() {
      let totalWidth = 0;
      this.disabledBlocks = [];
      Object.entries(this.blockWidths).forEach(([block, width], index) => {
        totalWidth += width + (index > 0 ? this.spacing : 0); // Add spacing for all blocks except the first
        if (totalWidth > this.containerWidth) {
          this.disabledBlocks.push(block);
        }
      });
    },
    isBlockDisabled(block) {
      return this.disabledBlocks.includes(block);
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-jobs-card {
  width: 100%;
  min-height: 127px;
  background: white;
  border-radius: 5px;
  margin-bottom: 5px;
  position: relative;
  flex-direction: column;

  &__companyName {
    color: $color-blue-dodger;
    font-size: $font-size-m;
    font-weight: 500;
  }

  &__job-title {
    max-width: 550px;
    color: $color-blue-dark-cello;
    font-size: $font-size-m;
    font-weight: 500;
  }

  &:hover {
    cursor: pointer;
  }

  &--isopened {
    background: $color-neutre-4;
  }

  &--isclosed {
    background: #FEF7F7;
  }

  &__body {
    padding: 10px;
    min-height: 76px;
    & > .handle {
      position: absolute;
      top: 15px;
      left: 15px;
      z-index: 2;
      color: $color-blue-dark-cello;
      opacity: 0.5;

      &--active {
        &:hover {
          cursor: grab;
        }

        &:focus {
          cursor: grabbing;
        }
      }

      &--disabled {
        opacity: 0.2;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }

  &__footer {
    width: 100%;
    align-items: center;
    height: 51px;
    background: $color-blue-light;
    justify-content: space-between;
  }

  &__status {
    display: flex;
    align-items: center;
    rotate: -90deg;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-left: 25px;

    &--isopened {
      color: $color-green-shamrock;
    }

    &--isclosed {
      color: $color-red-mandy;
    }
  }

  &__stats {
    width: 70%;
    align-items: center;

    & > div {
      padding: 0px 30px;
    }
  }

  &__avatar {
    margin-right: 10px;
    align-items: center;
    width: 20px;
    height: 20px;
  }

  &__author {
    margin-right: 10px;
    align-items: center;
  }

  &__content {
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > div {
      gap: 10px;
      flex-wrap: wrap;
    }

    &-block {
      gap: 10px;
      flex-wrap: wrap;
    }

    &--company {
      align-items: center;
    }

    &--container {
      margin-top: 5px;
      flex-direction: column;
      gap: 7px;
    }

    &--items {
      align-items: center;
      gap: 10px;

      & > p {
        font-weight: 400;
        font-size: 14px;
        color: $color-blue-dark-cello;
        display: flex;
        gap: 10px;
      }

      &-active {
        color: $color-green-shamrock;
      }
      &-inactive {
        color: #adadad;
      }
    }
  }

  &__social-buttons {
    align-items: center;
    width: 20%;
    justify-content: flex-end;
    padding-right: 10px;
  }

  &__company-logo {
    width: 40px;
    height: 40px;
    border-radius: 0%;
    border-radius: 4px;
    border: 1px solid $color-blue-medium;
  }

  &__publish-status {
    width: fit-content;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px 10px;
    border-radius: 30px;
    height: 28px;

    & p {
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: $font-size-xs;
    }
  }

  &__jobboard {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin-left: -10px;

    &-container {
      padding-left: 8px;
    }
  }

  &__list-counter {
    width: 20px;
    height: 20px;
    background: $color-tertiary;
    color: white;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-left: -10px;
  }
}

.is-online {
  background: #DDF4E9;
  color: $color-green-shamrock;
}

.is-offline {
  background: #F5DFE0;
  color: $color-red-mandy;
}
</style>
