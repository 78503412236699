<template>
  <div class="timeline-item-content is-column is-full-width is-relative">
    <div
      v-if="action.headerHtml"
      class="timeline-item-content__container pl-4 pt-3"
      style="font-size: 14px; border-bottom: 1px solid #d8dfe2; color: #1D385E;"
      v-html="action.headerHtml"
    ></div>
    <div class="timeline-item-content relative w-full">
      <div class="is-column is-full-width">
        <div
          v-if="action.html"
          class="timeline-item-content__container"
          style="padding: 10px; font-size:14px; color: #1D385E; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; max-width: 100%;"
          v-html="action.html">
        </div>
        <div
          v-else-if="action.type === macros.timelineType.contactCalendly"
          class="timeline-item-content__container"
        >
          <div class="timeline-item-content__wrapper is-column">
            <p class="timeline-item-content__text font-size-s is-secondary-dark">
              <span class="font-weight-bold">{{ $t('timeline.reserved-slot') }} :</span>
              {{ $t('timeline.the') }}
              {{ timeSlot }}
            </p>
            <p class="timeline-item-content__text font-size-s is-secondary-dark">
              <span class="font-weight-bold">{{ $t('timeline.recruitment-needs') }} :</span>
              {{ needs }}
            </p>
          </div>
        </div>
        <div
          v-else-if="action.type === macros.timelineType.marvinBotAction"
          class="timeline-item-content__container is-column is-full-width"
        >
          <div
            v-for="(contact, index) in contacts"
            :key="index"
            class="timeline-item-content__wrapper timeline-item-content__contact is-column"
          >
            <p class="timeline-item-content__text font-size-s is-secondary-dark">
              <span class="font-weight-bold">{{ contact.name }}</span> -
              {{ contact.job }}
            </p>
            <p class="timeline-item-content__text font-size-s is-secondary-dark">
              <span class="font-weight-bold">{{ $t('timeline.numbers') }} : </span>{{
                contact.phones
              }}
            </p>
            <p class="timeline-item-content__text font-size-s is-secondary-dark">
              <span class="font-weight-bold">{{ $t('timeline.emails') }} : </span>{{
                contact.emails
              }}
            </p>
          </div>
        </div>
        <div
          v-else-if="action.type === macros.timelineType.coderCompletedSubscriptionTypeform && isCoderCompletedSubscriptionTypeformDisplayed"
          class="timeline-item-content__container"
        >
          <div class="timeline-item-content__wrapper is-column">
            <p
              v-if="coderCompletedSubscriptionTypeformNoRemote"
              class="timeline-item-content__text font-size-s is-secondary-dark">
              <span class="font-weight-bold">{{ $t('timeline.choice-of-teleworking') }} :</span>
              {{ coderCompletedSubscriptionTypeformNoRemote }}
            </p>
            <p
              v-if="coderCompletedSubscriptionTypeformReferral"
              class="timeline-item-content__text font-size-s is-secondary-dark">
              <span class="font-weight-bold">{{ $t('timeline.how-he-knew-us') }} :</span>
              {{ coderCompletedSubscriptionTypeformReferral }}
            </p>
            <p
              v-if="coderCompletedSubscriptionTypeformOtherDomains"
              class="timeline-item-content__text font-size-s is-secondary-dark">
              <span class="font-weight-bold">{{ $t('timeline.professions') }} :</span>
              {{ coderCompletedSubscriptionTypeformOtherDomains }}
            </p>
            <p
              v-if="coderCompletedSubscriptionTypeformOtherLocation"
              class="timeline-item-content__text font-size-s is-secondary-dark">
              <span class="font-weight-bold">{{ $t('timeline.location') }} :</span>
              {{ coderCompletedSubscriptionTypeformOtherLocation }}
            </p>
          </div>
        </div>
        <div
          v-else-if="action.type === macros.timelineType.contactCompletedSubscriptionTypeform"
          class="timeline-item-content__container"
        >
          <div class="timeline-item-content__wrapper is-column">
            <p
              v-if="contactCompletedSubscriptionTypeformData.huntProfileType"
              class="timeline-item-content__text font-size-s is-secondary-dark">
              <span class="font-weight-bold">{{ $t('timeline.profiles-searched') }} :</span>
              {{ contactCompletedSubscriptionTypeformData.huntProfileType }}
            </p>
            <p
              v-if="contactCompletedSubscriptionTypeformData.huntStack"
              class="timeline-item-content__text font-size-s is-secondary-dark">
              <span class="font-weight-bold">{{ $t('timeline.stack') }} :</span>
              {{ contactCompletedSubscriptionTypeformData.huntStack }}
            </p>
            <p
              v-if="contactCompletedSubscriptionTypeformData.rpoProfileType"
              class="timeline-item-content__text font-size-s is-secondary-dark">
              <span class="font-weight-bold">{{
                  $t('timeline.type-of-profile-rpo-will-source')
                }} :</span>
              {{ contactCompletedSubscriptionTypeformData.rpoProfileType }}
            </p>
            <p
              v-if="contactCompletedSubscriptionTypeformData.callSchedule"
              class="timeline-item-content__text font-size-s is-secondary-dark">
              <span class="font-weight-bold">{{ $t('timeline.when-to-call') }}  :</span>
              {{ contactCompletedSubscriptionTypeformData.callSchedule }}
            </p>
            <p
              v-if="contactCompletedSubscriptionTypeformData.referral"
              class="timeline-item-content__text font-size-s is-secondary-dark">
              <span class="font-weight-bold">{{ $t('timeline.how-he-knew-us') }} :</span>
              {{ contactCompletedSubscriptionTypeformData.referral }}
            </p>
          </div>
        </div>
        <div v-else class="timeline-item-content__container is-full-width">
          <notes-editor
            v-if="((action || {})._note || {}).type !== macros.types.reminder.api"
            :action="action"
            :is-editing="isEditing"
            :is-new-note="$route.query.type === 'coders' || $route.query.type === 'suggestions-coders'"
            :profile="profile"
            class="timeline-item-content__editor is-full-width font-size-s border-radius-m"
            @update:isEditing="handleIsEditing"
            @link-process="$emit('link-process', $event)"
            @note-created="$emit('note-created', $event)"
            @note-deleted="$emit('note-deleted', $event)"
            @note-edited="$emit('note-edited', $event)"
            @note-pinned="$emit('note-pinned')"
            @unlink-process="$emit('unlink-process', $event)">
          </notes-editor>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import notesMacros, { getMediaFromApiValue } from '@/common-old/macros/notes';
  import NotesEditor from '@/components/Notes/NotesEditor';
  import { timelineType } from '@/common-old/macros/timeline/timeline';
  import moment from 'moment/min/moment-with-locales';

  export default {
    name: 'timeline-item-content',
    components: { NotesEditor },
    props: {
      action: {
        type: Object,
        default: () => ({}),
      },
      profile: {
        type: Object,
        default: () => ({}),
      },
      isEditing: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        macros: {
          types: notesMacros.type,
          timelineType,
        },
      };
    },
    computed: {
      media() {
        return ((this.action || {})._note || {}).media;
      },
      timeSlot() {
        if (this.action.type === timelineType.contactCalendly) {
          return moment(this.action?._company?.calendly?.timeSlot).format(
            'do',
          );
        }

        return null;
      },

      needs() {
        if (this.action.type === timelineType.contactCalendly) {
          return this.action.data;
        }

        return null;
      },
      contacts() {
        return this.action && this.action.data
          ? JSON.parse(this.action.data)
          : [];
      },
      coderCompletedSubscriptionTypeformData() {
        return this.action && this.action.type === timelineType.coderCompletedSubscriptionTypeform && this.action.data && JSON.parse(this.action.data);
      },
      coderCompletedSubscriptionTypeformNoRemote() {
        return this.coderCompletedSubscriptionTypeformData && this.coderCompletedSubscriptionTypeformData.noRemote;
      },
      coderCompletedSubscriptionTypeformReferral() {
        return this.coderCompletedSubscriptionTypeformData && this.coderCompletedSubscriptionTypeformData.referral;
      },
      coderCompletedSubscriptionTypeformOtherDomains() {
        return this.coderCompletedSubscriptionTypeformData && this.coderCompletedSubscriptionTypeformData.otherDomains;
      },
      coderCompletedSubscriptionTypeformOtherLocation() {
        return this.coderCompletedSubscriptionTypeformData && this.coderCompletedSubscriptionTypeformData.otherLocation;
      },
      isCoderCompletedSubscriptionTypeformDisplayed() {
        return this.coderCompletedSubscriptionTypeformData && (this.coderCompletedSubscriptionTypeformNoRemote || this.coderCompletedSubscriptionTypeformReferral || this.coderCompletedSubscriptionTypeformOtherDomains || this.coderCompletedSubscriptionTypeformOtherLocation);
      },
      contactCompletedSubscriptionTypeformData() {
        return this.action && this.action.type === timelineType.contactCompletedSubscriptionTypeform && this.action.data && JSON.parse(this.action.data);
      },
    },
    methods: {
      getIcon(apiValue) {
        const note = getMediaFromApiValue(apiValue);

        return note.icon;
      },
      handleIsEditing(e) {
        this.$emit('update:is-editing', e);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .timeline-item-content {
    &__container {
      flex-direction: column;

      .date--to--contact {
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        p {
          margin-right: 8px;
        }

        span {
          color: #3c80f7;
          border: 1px solid #3c80f74d;
          border-radius: 5px;
          padding: 8px;
        }
      }

      .skills {
        margin-bottom: 16px;
        flex-direction: column;
        width: 100%;

        p {
          margin-bottom: 8px;
        }

        section {
          display: flex;
          flex-direction: row;
          padding: 0;
          max-width: 100%;
          flex-wrap: wrap;

          .bc-tag {
            margin-right: 8px;
            width: max-content;
            flex: 0 0 auto;
            margin-bottom: 8px;
          }
        }
      }

      .salary {
        flex-direction: row;
        margin-bottom: 16px;

        div {
          display: flex;
          flex-direction: column;
          margin-right: 8px;

          p {
            margin-bottom: 6px;
          }
        }
      }

      .openToWork {
        align-items: center;
        flex-direction: row;

        div:first-child {
          margin-right: 16px;
        }

        div:last-child {
          display: flex;
          align-items: center;

          p {
            margin-right: 8px;
          }

          span {
            color: #3C80F7;
            border: 1px solid #3C80F74D;
            border-radius: 5px;
            padding: 8px;
          }
        }
      }
    }

    &__wrapper {
      right: 10px;
      bottom: 10px;
    }

    &__media {
      margin-top: -5px;
      right: 7.5px;
      color: $color-primary;
    }

    &__medium {
      height: 30px;
      width: 30px;
      margin-right: 5px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &__contact {
      margin-bottom: 10px;
    }
  }
</style>
