import { experienceOptions } from '@/macros/coders/experiences';
import { roles } from '@/macros/coders/roles';
import { contractOptions } from '@/macros/coders/contracts';
import { domains } from '@/common-old/macros/market';
import { personalityOptions } from '@/macros/coders/personalities';

export const getExperienceObjectFromApiValue = apiValue => Object.values(experienceOptions || []).find(experience => experience.api === apiValue);

export const getExperienceNameFromApiValue = apiValue => (getExperienceObjectFromApiValue(apiValue) || {}).name;

export const getExperienceQueryFromApiValue = apiValue => (getExperienceObjectFromApiValue(apiValue) || {}).query;

export const getExperienceNameFromExperienceNumber = experienceNumber => {
  const options = Object.values(experienceOptions);
  const experience = options.find(xp => experienceNumber >= xp.range[0] && experienceNumber <= xp.range[1]);

  return (experience || {}).name;
};

export const getRoleNameFromApiValue = apiValue => {
  if (apiValue) {
    const rolesArray = Object.entries(roles);
    const [, selectedRole] = rolesArray.find(([, values]) => values.api === apiValue);

    return selectedRole?.value;
  }

  return ''
};

export const getContractObjectFromApiValue = apiValue => Object.values(contractOptions || []).find(contract => contract.api === apiValue);

export const getContractNameFromApiValue = apiValue => (getContractObjectFromApiValue(apiValue) || {}).name;

export const getDomainFromApiValue = apiValue => Object.values(domains).find(domain => domain.api === apiValue);

export const getDomainNameFromApiValue = apiValue => (getDomainFromApiValue(apiValue) || {}).name;

export const getPersonalityObjectFromApiValue = apiValue => Object.values(personalityOptions || []).find(personality => personality.api === apiValue);

export const getPersonalityNameFromApiValue = apiValue => (getPersonalityObjectFromApiValue(apiValue) || {}).name;


export default {
  getExperienceNameFromApiValue,
  getExperienceQueryFromApiValue,
  getExperienceObjectFromApiValue,
  getExperienceNameFromExperienceNumber,
  getRoleNameFromApiValue,
  getContractObjectFromApiValue,
  getContractNameFromApiValue,
  getPersonalityNameFromApiValue,
  getPersonalityObjectFromApiValue,
};
