<template>
  <div class="panel-modal-merge-profile is-column">
    <div class="is-row is-align-items-center">
      <p
        class="panel-modal-merge-profile__title mb-5">
        {{ $t('candidates.merge-these-two-profiles') }}
      </p>
    </div>
    <div
      :class="{'is-align-items-center': hasSelectedProfile }"
      class="panel-modal-merge-profile__body is-row is-flex">
      <div class="panel-modal-merge-profile__container is-column">
        <div
          class="is-column"
          v-if="hasSelectedProfile">
          <merge-profile
            :conflicts="profileConflicts"
            :is-profile-to-delete="true"
            :profile="selectedProfile"
            @change="mergeProfileField"
            class="panel-modal-merge-profile__item"
            data-profile-to-delete>
          </merge-profile>
        </div>
        <div
          class="panel-modal-merge-profile__autocomplete is-column"
          v-else>
          <bc-autocomplete
            :is-scrollable="false"
            :limit="5"
            :options="filteredSuggestions"
            @input="getSuggestions"
            @select="getSelectedProfile"
            class="panel-modal-merge-profile__autocomplete"
            icon="search"
            name="coderSuggestion"
            :placeholder="$t('candidates.research-a-profile')"
            v-model="inputValue">
            <template #option="{ option }">
              <div class="panel-modal-merge-profile__suggestion is-align-items-center">
                <bc-avatar
                  :src="option.pictureUrl"
                  @error="setAlternativeImg"
                  size="xs">
                </bc-avatar>

                <bc-dropdown-item
                  :info="option.title"
                  :text="`${option.firstName} ${option.lastName}`"
                  class="panel-modal-merge-profile__suggestion-text">
                </bc-dropdown-item>
              </div>
            </template>
          </bc-autocomplete>
        </div>
      </div>
      <!-- <i
        class="icon-arrow-right"
        v-if="hasSelectedProfile">
      </i> -->
      <div
        @click="swapProfile"
        class="icon-repeat panel-modal-merge-profile__repeat is-justify-content-center is-align-items-center"
        data-swap-button
        v-if="hasSelectedProfile">
      </div>
      <div class="panel-modal-merge-profile__container is-column">
        <merge-profile
          :profile="profileToUpdate"
          class="panel-modal-merge-profile__item">
        </merge-profile>
      </div>
    </div>
    <div class="panel-modal-merge-profile__footer">
      <bc-button
        @click.native="closeModal"
        class="panel-modal-merge-profile__cta"
        data-button-cancel
        type="outlined">
        {{ $t('generics.cancel') }}
      </bc-button>
      <bc-button
        :disabled="!hasSelectedProfile"
        @click.native="mergeProfile"
        class="panel-modal-merge-profile__cta"
        data-button-merge>
        {{ $t('generics.merge') }}
      </bc-button>
    </div>
    <banner
      class="panel-modal-merge-profile__pre-footer mt-4"
      v-if="hasSelectedProfile">
      <p class="panel-modal-merge-profile__text">
        1. {{ $t('candidates.merge-profile-first-p') }}
      </p>
      <p class="panel-modal-merge-profile__text">
        2. {{ $t('candidates.merge-profile-second-p') }}
      </p>
      <p class="panel-modal-merge-profile__text">
        3. {{ $t('candidates.merge-profile-tirth-p') }}
      </p>
      <p
        class="panel-modal-merge-profile__text no-margin">
        4. {{ $t('candidates.merge-action-is') }}
        <span
          class="panel-modal-merge-profile__text panel-modal-merge-profile__text--bold">{{ $t('generics.irreversible') }}.</span>
      </p>
    </banner>
  </div>
</template>

<script>
  import coderHttp from '@/api/coders';
  import { debounce } from '@/common-old/mixins/Debounce/debounce';
  import { getProfileConflictsHelper } from '@/helpers/merge-profile/getConflicts';
  //
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete';
  import MergeProfile from '@/components/MergeProfile/MergeProfile.vue';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import Banner from '@/components/Banner/Banner';
  import { getRemoteNameFromApiValue } from '@/common-old/macros/remote';

  export default {
    name: 'panel-modal-merge-profile',
    components: {
      BcButton,
      BcDropdownItem,
      BcAvatar,
      MergeProfile,
      BcAutocomplete,
      Banner,
    },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
      selectedCoder: {
        type: Object,
        default: () => ({}),
      },
      createCoder: {
        type: Function,
        default: () => undefined,
      },
    },
    mounted() {
      if (Object.keys(this.selectedCoder).length) {
        this.selectedProfile = this.selectedCoder;
      }
    },
    data() {
      return {
        inputValue: '',
        suggestions: [],
        selectedProfile: {},
        profileConflicts: [],
        profileToUpdate: {},
        fieldsToMerge: {},
      };
    },
    watch: {
      selectedProfile() {
        this.getProfileConflicts();
      },
      profile: {
        handler() {
          /**
           * Use spread to deep copy
           * @type {{}}
           */
          this.profileToUpdate = { ...this.profile };

          if (this.hasSelectedProfile) {
            this.getProfileConflicts();
          }
        },
        immediate: true,
      },
    },
    computed: {
      filteredSuggestions() {
        return this.suggestions.filter(coder => coder._id !== this.profileToUpdate._id);
      },
      hasSelectedProfile() {
        return this.selectedProfile.hasOwnProperty('_id');
      },
      mapField() {
        return {
          remote: (this.selectedProfile.remote || []).map(remote => getRemoteNameFromApiValue(remote)),
        };
      },
    },
    methods: {
      getSuggestions: debounce(async function(query) {
        try {
          const { data } = await coderHttp.codersAutocomplete(query);

          this.suggestions = data;
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      }, 500),
      async getSelectedProfile(coder) {
        try {
          const { data } = await coderHttp.getCoderProfile(coder._id);

          this.selectedProfile = data;
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      async mergeProfile() {
        try {
          let newProfile = {};
          let profileActivity = {};

          if (!this.profileToUpdate._id) {
            const coder = await this.createCoder();
            if (coder) {
              this.profileToUpdate = coder;
            }
          }

          const { data: { profileMerged } } = await coderHttp.coderMerge({
            id: this.profileToUpdate._id,
            coderToDelete: this.selectedProfile._id,
            fieldsToMerge: this.fieldsToMerge,
          });

          if (this.$route.name.includes('Coders')) {
            profileActivity = await coderHttp.getCoderActivity(this.profileToUpdate._id);
          }

          newProfile = {
            ...this.profile,
            ...profileMerged,
            ...profileActivity.data,
          };

          if (profileMerged._id === this.profile._id) {
            this.updatePanelProfile(newProfile);
          } else {
            this.removeOldProfileCard(this.selectedProfile._id);
            this.redirectToNewMergedProfile(profileMerged._id);
          }

          this.closeModal();
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      setAlternativeImg(event) {
        event.target.src = require('@/assets/img/default-avatar.svg');
      },
      updatePanelProfile(profile) {
        this.$emit('profile-updated', profile);
      },
      closeModal() {
        this.$emit('close-modal');
      },
      getProfileConflicts() {
        this.profileConflicts = getProfileConflictsHelper({
          profileType: 'coder',
          profileToDelete: this.selectedProfile,
          profileToUpdate: this.profileToUpdate,
        });
      },
      redirectToNewMergedProfile(profileId) {
        this.$router.push({
          name: 'CodersPanel',
          params: {
            id: profileId,
          },
          query: {
            ...this.$route.query,
          },
        });
      },
      removeOldProfileCard(profileId) {
        this.$store.dispatch('removeCard', profileId);
      },
      mergeProfileField(field) {
        this.$set(this.profileToUpdate, field, this.selectedProfile[field]);

        if (this.mapField[field]) {
          this.$set(this.fieldsToMerge, field, this.mapField[field]);
        } else {
          this.$set(this.fieldsToMerge, field, this.selectedProfile[field]);
        }

        this.deleteConflict(field);
      },
      deleteConflict(field) {
        this.profileConflicts = this.profileConflicts.filter(conflict => conflict !== field);
      },
      swapProfile() {
        const oldProfileToUpdate = this.profileToUpdate;

        this.profileToUpdate = this.selectedProfile;
        this.selectedProfile = oldProfileToUpdate;
        this.fieldsToMerge = {};
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-modal-merge-profile {
    width: auto;
    min-width: 975px;
    min-height: 80vh;
    max-height: 85vh;
    padding: 50px;
    overflow: auto;
    display: flex;

    &__title {
      font-size: 24px;
      font-weight: $font-weight-bold;
      color: $color-tertiary;
    }

    &__text {
      @include font-regular(14px);
      line-height: 16px;
      margin-bottom: 15px;

      &--grey {
        color: $color-grey-2;
      }

      &--red {
        color: $color-error;
      }

      &--secondary {
        color: $color-secondary
      }

      &--bold {
        @include font-bold(14px);
      }
    }

    &__body {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
      flex-grow: 1;
    }

    &__container {
      width: 100%;
      height: auto;
      margin-right: 15px;

      &:last-of-type {
        margin-right: 0;
        margin-left: 15px;
      }
    }

    &__suggestion {
      margin-left: 10px;
    }

    &__suggestion-text {
      & :deep() .bc-dropdown-item__info {
        max-width: 250px;
        overflow: hidden;
      }
    }

    &__item {
      width: 445px;
      height: 480px;
      flex-grow: 1;
    }

    &__pre-footer {
      flex-direction: column;
      padding: 20px;
    }

    &__footer {
      justify-content: center;
      align-items: center;
    }

    &__cta {
      margin: 0 10px;
      width: 279px;
    }

    &__autocomplete {
      width: 100%;

      :deep() .bc-input {
        margin-top: 0;
      }
    }

    &__repeat {
      width: 30px;
      height: 30px;
      margin-left: 10px;
      border-radius: 50%;
      background-color: $color-secondary;
      color: white;

      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
