import { computed } from 'vue';
import { useStore } from '@/store';
export const useLinkedinTemplateVariables = ({ restrictToFirstAndLastName = false }: { restrictToFirstAndLastName?: boolean } = {}) => {
  const store = useStore();
  const variables = computed(() => store.state.emails.templatesVariables);

  const filteredVariables = computed(() => variables.value.filter((variable: any) => {
    if (!restrictToFirstAndLastName) return true;
    return variable.slug === 'firstName' || variable.slug === 'lastName';
  }));

  return {
    linkedinTemplateVariables: filteredVariables,
  };
};
