<template>
  <div class="panel-tag-link">
    <div class="panel-tag-link__column">
      <div class="panel-tag-link__card">
        <div class="panel-tag-link__card-label">
          {{ $t('tags.add-link') }}
        </div>
        <div class="panel-tag-link__card-content">
          <div class="panel-tag-link__card-column">
            <div>{{ $t('tags.type-of-link') }}</div>
            <bc-dropdown
              :is-scrollable="false"
              v-if="tagTypeLinkOptions.length"
              :options="tagTypeLinkOptions"
              is-expanded>
              <template #label="{ isDropdownOpen }">
                <bc-dropdown-trigger
                  :is-arrow="true"
                  :is-dropdown-open="isDropdownOpen"
                  :text="getTagTypeLinkName"
                  icon="tool"
                >
                </bc-dropdown-trigger>
              </template>
              <template #option="{ activeIndex, index, option }">
                <bc-dropdown-item
                  :is-focus="activeIndex === index"
                  :text="option.name"
                  :title="option.name"
                  class="hunt__dropdown-item"
                  @click.native="setTagTypeLink(option)">
                </bc-dropdown-item>
              </template>
            </bc-dropdown>
          </div>
          <panel-tag-autocomplete
            :selected="tags"
            :showLabel="false"
            :tagTypeSlug="tagTypeSlug"
            @select-tags="handleSelectTagToLink"
          ></panel-tag-autocomplete>
          <div class="button-action-right">
            <bc-button
              radius-size="s"
              size="extra-small"
              color="grey-5"
              @click="clearTagToLinks()">
              {{ $t('generics.reset') }}
            </bc-button>
            <bc-button
              radius-size="s"
              size="extra-small"
              color="primary"
              :disabled="!this.buttonIsActive"
              @click="createTagToLink()">
              {{ $t(`generics.${labelButtonAction}`) }}
            </bc-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PanelTagAutocomplete from '@/components/Panel/PanelTag/PanelTagAutocomplete';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
  import { getTagTypeLink } from '@/api/tags';
  import { mapState } from 'vuex';

  export default {
    name: 'panel-tag-link',
    components: {PanelTagAutocomplete, BcButton, BcDropdown, BcDropdownItem, BcDropdownTrigger},
    props: {
      tagToLinks: {
        type: Array,
        default: () => ([]),
      },
      tagTypeLink: {
        type: String,
        default: null,
      },
      labelButtonAction: {
        type: String,
        default: "create",
      },
      tagTypeSlug: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        buttonIsActive: false,
        tagTypeLinkOptions: [],
        tags: this.tagToLinks || [],
        typeLink: this.tagTypeLink || null,
      };
    },
    created() {
      this.getTagTypeLink();
    },
    methods: {
      async getTagTypeLink() {
        const { data } = await getTagTypeLink();

        this.tagTypeLinkOptions = data.map((typeLink) => ({
          name: typeLink.name,
          slug: typeLink.slug,
          value: typeLink._id,
        }));
      },
      async createTagToLink() {
        const tagTypeLink = this.tagTypeLinkOptions.find((item) => item.value === this.typeLink);

        this.$emit("create", this.tags, tagTypeLink);
      },
      handleSelectTagToLink(tags) {
        this.tags.push(...tags);

        const uniqueArray = this.tags.filter((obj, index, self) =>
          index === self.findIndex((t) => t._id.toString() === obj._id.toString())
        );

        this.tags = uniqueArray;

        if (this.typeLink !== null) {
          this.buttonIsActive = true;
        }

        this.$emit('update-tag-to-link', this.tags);
      },
      clearTagToLinks() {
        this.$emit("clear");
      },
      setTagTypeLink(item) {
        this.typeLink = item.value;

        if (this.tags.length > 0) {
          this.buttonIsActive = true;
        }

        this.$emit('update-tag-type-link', this.typeLink);
      },
    },
    computed: {
      ...mapState({
        tagTypes: state => state.tags.tagTypes,
      }),
      getTagTypeLinkName() {
        const tagTypeLink = this.tagTypeLinkOptions.find((item) => item.value === this.typeLink);
        return tagTypeLink ? tagTypeLink.name : '';
      },
      tagTypeId() {
        const tagType = this.tagTypes.find((item) => item.slug === this.tagTypeSlug);
        return tagType ? tagType._id : null;
      }
    },
    watch: {
      tagToLinks(newValue) {
        this.tags = newValue;
      },
      tagTypeLink(newValue) {
        this.typeLink = newValue;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .panel-tag-link {
    display: flex;
    width: 100%;
    // height: 100%;

    &__column {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &__column:first-child {
      border-right: 1px solid lightgrey;
    }

    &__column:last-child {
      border-left: none;
    }

    &__card {
      width: 100%;
      border-bottom: 1px solid lightgrey;
      display: flex;
      flex-direction: column;
    }

    &__card-label {
      width: 100%;
      padding: 20px 20px 0 20px;
      color: $color-secondary-dark;
    }

    &__card-content {
      width: 100%;
      padding: 20px 40px;
      display: flex;
      flex-direction: column;

      &-list {
        display: flex;
        flex-direction: column;
        padding: 0px 10px !important;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
      }

      &-list > div {
        margin: 5px 0px;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid lightgrey;
        border-radius: 5px;
      }
    }
    .button-action-right {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      & > * {
        margin: 0px 5px;
      }
    }

    &__card-content > div {
      width: 100%;
      padding: 10px 0px;
    }

    &__card-column {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__card-column > div:first-child {
      flex: 0 0 30%;
    }

    &__card-column > div:last-child {
      flex: 1;
    }
  }
</style>
