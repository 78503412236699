<template>
  <bc-modal
    :active="isModalOpen"
    @close="manageCloseFormDeal">
    <div class="modal-process-form-deal is-column is-relative">
      <div
        v-if="isSendingForm"
        class="modal-process-form-deal__loading is-absolute is-full-width is-align-items-center is-justify-content-center">
        <bc-spinner size="large"></bc-spinner>
      </div>
      <div class="modal-process-form-deal__content is-column is-relative">
        <div class="is-absolute icon-x-circle"
             @click.stop.once="manageCloseFormDeal">
        </div>
        <p class="modal-process-form-deal__text modal-process-form-deal__title">
          {{ $t('candidates.deal-form') }}
        </p>
        <p
          class="modal-process-form-deal__text modal-process-form-deal__text--secondary modal-process-form-deal__text--small">
          <span class="modal-process-form-deal__text--medium">
            {{ process._coder.firstName }} {{ process._coder.lastName }}
          </span>
          {{ $t('candidates.for') }} {{ jobName }} {{ $t('candidates.among') }} {{ process._company.name }}
        </p>
        <!-- component -->
        <ValidationObserver
          ref="observer"
          class="is-column"
          tag="form">
          <div class="modal-process-form-deal__body is-flex-wrap">
            <processes-deal-info
              :deal-info="dealInfo"
              :is-sent="isSent"
              @auto-save="updateEditedFormDealProperties($event, 'dealInfo')"
              @change-deal-info="updateFormDealProperties($event, 'dealInfo')">
            </processes-deal-info>
            <processes-billing
              :billing="billing"
              :is-error-bag-triggered="isErrorBagTriggered"
              :is-sent="isSent"
              @auto-save="updateEditedFormDealProperties($event, 'billing')"
              @change-billing="updateFormDealProperties($event, 'billing')">
            </processes-billing>
          </div>
          <!-- -->
          <processes-commission
            :coder-pass="coderPass"
            :is-error-bag-triggered="isErrorBagTriggered"
            :is-sent="isSent"
            :job-pass="jobPass"
            @auto-save="updateEditedFormDealProperties"
            @disable-send="disableSend"
            @change-coder-pass="updatePass($event, 'coderPass')"
            @change-job-pass="updatePass($event, 'jobPass')"
            @empty-coder-pass="emptyPass('coderPass')"
            @empty-job-pass="emptyPass('jobPass')">
          </processes-commission>
        </ValidationObserver>
        <div class="modal-process-form-deal__footer is-align-items-end is-column">
          <div class="is-column modal-process-form-deal__pre-footer-text is-align-items-end">
            <bc-checkbox
              id="correct-informations"
              :disabled="isSent"
              :value="isComplete"
              @input="setHasCorrectInfos">
              {{ $t('candidates.my-deal-information-is-verified-and-accurate') }}
            </bc-checkbox>
            <p
              v-if="correctInfosErrorMessage"
              class="modal-process-form-deal__text modal-process-form-deal__text--error">
              {{ correctInfosErrorMessage }}
            </p>
          </div>
          <div
            class="is-row is-align-items-center modal-process-form-deal__cta-container is-justify-content-space-between">
            <bc-button
              :disabled="isSent"
              class="modal-process-form-deal__cta modal-process-form-deal__cta--small"
              color="secondary"
              @click.native.stop="manageSaveFormDeal">
              {{ $t('candidates.save') }}
            </bc-button>
            <bc-button
              :disabled="!hasCorrectInfos"
              class="modal-process-form-deal__cta"
              color="tertiary"
              data-send-form-deal
              @click.native.stop="sendFormDeal">
              {{ $t('candidates.send-to-management') }}
            </bc-button>
          </div>
          <p class="modal-process-form-deal__footer-text">
            {{ $t('candidates.by-clicking-on-the-save-button') }}
          </p>
          <p class="modal-process-form-deal__footer-text modal-process-form-deal__footer-text--bold">
            {{ $t('candidates.if-second-candidate-smuggler') }}
          </p>
        </div>
      </div>
    </div>
    <bc-modal :active.sync="shouldOpenConfirmModal">
      <modal-process-confirm-form-deal
        @confirm-cancel="closeConfirmFormDeal"
        @send-form-deal="sendFormDeal">
      </modal-process-confirm-form-deal>
    </bc-modal>
  </bc-modal>
</template>

<script>
  import processesController from '@/managers/processes/controller';
  import processes from '@/api/processes';
  //
  import ToastMixin from '@/mixins/toast';
  //
  import ProcessesDealInfo from '@/components/Processes/ProcessesDealInfo';
  import ProcessesBilling from '@/components/Processes/ProcessesBilling';
  import ProcessesCommission from '@/components/Processes/ProcessesCommission';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import BcModal from '@/ui-kit/components/BcModal/BcModal';
  //
  import { ValidationObserver } from 'vee-validate';
  import ModalProcessConfirmFormDeal from '@/components/Modal/ModalProcess/ModalProcessConfirmFormDeal';
  import { computeDealAmount } from '@/helpers/processes';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';

  export default {
    name: 'modal-process-form-deal',
    components: {
      BcSpinner,
      ModalProcessConfirmFormDeal,
      BcModal,
      BcButton,
      BcCheckbox,
      ProcessesCommission,
      ProcessesBilling,
      ProcessesDealInfo,
      ValidationObserver,
    },
    props: {
      process: {
        type: Object,
        default: () => ({
          _coder: {},
          _company: {},
          _job: {},
          dealtAt: '',
          deal: {
            dealInfo: {
              startsAt: '',
              acceptedAt: '',
              partnerName: '',
              dealtAt: '',
              candidateFirstName: '',
              candidateLastName: '',
              fixed: null,
              variable: null,
            },
            billing: {
              percent: null,
              amount: null,
              partnerAddress: '',
              dateToSend: null,
              paymentDaysAfter: null,
              dealSource: null,
            },
            coderPass: {
              _coach: null,
              percent: null,
              type: null,
            },
            jobPass: {
              _coach: null,
              percent: null,
              type: null,
            },
          },
        }),
      },
      isModalOpen: {
        type: Boolean,
        default: false,
      },
    },
    mixins: [ToastMixin],
    data() {
      return {
        // Data structure based on back-end schema-app
        dealInfo: {
          startsAt: null,
          acceptedAt: null,
          partnerName: null,
          dealtAt: null,
          candidateFirstName: null,
          candidateLastName: null,
          fixed: null,
          variable: null,
        },
        billing: {
          percent: null,
          amount: null,
          dateToSend: null,
          paymentDaysAfter: 30,
          dealSource: null,
          partnerAddress: null,
        },
        coderPass: {},
        jobPass: {},
        missingDefaultFields: {},
        correctInfosErrorMessage: '',
        hasEditedFormDeal: false,
        hasCorrectInfos: false,
        shouldOpenConfirmModal: false,
        shouldCloseFormDeal: false,
        isComplete: false,
        isErrorBagTriggered: false,
        isSendingForm: false,
      };
    },
    created() {
      if (!this.onEditedMode) {
        this.setHasEditedFormDeal();
      }

      this.setMissingDefaultFields(this.process._coder, this.process.companyOffer, this.process._company);
      this.setDealInfo(this.process);
      this.setBilling(this.process.formDeal);
      this.setPasses(this.process.formDeal);
    },
    computed: {
      onEditedMode() {
        return this.$route.name.includes('Panel') &&
          this.$route.query['modal-type'] === 'modal-process-form-deal';
      },
      isSent() {
        return Boolean((this.process.formDeal || {}).sentAt || this.process.failedAt);
      },
      jobName() {
        return this.process._hunt ? this.process._hunt.poste : (this.process._job || {}).name;
      },
    },
    methods: {
      async saveFormDeal(options) {
        try {
          const { data } = await processesController.updateFormDeal(this.process._id, options);

          this.emitUpdatedProcess(data);
          this.hasEditedFormDeal = false;
          this.hasCorrectInfos = false;
          this.isComplete = false;
          this.correctInfosErrorMessage = '';

          if (this.shouldCloseFormDeal && !this.isComplete) {
            this.closeFormDeal();
          }
        } catch (e) {
          this.hasEditedFormDeal = false;
          this.$_displayErrorToast(e);
        }
      },
      async sendFormDeal() {
        try {
          if (!this.isSendingForm) {
            this.isSendingForm = true;

            const { data } = await processes.sendFormDeal(this.process._id);
            this.emitUpdatedProcess(data);
            this.$_displayConfirmToast(`${this.$i18n.t('candidates.form-deal-sent-to-gestion-following')}`);
            this.$emit('close-modal');
          }
        } catch (error) {
          this.isSendingForm = false;

          this.$_displayErrorToast(`${this.$i18n.t('candidates.error-submitting-deal-form')}`, error);
        }
      },
      async checkMissingFields() {
        this.isComplete = await this.$refs.observer.validate();
      },
      async setHasCorrectInfos(value, $event) {
        await this.checkMissingFields();

        if (!this.isComplete) {
          $event.preventDefault();
          this.hasCorrectInfos = false;
          this.isErrorBagTriggered = true;
        } else {
          this.hasCorrectInfos = !this.hasCorrectInfos;
          this.isErrorBagTriggered = false;
        }

        this.correctInfosErrorMessage = this.isComplete ? '' : this.$i18n.t('candidates.check-if-infos-are-correct');
      },
      dealAmount(coder = {}, companyOffer = {}) {
        if (companyOffer.fixed) {
          return computeDealAmount(companyOffer.fixed, companyOffer.variable);
        }

        if (coder.minimumPay) {
          return computeDealAmount(coder.minimumPay.yearly, companyOffer.variable);
        }

        return null;
      },
      computeFixedSalary(coder = {}, companyOffer = {}) {
        if (companyOffer.fixed) {
          return companyOffer.fixed;
        }

        if (coder.payWanted) {
          if (coder.payWanted.yearly) {
            return isNaN(coder.payWanted.yearly) ? coder.payWanted?.yearly?.minimum : this.dealInfo.fixed;
          }

          if (coder.payWanted.daily) {
            return isNaN(coder.payWanted.daily) ? coder.payWanted?.daily?.minimum : this.dealInfo.fixed;
          }

          return coder.payWanted.yearly ? coder.payWanted.yearly : coder.payWanted.daily;
        }

        if (coder.minimumPay) {
          return coder.minimumPay;
        }

        return null;
      },
      setMissingDefaultFields(coder = {}, companyOffer = {}, company = {}) {
        const fixedSalary = this.computeFixedSalary(coder, companyOffer);
        const amount = this.dealAmount(coder, companyOffer);
        const variable = (companyOffer || {}).variable;
        const fillDealInfo = () => {
          let dealInfoFilled = {};
          const fieldsToFill = [
            {
              name: 'candidateFirstName',
              value: (coder || {}).firstName,
            },
            {
              name: 'candidateLastName',
              value: (coder || {}).lastName,
            },
            {
              name: 'partnerName',
              value: (company || {}).name,
            },
            {
              name: 'fixed',
              value: fixedSalary,
            },
            {
              name: 'variable',
              value: variable,
            },
          ];

          for (const f of fieldsToFill) {
            const formDealHasProperty = ((this.process.formDeal || {}).dealInfo || {}).hasOwnProperty(f.name);

            if (!formDealHasProperty && f.value) {
              dealInfoFilled[f.name] = f.value;
            }
          }

          return dealInfoFilled;
        };
        const fillBilling = () => {
          if (this.process.formDeal?.billing && !this.process.formDeal?.billing.amount) {
            return {
              amount,
              paymentDaysAfter: 30,
            };
          }

          return {
            paymentDaysAfter: 30,
          };
        };
        const filledDealInfo = fillDealInfo();
        const filledBilling = fillBilling();
        const hasFilledFields = Object.keys(filledDealInfo).length > 0 || Object.keys(filledBilling) > 0;

        if (!this.process.formDeal) {
          this.missingDefaultFields = {
            dealtAt: this.process.dealtAt || new Date(),
            formDeal: {
              dealInfo: {
                candidateFirstName: (coder || {}).firstName,
                candidateLastName: (coder || {}).lastName,
                partnerName: (company || {}).name,
                ...fixedSalary && { fixed: fixedSalary },
                ...variable && { variable },
              },
              billing: {
                ...amount && { amount },
                paymentDaysAfter: 30,
              },
            },
          };

        } else {
          this.missingDefaultFields = {
            ...!this.process.dealtAt && { dealtAt: new Date() },
            ...hasFilledFields && {
              formDeal: {
                ...Object.keys(filledDealInfo).length > 0 && { dealInfo: filledDealInfo },
                ...Object.keys(filledBilling).length > 0 && { billing: filledBilling },
              },
            },
          };
        }
      },
      setDealInfo(process) {
        const { dealtAt, formDeal } = process;

        this.dealInfo = {
          ...this.dealInfo,
          ...this.missingDefaultFields.formDeal && { ...this.missingDefaultFields.formDeal.dealInfo },
          ...formDeal && formDeal.dealInfo && { ...formDeal.dealInfo },
          dealtAt: dealtAt || new Date(),
        };
      },
      setBilling(deal) {
        this.billing = {
          ...this.billing,
          ...this.missingDefaultFields.formDeal && { ...this.missingDefaultFields.formDeal.billing },
          ...deal && deal.billing && { ...deal.billing },
        };
      },
      setPasses(deal) {
        if (deal) {
          this.coderPass = deal.coderPass || {};
          this.jobPass = deal.jobPass || {};
        }
      },
      setHasEditedFormDeal() {
        this.hasEditedFormDeal = true;
      },
      updateFormDealProperties($event, property) {
        this.$set(this[property], $event.field, $event.value);
        this.setHasEditedFormDeal();
      },
      updateEditedFormDealProperties($event = {}, property = {}) {
        if (this.hasEditedFormDeal) {
          const baseOptions = $event.field === 'dealtAt' ? { dealtAt: $event.value } : { formDeal: { [property]: { [$event.field]: $event.value } } };
          const complexOptions = {
            ...this.missingDefaultFields.dealtAt && { dealtAt: this.missingDefaultFields.dealtAt },
            ...$event.field === 'dealtAt' && { dealtAt: $event.value },
            formDeal: {
              ...this.missingDefaultFields.formDeal,
              ...$event.field && {
                [property]: {
                  ...this.missingDefaultFields.formDeal && this.missingDefaultFields.formDeal[property],
                  [$event.field]: $event.value,
                },
              },
            },
          };

          const params = Object.keys(this.missingDefaultFields).length > 0 ? complexOptions : baseOptions;

          this.missingDefaultFields = {};
          this.saveFormDeal(params);
        }
      },
      updatePass($event, passName) {
        this.$set(this[passName], $event.field, $event.value);
        this.setHasEditedFormDeal();
      },
      emptyPass(passName) {
        this[passName] = {};
        this.setHasEditedFormDeal();
        this.saveFormDeal({ formDeal: { [passName]: {} } });
      },
      emitUpdatedProcess(data) {
        if (this.$route.name.includes('Panel')) {
          this.$emit('process-updated', data);
        } else {
          this.$emit('process-update', {
            updatedProcess: data,
            stepName: 'dealt',
          });
        }
      },
      closeFormDeal() {
        if (!this.isSent) {
          this.$_displayInfoToast(`${this.$i18n.t('candidates.form-deam-well-registered-to-process-section')}`);
        }
        this.$emit('close-modal');
      },
      manageCloseFormDeal() {
        if (this.hasEditedFormDeal && this.onEditedMode) {
          this.shouldCloseFormDeal = true;
        } else if (this.hasEditedFormDeal && !this.onEditedMode) {
          this.shouldCloseFormDeal = true;
          this.updateEditedFormDealProperties();
        } else {
          this.closeFormDeal();
        }
      },
      manageSaveFormDeal() {
        if (this.isComplete && this.hasCorrectInfos) {
          this.shouldOpenConfirmModal = true;
        } else {
          this.manageCloseFormDeal();
        }
      },
      closeConfirmFormDeal() {
        this.shouldOpenConfirmModal = false;
      },
      disableSend() {
        this.isComplete = false;
        this.hasCorrectInfos = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-process-form-deal {
    &__loading {
      height: 100%;
      z-index: 1;
      background: rgba($color-secondary, 0.5);
    }

    &__content {
      width: auto;
      height: 90vh;
      overflow: scroll;
      padding: 0 90px;
    }

    &__text {
      font-size: 20px;
      text-align: center;
      color: $color-primary;
      font-weight: $font-weight-regular;

      &--small {
        font-size: 18px;
      }

      &--extra-small {
        font-size: 14px;
      }

      &--medium {
        font-weight: $font-weight-medium;
      }

      &--secondary {
        color: $color-secondary;
      }

      &--error {
        @include font-regular(15px);
        margin-top: 5px;
        color: $color-error;
        letter-spacing: 0;
        line-height: 18px;
      }
    }

    &__title {
      margin: 30px 0 10px 0;
      @include font-medium();
    }

    &__body {
      margin-top: 23px;
      background-color: #F7F9FC;
      height: auto;
      padding-bottom: 20px;
    }

    &__label {
      @include font-regular(17px);
      line-height: 14px;
      text-align: left;
      color: $color-grey-4;
      margin-bottom: 10px;
      margin-left: 15px;

      &--required {
        color: $color-error;
      }
    }

    &__pre-footer-text {
      margin-bottom: 20px;
    }

    &__cta-container {
      width: 325px;
      margin-bottom: 10px;
    }

    &__cta {
      width: 175px;


      &--small {
        width: 135px;
      }
    }

    &__footer-text {
      @include font-regular(14px);
      line-height: 16px;
      color: $color-secondary-light;
      margin-bottom: 10px;

      &--bold {
        @include font-bold(14px);
      }
    }
  }

  .icon-x-circle {
    @include font-size(22px);
    top: 20px;
    right: 25px;
    color: $color-secondary;

    &:hover {
      cursor: pointer;
    }
  }
</style>
