<template>
  <router-link tag="span" :to="link" class="text-blue-500 cursor-pointer">
    {{ getFullName(props.person) }}
  </router-link>
</template>

<script setup lang="ts">
import { getFullName } from '@/managers/timeline/timelineMacros';
import { computed } from 'vue';
import { useRoute } from 'vue-router/composables';

interface Person {
  _id: string;
  firstName: string;
  lastName: string;
  pictureUrl?: string;
}

const props = defineProps<{
  person?: Person;
  type: 'coaches' | 'coders';
}>();

const route = useRoute();


function getLink(id: string, type: 'coaches' | 'coders') {
  return {
    ...route,
    params: {
      id,
    },
    query: {
      ...route.query,
      type,
    },
  };
}


const link = computed(() => props.person ? getLink(props.person._id, props.type) : null);

</script>
