import * as processesCustom from '@/api/processes-custom';

export function getProcessesCustom(options) {
  try {
    return processesCustom.getProcessesCustom(options);
  } catch (error) {
    if ((error || {}).response) {
      throw new Error(`API: ${error.response.data.message}`);
    }
    throw new Error(error);
  }
};
