export const bluecodersCompanyId = '59c248a304633204a79d7aa5';
export const bluecodersAcademyOrganizationId = '61834229c5317001439bc039';
export const marvinRecruiterOrganizationId = '61701ff8a2da0f003affb9c9';
export const shappersOrganizationId = '61e930633b767431126cc279';
export const plOrganizationId = '62591ad48e6f01ec08b6e4c9';
export const organizationExceptions = ['61fb99448fb11e8b1b9963ce'];

export default {
  bluecodersCompanyId,
  bluecodersAcademyOrganizationId,
  marvinRecruiterOrganizationId,
  shappersOrganizationId,
  organizationExceptions,
  plOrganizationId,
};
