<template>
  <div class="panel-process-header-buttons">
    <bc-button
      v-for="(item, index) in options"
      :key="index"
      :color="item.color"
      :icon-left="item.icon"
      :type="item.type"
      class="ml-2"
      size="extra-small"
      @click="item.action">
      <span v-if="item.name">
        {{ item.name }}
      </span>
    </bc-button>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  import { apiStatus } from '@/macros/processes/process';
  import { processController } from '@/managers/processes/controller';
  import ProcessMixin from '@/mixins/process';
  import MyOldProcessesMixin from '@/mixins/FavoriteLists/myOldProcesses';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';

  export default {
    name: 'panel-process-header-buttons',
    components: {
      BcButton,
    },
    props: {
      process: {
        type: Object,
        default: () => ({}),
      },
    },
    mixins: [ProcessMixin, MyOldProcessesMixin],
    i18n: {
      messages: {
        fr: {
          'set-to-failed': 'Mettre en échec',
        },
        en: {
          'set-to-failed': 'Set to failed'
        }
      }
    },
    data() {
      return {
        isOpenDropdown: false,
      };
    },
    computed: {
      isSuperUser() {
        return this.$store.getters['user/isSuperUser'];
      },
      options() {
        const options = [
          {
            name: this.$i18n.t('companies.panel-hunt-process.cancel-presentation'),
            icon: '',
            type: 'default',
            color: 'primary',
            visible: this.process.status === apiStatus.introduced,
            action: this.cancelIntroduceProcess,
          },
          {
            name: this.process.status < apiStatus.dealt ? this.$i18n.t('companies.panel-view-timeline.cancel-the-failure') : this.$i18n.t('companies.panel-view-timeline.cancel-the-drop'),
            icon: '',
            type: 'default',
            color: 'primary',
            visible: this.process.failedAt,
            action: this.unfailedProcess,
          },
          {
            name: this.$i18n.t('set-to-failed'),
            icon: '',
            type: 'outlined',
            color: 'error',
            visible: !this.process.abortedAt,
            action: this.abortProcess,
          },
          {
            name: this.$i18n.t('generics.cancel') + ' ' + this.$i18n.t('generics.failure'),
            icon: '',
            type: 'outlined',
            color: 'grey',
            visible: this.process.abortedAt,
            action: this.unabortProcess,
          },
          {
            name: '',
            icon: 'trash',
            type: 'default',
            color: 'error',
            visible: true,
            action: this.deleteProcess,
          },
        ];

        return options.filter(option => option.visible);
      },
      cardActive() {
        return this.$store.state.card.cards.findIndex(card => card._id === this.process._coder._id);
      },
    },
    methods: {
      ...mapActions([
        'updatePanelProfile',
      ]),
      async failedProcess() {
        const updatedProcess = await processController.move(this.process._id, 'failed');

        this.updatePanelProfile({
          processState: updatedProcess._coder.processState,
          coachProcessState: updatedProcess._coder.coachProcessState,
        });

        this.$emit('profile-updated', {
          processState: updatedProcess._coder.processState,
          coachProcessState: updatedProcess._coder.coachProcessState,
        });
        this.$emit('process-updated', { updatedProcess });
        this.$_manageCoderInMyOldProcessesList(updatedProcess);

        if (this.isSuperUser && this.process.status >= apiStatus.dealt) {
          window.open('https://docs.google.com/forms/d/e/1FAIpQLSck7JJ96JkDyubQCryJmyETQmCpHp1w8d9XGthf4VY7Brzs-Q/viewform', '_blank');
        }
      },
      async unfailedProcess() {
        const updatedProcess = await processController.move(this.process._id, 'unfailed');

        this.updatePanelProfile({
          processState: updatedProcess._coder.processState,
          coachProcessState: updatedProcess._coder.coachProcessState,
        });
        this.$emit('profile-updated', {
          processState: updatedProcess._coder.processState,
          coachProcessState: updatedProcess._coder.coachProcessState,
        });
        this.$emit('process-updated', { updatedProcess });
        this.$_manageCoderInMyOldProcessesList(updatedProcess);
      },
      async cancelIntroduceProcess() {
        try {
          const updatedProcess = await processController.move(this.process._id, 'pending');

          this.updatePanelProfile({
            processState: updatedProcess._coder.processState,
            coachProcessState: updatedProcess._coder.coachProcessState,
          });
          this.$emit('profile-updated', {
            processState: updatedProcess._coder.processState,
            coachProcessState: updatedProcess._coder.coachProcessState,
          });
          this.$emit('process-updated', { updatedProcess });
          this.$_manageCoderInMyOldProcessesList(updatedProcess);
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$i18n.t('companies.panel-view-timeline.error-during-status-changement'),
          });

          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      deleteProcess() {
        this.$emit('delete-process');
      },
      abortProcess() {
        this.$emit('abort-process');
      },
      unabortProcess() {
        this.$emit('unabort-process');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-process-header-buttons {

  }
</style>
