/*
    This helpers contains all functions to build quicklinks url
*/

import moment from 'moment/min/moment-with-locales'

export const getGoogleCalendarEventLink = ({
    i18n,
    routeFullPath,
    profileName,
    phone,
    email,
    type,
    organizationName,
}) => {

    const prefixes = {
        reminder: i18n.t('companies.panel-view-timeline.recall'),
        invitation: `${organizationName} :`,
    }
    const title = `${prefixes[type]} ${profileName}`;

    //this.$route.fullPath in component calling the function
    const route = routeFullPath.replace(/&/gi, '%26');

    const link = `${window.location.origin}${route}`;

    const startDate = moment().add(2, 'days').format('YYYYMMDD');

    return `https://calendar.google.com/calendar/r/eventedit?text=${
        title
    }${
        type == 'reminder'
        ? `&details=📞 ${phone ? phone : i18n.tc('generics.number', 0)}<br><br>🌐 <a href="${link}">${i18n.t('companies.panel-view-timeline.coach-link')}</a>`
        : ''
    }&dates=${
        startDate
    }T074500Z/${
        startDate
    }T081500Z${
        email ? `&add=${email}` : ''
    }`;
};

export const getMicrosoftCalendarEventLink = ({ 
  emailTo = '', 
  subject = '', 
  body = '', 
  startTime = '', 
  endTime = '' 
}) => {
  const params = new URLSearchParams({
    to: emailTo,
    subject: subject,
    body: body,
    startdt: startTime,
    enddt: endTime,
    path: '/calendar/action/compose',
    rru: 'addevent'  // This parameter helps handle the post-send redirect
  }).toString();

  return `https://outlook.office.com/calendar/action/compose?${params}`;
};
export const getGmailLink = ({
    emailTo,
}) => {
    return emailTo ? `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to=${emailTo}` : '';
    // return `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1
    // &to=[email du candidat]
    // &cc=
    // &bcc=
    // &su=[objet]
    // &body=[message]`;
};

export const getOutlookLink = ({ emailTo }) => {
  return emailTo ? `https://outlook.office.com/owa/?path=/mail/action/compose&to=${emailTo}` : '';
}

export const getWhatsappLink = ({
    phone
}) => {
    if (!phone) {
        return '';
    }

    let cleaned = ('' + phone).replace(/\D/g, '');
    if (phone.charAt(0) == '0') {
        cleaned = '33' + cleaned.substr(1, cleaned.length - 1);
    }

    if (/^\d+$/.test(cleaned) && cleaned.length == 11) {
        return `https://wa.me/${cleaned}`;
    }
    return '';
};

export default {
    getGmailLink,
    getGoogleCalendarEventLink,
    getMicrosoftCalendarEventLink,
    getWhatsappLink,
    getOutlookLink,
};
