<template>
  <views-tabs :tabs="tabs">
    <template #content>
      <slot></slot>
    </template>
  </views-tabs>
</template>

<script>
import { mapGetters } from 'vuex';
import ViewsTabs from '@/components/Views/ViewsTabs';

export default {
  name: 'candidates-tabs',
  components: {
    ViewsTabs,
  },
  computed: {
    ...mapGetters({
      ressourceCount: 'metadata/ressourceCount',
      coachRessourceCount: 'metadata/coachRessourceCount',
    }),
    tabs() {
      return [
        {
          key: 'coders',
          label: this.$i18n.tc('generics.candidate-cap', 2),
          to: { path: '/coders', query: { page: '1' } },
          count: this.ressourceCount.candidate,
          icon: 'users'
        },
        {
          key: 'contacts',
          label: this.$i18n.tc('generics.contact-cap', 2),
          to: '/contacts',
          count: this.ressourceCount.contact,
          icon: 'contact',
        },
      ];
    },
  },
}
</script>
