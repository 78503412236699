<template>
  <div class="database-jobs col-xs-12">
    <searchbar-jobs
      class="database-jobs__searchbar"
      jobs-searchbar
      v-if="hasTechnos">
    </searchbar-jobs>
    <router-view></router-view>
    <title-section
      :background-color="'white'"
      :color="'blue'"
      :title="`Annonces (${queryCount})`"
      class="database-jobs__title">
    </title-section>
    <bc-pagination
      :current.sync="currentPage"
      :per-page="12"
      :total="queryCount"
      class="database-jobs__pagination">
    </bc-pagination>
    <bc-spinner
      class="database-jobs__spinner"
      v-if="!isLoaded">
    </bc-spinner>
    <card-list
      :cards="cards"
      :is-loaded="true"
      class="pagination__card-list"
      ref="cardList"
      response-obj="jobs"
      v-else>
    </card-list>
    <bc-pagination
      :current.sync="currentPage"
      :per-page="12"
      :total="queryCount"
      class="database-jobs__pagination">
    </bc-pagination>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import TitleSection from '@/components/Title/TitleSection';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import BcPagination from '@/ui-kit/components/BcPagination';
  import CardList from '@/components/CardList/CardList';

  import locations_helpers from '@/common-old/utils/locations_helpers';
  import salary from '@/common-old/macros/salary';
  import SearchbarJobs from '@/components/Searchbar/SearchbarJobs/SearchbarJobs';

  export default {
    name: 'database-jobs',
    components: {
      SearchbarJobs,
      TitleSection,
      BcSpinner,
      BcPagination,
      CardList,
    },
    data() {
      return {
        isLoaded: true,
        currentPage: parseInt(this.$route.query.page) || 1,
      };
    },
    computed: {
      ...mapState({
        cards: state => state.card.cards,
        queryCount: state => state.card.count,
        globalMetadata: state => state.user.metadata,
        technos: state => state.tags.technos,
        profile: state => state.user.profile,
      }),
      salaryRange() {
        return {
          ...this.$route.query.contract && this.$route.query.contract === 'freelance' && {
            salaryMin: salary.salaryMinFilterFreelance,
            salaryMax: salary.salaryMaxFilterFreelance,
          },
          ...this.$route.query.contract && this.$route.query.contract === 'cdi' && {
            salaryMin: salary.salaryMin,
            salaryMax: salary.salaryMax,
          },
        };
      },
      parameters() {
        return {
          page: this.$route.query.page ? this.$route.query.page : 1,
          status: this.$route.query.status === 'all-status' ? undefined : this.$route.query.status,
          ...this.$route.query.technos && { technos: this.$route.query.technos },
          ...this.$route.query.search && { query: this.$route.query.search },
          ...this.$route.query.contracts && this.$route.query.contracts !== 'all' && { contract: this.$route.query.contracts },
          ...this.$route.query.experiences && { experiences: this.$route.query.experiences },
          ...this.$route.query.roles && { roles: this.$route.query.roles },
          ...this.$route.query.domains && { domains: this.$route.query.domains },
          ...this.$route.query.locations && { locations: JSON.stringify(locations_helpers.getLocationsId(this.$route.query.locations)) },
          ...this.$route.query.distance && { distance: this.$route.query.distance },
          ...this.$route.query.remotes && { remotes: this.$route.query.remotes },
          salaryMin: this.$route.query['salary-min'] && Number(this.$route.query['salary-min']) > this.salaryRange.salaryMin ? this.$route.query['salary-min'] : undefined,
          salaryMax: this.$route.query['salary-max'] && Number(this.$route.query['salary-max']) < this.salaryRange.salaryMax ? this.$route.query['salary-max'] : undefined,
        };
      },
      hasTechnos() {
        return this.technos.length;
      },
    },
    created() {
      this.setCount(1);
      this.getJobs();
    },
    methods: {
      ...mapActions([
        'resetCards',
        'getCards',
        'setCount',
      ]),
      async getJobs() {
        this.isLoaded = false;

        try {
          this.resetCards();
          await this.getCards({
            page: this.$route.query.page,
            call: 'jobs/lookup',
            parameters: this.parameters,
            responseKeyName: 'jobs',
          });
          this.isLoaded = true;
        } catch (error) {
          this.$emit('error', error);

          this.isLoading = true;
        }
      },
    },
    watch: {
      $route: {
        handler(to = {}, from = {}) {
          const isDifferentQueries = Object.keys(to.query).some(key => to.query[key] !== from.query[key]);
          const isOpeningPanel = Boolean(!(from.params || {}).id && (to.params || {}).id);
          const isClosingPanel = Boolean((from.params || {}).id && !(to.params || {}).id);
          const isInPanel = Boolean((from.params || {}).id && (to.params || {}).id);
          const pageChange = from.query.page !== to.query.page;

          if (isDifferentQueries && !isOpeningPanel && !isClosingPanel && (!isInPanel || (isInPanel && pageChange))) {
            this.getJobs();
            this.currentPage = parseInt(to.query.page) || 1;
          }
        },
        deep: true,
      },
      currentPage(newPage) {
        this.$router.push({ ...this.$route, query: { ...this.$route.query, page: newPage } });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .database-jobs {
    margin-top: 65px;
    padding: 0 15px;
    margin-bottom: 85px;
    @include page_grid();

    &__title {
      margin-bottom: 15px;
    }

    &__searchbar {
      margin-bottom: 20px;
    }

    &__pagination {
      margin: 20px 0;
    }

    &__spinner {
      margin: 100px 0;
    }

    &__card-list {
      margin-bottom: 100px;
    }

    &__spinner, &__pagination, &__card-list {
      justify-content: center;
    }
  }
</style>
