<template>
  <div
    class="home-statistics-kpi-container">
    <p
      class="home-statistics-kpi-container__title">
      {{ $t(performance.name) }}
    </p>
    <div
      class="home-statistics-kpi-container__data"
      v-if="!loading"
      v-tooltip="{ content: `${percentage}%` }">
      <span
        class="home-statistics-kpi-container__data-number">
        {{ (performance || {}).count }}/<span class="home-statistics-kpi-container__data-number--total">{{ (performance || {}).total }}</span>
      </span>
      <i
        :class="[`${performance.icon}`]"
        class="home-statistics-kpi-container__data-icon">
      </i>
    </div>
    <span
      class="home-statistics-kpi-container__loader"
      v-else>
    </span>
    <div
      class="home-statistics-kpi-container__wrapper"
      v-if="!loading">
      <div
        class="home-statistics-kpi-container__top-performer"
        v-if="(performance.topPerformers || []).length > 0">
        <p
          class="home-statistics-kpi-container__text">
          {{ $t('activities.top-performer') }}
        </p>
        <v-popover
          trigger="hover click">
          <bc-avatar
            :src="(performance.topPerformers[0] || {}).pictureUrl"
            class="home-statistics-kpi-container__avatar"
            size="s"
            v-if="!loading">
          </bc-avatar>
          <template
            slot="popover">
            <div class="is-column">
              <p
                :key="index"
                class="home-statistics-kpi-container__tooltip-text"
                v-for="(rank, index) in performance.topPerformers">
                {{ index + 1 }}.&nbsp;{{ rank.firstName }} {{ rank.lastName }}&nbsp;(<span class="home-statistics-kpi-container__tooltip-text--count">{{ rank.performance }}</span>)
              </p>
            </div>
          </template>
        </v-popover>
      </div>
      <div
        class="home-statistics-kpi-container__top-performer"
        v-else>
        <p class="home-statistics-kpi-container__text home-statistics-kpi-container__text--disable">
          {{ $t('activities.no-top-performer') }}
        </p>
      </div>
    </div>
    <span
      class="home-statistics-kpi-container__loader"
      v-else>
    </span>
  </div>
</template>
<script>
  import BcAvatar from '@/ui-kit/components/BcAvatar';

  export default {
    components: {
      BcAvatar,
    },
    name: 'home-statistics-kpi-container',
    computed: {
      isLoading() {
        return this.performance.loading;
      },
      percentage() {
        return Math.round(this.performance.count / this.performance.total * 100) || 0;
      },
    },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      performance: {
        type: Object,
        default: () => ({
          name: '',
          icon: '',
          count: 0,
          topPerformers: [{ coach: { pictureUrl: '', count: 0 } }],
        }),
      },
    },
    data() {
      return {
        isHoverPicture: false,
      };
    },
  };
</script>
<style lang="scss" scoped>
  .home-statistics-kpi-container {
    padding: 15px 10px 10px;
    flex-direction: column;
    justify-content: normal;
    align-items: center;
    border: 1px solid $color-grey-1;
    border-radius: $border-radius-s;
    transition: 0.3s;
    position: relative;

    &__loader {
      width: 80px;
      height: 10px;
      margin: 25px 0 10px;
      border-radius: $border-radius-l;
      animation-duration: 1.25s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: loadingAnimation;
      animation-timing-function: linear;
      background: linear-gradient(to right, $color-grey 8%, rgba($color-primary, 0.4) 18%, $color-grey 33%);
      background-size: 800px 104px;

      @keyframes loadingAnimation {
        0% {
          background-position: -468px 0
        }
        100% {
          background-position: 468px 0
        }
      }
    }

    &__picture {
      opacity: 0.75;
      transition: 0.3s;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }

    &__wrapper {
      border-top: 1px solid $color-grey-1;
      padding-top: 10px;
      justify-content: center;
      align-items: center;
    }

    &__title {
      font-size: $font-size-xs;
      color: $color-info-dark;
    }

    &__data {
      align-items: center;
      color: $color-primary;
      margin: 8px 0 10px;

      &-number {
        font-size: 28px;
        line-height: 37px;
        font-weight: $font-weight-bold;

        &--total {
          font-size: 16px;
        }
      }

      &-icon {
        font-size: 22px;
        height: 22px;
        margin-left: 15px;
      }
    }

    &__top-performer {
      align-items: center;
      height: 28px;
    }

    &__text {
      font-size: $font-size-xxs;
      color: $color-secondary-dark;
      margin-right: 10px;

      &--disable {
        opacity: 0.3;
        margin-right: 0;
      }
    }

    &__tooltip-text {
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &--count {
        display: initial;
      }
    }
  }
</style>
