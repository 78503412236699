<template>
  <div class="auth-magic-loader">
    <bc-spinner></bc-spinner>
  </div>
</template>

<script>
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import { setToken } from '@/managers/token/useCases/setToken';

export default {
  name: 'auth-magic-loader',
  components: { BcSpinner },
  computed: {
    profile() {
      return this.$store.state.user.profile;
    },
  },
  methods: {
    async setToken() {
      try {
        const { token } = this.$route.query;

        setToken(token);

        await this.$store.dispatch('user/getProfile');
        await this.$store.dispatch('getCoaches');
        await this.$store.dispatch('setCoachFavoriteLists', this.profile._id);

        // Set Crisp name and email address
        if (window.$crisp) {
          window.$crisp.push(['set', 'user:email', [this.profile.email]]);
          window.$crisp.push(['set', 'user:nickname', [`${this.profile.firstName} ${this.profile.lastName}`]]);
        }

        this.$router.push('/');
      } catch (error) {
        this.$toast.show({
          type: 'error',
          message: error?.response?.data?.message,
        });
      }
    },
  },
  mounted() {
    this.setToken();
  },
};
</script>

<style lang=scss scoped>
.auth-magic-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: rgba($color-blue-dark-cello, 0.8);
}
</style>
