import { apiType } from '@/macros/tags/types';

export const validateType = type => {
  const types = Object.values(apiType);

  if (types.some(tag => tag === type)) {
    return;
  }

  throw new Error(`Le type spécifié n\'existe pas : ${type}`);
};

export default {
  validateType,
};
