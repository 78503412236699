import { ref } from 'vue';

// Create a singleton instance that can be shared across components
const refreshCallback = ref<(() => Promise<void>) | null>(null);

export function useTimelineRefresh() {
  const setRefreshCallback = (callback: () => Promise<void>) => {
    refreshCallback.value = callback;
  };

  const refreshTimeline = async () => {
    if (refreshCallback.value) {
      await refreshCallback.value();
    }
  };

  return {
    setRefreshCallback,
    refreshTimeline,
  };
} 