<template>
  <td
    v-click-outside="setCompanyEditableToFalse"
    :class="
      !job.companyId
        ? `modal--company--cell${isEditable ? '--editable' : ''} : ''`
        : `${showModal ? 'from--modal' : ''}`
    "
    @click.stop="isEditable = true">
    <template v-if="checkCompany">
      <p v-if="!isEditable">
        <span v-if="inputValue">{{ inputValue }}</span>
        <span v-else class="cell-placeholder">{{ $t(placeholder) }}</span>
      </p>
      <bc-autocomplete
        v-else
        ref="companyDropdown"
        v-model="autocomplete"
        :is-scrollable="false"
        :options="options"
        class="company__dropdown is-full-width"
        keep-first
        name="input--company"
        @blur="displayCompany"
        @input="getSuggestions"
        @select="
          event => {
            emptySuggestions();
            $emit('company-selected', event);
          }
        ">
        <template #option="{ option }">
          <bc-dropdown-item
            :text="option.name"
            @click.native.stop="displayCompany(option)">
          </bc-dropdown-item>
        </template>
      </bc-autocomplete>
    </template>
    <template v-else>
      <div>
        <img :src="job.companyId.logoUrl" />
        <p>
          {{ job.companyId.name }}
        </p>
      </div>
    </template>
  </td>
</template>

<script>
import debounce from 'debounce';
import { searchPartners } from '@/api/confidentiality';
import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';

export default {
  name: 'companycell',

  components: { BcDropdownItem, BcAutocomplete },

  props: {
    job: {
      type: Object,
    },
    columnType: {
      type: String,
      required: true,
    },
    showModal: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    }
  },

  data() {
    return {
      companyId: '',
      isEditable: false,
      inputValue: '',
      isLoading: false,
      isNoResults: false,
      options: [],
      autocomplete: '',
    };
  },

  computed: {
    checkCompany() {
      if (!this.job.companyId) return true;
      return false;
    },
  },

  methods: {
    dataInput() {
      this.isEditable = false;
    },

    getSuggestions: debounce(async function(value) {
      if (value) {
        this.setIsLoading(true);
        try {
          const { data } = await searchPartners(value);

          this.options = data;

          this.isNoResults = !data?.length;
        } catch (error) {
          this.isNoResults = true;

          this.options = [];
        }
        this.setIsLoading(false);
      }
    }, 500),

    setIsLoading(value) {
      this.isLoading = value;
    },

    emptySuggestions() {
      this.autocomplete = '';

      this.options = [];
    },

    displayCompany(company) {
      if (this.autocomplete === '') {
        this.isEditable = false;
        this.inputValue = '';
        return;
      }

      this.companyId = company._id;
      this.inputValue = company.name;
      this.isEditable = false;
      this.$emit('update-cell-data', {
        columnName: this.columnType.toLowerCase(),
        data: this.companyId,
      });
    },

    setCompanyEditableToFalse() {
      this.isEditable = false;
    },

    setFocusOnDropdown: debounce(async function() {
      this.$refs?.companyDropdown?.$refs?.input?.$refs?.input?.focus();
    }, 100),
  },
  watch: {
    isEditable: {
      handler(newValue) {
        if (newValue === true) {
          this.setFocusOnDropdown()
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
td {
  vertical-align: inherit;
  width: 12%;
  border: none;
  padding-left: 10px;

  div {
    align-items: center;

    img {
      width: 15%;
      height: auto;
      object-fit: cover;
    }

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

td.modal--company--cell--editable {
  width: 12%;
  max-width: 8vw;
  max-height: 4vh;
  height: 6%;
  padding: 0;
}

.bc-autocomplete {
  height: 4vh;

  & :deep() {
    .bc-input {
      .bc-input__wrapper {
        input {
          padding: 20px 0;
        }
      }
    }
  }
}

.modal--company--cell {
  width: 12%;
  padding: 0;
}

@media (min-width: 1440px) and (max-width: 1660px) {
  td {
    width: 20%;
  }
  .from--modal {
    width: 14%;
  }
}
</style>
