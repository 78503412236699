<template>
  <div class="searchbar-filters-salary">
    <p class="searchbar-filters-salary__title">
      {{ $t('generics.salary-cap') }}
    </p>
    <div class="searchbar-filters-salary__wrapper">
      <p
        class="searchbar-filters-salary__label is-justify-content-center is-full-width is-text-align-center is-primary font-size-m">
        {{ $t('panel-coder-edit.salary-in') }} {{ unitContract }}
      </p>
      <bc-slider
        :key="contractsTag.query"
        v-model="salaryRange"
        :max="salaryMaxFilter"
        :min="salaryMinFilter"
        class="searchbar-filters-salary__slider"
        type="range">
      </bc-slider>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { buildSalaryTag } from '@/helpers/searchbar/factories';
  import salary from '@/common-old/macros/salary';
  import BcSlider from '@/ui-kit/components/BcSlider/BcSlider';
  import debounce from 'debounce';

  export default {
    name: 'searchbar-filters-salary',
    components: { BcSlider },
    i18n: {
      messages: {
        en: {
          'salary-per-day': '€/day',
        },
        fr: {
          'salary-per-day': '€/jour',
        },
      },
    },
    data() {
      return {
        salaryRange: [
          this.$route?.query?.['salary-min'] ? Number(this.$route?.query?.['salary-min']) : this.salaryMinFilter,
          this.$route?.query?.['salary-max'] ? Number(this.$route?.query?.['salary-max']) : this.salaryMaxFilter,
        ],
      };
    },
    computed: {
      ...mapState({
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags,
      }),
      // Current position of the tag salary
      tagIndex() {
        return this.searchbarFiltersTags.findIndex(tag => tag.type === 'salary');
      },
      //
      salaryMinFilter() {
        return this.contractsTag?.query === 'freelance' ? salary.salaryMinFilterFreelance : salary.salaryMin;
      },
      salaryMaxFilter() {
        return this.contractsTag?.query === 'freelance' ? salary.salaryMaxFilterFreelance : salary.salaryMax;
      },
      contractsTag() {
        return this.searchbarFiltersTags.find(tag => tag.type === 'contracts') || {};
      },
      unitContract() {
        return this.contractsTag?.query === 'freelance' ? this.$t('salary-per-day') : ' k€';
      },
    },
    beforeDestroy() {
      if (this.tagIndex >= 0) {
        this.$emit('on-reset', this.tagIndex);
      }
    },
    created() {
      this.setSalaryRange();
    },
    watch: {
      'contractsTag.query'() {
        if (this.tagIndex >= 0) {
          this.$emit('on-reset', this.tagIndex);
        }

        this.setSalaryRange();
      },
      salaryRange(to = [], from = []) {
        if (to[0] !== from[0] || to[1] !== from[1]) {
          this.setupSalaryQueries();
        }
      },
    },
    methods: {
      setSalaryRange() {
        this.salaryRange = [
          this.$route.query['salary-min'] ? Number(this.$route.query['salary-min']) : this.salaryMinFilter,
          this.$route.query['salary-max'] ? Number(this.$route.query['salary-max']) : this.salaryMaxFilter,
        ];
      },
      setupSalaryQueries: debounce(function() {
        if (this.salaryRange[0] !== this.salaryMinFilter || this.salaryRange[1] !== this.salaryMaxFilter) {
          this.$emit('on-select', buildSalaryTag({
            unit: this.unitContract,
            range: this.salaryRange,
          }));
        }

        if (this.tagIndex >= 0 && this.salaryRange[0] === this.salaryMinFilter && this.salaryRange[1] === this.salaryMaxFilter) {
          this.$emit('on-reset', this.tagIndex);
        }
      }, 500),
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-filters-salary {
    flex-direction: column;
    @include bp('tablet') {
      flex-direction: row;
    }

    &__title {
      margin-bottom: 20px;
      font-size: $font-size-s;
      color: $color-blue-dark-cello;
      line-height: 18px;
      justify-content: center;
      @include bp('tablet') {
        justify-content: flex-start;
        text-align: left;
        width: 85px;
        margin-bottom: 0;
        max-width: 85px;
        margin-right: 35px;
      }
    }

    &__wrapper {
      position: relative;
      flex-direction: column;
      @include bp('tablet') {
        min-width: 305px;
        width: 100%;
        margin-top: -5px;
      }
    }

    &__slider {
      width: 100%;
      @include bp('tablet') {
        margin-right: 65px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    &__label {
      margin-bottom: -5px;
    }
  }
</style>
