<script lang="ts" setup>
  import { defineEmits, defineProps, ref, watch, withDefaults } from 'vue';
  import linkedinImg from '@/assets/logo/linkedin.png';
  import fallbackImg from '@/assets/img/default-avatar.svg';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { Company } from '@/domains/models/Company';
  import { parsePhoneNumberFromString } from 'libphonenumber-js';
  import Tag from '@/components/Tag/Tag.vue';
  import BcAvatar from '@/ui-kit/components/BcAvatar';
  import { Employee } from '@/domains/models/Employee';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete.vue';
  import debounce from 'debounce';
  import { codersAutocomplete } from '@/api/coders';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { createEmployee, editEmployee } from '@/api/employees';
  import { useRoute, useRouter } from 'vue-router/composables';


  interface Props {
    company?: Company;
    employees?: Employee[];
    isCreating?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    isCreating: false,
  });
  const emits = defineEmits<{
    (e: 'close-edit', value: boolean): void,
    (e: 'update-employees', value: Employee[]): void
  }>();
  const toast = useToast();
  const router = useRouter();
  const route = useRoute();

  const selectedEmployees = ref<Employee[]>(props.employees || []);

  const removeEmployee = (employee: Employee) => {
    selectedEmployees.value = selectedEmployees.value.filter(e => e._coder?._id !== employee._coder?._id);
  };

  const closeEdit = () => {
    emits('close-edit', false);
  };

  const saveEmployees = async() => {
    try {
      const employeesToCreate = [];
      const employeesToEmit = [];
      const employeesToDelete = props?.employees?.filter(employee => selectedEmployees.value.every(e => e._id !== employee._id));

      for (const e of selectedEmployees.value) {
        if (!selectedEmployees.value.some(se => se._coder?._id === e._coder?._id)) {
          employeesToCreate.push({ coderId: e._coder?._id });
        } else {
          employeesToEmit.push(e);
        }
      }

      for (const e of selectedEmployees.value) {
        if (!e._id) {
          employeesToCreate.push({ coderId: e._coder?._id, companyId: props?.company?._id });
        }
      }

      for (const e of employeesToCreate) {
        await createEmployee({
          coderId: e.coderId || e._coder?._id,
          ...(e.companyId && { companyId: e.companyId }),
          createContact: true,
        });
      }

      for (const e of employeesToDelete || []) {
        await editEmployee({
          id: e._id,
          data: {
            active: false,
          },
        });
      }

      emits('update-employees', employeesToEmit.filter(e => e._company));
      closeEdit();
    } catch (err) {
      toast.show({
        type: 'error',
        message: err.message,
      });
    }
  };

  const setAlternativeImg = (event: any) => {
    event.target.src = fallbackImg;
  };

  const contactInput = ref('');
  const suggestions = ref([]);
  const isAutocompleteLoading = ref(false);

  const getSuggestions = debounce(async function() {
    isAutocompleteLoading.value = true;
    try {
      const { data } = await codersAutocomplete(contactInput.value);

      suggestions.value = data;

    } catch (err) {
      toast.show({
        type: 'error',
        message: err.message,
      });
    }
    isAutocompleteLoading.value = false;
  }, 300);
  const selectEmployee = employee => {
    selectedEmployees.value.push({ _coder: employee, _company: props.company._id });
  };
  const emptySuggestions = () => {
    suggestions.value = [];
  };

  const onCreateContact = () => {
    router.push({
      name: `${route.name}`,
      params: {
        id: 'new-contact',
      },
      query: {
        from: 'contact-autocomplete',
        contactInput: contactInput.value,
        companyId: route.params.id,
        type: 'create-contact',
      },
    });
  };

  watch(() => selectedEmployees.value, () => {
    if (props.isCreating) {
      emits('update-employees', selectedEmployees.value);
    }
  }, { deep: true });
</script>

<template>
  <div class="relative flex w-full flex-col rounded-md bg-neutral-200 px-5 py-4 text-sm">
    <div class="mt-2.5 flex flex-col">
      <BcAutocomplete
        v-model="contactInput"
        :limit="5"
        :loading="isAutocompleteLoading"
        :options="suggestions"
        :placeholder="$t('placeholder.write-contact-name')"
        class="panel-company-contacts__autocomplete"
        icon="user"
        label="Contact"
        name="location"
        @input="getSuggestions"
        @select="selectEmployee"
        @on-dropdown-close="emptySuggestions">
        <template #option="{ option }">
          <bc-dropdown-item
            :text="`${option.firstName} ${option.lastName} - ${option.title}`"
            class="font-size-s is-expanded">
          </bc-dropdown-item>
        </template>
        <template #footer>
          <button
            v-if="contactInput.length >= 2"
            class="panel-company-contacts__item is-full-width font-size-m is-secondary is-justify-content-center mx-auto py-3"
            @click="onCreateContact"
          >
            <span class="icon-candidates-plus mr-2"></span>
            {{ $t('process.create-the-contact', { contact: contactInput }) }}
          </button>
        </template>
      </BcAutocomplete>
      <div
        v-for="employee in selectedEmployees"
        :key="employee._id"
        class="relative flex flex-col border-b border-blue-200 py-2.5 last-of-type:border-0">
        <button
          class="icon-cross absolute inset-y-0 right-1 flex items-center p-2 text-blue-400 hover:text-blue-500"
          @click="removeEmployee(employee)">
        </button>
        <div class="flex flex-row items-center">
          <BcAvatar
            :src="employee._coder?.pictureUrl"
            class="size-[30px]"
            @error="setAlternativeImg"/>
          <div class="ml-2 flex flex-col text-blue-800">
            <p class="text-md font-semibold">{{ employee._coder?.firstName }}
              {{ employee._coder?.lastName }}</p>
            <div class="flex items-center gap-2">
              <p
                v-if="employee._coder?.title"
                v-tooltip.bottom="employee._coder?.title"
                class="text-md max-w-[200px] truncate">{{ employee._coder?.title }}</p>
              <a
                :class="{ 'grayscale': !employee._coder?.social?.linkedin }"
                :href="employee._coder?.social?.linkedin || '#'"
                class="text-sm"
                target="_blank">
                <img
                  :src="linkedinImg"
                  alt="linkedin"
                  class="h-5"/>
              </a>
            </div>
          </div>
        </div>
        <div class="mt-2 flex gap-2 flex-wrap">
          <Tag
            v-if="employee._coder?.phone"
            :text="parsePhoneNumberFromString(employee._coder?.phone, 'FR')?.formatInternational() || employee._coder?.phone"
            type="phone"/>
          <Tag
            v-if="employee._coder?.email"
            :text="employee._coder?.email"
            type="email"/>
          <Tag
            v-for="phone in employee._coder?.phones"
            :key="`${phone}-${Math.random()}`"
            :text="phone"
            type="email"/>
          <Tag
            v-for="email in employee._coder?.emails"
            :key="`${email}-${Math.random()}`"
            :text="email"
            type="email"/>
        </div>
      </div>
    </div>
    <div
      v-if="!isCreating"
      class="ml-auto mt-2.5 flex flex-row gap-2.5">
      <BcButton
        :label="$t('global.cancel')"
        icon-left="cross"
        size="small"
        type="outlined"
        @click="closeEdit">
        {{ $t('global.cancel') }}
      </BcButton>
      <BcButton
        icon-left="check"
        native-type="submit"
        size="small"
        @click="saveEmployees">
        {{ $t('global.save') }}
      </BcButton>
    </div>
  </div>
</template>

<style scoped></style>
