<template>
  <div
    @click.stop
    class="panel-coach-profile"
    v-click-outside="manageClosePanel"
  >
    <panel-coach-header :profile="profile" class="panel-coach-profile__header">
    </panel-coach-header>
    <panel-coach-body :profile="profile"></panel-coach-body>
    <panel-categories
      :categories="categories"
      class="panel-coach-profile__categories"
    >
    </panel-categories>
    <panel-view class="panel-coach-profile__timeline is-full-width">
      <panel-view-timeline user-type="coach"></panel-view-timeline>
    </panel-view>
  </div>
</template>

<script>
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';

  import PanelCoachHeader from '@/components/Panel/PanelCoach/PanelCoachHeader';
  import PanelCoachBody from '@/components/Panel/PanelCoach/PanelCoachBody';
  import PanelCategories from '@/components/Panel/PanelCategoriesOldStyle';
  import PanelView from '@/components/Panel/PanelView';
  import { mapState } from 'vuex';
  // http
  import { getCoachProfile } from '@/api/coaches';
  import PanelViewTimeline from '@/components/Panel/PanelView/PanelViewTimeline';

  export default {
    name: 'panel-coach-profile',
    components: {
      PanelViewTimeline,
      PanelView,
      PanelCategories,
      PanelCoachBody,
      PanelCoachHeader,
    },
    mixins: [PanelNavigation],
    data() {
      return {
        categories: [
          {
            name: this.$i18n.t('activities.history-of-my-actions-cap'),
            url: 'my-notes',
          },
        ],
        profile: {},
      };
    },
    computed: {
      ...mapState({
        timeline: state => state.panel.viewList,
      }),
    },
    methods: {
      async getProfile() {
        if (this.$route.params.id === 'profile') {
          await this.$store.dispatch('user/getProfile');

          this.profile = this.$store.state.user.profile;
        } else {
          const { data } = await getCoachProfile(this.$route.params.id);

          this.profile = data;
        }
      },
    },
    watch: {
      $route: {
        handler() {
          this.getProfile();
        },
        immediate: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-coach-profile {
    height: 100%;
    flex-direction: column;

    &__header {
      order: -2;
    }

    &__categories {
      order: -1;
      @include bp('tablet') {
        order: 0;
      }
    }

    &__timeline {
      padding: 0 30px;
    }
  }
</style>
