<template>
  <div class="panel-process-create-selected">
    <slot></slot>
    <button v-if="showCloseBtn" class="close-btn" @click.stop="$emit('on-close', $event)">
      <span class="icon-cross"></span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'panel-process-create-selected',
  props: {
    showCloseBtn: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-process-create-selected {
  width: 100%;
  padding: 8px;
  background: $color-blue-light;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 15px;
  color: $color-blue-dark-cello;
}

.close-btn {
  position: absolute;
  right: 3%;
  & > span {
    color: $color-blue-heavy-dark;
  }
}
</style>
