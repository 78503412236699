<template>
  <div class="is-column">
    <div class="is-align-items-center is-full-width">
      <bc-autocomplete
        ref="companies-autocomplete-input"
        v-model="newCompany"
        :filter-options="filterOptions"
        :force-close-dropdown="closeDropdown"
        :is-scrollable="false"
        :label="showLabel ? $t('generics.companies') : undefined"
        :limit="8"
        :options="options"
        :placeholder="$t('generics.find-profession')"
        :required="required"
        :selected-filter="selectedFilter"
        class="companies-autocomplete is-full-width"
        icon="award"
        is-dropdown-full-width
        keep-first
        name="autocomplete"
        v-bind="$attrs"
        @dropdown="onDropdown"
        @focus="onFocus"
        @input="getSuggestions"
        @select="selectCompany"
        @on-dropdown-close="emptySuggestions"
        @on-select-filter="onSelectFilter"
        @click.stop.native="onFocus($event, 'click')">
        <template #option="{ option }">
          <bc-dropdown-item
            v-if="!showCreateIndustry"
            :is-selected="isCompanySelected(option._id)"
            :text="option.name">
          </bc-dropdown-item>
        </template>
        <template #footer>
          <button
            v-if="forConfiguration || canCreate"
            :disabled="!isCreateEnabled"
            :style="{ 'opacity': isCreateEnabled ? '1' : '0.3' }"
            class="is-full-width font-size-m is-secondary is-justify-content-center mx-auto py-3"
            type="button"
            @click.prevent.stop="createNewCompany()">
            <span class="icon-plus mr-2"></span>
            {{ $t('generics.create-the-profession', { profession: newCompany }) }}
          </button>
        </template>
      </bc-autocomplete>
    </div>
    <div v-if="companiesSelected.length" class="companies-autocomplete__tags mt-2.5">
      <tag
        v-for="profession in companiesSelected"
        :key="profession._id"
        :can-close="true"
        :text="profession.name"
        type="companies"
        @click="selectCompany(profession)">
      </tag>
    </div>
  </div>
</template>

<script>
  import debounce from 'debounce';
  import SearchbarCoderDispatcher from '@/helpers/searchbar/dispatcher';
  import searchbar from '@/common-old/mixins/Searchbar/searchbar';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import Tag from '@/components/Tag/Tag';
  import { mapState } from 'vuex';
  import { createPartner, partnersAutocomplete } from '@/api/company';
  import { hasExactMatch } from '@/helpers/autocomplete';

  export default {
    name: 'companies-autocomplete',
    mixins: [SearchbarCoderDispatcher, searchbar],
    components: {
      BcAutocomplete,
      BcDropdownItem,
      Tag,
    },
    props: {
      forConfiguration: {
        type: Boolean,
        default: false,
      },
      canCreate: {
        type: Boolean,
        default: false,
      },
      profile: {
        type: Object,
        default: () => ({}),
      },
      selected: {
        type: Array,
        default: () => [],
      },
      showLabel: {
        type: Boolean,
        default: true,
      },
      filterOptions: {
        type: Array,
        default: () => [],
      },
      selectedFilter: {
        type: Object,
        default: () => ({}),
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      if (this.profile?._companies?.length) {
        this.companiesSelected = this.profile._companies;
      } else if (this.selected) {
        this.companiesSelected = this.selected;
      } else {
        this.companiesSelected = [];
      }
    },
    data() {
      return {
        newCompany: '',
        options: [],
        companiesSelected: [],
        showCreateIndustry: false,
        isDropdownOpen: false,
        closeDropdown: false,
      };
    },
    computed: {
      ...mapState({
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags,
      }),
      companiesQueries() {
        return this.$route.query?.companies || '';
      },
      isCreateEnabled() {
        return !hasExactMatch(this.newCompany, this.options.map(option => option.name));
      },
    },
    methods: {
      async createNewCompany() {
        this.closeDropdown = false;

        try {
          let { data: profession } = await createPartner({ name: this.newCompany });
          this.selectCompany(profession);
          this.closeDropdown = true;

          let successMessage = this.$t('toast.profession-successfully-created', { profession: profession.name });

          this.$toast.show({
            type: 'success',
            title: this.$t('toast.congratulations'),
            message: successMessage,
          });
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err.message,
          });
        }
      },
      onSelectFilter(option) {
        this.$emit('select-option', option);
      }, selectCompany(profession) {
        // Clean old selected companies
        if (this.isCompanySelected(profession._id)) {
          this.companiesSelected = this.companiesSelected.filter(p => p._id !== profession._id);
          if (this.forConfiguration) {
            this.$emit('update-value', {
              _companies: this.companiesSelected,
            });
          }
        } else {
          this.companiesSelected.push(profession);
          if (this.forConfiguration) {
            this.$emit('update-value', {
              _companies: this.companiesSelected,
            });
          }
        }
        this.$emit('select', this.companiesSelected);
        this.newCompany = '';
      },
      isCompanySelected(id) {
        return this.companiesSelected.findIndex(profession => profession._id === id) !== -1;
      },
      getSuggestions: debounce(async function() {
        try {
          const { data } = await partnersAutocomplete(this.newCompany);
          const companiesSelectedIds = this.companiesSelected.map(profession => profession._id);
          this.options = data.filter((suggestionCompany) => !companiesSelectedIds.includes(suggestionCompany._id));
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err.message,
          });
        }
      }, 300),
      emptySuggestions() {
        this.options = [];
        this.showCreateIndustry = false;
      },
      onFocus(_, from) {
        if (from === 'click' && !this.showCreateIndustry) {
          setTimeout(() => {
            this.$refs['companies-autocomplete-input'].$refs['input'].$refs['input'].focus();
          }, 0);
        }
      },
      onDropdown(isOpen) {
        this.isDropdownOpen = isOpen;
      },
    },
    watch: {
      companiesSelected(newValue) {
        this.$emit('select-companies', newValue);
      },
      selected(newValue) {
        this.companiesSelected = newValue;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .companies-autocomplete {
    &__create_industry {
      width: 100%;
      flex-direction: column;
      position: absolute;
      background: white;
      border-radius: 5px;

      &-header {
        padding: 15px;
        border-bottom: 1px solid gray;
        width: 100%;

        & > p {
          width: 100%;
          text-align: center;
        }
      }

      &-content {
        flex-direction: column;
        padding: 15px;
      }
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    :deep() .bc-input__wrapper {
      display: flex;
      flex-wrap: wrap;
      min-height: 41px;
    }

    :deep() .bc-input__label--focus {
      top: -20px;
      left: 5px;
      transform: none;
    }
  }
</style>
