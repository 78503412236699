const separators = [',', '/'];

function removeAccent(text) {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

export function getSeparator(text) {
  return separators.find(separator => text.includes(separator));
}

export function hasExactMatch(search = '', list = []) {
  return list.some(text => {
    const textTab = text
      .split(getSeparator(text))
      .map(text => removeAccent(text.replace('-', '').trim().toLowerCase()));
    return textTab.includes(removeAccent(search.toLowerCase()));
  })
}
