import { useI18n } from '@/i18n/i18n';
import { computed, ref } from 'vue';
import {
  CampaignNextCondition,
  CampaignNextConditionKey,
} from '@/components/Campaigns/CampaignsTypes';


export const useCampaignsNextConditions = ({ conditions }: {
  conditions: CampaignNextConditionKey[]
}) => {
  const { t } = useI18n();

  const availableConditions = computed<CampaignNextCondition[]>(() => [
    {
      key: 'INVITATION_ACCEPTED',
      name: t('campaigns.conditions.accepted-invitation'),
    },
    // {
    //   key: 'MESSAGE_RECEIVED',
    //   name: t('campaigns.conditions.linkedin-answer'),
    // },
    {
      key: 'MAIL_ANSWER',
      name: t('campaigns.conditions.mail-answer'),
    },
    {
      key: 'PROFILE_VISITED',
      name: t('campaigns.conditions.profile-visited'),
    },
    {
      key: 'PROCESSED_CANDIDATE',
      name: t('campaigns.conditions.processed-candidate'),
    },
    {
      key: 'DELAY',
      name: t('campaigns.conditions.delay'),
    },
    {
      key: 'IMMEDIATELY_AFTER_STEP',
      name: t('campaigns.conditions.immediately-after-step'),
    },
  ]);

  const selectedConditionKey = ref<CampaignNextConditionKey | null>(null);

  function setSelectedConditionKey(condition: CampaignNextConditionKey) {
    selectedConditionKey.value = condition;
  }

  const conditionsOptions = computed<CampaignNextCondition[]>(() => availableConditions.value.filter((condition) => conditions.includes(condition.key)));
  const selectedCondition = computed<CampaignNextCondition>(() => conditionsOptions.value.find((condition) => condition.key === selectedConditionKey.value) || conditionsOptions.value[0]);

  return {
    selectedCondition,
    setSelectedConditionKey,
    conditionsOptions,
    availableConditions,
  };
};
