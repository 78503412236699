<template>
  <div class="industries-modal-edit-domains is-column">
    <div class="is-align-items-center is-full-width">
      <bc-autocomplete
        v-model="input"
        :keep-first="true"
        :options="matches"
        class="industries-modal-edit-domains__autocomplete is-full-width"
        icon="tool"
        label="Domaines"
        name="domains"
        placeholder="Créer un nouveau domaine"
        @keydown.native.enter="addDomain">
        <template #option="{ option }">
          <bc-dropdown-item
            :text="option.name"
            class="industries-modal-edit-domains__option font-size-s is-expanded">
          </bc-dropdown-item>
        </template>
      </bc-autocomplete>
    </div>
    <div class="industries-modal-edit-domains__wrapper is-flex-wrap">
      <bc-tag
        v-for="(domain, index) in domains"
        :key="index"
        class="industries-modal-edit-domains__tag"
        deletable
        @click="removeDomain(domain)">
        {{ domain.name }}
      </bc-tag>
    </div>
  </div>
</template>

<script>
  import BcTag from '@/ui-kit/components/BcTag/BcTag';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';

  export default {
    name: 'industries-modal-edit-domains',
    components: { BcDropdownItem, BcAutocomplete, BcTag },
    props: {
      domains: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        input: '',
      };
    },
    computed: {
      matches() {
        if (!this.input) {
          return this.domains;
        }

        return this.domains.filter(domain => domain.name.toLowerCase().indexOf(this.input.toLowerCase()) === 0);
      },
    },
    methods: {
      addDomain() {
        this.$emit('update-value', {
          domains: [{ name: this.input }, ...this.domains],
        });

        this.input = '';
      },
      removeDomain(domain) {
        this.$emit('update-value', {
          domains: this.domains.filter(l => l.name.toLowerCase() !== domain.name.toLowerCase()),
        });

        this.$emit('domain-deleted', domain)
      },
    },
  };
</script>

<style lang="scss" scoped>
  .industries-modal-edit-domains {
    &__autocomplete {
      margin-bottom: 10px;
    }

    &__option {
      line-height: 15px;
      padding: 10px 20px;
    }

    &__wrapper {
      margin: -5px;
    }

    &__tag {
      margin: 5px;
    }
  }
</style>
