<script lang="ts" setup>
  import { Company } from '@/domains/models/Company';
  import { computed, defineEmits, ref } from 'vue';
  import MvAccordion from '@/ui-kit/components/MvAccordion/MvAccordion.vue';
  import MvAccordionTab from '@/ui-kit/components/MvAccordionTab/MvAccordionTab.vue';
  import { useStore } from '@/store';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { Process } from '@/domains/models/Process';
  import { useMutation } from '@tanstack/vue-query';
  import { useI18n } from '@/i18n/i18n';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { usePanelNavigation } from '@/composables/usePanelNavigation';
  import PanelCompanyTopEdit
    from '@/components/Panel/PanelCompany/PanelCompanyTop/PanelCompanyTopEdit.vue';
  import PanelCompanyContactsEdit
    from '@/components/Panel/PanelCompany/PanelCompanyContacts/PanelCompanyContactsEdit.vue';
  import PanelCompanyDetailsEdit
    from '@/components/Panel/PanelCompany/PanelCompanyDetails/PanelCompanyDetailsEdit.vue';
  import PanelCompanyDocuments from '@/components/Panel/PanelCompany/PanelCompanyDocuments.vue';
  import companyApi from '@/api/company';
  import contractsController from '@/managers/contracts/controller';
  import { apiTypes } from '@/macros/contracts/types';
  import { createEmployee } from '@/api/employees';
  import PanelCommonDescriptionEdit
    from '@/components/Panel/PanelCommon/PanelCommonDescription/PanelCommonDescriptionEdit.vue';
  import PanelCompanyModalDeduplication
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalDeduplication.vue';
  import { usePanelStore } from '@/store/pinia/panel';

  const emits = defineEmits<{
    (e: 'edit-company', value: boolean): void,
    (e: 'update-company', value: Company): void,
    (e: 'update-process', value: Process): void
  }>();

  const route = useRoute();
  const { closePanel } = usePanelNavigation();
  const i18n = useI18n();
  const toast = useToast();
  const store = useStore();
  const router = useRouter();
  const panelStore = usePanelStore();

  const editedProfile = ref<Company>({});
  const setEditedProfile = (data) => {
    editedProfile.value = {
      ...editedProfile.value,
      ...data,
    };
  };

  const observer = ref(null);
  const activeTabs = ref<number[]>([]);

  const serializeCompany = (company) => {
    const {
      name,
      email,
      phone,
      locations,
      description,
      logoUrl,
      social,
      size,
    } = company;

    return {
      ...name && { name },
      ...email && { email },
      ...logoUrl && { logoUrl },
      ...locations && { locations: locations.map(location => location.placeId || location) },
      ...phone !== undefined && { phone },
      ...social && { social },
      ...description && { description },
      ...size && { size },
    };
  };

  const getCompanyDedupe = useMutation({
    mutationKey: ['get-company-dedupe', editedProfile.value],
    mutationFn: async() => {
      const { data } = await companyApi.getCompaniesDeduplication({
        ...editedProfile.value.name && { name: editedProfile.value.name },
        ...editedProfile.value.social?.linkedin && { linkedin: editedProfile.value.social.linkedin },
        ...editedProfile.value.social?.website && { website: editedProfile.value.social.website },
        ...(editedProfile.value.email || editedProfile.value.emails?.length) && { emails: [...editedProfile.value.email ? [editedProfile.value.email] : [], ...editedProfile.value.emails?.length ? editedProfile.value.emails : []] },
        ...(editedProfile.value.phone || editedProfile.value.phones?.length) && { phones: [...editedProfile.value.phone ? [editedProfile.value.phone] : [], ...editedProfile.value.phones?.length ? editedProfile.value.phones : []] },
      });

      if (data.length) {
        panelStore.openModal<{
          companies: Company[],
          createCompanyCallback: () => void
          createNewProfileCallback: () => void
          draftCompany: Company
        }>(PanelCompanyModalDeduplication, {
          companies: data,
          createNewProfileCallback: createCompanyMutation.mutate,
          createCompanyCallback: createCompany,
          draftCompany: editedProfile.value,
        });
      } else {
        createCompanyMutation.mutate();
      }
    },
  });

  async function createCompany() {
    const formattedCompany = serializeCompany(editedProfile.value);
    const { data } = await companyApi.createPartner(formattedCompany);

    if (contracts.value?.length) {
      await Promise.all(contracts.value.map(async(document) => {
        await contractsController.createCompanyContract({
          id: data._id,
          type: apiTypes.contract,
          file: document.file,
        });
      }));
    }

    if (employees.value.length) {
      await Promise.all(employees.value.map(async(employee) => {
        await createEmployee({
          companyId: data._id,
          coderId: employee._coder._id,
          createContact: true,
        });
      }));
    }

    return data;
  }

  const createCompanyMutation = useMutation({
    mutationKey: ['create-company', editedProfile.value],
    mutationFn: () => createCompany(),
    onSuccess: async(data) => {
      if (route?.name?.includes('Partners')) {
        store.dispatch('unshiftCard', data);
      }

      await store.dispatch('user/updateMetadataByKey', {
        key: 'partners',
        value: store.state.user.metadata.partners + 1,
      });

      if (store.state.user.onboarding.partner) {
        toast.show({
          type: 'success',
          message: i18n.t('toast.company-successfully-created'),
        });
      } else {
        // Update onboarding
        await store.dispatch('user/updateOnboarding', { key: 'partner', value: true });
      }

      redirectToCompanyPanel(data._id);
    },
    onError: (e) => {
      console.error(e);
      toast.show({
        type: 'error',
        message: e.message,
      });
    },
  });

  const uploadDocument = ref<HTMLInputElement | null>(null);
  const contracts = ref<Document[]>([]);
  const contractsCount = computed(() => {
    return contracts?.value?.length || 0;
  });
  const triggerUploadButton = () => {
    uploadDocument.value?.click();
  };
  const addContract = async(e) => {
    if (e.target.files[0]) {
      const url = URL.createObjectURL(e.target.files[0]);

      contracts.value = [
        { url, file: e.target.files[0] },
        ...contracts.value || [],
      ];
      // const { data } = await documentsController.createCoderDocument({
      //   id: route.params.id,
      //   file: e.target.files[0],
      // });
      //
      // emits('update-candidate', { _documents: data._documents });
    }
  };

  const employees = ref([]);
  const redirectToCompanyPanel = (id) => {
    router.push({
      name: route?.name.includes('Panel') ? route?.name : `${route?.name}Panel`,
      params: {
        id,
      },
      query: {
        ...route?.query,
        type: 'companies',
      },
    });
  };
</script>

<template>
  <div class="flex max-h-full min-h-full flex-col overflow-auto bg-white pb-5">
    <div
      class="flex flex-col gap-5 px-5 pt-3.5"
    >
      <PanelCompanyTopEdit
        :company="editedProfile"
        :is-creating="true"
        @create-company="getCompanyDedupe.mutate"
        @edit-company="closePanel"
        @update-company="setEditedProfile"/>
    </div>
    <MvAccordion
      :active-index.sync="activeTabs"
      class="flex flex-col px-5"
      multiple
    >
      <MvAccordionTab>
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('panel-coder-edit.contact-information') }}
            </b>
          </div>
        </template>
        <PanelCompanyContactsEdit
          :company="editedProfile"
          :is-creating="true"
          @update-company="setEditedProfile"
        />
      </MvAccordionTab>
      <MvAccordionTab>
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('generics.details') }}
            </b>
          </div>
        </template>
        <PanelCompanyDetailsEdit
          :company="editedProfile"
          :is-creating="true"
          @update-company="setEditedProfile"
        />
      </MvAccordionTab>
      <MvAccordionTab
        :class="{ 'panel-company-profile__documents': contractsCount > 0 }">
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              Documents
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white"
            >
              {{ contractsCount }}
            </div>
            <div
              class="absolute right-0 flex gap-2.5 bg-white pl-2.5"
              @click.stop
            >
              <button
                class="flex size-[30px] items-center justify-center rounded-full border-2 border-blue-400 bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="triggerUploadButton"
              >
                <i class="icon-plus font-xs"/>
              </button>
              <input
                ref="uploadDocument"
                accept=".doc,.docx,application/pdf"
                class="hidden"
                name="document"
                type="file"
                @change="addContract"
              />
            </div>
          </div>
        </template>
        <PanelCompanyDocuments
          :documents="contracts"
          @update-company="$emit('update-company', $event)"
        />
      </MvAccordionTab>
      <MvAccordionTab>
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              Description
            </b>
          </div>
        </template>
        <PanelCommonDescriptionEdit
          :is-creating="true"
          :text="editedProfile.description"
          :title="$t('companies.panel-company.company-description')"
          @update-text="setEditedProfile({ description: $event })"
        />
      </MvAccordionTab>
    </MvAccordion>
  </div>
</template>

<style lang="scss" scoped>
  .panel-company-profile__documents > .p-toggleable-content {
    min-height: 640px;
  }
</style>
