<script setup lang="ts">
import { ref, computed, watch } from 'vue';

import BcAutocomplete from '@/ui-kit/components/BcAutocomplete';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import FilterContent from '@/components/Filter/FilterContent';
import Tag from '@/components/Tag/Tag';
import { useRoute, useRouter } from 'vue-router/composables';

const emits = defineEmits<{
  (e: 'count', count: { key: string; count: number }): void,
}>();

const route = useRoute();
const router = useRouter();

const search = ref('');
const suggestions = ref([]);

const selected = computed(() => {
  if (!route.query.searchInput) {
    return [];
  }
  return route.query.searchInput.split(',')
});

const getSuggestions = (value: any) => {
  suggestions.value = [{ value }];
};

const onSelect = (option: any) => {
  search.value = '';
  router.push({
    query: {
      ...route.query,
      page: 1,
      searchInput: [...selected.value, option.value].join(',')
    },
  });
};

const onPressTag = (tag) => {
  router.push({
    query: {
      ...route.query,
      page: 1,
      searchInput: [...selected.value.filter((value) => value !== tag)].join(',') || undefined
    },
  });
};

watch(() => selected.value, (value) => {
  emits('count', value?.length ? { key: 'searchInput', count: 1 } : { key: 'searchInput', count: 0 });
});

</script>

<template>
  <filter-content
    class="filter-customjobs-name__content"
    :label="$t('global.title')"
    icon="users">
    <div class="is-align-items-center is-full-width">
      <bc-autocomplete
        :options="suggestions"
        :is-dropdown-full-width="false"
        @input="getSuggestions"
        class="filter-customjobs-name__input is-expanded mt--3"
        name="filter-customjobs-name"
        :placeholder="$t('table-content.job-placeholder')"
        v-model="search">
        <template #option="{ option }">
          <bc-dropdown-item
            :text="option.value"
            :is-selected="false"
            @click.native.stop="onSelect(option)"
            class="filter-customjobs-name__option font-size-s is-expanded">
          </bc-dropdown-item>
        </template>
      </bc-autocomplete>
    </div>
    <div v-if="selected && selected.length" class="filter-customjobs-name__tags mt-2 flex gap-2">
      <tag
        v-for="selectedValue in selected"
        :key="selectedValue"
        :text="selectedValue"
        @click.native.stop="onPressTag(selectedValue)"
        can-close />
    </div>
  </filter-content>
</template>
