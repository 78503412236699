import { http } from '@/api/index';

export const getNotifications = (id, page = 1) => {
  return http.get(`/coaches/${id}/notifications`, { params: { page } });
};

export const markAsRead = (notifId) => {
  return http.put(`/notifications/${notifId}/read`);
};
export const markAllAsRead = () => {
  return http.put(`/notifications/read`);
};

export const sendSlackNotification = ({ title, body }) => {
  return http.post('/notifications/slack/features', {
    title,
    body,
  })
}

export default {
  getNotifications,
  markAsRead,
  markAllAsRead
}
