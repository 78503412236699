<template>
  <div class="panel-organization-configuration">
    <div class="panel-organization-configuration__container">
      <BcButton @click="syncAlgolia">Synchroniser Algolia</BcButton>
      <BcButton @click="generateAlgoliaCredentials">Générer les identifiants Algolia</BcButton>
      <BcButton @click="extractDocuments">Extraire les documents</BcButton>
      <panel-organization-configuration-features :profile="profile"></panel-organization-configuration-features>
      <panel-organization-configuration-parameters
        :profile="profile"
        :features="features"
      ></panel-organization-configuration-parameters>
      <panel-organization-configuration-schedules :profile="profile"></panel-organization-configuration-schedules>
      <panel-organization-configuration-customfields
        :profile="profile"></panel-organization-configuration-customfields>
    </div>
    <div class="panel-organization-configuration__container">
      <panel-organization-configuration-multicast
        :profile="profile"
        @profile-updated="$emit('profile-updated', $event)"
      ></panel-organization-configuration-multicast>
      <panel-organization-configuration-xtramile
        :profile="profile"
        @profile-updated="$emit('profile-updated', $event)"
      ></panel-organization-configuration-xtramile>
    </div>
  </div>
</template>

<script>
import { updateConfigurationOrganizationByKey, mapFeatureNames } from '@/api/organizations';
import PanelOrganizationConfigurationFeatures from './PanelOrganizationConfigurationFeatures';
import PanelOrganizationConfigurationParameters from './PanelOrganizationConfigurationParameters';
import PanelOrganizationConfigurationSchedules from './PanelOrganizationConfigurationSchedules';
import PanelOrganizationConfigurationCustomfields from './PanelOrganizationConfigurationCustomfields';
import PanelOrganizationConfigurationMulticast from './PanelOrganizationConfigurationMulticast';
import PanelOrganizationConfigurationXtramile from './PanelOrganizationConfigurationXtramile';
import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
import { syncAlgolia as syncAlgoliaApi, generateAlgoliaCredentials as generateAlgoliaCredentialsApi, extractDocuments as extractDocumentsApi } from '@/api/organizations';

export default {
  name: 'panel-organization-configuration',
  components: {
    PanelOrganizationConfigurationFeatures,
    PanelOrganizationConfigurationParameters,
    PanelOrganizationConfigurationSchedules,
    PanelOrganizationConfigurationMulticast,
    PanelOrganizationConfigurationXtramile,
    PanelOrganizationConfigurationCustomfields,
    BcButton,
  },
  props: {
    profile: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      features: {},
      process: {
        showExport: true,
        hasGoogleWidgets: false,
        hasAmount: false,
        allCategoriesActive: true,
        averageFee: 0,
      },
      framework: {
        showCompanies: false,
        takeNews: false,
      },
      db: {
        candidatesLimit: 200,
        companiesLimit: 200,
        jobsLimit: 20,
      },
      schedules: {},
      paid: false,
      mapFeatureNames,
      featuresWithoutPayments: ['extensionSkillsCrawl'],
      trialEndsAt: '',
    };
  },
  methods: {
    async syncAlgolia() {
      try {
        await syncAlgoliaApi(this.profile._id);
        this.$toast.show({
          type: 'info',
          message: 'Synchronisation en cours',
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async generateAlgoliaCredentials() {
      try {
        await generateAlgoliaCredentialsApi(this.profile._id);
        this.$toast.show({
          type: 'info',
          message: 'Génération des identifiants en cours',
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async extractDocuments() {
      try {
        await extractDocumentsApi(this.profile._id);
        this.$toast.show({
          type: 'info',
          message: 'Extraction des documents en cours',
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async updateFeaturesConfig(features) {
      try {
        await updateConfigurationOrganizationByKey({
          key: 'features',
          organizationId: this.profile._id,
          ...features,
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async updateConfig(key, data) {
      try {
        await updateConfigurationOrganizationByKey({
          key,
          organizationId: this.profile._id,
          ...data,
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async updateProcessConfig(processConfig) {
      try {
        await updateConfigurationOrganizationByKey({
          key: 'process',
          organizationId: this.profile._id,
          ...processConfig,
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async resetFeatures() {
      const features = Object.keys(this.features).reduce((acc, value) => {
        return { ...acc, [value]: false };
      }, {});
      this.features = features;
    },
  },
  watch: {
    features: {
      handler(newValue) {
        this.updateFeaturesConfig(newValue);
      },
      deep: true,
    },
    process: {
      handler(newValue) {
        this.updateProcessConfig(newValue);
      },
      deep: true,
    },
    framework: {
      handler(newValue) {
        this.updateFrameworkConfig(newValue);
      },
      deep: true,
    },
    db: {
      handler(newValue) {
        this.updateConfig('db', newValue);
      },
      deep: true,
    },
    schedules: {
      handler(newValue) {
        this.updateConfig('schedules', newValue);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-organization-configuration {
  width: 100%;
  padding: 40px;
  gap: 38px;

  &__container {
    flex-direction: column;
    width: 50%;
    gap: 20px;
  }

  &__title {
    font-weight: bold;
    margin: 10px 0px;
  }

  &__content {
    padding: 20px;
    flex-direction: column;
  }

  &__item {
    margin: 10px 0px;
    flex-direction: row;
    justify-content: space-between;

    &--container {
      flex-direction: column;
    }

    &>div {
      margin-right: 50px;
    }
  }

  .warning {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    margin-bottom: 15px;
    color: $color-blue-dark-cello;
  }

  .config-label {
    margin: 10px 0;
    gap: 10px;
    align-items: center;
  }
}
</style>
