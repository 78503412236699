<script lang="ts" setup>
  import { Company } from '@/domains/models/Company';
  import { computed, defineEmits, defineProps, ref, watch, withDefaults } from 'vue';
  import PanelCoderEditLocation from '@/components/Panel/PanelCoder/PanelCoderEdit/PanelCoderEditLocation.vue';
  import { useI18n } from '@/i18n/i18n';
  import { ValidationObserver } from 'vee-validate';
  import { useStore } from '@/store';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { useToast } from '@/ui-kit/components/BcToast';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger.vue';
  import BcCustomField from '@/ui-kit/components/BcCustomField/BcCustomField.vue';
  import { sizeOptions } from '@/macros/companies/sizes';
  import companyApi from '@/api/company';

  interface Props {
    company: Company;
    isCreating?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    isCreating: false,
  });
  const emit = defineEmits<{
    (e: 'close-edit'): void,
    (e: 'update-company', value: Company): void
  }>();
  const { t } = useI18n();
  const store = useStore();
  const toast = useToast();

  const organization = store.state.user?.profile?._organization;
  const customFields = organization?.configuration?.company?.customFields?.filter(customField => customField.activated) || [];

  const closeEdit = () => {
    emit('close-edit');
  };

  const editedProfile = ref({
    locations: props.company.locations,
    size: props.company.size,
  });
  const setEditedProfile = (data) => {
    editedProfile.value = {
      ...editedProfile.value,
      ...data,
    };
  };
  const handleSelectedSize = (size) => {
    editedProfile.value.size = size;
  };

  const purgedProfile = computed(() => ({
    locations: editedProfile.value.locations?.map(location => location.placeId || location.place_id),
    size: editedProfile.value.size || 0,
    customFields: customFieldsValues.value || {},
  }));
  const saveCompany = async() => {
    const { data } = await companyApi.updatePartner({
      id: props.company._id,
      obj: purgedProfile.value,
    });
    emit('update-company', data);
    closeEdit();
  };

  const customFieldsValues = ref(props.company?.customFields || {});
  const setCustomFields = (data) => {
    customFieldsValues.value = {
      ...customFieldsValues.value,
      [data.key]: data.value,
    };
  };

  watch(() => purgedProfile.value, (value) => {
    if (props.isCreating) {
      emit('update-company', purgedProfile.value);
    }
  }, { deep: true });
</script>

<template>
  <div class="relative flex w-full flex-row items-start gap-8 rounded-md bg-neutral-200 px-5 py-4">
    <ValidationObserver
      class="flex w-full flex-col gap-y-4"
      tag="form"
      @submit.prevent>
      <PanelCoderEditLocation
        :locations="editedProfile.locations"
        class="w-full"
        @click.native.stop
        @update-value="setEditedProfile">
      </PanelCoderEditLocation>
      <BcDropdown
        :options="sizeOptions"
        class="panel-company-edit-infos__input panel-company-edit-infos__input--half"
        data-size
        is-expanded
        @change="handleSelectedSize">
        <template #label="{ isDropdownOpen }">
          <BcDropdownTrigger
            :is-arrow="true"
            :is-dropdown-open="isDropdownOpen"
            :is-label-placeholder="false"
            :label="$t('panel-company-edit.nb-employees')"
            :text="$t(editedProfile.size ? `panel-company-edit.size-options-${editedProfile.size}` : 'panel-company-edit.size-options-0')"
            class="panel-company-edit-infos__trigger"
            icon="user">
          </BcDropdownTrigger>
        </template>
        <template #option="{ option, index, activeIndex }">
          <BcDropdownItem
            :is-focus="activeIndex === index"
            :is-selected="editedProfile.size === option.name"
            :text="$t(option.name)"
            class="is-expanded"
            @change="handleSelectedSize"
            @click.native="handleSelectedSize(option.api)">
          </BcDropdownItem>
        </template>
      </BcDropdown>
      <div v-if="customFields.length" class="mt-2.5 flex flex-col">
        <BcCustomField
          v-for="customField in customFields"
          :key="customField.key"
          :custom-field="customField"
          :name="customField.key === 'emergency' ? $t('generics.priority') : undefined"
          :placeholder="customField.defaultValue?.toString()"
          :selected="customFieldsValues[customField.key]"
          :type="customField.type"
          class="my-4"
          @on-update="setCustomFields"/>
      </div>
      <div
        v-if="!isCreating"
        class="z-0 ml-auto flex flex-row gap-x-2.5">
        <BcButton
          icon-left="cross"
          size="small"
          type="outlined"
          @click="closeEdit">
          {{ $t('global.cancel') }}
        </BcButton>
        <BcButton
          color="primary"
          icon-left="check"
          native-type="submit"
          size="small"
          @click="saveCompany">
          {{ $t('global.save') }}
        </BcButton>
      </div>
    </ValidationObserver>
  </div>
</template>
