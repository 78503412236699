<script setup>
  import { computed } from 'vue';
  import ViewsTabs from '@/components/Views/ViewsTabs';
  import { useStore } from '@/store.js';
  import { useRoute } from 'vue-router/composables';
  import { useI18n } from '@/i18n/i18n.js';

  const store = useStore();
  const route = useRoute();
  const { t, tc } = useI18n();

  const profile = computed(() => store.state.user.profile);

  const tabs = computed(() => [
    {
      key: 'campaigns',
      label: tc('campaigns.campaigns', 1),
      to: {
        path: '/campaigns',
      },
      count: undefined,
      icon: 'kanban',
    },
  ]);
</script>

<template>
  <views-tabs :tabs="tabs">
    <template #content>
      <slot></slot>
    </template>
  </views-tabs>
</template>
