export const timeOptions = () => {
  const hours = Array.from({ length: 13 }, (x, index) => index + 8);
  const minutes = ['00', '15', '30', '45'];

  const timeOptions = hours.map(hour => minutes.map(minute => `${hour}:${minute}`));

  return timeOptions.flat(1);
};

export default {
  timeOptions,
};
