<template>
  <div class="reminders-filters-status is-align-items-center">
    <ButtonGroup :items="items" :active-key="currentFilter" @click="handleClick" />
  </div>
</template>

<script setup lang="ts">
import suggestionsMacros from '@/macros/suggestions';
import ButtonGroup from '@/components/Button/ButtonGroup.vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { computed, defineProps } from 'vue';
import { useI18n } from '@/i18n/i18n';

const props = defineProps<{
  metadata: {
    reminders: number;
    recommendations: number;
    inbox: number;
  };
}>();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const currentFilter = computed<string>(()=> route.query.filter.toString());
const items = computed(() => [
  {
    key: suggestionsMacros.filters.todo,
    label: t('topbar.to-do'),
    icon: 'inbox',
    count: props.metadata.reminders,
  },
  {
    key: suggestionsMacros.filters.postponed,
    label: t('topbar.planned'),
    icon: 'clock',
    count: props.metadata.recommendations,
  },
  {
    key: suggestionsMacros.filters.archived,
    label: t('generics.archives'),
    icon: 'download',
    count: props.metadata.inbox,
  },
]);

function handleClick(item: { key: string }) {
  router.push({
      query: {
        ...route.query,
        filter: item.key,
      },
    });
}
</script>
