import { http } from '@/api/index';

export const postMessage = id => {
  return http.post(`/pokes/${id}/messages`);
};

export const getMessages = ({ id, page }) => {
  return http.get(`/pokes/${id}/messages`, { params: { page } });
};

export default {
  postMessage,
  getMessages
};
