<template>
  <div class="is-column">
    <div class="is-align-items-center is-full-width">
      <bc-autocomplete
        ref="professions-autocomplete-input"
        v-model="newProfession"
        :filter-options="filterOptions"
        :force-close-dropdown="closeDropdown"
        :is-scrollable="false"
        :label="showLabel ? $t('generics.professions') : undefined"
        :limit="8"
        :options="options"
        :placeholder="$t('generics.find-profession')"
        :required="required"
        :selected-filter="selectedFilter"
        class="professions-autocomplete is-full-width"
        icon="award"
        is-dropdown-full-width
        keep-first
        name="autocomplete"
        v-bind="$attrs"
        @dropdown="onDropdown"
        @focus="onFocus"
        @input="getSuggestions"
        @select="selectProfession"
        @on-dropdown-close="emptySuggestions"
        @on-select-filter="onSelectFilter"
        @click.stop.native="onFocus($event, 'click')">
        <template #option="{ option }">
          <bc-dropdown-item
            v-if="!showCreateIndustry"
            :is-selected="isProfessionSelected(option._id)"
            :prefix="option.industryName"
            :text="option.name">
          </bc-dropdown-item>
        </template>
        <template #footer>
          <button
            v-if="forConfiguration || canCreate"
            :disabled="!isCreateEnabled"
            :style="{ 'opacity': isCreateEnabled ? '1' : '0.3' }"
            class="is-full-width font-size-m is-secondary is-justify-content-center mx-auto py-3"
            type="button"
            @click.prevent.stop="createNewProfession()">
            <span class="icon-plus mr-2"></span>
            {{ $t('generics.create-the-profession', { profession: newProfession }) }}
          </button>
        </template>
      </bc-autocomplete>
    </div>
    <div v-if="professionsSelected.length" class="professions-autocomplete__tags mt-2.5">
      <tag
        v-for="profession in professionsSelected"
        :key="profession._id"
        :can-close="true"
        :text="profession.name"
        type="professions"
        @click="selectProfession(profession)">
      </tag>
    </div>
  </div>
</template>

<script>
  import debounce from 'debounce';
  import SearchbarCoderDispatcher from '@/helpers/searchbar/dispatcher';
  import searchbar from '@/common-old/mixins/Searchbar/searchbar';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import Tag from '@/components/Tag/Tag';
  import { mapState } from 'vuex';
  import { createProfession, getProfessionsAutocomplete } from '@/api/professions';
  import { hasExactMatch } from '@/helpers/autocomplete';

  export default {
    name: 'professions-autocomplete',
    mixins: [SearchbarCoderDispatcher, searchbar],
    components: {
      BcAutocomplete,
      BcDropdownItem,
      Tag,
    },
    props: {
      forConfiguration: {
        type: Boolean,
        default: false,
      },
      canCreate: {
        type: Boolean,
        default: false,
      },
      profile: {
        type: Object,
        default: () => ({}),
      },
      selected: {
        type: Array,
        default: () => [],
      },
      showLabel: {
        type: Boolean,
        default: true,
      },
      filterOptions: {
        type: Array,
        default: () => [],
      },
      selectedFilter: {
        type: Object,
        default: () => ({}),
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      if (this.profile?._professions?.length) {
        this.professionsSelected = this.profile._professions;
      } else if (this.selected) {
        this.professionsSelected = this.selected;
      } else {
        this.professionsSelected = [];
      }
    },
    data() {
      return {
        newProfession: '',
        options: [],
        professionsSelected: [],
        showCreateIndustry: false,
        isDropdownOpen: false,
        closeDropdown: false,
      };
    },
    computed: {
      ...mapState({
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags,
      }),
      professionsQueries() {
        return this.$route.query?.professions || '';
      },
      isCreateEnabled() {
        return !hasExactMatch(this.newProfession, this.options.map(option => option.name));
      },
    },
    methods: {
      async createNewProfession() {
        this.closeDropdown = false;

        try {
          let { data: profession } = await createProfession({ name: this.newProfession });
          this.selectProfession(profession);
          this.closeDropdown = true;

          let successMessage = this.$t('toast.profession-successfully-created', { profession: profession.name });

          this.$toast.show({
            type: 'success',
            title: this.$t('toast.congratulations'),
            message: successMessage,
          });
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err.message,
          });
        }
      },
      onSelectFilter(option) {
        this.$emit('select-option', option);
      }, selectProfession(profession) {
        // Clean old selected professions
        if (this.isProfessionSelected(profession._id)) {
          this.professionsSelected = this.professionsSelected.filter(p => p._id !== profession._id);
          if (this.forConfiguration) {
            this.$emit('update-value', {
              _professions: this.professionsSelected,
            });
          }
        } else {
          this.professionsSelected.push(profession);
          if (this.forConfiguration) {
            this.$emit('update-value', {
              _professions: this.professionsSelected,
            });
          }
        }
        this.$emit('select', this.professionsSelected);
        this.newProfession = '';
      },
      isProfessionSelected(id) {
        return this.professionsSelected.findIndex(profession => profession._id === id) !== -1;
      },
      getSuggestions: debounce(async function() {
        try {
          const { data } = await getProfessionsAutocomplete({ name: this.newProfession });
          const professionsSelectedIds = this.professionsSelected.map(profession => profession._id);
          this.options = data.filter((suggestionProfession) => !professionsSelectedIds.includes(suggestionProfession._id));
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err.message,
          });
        }
      }, 300),
      emptySuggestions() {
        this.options = [];
        this.showCreateIndustry = false;
      },
      onFocus(_, from) {
        if (from === 'click' && !this.showCreateIndustry) {
          setTimeout(() => {
            this.$refs['professions-autocomplete-input'].$refs['input'].$refs['input'].focus();
          }, 0);
        }
      },
      onDropdown(isOpen) {
        this.isDropdownOpen = isOpen;
      },
    },
    watch: {
      professionsSelected(newValue) {
        this.$emit('select-professions', newValue);
      },
      selected(newValue) {
        this.professionsSelected = newValue;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .professions-autocomplete {
    &__create_industry {
      width: 100%;
      flex-direction: column;
      position: absolute;
      background: white;
      border-radius: 5px;

      &-header {
        padding: 15px;
        border-bottom: 1px solid gray;
        width: 100%;

        & > p {
          width: 100%;
          text-align: center;
        }
      }

      &-content {
        flex-direction: column;
        padding: 15px;
      }
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    :deep() .bc-input__wrapper {
      display: flex;
      flex-wrap: wrap;
      min-height: 41px;
    }

    :deep() .bc-input__label--focus {
      top: -20px;
      left: 5px;
      transform: none;
    }
  }
</style>
