<template>
  <div class="panel-process-details-company">
    <p class="panel-process-details-company__title icon-partners">
      L'entreprise
    </p>
    <div class="panel-process-details-company__container">
      <div class="panel-process-details-company__wrapper">
        <p class="panel-process-details-company__name">
          {{ company.name }}
        </p>
        <router-link
          :to="{
            ...$route,
            params: {
              id: company._id
            },
            query: {
              ...$route.query,
              type: 'companies'
            }
          }"
          class="panel-process-details-job-introduced__link">
          <bc-button
            :bc-background-color-opacity="0.8"
            bc-radius="5px"
            bc-text-size="14px"
            class="panel-process-details-company__button">
            Voir l'entreprise
          </bc-button>
        </router-link>
      </div>
      <bc-divider
        :bc-divider-color-opacity="0.3"
        bc-divider-color="blue-dodger"
        class="panel-process-details-company__divider"
        v-if="contacts.length > 0">
      </bc-divider>
      <div
        class="panel-process-details-company__contacts"
        v-if="contacts.length > 0">
        <panel-process-details-contact
          :email="contact.email"
          :key="contact._id"
          :name="`${contact.firstName} ${contact.lastName}`"
          :phone="contact.mobile"
          :title="contact.job"
          class="panel-process-details-company__contact"
          v-for="contact in contacts.filter((contact, index) => index < displayedContacts)">
        </panel-process-details-contact>
        <p
          @click.stop="increaseDisplayedContacts"
          class="panel-process-details-company__text icon-chevron-down"
          v-if="displayedContacts < contacts.length">
          <span class="panel-process-details-company__text--underline">{{ $t('companies.panel-view-timeline.see-more') }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import BcButton from '@/legacy/ui-kit/components/Button/BcButton';
  import BcDivider from '@/legacy/ui-kit/components/Dividers/BcDivider';
  import PanelProcessDetailsContact
    from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsContact';

  export default {
    name: 'panel-process-details-company',
    components: { PanelProcessDetailsContact, BcDivider, BcButton },
    props: {
      company: {
        type: Object,
        default: () => ({})
      },
      contacts: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      isCopiedPhone: false,
      isCopiedEmail: false,
      displayedContacts: 2
    }),
    computed: {
      phoneTooltip() {
        return {
          content: this.isCopiedPhone ? this.$t('generics.copied') : this.company.phone,
          hideOnTargetClick: false
        };
      },
      emailTooltip() {
        return {
          content: this.isCopiedEmail ? this.$t('generics.copied') : this.company.email,
          hideOnTargetClick: false
        };
      }
    },
    methods: {
      setIsCopiedPhone() {
        this.isCopiedPhone = true;
        setTimeout(() => {
          this.isCopiedPhone = false;
        }, 2300);
      },
      setIsCopiedEmail() {
        this.isCopiedEmail = true;
        setTimeout(() => {
          this.isCopiedEmail = false;
        }, 2300);
      },
      increaseDisplayedContacts() {
        this.displayedContacts = this.displayedContacts + 2;
      }
    }
  };
</script>

<style lang=scss scoped>
  .panel-process-details-company {
    flex-direction: column;

    &__title {
      display: flex;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      line-height: 17px;
      color: rgba($color-blue-dodger, 0.8);
      text-transform: uppercase;
      align-items: center;
      margin: 0 0 10px 20px;

      &:before {
        font-size: $font-size-l;
        margin-right: 10px;
      }
    }

    &__container {
      padding: 15px 20px;
      background: $color-white;
      flex-direction: column;
      border-radius: 10px;
    }

    &__wrapper {
      justify-content: space-between;
      align-items: center;
    }

    &__divider {
      margin: 15px 0;
    }

    &__contacts {
      flex-direction: column;
      margin: -7.5px 0;
      align-items: center;
    }

    &__contact {
      margin: 7.5px 0;
      width: 100%;
    }

    &__name {
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      color: rgba($color-blue-dark-cello, 0.8);
      margin-right: 10px;
    }

    &__text {
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: $font-size-xs;
      color: rgba($color-blue-dodger, 0.8);
      align-items: center;
      flex-direction: row-reverse;
      margin: 7.5px 0;

      &:hover {
        cursor: pointer;

        .panel-process-details-company__text {
          &--underline {
            text-decoration: underline;
          }
        }
      }

      &:before {
        margin-left: 10px;
        text-decoration: none;
      }
    }
  }
</style>
