// TODO Supprimer ce store pour le remplacer par le plugin Toast

export default {
  state:  {
    isToasterDisplayed: false,
    options: {
      type: '',
      text: '',
      icon: '',
      backgroundColor: '',
      textColor: '',
      position: {
        top: null,
        bottom: null,
        left: null,
        right: null,
      },
      fixed: false,
      time: 2500,
      onClick: () => {},
    }
  },
  actions: {
    displayToaster({ commit }, params) {
      commit('displayToaster', params);
    }
  },
  mutations: {
    displayToaster(state, params) {
      state.isToasterDisplayed = true;

      state.options = {
        ...state.options,
        ...params
      };

      if (!state.options.fixed) {
        setTimeout(() => {
          state.isToasterDisplayed = false;
        }, state.options.time);
      }
    }
  }
};
