import { dealCommission, limitDateToSetOld } from '@/macros/processes/process';
import moment from 'moment/min/moment-with-locales';

export const computeDealAmount = (fixed, variable) => ((fixed + variable) * 1000) * dealCommission;

export const getCurrentProcesses = processes => {
 return (processes || []).filter(process => (moment(process.updatedAt).isAfter(moment().subtract(limitDateToSetOld, 'months'))));
};

export const getOldProcesses = processes => {
  return (processes || []).filter(process => (moment(process.updatedAt).isBefore(moment().subtract(limitDateToSetOld, 'months'))));
};

export const sortByFailedAt = processes => {
  return processes.sort((a, b) => a.failedAt && !b.failedAt ? 1 : -1);
};

export default {
  computeDealAmount,
  getCurrentProcesses,
  sortByFailedAt,
}
