import tags from '@/api/tags';
import { validateName } from '@/managers/tags/validators/validateName';
import { validateId } from '@/managers/tags/validators/validateId';

export const editDomain = async({ id, name }) => {
  const validate = ({ id, name }) => {
    try {
      validateId(id);
      validateName(name);
    } catch (error) {
      throw error;
    }
  };

  const execute = ({ id, name }) => {
    try {
      return tags.editTag({ name, id });
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    validate({ id, name });

    const { data } = await execute({
      name,
      id,
    });

    return data;
  } catch (error) {
    throw error;
  }
};
