<template>
  <div class="panel-job-edit-avatar">
    <bc-avatar
      :bc-img-src="pictureUrl && !imgFallback ? pictureUrl : require('@/assets/img/default-avatar.svg')"
      @error="setAlternativeImage"
      bc-avatar-radius="100%"
      bc-avatar-size="100px"
      bc-img-alt="avatar"
      class="panel-job-edit-avatar__img">
    </bc-avatar>
  </div>
</template>

<script>
  import BcAvatar from '@/legacy/ui-kit/components/Avatar/BcAvatar';

  export default {
    name: 'panel-job-edit-avatar',
    components: { BcAvatar },
    props: {
      pictureUrl: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      imgFallback: false
    }),
    methods: {
      setAlternativeImage() {
        this.imgFallback = true;
      }
    }
  };
</script>

<style lang=scss scoped>
  .panel-job-edit-avatar {
    justify-content: center;

    &__img {
      overflow: hidden;
    }
  }
</style>
