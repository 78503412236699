import processes from '@/api/processes';

export const getProcesses = async options => {
  const execute = async options => {
    try {
      return processes.getProcesses(options);
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    return execute(options);
  } catch (error) {
    throw error;
  }
};

export const getProcessesByColumn = async options => {
  const execute = async options => {
    try {
      return processes.getResourceProcesses(options);
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    return execute(options);
  } catch (error) {
    throw error;
  }
};
