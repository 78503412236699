<template>
  <div class="auth-magic-email-sent is-justify-content-center is-align-items-center is-column">
    <p class="auth-magic-email-sent__text">
      {{ $t('generics.hello') }} <strong>{{ user && user.firstName ? user.firstName : '' }}</strong>, {{ $t('login.email-send') }}
    </p>
    <bc-button
      class="auth-magic-email-sent__button mt-6"
      type="outlined"
      @click.native="getToken">
      {{ $t('login.no-email-received') }}
    </bc-button>
  </div>
</template>

<script>
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import auth from '@/api/auth';

  export default {
    name: 'auth-magic-email-sent',
    components: { BcButton },
    props: {
      user: {
        type: Object,
        default: () => ({}),
      },
      email: {
        type: String,
        default: '',
      },
    },
    methods: {
      async getToken() {
        try {
          this.$emit('is-fetching', true);

          if (this.email) {
            await auth.sendTokenMagicLinkEmail(this.email);

            this.$toast.show({
              type: 'success',
              message: `${this.$i18n.t('toast.email-successfully-sent-at-adress')} ${this.email}`,
            });
          }

          this.$emit('is-fetching', false);
        } catch (error) {
          this.$emit('is-fetching', false);

          this.$toast.show({
            type: 'error',
            message: error?.response?.data?.message || error,
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .auth-magic-email-sent {
    width: 100%;
    flex-grow: 1;
    padding: 80px 0 50px;
    max-width: 365px;

    &__text {
      font-size: $font-size-xxl;
      font-weight: 400;
      color: $color-secondary;
      text-align: center;
    }

    &__button {
      width: 100%;
    }
  }
</style>
