<template>
  <div class="panel-process-details-payment">
    <p class="panel-process-details-payment__title">
      {{ $t('avatar-dropdown.has-the-company-made-the-payment') }}
    </p>
    <bc-button
      class="panel-process-details-payment__button"
      color="secondary-light"
      type="negative"
      @click.native="confirmPayment">
      {{ $t('generics.validate') }}
    </bc-button>
  </div>
</template>

<script>
  import BcButton from '@/ui-kit/components/BcButton';

  import processesController from '@/managers/processes/controller';

  export default {
    name: 'panel-process-details-payment',
    components: { BcButton },
    props: {
      process: {
        type: Object,
        default: () => ({}),
      },
    },
    methods: {
      async confirmPayment() {
        try {
          const { data: { payedAt } } = await processesController.updatePayment(this.process._id);

          this.$emit('process-updated', {
            ...this.process,
            payedAt,
          });
          this.$emit('update-timeline');

        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-process-details-payment {
    background: $color-secondary-light;
    border-radius: 2px;
    padding: 15px 20px;

    &__title {
      display: flex;
      align-items: center;
      color: $color-white;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      text-transform: uppercase;

      &:before {
        margin-right: 10px;
      }
    }

    &__button {
      text-transform: uppercase;
      border-radius: $border-radius-xs;
    }
  }
</style>
