<script lang="ts" setup>
import { computed, defineProps, ref } from 'vue';
import { Candidate } from '@/domains/models/Candidate';
import linkedinLogo from '@/assets/logo/linkedin.png';
import linkedinInmail from '@/assets/logo/linkedin-inmail-croped.svg';
import linkedinInvitation from '@/assets/logo/linkedin-invitation.svg';
import linkedinMessage from '@/assets/logo/linkedin-message.svg';
import { useI18n } from '@/i18n/i18n';
import TransitionFadeTranslateUp
  from '@/components/UIKit/Transition/TransitionFadeTranslateUp.vue';
import { linkedinMacros } from '@/macros/linkedin';
import { useLinkedinPanel } from '@/composables/useLinkedinPanel';
import { useLinkedinCandidate } from '@/composables/useLinkedinCandidate';
import { useLinkedinRelationsApi } from '@/composables/api/useLinkedinRelationsApi';
import Spinner from '@/components/UIKit/Spinner/Spinner.vue';


interface Props {
  linkedinUrl: string;
  candidate: Candidate;
}

const props = defineProps<Props>();

const { t } = useI18n();

const candidate = computed(() => props.candidate);
const { canMessage, canInvite, canInmail } = useLinkedinCandidate(candidate);

const { syncRelationStatus, loading: relationLoading } = useLinkedinRelationsApi();
const { getPendingInvitations, loading: invitationsLoading } = useLinkedinRelationsApi();
const { openLinkedinPanel } = useLinkedinPanel();

const isOpen = ref(false);


function openInmailModal() {
  openLinkedinPanel(props.candidate, linkedinMacros.templates.type.inmail);
}

function openInviteModal() {
  openLinkedinPanel(props.candidate, linkedinMacros.templates.type.invitation);
}

function openMessageModal() {
  openLinkedinPanel(props.candidate, linkedinMacros.templates.type.message);
}

async function toggleAndRefreshIfNotConnected() {
  isOpen.value = !isOpen.value;
  if (!canMessage.value && isOpen.value) {
    await syncRelationStatus(props.candidate._id);
    if (canInvite.value) {
      getPendingInvitations();
    }
  }
}
</script>

<template>
  <a v-if="!canMessage && !canInvite && !canInmail" :href="props.linkedinUrl" target="_blank"
    class="hover:text-underline max-w-[47.5%]">
    <div class="relative flex basis-1/2 items-center text-blue-800">
      <img :src="linkedinLogo" alt="icon" class="mr-1 size-[13px]" />
      <p class="truncate text-xs font-medium">
        {{ props.linkedinUrl }}
      </p>
    </div>
  </a>
  <div v-else class="cursor-pointer panel-coder-contacts-social relative flex basis-1/2 items-center text-blue-800"
    @click.prevent="toggleAndRefreshIfNotConnected" :class="{ 'bg-blue-100': isOpen }">
    <img :src="linkedinLogo" alt="icon" class="mr-1 size-[13px]" />
    <p class="truncate text-xs font-medium">
      {{ props.linkedinUrl }}
    </p>
    <TransitionFadeTranslateUp>
      <div v-if="isOpen" v-click-outside="() => isOpen = false"
        class="absolute flex-col left-0 top-full z-10 min-w-48 mt-1.5 rounded-md bg-white text-xs shadow-md divide-y divide-blue-200">
        <a :href="props.linkedinUrl" target="_blank"
          class="flex gap-2.5 h-9 px-5 items-center hover:bg-blue-500 hover:text-white">
          <img :src="linkedinLogo" alt="linkedin-icon" class="size-[13px]" />
          <p>
            {{ t('global.see-profile') }}
          </p>
        </a>
        
        <div v-if="invitationsLoading || relationLoading" class="flex gap-2.5 h-9 px-5 cursor-pointer justify-center items-center border-b-blue-200 hover:bg-blue-500 hover:text-white">
          <Spinner bcSize="16px" />
        </div>
        <a v-else-if="canMessage" @click="openMessageModal"
          class="flex gap-2.5 h-9 px-5 cursor-pointer items-center border-b-blue-200 hover:bg-blue-500 hover:text-white">
          <img :src="linkedinMessage" alt="linkedin-message" class="size-[13px]" />
          <p>{{ t('linkedin.send-a-message') }}</p>
        </a>
        <a v-else-if="canInvite" @click="openInviteModal"
          class="flex gap-2.5 h-9 px-5 cursor-pointer items-center border-b-blue-200 hover:bg-blue-500 hover:text-white">
          <img :src="linkedinInvitation" alt="linkedin-invitation" class="size-[13px]" />
          <p>{{ t('linkedin.send-an-invitation') }}</p>
        </a>
        <a v-else-if="canInmail" @click="openInmailModal"
          class="flex gap-2.5 h-9 px-5 cursor-pointer items-center border-b-blue-200 hover:bg-blue-500 hover:text-white">
          <img :src="linkedinInmail" alt="linkedin-inmail" class="size-[13px]" />
          <p>{{ t('linkedin.send-an-inmail') }}</p>
        </a>
      </div>
    </TransitionFadeTranslateUp>
  </div>
</template>
