import timelineMacros from '@/managers/timeline/timelineMacros';

export const verifyActionMacroSet = (action) => {
  const actionRange = Math.trunc(action.type / 100);
  const actionMacro = timelineMacros[actionRange][action.type];

  return !!actionMacro;
};

export const getFormattedAction = (action) => {
  const actionRange = Math.trunc(action.type / 100);

  const timeline = timelineMacros[actionRange] || timelineMacros[0];

  const actionMacro = timeline?.[action.type];
  if (!actionMacro) {
    return action;
  }

  const icon = actionMacro.icon && typeof actionMacro.icon === 'function' ? actionMacro.icon(action) : null;
  const content = actionMacro.content && actionMacro.content(action);
  const headerHtml = actionMacro.headerHtml && actionMacro.headerHtml(action);
  const html = actionMacro.html && actionMacro.html(action);
  const source = actionMacro.source && actionMacro.source(action);

  return {
    ...action,
    pictureUrl: actionMacro.pictureUrl(action),
    header: actionMacro.header(action),
    ...content && { content },
    ...headerHtml && { headerHtml },
    ...html && { html },
    ...source && { source },
    ...icon && { icon },
  };
};

export default {
  getFormattedAction,
  verifyActionMacroSet,
};
