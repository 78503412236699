<template>
  <div class="flex items-center gap-2.5">
    <label
      :class="[
        `bc-toggle__${type}`,
        {
          [`bc-toggle__${type}--disabled`]: disabled,
          [`bc-toggle__${type}--checked`]: value,
          [`bc-toggle__${type}--checked-and-disabled`]: disabled && value,
          'is-row-reverse': reversed,
        },
      ]"
      :for="id"
      class="bc-toggle font-size-m">
      <span
        :class="{
          'bc-toggle__content': $slots.default,
          'bc-toggle__content--reversed': reversed,
        }">
      </span>
      <input
        :checked="value"
        :disabled="disabled"
        :id="id"
        @click="clickEmitter(value)"
        class="bc-toggle__slider"
        :class="[
          'rounded-2xl',
          'h-4 w-7',
          {
            'pointer-events-none cursor-default select-none opacity-60':
              disabled,
          },
          {
            'bg-blue-400': !value,
            [color === 'blue' ? 'bg-blue-500' : '']: !!value,
            [color === 'purple' ? 'bg-purple-600' : '']: !!value,
          },
        ]"
        type="checkbox" />
    </label>
    <span
      v-if="label"
      :class="{
        'text-blue-400': !value,
        [color === 'blue' ? 'text-blue-500' : '']: !!value,
        [color === 'purple' ? 'text-purple-600' : '']: !!value,
      }"
      class="text-xs">
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'bc-compact-toggle',
  props: {
    /**
     * The id is required for html semantic
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * The value of the checkbox
     */
    value: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable the label
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Set the label to the left
     */
    reversed: {
      type: Boolean,
      default: false,
    },
    /**
     * The type of the toggle
     * @values default, colored
     */
    color: {
      type: String,
      default: 'blue',
    },
    type: {
      type: String,
      default: 'colored',
    },
    label: {
      type: String,
    },
  },
  methods: {
    clickEmitter(value) {
      /**
       * click -> Emit the opposite of the value
       *
       * @event input
       * @type {boolean}
       */
      this.$emit('input', !value);
    },
  },
};
</script>

<style lang="scss" scoped>
.bc-toggle {
  $this: &;

  $colors: (
    default-color-off: $color-red-mandy,
    default-color-on: $color-green-shamrock,
    // default-color-hover-off: $color-grey-5,
    // default-color-hover-on: $color-primary-dark,
    colored-color-off: $color-error,
    colored-color-on: $color-success,
    colored-color-hover-off: $color-error-dark,
    colored-color-hover-on: $color-success-dark,
  );

  @mixin selectColor($type) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 1;

    #{$this}__slider {
      transition-duration: 0.2s;
      cursor: pointer;
      opacity: 1;
      position: relative;

      &:checked {
        opacity: 1;
      }

      &:disabled {
        cursor: default;
        opacity: 0.5;

        &:checked {
          opacity: 0.5;
        }
      }
    }

    #{$this}__slider:before {
      position: absolute;
      content: '';
      height: 0.75rem;
      width: 0.75rem;
      top: 0.125rem;
      left: 0.125rem;
      background-color: $color-white;
      border-radius: $border-radius-rounded;
      box-shadow: $shadow-default;
      transition: 0.5s cubic-bezier(0.17, 0.67, 0, 1);
    }

    #{$this}__slider:checked {
      &:before {
        transform: translateX(0.75rem);
      }
    }

    #{$this}__content {
      display: flex;
      align-items: center;
      margin-right: 10px;
      color: inherit;

      &--reversed {
        margin-right: 0;
        margin-left: 10px;
      }
    }

    &:hover {
      #{$this}__slider {
        &:checked {
          opacity: 1;
        }

        &:disabled {
          cursor: default;
          opacity: 0.5;

          &:checked {
            opacity: 0.5;
          }
        }
      }
    }

    &--disabled {
      cursor: default;
      opacity: 0.5;
    }

    &--checked-and-disabled {
      &:hover {
        opacity: 0.5;
      }
    }
  }

  &__default {
    @include selectColor('default');
  }

  &__colored {
    @include selectColor('colored');
  }
}
</style>
