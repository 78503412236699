<template>
  <div class="is-full-width is-column">
    <div
      class="card-reminders-coders-body__container is-full-width is-align-items-center is-relative">
      <div
        class="card-reminders-coders-body__wrapper is-full-width is-justify-content-center is-column card-reminders-coders-body__id">
        <div class="is-secondary-dark is-align-items-center is-full-width">
          <bc-avatar
            :src="(cardContent._coder || {}).pictureUrl"
            class="card-reminders-coders-body__avatar is-unshrink"
            size="s"
            @error="setAlternativeImg">
          </bc-avatar>
          <div class="card-reminders-coders-body__name is-column is-align-items-start">
            <p
              v-tooltip="fullName"
              class="card-reminders-coders-body__text body-l--medium is-ellipsis is-full-width">
              {{ fullName }}
            </p>
            <p
              v-if="(cardContent._coder || {}).title"
              v-tooltip="(cardContent._coder || {}).title"
              class="card-reminders-coders-body__text body-l is-ellipsis is-full-width">
              {{ profileTitle }}
            </p>
          </div>
        </div>
        <div v-if="cardContent" class="card-reminders-coders-body__footer-text">
          {{ suggestionTag }}
        </div>
      </div>
      <div
        class="card-reminders-coders-body__wrapper card-reminders-coders-body__link is-align-items-center hidden-xs hidden-sm">
        <a
          v-if="((cardContent._coder || {}).social || {}).linkedin"
          v-tooltip="$t('companies.panel-company-contracts.open-the-linkedin-profile')"
          :href="((cardContent._coder || {}).social || {}).linkedin"
          target="_blank"
          @click.stop>
          <img
            alt="logo linkedin"
            class="card-reminders-coders-body__icon"
            src="@/assets/logo/linkedin.png"/>
        </a>
        <a
          v-else-if="(cardContent._coder || {}).cvUrl"
          v-tooltip="$t('companies.panel-company-contracts.open-the-cv')"
          :href="(cardContent._coder || {}).cvUrl"
          class="font-weight-bold font-size-xl"
          target="_blank"
          @click.stop>
          {{ $t('generics.resume') }}
        </a>
      </div>
      <div
        class="card-reminders-coders-body__wrapper card-reminders-coders-body__stack is-align-items-center">
        <tag
          v-for="profession in professions.slice(0, 1)"
          :key="profession._id"
          :text="profession.name"
          v-tooltip="{
            content:
              professions.length > 1 && profession.name.length > textMaxLength
                ? profession.name
                : undefined,
          }"
          type="professions" />
        <tag
          v-if="professions.length > 1"
          :text="`+${professions.slice(1).length}`"
          type="professions"
          v-tooltip="{
            content: professions
              .slice(1)
              .map((p, i) => `${i > 0 ? ' ' : ''}${p.name}`),
          }" />
      </div>
      <div
        class="card-reminders-coders-body__wrapper card-reminders-coders-body__skills is-align-items-center">
        <card-tags
          :reference="'stack'"
          :tags="stack"
          class="card-reminders-coders-body__tag-wrapper">
          <template #tag="{ tag }">
            <tag
              v-tooltip="tag.name"
              :text="tag.name"
              type="technos">
            </tag>
          </template>
          <template #remaining="{ remaining }">
            <tag type="technos" :text="`+${remaining}`" :show-icon="false"></tag>
          </template>
        </card-tags>
      </div>
      <div
        class="card-reminders-coders-body__wrapper card-reminders-coders-body__locations is-align-items-center hidden-xs hidden-sm">
        <tag
          v-for="location in baseLocations.slice(0, 1)"
          :key="location.place_id"
          :text="getShortedText(location.fullAddress)"
          v-tooltip="{ content: location.fullAddress.length > textMaxLength ? location.fullAddress : undefined }"
          type="location" />
        <tag
          v-if="baseLocations.length > 1"
          class="ml-2"
          :text="`+${baseLocations.slice(1).length}`"
          type="location"
          v-tooltip="{
            content: baseLocations
              .slice(1)
              .map((location, i) => `${i > 0 ? '<br />' : ''}${location.fullAddress}`),
          }" />
      </div>
      <div
        v-tooltip.bottom="ratingTooltip"
        :class="{ 'hidden-xs': !qualifications.length }"
        class="card-reminders-coders-body__wrapper card-reminders-coders-body__rating is-align-items-center">
        <ratings
          v-if="qualifications.length"
          :rating="lastQualification.rating"
          class="is-align-items-center"
          disabled
          is-hidden-button
          star-size="m">
        </ratings>
        <div
          v-if="qualifications.length"
          class="card-reminders-coders-body__rating--count ml-1 font-size-s is-secondary">
          {{ qualifications.length }}
        </div>
      </div>
      <div
        v-if="isCoachOwnerOfSuggestion"
        class="card-reminders-coders-body__wrapper card-reminders-coders-body__actions hidden-xs hidden-sm"
        @click.stop>
        <reminders-buttons
          :suggestion="cardContent"
          @archived="removeCard"
          @unarchived="removeCard"
          @set-suggestion-to-postponed="handleToPostponed"
          @set-suggestion-to-rejected="removeCard"
          @set-suggestion-to-transfered="removeCard">
        </reminders-buttons>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment/min/moment-with-locales';
  import { mapActions, mapState } from 'vuex';

  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import { getDomainNameFromApiValue } from '@/managers/coders/formatters/codersDeserializers';
  import RemindersButtons from '@/components/Reminders/RemindersButtons/RemindersButtons';
  import suggestionsMacros from '@/macros/suggestions';
  import Ratings from '@/components/Toolbox/Ratings';
  import Tag from '@/components/Tag/Tag';
  import CardTags from '@/components/Card/CardTags';
import { formatPlaceDetailsToBaseLocation } from '@/formatters/locations';

  export default {
    name: 'card-reminders-coders-body',
    components: {
      Ratings,
      RemindersButtons,
      BcAvatar,
      Tag,
      CardTags,
    },
    props: {
      cardContent: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        textMaxLength: 13,
      }
    },
    computed: {
      ...mapState({
        profile: state => state.user.profile,
      }),
      coder() {
        return this.cardContent._coder || {};
      },
      fullName() {
        return `${this.coder.firstName} ${this.coder.lastName}`;
      },
      domains() {
        return this.coder.domains?.map(domain => ({ name: getDomainNameFromApiValue(domain) })) || [];
      },
      stack() {
        return [...(this.coder._technos3 ?? []), ...(this.coder._technos2 ?? []), ...(this.coder._technos1 ?? [])];
      },
      professions() {
        return this.coder?._professions || [];
      },
      baseLocations() {
        return (this.coder?.locations || []).map(formatPlaceDetailsToBaseLocation);
      },
      rating() {
        return this.coder.lastRating || {};
      },
      followers() {
        return this.coder.followers?.slice(0, 1) || [];
      },
      followersTooltip() {
        return {
          content: this.coder.followers?.map(follower => `${follower.firstName} ${follower.lastName}`)?.join(', '),
        };
      },
      isOnRemindersPage() {
        return this.$route.name.includes('Reminders');
      },
      isCoachOwnerOfSuggestion() {
        return !this.cardContent?._coach || this.cardContent?._coach?._id === this.profile._id;
      },
      profileTitle() {
        return this.coder.title === 'New coder' ? '' : this.coder.title;
      },
      isSuggestionToDo() {
        return this.cardContent.status === suggestionsMacros.status.todo.value;
      },
      qualifications() {
        return this.coder?._qualifications || [];
      },
      lastQualification() {
        return this.coder?._qualifications?.at(-1) || {};
      },
      ratingTooltip() {
        return {
          html: true,
          content: this.lastQualification._coach ? `
            <div>
              <img src='${this.lastQualification?._coach?.pictureUrl}' style='width: 20px; height: 20px; border-radius: 100%;' />
              <p style='margin-left: 5px; font-size: 14px; font-weight: 400;'>
                ${this.lastQualification?._coach?.firstName} ${this.lastQualification?._coach?.lastName}
              </p>
            </div>
          ` : '',
        };
      },
      suggestionTag() {
        let tag = '';
        if (this.cardContent.comebackAt) {
          tag = `${this.$i18n.t('generics.relaunch')} : ${moment(this.cardContent.comebackAt).format('L')}`;
          return tag;
        }

        if (this.cardContent._referrer) {
          tag += ` | ${this.$i18n.t('generics.relaunch')} ${this.cardContent._referrer.firstName} ${this.cardContent._referrer.lastName}`;
          return tag;
        }

        return tag;
      },
    },
    methods: {
      ...mapActions([
        'updateCardFromId',
      ]),
      setAlternativeImg(event) {
        event.target.src = require('@/assets/img/default-avatar.svg');
      },
      removeCard() {
        this.$store.dispatch('removeCard', this.cardContent._id);

        if (this.isSuggestionToDo) {
          this.$store.dispatch('removeSuggestionFromToDoSuggestions', this.cardContent._id);
        }
      },
      handleToPostponed(suggestion) {
        if (this.$route.query.filter !== 'postponed') {
          this.removeCard();
        } else {
          this.updateCardFromId({
            id: suggestion._id,
            content: suggestion,
          });
        }
      },
      getShortedText(text) {
        if (text.length > this.textMaxLength) {
          return text.slice(0, this.textMaxLength) + '...';
        }
        return text;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card-reminders-coders-body {
    &:hover {
      cursor: pointer;
      max-height: initial;
    }

    @include bp("tablet") {
      max-height: 120px;
    }

    &__container {
      padding: 15px;
      flex-direction: column;

      @include bp("tablet") {
        flex-direction: row;
      }
    }

    &__wrapper {
      margin: 10px;
      max-height: 100%;
      min-height: 37px;
      width: 100%;
      gap: 5px;

      &:deep() {
        .ratings {
          margin: 0;
        }
      }
    }

    &__id {
      @include coders-grid-name;
    }

    &__avatar {
      margin-right: 10px;
    }

    &__name {
      max-width: calc(100% - 50px);
    }

    &__divider {
      margin: 0.75em 0;
      border-top: 1px solid $color-grey-2;
    }

    &__link {
      @include coders-grid-cv;
      color: $color-success-light;

      a {
        color: $color-success-light;

        &:hover {
          color: $color-secondary-dark;
        }
      }
    }

    &__domains {
      @include coders-grid-domain;
    }

    &__stack {
      @include coders-grid-stack;
    }

    &__skills {
      @include coders-grid-skills;
    }

    &__locations {
      @include coders-grid-locations;
    }

    &__rating {
      @include coders-grid-rating;

      &--count {
        background: $color-blue-medium;
        color: $color-tertiary;
        border-radius: 100%;
        padding: 3px 6px;
        font-size: 11px;
      }
    }

    &__actions {
      @include coders-grid-actions;
    }

    &__text {
      color: $color-blue-dark-cello;
      max-width: 100%;
      font-size: 16px;
    }

    &__tag-wrapper {
      width: 100%;
    }

    &__tag {
      max-width: 100%;
      border-radius: 30px;
      color: #3D5476;
      font-weight: 500;
      padding: 4px 8px;
      font-size: 13px;

      &.is-unshrink {
        max-width: initial;
      }

      &--location {
        background: rgba($color-primary, 0.2);
        color: $color-primary;
      }

      &--domain {
        background: $color-primary-dark;
        color: $color-white;
      }
    }

    &__rating-avatar {
      margin-right: 5px;
    }

    &__follower {
      margin-left: 5px;

      &:first-of-type {
        margin-left: 0;
      }
    }

    &__status {
      height: 6px;
      background: $color-grey-4;

      &--processed {
        background: $color-success;
      }
    }

    &__count {
      background: $color-grey-3;
      color: $color-grey-5;
      height: 28px;
      width: 28px;
    }

    &__buttons {
      align-items: center;
      height: calc(100% - 5px);
      z-index: 1;

      &--pool {
        right: 5%;
      }

      &--pipe {
        right: 5%;
      }
    }

    &__icon {
      height: 20px;

      &:hover {
        filter: brightness(0.7);
      }
    }

    &__footer-text {
      color: $color-blue-heavy-dark;
      font-size: 14px;
    }
  }
</style>
