import debounce from 'debounce';
import { noteController } from '@/managers/notes/controller';
import noteDeserializers from '@/managers/notes/formatters/noteDeserializers';
import notesMacros from '@/common-old/macros/notes';

const routes = {
  companies: 'partners',
  coders: 'coders',
  contacts: 'contacts',
  hunt: 'hunt',
  jobs: 'jobs',
  pokes: 'pokes',
  process: 'processcustom',
  'suggestions-coders': 'coders',
};

const debounceDelay = 1000;

export default {
  data() {
    return {
      content: '',
      isSaved: false,
      isDraftLoaded: false,
      disableDraftNote: false,
    };
  },
  computed: {
    isValidOther() {
      return this.type === notesMacros.type.other.name && this.isNotEmptyMessage;
    },
    isNotEmptyMessage() {
      return this.content.replace(/<[^>]*>?/gm, '');
    },
    isDraftChanged() {
      return this.draft.content !== this.content || this.draft.type !== this.type || this.draft.rating !== this.rating;
    },
  },
  watch: {
    content() {
      if (this.isDraftLoaded) {
        this.handleContentChange();
      }
    },
    type() {
      if (this.isDraftLoaded) {
        this.handleTypeChange();
      }
    },
  },
  created() {
    this.$_setDefaultNoteToSavedNote();
  },
  methods: {
    setIsTyping(value) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          typing: value,
        },
      }).catch(() => {
      });
    },
    $_setDraft(draft) {
      this.draft = {
        ...this.draft,
        ...draft,
      };
    },
    async $_setDefaultNoteToSavedNote() {
      try {
        const id = this.timelineId ? this.timelineId : this.$route?.params?.id

        const { data } = await noteController.getDraft(id);

        if (data.content || data.type || data.rating || data.media) {
          const note = noteDeserializers.deserializeNote(data);

          this.content = note.content || '';
          // this.type = note.type || '';
          this.rating = note.rating || undefined;
          // this.media = note.media || [];

          if (!this.isNewNote) {
            this.media = note.media || [];
            this.noteType = note.type || '';
          }

          this.$_setDraft(note);

          this.$toast.show({
            message: this.$i18n.t('toast.draft-loaded'),
            type: 'success',
            icon: 'check',
          });
        }

        this.$nextTick(() => {
          this.isDraftLoaded = true;

          if (this.noteTypeTracking) {
            this.type = notesMacros.type.message.name;
          }
        });
      } catch (error) {
        this.$toast.show({
          message: error.message || error.response.data,
          type: 'error',
          icon: 'cross',
        });
      }
    },
    async $_createDraft() {
      try {
        const data = await noteController.createDraft({
          userType: routes[this.$route.query.type],
          id: this.timelineId ? this.timelineId : this.$route?.params?.id,
          note: {
            ...this.content && { content: this.content },
            ...this.noteType && { type: this.noteType },
            ...this.media?.length > 0 && { media: this.media },
            ...this.rating >= 0 && { rating: this.rating },
            ...this.urgency >= 0 && { urgency: this.urgency },
          },
        });

        const note = noteDeserializers.deserializeNote(data);
        this.$_setDraft(note);
      } catch (error) {
        this.$toast.show({
          message: error.message || error.response.data,
          type: 'error',
          icon: 'cross',
        });
      }
    },
    async $_editDraft() {
      try {
        const data = await noteController.editDraft({
          id: this.draft._id,
          note: {
            ...this.content && { content: this.content },
            ...this.noteType && { type: this.noteType },
            ...this.media?.length > 0 && { media: this.media },
            ...this.rating >= 0 && { rating: this.rating },
            ...this.urgency >= 0 && { urgency: this.urgency },
          },
        });

        const note = noteDeserializers.deserializeNote(data);
        this.$_setDraft(note);
      } catch (error) {
        this.$toast.show({
          message: error.message || error.response.data,
          type: 'error',
          icon: 'cross',
        });
      }
    },
    async $_deleteDraft() {
      await noteController.remove(this.draft._id);

      this.draft = {};
    },
    async $_updateDraft() {
      const isMessage = this.type === notesMacros.type.message.name;
      if (isMessage || this.isNotEmptyMessage) {
        try {
          const shouldCreateDraft = !this.draft._id;
          if (shouldCreateDraft) {
            await this.$_createDraft();

            this.$_editDraft();

            this.$_setIsSaved();
          } else if (this.isDraftChanged) {
            this.$_editDraft();

            this.$_setIsSaved();
          }
        } catch (error) {
          this.$toast.show({
            message: error.message || error.response.data,
            type: 'error',
            icon: 'cross',
          });
        }
      }
    },
    handleContentChange: debounce(function() {
      if (this.disableDraftNote) {
        return;
      }
      if (this.draft._id && !this.isNotEmptyMessage && this.type !== notesMacros.type.message.name) {
        this.$_deleteDraft();
      } else {
        this.$_updateDraft();
      }
    }, debounceDelay),
    handleTypeChange: debounce(function() {
      if (this.disableDraftNote) {
        return;
      }
      this.$_updateDraft();
    }, debounceDelay),
    $_setIsSaved() {
      this.isSaved = true;

      setTimeout(() => {
        this.isSaved = false;
      }, 2000);
    },
  },
};
