<template>
  <div class="accordion">
    <div class="accordion__header">
      <button @click.prevent.stop="toggle" class="accordion__button" :class="{ 'accordion__button--focus': isShow }">
        <span class="accordion__chevron">
          <i :class="{ 'icon-chevron-right': !isShow, 'icon-chevron-down': isShow }"></i>
        </span>
        {{ title }}
      </button>
      <span v-if="count !== undefined" class="accordion__counter" :class="{ 'accordion__counter--focus': isShow }">
        {{ count }}
      </span>
      <div class="accordion__header--bar"></div>
    </div>
    <div v-show="isShow" class="accordion__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'accordion',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    count: {
      type: Number,
    },
  },
  mounted() {
    this.isShow = this.open;
  },
  data() {
    return {
      isShow: false,
    }
  },
  methods: {
    toggle() {
      this.isShow = !this.isShow;
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  flex-direction: column;

  &__header {
    flex: 1;
    align-items: center;

    &--bar {
      flex: 1;
      height: 1px;
      background: $color-blue-medium;
      margin-left: 20px;
    }
  }

  &__content {
    border-radius: 5px;
    border: 0px solid $color-blue-medium;
    background: $color-neutre-4;
    margin-top: 10px;
  }

  &__chevron {
    font-size: 10px;
  }

  &__button {
    color: $color-tertiary;
    font-weight: $font-weight-bold;
    align-items: center;
    gap: 10px;

    &--focus {
      color: $color-primary;
    }
  }

  &__counter {
    display: flex;
    margin-left: 5px;
    background: $color-blue-medium;
    border-radius: 100%;
    min-width: 20px;
    min-height: 20px;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    &--focus {
      color: white;
      background: $color-primary;
    }
  }
}
</style>
