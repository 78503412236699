<script lang="ts" setup>
  import { Candidate } from '@/domains/models/Candidate';
  import { defineEmits, defineProps, Ref, ref } from 'vue';
  import PanelCoderTopView from '@/components/Panel/PanelCoder/PanelCoderTop/PanelCoderTopView.vue';
  import PanelCoderTopEdit from '@/components/Panel/PanelCoder/PanelCoderTop/PanelCoderTopEdit.vue';
  import PanelCarousel from '@/components/Panel/PanelCarousel.vue';

  interface Props {
    candidate: Candidate;
  }

  defineProps<Props>();
  defineEmits<{
    (e: 'edit-candidate', value: boolean): void,
    (e: 'update-candidate', value: Candidate): void
  }>();

  const isEditing: Ref<boolean> = ref(false);
</script>

<template>
  <PanelCarousel
    class="panel-coder-top"
    :class="{ 'panel-coder-top--editing': isEditing }"
    is-new-style>
    <PanelCoderTopView
      v-if="!isEditing"
      :candidate="candidate"
      @update-candidate="$emit('update-candidate')"
      @edit-candidate="isEditing = true"
    />
    <PanelCoderTopEdit
      v-else
      :candidate="candidate"
      class="-mb-5"
      @update-candidate="$emit('update-candidate', $event)"
      @edit-candidate="isEditing = false"/>
  </PanelCarousel>
</template>

<style lang="scss" scoped>
  .panel-coder-top {
    &--editing {
      &:deep() {
        .panel-carousel__navigation {
          position: relative;
          top: -15px;
        }
      }
    }
  }
</style>
