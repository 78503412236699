<template>
  <div class="panel-favorite-list-header">
    <bc-button
      @click.native="goPrevious"
      bc-background-color="transparent"
      bc-icon="cross"
      bc-icon-size="16px"
      bc-opacity="0.4"
      bc-text-color="dark-blue-cello"
      bc-text-color-hover="black"
      class="panel-favorite-list-header__close">
    </bc-button>
    <p class="panel-favorite-list-header__title">
      {{ title }}
    </p>
    <div
      class="panel-favorite-list-header__delete"
      v-tooltip.bottom="tooltip">
      <bc-button
        :disabled="!isOwner"
        @click.native="confirmDeleteFavoriteList"
        bc-background-color="red-mandy"
        bc-icon="trash"
        bc-icon-size="15px"
        bc-radius="50px"
        class="panel-favorite-list-header__button"
        v-if="isDisplayed">
      </bc-button>
    </div>
  </div>
</template>

<script>
  import BcButton from '@/legacy/ui-kit/components/Button/BcButton';
  //
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import favoriteLists from '@/macros/favoriteLists/favoriteLists';

  export default {
    name: 'panel-favorite-list-header',
    components: { BcButton },
    mixins: [PanelNavigation],
    props: {
      currentCoach: {
        type: Object,
        default: () => ({})
      },
      list: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      title() {
        return this.$route.query.subtype === 'create-favorite-list' ? this.$i18n.t('companies.panel-company.list-creation') : this.$i18n.t('companies.panel-company.list-edition');
      },
      tooltip() {
        let content = '';

        if (!this.isOwner) {
          content = this.$i18n.t('companies.panel-company.only-owners-can-delete-list');
        }

        return {
          content
        };
      },
      isOwner() {
        return this.currentCoach.role === favoriteLists.apiRights.owner;
      },
      isDisplayed() {
        return this.$route.query.subtype === 'edit-favorite-list' && !this.list.locked;
      }
    },
    methods: {
      confirmDeleteFavoriteList() {
        this.$emit('confirm-delete-list');
      }
    }
  };
</script>

<style lang=scss scoped>
  .panel-favorite-list-header {
    position: relative;
    width: 100%;
    height: 35px;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &__close {
      margin-right: auto;
    }

    &__title {
      font-weight: $font-weight-bold;
      font-size: $font-size-xl;
      line-height: 19px;
      text-align: center;
      color: $color-blue-dark-cello;
      position: absolute;
      margin: auto;
    }

    &__delete {
      margin-left: auto;
    }
  }
</style>
