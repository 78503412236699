<template>
  <td>
    <template v-if="canEdit">
      <p v-show="!isEditable" @click="focusSalary">
        {{ content }}
      </p>
      <div v-show="isEditable">
        <input
          type="number"
          ref="minInput"
          v-model="min"
          @focus="focusMin"
          @blur="switchMinSalaryEditable"
          id="minSalary" />
        <p>-</p>
        <input
          type="number"
          ref="maxInput"
          v-model="max"
          @focus="focusMax"
          @blur="switchMaxSalaryEditable"
          id="maxSalary" />
      </div>
    </template>
    <template v-else>
      <p>
        {{ content }}
      </p>
    </template>
  </td>
</template>

<script>
import TableCellMixin from '../../../mixins/Table/tableCell';

export default {
  mixins: [TableCellMixin],
  props: {
    editFromModal: {
      type: Boolean,
    },
  },
  name: 'salarycell',
  data() {
    return {
      isMinSalaryEditable: false,
      isMaxSalaryEditable: false,
      min: 0,
      max: 0,
    };
  },
  created() {
    if (this.job.salary) {
      this.min = this.job.salary.min;
      this.max = this.job.salary.max;
    }
  },
  methods: {
    updateSalary() {
      const optionCell = {
        columnName: this.columnType.toLowerCase(),
        data: {
          min: parseInt(this.min),
          max: parseInt(this.max),
        },
      };

      if (!this.job) {
        return this.$emit('update-cell-data', optionCell);
      }

      this.$emit('update-cell-data', { ...optionCell, jobId: this.job._id });
      this.updateContent();
    },
    updateContent() {
      if (this.job.salary) {
        if (this.editFromModal) {
          return `${this.min} - ${this.max}`;
        }

        return `${this.job.salary.min} - ${this.job.salary.max}`;
      }

      return `${this.min} - ${this.max}`;
    },
    switchMinSalaryEditable() {
      setTimeout(() => {
        this.isMinSalaryEditable = false;
      }, 50);
    },
    switchMaxSalaryEditable() {
      setTimeout(() => {
        this.isMaxSalaryEditable = false;
      }, 50);
    },
    focusMin() {
      this.isMinSalaryEditable = true;
      this.isMaxSalaryEditable = false;
    },
    focusMax() {
      this.isMaxSalaryEditable = true;
      this.isMinSalaryEditable = false;
    },
    focusSalary() {
      setTimeout(() => {
        this.$refs.minInput.focus();
      }, 50);

      this.isMinSalaryEditable = true;
      this.isMaxSalaryEditable = true;
    },
  },
  computed: {
    isEditable() {
      return this.isMinSalaryEditable || this.isMaxSalaryEditable;
    },
    content() {
      return this.updateContent();
    },
  },
  watch: {
    'job.salary'() {
      if (this.job.salary !== null) {
        this.min = this.job.salary.min;
        this.max = this.job.salary.max;
      }
    },
    isEditable() {
      if (!this.isEditable) this.updateSalary();
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  width: 80px;
  padding-left: 0 !important;
  text-align: center;
  max-height: 5%;
  height: 2%;
  max-width: 8vw;
  width: 10%;
  p {
    padding: 14px;
  }
  div {
    display: flex;
    align-items: center;
    padding: 4px;
    border: 1px solid $color-primary;
    color: $color-grey-4;
    font-family: 'Roboto';
    font-size: 16px;
    p {
      padding: 0;
      text-align: center;
    }
    input {
      text-align: center;
      width: 40px;
      padding: 6px 0px;
      border: 1px solid $color-grey-4;
      border-radius: 5px;
      color: $color-grey-4;
      font-family: 'Roboto';
      font-size: 16px;
    }

    #minSalary {
      margin-right: 10px;
    }

    #maxSalary {
      margin-right: 2px;
      margin-left: 10px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}
</style>
