<template>
  <TimelineMessage v-if="action.type === 1020" :action="action" />
  <div v-else class="timeline-item">
    <timeline-item-icon
      :action="computedAction"
      class="timeline-item__icon">
    </timeline-item-icon>
    <div
      :class="{ 'timeline-item__card-highlight': isHighlighted(action) }"
      class="timeline-item__card">
      <timeline-item-header
        :action="computedAction"
        :is-editable="isEditable"
        class="timeline-item__header"
        @edit-note="isEditing = true"
        @delete-note="isDeleting = true"
        @pin-note="handlePinNote">
      </timeline-item-header>
      <timeline-item-content
        v-if="isContentDisplayed || computedAction.content || computedAction.html"
        :action="computedAction"
        :is-deleting="isDeleting"
        :is-editing="isEditing"
        class="timeline-item__content"
        @link-process="$emit('link-process', $event)"
        @note-created="$emit('note-created', $event)"
        @note-deleted="$emit('note-deleted', $event)"
        @note-edited="$emit('note-edited', $event)"
        @note-pinned="handlePinNote"
        @update:is-editing="isEditing = $event"
        @unlink-process="$emit('unlink-process', $event)">
      </timeline-item-content>
      <component
        :is="specialTimelineItem(computedAction)"
        :action="computedAction">
      </component>
    </div>
    <ModalConfirm
      :cancel-text="$t('generics.cancel')"
      :confirm-text="$t('generics.yes-delete')"
      :content="`
        ${$t('timeline.by-deleting-this-note-you-will-lose-it-permanently')}.\n\n
        ${$t('timeline.this-action-is-irreversible')}`"
      :is-open="isDeleting"
      :title="$t('timeline.delete-this-note')"
      color="error"
      icon="❌"
      @close="isDeleting = false"
      @on-confirm="handleDeleteNote"
    />
  </div>
</template>

<script>
  import TimelineItemHeader from '@/components/Timeline/TimelineItem/TimelineItemHeader';
  import TimelineItemContent from '@/components/Timeline/TimelineItem/TimelineItemContent';
  import TimelineItemIcon from '@/components/Timeline/TimelineItem/TimelineItemIcon';
  import TimelineMessage from '@/components/Timeline/TimelineMessage.vue';
  // Special timeline item
  import ProcessTimelineItemOnboarding
    from '@/components/Process/ProcessTimelineItem/ProcessTimelineItemOnboarding';
  import ProcessTimelineItemValidated
    from '@/components/Process/ProcessTimelineItem/ProcessTimelineItemValidated';

  import notes from '@/common-old/macros/notes';
  import { actionsWithContent, processActionsWithContent } from '@/macros/timeline/actions';
  import { getFormattedAction } from '@/managers/timeline/timelineCenter';
  import BcModal from '@/ui-kit/components/BcModal/BcModal.vue';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
  import { noteController } from '@/managers/notes/controller.js';

  export default {
    name: 'timeline-item',
    components: {
      ModalConfirm,
      BcButton, BcModal,
      ProcessTimelineItemValidated,
      ProcessTimelineItemOnboarding,
      TimelineItemIcon,
      TimelineItemContent,
      TimelineItemHeader,
      TimelineMessage
    },
    props: {
      /**
       * The action of the timeline
       */
      action: {
        type: Object,
        default: () => ({}),
      },
      /**
       * The index of the action
       */
      actionIndex: {
        type: Number,
      },
      isEditable: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        isEditing: false,
        isDeleting: false,
      };
    },
    computed: {
      computedAction() {
        return getFormattedAction(this.action);
      },
      isHiddenQualification() {
        const isQualifOrRecruitmentProcess = this.action._note?.type === notes.type.qualification.api || this.action._note?.type === notes.type.process.api;
        return isQualifOrRecruitmentProcess && (this.$route.query.type === 'coders' || this.$route.query.type === 'companies' || this.$route.query.type === 'jobs' || this.$route.query.type === 'hunt' || this.$route.query.type === 'suggestions-coders');
      },
      isContentDisplayed() {
        return !this.isHiddenQualification && actionsWithContent.includes(this.action.type);
      },
    },
    methods: {
      isSpecialContent(action) {
        const stringifiedActionType = action?.type?.toString();

        return Object.keys(processActionsWithContent).includes(stringifiedActionType);
      },
      specialTimelineItem(action) {
        if (this.isSpecialContent(action)) {
          return processActionsWithContent[action.type];
        }
      },
      isHighlighted(action) {
        return action.type === 61;
      },
      async handleDeleteNote() {
        try {
          await noteController.remove({ id : this.action?._note?._id, actionId : this.action?._id});

          this.$toast.show({
            type: 'success',
            message: this.$i18n.t('toast.note-deletion-has-been-made'),
          });

          this.isDeleting = false;

          this.$emit('note-deleted', this.action?._note?._id);
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: error.message || error.response.data,
          });
        }
      },
      async handlePinNote() {
        const data = await noteController.editNote({
          id: this.action?._note?._id,
          note: {
            type: notes.type.echange.name,
            pin: true,
          },
        });

        this.$emit('note-pinned', data);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .timeline-item {
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    @include bp('tablet') {
      flex-direction: row;
      align-items: flex-start;
    }

    &:last-of-type {
      &:before {
        height: calc(100% - 30px);
        @include bp('tablet') {
          height: calc(100% - 50px);
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 4px;
      margin: 0 auto;
      height: calc(100% + 30px);
      background: $color-blue-medium;
      left: calc(50% - 1px);
      z-index: -1;
      @include bp('tablet') {
        height: calc(100% - 30px);
        top: 45px;
        left: 15.5px;
        right: auto;
        z-index: initial;
      }
    }

    &__icon {
      z-index: 1;
      margin-bottom: 10px;
      @include bp('tablet') {
        margin-bottom: 0;
        margin-right: 20px;
      }
    }

    &__card {
      width: 100%;
      background: $color-white;
      border-radius: $border-radius-m;
      border: 1px solid $color-blue-medium;
      flex-direction: column;

      &-highlight {
        border: 2px solid $color-primary;
      }
    }

    &__content {
      border-top: 1px solid $color-blue-medium;
    }
  }
</style>
