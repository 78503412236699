<script lang="ts" setup>
  import { Company } from '@/domains/models/Company';
  import { createProcess } from '@/managers/processes-custom/controller';
  import { defineEmits, defineProps, Ref, ref } from 'vue';
  import { Opportunity } from '@/domains/models/Opportunity';
  import { useRoute, useRouter } from 'vue-router/composables';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete.vue';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
  import { coderController } from '@/managers/coders/controller';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { useI18n } from '@/i18n/i18n';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
  import coders from '@/api/coders';
  import debounce from 'debounce';
  import fallbackImg from '@/assets/img/default-avatar.svg';
  import { Process } from '@/domains/models/Process';

  interface Props {
    opportunity: Opportunity;
  }

  const props = defineProps<Props>();
  const emit = defineEmits<{
    (e: 'close-edit', value: false): void;
    (e: 'create-process', value: Process): void;
  }>();

  const router = useRouter();
  const route = useRoute();
  const toast = useToast();
  const i18n = useI18n();

  // companies
  const candidateModelValue: Ref<string> = ref('');
  const candidateOptions: Ref<Company[]> = ref([]);
  const selectedCandidate: Ref<Partial<Company>> = ref({});
  const getCandidates = debounce(async({ query }: {
    query: string
  }): Promise<void> => {
    try {
      const { data } = await coders.candidatesAutocomplete(candidateModelValue.value);

      candidateOptions.value = data.map(candidate => ({
        ...candidate,
        name: `${candidate.firstName} ${candidate.lastName}`,
      }));
    } catch (error) {
      console.error(error);
    }
  }, 500);
  const setCandidate = (value: Company) => {
    selectedCandidate.value = value;
    candidateModelValue.value = '';
  };
  const removeCandidate = () => {
    selectedCandidate.value = {};
  };
  const formatString = (string = '') => {
    return string
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  };
  const splitHighlightValue = (name) => {
    const startHighlight = formatString(name).indexOf(formatString(candidateModelValue.value));
    const endHighlight = startHighlight + candidateModelValue.value.length;

    if (startHighlight === -1) {
      return [name, '', ''];
    }

    return [
      name.substring(0, startHighlight),
      name.substring(startHighlight, endHighlight),
      name.substring(endHighlight),
    ];
  };

  const isValidFirstNameAndLastName = () => {
    return candidateModelValue.value?.split(' ')?.length >= 2;
  };
  const getTextHint = (text) => {
    return `${
        splitHighlightValue(text)[0]
    }<span class="panel-process-create-coder__text--hint">${
        splitHighlightValue(text)[1]
    }</span>${splitHighlightValue(text)[2]}`;
  };
  const getOptionName = (option) => {
    return option.title
        ? `${option.firstName} ${option.lastName} - ${option.title}`
        : `${option.firstName} ${option.lastName}`;
  };

  const createCoderAndSetSelectedCoder = async() => {
    try {
      const [firstName, ...lastName] = candidateModelValue.value.trim().split(' ');

      const { data } = await coderController.createProfile({
        data: {
          firstName,
          ...(lastName && { lastName: lastName.join(' ') }),
        },
      });

      toast.show({
        type: 'success',
        title: i18n.t('toast.congratulations'),
        message: i18n.t('toast.candidate-successfully-added-to-your-data-basis'),
      });
    } catch (error) {
      toast.show({
        type: 'error',
        message: error?.response?.data?.message || error,
      });
    }
  };

  // process create
  const createNewProcess = async() => {
    try {
      const { data } = await createProcess({
        companyId: props.opportunity?._company?._id,
        customJobId: route.params.id,
        coderId: selectedCandidate.value._id,
      });
      emit('create-process', data);
      emit('close-edit', false);
    } catch (error) {
      console.error(error);
    }
  };

  const setAlternativeImg = (event: any) => {
    event.target.src = fallbackImg;
  };
</script>

<template>
  <div class="flex w-full flex-col gap-5 rounded-md bg-neutral-200 px-5 py-3.5">
    <div class="flex flex-col items-center gap-5 bg-white px-5 py-8">
      <div class="flex items-center">
        <i
          class="icon-user mr-2.5 size-[16px] text-blue-800"
        />
        <h2 class="uppercase text-blue-800">
          {{ $t('global.candidate') }} <span class="text-red-500">*</span>
        </h2>
      </div>
      <BcAutocomplete
        v-if="!selectedCandidate._id"
        ref="coderDropdown"
        v-model="candidateModelValue"
        :is-scrollable="false"
        :options="candidateOptions"
        class="w-full"
        keep-first
        name="autocomplete"
        placeholder="Écrivez le nom d’un candidat"
        @input="getCandidates"
        @select="setCandidate"
        @click.stop.native
      >
        <template #prepend>
          <div
            v-tooltip="$t('process.create-candidate-infos')"
            class="panel-process-create-coder__tooltip is-absolute is-right icon-info is-bottom is-top is-align-items-center is-secondary mr-3"></div>
        </template>
        <template #option="{ option, isActive }">
          <BcDropdownItem
            :class="{ 'panel-process-create-coder__option--active': isActive }"
            class="panel-process-create-coder__option"
          >
            <p
              class="panel-process-create-coder__text is-ellipsis"
              v-html="getTextHint(getOptionName(option))"
            >
            </p>
          </BcDropdownItem>
        </template>
        <template #footer>
          <button
            v-if="isValidFirstNameAndLastName && candidateModelValue.length >= 2"
            class="panel-process-create-company__item is-full-width font-size-m is-secondary is-justify-content-center mx-auto py-3"
            @click="createCoderAndSetSelectedCoder"
          >
            <span class="icon-candidates-plus mr-2"></span>
            {{ $t('process.create-the-coder', { candidate: candidateModelValue }) }}
          </button>
        </template>
      </BcAutocomplete>
      <button
        v-else
        class="relative flex w-full items-center justify-center rounded-md bg-blue-100 px-4 py-2 text-blue-800"
        @click="removeCandidate">
        <BcAvatar
          :alt="selectedCandidate.name"
          :src="selectedCandidate.pictureUrl"
          class="size-6 overflow-hidden rounded-full"
          @error="setAlternativeImg"
        />
        <p class="ml-2.5">
          {{ selectedCandidate.name }}
        </p>
        <button
          class="icon-cross absolute right-2.5 text-xs text-blue-800"
          @click="removeCandidate"/>
      </button>
    </div>
    <div class="flex flex-row justify-end gap-2.5">
      <BcButton
        icon-left="cross"
        size="small"
        type="outlined"
        @click="$emit('close-edit')">
        {{ $t('global.cancel') }}
      </BcButton>
      <BcButton
        :disabled="!selectedCandidate._id"
        color="primary"
        icon-left="check"
        native-type="submit"
        size="small"
        @click="createNewProcess">
        {{ $t('global.validate') }}
      </BcButton>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .panel-process-create-custom-job {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 15px 0;

    &__modal {
      min-width: 1000px;
      flex-direction: column;
      padding-top: 15px;
    }

    &__title {
      display: flex;
      color: #344f74;
      text-transform: uppercase;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      line-height: 17px;
      align-items: center;
      margin-bottom: 20px;
    }

    &__icon {
      display: inline-flex;
      font-size: $font-size-l;
      align-items: center;
      margin-right: 10px;
    }

    &__wrapper {
      flex-direction: column;
      width: 100%;
      margin: -2.5px 0;
    }

    &__option {
      width: 100%;
      margin: 2.5px 0;
      justify-content: center;
    }

    &__text {
      font-size: $font-size-s;
      color: $color-tertiary;

      &--black {
        color: $color-black;
      }
    }

    &__button {
      margin-top: 30px;
    }

    &__search {
      align-items: center;

      &--input {
        flex: 1;
        margin: 0;
      }

      &--header {
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: $color-blue-dark-cello;
        opacity: 0.7;
        padding: 14px 10px;
      }

      &--item {
        padding: 14px 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $color-blue-dark-cello;

        &-active {
          color: white;
        }
      }
    }
  }
</style>
