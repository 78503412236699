<template>
  <div class="card-coaches-log-as gap-3">
    <bc-button v-if="!disabled" @click.native="logAs">
      {{ $t('login.login') }}
    </bc-button>
    <bc-button
      v-if="isMarvinRecruiter"
      icon-left="link"
      size="small"
      @click.native.stop="getLogAsLink" />
  </div>
</template>

<script setup lang="ts">
import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
import user from '@/api/user';
import { removeToken } from '@/managers/token/useCases/removeToken';
import { useStore } from '@/store';
import { computed, withDefaults, defineProps } from 'vue';
import { useToast } from '@/ui-kit/components/BcToast';

const props = withDefaults(
  defineProps<{
    cardContent: any;
    disabled?: boolean;
  }>(),
  {
    disabled: false,
  }
);

const store = useStore();
const toast = useToast();
const isMarvinRecruiter = computed(() => store.getters['user/isMarvinRecruiter']);

async function logAs() {
  try {
    const {
      data: { token },
    } = await user.logAs(props.cardContent._id, 'coach');

    removeToken();
    await store.dispatch('user/cleanProfile');

    const newTab = window.open(`${window.location.origin}/log-as?token=${token}`, '_blank');

    if (newTab) {
      newTab.onload = function() {
        window.close();
      };
    }
  } catch (e: any) {
    toast.show({
      type: 'error',
      message: e?.response?.data?.message || e?.message,
    });
  }
}

async function getLogAsLink() {
  try {
    const {
      data: { token },
    } = await user.logAs(props.cardContent._id, 'coach');

    navigator.clipboard.writeText(`${window.location.origin}/log-as?token=${token}`);
  } catch (e: any) {
    toast.show({
      type: 'error',
      message: e?.response?.data?.message || e?.message,
    });
  }
}
</script>
