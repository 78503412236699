import { http } from '@/api/index';
import { i18n } from '../i18n/i18n';

export const subscriptionsModels = {
  costPerClick: 'usesCpc',
  organic: 'usesOrganic',
  credits: 'usesCredit',
  sponsored: 'usesSponsored',
}

export const applicationModes = {
  email: 'usesEmailApply',
  career: 'usesUrlApply',
  direct: 'usesDirectApply',
}

export const subscriptionsModelsLabels = {
  [subscriptionsModels.costPerClick]: i18n.t('generics.cost-per-click'),
  [subscriptionsModels.organic]: i18n.t('generics.organic'),
  [subscriptionsModels.credits]: i18n.t('generics.credits'),
  [subscriptionsModels.sponsored]: i18n.t('generics.sponsored'),
  'mixed': 'Mixed',
}

export const applicationModesLabels = {
  [applicationModes.email]: i18n.t('generics.by-email'),
  [applicationModes.career]: i18n.t('generics.via-my-career-site'),
  [applicationModes.direct]: i18n.t('generics.directly-in-the-app'),
}

export const publicationStatus = {
  SCHEDULED: 'SCHEDULED',
  PENDING: 'PENDING',
  SENT: 'SENT',
  DELIVERED: 'DELIVERED',
  DISPLAYED: 'DISPLAYED',
  CLICKED: 'CLICKED',
  DEPUBLICATION_PENDING: 'DEPUBLICATION_PENDING',
  DEPUBLICATION_SENT: 'DEPUBLICATION_SENT',
  DEPUBLICATION_DELIVERED: 'DEPUBLICATION_DELIVERED',
}

export const publishedStatus = [publicationStatus.PENDING, publicationStatus.SENT, publicationStatus.DELIVERED, publicationStatus.DISPLAYED, publicationStatus.CLICKED];

export const publicationStatusLabels = {
  [publicationStatus.SCHEDULED]: 'SCHEDULED',
  [publicationStatus.PENDING]: 'PENDING',
  [publicationStatus.SENT]: 'SENT',
  [publicationStatus.DELIVERED]: 'DELIVERED',
  [publicationStatus.DISPLAYED]: 'DISPLAYED',
  [publicationStatus.CLICKED]: 'CLICKED',
  [publicationStatus.DEPUBLICATION_PENDING]: 'DEPUBLICATION_PENDING',
  [publicationStatus.DEPUBLICATION_SENT]: 'DEPUBLICATION_SENT',
  [publicationStatus.DEPUBLICATION_DELIVERED]: 'DEPUBLICATION_DELIVERED',
}

export const checkOfficeId = ({ officeId, organizationId }) => {
  return http.get(`/integrations/xtramile/check-office`, {
    params: {
      officeId,
      organizationId,
    }
  });
}

export const getOfficeId = (officeId) => {
  return http.get(`/integrations/xtramile/office`, {
    params: {
      officeId,
    }
  });
}

export const getChannels = () => {
  return http.get(`/integrations/xtramile/channels`);
}

export const getActivatedChannels = () => {
  return http.get(`/integrations/xtramile/activated-channels`);
}

export const getActivatedChannelsSubscriptions = () => {
  return http.get(`/integrations/xtramile/activated-channels-subscriptions`);
}

export const oauthToken = (officeId) => {
  return http.post('/integrations/xtramile/oauth/token', {
    officeId,
  });
};

export const activate = ({ id, activated, organizationId }) => {
  return http.patch('/integrations/xtramile/activate', {
    id,
    activated,
    organizationId,
  });
}

export const assignCredits = ({ credits, organizationId }) => {
  return http.patch('/integrations/xtramile/assign-credits', {
    credits,
    organizationId,
  });
}

export const getChannelSubscriptions = ({ organizationId, channelId }) => {
  return http.get('/integrations/xtramile/channel-subscriptions', {
    params: {
      organizationId,
      channelId,
    }
  });
}

export const addChannelSubscriptions = ({ organizationId, channelId, channelSubscriptionId, applicationForwardingType, applicationMode, subscriptionModel, accountParams }) => {
  return http.post('/integrations/xtramile/channel-subscriptions', {
    organizationId,
    channelId,
    channelSubscriptionId,
    applicationMode,
    applicationForwardingType,
    subscriptionModel,
    accountParams,
  });
}

export const deleteChannelSubscriptions = ({ channelSubscriptionId, organizationId }) => {
  return http.delete('/integrations/xtramile/channel-subscriptions', {
    params: {
      channelSubscriptionId,
      organizationId,
    }
  });
}

export const getPublicationsStatus = ({ customJobId }) => {
  return http.get('/integrations/xtramile/status', {
    params: {
      customJobId,
    }
  });
}

export const publish = ({ customJobId, channelId, channelSubscriptionId }) => {
  return http.post('/integrations/xtramile/publish', {
    customJobId,
    channelId,
    channelSubscriptionId,
  });
}

export const unpublish = ({ publicationId, customJobId, channelId, channelSubscriptionId }) => {
  return http.delete(`/integrations/xtramile/publication`, {
    params: {
      publicationId,
      customJobId,
      channelId,
      channelSubscriptionId,
    }
  });
}

export const updateJob = ({ customJobId, fields, connectorName }) => {
  return http.patch(`/integrations/xtramile/job`, {
    customJobId,
    fields,
    connectorName,
  });
}

export const testCandidature = (params) => {
  return http.post('/integrations/xtramile/apply', params);
}

export default {
  getOfficeId,
  checkOfficeId,
  assignCredits,
  oauthToken,
  getChannels,
  getActivatedChannels,
  getActivatedChannelsSubscriptions,
  activate,
  addChannelSubscriptions,
  getChannelSubscriptions,
  deleteChannelSubscriptions,
  getPublicationsStatus,
  publish,
  unpublish,
  updateJob,
  testCandidature,
};
