import favoriteLists from '@/api/favoriteLists';

export default {
  actions: {
    async addCoderToCoachedList({ commit, dispatch, rootState }, { listId, coderId, cardIndex }) {
      const response = await favoriteLists.addCoderToFavoriteList({ listId, coderId });

      if (cardIndex > -1) {
        commit('updateCard', {
          index: cardIndex,
          content: response.data
        });
      }

      if (rootState.route.name.includes('Inbox')) {
        commit('setTimer', setTimeout(() => {
          commit('removeCard', coderId);

          dispatch('user/updateMetadataByKey', {
            key: 'newCoders',
            value: rootState.user.metadata.newCoders - 1
          });
        }, 25));
      }

      return response;
    },
    async addCoderToCoachedListFromPanel({ commit }, { listId, coderId, cardIndex }) {
      const response = await favoriteLists.addCoderToFavoriteList({ listId, coderId });

      commit('updateValue', {
        storeName: 'panel',
        stateName: 'profile',
        data: response.data
      });

      if (cardIndex > -1) {
        commit('updateCard', {
          index: cardIndex,
          content: response.data
        });
      }

      return response;
    },
    async addCoderToIgnoredList({ commit, rootState, dispatch }, { listId, coderId, cardIndex }) {
      const response = await favoriteLists.addCoderToFavoriteList({ listId, coderId });

      if (cardIndex > -1) {
        commit('updateCard', {
          index: cardIndex,
          content: { ...response.data, isIgnored: true }
        });
      }

      if (rootState.route.name.includes('CandidatePipe')) {
        commit('setTimer', setTimeout(() => {
          commit('removeCard', coderId);
          dispatch('user/updateMetadataByKey', {
            key: 'newCoders',
            value: rootState.user.metadata.newCoders - 1
          });
        }, 25));
      }

      return response;
    },
    async addCoderToIgnoredListFromPanel({ commit }, { listId, coderId, cardIndex }) {
      const response = await favoriteLists.addCoderToFavoriteList({ listId, coderId });

      commit('updateValue', {
        storeName: 'panel',
        stateName: 'profile',
        data: response.data
      });

      if (cardIndex > -1) {
        commit('updateCard', {
          index: cardIndex,
          content: response.data
        });
      }

      return response;
    },
    async deleteCoderFromCoachedListFromPanel({ commit }, { listId, coderId, cardIndex }) {
      const response = await favoriteLists.removeCoderFromFavoriteList({ listId, coderId });

      commit('updateValue', {
        storeName: 'panel',
        stateName: 'profile',
        data: response.data
      });

      if (cardIndex > -1) {
        commit('updateCard', {
          index: cardIndex,
          content: response.data
        });
      }
    },
    async removeAllPendingCardsFromInbox({ commit, dispatch, rootState }, pendingCards) {
      return new Promise(resolve => {
        commit('setTimer', setTimeout(() => {
          pendingCards.forEach(id => {
            commit('removeCard', id);

            dispatch('user/updateMetadataByKey', {
              key: 'newCoders',
              value: rootState.user.metadata.newCoders - 1
            });
          });
          resolve();
        }, 25));
      });
    },
    async removePendingCardFromInbox({ commit, dispatch, rootState }, id) {
      return new Promise(resolve => {
        commit('setTimer', setTimeout(() => {
          commit('removeCard', id);

          dispatch('user/updateMetadataByKey', {
            key: 'newCoders',
            value: rootState.user.metadata.newCoders - 1
          });
          resolve();
        }, 25));
      });
    }
  }
};
