<template>
  <div class="panel-chat-messages-list">
    <div
      v-for="(message, index) in messages"
      :key="message._id"
      class="panel-chat-messages-list__container">
      <!-- mettre le composant de diff ici -->
      <panel-chat-messages-date
        v-if="(index === 0) || shouldDisplayDate(message.createdAt, messages[index - 1].createdAt)"
        :date="message.createdAt"
        class="panel-chat-messages-list__date">
      </panel-chat-messages-date>
      <!-- mettre l'item ici -->
      <panel-chat-messages-item
        :index="index"
        :message="message"
        :next-message="messages[index + 1]"
        :previous-message="messages[index - 1]"
        :profile-id="profileId">
      </panel-chat-messages-item>
    </div>
  </div>
</template>

<script>
  import PanelChatMessagesItem from '@/components/Panel/PanelChat/PanelChatMessages/PanelChatMessagesItem';
  import PanelChatMessagesDate from '@/components/Panel/PanelChat/PanelChatMessages/PanelChatMessagesDate';
  import moment from 'moment/min/moment-with-locales';
  //
  import { mapState } from 'vuex';

  export default {
    name: 'panel-chat-messages-list',
    components: { PanelChatMessagesDate, PanelChatMessagesItem },
    props: {
      messages: {
        type: Array
      }
    },
    computed: {
      ...mapState({
        profileId: state => state.user.profile._id
      })
    },
    methods: {
      shouldDisplayDate(current, prev) {
        const limit = 60;
        const a = moment(current);
        const b = moment(prev);

        return a.diff(b, 'minutes') >= limit;
      }
    }
  };
</script>

<style scoped lang="scss">
  .panel-chat-messages-list {
    width: 100%;
    flex-direction: column;
    &__container {
      flex-direction: column;
    }
    &__date {
      margin-bottom: 20px;
      justify-content: center;
    }
  }
</style>
