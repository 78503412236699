<template>
  <div class="panel-company-header-prospection-status-rpo">
    <mv-select
      :backgroundColor="(prospectionStatusCategoryColor[prospectingStatusCategory] || {}).backgroundColor"
      :color="(prospectionStatusCategoryColor[prospectingStatusCategory] || {}).color"
      :is-absolute="!!isPanel"
      :items="items"
      :selected-label="prospectionStatusText"
      :value="prospectingStatus"
      class="panel-company-header-prospection-status-rpo__select"
      @select="handleSelect"
    ></mv-select>
  </div>
</template>

<script>
  import MvSelect from '@/ui-kit/components/MvSelect/MvSelect';
  import { apiRoles } from '@/macros/coaches/roles';
  import {
    getProspectingStatusCategory,
    prospectionStatusCategoryColor,
  } from '@/macros/companies/prospectionStatus';
  import { mapGetters } from 'vuex';

  export default {
    name: 'panel-company-header-prospection-status-rpo',
    components: {
      MvSelect,
    },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        prospectionStatusCategoryColor,
      };
    },
    computed: {
      ...mapGetters('user', ['configurationProspection']),
      isPanel() {
        return this.$route.params?.id;
      },
      items() {
        const items = [];
        this.configurationProspection?.categories?.forEach(category => {
          category?.steps?.forEach(step => {
            items.push({
              value: step.key,
              label: step.name,
              category: category.key,
            });
          });
        });
        return items;
      },
      user() {
        return this.$store.state.user.profile || {};
      },
      prospectingStatus() {
        return this.profile?.customStatus?.id;
      },
      prospectingStatusCategory() {
        return getProspectingStatusCategory(this.configurationProspection?.categories, this.prospectingStatus);
      },
      prospectionStatusText() {
        return this.items.find(it => it.value === this.prospectingStatus)?.label;
      },
      isUserBusinessManager() {
        return this.user.role === apiRoles.businessManager;
      },
    },
    methods: {
      handleSelect(status, onClick) {
        if (onClick) {
          onClick();
        } else {
          this.updateCompanyProspectingStatus(status);
        }
      },
      async updateCompanyProspectingStatus(status) {
        try {
          await this.$store.dispatch('setPartnerProspectingStatus', {
            partnerId: this.profile._id,
            status,
          });

          await this.$store.dispatch('updateCardFromId', {
            id: this.profile._id,
            content: {
              customStatus: {
                ...this.profile.customStatus,
                id: status,
              },
            },
          });

          this.$emit('profile-updated', { customStatus: status });
        } catch (error) {
          this.$toast.show({
            message: this.$t('toast.error-occured'),
            type: 'error',
            icon: 'cross',
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-company-header-prospection-status-rpo {
    &__select {
      width: 188px;

      &:deep() {
        .mv-select__btn {
          border-radius: 30px;
        }
      }
    }

    &__dropdown {
      height: 100%;

      &:disabled {
        cursor: not-allowed;
      }

      &:before {
        position: absolute;
        right: 10px;
      }
    }

    &__trigger {
      width: 180px;
      background: $color-secondary;
      color: $color-white;
      flex-grow: 1;

      &:before {
        position: absolute;
        right: 10px;
      }
    }

    &__item {
      padding: 15px 10px;
      cursor: pointer;
      border-bottom: 1px solid rgba($color-primary, 0.1);

      &.is-error {
        &:hover {
          background: rgba($color-error, 0.25);
        }

        :deep() {
          p {
            color: $color-error;
          }
        }
      }
    }
  }
</style>
