import { unabortProcessCustom as unabortProcessCustomCall } from '@/api/processes-custom';

export const unabortProcessCustom = ({ id }: { id : string }) => {
    try {
        return unabortProcessCustomCall(id);
    } catch (error) {
        if (error?.response) {
            throw new Error(`API: ${error.response.data.message}`);
        }
        throw new Error(error);
    }
};
