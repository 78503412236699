import { http } from '@/api/index';

export const getContactProfile = id => {
  return http.get(`/contacts/${id}`);
};

export const updateContact = ({ id, obj }) => {
  return http.patch(`/contacts/${id}`, obj);
};

export const updateCertif = ({id, data}) => {
  return http.patch(`/contacts/${id}/certif`, data);
}

export const updateResponsibleBilling = ({id, data}) => {
  return http.patch(`/contacts/${id}/responsible-billing`, data);
}

export const deleteContact = (id) => {
  return http.delete(`/contacts/${id}`);
};

export const createContact = ({ id, obj }) => {
  return http.post(`partners/${id}/contacts`, obj);
};

export const inviteContact = ({ id, email }) => {
  return http.post(`contacts/${id}/invite`, { email });
};


export default {
  getContactProfile,
  updateContact,
  createContact,
  deleteContact,
  inviteContact,
};
