import { typeOptions } from '@/macros/contracts/types';

const getContractTypeQueryFromTypeValue = type => typeOptions.find(t => t.api === type).query;

export const serializeContract = contract => {
  const serializeMap = {
    type: getContractTypeQueryFromTypeValue,
  };

  const formattedContract = { ...contract };

  Object.keys(serializeMap).forEach(key => {
    if (formattedContract[key]) {
      formattedContract[key] = serializeMap[key](formattedContract[key]);
    }
  });

  return formattedContract;
};
