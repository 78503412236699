<template>
  <modal-feature
    feature="career"
    :title="$t('features.create-career-website')"
    @close-modal="$emit('close-modal')"
  >
    <template #content>
      <p>{{ $t('features.career-personalize') }}</p>
    </template>
    <template #logo>
      <img src="@/assets/logo/feature-career.png" alt="career feature logo" />
    </template>
  </modal-feature>
</template>

<script>
import ModalFeature from './ModalFeature.vue';

export default {
  name: 'modal-feature-career',
  components: {
    ModalFeature,
  },
}
</script>
