<template>
  <div
    v-click-outside="manageClosePanel"
    class="panel-tag-replace"
    @click.stop>
    <div
      class="panel-tag-replace-header bg-neutral-200 is-full-width is-justify-content-space-between is-align-items-center">
      <panel-header-nav></panel-header-nav>
      <div>{{ $t("generics.replace") }}</div>
      <bc-spinner
        v-if="isValidateLoading"
        class="panel-tag-replace-header__validate-spinner">
      </bc-spinner>
      <bc-button
        radius-size="s"
        size="extra-small"
        color="primary"
        :disabled="isValidateLoading"
        @click="acceptTagReplace()">
        {{ $t('generics.validate') }}
      </bc-button>
    </div>
    <div class="panel-tag-replace__label">
      {{ $t("tags.replace-this-tag-present-on-profiles-and-jobs") }}
    </div>
    <div class="panel-tag-replace__card-content">
      <div class="panel-tag-replace__card-content-list">
        <div
          v-for="(itemLink, indexLink) in originsTags"
          :key="indexLink">
          <div>{{ itemLink.name }}</div>
          <div>{{ `Profils : ${itemLink.count.coders}` }}</div>
          <div>{{ `Jobs : ${itemLink.count.jobs}` }}</div>
        </div>
      </div>
    </div>
    <div class="panel-tag-replace__label">
      {{ $t("generics.by") }}
    </div>
    <div
      class="panel-tag-replace-container">
      <div class="panel-tag-replace-container__wrapper">
        <panel-tag-autocomplete
          :selected="targetTag"
          :showLabel="false"
          :tagTypeSlug="tagTypeSlug"
          :organization="organization"
          @select-tags="handleSelectTargetTag"
        ></panel-tag-autocomplete>
      </div>
    </div>
  </div>
</template>

<script>
  import { getTagById, replaceTag, deleteTag } from '@/api/tags';
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav';
  import PanelTagAutocomplete from '@/components/Panel/PanelTag/PanelTagAutocomplete';
  import { mapState } from 'vuex';

  export default {
    name: 'panel-tag-replace',
    components: {
      PanelHeaderNav,
      PanelTagAutocomplete,
      BcButton,
      BcSpinner,
    },
    mixins: [PanelNavigation],
    data() {
      return {
        tag: {},
        isLoading: true,
        isValidateLoading: false,
        targetTag: null,
        tagTypeId: null,
        organization: null,
        originsTags: []
      };
    },
    computed: {
      ...mapState({
        tagTypes: state => state.tags.tagTypes,
      }),
      tagTypeSlug() {
        return this.tagTypes.find(t => t._id === this.tagTypeId)?.slug;
      },
    },
    methods: {
      async getOriginsTags() {
        try {
          // Get all origins tags
          const originsTags = await Promise.all([...this.$route.query.origins.map((id) => getTagById(id))]);

          this.originsTags = originsTags.map(item => item.data);

          const organizations = this.originsTags.map(item => item._organization);
          const organizationsArray = Array.from(new Set(organizations));
          if (organizationsArray.length > 1) {
            this.$toast.show({
              title: 'Accès refusé',
              message: 'Les tags doivent être de la même organisation',
              type: 'error',
              icon: 'cross',
            });
            this.isLoading = false;
            this.closePanel();
            return;
          } else {
            this.organization = organizationsArray[0];
          }

          // Check same tag type
          const tagTypesArray = Array.from(new Set(this.originsTags.map(item => item._tagType)));
          if (tagTypesArray.length > 1) {
            this.$toast.show({
              title: 'Accès refusé',
              message: 'Les tags doivent être du même type',
              type: 'error',
              icon: 'cross',
            });
            this.isLoading = false;
            this.closePanel();
            return;
          } else {
            this.tagTypeId = tagTypesArray[0];
          }

          this.isLoading = false;
        } catch (error) {
          this.$toast.show({
            title: 'Accès refusé',
            message: 'Seul les utilisateurs avec le role god sont autorisés à accéder à la ressource',
            type: 'error',
            icon: 'cross',
          });
          this.isLoading = false;
        }
      },
      handleSelectTargetTag(tag) {
        this.targetTag = [tag[tag.length - 1]];
      },
      async acceptTagReplace() {
        // Display loading
        this.isValidateLoading = true;

        const originTags = this.originsTags.map(origin => origin._id);
        const targetTag = this.targetTag[0]._id;

        if (originTags.includes(targetTag)) {
          this.$toast.show({
            type: 'error',
            message: "Le tag ne peut pas être remplacé par lui-même",
          });

          this.closePanel();
        }

        // Replace tag
        await replaceTag({
          origin: this.originsTags.map(origin => origin._id).join(","),
          target: this.targetTag[0]._id,
          tagTypeSlug: this.tagTypeSlug,
        });

        // Delete origins tags
        const promises = this.originsTags.map(origin => deleteTag(origin._id));
        await Promise.all(promises);

        // Display success message
        this.$toast.show({
          type: 'success',
          message: this.$t('tags.the-tag-has-been-replaced-successfully'),
        });

        this.closePanel();
      },
    },
    watch: {
      $route: {
        handler() {
          this.getOriginsTags();
        },
        immediate: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-tag-replace {
    height: 100%;
    flex-direction: column;
    background-color: white;

    &__label {
      margin: 40px 20px 0px 20px;
      font-weight: 500;
      font-size: 14px;
    }

    &__card-content {
      width: 100%;
      padding: 20px 10px;
      display: flex;
      flex-direction: column;

      &-list {
        display: flex;
        flex-direction: column;
        padding: 0px 10px !important;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
      }

      &-list > div {
        margin: 5px 0px;
        padding: 10px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid lightgrey;
        border-radius: 5px;
      }
    }
  }

  .panel-tag-replace-header {
    padding: 10px 15px;
    flex-shrink: 0;
    border-bottom: 1px solid $color-grey-4;
    height: 50px;

    &__validate-spinner {
      width: 30px;
    }
  }

  .panel-tag-replace-container {
    margin: 20px;

    &__wrapper {
      width: 100%;

      > div {
        width: 100%;
      }
    }
  }
</style>
