<template>
  <transition
    name="fade-translate"
    mode="out-in">
    <slot></slot>
  </transition>
</template>

<script>
  export default {
    name: 'transition-fade-translate-up'
  };
</script>

<style lang="scss" scoped>
  .fade-translate-enter-active,
  .fade-translate-leave-active {
    transition: opacity 300ms ease-in-out, transform 300ms ease;
  }

  .fade-translate-enter,
  .fade-translate-leave-to {
    opacity: 0;
    transform: translateY(-10px);
  }
</style>
