export default {
  methods: {
    formatString(string = '') {
      return string
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getTextHint(text, search) {
      return `${
        this.splitHighlightValue(text, search)[0]
      }<span class="search-input-match">${
        this.splitHighlightValue(text, search)[1]
      }</span>${this.splitHighlightValue(text, search)[2]}`;
    },
    splitHighlightValue(name, search) {
      const startHighlight = this.formatString(name).indexOf(
        this.formatString(search)
      );
      const endHighlight = startHighlight + search.length;

      if (startHighlight === -1) {
        return [name, '', ''];
      }

      return [
        name.substring(0, startHighlight),
        name.substring(startHighlight, endHighlight),
        name.substring(endHighlight),
      ];
    },
  }
}
