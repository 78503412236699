<template>
  <select-container :label="$t('companies.account-management.contract-duration')" icon="file">
    <bc-dropdown
      class="custom-jobs-contract-duration-select__dropdown"
      :id="$attrs.id"
      :is-scrollable="true"
      :limit="3"
      :options="contractsDurationOptions"
      is-expanded>
      <template #label="{ isDropdownOpen }">
        <bc-dropdown-trigger
          type="button"
          :is-arrow="true"
          :is-dropdown-open="isDropdownOpen"
          :text="selectedContractDuration.label">
        </bc-dropdown-trigger>
      </template>
      <template #option="{ activeIndex, index, option }">
        <bc-dropdown-item
          :is-focus="activeIndex === index"
          :text="option.label"
          @click.native="selectContractDuration(option, index)">
        </bc-dropdown-item>
      </template>
    </bc-dropdown>
  </select-container>
</template>

<script>
import { contractDurationLabels } from '@/api/custom-jobs';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
import SelectContainer from '@/components/Select/SelectContainer';

export default {
  name: 'custom-jobs-contract-duration-select',
  components: {
    BcDropdown,
    BcDropdownItem,
    BcDropdownTrigger,
    SelectContainer,
  },
  props: {
    selected: {
      type: [Object, Number],
    },
  },
  mounted() {
    if (this.selected) {
      this.selectedContractDuration = this.handleSelected(this.selected);
    } else {
      this.selectedContractDuration = this.contractsDurationOptions[0];
    }
  },
  data() {
    return {
      selectedContractDuration: {},
      contractDurationLabels,
    };
  },
  computed: {
    contractsDurationOptions() {
      return Object.entries(this.contractDurationLabels).map(([apiCode, label]) => ({
        apiCode,
        label,
      }));
    },
  },
  methods: {
    selectContractDuration(option) {
      this.$emit('select-contract-duration', option);
      this.selectedContractDuration = option;
    },
    mapSelectedContract(value) {
      return this.contractsDurationOptions.find(contract => +contract.apiCode === +value) || {};
    },
    handleSelected(value) {
      return Number.isNaN(Number(value)) ? value : this.mapSelectedContract(value);
    },
  },
  watch: {
    selected(newValue) {
      this.selectedContractDuration = this.handleSelected(newValue);
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-jobs-contract-duration-select {
  &__dropdown {
    width: 100%;

    :deep() {
      .bc-dropdown-trigger {
        height: auto;
      }
    }
  }
}
</style>
