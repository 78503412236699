import processes from '@/api/processes';

export const updateProcessPayment = (id) => {
  try {
    return processes.updateProcessPayment({ id });
  } catch (error) {
    if ((error || {}).response) {
      throw new Error(`API: ${error.response.data.message}`);
    }

    throw new Error(error);
  }
};

export default {
  updateProcessPayment
};
