<template>
  <views>
    <views-tabs
      :tabs="tabs"
    >
      <template #content>
        <bc-dropdown
          :options="coachesOptions"
          class="panel-view-timeline__dropdown">
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="selectedUsers"
              :is-filter="true"
              icon="user">
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              :title="option.name"
              class="panel-view-timeline__dropdown"
              @click.native="setSelectedUser(option)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
      </template>
    </views-tabs>
    <views-container>
      <timeline-list
        :is-editable="false"
        :timeline="timeline"
        class="panel-view-timeline__list is-full-width"></timeline-list>
      <infinite-loading
        :distance="400"
        :identifier="timelineIdentifier"
        class="timeline__infinite-scroll is-full-width is-justify-content-center"
        @infinite="getTimeline"
      >
        <bc-spinner slot="spinner" size="large"></bc-spinner>
        <p slot="no-more" class="timeline__text font-size-m is-secondary-dark"></p>
        <p slot="no-results" class="timeline__text font-size-m is-secondary-dark">
          {{ $t('timeline.no-result') }}
        </p>
        <p slot="error" class="timeline__text font-size-m is-secondary-dark">
          {{ $t('timeline.error-during-request') }}
        </p>
      </infinite-loading>
    </views-container>
    <router-view></router-view>
  </views>
</template>

<script>
import TimelineList from '@/components/Timeline/TimelineList';
import { getUserTimeline } from '@/api/timeline';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import InfiniteLoading from 'vue-infinite-loading';
import Views from '@/components/Views/Views';
import ViewsTabs from '@/components/Views/ViewsTabs';
import ViewsContainer from '@/components/Views/ViewsContainer';
import BcDropdown from '@/ui-kit/components/BcDropdown';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
import { getFilterablePoolCoach } from '@/macros/prospectionPipe/dropdowns';

export default {
  name: 'history-actions',
  components: {
    ViewsContainer,
    Views,
    ViewsTabs,
    BcSpinner,
    TimelineList,
    BcDropdownTrigger,
    BcDropdownItem,
    BcDropdown,
    InfiniteLoading,
  },
  data() {
    return {
      timeline: [],
      timelinePage: 1,
      timelineIdentifier: +new Date(),
      categories: [
        {
          name: this.$i18n.t('activities.history-of-my-actions-cap'),
          url: 'my-notes',
        },
      ],
      clicked: false,
    };
  },
  computed: {
    tabs() {
      return [
        {
          key: 'history-actions',
          label: this.$i18n.t('activities.history-of-my-actions-cap'),
          to: { path: '/history-actions', query: { id: this.profileId } },
        },
      ]
    },
    isSuperUser() {
      return this.$store.getters['user/isSuperUser'];
    },
    profileId() {
      return this.$store.state.user.profile._id
    },
    coaches() {
      return this.$store.state.coaches.coaches
    },
    coachesOptions() {
      const coaches = this.coaches.filter(coach => getFilterablePoolCoach(coach))
      .map(coach => ({
        name: `${coach.firstName} ${coach.lastName}`,
        query: coach._id,
      }));
      return [
        ...coaches.sort((a, b) => a.name.localeCompare(b.name)),
      ];
    },
    selectedUser() {
      return this.$route.query['selected-user-id']
        ? this.coaches.find(
            coach => coach._id === this.$route.query['selected-user-id']
          )
        : this.coaches.find(coach => coach._id === this.$route.query['id']);
    },
    selectedUsers() {
      return `${this.selectedUser?.firstName} ${this.selectedUser?.lastName}`;
    },
    currentUserId() {
      return this.$route.query['selected-user-id']
        ? this.$route.query['selected-user-id']
        : this.profileId;
    },
  },

  watch: {
    $route: {
      handler() {
        this.resetInfiniteScroll();
      },
      immediate: true,
    },
  },
  methods: {
    //recuperer l'id via le store
    async getTimeline($state) {
      try {
        const {
          data: { timeline, next },
        } = await getUserTimeline({
          userType: 'coaches',
          id: this.currentUserId,
          page: this.timelinePage,
          ...((this.$route.query['coaches-activities'] || !this.isSuperUser) && {
            coachesActivities: this.$route.query['coaches-activities'],
          }),
          ...((this.$route.query['users-activities'] || !this.isSuperUser) && {
            usersActivities: this.$route.query['users-activities'],
          }),
          ...((this.$route.query['processes-activities'] || !this.isSuperUser) && {
            processesActivities: this.$route.query['processes-activities'],
          }),
        });

        this.timeline = [...this.timeline, ...timeline];

        if (next) {
          this.incrementPage();

          $state.loaded();
        } else {
          $state.loaded();

          $state.complete();
        }
      } catch (error) {
        if (error.message !== 'Operation canceled due to new request.') {
          $state.error();
        }
      }
    },

    incrementPage() {
      ++this.timelinePage;
    },
    resetInfiniteScroll() {
      this.timeline = [];
      this.timelinePage = 1;
      this.timelineIdentifier += 1;
    },
    setTimelineFilters(query) {
      const queryState =
        !this.$route.query[query] || this.$route.query[query] === 'false' ? 'true' : undefined;

      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          [query]: queryState,
          ...(query !== 'all-activities' &&
            this.$route.query['all-activities'] && { ['all-activities']: queryState }),
        },
      });

      if (query === 'all-activities') {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            ['coaches-activities']: this.$route.query['all-activities'],
            ['users-activities']: this.$route.query['all-activities'],
            ['processes-activities']: this.$route.query['all-activities'],
          },
        });
      }
    },

    loadTimeline(timeline) {
      this.timeline = timeline;
    },

    setSelectedUser(user) {
      this.$router.push({
        query: {
          ...this.$route.query,
          'selected-user-id': user.query,
        },
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.panel-view-timeline {
  padding: 15px 30px;

  div:first-child {
    button.bc-button__primary:nth-child(1) {
      margin-right: 8px;
    }
  }

  &__container {
    padding-left: 55px;
  }

  &__list {
    padding: 0;
  }

  &__wrapper {
    margin-left: auto;
    background: $color-grey-2;
    border: 1px solid $color-grey-2;
  }

  &__dropdown {
    width: 180px;
    margin-right: 10px;

      &--large {
        min-width: 210px;
      }

      &-item {
        width: 100%;
      }

      &:last-of-type {
        margin-right: 0;
      }
  }

  &__button {
    padding: 12px 15px;
    color: $color-grey-4;
    transition: 0.1s;

    &:hover {
      background: $color-grey-3;
    }

    &--active,
    &--active:hover {
      color: $color-secondary;
      background: $color-white;
    }

    &:first-of-type {
      border-top-left-radius: $border-radius-s;
      border-bottom-left-radius: $border-radius-s;
    }

    &:last-of-type {
      border-top-right-radius: $border-radius-s;
      border-bottom-right-radius: $border-radius-s;
    }
  }
}
</style>
