const routeNames = {
  Onboarding: 'Onboarding',
  Dashboard: 'Dashboard',
  HistoryActions: 'HistoryActions',
  CandidatePipe: 'CandidatePipe',
  CandidatesReminders: 'CandidatesReminders',
  ContactsReminders: 'ContactsReminders',
  Coders: 'Coders',
  Contacts: 'Contacts',
  Partners: 'Partners',
  AccountManagement: 'AccountManagement',
  Auth: 'Auth',
  Technos: 'Technos',
  Values: 'Values',
  Jobs: 'Jobs',
  FavoriteLists: 'FavoriteLists',
  FavoriteListsCoders: 'FavoriteListsCoders',
  FavoriteList: 'FavoriteList',
  Inbox: 'Inbox',
  Configuration: 'Configuration',
  Timeline: 'Timeline',
  Pipe: 'Pipe',
  LogAs: 'LogAs',
  Organizations: 'Organizations',
  Industries: 'Industries',
  IconsView: 'IconsView',
  ProspectionPipe: 'ProspectionPipe',
  CustomJobs: 'CustomJobs',
  Statistics: 'ProcessesStatistics',
  Campaigns: 'Campaigns',
}

export default routeNames;
