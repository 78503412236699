<template>
  <div class="panel-job-edit-remote col-xs-12">
    <title-section
      class="panel-job-edit-remote__title"
      position="left"
      :title="$t('panel-coder-edit.remote')">
    </title-section>
    <div class="panel-job-edit-remote__container">
      <div class="panel-job-edit-remote__item">
        <bc-checkbox
          bc-checkbox-border-color="blue-dark-cello"
          bc-checkbox-border-radius="5px"
          bc-checkbox-check-color="blue-dark-cello"
          bc-label-color="blue-dark-cello"
          :label="$t('panel-coder-edit.possible-remote')"
          v-model="available">
        </bc-checkbox>
        <div
          class="panel-job-edit-remote__icon"
          v-tooltip.right="$i18n.t('panel-coder-edit.choose-days')">
          i
        </div>
      </div>
      <div class="panel-job-edit-remote__item">
        <bc-checkbox
          bc-checkbox-border-color="blue-dark-cello"
          bc-checkbox-border-radius="5px"
          bc-checkbox-check-color="blue-dark-cello"
          bc-label-color="blue-dark-cello"
          :label="$t('panel-coder-edit.partial-remote')"
          v-model="partial">
        </bc-checkbox>
        <div
          class="panel-job-edit-remote__icon"
          v-tooltip.right="$i18n.t('panel-coder-edit.predefined-days')">
          i
        </div>
      </div>
      <div class="panel-job-edit-remote__item">
        <bc-checkbox
          bc-checkbox-border-color="blue-dark-cello"
          bc-checkbox-border-radius="5px"
          bc-checkbox-check-color="blue-dark-cello"
          bc-label-color="blue-dark-cello"
          :label="$t('panel-coder-edit.full-remote')"
          v-model="full">
        </bc-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleSection from '@/components/Title/TitleSection';
  import BcCheckbox from '@/legacy/ui-kit/components/Checkbox/BcCheckbox';
  import { apiRemote } from '@/common-old/macros/remote';

  export default {
    name: 'panel-job-edit-remote',
    components: {
      TitleSection,
      BcCheckbox
    },
    props: {
      profile: {
        type: Object,
        default: () => ({})
      },
      editedProfile: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      available: {
        get() {
          const profile = this.editedProfile.remote !== undefined ? this.editedProfile : this.profile;

          return (profile.remote || []).some(value => value === apiRemote.available);
        },
        set(value) {
          const referenceProfile = Array.isArray(this.editedProfile.remote) ? this.editedProfile : this.profile;

          if (value) {
            this.$emit('update-value', {
              remote: [...(referenceProfile.remote || []), apiRemote.available]
            });
          } else {
            this.$emit('update-value', {
              remote: (referenceProfile.remote || []).filter(value => value && value !== apiRemote.available)
            });
          }
        }
      },
      partial: {
        get() {
          const profile = this.editedProfile.remote !== undefined ? this.editedProfile : this.profile;

          return (profile.remote || []).some(value => value === apiRemote.partial);
        },
        set(value) {
          const referenceProfile = Array.isArray(this.editedProfile.remote) ? this.editedProfile : this.profile;

          if (value) {
            this.$emit('update-value', {
              remote: [...(referenceProfile.remote || []), apiRemote.partial]
            });
          } else {
            this.$emit('update-value', {
              remote: (referenceProfile.remote || []).filter(value => value && value !== apiRemote.partial)
            });
          }
        }
      },
      full: {
        get() {
          const profile = this.editedProfile.remote !== undefined ? this.editedProfile : this.profile;

          return (profile.remote || []).some(value => value === apiRemote.full);
        },
        set(value) {
          const referenceProfile = Array.isArray(this.editedProfile.remote) ? this.editedProfile : this.profile;

          if (value) {
            this.$emit('update-value', {
              remote: [...(referenceProfile.remote || []), apiRemote.full]
            });
          } else {
            this.$emit('update-value', {
              remote: (referenceProfile.remote || []).filter(value => value && value !== apiRemote.full)
            });
          }
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .panel-job-edit-remote {
    flex-direction: column;

    &__title {
      margin-bottom: $panel-title-margin-bottom;
    }

    &__text {
      font-size: $font-size-m;
      color: $color-blue-dark-cello;
      text-align: left;
      margin-right: 15px;
    }

    &__item {
      margin-right: 25px;
    }

    &__icon {
      color: $color-blue-dark-cello;
      margin-left: 5px;
      height: 20px;
      width: 20px;
      justify-content: center;
      align-items: center;
      border: 1px solid $color-blue-dark-cello;
      font-size: 12px;
      border-radius: 50%;

      &:hover {
        background: $color-blue-dark-cello;
        color: $color-white;
        cursor: pointer;
      }
    }
  }
</style>
