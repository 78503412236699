<template>
  <div class="configuration-team-topbar">
    <!-- <bc-button
      class="font-weight-medium ml-auto"
      icon-left="candidates-plus"
      :icon-right="!configurationFeatures.addTeamMember ? 'lock' : ''"
      @click="handleCreateUser">
      {{ $t('avatar-dropdown.create-an-user') }}
    </bc-button> -->
    <bc-modal :active="isAddUserModalShown" @close="closeAddUserModal">
      <team-modal-add-user
        @close-modal="closeAddUserModal"
        @update-users-accounts="updateUserPlan"></team-modal-add-user>
    </bc-modal>
    <bc-modal :active="isPlanModalShown" @close="closePlanModal">
      <change-plan-confirmation-modal
        :nbUsers="nbUsers"
        @upgrade-plan="acceptNewPlan"></change-plan-confirmation-modal>
    </bc-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { Modals } from '@/store/modules/app';

import BcButton from '@/ui-kit/components/BcButton';
import BcModal from '@/ui-kit/components/BcModal';
import TeamModalAddUser from '@/components/Team/TeamModal/TeamModalAddUser';
import ChangePlanConfirmationModal from '@/components/Subscription/SubscriptionConfirmationModal/ChangePlanConfirmationModal';
import { inviteUser } from '@/api/user';

export default {
  name: 'configuration-team-topbar',
  components: {
    BcButton,
    BcModal,
    TeamModalAddUser,
    ChangePlanConfirmationModal,
  },
  data() {
    return {
      isLoaded: true,
      usersAccounts: [],
      firstName: null,
      lastName: null,
      email: null,
      organizationId: null,
      isAddUserModalShown: false,
      nbUsers: null,
      isPlanModalShown: false,
    };
  },
  computed: {
    ...mapState({
      // coaches: state => state.coaches.coaches,
      profile: state => state.user.profile,
      // cards: state => state.card.cards,
    }),
    ...mapGetters({
      configurationFeatures: 'user/configurationFeatures',
    })
  },
  methods: {
    ...mapActions({
      setModal: 'app/setModal',
    }),
    handleCreateUser() {
      if (!this.configurationFeatures.addTeamMember) {
        return this.setModal(Modals['modal-feature-add-team-member']);
      }
      this.isAddUserModalShown = true;
    },
    async updateUserPlan(data) {
      if (data) {
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email;
        this.organizationId = data.organizationId;
      }
      // NOW UNUSED
      // this.isPlanModalShown = true
      await this.acceptNewPlan();
    },
    async acceptNewPlan() {
      try {
        const data = await inviteUser({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          organizationId: this.profile._organization._id,
        });

        let newOrganization = this.profile._organization;
        newOrganization.nbUsers = this.nbUsers += 1;
        await this.$store.dispatch('user/updateProfile', {
          _organization: newOrganization,
        });
        await this.$store.dispatch('getCoaches');
        this.$toast.show({
          type: 'success',
          title: this.$i18n.t('toast.congratulations'),
          message: this.$i18n.t('toast.you-have-invited-a-new-user-to-your-space'),
        });
        this.closePlanModal();
        this.updateUserTeamList(data);
      } catch (error) {
        this.$toast.show({
          type: 'error',
          title: this.$i18n.t('toast.error-creating-user'),
          message: error.message ? error.message : this.$t('toast.error-occured'),
        });
      }
    },
    updateUserTeamList(data) {
      this.usersAccounts.push(data);
    },
    closeAddUserModal() {
      this.isAddUserModalShown = false;
    },
    closePlanModal() {
      this.isPlanModalShown = false;
    },
  },
};
</script>
