<template>
  <transition
    style="backdrop-filter: blur(16px);"
    :enter-active-class="transition.enter"
    :leave-active-class="transition.leave">
    <div
      :class="[type, position]"
      class="bc-toast"
      role="alert"
      v-show="isActive">
      <img
        v-if="type === 'notification'"
        class="bc-toast__marvin"
        alt="Marvin Logo"
        src="@/assets/logo/marvin.png"
      >
      <i
        v-else
        :class="[`icon-${toastIcon(type)}`]"
        class="bc-toast__icon is-flex is-align-items-center is-justify-content-center">
      </i>
      <div class="bc-toast__wrapper">
        <p class="bc-toast__title">
          {{ title }}
        </p>
        <p class="bc-toast__message">
          {{ message }}
        </p>
      </div>
      <div class="bc-toast__close-container">
        <i
          @click="close"
          class="bc-toast__close icon-cross">
        </i>
      </div>
      <div v-if="type !== 'notification'" class="bc-toast__timer" :style="{ width: `${progress}%` }"></div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'bc-toast',
    props: {
      type: {
        type: String,
        default: 'info',
        validator: value => [
          'info',
          'success',
          'error',
          'warning',
          'notification',
        ].indexOf(value) >= 0,
      },
      title: {
        type: String,
        default: '',
      },
      message: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        default: '',
      },
      queue: {
        type: Boolean,
        default: false,
      },
      duration: {
        type: Number,
        default: 2500,
      },
      position: {
        type: String,
        default: 'bottom-right',
        validator: value => [
          'top-right',
          'top',
          'top-left',
          'bottom-right',
          'bottom',
          'bottom-left',
        ].indexOf(value) > -1,
      },
    },
    data() {
      return {
        isActive: false,
        parentTop: null,
        parentBottom: null,
        fadeDown: {
          enter: 'fade-in-down',
          leave: 'fade-out-up',
        },
        fadeUp: {
          enter: 'fade-in-up',
          leave: 'fade-out-down',
        },
        defaultIcons: {
          info: 'alert-circle',
          success: 'check-circle',
          error: 'alert-circle',
          warning: 'alert-circle',
        },
        progress: 0,
        timer: undefined,
      };
    },
    computed: {
      transition() {
        return this.position.includes('top') ? this.fadeDown : this.fadeUp;
      },
      container() {
        return this.position.includes('top') ? this.parentTop : this.parentBottom;
      },
    },
    beforeMount() {
      this.setupContainer();
    },
    mounted() {
      this.showNotice();
      const totalDuration = this.duration / 100;
      let time = 1;
      this.timer = setInterval(() => {
        time = time + 1;
        const progress = (time / totalDuration) * 100;
        if (progress > 100) {
          this.progress = 100;
        } else {
          this.progress = progress;
        }
      }, 100)
    },
    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    methods: {
      shouldQueue() {
        if (!this.queue) {
          return false;
        }

        return (this.parentTop.childElementCount > 0 || this.parentBottom.childElementCount > 0);
      },
      close() {
        clearTimeout(this.timer);
        this.isActive = false;

        // Timeout for the animation complete before destroying
        setTimeout(() => {
          this.$destroy();
        }, 150);
      },
      showNotice() {
        if (this.shouldQueue()) {
          setTimeout(() => this.showNotice(), 250);
          return;
        }

        if (this.container.insertAdjacentElement) {
          this.container.insertAdjacentElement('afterbegin', this.$el);
        }

        this.isActive = true;
        this.timer = setTimeout(() => this.close(), this.duration);
      },
      setupContainer() {
        this.parentTop = document.querySelector('.bc-notices.is-top');
        this.parentBottom = document.querySelector('.bc-notices.is-bottom');

        if (this.parentTop && this.parentBottom) {
          return;
        }

        if (!this.parentTop) {
          this.parentTop = document.createElement('div');
          this.parentTop.className = 'bc-notices is-top';
        }

        if (!this.parentBottom) {
          this.parentBottom = document.createElement('div');
          this.parentBottom.className = 'bc-notices is-bottom';
        }

        const container = document.body;

        const style = document.createElement('style');
        style.innerHTML = `
          .bc-notices {
            position: fixed;
            display: flex;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 2em;
            overflow: hidden;
            z-index: 9999999999!important;
            pointer-events: none;
          }

          .bc-notices.is-top {
            flex-direction: column;
          }

          .bc-notices.is-bottom {
            flex-direction: column-reverse;
          }
        `;
        document.head.appendChild(style);

        container.appendChild(this.parentTop);
        container.appendChild(this.parentBottom);
      },
      toastIcon(type) {
        return this.icon ? this.icon : this.defaultIcons[type];
      },
    },
  };
</script>

<style lang="scss" scoped>
  $colors: (
    "info": ($color-primary, #3865FD),
    "success": ($color-success, #3CCF8E),
    "warning": ($color-warning-dark, #F78E00),
    "error": ($color-error-light, #FF7178),
    "notification": ($color-blue-dark-cello, #F4F9FF),
  );

  .bc-toast {
    position: relative;
    align-items: center;
    animation-duration: 0.15s;
    margin: 0.5em 0;
    box-shadow: 0px 4px 30px 0px rgba(29, 57, 94, 0.10);
    border-radius: $border-radius-m;
    font-size: $font-size-m;
    padding: 18px 30px;
    pointer-events: auto;
    min-width: 500px;
    min-height: 80px;
    flex-shrink: 0;
    background: #FFF;

    @each $name, $pair in $colors {
      $color: nth($pair, 1);
      &.#{$name} {
        color: $color;
        border-bottom: 5px solid $color;

        &__wrapper {
          color: $color !important;
        }
      }
    }

    &__timer {
      position: absolute;
      bottom: -5px;
      height: 10px;
      background: #FFF;
      transform: translateX(-30px);
      border-bottom-left-radius: 5px;
      transition: 0.2s;
    }

    &__wrapper {
      flex-direction: column;
      width: 100%;
    }

    &__title {
      color: inherit;
      font-size: 16px;
      margin-bottom: 6px;
    }

    &__message {
      color: $color-tertiary;
      font-size: 16px;
    }

    &__icon {
      margin-right: 10px;
      font-size: 28px;
      height: 28px;
    }

    &__close {
      font-size: $font-size-s;
      cursor: pointer;
      color: $color-blue-heavy-dark;

      &-container {
        height: 100%;
        flex: 1;
        align-items: center;
      }
    }

    &.top, &.bottom {
      align-self: center;
    }

    &.top-right, &.bottom-right {
      align-self: flex-end;
    }

    &.top-left, &.bottom-left {
      align-self: flex-start;
    }

    &__marvin {
      width: 30px;
      margin-right: 10px;
    }

    &.notification {
      background: rgba(245, 245, 245, 0.6);
      box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.15);
      border-radius: 16px;
      border-color: transparent;
      min-width: 350px;
      -webkit-backdrop-filter: blur(16.601px);
      backdrop-filter: blur(16.601px);
    }
  }
</style>
