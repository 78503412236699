<script lang="ts" setup>
  import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav.vue';
  import { usePanelStore } from '@/store/pinia/panel';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox.vue';
  import ProspectsFiltersLastActivity
    from '@/components/Prospects/ProspectsFilters/ProspectsFiltersLastActivity.vue';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete.vue';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
  import { User } from '@/domains/models/User';
  import { computed } from 'vue';
  import { useStore } from '@/store';
  import { getFilterableCoach } from '@/macros/prospectionPipe/dropdowns';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { useI18n } from '@/i18n/i18n';

  const panelStore = usePanelStore();
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const { t } = useI18n();

  const coaches = computed<User[]>(() => store.state.coaches.coaches);
  const ownerOptions = computed<User[]>(() => coaches.value.filter((coach: User) => getFilterableCoach(coach)));

  function handleClose() {
    panelStore.closeModal();
  }

  function getUserInitials(user: Pick<User, 'firstName' | 'lastName'>) {
    return `${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`;
  }

  function getUserActiveStatus(user: User) {
    return route.query?.owners?.includes(user._id);
  }

  function selectOwner(owner: User) {
    if (route.query?.owners?.includes(owner._id)) {
      router.push({
        ...route,
        query: {
          ...route.query,
          owners: route.query.owners.filter((id: string) => id !== owner._id),
        },
      });
    } else {
      router.push({
        ...route,
        query: {
          ...route.query,
          owners: [...route.query.owners || [], owner._id],
        },
      });
    }
  }

  const ownerPlaceholder = computed<string>(() => Array.isArray(route.query.owners) ? ownerOptions.value.filter((coach: User) => route.query.owners.includes(coach._id)).map((coach: User) => `${coach.firstName} ${coach.lastName}`).join(', ') : t('generics.owner'));
</script>

<template>
  <div class="flex w-[640px] max-w-[640px] flex-col">
    <div
      class="flex min-h-[50px] w-full shrink-0 items-center justify-center gap-6 border-b border-b-[#EDF0FF] bg-neutral-200 px-[18px] py-2">
      <PanelHeaderNav
        :on-back="handleClose"
        :on-close="handleClose"
        class="absolute left-5 flex shrink-0"
      />
      <div class="flex shrink-0 items-center gap-6">
        <h2 class="shrink-0 text-2xl font-bold first-letter:capitalize">
          {{ $tc('generics.filters', 2) }}
        </h2>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="flex grow flex-col gap-[5px] px-[20px] py-[15px]">
        <label class="pl-[10px] text-xs text-blue-400">
          {{ $t('generics.contact-info') }}
        </label>
        <div class="flex flex-wrap gap-x-[20px] gap-y-[10px]">
          <BcCheckbox
            id="prospects-phone-filter"
            class="text-xs">
            {{ $t('hunt.has-a-phone-number') }}
          </BcCheckbox>
          <BcCheckbox
            id="prospects-phone-filter"
            class="text-xs">
            {{ $t('hunt.has-email') }}
          </BcCheckbox>
          <BcCheckbox
            id="prospects-phone-filter"
            class="text-xs">
            {{ $t('hunt.has-linkedin') }}
          </BcCheckbox>
        </div>
      </div>
      <hr class="border-blue-100"/>
      <ProspectsFiltersLastActivity/>
      <hr class="border-blue-100"/>
      <div class="flex grow flex-col gap-[5px] px-[20px] py-[15px]">
        <BcAutocomplete
          :keep-open="true"
          :label="$t('generics.owner')"
          :open-on-focus="true"
          :options="ownerOptions"
          :placeholder="ownerPlaceholder"
          class="max-w-[350px]"
          name="owner">
          <template #option="{ option, isActive }">
            <button
              class="flex h-[40px] w-full items-center px-[20px] text-blue-800 hover:text-blue-500"
              @click="selectOwner(option)">
              <BcCheckbox
                :id="option.name"
                :value="getUserActiveStatus(option)"
                class="mr-[10px]"/>
              <BcAvatar
                :src="option.pictureUrl"
                :placeholder="getUserInitials(option)"
                class="mr-[5px]"
                size="s"
              />
              <p class="text-sm">{{ option.firstName }} {{ option.lastName }}</p>
            </button>
          </template>
        </BcAutocomplete>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
