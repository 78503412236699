<script lang="ts" setup>
  import { computed, onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router/composables';
  import { useI18n } from '@/i18n/i18n';
  import Chart from 'chart.js';

  import { useStore } from '@/store';
  import { getCustomJobKpis } from '@/api/custom-jobs';

  const { t } = useI18n();
  const store = useStore();
  const kpis = ref({
    nbCandidates: 0,
    nbProcesses: 0,
    nbRecruitedProcesses: 0,
    candidatesOrigins: {},
  });

  const route = useRoute();
  const activatedChannels = computed(() => [{
    id: 'career',
    title: t('custom-jobs.career-website'),
    connectorName: 'career',
  }, ...store.state.xtramile?.activatedChannels]);
  const colors = computed(() => store.state.xtramile?.colors);
  const opportunityId = computed(() => route.params.id);

  onMounted(async () => {
    const { data } = await getCustomJobKpis(opportunityId.value);
    kpis.value = data;

    var ctx = document.getElementById('myChart')?.getContext?.('2d');
    if (!ctx) return;

    const dataKeys = Object.keys(data?.candidatesOrigins || {});

    new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: dataKeys?.length ? dataKeys.map(key => key) : [''],
        datasets: [{
          data: dataKeys?.length ? dataKeys.map(key => data.candidatesOrigins[key]?.length) : [1],
          backgroundColor: dataKeys.map(key => colors.value[key]),
          borderWidth: 1
        }],
      },
      options: {
        legend: {
          display: false
        },
      },
    });
  });



</script>

<template>
  <div class="flex w-full flex-col overflow-hidden rounded-md">
    <div class="flex w-full justify-between gap-2 bg-neutral-200 py-3">
      <div class="flex shrink-0 basis-1/3 items-center gap-2.5 border-r border-blue-200 px-5">
        <i class="icon-presentation flex size-[30px] shrink-0 items-center justify-center rounded-lg bg-white text-blue-500 shadow-[0_4px_30px_0_rgba(29,57,94,0.10)]"/>
        <p class="text-md leading-4 text-blue-800"><b>{{ kpis.nbCandidates }}</b> candidatures au total</p>
      </div>
      <div class="flex shrink-0 basis-1/3 items-center gap-2.5 border-r border-blue-200 px-5">
        <i class="icon-arrow-dot-down flex size-[30px] shrink-0 items-center justify-center rounded-lg bg-white text-blue-500 shadow-[0_4px_30px_0_rgba(29,57,94,0.10)]"/>
        <p class="text-md leading-4 text-blue-800"><b>{{ kpis.nbProcesses }}</b> process en cours</p>
      </div>
      <div class="flex shrink-0 basis-1/3 items-center gap-2.5 px-5">
        <i class="icon-partners flex size-[30px] shrink-0 items-center justify-center rounded-lg bg-white text-blue-500 shadow-[0_4px_30px_0_rgba(29,57,94,0.10)]"/>
        <p class="text-md leading-4 text-blue-800"><b>{{ kpis.nbRecruitedProcesses }}</b> candidats recrutés</p>
      </div>
    </div>
    <div class="flex flex-col bg-neutral-100 px-5 py-2.5">
      <b class="mb-3 text-blue-800 ">
        Origine des candidatures
      </b>

      <div class="flex justify-between gap-10">
        <div class="w-[30%]">
          <canvas id="myChart" width="400" height="400"></canvas>
        </div>
        <div class="flex flex-wrap w-[70%] gap-x-8">
          <div
            v-for="jobboard in activatedChannels"
            :key="jobboard.id"
            class="w-[45%] items-center"
          >
            <div class="flex items-center gap-1">
              <span class="size-[8px] rounded-full" :style="{ background: `${colors[jobboard.connectorName]}!important` }"></span>
              <p class="capitalize">{{ jobboard.title }}</p>
            </div>
            <div class="ml-auto">
              {{
                kpis.candidatesOrigins[jobboard.connectorName]
                  ? kpis.candidatesOrigins[jobboard.connectorName].length
                  : kpis.candidatesOrigins[jobboard.title]
                  ? kpis.candidatesOrigins[jobboard.title].length
                  : 0
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
