import moment from 'moment/min/moment-with-locales'

function getFullName(relatedUser, userType = 'coder') {
  const fullName = {
    coach: `${relatedUser?.firstName} ${relatedUser?.lastName}`,
    contact: `${relatedUser?.firstName} ${relatedUser?.lastName}`,
    coder: `${relatedUser?.firstName} ${relatedUser?.lastName}`,
    job: `${relatedUser?.name}`,
    company: `${relatedUser?.name}`,
    hunt: `${relatedUser?.poste}`,
    customJob: `${relatedUser?.title}`,
  };

  return fullName[userType];
}

export default {
  'ping-timeline': {
    name: 'pingTimeline',
    description: 'Ping un collègue depuis la timeline',
    pictureUrl: ({ author }) => author.pictureUrl,
    content: ({ author, message, processCustomId, data }) => {
      if (processCustomId) {
        return `<span class='notif-ressource'>${getFullName(author, 'coach')}</span> {{ $t('notifications.process-custom-timeline', { candidateName: "${data.candidateName}", customJobTitle: "${data.customJobTitle}" }) }} <span class='notif-ressource'></span>`
      }
      return `<span class='notif-ressource'>${getFullName(author, 'coach')}</span> {{ $t('notifications.mentioned-profile-of') }} <span class='notif-ressource'>${message}</span>`;
    },
  },
  'apply-from-career': {
    name: 'applyFromCareer',
    description: 'Ping les owners après une candidature depuis le site carrière',
    pictureUrl: ({ author }) => author.pictureUrl,
    content: ({ author, data }) => {
      return `<span class='notif-ressource'>${getFullName(author, 'coder')}</span> {{ $t('notifications.apply-from-career', { customJobTitle: "${data.customJobTitle}" }) }}`;
    },
  },
  'add-candidate-to-list': {
    name: 'addCandidateToList',
    description: 'Ping les coachs partagé après l\'ajout d\'un candidat dans une liste',
    pictureUrl: ({ data }) => data.candidatePictureUrl,
    content: ({ author, data }) => {
      return `<span class='notif-ressource'>${data.candidateName}</span> {{ $t('notifications.add-candidate-to-list', { listName: "${data.listName}" }) }} <span class='notif-ressource'>${getFullName(author, 'coder')}</span>`;
    },
  },
  'close-opportunity': {
    name: 'closeOpportunity',
    description: 'Ping les owner après la fermeture d\'une opportunité',
    pictureUrl: ({ author }) => author.pictureUrl,
    content: ({ author, data }) => {
      return `<span class='notif-ressource'>${getFullName(author, 'coder')}</span> {{ $t('notifications.close-opportunity') }} <span class='notif-ressource'>${data.customJobTitle}</span> {{ $t('timeline.among') }} <span class='notif-ressource'>${data.companyName}</span>`;
    },
  },
  'reminder-candidate': {
    name: 'reminderCandidate',
    description: 'Rappel lorsque la date correspond à aujourd\'hui',
    pictureUrl: ({ author }) => author.pictureUrl,
    content: ({ author, data }) => {
      return `{{ $t('generics.callback') }} <span class='notif-ressource'>${getFullName(author, 'coder')}</span> ({{ $t('notifications.reminder-date', { date: "${moment(data.reminderCreatedAt, "YYYYMMDD").fromNow()}" }) }})`;
    },
  },
  'email-group': {
    name: 'emailGroup',
    description: 'Envoi de mail groupé',
    icon: () => 'mail-arrow-up',
    content: ({ data }) => {
      let subject = data.template?.subject || '';
      subject = subject.replaceAll('{{', '{').replaceAll('}}', '}');
      return `Le mail <span class="text-blue-500">“${subject}”</span> a bien été envoyé à ${data.group?.length} destinataires`;
    }
  }

}
