<script setup lang="ts">
import { ref, computed, watch, defineProps, defineEmits } from 'vue';

import BcAutocomplete from '@/ui-kit/components/BcAutocomplete';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import FilterContent from '@/components/Filter/FilterContent';
import Tag from '@/components/Tag/Tag';
import { useRoute, useRouter } from 'vue-router/composables';

const route = useRoute();
const router = useRouter();

const props = defineProps<{
    emitOnly?: boolean;
}>();

const emit = defineEmits<{
    (e: 'update:filters', filters: Record<string, any>): void;
}>();

const search = ref('');
const suggestions = ref([]);
const localSelected = ref<string[]>([]);

watch(() => route.query.search, (newValue) => {
    if (newValue) {
        localSelected.value = (newValue as string).split(',');
    } else {
        localSelected.value = [];
    }
}, { immediate: true });

const selected = computed(() => {
    if (props.emitOnly) {
        return localSelected.value;
    }
    if (!route.query.search) {
        return [];
    }
    return (route.query.search as string).split(',');
});

const getSuggestions = (value: any) => {
    suggestions.value = [{ value }];
};

const onSelect = (option: any) => {
    search.value = '';
    const newSelected = [...selected.value, option.value];

    if (props.emitOnly) {
        localSelected.value = newSelected;
        emit('update:filters', {
            search: newSelected.join(',')
        });
    } else {
        router.push({
            query: {
                ...route.query,
                page: 1,
                search: newSelected.join(',')
            },
        });
    }
};

const onPressTag = (tag: string) => {
    const newSelected = selected.value.filter((value) => value !== tag);

    if (props.emitOnly) {
        localSelected.value = newSelected;
        emit('update:filters', {
            search: newSelected.length ? newSelected.join(',') : undefined
        });
    } else {
        router.push({
            query: {
                ...route.query,
                page: 1,
                search: newSelected.length ? newSelected.join(',') : undefined
            },
        });
    }
};
</script>

<template>
    <div class="flex flex-col w-full px-[20px] pb-[15px] pt-[15px]">
        <bc-autocomplete
            :options="suggestions"
            :is-dropdown-full-width="false"
            @input="getSuggestions"
            icon="users"
            :label="$t('global.title')"
            name="algolia-advanced-search-job"
            :placeholder="$t('table-content.job-placeholder')"
            v-model="search"
        >
            <template #option="{ option }">
                <bc-dropdown-item
                    :text="option.value"
                    :is-selected="false"
                    @click.native.stop="onSelect(option)"
                    class="filter-customjobs-name__option font-size-s is-expanded"
                >
                </bc-dropdown-item>
            </template>
        </bc-autocomplete>
        <div
            v-if="selected && selected.length"
            class="filter-customjobs-name__tags mt-2 flex gap-2"
        >
            <tag
                v-for="selectedValue in selected"
                :key="selectedValue"
                :text="selectedValue"
                @click.native.stop="onPressTag(selectedValue)"
                can-close
            />
        </div>
    </div>
</template>
