<template>
  <div class="container">
    <div class="header">
      <p class="title" :class="{ [`icon-${icon}`]: icon }">
        {{ title }}
        <span></span>
      </p>
      <slot name="header"></slot>
    </div>
    <div class="card" :style="cardStyle">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'panel-process-details-card',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    cardStyle: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  margin: 0 0 10px 20px;
}

.title {
  display: flex;
  font-weight: $font-weight-bold;
  font-size: $font-size-m;
  line-height: 17px;
  color: $color-blue-dark-cello;
  align-items: center;
  width: 100%;

  &:before {
    font-size: $font-size-l;
    margin-right: 10px;
  }

  & > span {
    margin-left: 10px;
    flex: 1;
    height: 1px;
    background: $color-blue-medium;
  }
}

.card {
  padding: 15px 20px;
  flex-direction: column;
  background: $color-white;
  border-radius: 5px;
  border: 1px solid $color-blue-medium;
}
</style>
