<script lang="ts" setup>
import { inject, onMounted, ref, watch } from 'vue';

// @ts-ignore
const icons: Record<string, () => Promise<string>> = inject('icons');
const iconsAvailable = Object.keys(icons);
type IconsArray = typeof iconsAvailable;

const container = ref<HTMLElement | null>(null);

export interface MvIconProps {
  size?: number;
  icon?: IconsArray[number];
}

const props = withDefaults(defineProps<MvIconProps>(), {
  size: 16,
});

async function setIcon() {
  if (!icons || !props.icon) return;
  if (!icons[props.icon]) {
    console.warn(`Icon ${props.icon} is not available`);
    return;
  }
  if (!container.value) return;
  container.value.innerHTML = await icons[props.icon]();

  const svg: SVGElement | null = container?.value?.querySelector('svg');
  if (!svg) return;

  svg.style.width = `${props.size}px`;
  svg.style.height = `${props.size}px`;
  const svgShapeSelectors = ['circle', 'ellipse', 'line', 'path', 'polygon', 'polyline', 'rect'];
  const svgPathsOrShapesList = svg.querySelectorAll(svgShapeSelectors.join(', '));
  if (!svgPathsOrShapesList) return;
  const isStrokeSpecified = [...svgPathsOrShapesList].some((pathOrStroke) => pathOrStroke.getAttribute('stroke'));
  if (isStrokeSpecified) {
    for (const pathOrStroke of svgPathsOrShapesList) {
      if (pathOrStroke.getAttribute('stroke')) {
        pathOrStroke.setAttribute('stroke', 'currentColor');
      } else {
        pathOrStroke.setAttribute('fill', 'currentColor');
      }
    }
  } else {
    svg.setAttribute('fill', 'currentColor');
    for (const pathOrStroke of svgPathsOrShapesList) {
      if (pathOrStroke.getAttribute('fill')) pathOrStroke.setAttribute('fill', 'currentColor');
    }
  }
}

onMounted(setIcon);
watch(() => props.icon, setIcon);
</script>

<template>
  <i ref="container" :style="{ width: size + 'px', height: size + 'px' }" class="mv-icon"></i>
</template>

<style>
.mv-icon {
  display: block;
  transition: color 0.05s;
}

/* THIS BIT OVERRIDES DEFINED HEIGHT WIDTH IN THE SVG */
.icon > svg {
  width: inherit;
  height: inherit;
}
</style>
