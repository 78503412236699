const dictionary = {
  fr: {
    messages: {
      notUnique: 'Ce champ est déjà utilisé par une autre entreprise',
      isPhoneValid: "Phone number is invalid",

    },
  },
  en: {
    messages: {
      notUnique: 'This field is already used by another company',
      isPhoneValid: "Le numéro de téléphone est invalide",
    },
  },
};

export default dictionary;
