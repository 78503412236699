<template>
  <views-tabs :tabs="tabs">
    <template #content>
      <slot></slot>
    </template>
  </views-tabs>
</template>

<script setup lang="ts">
import { useI18n } from '@/i18n/i18n';
import { useStore } from '@/store';
import ViewsTabs from '@/components/Views/ViewsTabs';
import { apiRoles } from '@/macros/coaches/roles';
import { computed } from 'vue';
import { toInt } from '@/utils/typeConversion';

const i18n = useI18n();
const store = useStore();
const ressourceCount = computed(() => store.getters['metadata/ressourceCount']);
const coachRessourceCount = computed(() => store.getters['metadata/coachRessourceCount']);

const profile = computed(() => store.state.user.profile);
const isSuperUser = computed(() => store.getters['user/isSuperUser']);

const prospectionPipeQuery = computed(() => {
  if (isSuperUser.value) {
    return [
      apiRoles.mentor,
      apiRoles.coach,
      apiRoles.presales,
      apiRoles.lead,
      apiRoles.businessManager,
    ].includes(profile.value.role)
      ? { 'coach-ids': profile.value._id, 'selected-pipe': 'personal-pipe' }
      : { 'coach-ids': undefined, 'selected-pipe': 'personal-pipe' };
  }

  return { 'coach-ids': profile.value._id, 'selected-pipe': 'personal-pipe' };
});

const tabs = computed(() => [
  {
    key: 'partners',
    label: i18n.tc('generics.company', 2),
    to: '/partners',
    count: ressourceCount.value.company,
    icon: 'companies',
  },
  {
    key: 'contacts',
    label: i18n.tc('generics.contact-cap', 2),
    to: '/contacts',
    count: ressourceCount.value.contact,
    icon: 'contact',
  },
  {
    key: 'prospects',
    label: i18n.t('topbar.prospection'),
    to: { path: '/pipe/prospects', query: prospectionPipeQuery.value },
    count: coachRessourceCount.value.prospect,
    icon: 'kanban',
  },
  {
    key: 'customers',
    label: i18n.t('generics.my-clients'),
    to: { path: '/pipe/account-management', query: { owner: profile.value._id } },
    count: coachRessourceCount.value.client,
    icon: 'client',
    iconFontSize: '0.9rem'
  },
]);
</script>
