<template>
  <div class="skills-autocomplete is-column">
    <div class="is-align-items-center is-full-width">
      <bc-autocomplete
        :keep-first="true"
        :limit="8"
        :options="matches"
        :filter-options="filterOptions"
        :selected-filter="selectedFilter"
        :is-scrollable="false"
        :required="required"
        is-dropdown-full-width
        :disabled="isDisabled"
        @input="getSuggestions"
        @dropdown="onDropdown"
        @select="handleSelect"
        @on-select-filter="onSelectFilter"
        class="skills-autocomplete__autocomplete is-full-width"
        :class="{ 'skills-autocomplete__disabled': isDisabled }"
        icon="tool"
        :label="showLabel ? $t('panel-coder-edit.skills') : undefined"
        name="skills-actual"
        :placeholder="$t('generics.find-skill')"
        v-model="searchSkill">
        <template #empty>
          <bc-dropdown-item
            class="skills-autocomplete__option font-size-s is-expanded"
            :text="$t('toast.no-skill-found')">
          </bc-dropdown-item>
        </template>
        <template #option="{ option }">
          <bc-dropdown-item
            :is-selected="isSkillSelected(option._id)"
            :text="option.name"
            class="skills-autocomplete__option font-size-s is-expanded">
          </bc-dropdown-item>
        </template>
        <template #footer>
          <button
            v-if="forConfiguration || canCreate"
            :disabled="!isCreateNewSkillEnabled"
            type="button"
            @click.prevent.stop="createNewSkill"
            class="is-full-width font-size-m is-secondary is-justify-content-center mx-auto py-3"
            :style="{ 'opacity': isCreateNewSkillEnabled ? '1' : '0.3' }">
            <span class="icon-plus mr-2"></span>
            {{ $t('panel-coder-edit.create-the-skill', { skill: searchSkill }) }}
          </button>
        </template>
      </bc-autocomplete>
    </div>
    <div v-if="selectedSkillsList.length" class="skills-autocomplete__tags mt-2.5">
      <tag
        v-for="(skill, index) in selectedSkillsList"
        :key="index"
        :text="skill.name"
        :can-close="true"
        type="technos"
        @click="handleSelect(skill)">
      </tag>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce';
import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import Tag from '@/components/Tag/Tag';
import { createTag, getTagsAutocomplete } from '@/api/tags';
import searchbar from '@/common-old/mixins/Searchbar/searchbar';
import { hasExactMatch } from '@/helpers/autocomplete';

export default {
  name: 'skills-autocomplete',
  components: {
    BcDropdownItem,
    BcAutocomplete,
    Tag,
  },
  mixins: [searchbar],
  props: {
    profile: {
      type: Object,
      default: () => ({}),
    },
    editedProfile: {
      type: Object,
      default: () => ({}),
    },
    selectedProfessions: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    forConfiguration: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    filterOptions: {
      type: Array,
      default: () => [],
    },
    selectedFilter: {
      type: Object,
      default: () => ({}),
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.selected.length) {
      this.selectedSkillsList = this.selected;
    }
  },
  data() {
    return {
      searchSkill: '',
      isDisabled: false,
      skillsList: [],
      selectedSkillsList: [],
      isDropdownOpen: false,
    };
  },
  computed: {
    matches() {
      return this.skillsList.filter(
        skill => skill.name.toLowerCase().includes(this.searchSkill.toLowerCase())
      );
    },
    isCreateNewSkillEnabled() {
      return !hasExactMatch(this.searchSkill, this.matches.map(skill => skill.name));
    },
  },
  methods: {
    getSuggestions: debounce(async function() {
      try {
        const { data } = await getTagsAutocomplete({ name: this.searchSkill });
        const skillsIds = this.selectedSkillsList.map(skill => skill._id);
        this.skillsList = data.filter(skill => !skillsIds.includes(skill._id));
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    }, 300),
    onSelectFilter(option) {
      this.$emit('select-option', option);
    },
    onDropdown(isOpen) {
      this.isDropdownOpen = isOpen;
    },
    handleSelect(skill) {
      if (this.isSkillSelected(skill._id)) {
        this.removeSkill(skill);
      } else {
        this.addSkill(skill);
      }
      this.$emit('select-skills', this.selectedSkillsList);
      this.searchSkill = '';
    },
    addSkill(skill) {
      this.$emit('add-skill', skill);
      this.selectedSkillsList.push(skill);
    },
    removeSkill(skill) {
      this.$emit('remove-skill', skill);
      this.selectedSkillsList = this.selectedSkillsList.filter(s => s._id !== skill._id);
    },
    removeLastSkill() {
      if (this.searchSkill !== '' || this.selectedSkillsList.length === 0) {
        return;
      }
      const lastSkill = this.selectedSkillsList[this.selectedSkillsList.length - 1] || {};
      this.removeSkill(lastSkill);
    },
    isSkillSelected(skillId) {
      return this.selectedSkillsList.findIndex(skill => skill._id === skillId) !== -1;
    },
    async createNewSkill() {
      if (!this.isCreateNewSkillEnabled) {
        return;
      }
      const params = { name: this.searchSkill };
      try {
        const { data: skill } = await createTag(params);
        this.searchSkill = '';
        this.addSkill(skill);
        this.$toast.show({
          type: 'success',
          title: this.$t('toast.congratulations'),
          message: this.$t('toast.skill-successfully-created', { skill: skill.name }),
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
  },
  watch: {
    selected(newValue) {
      this.selectedSkillsList = newValue;
    }
  }
};
</script>

<style lang="scss" scoped>
.skills-autocomplete {
  &__create_industry {
    width: 100%;
    flex-direction: column;
    position: absolute;
    background: white;
    border-radius: 5px;

    &-header {
      padding: 15px;
      border-bottom: 1px solid gray;
      width: 100%;

      & > p {
        width: 100%;
        text-align: center;
      }
    }

    &-content {
      flex-direction: column;
      padding: 15px;
    }
  }

  &__option {
    line-height: 15px;
    padding: 10px 20px;
  }

  &__wrapper {
    margin: -5px;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__tag {
    border-radius: 30px;
    margin: 2px;
    font-size: $font-size-xxs;
    font-weight: $font-weight-bold;
    background: #ecf1fa;

    &:hover {
      cursor: pointer;
    }
  }

  &__disabled {
    opacity: 0.6;
    &:hover {
      cursor: not-allowed;
    }
  }

  :deep() .bc-input__wrapper {
    display: flex;
    flex-wrap: wrap;
    min-height: 41px;
  }

  :deep() .bc-input__label--focus {
    top: -20px;
    left: 5px;
    transform: none;
  }
}
</style>
