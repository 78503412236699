export default {
  'fr': {
    'le candidat': 'l’apprenant',
    'du candidat': 'de l\'apprenant',
    'de candidat': 'd\'apprenant',
    'de candidats': 'd\'apprenants',
    'candidats': 'apprenants',
    'candidat': 'apprenant',
    'mes process': 'Mes missions',
    'un process': 'une mission',
    'processes': 'missions',
    'process': 'mission',
  },
  'en': {
    'candidates': 'students',
    'candidate': 'student',
    'applicants': 'students',
    'applicant': 'student',
    'processes': 'missions',
    'process': 'mission',
  }
}
