<script setup lang="ts">
import { computed, defineProps, defineEmits, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import ProfessionsAutocomplete from '@/components/Professions/ProfessionsAutocomplete.vue';
import { useI18n } from '@/i18n/i18n';

const { t } = useI18n();

const route = useRoute();
const router = useRouter();

interface Profession {
    _id: string;
    name: string;
}

const props = defineProps<{
    emitOnly?: boolean;
}>();

const emit = defineEmits<{
    (e: 'update:filters', filters: Record<string, any>): void;
}>();

const selected = computed<Profession[]>(() => {
    if (!route.query.professions) {
        return [];
    }
    const professions = (route.query.professions as string).split(',');
    const professionsNames = (route.query.professionsNames as string).split(',');
    return professions.map((id, index) => ({
        _id: id,
        name: professionsNames[index],
    }));
});

function handleSelect(professions: Profession[]) {
    if (props.emitOnly) {
        emit('update:filters', {
            professions: professions.length ? professions.map(p => p._id).join(',') : undefined,
            professionsNames: professions.length ? professions.map(p => p.name).join(',') : undefined,
        });
    } else {
        router.push({
            query: {
                ...route.query,
                page: 1,
                professions: professions.length ? professions.map(p => p._id).join(',') : undefined,
                professionsNames: professions.length ? professions.map(p => p.name).join(',') : undefined,
            },
        }).catch(() => { });
    }
}

const filterOptions = computed(() => [
    { key: 'and', name: t('generics.and').toUpperCase() },
    { key: 'or', name: t('generics.or').toUpperCase() },
]);
const localOperator = ref(filterOptions.value[0].key);

const selectedOperator = computed(() => {
    return route.query.professionsOperator || localOperator.value;
});

const onSelectFilter = (filter: any) => {
    localOperator.value = filter.key;
    emit('update:filters', {
        professionsOperator: localOperator.value,
    });
};

const selectedFilter = computed(() => {
    return filterOptions.value.find(option => option.key === selectedOperator.value);
});
</script>

<template>
    <ProfessionsAutocomplete
        class="px-[20px] pb-[15px] pt-[15px] algolia-advanced-search-professions"
        :selected="selected"
        :filter-options="filterOptions"
        :selected-filter="selectedFilter"
        @select="handleSelect"
        @select-option="onSelectFilter"
    />
</template>


<style lang="scss" scoped>
.algolia-advanced-search-professions {
    &:deep(.bc-autocomplete__filter-dropdown-wrapper) {
        margin-top: auto;
    }
}
</style>
