<template>
  <div class="modal-coders-deduplication--root">
    <div v-show="!Object.keys(selectedCoder).length" class="modal-coders-deduplication">
      <h3 class="modal-coders-deduplication__title" v-html="$t('coder.duplicate-warning') + ' :'"></h3>
      <div class="modal-coders-deduplication__items">
        <modal-coders-item
          v-for="coder in deduplication"
          :key="coder._id"
          :coder="coder"
          :checked="checkedCoder._id === coder._id"
          @on-check="handleCheck"
        ></modal-coders-item>
      </div>
      <div class="modal-coders-deduplication__buttons">
        <bc-button class="flex-1" type="outlined" v-if="!editedProfile._id" @click.stop="handleCreateCoder">
          {{ $t('generics.create-a-new-profile') }}
        </bc-button>
        <bc-button
          class="flex-1"
          :disabled="!checkedCoder._id"
          @click.stop="updateInfo"
        >
          {{ $t('generics.update-info') }}
        </bc-button>
      </div>
    </div>
    <panel-modal-merge-profile
      v-if="Object.keys(selectedCoder).length"
      :profile="profile"
      :selectedCoder="selectedCoder"
      :create-coder="createCoder"
      @close-modal="selectedCoder = {}"
      @profile-updated="handleProfileUpdated">
    </panel-modal-merge-profile>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { coderController } from '@/managers/coders/controller';

import BcButton from '@/ui-kit/components/BcButton/BcButton';
import PanelModalMergeProfile from '@/components/Panel/PanelModal/PanelModalMergeProfile';
import ModalCodersItem from './ModalCodersItem';

export default {
  name: 'modal-coders-deduplication',
  components: {
    BcButton,
    ModalCodersItem,
    PanelModalMergeProfile,
  },
  mounted() {
    this.profile = this.editedProfile;
  },
  data() {
    return {
      profile: {},
      selectedCoder: {},
      checkedCoder: {},
    }
  },
  computed: {
    ...mapState({
      deduplication: state => state.coders.deduplication,
      editedProfile: state => state.coders.editedProfile,
    }),
    metadata() {
      return this.$store.state.user.metadata;
    },
    onboarding() {
      return this.$store.state.user.onboarding;
    },
  },
  methods: {
    ...mapActions({
      unshiftCard: 'unshiftCard',
      setModal: 'app/setModal',
    }),
    handleClose() {
      this.$emit('close-modal')
    },
    handleCheck(coder) {
      if (this.checkedCoder._id === coder._id) {
        this.checkedCoder = {};
        return;
      }
      this.checkedCoder = coder;
    },
    handleProfileUpdated(coder) {
      this.setModal('');
      this.redirectToCoderPanel(coder._id);
    },
    async createCoder() {
      const { data } = await coderController.createProfile({
        data: this.editedProfile,
      });
      this.profile = data;
      if (this.$route.name.includes('Coders')) {
        this.unshiftCard(data);
      }
      await this.$store.dispatch('user/updateMetadataByKey', {
        key: 'coders',
        value: this.metadata.coders + 1,
      });
      return data;
    },
    async handleCreateCoder() {
      try {
        const data = await this.createCoder();
        if (this.onboarding.coder) {
          this.$toast.show({
            type: 'success',
            message: this.$i18n.t('toast.candidate-created'),
          });
        } else {
          await this.$store.dispatch('user/updateOnboarding', {
            key: 'coder',
            value: true,
          });
        }
        this.setModal('');
        this.redirectToCoderPanel(data._id);
      } catch (error) {
        if (
          error?.response?.data?.message ===
          'Coder using this email already exists' ||
          error?.response?.data?.message === 'Email already exists'
        ) {
          this.$toast.show({
            type: 'error',
            message: this.$i18n.t('toast.email-already-use'),
          });
        } else {
          this.$toast.show({
            type: 'error',
            message: error?.response?.data?.message || error,
          });
        }
      }
    },
    async updateInfo() {
      this.selectedCoder = this.checkedCoder;
    },
    redirectToCoderPanel(id) {
      this.$router.push({
        name: this.$route.name.includes('Panel') ? this.$route.name : `${this.$route.name}Panel`,
        params: {
          id,
        },
        query: {
          ...this.$route.query,
          type: 'coders',
        },
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-coders-deduplication {
  width: 679px;
  background: white;
  border-radius: 30px;
  padding: 50px;
  flex-direction: column;
  gap: 20px;
  position: relative;

  &--root {

  }

  &__close {
    position: absolute;
    right: 40px;
    top: 40px;
    color: $color-blue-heavy-dark;
  }

  &__title {
    color: $color-tertiary;
    font-size: 24px;
    font-weight: 700;
  }

  &__buttons {
    margin-top: 10px;
    justify-content: center;
    gap: 20px;
  }

  &__items {
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    min-height: 150px;
    max-height: 400px;
  }
}

.flex-1 {
  flex: 1;
}
</style>
