import { computed } from 'vue';
import { useStore } from '@/store';
interface Card {
  _id: string;
  [key: string]: any;
}

export const useMultiselect = (identifier = '_id') => {
  const store = useStore();

  const selectedCards = computed(() => store.state.card.selectedCards);
  const cards = computed(() => store.state.card.cards);

  const setSelectedCards = (value: boolean, card: Card): void => {
    const selectedCardsIds = selectedCards.value.map((card: Card) => card[identifier]);

    if (!value && selectedCardsIds.includes(card[identifier])) {
      store.dispatch('setSelectedCards', selectedCards.value.filter((c: Card) => c[identifier] !== card[identifier]));
    } else if (value && !selectedCardsIds.includes(card[identifier])) {
      store.dispatch('setSelectedCards', [...selectedCards.value, { ...card, _id: card[identifier] }]);
    }
  };

  const getIsSelectedStatus = (id: string): boolean => {
    return selectedCards.value.map((card: Card) => card[identifier]).includes(id);
  };

  const selectAllCards = (value: boolean, cardsToSelect: Card[] | undefined): void => {
    const selectedCardsArray = value ? cardsToSelect ? cardsToSelect : cards.value : [];

    store.dispatch('setSelectedCards', selectedCardsArray.map((card: Card) => ({ ...card, _id: card[identifier] })));
  };

  const resetSelectedCards = (): void => {
    store.dispatch('setSelectedCards', []);
  };

  const getIsAllCardsSelected = (items: Card[]) => {
    return items.length > 0 && items.every((card: Card) => getIsSelectedStatus(card[identifier]));
  }

  return {
    selectedCards,
    cards,
    setSelectedCards,
    getIsSelectedStatus,
    selectAllCards,
    resetSelectedCards,
    getIsAllCardsSelected,
  };
};