<script setup lang="ts">
import { defineAsyncComponent } from 'vue';

type Props = {
  name: string;
  size?: string;
  color?: string;
};

const props = withDefaults(defineProps<Props>(), {
  color: 'currentColor',
  size: '16px',
});


const icon = defineAsyncComponent(() =>
  import(`../../assets/svg/${props.name}.svg?component`)
);
</script>

<template>
  <component :is="icon" :width="size" :height="size" />
</template>
