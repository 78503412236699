import { getProfessions } from '@/api/professions';
import { professionController } from '@/managers/professions/controller';

export default {
  namespaced: true,
  state: {
    professions: [],
  },
  actions: {
    async getProfessions({ commit }) {
      const { data } = await getProfessions();

      commit('setProfessions', data);
    },
    async createProfession({ commit }, { type, name }) {
      const profession = await professionController.create({ type, name });

      commit('setProfessions', [profession]);

      return profession;
    },
    async editProfession({ commit }, { id, name }) {
      await professionController.edit({ id, name });

      commit('updateProfession', { _id: id, name });
    },
    async deleteProfession({ commit }, { id }) {
      await professionController.remove(id);

      commit('deleteProfession', id);
    },
  },
  mutations: {
    setProfessions(state, professions) {
      state.professions = [...professions, ...state.professions];
    },
    updateProfession(state, profession) {
      const index = state.professions.findIndex(t => t._id === profession._id);

      state.professions[index].name = profession.name;
    },
    deleteProfession(state, id) {
      state.professions = state.professions.filter(profession => profession._id !== id);
    },
  },
};
