<template>
  <div class="panel-favorite-list-manage-resource-lists">
    <div class="panel-favorite-list-manage-resource-lists__container">
      <spinner
        :spinning="isLoading"
        class="panel-favorite-list-manage-resource-lists__container-spinner"
        v-if="isLoading">
      </spinner>
      <div
        class="panel-favorite-list-manage-resource-lists__container-item"
        v-if="!isLoading && lists.length > 0">
        <panel-favorite-list-manage-resource-lists-item
          :key="list._id"
          :list="list"
          @resource-added-to-list="addResourceToList(list._id)"
          @resource-removed-to-list="removeResourceFromList(list._id)"
          v-for="list in lists">
        </panel-favorite-list-manage-resource-lists-item>
      </div>
      <div
        class="panel-favorite-list-manage-resource-lists__default mt-3"
        v-else-if="!isLoading">
        <p class="panel-favorite-list-manage-resource-lists__default-primary-text">
          {{ $t('companies.panel-company.no-list-found') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import PanelFavoriteListManageResourceListsItem
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListManageResource/PanelFavoriteListManageResourceListsItem';
  import Spinner from '@/components/UIKit/Spinner/Spinner';
  //
  import { types } from '@/macros/favoriteLists/favoriteLists';
  //
  import debounce from 'debounce';
  import favoriteLists from '@/api/favoriteLists';
  //
  import { mapState } from 'vuex';

  export default {
    name: 'panel-favorite-list-manage-resource-lists',
    components: { Spinner, PanelFavoriteListManageResourceListsItem },
    data() {
      return {
        isLoading: false,
        lists: [],
      };
    },
    computed: {
      ...mapState({
        profile: state => state.user.profile,
        metadata: state => state.user.metadata,
        selectedCards: state => state.card.selectedCards,
      }),
    },
    created() {
      this.getLists();
    },
    watch: {
      '$route.query': {
        handler: debounce(function(newQueries, oldQueries) {
          if (newQueries['search-lists'] !== oldQueries['search-lists']) {
            this.getLists();
          }
        }, 500),
      },
    },
    methods: {
      async getLists() {
        this.isLoading = true;

        try {
          const mapParamsFavoriteType = {
            'favorite-coder-list': {
              ...!this.selectedCards.length && { coderId: this.$route.params.id },
              type: types.coder,
            },
            'favorite-job-list': {
              ...!this.selectedCards.length && { jobId: this.$route.params.id },
              type: types.job,
            },
            'favorite-company-list': {
              ...!this.selectedCards.length && { companyId: this.$route.params.id },
              type: types.company,
            },
            'favorite-contact-list': {
              ...!this.selectedCards.length && { coderId: this.$route.params.id },
              type: types.contact,
            },
            'favorite-coder-and-contact-list': {
              ...!this.selectedCards.length && { coderId: this.$route.params.id },
              type: types.coder,
            },
          };
          const getFavoriteListsParameters = {
            ...this.$route.query['search-lists'] && { query: this.$route.query['search-lists'] },
            ...mapParamsFavoriteType[this.$route.query.type],
          };
          const getMultipleFavoriteListsParameters = {
            ...this.$route.query['search-lists'] && { query: this.$route.query['search-lists'] },
            ...!this.selectedCards.length && { coderId: this.$route.params.id },
            types: [types.coder, types.contact],
          };
          let response = this.$route.query.type === 'favorite-coder-and-contact-list' ? await favoriteLists.getMultipleFavoriteLists(getMultipleFavoriteListsParameters) : await favoriteLists.getFavoriteLists(getFavoriteListsParameters);

          // Get the existence of the selected cards in the lists
          if (this.selectedCards.length || this.$route.params.id) {
            let ids;
            if (this.selectedCards.length) {
              ids = this.selectedCards.map(card => card._id);
            } else if (this.$route.params.id) {
              ids = [this.$route.params.id];
            }
            // Transform to string of array separated by comma
            ids = ids.map(id => id.toString()).join(',');
            const type = mapParamsFavoriteType[this.$route.query.type]?.type;

            if (ids.length > 0 && type) {
              const responseExistence = await favoriteLists.getFavoriteListsExistence({ ids, type });
              const listExistence = responseExistence.data;

              if (listExistence && listExistence.lists.length > 0) {
                function getIsListed(list) {
                  const elem = listExistence.lists.find(element => element._id.toString() === list._id.toString());
                  const result = elem ? elem.existentIds.filter(item => ids.split(",").includes(item.toString())).length > 0 : false;
                  return result;
                }

                response.data = response.data.map(list => {
                  return {
                    ...list,
                    isListed: getIsListed(list),
                  };
                });
              } else {
                response.data = response.data.map(list => {
                  return {
                    ...list,
                    isListed: false,
                  };
                });
              }
            }
          } else {
            response.data = response.data.map(list => {
              return {
                ...list,
                isListed: false,
              };
            });
          }

          this.lists = response.data;
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }

        this.isLoading = false;
      },
      addResourceToList(listId) {
        const index = this.lists.findIndex(list => list._id === listId);

        this.lists[index].isListed = true;
        this.lists[index].elementsCount++;
      },
      removeResourceFromList(listId) {
        const index = this.lists.findIndex(list => list._id === listId);

        this.lists[index].isListed = false;
        this.lists[index].elementsCount--;
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-favorite-list-manage-resource-lists {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    height: 100%;

    &__title {
      margin-bottom: 15px;
    }

    &__container {
      position: relative;
      height: 100%;
      overflow-y: scroll;
    }

    &__default {
      flex-direction: column;
      text-align: center;
      width: 100%;
    }

    &__default-primary-text {
      font-size: $font-size-m;
      line-height: 19px;
      color: $color-blue-dark-cello;
    }

    &__default-secondary-text {
      font-size: $font-size-m;
      line-height: 19px;
      color: $color-blue-dodger;
      text-decoration: underline;
    }

    &__container-spinner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &__container-item {
      width: 100%;
      flex-direction: column;
    }
  }
</style>
