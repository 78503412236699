<template>
  <bc-modal v-if="!onlyContent" :active="isOpen" @close="$emit('close', $event)">
    <div class="modal-delete-item">
      <h1>{{ icons }}</h1>
      <h3>{{ title }}</h3>
      <div class="modal-delete-item__content">
        <slot name="content"></slot>
      </div>
      <div class="modal-delete-item__footer">
        <bc-button class="flex-1" type="outlined" @click.native.stop="$emit('close', $event)">{{
            $t('generics.no-cancel-this-action')
          }}
        </bc-button>
        <bc-button
          :disabled="isSubmitDisabled"
          class="flex-1"
          color="error"
          @click.native.stop="handleConfirm"
        >
          {{ confirmText ? confirmText : $t('generics.yes-delete') }}
        </bc-button>
      </div>
    </div>
  </bc-modal>
  <div v-else class="modal-delete-item">
    <h1>{{ icons }}</h1>
    <h3>{{ title }}</h3>
    <div class="modal-delete-item__content">
      <slot name="content"></slot>
    </div>
    <div class="modal-delete-item__footer">
      <bc-button class="flex-1" type="outlined" @click.native.stop="$emit('close', $event)">{{
          $t('generics.no-cancel-this-action')
        }}
      </bc-button>
      <bc-button
        :disabled="isSubmitDisabled"
        class="flex-1"
        color="error"
        @click.native.stop="handleConfirm"
      >
        {{ confirmText ? confirmText : $t('generics.yes-delete') }}
      </bc-button>
    </div>
  </div>
</template>

<script>
  import BcModal from '@/ui-kit/components/BcModal';
  import BcButton from '@/ui-kit/components/BcButton';

  export default {
    name: 'modal-delete-item',
    components: {
      BcModal,
      BcButton,
    },
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
      },
      onlyContent: {
        type: Boolean,
        default: false,
      },
      confirmText: {
        type: String,
      },
      type: {
        type: String,
        default: 'delete',
      },
      isSubmitDisabled: {
        type: Boolean,
        default: false,
      },
      customIcons: {
        type: String,
        default: '',
      },
    },
    computed: {
      icons() {
        if (this.customIcons) {
          return this.customIcons;
        }

        return this.type === 'abort' ? '✋❌' : '🗑️👋';
      },
    },
    methods: {
      handleConfirm(event) {
        if (this.isSubmitDisabled) return;

        this.$emit('on-confirm', event);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-delete-item {
    display: flex;
    max-width: 600px;
    flex-direction: column;
    padding: 50px;

    & > h3 {
      color: $color-tertiary;
      font-weight: 700;
      font-size: 20px;
      margin: 10px 0;
    }

    &__content {
      min-width: 400px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-weight: 500;
      font-size: 16px;
      color: $color-tertiary-2;

      &-list {
        list-style-type: decimal;
        text-align: left;
        padding: 10px;

        & > li {
          margin: 5px 0;
        }
      }
    }

    &__footer {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      gap: 10px;
    }
  }

  .flex-1 {
    flex: 1;
  }
</style>
