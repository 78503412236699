<script lang="ts" setup>
  import { Company } from '@/domains/models/Company';
  import PanelCompanyModalMergePreviewCoordinates
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalMerge/PanelCompanyModalMergePreview/PanelCompanyModalMergePreviewCoordinates.vue';
  import PanelCompanyModalMergePreviewDetails
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalMerge/PanelCompanyModalMergePreview/PanelCompanyModalMergePreviewDetails.vue';
  import PanelCompanyModalMergePreviewOthers
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalMerge/PanelCompanyModalMergePreview/PanelCompanyModalMergePreviewOthers.vue';
  import PanelModalMergePreviewTop
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalMerge/PanelCompanyModalMergePreview/PanelCompanyModalMergePreviewTop.vue';
  import { computed } from 'vue';
  import { useI18n } from '@/i18n/i18n';

  const props = defineProps<{
    company: Company;
    toDelete: boolean;
  }>();

  const i18n = useI18n();

  const title = computed(() => props.toDelete ? i18n.t('candidates.profile-to-delete') : i18n.t('candidates.profile-selection'));
</script>

<template>
  <div
    :class="{ 'border-blue-200 bg-neutral-100': !toDelete, 'border-red-200 bg-[#F5DFE033]': toDelete }"
    class="flex h-full max-h-full flex-col items-center overflow-auto rounded border px-10 py-5 text-blue-800">
    <h2
      :class="{
      'text-blue-800': !toDelete,
      'text-red-500': toDelete
      }"
      class="text-lg font-bold">
      {{ title }}
    </h2>
    <hr class="mb-5 mt-2.5 w-full border-t border-blue-200">
    <PanelModalMergePreviewTop
      :company="company"
      :to-delete="toDelete"/>
    <PanelCompanyModalMergePreviewCoordinates
      :company="company"
      :to-delete="toDelete"/>
    <PanelCompanyModalMergePreviewDetails
      :company="company"
      :to-delete="toDelete"/>
    <PanelCompanyModalMergePreviewOthers
      :company="company"
      :to-delete="toDelete"/>
  </div>
</template>

<style lang="scss" scoped>
</style>
