<template>
  <div class="onboarding-chapter-sections">
    <div class="onboarding-chapter-sections--text">
      <p class="onboarding-chapter-sections__title">{{ title }}</p>
      <div class="onboarding-chapter-sections__item-container">
        <onboarding-section-item
          v-for="(section, index) in sections"
          :key="section.key"
          :title="section.title"
          :text="section.text"
          :number="index + 1"
          :selected="selectedSectionKey === section.key"
          @click="handleClick(section.key)"></onboarding-section-item>
      </div>
      <div class="onboarding-chapter-sections__actions">
        <bc-button
          v-if="selectedSection.buttonAction"
          color="primary-base"
          @click="selectedSection.buttonAction.action">
          {{ selectedSection.buttonAction.label }}
        </bc-button>
        <bc-button type="outlined" @click="handleNextSection">
          {{ isEnd ? $t('generics.continue-to-discover') : $t('generics.next-chapter') }}
        </bc-button>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="isDisplayed" class="onboarding-chapter-sections--content">
        <video-player
          v-if="selectedSection.contentType === 'video' && selectedSection.content"
          ref="videoPlayer"
          :options="{
            autoplay: true,
            sources: [
              {
                type: 'video/mp4',
                src: selectedSection.content,
              },
            ],
            controlBar: {
              volumePanel: {
                inline: false,
              },
            },
          }"
          :playsinline="true"
          class="video-player-box vjs-big-play-centered">
        </video-player>
        <div v-if="selectedSection.contentType === 'gif'" class="onboarding-chapter-sections__gif">
          <img :src="selectedSection.content" />
        </div>
        <div v-if="selectedSection.contentType === 'img'" class="onboarding-chapter-sections__img">
          <img :src="selectedSection.content" />
        </div>
        <div v-if="selectedSection.contentType === 'component'">
          <component :is="selectedSection.content" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import { videoPlayer } from 'vue-video-player';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import OnboardingSectionItem from './OnboardingSectionItem';

export default {
  name: 'onboarding-chapter-sections',
  components: {
    BcButton,
    OnboardingSectionItem,
    videoPlayer,
  },
  props: {
    title: {
      type: String,
    },
    sections: {
      type: Array,
    },
    nextChapter: {
      type: Object,
    },
  },
  mounted() {
    this.setDefaultSection();
  },
  data() {
    return {
      isDisplayed: false,
    }
  },
  computed: {
    selectedSectionKey() {
      return this.$route.query?.section ? this.$route.query?.section : this.sections[0].key;
    },
    selectedSectionIndex() {
      const index = this.sections.findIndex(section => section.key === this.selectedSectionKey);
      return index !== -1 ? index : 0;
    },
    selectedSection() {
      return this.sections[this.selectedSectionIndex] || {};
    },
    isEnd() {
      const nextSection = this.sections[this.selectedSectionIndex + 1]?.key;
      return !nextSection && !this.nextChapter;
    },
  },
  methods: {
    handleDisplay() {
      this.isDisplayed = false;
      setTimeout(() => this.isDisplayed = true, 300)
    },
    updateSection(key) {
      this.$router.push({
        query: {
          ...this.$route.query,
          section: key,
        },
      });
    },
    goToNextChapter() {
      if (!this.nextChapter) {
        window.open('https://calendly.com/victoire-13/reserver-une-demo-de-l-ats', '__blank');
        return
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          section: this.nextChapter?.sections?.[0]?.key,
          chapter: this.nextChapter?.key,
        },
      });
    },
    handleClick(key) {
      this.updateSection(key);
    },
    handleNextSection() {
      const nextSection = this.sections[this.selectedSectionIndex + 1]?.key;
      if (nextSection) {
        this.updateSection(nextSection);
      } else {
        this.goToNextChapter();
      }
    },
    setDefaultSection() {
      if (!this.sections.find(section => section.key === this.$route.query?.section)) {
        this.updateSection(this.sections[0].key);
      }
    },
    showComponent() {
      if (this.selectedSection?.component) {
        Vue.component(this.selectedSection.content, this.selectedSection.component)
      }
    }
  },
  watch: {
    isDisplayed: {
      handler(value) {
        if (value) {
          this.showComponent()
        }
      },
      immediate: true,
    },
    '$route.query.section': {
      handler() {
        this.handleDisplay()
      },
      immediate: true,
    },
    '$route.query.chapter': {
      handler() {
        this.handleDisplay()
      },
      immediate: true,
    }
  }
};
</script>

<style lang="scss" scoped>
.onboarding-chapter-sections {
  border-radius: 10px;
  border: 1px solid $color-blue-medium;
  background: white;
  width: 100%;
  height: 100%;

  &__item-container {
    height: 100%;
    max-height: 500px;
    margin-top: 20px;
    flex-direction: column;
    gap: 8px;
    overflow: auto;
  }

  &--text {
    flex-direction: column;
    width: 40%;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  &--content {
    flex-direction: column;
    width: 60%;
    background: $color-neutre-3;
    align-items: center;
    justify-content: center;
  }

  &__title {
    color: $color-blue-dark-cello;
    font-size: 24px;
    font-weight: $font-weight-bold;
  }

  &__actions {
    flex: 1;
    padding-bottom: 20px;
    align-items: flex-end;
    gap: 10px;
    padding-top: 18px;
  }

  &__gif {
    align-items: center;
    justify-content: center;
    padding: 40px 50px;

    & > img {
      border-radius: $border-radius-m;
      width: 100%;
      height: 100%;
      box-shadow: 0px 14px 14px 8px rgba(56, 101, 253, 0.05);
    }
  }

  &__img {
    align-items: center;
    justify-content: center;
    height: 100%;

    & > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.slide-fade-enter-active {
  transition: all .6s ease;
}
.slide-fade-leave-active {
  transition: all .4s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(6px);
  opacity: 0;
}
</style>

<style lang="scss">

.video-player-box {
  padding: 40px 50px;
  width: 85%;
  height: 95%;
  max-height: 550px;
}

.video-js {
  border-radius: $border-radius-m;
  width: 100%;
  height: 100%;
  box-shadow: 0px 14px 14px 8px rgba(56, 101, 253, 0.05);
}
</style>
