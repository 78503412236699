import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { computed, ref } from 'vue';
import { useStore } from '@/store';
import { removeToken } from '@/managers/token/useCases/removeToken';
import { useToast } from '@/ui-kit/components/BcToast';

const baseURL = import.meta.env.VITE_API_URL;

export function useApi() {
  const store = useStore();
  const toast = useToast();
  const loading = ref(false);
  const requestError = ref();
  const isRequestSuccessful = computed(() => !requestError.value);

  async function addToken(options: AxiosRequestConfig = {}) {
    const token = localStorage.getItem('Token');
    options.headers = {
      ...options.headers,
      ...(token && { Token: token }),
      'ngrok-skip-browser-warning': true,
      'Source-Application': 'crm',
    };

    return options;
  }

  function handleError(error: any, message = '') {
    if (error?.response?.status === 401) {
      store.dispatch('token/setIsExpired', true);
      removeToken();
    } else if (error?.response?.status === 409) {
      const nestedMessage = error?.response?.data?.message;
      error.message = nestedMessage;
    }
    requestError.value = error.message;
    if (message) {
      toast.show({
        type: 'error',
        message,
      });
    }
  }

  async function del(
    route: string,
    successMessage = '',
    errorMessage = '',
    additional_params = {},
  ) {
    try {
      loading.value = true;
      requestError.value = '';
      const response = await axios.delete(
        baseURL + route,
        await addToken({ params: { ...additional_params } }),
      );
      if (successMessage) {
        toast.show({
          type: 'success',
          message: successMessage,
        });
      }
      return response.data;
    } catch (error) {
      await handleError(error, errorMessage);
    } finally {
      loading.value = false;
    }
  }

  async function get<T>(route: string, additional_params = {}, successMessage = '', errorMessage = ''): Promise<T | undefined> {
    try {
      loading.value = true;
      requestError.value = '';
      const response = await axios.get(baseURL + route, {
        ...(await addToken({
          params: {
            ...additional_params,
          },
        })),
        transformResponse: [
          (data, headers) => {
            if (headers['content-type'].includes('application/json') && data) {
              return JSON.parse(data);
            }
            return data;
          },
        ],
      });
      if (successMessage) {
        toast.show({
          type: 'success',
          message: successMessage,
        });
      }
      return (response.data as T) ?? undefined;
    } catch (error) {
      await handleError(error, errorMessage);
      return undefined;
    } finally {
      loading.value = false;
    }
  }

  async function getList<T>(route: string, additional_params = {}): Promise<T[]> {
    return (await get<T[]>(route, additional_params)) || [];
  }

  async function patch(route: string, payload: any, successMessage = '', errorMessage = '') {
    try {
      loading.value = true;
      requestError.value = '';
      const response = await axios.patch(baseURL + route, payload, await addToken());
      if (successMessage) {
        toast.show({
          type: 'success',
          message: successMessage,
        });
      }
      return response.data;
    } catch (error) {
      await handleError(error, errorMessage);
    } finally {
      loading.value = false;
    }
  }

  async function post<T>(
    route: string,
    payload: any = {},
    successMessage = '',
    errorMessage = '',
  ): Promise<T | undefined> {
    try {
      loading.value = true;
      requestError.value = '';
      const response = await axios.post(baseURL + route, payload, await addToken());
      if (successMessage) {
        toast.show({
          type: 'success',
          message: successMessage,
        });
      }
      return (response.data as T) ?? undefined;
    } catch (error) {
      await handleError(error, errorMessage);
    } finally {
      loading.value = false;
    }
  }

  async function put(route: string, payload?: any, successMessage = '', errorMessage = '') {
    try {
      loading.value = true;
      requestError.value = '';
      await axios.put(baseURL + route, payload, await addToken());
      if (successMessage) {
        toast.show({
          type: 'success',
          message: successMessage,
        });
      }
    } catch (error) {
      await handleError(error, errorMessage);
    } finally {
      loading.value = false;
    }
  }

  return {
    del,
    get,
    getList,
    patch,
    post,
    put,
    handleError,
    loading,
    requestError,
    isRequestSuccessful,
  };
}
