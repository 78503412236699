<template>
  <div
    v-tooltip="tooltip"
    class="panel-poke-caption-step"
    :class="[`panel-poke-caption-step--${color}`, { 'panel-poke-caption-step--locked': locked }]">
    <div
      v-if="locked"
      class="panel-poke-caption-step__wrapper">
      <div
        class="panel-poke-caption-step__icon"
        :class="[`icon-${icon}`, `panel-poke-caption-step__icon--${color}`]">
      </div>
    </div>
    <radial-progress-bar
      v-else
      class="panel-poke-caption-step__radial"
      :diameter="40"
      :stroke-width="2"
      :total-steps="100"
      :start-color="timerColor"
      :stop-color="timerColor"
      :completed-steps="timer > 0 ? timer : 0"
      :animate-speed="1000"
      :timing-func="'ease-out'"
      :inner-stroke-color="timerBackgroundColor">
      <div
        class="panel-poke-caption-step__icon"
        :class="[`icon-${icon}`, `panel-poke-caption-step__icon--${color}`]">
      </div>
    </radial-progress-bar>
    <div class="panel-poke-caption-step__caption">
      <p
        class="panel-poke-caption-step__text panel-poke-caption-step__text--uppercase"
        :class="[`panel-poke-caption-step__text--${color}`]">
        {{ status }}
      </p>
      <p class="panel-poke-caption-step__text panel-poke-caption-step__text--date">
        {{ date }}
      </p>
    </div>
  </div>
</template>

<script>
  import RadialProgressBar from '@/legacy/ui-kit/components/Radial/BcRadial';

  export default {
    /**
     * This component is the step of the poke caption
     */
    name: 'panel-poke-caption-step',
    components: { RadialProgressBar },
    props: {
      /**
       * Indicate if the status is locked
       */
      locked: {
        type: Boolean,
        default: false
      },
      /**
       * It indicates which icon should be showed
       */
      icon: String,
      /**
       * It indicates the color of the background
       */
      color: String,
      /**
       * It indicates the status of the step
       */
      status: String,
      /**
       * It indicates the date if it shows or the placeholder instead of the date
       */
      date: String,
      /**
       * The timer percentage
       */
      timer: Number,
      /**
       * The color of the radial progress bar
       */
      timerColor: String,
      /**
       * The background color of the radial progress bar
       */
      timerBackgroundColor: String,
      /**
       * The tooltip conf object
       */
      tooltip: Object
    }
  };
</script>

<style scoped lang="scss">
  .panel-poke-caption-step {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background: $color-white;
    border-radius: 5px;
    padding: 10px;
    max-height: 140px;
    @include bp('tablet') {
      flex-direction: row;
      align-items: center;
      padding: 10px 20px;
      max-height: 70px;
    }
    &--locked {
      background: $color-gray-athens;
    }
    &--blue {
      border: 1px solid $color-blue;
    }
    &--green {
      border: 1px solid $color-green-lima
    }
    &--yellow {
      border: 1px solid $color-yellow-lighting;
    }
    &__icon {
      border-radius: 50%;
      font-size: $font-size-xl;
      justify-content: center;
      align-items: center;
      color: $color-white;
      flex-shrink: 0;
      &--blue {
        color: $color-blue;
      }
      &--green {
        color: $color-green-lima;
      }
      &--yellow {
        color: $color-yellow-lighting;
      }
      &--gray {
        color: $color-gray-regent;
      }
    }
    &__wrapper {
      min-width: 40px;
      min-height: 40px;
      align-items: center;
      justify-content: center;
      .panel-poke-caption-step__icon {
        font-size: 30px;
      }
    }
    &__caption {
      flex-direction: column;
      justify-content: space-around;
      max-width: 80px;
      margin-top: 10px;
      @include bp('tablet') {
        margin-left: 10px;
        margin-top: 0;
      }
    }
    &__text {
      font-size: $font-size-s;
      color: $color-gray;
      text-align: center;
      justify-content: center;
      line-height: 16px;
      @include bp('tablet') {
        height: 50%;
        justify-content: flex-start;
        text-align: left;
      }
      &--blue {
        color: $color-blue;
      }
      &--green {
        color: $color-green-lima;
      }
      &--yellow {
        color: $color-yellow-lighting;
      }
      &--gray {
        color: $color-gray-regent;
      }
      &--uppercase {
        text-transform: uppercase;
      }
      &--date {
        font-size: $font-size-xxs;
        line-height: 14px;
        color: $color-blue-dark-cello;

      }
    }
  }
</style>
