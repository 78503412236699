<template>
  <div @click="openPanel({ id: cardContent._id, type: 'organizations' })"
    @click.meta="openPanelInNewTab({ id: cardContent._id, type: 'organizations' })"
    class="card-organizations w-full flex-col overflow-hidden border-radius-m relative">
    <div class="card-organizations__container w-full items-start relative">
      <div class="card-organizations__wrapper w-full flex-col items-center justify-center">
        <div class="is-secondary-dark items-center w-full">
          <bc-avatar :src="cardContent._company.logoUrl" class="card-organizations__avatar shrink-0" size="s"
            @error="setAlternativeImg">
          </bc-avatar>
          <div class="card-organizations__name flex-col items-start">
            <p v-tooltip="cardContent.name"
              class="card-organizations__text font-weight-bold font-size-m truncate w-full">
              {{ cardContent.name }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="card-organizations__wrapper card-organizations__active-users w-full flex-col items-left justify-center">
        <p v-tooltip="cardContent.activeUsersCount"
          class="card-organizations__text font-weight-bold font-size-m truncate w-full">
          {{ cardContent.activeUsersCount }}
        </p>
      </div>
      <div class="card-organizations__wrapper card-organizations__tags w-full items-center hidden-xs hidden-sm">
        <card-tags reference="industries" :tags="industries" class="card-coders__tag-wrapper">
          <template #tag="{ tag }">
            <bc-tag v-tooltip="tag.name" class="card-coders__tag" ellipsis>
              {{ tag.name }}
            </bc-tag>
          </template>
          <template #remaining="{ remaining }">
            <bc-tag class="card-coders__tag shrink-0">
              +{{ remaining }}
            </bc-tag>
          </template>
        </card-tags>
      </div>
      <div @click.stop
        class="card-organizations__wrapper card-organizations__status w-full flex-col items-left justify-center">
        <bc-dropdown @click.prevent.stop class="custom-jobs-contract-duration-select__dropdown" :is-popper="true"
          :is-scrollable="true" :limit="3" :options="organizationStatusOptions">
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger type="button" :is-arrow="true" :is-dropdown-open="isDropdownOpen"
              :text="organizationStatusOptions.find(option => option.value === selectedOrganizationStatus)?.label">
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item :is-focus="activeIndex === index" :text="option.label"
              @click.native="selectOrganizationStatus(cardContent._id, option)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { usePanelNavigation } from '@/composables/usePanelNavigation';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
import CardTags from '@/components/Card/CardTags';
import BcTag from '@/ui-kit/components/BcTag/BcTag';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
import { editOrganization } from '@/api/organizations';

const props = defineProps({
  cardContent: {
    type: Object,
    default: () => ({}),
  },
});

const { openPanel, openPanelInNewTab } = usePanelNavigation();

const industries = computed(() => props.cardContent._industries || []);

const organizationStatusOptions = computed(() => [
  { value: 'CLIENT', label: 'Client' },
  { value: 'TEST', label: 'Test' },
  { value: 'FREE_ACCOUNT', label: 'Compte Gratuit' },
  { value: 'INACTIVE', label: 'Inactif' }
]);
const selectedOrganizationStatus = ref(props.cardContent.organizationStatus);
async function selectOrganizationStatus(id, option) {
  await editOrganization({ id, data: { organizationStatus: option.value } });
  selectedOrganizationStatus.value = option.value;
}

function setAlternativeImg(event) {
  event.target.src = require('@/assets/img/default-avatar.svg');
}
</script>

<style lang="scss" scoped>
.card-organizations {
  &:hover {
    cursor: pointer;
    max-height: initial;
  }

  @include bp("tablet") {
    max-height: 120px;
  }

  &__container {
    padding: 15px;
    flex-direction: column;

    @include bp("tablet") {
      flex-direction: row;
    }

    @include bp("desktop") {
      padding: 25px 15px;
    }
  }

  &__wrapper {
    margin: 10px;
    max-height: 100%;
    min-height: 37px;

    @include bp("tablet") {
      margin: 0 10px;
    }
  }

  &__avatar {
    margin-right: 10px;
  }

  &__name {
    max-width: calc(100% - 50px);
  }

  &__active-users {
    max-width: 7rem;
  }

  &__tags {
    max-width: 11rem;
  }

  &__status {
    max-width: 11.5rem;
  }
}
</style>
