<template>
  <component
    v-click-outside="closePanel"
    :is="$route.query.subtype">
  </component>
</template>

<script>
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation.js';
  //
  import PanelFavoriteListAddResource
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListManageResource/PanelFavoriteListManageResource';
  import PanelFavoriteListCreate
    from "@/components/Panel/PanelFavoriteList/PanelFavoriteListCreate";
  import PanelFavoriteListUpdate
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListUpdate.vue'

  export default {
    name: "panel-favorite-list",
    components: {
      'add-resources': PanelFavoriteListAddResource,
      'create-favorite-list': PanelFavoriteListCreate,
      'edit-favorite-list': PanelFavoriteListUpdate
    },
    mixins: [PanelNavigation]
  }
</script>
