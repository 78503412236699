<template>
  <p class="truncate">
    <span>{{ parts[0] }}</span>
    <span v-if="parts[1]" class="search-input-match">{{ parts[1] }}</span>
    <span>{{ parts[2] }}</span>
  </p>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  text: string;
  search: string;
}>();

function formatString(string = '') {
  return string
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

function splitHighlightValue(name: string, search: string) {
  const startHighlight = formatString(name).indexOf(formatString(search));
  const endHighlight = startHighlight + search.length;

  if (startHighlight === -1) {
    return [name, '', ''];
  }

  return [
    name.substring(0, startHighlight),
    name.substring(startHighlight, endHighlight),
    name.substring(endHighlight),
  ];
}

const parts = computed(() => splitHighlightValue(props.text, props.search));
</script>

<style scoped lang="scss">
.search-input-match {
  border-radius: 2px;
  background: $color-blue-medium;
  color: $color-primary;
}
</style>
