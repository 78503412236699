<script lang="ts" setup>
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
  import { computed, ref } from 'vue';
  import { useTimeoutFn } from '@vueuse/core';
  import TimelineItemWrapper
    from '@/components/Panel/PanelCoder/PanelCoderTemp/TimelineItemWrapperTemp.vue';
  import { addDays, formatDistanceToNow, format } from 'date-fns';
  import { fr } from 'date-fns/locale';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
  import { useStore } from '@/store';

  const isLoaded = ref(false);
  const store = useStore();
  const coach = computed(() => store.state.user.profile);

  const props = defineProps<{
    coder: any
  }>();

  useTimeoutFn(() => {
    isLoaded.value = true;
  }, 0);

  const formatTooltipDate = (date: Date) => {
    return `prévu le ${format(date, 'dd/MM/yyyy HH:mm', { locale: fr })}`;
  };

  const actions = computed(() => [
    {
      title: 'Action prévue dans la campagne',
      campaignName: 'Campagne mailing',
      rawDate: addDays(new Date(), 2),
      date: formatDistanceToNow(addDays(new Date(), 2), { addSuffix: true, locale: fr }),
      h2: `<span class="text-blue-500">${coach.value.firstName} ${coach.value.lastName}</span> a programmé l'envoi d'un message sur Linkedin à <span class="text-blue-500">${props.coder.firstName} ${props.coder.lastName}</span>`,
      icon: 'send',
      text: `Bonjour ${props.coder.firstName},

Je suis ${coach.value.firstName}, consultant chez Marvin Recruiter. Nous collaborons avec des entreprises comme Marvin Recruiter pour les accompagner dans leurs projets de recrutement et leur permettre d'attirer les meilleurs talents.

Je serais ravi de connecter avec vous pour échanger sur vos besoins ou projets.

Au plaisir,
${coach.value.firstName}`,
    },
    {
      title: 'Action prévue dans la campagne',
      campaignName: 'Campagne mailing',
      rawDate: addDays(new Date(), 5),
      date: formatDistanceToNow(addDays(new Date(), 5), { addSuffix: true, locale: fr }),
      h2: `<span class="text-blue-500">${coach.value.firstName} ${coach.value.lastName}</span> a programmé l'envoi d'un message sur Linkedin à <span class="text-blue-500">${props.coder.firstName} ${props.coder.lastName}</span>`,
      icon: 'send',
      text: `Bonjour ${props.coder.firstName},

Merci d'avoir accepté mon invitation !
Avez-vous eu l'occasion de consulter notre plateforme et de réfléchir à notre proposition ? Si tu as des questions ou si tu souhaites en discuter davantage, je serais heureux de planifier un moment pour en parler.

À bientôt.`,
    },
  ]);
</script>

<template>
  <div class="flex w-full flex-col items-center">
    <BcSpinner
      v-if="!isLoaded"
      size="large"/>
    <template v-else>
      <TimelineItemWrapper
        v-for="(action, index) in actions"
        :key="index"
        :action="action"
        class="mb-2.5 w-full"
      >
        <div class="flex w-full flex-col rounded-lg border border-blue-200 bg-white">
          <div class="flex items-center gap-2.5 px-[15px] py-[8px]">
            <i class="icon-clock text-blue-800"/>
            <p class="text-xs text-blue-800">{{ action.title }} <span
              class="text-blue-500">{{ action.campaignName }}</span></p>
            <div class="ml-auto rounded-full bg-blue-100 px-[10px] py-[5px] text-xs text-blue-800"
              v-tooltip.bottom="{
                content: formatTooltipDate(action.rawDate),
                hideOnTargetClick: false
              }">
              {{ action.date }}
            </div>
          </div>
          <div class="flex items-center gap-2.5 bg-[#EAEDFE] px-[10px] py-[4px]">
            <BcAvatar
              :src="coach.pictureUrl"
              size="s"
            />
            <p
              class="text-ellipsis text-xs text-blue-800"
              v-html="action.h2">
            </p>
          </div>
          <div class="w-full p-[18px]">
            <textarea
              v-autosize
              disabled
              :value="action.text"
              class="w-full resize-none rounded-[10px] border border-blue-200 p-[15px] text-sm focus:outline-0"
            />
          </div>
        </div>
      </TimelineItemWrapper>
    </template>
  </div>
</template>

<style lang="scss" scoped>

</style>
