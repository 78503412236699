<script lang="ts" setup>
  import { computed, defineEmits, defineProps, onMounted, ref, watch, withDefaults } from 'vue';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { useStore } from '@/store';
  import BcAvatar from '@/ui-kit/components/BcAvatar';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { useI18n } from '@/i18n/i18n';
  import { Opportunity } from '@/domains/models/Opportunity';
  import { setAlternativeImg } from '@/mixins/imgFallback';
  import BcInput from '@/ui-kit/components/BcInput/BcInput.vue';
  import { ValidationProvider } from 'vee-validate';
  import { updateCustomJob } from '@/api/custom-jobs';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete';
  import { debounce } from '@/common-old/mixins/Debounce/debounce';
  import companyHttp from '@/api/company';

  interface Props {
    opportunity: Opportunity;
    isCreating?: boolean;
    showCompanies?: boolean;
  }

  interface EditedProfile {
    title: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    isCreating: false,
    showCompanies: true,
  });
  const emit = defineEmits<{
    (e: 'edit-opportunity', value: boolean): void,
    (e: 'update-opportunity', value: EditedProfile): void
    (e: 'create-opportunity'): void
  }>();

  const store = useStore();
  const route = useRoute();
  const toast = useToast();
  const router = useRouter();
  const i18n = useI18n();
  const cardActive = store.getters.cardActive;
  const organization = computed(() => {
    return store.getters['user/organization'];
  });

  const observer = ref(null);

  const editedProfile = ref<EditedProfile>({
    title: props.opportunity?.title || '',
    ...props.opportunity?._company?._id && { _company: props.opportunity._company },
  });
  const setEditedProfile = (data) => {
    editedProfile.value = {
      ...editedProfile.value,
      ...data,
    };
  };
  const closeEdit = () => {
    emit('edit-opportunity', false);
  };

  const errors = ref([]);
  const partnerModelValue = ref(props.opportunity?._company?.name || '');
  const partnerSuggestions = ref([]);

  onMounted(() => {
    if (!props.showCompanies) {
      setEditedProfile({
        _company: organization.value._company,
      });
    }
  });

  const getPartnerSuggestions = debounce(async function() {
    try {
      const { data } = await companyHttp.partnersAutocomplete(partnerModelValue.value);

      partnerSuggestions.value = data;
    } catch (error) {
      toast.show({
        type: 'error',
        message: i18n.t('toast.error-occured'),
      });
    }
  }, 500);

  const handleSelectedCompany = (company) => {
    setEditedProfile({
      _company: company,
    });

    partnerModelValue.value = company.name;
  };

  watch(() => partnerModelValue.value, () => {
    if (!props.showCompanies) {
      return;
    }
    getPartnerSuggestions();
  });

  const saveOpportunity = (async() => {
    if (!props.isCreating) {
      const { data } = await updateCustomJob({
        id: props.opportunity._id,
        title: editedProfile.value.title,
      });

      if (cardActive >= 0) {
        await store.dispatch('updateCard', { index: cardActive, content: data });
      }
      emit('update-opportunity', editedProfile.value);
      closeEdit();
    } else {
      emit('create-opportunity');
    }
  });

  // creating case
  watch(() => ({ title: editedProfile.value.title, _company: editedProfile.value._company }), (value) => {
    if (props.isCreating) {
      emit('update-opportunity', value);
    }
  }, { deep: true });
</script>

<template>
  <div class="relative flex shrink-0 flex-row items-start gap-x-8 px-3 pt-5">
    <div class="relative mt-5">
      <BcAvatar
        :key="opportunity?._company?.logoUrl"
        :src="opportunity?._company?.logoUrl"
        class="size-[80px] shrink-0 rounded-full border-2 border-blue-300"
        @error="setAlternativeImg({event: $event, type: 'companies'})"
      />
      <i class="icon-suitcase absolute bottom-0 right-0 flex size-[30px] items-center justify-center rounded-full bg-[#E1EBFD] text-xs text-blue-500"/>
    </div>
    <div class="relative flex grow flex-row flex-wrap gap-y-2.5">
      <BcAutocomplete
        v-if="showCompanies"
        v-model="partnerModelValue"
        :disabled="!isCreating"
        :error="errors[0]"
        :is-scrollable="false"
        :label="$t('panel-company-edit.company-name')"
        :label-placeholder="false"
        :options="partnerSuggestions"
        :placeholder="$t('panel-company-edit.company-name')"
        :valid="!!editedProfile._company?._id"
        class="panel-company-edit-name__input mb-1"
        icon="companies"
        name="Nom"
        required
        @select="handleSelectedCompany">
        <template #prepend>
          <div
            v-if="errors.length"
            class="panel-company-edit-name__conflict">
            <i class="panel-company-edit-name__conflict-icon icon-alert"/>
          </div>
        </template>
        <template #option="{ option }">
          <div class="panel-company-edit-name__link">
            <BcAvatar
              :src="option?.logoUrl"
              @error="setAlternativeImg({event: $event, type: 'companies'})"
              class="panel-company-edit-name__img"
              size="xs">
            </BcAvatar>
            <p class="panel-company-edit-name__text">
              {{ option.name }}
            </p>
          </div>
        </template>
      </BcAutocomplete>
      <ValidationProvider
        v-slot="{ errors, valid }"
        :name="$t('avatar-dropdown.job-title')"
        :rules="{ required: true, min: 2 }"
        slim>
        <BcInput
          ref="title"
          v-model="editedProfile.title"
          :class="{ invalid: errors.length > 0 }"
          :error="errors[0]"
          :label="$t('avatar-dropdown.job-title')"
          :label-placeholder="false"
          :placeholder="$t('avatar-dropdown.job-title')"
          :valid="valid && editedProfile.title?.length > 0"
          class="flex w-full"
          icon="suitcase"
          name="title"
          required
          type="text">
        </BcInput>
      </ValidationProvider>
      <div
        class="z-0 ml-auto flex flex-row gap-x-2.5">
        <BcButton
          icon-left="cross"
          size="small"
          type="outlined"
          @click="closeEdit">
          {{ $t('global.cancel') }}
        </BcButton>
        <BcButton
          color="primary"
          icon-left="check"
          size="small"
          @click.prevent="saveOpportunity">
          {{ $t('global.save') }}
        </BcButton>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
  .panel-company-edit-name {
    width: calc(50% - 10px);
    flex-wrap: wrap;
    justify-content: space-between;

    &__title {
      width: 100%;
    }

    &__asterisk {
      color: $color-red-mandy;
      margin-right: 10px;
    }

    &__input {
      width: 100%;
    }

    &__not-found {
      align-items: center;
      width: 100%;
      height: 46px;
      padding: 0 20px;
    }

    &__link {
      display: flex;
      width: 100%;
      height: 46px;
      text-decoration: none;
      color: inherit;
      align-items: center;
      padding: 0 20px;

      &--active {
        background-color: $color-blue-dodger;
        color: white;
      }
    }

    &__item {
      display: flex;
      list-style: none;
    }

    &__img {
      overflow: hidden;
      margin-right: 10px;
    }

    &__text {
      @include font-size(16px);
    }

    &__conflict {
      justify-content: center;
      align-items: center;
      background-color: $color-red-mandy;
      color: white;
      border-radius: $border-radius-rounded;
      padding: 5px;
      margin: 0 -10px 0 20px;

      &-icon {
        font-size: $font-size-xs;
        height: 13px;
      }
    }

  }
</style>
