import { http } from './index.js';

export const getProfile = () => {
  return http.get('/profile', {});
};

export const logAs = (id, role) => {
  return http.post('/log-as', { id, role });
};

export const getToken = ({ email, password, role }) => {
  return http.post('/users/token', {
    email,
    password,
    role
  });
};

export const inviteUser  = ({ firstName, lastName, email,organizationId}) => {
  return http.post(`/organizations/${organizationId}/invite-user`, {
    firstName,
    lastName,
    email,
  });
}

export default {
  getProfile,
  logAs,
  getToken,
  inviteUser
};
