<template>
  <div class="technos-modal-delete col-xs-12">
    <h1 class="technos-modal-delete__title">
      Supprimer la techno "{{ technoName }}"?
    </h1>
    <p class="technos-modal-delete__text">
      En supprimant cette techno, vous la perdrez indéfiniment
    </p>
    <p class="technos-modal-delete__text">
      Cette action est irréversible.
    </p>
    <div class="technos-modal-delete__wrapper">
      <bc-button
        class="technos-modal-delete__button"
        bc-background-color="blue"
        bc-radius="30px"
        @click.native="closeModal"
        bc-text-size="14px"
        bc-padding="10px 30px">
        Non, annuler cette action
      </bc-button>
      <bc-button
        class="technos-modal-delete__button"
        bc-radius="30px"
        bc-background-color="red-amaranth"
        @click.native="confirmDelete"
        bc-text-size="14px"
        bc-padding="10px 30px">
        Oui, supprimer cette techno
      </bc-button>
    </div>
  </div>
</template>

<script>
  import BcButton from '@/legacy/ui-kit/components/Button/BcButton';

  /**
   * The modal to make the user confirm his action of deleting his account
   */
  export default {
    name: 'technos-modal-delete',
    components: { BcButton },
    props: {
      technoName: String
    },
    methods: {
      closeModal() {
        this.$emit('close-modal');
      },
      /**
       * The method to send the delete action to the API and changing the modal to the confirm-modal
       */
      confirmDelete() {
        this.$emit('delete-techno');
      }
    }
  };
</script>

<style scoped lang="scss">
  .technos-modal-delete {
    padding: 20px 30px;
    @include bp('tablet') {
      min-width: 500px;
    }
    &__title {
      text-transform: uppercase;
      font-size: $font-size-m;
      justify-content: center;
      text-align: center;
      margin-bottom: 20px;
      font-weight: $font-weight-medium;
      color: $color-black;
    }
    &__text {
      justify-content: center;
      text-align: center;
      font-size: $font-size-s;
      color: $color-black;
    }
    &__wrapper {
      flex-direction: row;
      margin-top: 20px;
      flex-wrap: wrap;
      justify-content: space-around;
      @include bp('tablet') {
        justify-content: space-between;
        flex-wrap: nowrap;
      }
    }
    &__button {
      margin: 5px;
      width: 100%;
      @include bp('tablet') {
        margin: 0;
        margin-right: 20px;
        width: auto;
      }
      padding: 0 10px;
      &:last-of-type {
        @include bp('tablet') {
          margin-right: 0;
        }
      }
    }
  }
</style>
