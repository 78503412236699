<template>
  <div class="card-chat-content">
    <div
      :class="{'card-chat-content__main-wrapper--highlighted': !cardContent.seen }"
      class="card-chat-content__main-wrapper">
      <div class="card-chat-content__caption">
        <picture-avatar
          :img-src="cardContent.pictureUrl ? cardContent.pictureUrl : ''"
          class="card-chat-content__picture">
        </picture-avatar>
      </div>
      <!-- -->
      <div class="card-chat-content__body">
        <title-pokes
          :last-message-at="cardContent.lastMessageAt"
          :last-message-text="cardContent.lastMessageText"
          :new-message="cardContent.seen"
          :text-left="cardContent.name">
        </title-pokes>
      </div>
    </div>
  </div>
</template>

<script>
  import PictureAvatar from '@/components/UIKit/Picture/PictureAvatar';
  import TitlePokes from '@/components/Title/TitlePokes';
  // Macros
  import countdown from '@/common-old/mixins/Timer/countdown';
  // state

  export default {
    name: 'card-chat-content',
    components: {
      PictureAvatar,
      TitlePokes
    },
    props: {
      cardContent: {
        type: Object
      }
    },
    mixins: [countdown]
  };
</script>

<style lang="scss" scoped>
  .card-chat-content {
    width: 100%;
    height: 90px;
    overflow: hidden;
    flex-direction: row;
    @include bp('tablet') {
      align-items: flex-end;
    }

    &__main-wrapper {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      padding: 15px;
      flex-direction: row;
      align-items: center;

      &--highlighted {
        background-color: #3C80F71A;
      }
    }

    &__caption {
      align-items: center;
      margin-right: 15px;
    }

    &__body {
      flex-direction: column;
    }

    &__picture {
      max-height: 50px;
      max-width: 50px;
    }
  }
</style>
