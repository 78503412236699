<template>
  <div
    :class="{'searchbar-jobs--open': isActiveFilter}"
    class="searchbar-jobs">
    <button-default
      @click.native="closeSearchbar"
      bc-background-color="transparent"
      bc-icon="cross"
      bc-icon-size="20px"
      bc-padding="0px"
      bc-text-color="blue-cadet"
      class="searchbar-jobs__icon hidden-md hidden-sm"
      v-if="isActiveFilter">
    </button-default>
    <div class="searchbar-jobs__wrapper">
      <searchbar-autocomplete-jobs
        @on-delete="updateRouteQueries"
        @on-select="addTag($event), updateRouteQueries()"
        class="searchbar-jobs__autocomplete">
      </searchbar-autocomplete-jobs>
    </div>
    <keep-alive>
      <searchbar-filters
        @on-delete="updateRouteQueries"
        class="searchbar-jobs__filters"
        v-if="isActiveFilter">
        <searchbar-filters-location
          @on-select="addTag($event), updateRouteQueries()"
          class="searchbar-filters__wrapper"
          :placeholder="$t('companies.account-management.filter-by-region-department-cities')">
        </searchbar-filters-location>
        <searchbar-filters-jobs-status
          @on-select="replaceTag($event), updateRouteQueries()"
          @on-unselect="removeTag($event), updateRouteQueries()"
          class="searchbar-filters__wrapper">
        </searchbar-filters-jobs-status>
        <searchbar-filters-experience
          @on-select="addTag($event), updateRouteQueries()"
          @on-unselect="removeTag($event), updateRouteQueries()"
          class="searchbar-filters__wrapper">
        </searchbar-filters-experience>
        <searchbar-filters-role
          @on-select="addTag($event), updateRouteQueries()"
          @on-unselect="removeTag($event), updateRouteQueries()"
          class="searchbar-filters__wrapper">
        </searchbar-filters-role>
        <searchbar-filters-domains
          @on-select="addTag($event), updateRouteQueries()"
          @on-unselect="removeTag($event), updateRouteQueries()"
          class="searchbar-filters__wrapper">
        </searchbar-filters-domains>
        <searchbar-filters-contract
          @on-select="replaceTag($event), updateRouteQueries()"
          @on-unselect="setTags($event), updateRouteQueries()"
          class="searchbar-filters__wrapper">
        </searchbar-filters-contract>
        <searchbar-filters-salary
          @on-reset="removeTag($event), updateRouteQueries()"
          @on-select="replaceTag($event), updateRouteQueries()"
          class="searchbar-filters__wrapper"
          v-show="$route.query.contracts === 'cdi' || $route.query.contracts === 'freelance'">
        </searchbar-filters-salary>
        <searchbar-filters-remote
          @on-select="addTag($event), updateRouteQueries()"
          @on-unselect="removeTag($event), updateRouteQueries()"
          class="searchbar-filters__wrapper">
        </searchbar-filters-remote>
      </searchbar-filters>
    </keep-alive>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  // helpers
  import SearchbarDispatcher from '@/helpers/searchbar/dispatcher';
  import { buildJobStatusTag } from '@/helpers/searchbar/factories/jobsTag';
  import { mapLocationQuery, splitQuery } from '@/helpers/url/decode_url';
  import {
    buildContractTag,
    buildDomainTag,
    buildExperienceTag,
    buildLocationsTag,
    buildPhoneTag,
    buildRemoteTag,
    buildRoleTag,
    buildSalaryTag,
    buildSearchTag,
    buildTechnosTag,
  } from '@/helpers/searchbar/factories';
  // components
  import SearchbarFiltersLocation from '@/components/Searchbar/SearchbarFilters/SearchbarFiltersLocation';
  import SearchbarFilters from '@/components/Searchbar/SearchbarFilters';
  import ButtonDefault from '@/legacy/ui-kit/components/Button/BcButton';
  import SearchbarFiltersExperience from '@/components/Searchbar/SearchbarFilters/SearchbarFiltersExperience';
  import SearchbarFiltersRole from '@/components/Searchbar/SearchbarFilters/SearchbarFiltersRole';
  import SearchbarFiltersDomains from '@/components/Searchbar/SearchbarFilters/SearchbarFiltersDomainsLegacy';
  import SearchbarFiltersContract from '@/components/Searchbar/SearchbarFilters/SearchbarFiltersContract';
  import SearchbarFiltersSalary from '@/components/Searchbar/SearchbarFilters/SearchbarFiltersSalary';
  import SearchbarFiltersRemote from '@/components/Searchbar/SearchbarFilters/SearchbarFiltersRemote';
  import SearchbarAutocompleteJobs from '@/components/Searchbar/SearchbarJobs/SearchbarAutocompleteJobs';
  import SearchbarFiltersJobsStatus
    from '@/components/Searchbar/SearchbarFilters/SearchbarFiltersStatus/SearchbarFiltersJobsStatus';

  export default {
    name: 'searchbar-jobs',
    components: {
      SearchbarFiltersJobsStatus,
      SearchbarAutocompleteJobs,
      SearchbarFiltersRemote,
      SearchbarFiltersSalary,
      SearchbarFiltersContract,
      SearchbarFiltersDomains,
      SearchbarFiltersRole,
      SearchbarFiltersExperience,
      SearchbarFiltersLocation,
      ButtonDefault,
      SearchbarFilters,
    },
    mixins: [SearchbarDispatcher],
    data() {
      return {
        mapComplexQuery: {
          search: {
            queryBuilder: splitQuery,
            tagBuilder: buildSearchTag,
          },
          locations: {
            queryBuilder: mapLocationQuery,
            tagBuilder: buildLocationsTag,
          },
          status: {
            queryBuilder: splitQuery,
            tagBuilder: buildJobStatusTag,
          },
          experience: {
            queryBuilder: splitQuery,
            tagBuilder: buildExperienceTag,
          },
          role: {
            queryBuilder: splitQuery,
            tagBuilder: buildRoleTag,
          },
          domains: {
            queryBuilder: splitQuery,
            tagBuilder: buildDomainTag,
          },
          remotes: {
            queryBuilder: splitQuery,
            tagBuilder: buildRemoteTag,
          },
        },
        mapSimpleQuery: {
          contracts: {
            tagBuilder: buildContractTag,
          },
          phone: {
            tagBuilder: buildPhoneTag,
          },
        },
      };
    },
    computed: {
      ...mapState({
        isActiveFilter: state => state.searchbar.isActiveFilter,
        technos: state => state.tags.technos,
      }),
      ...mapGetters({
        searchQueries: 'searchbarFiltersComputed',
      }),
      routeQueries() {
        return {
          ...this.searchQueries,
        };
      },
    },
    mounted() {
      this.setupTags();
    },
    methods: {
      setupTags() {
        const mapQueries = Object.keys(this.$route.query);
        const filteredMapQueries = mapQueries.filter(key => this.$route.query[key]);

        if (filteredMapQueries.includes('salary-min') || filteredMapQueries.includes('salary-max')) {
          const unit = this.$route.query.contracts === 'cdi' ? 'K€' : '€/jour';
          const range = [this.$route.query['salary-min'], this.$route.query['salary-max']];
          const tag = buildSalaryTag({ unit, range });

          this.addTag(tag);
        }

        if (filteredMapQueries.includes('technos')) {
          const technosIds = splitQuery(this.$route.query.technos);
          const technos = technosIds.map(id => this.technos.find(techno => techno._id === id));

          technos.forEach(techno => {
            this.addTag(buildTechnosTag(techno));
          });
        }

        filteredMapQueries.forEach(value => {
          if (this.mapComplexQuery[value]) {
            const queries = this.mapComplexQuery[value].queryBuilder(this.$route.query[value]);

            queries.forEach(q => {
              this.addTag(this.mapComplexQuery[value].tagBuilder(q));
            });
          }

          if (this.mapSimpleQuery[value]) {
            this.addTag(this.mapSimpleQuery[value].tagBuilder(this.$route.query[value]));
          }
        });
      },
      updateRouteQueries() {
        this.$router.push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            ...this.routeQueries,
            page: 1,
          },
        });
      },
      closeSearchbar() {
        this.$store.dispatch('resetAndCloseSearchBar');
      },
    },
    beforeDestroy() {
      this.closeSearchbar();
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-jobs {
    flex-direction: column;
    z-index: 5;
    @include bp('tablet') {
      position: relative;
    }

    &__icon {
      position: absolute !important;
      top: 15px;
      right: 15px;
    }

    &--open {
      display: block;
      position: fixed;
      background: $color-white;
      overflow: auto;
      top: 0;
      left: 0;
      height: 100vh;
      min-height: 100vh;
      width: 100%;
      padding: 85px 15px 50px;
      @include bp('tablet') {
        display: flex;
        position: initial;
        overflow: initial;
        height: initial;
        min-height: initial;
        padding: initial;
        background: initial;
      }
    }

    &__autocomplete {
      margin-bottom: 20px;
    }

    &__wrapper {
      margin-bottom: -10px;
      @include bp('tablet') {
        padding: 0;
      }
    }

    &__input {
      width: 100%;
    }

    &__filters {
      margin: 10px -15px 50px;
      @include bp('tablet') {
        margin: 0;
        border-radius: 4px;
      }
    }
  }
</style>
