<script lang="ts" setup>
  import CampaignsTemplatesPreviewItem
    from '@/components/Campaigns/CampaignsTemplates/CampaignsTemplatesPreviewItem.vue';
  import { CampaignAction } from '@/domains/models/Campaign';
  import { computed } from 'vue';
  import { useI18n } from '@/i18n/i18n';

  const props = defineProps<{
    actions: CampaignAction[]
  }>();

  const { t } = useI18n();

  const title = computed(() => props.actions.length ? t('campaigns.template-actions-preview') : t('campaigns.create-actions-by-yourself'));
</script>

<template>
  <div class="flex w-full max-w-[350px] grow flex-col gap-3.5 bg-neutral-100 px-[20px] py-[30px]">
    <div class="flex items-center gap-2.5">
      <h3 class="font-bold text-blue-800 first-letter:capitalize">
        {{ title }}
      </h3>
      <hr
        v-if="actions.length"
        class="flex grow border-b-0 border-blue-200"/>
    </div>
    <div class="flex flex-col gap-2.5">
      <CampaignsTemplatesPreviewItem
        v-for="action in actions"
        :key="action._id"
        :action="action"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
