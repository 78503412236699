import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      technos: state => state.tags.technos,
    }),
    ...mapGetters([
      'matches',
    ]),
    /**
     * Return all the technos whose the id are in the $route.query
     */
    technosTags() {
      return this.searchbarTags.filter(tag => tag.type === 'technos');
    },
    filteredTechnosTags() {
      return this.technosTags.length > 0 ? this.technosTags.map(tag => {
        return this.technos.find(techno => techno._id === tag._id);
      }) : [];
    },
    /***
     * This computed property returns the technos unused from the autocomplete list
     *
     */
    technosFiltered() {
      return this.technos.filter(a1 => {
        return this.filteredTechnosTags
          .map(a2 => {
            if (a2._id) {
              return a2.name;
            }
          }).indexOf(a1.name) < 0;
      });
    },
    technosSuggestions() {
      return this.matches.slice(0, this.matchesLimit).map(match => {
        return {
          icon: 'tool',
          ...match,
          type: 'techno',
        };
      });
    },
  },
};
