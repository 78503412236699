import { useApi } from './useApi';
import { 
  GET_UNIPILE_ACCOUNTS, 
  SYNC_FETCH_UNIPILE_ACCOUNTS, 
  DELETE_UNIPILE_ACCOUNT,
  GET_UNIPILE_PROVIDER_ID,
  GET_UNIPILE_ACCOUNT_LINK 
} from './routes';
import { UnipileAccount } from '@/domains/UnipileAccount';

export function useUnipileAccountsApi() {
  const { getList, del, post, get, loading, requestError } = useApi();

  async function fetchAccounts() {
    const data = await getList<UnipileAccount>(GET_UNIPILE_ACCOUNTS());
    console.log('data fetch', data);
    return data;
  }

  async function syncAndFetchAccounts() {
    const data = await post<UnipileAccount[]>(SYNC_FETCH_UNIPILE_ACCOUNTS()) || [];
    return data;
  }

  async function deleteAccount(id?: string) {
    if (!id) return;
    await del(DELETE_UNIPILE_ACCOUNT(id));
    return { id };
  }

  async function getProviderId(publicId: string, providerSlug: string) {
    if (!publicId) return null;
    try {
      const data = await get(GET_UNIPILE_PROVIDER_ID(), { publicId, providerSlug });
      return data || null;
    } catch (error) {
      console.error('Failed to fetch provider IDs:', error);
      return null;
    }
  }

  async function getAuthLink(redirectUri: string) {
    const data = await get<{ url: string }>(GET_UNIPILE_ACCOUNT_LINK(), { 
      redirect_uri: redirectUri 
    });
    return data;
  }

  async function getReconnectLink(redirectUri: string, id: string) {
    const data = await get<{ url: string }>(GET_UNIPILE_ACCOUNT_LINK(), { 
      redirect_uri: redirectUri,
      account_id: id
    });
    return data;
  }

  return {
    fetchAccounts,
    syncAndFetchAccounts,
    deleteAccount,
    getProviderId,
    getAuthLink,
    getReconnectLink,
    loading,
    requestError,
  };
} 