<template>
  <bc-modal
    :active="isModalOpen"
    @close="closeModal(), moveProcessBack(process,'onboarding')"
    class="modal-process-start-validation">
    <div class="modal-process-start-validation__content">
      <div class="modal-process-start-validation__header">
        <p class="modal-process-start-validation__title">
          {{ $t('hunt.start-validation') }}
        </p>
        <p class="modal-process-start-validation__subtitle">
          <span class="modal-process-start-validation__name">{{ coderFullName }}</span>
          {{ $t('hunt.did-he-get-off-to-a-good-start') }}
          <span class="modal-process-start-validation__name">{{ jobName }}</span>
          {{ $t('hunt.in') }}
          <span
            class="modal-process-start-validation__name">{{ (process._company || {}).name }}</span>
        </p>
      </div>
      <bc-divider
        :bc-divider-color-opacity="0.2"
        bc-divider-color="blue-dark-cello"
        class="modal-process-start-validation__divider">
      </bc-divider>
      <div class="modal-process-start-validation__body">
        <div
          class="modal-process-start-validation__wrapper modal-process-start-validation__wrapper--centered">
          <p
            class="modal-process-start-validation__label modal-process-start-validation__label-date">
            {{ $t('hunt.confirm-start-date') }} :
          </p>
          <v-date-picker
            :locale="locale"
            :popover="{ visibility: 'focus' }"
            class="modal-process-start-validation__date-picker"
            v-model="startsAt">
          </v-date-picker>
        </div>
      </div>
      <bc-divider
        :bc-divider-color-opacity="0.2"
        bc-divider-color="blue-dark-cello"
        class="modal-process-start-validation__divider">
      </bc-divider>
      <bc-rich-text-editor-bubble
        :is-editable="true"
        @blur="setIsTyping()"
        @focus="setIsTyping(true)"
        class="modal-process-start-validation__textarea is-full-width font-size-s border-radius-m"
        :placeholder="$t('hunt.first-candidate-impressions')"
        position="absolute"
        ref="textarea"
        v-autosize="content"
        v-model="content">
      </bc-rich-text-editor-bubble>
      <bc-divider
        :bc-divider-color-opacity="0.2"
        bc-divider-color="blue-dark-cello"
        class="modal-process-start-validation__divider">
      </bc-divider>
      <div class="modal-process-start-validation__footer">
        <bc-button
          @click="closeModal(), moveProcessBack(process,'onboarding')"
          class="modal-process-start-validation__button"
          size="small"
          type="outlined">
          {{ $t('generics.cancel') }}
        </bc-button>
        <bc-button
          @click="setValidation"
          class="modal-process-start-validation__button"
          color="success"
          size="small">
          {{ $t('generics.validate') }}
        </bc-button>
      </div>
    </div>
  </bc-modal>
</template>

<script>
  import { mapActions } from 'vuex';

  import BcButton from '@/ui-kit/components/BcButton';
  import BcDivider from '@/legacy/ui-kit/components/Dividers/BcDivider';
  import BcRichTextEditorBubble from '@/ui-kit/components/BcRichTextEditor/BcRichTextEditorBubble';

  import { processController } from '@/managers/processes/controller';
  import { processStatus } from '@/macros/processes/process';
  import processModal from '@/mixins/Processes/processModal';
  import BcModal from '@/ui-kit/components/BcModal/BcModal';
  import locale from '@/mixins/i18n/locale';

  export default {
    name: 'modal-process-start-validation',
    components: { BcModal, BcRichTextEditorBubble, BcDivider, BcButton },
    props: {
      process: {
        type: Object,
        default: () => ({}),
      },
      isModalOpen: {
        type: Boolean,
        default: false,
      },
    },
    mixins: [processModal, locale],
    data() {
      return {
        startsAt: (this.process || {}).startsAt || new Date(),
        content: '',
      };
    },
    computed: {
      jobName() {
        return this.process._hunt ? this.process._hunt.poste : (this.process._job || {}).name;
      },
    },
    methods: {
      ...mapActions([
        'updatePanelProfile',
      ]),
      async setValidation() {
        try {
          const newStatus = processStatus.onboarding;

          const updatedProcess = await processController.move(
            this.process._id,
            newStatus,
            {
              startsAt: this.startsAt,
              content: this.content,
            });

          this.updatePanelProfile({ processState: updatedProcess._coder.processState });
          this.$emit('profile-updated', { processState: updatedProcess._coder.processState });

          this.$emit('process-update', {
            updatedProcess: updatedProcess,
            stepName: newStatus,
          });

          this.moveProcessForward(updatedProcess, processStatus.dealt, newStatus);
          this.closeModal();
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      setIsTyping(value) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            typing: value,
          },
        }).catch(() => {
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-process-start-validation {
    &__content {
      width: 405px;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      padding: 30px 0;
      min-width: 320px;
    }

    &__header {
      flex-direction: column;
      align-items: center;
      padding: 0 40px;
    }

    &__divider {
      margin: 20px 0;
    }

    &__title {
      text-transform: uppercase;
      line-height: 17px;
      color: $color-primary;
      font-weight: $font-weight-bold;
      margin-bottom: 10px;
    }

    &__label {
      align-items: center;
    }

    &__subtitle {
      width: 300px;
      text-align: center;
      font-size: $font-size-s;
      color: $color-secondary;
    }

    &__name {
      display: initial;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      color: $color-primary;
    }

    &__wrapper {
      align-items: center;
      font-size: $font-size-s;
      line-height: 17px;
      color: $color-secondary;
      justify-content: flex-end;
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__body {
      flex-direction: column;
    }

    &__button {
      font-size: $font-size-s;
      margin: 0 5px;
    }

    &__label {
      justify-content: flex-end;
      align-items: center;

      &-date {
        text-align: right;
      }
    }

    &__date-picker {
      color: $color-primary;
      max-width: 155px;
      margin-left: 15px;

      & :deep() {
        input {
          outline: none;
          box-shadow: none;
          border: 2px solid rgba($color-primary, 0.5);
          border-radius: 50px;
          text-align: center;
          color: $color-primary;
          font-size: $font-size-s;
          padding: 5px 10px;

          &:focus {
            border-color: $color-primary;
          }
        }
      }
    }

    &__textarea {
      width: 350px;
      min-height: 120px;
      max-height: 250px;
      border-radius: $border-radius-s;
      border: 1px solid #A4AFBE;
      padding: 10px 20px;
      margin: 0;
    }
  }
</style>
