<template>
  <div class="modal-leave-edit-confirmation border-radius-s is-align-items-center is-column">
    <div class="modal-leave-edit-confirmation__header">
      <p class="modal-leave-edit-confirmation__text">❌👋</p>
      <p class="modal-leave-edit-confirmation__text mt-1">
        {{ $t( 'panel-company-edit.modal-leave-edit.title' ) }}
      </p>
    </div>
    <div class="modal-leave-edit-confirmation__buttons">
      <bc-button
        @click="continueEdit"
        class="modal-leave-edit-confirmation__button"
        type="outlined">
        {{ $t( 'panel-company-edit.modal-leave-edit.continue' ) }}
      </bc-button>
      <bc-button
        @click="leaveEdit"
        class="modal-leave-edit-confirmation__button"
        color="error">
        {{ $t( 'panel-company-edit.modal-leave-edit.leave' ) }}
      </bc-button>
    </div>
  </div>
</template>

<script>
  import BcButton from '@/ui-kit/components/BcButton/BcButton';

  export default {
    name: 'modal-leave-edit-confirmation',
    components: { BcButton },
    methods: {
      leaveEdit() {
        this.$emit('leave-edit');
      },
      continueEdit() {
        this.$emit('continue-edit');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-leave-edit-confirmation {
    justify-content: space-around;
    padding: 50px;
    width: 540px;
    height: 259px;

    &__header {
      flex-direction: column;
      margin-bottom: 20px;
    }

    &__text {
      color: $color-tertiary;
      text-align: left;
      font-size: $font-size-xl;
      font-weight: $font-weight-bold;
      line-height: 138.9%;
    }

    &__buttons {
      width: 100%;
    }

    &__button {
      font-size: $font-size-s;
      min-width: 150px;
      justify-content: center;
      margin: 0 5px;
      flex: 1;
    }
  }
</style>
