<template>
  <views class="onboarding-views">
    <views-container class="onboarding-container">
      <onboarding-header class="mt-2"></onboarding-header>
      <onboarding-chapters class="mt-5"></onboarding-chapters>
    </views-container>
    <router-view></router-view>
  </views>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';
import Views from '@/components/Views/Views';
import ViewsContainer from '@/components/Views/ViewsContainer';
import OnboardingHeader from '@/components/Onboarding/OnboardingHeader';
import OnboardingChapters from '@/components/Onboarding/OnboardingChapters';
import coaches from '@/api/coaches';

export default {
  name: 'onboarding',
  components: {
    Views,
    ViewsContainer,
    OnboardingHeader,
    OnboardingChapters,
  },
  mounted() {
    if (this.profile.onboardingProgress) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          chapter: this.profile.onboardingProgress.chapter,
          section: this.profile.onboardingProgress.section,
        }
      })
    }
  },
  data() {
    return {
      cancelTokenSource: undefined,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
    }),
  },
  methods: {
    ...mapActions({
      updateOnboardingProgress: 'user/updateOnboardingProgress',
    }),
    cancelUpdateOnboarding() {
      if (!this.cancelTokenSource) return;
      this.cancelTokenSource.cancel('manualCancel');
    },
    async updateOnboarding({ section, chapter }) {
      if (!this.profile._id) return;

      try {
        this.cancelUpdateOnboarding();

        this.cancelTokenSource = axios.CancelToken.source();
        const { data } = await coaches.updateCoachProfile({
          id: this.profile._id,
          updatedCoach: {
            onboardingProgress: {
              section,
              chapter,
            },
          },
          cancelTokenSource: this.cancelTokenSource,
        });

        this.updateOnboardingProgress({
          section: data.onboardingProgress.section,
          chapter: data.onboardingProgress.chapter,
        });
      } catch (err) {
        if (err.message === 'manualCancel') return

        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
  },
  watch: {
    '$route.query'(to, from) {
      if (to.section !== from.section || to.chapter !== from.chapter) {
        this.updateOnboarding({
          section: to.section,
          chapter: to.chapter,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding-views {
  flex: 1;
  flex-direction: column;
}

.onboarding-container {
  height: 100%;

  &:deep() {
    & > div {
      height: 100%;
    }
  }
}
</style>
