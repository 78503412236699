<template>
  <views-tabs :tabs="tabs"></views-tabs>
</template>

<script>
import ViewsTabs from '@/components/Views/ViewsTabs';

export default {
  name: 'configuration-preferences-tabs',
  components: {
    ViewsTabs,
  },
  computed: {
    tabs() {
      return [
        {
          key: 'share-processes',
          label: this.$i18n.t('generics.share-processes'),
          to: '/configuration/share-processes',
        },
        {
          key: 'linkedin-extension',
          label: this.$i18n.t('avatar-dropdown.linkedin-extension'),
          to: '/configuration/linkedin-extension',
        },
      ];
    },
  },
};
</script>
