import { getProspectionPipe } from '@/managers/prospectionPipe/useCases/getProspectionPipe';
import { getProspectionPipeCount } from '@/managers/prospectionPipe/useCases/getProspectionPipeCount';



const get = ({ coachIds, status, sort, teamIds, page }) => {
  return getProspectionPipe({ coachIds, status, sort, teamIds, page });
};

const getCount = ({ coachIds, status, teamIds }) => {
  return getProspectionPipeCount({ coachIds, status, teamIds });
};

export const prospectionPipeController = {
  get,
  getCount
};

export default {
  prospectionPipeController,
};
