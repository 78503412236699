import { listenResumeHistories, getResumeHistories, setResumeClosedAt } from "../../../firebase"

export default {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {
    resumeUpload(state) {
      return state.list[0];
    },
    hasActiveResumeUpload(state) {
      return state.list[0]?.active || false;
    },
    isDone(state) {
      if (state.list.length) {
        return Object.keys(state.list[0]?.files || {}).every(key => {
          const file = state.list[0].files[key];
          return file.status !== 'PENDING';
        });
      }
      return false;
    },
    progressCount(state) {
      if (state.list.length) {
        let count = 0;
        Object.keys(state.list[0]?.files || {}).forEach(key => {
          const file = state.list[0].files[key];
          if (file.status !== 'PENDING') {
            count++;
          }
        });
        return count;
      }
      return 0;
    },
  },
  actions: {
    async getResumeHistories({ commit }, { coachId }) {
        const list = await getResumeHistories({ coachId });
        commit('setList', list);
    },
    async listenResumeHistories({ commit }, { coachId }) {
        const listener = await listenResumeHistories({ coachId }, ({ type, data }) => {
          if (type === 'modified') {
            commit('updateList', data);
          }
        });

        return listener;
    },
    async setClosedAt({ commit }, { coachId, groupId }) {
      await setResumeClosedAt({ coachId, groupId });
    }
  },
  mutations: {
    setList(state, list) {
      state.list = list;
    },
    updateList(state, item) {
      const findedIndex = state.list.findIndex(it => it.groupId === item.groupId);
      if (findedIndex !== -1) {
        const list = [...state.list];
        list[findedIndex] = item;
        state.list = [...list];
      }
    }
  },
}
