<script setup lang="ts">
import EmailAttachment, { EventData } from './EmailAttachment.vue';

import { defineProps, defineEmits, withDefaults, computed, ref } from 'vue';

interface Props {
  files: File[];
  attachments?: { filename: string; path: string; }[];
  canRemove?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  files: () => [] as File[],
  attachments: () => [],
  canRemove: true,
});
const emits = defineEmits<{
  (e: 'on-remove', data: EventData): void,
}>();

const showAll = ref(false);
const showLimit = 4;

const filesDisplayList = computed(() => {
  if (props.files.length > showLimit && !showAll.value) {
    return props.files.slice(0, showLimit);
  }
  return props.files;
});

const onToggleShowAll = () => {
  showAll.value = !showAll.value;
}

const onRemove = (data: EventData) => {
  emits('on-remove', data);
}

const onDownload = ({ file, attachment }: EventData) => {
  if (file) {
    const url = URL.createObjectURL(file);
    download(url, file.name);
  }
  if (attachment) {
    window.open(attachment.path, '__blank');
  }
}

const onOpen = ({ file, attachment }: EventData) => {
  if (file) {
    const url = URL.createObjectURL(file);
    window.open(url, '__blank');
  }
  if (attachment) {
    window.open(attachment.path, '__blank');
  }
}

const download = (url: string, name: string) => {
  const a = document.createElement('a')
  a.href = url
  a.download = name
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
</script>

<template>
  <div class="is-column">
    <button
      v-if="files.length > showLimit"
      class="mb-3 items-center justify-end"
      @click="onToggleShowAll"
    >
      <p class="text-sm text-blue-500 underline">{{ showAll ? $t('generics.reduce') : $t('generics.show-all') }}</p>
      <div class="ml-2 size-5 items-center justify-center rounded-full bg-blue-500 text-xs text-white">{{ files.length }}</div>
    </button>
    <div class="flex flex-wrap gap-x-5 gap-y-1">
      <EmailAttachment
        v-for="attachment in attachments"
        :key="attachment.path"
        :attachment="attachment"
        :canRemove="canRemove"
        @on-remove="onRemove"
        @on-download="onDownload"
        @on-open="onOpen"
      />
      <EmailAttachment
        v-for="file in filesDisplayList"
        :key="file.lastModified + file.name"
        :file="file"
        :canRemove="canRemove"
        @on-remove="onRemove"
        @on-download="onDownload"
        @on-open="onOpen"
      />
    </div>
  </div>
</template>
