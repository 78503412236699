<template>
  <select-container
    :label="$t('companies.account-management.contract-type')"
    :required="required"
    icon="file">
    <bc-dropdown
      :id="$attrs.id"
      :is-scrollable="true"
      :limit="3"
      :options="contractsOptions"
      :required="required"
      class="custom-jobs-contract-select__dropdown"
      :close-dropdown-at-select="false"
      is-expanded>
      <template #label="{ isDropdownOpen }">
        <bc-dropdown-trigger
          :is-arrow="true"
          :is-dropdown-open="isDropdownOpen"
          :text="selectedContractLabel"
          type="button">
        </bc-dropdown-trigger>
      </template>
      <template #option="{ activeIndex, index, option }">
        <bc-dropdown-item
          :is-focus="activeIndex === index"
          :is-selected="selectedContracts.includes(Number(option.apiCode))"
          :text="option.label"
          @click.native="selectContract(option)">
        </bc-dropdown-item>
      </template>
    </bc-dropdown>
  </select-container>
</template>

<script lang="ts" setup>
  import { contractsLabels } from '@/api/custom-jobs';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
  import SelectContainer from '@/components/Select/SelectContainer';
  import { computed } from 'vue';

  const props = withDefaults(defineProps<{
    selectedContracts: number[];
    required: boolean;
  }>(), {
    required: false,
    selectedContracts: () => [],
  });
  const emit = defineEmits<{
    (e: 'select-contracts', value: number[]): void
  }>();

  const contractsOptions = computed(() => Object.entries(contractsLabels).map(([apiCode, label]) => ({
    apiCode,
    label,
  })));

  const selectedContractLabel = computed(() => props.selectedContracts.map((contract) => contractsLabels[contract]).join(', '));

  function selectContract(option) {
    if (props.selectedContracts.includes(Number(option.apiCode))) {
      emit('select-contracts', props.selectedContracts.filter((contract) => contract !== Number(option.apiCode)));
      return;
    }
    const deduplicateContracts = Array.from(new Set([...props.selectedContracts, option.apiCode].map(Number)));

    emit('select-contracts', deduplicateContracts);
  }
</script>

<style lang="scss" scoped>
  .custom-jobs-contract-select {
    &__dropdown {
      width: 100%;

      :deep() {
        .bc-dropdown-trigger {
          height: auto;
        }
      }
    }
  }
</style>
