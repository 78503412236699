import { http } from '@/api/index';

export const getWttjProfessionsList = ({ page = 1, perPage = 99 }) => {
  return http.get(`/wttj/professions`, {
    params: {
      page,
      perPage,
    }
  });
}

export const getWttjLanguages = () => {
  return http.get(`/wttj/languages`);
}

export const getWttjDepartmentsList = ({ page = 1, perPage = 99 }) => {
  return http.get(`/wttj/departments`, {
    params: {
      page,
      perPage,
    }
  });
}
