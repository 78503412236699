type SearchParam = string | (string | null)[];

export function getFirstSearchParam(searchParam?: SearchParam): string | undefined {
  if (Array.isArray(searchParam)) {
    if (searchParam.length) {
      return searchParam[0] ?? undefined;
    }
  } else {
    return searchParam;
  }
}

export function getSearchParams(searchParam?: SearchParam): string[] {
  if (!searchParam) {
    return [];
  }

  if (Array.isArray(searchParam)) {
    return searchParam.filter(value => value !== null);
  }

  return searchParam.split(',').filter(value => value !== '');
}

export function searchParamToString(
  searchParam?: SearchParam,
  defaultValue: string = ''
): string {
  return getFirstSearchParam(searchParam) ?? defaultValue;
}

export function searchParamToNumber(searchParam?: SearchParam, defaultValue: number = 0): number {
  const numberOrNaN = Number(getFirstSearchParam(searchParam));
  return Number.isNaN(numberOrNaN) ? defaultValue : numberOrNaN;
}

export function pathParamToString(param?: string | string[]): string {
  if (Array.isArray(param)) {
    if (param.length) {
      return param[0];
    }
    return '';
  } else {
    return param || '';
  }
}

export function toInt(n: number): number | undefined {
  if (Number.isNaN(n)) {
    return
  }
  return n
}
