<template>
  <div class="modal-process-confirm-form-deal is-column is-align-items-center">
    <div class="modal-process-confirm-form-deal__text-container is-column">
      <p class="modal-process-confirm-form-deal__text">
        Bonne nouvelle
      </p>
      <p class="modal-process-confirm-form-deal__text">
        Votre Form Deal est complet !
      </p>
    </div>
    <p class="modal-process-confirm-form-deal__sub-text">
      Maintenant que l'on a toutes les infos vous pouvez envoyer le Form Deal à la Gestion, pour qu’elle puisse gérer la facture.
    </p>
    <bc-checkbox
      @input="setHasCorrectInfos"
      class="modal-process-confirm-form-deal__checkbox"
      id="correct-informations-in-modal">
      Les infos insérées automatiquement sont correctes
    </bc-checkbox>
    <div class="is-row is-align-items-center modal-process-confirm-form-deal__cta-container is-justify-content-space-between">
      <bc-button
        @click.stop.native="cancelConfirm"
        type="outlined"
        class="modal-process-form-deal__cta"
        color="secondary">
        Attends, je suis pas certain des infos
      </bc-button>
      <bc-button
        @click.stop.once.native="sendFormDeal"
        :disabled="!hasCorrectInfos"
        class="modal-process-form-deal__cta"
        color="tertiary">
        Envoyer à la gestion
      </bc-button>
    </div>
  </div>
</template>
<script>
  import BcCheckbox from "@/ui-kit/components/BcCheckbox/BcCheckbox";
  import BcButton from "@/ui-kit/components/BcButton/BcButton";

  export default {
    name: 'modal-process-confirm-form-deal',
    components: { BcButton, BcCheckbox },
    data() {
      return {
        hasCorrectInfos: false,
      }
    },
    methods: {
      setHasCorrectInfos() {
        this.hasCorrectInfos = !this.hasCorrectInfos;
      },
      cancelConfirm() {
        this.$emit('confirm-cancel');
      },
      sendFormDeal() {
        this.$emit('send-form-deal');
      }
    }
  };
</script>
<style scoped lang=scss>
  .modal-process-confirm-form-deal {
    height: 243px;
    width: 503px;
    padding: 20px;

    &__text-container {
      margin-bottom: 25px;
    }

    &__text {
      @include font-bold(16px);
      color: $color-primary;
      letter-spacing: -0.1px;
      line-height: 19px;
      text-align: center;
    }

    &__sub-text {
      @include font-size(14px);
      color: $color-secondary;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      margin-bottom: 30px;
    }

    &__checkbox {
      margin-bottom: 20px;
    }

    &__cta-container {
      width: 100%;
    }
  }
</style>
