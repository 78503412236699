<template>
  <div class="onboarding-header">
    <div class="onboarding-header__titles">
      <h3 class="onboarding-header__title">{{
        $t('onboarding.welcome-name', { name: profile.firstName })
      }}</h3>
      <p class="onboarding-header__subtitle body-m mt-2">{{ $t('onboarding.welcome-subtitle') }}</p>
    </div>
    <div class="onboarding-header__buttons">
      <bc-button color="primary-base" icon-left="headset" @click="handleClickDemo">
        {{ $t('onboarding.request-a-demo') }}
      </bc-button>
    </div>

    <bc-modal :active="showTeamModal" @close="toggleTeamModal">
      <team-modal-add-user
        @close-modal="toggleTeamModal"
        @update-users-accounts="updateUserPlan">
      </team-modal-add-user>
    </bc-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { inviteUser } from '@/api/user';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcModal from '@/ui-kit/components/BcModal/BcModal';
import TeamModalAddUser from '@/components/Team/TeamModal/TeamModalAddUser';

export default {
  name: 'onboarding-header',
  components: {
    BcButton,
    BcModal,
    TeamModalAddUser,
  },
  data() {
    return {
      showTeamModal: false,
    };
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
    }),
  },
  methods: {
    handleClickDemo() {
      window.open('https://calendly.com/marvin-recruiter/30min', '__blank');
    },
    toggleTeamModal() {
      this.showTeamModal = !this.showTeamModal;
    },
    async updateUserPlan(data) {
      try {
        const invitedUser = await inviteUser({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          organizationId: this.profile._organization._id,
        });

        let newOrganization = this.profile._organization;
        newOrganization.nbUsers = this.nbUsers += 1;
        await this.$store.dispatch('user/updateProfile', {
          _organization: newOrganization,
        });
        this.$toast.show({
          type: 'success',
          title: this.$i18n.t('congratulations'),
          message: this.$i18n.t('toast.you-have-invited-a-new-user-to-your-space'),
        });
        // this.toggleTeamModal();
        // this.updateUserTeamList(invitedUser);
      } catch (error) {
        this.$toast.show({
          type: 'error',
          title: this.$i18n.t('toast.error-creating-user'),
          message: this.$t('toast.error-occured'),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding-header {
  justify-content: space-between;

  &__title {
    font-size: 28px;
    font-weight: $font-weight-bold;
    color: $color-blue-dark-cello;
  }

  &__subtitle {
    color: $color-blue-heavy-dark;
  }

  &__titles {
    flex-direction: column;
  }

  &__buttons {
    gap: 20px;
    height: 20px;
    align-items: flex-start;
  }
}
</style>
