<template>
  <div class="bc-custom-field-link">
    <bc-input
      class="bc-custom-field-link__input"
      :value="link"
      :name="customField.key"
      :valid="link !== ''"
      type="text"
      @input="handleInput">
      <template #prepend="{ isFocus }">
        <div
          v-if="customField.baseUri"
          :class="{
            'bc-custom-field-link__prepend--focus': isFocus,
            'bc-custom-field-link__prepend--empty': !link,
          }"
          class="bc-custom-field-link__prepend--valid bc-custom-field-link__prepend">
          {{ customField.baseUri }}
        </div>
      </template>
    </bc-input>
  </div>
</template>

<script>
import BcInput from '@/ui-kit/components/BcInput/BcInput';

export default {
  name: 'bc-custom-field-link',
  components: {
    BcInput,
  },
  props: {
    customField: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (this.selected) {
      this.link = this.selected;
    } else if (this.customField.defaultValue?.length) {
      this.link = this.customField.defaultValue;
    }
  },
  data() {
    return {
      link: '',
    };
  },
  methods: {
    handleInput(value) {
      this.link = value;
      this.$emit('on-update', { key: this.customField.key, value: value || undefined });
    },
  },
  watch: {
    selected(value) {
      this.link = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.bc-custom-field-link {
  width: 100%;

  @include bp('tablet') {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    flex-basis: 100%;
  }

  &__input {
    flex-basis: 100%;
    width: 100%;
    margin-top: 0;
  }

  & :deep() {
    .bc-input__input {
      width: 100%;
      padding-left: 15px;
    }
  }

  &__prepend {
    background: $color-primary;
    color: $color-white;
    height: 40px;
    padding: 0 10px;
    align-items: center;
    margin-right: -15px;
    font-size: $font-size-s;
    flex-shrink: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    &--linkedin {
      padding-right: 35px;

      &:hover {
        cursor: pointer;
      }
    }

    &--empty {
      background: $color-blue-medium;
    }
  }

  &__chevron {
    font-size: 10px;
    right: 0;
  }
}
</style>
