<template>
  <div class="organizations-modal-edit is-column is-align-items-center">
    <p class="organizations-modal-edit__title font-size-xl mb-4 font-weight-bold">
      Éditer une organisation
    </p>
    <bc-spinner
      v-if="isLoading"
      class="is-justify-content-center"
      size="large">
    </bc-spinner>
    <div class="organizations-modal-edit__body is-full-width">
      <organizations-modal-edit-industries
        :industries="industries"
        :industries-list="industriesList"
        class="is-full-width"
        @update-value="setEditedProfile">
      </organizations-modal-edit-industries>
    </div>
    <hr class="organizations-modal-edit__separator mt-5 mb-5 full-width"/>
    <div class="organizations-modal-edit__wrapper is-full-width is-justify-content-center">
      <bc-button
        class="organizations-modal-edit__button mr-4"
        data-button-cancel
        type="outlined"
        @click="$emit('close-modal')">
        {{ $t('generics.cancel') }}
      </bc-button>
      <bc-button
        class="organizations-modal-edit__button"
        color="success"
        data-button-validate
        @click="editOrganization">
        Éditer l'organisation
      </bc-button>
    </div>
  </div>
</template>

<script>
  import OrganizationsModalEditIndustries
    from '@/components/Organizations/OrganizationsModal/OrganizationsModalEdit/OrganizationsModalEditIndustries';
  import { getIndustries } from '@/api/industries';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import { editOrganization } from '@/api/organizations';
  import { serializeOrganization } from '@/managers/organizations/formatters/organizationsSerializers';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'organizations-modal-edit',
    components: { BcButton, BcSpinner, OrganizationsModalEditIndustries },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        isLoading: true,
        industriesList: [],
        editedProfile: {},
      };
    },
    computed: {
      ...mapGetters([
        'cardActive',
      ]),
      industries() {
        return this.editedProfile.hasOwnProperty('_industries') ? this.editedProfile._industries : this.profile._industries || [];
      },
    },
    methods: {
      ...mapActions([
        'updateCard',
      ]),
      async getIndustries() {
        this.isLoading = true;

        try {
          const { data } = await getIndustries();

          this.industriesList = data;

          this.isLoading = false;
        } catch (error) {
          this.$toast.show({
            message: error?.response?.data?.message || error,
            type: 'error',
          });
        }
      },
      setEditedProfile(profile) {
        this.editedProfile = { ...this.editedProfile, ...profile };
      },
      async editOrganization() {
        try {
          const formattedIndustry = serializeOrganization(this.editedProfile);

          const { data } = await editOrganization({ id: this.profile._id, data: formattedIndustry });

          if (this.cardActive >= 0) {
            this.updateCard({ index: this.cardActive, content: data });
          }

          this.$emit('profile-updated', data);
        } catch (error) {
          this.$toast.show({
            title: 'Erreur lors de l\'édition de l\'organisation',
            type: 'error',
            message: error?.response?.data?.message || error,
          });
        }
      },
    },
    created() {
      this.getIndustries();
    },
  };
</script>

<style lang=scss scoped>
  .organizations-modal-edit {
    padding: 30px;
    width: 100%;
    max-width: 600px;

    @include bp('tablet') {
      min-width: 600px;
    }

    &__title {
      color: $color-secondary;
    }

    &__separator {
      width: 100%;
      border: 0;
      border-top: 1px solid rgba($color-secondary, 0.2);
    }
  }
</style>
