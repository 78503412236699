<template>
  <div :style="tagStyle" class="bc-tag__container">
    <span v-if="prefix" class="bc-tag__prefix">{{ prefix }}</span>
    <p
      :class="{ 'bc-tag--disabled': $attrs.disabled }"
      class="bc-tag"
      ref="button"
      v-bind="$attrs"
      v-on="listeners">
      <i
        :class="[`icon-${bcIcon}`]"
        :style="iconStyle"
        class="bc-tag__icon"
        ref="icon"
        v-if="bcIcon">
      </i>
      <span
        class="bc-tag__text"
        key="text"
        ref="text"
        v-if="$slots.default">
        <slot></slot>
      </span>
      <i
        :style="crossStyle"
        class="bc-tag__cross icon-cross"
        ref="icon"
        v-if="bcCross && !$attrs.disabled">
      </i>
    </p>
  </div>

</template>

<script>
  import colors_helpers from '@/legacy/ui-kit/utils/colors_helpers';

  export default {
    name: 'legacy-bc-tag',
    props: {
      /**
       Define the background color
       */
      bcBackgroundColor: {
        type: String,
        default: 'blue-dodger'
      },
      /**
       Define the opacity of the background color
       */
      bcBackgroundColorOpacity: {
        type: Number,
        default: 1
      },
      /**
       * The icon of the tag
       */
      bcIcon: {
        type: [String, Boolean],
        default: ''
      },
      /**
       * The icon size
       */
      bcIconSize: {
        type: String,
        default: '10px'
      },
      /**
       * The icon size
       */
      bcIconColor: {
        type: String,
        default: 'white'
      },
      /**
       * The icon margin
       */
      bcIconMargin: {
        type: String,
        default: '10px'
      },
      /**
       * The icon size
       */
      bcIconColorOpacity: {
        type: Number,
        default: 1
      },
      /**
       * The color of the text
       */
      bcTextColor: {
        type: String,
        default: 'white'
      },
      /**
       * The opacity of the color of the text
       */
      bcTextColorOpacity: {
        type: Number,
        default: 1
      },
      /**
       * Define if there is a cross next to the text
       */
      bcCross: {
        type: Boolean,
        default: false
      },
      /**
       * Define the size of the cross
       */
      bcCrossSize: {
        type: String,
        default: '10px'
      },
      /**
       * Define the color of the cross
       */
      bcCrossColor: {
        type: String,
        default: 'white'
      },
      /**
       * Define the margin of the cross
       */
      bcCrossMargin: {
        type: String,
        default: '10px'
      },
      bcBorderColor: {
        type: String,
        default: ''
      },
      bcBorderColorOpacity: {
        type: Number,
        default: 1
      },
      prefix: {
        type: String,
        required: false
      }
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners
        };
      },
      tagStyle() {
        return {
          color: colors_helpers.$_checkValidColor(this.bcTextColor, this.bcTextColorOpacity),
          background: colors_helpers.$_checkValidColor(this.bcBackgroundColor, this.bcBackgroundColorOpacity),
          ...this.bcBorderColor && { border: `1px solid ${colors_helpers.$_checkValidColor(this.bcBorderColor, this.bcBorderColorOpacity)}` }
        };
      },
      iconStyle() {
        return {
          'font-size': this.bcIconSize,
          color: colors_helpers.$_checkValidColor(this.bcIconColor, this.bcIconColorOpacity),
          'margin-right': this.bcIconMargin
        };
      },
      crossStyle() {
        return {
          'font-size': this.bcCrossSize,
          color: colors_helpers.$_checkValidColor(this.bcCrossColor),
          'margin-left': this.bcCrossMargin
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .bc-tag {
    position: relative;
    align-items: center;
    flex-shrink: 0;
    transition: all 0.2s;
    display: flex;
    padding: 4px 10px;
    width: 100%;
    overflow: hidden;

    &__container {
      overflow: hidden;
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.7;
    }

    &__prefix {
      background: rgba(83, 95, 221, 0.3);
      border-bottom-left-radius: 30px;
      border-top-left-radius: 30px;
      display: flex;
      align-items: center;
      padding: 4px 10px;
      font-weight: 500;
    }

    &__text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
