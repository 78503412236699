<script lang="ts" setup>
  import { defineEmits, defineProps } from 'vue';

  defineProps<{
    images: string[];
    isReadOnly: boolean;
  }>();

  const emit = defineEmits<{
    (e: 'remove-image', value: number): void;
  }>();

  const handleImageClick = (img: string) => {
    const image = new Image();
    image.src = img;
    const w = window.open('');
    w.document.write(image.outerHTML);
  };
  const handleRemoveImage = (index: number) => {
    emit('remove-image', index);
  };
</script>

<template>
  <div
    v-if="images.length > 0"
    class="flex flex-wrap items-start gap-2">
    <button
      v-for="(image, index) in images"
      :key="index"
      class="relative rounded-md p-1 transition-all duration-200 hover:bg-blue-100"
      @click="handleImageClick(image)">
      <button
        v-if="!isReadOnly"
        class="icon-cross !absolute -right-2 -top-2 !h-[16px] !w-[16px] items-center justify-center rounded-full bg-red-500 !p-0 text-[8px] text-white hover:bg-red-400"
        @click.stop="handleRemoveImage(index)"/>
      <!--      <img-->
      <!--        :src="image"-->
      <!--        alt="image"-->
      <!--        class="size-12 object-cover"/>-->
      <img
        :src="image"
        alt="image"
        class="w-full object-contain"/>
    </button>
  </div>
</template>

<style lang="scss" scoped>

</style>
