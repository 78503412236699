<template>
  <div class="panel-carousel-arrow">
    <div
      :class="{ 'panel-carousel-arrow__icons--reverted': reverted}"
      class="panel-carousel-arrow__icons icon-chevron-left"></div>
  </div>
</template>

<script>
  export default {
    name: 'panel-carousel-arrow',
    props: {
      /**
       * If it's true, the arrow will be reverted
       */
      reverted: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style scoped lang="scss">
  .panel-carousel-arrow {
    align-items: center;
    border-radius: 50%;

    &__icons {
      color: rgba($color-blue-dark-cello, 0.5);
      font-size: 32px;
      @include transition(all 0.2s ease);

      &--reverted {
        @include transform(scaleX(-1));
      }

      &:hover {
        cursor: pointer;
        color: $color-blue;
      }
    }
  }
</style>
