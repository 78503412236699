<template>
  <div
    :style="containerStyle"
    class="bc-input">
    <label
      :style="labelStyle"
      @click="$refs.bcInput.focus()"
      class="bc-input__label"
      v-if="bcLabel">
      {{ bcLabel }}
    </label>
    <div
      :style="wrapperStyle"
      class="bc-input__wrapper">
      <slot></slot>
      <input
        :autocomplete="$attrs.autocomplete ? $attrs.autocomplete : 'off'"
        :placeholder="$attrs.placeholder && bcIsLabelPlaceholder ? null : $attrs.placeholder"
        :style="inputStyle"
        :type="$attrs.type ? $attrs.type : 'text'"
        :value="value"
        class="bc-input__input"
        ref="bcInput"
        v-bind="$attrs"
        v-on="listeners">
      <transition name="placeholder">
        <span
          :style="placeholderStyle"
          @click="$refs.bcInput.focus()"
          class="bc-input__placeholder"
          ref="bcInputPlaceholder"
          v-if="bcIsLabelPlaceholder && $attrs.placeholder && !bcLabel">
          {{ $attrs.placeholder }}
        </span>
      </transition>
    </div>
    <p
      :style="errorStyle"
      class="bc-input__error"
      v-if="bcIsError && bcErrorMessage">
      {{ bcErrorMessage }}
    </p>
  </div>
</template>

<script>
  import colors_helpers from '@/legacy/ui-kit/utils/colors_helpers';

  export default {
    name: 'legacy-bc-input',
    inheritAttrs: false,
    props: {
      value: {
        default: '',
        type: [String, Number]
      },
      bcIsLabelPlaceholder: {
        default: null,
        type: Boolean
      },
      bcLabel: {
        default: null,
        type: String
      },
      bcAutofocus: {
        default: false,
        type: Boolean
      },
      bcLabelColor: {
        default: 'blue-cello',
        type: String
      },
      bcLabelOpacity: {
        default: 0.5,
        type: Number
      },
      bcLabelFontSize: {
        default: '14px',
        type: String
      },
      bcLabelFontSizeFocus: {
        default: '14px',
        type: String
      },
      bcLabelColorFocus: {
        default: 'blue-dodger',
        type: String
      },
      bcLabelOpacityFocus: {
        default: 1,
        type: Number
      },
      bcLabelMargin: {
        default: '0 0 5px 0',
        type: String
      },
      bcLabelMarginFocus: {
        default: '0 0 5px 0',
        type: String
      },
      bcInputFontSize: {
        default: '16px',
        type: String
      },
      bcInputColor: {
        default: 'blue-cello',
        type: String
      },
      bcInputColorFocus: {
        default: 'blue-dodger',
        type: String
      },
      bcInputBorderColor: {
        default: 'blue-cello',
        type: String
      },
      bcInputBorderOpacity: {
        default: 0.5,
        type: Number
      },
      bcInputBorderColorFocus: {
        default: 'blue-dodger',
        type: String
      },
      bcInputBorderOpacityFocus: {
        default: 1,
        type: Number
      },
      bcInputBorderColorValid: {
        default: 'green-jungle',
        type: String
      },
      bcInputOpacity: {
        default: 1,
        type: Number
      },
      bcInputBorderColorError: {
        default: 'red-mandy',
        type: String
      },
      bcInputBorderRadius: {
        type: String,
        default: '4px'
      },
      bcInputPadding: {
        type: String,
        default: '10px 20px'
      },
      bcInputPaddingFocus: {
        type: String
      },
      bcInputWidth: {
        type: String,
        default: '100%'
      },
      bcInputMinHeight: {
        type: String
      },
      bcInputMarginTopLabelPlaceholder: {
        type: String,
        default: '25px'
      },
      bcPlaceholderFontSize: {
        type: String,
        default: '16px'
      },
      bcPlaceholderFontSizeFocus: {
        type: String,
        default: '14px'
      },
      bcPlaceholderTranslateFocus: {
        type: String,
        default: 'translate(-10px, -75%)'
      },
      bcPlaceholderMargin: {
        type: String
      },
      bcPlaceholderColor: {
        type: String,
        default: 'blue-cello'
      },
      bcPlaceholderOpacity: {
        type: Number,
        default: 0.5
      },
      bcPlaceholderColorFocus: {
        type: String,
        default: 'blue-dodger'
      },
      bcPlaceholderOpacityFocus: {
        type: Number,
        default: 1
      },
      bcIsError: {
        type: Boolean,
        default: false
      },
      bcErrorMessage: {
        type: [Boolean, String]
      },
      bcErrorColor: {
        type: String,
        default: 'red-mandy'
      },
      bcErrorFontSize: {
        type: String,
        default: '13px'
      },
      bcErrorMargin: {
        type: String,
        default: '5px 0 0 0'
      }
    },
    data: () => ({
      isFocus: false
    }),
    computed: {
      containerStyle() {
        return {
          ...this.bcIsLabelPlaceholder && { marginTop: this.bcInputMarginTopLabelPlaceholder }
        };
      },
      wrapperStyle() {
        return {
          border: `1px solid ${
            this.isFocus ? colors_helpers.$_checkValidColor(this.bcInputBorderColorFocus, this.bcInputBorderOpacityFocus) :
              this.bcIsError ? colors_helpers.$_checkValidColor(this.bcErrorColor, this.bcInputBorderOpacity) :
                colors_helpers.$_checkValidColor(this.bcInputBorderColor, this.bcInputBorderOpacity)
            }`,
          borderRadius: this.bcInputBorderRadius
        };
      },
      inputStyle() {
        return {
          color: this.isFocus ? colors_helpers.$_checkValidColor(this.bcInputColorFocus, 1) : colors_helpers.$_checkValidColor(this.bcInputColor, 1),
          padding: (this.isFocus || this.value) && this.bcInputPaddingFocus ? this.bcInputPaddingFocus : this.bcInputPadding,
          width: this.bcInputWidth,
          fontSize: this.bcInputFontSize,
          ...this.bcInputMinHeight && { minHeight: this.bcInputMinHeight },
          borderRadius: this.bcInputBorderRadius,
          opacity: this.bcInputOpacity
        };
      },
      placeholderStyle() {
        return {
          color: this.isFocus ? colors_helpers.$_checkValidColor(this.bcPlaceholderColorFocus, this.bcPlaceholderOpacityFocus) : colors_helpers.$_checkValidColor(this.bcPlaceholderColor, this.bcPlaceholderOpacity),
          padding: this.bcInputPadding,
          fontSize: this.isFocus || this.value ? this.bcPlaceholderFontSizeFocus : this.bcPlaceholderFontSize,
          ...this.bcPlaceholderMargin && { margin: this.bcPlaceholderMargin },
          ...((this.isFocus || this.value) && this.bcIsLabelPlaceholder) && { transform: this.bcPlaceholderTranslateFocus }
        };
      },
      labelStyle() {
        return {
          color: this.isFocus ? colors_helpers.$_checkValidColor(this.bcLabelColorFocus, this.bcLabelOpacityFocus) : colors_helpers.$_checkValidColor(this.bcLabelColor, this.bcLabelOpacity),
          fontSize: this.isFocus ? this.bcLabelFontSizeFocus : this.bcLabelFontSize,
          margin: this.isFocus ? this.bcLabelMarginFocus : this.bcLabelMargin
        };
      },
      errorStyle() {
        return {
          fontSize: this.bcErrorFontSize,
          margin: this.bcErrorMargin,
          color: colors_helpers.$_checkValidColor(this.bcErrorColor)
        };
      },
      listeners() {
        return {
          ...this.$listeners,
          input: evt => {
            this.$emit('input', evt.target.value);
          },
          focus: evt => {
            this.$emit('focus', evt);
            this.setFocus(true);
          },
          blur: evt => {
            this.$emit('blur', evt);
            this.setFocus(false);
          }
        };
      }
    },
    methods: {
      setFocus(value) {
        this.isFocus = value;
      }
    },
    mounted() {
      if (this.bcAutofocus) {
        this.$refs.bcInput.focus();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .bc-input {
    flex-direction: column;
    &__wrapper {
      width: 100%;
      position: relative;
    }
    &__label {
      transition: 0.3s;
    }
    &__input {
      transition: 0.3s;
      &:disabled {
        background: rgba($color-blue-dark-cello, 0.05);
      }
    }
    &__placeholder {
      transition: 0.3s;
      position: absolute;
      height: 100%;
      align-items: center;
      user-select: none;
      cursor: text;
    }
  }

</style>
