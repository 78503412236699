<template>
  <views-tabs :tabs="tabs">
    <template #content>
      <slot></slot>
    </template>
  </views-tabs>
</template>

<script>
import ViewsTabs from '@/components/Views/ViewsTabs';

export default {
  name: 'configuration-multicast-tabs',
  components: {
    ViewsTabs,
  },
  computed: {
    tabs() {
      return [
        {
          key: 'career-website',
          label: this.$i18n.t('custom-jobs.career-website'),
          to: '/configuration/career-website',
        },
        {
          key: 'jobboards-xtramile',
          label: 'Xtramile',
          to: '/configuration/xtramile',
        },
        {
          key: 'jobboards-other',
          label: this.$i18n.t('jobboards.other'),
          to: '/configuration/jobboards',
        },
        {
          key: 'multiposting',
          label: this.$i18n.t('generics.statistics'),
          to: '/configuration/multiposting',
        },
      ];
    },
  },
};
</script>
