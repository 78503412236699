<template>
  <div class="panel-process-header bg-neutral-200">
    <div class="panel-process-header__nav">
      <panel-header-nav></panel-header-nav>
      <bc-button
        @click.native="goBack"
        bc-background-color="transparent"
        bc-icon="arrow-left"
        bc-icon-size="16px"
        bc-padding="0"
        bc-text-color="gray-silver-chalice"
        bc-text-color-hover="black"
        class="panel-view-header__button">
      </bc-button>
    </div>
    <spinner v-if="isLoading"></spinner>
    <p
      class="panel-process-header__text"
      v-else-if="configurationFramework.showCompanies">
      <span class="panel-process-header__text--blue">{{ process._coder.firstName }} {{ process._coder.lastName }}</span>
      {{ $t('companies.panel-hunt-process.was-connected-with') }} <span class="panel-process-header__text--blue">{{ process._company.name }}
      </span> {{ $t('companies.panel-hunt-process.for-the-job') }} : <span class="panel-process-header__text--blue">« {{ jobName }} »</span>
    </p>
    <p
      class="panel-process-header__text"
      v-else>
      <span class="panel-process-header__text--blue">{{ process._coder.firstName }} {{ process._coder.lastName }}</span>
      {{ $t('process.in-process-for-opportunity') }} : <span class="panel-process-header__text--blue">« {{ jobName }} »</span>
    </p>
    <div
      class="panel-process-header__wrapper"
      v-if="!isLoading">
      <panel-process-header-buttons
        :process="process"
        @delete-process="deleteProcess"
        @abort-process="abortProcess"
        @unabort-process="unabortProcess"
        @process-updated="updateProcess($event)"
        class="panel-process-header__buttons"
        data-button-dropdown>
      </panel-process-header-buttons>
    </div>
    <modal
      @close-modal="closeModal"
      v-if="displayModal !== ''">
      <modal-process-abort-reason
        v-if="displayModal === 'abortProcessReason'"
        @confirm-action="processActions.abortProcess.action($event)"
        @cancel-action="closeModal"
      ></modal-process-abort-reason>
      <modal-process-action-confirmation
        v-else
        :title="processActions[displayModal].title"
        :text="processActions[displayModal].text"
        :confirmText="processActions[displayModal].confirmText"
        :type="processActions[displayModal].type"
        @confirm-action="processActions[displayModal].action({ confirm: true })"
        @cancel-action="closeModal">
      </modal-process-action-confirmation>
    </modal>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { mapStores } from 'pinia';
  import { useProcessesStore } from '@/store/pinia/processes';

  import PanelProcessHeaderButtons
    from '@/components/Panel/PanelProcess/PanelProcessHeader/PanelProcessHeaderButtons';
  import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav';
  import Spinner from '@/components/UIKit/Spinner/Spinner';
  import Modal from '@/components/Modal/Modal';
  import ModalProcessActionConfirmation from '@/components/Modal/ModalProcess/ModalProcessActionConfirmation';
  import ModalProcessAbortReason from '@/components/Modal/ModalProcess/ModalProcessAbortReason';
  import BcButton from '@/legacy/ui-kit/components/Button/BcButton';
  import steps from '@/mixins/Processes/steps';
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import ProcessMixin from '@/mixins/process';

  export default {
    name: 'panel-process-header',
    components: {
      // BcTag,
      ModalProcessAbortReason,
      ModalProcessActionConfirmation,
      Modal,
      Spinner,
      PanelHeaderNav,
      PanelProcessHeaderButtons,
      BcButton
    },
    props: {
      process: {
        type: Object,
        default: () => ({}),
      },
      isLoading: {
        type: Boolean,
        default: true,
      },
    },
    mixins: [steps, ProcessMixin, PanelNavigation],
    data() {
      return {
        displayModal: '',
        processActions: {
          deleteProcess: {
            key: 'deleteProcess',
            title: this.$t('generics.delete-process-warning'),
            text: this.$t('generics.delete-process-warning-content'),
            action: this.deleteProcess,
            type: 'delete',
          },
          abortProcess: {
            key: 'abortProcess',
            title: this.$t('process.confirm-abort'),
            text: '',
            confirmText: this.$t('process.yes-abort-process'),
            action: this.abortProcess,
            type: 'abort'
          },
          unabortProcess: {
            key: 'unabortProcess',
            title: this.$t('process.confirm-unabort'),
            text: '',
            confirmText: this.$t('process.yes-activate-process'),
            action: this.unabortProcess,
            type: 'abort',
          }
        }
      };
    },
    computed: {
      ...mapGetters('user', ['configurationProcess', 'configurationFramework']),
      ...mapStores(useProcessesStore),
      cardActive() {
        return this.$store.state.card.cards.findIndex(card => card._id === this.process._coder._id);
      },
      jobName() {
        return this.process._customJob?.title || '';
      },
    },
    methods: {
      ...mapActions({
        updateCustomJob: 'customJobs/updateCustomJob',
      }),
      updateProcess({ updatedProcess: process }) {
        this.$emit('process-updated', process);
        this.$emit('update-timeline');
      },
      closeModal() {
        this.displayModal = '';
      },
      async deleteProcess({ confirm } = {}) {
        if (!confirm) {
          this.displayModal = this.processActions.deleteProcess.key
          return
        }
        try {
          const data = await this.processesStore.onDelete({ processId: this.$route.params.id });
          await this.updateCustomJob({ id: data._customJob._id, nbActiveProcesses: data._customJob.nbActiveProcesses });

          this.onActionSuccess({ data });
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      goBack() {
        this.goPrevious();
        this.closeCategory();
      },
      closeCategory() {
        this.$store.dispatch('setActiveCategory', false);
      },
      async abortProcess({ confirm, reason } = {}) {
        if (!confirm) {
          let modal = this.processActions.abortProcess.key;
          if (this.configurationProcess.failureReason) {
            modal += 'Reason';
          }
          this.displayModal = modal;
          return
        }
        try {
          const data = await this.processesStore.onAbort({ processId: this.$route.params.id, reason });
          this.onActionSuccess({ data });
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      async unabortProcess({ confirm } = {}) {
        if (!confirm) {
          this.displayModal = this.processActions.unabortProcess.key
          return
        }
        try {
          const data = await this.processesStore.onUnabort({ processId: this.$route.params.id });
          if (data._customJob._id) {
            await this.updateCustomJob({ id: data._customJob._id, nbActiveProcesses: data._customJob.nbActiveProcesses });
          }
          this.onActionSuccess({ data });
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      onActionSuccess(res) {
        this.$_updateCoder(res.data._coder);
        this.$router.go(-1);
      }
    },
  };
</script>

<style lang=scss scoped>
  .panel-process-header {
    padding: 13px 20px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-blue-light;
    z-index: 2;

    &__nav{
      .panel-header-nav {
      margin-right: 15px;
      }
    }

    &__text {
      font-size: $font-size-m;
      color: $color-blue-dark-cello;
      text-align: center;
      padding: 0 5%;

      &--blue {
        display: initial;
        color: $color-primary;
      }
    }

    &__wrapper {
      align-items: center;
    }

    &__status {
      font-size: $font-size-xs;
      line-height: 14px;
      height: 32px;
      color: $color-white;
      margin: 0 5px;
    }

    &__dropdown {
      & :deep() {
        & > .bc-dropdown__menu {
          border: 1px solid $color-primary;
          width: calc(100vw - 40px);
          margin: auto 20px auto auto;

          @include bp("tablet") {
            width: calc(100vw - 100px);
            margin: auto 50px auto auto;
            max-width: 1070px;
          }
        }
      }
    }
  }
</style>
