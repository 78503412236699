let autosize = require('autosize');
let autoSizeInput = require('autosize-input');

export default {
    install: function(Vue) {
        Vue.directive('autosize', {
            bind: function(el) {
                Vue.nextTick(function() {
                    var tagName = el.tagName;
                    if (tagName == 'TEXTAREA') {
                        autosize(el);
                    } else if (tagName == 'INPUT' && el.type == 'text') {
                        autoSizeInput(el);
                    }
                });
            },

            componentUpdated: function(el) {
                Vue.nextTick(function() {
                    var tagName = el.tagName;
                    if (tagName == 'TEXTAREA') {
                        autosize.update(el);
                    } else if (tagName == 'INPUT' && el.type == 'text') {
                        autoSizeInput(el);
                    }
                });
            },

            unbind: function(el) {
                autosize.destroy(el);
            },
        });
    },
};
