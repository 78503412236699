<template>
  <div
    :class="{
      'process-card-company--pending' : isPending,
      'process-card-company--filtered' : isOld,
      'process-card-company--failed': isFailed,
      'process-card-company--warning': warningOnCard,
      'is-draggable process-card-company--draggable': !isFailed && !isDealt && isAuthorized && !isAborted && isDraggable
    }"
    class="process-card-company">
    <div
      v-if="isPending"
      class="process-card-company__mask">
    </div>
    <p class="process-card-company__title body-m--medium">
      {{ name }} <process-card-source-logo :source="process.source" />
    </p>
    <p class="process-card-company__name">
      {{ processName }}
    </p>
    <process-custom-card-tags :process="process"></process-custom-card-tags>
    <div style="display: block;">
      <slot></slot>
    </div>
    <div class="is-primary font-size-xs is-flex is-align-items-center is-full-width">
      <p class="process-card-company__date">
        {{ date }}
      </p>
      <span class="process-card-company__divider"></span>
    </div>
    <div class="process-card-company__icon icon-dragdrop"></div>
    <bc-avatar
      v-if="process._coach"
      :alt="(process._coach || {}).firstName"
      :src="(process._coach || {}).pictureUrl"
      :placeholder="getCoachInitials()"
      v-tooltip="coachTooltip"
      bc-avatar-radius="100%"
      size="xs"
      class="process-card-company__avatar">
    </bc-avatar>
    <div
      style="position: absolute; bottom: 10px; right: 10px;"
      @mouseenter="$emit('on-options-hover', true)"
      @mouseleave="$emit('on-options-hover', false)"
    >
      <process-card-options
        :is-abort="process.abortedAt !== undefined"
        @on-action="$emit('on-action', $event)"
      ></process-card-options>
    </div>
  </div>
</template>

<script>
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import ProcessCustomCardTags from '@/components/Process/ProcessCard/ProcessCustomCardTags';
  import ProcessCardSourceLogo from '@/components/Processes/ProcessCardSourceLogo';
  import processCard from '@/mixins/Processes/processCard';
  import ProcessCardOptions from '@/components/Process/ProcessCard/ProcessCardOptions';
  import { apiStatus } from '@/macros/processes/process';

  export default {
    name: 'process-card-company',
    components: {
      BcAvatar,
      ProcessCustomCardTags,
      ProcessCardSourceLogo,
      ProcessCardOptions,
    },
    mixins: [processCard],
    props: {
      isDraggable: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      isSuperUser() {
        return this.$store.getters['user/isSuperUser'];
      },
      warningOnCard() {
        return this.isSuperUser && !this.process.formDeal?.sentAt &&
          this.process.status === apiStatus.dealt &&
          !this.process.failedAt;
      },
      processName() {
        return this.process._customJob?.title || '';
      },
      coachTooltip() {
        return {
          content: `${this.process._coach?.firstName} ${this.process._coach?.lastName}`,
          classes: 'tooltip-pre',
        }
      },
    },
    methods: {
      getCoachInitials() {
        return `${(this.process._coach || {}).firstName?.charAt(0)}${(
          this.process._coach || {}
        ).lastName?.charAt(0)}`.trim();
      },
    }
  };
</script>

<style lang="scss" scoped>
  .process-card-company {
    flex-direction: column;
    background: $color-white;
    padding: 15px;
    border-radius: 10px;
    position: relative;
    align-items: flex-start;
    min-width: 190px;

    &--pending {
      border: 1px solid $color-blue-dodger;
    }

    &--filtered {
      border: 1px solid rgba($color-blue-dark-cello, 0.3);
      background: transparent;
    }

    &--warning {
      border: 1px solid $color-warning;
    }

    &--draggable {
      &:hover {
        .process-card-company__icon {
          opacity: 1;
        }
      }
    }

    &__mask {
      animation-name: pending;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }

    &__title {
      color: $color-secondary-light;
      margin-bottom: 2px;
    }

    &__name {
      display: flex;
      font-size: $font-size-s;
      color: $color-primary;
      line-height: 17px;
    }

    &__date {
      flex-shrink: 0;
      line-height: 1.5em;
      color: $color-primary;
      font-size: $font-size-xs;
      margin-top: 8px;
    }

    &__avatar {
      position: absolute;
      right: 10px;
      top: 15px;
      overflow: hidden;
    }

    &__icon {
      transition: 0.3s;
      color: rgba($color-blue-dark-cello, 0.5);
      position: absolute;
      right: 10px;
      bottom: 15px;
      opacity: 0;
    }

    &__divider {
      line-height: 1em;
      position: relative;
      outline: 0;
      border: 0;
      width: 80%;
      align-items: center;
      margin-left: 5px;
      margin-right: 20px;
      margin-top: 8px;

      &:before {
        content: '';
        background: $color-primary;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
      }
    }
  }

  @keyframes pending {
    0% {
      background: rgba($color-blue-dodger, 0.2);
    }
    50% {
      background: rgba($color-blue-dodger, 0.05);

    }
    100% {
      background: rgba($color-blue-dodger, 0.2);
    }
  }
</style>
