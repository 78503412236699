<template>
  <div class="panel-custom-job-posts">
    <div class="panel-custom-job-posts__menu is-full-width">
      <bc-button
        class="panel-custom-job-posts__menu-upload"
        icon-left="upload-cloud"
        @click.native="triggerButton">
        {{ $t('companies.panel-company-contracts.upload') }}
      </bc-button>
      <input
        ref="uploadButton"
        accept=".doc,.docx,application/pdf"
        class="hidden-xs hidden-md hidden-sm"
        type="file"
        @change="uploadPost($event)" />
      <div v-if="posts.length > 0" class="panel-custom-job-posts__menu-list">
        <document-item
          v-for="post in posts"
          :key="post._id"
          :is-selected="selectedPost._id === post._id"
          :document="post"
          @click.native="selectItem(post)"
          @delete-document="setIsModalOpen(true)">
        </document-item>
      </div>
    </div>
    <div class="panel-custom-job-posts__post hidden-xs">
      <iframe
        class="is-full-width"
        v-if="selectedDocumentType.includes('doc')"
        :src="`https://docs.google.com/gview?url=${selectedDocumentUrl}&embedded=true`"
        frameborder="0">
      </iframe>
      <panel-view-pdf v-else :empty-message="emptyMessage" :pdf-url="selectedDocumentUrl"> </panel-view-pdf>
    </div>
    <bc-modal :active.sync="isModalOpen">
      <panel-custom-jobs-posts-modal-delete
        @cancel-delete="setIsModalOpen(false)"
        @confirm-delete="removePost">
      </panel-custom-jobs-posts-modal-delete>
    </bc-modal>
  </div>
</template>

<script>
import PanelViewPdf from '@/components/Panel/PanelView/PanelViewPDF';
import BcButton from '@/ui-kit/components/BcButton';
import postsController from '@/managers/posts/controller';
import BcModal from '@/ui-kit/components/BcModal/BcModal';
import PanelCustomJobsPostsModalDelete from '@/components/Panel/PanelCustomJobs/PanelCustomJobsPosts/PanelCustomJobsPostsModalDelete';
import DocumentItem from '@/components/Document/DocumentItem';

export default {
  name: 'panel-custom-jobs-posts',
  components: {
    PanelCustomJobsPostsModalDelete,
    BcModal,
    BcButton,
    PanelViewPdf,
    DocumentItem,
  },
  props: {
    profile: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      posts: [],
      emptyMessage:
        this.posts?.length > 0
          ? this.$i18n.t('companies.panel-company-contracts.select-a-job-description')
          : this.$i18n.t('companies.panel-company-contracts.no-job-description-at-the-moment'),
      selectedPost: {},
      isModalOpen: false,
    };
  },
  computed: {
    selectedDocumentUrl() {
      return this.posts.length > 0 ? this.selectedPost.url : '';
    },
    selectedDocumentType() {
      return this.selectedDocumentUrl?.split('.')?.pop() || '';
    },
  },
  methods: {
    selectItem(post) {
      this.selectedPost = post;
    },
    triggerButton() {
      this.$refs.uploadButton.click();
    },
    setIsModalOpen(value) {
      this.isModalOpen = value;
    },
    async getCustomJobPosts() {
      try {
        const { data } = await postsController.getCustomJobPosts(this.$route.params.id);

        this.posts = data;

        this.selectedPost = this.posts[0] || {};
      } catch (error) {
        this.$toast.show({
          message: this.$t('toast.error-occured'),
          type: 'error',
          icon: 'cross',
        });
      }
    },
    async uploadPost(e) {
      if (e.target.files[0]) {
        try {
          const { data } = await postsController.createCustomJobPost({
            id: this.$route.params.id,
            file: e.target.files[0],
          });

          this.posts.unshift(data);
          this.selectedPost = data;
        } catch (error) {
          this.$toast.show({
            message: this.$t('toast.error-occured'),
            type: 'error',
            icon: 'cross',
          });
        }
      }
    },
    async removePost() {
      try {
        await postsController.removeCustomJobPost(this.selectedPost._id);

        const index = this.posts.findIndex(post => this.selectedPost._id === post._id);

        this.posts.splice(index, 1);

        this.setIsModalOpen(false);
      } catch (error) {
        this.$toast.show({
          message: this.$t('toast.error-occured'),
          type: 'error',
          icon: 'cross',
        });
      }
    },
  },
  watch: {
    '$route.params.id': {
      handler() {
        this.getCustomJobPosts();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-custom-job-posts {
  width: 100%;
  flex-shrink: 0;

  &__menu {
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
    min-width: 200px;
    overflow-y: scroll;
    background-color: $color-neutre-3;

    @include bp('tablet') {
      max-width: 200px;
    }

    &-upload {
      width: 160px;
      height: 40px;
    }

    &-list {
      flex-direction: column;
      align-items: center;

      &-text {
        margin-top: 20px;
        color: $color-white;
        font-size: $font-size-m;
        align-items: center;

        &-carret {
          border-top: 1px solid $color-white;
          height: 0;
          width: 6px;

          &:first-of-type {
            margin-right: 10px;
          }

          &:last-of-type {
            margin-left: 10px;
          }
        }
      }
    }
  }

  &__post {
    width: 100%;

    &-upload {
      position: absolute;
      bottom: 10px;
      left: 50%;
    }
  }
}
</style>
