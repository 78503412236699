<template>
  <div
    class="panel-pokes-status-source"
    v-if="cardContent._coach">
    <i class="panel-pokes-status-source__icon icon-target"></i>
    <p class="panel-pokes-status-source__text">
      Chassé
    </p>
    <div
      class="panel-pokes-status-source__wrapper"
      v-tooltip="`Chassé par ${ cardContent._coach.firstName } ${ cardContent._coach.lastName }`">
      <img
        :src="cardContent._coach.pictureUrl"
        class="panel-pokes-status-source__img">
    </div>
  </div>
</template>

<script>
  // TODO move or refacto to ui-kit
  export default {
    name: 'panel-pokes-status-source',
    props: {
      /**
       * The Object that contains the content of the response that will be displayed by the card
       */
      cardContent: Object
    },
    computed: {
      bubbleContent() {
        return `Chassé par ${this.cardContent._coach.firstName}`;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .panel-pokes-status-source {
    border: 1px solid $color-yellow-sunglow;
    padding: 6.5px 10px;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    &__icon {
      color: $color-yellow-sunglow;
      font-size: $font-size-s;
      margin-right: 5px;
    }
    &__text {
      font-size: $font-size-xs;
      color: $color-yellow-sunglow;
      margin-right: 5px;
      text-transform: uppercase;
    }
    &__wrapper {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      overflow: hidden;
    }
    &__img {
      width: 100%;
      height: auto;
    }
  }
</style>
