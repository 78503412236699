<script lang="ts" setup>
import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
import { usePanelNavigation } from '@/composables/usePanelNavigation';
import { useRoute } from 'vue-router/composables';
import type { Component } from 'vue';
import { computed, defineEmits, defineProps, ref, withDefaults } from 'vue';
import ButtonShareJob from '@/components/Button/ButtonShareJob.vue';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
import { setAlternativeImg } from '@/mixins/imgFallback';
import { useToast } from '@/ui-kit/components/BcToast';
import { deleteCustomJob, updateCustomJob } from '@/api/custom-jobs';
import CustomJobsCloseWarningModal from '@/components/CustomJobs/CustomJobsCloseWarningModal.vue';
import CustomJobsDeleteWarningModal from '@/components/CustomJobs/CustomJobsDeleteWarningModal.vue';
import { useI18n } from '@/i18n/i18n';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger.vue';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
import { useStore } from '@/store';
import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav.vue';

const store = useStore();
const route = useRoute();
const { closePanel } = usePanelNavigation();
const toast = useToast();
const i18n = useI18n();

const props = withDefaults(
  defineProps<{
    profile?: any;
    isCreating?: boolean;
  }>(),
  {
    isCreating: false,
    profile: () => ({}),
  }
);

const emits = defineEmits<{
  (e: 'update-opportunity', value: any): void;
}>();

// modal
const modalsMap: {
  component: Component;
  key: string;
}[] = [
  {
    component: CustomJobsDeleteWarningModal,
    key: 'delete',
  },
  {
    component: CustomJobsCloseWarningModal,
    key: 'close',
  },
];
const modalType = ref('');
const setModalType = (type: string) => {
  modalType.value = type;
};
const closeModal = () => {
  modalType.value = '';
};
const handleOnConfirmModal = () => {
  if (modalType.value === 'delete') {
    confirmDeleteOpportunity();
  } else {
    confirmUpdateHunt({ item: { apiStatus: 'close' } });
  }
};
const modal = computed(() => {
  return modalsMap.find(modal => modal.key === modalType.value)?.component || null;
});
const isModalOpen = computed(() => !!modal.value);

const confirmDeleteOpportunity = async () => {
  try {
    await deleteCustomJob(route.params.id);

    toast.show({
      type: 'success',
      message: i18n.t('toast.job-deletion-has-been-carried-out'),
    });

    closePanel();
  } catch (e) {
    toast.show({
      type: 'error',
      message: e.message,
    });
  }
};
const updateOwners = async owners => {
  try {
    const { data } = await updateCustomJob({
      id: route.params.id,
      owners: owners.map(coach => coach._id),
    });

    emits('update-opportunity', { _owners: data._owners });
  } catch (e) {
    toast.show({
      type: 'error',
      message: e.message,
    });
  }
};

const openDropdownItems = [
  {
    activeBackgroundColor: '#3DCF8E1A',
    apiStatus: 'open',
    backgroundColor: '#3DCF8E1A',
    name: i18n.t('hunt.opened-job'),
    textColor: '#3DCF8E',
    action: () => {},
  },
  {
    activeBackgroundColor: '#E6525A1A',
    apiStatus: 'close',
    backgroundColor: '#E6525A1A',
    name: i18n.t('hunt.closed-job'),
    textColor: '#E6525A',
    action: () => {},
  },
];
const closeDropdownItems = [
  {
    activeBackgroundColor: '#E6525A1A',
    apiStatus: 'close',
    backgroundColor: '#E6525A1A',
    name: i18n.t('hunt.closed-job'),
    textColor: '#E6525A',
    action: () => {},
  },
  {
    activeBackgroundColor: '#3DCF8E1A',
    apiStatus: 'open',
    backgroundColor: '#3DCF8E1A',
    name: i18n.t('hunt.opened-job'),
    textColor: '#3DCF8E',
    action: () => {},
  },
];
const statusDropdownItems = computed(() => {
  return props.profile.active ? openDropdownItems : closeDropdownItems;
});
const statusDropdownContent = computed(() => {
  return props.profile.active ? i18n.t('hunt.opened-job') : i18n.t('hunt.closed-job');
});

const closeOptions = computed(() => {
  return store.getters['customJobs/closeOptions'];
});

const updateHunt = status => {
  if (status.apiStatus === 'close' && props.profile.active) {
    setModalType('close');
    return;
  }
  confirmUpdateHunt(status);
};
const confirmUpdateHunt = async status => {
  const { data } = await updateCustomJob({
    id: route.params.id,
    active: status.apiStatus === 'open',
    closeOptions: closeOptions.value,
  });

  emits('update-opportunity', { active: data.active });
  closeModal();
};
</script>

<template>
  <div
    class="panel-opportunity-header flex min-h-[50px] w-full items-center justify-between border-b border-b-[#EDF0FF] bg-neutral-200 px-[18px] py-2">
    <div class="flex items-center gap-5">
      <PanelHeaderNav />
      <div v-if="!isCreating" class="flex items-center gap-2.5">
        <div class="ml-1 flex">
          <BcAvatar
            v-for="owner in profile?._owners"
            :key="owner._id"
            v-tooltip.bottom="{ content: `${owner.firstName} ${owner.lastName}` }"
            :alt="owner.firstName"
            :src="owner.pictureUrl"
            class="-ml-1 size-[30px] shrink-0"
            @error="setAlternativeImg({ event: $event, type: 'coders' })" />
        </div>
        <ButtonShareJob
          :company="profile?._company"
          :job="profile"
          class="h-[30px]"
          @update-owners="updateOwners" />
      </div>
    </div>
    <div v-if="!isCreating" class="flex gap-2.5">
      <BcDropdown
        v-if="profile._id"
        :options="statusDropdownItems"
        class="panel-company-edit-infos__input panel-company-edit-infos__input--half"
        data-size
        is-expanded
        @change="updateHunt">
        <template #label="{ isDropdownOpen }">
          <BcDropdownTrigger
            :class="{
              '!bg-[#3DCF8E1A] !text-[#3DCF8E]': profile.active,
              '!bg-[#E6525A1A] !text-[#E6525A]': !profile.active,
            }"
            class="!h-[30px] rounded-3xl !border !border-[#D9DFFD] !px-5 text-sm"
            :is-arrow="true"
            :is-dropdown-open="isDropdownOpen"
            :text="statusDropdownContent">
          </BcDropdownTrigger>
        </template>
        <template #option="{ option, index, activeIndex }">
          <BcDropdownItem
            :is-focus="activeIndex === index"
            :text="$t(option.name)"
            class="is-expanded !py-[10px]"
            @click.native="updateHunt(option)">
          </BcDropdownItem>
        </template>
      </BcDropdown>
      <bc-button
        color="error"
        icon-left="trash"
        size="extra-small"
        @click.native="setModalType('delete')">
        {{ $t('generics.delete') }}
      </bc-button>
    </div>
    <component
      :is="modal"
      :is-open="isModalOpen"
      :profile="profile"
      @close="closeModal"
      @close-modal="closeModal"
      @on-confirm="handleOnConfirmModal">
    </component>
  </div>
</template>

<style lang="scss" scoped>
.panel-opportunity-header {
  &:deep() {
    .mv-button-rounded__button {
      padding: 0 20px;
      height: 100%;
    }

    .bc-dropdown-trigger__text {
      display: flex;
      flex-shrink: 0;
      max-width: 100%;
    }
  }
}
</style>
