<template>
  <div class="panel-job-edit-description col-xs-12">
    <title-section
      class="panel-job-edit-description__title"
      position="left"
      :title="$t('generics.add')">
    </title-section>
    <bc-rich-text-editor
      :class="{'panel-job-edit-description__input--focus': isFocus}"
      @click.native="focus"
      class="panel-job-edit-description__input"
      :placeholder="$t('companies.account-management.write-add-description')"
      v-click-outside="blur"
      v-model="description">
    </bc-rich-text-editor>
  </div>
</template>

<script>
  import TitleSection from '@/components/Title/TitleSection';
  import BcRichTextEditor from '@/legacy/ui-kit/components/RichTextEditor/BcRichTextEditor';

  export default {
    name: 'panel-job-edit-description',
    components: {
      BcRichTextEditor,
      TitleSection,
    },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
      editedProfile: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      isFocus: false,
    }),
    computed: {
      description: {
        get() {
          return this.editedProfile.description !== undefined && this.editedProfile.description !== this.profile.description ? this.editedProfile.description : this.profile.description;
        },
        set(value) {
          this.$emit('update-value', {
            description: value,
          });
        },
      },
    },
    methods: {
      focus() {
        this.isFocus = true;
      },
      blur() {
        this.isFocus = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-job-edit-description {
    @include bp('tablet') {
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
    }

    &__title {
      width: 100%;
      margin-bottom: $panel-title-margin-bottom;
    }

    &__input {
      padding: 0 20px;
      border-radius: 5px;
      color: $color-blue;
      border: solid rgba($color-blue-dark-cello, 0.5) 1px;
      min-height: 50px;
      width: 100%;
      font-size: $font-size-xs;
      overflow-wrap: break-word;
      resize: none;

      & :deep() {
        .bc-rich-text-editor__menu {
          position: sticky;
          top: -40px;
          background: $color-white;
          z-index: 1;
        }
      }

      &--focus {
        border: solid $color-blue-dodger 1px;
      }

      &::placeholder {
        color: $color-gray-regent;
      }
    }
  }
</style>
