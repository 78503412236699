<script lang="ts" setup>
  import { defineEmits, defineProps } from 'vue';
  import { Process } from '@/domains/models/Process';
  import PanelProcessesItem from '@/components/Panel/PanelProcessesItem.vue';
  import InfiniteLoadingViewport from '@/components/InfiniteLoading/InfiniteLoadingViewport.vue';
  import { useI18n } from '@/i18n/i18n';

  interface Props {
    processes: Process[];
    identifier: number;
  }

defineProps<Props>();
  const emit = defineEmits<{
    'update-process': (value: Process) => void,
    'remove-process': [{ value: string }]
  }>();

  const { t } = useI18n();

  const updateProcess = ({ index, process }) => {
    // processes.value[index] = { ...processes.value[index], ...process };
    emit('update-process', { index, process });
  };

  const removeProcessFromProcesses: (id: string) => void = (id: string) => {
    // processes.value = processes.value.filter(process => process._id !== id);
    emit('remove-process', { id });
  };


function loadMoreProcesses($state) {
  console.log('loadMoreProcesses', $state);
  emit('load-more-processes', $state);
};
</script>

<template>
  <div class="flex w-full flex-col gap-2.5 text-sm h-min-4">
    <PanelProcessesItem
      v-for="(process, index) in processes"
      :key="process._id"
      :process="process"
      parent="company"
      @remove-process="removeProcessFromProcesses"
      @update-process="updateProcess({ index, process: $event })"
    />
    <InfiniteLoadingViewport :identifier="identifier" @infinite="loadMoreProcesses">
      <p class="flex w-full grow items-center rounded-md bg-neutral-200 px-5 py-4 text-blue-800">
        {{ t('processes.nothing') }}
      </p>
    </InfiniteLoadingViewport>
  </div>
</template>
