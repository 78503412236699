<template>
  <div
    class="panel-process-profile col-xs-12"
    v-click-outside="manageClosePanel">
    <panel-process-header
      :is-loading="isLoading"
      :process="process"
      @process-updated="updateProcess"
      @update-timeline="updateTimeline"
      class="panel-process-profile__header">
    </panel-process-header>
    <div class="panel-process-profile__body">
      <panel-process-timeline
        :action-identifier="identifier"
        :process="process"
        :current-formatted-process="currentFormattedInitialProcess"
        class="panel-process-profile__wrapper">
      </panel-process-timeline>
      <panel-process-details
        :is-loading="isLoading"
        :process="process"
        @process-updated="updateProcess"
        @update-timeline="updateTimeline"
        class="panel-process-profile__wrapper">
      </panel-process-details>
    </div>
  </div>
</template>

<script>
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation.js';
  //
  import PanelProcessHeader from '@/components/Panel/PanelProcess/PanelProcessHeader';
  import PanelProcessTimeline from '@/components/Panel/PanelProcess/PanelProcessTimeline';
  import PanelProcessDetails from '@/components/Panel/PanelProcess/PanelProcessDetails';
  // import { getProcessProfile } from '@/api/processes';
  import { getProcessesCustomById } from '@/api/processes-custom';

  export default {
    name: 'panel-process-profile',
    components: {
      PanelProcessDetails,
      PanelProcessTimeline,
      PanelProcessHeader,
    },
    mixins: [PanelNavigation],
    data: () => ({
      process: {},
      currentFormattedInitialProcess: {},
      isLoading: true,
      identifier: +new Date(),
    }),
    methods: {
      updateProcess(process) {
        this.process = process;
      },
      updateCard(process) {
        const cardIndex = this.$store.state.card.cards.findIndex(card => card._id === process._coder._id);

        if (cardIndex >= 0) {
          this.$store.dispatch('updateCard', {
            index: cardIndex,
            content: {
              coachProcessState: process._coder.coachProcessState,
              processState: process._coder.processState,
            },
          });
        }
      },
      async getProcessProfile() {
        this.isLoading = true;

        try {
          const response = await getProcessesCustomById(this.$route.params.id);

          this.process = {
            ...this.process,
            ...response.data,
          };

          this.currentFormattedInitialProcess = {
            id: response.data._id,
            coderLabel: `${response.data._coder.firstName} ${response.data._coder.lastName}`,
            companyLabel: `${response.data._company.name}`,
            jobLabel: response.data?._customJob?.title,
            initial: true,
          };

          this.currentFormattedInitialProcess = {
            id: response.data._id,
            coderLabel: `${response.data._coder.firstName} ${response.data._coder.lastName}`,
            companyLabel: `${response.data._company.name}`,
            jobLabel: response.data?._customJob?.title,
            initial: true,
          };

          this.updateCard(this.process);

          this.isLoading = false;
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: error.response?.data?.message,
          });

          this.isLoading = false;
        }
      },
      updateTimeline() {
        this.identifier = +new Date();
      },
      manageClosePanel(event = {}) {
        if ((event.target || {}).className === 'icon-close' || (event.target || {}).className === 'overlay') {
          this.closePanel();
        }
      },
    },
    watch: {
      '$route.params.id': {
        handler() {
          this.getProcessProfile();
        },
        immediate: true,
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-process-profile {
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    &__body {
      flex-grow: 1;
      height: calc(100% - 60px);
    }

    &__wrapper {
      width: 50%;
      height: 100%;
      flex-shrink: 0;
    }
  }
</style>
