<template>
  <mv-filter
    :label="$tc('generics.skill', 2)"
    icon="tool"
    :count="selected.length"
  >
    <filter-content
      class="filter-skills__content"
      :label="$tc('generics.skill', 2)"
      icon="tool"
    >
      <skills-autocomplete
        :selected="selected"
        :show-label="false"
        :filter-options="filterOptions"
        :selected-filter="selectedFilter"
        @select-skills="handleSelect"
        @select-option="onSelectOption"
      ></skills-autocomplete>
    </filter-content>
  </mv-filter>
</template>

<script>
import MvFilter from '@/ui-kit/components/MvFilter/MvFilter';
import FilterContent from '@/components/Filter/FilterContent';
import SkillsAutocomplete from '@/components/Professions/SkillsAutocomplete';

export default {
  name: 'filter-skills',
  components: {
    MvFilter,
    FilterContent,
    SkillsAutocomplete,
  },
  data() {
    return {
      filterOptions: [
        { key: 'and', name: this.$t('generics.and').toUpperCase() },
        { key: 'or', name: this.$t('generics.or').toUpperCase() },
      ],
      selectedFilter: {},
    }
  },
  mounted() {
    let selected = this.filterOptions[0];
    if (this.$route.query.skillsOperator) {
      selected = this.filterOptions.find(option => option.key === this.$route.query.skillsOperator);
    }
    this.selectedFilter = selected;
  },
  computed: {
    skills() {
      return this.$route.query.technos;
    },
    skillsNames() {
      return this.$route.query.technosNames;
    },
    selected() {
      if (!this.skills) {
        return [];
      }
      const skills = this.skills.split(',');
      const skillsNames = this.skillsNames.split(',');
      return skills.map((id, index) => ({
        _id: id,
        name: skillsNames[index],
      }))
    },
    selectedOperator() {
      return this.selectedFilter?.key || this.filterOptions[0]?.key;
    },
  },
  methods: {
    handleSelect(skills) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          technos: skills.length ? skills.map(p => p._id).join(',') : undefined,
          technosNames: skills.length ? skills.map(p => p.name).join(',') : undefined,
          ...this.selectedOperator && { skillsOperator: this.selectedOperator },
        },
      });
    },
    onSelectOption(filter = {}) {
      this.selectedFilter = filter;
      if (this.$route.query.technos) {
        this.$router.push({
          query: {
            ...this.$route.query,
            skillsOperator: filter.key,
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.filter-skills {
  &__content {
    min-width: 574px;;

    &:deep() {
      .bc-input {
        margin-top: 0;
      }
    }
  }
}
</style>
