import coders from '@/api/coders';
import { validateName } from '@/managers/coders/validators/validateName';
import { serializeCoder } from '@/managers/coders/formatters/codersSerializers';

export const createCoderProfile = data => {
  const validate = coder => {
    try {
      const mapValidator = {
        firstName: validateName,
        lastName: validateName,
      };

      const keysToValidate = Object.keys(coder);

      keysToValidate.forEach(key => mapValidator[key]);
    } catch (error) {
      throw error;
    }
  };
  const execute = (data) => {
    try {
      return coders.createCoder(data);
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    validate(data);

    const formattedCoder = serializeCoder(data);

    return execute(formattedCoder);
  } catch (error) {
    throw error;
  }
};

export default {
  createCoderProfile,
};
