<template>
  <modal-delete-item
    :is-open="isOpen"
    :title="$t('custom-jobs.delete-warning-title')"
    @on-confirm="$emit('on-confirm', $event)"
    @close="$emit('close', $event)"
  >
    <template #content>
      <div class="custom-jobs-delete-warning-modal__content">
        <p>{{ $t('custom-jobs.delete-warning-content') }}</p>
        <ul class="custom-jobs-delete-warning-modal__content-list">
          <li>→ {{ $t('custom-jobs.delete-warning-process') }}</li>
          <li>→ {{ $t('custom-jobs.delete-warning-career') }}</li>
        </ul>
      </div>
    </template>
  </modal-delete-item>
</template>

<script>
import ModalDeleteItem from '@/components/Modal/ModalDeleteItem';

export default {
  name: 'custom-jobs-delete-warning-modal',
  components: {
    ModalDeleteItem,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-jobs-delete-warning-modal {
  &__content {
    flex-direction: column;

    &-list {
      list-style-type: none;
      text-align: left;
      color: $color-blue-heavy-dark;
      font-size: $font-size-m;

      & > li {
        margin-top: 20px;
      }
    }
  }
}
</style>
