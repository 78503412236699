export const getIdsFromSkillsArray = skills => skills.map(skill => skill._id);

export const serializeOrganization = organization => {
  const serializeMap = {
    _industries: getIdsFromSkillsArray,
  };

  const formattedOrganization = { ...organization };

  Object.keys(serializeMap).forEach(key => {
    if (formattedOrganization[key]) {
      formattedOrganization[key] = serializeMap[key](formattedOrganization[key]);
    }
  });

  return formattedOrganization;
};
