<template>
  <div
    :class="{ 'bc-checkbox--disabled': $attrs.disabled }"
    class="bc-checkbox">
    <input
      :checked="value || $attrs.checked"
      :value="value"
      ref="checkbox"
      type="checkbox"
      v-bind="$attrs"
      v-on="listeners">
    <span
      :class="{ 'bc-checkbox__square--active': value }"
      :style="checkBoxStyle"
      @click="toggleValue"
      @touch="toggleValue"
      class="bc-checkbox__square">
      <span
        :class="{ 'bc-checkbox__cross--active': value }"
        :style="crossStyle"
        class="bc-checkbox__cross">
      </span>
      <i
        :style="iconStyle"
        class="bc-checkbox__icon icon-check">
      </i>
    </span>
    <label
      :class="{ 'bc-checkbox__text--active': value }"
      :style="labelStyle"
      @click="toggleValue"
      class="bc-checkbox__text"
      v-if="label">
      {{ label }}
    </label>
  </div>
</template>

<script>
  import colors_helpers from '@/legacy/ui-kit/utils/colors_helpers';

  export default {
    name: 'legacy-bc-checkbox',
    inheritAttrs: false,
    props: {
      /**
       * The label if there is
       */
      label: {
        type: String,
        default: ''
      },
      /**
       * The value of the checkbox
       */
      value: {
        type: Boolean,
        default: false
      },
      bcCheckboxBorderColorOpacity: {
        type: Number,
        default: 1
      },
      /**
       * The color of the border
       */
      bcCheckboxBorderColor: {
        type: String,
        default: 'blue-cadet'
      },
      /**
       * The color of the check
       */
      bcCheckboxCheckColor: {
        type: String,
        default: 'blue-dodger'
      },
      /**
       * The radius of the border
       */
      bcCheckboxBorderRadius: {
        type: String,
        default: '4px'
      },
      /**
       * The background of the checkbox
       */
      bcCheckboxBackground: {
        type: String,
        default: 'white'
      },
      bcCheckboxSize: {
        type: String,
        default: '20px'
      },
      /**
       * The size of the icon inside the checkbox
       */
      bcCheckboxCheckSize: {
        type: String,
        default: '10px'
      },
      bcCheckboxCrossBackground: {
        type: String,
        default: 'blue-dodger'
      },
      /**
       * The color of the label
       */
      bcLabelColor: {
        type: String,
        default: 'blue-dodger'
      },
      /**
       * The font-size of the label
       */
      bcLabelFontSize: {
        type: String,
        default: '16px'
      },
      /**
       * The padding of the label
       */
      bcLabelPadding: {
        type: String,
        default: ''
      }
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          change: event => {
            this.toggleValue(event);
          }
        };
      },
      checkBoxStyle() {
        return {
          borderColor: this.value ? colors_helpers.$_checkValidColor(this.bcCheckboxCrossBackground) : colors_helpers.$_checkValidColor(this.bcCheckboxBorderColor, this.bcCheckboxBorderColorOpacity),
          'border-radius': this.bcCheckboxBorderRadius,
          background: colors_helpers.$_checkValidColor(this.bcCheckboxBackground),
          width: this.bcCheckboxSize,
          height: this.bcCheckboxSize
        };
      },
      crossStyle() {
        return {
          background: colors_helpers.$_checkValidColor(this.bcCheckboxCrossBackground)
        };
      },
      iconStyle() {
        return {
          'font-size': this.bcCheckboxCheckSize,
          color: colors_helpers.$_checkValidColor(this.bcCheckboxCheckColor)
        };
      },
      labelStyle() {
        return {
          color: colors_helpers.$_checkValidColor(this.bcLabelColor),
          'font-size': this.bcLabelFontSize,
          padding: this.bcLabelPadding
        };
      }
    },
    methods: {
      toggleValue(event) {
        if (!this.$attrs.disabled) {
          this.$emit('input', !this.value);
          this.$emit('change', event);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .bc-checkbox {
    align-items: center;

    &--disabled {
      opacity: 0.5;
    }

    &__square {
      display: flex;
      transition: all .2s ease;
      cursor: pointer;
      position: relative;
      border-radius: 2px;
      align-items: center;
      justify-content: center;
      transform: rotate(-90deg);
      overflow: hidden;
      box-sizing: border-box;
      border: 1px solid;
    }

    &__cross {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      transform: translate(100%);
      transform-origin: right;
      transition: all .20s ease;
      z-index: 1;
    }

    &__text {
      padding-left: 10px;
      transition: 0.2s ease;
      cursor: pointer;
    }

    &__icon {
      backface-visibility: visible;
      transition: all .2s ease-out;
      z-index: 2;
      opacity: 0;
      transform: translate(30px);
      transform-origin: center;
    }

    input[type="checkbox"] {
      display: none;
      opacity: 0;
      z-index: 200;
      cursor: pointer;
      background: rgb(85, 215, 117);

      &:active:checked {
        + .bc-checkbox__cross {
          transform: translate(3px) !important;
        }

        + .bc-checkbox__icon {
          transform: translate(6px) !important;
        }
      }

      &:checked {
        + .bc-checkbox__square {
          transform: rotate(0deg) !important;

          .bc-checkbox__cross {
            transform: translate(0);
          }

          .bc-checkbox__icon {
            opacity: 1;
            transform: translate(0) !important;
            color: rgb(255, 255, 255) !important;
          }
        }
      }
    }
  }
</style>
