<template>
  <div
    class="flex w-full flex-col"
    :class="getTabClass()"
  >
    <div
      :class="[
        'flex w-full flex-row',
        { 'pointer-events-none cursor-default select-none opacity-60': disabled }, // Condition
      ]">
      <a
        role="tab"
        :class="[
          'after:my-auto after:ml-2.5 after:flex after:h-px after:w-full after:border-0 after:bg-[#D9DFFD]',
          'relative flex w-full cursor-pointer select-none items-center no-underline', // Alignments
          'rounded-t-md font-bold transition transition-shadow duration-200 ease-in-out', // Padding and transition
          'text-md text-blue-500', // Borders and colors
          'focus:text-blue-500 focus:outline-none focus:outline-offset-0', // Focus
        ]"
        @click="onTabClick"
        @keydown="onTabKeydown"
        :tabindex="disabled ? null : '0'"
        :aria-expanded="isTabActive()"
        :id="ariaId + '_header'"
        :aria-controls="ariaId + '_content'"
      >
        <span
          :class="[
            'mr-2 inline-block h-3 stroke-current text-[10px]',
            isTabActive() ? getHeaderCollapseIcon() : getHeaderExpandIcon()]">
        </span>
        <span
          :class="[
            `flex shrink-0 items-center leading-none`,
          ]"
          class="p-accordion-header-text"
          v-if="header"
        >{{ header }}</span>
        <slot name="header"></slot>
      </a>
    </div>
    <transition name="p-toggleable-content">
      <div
        :class="[
          'rounded-b-lg rounded-t-none bg-white',
        ]"
        class="p-toggleable-content"
        v-show="isTabActive()"
        role="region"
        :id="ariaId + '_content'"
        :aria-labelledby="ariaId + '_header'"
      >
        <div class="p-accordion-content w-full">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
let lastId = 0;

const UniqueComponentId = (prefix = 'pv_id_') => {
  lastId++;
  return `${prefix}${lastId}`;
}

const domIndex = (element) => {
  let children = element.parentNode.childNodes;
  let num = 0;
  for (var i = 0; i < children.length; i++) {
    if (children[i] === element) return num;
    if (children[i].nodeType === 1) num++;
  }
  return -1;
}


export default {
  props: {
    header: null,
    disabled: Boolean
  },
  data() {
    return {
      index: null
    }
  },
  created() {
    this.$parent.$children.forEach((child, i) => {
      if (child === this) this.index = i
    })
  },
  methods: {
    onTabClick(event) {
      if (!this.disabled) {
        this.$parent.onToggle(event, this, domIndex(this.$el), this.isTabActive());
      }
    },
    onTabKeydown(event) {
      if (event.which === 13) {
        this.onTabClick(event);
      }
    },
    isTabActive() {
      let activeArray = this.$parent.d_activeIndex;
      return this.$parent.multiple ? activeArray && activeArray.includes(this.index) : this.index === activeArray;
    },
    getTabClass() {
      return ['p-accordion-tab', { 'p-accordion-tab-active': this.isTabActive() }];
    },
    getTabHeaderClass() {
      return ['p-accordion-header', { 'p-highlight': this.isTabActive(), 'p-disabled': this.disabled }]
    },
    getHeaderCollapseIcon() {
      return ['icon-chevron-down', this.$parent.collapseIcon];
    },
    getHeaderExpandIcon() {
      return ['icon-chevron-right', this.$parent.expandIcon];
    },
  },
  computed: {
    ariaId() {
      return UniqueComponentId();
    }
  }
}
</script>
