import {
  getCustomJobs,
  createCustomJob,
  updateCustomJob,
  deleteCustomJob,
} from '@/api/custom-jobs';

const defaultCloseOptions = {
  closeProcesses: false,
  removeFromCareer: true,
}

export default {
  namespaced: true,
  state: () => ({
    list: [],
    selected: {},
    closeOptions: { ...defaultCloseOptions },
  }),
  getters: {
    selectedCustomJob(state) {
      return state.selected;
    },
    closeOptions(state) {
      return state.closeOptions;
    },
  },
  actions: {
    async getCustomJobs({ commit }, { coachId, teamId, active, professionIds, skillIds, page }) {
      let { data: { jobs } } = await getCustomJobs({
        coachId,
        teamId,
        active,
        professionIds,
        skillIds,
        page
      });
      commit('setCustomJobsList', jobs);
      return jobs;
    },
    async createCustomJob({ commit }, customJob) {
      try {
        const { data: createdCustomJob } = await createCustomJob(customJob);
        commit('createCustomJob', createdCustomJob);
        return createdCustomJob;
      } catch (err) {
        throw err;
      }
    },
    async updateCustomJob({ commit }, payload) {
      try {
        const { data: updatedJob } = await updateCustomJob(payload);
        commit('updateCustomJob', updatedJob);
        return updatedJob;
      } catch (err) {
        throw err;
      }
    },
    async deleteCustomJob({ commit }, customJobId) {
      try {
        await deleteCustomJob(customJobId);
        commit('deleteCustomJob', customJobId);
      } catch (err) {
        throw err;
      }
    },
    setSelected({ commit }, customJob) {
      commit('setSelected', customJob);
    },
    setCloseOptions({ commit }, { key, value }) {
      commit('setCloseOptions', { key, value })
    },
    resetCloseOptions({ commit }) {
      commit('resetCloseOptions')
    },
  },
  mutations: {
    setCustomJobsList(state, list) {
      state.list = list;
    },
    createCustomJob(state, customJob) {
      state.list = [customJob, ...state.list];
    },
    updateCustomJob(state, customJob) {
      const index = state.list.findIndex(job => job._id === customJob._id);
      if (index !== -1) {
        state.list[index] = customJob;
        state.list = [...state.list];
      }
    },
    deleteCustomJob(state, customJobId) {
      state.list = state.list.filter(job => job._id !== customJobId);
    },
    setSelected(state, customJob) {
      state.selected = customJob;
    },
    setCloseOptions(state, { key, value }) {
      state.closeOptions = {
        ...state.closeOptions,
        [key]: value,
      }
    },
    resetCloseOptions(state) {
      state.closeOptions = { ...defaultCloseOptions };
    }
  },
}
