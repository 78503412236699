import { abortProcessCustom as abortProcessCustomCall } from '@/api/processes-custom';

export const abortProcessCustom = ({ id, reason, rejected } = {}) => {
    try {
        return abortProcessCustomCall(id, { reason, rejected });
    } catch (error) {
        if (error?.response) {
            throw new Error(`API: ${error.response.data.message}`);
        }
        throw new Error(error);
    }
};
