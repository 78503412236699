<template>
  <div class="title-resource">
    <div class="title-resource__container">
      <p class="title-resource__name title-resource__name--left">
        {{ textLeft }}
      </p>
      <slot name="icon"></slot>
    </div>
    <p class="title-resource__name title-resource__name--right">
      {{ textRight }}
    </p>
  </div>
</template>

<script>
  export default {
    name: 'title-resource',
    props: {
      textLeft: {
        type: String
      },
      textRight: {
        type: String
      }
    },
  };
</script>

<style lang="scss" scoped>
  .title-resource {
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    text-align: left;
    white-space: nowrap;
    flex-shrink: 0;
    margin-bottom: 5px;

    &__container {
      align-items: center;
      flex-shrink: 0;
      @include bp("tablet") {
      }

      img {
          margin-left: 5px;
          height: 16px;
          width: auto;

        }
        img:hover {
          cursor: pointer;
        }
    }

    &__name {
      display: block;
      line-height: 18px;

      &--left {
        font-size: $font-size-m;
        color: $color-primary;
        margin-right: 5px;
      }

      &--right {
        max-width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: $font-weight-bold;
        font-size: $font-size-m;
        color: $color-blue-dark-cello;
      }
    }
  }
</style>
