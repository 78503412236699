<template>
  <transition-fade>
    <overlay
      :zIndex="110"
      @click.self.native="closeModal()"
      class="modal__overlay">
      <div
        :style="modalStyle"
        class="modal__container"
        :class="`${showModal ? 'modal__container__hunt' : ''}`"
        key="container">
        <slot></slot>
      </div>
    </overlay>
  </transition-fade>
</template>

<script>
  // TODO move or refacto to ui-kit
  import Overlay from '@/components/UIKit/Overlay';
  // dependencies
  import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
  import { mapState } from 'vuex';
  import TransitionFade from '@/components/UIKit/Transition/TransitionFade';

  /**
   * The modal which is called when there is a query modal in the $route
   */
  export default {
    name: 'modal',
    components: {
      TransitionFade,
      Overlay
    },
    props: {
      /**
       * The margin of the modal
       */
      bcModalMargin: {
        type: String
      },
      /**
       * The height of the modal
       */
      bcModalHeight: {
        type: String
      },
      /**
       * The width of the modal
       */
      bcModalWidth: {
        type: String
      },
      bcTopPosition: {
        type: String
      },
      bcBottomPosition: {
        type: String
      },
      bcLeftPosition: {
        type: String
      },
      bcRightPosition: {
        type: String
      },
      bcOverflow: {
        type: String,
        default: 'auto'
      },
      showModal: {
        type: Boolean
      }
    },
    data() {
      return {
        modalNode: null
      };
    },
    computed: {
      ...mapState({
        profile: state => state.panel.profile
      }),
      modalStyle() {
        return {
          ...this.bcModalMargin && { margin: this.bcModalMargin },
          ...this.bcModalHeight && { height: this.bcModalHeight },
          ...this.bcModalWidth && { width: this.bcModalWidth },
          ...this.bcTopPosition && { top: this.bcTopPosition },
          ...this.bcBottomPosition && { bottom: this.bcBottomPosition },
          ...this.bcLeftPosition && { left: this.bcLeftPosition },
          ...this.bcRightPosition && { right: this.bcRightPosition },
          ...this.bcOverflow && { overflow: this.bcOverflow }
        };
      }
    },
    mounted() {
      this.modalNode = document.querySelector('.modal__container');
      disableBodyScroll(this.modalNode);
    },
    methods: {
      closeModal() {
        this.$emit('close-modal');
      }
    },
    beforeDestroy() {
      enableBodyScroll(this.modalNode);
    }
  };
</script>

<style lang="scss" scoped>
  .modal {
    &__overlay {
      justify-content: center;
      align-items: center;
    }

    &__container {
      position: absolute;
      background: $color-white;
      border-radius: 30px;
      width: 100vw;
      height: 100vh;
      justify-content: center;
      z-index: 1000;
      left: 0;
      right: 0;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
      animation-name: modal-transition;
      animation-duration: 500ms;
      @include bp('tablet') {
        width: auto;
        height: auto;
        right: initial;
        left: initial;
      }
    }
  }

  @keyframes modal-transition {
    0% {
      opacity: 0;
      transform: translateY(7%);
    }
    50% {
      opacity: 0;
      transform: translateY(4%);
    }
    75% {
      opacity: 0.5;
      transform: translateY(2%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
</style>
