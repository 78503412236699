
const experiences = [
  { api: 2, name: 'table-header.junior' },
  { api: 3, name: 'table-header.confirmed' },
  { api: 5, name: 'table-header.senior' },
  { api: 4, name: 'table-header.lead' },
  { api: 6, name: 'table-header.manager' },
];

export default experiences;
