import { http } from '@/api/index';
import { i18n } from '../i18n/i18n';

export const kasprTrads = {
  number: i18n.tc('generics.number', 1),
  numbers: i18n.tc('generics.number', 2),
  email: 'Email',
  emails: 'Emails',
};

export const verifyApiKey = (apiKey) => {
  return http.post('/integrations/kaspr/verify', {
    apiKey,
  });
};

export const searchLinkedin = ({ linkedinId, name, contactId, coderId }) => {
  return http.post('/integrations/kaspr/linkedin', {
    linkedinId,
    name,
    coderId,
    contactId,
  });
};

export const getRemainingCredits = (organizationId) => {
  return http.get('/integrations/kaspr/credits', {
    params: {
      organizationId,
    },
  });
};
