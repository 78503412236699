export const expected = {
  pipeToQualify: 1,
  pipeToProcess: 2,
  pipeGarden: 3,
};

export const fixedNames = {
  pipeToQualify: 'toQualify',
  pipeToProcess: 'toProcess',
};

export default {
  expected,
  fixedNames,
};
