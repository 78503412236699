<template>
  <div class="panel-favorite-list-export is-column">
    <div v-if="isCoderOrContact" class="is-column">
      <title-section
        class="panel-favorite-list-export__title"
        position="left"
        :title="$t('email.send-a-mail')"
      >
      </title-section>
      <ButtonEmail @click="setListId" />
    </div>
    <title-section
      class="panel-favorite-list-export__title"
      position="left"
      :title="$t('companies.account-management.export')"
      v-if="showExport"
    >
    </title-section>
    <bc-button @click="setIsOpenModal(true)" v-if="showExport">
      {{ $t('companies.account-management.download-list') }}
    </bc-button>
    <bc-modal :active.sync="isModalOpen">
      <div class="panel-favorite-list-export__modal is-column">
        <div class="panel-favorite-list-export__modal-body is-column">
          <p
            class="panel-favorite-list-export__modal-title is-primary font-weight-bold is-uppercase is-text-align-center">
            {{ $t('companies.account-management.export-candidates-list-and-their-personal-data') }}
          </p>
        </div>
        <div class="panel-favorite-list-export__modal-footer is-justify-content-center">
          <bc-button
            class="panel-favorite-list-export__modal-button"
            type="outlined"
            @click="setIsOpenModal(false)">
            {{ $t('generics.cancel') }}
          </bc-button>
          <bc-button
            class="panel-favorite-list-export__modal-button"
            color="success"
            @click="getExportedCodersEmails">
            {{ $t('companies.account-management.download-export') }}
          </bc-button>
        </div>
      </div>
    </bc-modal>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { saveAs } from 'file-saver';
  import TitleSection from '@/components/Title/TitleSection';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import BcModal from '@/ui-kit/components/BcModal/BcModal';
  import ButtonEmail from '@/components/Button/ButtonEmail';
  import { getFavoriteListContactExported } from '@/api/favoriteLists';
  import { codersLookup, contactsLookup } from '@/api/coders';

  export default {
    name: 'panel-favorite-list-export',
    components: {
      BcModal,
      BcButton,
      TitleSection,
      ButtonEmail,
    },
    props: {
      list: {
        type: Object,
        default: () => ({}),
      }
    },
    mounted(){
      this.showExport = this.profile?._organization?.configuration?.process?.showExport;
    },
    data() {
      return {
        isModalOpen: false,
        showExport: false
      };
    },
    computed: {
      profile() {
        return this.$store.state.user.profile;
      },
      isCoderOrContact() {
        return this.$route.query['list-type'] === 'coder' || this.$route.query['list-type'] === 'contact'
      }
    },
    methods: {
      ...mapActions({
        setSelectedCoders: 'emails/setSelectedCoders',
        setEmailIsLoading: 'emails/setIsLoading',
      }),
      setIsOpenModal(value) {
        this.isModalOpen = value;
      },
      async getExportedCodersEmails() {
        try {
          const { data } = await getFavoriteListContactExported(this.list._id);

          let json = JSON.stringify(data || '');
          let buffer = Buffer.from(JSON.parse(json));
          let readUTF8 = buffer.toString('utf8');
          const blob = new Blob([readUTF8], { type: 'text/csv' });
          saveAs(blob, `${this.list.name}.csv`);

          this.setIsOpenModal(false);

          this.$toast.show({
            type: 'success',
            message: this.$i18n.t("toast.candidates-list-correctly-exported")
          });
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: error?.response?.data?.message || error,
          });
          return;
        }
      },
      async setListId() {
        const listId = this.list?._id;
        if (!listId) return;

        try {
          this.setEmailIsLoading(true);

          let response = null;
          if (this.$route.query['list-type'] === 'contact') {
            response = await contactsLookup({ listId, withoutPagination: true });
          } else if (this.$route.query['list-type'] === 'coder') {
            response = await codersLookup({ listId, withoutPagination: true });
          }
          const list = response?.data;
          if (list?.coders?.length) {
            this.setSelectedCoders(list.coders)
          }

          this.setEmailIsLoading(false);
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err?.message,
          });
          this.setEmailIsLoading(false);
        }
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-favorite-list-export {
    &__title {
      margin-top: 30px;
      margin-bottom: 15px;
    }

    &__modal {
      max-width: 600px;
      padding: 10px 0;
    }

    &__modal-body, &__modal-footer {
      padding: 25px 50px;
    }

    &__modal-footer {
      border-top: 1px solid $color-grey-3;
    }

    &__modal-checkbox {
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__modal-button {
      margin: 0 10px;
    }
  }
</style>
