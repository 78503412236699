<template>
  <div class="hellowork">
    <JobboardConfigItem
      :channel="channel"
      :subscriptionModel="subscriptionModel"
      :applicationMode="applicationMode"
      :applicationForwardingType="applicationForwardingType"
      :disabledSubscriptions="disabledSubscriptionsMapped"
      :disabledApplications="disabledApplicationsMapped"
      :is-valid="isValid"
      @update-subscription-model="updateSubscriptionModel"
      @update-application-mode="updateApplicationMode"
      @update-application-forwarding-type="updateApplicationForwardingType"
      @cancel="$emit('cancel', $event)"
      @submit="onSubmit">
      <template #subscriptionContent>
        <div v-if="subscriptionModel && subscriptionModel !== subscriptionsModels.organic" class="mt-5 pt-3 is-full-width is-column">
          <ContractIdField
            :label="$t('generics.contract-id')"
            :placeholder="`${$t('generics.contract-id').toLowerCase()} Hellowork`"
            :warning="$t('jobboards.warning-login-hellowork')"
            v-model="contractId"
          />
          <div v-if="!isXtramile" class="mt-4 is-column">
            <div class="is-column">
              <label class="hellowork__label">{{ $t('generics.auto-broadcast') }}</label>
              <div>
                <bc-toggle id="hellowork-auto" v-model="autoPublish"></bc-toggle>
                <p class="ml-3" :class="{ 'hellowork__activated': autoPublish, 'hellowork__disabled': !autoPublish }">
                  {{ autoPublish ? $t('generics.activated') : $t('generics.disabled') }}
                </p>
                <WarningCercle
                  class="ml-3"
                  :content="$t('jobboards.jobboard-auto-warning', { jobboard: 'Hellowork' })"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </JobboardConfigItem>
  </div>
</template>

<script>
import { subscriptionsModels, applicationModes } from '@/api/xtramile';
import BcToggle from '@/ui-kit/components/BcToggle/BcToggle';
import WarningCercle from '@/components/WarningCercle/WarningCercle';
import ContractIdField from '@/components/Jobboard/JobboardConfigChannel/Fields/ContractIdField';
import JobboardConfigItem from '@/components/Jobboard/JobboardConfig/JobboardConfigItem';

export default {
  name: 'hellowork',
  components: {
    BcToggle,
    WarningCercle,
    ContractIdField,
    JobboardConfigItem,
  },
  props: {
    channel: {
      type: Object,
      default: () => ({}),
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    disabledSubscriptions: {
      type: Array,
      default: () => [],
    },
    disabledApplications: {
      type: Array,
      default: () => [],
    },
    isXtramile: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.isXtramile && this.values?.params) {
      this.subscriptionModel = this.values.params.subscriptionModel;
      this.applicationMode = this.values.params.applicationMode;
      this.applicationForwardingType = this.values.params.applicationForwardingType;
      this.contractId = this.values.params.accountParams.CONTRACT_ID;
    }
    if (!this.isXtramile) {
      this.subscriptionModel = this.subscriptionsModels.credits;
      this.applicationMode = this.applicationModes.direct;
      this.contractId = this.values?.credentials?.contractId;
      this.autoPublish = this.values?.params?.autoPublish;
    }
  },
  data() {
    return {
      subscriptionsModels,
      applicationModes,
      contractId: '',
      subscriptionModel: '',
      applicationMode: '',
      autoPublish: false,
      applicationForwardingType: '',
    }
  },
  computed: {
    isSubscriptionValid() {
      if (!this.subscriptionModel) return false;
      if (this.subscriptionModel === this.subscriptionsModels.costPerClick) {
        return !!this.contractId;
      }
      if (!this.isXtramile && this.subscriptionModel === this.subscriptionsModels.credits) {
        return !!this.contractId;
      }
      return true;
    },
    isApplicationValid() {
      if (!this.applicationMode) return false;
      return true;
    },
    isValid() {
      return this.isSubscriptionValid && this.isApplicationValid;
    },
    disabledSubscriptionsMapped() {
      if (this.isXtramile) {
        return this.disabledSubscriptions;
      } else {
        return Object.values(this.subscriptionsModels).filter(value => value !== this.subscriptionsModels.credits)
      }
    },
    disabledApplicationsMapped() {
      if (this.isXtramile) {
        return this.disabledApplications;
      } else {
        return Object.values(this.applicationModes).filter(value => value !== this.applicationModes.direct)
      }
    }
  },
  methods: {
    updateSubscriptionModel(key) {
      this.subscriptionModel = key;
    },
    updateApplicationMode(key) {
      this.applicationMode = key;
    },
    updateApplicationForwardingType(value) {
      this.applicationForwardingType = value;
    },
    onSubmit() {
      if (!this.isXtramile) {
        this.$emit('submit', {
          isXtramile: false,
          jobboard: 'hellowork',
          credentials: {
            contractId: this.contractId,
          },
          params: {
            autoPublish: this.autoPublish,
          },
        })
        return
      }
      this.$emit('submit', {
        channelId: this.channel.id,
        subscriptionModel: this.subscriptionModel,
        applicationMode: this.applicationMode,
        applicationForwardingType: this.applicationForwardingType,
        accountParams: {
          CONTRACT_ID: this.contractId,
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.hellowork {
  &__label {
    color: $color-blue-heavy-dark;
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  p {
    font-size: 15px;
    font-weight: 400;
  }

  &__activated {
    color: $color-green-shamrock;
  }

  &__disabled {
    color: $color-red-mandy;
  }
}
</style>
