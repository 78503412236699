import Vue from 'vue';
import TopbarOnboardingToast from './TopbarOnboardingToast';

const Toast = {
  show(params) {
    let message;

    if (typeof params === 'string') {
      message = params;
    }

    const config = {
      message,
    };

    const propsData = { ...config, ...params };

    const Component = Vue.extend(TopbarOnboardingToast);

    return new Component({
      el: document.createElement('div'),
      propsData
    });
  }
};

const Plugin = {
  install(Vue) {
    Vue.prototype.$onboardingToast = Toast;
  }
};

export default Plugin;
