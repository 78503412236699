import timeline from '@/api/timeline';
import { validateContent } from '@/managers/notes/validators/validateContent';
import { serializeNote } from '@/managers/notes/formatters/noteSerializers';

export const editDraft = async({ id, note }) => {
  const validate = note => {
    try {
      const mapValidator = {
        content: validateContent,
      };

      const keysToValidate = Object.keys(note);

      keysToValidate.forEach(key => mapValidator[key]);
    } catch (error) {
      throw error;
    }
  };

  const execute = (id, note) => {
    try {
      return timeline.editDraft({ id, note });
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    validate(note);

    const serializedNote = serializeNote(note);

    const { data } = await execute(id, serializedNote);

    return data;
  } catch (error) {
    throw error;
  }
};

export default {
  editDraft,
};
