<template>
  <div class="panel-favorite-list-modal-leave-list">
    <p
      class="panel-favorite-list-modal-leave-list__text panel-favorite-list-modal-leave-list__text--large">
      {{ $t("companies.account-management.do-you-really-want-to-unfollow-this-list") }}
    </p>
    <div class="panel-favorite-list-modal-leave-list__cta">
      <bc-button
        @click.native="goCancel"
        bc-backgroud-color-hover="red-mandy"
        bc-background-color="red-mandy"
        bc-radius="54px"
        bc-text-color="white"
        class="panel-favorite-list-modal-leave-list__validate">
        {{ $t('generics.cancel') }}
      </bc-button>
      <bc-button
        @click.native="goValidate"
        bc-background-color="blue-dodger"
        bc-background-color-hover="blue-dodger"
        bc-radius="54px"
        bc-text-color="white"
        class="panel-favorite-list-modal-leave-list__validate">
        {{ $t('generics.validate') }}
      </bc-button>
    </div>
  </div>
</template>

<script>
  import BcButton from "@/legacy/ui-kit/components/Button/BcButton";

  export default {
    name: 'panel-favorite-list-modal-leave-list',
    components: {BcButton},
    methods: {
      goCancel() {
        this.$emit('close-modal');
      },
      goValidate() {
        this.$emit('on-validate', 'leaveList');
      }
    }
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/layouts/panel-favorite-list-modal';

  .panel-favorite-list-modal-leave-list {
    @include panel-favorite-list-modal
  }
</style>
