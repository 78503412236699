import documents from '@/api/documents';

export const removeCoderDocument = id => {
  const execute = id => {
    try {
      return documents.removeDocument(id);
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    return execute(id);
  } catch (error) {
    throw error;
  }
};

export default {
  removeCoderDocument,
};
