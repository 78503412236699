<template>
  <div class="home-statistics is-column is-full-width is-align-items-center">
    <views-topbar :title="$t('activities.performances-cap')">
      <home-filters
        class="ml-3 mr-auto"
        :coach-dropdown="coachesOptions"
        :is-team-gestion-active="isTeamGestionActive"
        @switch-team="updateTeamActive">
      </home-filters>
    </views-topbar>
    <views-container>
      <home-kpi
        :loading="isLoading"
        :performances="performances">
      </home-kpi>
    </views-container>
  </div>
</template>

<script>
  import HomeFilters from '@/components/Home/HomeStatistics/HomeStatisticsFilters';
  import HomeKpi from '@/components/Home/HomeStatistics/HomeStatisticsKpi';
  import { getPerformances } from '@/api/home';
  import teamMacros from '@/common-old/macros/team';
  import { getFilterableCoach } from '@/macros/prospectionPipe/dropdowns';
  import { http } from '@/api/index';
  import ViewsTopbar from '@/components/Views/ViewsTopbar';
  import ViewsContainer from '@/components/Views/ViewsContainer';

  export default {
    name: 'home-statistics',
    components: { ViewsContainer, ViewsTopbar, HomeKpi, HomeFilters },
    data() {
      return {
        userTeamId: teamMacros.business.type,
        coachesCopy: [],
        isLoading: true,
        performances: {
          codersPerformances: {
            contacted: {
              name: 'activities.making-contacts-upper',
              icon: 'icon-contacted',
            },
            qualified: {
              name: 'activities.full-takes-upper',

              icon: 'icon-qualified',
            },
          },
          partnersPerformances: {
            discussed: {
              name: 'activities.exchanges-upper',
              icon: 'icon-chat',
            },
            signed: {
             name: 'activities.signed-upper',
              icon: 'icon-signed',
            },
          },
          jobsPerformances: {
            published: {
             name: 'activities.published-upper',
              icon: 'icon-suitcase',
            },
          },
          processesPerformances: {
            introduced: {
              name: 'activities.presentations-upper',
              icon: 'icon-presentation',
            },
            processed: {
              name: 'activities.processing-upper',
              icon: 'icon-process',
            },
            finalised: {
              name: 'activities.in-final-upper',
              icon: 'icon-final',
            },
            offered: {
              name: 'activities.offers-upper',
              icon: 'icon-offer',
            },
            dealt: {
              name: 'activities.deals-upper',
              icon: 'icon-partners',
            },
            dropped: {
              name: 'activities.drops-upper',
              icon: 'icon-drop',
            },
          },
        },
      };
    },
    async mounted() {
      for (const team of Object.values(teamMacros)) {
        if (team.roles.includes(this.profile.role)) {
          this.userTeamId = team.type;
        }
      }

      this.coachesCopy = await this.getCoachesByTeamName();
      if (this.coachesCopy.find(c => c._id === this.profile._id)) {
        this.$router.push({
          query: {
            ...this.$route.query,
            'coach-id': this.profile._id,
          },
        });
      }
      await this.getPerformances();
    },
    computed: {
      isTeamGestionActive() {
        return this.userTeamId === teamMacros.gestion.type;
      },
      profile() {
        return this.$store.state.user.profile;
      },
      coachesOptions() {
        return this.coachesCopy
        .filter(coach => getFilterableCoach(coach))
        .sort((a, b) => a.firstName.localeCompare(b.firstName));
      },
    },
    methods: {
      async updateTeamActive(value) {
        if (this.$route.query['coach-id']) {
          const newQuery = { ...this.$route.query };

          delete newQuery['coach-id'];
          this.$router.replace({ query: newQuery });
        }

        this.userTeamId = value ? teamMacros.business.type : teamMacros.gestion.type;

        this.coachesCopy = await this.getCoachesByTeamName();
        await this.getPerformances();
      },
      async getPerformances() {
        const start = this.$route.query['start-date'];
        const end = this.$route.query['end-date'];
        const coachId = this.$route.query['coach-id'] || (this.coachesOptions && this.coachesOptions.length > 0 && this.coachesOptions[0]._id) || {};
        const team = Object.keys(teamMacros).find(team => teamMacros[team].type === this.userTeamId);

        const coach = this.coachesOptions.find(c => c._id === coachId);
        if (!coach || !teamMacros[team].roles.includes(coach.role)) {
          return;
        }

        try {
          this.isLoading = true;

          const { data: entities } = await getPerformances(start, end, coachId, team);

          for (const entity in entities) {
            for (const stats in this.performances[entity]) {
              this.performances[entity][stats] = {
                ...this.performances[entity][stats],
                ...entities[entity][stats],
              };
            }
          }

          // We remove the topPerformer stat as we don't want to show it to the user.
          this.performances['processesPerformances']['dropped'].topPerformers = [];

          this.isLoading = false;
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });

          this.isLoading = false;
        }
      },
      async getCoachesByTeamName() {
        const { data } = await http.get('/coaches', {
          params: {
            team: Object.keys(teamMacros).find(team => teamMacros[team].type === this.userTeamId),
          },
        });

        return data.coaches;
      },
    },
    watch: {
      '$route.query': {
        async handler() {
          await this.getPerformances();
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
