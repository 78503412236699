<template>
  <div class="panel-poke-caption-pokers">
    <panel-poke-caption-pokers-card
      :card-content="leftContent"
      :side="'left'"
      @click.native="openPanel({ id: leftContent._id, type: leftContent.panelType })"
      class="panel-poke-caption-pokers__card">
    </panel-poke-caption-pokers-card>
    <panel-poke-caption-status
      :card-content="cardContent"
      class="panel-poke-caption-pokers__status">
    </panel-poke-caption-status>
    <panel-poke-caption-pokers-card
      :card-content="rightContent"
      :side="'right'"
      @click.native="openPanel({ id: rightContent._id, type: rightContent.panelType })"
      class="panel-poke-caption-pokers__card">
    </panel-poke-caption-pokers-card>
  </div>
</template>

<script>
  // mixins
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  // components
  import PanelPokeCaptionStatus from '@/components/Panel/PanelPoke/PanelPokeCaption/PanelPokeCaptionStatus';
  import PanelPokeCaptionPokersCard
    from '@/components/Panel/PanelPoke/PanelPokeCaption/PanelPokeCaptionPokers/PanelPokeCaptionPokersCard';

  export default {
    name: 'panel-poke-caption-pokers',
    components: {
      PanelPokeCaptionStatus,
      PanelPokeCaptionPokersCard,
    },
    props: {
      cardContent: {
        type: Object,
        default: () => ({}),
      },
    },
    mixins: [PanelNavigation],
    data: () => ({
      completedSteps: 100,
    }),
    computed: {
      /**
       * The coder map object for the cardInfo component
       * @returns {{pictureUrl: string, name: string, title: string, infos: computed.infosCoder, tags: *}}
       */
      coderObject() {
        return {
          pictureUrl: this.cardContent && this.cardContent._coder ? this.cardContent._coder.pictureUrl : '',
          name: this.cardContent && this.cardContent._coder ? `${this.cardContent._coder.firstName} ${this.cardContent._coder.lastName}` : '',
          title: this.cardContent && this.cardContent._coder ? this.cardContent._coder.title : '',
          contracts: this.cardContent && this.cardContent._coder ? this.cardContent._coder.contracts : [],
          tags: this.cardContent && this.cardContent._coder ? [...this.cardContent._coder._technos3, ...this.cardContent._coder._technos2, ...this.cardContent._coder._technos1] : [],
          _id: this.cardContent && this.cardContent._coder ? this.cardContent._coder._id : '',
          panelType: 'coders',
          remote: this.cardContent && this.cardContent._coder && this.cardContent._coder.remote,
        };
      },
      /**
       * The job map object for the cardInfo component
       * @returns {{pictureUrl: string, name: string, title: string, infos: computed.infosJob, tags: *}}
       */
      jobObject() {
        return {
          pictureUrl: this.cardContent && this.cardContent._company ? this.cardContent._company.logoUrl : '',
          name: this.cardContent && this.cardContent._company ? this.cardContent._company.name : '',
          title: this.cardContent && this.cardContent._job ? this.cardContent._job.name : '',
          contracts: this.cardContent && this.cardContent._job ? [this.cardContent._job.contract] : [],
          tags: this.cardContent && this.cardContent._job ? [...this.cardContent._job._technosPrimary, ...this.cardContent._job._technosSecondary] : [],
          _id: this.cardContent && this.cardContent._job ? this.cardContent._job._id : '',
          panelType: 'jobs',
          remote: this.cardContent && this.cardContent._job && this.cardContent._job.remote,
        };
      },
      leftContent() {
        return this.cardContent.direction === 2 ? this.jobObject : this.coderObject;
      },
      rightContent() {
        return this.cardContent.direction === 2 ? this.coderObject : this.jobObject;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-poke-caption-pokers {
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 15px;
    overflow: auto;
    display: block;
    @include bp('tablet') {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0;
    }

    &__status {
      @include bp('tablet') {
        height: 100%;
      }
    }

    &__card {
      border: 1px solid $color-gray-periwinkle;
      border-radius: 4px;
      background: $color-white;
      z-index: 1;
      transition: 0.3s;
      @include bp('tablet') {
        margin-bottom: 0;
        height: 100%;
        max-width: 300px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &:hover {
        cursor: pointer;
        box-shadow: $shadow-default;
      }
    }
  }
</style>
