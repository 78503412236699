<template>
  <div class="flex size-[36px] flex-shrink-0 items-center justify-center rounded-full bg-blue-100">
    <Dropdown :is-open="isOpen" class="z-10" @close="isOpen = false">
      <template #trigger>
        <button class="flex" @click.stop="handleClick">
          <Spinner v-if="relationLoading || invitationsLoading" bcSize="16px" />
          <img v-else :src="require('@/assets/logo/linkedin-message.svg')" alt="linkedin" />
        </button>
      </template>
      <div class="flex-col min-w-48 rounded-md bg-white text-xs shadow-md divide-y divide-blue-200">
        <button
          :disabled="!canInvite"
          @click="handleInvitation"
          class="flex w-full gap-2.5 h-9 px-5 cursor-default enabled:cursor-pointer items-center border-b-blue-200 opacity-60 enabled:opacity-100 enabled:hover:bg-blue-500 enabled:hover:text-white"
        >
          <img :src="require('@/assets/logo/linkedin-invitation.svg')" alt="linkedin-invitation" class="size-[13px]" />
          <p>{{ t('linkedin.send-an-invitation') }}</p>
        </button>
        <button
          :disabled="!canInmail"
          @click="handleInmail"
          class="flex w-full gap-2.5 h-9 px-5 cursor-default enabled:cursor-pointer items-center border-b-blue-200 opacity-60 enabled:opacity-100 enabled:hover:bg-blue-500 enabled:hover:text-white"
        >
          <img :src="require('@/assets/logo/linkedin-inmail.svg')" alt="linkedin-inmail" class="size-[13px]" />
          <p>{{ t('linkedin.send-an-inmail') }}</p>
        </button>
      </div>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useI18n } from '@/i18n/i18n';
import Spinner from '@/components/UIKit/Spinner/Spinner.vue';
import { useLinkedinPanel } from '@/composables/useLinkedinPanel';
import { linkedinMacros } from '@/macros/linkedin';
import { useLinkedinRelationsApi } from '@/composables/api/useLinkedinRelationsApi';
import { useLinkedinCandidate } from '@/composables/useLinkedinCandidate';
import type { Candidate } from '@/domains/models/Candidate';
import Dropdown from '@/components/UIKit/Dropdown/Dropdown.vue';

interface Props {
  candidate: Candidate;
}

const props = defineProps<Props>();

const { t } = useI18n();
const { openLinkedinPanel } = useLinkedinPanel();

const candidateRef = computed(() => props.candidate);
const { canMessage, canInvite, canInmail } = useLinkedinCandidate(candidateRef);

console.log('canMessage', canMessage.value);
  console.log('canInvite', canInvite.value);
  console.log('canInmail', canInmail.value);
const { syncRelationStatus, loading: relationLoading } = useLinkedinRelationsApi();
const { getPendingInvitations, loading: invitationsLoading } = useLinkedinRelationsApi();

const isOpen = ref(false);

async function handleClick() {
  if (canMessage.value) {
    await openLinkedinPanel(props.candidate, linkedinMacros.templates.type.message);
    return;
  }

  await syncRelationStatus(props.candidate._id);

  if (canMessage.value) {
    await openLinkedinPanel(props.candidate, linkedinMacros.templates.type.message);
    return;
  }

  if (canInvite.value) {
    await getPendingInvitations();
  }

  isOpen.value = !isOpen.value;
}

async function handleInvitation() {
  if (!canInvite.value) return;
  isOpen.value = false;
  await openLinkedinPanel(props.candidate, linkedinMacros.templates.type.invitation);
}

async function handleInmail() {
  if (!canInmail.value) return;
  isOpen.value = false;
  await openLinkedinPanel(props.candidate, linkedinMacros.templates.type.inmail);
}
</script>
