<template>
  <div class="container">
    <div
      :class="[{
        'panel-categories-item-old-style--active' : isActive && !isSingle,
        'panel-categories-item-old-style--active-green': isActive && !isSingle && category.color === 'green',
        'panel-categories-item-old-style--active-yellow': isActive && !isSingle && category.color === 'yellow',
        'panel-categories-item-old-style--single': isSingle && isNotMobile,
        'panel-categories-item-old-style--disabled': isDisabled }]"
      @click="changeCategory"
      class="panel-categories-item-old-style">
      <p
        :class="{'panel-categories-item-old-style__text--single': isSingle && isNotMobile, 'panel-categories-item-old-style__text--disabled': isDisabled}"
        class="panel-categories-item-old-style__text">
        <span
          class="panel-categories-item-old-style__icon icon-lock"
          v-if="isDisabled">
        </span>
        {{ categoryName }}
      </p>
      <div
        v-if="count"
        class="panel-categories-item-old-style__count"
        :class="{ 'panel-categories-item-old-style__count--active': isActive }"
      >
        {{ count }}
      </div>
      <div
        v-if="badge"
        class="panel-categories-item-old-style__badge"
        :style="{ 'background-color': badge.backgroundColor, 'color': badge.color }"
      >
        {{ badge.label }}
      </div>
    </div>
    <div
      v-if="!isSingle"
      class="bottom-bar"
      :class="{
        'bottom-bar--active-left': isActive && direction === 'left',
        'bottom-bar--active-right': isActive && direction === 'right',
      }"
    ></div>
  </div>
</template>

<script>
  // TODO move or refacto to ui-kit
  /**
   * This component is the category item of the Panel. It changes the $route when we click on it
   */
  export default {
    name: 'panel-categories-item-old-style',
    props: {
      /**
       * The category which will be show
       */
      category: Object,
      /**
       * The index of the category
       */
      categoryIndex: Number,
      /**
       * Indicate if the category is unique, which will means that the style will be different.
       */
      isSingle: Boolean,
      /**
       * Indicate if the categories should be display in phone style or in desktop style
       */
      isNotMobile: Boolean,
      categories: {
        type: Array,
        default: () => [],
      },
      count: {
        type: Number,
        default: 0,
      }
    },
    mounted() {
      this.setDirection()
    },
    data() {
      return {
        direction: 'left',
      }
    },
    computed: {
      /**
       * Indicate if the category should be blue or not
       */
      isActive() {
        return this.$route.query.category === this.category.url || (!this.$route.query.category && this.categoryIndex === 0);
      },
      /**
       * Indicate if the category is disabled
       */
      isDisabled() {
        return this.category.disabled;
      },
      badge() {
        return this.category.badge;
      },
      categoryName() {
        return this.category.name.slice(0, 1).toUpperCase() + this.category.name.slice(1).toLowerCase();
      }
    },
    methods: {
      setDirection() {
        const i = this.categories.findIndex(category => category.url === this.$route.query.category)
        this.direction = i > this.categoryIndex ? 'right' : 'left';
      },
      changeCategory() {
        /**
         * Change the category with another route and set the activeCategory state to true if the category is not disabled
         */
        if (!this.isDisabled) {
          this.setDirection();
          this.$router.push({
            name: this.$route.name,
            params: this.$route.params,
            query: { ...this.$route.query, category: this.category.url }
          });
          this.$store.dispatch('setActiveCategory', true);
        }
      }
    },
    async beforeDestroy() {
      /**
       * Set to false the activeCategory state before destroying the component to reinitialize the Panel
       */
      this.$store.dispatch('setActiveCategory', false);
    }
  };
</script>

<style scoped lang="scss">
  .container {
    flex-direction: column;
    width: 100%;
  }

  .bottom-bar {
    height: 5px;
    background: $color-blue-light;
    width: 100%;

    &--active-left {
      height: 6px;
      margin-top: -1px;
      background: $color-primary;
      border-radius: 10px 10px 0px 0px;
      animation: left-slide 0.2s ease-in;
    }

    &--active-right {
      height: 6px;
      margin-top: -1px;
      background: $color-primary;
      border-radius: 10px 10px 0px 0px;
      animation: right-slide 0.2s ease-in;
    }
  }

  .panel-categories-item-old-style {
    padding: 12.5px 0;
    justify-content: center;
    font-size: $font-size-s;
    font-weight: $font-weight-light;
    width: 100%;
    @include transition(all 0.2s ease);
    color: $color-tertiary;
    align-items: center;
    max-height: 43px;

    &:hover {
      cursor: pointer;
    }
    &--active {
      & > p {
        color: $color-primary;
      }

      &-green {
        background: $color-green-lima;
        border: 1px solid $color-green-lima;
        &:hover {
          background: $color-green-lima;
        }
      }
      &-yellow {
        background: $color-yellow-lighting;
        border: 1px solid $color-yellow-lighting;
        &:hover {
          background: $color-yellow-lighting;
        }
      }
    }
    &--single {
      line-height: 1em;
      position: relative;
      color: $color-blue;
      text-align: center;
      outline: 0;
      border: 0;
      width: 100%;
      height: 44px;
      font-size: $font-size-s;
      text-transform: uppercase;
      font-weight: $font-weight-medium;
      align-items: center;
      &:before {
        content: '';
        background: $color-blue;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
      }
      &:after {
        content: attr(data-content);
        position: relative;
        display: inline-block;
        color: $color-blue;
        padding: 0 0.5em;
        background-color: $color-white;
      }
      &:hover {
        cursor: initial;
        background: $color-white;
      }
    }
    &--disabled {
      background: $color-gray-mystic;
      color: $color-gray-silver-chalice;
      border: 1px solid $color-gray-mystic;
      &:before, &:after {
        display: none;
      }
      &:hover {
        cursor: auto;
        background: $color-gray-mystic;
      }
    }
    &__text {
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $color-tertiary;

      &--single {
        z-index: 1;
        padding: 0 10px;
        background-color: $color-white;
      }
      &--disabled {
        background: $color-gray-mystic;
      }
    }
    &__icon {
      margin-right: 10px;
    }
    &__badge {
      margin-left: 10px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-transform: capitalize;
      border-radius: 10px;
      padding: 2px 6px;
    }
    &__count {
      width: 20px;
      height: 20px;
      background: $color-blue-medium;
      color: $color-tertiary;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      font-size: 14px;
      margin-left: 5px;

      &--active {
        background: $color-primary;
        color: white;
      }
    }
  }
</style>
