<template>
  <filter-content
    class="filter-coders-contract-type"
    :label="$t('companies.account-management.contract-type')"
    icon="file">
    <div class="filter-coders-contract-type__container flex-wrap">
      <bc-radio
        :id="contract.query"
        :key="contractIndex"
        :label="contract.name"
        :name="contract.name"
        :value="contract.query"
        :checked="selected === contract.query"
        @input="handleCheck(contract.query)"
        v-for="(contract, contractIndex) in contracts">
        {{ $t(contract.name) }}
      </bc-radio>
    </div>
  </filter-content>
</template>

<script setup lang="ts">
import { computed, ref, defineProps, defineEmits, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import BcRadio from '@/ui-kit/components/BcRadio/BcRadio.vue';
import FilterContent from '@/components/Filter/FilterContent.vue';

const props = defineProps<{
  emitOnly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:filters', filters: Record<string, any>): void;
  (e: 'count', value: { key: string; count: number }): void;
}>();

const route = useRoute();
const router = useRouter();

const localSelected = ref<string | undefined>();

const selected = computed(() => props.emitOnly ? localSelected.value : route.query.contracts);

const contracts = [
  {
    name: 'panel-coder-edit.permanent-contract',
    query: 'cdi',
  },
  {
    name: 'contracts.cdd',
    query: 'cdd',
  },
  {
    name: 'panel-coder-edit.mixed-training',
    query: 'alternance',
  },
  {
    name: 'panel-coder-edit.training-course',
    query: 'stage',
  },
  {
    name: 'contracts.interim',
    query: 'interim',
  },
  {
    name: 'panel-coder-edit.freelance',
    query: 'freelance',
  },
  {
    name: 'contracts.consultant',
    query: 'consultant',
  },
  {
    name: 'contracts.intermittent',
    query: 'intermittent',
  },
];

function handleCheck(query: string) {
  const newValue = selected.value !== query ? query : undefined;
  
  if (props.emitOnly) {
    localSelected.value = newValue;
    emit('update:filters', {
      contracts: newValue,
    });
  } else {
    router.push({
      query: {
        ...route.query,
        page: 1,
        contracts: newValue,
      },
    });
  }
}

watch(selected, (value) => {
  emit('count', { key: 'contract-type', count: value ? 1 : 0 });
}, { immediate: true });
</script>

<style lang="scss" scoped>
.filter-coders-contract-type {
  &__container {
    gap: 15px;
    margin-top: 5px;
  }
}
</style>
