<template>
  <views-tabs
    :active="activeTab"
    :tabs="tabs"
    :for-route="false"
  >
    <template #content>
      <slot></slot>
    </template>
  </views-tabs>
</template>

<script>
import { mapGetters } from 'vuex';
import ViewsTabs from '@/components/Views/ViewsTabs';

export default {
  name: 'lists-tabs',
  components: {
    ViewsTabs,
  },
  computed: {
    ...mapGetters({
      ressourceCount: 'metadata/ressourceCount',
      coachRessourceCount: 'metadata/coachRessourceCount',
    }),
    tabs() {
      return [
        {
          key: 'company',
          label: this.$i18n.t('generics.companies'),
          // to: { path: '/favorite-lists', query: { page: '1', 'list-type': 'company' } },
          count: this.coachRessourceCount.companyList || 0,
          icon: 'list',
          exact: true,
          iconFontSize: '0.732rem',
          onClick: () => {
            this.$router.push({ name: 'FavoriteLists', query: { page: '1', 'list-type': 'company' } })
          },
        },
        {
          key: 'coder',
          label: this.$i18n.tc('generics.candidate-cap', 2),
          // to: { path: '/favorite-lists', query: { page: '1', 'list-type': 'coder' } },
          count: this.coachRessourceCount.candidateList || 0,
          icon: 'users',
          exact: true,
          onClick: () => {
            this.$router.push({ name: 'FavoriteLists', query: { page: '1', 'list-type': 'coder' } })
          },
        },
        {
          key: 'contact',
          label: this.$i18n.tc('generics.contact-cap', 2),
          // to: { path: '/favorite-lists', query: { page: '1', 'list-type': 'contact' } },
          count: this.coachRessourceCount.contactList || 0,
          icon: 'contact',
          exact: true,
          onClick: () => {
            this.$router.push({ name: 'FavoriteLists', query: { page: '1', 'list-type': 'contact' } })
          },
        },
      ];
    },
    activeTab() {
      return this.$route.query?.['list-type'] || 'company';
    },
  },
}
</script>
