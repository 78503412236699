import { domains } from '@/common-old/macros/market';
import { roles } from '@/common-old/macros/roles';
import { contracts } from '@/common-old/macros/contracts';
import { experience } from '@/common-old/macros/experience';

export const status = {
  draft: 1,
  online: 2,
  offline: 3
};

export default { status, domains, roles, contracts, experience, };
