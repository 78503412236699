<template>
  <div class="searchbar-autocomplete-partners">
    <div class="searchbar-autocomplete-partners__bar">
      <div class="searchbar-autocomplete-partners__wrapper">
        <mv-searchbar
          v-model="searchbarInput"
          :title="$tc('generics.company', 2)"
          :is-loading="isLoading"
          :suggestions="suggestions"
          :placeholder="$t('companies.account-management.companies-data-basis')"
          :is-scrollable="true"
          @select="triggerAutocompleteEvent"
        >
          <template #results="{ suggestion, index }">
            <searchbar-partners-item
              :suggestion="suggestion"
              :search="searchbarInput"
              :show-label="index === 0"
            ></searchbar-partners-item>
          </template>
        </mv-searchbar>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import debounce from 'debounce';
  import partnersApi from '@/api/company';
  // mixins
  import AutocompleteMixin from '@/common-old/mixins/Autocomplete/autocomplete';
  import TechnosMixin from '@/mixins/Technos';
  import SearchbarAutocompleteEventsMixin from '@/mixins/SearchbarAutocomplete/searchbarAutocompleteEvents';
  import SearchbarPartnersItem from '@/components/Searchbar/SearchbarPartners/SearchbarPartnersItem';
  import MvSearchbar from '@/ui-kit/components/MvSearchbar/MvSearchbar';

  export default {
    name: 'searchbar-autocomplete-partners',
    components: {
      MvSearchbar,
      SearchbarPartnersItem,
    },
    mixins: [AutocompleteMixin, TechnosMixin, SearchbarAutocompleteEventsMixin],
    props: {
      listId: {
        type: String,
      },
    },
    data() {
      return {
        isLoading: false,
        isFocusedSearchbar: false,
        searchbarInput: '',
        matchesLimit: 5,
        suggestions: [],
        lastIndexesOfCategories: []
      };
    },
    mounted() {
      // const input = this.$refs['searchbar-autocomplete-partners-input'].$el.children[0];
      // if (input) {
      //   input.focus();
      // }
    },
    computed: {
      ...mapState({
        inputValue: state => state.autocomplete.inputValue,
        isActiveFilter: state => state.searchbar.isActiveFilter,
        searchbarTags: state => state.searchbar.searchbarFiltersTags,
      }),
      suggestionSelected() {
        if (typeof this.pointer === 'number') {
          return this.suggestions[this.pointer || 0];
        }
        return this.pointer;
      }
    },
    methods: {
      /***
       *
       * Clear the input field
       */
      clearInput() {
        this.searchbarInput = '';
      },
      emitOnDelete() {
        this.$emit('on-delete');
      },
      /**
       *
       * Delete inside the searchbarTags array, the last tag
       */
      deleteInputQuery() {
        if (this.searchbarInput.length === 0 && this.searchbarTags.length > 0) {
          this.$store.dispatch('popSearchbarFiltersTags');

          this.$emit('on-delete');
        }
      },
      updateLastIndexesOfCategories() {
        this.lastIndexesOfCategories = [];

        for (let i = 0; i < this.suggestions.length - 1; i++) {
          if (this.suggestions[i].type !== this.suggestions[i + 1].type) {
            this.lastIndexesOfCategories.push(i + 1);
          }
        }
      },
      async getJobsSuggestions() {
        try {
          this.isLoading = true;
          const partners = await partnersApi.partnersAutocomplete(this.searchbarInput, this.listId);

          if (!this.isFocusedSearchbar) {
            this.openSuggestionsList();
          }

          this.partnersSuggestions = partners.data.map(partner => ({
            type: 'partner',
            _id: partner._id,
            name: partner.name,
            picture: partner.logoUrl || '@/assets/img/default-avatar.svg'
          }));

          this.suggestions = [...this.partnersSuggestions];
          this.isLoading = false;
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err.message,
          });
          this.isLoading = false;
        }
      },
      clearSuggestions() {
        this.suggestions = [];
      },
      openSuggestionsList() {
        this.isFocusedSearchbar = true;
      },
      closeSuggestionsList() {
        this.isFocusedSearchbar = false;
      }
    },
    watch: {
      /***
       * Watch the input value to set the autocomplete input value
       */
      searchbarInput: debounce(async function(newVal) {
        this.$store.dispatch('setAutocompleteInputValue', this.searchbarInput);

        if (newVal) {
          await this.getJobsSuggestions();
        } else {
          this.clearSuggestions();
        }

        this.updateLastIndexesOfCategories();
      }, 400),
    }
  };
</script>

<style scoped lang="scss">
  .searchbar-autocomplete-partners {
    flex-direction: column;
    width: 100%;

    &__bar {
      flex-direction: column;
      width: 100%;
      align-items: center;
      justify-content: space-around;
      min-height: 50px;
      position: relative;
      @include bp('tablet') {
        flex-direction: row;
        align-items: stretch;
      }
    }

    &__wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      align-items: center;
      @include bp('tablet') {
        height: auto;
      }
    }

    &__icon {
      font-size: $font-size-xxl;
      color: rgba($color-blue-san-juan, 0.4);
      position: absolute;
      left: 15px;
      transition: 0.3s;

      &--active {
        transform: rotate(90deg);
      }
    }

    &__button {
      margin-top: 10px;
      @include bp('tablet') {
        margin-top: 0;
        margin-left: 10px;
      }
    }

    &__autocomplete-list {
      position: absolute;
      z-index: 3;
      width: 100%;
    }

    &__input {
      @include bp('tablet') {
        min-width: 260px;
        margin-left: -30px;
      }
    }

    &__tag-container {
      margin-top: 10px;
      flex-wrap: wrap;
      margin-bottom: -10px;
    }

    &__tag {
      margin-right: 10px;
      max-height: 30px;
      margin-bottom: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
</style>
