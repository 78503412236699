<template>
  <div class="app-bar-item" :class="{ 'app-bar-item--active': active }">
    <i :class="`icon-${icon}`"></i>
    <div v-if="count" class="app-bar-item__count">
      {{ count }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-bar-item',
  props: {
    icon: {
      type: String,
    },
    count: {
      type: Number,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-bar-item {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  position: relative;

  &:hover, &--active {
    background: $color-blue-medium;
    cursor: pointer;
  }

  &__count {
    position: absolute;
    right: -4px;
    top: -4px;
    width: 20px;
    height: 20px;
    background: $color-red-mandy;
    border-radius: 100%;
    font-weight: 700;
    font-size: 11px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
  }
}
</style>
