export const websiteDomain = /(?!w)+([A-Za-z0-9|-])+(\.([a-z-A-Z|-])+){1,}/g;
export const isLinkedin = /^(https|http):\/\/([a-z]{2,3}\.)?linkedin\.com\/company\/.+\/?$/;
export const isWelcome = /^https:\/\/[a-z]{2,3}\.welcometothejungle\.com\/[a-z]{2}\/companies\/.*$/;
export const isPhoneNumber = /^\+?[0-9\" "]+$/;

export default {
  websiteDomain,
  isLinkedin,
  isWelcome,
  isPhoneNumber
}
