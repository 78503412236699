<template>
  <button
    :type="$attrs.type"
    :class="{
      'bc-dropdown-trigger--focus': isDropdownOpen,
      'bc-dropdown-trigger--disabled': disabled,
      'bc-dropdown-trigger--label': label,
      'bc-dropdown-trigger--success': text,
      'bc-dropdown-trigger--filter': isFilter,
    }"
    class="bc-dropdown-trigger is-justify-content-space-between is-align-items-center border-radius-s is-relative is-expanded is-full-width">
    <span
      :class="{
        'bc-dropdown-trigger__label--focus font-size-xs' : isDropdownOpen || text || !isLabelPlaceholder,
      }"
      :disabled="$attrs.disabled"
      class="bc-dropdown-trigger__label is-flex is-absolute font-size-m"
      v-if="label">
      <i
        :class="[
          `icon-${icon}`,
          { 'text-normal': isDropdownOpen }
        ]"
        class="bc-dropdown-trigger__label-icon is-flex is-align-items-center"
        v-if="icon">
      </i>
      {{ label }}
      <span
        class="bc-dropdown-trigger__label-required"
        v-if="$attrs.required">
        *
      </span>
    </span>
    <div
      v-if="text"
      class="bc-dropdown-trigger__text-container"
      :class="{ 'bc-dropdown-trigger__text-container--filter': isFilter }"
    >
      <img v-if="img" :src="img" />
      <i
        :class="[
          `icon-${icon}`,
        ]"
        class="bc-dropdown-trigger__label-icon is-flex is-align-items-center text-normal"
        v-if="icon && !label">
      </i>
      <span
        class="bc-dropdown-trigger__text"
        :class="{ 'bc-dropdown-trigger__text--filter': isFilter }">
        {{ text }}
      </span>
    </div>
    <span
      class="bc-dropdown-trigger__text text-placeholder"
      :class="{ 'bc-dropdown-trigger__text--filter': isFilter }"
      v-else>
      {{ $attrs.placeholder }}
      <span
        class="bc-dropdown-trigger__label-required"
        v-if="$attrs.required">
        *
      </span>
    </span>
    <i
      :class="{
        'icon-chevron-down': !isDropdownOpen,
        'icon-chevron-up': isDropdownOpen,
      }"
      class="bc-dropdown-trigger__chevron is-flex is-align-items-center"
      v-if="isArrow">
    </i>
  </button>
</template>

<script>
  export default {
    name: 'bc-dropdown-trigger',
    props: {
      text: {
        type: String,
        default: '',
      },
      isArrow: {
        type: Boolean,
        default: false,
      },
      isDropdownOpen: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: [Boolean, String],
        default: false,
      },
      icon: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      isLabelPlaceholder: {
        type: Boolean,
        default: true,
      },
      img: {
        type: [String, Object],
      },
      isFilter: {
        type: Boolean,
        default: false,
      }
    },
  };
</script>

<style lang=scss scoped>
  $dropdown-color: $color-secondary;

  .bc-dropdown-trigger {
    background: $color-white;
    padding: 10px 20px 10px 10px;
    height: 40px;
    color: $color-tertiary;
    border: 1px solid $color-blue-medium;
    gap: 10px;

    &--filter {
      height: 36px;
      padding: 10px 12px;
      align-items: center;
    }

    img {
      width: 20px;
      height: 20px;
    }

    &:hover {
      color: $color-grey-5;
      border-color: $color-blue-medium;

      .bc-dropdown-trigger__label {
        color: $color-grey-5;
      }
    }

    &--label {
      margin-top: 15px;
    }

    &--focus, &:active, &:focus {
      color: $color-primary;
      background-color: rgba($color-primary, 0.05);
      border: 1px solid rgba($color-primary, 0.00);

      &:hover {
        color: $color-primary;
      }
    }

    &--success, &--success:hover {
      color: $color-secondary-dark;
    }

    &--disabled, &--disabled:hover {
      cursor: default;
      color: $color-grey-4;
      border: 1px solid $color-grey-4;
      background-color: $color-grey-2;
      pointer-events: none;
    }

    &__text {
      max-width: calc(100% - 20px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &-container {
        width: 100%;
        overflow: hidden;
        align-items: center;
        gap: 10px;
        padding: 1px;
      }
    }

    .text-placeholder {
      color: $color-blue-heavy-dark;
    }

    &__chevron {
      font-size: 10px;
    }

    &__label {
      color: $color-blue-heavy-dark;
      transition: font-size 0.3s, transform 0.3s;
      left: 20px;
      top: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: calc(100% - 60px);

      &--hover {
        color: $color-blue-heavy-dark;
      }

      &--focus {
        color: $color-blue-heavy-dark !important;
        transform: translate(-10px, -32.5px);
      }
    }

    &__label-icon {
      margin-right: 5px;
    }

    &__label-required {
      color: $color-error;
      margin-left: 5px;
    }
  }
</style>
