import timeline from '@/api/timeline';

export const deleteNote = ({ id, actionId }) => {
  try {
    return timeline.deleteNote({ id, actionId });
  } catch (error) {
    if ((error || {}).response) {
      throw new Error(`API: ${error.response.data.message}`);
    }

    throw new Error(error);
  }
};

export default {
  deleteNote,
};
