import { defineStore } from 'pinia';
import { getParsingResumeHistories, listenParsingResumeHistories, setParsingResumeClosedAt } from '../../../firebase';

type ParsingStatus = 'PENDING' | 'SUCCESS' | 'ERROR';

interface FileStatus {
  status: ParsingStatus;
  error?: string;
  result?: unknown;
  startedAt?: Date;
  finishedAt?: Date;
}

interface ParsingResume {
  groupId: string;
  files: Record<string, FileStatus>;
  active: boolean;
  startedAt: Date;
  closedAt?: Date;
  metadata?: {
    source?: string;
    type?: string;
    size?: number;
  };
}

interface ParsingHistoryState {
  list: ParsingResume[];
  isLoading: boolean;
  error: string | null;
}

export const useParsingResumeHistoryStore = defineStore('parsingResumeHistories', {
  state: (): ParsingHistoryState => ({
    list: [],
    isLoading: false,
    error: null,
  }),

  getters: {
    hasActiveParsing: (state): boolean => 
      state.list.some(item => item.active),
    // isDone: (state): boolean => {
    //   const active = state.list.find(item => item.active);
    //   if (!active) return true;

    //   return Object.values(active.files).every(file => 
    //     file.status === 'SUCCESS' || file.status === 'ERROR'
    //   );
    // },

    // totalCount: (state): number => {
    //   const active = state.list.find(item => item.active);
    //   return active ? Object.keys(active.files).length : 0;
    // },
  },

  actions: {
    async getParsingResumeHistory(coachId: string) {
      try {
        this.isLoading = true;
        this.error = null;

        const list = await getParsingResumeHistories({ coachId });
        
        this.list = list ?? [];
      } catch (error) {
        console.error('Error fetching parsing histories:', error);
        this.error = error instanceof Error ? error.message : 'Erreur lors de la récupération de l\'historique';
      } finally {
        this.isLoading = false;
      }
    },

    async listenParsingResumeHistory(coachId: string) {
      try {
        this.error = null;
        return  listenParsingResumeHistories({ coachId }, ({ type, data }) => {
          if (type === 'modified' || type === 'added') {
            this.updateList(data);
          }
        });
      } catch (error) {
        console.error('Error listening to parsing histories:', error);
        this.error = error instanceof Error ? error.message : 'Erreur lors de l\'écoute de l\'historique';
      }
    },

    async setResumeClosedAt({ coachId, groupId }: { coachId: string, groupId: string }) {
      try {
        this.error = null;
        await setParsingResumeClosedAt({ coachId, groupId });
        const item = this.list.find(it => it.groupId === groupId);
        if (item) {
          item.active = false;
          item.closedAt = new Date();
        }
      } catch (error) {
        console.error('Error closing parsing history:', error);
        this.error = error instanceof Error ? error.message : 'Erreur lors de la fermeture de l\'historique';
      }
    },

    updateList(item: ParsingResume) {
      const index = this.list.findIndex(it => it.groupId === item.groupId);
      if (index !== -1) {
        this.list.splice(index, 1, item);
      } else {
        this.list.unshift(item);
      }
    },

    // reset() {
    //   this.list = [];
    //   this.isLoading = false;
    //   this.error = null;
    // },
  },
});
