<template>
  <div
    v-click-outside="closePanel"
    class="panel-coach-body is-align-items-center"
  >
    <bc-avatar
      v-if="profile._id"
      :src="getPicture"
      alt="coach picture"
      class="panel-coach-body__picture"
      size="l"
      :placeholder="userInitials"
      @error="setAlternativeImg"
    >
    </bc-avatar>
    <div class="panel-coach-body__container is-align-items-start">
      <div class="panel-coach-body__wrapper is-column">
        <p class="panel-coach-body__text panel-coach-body__text--primary" v-if="profile.enabled">
          {{ profile.firstName }} {{ profile.lastName }}
        </p>
        <p class="panel-coach-body__text panel-coach-body__text--secondary">
          {{ profile.job }}
        </p>
        <panel-caption-contacts
          :profile="profile"
          :contacts="contacts"
        ></panel-caption-contacts>
      </div>
      <bc-tag v-if="isSuperUser" class="panel-coach-body__tag">
        {{ role }}
      </bc-tag>
    </div>
  </div>
</template>

<script>
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import BcTag from '@/ui-kit/components/BcTag/BcTag';
  import PanelCaptionContacts from '@/components/Panel/PanelCaption/PanelCaptionContacts';
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import { getRoleNameFromApiValue } from '@/managers/coaches/formatters/coachesDeserializers';

  export default {
    name: 'panel-coach-body',
    components: {
      PanelCaptionContacts,
      BcTag,
      BcAvatar,
    },
    mixins: [PanelNavigation],
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      role() {
        return getRoleNameFromApiValue(this.profile.role);
      },
      contacts() {
        return [
          {
            icon: 'phone',
            field: 'phone',
            data: this.profile.phone,
            others: this.profile.phones,
            type: 'tooltip',
          },
          {
            icon: 'mobile',
            data: this.profile.mobile,
            type: 'tooltip',
          },
          {
            icon: 'mail',
            field: 'email',
            data: this.profile.email,
            others: this.profile.emails,
            type: 'tooltip',
          },
          {
            icon: 'linkedin',
            data: this.profile.linkedin ? this.profile.linkedin : '',
            type: 'link',
          },
        ];
      },
      userInitials() {
        return `${this.profile.firstName?.charAt(
          0
        )}${this.profile.lastName?.charAt(0)}`;
      },
      isSuperUser() {
        return this.$store.getters['user/isSuperUser'];
      },
        getPicture() {
        return this.profile.enabled
          ? this.profile.pictureUrl
          : !this.profile.enabled && this.profile._organization.superUser
          ? 'https://storage.googleapis.com/bluecoders-pictures/picture-09-04-2021T17:54:24-0.226904424646134920.221639346133786440.4314229673393155.jpg'
          : this.profile.pictureUrl;
      },
    },
    methods: {
      setAlternativeImg(event) {
        event.target.src = require('@/assets/img/default-avatar.svg');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-coach-body {
    flex-direction: column;
    justify-content: center;
    background-color: white;
    flex-shrink: 0;
    flex-grow: 1;
    border-bottom: 1px solid #e5ecf0;
    @include bp('tablet') {
      flex-direction: row;
      justify-content: flex-start;
      height: 110px;
      max-height: 110px;
      padding: 15px;
    }

    &__picture {
      margin-bottom: 20px;
      @include bp('tablet') {
        margin-bottom: 0;
        margin-left: 70px;
        margin-right: 20px;
      }
    }

    &__text {
      line-height: 1.5em;

      &--primary {
        color: $color-primary;
      }

      &--secondary {
        color: $color-secondary;
      }
    }

    &__tag {
      margin-left: 20px;
      color: $color-primary;
    }
  }
</style>
