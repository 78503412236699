<template>
  <div
    :class="{'modal-companies-item--checked': checked}"
    class="modal-companies-item card"
    @click="$emit('on-check', company)"
  >
    <div class="flex w-full items-start">
      <bc-checkbox
        :id="company._id"
        :value="checked"
        class="modal-companies-item__checkbox"
        @input="$emit('on-check', company)"
      />
      <div class="flex w-full flex-col">
        <div class="flex items-center gap-[10px]">
          <bc-avatar
            :src="company.logoUrl"
            class="modal-companies-item__avatar"
            @error="setAlternativeImg({event: $event, type: 'companies'})">
          </bc-avatar>
          <p
            :class="{'!text-red-500': isNameSame }"
            class="text-xs"
          >
            {{ company.name }}
          </p>
        </div>
        <div class="modal-companies-item__infos flex w-full flex-wrap gap-x-[10px] gap-y-[5px]">
          <div
            v-for="email in emails"
            :key="email"
            :class="{'!bg-red-50 !text-red-500': isEmailSame(email) }"
            class="flex items-center rounded-3xl bg-blue-100 px-[10px] py-[1px] text-xs font-medium"
          >
            <span class="icon-mail mr-1 size-[13px] text-[13px]"></span>
            {{ email }}
          </div>
          <div
            v-for="phone in phones"
            :key="phone"
            :class="{'!bg-red-50 !text-red-500': isPhoneSame(phone) }"
            class="flex items-center rounded-3xl bg-blue-100 px-[10px] py-[1px] text-xs font-medium"
          >
            <span class="icon-phone mr-1 size-[13px] text-[13px]"></span>
            {{ getFormattedPhoneNumber(phone) }}
          </div>
          <Tag
            v-for="location in locations"
            :key="location.place_id"
            :text="location.completeAddress ? location.completeAddress : location.fullAddress"
            type="location"
          />
          <div
            v-if="company.social?.website"
            :class="{'!bg-red-50 !text-red-500': isWebsiteSame }"
            class="flex items-center rounded-3xl bg-blue-100 px-[10px] py-[1px] text-xs font-medium"
          >
            <span class="icon-website mr-1 size-[13px] text-[13px]"></span>
            {{ company.social?.website }}
          </div>
          <div
            v-if="company.social?.linkedin"
            :class="{'!bg-red-50 !text-red-500': isLinkedinSame }"
            class="flex items-center rounded-3xl bg-blue-100 px-[10px] py-[1px] text-xs font-medium"
          >
            <img
              :src="linkedinImg"
              alt="icon"
              class="mr-1 size-[13px]"
            />
            {{ company.social?.linkedin }}
          </div>
          <div
            v-if="company.social?.welcomeToTheJungle"
            class="flex items-center rounded-3xl bg-blue-100 px-[10px] py-[1px] text-xs font-medium"
          >
            <img
              :src="welcomeToTheJungleImg"
              alt="icon"
              class="mr-1 size-[13px]"
            />
            {{ company.social?.welcomeToTheJungle }}
          </div>
          <Tag
            v-if="size"
            :text="size"
            type="size"
          />
          <Tag
            v-for="(customField, index) in customFields"
            v-show="company?.customFields?.[customField.key] !== null && company?.customFields?.[customField.key] !== ''"
            :key="`custom-field-${index}`"
            :icon="customField.icon"
            :text="
            customField.key === 'experience'
              ? `${company?.customFields?.[customField.key]} ${$tc(
                  'global.year',
                  company?.customFields?.[customField.key]
                )}`
              : Array.isArray(company?.customFields?.[customField.key])
                ? company?.customFields?.[customField.key]?.map(value => `${value} ${customField.unit ? customField.unit : ''}`)?.join(' - ')
                : company?.customFields?.[customField.key]
          "
            :title="customField.name"
            :type="customField.key"/>
        </div>
      </div>
      <bc-button
        class="flex shrink-0 !px-[10px] !py-[5px]"
        icon-left="external-link"
        size="extra-small"
        @click="handleClickProfile">
        {{ $t('companies.panel-hunt-process.see-the-profile') }}
      </bc-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed } from 'vue';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import { setAlternativeImg } from '@/mixins/imgFallback.js';
  import Tag from '@/components/Tag/Tag.vue';
  import { Location } from '@/domains/models/Location.js';
  import { formatPlaceDetailsToBaseLocation } from '@/formatters/locations.js';
  import linkedinImg from '@/assets/logo/linkedin.png';
  import welcomeToTheJungleImg from '@/assets/logo/wttj.png';
  import { useI18n } from '@/i18n/i18n';
  import { useStore } from '@/store';
  import { Company } from '@/domains/models/Company';
  import { getFormattedPhoneNumber} from '@/formatters/phoneNumbers';

  const props = withDefaults(defineProps<{
    company: Company
    checked: boolean
    draftCompany: Company
  }>(), {
    checked: false,
  });

  defineEmits(['on-check']);

  const i18n = useI18n();

  const companyUri = computed(() => {
    const baseUri = `${window.location.protocol}//${window.location.host}`;
    return `${baseUri}/partners/${props.company._id}?page=1&type=companies`;
  });

  const store = useStore();
  const user = store.state.user.profile;
  const customFields = props.company.customFields && user._organization.configuration?.company?.customFields?.filter(customField => customField.activated) || [];

  type LocationTag = Pick<Location, 'place_id' | 'completeAddress' | 'fullAddress'> & {
    text: string
  };
  const locations = computed<LocationTag[]>(() => {
    return props.company?.locations?.map(location => formatPlaceDetailsToBaseLocation(location, 'locality')) || [''];
  });
  const emails = computed(() => {
    return [...props.company?.email ? [props.company?.email] : [], ...(props.company?.emails || [])];
  });
  const draftEmails = computed(() => {
    return [...props.draftCompany?.email ? [props.draftCompany?.email] : [], ...(props.draftCompany?.emails || [])];
  });
  const phones = computed(() => {
    return [...props.company?.phone ? [props.company?.phone] : [], ...(props.company?.phones || [])];
  });
  const draftPhones = computed(() => {
    return [...props.draftCompany?.phone ? [props.draftCompany?.phone] : [], ...(props.draftCompany?.phones || [])];
  });
  const size = computed<string>(() => {
    return i18n.t(`panel-company-edit.size-options-${props.company.size || 0}`);
  });

  function handleClickProfile() {
    window.open(companyUri.value, '_blank');
  };

  const isWebsiteSame = computed(() => props.draftCompany?.social?.website && props.company?.social?.website === props.draftCompany?.social?.website);
  const isLinkedinSame = computed(() => props.draftCompany?.social?.linkedin && props.company?.social?.linkedin === props.draftCompany?.social?.linkedin);
  const isNameSame = computed(() => props.company?.name?.toLowerCase() === props.draftCompany?.name?.toLocaleLowerCase());

  function isEmailSame(email?: string) {
    return draftEmails.value.some(e => e === email);
  }
  function isPhoneSame(phone?: string) {
    return draftPhones.value.some(p => p === phone);
  }
</script>

<style lang="scss" scoped>
  .modal-companies-item {
    background: $color-neutre-4;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    &:hover {
      cursor: pointer;
    }

    &--checked {
      box-shadow: 0px 0px 0px 1px #3C80F7;
      backdrop-filter: blur(2px);
    }

    &__checkbox {
      margin-right: 10px;

      :deep() {
        input {
          border-radius: 100%;
        }
      }
    }

    &__avatar {
      width: 30px;
      height: 30px;
    }
  }
</style>
