<template>
  <div class="panel-job-notes">
    <panel-view-timeline :user-type="'jobs'"></panel-view-timeline>
  </div>
</template>

<script>
    import PanelViewTimeline from '@/components/Panel/PanelView/PanelViewTimeline';

    export default {
      components: { PanelViewTimeline },
      name: 'panel-job-notes'
    }
</script>

<style scoped lang="scss">
  .panel-job-notes {
    width: 100%;
  }

</style>
