<template>
  <views-tabs :tabs="tabs">
    <template #content>
      <slot></slot>
    </template>
  </views-tabs>
</template>

<script>
import ViewsTabs from '@/components/Views/ViewsTabs';

export default {
  name: 'statistics-tabs',
  components: {
    ViewsTabs,
  },
  computed: {
    profile() {
      return this.$store.state.user.profile;
    },
    tabs() {
      return [];
    },
  },
}
</script>
