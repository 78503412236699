<template>
  <div class="database-companies-modal-data-import is-column is-align-items-center">
    <p
      class="database-companies-modal-data-import__title is-uppercase is-primary font-weight-bold mb-4">
      {{ $t('candidates.import-my-customers') }}
    </p>
    <div class="database-companies-modal-data-import__wrapper is-full-width is-column">
      <p class="database-companies-modal-data-import__text is-secondary is-text-align-center">
        {{ $t('companies.candidates.for-all-questions') }}
      </p>
    </div>
    <bc-button
      class="database-companies-modal-data-import__upload-button"
      color="info"
      icon-left="upload-cloud"
      @click.native="triggerButton">
      {{ $t('comapanies.candidates.choose-a-file') }}
    </bc-button>
    <input
      ref="uploadButton"
      class="hidden-xs hidden-md hidden-sm"
      type="file"
      @change="uploadFile($event)"/>
  </div>
</template>

<script>
  import BcButton from '@/ui-kit/components/BcButton';
  import upload from '@/api/upload';
  import { mapState } from 'vuex';

  export default {
    name: 'database-companies-modal-data-import-button',
    components: {
      BcButton,
    },
    computed: {
      ...mapState({
        profile: store => store.user.profile,
      }),
    },
    methods: {
      dialPhoneNumber() {
        document.querySelector('.lc-15a7cvo').click();
      },
      triggerButton() {
        this.$refs.uploadButton.click();
      },
      async uploadFile(e) {
        if (e.target.files[0]) {
          try {
            this.file = e.target.files[0];

            const dataToSend = new FormData();

            dataToSend.append('file', this.file);
            dataToSend.append('uploadType', 'onboarding_data');

            await upload.upload({
              id: this.profile._id,
              file: dataToSend,
            });
            this.$toast.show({
                       type: 'success',
              title:  this.$i18n.t('toast.congratulations'),
              message: this.$i18n.t('toast.file-received-for-bdd-integration'),
            });
            if (!this.profile.isDataImported) {
              await this.editProfile({ isDataImported: true });
            }
          } catch (error) {
            this.$toast.show({
              message: this.$t('toast.error-occured'),
              type: 'error',
              icon: 'cross',
            });
          }
        }
      },
    },
  };
</script>


<style lang="scss" scoped>
  .database-companies-modal-data-import {
    padding: 30px;
    width: 100%;
    max-width: 400px;

    @include bp('tablet') {
      min-width: 400px;
    }

    &__separator {
      width: 100%;
      border: 0;
      border-top: 1px solid rgba($color-secondary, 0.2);
    }

    &__upload-button {
      margin-top: 20px;
    }
  }
</style>
