import { http } from '@/api/index';

export const getOrganizationRessourceCount = ({ organizationId, candidate, customJob, company, contact, applications }) => {
  return http.get(`/metadata/organization/${organizationId}/ressource-count`, {
    params: {
      candidate,
      customJob,
      company,
      contact,
      applications,
    }
  });
}

export const getCoachRessourceCount = ({ coachId }) => {
  return http.get(`/metadata/coach/${coachId}/ressource-count`);
}
