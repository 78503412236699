<script lang="ts" setup>
  import CardList from '@/components/CardList/CardList.vue';
  import Card from '@/components/Card/Card.vue';
  import { useStore } from '@/store';
  import CampaignsCard from '@/components/Campaigns/CampaignsCard.vue';
  import { useRouter } from 'vue-router/composables';
  import { computed } from 'vue';

  const store = useStore();
  const router = useRouter();

  defineProps<{
    cards: CampaignCard[];
  }>();

  const selectedCards = computed(() => store.state.card.selectedCards ?? []);

  type CampaignCard = {
    _id: string;
  }

  function setSelectedCards(value: boolean, card: CampaignCard) {
    const selectedCardsIds: string[] = selectedCards.value.map((card: CampaignCard) => card._id);

    if (!value && selectedCardsIds.includes(card._id)) {
      store.dispatch('setSelectedCards', selectedCards.value.filter((c: CampaignCard) => c._id !== card._id));
    } else if (value && !selectedCardsIds.includes(card._id)) {
      store.dispatch('setSelectedCards', [...selectedCards.value, card]);
    }
  }

  function getIsSelectedStatus(id: string) {
    return selectedCards.value.map((card: CampaignCard) => card._id).includes(id);
  }

  function goToProspectsList(id: string) {
    router.push({
      name: 'Prospects',
      query: {
        campaignId: id,
      },
    });
  }
</script>

<template>
  <CardList
    ref="cardList"
    :cards="cards"
    :is-loaded="true"
    class="pagination__card-list">
    <template #card="{ card }">
      <Card
        :card-content="card"
        @click.native="goToProspectsList(card._id)">
        <CampaignsCard
          :card-content="card"
          :is-selected="getIsSelectedStatus(card._id)"
          context="search-dbcampaigns"
          @checked="setSelectedCards($event, card)">
        </CampaignsCard>
      </Card>
    </template>
  </CardList>
</template>
