import { http } from '@/api/index';
import moment from 'moment/min/moment-with-locales';

export const updateProcessState = ({ id, step, params }) => {
  return http.put(`/processes/${id}/state`, {
    step,
    ...params,
  });
};

export const postProcessFailed = id => {
  return http.put(`/processes/${id}/failed`);
};

export const postProcessUnfailed = id => {
  return http.put(`/processes/${id}/unfailed`);
};

export const postProcesses = ({
                                coderId,
                                jobId,
                                huntId,
                                companyId,
                                status,
                                introductionSalary,
                              }) => {
  return http.post('/processes', { coderId, jobId, huntId, companyId, status, introductionSalary });
};

export const getProcessTimeline = ({ id, page }) => {
  return http.get(`/processes/${id}/timeline`, {
    params: { page },
  });
};

export const getProcessProfile = id => {
  return http.get(`/processes/${id}`);
};

export const getResourceProcesses = ({ resource, id, steps, from, to, coachIds, jobIds }) => {
  return http.get(`/${resource}/${id}/processes`, {
    params: { resource, id, steps, from, to, coachIds, jobIds },
  });
};

export const updateProcess = ({ id, process }) => {
  return http.patch(`/processes/${id}`, process);
};

export const updateProcessPayment = ({ id }) => {
  return http.put(`/processes/${id}/payed`);
};


export const deleteProcess = id => {
  return http.delete(`/processes/${id}`);
};

export const getProcesses = ({ from, to, coachIds, teamsIds }) => {
  return http.get('/processes', {
    params: {
      from,
      to: moment(to).add(1, 'd').format('YYYY-MM-DD'),
      ...coachIds && { coachIds },
      ...teamsIds && { teamsIds },
    },
  });
};

export const getLinkableProcesses = (processId) => {
  return http.get(`/processes/${processId}/linkableProcesses`);
};

export const updateFormDeal = (id, updatedFormDeal) => {
  return http.patch(`/processes/${id}/deal`, updatedFormDeal);
};

export const sendFormDeal = (id) => {
  return http.put(`/processes/${id}/deal`);
};

export default {
  updateProcessState,
  postProcessFailed,
  postProcessUnfailed,
  postProcesses,
  getProcessTimeline,
  getResourceProcesses,
  getProcessProfile,
  updateProcess,
  deleteProcess,
  getProcesses,
  getLinkableProcesses,
  updateFormDeal,
  sendFormDeal,
  updateProcessPayment,
};
