import { http } from '@/api/index';

export const getDashboardReminders = ({ start, end }) => {
  return http.get('/dashboard/reminders', { params: { start, end } });
};

export const getDashboardDealsBirthdays = ({ start, end }) => {
  return http.get('/dashboard/deals-birthdays', { params: { start, end } });
};

export const getDashboardStarts = ({ start, end }) => {
  return http.get('/dashboard/starts', { params: { start, end } });
};

export const getDashboardCandidatesProcesses = ({ start, end, coachId }) => {
  return http.get('/dashboard/kpis/process/me', { params: { start, end, coachId } });
};

export const getTopPerformersCandidatesProcesses = ({ start, end }) => {
  return http.get('/dashboard/kpis/process/top-performers', { params: { start, end } });
};

export const getDashboardNewNotesSigned = ({ start, end, coachId }) => {
  return http.get('dashboard/kpis/clients/notes-signed', { params: { start, end, coachId }});
}

export const getDashboardNewNotesUnsigned = ({ start, end, coachId }) => {
  return http.get('dashboard/kpis/clients/notes-unsigned', { params: { start, end, coachId }});
}

export const getDashboardNewSigned = ({ start, end, coachId }) => {
  return http.get('dashboard/kpis/clients/signed', { params: { start, end, coachId }});
}

export const getDashboardNewContacted = ({ start, end, coachId }) => {
  return http.get('dashboard/kpis/candidates/contacted', { params: { start, end, coachId }});
}

export const getDashboardNewQualifications = ({ start, end, coachId }) => {
  return http.get('dashboard/kpis/candidates/qualified', { params: { start, end, coachId }});
}

export const getDashboardCandidatesMultiposting = ({ start, end, coachId } = {}) => {
  return http.get('dashboard/kpis/candidates/multiposting', { params: { start, end, coachId }});
}

export const getDashboardNewOpportunities = ({ start, end, coachId }) => {
  return http.get('dashboard/kpis/opportunities/new', { params: { start, end, coachId }});
}

export default {
  getDashboardReminders,
  getDashboardDealsBirthdays,
  getDashboardStarts,

  getDashboardCandidatesProcesses,
  getTopPerformersCandidatesProcesses,

  getDashboardNewNotesSigned,
  getDashboardNewNotesUnsigned,
  getDashboardNewSigned,

  getDashboardNewContacted,
  getDashboardNewQualifications,

  getDashboardNewOpportunities
};
