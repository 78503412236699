<template>
  <div class="views flex-col w-full items-center">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'views',
  };
</script>

<style lang="scss" scoped>
  .views {
    @include page_grid();
    padding-top: var(--app-bar-height);
  }
</style>
