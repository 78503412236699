<template>
  <div class="prospection-pipe-filters">
    <div class="prospection-pipe-filters__wrapper">
      <bc-dropdown
        :options="coachesOptions"
        class="prospection-pipe-filters__dropdown">
        <template #label="{ isDropdownOpen }">
          <bc-dropdown-trigger
            :is-arrow="true"
            :is-dropdown-open="isDropdownOpen"
            :text="selectedCoaches"
            :is-filter="true"
            icon="user">
          </bc-dropdown-trigger>
        </template>
        <template #option="{ activeIndex, index, option }">
          <bc-dropdown-item
            :is-focus="activeIndex === index"
            :text="option.name"
            :title="option.name"
            class="prospection-pipe-filters__dropdown-item"
            @click.native="setSelectedCoaches(option)">
          </bc-dropdown-item>
        </template>
      </bc-dropdown>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import BcDropdown from '@/ui-kit/components/BcDropdown';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
  import { getFilterablePoolCoach } from '@/macros/prospectionPipe/dropdowns';

  export default {
    name: 'prospection-pipe-filters',
    components: { BcDropdownTrigger, BcDropdownItem, BcDropdown },
    computed: {
      ...mapState({
        coaches: state => state.coaches.coaches,
        profile: state => state.user.profile,
      }),
      isSuperUser() {
        return this.$store.getters['user/isSuperUser'];
      },
      isIndividualFilterSelected() {
        return this.$route.query['selected-pipe'] === 'personal-pipe';
      },
      defaultAccountManager() {
        return this.coaches.find(coach => coach.lastName === 'LA') || this.coachesOptions[0];
      },
      coachesOptions() {
        const coaches = this.coaches
        .filter(coach => getFilterablePoolCoach(coach))
        .map(coach => ({
          name: `${coach.firstName} ${coach.lastName}`,
          query: coach._id,
        }));

        return [
          {
            name: this.$i18n.t('process.everyone'),
            type: 'global-pipe',
            query: null,
          },
          ...coaches.sort((a, b) => a.name.localeCompare(b.name)),
        ];
      },
      selectedCoach() {
        return this.coaches.find(coach => coach._id === this.$route.query['coach-ids']);
      },
      selectedCoaches() {
        return this.selectedCoach ? `${this.selectedCoach.firstName} ${this.selectedCoach.lastName}` : this.$i18n.t('process.everyone');
      },
    },
    methods: {
      setSelectedCoaches(coach) {
        if (coach.query === 'all') {
          this.$router.push({
            query: {
              ...this.$route.query,
              'coach-ids': undefined,
            },
          });
        } else {
          this.$router.push({
            query: {
              ...this.$route.query,
              'coach-ids': coach.query,
            },
          });
        }
      },
    },
  };
</script>

<style lang=scss scoped>
  .prospection-pipe-filters {
    justify-content: space-between;

    &__dropdown {
      min-width: 180px;
      margin-right: 10px;

      &-item {
        width: 180px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    &__date-picker {
      min-width: 250px;

      & :deep() {
        input {
          height: 100%;
          outline: none;
          box-shadow: none;
          border: 1px solid rgba($color-blue-dodger, 0.3);
          text-align: center;
          color: $color-blue-dodger;
          font-size: $font-size-s;
          padding: 5px 10px;
          transition: 0.3s;

          &:focus {
            border-color: $color-blue-dodger;
          }
        }

        .vc-grid-cell {
          display: block;
        }
      }
    }
  }
</style>
