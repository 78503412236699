<template>
  <div class="modal-templates-note">
    <ModalTemplatesLinkedinHeader
      :can-delete="!!params.linkedinTemplate"
      :can-duplicate="false"
      :disabled="params.disabled"
      :title="params.linkedinTemplate ? $t('email.template-edition') : $t('email.template-creation')"
      @on-close="onClose"
      @on-delete="onDeleteTemplate"
    />
    <div class="modal-templates-note__content flex flex-grow">
      <LinkedinTemplateForm
        :disabled="params.disabled"
        :template="params.linkedinTemplate"
        @on-close="onClose"
      />
    </div>
    <ModalDeleteItem
      :is-open="isModalOpen"
      :title="$t('templates.template-delete-confirm')"
      custom-icons="❌📝"
      @close="onCloseModal"
      @on-confirm="onConfirmDelete"
    >
      <template #content>
        <p>{{ $t('templates.template-delete-confirm-message') }}</p>
      </template>
    </ModalDeleteItem>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import ModalDeleteItem from '@/components/Modal/ModalDeleteItem';
  import ModalTemplatesLinkedinHeader
    from '@/components/Modal/ModalTemplates/ModalTemplatesLinkedin/ModalTemplatesLinkedinHeader.vue';
  import LinkedinTemplateForm
    from '@/components/Linkedin/LinkedinTemplateForm.vue';
  import { useLinkedinTemplatesStore } from '@/store/pinia/linkedinTemplates.ts';
  import { mapActions } from 'pinia';

  export default {
    components: {
      LinkedinTemplateForm,
      ModalTemplatesLinkedinHeader,
      ModalDeleteItem,
    },
    data() {
      return {
        isModalOpen: false,
        confirmDelete: false,
      };
    },
    computed: {
      ...mapState('app', ['params']),
    },
    methods: {
      ...mapActions(useLinkedinTemplatesStore, ['deleteTemplate']),
      onClose() {
        this.$emit('close-modal');
      },
      canUserPerformAction() {
        const templateId = this.params?.linkedinTemplate?._id;
        if (!templateId) {
          throw new Error('invalid template id');
        }
      },
      async onDeleteTemplate() {
        if (!this.confirmDelete) {
          this.isModalOpen = true;
          return;
        }

        try {
          const templateId = this.params?.linkedinTemplate?._id;
          this.canUserPerformAction();

          await this.deleteTemplate(templateId);

          this.$toast.show({
            type: 'success',
            message: this.$t('templates.template-delete-success'),
          });
          this.onClose();
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err?.response?.data?.message || err.message,
          });
        }
      },
      onCloseModal() {
        this.isModalOpen = false;
      },
      async onConfirmDelete() {
        this.confirmDelete = true;
        await this.onDeleteTemplate();
        this.confirmDelete = false;
        this.isModalOpen = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-templates-note {
    position: relative;
    flex-direction: column;
    width: 640px;
    overflow: auto;

    &:deep(.modal-delete-item) {
      max-width: 540px;
    }
  }
</style>
