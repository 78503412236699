<script lang="ts" setup>
  import CardList from '@/components/CardList/CardList.vue';
  import Card from '@/components/Card/Card.vue';
  import { useStore } from '@/store';
  import { ref } from 'vue';
  import IncomingActionsCard from '@/components/IncomingActions/IncomingActionsCard.vue';

  const store = useStore();

  // const cards = store.state.card.cards;
  const selectedCards = store.state.card.selectedCards;
  const cards = ref([
    {
      '_id': 'bcdedaa6268ed424eab4a9e5',
      '_coach': '661fd046268ed424eab4a9e2',
      'name': 'Ma première campagne',
      '_campaignTypes': ['661fd046268ed424eab4a9e2'],
      'campaignTypes': [{
        '_id': '661fd046268ed424eab4a9e2',
        'slug': 'linkedin-recruiter',
        'name': 'LINKEDIN_RECRUITER',
        '_organization': '660bc7cfa4bae63c42cf7872',
      }],
      'campaignState': 'STOPPED',
      'scheduledAt': '2024-12-29T13:36:06.144Z',
      'prospectsSources': [
        '456fd046268ed424eab4a9e5',
        '123fd046268ed424eab4a9e5',
      ],
      'campaignActions': [
        {
          'type': 'LINKEDIN_MESSAGE',
          '_messageTemplate': 'm77d046268ed424eab4a9e5',
          'nextValidation': {
            'operator': 'OR',
            'conditions': [
              {
                'type': 'DELAY',
                'numberOfDays': 3,
              },
              // {
              //   'type': 'MESSAGE_RECEIVED',
              // },
            ],
          },
        },
        {
          'type': 'LINKEDIN_PROFILE_VISIT',
          'enrichProfile': true,
          'noticeVisit': true,
          'nextValidation': {
            'operator': 'OR',
            'conditions': [
              {
                'type': 'DELAY',
                'numberOfDays': 3,
              },
              // {
              //   'type': 'MESSAGE_RECEIVED',
              // },
            ],
          },
        },
      ],
      'isTemplate': false,
      'createdAt': '2024-12-23T13:36:06.144Z',
      'updatedAt': '2024-12-24T13:36:06.144Z',
    },
  ]);

  type CampaignCard = {
    _id: string;
  }

  function setSelectedCards(value: boolean, card: CampaignCard) {
    const selectedCardsIds: string[] = selectedCards.map((card: CampaignCard) => card._id);

    if (!value && selectedCardsIds.includes(card._id)) {
      store.dispatch('setSelectedCards', selectedCards.filter((c: CampaignCard) => c._id !== card._id));
    } else if (value && !selectedCardsIds.includes(card._id)) {
      store.dispatch('setSelectedCards', [...selectedCards, card]);
    }
  }

  function getIsSelectedStatus(id: string) {
    return selectedCards.map((card: CampaignCard) => card._id).includes(id);
  }
</script>

<template>
  <div>
    <card-list
      ref="cardList"
      :cards="cards"
      :is-loaded="true"
      class="pagination__card-list">
      <template #card="{ card }">
        <Card
          :card-content="card">
          <IncomingActionsCard
            :card-content="card"
            :is-selected="getIsSelectedStatus(card._id)"
            context="search-dbincomingactions"
            @checked="setSelectedCards($event, card)">
          </IncomingActionsCard>
        </Card>
      </template>
    </card-list>
  </div>
</template>
