<template>
  <div class="timeline-editor-process is-column is-full-width is-relative border-radius-m">
    <button
      class="is-absolute icon-cross font-size-s timeline-editor-process__cross"
      data-button-close
      @click="closeTimelineEditor">
    </button>
    <bc-rich-text-editor-bubble
      :key="draft._id"
      ref="textarea"
      v-model="content"
      v-autosize="content"
      :is-editable="true"
      :mention-suggestions="mentionCoaches"
      :placeholder="$t('companies.panel-view-timeline.start-writing')"
      class="timeline-editor-process__textarea is-full-width font-size-s border-radius-m"
      @blur="setIsTyping()"
      @focus="setIsTyping(true)"
      @on-mention-select="onMentionSelect"
      @show-suggestions="showSuggestions = $event"
    >
    </bc-rich-text-editor-bubble>
    <div class="is-absolute timeline-editor-process__wrapper">
      <p
        :class="{ 'timeline-editor-process__save--disabled': !isSaved }"
        class="timeline-editor-process__save">
        {{ $t('companies.panel-view-timeline.note-saved') }}
        <i class="timeline-editor-process__save-icon icon-check"></i>
      </p>
      <span
        v-tooltip="tooltipContent"
        class="timeline-editor-process__button">
        <bc-button
          :disabled="!isValid"
          color="success"
          data-button-submit
          @click="sendNote">
          {{ $t('generics.validate') }}
        </bc-button>
      </span>
    </div>
  </div>
</template>

<script>
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import notesMacros from '@/common-old/macros/notes';
  import BcRichTextEditorBubble from '@/ui-kit/components/BcRichTextEditor/BcRichTextEditorBubble';
  import { noteController } from '@/managers/notes/controller';
  //import TimelineLink from '@/components/Timeline/TimelineLink';
  import TimelineEditorMixin from '@/mixins/Timeline/timelineEditor';
  import { mapGetters } from 'vuex';

  export default {
    name: 'timeline-editor-process',
    components: {
      //TimelineLink,
      BcRichTextEditorBubble,
      BcButton,
    },
    mixins: [TimelineEditorMixin],
    props: {
      currentFormattedProcess: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        content: '',
        linkedProcesses: [],
        draft: {
          content: '',
        },
        selectedMentions: [],
        showSuggestions: false,
      };
    },
    computed: {
      ...mapGetters({
        mentionCoaches: 'mentionCoaches',
      }),
      isValidOther() {
        return !!this.isNotEmptyMessage;
      },
      isValid() {
        return this.isValidOther && this.draft._id;
      },
      isNotEmptyMessage() {
        return this.content.replace(/<[^>]*>?/gm, '');
      },
      tooltipOptions() {
        return [
          {
            error: this.type === notesMacros.type.other.name && !this.isValidOther,
            text: this.$i18n.t('companies.panel-view-timeline.please-add-content-to-the-note'),
          },
        ];
      },
      tooltipContent() {
        return (this.tooltipOptions.find(option => option.error) || {}).text || '';
      },
    },
    methods: {
      onMentionSelect(mention) {
        this.selectedMentions = [...this.selectedMentions, mention];
      },
      closeTimelineEditor() {
        this.$emit('close');
      },
      syncWithDomMentions() {
        let domMentions = [];
        if (this.$refs['textarea']?.$el) {
          const mentionsNodes = Array.from(this.$refs['textarea'].$el.querySelectorAll('.mention'));
          domMentions = mentionsNodes.map(node => node.dataset.mentionId);
          const selectedCoaches = this.mentionCoaches.filter(coach => domMentions.includes(coach.id));
          this.selectedMentions = selectedCoaches;
        }
      },
      async sendNote() {
        this.syncWithDomMentions();

        const note = {
          type: notesMacros.type.other.name,
          ...this.content && { content: this.content },
        };

        try {
          const response = await noteController.createNote({
            userType: 'processcustom',
            id: this.draft._id,
            note,
            mentions: this.selectedMentions.map(mention => mention.id),
          });

          if (this.linkedProcesses.length > 0) {
            this.linkProcessAfterSendingNote(response?._note?._id);
          }

          if (this.selectedMentions.length) {
            this.$toast.show({
              type: 'success',
              message: this.$t('notifications.send-with-mentions', {
                mentions: this.selectedMentions.map(mention => mention.name).join(', '),
              }),
            });
          }

          this.$emit('note-saved', response);

          this.closeTimelineEditor();
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: error.message || error.response.data,
          });
        }
      },
      async linkProcessAfterSendingNote(noteId) {
        const _linkedProcesses = this.linkedProcesses
        .filter(process => process.id !== this.$route.params.id)
        .map(process => process.id);

        this.linkedProcesses = [];

        this.$emit('link-process', {
          noteId,
          processIds: _linkedProcesses,
        });
      },
      linkProcess($event) {
        this.linkedProcesses.push($event);
      },
      unlinkProcess($event) {
        this.linkedProcesses = this.linkedProcesses.filter(process => process.id !== $event);
      },
    },
    watch: {
      showSuggestions(show) {
        this.disableDraftNote = show;
      },
    },
  };
</script>

<style lang=scss scoped>
  .timeline-editor-process {
    background: $color-white;
    border: 2px solid $color-primary;

    &__cross {
      right: 30px;
      top: 15px;
    }

    &__textarea {
      padding: 20px 30px;
      resize: none;
      min-height: 100px;
      color: $color-secondary-dark;
      font-family: $font-family;
    }

    &__wrapper {
      align-items: center;
      justify-content: center;
      right: 10px;
      bottom: 10px;
    }

    &__save {
      display: flex;
      align-items: center;
      color: $color-success;
      padding: 5px 10px;
      border-radius: $border-radius-s;
      background-color: rgba($color-success, 0.08);
      transition: 0.2s ease-out;

      &-icon {
        height: 16px;
        margin-left: 5px;
      }

      &--disabled {
        opacity: 0;
      }
    }

    &__link {
      margin: 0 10px;
    }

    &__button {
      @include bp("tablet") {
        margin-left: auto;
        right: 20px;
        bottom: 15px;
      }
    }
  }
</style>
