<template>
  <MvFilter :label="$t('companies.owner')" icon="user" :count="selectedCoachesIds.length" align="left">
    <FilterContent class="min-w-[340px]" :label="$t('companies.owner')" icon="user">
      <MvSelect icon="user" :placeholder="$t('companies.select-an-owner')" :items="coachOptions" :isAbsolute="true"
        :with-avatar="true" @select="onSelectCoach" />
      <div v-if="selectedCoachesIds.length" class="mt-2 flex flex-wrap gap-2">
        <Tag v-for="coach in selectedCoaches" :key="coach.value" :text="coach.label" :canClose="true"
          :avatar="coach.avatar || DEFAULT_COACH_AVATAR" @error="onAvatarError" @click="onRemoveCoach(coach._id)" />
      </div>
    </FilterContent>
  </MvFilter>
</template>

<script setup lang="ts">
import MvFilter from '@/ui-kit/components/MvFilter/MvFilter.vue';
import FilterContent from '@/components/Filter/FilterContent.vue';
import MvSelect from '@/ui-kit/components/MvSelect/MvSelect.vue';
import Tag from '@/components/Tag/Tag.vue';
import { ref, computed, watch } from 'vue';
import { useStore } from '@/store';
import { useI18n } from '@/i18n/i18n';
import { useRoute, useRouter } from 'vue-router/composables';
import { getFilterablePoolCoach } from '@/macros/prospectionPipe/dropdowns';
import { getSearchParams } from '@/utils/typeConversion';

type Coach = {
  _id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  pictureUrl: string;
  enabled: boolean;
};

type CoachOption = {
  _id: string;
  label: string;
  value: string;
  enabled?: boolean;
  avatar?: string;
  selected?: boolean;
};

const DEFAULT_COACH_AVATAR = require('@/assets/img/default-avatar.svg');

const PARAM_COACH_IDS = 'ownerIds';

const store = useStore();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();


function handleArrayParamChange(param: string, newArray: string[]) {
    router.replace({ query: { ...route.query, [param]: newArray.length ? newArray.join(',') : undefined } });
}

const selectedCoachesIds = computed<string[]>({
  get: () => getSearchParams(route.query[PARAM_COACH_IDS]),
  set: (newArray: string[]) => handleArrayParamChange(PARAM_COACH_IDS, newArray),
});
const selectedCoaches = computed<CoachOption[]>(() => coachOptions.value.filter(coach => selectedCoachesIds.value.includes(coach._id)));

const coaches = computed<Coach[]>(() => store.state.coaches?.coaches);

function coachFullName(coach: Coach): string {
  let fullName = coach.name || `${coach.firstName} ${coach.lastName}`;

  return coach.enabled
    ? fullName
    : `${coach.lastName === 'Désactivé' ? coach.email : fullName} - ${t('generics.disabled')}`;
}

const coachOptions = computed<CoachOption[]>(() => {
  const filteredCoaches = coaches.value
    .filter(coach => getFilterablePoolCoach(coach, false))
    .map(coach => ({
      _id: coach._id,
      label: coachFullName(coach),
      value: coach._id,
      enabled: false,
      avatar: coach.pictureUrl || DEFAULT_COACH_AVATAR,
      selected: selectedCoachesIds.value.includes(coach._id),
    }));

  return [
    ...filteredCoaches.sort((a, b) => a.label.localeCompare(b.label) && b.enabled - a.enabled),
  ];
});

const onSelectCoach = (coachId: string) => {
  const foundCoach = coachOptions.value.find(coach => coach._id === coachId)
  if (!foundCoach) return;
  if (selectedCoachesIds.value.includes(foundCoach._id)) {
    selectedCoachesIds.value = selectedCoachesIds.value.filter(coachId => coachId !== foundCoach._id);
  } else {
    selectedCoachesIds.value = [...selectedCoachesIds.value, foundCoach._id];
  }
}

const onRemoveCoach = (coachIdToRemove: string) => {
  selectedCoachesIds.value = selectedCoachesIds.value.filter(coachId => coachId !== coachIdToRemove);
}

const onAvatarError = (event: any) => {
  event.target.src = DEFAULT_COACH_AVATAR;
}
</script>
