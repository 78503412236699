<template>
  <hr
    :style="dividerStyle"
    class="bc-divider">
</template>

<script>
  import colors_helpers from '@/legacy/ui-kit/utils/colors_helpers';

  export default {
    name: 'legacy-bc-divider',
    props: {
      bcDividerColor: {
        type: String,
        default: 'blue-cello'
      },
      bcDividerColorOpacity: {
        type: Number,
        default: 1
      },
      bcDividerMaxWidth: {
        type: String,
        default: ''
      },
      bcDividerHeight: {
        type: String,
        default: '1px'
      },
      bcDividerWidth: {
        type: String,
        default: '100%'
      }
      // size: {
      //   type: String,
      //   required: false,
      //   default: '1px',
      // },
      // width: {
      //   type: String,
      //   required: false,
      //   default: '100%'
      // },
      // maxWidth: {
      //   type: String,
      //   required: false,
      //   default: '100%'
      // },
      // color: {
      //   type: String,
      //   required: true
      // },
      // colorOpacity: {
      //   type: String,
      //   required: false,
      //   default: '1'
      // }
    },
    computed: {
      dividerStyle() {
        return {
          borderTop: `${this.bcDividerHeight} solid ${colors_helpers.$_checkValidColor(this.bcDividerColor, this.bcDividerColorOpacity)}`,
          width: this.bcDividerWidth
        };
      }
    }
  };
</script>

<style lang="scss">
  .bc-divider {
    margin-top: 0;
    margin-bottom: 0;
    border-left: 0;
    border-bottom: 0;
    border-right: 0;
  }
</style>
