<template>
  <div class="searchbar-coders-filters">

    <!-- <filter-coders-job-title /> -->
    <!-- <filter-companies /> -->
    <filter-coders-location/>
    <filter-professions/>
    <filter-coders-job-title></filter-coders-job-title>
  </div>
</template>

<script>
  import FilterProfessions from '@/components/Filter/FilterProfessions';
  import FilterCodersLocation from '@/components/Filter/Coders/FilterCodersLocation';
  import FilterCompanies from '@/components/Filter/FilterCompanies';
  import FilterCodersJobTitle from '@/components/Filter/Coders/FilterCodersJobTitle.vue';

  export default {
    name: 'searchbar-coders-filters',
    components: {
      FilterProfessions,
      FilterCodersLocation,
      FilterCompanies,
      FilterCodersJobTitle,
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-coders-filters {
    gap: 10px;
  }
</style>
