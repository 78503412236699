<script lang="ts" setup>

  import CustomJobPublishWarning from '@/components/CustomJobs/CustomJobPublishWarning.vue';
  import BcToggle from '@/ui-kit/components/BcToggle/BcToggle.vue';
  import { useStore } from '@/store';
  import { computed, defineEmits, defineProps, nextTick, ref, watch } from 'vue';
  import { Opportunity } from '@/domains/models/Opportunity';
  import { getCareerUri } from '@/helpers/url/career_url';
  import { useI18n } from '@/i18n/i18n';
  import { updateCustomJob } from '@/api/custom-jobs';
  import { Company } from '@/domains/models/Company';
  import JobboardPublishList from '@/components/Jobboard/JobboardPublishList.vue';
  import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';

  const props = defineProps<{
    opportunity: Opportunity;
    isCreating?: boolean;
  }>();
  const emits = defineEmits<{
    (e: 'update-opportunity', value: Company): void,
  }>();

  const store = useStore();
  const i18n = useI18n();

  // template refs
  const publishToggle = ref();

  const jobboardModal = ref();
  const isModalConfirmOpen = ref();

  const multidiffusionAvailable = ref(true);
  const isConfirmAutopublish = ref(false);
  const isConfirmDepublications = ref(false);

  const configurationJobMultiposting = computed(() => {
    return store.getters['user/configurationJobMultiposting'];
  });

  const autopublishJobboard = computed(() => {
    const jobMultiposting = props.opportunity.jobMultiposting || {};
    let jobboard = Object.keys(jobMultiposting)
      .find(jobboard => hasAutopublish(jobboard) && jobMultiposting[jobboard]?.status !== 'published');
    if (!jobboard && configurationJobMultiposting.value?.hellowork?.params?.autoPublish) {
      return 'hellowork';
    }
    return jobboard;
  });

  const configurationFeatures = store.getters['user/configurationFeatures'];
  const organization = store.getters['user/organization'];
  const isMultidiffusionFeatureActive = computed(() => configurationFeatures?.career);
  const isCareerMultidiffusionActive = computed(() => organization?.configuration?.career?.activated || false);
  const isOpportunityActive = computed(() => props.opportunity?.active || false);
  const hasMultidiffusion = computed(() => configurationFeatures.multidiffusion);

  const checkFeatureActive = () => {
    if (!isMultidiffusionFeatureActive.value) {
      // i18n.setModal(Modals['modal-feature-career']);
      return false;
    }
    return true;
  };

  const isCopiedUrl = ref(false);
  const setIsCopiedUrl = () => {
    isCopiedUrl.value = true;
    setTimeout(() => {
      isCopiedUrl.value = false;
    }, 2300);
  };

  const careerWebsiteUrl = computed(() => {
    return getCareerUri(organization.name, props.opportunity?._id);
  });
  const handleTooltip = computed(() => {
    return {
      content: isCopiedUrl.value ? i18n.t('generics.copied') : i18n.t('companies.account-management.copy-link'),
      hideOnTargetClick: false,
      trigger: 'hover manual',
      show: isCopiedUrl.value,
    };
  });

  const isPublishCustomJobActive = ref(props.opportunity?.adPublished || false);
  const hasJobboardPublished = ref(false);

  const hasDescription = computed(() => {
    const hasOpportunityDescription = Boolean(props.opportunity?.description && props.opportunity?.profileDescription);
    let hasCompanyDescription = false;
    if (props.opportunity?.descriptionType === 'organization') {
      hasCompanyDescription = props.opportunity?._organization?.description;
    } else if (props.opportunity?.descriptionType === 'client') {
      hasCompanyDescription = props.opportunity?._company?.description;
    } else if (props.opportunity?.descriptionType === 'custom') {
      hasCompanyDescription = Boolean(props.opportunity?.customCompanyDescription);
    }
    return hasOpportunityDescription && hasCompanyDescription;
  });

  const isDisabled = computed(() => {
    return Boolean(!hasDescription.value || !isOpportunityActive.value || !props.opportunity?.locations?.length);
  });

  const hasAutopublish = (jobboard: string) => {
    return configurationJobMultiposting.value?.[jobboard]?.params?.autoPublish || false;
  }

  const hasJobboardsPublications = async () => {
    const publishedJobboardsList = await store.dispatch('xtramile/getPublishedJobboardsList', {
      customJob: props.opportunity,
      configurationJobMultiposting: configurationJobMultiposting.value,
    });
    return publishedJobboardsList?.length > 0;
  }

  const setAdPublished = async (published: boolean) => {
    if (!published) {
      isConfirmAutopublish.value = false;
    } else {
      isConfirmDepublications.value = false;
    }

    if (autopublishJobboard.value && published && !isConfirmAutopublish.value) {
      jobboardModal.value = '';
      nextTick(() => {
        isPublishCustomJobActive.value = false;
        jobboardModal.value = autopublishJobboard.value;
      });
      return;
    }

    if (!props.isCreating) {
      await updateCustomJob({
        id: props.opportunity._id,
        adPublished: isPublishCustomJobActive.value,
      });

      emits('update-opportunity', { adPublished: isPublishCustomJobActive.value });
    } else {
      // emit('create-opportunity');
    }

    isConfirmDepublications.value = false;
  };

  const onAutopublish = () => {
    isConfirmAutopublish.value = true;
    isPublishCustomJobActive.value = true;
  }

  const onConfirmDeduplication = async () => {
    isConfirmDepublications.value = true;
    isPublishCustomJobActive.value = false;

    onCloseModal();
  }

  const onCloseModal = () => {
    isModalConfirmOpen.value = false;
  }

  const onUpdateIsPublishCustomJobActive = async (value: boolean) => {
    const hasJobboardsPublication = await hasJobboardsPublications();
    if (hasJobboardsPublication && !isConfirmDepublications.value) {
      isModalConfirmOpen.value = true;
      // Force toggle to stay on true value
      publishToggle.value.$el.children[1].value = true;
      publishToggle.value.$el.children[1].checked = true;
      return
    }
    isPublishCustomJobActive.value = value;
  }

  const onJobUnpublishFromCareer = () => {
    isPublishCustomJobActive.value = false;
  }

  watch(() => isPublishCustomJobActive.value, async(value) => {
    await setAdPublished(value);
  });
</script>

<template>
  <div class="flex w-full flex-col items-start">
    <div class="mb-2.5 flex w-full flex-col gap-[5px]">
      <label
        class="text-blue-800"
      >
        {{ $t('custom-jobs.career-website') }}
      </label>
      <div
        class="flex w-full justify-between rounded-md border border-blue-200 bg-neutral-100 px-5 py-2"
        @click="checkFeatureActive">
        <div class="panel-custom-jobs-distribution__link max-w-[300px] bg-white">
          <div>
            <a :href="careerWebsiteUrl" class="is-ellipsis" target="_blank">{{ careerWebsiteUrl }}</a>
          </div>
          <button
            v-clipboard:copy="careerWebsiteUrl"
            v-clipboard:success="setIsCopiedUrl"
            v-tooltip="handleTooltip">
            <i class="icon-clipboard-2"></i>
          </button>
        </div>
        <div class="flex gap-2.5">
          <i v-if="!isMultidiffusionFeatureActive" class="icon-lock mr-3" style="color: #F78F00;"></i>
          <label
            :class="{
              'publish-custom-job__label--disabled': !isCareerMultidiffusionActive || !isMultidiffusionFeatureActive || !isOpportunityActive || !hasDescription,
              'publish-custom-job__label--active': isPublishCustomJobActive,
              'publish-custom-job__label--inactive': !isPublishCustomJobActive,
            }"
            class="publish-custom-job__label"
            for="publish-custom-job">
            {{ $t('custom-jobs.distribute-my-offer') }}
          </label>
          <bc-toggle
            id="publish-custom-job"
            ref="publishToggle"
            :value="isPublishCustomJobActive"
            :disabled="!isCareerMultidiffusionActive || !isMultidiffusionFeatureActive || hasJobboardPublished || !isOpportunityActive || isDisabled"
            @input="onUpdateIsPublishCustomJobActive"
            type="default">
          </bc-toggle>
          <custom-job-publish-warning v-if="!isOpportunityActive || isDisabled"/>
        </div>
      </div>
    </div>
    <div class="mb-2.5 flex w-full flex-col gap-[5px]">
      <label
        class="text-blue-800">
        Multidiffusion
      </label>
      <div
        v-if="hasMultidiffusion && !isConfirmDepublications"
        class="flex w-full justify-between rounded-md">
        <JobboardPublishList
          :custom-job="opportunity"
          :jobboard-modal="jobboardModal"
          :is-job-published="isPublishCustomJobActive"
          @on-update="$emit('update-opportunity', $event)"
          @on-autopublish="onAutopublish"
          @on-job-unpublish="onJobUnpublishFromCareer"
          @failed-status="multidiffusionAvailable = false"
        />
      </div>
      <div v-if="isConfirmDepublications" class="mx-auto mt-4">
        <BcSpinner></BcSpinner>
      </div>
    </div>
    <ModalConfirm
      :isOpen="isModalConfirmOpen"
      :title="$t('jobboards.remove-jobboard-warning')"
      :content="$t('generics.do-you-want-to-continue')"
      :cancelText="$t('generics.cancel')"
      :confirmText="$t('generics.confirm')"
      color="error"
      @on-confirm="onConfirmDeduplication"
      @close="onCloseModal"
    />
  </div>
</template>

<style lang="scss" scoped>
  .custom-jobs-publish-form {
    width: 100%;
    flex-direction: column;
    padding-bottom: 8%;

    &__select {
      width: 329px;
    }

    &__text {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: $color-tertiary;
    }

    &__item {
      height: 50px;
      border-radius: 5px;
      border: 1px solid $color-blue-medium;
      background: $color-neutre-4;
      padding: 10px 30px;
      align-items: center;
      justify-content: space-between;

      & > p {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $color-tertiary;
      }
    }

    &__content {
      flex-direction: column;
      overflow: auto;
      max-height: 70vh;
      padding-bottom: 60px;
    }

    &__footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      margin: auto;
      background: white;
    }

    &__toggle {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .publish-custom-job {
    &__label {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      align-items: center;

      &--disabled {
        color: $color-blue-cadet;
        opacity: 0.5;
      }

      &--active {
        color: $color-green-shamrock;
      }

      &--inactive {
        color: $color-red-mandy;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__warning {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin-top: 15px;
      color: $color-blue-dark-cello;
    }
  }

  .panel-custom-jobs-distribution {
    &__link {
      border: 1px solid $color-blue-medium;
      border-radius: 5px;

      & > div {
        overflow: auto;
        padding: 10px;
        padding-right: 30px;
        max-width: 600px;

        & > a {
          color: $color-primary;
          text-decoration-line: underline;
          font-weight: 400;
          font-size: 15px;
          line-height: 19px;
        }
      }

      & > button {
        border-left: 1px solid $color-blue-medium;
        padding: 10px;
      }
    }
  }
</style>
