import { useStore } from '@/store';
import { computed } from 'vue';

export const useMultiselect = () => {
  const store = useStore();

  const selectedCards = computed<any[]>(() => store.state.card.selectedCards);
  const cards = computed<any[]>(() => store.state.card.cards);
  const isAllCardsSelected = computed(() => cards.value.length === selectedCards.value.length);

  function setSelectedCards(value, card) {
    const selectedCardsIds = selectedCards.value.map(card => card._id);

    if (!value && selectedCardsIds.includes(card._id)) {
      store.dispatch(
        'setSelectedCards',
        selectedCards.value.filter(c => c._id !== card._id),
      );
    } else if (value && !selectedCardsIds.includes(card._id)) {
      store.dispatch('setSelectedCards', [...selectedCards.value, card]);
    }
  }

  function getIsSelectedStatus(id) {
    return selectedCards.value.map(card => card._id).includes(id);
  }

  function selectAllCards(value) {
    const selectedCards = value ? cards.value : [];

    store.dispatch('setSelectedCards', selectedCards);
  }

  function resetSelectedCards() {
    store.dispatch('setSelectedCards', []);
  }

  return {
    selectedCards,
    setSelectedCards,
    getIsSelectedStatus,
    isAllCardsSelected,
    selectAllCards,
    resetSelectedCards,
  };
};
