<template>
  <statistics-item
    class="statistics-candidates"
    :title="$t('generics.sourcing-cap')"
    :steps="steps"
    :is-loading="isLoading"
  ></statistics-item>
</template>

<script>
import { getDashboardNewContacted, getDashboardNewQualifications } from '@/api/dashboard';

import StatisticsItem from './StatisticsItem';

export default {
  name: 'statistics-candidates',
  components: {
    StatisticsItem,
  },
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      isLoading: false,
      steps: [
        {
          key: 'candidate-contacts',
          name: this.$t('statistics.nb-candidate-contacts'),
          icon: 'presentation',
          value: 0,
          total: 0,
          topPerformers: [],
        },
        {
          key: 'candidate-qualifications',
          name: this.$t('statistics.nb-candidate-notation'),
          icon: 'star',
          value: 0,
          total: 0,
          topPerformers: [],
        },
      ],
    };
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      await Promise.all([this.getKPIs(0, 'contacts'), this.getKPIs(1, 'qualifications')]);
      this.isLoading = false;
    },
    async getKPIs(index, name) {
      const handlers = {
        contacts: getDashboardNewContacted,
        qualifications: getDashboardNewQualifications,
      };

      const { data } = await handlers[name]({
        start: this.$route.query.activityStart,
        end: this.$route.query.activityEnd,
        coachId: this.$route.query.activityCoachId,
      });

      this.steps[index].value = data.value;
      this.steps[index].total = data.total;
      this.steps[index].topPerformers = data.topPerformers;
    },
  },
  watch: {
    '$route.query.activityCoachId'() {
      this.fetchData();
    },
    '$route.query.activityStart'() {
      this.fetchData();
    },
    '$route.query.activityEnd'() {
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-candidates {
}
</style>
