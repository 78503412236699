// @ts-nocheck
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import Vuex from 'vuex';
import App from './App';
import router from './router';
import store from './store';
import VueResource from 'vue-resource';
import vClickOutside from 'v-click-outside';
import { sync } from 'vuex-router-sync';
import imgFallback from '@/common-old/directives/imgFallback';
import VueAutosize from '@/common-old/mixins/Autosize/autosize';
import VueClipboard from 'vue-clipboard2';
import { VClosePopover, VPopover, VTooltip } from 'v-tooltip';
import routerMiddleware from '@/middlewares/router';
import Toast from '@/ui-kit/components/BcToast';
import OnboardingToast from '@/components/Topbar/TopbarOnboarding/TopbarOnboardingToast';
import VCalendar from 'v-calendar';
// import VueSocketIO from 'vue-socket.io';
import VueScrollTo from 'vue-scrollto';
import VueMeta from 'vue-meta';
// import Hotjar from 'vue-hotjar';
import { setup } from '@/api';
import { i18n } from './i18n/i18n';
import { Trans } from './i18n/plugins/Translation';
import { Validator } from 'vee-validate';
import moment from 'moment/min/moment-with-locales';
import PortalVue from 'portal-vue';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueConfetti from 'vue-confetti'
import { VueQueryPlugin } from '@tanstack/vue-query'

import dictionary from '@/i18n/plugins/vee-validate/dictionary';
import { http } from '@/api/index';
import { createPinia, PiniaVuePlugin } from 'pinia'
import * as Sentry from "@sentry/vue";
import InstantSearch from 'vue-instantsearch';
import MarvinIcons from '@/lib/icons/main.ts';

import '../service-worker';
import './index.scss';

// import '@marvin-recruiter/ui-kit/dist/style.css';

import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';

Vue.use(InstantSearch);
Vue.use(PrimeVue, { unstyled: true });
Vue.use(PortalVue);
Vue.use(VueMeta);
Vue.use(VueResource);
Vue.use(VueAutosize);
Vue.use(Vuex);
Vue.use(PiniaVuePlugin)
Vue.use(vClickOutside);
Vue.use(MarvinIcons);
Vue.use(VueClipboard);
Vue.use(VueQueryPlugin, {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    }
  }
});
Vue.use(VCalendar, {
  locale: i18n.locale,
  locales: {
    'en': {
      firstDayOfWeek: 1,
      masks: {
        L: 'YYYY-MM-DD',
        LL: 'MMM DD, YYYY',
      },
    },
    'fr': {
      firstDayOfWeek: 2,
      masks: {
        L: 'DD-MM-YYYY',
        LL: 'DD MMM YYYY',
      },
    },
  },
});
Vue.use(VueScrollTo);
Vue.use(Toast, {
  duration: 2000,
});
Vue.use(OnboardingToast, {
  duration: 4000,
});
Vue.use(CKEditor);
Vue.use(VueConfetti);

Vue.directive('img-fallback', imgFallback);
Vue.directive('tooltip', VTooltip);
Vue.directive('close-popover', VClosePopover);
Vue.component('v-popover', VPopover);

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);

Vue.config.productionTip = false;
VTooltip.options.defaultBoundariesElement = document.body;

if (import.meta.env.PROD) {
  Sentry.init({
    Vue,
    dsn: "https://ba76d361d6b7d96ce0cbc178ae4bee45@o4508733762043904.ingest.de.sentry.io/4508733763944528",
    integrations: [
      Sentry.browserTracingIntegration({ 
        router,
      }),
    ],
    beforeSend(event) {    
      if (event.request) {
        // Capture request data
        const request = event.request;
        if (request.data) {
          event.request.data = request.data;
        }
      }
      
      // Force 500 and 503 errors to Sentry
      if (event.extra && event.extra.response) {
        const status = event.extra.response.status;
        if (status === 500 || status === 503) {
          event.tags = {
            ...event.tags,
            priority: 'high',
            error_type: 'server_error',
            status_code: status
          };

          return event;
        }

        // Capture response data
        event.extra.response_body = event.extra.response.data;
      }
      return event;
    },
    environment: import.meta.env.APP_ENV ?? 'local',
    // debug: true,
    // Tracing
    tracesSampleRate: import.meta.env.PROD ? 1.0 : 0,
    // tracePropagationTargets: ['localhost'],

    replaysSessionSampleRate: import.meta.env.PROD ? 1.0 : 0,
    replaysOnErrorSampleRate: import.meta.env.PROD ? 1.0 : 0,
  });
}

setup(store);
const pinia = createPinia()
/**
 * Router beforeEach route middleware
 */
routerMiddleware();
// Vuex Router Sync Initialize
sync(store, router);

Validator.localize(i18n.locale, dictionary[i18n.locale]);
moment.locale(i18n.locale);

Vue.config.devtools = import.meta.env.PROD === false;
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  pinia,
  components: { App },
  provide: {
    icons: Vue.prototype.$marvinIcons
  },
  created() {
    http.defaults.headers.common['content-language'] = this.$i18n.locale;
  },
  template: '<App/>',
  ...(process && import.meta.env.MODE !== 'test' && { i18n }),
});
