<template>
  <mv-filter class="filter-coders-more" :label="$t('generics.more-criteria')" align="right" :count="totalCount">
    <div class="filter-coders-more__container">
      <div class="filter-coders-more__divider">
        <filter-coders-contract-type @count="updateCount"></filter-coders-contract-type>
      </div>
      <div class="filter-coders-more__divider">
        <filter-coders-company
          class="mt-3"
          :label="$t('generics.associated-company')"
          :with-select="false"
          :with-tags="true"
          @count="updateCount"
        >
        </filter-coders-company>
      </div>
      <div class="filter-coders-more__divider">
        <filter-coders-salary @count="updateCount"></filter-coders-salary>
      </div>
      <div class="filter-coders-more__divider">
        <filter-coders-custom-fields @count="updateCount"></filter-coders-custom-fields>
      </div>
      <div class="filter-coders-more__divider">
        <filter-coders-remote @count="updateCount"></filter-coders-remote>
      </div>
      <div class="filter-coders-more__divider">
        <filter-coders-contact @count="updateCount"></filter-coders-contact>
      </div>
      <div class="filter-coders-more__divider">
        <filter-coders-career @count="updateCount"></filter-coders-career>
      </div>
      <div class="filter-coders-more__divider">
        <filter-coders-last-activity @count="updateCount"></filter-coders-last-activity>
      </div>
      <div class="filter-coders-more__divider">
        <filter-coders-min-evaluation @count="updateCount"></filter-coders-min-evaluation>
      </div>
      <div class="filter-coders-more__divider">
        <filter-coders-uniqueid @count="updateCount"></filter-coders-uniqueid>
      </div>
    </div>
  </mv-filter>
</template>

<script>
import MvFilter from '@/ui-kit/components/MvFilter/MvFilter';
import FilterCodersContractType from './FilterCodersContractType';
import FilterCodersContact from './FilterCodersContact';
import FilterCodersCareer from './FilterCodersCareer';
import FilterCodersLastActivity from './FilterCodersLastActivity';
import FilterCodersMinEvaluation from './FilterCodersMinEvaluation';
import FilterCodersSalary from './FilterCodersSalary';
import FilterCodersCustomFields from './FilterCodersCustomFields';
import FilterCodersRemote from '@/components/Filter/Coders/FilterCodersRemote';
import FilterCodersUniqueid from '@/components/Filter/Coders/FilterCodersUniqueid';
import FilterCodersCompany from '@/components/Filter/Coders/FilterCodersCompany';

export default {
  name: 'filter-coders-more',
  components: {
    MvFilter,
    FilterCodersContractType,
    FilterCodersContact,
    FilterCodersCareer,
    FilterCodersLastActivity,
    FilterCodersMinEvaluation,
    FilterCodersSalary,
    FilterCodersCustomFields,
    FilterCodersRemote,
    FilterCodersUniqueid,
    FilterCodersCompany,
  },
  data() {
    return {
      count: {},
    };
  },
  computed: {
    totalCount() {
      return Object.keys(this.count).reduce((acc, key) => {
        return acc + this.count[key];
      }, 0);
    },
    salaryType() {
      return this.$route.query.salaryType;
    },
  },
  methods: {
    updateCount(count) {
      this.count = {
        ...this.count,
        [count.key]: count.count,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-coders-more {
  &__container {
    flex-direction: column;
    min-width: 581px;
  }

  &__divider {
    border-bottom: 1px solid $color-blue-light;
  }
}
</style>
