<template>
  <div class="inbox">
    <div class="is-align-items-center is-justify-content-center inbox__header">
      <p class="inbox__title">
        Nouvelles inscriptions Partners
      </p>
      <router-link
        :to="`${$route.path}/profile?page=${$route.query.page}&type=edit-profile&coach-id=${coachId}`"
        class="inbox__profile is-row">
        <div class="inbox__icon icon-slider"></div>
        <p class="inbox__text">
          Mes préférences
        </p>
      </router-link>
    </div>
    <router-view class="inbox__list"></router-view>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'inbox',
    computed: {
      ...mapState({
        metadata: state => state.user.metadata,
        coachId: state => state.user.profile._id,
        notificationsStatus: state => state.notifications.status
      }),
    }
  };
</script>

<style lang="scss" scoped>
  .inbox {
    width: 100%;
    align-items: center;
    margin: 60px 0 85px;
    padding: 50px;
    flex-direction: column;

    &__header {
      position: relative;
      width: 100%;
      flex-direction: column;

      @include bp('tablet') {
        flex-direction: row;
      }
    }

    &__title {
      text-align: center;
      font-size: $font-size-xxl;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      color: $color-secondary;
    }

    &__profile {
      display: flex;
      padding: 10px 20px;
      border-radius: $border-radius-xl;
      background-color: rgba($color-secondary-dark, 0.1);
      color: $color-secondary-dark;

      @include bp('tablet') {
        position: absolute;
        right: 0;
      }
    }

    &__icon {
      font-size: $font-size-l;
    }

    &__text {
      margin-left: 10px;
      font-size: $font-size-s;
    }
  }
</style>
