<template>
  <transition name="dropdown">
    <slot></slot>
  </transition>
</template>

<script>
  export default {
    name: 'transition-dropdown'
  }
</script>

<style lang="scss" scoped>
  .dropdown-enter-active,
  .dropdown-leave-active {
    @include transition(opacity 0.3s ease, transform 0.5s ease)
  }

  .dropdown-enter,
  .dropdown-leave-to {
    @include opacity(0);
    transform: translateY(-2.5%);
  }
</style>
