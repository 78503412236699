<template>
  <div class="configuration-multiposting">
    <configuration-multicast-tabs></configuration-multicast-tabs>
    <div class="configuration-multiposting__container">
      <h3 class="body-l--bold mb-2">
        {{ $t('generics.statistics') }}
      </h3>
      <div class="configuration-other__content">
        <bc-checkbox id="multiposting-stats" v-model="multiposting.showStats">
          {{ $t('config.multiposting-statistics') }}
        </bc-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
import ConfigurationMulticastTabs from '@/components/Configuration/Tabs/ConfigurationMulticastTabs';

export default {
  name: 'configuration-multiposting',
  components: {
    BcCheckbox,
    ConfigurationMulticastTabs,
  },
  mounted() {
    if (this.configuration.multiposting) {
      this.multiposting = this.configuration.multiposting;
    }
  },
  data() {
    return {
      multiposting: {
        showStats: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      configuration: 'user/configuration',
    }),
  },
  methods: {
    ...mapActions({
      updateConfigurationOrganizationByKey: 'user/updateConfigurationOrganizationByKey',
    }),
    async updateConfiguration(key, data) {
      try {
        await this.updateConfigurationOrganizationByKey({ key, ...data });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
  },
  watch: {
    multiposting: {
      handler(data) {
        this.updateConfiguration('multiposting', data);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.configuration-multiposting {
  flex-direction: column;

  &__container {
    flex-direction: column;
    gap: 10px;
    padding: 20px 30px;
  }
}
</style>
