<template>
  <div class="jobteaser">
    <JobboardConfigItem
      :channel="channel"
      :subscriptionModel="subscriptionModel"
      :applicationMode="applicationMode"
      :applicationForwardingType="applicationForwardingType"
      :disabledSubscriptions="disabledSubscriptions"
      :disabledApplications="disabledApplications"
      :is-valid="isValid"
      @update-subscription-model="updateSubscriptionModel"
      @update-application-mode="updateApplicationMode"
      @update-application-forwarding-type="updateApplicationForwardingType"
      @cancel="$emit('cancel', $event)"
      @submit="onSubmit">
      <template #subscriptionContent>
        <div class="is-full-width mt-5 flex-col gap-3 pt-3">
          <ContractIdField
            v-model="contractId"
            :label="'COMPANY_ID'"
            :placeholder="`Jobteaser COMPANY_ID`"
            :warning="$t('jobboards.warning-login-jobteaser')"
          />
          <div class="flex gap-[10px]">
            <mv-select
              :items="paymentTypesOptions"
              :value="paymentType"
              :is-absolute="true"
              @select="onSelectPaymentType"
            ></mv-select>
            <div>
              <WarningCercle
                :content="channel?.accountParamsJsonSchema?.properties?.PAYMENT_TYPE?.description"
              />
            </div>
          </div>
        </div>
      </template>
      <template #applicationMode>
        <div v-if="applicationMode === applicationModes.email" class="is-full-width mt-5">
          <bc-input
            v-model="email"
            class="is-full-width"
            :name="$t('contacts.email')"
            label="Mail sur lequel envoyer les candidatures"
            :placeholder="$t('contacts.email')"
            :labelPlaceholder="false"
            :required="true"
          >
          </bc-input>
        </div>
      </template>
    </JobboardConfigItem>
  </div>
</template>

<script>
import { subscriptionsModels, applicationModes } from '@/api/xtramile';
import BcInput from '@/ui-kit/components/BcInput/BcInput';
import ContractIdField from '@/components/Jobboard/JobboardConfigChannel/Fields/ContractIdField';
import JobboardConfigItem from '@/components/Jobboard/JobboardConfig/JobboardConfigItem';
import WarningCercle from '@/components/WarningCercle/WarningCercle';
import MvSelect from '@/ui-kit/components/MvSelect/MvSelect';

export default {
  name: 'jobteaser',
  components: {
    BcInput,
    ContractIdField,
    JobboardConfigItem,
    WarningCercle,
    MvSelect,
  },
  props: {
    channel: {
      type: Object,
      default: () => ({}),
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    disabledSubscriptions: {
      type: Array,
      default: () => [],
    },
    disabledApplications: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    if (this.values?.params) {
      this.subscriptionModel = this.values.params.subscriptionModel;
      this.applicationMode = this.values.params.applicationMode;
      this.applicationForwardingType = this.values.params.applicationForwardingType;
      this.contractId = this.values.params.accountParams.COMPANY_ID;
      this.paymentType = this.values.params.accountParams.paymentType;
      this.email = this.values.params.accountParams.email;
    }
  },
  data() {
    return {
      subscriptionsModels,
      applicationModes,
      contractId: '',
      subscriptionModel: '',
      applicationMode: '',
      email: '',
      paymentType: 'premium_credits',
      applicationForwardingType: '',
    }
  },
  computed: {
    isSubscriptionValid() {
      if (!this.subscriptionModel) return false;
      if (this.subscriptionModel === this.subscriptionsModels.credits) {
        return !!this.contractId;
      }
      return true;
    },
    isApplicationValid() {
      if (!this.applicationMode) return false;
      if (this.applicationMode === this.applicationModes.email) {
        return !!this.email
      }
      return true;
    },
    isValid() {
      return this.isSubscriptionValid && this.isApplicationValid;
    },
    paymentTypesOptions() {
      return this.channel?.accountParamsJsonSchema?.properties?.PAYMENT_TYPE?.enum?.map(paymentType => {
        return {
          _id: paymentType,
          label: paymentType,
          value: paymentType,
        }
      })
    }
  },
  methods: {
    updateSubscriptionModel(key) {
      this.subscriptionModel = key;
    },
    updateApplicationMode(key) {
      this.applicationMode = key;
    },
    onSelectPaymentType(value) {
      this.paymentType = value;
    },
    updateApplicationForwardingType(value) {
      this.applicationForwardingType = value;
    },
    onSubmit() {
      this.$emit('submit', {
        channelId: this.channel.id,
        subscriptionModel: this.subscriptionModel,
        applicationMode: this.applicationMode,
        applicationForwardingType: this.applicationForwardingType,
        accountParams: {
          COMPANY_ID: this.contractId,
          email: this.email,
          paymentType: this.paymentType,
        }
      })
    }
  }
}
</script>
