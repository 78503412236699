<script lang="ts" setup>
  import { Process } from '@/domains/models/Process';
  import { ConfigurationProcessCategoryStep } from '@/domains/models/Organization';
  import { computed, defineEmits, defineProps, ref, withDefaults } from 'vue';
  import defaultAvatarCompany from '@/assets/img/default-avatar-company.svg';
  import defaultAvatarCandidate from '@/assets/img/default-avatar.svg';
  import BcModal from '@/ui-kit/components/BcModal';
  import BcAvatar from '@/ui-kit/components/BcAvatar';
  import BcButton from '@/ui-kit/components/BcButton';
  import { ProcessCustomCategoriesEnum } from '@/macros/processesCustom/process';
  import { useMutation } from '@tanstack/vue-query';
  import ModalProcessAbortReason from '../Modal/ModalProcess/ModalProcessAbortReason.vue';
  import ModalProcessCommissionCustom
    from '@/components/Modal/ModalProcess/ModalProcessCommissionCustom.vue';
  import ProcessDropdown from '@/components/Process/ProcessDropdown.vue';
  import { useStore } from '@/store';
  import { RouterLink } from 'vue-router';
  import { useRoute } from 'vue-router/composables';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { useI18n } from '@/i18n/i18n';
  import ModalProcessActionConfirmation
    from '@/components/Modal/ModalProcess/ModalProcessActionConfirmation.vue';
  import { updateCustomJob } from '@/api/custom-jobs';
  import ProcessCardSourceLogo from '@/components/Processes/ProcessCardSourceLogo.vue';
  import ProcessCardOptions from '@/components/Process/ProcessCard/ProcessCardOptions.vue';
  import ModalProcessDate from '@/components/Modal/ModalProcess/ModalProcessDate.vue';
  import { getShorthandDate } from '@/helpers/date';
  import { useElementBounding } from '@vueuse/core';
  import ModalProcessDeal from '@/components/Modal/ModalProcess/ModalProcessDeal.vue';
  import { useProcessesStore } from '@/store/pinia/processes';

  type ModalActionType =
    'abort-process'
    | 'unabort-process'
    | 'reject-process'
    | 'delete'
    | 'commission-custom'
    | 'next-interview'
    | 'next-presentation'
    | 'integration-date';

  interface Props {
    process: Process;
    parent?: 'company' | 'candidate';
  }

  const props = withDefaults(defineProps<Props>(), {
    parent: 'candidate',
  });
  const emit = defineEmits<{
    'update-process': (value: Process) => void,
    'remove-process': [{ value: string }]
  }>();
  const modalState = ref<ModalActionType | null>(null);

  const container = ref<HTMLDivElement | null>(null);
  const { y: containerScroll } = useElementBounding(container);

  const route = useRoute();
  const { t } = useI18n();
  const toast = useToast();

  const store = useStore();
  const organization = store.state.user?.profile?._organization;
  const processesStore = useProcessesStore();

  const dealModalData = ref<any>();

  const statusOptions: ConfigurationProcessCategoryStep[] = organization.configuration.process?.categories.map(category => {
    return {
      ...category,
      steps: category.steps.map(step => ({
        ...step,
        categoryKey: category.key,
      })),
    };
  });
  const flatStatusOptions: ConfigurationProcessCategoryStep[] = organization.configuration.process?.categories?.reduce((acc, category) => {
    return [...acc, ...category.steps.map(step => ({
      ...step,
      categoryKey: category.key,
      value: step.key,
      label: step.name,
    }))];
  }, []);

  const configurationProcess = computed(() => {
    return store.getters['user/configurationProcess'];
  });
  const shouldSendMailOnReject = computed(() => organization?.configuration?.multiposting?.sendEmailAfterApplicationTreated);
  const shouldSendMailOnFailure = computed(() => organization?.configuration?.process?.failureEmail);


  const closeModal = () => {
    modalState.value = null;
    store.dispatch('app/setParams', {});
  };

  const processCategoryColor = {
    [ProcessCustomCategoriesEnum.beforeCandidatePresentation]: {
      color: '#3865FD',
      backgroundColor: '#EDF0FF',
    },
    [ProcessCustomCategoriesEnum.beforeProcessRecruitment]: {
      color: '#4A3AFF',
      backgroundColor: '#ECF2FD',
    },
    [ProcessCustomCategoriesEnum.afterProcessRecruitment]: {
      color: '#3DCF8E',
      backgroundColor: '#EEF9F4',
    },
  };

  const currentProcess = computed(() => {
    return props.process.historySteps.at(-1);
  });
  const currentProcessStatus = computed(() => {
    return flatStatusOptions.find(status => currentProcess.value?.stepKey === status.key) || flatStatusOptions[0];
  });
  const currentProcessCategory = computed(() => {
    return processCategoryColor[currentProcess.value?.categoryKey];
  });
  const isFirstStatus = computed(() => {
    return currentProcess.value?.stepKey === flatStatusOptions[0].key;
  });
  const isFailure = computed(() => {
    return !!props.process?.abortedAt;
  });

  function getCommissionValue(process) {
    return process?.amountBilled || process?.commissionCustom || process?.commission || 0;
  }

  const commission = computed(() => {
    return getCommissionValue(props.process);
  });

  const updateProcessStatus = (status: { categoryKey: string, key: string }) => {
    return processesStore.onUpdateStep({
      processId: props.process._id,
      categoryKey: status.categoryKey,
      stepKey: status.key,
    });
  };

  function openEmailPanel({ customJob, actionId }: { customJob: any; actionId?: string; }) {
    const params = {
      coder: props.process._coder,
      customJob,
      actionId,
      emails: [props.process._coder.email, ...(props.process._coder.emails || [])].filter(Boolean),
      isCompany: false,
      isApplication: true,
    };
    store.dispatch('app/setIsPanel', true);
    store.dispatch('app/setParams', params);
    store.dispatch('app/setModal', 'modal-email-send-form');
  };

  function handleStatusChange(step: { stepKey: string, categoryKey: string }) {
    const shouldConfirmDealFirst =
      configurationProcess.value?.processSuccessValidation?.activated &&
      step.categoryKey === 'afterProcessRecruitment' &&
      !props.process.dealAcceptedAt;
    if (shouldConfirmDealFirst) {
      dealModalData.value = { process: props.process, step };
      return;
    }
    updateProcessStatusMutation.mutate(step);
  }

  const updateProcessStatusMutation = useMutation({
    mutationKey: ['UPDATE_PROCESS_CUSTOM_STEP_STATUS', props.process._id],
    mutationFn: async(step: { categoryKey: string; key: string }) => {
      const data = await updateProcessStatus({
        categoryKey: step.categoryKey,
        key: step.key,
      });

      return data;
    },
    onSuccess: (response, step) => {
      if (step.email) {
        openEmailPanel({ customJob: response._customJob, actionId: response?.action?._id });
      }
      emit('update-process', { historySteps: response.historySteps });
    },
    onError: (e) => {
      console.error(e);
      toast.show({
        type: 'error',
        message: t('toast.error-occured'),
      });
    },
  });

  const updateProcessStatusMutationWithoutEmail = useMutation({
    mutationKey: ['UPDATE_PROCESS_CUSTOM_STEP_STATUS', props.process._id],
    mutationFn: async(step: { categoryKey: string; key: string }) => {
      const data = await updateProcessStatus({
        categoryKey: step.categoryKey,
        key: step.key,
      });

      return data;
    },
    onSuccess: (response, step) => {
      emit('update-process', { historySteps: response.historySteps });
    },
    onError: (e) => {
      console.error(e);
      toast.show({
        type: 'error',
        message: t('toast.error-occured'),
      });
    },
  });


  const confirmAbortProcess = async(params: { reason: string }) => {
    // open email panel on candidature reject
    if ((isFirstStatus.value && shouldSendMailOnReject.value) || shouldSendMailOnFailure.value) {
      await store.dispatch('emails/setEmailSentCallback', async() => {
        abortProcessMutation.mutate({ ...params?.reason && { reason: params.reason } });
      });
      openEmailPanel({
        customJob: props.process._customJob,
        // actionId: props?._action?._id,
      });
    } else {
      abortProcessMutation.mutate({ ...params?.reason && { reason: params.reason } });
    }
  };

  const abortProcessMutation = useMutation({
    mutationKey: ['ABORT_PROCESS_CUSTOM', props.process._id],
    mutationFn: async({ reason }: { reason?: string } = { reason: '' }) => {
      const data = await processesStore.onAbort({
        processId: props.process._id,
        reason,
        rejected: isFirstStatus.value,
      });
      return data;
    },
    onSuccess: (response) => {
      emit('update-process', { abortedAt: response.abortedAt });

      closeModal();
    },
    onError: (e) => {
      console.error(e);
    },
  });

  const unabortProcessMutation = useMutation({
    mutationKey: ['UNABORT_PROCESS_CUSTOM', props.process._id],
    mutationFn: async() => {
      const data = await processesStore.onUnabort({ processId: props.process._id });
      return data;
    },
    onSuccess: (response) => {
      emit('update-process', { abortedAt: null });

      closeModal();
    },
    onError: (e) => {
      console.error(e);
    },
  });


  const deleteProcessMutation = useMutation({
    mutationKey: ['DELETE_PROCESS_CUSTOM', props.process._id],
    mutationFn: async() => {
      const data = await processesStore.onDelete({ processId: props.process._id });
      if (data._customJob) {
        await updateCustomJob({
          id: data._customJob._id,
          nbActiveProcesses: data._customJob.nbActiveProcesses,
          withActions: false,
        });
      }
      return data;
    },
    onSuccess: (response) => {
      emit('remove-process', response._id);
      closeModal();
    },
    onError: (e) => {
      console.error(e);
    },
  });

  const actionsKeys = {
    'next-interview': 'interviewedAt',
    'next-presentation': 'introducedAt',
    'integration-date': 'integratedAt',
  };

  const setProcessScheduledDate = useMutation({
    mutationKey: ['SET_PROCESS_SCHEDULED_DATE', props.process._id],
    mutationFn: async({ date }) => {
      const key = actionsKeys[modalState.value];

      const data = await processesStore.onUpdate({ processId: props.process._id, [key]: date });

      return data;
    },
    onSuccess: (data) => {
      const key = actionsKeys[modalState.value];
      emit('update-process', { [key]: data[key] });

      closeModal();
    },
    onError: (e) => {
      console.error(e);
    },
  });


  const setCommissionCustomMutation = useMutation({
    mutationKey: ['SET_COMMISSION_CUSTOM_MUTATION', props.process._id],
    mutationFn: async({ commissionCustom } = {}) => {
      await processesStore.onUpdate({
        processId: props.process._id,
        commissionCustom: +commissionCustom,
      });
      return { commissionCustom };
    },
    onSuccess: (response) => {
      emit('update-process', { commissionCustom: +response.commissionCustom });
      closeModal();
    },
    onError: (e) => {
      console.error(e);
    },
  });

  const setAlternativeImg = (event: any) => {
    event.target.src = props.parent === 'candidate' ? defaultAvatarCompany : defaultAvatarCandidate;
  };

  const abortProcess = () => {
    modalState.value = 'abort-process';
  };

  const unabortProcess = () => {
    modalState.value = 'unabort-process';
  };

  const rejectProcess = () => {
    modalState.value = 'reject-process';
  };

  const deleteProcess = () => {
    modalState.value = 'delete';
  };

  const handleAcceptApplication = async() => {
    if (flatStatusOptions[1]?.email) {
      await store.dispatch('emails/setEmailSentCallback', async() => {
        updateProcessStatusMutationWithoutEmail.mutate(flatStatusOptions[1]);
      });
      openEmailPanel({
        coder: props.process._coder,
        customJob: props.process._customJob,
      });
    } else {
      updateProcessStatusMutation.mutate(flatStatusOptions[1]);
    }
  };

  const setCommissionCustom = () => {
    store.dispatch('app/setParams', {
      processCustom: props.process,
    });
    modalState.value = 'commission-custom';
  };

  const setPresentationDate = () => {
    store.dispatch('app/setParams', {
      processCustom: props.process,
    });
    modalState.value = 'next-presentation';
  };

  const setInterviewDate = () => {
    store.dispatch('app/setParams', {
      processCustom: props.process,
    });
    modalState.value = 'next-interview';
  };

  const setIntegrationDate = () => {
    store.dispatch('app/setParams', {
      processCustom: props.process,
    });
    modalState.value = 'integration-date';
  };


  const onProcessAction = (action: ModalActionType) => {
    const processActionsMap: Record<ModalActionType, Function> = {
      ['abort-process']: abortProcess,
      ['unabort-process']: unabortProcess,
      ['reject-process']: rejectProcess,
      ['delete']: deleteProcess,
      ['commission-custom']: setCommissionCustom,
      ['next-presentation']: setPresentationDate,
      ['next-interview']: setInterviewDate,
      ['integration-date']: setIntegrationDate,
    };
    processActionsMap[action]?.();
  };
</script>

<template>
  <div ref="container" class="flex grow items-center rounded-md bg-neutral-200 px-5 py-4">
    <BcModal :active="!!modalState" :display-close-button="false" @close="closeModal">
      <ModalProcessCommissionCustom
        v-if="modalState === 'commission-custom'"
        @confirm-action="setCommissionCustomMutation.mutate"
        @cancel-action="closeModal">
      </ModalProcessCommissionCustom>
      <ModalProcessDate
        v-else-if="modalState === 'next-presentation' || modalState === 'next-interview' || modalState === 'integration-date'"
        type="presentation"
        @cancel-action="closeModal" @confirm-action="setProcessScheduledDate.mutate">
      </ModalProcessDate>
      <ModalProcessActionConfirmation
        v-else-if="modalState === 'delete'"
        :text="$t('generics.delete-process-warning-content')"
        :title="$t('generics.delete-process-warning')"
        type="delete" @confirm-action="deleteProcessMutation.mutate"
        @cancel-action="closeModal">
      </ModalProcessActionConfirmation>
      <ModalProcessActionConfirmation
        v-else-if="!isFailure && isFirstStatus"
        :confirm-text="$t('process.yes-abort-process')"
        :title="$t('process.confirm-abort')" type="abort"
        @confirm-action="confirmAbortProcess"
        @cancel-action="closeModal">
      </ModalProcessActionConfirmation>
      <ModalProcessActionConfirmation
        v-else-if="isFailure"
        :confirm-text="$t('process.yes-activate-process')"
        :title="$t('process.confirm-unabort')" type="abort"
        @confirm-action="unabortProcessMutation.mutate"
        @cancel-action="closeModal">
      </ModalProcessActionConfirmation>
      <ModalProcessAbortReason
        v-else-if="configurationProcess.failureReason"
        @confirm-action="confirmAbortProcess"
        @cancel-action="closeModal"
        @remove-process="$emit('remove-process', process._id)">
      </ModalProcessAbortReason>
      <ModalProcessActionConfirmation
        v-else :confirm-text="$t('process.yes-abort-process')"
        :title="$t('process.confirm-abort')" type="abort"
        @confirm-action="confirmAbortProcess"
        @cancel-action="closeModal">
      </ModalProcessActionConfirmation>
    </BcModal>
    <div class="relative">
      <BcAvatar
        :src="parent === 'candidate' ? process._company?.logoUrl : process._coder?.pictureUrl"
        @error="setAlternativeImg">
      </BcAvatar>
      <ProcessCardSourceLogo :source="process.source" class="absolute bottom-0 left-5 right-0"/>
    </div>
    <RouterLink
      :to="{
      name: route.name,
      params: {
        id: parent === 'candidate' ? (process._customJob?._id || process._company?._id) : process._coder?._id
      },
      query: { ...route.query, type: parent === 'candidate' ? process._customJob?._id ? 'hunt' : 'companies' : 'coders' }
    }" class="flex grow hover:opacity-75">
      <div class="mx-3.5 flex flex-col">
        <div class="block items-center">
          <p class="text-sm font-medium text-blue-800">
            {{
              parent === 'candidate' ? process._company?.name : `${process._coder?.firstName} ${process._coder.lastName}`
            }}
          </p>
          <p class="max-w-[10rem] truncate text-sm text-blue-500">
            {{
              parent === 'candidate' ? process._customJob?.title : process._coder.title
            }}
          </p>
          <div class="flex flex-wrap gap-1">
            <div
              v-if="!isFailure && isFirstStatus"
              class="icon-send flex h-[20px] w-fit items-center rounded-xl bg-blue-100 px-2.5 text-xs font-medium text-blue-800">
              <span class="relative ml-1">{{ $t('global.appliance') }}</span>
            </div>
            <div
              v-if="!isFailure && !isFirstStatus && currentProcessStatus?.categoryKey !== 'afterProcessRecruitment'"
              class="icon-repeat flex h-[20px] w-fit items-center rounded-xl bg-[#d8e0fc] px-2.5 text-xs font-medium text-blue-500">
              <span class="relative ml-1">Process</span>
            </div>
            <div
              v-if="!isFailure && !isFirstStatus && currentProcessStatus?.categoryKey === 'afterProcessRecruitment'"
              class="icon-medal flex h-[20px] w-fit items-center rounded-xl bg-green-100 px-2.5 text-xs font-medium text-green-500">
              <span class="relative ml-1">{{ $t('generics.recruited') }}</span>
            </div>
            <div
              v-if="isFailure"
              class="icon-x-circle flex h-[20px] w-fit items-center rounded-xl bg-red-50 px-2.5 text-xs font-medium text-red-500">
              <span class="relative ml-1 font-medium">{{ $t('generics.failure') }}</span>
            </div>
            <div
              v-if="configurationProcess.hasAmount && commission"
              v-tooltip.bottom="{ content: $t('generics.forecast-amount') }"
              class="icon-coin-alt flex h-[20px] w-fit items-center rounded-xl bg-blue-100 px-2.5 text-xs font-medium text-blue-800">
              <span class="ml-1">
                {{ commission }} €
              </span>
            </div>
            <div
              v-if="configurationProcess.displayNextInterview && process.introducedAt"
              v-tooltip.bottom="{ content: $t('process.next-presentation-date') }"
              class="icon-clock flex h-[20px] w-fit items-center rounded-xl bg-blue-100 px-2.5 text-xs font-medium text-blue-800">
              <span class="ml-1">
                {{ getShorthandDate(process.introducedAt) }}
              </span>
            </div>
            <div
              v-if="configurationProcess.displayNextInterview && process.interviewedAt"
              v-tooltip.bottom="{ content: $t('process.next-interview-date') }"
              class="icon-headphones flex h-[20px] w-fit items-center rounded-xl bg-blue-100 px-2.5 text-xs font-medium text-blue-800">
              <span class="ml-1">
                {{ getShorthandDate(process.interviewedAt) }}
              </span>
            </div>
            <div
              v-if="configurationProcess.displayNextInterview && process.integratedAt"
              v-tooltip.bottom="{ content: $t('process.integration-date') }"
              class="icon-partners flex h-[20px] w-fit items-center rounded-xl bg-blue-100 px-2.5 text-xs font-medium text-blue-800">
              <span class="ml-1">
                {{ getShorthandDate(process.integratedAt) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isFailure" class="ml-auto flex items-center gap-2">
        <ProcessDropdown
          :backgroundColor="currentProcessCategory?.backgroundColor"
          :color="currentProcessCategory?.color" :items="statusOptions"
          :scroll-position="containerScroll"
          :selected-label="currentProcessStatus?.label" :value="currentProcessStatus"
          class="min-w-[160px]"
          @select="handleStatusChange"/>
      </div>
      <div v-else-if="!isFirstStatus" class="ml-auto flex items-center gap-2">
        <ProcessDropdown
          :backgroundColor="currentProcessCategory?.backgroundColor"
          :color="currentProcessCategory?.color" :items="statusOptions"
          :scroll-position="containerScroll"
          :selected-label="currentProcessStatus?.label" :value="currentProcessStatus"
          class="min-w-[160px]"
          @select="handleStatusChange"/>
      </div>
      <div v-else class="ml-auto flex items-center gap-2">
        <BcButton
          icon-left="check" size="small"
          @click.native.prevent="handleAcceptApplication">
          {{ $t('generics.accept') }}
        </BcButton>
      </div>
    </RouterLink>
    <div class="ml-[10px]">
      <ProcessCardOptions
        :is-abort="Boolean(process.abortedAt)"
        :is-application="isFirstStatus && !Boolean(process.abortedAt)" size="l"
        @on-action="onProcessAction">
      </ProcessCardOptions>
    </div>
    <ModalProcessDeal
      :data="dealModalData" :is-modal-open="!!dealModalData"
      @close-modal="dealModalData = undefined"
      @move-process="({ step }) => updateProcessStatusMutation.mutate(step)"/>
  </div>
</template>
