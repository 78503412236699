// import moment from 'moment/min/moment-with-locales';

import { http } from '@/api/index';
import { Process } from '@/domains/models/Process';

export const getProcessesCustom = (params) => {
  return http.get<Process[] | Array<Process[]> | undefined>(`/processcustom/byparams`, {
    params,
  });
};

export const getProcessesCustomAggregate = (params) => {
  return http.get<[Process[], number]>(`/processcustom/byparams/aggregate`, {
    params,
  });
};

export const getProcessesCustomById = id => {
  return http.get(`/processcustom/${id}`);
};

export const getProcessCustomTimeline = ({ id, page }) => {
  return http.get(`/processcustom/${id}/timeline`, {
    params: { page },
  });
};

export const createProcessCustom = process => {
  return http.post<Process | undefined>('/processcustom', process);
};

export const updateProcessCustom = ({ id, ...data }) => {
  return http.put(`/processcustom/${id}`, data);
};

export const updateProcessCustomStep = ({ id, ...step }) => {
  return http.put(`/processcustom/${id}/step`, step);
};

export const getLastProcessesCustom = ({ coachId, teamId, filterBy = 'candidates', to, from }) => {
  return http.get(`/processcustom/bycoach`, {
    params: {
      coachId,
      teamId,
      filterBy,
      to,
      from,
    },
  });
};


export const getAggregatedProcesses = ({ coachId, teamId, groupBy = 'candidates', to, from, stepKey, page }) => {
  return http.get(`/processcustom/aggregate`, {
    params: {
      coachId,
      teamId,
      groupBy,
      page,
      to,
      stepKey,
      from,
    },
  });
};

export const getAggregatedProcessesMetadata = ({ coachId, teamId, groupBy = 'candidates', to, from }) => {
  return http.get(`/processcustom/aggregate/metadata`, {
    params: {
      coachId,
      teamId,
      groupBy,
      to,
      from,
    },
  });
};

export const getAbortedProcessesCustom = ({
                                            coachId,
                                            teamId,
                                            filterBy = 'candidates',
                                            to,
                                            from,
                                          }) => {
  return http.get('/processcustom/aborted', {
    params: {
      coachId,
      teamId,
      filterBy,
      to,
      from,
    },
  });
};

export const dealProcessCustom = ({
                                    id,
                                    acceptanceDate,
                                    dealAmount,
                                    financialProposal,
                                    estimatedStartDate,
                                  }) => {
  return http.post(`/processcustom/${id}/deal`, {
    acceptanceDate,
    dealAmount,
    financialProposal,
    estimatedStartDate,
  });
};

export const abortProcessCustom = (id: string, { reason, rejected }: { reason?: string; rejected?: boolean }) => {
  return http.put(`/processcustom/${id}/abort`, { reason, rejected });
};

export const unabortProcessCustom = (id) => {
  return http.put(`/processcustom/${id}/unabort`);
};
