<template>
  <div class="title-pokes">
    <div class="title-pokes__container">
      <p class="title-pokes__main-text">
        {{ textLeft }}
      </p>
    </div>
    <div class="title-pokes__container">
      <div class="icon-paper-plane">
      </div>
      <p
        :class="{'title-pokes__message-text--regular': !seen}"
        class="title-pokes__message-text">
        {{ lastMessageText }}
      </p>
    </div>
    <p
      :class="{'title-pokes__hour--regular': !seen}"
      class="title-pokes__hour">
      {{ lastMessageAt }}
    </p>
  </div>
</template>

<script>
  export default {
    name: 'title-pokes',
    props: {
      textLeft: {
        type: String,
      },
      textRight: {
        type: String,
      },
      seen: {
        type: Boolean,
        default: false,
      },
      lastMessageAt: {
        type: String
      },
      lastMessageText: {
        type: String
      }
    },
  };
</script>

<style lang="scss" scoped>
  .title-pokes {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: left;
    white-space: nowrap;
    flex-shrink: 0;

    &__container {
      align-items: flex-start;
      flex-shrink: 0;
    }

    &__main-text {
      font-size: $font-size-xs;
      text-align: left;
      line-height: 15px;
      color: $color-blue-dodger;
      margin-bottom: 3px;
    }

    &__message-text {
      font-weight: $font-weight-light;
      font-size: $font-size-xs;
      text-align: left;
      line-height: 15px;
      color: $color-blue-dark-cello;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 177px;
      overflow: hidden;
      margin-bottom: 3px;
      @include bp('tablet') {
        max-width: 585px;
      }

      &--regular {
        font-size: $font-size-xs;
      }
    }

    &__hour {
      font-weight: $font-weight-light;
      font-size: $font-size-xxs;
      line-height: 14px;
      color: $color-blue-dark-cello;

      &--regular {
        font-size: $font-size-xs;
      }
    }

    &__logo {
      width: 13px;
      height: 13px;
      margin-right: 5px;
    }
  }

  .icon-paper-plane {
    font-size: $font-size-xs;
    color: $color-blue-dark-cello;
    opacity: 0.5;
    margin-right: 5px;
  }
</style>
