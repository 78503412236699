import moment from 'moment/min/moment-with-locales';
import { macros } from "@/macros/processes/formDeal";

export const validateMaxDealtAt = (dealtAt) => {
  const today = moment();

  if (moment(dealtAt) < today) {
    return true;
  }

  throw Error('La date de deal ne peut pas être dans le futur !');
};
export const validateDateToSend = (dateToSend) => {
  const validDateToSend = [macros.dateToSend.start.frontValue, macros.dateToSend.acceptation.frontValue];

  if (validDateToSend.includes(dateToSend)) {
    return true;
  }

  throw Error('La date de facturation n\'est pas valide !');
};

export const validateDealSources = (dealSources) => {
  const validDealSources = [
    macros.dealSources.huntBC,
    macros.dealSources.huntLinkedIn,
    macros.dealSources.subscriptionBC,
    macros.dealSources.network,
    macros.dealSources.cooptation,
    macros.dealSources.network,
  ];

  if (validDealSources.includes(dealSources)) {
    return true;
  }

  throw Error('La provenance du deal est incorrecte !');
};

export const validatePassType = (passType) => {
  const validatePassType = [macros.passTypes.in.frontValue, macros.passTypes.out.frontValue];

  if (validatePassType.includes(passType)) {
    return true;
  }

  throw Error('Le type de cette passe n\'est pas valide !');
};

export default {
  validateMaxDealtAt,
  validateDateToSend,
  validateDealSources,
  validatePassType,
};

