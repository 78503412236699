import { http } from '@/api/index';

export const getIndustries = () => {
  return http.get('/industries');
};

export const getIndustriesAutocomplete = ({ name }) => {
  return http.get('/industries/autocomplete', {
    params: {
      query: name,
    }
  });
}

export const getIndustrySkills = id => {
  return http.get(`/industries/${id}/skills`);
};

export const getIndustryDomains = id => {
  return http.get(`/industries/${id}/domains`);
};

export const getIndustryProfessions = id => {
  return http.get(`/industries/${id}/professions`);
};

export const createIndustry = ({ name }) => {
  return http.post('/industries', { name });
};

export const getIndustryProfile = id => {
  return http.get(`/industries/${id}`);
};

export default {
  getIndustries,
  createIndustry,
  getIndustryProfile,
  getIndustrySkills,
  getIndustryDomains,
};
