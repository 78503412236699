<template>
  <bc-dropdown
    class="database-coders-button-create is-secondary"
    position="bottom-center">
    <template #label="{ isDropdownOpen }">
      <bc-button
        :icon-right="isDropdownOpen ? 'chevron-down' : 'chevron-right'"
        class="database-coders-button-create__button"
        icon-left="candidates-plus">
        {{ $t('generics.create-a-candidate') }}
      </bc-button>
    </template>
    <template #custom>
      <div
        class="database-coders-button-create__options is-column is-full-width">
        <a
          href="https://www.linkedin.com/search/results/people/?origin=GLOBAL_SEARCH_HEADER&sid=aSM"
          target="_blank">
          <button class="database-coders-button-create__link font-size-s">
            {{ $t('generics.create-with-linkedin') }}
            <i v-if="isCreationLocked" class="icon-lock ml-2"></i>
          </button>
        </a>
        <input
          ref="uploadButton"
          accept=".xlsx, .xls, .csv, text/plain"
          class="hidden-xs hidden-md hidden-sm"
          type="file"
          @change="uploadFile"/>
        <button
          class="database-coders-button-create__link font-size-s"
          @click="handleDropCVFeature">
          {{ $t('generics.create-from-cv') }} <i v-if="!configurationFeatures.candidateDropCV || isCreationLocked" class="icon-lock ml-2"></i>
        </button>
        <button
          class="database-coders-button-create__link font-size-s"
          @click="goToNewCoder">
          {{ $t('generics.manually-create') }}
          <i v-if="isCreationLocked" class="icon-lock ml-2"></i>
        </button>
      </div>
    </template>
  </bc-dropdown>
</template>

<script>
  import upload from '@/api/upload';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
  import { mapActions, mapGetters } from 'vuex';
  import { Modals } from '@/store/modules/app'

  export default {
    name: 'database-coders-button-create',
    components: { BcDropdown, BcButton },
    data() {
      return {
        Modals,
      }
    },
    computed: {
      ...mapGetters({
        configurationFeatures: 'user/configurationFeatures',
        configurationDb: 'user/configurationDb',
        ressourceCount: 'metadata/ressourceCount',
      }),
      isCreationLocked() {
        return !this.configurationFeatures.candidatesLimitUnlocked && this.ressourceCount.candidate >= this.configurationDb.candidatesLimit;
      },
    },
    methods: {
      ...mapActions([
        'setEditedProfile',
      ]),
      ...mapActions('app', ['setModal', 'setParams']),
      checkLimit() {
        if (!this.isCreationLocked) {
          return false;
        }
        this.setParams({ feature: 'candidatesLimitUnlocked' });
        this.setModal(Modals['modal-feature-db-limit']);
        return true;
      },
      goToNewCoder() {
        const limitReached = this.checkLimit();
        if (limitReached) return;

        this.setEditedProfile({});

        this.$router.push({
          name: `${this.$route.name}Panel`,
          params: {
            id: 'new-coder',
          },
          query: {
            ...this.$route.query,
            type: 'create-coder',
          },
        });
      },
      triggerButton() {
        this.$refs.uploadButton.click();
      },
      handleDropCVFeature() {
        const limitReached = this.checkLimit();
        if (limitReached) return;

        if (!this.configurationFeatures.candidateDropCV) {
          this.setModal(Modals['modal-feature-drop-cv']);
        } else {
          this.setModal(Modals.uploadCodersResume);
        }
      },
      async uploadFile(e) {
        if (e.target.files[0]) {
          try {
            this.file = e.target.files[0];

            const dataToSend = new FormData();

            dataToSend.append('file', this.file);
            dataToSend.append('uploadType', 'onboarding_data');

            await upload.upload({
              id: this.profile._id,
              file: dataToSend,
            });
            this.$toast.show({
              type: 'success',
              title: this.$i18n.t('toast.congratulations'),
              message: this.$i18n.t('toast.file-received-for-bdd-integration'),
            });
            if (!this.profile.isDataImported) {
              await this.editProfile({ isDataImported: true });
            }
          } catch (error) {
            this.$toast.show({
              message: this.$t('toast.error-occured'),
              type: 'error',
              icon: 'cross',
            });
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .database-coders-button-create {
    max-width: 100%;

    :deep() {
      .bc-dropdown__menu {
        justify-content: center;
      }

      .bc-dropdown__content {
        flex-grow: 0;
      }
    }

    &__wrapper {
      justify-content: space-around;
      flex-grow: 1;
    }

    &__button {
      min-width: 210px;

      :deep() {
        .bc-button__icon-right {
          font-size: $font-size-xs;
        }
      }
    }

    &__options {
      width: 100%;
      padding: 5px 0 5px 40px;
      font-size: $font-size-s;
      position: relative;
      background: $color-white;
      box-shadow: 0 3px 6px #00000029;
      min-width: 210px;

      &:before {
        content: '';
        position: absolute;
        width: 1px;
        margin: 0 auto;
        top: 12.5px;
        height: calc(100% - 25px);
        background: rgba($color-secondary, 0.4);
        left: 20px;
      }
    }

    &__link {
      color: $color-secondary;
      padding: 5px;
      cursor: pointer;

      &--error {
        color: $color-error;
      }

      &:hover {
        color: $color-marvin;
      }
    }
  }
  .icon-lock {
    color: $color-warning-dark;
  }
</style>
