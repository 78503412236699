import { createDomain } from '@/managers/domains/useCases/createDomain';
import { editDomain } from '@/managers/domains/useCases/editDomain';
import { deleteDomain } from '@/managers/domains/useCases/deleteDomain';

const create = ({ name }) => {
  return createDomain({ name });
};

const edit = ({ id, name }) => {
  return editDomain({ id, name });
};

const remove = id => {
  return deleteDomain(id);
};

export const domainController = {
  create,
  edit,
  remove,
};

export default {
  domainController,
};
