<template>
  <Views class="pool is-column is-full-width is-align-items-center">
    <RemindersTabs />
    <ViewsContainer>
      <RemindersFilters
        :coach-dropdown="coachesOptions"
        :metadata="metadata"
        class="pool__filters" />
      <RemindersCardList :is-loading="isLoading" />
      <InfiniteLoading
        :distance="50"
        :identifier="infiniteIdentifier"
        style="justify-content: center"
        @infinite="getCandidates">
        <BcSpinner slot="spinner" />
        <p slot="no-more">
          {{ cards.length ? '' : $t('generics.empty-list') }}
        </p>
      </InfiniteLoading>
    </ViewsContainer>
    <router-view />
  </Views>
</template>

<script setup lang="ts">
import axios from 'axios';
import RemindersCardList from '@/components/Reminders/RemindersCardList.vue';
import RemindersFilters from '@/components/Reminders/RemindersFilters.vue';
import { getSuggestionsByParams } from '@/api/suggestions';
import { getFilterablePoolCoach } from '@/macros/prospectionPipe/dropdowns';
import Views from '@/components/Views/Views.vue';
import ViewsContainer from '@/components/Views/ViewsContainer.vue';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
import InfiniteLoading from 'vue-infinite-loading';
import RemindersTabs from '@/views/Tabs/RemindersTabs.vue';
import { useMultiselect } from '@/composables/useMultiselect';
import { useToast } from '@/ui-kit/components/BcToast';
import { ref, computed, watch, onBeforeUnmount, onMounted } from 'vue';
import { useStore } from '@/store';
import { useRoute, useRouter } from 'vue-router/composables';

const toast = useToast();
const store = useStore();
const route = useRoute();
const router = useRouter();
const { resetSelectedCards } = useMultiselect();
const infiniteIdentifier = ref(+new Date());
const isLoading = ref(false);
const page = ref(1);
const cancelTokenSource = ref();

onBeforeUnmount(() => {
  cancelTokenSource.value.cancel('manualCancel');
  store.dispatch('resetCards');
});

onMounted(async () => {
  cancelTokenSource.value = axios.CancelToken.source();
  resetSelectedCards();
  await store.dispatch('resetCards');
  getSuggestionsMetadata();
  if (coachesOptions.value.length > 0) {
    const coach =
      coachesOptions.value.find(c => c._id.toString() === profile.value._id.toString()) ||
      coachesOptions.value[0];

    router
      .push({
        query: {
          ...route.query,
          'coach-id': coach._id,
        },
      })
      .catch(() => {});
  }
});

const coaches = computed(() => store.state.coaches.coaches);
const profile = computed(() => store.state.user.profile);
const metadata = computed(() => ({
  inbox: store.state.reminders.suggestionsInbox.total,
  recommendations: store.state.reminders.suggestionsRecommendations.total,
  reminders: store.state.reminders.suggestionsReminder.total,
}));
const cards = computed(() => store.state.card.cards);
const coachesOptions = computed(() => {
  return coaches.value
    .filter(coach => getFilterablePoolCoach(coach))
    .sort((a, b) => a.firstName.localeCompare(b.firstName));
});

function setCoachRessourceCount({ ressourceType, count }) {
  store.dispatch('metadata/setCoachRessourceCount', { ressourceType, count });
}

async function getCandidates($state) {
  try {
    const filter = route.query.filter;
    const coachId = route.query['coach-id'];
    const { data: suggestions } = await getSuggestionsByParams({
      filter,
      coachId,
      reminderType: 'candidate',
      page: page.value,
      cancelTokenSource: cancelTokenSource.value,
    });
    const hasNext = suggestions.totalPage > page.value;
    if (hasNext) {
      if ($state) {
        $state.loaded();
      }
      page.value = page.value + 1;
    } else if ($state) {
      $state.loaded();
      $state.complete();
    }

    await store.dispatch('setCards', suggestions.items);

    isLoading.value = false;
  } catch (e) {
    if (e.message === 'manualCancel') {
      return;
    }
    toast.show({
      type: 'error',
      message: e.message,
    });
  }
}

async function getSuggestionsMetadata() {
  const coachId = route.query['coach-id'];
  await store.dispatch('getRemindersSuggestions', { coachId, reminderType: 'candidate' });
  setCoachRessourceCount({ ressourceType: 'candidateReminder', count: metadata.value.reminders });
}

watch(
  () => route.query,
  (to, from) => {
    if (to && from && (to.type || from.type || JSON.stringify(to) === JSON.stringify(from))) {
      return;
    }

    if (!route.query['coach-id'] && coachesOptions.value.length > 0) {
      const coach =
        coachesOptions.value.find(c => c._id.toString() === profile.value._id.toString()) ||
        coachesOptions.value[0];

      router
        .push({
          query: {
            ...route.query,
            'coach-id': coach._id,
          },
        })
        .catch(() => {});
    }
  },
  { deep: true, immediate: true },
);

async function resetInfiniteLoading() {
  page.value = 1;
  infiniteIdentifier.value = +new Date();
  resetSelectedCards();
  await store.dispatch('resetCards');
}

watch(
  () => route.query.filter,
  async () => {
    resetInfiniteLoading();
  },
);

watch(
  () => route.query.type,
  async () => {
    getSuggestionsMetadata();
    resetInfiniteLoading();
  },
);
</script>

<style lang="scss" scoped>
.pool {
  position: relative;
  @include page_grid();

  &__wrapper {
    width: 100%;
  }

  &__title {
    @include font-bold(22px);
    line-height: 25px;
    color: $color-secondary;
    text-align: center;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 19px;
    color: $color-secondary;
    text-align: center;
  }
}
</style>
