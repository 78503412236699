<template>
  <mv-filter class="filter-companies-more" label="+ de critères" align="right" :count="totalCount">
    <div class="filter-companies-more__container">
      <div class="filter-companies-more__divider">
        <FilterUniqueid @count="updateCount" />
      </div>
      <div v-if="customFieldsFilters.length" class="filter-companies-more__divider">
        <filter-companies-custom-fields @count="updateCount" :custom-fields-filters="customFieldsFilters" ></filter-companies-custom-fields>
      </div>
    </div>
  </mv-filter>
</template>

<script>
import { mapGetters } from 'vuex';
import MvFilter from '@/ui-kit/components/MvFilter/MvFilter';
import FilterCompaniesCustomFields from '@/components/Filter/Companies/FilterCompaniesCustomFields.vue';
import FilterUniqueid from '@/components/Filter/FilterUniqueid.vue';

export default {
  name: 'filter-companies-more',
  components: {
    MvFilter,
    FilterCompaniesCustomFields,
    FilterUniqueid,
  },
  data() {
    return {
      count: {},
    };
  },
  computed: {
    ...mapGetters({
      configurationCompany: 'user/configurationCompany',
    }),
    totalCount() {
      return Object.keys(this.count).reduce((acc, key) => {
        return acc + this.count[key];
      }, 0);
    },
    salaryType() {
      return this.$route.query.salaryType;
    },
    customFieldsFilters() {
      return this.configurationCompany.customFields?.filter(
        customField => customField.showFilter && customField.key !== 'remote'
      );
    },
  },
  methods: {
    updateCount(count) {
      this.count = {
        ...this.count,
        [count.key]: count.count,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-companies-more {
  &__container {
    flex-direction: column;
    min-width: 581px;
  }

  &__divider {
    border-bottom: 1px solid $color-blue-light;
    width: 100%;
  }
}
</style>
