<template>
  <bc-dropdown class="is-align-items-stretch panel-header-link-generator">
    <template #label="{ isDropdownOpen }">
      <button
        :class="{ 'bg-neutral-200 !border-neutral-200': isDropdownOpen }"
        class="!h-[32px] rounded-full !px-[10px] gap-2.5 flex items-center text-sm !pr-[20px] !pl-[20px] border border-blue-200 text-blue-500"
        @click="handleClickGenerateLink">
        <i class="icon-arrow-up-from-bracket"></i>
        <span>{{ $t('generics.share') }}</span>
      </button>
    </template>
    <template #custom>
      <div
        v-if="configurationFeatures.shareProcesses"
        class="panel-header-link-generator__dropdown is-relative is-full-width is-column pb-4 pt-4 pl-5 pr-5"
        @click.stop>
        <p class="panel-header-link-generator__text body-m">
          {{ $t('share-link') }}
        </p>
        <bc-input
          :value="generatedLink"
          class="panel-header-link-generator__input"
          name="link"
          type="text">
          <template #append="{ isFocus }">
            <div
              v-clipboard:copy="generatedLink"
              v-clipboard:success="setIsCopiedLink"
              v-tooltip="{
                content: isCopiedLink
                  ? $t('companies.account-management.link-copied')
                  : $t('companies.account-management.copy-link'),
                hideOnTargetClick: false,
              }"
              :class="{ 'panel-header-link-generator__prepend--focused': isFocus }"
              class="panel-header-link-generator__prepend icon-clipboard pr-2 pl-2 is-flex is-align-items-center">
            </div>
          </template>
        </bc-input>
      </div>
    </template>
  </bc-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { Modals } from '@/store/modules/app';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
import BcInput from '@/ui-kit/components/BcInput/BcInput';

export default {
  name: 'panel-header-link-generator',
  components: { BcInput, BcDropdown },
  i18n: {
    messages: {
      en: {
        'share-link': 'Share link',
      },
      fr: {
        'share-link': 'Lien de partage',
      },
    },
  },
  props: {
    company: {
      type: Object,
      default: () => ({}),
    },
    job: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isCopiedLink: false,
    };
  },
  computed: {
    ...mapGetters({
      configurationFeatures: 'user/configurationFeatures',
    }),
    generatedLink() {
      const subDomain = window.location.origin.split('.')[0].split('//')[1];
      const replaceDomain = subDomain == "app" ? "partners" : `partners.${subDomain}`;
      let partnerUrl = window.location.origin.replace(subDomain, replaceDomain);
      if (subDomain.includes('localhost')) {
        partnerUrl = 'http://localhost:8086';
      }

      let url = `${partnerUrl}/${this.company.publicToken}`;
      if (this.job._id) {
        url += `/${this.job._id}`;
      }
      return url;
    },
  },
  methods: {
    ...mapActions({
      setModal: 'app/setModal',
    }),
    handleClickGenerateLink() {
      if (!this.configurationFeatures.shareProcesses) {
        this.setModal(Modals['modal-feature-share-processes']);
      }
    },
    setIsCopiedLink() {
      this.isCopiedLink = true;
      setTimeout(() => {
        this.isCopiedLink = false;
      }, 2300);
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-header-link-generator {
  &__dropdown {
    width: 341px;
  }

  &__text {
    color: $color-tertiary;
  }

  &__prepend {
    cursor: pointer;
    color: $color-tertiary;
    border-left: 1px solid $color-blue-medium;
    height: 100%;
    border-top-right-radius: $border-radius-xs;
    border-bottom-right-radius: $border-radius-xs;
    min-height: 41px;

    &--focused {
      border-color: $color-primary;
    }
  }

  & :deep() {
    .bc-input {
      margin-top: 5px;
    }

    input {
      padding-right: 10px;
      text-overflow: ellipsis;
    }
  }
}
</style>
