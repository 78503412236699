<script setup>
  import { usePanelNavigation } from '@/composables/usePanelNavigation';
  import { useRoute } from 'vue-router/composables';
  import { watch } from 'vue';
  import { useQuery } from '@tanstack/vue-query';
  import companyApi from '@/api/company';
  import PanelCompanyHeader from '@/components/Panel/PanelCompany/PanelCompanyHeader.vue';
  import { useToast } from '@/ui-kit/components/BcToast';
  import PanelCompanyNotes from '@/components/Panel/PanelCompany/PanelCompanyNotes.vue';
  import PanelCompanyProfile from '@/components/Panel/PanelCompany/PanelCompanyProfile.vue';
  import PanelCompanyModal from '@/components/Panel/PanelCompany/PanelCompanyModal.vue';

  const route = useRoute();
  const { closePanel } = usePanelNavigation();
  const toast = useToast();
  const manageClosePanel = (event) => {
    if (event.target?.className === 'icon-close' || event.target?.className === 'overlay') {
      closePanel();
    }
  };

  const { data: profile, refetch } = useQuery({
    queryKey: ['GET_COMPANY_PROFILE', route.params.id],
    queryFn: async() => {
      try {
        const { data } = await companyApi.getPartnerProfile(route.params.id);

        return data;
      } catch (e) {
        toast.show({
          type: 'error',
          message: 'An error occured',
        });

        console.error(e);
      }
    },
  });
  watch(
    () => route.params.id,
    async() => {
      refetch();
    },
  );
</script>

<template>
  <div
    v-click-outside="manageClosePanel"
    class="flex max-h-full shrink-0 grow flex-col items-center justify-center"
  >
    <PanelCompanyHeader
      :profile="profile"
      class="shrink-0"
      @profile-updated="refetch"
    />
    <div class="flex max-h-[calc(100%-50px)] w-full grow">
      <div class="flex w-1/2 flex-col">
        <PanelCompanyProfile
          :profile="profile"
          @update-company="refetch"/>
      </div>
      <div class="flex w-1/2 flex-col overflow-y-auto bg-neutral-100">
        <PanelCompanyNotes :profile="profile"/>
      </div>
    </div>
    <PanelCompanyModal/>
  </div>
</template>

