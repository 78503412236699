export const salary = {
  salaryMin: 0,
  salaryMinCDIEdit: 25,
  salaryMinFreelanceEdit: 200,
  salaryMinFilterCDI: 25,
  salaryMinFilterFreelance: 200,
  salaryMax: 200,
  salaryMaxCDIEdit: 150,
  salaryMaxFilter: 100,
  salaryMaxFilterFreelance: 1500,
  salaryMaxFreelanceEdit: 1500

};

export default salary;
