<template>
  <div class="panel-job-caption">
    <placeholder-panel-carousel v-if="isLoading">
    </placeholder-panel-carousel>
    <div
      v-else
      class="panel-job-caption__container">
      <picture-avatar
        :imgSrc="profile._company ? profile._company.logoUrl : ''"
        class="panel-job-caption__picture">
      </picture-avatar>
      <div class="panel-job-caption__infos col-xs-12">
        <div class="panel-job-caption__caption">
          <div class="panel-job-caption__name-wrapper">
            <p
              v-if="profile._company"
              class="panel-job-caption__title panel-job-caption__name"
              @click="openPanel({ id: profile._company._id, type: 'companies' })">
              {{ profile._company.name }}
            </p>
            <panel-caption-name
              :beforeSeparator="name"
              :buttons="true"
              :remote="profile.remote"
              class="panel-job-caption__name">
              <panel-caption-contacts
                :profile="profile"
                :contacts="contacts"
                :icons="icons"
                @copy="setIsCopied($event)"
                @mouseHover="icons[$event].isHover = true"
                @mouseOut="icons[$event].isHover = false">
              </panel-caption-contacts>
            </panel-caption-name>
          </div>
          <bc-tag
            v-if="role"
            bc-background-color="blue-eastern"
            bc-text-color="white"
            class="panel-job-caption__role">
            {{ $t(role) }}
          </bc-tag>
          <bc-tag
            v-if="domains && domains.length > 0"
            bc-background-color="purple-dark"
            bc-text-color="white"
            class="panel-job-caption__domains">
            {{ $t(domains[0]) }}
          </bc-tag>
          <bc-tag
            v-if="domains && domains.length > 1"
            v-tooltip="{ content: domainsRemaining }"
            bc-background-color="purple-dark"
            bc-text-color="white"
            class="panel-job-caption__domains">
            +{{ domains.length - 1 }}
          </bc-tag>
        </div>
        <panel-caption-icons :infos="infos"></panel-caption-icons>
      </div>
    </div>
  </div>
</template>

<script>
  import PanelCaptionName from '@/components/Panel/PanelCaption/PanelCaptionName';
  import PanelCaptionIcons from '@/components/Panel/PanelCaption/PanelCaptionIcons';
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import PictureAvatar from '@/components/UIKit/Picture/PictureAvatar';
  import PlaceholderPanelCarousel from '@/components/UIKit/Placeholder/PlaceholderPanel/PlaceholderPanelCarousel';
  import PanelCaptionContacts from '@/components/Panel/PanelCaption/PanelCaptionContacts';
  // helpers
  import JobHelpers from '@/common-old/mixins/Jobs/jobHelpers';
  import sizes from '@/common-old/macros/sizes';
  import { roles } from '@/common-old/macros/roles';
  import { getDomainFromApiValue } from '@/common-old/macros/market';
  import BcTag from '@/legacy/ui-kit/components/Tag/BcTag';
import { getCompleteAddressOrTag } from '@/formatters/locations';

  /**
   * It's the caption which will be use each time we want to display a job inside the panel
   */

  export default {
    name: 'panel-job-caption',
    components: {
      BcTag,
      PanelCaptionContacts,
      PlaceholderPanelCarousel,
      PictureAvatar,
      PanelCaptionName,
      PanelCaptionIcons,
    },
    mixins: [PanelNavigation, JobHelpers],
    props: {
      /**
       * The profile of the job
       * @param state
       */
      profile: {
        type: Object,
        default: () => {
          return {};
        },
      },
      /**
       * Determinate if the content loading should be displayed
       */
      isLoading: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        icons: [
          {
            isHover: false,
            isCopied: false,
          },
        ],
        sizes,
      };
    },
    computed: {
      name() {
        return this.profile.name ? this.profile.name : '';
      },
      role() {
        return this.profile.role ? Object.entries(roles).find(m => m[1].api === this.profile.role)[1].value : '';
      },
      domains() {
        return this.profile.domains ? this.profile.domains.map(domain => getDomainFromApiValue(domain).name) : [];
      },
      domainsRemaining() {
        return this.domains.slice(1).join(', ');
      },
      publicLink() {
        if (this.profile?._id) {
          let company = this.profile?._company?.name
            .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .split(' ');

          let slugName = this.profile.name
            .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .split(' ');
          slugName = encodeURIComponent(
            slugName
              .filter(item => {
                return item.length > 2;
              })
              .join('-'),
          );
          // eslint-disable-next-line no-unused-vars
          let slugArr = `${company}-${slugName}-${this.$route.params.id}`;
          return `${window.location.origin}/jobs/${this.$route.params.id}`.toLowerCase();
        }

        return '';
      },
      infos() {
        return [
          {
            icon: 'map-pin',
            value: getCompleteAddressOrTag(this.profile.locations?.[0]) || ''
          },
          {
            icon: 'diploma',
            value: this.getJobField('experience', 'value', this.profile.experience),
            label: '',
          },
          {
            icon: 'dollars',
            value: this.profile && this.profile.salary ? this.computeJobSalary(this.profile) : '',
            label: '',
          },
          {
            icon: 'users',
            value: this.profile._company && this.profile._company.size ? this.$i18n.t(sizes[this.profile._company.size].name ): this.sizes[0].name,
          },
        ];
      },
      contacts() {
        return [
          {
            icon: 'external-link',
            data: this.publicLink,
            type: 'copy',
          },
        ];
      },
    },
    methods: {
      setIsCopied(index) {
        this.icons[index].isCopied = true;
        setTimeout(() => {
          this.icons[index].isCopied = false;
        }, 2300);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-job-caption {
    flex-shrink: 0;

    &__container {
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      @include bp('tablet') {
        flex-direction: row;
      }
    }

    &__infos {
      align-items: center;

      @include bp('tablet') {
        align-items: flex-start;
      }
    }

    &__picture {
      margin-bottom: 20px;

      @include bp('tablet') {
        margin-bottom: 0;
        margin-right: 20px;
      }
    }

    &__caption {
      align-items: center;
      margin-bottom: 20px;

      @include bp('tablet') {
        margin-bottom: 10px;
      }
    }

    &__name {
      justify-content: center;
      align-items: center;

      @include bp('tablet') {
        width: 100%;
        justify-content: flex-start;
        flex-direction: row !important;
      }

      &-wrapper {
        flex-direction: column;
        margin-right: 10px;
      }
    }

    &__title {
      width: fit-content;
      color: $color-blue;
      font-size: $font-size-m;
      margin-bottom: 5px;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &__role, &__domains {
      justify-content: center;
      align-items: center;
      margin: 0 5px;
      padding: 7.5px 15px;
      border-radius: 20px;
      white-space: nowrap;
      font-size: $font-size-xs;

      &:first-child {
        margin-left: 0;
      }
    }

    &__loading {
      margin: 0 70px;

      @include bp('tablet') {
        margin: 0 20px;
      }
    }
  }
</style>
