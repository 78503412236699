<template>
  <div
    class="panel-favorite-list-share"
    @click.stop>
    <title-section
      class="panel-favorite-list-share__title"
      position="left"
      :title="$t('generics.share')">
    </title-section>
    <!-- -->
    <div
      v-tooltip="dropdownTooltip"
      class="panel-favorite-list-share__autocomplete">
      <bc-autocomplete
        v-model="coachInput"
        :disabled="isCurrentUserReader"
        :label-placeholder="false"
        :options="filteredCoaches"
        class="is-full-width"
        keep-first
        keep-open
        name="coach"
        open-on-focus
        :placeholder="$t('companies.panel-company.write-a-colleague-name-or-email')"
        @select="addSubscriber($event), coachInput = ''">
        <template #option="{ option }">
          <bc-dropdown-item
            :info="option.email"
            :text="`${option.firstName} ${option.lastName}`"
            class="fade-select-leave-active__option font-size-s is-full-width">
          </bc-dropdown-item>
        </template>
      </bc-autocomplete>
    </div>
    <!-- -->
    <div class="panel-favorite-list-share__container">
      <div class="panel-favorite-list-share__row">
        <bc-avatar
          :bc-img-src="currentOwner.pictureUrl"
          bc-avatar-size="36px"
          bc-img-alt="owner avatar"
          class="panel-favorite-list-share__avatar">
        </bc-avatar>

        <div class="panel-favorite-list-share__text-container">
          <p class="panel-favorite-list-share__text-black font-weight-bold mb-1">
            {{ currentOwner.firstName }} {{ currentOwner.lastName }}
            {{ currentOwner._id === currentCoach._id ? $i18n.t('companies.panel-company.(you)') : '' }}
          </p>

          <p class="panel-favorite-list-share__text-grey">
            {{ currentOwner.email }}
          </p>
        </div>

        <p class="panel-favorite-list-share__text-grey">
          {{ $t('companies.panel-company.owner') }}
        </p>

        <div
          v-tooltip.bottom="crossTooltip"
          class="panel-favorite-list-share__owner-remove-container">
          <bc-button
            v-if="isOwnerDeletable"
            bc-background-color="transparent"
            bc-icon="cross"
            bc-icon-size="16px"
            bc-opacity="0.5"
            bc-text-color="dark-blue-cello"
            bc-text-color-hover="black"
            class="panel-favorite-list-share__remove-owner"
            data-cross-owner
            v-on:[clickEvent].native="removeSubscriberAndDeleteList">
          </bc-button>
        </div>
      </div>

      <bc-divider
        :bc-divider-color-opacity="0.3"
        bc-divider-color="blue-dodger">
      </bc-divider>

      <p class="body-m mt-2">
        {{ shareText }}
      </p>

      <div
        v-for="subscriber in currentSubscribers"
        :key="subscriber._id"
        class="panel-favorite-list-share__item">
        <div class="panel-favorite-list-share__item-container">
          <bc-avatar
            :bc-img-src="subscriber.pictureUrl"
            bc-avatar-size="36px"
            bc-img-alt="owner avatar"
            class="panel-favorite-list-share__avatar">
          </bc-avatar>

          <div class="panel-favorite-list-share__text-container">
            <p class="panel-favorite-list-share__text-black">
              {{ (subscriber || {}).firstName }} {{ (subscriber || {}).lastName.toUpperCase() }}
              {{ subscriber._id === currentCoach._id ? $i18n.t('companies.panel-company.(you)') : '' }}
            </p>

            <p class="panel-favorite-list-share__text-grey">
              {{ subscriber.email }}
            </p>
          </div>

          <bc-dropdown
            v-if="shouldDisplayDropdown(subscriber)"
            :options="setUserRights(subscriber)"
            class="panel-favorite-list-share__dropdown"
            data-dropdown-role
            is-popper
            @change="changeSubscriberRole($event, subscriber)">
            <template #label="{ isDropdownOpen }">
              <div class="is-column is-expanded">
                <bc-dropdown-trigger
                  :is-arrow="true"
                  :is-dropdown-open="isDropdownOpen"
                  :placeholder="roleMap[subscriber.role - 1]"
                  class="panel-favorite-list-share__trigger font-size-s">
                </bc-dropdown-trigger>
              </div>
            </template>
            <template #option="{ option, index, activeIndex  }">
              <bc-dropdown-item
                :is-focus="activeIndex === index"
                :text="option.name"
                class="panel-favorite-list-share__options is-expanded font-size-s"
                @change="changeSubscriberRole(option, subscriber)"
                @click.native="changeSubscriberRole(option, subscriber)">
              </bc-dropdown-item>
            </template>
          </bc-dropdown>

          <p v-else class="panel-favorite-list-share__text-grey">
            {{ roleMap[subscriber.role - 1] }}
          </p>

          <bc-button
            v-if="shouldDisplayCross(subscriber)"
            :bc-text-color-opacity="0.5"
            :bc-text-color-opacity-hover="1"
            bc-background-color="transparent"
            bc-icon="cross"
            bc-icon-size="16px"
            bc-text-color="blue-dark-cello"
            bc-text-color-hover="blue-dark-cello"
            class="panel-favorite-list-share__remove"
            data-cross-role
            @click.stop.native="removeSubscriber(subscriber)">
          </bc-button>
        </div>

        <bc-divider
          :bc-divider-color-opacity="0.3"
          bc-divider-color="blue-dodger">
        </bc-divider>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  // macros
  import favoriteListsMacros from '@/macros/favoriteLists/favoriteLists';
  //
  import BcAvatar from '@/legacy/ui-kit/components/Avatar/BcAvatar';
  import BcButton from '@/legacy/ui-kit/components/Button/BcButton';
  import BcDivider from '@/legacy/ui-kit/components/Dividers/BcDivider';
  import BcDropdown from '@/ui-kit/components/BcDropdown';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
  import TitleSection from '@/components/Title/TitleSection';
  import { apiRoles } from '@/macros/coaches/roles';


  export default {
    name: 'panel-favorite-list-share',
    components: {
      BcDropdownTrigger,
      BcDropdownItem,
      BcAutocomplete,
      BcDropdown,
      BcDivider,
      BcButton,
      BcAvatar,
      TitleSection,
    },
    props: {
      listSubscribers: {
        type: Array,
        default: () => [],
      },
      lockedList: {
        type: Boolean,
        default: false,
      },
      editedListSubscribers: {
        type: Array,
        default: () => [],
      },
      currentCoach: {
        type: Object,
        default: () => ({}),
      },
      currentOwner: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        coachInput: '',
        roleMap: [this.$i18n.t('companies.panel-company.owner'), this.$i18n.t('companies.panel-company.administrator'), this.$i18n.t('companies.panel-company.lector')],
      };
    },
    computed: {
      ...mapState({
        coaches: state => state.coaches.coaches,
      }),
      currentSubscribers() {
        return this.editedListSubscribers.filter(subscriber => subscriber.role !== favoriteListsMacros.apiRights.owner);
      },
      shareText() {
        return this.currentSubscribers.length > 0 ? (this.$i18n.t('companies.panel-company.share-with') + ':') : this.$i18n.t('companies.panel-company.you-have-not-shared-this-list');
      },
      availableCoaches() {
        return this.coaches.filter(coach => coach.role && coach.role !== apiRoles.guest);
      },
      filteredCoaches() {
        return this.matchedCoaches.filter(coach => this.currentSubscribers.map(coachOnList => coachOnList.email).indexOf(coach.email) < 0 && !!coach.enabled);
      },
      matchedCoaches() {
        const isCoachCurrentCoach = coach => coach._id !== this.currentCoach._id;
        const isCoachCurrentOwner = coach => coach._id !== this.currentOwner._id;
        const isCoachEmailMatchWithInput = coach => coach.email.toLowerCase().indexOf(this.coachInput.toLowerCase()) >= 0;

        return (this.availableCoaches || []).filter(coach => isCoachCurrentCoach(coach) && isCoachCurrentOwner(coach) && isCoachEmailMatchWithInput(coach));
      },
      isOwnerDeletable() {
        return this.$route.query.subtype === 'edit-favorite-list' &&
          this.currentOwner._id === this.currentCoach._id &&
          !this.lockedList;
      },
      isCurrentUserReader() {
        return this.currentCoach.role === favoriteListsMacros.apiRights.reader;
      },
      dropdownTooltip() {
        return {
          content: this.isCurrentUserReader ? this.$i18n.t('companies.panel-company.only-administrators-can-share-list') : '',
        };
      },
      crossTooltip() {
        return {
          content: this.isOwnerDeletable && this.listSubscribers.length > 1 ? this.$i18n.t('companies.panel-company.name-a-new-owner-before-leaving-your-own-list') : '',
        };
      },
      clickEvent() {
        return this.isOwnerDeletable && this.listSubscribers.length === 1 ? 'click' : null;
      },
    },
    methods: {
      addSubscriber(coach) {
        const newSubscriber = {
          ...coach,
          role: favoriteListsMacros.apiRights.reader,
        };

        this.$emit('add-subscriber', newSubscriber);
      },
      removeSubscriber(subscriber) {
        if (subscriber._id !== this.currentCoach._id) {
          this.$emit('remove-subscriber', subscriber);
        } else {
          this.$emit('leave-list', subscriber);
        }
      },
      removeSubscriberAndDeleteList() {
        this.$emit('remove-subscriber-and-delete-list', this.currentCoach);
      },
      changeSubscriberRole($event, subscriber) {
        const actionMap = {
          owner: this.setToOwner,
          admin: this.setToAdmin,
          reader: this.setToReader,
        };

        actionMap[$event.query](subscriber);
      },
      shouldDisplayCross(subscriber) {
        const isAdded = subscriber.isAdded;
        const isReaderBeforeChanges = this.listSubscribers.some(coach => coach._id === subscriber._id && coach.role === favoriteListsMacros.apiRights.reader);
        const isCurrentUserItem = this.currentCoach._id === subscriber._id;

        return ((isAdded || isReaderBeforeChanges) && !this.isCurrentUserReader) || isCurrentUserItem;
      },
      shouldDisplayDropdown(subscriber) {
        const isAdded = subscriber.isAdded;
        const isReaderBeforeChanges = this.listSubscribers.some(coach => coach._id === subscriber._id && coach.role === favoriteListsMacros.apiRights.reader);
        const isCurrentUserOwner = this.currentCoach.role === favoriteListsMacros.apiRights.owner;
        const isCurrentUserItem = this.currentCoach._id === subscriber._id;

        return (isAdded || isReaderBeforeChanges || isCurrentUserOwner || isCurrentUserItem) && !this.isCurrentUserReader && !this.lockedList;
      },
      setToOwner(subscriber) {
        this.$emit('set-to-owner', subscriber);
      },
      setToAdmin(subscriber) {
        this.$emit('set-to-admin', subscriber);
      },
      setToReader(subscriber) {
        this.$emit('set-to-reader', subscriber);
      },
      setUserRights(subscriber) {
        const owner = {
          name: this.$i18n.t('companies.panel-company.owner'),
          query: 'owner',
        };

        const admin = {
          name: this.$i18n.t('companies.panel-company.administrator'),
          query: 'admin',
        };

        const reader = {
          name: this.$i18n.t('companies.panel-company.lector'),
          query: 'reader',
        };

        const isCreatingList = this.$route.query.subtype === 'create-favorite-list';
        const isCurrentUser = this.currentCoach._id === subscriber._id;
        const isCurrentUserAdmin = this.currentCoach.role === favoriteListsMacros.apiRights.admin;
        const isCurrentUserOwner = this.currentCoach.role === favoriteListsMacros.apiRights.owner;
        const isReaderBeforeChanges = this.listSubscribers.some(coach => coach._id === subscriber._id && coach.role === favoriteListsMacros.apiRights.reader);

        const options = [];

        if (isCreatingList || isCurrentUserOwner) {
          options.push(owner);
        }

        if (isCreatingList || isCurrentUserOwner || isCurrentUserAdmin) {
          options.push(admin);
        }

        if (((isCurrentUserOwner || isCurrentUserAdmin) && isReaderBeforeChanges) || isCreatingList || isCurrentUser) {
          options.push(reader);
        }

        return options;
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-favorite-list-share {
    flex-direction: column;

    &__title {
      margin-top: 30px;
    }

    &__input {
      width: 100%;
    }

    &__autocomplete {
      width: 100%;
    }

    &__container {
      flex-direction: column;
    }

    &__row {
      margin: 20px 0 10px;
      align-items: center;
    }

    &__avatar {
      overflow: hidden;
      margin-right: 15px;
    }

    &__text-container {
      flex-direction: column;
      align-items: flex-start;
      margin-right: auto;
    }

    &__text-black {
      font-size: $font-size-m;
      line-height: 19px;
      color: $color-tertiary;

      &--small {
        font-size: $font-size-xs;
        margin-top: 10px;
      }
    }

    &__text-grey {
      font-size: $font-size-s;
      line-height: 17px;
      color: $color-tertiary;
    }

    &__remove-owner,
    &__remove {
      margin-left: 13.5px;
    }

    &__item {
      flex-direction: column;
    }

    &__item-container {
      margin: 10px 0;
      align-items: center;
    }

    &__dropdown, &__options {
      width: 160px;
    }

    &__owner-remove-container {
      position: relative;
    }
  }
</style>
