<template>
  <component
    :is="panelCoachEditComponent"
    v-bind="$attrs"
    v-on="$listeners">
  </component>
</template>

<script>
  import PanelCoachEditCoach
    from '@/components/Panel/PanelCoach/PanelCoachEdit/PanelCoachEditCoach';
  import PanelCoachEditRpo
    from '@/components/Panel/PanelCoach/PanelCoachEdit/PanelCoachEditRpo';

  export default {
    name: 'panel-coach-edit-caption',
    inheritAttrs: false,
    components: {
      PanelCoachEditRpo,
      PanelCoachEditCoach,
    },
    computed: {
      panelCoachEditComponent() {
        return this.$store.getters['user/isSuperUser'] ? 'panel-coach-edit-coach' : 'panel-coach-edit-rpo';
      },
    },
  };
</script>
