import axios from 'axios';
import { removeToken } from '@/managers/token/useCases/removeToken';
import * as Sentry from "@sentry/vue";

const http = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'ngrok-skip-browser-warning': true,
  },
});

const setup = (store) => {
  http.interceptors.request.use(
    config => {
      let token = localStorage.getItem('Token');

      if (token) {
        config.headers['Token'] = token;
      }
      config.headers['Source-Application'] = 'crm';

      return config;
    });

  http.interceptors.response.use(
    response => response,
    error => {
      if (error?.response?.status === 500 || error?.response?.status === 503) {
        Sentry.captureException(error, {
          extra: {
            response_body: error.response?.data,
            request_body: error.config?.data,
            url: error.config?.url,
            method: error.config?.method,
          },
          tags: {
            status_code: error.response?.status,
            error_type: 'server_error',
            priority: 'high'
          }
        });
      }

      if (error?.response?.status === 401) {
        store.dispatch('token/setIsExpired', true);
        removeToken();
      } else if (error?.response?.status === 409) {
        let message = error?.response?.data?.message;
        error.message = message;
      }
      return Promise.reject(error);
    },
  );
};

export { http, setup };
