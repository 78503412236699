import Vue from 'vue';
import cards from '@/api/cards';
// TODO Renommer ce module en cards à la place de card

export default {
  state: {
    cards: [],
    count: 0,
    totalPages: 0,
    next: false,
    limited: 0,
    previousRequest: '',
    timer: 0,
    cardsToRemoveFromList: [],
    selectedCards: [],
    isRequestProcessing: false,
    sort: {
      field: 'default',
      direction: 'desc',
    },
  },
  actions: {
    setCards({ commit }, content) {
      commit('setCards', content);
    },
    setIsRequestProcessing({ commit }, value) {
      commit('setIsRequestProcessing', value);
    },
    unshiftCard({ commit }, content) {
      commit('unshiftCard', { content });
    },
    updateCard({ commit }, { index, content }) {
      commit('updateCard', { index, content });
    },
    updateCardFromId({ commit, state }, { id, content }) {
      const index = state.cards.findIndex(card => card._id === id);

      if (index >= 0) {
        commit('updateCard', { index, content });
      }

      return index;
    },
    setSelectedCards({ commit }, cards) {
      commit('setSelectedCards', cards);
    },
    updateSelectedCardFromId({ commit, state }, { id, content }) {
      const index = state.selectedCards.findIndex(card => card._id === id);

      if (index >= 0) {
        commit('updateSelectedCard', { index, content });
      }

      return index;
    },
    resetCards({ commit }) {
      commit('resetCards');
    },
    eraseCards({ commit }, content) {
      commit('eraseCards', content);
    },
    removeCard({ commit }, id) {
      commit('removeCard', id);
    },
    removeCardFromList({ commit }, id) {
      commit('removeCard', id);
      commit('removeCardFromList', id);
    },
    async getCards({ commit }, { call, parameters, page = 1, responseKeyName, cancelTokenSource }) {
      commit('resetCards');

      const response = await cards.getCards({
        call,
        params: {
          ...parameters,
          page,
        },
        cancelTokenSource,
      });

      const cardsTyped = response.data[responseKeyName].map(obj => ({
        ...obj,
        resource: responseKeyName,
      }));

      commit('setCards', cardsTyped);
      commit('setNext', response.data.next);
      commit('setCount', response.data.count);
      commit('setLimited', response.data.limited);

      return response;
    },
    async getCountCards({ commit }, { call, parameters, page = 1, cancelTokenSource }) {
      const response = await cards.getCards({
        call,
        params: {
          ...parameters,
          page,
        },
        cancelTokenSource,
      });

      commit('setCount', response.data.count);

      return response;
    },
    setCount({ commit }, data) {
      commit('setCount', data);
    },
    toggleCardToRemoveFromList({ commit }, id) {
      commit('toggleCardToRemoveFromList', id);
    },
    incrementCount({ commit }) {
      commit('incrementCount');
    },
    setSort({ commit }, { field, direction }) {
      commit('setSort', { field, direction });
    },
  },
  getters: {
    // Return the index of the card showed in the Panel & highlighted in the background
    cardActive: (state, getters, rootState) => {
      return (rootState.route.params.id || '') !== 'profile' ? state.cards.findIndex(card => (card._id === rootState.route.params.id)) : null;
    },
  },
  mutations: {
    setCards(state, data) {
      state.cards = [...state.cards, ...data];
    },
    setSelectedCards(state, cards) {
      state.selectedCards = cards;
    },
    setIsRequestProcessing(state, value) {
      state.isRequestProcessing = value;
    },
    updateCard(state, data) {
      Vue.set(state.cards, data.index, { ...state.cards[data.index], ...data.content });
    },
    updateSelectedCard(state, data) {
      Vue.set(state.selectedCards, data.index, { ...state.selectedCards[data.index], ...data.content });
    },
    resetCards(state) {
      state.cards = [];
    },
    removeCard(state, id) {
      const isInCardsList = state.cards.some(card => card._id === id);

      if (isInCardsList) {
        state.cards = state.cards.filter(card => card._id !== id);

        state.count = state.count - 1;
      }
    },
    removeCardFromList(state, id) {
      const cardIndex = state.cardsToRemoveFromList.findIndex(elem => elem === id);

      if (cardIndex >= 0) {
        state.cardsToRemoveFromList.splice(cardIndex, 1);
      }
    },
    eraseCards(state, data) {
      state.cards = data;
    },
    unshiftCard(state, data) {
      state.cards.unshift(data.content);
    },
    setCount(state, data) {
      state.count = data;
    },
    setNext(state, data) {
      state.next = data;
    },
    setLimited(state, data) {
      state.limited = data;
    },
    setTimer(state, data) {
      state.timer = data;
    },
    toggleCardToRemoveFromList(state, data) {
      const cardsToRemoveFromList = state.cardsToRemoveFromList;

      if (cardsToRemoveFromList.includes(data)) {
        const cardIndex = cardsToRemoveFromList.findIndex(elem => elem === data);
        cardsToRemoveFromList.splice(cardIndex, 1);
      } else {
        cardsToRemoveFromList.push(data);
      }
    },
    incrementCount(state) {
      ++state.count;
    },
    setSort(state, data) {
      state.sort = data;
    },
  },
};
