<template>
  <div
    v-click-outside="closePanel"
    class="panel-coach-edit-coach col-xs-12"
  >
    <div class="panel-coach-edit-coach__header">
      Édition du profil
    </div>
    <ValidationObserver
      ref="observer"
      class="panel-coach-edit-coach__form"
      tag="form"
      @submit.prevent
    >
      <div
        v-if="isLoading"
        class="panel-coach-edit-coach__loader"
      >
        <bc-spinner size="large"></bc-spinner>
      </div>
      <div
        v-else
        class="panel-coach-edit-coach__body"
      >
        <panel-edit-avatar
          :edited-profile="editedProfile"
          :profile="profile"
          class="panel-coach-edit-coach__wrapper"
          @update-value="setEditedProfile"
        >
        </panel-edit-avatar>
        <panel-coach-edit-caption
          :edited-profile="editedProfile"
          :profile="profile"
          class="panel-coach-edit-coach__wrapper"
          @update-value="setEditedProfile"
        >
        </panel-coach-edit-caption>
      </div>
      <div class="panel-coach-edit-coach__footer">
        <bc-button
          bc-background-color="red-amaranth"
          bc-padding="10px 25px"
          bc-radius="30px"
          bc-text-size="14px"
          class="panel-coach-edit-coach__button"
          @click.native="cancelEdit"
        >
          {{ $t('generics.cancel') }}
        </bc-button>
        <bc-button
          :disabled="!isEditedProfile"
          bc-background-color="blue-dodger"
          bc-padding="10px 25px"
          bc-radius="30px"
          bc-text-size="14px"
          class="panel-coach-edit-coach__button"
          @click.native="saveEdit"
        >
          {{ $t('generics.validate') }}
        </bc-button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
//
import PanelEditAvatar from '@/components/Panel/PanelEdit/PanelEditAvatar';
import coaches from '@/api/coaches';
import BcButton from '@/legacy/ui-kit/components/Button/BcButton';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import { ValidationObserver } from 'vee-validate';
import PanelCoachEditCaption from '@/components/Panel/PanelCoach/PanelCoachEdit/PanelCoachEditCaption';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'panel-coach-edit-coach',
  components: {
    PanelCoachEditCaption,
    BcSpinner,
    BcButton,
    PanelEditAvatar,
    ValidationObserver,
  },
  mixins: [PanelNavigation],
  data: () => ({
    profile: {},
    editedProfile: {},
    isLoading: true,
  }),
  computed: {
    ...mapGetters(['cardActive']),
    isEditedProfile() {
      return Object.entries(this.editedProfile).length > 0;
    },
    coachId() {
      return this.$route.params.id === 'profile' ? this.$store.state.user.profile._id : this.$route.params.id;
    },
  },
  methods: {
    ...mapActions([
      'updateCard',
      'updateCoach',
    ]),
    goalsValues() {
      this.$emit('update-value', { goals: this.profile.goals });
    },
    async getCoachProfile() {
      try {
        const { data } = await coaches.getCoachProfile(this.coachId);

        this.profile = data;

        if (!this.profile.goals) {
          this.profile.goals = {
            processesNb: 0,
            quarterRevenue: 0,
          };
        }

        this.isLoading = false;
      } catch (error) {
        this.$toast.show({
          type: 'error',
          message: this.$t('toast.error-occured'),
        });

        this.isLoading = false;
      }
    },
    setEditedProfile(profile) {
      this.editedProfile = { ...this.editedProfile, ...profile };
    },
    redirectToCoachPanel() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          type: 'coaches',
          ...this.$route.query['unsaved-changes'] && { 'unsaved-changes': undefined },
        },
      });
    },
    async editCoach(updatedObj) {
      try {
        const { data } = await coaches.updateCoachProfile({
          id: this.coachId,
          updatedCoach: updatedObj,
        });

        this.updateCoach(data);

        if (this.cardActive >= 0) {
          this.updateCard({ index: this.cardActive, content: data });
        }

        this.redirectToCoachPanel();
      } catch (error) {
        this.$toast.show({
          type: 'error',
          message: this.$t('toast.error-occured'),
        });
      }
    },
    async saveEdit() {
      const validator = await this.$refs.observer.validate();

      if (validator) {
        const {
          firstName,
          lastName,
          pictureUrl,
          job,
          email,
          mobile,
          _manager,
          linkedin,
          goals,
          location,
          role,
          _teams,
        } = this.editedProfile;

        const getTechnosId = technosArray => technosArray.map(techno => techno._id).join(',');

        const updatedObj = {
          ...firstName && { firstName },
          ...lastName && { lastName },
          ...pictureUrl && { pictureUrl },
          ...job && { job },
          ...email && { email },
          ...mobile && { mobile },
          ..._manager && { _manager },
          ...linkedin && { linkedin },
          ...goals && { goals },
          ...location && { location },
          ...role && { role },
          ..._teams && { _teams },
          ...Array.isArray(this.editedProfile._technos) && { _technos: getTechnosId(this.editedProfile._technos) },
          ...this.editedProfile.onboarding && { onboarding: { ...this.profile.onboarding, ...this.editedProfile.onboarding } },
        };

        this.editCoach(updatedObj);
      } else {
        this.$scrollTo('.invalid', 100, {
          container: '.panel-coach-edit-coach__body',
          offset: -100,
        });
      }
    },
    cancelEdit() {
      this.$router.go(-1);
    },
  },
  created() {
    this.getCoachProfile();
  },
  watch: {
    isEditedProfile(to) {
      if (to) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            'unsaved-changes': true,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-coach-edit-coach {
  height: 100%;
  padding-bottom: 61px;

  @include bp('tablet') {
    padding-bottom: 0;
  }

  &__header {
    min-height: 50px;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: $color-white;
    font-size: $font-size-xl;
    text-transform: uppercase;
    color: $color-blue-dark-cello;
    border-bottom: 1px solid rgba($color-blue-dark-cello, 0.25);
  }

  &__form {
    flex-direction: column;
    align-items: center;
    background: $color-white;
    flex-grow: 1;
    max-height: calc(100% - 50px);
  }

  &__loader {
    background: rgba($color-blue-dark-cello, 0.1);
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
  }

  &__body {
    display: block;
    overflow: auto;
    width: 100%;
    padding: 40px 20px;
    flex-grow: 1;

    @include bp('tablet') {
      padding: 40px 50px;
    }
  }


  &__footer {
    position: fixed;
    bottom: 0;
    padding: 10px;
    border-top: 1px solid rgba($color-blue-dark-cello, 0.25);
    width: 100%;
    justify-content: center;

    @include bp('tablet') {
      position: initial;
    }
  }

  &__wrapper {
    margin-bottom: $panel-container-margin-bottom;
  }

  &__button {
    max-width: 150px;
    min-width: 100px;
    justify-content: center;

    &:first-of-type {
      margin-right: 20px;
    }

    @include bp('tablet') {
      min-width: 150px;
    }
  }
}
</style>
