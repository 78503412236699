<template>
  <div class="placeholder-panel-carousel">
    <content-loader
      class="placeholder-panel-carousel__content"
      primary-color="#727a89"
      secondary-color="#929db0"
      :height="78"
      :width="698">
      <circle cx="38.5" cy="38.5" r="38.5"/>
      <rect x="95" y="25" rx="3" ry="3" width="70" height="10"/>
      <rect x="175" y="25" rx="3" ry="3" width="100" height="10"/>
      <rect x="95" y="45" rx="3" ry="3" width="50" height="10"/>
      <rect x="155" y="45" rx="3" ry="3" width="40" height="10"/>
      <rect x="205" y="45" rx="3" ry="3" width="60" height="10"/>
      <rect x="275" y="45" rx="3" ry="3" width="20" height="10"/>
    </content-loader>
  </div>
</template>

<script>
  import { ContentLoader } from 'vue-content-loader';

  /**
   * The component which will be displayed instead of an empty carousel
   */
  export default {
    name: 'placeholder-panel-carousel',
    components: {
      ContentLoader
    }
  };
</script>

<style scoped lang="scss">
  .placeholder-panel-carousel {
    width: 100%;
    &__content {
      width: 100%;
      max-height: 78px;
    }
    & :deep() {
      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
</style>
