<template>
  <input
    :style="inputStyle"
    :value="value"
    @blur="isFocused = false"
    @focus="isFocused = true"
    class="bc-input-number"
    ref="input"
    type="number"
    v-bind="$attrs"
    v-on="listeners">
</template>

<script>
import colors_helpers from '@/legacy/ui-kit/utils/colors_helpers';

export default {
  name: 'legacy-bc-input-number',
  inheritAttrs: false,
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    bcInputBorderColorBlurred: {
      type: String,
      default: 'blue-dodger'
    },
    bcInputBorderColorFocused: {
      type: String,
      default: 'blue-dodger'
    },
    bcInputBorderColorOpacityBlurred: {
      type: Number,
      default: 0.2
    },
    bcInputBorderColorOpacityFocused: {
      type: Number, default: 1
    },
    bcInputFontColor: {
      type: String,
      default: 'blue-dodger'
    },
    bcInputBackgroundColor: {
      type: String,
      default: 'white'
    },
    bcInputPadding: {
      type: String,
      default: '5px 10px'
    },
    bcInputBorderRadius: {
      type: String,
      default: '5px'
    }
  },
  data: () => ({
    isFocused: false
  }),
  computed: {
    inputStyle() {
      return {
        borderColor: this.isFocused ? colors_helpers.$_checkValidColor(this.bcInputBorderColorFocused, this.bcInputBorderColorOpacityFocused) : colors_helpers.$_checkValidColor(this.bcInputBorderColorBlurred, this.bcInputBorderColorOpacityBlurred),
        color: colors_helpers.$_checkValidColor(this.bcInputFontColor),
        padding: this.bcInputPadding,
        borderRadius: this.bcInputBorderRadius,
        background: colors_helpers.$_checkValidColor(this.bcInputBackgroundColor)
      };
    },
    listeners() {
      return {
        ...this.$listeners,
        input: (evt) => {
          this.$emit('input', Number(evt.target.value));
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
  .bc-input-number {
    border: 1px solid transparent;
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
</style>
