<template>
  <modal-feature
    feature="searchByLastActivity"
    :title="$t('features.search-activity-date')"
    @close-modal="$emit('close-modal')"
  >
    <template #content>
      <p>{{ $t('features.filter-activity-date') }}</p>
    </template>
    <template #logo>
      <img src="@/assets/logo/feature-search-last-activity.png" alt="last activity feature logo" />
    </template>
  </modal-feature>
</template>

<script>
import ModalFeature from './ModalFeature.vue';

export default {
  name: 'modal-feature-last-activity',
  components: {
    ModalFeature,
  },
}
</script>
