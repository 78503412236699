<template>
  <button
    :class="{
      'bc-dropdown-trigger--focus': isDropdownOpen,
      'bc-dropdown-trigger--disabled': disabled,
      'bc-dropdown-trigger--success': text
    }"
    class="bc-dropdown-trigger is-justify-content-space-between is-align-items-center is-relative is-expanded is-full-width">
    <span
      :class="{
        'bc-dropdown-trigger__label--focus font-size-s' : isDropdownOpen || text,
      }"
      :disabled="$attrs.disabled"
      class="bc-dropdown-trigger__label is-flex is-absolute font-size-m"
    >
      <i
        :class="[
          `icon-${icon}`,
          { 'font-size-s': isDropdownOpen }
        ]"
        class="bc-dropdown-trigger__label-icon is-flex is-align-items-center"
        v-if="icon">
      </i>
      <span
        class="bc-dropdown-trigger__label-required"
        v-if="$attrs.required">
        *
      </span>
    </span>
    <span class="bc-dropdown-trigger__text">
      {{ text }}
    </span>
    <i
      :class="{
        'icon-chevron-down': !isDropdownOpen,
        'icon-chevron-up': isDropdownOpen,
      }"
      class="bc-dropdown-trigger__chevron is-absolute is-flex is-align-items-center"
      v-if="isArrow">
    </i>
  </button>
</template>

<script>
  export default {
    name: 'bc-dropdown-trigger',
    props: {
      text: {
        type: String,
        default: '',
      },
      isArrow: {
        type: Boolean,
        default: false,
      },
      isDropdownOpen: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: [Boolean, String],
        default: false,
      },
      icon: {
        type: String,
        default: '',
      }
    },
  };
</script>

<style lang=scss scoped>
  $dropdown-color: $color-secondary;

  .bc-dropdown-trigger {
    padding: 10px;
    height: 40px;

    &:hover {
      color: $color-grey-5;
      border-color: $color-grey-5;

      .bc-dropdown-trigger__label {
        color: $color-grey-5;
      }
    }

    &--focus, &:active, &:focus {
      color: $color-primary;
      background-color: rgba($color-primary, 0.05);

      &:hover {
        color: $color-primary;
      }
    }

    &--success, &--success:hover {
      color: $color-secondary-dark;
    }

    &--disabled, &--disabled:hover {
      cursor: default;
      color: $color-grey-4;
      background-color: $color-grey-2;
      pointer-events: none;
    }

    &__text {
      max-width: calc(100% - 20px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__chevron {
      font-size: 10px;
      right: 10px;
    }
  }
</style>
