<template>
  <div class="process-column">
    <div :class="[
      `process-column__header--${props.step.categoryKey}`,
      { 'process-column__header--first': props.isFirst },
      { 'process-column__header--last': props.isLast },
    ]" :style="{ ...(props.step.style && props.step.style.header) }" class="process-column__header">
      <div class="process-column__title">
        <p>
          {{ props.step.name }}
        </p>
        <router-link v-if="props.step.iconLink" :to="props.step.iconLink">
          <i alt="" class="icon-external-link" />
        </router-link>
      </div>
      <p class="process-column__title">
        {{ columnMetadata?.documentCount }}
      </p>
    </div>
    <div ref="process-column__body" :class="[
      {
        [`process-column__body--${props.step.categoryKey}--hover`]:
          isHover && props.isDragging && hoverId === props.step.stepKey && !props.readOnly,
      },
      `process-column__body--${props.step.categoryKey}`,
    ]" class="process-column__body">
      <slot></slot>
      <draggable :id="props.step.stepKey" v-model="currentProcesses"
        :class="{ 'process-column__step--disabled': props.readOnly }" :disabled="props.readOnly"
        :ghost-class="'process-card--ghost'" animation="200" class="process-column__step" group="process"
        handle=".is-draggable" @end="emit('dragging', false)" @start="handleDraggingStart" @dragenter.native="setIsHover"
        @dragleave.native="unsetIsHover" @dragover.native="dragover" @mouseenter.native="setIsHover"
        @mouseleave.native="unsetIsHover">
        <router-link v-for="processAggregated in processes" :key="processAggregated.referenceProcess._id" :class="{
          'process-column__link--forbidden': !processAggregated.referenceProcess.processedAt,
        }" :event="isHoverCandidateBtns || isOptionsHover ? 'hover' : 'click'"
          :to="panelRoute(processAggregated.referenceProcess)" class="process-column__link"
          @dragstart.native="() => handleDragStart(processAggregated)">
          <component :is="processCardComponent"
            :is-draggable="!props.readOnly && handleDraggable(processAggregated)"
            :process-aggregated="processAggregated" :step="props.step" class="card process-column__card list-group-item"
            @on-options-hover="onOptionsHover" @on-action="onAction($event, processAggregated.referenceProcess)"
            @set-modal-type="emit('set-modal-type', $event)">
            <div v-if="careerIsActive &&
              props.isFirst &&
              props.categoryIndex === 0 &&
              numberOfProcesses(processAggregated) === 1
              " class="mt-2 items-center gap-[11px]" @mouseenter="isHoverCandidateBtns = true"
              @mouseleave="isHoverCandidateBtns = false">
              <bc-button iconLeft="check" @click="acceptProcess(processAggregated.referenceProcess._id)">
                {{ $t('generics.accept') }}
              </bc-button>
              <button class="text-sm font-bold text-blue-500" @click="rejectProcess(processAggregated.referenceProcess._id)">
                {{ $t('generics.reject') }}
              </button>
            </div>
          </component>
        </router-link>
      </draggable>
      <InfiniteLoading :identifier="identifier"
        @infinite="($state) => $emit('load-more-processes', $state, props.step.stepKey)" style="justify-content: center">
        <BcSpinner slot="spinner" :style="{ marginTop: processes.length ? '2rem' : 0 }" />
        <span slot="no-more" />
        <span slot="no-results" />
      </InfiniteLoading>
      <div v-if="props.showAmount && isCandidateFilter" class="process-column__amount">
        <div :class="`process-column__amount--${props.step.categoryKey}`">
          <span class="process-column__amount-number body-m--medium">&nbsp;{{ columnMetadata?.totalCommission?.toLocaleString() }}
            €</span>
        </div>
      </div>
    </div>

    <modal v-if="displayModal !== ''" @close-modal="closeModal">
      <modal-process-abort-reason v-if="displayModal === 'abortProcessReason'" :isLoading="isActionLoading"
        @confirm-action="processActions.abortProcess.action($event)"
        @cancel-action="closeModal"></modal-process-abort-reason>
      <modal-process-commission-custom v-else-if="displayModal === 'commissionCustom'"
        @confirm-action="setCommissionCustom($event)" @cancel-action="closeModal" />
      <modal-process-date
        v-else-if="displayModal === 'next-interview' || displayModal === 'next-presentation' || displayModal === 'integration-date'"
        :type="displayModal === 'next-interview' ? 'interview' : displayModal === 'integration-date' ? 'integration' : 'presentation'"
        @cancel-action="closeModal"
        @confirm-action="setProcessScheduledDate">
      </modal-process-date>
      <modal-process-action-confirmation v-else :confirmText="processActions[displayModal].confirmText"
        :isLoading="isActionLoading" :text="processActions[displayModal].text" :title="processActions[displayModal].title"
        :type="processActions[displayModal].type" @confirm-action="processActions[displayModal].action({ confirm: true })"
        @cancel-action="closeModal">
      </modal-process-action-confirmation>
    </modal>
  </div>
</template>

<script setup lang="ts">
import debounce from 'debounce';
import draggable from 'vuedraggable';

import BcButton from '@/ui-kit/components/BcButton';

import InfiniteLoading from 'vue-infinite-loading';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';

import ProcessesCardCustomCandidateAggregated from '@/components/Processes/ProcessesCardCustomCandidateAggregated.vue';
import ProcessesCardCustomJobAggregated from '@/components/Processes/ProcessesCardCustomJobAggregated.vue';

import { modalTypes } from '@/macros/processes/process';

import Modal from '@/components/Modal/Modal.vue';
import ModalProcessActionConfirmation from '@/components/Modal/ModalProcess/ModalProcessActionConfirmation.vue';
import ModalProcessAbortReason from '@/components/Modal/ModalProcess/ModalProcessAbortReason.vue';
import ModalProcessCommissionCustom from '@/components/Modal/ModalProcess/ModalProcessCommissionCustom.vue';
import ModalProcessDate from '@/components/Modal/ModalProcess/ModalProcessDate.vue';
import { defineProps, withDefaults, ref, computed } from 'vue';
import { useStore } from '@/store';
import { useI18n } from '@/i18n/i18n';
import { useToast } from '@/ui-kit/components/BcToast';
import { useRoute } from 'vue-router/composables';
import { useProcessesStore } from '@/store/pinia/processes';

const isOpportunityView = computed(() => route.query['selected-view'] === 'opportunities');

const processCardComponent = computed(() =>
  isOpportunityView.value ? ProcessesCardCustomJobAggregated : ProcessesCardCustomCandidateAggregated,
);

const props = withDefaults(
  defineProps<{
    step: any;
    categoryIndex?: number;
    processes: { _id: string; referenceProcess: any }[];
    columnMetadata?: {documentCount: number; totalCommission: number};
    stepName?: string;
    identifier: number;
    isDragging?: boolean;
    readOnly?: boolean;
    showAmount?: boolean;
    isFirst?: boolean;
    isLast?: boolean;
  }>(),
  {
    stepName: '',
    isDragging: false,
    readOnly: false,
    showAmount: false,
    isFirst: false,
    isLast: false,
  },
);

const emit = defineEmits<{
  (e: 'dragging', value: boolean): void;
  (e: 'drag-warning'): void;
  (e: 'move-process', params: { processId: string }): void;
  (e: 'accept-process', params: { processId: string }): void;
  (e: 'reject-process', params: { processId: any }): void;
  (e: 'abort-process', params: { processId: string }): void;
  (e: 'unabort-process', processId: string): void;
  (e: 'delete-process', processId: string): void;
  (e: 'process-update', params: { updatedProcess: any }): void;
  (e: 'set-modal-type', params: { process: any; type: string }): void;
  (e: 'dragging', value: boolean): void;
  (e: 'card-dragged', event: Event): void;
  (e: 'set-modal-type', params: { process: any; type: string }): void;
}>();

const store = useStore();
const { t } = useI18n();
const toast = useToast();
const route = useRoute();
const processesStore = useProcessesStore();

const isHover = ref(false);
const hoverId = ref(undefined);
const isHoverCandidateBtns = ref(false);
const isOptionsHover = ref(false);
const displayModal = ref('');
const selectedProcess = ref();
const processActions = ref({
  deleteProcess: {
    key: 'deleteProcess',
    title: t('generics.delete-process-warning'),
    text: t('generics.delete-process-warning-content'),
    action: deleteProcess,
    type: 'delete',
  },
  abortProcess: {
    key: 'abortProcess',
    title: t('process.confirm-abort'),
    text: '',
    confirmText: t('process.yes-abort-process'),
    action: abortProcess,
    type: 'abort',
  },
  unabortProcess: {
    key: 'unabortProcess',
    title: t('process.confirm-unabort'),
    text: '',
    confirmText: t('process.yes-activate-process'),
    action: unabortProcess,
    type: 'abort',
  },
  commissionCustom: {
    key: 'commissionCustom',
    title: 'toto',
    action: setCommissionCustom,
    type: 'custom-modal',
  },
  setInterviewDate: {
    key: 'next-interview',
    action: setProcessScheduledDate,
    type: 'set-interview-date',
  },
  setPresentationDate: {
    key: 'next-presentation',
    action: setProcessScheduledDate,
    type: 'set-presentation-date',
  },
  setIntegrationDate: {
    key: 'integration-date',
    action: setProcessScheduledDate,
    type: 'set-integration-date',
  },
});

const isActionLoading = ref(false);

const profile = computed(() => store.state.user.profile);
const isCandidateFilter = computed(() => route.query['selected-view'] === 'candidates');
const configurationProcess = computed(() => store.getters['user/configurationProcess']);
const isCandidateStep = computed(
  () =>
    props.categoryIndex === 0 &&
    configurationProcess.value.categories[0]?.steps[0]?.key === props.step.stepKey,
);
const careerIsActive = computed(
  () => profile.value?._organization?.configuration?.career?.activated,
);
const currentProcesses = computed({
  get: () => {
    return props.processes;
  },
  set: newProcesses => {
    const newProcess = newProcesses.find(process => !currentProcesses.value.some((currentProcess) => currentProcess._id === process._id));
    if (newProcess) {
      manageProcess(newProcess.referenceProcess);
      emit('add-process', {
        processesId: newProcess.referenceProcess._id,
      });
    }
  },
});

function updateCustomJob(params) {
  store.dispatch('customJobs/updateCustomJob', params);
}

function setModal(type) {
  store.dispatch('app/setModal', type);
}

function setParams(params) {
  store.dispatch('app/setParams', params);
}

function setIsPanel(value) {
  store.dispatch('app/setIsPanel', value);
}

function onOptionsHover(value) {
  isOptionsHover.value = value;
}

function onAction(action, process) {
  selectedProcess.value = process;
  if (action === 'abort-process') {
    abortProcess();
  }
  if (action === 'unabort-process') {
    unabortProcess();
  }
  if (action === 'delete') {
    deleteProcess();
  }
  if (action === 'commission-custom') {
    setCommissionCustom();
  }
  if (action === 'next-presentation' || action === 'next-interview' || action === 'integration-date') {
    setProcessScheduledDate({ action });
  }
}

function closeModal() {
  displayModal.value = '';
}

function onActionSuccess(res) {
  closeModal();
  updateCoder(res.data._coder);
}

async function abortProcess({ confirm, reason } = {}) {
  if (!confirm) {
    let modal = processActions.value.abortProcess.key;
    if (configurationProcess.value.failureReason && !isCandidateStep) {
      modal += 'Reason';
    }
    displayModal.value = modal;
    return;
  }
  try {
    isActionLoading.value = true;

    const data = await processesStore.onAbort({ processId: selectedProcess.value._id, reason });
    // const res = await abortProcessCustom(selectedProcess.value._id, { reason });
    emit('abort-process', { processId: selectedProcess.value._id});

    if (isCandidateStep && props.step.email) {
      openEmailPanel({
        coder: selectedProcess.value._coder,
        customJob: selectedProcess.value._customJob,
      });
    }

    onActionSuccess({ data });
    isActionLoading.value = false;
  } catch (error) {
    isActionLoading.value = false;
    toast.show({
      type: 'error',
      message: t('toast.error-occured'),
    });
  }
}

async function unabortProcess({ confirm } = {}) {
  if (!confirm) {
    displayModal.value = processActions.value.unabortProcess.key;
    return;
  }
  try {
    const data = await processesStore.onUnabort({ processId: selectedProcess.value._id });
    // const res = await unabortProcessCustom(selectedProcess.value._id);
    if (data._customJob?._id) {
      await updateCustomJob({
        id: data._customJob._id,
        nbActiveProcesses: data._customJob.nbActiveProcesses,
        withActions: false,
      });
    }
    emit('unabort-process', selectedProcess.value._id);
    onActionSuccess({ data });
  } catch (error) {
    toast.show({
      type: 'error',
      message: t('toast.error-occured'),
    });
  }
}

async function deleteProcess({ confirm } = {}) {
  if (!confirm) {
    displayModal.value = processActions.value.deleteProcess.key;
    return;
  }
  try {
    const data = await processesStore.onDelete({ processId: selectedProcess.value._id });
    if (data?._customJob) {
      await updateCustomJob({
        id: data._customJob._id,
        nbActiveProcesses: data._customJob.nbActiveProcesses,
        withActions: false,
      });
    }

    emit('delete-process', selectedProcess.value._id);

    onActionSuccess({ data });
  } catch (error) {
    toast.show({
      type: 'error',
      message: t('toast.error-occured'),
    });
  }
}

async function setCommissionCustom({ confirm, commissionCustom } = {}) {
  if (!confirm) {
    setParams({
      processCustom: selectedProcess.value,
    });
    displayModal.value = processActions.value.commissionCustom.key;
    return;
  }
  try {
    const data = await processesStore.onUpdate({
      processId: selectedProcess.value._id,
      commissionCustom: +commissionCustom,
    });
    emit('process-update', { updatedProcess: data });
  } catch (err) {
    toast.show({
      type: 'error',
      message: err?.message,
    });
  } finally {
    setParams({});
    closeModal();
  }
}

async function setProcessScheduledDate({ confirm, date, action } = {}) {
  if (!confirm) {
    displayModal.value = action;
    return;
  }
  const keys = {
    'next-interview': 'interviewedAt',
    'next-presentation': 'introducedAt',
    'integration-date': 'integratedAt',
  }
  const key = keys[displayModal.value];

  try {
    const data = await processesStore.onUpdate({
      processId: selectedProcess.value._id,
      [key]: date,
    });
    emit('process-update', { updatedProcess: { ...selectedProcess.value, [key]: data[key] } });
  } catch (error) {
    toast.show({
      type: 'error',
      message: t('toast.error-occured'),
    });
  } finally {
    setParams({});
    closeModal();
  }
}

function dragStart(processAggregated) {
  if (!handleDraggable(processAggregated)) {
    emit('drag-warning');
  }
}

function handleDragStart(processAggregated) {
  debounce(dragStart(processAggregated), 200);
}

function handleDraggable(processAggregated) {
  return processAggregated.processesNumber === 1;
}

function handleDraggingStart(event) {
  emit('dragging', true);
  emit('card-dragged', event);
}

function manageProcess(newProcess) {
  if (modalTypes[props.stepName]) {
    emit('set-modal-type', {
      process: newProcess,
      type: modalTypes[props.stepName].modalName,
    });

    if (!modalTypes[props.stepName].isBlocking) {
      emit('move-process', {
        processId: newProcess._id,
      });
    }
  } else {
    emit('move-process', {
      processId: newProcess._id,
    });
  }
}

function opportunityUrl(process) {
  if (process?.customJob?._id) {
    return {
      ...route,
      params: {
        id: getId(process._customJob),
      },
      query: {
        ...route.query,
        type: 'hunt',
      },
    };
  } else {
    return {
      ...route,
      params: {
        id: getId(process.company),
      },
      query: {
        ...route.query,
        type: 'companies',
      },
    };
  }
}

function candidateUrl(process) {
  return {
    ...route,
    params: {
      id: getId(process._coder),
    },
    query: {
      ...route.query,
      type: 'coders',
    },
  };
}

function getId(fieldMaybePopulated) {
  return fieldMaybePopulated?._id || fieldMaybePopulated;

}

function panelRoute(process) {
  if (route.params.id) {
    if (route.query.type === 'process-kanban') {
      if (route.query.subtype === 'coders') {
        return opportunityUrl(process);
      } else if (route.query.subtype === 'hunt' || route.query.subtype === 'companies') {
        return candidateUrl(process);
      }
    }

    if (route.query.type === 'coders') {
      return opportunityUrl(process);
    } else if (route.query.type === 'hunt' || route.query.type === 'companies') {
      return candidateUrl(process);
    }
  }

  let id
  let type
  if (route.query['selected-view'] === 'opportunities' && process._customJob) {
    id = getId(process._customJob)
    type = 'hunt'
  } else if (route.query['selected-view'] === 'opportunities') {
    id = getId(process._company)
    type = 'partners'
  } else if (route.query['selected-view'] === 'candidates') {
    id = getId(process._coder)
    type = 'coders'
  }

  const processesPanel = {
    name: 'ProcessesPanel',
    params: {
      id,
    },
    query: {
      ...route.query,
      type,
      category: 'process',
      context: 'processes',
    },
  };

  const processPanel = {
    ...route,
    params: {
      id: process._id,
    },
    query: {
      ...route.query,
      type: 'process',
      typing: true,
    },
  };

  if (route.name === 'Processes') {
    return processesPanel;
  }

  return processPanel;
}

function setIsHover() {
  isHover.value = true;
}

function unsetIsHover() {
  isHover.value = false;
}

function dragover(event) {
  setIsHover();
  if (event.target.id !== hoverId.value) {
    hoverId.value = event.target.id;
  }
  emit('dragover', event);
}

function acceptProcess(processId) {
  emit('accept-process', { processId });
  setTimeout(() => {
    isHoverCandidateBtns.value = false;
  }, 100);
}

function rejectProcess(processId) {
  emit('reject-process', { processId });

  setTimeout(() => {
    isHoverCandidateBtns.value = false;
  }, 100);
}

function openEmailPanel({ coder, customJob, actionId }) {
  setParams({
    coder,
    customJob,
    actionId,
    emails: [coder.email, ...(coder.emails || [])].filter(Boolean),
    isCompany: false,
    isApplication: true,
  });
  setIsPanel(true);
  setModal('modal-email-send-form');
}

function updateCoder(coder) {
  const coderIndex = store.state.card.cards.findIndex(card => card._id === coder._id);

  if (coderIndex >= 0) {
    store.dispatch('updateCard', {
      index: coderIndex,
      content: {
        processState: coder.processState,
        coachProcessState: coder.coachProcessState,
      },
    });
  }
}

function numberOfProcesses(aggregatedProcess) {
  return aggregatedProcess.processesByCompany?.flatMap(
    companyProcesses => companyProcesses.processes,
  )?.length || 0;
}

</script>

<style lang="scss" scoped>
.process-column {
  flex-direction: column;
  position: relative;

  &__header {
    padding: 15px;
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    font-size: $font-size-s;
    background: $color-white;
    border-bottom: 1px solid rgba($color-blue-dark-cello, 0.2);
    z-index: 1;
    height: 47.5px;
    border-radius: inherit;

    &--first {
      border-top-left-radius: 5px;
    }

    &--last {
      border-top-right-radius: 5px;
    }

    &--beforeCandidatePresentation {
      background: $color-primary;
    }

    &--beforeProcessRecruitment {
      background: $color-secondary;
    }

    &--afterProcessRecruitment {
      background: $color-success;
    }

    &--failed {
      background: $color-error;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  &__title {
    color: white;

    a {
      color: white;
      margin-left: 10px;
      height: 16.35px;
    }
  }

  &__body {
    flex-grow: 1;
    transition: 0.15s;
    flex-direction: column;
    padding-top: 5px;
    overflow: auto;
    padding-bottom: 55px;

    &--beforeCandidatePresentation {
      background: rgba($color-primary, 0.05);

      &--hover {
        background: rgba($color-primary, 0.2);
      }
    }

    &--beforeProcessRecruitment {
      background: rgba($color-secondary, 0.05);

      &--hover {
        background: rgba($color-secondary, 0.2);
      }
    }

    &--afterProcessRecruitment {
      background: rgba($color-success, 0.05);

      &--hover {
        background: rgba($color-success, 0.2);
      }
    }

    &--failed {
      background: rgba($color-error, 0.05);

      &--hover {
        background: rgba($color-error, 0.2);
      }
    }
  }

  &__step {
    flex-direction: column;
    width: 100%;
    flex-grow: 1;

    &--old {
      margin-top: 10px;
      flex-grow: 1;
      height: 100%;
      padding: 10px 0;
      border-top: 1px solid rgba($color-blue-dodger, 0.3);
    }

    &--disabled {
      cursor: initial;
      user-select: none;
    }
  }

  &__link {
    justify-content: center;
  }

  &__card {
    margin: 5px 10px;
  }

  &__amount {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-transform: uppercase;
    background: $color-white;
    color: $color-secondary;
    border-top: 1px solid rgba($color-secondary, 0.1);
    z-index: 2;
    border-right: 1px solid $color-blue-light;

    &-number {
      color: $color-tertiary;
      width: 100%;
      text-align: right;
    }

    &--beforeCandidatePresentation {
      background: rgba($color-primary, 0.1);
      width: 100%;
      height: 100%;
      padding: 15px 20px;
    }

    &--beforeProcessRecruitment {
      background: rgba($color-secondary, 0.1);
      width: 100%;
      height: 100%;
      padding: 15px 20px;
    }

    &--afterProcessRecruitment {
      background: rgba($color-success, 0.1);
      width: 100%;
      height: 100%;
      padding: 15px 20px;
    }

    &--failed {
      background: rgba($color-error, 0.1);
      width: 100%;
      height: 100%;
      padding: 15px 20px;
    }
  }
}

.process-card {
  &--ghost {
    display: none;
  }
}
</style>
