<template>
  <div class="topbar-container is-column">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'topbar-container',
  };
</script>

<style lang="scss" scoped>
  .topbar-container {
    background: white;
    padding: 30px 18px;
  }
</style>
