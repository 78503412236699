<template>
  <div class="bc-rich-text-editor">
    <editor-menu-bar :editor="editor">
      <div
        class="bc-rich-text-editor__menu"
        slot-scope="{ commands, isActive }">
        <button
          class="bc-rich-text-editor__button"
          :class="{ 'bc-rich-text-editor__button--active': isActive.bold() }"
          @click.prevent="commands.bold">
          <span class="bc-rich-text-editor__icon icon-tiptap-bold"></span>
        </button>
        <button
          class="bc-rich-text-editor__button"
          :class="{ 'bc-rich-text-editor__button--active': isActive.italic() }"
          @click.prevent="commands.italic">
          <span class="bc-rich-text-editor__icon icon-tiptap-italic"></span>
        </button>
        <button
          class="bc-rich-text-editor__button"
          :class="{ 'bc-rich-text-editor__button--active': isActive.strike() }"
          @click.prevent="commands.strike">
          <span class="bc-rich-text-editor__icon icon-tiptap-strike"></span>
        </button>
        <button
          class="bc-rich-text-editor__button"
          :class="{ 'bc-rich-text-editor__button--active': isActive.underline() }"
          @click.prevent="commands.underline">
          <span class="bc-rich-text-editor__icon icon-tiptap-underline"></span>
        </button>
        <button
          class="bc-rich-text-editor__button"
          :class="{ 'bc-rich-text-editor__button--active': isActive.paragraph() }"
          @click.prevent="commands.paragraph">
          <span class="bc-rich-text-editor__icon icon-tiptap-paragraph"></span>
        </button>
        <button
          class="bc-rich-text-editor__button"
          :class="{ 'bc-rich-text-editor__button--active': isActive.heading({ level: 1 }) }"
          @click.prevent="commands.heading({ level: 1 })">
          H1
        </button>
        <button
          class="bc-rich-text-editor__button"
          :class="{ 'bc-rich-text-editor__button--active': isActive.heading({ level: 2 }) }"
          @click.prevent="commands.heading({ level: 2 })">
          H2
        </button>
        <button
          class="bc-rich-text-editor__button"
          :class="{ 'bc-rich-text-editor__button--active': isActive.heading({ level: 3 }) }"
          @click.prevent="commands.heading({ level: 3 })">
          H3
        </button>
        <button
          class="bc-rich-text-editor__button"
          :class="{ 'bc-rich-text-editor__button--active': isActive.bullet_list() }"
          @click.prevent="commands.bullet_list">
          <span class="bc-rich-text-editor__icon icon-tiptap-ul"></span>
        </button>
        <button
          class="bc-rich-text-editor__button"
          :class="{ 'bc-rich-text-editor__button--active': isActive.ordered_list() }"
          @click.prevent="commands.ordered_list">
          <span class="bc-rich-text-editor__icon icon-tiptap-ol"></span>
        </button>
        <button
          class="bc-rich-text-editor__button"
          @click.prevent="commands.undo">
          <span class="bc-rich-text-editor__icon icon-tiptap-undo"></span>
        </button>
        <button
          class="bc-rich-text-editor__button"
          @click.prevent="commands.redo">
          <span class="bc-rich-text-editor__icon icon-tiptap-redo"></span>
        </button>
      </div>
    </editor-menu-bar>
    <editor-content
      v-bind="$attrs"
      class="bc-rich-text-editor__textarea"
      v-on="listeners"
      :editor="editor">
    </editor-content>
  </div>
</template>

<script>
  import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
  import {
    Bold,
    BulletList,
    HardBreak,
    Heading,
    History,
    Italic,
    Link,
    ListItem,
    OrderedList,
    Strike,
    Underline,
  } from 'tiptap-extensions';

  export default {
    name: 'legacy-bc-rich-text-editor',
    components: {
      EditorContent,
      EditorMenuBar,
    },
    inheritAttrs: false,
    props: {
      /**
       * Define the field inside the object which will be modified
       */
      value: {
        type: [String, Number]
      }
    },
    data: () => ({
      editor: null
    }),
    mounted() {
      this.editor = new Editor({
        content: this.value,
        extensions: [
          new BulletList(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new Bold(),
          new Italic(),
          new Link(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        onUpdate: event => {
          this.getValue(event);
        }
      });
    },
    beforeDestroy() {
      this.editor.destroy();
    },
    methods: {
      getValue({ getHTML }) {
        this.$emit('input', getHTML());
      }
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
        };
      },
    }
  };
</script>

<style scoped lang="scss">
  .bc-rich-text-editor {
    width: 100%;
    flex-direction: column;
    min-height: 150px;
    border: 1px solid rgba($color-blue-dodger, 0.3);
    border-radius: 4px;
    margin-bottom: 15px;
    @include bp('tablet') {
      margin-bottom: 25px;
    }
    &__textarea {
      display: flex;
      flex-grow: 1;
      min-height: 100%;
      overflow: auto;
      text-align: left;
      @include font-size(14px);
      :deep() {
        & > div {
          display: block;
          line-height: 1.5;
          width: 100%;
          @include font-size(16px);
          color: $color-blue-dark-cello;
        }
        @include default-css;
      }
    }
    &__menu {
      flex-shrink: 0;
      transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
      border-bottom: 1px solid rgba($color-blue-dodger, 0.3);
      padding: 5px;
      justify-content: center;
    }
    &__button {
      font-weight: bold;
      background: transparent;
      border: 0;
      color: $color-black;
      padding: 0.2rem 0.5rem;
      margin-right: 0.2rem;
      border-radius: 3px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include font-size(11px);
      &:hover {
        background-color: rgba($color-black, 0.05);
      }
      &--active {
        background-color: rgba($color-black, 0.1);
      }
    }
    &__icon {
      @include font-size(12px);
    }
  }
</style>
