<script lang="ts" setup>
  import { Candidate } from '@/domains/models/Candidate';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { defineEmits, defineProps, ref, watch, withDefaults } from 'vue';
  import BcInput from '@/ui-kit/components/BcInput/BcInput.vue';
  import regexp from '@/macros/coders/regexp';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import PanelEditAvatar from '@/components/Panel/PanelEdit/PanelEditAvatar.vue';
  import { coderController } from '@/managers/coders/controller';
  import { useStore } from '@/store';
  import { useProcessesStore } from '@/store/pinia/processes';
  import { useRoute } from 'vue-router/composables';
  interface Props {
    candidate: Candidate;
    isCreating?: boolean;
  }

  interface EditedProfile {
    pictureUrl: string;
    firstName: string;
    lastName: string;
    title: string;
  }
  const processesStore = useProcessesStore();
  const route = useRoute();
  const props = withDefaults(defineProps<Props>(), {
    isCreating: false,
  });
  const emit = defineEmits<{
    (e: 'edit-candidate', value: boolean): void,
    (e: 'update-candidate', value: EditedProfile): void
    (e: 'create-candidate'): void
  }>();

  const store = useStore();
  const cardActive = store.getters.cardActive;

  const observer = ref(null);

  const editedProfile = ref<EditedProfile>({
    firstName: props.candidate.firstName,
    lastName: props.candidate.lastName,
    title: props.candidate.title,
    pictureUrl: props.candidate.pictureUrl,
  });
  const setEditedProfile = (data) => {
    editedProfile.value = {
      ...editedProfile.value,
      ...data,
    };
  };
  const closeEdit = () => {
    emit('edit-candidate', false);
  };
  const saveCandidate = (async() => {
    const validator = await observer.value.validate();

    if (validator) {
      if (!props.isCreating) {
        const { data } = await coderController.editProfile({
          id: props.candidate._id,
          data: editedProfile.value,
        });

        if (cardActive >= 0) {
          await store.dispatch('updateCard', { index: cardActive, content: data });
        }
        if (route.query.processId && typeof route.query.processId === 'string') {
          processesStore.updateCardInfo({processId : route.query.processId, firstName : data.firstName, lastName: data.lastName , title : data.title });
        }
        emit('update-candidate', editedProfile.value);
        closeEdit();
      } else {
        emit('create-candidate');
      }
    }
  });

  // creating case
  watch(() => ({ firstName: editedProfile.value.firstName, lastName: editedProfile.value.lastName, title: editedProfile.value.title, pictureUrl: editedProfile.value.pictureUrl }), (value) => {
    if (props.isCreating) {
      emit('update-candidate', value);
    }
  }, { deep: true });
</script>

<template>
  <div class="relative flex shrink-0 flex-row items-start gap-x-8 pt-5">
    <PanelEditAvatar
      :edited-profile="editedProfile"
      :profile="candidate"
      @update-value="setEditedProfile"/>
    <ValidationObserver
      ref="observer"
      class="relative -top-3 flex flex-row flex-wrap items-start gap-x-3.5 gap-y-2.5"
      tag="form"
      @submit.prevent>
      <ValidationProvider
        v-slot="{ errors, valid }"
        :name="$t('contacts.firstname')"
        :rules="{ required: true, min: 2, regex: regexp.name }"
        slim
      >
        <BcInput
          ref="first-name"
          v-model="editedProfile.firstName"
          :class="{ invalid: errors.length > 0 }"
          :error="errors[0]"
          :label="$t('contacts.firstname')"
          :label-placeholder="false"
          :placeholder="$t('contacts.firstname')"
          :valid="valid"
          class="mb-1.5 flex max-w-1/2-gap-3.5"
          name="first-name"
          required
          type="text"
        >
        </BcInput>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors, valid }"
        :name="$t('contacts.lastname')"
        :rules="{ required: true, min: 2, regex: regexp.name }"
        slim
      >
        <BcInput
          ref="last-name"
          v-model="editedProfile.lastName"
          :class="{ invalid: errors.length > 0 }"
          :error="errors[0]"
          :label="$t('contacts.lastname')"
          :label-placeholder="false"
          :placeholder="$t('contacts.lastname')"
          :valid="valid"
          class="flex max-w-1/2-gap-3.5"
          name="last-name"
          required
          type="text"
        >
        </BcInput>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors, valid }"
        :name="$t('avatar-dropdown.job-title')"
        :rules="{ min: 2 }"
        slim>
        <bc-input
          ref="title"
          v-model="editedProfile.title"
          :class="{ invalid: errors.length > 0 }"
          :error="errors[0]"
          :label="$t('avatar-dropdown.job-title')"
          :labelPlaceholder="false"
          :placeholder="$t('placeholder.example-recruiter')"
          :valid="valid && editedProfile.title?.length > 0"
          class="w-full"
          icon="suitcase"
          name="job"
          type="text">
        </bc-input>
      </ValidationProvider>
      <div
        class="ml-auto flex flex-row gap-x-2.5">
        <BcButton
          icon-left="cross"
          size="small"
          type="outlined"
          @click="closeEdit">
          {{ $t('global.cancel') }}
        </BcButton>
        <BcButton
          color="primary"
          icon-left="check"
          size="small"
          @click.prevent="saveCandidate">
          {{ $t('global.save') }}
        </BcButton>
      </div>
    </ValidationObserver>
  </div>
</template>
