<template>
  <div class="custom-jobs-empty">
    <h3 class="custom-jobs-empty__text">{{ $t('generics.no-result') }}</h3>
  </div>
</template>

<script>

export default {
  name: 'custom-jobs-empty',
  methods: {
    openCreateOpportunityPanel() {
      this.$router.push({
        ...this.$route,
        name: `${this.$route.name}Panel`,
        params: {
          id: 'new-custom-jobs',
        },
        query: {
          ...this.$route.query,
          type: 'create-custom-jobs',
        },
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-jobs-empty {
  display: flex;
  width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $color-blue-dark-cello;
    margin-bottom: 15px;
  }
}
</style>
