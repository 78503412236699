<template>
  <SearchbarItem>
    <div class="searchbar-coders-item">
      <bc-avatar
        class="searchbar-coders-item__avatar"
        :src="suggestion.picture"
        size="xs"
        @error="setAlternativeImg"
      ></bc-avatar>
      <p class="is-ellipsis" v-html="getTextHint(suggestion.name, search)"></p>
    </div>
  </SearchbarItem>
</template>

<script>
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
import SearchbarItem from '../SearchbarItem';
import searchbarMixins from '@/mixins/searchbar';

export default {
  name: 'searchbar-coders-item',
  mixins: [searchbarMixins],
  components: {
    BcAvatar,
    SearchbarItem,
  },
  props: {
    suggestion: {
      type: Object,
      default: () => ({}),
    },
    search: {
      type: String,
      default: '',
    },
  },
  methods: {
    setAlternativeImg(event) {
      event.target.src = require('@/assets/img/default-avatar.svg');
    },
  }
}
</script>

<style lang="scss" scoped>
.searchbar-coders-item {
  align-items: center;
  gap: 10px;
  width: 100%;

  &__avatar {
    min-width: 20px;
    min-height: 20px;

    :deep() {
      & img {
        border: 1px solid $color-blue-light;
        border-radius: 100%;
      }
    }
  }
}
</style>
