import { getLinkableProcesses } from '@/api/processes';

export default {
  state: {
    processLastUpdate: undefined,
    processSuggestions: [],
    editedProcessesQueue: [],
  },
  actions: {
    async getLinkableProcesses({ commit }, processId) {
      const res = await getLinkableProcesses(processId);

      commit('setProcessSuggestions', res.data);
    },
    addProcessToEditedProcessesQueue({ commit }, processId) {
      commit('addProcessToEditedProcessesQueue', processId);
    },
    emptyProcessesQueue({ commit }) {
      commit('emptyProcessesQueue');
    },
    setProcessLastUpdate({ commit }) {
      commit('setProcessLastUpdate');
    }
  },
  mutations: {
    setProcessSuggestions(state, payload) {
      state.processSuggestions = payload;
    },
    addProcessToEditedProcessesQueue(state, id) {
      state.editedProcessesQueue.push(id);
    },
    emptyProcessesQueue(state) {
      state.editedProcessesQueue = [];
    },
    setProcessLastUpdate(state) {
      state.processLastUpdate = new Date();
    }
  },
};
