

export const linkedinMacros = {
  templates: {
    type: {
      message: 'LINKEDIN_MESSAGE' as const,
      invitation: 'LINKEDIN_INVITATION' as const,
      inmail: 'LINKEDIN_INMAIL' as const,
    }
  }
}
type LinkedinTemplateType = typeof linkedinMacros.templates.type;
//get an enum of the values of the type
export type LinkedinTemplateTypeEnum = LinkedinTemplateType[keyof LinkedinTemplateType];

export default linkedinMacros;