<script lang="ts" setup>

  import linkedinImg from '@/assets/logo/linkedin.png';
  import welcomeToTheJungleImg from '@/assets/logo/wttj.png';
  import PanelCompanyContactsSocial
    from '@/components/Panel/PanelCompany/PanelCompanyContacts/PanelCompanyContactsSocial.vue';
  import { computed, inject } from 'vue';
  import { parsePhoneNumberFromString } from 'libphonenumber-js';
  import { Company } from '@/domains/models/Company';
  import PanelCompanyModalMergePreviewTitle
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalMerge/PanelCompanyModalMergePreview/PanelCompanyModalMergePreviewTitle.vue';
  import PanelCompanyModalMergeButton
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalMerge/PanelCompanyModalMergeButton.vue';
  import ProvideKeys from '@/components/Panel/PanelCompany/PanelCompanyModal/ProvideKeys';
  import { SelectedFields } from '@/components/Panel/PanelCompany/PanelCompanyModal/types';

  const props = defineProps<{
    company: Company;
    toDelete: boolean;
  }>();


  const emails = computed<string[]>(() => {
    return [...props.company?.email ? [props.company.email] : [], ...(props.company?.emails?.length ? props.company.emails : [])].filter(e => e);
  });
  const phones = computed<string[]>(() => {
    return [...props.company?.phone ? [props.company.phone] : [], ...(props.company?.phones?.length ? props.company.phones : [])].filter(e => e).map(phone => parsePhoneNumberFromString(phone, 'FR')?.formatInternational() || phone);
  });

  const {
    selectedFields,
    setSelectedFields,
    selectedFieldsProfile,
    selectedPhones,
    selectedEmails,
    setSelectedPhones,
    setSelectedEmails,
  } = inject(ProvideKeys.selectedFields) as SelectedFields;

  const mergedEmails = computed<string[]>(() => {
    if (props.toDelete) {
      return [];
    }

    return [...selectedEmails.value?.length ? [...selectedEmails.value] : []];
  });
  const mergedPhones = computed<string[]>(() => {
    if (props.toDelete) {
      return [];
    }

    return [...selectedPhones.value?.length ? [...selectedPhones.value] : []];
  });

  function getImportedStatus(value: string, values: string[]): 'imported' | 'import' {
    return values.includes(value) ? 'imported' : 'import';
  }

  const linkedinValue = computed(() => selectedFieldsProfile?.value?.social?.linkedin ?? props.company.social?.linkedin);
  const websiteValue = computed(() => selectedFieldsProfile?.value?.social?.website ?? props.company.social?.website);
  const welcomeToTheJungleValue = computed(() => selectedFieldsProfile?.value?.social?.welcomeToTheJungle ?? props.company.social?.welcomeToTheJungle);

  const { rightProfile } = inject(ProvideKeys.mergeProfiles);

  function getMergeButtonLabel(value): 'erase' | 'import' {
    return value ? 'erase' : 'import';
  }

  const linkedinImportedStatus = computed<'imported' | 'erase' | 'import'>(() => selectedFields.value.includes('social.linkedin') ? 'imported' : getMergeButtonLabel(rightProfile.value?.social?.linkedin));
  const websiteImportedStatus = computed<'imported' | 'erase' | 'import'>(() => selectedFields.value.includes('social.website') ? 'imported' : getMergeButtonLabel(rightProfile.value?.social?.website));
  const welcomeToTheJungleImportedStatus = computed<'imported' | 'erase' | 'import'>(() => selectedFields.value.includes('social.welcomeToTheJungle') ? 'imported' : getMergeButtonLabel(rightProfile.value?.social?.welcomeToTheJungle));
</script>

<template>
  <div class="mt-5 flex w-full flex-col gap-5">
    <PanelCompanyModalMergePreviewTitle :title="$t('panel-coder-edit.contact-information')"/>
    <div class="flex flex-wrap justify-between gap-[10px]">
      <div
        v-for="(email, index) in emails"
        :key="email"
        class="flex w-full max-w-[47.5%] gap-1">
        <PanelCompanyContactsSocial
          v-tooltip="email"
          :class="{'max-w-[60%]': toDelete, 'max-w-full': !toDelete}"
          :company="company"
          :is-email="true"
          :is-pro="index > 0"
          :value="email"
          icon="mail"
        />
        <PanelCompanyModalMergeButton
          v-if="toDelete"
          :status="getImportedStatus(email, selectedEmails)"
          @click.native="setSelectedEmails(email)"/>
      </div>
      <div
        v-for="(email) in mergedEmails"
        :key="email"
        class="flex w-full max-w-[47.5%] gap-1">
        <PanelCompanyContactsSocial
          v-tooltip="email"
          :class="{'max-w-[60%]': toDelete, 'max-w-full': !toDelete}"
          :company="selectedFieldsProfile"
          :is-email="true"
          :is-pro="true"
          :value="email"
          class="grow"
          icon="mail"
        />
        <div
          class="icon-check flex size-5 shrink-0 items-center justify-center rounded-full bg-green-550 text-[10px] text-white">
        </div>
      </div>
      <div
        v-for="(phone, index) in phones"
        :key="phone"
        class="flex w-full max-w-[47.5%] gap-1">
        <PanelCompanyContactsSocial
          v-tooltip="phone"
          :class="{'max-w-[60%]': toDelete, 'max-w-full': !toDelete}"
          :company="company"
          :is-pro="index > 0"
          :value="phone"
          class="h-5 w-full grow"
          icon="phone"
        />
        <PanelCompanyModalMergeButton
          v-if="toDelete"
          :status="getImportedStatus(phone, selectedPhones)"
          @click.native="setSelectedPhones(phone)"/>
      </div>
      <div
        v-for="(phone) in mergedPhones"
        :key="phone"
        class="flex w-full max-w-[47.5%] gap-1">
        <PanelCompanyContactsSocial
          v-tooltip="phone"
          :class="{'max-w-[60%]': toDelete, 'max-w-full': !toDelete}"
          :company="selectedFieldsProfile"
          :is-pro="true"
          :value="phone"
          class="h-5 w-full grow"
          icon="phone"
        />
        <div
          class="icon-check flex size-5 items-center justify-center rounded-full bg-green-550 text-[10px] text-white">
        </div>
      </div>
      <div
        v-if="linkedinValue"
        class="flex w-full max-w-[47.5%] gap-1">
        <PanelCompanyContactsSocial
          v-tooltip="linkedinValue"
          :class="{'max-w-[60%]': toDelete, 'max-w-full': !toDelete}"
          :company="company"
          :icon-url="linkedinImg"
          :value="linkedinValue"
          class="w-full min-w-[50%] grow"
        />
        <PanelCompanyModalMergeButton
          v-if="toDelete"
          :status="linkedinImportedStatus"
          @click.native="setSelectedFields('social.linkedin')"/>
        <div
          v-else-if="!toDelete && linkedinImportedStatus === 'imported'"
          class="icon-check flex size-5 shrink-0 items-center justify-center rounded-full bg-green-550 text-[10px] text-white">
        </div>
      </div>
      <div
        v-if="websiteValue"
        class="flex w-full max-w-[47.5%] gap-1">
        <PanelCompanyContactsSocial
          v-tooltip="websiteValue"
          :class="{'max-w-[60%]': toDelete, 'max-w-full': !toDelete}"
          :company="company"
          :value="websiteValue"
          class="w-full min-w-[50%] grow"
          icon="website"
        />
        <PanelCompanyModalMergeButton
          v-if="toDelete"
          :status="websiteImportedStatus"
          @click.native="setSelectedFields('social.website')"/>
        <div
          v-else-if="!toDelete && websiteImportedStatus === 'imported'"
          class="icon-check flex size-5 shrink-0 items-center justify-center rounded-full bg-green-550 text-[10px] text-white">
        </div>
      </div>
      <div
        v-if="welcomeToTheJungleValue"
        class="flex w-full max-w-[47.5%] gap-1">
        <PanelCompanyContactsSocial
          v-tooltip="welcomeToTheJungleValue"
          :class="{'max-w-[60%]': toDelete, 'max-w-full': !toDelete}"
          :company="company"
          :icon-url="welcomeToTheJungleImg"
          :value="welcomeToTheJungleValue"
          class="h-5 w-full grow"
        />
        <PanelCompanyModalMergeButton
          v-if="toDelete"
          :status="welcomeToTheJungleImportedStatus"
          @click.native="setSelectedFields('social.welcomeToTheJungle')"/>
        <div
          v-else-if="!toDelete && welcomeToTheJungleImportedStatus === 'imported'"
          class="icon-check flex size-5 shrink-0 items-center justify-center rounded-full bg-green-550 text-[10px] text-white">
        </div>
      </div>
    </div>
  </div>
</template>
