import Vue from 'vue';
import Api from '@/common-old/lib/Api';
import coders from '@/api/coders';

export default {
  state: {
    deduplication: [],
    editedProfile: {},
  },
  actions: {
    async updateCoderProfile(context, { id, obj }) {
      return await coders.updateCoderProfile({ id, updatedObj: obj });
    },
    async deleteCoderProfile({ dispatch, commit, rootState }, id) {
      const response = await coders.deleteCoderProfile(id);

      dispatch('user/updateMetadataByKey', {
        key: 'coders',
        value: rootState.user.metadata.coders - 1,
      });

      commit('removeCard', id);

      return response;
    },
    //TODO A refactoriser quand Coders-Infos aura été merge car trop de composants ont été modifiés et ne sont plus d'actualité
    async updateCoderStatus(context, { id, status }) {
      return await coders.updateCoderStatus({ id, status });
    },
    // TODO Refacto lors de la refacto de l'infinite-scroll
    async getCodersResources({ commit }, params) {
      const response = await coders.getCodersPokes({ id: params.parameters.id, page: params.parameters.page });

      commit('setViewList', response.data.pokes);

      return response;
    },
    async createCoder({ dispatch, commit, rootState }, { obj }) {
      const response = await coders.createCoder(obj);

      commit('unshiftCard', { content: { ...response.data } });
      dispatch('user/updateMetadataByKey', {
        key: 'coders',
        value: rootState.user.metadata.coders + 1,
      });

      await this.$store.dispatch('user/updateOnboarding', { key: 'coder', value: true });

      return response;
    },
    async inviteCoder(context, { id, email }) {
      return await coders.inviteCoder({ id, email });
    },
    async resetCoderTimer(context, id) {
      return await Vue.http.put(`${Api.coders.getCoderProfile(id)}/reset`);
    },
    async getCoderLinkedin(context, id) {
      return await coders.getCoderLinkedIn(id);
    },
    async resetCoderStatus({ commit }, { id, index }) {
      const response = await coders.resetCoderStatus(id);

      commit('updateCard', {
        index,
        content: response.data,
      });

      return response;
    },
    async resetCoderStatusFromPanel({ commit }, { id, index }) {
      const response = await coders.resetCoderStatus(id);

      commit('updateCard', {
        index,
        content: response.data,
      });

      commit('updateValue', {
        storeName: 'panel',
        stateName: 'profile',
        data: response.data,
      });

      return response;
    },
    async resetCoderPendingTimer({ commit }, { id, index }) {
      const response = await coders.resetCoderPendingTimer(id);

      commit('updateCard', {
        index,
        content: response.data,
      });

      return response;
    },
    async resetCoderPendingTimerFromPanel({ commit }, { id, index }) {
      const response = await coders.resetCoderPendingTimer(id);

      commit('updateCard', {
        index,
        content: response.data,
      });

      commit('updateValue', {
        storeName: 'panel',
        stateName: 'profile',
        data: response.data,
      });

      return response;
    },
    async getDeduplication({ commit }, { firstName, lastName, email, phone, linkedin, editedProfile }) {
      let { data: deduplication } = await coders.getDeduplication({
        firstName,
        lastName,
        email,
        phone,
        linkedin,
      });
      if (editedProfile?._id) {
        deduplication = deduplication.filter(d => d._id !== editedProfile._id);
      }
      commit('setDeduplication', deduplication);
      commit('setEditedProfile', editedProfile);
      return deduplication;
    }
  },
  mutations: {
    setDeduplication(state, value) {
      state.deduplication = value;
    },
    setEditedProfile(state, value) {
      state.editedProfile = value;
    },
  },
};
