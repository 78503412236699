import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import panel from '@/common-old/store/modules/panel';
import toaster from '@/common-old/store/modules/toaster';
import card from '@/common-old/store/modules/card';
import chat from '@/common-old/store/modules/chat';
import location from '@/common-old/store/modules/location';
import autocomplete from '@/common-old/store/modules/autocomplete';
import searchbar from '@/store/modules/searchbar';
import reminders from '@/store/modules/reminders';

import localModules from '@/store/modules';

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['user', 'coaches', 'persistedRoute', 'notifications', 'onboarding.steps'],
  })],
  actions: {
    updateValue({ commit }, params) {
      commit('updateValue', params);
    },
  },
  mutations: {
    updateValue(state, params) {
      /**
       * This mutation will update a state value
       * The state must be an object
       * It must get 3 properties :
       * -storeName: define the store which will be mutate
       * -stateName: define the state inside the store which will be mutate
       * -data: define the value that the property will get. It must be an object
       */
      state[params.storeName][params.stateName] = {
        ...state[params.storeName][params.stateName],
        ...params.data,
      };
    },
  },
  modules: {
    panel,
    chat,
    toaster,
    card,
    autocomplete,
    location,
    searchbar,
    reminders,
    ...localModules,
  },
});

export const useStore = () => store;
export default store;
