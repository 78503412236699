<script lang="ts" setup>
  import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav.vue';
  import { usePanelStore } from '@/store/pinia/panel';
  import CampaignsTemplatesChoices
    from '@/components/Campaigns/CampaignsTemplates/CampaignsTemplatesChoices.vue';
  import { computed, ref } from 'vue';
  import { CampaignAction, CampaignActionKey, CampaignTemplate } from '@/domains/models/Campaign';
  import CampaignsTemplatesPreview
    from '@/components/Campaigns/CampaignsTemplates/CampaignsTemplatesPreview.vue';
  import { useCampaignsActions } from '@/components/Campaigns/composables/useCampaignsActions';

  const panelStore = usePanelStore();

  function handleClose() {
    panelStore.closeModal();
  }

  const { steps } = useCampaignsActions();

  function getStepFromKey(key: CampaignActionKey): CampaignAction {
    return steps.value.find(step => step.key === key);
  }

  const templates = computed<CampaignTemplate[]>(() => [
    {
      key: 'empty',
      name: 'Vierge',
      pictureUrl: require('@/assets/img/campaigns/templates/empty.png'),
      campaignActions: [],
    },
    {
      _id: '67938ece221d14f64b75466b',
      key: 'linkedin-recruiter',
      name: 'Linkedin Recruiter',
      pictureUrl: require('@/assets/img/campaigns/templates/linkedin-recruiter.png'),
      campaignActions: [
        getStepFromKey('LINKEDIN_PROFILE_VISIT'),
        getStepFromKey('LINKEDIN_INVITATION'),
        getStepFromKey('LINKEDIN_INMAIL_RECRUITER'),
        getStepFromKey('LINKEDIN_MESSAGE'),
        getStepFromKey('LINKEDIN_MESSAGE'),
      ],
    },
    {
      _id: '67938ece221d14f64b75466c',
      key: 'linkedin-sales',
      name: 'Linkedin Sales Navigator',
      pictureUrl: require('@/assets/img/campaigns/templates/linkedin-sales.png'),
      campaignActions: [
        getStepFromKey('LINKEDIN_PROFILE_VISIT'),
        getStepFromKey('LINKEDIN_INVITATION'),
        getStepFromKey('LINKEDIN_INMAIL_SALES_NAVIGATOR'),
        getStepFromKey('LINKEDIN_MESSAGE'),
      ],
    },
    // {
    //   _id: '67938ece221d14f64b75466c',
    //   key: 'linkedin-premium',
    //   name: 'Linkedin Premium',
    //   pictureUrl: require('@/assets/img/campaigns/templates/linkedin-premium.png'),
    //   campaignActions: [
    //     getStepFromKey('LINKEDIN_PROFILE_VISIT'),
    //     getStepFromKey('LINKEDIN_INVITATION'),
    //     getStepFromKey('LINKEDIN_INMAIL_PREMIUM'),
    //     getStepFromKey('LINKEDIN_MESSAGE'),
    //   ],
    // },
    {
      _id: '67938ece221d14f64b75466f',
      key: 'linkedin-mail',
      name: 'Linkedin + mail',
      pictureUrl: require('@/assets/img/campaigns/templates/mixed.png'),
      campaignActions: [
        getStepFromKey('LINKEDIN_PROFILE_VISIT'),
        getStepFromKey('LINKEDIN_INVITATION'),
        getStepFromKey('LINKEDIN_MESSAGE'),
        getStepFromKey('SEND_MAIL'),
        getStepFromKey('SEND_MAIL'),
      ],
    },
    {
      _id: '67938ece221d14f64b75466e',
      key: 'mail',
      name: 'Mail',
      pictureUrl: require('@/assets/img/campaigns/templates/mail.png'),
      campaignActions: [
        getStepFromKey('SEND_MAIL'),
        getStepFromKey('SEND_MAIL'),
        getStepFromKey('SEND_MAIL'),
      ],
    },
    {
      _id: '67938ece221d14f64b75466d',
      key: 'linkedin',
      name: 'Linkedin',
      pictureUrl: require('@/assets/img/campaigns/templates/linkedin-recruiter.png'),
      campaignActions: [
        getStepFromKey('LINKEDIN_PROFILE_VISIT'),
        getStepFromKey('LINKEDIN_INVITATION'),
        getStepFromKey('LINKEDIN_MESSAGE'),
        getStepFromKey('LINKEDIN_MESSAGE'),
      ],
    },
  ]);

  const selectedTemplateId = ref<string | null>(templates.value[0].key);

  function setSelectedTemplateKey(value: string) {
    selectedTemplateId.value = value;
  }

  const selectedTemplate = computed<CampaignTemplate | null>(() => {
    return templates.value.find(template => template.key === selectedTemplateId.value) ?? null;
  });
</script>

<template>
  <div class="flex w-[1280px] max-w-[100vw] flex-col overflow-auto">
    <div
      class="flex min-h-[50px] items-center justify-center gap-6 border-b border-b-[#EDF0FF] bg-neutral-200 px-[18px] py-2">
      <PanelHeaderNav
        :is-back-visible="false"
        :on-close="handleClose"
        class="absolute left-5 flex shrink-0"
      />
      <div class="flex shrink-0 items-center gap-6">
        <h2 class="shrink-0 text-2xl font-bold first-letter:capitalize">
          {{ $t('generics.create-a-resource', { resource: $t('generics.a-campaign') }) }}
        </h2>
      </div>
    </div>
    <div class="flex w-full grow">
      <CampaignsTemplatesChoices
        :selected-template="selectedTemplate"
        :templates="templates"
        @select="setSelectedTemplateKey"/>
      <CampaignsTemplatesPreview :actions="selectedTemplate?.campaignActions ?? []"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
