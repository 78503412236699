<template>
  <div class="panel-caption-contact-item">
    <div
      v-if="!list.length"
      class="panel-caption-contact-item__icons-wrapper"
      v-clipboard:copy="item.data"
      v-clipboard:success="setIsCopiedState"
      v-tooltip.bottom="{
        content: isCopied ? this.$t('generics.copied') : item.data,
        hideOnTargetClick: false,
      }">
      <div
        :class="[
          `icon-${item.icon}`,
          { 'panel-caption-contact-item__icons--activated': item.data },
        ]"
        class="panel-caption-contact-item__icons">
      </div>
    </div>
    <v-popover v-else-if="list.length >= 1" :open.sync="tooltipShow[id]" :trigger="'manual click'">
      <div
        class="tooltip-target panel-caption-contact-item__icons-wrapper"
        @mouseover="item.data && showTooltip(id)"
        @mouseleave="delay(id, hideTooltip.bind(null, id), 200)">
        <div
          :class="[
            `icon-${item.icon}`,
            { 'panel-caption-contact-item__icons--activated': item.data },
          ]"
          class="panel-caption-contact-item__icons">
        </div>
      </div>

      <template slot="popover">
        <panel-caption-contacts-list
          :data="item.data"
          :list="list"
          @on-delete="$emit('on-delete', $event)"
          @mouseover="tooltipHover[id] = true"
          @mouseleave="hideTooltip(id)" />
      </template>
    </v-popover>

    <div v-if="list.length" class="panel-caption-contact-item__counter">
      {{ list.length + 1 }}
    </div>
  </div>
</template>

<script>
import PanelCaptionContactsList from './PanelCaptionContactsList';

export default {
  name: 'panel-caption-contact-item',
  components: {
    PanelCaptionContactsList,
  },
  props: {
    id: {
      type: String,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isCopied: false,
      tooltipHover: {},
      tooltipShow: {
        [this.id]: false,
      },
    };
  },
  methods: {
    setIsCopiedState() {
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 1000);
    },
    showTooltip(tooltip) {
      this.hideAllTooltip();
      this.tooltipShow[tooltip] = true;
    },
    hideTooltip(tooltip) {
      this.tooltipShow = {
        ...this.tooltipShow,
        [tooltip]: false,
      };
      this.tooltipHover = {
        ...this.tooltipHover,
        [tooltip]: false,
      };
    },
    delay(key, cb, time) {
      setTimeout(() => {
        if (!this.tooltipHover[key]) {
          cb();
        }
      }, time);
    },
    hideAllTooltip() {
      Object.keys(this.tooltipShow).forEach(key => {
        this.tooltipShow = {
          ...this.tooltipShow,
          [key]: false,
        };
        this.tooltipHover = {
          ...this.tooltipShow,
          [key]: false,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-caption-contact-item {
  position: relative;

  &__wrapper {
    position: relative;
    margin-right: 2px;
  }

  &__icons {
    font-size: $font-size-l;
    padding: 5px;
    border-radius: 5px;
    color: rgba($color-blue-dark-cello, 0.3);
    cursor: unset;

    &--activated {
      color: $color-primary;

      &:hover {
        background-color: rgba($color-primary, 0.15);
      }
    }
  }

  &__counter {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 8px;
    font-weight: $font-weight-bold;
    color: white;
    background: $color-red-mandy;
    width: 11px;
    height: 11px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
  }
}
</style>

<style lang="scss">
.tooltip.popover .popover-inner {
  padding: 0 !important;
}
</style>
