<script lang="ts" setup>
  import CampaignsFiltersType
    from '@/components/Campaigns/CampaignsFilters/CampaignsFiltersType.vue';
  import CampaignsFiltersStatus
    from '@/components/Campaigns/CampaignsFilters/CampaignsFiltersStatus.vue';
  import CampaignsFiltersSearchbar
    from '@/components/Campaigns/CampaignsFilters/CampaignsFiltersSearchbar.vue';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { usePanelStore } from '@/store/pinia/panel';
  import CampaignsModal from '@/components/Campaigns/CampaignsModal.vue';
  import CampaignsModalCreate from '@/components/Campaigns/CampaignsModal/CampaignsModalCreate.vue';

  const panelStore = usePanelStore();

  function goCreateCampaign() {
    panelStore.openModal<{}>(CampaignsModalCreate, {});
  }
</script>

<template>
  <div class="flex items-center gap-2">
    <CampaignsFiltersType/>
    <CampaignsFiltersStatus/>
    <CampaignsFiltersSearchbar/>
    <BcButton
      class="capitalize"
      icon-left="plus"
      @click="goCreateCampaign">
      {{ $t('campaigns.new-campaign') }}
    </BcButton>
    <CampaignsModal/>
  </div>
</template>

<style lang="scss" scoped>

</style>
