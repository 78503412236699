<template>
  <div class="panel-favorite-list-update-rpo">
    <div
      v-if="isLoading"
      class="panel-favorite-list-update-rpo__container">
      <spinner></spinner>
    </div>
    <div
      v-else
      class="panel-favorite-list-update-rpo__container">
      <panel-header>
        <panel-favorite-list-header
          :current-coach="currentCoach"
          :list="list"
          @confirm-delete-list="modalType = 'delete-list'">
        </panel-favorite-list-header>
      </panel-header>
      <ValidationObserver
        ref="observer"
        v-slot="{ valid }"
        class="panel-favorite-list-update-rpo__wrapper"
        tag="form"
        @submit.prevent>
        <div class="panel-favorite-list-update-rpo__body">
          <panel-favorite-list-name
            v-model="editedList.name"
            :current-coach="currentCoach"
            :locked-list="list.locked">
          </panel-favorite-list-name>
          <panel-favorite-list-export
            v-if="isUserBusinessManager"
            :list="list">
          </panel-favorite-list-export>
          <panel-favorite-list-share
            v-if="coaches.length > 1"
            :current-coach="currentCoach"
            :current-owner="currentOwner"
            :edited-list-subscribers="editedList.subscribers"
            :list-subscribers="list.subscribers"
            :locked-list="list.locked"
            class="panel-favorite-list-update-rpo__list-share"
            @add-subscriber="pushSubscriber"
            @leave-list="openModal($event, 'confirm-leave-list')"
            @remove-subscriber="removeSubscriber"
            @remove-subscriber-and-delete-list="openModal($event, 'remove-subscriber-and-delete-list')"
            @set-to-admin="setRole($event, 'admin')"
            @set-to-owner="openModal($event, 'set-to-new-owner')"
            @set-to-reader="setRole($event, 'reader')">
          </panel-favorite-list-share>
        </div>
        <panel-favorite-list-footer
          v-if="!isCurrentCoachReader"
          :edited-list="editedList"
          :is-valid-form="valid"
          :list="list">
        </panel-favorite-list-footer>
      </ValidationObserver>
    </div>
    <modal
      v-if="modalType"
      @close-modal="closeModal">
      <component
        :is="currentModal"
        :is-alone="list.subscribers.length === 1"
        @close-modal="closeModal"
        @on-validate="confirmModalAction">
      </component>
    </modal>
  </div>
</template>

<script>
  // http
  import favoriteListHttp from '@/api/favoriteLists';
  // macros
  import favoriteListsMacros from '@/macros/favoriteLists/favoriteLists';
  // mixins
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  //
  import PanelFavoriteListHeader
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListHeader';
  import PanelFavoriteListFooter
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListFooter';
  import PanelHeader from '@/components/Panel/PanelHeader';
  import PanelFavoriteListName from '@/components/Panel/PanelFavoriteList/PanelFavoriteListName';
  import PanelFavoriteListShare from '@/components/Panel/PanelFavoriteList/PanelFavoriteListShare';
  import Modal from '@/components/Modal/Modal';
  import PanelFavoriteListModalNewOwner
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListModal/PanelFavoriteListModalNewOwner';
  import PanelFavoriteListModalLeaveList
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListModal/PanelFavoriteListModalLeaveList';
  import PanelFavoriteListModalDeleteList
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListModal/PanelFavoriteListModalDeleteList';
  import Spinner from '@/legacy/ui-kit/components/Spinner/BcSpinner';
  import { ValidationObserver } from 'vee-validate';
  import PanelFavoriteListModalLeaveListAndDeleteList
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListModal/PanelFavoriteListModalLeaveListAndDeleteList';
  import PanelFavoriteListExport
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListExport';
  import { apiRoles } from '@/macros/coaches/roles';

  export default {
    name: 'panel-favorite-list-update-rpo',
    components: {
      PanelFavoriteListExport,
      PanelFavoriteListModalLeaveListAndDeleteList,
      Spinner,
      PanelFavoriteListModalNewOwner,
      Modal,
      PanelFavoriteListShare,
      PanelFavoriteListFooter,
      PanelFavoriteListHeader,
      PanelFavoriteListName,
      PanelHeader,
      PanelFavoriteListModalLeaveList,
      PanelFavoriteListModalDeleteList,
      ValidationObserver,
    },
    mixins: [PanelNavigation],
    data() {
      return {
        list: {
          name: '',
          locked: false,
          subscribers: [],
        },
        editedList: {
          name: '',
          subscribers: [],
        },
        ownerState: {},
        isLoading: false,
        modalType: '',
        mapListType: ['coder'],
      };
    },
    created() {
      this.getFavoriteListDetails();
    },
    computed: {
      coaches() {
        return this.$store.state.coaches?.coaches;
      },
      listId() {
        return this.$route.query['list-id'] ? this.$route.query['list-id'] : this.$route.params.id;
      },
      userId() {
        return this.$store.state.user.profile._id;
      },
      isUserBusinessManager() {
        return this.$store.state.user?.profile?.role === apiRoles.businessManager;
      },
      isCurrentCoachReader() {
        return this.currentCoach.role === favoriteListsMacros.apiRights.reader;
      },
      currentOwner() {
        return this.editedList.subscribers.find(subscriber => subscriber.role === favoriteListsMacros.apiRights.owner) || {};
      },
      currentCoach() {
        return this.list.subscribers.find(subscriber => subscriber._id === this.userId) || {};
      },
      currentModal() {
        const maps = {
          'set-to-new-owner': 'panel-favorite-list-modal-new-owner',
          'confirm-leave-list': 'panel-favorite-list-modal-leave-list',
          'remove-subscriber-and-delete-list': 'panel-favorite-list-modal-leave-list-and-delete-list',
          'delete-list': 'panel-favorite-list-modal-delete-list',
        };

        return maps[this.modalType];
      },
    },
    methods: {
      async getFavoriteListDetails() {
        this.isLoading = true;

        try {
          const res = await favoriteListHttp.getFavoriteListById(this.listId);

          this.list = {
            ...res.data,
            subscribers: res.data.subscribers.map(subscriber => ({
              ...subscriber._coach,
              role: subscriber.role,
              notifications: subscriber.notifications,
            })),
          };

          this.editedList = {
            ...res.data,
            subscribers: res.data.subscribers.map(subscriber => ({
              ...subscriber._coach,
              role: subscriber.role,
              subscriber: subscriber.notifications,
            })),
          };
        } catch (error) {
          if (error.response.status === 403) {
            this.$toast.show({
              type: 'error',
              message: this.$i18n.t('toast.no-right-to-list-access'),
            });
          } else {
            this.$toast.show({
              type: 'error',
              message: this.$t('toast.error-occured'),
            });
          }
        }

        this.isLoading = false;
      },
      pushSubscriber(subscriber) {
        subscriber.isAdded = true;
        subscriber.role = favoriteListsMacros.apiRights.reader;

        this.editedList.subscribers.push(subscriber);
      },
      setRole(subscriber, role) {
        const index = (this.editedList.subscribers || []).findIndex(coach => coach._id === subscriber._id);

        this.editedList.subscribers[index].role = favoriteListsMacros.apiRights[role];
      },
      removeSubscriber(subscriber) {
        this.editedList.subscribers = this.editedList.subscribers.filter(coach => coach._id !== subscriber._id);
      },
      openModal($event, modal) {
        this.modalType = modal;

        this.ownerState = $event;
      },
      setNewOwner() {
        const oldOwnerIndex = this.editedList.subscribers.findIndex(subscriber => subscriber.role === favoriteListsMacros.apiRights.owner);
        const newOwnerIndex = this.editedList.subscribers.findIndex(subscriber => subscriber._id === this.ownerState._id);

        this.editedList.subscribers[oldOwnerIndex].role = favoriteListsMacros.apiRights.admin;
        this.editedList.subscribers[newOwnerIndex].role = favoriteListsMacros.apiRights.owner;

        this.closeModal();
      },
      async leaveList() {
        try {
          await favoriteListHttp.editFavoriteList({
            listId: this.listId,
            removedSubscribers: [{
              _coach: this.userId,
              role: this.currentCoach.role,
            }],
          });

          this.$toast.show({
            type: 'success',
            message: `${this.$i18n.t('toast.you-left')}
 «${this.list.name}»`,
          });

          this.$store.dispatch('deleteFavoriteListWithoutCall', {
            listId: this.list._id,
            listType: this.list.type,
          });

          this.$store.dispatch('removeCard', this.listId);

          if (this.$route.name.includes('FavoriteList')) {
            this.$router.replace({
              name: 'FavoriteLists',
              query: {
                'list-type': this.$route.query['list-type'],
              },
            });
          } else {
            this.closePanel();
          }
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      async deleteList() {
        try {
          await this.$store.dispatch('deleteFavoriteList', {
            listId: this.list._id,
            listType: this.list.type,
          });

          this.$toast.show({
            type: 'success',
            message:  `${this.$i18n.t('toast.you-deleted')} «${this.list.name}»`,
          });

          this.$store.dispatch('removeCard', this.listId);

          if (this.$route.name === 'FavoriteListPanel') {
            this.$router.replace({
              name: 'FavoriteLists',
              query: {
                'list-type': this.$route.query['list-type'],
              },
            });
          } else {
            this.closePanel();
          }
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      confirmModalAction($event) {

        const actions = {
          setOwner: this.setNewOwner,
          leaveList: this.leaveList,
          deleteList: this.deleteList,
          leaveListAndDeleteList: this.deleteList,
        };

        actions[$event]();
      },
      closeModal() {
        this.modalType = '';
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-favorite-list-update-rpo {
    position: relative;
    flex-direction: column;
    height: 100%;
    background: white;

    &__container {
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      height: 100%;
    }

    &__wrapper {
      flex-direction: column;
      flex-grow: 1;
      height: calc(100% - 56px);
    }

    &__body {
      padding: 20px;
      flex-direction: column;
      flex-grow: 1;
      overflow: auto;
      @include bp('tablet') {
        padding: 0 50px;
      }
    }

    &__list-share {
      padding-bottom: 50px;
    }
  }
</style>
