import { defineStore } from 'pinia';
import { Process } from '@/domains/models/Process';

import {
  updateProcessCustomStep,
  getProcessesCustomAggregate,
  abortProcessCustom,
  updateProcessCustom,
  unabortProcessCustom,
} from '@/api/processes-custom';

type State = {
  applicationsList: Process[];
  applicationsTotal: number;
  isLoading: boolean;
}

export const useProcessesStore = defineStore('processes', {
  state: (): State => {
    return {
      applicationsList: [],
      applicationsTotal: 0,
      isLoading: false,
    };
  },
  actions: {
    async fetchApplications({
      isApplication,
      isNewApplication,
      isApplicationTreated,
      withPagination,
      page,
      pageSize,
      coachIds,
      customJobOwnersIds,
      ...query
    }: {
      isApplication: boolean;
      isNewApplication: boolean;
      isApplicationTreated: boolean;
      withPagination: boolean;
      page: number;
      pageSize: number;
      coachIds: string[];
      customJobOwnersIds: string[];
    } & Record<string, any>): Promise<[Process[], number]> {
      try {
        this.isLoading = true;
        const queries = {
          isApplication,
          isNewApplication,
          isApplicationTreated,
          withPagination,
          page,
          pageSize,
          coachIds,
          customJobOwnersIds,
          ...query,
        };
        const { data } = await getProcessesCustomAggregate(queries);
        this.applicationsList = data?.[0] || [];
        this.applicationsTotal = data?.[1];
        this.isLoading = false;

        return data;
      } catch (err) {
        this.isLoading = false;
        throw new Error('Could not fetch applications list');
      }
    },
    updateCardInfo({
      processId,
      email,
      emails,
      phone,
      phones,
      firstName,
      lastName,
      title,
    }: {
      processId: string;
      email?: string;
      emails?: string[];
      phone?: string;
      phones?: string[];
      firstName?: string;
      lastName?: string;
      title?: string;
    }) {
      // Find the index of the process in the applications list
      const processIndex = this.applicationsList.findIndex(process => process._id === processId);
    
      if (processIndex !== -1) {
        const existingCoder = this.applicationsList[processIndex]._coder || {};
    
        this.applicationsList = [
          ...this.applicationsList.slice(0, processIndex),
          {
            ...this.applicationsList[processIndex],
            _coder: {
              ...existingCoder,
              ...(email !== undefined && { email }),
              ...(emails !== undefined && { emails }),
              ...(phone !== undefined && { phone }),
              ...(phones !== undefined && { phones }),
              ...(firstName !== undefined && { firstName }),
              ...(lastName !== undefined && { lastName }),
              ...(title !== undefined && { title }),
            },
          },
          ...this.applicationsList.slice(processIndex + 1),
        ];
      }
    },    
    async onUpdate({ processId, ...query }: { processId: string; } & Record<string, any>) {
      const { data } = await updateProcessCustom({ id: processId, ...query });

      const findProcess = this.applicationsList.find(process => process._id === processId)
      if (findProcess) {
        const candidateStepKey = data?._organization?.configuration?.process?.categories?.[0]?.steps?.[0]?.key;
        if (candidateStepKey && candidateStepKey !== data.historySteps[data.historySteps.length - 1]?.stepKey) {
          this.applicationsList = this.applicationsList.filter(process => process._id !== data._id);
        }
      }

      return data;
    },
    async onUpdateStep({ processId, categoryKey, stepKey }: { processId: string; categoryKey: string; stepKey: string; }) {
      const { data } = await updateProcessCustomStep({
        id: processId,
        categoryKey,
        stepKey,
      });

      const findProcess = this.applicationsList.find(process => process._id === processId)
      if (findProcess) {
        const candidateStepKey = data?._organization?.configuration?.process?.categories?.[0]?.steps?.[0]?.key;
        if (candidateStepKey && candidateStepKey !== data.historySteps[data.historySteps.length - 1]?.stepKey) {
          this.applicationsList = this.applicationsList.filter(process => process._id !== data._id);
        }
      }

      return data;
    },
    async onAccept({ processId, categoryKey, stepKey }: { processId: string; categoryKey: string; stepKey: string; }) {
      const { data } = await updateProcessCustomStep({
        id: processId,
        categoryKey,
        stepKey,
      });
      this.applicationsList = this.applicationsList.filter(process => process._id !== data._id);

      return data;
    },
    async onReject({ processId }: { processId: string; }) {
      const { data } = await abortProcessCustom(processId, { reason: '', rejected: true });
      this.applicationsList = this.applicationsList.filter(process => process._id !== data._id);

      return data;
    },
    async onAbort({ processId, reason = '', rejected = false }: { processId: string; reason?: string; rejected?: boolean }) {
      const { data } = await abortProcessCustom(processId, { reason, rejected });
      this.applicationsList = this.applicationsList.filter(process => process._id !== data._id);

      return data;
    },
    async onDelete({ processId }: { processId: string; }) {
      const { data } = await updateProcessCustom({ id: processId, delete: true });
      this.applicationsList = this.applicationsList.filter(process => data._id !== process._id);

      return data;
    },
    async onDeleteMultiple({ processes }: { processes: string[]; }) {
      const results = await Promise.all(processes.map(processId => {
        return updateProcessCustom({ id: processId, delete: true });
      }));
      const deletedProcessesIds = results.map(result => result?.data?._id);
      this.applicationsList = this.applicationsList.filter(process => !deletedProcessesIds.includes(process._id));
      return results;
    },
    async onUnabort({ processId }: { processId: string }) {
      const { data } = await unabortProcessCustom(processId);
      return data;
    },
  }
});
