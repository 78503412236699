<template>
  <div
    :class="{ 'merge-coder-profile--red': isProfileToDelete }"
    class="merge-coder-profile is-column">
    <p
      class="merge-coder-profile__title title--bold"
      :class="{ 'merge-coder-profile__title--red': isProfileToDelete }"
    >
      {{ isProfileToDelete ? $t('candidates.profile-to-delete') : $t('candidates.profile-selection') }}
    </p>
    <div class="merge-coder-profile__avatar is-align-items-end">
      <bc-avatar
        :src="profile.pictureUrl"
        @error="setAlternativeAvatar"
        size="l">
      </bc-avatar>
      <v-popover
        data-pictureUrl-conflict
        placement="right"
        popover-base-class="tooltip tooltip-merge-coder"
        popover-inner-class="popover popover-merge-coder"
        trigger="click" v-show="conflicts.includes('pictureUrl')">
        <div
          class="icon-alert merge-coder-profile__alert is-align-items-center is-justify-content-center"></div>
        <template slot="popover">
          <div class="is-column">
            <p class="merge-coder-profile__text merge-coder-profile__text--white">
              {{ $t('candidates.update-in-final-profile') }}
            </p>
            <div class="is-row is-justify-content-center">
              <bc-button
                @click.native="changeCoderInfo('pictureUrl')"
                class="merge-coder-profile__popover-cta"
                color="success"
                data-confirm-merge-button
                v-close-popover>
                {{ $t('generics.yes') }}
              </bc-button>
              <bc-button
                class="merge-coder-profile__popover-cta"
                color="secondary"
                v-close-popover>
                {{ $t('generics.no') }}
              </bc-button>
            </div>
          </div>
        </template>
      </v-popover>
    </div>
    <div class="merge-coder-profile__row is-align-items-center">
      <p class="merge-coder-profile__text is-capitalize">
        {{ profile.firstName }} {{ profile.lastName }}
      </p>
      <div
        class="merge-coder-profile__remote"
        slot="icon"
        v-if="(profile.remote || []).length > 0">
        <div
          class="merge-coder-profile__remote-icon icon-remote-available"
          v-if="(profile.remote || []).some(value => value === remote.available)"
          v-tooltip.top="$i18n.t('panel-coder-edit.possible-remote')">
        </div>

        <div
          class="merge-coder-profile__remote-icon icon-remote-partial"
          v-if="(profile.remote || []).some(value => value === remote.partial)"
          v-tooltip.top="$i18n.t('panel-coder-edit.partial-remote')">
        </div>

        <div
          class="merge-coder-profile__remote-icon icon-remote-full"
          v-if="(profile.remote || []).some(value => value === remote.full)"
          v-tooltip.top="$i18n.t('panel-coder-edit.full-remote')">
        </div>
      </div>
      <v-popover
        data-remote-conflict
        placement="right"
        popover-base-class="tooltip tooltip-merge-coder"
        popover-inner-class="popover popover-merge-coder"
        trigger="click" v-show="conflicts.includes('remote')">
        <div
          class="icon-alert merge-coder-profile__alert is-align-items-center is-justify-content-center"></div>
        <template slot="popover">
          <div class="is-column">
            <p class="merge-coder-profile__text merge-coder-profile__text--white">
              {{ $t('candidates.update-in-final-profile') }}
            </p>
            <div class="is-row is-justify-content-center">
              <bc-button
                @click.native="changeCoderInfo('remote')"
                class="merge-coder-profile__popover-cta"
                color="success"
                data-confirm-merge-button
                v-close-popover>
                {{ $t('generics.yes') }}
              </bc-button>
              <bc-button
                class="merge-coder-profile__popover-cta"
                color="secondary"
                v-close-popover>
                {{ $t('generics.no') }}
              </bc-button>
            </div>
          </div>
        </template>
      </v-popover>
    </div>
    <div class="merge-coder-profile__row is-align-items-center">
      <p
        class="merge-coder-profile__text merge-coder-profile__text--bold merge-coder-profile__text--secondary">
        {{ profile.title }}
      </p>
      <v-popover
        data-title-conflict
        placement="right"
        popover-base-class="tooltip tooltip-merge-coder"
        popover-inner-class="popover popover-merge-coder"
        trigger="click" v-show="conflicts.includes('title')">
        <div
          class="icon-alert merge-coder-profile__alert is-align-items-center is-justify-content-center"></div>
        <template slot="popover">
          <div class="is-column">
            <p class="merge-coder-profile__text merge-coder-profile__text--white">
              {{ $t('candidates.update-in-final-profile') }}
            </p>
            <div class="is-row is-justify-content-center">
              <bc-button
                @click.native="changeCoderInfo('title')"
                class="merge-coder-profile__popover-cta"
                color="success"
                data-confirm-merge-button
                v-close-popover>
                {{ $t('generics.yes') }}
              </bc-button>
              <bc-button
                class="merge-coder-profile__popover-cta"
                color="secondary"
                v-close-popover>
                {{ $t('generics.no') }}
              </bc-button>
            </div>
          </div>
        </template>
      </v-popover>
    </div>
    <div class="merge-coder-profile__row is-align-items-center">
      <i class="merge-coder-profile__icon icon-diploma"></i>
      <p
        class="merge-coder-profile__text merge-coder-profile__text--small merge-coder-profile__text--secondary">
        {{ $t(profileExperience) }}
      </p>
      <v-popover
        data-experience-conflict
        placement="right"
        popover-base-class="tooltip tooltip-merge-coder"
        popover-inner-class="popover popover-merge-coder"
        trigger="click" v-show="conflicts.includes('experience')">
        <div
          class="icon-alert merge-coder-profile__alert is-align-items-center is-justify-content-center"></div>
        <template slot="popover">
          <div class="is-column">
            <p class="merge-coder-profile__text merge-coder-profile__text--white">
              {{ $t('candidates.update-in-final-profile') }}
            </p>
            <div class="is-row is-justify-content-center">
              <bc-button
                @click.native="changeCoderInfo('experience')"
                class="merge-coder-profile__popover-cta"
                color="success"
                data-confirm-merge-button
                v-close-popover>
                {{ $t('generics.yes') }}
              </bc-button>
              <bc-button
                class="merge-coder-profile__popover-cta"
                color="secondary"
                v-close-popover>
                {{ $t('generics.no') }}
              </bc-button>
            </div>
          </div>
        </template>
      </v-popover>
    </div>
    <div class="merge-coder-profile__row is-align-items-center">
      <i class="merge-coder-profile__icon icon-map-pin"></i>
      <p
        class="merge-coder-profile__text merge-coder-profile__text--small merge-coder-profile__text--secondary">
        {{ profileLocation }}
      </p>
    </div>
    <div class="merge-coder-profile__row is-align-items-center">
      <i class="merge-coder-profile__icon icon-linkedin"></i>
      <p
        class="merge-coder-profile__text merge-coder-profile__text--small merge-coder-profile__text--secondary">
        {{ profile.social?.linkedin }}
      </p>
      <v-popover
        data-social-conflict
        placement="right"
        popover-base-class="tooltip tooltip-merge-coder"
        popover-inner-class="popover popover-merge-coder"
        trigger="click" v-show="conflicts.includes('social')">
        <div
          class="icon-alert merge-coder-profile__alert is-align-items-center is-justify-content-center"></div>
        <template slot="popover">
          <div class="is-column">
            <p class="merge-coder-profile__text merge-coder-profile__text--white">
              {{ $t('candidates.update-in-final-profile') }}
            </p>
            <div class="is-row is-justify-content-center">
              <bc-button
                @click.native="changeCoderInfo('social')"
                class="merge-coder-profile__popover-cta"
                color="success"
                data-confirm-merge-button
                v-close-popover>
                {{ $t('generics.yes') }}
              </bc-button>
              <bc-button
                class="merge-coder-profile__popover-cta"
                color="secondary"
                v-close-popover>
                {{ $t('generics.no') }}
              </bc-button>
            </div>
          </div>
        </template>
      </v-popover>
    </div>
    <div class="merge-coder-profile__row--spaced is-align-items-center">
      <p
        class="merge-coder-profile__text merge-coder-profile__text--bold merge-coder-profile__text--secondary">
        {{ $t('candidates.job') }}
      </p>
      <hr class="merge-coder-profile__divider"/>
    </div>
    <div class="is-flex-wrap is-align-items-center">
      <bc-tag
        :key="index"
        class="merge-coder-profile__tag"
        tone="light"
        v-for="(domain, index) in profileDomains">
        {{ $t(domain) }}
      </bc-tag>
    </div>
    <div class="is-flex-wrap is-align-items-center">
      <bc-tag
        :key="index"
        class="merge-coder-profile__tag"
        v-for="(techno, index) in profileTechnos">
        {{ techno.name }}
      </bc-tag>
    </div>
  </div>
</template>

<script>
  import { getDomainFromApiValue } from '@/common-old/macros/market';
  import { getRoleNameFromApiValue } from '@/common-old/macros/roles';
  import { apiRemote } from '@/common-old/macros/remote';
  //
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import BcTag from '@/ui-kit/components/BcTag/BcTag';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import { getExperienceNameFromExperienceNumber } from '@/managers/coders/formatters/codersDeserializers';
import { getCompleteAddressOrTag } from '@/formatters/locations';

  export default {
    name: 'merge-coder-profile',
    components: { BcButton, BcTag, BcAvatar },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
        required: true,
      },
      isProfileToDelete: {
        type: Boolean,
        default: false,
      },
      conflicts: {
        type: Array,
        default: () => ([]),
      },
    },
    data() {
      return {
        remote: {
          available: apiRemote.available,
          partial: apiRemote.partial,
          full: apiRemote.full,
        },
        errorOnAvatar: false,
      };
    },
    computed: {
      profileExperience() {
        return getExperienceNameFromExperienceNumber(this.profile.experience);
      },
      profileLocation() {
        return getCompleteAddressOrTag(this.profile.locations?.[0]) || '';
      },
      profileDomains() {
        return [
          ...(this.profile.domains || []).map(domain => getDomainFromApiValue(domain).name),
          ...(this.profile.role ? [getRoleNameFromApiValue(this.profile.role)] : []),
        ];
      },
      profileTechnos() {
        return [
          ...(this.profile._technos1 && this.profile._technos1.length ? this.profile._technos1 : []),
          ...(this.profile._technos2 && this.profile._technos2.length ? this.profile._technos2 : []),
          ...(this.profile._technos3 && this.profile._technos3.length ? this.profile._technos3 : []),
        ];
      },
    },
    methods: {
      setAlternativeAvatar(event) {
        event.target.src = require('@/assets/img/default-avatar.svg');
      },
      changeCoderInfo(key) {
        this.$emit('change', key);
      },
    },
  };
</script>

<style lang=scss scoped>
  .merge-coder-profile {
    width: 100%;
    padding: 30px;
    border: 1px solid $color-blue-medium;
    background: $color-neutre-4;
    overflow: scroll;
    border-radius: 5px;

    &--red {
      border: 1px solid #F5DFE0;
      background: #F5DFE033;
    }

    &__avatar {
      margin-bottom: 20px;
    }

    &__row {
      margin-bottom: 10px;

      &--spaced {
        margin: 5px 0 15px 0;
      }
    }


    &__title {
      text-align: center;

      &--red {
        color: $color-red-mandy;
      }
    }

    &__text {
      width: fit-content;
      @include font-regular(16px);
      text-align: left;
      line-height: 19px;
      color: $color-primary;

      &--bold {
        @include font-bold(14px);
        line-height: 16px;
      }

      &--white {
        color: white;
      }

      &--small {
        @include font-regular(14px);
        line-height: 15px;
      }

      &--secondary {
        color: $color-secondary-dark
      }
    }

    &__icon {
      width: 16px;
      height: 16px;
      @include font-size(16px);
      color: $color-primary;
      margin-right: 10px;
    }

    &__divider {
      width: 200px;
      margin-left: 20px;
      border-top: 1px solid $color-primary-light;
    }

    &__tag {
      margin-right: 5px;
      margin-bottom: 5px;
      text-transform: capitalize;
    }

    &__remote {
      margin-left: 10px;
    }

    &__remote-icon {
      @include font-size(15px);
      height: 30px;
      width: 30px;
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      background: $color-blue-hawkes;
      color: $color-blue-cello;
      margin-left: -5px;
      border: 1px solid $color-white;

      &:first-of-type {
        margin-left: 0;
      }
    }

    &__alert {
      width: 18px;
      height: 18px;
      background-color: $color-error;
      color: white;
      border-radius: 50%;
      margin-left: 5px;
      @include font-size(9px);

      &:hover {
        cursor: pointer;
      }
    }

    &__popover-cta {
      margin: 10px 5px 0;
    }
  }
</style>

<style lang="scss">
  .tooltip-merge-coder {
    min-width: 250px;
    z-index: 99999 !important;
  }

  .popover-merge-coder {
    padding: 15px;
    background-color: $color-secondary-light;
    color: $color-white;
    border-radius: $border-radius-m;
  }
</style>
