<template>
  <div
    v-click-outside="closePanel"
    class="panel-organization-body is-align-items-center">
    <bc-avatar
      :src="company.logoUrl"
      alt="organization logo"
      class="panel-organization-body__picture"
      size="l"
      @error="setAlternativeImg">
    </bc-avatar>
    <div class="panel-organization-body__container is-align-items-start is-column">
      <div class="panel-organization-body__wrapper is-column">
        <p class="panel-organization-body__text is-secondary font-size-l font-weight-medium">
          {{ company.name }}
        </p>
        <router-link
          :to="companyLink"
          class="panel-organization-body__text panel-organization-body__link is-secondary">
          {{ $t('companies.panel-hunt-process.see-the-company-profile') }}
        </router-link>
      </div>
      <div
        v-if="profile._industries && profile._industries.length"
        class="panel-organization-body__industries panel-organization-body__tag-wrapper mt-2">
        <bc-tag
          v-for="industry in profile._industries"
          :key="industry._id"
          class="panel-organization-body__tag">
          {{ industry.name }}
        </bc-tag>
      </div>
    </div>
  </div>
</template>

<script>
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import BcTag from '@/ui-kit/components/BcTag/BcTag';

  export default {
    name: 'panel-organization-body',
    components: {
      BcTag,
      BcAvatar,
    },
    mixins: [PanelNavigation],
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      company() {
        return this.profile._company || {};
      },
      companyLink() {
        return {
          name: this.$route.name,
          params: { id: this.profile._id },
          query: { ...this.$route.query, type: 'companies' },
        };
      },
    },
    methods: {
      setAlternativeImg(event) {
        event.target.src = require('@/assets/img/default-avatar.svg');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-organization-body {
    flex-direction: column;
    justify-content: center;
    background-color: white;
    flex-shrink: 0;
    flex-grow: 1;
    @include bp('tablet') {
      flex-direction: row;
      justify-content: flex-start;
      height: 110px;
      max-height: 110px;
      padding: 15px;
    }

    &__picture {
      margin-bottom: 20px;
      @include bp('tablet') {
        margin-bottom: 0;
        margin-left: 70px;
        margin-right: 20px;
      }
    }

    &__text {
      line-height: 1.5em;
    }

    &__link {
      &:hover {
        color: $color-primary;
        text-decoration: underline;
      }
    }

    &__tag-wrapper {
      margin: -5px
    }

    &__tag {
      margin: 5px;
    }
  }
</style>
