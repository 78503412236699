export const formatFormDeal = (resource) => ({
  ...resource,
  ...resource.formDeal && {
    formDeal: {
      ...resource.formDeal,
      ...resource.formDeal.coderPass && { coderPass: formPass(resource.formDeal.coderPass) },
      ...resource.formDeal.jobPass && { jobPass: formPass(resource.formDeal.jobPass) },
    }
  }
});

export const formPass = (pass) => ({
  ...pass,
  ...pass._coach && { _coach: pass._coach._id },
});
