<template>
  <div class="searchbar-coders-filters">
    <filter-professions></filter-professions>
    <filter-skills></filter-skills>
    <filter-coders-job-title></filter-coders-job-title>
    <filter-coders-location></filter-coders-location>
    <filter-coders-more></filter-coders-more>
  </div>
</template>

<script>
import FilterProfessions from '@/components/Filter/FilterProfessions';
import FilterSkills from '@/components/Filter/FilterSkills';
import FilterCodersLocation from '@/components/Filter/Coders/FilterCodersLocation';
import FilterCodersMore from '@/components/Filter/Coders/FilterCodersMore';
import FilterCodersJobTitle from '@/components/Filter/Coders/FilterCodersJobTitle.vue';

export default {
  name: 'searchbar-coders-filters',
  components: {
    FilterProfessions,
    FilterSkills,
    FilterCodersLocation,
    FilterCodersMore,
    FilterCodersJobTitle
  },
}
</script>

<style lang="scss" scoped>
.searchbar-coders-filters {
  gap: 10px;
}
</style>
