<template>
  <div class="is-column is-align-items-center reminders-card-list">
    <BcSpinner v-if="isLoading" class="reminders-card-list__spinner"> </BcSpinner>
    <RemindersCardListRefresh
      v-else-if="isRecommendationsCategorySelected && !remindersCards.length"
      @refresh-pool="$emit('refresh-pool')" />
    <div v-else-if="remindersCards.length" class="is-align-items-center is-column is-full-width">
      <RemindersCodersGrid :is-checked="isAllCardsSelected" @checked="selectAllCards" />
      <CardList :cards="remindersCards" class="reminders-card-list__wrapper">
        <template #card="{ card }">
          <CardReminders
            :card-content="card"
            :is-selected="getIsSelectedStatus(card._id)"
            card-type="coders"
            @checked="setSelectedCards($event, card)">
            <CardRemindersCoders :card-content="card" context="pool" />
          </CardReminders>
        </template>
      </CardList>
    </div>
    <MultiselectFooter
      v-if="selectedCards.length"
      class="reminders-card-list__footer is-fixed is-bottom is-left is-full-width is-justify-content-center is-align-items-center is-column" />
  </div>
</template>

<script setup lang="ts">
import { useMultiselect } from '@/composables/useMultiselect';
//
import CardReminders from '@/components/Card/CardReminders.vue';
import CardList from '@/components/CardList/CardList.vue';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
import RemindersCodersGrid from '@/components/Reminders/RemindersCodersGrid.vue';
import CardRemindersCoders from '@/components/Card/CardReminders/CardRemindersCoders.vue';
import MultiselectFooter from '@/components/Multiselect/MultiselectFooterLegacy.vue';
//
import suggestionsMacros from '@/macros/suggestions';
import RemindersCardListRefresh from '@/components/Reminders/RemindersCardList/RemindersCardListRefresh.vue';
import { useStore } from '@/store';
import { useRoute } from 'vue-router/composables';
import { computed, onBeforeUnmount } from 'vue';

const props = withDefaults(defineProps<{
  isLoading: boolean;
}>(), {
  isLoading: false,
});

const store = useStore();
const route = useRoute();
const {
  selectedCards,
  getIsSelectedStatus,
  isAllCardsSelected,
  selectAllCards,
  resetSelectedCards,
  setSelectedCards,
} = useMultiselect();

const remindersCards = computed(() => store.state.card.cards);

const isRecommendationsCategorySelected = computed(() => {
  return (
    Number(route.query['suggestions-category']) ===
    suggestionsMacros.categories.recommendation.value
  );
});

onBeforeUnmount(() => {
  resetSelectedCards();
});
</script>

<style lang="scss" scoped>
.reminders-card-list {
  &__wrapper {
    margin: 0 0 30px;
    padding-bottom: 50px;
  }

  &__spinner {
    margin: 100px 0;
  }

  &__text {
    color: $color-secondary;
    font-weight: $font-weight-bold;
  }
}
</style>
