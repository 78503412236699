<template>
  <span>
    <draggable
      v-if="items.length"
      class="custom-jobs-list"
      :disabled="!isDraggable"
      v-model="customJobsOrders"
      draggable=".draggable"
      handle=".handle"
      animation="200"
      ghost-class="custom-jobs-list--ghost"
      @end="endDrag"
    >
      <transition-group>
        <component
          :is="displayMode"
          v-for="customJob in customJobsOrders"
          class="draggable"
          :key="customJob._id"
          :customJob="customJob"
          :show-status="showStatus"
          :is-draggable="isDraggable"
          :window-width="windowWidth"
          @click="handleClickItem"
          @click-active-processes="handleClickActiveProcesses"
          @on-action="onAction" />
      </transition-group>
    </draggable>
    <infinite-loading
      :identifier="identifier"
      @infinite="loadMore"
      style="justify-content: center;"
    >
      <bc-spinner slot="spinner" :style="{marginTop: items.length ? 0 : '20vh'}"></bc-spinner>
      <span slot="no-more">
      </span>
      <div slot="no-results"><custom-jobs-empty /></div>
    </infinite-loading>
    <custom-jobs-delete-warning-modal
      :isOpen="showDeleteWarning"
      @close="onCloseDeleteWarning"
      @on-confirm="onConfirmDelete()" />
    <custom-jobs-close-warning-modal
      :isOpen="showCloseWarning"
      @close="onCloseCloseWarning"
      @on-confirm="onCloseOpportunity()" />
  </span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import { updateCustomJobPosition, duplicateCustomJob } from '@/api/custom-jobs';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import CustomJobsEmpty from '@/components/CustomJobs/CustomJobsEmpty';
import CustomJobsCard from '@/components/CustomJobs/CustomJobsCard';
import CustomJobsDeleteWarningModal from '@/components/CustomJobs/CustomJobsDeleteWarningModal';
import CustomJobsCloseWarningModal from '@/components/CustomJobs/CustomJobsCloseWarningModal';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'custom-jobs-list',
  components: {
    BcSpinner,
    draggable,
    CustomJobsCard,
    CustomJobsEmpty,
    CustomJobsDeleteWarningModal,
    CustomJobsCloseWarningModal,
    InfiniteLoading
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    refetch: {
      type: Function,
    },
    loadMore: {
      type: Function,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    identifier: {
      type: Number
    }
  },
  created() {
    this.setWindowWidth();
    window.addEventListener('resize', this.setWindowWidth);
    this.$on('hook:destroyed', () => {
      window.removeEventListener('resize', this.setWindowWidth);
    });
  },
  mounted() {
    this.customJobsOrders = this.items;
  },
  data() {
    return {
      windowWidth: undefined,
      displayMode: 'custom-jobs-card',
      showDeleteWarning: false,
      showCloseWarning: false,
      deleteCustomJobId: undefined,
      toCloseCustomJob: undefined,
      customJobsOrders: [],
    };
  },
  computed: {
    ...mapGetters({
      closeOptions: 'customJobs/closeOptions',
    }),
    onActionMap() {
      return {
        edit: this.onEdit,
        delete: this.onDelete,
        share: this.onShare,
        duplicate: this.onDuplicate,
        'open-opportunity': this.onOpenOpportunity,
        'close-opportunity': this.onShowCloseWarning,
      };
    },
  },
  methods: {
    ...mapActions({
      deleteCustomJob: 'customJobs/deleteCustomJob',
      updateCustomJob: 'customJobs/updateCustomJob',
      duplicateCustomJob: 'customJobs/duplicateCustomJob',
      setSelected: 'customJobs/setSelected',
    }),
    showCustomJobPanel(customJob, query) {
      this.$router.push({
        name: 'CustomJobsPanel',
        query: {
          page: 1,
          type: 'hunt',
          category: 'profil-complete',
          coachId: this.$route.query.coachId,
          'company-id': customJob._company._id,
          ...this.$route.query,
          ...query,
          display: 'panel',
        },
        params: {
          id: customJob._id,
        },
      });
    },
    handleClickItem(customJob) {
      this.showCustomJobPanel(customJob);
    },
    handleClickActiveProcesses(customJob) {
      this.showCustomJobPanel(customJob, { category: 'process' });
    },
    onAction(key, customJob) {
      if (key in this.onActionMap) {
        this.onActionMap[key](customJob);
      }
    },
    onEdit(customJob, params) {
      this.$router.push({
        ...this.$route,
        name: `${this.$route.name}Panel`,
        params: {
          id: customJob._id,
          ...params,
        },
        query: {
          ...this.$route.query,
          display: 'panel',
          type: 'edit-custom-jobs',
        },
      });
    },
    onShare(customJob) {
      this.onEdit(customJob, { step: 2 });
    },
    async onDelete(customJob) {
      this.showDeleteWarning = true;
      this.deleteCustomJobId = customJob._id;
    },
    async onDuplicate(customJob) {
      try {
        const { data } = await duplicateCustomJob(customJob._id);

        this.customJobsOrders = [
          data,
          ...this.customJobsOrders,
        ]

        this.$toast.show({
          type: 'success',
          message: this.$t('toast.job-duplicate-success')
        })
      } catch (err) {
        this.handleError(err);
      }
    },
    async onConfirmDelete(id) {
      const customJobId = id ? id : this.deleteCustomJobId;
      try {
        await this.deleteCustomJob(customJobId);
        this.showDeleteWarning = false;
        this.deleteCustomJobId = undefined;
      } catch (err) {
        this.handleError(err);
      }
    },
    onCloseDeleteWarning() {
      this.showDeleteWarning = false
    },
    onShowCloseWarning(customJob) {
      this.toCloseCustomJob = customJob;
      this.setSelected(customJob);
      this.showCloseWarning = true;
    },
    onCloseCloseWarning() {
      this.toCloseCustomJob = undefined;
      this.setSelected({});
      this.showCloseWarning = false;
    },
    async onOpenOpportunity(customJob) {
      try {
        await this.updateCustomJob({
          id: customJob._id,
          active: true,
        });
      } catch (err) {
        this.handleError(err);
      }
    },
    async onCloseOpportunity() {
      if (!this.toCloseCustomJob) {
        return;
      }
      try {
        await this.updateCustomJob({
          id: this.toCloseCustomJob._id,
          active: false,
          closeOptions: this.closeOptions,
        });
        this.onCloseCloseWarning();
      } catch (err) {
        this.handleError(err);
      }
    },
    async endDrag(data) {
      const customJobId = data.item.getAttribute('data-id');
      try {
        const positions = this.customJobsOrders.map((customJob, i) => ({
          id: customJob._id,
          position: i + 1,
        }));
        updateCustomJobPosition({ id: customJobId, positions });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
        this.refetch();
      }
    },
    handleError(error) {
      this.$toast.show({
        type: 'error',
        message: error.message,
      });
    },
    setWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  watch: {
    items(value) {
      this.customJobsOrders = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-jobs-list {
  display: block;

  &--ghost {
    background: rgba(29, 57, 94, 0.05);
    border: 1px solid $color-blue-dodger;
    border-radius: 5px;
  }
}
</style>
