<template>
  <filter-content class="filter-coders-contact" :label="$t('panel-coder-edit.contact-information')">
    <div class="filter-coders-contact__container">
      <bc-checkbox
        class="filter-coders-contact__checkbox is-justify-content-center"
        id="phone"
        :value="Boolean(selected['phone'])"
        @input="handleCheck('phone')">
        {{ $t('hunt.has-a-phone-number') }}
      </bc-checkbox>
      <bc-checkbox
        class="filter-coders-contact__checkbox is-justify-content-center"
        id="searchbar-filters-email"
        :value="Boolean(selected['email'])"
        @input="handleCheck('email')">
        {{ $t('hunt.has-email') }}
      </bc-checkbox>
    </div>
  </filter-content>
</template>

<script setup lang="ts">
import { computed, ref, defineProps, defineEmits, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
import FilterContent from '@/components/Filter/FilterContent';

const props = defineProps<{
  emitOnly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:filters', filters: Record<string, any>): void;
  (e: 'count', value: { key: string; count: number }): void;
}>();

const route = useRoute();
const router = useRouter();

interface ContactState {
  email: boolean;
  phone: boolean;
}

const localSelected = ref<ContactState>({
  email: false,
  phone: false,
});

onMounted(() => {
  if (props.emitOnly) {
    localSelected.value = {
      email: Boolean(route.query.email),
      phone: Boolean(route.query.phone),
    };
  }
});

const selected = computed(() => {
  if (props.emitOnly) {
    return localSelected.value;
  }
  return {
    email: Boolean(route.query.email),
    phone: Boolean(route.query.phone),
  };
});

const handleCheck = (key: 'email' | 'phone') => {
  const newValue = !selected.value[key];

  if (props.emitOnly) {
    localSelected.value = {
      ...localSelected.value,
      [key]: newValue,
    };
    emit('update:filters', {
      [key]: newValue || undefined,
    });
  } else {
    router.push({
      query: {
        ...route.query,
        page: 1,
        [key]: newValue || undefined,
      },
    });
  }
};

watch(selected, (value) => {
  const nb = Object.keys(value).filter(k => value[k as keyof typeof value]);
  emit('count', { key: 'contact', count: nb.length });
}, { immediate: true });
</script>

<style lang="scss" scoped>
.filter-coders-contact {
  &__container {
    gap: 20px;
  }
}
</style>
