<template>
  <div class="panel-favorite-list-manage-resource is-column">
    <panel-header class="panel-favorite-list-manage-resource__header">
      <panel-favorite-list-manage-resource-header></panel-favorite-list-manage-resource-header>
    </panel-header>
    <div class="panel-favorite-list-manage-resource__wrapper is-column">
      <panel-favorite-list-create-new-list></panel-favorite-list-create-new-list>
      <panel-favorite-list-manage-resource-body></panel-favorite-list-manage-resource-body>
    </div>
  </div>
</template>

<script>
  import PanelHeader
    from '@/components/Panel/PanelHeader';
  import PanelFavoriteListManageResourceHeader
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListManageResource/PanelFavoriteListManageResourceHeader';
  import PanelFavoriteListCreateNewList
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListCreate/PanelFavoriteListCreateNewList';
  import PanelFavoriteListManageResourceBody
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListManageResource/PanelFavoriteListManageResourceBody';

  export default {
    name: 'panel-favorite-list-manage-resource',
    components: {
      PanelFavoriteListManageResourceHeader,
      PanelFavoriteListCreateNewList,
      PanelFavoriteListManageResourceBody,
      PanelHeader
    }
  };
</script>

<style lang=scss scoped>
  .panel-favorite-list-manage-resource {
    height: 100%;
    background: white;

    &__header {
      border-bottom: 1px solid #3C80F74D;
    }

    &__wrapper {
      overflow: auto;
    }
  }

</style>
