import colors_helpers from '@/legacy/ui-kit/utils/colors_helpers';

export const apiContracts = {
  cdi: 1,
  freelance: 2,
  stage: 3,
  alternance: 4,
  cdd: 5,
  consultant: 6,
  interim: 7,
  intermittent: 8,
};

export const contracts = {
  cdi: {
    api: apiContracts.cdi,
    name: 'panel-coder-edit.permanent-contract',
    query: 'cdi',
    color: colors_helpers.$_checkValidColor('blue-dodger'),
    flatColor: 'blue-dodger',
  },
  freelance: {
    api: apiContracts.freelance,
    name: 'panel-coder-edit.freelance',
    query: 'freelance',
    color: colors_helpers.$_checkValidColor('yellow-sunglow'),
    flatColor: 'yellow-sunglow'
  },
  stage: {
    api: apiContracts.stage,
    name: 'panel-coder-edit.training-course',
    query: 'stage',
    color: colors_helpers.$_checkValidColor('green-lima'),
    flatColor: 'green-lima'
  },
  alternance: {
    api: apiContracts.alternance,
    name: 'panel-coder-edit.mixed-training',
    query: 'alternance',
    color: colors_helpers.$_checkValidColor('purple-medium'),
    flatColor: 'purple-medium'
  },
  cdd: {
    api: apiContracts.cdd,
    name: 'contracts.cdd',
    query: 'cdd',
    color: colors_helpers.$_checkValidColor('purple-medium'),
    flatColor: 'purple-medium'
  },
  consultant: {
    api: apiContracts.consultant,
    name: 'contracts.consultant',
    query: 'consultant',
    color: colors_helpers.$_checkValidColor('purple-medium'),
    flatColor: 'purple-medium'
  },
  interim: {
    api: apiContracts.interim,
    name: 'contracts.interim',
    query: 'interim',
    color: colors_helpers.$_checkValidColor('purple-medium'),
    flatColor: 'purple-medium'
  },
  intermittent: {
    api: apiContracts.intermittent,
    name: 'contracts.intermittent',
    query: 'intermittent',
    color: colors_helpers.$_checkValidColor('purple-medium'),
    flatColor: 'purple-medium'
  },
};

export const getContractNameFromApiValue = apiValue => Object.keys(apiContracts).find(key => apiContracts[key] === apiValue);

export const getContractObjectFromApiValue = apiValue => Object.values(contracts).find(contract => contract.api === apiValue);

export default {
  apiContracts,
  contracts,
  getContractNameFromApiValue,
  getContractObjectFromApiValue,
};
