<template>
  <div class="panel-process-details-dealt">
    <p class="panel-process-details-dealt__title">
      Fin de période de risque ?
    </p>
    <bc-button
      @click="openModal"
      class="panel-process-details-dealt__button"
      color="primary-light"
      type="negative">
      {{ $t('generics.validate') }}
    </bc-button>
    <modal
      @close-modal="closeModal"
      v-if="displayModal">
      <modal-process-onboarding-validation
        :process="process"
        :is-modal-open="true"
        @close-modal="closeModal"
        @process-update="updateProcess">
      </modal-process-onboarding-validation>
    </modal>
  </div>
</template>

<script>
  import BcButton from '@/ui-kit/components/BcButton';
  import Modal from '@/components/Modal/Modal';
  import ModalProcessOnboardingValidation
    from '@/components/Modal/ModalProcess/ModalProcessOnboardingValidation';

  export default {
    name: 'panel-process-details-dealt',
    components: { ModalProcessOnboardingValidation, Modal, BcButton },
    props: {
      process: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        displayModal: false,
      };
    },
    methods: {
      openModal() {
        this.displayModal = true;
      },
      closeModal() {
        this.displayModal = false;
      },
      updateProcess({ updatedProcess: process, }) {
        this.$emit('process-updated', process);
        this.$emit('update-timeline');
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-process-details-dealt {
    background: $color-primary-light;
    border-radius: 2px;
    padding: 13px 20px;

    &__title {
      display: flex;
      align-items: center;
      color: $color-white;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      text-transform: uppercase;

      &:before {
        margin-right: 10px;
      }
    }

    &__button {
      text-transform: uppercase;
      border-radius: $border-radius-xs;
    }
  }
</style>
