<template>
  <div
    class="searchbar-autocomplete-list">
    <div
      v-for="(categoryItem, type, index) in itemsComputed"
      :key="type"
      :class="{ 'searchbar-autocomplete-list__list-separator': index > 0 }"
      class="searchbar-autocomplete-list__list"
    >
      <h3 class="searchbar-autocomplete-list__title">{{ typesTrads[type] }}</h3>
      <ul>
        <li
          v-for="(item) in categoryItem"
          :key="item._id"
          class="searchbar-autocomplete-list__item"
          @mousedown="$emit('click', item)"
          @mouseenter="$emit('mouseenter', item)">
          <div
            v-if="item.icon"
            :class="`icon-${item.icon}`"
            class="searchbar-autocomplete-list__icon">
          </div>
          <div
            v-if="item.picture"
            class="searchbar-autocomplete-list__picture-wrapper">
            <img
              :alt="item.name"
              :src="item.picture"
              class="searchbar-autocomplete-list__picture"
              @error="setAlternativeImg">
          </div>
          <div
            v-if="item.type==='input' && ($route.path.includes('coders') || $route.query['list-type'] === 'coder')">
            <p class="searchbar-autocomplete-list__text--info" v-html="inputDescription()[0]">
            </p>
          </div>
          <p class="searchbar-autocomplete-list__text">
            {{ splitHighlightValue(item[itemValue])[0] }}<span class="searchbar-autocomplete-list__text--hint">{{ splitHighlightValue(item[itemValue])[1] }}</span>{{ splitHighlightValue(item[itemValue])[2] }}
          </p>
          <div
            v-if="item.type==='input' && ($route.path.includes('coders') || $route.query['list-type'] === 'coder')">
            <p class="searchbar-autocomplete-list__text--info" v-html="inputDescription()[1]">
            </p>
          </div>
          <div v-if="item.info">
            <p class="searchbar-autocomplete-list__text">
              &nbsp;-&nbsp;
            </p>
            <p class="searchbar-autocomplete-list__text--info">
              {{ item.info }}
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'searchbar-autocomplete-list',
    props: {
      /**
       * The value of the input which will filter the list
       */
      value: {
        type: [String, Number],
        default: '',
      },
      /**
       * The list which will be shown
       */
      list: {
        type: Array,
        default: () => [],
      },
      /**
       * The pointer value
       */
      pointer: {
        type: [Number, Object],
      },
      /**
       * It's the data which will be shown
       */
      itemValue: {
        type: String,
        default: '',
      },
      lastIndexesOfCategories: {
        type: Array,
        default: () => [],
      },
      fallbackImage: {
        type: String,
        default: 'person',
        validator: value => {
          return [
            'person',
            'company',
          ].indexOf(value) >= 0;
        },
      },
    },
    data() {
      return {
        typesTrads: {
          coder: this.$tc('generics.candidate-cap', 2),
          profession: this.$t('generics.professions'),
          techno: this.$t('panel-coder-edit.skills'),
          input: this.$t('avatar-dropdown.job-title'),
          partner: this.$tc('generics.company', 2),
        },
      };
    },
    computed: {
      itemsComputed() {
        return this.list.reduce((acc, value) => {
          if (!acc[value.type]) {
            return {
              ...acc,
              [value.type]: [value],
            };
          }
          return {
            ...acc,
            [value.type]: [...acc[value.type], value],
          };
        }, {});
      },
    },
    methods: {
      formatString(string) {
        return string.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      },
      setAlternativeImg(event) {
        event.target.src = this.fallbackImage === 'person' ? require('@/assets/img/default-avatar.svg') : require('@/assets/img/default-avatar-company.svg');
      },
      splitHighlightValue(name) {
        const startHighlight = this.formatString(name).indexOf(this.formatString(this.value));
        const endHighlight = startHighlight + this.value.length;

        if (startHighlight === -1) {
          return [name, '', ''];
        }

        return [
          name.substring(0, startHighlight),
          name.substring(startHighlight, endHighlight),
          name.substring(endHighlight),
        ];
      },
      inputDescription() {
        const descriptions = {
          Coders: [`${this.$i18n.t('generics.search')}&nbsp`, `&nbsp${this.$i18n.t('generics.in-the-coders-description')}`],
          FavoriteList: [`${this.$i18n.t('generics.search')}&nbsp`, `&nbsp${this.$i18n.t('generics.in-the-coders-description')}`],
          Partners: ['', ''],
          Jobs: ['', ''],
        };

        return descriptions[this.$route.name] || ['', ''];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-autocomplete-list {
    $this: &;
    top: calc(100% + 5px);
    left: 0;
    border-top: 0;
    overflow: hidden;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(29, 57, 94, 0.2);
    border-radius: 5px;
    margin-top: 5px;

    &__list {
      padding: 5px 0 0 0;
      background: $color-white;
      flex-direction: column;
    }

    &__list-separator {
      border-top: 1px solid rgba($color-blue-dark-cello, 0.15);
    }

    &__text-wrapper {
      flex-direction: row;
    }

    &__text {
      all: initial;
      font-size: $font-size-s;
      font-family: rubikregular, Helvetica, Arial, sans-serif;
      color: $color-blue-dark-cello;
      cursor: inherit;

      &--hint {
        all: initial;
        font-size: $font-size-s;
        font-family: rubikregular, Helvetica, Arial, sans-serif;
        color: $color-blue;
        text-transform: capitalize;
        cursor: inherit;
      }

      &--info {
        all: initial;
        font-size: $font-size-s;
        font-family: rubikregular, Helvetica, Arial, sans-serif;
        color: $color-dark-gray;
        cursor: inherit;
      }
    }

    &__item {
      display: flex;
      padding: 7.5px;
      border-bottom: 0;
      color: $color-blue-dark-cello;
      justify-content: flex-start;
      background: $color-white;
      font-size: $font-size-s;

      @include bp('tablet') {
        padding: 10px 60px;
        justify-content: flex-start;
        align-items: center;
      }

      &:hover {
        background: rgba($color-blue-dark-cello, 0.05);
        cursor: pointer;
        border-color: rgba($color-blue-dark-cello, 0.05);
      }

      &:first-of-type {
        border-top: 0;
      }

      &--last-of-category {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        border-top: 1px solid rgba($color-blue-dark-cello, 0.15);
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    &__icon {
      font-size: $font-size-s;
      color: $color-blue;
      text-transform: capitalize;
      margin-right: 10px;
    }

    &__picture {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 10px;
    }

    &__title {
      padding: 10px 60px;
      border-bottom: 0;
      color: $color-blue-dark-cello;
      background: $color-white;
      font-size: $font-size-s;
    }
  }
</style>
