<template>
  <div class="picture-avatar__container">
    <div class="picture-avatar">
      <img
        :src="imgSrc"
        @error="setAlternativeImg"
        alt="Opportunity avatar"
        class="picture-avatar__img">
    </div>
    <div class="picture-avatar__icon">
      <i class="icon-briefcase"></i>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'picture-opportunity',
    /**
     * The url of the image
     */
    props: {
      imgSrc: {
        type: String,
        default: require('@/assets/img/default-avatar-opportunity.svg')
      },
      width: {
        type: String,
        default: '78px'
      },
      height: {
        type: String,
        default: 'auto'
      },
      fallbackImg: {
        type: String,
        default: require('@/assets/img/default-avatar-opportunity.svg'),
      }
    },
    methods: {
      setAlternativeImg(event) {
        event.target.src = this.fallbackImg;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .picture-avatar {
    &__container {
      position: relative;
      margin-right: 25px;
    }

    justify-content: center;
    align-items: center;
    width: 85px;
    height: 85px;
    border: 1px solid $color-gray-mystic;
    border-radius: 50%;
    flex-shrink: 0;
    overflow: hidden;
    background: white;
    z-index: 1;

    &__img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      pointer-events: none;
    }

    &__icon {
      position: absolute;
      bottom: 8px;
      background: $color-blue-hawkes;
      padding: 10px;
      border-radius: 100%;
      color: $color-primary;
      font-size: $font-size-s;
      right: -12px;
      z-index: 1;
    }
  }
</style>
