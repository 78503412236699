<template>
  <div class="panel-process-create-footer">
    <bc-button
      :disabled="isLoading"
      class="panel-process-create-footer__button"
      type="outlined"
      @click.native="goBack">
      {{ $t('generics.cancel') }}
    </bc-button>
    <bc-button
      :disabled="!isProcessComplete || isLoading"
      class="panel-process-create-footer__button"
      native-type="submit"
      @click.native="postProcesses">
      {{ $t('generics.validate') }}
    </bc-button>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { createProcessCustom } from '@/api/processes-custom'
  import { apiStatus } from '@/macros/processes/process';
  import moment from 'moment/min/moment-with-locales';

  import ProcessMixin from '@/mixins/process';
  import MetadatasMixin from '@/mixins/metadatas';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';

  export default {
    name: 'panel-process-create-footer',
    components: { BcButton },
    props: {
      isProcessComplete: {
        type: Boolean,
        default: false,
      },
      processes: {
        type: Array,
        default: () => [],
      },
      selectedCoders: {
        type: Array,
        default: () => [],
      }
    },
    mixins: [ProcessMixin, MetadatasMixin],
    data() {
      return {
        isLoading: false,
      }
    },
    computed: {
      profile() {
        return this.$store.state.user.profile;
      },
      onboarding() {
        return this.$store.state.user.onboarding;
      },
    },
    methods: {
      ...mapActions({
        updateCustomJob: 'customJobs/updateCustomJob',
      }),
      goBack() {
        this.$emit('cancel');
      },
      formatQuery(coderId, process) {
        return {
          ...process._customJob && process._customJob._id && { customJobId: process._customJob._id },
          companyId: process._company._id,
          coderId,
        }
      },
      async postProcesses() {
        if (this.$route.query?.from === 'multi-select') {
          this.postMultiSelectedProcesses();
          return;
        }
        this.isLoading = true;
        const autoFillIncompleteProcesses = this.processes.map(process => ({
          ...process,
          ...process.status <= 0 && { status: apiStatus.introduced },
        }));

        for (const process of autoFillIncompleteProcesses) {
          try {
            const coderId = process._coder._id || this.$route.params._id
            const { data } = await createProcessCustom(this.formatQuery(coderId, process));

            if (this.$route.name.includes('CandidatePipe')) {
              await this.$store.dispatch('removeCard', this.$route.params.id);
            }

            this.$_updateCoder(data._coder);

            await this.$store.dispatch('user/updateMetadataByKey', {
              key: 'processes',
              value: (this.$store.state.user.metadata.processes || 0) + 1,
            });

            if (data._customJob?._id) {
              await this.updateCustomJob({ id: data._customJob._id, nbActiveProcesses: data._customJob.nbActiveProcesses });
            }


            if (this.onboarding.process) {
              this.$toast.show({
                message: this.$i18n.t('toast.process-created-successfully'),
                type: 'success',
              });
            } else {
              await this.$store.dispatch('user/updateOnboarding', { key: 'process', value: true });
            }

          } catch (error) {
            this.$toast.show({
              type: 'error',
              message: error.response?.data?.message || error,
            });
          }
        }

        if (this.$route.params.id === 'panel') {
          delete this.$route.params.id;
        }

        if (this.$route.params.id) {
          await this.$router.push({
            query: {
              ...this.$route.query,
              type: this.$route.query.from,
            },
            params: {
              id: this.$route.query.suggestionId ? this.$route.query.suggestionId : this.$route.params.id
            }
          });
          return
        }

        this.isLoading = false;

        await this.$router.push(`/pipe/processes?selected-pipe=personal-pipe&processes-starts=${moment().startOf('week').format('DD-MM-YYYY')}&processes-ends=${moment().endOf('week').format('DD-MM-YYYY')}&processes-selected-coaches=${this.profile._id}`);
      },
      async postMultiSelectedProcesses() {
        if (!this.selectedCoders.length) {
          return;
        }
        this.isLoading = true;
        const process = this.processes[0];
        try {
          const results = await Promise.all(
            this.selectedCoders.map((coder) => createProcessCustom(this.formatQuery(coder._id, process)))
          )

          let nbSuccess = 0;
          results.forEach(async(result) => {
            if (result.status === 200) {
              nbSuccess++;
              this.$_updateCoder(result.data._coder);
              await this.$store.dispatch('user/updateMetadataByKey', {
                key: 'processes',
                value: (this.$store.state.user.metadata.processes || 0) + 1,
              });
            }
          });

          if (process._customJob?._id) {
            await this.updateCustomJob({ id: process._customJob._id, nbActiveProcesses: process._customJob.nbActiveProcesses });
          }

          if (this.onboarding.process) {
            this.$toast.show({
              message: this.$i18n.t('toast.multi-process-created-successfully', { count: nbSuccess, hunt: process._customJob.title }),
              type: 'success',
            });
          } else {
            await this.$store.dispatch('user/updateOnboarding', { key: 'process', value: true });
          }

          this.$store.dispatch('setSelectedCards', []);

          this.isLoading = false;

          const customJobId = process._customJob._id
          const companyId = process._company._id;

          if (customJobId) {
            await this.$router.push(
              `/pipe/hunt/${customJobId}?type=hunt&selected-pipe=personal-pipe&job-active=true&category=process&coachId=${this.profile._id}&company-id=${companyId}`
            );
          } else {
            await this.$router.push(`/pipe/processes?selected-view=candidates&selected-pipe=personal-pipe&processes-selected-coaches=${this.profile._id}`);
          }

        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: error.response?.data?.message || error,
          });
        }
      }
    },
  };
</script>

<style lang=scss scoped>
  .panel-process-create-footer {
    padding: 7px 20px;
    background: $color-white;
    width: 100%;
    justify-content: center;

    &__button {
      margin: 0 10px;
    }
  }
</style>
