<template>
  <bc-modal
    :active="isModalOpen"
    @close="closeModal(), moveProcessBack(process,'validated')"
    class="modal-process-onboarding-validation">
    <div class="modal-process-onboarding-validation__content">
      <div class="modal-process-onboarding-validation__header">
        <p class="modal-process-onboarding-validation__title">
          {{ $t('candidates.candidate-onboarding-assessment') }} :
        </p>
        <p class="modal-process-onboarding-validation__subtitle">
          {{ $t('candidates.remind') }} :
          <span class="modal-process-onboarding-validation__name">{{ coderFullName }}</span>
          {{ $t('candidates.has-started-for-the-position-of') }}
          <span
            class="modal-process-onboarding-validation__name">{{ jobName }}</span>
          {{ $t('timeline.among') }}
          <span
            class="modal-process-onboarding-validation__name">{{ (process._company || {}).name }}</span>
          {{ $t('timeline.the') }} {{ startDate }}
        </p>
      </div>
      <bc-divider
        :bc-divider-color-opacity="0.2"
        bc-divider-color="blue-dark-cello"
        class="modal-process-onboarding-validation__divider">
      </bc-divider>
      <bc-rich-text-editor-bubble
        :is-editable="true"
        @blur="setIsTyping()"
        @focus="setIsTyping(true)"
        class="modal-process-onboarding-validation__textarea is-full-width font-size-s border-radius-m"
        :placeholder="$t('candidates.how-was-the-onboarding')"
        position="absolute"
        ref="textarea"
        v-autosize="content"
        v-model="content">
      </bc-rich-text-editor-bubble>
      <bc-divider
        :bc-divider-color-opacity="0.2"
        bc-divider-color="blue-dark-cello"
        class="modal-process-onboarding-validation__divider">
      </bc-divider>
      <div>
        <p
          class="modal-process-onboarding-validation__text font-weight-bold is-uppercase font-size-s is-flex is-align-items-center">
          {{ $t('candidates.onboarding-assessment') }}
        </p>
        <ratings
          :is-hidden-button="true"
          :rating="rating"
          @rating-set="setRating"
        >
        </ratings>
      </div>
      <bc-divider
        :bc-divider-color-opacity="0.2"
        bc-divider-color="blue-dark-cello"
        class="modal-process-onboarding-validation__divider">
      </bc-divider>
      <div class="modal-process-onboarding-validation__footer">
        <bc-button
          @click="closeModal(), moveProcessBack(process,'validated')"
          class="modal-process-onboarding-validation__button"
          size="small"
          type="outlined">
          {{ $t('generics.cancel') }}
        </bc-button>
        <bc-button
          :disabled="!rating"
          @click.stop="setValidation"
          class="modal-process-onboarding-validation__button"
          color="success"
          size="small">
          {{ $t('generics.validate') }}
        </bc-button>
      </div>
    </div>
  </bc-modal>
</template>

<script>
  import moment from 'moment/min/moment-with-locales';

  import BcButton from '@/ui-kit/components/BcButton';
  import BcDivider from '@/legacy/ui-kit/components/Dividers/BcDivider';
  import BcRichTextEditorBubble from '@/ui-kit/components/BcRichTextEditor/BcRichTextEditorBubble';

  import Ratings from '@/components/Toolbox/Ratings';
  import { processController } from '@/managers/processes/controller';
  import { processStatus } from '@/macros/processes/process';
  import processModal from '@/mixins/Processes/processModal';
  import BcModal from '@/ui-kit/components/BcModal';
  import { mapActions } from 'vuex';

  export default {
    name: 'modal-process-onboarding-validation',
    components: { Ratings, BcRichTextEditorBubble, BcDivider, BcButton, BcModal },
    props: {
      process: {
        type: Object,
        default: () => ({}),
      },
      isModalOpen: {
        type: Boolean,
        default: false,
      },
    },
    mixins: [processModal],
    data() {
      return {
        content: '',
        rating: null,
      };
    },
    computed: {
      startDate() {
return moment(this.process.onboarding.startsAt).format('L');
      },
      jobName() {
        return this.process._hunt
          ? this.process._hunt.poste
          : (this.process._job || {}).name;
      },
    },
    methods: {
      ...mapActions(['updatePanelProfile']),
      async setValidation() {
        try {
          const newStatus = processStatus.validated;

          const updatedProcess = await processController.move(
            this.process._id,
            newStatus,
            {
              content: this.content,
              rating: this.rating,
            }
          );

          this.updatePanelProfile({
            processState: updatedProcess._coder.processState,
          });
          this.$emit('profile-updated', {
            processState: updatedProcess._coder.processState,
          });

          this.$emit('process-update', {
            updatedProcess: updatedProcess,
            stepName: newStatus,
          });

          this.moveProcessForward(
            updatedProcess,
            processStatus.onboarding,
            newStatus
          );
          this.closeModal();
          this.$toast.show({
            type: 'success',
            title: `${this.$i18n.t('toast.congratulations')} !`,
            message: this.$i18n.t('toast.premium-guaranteed'),
            duration: 3000,
          });
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      setIsTyping(value) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            typing: value,
          },
        });
      },
      setRating(value) {
        this.rating = value;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-process-onboarding-validation {
    &__content {
      width: 405px;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      padding: 30px 0;
      min-width: 320px;
    }

    &__header {
      flex-direction: column;
      align-items: center;
      padding: 0 40px;
    }

    &__divider {
      margin: 20px 0;
    }

    &__title {
      text-transform: uppercase;
      line-height: 17px;
      color: $color-primary;
      font-weight: $font-weight-bold;
      margin-bottom: 10px;
    }

    &__label {
      align-items: center;
    }

    &__subtitle {
      width: 300px;
      text-align: center;
      font-size: $font-size-s;
      color: $color-secondary;
    }

    &__name {
      display: initial;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      color: $color-primary;
    }

    &__wrapper {
      align-items: center;
      font-size: $font-size-s;
      line-height: 17px;
      color: $color-secondary;
      justify-content: flex-end;
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__body {
      flex-direction: column;
    }

    &__button {
      font-size: $font-size-s;
      margin: 0 5px;
    }

    &__textarea {
      width: 350px;
      min-height: 120px;
      max-height: 250px;
      border-radius: $border-radius-s;
      border: 1px solid #a4afbe;
      padding: 10px 20px;
      margin: 0;
    }

    &__text {
      color: $color-secondary;
    }
  }
</style>
