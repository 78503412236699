<template>
  <div class="is-column">
    <div v-if="nbUsers === 1" class="change-plan-confirmation-modal__solo">
      <div class="change-plan-confirmation-modal__title is-column mb-6">
        <span>{{ $t('avatar-dropdown.by-adding-this-user-you-agree-to') }}</span>
        <span>{{ $t('avatar-dropdown.upgrade-to-team-plan') }}</span>
      </div>
      <div class="is-column change-plan-confirmation-modal__inputs-container">
        <span class="mb-4 font-weight-bold">
          {{ $t('avatar-dropdown.number-of-users') }} :
          <span class="font-size-m is-primary"
          ><span class="is-secondary">{{ $t('avatar-dropdown.until') }}</span> 3</span>
        </span>

        <span class="mb-6 font-weight-bold font-size-m">
          {{ $t('avatar-dropdown.monthly-billing') }} :
          <span class="font-size-m is-primary">289€ </span>
          <span class="is-secondary">HT</span>
        </span>

        <bc-button
          :bc-text-color-opacity="0.5"
          bc-background-color="purple-dark"
          bc-icon="cross"
          bc-icon-size="16px"
          bc-padding="0"
          bc-text-color="blue-dark"
          @click.native="confirmInviteUser"
          :disabled="premiumStripeCalled"
          class="font-size-m mr-auto ml-auto is-column"
        >
          <span>{{ $t('avatar-dropdown.i-understand-i-agree-to-go-to') }}</span>
          <span> {{ $t('avatar-dropdown.upper-plane') }}</span>
        </bc-button>
      </div>
    </div>

    <div v-if="nbUsers === 2" class="change-plan-confirmation-modal__two">
      <div class="is-column change-plan-confirmation-modal__inputs-container">
        <div class="is-column mb-5 font-weight-bold">
          <span>{{ $t('avatar-dropdown.by-adding-this-user-you-occupy-the') }}</span>
          <span>{{ $t('avatar-dropdown.three-places-available-from-the-launch-plan') }}</span>
        </div>

        <bc-button
          :bc-text-color-opacity="0.5"
          bc-background-color="purple-dark"
          bc-icon="cross"
          bc-icon-size="16px"
          bc-padding="0"
          bc-text-color="blue-dark"
          @click.native="confirmInviteUser"
          :disabled="premiumStripeCalled"
          class="font-size-m mr-auto ml-auto is-column"
        >
          <span> {{ $t('avatar-dropdown.i-understand') }}</span>
        </bc-button>
      </div>
    </div>

    <div v-else-if="nbUsers >= 3" class="change-plan-confirmation-modal">
      <div class="change-plan-confirmation-modal__title is-column mb-6">
        <span>{{ $t('avatar-dropdown.by-adding-this-user-you-will-be') }}
        </span>
        <span>{{ $t('avatar-dropdown.billed-at') }} 119€ {{ $t('avatar-dropdown.by-month-and-per-user') }}</span>
      </div>
      <div class="mb-6">
        <h2>{{ $t('avatar-dropdown.your-new-subscription') }} :</h2>
      </div>


      <div class="change-plan-confirmation-modal__inputs-container mb-4">
        <span class="mb-2 font-weight-bold">
          <h2>{{ $t('avatar-dropdown.number-of-users') }} :</h2><span class="font-size-m is-primary">{{ nbUsers +1 }}</span>
        </span>
        <span class="mb-2 font-weight-bold">
          {{ $t('avatar-dropdown.price-per-user') }} :
          <span class="font-size-m is-primary">
            119€ <span class="is-secondary"> HT </span>
          </span>
        </span>
        <span class="font-weight-bold">
          {{ $t('avatar-dropdown.monthly-billing') }} :
          <span class="font-size-m is-primary">
            {{ (nbUsers + 1) * 119 }}
            <span class="is-secondary"> HT</span></span
          ></span
        >
        <div class="mt-6">
          <bc-button
            class="is-column"
            native-type="submit"
            @click.native="confirmInviteUser"
            :disabled="customizedStripeCalled"
          >
            <span>  {{ $t('avatar-dropdown.i-understand-i-agree-to-put') }}</span>
            <span>{{ $t('avatar-dropdown.update-my-subscription') }}
            </span>
          </bc-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BcButton from '@/ui-kit/components/BcButton/BcButton';

  export default {
    name: 'change-plan-confirmation-modal',
    components: { BcButton },
    props: {
      nbUsers: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        premiumStripeCalled: false,
        customizedStripeCalled: false
      };
    },
    methods: {
      confirmInviteUser() {
        this.toggleButtonLocked;
        this.$emit('upgrade-plan');
      },
      toggleButtonLocked() {
        this.premiumStripeCalled = !this.premiumStripeCalled;
      }
    },
    computed: {
      profile() {
        return this.$store.state.user.profile;
      },
      isSolo() {
        return this.profile?._organization?.nbUsers === 1;
      },
      isPremium() {
        return (
          this.profile?._organization?.nbUsers > 1 &&
          this.profile?._organization?.nbUsers < 4
        );
      },
      plan() {
        if (this.isSolo) {
          return {
            img: require('@/assets/logo/freemium.png'),
            name: 'Solo'
          };
        }

        return this.isPremium
          ? {
              img: require('@/assets/logo/premium.png'),
              name: 'Premium'
            }
          : {
              img: require('@/assets/logo/entreprise.png'),
              name: 'Entreprise'
            };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .change-plan-confirmation-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 30px;
    min-width: 450px;
    min-height: 200px;
    &__solo {
      text-align: center;
      display: block;
      padding: 50px;
    }
    &__two {
padding: 30px;
}
    &__title {
      font-size: 20px;
      color: #1d395e;
      font-family: 'Roboto';
      font-weight: bold;
      text-align: center;
      letter-spacing: 0px;
    }
    &__inputs-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
</style>
