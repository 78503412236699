<script lang="ts" setup>
import { Candidate } from '@/domains/models/Candidate';
import {
  getGmailLink,
  getGoogleCalendarEventLink,
  getMicrosoftCalendarEventLink,
  getOutlookLink,
  getWhatsappLink,
} from '@/helpers/quicklinks';
import { computed, defineEmits, defineProps, onMounted, ref, withDefaults, watch } from 'vue';
import { useI18n } from '@/i18n/i18n';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
import { useToast } from '@/ui-kit/components/BcToast';
import { getConfiguration } from '@/api/emails';
import { getProcessesCustom } from '@/api/processes-custom';

import linkedin from '@/assets/logo/linkedin.png';
import gmail from '@/assets/logo/gmail.png';
import emailIcon from '@/assets/img/email.svg';
import outlook from '@/assets/logo/outlook_messaging.png';
import whatsapp from '@/assets/logo/whatsapp.svg';
import gcalendar from '@/assets/logo/google-calendar.png';
import outlookcalendar from '@/assets/logo/outlook_calendar.png';
import { useRoute } from 'vue-router/composables';
import { useStore } from '@/store';
import BcButton from '@/ui-kit/components/BcButton';
import imgFallback from '@/assets/img/default-avatar.svg';
import CompactTag from '@/components/Tag/CompactTag.vue';
import ImageWithDefault from '@/components/Image/ImageWithDefault.vue';
import LinkedinConnectionBadge from '@/components/Badge/LinkedinConnectionBadge.vue';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';


import defaultCompanyAvatar from '@/assets/img/default-avatar-company.svg';
import defaultCompanyAvatarTooltip from '@/assets/img/default-avatar-company-3.svg';
import PanelTopActionButton from '@/components/Panel/PanelTopActionButton.vue';
import { useUnipileAccountsStore } from '@/store/pinia/unipileAccounts';
import { useLinkedinCandidate } from '@/composables/useLinkedinCandidate';
import { useLinkedinRelationsApi } from '@/composables/api/useLinkedinRelationsApi';
import { LinkedinRelationStatus } from '@/domains/models/Linkedin';
import PanelTopActionLinkedin from '@/components/Panel/PanelTopActionLinkedin.vue';
import { parseCoderResume, extractCodersTextDocuments } from '@/api/coders';
import { useMutation } from '@tanstack/vue-query';
import { useParsingResumeHistoryStore } from '@/store/pinia/parsingHistories';

const i18n = useI18n();
const route = useRoute();
const store = useStore();
const toast = useToast();

interface Props {
  candidate: Candidate;
}

const props = withDefaults(defineProps<Props>(), {
  candidate: () => ({}) as Candidate,
});
const emit = defineEmits<{ (e: 'update-candidate'): void }>();

const unipileAccountsStore = useUnipileAccountsStore();
const tasksResumeParsingStore = useParsingResumeHistoryStore();
const candidateRef = computed(() => props.candidate);

const { relationStatus } = useLinkedinCandidate(candidateRef);
const { syncRelationStatus, loading: relationLoading } = useLinkedinRelationsApi();

const isEmailVerified = ref(false);
const activeEmployees = computed(() => props.candidate._employees?.filter((employee) => employee.active));
const associatedCompanies = computed(() => activeEmployees.value?.map(employee => employee._company) || []);

const isContact = computed(() => props.candidate.profileTypes?.includes('contact'));

const hasGoogleWidgets = computed(() => {
  return store.getters['user/configurationProcess'].hasGoogleWidgets;
});

const hasMicrosoftWidgets = computed(() => {
  return store.getters['user/configurationProcess'].hasMicrosoftWidgets;
});

const googleCalendarLink = computed(() => getGoogleCalendarEventLink({
  i18n,
  routeFullPath: route.fullPath,
  profileName: `${props.candidate.firstName} ${props.candidate.lastName}`,
  phone: props.candidate.phone,
  email: props.candidate.email,
  type: 'invitation',
  organizationName: store.state.user._organization?.name,
}));

const microsoftCalendarLink = computed(() =>
  getMicrosoftCalendarEventLink({
    emailTo: props.candidate?.email || ""
  })
);

const gmailLink = computed(() => getGmailLink({ emailTo: props.candidate.email }));
const outlookLink = computed(() => {
  return getOutlookLink({ emailTo: props.candidate.email });
});
const whatsappLink = computed(() => getWhatsappLink({ phone: props.candidate.phone }));
const setAlternativeImg = event => {
  event.target.src = imgFallback;
};

const resumeHistories = computed(() => tasksResumeParsingStore.list ?? []);
const isParseCandidatePending = computed(() => resumeHistories.value?.some(resume => resume.coder?._id === props.candidate?._id && resume.status === 'PENDING'));

const onSendMail = async () => {
  if (!props.candidate.email) {
    return;
  }

  try {
    const { data: processes } = await getProcessesCustom({ resource: 'coder', coderId: props.candidate?._id });
    const activeProcesses = processes.filter(process => !process.abortedAt && !process.rejectedAt && !process.deletedAt)

    store.dispatch('app/setIsPanel', true);
    store.dispatch('app/setParams', {
      coder: props.candidate,
      emails: [props.candidate.email, ...(props.candidate.emails || [])].filter(Boolean),
      ...activeProcesses.length === 1 && { customJob: activeProcesses[0]._customJob },
      ...activeProcesses.length > 1 && { warningMessage: i18n.t('email.not-have-obvious-variables') },
      isCompany: false,
    });
    store.dispatch('app/setModal', 'modal-email-send-form');
  } catch (err) {
    console.log('err', err);
  }

};

const { isPending, mutateAsync: parseCandidate } = useMutation({
  mutationKey: ['parse-coder', props.candidate?._id ?? ''],
  mutationFn: async () => {
    await parseCoderResume(props.candidate?._id)

    await store.dispatch('resumeHistories/getResumeHistories', { coachId: store.state.user.profile._id });
  },
  onError: (error) => {
    toast.show({
      type: 'error',
      title: i18n.t('candidate.parse.error'),
      message: error?.response?.data?.message || error
    });
  },
});
const canParseCandidate = computed(() => {
  return !isPending.value && !isParseCandidatePending.value && props.candidate?._documents?.length;
});

onMounted(async () => {
  const { data: emailConfig } = await getConfiguration();
  isEmailVerified.value = emailConfig.verified;
});

watch(() => isParseCandidatePending.value, () => {
  emit('update-candidate');
})
</script>

<template>
  <div class="flex flex-row gap-[18px] bg-white px-2 py-4">
    <BcAvatar
      :src="candidate?.pictureUrl"
      class="size-[80px] shrink-0 rounded-full border-2 border-blue-300"
      @error="setAlternativeImg"
    />
    <div class="absolute right-5 top-2.5 flex gap-2.5">
      <BcButton
        :disabled="!canParseCandidate"
        icon-left="stroke"
        size="extra-small"
        @click="parseCandidate"
        v-tooltip.bottom="i18n.t('candidate.parse.tooltip.enrich')"
      >
        <div class="gap-2 flex items-center">
          <p>
            {{ i18n.t('candidate.parse.button.enrich') }}
          </p>
          <BcSpinner
            size="small"
            v-if="isParseCandidatePending"
            color="white"
          />
        </div>
      </BcButton>
      <BcButton
        icon-left="edit-3"
        size="extra-small"
        @click="$emit('edit-candidate', true)"
        v-tooltip.bottom="i18n.t('candidate.parse.tooltip.edit')"
      />
    </div>
    <div class="flex w-full flex-col items-start overflow-hidden">
      <div class="gap-3 mb-0.5">
        <a
          :href="`https://www.linkedin.com/search/results/people/?keywords=${candidate.firstName} ${candidate.lastName}`"
          class="!text-blue-500 hover:underline"
          target="_blank"
        >
          <p class="text-md">{{ candidate.firstName }} {{ candidate.lastName }}</p>
        </a>
        <LinkedinConnectionBadge
          v-if="unipileAccountsStore.linkedInAccount && relationStatus && relationStatus !== LinkedinRelationStatus.NotConnected"
          :relation-status="relationStatus"
          :loading="relationLoading"
          @refresh="syncRelationStatus(candidate._id)"
        />
      </div>

      <div class="flex mb-2.5 w-full items-center overflow-hidden gap-[5px]">
        <p
          v-tooltip.bottom="candidate.title"
          class="truncate text-sm font-bold text-blue-800 tracking-tight"
        >
          {{ candidate.title }}
        </p>
        <div
          v-if="isContact && associatedCompanies.length"
          class="gap-1"
        >
          <RouterLink
            v-for="company in associatedCompanies.slice(0, 2)"
            :key="company._id"
            :to="{ name: route.name, params: { id: company._id }, query: { ...route.query, type: 'companies' } }"
            class="flex hover:opacity-75"
          >
            <CompactTag
              :label="company.name"
              color="purple"
            >
              <template #logo>
                <ImageWithDefault
                  :default-image-url="defaultCompanyAvatar"
                  :src="company.logoUrl ?? ''"
                  alt="companyLogo"
                  class="rounded-full"
                />
              </template>
            </CompactTag>
          </RouterLink>
          <v-popover
            v-if="associatedCompanies.length > 2"
            :trigger="'click hover'"
          >
            <CompactTag
              :label="`+${associatedCompanies.slice(2).length}`"
              color="purple"
            >
              <template #logo>
                <ImageWithDefault
                  :default-image-url="defaultCompanyAvatar"
                  alt="companyLogo"
                  class="rounded-full shrink-0"
                />
              </template>
            </CompactTag>
            <template #popover>
              <div class="flex flex-col p-[10px] gap-1">
                <RouterLink
                  v-for="company in associatedCompanies.slice(2)"
                  :key="company._id"
                  :to="{ name: route.name, params: { id: company._id }, query: { ...route.query, type: 'companies' } }"
                  class="flex justify-center"
                >
                  <p class="flex gap-1 text-white">
                    <ImageWithDefault
                      :default-image-url="defaultCompanyAvatarTooltip"
                      :url="company.logoUrl || defaultCompanyAvatarTooltip"
                      alt="companyLogo"
                      class="rounded-full !w-[20px] !h-[20px]"
                    />
                    {{ company.name }}
                  </p>
                </RouterLink>
              </div>
            </template>
          </v-popover>
        </div>
      </div>
      <div class="flex flex-row gap-2.5 overflow-x-auto w-full">
        <a
          :class="{
            'pointer-events-none opacity-50': !candidate.social?.linkedin,
          }"
          :href="candidate.social?.linkedin || '#'"
          class="cursor-pointer text-sm flex-shrink-0"
          target="_blank"
        >
          <div class="flex size-[36px] items-center justify-center rounded-full bg-blue-100">
            <img
              :src="linkedin"
              alt="linkedin"
              class="size-[18px]"
            />
          </div>
        </a>
        <PanelTopActionLinkedin
          v-if="!!unipileAccountsStore.linkedInAccount"
          :candidate="candidate"
        />
        <PanelTopActionButton
          v-if="isEmailVerified"
          :enabled="!!candidate.email"
          :icon-src="emailIcon"
          @on-action="onSendMail"
        />
        <div
          v-if="!isEmailVerified && hasGoogleWidgets"
          class="flex size-[36px]  flex-shrink-0 items-center justify-center rounded-full bg-blue-100"
        >
          <a
            :class="{ 'pointer-events-none opacity-50': !candidate.email }"
            :href="gmailLink"
            class="text-sm"
            target="_blank"
          >
            <p class="text-sm">
              <img
                :src="gmail"
                alt="gmail"
                class="size-[18px]"
              />
            </p>
          </a>
        </div>
        <div
          v-if="!isEmailVerified && hasMicrosoftWidgets"
          class="flex size-[36px] flex-shrink-0 items-center justify-center rounded-full bg-blue-100"
        >
          <a
            :class="{ 'pointer-events-none opacity-50': !candidate.email }"
            :href="outlookLink"
            class="text-sm"
            target="_blank"
          >
            <p class="text-sm">
              <img
                :src="outlook"
                alt="outlook"
                class="size-[18px]"
              />
            </p>
          </a>
        </div>
        <div class="flex size-[36px] items-center justify-center rounded-full bg-blue-100">
          <a
            :class="{ 'pointer-events-none opacity-50': !whatsappLink }"
            :href="whatsappLink"
            class="p-1.5 text-sm"
            target="_blank"
          >
            <img
              :src="whatsapp"
              alt="whatsapp"
              class="size-[18px]"
            />
          </a>
        </div>
        <div
          v-if="hasGoogleWidgets"
          class="flex size-[36px] flex-shrink-0 items-center justify-center rounded-full bg-blue-100"
        >
          <a
            :href="googleCalendarLink"
            class="text-sm"
            target="_blank"
          >
            <img
              :src="gcalendar"
              alt="gcalendar"
              class="size-[18px]"
            />
          </a>
        </div>
        <div
          v-if="hasMicrosoftWidgets"
          class="flex size-[36px] items-center justify-center rounded-full bg-blue-100"
        >
          <div
            v-if="hasMicrosoftWidgets"
            class="flex size-[36px] items-center justify-center rounded-full bg-blue-100"
          >
            <a
              :href="microsoftCalendarLink"
              class="text-sm"
              target="_blank"
            >
              <img
                :src="outlookcalendar"
                alt="outlookcalendar"
                class="size-[18px]"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slide-up {
  animation: slideUp 1s ease-out infinite alternate;
}
</style>
