export default {
  namespaced: true,
  state() {
    return {
      status: {
        inbox: {
          coders: false,
          partners: false
        },
        count: 0
      },
      notifications: []
    };
  },
  actions: {
    incrementCount({ commit }) {
      commit('incrementCount');
    },
    decrementCount({ commit }) {
      commit('decrementCount');
    },
    resetNotificationsCount({ commit }) {
      commit('resetNotificationsCount');
    },
    updateNotificationsStatus({ commit }, data) {
      commit('updateNotificationsStatus', data);
    },
    getNotifications({ commit }) {
      commit('getNotifications')
    },
    updateNotifications({ commit }, data) {
      commit('updateNotifications', data)
    },
    updateReadAt({ commit }, data) {
      commit('updateReadAt', data)
    },
    updateAllReadAt({ commit }, data) {
      commit('updateAllReadAt', data)
    }
  },
  getters: {},
  mutations: {
    incrementCount(state) {
      ++state.status.count;
    },
    decrementCount(state) {
      if (state.status.count > 0) {
        --state.status.count;
      }
    },
    resetNotificationsCount(state) {
      state.status.count = 0;
    },
    updateNotificationsStatus(state, { key, value }) {
      const keys = key.split('.');
      const newValue = keys.reduce((acc, curr, i) => {
        return acc[curr] = keys[i + 1] === undefined ? value : state.status[curr]
      }, {});

      state.status = { ...state.status, ...newValue };
    },
    updateNotifications(state, notifications) {
      state.notifications = notifications;
    },
    updateReadAt(state, { id, value }) {
      const notification = state.notifications.find(n => n._id === id);
      notification.readAt = value;
    },
    updateAllReadAt(state, value) {
      state.notifications.map(n => n.readAt = value);
    }
  }
};
