import { useRoute, useRouter } from 'vue-router/composables';
import { useStore } from '@/store';

export function usePanelNavigation() {
  const router = useRouter();
  const route = useRoute();
  const store = useStore();

  type OpenPanelParams = {
      id: string;
      type?: string;
      category?: string;
      subtype?: string;
      context?: string;
      query?: any;
  };

  const goPrevious = () => {
      router.go(-1);
  };

  const closePanel = () => {
      if (store.state.app.modal) {
          return;
      }
      delete route.query.type;
      delete route.query.category;
      delete route.query.modal;
      delete route.query.subtype;
      delete route.query.context;
      delete route.query['coaches-activities'];
      delete route.query['users-activities'];
      delete route.query['processes-activities'];
      delete route.query['all-activities'];
      delete route.query['unsaved-changes'];
      delete route.query['timeline-type'];
      delete route.query['view-tab'];
      delete route.query.display;
      delete route.query.quicksearch;

      if (route.query.from === 'contact-autocomplete') {
          delete route.query.contactInput;
          delete route.query.from;
          delete route.query.companyId;
      }

      if (!route.params.id || route.params.id === 'panel' || route.params.from === 'panel' || route.params.id === 'new-custom-jobs') {
          goPrevious();
      } else {
          let query = route.query;
          const savedQuery = sessionStorage.getItem('savedQuery');
          if (!Object.keys(query).length && savedQuery) {
              query = JSON.parse(savedQuery);
          }

          router.push({
              path: route.path.substr(0, route.path.lastIndexOf('/')),
              query: {
                  ...query,
              },
          });
      }
  };

  function openPanel({ id, type, category, subtype, context, ...query }: OpenPanelParams) {
    if (!route.name) return;
    router.push({
      name: route.name?.includes('Panel') ? route.name : `${route.name}Panel`,
      params: {
        ...route.params,
        id,
      },
      query: {
        ...route.query,
        ...type && { type },
        ...category && { category },
        ...subtype && { subtype },
        ...context && { context },
        ...query,
      },
    });
  }

  function openPanelInNewTab({ id, type, category, subtype, context, ...query }: OpenPanelParams) {
    let routeName: string;
    if (route.name === 'HuntJobsPanel' || route.name === 'HuntJobs' || route.name === 'HuntJobsPanel') {
      routeName = 'HuntJobsPanel';
    } else if (route.name?.includes('Panel')) {
      routeName = route.name;
    } else {
      routeName = `${route.name}Panel`;
    }
    const newRoute = router.resolve({
      name: routeName,
      params: {
        ...route.params,
        id,
      },
      query: {
        ...route.query,
        ...type && { type },
        ...category && { category },
        ...subtype && { subtype },
        ...context && { context },
        ...query,
      },
    });

    window.open(newRoute.href, '_blank');
  }

  return {
      closePanel,
      goPrevious,
      openPanel,
      openPanelInNewTab,
  };
}
