import contracts from '@/api/contracts';

export const removeCompanyContract = id => {
  const execute = id => {
    try {
      return contracts.removeCompanyContract(id);
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    return execute(id);
  } catch (error) {
    throw error;
  }
};

export default {
  removeCompanyContract,
};
