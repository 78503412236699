<template>
  <div class="timeline-list">
    <timeline-item
      v-for="(action, index) in timeline"
      :key="action._id"
      :action="action"
      :action-index="index"
      :is-editable="isEditable"
      class="timeline-list__item"
      @link-process="$emit('link-process', $event)"
      @note-deleted="$emit('note-deleted', $event)"
      @note-edited="$emit('note-edited', $event)"
      @note-pinned="$emit('note-pinned', $event)"
      @unlink-process="$emit('unlink-process', $event)"
    >
    </timeline-item>
  </div>
</template>

<script>
  import TimelineItem from '@/components/Timeline/TimelineItem';

  export default {
    name: 'timeline-list',
    components: { TimelineItem },
    props: {
      /**
       * The array which will be looped
       */
      timeline: {
        type: Array,
        default: () => {
          return [];
        },
      },
      isEditable: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .timeline-list {
    position: relative;
    flex-direction: column;
    width: 100%;

    &__item {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
</style>
