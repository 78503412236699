<template>
  <div class="topbar-searchbar items-center relative">
    <mv-searchbar
      v-model="input"
      :is-input-loading="isLoading && input.length > 2"
      :input-style="{ width: '328px' }"
      :placeholder="$t('topbar.quick-search')"
    >
      <template #customContent>
        <div class="flex flex-col w-[328px] overflow-y-auto max-h-[700px]">
          <div class="topbar-searchbar__wrapper flex flex-col items-center justify-center w-full h-full">
            <div class="topbar-searchbar__title body-m--medium">
              {{ $tc('generics.candidate-cap', 2) }}
            </div>
            <router-link
              v-for="(suggestion, index) in codersSuggestions"
              :key="`${suggestion._id}-${index}`"
              :to="
                getPanelLink(suggestion._id, {
                  type: 'coders',
                  context: 'search-quick',
                })
              "
              class="topbar-searchbar__item w-full font-size-s items-center flex"
              @click.native="input=''">
              <bc-avatar
                :src="suggestion.pictureUrl"
                class="topbar-searchbar__item-avatar"
                size="xs"
                @error="setAlternativeImg">
              </bc-avatar>
              <HighlightText :text="suggestion.company ? `${suggestion.firstName} ${suggestion.lastName} - ${suggestion.company.name}` : `${suggestion.firstName} ${suggestion.lastName}`" :search="input" />
            </router-link>
            <p
              v-if="!codersSuggestions.length"
              class="topbar-searchbar__item topbar-searchbar__item--disabled topbar-searchbar__item-text font-size-s">
              {{ $t('topbar.no-candidates-found') }}
            </p>
            <router-link
              class="topbar-searchbar__link"
              to="/coders?page=1"
              @click.native="input=''">
              <i class="icon-users"></i> {{ $t('topbar.access-candidates-bdd') }}
            </router-link>
          </div>
          <div
            v-if="configurationFramework.showCompanies"
            class="topbar-searchbar__wrapper flex flex-col items-center justify-center w-full">
            <div class="topbar-searchbar__title body-m--medium is-capitalize">
              {{ $tc('generics.company', 2) }}
            </div>
            <router-link
              v-for="(suggestion, index) in partnersSuggestions"
              :key="`${suggestion._id}-${index}`"
              :to="getPanelLink(suggestion._id, { type: 'companies' })"
              class="topbar-searchbar__item w-full font-size-s items-center flex"
              @click.native="input=''">
              <bc-avatar
                :src="suggestion.logoUrl"
                class="topbar-searchbar__item-avatar"
                size="xs"
                @error="setAlternativeCompanyImg">
              </bc-avatar>
              <HighlightText :text="suggestion.name" :search="input" />
            </router-link>
            <p
              v-if="!partnersSuggestions.length"
              class="topbar-searchbar__item topbar-searchbar__item--disabled topbar-searchbar__item-text font-size-s">
              {{ $t('topbar.no-company-found') }}
            </p>
            <router-link
              class="font-size-s items-center topbar-searchbar__link"
              to="/partners?page=1"
              @click.native="input=''">
              <i class="icon-companies"></i> {{ $t('topbar.access-company-bdd') }}
            </router-link>
          </div>
          <div class="topbar-searchbar__wrapper flex-col items-center justify-center size-full">
            <div class="topbar-searchbar__title body-m--medium">
              {{ $tc('generics.contact-cap', 2) }}
            </div>
            <router-link
              v-for="(suggestion, index) in contactsSuggestions"
              :key="`${suggestion._id}-${index}`"
              :to="
                getPanelLink(suggestion._id, {
                  type: 'coders',
                  context: 'search-quick',
                })
              "
              class="topbar-searchbar__item w-full font-size-s is-align-items-center flex"
              @click.native="input=''">
              <bc-avatar
                :src="suggestion.pictureUrl"
                class="topbar-searchbar__item-avatar"
                size="xs"
                @error="setAlternativeImg">
              </bc-avatar>
              <HighlightText :text="suggestion.company ? `${suggestion.firstName} ${suggestion.lastName} - ${suggestion.company.name}` : `${suggestion.firstName} ${suggestion.lastName}`" :search="input" />
            </router-link>
            <p
              v-if="!contactsSuggestions.length"
              class="topbar-searchbar__item topbar-searchbar__item--disabled topbar-searchbar__item-text font-size-s">
              {{ $t('topbar.no-contacts-found') }}
            </p>
            <router-link
              class="topbar-searchbar__link"
              to="/contacts?page=1"
              @click.native="input=''">
              <i class="icon-contact"></i> {{ $t('topbar.access-contacts-bdd') }}
            </router-link>
          </div>
        </div>
      </template>
    </mv-searchbar>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import companiesApi from '@/api/company';
import codersApi from '@/api/coders';

import panelNavigation from '@/common-old/mixins/Panel/panelNavigation';
import { debounce } from '@/common-old/mixins/Debounce/debounce';

import ToastMixin from '@/mixins/toast';

import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
import MvSearchbar from '@/ui-kit/components/MvSearchbar/MvSearchbar.vue';
import searchbarMixins from '@/mixins/searchbar';
import HighlightText from '@/components/HighlightText.vue';

export default {
  name: 'topbar-searchbar',
  components: {
    BcAvatar,
    MvSearchbar,
    HighlightText,
  },
  mixins: [panelNavigation, ToastMixin, searchbarMixins],
  props: {
    isHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: '',
      isInputFocus: false,
      isCodersLoading: true,
      isPartnersLoading: true,
      isContactsLoading: true,
      isJobsLoading: true,
      codersSuggestions: [],
      contactsSuggestions: [],
      partnersSuggestions: [],
      jobsSuggestions: [],
    };
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
    }),
    ...mapGetters({
      configurationFramework: 'user/configurationFramework',
    }),
    isFromSuperOrganization() {
      return this.$store.getters['user/isSuperUser'];
    },
    isLoading() {
      return this.isCodersLoading ||
            this.isPartnersLoading
    },
    isInputEmpty() {
      return !!this.input;
    },
  },
  methods: {
    getSuggestions: debounce(function() {
      this.getCodersSuggestions();
      this.getPartnersSuggestions();
      this.getContactsSuggestions();
    }, 250),
    setAlternativeImg(event) {
      event.target.src = require('@/assets/img/default-avatar.svg');
    },
    setAlternativeCompanyImg(event) {
      event.target.src = require('@/assets/img/default-avatar-company.svg');
    },
    focusInputFocus(value = false) {
      this.isInputFocus = value;

      if (this.input) {
        this.getCodersSuggestions();
        this.getPartnersSuggestions();
      }
    },
    getPanelLink(id, newQueries) {
      let routeName;
      if (
        this.$route.name === 'HuntJobsPanel' ||
        this.$route.name === 'HuntJobs'
      ) {
        routeName = 'HuntJobsPanel';
      } else if (this.$route.name.includes('Panel')) {
        routeName = this.$route.name;
      } else {
        routeName = `${this.$route.name}Panel`;
      }

      return {
        name: routeName,
        params: {
          id,
        },
        query: {
          ...this.$route.query,
          ...newQueries,
          quicksearch: true,
        },
      };
    },
    async getCodersSuggestions() {
      try {
        this.isCodersLoading = true;

        const { data } = await codersApi.candidatesAutocomplete(this.input);

        this.codersSuggestions = data.map(coder => ({
          ...coder,
          type: 'coders',
        }));

        this.isCodersLoading = false;
      } catch (error) {
        this.$toast.show({
          type: 'error',
          message: this.$t('toast.error-occured'),
        });

        this.isCodersLoading = false;
      }
    },
    async getPartnersSuggestions() {
      if (!this.configurationFramework.showCompanies) {
        this.isPartnersLoading = false;
        return;
      }
      try {
        this.isPartnersLoading = true;

        const { data } = await companiesApi.partnersAutocomplete(this.input);

        this.partnersSuggestions = data.map(company => ({
          ...company,
          type: 'companies',
        }));

        this.isPartnersLoading = false;
      } catch (error) {
        this.$toast.show({
          type: 'error',
          message: this.$t('toast.error-occured'),
        });

        this.isPartnersLoading = false;
      }
    },
    async getContactsSuggestions() {
      try {
        this.isContactsLoading = true;

        const { data } = await codersApi.contactsAutocomplete(this.input);

        this.contactsSuggestions = data.map(contact => ({
          ...contact,
          type: 'coders',
        }));

        this.isContactsLoading = false;
      } catch (error) {
        this.$toast.show({
          type: 'error',
          message: this.$t('toast.error-occured'),
        });

        this.isContactsLoading = false;
      }
    },
    resetQuickSearch() {
      this.codersSuggestions = [];
      this.partnersSuggestions = [];
      this.contactsSuggestions = [];

      this.isCodersLoading = true;
      this.isPartnersLoading = true;
      this.isContactsLoading = true;
    },
  },
  watch: {
    input() {
      this.getSuggestions();
    },
    isInputEmpty() {
      this.resetQuickSearch();
    },
    isHover(to) {
      if (!to) {
        this.$refs.input?.blur();
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped>
.topbar-searchbar {
  width: 328px;

  &__container {
    width: 100%;
  }

  &__title {
    padding: 10px 20px;
  }

  &__icon {
    left: 20px;
    color: $color-grey-4;
  }

  &__wrapper {
    border-bottom: 1px solid $color-blue-medium;
  }

  &__item {
    padding: 7.5px 20px;
    color: inherit;

    &:hover {
      background: $color-blue-light;
      cursor: pointer;
    }

    &--disabled {
      pointer-events: none;
      color: rgba($color-tertiary, 0.5);
      padding-bottom: 20px;
    }
  }

  &__item-avatar {
    margin-right: 10px;
    flex-shrink: 0;
  }

  &__item-icon {
    color: $color-primary;
  }

  &__link {
    padding: 10px 20px;
    padding-bottom: 20px;
    color: $color-primary;
    text-decoration: none;
    font-size: $font-size-s;
    display: flex;
    align-items: center;
    gap: 7px;
  }
}
</style>
