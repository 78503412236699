import timeline from '@/api/timeline';
import { serializeNote } from '@/managers/notes/formatters/noteSerializers';

export const editEchange = async({ id, note, mentions }) => {
  const execute = ({ id, note }) => {
    try {
      return timeline.editNote({
        id,
        note: {
          // type: note.type,
          ...note.hasOwnProperty('rating') && note.rating >= 0 && { rating: note.rating },
          ...(note.hasOwnProperty('pin') && { pin: note.pin }),
          ...(note.hasOwnProperty('openToWork') && { openToWork: note.openToWork }),
          ...(note.hasOwnProperty('images') && { images: note.images }),
          ...(note.content || '').trim() && { content: note.content },
          mentions,
        },
      });
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    const serializedNote = serializeNote(note);

    const { data } = await execute({
      id,
      note: serializedNote,
    });

    return data;
  } catch (error) {
    throw error;
  }
};
