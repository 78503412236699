<template>
  <div class="modal-email-send-form" :class="{ 'pb-20': hasActiveResumeUpload || hasActiveMessages }">
    <ModalEmailHeader
      :title="$t('email.send-a-mail')"
      @on-close="onClose"
    />
    <div v-if="isEmailLoading" class="modal-email-send-form__content min-h-[50%] items-center justify-center">
      <bc-spinner></bc-spinner>
    </div>
    <div v-else class="modal-email-send-form__content">
      <email-send-form-companies
        v-if="params.isCompany"
        :action-id="params.actionId"
        :recipient-emails="params.emails"
        :warning-message="params.warningMessage"
        :selected-companies="selectedCompanies"
        @on-close="onClose"
      ></email-send-form-companies>
      <email-send-form
        v-else
        :coder="params.coder"
        :custom-job="params.customJob"
        :action-id="params.actionId"
        :recipient-emails="params.emails"
        :warning-message="params.warningMessage"
        :selected-cards="selectedCoders"
        :selected-custom-jobs="selectedCustomJobs"
        :is-application="params.isApplication"
        @on-close="onClose"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import EmailSendForm from '@/components/Email/EmailSendForm';
import EmailSendFormCompanies from '@/components/Email/EmailSendFormCompanies';
import ModalEmailHeader from '@/components/Modal/ModalEmail/ModalEmailHeader';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';

export default {
  name: 'modal-email-send-form',
  components: {
    EmailSendForm,
    EmailSendFormCompanies,
    ModalEmailHeader,
    BcSpinner,
  },
  beforeDestroy() {
    this.setParams({
      actionId: undefined,
      isApplication: undefined,
    });
  },
  computed: {
    ...mapState('app', ['params']),
    ...mapGetters({
      selectedCoders: 'emails/selectedCoders',
      selectedCompanies: 'emails/selectedCompanies',
      selectedCustomJobs: 'emails/selectedCustomJobs',
      isEmailLoading: 'emails/isLoading',
      hasActiveResumeUpload: 'resumeHistories/hasActiveResumeUpload',
      hasActiveMessages: 'messageHistories/hasActiveMessages',
    }),
  },
  methods: {
    ...mapActions({
      setParams: 'app/setParams',
    }),
    onClose() {
      this.$emit('close-modal');
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-email-send-form {
  position: relative;
  flex-direction: column;
  width: 640px;
  overflow: auto;
}
</style>
