<script lang="ts" setup>
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete.vue';
  import { useAutocomplete } from '@/composables/useAutocomplete';
  import { partnersAutocomplete } from '@/api/company';
  import { Company } from '@/domains/models/Company';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
  import { computed } from 'vue';

  const props = defineProps<{
    company: Company
  }>();

  const { suggestions, getSuggestions, inputValue } = useAutocomplete<Company>({
    getSuggestionsFn: partnersAutocomplete,
  });

  const filteredSuggestions = computed<Company[]>(() => suggestions.value?.filter(suggestion => suggestion._id !== props.company._id) || []);

  const emits = defineEmits<{
    (e: 'select', value: Company): void
  }>();
  const getSelectedProfile = (profile: Company) => {
    emits('select', profile);
  };
</script>

<template>
  <div class="flex items-start">
    <bc-autocomplete
      v-model="inputValue"
      :is-scrollable="false"
      :limit="5"
      :options="filteredSuggestions"
      :placeholder="$t('candidates.research-a-profile')"
      class="w-full"
      icon="search"
      name="companiesSuggestions"
      @input="getSuggestions"
      @select="getSelectedProfile">
      <template #option="{ option }">
        <bc-dropdown-item
          :text="option.name">
        </bc-dropdown-item>
      </template>
    </bc-autocomplete>
  </div>
</template>

<style lang="scss" scoped>
  :deep(.bc-input) {
    @apply mt-0;
  }

  :deep(.bc-input__wrapper) {
    @apply rounded-3xl;
  }
</style>
