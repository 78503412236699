<template>
  <div class="panel-coach-header bg-neutral-200 is-full-width is-justify-content-space-between is-align-items-center">
    <panel-header-nav />
    <panel-header-date
      :date="profile.createdAt"
      class="panel-coach-header__date"
      :text="$t('avatar-dropdown.profile-created-the')">
    </panel-header-date>
    <router-link
      :to="editLink.href"
      v-if="isDisplayedButton">
      <bc-button
        class="panel-coach-header__button"
        color="primary"
        icon-left="edit-3"
        radius-size="s"
        size="extra-small"
      >
        {{ $t('avatar-dropdown.edit-the-profile') }}
      </bc-button>
    </router-link>
  </div>
</template>

<script>
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import PanelHeaderDate from '@/components/Panel/PanelHeader/PanelHeaderDate';
  import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import { apiRoles } from '@/macros/coaches/roles';

  export default {
    name: 'panel-coach-header',
    components: {
      BcButton,
      PanelHeaderNav,
      PanelHeaderDate,
    },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
    },
    mixins: [PanelNavigation],
    computed: {
      user() {
        return this.$store.state.user.profile
      },
      editLink() {
        return this.$router.resolve({
          ...this.$route,
          query: {
            ...this.$route.query,
            type: 'edit-coach',
          },
        });
      },
      isDisplayedButton() {
        return this.canEditOtherProfile || this.$route.params.id === 'profile';
      },
      canEditOtherProfile() {
        return this.user.role === apiRoles.product || this.user.role === apiRoles.businessManager;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-coach-header {
    padding: 10px 15px;
    flex-shrink: 0;
    border-bottom: 1px solid $color-gray-mystic;
    height: 50px;

    &__log-as {
      margin-right: 20px;
    }

    &__date {
      margin-left: 20px;
      margin-right: auto;
    }
  }
</style>
