<template>
  <bc-dropdown
    class="bc-custom-field-multi-select"
    :id="$attrs.id"
    :is-scrollable="true"
    :limit="3"
    :close-dropdown-at-select="!isFilter"
    :options="customField.options"
    is-expanded
    v-bind="$attrs">
    <template #label="{ isDropdownOpen }">
      <bc-dropdown-trigger
        type="button"
        :is-arrow="true"
        :is-dropdown-open="isDropdownOpen"
        :placeholder="placeholder"
        :text="selectedValue">
      </bc-dropdown-trigger>
    </template>
    <template #option="{ activeIndex, index, option }">
      <bc-dropdown-item
        :is-focus="activeIndex === index"
        :text="option"
        :is-multi-select="true"
        :is-selected="checkIsSelected(option)"
        @click.native="selectOption(option, index)">
      </bc-dropdown-item>
    </template>
  </bc-dropdown>
</template>

<script>
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';

export default {
  name: 'bc-custom-field-multi-select',
  components: {
    BcDropdown,
    BcDropdownItem,
    BcDropdownTrigger,
  },
  props: {
    customField: {
      type: Object,
      required: true,
    },
    selected: {
      type: [String, Array],
    },
    isFilter: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
  },
  mounted() {
    this.handleSelectedValue(this.selected);
  },
  data() {
    return {
      selectedValue: undefined,
    };
  },
  methods: {
    selectOption(option) {
      let value = this.selected || [];
      if (value.includes(option)) {
        value = value.filter(v => v !== option);
      } else {
        value = [...value, option];
      }

      this.$emit('on-update', { key: this.customField.key, value });
    },
    checkIsSelected(option) {
      if (Array.isArray(this.selected)) {
        return this.selected.includes(option);
      }
      return this.selected === option;
    },
    handleSelectedValue(value) {
      if (Array.isArray(value)) {
        this.selectedValue = value.join(', ')
      } else if (value) {
        this.selectedValue = value;
      }
    }
  },
  watch: {
    selected(value) {
      this.handleSelectedValue(value);
    }
  }
};
</script>

<style lang="scss" scoped>
.bc-custom-field-multi-select {
  width: 100%;
}
</style>
