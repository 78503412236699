import company from '@/api/company';
import { getHuntJobs } from '@/api/hunt';

export default {
  actions: {
    async getPartnerProfile({ commit }, id) {
      const response = await company.getPartnerProfile(id);

      commit('setPanelProfile', { ...response.data });

      return response;
    },

    async createPartner({ commit }, newPartner) {
      const response = await company.createPartner(newPartner);

      commit('unshiftCard', { content: { ...response.data } });

      return response;
    },

    async updatePartner({ commit, rootState }, { id, obj }) {
      const response = await company.updatePartner({ id, obj });

      commit('updateValue', {
        storeName: 'panel',
        stateName: 'profile',
        data: {
          ...rootState.panel.profile,
          ...obj,
        },
      });

      return response;
    },
    async setHuntViewList({ commit }, params) {
      const response = await getHuntJobs(params);

      commit('setViewList', response.data._jobs);

      return response;
    },
    async setPartnerJobsViewList({ commit }, params) {
      const response = await company.getPartnerJobs(params);

      commit('setViewList', response.data);

      return response;
    },

    async setPartnerContactsViewList({ commit }, params) {
      const response = await company.getPartnerContacts(params.call);

      commit('setViewList', response.data);

      return response;
    },

    async setPartnerProspectingStatus({ commit }, { partnerId, status }) {
      const { data } = await company.setPartnerProspectingStatus({ partnerId, status });

      commit('updateValue', {
        storeName: 'panel',
        stateName: 'profile',
        data: {
          customStatus: data.customStatus,
        },
      });

      return data;
    },

    async deletePartner({ dispatch, commit, rootState }, id) {
      const response = await company.deletePartner(id);

      dispatch('user/updateMetadataByKey', {
        key: 'partners',
        value: rootState.user.metadata.partners - 1,
      });

      commit('removeCard', id);

      return response;
    },
  },
};
