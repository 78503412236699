<script lang="ts" setup>
  import PanelProcessKanbanCompanyHeader
    from '@/components/Panel/PanelProcessKanban/PanelProcessKanbanCompanyHeader.vue';
  import PanelProcessCustom from '@/components/Panel/PanelProcessCustom.vue';
  import { useQuery } from '@tanstack/vue-query';
  import { useRoute } from 'vue-router/composables';
  import companyApi from '@/api/company';

  const route = useRoute();

  const { data: company } = useQuery({
    queryKey: ['GET_COMPANY_PROFILE', route.params.id],
    queryFn: async() => {
      const { data } = await companyApi.getPartnerProfile(route.params.id);
      return data;
    },
  });
</script>

<template>
  <div class="flex grow flex-col">
    <PanelProcessKanbanCompanyHeader :company="company"/>
    <PanelProcessCustom
      :profile="company"
      class="flex max-h-full grow"
      type="company"
    />
  </div>
</template>
