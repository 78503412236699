import { http } from '@/api/index';

export const getProfessions = () => {
  return http.get('/professions');
};

export const getProfessionsAutocomplete = ({ name }) => {
  return http.get('/professions/autocomplete', {
    params: {
      query: name,
    }
  });
}

export const createProfession = ({ name, industryId }) => {
  return http.post('/professions', {
    name,
    industry: industryId,
  });
};

export const editProfession = ({ id, name, domains }) => {
  return http.patch(`/professions/${id}`, {
    name,
    domains
  });
};

export const deleteProfession = id => {
  return http.delete(`/professions/${id}`);
};

export default {
  getProfessions,
  createProfession,
  editProfession,
  deleteProfession,
};
