<template>
  <div class="picture-avatar">
    <img
      :src="imgSrc"
      :style="{width: width, height: height}"
      @error="setAlternativeImg"
      alt="User avatar"
      class="picture-avatar__img">
  </div>
</template>

<script>
  export default {
    name: 'picture-avatar',
    /**
     * The url of the image
     */
    props: {
      imgSrc: {
        type: String,
        default: require('@/assets/img/default-avatar.svg')
      },
      width: {
        type: String,
        default: '78px'
      },
      height: {
        type: String,
        default: 'auto'
      },
      fallbackImg: {
        type: String,
        default: require('@/assets/img/default-avatar.svg'),
      }
    },
    methods: {
      setAlternativeImg(event) {
        event.target.src = this.fallbackImg;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .picture-avatar {
    justify-content: center;
    align-items: center;
    width: 66px;
    height: 66px;
    border: 1px solid $color-gray-mystic;
    border-radius: 50%;
    flex-shrink: 0;
    overflow: hidden;
    background: white;
    z-index: 1;

    &__img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      pointer-events: none;
    }
  }
</style>
