import { http } from '@/api/index';

export const searchPartners = name => {
  return http.get('/search/partner', { params: { name } });
};

export const blockCompany = ({ partnerId, coderId }) => {
  return http.post(`/partners/${partnerId}/block`, { coderId });
};

export const unblockCompany = ({ partnerId, coderId }) => {
  return http.delete(`/partners/${partnerId}/block`, { data: { coderId } });
};

export default {
  searchPartners,
  blockCompany,
  unblockCompany
};
