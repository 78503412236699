export const validateContent = content => {
  if (content) {
    return;
  }

  throw new Error('Le contenu de la note est vide');
};

export default {
  validateContent,
};
