<template>
  <div class="statistics-item">
    <div class="statistics-item__header">
      <p class="statistics-item__title">
        {{ title }}
      </p>
      <div>
        <slot name="filters"></slot>
      </div>
    </div>
    <div
      class="statistics-item__content"
      :class="{
        'bg-blue-100 border-none rounded-t-[10px]': $slots.default,
        'bg-white border-[10px]': !$slots.default,
      }">
      <div v-if="isLoading && !steps.length" class="statistics-item__spinner is-full-width">
        <bc-spinner></bc-spinner>
      </div>
      <div
        v-for="(step, index) in steps"
        :key="step.key"
        class="statistics-item__item-container"
        :class="{ 'statistics-item__separator': index < steps.length - 1 }">
        <div class="statistics-item__item">
          <div>
            <div class="statistics-item__icon">
              <i :class="`icon-${step.icon}`"></i>
            </div>
            <div v-if="isLoading" class="statistics-item__spinner">
              <bc-spinner></bc-spinner>
            </div>
            <div v-else class="statistics-item__count-container">
              <div class="statistics-item__count">
                <h2
                  v-tooltip.right="{
                    content: `${step.value}/${step.total}`,
                    classes: 'app-bar-tooltip'
                  }">
                  {{ step.value }}
                </h2>
                <!-- <h4>{{ step.total }}</h4> -->
              </div>
            </div>
          </div>
          <p class="statistics-item__label">{{ step.name }}</p>
          <div
            v-if="getIsTopPerformersVisible(step)"
            class="is-align-items-center mt-3">
            <bc-avatar
              v-tooltip.bottom="{ content: getHtml(step.topPerformers) }"
              :placeholder="
                getFullNameInitial(
                  `${getFullName(step.topPerformers[0])}`
                )
              "
              :src="(step.topPerformers[0] || {}).pictureUrl"
              bc-avatar-radius="100%"
              size="xs">
            </bc-avatar>
            <p class="statistics-item__performers-label ml-2">
              {{ $t('generics.team-detail') }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$slots.default" class="statistics-item__content--slot">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';

export default {
  name: 'statistics-item',
  components: {
    BcAvatar,
    BcSpinner,
  },
  props: {
    title: {
      type: String,
    },
    steps: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
    }),
  },
  methods: {
    getHtml(topPerformers) {
      if (!topPerformers.length) {
        return '';
      }

      const topPerformersList = topPerformers
        .map(
          (rank, index) =>
            `<li style="text-align: left;">
                ${index + 1}. ${this.getFullName(rank)} (<span>${rank.score}</span
                >)
          </li>`
        )
        .join('');

      return `
          <ul class="is-flex is-column is-text-align-left" style="list-style-type: none;">
            ${topPerformersList}
          </ul>`;
    },
    getFullName(step = {}) {
      if (step.fullName) return step.fullName;
      return `${step.firstName} ${step.lastName}`;
    },
    getFullNameInitial(fullName) {
      return fullName
        .split(' ')
        .map(name => name[0])
        .join('');
    },
    getIsTopPerformersVisible(step) {
      return this.profile?._organization?.nbUsers > 1 && step?.topPerformers?.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-item {
  width: 100%;
  flex-direction: column;

  &__header {
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
  }

  &__title {
    color: $color-blue-dark-cello;
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
  }

  &__label {
    width: 100%;
    color: $color-tertiary;
    font-size: $font-size-m;
    min-inline-size: 200px;
    overflow-wrap: break-word;
    margin-top: 5px;
  }

  &__content {
    height: 141px;
    border: 1px solid $color-blue-medium;
    padding: 20px 0px;
    overflow: auto;

    &--slot {
      border-radius: 0px 0px 10px 10px;
      border: 1px solid var(--MEDIUM-BLUE, #D9DFFD);
      background: #FFF;
      padding: 30px;
    }
  }

  &__separator {
    border-right: 1px solid $color-blue-medium;
  }

  &__item-container {
    width: 100%;
    padding: 0px 20px;
  }

  &__item {
    width: 100%;
    flex-direction: column;
  }

  &__count {
    color: $color-tertiary;
    align-items: center;

    & h2 {
      font-size: 32px;
      font-weight: 500;
    }

    & h4 {
      font-size: 24px;
      font-weight: 500;
    }

    &-container {
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__performers-label {
    color: $color-blue-heavy-dark;
    font-size: $font-size-s;
  }

  &__icon {
    width: 44px;
    height: 44px;
    padding: 0px 20px;
    background: white;
    color: $color-primary;
    border-radius: 10px;
    box-shadow: 0px 4px 30px 0px rgba(29, 57, 94, 0.10);
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  &__spinner {
    align-items: center;
    justify-content: center;
  }
}
</style>
