
export const validateMedia = media => {
  if (media.length > 0) {
    return;
  }

  throw new Error(`${this.$i18n.t('candidates.the-note-does-not-contain-any-media')}`);
};

export default {
  validateMedia,
};
