<template>
  <div
    class="mv-select"
    ref="mv-select"
    :class="[!asButton && !size && 'w-full', size === 's' && 'w-fit']"
    v-click-outside="handleClickOutside"
  >
    <label v-if="label" class="mv-select__label">
      {{ label }} <span v-if="required" class="mv-select__required">*</span>
    </label>
    <button
      :class="{
        'mv-select__as-btn': asButton,
        'mv-select__as-btn--isopen': asButton && isOpen,
        'mv-select__btn': !asButton,
        'opacity-50': disabled,
      }"
      :style="{ 'background-color': backgroundColor, color }"
      :disabled="disabled"
      @click.prevent="handleClick">
      <span v-if="currentSelectedLabel" :class="`body-${labelSize} flex gap-2 items-center`">
        <i v-if="icon" :class="`icon-${icon}`"></i> {{ currentSelectedLabel }}
      </span>
      <span v-else-if="placeholder" :class="`body-${labelSize} flex items-center gap-2 text-ellipsis`">
        <i v-if="icon" :class="`icon-${icon}`"></i> {{ placeholder }}
      </span>
      <span v-if="!asButton" class="body-m ml-1">
        <i
          class="mv-select__icon"
          :class="{
            'icon-chevron-up': isOpen,
            'icon-chevron-down': !isOpen,
          }"></i>
      </span>
    </button>
    <portal v-if="isOpen && !isAbsolute && items.length" to="mv-select">
      <ul
        v-show="isOpen"
        class="mv-select__items"
        @mouseenter="isHoverItems = true"
        @mouseleave="isHoverItems = false"
        :style="{
          left: `${position.left}px`,
          top: `${position.bottom + 10}px`,
          width: `${width ? width : `${position.width}px`}`,
        }">
        <li
          v-for="(item, index) in items"
          :key="item.value"
          :class="{
            'mv-select__border': index !== items.length - 1,
            'mv-select__selected': isSelected(item) || item.selected,
            'bg-primary': item.selected,
          }"
          @click.prevent.stop="handleClickItem(item, index)">
          <div class="flex items-center gap-2">
            <div v-if="withCheckbox">
              <bc-checkbox
                :id="item.id || item._id"
                :value="item.selected"
              ></bc-checkbox>
            </div>
            <div v-if="item.avatar">
              <bc-avatar
                :src="item.avatar"
                size="xxs"
                @error="$emit('error', $event)" />
            </div>
            <span>{{ item.label }}</span>
            <slot name="content" :item="item"></slot>
          </div>
        </li>
      </ul>
    </portal>
    <div
      v-if="isOpen && isAbsolute && items.length"
      ref="mv-select__items--absolute"
      class="mv-select__items--absolute w-full"
    >
      <ul
        v-show="isOpen"
        class="mv-select__items size-full"
        :class="{ 'mv-select__items--absolute--top': direction === 'top' }"
        @mouseenter="isHoverItems = true"
        @mouseleave="isHoverItems = false">
        <li
          v-for="(item, index) in items"
          :key="item.value"
          :class="{
            'mv-select__border': index !== items.length - 1,
            'mv-select__selected': isSelected(item) || item.selected,
            'bg-primary': item.selected,
          }"
          @click.stop="handleClickItem(item, index)">
          <div class="flex items-center gap-2">
            <div v-if="withCheckbox">
              <bc-checkbox
                :id="item.id"
                :value="item.selected"
              ></bc-checkbox>
            </div>
            <div v-if="item.avatar">
              <bc-avatar
                :src="item.avatar"
                size="xxs"
                @error="$emit('error', $event)" />
            </div>
            <span>{{ item.label }}</span>
            <slot name="content" :item="item"></slot>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';

export default {
  name: 'mv-select',
  components: {
    BcAvatar,
    BcCheckbox,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number, Object],
    },
    label: {
      type: String,
    },
    selectedLabel: {
      type: String,
    },
    backgroundColor: {
      type: String,
    },
    color: {
      type: String,
    },
    isAbsolute: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    labelSize: {
      type: String,
      default: 'm',
    },
    asButton: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    selectFullItem: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String,
      default: 'bottom',
    },
    withCheckbox: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
    },
    width: {
      type: String,
    }
  },
  mounted() {
    this.setPosition();
  },
  data() {
    return {
      isOpen: false,
      isHoverItems: false,
      position: {},
    };
  },
  computed: {
    selectedItem() {
      return this.items.find(item => item.value == this.value);
    },
    currentSelectedLabel() {
      if (this.selectedLabel) return this.selectedLabel;
      return this.selectedItem?.label;
    },
  },
  methods: {
    handleClick() {
      if (!this.position.width) {
        this.setPosition();
      }
      this.$nextTick(() => {
        this.isOpen = !this.isOpen;
      });
    },
    handleClickOutside() {
      if (this.isHoverItems) return;
      this.isOpen = false;
    },
    handleClickItem(item, index) {
      if (!this.withCheckbox) {
        this.isOpen = false;
      }
      this.$emit('select', this.selectFullItem ? item : item.value, item.onClick, index);
    },
    isSelected(item) {
      return String(this.value) === String(item.value);
    },
    setPosition() {
      const ref = this.$refs['mv-select'];
      this.position = ref.getBoundingClientRect();
    }
  },
};
</script>

<style lang="scss" scoped>
.mv-select {
  flex-direction: column;
  position: relative;

  &__btn {
    width: 100%;
    height: 36px;
    border: 1px solid $color-blue-medium;
    border-radius: 5px;
    background: white;
    align-items: center;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $color-tertiary;
    justify-content: space-between;
  }

  &__as-btn {
    min-width: 218px;
    height: 36px;
    border-radius: 30px;
    border: 1px solid transparent;
    background: $color-primary;
    align-items: center;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: white;
    justify-content: center;

    &--isopen {
      background: $color-neutre-4;
      border: 1px solid $color-blue-medium;
      color: $color-primary;
    }
  }

  &__items {
    position: absolute;
    z-index: 9999999999999;
    background: white;
    height: auto;
    list-style-type: none;
    border: 1px solid $color-blue-light;
    box-shadow: 0px 2px 4px rgba(29, 57, 94, 0.1);
    border-radius: 5px;
    max-height: 400px;
    overflow: auto;

    & > li {
      padding: 10px 20px;
      min-height: 39px;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: $color-tertiary;

      &:hover {
        cursor: pointer;
        background: $color-blue-light;
        color: $color-primary;
      }
    }

    &--absolute {
      position: absolute;
      width: 100%;
      min-width: 160px;
      top: 100%;
      left: 0;
      margin-top: 5px;

      &--top {
        top: auto !important;
        bottom: 100%;
        margin-bottom: 45px;
        max-height: 300px;
      }
    }
  }

  &__border {
    border-bottom: 1px solid $color-blue-medium;
  }

  &__selected {
    color: $color-primary !important;
    background: $color-blue-light;
  }

  &__icon {
    font-size: 10px;
  }

  &__label {
    color: $color-blue-heavy-dark;
    font-size: $font-size-xs;
    font-weight: 400;
    margin-bottom: 5px;
    margin-left: 10px;
  }

  &__required {
    color: $color-red-mandy;
    margin-left: 3px;
    font-size: 12px;
  }
}
</style>
