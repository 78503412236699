<template>
  <div class="searchbar-filters-location">
    <p class="searchbar-filters-location__title">
      {{ $t('timeline.location') }}
    </p>
    <div class="searchbar-filters-location__wrapper">
      <dropdown-select
        :bc-autocomplete="true"
        :bc-dropdown-border-color-opacity="0.4"
        :bc-empty-input-on-click="true"
        :bc-icon-caret-displayed="false"
        :bc-input-box-shadow-active="true"
        :bc-is-asynchronous="true"
        :bc-is-loading="isLoading"
        :bc-is-no-results="isNoResults"
        :bc-item-background-color-hover-opacity="0.05"
        :bc-options="locations"
        :bc-options-box-shadow-opacity="0.3"
        :bc-placeholder="placeholder"
        @change="setLocation($event), locationSet = $event.name"
        @input="getSuggestions($event), checkValue($event)"
        bc-dropdown-border-color="blue-dark-cello"
        bc-input-font-color="blue-dodger"
        bc-input-font-size="14px"
        bc-input-padding="10px 50px 10px 20px"
        bc-input-padding-focus="10px 50px 10px 20px"
        bc-item-background-color-active="blue-dark-cello"
        bc-item-background-color-hover="blue-dark-cello"
        bc-item-font-color="blue-dark-cello"
        bc-item-font-size="14px"
        bc-no-results-text-font-size="14px"
        bc-options-border-bottom-radius="4px"
        bc-options-box-shadow-config="0px 3px 6px"
        bc-options-margin="-2px auto auto auto"
        class="searchbar-filters-location__dropdown">
      </dropdown-select>
    </div>
  </div>
</template>

<script>
  // TODO move or refacto to ui-kit
  import { mapState } from 'vuex';
  import DropdownSelect from '@/legacy/ui-kit/components/Dropdown/BcDropdown';
  import debounce from 'debounce';

  export default {
    components: {
      DropdownSelect
    },
    name: 'searchbar-filters-location',
    props: {
      placeholder: {
        type: String
      }
    },
    computed: {
      ...mapState({
        locations: state => state.location.locationsSuggestions
      })
    },
    data: () => ({
      isNoResults: false,
      isLoading: false,
      locationSet: ''
    }),
    methods: {
      getSuggestions: debounce(function(value) {
        if (value && value !== this.value && value !== this.locationSet) {
          try {
            this.setIsLoading(true);
            this.$store.dispatch('getLocationsAutocomplete', { query: value });
            this.isNoResults = false;
          } catch (error) {
            this.isNoResults = true;
            this.$store.dispatch('emptyLocationsSuggestions');
          }
          this.setIsLoading(false);
        }
      }, 500),
      checkValue(value) {
        if (!value) {
          this.$store.dispatch('emptyLocationsSuggestions');
        }
      },
      setIsLoading(value) {
        this.isLoading = value;
      },
      setLocation(location) {
        /**
         * We determinate if the tag we want to add will be a technos or a search
         * @type {string}
         */
        const tagObject = {
          type: 'location',
          name: location.description,
          query: {
           placeId: location.place_id,
          }
        };

        this.$emit('on-select', tagObject);

        this.$store.dispatch('emptyLocationsSuggestions');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .searchbar-filters-location {
    flex-direction: column;
    padding: 20px 20px -20px;
    @include bp('tablet') {
      flex-direction: row;
    }

    &__title {
      display: flex;
      margin-bottom: 20px;
      font-size: $font-size-s;
      color: $color-blue-dark-cello;
      line-height: 18px;
      align-items: center;
      @include bp('tablet') {
        width: 100%;
        margin-bottom: 0;
        max-width: 85px;
        margin-right: 35px;
      }
    }

    &__wrapper {
      position: relative;
      justify-content: center;
      align-items: center;
      width: 100%;
      @include bp('tablet') {
        justify-content: flex-start;
      }
    }

    &__dropdown {
      max-width: 420px;

      & :deep() {
        input::placeholder {
          color: $color-blue-dark-cello;
          opacity: 0.4;
        }
      }
    }
  }
</style>
