<template>
  <div class="searchbar-filters-experience">
    <p class="searchbar-filters-experience__title">
      {{ $t('table-header.expertise') }}
    </p>
    <div class="searchbar-filters-experience__wrapper">
      <bc-checkbox
        class="searchbar-filters-experience__checkbox"
        id="student"
        v-if="!$route.name.includes('Coders') && ($route.query || {})['list-type'] !== 'coder'"
        v-model="student">
        {{ $t('table-header.student') }}
      </bc-checkbox>
      <bc-checkbox
        class="searchbar-filters-experience__checkbox"
        id="junior"
        v-model="junior">
        {{ $t('table-header.junior') }}
      </bc-checkbox>
      <bc-checkbox
        class="searchbar-filters-experience__checkbox"
        id="confirmed"
        v-model="confirmed">
        {{ $t('table-header.confirmed') }}
      </bc-checkbox>
      <bc-checkbox
        class="searchbar-filters-experience__checkbox"
        id="expert"
        v-model="expert">
        {{ $t('table-header.expert') }}
      </bc-checkbox>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { buildExperienceTag } from '@/helpers/searchbar/factories';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';

  export default {
    components: { BcCheckbox },
    name: 'searchbar-filters-experience',
    computed: {
      ...mapState({
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags,
      }),
      student: {
        get() {
          return this.searchbarFiltersTags.some(tag => tag.query === 'student');
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildExperienceTag('student'));
          } else {
            const tagIndex = this.searchbarFiltersTags.findIndex(tag => tag.type === 'experience' && tag.query === 'student');
            if (tagIndex >= 0) {
              this.$emit('on-unselect', tagIndex);
            }
          }
        },
      },
      junior: {
        get() {
          return this.searchbarFiltersTags.some(tag => tag.query === 'junior');
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildExperienceTag('junior'));
          } else {
            const tagIndex = this.searchbarFiltersTags.findIndex(tag => tag.type === 'experience' && tag.query === 'junior');
            if (tagIndex >= 0) {
              this.$emit('on-unselect', tagIndex);
            }
          }
        },
      },
      confirmed: {
        get() {
          return this.searchbarFiltersTags.some(tag => tag.query === 'confirmed');
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildExperienceTag('confirmed'));
          } else {
            const tagIndex = this.searchbarFiltersTags.findIndex(tag => tag.type === 'experience' && tag.query === 'confirmed');
            if (tagIndex >= 0) {
              this.$emit('on-unselect', tagIndex);
            }
          }
        },
      },
      expert: {
        get() {
          return this.searchbarFiltersTags.some(tag => tag.query === 'expert');
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildExperienceTag('expert'));
          } else {
            const tagIndex = this.searchbarFiltersTags.findIndex(tag => tag.type === 'experience' && tag.query === 'expert');
            if (tagIndex >= 0) {
              this.$emit('on-unselect', tagIndex);
            }
          }
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-filters-experience {
    flex-direction: column;
    @include bp('tablet') {
      flex-direction: row;
    }

    &__title {
      margin-bottom: 20px;
      font-size: $font-size-s;
      justify-content: center;
      color: $color-blue-dark-cello;
      line-height: 18px;
      @include bp('tablet') {
        justify-content: flex-start;
        text-align: left;
        margin-bottom: 0;
        width: 85px;
        margin-right: 35px;
      }
    }

    &__wrapper {
      position: relative;
      flex-wrap: wrap;
      margin: -5px -15px;
      width: 100%;
      @include bp('tablet') {
        min-width: 305px;
        margin-top: -5px;
        width: initial;
      }
    }

    &__checkbox {
      justify-content: center;
      margin: 5px 15px;
      @include bp('tablet') {
        flex: initial;
        margin-right: 30px;
      }
    }
  }
</style>
