<template>
  <modal-feature
    :feature="params.feature"
    :title="$t('features.db-limit-title', { limit, ressource: featureTrads })"
    :show-footer="false"
    @close-modal="$emit('close-modal')"
  >
    <template #content>
      <p>{{ $t('features.db-limit-content', { limit, ressource: featureTrads }) }}</p>
    </template>
    <template #logo>
      <img src="@/assets/logo/lock.png" alt="lock logo" />
    </template>
  </modal-feature>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ModalFeature from './ModalFeature';

const tradsMap = {
  "candidatesLimitUnlocked": "candidate-cap",
  "companiesLimitUnlocked": "company",
  "jobsLimitUnlocked": "opportunity",
}

export default {
  name: 'modal-feature-db-limit',
  components: {
    ModalFeature,
  },
  computed: {
    ...mapState('app', ['params']),
    ...mapGetters({
      configurationDb: 'user/configurationDb',
    }),
    limit() {
      return this.configurationDb[this.params.feature.replace('Unlocked', '')];
    },
    featureTrads() {
      return this.$i18n.tc(`generics.${tradsMap[this.params.feature]}`, 2)
    },
  },
}
</script>
