<template>
  <component
    :is="panelFavoriteListUpdateComponent"
    v-bind="$attrs"
    v-on="$listeners">
  </component>
</template>

<script>
  import PanelFavoriteListUpdateCoach
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListUpdate/PanelFavoriteListUpdateCoach';
  import PanelFavoriteListUpdateRpo
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListUpdate/PanelFavoriteListUpdateRpo';

  export default {
    name: 'panel-favorite-list-update',
    components: { PanelFavoriteListUpdateRpo, PanelFavoriteListUpdateCoach },
    inheritAttrs: false,
    computed: {
      panelFavoriteListUpdateComponent() {
        return this.$store.getters['user/isSuperUser'] ? 'panel-favorite-list-update-coach' : 'panel-favorite-list-update-rpo';
      },
    },
  };

</script>
