<template>
  <div class="reminders-buttons is-align-items-center">
    <RemindersButtonsArchive v-if="!isArchived" @click.stop.native="archive" />
    <RemindersButtonsTransfer
      v-if="isSuggestionNotTransfered"
      v-tooltip="$t('candidates.transfer-this-reminder')"
      @click.stop.native="modal = 'transfer'" />
    <RemindersButtonsUnarchive v-if="isArchived" @unarchive="unarchive($event)" />
    <RemindersButtonsLater
      v-if="isSuggestionNotTransfered && !isArchived"
      v-tooltip="$t('candidates.postpone-this-reminder')"
      @postpone="later" />
    <RemindersButtonsDelete
      v-if="isArchived"
      v-tooltip="$t('candidates.delete-this-reminder')"
      @click.stop.native="handleDelete" />
    <bc-modal :active.sync="isModalOpen" @close="closeModal">
      <RemindersDeleteModal
        v-if="modal === 'confirmDelete'"
        @cancel="closeModal"
        @confirm="reject" />
      <RemindersTransferModal
        v-else-if="modal === 'transfer'"
        @cancel="closeModal"
        @confirm="transfer" />
    </bc-modal>
  </div>
</template>

<script setup lang="ts">
import suggestionsHttp from '@/api/suggestions';

import RemindersButtonsTransfer from '@/components/Reminders/RemindersButtons/RemindersButtonsTransfer.vue';
import RemindersButtonsLater from '@/components/Reminders/RemindersButtons/RemindersButtonsLater.vue';
import RemindersButtonsDelete from '@/components/Reminders/RemindersButtons/RemindersButtonsDelete.vue';
import RemindersButtonsArchive from '@/components/Reminders/RemindersButtons/RemindersButtonsArchive.vue';
import RemindersButtonsUnarchive from '@/components/Reminders/RemindersButtons/RemindersButtonsUnarchive.vue';
import RemindersTransferModal from '@/components/Reminders/RemindersTransferModal.vue';
import RemindersDeleteModal from '@/components/Reminders/RemindersDeleteModal.vue';

import suggestionsMacros from '@/macros/suggestions';
import BcModal from '@/ui-kit/components/BcModal/BcModal.vue';
import { useToast } from '@/ui-kit/components/BcToast';
import { useI18n } from '@/i18n/i18n';
import { ref, computed } from 'vue';

const props = defineProps<{
  suggestion: {
    type: any;
    status?: number;
    archived?: boolean;
    _coach?: any;
    _id: string;
  };
}>();

const emit = defineEmits<{
  (e: 'set-suggestion-to-postponed', suggestion: any): void;
  (e: 'set-suggestion-to-rejected'): void;
  (e: 'set-suggestion-to-transfered', coach: any): void;
  (e: 'archived'): void;
  (e: 'unarchived'): void;
}>();

const toast = useToast();
const { t } = useI18n();

const modal = ref<string>();
const isModalOpen = computed(() => !!modal.value);

const isArchived = computed(() => !!props.suggestion.archived);

const isSuggestionNotTransfered = computed(
  () => props.suggestion.status !== suggestionsMacros.status.transfered.value,
);

function closeModal() {
  modal.value = undefined;
}

function handleDelete() {
  if (props.suggestion._coach) {
    modal.value = 'confirmDelete';
  }
}

async function later({ nbDays }: { nbDays: number }) {
  try {
    const { data: suggestion } = await suggestionsHttp.postponeSuggestion(
      props.suggestion._id,
      nbDays,
    );
    emit('set-suggestion-to-postponed', suggestion);

    toast.show({
      type: 'success',
      message: t('toast.reminder-configure-success'),
    });
  } catch (error) {
    toast.show({
      type: 'error',
      message: error.message,
    });
  }
}

async function reject() {
  try {
    await suggestionsHttp.rejectSuggestion(props.suggestion._id);

    emit('set-suggestion-to-rejected');

    if (modal.value) modal.value = undefined;

    toast.show({
      type: 'success',
      message: t('toast.suggestion-successfully-rejected'),
    });
  } catch (error) {
    toast.show({
      type: 'error',
      message: error.message,
    });
  }
}

async function transfer(coach) {
  try {
    await suggestionsHttp.transferSuggestion(props.suggestion._id, coach._id);

    emit('set-suggestion-to-transfered', coach);

    closeModal();

    toast.show({
      type: 'success',
      message: t('toast.suggestion-successfully-forwarded'),
    });
  } catch (error) {
    toast.show({
      type: 'error',
      message: error.message,
    });
  }
}

async function archive() {
  try {
    await suggestionsHttp.archiveSuggestion(props.suggestion._id);

    closeModal();

    toast.show({
      type: 'success',
      message: t('generics.archived-reminder'),
    });
    emit('archived');
  } catch (error) {
    toast.show({
      type: 'error',
      message: error.message,
    });
  }
}

async function unarchive({ nbDays }: { nbDays: number }) {
  try {
    await suggestionsHttp.unarchiveSuggestion(props.suggestion._id, nbDays);

    closeModal()

    toast.show({
      type: 'success',
      message: t('toast.reminder-configure-success'),
    });
    emit('unarchived');
  } catch (error) {
    toast.show({
      type: 'error',
      message: error.message,
    });
  }
}
</script>
