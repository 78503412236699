import { getCoderProfile } from '@/managers/coders/useCases/getCoderProfile';
import { getCoderActivity } from '@/managers/coders/useCases/getCoderActivity';
import { editCoderProfile } from '@/managers/coders/useCases/editCoderProfile';
import { createCoderProfile } from '@/managers/coders/useCases/createCoderProfile';

const getProfile = (id, context) => {
  return getCoderProfile(id, context);
};

const getActivity = id => {
  return getCoderActivity(id);
};

const editProfile = ({ id, data }) => {
  return editCoderProfile(id, data);
};

const createProfile = ({ data }) => {
  return createCoderProfile(data);
};

export const coderController = {
  getProfile,
  getActivity,
  editProfile,
  createProfile,
};

export default {
  coderController,
};
