<template>
  <div
    class="notifications-list-content-item"
    :class="{ 'notifications-list-content-item__unread': !mappedNofification.read }"
    @click="handleClick"
  >
    <div v-if="mappedNofification.icon" class="notifications-list-content-item__picture">
      <i :class="`icon-${mappedNofification.icon}`" class="text-xl text-blue-500"></i>
    </div>
    <bc-avatar
      v-else
      :placeholder="userInitials"
      class="notifications-list-content-item__picture"
      :src="mappedNofification.pictureUrl"
      @error="setAlternativeImg"
    ></bc-avatar>
    <div v-if="contentComponent" class="notifications-list-content-item__content">
      <component :is="contentComponent"></component>
    </div>
    <div class="notifications-list-content-item__date">
      {{ formatNotification.createdAt }}
    </div>
  </div>
</template>

<script>
import moment from 'moment/min/moment-with-locales';
import BcAvatar from '@/ui-kit/components/BcAvatar';
import notificationsTypes from './notificationsTypes';

export default {
  name: 'notifications-list-content-item',
  components: {
    BcAvatar,
  },
  props: {
    notification: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    notificationsTypes,
  }),
  computed: {
    formatNotification() {
      const notif = { ...this.notification };
      if (typeof notif.author === 'string') {
        notif.author = JSON.parse(notif.author);
      }
      if (notif.createdAt?.seconds) {
        notif.createdAt = moment(notif.createdAt.toDate()).fromNow(true).replace('minutes', 'min');
      }
      return notif;
    },
    mappedNofification() {
      const notif = this.formatNotification;
      const mappedNotification = notificationsTypes[notif.type];
      if (mappedNotification) {
        return {
          ...notif,
          ...mappedNotification.pictureUrl && { pictureUrl: mappedNotification.pictureUrl(notif) },
          ...mappedNotification.content && { content: mappedNotification.content(notif) },
          ...mappedNotification.icon && { icon: mappedNotification.icon(notif) },
        };
      }
      return notif;
    },
    contentComponent() {
      if (!this.mappedNofification.content) {
        return;
      }
      return {
        name: 'notifications-list-content-item-c',
        template: `<p>${this.mappedNofification.content}</p>`,
        props: this.$options.props,
      }
    },
    userInitials() {
      return `${this.formatNotification?.author?.firstName?.charAt(
        0
      )}${this.formatNotification?.author?.lastName?.charAt(0)}`;
    },
  },
  methods: {
    setAlternativeImg(event) {
      event.target.src = require('@/assets/img/default-avatar.svg');
    },
    handleClick() {
      this.$emit('click', this.notification);
    }
  }
}
</script>

<style lang="scss" scoped>
.notifications-list-content-item {
  align-items: center;
  gap: 16px;
  padding: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: $color-blue-dark-cello;

  &__picture {
    width: 42px;
    height: 42px;
    font-size: initial;
    border: 1px solid $color-blue-medium;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
  }

  &__content {
    width: 65%;
    flex-wrap: wrap;
  }

  &__date {
    flex: 1;
    align-self: baseline;
    justify-content: flex-end;
  }

  &__unread {
    background: #3c81f71f;
  }

  &:hover {
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.notif-ressource {
  color: #3C80F7;
}
</style>
