<template>
  <div class="panel-process-details-form-deal">
    <div class="is-row" v-if="formDealIsCompleted">
      <div class="icon-eye"></div>
      <p class="is-align-items-center is-row panel-process-details-form-deal__text">
        {{ $t('candidates.view-the') }}
        <router-link
          :to="{
            ...$route,
            query: {
              ...$route.query,
              'modal-type': 'modal-process-form-deal'
            }
          }"
          class="panel-process-details-form-deal__text--link">
          {{ $t('candidates.form-deal') }}
        </router-link>

        {{ $t('candidates.of') }} {{ process._coder.firstName }} {{ process._coder.lastName }}
      </p>
    </div>
    <div class="is-row" v-else>
      <div class="icon-alert-triangle"></div>
      <p class="is-align-items-center is-row panel-process-details-form-deal__text">
        {{ $t('candidates.you-have-the') }}

        <router-link
          :to="{
            ...$route,
            query: {
              ...$route.query,
              'modal-type': 'modal-process-form-deal'
            }
          }"
          class="panel-process-details-form-deal__text--link">
          {{ $t('candidates.form-deal') }}
        </router-link>

        {{ $t('candidates.of') }} {{ process._coder.firstName }} {{ process._coder.lastName }} {{ $t('candidates.to-finish') }}
      </p>
    </div>
    <modal
      @close-modal="closeModal"
      v-if="displayModal">
      <modal-process-form-deal
        @process-updated="updateProcess"
        :is-modal-open="displayModal"
        :process="process"
        @close-modal="closeModal">
      </modal-process-form-deal>
    </modal>
  </div>
</template>
<script>
  import ModalProcessFormDeal from "@/components/Modal/ModalProcess/ModalProcessFormDeal";
  import Modal from "@/components/Modal/Modal";

  export default {
    name: 'panel-process-details-form-deal',
    components: { Modal, ModalProcessFormDeal },
    props: {
      process: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      formDealIsCompleted() {
        return (this.process.formDeal || {}).sentAt || this.process.failedAt;
      },
      displayModal() {
        return !!this.$route.query['modal-type']
      }
    },
    methods: {
      updateProcess($event) {
        this.$emit('process-updated', $event);
      },
      closeModal() {
        this.$router.replace({
          query: {
            ...this.$route.query,
            'modal-type': undefined,
          },
        });
      },
    },
  };
</script>
<style lang=scss scoped>
  .panel-process-details-form-deal {
    &__text {
      color: $color-secondary;

      &--link {
        @include font-bold();
        color: $color-primary;
      }
    }
  }

  .icon-alert-triangle {
    margin-right: 5px;
    color: $color-warning;
  }

  .icon-eye {
    margin-right: 5px;
    color: $color-secondary;
  }
</style>
