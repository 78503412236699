<template>
  <panel-organization-configuration-item title="Multidiffusion en directe" contentStyle="padding: 0;">
    <div class="panel-organization-configuration-multicast">
      <panel-organization-multicast-item
        label="Site carrière"
        :logo="require('@/assets/logo/marvin_2.png')"
        :value="features.career"
        @input="handleInput($event, 'career')">
      </panel-organization-multicast-item>
      <hr class="hr" />
      <panel-organization-multicast-item
        label="Site web"
        logoClass="p-1"
        :logo="require('@/assets/logo/website.png')"
        :value="job_multiposting.website"
        @input="handleInput($event, 'website')">
      </panel-organization-multicast-item>
      <panel-organization-multicast-item
        label="WTTJ"
        :logo="require('@/assets/logo/wttj_logo.png')"
        :value="job_multiposting.wttj"
        @input="handleInput($event, 'wttj')"></panel-organization-multicast-item>
      <panel-organization-multicast-item
        label="Hellowork"
        :logo="require('@/assets/logo/jobboards/hellowork.png')"
        :value="job_multiposting.hellowork"
        @input="handleInput($event, 'hellowork')"
        @on-edit="onEdit('hellowork')"></panel-organization-multicast-item>
      <panel-organization-multicast-item
        label="Indeed"
        :logo="require('@/assets/logo/jobboards/indeed.png')"
        :value="job_multiposting.indeed"
        @input="handleInput($event, 'indeed')"
        @on-edit="onEdit('indeed')"></panel-organization-multicast-item>
    </div>

    <bc-modal
      :active="isModalOpen"
      @close="isModalOpen = false">
      <JobboardConfigChannel
        :channel="selectedJobboard"
        :config="config"
        @submit="onSubmitChannel"
        @cancel="isModalOpen = false"
      ></JobboardConfigChannel>
    </bc-modal>
  </panel-organization-configuration-item>
</template>

<script>
import { signIn } from '@/api/job-multipost';
import { updateConfigurationOrganizationByKey } from '@/api/organizations';
import BcModal from '@/ui-kit/components/BcModal/BcModal';
import PanelOrganizationConfigurationItem from './PanelOrganizationConfigurationItem';
import PanelOrganizationMulticastItem from './PanelOrganizationMulticastItem';
import JobboardConfigChannel from '@/components/Jobboard/JobboardConfigChannel/JobboardConfigChannel';

export default {
  name: 'panel-organization-configuration-multicast',
  components: {
    BcModal,
    PanelOrganizationConfigurationItem,
    PanelOrganizationMulticastItem,
    JobboardConfigChannel,
  },
  props: {
    profile: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    if (this.profile?.configuration?.features) {
      this.features = this.profile.configuration.features;
    }
    if (this.profile?.configuration?.integrations) {
      Object.keys(this.job_multiposting).forEach(key => {
        if (key in this.profile.configuration.integrations?.job_multiposting) {
          this.job_multiposting = {
            ...this.job_multiposting,
            [key]: this.profile.configuration.integrations.job_multiposting[key].activated,
          }
        }
      })
    }
  },
  data() {
    return {
      selectedJobboard: {
        connectorName: '',
        title: '',
      },
      isModalOpen: false,
      features: {
        career: false,
      },
      job_multiposting: {
        wttj: false,
        hellowork: false,
        indeed: false,
        website: false,
      },
    };
  },
  computed: {
    configurationIntegrations() {
      return this.profile?.configuration?.integrations || {};
    },
    config() {
      return this.configurationIntegrations?.job_multiposting?.[this.selectedJobboard.connectorName] || {}
    },
  },
  methods: {
    onEdit(jobboard) {
      this.isModalOpen = true;
      this.selectedJobboard = {
        connectorName: jobboard,
        title: `${jobboard[0].toUpperCase()}${jobboard.substring(1)}`
      };
    },
    async onSubmitChannel({ jobboard, params, credentials }) {
      const { data: config } = await signIn({
        jobboard,
        credentials,
        organizationId: this.profile._id,
        params,
      });

      this.$emit('profile-updated', {
        ...this.profile,
        configuration: {
          ...this.profile.configuration,
          integrations: {
            ...this.profile.configuration?.integrations || {},
            job_multiposting: {
              ...this.profile.configuration?.integrations?.job_multiposting || {},
              [jobboard]: config,
            }
          }
        }
      })
      this.isModalOpen = false;
    },
    async handleInput(value, key) {
      try {
        if (key === 'career') {
          const features = {
            ...this.features,
            [key]: value,
          };
          await this.updateFeaturesConfig(features);
          this.features = features;
          return;
        }
        await this.updateMultiposting(key, value);
        this.job_multiposting = {
          ...this.job_multiposting,
          [key]: value,
        };
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async updateFeaturesConfig(features) {
      try {
        await updateConfigurationOrganizationByKey({
          key: 'features',
          organizationId: this.profile._id,
          ...features,
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async updateMultiposting(key, value) {
      if (!this.profile?.configuration?.integrations) {
        throw new Error('Empty configuration, please retry');
      }

      try {
        await updateConfigurationOrganizationByKey({
          key: 'integrations',
          organizationId: this.profile._id,
          ...{
            ...this.profile.configuration.integrations,
            job_multiposting: {
              ...this.profile.configuration.integrations.job_multiposting,
              [key]: {
                ...this.profile.configuration.integrations.job_multiposting[key] || {},
                activated: value,
              },
            },
          },
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-organization-configuration-multicast {
  width: 100%;
  flex-direction: column;
  align-items: center;
}
</style>
