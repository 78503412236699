import { apiStatus } from '@/macros/processes/process';

export default {
  computed: {
    coderFullName() {
      return `${(this.process._coder || {}).firstName} ${(this.process._coder || {}).lastName}`;
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    moveProcessBack(process, fromStep) {
      // Get old process step name by process status
      const [oldProcessStep] = Object.entries(apiStatus).find(([, value]) =>  process.status === value);
      this.$emit('move-process', { process, fromStep, toStep: oldProcessStep });
    },
    moveProcessForward(process, fromStep, toStep) {
      this.$emit('move-process', { process, fromStep, toStep });
    },
  }
};
