import useCases from '@/managers/contracts/useCases';

const getCompanyContracts = id => {
  return useCases.getCompanyContracts(id);
};

const createCompanyContract = ({ id, type, file }) => {
  return useCases.createCompanyContract({ id, type, file });
};

const removeCompanyContract = id => {
  return useCases.removeCompanyContract(id);
};

export default {
  getCompanyContracts,
  createCompanyContract,
  removeCompanyContract,
};
