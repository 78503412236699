import { validateType } from '@/managers/notes/validators/validateType';
import { serializeNote } from '@/managers/notes/formatters/noteSerializers';
import timeline from '@/api/timeline';
import { validateMedia } from '@/managers/notes/validators/validateMedia';

export const createSentMessage = async({ userType, id, note, mentions, images }) => {
  const validate = note => {
    try {
      validateType(note.type);
      validateMedia(note.media);
    } catch (error) {
      throw error;
    }
  };

  const execute = ({ userType, id, note, mentions, images }) => {
    try {
      return timeline.createNote({
        userType,
        id,
        note: {
          type: note.type,
          media: note.media,
          content: (note.content || '').trim() ? note.content : 'Message laissé',
          ...note.hasOwnProperty('dateToContact') && { dateToContact: note.dateToContact },
          ...note.hasOwnProperty('reminderType') && { reminderType: note.reminderType },
          mentions,
          images,
        },
      });
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    validate(note);

    const serializedNote = serializeNote(note);

    const { data } = await execute({
      userType,
      id,
      note: serializedNote,
      mentions,
      images,
    });

    return data;
  } catch (error) {
    throw error;
  }
};
