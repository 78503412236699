import { timelinePartners } from '@/common-old/macros/timeline/timelinePartners';
import { timelineCoaches } from '@/common-old/macros/timeline/timelineCoaches';
import { timelineCoders } from '@/common-old/macros/timeline/timelineCoders';

export const timeline = {
  /**
   * Action type 0 is for the type add an action
   */
  0: {
    description: 'Someone wants to add a note',
    icon: 'plus',
    author: {
      type: 'user',
      after: 'companies.panel-company.add-a-note',
    },
  },
  ...timelinePartners,
  ...timelineCoaches,
  ...timelineCoders,
};

export const timelineType = {
  failedProcess: 47,
  unfailedProcess: 48,
  addNote: 0,
  processValidated: 61,
  coachCreateProcess: 45,
  coderVisitedBluecoders: 216,
  contactVisitedBluecoders: 134,
  coachCreateJob: 11,
  hookedCoder: 65,
  coachChangedCompanyPartnership: 34,
  marvinBotAction: 66,
  suggestionCreate: 79,
  contactCalendly: 135,
  coderCompletedSubscriptionTypeform: 223,
  contactCompletedSubscriptionTypeform: 137,
  processCustomCreate: 90,
  processCustomStep: 91,
  processCustomAborted: 92,
  processCustomUnaborted: 93,
  messagingGmail: 301,
  messagingOutlook: 302,
  deleteNote: 86,
  reminderSet: 99,
  emailSentFromMarvin: 1000,
};

export const processActions = [54, 55, 57, 61];
export const processCustomActions = [timelineType.processCustomCreate, timelineType.processCustomStep, timelineType.processCustomAborted, timelineType.processCustomUnaborted];

export const processSteps = [47, 48, 49, 50, 51, 52, 53, 56, 60];
export const processCustomSteps = [timelineType.processCustomStep];
export const processCustomAborted = [timelineType.processCustomAborted];

export const messagingActions = {
  [timelineType.messagingGmail]: 'gmail',
  [timelineType.messagingOutlook]: 'outlook',
};

export default timeline;
