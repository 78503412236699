<template>
  <modal-feature
    feature="multidiffusion"
    :title="$t('features.multipost-title')"
    @close-modal="$emit('close-modal')">
    <template #content>
      <p>{{ $t('features.multipost-content') }}</p>
    </template>
    <template #logo>
      <img src="@/assets/logo/feature-multipost.png" alt="multi post feature logo" />
    </template>
  </modal-feature>
</template>

<script>
import ModalFeature from './ModalFeature.vue';

export default {
  name: 'modal-feature-multipost',
  components: {
    ModalFeature,
  },
};
</script>
