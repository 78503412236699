<template>
  <div class="panel-process">
    <div class="panel-process__wrapper">
      <div class="panel-process__body">
        <process-column
          v-for="(step, stepName, index) in steps"
          :key="index"
          :card-type="resource.card"
          :is-dragging="isDragging"
          :read-only="getProcessColumnDisabledStatus(stepName)"
          :step="step"
          :step-name="stepName"
          :style="{ color: step.color }"
          class="panel-process__step"
          @dragging="isDragging = $event"
          @input="updateProcesses"
          @card-dragged="getEditableProcessColumn"
          @move-process="moveProcess"
          @process-update="updateProcess"
          @profile-updated="$emit('profile-updated', $event)"
          @set-modal-type="setModalType($event)">
          <bc-button
            v-if="index === 0 && ($route.query.type === 'coders' || $route.query.type === 'suggestions-coders')"
            class="panel-process__button"
            color="secondary"
            icon-left="plus-circle"
            type="outlined"
            @click="createProcess">
            {{ $t('hunt.add-a-process') }}
          </bc-button>
        </process-column>
        <component
          :is="modalType"
          :is-modal-open="isModalOpen"
          :process="modalProcess"
          class="panel-process__modal"
          @close-modal="closeModal"
          @move-process="moveProcessCard"
          @process-update="updateProcess"
          @profile-updated="$emit('profile-updated', $event)">
        </component>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  //
  import MyOldProcessesMixin from '@/mixins/FavoriteLists/myOldProcesses';
  //
  import ProcessColumn from '@/components/Process/ProcessColumn';
  import BcButton from '@/ui-kit/components/BcButton';
  // Steps Modals
  import ModalProcessNewOffer from '@/components/Modal/ModalProcess/ModalProcessNewOffer';
  import ModalProcessDeleteConfirmation
    from '@/components/Modal/ModalProcess/ModalProcessDeleteConfirmation';
  import ModalProcessStartValidation
    from '@/components/Modal/ModalProcess/ModalProcessStartValidation';
  import ModalProcessOnboardingValidation
    from '@/components/Modal/ModalProcess/ModalProcessOnboardingValidation';
  // Footers

  import moment from 'moment/min/moment-with-locales';
  import { processController } from '@/managers/processes/controller';
  import { processStatus } from '@/macros/processes/process';
  import { bluecodersCompanyId } from '@/macros/utils';
  import steps from '@/mixins/Processes/steps';
  import ModalProcessFormDeal from "@/components/Modal/ModalProcess/ModalProcessFormDeal";

  export default {
    name: 'panel-process',
    components: {
      ModalProcessOnboardingValidation,
      ModalProcessStartValidation,
      ModalProcessDeleteConfirmation,
      ModalProcessNewOffer,
      BcButton,
      ProcessColumn,
      ModalProcessFormDeal
    },
    mixins: [steps, MyOldProcessesMixin],
    props: {
      profile: {
        default: () => ({}),
        type: Object,
      },
    },
    data() {
      return {
        isDragging: false,
        isDraggingForbidden: false,
        isModalOpen: false,
        modalType: '',
        modalProcess: {},
        resources: {
          coders: {
            card: 'process-card-coders',
            type: 'coders',
            footer: 'panel-coder-process-footer',
          },
          jobs: {
            card: 'process-card-job',
            type: 'jobs',
            footer: 'panel-job-process-footer',
          },
          hunt: {
            card: 'process-card-hunt',
            type: 'hunt',
            footer: 'panel-hunt-process-footer',
          },
          companies: {
            card: 'process-card-companies',
            type: 'partners',
            footer: 'panel-company-process-footer',
          },
          'suggestions-coders': {
            card: 'process-card-coders',
            type: 'coders',
            footer: 'panel-coder-process-footer',
          },
        },
      };
    },
    computed: {
      ...mapGetters([
        'cardActive',
        'myOldProcesses',
      ]),
      resource() {
        return this.resources[this.$route.query.type];
      },
      options() {
        return {
          ...this.$route.query['process-starts'] && { from: moment(this.$route.query['process-starts'], 'D').startOf('day').toISOString() },
          ...this.$route.query['process-ends'] && { to: moment(this.$route.query['process-ends'], 'D').endOf('day').toISOString() },
          ...this.$route.query['selected-coaches'] && { coachIds: this.$route.query['selected-coaches'].join(',') },
          ...this.$route.query['selected-jobs'] && { jobIds: this.$route.query['selected-jobs'].join(',') },
        };
      },
      formDealToDo() {
        return this.steps['dealt'].processes.filter(process => !(process.formDeal || {}).sentAt && !process.failedAt && process?._company?._id !== bluecodersCompanyId);
      },
    },
    methods: {
      ...mapActions([
        'updatePanelProfile',
        'updateCard',
        'unshiftCard',
        'removeCard',
      ]),
      getProcesses() {
        const steps = Object.entries(this.steps);

        steps.forEach(([stepName, step]) => {
          this.getProcessesByColumn(stepName, step);
        });
      },
      async getProcessesByColumn(stepName, step) {
        try {
          const options = {
            resource: this.resource.type,
            id: this.profile._id || this.$route.params.id,
            steps: step.query,
            ...this.options,
          };

          const { data } = await processController.get(options);

          this.steps[stepName].processes = data;
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      getStepByQuery(query) {
        const [stepName] = Object.entries(this.steps).find(([, values]) => {
          return values.query.includes(query);
        });

        return this.steps[stepName];
      },
      getEditableProcessColumn(process) {
        if (this.isDragging && process?.item?.className?.includes('forbidden')) {
          this.isDraggingForbidden = true;
        }
      },
      getProcessColumnDisabledStatus(columnName) {
        return columnName !== 'introduced' && columnName !== 'processed' && this.isDraggingForbidden;
      },

      createProcess() {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            type: 'create-process',
            from: this.$route.query.type,
          },
          ...this.$route.query.type === 'suggestions-coders' && {
            params: {
              id: this.profile?._id,
            },
          },
        });
      },

      updateProcesses({ stepName, processes }) {
        this.steps[stepName].processes = processes;
      },
      updateProcess({ updatedProcess, stepName: queryName }) {
        if (queryName === processStatus.onboarding) {
          this.getProcesses();
        }

        // Get step by query
        const step = this.getStepByQuery(queryName);

        // Get process by id in step processes
        const processIndex = step.processes.findIndex(process => process._id === updatedProcess._id);

        this.$set(step.processes, processIndex, updatedProcess);

        this.updatePanelProfile({
          processState: updatedProcess._coder.processState,
          coachProcessState: updatedProcess._coder.coachProcessState,
        });
        this.$emit('profile-updated', {
          processState: updatedProcess._coder.processState,
          coachProcessState: updatedProcess._coder.coachProcessState,
        });
        this.$_manageCoderInMyOldProcessesList(updatedProcess);

        if (this.cardActive >= 0) {
          this.updateCard({ index: this.cardActive, content: updatedProcess._coder });
        }
      },

      async moveProcess({ processId, stepName }) {
        try {
          const updatedProcess = await processController.move(processId, stepName);

          this.updateProcess({ updatedProcess, stepName });
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      moveProcessCard({ process, fromStep, toStep }) {
        const fromStepProcesses = this.getStepByQuery(fromStep).processes;
        const toStepProcesses = this.getStepByQuery(toStep).processes;

        const alreadyMoved = toStepProcesses.find(p => p._id === process._id);

        if (!alreadyMoved) {
          const processIndex = fromStepProcesses.findIndex(p => p._id === process._id);

          fromStepProcesses.splice(processIndex, 1);
          toStepProcesses.unshift(process);
        }
      },

      // MODAL
      setModalType(event) {
        if (!(event.type === 'modal-process-form-deal' && event.process._company._id === bluecodersCompanyId)) {
          this.isModalOpen = true;
          this.modalProcess = event.process;
          this.modalType = event.type;
          this.$router.replace({
            query: {
              ...this.$route.query,
              typing: true,
            },
          });
        }
      },
      closeModal() {
        this.isModalOpen = false;
        this.$router.replace({
          query: {
            ...this.$route.query,
            typing: undefined,
          },
        });
      },
    },
    watch: {
      'profile._id': {
        handler() {
          this.getProcesses();
        },
        immediate: true,
      },
      '$route.query': {
        handler(to, from = {}) {
          const isDifferentProcessStarts = to['process-starts'] !== from['process-starts'];
          const isDifferentProcessEnds = to['process-ends'] !== from['process-ends'];
          const isDifferentSelectedCoaches = to['selected-coaches'] !== from['selected-coaches'];

          if (isDifferentProcessEnds || isDifferentProcessStarts || isDifferentSelectedCoaches) {
            this.getProcesses();
          }
        },
        deep: true,
      },
      isDragging() {
        if (!this.isDragging && this.isDraggingForbidden) {
          this.isDraggingForbidden = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  $border: 1px solid rgba($color-primary, 0.3);

  .panel-process {
    width: 100%;
    padding: 10px;

    &__wrapper {
      flex-direction: column;
      border: $border;
      width: 100%;
      border-radius: $border-radius-s;
      height: 100%;
      overflow: auto;
    }

    &__body {
      overflow: auto;
      height: 100%;
      border-bottom: $border;
    }

    &__step {
      width: 100%;
      border-right: $border;

      &:last-of-type {
        border-right: 0;
      }
    }

    &__button {
      background-color: transparent;
      width: 170px;
      margin: 10px;
    }

    &__modal {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
</style>
