<template>
  <panel-organization-configuration-item
    title="Multidiffusion Xtramile"
    class="panel-organization-configuration-xtramile__item">
    <div class="panel-organization-configuration-xtramile__container">
      <div class="panel-organization-configuration-xtramile__credit-form">
        <div class="panel-organization-configuration-xtramile__officeid">
          <bc-input
            name="officeid"
            class="mt-3 is-full-width"
            label="Xtramile office_id"
            :labelPlaceholder="false"
            v-model="officeId" />
          <bc-button
            class="panel-organization-configuration-xtramile__btn mt-3"
            icon-center="check"
            color="success"
            @click="checkOfficeId"></bc-button>
        </div>
        <div class="panel-organization-configuration-xtramile__credit">
          <bc-input
            name="credits"
            class="panel-organization-configuration-xtramile__credit--input mt-0"
            :value="credits"
            @input="assignCredits" />
          <p>Crédits attribués par mois</p>
        </div>
      </div>
      <div class="panel-organization-configuration-xtramile__credit-count">
        <div>
          <div class="panel-organization-configuration-xtramile__icon-container">
            <i class="icon-credit-card"></i>
          </div>
          <p>
            <b>{{ remainingCredits }} crédits</b> restants pour le mois en cours
          </p>
        </div>
        <div>
          <div class="panel-organization-configuration-xtramile__icon-container">
            <i class="icon-credit-card"></i>
          </div>
          <p class="panel-organization-configuration-xtramile__primary">
            Il reste <b>{{ remainginMarvinCredits }} crédits</b> Marvin non attribués
          </p>
        </div>
      </div>
      <div class="panel-organization-configuration-xtramile__divider"></div>
      <div class="panel-organization-configuration-xtramile__jobboard-list">
        <p class="px-4">Liste des jobboards</p>
        <JobboardConfigList class="mt-5" :profile="profile" @profile-updated="$emit('profile-updated', $event)"></JobboardConfigList>
      </div>
    </div>
  </panel-organization-configuration-item>
</template>

<script>
import debounce from 'debounce';
import xtramile from '@/api/xtramile';
import { mask } from '@/helpers/strings';

import BcInput from '@/ui-kit/components/BcInput/BcInput';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import PanelOrganizationConfigurationItem from './PanelOrganizationConfigurationItem';
import JobboardConfigList from '@/components/Jobboard/JobboardConfig/JobboardConfigList';

export default {
  name: 'panel-organization-configuration-xtramile',
  components: {
    BcInput,
    BcButton,
    PanelOrganizationConfigurationItem,
    JobboardConfigList,
  },
  props: {
    profile: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      officeId: '',
      enabled: false,
      credits: 0,
      remainingCredits: 0,
      remainginMarvinCredits: 0,
    };
  },
  mounted() {
    if (this.configurationIntegrations?.xtramile) {
      this.officeId = this.configurationIntegrations.xtramile?.credentials?.officeId || '';
      this.enabled = this.configurationIntegrations.xtramile?.enabled || false;
      this.credits = this.configurationIntegrations.xtramile?.credits || 0;
    }
    this.assignCredits(this.credits)
  },
  computed: {
    configurationIntegrations() {
      return this.profile?.configuration?.integrations || {};
    },
  },
  methods: {
    updateOfficeId(value) {
      this.officeId = value;
      this.maskedOfficeId = mask(value);
    },
    assignCredits: debounce(async function(credits) {
      try {
        const { data } = await xtramile.assignCredits({ credits, organizationId: this.profile._id });
        this.credits = credits;
        this.remainingCredits = data.remainingCredits;
        this.remainginMarvinCredits = data.remainginMarvinCredits;
      } catch (error) {
        this.$toast.show({
          type: 'error',
          message: error.response?.data?.message || error.message,
        });
      }
    }, 1000),
    async checkOfficeId() {
      try {
        const { data } = await xtramile.checkOfficeId({ officeId: this.officeId, organizationId: this.profile._id });
        if (data.xtramileConfig?.enabled) {
          this.enabled = true;
        }
        this.$toast.show({
          type: 'success',
          message: 'Office id ajouté avec succès',
        });
      } catch (error) {
        this.$toast.show({
          type: 'error',
          message: error.response?.data?.message || error.message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-organization-configuration-xtramile {
  &__container {
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }

  &__officeid {
    width: 100%;
    align-items: center;
    gap: 10px;
  }

  &__credit-form {
    flex-direction: column;
    gap: 15px;
    padding: 22px 12px;
  }

  &__credit-count {
    padding: 10px 20px;
    background: #ecf2fd;
    border-radius: 5px;
    width: 100%;
    gap: 20px;

    & > div:first-child {
      border-right: 1px solid $color-blue-medium;
    }

    & > div {
      gap: 10px;
      flex: 1;

      & > p {
        width: 170px;
      }
    }
  }

  &__credit {
    gap: 20px;
    align-items: center;
  }

  &__btn {
    width: 36px;
    height: 36px;
  }

  &__item {
    &:deep() {
      .panel-organization-configuration-item__content {
        padding: 0;
      }
    }
  }

  &__icon-container {
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 5px #e4ecfc;
    align-items: center;
    justify-content: center;
  }

  &__divider {
    width: 90%;
    height: 1px;
    background: $color-blue-medium;
    align-self: center;
    margin: 10px 0;
  }

  &__jobboard-list {
    flex-direction: column;
  }

  &__primary {
    color: $color-primary;
  }

  & p {
    color: $color-blue-dark-cello;
  }
}
</style>
