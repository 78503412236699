<template>
  <views class="database-logs-extension">
    <admin-tabs></admin-tabs>
    <router-view></router-view>
    <views-container>
      <div class="flex w-full flex-col gap-2.5">
        <bc-button
          class="flex"
          @click="increaseExtensionVersion">
          Mettre à jour une nouvelle version
        </bc-button>
        <div class="flex w-full flex-col gap-1">
          <div
            v-for="item in items"
            :key="item._id"
            class="justify-between rounded-md border border-blue-100 px-5 py-2">
            <p><b>Id</b> : {{ item._id }}</p>
            <p><b>Date</b> : {{ getDate(item.createdAt) }}</p>
          </div>
        </div>
      </div>
    </views-container>
  </views>
</template>

<script lang="ts" setup>
  import Views from '@/components/Views/Views';
  import ViewsContainer from '@/components/Views/ViewsContainer';
  import AdminTabs from '@/views/Tabs/AdminTabs';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';

  import { onMounted, ref } from 'vue';

  import { getExtensions, upgradeExtensionVersion } from '@/api/extension.js';
  import { onBeforeRouteUpdate } from 'vue-router/composables';
  import { useToast } from '@/ui-kit/components/BcToast';

  import { format, parseISO } from 'date-fns';


  onBeforeRouteUpdate((to, from, next) => {
    next(vm => {
      if (vm.$store.getters['user/isMarvinRecruiter']) {
        next();
      } else {
        next('/');
      }
    });
  });

  const toast = useToast();

  const items = ref<{ _id: string, createdAt: string }[]>([]);

  const increaseExtensionVersion = async() => {
    try {
      const { data } = await upgradeExtensionVersion();

      items.value = [data, ...items.value];

      toast.show({
        type: 'success',
        message: 'La version de l\'extension a été mise à jour avec succès',
      });
    } catch (error) {
      toast.show({
        type: 'error',
        message: error?.response?.data?.message,
      });
    }
  };

  const fetchExtensions = async() => {
    try {
      const { data } = await getExtensions();
      items.value = data;
    } catch (error) {
      toast.show({
        type: 'error',
        message: error?.response?.data?.message,
      });
    }
  };

  const getDate = (date: string) => {
    return format(parseISO(date), 'dd/MM/yyyy HH:mm');
  };

  onMounted(() => {
    fetchExtensions();
  });
</script>

<style lang="scss" scoped>

  .database-logs-extension {
    @include page_grid();

    &__cards {
      flex-wrap: wrap;
      flex-direction: column;
    }

    &__filters {
      margin: 20px 0px 20px;
      align-self: flex-start;
      justify-content: left;

      &-label {
        align-items: center;
        padding: 0 10px 0 20px;
      }
    }

    &__filters-actions {
      margin: 0px 0px 20px;
      align-self: flex-start;
      justify-content: left;

      &-label {
        align-items: center;
        padding: 0 10px 0 20px;
      }
    }

    &__input {
      margin: 0 10px;
    }

    &__dropdown {
      margin: 0 10px;
    }

    &__button {
      flex-shrink: 0;
    }

    &__pagination {
      margin: 20px 0;
      justify-content: right;
    }

    &__spinner {
      margin: 100px 0;
      justify-content: center;
    }

    .ml10 {
      margin-left: 10px;
    }
  }

  .bc-dropdown {
    min-width: 150px;
    justify-content: center;
    margin: 0 5px;
  }
</style>
