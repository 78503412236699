<script lang="ts" setup>
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
  import { computed } from 'vue';
  import { setAlternativeImg } from '@/mixins/imgFallback';
  import { getPersonInitials } from '@/mixins/getInitials';
  import { useI18n } from '@/i18n/i18n';

  type TemplateProps = {
    name: string;
    _id: string;
    body: string;
    author: {
      _id: string;
      firstName: string;
      lastName: string;
      pictureUrl: string;
    };
    activated?: boolean;
  }

  type TemplateFormatted =
    Omit<TemplateProps, '_id'>
    & {
    value: TemplateProps['_id']
  };

  type Props = {
    templates: TemplateProps[];
    userId: string;
    isMinified: boolean;
  }

  const { t } = useI18n();

  const props = defineProps<Props>();
  const emits = defineEmits<{
    (e: 'template-selected', value: TemplateFormatted): void,
  }>();

  const templatesOptions = computed<TemplateFormatted[]>(() => {
    const templates = props.templates.reduce((acc: {
      userCustomTemplates: TemplateFormatted[],
      othersCustomTemplates: TemplateFormatted[]
    }, template: TemplateProps) => {
      if (template.activated) {
        const t: TemplateFormatted = {
          name: template.name,
          value: template._id,
          body: template.body,
          author: template.author,
        };
        if (template.author._id === props.userId) {
          acc.userCustomTemplates.push(t);
        } else {
          acc.othersCustomTemplates.push(t);
        }
      }
      return acc;
    }, { userCustomTemplates: [], othersCustomTemplates: [] });

    return [
      ...templates.userCustomTemplates,
      ...templates.othersCustomTemplates.sort((a, b) => a.author?.lastName?.localeCompare(b.author?.lastName)),
    ];
  });

  const onSelectTemplate = (option: TemplateFormatted) => {
    emits('template-selected', option);
  };

  const isDisabled = computed<boolean>(() => !templatesOptions.value.length);
  const tooltip = computed(() => ({
    content: isDisabled.value ? `<p class="flex shrink-0">${t('templates.no-templates-has-been-configured')}</p>` : '',
  }));
</script>

<template>
  <bc-dropdown
    :disabled="isDisabled"
    :is-scrollable="true"
    :options="templatesOptions"
    class="dropdown-templates">
    <template #label>
      <div
        v-tooltip="tooltip">
        <div
          :class="[
           { 'pointer-events-none cursor-default opacity-50': isDisabled }
         ]">
          <button
            :class="[
               'relative',
               'flex cursor-pointer select-none items-center justify-center gap-2 overflow-hidden',
               'focus:border focus:border-blue-200 focus:from-[#FFF] focus:to-[#FFF] focus:text-blue-500',
               'via-51% rounded-3xl bg-gradient-to-r from-[#4A3AFF] from-10% via-[#3865FD] to-[#4793F0] to-100% fill-white text-white hover:from-[#4A3AFF] hover:to-[#4A3AFF]',
               'border border-transparent border-l-[#4A3AFF] border-r-[#4793F0] hover:border-x-[#4A3AFF]',
               'rounded-2xl',
               'h-8',
               { 'px-5': !isMinified },
               { 'size-8': isMinified },
            ]"
            :disabled="isDisabled">
            <i class="icon-file text-xs"></i>
            <p
              v-show="!isMinified"
              class="text-sm"> {{ $t('generics.select-a-template') }}</p>
          </button>
        </div>
      </div>
    </template>
    <template
      #option="{ activeIndex, index, option }">
      <bc-dropdown-item
        :is-focus="activeIndex === index"
        class="relative flex w-full"
        @click.native="onSelectTemplate(option)">
        <div class="flex w-full min-w-[180px]">
          <p class="w-full truncate">{{ option.name }}</p>
          <bc-avatar
            v-tooltip="{ trigger: 'hover', content: `${$t('generics.created-by')} ${option?.author?.firstName} ${option?.author?.lastName}` }"
            :placeholder="getPersonInitials(option.author)"
            :src="option?.author?.pictureUrl"
            class="ml-3 shrink-0"
            size="xs"
            @error="setAlternativeImg({event: $event})"/>
        </div>
      </bc-dropdown-item>
    </template>
  </bc-dropdown>
</template>

<style lang="scss" scoped>
  .dropdown-templates {
    &:deep(.mv-select__as-btn) {
      max-height: 32px;
    }

    &:deep(.bc-dropdown-item__text) {
      width: 100%;
    }
  }
</style>
