<template>
  <statistics-item
    class="statistics-processes"
    :title="$t('dashboard.candidates-in-process')"
    :steps="steps"
    :is-loading="isLoading">
  </statistics-item>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getDashboardCandidatesProcesses,
  getTopPerformersCandidatesProcesses,
} from '@/api/dashboard';

import StatisticsItem from './StatisticsItem';

export default {
  name: 'statistics-processes',
  components: {
    StatisticsItem,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      isLoading: false,
      steps: [],
      categoriesIcons: {
        0: 'check',
        1: 'arrow-dot-down',
        2: 'partners',
      },
    };
  },
  computed: {
    ...mapGetters({
      configurationProcess: 'user/configurationProcess',
    }),
    profile() {
      return this.$store.state.user.profile;
    },
    isSuperUser() {
      return this.$store.getters['user/isSuperUser'];
    },
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      const [candidates, topPerformers] = await Promise.all([
        this.getCandidatesProcesses(),
        this.getTopPerformers(),
      ])

      this.steps = candidates.map((candidate, index) => ({
        ...candidate,
        topPerformers: topPerformers[index].topPerformers,
      }))

      this.isLoading = false;
    },
    async getCandidatesProcesses() {
      const start = this.$route.query.activityStart;
      const end = this.$route.query.activityEnd;
      const coachId = this.$route.query.activityCoachId;
      const { data } = await getDashboardCandidatesProcesses({ start, end, coachId });
      const mappedData = data.map(d => ({ ...d, name: d.stepName, icon: this.getCategoryIcon(d.categoryKey) }));
      return mappedData;
    },
    async getTopPerformers() {
      const start = this.$route.query.activityStart;
      const end = this.$route.query.activityEnd;
      const coachId = this.$route.query.activityCoachId;
      const { data } = await getTopPerformersCandidatesProcesses({ start, end, coachId });
      return data;
    },
    getCategoryIcon(categoryKey) {
      const categoryIndex = this.configurationProcess?.categories?.findIndex(
        category => category.key === categoryKey
      );
      return this.categoriesIcons[categoryIndex] || '';
    },
    getHtml(topPerformers) {
      if (!topPerformers.length) return '';

      return `
          <ul style="display: flex; flex-direction: column; text-align: left; list-style-type: none;">
            ${topPerformers
              .map(
                (rank, index) =>
                  `<li style="text-align: left;">
                ${index + 1}. ${rank.fullName} (<span>${rank.score}</span
                >)
              </li>`
              )
              .join('')}
          </ul>
        `;
    },
    getFullNameInitial(fullName) {
      return fullName
        .split(' ')
        .map(name => name[0])
        .join('');
    },
    scrollTo(direction) {
      const el = this.$refs['kpi-processes-dashboard'];
      const value = direction === 'left' ? -150 : 150;
      const scrollLeft = el.scrollLeft + value;

      this.$refs['kpi-processes-dashboard'].scrollTo({
        top: 0,
        left: scrollLeft,
        behavior: 'smooth',
      });
    },
  },
  watch: {
    '$route.query.activityCoachId'() {
      this.loadData();
    },
    '$route.query.activityStart'() {
      this.loadData();
    },
    '$route.query.activityEnd'() {
      this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-processes {
}
</style>
