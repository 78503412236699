<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
  import { useI18n } from '@/i18n/i18n';
  import MvFilter from '@/ui-kit/components/MvFilter/MvFilter.vue';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { CampaignStatusKey } from '@/domains/models/Campaign';
  import { capitalize } from '@/utils/stringTransform';

  type CampaignsStatusValue =
    'ALL' | CampaignStatusKey

  type StatusSuggestions = {
    value: CampaignsStatusValue;
    label: string | (() => string);
    textColor: string
    backgroundColor: string
  };

  const { t } = useI18n();
  const router = useRouter();
  const route = useRoute();

  const suggestions = ref<StatusSuggestions[]>([
    {
      value: 'ALL',
      label: capitalize(t('campaigns.status.all')),
      textColor: '#1D385D',
      backgroundColor: '#FFFFFF',
    },
    {
      value: 'DRAFT',
      label: capitalize(t('campaigns.status.draft')),
      textColor: '#1D385D',
      backgroundColor: '#FFFFFF',
    },
    {
      value: 'ONGOING',
      label: capitalize(t('campaigns.status.pending')),
      textColor: '#3865FD',
      backgroundColor: '#EDF0FF',
    },
    {
      value: 'FINISHED',
      label: capitalize(t('campaigns.status.done')),
      textColor: '#3DCF8E',
      backgroundColor: '#DDF4E9',
    },
    {
      value: 'PAUSED',
      label: capitalize(t('campaigns.status.paused')),
      textColor: '#F78E00',
      backgroundColor: '#FBEEDB',
    },
    {
      value: 'ARCHIVED',
      label: capitalize(t('campaigns.status.archived')),
      textColor: '#1D385D',
      backgroundColor: '#E8EBEE',
    },
    {
      value: 'FAILED',
      label: capitalize(t('campaigns.status.failed')),
      textColor: '#1D385D',
      backgroundColor: '#E8EBEE',
    },
    {
      value: 'SCHEDULED',
      label: capitalize(t('campaigns.status.scheduled')),
      textColor: '#1D385D',
      backgroundColor: '#E8EBEE',
    },
  ]);

  const selectedType = computed<StatusSuggestions>(() => suggestions.value.find(suggestion => suggestion.value === route.query.type) ?? suggestions.value[0]);
  const selectedTypeLabel = computed<string | (() => string)>(() => selectedType.value.label);
  const selectedTypeBackgroundColor = computed<string>(() => selectedType.value.backgroundColor);
  const selectedTypeTextColor = computed<string>(() => selectedType.value.textColor);

  function selectStatus(status: CampaignsStatusValue) {
    router.push({
      ...route,
      query: {
        ...route.query,
        'campaigns-status': status === 'ALL' ? undefined : status,
      },
    });
  }
</script>

<template>
  <MvFilter
    :label="selectedTypeLabel"
    :style="{
      ...selectedType && { background: selectedType.backgroundColor }
    }"
    align="right"
    class="campaign-filter-status shrink-0 rounded-[5px]"
  >
    <div class="flex flex-col overflow-hidden rounded border border-blue-100">
      <BcDropdownItem
        v-for="suggestion in suggestions"
        :key="suggestion.value"
        class="is-expanded cursor-pointer border-b border-blue-100 last:border-0"
        @click.native="selectStatus(suggestion.value)">
        <p
          :style="{
            color: suggestion.textColor
          }"
          class="first-letter:capitalize">
          {{ suggestion.label }}
        </p>
      </BcDropdownItem>
    </div>
  </MvFilter>
</template>

<style lang="scss" scoped>
  .campaign-filter-status {
    &:deep() {
      button {
        background: v-bind('selectedTypeBackgroundColor');
        color: v-bind('selectedTypeTextColor');
      }
    }
  }
</style>
