<script lang="ts" setup>
  import { computed, defineEmits, defineProps, ref } from 'vue';
  import { Opportunity } from '@/domains/models/Opportunity';
  import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
  import PanelCommonDescriptionView
    from '@/components/Panel/PanelCommon/PanelCommonDescription/PanelCommonDescriptionView.vue';
  import PanelCommonDescriptionEdit
    from '@/components/Panel/PanelCommon/PanelCommonDescription/PanelCommonDescriptionEdit.vue';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { descriptionTypes, updateCustomJob } from '@/api/custom-jobs';
  import companyApi from '@/api/company';
  import { useStore } from '@/store';
  import MvSelect from '@/ui-kit/components/MvSelect/MvSelect.vue';
  import { useI18n } from '@/i18n/i18n';

  interface Props {
    opportunity: Opportunity;
    isEditing: boolean;
  }

  const props = defineProps<Props>();
  const emits = defineEmits<{
    (e: 'update-opportunity', value: Partial<Opportunity>): void,
    (e: 'close-edit'): void
    (e: 'save-text', value: string): void
  }>();

  const toast = useToast();
  const store = useStore();
  const i18n = useI18n();

  const confirmUpdate = ref({ type: '', warning: '', description: '' });
  const descriptionType = ref(props.opportunity?.descriptionType || descriptionTypes.organization);
  const handleSelectCompanyDescription = (value: string) => {
    descriptionType.value = value;
  };

  const companyDescription = computed(() => {
    if (descriptionType.value === descriptionTypes.client) {
      return props.opportunity?._company.description;
    } else if (descriptionType.value === descriptionTypes.custom) {
      return props.opportunity?.customCompanyDescription;
    }
    return props.opportunity?._organization?.description;
  });

  const onCloseEdit = () => {
    if (confirmUpdate.value.type) {
      return;
    }
    emits('close-edit');
  };

  const resetConfirmUpdate = () => {
    confirmUpdate.value.type = '';
    confirmUpdate.value.warning = '';
    confirmUpdate.value.description = '';
  };

  const onConfirmUpdate = async() => {
    if (confirmUpdate.value.type === descriptionTypes.client) {
      await updateClientDescription(confirmUpdate.value.description);
    }
    if (confirmUpdate.value.type === descriptionTypes.organization) {
      await updateOrganizationDescription(confirmUpdate.value.description);
    }
    resetConfirmUpdate();
    onCloseEdit();
  };

  const updateClientDescription = async(description: string) => {
    await updateCustomJob({
      id: props.opportunity._id,
      descriptionType: descriptionTypes.client,
    });
    await companyApi.updatePartner({
      id: props.opportunity?._company?._id,
      obj: {
        description,
      },
    });
    emits('update-opportunity', {
      _company: {
        ...props.opportunity._company,
        description,
      },
    });
  };

  const updateOrganizationDescription = async(description: string) => {
    await updateCustomJob({
      id: props.opportunity._id,
      descriptionType: descriptionTypes.organization,
    });
    await store.dispatch('user/updateOrganization', {
      description,
    });

    emits('update-opportunity', {
      _organization: {
        ...props.opportunity._organization,
        description,
      },
    });
  };

  const saveCompanyDescription = async(description: string) => {
    try {
      resetConfirmUpdate();
      confirmUpdate.value.description = description;

      if (descriptionType.value === descriptionTypes.custom) {
        await updateCustomJob({
          id: props.opportunity._id,
          customCompanyDescription: description,
          descriptionType: descriptionTypes.custom,
        });
        emits('update-opportunity', {
          customCompanyDescription: description,
          descriptionType: descriptionTypes.custom,
        });
      } else if (descriptionType.value === descriptionTypes.client) {
        await updateClientDescription(confirmUpdate.value.description);
      } else if (descriptionType.value === descriptionTypes.organization) {
        await updateOrganizationDescription(confirmUpdate.value.description);
      }
    } catch (error) {
      toast.show({
        message: error.message,
        type: 'error',
      });
    }
  };

  const companyDescriptionOptions = computed(() => {
    return [
      {
        label: i18n.t('generics.description-of', { name: props.opportunity?._organization?.name }),
        value: descriptionTypes.organization,
      },
      {
        label: i18n.t('generics.description-of', { name: props.opportunity?._company?.name }),
        value: descriptionTypes.client,
      },
      { label: i18n.t('generics.custom-description'), value: descriptionTypes.custom },
    ];
  });
</script>

<template>
  <div class="block w-full">
    <PanelCommonDescriptionView
      v-if="!isEditing"
      :text="companyDescription"
    />
    <div
      v-else
      class="flex w-full flex-col items-start">
      <MvSelect
        :is-absolute="true"
        :items="companyDescriptionOptions"
        :label="`${$t('generics.to-use')} :`"
        :value="descriptionType"
        class="mb-2 max-w-[300px]"
        @select="handleSelectCompanyDescription"
      >
      </MvSelect>
      <PanelCommonDescriptionEdit
        :key="descriptionType"
        :is-disabled="descriptionType !== descriptionTypes.custom"
        :text="companyDescription"
        :title="$t('companies.panel-company.company-description')"
        @close-edit="onCloseEdit"
        @save-text="saveCompanyDescription"
      />
    </div>
    <ModalConfirm
      :cancelText="$t('generics.continue-edit')"
      :confirmText="$t('generics.confirm')"
      :content="confirmUpdate.warning"
      :is-open="!!confirmUpdate.type"
      :title="$t('description.edit-description')"
      color="success"
      @close="resetConfirmUpdate"
      @on-confirm="onConfirmUpdate"
    ></ModalConfirm>
  </div>
</template>

<style scoped>

</style>
