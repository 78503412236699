<template>
  <section v-click-outside="closePanel" class="panel__custom__jobs">
    <header class="bg-neutral-200">
      <div class="header--options">
        <div class="header--options--left">
          <panel-header-nav />
          <button-share-job
            class="ml-5"
            :company="job._company"
            :job="job"
            @update-owners="updateOwners"
          ></button-share-job>
        </div>
        <div v-if="!job.isSpontaneous" class="header--options--right is-align-items-center">
          <button-dropdown
            v-if="job._id"
            class="panel-custom-jobs__dropdown"
            :dropdownItems="changeStatus"
            :dropdownText="contentDp"
            @select="updateHunt">
          </button-dropdown>
          <custom-jobs-delete-warning-modal
            :isOpen="displayModalDelete"
            @close="displayModalDelete = false"
            @on-confirm="deleteJob"/>
          <custom-jobs-close-warning-modal
            :isOpen="displayWarningModal"
            @close="onCloseCloseWarning"
            @on-confirm="confirmUpdateHunt"/>
          <bc-button
            v-tooltip="{ content: $t('hunt.edit-opportunity') }"
            class="ml-1"
            data-merge-profile
            icon-left="edit-3"
            size="extra-small"
            color="primary-base"
            @click.native="goEdit">
          </bc-button>
          <bc-button
            v-tooltip="{ content: $t('hunt.delete-opportunity') }"
            color="error-base"
            icon-left="trash"
            size="extra-small"
            @click.native="goDeleteJob"
          >
          </bc-button>
        </div>
      </div>
    </header>
    <div class="main">
      <panel-custom-jobs-caption :job="job"></panel-custom-jobs-caption>
      <panel-categories :categories="categories"></panel-categories>
      <panel-view>
        <component
          :is="panelView"
          :key="panelView"
          :companyId="job._company && job._company._id"
          :customJob="job"
          :refetch="populateData"
          :should-refetch="shouldRefetch"
          type="hunt"
          @urgency-updated="setUrgency"
          @on-update="onUpdate">
        </component>
      </panel-view>
    </div>
  </section>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import { getCustomJobsById } from '@/api/custom-jobs';
  import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav';
  import ButtonDropdown from '../../UIKit/Button/ButtonDropdown.vue';
  import { http } from '@/api/index.js';
  import PanelCustomJobsCaption from './PanelCustomJobsCaption.vue';
  import PanelCategories from '@/components/Panel/PanelCategoriesOldStyle';
  import PanelView from '../PanelView';
  import PanelCustomJobsNotes from './PanelCustomJobsNotes';
  import PanelJobModalDelete from '@/components/Panel/PanelJob/PanelJobModal/PanelJobModalDelete';
  import TableModal from '@/components/Table/Modal/TableModal';
  import PanelProcessCustom from '@/components/Panel/PanelProcessCustom';
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import PanelHuntProcessRpo from '@/components/Panel/PanelHunt/PanelHuntProcessRpo';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import PanelCustomJobsDetails from './PanelCustomJobsDetails/PanelCustomJobsDetails';
  import PanelCustomJobsDistribution from './PanelCustomJobsDistribution';
  import PanelCustomJobsPosts from './PanelCustomJobsPosts/PanelCustomJobsPosts';
  import CustomJobsDeleteWarningModal from '@/components/CustomJobs/CustomJobsDeleteWarningModal';
  import CustomJobsCloseWarningModal from '@/components/CustomJobs/CustomJobsCloseWarningModal';
  import PanelHeaderLinkGenerator from '@/components/Panel/PanelHeaderLinkGenerator';
  import ButtonShareJob from '@/components/Button/ButtonShareJob';

  export default {
    name: 'panel-custom-jobs',
    components: {
      PanelHeaderLinkGenerator,
      BcButton,
      PanelHuntProcessRpo,
      PanelHeaderNav,
      ButtonDropdown,
      PanelCustomJobsCaption,
      PanelJobModalDelete,
      TableModal,
      PanelCategories,
      PanelView,
      'my-notes': PanelCustomJobsNotes,
      process: PanelProcessCustom,
      posts: PanelCustomJobsPosts,
      'profil-complete': PanelCustomJobsDetails,
      distribution: PanelCustomJobsDistribution,
      CustomJobsDeleteWarningModal,
      CustomJobsCloseWarningModal,
      ButtonShareJob,
    },
    mixins: [PanelNavigation],
    data() {
      return {
        showModal: false,
        displayModalDelete: false,
        displayWarningModal: false,
        selectedStatus: undefined,
        job: {},
        error: false,
        isEditable: false,
        shouldRefetch: false,
        open: [
          {
            activeBackgroundColor: '#3DCF8E1A',
            apiStatus: 'open',
            backgroundColor: '#3DCF8E1A',
            name: this.$i18n.t('hunt.opened-job'),
            textColor: '#3DCF8E',
            action: () => {
            },
          },
          {
            activeBackgroundColor: '#E6525A1A',
            apiStatus: 'close',
            backgroundColor: '#E6525A1A',
            name: this.$i18n.t('hunt.closed-job'),
            textColor: '#E6525A',
            action: () => {
            },
          },
        ],
        close: [
          {
            activeBackgroundColor: '#E6525A1A',
            apiStatus: 'close',
            backgroundColor: '#E6525A1A',
            name: this.$i18n.t('hunt.closed-job'),
            textColor: '#E6525A',
            action: () => {
            },
          },
          {
            activeBackgroundColor: '#3DCF8E1A',
            apiStatus: 'open',
            backgroundColor: '#3DCF8E1A',
            name: this.$i18n.t('hunt.opened-job'),
            textColor: '#3DCF8E',
            action: () => {
            },
          },
        ],
      };
    },
    async mounted() {
      document.querySelector('.panel__container').style = 'background-color: white;';
      this.populateData();
    },
    methods: {
      ...mapActions({
        updateCustomJob: 'customJobs/updateCustomJob',
        deleteCustomJob: 'customJobs/deleteCustomJob',
        setSelected: 'customJobs/setSelected',
      }),
      async deleteJob() {
        try {
          await this.deleteCustomJob(this.$route.params.id);
          this.$toast.show({
            type: 'success',
            message: this.$i18n.t('toast.job-deletion-has-been-carried-out'),
          });
          this.closePanel();
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: error.message,
          });
        }
      },
      async updateFromModal(jobData) {
        jobData.coachId = jobData.coachId.map(coach => coach.coachId);

        try {
          await http.patch(`/hunt/${this.$route.params.id}`, jobData);
          await this.populateData();

          this.showModal = false;
        } catch (error) {
          error;
          this.$toast.show({
            message: this.$i18n.t('toast.you-do-not-have-permissions'),
            type: 'error',
          });
        }
      },
      async populateData() {
        try {
          const { data } = await getCustomJobsById(this.$route.params.id);
          this.job = data;
          if (this.job._owners?.find(coach => coach._id === this.myId)) {
            this.isEditable = true;
          }
        } catch (error) {
          this.error = true;
        }
      },
      async updateHunt(e) {
        this.setSelected(this.job);
        this.selectedStatus = e.item.apiStatus === 'close' ? false : true;
        if (e.item.apiStatus === 'close') {
          this.displayWarningModal = true;
          return;
        }
        this.confirmUpdateHunt();
      },
      onCloseCloseWarning() {
        this.displayWarningModal = false;
        this.selectedStatus = undefined;
        this.setSelected({});
      },
      async confirmUpdateHunt() {
        try {
          const data = await this.updateCustomJob({
            id: this.$route.params.id,
            active: this.selectedStatus,
          });
          this.job.active = data.active;
          if (!data.active) {
            this.shouldRefetch = !this.shouldRefetch;
          }
          this.onCloseCloseWarning();
        } catch (error) {
          this.error = true;
        }
      },
      setUrgency(urgency) {
        this.job.urgence = urgency;
      },
      goEdit() {
        this.$router.push({
          ...this.$route,
          params: {
            id: this.job._id,
            from: 'panel',
          },
          query: {
            ...this.$route.query,
            display: 'panel',
            type: 'edit-custom-jobs',
          },
        });
      },
      goEditJob() {
        this.showModal = true;
      },
      goDeleteJob() {
        this.displayModalDelete = true;
      },
      closeDeleteModal() {
        this.displayModalDelete = false;
      },
      onUpdate(value) {
        this.job = {
          ...this.job,
          ...value,
        };
      },
      async updateOwners(owners) {
        try {
          const data = await this.updateCustomJob({
            id: this.$route.params.id,
            owners: owners.map(coach => coach._id),
          });
          this.job = {
            ...this.job,
            _owners: data._owners,
          };
        } catch (error) {
          this.error = true;
        }
      },
    },
    computed: {
      ...mapState({
        myId: store => store.user.profile._id,
        profileRole: store => store.user.profile.role,
      }),
      processCount() {
        return this.job.nbActiveProcesses || 0;
      },
      isSuperUser() {
        return this.$store.getters['user/isSuperUser'];
      },
      settingsItems() {
        return [
          {
            name: this.$i18n.t('companies.panel-hunt-process.edit-the-job'),
            activeBackgroundColor: '#3c80f6',
            backgroundColor: '#fff',
            action: this.goEditJob,
            textColor: '#333333',
          },
          {
            name: this.$i18n.t('companies.panel-hunt-process.delete-the-job'),
            activeBackgroundColor: '#3c80f6',
            backgroundColor: '#fff',
            action: this.goDeleteJob,
            textColor: '#e6525a',
          },
        ];
      },
      changeStatus() {
        if (this.job.active) return this.open;
        return this.close;
      },
      contentDp() {
        if (this.job.active) {
          return this.$i18n.t('hunt.opened-job');
        }
        return this.$i18n.t('hunt.closed-job');
      },
      panelView() {
        if (!this.isSuperUser && this.$route.query.category === 'process') {
          return 'process';
        }
        return this.$route.query.category ? this.$route.query.category : 'my-notes';
      },
      categories() {
        const defaultCategories = [
          {
            name: this.$i18n.t('generics.my-notes'),
            url: 'my-notes',
          },
          {
            name: this.$i18n.t('generics.job-descriptions'),
            url: 'posts',
          },
          {
            name: 'Profil complet',
            url: 'profil-complete',
          },
          {
            name: `${this.$i18n.t('generics.process')}`,
            url: 'process',
            count: this.processCount,
          },
        ];

        if (!this.job.isSpontaneous) {
          defaultCategories.push({
            name: this.$i18n.t('generics.distribution'),
            url: 'distribution',
            badge: {
              label: this.job.adPublished ? this.$t('generics.active') : this.$t('generics.inactive'),
              color: this.job.adPublished ? '#3DCF8E' : '#E6525A',
              backgroundColor: 'rgba(200, 200, 200, 0.3)',
            },
          });
        }

        return defaultCategories;
      },
    },
    watch: {
      async '$route.params.id'() {
        this.populateData();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-custom-jobs {
    &__dropdown {
      min-width: 188px;
    }
  }

  .panel__custom__jobs {
    padding: 0;

    header {
      padding: 0 10px 0 10px;
    }
  }

  section {
    flex-direction: column;
    flex: 1;

    & > .main {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
    }

    header {
      height: 50px;
      border-bottom: 1px solid #e5ecf0;

      .header--link {
        justify-content: center;
        align-items: center;
        color: $color-blue;
        font-size: $font-size-m;
        width: 40%;
        margin: 10px 5px 5px;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .header--options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .header--options--left {
          .panel-header-nav {
            margin-right: 15px;
          }
        }

        .header--options--right {
          display: flex;
          justify-content: flex-end;
          gap: 10px;
          width: 45%;

          .button-dropdown {
            padding: 10px;
            width: 30%;
            border-radius: 4px;
            max-height: 32px;

            .button-dropdown__wrapper {
              .div:first-child {
                font-size: 0.8em !important;
              }
            }
          }

          .panel-job-header__settings {
            width: 6%;
            padding: 9px 0;
          }

          button {
            max-width: 30px;
          }

          button > i {
            font-size: 18px;
          }
        }
      }
    }
  }
</style>
