<script lang="ts" setup>
  import BcTextEditor from '@/ui-kit/components/BcTextEditor/BcTextEditor.vue';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { defineEmits, defineProps, ref, watch, withDefaults } from 'vue';

  interface Props {
    text?: string;
    title: string;
    isCreating?: boolean;
    isDisabled?: boolean;
  }

  const emit = defineEmits<{
    (e: 'close-edit', value: boolean): void,
    (e: 'update-text', value: string): void
    (e: 'save-text', value: string): void
  }>();
  const props = withDefaults(defineProps<Props>(), {
    isCreating: false,
  });

  const content = ref<string>(props.text || '');
  const closeEdit = () => {
    emit('close-edit', false);
  };

  const saveDescription = async() => {
    emit('save-text', content.value);
    closeEdit();
  };

  watch(() => content.value, () => {
    if (props.isCreating) {
      emit('update-text', content.value);
    }
  });
</script>

<template>
  <div class="panel-common-description-edit flex w-full flex-col items-start rounded-md">
    <label class="mb-[5px] ml-[5px] text-xs text-blue-400">{{ title }}</label>
    <BcTextEditor
      v-model="content"
      :disabled="isDisabled"
      :config="{
        toolbar: {
          // items: [
          //   'undo', 'redo',
          //   '|', 'heading',
          //   '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
          //   '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
          //   '|', 'link', 'uploadImage', 'blockQuote', 'codeBlock',
          //   '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent',
          // ],
          removeItems: ['uploadImage', 'insertTable'],
          shouldNotGroupWhenFull: true,
        }
      }"
      class="mb-4 w-full rounded-md"
      :class="{ 'grayscale opacity-50': isDisabled }"
      @focusin.native="() => $router.replace({ ...$route, query: {...$route.query, typing: true} })"
      @focusout.native="() => $router.replace({ ...$route, query: {...$route.query, typing: undefined} })"
    />
    <div
      v-if="!isCreating"
      class="ml-auto flex gap-2.5">
      <BcButton
        :label="$t('global.cancel')"
        icon-left="cross"
        size="small"
        type="outlined"
        @click="closeEdit">
        {{ $t('global.cancel') }}
      </BcButton>
      <BcButton
        icon-left="check"
        native-type="submit"
        size="small"
        @click="saveDescription">
        {{ $t('global.save') }}
      </BcButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .panel-common-description-edit {
    & :deep() {
      .ck-editor div, .ck-editor .ck-content {
        border-color: #D9DFFD;
      }

      .ck-editor ck-toolbar__separator {
        background: #D9DFFD;
      }
    }
  }
</style>
