<template>
  <div
    class="panel-caption-contacts-list"
    v-on="$listeners"
  >
    <div class="panel-caption-contacts-list__content">
      <div class="panel-caption-contacts-list__text">
        {{ data }}
      </div>
      <div class="panel-caption-contacts-list__buttons">
        <bc-button
          v-tooltip.bottom="{
            show: copied[data],
            content: $t('generics.copied'),
            hideOnTargetClick: false,
            trigger: 'manual'
          }"
          iconCenter="file-copy"
          size="extra-small"
          v-clipboard:copy="data"
          v-clipboard:success="setIsCopiedState"
        ></bc-button>
      </div>
    </div>
    <div
      class="panel-caption-contacts-list__content"
      v-for="item in list"
      :key="item"
    >
      <div class="panel-caption-contacts-list__text">
        {{ item }}
      </div>
      <div class="panel-caption-contacts-list__buttons">
        <bc-button
          v-tooltip.bottom="{
            show: copied[item],
            content: $t('generics.copied'),
            hideOnTargetClick: false,
            trigger: 'manual'
          }"
          color="info"
          iconCenter="file-copy"
          size="extra-small"
          v-clipboard:copy="item"
          v-clipboard:success="setIsCopiedState"
        ></bc-button>
        <!-- <bc-button
          color="error"
          iconCenter="trash"
          size="extra-small"
          @click.native="$emit('on-delete', item)"
        ></bc-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import BcButton from '@/ui-kit/components/BcButton/BcButton';

export default {
  name: 'panel-caption-contacts-list',
  components: {
    BcButton,
  },
  props: {
    data: {
      type: String,
    },
    list: {
      type: Array,
      default: () => [],
    }
  },
  data: () => ({
    copied: {},
  }),
  methods: {
    setIsCopiedState(e) {
      this.copied = {
        ...this.copied,
        [e.text]: true,
      }
      setTimeout(() => {
        this.copied = {
          ...this.copied,
          [e.text]: false,
        }
      }, 1000);
    },
  }
}
</script>

<style lang="scss" scoped>
.panel-caption-contacts-list {
  width: 100%;
  flex-direction: column;
  gap: 8px;
  padding: 15px;

  &__content {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    width: 100%;
  }

  &__buttons {
    gap: 5px;

    &:deep() {
      i {
        font-size: 18px;
      }
    }
  }

  &__text {
    font-size: 14px;
  }
}
</style>
