<template>
  <bc-dropdown
    :close-dropdown-at-select="!multiChoice"
    force-direction
    :is-scrollable="false"
    boundaries-element="viewport"
    class="domains-dropdown__domains"
    name="domain">
    <template #label="{ isDropdownOpen }">
      <bc-dropdown-trigger
        :class="{ 'domains-dropdown__trigger--old': isOldStyle }"
        :is-arrow="true"
        :is-dropdown-open="isDropdownOpen"
        :is-label-placeholder="isLabelPlaceholder"
        :label="label"
        :text="selectedDomains"
        class="domains-dropdown__trigger"
        :placeholder="$t('activities.fill-in-a-profession')"
        type="button">
      </bc-dropdown-trigger>
    </template>
    <template #custom>
      <domains-dropdown-content
        :multi-choice="multiChoice"
        :options="options"
        :values="values"
        @click="$emit('click', $event)"
        class="domains-dropdown__domains">
      </domains-dropdown-content>
    </template>
  </bc-dropdown>
</template>

<script>
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
  import DomainsDropdownContent from '@/components/DomainsDropdown/DomainsDropdownContent';

  export default {
    name: 'domains-dropdown',
    components: {
      BcDropdown,
      BcDropdownTrigger,
      DomainsDropdownContent,
    },
    props: {
      label: {
        type: String,
        default: '',
      },
      values: {
        type: Array,
        default: () => [],
      },
      options: {
        type: Array,
        default: () => [],
      },
      multiChoice: {
        type: Boolean,
        default: false,
      },
      isOldStyle: {
        type: Boolean,
        default: true,
      },
      isLabelPlaceholder: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      selectedDomains() {
        return this.values.map(key => this.$t(key)).join(', ');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .domains-dropdown {
    &__domains {
      & :deep() {
        .bc-dropdown__menu {
          width: calc(100% - 120px);
          z-index: 11;
          @include bp("tablet") {
            width: initial;
          }
        }

        .bc-dropdown__content {
          overflow: auto;
          max-height: calc(100vh - 160px);

          @include bp("tablet") {
            max-height: initial;
            overflow: hidden;
          }
        }
      }
    }

    &__trigger {
      border-color: rgba($color-blue-cello, 0.5);

      &:hover {
        border-color: rgba($color-blue-cello, 0.5);
      }

      &--old {
        width: 420px;

        :deep() {
          .bc-dropdown-trigger__text {
            display: initial;
            color: $color-blue-cello;
            font-weight: $font-weight-light;
            font-size: $font-size-s;
          }
        }
      }
    }
  }
</style>
