<template>
  <div>
    <component
      @change="emitMergeInfo"
      :conflicts="conflicts"
      :is-profile-to-delete="isProfileToDelete"
      :profile="profile"
      :is="profileView">
    </component>
  </div>
</template>
<script>
  import MergeCoderProfile from "@/components/MergeProfile/MergeCoderProfile.vue";

  export default {
    name: 'merge-profile',
    components: {
      MergeCoderProfile,
    },
    props: {
      profileType: {
        type: String,
        default: 'coder',
      },
      profile: {
        type: Object,
        default: () => ({}),
        required: true,
      },
      isProfileToDelete: {
        type: Boolean,
        default: false,
      },
      conflicts: {
        type: Array,
        default: () => ([]),
      },
    },
    data() {
      return {
        mapProfileComponent: {
          'coder': 'merge-coder-profile',
        },
      };
    },
    computed: {
      profileView() {
        return this.mapProfileComponent[this.profileType];
      },
    },
    methods: {
      emitMergeInfo(key) {
        this.$emit('change', key);
      },
    },
  };
</script>
