import coders from '@/common-old/macros/coders';

export default {
  methods: {
    computeCoderSalary(obj) {
      const contractsSalaryMap = [
        {
          contract: coders.contracts.cdi.api,
          suffix: this.$i18n.t('companies.account-management.pay-year-simple'),
          value: obj.minimumPay ? obj.minimumPay.yearly : 0
        },
        {
          contract: coders.contracts.freelance.api,
          suffix: this.$i18n.t('companies.account-management.pay-day'),
          value: obj.minimumPay ? obj.minimumPay.daily : 0
        },
        {
          contract: coders.contracts.stage.api,
          value: null
        },
        {
          contract: coders.contracts.alternance.api,
          value: null
        }
      ];

      const separator = ' ou ';
      let res = '';

      contractsSalaryMap.forEach(contractSalaryMap => {
        const valueNotNull = contractSalaryMap.value && contractSalaryMap.value !== 0 && contractSalaryMap.value !== null;
        if (obj.contractCurrent === contractSalaryMap.contract && valueNotNull) {
          if (res.length > 0) {
            res += separator;
          }
          res += `${contractSalaryMap.value}${contractSalaryMap.suffix}`;
        }
      });

      return res;
    },
    getCoderField(object, value, apiValue) {
      for (let property in coders[object]) {
        if (coders[object][property].api === apiValue) {
          return coders[object][property][value];
        }
      }
    }
  }
};
