export const xp = {
  xpMin: 0,
  xpMax: 99,
  xpMaxFilter: 15
};

export const apiExperience = {
  student: 1,
  junior: 2,
  confirmed: 3,
  lead: 4,
  expert: 5,
  manager: 6,
};

export const experience = {
  junior: {
    api: apiExperience.junior,
    value: 'table-header.junior',
    query: 'junior'
  },
  confirmed: {
    api: apiExperience.confirmed,
    value: 'table-header.confirmed',
    query: 'confirmed'
  },
  lead: {
    api: apiExperience.lead,
    value: 'table-header.lead',
    query: 'lead'
  },
  expert: {
    api: apiExperience.expert,
    value: 'table-header.senior',
    query: 'expert'
  },
  manager: {
    api: apiExperience.manager,
    value: 'table-header.manager',
    query: 'manager'
  },
};

export const getExperienceNameFromApiValue = apiValue => Object.keys(apiExperience).find(key => apiExperience[key] === apiValue);

export const getExperienceItemsList = () => Object.entries(experience).flat().filter(experience => typeof experience === 'object');


export default {
  xp,
  experience
};
