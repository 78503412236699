<script lang="ts" setup>
import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
import { usePanelNavigation } from '@/composables/usePanelNavigation';
import { useStore } from '@/store';
import { useRoute, useRouter } from 'vue-router/composables';
import BcModal from '@/ui-kit/components/BcModal/BcModal.vue';
import type { Component } from 'vue';
import { computed, defineProps, defineEmits, ref, withDefaults } from 'vue';
import PanelModalMergeProfile from '@/components/Panel/PanelModal/PanelModalMergeProfile.vue';
import PanelCoderModalDelete from '@/components/Panel/PanelCoder/PanelCoderModal/PanelCoderModalDelete.vue';
import ProfileTypesSelection from '@/components/ProfileTypesSelection/ProfileTypesSelection.vue';
import { Candidate, ProfileType } from '@/domains/models/Candidate';
import { coderController } from '@/managers/coders/controller';

const store = useStore();
const route = useRoute();
const router = useRouter();
const { closePanel } = usePanelNavigation();

defineProps<{
  profileTypes: ProfileType[];
}>();

defineEmits<{
  (e: 'update:profile-types', value: ProfileType[]): void;
}>();
</script>

<template>
  <div
    class="flex min-h-[50px] w-full items-center justify-between border-b border-b-[#EDF0FF] bg-white px-[20px] py-2">
    <div class="flex gap-9">
      <button
        class="icon-cross flex items-center text-sm text-blue-400 hover:text-blue-500"
        @click="closePanel" />
      <ProfileTypesSelection
        v-if="profileTypes"
        :value="profileTypes"
        @input="$emit('update:profile-types', $event)" />
    </div>
  </div>
</template>
