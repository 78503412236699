<template>
  <div class="panel-chat-messages-job">
    <panel-chat-messages-job-mobile
      :company-name="((message || {})._contact || {})._company.name"
      :job-name="(message || {})._job.name"
      :logo-url="((message || {})._contact || {})._company.logoUrl"
      @click.native="openPanel({ id: message._job._id, type: 'jobs' })"
      v-if="isMobile">
    </panel-chat-messages-job-mobile>
    <card-informations
      :card-content="cardContent"
      @click.native="openPanel({ id: (message || {})._job._id, type: 'jobs' })"
      v-else>
    </card-informations>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import PanelChatMessagesJobMobile
    from '@/components/Panel/PanelChat/PanelChatMessages/PanelChatMessagesJob/PanelChatMessagesJobMobile';
  //
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import CardInformations from '@/components/Card/CardInformations/CardInformations';

  export default {
    name: 'panel-chat-messages-job',
    components: { CardInformations, PanelChatMessagesJobMobile },
    mixins: [PanelNavigation],
    props: {
      message: {
        type: Object,
      },
    },
    computed: {
      ...mapState({
        windowWidth: state => state.panel.windowWidth,
      }),
      cardContent() {
        return {
          ...this.message._job,
          _company: this.message._contact._company,
        };
      },
      isMobile() {
        return this.windowWidth < 769;
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-chat-messages-job {
    width: 100%;
    height: 90px;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }

    @include bp('tablet') {
      height: 155px;
      border: 1px solid #EEE;
      border-radius: 5px;
    }
  }
</style>
