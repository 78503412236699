export const setToken = (token) => {
  localStorage.setItem('Token', token);

  // extension related
  window.dispatchEvent(new Event('mv-extension-storage'));
};

export default {
  setToken,
};
