<template>
  <div class="panel-organization-configuration-parameters">
    <panel-organization-configuration-item title="Parametres">
      <div class="panel-organization-configuration-parameters__content">
        <div class="config-label">
          <bc-checkbox
            id="show-companies"
            label="Afficher la partie entreprise"
            v-model="framework.showCompanies"></bc-checkbox>
          <label for="show-companies"> Afficher la partie entreprise </label>
        </div>
        <div class="config-label">
          <bc-checkbox
            id="show-google-widgets"
            label="Afficher les widgets google"
            v-model="process.hasGoogleWidgets"></bc-checkbox>
          <label for="show-google-widgets">
            {{ $t('config.display_google_widgets') }}
          </label>
        </div>
        <div class="config-label">
          <bc-checkbox
            id="show-microsoft-widgets"
            label="Afficher les widgets microsoft"
            v-model="process.hasMicrosoftWidgets"></bc-checkbox>
          <label for="show-microsoft-widgets">
            {{ $t('config.display_microsoft_widgets') }}
          </label>
        </div>
        <div class="config-label">
          <bc-checkbox
            id="all-categories-active"
            v-model="process.allCategoriesActive"></bc-checkbox>
          <label for="all-categories-active">
            Toutes les catégories sont considérées comme actives (càd dont on prend en compte les
            process dans les KPIs)
          </label>
        </div>
        <div class="config-label" v-if="allowExport">
          <bc-checkbox id="show-export" v-model="process.showExport"></bc-checkbox>
          <label for="show-export">
            {{ $t('config.show-export') }}
          </label>
        </div>
        <div class="config-label">
          <bc-input
            id="averageFee"
            ref="averageFee"
            v-model="process.averageFee"
            class="mt-0"
            name="averageFee"
            type="number">
          </bc-input>
          <label for="averageFee">
            {{ $t('config.average_fee') }}
          </label>
        </div>
        <div v-if="!features.candidatesLimitUnlocked" class="config-label">
          <bc-input
            id="candidatesLimit"
            v-model="db.candidatesLimit"
            class="mt-0"
            name="candidatesLimit"
            type="number">
          </bc-input>
          <label for="candidatesLimit"> Limite candidat </label>
        </div>
        <div v-if="!features.companiesLimitUnlocked" class="config-label">
          <bc-input
            id="companiesLimit"
            v-model="db.companiesLimit"
            class="mt-0"
            name="companiesLimit"
            type="number">
          </bc-input>
          <label for="companiesLimit"> Limite entreprise </label>
        </div>
        <div v-if="!features.jobsLimitUnlocked" class="config-label">
          <bc-input
            id="jobsLimit"
            v-model="db.jobsLimit"
            class="mt-0"
            name="jobsLimit"
            type="number">
          </bc-input>
          <label for="jobsLimit"> Limite opportunité </label>
        </div>
      </div>
    </panel-organization-configuration-item>
  </div>
</template>

<script>
import organizationsMacros from '@/macros/organizations';
import { updateConfigurationOrganizationByKey } from '@/api/organizations';

import BcInput from '@/ui-kit/components/BcInput/BcInput';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
import PanelOrganizationConfigurationItem from './PanelOrganizationConfigurationItem';

export default {
  name: 'panel-organization-configuration-parameters',
  components: {
    BcInput,
    BcCheckbox,
    PanelOrganizationConfigurationItem,
  },
  props: {
    profile: {
      type: Object,
      default: () => ({}),
    },
    features: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      process: {
        showExport: true,
        hasGoogleWidgets: false,
        hasMicrosoftWidgets: false,
        hasAmount: false,
        allCategoriesActive: true,
        averageFee: 0,
      },
      framework: {
        showCompanies: false,
        takeNews: false,
      },
      db: {
        candidatesLimit: 200,
        companiesLimit: 200,
        jobsLimit: 20,
      },
      schedules: {},
      paid: false,
    };
  },
  mounted() {
    if (this.profile?.configuration?.framework) {
      this.framework = this.profile.configuration.framework;
    }
    if (this.profile?.configuration?.process?.hasAmount) {
      this.process.hasAmount = this.profile.configuration.process.hasAmount;
    }
    if (this.profile?.configuration?.process?.hasGoogleWidgets) {
      this.process.hasGoogleWidgets = this.profile.configuration.process.hasGoogleWidgets;
    }
    if (this.profile?.configuration?.process?.hasMicrosoftWidgets) {
      this.process.hasMicrosoftWidgets = this.profile.configuration.process.hasMicrosoftWidgets;
    }
    if (this.profile?.configuration?.process?.averageFee) {
      this.process.averageFee = this.profile.configuration.process.averageFee;
    }
    this.process.allCategoriesActive = !!this.profile?.configuration?.process?.allCategoriesActive;
    this.process.showExport = !!this.profile?.configuration?.process?.showExport;
    this.db = this.profile.configuration.db;
  },
  computed: {
    allowExport() {
      return (
        this.$store.state.user.profile?._organization?._id !== organizationsMacros.BluecodersId
      );
    },
  },
  methods: {
    async updateConfig(key, data) {
      try {
        await updateConfigurationOrganizationByKey({
          key,
          organizationId: this.profile._id,
          ...data,
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async updateProcessConfig(processConfig) {
      try {
        await updateConfigurationOrganizationByKey({
          key: 'process',
          organizationId: this.profile._id,
          ...(this.profile?.configuration?.process || {}),
          ...processConfig,
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async updateFrameworkConfig(framework) {
      try {
        await updateConfigurationOrganizationByKey({
          key: 'framework',
          organizationId: this.profile._id,
          ...framework,
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
  },
  watch: {
    process: {
      handler(newValue) {
        this.updateProcessConfig(newValue);
      },
      deep: true,
    },
    framework: {
      handler(newValue) {
        this.updateFrameworkConfig(newValue);
      },
      deep: true,
    },
    db: {
      handler(newValue) {
        this.updateConfig('db', newValue);
      },
      deep: true,
    },
    schedules: {
      handler(newValue) {
        this.updateConfig('schedules', newValue);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-organization-configuration-parameters {
  &__content {
    flex-direction: column;
    gap: 10px;
  }
}

.config-label {
  gap: 10px;
  align-items: center;
}
</style>
