<template>
  <div class="date-picker is-relative">
    <v-popover
      :auto-hide="true"
      :disabled="disabled"
      :handle-resize="true"
      popover-base-class="tooltip popover popover-calendar date-picker__popover"
      trigger="click">
      <div class="is-row is-align-items-center">
        <bc-input
          :disabled="disabled"
          :error="error"
          :label="label"
          :label-placeholder="false"
          :name="label"
          :required="fieldRequired"
          :value="value"
          class="date-picker__input"
          placeholder="20/02/2020">
        </bc-input>
      </div>
      <template slot="popover">
        <div class="is-row is-align-items-center">
          <v-date-picker
            :locale="locale"
            :max-date="maxDate"
            :min-date="minDate"
            :popover="{ placement: 'bottom' }"
            @input="setDate"
            class="tooltip-content"
            is-inline
            v-close-popover="closePopover"
            v-model="pickerDate">
          </v-date-picker>
        </div>
      </template>
    </v-popover>
    <v-popover
      :auto-hide="true"
      :handle-resize="true"
      class="is-absolute date-picker__tooltip"
      placement="right"
      popover-base-class="tooltip-light tooltip"
      trigger="click hover"
      v-if="$slots.default">
      <div
        :class="{
          'icon-alert-circle-full': isHovering,
          'icon-alert-circle': !isHovering,
        }"
        @mouseout="isHovering = false"
        @mouseover="isHovering = true">
      </div>
      <template slot="popover">
        <slot></slot>
      </template>
    </v-popover>
  </div>
</template>
<script>
  import BcInput from "@/ui-kit/components/BcInput/BcInput";
  import * as moment from "moment";
  import locale from '@/mixins/i18n/locale';

  export default {
    name: 'date-picker',
    components: { BcInput },
    mixins: [locale],
    props: {
      label: {
        type: String,
        default: '',
      },
      fieldRequired: {
        type: Boolean,
        default: false,
      },
      value: {
        type: String,
        default: '',
      },
      maxDate: {
        type: [Number, String, Date],
        default: null,
      },
      minDate: {
        type: [Number, String, Date],
        default: null,
      },
      error: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isHovering: false,
        closePopover: false,
        pickerDate: '',
      };
    },
    methods: {
      setDate(newDate) {
        this.$emit('input', moment(newDate).add(2, 'h').toISOString());
        this.$emit('blur');
        this.closePopover = true;
      },
    },
  };
</script>

<style lang=scss scoped>
  .date-picker {
    width: fit-content;

    &__input {
      margin-right: 5px;
    }

    &__tooltip {
      margin-top: 10px;
      height: fit-content;
      right: -20px;
    }
  }


  .icon-alert-circle {
    color: $color-primary;
  }

  .icon-alert-circle-full {
    color: $color-primary;

    &:hover {
      cursor: pointer;
    }
  }
</style>
