export default {
  state: {
    route: {}
  },
  actions: {
    setPersistedRoute({ commit }, route) {
      commit('setPersistedRoute', route);
    }
  },
  mutations: {
    setPersistedRoute(state, route) {
      state.route = route;
    }
  }
};
