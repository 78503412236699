<template>
  <div class="card-pokes">
    <card-informations-pokes-content
      :card-content="cardContent.direction === 2 ? jobObject : coderObject"
      :side="'left'"
      @click.meta.native.exact="openPanelInNewTab({ id: cardContent._id, type: 'pokes', category: 'my-notes' })"
      @click.native.exact="openPanel({ id: cardContent._id, type: 'pokes', category: 'my-notes' })"
      class="card-pokes__block">
    </card-informations-pokes-content>
    <card-informations-pokes-content
      :card-content="cardContent.direction === 2 ? coderObject : jobObject"
      :side="'right'"
      @click.meta.native.exact="openPanelInNewTab({ id: cardContent._id, type: 'pokes', category: 'my-notes' })"
      @click.native.exact="openPanel({ id: cardContent._id, type: 'pokes', category: 'my-notes' })"
      class="card-pokes__block">
    </card-informations-pokes-content>
  </div>
</template>

<script>
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import JobHelpers from '@/common-old/mixins/Jobs/jobHelpers';
  // helpers
  import CoderHelpers from '@/common-old/mixins/Coders/coderHelpers';
  import CardInformationsPokesContent
    from '@/components/Card/CardInformations/CardInformationsPokes/CardInformationsPokesContent';
  import { getExperienceNameFromApiValue } from '@/managers/coders/formatters/codersDeserializers';
import { getCompleteAddressOrTag } from '@/formatters/locations';

  export default {
    name: 'card-pokes',
    components: {
      CardInformationsPokesContent,
    },
    props: {
      /**
       * The Object that contains the content of the response that will be displayed by the card
       */
      cardContent: Object,
      /**
       * The type of the card
       * `jobs, pokes, receivedPokes`
       */
      cardType: String,
      /**
       * The index of the card
       */
      cardIndex: Number,
    },
    mixins: [PanelNavigation, JobHelpers, CoderHelpers],
    data() {
      return {
        isLoading: true,
      };
    },
    computed: {
      card() {
        return this.$store.state.card.cards.length > 0 ? this.$store.state.card.cards[this.cardIndex] : {};
      },
      /**
       * The array of infos for a job
       * @returns {*[]}
       */
      infosJob() {
        return [
          {
            icon: 'map-pin',
            value: getCompleteAddressOrTag(this.cardContent._job?.locations?.[0]) || '',
          },
          {
            icon: 'diploma',
            value: this.cardContent._job ? this.getJobField('experience', 'value', this.cardContent._job.experience) : '',
            label: '',
          },
          {
            icon: 'dollars',
            value: this.cardContent._job ? this.computeJobSalary(this.cardContent._job) : '',
            label: '',
          },
        ];
      },
      /**
       * The array of infos for a coder
       * @returns {*[]}
       */
      infosCoder() {
        return [
          {
            icon: 'map-pin',
            value: getCompleteAddressOrTag(this.cardContent._coder?.locations?.[0]) || '',
          },
          {
            icon: 'diploma',
            value: this.cardContent._coder && this.cardContent._coder.experience !== 0 ? getExperienceNameFromApiValue(this.cardContent._coder.experienceStatus) : '',
            label: '',
          },
          {
            icon: 'coins',
            value: this.cardContent._coder && this.cardContent._coder.minimumPay ? this.computeCoderSalary(this.cardContent._coder) : '',
            label: '',
          },
        ];
      },
      /**
       * The coder map object for the cardInfo component
       * @returns {{pictureUrl: string, name: string, title: string, infos: computed.infosCoder, tags: *}}
       */
      coderObject() {
        return {
          remote: this.cardContent._coder.remote,
          contracts: this.cardContent._coder.contracts,
          pictureUrl: this.cardContent && this.cardContent._coder ? this.cardContent._coder.pictureUrl : '',
          name: this.cardContent && this.cardContent._coder ? `${this.cardContent._coder.firstName} ${this.cardContent._coder.lastName}` : '',
          title: this.cardContent && this.cardContent._coder ? this.cardContent._coder.title : '',
          infos: this.infosCoder,
          tags: this.cardContent && this.cardContent._coder ? [...this.cardContent._coder._technos3, ...this.cardContent._coder._technos2, ...this.cardContent._coder._technos1] : [],
        };
      },
      /**
       * The job map object for the cardInfo component
       * @returns {{pictureUrl: string, name: string, title: string, infos: computed.infosJob, tags: *}}
       */
      jobObject() {
        return {
          remote: this.cardContent._job.remote,
          contracts: [this.cardContent._job.contract],
          pictureUrl: this.cardContent && this.cardContent._company ? this.cardContent._company.logoUrl : '',
          name: this.cardContent && this.cardContent._company ? this.cardContent._company.name : '',
          title: this.cardContent && this.cardContent._job ? this.cardContent._job.name : '',
          infos: this.infosJob,
          tags: this.cardContent && this.cardContent._job ? [...this.cardContent._job._technosPrimary, ...this.cardContent._job._technosSecondary] : [],
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card-pokes {
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
    @include bp('tablet') {
      flex-direction: row;
    }

    &__block {
      margin: 5px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .disabled {
    cursor: default;
  }
</style>
