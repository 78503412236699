<template>
  <div
    class="technos-star icon-star"
    :class="`technos-star--${color}`"></div>
</template>

<script>
  // TODO Remplacer par le composant icon
export default {
  name: 'technos-star',
  props: {
    color: {
      type: String,
      default: 'blue'
    }
  }
};
</script>

<style scoped lang="scss">
.technos-star {
  font-size: 25px;
  color: $color-blue;
  &--transparent {
    @include opacity(0.25);
  }
  &--gray {
    color: $color-gray-raven;
  }
}
</style>
