<template>
  <div
    class="card-admin-tag is-full-width is-column is-overflow-hidden border-radius-m is-relative"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
    @click.exact="handleClick">
    <div
      class="card-admin-tag__container">
      <div
        class="card-admin-tag__wrapper card-admin-tag__name">
        <div @click.stop>
          <bc-checkbox
            :id="cardContent._id"
            v-model="checked"
            @input="$emit('checked', $event)">
          </bc-checkbox>
          <p
            v-tooltip="cardContent.name">
            {{ cardContent.name }}
          </p>
        </div>
      </div>
      <div>
        <div
          class="card-admin-tag__wrapper hidden-xs hidden-sm">
          <b>Type :</b>
          &nbsp;{{ this.cardContent.type.name }}
        </div>
        <div
          class="card-admin-tag__wrapper hidden-xs hidden-sm">
          <b>Profils :</b>
          &nbsp;{{ this.cardContent.count.coders }}
        </div>
        <div
          class="card-admin-tag__wrapper hidden-xs hidden-sm">
          <b>Jobs :</b>
          &nbsp;{{ this.cardContent.count.jobs }}
        </div>
        <div
          class="card-admin-tag__wrapper hidden-xs hidden-sm">
          <b>Visible :</b>
          &nbsp;{{ this.cardContent.visible }}
        </div>
        <div
          class="card-admin-tag__wrapper hidden-xs hidden-sm">
          <b>Vérifié :</b>
          &nbsp;{{ this.cardContent.verified }}
        </div>
        <div
          class="card-admin-tag__wrapper hidden-xs hidden-sm">
          <b>Organisation :</b>
          &nbsp;{{ this.getOrganization() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PanelNavigationMixin from '@/common-old/mixins/Panel/panelNavigation';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
  import { mapState } from 'vuex';

  export default {
    name: 'card-admin-tags',
    mixins: [PanelNavigationMixin],
    components: {
      BcCheckbox,
    },
    props: {
      cardContent: {
        type: Object,
        default: () => ({}),
      },
      isChecked: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isHover: false,
        checked: this.isChecked || false,
      };
    },
    computed: {
      ...mapState({
        organizationsWithTags: state => state.tags.organizationsWithTags,
      }),
    },
    methods: {
      handleClick() {
        this.openPanel({ id: this.cardContent._id, type: 'tags' })
      },
      check() {
        this.$emit('checked', this.cardContent._id)
      },
      getOrganization() {
        if (!this.cardContent._organization) return "Toutes";
        
        const orga = this.organizationsWithTags.find(orga => orga._id.toString() === this.cardContent._organization.toString())
        return orga ? orga.name : "Toutes";
      }
    },
  };
</script>

<style lang="scss" scoped>
  .card-admin-tag {
    &:hover {
      cursor: pointer;
      max-height: initial;
    }


    &__container {
      padding: 15px;
      flex-direction: column;
      justify-content: space-between;

      @include bp('tablet') {
        flex-direction: row;
      }

      @include bp('desktop') {
        // padding: 25px 15px;
      }
    }


    &__checkbox {
      align-items: center;
    }

    &__wrapper {
      margin: 10px;
      max-height: 100%;
      min-height: 37px;
      width: 100%;

      @include bp('tablet') {
        margin: 0 10px;
        width: initial;
      }

      align-items: center;
    }


    &__name {
      min-width: 20%;
      max-width: 20%;
      color: $color-secondary-dark;
      font-weight: bold;
      font-size: $font-size-m;

      & > div > div.bc-checkbox {
        margin-right: 20px;
      }
    }
  }
</style>
