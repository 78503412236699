<script lang="ts" setup>
  import { computed, defineProps } from 'vue';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { useStore } from '@/store';

  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import FiltersDropdownCoach from '@/components/Filters/FiltersDropdown/FiltersDropdownCoach.vue';

  const modalTypes = ['modal-email-template-form', 'modal-templates-note', 'modal-templates-linkedin'] as const;
  type ModalType = typeof modalTypes[number];

  const route = useRoute();
  const router = useRouter();
  const store = useStore();
  const props = withDefaults(defineProps<{
    modalType: ModalType
  }>(), {
    modalType: 'modal-email-template-form',
  });

  const profile = computed(() => {
    return store.state.user.profile;
  });

  const selectedCoachId = computed(() => {
    if (route.query.all) {
      return undefined;
    }
    return route.query.coachId || profile.value?._id;
  });

  const handleCoachSelection = (coachId?: string) => {
    router.push({
      query: {
        ...route.query,
        coachId: coachId,
        all: !coachId ? 'true' : undefined,
      },
    });
  };

  const onCreateNewTemplate = () => {
    store.dispatch('app/setParams', {
      emailTemplate: undefined,
      isDefaultTemplate: undefined,
      disabled: undefined,
    });
    store.dispatch('app/setIsPanel', true);
    store.dispatch('app/setModal', props.modalType);
  };
</script>

<template>
  <div class="configuration-templates-header items-center gap-4">
    <FiltersDropdownCoach
      :selected-coach-id="selectedCoachId"
      class="mr-3"
      icon="users"
      @update:selected-coach-id="handleCoachSelection">
    </FiltersDropdownCoach>
    <BcButton icon-left="plus" @click="onCreateNewTemplate">
      {{ $t('email.create-new-template') }}
    </BcButton>
  </div>
</template>
