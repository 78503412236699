<template>
  <div class="configuration-share-processes">
    <configuration-share-processes-tabs></configuration-share-processes-tabs>
    <div class="configuration-share-processes__container">
      <h3 class="body-l--bold mb-2">{{ $t('generics.share-processes') }}</h3>
      <p class="configuration-share-processes__label"> {{ $t('config.share-process-label') }} : </p>
      <div class="configuration-share-processes__content">
        <bc-checkbox
          id="share-process-email"
          v-model="shareProcesses.email"
        >
          {{ $t('contacts.email') }}
        </bc-checkbox>
      </div>
      <div class="configuration-share-processes__content">
        <bc-checkbox
          id="share-process-phone"
          v-model="shareProcesses.tel"
        >
          {{ $t('contacts.phone') }}
        </bc-checkbox>
      </div>
      <div class="configuration-share-processes__content">
        <bc-checkbox
          id="share-process-linkedin"
          v-model="shareProcesses.linkedin"
        >
          Linkedin
        </bc-checkbox>
      </div>
      <div class="configuration-share-processes__content">
        <bc-checkbox
          id="share-process-cv"
          v-model="shareProcesses.cv"
        > CV </bc-checkbox>
      </div>

      <p class="configuration-share-processes__label mt-1"> Process : </p>
      <div class="configuration-share-processes__content">
        <bc-checkbox
          id="share-process-only-active"
          v-model="shareProcesses.onlyActiveProcesses"
        >
          {{ $t('config.share-process-only-active') }}
        </bc-checkbox>
        <div
          :class="{
            'icon-alert-circle-full': isHoveringProcessAlert,
            'icon-alert-circle': !isHoveringProcessAlert,
          }"
          class="ml-2"
          v-tooltip.bottom="{
            content: $t('config.share-process-only-active-alert'),
          }"
          @mouseout="isHoveringProcessAlert = false"
          @mouseover="isHoveringProcessAlert = true"
        >
        </div>
      </div>

      <div
        class="is-column"
        v-if="configurationFeatures.shareProcesses"
      >
        <h3 class="mt-5 body-m--medium">{{ $t('config.share-logo') }}</h3>
        <div
          class="configuration-share-processes__logo"
          v-if="shareProcesses.logoUrl || isUploading"
        >
          <div
            class="configuration-share-processes__logo--loader"
            v-if="isUploading"
          >
            <bc-spinner></bc-spinner>
          </div>
          <div v-if="shareProcesses.logoUrl">
            <img :src="shareProcesses.logoUrl" />
            <div>
              <bc-button
                @click="shareProcesses.logoUrl = ''"
                iconLeft="trash"
                color="error"
              ></bc-button>
            </div>
          </div>
        </div>
        <upload-container
          v-else
          accept="image/*"
          class="mt-1"
          @on-cropped="handleCroppedImage"
          :supportedFormats="['png', 'jpg', 'jpeg']"
          :useCropper="true"
          :cropperOptions="{
            aspectRatio: 15 / 6,
          }"
          :croppedImageSize="{
            imageSmoothingQuality: 'high',
            width: 600,
            height: 240,
          }"
        >
          <p style="font-size: 12px"> max 200x80px </p>
        </upload-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { upload } from '@/api/upload';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import UploadContainer from '@/components/UploadContainer/UploadContainer';
import ConfigurationShareProcessesTabs from '@/components/Configuration/Tabs/ConfigurationPreferencesTabs';

export default {
  name: 'configuration-share-processes',
  components: {
    BcButton,
    BcCheckbox,
    BcSpinner,
    UploadContainer,
    ConfigurationShareProcessesTabs,
  },
  data() {
    return {
      isHoveringProcessAlert: false,
      isUploading: false,
      shareProcesses: {
        email: false,
        tel: false,
        linkedin: false,
        cv: false,
        onlyActiveProcesses: false,
        logoUrl: ''
      },
    };
  },
  computed: {
    ...mapGetters({
      configuration: 'user/configuration',
      configurationFeatures: 'user/configurationFeatures',
    }),
    profile() {
      return this.$store.state.user.profile;
    },
  },
  mounted() {
    if (this.configuration.shareProcesses) {
      this.shareProcesses = this.configuration.shareProcesses;
    }
  },
  methods: {
    ...mapActions({
      updateConfigurationOrganizationByKey: 'user/updateConfigurationOrganizationByKey',
    }),
    async updateConfiguration(key, data) {
      try {
        await this.updateConfigurationOrganizationByKey({ key, ...data });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async handleCroppedImage(file) {
      this.isUploading = true;
      try {
        const dataToSend = new FormData();
        dataToSend.append('file', file);
        dataToSend.append('uploadType', 'picture');
        const { data } = await upload({
          id: this.profile._id,
          file: dataToSend,
        });
        this.shareProcesses = {
          ...this.shareProcesses,
          logoUrl: data.fileUrl,
        };
        this.isUploading = false;
        this.$toast.show({
          type: 'success',
          title: this.$i18n.t('toast.congratulations'),
          message: this.$i18n.t('toast.file-received-for-bdd-integration'),
        });
      } catch (err) {
        this.isUploading = false;
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
  },
  watch: {
    shareProcesses: {
      handler(data) {
        this.updateConfiguration('shareProcesses', data);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.configuration-share-processes {
  flex-direction: column;

  &__container {
    flex-direction: column;
    gap: 10px;
    padding: 20px 30px;
  }

  &__logo {
    margin-top: 5px;

    &>div {
      position: relative;
      align-items: center;
      justify-content: space-between;
      width: 405px;
      min-height: 110px;

      &>img {
        border: 1px solid rgba(29, 57, 94, 0.5);
        border-radius: 4px;
        width: 360px;
      }

      & button {
        width: 32px;
        height: 32px;
      }
    }

    &--loader {
      justify-content: center !important;
    }
  }
}
</style>
