import router from '@/router';
import moment from 'moment/min/moment-with-locales';
import showdown from 'showdown';
import { i18n } from '../../i18n/i18n';

import { type } from '../../common-old/macros/notes';
import { oldProspectionStatusTrads } from '../../macros/companies/prospectionStatus';
import { formatEmail, formatEmailCompanies } from '@/helpers/strings';

export function getFullName(relatedUser = {}, userType = 'coder') {
  const fullName = {
    coach: `${relatedUser?.firstName} ${relatedUser?.lastName}`,
    contact: `${relatedUser?.firstName} ${relatedUser?.lastName}`,
    coder: `${relatedUser?.firstName} ${relatedUser?.lastName}`,
    job: `${relatedUser?.name}`,
    company: `${relatedUser?.name}`,
    hunt: `${relatedUser?.poste}`,
    customJob: `${relatedUser?.title}`,
  };

  return fullName[userType];
}

function getCurrentRouteName() {
  return router.currentRoute.name;
}

function removeTags(str) {
  if ((str === null) || (str === ''))
    return false;
  else
    str = str.toString();
  return str.replace(/(<([^>]+)>)/ig, '');
}

const getCurrentRouteType = () => {
  return router.currentRoute?.query?.type;
};

const getLink = (id, type) => {
  const link = {
    params: { id },
    query: { ...router.currentRoute?.query, type },
    name: getCurrentRouteName(),
  };

  return router.resolve(link).href;
};

const insertIdForLink = (id, link) => {
  if (!id) {
    return link;
  }
  if (!link.includes('/' + id)) {
    const linkTab = link.split('?');
    return linkTab[0] + `/${id}?` + linkTab[1];
  }
  return link;
};

// function markdownToHtml(body) {
//   // Regular expression to match URLs
//   const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;

//   // Replace all URLs in the text with HTML links
//   const maxLength = 48;
//   return body.replace(urlRegex, function(url) {
//     return `<a target="_blank" href="${url}">${url.substr(0, maxLength)}${url.length > maxLength ? '...' : ''}</a>`;
//   });
// }

function markdownToHTML(markdown) {
  var converter = new showdown.Converter();
  return converter.makeHtml(markdown);
}

function getNoteIcon(note = {}) {
  if (note.type === type.note.api) {
    return 'pen-square';
  }
  if (note.type === type.call.api) {
    return 'phone';
  }
  if (note.type === type.email.api) {
    return 'mail';
  }
  if (note.type === type.reminder.api) {
    return 'calendar-circle-plus';
  }
}

function getNoteSource(note = {}) {
  if (note.type === type.linkedin.api) {
    return 'linkedin';
  }
  if (note.type === type.whatsapp.api) {
    return 'whatsapp';
  }
}

function getNoteTypeLabel({ note, authorLink, resourceLink = '' }) {
  const map = {
    [type.qualification.api]: `${authorLink} ${i18n.t('timeline.has-qualifiate')} ${resourceLink}`,
    [type.message.api]: `${authorLink} ${i18n.t('timeline.created-a-note-for')} ${resourceLink}`,
    [type.other.api]: `${authorLink} ${i18n.t('timeline.created-a-note-for')} ${resourceLink}`,
    [type.note.api]: `${i18n.t('notes.action-note')} ${authorLink} ${i18n.t('generics.for')} ${resourceLink}`,
    [type.call.api]: `${i18n.t('notes.action-call')} ${authorLink} ${i18n.t('generics.for')} ${resourceLink}`,
    [type.email.api]: `${i18n.t('notes.action-email')} ${authorLink} ${i18n.t('generics.for')} ${resourceLink}`,
    [type.echange.api]: `${i18n.t('notes.action-note')} ${authorLink} ${i18n.t('generics.for')} ${resourceLink}`,
    [type.linkedin.api]: `${i18n.t('notes.action-linkedin')} ${authorLink} ${i18n.t('generics.for')} ${resourceLink}`,
    [type.whatsapp.api]: `${i18n.t('notes.action-whatsapp')} ${authorLink} ${i18n.t('generics.for')} ${resourceLink}`,
    [type.reminder.api]: `${authorLink} ${i18n.t('notes.action-reminder', { date: moment(note.dateToContact).format('L') })}`,
  };

  return map[note.type] || '';
}

export default [
  {
    7: {
      name: 'partnerNote',
      description: 'A coach created a note for a partner',
      visible: true,
      icon: ({ _note }) => getNoteIcon(_note),
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      source: ({ _note }) => getNoteSource(_note),
      header: ({ _coach, _note, _company }) => {
        const coachLink = insertIdForLink(_coach._id, getLink(_coach._id, 'coaches'));
        const companyLink = insertIdForLink(_company._id, getLink(_company._id, 'companies'));
        const authorLink = `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link>`;
        const resourceLink = `<router-link tag="b" to="${companyLink}">${_company.name}</router-link>`;

        const label = getNoteTypeLabel({ note: _note, authorLink, resourceLink });

        if (label) {
          return label;
        }
      },
      content: ({ _note }) => _note.content,
    },
    21: {
      name: 'coderNote',
      description: 'A coach created a note for a coder',
      visible: true,
      icon: ({ _note }) => getNoteIcon(_note),
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      source: ({ _note }) => getNoteSource(_note),
      header: ({ _coach, _note, _coder }) => {
        const coachLink = insertIdForLink(_coach._id, getLink(_coach._id, 'coaches'));
        const coderLink = insertIdForLink(_coder._id, getLink(_coder._id, 'coders'));
        const authorLink = `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link>`;
        const resourceLink = `<router-link tag="b" to="${coderLink}">${getFullName(_coder, 'coder')}</router-link>`;
        const label = getNoteTypeLabel({ note: _note, authorLink, resourceLink });

        if (label) {
          return label;
        }
      },
      content: ({ _note }) => _note.content,
    },
    99: {
      name: 'coderReminder',
      description: 'A coach created a reminder for a coder',
      visible: true,
      icon: 'calendar-circle-plus',
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header: ({ _coach, _coder, _suggestion }) => {
        const coachLink = insertIdForLink(_coach._id, getLink(_coach._id, 'coaches'));
        const authorLink = `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link>`;
        const coderRoute = insertIdForLink(_coder._id, getLink(_coder._id, 'coders'));
        const coderLink = `<router-link tag="b" to="${coderRoute}">${_coder.firstName} ${_coder.lastName}</router-link>`;

        const isCandidate = _suggestion.reminderType === 'candidate';
        const label = `${authorLink} ${i18n.t(isCandidate ? 'notes.action-reminder-candidate' : 'notes.action-reminder-contact')} ${i18n.t('generics.for')} ${coderLink} ${i18n.t('timeline.at(date)')} ${moment(_suggestion.comebackAt).format('L')}`;

        if (label) {
          return label;
        }
      },
      content: ({ _suggestion }) => _suggestion?.content,
    },
    29: {
      name: 'contactNote',
      description: 'A coach created a note for a contact',
      visible: true,
      icon: ({ _note }) => getNoteIcon(_note),
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      source: ({ _note }) => getNoteSource(_note),
      header: ({ _coach, _note }) => {
        const coachLink = insertIdForLink(_coach._id, getLink(_coach._id, 'coaches'));
        const authorLink = `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link>`;
        const label = getNoteTypeLabel({ note: _note, authorLink });

        if (label) {
          return label;
        }
      },
      content: ({ _note }) => _note.content,
    },
    45: {
      name: 'processNoteCreated',
      description: 'Quand un coach crée une note pour un process',
      visible: true,
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header: ({ _coach, _process, _processCustom, _coder }) => {
        const process = _processCustom ? _processCustom : _process;

        const panelType = getCurrentRouteType();
        const coachLink = insertIdForLink(_coach._id, getLink(_coach._id, 'coaches'));
        // const processLink = insertIdForLink(process._id, getLink(process._id, 'process'));
        const jobLink = insertIdForLink(process?._customJob._id, getLink(process?._customJob?._id, 'hunt'));
        const companyLink = insertIdForLink(process?._company?._id, getLink(process?._company?._id, 'companies'));
        const coderLink = insertIdForLink(_coder._id, getLink(_coder._id, 'coders'));

        return panelType === 'process'
          ? `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> {{$t('timeline.created-a-note')}}`
          : `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> {{$t('timeline.created-a-note-for-his')}} <router-link tag="b" to="${coderLink}">${_coder.firstName} ${_coder.lastName}</router-link> {{$t('activities.on')}} <router-link tag="b" to="${jobLink}">${getFullName(_processCustom ? _processCustom?._customJob : _process?._customJob, 'customJob')}</router-link> {{$t('timeline.among')}} <router-link tag="b" to="${companyLink}">${getFullName(_processCustom ? _processCustom?._company : process?._company, 'company')}</router-link>`;
      },
      content: ({ _note }) => _note.content,
    },
    67: {
      name: 'partnerEditOwnership',
      visible: true,
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header(data) {
        const coachLink = getLink(data._coach._id, 'coaches');
        const companyLink = getLink(data._company._id, 'companies');

        if (data._secondCoach) {
          const coachSecondLink = getLink(data._secondCoach._id, 'coaches');

          return `<router-link tag="b" to="${coachLink}">${getFullName(data._coach, 'coach')}</router-link> {{$t('timeline.assigned')}} <router-link tag="b" to="${companyLink}">${getFullName(data._company, 'company')}</router-link> {{$t('timeline.at')}} <router-link tag="b" to="${coachSecondLink}">${getFullName(data._secondCoach, 'coach')}</router-link>`;
        }

        return `<router-link tag="b" to="${coachLink}">${getFullName(data._coach, 'coach')}</router-link> {{$t('activities.supported')}} <router-link tag="b" to="${companyLink}">${getFullName(data._company, 'company')}</router-link>`;
      },
    },
    68: {
      name: 'partnerRemoveOwnership',
      visible: true,
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header(data) {
        const coachLink = getLink(data._coach._id, 'coaches');
        const companyLink = getLink(data._company._id, 'companies');

        if (data._coach?._id === data._secondCoach?._id) {
          return `<router-link tag="b" to="${coachLink}">${getFullName(data._coach, 'coach')}</router-link> {{$t('timeline.withdrew')}} <router-link tag="b" to="${companyLink}">${getFullName(data._company, 'company')}</router-link> {{$t('timeline.from-its-Account-Management-list')}}`;
        }

        if (data._secondCoach) {
          const coachSecondLink = getLink(data._secondCoach._id, 'coaches');

          return `<router-link tag="b" to="${coachLink}">${getFullName(data._coach, 'coach')}</router-link> {{$t('timeline.withdrew')}} <router-link tag="b" to="${companyLink}">${getFullName(data._company, 'company')}</router-link> {{$t('timeline.from-the-Account-Management-list-of')}} <router-link tag="b" to="${coachSecondLink}">${getFullName(data._secondCoach, 'coach')}</router-link>`;
        }

        return `<router-link tag="b" to="${coachLink}">${getFullName(data._coach, 'coach')}</router-link> {{$t('timeline.withdrew')}} <router-link tag="b" to="${companyLink}">${getFullName(data._company, 'company')}</router-link> {{$t('timeline.from-its-Account-Management-list')}}`;
      },
    },
    72: {
      name: 'customJobNoteCreate',
      description: 'A coach created a note for a customJob',
      visible: true,
      icon: ({ _note }) => getNoteIcon(_note),
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      source: ({ _note }) => getNoteSource(_note),
      header: ({ _coach, _note, _customJob }) => {
        const coachLink = insertIdForLink(_coach._id, getLink(_coach._id, 'coaches'));
        const customJobLink = insertIdForLink(_customJob._id, getLink(_customJob._id, 'hunt'));
        const authorLink = `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link>`;
        const resourceLink = `<router-link tag="b" to="${customJobLink}">${_customJob.title}</router-link>`;
        const label = getNoteTypeLabel({ note: _note, authorLink, resourceLink });

        if (label) {
          return label;
        }
      },
      content: ({ _note }) => _note.content,
    },
    76: {
      name: 'processPayed',
      visible: true,
      pictureUrl: ({ _company }) => _company.logoUrl,
      header({ _company }) {
        const companyLink = getLink(_company._id, 'companies');

        return `<router-link tag="b" to="${companyLink}">${getFullName(_company, 'company')}</router-link> {{$t('timeline.made-the-payment-for-this-process')}}`;
      },
    },
    77: {
      name: 'prospectingReminderSet',
      visible: true,
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header({ _company, _coach, data }) {
        const coachLink = getLink(_coach._id, 'coaches');
        const companyLink = getLink(_company._id, 'companies');

        return `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> {{$t('timeline.has-scheduled-a-relaunch-date-for')}}  <router-link tag="b" to="${companyLink}">${getFullName(_company, 'company')}</router-link> {{$t('timeline.the')}} <strong>${moment(data).format('L')}</strong>`;
      },
    },
    78: {
      name: 'prospectingStatusSet',
      visible: true,
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header({ _company, _coach, data }) {
        const coachLink = getLink(_coach._id, 'coaches');
        const companyLink = getLink(_company._id, 'companies');
        const oldStatus = typeof data === 'number' ? data : undefined;

        let status = data?.status ? data.status : '';
        if (oldStatus) {
          status = oldProspectionStatusTrads[oldStatus];
        }

        return `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> {{$t('timeline.amended-the-status-of')}} <router-link tag="b" to="${companyLink}">${getFullName(_company, 'company')}</router-link> {{$t('timeline.in')}} <strong>${status}</strong>`;
      },
    },
    80: {
      name: 'documentUploaded',
      visible: true,
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header({ _coder, _coach, data }) {
        const coachLink = getLink(_coach._id, 'coaches');
        const coderLink = getLink(_coder._id, 'coders');
        const documentName = data?.name;

        const documentLink = {
          params: { id: _coder._id },
          query: { type: 'coders', category: 'documents', 'selected-document': data.url },
          name: getCurrentRouteName(),
        };

        return `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> {{$t('timeline.uploaded-a-document')}} (<router-link tag="b" to="${router.resolve(documentLink).href}">${documentName}</router-link>) {{$t('timeline.for')}} <router-link tag="b" to="${coderLink}">${getFullName(_coder, 'coder')}</router-link>`;
      },
    },
    81: {
      name: 'documentUploaded',
      visible: true,
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header({ _coach, _customJob, data }) {
        const coachLink = getLink(_coach._id, 'coaches');
        const customJobLink = getLink(_customJob._id, 'hunt');
        const documentName = data?.document?.name;

        const documentLink = {
          params: { id: _customJob._id },
          query: {
            page: 1,
            type: 'hunt',
            coachId: _coach._id,
            category: 'posts',
            'selected-document': data.url,
          },
          name: getCurrentRouteName(),
        };

        return `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> {{$t('timeline.uploaded-a-document')}} (<router-link tag="b" to="${router.resolve(documentLink).href}">${documentName}</router-link>) {{$t('timeline.for')}} <router-link tag="b" to="${customJobLink}">${_customJob.title}</router-link>`;
      },
    },
    84: {
      name: 'suggestionPostPone',
      visible: true,
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header({ _coach, _coder, data }) {
        const coachLink = getLink(_coach._id, 'coaches');
        const coderLink = getLink(_coder._id, 'coders');
        const days = data?.nbDays;

        return `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> {{ $t('timeline.has-postpone-candidate-suggestion') }} <router-link tag="b" to="${coderLink}">${_coder.firstName} ${_coder.lastName}</router-link> {{ $t('timeline.of-nb-days', { nbDays: ${days} }) }}`;
      },
    },
    85: {
      name: 'suggestionTransfer',
      visible: true,
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      author: 'coach',
      header({ _coach, _coder, data }) {
        const coachLink = getLink(_coach._id, 'coaches');
        const coderLink = getLink(_coder._id, 'coders');
        const coachToLink = getLink((data.coachTo || {})._id, 'coaches');

        return `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> {{ $t('timeline.has-transfer') }} <router-link tag="b" to="${coderLink}">${_coder.firstName} ${_coder.lastName}</router-link> {{ $t('timeline.to') }} <router-link tag="b" to="${coachToLink}">${getFullName(data.coachTo || {}, 'coach')}</router-link>`;
      },
    },
    86: {
      name: 'noteDelete',
      visible: true,
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      icon: 'trash',
      header({ _coach, _coder, _customJob, _company }) {
        const coachLink = getLink(_coach._id, 'coaches');
        let resource = {
          name: null,
          link: null,
        };
        if (_coder?._id) {
          resource.name = getFullName(_coder, 'coder');
          resource.link = insertIdForLink(_coder._id, getLink(_coder._id, 'coders'));
        } else if (_customJob?._id) {
          resource.name = _customJob.title;
          resource.link = insertIdForLink(_customJob._id, getLink(_customJob._id, 'hunt'));
        } else if (_company?._id) {
          resource.name = _company.name;
          resource.link = insertIdForLink(_company._id, getLink(_company._id, 'companies'));
        }

        return `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> {{ $t('timeline.has-removed-note') }} <router-link tag="b" to="${resource.link}">${resource.name}</router-link>`;
      },
    },
    90: {
      name: 'A coach has created a process',
      visible: true,
      pictureUrl: ({ _coder }) => _coder.pictureUrl,
      header({ _coder, _coach }) {
        let coderLink = getLink(_coder._id, 'coders');
        if (!coderLink.includes(_coder._id)) {
          coderLink = `${router.currentRoute.path}/${_coder._id}/?type=coders&category=my-notes`;
        }
        if (_coach) {
          const coachLink = getLink(_coach._id, 'coaches');
          return `<router-link tag="b" to="${coachLink}">${_coach.firstName} ${_coach.lastName}</router-link> {{ $t('timeline.has-created-a-process-for') }} <router-link tag="b" to="${coderLink}">${_coder.firstName} ${_coder.lastName}</router-link>`;
        }
        return `{{ $t('timeline.a-process-has-created-a-process-for') }} <router-link tag="b" to="${coderLink}">${_coder.firstName} ${_coder.lastName}</router-link>`;
      },
    },
    91: {
      name: 'processStepUpdate',
      visible: true,
      pictureUrl: ({ _coder }) => _coder.pictureUrl,
      header({ _coder, data, _coach }) {
        let coderLink = getLink(_coder._id, 'coders');
        const coachLink = getLink(_coach?._id, 'coaches');
        if (!coderLink.includes(_coder._id)) {
          coderLink = `${router.currentRoute.path}/${_coder._id}/?type=coders&category=my-notes`;
        }

        if (_coach?._id) {
          return `<router-link tag="b" to="${coachLink}">${_coach.firstName} ${_coach.lastName}</router-link> {{ $t('generics.moved') }} <router-link tag="b" to="${coderLink}">${_coder.firstName} ${_coder.lastName}</router-link> {{ $t('activities.at-step') }} <span class="text-blue-500">${data?.stepLabel}</span>`;
        }
      },
      html({ _note }) {
        if (_note?.content) {
          const content = JSON.parse(_note.content);
          return formatEmail(content);
        }
        return '';
      },
    },
    92: {
      name: 'processFailure',
      visible: true,
      pictureUrl: ({ _coder }) => _coder.pictureUrl,
      header({ _coder }) {
        let coderLink = getLink(_coder._id, 'coders');
        if (!coderLink.includes(_coder._id)) {
          coderLink = `${router.currentRoute.path}/${_coder._id}/?type=coders&category=my-notes`;
        }
        return `<router-link tag="b" to="${coderLink}">${_coder.firstName} ${_coder.lastName}</router-link> {{ $t('timeline.process-failure') }}`;
      },
      html({ data } = {}) {
        return data?.reason || '';
      },
    },
    94: {
      name: 'processCustomDeal',
      visible: true,
      pictureUrl: ({ _coder }) => _coder.pictureUrl,
      header({ _coder, _coach, _customJob }) {
        let coderLink = getLink(_coder._id, 'coders');
        if (!coderLink.includes(_coder._id)) {
          coderLink = `${router.currentRoute.path}/${_coder._id}/?type=coders&category=my-notes`;
        }
        const coachLink = getLink(_coach._id, 'coaches');
        const customJobLink = getLink(_customJob._id, 'hunt');

        return `<router-link tag="b" to="${coderLink}">${_coder.firstName} ${_coder.lastName}</router-link> {{ $t('timeline.was-recruited-by') }} <router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> {{ $t('timeline.in-place') }} <router-link tag="b" to="${customJobLink}">${_customJob.title}</router-link>`;
      },
      html({ data } = {}) {
        return `<div style="display: block;">
          <p>${i18n.t('candidates.acceptance-date')} : <b style="font-weight: bold">${moment(data.acceptanceDate).format('LL')}</b></p>
          <p>${i18n.t('generics.amount-to-bill')} : <b style="font-weight: bold">${data.dealAmount} € HT</b></p>
          <p>${i18n.t('generics.financial-proposal-details')} : <b style="font-weight: bold">${data.financialProposal}</b></p>
          <p>${i18n.t('generics.estimated-start-date')} : <b style="font-weight: bold">${moment(data.estimatedStartDate).format('LL')}</b></p>
        </div>`;
      },
    },
    95: {
      name: 'coderKasprUpdate',
      visible: true,
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header({ _coach, data }) {
        const coachLink = getLink(_coach._id, 'coaches');
        return `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> {{ $t('timeline.kaspr-search-timeline', { emailsCount: ${data.fields.newEmailsCount}, numbersCount: ${data.fields.newNumbersCount}, }) }}`;
      },
    },
    96: {
      name: 'coderKasprEmptyUpdate',
      visible: true,
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header({ _coach }) {
        const coachLink = getLink(_coach._id, 'coaches');
        return `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> {{ $t('timeline.kaspr-empty-search-timeline') }}`;
      },
    },
    1000: {
      name: 'sendMail',
      visible: true,
      icon: 'mail',
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header({ _coach, _coder }) {
        const coachLink = getLink(_coach?._id, 'coaches');
        const coderLink = getLink(_coder?._id, 'coders');
        return `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> {{ $t('email.sent-an-email-to') }} <router-link tag="b" to="${coderLink}">${getFullName(_coder, 'coder')}</router-link>`;
      },
      html({ _note }) {
        if (_note?.content) {
          const content = JSON.parse(_note.content);
          return formatEmail(content);
        }
        return '';
      },
    },
    1001: {
      name: 'sendMailCompanies',
      visible: true,
      icon: 'mail',
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header({ _coach, _note }) {
        if (_note?.content) {
          const content = JSON.parse(_note.content);
          const coachLink = getLink(_coach?._id, 'coaches');
          return `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> {{ $t('email.sent-an-email-to') }} <span class='text-blue-500'>{{ $tc('generics.recipient', ${content?.group?.length}).toLowerCase() }}</span>`;
        }
        return '';
      },
      html({ _note }) {
        if (_note?.content) {
          const content = JSON.parse(_note.content);
          return formatEmailCompanies(content);
        }
        return '';
      },
    },
  },
  {},
  {
    203: {
      name: 'coderSawJob',
      description: 'Quand un coder a vu un job',
      visible: true,
      pictureUrl: ({ _coder }) => _coder.pictureUrl,
      header: ({ _coder, _job, _company }) => {
        const coderLink = getLink(_coder._id, 'coders');
        const jobLink = getLink(_job._id, 'jobs');
        const companyLink = getLink(_company._id, 'companies');

        return `<router-link tag="b" to="${coderLink}">${getFullName(_coder, 'coder')}</router-link> {{$t('timeline.saw')}} <router-link tag="b" to="${jobLink}">${getFullName(_job, 'job')}</router-link> {{$t('timeline.of')}} <router-link tag="b" to="${companyLink}">${getFullName(_company, 'company')}</router-link>`;
      },
    },
    205: {
      name: 'coderPokedJob',
      description: 'Quand un coder a poké un job',
      visible: true,
      pictureUrl: ({ _coder }) => _coder.pictureUrl,
      header: ({ _coder, _poke }) => {
        const coderLink = getLink(_coder, 'coders');
        const jobLink = getLink(_poke?._job._id, 'jobs');

        return `<router-link tag="b" to="${coderLink}">${getFullName(_coder, 'coder')}</router-link> {{$t('timeline.is-interested-by')}} <router-link tag="b" to="${jobLink}">${getFullName(_poke?._job, 'job')}</router-link>`;
      },
    },
    230: {
      name: 'coderCreateAccountFromApplication',
      icon: 'user-check',
      description: 'Quand un candidat crée son compte depuis une plateforme d\'opportunités',
      visible: true,
      source: ({ data }) => data.source !== 'career' && data.source,
      pictureUrl: ({ _coder }) => _coder.pictureUrl,
      header: ({ _coder, data }) => {
        const coderLink = getLink(_coder._id, 'coders');
        if (data.source === 'career') {
          return `<router-link tag="b" to="${coderLink}">${getFullName(_coder, 'coder')}</router-link> {{$t('timeline.registered-from-career')}}</router-link>`;
        }
        let label = `<router-link tag="b" to="${coderLink}">${getFullName(_coder, 'coder')}</router-link> {{$t('timeline.registered-on-the-platform')}}</router-link>`;
        if (data.source) {
          label = `<router-link tag="b" to="${coderLink}">${getFullName(_coder, 'coder')}</router-link> {{$t('timeline.registered-from-multiposting')}}</router-link> ${data.source.toUpperCase()}`;
        }
        return label;
      },
    },
    231: {
      name: 'coderUploadCv',
      icon: 'upload',
      description: 'Quand un candidat upload son cv depuis une plateforme d\'opportunités',
      visible: true,
      pictureUrl: ({ _coder }) => _coder.pictureUrl,
      header: ({ _coder }) => {
        const coderLink = getLink(_coder._id, 'coders');
        return `<router-link tag="b" to="${coderLink}">${getFullName(_coder, 'coder')}</router-link> {{$t('timeline.candidate-upload-cv')}}</router-link>`;
      },
    },
    232: {
      name: 'coderApplyJob',
      icon: 'send',
      description: 'Quand un candidat postule depuis une plateforme d\'opportunités',
      visible: true,
      pictureUrl: ({ _coder }) => _coder.pictureUrl,
      source: ({ data }) => data.source !== 'career' && data.source,
      header: ({ _coder, _customJob, _company, data }) => {
        const coderLink = getLink(_coder._id, 'coders');
        let label = `<router-link tag="b" to="${coderLink}">${getFullName(_coder, 'coder')}</router-link> {{$t('timeline.apply-to')}} <span style="font-weight: bold">${_customJob.title}</span> {{ $t('timeline.among') }} <span style="font-weight: bold">${_company.name}</span>`;
        if (data.source && data.source !== 'career') {
          label += ` {{ $t('generics.from') }} ${data.source.toUpperCase()}`;
        }
        return label;
      },
      html: ({ data }) => {
        let text = '';
        if (data.motivationText) {
          text += removeTags(data.motivationText);
        }
        if (data.social) {
          text += '<br /><br />';
          text += '<ul>';
          text += Object.keys(data.social).map(social => {
            return `<li>${social}: <a style="color: #3C80F7;" href="${removeTags(data.social[social])}" target="_blank">${removeTags(data.social[social])}</a></li>`;
          }).join('');
          text += '</ul>';
        }
        return text;
      },
    },
    250: {
      name: 'coderCreatedFromExtension',
      icon: 'user-check',
      description: 'Quand un candidat est créé depuis l\'extension',
      visible: true,
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header: ({ _coder, _coach, data }) => {
        const coderLink = getLink(_coder._id, 'coders');
        const coachLink = insertIdForLink(_coach._id, getLink(_coach._id, 'coaches'));

        return `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> {{ $t('timeline.created') }} <router-link tag="b" to="${coderLink}">${getFullName(_coder, 'coder')}</router-link> ${i18n.t('timeline.from-source', { source: data || 'Linkedin' })}`;
      },
    },
    255: {
      name: 'coderUpdatedFromParsedResume',
      icon: 'magic-wand',
      description: 'Quand un candidat est mis à jour depuis un parse cv',
      visible: true,
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header: ({ _coder, _coach, data }) => {
        const coderLink = getLink(_coder._id, 'coders');
        const coachLink = insertIdForLink(_coach._id, getLink(_coach._id, 'coaches'));

        return `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> ${i18n.t('timeline.enriched-from-source', { candidate: `<router-link tag="b" to="${coderLink}">${getFullName(_coder, 'coder')}</router-link>`, source: 'document' })}`;
      },
    },
    256: {
      name: 'coderCreatedWithRGPD',
      icon: 'lock',
      description: 'Quand un candidat est créé depuis l\'extension avec consentement RGPD',
      visible: true,
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header: ({ _coder, _coach, data }) => {
        const coderLink = getLink(_coder._id, 'coders');
        const coachLink = insertIdForLink(_coach._id, getLink(_coach._id, 'coaches'));

        return `<router-link tag="b" to="${coachLink}">${getFullName(_coach, 'coach')}</router-link> {{ $t('timeline.created') }} <router-link tag="b" to="${coderLink}">${getFullName(_coder, 'coder')}</router-link> ${i18n.t('timeline.from-source', { source: data || 'Linkedin' })} ${i18n.t('timeline.with-consent')}`;
      },
    },
    257: {
      name: 'companyMerged',
      icon: 'merge',
      description: 'Quand une entreprise a été mergée avec une autre',
      visible: true,
      pictureUrl: ({ _coach }) => _coach.pictureUrl,
      header: ({ _company, _coach, data }) => {
        const companyLink = insertIdForLink(_company._id, getLink(_company._id, 'companies'));
        const mergeCompanyName = data?.name || '{{ $t(\'generics.a-company\') }}';

        return `${mergeCompanyName} {{ $t('timeline.was-merged') }} {{ $t('generics.with') }} <router-link tag="b" to="${companyLink}">${_company.name}</router-link>`;
      },
    },
  },
  {
    301: {
      name: 'syncGmail',
      description: 'Synchro gmail',
      visible: true,
      pictureUrl: ({ _coach, _coder, _contact, _company, data }) => {
        const COACH_TO_PROFILE = 1;
        //const PROFILE_TO_PROFILE = 2;

        let pictureUrl;
        if (data.direction == COACH_TO_PROFILE) {
          pictureUrl = _coach.pictureUrl;
        } else {
          if (_coder) {
            pictureUrl = _coder.pictureUrl;
          } else if (_contact && _contact.pictureUrl) {
            pictureUrl = _contact.pictureUrl;
          } else if (_company && _company.logoUrl) {
            pictureUrl = _company.logoUrl;
          }
        }
        return pictureUrl;
      },
      header: ({ _coach, _coder, _contact, data }) => {
        const COACH_TO_PROFILE = 1;
        //const PROFILE_TO_PROFILE = 2;

        let senderName;
        let senderLink;

        let receiverName;
        let receiverLink;

        if (data.direction == COACH_TO_PROFILE) {
          senderName = getFullName(_coach, 'coach');
          senderLink = insertIdForLink(_coach._id, getLink(_coach._id, 'coaches'));
          if (_coder) {
            receiverName = getFullName(_coder, 'coder');
            receiverLink = insertIdForLink(_coder._id, getLink(_coder._id, 'coders'));
          } else {
            receiverName = getFullName(_contact, 'contact');
            receiverLink = insertIdForLink(_contact._id, getLink(_contact._id, 'contacts'));
          }
        } else {
          if (_coder) {
            senderName = getFullName(_coder, 'coder');
            senderLink = insertIdForLink(_coder._id, getLink(_coder._id, 'coders'));
          } else {
            senderName = getFullName(_contact, 'contact');
            senderLink = insertIdForLink(_contact._id, getLink(_contact._id, 'contacts'));
          }
          receiverName = getFullName(_coach, 'coach');
          receiverLink = insertIdForLink(_coach._id, getLink(_coach._id, 'coaches'));
        }
        return `<router-link tag="b" to="${senderLink}">${senderName}</router-link> {{$t('timeline.mail-send')}} <router-link tag="b" to="${receiverLink}">${receiverName}</router-link>`;
      },
      headerHtml: ({ _coach, _coder, _contact, data }) => {
        const COACH_TO_PROFILE = 1;
        //const PROFILE_TO_PROFILE = 2;

        let senderName;
        let senderEmailAddress;

        let receiverName;
        let receiverEmailAddress;

        if (data.direction == COACH_TO_PROFILE) {
          senderName = getFullName(_coach, 'coach');
          senderEmailAddress = _coach.email;
          if (_coder) {
            receiverName = getFullName(_coder, 'coder');
            receiverEmailAddress = _coder.email;
          } else {
            receiverName = getFullName(_contact, 'contact');
            receiverEmailAddress = _contact.email;
          }
        } else {
          if (_coder) {
            senderName = getFullName(_coder, 'coder');
            senderEmailAddress = _coder.email;
          } else {
            senderName = getFullName(_contact, 'contact');
            senderEmailAddress = _contact.email;
          }
          receiverName = getFullName(_coach, 'coach');
          receiverEmailAddress = _coach.email;
        }

        let text = `<span style="display: inline"><b style="font-size: 14px; font-weight: 700;">${senderName}</b> &lt${senderEmailAddress}&gt</span><br>`;
        text += `<span style="display: inline">À: <b style="font-size: 14px; font-weight: 700;">${receiverName}</b> &lt${receiverEmailAddress}&gt</span><br>`;
        return text;
      },
      html: ({ data, _id }) => {
        const convertedHTML = markdownToHTML(data.body).replaceAll(/\n/g, '<br/>').replaceAll('[image: image]', '');
        let formatedHTML = convertedHTML;

        const hasResponses = convertedHTML.includes('<blockquote>');
        if (hasResponses) {
          formatedHTML = convertedHTML.replace('<blockquote>', '<blockquote style="display: none;">');
        }

        let text = `
          <span style="display: inline;">
            <b style="font-size: 16px; font-weight: 700;">
              ${i18n.t('timeline.mail-subject')}
            </b> : ${data.subject}<br><br>
          </span>`;
        text += `<div id="html-${_id}" data-seemore="false" style='flex-direction: column;'>${formatedHTML}</div>`;
        if (hasResponses) {
          const onClickHandler = `
            const el = document.getElementById('html-${_id}');
            const seemore = el.getAttribute('data-seemore');
            const i = createElement('i');
            i.style = 'font-size: 8px; margin-left: 5px;';
            if (seemore === 'false') {
              el.children[el.children.length - 1].style = 'display: block;';
              el.setAttribute('data-seemore', 'true');
              i.classList.add('icon-chevron-up');
              this.innerHTML = '${i18n.t('timeline.see-less')} ' + i.outerHTML;
            }
            if (seemore === 'true') {
              el.children[el.children.length - 1].style = 'display: none;';
              el.setAttribute('data-seemore', 'false');
              i.classList.add('icon-chevron-down');
              this.innerHTML = '${i18n.t('timeline.see-more')} ' + i.outerHTML;
            }
          `;

          text += `
            <button
              onclick="${onClickHandler}"
              style="width: fit-content; font-weight: 400; font-size: 14px; border-bottom: 1px solid #1D395E; align-items: center;">
              ${i18n.t('timeline.see-more')} <i style="font-size: 8px; margin-left: 5px;" class="icon-chevron-down"></i>
            </button>
          `;
        }

        return text;
      },
    },
    302: {
      name: 'syncOutlook',
      description: 'Synchro outlook',
      visible: true,
      pictureUrl: ({ _coder }) => _coder.pictureUrl,
      header: () => {
        return `TEST SYNC OUTLOOK`;
      },
    },
  },
];
