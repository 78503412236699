<template>
  <bc-dropdown
    class="card-industries is-secondary is-full-width"
    position="bottom-center">
    <template #label="{ isDropdownOpen }">
      <div
        class="card-industries__trigger is-full-width is-column is-overflow-hidden border-radius-m is-relative">
        <div class="card-industries__container is-full-width is-align-items-start is-relative">
          <div
            class="card-industries__wrapper is-full-width is-column card-industries__id is-align-items-center is-justify-content-center">
            <div class="is-secondary-dark is-align-items-center is-full-width is-justify-content-center">
              <div class="card-industries__name is-column is-align-items-center">
                <p
                  v-tooltip="cardContent.name"
                  class="card-industries__text font-weight-bold font-size-m is-ellipsis is-full-width">
                  {{ cardContent.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #custom>
      <div
        class="card-industries__options is-column is-full-width">
        <button
          class="card-industries__link font-size-s"
          @click="$emit('go-to-edit-industry')">
          {{ $t('companies.panel-company.edit-industry') }}
        </button>
        <button
          class="card-industries__link font-size-s"
          @click="$emit('go-to-link-domains-and-professions')">
          {{ $t('companies.panel-company.link-domains-and-professions') }}
        </button>
      </div>
    </template>
  </bc-dropdown>
</template>

<script>
  import PanelNavigationMixin from '@/common-old/mixins/Panel/panelNavigation';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';

  export default {
    name: 'card-industries',
    components: { BcDropdown },
    mixins: [PanelNavigationMixin],
    props: {
      cardContent: {
        type: Object,
        default: () => ({}),
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card-industries {
    cursor: pointer;
    color: $color-secondary;

    &__container {
      padding: 15px;
      flex-direction: column;
      transition: 0.3s;

      &:hover {
        background: rgba($color-primary, 0.05);
      }

      @include bp("tablet") {
        flex-direction: row;
      }
    }

    &__wrapper {
      max-height: 100%;
      min-height: 20px;
      width: 100%;
    }

    &__name {
      max-width: calc(100% - 50px);
    }

    &__options {
      width: 100%;
      padding: 5px 0 5px 40px;
      font-size: $font-size-s;
      position: relative;
      background: $color-white;
      box-shadow: 0 3px 6px #00000029;
      min-width: 210px;

      &:before {
        content: '';
        position: absolute;
        width: 1px;
        margin: 0 auto;
        top: 12.5px;
        height: calc(100% - 25px);
        background: rgba($color-secondary, 0.4);
        left: 20px;
      }
    }

    &__link {
      color: $color-secondary;
      padding: 5px;
      cursor: pointer;

      &--error {
        color: $color-error;
      }

      &:hover {
        color: $color-marvin;
      }
    }
  }
</style>
