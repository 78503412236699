<template>
  <button
    :class="{ 'bc-switcher--active': value || $attrs.checked }"
    :style="switcherStyle"
    @click="toggleValue($event)"
    class="bc-switcher"
    name="button"
    ref="buttonCheckbox"
    type="button"
    v-bind="$attrs">
    <input
      :checked="value || $attrs.checked"
      :disabled="$attrs.disabled"
      class="bc-switcher__input"
      ref="inputCheckbox"
      type="checkbox"
      v-on="listeners">
    <span
      :style="circleStyle"
      class="bc-switcher__circle">
    </span>
  </button>
</template>

<script>
  import colors_helpers from '@/legacy/ui-kit/utils/colors_helpers';

  export default {
    name: 'legacy-bc-switcher',
    inheritAttrs: false,
    props: {
      value: {
        type: Boolean
      },
      bcBackgroundColorActive: {
        default: 'blue-dodger',
        type: String
      },
      bcBackgroundColorOpacityActive: {
        type: Number,
        default: 1
      },
      bcBackgroundColorInactive: {
        default: 'blue-dark-cello',
        type: String
      },
      bcBackgroundColorOpacityInactive: {
        type: Number,
        default: 0.2
      }
    },
    data: () => ({
      checkboxClicked: false
    }),
    computed: {
      switcherStyle() {
        return {
          background: this.value ? colors_helpers.$_checkValidColor(this.bcBackgroundColorActive, this.bcBackgroundColorOpacityActive) : colors_helpers.$_checkValidColor(this.bcBackgroundColorInactive, this.bcBackgroundColorOpacityInactive)
        };
      },
      circleStyle() {
        return {};
      },
      listeners() {
        return {
          ...this.$listeners,
          input: evt => {
            this.$emit('input', evt.target.checked);
          }
        };
      }
    },
    methods: {
      toggleValue(event) {
        // this.$emit('input', !this.value);
        this.$emit('change', event);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .bc-switcher {
    border-radius: 10px;
    border: 0;
    min-width: 36px;
    height: 20px;
    position: relative;
    cursor: pointer;
    transition: all .2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    &:disabled {
      cursor: default;
      pointer-events: none;
    }
    &:active:not(.bc-switcher__circle) {
      .bc-switcher__circle {
        width: 20px;
        border-radius: 15px;
      }
    }
    &--active {
      &:active {
        .bc-switcher__circle {
          width: 20px;
          margin-left: calc(100% - 24px);
        }
      }
      .bc-switcher__circle {
        margin-left: calc(100% - 20px);
      }
    }
    &__circle {
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      background: rgb(255, 255, 255);
      left: 2px;
      border-radius: 50%;
      transition: all .25s ease;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
    }
    &__input {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      cursor: pointer;
    }
  }
</style>
