import { http } from '@/api/index';

export const getConfiguration = () => {
  return http.get('/emails/configuration');
}

export const getProviders = () => {
  return http.get('/emails/providers');
}

export const getStrategies = () => {
  return http.get('/emails/strategies');
}

export const getEmailsAutocomplete = (query) => {
  return http.get('/emails/autocomplete', {
    params: {
      query,
    }
  })
}

export const getTemplates = ({ coachId, all } = {}) => {
  return http.get('/emails/templates', {
    params: {
      coachId,
      all,
    }
  });
}

export const getTemplatesVariables = () => {
  return http.get('/emails/templates/variables');
}

export const postTemplatesVariable = ({ slug, name, source }) => {
  return http.post('/emails/templates/variables', {
    slug,
    name,
    source,
  });
}

export const updateTemplatesVariable = ({ id, slug, name, source }) => {
  return http.patch(`/emails/templates/variables/${id}`, {
    slug,
    name,
    source,
  });
}

export const getTemplatePreview = ({ id, coachId, coderId, customJobId, companyId }) => {
  return http.get(`/emails/templates/${id}/preview`, {
    params: {
      coachId,
      coderId,
      customJobId,
      companyId,
    }
  })
}

export const getVariablesPreview = ({ coachId, coderId, customJobId, companyId } = {}) => {
  return http.get(`/emails/variables/preview`, {
    params: {
      coachId,
      coderId,
      customJobId,
      companyId,
    }
  })
}

export const createOrUpdateConfiguration = ({
  emailSenderStrategyId,
  emailFetcherStrategyId,
  fullname,
  email,
  password,
  imap_host,
  imap_port,
  imap_ssl,
  smtp_host,
  smtp_port,
  smtp_ssl,
  signature,
  verified,
}) => {
  return http.post('/emails/configuration', {
    emailSenderStrategyId,
    emailFetcherStrategyId,
    fullname,
    email,
    password,
    imap_host,
    imap_port,
    imap_ssl,
    smtp_host,
    smtp_port,
    smtp_ssl,
    signature,
    verified,
  });
}

export const updateConfiguration = ({
  emailSenderStrategyId,
  emailFetcherStrategyId,
  fullname,
  email,
  password,
  imap_host,
  imap_port,
  imap_ssl,
  smtp_host,
  smtp_port,
  smtp_ssl,
  signature,
  verified,
}) => {
  return http.patch('/emails/configuration', {
    emailSenderStrategyId,
    emailFetcherStrategyId,
    fullname,
    email,
    password,
    imap_host,
    imap_port,
    imap_ssl,
    smtp_host,
    smtp_port,
    smtp_ssl,
    signature,
    verified,
  });
}

export const verifyConfiguration = () => {
  return http.post(`/emails/configuration/verify`);
}

export const disableConfiguration = () => {
  return http.post(`/emails/configuration/disable`);
}

export const createTemplate = ({
  name,
  senderName,
  subject,
  body,
  cc,
  custom,
  attachments,
}) => {
  return http.post('/emails/template', {
    name,
    senderName,
    subject,
    body,
    cc,
    custom,
    attachments,
  });
}

export const editTemplate = ({
  id,
  name,
  senderName,
  subject,
  body,
  cc,
  custom,
  activated,
  attachments,
}) => {
  return http.patch(`/emails/template/${id}`, {
    name,
    senderName,
    subject,
    body,
    cc,
    custom,
    activated,
    attachments,
  });
}

export const deleteTemplate = ({
  id,
}) => {
  return http.delete(`/emails/template/${id}`);
}

export const createHistory = ({
  coderId,
  actionId,
  emailTemplateId,
  emailTemplateSlug,
  customJobId,
  companyId,
  noteId,
  overrideEmail,
  attachments,
} = {}) => {
  return http.post('/emails/history', {
    coderId,
    ...actionId && { actionId },
    emailTemplateId,
    emailTemplateSlug,
    customJobId,
    companyId,
    noteId,
    overrideEmail,
    attachments,
  });
}

export const sendMail = ({ historyId }) => {
  return http.post(`/emails/history/${historyId}/send`);
}

export const sendMailGroup = ({ group, groupType, attachments, template, isContact, companies, isAsync }) => {
  return http.post(`/emails/send-group`, {
    group,
    groupType,
    attachments,
    template,
    isContact,
    companies,
    isAsync,
  });
}
