<template>
  <bc-modal
    active
    class="token-modal is-align-items-center is is-justify-content-center">
    <div class="token-modal__container is-column is-justify-content-center is-align-items-center">
      <h2 class="token-modal__title font-size-xl is-secondary is-text-align-center">
        Vos accès ont expirés ou sont invalides
      </h2>
      <h3 class="token-modal__text font-weight-medium font-size-m is-secondary is-text-align-center">
        Vous allez maintenant être redirigé vers la page de connexion
      </h3>
      <bc-button
        class="mt-5"
        @click="goToLoginPage">
        Aller vers la page de connexion
      </bc-button>
    </div>
  </bc-modal>
</template>

<script>
  import BcModal from '@/ui-kit/components/BcModal/BcModal';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';

  export default {
    name: 'token-modal',
    components: { BcButton, BcModal },
    methods: {
      goToLoginPage() {
        this.$store.dispatch('user/cleanProfile');
        this.$store.dispatch('token/setIsExpired', false);

        this.$router.replace('/auth/login');
      },
    },
    mounted() {
      setTimeout(() => {
        this.goToLoginPage();
      }, 5000);
    },
  };
</script>

<style lang="scss" scoped>
  .token-modal {
    position: fixed;
    height: 100vh;
    width: 100vw;

    &__container {
      padding: 2em;
    }

    &__text {
      max-width: 350px;
    }
  }
</style>
