<template>
  <div
    v-if="!isActiveFilter"
    class="searchbar-tags-buttons">
    <searchbar-buttons-reset
      @on-reset="emitOnReset"
      @on-delete="emitDelete">
    </searchbar-buttons-reset>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import SearchbarButtonsReset from '@/components/Searchbar/SearchbarButtons/SearchbarButtonsReset';

  export default {
    name: 'searchbar-tags-buttons',
    components: { SearchbarButtonsReset },
    computed: {
      ...mapState({
        isActiveFilter: state => state.searchbar.isActiveFilter
      })
    },
    methods: {
      emitDelete() {
        this.$emit('on-delete');
      },
      emitOnReset() {
        this.$emit('on-reset');
      },
    },
  };
</script>

<style scoped lang="scss">
  .searchbar-tags-buttons {
    flex-direction: column;
    align-items: center;
    @include bp('tablet') {
      flex-direction: row;
      justify-content: space-around;
    }
    &__button {
      max-width: 220px;
      padding: 10px 20px;
      margin-bottom: 10px;
      &:last-of-type {
        margin-bottom: 0;
      }
      @include bp('tablet') {
        margin-bottom: 0;
      }
    }
  }
</style>
