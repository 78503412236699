import { myGardenTag } from '@/macros/favoriteLists/favoriteLists';

export default {
  methods: {
    updateMyGardenNotificationsStatus({ value, userId, lists }) {
      const myGardenIndex = lists.findIndex(list => list.tag === myGardenTag);
      const myGardenList = lists[myGardenIndex];
      const subscriberToUpdateIndex = myGardenList.subscribers.findIndex(subscriber => subscriber?._coach?._id === userId);
      const listsUpdated = lists;

      listsUpdated[myGardenIndex].subscribers[subscriberToUpdateIndex].notifications = value;

      this.$store.dispatch('user/updateProfile', {
        _lists: listsUpdated,
      });
    },
    updateCardNotificationsStatus({ index, subscribersFiltered, subscriberToUpdate, notificationsStatus }) {
      this.$store.dispatch('updateCard', {
        index,
        content: {
          subscribers: [
            ...subscribersFiltered,
            subscriberToUpdate,
          ],
        },
      });

      const toast = notificationsStatus ? 'Notifications activées sur la liste' : 'Notifications désactivées sur la liste';

      this.$toast.show({
        message: toast,
        type: 'success',
      });
    },
  },
};
