<template>
  <div
    class="bc-input-phone border-radius-s is-relative is-column is-justify-content-center"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
    @keydown.left.right.stop
  >
    <div class="bc-input-phone__wrapper is-relative">
      <label
        v-if="$attrs.placeholder"
        :class="{
          'bc-input-phone__label--hover' : isHover,
          'bc-input-phone__label--disabled--hover' : isHover && $attrs.disabled,
          'bc-input-phone__label--focus font-size-xs' : isFocus || value || phone || (!phoneObject.isValid && touched) || !labelPlaceholder,
        }"
        :disabled="$attrs.disabled"
        class="bc-input-phone__label is-absolute font-size-m is-top is-bottom is-align-items-center"
        for="phone">
        <i
          v-if="icon"
          :class="[
            `icon-${icon}`,
            { 'font-size-xs': isFocus }
          ]"
          class="bc-input-phone__label-icon is-flex is-align-items-center">
        </i>
        {{ $attrs.placeholder }}
        <span
          v-if="required"
          class="bc-input-phone__label-required">
          *
        </span>
      </label>
      <bc-dropdown
        :disabled="$attrs.disabled"
        :emit-object="true"
        :options="countries"
        class="bc-input-phone__dropdown-wrapper"
        @change="setActiveCountry">
        <template #label>
          <div
            :class="{
              'bc-input-phone__dropdown--focus': isFocus || isDropdownOpen && !$attrs.disabled,
              'bc-input-phone__dropdown--hover': isHover && !$attrs.disabled,
              'bc-input-phone__dropdown--disabled': $attrs.disabled,
              'bc-input-phone__dropdown--complete': phone && !$attrs.disabled,
            }"
            class="bc-input-phone__dropdown is-relative">
            <p class="bc-input-phone__country is-flex font-size-xs">
              {{ activeCountry.iso2 }}
            </p>
            <i
              :class="{
                'icon-chevron-down': !isDropdownOpen,
                'icon-chevron-up': isDropdownOpen,
              }"
              class="bc-input-phone__icon is-flex is-align-items-center">
            </i>
          </div>
        </template>
        <template #option="{ option, index, activeIndex }">
          <bc-dropdown-item
            :info="`+${option.dialCode}`"
            :is-focus="activeIndex === index"
            :text="option.name"
            class="bc-input-phone__option is-expanded"
            @click.native="setActiveCountry(option)">
          </bc-dropdown-item>
        </template>
      </bc-dropdown>
      <input
        id="phone"
        ref="input"
        v-model="formattedPhone"
        :class="{
          'bc-input-phone__input--error': !phoneObject.isValid && touched && phone.length > 0 || error,
          'bc-input-phone__input--success': phoneObject.isValid,
          'bc-input-phone__input--label-placeholder': labelPlaceholder,
        }"
        class="bc-input-phone__input border-radius-s font-size-m"
        type="tel"
        v-bind="$attrs"
        @blur="isFocus = false"
        @focus="isFocus = true">
      <i
        v-if="phoneObject.isValid || !phoneObject.isValid && touched || error"
        :class="{
          'bc-input-phone__validate--success icon-check': phoneObject.isValid,
          'bc-input-phone__validate--error icon-cross': !phoneObject.isValid && touched && phone.length > 0 || !phoneObject.isValid && touched && required || error,
        }"
        class="bc-input-phone__validate is-flex is-align-items-center is-absolute"
        @mouseup="setFocus">
      </i>
    </div>
    <p
      v-if="!phoneObject.isValid && touched && phone.length > 0 || error"
      class="bc-input-phone__error font-size-xs is-column">
      {{ error }}
    </p>
  </div>
</template>

<script>
  import PhoneNumber from 'awesome-phonenumber';
  import countries from './countries';
  import BcDropdown from '../BcDropdown/BcDropdown';
  import BcDropdownItem from '../BcDropdown/BcDropdownItem';

  export default {
    name: 'bc-input-phone',
    components: { BcDropdownItem, BcDropdown },
    inheritAttrs: false,
    props: {
      /**
       * The value of the input
       */
      value: {
        type: String,
        default: '',
      },
      /**
       * The name of the input
       */
      name: {
        type: String,
        required: true,
      },
      /**
       * The icon of the label
       */
      icon: {
        type: String,
        default: '',
      },
      /**
       * Set the input as required
       */
      required: {
        type: Boolean,
        default: false,
      },
      error: {
        type: String,
        default: '',
      },
      labelPlaceholder: {
        type: Boolean,
        default: true,
      },
    },
    mounted() {
      const code = PhoneNumber(this.phone).getRegionCode();
      if (code) {
        this.activeCountry = this.findCountry(code) || this.activeCountry;
      }
    },
    data() {
      return {
        isFocus: false,
        isHover: false,
        isDropdownOpen: false,
        activeCountry: {
          iso2: 'FR',
        },
        phone: this.value?.trim() || '',
        touched: false,
        countries,
      };
    },
    computed: {
      formattedPhone: {
        get() {
          return this.phoneObject.valid ? this.phoneObject?.number?.international : this.phoneObject?.number?.input || '';
        },
        set(value) {
          this.phone = value;
        },
      },
      phoneObject() {
        const result = PhoneNumber(this.phone, this.activeCountry.iso2).toJSON();

        Object.assign(result, {
          isValid: result.valid,
          country: this.activeCountry,
        });

        return result;
      },
      phoneText() {
        let key = 'input';

        if (this.phoneObject.valid) {
          key = 'international';
        }

        return this.phoneObject.number[key] || '';
      },
    },
    watch: {
      'phoneObject.valid'(value) {
        if (value) {
          /**
           * watch phoneObject.valid -> Emit that the input is valid
           *
           * @event valid
           */
          this.$emit('valid');
          this.phone = this.phoneText;
        } else {
          /**
           * watch phoneObject.valid -> Emit that the input has an error
           *
           * @event error
           */
          this.$emit('error');
        }
      },
      value() {
        this.phone = this.value;

        if (this.phoneObject.valid) {
          this.$emit('valid');
        } else {
          this.$emit('error');
        }
      },
      phone(newValue, oldValue) {
        if (!this.testCharacters()) {
          this.$nextTick(() => {
            this.phone = oldValue;
          });
        } else if (newValue) {
          if (newValue[0] === '+') {
            const code = PhoneNumber(newValue).getRegionCode();
            if (code) {
              this.activeCountry = this.findCountry(code) || this.activeCountry;
            }
          }

          this.onInput();
        } else {
          this.$emit('input', '');
        }
      },
    },
    methods: {
      setActiveCountry(country) {
        this.activeCountry.iso2 = country.iso2;
      },
      closeDropdown() {
        this.isDropdownOpen = false;
      },
      findCountry(iso = '') {
        return countries.find(country => country.iso2 === iso.toUpperCase());
      },
      onInput() {
        this.touched = true;

        if (!this.testCharacters()) {
          return;
        }
        // Returns response.number to assign it to v-model (if being used)
        // Returns full response for cases @input is used
        // and parent wants to return the whole response.

        /**
         * input -> Emit the phone value, phone object (response from awesome-phonenumber)
         *
         * @event input
         * @type {number, object}
         */
        this.$nextTick(() => {
          this.$emit('input', this.phoneText, this.phoneObject);
        });
      },
      testCharacters() {
        const re = /^[()\-+0-9\s]*$/;

        return re.test(this.phone);
      },
      setFocus() {
        this.$refs.input.focus();
      },
    },
  };
</script>

<style lang=scss scoped>
  $color-default: $color-blue-medium;
  $color-hover: $color-secondary;
  $color-focus: $color-primary;
  $color-complete: $color-secondary-dark;

  .bc-input-phone {
    width: 350px;
    margin-top: 15px;

    &__wrapper {
      width: 100%;
    }

    &__dropdown-wrapper {
      height: 100%;

      :deep() {
        .bc-dropdown__input {
          height: 100%;
        }
      }
    }

    &__label {
      color: $color-blue-heavy-dark;
      transition: 0.3s;
      left: 75px;
      cursor: text;

      &--hover {
        color: $color-blue-heavy-dark;
      }

      &--focus {
        color: $color-blue-heavy-dark;
        transform: translate(-65px, -32.5px);
      }

      &--disabled--hover {
        pointer-events: none;
        color: $color-blue-heavy-dark !important;
      }
    }

    &__label-icon {
      margin-right: 5px;
      transition: 0.3s;
    }

    &__label-required {
      color: $color-error;
      margin-left: 5px;
    }

    &__input {
      border: 1px solid $color-default !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
      padding: 0px 10px;
      color: $color-complete;
      flex-grow: 1;
      width: calc(100% - 56.5px);

      &:disabled {
        pointer-events: none;
        background-color: $color-grey-2 !important;
        border-color: $color-default !important;
      }

      &::placeholder {
        color: $color-blue-heavy-dark;
      }

      &:hover {
        border-color: $color-hover;
      }

      &:focus {
        border-color: $color-focus;
        background: rgba($color-focus, 0.05);
        color: $color-focus;
      }

      &--error {
        border-color: $color-error !important;
        background: rgba($color-error, 0.05);

        &:focus {
          background-color: rgba($color-error, 0.05);
        }
      }

      &--success {
        border-color: $color-complete;

        &:focus {
          border-color: $color-success !important;
          background: rgba($color-success, 0.05);
        }
      }

      &--label-placeholder::placeholder {
        opacity: 0;
      }
    }

    &__validate {
      font-size: 14px;
      right: 10px;
      padding: 14px 0;
      cursor: text;

      &--error {
        color: $color-error;
      }

      &--success {
        color: $color-success;
      }
    }

    &__dropdown {
      padding: 12px 10px;
      background: $color-primary;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      color: $color-white;
      height: 100%;

      &:hover {
        cursor: pointer;
      }

      &--disabled {
        pointer-events: none;
      }
    }

    &__icon {
      font-size: 10px;
      margin-left: 10px;
    }

    &__error {
      margin-top: 5px;
      color: $color-error;
    }

    &__option {
      max-width: 300px;
    }
  }
</style>
