<template>
  <mv-button-rounded :title="$t('generics.share')" icon="arrow-up-from-bracket">
    <div class="button-share-job__share">
      <custom-jobs-share-form
        class="mt-3"
        :default-owners="job._owners"
        :is-independant="true"
        @update-value="updateCustomJobShare"
      ></custom-jobs-share-form>
    </div>
    <div class="button-share-job__divider"></div>
    <div
      v-if="configurationFeatures.shareProcesses"
      class="button-share-job__dropdown is-full-width is-column pb-4 pt-4 pl-5 pr-5"
      @click.stop>
      <bc-input :label="$t('generics.share-to-customer')" :value="generatedLink" class="button-share-job__input" name="link" type="text">
        <template #append="{ isFocus }">
          <div
            v-clipboard:copy="generatedLink"
            v-clipboard:success="setIsCopiedLink"
            v-tooltip="{
              content: isCopiedLink
                ? $t('companies.account-management.link-copied')
                : $t('companies.account-management.copy-link'),
              hideOnTargetClick: false,
            }"
            :class="{ 'button-share-job__prepend--focused': isFocus }"
            class="button-share-job__prepend icon-clipboard pr-2 pl-2 is-flex is-align-items-center">
          </div>
        </template>
      </bc-input>
    </div>
  </mv-button-rounded>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BcInput from '@/ui-kit/components/BcInput/BcInput';
import MvButtonRounded from '@/ui-kit/components/MvButtonRounded/MvButtonRounded';
import CustomJobsShareForm from '@/components/CustomJobs/CustomJobsForm/CustomJobsShareForm';
import { Modals } from '@/store/modules/app';

export default {
  name: 'button-share-job',
  components: {
    MvButtonRounded,
    BcInput,
    CustomJobsShareForm,
  },
  props: {
    company: {
      type: Object,
      default: () => ({}),
    },
    job: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isCopiedLink: false,
    };
  },
  computed: {
    ...mapGetters({
      configurationFeatures: 'user/configurationFeatures',
    }),
    generatedLink() {
      const subDomain = window.location.origin.split('.')[0].split('//')[1];
      const replaceDomain = subDomain == "app" ? "partners" : `partners.${subDomain}`;
      let partnerUrl = window.location.origin.replace(subDomain, replaceDomain);
      if (subDomain.includes('localhost')) {
        partnerUrl = 'http://localhost:8086';
      }

      let url = `${partnerUrl}/${this.company.publicToken}`;
      if (this.job._id) {
        url += `/${this.job._id}`;
      }
      return url;
    },
  },
  methods: {
    ...mapActions({
      setModal: 'app/setModal',
    }),
    handleClickGenerateLink() {
      if (!this.configurationFeatures.shareProcesses) {
        this.setModal(Modals['modal-feature-share-processes']);
      }
    },
    setIsCopiedLink() {
      this.isCopiedLink = true;
      setTimeout(() => {
        this.isCopiedLink = false;
      }, 2300);
    },
    updateCustomJobShare(value) {
      this.$emit('update-owners', value);
    }
  },
};
</script>

<style lang="scss" scoped>
.button-share-job {
  &__dropdown {
    width: 100%;
  }

  &__share {
    flex-direction: column;
    max-height: 600px;
    overflow: visible;
  }

  &__divider {
    height: 1px;
    background: $color-blue-medium;
    width: 100%;
  }

  &__prepend {
    cursor: pointer;
    color: $color-tertiary;
    border-left: 1px solid $color-blue-medium;
    height: 100%;
    border-top-right-radius: $border-radius-xs;
    border-bottom-right-radius: $border-radius-xs;
    min-height: 41px;

    &--focused {
      border-color: $color-primary;
    }
  }

  & :deep() {
    .bc-input {
      margin-top: 5px;
    }

    input {
      padding-right: 10px;
      text-overflow: ellipsis;
    }
  }
}
</style>
