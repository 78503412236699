<template>
  <div class="panel-process-details-confirm">
    <p class="panel-process-details-confirm__title">
      {{ $t('candidates.candidate-is-waiting-to-be-presented') }}
    </p>
    <bc-button
      @click.native="confirmProcess"
      class="panel-process-details-confirm__button"
      color="secondary-light"
      type="negative">
      {{ $t('generics.confirm') }}
    </bc-button>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  import BcButton from '@/ui-kit/components/BcButton';

  import { processController } from '@/managers/processes/controller';
  import { processStatus } from '@/macros/processes/process';

  export default {
    name: 'panel-process-details-confirm',
    components: { BcButton },
    methods: {
      ...mapActions([
        'updatePanelProfile',
      ]),
      async confirmProcess() {
        try {
          const updatedProcess = await processController.move(this.$route.params.id, processStatus['introduced']);

          this.updatePanelProfile({ processState: updatedProcess._coder.processState });
          this.$emit('profile-updated', { processState: updatedProcess._coder.processState });
          this.$emit('process-updated', updatedProcess);
          this.$emit('update-timeline');
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-process-details-confirm {
    background: $color-secondary-light;
    border-radius: 2px;
    padding: 15px 20px;

    &__title {
      display: flex;
      align-items: center;
      color: $color-white;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      text-transform: uppercase;

      &:before {
        margin-right: 10px;
      }
    }

    &__button {
      text-transform: uppercase;
      border-radius: $border-radius-xs;
    }
  }
</style>
