<template>
  <filter-content :label="$t('hunt.last-activity')" class="filter-coders-last-activity !px-[20px]">
    <div class="filter-coders-last-activity__wrapper w-full flex-col">
      <bc-slider
        v-model="range"
        :is-input-displayed="false"
        :max="max"
        :min="0"
        :step="1"
        class="filter-coders-last-activity__slider w-full"
        is-revert
        type="range"
        @end-changing="onChange">
        <bc-slider-tick :value="0" class="filter-coders-last-activity__tick">
          {{ $t('hunt.today') }}
        </bc-slider-tick>
        <bc-slider-tick :value="1" class="filter-coders-last-activity__tick">
          {{ $t('hunt.three-months') }}
        </bc-slider-tick>
        <bc-slider-tick :value="2" class="filter-coders-last-activity__tick">
          {{ $t('hunt.six-months') }}
        </bc-slider-tick>
        <bc-slider-tick :value="3" class="filter-coders-last-activity__tick">
          {{ $t('hunt.one-year') }}
        </bc-slider-tick>
        <bc-slider-tick :value="4" class="filter-coders-last-activity__tick">
          {{ $t('hunt.more-than-a-year') }}
        </bc-slider-tick>
      </bc-slider>
    </div>
  </filter-content>
</template>

<script lang="ts" setup>
  import { computed, ref, watch } from 'vue';
  import { format, subMonths } from 'date-fns';
  import BcSlider from '@/ui-kit/components/BcSlider/BcSlider';
  import BcSliderTick from '@/ui-kit/components/BcSlider/BcSliderTick';
  import FilterContent from '@/components/Filter/FilterContent';
  import { Modals } from '@/store/modules/app';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { useStore } from '@/store';
  import { useI18n } from '@/i18n/i18n';

  // Types
  interface Option {
    labelLeft: string;
    labelRight: string;
    labelLast: string;
    query: string | undefined;
  }

  const emit = defineEmits<{
    (e: 'count', payload: { key: string; count: number }): void
  }>();

  const route = useRoute();
  const router = useRouter();
  const store = useStore();
  const { t } = useI18n();

  const range = ref<[number, number]>([0, 4]);

  const formatDate = (date: Date): string => {
    return format(date, 'd-MM-yyyy');
  };

  const options = computed<Option[]>(() => [
    {
      labelLeft: t('hunt.no-limit-determined'),
      labelRight: '',
      labelLast: '',
      query: undefined,
    },
    {
      labelLeft: t('hunt.more-than-3-months'),
      labelRight: t('hunt.less-than-3-months'),
      labelLast: t('hunt.the-last-3-months'),
      query: formatDate(subMonths(new Date(), 3)),
    },
    {
      labelLeft: t('hunt.more-than-6-months'),
      labelRight: t('hunt.less-than-6-months'),
      labelLast: t('hunt.the-last-6-months'),
      query: formatDate(subMonths(new Date(), 6)),
    },
    {
      labelLeft: t('hunt.more-than-1-year'),
      labelRight: t('hunt.less-than-1-year'),
      labelLast: t('hunt.the-last-year'),
      query: formatDate(subMonths(new Date(), 12)),
    },
    {
      labelLeft: '',
      labelRight: t('hunt.no-limit-determined'),
      labelLast: '',
      query: undefined,
    },
  ]);

  const max = computed(() => options.value.length - 1);

  const configurationFeatures = computed(() => store.getters['user/configurationFeatures']);

  const setQuery = (newRange: [number, number]) => {
    const [from, to] = newRange;
    router.push({
      query: {
        ...route.query,
        page: route.query?.page ? route.query.page : 1,
        'last-coach-activity-from': from ? options.value[from]?.query : undefined,
        'last-coach-activity-to': to ? options.value[to]?.query : undefined,
      },
    });
  };

  const setRange = (newRange: [number, number]) => {
    setQuery(newRange);
    emit('count', {
      key: 'last-activity',
      count: newRange[0] !== 0 || newRange[1] !== 4 ? 1 : 0,
    });
    range.value = newRange;
  };

  const onChange = (newRange: [number, number]) => {
    if (!configurationFeatures.value.searchByLastActivity) {
      store.dispatch('app/setModal', Modals['modal-feature-last-activity']);
      return;
    }
    setRange(newRange);
  };

  watch(
    () => route.query,
    (query) => {
      const from = query['last-coach-activity-from'] as string | undefined;
      const to = query['last-coach-activity-to'] as string | undefined;

      const fromRange = options.value.findIndex(opt => from && opt.query === from);
      const toRange = options.value.findIndex(opt => to && opt.query === to);

      if (fromRange !== -1 && toRange !== -1) {
        setRange([fromRange, toRange]);
      } else if (fromRange !== -1) {
        setRange([fromRange, 4]);
      } else if (toRange !== -1) {
        setRange([0, toRange]);
      } else {
        setRange([0, 4]);
      }
    },
    { immediate: true },
  );
</script>

<style lang="scss" scoped>
  .filter-coders-last-activity {
    min-height: 90px;

    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      font-size: $font-size-s;
      justify-content: center;
      line-height: 18px;
      @include bp('tablet') {
        justify-content: flex-start;
        text-align: left;
        margin-bottom: 0;
        width: 85px;
        margin-right: 35px;
      }
    }

    &__wrapper {
      position: relative;
      margin-right: -30px;
      justify-content: center;
      @include bp('tablet') {
        min-width: 305px;
        margin-top: -5px;
      }
    }

    &__text {
      margin-top: 15px;
    }

    &__slider {
      flex-shrink: 0;
      min-height: 34px;
    }

    &__tick {
      :deep() {
        .bc-slider-tick__label {
          min-width: 100px;
          text-align: center;
        }
      }
    }
    
    &:deep(.filter-content__title) {
      @apply pl-[10px] text-xs text-blue-400;
    }
  }
</style>
