import { Candidate } from '@/domains/models/Candidate';
import { Process } from '@/domains/models/Process';
import { useStore } from '@/store';

export const useEmail = () => {
  const store = useStore();

  function setModal(modal) {
    store.dispatch('app/setModal', modal);
  }

  function setParams(params) {
    store.dispatch('app/setParams', params);
  }

  function setIsPanel(isPanel) {
    store.dispatch('app/setIsPanel', isPanel);
  }

  function openEmailPanel(coder: Candidate, process: Process, isCompany: boolean = false) {
    setParams({
      coder,
      customJob: process?._customJob,
      actionId: process?.action?._id,
      emails: [coder.email, ...(coder.emails || [])].filter(Boolean),
      isCompany,
    });
    setIsPanel(true);
    setModal('modal-email-send-form');
  }

  return {
    openEmailPanel,
  };
};
