import * as customJobs from '@/api/custom-jobs';

export const getCustomJobPosts = id => {
  const execute = (id) => {
    try {
      return customJobs.getCustomJobPosts(id);
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    return execute(id);
  } catch (error) {
    throw error;
  }
};

export default {
  getCustomJobPosts,
};
