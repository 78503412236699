<template>
  <div ref="processes-activities" class="processes-activities is-full-width">
    <!-- <div
      class="processes-activities__header is-full-width mb-4">
      <i class="icon-trophy mr-2"></i>
      <p class="processes-activities__title font-weight-bold">
        {{ $t('generics.process-activities') }}
      </p>
    </div> -->
    <div
      ref="processes-activities__container"
      class="processes-activities__container is-column"
    >
      <div v-for="smallWin in smallWins" :key="smallWin.name">
        <bc-avatar
          :src="(smallWin || {}).pictureUrl"
          class="is-unshrink"
          size="s"
          @error="setAlternativeImg($event, smallWin)">
        </bc-avatar>
        <div class="processes-activities__content">
          <component :is="contentComponent(smallWin)"></component>
          <span class="processes-activities__date">{{
            getCreatedDateFromNow(smallWin.createdAt)
          }}</span>
        </div>
      </div>
      <infinite-loading
        :identifier="timelineIdentifier"
        style="justify-content: center;"
        @infinite="getSmallWins"
      >
        <bc-spinner slot="spinner"></bc-spinner>
        <p slot="no-more">
          {{ smallWins.length ? '' : $t('dashboard.small-wins-empty') }}
        </p>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import moment from 'moment/min/moment-with-locales';
  import { getSmallWinsTimeline } from '@/api/timeline';

  import InfiniteLoading from 'vue-infinite-loading';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';

  import timelineSmallWinMacros from '@/managers/timeline/timelineSmallWinMacros';

  export default {
    name: 'processes-activities',
    components: {
      BcAvatar,
      BcSpinner,
      InfiniteLoading,
    },
    data() {
      return {
        currentPage: 1,
        smallWins: [],
        timelineIdentifier: +new Date(),
        timelineSmallWinMacros,
      };
    },
    computed: {
      profile() {
        return this.$store.state.user.profile;
      },
    },
    mounted() {
      const smallWins = this.$refs['processes-activities'];
      if (smallWins?.$el) {
        smallWins.$el.addEventListener('mouseenter', this.onMouseEnter);
        smallWins.$el.addEventListener('mouseleave', this.onMouseLeave);
      }
    },
    beforeDestroy() {
      const smallWins = this.$refs['processes-activities'];
      if (smallWins?.$el) {
        smallWins.$el.removeEventListener('mouseenter', this.onMouseEnter);
        smallWins.$el.removeEventListener('mouseleave', this.onMouseLeave);
      }
    },
    methods: {
      ...mapActions({
        setIsHoverSmallWins: 'app/setIsHoverSmallWins',
      }),
      isScrollable(el) {
        if (!el) {
          return false;
        }
        return el.scrollHeight > el.clientHeight;
      },
      async getSmallWins($state) {
        try {
          try {
            const {
              data: {
                timeline,
                next,
              },
            } = await getSmallWinsTimeline({ page: this.currentPage });
            const mappedActivity = timeline.map(it => timelineSmallWinMacros[it.type] && timelineSmallWinMacros[it.type](it)).filter(it => !!it);
            this.smallWins = [...this.smallWins, ...mappedActivity];
            if (next) {
              $state.loaded();
              this.currentPage++;
            } else {
              $state.loaded();
              $state.complete();
            }
          } catch (error) {
            $state.error();
          }
        } catch (err) {
          this.$toast.show({
            message: this.$t('toast.error-occured'),
            type: 'error',
            icon: 'cross',
          });
        }
      },
      contentComponent(activity) {
        return {
          name: 'processes-activities__content-item',
          template: `<span>${activity.content}</span>`,
        };
      },
      setAlternativeImg(event, smallWin) {
        if (smallWin.isCompany) {
          return event.target.src = require('@/assets/img/default-avatar-company.svg');
        }
        event.target.src = require('@/assets/img/default-avatar.svg');
      },
      onMouseEnter() {
        const container = this.$refs['processes-activities__container'];
        if (this.isScrollable(container)) {
          this.setIsHoverSmallWins(true);
        }
      },
      onMouseLeave() {
        const container = this.$refs['processes-activities__container'];
        if (this.isScrollable(container)) {
          this.setIsHoverSmallWins(false);
        }
      },
      getCreatedDateFromNow(date) {
        return moment(date).fromNow(true);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .processes-activities {
    flex: 1;
    flex-direction: column;
    padding: 0 20px;
    padding-top: 20px;

    &__tooltip {
      cursor: pointer;
    }

    &__date {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $color-blue-heavy-dark;
      min-width: 90px;
      display: flex;
      justify-content: flex-end;
    }

    &__container {
      width: 100%;
      height: 100%;
      overflow: auto;

      & > div {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        align-items: center;
        gap: 10px;
        margin-bottom: 15px;
      }
    }

    &__content {
      flex: 1;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      font-size: 14px;

      &:deep() {
        .link {
          text-decoration: none;
          color: $color-primary;
          font-size: 16px;
          font-weight: 400;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &__loader {
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
    }
  }
</style>
