<template>
  <div
    class="card-list-draggable"
    ref="cardList">
    <slot
      class="card-list-draggable__no-result"
      v-if="!isError && isLoaded && this.cards.length === 0">
    </slot>
    <p
      class="card-list-draggable__no-result"
      v-if="!isError && isLoaded && this.cards.length === 0 && !$slots.default">
      <span class="card-list-draggable__icon icon-search"></span>
      {{ $t('generics.no-result-found') }}
    </p>
    <p
      class="card-list-draggable__error"
      v-if="isError && !$slots.default">
      <span class="card-list-draggable__icon icon-cross"></span>
      {{ $t('companies.panel-company.error-during-request') }}
    </p>
    <draggable
      @change="$emit('list-order-updated', $event)"
      class="card-list-draggable__container"
      v-model="draggableCards">
      <card
        :card-content="card"
        :card-index="index"
        :key="`${card._id}-${index}`"
        card-type="favorite-lists"
        class="card-list-draggable__card"
        v-for="(card, index) in draggableCards">
      </card>
    </draggable>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';
  import Card from '@/components/Card/Card';

  /**
   * This component just do a v-for on the Object he receive. He also contains the infinite scroll.
   */

  export default {
    name: 'card-list-draggable',
    components: {
      Card,
      draggable,
    },
    props: {
      /**
       * The Array that contains all the card
       */
      cards: Array,
      /**
       * The boolean that determine if the Api call is finished
       */
      isLoaded: Boolean,
      /**
       * The boolean that determine if the Api call is error
       */
      isError: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      draggableCards: {
        get() {
          return this.cards;
        },
        set(value) {
          this.$emit('list-updated', value);
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card-list-draggable {
    flex-direction: column;
    width: 100%;

    &__container {
      flex-direction: column;
      width: 100%;
    }

    &__no-result {
      display: flex;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      color: $color-gray-raven;
      margin-top: 10px;
    }

    &__error {
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      color: $color-gray-raven;
      margin-top: 10px;
      display: flex;
    }

    &__icon {
      font-size: $font-size-m;
      margin-right: 5px;
    }

    &__card {
      &:last-of-type {
        margin-bottom: 100px;
      }
    }
  }

  .sortable-ghost {
    opacity: 0;
  }
</style>
