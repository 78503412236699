<template>
  <div class="configuration-my-company">
    <views-tabs :tabs="[]"></views-tabs>
    <div class="configuration-my-company__content">
      <div>
        <bc-input
          class="configuration-my-company__input"
          :label="$t('generics.name-of-my-company')"
          :labelPlaceholder="false"
          name="organizationName"
          v-model="organizationName"
          @input="updateOrganizationName"
          :valid="true"
        ></bc-input>
      </div>
      <div class="is-column">
        <label>{{ $t('generics.logo') }}</label>
        <div class="configuration-my-company__logo" v-if="logoUrl || isUploading">
          <div class="configuration-my-company__logo--loader" v-if="isUploading">
            <bc-spinner></bc-spinner>
          </div>
          <div v-if="logoUrl">
            <img :src="logoUrl" />
            <div>
              <bc-button
                @click="removeLogo"
                iconLeft="trash"
                color="error"></bc-button>
            </div>
          </div>
        </div>
        <upload-container
          v-else
          class="mt-2"
          accept="image/*"
          @on-cropped="handleCroppedImage"
          :supportedFormats="['png', 'jpg', 'jpeg']"
          :useCropper="true"
          :cropperOptions="{
            aspectRatio: 15 / 6,
          }"
          :croppedImageSize="{
            imageSmoothingQuality: 'high',
            width: 600,
            height: 240,
          }">
          <p style="font-size: 12px"> max 200x80px </p>
        </upload-container>
      </div>
      <div class="is-column">
        <label class="mb-2">{{ $t('generics.description-of-my-company') }}</label>
        <bc-text-editor v-model="organizationDescription" @input="updateOrganizationDescription"></bc-text-editor>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce';
import { mapState, mapActions } from 'vuex';
import { upload } from '@/api/upload';

import BcInput from '@/ui-kit/components/BcInput/BcInput';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import BcTextEditor from '@/ui-kit/components/BcTextEditor/BcTextEditor';
import ViewsTabs from '@/components/Views/ViewsTabs';
import UploadContainer from '@/components/UploadContainer/UploadContainer';

export default {
  name: 'configuration-my-company',
  components: {
    ViewsTabs,
    UploadContainer,
    BcInput,
    BcSpinner,
    BcButton,
    BcTextEditor,
  },
  mounted() {
    this.organizationName = this.profile._organization.name;
    this.organizationDescription = this.profile._organization.description;
    this.logoUrl = this.profile._organization.logoUrl;
  },
  data() {
    return {
      organizationName: '',
      organizationDescription: '',
      logoUrl: '',
      isUploading: false,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
    }),
  },
  methods: {
    ...mapActions({
      updateOrganization: 'user/updateOrganization',
    }),
    async removeLogo() {
      try {
        await this.updateOrganization({
          logoUrl: '',
        });
        this.logoUrl = '';
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    updateOrganizationDescription: debounce(async function(description) {
      try {
        await this.updateOrganization({
          description,
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    }, 500),
    updateOrganizationName: debounce(async function(name) {
      try {
        await this.updateOrganization({
          name,
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    }, 500),
    async handleCroppedImage(file) {
      this.isUploading = true;
      try {
        const dataToSend = new FormData();
        dataToSend.append('file', file);
        dataToSend.append('uploadType', 'picture');
        const { data } = await upload({
          id: this.profile._id,
          file: dataToSend,
        });
        this.logoUrl = data.fileUrl;
        this.isUploading = false;
        this.updateOrganization({
          logoUrl: data.fileUrl,
        });
        this.$toast.show({
          type: 'success',
          title: this.$i18n.t('toast.congratulations'),
          message: this.$i18n.t('toast.modifications-registered'),
        });
      } catch (err) {
        this.isUploading = false;
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.configuration-my-company {
  flex-direction: column;
  width: 100%;

  &__content {
    flex-direction: column;
    padding: 30px;
    gap: 20px;

    &:deep() {
      .bc-input__label {
        left: 10px;
        font-size: 14px;
        font-weight: 500;
        color: $color-blue-dark-cello;
      }
    }
  }

  &__input {
    width: 500px;
  }

  label {
    font-size: 14px;
    font-weight: 500;
    color: $color-blue-dark-cello;
  }

  &__logo {
    margin-top: 10px;
    & > div {
      position: relative;
      align-items: center;
      justify-content: space-between;
      width: 405px;
      min-height: 110px;

      & > img {
        border: 1px solid rgba(29, 57, 94, 0.5);
        border-radius: 4px;
        width: 360px;
      }

      & button {
        width: 32px;
        height: 32px;
      }
    }

    &--loader {
      justify-content: center !important;
    }
  }
}
</style>
