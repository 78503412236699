<template>
  <div class="searchbar-filters-item">
    <p class="searchbar-filters-item__title">
      {{ title }}
    </p>
    <div class="searchbar-filters-item__wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'searchbar-filters-item',
  props: {
    title: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.searchbar-filters-item {
  flex-direction: column;
  align-items: center;
  @include bp('tablet') {
    flex-direction: row;
  }

  &__title {
    margin-bottom: 20px;
    font-size: $font-size-s;
    justify-content: center;
    color: $color-blue-dark-cello;
    line-height: 18px;
    @include bp('tablet') {
      justify-content: flex-start;
      text-align: left;
      margin-bottom: 0;
      width: 85px;
      margin-right: 35px;
    }
  }

  &__wrapper {
    width: 100%;
    align-items: center;
  }
}
</style>
