<template>
  <views
    ref="views"
    class="database-coders col-xs-12 is-align-items-center mt-4">
    <views-container>
      <router-view></router-view>
      <SearchbarCoders
        v-if="!isAlgoliaVisible"
        :results-count="queryCount"
        class="database-coders__searchbar"
        coders-searchbar
        @has-filter="hasFilter = $event" />
      <coders-empty
        v-if="!isAlgoliaVisible && isLoaded && cards.length === 0 && !globalMetadata.coders"
        :showCreateButton="!hasFilter"
        :title="
          hasFilter
            ? $t('generics.no-result-found')
            : $t('companies.panel-company.your-candidate-database-is-empty')
        "
        class="database-coders__empty"
        @import="isModalOpen = true">
      </coders-empty>
      <component
        v-else
        class="flex flex-col w-full"
        :container-el="$refs.views"
        :is="isAlgoliaVisible ? 'algolia-results' : 'div'">
        <bc-spinner v-if="!isLoaded" class="database-coders__spinner"> </bc-spinner>
        <div v-else class="is-align-items-center is-column">
          <SearchbarFiltersResult
            v-if="hasFilters"
            class="w-full text-left"
            :label="$tc('generics.candidates-in-db', queryCount)"
            @on-reset="handleReset" />
          <coders-grid :is-checked="isAllCardsSelected" class="hidden-xs" @checked="selectAllCards">
          </coders-grid>
          <card-list ref="cardList" :cards="cards" :is-loaded="true" class="pagination__card-list">
            <template #card="{ card }">
              <card :card-content="card" card-type="coders">
                <card-coders
                  :card-content="card"
                  :is-selected="getIsSelectedStatus(card._id)"
                  context="search-dbcoders"
                  @checked="setSelectedCards($event, card)">
                </card-coders>
              </card>
            </template>
          </card-list>
        </div>
        <bc-pagination
          :current.sync="currentPage"
          :total="queryCount"
          class="database-coders__pagination">
        </bc-pagination>
      </component>
      <multiselect-footer
        v-if="selectedCards.length"
        class="database-coders__footer is-fixed is-bottom is-left is-full-width is-justify-content-center is-align-items-center is-column">
      </multiselect-footer>
    </views-container>
    <bc-modal :active="isModalOpen" @close="isModalOpen = false">
      <database-coders-modal-data-import @close-modal="isModalOpen = false">
      </database-coders-modal-data-import>
    </bc-modal>
  </views>
</template>

<script>
import axios from 'axios';
import { mapActions, mapState } from 'vuex';
import Views from '@/components/Views/Views';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import BcPagination from '@/ui-kit/components/BcPagination';
import CardList from '@/components/CardList/CardList';
import locations_helpers from '@/common-old/utils/locations_helpers';
import salary from '@/common-old/macros/salary';
import moment from 'moment/min/moment-with-locales';
import CodersGrid from '@/components/Coders/CodersGrid';
import CardCoders from '@/components/Card/CardCoders.vue';
import Card from '@/components/Card/Card';
import MultiselectFooter from '@/components/Multiselect/MultiselectFooterLegacy';
import MultiselectMixin from '@/mixins/Multiselect/multiselect';
import ViewsContainer from '@/components/Views/ViewsContainer';
import BcModal from '@/ui-kit/components/BcModal/BcModal';
import DatabaseCodersModalDataImport from '@/components/DatabaseCoders/DatabaseCodersModal/DatabaseCodersModalDataImport';
import CodersEmpty from '@/components/Coders/CodersEmpty';
import CandidatesTabs from '@/views/Tabs/CandidatesTabs';
import AlgoliaResults from '@/components/Algolia/AlgoliaResults.vue';
import SearchbarCoders from '@/components/Searchbar/SearchbarCoders/SearchbarCoders';
import { useLaunchDarklyStore } from '@/store/pinia/launchDarkly';
import { mapStores } from 'pinia';
import SearchbarFiltersResult from '@/components/Searchbar/SearchbarFiltersResult.vue';

export default {
  name: 'database-coders',
  components: {
    CodersEmpty,
    DatabaseCodersModalDataImport,
    BcModal,
    ViewsContainer,
    MultiselectFooter,
    Card,
    CardCoders,
    CodersGrid,
    BcSpinner,
    BcPagination,
    CardList,
    Views,
    CandidatesTabs,
    AlgoliaResults,
    SearchbarCoders,
    SearchbarFiltersResult,
  },
  mixins: [MultiselectMixin],
  data() {
    return {
      isLoaded: true,
      currentPage: parseInt(this.$route.query.page) || 1,
      isModalOpen: false,
      cancelTokenSource: undefined,
      hasFilter: false,
    };
  },
  computed: {
    ...mapState({
      cards: state => state.card.cards,
      sort: state => state.card.sort,
      cardsToRemoveFromList: state => state.card.cardsToRemoveFromList,
      queryCount: state => state.card.count,
      globalMetadata: state => state.user.metadata,
      isRequestProcessing: state => state.card.isRequestProcessing,
      profile: state => state.user.profile,
      resourceCount: state => state.metadata.resourceCount,
    }),
    ...mapStores(useLaunchDarklyStore),
    isSuperUser() {
      return this.$store.getters['user/isSuperUser'];
    },
    salaryRange() {
      return {
        ...(this.$route.query.contracts &&
          this.$route.query.contracts === 'freelance' && {
            salaryMin: salary.salaryMinFilterFreelance,
            salaryMax: salary.salaryMaxFilterFreelance,
          }),
        ...(this.$route.query.contracts &&
          this.$route.query.contracts === 'cdi' && {
            salaryMin: salary.salaryMin,
            salaryMax: salary.salaryMax,
          }),
      };
    },
    filtersQuery() {
      return [
        'professions',
        'professionsNames',
        'technos',
        'technosNames',
        'locations',
        'contracts',
        'phone',
        'email',
        'cv',
        'linkedin',
        'last-coach-activity-from',
        'last-coach-activity-to',
        'rating',
        'currentSalaryMin',
        'currentSalaryMax',
        'salaryWantedMin',
        'salaryWantedMax',
        'search',
        'uniqueids',
        'distance',
        'companies',
      ];
    },
    hasFilters() {
      return Object.keys(this.$route.query)
        .filter(key => this.$route.query[key])
        .some(key => this.filtersQuery.includes(key) || key.startsWith('cf-'));
    },
    hasAlgoliaConfiguration() {
      return !!this.profile?._organization?.configuration?.integrations?.algolia?.credentials;
    },
    isAlgoliaEnabled() {
      return this.launchDarklyStore?.getFlag('enableAlgolia') ?? false;
    },
    isAlgoliaVisible() {
      return this.hasAlgoliaConfiguration && this.isAlgoliaEnabled;
    },
    parameters() {
      const params = {
        page: this.$route.query.page ? this.$route.query.page : 1,
        ...(this.$route.query['job-id'] && { jobId: this.$route.query['job-id'] }),
        ...(this.$route.query.status !== 'all' && { status: this.$route.query.status }),
        ...(this.$route.query.technos && { technos: this.$route.query.technos }),
        ...(this.$route.query.search && { query: this.$route.query.search }),
        ...(this.$route.query.contracts &&
          this.$route.query.contracts !== 'all' && {
            contractCurrent: this.$route.query.contracts,
          }),
        ...(this.$route.query.experiences && { experiences: this.$route.query.experiences }),
        ...(this.$route.query.roles && { roles: this.$route.query.roles }),
        ...(this.$route.query.professions && { professions: this.$route.query.professions }),
        ...(this.$route.query.locations && {
          locations: JSON.stringify(locations_helpers.getLocationsId(this.$route.query.locations)),
        }),
        ...(this.$route.query.distance && { distance: this.$route.query.distance }),
        ...(this.$route.query.remotes && { remotes: this.$route.query.remotes }),
        ...(this.$route.query.companies && { companies: this.$route.query.companies }),
        ...(this.$route.query.phone && { phone: true }),
        ...(this.$route.query.cv && { cv: true }),
        ...(this.$route.query.email && { email: true }),
        ...(this.$route.query.linkedin && { linkedin: true }),
        ...(this.$route.query.rating && { lastRating: Number(this.$route.query.rating) }),
        ...(this.$route.query.salaryType && { salaryType: Number(this.$route.query.salaryType) }),
        ...(this.$route.query.currentSalaryType && {
          currentSalaryType: this.$route.query.currentSalaryType,
        }),
        ...(this.$route.query.currentSalaryMin && {
          currentSalaryMin: this.$route.query.currentSalaryMin,
        }),
        ...(this.$route.query.currentSalaryMax && {
          currentSalaryMax: this.$route.query.currentSalaryMax,
        }),
        ...(this.$route.query.salaryWantedType && {
          salaryWantedType: this.$route.query.salaryWantedType,
        }),
        ...(this.$route.query.salaryWantedMin && {
          salaryWantedMin: this.$route.query.salaryWantedMin,
        }),
        ...(this.$route.query.salaryWantedMax && {
          salaryWantedMax: this.$route.query.salaryWantedMax,
        }),
        ...(this.$route.query.uniqueids && {
          uniqueids: this.$route.query.uniqueids.split(',').map(id => +id),
        }),
        ...(this.$route.query.professionsOperator && {
          professionsOperator: this.$route.query.professionsOperator,
        }),
        ...(this.$route.query.skillsOperator && {
          skillsOperator: this.$route.query.skillsOperator,
        }),
        ...(this.$route.query['last-coach-activity-from'] && {
          lastCoachActivityFrom: moment(
            this.$route.query['last-coach-activity-from'],
            'D-MM-YYYY',
          ).toDate(),
        }),
        ...(this.$route.query['last-coach-activity-to'] && {
          lastCoachActivityTo: moment(
            this.$route.query['last-coach-activity-to'],
            'D-MM-YYYY',
          ).toDate(),
        }),
        ...(this.sort.field !== 'default' &&
          this.sort.direction !== 'default' && {
            sortField: this.sort.field,
            sortDirection: this.sort.direction,
          }),
        salaryMin:
          this.$route.query['salary-min'] &&
          Number(this.$route.query['salary-min']) > this.salaryRange.salaryMin
            ? this.$route.query['salary-min']
            : undefined,
        salaryMax:
          this.$route.query['salary-max'] &&
          Number(this.$route.query['salary-max']) < this.salaryRange.salaryMax
            ? this.$route.query['salary-max']
            : undefined,
      };

      const customFieldsQuery = Object.keys(this.$route.query).filter(key => key.startsWith('cf-'));
      if (customFieldsQuery.length) {
        const obj = {};
        customFieldsQuery.forEach(key => {
          obj[key.split('cf-')[1]] = this.$route.query[key];
        });
        params['customFields'] = JSON.stringify(obj);
      }

      return params;
    },
    metadata() {
      return this.globalMetadata === this.queryCount ? this.globalMetadata : this.queryCount;
    },
  },
  watch: {
    $route: {
      handler(to = {}, from = {}) {
        this.handleQueriesChange(to, from);
      },
      deep: true,
    },
    sort() {
      this.currentPage = 1;
      this.getCoders();
    },
    currentPage(newPage) {
      this.$router.push({ ...this.$route, query: { ...this.$route.query, page: newPage } });

      this.$store.dispatch('setSelectedCards', []);
    },
  },
  created() {
    this.setCount(1);
    this.getCoders();
    this.currentPage = parseInt(this.$route.query.page) || 1;
  },
  beforeDestroy() {
    this.cancelGetCoders();
    this.resetSelectedCards();
  },
  methods: {
    ...mapActions([
      'setEditedProfile',
      'resetCards',
      'getCards',
      'getCountCards',
      'setCount',
      'removeCardFromList',
    ]),
    cancelGetCoders() {
      if (!this.cancelTokenSource) return;
      this.cancelTokenSource.cancel('manualCancel');
    },
    handleSearch(value) {
      this.$router.push({ ...this.$route, query: { ...this.$route.query, q: value } });
    },
    handleReset() {
      const query = { ...this.$route.query, page: 1 };
      for (const key in query) {
        if (this.filtersQuery.includes(key) || key.startsWith('cf-') || key === 'salaryType') {
          Reflect.deleteProperty(query, key);
        }
        if (key === 'companies') {
          Reflect.deleteProperty(query, 'companiesNames');
        }
      }
      this.$router.push({
        query,
      });
    },
    handleQueriesChange(to, from) {
      if (to.query.p) {
        return;
      }
      // const isDifferentQueries = Object.keys(to.query).some(key => from.query[key] !== to.query[key]);
      const isOpeningPanel = Boolean(!(from.params || {}).id && (to.params || {}).id);
      const isClosingPanel = Boolean((from.params || {}).id && !(to.params || {}).id);
      const isInPanel = Boolean((from.params || {}).id && (to.params || {}).id);
      const pageChange = from.query?.page !== to.query?.page;

      if (!isOpeningPanel && !isClosingPanel && (!isInPanel || (isInPanel && pageChange))) {
        this.cancelGetCoders();
        this.getCoders();
        this.currentPage = parseInt(to.query.page) || 1;
      }

      if (isClosingPanel && this.cardsToRemoveFromList.length) {
        const cardsToRemoveFromList = [...this.cardsToRemoveFromList];
        for (const cardToRemoveFromList of cardsToRemoveFromList) {
          this.removeCardFromList(cardToRemoveFromList);
        }
      }
    },
    goNewCoder() {
      this.setEditedProfile({});

      this.$router.push({
        name: `${this.$route.name}Panel`,
        params: {
          id: 'new-coder',
        },
        query: {
          ...this.$route.query,
          type: 'create-coder',
        },
      });
    },
    async getCoders() {
      this.cancelTokenSource = axios.CancelToken.source();
      this.isLoaded = false;
      try {
        this.resetCards();
        const currentPage = parseInt(this.$route.query.page) || 1;
        const response = await this.getCards({
          page: currentPage,
          call: 'coders/lookup',
          parameters: this.parameters,
          responseKeyName: 'coders',
          cancelTokenSource: this.cancelTokenSource,
        });

        this.isLoaded = true;

        if (response.data?.coders.length > 0) {
          const currentCount = response.data?.count;
          if (currentPage == 1 || this.queryCount <= currentCount) {
            await this.getCountCards({
              page: currentPage,
              call: 'coders/lookup/count',
              parameters: this.parameters,
              responseKeyName: 'coders',
              cancelTokenSource: this.cancelTokenSource,
            });
          }
        }
      } catch (error) {
        this.$emit('error', error);

        this.isLoading = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.database-coders {
  @include page_grid();

  &__title {
    min-height: 30px;
  }

  &__subtitle {
    position: relative;
    top: 1px;
    color: #3d5476;
  }

  &__filters {
    margin-bottom: 20px;
  }

  &__pagination {
    margin: 20px 0;
  }

  &__spinner {
    margin: 100px 0;
  }

  &__card-list {
    margin-bottom: 100px;
  }

  &__spinner,
  &__pagination,
  &__card-list {
    justify-content: center;
  }
}
</style>
