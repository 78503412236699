<template>
  <div class="panel-process-create-custom-job">
    <h2 class="panel-process-create-custom-job__title">
      <span class="panel-process-create-custom-job__icon icon-job-position"></span> {{ $tc('generics.opportunity',1) }}
    </h2>
    <spinner v-if="isLoading"></spinner>
    <div
      v-else-if="!isLoading && customJobs.length === 0 && !Object.keys(selectedCustomJob).length"
      class="panel-process-create-custom-job__wrapper is-column is-align-items-center"
    >
      <p v-if="!selectedCompany._id" class="panel-process-create-custom-job__text mb-2">
        {{ $t('process-create.select-company-access-opportunities') }}
      </p>
      <div v-else class="panel-process-create-custom-job__text is-column is-align-items-center mb-2">
        {{ $t('process.empty-opportunity') }}
        <bc-button
          class="panel-process-create-job__button ml-2 mt-4"
          icon-left="opportunities-plus"
          :disabled="!selectedCompany._id"
          @click.stop.native="openCustomJobCreatePanel"
        />
      </div>
    </div>
    <div
      v-else-if="!isLoading && customJobs.length > 0 && !Object.keys(selectedCustomJob).length"
      class="panel-process-create-custom-job__wrapper"
    >
      <div class="panel-process-create-custom-job__search">
        <bc-autocomplete
          class="panel-process-create-custom-job__search--input"
          name="customjob-search"
          v-model="searchCustomJob"
          :open-on-focus="true"
          :is-scrollable="false"
          :options="filtredCustomJobs"
          :label-placeholder="true"
          :label="$t('generics.search-opportunity')"
          @select="onSelect"
          v-bind="$attrs">
          <template v-if="!searchCustomJob" #header>
            <div class="panel-process-create-custom-job__search--header">
              {{ $t('generics.most-viewed') }}
            </div>
          </template>
          <template #option="{ option, isActive }">
            <div
              class="panel-process-create-custom-job__search--item"
              :class="{ 'panel-process-create-custom-job__search--item-active': isActive }"
            >
              {{ option.title }}&nbsp;
              <span v-if="option.salaryMin">
                ({{ option.salaryMin }} - {{ option.salaryMax }} {{ $t('custom-jobs.currency', { type: salaryLabelsShort[option.salaryType] }) }})
              </span>
            </div>
          </template>
          <template #footer>
            <button
              v-if="searchCustomJob"
              class="panel-process-create-company__item is-full-width font-size-m is-secondary is-justify-content-center mx-auto py-3"
              @click="openCustomJobCreatePanel"
            >
              <span class="icon-opportunities-plus mr-2"></span>
              {{ $t('process.create-the-job', { job: searchCustomJob }) }}
            </button>
          </template>
        </bc-autocomplete>
      </div>
    </div>
    <panel-process-create-selected
      v-if="Object.keys(selectedCustomJob).length"
      :show-close-btn="!fromHunt"
      @on-close="$emit('cancel-selected')"
    >
      {{ selectedCustomJob.title }}
    </panel-process-create-selected>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getCustomJobs, salaryLabelsShort } from '@/api/custom-jobs';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcAutocomplete from '@/ui-kit/components/BcAutocomplete';
import Spinner from '@/components/UIKit/Spinner/Spinner';
import PanelProcessCreateSelected from './PanelProcessCreateSelected';

export default {
  name: 'panel-process-create-custom-job',
  components: {
    BcButton,
    BcAutocomplete,
    Spinner,
    PanelProcessCreateSelected,
  },
  props: {
    selectedCustomJob: {
      type: Object,
      default: () => ({})
    },
    selectedCompany: {
      type: Object,
      default: () => ({})
    },
    processes: {
      type: Array,
      default: () => []
    }
  },
  created() {
    this.getCompanyCustomJobs();
  },
  mounted() {
    const selectedCustomJob = sessionStorage.getItem(`selected-custom-job-${this.profileId}`);
    if (selectedCustomJob) {
      this.$emit('on-selected', JSON.parse(selectedCustomJob));
      sessionStorage.removeItem(`selected-custom-job-${this.profileId}`);
    }
  },
  data() {
    return {
      isLoading: false,
      customJobs: [],
      isModalOpen: false,
      salaryLabelsShort,
      searchCustomJob: '',
    };
  },
  computed: {
    ...mapGetters({
      profileId: 'user/profileId',
    }),
    fromHunt() {
      return this.$route.query.from === 'hunt'
    },
    filtredCustomJobs() {
      if (!this.searchCustomJob) {
        let jobs = [...this.customJobs]
        jobs.sort((a, b) => a.nbActiveProcesses - b.nbActiveProcesses);
        if (jobs.length > 5) {
          jobs = jobs.slice(0, 5);
        }
        return jobs;
      }
      return this.customJobs.filter(job => job.title.toLowerCase().includes(this.searchCustomJob.toLowerCase()));
    }
  },
  methods: {
    openCustomJobCreatePanel() {
      this.$router.push({
        ...this.$route,
        params: {
          id: 'new-custom-jobs',
          from: 'panel',
          initData: {
            companyId: this.selectedCompany._id,
            selectedCompany: this.selectedCompany
          }
        },
        query: {
          ...this.$route.query,
          type: 'create-custom-jobs',
          from: 'create-process',
            companyId: this.selectedCompany._id,
        },
      });
    },
    getButtonStyle(id) {
      const style = {
        active: {
          backgroundColor: '#344f74',
          borderOpacity: 0
        },
        inactive: {
          backgroundColor: 'white',
          borderOpacity: 0.3
        }
      };
      return this.selectedCustomJob._id === id ? style.active : style.inactive;
    },
    async getCompanyCustomJobs() {
      if (!this.selectedCompany._id) {
        this.$emit('on-selected', {});
        return (this.customJobs = []);
      }

      this.isLoading = true;

      try {
        const { data } = await getCustomJobs({ companyId: this.selectedCompany._id, active: true, });
        const processesCustomJobsId = this.processes.map(process => (process._customJob || {})._id);
        // We filter the response to avoid creating duplicated processes
        this.customJobs = data.jobs.filter(customJob => !processesCustomJobsId.includes(customJob._id) && !customJob.isSpontaneous);
      } catch (error) {
        this.$toast.show({
          type: 'error',
          message: this.$t('toast.error-occured')
        });
      }

      this.isLoading = false;
    },
    setSelectedCustomJob(opportunity) {
      this.customJobs = [...this.customJobs, opportunity];
      this.$emit('on-selected', opportunity);
      this.isModalOpen = false;

      this.$toast.show({
        type: 'success',
        title: this.$$i18n.t('generics.congratulations'),
        message: `${this.$i18n.t('toast.the-opportunity')} “${opportunity.poste}” ${this.$i18n.t('toast.successfully-created')}`
      });
    },
    onSelect(customJob) {
      this.$emit('on-selected', customJob)
    }
  },
  watch: {
    selectedCompany: function() {
      this.getCompanyCustomJobs();
    },
    isModalOpen(value) {
      if (!value) {
        this.getCompanyCustomJobs();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-process-create-custom-job {
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 15px 0;

  &__modal {
    min-width: 1000px;
    flex-direction: column;
    padding-top: 15px;
  }

  &__title {
    display: flex;
    color: #344f74;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    font-size: $font-size-s;
    line-height: 17px;
    align-items: center;
    margin-bottom: 20px;
  }

  &__icon {
    display: inline-flex;
    font-size: $font-size-l;
    align-items: center;
    margin-right: 10px;
  }

  &__wrapper {
    flex-direction: column;
    width: 100%;
    margin: -2.5px 0;
  }

  &__option {
    width: 100%;
    margin: 2.5px 0;
    justify-content: center;
  }

  &__text {
    font-size: $font-size-s;
    color: $color-tertiary;

    &--black {
      color: $color-black;
    }
  }

  &__button {
    margin-top: 30px;
  }

  &__search {
    align-items: center;

    &--input {
      flex: 1;
      margin: 0;
    }

    &--header {
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      color: $color-blue-dark-cello;
      opacity: 0.7;
      padding: 14px 10px;
    }

    &--item {
      padding: 14px 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $color-blue-dark-cello;

      &-active {
        color: white;
      }
    }
  }
}
</style>
