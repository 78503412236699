<template>
  <label
    class="radio-default">
    <input
      class="radio-default__input"
      ref="radioInput"
      v-bind="$attrs"
      :checked="isActive || $attrs.checked"
      :value="value"
      type="radio"
      v-on="listeners">
    <span
      class="radio-default__wrapper">
      <span
        :style="radioStyle"
        class="radio-default__border">
      </span>
      <span
        :style="circleStyle"
        class="radio-default__circle">
      </span>
    </span>
    <span
      v-if="label"
      class="radio-default__text"
      :class="{'radio-default__text--active': isActive, 'radio-default__text--disabled': $attrs.disabled}">
      {{ label }}
    </span>
  </label>
</template>

<script>
  import colors_helpers from '@/legacy/ui-kit/utils/colors_helpers';

  export default {
    name: 'radio-default',
    inheritAttrs: false,
    props: {
      label: {
        type: String,
        default: ''
      },
      value: {
        type: [Boolean, Number, String],
        default: false
      },
      radioValue: {
        type: [Boolean, Number, String],
        default: ''
      },
      /**
       * The color of the border of the radio
       */
      bcRadioBorderColor: {
        type: String,
        default: 'blue-cadet'
      },
      /**
       * The opacity of the border of the radio
       */
      bcRadioBorderColorOpacity: {
        type: Number,
        default: 1
      },
      /**
       * The color of the background of the radio
       */
      bcRadioBackgroundColor: {
        type: String,
        default: 'blue-dodger'
      },
      /**
       * The color of the background of the radio when inactive
       */
      bcRadioBackgroundColorInactive: {
        type: String,
        default: 'white'
      },
      /**
       * The color of the background of the radio when disabled
       */
      bcRadioBackgroundColorDisabled: {
        type: String,
        default: 'blue-cadet'
      }
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: () => {
            this.$emit('input', this.radioValue);
          },
          change: () => {
            this.$emit('change', this.radioValue);
          }
        };
      },
      isActive() {
        return this.value === this.radioValue;
      },
      radioStyle() {
        return {
          borderColor: this.isActive ? colors_helpers.$_checkValidColor(this.bcRadioBackgroundColor) : colors_helpers.$_checkValidColor(this.bcRadioBorderColor, this.bcRadioBorderColorOpacity),
          ...!this.$attrs.disabled && { background: this.isActive ? colors_helpers.$_checkValidColor(this.bcRadioBackgroundColor, 1) : colors_helpers.$_checkValidColor(this.bcRadioBackgroundColorInactive, 1) },
          ...this.$attrs.disabled && { background: colors_helpers.$_checkValidColor(this.bcRadioBackgroundColorDisabled, 1) }
        };
      },
      circleStyle() {
        return {
          ...this.$attrs.disabled && { background: colors_helpers.$_checkValidColor(this.bcRadioBackgroundColorDisabled, 1) }
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .radio-default {
    align-items: center;
    justify-content: center;
    position: relative;
    &__text {
      font-size: $font-size-s;
      color: $color-blue-cadet;
      margin-left: 10px;
      transition: 0.2s ease;
      cursor: pointer;
      &--active {
        color: $color-blue-dodger;
      }
      &--disabled {
        color: rgba($color-blue-dark-cello, 0.3);
        cursor: initial;
      }
    }
    &__wrapper {
      position: relative;
      display: flex;
      flex-shrink: 0;
      height: 18px;
      width: 18px;
      justify-content: center;
      align-items: center;
    }
    &__border {
      position: absolute;
      border: 1px solid transparent;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      left: 0;
      transition: 0.25s;
      top: 0;
    }
    &__circle {
      height: 35%;
      width: 35%;
      border-radius: 50%;
      position: absolute;
      transition: 0.25s;
      background: $color-white;
      z-index: 1;
    }
    &__input {
      position: absolute;
      left: 0;
      opacity: 0;
      margin: 0;
      width: 18px;
      height: 18px;
      &:disabled {
        + .radio-default__wrapper, .radio-default__text {
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }
  }
</style>
