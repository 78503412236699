<template>
  <div class="industries-modal-link is-column is-align-items-center">
    <p class="industries-modal-link__title font-size-xl mb-4 font-weight-bold">
      Lier des domaines et des professions
    </p>
    <bc-spinner
      v-if="isLoading"
      class="is-justify-content-center"
      size="large">
    </bc-spinner>
    <div class="industries-modal-link__body is-full-width is-column">
      <bc-dropdown
        :options="domains"
        class="industries-modal-link__dropdown"
        is-expanded>
        <template #label="{ isDropdownOpen }">
          <bc-dropdown-trigger
            :is-arrow="true"
            :is-dropdown-open="isDropdownOpen"
            :text="selectedDomain.name"
            class="industries-modal-link__filter"
            placeholder="Sélectionnez un domaine"
          >
          </bc-dropdown-trigger>
        </template>
        <template #option="{ activeIndex, index, option }">
          <bc-dropdown-item
            :is-focus="activeIndex === index"
            :text="option.name"
            @change="selectDomain(option)"
            @click.native="selectDomain(option)">
          </bc-dropdown-item>
        </template>
      </bc-dropdown>
    </div>
    <div
      v-if="selectedDomain._id"
      class="industries-modal-link__tag-wrapper is-flex-wrap">
      <bc-tag
        v-for="profession in professions"
        :key="profession._id"
        :class="{ 'industries-modal-link__tag--active': getProfessionLinkStatus(profession) }"
        class="industries-modal-link__tag"
        @click.native="setDomainToEdit(profession)">
        {{ profession.name }}
      </bc-tag>
    </div>
    <hr class="industries-modal-link__separator mt-5 mb-5 full-width"/>
    <div class="industries-modal-link__wrapper is-full-width is-justify-content-center">
      <bc-button
        class="industries-modal-link__button mr-4"
        data-button-cancel
        type="outlined"
        @click="$emit('close-modal')">
        {{ $t('generics.cancel') }}
      </bc-button>
      <bc-button
        :disabled="!professionsToEdit.length"
        class="industries-modal-link__button"
        color="success"
        data-button-validate
        @click="editProfession">
        Lier les domaines et professions
      </bc-button>
    </div>
  </div>
</template>

<script>
  import { getIndustryDomains, getIndustryProfessions } from '@/api/industries';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import { mapActions, mapGetters } from 'vuex';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
  import BcDropdownTrigger from '@/components/Table/Cell/DropdownOptions';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcTag from '@/ui-kit/components/BcTag/BcTag';
  import { editProfession } from '@/api/professions';

  export default {
    name: 'industries-modal-link',
    components: {
      BcTag,
      BcDropdownItem,
      BcDropdownTrigger,
      BcDropdown,
      BcButton,
      BcSpinner,
    },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        isLoading: true,
        selectedDomain: {},
        domains: [],
        professions: [],
        professionsToEdit: [],
      };
    },
    computed: {
      ...mapGetters([
        'cardActive',
      ]),
    },
    watch: {
      selectedDomain: {
        handler() {
          this.professionsToEdit = [];
        },
        deep: true,
      },
    },
    methods: {
      ...mapActions([
        'updateCard',
      ]),
      selectDomain(domain) {
        this.selectedDomain = domain;
      },
      async getIndustry() {
        this.isLoading = true;

        try {
          const [domains, professions] = await Promise.all([
            getIndustryDomains(this.profile._id),
            getIndustryProfessions(this.profile._id),
          ]);

          this.domains = domains.data;
          this.professions = professions.data;

          this.isLoading = false;
        } catch (error) {
          this.$toast.show({
            message: error?.response?.data?.message || error,
            type: 'error',
          });
        }
      },
      setDomainToEdit(profession) {
        const professionIndex = this.professions.findIndex(p => p._id === profession._id);
        const isDomainSetForProfession = profession?._domains?.some(domain => domain === this.selectedDomain._id);
        const domainsToSet = isDomainSetForProfession ? profession?._domains?.filter(domain => domain !== this.selectedDomain._id) || [] : [...(profession._domains || []), this.selectedDomain._id];

        this.$set(this.professions, professionIndex, { ...profession, _domains: domainsToSet });

        if (!this.professionsToEdit.some(p => p._id === profession._id)) {
          this.professionsToEdit.push({
            ...profession,
            _domains: domainsToSet,
          });
        } else {
          const professionToEditIndex = this.professionsToEdit.findIndex(p => p._id === profession._id);

          this.$set(this.professionsToEdit, professionToEditIndex, {
            ...profession,
            _domains: domainsToSet,
          });
        }
      },
      getProfessionLinkStatus(profession) {
        return profession?._domains?.some(domain => domain === this.selectedDomain._id);
      },
      async editProfession() {
        try {
          for (const profession of this.professionsToEdit) {
            if (profession._id) {
              await editProfession({
                id: profession._id,
                domains: profession._domains.map(domain => domain?._id || domain),
              });
            }
          }

          this.$toast.show({
            title: 'Domaine édité',
            type: 'success',
            message: 'Domaine édité avec succès',
          });

          this.$emit('close-modal');
        } catch (error) {
          this.$toast.show({
            title: 'Erreur lors de l\'édition de l\'organisation',
            type: 'error',
            message: error?.response?.data?.message || error,
          });
        }
      },
    },
    created() {
      this.getIndustry();
    },
  };
</script>

<style lang=scss scoped>
  .industries-modal-link {
    padding: 30px;
    width: 100%;
    max-width: 600px;
    max-height: calc(100vh - 100px);

    @include bp('tablet') {
      min-width: 600px;
    }

    &__title {
      color: $color-secondary;
    }

    &__body {
      padding: 20px 0;
    }

    &__separator {
      width: 100%;
      border: 0;
      border-top: 1px solid rgba($color-secondary, 0.2);
    }

    &__tag {
      margin: 5px;
      cursor: pointer;

      &--active {
        :deep() {
          background: $color-marvin;
          color: $color-white;
        }
      }
    }

    &__tag-wrapper {
      margin: -5px
    }

    & :deep() {
      .bc-dropdown-trigger {
        border: 1px solid rgba($color-primary, 0.3);
        border-radius: $border-radius-s;
      }
    }
  }
</style>
