<script setup lang="ts">
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
import AlgoliaCardsCandidate from './AlgoliaCards/AlgoliaCardsCandidate.vue';
import { algoliasearch } from 'algoliasearch';

interface SearchResult {
  hits: any[];
  nbHits: number;
  page: number;
  nbPages: number;
  hitsPerPage: number;
  processingTimeMS: number;
  exhaustiveNbHits: boolean;
}

import { useI18n } from '@/i18n/i18n';
import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger.vue';
import { usePanelStore } from '@/store/pinia/panel';
import AlgoliaModalAdvancedSearch from '@/components/Algolia/AlgoliaModal/AlgoliaModalAdvancedSearch.vue';
import AlgoliaModal from '@/components/Algolia/AlgoliaModal.vue';
import { ref, computed } from 'vue';
import MultiselectFooter from '@/components/Multiselect/MultiselectFooterLegacy.vue';
import { useMultiselect } from '../Multiselect/useMultiselect';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox.vue';
import BcPagination from '@/ui-kit/components/BcPagination/BcPagination.vue';
import { useStore } from '@/store';
import { useRoute, useRouter } from 'vue-router/composables';

const { t } = useI18n();
const placeholder = t('generics.search-candidate');

const store = useStore();
const organization = computed(() => store.state.user?.profile?._organization || {});
const organizationId = computed(() => organization.value?._id);
const organizationConfiguration = computed(() => organization.value?.configuration || {});
const algoliaConfiguration = computed(
  () => organizationConfiguration.value?.integrations?.algolia?.credentials || {},
);

const algoliaClient = algoliasearch(
  algoliaConfiguration.value?.appId,
  algoliaConfiguration.value?.appKey,
);

const indexList = [`coders_${organizationId.value}`];

const route = useRoute();
const router = useRouter();

const DEBOUNCE_DELAY = 250; // 250ms de délai
const HITS_PER_PAGE = 5;
const MAX_HITS = 1000;
const MAX_DISPLAYED_HITS = 50; // Maximum number of hits to display

const searchTerm = ref(route.query.q?.toString() || '');
const isAlgoliaVisible = computed(
  () =>
    route.query.q !== undefined &&
    route.query.q !== '' &&
    route.query.p !== undefined &&
    route.query.p !== '',
);
function setSearchTerm(value: string) {
  searchTerm.value = value;
}

// Pour la pagination principale (groupes de 50)
const currentPage = ref(Number(route.query.p) - 1 || 0);

// Pour le chargement infini (groupes de 5)
const loadingIndex = ref(0);

// Calcul du nombre maximum de pages en fonction de la limite de 1000 résultats
const getMaxPages = (nbHits: number) => {
  // On divise par 50 car chaque page contient 50 résultats
  return Math.ceil(Math.min(nbHits, MAX_HITS) / MAX_DISPLAYED_HITS);
};

const setCurrentPage = (page: number) => {
  currentPage.value = page;
  loadingIndex.value = page * 10; // 10 groupes de 5 par page
  displayedHits.value = [];
  resetSelectedCards();

  window.scrollTo({ top: 0, behavior: 'smooth' });
  router
    .replace({
      ...route,
      query: {
        ...route.query,
        q: searchTerm.value || undefined,
        p: page >= 0 ? String(page + 1) : undefined,
      },
    })
    .catch(() => {});
};

async function search({ term, index, page = 0, hitsPerPage = HITS_PER_PAGE }) {
  const { results } = await algoliaClient.search([
    {
      indexName: index,
      params: {
        query: term,
        hitsPerPage,
        page,
        highlightPostTag: '</mark>',
        highlightPreTag: '<mark>',
        attributesToHighlight: ['*'],
        attributesToSnippet: [
          'documentTexts.text:10',
          'notes.content:10',
          'experiences.description:10',
          'experiences.title:10',
        ],
        snippetEllipsisText: '...',
      },
    },
  ]);

  return results[0];
}

const displayedHits = ref([]);

const searchClient = {
  ...algoliaClient,
  search: async (requests: SearchRequest[]) => {
    const query = route.query.q;

    if (!query || loadingIndex.value === -1) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          page: loadingIndex.value === -1 ? 0 : loadingIndex.value,
          nbPages: 0,
          hitsPerPage: HITS_PER_PAGE,
          processingTimeMS: 0,
          exhaustiveNbHits: true,
        })),
      });
    }

    try {
      const usersResponse = (await search({
        term: query,
        index: indexList[0],
        page: loadingIndex.value,
        hitsPerPage: HITS_PER_PAGE,
      })) as SearchResult;

      // Utiliser un Set pour dédupliquer les résultats
      const uniqueHits = Array.from(
        new Map(
          [...displayedHits.value, ...usersResponse.hits].map(hit => [hit.objectID, hit]),
        ).values(),
      );

      displayedHits.value = loadingIndex.value === 0 ? usersResponse.hits : uniqueHits;

      if (loadingIndex.value < usersResponse.nbPages) {
        loadNextPage();
      }

      return Promise.resolve({
        results: [
          {
            hits: displayedHits.value,
            nbHits: usersResponse.nbHits,
            page: currentPage.value,
            nbPages: Math.ceil(Math.min(usersResponse.nbHits, MAX_DISPLAYED_HITS) / HITS_PER_PAGE),
            hitsPerPage: HITS_PER_PAGE,
            processingTimeMS: usersResponse.processingTimeMS,
            exhaustiveNbHits: usersResponse.exhaustiveNbHits,
          },
        ],
      });
    } catch (error) {
      console.error('Error searching:', error);
      return Promise.reject(error);
    }
  },
};

// Fonction pour charger la page suivante
const loadNextPage = async () => {
  if (searchTerm.value) {
    loadingIndex.value += 1;
    if (
      displayedHits.value.length < MAX_DISPLAYED_HITS &&
      loadingIndex.value <= (currentPage.value + 1) * 10 - 1
    ) {
      try {
        await searchClient.search([
          {
            indexName: indexList[0],
            params: {
              query: searchTerm.value,
              page: loadingIndex.value,
              hitsPerPage: HITS_PER_PAGE,
            },
          },
        ]);
      } catch (error) {
        console.error('Error loading next page:', error);
      }
    }
  }
};

const setAlgoliaQuery = debounce((refine, value) => {
  loadingIndex.value = 0;
  displayedHits.value = [];
  currentPage.value = 0;

  router.replace({
    ...route,
    query: {
      ...route.query,
      q: value || undefined,
      p: value ? 1 : undefined,
    },
  });

  if (refine) {
    refine(value);
  }
  resetSelectedCards();
}, DEBOUNCE_DELAY);

const panelStore = usePanelStore();

const advancedSearchCount = computed(() => {
  const query = route.query;
  const queryCount = {
    'cf-experience': query['cf-experience'] ? 1 : 0,
    'cf-remote': query['cf-remote'] ? 1 : 0,
    companies: query.companies ? 1 : 0,
    contracts: query.contracts ? 1 : 0,
    currentSalaryMax: query.currentSalaryMax ? 1 : 0,
    currentSalaryMin: query.currentSalaryMin ? 1 : 0,
    email: query.email ? 1 : 0,
    lastCoachActivity: query['last-coach-activity-from'] || query['last-coach-activity-to'] ? 1 : 0,
    linkedin: query.linkedin ? 1 : 0,
    locations: query.locations ? 1 : 0,
    phone: query.phone ? 1 : 0,
    professions: query.professions ? 1 : 0,
    rating: query.rating ? 1 : 0,
    salaryWanted: query.salaryWantedMax || query.salaryWantedMin ? 1 : 0,
    search: query.search ? 1 : 0,
    technos: query.technos ? 1 : 0,
    uniqueid: query.uniqueid ? 1 : 0,
  };
  return Object.values(queryCount).reduce((acc, count) => acc + count, 0);
});

function goFilters(refine) {
  panelStore.openModal(AlgoliaModalAdvancedSearch, {
    onSearch: () => refine(''),
  });
}

const {
  selectedCards,
  setSelectedCards,
  getIsSelectedStatus,
  selectAllCards,
  resetSelectedCards,
  getIsAllCardsSelected,
} = useMultiselect('objectID');

function debounce<T extends (...args: any[]) => any>(
  fn: T,
  delay: number,
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (...args: Parameters<T>) {
    return new Promise(resolve => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(async () => {
        resolve(await fn(...args));
      }, delay);
    });
  };
}
</script>

<template>
  <div class="algolia-results flex w-full flex-col">
    <AlgoliaModal />
    <ais-instant-search :index-name="indexList[0]" :search-client="searchClient">
      <div class="flex w-full grow flex-col">
        <ais-search-box>
          <template #default="{ isSearchStalled, refine }">
            <div class="flex w-full flex-col">
              <div class="flex w-full">
                <label
                  class="relative flex w-full max-w-[375px] items-center gap-3 rounded-3xl border border-blue-200 bg-neutral-100 px-[20px] py-[10px] text-blue-400 focus:border-blue-500 focus:outline-none">
                  <span class="icon-search" />
                  <input
                    ref="input"
                    :value="searchTerm"
                    :placeholder="placeholder"
                    spellcheck="false"
                    class="w-full bg-transparent text-sm outline-none placeholder:text-blue-400"
                    type="search"
                    @input="
                      e => {
                        setSearchTerm(e?.currentTarget?.value);
                        setAlgoliaQuery(
                          () => refine(e?.currentTarget?.value),
                          e?.currentTarget?.value,
                        );
                      }
                    " />
                </label>
                <BcDropdownTrigger
                  :text="$t('generics.advanced-search')"
                  :class="{ '!max-w-[230px]': advancedSearchCount }"
                  class="trigger ml-auto max-w-[206px] !p-[10px] shrink-0 !justify-center"
                  icon="equalizer"
                  :count="advancedSearchCount"
                  @click.native.stop="goFilters(refine)" />
              </div>
              <slot v-if="!isAlgoliaVisible" />
              <div v-else class="flex flex-col">
                <ais-state-results class="mt-[15px] flex w-full flex-col gap-2.5">
                  <template #default="{ results: { nbHits, nbPages } }">
                    <div v-if="searchTerm" class="flex items-center gap-[10px]">
                      <p class="font-bold text-blue-800">
                        {{
                          $t('algolia.candidates-found', {
                            count: nbHits,
                            search: searchTerm,
                          })
                        }}
                      </p>
                      <button
                        class="flex items-center gap-1 hover:text-blue-500"
                        @click="
                          () => {
                            setSearchTerm('');
                            setAlgoliaQuery(refine(''));
                          }
                        ">
                        <i class="icon-refresh-cw text-xs"></i>
                        <span class="text-sm">{{ $t('generics.reinitialize') }}</span>
                      </button>
                    </div>
                    <ais-hits class="flex w-full flex-col gap-2.5">
                      <template #default="{ items }">
                        <div class="flex flex-col gap-2.5">
                          <div v-if="isSearchStalled && searchTerm" class="w-full justify-center">
                            <BcSpinner size="large" />
                          </div>
                          <div v-else class="flex flex-col gap-2.5">
                            <div class="ml-[30px] flex items-center justify-start">
                              <BcCheckbox
                                id="select-all"
                                :value="getIsAllCardsSelected(displayedHits)"
                                @input="
                                  selectAllCards(
                                    !getIsAllCardsSelected(displayedHits),
                                    displayedHits,
                                  )
                                ">
                                {{ $t('generics.select-all-results') }}
                              </BcCheckbox>
                            </div>
                            <AlgoliaCardsCandidate
                              v-for="item in displayedHits"
                              :key="`${item.objectID}-${item.firstName}-${item.lastName}`"
                              :candidate="item"
                              :is-selected="getIsSelectedStatus(item.objectID)"
                              @toggle-selection="
                                setSelectedCards(!getIsSelectedStatus(item.objectID), item)
                              " />
                          </div>
                        </div>
                      </template>
                    </ais-hits>
                    <ais-pagination class="mt-4">
                      <template #default="{ currentRefinement, refine, nbHits }">
                        <BcPagination
                          :current="currentPage + 1"
                          :total="getMaxPages(nbHits) * MAX_DISPLAYED_HITS"
                          :per-page="MAX_DISPLAYED_HITS"
                          @change="
                            page => {
                              setCurrentPage(page - 1);
                              refine(page - 1);
                            }
                          " />
                      </template>
                    </ais-pagination>
                  </template>
                </ais-state-results>
              </div>
            </div>
          </template>
        </ais-search-box>
      </div>
    </ais-instant-search>
    <MultiselectFooter
      v-if="selectedCards.length"
      class="fixed bottom-0 left-0 flex w-full flex-col items-center justify-center" />
  </div>
</template>

<style lang="scss" scoped>
.algolia-results {
  &:deep(.ais-Hits-list) {
    @apply flex flex-col gap-2.5;
  }

  &:deep(.ais-Pagination) {
    @apply flex justify-center;
  }

  &:deep(input[type='search']::-webkit-search-cancel-button) {
    display: none;
  }

  &:deep(.bc-dropdown-trigger__text-container) {
    @apply justify-center;
  }
}
</style>
