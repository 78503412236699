<template>
  <component
    :is="deletable ? 'button' : 'div'"
    :class="{
      'border-radius-s': type === 'default',
      'border-radius-l': type === 'rounded',
      'bc-tag--badge border-radius-rounded is-justify-content-center font-size-xs': type === 'badge' && size === 'medium',
      'bc-tag--badge bc-tag--badge--small border-radius-rounded is-justify-content-center': type === 'badge' && size === 'small',
      'bc-tag--medium font-size-s': size === 'medium' && type !== 'badge',
      'bc-tag--large font-size-m': size === 'large' && type !== 'badge',
      'bc-tag--small font-size-xs': size === 'small' && type !== 'badge',
      'bc-tag--deletable': deletable,
      'bc-tag--disabled': $attrs.disabled,
      'bc-tag--dark': tone === 'dark',
      'bc-tag--light': tone === 'light',
    }"
    class="bc-tag is-align-items-center is-relative"
    v-on="$listeners">
    <i
      v-if="icon"
      :class="[`icon-${icon}`]"
      class="bc-tag__icon is-flex is-align-items-center">
    </i>
    <p
      v-if="$slots.default"
      :class="{ 'bc-tag__text--ellipsis': ellipsis, 'bc-tag__text--light': tone === 'light' }"
      class="bc-tag__text">
      <!-- @slot The slot for the content of the tag -->
      <slot></slot>
    </p>
    <i
      v-if="deletable && !deleteButton"
      :class="{ 'bc-tag__text--light': tone === 'light' }"
      class="bc-tag__cross icon-cross is-flex is-align-items-center">
    </i>
    <span
      v-else-if="deletable && deleteButton"
      class="bc-tag__button is-flex is-justify-content-center is-align-items-center"
      role="button">
      <span
        class="bc-tag__button-zone"
        @click.stop.exact="$emit('on-delete')">
      </span>
      <i
        :class="{ 'bc-tag__text--light': tone === 'light' }"
        class="bc-tag__cross icon-cross is-flex is-align-items-center">
      </i>
    </span>
  </component>
</template>

<script>
  export default {
    name: 'bc-tag',
    props: {
      /**
       * The icon displayed in the tag
       */
      icon: {
        type: String,
        default: '',
      },
      /**
       * The type of the tag
       * @values default, rounded, badge
       */
      type: {
        type: String,
        default: 'default',
        validator: type => ['default', 'rounded', 'badge'].indexOf(type) >= 0,
      },
      /**
       * Display a cross on the right inside the tag
       */
      deletable: {
        type: Boolean,
        default: false,
      },
      /**
       * The size of the tag
       * @values large, medium, small
       */
      size: {
        type: String,
        default: 'medium',
        validator: size => ['large', 'medium', 'small'].indexOf(size) >= 0,
      },
      /**
       * The tone of the tag
       * @values default, light, dark
       */
      tone: {
        type: String,
        default: 'default',
        validator: value => ['default', 'light', 'dark'].indexOf(value) >= 0,
      },
      /**
       * Add '...' to the end of long text, it requires a max width on the tag !
       * @values default, light, dark
       */
      ellipsis: {
        type: Boolean,
        default: false,
      },
      /**
       * Enable a button on cross to have a on-delete custom event
       * @values true, false
       */
      deleteButton: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .bc-tag {
    background: $color-grey;
    color: $color-secondary-dark;

    &--disabled {
      opacity: 0.5;
    }

    &--badge {
      width: 30px;
      height: 30px;
      text-align: center;

      &--small {
        width: 24px;
        height: 24px;
      }
    }

    &--dark {
      background: $color-secondary-dark;
      color: $color-white;
    }

    &--light {
      background: rgba($color-primary-dark, 0.1);
    }

    &--medium {
      padding: 7px 15px;

      & .bc-tag__icon {
        font-size: $font-size-xxs;
        margin-right: 5px;
      }

      & .bc-tag__cross, .bc-tag__button {
        font-size: 10px;
        margin-left: 10px;
      }
    }

    &--small {
      padding: 5px 10px;

      & .bc-tag__icon {
        font-size: 11px;
        margin-right: 5px;
      }

      & .bc-tag__cross, .bc-tag__button {
        font-size: 8px;
        margin-left: 10px;
      }
    }

    &--large {
      padding: 10px 20px;

      & .bc-tag__icon {
        font-size: 16px;
        margin-left: 5px;
      }
    }

    &--large {
      padding: 10px 20px;

      & .bc-tag__icon {
        font-size: 16px;
        margin-right: 5px;
      }

      & .bc-tag__cross, .bc-tag__button {
        font-size: 12px;
        margin-left: 10px;
      }
    }

    &__text {
      color: inherit;
      &--ellipsis {
        max-width: 10em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &--light {
        color: rgba($color-primary-dark, 1);
        font-weight: 500;
      }
    }

    &__button {
      height: 100%;
      border-left: 1px solid $color-white;
      padding-left: 10px;
      overflow: hidden;
      position: relative;

      & .bc-tag__cross {
        margin-left: 0;
      }
    }

    &__button-zone {
      height: calc(100% + 10px);
      width: calc(100% + 10px);
      position: absolute;
    }
  }
</style>
