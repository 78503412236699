import { http } from '@/api/index';

export const getSuggestions = (status, coachId, category) => {
  return http.get('/suggestions', { params: { status, coachId, category } });
};

export const getSuggestionsByParams = ({ filter, coachId, reminderType, page = 1 , cancelTokenSource = {} }) => {
  return http.get('/suggestions/byparams', {
    params: { filter, coachId, reminderType, page },
    cancelToken: cancelTokenSource.token,
  });
}

export const getSuggestionProfile = id => {
  return http.get(`/suggestions/${id}`);
};

export const postSuggestion = suggestion => {
  return http.post('/suggestions', suggestion);
};

export const acceptSuggestion = suggestionId => {
  return http.put(`/suggestions/${suggestionId}/accept`);
};

export const rejectSuggestion = suggestionId => {
  return http.put(`/suggestions/${suggestionId}/reject`);
};

export const postponeSuggestion = (suggestionId, nbDays, saveAction = true) => {
  return http.put(`/suggestions/${suggestionId}/postpone`, { nbDays, saveAction });
};

export const transferSuggestion = (suggestionId, coachId) => {
  return http.put(`/suggestions/${suggestionId}/transfer`, {
    to: coachId,
  });
};

export const archiveSuggestion = (suggestionId) => {
  return http.put(`/suggestions/${suggestionId}/archive`);
};

export const unarchiveSuggestion = (suggestionId, nbDays) => {
  return http.put(`/suggestions/${suggestionId}/unarchive`, { nbDays });
};

export default {
  getSuggestions,
  postSuggestion,
  acceptSuggestion,
  rejectSuggestion,
  postponeSuggestion,
  transferSuggestion,
  getSuggestionProfile,
  archiveSuggestion,
  unarchiveSuggestion,
};
