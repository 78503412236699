<script lang="ts" setup>
import { computed, defineProps, ref, withDefaults } from 'vue';
import gcalendar from '@/assets/logo/google-calendar.png';
import outlookcalendar from '@/assets/logo/outlook_calendar.png';
import whatsapp from '@/assets/logo/whatsapp.png';
import { useI18n } from '@/i18n/i18n';
import { useRoute } from 'vue-router/composables';
import {
  getGmailLink,
  getGoogleCalendarEventLink,
  getMicrosoftCalendarEventLink,
  getOutlookLink,
  getWhatsappLink,
} from '@/helpers/quicklinks';
import { useStore } from '@/store';
import { Candidate } from '@/domains/models/Candidate';
import { useClipboard } from '@vueuse/core';
import TransitionFadeTranslateUp from '@/components/UIKit/Transition/TransitionFadeTranslateUp.vue';

interface Props {
  value: string;
  icon?: string;
  isPro?: boolean;
  iconUrl?: string;
  isEmail?: boolean;
  isPhone?: boolean;
  candidate: Candidate;
}

const i18n = useI18n();
const route = useRoute();
const store = useStore();

const props = withDefaults(defineProps<Props>(), {
  value: '',
  isEmail: false,
});

const isOpen = ref(false);

const widgetAvailability = computed(() => {
  const configurationProcess = store.getters['user/configurationProcess'];
  return {
    hasGoogleWidgets: configurationProcess.hasGoogleWidgets,
    hasMicrosoftWidgets: configurationProcess.hasMicrosoftWidgets,
  };
});
const microsoftCalendarLink = computed(() => 
  getMicrosoftCalendarEventLink({ 
    emailTo: props.candidate?.email || "" 
  })
);
const googleCalendarLink = computed(() =>
  getGoogleCalendarEventLink({
    i18n,
    routeFullPath: route.fullPath,
    profileName: `${props.candidate.firstName} ${props.candidate.lastName}`,
    phone: props.candidate.phone,
    email: props.candidate.email,
    type: 'invitation',
    organizationName: store.state.user._organization?.name,
  }),
);
const gmailLink = computed(() => getGmailLink({ emailTo: props.candidate.email }));
const outlookLink = computed(() => {
  return getOutlookLink({ emailTo: props.candidate.email });
});
const { copy, copied } = useClipboard();
</script>

<template>
  <div
    :class="{
      'cursor-pointer': isEmail || isPhone,
    }"
    class="panel-coder-contacts-social relative flex basis-1/2 items-center text-blue-800"
    @click="isOpen = !isOpen">
    <i v-if="icon" :class="`icon-${icon}`" class="mr-1" size="13"> </i>
    <img v-if="iconUrl" :src="iconUrl" alt="icon" class="mr-1 size-[13px]" />
    <p class="truncate text-xs font-medium">
      {{ value }}
    </p>
    <span v-if="isPro" class="ml-1.5 rounded-lg bg-blue-100 px-2.5 text-xs"> Pro </span>
    <TransitionFadeTranslateUp>
      <ul
        v-if="isOpen && (isEmail || isPhone)"
        v-click-outside="() => (isOpen = false)"
        class="absolute left-0 top-full z-10 mt-1.5 overflow-hidden rounded-md bg-white text-xs shadow-md">
        <li
          class="flex cursor-pointer items-center rounded-t-md border-b border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white"
          @click="copy(value)">
          <span class="icon-copy"></span>
          <p class="ml-2">{{
            isEmail ? $t('companies.account-management.copy-email') : $t('generics.copy')
          }}</p>
        </li>
        <a v-if="isEmail && widgetAvailability.hasGoogleWidgets" :href="gmailLink" target="_blank">
          <li
            class="flex cursor-pointer items-center border-b border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white">
            <span class="icon-mail"></span>
            <p class="ml-2">{{ $t('coder.quicklinks.gmail') }}</p>
          </li>
        </a>
        <a
          v-if="isEmail && widgetAvailability.hasMicrosoftWidgets"
          :href="outlookLink"
          target="_blank">
          <li
            class="flex cursor-pointer items-center border-b border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white">
            <span class="icon-mail"></span>
            <p class="ml-2">{{ $t('coder.quicklinks.outlook') }}</p>
          </li>
        </a>
        <a
          v-if="isEmail && widgetAvailability.hasGoogleWidgets"
          :href="googleCalendarLink"
          target="_blank">
          <li
            class="flex cursor-pointer items-center border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white">
            <img :src="gcalendar" alt="gcalendar" class="size-[13px]" />
            <p class="ml-2">{{ $t('generics.invit') }}</p>
          </li>
        </a>
        <a
          v-if="isEmail && widgetAvailability.hasMicrosoftWidgets"
          :href="microsoftCalendarLink"
          target="_blank">
          <li
            class="flex cursor-pointer items-center border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white">
            <img :src="outlookcalendar" alt="outlookcalendar" class="size-[13px]" />
            <p class="ml-2">{{ $t('generics.invit') }}</p>
          </li>
        </a>
        <a v-if="isPhone" :href="`tel:${value}`" target="_blank">
          <li
            class="flex cursor-pointer items-center border-b border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white">
            <span class="icon-phone"></span>
            <p class="ml-2">{{ $t('generics.phone-call') }}</p>
          </li>
        </a>
        <a v-if="isPhone" :href="getWhatsappLink({ phone: value })" target="_blank">
          <li
            class="flex cursor-pointer items-center border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white">
            <img :src="whatsapp" alt="whatsapp" class="size-[13px]" />
            <p class="ml-2">{{ $t('coder.quicklinks.whatsapp') }}</p>
          </li>
        </a>
      </ul>
    </TransitionFadeTranslateUp>
  </div>
</template>
