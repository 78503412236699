<template>
  <div class="industries-modal-edit is-column is-align-items-center">
    <p class="industries-modal-edit__title font-size-xl mb-4 font-weight-bold">
      Éditer une industrie
    </p>
    <bc-spinner
      v-if="isLoading"
      class="is-justify-content-center"
      size="large">
    </bc-spinner>
    <div class="industries-modal-edit__body is-full-width is-column">
      <industries-modal-edit-domains
        :domains="editedProfile.domains"
        class="is-full-width mb-5"
        @domain-deleted="addDomainToDelete"
        @update-value="setEditedProfile">
      </industries-modal-edit-domains>
      <industries-modal-edit-professions
        :professions="editedProfile.professions"
        class="is-full-width mb-5"
        @profession-deleted="addProfessionToDelete"
        @update-value="setEditedProfile">
      </industries-modal-edit-professions>
      <industries-modal-edit-skills
        :skills="editedProfile.skills"
        class="is-full-width"
        @skill-deleted="addSkillToDelete"
        @update-value="setEditedProfile">
      </industries-modal-edit-skills>
    </div>
    <hr class="industries-modal-edit__separator mt-5 mb-5 full-width"/>
    <div class="industries-modal-edit__wrapper is-full-width is-justify-content-center">
      <bc-button
        class="industries-modal-edit__button mr-4"
        data-button-cancel
        type="outlined"
        @click="$emit('close-modal')">
        {{ $t('generics.cancel') }}
      </bc-button>
      <bc-button
        class="industries-modal-edit__button"
        color="success"
        data-button-validate
        @click="editOrganization">
        Éditer l'organisation
      </bc-button>
    </div>
  </div>
</template>

<script>
  import IndustriesModalEditProfessions
    from '@/components/Industries/IndustriesModal/IndustriesModalEdit/IndustriesModalEditProfessions';
  import { getIndustryDomains, getIndustryProfessions, getIndustrySkills } from '@/api/industries';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import { mapActions, mapGetters } from 'vuex';
  import IndustriesModalEditSkills
    from '@/components/Industries/IndustriesModal/IndustriesModalEdit/IndustriesModalEditSkills';
  import { createProfession, deleteProfession } from '@/api/professions';
  import { createTag, deleteTag } from '@/api/tags';
  import IndustriesModalEditDomains
    from '@/components/Industries/IndustriesModal/IndustriesModalEdit/IndustriesModalEditDomains';
  import { createDomain, deleteDomain } from '@/api/domains';

  export default {
    name: 'industries-modal-edit',
    components: {
      IndustriesModalEditDomains,
      IndustriesModalEditSkills,
      BcButton,
      BcSpinner,
      IndustriesModalEditProfessions,
    },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        isLoading: true,
        editedProfile: {
          professions: [],
          skills: [],
          domains: [],
        },
        skillsToDelete: [],
        professionsToDelete: [],
        domainsToDelete: [],
      };
    },
    computed: {
      ...mapGetters([
        'cardActive',
      ]),
    },
    methods: {
      ...mapActions([
        'updateCard',
      ]),
      async getIndustry() {
        this.isLoading = true;

        try {
          const [professions, skills, domains] = await Promise.all([
            getIndustryProfessions(this.profile._id),
            getIndustrySkills(this.profile._id),
            getIndustryDomains(this.profile._id),
          ]);

          this.setEditedProfile({
            professions: professions.data,
            skills: skills.data,
            domains: domains.data,
          });

          this.isLoading = false;
        } catch (error) {
          this.$toast.show({
            message: error?.response?.data?.message || error,
            type: 'error',
          });
        }
      },
      setEditedProfile(profile) {
        this.editedProfile = { ...this.editedProfile, ...profile };
      },
      addProfessionToDelete(profession) {
        this.professionsToDelete.push(profession);
      },
      addDomainToDelete(domain) {
        this.domainsToDelete.push(domain);
      },
      addSkillToDelete(skill) {
        this.skillsToDelete.push(skill);
      },
      async editOrganization() {
        try {
          const professionsToCreate = this.editedProfile.professions.filter(domain => !domain._id);
          const skillsToCreate = this.editedProfile.skills.filter(skill => !skill._id);
          const domainsToCreate = this.editedProfile.domains.filter(domain => !domain._id);

          for (const domain of professionsToCreate) {
            await createProfession({ name: domain.name, industryId: this.profile._id });
          }

          for (const skill of skillsToCreate) {
            await createTag({ name: skill.name, industryId: this.profile._id });
          }

          for (const domain of domainsToCreate) {
            await createDomain({ name: domain.name, industryId: this.profile._id });
          }

          for (const profession of this.professionsToDelete) {
            if (profession._id) {
              await deleteProfession(profession._id);
            }
          }

          for (const domain of this.domainsToDelete) {
            if (domain._id) {
              await deleteDomain(domain._id);
            }
          }

          for (const skill of this.skillsToDelete) {
            if (skill._id) {
              await deleteTag(skill._id);
            }
          }

          this.$toast.show({
            title: 'Industrie éditée',
            type: 'success',
            message: 'Industrie éditée avec succès',
          });

          this.$emit('close-modal');
        } catch (error) {
          this.$toast.show({
            title: 'Erreur lors de l\'édition de l\'organisation',
            type: 'error',
            message: error?.response?.data?.message || error,
          });
        }
      },
    },
    created() {
      this.getIndustry();
    },
  };
</script>

<style lang=scss scoped>
  .industries-modal-edit {
    padding: 30px;
    width: 100%;
    max-width: 600px;
    max-height: calc(100vh - 100px);

    @include bp('tablet') {
      min-width: 600px;
    }

    &__title {
      color: $color-secondary;
    }

    &__body {
      overflow: auto;
      padding: 20px 0;
    }

    &__separator {
      width: 100%;
      border: 0;
      border-top: 1px solid rgba($color-secondary, 0.2);
    }
  }
</style>
