import { http } from '@/api/index';

export const getCoachProfile = async(id) => {
  return await http.get(`/coaches/${id}`);
};

export const updateCoachProfile = async({ id, updatedCoach, cancelTokenSource = {} }) => {
  if (!id) return
  return await http.patch(`/coaches/${id}`, updatedCoach, { cancelToken: cancelTokenSource.token });
};

export const createCoach = async(data) => {
  return await http.post(`/coaches`, {
    ...data,
    password: new Date().getTime() + Math.floor((Math.random() * 10000) + 1).toString(16),
  });
};

export const getCoaches = async(query) => {
  return await http.get('/coaches', {
    params: {
      ...query,
    },
  });
};

export const updateEnableStateAccount = async(id, enabled) => {
  return http.patch(`/coaches/${id}/account`, {
    enabled,
  });
};

export const getGoals = async({ coachIds, teamsIds, from, to }) => {
  return await http.get('/coaches/goals', {
    params: {
      ...coachIds && { coachIds },
      ...teamsIds && { teamsIds },
      ...from && { from },
      ...to && { to },
    },
  });
};

export const setOnboardingFinished = async(id) => {
  return await http.put(`/coaches/${id}/onboarding`);
};

export default {
  getCoachProfile,
  updateCoachProfile,
  getCoaches,
  updateEnableStateAccount,
  getGoals,
  createCoach,
  setOnboardingFinished,
};
