<template>
  <panel-header class="panel-poke-header">
    <div class="panel-poke-header__nav">
      <bc-button
        @click.native="closePanel"
        bc-background-color="transparent"
        bc-icon="cross"
        bc-icon-size="16px"
        bc-padding="0"
        bc-text-color="gray-silver-chalice"
        bc-text-color-hover="black"
        class="panel-poke-header__button">
      </bc-button>
      <bc-button
        @click.native="goPrevious"
        bc-background-color="transparent"
        bc-icon="arrow-left"
        bc-icon-size="16px"
        bc-padding="0"
        bc-text-color="gray-silver-chalice"
        bc-text-color-hover="black"
        class="panel-poke-header__button">
      </bc-button>
    </div>
    <bc-button
      @click.native="deletePoke"
      bc-background-color="red-amaranth"
      bc-icon="trash"
      bc-icon-size="18px"
      bc-padding="7.5px"
      bc-radius="100%"
      class="panel-poke-header__delete hidden-xs"
      key="button-reset">
    </bc-button>
  </panel-header>
</template>

<script>
  import { mapState } from 'vuex';
  //
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import PanelHeader from '@/components/Panel/PanelHeader';
  import BcButton from '@/legacy/ui-kit/components/Button/BcButton';
  /**
   * The panel header when you click on my profile
   */
  export default {
    name: 'panel-poke-header',
    components: {
      BcButton,
      PanelHeader
    },
    /**
     * We import the closePanel function to give it to the button cross
     */
    mixins: [PanelNavigation],
    computed: {
      ...mapState({
        profile: state => state.panel.profile
      }),
    },
  };
</script>

<style lang="scss" scoped>
  .panel-poke-header {
    flex-wrap: wrap;
    @include bp('tablet') {
      flex-wrap: nowrap;
    }
    &__nav {
      align-items: center;
    }
    &__button {
      margin-right: 7.5px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
</style>
