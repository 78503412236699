<script setup lang="ts">
import { computed, ref, defineEmits, defineProps, withDefaults, onMounted, nextTick, watch } from 'vue';
import { useStore } from '@/store';
import { uploadFile } from '@/api/upload';

import { useToast } from '@/ui-kit/components/BcToast';
import { useI18n } from '@/i18n/i18n';
import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
import BcInput from '@/ui-kit/components/BcInput/BcInput.vue';
import EmailVariablesList from '@/components/Email/EmailVariablesList.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import EmailAttachmentList from '@/components/Email/EmailAttachmentList.vue';
import { EventData } from '@/components/Email/EmailAttachment.vue';
import MvSelect from '@/ui-kit/components/MvSelect/MvSelect.vue';
import { useLinkedinTemplateForm } from '@/composables/form/useLinkedinTemplateForm';
import MvTextArea from '@/ui-kit/components/MvTextArea/MvTextArea.vue';
import { useLinkedinTemplatesStore } from '@/store/pinia/linkedinTemplates';
import { linkedinMacros, type LinkedinTemplateTypeEnum } from '@/macros/linkedin';

interface Props {
  template?: {
    _id: string;
    name: string;
    type: LinkedinTemplateTypeEnum;
    subject: string;
    body: string;
    default: boolean;
    activated: boolean;
    attachments: { filename: string; path: string; }[];
  }
  disabled: boolean;
}

interface ActionParams {
  icon: string;
  title: string;
  content: string;
  cancelText: string;
  confirmText: string;
  onConfirmAction: () => void,
}

const props = withDefaults(defineProps<Props>(), {
  template: undefined,
  disabled: false,
});

const emit = defineEmits<{
  (e: 'on-close'): void,
}>();

const linkedinTemplatesStore = useLinkedinTemplatesStore();
const store = useStore();
const toast = useToast();
const i18n = useI18n();

const isModalOpen = ref(false);
const isActionConfirmed = ref(false);
const isLoading = ref(false);
const uploadInputRef = ref();
const attachments = ref<{ filename: string; path: string; }[]>(props.template?.attachments || []);



const isEditing = computed(() => {
  return props.template?._id !== undefined;
});

const templatesVariables = computed(() => {
  return store.state.emails.templatesVariables;
});

const templateTypes = computed<{ label: string; value: LinkedinTemplateTypeEnum }[]>(() => {
  return [
    { label: 'Message', value: linkedinMacros.templates.type.message },
    { label: 'Invitation personnalisée', value: linkedinMacros.templates.type.invitation },
    { label: 'InMail', value: linkedinMacros.templates.type.inmail },
  ];
});

onMounted(async () => {
  await store.dispatch('emails/getTemplatesVariables');
});

const { form: templateForm, isPossiblyValid, fieldErrors, currentRules, approximateBodyLength, approximateSubjectLength } = useLinkedinTemplateForm();

const getAttachments = async () => {
  let _attachments = [...attachments.value];
  if (templateForm.value.attachmentsToUpload.length) {
    const promises = templateForm.value.attachmentsToUpload.map(file => {
      const dataToSend = new FormData();
      dataToSend.append('file', file);
      return uploadFile({ file: dataToSend });
    });
    const uploadedAttachments = await Promise.all(promises);
    _attachments = [..._attachments, ...uploadedAttachments.map(result => ({
      filename: result.data.fileName,
      path: result.data.fileUrl,
    }))];
  }
  return _attachments;
}

const onSave = async () => {
  try {
    if (!isPossiblyValid.value) {
      return;
    }
    isLoading.value = true;

    const uploadedAttachments = await getAttachments();

    if (isEditing.value && props.template) {
      if (props.template.default && !isActionConfirmed.value) {
        nextTick(() => {
          isModalOpen.value = true;
        });
        return;
      }

      await linkedinTemplatesStore.editTemplate(props.template._id, {
        name: templateForm.value.name,
        type: templateForm.value.type,
        subject: templateForm.value.subject,
        body: templateForm.value.body,
        attachments: uploadedAttachments,
      });
    } else {
      
      await linkedinTemplatesStore.createTemplate({
        name: templateForm.value.name,
        type: templateForm.value.type,
        subject: templateForm.value.subject,
        body: templateForm.value.body,
        attachments: uploadedAttachments,
      });
    }

    emit('on-close');
    onCloseModal();

  } catch (err: any) {
    toast.show({
      type: 'error',
      message: err?.response?.data?.message || err?.message,
    });
  } finally {
    isLoading.value = false;
  }
}

function onTemplateTypeSelect(newType) {
  templateForm.value.type = newType;
  if (newType === linkedinMacros.templates.type.inmail) {
    attachments.value = []
    templateForm.value.attachmentsToUpload = []
  } else {
    templateForm.value.subject = ''
  }
}

const onUpload = () => {
  uploadInputRef.value.click();
}

const onUploadSelect = (e: any) => {
  const files = e.target.files;
  templateForm.value.attachmentsToUpload = [...new Set([...templateForm.value.attachmentsToUpload, ...files])];
}

const onRemoveFile = ({ file, attachment }: EventData) => {
  if (file) {
    templateForm.value.attachmentsToUpload = templateForm.value.attachmentsToUpload.filter(
      f => f.name !== file.name && f.size !== file.size && f.lastModified !== file.lastModified
    );
  }
  if (attachment) {
    attachments.value = attachments.value.filter(a => a.path !== attachment.path);
  }
}

const onCloseModal = () => isModalOpen.value = false;

const onConfirmEdit = () => {
  isActionConfirmed.value = true;
  onSave();
}

const actionParams: Record<string, ActionParams> = {
  edit: {
    icon: '📝',
    title: 'Voulez-vous vraiment modifier ce template ?',
    content: 'Ce template est commun à tous les utilisateurs de votre espace. Voulez-vous réellement le modifier ?',
    cancelText: 'Non, annuler cette action',
    confirmText: 'Oui, modifier',
    onConfirmAction: onConfirmEdit,
  }
}

const bodyLabel = computed(() => {
  const bodyLabels: Record<LinkedinTemplateTypeEnum, string> = {
    [linkedinMacros.templates.type.message]: i18n.t('linkedin.message-body'),
    [linkedinMacros.templates.type.invitation]: i18n.t('linkedin.invitation-body'),
    [linkedinMacros.templates.type.inmail]: i18n.t('linkedin.inmail-body'),
  };
  return bodyLabels[templateForm.value.type];
});

watch(() => props.template, () => {
  console.log('newTemplate', props.template);
  if (props.template) {
    templateForm.value = {
      name: props.template.name,
      type: props.template.type,
      subject: props.template.subject,
      body: props.template.body,
      attachmentsToUpload: [],
    }
  }
}, { immediate: true });




// // Audio recording states
// const mediaRecorder = ref<MediaRecorder | null>(null);
// const isRecording = ref(false);
// const audioChunks = ref<Blob[]>([]);
// const audioURL = ref<string>('');

// // Audio recording methods
// const startRecording = async () => {
//   try {
//     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//     mediaRecorder.value = new MediaRecorder(stream);
//     audioChunks.value = [];
    
//     mediaRecorder.value.ondataavailable = (e) => {
//       audioChunks.value.push(e.data);
//     };

//     mediaRecorder.value.onstop = () => {
//       const audioBlob = new Blob(audioChunks.value, { type: 'audio/wav' });
//       audioURL.value = URL.createObjectURL(audioBlob);
      
//       // Add to attachments to upload
//       const file = new File([audioBlob], `audio_${Date.now()}.wav`, { type: 'audio/wav' });
//       voiceMessage.value = file;
//     };

//     mediaRecorder.value.start();
//     isRecording.value = true;
//   } catch (err: any) {
//     toast.show({
//       type: 'error',
//       message: 'Impossible d\'accéder au microphone',
//     });
//   }
// };

// const stopRecording = () => {
//   if (mediaRecorder.value && isRecording.value) {
//     mediaRecorder.value.stop();
//     isRecording.value = false;
//     mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
//   }
// };
</script>

<template>
  <div class="linkedin-template-form flex-col w-full">
    <div class="flex-col min-h-[85vh] p-5 gap-4 first:mt-5">
      <!-- Template name -->
      <BcInput v-model="templateForm.name" name="templateName" class="w-full mt-2" :label="$t('email.template-name')"
        :label-placeholder="false" :valid="!!templateForm.name && !fieldErrors.templateName"
        :disabled="disabled || template?.default" required />

      <MvSelect id="templateType" label="Type" :items="templateTypes" :value="templateForm.type" :is-absolute="true"
        @select="onTemplateTypeSelect" class="w-full" />

      <EmailVariablesList :variables="templatesVariables" />

      <BcInput v-if="currentRules.allowSubject" v-model="templateForm.subject" name="subject" class="w-full"
        :label="$t('linkedin.inmail-subject')" :label-placeholder="false"
        :valid="!!templateForm.subject && !fieldErrors.subject" :error="fieldErrors.subject" :disabled="disabled"
        :required="currentRules.requiresSubject" />

      <MvTextArea v-model="templateForm.body" :label="bodyLabel" :disabled="disabled"
        :max-length="currentRules.maxBodyLength" required />
        <small v-if="currentRules.maxBodyLength" class="text-blue-400 mt-1">{{ approximateBodyLength }}/{{ currentRules.maxBodyLength }}</small>
       <p v-if="!!templateForm.body" class="text-red-500 mt-[5px] font-size-s">
        {{ fieldErrors.body }}
      </p>

      <div v-if="currentRules.allowAttachments" class="flex-col">
        <div class="flex gap-2">
          <BcButton class="mt-2" icon-left="attachment" type="outlined" @click="onUpload">
            {{ $t('email.add-an-attachment') }}
          </BcButton>
          <!-- <BcButton 
            class="mt-2 text-blue-500" 
            type="outlined" 
            :class="{ 'text-red-500': isRecording }"
            @click="isRecording ? stopRecording() : startRecording()"
          >
            <MvIcon :name="isRecording ? 'stop': 'microphone'" size="16" />
            {{ isRecording ? 'Arrêter l\'enregistrement' : 'Enregistrer un audio' }}
          </BcButton> -->
        </div>
        <input ref="uploadInputRef" type="file" multiple style="display: none;" @change="onUploadSelect" />
        <EmailAttachmentList class="mt-3" :attachments="attachments" :files="templateForm.attachmentsToUpload"
          @on-remove="onRemoveFile" />
        <p v-if="fieldErrors.uploadError" class="text-red-500 mt-[5px] font-size-s">
          {{ fieldErrors.uploadError }}
        </p>
      </div>
    </div>
    <div class="min-h-[75px] w-full items-center justify-center gap-2 border-t border-blue-100">
      <BcButton icon-left="cross" type="outlined" @click="emit('on-close')">
        {{ $t('generics.cancel') }}
      </BcButton>
      <BcButton icon-left="check" :disabled="!isPossiblyValid || isLoading || disabled" @click="onSave">
        {{ $t('generics.save') }}
      </BcButton>
    </div>

    <ModalConfirm :isOpen="isModalOpen === true" :icon="actionParams.edit.icon" :title="actionParams.edit.title"
      :content="actionParams.edit.content" :cancelText="actionParams.edit.cancelText"
      :confirmText="actionParams.edit.confirmText" color="success" @on-confirm="actionParams.edit.onConfirmAction"
      @close="onCloseModal" />
  </div>
</template>
