export default {
  computed: {
    $_pokeStatus() {
      return [
        '',
        'poked',
        'poked',
        'refused',
        'matched'
      ];
    }
  }
};
