
import { i18n } from '@/i18n/i18n';

export const apiRights = {
  owner: 1,
  admin: 2,
  reader: 3,
};

export const types = {
  coder: 1,
  job: 2,
  company: 3,
  contact: 4,
};

export const placeholder = {
  coder: 'Candidats',
  job: 'Jobs',
  company: 'Partners',
  contact: 'Contacts',
};

export const listTypeOptions = [
  {
    name: i18n.tc('generics.candidate-cap',2),
    type: types.coder,
  },
  {
    name: i18n.tc('generics.company',2),
    type: types.company,
  },
  {
    name: i18n.tc('generics.contact-cap',2),
    type: types.contact,
  },
];


export const rightsName = [
  'Propriétaire',
  'Administrateur',
  'Lecteur',
];

export const getRightsNameFromApiValues = apiValue => Object.keys(apiRights).find(key => apiRights[key] === apiValue);

export const myCodersPipeName = 'Sourcing';

export const myIgnoredCodersName = 'Coders ignorés';

export const myJobsPipeName = 'Pipe jobs';

export const myGarden = 'Mes process';

export const myOldProcesses = "Anciens process";

export const myGardenTag = 1;

export default {
  apiRights,
  types,
  placeholder,
  listTypeOptions,
  rightsName,
  getRightsNameFromApiValues,
  myCodersPipeName,
  myJobsPipeName,
  myIgnoredCodersName,
  myGarden,
  myGardenTag,
  myOldProcesses,
};
