<template>
  <td @click.stop="isEditable = true">
    <template v-if="canEdit">
      <p v-if="!isEditable">
        {{ inputValue }}
      </p>
      <div v-else>
        <input type="number" v-model="inputValue" @blur="dataInput" v-focus />
      </div>
    </template>
    <template v-else>
      <p>
        {{ inputValue }}
      </p>
    </template>
  </td>
</template>

<script>
import TableCellMixin from '../../../mixins/Table/tableCell';

export default {
  mixins: [TableCellMixin],
  name: 'numbercell',
  data() {
    return {
      inputValue: 1,
      isEditable: false,
    };
  },

  created() {
    if (this.job.profils) {
      return (this.inputValue = this.job.profils);
    }

    return (this.inputValue = 1);
  },

  methods: {
    dataInput(e) {
      this.isEditable = false;

      if (this.job.profils !== parseInt(e.target.value)) {
        const cellData = {
          columnName: this.columnType.toLowerCase(),
          data: parseInt(this.inputValue),
        };

        if (!this.job) {
          return this.$emit('update-cell-data', cellData);
        }

        this.$emit('update-cell-data', { ...cellData, jobId: this.job._id });
      }
    },
  },
  watch: {
    'job.profils'() {
      this.inputValue = this.job.profils;
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  width: 78px;
  div {
    justify-content: center;
    input {
      border: 1px solid $color-grey-4;
      border-radius: 5px;
      padding: 12px 20px;
      color: $color-grey-4;
      width: 60px;
      font-family: 'Roboto';
      font-size: 16px;
      text-align: center;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  & p {
    width: 78px;
    overflow: auto;
  }
}
</style>
