import Vue from 'vue';
import BcToast from './BcToast';

const Toast = {
  show(params) {
    let message;

    if (typeof params === 'string') {
      message = params;
    }

    const config = {
      message,
      position: params.position || 'bottom-right',
    };

    const propsData = { ...config, ...params };

    const Component = Vue.extend(BcToast);

    return new Component({
      el: document.createElement('div'),
      propsData,
    });
  },
};

const Plugin = {
  install(Vue) {
    Vue.prototype.$toast = Toast;
  },
};

const useToast = () => {
  return Toast;
};
export { useToast };
export default Plugin;
