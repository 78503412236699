<template>
  <div class="panel-process-details-job-footer">
    <bc-button
      class="panel-process-details-job-footer__button"
      data-button-cancel
      type="outlined"
      @click.native="cleanQueries">
      {{ $t('generics.cancel') }}
    </bc-button>
    <bc-button
      class="panel-process-details-job-footer__button"
      color="success"
      data-button-validate
      @click.native="validateEdit">
      {{ $t('generics.validate') }}
    </bc-button>
  </div>
</template>

<script>
  import BcButton from '@/ui-kit/components/BcButton';
  import moment from 'moment/min/moment-with-locales';
  import processes from '@/api/processes';

  export default {
    name: 'panel-process-details-job-footer',
    components: { BcButton },
    props: {
      isEditing: {
        type: Boolean,
        default: false,
      },
      process: {
        type: Object,
        default: () => ({}),
      },
    },
    methods: {
      cleanQueries() {
        this.$router.replace({
          ...this.$route,
          query: {
            ...this.$route.query,
            'new-offer-fixed': undefined,
            'new-offer-variable': undefined,
            'new-introduction-salary': undefined,
            'new-deal-fixed': undefined,
            'new-deal-variable': undefined,
            'new-deal-amount': undefined,
            'new-deal-starts-at': undefined,
            'new-deal-dealt-at': undefined,
            'new-deal-percent': undefined,
          },
        });

        this.$emit('update:isEditing', false);
      },
      async validateEdit() {
        try {
          const process = {
            ...this.$route.query['new-introduction-salary'] && { introductionSalary: Number(this.$route.query['new-introduction-salary']) },
            ...(this.$route.query['new-offer-fixed'] !== undefined || this.$route.query['new-offer-variable'] !== undefined) && {
              companyOffer: {
                ...this.process.companyOffer,
                ...this.$route.query['new-offer-fixed'] !== undefined && { fixed: Number(this.$route.query['new-offer-fixed']) },
                ...this.$route.query['new-offer-variable'] !== undefined && { variable: Number(this.$route.query['new-offer-variable']) },
              },
            },
            ...(this.$route.query['new-deal-fixed'] || this.$route.query['new-deal-variable'] || this.$route.query['new-deal-amount'] || this.$route.query['new-deal-dealt-at'] || this.$route.query['new-deal-percent'] || this.$route.query['new-deal-starts-at']) && {
              ...this.$route.query['new-deal-dealt-at'] && { dealtAt: moment(this.$route.query['new-deal-dealt-at'], 'D') },
              deal: {
                ...this.process.deal,
                ...this.$route.query['new-deal-fixed'] && { fixed: Number(this.$route.query['new-deal-fixed']) },
                ...this.$route.query['new-deal-variable'] && { variable: Number(this.$route.query['new-deal-variable']) },
                ...this.$route.query['new-deal-amount'] && { amount: Number(this.$route.query['new-deal-amount']) },
                ...this.$route.query['new-deal-percent'] && { percent: Number(this.$route.query['new-deal-percent']) },
                ...this.$route.query['new-deal-starts-at'] && { startsAt: moment(this.$route.query['new-deal-starts-at'], 'D') },
              },
            },
            ...this.process.onboarding && {
              onboarding: {
                ...this.$route.query['new-deal-starts-at'] && { startsAt: moment(this.$route.query['new-deal-starts-at'], 'D') },
              },
            },
            ...this.$route.query['new-deal-validated-at'] && { validatedAt: moment(this.$route.query['new-deal-validated-at'], 'D') },
          };

          const { data: updatedProcess } = await processes.updateProcess({
            id: this.$route.params.id,
            process,
          });

          this.$emit('process-updated', updatedProcess);

          this.cleanQueries();
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-process-details-job-footer {
    padding: 15px;
    justify-content: center;

    &__button {
      margin: 0 5px;
    }
  }
</style>
