import { defineStore } from 'pinia';
import {
  createLinkedinTemplate,
  deleteLinkedinTemplate,
  editLinkedinTemplate,
  getLinkedinTemplates,
} from '@/api/linkedinTemplates';
import type { CreateLinkedinTemplateInput, EditLinkedinTemplateInput } from '@/domains/models/LinkedinTemplate';
import Vue, { ref } from 'vue';

type State = {
  items: any[];
  isLoading: boolean;
}

export const useLinkedinTemplatesStore = defineStore('linkedinTemplates', () => {
  const items = ref<any[]>([]);
  const isLoading = ref(false);

  async function fetchLinkedinTemplates({ coachId, all, sorted = false }: {
    coachId?: string
    all?: boolean | null
    sorted?: boolean
  }): Promise<void> {
    isLoading.value = true;
    try {
      const { data } = await getLinkedinTemplates({ coachId, all });

      items.value = sorted ? data : data;
    } catch (e) {
      items.value = [];
      throw new Error('Could not fetch linkedin templates');
    } finally {
      isLoading.value = false;
    }
  }

  async function createTemplate(createTemplateInput: CreateLinkedinTemplateInput): Promise<void> {
    try {
      const { data } = await createLinkedinTemplate(createTemplateInput);
      items.value = [data, ...items.value];
    } catch (e) {
      throw new Error('Could not create linkedin template');
    }
  }

  async function editTemplate(id: string, updatedFields: EditLinkedinTemplateInput): Promise<void> {
    try {
      const { data } = await editLinkedinTemplate(id, updatedFields);
      const index = items.value.findIndex((item) => item._id === id);
      Vue.set(items.value, index, { ...items.value[index], ...data });
    } catch (e) {
      throw new Error('Could not edit linkedin template');
    }
  }

  async function deleteTemplate(templateId: string): Promise<void> {
    try {
      await deleteLinkedinTemplate({ id: templateId });
      items.value = items.value.filter((item) => item._id !== templateId);
    } catch (e) {
      throw new Error('Could not delete linkedin template');
    }
  }

  return {
    items,
    isLoading,
    fetchLinkedinTemplates,
    createTemplate,
    editTemplate,
    deleteTemplate,
  };
});
