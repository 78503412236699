<template>
  <div class="bc-spinner">
    <div
      :class="[ `bc-spinner__spinner--${size}`, color && `bc-spinner__spinner--${color}` ]"
      class="bc-spinner__spinner">
    </div>
  </div>
</template>

<script>
  export default {
    name: 'bc-spinner',
    props: {
      size: {
        type: String,
        default: 'medium',
        validator: value => ['small', 'medium', 'large', 'extra-large'].indexOf(value) >= 0,
      },
      color: {
        type: String,
      },
    },
  };
</script>

<style lang="scss" scoped>
  $size-small: 10px;
  $size-medium: 20px;
  $size-large: 40px;
  $size-extra-large: 100px;

  .bc-spinner {
    text-align: center;

    &__spinner {
      -webkit-animation: loader 0.75s 0s infinite linear;
      animation: loader 0.75s 0s infinite linear;
      animation-fill-mode: both;
      border: 2px solid;
      background: transparent;
      border-radius: 100%;
      border-color: $color-primary $color-primary transparent;

      &--small {
        height: $size-small;
        width: $size-small;
      }

      &--medium {
        height: $size-medium;
        width: $size-medium;
      }

      &--large {
        height: $size-large;
        width: $size-large;
      }

      &--extra-large {
        height: $size-extra-large;
        width: $size-extra-large;
        border-width: 5px;
      }

      &--white {
        border-color: white white transparent;
      }

      &--secondary {
        border-color: $color-secondary-dark $color-secondary-dark transparent;
      }
    }
  }

  @-webkit-keyframes loader {
    0% {
      -webkit-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
    }
    50% {
      -webkit-transform: rotate(180deg) scale(0.85);
      transform: rotate(180deg) scale(0.85);
    }
    100% {
      -webkit-transform: rotate(360deg) scale(1);
      transform: rotate(360deg) scale(1);
    }
  }

  @keyframes loader {
    0% {
      -webkit-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
    }
    50% {
      -webkit-transform: rotate(180deg) scale(0.85);
      transform: rotate(180deg) scale(0.85);
    }
    100% {
      -webkit-transform: rotate(360deg) scale(1);
      transform: rotate(360deg) scale(1);
    }
  }
</style>
