<template>
  <div class="mv-button-rounded" v-on="$listeners">
    <button
      class="mv-button-rounded__button"
      :class="{ 'mv-button-rounded__button--opened': isOpen }"
      @click.stop="toggle">
      <i v-if="icon" :class="`icon-${icon}`"></i>
      <span>{{ title }}</span>
    </button>
    <transition name="slide-fade">
      <div v-if="isOpen" class="mv-button-rounded__content" v-click-outside="close">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'mv-button-rounded',
  props: {
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mv-button-rounded {
  flex-direction: column;
  position: relative;

  &__button {
    min-width: fit-content;
    color: $color-primary;
    font-size: $font-size-s;
    font-weight: $font-weight-regular;
    gap: 10px;
    border-radius: 30px;
    border: 1px solid $color-blue-medium;
    background: white;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;

    &--opened {
      background: $color-blue-light;
    }
  }

  &__separator {
    border-bottom: 1px solid $color-blue-medium;
  }

  &__content {
    position: absolute;
    z-index: 9999;
    width: 100%;
    min-width: fit-content;
    top: 100%;
    flex-direction: column;
    margin-top: 7px;
    border-radius: 5px;
    border: 1px solid $color-blue-light;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(29, 57, 94, 0.1);
  }
}
</style>
