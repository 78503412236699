<template>
  <div
    :class="{ 'is-tick-hidden': isTickHidden }"
    :style="getTickStyle(position)"
    class="bc-slider-tick">
    <span
      class="bc-slider-tick__label"
      v-if="$slots.default">
      <slot></slot>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'bc-slider-tick',
    props: {
      value: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      percentage() {
        return (this.value - this.$parent.min) / (this.$parent.max - this.$parent.min) * 100;
      },
      position() {
        return (this.percentage >= 0 && this.percentage <= 100) ? this.percentage : 0;
      },
      isTickHidden() {
        return this.value === this.$parent.min || this.value === this.$parent.max;
      },
    },
    methods: {
      getTickStyle(position) {
        return {
          left: position + '%',
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  $slider-tick-width: 3px !default;
  $slider-tick-radius: $border-radius-rounded !default;
  $slider-tick-background: $color-grey-5 !default;
  $slider-tick-to-track-ratio: 0.5 !default;
  $slider-thumb-to-track-ratio: 2 !default;

  .bc-slider-tick {
    $track-height: 1em / $slider-thumb-to-track-ratio;
    $tick-height: $track-height * $slider-tick-to-track-ratio;

    position: absolute;
    width: $slider-tick-width;
      @include bp('phone') {
          width: 0px;
      }
    transform: translate(-50%, -50%);
    top: 50%;
    background: $slider-tick-background;
    border-radius: $slider-tick-radius;
    height: $tick-height;

    &:nth-child(odd){
      @include bp('phone') {
      top: -50px;
      }
     }
    &.is-tick-hidden {
      background: transparent;
    }

    &__label {
      color: $color-blue-heavy-dark;
      font-size: $font-size-xs;
      position: absolute;
      top: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
    }
  }
</style>
