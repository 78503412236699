<template>
  <td
    @click.stop="isEditable = true"
    v-click-outside="setLocationEditableToFalse">
    <template v-if="canEdit">
      <template v-if="!isEditable">
        <p v-if="locationInput">{{ locationInput }}</p>
        <p v-else class="cell-placeholder">{{ $t(placeholder) }}</p>
      </template>
      <template v-else>
        <bc-autocomplete
          :loading="isLoading"
          :options="suggestions"
          :is-dropdown-full-width="false"
          ref="companyDropdown"
          @select.native="selectLocation"
          class="location__autocomplete is-expanded"
          name="location"
          v-model="locationInput">
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="input">
            </bc-dropdown-trigger>
          </template>
          <template #empty>
            <template v-if="isComplete && !isLoading">
              <p class="location__option font-size-s">
                {{ $t('toast.no-location-found') }}
              </p>
            </template>
          </template>
          <template #option="{ option }">
            <bc-dropdown-item
              :text="option.name"
              @click.native.stop="displayLoc(option)"
              class="location__option font-size-s is-expanded">
            </bc-dropdown-item>
          </template>
        </bc-autocomplete>
      </template>
    </template>
    <template v-else>
      <p>
        {{ locationInput }}
      </p>
    </template>
  </td>
</template>

<script>
import BcAutocomplete from '../../../ui-kit/components/BcAutocomplete/BcAutocomplete.vue';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import BcDropdownTrigger from '../../../ui-kit/components/BcDropdown/BcDropdownTrigger';
import { debounce } from '@/common-old/mixins/Debounce/debounce';
import location from '@/api/location';
import { getCompleteAddressOrTag } from '@/formatters/locations';

export default {
  components: { BcDropdownItem, BcAutocomplete, BcDropdownTrigger },

  name: 'locationcell',

  props: {
    wantedLocations: {
      type: Array,
      default: () => [],
    },
    columnType: {
      type: String,
      required: true,
    },
    job: {
      type: Object,
    },
    canEdit: {
      type: Boolean,
    },
    editFromModal: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    }
  },

  data() {
    return {
      isEditable: false,
      input: '',
      suggestions: [],
      isLoading: false,
      isComplete: false,
    };
  },

  created() {
    if (this.job._id && this.job.locations.length) {
      this.input = `${getCompleteAddressOrTag(this.job.locations?.[0]) || ''}`;
    }
  },

  computed: {
    locationInput: {
      get() {
        if (this.isEditable) {
          if (this.input === '') {
            return '';
          }

          return this.input;
        }

        if (this.job._id) {
          if (this.editFromModal) {
            return this.input;
          }

          return getCompleteAddressOrTag(this.job.locations?.[0]) || ''
        }

        return this.input;
      },
      set(value) {
        this.handleInputOnLocationAutocomplete(value);
      },
    },
  },

  methods: {
    selectLocation(location) {
      this.input = '';

      this.$emit('update-value', {
        locationsWanted: [...this.wantedLocations, location],
      });
    },

    getLocationSuggestions: debounce(async function(value) {
      if (value) {
        try {
          const {
            data: { predictions },
          } = await location.getLocationAutocomplete(value);

          this.suggestions = predictions.map(location => ({
            ...location,
            name: location.description,
          }));

          this.isLoading = false;
          this.isComplete = true;
        } catch (error) {
          this.isLoading = false;
          this.isComplete = true;
        }
      } else {
        this.isLoading = false;
        this.isComplete = true;
      }
    }, 500),
    async handleInputOnLocationAutocomplete(value) {
      this.input = value;
      this.suggestions = [];

      if (value) {
        this.isLoading = true;
        this.isComplete = false;

        this.getLocationSuggestions(value);
      }
    },

    displayLoc(location) {
      this.isEditable = false;

      if (this.input === '') return (this.input = '');

      if (location) {
        this.input = location.name;
      } else {
        return (this.input = '');
      }

      const optionCell = {
        columnName: this.columnType.toLowerCase(),
        data: location.place_id,
      };

      this.wantedLocations[0] = optionCell;

      if (!this.job._id) {
        return this.$emit('update-cell-data', this.wantedLocations[0]);
      }

      this.$emit('update-cell-data', {
        ...this.wantedLocations[0],
        jobId: this.job._id,
      });
    },
    setLocationEditableToFalse() {
      this.isEditable = false;
    },
    setFocusOnDropdown: debounce(async function() {
      this.$refs?.companyDropdown?.$refs?.input?.$refs?.input?.focus();
    }, 100),
  },
  watch: {
    'job.locations'() {
      this.input = getCompleteAddressOrTag(this.job.locations?.[0]) || '';
    },
    isEditable: {
      handler(newValue) {
        if (newValue === true) {
          this.setFocusOnDropdown()
        }
      }
    }
  }
}
</script>

<style lang="scss">
td {
  max-width: 8vw;
  width: 11%;
  max-height: 5vh;
  height: 5%;
  .bc-autocomplete {
    background-color: $color-white;
    & :deep() {
      .bc-autocomplete__dropdown {
        width: 250px;
      }
    }
    .bc-input {
      margin-top: 0;
      &__wrapper {
        border-color: $color-primary;
        border-radius: 0;
        #location {
          color: $color-primary;
          padding: 12px 0 12px 0;
          overflow: hidden;
        }
      }
    }
  }
}
</style>

