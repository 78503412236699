<script lang="ts" setup>
  import {
    Candidate,
    CandidateBasicSocialInfo,
    CandidateContactInfo,
  } from '@/domains/models/Candidate';
  import { computed, defineEmits, defineProps, ref, watch, withDefaults } from 'vue';
  import { ValidationObserver, ValidationProvider, Validator } from 'vee-validate';
  import BcInputPhone from '@/ui-kit/components/BcInputPhone/BcInputPhone.vue';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { isPhoneNumber } from '@/common-old/macros/regex';
  import BcInput from '@/ui-kit/components/BcInput/BcInput.vue';
  import { isLinkedinSlug } from '@/macros/socials/regex';
  import { linkedinPersonUrl } from '../../../../macros/socials/links';
  import { coderController } from '@/managers/coders/controller';
  import { useStore } from '@/store';

  interface Props {
    candidate: Candidate;
    isCreating?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    isCreating: false,
  });
  const emit = defineEmits<{
    (e: 'close-edit'): void,
    (e: 'update-candidate', value: CandidateContactInfo & CandidateBasicSocialInfo): void
  }>();
  Validator.extend('isPhoneValid', {
    validate: value => !value,
    getMessage: '', // TODO figure out way to use i18n in this scope -> $t('toast.phone-is-invalid')
  });

  const store = useStore();

  const closeEdit = () => {
    emit('close-edit');
  };

  const editedProfile = ref<CandidateContactInfo & CandidateBasicSocialInfo>({
    phone: props.candidate.phone || '',
    phones: props.candidate.phones || [''],
    email: props.candidate.email || '',
    emails: props.candidate.emails || [''],
    social: {
      linkedin: props.candidate.social?.linkedin ? props.candidate.social?.linkedin.split(linkedinPersonUrl)[1] : props.candidate.social?.linkedin || '',
    },
  });
  const isPhoneValid = ref<boolean>(true);
  const isPhoneValidOrEmpty = computed(() => {
    return isPhoneValid.value || !editedProfile.value.phone;
  });
  const selectedCards = computed(() => {
    return store.state.card.selectedCards;
  });

  const purgedProfile = computed(() => ({
    ...editedProfile.value.email && { email: editedProfile.value.email },
    ...!editedProfile.value.email && { email: '' },
    ...editedProfile.value.phone && { phone: editedProfile.value.phone },
    ...!editedProfile.value.phone && { phone: '' },
    ...editedProfile.value.phones?.length && { phones: editedProfile.value.phones.filter(phone => phone) },
    ...editedProfile.value.emails?.length && { emails: editedProfile.value.emails.filter(email => email) },
    social: {
      ...props.candidate.social,
      linkedin: editedProfile.value.social.linkedin ? `${linkedinPersonUrl}${editedProfile.value.social.linkedin}` : '',
    },
  }));

  const saveCandidate = async() => {
    if (isPhoneValidOrEmpty.value) {
      const { data } = await coderController.editProfile({
        id: props.candidate._id,
        data: purgedProfile.value,
      });
      emit('update-candidate', data);

      // To update the emails on selected cards
      if (selectedCards.value?.length) {
        const newCards = [];

        for (const card of selectedCards.value) {
          if (card.resource === 'companies') {
            const contacts = card._contacts;
            const findedContactIndex = contacts.findIndex((contact: any) => contact._id === props.candidate._id);
            if (findedContactIndex !== -1) {
              contacts[findedContactIndex] = {
                ...contacts[findedContactIndex],
                email: data.email,
                emails: data.emails,
              }
            }
            newCards.push({
              ...card,
              _contacts: contacts,
            })
          }
        }

        if (newCards.length) {
          store.dispatch('setSelectedCards', newCards);
        }
      }
      closeEdit();
    }
  }

  watch(() => editedProfile.value, () => {
    if (props.isCreating) {
      emit('update-candidate', purgedProfile.value);
    }
  }, { deep: true });
</script>

<template>
  <div class="relative flex w-full flex-row items-start gap-8 rounded-md bg-neutral-200 px-5 py-4">
    <ValidationObserver
      ref="observer"
      class="flex w-full flex-col gap-2.5"
      tag="form"
      @submit.prevent>
      <div class="flex flex-col items-start">
        <div class="relative flex w-full flex-row flex-wrap items-start gap-x-3.5 gap-y-2.5">
          <ValidationProvider
            v-slot="{ errors, valid }"
            :rules="{ required: false, email: true }"
            name="Email"
            slim>
            <bc-input
              ref="email"
              v-model="editedProfile.email"
              :class="{ invalid: errors.length > 0 }"
              :error="errors[0]"
              :label="$t('contacts.email')"
              :required="false"
              :valid="valid && !!editedProfile.email"
              class="w-full max-w-1/2-gap-3.5"
              icon="mail"
              name="email"
              type="text">
            </bc-input>
          </ValidationProvider>
          <ValidationProvider
            v-for="(email, index) in editedProfile.emails"
            :key="`email-${index}`"
            v-slot="{ errors, valid }"
            :name="$t('contacts.email')"
            :rules="{ required: false, email: true }"
            slim>
            <bc-input
              ref="email"
              v-model="editedProfile.emails[index]"
              :class="{ invalid: errors.length > 0 }"
              :error="errors[0]"
              :label="`${$t('contacts.email')} pro`"
              :label-placeholder="false"
              :placeholder="`${$t('contacts.email')} pro`"
              :required="false"
              :valid="valid && editedProfile.emails[index]"
              class="w-full max-w-1/2-gap-3.5"
              icon="mail"
              name="email"
              type="text">
            </bc-input>
          </ValidationProvider>
        </div>
        <button
          class="mt-2.5 text-sm text-blue-500 hover:underline"
          @click="editedProfile.emails.push('')">
          + {{ $t('generics.add-mail') }}
        </button>
      </div>
      <div class="flex flex-col items-start">
        <div class="relative flex w-full flex-row flex-wrap items-start gap-x-3.5 gap-y-2.5">
          <ValidationProvider
            ref="phoneProvider"
            v-slot="{ errors }"
            :name="$t('contacts.phone')"
            :rules="{ required: false, isPhoneValid: !isPhoneValidOrEmpty }"
            slim>
            <BcInputPhone
              ref="phone"
              v-model="editedProfile.phone"
              :class="{ invalid: errors.length > 0 }"
              :error="errors[0]"
              :label="$t('contacts.phone')"
              :label-placeholder="false"
              :placeholder="$t('contacts.phone')"
              :required="false"
              class="max-w-1/2-gap-3.5"
              icon="phone"
              name="phone"
              type="text"
              @error="isPhoneValid = false"
              @valid="isPhoneValid = true"
              @keydown.native.enter.prevent>
            </BcInputPhone>
          </ValidationProvider>
          <ValidationProvider
            v-for="(phone, index) in editedProfile.phones"
            :key="index"
            :ref="`phoneProvider-${index}`"
            v-slot="{ errors }"
            :name="$t('contacts.phone')"
            :rules="{
              required: false,
              regex: isPhoneNumber,
              isPhoneValid: !isPhoneValid,
            }"
            slim>
            <BcInputPhone
              ref="phone"
              v-model="editedProfile.phones[index]"
              :class="{ invalid: errors.length > 0 }"
              :error="errors[0]"
              :label="`${$t('contacts.phone')} pro`"
              :label-placeholder="false"
              :placeholder="`${$t('contacts.phone')} pro`"
              :required="false"
              class="max-w-1/2-gap-3.5"
              icon="phone"
              name="phone"
              type="text"
              @keydown.native.enter.prevent>
            </BcInputPhone>
          </ValidationProvider>
        </div>
        <button
          class="mt-2.5 text-sm text-blue-500 hover:underline"
          @click="editedProfile.phones.push('')">
          + {{ $t('generics.add-phone') }}
        </button>
      </div>
      <ValidationProvider
        v-slot="{ errors, valid }"
        :rules="{ required: false, regex: isLinkedinSlug }"
        name="LinkedIn"
        slim>
        <bc-input
          ref="linkedin"
          v-model="editedProfile.social.linkedin"
          :class="{ invalid: errors.length > 0 }"
          :error="errors[0]"
          :label="$t('generics.linkedin')"
          :placeholder="$t('generics.linkedin')"
          :required="false"
          :valid="valid && !!editedProfile.social.linkedin"
          class="w-full"
          icon="linkedin"
          name="linkedin"
          type="text">
          <template #prepend="{ isFocus }">
            <div
              class="-mr-1 h-full shrink-0 items-center rounded-l-[5px] bg-blue-500 p-2.5 text-white">
              {{ linkedinPersonUrl }}
            </div>
          </template>
        </bc-input>
      </ValidationProvider>
      <div
        v-if="!isCreating"
        class="ml-auto flex flex-row gap-2.5">
        <BcButton
          icon-left="cross"
          size="small"
          type="outlined"
          @click.native="closeEdit">
          {{ $t('global.cancel') }}
        </BcButton>
        <BcButton
          icon-left="check"
          native-type="submit"
          size="small"
          @click.native="saveCandidate">
          {{ $t('global.save') }}
        </BcButton>
      </div>
    </ValidationObserver>
  </div>
</template>

<style lang="scss" scoped>
  .panel-coder-edit-coordinates {
    &__prepend {
      background: $color-primary;
      color: $color-white;
      height: 40px;
      padding: 0 10px;
      align-items: center;
      margin-right: -15px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      &--empty {
        background: $color-blue-medium;
      }
    }
  }
</style>
