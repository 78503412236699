<template>
  <div class="card-informations-pokes-content">
    <div class="card-informations-pokes-content__main-wrapper">
      <div class="card-informations-pokes-content__caption">
        <picture-avatar
          :img-src="cardContent.pictureUrl ? cardContent.pictureUrl : ''"
          class="card-informations-pokes-content__picture">
        </picture-avatar>
        <card-informations-pokes-content-contracts :contracts="cardContent.contracts">
        </card-informations-pokes-content-contracts>
      </div>
      <!-- -->
      <title-pokes
        :text-left="cardContent.name"
        :text-right="cardContent.title"
        class="card-informations-pokes-content__title">
        <div
          class="card-informations-pokes-content__remote-container"
          slot="icon"
          v-if="cardContent.remote">
          <div
            class="icon-home"
            v-tooltip="'Full remote uniquement'">
          </div>
        </div>
      </title-pokes>
      <!-- -->
      <!-- bottom of the card -->
      <div class="card-informations-pokes-content__infos-wrapper">
        <card-informations-tags
          :tags="cardContent.tags"
          class="card-informations-pokes-content__tags">
        </card-informations-tags>
      </div>
    </div>
  </div>
</template>

<script>
  import PictureAvatar from '@/components/UIKit/Picture/PictureAvatar';
  import CardInformationsTags from '@/components/Card/CardInformations/CardInformationsUtils/CardInformationsTags';
  import TitlePokes from '@/components/UIKit/Title/TitlePokes';
  // Macros
  import countdown from '@/common-old/mixins/Timer/countdown';
  import CardInformationsPokesContentContracts
    from '@/components/Card/CardInformations/CardInformationsPokes/CardInformationsPokesContent/CardInformationsPokesContentContracts';
  // state
  export default {
    name: 'card-informations-pokes-content',
    components: {
      CardInformationsPokesContentContracts,
      PictureAvatar,
      CardInformationsTags,
      TitlePokes
    },
    props: {
      cardContent: {
        type: Object
      }
    },
    mixins: [countdown]
  };
</script>

<style lang="scss" scoped>
  .card-informations-pokes-content {
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    @include bp('tablet') {
      align-items: flex-end;
    }
    &__main-wrapper {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      border: 1px solid $color-blue-hawkes;
      flex-direction: column;
    }
    &__title {
      padding: 15px;
    }
    &__caption {
      position: relative;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      transition: 0.3s;
      overflow: hidden;
      padding: 20px 15px 0;
      &:before {
        position: absolute;
        background-color: $color-blue-hawkes;
        content: "";
        width: calc(100% + 1px);
        height: 100%;
        clip-path: polygon(0 0, 100% 0, 100% 47%, 0 81%);
        left: 0;
        top: 0;
      }
    }
    &__picture {
      max-height: 50px;
      max-width: 50px;
    }
    &__infos-wrapper {
      width: 100%;
      padding: 0 15px 20px;
      flex-direction: column;
    }
  }
</style>
