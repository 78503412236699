import { setListNotifications } from '@/managers/lists/useCases/setListNotifications';

const setNotifications = (id, notifications) => {
  return setListNotifications(id, notifications);
};

export const listController = {
  setNotifications,
};

export default {
  listController,
};
