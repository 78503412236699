<template>
  <div class="panel-process-details-job-introduced">
    <p class="panel-process-details-job-introduced__name">
      {{ jobName }}
    </p>
    <div class="panel-process-details-job-introduced__wrapper" v-if="isEditing">
      <p class="panel-process-details-job-introduced__title icon-arrow-left">
        {{ $t('timeline.presentation') }}
      </p>
      <div class="panel-process-details-job-introduced__subwrapper">
        <label
          class="panel-process-details-job-introduced__label"
          for="salary-fixed">
          {{ $t('companies.panel-hunt-process.gross-fixed-salary') }}
        </label>
        <bc-input-number
          :bc-input-border-color-opacity-blurred="0.5"
          bc-input-border-radius="2px"
          bc-input-padding="0"
          class="panel-process-details-job-introduced__input"
          id="salary-fixed"
          max="200"
          min="0"
          v-model.number="introductionSalary">
        </bc-input-number>
        <p class="panel-process-details-job-introduced__label">
          {{ $t('hunt.k-currency-year') }}
        </p>
      </div>
    </div>
    <div
      v-else
      class="panel-process-details-job-introduced__container"
    >
      <router-link
        :to="{
          ...$route,
          params: {
            id: jobId,
          },
          query: {
            ...$route.query,
            type: 'hunt',
            'company-id': process._company._id
          }
        }"
        class="panel-process-details-job-introduced__link">
        <bc-button
          :bc-background-color-opacity="0.8"
          bc-padding="8px 20px"
          bc-text-size="14px"
          class="panel-process-details-job-introduced__button">
          {{ $t('companies.panel-hunt-process.see-the-job') }}
        </bc-button>
      </router-link>
    </div>
  </div>
</template>

<script>
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import BcInputNumber from '@/legacy/ui-kit/components/Input/BcInputNumber';

  export default {
    name: 'panel-process-details-job-introduced',
    components: {
      BcInputNumber,
      BcButton
    },
    props: {
      process: {
        type: Object,
        default: () => ({
          _customJob: {}
        })
      },
      isEditing: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      introductionSalary: {
        get() {
          return this.$route.query['new-introduction-salary'] >= 0 ? this.$route.query['new-introduction-salary'] : this.process.introductionSalary;
        },
        set(value) {
          this.$router.replace({ query: { ...this.$route.query, 'new-introduction-salary': value } });
        }
      },
      isFailed() {
        return this.process.failedAt && !this.process.offeredAt && !this.process.dealtAt;
      },
      jobId() {
        return this.process._customJob?._id;
      },
      jobName() {
        return this.process._customJob?.title || '';
      }
    }
  };
</script>

<style lang=scss scoped>
  .panel-process-details-job-introduced {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  &__name {
    font-weight: 400;
    font-size: $font-size-m;
    color: $color-tertiary;
  }

  &__title {
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    font-size: $font-size-s;
    color: $color-secondary-light;
    display: flex;
    align-items: center;

    &--red {
      color: $color-red-mandy;
    }

    &:before {
      margin-right: 10px;
      font-size: $font-size-l;
      transform: rotate(180deg);
    }
  }

  &__header {
    align-items: center;
    margin-bottom: 5px;
  }

  &__tag {
    border-radius: 4px;
    font-size: $font-size-xs;
    padding: 3px 10px;
    margin-left: 10px;
  }

  &__text {
    display: flex;
    color: $color-secondary;
    font-size: $font-size-s;
    line-height: 17px;
    padding-left: 28px;

    &--bold {
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      display: flex;
    }
  }

  &__container {
    justify-content: space-between;
    align-items: center;
  }

  &__wrapper {
    flex-direction: column;
  }

  &__subwrapper {
    align-items: center;
    padding-left: 28px;
  }

  &__label {
    color: $color-secondary;
    font-size: $font-size-s;
    line-height: 17px;
  }

  &__input {
    margin: 0 5px;
    max-width: 40px;
    text-align: center;
    min-height: 30px;
    font-size: $font-size-s;
  }
}
</style>
