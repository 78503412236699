import { domains } from '@/common-old/macros/market';
import { roles } from '@/common-old/macros/roles';
import { contracts } from '@/common-old/macros/contracts';
import { experience } from '@/common-old/macros/experience';

export const status = {
  pending: 1,
  coached: 2,
  online: 3,
  unskilled: 4,
  inactive: 5,
  banned: 6,
};

export const placeholder = {
  notProcess: ('candidates.not-in-process'),
  pending: ('candidates.pending'),
  presented: ('candidates.presented'),
  introduced: ('candidates.introduced'),
  processed: ('generics.processing'),
  finalised: ('candidates.finalised'),
  offered: ('candidates.offered'),
  dealt: ('candidates.waiting-to-start'),
  garden: ('candidates.garden'),
  toQualify: ('candidates.to-qualify'),
  toProcess: ('candidates.to-send-in-process'),
  ignored: ('candidates.ignored'),
  onboarding: ('generics.onboarding-cap'),
  validated: ('candidates.deal'),
};
 
export const pipeStatus = {
  nothing: 0,
  pending: 1,
  introduced: 2,
  processed: 3,
  finalised: 4,
  offered: 5,
  dealt: 6,
  garden: 7,
  toQualify: 8,
  toProcess: 9,
  ignored: 10,
  onboarding: 11,
  validated: 12,
};

export const processState = {
  [pipeStatus.nothing]: placeholder.notProcess,
  [pipeStatus.pending]: placeholder.pending,
  [pipeStatus.introduced]: placeholder.introduced,
  [pipeStatus.processed]: placeholder.processed,
  [pipeStatus.finalised]: placeholder.finalised,
  [pipeStatus.offered]: placeholder.offered,
  [pipeStatus.dealt]: placeholder.dealt,
  [pipeStatus.garden]: placeholder.garden,
  [pipeStatus.toQualify]: placeholder.toQualify,
  [pipeStatus.toProcess]: placeholder.toProcess,
  [pipeStatus.ignored]: placeholder.ignored,
  [pipeStatus.onboarding]: placeholder.onboarding,
  [pipeStatus.validated]: placeholder.validated,
};

export const processStateDate = {
  [pipeStatus.nothing]: 'createdAt',
  [pipeStatus.pending]: 'createdAt',
  [pipeStatus.introduced]: 'introducedAt',
  [pipeStatus.processed]: 'processedAt',
  [pipeStatus.finalised]: 'finalisedAt',
  [pipeStatus.offered]: 'finalisedAt',
  [pipeStatus.dealt]: 'dealtAt',
  [pipeStatus.garden]: 'createdAt',
  [pipeStatus.toQualify]: 'createdAt',
  [pipeStatus.toProcess]: 'createdAt',
  [pipeStatus.ignored]: 'createdAt',
  [pipeStatus.onboarding]: 'onboardedAt',
  [pipeStatus.validated]: 'validatedAt',
};

export const getProcessState = (value) => {
  return processState[value];
};

export default {
  pipeStatus,
  status,
  domains,
  roles,
  contracts,
  experience,
  processState,
  getProcessState,
  placeholder,
};
