<template>
  <div class="panel-process-details-coach">
    <div
      v-if="isLoading"
      class="panel-process-details-coach__container">
      <spinner></spinner>
    </div>
    <div
      v-else
      class="panel-process-details-coach__container">
      <component
        :is="modalComponent"
        v-if="modalComponent"
        :process="process"
        class="panel-process-details-coach__wrapper"
        @profile-updated="$emit('profile-updated', $event)"
        @process-updated="updateProcess"
        @update-timeline="updateTimeline">
      </component>
      <panel-process-details-form-deal
        v-if="isVisibleFormDeal"
        :process="process"
        class="panel-process-details-coach__wrapper"
        @process-updated="updateProcess">
      </panel-process-details-form-deal>
      <panel-process-details-payment
        v-if="isDebtor"
        :process="process"
        class="panel-process-details-coach__wrapper"
        @update-timeline="updateTimeline"
        @process-updated="updateProcess">
      </panel-process-details-payment>
      <panel-process-details-recap
        :process="process"
        class="panel-process-details-coach__wrapper"
        @process-updated="updateProcess"
      />
      <panel-process-details-job
        :process="process"
        class="panel-process-details-coach__wrapper"
        @process-updated="updateProcess">
      </panel-process-details-job>
      <panel-process-details-coder
        :coder="process._coder"
        class="panel-process-details-coach__wrapper">
      </panel-process-details-coder>
    </div>
  </div>
</template>

<script>
  import PanelProcessDetailsJob from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsJob';
  import PanelProcessDetailsCoder from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsCoder';
  import PanelProcessDetailsCompany
    from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsCompany';
  import PanelProcessDetailsOffer from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsOffer';
  import PanelProcessDetailsConfirm
    from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsConfirm';
  import PanelProcessDetailsDealt from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsDealt';
  import PanelProcessDetailsOnboarding
    from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsOnboarding';
  import PanelProcessDetailsFormDeal
    from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsFormDeal';
  import Spinner from '@/components/UIKit/Spinner/Spinner';
  import PanelProcessDetailsRecap from './PanelProcessDetailsRecap.vue';

  import { getStepNameByProcessStatus } from '@/managers/processes/formatters/processDeserializers';
  //
  import { apiStatus } from '@/macros/processes/process';
  import { bluecodersCompanyId } from '@/macros/utils';
  import PanelProcessDetailsPayment
    from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsPayment';
  import moment from 'moment/min/moment-with-locales';

  export default {
    name: 'panel-process-details-coach',
    components: {
      PanelProcessDetailsPayment,
      Spinner,
      PanelProcessDetailsOnboarding,
      PanelProcessDetailsDealt,
      PanelProcessDetailsConfirm,
      PanelProcessDetailsOffer,
      PanelProcessDetailsCompany,
      PanelProcessDetailsCoder,
      PanelProcessDetailsJob,
      PanelProcessDetailsFormDeal,
      PanelProcessDetailsRecap
    },
    props: {
      process: {
        type: Object,
        default: () => ({
          _coder: {},
        }),
      },
      isLoading: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        types: {
          pending: 'panel-process-details-confirm',
          introduced: '',
          processed: '',
          finalised: 'panel-process-details-offer',
          offered: '',
          dealt: 'panel-process-details-dealt',
          onboarding: 'panel-process-details-onboarding',
          validated: '',
        },
        processStatus: apiStatus,
      };
    },
    computed: {
      isDrop() {
        return this.process.failedAt && this.process.dealtAt;
      },
      modalComponent() {
        if (!this.isDrop) {
          const step = getStepNameByProcessStatus(this.process.status);
          return this.types[step];
        }

        return '';
      },
      isVisibleFormDeal() {
        return this.process.status >= this.processStatus.dealt && this.process._company._id !== bluecodersCompanyId;
      },
      isDebtor() {
        const limitOldDate = moment().subtract(3, 'months')
        const isFormDealOld = moment(this.process?.formDeal?.sentAt).isBefore(limitOldDate)

        return this.process?.formDeal?.sentAt && !isFormDealOld && !this.process?.payedAt;
      },
    },
    methods: {
      updateProcess(process) {
        this.$emit('process-updated', process);
      },
      updateTimeline() {
        this.$emit('update-timeline');
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-process-details-coach {
    background: $color-neutre-3;
    flex-grow: 1;
    overflow: auto;

    &__container {
      width: 100%;
      flex-direction: column;
      padding: 30px;
      height: fit-content;
    }

    &__wrapper {
      margin-bottom: 20px;
      justify-content: space-between;

      &:last-of-type {
        margin-top: 15px;
        margin-bottom: 0;
      }
    }

    &__notes {
      width: calc(50% - 10px);
    }
  }
</style>
