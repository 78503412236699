<template>
  <div
    @scroll="setScrollPosition($event)"
    class="panel-chat-messages col-xs-12"
    infinite-wrapper
    ref="messages">
    <div
      :class="{'panel-chat-messages__container--marginless': messages.length === 0}"
      class="panel-chat-messages__container col-xs-12">
      <infinite-loading
        :force-use-infinite-wrapper="true"
        @infinite="loadMessages"
        class="panel-chat-messages__infinite-scroll"
        direction="top">
        <bc-spinner
          :loading="true"
          slot="spinner">
        </bc-spinner>
        <div
          class="panel-chat-messages__no-result"
          slot="no-results">
          <p v-if="messages.length === 0">
            Bienvenue dans le chat !
          </p>
        </div>
        <span slot="no-more"></span>
      </infinite-loading>
      <panel-chat-messages-list
        :messages="messages"
        class="panel-chat-messages__item">
      </panel-chat-messages-list>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import InfiniteLoading from 'vue-infinite-loading';
  // import moment from 'moment/min/moment-with-locales';
  import PanelChatMessagesList from '@/components/Panel/PanelChat/PanelChatMessages/PanelChatMessagesList';
  import BcSpinner from '@/legacy/ui-kit/components/Spinner/BcSpinner';
  //

  /**
   * The view where all the messages will be shown and with an infinite scroll on the top
   */
  export default {
    name: 'panel-chat-messages',
    components: {
      PanelChatMessagesList,
      InfiniteLoading,
      BcSpinner
    },
    computed: {
      ...mapState({
        messages: state => state.chat.messages
      })
    },
    data() {
      return {
        /**
         * The position inside the scrollbar
         */
        scrollPosition: 0,
        /**
         * Used to determinate which page the loadMessages function should request
         */
        page: 1,
        /**
         * Used to see if there is no more messages
         */
        next: false,
        /**
         * Used to determinate the scroll position
         */
        height: 0,
        /**
         * Used to determinate the scroll position if the user is scrolling to the top
         */
        scrollDirection: 'up'
      };
    },
    props: {
      isLoading: {
        type: Boolean
      }
    },
    created() {
      this.$store.dispatch('setMessages', []);
    },
    methods: {
      setScrollPosition(event) {
        this.scrollPosition = event.target.scrollTop;
      },
      async loadMessages($state) {
        /**
         * We set a new height to calculate the scroll position after the push of new messages in the array
         */
        const response = await this.$store.dispatch('getMessages', {
          id: this.$route.params.id,
          page: this.page,
          messages: this.messages
        });

        this.$emit('checkMessages', response.data.messages.length === 0);
        /**
         * We set the next to true or false depending of the response
         */
        this.next = response.data.next;

        ++this.page;

        if (response.data.messages.length === 0) {
          $state.complete();
        } else {
          $state.loaded();
        }
        /**
         * If there is no page, we close the infinite scroll
         */
        if (!this.next) {
          $state.complete();
        }
      }
    },
    mounted() {
      /**
       * When we load the component, we set the scroll to the bottom of the chat
       */
      this.$nextTick(() => {
        if (this.scrollDirection === 'down' && this.$refs.messages) {
          this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
        }
      });
    },
    updated() {
      this.$nextTick(() => {
        if (this.$refs.messages) {
          this.height = this.$refs.messages.scrollHeight;
        }
      });
    },
    async beforeDestroy() {
      /**
       * We reset the messages
       */
      this.$store.dispatch('setMessages', []);
    },
    watch: {
      /**
       * When the scrollPosition will change, we will determinate if the direction of the scroll is up or down
       */
      scrollPosition(newVal, oldVal) {
        this.scrollDirection = newVal > oldVal ? 'down' : 'up';
      },
      height(newVal, oldVal) {
        if (this.scrollDirection === 'up' && this.scrollPosition <= 100) {
          this.$refs.messages.scrollTop = newVal - oldVal;
        } else {
          this.$refs.messages.scrollTop = newVal;
        }
      },
      /**
       * When the route change, we will empty the list and the timer, and trigger the infinite loader again
       */
      $route: {
        handler(to, from) {
          if (!to.query.modal && (from && !from.query.modal)) {
            /**
             * We set the direction to down to set the scroll position to bottom
             */
            this.scrollDirection = 'down';
            /**
             * We reset the messages
             */
            this.$store.dispatch('setMessages', []);
            /**
             * We set the actual page to 1
             */
            this.page = 1;
            /**
             * We set the scroll to bottom of the div
             */
            this.$refs.messages.scrollTop = this.$refs.messages.scrollTopMax;
          }
        },
        immediate: true
      }
    }
  };
</script>

<style lang="scss" scoped>
  .panel-chat-messages {
    flex-grow: 1;
    justify-content: flex-start;
    overflow-y: auto;
    background-color: white;
    z-index: 1;

    &__container {
      flex-shrink: 0;
      margin-top: auto;

      &--marginless {
        margin: auto;
      }
    }

    &__wrapper {
      flex-direction: column;
      align-items: center;
    }

    &__title {
      font-size: $font-size-m;
      line-height: 19px;
      color: $color-blue-dark-cello;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    &__item {
      max-width: 100%;
    }

    &__text {
      height: 0;
    }

    &__no-result {
      font-size: $font-size-l;
      color: $color-blue-dodger;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
    }

    &__infinite-scroll {
      height: 70px;
      align-items: center;
      justify-content: center;
    }
  }
</style>
