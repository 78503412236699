import salaryMacros from '@/common-old/macros/salary';

export default {
  // TODO Refacto ce module pour supprimer les states
  state: {
    isActiveFilter: false,
    isActiveMatching: false,
    searchbarFilters: {},
    searchbarTags: [],
    searchbarFiltersTags: [],
  },
  actions: {
    pushSearchbarFiltersTags({ commit }, data) {
      commit('pushSearchbarFiltersTags', data);
    },
    setSearchbarFiltersTags({ commit }, data) {
      commit('setSearchbarFiltersTags', data);
    },
    popSearchbarFiltersTags({ commit }, data) {
      commit('popSearchbarFiltersTags', data);
    },
    replaceSearchbarFiltersTags({ commit }, data) {
      commit('replaceSearchbarFiltersTags', data);
    },
    replaceSearchbarFiltersCustomFieldTags({ commit }, data) {
      commit('replaceSearchbarFiltersCustomFieldTags', data);
    },
    spliceSearchbarFiltersTags({ commit }, data) {
      commit('spliceSearchbarFiltersTags', data);
    },
    setIsActiveMatching({ commit }, data) {
      commit('setIsActiveMatching', data);
    },
    setIsActiveFilter({ commit }, data) {
      commit('setIsActiveFilter', data);
    },
    resetSearchbarFilters({ commit }, data) {
      commit('resetSearchbarFilters', data);
    },
    resetSearchbarFiltersTags({ commit }) {
      commit('resetSearchbarFiltersTags');
    },
    resetAndCloseSearchBar({ commit }) {
      commit('setIsActiveFilter', false);
      commit('resetSearchbarFiltersTags');
    },
  },
  mutations: {
    setIsActiveFilter(state, data) {
      state.isActiveFilter = data;
    },
    setIsActiveMatching(state, data) {
      state.isActiveMatching = data;
    },
    setSearchbarFilters(state, data) {
      state.searchbarFilters = { ...state.searchbarFilters, ...data };
    },
    resetSearchbarFilters(state) {
      state.searchbarFilters = {};
    },
    setSearchbarFiltersTags(state, data) {
      state.searchbarFiltersTags = data;
    },
    pushSearchbarFiltersTags(state, data) {
      state.searchbarFiltersTags.push(data);
    },
    replaceSearchbarFiltersTags(state, data) {
      const index = state.searchbarFiltersTags.findIndex(tag => tag.type === data.type);
      if (index >= 0) {
        state.searchbarFiltersTags.splice(index, 1);
      }
      state.searchbarFiltersTags.push(data);
    },
    replaceSearchbarFiltersCustomFieldTags(state, data) {
      const filtred = state.searchbarFiltersTags.filter(tag => tag.key !== data.key);
      state.searchbarFiltersTags = [...filtred, data];
    },
    popSearchbarFiltersTags(state) {
      state.searchbarFiltersTags.pop();
    },
    spliceSearchbarFiltersTags(state, index) {
      state.searchbarFiltersTags.splice(index, 1);
    },
    resetSearchbarFiltersTags(state) {
      state.searchbarFiltersTags = state.searchbarFiltersTags.filter(tag => tag.disabled);
    },
  },
  getters: {
    statusSelected(state) {
      const tags = state.searchbarFiltersTags.filter(tag => tag.type === 'status');
      return tags.map(tag => tag.query).join(',');
    },
    locationsSelected(state) {
      const tags = state.searchbarFiltersTags.filter(tag => tag.type === 'location');
      return tags.map(tag => `${tag.name}+${tag.query.placeId}`).join('|');
    },
    contractsSelected(state) {
      const tags = state.searchbarFiltersTags.filter(tag => tag.type === 'contracts');
      return tags.map(tag => tag.query).join(',');
    },
    experienceSelected(state) {
      const tags = state.searchbarFiltersTags.filter(tag => tag.type === 'experience');
      return tags.map(tag => tag.query).join(',');
    },
    roleSelected(state) {
      const tags = state.searchbarFiltersTags.filter(tag => tag.type === 'role');
      return tags.map(tag => tag.query).join(',');
    },
    partnershipSelected(state) {
      const tags = state.searchbarFiltersTags.filter(tag => tag.type === 'partnership');
      return tags.map(tag => tag.query).join(',');
    },
    domainsSelected(state) {
      const tags = state.searchbarFiltersTags.filter(tag => tag.type === 'domains');
      return tags.map(tag => tag._id).join(',');
    },
    remoteSelected(state) {
      const remoteValues = ['remote-available', 'remote-partial', 'remote-full'];
      const tags = state.searchbarFiltersTags.filter(tag => remoteValues.includes(tag.type));

      return tags.map(tag => tag.query).join(',');
    },
    technosSelected(state) {
      const tags = state.searchbarFiltersTags.filter(tag => tag.type === 'technos');
      return tags.map(tag => tag._id).join(',');
    },
    professionsSelected(state) {
      const tags = state.searchbarFiltersTags.filter(tag => tag.type === 'professions');
      return tags.map(tag => tag._id).join(',');
    },
    companiesSelected(state) {
      const tags = state.searchbarFiltersTags.filter(tag => tag.type === 'companies');
      return tags.map(tag => tag._id).join(',');
    },
    searchSelected(state) {
      const tags = state.searchbarFiltersTags.filter(tag => tag.type === 'search');
      return tags.map(tag => tag.name).join(',');
    },
    ratingSelected(state) {
      const tags = state.searchbarFiltersTags.filter(tag => tag.type === 'rating');
      return tags.map(tag => tag.query).join(',');
    },
    lastCoachActivitySelected(state) {
      return state.searchbarFiltersTags.find(tag => tag.type === 'lastCoachActivity');
    },
    isPhoneSelected(state) {
      return state.searchbarFiltersTags.filter(tag => tag.type === 'phone').length > 0;
    },
    isCVSelected(state) {
      return state.searchbarFiltersTags.filter(tag => tag.type === 'cv').length > 0;
    },
    isEmailSelected(state) {
      return state.searchbarFiltersTags.filter(tag => tag.type === 'email').length > 0;
    },
    isLinkedinSelected(state) {
      return state.searchbarFiltersTags.filter(tag => tag.type === 'linkedin').length > 0;
    },
    /**
     * Check if the salary filters has min-salary > 0 or max-salary < salary.max-salary
     * If not, we will hide the tag salary
     */
    salarySelected(state) {
      return state.searchbarFiltersTags.find(tag => tag.type === 'salary');
    },
    customFieldsSelected(state) {
      return state.searchbarFiltersTags.filter(tag => tag.type === 'customField');
    },
    isValidSalary(state) {
      const salaryTag = state.searchbarFiltersTags.find(tag => tag.type === 'salary');

      if (salaryTag) {
        return salaryTag.query.salaryMin !== salaryMacros.salaryMin || salaryTag.query.salaryMax !== salaryMacros.salaryMax;
      }

      return false;
    },
    /**
     * The computed data which will be pushed inside the route when the user will validate the advanced filters results
     */
    searchbarFiltersComputed: (state, getters) => {
       const filter = {
        contracts: getters.contractsSelected ? getters.contractsSelected : undefined,
        status: getters.statusSelected ? getters.statusSelected : undefined,
        experiences: getters.experienceSelected ? getters.experienceSelected : undefined,
        roles: getters.roleSelected ? getters.roleSelected : undefined,
        partnership: getters.partnershipSelected ? getters.partnershipSelected : undefined,
        remotes: getters.remoteSelected ? getters.remoteSelected : undefined,
        domains: getters.domainsSelected ? getters.domainsSelected : undefined,
        companies: getters.companiesSelected ? getters.companiesSelected : undefined,
        technos: getters.technosSelected ? getters.technosSelected : undefined,
        professions: getters.professionsSelected ? getters.professionsSelected : undefined,
        search: getters.searchSelected ? getters.searchSelected : undefined,
        locations: getters.locationsSelected ? getters.locationsSelected : undefined,
        phone: getters.isPhoneSelected ? getters.isPhoneSelected : undefined,
        cv: getters.isCVSelected ? getters.isCVSelected : undefined,
        email: getters.isEmailSelected ? getters.isEmailSelected : undefined,
        linkedin: getters.isLinkedinSelected ? getters.isLinkedinSelected : undefined,
        rating: getters.ratingSelected ? getters.ratingSelected : undefined,
        'salary-min': getters.isValidSalary ? getters.salarySelected.query.salaryMin : undefined,
        'salary-max': getters.isValidSalary ? getters.salarySelected.query.salaryMax : undefined,
        'last-coach-activity-from': getters.lastCoachActivitySelected ? getters.lastCoachActivitySelected.query.lastCoachActivityFrom : undefined,
        'last-coach-activity-to': getters.lastCoachActivitySelected ? getters.lastCoachActivitySelected.query.lastCoachActivityTo : undefined,
      };

      if (getters.customFieldsSelected.length) {
        getters.customFieldsSelected.forEach((customField) => {
          const value = customField.name;
          const cfKey = `cf-${customField.key}`
          if (filter[cfKey]) {
            filter[cfKey] += ',' + value;
          } else {
            filter[cfKey] = value;
          }
        });
      }

      return filter;
    },
  },
};
