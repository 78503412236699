<template>
  <div class="jobboard-config-list">
    <div v-if="isLoading" class="jobboard-config-list__loader">
      <bc-spinner></bc-spinner>
    </div>
    <div v-else class="jobboard-config-list__content">
      <JobboardConfigListItem
        :class="{ 'jobboard-config-list__separator': index < channelsFiltred.length -1 }"
        v-for="(channel, index) in channelsFiltred"
        :key="channel.id"
        :id="channel.id"
        :title="channel.title"
        :logo-url="channel.logoUrl"
        :is-activated="getIsActivated(channel.id)"
        :is-xtramile-enabled="isXtramileEnabled"
        :profile="profile"
        :name="channel.connectorName"
        :subscriptions-list="channelSubscriptionsList[channel.id]"
        @on-toggle="onActivate"
        @on-add="onAdd"
        @on-edit="onEdit"
        @on-delete="onDelete"
        @add-channel-subscription="addChannelSubscription"
        @set-channel-subscriptions="setChannelSubscriptions"
      ></JobboardConfigListItem>
    </div>
    <bc-modal
      :active="isModalOpen"
      @close="isModalOpen = false">
      <JobboardConfigChannel
        :channel="selectedChannel"
        :channelSubscriptionId="selectedChannelSubscriptionId"
        :config="config"
        @submit="onSubmitChannel"
        @cancel="isModalOpen = false"
      ></JobboardConfigChannel>
    </bc-modal>
    <modal-confirm
      :is-open="isModalDeleteOpen"
      icon="❌👋"
      :title="'Voulez-vous supprimer cette channel subscription ?'"
      :cancelText="'Non'"
      :confirmText="'Oui'"
      @close="onCloseModalConfirm"
      @on-confirm="onConfirmDelete"
    ></modal-confirm>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { activate, addChannelSubscriptions, deleteChannelSubscriptions } from '@/api/xtramile';
import { signIn } from '@/api/job-multipost';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import BcModal from '@/ui-kit/components/BcModal/BcModal';
import JobboardConfigListItem from './JobboardConfigListItem';
import JobboardConfigChannel from '@/components/Jobboard/JobboardConfigChannel/JobboardConfigChannel';
import ModalConfirm from '@/components/Modal/ModalConfirm';

export default {
  name: 'jobboard-config-list',
  components: {
    BcSpinner,
    BcModal,
    JobboardConfigListItem,
    JobboardConfigChannel,
    ModalConfirm,
  },
  props: {
    profile: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: true,
      isModalOpen: false,
      isModalDeleteOpen: false,
      selectedChannel: {},
      channelSubscriptionsList: {},
      selectedChannelSubscriptionId: '',
    }
  },
  mounted() {
    this.getChannelsList();
  },
  methods: {
    ...mapActions({
      getChannels: 'xtramile/getChannels',
      // updateConfigurationMultiposting: 'user/updateConfigurationMultiposting',
    }),
    async getChannelsList() {
      try {
        await this.getChannels();
        this.isLoading = false;
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.response?.data?.message || err.message,
        });
        this.isLoading = false;
      }
    },
    async onActivate({ id, activated }) {
      try {
        await activate({ id, activated, organizationId: this.profile._id });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.response?.data?.message || err.message,
        })
      }
    },
    onEdit({ channelId, channelSubscriptionId }) {
      this.selectedChannel = this.channels.find(channel => channel.id === channelId);
      this.selectedChannelSubscriptionId = channelSubscriptionId;
      this.isModalOpen = true;
    },
    onDelete(channelSubscriptionId) {
      this.isModalDeleteOpen = true;
      this.selectedChannelSubscriptionId = channelSubscriptionId;
    },
    onAdd(channelId) {
      this.selectedChannelSubscriptionId = '';
      this.selectedChannel = this.channels.find(channel => channel.id === channelId);
      this.isModalOpen = true;
    },
    addChannelSubscription({ channelId, channelSubscription }) {
      const currentList = [...this.channelSubscriptionsList[channelId] || []];
      const existIndex = currentList.findIndex(channelSub => channelSub.id === channelSubscription.id);
      if (existIndex !== -1) {
        currentList[existIndex] = channelSubscription;
        this.channelSubscriptionsList = {
          ...this.channelSubscriptionsList,
          [channelId]: [...currentList],
        }
      } else {
        this.channelSubscriptionsList = {
          ...this.channelSubscriptionsList,
          [channelId]: [channelSubscription, ...currentList],
        }
      }

    },
    setChannelSubscriptions({ channelId, subscriptionsList }) {
      this.channelSubscriptionsList = {
        ...this.channelSubscriptionsList,
        [channelId]: subscriptionsList,
      }
    },
    async onConfirmDelete() {
      try {
        if (!this.selectedChannelSubscriptionId) {
          throw new Error('channelSubscriptionId not found');
        }

        const findedChannelSubscription = Object.values(this.channelSubscriptionsList).flat().find(channelSubscription => channelSubscription.id === this.selectedChannelSubscriptionId);
        await deleteChannelSubscriptions({
          channelSubscriptionId: this.selectedChannelSubscriptionId,
          organizationId: this.profile._id,
        });
        if (findedChannelSubscription) {
          this.channelSubscriptionsList = {
            ...this.channelSubscriptionsList,
            [findedChannelSubscription.channelId]: (this.channelSubscriptionsList[findedChannelSubscription.channelId] || []).filter(channelSubscription => channelSubscription.id !== this.selectedChannelSubscriptionId),
          }
        }
        this.onCloseModalConfirm();
      } catch (err) {
        console.log('onConfirmDelete err.response', err.response);
        this.$toast.show({
          type: 'error',
          message: err.response?.data?.message || err.message,
        })
      }
    },
    onCloseModalConfirm() {
      this.isModalDeleteOpen = false;
      this.selectedChannelSubscriptionId = '';
    },
    getIsActivated(channelId) {
      const activated = this.configurationIntegrations?.xtramile?.activated || {};
      return activated[channelId] || false;
    },
    async onSubmitChannel({ isXtramile, jobboard, ...params }) {
      try {
        if (isXtramile === false) {
          await this.onSubmitInternalIntegration({ jobboard, params });
          return;
        }

        const { data } = await addChannelSubscriptions({
          organizationId: this.profile._id,
          channelSubscriptionId: this.selectedChannelSubscriptionId,
          ...params,
        });
        this.addChannelSubscription({ channelId: data.channelId, channelSubscription: data  });

        this.$emit('profile-updated', {
          ...this.profile,
          configuration: {
            ...this.profile.configuration,
            integrations: {
              ...this.profile.configuration?.integrations || {},
              xtramile: {
                ...this.profile.configuration?.integrations?.xtramile || {},
                channelSubscriptions: {
                  ...this.profile.configuration?.integrations?.xtramile?.channelSubscriptions || {},
                  [data.id]: { params, response: data },
                }
              }
            }
          }
        })
        this.isModalOpen = false;
        this.selectedChannelSubscriptionId = '';
      } catch (err) {
        this.isModalOpen = false;
        this.selectedChannelSubscriptionId = '';
        this.$toast.show({
          type: 'error',
          message: err.response?.data?.message || err.message,
          duration: 6000,
        })
      }
    },
    async onSubmitInternalIntegration({ jobboard, params }) {
      const { data: config } = await signIn({
        jobboard,
        credentials: params.credentials,
        organizationId: this.profile._id,
        params: params.params,
      });
      this.$emit('profile-updated', {
        ...this.profile,
        configuration: {
          ...this.profile.configuration,
          integrations: {
            ...this.profile.configuration?.integrations || {},
            job_multiposting: {
              ...this.profile.configuration?.integrations?.job_multiposting || {},
              [jobboard]: config,
            }
          }
        }
      })
      this.isModalOpen = false;
    }
  },
  computed: {
    ...mapState({
      channels: state => state.xtramile.channels,
    }),
    channelsFiltred() {
      return this.channels.filter(channel => !channel.isInternal);
    },
    configurationIntegrations() {
      return this.profile?.configuration?.integrations || {};
    },
    isInternalIntegration() {
      return this.selectedChannel.isInternal;
    },
    config() {
      if (this.isInternalIntegration) {
        return this.configurationIntegrations?.job_multiposting?.[this.selectedChannel?.connectorName] || {}
      }
      return this.configurationIntegrations?.xtramile || {};
    },
    isXtramileEnabled() {
      return this.configurationIntegrations?.xtramile?.enabled;
    }
  },
}
</script>

<style lang="scss" scoped>
.jobboard-config-list {
  flex-direction: column;

  &__loader {
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  &__content {
    flex-direction: column;
    width: 100%;
  }

  &__separator {
    border-bottom: 1px solid $color-blue-light;
  }
}
</style>
