<script lang="ts" setup>
  import { Opportunity } from '@/domains/models/Opportunity';
  import { Company } from '@/domains/models/Company';
  import { computed, defineProps } from 'vue';
  import { useI18n } from '@/i18n/i18n';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
  import imgFallback from '@/assets/img/default-avatar.svg';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { contractsLabels, salaryTypesLabels } from '@/api/custom-jobs';
  import Tag from '@/components/Tag/Tag.vue';

  const props = defineProps<{
    opportunity: Opportunity;
    company: Company;
  }>();

  const i18n = useI18n();
  const router = useRouter();
  const route = useRoute();

  const salary = computed(() => {
    if (props.opportunity.budget?.min && props.opportunity.budget?.max) {
      return `${props.opportunity.budget?.min} - ${props.opportunity.budget?.max} ${i18n.t(salaryTypesLabels[props.opportunity.budget?.salaryType || props.opportunity.budget?.variableType], 1)}`;
    }
    return '';
  });

  const skillsSlice = 1;

  const opportunityLink = computed(() => {
    return router.resolve({
      ...route,
      params: {
        ...route.params,
        id: props.opportunity._id,
      },
      query: {
        ...route.query,
        type: 'hunt',
        'company-id': props.company._id,
      },
    })?.href;
  });
  const setAlternativeImg = (event) => {
    event.target.src = imgFallback;
  };
</script>

<template>
  <RouterLink
    :to="opportunityLink"
    class="flex flex-col hover:opacity-75">
    <div class="flex flex-col gap-2.5 rounded-t-md bg-neutral-200 p-4">
      <b class="text-sm font-bold text-blue-800">
        {{ opportunity.title }}
      </b>
      <div class="flex flex-row flex-wrap gap-2.5">
        <Tag
          v-for="profession in opportunity._professions.slice(0, 1)"
          :key="profession._id"
          :text="profession.name"
          type="professions"/>
        <Tag
          v-if="opportunity._professions.length > 1"
          v-tooltip="{
            content: opportunity._professions
              .slice(1)
              .map((p, i) => `${i > 0 ? '<br />' : ''}${p.name}`),
          }"
          :text="`+${opportunity._professions.slice(1).length}`"
          type="professions"/>
        <Tag
          v-if="opportunity.customFields && opportunity.customFields.emergency"
          :text="opportunity.customFields.emergency"
          type="emergency"
        />
        <Tag
          v-for="location in opportunity.locations.slice(0, 1)"
          :key="location.placeId"
          v-tooltip="{
            content:
              location.addressComponents[0].longName.length > 12
                ? location.addressComponents[0].longName
                : undefined,
          }"
          :text="location.addressComponents?.[0]?.shortName"
          type="location"/>
        <Tag
          v-if="opportunity.locations.length > 1"
          v-tooltip="{
            content: opportunity.locations
              .slice(1)
              .map((p, i) => `${i > 0 ? '<br />' : ''}${p.addressComponents[0].longName}`),
          }"
          :text="`+${opportunity.locations.slice(1).length}`"
          type="location"/>
      </div>
      <div class="flex flex-row flex-wrap gap-2.5">
        <Tag
          :text="opportunity.customFields?.['nb-profils']"
          icon="user-plus"/>
        <Tag
          :text="contractsLabels[opportunity.contract]"
          type="contracts"/>
        <Tag
          v-if="salary"
          :text="salary"
          type="salary">
        </Tag>
        <Tag
          v-for="skill in opportunity._skills?.slice(0, skillsSlice)"
          :key="skill._id"
          :text="skill.name"
          type="technos">
        </Tag>
        <Tag
          v-if="opportunity._skills?.length > skillsSlice"
          v-tooltip.bottom="{content: opportunity._skills?.slice(skillsSlice).map(skill => skill.name).join(', ')}"
          :text="`+${opportunity._skills?.slice(skillsSlice).length}`"
          type="technos">
        </Tag>
      </div>
    </div>
    <div class="flex flex-row items-center justify-between gap-2.5 rounded-b-md bg-blue-100 px-5 py-2.5">
      <div class="flex items-center gap-2">
        <div class="flex size-6 shrink-0 items-center justify-center rounded-md bg-white text-blue-500">
          <i
            class="icon-user-plus flex size-12 justify-center"/>
        </div>
        <p class="text-xs text-blue-800">
          <b>{{ opportunity.nbCandidateProcesses || 0 }}</b> {{ $t('global.new-applications') }}
        </p>
      </div>
      <div class="mx-1.5 flex h-[32px] border-r border-blue-200"></div>
      <div class="flex items-center gap-2">
        <div class="flex size-6 shrink-0 items-center justify-center rounded-md bg-white text-blue-500">
          <i
            class="icon-user-plus flex size-12 justify-center"/>
        </div>
        <p class="text-xs text-blue-800">
          <b>{{ opportunity.nbActiveProcesses || 0 }}</b> {{ $t('global.ongoing-processes') }}
        </p>
      </div>
      <div class="mx-1.5 flex h-[32px] border-r border-blue-200"></div>
      <p
        :class="opportunity.adPublished ? 'text-green-500' : 'text-red-500'"
        class="flex shrink-0 items-center text-xs">
        &#x25cf; {{ opportunity.adPublished ? $t('global.online') : $t('global.offline') }}
      </p>
      <div class="mx-1.5 flex h-[32px] border-r border-blue-200"></div>
      <BcAvatar
        v-tooltip.bottom="{content:`${opportunity._owners?.[0]?.firstName} ${opportunity._owners?.[0]?.lastName}`}"
        :alt="opportunity._owners?.[0]?.firstName"
        :src="opportunity._owners?.[0]?.pictureUrl"
        class="size-6 shrink-0"
        @error="setAlternativeImg"
      />
    </div>
  </RouterLink>
</template>

<style scoped>

</style>
