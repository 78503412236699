<template>
  <div class="panel-job-edit-infos col-xs-12">
    <title-section
      :position="'left'"
      :title="$t('companies.account-management.info-upper')"
      class="panel-job-edit-infos__title">
    </title-section>
    <panel-edit-dropdown
      :options="itemsExperience"
      :validate="{ required: requiredExperience }"
      :value="defaultExperience"
      @input="setSelectedExperience"
      class="panel-job-edit-infos__input"
      data-experience
      id="experience"
      :label="$t('companies.account-management.xp')"
      label-color="blue-dark-cello"
      label-font-size="16px"
      label-icon="diploma"
      label-icon-color="blue-dodger"
      label-icon-size="19px"
      name="experience"
      :placeholder="$t('companies.account-management.xp')">
    </panel-edit-dropdown>
    <panel-edit-location
      :validate="{ required: requiredLocation }"
      :value="defaultLocations"
      @change="setSelectedLocations"
      class="panel-job-edit-infos__input"
      data-location
      id="locations"
      :label="$t('timeline.location')"
      label-font-size="16px"
      label-icon="map-pin"
      label-icon-color="blue-dodger"
      label-icon-size="19px"
      name="locations"
      :placeholder="$t('companies.account-management.enter-a-location')">
    </panel-edit-location>
    <div class="is-column panel-job-edit-infos__input">
      <panel-edit-domains-label
        :required-domains="requiredDomains">
      </panel-edit-domains-label>
      <domains-dropdown
        multi-choice
        :values="defaultDomains"
        :options="profileDomainsCategories"
        @click="setSelectedDomains">
      </domains-dropdown>
    </div>
    <panel-edit-dropdown
      :options="itemsRole"
      :validate="{ required: requiredRole }"
      :value="defaultRole"
      @input="setSelectedRole"
      class="panel-job-edit-infos__input"
      data-role
      id="role"
      :label="$t('panel-coder-edit.function')"
      label-color="blue-dark-cello"
      label-font-size="16px"
      label-icon="suitcase"
      label-icon-color="blue-dodger"
      label-icon-size="19px"
      name="role"
      :placeholder="$t('panel-coder-edit.function')">
    </panel-edit-dropdown>
  </div>
</template>

<script>
  import TitleSection from '@/components/Title/TitleSection';
  import PanelEditDropdown from '@/components/Panel/PanelEdit/PanelEditDropdown';
  import DomainsDropdown from '@/components/DomainsDropdown/DomainsDropdown';
  import { experience, getExperienceItemsList, getExperienceNameFromApiValue } from '@/common-old/macros/experience';
  import PanelEditLocation from '@/components/Panel/PanelEdit/PanelEditLocation';
  import { mapState } from 'vuex';
  import { getRoleItemsList, getRoleNameFromApiValue } from '@/common-old/macros/roles';
  import { domains, getDomainFromApiValue, getProfileDomainsCategories, getDomainFromName } from '@/common-old/macros/market';
  import PanelEditDomainsLabel from '@/components/Panel/PanelEdit/PanelEditDomainsLabel';
  import domainsDropdownBehaviorMixin from '@/mixins/Domains/domainsDropdownBehavior';
import { getCompleteAddressOrTag } from '@/formatters/locations';

  export default {
    name: 'panel-job-edit-infos',
    components: {
      PanelEditLocation,
      PanelEditDropdown,
      DomainsDropdown,
      TitleSection,
      PanelEditDomainsLabel,
    },
    mixins: [domainsDropdownBehaviorMixin],
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
      editedProfile: {
        type: Object,
        default: () => ({}),
      },
      requiredDomains: {
        type: Boolean,
        default: false,
      },
      requiredRole: {
        type: Boolean,
        default: false,
      },
      requiredExperience: {
        type: Boolean,
        default: false,
      },
      requiredLocation: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      this.itemsExperience = getExperienceItemsList().map(item => ({
        name: this.$t(item.value),
        query: item.query,
      }));

      this.itemsRole = getRoleItemsList().map(item => ({
        name: this.$t(item.value),
        query: item.query,
      }));

      this.profileDomainsCategories = getProfileDomainsCategories()
    },
    data: () => ({
      itemsExperience: [],
      itemsRole: [],
      profileDomainsCategories: [],
    }),
    computed: {
      ...mapState({
        locationsSuggestions: state => state.location.locationsSuggestions,
      }),
      defaultExperience() {
        const statusName = this.$i18n.t(getExperienceNameFromApiValue(this.profile.experience));

        return statusName ? experience[statusName].value : '';
      },
      defaultLocations() {
        return getCompleteAddressOrTag(this.profile.locations?.[0]) || '';
      },
      defaultRole() {
        const roleName = getRoleNameFromApiValue(this.profile.role);

        return roleName || '';
      },
      defaultDomains() {
        if (!this.editedProfile.domains && !this.profile.domains) {
          return [];
        }
        return this.editedProfile.domains && this.editedProfile.domains.length > 0 ?
          this.editedProfile.domains.map(domain => domains[domain].name) :
          this.profile.domains.map(domain => getDomainFromApiValue(domain).name);
      },
    },
    methods: {
      setSelectedExperience(experience) {
        this.$emit('update-value', {
          experience: experience.query,
        });
      },
      setSelectedRole(role) {
        this.$emit('update-value', {
          role: role.query,
        });
      },
      setSelectedDomains(domains) {
        this.selectedDomains = this.defaultDomains.map(domainName => getDomainFromName(domainName).query);
        this.isDomainsRequired = true;

        this.selectDomains(domains);

        this.$emit('update-value', {
          domains: this.selectedDomains,
        });
      },
      setSelectedLocations(location) {
        if (location.placeId) {
          this.$emit('update-value', {
            locations: [location.placeId]
          });
        } else {
          this.$emit('update-value', {
            locations: [],
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-job-edit-infos {
    @include bp('tablet') {
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
    }

    &__title {
      margin-bottom: $panel-title-margin-bottom;
      flex-basis: 100%;
    }

    &__input {
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }

      @include bp('tablet') {
        flex-basis: calc(50% - 5px);
      }
    }
  }
</style>
