<template>
  <div class="industries-autocomplete">
    <div>
      <bc-autocomplete
        v-if="showInput"
        :placeholder="$t('generics.find-sector')"
        v-model="value"
        :is-scrollable="false"
        :options="options"
        class="is-full-width"
        keep-first
        name="autocomplete"
        :force-close-dropdown="!forConfiguration && !isFocus"
        @focus="onFocus"
        @blur="onBlur"
        @input="getSuggestions"
        @on-dropdown-close="emptySuggestions"
        @select="onSelectIndustry"
        @click.prevent.stop.native
        v-bind="$attrs">
        <template #option="{ option }">
          <bc-dropdown-item
            :is-selected="isIndustrySelected(option._id)"
            :text="option.name">
          </bc-dropdown-item>
        </template>
        <template #footer>
          <button
            @click.prevent="createNewIndustry()"
            class="is-full-width pt-3 pb-3 font-size-m is-secondary is-justify-content-center mr-auto ml-auto"
          >
            <span class="icon-plus mr-2"></span>
            {{ $t('panel-coder-edit.create-sector', { sector: value }) }}
          </button>
        </template>
      </bc-autocomplete>
    </div>
    <div class="tag-container">
      <bc-tag
        class="tag"
        :class="{ 'tag-select': !forConfiguration }"
        v-for="industry of selectedIndustries"
        type="rounded"
        tone="light"
        :deletable="forConfiguration"
        :key="industry._id"
        :bc-cross="true"
        @click.prevent.native="handleClickTag(industry)">
        {{ industry.name }}
      </bc-tag>
      <bc-tag
        v-if="noIndustryChoice"
        class="tag tag-select-empty"
        type="rounded"
        tone="dark"
        @click.prevent.native="handleClickTag(undefined)"
      >
        {{ $t('panel-coder-edit.empty-sector') }}
      </bc-tag>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import debounce from 'debounce';
import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import BcTag from '@/ui-kit/components/BcTag/BcTag';

import searchbar from '@/common-old/mixins/Searchbar/searchbar';
import { getIndustriesAutocomplete, createIndustry } from '@/api/industries';

export default {
  name: 'industries-autocomplete',
  components: {
    BcAutocomplete,
    BcDropdownItem,
    BcTag,
  },
  mixins: [searchbar],
  props: {
    forConfiguration: {
      type: Boolean,
      default: false
    },
    showInput: {
      type: Boolean,
      default: true
    },
    noIndustryChoice: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.selectedIndustries = this.organization?._industries || []
  },
  data() {
    return {
      value: '',
      selectedIndustries: [],
      options: [],
      isFocus: false,
    };
  },
  computed: {
    ...mapGetters('user', ['organization'])
  },
  methods: {
    ...mapActions('user', ['updateOrganizationIndustries']),
    getSuggestions: debounce(async function(name) {
      if (name && name.length > 15 ) {
        this.value = name.substring(0, 14)
        this.$toast.show({
          type: 'error',
          title: 'Erreur',
          message: `Vous ne pouvez pas entrer plus de 15 charactères`
        })
        return
      }
      try {
        const { data } = await getIndustriesAutocomplete({ name });
        const selectedIndustriesIds = this.selectedIndustries.map((industry) => industry._id)
        this.options = data.filter((industry) => !selectedIndustriesIds.includes(industry._id));
      } catch (err) {
        console.log('err suggestions...', err);
      }
    }, 300),
    emptySuggestions() {
      this.options = [];
      this.value = '';
    },
    isIndustrySelected(id) {
      return this.selectedIndustries.findIndex(({ _id }) => _id === id) !== -1;
    },
    setSelectedIndustries(industry) {
      if (!this.forConfiguration) {
        this.$emit('selected', industry)
        return;
      }
      if (this.isIndustrySelected(industry._id)) {
        this.selectedIndustries = this.selectedIndustries.filter(({ _id }) => industry._id !== _id);
      } else {
        this.selectedIndustries.push(industry);
      }
    },
    async createNewIndustry() {
      if (this.value === '') {
        return;
      }
      if (!this.forConfiguration) {
        this.$emit('created', this.value)
        return;
      }
      try {
        const { data } = await createIndustry({ name: this.value });
        this.selectedIndustries.push(data)
        this.$toast.show({
          type: 'success',
          title: 'Félicitations',
          message: `Le secteur “${data.name}” a bien été créé.`
        })
        this.value = ''
      } catch (err) {
        console.log('err industry creation', err);
      }
    },
    handleClickTag(industry) {
      if (this.forConfiguration) {
        this.selectedIndustries = this.selectedIndustries.filter(sIndustry => sIndustry._id !== industry._id);
      } else {
        this.$emit('selected', industry)
      }
    },
    onBlur() {
      setTimeout(() => {
        this.isFocus = false
      }, 200)
    },
    onFocus() {
      this.isFocus = true
    },
    onSelectIndustry(event) {
      this.setSelectedIndustries(event);
      this.value = ''
    }
  },
  watch: {
    selectedIndustries(industries) {
      this.updateOrganizationIndustries(industries);
    }
  }
};
</script>
<style lang="scss" scoped>
.industries-autocomplete {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.title {
  font-weight: 500;
  font-size: 16px;
  color: $color-blue-dark-cello;
}

.tag-container {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
}

.tag {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: $font-size-xxs;
  font-weight: $font-weight-light;

  &:hover {
    cursor: pointer;
  }
}

.tag-select {
  background: white;
  color: $color-purple-dark;
  border: 1px solid $color-purple-dark;
  border-radius: 30px;
}

.tag-select-empty {
  background: white;
  color: $color-blue-dark-cello;
  border: 1px solid $color-blue-dark-cello;
  border-radius: 30px;
  font-weight: 500;
}
</style>
