<template>
  <div
    v-click-outside="closeDropdown"
    class="home-statistics-filters datepicker-trigger is-justify-content-center">
    <div class="home-statistics-filters__wrapper">
      <button
        :class="{ 'home-statistics-filters__button--active': $route.query.period === 'day' }"
        class="home-statistics-filters__button is-align-items-center border-radius-s font-size-m is-justify-content-center"
        color="primary"
        size="small"
        type="outlined"
        @click="selectPeriod('day')">
        {{ $t('process.day-cap') }}
      </button>
      <button
        :class="{ 'home-statistics-filters__button--active': $route.query.period === 'week' }"
        class="home-statistics-filters__button is-align-items-center border-radius-s font-size-m is-justify-content-center"
        color="primary"
        size="small"
        type="outlined"
        @click="selectPeriod('week')">
        {{ $t('process.week') }}
      </button>
      <button
        :class="{ 'home-statistics-filters__button--active': $route.query.period === 'month' }"
        class="home-statistics-filters__button is-align-items-center border-radius-s font-size-m is-justify-content-center"
        color="primary"
        size="small"
        type="outlined"
        @click="selectPeriod('month')">
        {{ $t('process.month') }}
      </button>
    </div>
    <v-date-picker
      v-model="range"
      :columns="2"
      :locale="locale"
      :max-date="new Date()"
      :popover="{ visibility: 'click' }"
      mode="range"
      @input="setProcessDateRange">
      <template v-slot="{ togglePopover }">
        <div
          id="datepicker-trigger"
          class="home-statistics-filters__filter home-statistics-filters__wrapper"
          @click="() => {
          togglePopover();
          isDropdownOpen = !isDropdownOpen;
        }">
          {{ formattedDate }}
          <i
            :class="{
            'icon-chevron-up': isDropdownOpen,
            'icon-chevron-down': !isDropdownOpen,
          }"
            class="home-statistics-filters__filter-chevron">
          </i>
        </div>
      </template>
    </v-date-picker>

    <div class="switch-team">
      <p :class="{ switchactive: isTeamGestionActive, switchinactive: !isTeamGestionActive}">
        {{ $t('activities.management') }}
      </p>
      <switcher
        :bcBackgroundColorInactive="'blue-dodger'"
        :bcBackgroundColorOpacityInactive="1"
        :value="!isTeamGestionActive"
        @input="$emit('switch-team', $event)">
      </switcher>
      <p :class="{ switchactive: !isTeamGestionActive, switchinactive: isTeamGestionActive}">
        {{ $t('activities.business') }}
      </p>
    </div>
    <bc-dropdown
      :options="coachDropdown"
      class="home-statistics-filters__dropdown home-statistics-filters__wrapper"
      is-expanded>
      <template #label="{ isDropdownOpen }">
        <bc-dropdown-trigger
          :is-arrow="true"
          :is-dropdown-open="isDropdownOpen"
          :text="`${selectedCoach.firstName} ${selectedCoach.lastName}`"
          class="home-statistics-filters__filter"
        >
        </bc-dropdown-trigger>
      </template>
      <template #option="{ activeIndex, index, option }">
        <bc-dropdown-item
          :is-focus="activeIndex === index"
          :text="`${option.firstName} ${option.lastName}`"
          @change="selectCoach(option)"
          @click.native="selectCoach(option)">
        </bc-dropdown-item>
      </template>
    </bc-dropdown>
  </div>
</template>

<script>
  import moment from 'moment/min/moment-with-locales';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcSwitcher from '@/legacy/ui-kit/components/Switcher/BcSwitcher';
  import locale from '@/mixins/i18n/locale';

  export default {
    name: 'home-statistics-filters',
    mixins: [locale],
    components: {
      BcDropdownItem,
      BcDropdown,
      BcDropdownTrigger,
      switcher: BcSwitcher,
    },
    props: {
      isTeamGestionActive: {
        type: Boolean,
        required: true,
      },
      coachDropdown: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        isDropdownOpen: false,
        startDate: this.$route.query['start-date'] ? this.$route.query['start-date'] : '',
        endDate: this.$route.query['end-date'] ? this.$route.query['end-date'] : '',
        periodFilters: [
          {
            period: 'day',
            startDate: moment().format('L'),
            endDate: moment().add('1', 'days').format('L'),
          },
          {
            period: 'week',
            startDate: moment().startOf('week').format('L'),
            endDate: moment().endOf('week').format('L'),
          },
          {
            period: 'month',
            startDate: moment().startOf('month').format('L'),
            endDate: moment().endOf('month').format('L'),
          },
        ],
        range: [],
      };
    },
    computed: {
      formattedDate() {
        return this.$route.query['start-date'] && this.$route.query['end-date'] ?
          `${moment(this.$route.query['start-date']).format('L')} > ${moment(this.$route.query['end-date']).format('L')}` :
          `${moment(this.startDate).format('L')} > ${moment(this.endDate).format('L')}`;
      },
      profile() {
        return this.$store.state.user.profile;
      },
      selectedCoach() {
        return this.coachDropdown.find(coach => coach._id === this.$route.query['coach-id']) || this.coachDropdown[0] || {};
      },
    },
    methods: {
      selectPeriod(period) {
        const filter = this.periodFilters.find(filter => period === filter.period);

        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            'period': filter.period,
            'start-date': filter.startDate,
            'end-date': filter.endDate,
          },
        });
      },
      selectCoach(coach) {
        this.$router.push({
          query: {
            ...this.$route.query,
            'coach-id': coach._id,
          },
        });
      },
      setProcessDateRange(date) {
        if (!this.$route.query['process-starts'] || !this.$route.query['process-ends']) {
          this.$router.replace({
            ...this.$route,
            query: {
              ...this.$route.query,
              'start-date': moment((date || {}).start || this.$route.query['start-date']).format('YYYY-MM-DD'),
              'end-date': moment((date || {}).end || this.$route.query['end-date']).format('YYYY-MM-DD'),
            },
          });
        } else {
          this.$router.push({
            ...this.$route,
            query: {
              ...this.$route.query,
              'start-date': moment((date || {}).start || this.$route.query['start-date']).format('YYYY-MM-DD'),
              'end-date': moment((date || {}).end || this.$route.query['end-date']).format('YYYY-MM-DD'),
            },
          });
        }
      },
      closeDropdown() {
        this.isDropdownOpen = false;
      },
    },
    watch: {
      $route: {
        handler() {
          this.startDate = this.$route.query['start-date'] ? this.$route.query['start-date'] : '';
          this.endDate = this.$route.query['end-date'] ? this.$route.query['end-date'] : '';
        },
        immediate: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .home-statistics-filters {
    flex-direction: column;
    align-items: center;

    @include bp('tablet') {
      flex-direction: row;
      align-items: initial;
    }

    &__filter {
      justify-content: space-between;
      align-items: center;
      padding: 0 25px;
      min-width: 215px;
      border: 1px solid $color-grey-1;
      color: $color-primary;
      transition: 0.3s;
      border-radius: 4px;
      font-size: $font-size-m;
      text-align: center;
      height: 40px;
      background: $color-white;

      &:hover {
        cursor: pointer;
        transition: 0.3s;
        background-color: rgba(51, 112, 234, 0.1);
      }

      &--active {
        background-color: $color-primary-dark;
        color: $color-white;

        &:hover {
          background-color: $color-primary-dark;
        }
      }

      &-chevron {
        font-size: 14px;
        height: 12px;
        margin-left: 10px;
      }
    }

    &__wrapper {
      margin: 5px 0;
      justify-content: center;

      &:first-of-type {
        margin: 5px 7.5px;
      }
    }

    &__button {
      margin: 0 5px;
      padding: 5px 15px;
      border: 1px solid rgba($color-primary, 0.3);
      background: $color-white;
      color: $color-primary;
      min-width: 75px;

      &:hover {
        background: rgba($color-primary, 0.1);
      }

      &--active, &--active:hover {
        background: $color-primary;
        color: $color-white;
      }
    }

    &__picker {
      margin-top: 15px;
      flex-direction: column;
      box-shadow: $shadow-default;
      border-radius: 10px;

      & :deep() {
        tbody {
          all: initial;
        }

        .asd {
          &__wrapper {
            flex-direction: column;
          }

          &__month-name {
            justify-content: center;
            color: $color-blue-cello;
          }

          &__day-button {
            justify-content: center;
            font-size: $font-size-s;
          }

          &__day {
            &--enabled {
              border: 0;
              transition: 0.15s;

              &:hover {
                background-color: $color-blue-hawkes;
              }
            }
          }

          &__action-buttons {
            button {
              position: initial;
              margin-left: 15px;
            }
          }
        }
      }
    }

    .switch-team {
      align-items: center;
      margin: 0 24px;

      p {
        font-size: 1rem;
        color: #213C61;
        text-align: left;
        line-height: 19px;
      }

      button {
        margin: 0 8px;
      }

      .switchactive {
        color: $color-primary;
      }

      .switchinactive {
        color: $color-grey-4;
      }
    }
  }
</style>
