<template>
  <div
    :class="{
      'panel-chat-messages-item--reversed': isFromUser,
      'panel-chat-messages-item--margin': shouldDisplayInformations
    }"
    class="panel-chat-messages-item">
    <div
      :class="{'panel-chat-messages-item__container--reversed': isFromUser}"
      class="panel-chat-messages-item__container">
      <picture-chat
        v-if="shouldDisplayInformations"
        :img-src="messagePicture"
        :company-picture="companyPicture"
        class="panel-chat-messages-item__avatar">
      </picture-chat>
      <panel-chat-messages-hour
        v-if="shouldDisplayInformations"
        :created-at="message.createdAt">
      </panel-chat-messages-hour>
    </div>
    <component
      :is="messageType"
      :text="message.text"
      :message="message"
      :interlocutor-name="interlocutorName"
      :is-from-user="isFromUser">
    </component>
  </div>
</template>

<script>
  import PanelChatMessagesBubble from '@/components/Panel/PanelChat/PanelChatMessages/PanelChatMessagesBubble';
  import PictureChat from '@/components/UIKit/Picture/PictureChat';
  import PanelChatMessagesHour from '@/components/Panel/PanelChat/PanelChatMessages/PanelChatMessagesHour';
  import PanelChatMessagesJob from '@/components/Panel/PanelChat/PanelChatMessages/PanelChatMessagesJob';
  //
  import moment from 'moment/min/moment-with-locales';

  /**
   * The container of the message item
   */
  export default {
    name: 'panel-chat-messages-item',
    components: {
      PanelChatMessagesHour,
      PictureChat,
      PanelChatMessagesBubble,
      PanelChatMessagesJob
    },
    props: {
      /**
       * The message object to display
       */
      message: {
        type: Object,
        default: () => {
          return {};
        },
      },
      profileId: {
        type: String,
      },
      nextMessage: {
        type: Object
      },
      previousMessage: {
        type: Object
      },
    },
    data() {
      return {
        userType: [
          '',
          '_coder',
          '_contact',
          '_coach'
        ]
      }
    },
    computed: {
      messageType() {
        const type = {
          1: 'panel-chat-messages-bubble',
          3: 'panel-chat-messages-job'
        };

        return type[this.message.type];
      },
      /**
       * Determinate if the message is from user. If it is, it will change it style
       * @returns {*}
       */
      isFromUser() {
        const coder = 1;
        if (this.message.authorType === coder) {
          return true;
        }
        return this.profileId === this.message[this.userType[this.message.authorType]]._id;
      },
      messagePicture() {
        return this.message[this.userType[this.message.authorType]] && this.message[this.userType[this.message.authorType]].pictureUrl ?
          this.message[this.userType[this.message.authorType]].pictureUrl : null;
      },
      interlocutorName() {
        if (!this.previousMessageIsFromTheSameUser) {
          return this.message[this.userType[this.message.authorType]] ?
            this.message[this.userType[this.message.authorType]].firstName : '';
        }
        return '';
      },
      companyPicture() {
        return this.message._contact && this.message._contact._company ? this.message._contact._company.logoUrl : ''
      },
      nextMessageIsFromTheSameUser() {
        if (this.nextMessage) {
          return this.message[this.userType[this.message.authorType]]._id === this.nextMessage[this.userType[this.nextMessage.authorType]]._id;
        }
        return false;
      },
      previousMessageIsFromTheSameUser() {
        if (this.previousMessage) {
          return this.message[this.userType[this.message.authorType]]._id === this.previousMessage[this.userType[this.previousMessage.authorType]]._id;
        }
        return false;
      },
      shouldDisplayInformations() {
        return !this.nextMessageIsFromTheSameUser ||
          !this.nextMessage ||
          (this.timeDifferenceWithPreviousMessage >= 5 && this.timeDifferenceWithNextMessage >= 5) ||
          this.timeDifferenceWithNextMessage >= 5;
      },
      timeDifferenceWithPreviousMessage() {
        if (this.previousMessage) {
          const current = moment(this.message.createdAt);
          const previous = moment(this.previousMessage.createdAt);

          return current.diff(previous, 'minutes');
        }
        return 0;
      },
      timeDifferenceWithNextMessage() {
        if (this.nextMessage) {
          const current = moment(this.message.createdAt);
          const next = moment(this.nextMessage.createdAt);

          return next.diff(current, 'minutes');
        }
        return 0;
      }
    },
  };
</script>

<style scoped lang="scss">
  .panel-chat-messages-item {
    margin-bottom: 3px;
    padding: 0 10px;
    width: 100%;
    @include bp('tablet') {
      padding: 0 20px;
    }
    &--margin {
      margin-bottom: 20px;
    }
    &--reversed {
      flex-direction: row-reverse;
      margin-left: auto;
    }
    &__avatar {
      margin-bottom: 6px;
    }
    &__container {
      flex-direction: column;
      align-items: center;
      margin-right: 11px;
      min-width: 38px;
      &--reversed {
        margin-right: 0;
        margin-left: 11px;
      }
    }
  }
</style>
