import { createProfession } from '@/managers/professions/useCases/createProfession';
import { editProfession } from '@/managers/professions/useCases/editProfession';
import { deleteProfession } from '@/managers/professions/useCases/deleteProfession';

const create = ({ name }) => {
  return createProfession({ name });
};

const edit = ({ id, name }) => {
  return editProfession({ id, name });
};

const remove = id => {
  return deleteProfession(id);
};

export const professionController = {
  create,
  edit,
  remove,
};

export default {
  professionController,
};
