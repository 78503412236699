<template>
  <div class="panel-process-details-job-deal-form">
    <p
      class="panel-process-details-job-deal-form__title icon-arrow-left">
      {{ $t('process.in-waiting-to-start') }}
    </p>
    <div class="panel-process-details-job-deal-form__container">
      <div class="panel-process-details-job-deal-form__wrapper">
        <label
          class="panel-process-details-job-deal-form__label"
          for="salary-fixed">
          {{ $t('companies.panel-hunt-process.gross-fixed-salary') }}
        </label>
        <bc-input-number
          :bc-input-border-color-opacity-blurred="0.5"
          bc-input-border-radius="2px"
          bc-input-padding="0"
          class="panel-process-details-job-deal-form__input"
          id="salary-fixed"
          max="200"
          min="0"
          v-model.number="salaryFixed">
        </bc-input-number>
        <p class="panel-process-details-job-deal-form__label">
          K €/an
        </p>
      </div>
      <div class="panel-process-details-job-deal-form__wrapper">
        <label
          class="panel-process-details-job-deal-form__label"
          for="salary-variable">
          + {{ $tc('generics.variable', 1 ) }}
        </label>
        <bc-input-number
          :bc-input-border-color-opacity-blurred="0.5"
          bc-input-border-radius="2px"
          bc-input-padding="0"
          class="panel-process-details-job-deal-form__input"
          id="salary-variable"
          max="200"
          min="0"
          v-model.number="salaryVariable">
        </bc-input-number>
        <p class="panel-process-details-job-deal-form__label">
          K €/an
        </p>
      </div>
    </div>
    <div class="panel-process-details-job-deal-form__container">
      <div class="panel-process-details-job-deal-form__wrapper">
        <label
          class="panel-process-details-job-deal-form__label"
          for="percent">
          % {{ $t('companies.panel-hunt-process.in-effect') }}
        </label>
        <bc-input-number
          :bc-input-border-color-opacity-blurred="0.5"
          bc-input-border-radius="2px"
          bc-input-padding="5px"
          class="panel-process-details-job-deal-form__input panel-process-details-job-deal-form__input--large"
          id="percent"
          max="100"
          min="0"
          v-model.number="percent">
        </bc-input-number>
        <p class="panel-process-details-job-deal-form__label">
          %
        </p>
      </div>
    </div>
    <div class="panel-process-details-job-deal-form__container">
      <div class="panel-process-details-job-deal-form__wrapper">
        <label
          class="panel-process-details-job-deal-form__label"
          for="amount">
          {{ $t('companies.panel-hunt-process.deal-amount') }}
        </label>
        <bc-input-number
          :bc-input-border-color-opacity-blurred="0.5"
          bc-input-border-radius="2px"
          bc-input-padding="5px"
          class="panel-process-details-job-deal-form__input panel-process-details-job-deal-form__input--large"
          id="amount"
          max="99999"
          min="0"
          v-model.number="dealAmount">
        </bc-input-number>
        <p class="panel-process-details-job-deal-form__label">
          €/an
        </p>
      </div>
    </div>
    <div class="panel-process-details-job-deal-form__container">
      <div class="panel-process-details-job-deal-form__wrapper">
        <p class="panel-process-details-job-deal-form__label panel-process-details-job-deal-form__label-date">
          {{ $t('companies.panel-hunt-process.deal-date') }} :
        </p>
        <v-date-picker
          :locale="locale"
          :popover="{ visibility: 'focus' }"
          class="panel-process-details-job-deal-form__date-picker"
          v-model="dealtAt">
        </v-date-picker>
      </div>
      <div class="panel-process-details-job-deal-form__wrapper">
        <p class="panel-process-details-job-deal-form__label panel-process-details-job-deal-form__label-date">
          {{ $t('companies.panel-hunt-process.start-on') }}
        </p>
        <v-date-picker
          :locale="locale"
          :min-date="new Date(dealtAt)"
          :popover="{ visibility: 'focus' }"
          class="panel-process-details-job-deal-form__date-picker"
          v-model="startsAt">
        </v-date-picker>
      </div>
    </div>
  </div>
</template>

<script>
  import BcInputNumber from '@/legacy/ui-kit/components/Input/BcInputNumber';
  import moment from 'moment/min/moment-with-locales';
  import locale from '@/mixins/i18n/locale';

  export default {
    name: 'panel-process-details-job-deal-form',
    components: { BcInputNumber },
    mixins: [locale],
    props: {
      processDealtAt: {
        type: String,
        default: () => new Date().toISOString(),
      },
      processStartsAt: {
        type: String,
        default: () => new Date().toISOString(),
      },
      deal: {
        type: Object,
        default: () => ({
          fixed: 0,
          variable: 0,
          amount: 0,
          percent: 0,
          startsAt: new Date(),
        }),
      },
    },
    computed: {
      salaryFixed: {
        get() {
          return this.$route.query['new-deal-fixed'] >= 0
            ? this.$route.query['new-deal-fixed']
            : (this.deal || {}).fixed;
        },
        set(value) {
          this.$router.replace({
            query: { ...this.$route.query, 'new-deal-fixed': value },
          });
        },
      },
      salaryVariable: {
        get() {
          return this.$route.query['new-deal-variable'] >= 0
            ? this.$route.query['new-deal-variable']
            : (this.deal || {}).variable;
        },
        set(value) {
          this.$router.replace({
            query: { ...this.$route.query, 'new-deal-variable': value },
          });
        },
      },
      dealAmount: {
        get() {
          return this.$route.query['new-deal-amount'] >= 0
            ? this.$route.query['new-deal-amount']
            : (this.deal || {}).amount;
        },
        set(value) {
          this.$router.replace({
            query: { ...this.$route.query, 'new-deal-amount': value },
          });
        },
      },
      percent: {
        get() {
          return this.$route.query['new-deal-percent'] >= 0
            ? this.$route.query['new-deal-percent']
            : (this.deal || {}).percent;
        },
        set(value) {
          this.$router.replace({
            query: { ...this.$route.query, 'new-deal-percent': value },
          });
        },
      },
      startsAt: {
        get() {
          return this.$route.query['new-deal-starts-at']
            ? moment(
                this.$route.query['new-deal-starts-at'],
                'D'
              ).toDate()
            : moment(this.processStartsAt).toDate();
        },
        set(value) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              'new-deal-starts-at': moment(value).format(
                'D'
              ),
            },
          });
        },
      },
      dealtAt: {
        get() {
          return this.$route.query['new-deal-dealt-at']
            ? moment(
                this.$route.query['new-deal-dealt-at'],
                'D'
              ).toDate()
            : moment(this.processDealtAt).toDate();
        },
        set(value) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              'new-deal-dealt-at': moment(value).format(
                'D'
              ),
            },
          });
        },
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-process-details-job-deal-form {
    flex-direction: column;

    &__title {
      text-transform: uppercase;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      color: $color-primary;
      display: flex;
      align-items: center;
      margin-bottom: -5px;

      &--gold {
        color: $color-secondary;

        &:before {
          color: $color-offer;
        }
      }

      &:before {
        margin-right: 10px;
        font-size: $font-size-l;
        transform: rotate(180deg);
      }
    }

    &__container {
      margin-left: 28px;
      border-bottom: 1px solid rgba($color-secondary, 0.2);
      flex-direction: column;
      padding: 10px 0;

      &:last-of-type {
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    &__wrapper {
      align-items: center;
      margin: 2.5px 0;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__label {
      color: $color-secondary;
      font-size: $font-size-s;
      line-height: 17px;

      &-date {
        width: 80px;
      }
    }

    &__input {
      margin: 0 5px;
      max-width: 40px;
      font-size: $font-size-s;
      text-align: center;
      min-height: 30px;

      &--large {
        max-width: 80px;
      }
    }

    &__date-picker {
      color: $color-primary;
      max-width: 155px;
      margin-left: 10px;

      & :deep() {
        input {
          outline: none;
          box-shadow: none;
          border: 1px solid rgba($color-primary, 0.5);
          border-radius: 50px;
          text-align: center;
          color: $color-primary;
          font-size: $font-size-s;
          padding: 5px 10px;

          &:focus {
            border-color: $color-primary;
          }
        }
      }
    }
  }
</style>
