<script lang="ts" setup>
  import { computed, defineEmits, defineProps, onMounted, ref, withDefaults } from 'vue';
  import { useStore } from '@/store';

  import { useToast } from '@/ui-kit/components/BcToast';
  import { useI18n } from '@/i18n/i18n';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import BcInput from '@/ui-kit/components/BcInput/BcInput';
  import BcTextEditor from '@/ui-kit/components/BcTextEditor/BcTextEditor';
  import ModalConfirm from '@/components/Modal/ModalConfirm';
  import { NotesTemplate } from '@/domains/models/NotesTemplate';
  import { useNotesTemplatesStore } from '@/store/pinia/notesTemplates';

  interface Props {
    template?: NotesTemplate;
    disabled: boolean;
  }

  interface ActionParams {
    icon: string;
    title: string;
    content: string;
    cancelText: string;
    confirmText: string;
    onConfirmAction: () => void,
  }

  const props = withDefaults(defineProps<Props>(), {
    template: undefined,
    disabled: false,
  });

  const emits = defineEmits<{
    (e: 'on-close'): void,
  }>();

  const store = useStore();
  const toast = useToast();
  const i18n = useI18n();

  const notesTemplateStore = useNotesTemplatesStore();

  const isModalOpen = ref(false);
  const isActionConfirmed = ref(false);
  const isLoading = ref(false);

  const isEditing = computed(() => {
    return props.template?._id !== undefined;
  });

  const templatesVariables = computed(() => {
    return store.state.emails.templatesVariables;
  });

  const form = ref({
    templateName: '',
    body: '',
  });

  onMounted(async() => {
    // on edit
    if (isEditing.value && props.template) {
      form.value.templateName = props.template.name;
      form.value.body = props.template.body;
      return;
    }
  });

  const isFormValid = computed(() => {
    return form.value.templateName && form.value.body;
  });

  const onSave = async() => {
    try {
      if (!isFormValid.value) {
        return;
      }
      isLoading.value = true;

      if (isEditing.value && props.template) {
        await notesTemplateStore.editTemplate({
          _id: props.template._id,
          name: form.value.templateName,
          body: form.value.body,
        });

        toast.show({
          type: 'success',
          message: i18n.t('templates.template-edit-success'),
        });
      } else {
        await notesTemplateStore.createTemplate({
          name: form.value.templateName,
          body: form.value.body,
        });

        toast.show({
          type: 'success',
          message: i18n.t('templates.template-create-success'),
        });
      }

      onClose();

    } catch (err: any) {
      toast.show({
        type: 'error',
        message: err?.response?.data?.message || err?.message,
      });
    } finally {
      isLoading.value = false;
      onCloseModal();
    }

  };

  const onClose = () => {
    emits('on-close');
  };

  const onCloseModal = () => isModalOpen.value = false;

  const onConfirmEdit = () => {
    isActionConfirmed.value = true;
    onSave();
  };

  const actionParams: Record<string, ActionParams> = {
    edit: {
      icon: '📝',
      title: 'Voulez-vous vraiment modifier ce template ?',
      content: 'Ce template est commun à tous les utilisateurs de votre espace. Voulez-vous réellement le modifier ?',
      cancelText: 'Non, annuler cette action',
      confirmText: 'Oui, modifier',
      onConfirmAction: onConfirmEdit,
    },
  };
</script>

<template>
  <div class="flex-col w-full flex flex-grow">
    <form
      class="flex-col grow"
      @submit.prevent>
      <div class="flex-col flex flex-grow gap-4 p-5">
        <BcInput
          v-model="form.templateName"
          :disabled="disabled"
          :label="$t('templates.template-name')"
          :label-placeholder="false"
          :valid="!!form.templateName"
          class="is-full-width"
          name="templateName"
          required
        />

        <BcTextEditor
          v-model="form.body"
          :disabled="disabled"
          :label="$t('templates.body')"
          class="is-full-width"
          required
        />
      </div>
      <div class="min-h-[75px] w-full items-center justify-center gap-2 border-t border-blue-100">
        <BcButton icon-left="cross" type="outlined" @click="onClose">
          {{ $t('generics.cancel') }}
        </BcButton>
        <BcButton
          :disabled="!isFormValid || isLoading || disabled"
          icon-left="check"
          native-type="submit"
          @click="onSave"
        >
          {{ $t('generics.save') }}
        </BcButton>
      </div>
    </form>

    <ModalConfirm
      :cancelText="actionParams.edit.cancelText"
      :confirmText="actionParams.edit.confirmText"
      :content="actionParams.edit.content"
      :icon="actionParams.edit.icon"
      :isOpen="isModalOpen === true"
      :title="actionParams.edit.title"
      color="success"
      @close="onCloseModal"
      @on-confirm="actionParams.edit.onConfirmAction"
    />
  </div>
</template>
