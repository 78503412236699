<template>
  <Views class="is-full-width">
    <div class="processes">
      <OpportunitiesTabs>
        <ProcessesFilters :is-current-period-default="isCurrentPeriodDefault"
          :display-next-interview="displayNextInterview" class="processes__filters ml-5 mr-auto">
        </ProcessesFilters>
      </OpportunitiesTabs>
      <div class="processes__container pb-2">
        <div class="processes__body is-relative">
          <ProcessesColumnsCustomAggregated :isDragging="isDragging" @load-more-processes="loadMoreProcesses"
            :selected-coach-id="coachId" :show-amount="true"
            :identifier="identifier" :processesByStepKey="processesByStepKey" @card-dragged="getEditableProcessColumn"
            :show-failed="true" @dragging="handleDragging" @accept-process="acceptProcess" @reject-process="rejectProcess"
            @abort-process="abortProcess" @move-process="moveProcess" @process-update="updateProcess"
            :columns-metadata="processesColumnsMetadata" @drag-warning="dragWarning" />
        </div>
      </div>
      <router-view></router-view>
    </div>
    <ModalProcessDeal :data="modalData" :is-modal-open="!!modalData" @close-modal="modalData = undefined"
      @move-process="({ processId, step}) => moveProcess({processId, stepKeyTo: step.key, confirm: true})" />
  </Views>
</template>

<script setup lang="ts">
import moment from 'moment/min/moment-with-locales';

import Views from '@/components/Views/Views.vue';
import ProcessesFilters from '@/components/Processes/ProcessesFilters.vue';
import ProcessesColumnsCustomAggregated from '@/components/Processes/ProcessesColumnsCustomAggregated.vue';
import {
  updateProcessCustomStep,
} from '@/api/processes-custom';
import OpportunitiesTabs from '@/views/Tabs/OpportunitiesTabs.vue';
import ModalProcessDeal from '@/components/Modal/ModalProcess/ModalProcessDeal.vue';
import { abortProcessCustom } from '@/api/processes-custom';
import { computed, ref, watch } from 'vue';
import { useStore } from '@/store';
import { useRoute } from 'vue-router/composables';
import { useToast } from '@/ui-kit/components/BcToast';
import { useI18n } from '@/i18n/i18n';
import { useEmail } from '@/composables/useEmail';
import { type Process } from '@/domains/models/Process';
import { getAggregatedProcesses, getAggregatedProcessesMetadata } from '@/api/processes-custom';

const isDragging = ref(false);
const defaultProcessesStarts = moment().startOf('week').format('DD-MM-YYYY');
const defaultProcessesEnd = moment().endOf('week').format('DD-MM-YYYY');
const modalData = ref();

function handleDragging(params) {
  isDragging.value = true;

}

const store = useStore();
const route = useRoute();
const toast = useToast()
const { t } = useI18n();
const { openEmailPanel } = useEmail();

const profile = computed(() => store.state.user.profile);
const cardActive = computed(() => store.getters.cardActive);
const configurationProcess = computed(() => store.getters['user/configurationProcess']);
const displayNextInterview = computed(() => configurationProcess.value?.displayNextInterview && route.query['selected-view'] !== 'opportunities');

const isCurrentPeriodDefault = computed(() => {
  const start = moment().isoWeekday(1).format('DD-MM-YYYY');
  const end = moment().isoWeekday(7).format('DD-MM-YYYY');

  const isStartsDateBetweenActualPeriod = (route.query['processes-starts'] || defaultProcessesStarts) === start;
  const isEndsDateBetweenActualPeriod = (route.query['processes-ends'] || defaultProcessesEnd) === end;

  return isStartsDateBetweenActualPeriod && isEndsDateBetweenActualPeriod;
});

function updatePanelProfile({ processState, coachProcessState }) {
  store.dispatch('updatePanelProfile', { processState, coachProcessState });
}

function updateCard({ index, content }) {
  store.dispatch('updateCard', { index, content });
}

function dragWarning() {
  toast.show({
    type: 'warning',
    message: route.query['selected-view'] === 'candidates'
      ? t('process.process-dragdrop-warning')
      : t('process.process-company-dragdrop-warning'),
  });
}

const processesByStepKey = ref({});

const isDraggingForbidden = ref(false);

const isPanelView = computed(() => route.name?.includes('Panel'));


const coachId = computed(() => {
  if (route.query['processes-selected-coaches']) {
    return route.query['processes-selected-coaches'];
  }

  if (!route.query['selected-pipe']) {
    return profile.value?._id;
  }
  return
});

const teamId = computed(() => route.query['processes-selected-teams']);
const groupBy = computed(() => route.query['selected-view']);
const startDate = computed(() => route.query['processes-starts']);
const endDate = computed(() => route.query['processes-ends']);

async function loadMoreProcesses($state: any, stepKey: string) {
  try {
    if (!currentPages.value[stepKey]) currentPages.value[stepKey] = 1;
    const currentPage = currentPages.value[stepKey];
    const { data: processesData } = await getAggregatedProcesses({
      coachId: coachId.value,
      teamId: teamId.value,
      groupBy: groupBy.value,
      page: currentPage,
      ...(startDate.value &&
        endDate.value && {
        from: moment(startDate.value, 'DD-MM-YYYY').toISOString(),
        to: moment(endDate.value, 'DD-MM-YYYY').toISOString(),
      }),
      stepKey: stepKey,
    });
    const { processes: currentPageProcesses, isLastPage } = processesData;
    const currentStepKeyProcesses = processesByStepKey.value[stepKey] ?? [];
    processesByStepKey.value[stepKey] = [...currentStepKeyProcesses, ...currentPageProcesses];
    processesByStepKey.value = { ...processesByStepKey.value };
    if (currentPageProcesses?.length) {
      currentPages.value[stepKey]++;
      $state?.loaded();
    }
    if (isLastPage) {
      $state?.complete();
    }
  } catch (error) {
    toast.show({
      type: 'error',
      title: 'Error',
      message: error?.message ?? error,
    });
  }
}

const identifier = ref(+new Date());
const currentPages = ref({});
const processesColumnsMetadata = ref({});

watch(
  () => [teamId.value, coachId.value, groupBy.value, startDate.value, endDate.value, isPanelView.value],
  () => {
    if (isPanelView.value) return
    processesByStepKey.value = {};
    currentPages.value = {};
    identifier.value = +new Date();
    fetchProcessesMetadata();
  },
);


function getEditableProcessColumn(process) {
  if (isDragging.value && process?.item?.className?.includes('forbidden')) {
    isDraggingForbidden.value = true;
  }
}

function getStepConfig({ categoryKey, stepKey }) {
  let stepConfig = {};
  const category = configurationProcess.value.categories.find(category => category.key === categoryKey);
  if (category) {
    stepConfig = category.steps.find(step => step.key === stepKey);
  }
  return {...stepConfig, categoryKey: category?.key};
}

const orderedSteps = computed(() =>
  configurationProcess.value.categories.flatMap(category => {
    return category.steps.map(step => ({ ...step, categoryKey: category.key }));
  }),
);

async function moveProcess({ processId, stepKeyTo, confirm }: { processId: string, stepKeyTo: string, confirm?: boolean }) {
  try {
    const foundStepKeyFrom = Object.keys(processesByStepKey.value).find(key => processesByStepKey.value[key].find(process => process.referenceProcess._id === processId));
    const foundStepFrom = orderedSteps.value.find(step => step.key === foundStepKeyFrom);
    const foundStepTo = orderedSteps.value.find(step => step.key === stepKeyTo);

    const processAggregated = processesByStepKey.value[foundStepKeyFrom]?.find(process => process.referenceProcess._id === processId);

    const shouldConfirmDealFirst =
          configurationProcess.value?.processSuccessValidation?.activated &&
          foundStepTo.categoryKey === 'afterProcessRecruitment' &&
          !processAggregated.referenceProcess.dealAcceptedAt && !confirm

    if (shouldConfirmDealFirst) {
      modalData.value = { process: processAggregated.referenceProcess, step: foundStepTo };
      return;
    }

    const stepConfig = getStepConfig({
      categoryKey: foundStepTo.categoryKey,
      stepKey: foundStepTo.key,
    });

    const { data: updatedProcess } = await updateProcessCustomStep({
      id: processId,
      categoryKey: foundStepTo.categoryKey,
      stepKey: foundStepTo.key,
    });

    if (updatedProcess._customJob) {
      await store.dispatch('customJobs/updateCustomJob', {
        id: updatedProcess._customJob._id,
        nbActiveProcesses: updatedProcess._customJob.nbActiveProcesses,
        withActions: false,
      });
    }

    updateProcess({ updatedProcess, stepKeyFrom: foundStepFrom.key, stepKeyTo: foundStepTo.key });
    if (stepConfig.email && route.query['selected-view'] === 'candidates') {
      openEmailPanel(updatedProcess._coder, updatedProcess);
    }

  } catch (error) {
    console.error(error)
    toast.show({
      type: 'error',
      message: t('toast.error-occured'),
    });
  }
}

function updateProcess({ updatedProcess, stepKeyFrom, stepKeyTo }: {updatedProcess: Process, stepKeyFrom: string, stepKeyTo: string}) {
  store.dispatch('setProcessLastUpdate');

  const processIndex = processesByStepKey.value[stepKeyFrom]?.findIndex(process => process.referenceProcess._id === updatedProcess._id);
  const beforeUpdateProcess = processesByStepKey.value[stepKeyFrom].find(process => process.referenceProcess._id === updatedProcess._id);

  if (!beforeUpdateProcess) return;

  const aggregatedUpdatedProcess = { ...beforeUpdateProcess, referenceProcess: { ...beforeUpdateProcess.referenceProcess, ...updatedProcess } };
  if (stepKeyTo && stepKeyTo !== stepKeyFrom) {
    processesByStepKey.value[stepKeyTo]?.unshift(aggregatedUpdatedProcess);
    processesByStepKey.value[stepKeyFrom] = processesByStepKey.value[stepKeyFrom]?.filter(process => process.referenceProcess._id !== updatedProcess._id);
  } else {
    processesByStepKey.value[stepKeyFrom][processIndex] = aggregatedUpdatedProcess;
    processesByStepKey.value = { ...processesByStepKey.value };
  }
  handleMetadataUpdate(stepKeyTo, stepKeyFrom, aggregatedUpdatedProcess, beforeUpdateProcess)

  updatePanelProfile({
    processState: updatedProcess._coder.processState,
    coachProcessState: updatedProcess._coder.coachProcessState,
  });

  if (cardActive.value >= 0) {
    updateCard({ index: cardActive.value, content: updatedProcess._coder });
  }
}

function getCommissionValue(process) {
    return process.amountBilled || process.commissionCustom || process.commission || 0;
}

function handleMetadataUpdate(stepKeyTo, stepKeyFrom, aggregatedUpdatedProcess, beforeUpdateProcess) {
  if (!processesColumnsMetadata.value[stepKeyTo]) return;
  if (!processesColumnsMetadata.value[stepKeyFrom]) return;

  if (stepKeyFrom === stepKeyTo) {
    processesColumnsMetadata.value[stepKeyTo].totalCommission += getCommissionValue(aggregatedUpdatedProcess.referenceProcess) - getCommissionValue(beforeUpdateProcess.referenceProcess);
    return;
  }
  processesColumnsMetadata.value[stepKeyTo].documentCount++;
  processesColumnsMetadata.value[stepKeyFrom].documentCount--;

  const difference = getCommissionValue(aggregatedUpdatedProcess.referenceProcess);
  processesColumnsMetadata.value[stepKeyFrom].totalCommission -= difference;
  processesColumnsMetadata.value[stepKeyTo].totalCommission += difference;
}


function abortProcess({ processId }) {
  moveProcess({
    processId,
    stepKeyTo: configurationProcess.value.categories[0].steps[1].key
  });
}

function acceptProcess({ processId }) {
  moveProcess({
    processId,
    stepKeyTo: configurationProcess.value.categories[0].steps[1].key
  });
}

async function rejectProcess({ processId }) {
  try {
    const { data: rejectedProcess } = await abortProcessCustom(processId, { rejected: true });

    const stepKeyFrom = configurationProcess.value.categories[0].steps[0].key;
    processesByStepKey.value[stepKeyFrom] = processesByStepKey.value[stepKeyFrom]?.filter(process => process.referenceProcess._id !== processId);

    const stepConfig = getStepConfig({
      categoryKey: configurationProcess.value.categories[0].key,
      stepKey: configurationProcess.value.categories[0].steps[0].key,
    });

    if (stepConfig.email) {
      openEmailPanel(rejectedProcess._coder, rejectedProcess);
    }

  } catch (err) {
    toast.show({
      type: 'error',
      message: err.message,
    })
  }
}

async function fetchProcessesMetadata() {
  processesColumnsMetadata.value = {};
  const { data } = await getAggregatedProcessesMetadata({
    coachId: coachId.value,
    teamId: teamId.value,
    groupBy: groupBy.value,
    ...(startDate.value &&
      endDate.value && {
      from: moment(startDate.value, 'DD-MM-YYYY').toISOString(),
      to: moment(endDate.value, 'DD-MM-YYYY').toISOString(),
    }),
  });
  processesColumnsMetadata.value = data;
}
fetchProcessesMetadata();
</script>

<style lang=scss scoped>
$border: 1px solid rgba($color-blue-dodger, 0.3);
$column-width: 220px;

.processes {
  flex-direction: column;
  width: 100%;
  height: calc(100vh - var(--app-bar-height));

  &__container {
    overflow: auto;
    padding-left: 16px;
  }

  &__filters {
    z-index: 2;
  }

  &__body {
    position: relative;
    height: calc(100vh - 160px);
    border-radius: 2px;
    z-index: 1;
  }

  &__loader {
    position: absolute;
    left: -1%;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba($color-grey, 0.5);
    z-index: 2;

    &--item {
      width: 50%;
    }
  }

  &__wrapper {
    position: relative;
    flex-direction: column;
    border-right: $border;
    min-width: $column-width;
    width: 100%;

    &:last-of-type {
      border-right: 0;
    }
  }

  &__step {
    height: 100%;

    &-overlay {
      position: absolute;
      top: 47px;
      width: 100%;
      height: 100%;
      background-color: rgba($color-secondary, 0.3);
    }
  }

  &__arrow {
    z-index: 100;
    margin-left: -1rem;

    @include bp("desktop") {
      margin-left: -3rem;
      padding: 0.75rem;
    }

    &:hover {
      color: $color-primary;
      animation: bounce-left 1.5s infinite;
    }

    &:last-of-type {
      margin-right: -1rem;

      @include bp("desktop") {
        margin-right: -3rem;
        padding: 0.75rem;
      }

      &:hover {
        animation: bounce-right 1.5s infinite;
      }
    }
  }

  @keyframes bounce-left {

    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateX(0);
    }

    40% {
      transform: translateX(-1rem);
    }

    60% {
      transform: translateX(-0.5rem);
    }
  }

  @keyframes bounce-right {

    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateX(0);
    }

    40% {
      transform: translateX(1rem);
    }

    60% {
      transform: translateX(0.5rem);
    }
  }
}
</style>
