<template>
  <div class="notifications-list-content">
    <div
      v-if="!notifications.length"
      class="notifications-list-content__empty p-5"
    >
      <p>😔 {{ $t('notifications.empty-notifications') }}</p>
    </div>
    <div v-else class="notifications-list-content__items" :class="{ 'pb-10': $slots.footer }">
      <div
        v-for="(notification, index) in notifications"
        :key="notification.id"
        :class="{ 'notifications-list-content__separator': index < notifications.length - 1 }"
        class="flex w-full items-center"
      >
        <notifications-list-content-item
          v-if="!notification.link"
          :notification="notification"
          class="flex w-full items-center"
          @click="markAsRead">
        </notifications-list-content-item>
        <router-link
          class="flex w-full items-center"
          v-else
          :to="getLink(notification)"
        >
          <notifications-list-content-item
            :notification="notification"
            class="flex w-full items-center"
            @click="markAsRead">
          </notifications-list-content-item>
        </router-link>
      </div>
      <infinite-loading
        :identifier="timelineIdentifier"
        @infinite="getNextContent"
        style="justify-content: center;"
      >
        <bc-spinner slot="spinner"></bc-spinner>
        <p slot="no-more"></p>
      </infinite-loading>
    </div>
    <div
      v-if="$slots.footer"
      class="absolute bottom-0 flex w-full justify-center border-t border-t-blue-200 bg-white py-2">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';

  import NotificationsListContentItem from './NotificationsListContentItem';
  import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'notifications-list-content',
    components: {
      NotificationsListContentItem,
      InfiniteLoading,
      BcSpinner,
    },
    props: {
      notifications: {
        type: Array,
        default: () => [],
      },
      timelineIdentifier: {
        type: Number,
      },
    },
    computed: {
      profileId() {
        return this.$store.state.user.profile._id;
      },
    },
    methods: {
      ...mapActions({
        markNotificationAsRead: 'mainNotifications/markNotificationAsRead',
      }),
      markAsRead(notification) {
        if (!notification.read) {
          this.markNotificationAsRead({ notificationId: notification.id, coachId: this.profileId });
        }
      },
      getLink(notification) {
        let link = notification.link;
        if (['apply-from-career', 'apply-from-zapier'].includes(notification.type)) {
          link = link.replace('/coders/', '/pipe/applications/');
        }
        const url = new URL(link);
        return this.$router.resolve(url.href.replace(url.origin, ''))?.href;
      },
      getNextContent($state) {
        this.$emit('on-paginate', $state);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .notifications-list-content {
    flex-direction: column;
    height: 100%;
    overflow: auto;

    &__items {
      flex-direction: column;
    }

    &__separator {
      border-bottom: 1px solid #172d4b2c;
    }

    &__empty {
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: center;

      & > p {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        color: $color-blue-dark-cello;
        margin-bottom: 15px;
      }
    }
  }
</style>
