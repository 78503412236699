import { http } from '@/api/index';

export const getPokeProfile = async(id) => {
  return http.get(`/pokes/${id}`);
};

export const deletePokeProfile = async(id) => {
  return http.delete(`/pokes/${id}`);
};

export default {
  getPokeProfile,
  deletePokeProfile
};
