<template>
  <filter-content class="filter-coders-min-evaluation" :label="$t('hunt.minimum-rating')">
    <div class="filter-coders-min-evaluation__wrapper is-full-width is-column">
      <bc-slider
        v-model="rating"
        :is-input-displayed="false"
        :max="5"
        :min="0"
        :step="1"
        is-revert
        class="filter-coders-min-evaluation__slider is-full-width"
        @end-changing="setRatingFilter">
        <bc-slider-tick
          v-for="(r, index) in values"
          :key="index"
          :value="r"
          class="filter-coders-min-evaluation__tick">
          {{ r }}
        </bc-slider-tick>
      </bc-slider>
    </div>
  </filter-content>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import BcSlider from '@/ui-kit/components/BcSlider/BcSlider.vue';
import BcSliderTick from '@/ui-kit/components/BcSlider/BcSliderTick.vue';
import FilterContent from '@/components/Filter/FilterContent.vue';

const props = defineProps<{
  emitOnly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:filters', filters: Record<string, any>): void;
  (e: 'count', value: { key: string; count: number }): void;
}>();

const route = useRoute();
const router = useRouter();

const localRating = ref(0);
const rating = ref(0);

const values = computed(() => Array.from(Array(6).keys()).reverse());

function updateRating(newRating: number) {
  const ratingValue = newRating || undefined;

  if (props.emitOnly) {
    localRating.value = newRating;
    emit('update:filters', {
      rating: ratingValue,
    });
  } else {
    router.push({
      query: {
        ...route.query,
        page: 1,
        rating: ratingValue,
      },
    });
  }

  rating.value = newRating;
  emit('count', { key: 'min-evaluation', count: newRating ? 1 : 0 });
}

function setRatingFilter(newRating: number | string) {
  updateRating(+newRating);
}

onMounted(() => {
  if (props.emitOnly && route.query.rating) {
    localRating.value = +(route.query.rating as string);
    rating.value = localRating.value;
  } else if (route.query.rating) {
    setRatingFilter(route.query.rating as string);
  }
});

watch(() => route.query.rating, (newRating) => {
  if (!props.emitOnly) {
    setRatingFilter(newRating ? newRating as string : 0);
  }
});
</script>

<style lang="scss" scoped>
.filter-coders-min-evaluation {
  min-height: 90px;

  &__title {
    margin-bottom: 20px;
    font-size: $font-size-s;
    justify-content: center;
    line-height: 18px;
    @include bp('tablet') {
      justify-content: flex-start;
      text-align: left;
      margin-bottom: 0;
      width: 85px;
      margin-right: 35px;
    }
  }

  &__wrapper {
    position: relative;
    margin-right: -30px;
    justify-content: center;
    @include bp('tablet') {
      min-width: 305px;
      margin-top: -5px;
    }
  }

  &__text {
    margin-top: 15px;
  }

  &__slider {
    flex-shrink: 0;
    min-height: 55px;
  }

  &__tick {
    :deep() {
      .bc-slider-tick__label {
        min-width: 100px;
        text-align: center;
      }
    }
  }
}
</style>
