const baseUris = {
  PROD: 'https://jobs.marvinrecruiter.com',
  PREPROD: 'https://jobs.preprod.marvinrecruiter.com',
  REVIEW: 'https://jobs.review.marvinrecruiter.com',
  DEV: 'http://localhost:3000'
};

const hostnames = {
  'app.marvinrecruiter.com': baseUris.PROD,
  'preprod.marvinrecruiter.com': baseUris.PREPROD,
  'review.marvinrecruiter.com': baseUris.REVIEW,
}

const hostname = window.location.hostname;

export const getCareerUri = (organizationName, customJobId) => {
  const baseUri = hostnames[hostname] || baseUris.DEV;
  let uri = `${baseUri}/${organizationName.toLowerCase()}`;
  if (customJobId) {
    uri += `/${customJobId}`;
  }
  return uri;
}
