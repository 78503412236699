<template>
    <div
      v-tooltip.right="{ content: label, classes: 'app-bar-tooltip' }"
      :class="{ 'topbar-trigger--active': isActive }"
      class="topbar-trigger is-align-items-center is-full-width border-radius-m">
      <i
        v-if="icon"
        :class="[`icon-${icon}`, iconClass ? iconClass : 'font-size-s']"
        class="topbar-trigger__icon is-flex is-align-items-center is-relative mr-4"
      >
        <span v-if="count" class="topbar-trigger__count">
          {{ count }}
        </span>
      </i>
      <slot name="icon"></slot>
      <p
        :style="{ 'font-weight': isActive ? 500 : undefined }"
        class="topbar-trigger__text font-size-s font-weight-bold">
        {{ text }}
      </p>
      <i
        v-if="isChevron"
        :class="{
          'icon-chevron-down': isActive,
          'icon-chevron-right': !isActive,
        }"
        class="topbar-trigger__chevron is-flex font-size-xxs is-align-items-center ml-auto">
      </i>
    </div>
  </template>
  
  <script>
    export default {
      name: 'topbar-config-trigger',
      props: {
        text: {
          type: String,
          default: '',
        },
        label: {
          type: String,
          default: '',
        },
        icon: {
          type: String,
          default: '',
        },
        isActive: {
          type: Boolean,
          required: false,
          default: false,
        },
        isChevron: {
          type: Boolean,
          required: false,
          default: false,
        },
        textClass: {
          type: String,
        },
        textStyle: {
          type: String,
        },
        count: {
          type: Number,
        },
        iconClass: {
          type: String,
        },
      },
    };
  </script>
  
  <style lang="scss" scoped>
  
    .topbar-trigger {
      padding: 10px 15px;
      transition: 0.3s;
      align-items: center;
      height: 40px;
      color: $color-blue-dark-cello;
  
      &:hover {
        color: $color-primary;
      }
  
      &--active {
        background: $color-primary;
        border-radius: 10px;
        color: white;
  
        & p, i {
          color: white;
        }
  
      }
  
      &__count {
        position: absolute;
        top: -10px;
        left: 8px;
        font-style: normal;
        border-radius: 100%;
        background: #E6525A;
        color: white;
        font-size: 10px;
        padding: 2px;
        min-width: 15px;
      }
    }
  </style>
  
  <style lang="scss">
    .app-bar-tooltip[x-placement^="right"] {
      & .tooltip-arrow {
        display: none;
      }
  
      & .tooltip-inner {
        font-size: $font-size-xs;
        font-weight: $font-weight-regular;
      }
    }
  </style>
  