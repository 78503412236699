<template>
  <component :is="value" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import { mapGetters } from 'vuex';
import { getIntegrationByName, XTRAMILE_INTEGRATION_KEY } from '@/api/integrations';

import JobboardPublishListNew from './JobboardPublish/JobboardPublishList';
import JobboardPublishListOld from './JobboardPublish/JobboardPublishListOld';

export default {
  name: 'jobboard-publish-list',
  components: {
    JobboardPublishListNew,
    JobboardPublishListOld,
  },
  async created() {
    const { data } = await getIntegrationByName(XTRAMILE_INTEGRATION_KEY);
    if (data.config?.limitedOrganizations && !data.config.limitedOrganizations.includes(this.organization._id)) {
      this.value = 'jobboard-publish-list-old';
    }
  },
  data() {
    return {
      value: 'jobboard-publish-list-new',
    }
  },
  computed: {
    ...mapGetters({
      organization: 'user/organization',
    }),
  }
}
</script>
