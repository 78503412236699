<template>
  <div class="values is-full-width">
    <div class="values__container">
      <title-section
        :background-color="'white'"
        :title="`Valeurs / Soft skills (${values.length})`"
        class="values__title"
        color="blue">
      </title-section>
      <div class="values__wrapper">
        <technos-tag
          :is-creating="true"
          :tag-type="type"
          class="values__tag">
        </technos-tag>
        <technos-tag
          :key="value._id"
          :tag-type="type"
          :techno="value"
          class="values__tag"
          v-for="value in values">
        </technos-tag>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleSection from '@/components/Title/TitleSection';
  import { mapState } from 'vuex';
  import TechnosTag from '@/components/Technos/TechnosTag';
  import { apiType } from '@/macros/tags/types';

  export default {
    name: 'values',
    components: {
      TechnosTag,
      TitleSection,
    },
    data() {
      return {
        type: apiType.value,
      };
    },
    computed: {
      ...mapState({
        values: state => state.tags.values,
      }),
    },
  };
</script>

<style lang="scss" scoped>
  .values {
    margin-top: 65px;
    padding: 0 15px;
    @include page_grid();

    &__container {
      flex-direction: column;
      width: 100%;
    }

    &__title {
      width: 100%;
      margin-bottom: 10px;
      @include bp('tablet') {
        margin-bottom: 15px;
      }
    }

    &__wrapper {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__tag {
      margin-bottom: 10px;
      width: 100%;
      @include bp('tablet') {
        width: calc(50% - 10px);
      }
    }
  }
</style>
