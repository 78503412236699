import camelCase from 'lodash.camelcase';

const files = import.meta.glob('./*.js', { eager: true });
const modules = {};

Object.keys(files).forEach(fileName => {
    if (fileName === './index.js') return;
    const moduleName = camelCase(
        fileName.replace(/(\.\/|\.js)/g, ''),
    );

    modules[moduleName] = files[fileName].default;
});

export default modules;
