<template>
  <div
    class="card-reminders-coders is-full-width is-column is-relative"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
    @click.exact="openPanel({ id: cardContent._coder?._id, type: 'suggestions-coders', context})"
    @click.meta.exact="openPanelInNewTab({ id: cardContent._coder?._id, type: 'suggestions-coders',  context})">
    <CardRemindersCodersBody
      :card-content="cardContent"
      :context="context"
      :is-hover="isHover"
      :is-selected="isSelected"
      @checked="$emit('checked', $event)">
    </CardRemindersCodersBody>
  </div>
</template>

<script>
  //
  import PanelNavigationMixin from '@/common-old/mixins/Panel/panelNavigation';
  import CardRemindersCodersBody
    from '@/components/Card/CardReminders/CardRemindersCoders/CardRemindersCodersBody.vue';

  export default {
    name: 'card-reminders-coders',
    components: { CardRemindersCodersBody },
    mixins: [PanelNavigationMixin],
    props: {
      cardContent: {
        type: Object,
        default: () => ({}),
      },
      isSelected: {
        type: Boolean,
        default: false,
      },
      context: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isHover: false,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .card-reminders-coders {
    &:hover {
      cursor: pointer;
      max-height: initial;
    }

    @include bp("tablet") {
      max-height: 120px;
    }

    &__footer {
      border-radius: 5px 5px 0 0;
      bottom: 6px;
    }

    &__footer-text {
      margin: 10px 25px;
      font-size: 14px;
      color: $color-blue-heavy-dark;
    }
  }
</style>
