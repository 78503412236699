<template>
  <button
    class="integration-kaspr-button"
    :class="{ 'integration-kaspr-button--disabled': isDisabled }"
    v-bind="$attrs"
    v-tooltip="{ content: isDisabled ? $t('config.profile-empty-linkedin') : $t('config.kaspr_find_coordinates') }"
    @click.prevent="searchLinkedin"
    :disabled="isLoading"
  >
    <img v-if="!isLoading" src="@/assets/logo/kaspr_logo.png"/>
    <bc-spinner v-else></bc-spinner>
  </button>
</template>

<script>
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import { searchLinkedin } from '@/api/kaspr';

  export default {
    name: 'integration-kaspr-button',
    components: {
      BcSpinner,
    },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
      ressource: {
        type: String,
        default: 'coder',
        validator(value) {
          return ['coder', 'contact'].includes(value);
        },
      },
    },
    data() {
      return {
        isLoading: false,
      };
    },
    computed: {
      linkedinId() {
        if (!this.profile.social) {
          return;
        }
        return this.profile.social.linkedin.split('https://www.linkedin.com/in/')[1];
      },
      isDisabled() {
        if (!this.profile.social) {
          return true;
        }
        return !this.profile.social.linkedin || !this.linkedinId;
      },
    },
    methods: {
      async searchLinkedin() {
        if (this.isDisabled) {
          return;
        }
        this.isLoading = true;
        try {
          const { data } = await searchLinkedin({
            linkedinId: this.linkedinId,
            name: `${this.profile.firstName} ${this.profile.lastName}`,
            [`${this.ressource}Id`]: this.profile._id,
          });

          if (!Object.keys(data).length || (data?.updated?.newNumbersCount + data?.updated?.newNumbersCount + data?.updated?.newWorkEmailsCount) === 0) {
            this.$toast.show({
              type: 'warning',
              message: this.$t('config.kaspr-empty-result'),
            });
          }

          if (data.updated) {
            this.$toast.show({
              type: 'success',
              message: this.$t('config.kaspr-update-fields-count', {
                workEmailsCount: data.updated.newWorkEmailsCount,
                directEmailsCount: data.updated.newDirectEmailsCount,
                numbersCount: data.updated.newNumbersCount,
              }),
              duration: 5000,
            });
          }

          if (data.profile) {
            this.$emit('profile-updated', data.profile);
          }

          this.isLoading = false;
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err.response?.data?.message || err.message,
          });
          this.isLoading = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .integration-kaspr-button {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    align-items: center;
    justify-content: center;

    & > img {
      width: 100%;
      height: 100%;
    }

    &--disabled {
      opacity: 0.4;
    }
  }
</style>
