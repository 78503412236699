<template>
  <div
    ref="bcSlider"
    class="bc-slider is-align-items-stretch">
    <bc-input-number
      v-if="isInputDisplayed"
      v-model="leftValue"
      :class="{
        'bc-slider__input--hover': !disabled && leftCursor.hover,
        'bc-slider__input--pressed': !disabled && leftCursor.pressed,
      }"
      :disabled="disabled"
      :placeholder="placeholder"
      :max="max"
      :min="min"
      :should-display-incrementer="false"
      class="bc-slider__input is-justify-content-center"
      name="left-input"
      @blur="onBlur('left')"
      @focus="onFocus('left')">
    </bc-input-number>
    <div
      :class="{ 'bc-slider__wrapper--disabled': disabled }"
      class="bc-slider__wrapper is-full-width is-align-items-center"
      @mousedown.prevent="barMouseDown"
      @touchstart.prevent="barMouseDown">
      <div
        ref="sliderBar"
        :class="{ 'bc-slider__bar--disabled': disabled }"
        class="bc-slider__bar">
        <template v-if="ticks">
          <bc-slider-tick
            v-for="(tick, index) in tickValues"
            :key="index"
            :value="tick">
          </bc-slider-tick>
        </template>
        <slot></slot>
        <div
          ref="bcBar"
          :class="{ 'bc-slider__bar-fill--range': type === 'range', 'bc-slider__bar-fill--revert': isRevert }"
          :style="progressBarStyle"
          class="bc-slider__bar-fill"
          @mousedown.self.prevent="barMouseDown"
          @touchstart.self.prevent="barMouseDown">
        </div>
        <div
          ref="bcCursorLeft"
          :class="{
            'bc-slider__cursor--hover': !disabled && leftCursor.hover,
            'bc-slider__cursor--active': !disabled && leftCursor.pressed,
          }"
          :style="cursorLeftStyle"
          class="bc-slider__cursor bc-slider__cursor-left"
          @mousedown.prevent="cursorMouseDown(leftCursor)"
          @mouseout.prevent="leftCursor.hover = false"
          @mouseover.prevent="leftCursor.hover = true"
          @touchend.prevent="leftCursor.hover = false"
          @touchstart.prevent="cursorMouseDown(leftCursor)">
        </div>
        <div
          v-if="type === 'range'"
          ref="bcCursorRight"
          :class="{
            'bc-slider__cursor--hover': !disabled && rightCursor.hover,
            'bc-slider__cursor--active': !disabled && rightCursor.pressed,
          }"
          :style="cursorRightStyle"
          class="bc-slider__cursor bc-slider__cursor-right"
          @mousedown.prevent="cursorMouseDown(rightCursor)"
          @mouseout.prevent="rightCursor.hover = false"
          @mouseover.prevent="rightCursor.hover = true"
          @touchend.prevent="rightCursor.hover = false"
          @touchstart.prevent="cursorMouseDown(rightCursor)">
        </div>
      </div>
    </div>
    <bc-input-number
      v-if="isInputDisplayed && type === 'range'"
      v-model="rightValue"
      :class="{
        'bc-slider__input--hover': !disabled && rightCursor.hover,
        'bc-slider__input--pressed': !disabled && rightCursor.pressed,
      }"
      :disabled="disabled"
      :placeholder="placeholder"
      :max="max"
      :min="min"
      :should-display-incrementer="false"
      class="bc-slider__input is-justify-content-center"
      name="right-input"
      @blur="onBlur('right')"
      @focus="onFocus('right')">
    </bc-input-number>
  </div>
</template>

<script>
  import BcInputNumber from '../BcInputNumber/BcInputNumber';
  import BcSliderTick from './BcSliderTick';

  export default {
    name: 'bc-slider',
    components: { BcSliderTick, BcInputNumber },
    props: {
      /**
       * The value of the slider
       */
      value: {
        type: [Number, Array],
      },
      /**
       * The min value of the slider
       */
      min: {
        type: Number,
        default: 0,
      },
      /**
       * The max value of the slider
       */
      max: {
        type: Number,
        default: 100,
      },
      /**
       * The type of the slider
       * @values 'simple', 'range'
       */
      type: {
        type: String,
        default: 'simple',
        validator: value => ['simple', 'range'].includes(value),
      },
      placeholder: {
        type: String,
      },
      /**
       * Define the value of step
       * @values 'simple', 'range'
       */
      step: {
        type: Number,
        default: 1,
        validator: value => value >= 0,
      },
      /**
       * Disable the slider
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      isInputDisplayed: {
        type: Boolean,
        default: true,
      },
      ticks: {
        type: Boolean,
        default: false,
      },
      ticksLabel: {
        type: Boolean,
        default: false,
      },
      isRevert: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        lastPressed: 'left',
        leftCursor: {
          name: 'left',
          hover: false,
          pressed: false,
          focus: false,
          value: this.value.length > 0 ? this.value[0] : this.value,
        },
        rightCursor: {
          name: 'right',
          hover: false,
          pressed: false,
          focus: false,
          value: this.value[1] || this.max,
        },
        delta: this.max - this.min,
      };
    },
    computed: {
      leftValue: {
        get() {
          return this.type === 'range' ? this.value[0] : this.value;
        },
        set(value) {
          this.$emit('input', this.type === 'range' ? [value, this.value[1]] : value);
        },
      },
      rightValue: {
        get() {
          return this.value[1];
        },
        set(value) {
          this.$emit('input', [this.value[0], value]);
        },
      },
      tickValues() {
        if (!this.ticks || this.min > this.max || this.step === 0) {
          return [];
        }

        const result = [];

        for (let i = this.min + this.step; i < this.max; i = i + this.step) {
          result.push(i);
        }

        return result;
      },
      progressBarStyle() {
        return this.getProgressBarStyle();
      },
      cursorLeftStyle() {
        if (Array.isArray(this.value) && this.leftValue > this.rightValue && this.leftCursor.focus) {
          return { left: `${this.computePosition(this.value[1])}%` };
        } else if (this.value > this.max || this.leftValue > this.max) {
          return { left: `${this.computePosition(this.max)}%` };
        } else if (this.value < this.min || this.leftValue < this.min) {
          return { left: `${this.computePosition(this.min)}%` };
        }

        return { left: `${this.computePosition(this.type === 'range' ? this.value[0] : this.value)}%` };
      },
      cursorRightStyle() {
        if (this.rightValue < this.leftValue && this.rightCursor.focus) {
          return { left: `${this.computePosition(this.leftValue)}%` };
        } else if (this.rightValue > this.max) {
          return { left: `${this.computePosition(this.max)}%` };
        }
        return { left: `${this.computePosition(this.rightValue)}%` };
      },
    },
    mounted() {
      // Check props
      if (this.type === 'range') {
        if (!Array.isArray(this.value)) {
          console.error('Expected value to be an array');
          this.$emit('input', [this.min, this.max]);
        }

        if (this.value[0] < this.min) {
          console.error(`Expected min range value to be equal or superior to ${this.min}`);
          this.$emit('input', [this.min, this.value[1]]);
        }

        if (this.value[1] > this.max) {
          console.error(`Expected max range value to be equal or inferior to ${this.max}`);
          this.$emit('input', [this.value[0], this.max]);
        }
      } else if (this.type === 'simple') {
        if (this.value < this.min) {
          console.error(`Expected value to be equal or superior to ${this.min}`);
          this.$emit('input', this.min);
        }

        if (this.value > this.max) {
          console.error(`Expected value to be equal or inferior to ${this.max}`);
          this.$emit('input', this.max);
        }
      }
    },
    methods: {
      computeValue(posX, min, max) {
        const bodyRect = document.body.getBoundingClientRect();
        const sliderBarRect = this.$refs.sliderBar.getBoundingClientRect();

        const minPx = sliderBarRect.left - bodyRect.left;
        const maxPx = sliderBarRect.right - bodyRect.left;
        const ratio = (posX - minPx) / (maxPx - minPx);

        let newValue = ((this.max - this.min) * ratio) + Math.abs(this.min);

        if (newValue > max) {
          newValue = max;
        } else if (newValue < min) {
          newValue = min;
        }

        return Math.floor(newValue / this.step) * this.step;
      },
      computePosition(value) {
        return (value - this.min) / this.delta * 100;
      },

      cursorMouseDown(cursor) {
        cursor.pressed = true;
        this.lastPressed = cursor.name;

        window.onmousemove = mouseEvent => this.cursorMouseMove(mouseEvent, cursor);
        this.$refs.bcSlider.ontouchmove = touchEvent => this.cursorMouseMove(touchEvent, cursor);
        this.$refs.bcBar.ontouchmove = touchEvent => this.cursorMouseMove(touchEvent, cursor);
        this.$refs.bcCursorLeft.ontouchmove = touchEvent => this.cursorMouseMove(touchEvent, cursor);

        if (this.$refs.bcCursorRight) {
          this.$refs.bcCursorRight.ontouchmove = touchEvent => this.cursorMouseMove(touchEvent, cursor);
        }

        window.onmouseup = () => cursor.pressed = false;
        this.$refs.bcSlider.ontouchend = () => cursor.pressed = false;
      },
      cursorMouseMove(event, cursor) {
        if (cursor.pressed) {
          const clientX = event.touches ? event.touches[0].clientX : event.clientX;

          if (this.type === 'simple') {
            /**
             * input -> Emit the value
             *
             * @event input
             * @type {number}
             */
            this.$emit('input', this.computeValue(clientX, this.min, this.max));
          } else if (this.type === 'range') {
            if (cursor.name === 'left') {
              this.$emit('input', [this.computeValue(clientX, this.min, this.value[1]), this.value[1]]);
              this.lastPressed = 'left';
            } else if (cursor.name === 'right') {
              this.$emit('input', [this.value[0], this.computeValue(clientX, this.value[0], this.max)]);
              this.lastPressed = 'right';
            }
          }
        }
      },

      barMouseDown(event) {
        const clientX = event.touches ? event.touches[0].clientX : event.clientX;

        if (this.type === 'simple') {
          this.cursorMouseDown(this.leftCursor);
          this.cursorMouseMove(event, this.leftCursor);
        } else if (this.type === 'range') {
          const clickedValue = this.computeValue(clientX, this.min, this.max);

          if (clickedValue < this.value[0]) {
            this.$emit('input', [clickedValue, this.value[1]]);
            this.lastPressed = 'left';
          } else if (clickedValue > this.value[1]) {
            this.$emit('input', [this.value[0], clickedValue]);
            this.lastPressed = 'right';
          } else {
            if (this.lastPressed === 'left') {
              this.$emit('input', [clickedValue, this.value[1]]);
            } else {
              this.$emit('input', [this.value[0], clickedValue]);
            }
          }
          this.cursorMouseDown(this[`${this.lastPressed}Cursor`]);
        }
      },
      onFocus(position) {
        if (position === 'left') {
          this.leftCursor.focus = true;
        } else {
          this.rightCursor.focus = true;
        }
      },
      onBlur(position) {
        if (position === 'left') {
          this.leftCursor.focus = false;

          this.reajustLeftValue();
        } else {
          this.rightCursor.focus = false;

          this.reajustRightValue();
        }
      },
      reajustLeftValue() {
        let value = this.type === 'range' ? this.value[0] : this.value;

        if (this.type === 'range') {
          if (value > this.value[1]) {
            this.$emit('input', [this.value[1], this.value[1]]);
          } else if (value > this.max) {
            this.$emit('input', [this.max, this.value[1]]);
          } else if (value < this.min) {
            this.$emit('input', [this.min, this.value[1]]);
          }
        } else {
          if (value === undefined) return;
          if (value > this.max) {
            this.$emit('input', this.max);
          } else if (value < this.min) {
            this.$emit('input', this.min);
          }
        }
      },
      reajustRightValue() {
        let value = this.value[1];

        if (value < this.value[0]) {
          this.$emit('input', [this.value[0], this.value[0]]);
        }
        if (value > this.max || value < this.min) {
          this.$emit('input', [this.value[0], this.max]);
        }
      },
      getProgressBarStyle() {
        let width;

        if (Array.isArray(this.value)) {
          const [leftValue, rightValue] = this.value;

          let left, right;

          if (leftValue < this.min) {
            left = this.computePosition(this.min);
          } else if (leftValue > this.max) {
            left = this.computePosition(this.max);
          } else {
            left = this.computePosition(leftValue);
          }

          if (rightValue < this.min) {
            right = this.computePosition(this.min);
          } else if (rightValue > this.max) {
            right = this.computePosition(this.max);
          } else {
            right = this.computePosition(rightValue);
          }

          if (right < left) {
            width = 0;
          } else {
            width = right - left;
          }

          return {
            width: `${width}%`,
            left: `${left}%`,
          };
        }

        if (this.isRevert) {
          const left = this.computePosition(this.value);
          width = this.computePosition(this.value) > 0 ? 100 - this.computePosition(this.value) : 100;

          return {
            width: `${width}%`,
            left: `${left}%`,
          };
        } else {
          width = this.computePosition(this.value) > 0 ? this.computePosition(this.value) : 0;
          return { width: `${width}%` };
        }
      },
    },
    watch: {
      'leftCursor.pressed'(to, from) {
        const value = this.type === 'range' ? [this.leftValue, this.rightValue] : this.leftValue;

        if (from && !to) {
          this.$emit('end-changing', value);
        }
      },
      'rightCursor.pressed'(to, from) {
        const value = this.type === 'range' ? [this.leftValue, this.rightValue] : this.leftValue;

        if (from && !to) {
          this.$emit('end-changing', value);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .bc-slider {
      @include bp('phone') {
        margin-top: 20px;
        justify-content: space-between;
      }
    &__wrapper {
      cursor: pointer;
      position: relative;

      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }

    }

    &__input {
      min-width: 70px;
      min-height: 40px;
      transition: color 0.3s;
      color: $color-grey-4;
      font-size: $font-size-m;
      user-select: none;
      margin-top: 0;

      & :deep() {
        .bc-input-number__container {
          height: 100%;
        }

        .bc-input-number__wrapper {
          width: 100%;
        }
      }

      &--hover {
        :deep() {
          .bc-input-number__input {
            color: $color-grey-5;
            border-color: $color-grey-5;
          }
        }
      }

      &--pressed {
        & :deep() {
          .bc-input-number__input {
            color: $color-primary;
            border-color: $color-primary;
          }
        }
      }
    }

    &__bar {
      position: relative;
      align-items: center;
      margin: 0 15px + 11px; // 15 is the margin between label and slider. 11 is the radius of the cursor
      height: 4px;
      width: 100%;
      background-color: $color-blue-light;
      border-radius: $border-radius-m;

      &--disabled {
        cursor: initial;
      }

      &-fill {
        left: 0;
        height: 100%;
        background-color: $color-primary;
        border-radius: $border-radius-m;

        &--range {
          position: absolute;
          width: 100%;
        }

        &--revert {
          position: absolute;
        }
      }
    }

    &__cursor {
      position: absolute;
      width: 14px;
      height: 14px;
      background-color: $color-primary;
      border-radius: $border-radius-rounded;
      box-shadow: $shadow-default;
      transition: background-color 0.3s;
      cursor: inherit;

      &:hover {
        cursor: grab;
        box-shadow: none;
        background-color: $color-primary-dark;
      }

      &--active {
        width: 14px;
        height: 14px;
        box-shadow: none;
        margin-left: -13px;
        background-color: $color-primary;

        &:hover {
          background-color: $color-primary;
        }
      }

      &-left {
        margin-left: -11px;
      }

      &-right {
        margin-left: -11px;
      }
    }
  }
</style>
