<template>
  <div class="panel-header">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    /**
     * The base of the Panel header
     */
    name: 'panel-header'
  };
</script>

<style scoped lang="scss">
  .panel-header {
    background: $color-white;
    padding: 10px 20px;
    flex-shrink: 0;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid $color-blue-light;
    align-items: stretch;
  }
</style>
