const customFields = {
  'single-select': {
    key: 'single-select',
    multiselectFilter: true,
    params: {
      options: [],
    },
  },
  'multi-select': {
    key: 'multi-select',
    multiselectFilter: true,
    params: {
      options: [],
    },
  },
  cursor: {
    key: 'cursor',
    filter: 'range',
    params: {
      min: 0,
      max: 0,
      increment: 0,
    },
  },
  range: {
    key: 'range',
    params: {
      min: 0,
      max: 0,
      increment: 0,
    },
  },
  number: {
    key: 'number',
    filter: 'number',
    params: {
      min: 0,
      max: 0,
      increment: 0,
    },
  },
  'number-with-unit': {
    key: 'number-with-unit',
    filter: 'number',
    params: {
      min: 0,
      max: 0,
      increment: 0,
      unit: '',
    },
  },
  link: {
    key: 'link',
  },
  string: {
    key: 'string',
  },
};

export const CUSTOM_FIELDS_MULTISELECT_FILTERS = Object.values(customFields).filter(value => value.multiselectFilter).map(value => value.key)

export default customFields;
