<template>
  <div class="panel-favorite-list-add-resource-header">
    <bc-button
      @click.native="closePanel"
      bc-background-color="transparent"
      bc-icon="cross"
      bc-icon-size="16px"
      bc-opacity="0.4"
      bc-text-color="blue-dark-cello"
      class="panel-favorite-list-add-resource-header__close">
    </bc-button>
    <bc-button
      @click.native="goPrevious"
      bc-background-color="transparent"
      bc-icon="arrow-left"
      bc-icon-size="16px"
      bc-opacity="0.4"
      bc-text-color="blue-dark-cello"
      class="panel-favorite-list-add-resource-header__previous">
    </bc-button>
    <h2 class="panel-favorite-list-add-resource-header__title">
      {{ $t('companies.panel-company.add-to-a-list') }}
    </h2>
  </div>
</template>

<script>
  import panelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import BcButton from "@/legacy/ui-kit/components/Button/BcButton";

  export default {
    name: "panel-favorite-list-add-resource-header",
    components: { BcButton },
    mixins: [panelNavigation]
  }
</script>

<style lang=scss scoped>
  .panel-favorite-list-add-resource-header {
    position: relative;
    width: 100%;
    height: 35px;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &__close {
      position: absolute;
      left: 0;

      &:hover {
        cursor: pointer;
      }
    }

    &__previous {
      position: absolute;
      left: 30px;

      &:hover {
        cursor: pointer;
      }
    }

    &__title {
      font-size: $font-size-m;
      color: $color-blue-dark-cello;
      text-transform: uppercase;
    }
  }

</style>
