<template>
  <div class="panel-favorite-list-create" @click.stop>
    <panel-header>
      <panel-favorite-list-header :current-coach="currentCoach" :list="list"/>
    </panel-header>
    <ValidationObserver
      ref="observer"
      v-slot="{ valid }"
      class="panel-favorite-list-create__wrapper"
      tag="form"
      @submit.prevent>
      <div class="panel-favorite-list-create__body">
        <panel-favorite-list-name v-model="list.name"/>
        <panel-favorite-list-type
          :list-placeholder="typePlaceholder"
          @select="updateListType"/>
        <panel-favorite-list-share
          :current-coach="currentCoach"
          :current-owner="currentOwner"
          :edited-list-subscribers="list.subscribers"
          @add-subscriber="pushSubscriber"
          @remove-subscriber="removeSubscriber"
          @set-to-admin="updateSubscriberRole($event, 'admin')"
          @set-to-owner="updateSubscriberRole($event, 'owner')"
          @set-to-reader="updateSubscriberRole($event, 'reader')">
        </panel-favorite-list-share>
      </div>
      <panel-favorite-list-footer :edited-list="editedList" :is-valid-form="valid">
      </panel-favorite-list-footer>
    </ValidationObserver>
    <modal v-if="isModalDisplayed" @close-modal="closeModal">
      <panel-favorite-list-modal-new-owner
        @close-modal="closeModal"
        @on-validate="setCurrentOwnerToAdmin">
      </panel-favorite-list-modal-new-owner>
    </modal>
  </div>
</template>

<script lang="ts" setup>
  import { ValidationObserver } from 'vee-validate';
  import Modal from '@/components/Modal/Modal';
  import PanelFavoriteListHeader
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListHeader';
  import PanelFavoriteListName from '@/components/Panel/PanelFavoriteList/PanelFavoriteListName';
  import PanelFavoriteListType from '@/components/Panel/PanelFavoriteList/PanelFavoriteListType';
  import PanelFavoriteListFooter
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListFooter';
  import PanelHeader from '@/components/Panel/PanelHeader';
  import PanelFavoriteListShare from '@/components/Panel/PanelFavoriteList/PanelFavoriteListShare';
  import PanelFavoriteListModalNewOwner
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListModal/PanelFavoriteListModalNewOwner';
  import favoriteListsMacros from '@/macros/favoriteLists/favoriteLists';
  import { useRoute } from 'vue-router/composables';
  import { computed, onUnmounted, ref, Ref } from 'vue';
  import { useStore } from '@/store';

  const route = useRoute();
  const store = useStore();

  type List = {
    name: string;
    type: string;
    coachId?: string;
    subscribers: { role: number, _id: string }[];
  };

  const mapRouteQueries: {[listType: string]: number} = {
    'favorite-coder-list': 1,
    'favorite-job-list': 2,
    'favorite-company-list': 3,
    'favorite-contact-list': 4,
  };

  const list: Ref<List> = ref({
    name: '',
    type: mapRouteQueries[route.query.type] ?? favoriteListsMacros.types.coder,
    subscribers: [],
  });
  const newOwner = ref({});
  const isModalDisplayed = ref(false);

  const currentOwner = computed(
    () =>
      list.value.subscribers.find(
        subscriber => subscriber.role === favoriteListsMacros.apiRights.owner,
      ) || {},
  );

  const currentCoach = computed(
    () => list.value.subscribers.find(subscriber => subscriber._id === userId.value) || {},
  );

  const userId = computed(() => store.state.user.profile._id);

  const formattedSubscribers = computed(() =>
    list.value.subscribers.map(subscriber => ({
      _coach: subscriber._id,
      role: subscriber.role,
    })),
  );

  const editedList = computed(() => ({
    ...(list.value.name && { name: list.value.name }),
    coachId: list.value.coachId,
    ...(formattedSubscribers.value.length > 0 && { subscribers: formattedSubscribers.value }),
    type: list.value.type,
  }));

  const typePlaceholder = computed(
    () => favoriteListsMacros.listTypeOptions.find(option => option.type === list.value.type).name,
  );

  function setupList() {
    const newOwner = {
      ...store.state.user.profile,
      role: favoriteListsMacros.apiRights.owner,
    };
    list.value.coachId = userId.value;
    list.value.subscribers.push(newOwner);
  }

  function pushSubscriber($event) {
    $event.isAdded = true;
    list.value.subscribers.push($event);
  }

  function updateSubscriberRole($event, role) {
    const subIndex = list.value.subscribers.findIndex(sub => sub._id === $event._id);

    if (role === 'owner') {
      newOwner.value = $event;
      openNewOwnerModal();
    } else {
      list.value.subscribers[subIndex].role = favoriteListsMacros.apiRights[role];
    }
  }

  function openNewOwnerModal() {
    isModalDisplayed.value = true;
  }

  function setCurrentOwnerToAdmin() {
    const currentOwnerIndex = list.value.subscribers.findIndex(
      subscriber => subscriber.role === favoriteListsMacros.apiRights.owner,
    );
    const newOwnerIndex = list.value.subscribers.findIndex(
      subscriber => subscriber._id === newOwner.value._id,
    );

    list.value.subscribers[currentOwnerIndex].role = favoriteListsMacros.apiRights.admin;
    list.value.subscribers[newOwnerIndex].role = favoriteListsMacros.apiRights.owner;

    newOwner.value = {};

    isModalDisplayed.value = false;
  }

  function removeSubscriber($event) {
    const subIndex = list.value.subscribers.findIndex(subscriber => subscriber._id === $event._id);

    list.value.subscribers.splice(subIndex, 1);
  }

  function closeModal() {
    isModalDisplayed.value = false;
  }

  function updateListType(newList: Pick<List, 'name' | 'type'>): void {
    list.value.type = newList.type;
  }

  onUnmounted(() => {
    delete route.query['list-id'];
  });

  setupList();
</script>

<style lang="scss" scoped>
  .panel-favorite-list-create {
    position: relative;
    flex-direction: column;
    height: 100%;
    background: white;

    &__container {
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
    }

    &__wrapper {
      height: calc(100% - 56px);
      flex-direction: column;
      flex-grow: 1;
    }

    &__body {
      padding: 20px;
      flex-direction: column;
      flex-grow: 1;
      overflow: auto;
      @include bp('tablet') {
        padding: 0 50px;
      }
    }
  }
</style>
