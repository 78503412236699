<template>
  <component
    :is="panelFavoriteListCreateComponent"
    v-bind="$attrs"
    v-on="$listeners">
  </component>
</template>

<script>
  import PanelFavoriteListCreateCoach
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListCreate/PanelFavoriteListCreateCoach';
  import PanelFavoriteListCreateRpo
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListCreate/PanelFavoriteListCreateRpo';

  export default {
    name: 'panel-favorite-list-update',
    components: { PanelFavoriteListCreateRpo, PanelFavoriteListCreateCoach },
    inheritAttrs: false,
    computed: {
      panelFavoriteListCreateComponent() {
        return 'panel-favorite-list-create-rpo'
      },
    },
  };

</script>
