import favoriteLists from '@/api/favoriteLists';
import Vue from 'vue';
import {
  myCodersPipeName,
  myGardenTag,
  myIgnoredCodersName,
  myOldProcesses,
} from '@/macros/favoriteLists/favoriteLists';

const mapListMetadatas = ['coderLists', 'jobLists', 'companyLists'];

export default {
  state: () => {
    return {
      lists: [],
    };
  },
  getters: {
    myVisibleLists(state, getters) {
      return [
        ...state.lists.filter(list => !list.hidden),
        getters.myGarden,
        getters.myOldProcesses,
      ];
    },
    myGarden(state) {
      return state.lists.find(list => list.tag === myGardenTag);
    },
    myOldProcesses(state) {
      return state.lists.find(list => list.name === myOldProcesses);
    },
    pipeCodersList(state) {
      return (state.lists.find(list => list.name === myCodersPipeName && list.locked) || {});
    },
    myIgnoredCodersList(state) {
      return (state.lists.find(list => list.name === myIgnoredCodersName && list.locked) || {});
    },
  },
  actions: {
    async setCoachFavoriteLists({ commit }, coachId) {
      const response = await favoriteLists.getCoachFavoriteLists(coachId);

      commit('setLists', response.data);

      return response;
    },
    async setGlobalFavoriteLists({ commit }, { query, coderId }) {
      const response = await favoriteLists.getFavoriteLists({ query, coderId });

      commit('setLists', response.data.lists);

      return response;
    },
    async createFavoriteList({ commit, dispatch }, { name, coachId, subscribers, type }) {
      const response = await favoriteLists.createFavoriteList({ name, coachId, subscribers, type });

      dispatch('user/incrementMetadataByKey', mapListMetadatas[type - 1]);
      dispatch('user/incrementMetadataByKey', 'lists');

      commit('addList', response.data);

      return response;
    },
    async editFavoriteList({ commit }, { listId, name, addedSubscribers, changedSubscribers, removedSubscribers }) {
      const response = await favoriteLists.editFavoriteList({
        listId,
        name,
        addedSubscribers,
        changedSubscribers,
        removedSubscribers,
      });

      commit('editList', response.data);

      return response;
    },
    async deleteFavoriteList({ commit, dispatch }, { listId, listType }) {
      const response = await favoriteLists.deleteFavoriteList(listId);

      dispatch('user/decrementMetadataByKey', mapListMetadatas[listType - 1]);
      dispatch('user/decrementMetadataByKey', 'lists');

      commit('removeCard', listId);

      return response;
    },
    async addCoderToFavoriteList({ commit }, { listId, resourceId, cardIndex }) {
      const response = await favoriteLists.addCoderToFavoriteList({ listId, coderId: resourceId });

      commit('incrementElementListCount', listId);

      if (cardIndex > -1) {
        commit('updateCard', {
          index: cardIndex,
          content: response.data,
        });
      }

      return response;
    },
    async addJobToFavoriteList({ commit }, { listId, resourceId, cardIndex }) {
      const response = await favoriteLists.addJobToFavoriteList({ listId, jobId: resourceId });

      commit('incrementElementListCount', listId);

      if (cardIndex > -1) {
        commit('updateCard', {
          index: cardIndex,
          content: response.data,
        });
      }
    },
    async addCompanyToFavoriteList({ commit }, { listId, resourceId, cardIndex }) {
      const response = await favoriteLists.addCompanyToFavoriteList({
        listId,
        companyId: resourceId,
      });

      if (cardIndex > -1) {
        commit('updateCard', {
          index: cardIndex,
          content: response.data,
        });
      }

      return response;
    },
    async deleteCoderFromFavoriteList({ commit }, { listId, resourceId, cardIndex }) {
      const response = await favoriteLists.removeCoderFromFavoriteList({
        listId,
        coderId: resourceId,
      });

      commit('decrementElementListCount', listId);

      if (cardIndex > -1) {
        commit('updateCard', {
          index: cardIndex,
          content: response.data,
        });
      }

      return response;
    },
    async removeJobFromFavoriteList({ commit }, { listId, resourceId, cardIndex }) {
      const response = await favoriteLists.removeJobFromFavoriteList({ listId, jobId: resourceId });

      commit('decrementElementListCount', listId);

      if (cardIndex > -1) {
        commit('updateCard', {
          index: cardIndex,
          content: response.data,
        });
      }
    },
    async removeCompanyFromFavoriteList({ commit }, { listId, resourceId, cardIndex }) {
      const response = await favoriteLists.removeCompanyFromFavoriteList({
        listId,
        companyId: resourceId,
      });

      if (cardIndex > -1) {
        commit('updateCard', {
          index: cardIndex,
          content: response.data,
        });
      }

      return response;
    },
    deleteFavoriteListWithoutCall({ commit, dispatch }, { listId, listType }) {
      dispatch('user/decrementMetadataByKey', mapListMetadatas[listType - 1]);
      dispatch('user/decrementMetadataByKey', 'lists');

      commit('removeCard', listId);
    },
    incrementElementListCount({ commit }, listId) {
      commit('incrementElementListCount', listId);
    },
    decrementElementListCount({ commit }, listId) {
      commit('decrementElementListCount', listId);
    },
  },
  mutations: {
    setLists(state, lists) {
      state.lists = lists;
    },
    addList(state, list) {
      state.lists.push(list);
    },
    editList(state, listObject) {
      const index = state.lists.findIndex(list => list._id === listObject._id);

      Vue.set(state.lists, index, listObject);
    },
    incrementElementListCount(state, listId) {
      const listIndex = state.lists.findIndex(list => list._id === listId);

      ++state.lists[listIndex].elementsCount;
    },
    decrementElementListCount(state, listId) {
      const listIndex = state.lists.findIndex(list => list._id === listId);

      --state.lists[listIndex].elementsCount;
    },
  },
};

