import notes from '@/common-old/macros/notes';

export const getTypeApiValueFromName = note => {
  return notes.type[note.type].api;
};

export const getMediaApiValueFromName = note => {
  return note.media.map(medium => notes.media[medium].api);
};

export const getDateToContact = note => {
  return note.dateToContact instanceof Date ? note.dateToContact : '';
};

export const serializeNote = note => {
  const serializeMap = {
    type: getTypeApiValueFromName,
    media: getMediaApiValueFromName,
    dateToContact: getDateToContact,
  };

  Object.keys(serializeMap).forEach(key => {
    if (note[key]) {
      note[key] = serializeMap[key](note);
    }
  });

  return note;
};

export default {
  getTypeApiValueFromName,
  getMediaApiValueFromName,
  serializeNote,
};
