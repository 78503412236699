export default {
  state: {
    inputValue: '',
    suggestions: []
  },
  actions: {
    setAutocompleteInputValue({ commit }, inputValue) {
      commit('setAutocompleteInputValue', inputValue);
    },
    setSuggestions({ commit }, suggestions) {
      commit('setSuggestions', suggestions);
    }
  },
  getters: {
    matches(state) {
      if (state.suggestions.length > 0) {
        return state.suggestions.filter(obj => obj.name.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0);
      } else {
        return [];
      }
    }
  },
  mutations: {
    setSuggestions(state, data) {
      state.suggestions = data;
    },
    setAutocompleteInputValue(state, data) {
      state.inputValue = data;
    }
  }
};
