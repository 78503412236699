<template>
  <div class="searchbar-filters-remote">
    <p class="searchbar-filters-remote__title">
      {{ $t('panel-coder-edit.remote') }}
    </p>
    <div class="searchbar-filters-remote__wrapper">
      <bc-checkbox
        class="searchbar-filters-remote__checkbox"
        id="available"
        :label="$t('panel-coder-edit.possible-remote')"
        v-model="available">
        {{ $t('panel-coder-edit.possible-remote') }}
      </bc-checkbox>
      <bc-checkbox
        class="searchbar-filters-remote__checkbox"
        id="partial"
        :label="$t('panel-coder-edit.partial-remote')"
        v-model="partial">
        {{ $t('panel-coder-edit.partial-remote') }}
      </bc-checkbox>
      <bc-checkbox
        class="searchbar-filters-remote__checkbox"
        id="full"
        :label="$t('panel-coder-edit.full-remote')"
        v-model="full">
        {{ $t('panel-coder-edit.full-remote') }}
      </bc-checkbox>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { apiRemote, getRemoteNameFromApiValue } from '@/common-old/macros/remote';
  import { buildRemoteTag } from '@/helpers/searchbar/factories';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';

  export default {
    name: 'searchbar-filters-remote',
    components: {
      BcCheckbox,
    },
    computed: {
      ...mapState({
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags,
      }),
      available: {
        get() {
          return this.searchbarFiltersTags.some(tag => tag.type === 'remote-available' && tag.query === getRemoteNameFromApiValue(apiRemote.available));
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildRemoteTag('available'));
          } else {
            const index = this.searchbarFiltersTags.findIndex(tag => tag.type === 'remote-available' && tag.query === getRemoteNameFromApiValue(apiRemote.available));

            if (index >= 0) {
              this.$emit('on-unselect', index);
            }
          }
        },
      },
      partial: {
        get() {
          return this.searchbarFiltersTags.some(tag => tag.type === 'remote-partial' && tag.query === getRemoteNameFromApiValue(apiRemote.partial));
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildRemoteTag('partial'));
          } else {
            const index = this.searchbarFiltersTags.findIndex(tag => tag.type === 'remote-partial' && tag.query === getRemoteNameFromApiValue(apiRemote.partial));

            if (index >= 0) {
              this.$emit('on-unselect', index);
            }
          }
        },
      },
      full: {
        get() {
          return this.searchbarFiltersTags.some(tag => tag.type === 'remote-full' && tag.query === getRemoteNameFromApiValue(apiRemote.full));
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildRemoteTag('full'));
          } else {
            const index = this.searchbarFiltersTags.findIndex(tag => tag.type === 'remote-full' && tag.query === getRemoteNameFromApiValue(apiRemote.full));

            if (index >= 0) {
              this.$emit('on-unselect', index);
            }
          }
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-filters-remote {
    flex-direction: column;
    padding: 20px 20px -20px;
    @include bp('tablet') {
      flex-direction: row;
    }

    &__title {
      margin-bottom: 20px;
      @include font-size(14px);
      color: $color-blue-dark-cello;
      line-height: 18px;
      @include bp('tablet') {
        width: 100%;
        max-width: 85px;
        margin-right: 35px;
        text-align: left;
        margin-bottom: 0;
      }
    }

    &__wrapper {
      position: relative;
      flex-wrap: wrap;
      margin: -5px -15px;

    }

    &__checkbox {
      margin: 5px 15px;
    }
  }
</style>
