<script setup lang="ts">
import { defineProps, withDefaults, onMounted, ref, computed, defineEmits, watch, watchEffect } from 'vue';
import EmailAttachmentList from '@/components/Email/EmailAttachmentList.vue';
import MvSelect from '@/ui-kit/components/MvSelect/MvSelect';

type CC = {
  key?: string;
  address: string;
  name: string;
}

type TemplatePreview = {
  body: string;
  subject: string;
  result: Record<string, { label: string; value: string }>,
}

interface Props {
  recipientEmail: string;
  recipientName: string;
  senderEmail: string;
  senderName: string;
  object: string;
  body: string;
  signature: string;
  attachments: { filename: string; path: string }[];
  files: File[];
  cc: CC[],
  templatePreview?: TemplatePreview;
  selectedCards?: { _id: string; firstName: string; lastName: string; email: string; label: string; value: string; }[];
}

const props = withDefaults(defineProps<Props>(), {
  recipientEmail: '',
  recipientName: '',
  senderEmail: '',
  senderName: '',
  object: '',
  body: '',
  signature: '',
  attachments: () => [],
  files: () => [] as File[],
  cc: () => [] as CC[],
  templatePreview: () => ({}) as TemplatePreview,
  selectedCards: () => [],
});

const emits = defineEmits<{
  (e: 'on-empty-variables', emptyVariablesList: TemplatePreview['result']): void,
  (e: 'on-select-card', card, index: number): void,
}>();

let emptyVariables: TemplatePreview['result'] = {};

const signatureContainer = ref();
const emptyVariablesList = ref<TemplatePreview['result']>({});
const emptyVariablesString = computed(() => {
  return Object.keys(emptyVariablesList.value).map(key => key).join(',');
});
const selectedCardId = ref('');

onMounted(() => {
  const shadow = signatureContainer.value.attachShadow({ mode: "open" });
  const div = document.createElement("div");
  div.innerHTML = props.signature;
  shadow.appendChild(div);
});

watch(emptyVariablesString, () => {
  emits('on-empty-variables', emptyVariablesList.value);
});

watchEffect(() => {
  if (!selectedCardId.value && props.selectedCards.length) {
    selectedCardId.value = props.selectedCards[0]?._id;
    emits('on-select-card', props.selectedCards[0]);
  }
});

const replaceVariables = (value: string, type: 'body' | 'object') => {
  let formatedValue = value;
  const result = props.templatePreview?.result;
  if (result) {
    Object.keys(result).forEach((key) => {
      const mKey = `${type}_${key}`

      if (result[key].value) {
        formatedValue = formatedValue.replaceAll(result[key].label, result[key].value);
      } else {
        // If variable still in string, add to emptyVariables list
        if (formatedValue.includes(result[key].label)) {
          emptyVariables = {
            ...emptyVariables,
            [mKey]: result[key],
          }
        } else if (emptyVariables[mKey]) {
          // Else remove from emptyVariables
          const empty = { ...emptyVariables };
          delete empty[mKey];
          emptyVariables = { ...empty };
        }
      }
    });
  }

  emptyVariablesList.value = emptyVariables;

  return formatedValue;
}

// computed

const objectPreview = computed(() => {
  return replaceVariables(props.object, 'object');
});

const bodyPreview = computed(() => {
  return replaceVariables(props.body, 'body');
});

const selectedCardLabel = computed(() => {
  const find = props.selectedCards.find(card => selectedCardId.value === card._id);
  return find?.label || '';
});

// methods

const onSelectedCard = (cardId: string, _onClick: () => void, index: number) => {
  selectedCardId.value = cardId;
  emits('on-select-card', props.selectedCards.find(card => cardId === card._id), index)
};

</script>

<template>
  <div class="is-column">
    <div class="w-full items-center">
      <p class="font-bold text-blue-800">Aperçu</p>
      <div class="ml-3 h-[1px] w-full bg-blue-200"></div>
    </div>
    <div class="is-column mt-3 w-full">
      <div class="is-column gap-[10px] rounded-t-md border border-blue-200 bg-neutral-100 px-7 py-5">
        <div class="gap-1 font-bold text-blue-800">
          {{ $t('generics.of-cap') }} : <span class="font-normal">{{ senderName }}</span><span class="font-normal text-blue-400">{{ '<' + senderEmail + '>' }}</span>
        </div>
        <div v-if="!selectedCards.length" class="gap-1 font-bold text-blue-800">
          {{ $t('generics.to-cap') }} : <span class="font-normal">{{ recipientName }}</span><span class="font-normal text-blue-400">{{ '<' + recipientEmail + '>' }}</span>
        </div>
        <div v-else class="items-center font-bold text-blue-800">
          <div class="w-10">{{ $t('generics.to-cap') }} :</div>
          <MvSelect
            :items="selectedCards"
            :value="selectedCardId"
            :isAbsolute="true"
            :selectedLabel="selectedCardLabel"
            @select="onSelectedCard"
          ></MvSelect>
        </div>
        <div v-if="cc.length" class="w-full gap-1">
          <div class="flex-0 font-bold text-blue-800">CC : </div>
          <div class="is-column gap-1">
            <div v-for="email of cc" :key="email.key" class="gap-1">
              <span class="font-normal">{{ email.name }}</span><span class="font-normal text-blue-400">{{ '<' + email.address + '>' }}</span>
            </div>
          </div>
        </div>
        <div v-if="objectPreview" class="gap-1 font-bold text-blue-800">
          {{ $t('generics.object') }} : <span class="font-normal">{{ objectPreview }}</span>
        </div>
      </div>
      <div class="email-preview__body rounded-b-md border border-t-0 border-blue-200 px-7 py-2">
        <div class="mv-text-editor-preview" v-html="bodyPreview"></div>
        <div ref="signatureContainer" class="mt-5"></div>
      </div>
      <div v-if="files.length || attachments.length">
        <EmailAttachmentList
          class="mt-3"
          :attachments="attachments"
          :files="files"
          :canRemove="false"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.email-preview {
  &__body {
    display: block;
    & div, p {
      display: block !important;
    }

    & > div:first-child {
      // GMAIL p styles
      p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }
    }
  }
}
</style>
