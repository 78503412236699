<template>
  <div class="card-chat">
    <card-chat-content
      :card-content="returnedObject"
      @click.meta.native.exact="openPanelInNewTab({ id: cardContent._id, type: 'chat' })"
      @click.native.exact="openPanel({ id: cardContent._id, type: 'chat' })">
    </card-chat-content>
  </div>
</template>

<script>
  // components
  import CardChatContent from '@/components/Card/CardChat/CardChatContent';
  // mixins
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  //
  import moment from 'moment/min/moment-with-locales';

  export default {
    name: 'card-chat',
    components: {
      CardChatContent
    },
    mixins: [PanelNavigation],
    props: {
      /**
       * The Object that contains the content of the response that will be displayed by the card
       */
      cardContent: Object,
      /**
       * The index of the card
       */
      cardIndex: Number
    },
    computed: {
      /**
       * The job map object for the cardInfo component
       * @returns {{pictureUrl: string, name: string, title: string, infos: computed.infosJob, tags: *}}
       */
      jobObject() {
        return {
          pictureUrl: this.cardContent && this.cardContent._company ? this.cardContent._company.logoUrl : '',
          name: this.cardContent && this.cardContent._company ? this.cardContent._company.name : '',
          seen: true,
          lastMessageAt: this.lastMessageAt,
          lastMessageText: this.lastMessageText
        };
      },
      coderObject() {
        return {
          pictureUrl: this.cardContent && this.cardContent._coder ? this.cardContent._coder.pictureUrl : '',
          name: this.cardContent && this.cardContent._coder ? this.cardContent._coder.firstName : '',
          seen: true,
          lastMessageAt: this.lastMessageAt,
          lastMessageText: this.lastMessageText
        };
      },
      returnedObject() {
        return this.$route.query.type === 'coders' ? this.jobObject : this.coderObject;
      },
      lastMessageText() {
        if (this.cardContent._lastMessage) {
          const text = {
            1: this.cardContent._lastMessage.text,
            3: 'Vous avez reçu une pièce jointe'
          };
          return text[this.cardContent._lastMessage.type];
        }
        return 'Pas de messages dans la conversation';
      },
      lastMessageAt() {
        if (this.cardContent._lastMessage) {
          return moment(this.cardContent._lastMessage.createdAt).fromNow()
        }
        return '';
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card-chat {
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 5px;
    @include bp('tablet') {
      flex-direction: row;
    }

    &:hover {
      cursor: pointer;
    }

    &__status {
      margin: 10px 0 0;
      width: 100%;
      @include bp('tablet') {
        margin: 0 0 0 10px;
        max-width: 180px;
      }
    }
  }

  .card-chat .card-informations {
    width: 100%;
  }

  .disabled {
    cursor: default;
  }
</style>
