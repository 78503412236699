<template>
  <div
    :class="{
      'process-card-job--pending' : isPending,
      'process-card-job--filtered' : isOld,
      'process-card-job--failed': isFailed,
      'process-card-job--warning': warningOnCard,
      'is-draggable process-card-job--draggable': !isFailed && !isDealt && isAuthorized && !isAborted && isDraggable
    }"
    class="process-card-job">
    <div
      class="process-card-job__mask"
      v-if="isPending">
    </div>
    <p class="process-card-job__name">
      {{ name }}
    </p>
    <process-custom-card-tags :process="process"></process-custom-card-tags>
    <div style="display: block;">
      <slot></slot>
    </div>
    <p class="process-card-job__date">
      {{ date }}
    </p>
    <div class="process-card-job__icon icon-dragdrop"></div>
    <bc-avatar
      v-if="process._coach"
      :alt="(process._coach || {}).firstName"
      :src="(process._coach || {}).pictureUrl"
      :placeholder="getCoachInitials()"
      v-tooltip="coachTooltip"
      bc-avatar-radius="100%"
      size="xs"
      class="process-card-job__avatar">
    </bc-avatar>
  </div>
</template>

<script>
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import ProcessCustomCardTags from '@/components/Process/ProcessCard/ProcessCustomCardTags';
  import processCard from '@/mixins/Processes/processCard';
  import { apiStatus } from "@/macros/processes/process";

  export default {
    name: 'process-card-job',
    components: {
      BcAvatar,
      ProcessCustomCardTags,
    },
    mixins: [processCard],
    props: {
      isDraggable: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      warningOnCard() {
        return !(this.process.formDeal || {}).sentAt &&
          this.process.status === apiStatus.dealt &&
          !this.process.failedAt;
      },
      coachTooltip() {
        return {
          content: `${this.process._coach?.firstName} ${this.process._coach?.lastName}`,
          classes: 'tooltip-pre',
        }
      },
    },
    methods: {
      getCoachInitials() {
        return `${(this.process._coach || {}).firstName?.charAt(0)}${(
          this.process._coach || {}
        ).lastName?.charAt(0)}`.trim();
      },
    }
  };
</script>

<style lang="scss" scoped>
  .process-card-job {
    flex-direction: column;
    background: $color-white;
    padding: 15px;
    border-radius: 10px;
    position: relative;
    align-items: flex-start;

    &--pending {
      border: 1px solid $color-blue-dodger;
    }

    &--filtered {
      border: 1px solid rgba($color-blue-dark-cello, 0.3);
      background: transparent;
    }

    &--warning {
      border: 1px solid $color-warning;
    }

    &--draggable {
      &:hover {
        .process-card-job__icon {
          opacity: 1;
        }
      }
    }

    &__mask {
      animation-name: pending;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }

    &__name {
      display: initial;
      font-size: $font-size-s;
      color: $color-blue-dark-cello;
      line-height: 17px;
    }

    &__date {
      color: rgba($color-blue-dark-cello, 0.5);
      font-size: $font-size-s;
      line-height: 17px;
    }

    &__avatar {
      position: absolute;
      right: 10px;
      top: 15px;
      overflow: hidden;
    }

    &__icon {
      transition: 0.3s;
      color: rgba($color-blue-dark-cello, 0.5);
      position: absolute;
      right: 10px;
      bottom: 15px;
      opacity: 0;
    }
  }

  @keyframes pending {
    0% {
      background: rgba($color-blue-dodger, 0.2);
    }
    50% {
      background: rgba($color-blue-dodger, 0.05);
    }
    100% {
      background: rgba($color-blue-dodger, 0.2);
    }
  }
</style>
