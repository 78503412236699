<template>
  <div>
    <div v-if="isUploading" class="container">
      <div class="progress-container">
        <p class="upload-status">{{ uploadingStatus }}</p>
        <div class="progress-bar">
          <div :style="{ width: `${totalProgression}%` }"></div>
        </div>
        <div class="filename-container">
          <img src="@/assets/logo/file-text.png" />
          <span class="file-name">{{ file.name }}</span>
          <span class="file-size">{{ fileSize }} Ko</span>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <div v-if="!isUploading" class="drag-area" :class="{ active: isActive }" @dragover.prevent="onDragOver"
        @dragleave.prevent="onDragLeave" @drop.prevent="onDrop($event)">
        <div class="header">
          <p>
            {{ $t('dashboard.drag-and-drop-or') }}
            <span @click="openUploadPannel">
              {{ $t('dashboard.select-files') }}
            </span>
          </p>
        </div>
        <p>{{ $t('dashboard.supported-files-formats') }} (limit: 50)</p>
        <input ref="fileInput" @change="handleFileUpload" type="file" multiple hidden>
      </div>
      <div v-if="files.length" class="flex flex-col px-4">
        <div class="flex flex-col gap-2 overflow-auto max-h-[300px]">
          <div v-for="(file, fileIndex) in files" :key="file.name"
            class="filename-container justify-between items-center">
            <div class="items-center w-full flex">
              <img src="@/assets/logo/file-text.png" />
              <span class="file-name ml-2" v-tooltip="file.name.length >= 30 ? file.name : ''">{{ file.name.length >= 30 ?
                file.name.substring(0, 30) + '...' : file.name }}</span>
              <span class="file-size">{{ Math.ceil(file.size / 1000) }} Ko</span>
            </div>
            <button @click="removeFile(fileIndex)">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L8.41421 7L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L7 8.41421L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                  fill="#9FAADD" />
              </svg>
            </button>
          </div>
        </div>
        <div class="flex mt-4 gap-4 justify-center">
          <bc-button class="flex-1" type="outlined" @click="cancelFilesUpload">{{ $t('generics.cancel-this-action')
          }}</bc-button>
          <bc-button class="flex-1" color="success" @click="uploadFiles" :is-loading="isLoading">{{
            $t('generics.create-candidates') }}</bc-button>
        </div>
      </div>
      <p v-if="errorMessage" class="error-msg">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Modals } from '@/store/modules/app';
import PanelNavigationMixin from '@/common-old/mixins/Panel/panelNavigation';
import coderHttp from '@/api/coders';
import BcButton from '@/ui-kit/components/BcButton';

const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx)$/i;

export default {
  name: 'coders-upload-cv-modal',
  mixins: [PanelNavigationMixin],
  components: {
    BcButton,
  },
  data() {
    return {
      isActive: false,
      isUploading: false,
      isLoading: false,
      uploadingProgression: 0,
      parsingEstimatedProgression: 0,
      closeIcon: "&#x2715",
      file: undefined,
      files: [],
      errorMessage: '',
    }
  },
  computed: {
    fileSize() {
      return Math.ceil(this.file.size / 1000);
    },
    profileId() {
      return this.$store.state.user.profile._id;
    },
    uploadingStatus() {
      let status = this.$i18n.t("dashboard.cv-recovery")
      if (this.uploadingProgression > 20 && this.uploadingProgression <= 40) {
        status = this.$i18n.t("dashboard.analyse")
      }
      if (this.uploadingProgression > 40 && this.uploadingProgression <= 60) {
        status = this.$i18n.t("dashboard.data-recovery")
      }
      if (this.uploadingProgression > 60) {
        status = this.$i18n.t("dashboard.candidate-creation")
      }
      return status;
    },
    totalProgression() {
      return this.uploadingProgression / 2 + this.parsingEstimatedProgression / 2
    }
  },
  watch: {
    uploadingProgression: {
      handler(progression) {
        if (progression === 100) {
          const intervalID = setInterval(() => this.parsingEstimatedProgression < 100 ? this.parsingEstimatedProgression++ : undefined, 500)
          setTimeout(() => clearInterval(intervalID), 30000)
        }
      }
    },
  },
  methods: {
    ...mapActions({
      getDeduplication: 'getDeduplication',
      setModal: 'app/setModal',
      setParams: 'app/setParams',
      getResumeHistories: 'resumeHistories/getResumeHistories',
    }),
    handleClose() {
      this.$emit('close-modal')
    },
    openUploadPannel() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(e) {
      const files = e.target.files;
      if (!this.checkMaxFiles(files)) return;

      if (files?.length > 1) {
        this.setFiles(files);
        return;
      }
      this.upload(files[0]);
    },
    onDragOver() {
      this.isActive = true;
    },
    onDragLeave() {
      this.isActive = false;
    },
    onDrop(e) {
      const files = e.dataTransfer.files;
      if (!this.checkMaxFiles(files)) return;

      if (files?.length > 1) {
        this.setFiles(files);
        return
      }
      this.upload(files[0]);
    },
    fileValidation(file) {
      return allowedExtensions.exec(file.name);
    },
    checkMaxFiles(files) {
      if (files?.length > 50) {
        this.$toast.show({
          type: 'error',
          message: 'limit to 50 files',
        });
        return false;
      }
      return true;
    },
    handleUploadProgression(progressEvent) {
      setTimeout(() => {
        this.uploadingProgression = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      }, 2000);
    },
    async upload(file) {
      if (!this.fileValidation(file)) {
        return this.errorMessage = this.$i18n.t("dashboard.incorrect-file-format");
      }

      this.errorMessage = '';
      this.file = file;
      this.isUploading = true;

      const formData = new FormData();
      formData.append('file', this.file);

      try {
        const { data: response } = await coderHttp.uploadCoderResume(formData, this.handleUploadProgression);
        const coderId = response.user_id;
        const { data: coder } = await coderHttp.getCoderProfile(coderId);

        const deduplication = await this.getDeduplication({
          firstName: coder.firstName,
          lastName: coder.lastName,
          email: coder.email,
          phone: coder.phone,
          linkedin: coder?.social?.linkedin,
          editedProfile: coder,
        });
        if (deduplication.length) {
          this.setModal(Modals['modal-coders-deduplication']);
        } else {
          this.onUploadSuccess(response);
        }
      } catch (err) {
        if (err?.response?.data?.message === 'Candidates limit achieved') {
          this.setParams({ feature: 'candidatesLimitUnlocked' });
          this.setModal(Modals['modal-feature-db-limit']);
        }

        this.reset();
        this.errorMessage = this.$i18n.t("dashboard.an-error-occurred-while-retrieving-the-document")
      }
    },
    setFiles(files) {
      this.files = Array.from(files);
    },
    async uploadFiles() {
      try {
        this.isLoading = true;

        const formData = new FormData();

        this.files.forEach((file, index) => {
          formData.append(`file[${index}]`, file);
        });

        await coderHttp.uploadMultipleCodersResume(formData);
        await this.getResumeHistories({ coachId: this.profileId });

        this.handleClose();

        this.isLoading = false;
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err?.message,
        });
        this.isLoading = false;
      }
    },
    removeFile(fileIndex) {
      this.files = this.files.filter((_, index) => index !== fileIndex);
    },
    cancelFilesUpload() {
      this.files = [];
      this.$refs.fileInput.value = null;
    },
    onUploadSuccess({ user_id, file_path }) {
      this.setModal('')
      this.openPanel({ id: user_id, type: 'coders', category: 'documents', 'selected-document': file_path })
    },
    reset() {
      this.isUploading = false;
      this.file = undefined;
      this.uploadingProgression = 0;
      this.parsingEstimatedProgression = 0;
    }
  },

}
</script>

<style lang=scss scoped>
.container {
  margin: 4%;
  color: $color-blue-dark-cello;
  display: block;
  width: 540px;
  padding: 10px;
}

.close-btn-container {
  display: block;
  display: flex;
  justify-content: flex-end;

  &>button {
    font-size: 25px;
    color: $color-blue-heavy-dark;
  }
}

.drag-area {
  border-radius: 4px;
  border: 1px dashed $color-blue-heavy-dark;
  background: $color-neutre-3;
  margin: 4%;
  padding: 5%;
  height: 192px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url('@/assets/logo/upload.png');
  background-repeat: no-repeat;
  background-position: top;
  background-position-y: 15%;
}

.progress-container {
  flex-direction: column;
  padding: 50px;
}

.drag-area.active {
  border: 2px solid $color-blue-dark-cello;
}

.drag-area .icon {
  font-size: 100px;
  color: #fff;
}

.drag-area .header {
  max-width: 75%;
  justify-content: center;
  margin-top: 17%;

  & p {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }
}

.drag-area span {
  color: $color-primary;
  text-decoration: underline;
}

.drag-area button {
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  background: #fff;
  color: #5256ad;
  border-radius: 5px;
  cursor: pointer;
}

.drag-area img {
  object-fit: contain;
  margin-bottom: 5%;
}

p {
  color: $color-blue-dark-cello;
  font-size: 12px;
  margin-top: 10px;
}

.header span:hover {
  cursor: pointer;
}

.upload-status {
  color: $color-tertiary;
  font-weight: 700;
  font-size: 16px;
}

.progress-bar {
  border-radius: 5px;
  background: #D9D9D9;
  width: 100%;
  height: 17px;
  margin: 5% 0%;

  &>div {
    border-radius: 5px;
    background: linear-gradient(90deg, $color-blue-dodger 0%, $color-purple-dark 54.05%);
    transition: width 5000ms;
  }
}

.filename-container {
  border: 1px solid #3D5476;
  border-radius: 4px;
  padding: 2% 4%;
  display: inline-flex;
  width: 100%;

  &>img {
    margin-right: 2%;
    width: 13px;
    height: 18.4px;
  }
}

.file-size {
  color: $color-blue-dark-cello;
  opacity: 0.5;
  margin-left: 5%;
  white-space: pre;
}

.file-name {
  word-break: break-word;
}

.error-msg {
  text-align: center;
  color: $color-red;
  font-weight: 700;
}
</style>
