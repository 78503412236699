<script lang="ts" setup>
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
import { usePanelNavigation } from '@/composables/usePanelNavigation';
import { useStore } from '@/store';
import { useRoute, useRouter } from 'vue-router/composables';
import BcModal from '@/ui-kit/components/BcModal/BcModal.vue';
import type { Component } from 'vue';
import { computed, defineProps, defineEmits, ref, withDefaults } from 'vue';
import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav.vue';
import PanelModalMergeProfile from '@/components/Panel/PanelModal/PanelModalMergeProfile.vue';
import PanelCoderModalDelete
from '@/components/Panel/PanelCoder/PanelCoderModal/PanelCoderModalDelete.vue';
import ProfileTypesSelection from '@/components/ProfileTypesSelection/ProfileTypesSelection.vue';
import { Candidate, ProfileType } from '@/domains/models/Candidate';
import { coderController } from '@/managers/coders/controller';
import { getCoderContracts } from '@/api/coders';

const store = useStore();
const route = useRoute();
const router = useRouter();
const { closePanel } = usePanelNavigation();
  const props = withDefaults(defineProps<{
    profile?: Candidate;
    isCreating?: boolean;
  }>(), {
    isCreating: false,
  });
  const emit = defineEmits<{
    (e: 'profile-updated', value: Candidate): void;
  }>();

  const profileTypes = computed(() => props.profile?.profileTypes);

  async function updateCoderProfileTypes(newProfileTypes: ProfileType[]) {
    if (!props.profile) return;

    const { data } = await coderController.editProfile({
        id: props.profile._id,
        data: {profileTypes: newProfileTypes},
      });
      emit('profile-updated', data);
  }

  const openFavoriteListsPanel = () => {
    if (!props.profile) return;
    let listType: string;
    const hasCandidate = props.profile.profileTypes?.includes('candidate');
    const hasContact = props.profile.profileTypes?.includes('contact');
    if (hasCandidate && hasContact) {
      listType = 'favorite-coder-and-contact-list';
    } else if (hasContact) {
      listType = 'favorite-contact-list'
    } else if (hasCandidate) {
      listType = 'favorite-coder-list'
    } else {
      return;
    }
    router.push({
      name: route.name,
      params: {
        id: props.profile._id,
      },
      query: {
        ...route.query,
        type: listType,
        subtype: 'add-resources',
      },
    });
  };

  // modal
  const modalsMap: {
    component: Component,
    key: string
  }[] = [
    {
      component: PanelCoderModalDelete,
      key: 'delete',
    },
    {
      component: PanelModalMergeProfile,
      key: 'merge',
    },
  ];
  const modalType = ref('');
  const setModalType = (type: string) => {
    modalType.value = type;
  };
  const closeModal = () => {
    modalType.value = '';
  };
  const modal = computed(() => {
    return modalsMap.find(modal => modal.key === modalType.value)?.component || null;
  });
  const isModalOpen = computed(() => !!modal.value);

  const confirmDeleteCoder = () => {
    if (props.profile?._id) {
      store.dispatch('deleteCoderProfile', props.profile._id);
    } else {
      store.dispatch('deleteCoderProfile', route.params.id); // legacy
    }

    closePanel();
  };
</script>

<template>
  <div
    class="flex min-h-[50px] w-full items-center justify-between border-b border-b-[#EDF0FF] bg-neutral-200 px-[18px] py-2">
    <div class="flex gap-[1.875rem]">
      <PanelHeaderNav />
      <ProfileTypesSelection
        v-if="profileTypes"
        :value="profileTypes"
        @input="updateCoderProfileTypes"
      />
    </div>
    <div
      v-if="!isCreating"
      class="flex gap-2.5">
      <BcButton
        icon-left="addtolist"
        size="extra-small"
        @click="openFavoriteListsPanel"
      >
        {{ $t('companies.panel-company.add-to-a-list') }}
      </BcButton>
      <bc-button
        color="primary"
        icon-left="copy"
        size="extra-small"
        @click.native="setModalType('merge')">
        {{ $t('generics.merge') }}
      </bc-button>
      <bc-button
        color="error"
        icon-left="trash"
        size="extra-small"
        @click.native="setModalType('delete')">
        {{ $t('generics.delete') }}
      </bc-button>
    </div>
    <bc-modal
      :active="isModalOpen"
      class="panel-coder-header-coach__modal"
      @close="closeModal">
      <component
        :is="modal"
        :profile="profile"
        @close-modal="closeModal"
        @confirm-delete="confirmDeleteCoder"
        @profile-updated="$emit('profile-updated', $event)">
      </component>
    </bc-modal>
  </div>
</template>
