import { useApi } from './useApi';
import { useLinkedinRelationsStore } from '@/store/pinia/linkedinRelations';

export function useLinkedinRelationsApi() {
  const { post, get, loading, requestError } = useApi();
  const linkedinRelationsStore = useLinkedinRelationsStore();

  async function syncRelationStatus(coderId: string) {
    try {
      if (!coderId) return;
      
      const { relation, publicId } = await post<{relation: {isRelation: boolean, updatedBinding: boolean, profile: boolean}, publicId: string}>(`/unipile/linkedin_relations/coders/${coderId}/sync`) || {};
      
      if (relation?.isRelation !== undefined && publicId) {
        linkedinRelationsStore.addOrRemoveRelation(publicId, relation.isRelation);
      }
    } catch (error) {
      console.error('Error refreshing LinkedIn relation:', error);
    }
  }

  async function getPendingInvitations() {
    try {
      const data = await get<{pendingInvitations: string[]}>(`/linkedin/pending_invitations`);
      if (data) {
        linkedinRelationsStore.addPendingInvitations(data.pendingInvitations);
      } else {
        linkedinRelationsStore.setPendingInvitations([]);
      }
    } catch (error) {
      console.error('Error refreshing LinkedIn relation:', error);
    }
  }

  async function fetchRelationStatus(coderId: string) {
    try {
      if (coderId) {
        const { relation, publicId } = await get<{relation: {isRelation: boolean, updatedBinding: boolean, profile: boolean}, publicId: string}>(`/unipile/linkedin_relations/coders/${coderId}`) || {};
        if (relation?.isRelation !== undefined && publicId) {
          linkedinRelationsStore.addOrRemoveRelation(publicId, relation.isRelation);
        }
      }
    } catch (error) {
      console.error('Error getting LinkedIn relation:', error);
    }
  }

  async function getRelations() {
    try {
      const relationsAndPendingInvitations = await get<{relations: string[], pendingInvitations: string[]}>('/unipile/linkedin_relations');
      if (relationsAndPendingInvitations) {
        linkedinRelationsStore.setRelations(relationsAndPendingInvitations.relations);
        linkedinRelationsStore.setPendingInvitations(relationsAndPendingInvitations.pendingInvitations);
      }
    } catch (error) {
      console.error('Error refreshing LinkedIn relation:', error);
    }
  }

  return {
    syncRelationStatus,
    fetchRelationStatus,
    getRelations,
    getPendingInvitations,
    loading,
    requestError,
  };
} 