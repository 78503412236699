<template>
  <div class="panel-process-details-job-validated-form">
    <p
      class="panel-process-details-job-validated-form__title icon-arrow-left panel-process-details-job-validated-form__title">
      {{ $t('candidates.deal-validate') }}
    </p>
    <div class="panel-process-details-job-validated-form__container">
      <div class="panel-process-details-job-validated-form__wrapper">
        <p class="panel-process-details-job-validated-form__label panel-process-details-job-validated-form__label-date">
          {{ $t('candidates.start-of-onboarding') }}

        </p>
        <v-date-picker
          :locale="locale"
          :popover="{ visibility: 'focus' }"
          class="panel-process-details-job-validated-form__date-picker"
          v-model="startsAt">
        </v-date-picker>
      </div>
      <div class="panel-process-details-job-validated-form__wrapper">
        <p class="panel-process-details-job-validated-form__label panel-process-details-job-validated-form__label-date">
          {{ $t('candidates.end-of-onboarding') }}

        </p>
        <v-date-picker
          :locale="locale"
          :popover="{ visibility: 'focus' }"
          class="panel-process-details-job-validated-form__date-picker"
          v-model="validatedAt">
        </v-date-picker>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment/min/moment-with-locales';
  import locale from '@/mixins/i18n/locale';

  export default {
    name: 'panel-process-details-job-validated-form',
    mixins: [locale],
    props: {
      processStartsAt: {
        type: String,
        default: () => new Date().toISOString(),
      },
      processValidatedAt: {
        type: String,
        default: () => new Date().toISOString(),
      },
      deal: {
        type: Object,
        default: () => ({
          fixed: 0,
          variable: 0,
          amount: 0,
          startsAt: new Date(),
        })
      }
    },
    computed: {
      startsAt: {
        get() {
          return this.$route.query['new-deal-starts-at'] ? moment(this.$route.query['new-deal-starts-at'], 'D').toDate() : moment(this.processStartsAt).toDate();
        },
        set(value) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              'new-deal-starts-at': moment(value).format('D')
            }
          });
        }
      },
      validatedAt: {
        get() {
          return this.$route.query['new-deal-validated-at'] ? moment(this.$route.query['new-deal-validated-at'], 'D').toDate() : moment(this.processValidatedAt).toDate();
        },
        set(value) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              'new-deal-validated-at': moment(value).format('D')
            }
          });
        }
      }
    }
  };
</script>

<style lang=scss scoped>
  .panel-process-details-job-validated-form {
    flex-direction: column;

    &__title {
      text-transform: uppercase;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      color: $color-deal;
      display: flex;
      align-items: center;
      margin-bottom: -5px;

      &:before {
        margin-right: 10px;
        font-size: $font-size-l;
        transform: rotate(180deg);
      }
    }

    &__container {
      margin-left: 28px;
      border-bottom: 1px solid rgba($color-secondary, 0.2);
      flex-direction: column;
      padding: 10px 0;

      &:last-of-type {
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    &__wrapper {
      align-items: center;
      margin: 2.5px 0;

      &:first-of-type {
        margin-top: 0
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__label {
      color: $color-secondary;
      font-size: $font-size-s;
      line-height: 17px;

      &-date {
        width: 80px;
      }
    }

    &__input {
      margin: 0 5px;
      max-width: 40px;
      font-size: $font-size-s;
      text-align: center;
      min-height: 30px;

      &--large {
        max-width: 80px;
      }
    }

    &__date-picker {
      color: $color-primary;
      max-width: 155px;
      margin-left: 10px;

      & :deep() {
        input {
          outline: none;
          box-shadow: none;
          border: 1px solid rgba($color-primary, 0.5);
          border-radius: 50px;
          text-align: center;
          color: $color-primary;
          font-size: $font-size-s;
          padding: 5px 10px;

          &:focus {
            border-color: $color-primary;
          }
        }
      }
    }
  }
</style>
