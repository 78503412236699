<script lang="ts" setup>
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete.vue';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
  import { ref } from 'vue';
  import { useQuery } from '@tanstack/vue-query';
  import { getFavoriteLists } from '@/api/favoriteLists';
  import { FavoriteList } from '@/domains/models/FavoriteList';
  import { AxiosResponse } from 'axios';
  import { useI18n } from '@/i18n/i18n';
  import { CampaignProspectSourceDraft } from '@/domains/models/Campaign';

  const emit = defineEmits<{
    (e: 'update-prospects', value: any): void;
  }>();

  const { t } = useI18n();

  const searchbarInput = ref<string>('');
  const { data: lists } = useQuery({
    queryKey: ['fetch-lists', searchbarInput],
    queryFn: async() => {
      const promises: Promise<AxiosResponse<FavoriteList[]>>[] = [
        getFavoriteLists({ query: searchbarInput.value ?? '', type: 1 }),
        getFavoriteLists({ query: searchbarInput.value ?? '', type: 2 }),
        getFavoriteLists({ query: searchbarInput.value ?? '', type: 3 }),
      ];

      const responses = await Promise.all(promises);

      return responses.reduce((acc, response) => acc.concat(response.data), []);
    },
  });

  function getFormattedType(type: number): { name: string, icon: string } {
    switch (type) {
      case 1:
        return {
          name: t('generics.candidates'),
          icon: '',
        };
      case 2:
        return {
          name: 'Contacts',
          icon: '',
        };
      case 3:
        return {
          name: t('generics.companies'),
          icon: '',
        };
      default:
        return {
          name: '',
          icon: '',
        };
    }
  }

  function handleSelect(option: FavoriteList) {
    const emptyProspectSource: CampaignProspectSourceDraft & Pick<FavoriteList, 'elementsCount'> = {
      type: 'CODER_LIST',
      name: option.name,
      _list: option,
      elementsCount: option.elementsCount,
    };
    emit('update-prospects', emptyProspectSource);
  }
</script>

<template>
  <div class="flex flex-col gap-[20px]">
    <div class="flex items-center gap-1">
      <i class="icon-list"/>
      <h3 class="text-xl font-bold first-letter:capitalize">
        {{ $t('campaigns.prospects.from-my-lists') }}
      </h3>
    </div>
    <BcAutocomplete
      v-model="searchbarInput"
      :is-dropdown-full-width="true"
      :keep-open="true"
      :label="$t('global.search-a-list')"
      :open-on-focus="true"
      :options="lists"
      class="min-w-[200px] max-w-[600px]"
      name="type"
      placeholder="Exemple: Nom de la liste"
      v-bind="$attrs"
      v-on="$listeners">
      <template #option="{ option }">
        <bc-dropdown-item
          class="option w-full"
          @click.native="handleSelect(option)">
          <div class="flex w-full grow justify-between">
            <div class="flex items-center gap-2.5">
              <span>{{ option.name }}</span>
              <span class="rounded-xl bg-blue-200 px-1.5 py-[3px] text-blue-900">
                {{ option.elementsCount }}
              </span>
            </div>
            <span class="ml-auto rounded-xl bg-blue-100 px-2.5 py-1 text-xs text-blue-800">
              {{ getFormattedType(option.type).name }}
            </span>
          </div>
        </bc-dropdown-item>
      </template>
    </BcAutocomplete>
  </div>
</template>

<style lang="scss" scoped>
  .option {
    &:deep() {
      .bc-dropdown-item__text {
        @apply w-full
      }
    }
  }
</style>
