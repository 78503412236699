<template>
  <div class="panel-profile-item" :class="{ 'panel-profile-item--border': withBorder }">
    <div class="panel-profile-item__header">
      <i :class="`icon-${icon}`"></i>
      <h3 :style="titleStyle">{{ formatTitle }}</h3>
      <div>
        <slot name="header"></slot>
      </div>
    </div>
    <slot class="panel-profile-item__content" name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'panel-profile-item',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
    },
    titleStyle: {
      type: String,
    },
    withBorder: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formatTitle() {
      let title = this.title;
      if (this.unit) {
        title += ` (${this.unit})`;
      }
      return title;
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-profile-item {
  width: 100%;
  border-radius: 5px;
  padding: 10px 35px;
  flex-direction: column;
  min-height: 60px;
  justify-content: center;

  &--border {
    border: 1px solid $color-blue-medium;
    background: $color-neutre-4;
  }

  &__header {
    width: 100%;
    gap: 20px;
    align-items: center;
    padding: 5px 0px;

    & > div {
      flex: 1;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 5px;
    }

    & > h3 {
      font-weight: $font-weight-regular;
      font-size: $font-size-m;
      line-height: 19px;
      color: $color-blue-dark-cello;
    }
  }

  &__content {
    margin-top: 10px;
    flex-direction: column;
  }
}
</style>
