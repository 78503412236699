<template>
  <div class="jobboard-config-item">
    <h3 class="jobboard-config-item__title">
      {{ $t('jobboards.config-jobboard') }}
    </h3>
    <div class="jobboard-config-item__content">
      <p class="jobboard-config-item__label">
        {{ $t('jobboards.name') }}
      </p>
      <div class="jobboard-config-item__details">
        <JobboardLogo :logoUrl="channel.logoUrl" :connectorName="channel.connectorName" />
        <p>{{ channel.title }}</p>
      </div>
    </div>
    <div v-if="!hideSubscriptions" class="jobboard-config-item__content">
      <p class="jobboard-config-item__label">Modèle de souscription</p>
      <div class="jobboard-config-item__radios">
        <bc-radio
          :checked="subscriptionModel === subscriptionsModels.costPerClick"
          :id="subscriptionsModels.costPerClick"
          :name="subscriptionsModels.costPerClick"
          :disabled="disabledCostPerClick"
          v-tooltip.bottom="{
            content: disabledCostPerClick ? $t('jobboards.unavailable-option') : undefined,
            classes: 'jobboard-config-item-tooltip'
          }"
          @input="updateSubscriptionModel(subscriptionsModels.costPerClick)">
          {{ $t('generics.cost-per-click') }}
        </bc-radio>
        <bc-radio
          :checked="subscriptionModel === subscriptionsModels.organic"
          :id="subscriptionsModels.organic"
          :name="subscriptionsModels.organic"
          :disabled="disabledOrganic"
          v-tooltip.bottom="{
            content: disabledOrganic ? $t('jobboards.unavailable-option') : undefined,
            classes: 'jobboard-config-item-tooltip'
          }"
          @input="updateSubscriptionModel(subscriptionsModels.organic)">
          {{ $t('generics.organic') }}
        </bc-radio>
        <bc-radio
          :checked="subscriptionModel === subscriptionsModels.credits"
          :id="subscriptionsModels.credits"
          :name="subscriptionsModels.credits"
          :disabled="disabledCredits"
          v-tooltip.bottom="{
            content: disabledCredits ? $t('jobboards.unavailable-option') : undefined,
            classes: 'jobboard-config-item-tooltip'
          }"
          @input="updateSubscriptionModel(subscriptionsModels.credits)">
          {{ $t('generics.credits') }}
        </bc-radio>
        <bc-radio
          :checked="subscriptionModel === subscriptionsModels.sponsored"
          :id="subscriptionsModels.sponsored"
          :name="subscriptionsModels.sponsored"
          :disabled="disabledSponsored"
          v-tooltip.bottom="{
            content: disabledSponsored ? $t('jobboards.unavailable-option') : undefined,
            classes: 'jobboard-config-item-tooltip'
          }"
          @input="updateSubscriptionModel(subscriptionsModels.sponsored)">
          {{ $t('generics.sponsored') }}
        </bc-radio>
        <bc-radio
          v-if="hasMixed"
          :checked="subscriptionModel === 'mixed'"
          :id="'mixed'"
          :name="'mixed'"
          v-tooltip.bottom="{
            content: disabledSponsored ? $t('jobboards.unavailable-option') : undefined,
            classes: 'jobboard-config-item-tooltip'
          }"
          @input="updateSubscriptionModel('mixed')">
          Mixed
        </bc-radio>
      </div>
    </div>
    <div v-if="$slots.subscriptionContent">
      <slot name="subscriptionContent"></slot>
    </div>
    <div class="jobboard-config-item__content">
      <p class="jobboard-config-item__label">Mode de réception des candidatures</p>
      <div class="jobboard-config-item__radios">
        <div>
          <bc-radio
            :checked="applicationMode === applicationModes.email"
            :id="applicationModes.email"
            :name="applicationModes.email"
            :disabled="disabledEmail"
            v-tooltip.bottom="{
              content: disabledEmail ? $t('jobboards.unavailable-option') : undefined,
              classes: 'jobboard-config-item-tooltip'
            }"
            @input="updateApplicationMode(applicationModes.email)">
            {{ $t('generics.by-email') }}
          </bc-radio>
          <WarningCercle class="ml-2" content="Toutes les candidatures reçues par mail devront être créées à la main par vos propres soins" />
        </div>
        <bc-radio
          :checked="applicationMode === applicationModes.career"
          :id="applicationModes.career"
          :name="applicationModes.career"
          :disabled="disabledCareer"
          v-tooltip.bottom="{
            content: disabledCareer ? $t('jobboards.unavailable-option') : undefined,
            classes: 'jobboard-config-item-tooltip'
          }"
          @input="updateApplicationMode(applicationModes.career)">
          {{ $t('generics.via-my-career-site') }}
        </bc-radio>
        <bc-radio
          v-if="!disabledDirect || disabledEmail"
          :checked="applicationMode === applicationModes.direct"
          :id="applicationModes.direct"
          :name="applicationModes.direct"
          :disabled="disabledDirect"
          v-tooltip.bottom="{
            content: disabledDirect ? $t('jobboards.unavailable-option') : undefined,
            classes: 'jobboard-config-item-tooltip'
          }"
          @input="updateApplicationMode(applicationModes.direct)">
          {{ $t('generics.directly-in-the-app') }}
        </bc-radio>
        <!-- <bc-radio
          v-else
          :checked="applicationForwardingType === 'Webhook'"
          :id="applicationModes.direct"
          :name="applicationModes.direct"
          v-tooltip.bottom="{
            classes: 'jobboard-config-item-tooltip'
          }"
          @input="updateApplicationForwardingType('Webhook')">
          {{ $t('generics.directly-in-the-app') }}
        </bc-radio> -->

        <bc-checkbox
          v-else
          :id="applicationModes.direct"
          :value="applicationForwardingType === 'Webhook'"
          :disabled="applicationMode === applicationModes.career"
          @input="updateApplicationForwardingType('Webhook')">
          {{ $t('generics.directly-in-the-app') }}
        </bc-checkbox>
      </div>
    </div>
    <div v-if="$slots.applicationMode">
      <slot name="applicationMode"></slot>
    </div>
    <div class="jobboard-config-item__buttons">
      <bc-button
        type="outlined"
        class="jobboard-config-item__btn"
        @click="$emit('cancel', $event)"
      >
        Non, annuler cette action
      </bc-button>
      <bc-button color="success" class="jobboard-config-item__btn" @click="onSubmit" :disabled="!isValid">
        Valider les informations
      </bc-button>
    </div>
  </div>
</template>

<script>
import { subscriptionsModels, applicationModes } from '@/api/xtramile';
import BcRadio from '@/ui-kit/components/BcRadio/BcRadio';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
import WarningCercle from '@/components/WarningCercle/WarningCercle';
import JobboardLogo from '@/components/Jobboard/JobboardLogo';

export default {
  name: 'jobboard-config-item',
  components: {
    BcRadio,
    BcButton,
    BcCheckbox,
    WarningCercle,
    JobboardLogo,
  },
  props: {
    channel: {
      type: Object,
      default: () => ({}),
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    subscriptionModel: {
      type: String,
    },
    applicationMode: {
      type: String,
    },
    applicationForwardingType: {
      type: String,
    },
    disabledSubscriptions: {
      type: Array,
      default: () => [],
    },
    disabledApplications: {
      type: Array,
      default: () => [],
    },
    hasMixed: {
      type: Boolean,
      default: false,
    },
    hideSubscriptions: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      subscriptionsModels,
      applicationModes,
    }
  },
  computed: {
    disabledOrganic() {
      return this.disabledSubscriptions.includes(this.subscriptionsModels.organic)
    },
    disabledCostPerClick() {
      return this.disabledSubscriptions.includes(this.subscriptionsModels.costPerClick)
    },
    disabledCredits() {
      return this.disabledSubscriptions.includes(this.subscriptionsModels.credits)
    },
    disabledSponsored() {
      return this.disabledSubscriptions.includes(this.subscriptionsModels.sponsored)
    },
    disabledEmail() {
      return this.disabledApplications.includes(this.applicationModes.email)
    },
    disabledCareer() {
      return this.disabledApplications.includes(this.applicationModes.career)
    },
    disabledDirect() {
      return this.disabledApplications.includes(this.applicationModes.direct)
    }
  },
  methods: {
    updateSubscriptionModel(key) {
      this.$emit('update-subscription-model', key);
    },
    updateApplicationMode(key) {
      if (key === applicationModes.career) {
        this.$emit('update-application-forwarding-type', 'JobContactEmail');
      }
      this.$emit('update-application-mode', key);
    },
    updateApplicationForwardingType(value) {
      this.$emit('update-application-forwarding-type', this.applicationForwardingType === 'Webhook' ? 'JobContactEmail' : value);
    },
    onSubmit(e) {
      if (!this.isValid) return
      this.$emit('submit', e);
    }
  }
}
</script>

<style lang="scss" scoped>
.jobboard-config-item {
  flex-direction: column;
  min-width: 650px;
  padding: 50px;

  &__title {
    color: $color-tertiary;
    font-size: 24px;
    font-weight: 700;
  }

  &__details {
    border-radius: 5px;
    border: 1px solid $color-blue-medium;
    background: $color-neutre-3;
    align-items: center;
    gap: 10px;
    padding: 7px 20px;

    & p {
      color: $color-blue-heavy-dark;
    }
  }

  &__content {
    flex-direction: column;
    margin-top: 20px;
  }

  &__radios {
    gap: 20px;
  }

  &__buttons {
    gap: 20px;
    margin-top: 30px;
    justify-content: center;
  }

  &__btn {
    width: 100%;
  }

  &__label {
    color: $color-blue-heavy-dark;
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
    margin-bottom: 10px;
  }
}
</style>

<style lang="scss">
  .jobboard-config-item-tooltip[x-placement^="bottom"] {
    & .tooltip-arrow {
      display: none;
    }
  }
</style>
