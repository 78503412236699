<template>
  <div
    id="app"
    ref="marvin__app"
    :class="{ 'pb-[110px]': hasPadding }"
  >
    <div style="flex-direction: column">
      <AppBar v-if="!isOnAuth"></AppBar>
      <div
        :class="{ 'app__container--auth': isOnAuth, 'app__container--fixed': isHoverSmallWins }"
        class="app__container"
      >
        <Topbar
          v-if="!isOnAuth"
          :is-notifications-open="isNotificationsOpen"
        ></Topbar>
        <NotificationsList v-if="isNotificationsOpen"></NotificationsList>
        <router-view></router-view>
        <TransitionFade>
          <TokenModal v-if="isTokenExpired"></TokenModal>
        </TransitionFade>
        <TransitionFade>
          <div
            v-if="isRequestProcessing"
            class="app__busy is-fixed is-top is-left is-justify-content-center is-align-items-center"
          >
            <BcSpinner size="large"></BcSpinner>
          </div>
        </TransitionFade>
      </div>
    </div>
    <!-- <div class="app__background"></div> -->
    <PortalTarget name="mv-select" />
    <PortalTarget name="modal" />
    <BcModal
      :active="modal !== ''"
      :is-panel="isPanel"
      @close="closeModal"
    >
      <CodersUploadCvModal
        v-if="modal === 'uploadCodersResume'"
        @close-modal="closeModal"
      >
      </CodersUploadCvModal>
      <component
        :is="modal"
        v-else
        @close-modal="closeModal"
      />
    </BcModal>
    <ResumeUploadContainer
      v-if="hasActiveResumeUpload"
      :hasActiveMessages="hasActiveMessages"
    />
    <TasksResumeParsing
      v-if="hasActiveParsingResume"
    />
    <MessagingPendingContainer v-if="hasActiveMessages" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { onMessage } from 'firebase/messaging';
import { messaging, setCoachNotificationsToken } from '../firebase';
import * as Sentry from '@sentry/vue';

// import eventDispatcher from '@/managers/notifManager/eventDispatcher';
import TransitionFade from '@/components/UIKit/Transition/TransitionFade';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import TokenModal from '@/components/Token/TokenModal';
import Topbar from '@/components/Topbar/Topbar';
import BcModal from '@/ui-kit/components/BcModal';
import '@/styles/vue-video-player-style.scss';
import CodersUploadCvModal from '@/components/Coders/CodersUploadCvModal';
import ModalFeatureDropCv from '@/components/Modal/ModalFeature/ModalFeatureDropCv';
import ModalFeatureCareer from '@/components/Modal/ModalFeature/ModalFeatureCareer';
import ModalFeatureShareProcesses
  from '@/components/Modal/ModalFeature/ModalFeatureShareProcesses';
import ModalFeatureEditProcessesB2c
  from '@/components/Modal/ModalFeature/ModalFeatureEditProcessesB2c';
import ModalFeatureAddTeamMember from '@/components/Modal/ModalFeature/ModalFeatureAddTeamMember';
import ModalFeatureCustomFields from '@/components/Modal/ModalFeature/ModalFeatureCustomFields';
import ModalFeatureCustomNotes from '@/components/Modal/ModalFeature/ModalFeatureCustomNotes';
import ModalFeatureLastActivity from '@/components/Modal/ModalFeature/ModalFeatureLastActivity';
import ModalFeatureMultipost from '@/components/Modal/ModalFeature/ModalFeatureMultipost';
import ModalFeatureDbLimit from '@/components/Modal/ModalFeature/ModalFeatureDbLimit';
import ModalCodersDeduplication from '@/components/Modal/ModalCoders/ModalCodersDeduplication';
import ModalEmailSendForm from '@/components/Modal/ModalEmail/ModalEmailSendForm';
import ModalLinkedinSendForm from '@/components/Modal/ModalLinkedin/ModalLinkedinSendForm';
import ModalEmailTemplateForm from '@/components/Modal/ModalEmail/ModalEmailTemplateForm';
import ModalTemplatesNote
  from '@/components/Modal/ModalTemplates/ModalTemplatesNote/ModalTemplatesNote.vue';
import ModalTemplatesLinkedin
  from '@/components/Modal/ModalTemplates/ModalTemplatesLinkedin/ModalTemplatesLinkedin.vue';
import NotificationsList from '@/components/Notifications/NotificationsList';
import AppBar from '@/components/App/AppBar';
import { Modals } from '@/store/modules/app';
import ResumeUploadContainer from '@/components/Resume/ResumeUploadContainer';
import MessagingPendingContainer from '@/components/Messaging/MessagingPendingContainer';
import { useLinkedinRelationsApi } from '@/composables/api/useLinkedinRelationsApi';
import { useUnipileAccountsStore } from '@/store/pinia/unipileAccounts';
import { useCampaignsStore } from '@/store/pinia/campaigns';
import Intercom, { update, shutdown, hide } from '@intercom/messenger-js-sdk';
import { Crisp } from "crisp-sdk-web";
import { mapStores } from 'pinia';
import { useLaunchDarklyStore } from '@/store/pinia/launchDarkly';
import TasksResumeParsing from './components/Tasks/TasksResumeParsing.vue';
import { useParsingResumeHistoryStore } from './store/pinia/parsingHistories';

export default {
  name: 'app',
  components: {
    Topbar,
    TokenModal,
    BcSpinner,
    TransitionFade,
    BcModal,
    CodersUploadCvModal,
    ModalFeatureDropCv,
    ModalFeatureCareer,
    ModalFeatureShareProcesses,
    ModalFeatureEditProcessesB2c,
    ModalFeatureAddTeamMember,
    ModalFeatureCustomFields,
    ModalFeatureCustomNotes,
    ModalFeatureLastActivity,
    ModalFeatureMultipost,
    ModalFeatureDbLimit,
    ModalCodersDeduplication,
    NotificationsList,
    ModalEmailSendForm,
    ModalEmailTemplateForm,
    ModalTemplatesNote,
    ModalLinkedinSendForm,
    ModalTemplatesLinkedin,
    AppBar,
    ResumeUploadContainer,
    MessagingPendingContainer,
    TasksResumeParsing,
  },
  metaInfo: {
    title: 'Marvin Recruiter',
  },
  setup() {
    const unipileAccountsStore = useUnipileAccountsStore();
    const campaignsStore = useCampaignsStore();
    const linkedinRelationsApi = useLinkedinRelationsApi();
    return {
      unipileAccountsStore,
      campaignsStore,
      linkedinRelationsApi,
    };
  },
  data: () => ({
    unsubNotifs: undefined,
    unsubResumeHistories: undefined,
    unsubMessageHistories: undefined,
    unsubOnMessage: undefined,
  }),
  computed: {
    ...mapState('app', ['modal', 'isHoverSmallWins', 'isPanel']),
    ...mapStores(useLaunchDarklyStore, useParsingResumeHistoryStore),
    ...mapGetters({
      configurationDb: 'user/configurationDb',
      hasActiveResumeUpload: 'resumeHistories/hasActiveResumeUpload',
      hasActiveTasks: 'resumeHistories/hasActiveTasks',
      hasActiveMessages: 'messageHistories/hasActiveMessages',
    }),
    hasActiveParsingResume() {
      return this.parsingResumeHistoriesStore.hasActiveParsing;
    },
    hasPadding() {
      return this.hasActiveMessages || this.hasActiveResumeUpload || this.selectedCards.length;
    },
    selectedCards() {
      return this.$store.state.card.selectedCards;
    },
    hasPaid() {
      return this.$store.state.user.profile?._organization?.subscription?.type === 2;
    },
    isOnAuth() {
      return this.$route?.name?.includes('Auth');
    },
    isRequestProcessing() {
      return this.$store.state.card.isRequestProcessing;
    },
    isTokenExpired() {
      return this.$store.state.token.isExpired;
    },
    isSuperUser() {
      return this.$store.getters['user/isSuperUser'];
    },
    isMarvinRecruiter() {
      return this.$store.getters['user/isMarvinRecruiter'];
    },
    isAcademy() {
      return this.$store.getters['user/isAcademy'];
    },
    profile() {
      return this.$store.state.user.profile;
    },
    loading() {
      return this.$store.state.user.loading;
    },
    isNotificationsOpen() {
      return this.$store.state.mainNotifications.isOpen;
    },
  },
  watch: {
    $route(to) {
      if (!to.params.id) {
        document.title = 'Marvin Recruiter';
      }
    },
    '$route.query': {
      handler(query) {
        if (query.modal && query.feature) {
          this.setParams({ feature: query.feature });
          this.setModal(Modals[query.modal]);
        }
      },
      immediate: true,
    },
    profile: {
      async handler(to, from) {
        if (to?._id !== from?._id && to?._id) {
          await this.initializeApp();
        }

        if (!to?._id) {
          shutdown();
          hide();
        } else {
          this.setIntercom(true);
        }
      },
      immediate: true,
      deep: true,
    },
    loading: {
      async handler() {
        this.$router.go();
      },
      deep: true,
    },
    'launchDarklyStore.isReady': {
      handler(isReady) {
        if (!isReady) return;

        const enableCrisp = this.launchDarklyStore.getFlag('enableCrisp');

        if (enableCrisp) {
          Crisp.configure('c64d3735-1090-44a8-91cc-0da81686a170');

          this.$nextTick(() => {
            const chatButton = document.querySelector('.lc-1s5l46q');
            const chatDiv = document.querySelector('.lc-rz39p8');

            if (chatDiv) {
              chatDiv.style.width = '40px';
              chatDiv.style.height = '40px';
            }

            if (chatButton) {
              chatButton.style.width = '40px';
              chatButton.style.height = '40px';
            }
          });
        } else {
          this.setIntercom();
        }
      },
      immediate: true
    },
  },
  async created() {
    try {
      if (localStorage.getItem('Token')) {
        this.handleWindowWidth();
        window.addEventListener('resize', this.handleWindowWidth);
        this.$on('hook:destroyed', () => {
          window.removeEventListener('resize', this.handleWindowWidth);
        });

        await this.getProfile();

        // Set Crisp name and email address
        if (window.$crisp) {
          window.$crisp.push(['set', 'user:email', [this.profile.email]]);
          window.$crisp.push([
            'set',
            'user:nickname',
            [`${this.profile.firstName} ${this.profile.lastName}`],
          ]);
        }
      }
    } catch (error) {
      this.$toast.show({
        type: 'error',
        message: this.$t('toast.error-occured'),
      });
    }
  },
  beforeDestroy() {
    if (this.unsubNotifs) {
      this.unsubNotifs();
    }
    if (this.unsubResumeHistories) {
      this.unsubResumeHistories();
    }
    if (this.unsubOnMessage) {
      this.unsubOnMessage();
    }
  },
  async mounted() {
    // this.sockets.subscribe('notification', eventDispatcher.dispatcher);

    if (localStorage.getItem('Token')) {
      if (this.$refs['marvin__app']) {
        this.$refs['marvin__app'].setAttribute('profile-id', this.profile._id);
      }
    }

    this.$nextTick(() => {
      this.redirectToHomePage();
    });

    if (messaging) {
      this.unsubOnMessage = onMessage(messaging, () => {
        this.$toast.show({
          type: 'notification',
          icon: 'bell',
          title: this.$t('notifications.new') + ' 🚨',
          // message: payload.notification.body,
          position: 'top-right',
          duration: 4000,
        });
      });
    }
  },
  methods: {
    ...mapActions({
      getProfile: 'user/getProfile',
      getCoaches: 'getCoaches',
      setCoachFavoriteLists: 'setCoachFavoriteLists',
      setIsAppReady: 'app/setIsLoading',
      setModal: 'app/setModal',
      setParams: 'app/setParams',
      setWindowWidth: 'app/setWindowWidth',
      listenCoachNotification: 'mainNotifications/listenCoachNotification',
      getCoachNotifications: 'mainNotifications/getCoachNotifications',
      resetLastVisibles: 'mainNotifications/resetLastVisibles',
      listenResumeHistories: 'resumeHistories/listenResumeHistories',
      listenMessageHistories: 'messageHistories/listenMessageHistories',
      getResumeHistories: 'resumeHistories/getResumeHistories',
      getMessageHistories: 'messageHistories/getMessageHistories',
      getOrganizationRessourceCount: 'metadata/getOrganizationRessourceCount',
      getCoachRessourceCount: 'metadata/getCoachRessourceCount',
    }),
    redirectToHomePage() {
      if (this.$route?.name === 'Onboarding' && this.profile?._id && this.hasPaid) {
        this.$router.replace({
          path: '/pipe/hunt',
          query: {
            coachId: this.profile._id,
            'selected-pipe': 'personal-pipe',
            'job-active': 'true',
          },
        });
      }
    },
    handleWindowWidth() {
      this.setWindowWidth(window.innerWidth);
    },
    setIntercom(shouldUpdate = false) {
      const data = {
        app_id: 'yt9mprpp',
        user_id: this.profile._id,
        name: `${this.profile.firstName} ${this.profile.lastName}`,
        email: this.profile.email,
        created_at: this.profile.createdAt,
        account_name: this.profile?._organization?.name,
        phone: this.profile?.mobile?.toString() || this.profile?.phone?.toString(),
        title: this.profile.job,
        company: {
          company_id: this.profile?._organization?._id,
          created_at: this.profile?._organization?.createdAt,
          name: this.profile?._organization?.name,
        }
      }

      if (shouldUpdate) {
        update(data);
      } else {
        this.$nextTick(() => {
          Intercom(data);
        })
      }
    },
    async initializeApp() {
      if (localStorage.getItem('Token')) {
        const coachId = this.$store.state.user.profile._id;
        const coachProfile = this.$store.state.user.profile

        // sentry init
        if (coachProfile) {
          Sentry.setContext('coach', {
            id: coachProfile._id,
            email: coachProfile.email,
            firstName: coachProfile.firstName,
            lastName: coachProfile.lastName,
            role: coachProfile.role,
          });

          Sentry.setContext('organization', {
            id: coachProfile._organization._id,
            name: coachProfile._organization.name,
          })

        }

        this.resetLastVisibles(); // reset notifications lastVisible values

        await setCoachNotificationsToken({ coachId });
        await this.getCoachNotifications({
          coachId,
          filter: ['candidates', 'withoutCandidates'],
        });


        this.unsubNotifs = this.listenCoachNotification({ coachId });
        this.unsubResumeHistories = this.listenResumeHistories({ coachId });
        this.unsubMessageHistories = this.listenMessageHistories({ coachId });
        this.unsubParsingResumeHistories = this.parsingResumeHistoriesStore?.listenParsingResumeHistory(coachId);

        this.getResumeHistories({ coachId });
        this.getMessageHistories({ coachId });
        this.parsingResumeHistoriesStore?.getParsingResumeHistory(coachId);

        this.getOrganizationRessourceCount({
          organizationId: this.profile._organization._id,
          candidate: true,
          customJob: true,
          company: true,
          contact: true,
          applications: true,
        });

        this.getCoachRessourceCount({
          coachId: this.profile._id,
        });

        await this.getCoaches();
        await this.setCoachFavoriteLists(coachId);
        await this.unipileAccountsStore.getAccounts();
        await this.campaignsStore.getCampaignsConfiguration();
        if (this.unipileAccountsStore.linkedInAccount) {
          await this.linkedinRelationsApi.getRelations();
        }

        this.setIsAppReady(false);

        if (this.isSuperUser) {
          if (this.$hj) {
            this.$hj('event', 'is_superuser');
          }
        }

        if (this.$hj) {
          this.$hj('identify', coachId, {
            email: this.$store.state.user.profile.email,
          });
        }
        this.redirectToHomePage();

      }
    },
    closeModal() {
      this.setModal('');
      if (this.$route.query.modal && this.$route.query.feature) {
        this.$router.replace({
          ...this.$route,
          query: {
            ...this.$route.query,
            modal: undefined,
            feature: undefined,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import './ui-kit/assets/scss/reset';
@import './ui-kit/assets/scss/grid';
@import './ui-kit/assets/scss/elements/module';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

:root {
  --app-bar-height: 73px;
}

body {
  width: 100vw;
  color: $color-tertiary;
  overscroll-behavior: none;

  & * {
    line-height: 1.15;
  }
}

#app {
  width: 100%;
  min-height: 100vh;
  margin: auto;
  flex-direction: column;
}

.app {
  &__spinner {
    width: 100%;
    height: 100%;
    padding: 30px;
  }

  &__container {
    padding-left: 85px;
    width: 100%;
    min-height: 100vh;
    margin: auto;

    &--auth {
      padding-left: 0;

      @media (min-width: 1600px) {
        padding-left: 0;
      }
    }

    &--fixed {
      position: fixed;
    }
  }

  &__background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(90deg, #f2f4fd 0%, #fafbff 100%);
  }

  &__busy {
    height: 100vh;
    width: 100vw;
    background: rgba($color-secondary-dark, 0.25);
    z-index: 999;
  }
}

#loom-companion-mv3 {
  display: none;
}

.vc-popover-content-wrapper {
  & * {
    display: initial;
  }
}

.vc-nav-container {
  display: block;
}

.vc-grid-cell {
  display: block;
}

.vc-bg-gray-800 {
  background: $color-secondary-light !important;
}

.vc-nav-container {
  .vc-grid-cell {
    padding: 5px;

    span {
      padding-right: 2.5px;
      padding-left: 2.5px;
    }
  }
}

.lc-1s5l46q,
.lc-rz39p8 {
  height: 40px !important;
  width: 40px !important;
}

.cell-placeholder {
  color: #354052;
  font-weight: 400;
  font-size: 16px;
  font-style: italic;
  opacity: 0.5;
}

// THIN
@font-face {
  font-family: 'Roboto';
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-Thin.eot');
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Thin.woff2') format('woff2'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Thin.woff') format('woff'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-ThinItalic.eot');
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-ThinItalic.woff2') format('woff2'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-ThinItalic.woff') format('woff'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

// LIGHT
@font-face {
  font-family: 'Roboto';
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-Light.eot');
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Light.woff2') format('woff2'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Light.woff') format('woff'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-LightItalic.eot');
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-LightItalic.woff2') format('woff2'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-LightItalic.woff') format('woff'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

// REGULAR
@font-face {
  font-family: 'Roboto';
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-Regular.eot');
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Regular.woff') format('woff'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-Italic.eot');
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Italic.woff2') format('woff2'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Italic.woff') format('woff'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

// MEDIUM
@font-face {
  font-family: 'Roboto';
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-Medium.eot');
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Medium.woff') format('woff'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-MediumItalic.eot');
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-MediumItalic.woff2') format('woff2'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-MediumItalic.woff') format('woff'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

// BOLD
@font-face {
  font-family: 'Roboto';
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-Bold.eot');
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Bold.woff') format('woff'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-BoldItalic.eot');
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-BoldItalic.woff2') format('woff2'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-BoldItalic.woff') format('woff'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

// BLACK
@font-face {
  font-family: 'Roboto';
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-BlackItalic.eot');
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-BlackItalic.woff2') format('woff2'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-BlackItalic.woff') format('woff'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-Black.eot');
  src: url('./ui-kit/assets/fonts/Roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Black.woff2') format('woff2'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Black.woff') format('woff'),
    url('./ui-kit/assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'bc-font';
  src: url('./ui-kit/assets/fonts/bc-font.eot');
  src: url('./ui-kit/assets/fonts/bc-font.eot?#iefix') format('embedded-opentype'),
    url('./ui-kit/assets/fonts/bc-font.woff') format('woff'),
    url('./ui-kit/assets/fonts/bc-font.ttf') format('truetype'),
    url('./ui-kit/assets/fonts/bc-font.svg#bc-font') format('svg');
  font-weight: normal;
  font-style: normal;
}
</style>
