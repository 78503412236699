<template>
  <div
    class="panel-company-notes flex w-full flex-col"
    @click.stop>
    <div class="w-full px-5 pt-5">
      <TabSwitcher
        :active-tab="activeTab"
        :tabs="timelineTabs"
        class="w-full justify-evenly"
        @set-active-tab="setTimelineType"
      />
    </div>
    <panel-view-timeline
      :profile="profile"
      user-type="partners"
      @note-saved="$emit('note-saved')">
    </panel-view-timeline>
  </div>
</template>

<script lang="ts" setup>
  import PanelViewTimeline from '@/components/Panel/PanelView/PanelViewTimeline.vue';
  import TabSwitcher from '@/components/UIKit/TabSwitcher.vue';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { TimelineType } from '@/domains/models/Timeline';
  import macros from '@/macros/macros';
  import { computed, onBeforeMount, onUnmounted } from 'vue';
  import { useI18n } from '@/i18n/i18n';
  import { Company } from '@/domains/models/Company';

  type Props = {
    profile: Company;
  }

  defineProps<Props>();
  const router = useRouter();
  const route = useRoute();
  const { t } = useI18n();

  defineEmits<{
    (e: 'note-saved'): void,
  }>();


  const timelineTabs = computed<{
    value: TimelineType,
    label: string,
  }[]>(() => [
    {
      value: macros.timeline.types.all,
      label: t(`timeline.${macros.timeline.types.all}`),
    },
      {
        value: macros.timeline.types.notes,
        label: t(`timeline.${macros.timeline.types.notes}`),
      },
      {
        value: macros.timeline.types.activities,
        label: t(`timeline.${macros.timeline.types.activities}`),
      },
    ],
  );
  const activeTab = computed<number>(() => {
    const type = route.query['timeline-type'] as TimelineType;
    const index = timelineTabs.value.findIndex((tab) => tab.value === type);

    return index > -1 ? index : 0;
  });

  const setTimelineType = (typeIndex: number): void => {
    const type = timelineTabs.value[typeIndex].value;

    router.replace({
      ...route,
      name: route.name || undefined,
      path: route.path,
      query: {
        ...route.query,
        'timeline-type': type,
      },
    });
  };

  onUnmounted(() => {
    router.replace({
      ...route,
      name: route.name || undefined,
      query: {
        ...route.query,
        'timeline-type': route.query['timeline-type'] === 'all' ? route.query['timeline-type'] : undefined,
      },
    });
  });

  onBeforeMount(() => {
    setTimelineType(0);
  });
</script>
