<template>
  <views-topbar-create-dropdown
    :is-active="isDropdownOpen"
    class="views-topbar-create-contacts is-secondary">
    <template #header>
      <views-topbar-create-trigger
        :text="$tc('generics.contact-cap', 1)" class="cursor-default" >
        <template #icon>
          <div class="icon-container">
            <icon name="contacts-plus" alt="plus" width="16" height="16" />
          </div>
        </template>
      </views-topbar-create-trigger>
    </template>
    <template #default>
      <div
        class="views-topbar-create-contacts__options is-column">
        <a
          href="https://www.linkedin.com/search/results/people/?origin=GLOBAL_SEARCH_HEADER&sid=aSM"
          class="views-topbar-create-contacts__link"
          target="_blank">
          <button>
            {{ $t('generics.create-with-linkedin') }}
            <i v-if="isCreationLocked" class="icon-lock ml-2"></i>
          </button>
        </a>
        <input
          ref="uploadButton"
          accept=".xlsx, .xls, .csv, text/plain"
          class="hidden-xs hidden-md hidden-sm"
          type="file"
          @change="uploadFile"/>
        <button
          class="views-topbar-create-contacts__link"
          @click="goToNewContact">
          {{ $t('generics.manually-create') }}
          <i v-if="isCreationLocked" class="icon-lock ml-2"></i>
        </button>
      </div>
    </template>
  </views-topbar-create-dropdown>
</template>

<script>
  import ViewsTopbarCreateDropdown
    from '@/components/Views/ViewsTopbar/ViewsTopbarCreate/ViewsTopbarCreateDropdown';
  import ViewsTopbarCreateTrigger
    from '@/components/Views/ViewsTopbar/ViewsTopbarCreate/ViewsTopbarCreateTrigger';
  import Icon from '@/components/Icon/Icon.vue';
  import { mapActions, mapState, mapGetters } from 'vuex';
  import upload from '@/api/upload';
  import { Modals } from '@/store/modules/app'

  export default {
    name: 'views-topbar-create-contacts',
    components: {
      Icon,
      ViewsTopbarCreateTrigger,
      ViewsTopbarCreateDropdown,
    },
    props: {
      isHover: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isDropdownOpen: true,
        isUploadModalCVOpen: false,
        Modals,
      };
    },
    computed: {
      ...mapState('app', ['modal']),
      ...mapGetters({
        configurationFeatures: 'user/configurationFeatures',
        configurationDb: 'user/configurationDb',
        ressourceCount: 'metadata/ressourceCount',
      }),
      isCreationLocked() {
        return !this.configurationFeatures.candidatesLimitUnlocked && this.ressourceCount.candidate >= this.configurationDb.candidatesLimit;
      },
    },
    methods: {
      ...mapActions('app', ['setModal', 'setParams']),
      ...mapActions([
        'setEditedProfile',
      ]),
      checkLimit() {
        if (!this.isCreationLocked) {
          return false;
        }
        this.setParams({ feature: 'candidatesLimitUnlocked' });
        this.setModal(Modals['modal-feature-db-limit']);
        return true;
      },
      goToNewContact() {
        const limitReached = this.checkLimit();
        if (limitReached) return;

        this.setEditedProfile({});

        this.$router.push({
          name: `${this.$route.name}Panel`,
          params: {
            id: 'new-contact',
          },
          query: {
            ...this.$route.query,
            type: 'create-contact',
          },
        });
      },
      triggerButton() {
        this.$refs.uploadButton.click();
      },
      async uploadFile(e) {
        if (e.target.files[0]) {
          try {
            this.file = e.target.files[0];

            const dataToSend = new FormData();

            dataToSend.append('file', this.file);
            dataToSend.append('uploadType', 'onboarding_data');

            await upload.upload({
              id: this.profile._id,
              file: dataToSend,
            });

            this.$toast.show({
              type: 'success',
              title:  this.$i18n.t('toast.congratulations'),
              message: this.$i18n.t('toast.file-received-for-bdd-integration'),
            });

            if (!this.profile.isDataImported) {
              await this.editProfile({ isDataImported: true });
            }
          } catch (error) {
            this.$toast.show({
              message: this.$t('toast.error-occured'),
              type: 'error',
              icon: 'cross',
            });
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .views-topbar-create-contacts {
    max-width: 100%;

    &__wrapper {
      justify-content: space-around;
      flex-grow: 1;
    }

    &__options {
      width: 100%;
      font-size: $font-size-s;
      position: relative;
    }

    &__link {
      display: flex;
      color: $color-tertiary;
      padding: 5px;
      height: 36px;
      cursor: pointer;
      align-items: center;
      padding-left: 30px;

      &--error {
        color: $color-error;
      }

      &:hover {
        background: $color-blue-light;
        color: $color-primary;
      }
    }

    .router-link-active {
      color: $color-marvin;
      font-weight: $font-weight-medium;
    }
  }

  .icon-lock {
    color: $color-warning-dark;
  }
</style>
