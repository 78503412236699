<template>
  <div class="panel-favorite-list-create-new-list">
    <title-section
      class="panel-favorite-list-create-new-list__title-new-list"
      position="left"
      :title="$t('companies.panel-company.new-list')">
    </title-section>
    <bc-button
      @click.native="goToCreateNewList"
      bc-icon="plus"
      bc-icon-margin="10px"
      bc-icon-size="15px"
      bc-radius="54px"
      bc-text-color="white"
      bc-text-size="16px"
      bc-type="regular"
      class="panel-favorite-list-create-new-list__cta-new-list">
      {{ $t('generics.create') }}
    </bc-button>
  </div>
</template>

<script>
  import TitleSection from '@/components/Title/TitleSection';
  import BcButton from '@/legacy/ui-kit/components/Button/BcButton';

  export default {
    name: 'panel-favorite-list-create-new-list',
    components: { BcButton, TitleSection },
    methods: {
      goToCreateNewList() {
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            subtype: 'create-favorite-list'
          },
          params: {
            ...this.$route.params
          }
        });
      }
    }
  };
</script>

<style lang=scss scoped>
  .panel-favorite-list-create-new-list {
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    @include bp('desktop') {
      padding: 50px 50px 0;
      margin-bottom: 30px;
    }
    &__title-new-list {
      margin-bottom: 15px;
    }

    &__cta-new-list {
      width: 150px;
      height: 40px;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
    }
  }

</style>
