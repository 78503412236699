<template>
  <div class="searchbar-filters-contract">
    <p class="searchbar-filters-contract__title">
      {{ $t('panel-coder-edit.contract') }}
    </p>
    <div class="searchbar-filters-contract__wrapper">
      <bc-radio
        :id="contract.query"
        :key="contractIndex"
        :label="contract.name"
        :name="contract.name"
        :value="contract.query"
        :checked="selectedContract === contract.query"
        @input="setSelectedContract(contract.query)"
        class="searchbar-filters-contract__checkbox"
        v-for="(contract, contractIndex) in contracts">
        {{ $t(contract.name) }}
      </bc-radio>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { buildContractTag } from '@/helpers/searchbar/factories';
  import BcRadio from '@/ui-kit/components/BcRadio/BcRadio';

  export default {
    name: 'searchbar-filters-contract',
    components: {
      BcRadio,
    },
    computed: {
      ...mapState({
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags,
      }),
      selectedContract() {
        const selectedContract = this.searchbarFiltersTags.find(tag => tag.type === 'contracts');
        return selectedContract ? this.contracts.find(contract => contract.name === selectedContract.name).query : 'all';
      },
    },
    data() {
      return {
        contracts: [
          {
            name: this.$i18n.t('companies.steps.every'),
            query: 'all',
          },
          {
            name: 'panel-coder-edit.permanent-contract',
            query: 'cdi',
          },
          {
            name: 'contracts.cdd',
            query: 'cdd',
          },
          {
            name: 'panel-coder-edit.mixed-training',
            query: 'alternance',
          },
          {
            name: 'panel-coder-edit.training-course',
            query: 'stage',
          },
          {
            name: 'contracts.interim',
            query: 'interim',
          },
          {
            name: 'panel-coder-edit.freelance',
            query: 'freelance',
          },
          {
            name: 'contracts.consultant',
            query: 'consultant',
          },
          {
            name: 'contracts.intermittent',
            query: 'intermittent',
          },
        ],
      };
    },
    methods: {
      setSelectedContract(value) {
        if (value === 'all') {
          const filteredTags = this.searchbarFiltersTags.filter(tag => tag.type !== 'contracts');
          this.$emit('on-unselect', filteredTags);
        } else {
          this.$emit('on-select', buildContractTag(value));
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-filters-contract {
    flex-direction: column;
    padding: 20px 20px -20px;
    @include bp('tablet') {
      flex-direction: row;
      justify-content: flex-start;
    }

    &__title {
      margin-bottom: 20px;
      font-size: $font-size-s;
      color: $color-blue-dark-cello;
      line-height: 18px;
      justify-content: center;
      @include bp('tablet') {
        justify-content: flex-start;
        text-align: left;
        width: 85px;
        margin-bottom: 0;
        max-width: 85px;
        margin-right: 35px;
      }
    }

    &__wrapper {
      position: relative;
      flex-wrap: wrap;
      margin: -5px -15px;
      width: 100%;
      @include bp('tablet') {
        width: initial;
        justify-content: initial;
      }
    }

    &__checkbox {
      justify-content: center;
      margin: 5px 15px;
      @include bp('tablet') {
        justify-content: initial;
      }
    }
  }
</style>
