<template>
  <select-container :label="label" icon="user">
    <bc-dropdown
      class="company-contacts-select__dropdown"
      :disabled="isDisabled"
      :id="$attrs.id"
      :is-scrollable="true"
      :limit="3"
      :options="contacts"
      is-expanded>
      <template #label="{ isDropdownOpen }">
        <bc-dropdown-trigger
          :disabled="isDisabled"
          type="button"
          :is-arrow="true"
          :is-dropdown-open="isDropdownOpen"
          :text="getContactFullName(selectedContact)">
        </bc-dropdown-trigger>
      </template>
      <template #option="{ activeIndex, index, option }">
        <bc-dropdown-item
          :avatar="index > 0 ? (option && option.pictureUrl) : null"
          @error="setAlternativeImg({event: $event, type: 'coders'})"
          :is-focus="activeIndex === index"
          :text="getContactFullName(option)"
          @click.native="selectContact(option, index)">
        </bc-dropdown-item>
      </template>
    </bc-dropdown>
  </select-container>
</template>

<script>
import { getCompanyContacts } from '@/api/company';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
import SelectContainer from '@/components/Select/SelectContainer';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
import { setAlternativeImg } from '@/mixins/imgFallback.js';

export default {
  name: 'company-contacts-select',
  components: {
    BcAvatar,
    BcDropdown,
    BcDropdownItem,
    BcDropdownTrigger,
    SelectContainer,
  },
  props: {
    companyId: {
      type: String,
    },
    label: {
      type: String,
    },
    selected: {
      type: Object,
    },
  },
  mounted() {
    if (this.companyId) {
      this.getContacts();
    }
    if (this.selected) {
      this.selectContact(this.selected);
    }
  },
  data() {
    return {
      contacts: [],
      selectedContact: undefined,
    };
  },
  computed: {
    isDisabled() {
      return !this.selectedContact && !this.contacts.length
    }
  },
  methods: {
    setAlternativeImg,
    async getContacts() {
      try {
        const { data } = await getCompanyContacts(this.companyId);
        this.contacts = [undefined, ...data];
        if (!this.selected) {
          this.selectContact(undefined);
        }
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    selectContact(contact) {
      this.$emit('select-contact', contact);
      this.selectedContact = contact;
    },
    getContactFullName(contact) {
      if (contact) {
        return contact._coder?.lastName + ' ' + contact._coder?.firstName;
      }
      return this.$t('generics.none');
    },
  },
  watch: {
    companyId(newValue) {
      if (newValue) {
        this.getContacts();
      }
    },
    selected(newValue) {
      this.selectContact(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.company-contacts-select {
  display: block;
  position: relative;

  & > label {
    position: absolute;
    color: $color-blue-cadet;
    left: 10px;
    top: -20px;

    & > i {
      margin-right: 5px;
    }
  }

  &__dropdown {
    width: 100%;

    :deep() {
      .bc-dropdown-trigger {
        height: auto;
      }
    }
  }
}
</style>
