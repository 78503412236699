<template>
  <div class="searchbar-filters-phone">
    <p class="searchbar-filters-phone__title">
      {{ $t('panel-coder-edit.contact-information') }}
    </p>
    <div class="searchbar-filters-phone__wrapper">
      <bc-checkbox
        class="searchbar-filters-phone__checkbox is-justify-content-center"
        id="phone"
        v-model="phone">
        {{ $t('hunt.has-a-phone-number') }}
      </bc-checkbox>
      <bc-checkbox
        class="searchbar-filters-phone__checkbox is-justify-content-center"
        id="searchbar-filters-email"
        v-model="email">
        {{ $t('hunt.has-email') }}
      </bc-checkbox>
    </div>
  </div>
</template>

<script>
  // TODO move or refacto to ui-kit
  import { mapState } from 'vuex';
  import { buildPhoneTag, buildEmailTag } from '@/helpers/searchbar/factories';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';

  export default {
    components: {
      BcCheckbox,
    },
    name: 'searchbar-filters-phone',
    computed: {
      ...mapState({
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags,
      }),
      phone: {
        get() {
          return this.searchbarFiltersTags.some(tag => tag.type === 'phone');
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildPhoneTag());
          } else {
            const index = this.searchbarFiltersTags.findIndex(tag => tag.type === 'phone');

            if (index >= 0) {
              this.$emit('on-unselect', index);
            }
          }
        },
      },
      email: {
        get() {
          return this.searchbarFiltersTags.some(tag => tag.type === 'email');
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildEmailTag());
          } else {
            const index = this.searchbarFiltersTags.findIndex(tag => tag.type === 'email');

            if (index >= 0) {
              this.$emit('on-unselect', index);
            }
          }
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-filters-phone {
    flex-direction: column;
    padding: 20px 20px -20px;
    @include bp('tablet') {
      flex-direction: row;
    }

    &__title {
      margin-bottom: 20px;
      font-size: $font-size-s;
      color: $color-blue-dark-cello;
      line-height: 18px;
      @include bp('tablet') {
        width: 100%;
        max-width: 85px;
        margin-right: 35px;
        text-align: left;
        margin-bottom: 0;
      }
    }

    &__wrapper {
      position: relative;
      flex-wrap: wrap;
      gap: 16px;
    }
  }
</style>
