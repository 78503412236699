<template>
  <div
    ref="searchbarTags"
    class="searchbar-tags-container">
    <div class="searchbar-tags-container__container">
      <tag-default
        v-for="(tag, tagIndex) in displayedTags"
        :prefix="tag.prefix"
        :key="tagIndex"
        :bc-background-color="tagsStyleMap[tag.type].backgroundColor"
        :bc-background-color-opacity="tagsStyleMap[tag.type].backgroundColorOpacity"
        :bc-cross="true"
        :bc-cross-color="tagsStyleMap[tag.type].crossColor"
        :bc-icon="tagsStyleMap[tag.type].icon || tag.icon"
        :bc-icon-color="tagsStyleMap[tag.type].iconColor"
        :bc-text-color="tagsStyleMap[tag.type].textColor"
        :bc-text-color-opacity="tagsStyleMap[tag.type].textColorOpacity"
        bc-icon-size="12px"
        class="searchbar-tags-container__tag"
        @click.native="deleteTag(tagIndex)">
        {{ getTagLabel(tag.name) }}
      </tag-default>
      <tag
        v-if="!isDisplayedAllTags && maxShowedTags !== searchbarFiltersTags.length && maxShowedTags > 0"
        key="hidden-tags"
        :color="'blue-hover'"
        :text="`+${searchbarFiltersTags.length - maxShowedTags}`"
        class="searchbar-tags-container__tag"
        @click.native="isDisplayedAllTags = true">
      </tag>
    </div>
  </div>
</template>

<script>
  import Tag from '@/components/UIKit/Tag/Tag';
  import { mapState } from 'vuex';
  import TagDefault from '@/legacy/ui-kit/components/Tag/BcTag';
  import searchbar from '@/common-old/mixins/Searchbar/searchbar';

  export default {
    /**
     * The component which will display the tags
     */
    name: 'searchbar-tags-container',
    components: { TagDefault, Tag },
    mixins: [searchbar],
    data() {
      return {
        searchbarTagsContainerWidth: 0,
        isDisplayedAllTags: false,
      };
    },
    computed: {
      ...mapState({
        searchbarFilters: state => state.searchbar.searchbarFilters,
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags,
        technos: state => state.tags.technos,
      }),
      /**
       * It calculate the maximum tags that the container is able to display without wrapping the tags
       * If the container has reach the end of the all tags array before reaching the maximum size of array, it will render the length of the all tags array
       * @returns {number}
       */
      maxShowedTags() {
        let tagsContentWidth = 0;
        if (this.searchbarFiltersTags.length > 0) {
          for (let [index, value] of this.searchbarFiltersTags.map(tag => tag.name).entries()) {
            if ((tagsContentWidth + value.length * 7.25 + 55) < this.searchbarTagsContainerWidth) {
              tagsContentWidth = tagsContentWidth + (value.length * 7.25 + 55);
            } else {
              return index - 1;
            }
          }
        }
        return this.searchbarFiltersTags.length;
      },
      /**
       * If the data isDisplayedAllTags is true, it will render all the tags
       * @returns {computed.allTags}
       */
      displayedTags() {
        return this.isDisplayedAllTags || this.maxShowedTags < 0 ? this.searchbarFiltersTags : this.searchbarFiltersTags.slice(0, this.maxShowedTags);
      },
    },
    methods: {
      /**
       * The method to set the searchbar tags width
       */
      setSearchbarTagsWidth() {
        this.searchbarTagsContainerWidth = this.$refs.searchbarTags ? this.$refs.searchbarTags.offsetWidth : 0;
      },
      /**
       * The method to delete a tag
       */
      deleteTag(index) {
        this.$store.dispatch('spliceSearchbarFiltersTags', index);

        this.$emit('on-delete');
      },
      getTagLabel(name) {
        if (name.includes('.')) {
          return this.$t(name);
        }
        return name;
      }
    },
    mounted() {
      /**
       * Set the searchbarTagsWidth when the component is mounted
       */
      this.$nextTick(() => {
        this.setSearchbarTagsWidth();
        window.addEventListener('resize', this.setSearchbarTagsWidth);
      });
    },
    /**
     * Destroy the searchbarTagsWidth when the component is destroyed
     */
    beforeDestroy() {
      window.removeEventListener('resize', this.setSearchbarTagsWidth);
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-tags-container {
    width: 100%;
    flex-wrap: wrap;

    &__container {
      flex-wrap: wrap;
      margin-bottom: -10px;
      width: 100%;
    }

    &__tag {
      margin-right: 10px;
      margin-bottom: 10px;
      border-radius: 30px;
      font-size: $font-size-xxs;
      font-weight: $font-weight-bold;

      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
