export const getLocationName = (locationsArray = [{}]) => {
  const [firstLocationOfLocationsArray] = locationsArray;

  const firstAddressComponent = (((firstLocationOfLocationsArray || {}).addressComponents || [])[0] || {});

  return (firstAddressComponent || {}).longName || '';
};

export const getLocationNameFromLocationObject = location => {
  const firstAddressComponent = (((location || {}).addressComponents || [])[0] || {});

  return (firstAddressComponent || {}).longName || '';
};

export const getLocationCountryFromLocationObject = location => {
  const addressComponents = (location || {}).addressComponents || [];
  const country = addressComponents.find(address => {
    return address.types.find(type => type === 'country');
  }) || {};

  if (country.longName) {
    return country.longName;
  }

  return '';
};

export default {
  getLocationName,
  getLocationNameFromLocationObject,
  getLocationCountryFromLocationObject,
};
