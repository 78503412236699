<template>
  <div class="jobboard-config-channel">
    <component
      :is="connectorName"
      :values="values"
      :channel="channel"
      :disabledSubscriptions="disabledSubscriptions"
      :disabledApplications="disabledApplications"
      :is-xtramile="!channel.isInternal"
      @cancel="$emit('cancel', $event)"
      @submit="$emit('submit', $event)"
    ></component>
  </div>
</template>

<script>
import { subscriptionsModels, applicationModes } from '@/api/xtramile';
import Jobrapido from './Channels/Jobrapido';
import Monster from './Channels/Monster';
import Talent from './Channels/Talent';
import Adzuna from './Channels/Adzuna';
import Indeed from './Channels/Indeed';
import IndeedInternal from './Channels/IndeedInternal';
import Jooble from './Channels/Jooble';
import Meteojob from './Channels/Meteojob';
import Cadremploi from './Channels/Cadremploi';
import HelloworkInternal from './Channels/HelloworkInternal';
import Joblift from './Channels/Joblift';
import Engagementjeunes from './Channels/EngagementJeunes';
import Jobijoba from './Channels/Jobijoba';
import Fashionjobs from './Channels/FashionJobs';
import Jobintree from './Channels/Jobintree';
import Apec from './Channels/Apec';
import Holeest from './Channels/Holeest';
import Leboncoin from './Channels/Leboncoin';
import Welcometothejungle from './Channels/Welcometothejungle';
import Yupeek from './Channels/Yupeek';
import Goldenbees from './Channels/Goldenbees';
import Staffsante from './Channels/StaffSante';
import Emploisoignant from './Channels/EmploiSoignant';
import Glassdoor from './Channels/Glassdoor';
import Stepstone from './Channels/Stepstone';
import Linkedin from './Channels/Linkedin';
import Jobteaser from './Channels/Jobteaser';
import Nostalentsnosemploi from './Channels/NosTalentsNosEmploi';
import WebsiteInternal from './Channels/WebsiteInternal';


export default {
  name: 'jobboard-config-channel',
  components: {
    Jobrapido,
    Monster,
    Talent,
    Adzuna,
    Indeed,
    IndeedInternal,
    Jooble,
    Meteojob,
    Cadremploi,
    HelloworkInternal,
    Joblift,
    Engagementjeunes,
    Jobijoba,
    Fashionjobs,
    Jobintree,
    Apec,
    Holeest,
    Leboncoin,
    Welcometothejungle,
    Yupeek,
    Goldenbees,
    Staffsante,
    Emploisoignant,
    Glassdoor,
    Stepstone,
    Linkedin,
    Jobteaser,
    Nostalentsnosemploi,
    WebsiteInternal,
  },
  created() {
    const disabledSubscriptions = [];
    const disabledApplications = [];
    if (!this.channel.allowsEmailApply) {
      disabledApplications.push(this.applicationModes.email);
    }
    if (!this.channel.allowsUrlApply) {
      disabledApplications.push(this.applicationModes.career);
    }
    if (!this.channel.allowsDirectApply) {
      disabledApplications.push(this.applicationModes.direct);
    }
    if (!this.channel.allowsCpc) {
      disabledSubscriptions.push(this.subscriptionsModels.costPerClick);
    }
    if (!this.channel.allowsOrganic) {
      disabledSubscriptions.push(this.subscriptionsModels.organic);
    }
    if (!this.channel.allowsCredit) {
      disabledSubscriptions.push(this.subscriptionsModels.credits);
    }
    if (!this.channel.allowsSponsored) {
      disabledSubscriptions.push(this.subscriptionsModels.sponsored);
    }
    this.disabledSubscriptions = disabledSubscriptions;
    this.disabledApplications = disabledApplications;
  },
  props: {
    channel: {
      type: Object,
      default: () => ({}),
    },
    channelSubscriptionId: {
      type: String,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    isInternalIntegration: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subscriptionsModels,
      applicationModes,
      disabledSubscriptions: [],
      disabledApplications: [],
    };
  },
  computed: {
    values() {
      if (this.channel.isInternal) {
        return this.config;
      }

      return this.config?.channelSubscriptions?.[this.channelSubscriptionId] || {}
    },
    connectorName() {
      if (this.channel.isInternal) {
        return `${this.channel.connectorName}-internal`;
      }
      if (this.channel.connectorName.includes('__xtramile')) {
        return this.channel.connectorName.replace('__xtramile', '');
      }
      return this.channel.connectorName;
    }
  }
};
</script>

<style lang="scss" scoped>
.jobboard-config-channel {
  flex-direction: column;
}
</style>
