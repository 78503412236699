import { defineStore } from 'pinia';
import { ref, type Ref } from 'vue';

export type HasReceivedMessagesState = Record<string, boolean>;

export interface LinkedinProfileStore {
  hasReceivedMessages: Ref<HasReceivedMessagesState>;
  setHasReceivedMessages: (linkedinSlug: string, value: boolean) => void;
}

export const useLinkedinProfileStore = defineStore('linkedinProfile', (): LinkedinProfileStore => {
  const hasReceivedMessages = ref<HasReceivedMessagesState>({});

  function setHasReceivedMessages(linkedinSlug: string, value: boolean) {
    hasReceivedMessages.value[linkedinSlug] = value;
  }

  return {
    hasReceivedMessages,
    setHasReceivedMessages,
  };
}); 