<template>
  <div class="team-modal-add-user">
    <ValidationObserver
      ref="observer"
      v-slot="{ valid }"
      tag="form"
      class="team-modal-add-user__form"
      @submit.prevent="sendUserData"
      autocomplete="off">
      <p class="team-modal-add-user__title">
        {{ $t('avatar-dropdown.adding-a-user-to-your-space') }}
      </p>
      <div class="team-modal-add-user__inputs-container">
        <ValidationProvider
          :rules="{ required: requiredFirstName, min: 2, regex: nameRegexp }"
          :name="$t('contacts.firstname')"
          slim
          v-slot="{ errors, valid }">
          <bc-input
            :class="{ invalid: errors.length > 0 }"
            :error="errors[0]"
            :valid="valid"
            :label="$t('contacts.firstname')"
            name="first-name"
            :placeholder="$t('contacts.firstname')"
            :labelPlaceholder="false"
            ref="firstName"
            type="text"
            v-model.trim="firstName"
            required>
          </bc-input>
        </ValidationProvider>
      </div>

      <div class="team-modal-add-user__inputs-container">
        <ValidationProvider
          :rules="{ required: requiredLastName, min: 2, regex: nameRegexp }"
          :name="$t('contacts.lastname')"
          slim
          v-slot="{ errors, valid }">
          <bc-input
            :class="{ invalid: errors.length > 0 }"
            :error="errors[0]"
            :valid="valid"
            :label="$t('contacts.lastname')"
            name="last-name"
            :placeholder="$t('contacts.lastname')"
            :labelPlaceholder="false"
            ref="lastName"
            type="text"
            v-model.trim="lastName"
            required>
          </bc-input>
        </ValidationProvider>
      </div>
      <div class="team-modal-add-user__inputs-container">
        <ValidationProvider
          :rules="{ required: requiredEmail, email: true }"
          name="Email"
          slim
          v-slot="{ errors, valid }">
          <bc-input
            :class="{ invalid: errors.length > 0 }"
            :error="errors[0]"
            :valid="valid"
            :required="requiredEmail"
            :label="$t('contacts.email')"
            name="email"
            :placeholder="$t('contacts.email')"
            :labelPlaceholder="false"
            ref="email"
            type="email"
            v-model.trim="email">
          </bc-input>
        </ValidationProvider>
      </div>
      <div class="team-modal-add-user__actions-inputs-container">
        <bc-button
          class="team-modal-add-user__btn"
          bc-padding="12px 20px"
          type="outlined"
          @click.native="$emit('close-modal')">
          {{ $t('generics.cancel') }}
        </bc-button>
        <bc-button
          class="send-invitation-button team-modal-add-user__btn"
          native-type="submit"
          :disabled="!valid">
          {{ $t('avatar-dropdown.send-invite') }}
        </bc-button>
      </div>
    </ValidationObserver>

    <div class="team-modal-add-user__details">
      <div class="team-modal-add-user__details--container">
        <div class="team-modal-add-user__details--title">
          <i class="icon-handshake font-size-xxl"></i>
          <p>{{ $t('add-user.section-1-title') }}</p>
        </div>
        <p class="team-modal-add-user__details--text">
          {{ $t('add-user.section-1-text') }}
        </p>
      </div>

      <div class="team-modal-add-user__details--container">
        <div class="team-modal-add-user__details--title">
          <i class="icon-chat"></i>
          <p>{{ $t('add-user.section-2-title') }}</p>
        </div>
        <p class="team-modal-add-user__details--text">
          {{ $t('add-user.section-2-text') }}
        </p>
      </div>

      <div class="team-modal-add-user__details--container">
        <div class="team-modal-add-user__details--title">
          <i class="icon-trophy"></i>
          <p>{{ $t('add-user.section-3-title') }}</p>
        </div>
        <p class="team-modal-add-user__details--text">
          {{ $t('add-user.section-3-text') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import BcInput from '@/ui-kit/components/BcInput/BcInput';
import BcButton from '@/ui-kit/components/BcButton';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapState } from 'vuex';
import regexp from '@/macros/coders/regexp';

export default {
  name: 'team-modal-add-user',
  components: { BcInput, BcButton, ValidationProvider, ValidationObserver },
  props: {
    requiredFirstName: {
      type: Boolean,
      default: true,
    },
    requiredLastName: {
      type: Boolean,
      default: true,
    },
    requiredEmail: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      nameRegexp: regexp.name,
    };
  },
  methods: {
    async sendUserData() {
      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        organizationId: this.profile._organization._id,
      };
      this.$emit('update-users-accounts', data);
      this.$emit('close-modal');
    },
  },

  computed: {
    ...mapState({
      coaches: state => state.coaches.coaches,
      profile: state => state.user.profile,
    }),
    metadata() {
      return this.coaches.length > 0 ? this.coaches.length : 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.team-modal-add-user {
  min-height: 442px;

  &__form {
    flex-direction: column;
    padding: 50px;
    width: 475px;
    gap: 24px;
  }

  &__details {
    flex-direction: column;
    gap: 20px;
    padding: 50px;
    width: 475px;
    background: $color-neutre-4;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    justify-content: center;

    &--container {
      flex-direction: column;
    }

    &--title {
      color: $color-primary;
      font-size: $font-size-m;
      font-weight: $font-weight-bold;
      gap: 8px;
      align-items: center;
    }

    &--text {
      color: $color-tertiary-2;
      text-align: justify;
      font-size: $font-size-s;
      margin-top: 10px;
    }
  }

  &__title {
    color: $color-tertiary;
    font-size: 28px;
    font-weight: $font-weight-bold;
  }

  &__inputs-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__actions-inputs-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .bc-button {
      font-size: 14px;
    }
  }

  &__btn {
    width: 100%;
  }
}
</style>
