<template>
  <div class="log-as"></div>
</template>

<script>
  import { setToken } from '@/managers/token/useCases/setToken';
  import { removeToken } from '@/managers/token/useCases/removeToken';

  export default {
    name: 'log-as',
    beforeRouteEnter(to, from, next) {
      next(vm => {
        removeToken();
        vm.$store.dispatch('user/cleanProfile')
        setToken(to.query.token);
        next('/')
      });
    },
  };
</script>
