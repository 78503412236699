<template>
  <TimelineItemWrapper :action="{ icon: 'plus' }">
    <div class="notes-actions">
      <BcButton
        class="notes-actions__create-note h-[36px]"
        icon-left="pen-square"
        size="small"
        @click="$emit('on-action', 'note')">
        {{ $t('companies.panel-view-timeline.create-note') }}
      </BcButton>

      <div class="notes-actions__buttons">
        <BcButton
          v-tooltip.bottom="{ content: $t('notes.log-call') }"
          icon-center="phone"
          size="small"
          type="outlined"
          @click="$emit('on-action', 'call')"/>
        <BcButton
          v-tooltip.bottom="{ content: $t('notes.log-an-email') }"
          icon-center="mail"
          size="small"
          type="outlined"
          @click="$emit('on-action', 'email')"/>
        <BcButton
          v-if="['coders', 'suggestions-coders', 'companies'].includes(type)"
          v-tooltip.bottom="{ content: $t('notes.log-a-linkedin-exchange') }"
          class="notes-actions__btn-img"
          size="small"
          type="outlined"
          @click="$emit('on-action', 'linkedin')">
          <img :src="require('@/assets/logo/linkedin.png')"/>
        </BcButton>
        <BcButton
          v-if="['coders', 'suggestions-coders'].includes(type)"
          v-tooltip.bottom="{ content: $t('notes.log-message') }"
          class="notes-actions__btn-img"
          size="small"
          type="outlined"
          @click="$emit('on-action', 'whatsapp')">
          <img :src="require('@/assets/logo/whatsapp.png')"/>
        </BcButton>
      </div>
    </div>
  </TimelineItemWrapper>
</template>

<script>
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import TimelineItemWrapper from '@/components/Timeline/TimelineItemWrapper';

  export default {
    name: 'notes-actions',
    components: {
      BcButton,
      TimelineItemWrapper,
    },
    props: {
      type: {
        type: String,
      },
      profile: {
        type: Object,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .notes-actions {
    gap: 10px;
    padding-bottom: 30px;

    &__buttons {
      gap: 10px;
    }

    &__btn-img {
      align-items: center;
      padding: 0 9px !important;
      height: 36px;
      width: 36px;

      & > img {
        width: 15px;
        height: 15px;
      }
    }
  }
</style>
