<template>
  <div class="bc-pagination">
    <bc-button
      :disabled="!hasPrev"
      :icon-left="'chevron-left'"
      @click="changePage(current - 1)"
      class="bc-pagination__page"
      size="extra-small"
      type="outlined">
    </bc-button>
    <ul class="bc-pagination__list">
      <!--First-->
      <li v-if="hasFirst">
        <span
          :class="{ 'bc-pagination__page--active': isActive(1) }"
          @click="changePage(1)"
          class="bc-pagination__page">
          1
        </span>
      </li>
      <li
        @click="openPageSelector"
        class="bc-pagination__page"
        v-if="hasFirstEllipsis">
        &hellip;
      </li>

      <!--Pages-->
      <li
        :key="pageNumber"
        @click="changePage(pageNumber)"
        v-for="pageNumber in pagesInRange">
        <span
          :class="{ 'bc-pagination__page--active': isActive(pageNumber) }"
          class="bc-pagination__page">{{ pageNumber }}</span>
      </li>

      <!--Last-->
      <li
        @click="openPageSelector"
        class="bc-pagination__page"
        v-if="hasLastEllipsis">
        &hellip;
      </li>
      <li v-if="hasLast">
        <span
          :class="{ 'bc-pagination__page--active': isActive(pageCount) }"
          @click="changePage(pageCount)"
          class="bc-pagination__page">
          {{ pageCount }}
        </span>
      </li>
    </ul>
    <bc-button
      :disabled="!hasNext"
      :icon-right="'chevron-right'"
      @click="changePage(current + 1)"
      class="bc-pagination__page"
      size="extra-small"
      type="outlined">
    </bc-button>

    <bc-modal :active.sync="isModalOpen">
      <bc-pagination-modal
        @submit="changePage"
        :max="pageCount"
        :current="current">
      </bc-pagination-modal>
    </bc-modal>
  </div>
</template>

<script>
  import BcButton from '../BcButton';
  import BcModal from '../BcModal';
  import BcPaginationModal from './BcPaginationModal';

  export default {
    name: 'bc-pagination',
    components: { BcPaginationModal, BcModal, BcButton },
    props: {
      total: {
        type: Number,
        default: 20
      },
      perPage: {
        type: Number,
        default: 36
      },
      current: {
        type: Number,
        default: 1
      }
    },
    data() {
      return {
        beforeCurrent: 3,
        afterCurrent: 3,
        isModalOpen: false,
      };
    },
    computed: {
      /**
       * Total page size (count).
       */
      pageCount() {
        return Math.ceil(this.total / this.perPage);
      },
      /**
       * Check if previous button is available.
       */
      hasPrev() {
        return this.current > 1;
      },

      /**
       * Check if first page button should be visible.
       */
      hasFirst() {
        return this.current >= 2 + this.beforeCurrent;
      },

      /**
       * Check if first ellipsis should be visible.
       */
      hasFirstEllipsis() {
        return this.current >= this.beforeCurrent + 4;
      },

      /**
       * Check if last page button should be visible.
       */
      hasLast() {
        return this.current <= this.pageCount - (1 + this.afterCurrent);
      },

      /**
       * Check if last ellipsis should be visible.
       */
      hasLastEllipsis() {
        return this.current < this.pageCount - (2 + this.afterCurrent);
      },

      /**
       * Check if next button is available.
       */
      hasNext() {
        return this.current < this.pageCount;
      },

      /**
       * Get near pages, 1 before and 1 after the current.
       * Also add the click event to the array.
       */
      pagesInRange() {
        let left = Math.max(1, this.current - this.beforeCurrent);
        if (left - 1 === 2) {
          left--; // Do not show the ellipsis if there is only one to hide
        }
        let right = Math.min(this.current + this.afterCurrent, this.pageCount);
        if (this.pageCount - right === 2) {
          right++; // Do not show the ellipsis if there is only one to hide
        }

        const pages = [];
        for (let i = left; i <= right; i++) {
          pages.push(i);
        }

        return pages;
      }
    },
    watch: {
      /**
       * If current page is trying to be greater than page count, set to last.
       */
      pageCount(value) {
        if (this.current > value) {
          return this.pageCount;
        }
      }
    },
    methods: {
      isActive(num) {
        return num === this.current;
      },

      changePage(num) {
        if (this.current === num || num < 1 || num > this.pageCount) {
          return;
        }
        this.$emit('change', num);
        this.$emit('update:current', num);
      },
      openPageSelector() {
        this.isModalOpen = true;
      },
      submitNewPage() {
        this.changePage(this.inputValue);

        this.isModalOpen = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .bc-pagination {
    align-items: center;
    color: $color-secondary;
    font-size: $font-size-l;

    &__list {
      display: flex;
      list-style: none;
    }

    &__page {
      margin-left: 6px;
      cursor: pointer;
    }

    &__page {
      padding: 0px 5px;
      min-width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-white;
      border: 1px solid $color-blue-medium;
      border-radius: 5px;
      color: $color-tertiary;

      &:hover {
        background: $color-neutre-3;
        color: $color-tertiary;
      }

      &--active {
        color: $color-white;
        background: $color-primary;
        border: none;

        &:hover {
          color: $color-white !important;
          background: $color-primary !important;
        }
      }
    }
  }
</style>
