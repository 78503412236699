<script setup>
import { usePanelNavigation } from '@/composables/usePanelNavigation';
import { useRoute } from 'vue-router/composables';
import { watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getCustomJobsById } from '@/api/custom-jobs';
import { useToast } from '@/ui-kit/components/BcToast';
import PanelCompanyNotes from '@/components/Panel/PanelCompany/PanelCompanyNotes.vue';
import PanelOpportunityHeader from '@/components/Panel/PanelOpportunity/PanelOpportunityHeader.vue';
import PanelOpportunityProfile from '@/components/Panel/PanelOpportunity/PanelOpportunityProfile.vue';

const route = useRoute();
const { closePanel } = usePanelNavigation();
  const toast = useToast();
  const manageClosePanel = (event) => {
    if (event.target?.className === 'icon-close' || event.target?.className === 'overlay') {
      closePanel();
    }
  };

  const { data: profile, refetch } = useQuery({
    queryKey: ['GET_OPPORTUNITY_PROFILE', route.params.id],
    queryFn: async() => {
      try {
        const { data } = await getCustomJobsById(route.params.id);

        return data;
      } catch (e) {
        toast.show({
          type: 'error',
          message: 'An error occured',
        });

        console.error(e);
      }
    },
  });

  watch(
      () => route.params.id,
      async() => {
        refetch();
      },
  );
</script>

<template>
  <div
    v-click-outside="manageClosePanel"
    class="flex max-h-full shrink-0 grow flex-col items-center justify-center"
  >
    <PanelOpportunityHeader
      :profile="profile"
      class="shrink-0"
      @update-opportunity="refetch"
    />
    <div class="flex max-h-[calc(100%-50px)] w-full grow">
      <div class="flex w-1/2 flex-col">
        <PanelOpportunityProfile
          :profile="profile"
          @update-opportunity="refetch"/>
      </div>
      <div class="flex w-1/2 flex-col overflow-y-auto bg-neutral-100">
        <PanelCompanyNotes :profile="profile"/>
      </div>
    </div>
  </div>
</template>

