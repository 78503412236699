<template>
  <div class="industries-modal-create is-column is-align-items-center">
    <p class="industries-modal-create__title font-size-xl mb-4 font-weight-bold">
      Créer une industrie
    </p>
    <div class="is-column is-align-items-center is-full-width">
      <bc-input
        ref="name"
        v-model="name"
        class="industries-modal-create__input is-full-width"
        label="Nom"
        name="name"
        placeholder="Nom"
        type="text">
      </bc-input>
    </div>
    <hr class="industries-modal-create__separator mt-5 mb-5 full-width"/>
    <div class="industries-modal-create__wrapper is-full-width is-justify-content-center">
      <bc-button
        class="industries-modal-create__button mr-4"
        data-button-cancel
        type="outlined"
        @click="$emit('close-modal')">
        {{ $t("generics.cancel") }}
      </bc-button>
      <bc-button
        :disabled="!name"
        class="industries-modal-create__button"
        color="success"
        data-button-validate
        @click="createIndustry">
        Créer l'industrie
      </bc-button>
    </div>
  </div>
</template>

<script>
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import { createIndustry } from '@/api/industries';
  import BcInput from '@/ui-kit/components/BcInput/BcInput';

  export default {
    name: 'industries-modal-create',
    components: { BcInput, BcButton },
    data() {
      return {
        name: '',
      };
    },
    methods: {
      async createIndustry() {
        try {
          const { data } = await createIndustry({
            name: this.name,
          });

          this.$emit('industry-created', data);
        } catch (error) {
          this.$toast.show({
            title: 'Erreur lors de la création de l\'industrie',
            type: 'error',
            message: error?.response?.data?.message || error,
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .industries-modal-create {
    padding: 30px;
    width: 100%;
    max-width: 600px;

    @include bp('tablet') {
      min-width: 600px;
    }

    &__title {
      color: $color-secondary;
    }

    &__separator {
      width: 100%;
      border: 0;
      border-top: 1px solid rgba($color-secondary, 0.2);
    }
  }
</style>
