<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
  import { useStore } from '@/store';
  import CampaignsCardType from '@/components/Campaigns/CampaignsCard/CampaignsCardType.vue';
  import CampaignsCardStatus from '@/components/Campaigns/CampaignsCard/CampaignsCardStatus.vue';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
  import { useI18n } from '@/i18n/i18n';
  import { User } from '@/domains/models/User';
  // import DuplicateIcon from '@/ui-kit/assets/icons/duplicate.svg?component';
  import ArchiveIcon from '@/ui-kit/assets/icons/archive.svg?component';
  import PauseIcon from '@/ui-kit/assets/icons/pause-2.svg?component';
  import { Campaign } from '@/domains/models/Campaign';
  import { usePanelStore } from '@/store/pinia/panel';
  import CampaignsCreateView from '@/components/Campaigns/CampaignsCreate/CampaignsCreateView.vue';
  import { useMutation } from '@tanstack/vue-query';
  import { archiveCampaign, pauseCampaign } from '@/api/campaigns';
  import { useCampaignsStore } from '@/store/pinia/campaigns';

  const props = defineProps<{
    cardContent: Campaign
    isSelected: Boolean
    context: String
  }>();

  const { t } = useI18n();

  defineEmits(['checked']);

  const store = useStore();
  const panelStore = usePanelStore();
  const campaignStore = useCampaignsStore();

  const isHover = ref(false);

  const profile = computed<User>(() => store.state.user.profile);
  const prospectsCount = computed(() => props.cardContent.prospectsSources?.reduce((acc, source) => acc + source.elementsCount, 0) ?? 0);
  const type = computed(() => props.cardContent.campaignTypes?.[0]?.name || campaignStore.configuration.types.find(type => type._id === props.cardContent?._campaignTypes?.[0])?.name);

  const archiveCampaignMutation = useMutation({
    mutationKey: ['archive-campaigns', props.cardContent._id],
    mutationFn: async() => {
      await archiveCampaign(props.cardContent._id);

      await store.dispatch('updateCardFromId', {
        id: props.cardContent._id,
        content: {
          status: 'ARCHIVED',
        },
      });
    },
  });
  const pauseCampaignMutation = useMutation({
    mutationKey: ['pause-campaigns', props.cardContent._id],
    mutationFn: async() => {
      await pauseCampaign(props.cardContent._id);

      await store.dispatch('updateCardFromId', {
        id: props.cardContent._id,
        content: {
          status: 'PAUSED',
        },
      });
    },
  });

  const settingsOptions = [
    // {
    //   key: 'duplicate',
    //   label: t('generics.duplicate'),
    //   icon: DuplicateIcon,
    // },
    {
      key: 'archive',
      label: t('generics.to-archive'),
      icon: ArchiveIcon,
      event: archiveCampaignMutation.mutateAsync,
    },
    {
      key: 'pause',
      label: t('generics.to-pause'),
      icon: PauseIcon,
      event: pauseCampaignMutation.mutateAsync,
    },
  ];

  const userInitials = computed(() => {
    return `${profile.value.firstName?.charAt(0)}${profile.value.lastName?.charAt(0)}`;
  });

  function handleEdit() {
    panelStore.openModal(CampaignsCreateView, {
      campaign: props.cardContent ?? {},
    });
  }
</script>

<template>
  <div
    class="campaigns-card w-full flex-col border-radius-m is-relative px-[30px] py-[20px]"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
  >
    <div
      class="campaigns-card__container relative flex  grid grid-cols-[30px_minmax(200px,2fr)_minmax(150px,1fr)_minmax(150px,2fr)_minmax(150px,1fr)_160px_150px] gap-x-2.5 w-full items-center justify-start">
      <div
        class="campaigns-card__wrapper justify-content-left"
        @click.stop>
        <bc-checkbox
          :id="cardContent._id"
          :value="isSelected"
          @input="$emit('checked', $event)"
        />
      </div>

      <!-- Name and Title -->
      <div
        class="is-secondary-dark is-align-items-center w-full flex items-center">
        <p
          class="campaigns-card__text body-l--medium is-ellipsis w-full"
        >
          {{ cardContent.name }}
        </p>
      </div>

      <div
        class="campaigns-card__wrapper w-full flex shrink-0 justify-start">
        <CampaignsCardType
          :type="type"
        />
      </div>

      <div
        class="campaigns-card__wrapper w-full flex shrink-0 justify-start">
        <CampaignsCardStatus 
          :campaign="cardContent"
        />
      </div>

      <div
        class="campaigns-card__wrapper w-full flex shrink-0 justify-start">
        {{ prospectsCount }}
      </div>
      <div
        class="campaigns-card__wrapper w-full flex justify-start">
        <BcAvatar
          :placeholder="userInitials"
          :src="cardContent.coach?.pictureUrl"
          size="s"
        />
      </div>

      <div
        class="campaigns-card__wrapper w-full shrink-0 flex justify-start gap-2">
        <BcButton
          icon-left="edit-3"
          size="extra-small"
          @click.native.stop="handleEdit"
        >
          {{ $t('generics.edit') }}
        </BcButton>
        <BcDropdown
          :close-dropdown-at-select="false"
          :force-direction="true"
          :is-scrollable="false"
          :options="settingsOptions"
          position="bottom-left"
          @click.native.stop
        >
          <template #label="{ isDropdownOpen }">
            <BcButton
              :class="{'campaigns-card__button--open': isDropdownOpen}"
              class="campaigns-card__button"
              icon-left="settings"
              size="extra-small"
            />
          </template>
          <template #option="{ option, isActive }">
            <BcDropdownItem
              :class="{ 'bg-blue-100 fill-blue-500 text-blue-500': isActive }"
              :svg-component="option.icon"
              class="min-w-[200px] shrink-0"
              @click.native="option.event"
            >
              <p class="first-letter:capitalize">{{ option.label }}</p>
            </BcDropdownItem>
          </template>
        </BcDropdown>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .campaigns-card {
    &__button {
      background: $color-blue-gradient-1;
      font-weight: 500;
      font-size: 15px;

      &:hover {
        background: $color-blue-gradient-2;
      }

      &--open {
        background: $color-neutre-4;
        color: $color-primary;

        &:hover {
          color: white;
          background: $color-neutre-4 !important;
        }

        &:deep() {
          i {
            font-size: 14px;
          }
        }
      }
    }
  }
</style>
