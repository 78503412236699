<template>
  <div class="container">
    <configuration-multicast-tabs>
      <div class="header-top">
        <div>
          <bc-button v-if="!configurationFeatures.multidiffusion" icon-left="share-2" icon-right="lock" @click="showMultipostModal">
            {{ $t('features.enable-multipost') }}
          </bc-button>
        </div>
      </div>
    </configuration-multicast-tabs>
    <views-container>
      <div class="configuration-jobboards">
        <router-view></router-view>
        <div v-if="!careerActivated" class="configuration-jobboards__warning-container">
          <p class="configuration-jobboards__warning"></p>⚠️ {{ $t('features.should-activate-career') }}
        </div>
        <div class="configuration-jobboards__titles">
          <p style="width: 40%;" class="pl-2">ID</p>
          <p style="width: 20%;">{{ $t('generics.status') }}</p>
          <p style="width: 30%;">{{ $t('generics.subscription-models') }}</p>
          <p style="width: 10%;">{{ $t('generics.edit-2') }}</p>
        </div>
        <div v-if="isLoading && !activatedChannelsSubscriptionsFiltred.length && configurationFeatures.multidiffusion" class="configuration-jobboards__items-loader">
          <bc-spinner></bc-spinner>
        </div>
        <div v-else-if="configurationFeatures.multidiffusion" class="configuration-jobboards__items">
          <configuration-jobboards-item
            v-for="channelSubscription in activatedChannelsSubscriptionsFiltred" :key="channelSubscription.id"
            :jobboard="channelSubscription"
            :channel-subscription="channelSubscription"
            :channel-subscription-config="getChannelSubscriptionConfig(channelSubscription.id, channelSubscription.channelId, channelSubscription.connectorName)"
            :configuration-integrations="configurationIntegrations"
            :disabled="!careerActivated"
            @edit="onEdit"
          ></configuration-jobboards-item>
        </div>
        <div v-else class="configuration-jobboards__items">
          <configuration-jobboards-item
            v-for="jobboard in trialChannelsList" :key="jobboard.id"
            :jobboard="jobboard"
          ></configuration-jobboards-item>
          <div class="configuration-jobboards__block-feature"></div>
        </div>
      </div>
      <bc-modal
        :active="isModalOpen"
        @close="isModalOpen = false">
        <JobboardConfigChannel
          :channel="getJobboard(selectedChannel.connectorName)"
          :channelSubscriptionId="selectedChannel.id"
          :config="xtramileConfig"
          @submit="onSubmitChannel"
          @cancel="isModalOpen = false"
        ></JobboardConfigChannel>
      </bc-modal>
    </views-container>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { sendSlackNotification } from '@/api/notifications';
import { addChannelSubscriptions } from '@/api/xtramile';
import { signIn } from '@/api/job-multipost';
import { Modals } from '@/store/modules/app';
import { trialChannelsList } from './data'
import BcModal from '@/ui-kit/components/BcModal/BcModal';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
// import BcInput from '@/ui-kit/components/BcInput/BcInput';
// import ConfigurationJobboardsSection from './ConfigurationJobboardsSection.vue';
import ConfigurationJobboardsItem from '@/components/Configuration/ConfigurationJobboardsXtramile/ConfigurationJobboardsItem';
// import WarningCercle from '@/components/WarningCercle/WarningCercle';
import ViewsContainer from '@/components/Views/ViewsContainer';
import ConfigurationMulticastTabs from '@/components/Configuration/Tabs/ConfigurationMulticastTabs';
import JobboardConfigChannel from '@/components/Jobboard/JobboardConfigChannel/JobboardConfigChannel';

export default {
  name: 'configuration-jobboards',
  components: {
    BcModal,
    BcButton,
    BcSpinner,
    // BcInput,
    // ConfigurationJobboardsSection,
    ConfigurationJobboardsItem,
    // WarningCercle,
    ViewsContainer,
    ConfigurationMulticastTabs,
    JobboardConfigChannel,
  },
  data() {
    return {
      isLoading: true,
      isModalOpen: false,
      selectedChannel: {},
      trialChannelsList: [],
    }
  },
  async mounted() {
    if (this.configurationFeatures.multidiffusion) {
      await this.getActivatedChannels();
      await this.getActivatedChannelsSubscriptions();
      this.isLoading = false;
    } else {
      this.trialChannelsList = trialChannelsList.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
      this.showMultipostModal();
    }
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
      activatedChannels: state => state.xtramile.activatedChannels,
      activatedChannelsSubscriptions: state => state.xtramile.activatedChannelsSubscriptions,
    }),
    activatedChannelsFiltred() {
      return this.activatedChannels.filter(channel => !channel.isInternal);
    },
    activatedChannelsSubscriptionsFiltred() {
      return this.activatedChannelsSubscriptions.filter(channel => !channel.isInternal);
    },
    ...mapGetters({
      configurationFeatures: 'user/configurationFeatures',
    }),
    configurationIntegrations() {
      return this.profile?._organization?.configuration?.integrations || {};
    },
    careerActivated() {
      return this.profile?._organization?.configuration?.career?.activated;
    },
    credits() {
      return this.configurationIntegrations?.xtramile?.credits || 0;
    },
    usedCredits() {
      return this.configurationIntegrations?.xtramile?.usedCredits || 0;
    },
    xtramileConfig() {
      if (this.isInternalIntegration) {
        return this.configurationIntegrations?.job_multiposting?.[this.selectedChannel?.connectorName] || {}
      }
      return this.configurationIntegrations?.xtramile || {};
    },
    isInternalIntegration() {
      return this.selectedChannel?.isInternal;
    },
  },
  methods: {
    ...mapActions({
      getActivatedChannels: 'xtramile/getActivatedChannels',
      getActivatedChannelsSubscriptions: 'xtramile/getActivatedChannelsSubscriptions',
      setModal: 'app/setModal',
      updateConfigurationXtramile: 'user/updateConfigurationXtramile',
      updateConfigurationMultiposting: 'user/updateConfigurationMultiposting',
    }),
    onEdit(channelSubscription) {
      this.selectedChannel = channelSubscription;
      this.isModalOpen = true;
    },
    showMultipostModal() {
      this.setModal(Modals['modal-feature-multipost']);
    },
    getJobboard(connectorName) {
      return this.activatedChannelsFiltred.find(channel => channel.connectorName === connectorName);
    },
    getChannelSubscriptionConfig(channelSubscriptionId, channelId, name) {
      let config = {};
      const channel = this.activatedChannels.find(channel => channel.id === channelId);
      if (channel?.isInternal) {
        config = this.configurationIntegrations?.job_multiposting?.[name] || {}
      }
      config = this.configurationIntegrations?.xtramile || {};
      return config?.channelSubscriptions?.[channelSubscriptionId] || {}
    },
    async onSubmitChannel({ isXtramile, jobboard, ...params }) {
      if (isXtramile === false) {
        await this.onSubmitInternalIntegration({ jobboard, params });
        return;
      }

      try {
        const { data } = await addChannelSubscriptions({
          channelSubscriptionId: this.selectedChannel.id,
          ...params,
        });
        this.updateConfigurationXtramile({
          ...this.xtramileConfig,
          channelSubscriptions: {
            ...this.xtramileConfig.channelSubscriptions,
            [data.id]: { params, response: data },
          }
        })
        this.selectedChannel = {};
        this.isModalOpen = false;
      } catch (err) {
        this.selectedChannel = {};
        this.isModalOpen = false;
        this.$toast.show({
          type: 'error',
          message: err.response?.data?.message || err.message,
          duration: 6000,
        })
      }
    },
    async onSubmitInternalIntegration({ jobboard, params }) {
      const { data: config } = await signIn({
        jobboard,
        credentials: params.credentials,
        params: params.params,
      });
      this.updateConfigurationMultiposting({ key: jobboard, ...config });
      this.isModalOpen = false;
    },
    async requestMoreOffer() {
      const organizationName = this.profile._organization.name;
      const userName = (this.profile.firstName || '') + ' ' + (this.profile.lastName || '');
      const email = this.profile.email || '';
      const mobile = this.profile.mobile || '';

      try {
        await sendSlackNotification({
          title: '-',
          body: `NomOrga: ${organizationName}, \nNomUser: ${userName}, \nAdresseMail: ${email}, \nTéléphone: ${mobile}, \nFeature demandée: Demande de crédits supplémentaires pour la multidiffusion`,
        });
        this.$toast.show({
          type: 'success',
          message: this.$t('features.feature-request'),
          duration: 7000,
        });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  flex-direction: column;
}
.configuration-jobboards {
  width: 100%;
  flex-direction: column;
  gap: 10px;
  position: relative;

  &__titles {
    padding: 5px 30px;

    & > p {
      color: $color-blue-heavy-dark;
      font-size: 13px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  &__items {
    position: relative;
    flex-direction: column;
    gap: 5px;

    &-loader {
      justify-content: center;
      align-items: center;
      min-height: 100px;
    }
  }

  &__block-feature {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4fd 0%, #fafbff 100%);
    opacity: 0.5;
  }

  &__warning {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    margin-bottom: 15px;
    color: $color-blue-dark-cello;

    &-container {
      width: 100%;
      justify-content: flex-end;
      margin-bottom: 5px;
    }
  }
}

.header-top {
  align-items: center;
  gap: 20px;

  &__offer {
    align-items: center;
    padding-right: 20px;
    gap: 10px;
    border-right: 1px solid $color-blue-medium;

    & p {
      font-size: 14px;
      max-width: 150px;
    }
  }
}

.offer-icon {
  border-radius: 10px;
  background: white;
  box-shadow: 0px 4px 30px 0px rgba(29, 57, 94, 0.10);
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
  .tooltip-custom {
    height: 60vh;

    .tooltip-inner {
      max-width: 100%;
      height: 100%;
    }
  }
</style>
