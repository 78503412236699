<template>
  <div
    :style="{ 'margin': bcMargin }"
    class="spinner"
    v-if="spinning">
    <div
      :style="spinnerStyle"
      class="spinner__item">
      <!-- Here the loader -->
    </div>
  </div>
</template>

<script>
  import colors_helpers from '@/legacy/ui-kit/utils/colors_helpers';

  export default {
    name: 'spinner',
    props: {
      /**
       * The Boolean that determine if the spinner is visible
       */
      spinning: {
        type: Boolean,
        default: true
      },
      /**
       * The custom margin of the spinner
       */
      bcMargin: {
        type: String,
        default: ''
      },
      /**
       * The custom borderColor of the spinner
       */
      bcBorderColor: {
        type: String,
        default: '#3C80F7'
      },
      /**
       * The size of the spinner,
       */
      bcSize: {
        type: String,
        default: '31px'
      }
    },
    computed: {
      spinnerStyle() {
        return {
          borderColor: this.bcBorderColor.includes('#') ? this.bcBorderColor : colors_helpers.$_checkValidColor(this.bcBorderColor),
          height: this.bcSize,
          width: this.bcSize
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  // Spinning div
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    // Loader style and spinning effect
    &__item {
      border: 2px solid;
      border-top: 2px solid $color-white !important;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
</style>
