<template>
  <div
    class="panel-reminder-coder-timeline is-column is-full-width is-align-items-center"
    @click.stop
  >
    <div class="is-column is-full-width">
      <div v-if="!isCreatingNote" class="is-column is-full-width">
        <div
          class="panel-reminder-coder-timeline__container is-full-width is-align-items-center is-justify-content-space-between"
        >
          <div class="panel-reminder-coder-timeline__actions">
            <NotesActions
              v-if="canCreateNote"
              :type="$route.query.type"
              :profile="profile"
              @on-action="onNotesAction"
              @reminder-date="onReminderData"
            />
          </div>
        </div>
      </div>
      <timeline-item-wrapper
        v-else
        class="is-full-width mb-5"
        :action="noteTypeAction"
      >
        <div class="panel-reminder-coder-timeline__editor">
          <timeline-editor
            :is-new-note="userType.includes('coders')"
            :noteType="noteType"
            :reminderData="reminderData"
            :noteTypeTracking="noteTypeTracking"
            :profile="profile"
            :timeline-id="profile._id"
            @close="e => setIsCreatingNote(e, false)"
            @note-saved="handleNoteSaved"
          >
          </timeline-editor>
        </div>
      </timeline-item-wrapper>
      <div
        v-if="qualifications.length > 0"
        class="panel-reminder-coder-timeline__qualifications is-column is-align-items-center is-full-width"
      >
        <qualifications-item
          v-for="qualification in showedQualifications"
          :key="qualification._id"
          :can-edit-qualif="!isUrgencyEditable"
          :is-new-note="userType.includes('coders')"
          :qualification="qualification"
          class="panel-reminder-coder-timeline__qualifications-item"
          @note-deleted="deleteQualification"
          @note-edited="editQualification"
        >
        </qualifications-item>
        <button
          v-if="qualificationsCount > 0"
          class="panel-reminder-coder-timeline__qualifications-button is-underline font-size-s"
          data-button-see-more
          @click="increaseDisplayedQualifications"
        >
          {{ $t('candidates.see-old-qualifications') }}    ({{ qualificationsCount }})
        </button>
      </div>
      <div
        v-if="process.length > 0"
        class="panel-reminder-coder-timeline__qualifications is-column is-align-items-center is-full-width"
      >
        <process-item
          v-for="processes in showedProcess"
          :key="processes._id"
          :process="processes"
          class="panel-reminder-coder-timeline__qualifications-item"
          @note-deleted="deleteProcess"
          @note-edited="editProcess"
        >
        </process-item>
        <button
          v-if="ProcessesCount > 0"
          class="panel-reminder-coder-timeline__qualifications-button is-underline font-size-s"
          data-button-see-more
          @click="increaseDisplayedProcesses"
        >
          {{ $t('candidates.see-old-processes') }} ({{ ProcessesCount }})
        </button>
      </div>
    </div>
    <panel-timeline
      :id="profile._id"
      :identifier="timelineIdentifier"
      :page="timelinePage"
      :timeline="timeline"
      :user-type="userType"
      @note-deleted="deleteNote"
      @note-edited="editNote"
      @timeline-incomplete="incrementPage"
      @timeline-loaded="loadTimeline"
    >
    </panel-timeline>
    <slot :update="editQualification"></slot>
  </div>
</template>

<script lang="ts">
  import { mapGetters, mapState } from 'vuex';
  //
  import PanelTimeline from '@/components/Panel/PanelTimeline';
  import { noteController } from '@/managers/notes/controller';
  import QualificationsItem from '@/components/Qualifications/QualificationsItem';
  import ProcessItem from '@/components/ProcessRecruitment/ProcessItem';
  import NotesActions from '@/components/Notes/NotesActions.vue';
  import TimelineEditor from '@/components/Timeline/TimelineEditor.vue';
  import TimelineItemWrapper from '@/components/Timeline/TimelineItemWrapper';
  import notes, { hasQualificationsWithRating } from '@/common-old/macros/notes';
  //
  import MetadatasMixin from '@/mixins/metadatas';
  //
  import pipe from '@/macros/pipe';
  import moment from 'moment/min/moment-with-locales';

  export default {
    name: 'panel-reminder-coder-timeline',
    components: {
      QualificationsItem,
      PanelTimeline,
      ProcessItem,
      NotesActions,
      TimelineEditor,
      TimelineItemWrapper,
    },
    props: {
      userType: {
        type: String,
        default: '',
      },
      profile: {
        type: Object,
        default: () => ({}),
      },
    },
    mixins: [MetadatasMixin],
    data() {
      return {
        noteType: undefined,
        isCreatingNote: false,
        qualifications: [],
        process: [],
        displayedQualifications: 1,
        displayedProcesses: 1,
        timeline: [],
        timelinePage: 1,
        timelineIdentifier: +new Date(),
        noteTypeTracking: false,
        reminderData: undefined,
      };
    },
    computed: {
      ...mapGetters({
        cardActive: 'cardActive',
      }),
      ...mapState({
        coachData: store => store.user.profile,
      }),
      canCreateNote() {
        return (
          this.$route.params.id !== 'profile' &&
          this.$route.query.type !== 'coaches'
        );
      },
      noteTypeAction() {
        if (this.noteType === notes.type.note.name) {
          return { icon: 'pen-square' }
        }
        if (this.noteType === notes.type.call.name) {
          return { icon: 'phone' }
        }
        if (this.noteType === notes.type.email.name) {
          return { icon: 'mail' }
        }
        if (this.noteType === notes.type.linkedin.name) {
          return { source: 'linkedin' }
        }
        if (this.noteType === notes.type.whatsapp.name) {
          return { source: 'whatsapp' }
        }
        if (this.noteType === notes.type.reminder.name) {
          return { icon: 'calendar-circle-plus' }
        }
        return { icon: 'pen-square' }
      },
      isSuperUser() {
        return this.$store.getters['user/isSuperUser'];
      },
      isShappers() {
        return this.$store.getters['user/isShappers'];
      },
      isReminderVisible() {
        return this.isSuperUser || this.isShappers;
      },
      isUrgencyEditable() {
        if (this.timeline && this.timeline.length > 0 && this.timeline[0]._customJob) {
          return this.timeline[0]._customJob._owners.map(owner => owner._id || owner).includes(this.coachData._id);
        }

        return false;
      },
      showedQualifications() {
        return this.qualifications.filter((contact, index) => index < this.displayedQualifications);
      },
      showedProcess() {
        return this.process.filter((contact, index) => index < this.displayedProcesses);
      },
      isAllActivitiesFilterActive() {
        return this.$route.query['all-activities'];
      },
      isProcessActivitiesFilterActive() {
        return this.$route.query['processes-activities'];
      },
      isCoachActivitiesFilterActive() {
        return this.$route.query['coaches-activities'];
      },
      isUsersActivitiesFilterActive() {
        return this.$route.query['users-activities'];
      },
      qualificationsCount() {
        return this.qualifications.length - this.showedQualifications.length;
      },
      ProcessesCount() {
        return this.process.length - this.showedProcess.length;
      },
      hasQualifications() {
        return hasQualificationsWithRating.includes(this.$route.query.type);
      },
    },
    beforeCreate() {
      this.$store.dispatch('emptyViewList');
    },
    beforeDestroy() {
      this.$store.dispatch('emptyViewList');
    },
    watch: {
      'profile._id': {
        handler() {
          this.resetInfiniteScroll();

          this.displayedQualifications = 1;
          this.displayedProcesses = 1;

          if (this.hasQualifications) {
            this.getQualifications();
          }
        },
        immediate: true,
        deep: true,
      },
      isAllActivitiesFilterActive() {
        this.resetInfiniteScroll();
      },
      isProcessActivitiesFilterActive() {
        this.resetInfiniteScroll();
      },
      isCoachActivitiesFilterActive() {
        this.resetInfiniteScroll();
      },
      isUsersActivitiesFilterActive() {
        this.resetInfiniteScroll();
      },
    },
    methods: {
      onNotesAction(type) {
        if (type !== 'reminder') {
          this.onReminderData()
        }
        this.noteType = type;
        this.setIsCreatingNote(null, true, type !== 'call')
      },
      onReminderData(date?: Date, profileType?: string) {
        this.reminderData = date && profileType ? { date, profileType } : undefined;
      },
      goCreateAlarm() {
        const title = `${this.$i18n.t('companies.panel-view-timeline.recall')} ${
          this.profile.firstName
            ? `${this.profile.firstName} ${this.profile.lastName}`
            : `${this.profile.name}`
        }`;
        const route = this.$route.fullPath.replace(/&/gi, '%26');
        const link = `${window.location.origin}${route}`.split('?')[0];
        const linkType = this.userType === 'partners' ? 'companies' : this.userType;
        const description = `${this.$i18n.tc('generics.number', 1)}: ${
          this.profile.phone ? this.profile.phone : this.$i18n.tc('generics.number', 0)
        } - ${this.$i18n.t('companies.panel-view-timeline.coach-link')}: ${link}?type=${linkType}`;
        const startDate = moment()
        .add(2, 'days')
        .format('YYYYMMDD');

        window.open(
          `https://calendar.google.com/calendar/r/eventedit?text=${title}&details=${description}&location=location&dates=${startDate}T074500Z/${startDate}T081500Z`,
          '_blank',
        );
      },

      async getQualifications() {
        const {
          data: { qualifications },
        } = await noteController.get({
          userType: 'coders',
          id: this.profile._id,
          type: 'qualifications',
        });

        this.qualifications = qualifications;
      },
      increaseDisplayedQualifications() {
        const count = 2;

        this.displayedQualifications += count;
      },
      increaseDisplayedProcesses() {
        const count = 2;

        this.displayedProcesses += count;
      },
      incrementPage() {
        ++this.timelinePage;
      },
      setIsCreatingNote(e, value, isTracking) {
        if (e) {
          e.stopPropagation();
        }

        this.isCreatingNote = value;

        if (value) {
          this.noteTypeTracking = isTracking;
        }
      },
      setTimelineFilters(query) {
        const queryState =
          !this.$route.query[query] || this.$route.query[query] === 'false' ? 'true' : undefined;

        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            [query]: queryState,
            ...(query !== 'all-activities' &&
              this.$route.query['all-activities'] && { ['all-activities']: queryState }),
          },
        });

        if (query === 'all-activities') {
          this.$router.push({
            ...this.$route,
            query: {
              ...this.$route.query,
              ['coaches-activities']: this.$route.query['all-activities'],
              ['users-activities']: this.$route.query['all-activities'],
              ['processes-activities']: this.$route.query['all-activities'],
            },
          });
        }
      },
      editQualification(note) {
        if (note) {
          const index = this.qualifications.findIndex(
            qualification => qualification._id === note._id,
          );

          if (note.type === notes.type.qualification.api) {
            this.$set(this.qualifications, index, { ...this.qualifications[index], ...note });

            if (this.cardActive >= 0) {
              this.$store.dispatch('updateCard', {
                index: this.cardActive,
                content: {
                  lastRating: {
                    _coach: note._coach,
                    ...(note.rating && { rating: note.rating }),
                    ...(note.urgency && { urgency: note.urgency }),
                  },
                },
              });
            }
          } else {
            this.qualifications.splice(index, 1);

            const actionIndex = this.timeline.findIndex(action => action?._note?._id === note?._id);

            this.timeline[actionIndex]._note = note;
          }
          this.$emit('qualification-created', note);
        }
      },
      editProcess(note) {
        if (note) {
          const index = this.process.findIndex(process => process._id === note._id);

          if (note.type === notes.type.process.api) {
            this.$set(this.process, index, { ...this.process[index], ...note });

            if (this.cardActive >= 0) {
              this.$store.dispatch('updateCard', {
                index: this.cardActive,
                content: {
                  lastRating: {
                    _coach: note._coach,
                    ...(note.rating && { rating: note.rating }),
                    ...(note.urgency && { urgency: note.urgency }),
                  },
                },
              });
            }
          } else {
            this.process.splice(index, 1);

            const actionIndex = this.timeline.findIndex(action => action?._note?._id === note?._id);

            this.timeline[actionIndex]._note = note;
          }
          this.$emit('qualification-created', note);
        }
      },
      editNote(action) {
        const editedNoteIndex = this.timeline.findIndex(a => a._id === action._id);

        if (action?.note?.type === notes.type.qualification.api) {
          const note = {
            ...this.timeline[editedNoteIndex]._note,
            ...action.note,
          };

          this.qualifications.unshift(note);

          this.$emit('qualification-created', note);
        }

        this.timeline[editedNoteIndex]._note = {
          ...this.timeline[editedNoteIndex]._note,
          ...action.note,
        };
      },
      handleNoteSaved(action) {
        if (action?._note?.type === notes.type.qualification.api) {
          this.qualifications.unshift(action._note);

          this.$emit('qualification-created', action._note);
        } else {
          if (action?._note?.dateToContact) {
            this.$emit('reminder-created', action._note);
          } else {
            this.$emit('echange-created', action._note);
          }
        }

        this.handleUpdate(action);

        this.timeline.unshift(action);
      },
      deleteQualification(id) {
        const index = this.qualifications.findIndex(qualification => qualification._id === id);

        this.qualifications.splice(index, 1);

        if (this.cardActive >= 0) {
          this.$store.dispatch('updateCard', {
            index: this.cardActive,
            content: {
              lastRating: {},
            },
          });
        }

        this.deleteNote(id);
      },
      deleteProcess(id) {
        const index = this.process.findIndex(process => process._id === id);

        this.process.splice(index, 1);

        if (this.cardActive >= 0) {
          this.$store.dispatch('updateCard', {
            index: this.cardActive,
            content: {
              lastRating: {},
            },
          });
        }

        this.deleteNote(id);
      },
      deleteNote(id) {
        const index = this.timeline.findIndex(qualification => qualification?._note?._id === id);

        this.timeline.splice(index, 1);
      },
      loadTimeline(timeline) {
        this.timeline = timeline;
      },
      resetInfiniteScroll() {
        this.timeline = [];
        this.timelinePage = 1;
        this.timelineIdentifier += 1;
      },
      handleUpdate(note) {
        if (this.$route.query.type === 'suggestions-coders') {
          this.$_updateCandidatePipeMetadatas(
            this.profile.coachProcessState,
            note._coder.coachProcessState,
          );

          this.$emit('profile-updated', {
            coachProcessState: {
              status: pipe.expected.toProcess,
            },
            processState: {
              status: pipe.expected.toProcess,
            },
          });
        }

        this.handleCard(note);
      },
      handleCard(note) {
        this.$store.dispatch('updateCard', {
          index: this.cardActive,
          content: {
            ...(this.$route.query.type === 'coders' && {
              coachProcessState: { status: pipe.expected.toProcess },
              processState: { status: pipe.expected.toProcess },
            }),
            lastRating: {
              _coach: note._coach,
              rating: note.rating,
            },
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-reminder-coder-timeline {
    padding: 15px 30px;

    div:first-child {
      button.bc-button__primary:nth-child(1) {
        margin-right: 8px;
      }
    }

    &__container {
      align-items: baseline;

      .bc-button--alarm {
        margin-left: 15px;
      }
    }

    &__actions {
      flex: 1;
      justify-content: space-between;
      align-items: start;
      margin-right: 20px;
    }

    &__wrapper {
      margin-left: auto;
      background: $color-grey-2;
      border: 1px solid $color-grey-2;
    }

    &__qualifications {
      margin-top: 20px;
    }

    &__button {
      padding: 12px 15px;
      color: $color-grey-4;
      transition: 0.1s;

      &:hover {
        background: $color-grey-3;
      }

      &--active,
      &--active:hover {
        color: $color-secondary;
        background: $color-white;
      }

      &:first-of-type {
        border-top-left-radius: $border-radius-s;
        border-bottom-left-radius: $border-radius-s;
      }

      &:last-of-type {
        border-top-right-radius: $border-radius-s;
        border-bottom-right-radius: $border-radius-s;
      }
    }

    &__qualifications-button {
      margin-top: 15px;
      color: $color-primary;

      &:hover {
        text-decoration: none;
      }
    }

    &__qualifications-item {
      width: 100%;
      margin-top: 10px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &__editor {
      width: 100%;
      margin-left: 20px;
      border: 1px solid $color-primary;
      border-radius: $border-radius-m;
    }
  }
</style>
