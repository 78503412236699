<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router/composables';
import { useStore } from '@/store';
import { getDashboardCandidatesMultiposting } from '@/api/dashboard';

import ProcessesActivities from '@/components/Processes/ProcessesActivities';
import NotificationsListContent from './NotificationsListContent';
import ViewsTabs from '@/components/Views/ViewsTabs';

const store = useStore();
const router = useRouter();
const active = ref('inbox');

const id1 = + new Date() + Math.random();
const id2 = + new Date() + Math.random();

const profileId = computed(() => {
  return store.state.user.profile?._id;
});

const notificationsListCandidates = computed(() => {
  return store.getters['mainNotifications/notificationsListCandidates'];
});

const notificationsListWithoutCandidates = computed(() => {
  return store.getters['mainNotifications/notificationsListWithoutCandidates'];
});

const unreadInboxNotifications = computed(() => {
  return store.getters['mainNotifications/unreadInboxNotifications'];
})

const tabs = ref([
  {
    key: 'inbox',
    label: 'Inbox',
    icon: 'inbox',
    onClick: () => {
      active.value = 'inbox';
    },
    count: unreadInboxNotifications.value?.length || undefined,
  },
  {
    key: 'candidates',
    label: 'Candidatures',
    icon: 'file-cv',
    onClick: () => {
      active.value = 'candidates';
    },
    count: undefined,
  },
  {
    key: 'activities',
    label: 'Activités',
    icon: 'trophy',
    onClick: () => {
      active.value = 'activities';
    },
    count: undefined,
  },
]);

// Life Cycles
onMounted(async () => {
  const { data } = await getDashboardCandidatesMultiposting({});
  if (data.nbWaitingCandidates) {
    const t = [...tabs.value];
    t[1].count = data.nbWaitingCandidates;
    tabs.value = [...t];
  }
});

// Methods
const onPaginateCandidates = async ($state) => {
  const data = await store.dispatch('mainNotifications/getCoachNotifications', {
    coachId: profileId.value,
    filter: ['candidates'],
  });
  if (Object.keys(data).length) {
    $state.loaded();
  } else {
    $state.complete();
  }
}

const onPaginateWithoutCandidates = async ($state) => {
  const data = await store.dispatch('mainNotifications/getCoachNotifications', {
    coachId: profileId.value,
    filter: ['withoutCandidates'],
  });
  if (Object.keys(data).length) {
    $state.loaded();
  } else {
    $state.complete();
  }
}

const navigateToCandidates = () => {
  router.push({
    name: 'Applications',
  });
}

// Watch
watch(unreadInboxNotifications, (value) => {
  const t = [...tabs.value];
  t[0].count = value?.length;
  tabs.value = [...t];
});
</script>

<template>
  <div class="flex flex-col">
    <div>
      <ViewsTabs :tabs="tabs" :forRoute="false" :active="active"></ViewsTabs>
    </div>
    <div class="h-full overflow-auto max-h-[300px]">
      <NotificationsListContent
        v-if="active === 'inbox'"
        :notifications="notificationsListWithoutCandidates"
        :timeline-identifier="id1"
        @on-paginate="onPaginateWithoutCandidates"
      ></NotificationsListContent>

      <NotificationsListContent
        v-if="active === 'candidates'"
        :notifications="notificationsListCandidates"
        :timeline-identifier="id2"
        @on-paginate="onPaginateCandidates"
      >
        <template #footer>
          <button class="text-sm text-blue-500" @click="navigateToCandidates">
            Accéder à toutes les candidatures
          </button>
        </template>
      </NotificationsListContent>

      <ProcessesActivities v-if="active === 'activities'"></ProcessesActivities>
    </div>
  </div>
</template>
