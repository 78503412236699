<template>
  <div
    class="panel-tag-header bg-neutral-200 is-full-width is-justify-content-space-between is-align-items-center">
    <panel-header-nav></panel-header-nav>
    <panel-header-date
      :date="tag.createdAt"
      class="panel-tag-header__date"
      text="Date :">
    </panel-header-date>
    <bc-button
      class="panel-organization-header__button"
      color="error"
      icon-left="trash"
      radius-size="s"
      size="extra-small"
      type="flat"
      @click="setIsDeletingOrganization(true)">
      {{ $t("tags.delete-tag") }}
    </bc-button>
    <bc-modal
      :active="isDeletingOrganization"
      @close="closeModal">
      <tag-modal-delete
        v-if="isDeletingOrganization"
        :tag="tag"
        @close-modal="cancelDelete"
        @tag-deleted="confirmDelete">
      </tag-modal-delete>
    </bc-modal>
  </div>
</template>

<script>
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import PanelHeaderDate from '@/components/Panel/PanelHeader/PanelHeaderDate';
  import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import BcModal from '@/ui-kit/components/BcModal/BcModal';
  import TagModalDelete from '@/components/Tag/TagModalDelete.vue';

  export default {
    name: 'panel-organization-header',
    components: {
      TagModalDelete,
      BcModal,
      BcButton,
      PanelHeaderNav,
      PanelHeaderDate,
    },
    mixins: [PanelNavigation],
    props: {
      tag: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        isDeletingOrganization: false,
      };
    },
    methods: {
      setIsDeletingOrganization(value) {
        this.isDeletingOrganization = value;
      },
      closeModal() {
        this.cancelDelete();
      },
      cancelDelete() {
        this.setIsDeletingOrganization(false);
      },
      confirmDelete() {
        this.$emit('tag-deleted');

        this.setIsDeletingOrganization(false);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-tag-header {
    padding: 10px 15px;
    flex-shrink: 0;
    border-bottom: 1px solid $color-grey-4;
    height: 50px;

    &__date {
      margin-left: 20px;
      margin-right: auto;
    }
  }
</style>
