<template>
  <div class="flex justify-center items-center overflow-hidden rounded-full size-[30px] shrink-0 border-purple-550"
    :class="{ 'border': !props.person?.pictureUrl && !props.defaultAvatarSrc }">
    <img v-if="props.person?.pictureUrl || props.defaultAvatarSrc" 
      :src="currentAvatarSrc"
      alt="person-avatar" 
      @error="handleError" 
      class="flex">
    <span v-else class="text-base text-purple-550 font-bold">
      {{ userInitials }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { getInitials } from '@/helpers/getIntials';

const props = defineProps<{
  person?: {
    firstName: string,
    lastName: string,
    pictureUrl?: string,
  },
  defaultAvatarSrc?: string,
}>();

const emit = defineEmits<{
  (event: 'error', errorData: any): void
}>();

const isError = ref(false);

const currentAvatarSrc = computed(() => {
  if (isError.value || !props.person?.pictureUrl) {
    return props.defaultAvatarSrc;
  }
  return props.person.pictureUrl;
});

const userInitials = computed(() => {
  return props.person ? getInitials(`${props.person.firstName} ${props.person.lastName}`) : '';
});

const handleError = (errorData: any) => {
  isError.value = true;
  emit('error', errorData);
};
</script>
