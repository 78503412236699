<template>
  <views class="integrations">
    <admin-tabs :integrations-count="cards.length">
      <bc-button
        class="integrations__button ml-auto mr-3"
        icon-left="plus"
        @click.native="createIntegrationModal">
        Créer une intégration
      </bc-button>
    </admin-tabs>
    <router-view></router-view>
    <views-container>
      <bc-spinner
        v-if="!isLoaded"
        class="integrations__spinner">
      </bc-spinner>
      <div
        v-else
        class="integrations__cards is-justify-content-center">
        <card
          v-for="card in cards"
          :key="card._id"
          :card-content="card"
          :is-full-width="false"
          class="integrations__card"
          @click="updateIntegrationModal(card)">
          <div class="integrations__card-content">
            <div>
              {{ card.name }}
            </div>
            <div
              @mouseenter="isHoverDelete = true"
              @mouseleave="isHoverDelete = false"
            >
              <bc-button
                iconCenter="trash"
                size="extra-small"
                color="error"
                @click="deleteIntegrationModal(card._id)"
              ></bc-button>
            </div>
          </div>
        </card>
      </div>
    </views-container>
    <bc-modal
      :active="isModalOpen"
      @close="cancelModal">
      <div
        v-if="modal === 'create'"
        class="integrations__create-modal"
      >
        <h3 class="mb-1qsd">Créer une intégration</h3>
        <bc-input label="Nom" name="create-name" v-model="create.name"></bc-input>
        <p>Configuration :</p>
        <textarea
          class="integrations__textarea"
          placeholder="Configuration"
          :value="showJson(create.config)"
          @input="updateCreateConfig"
          rows="25"
        >
        </textarea>
        <input label="config" type="textarea" rows="25" />
        <div class="is-full-width">
          <bc-button class="is-full-width" @click="addIntegration">Créer</bc-button>
        </div>
      </div>
      <div
        v-if="modal === 'update'"
        class="integrations__create-modal"
      >
        <h3 class="mb-1qsd">Mettre à jour une intégration</h3>
        <bc-input label="Nom" name="update-name" v-model="update.name"></bc-input>
        <p>Configuration :</p>
        <textarea
          class="integrations__textarea"
          placeholder="Configuration"
          :value="showJson(update.config)"
          @input="updateConfig"
          rows="25"
        >
        </textarea>
        <input label="config" type="textarea" rows="25" />
        <div class="is-full-width">
          <bc-button class="is-full-width" @click="updateIntegration">Mettre à jour</bc-button>
        </div>
      </div>
      <div
        v-if="modal === 'delete'"
        class="integrations__delete-modal"
      >
        <h3 class="mb-1">Supprimer une intégration</h3>
        <p>Confirmer la suppression ?</p>
        <div class="integrations__delete-buttons">
          <bc-button @click="cancelModal" type="outlined">Annuler</bc-button>
          <bc-button @click="deleteIntegration" color="error">Confirmer</bc-button>
        </div>
      </div>
    </bc-modal>
  </views>
</template>

<script>
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import BcInput from '@/ui-kit/components/BcInput/BcInput';
  import Card from '@/components/Card/Card';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import BcModal from '@/ui-kit/components/BcModal/BcModal';
  import { getIntegrations, addIntegration, updateIntegration, deleteIntegration } from '@/api/integrations';
  import Views from '@/components/Views/Views';
  import ViewsContainer from '@/components/Views/ViewsContainer';
  import AdminTabs from '@/views/Tabs/AdminTabs';

  export default {
    name: 'industries',
    components: {
      ViewsContainer,
      Views,
      BcModal,
      BcButton,
      Card,
      BcSpinner,
      AdminTabs,
      BcInput,
    },
    data() {
      return {
        isLoaded: true,
        currentPage: parseInt(this.$route.query.page) || 1,
        cards: [],
        isModalOpen: false,
        modal: '',
        selectedCard: {},
        create: {
          name: '',
          config: '',
        },
        update: {
          name: '',
          config: '',
        },
        deleteId: '',
        isHoverDelete: false,
      };
    },
    created() {
      this.getIntegrations();
    },
    watch: {
      currentPage(newPage) {
        this.$router.push({ ...this.$route, query: { ...this.$route.query, page: newPage } });
        this.getIntegrations();
      },
    },
    methods: {
      resetCards() {
        this.cards = [];
      },
      editIntegration(card) {
        this.selectedCard = card;
        this.modal = 'edit';
        this.isModalOpen = true;
      },
      goLinkDomainsAndProfessions(card) {
        this.selectedCard = card;
        this.modal = 'link';
        this.isModalOpen = true;
      },
      createIntegrationModal() {
        this.create = {
          name: '',
          config: '',
        }
        this.modal = 'create';
        this.isModalOpen = true;
      },
      updateIntegrationModal(integration) {
        if (this.isHoverDelete) {
          return;
        }
        this.update = {
          name: integration.name,
          config: integration.config ? JSON.stringify(integration.config) : '',
        }
        this.modal = 'update';
        this.isModalOpen = true;
      },
      deleteIntegrationModal(id) {
        this.deleteId = id;
        this.modal = 'delete';
        this.isModalOpen = true;
      },
      async addIntegration() {
        try {
          const { data } = await addIntegration({ name: this.create.name, config: this.create.config });
          this.cards = [...this.cards, data];
          this.$toast.show({
            type: 'success',
            message: 'Intégration ajouté avec succès'
          });
          this.cancelModal()
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err.message,
          });
        }
      },
      async updateIntegration() {
        try {
          const { data } = await updateIntegration({ name: this.update.name, config: this.update.config });
          const cards = [...this.cards];
          const index = cards.findIndex(card => card._id === data._id);
          if (index !== -1) {
            cards[index] = data;
          }
          this.cards = cards;
          this.cancelModal()
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err.message,
          });
        }
      },
      async deleteIntegration() {
        try {
          await deleteIntegration(this.deleteId);
          this.cards = [...this.cards.filter(card => card._id !== this.deleteId)];
          this.cancelModal()
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err.message,
          });
        }
      },
      updateCreateConfig(e) {
        this.create.config = e.target.value
      },
      updateConfig(e) {
        this.update.config = e.target.value
      },
      showJson(value) {
        if (!value) return ''
        return JSON.stringify(JSON.parse(value), undefined, 4);
      },
      cancelModal() {
        this.modal = '';
        this.isModalOpen = false;
        this.selectedCard = {};
        this.create = {
          name: '',
          config: '',
        }
        this.update = {
          name: '',
          config: '',
        }
        this.deleteId = '';
      },
      confirmCreate(industry) {
        this.cards.unshift(industry);

        this.cancelModal();
      },
      confirmEdit(industry) {
        this.cards.unshift(industry);

        this.cancelModal();
      },
      async getIntegrations() {
        this.isLoaded = false;

        try {
          this.resetCards();

          const { data } = await getIntegrations();

          this.cards = data;

          this.isLoaded = true;
        } catch (error) {
          this.$emit('error', error);

          this.isLoading = true;
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (vm.$store.getters['user/isMarvinRecruiter']) {
          next();
        } else {
          next('/');
        }
      });
    },
  };
</script>

<style lang="scss" scoped>
  .integrations {
    &__title {
      min-height: 30px;
      margin-bottom: 15px;
    }

    &__searchbar {
      margin-bottom: 20px;
    }

    &__filters {
      margin-bottom: 20px;
    }

    &__pagination {
      margin: 20px 0;
    }

    &__spinner {
      margin: 100px 0;
    }

    &__card-list {
      margin-bottom: 100px;
    }

    &__spinner, &__pagination, &__card-list {
      justify-content: center;
    }

    &__cards {
      margin: -10px;
      flex-wrap: wrap;
    }

    &__card {
      min-width: 100%;
      margin: 10px;
    }

    &__create-modal {
      flex-direction: column;
      padding: 8%;
      min-height: 600px;
      min-width: 800px;
      gap: 20px;
    }

    &__delete-modal {
      flex-direction: column;
      padding: 8%;
      min-height: 200px;
      min-width: 800px;
      gap: 20px;
    }

    &__delete-buttons {
      gap: 10px;
      justify-content: center;
    }

    &__textarea {
      border: 1px solid $color-blue-medium;
      border-radius: 5px;
      padding: 10px;
    }

    &__card-content {
      width: 100%;
      padding: 10px 20px;
      align-items: center;
      justify-content: space-between;
    }
  }
</style>
