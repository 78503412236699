<template>
  <div
    class="panel-caption-icons"
    v-if="filteredInfos.length > 0">
    <div
      :key="index"
      class="panel-caption-icons__items"
      v-for="(info, index) in filteredInfos">
      <icon-labeled
        :icon="info.icon"
        :label="$t(info.label)"
        :value="$t(info.value)"
        class="panel-caption-icons__icons"
        icon-size="15px"
        label-font-size="12px">
      </icon-labeled>
    </div>
  </div>
</template>

<script>
  // TODO move or refacto to ui-kit
  import IconLabeled from '@/components/UIKit/Icon/IconLabeled';

  export default {
    components: {
      IconLabeled
    },
    props: {
      infos: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      filteredInfos() {
        return this.infos.filter(info => info.value);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .panel-caption-icons {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    @include bp('tablet') {
      flex-direction: row;
      justify-content: flex-start;
    }
    &__items {
      margin: 5px 0;
      align-items: center;
      @include bp('tablet') {
        margin: 3px 0;
      }
    }
    &__icons {
      align-items: center;
      &:last-of-type {
        margin-right: 0;
      }
    }
    &__li-divider {
      display: none;
      @include bp('tablet') {
        display: flex;
        margin: 0 15px;
      }
      &:before {
        content: "•";
        color: $color-blue-dodger;
        align-items: center;
      }
    }
  }
</style>
