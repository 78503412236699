<template>
  <div
    class="card-coders is-full-width is-column is-overflow-hidden border-radius-m is-relative"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
    @click.exact="openPanel({ id: cardContent._id, type: 'coders', context })"
    @click.meta.exact="openPanelInNewTab({ id: cardContent._id, type: 'coders', context })">
    <div class="card-coders__container is-full-width is-align-items-start is-relative">
      <div class="card-coders__wrapper card-coders__checkbox is-align-items-center" @click.stop>
        <bc-checkbox :id="cardContent._id" :value="isSelected" @input="$emit('checked', $event)">
        </bc-checkbox>
      </div>
      <div class="card-coders__wrapper is-full-width is-column card-coders__id">
        <div class="is-secondary-dark is-align-items-center is-full-width">
          <bc-avatar
            :src="cardContent.pictureUrl"
            class="card-coders__avatar is-unshrink"
            size="s"
            @error="setAlternativeImg">
          </bc-avatar>
          <div class="card-coders__name is-column is-align-items-start">
            <p
              v-tooltip="fullName"
              class="card-coders__text body-l--medium is-ellipsis is-full-width">
              {{ fullName }}
            </p>
            <p
              v-if="cardContent.title"
              v-tooltip="cardContent.title"
              class="card-coders__text body-l is-ellipsis is-full-width">
              {{ profileTitle }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="card-coders__wrapper card-coders__link is-align-items-center hidden-xs hidden-sm items-center gap-2">
        <a
          class="size-[30px] items-center justify-center rounded-full bg-blue-100 hover:cursor-pointer shrink-0 flex hover:bg-blue-200"
          :class="{ 'opacity-50': !cardContent?.social?.linkedin }"
          :href="cardContent?.social?.linkedin"
          target="_blank"
          @click.stop>
          <img class="size-[15px] object-contain" src="@/assets/logo/linkedin.png" />
        </a>
        <v-popover
          class="z-2"
          :disabled="!hasDocument"
          :placement="'right-end'"
          :trigger="'click hover'"
          popover-base-class="z-[9999]"
          popover-inner-class="p-5 h-[60vh]">
          <div
            class="size-[30px] items-center justify-center rounded-full bg-red-50 hover:cursor-pointer hover:bg-blue-200"
            :class="{ 'opacity-50': !hasDocument }"
            @click="openPdfTab">
            <img class="size-[15px] object-contain" src="@/assets/logo/files/pdf.png" />
          </div>
          <template #popover>
            <iframe
              v-if="
                cardContent?._documents?.[0]?.url?.endsWith('.docx') ||
                cardContent?._documents?.[0]?.url?.endsWith('.doc')
              "
              class="h-[70vh] w-[700px]"
              frameborder="0"
              :src="`https://docs.google.com/gview?url=${cardContent?._documents?.[0]?.url}&embedded=true`" />
            <iframe
              v-else
              class="h-[70vh] w-[700px]"
              frameborder="0"
              :src="`${cardContent?._documents?.[0]?.url}`" />
          </template>
        </v-popover>
      </div>
      <div class="card-coders__wrapper card-coders__professions is-align-items-center hidden-xs">
        <card-tags :reference="'professions'" :tags="professions" class="card-coders__tag-wrapper">
          <template #tag="{ tag }">
            <tag v-tooltip="{ content: tag.name }" :text="tag.name" type="professions">
              {{ tag.name }}
            </tag>
          </template>
          <template #remaining="{ remaining }">
            <tag type="professions" :text="`+${remaining}`" :show-icon="false"></tag>
          </template>
        </card-tags>
      </div>
      <div class="card-coders__wrapper card-coders__skills is-align-items-center">
        <card-tags :reference="'stack'" :tags="stack" class="card-coders__tag-wrapper">
          <template #tag="{ tag }">
            <tag v-tooltip="tag.name" :text="tag.name" type="technos"> </tag>
          </template>
          <template #remaining="{ remaining }">
            <tag type="technos" :text="`+${remaining}`" :show-icon="false"></tag>
          </template>
        </card-tags>
      </div>
      <div class="card-coders__wrapper card-coders__locations hidden-xs hidden-sm">
        <card-tags :reference="'locations'" :tags="locations" class="card-coders__tag-wrapper">
          <template #tag="{ tag }">
            <tag
              v-tooltip="{
                content: [
                  ...new Set([tag.completeAddress ? tag.completeAddress : tag.fullAddress]),
                ].join(', '),
              }"
              :text="tag.fullAddress"
              :text-limit="10"
              type="location">
            </tag>
          </template>
          <template #remaining="{ remaining }">
            <tag type="location" :text="`+${remaining}`" :show-icon="false"></tag>
          </template>
        </card-tags>
      </div>
      <div
        v-tooltip.bottom="ratingTooltip"
        :class="{ 'hidden-xs': !qualifications.length }"
        class="card-coders__wrapper card-coders__rating is-align-items-center">
        <ratings
          v-if="lastQualification.rating"
          :rating="lastQualification.rating"
          class="is-align-items-center"
          color="primary"
          disabled
          is-hidden-button
          star-size="m">
        </ratings>
        <div v-if="qualifications.length" class="card-coders__rating--count ml-1">
          {{ qualifications.length }}
        </div>
      </div>
      <!-- <div
        v-if="arrivalDate"
        class="card-coders__wrapper card-coders__date is-align-items-center hidden-xs hidden-sm">
        {{ arrivalDate }}
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from 'moment/min/moment-with-locales';
import PanelNavigationMixin from '@/common-old/mixins/Panel/panelNavigation';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
import CardTags from '@/components/Card/CardTags';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
import { pipeStatus } from '@/common-old/macros/coders';
import { mapState } from 'vuex';
import Ratings from '@/components/Toolbox/Ratings';
import Tag from '@/components/Tag/Tag';
import { formatPlaceDetailsToBaseLocation } from '@/formatters/locations';

export default {
  name: 'card-coders',
  components: {
    Ratings,
    BcCheckbox,
    CardTags,
    BcAvatar,
    Tag,
  },
  mixins: [PanelNavigationMixin],
  props: {
    cardContent: {
      type: Object,
      default: () => ({}),
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    context: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isHover: false,
    };
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
    }),
    fullName() {
      return `${this.cardContent.firstName} ${this.cardContent.lastName}`;
    },
    professions() {
      return this.cardContent._professions || [];
    },
    stack() {
      return [
        ...this.cardContent._technos3,
        ...this.cardContent._technos2,
        ...this.cardContent._technos1,
      ];
    },
    hasDocument() {
      return this.cardContent?._documents?.length > 0;
    },
    locations() {
      return this.cardContent?.locations?.map(location =>
        formatPlaceDetailsToBaseLocation(location, 'locality'),
      );
    },
    rating() {
      return this.cardContent.lastRating || {};
    },
    followers() {
      return this.cardContent.followers?.slice(0, 1) || [];
    },
    author() {
      return this.cardContent.lastRating?._coach;
    },
    qualifications() {
      return this.cardContent?._qualifications || [];
    },
    lastQualification() {
      return this.qualifications?.at(-1) || this.rating;
    },
    followersTooltip() {
      return {
        content: this.cardContent.followers
          ?.map(follower => `${follower.firstName} ${follower.lastName}`)
          ?.join(', '),
      };
    },
    ratingTooltip() {
      return {
        html: true,
        content: this.lastQualification._coach
          ? `
            <div>
              <img src='${this.lastQualification?._coach?.pictureUrl}' style='width: 20px; height: 20px; border-radius: 100%;' />
              <p style='margin-left: 5px; font-size: 14px; font-weight: 400;'>
                ${this.lastQualification?._coach?.firstName} ${this.lastQualification?._coach?.lastName}
              </p>
            </div>
          `
          : '',
      };
    },
    isCoderInProcess() {
      return (
        this.cardContent.processState &&
        this.cardContent.processState?.status !== pipeStatus.nothing
      );
    },
    profileTitle() {
      return this.cardContent.title === 'New coder' ? '' : this.cardContent.title;
    },
    arrivalDate() {
      return this.cardContent.abortedAt ? moment(this.cardContent.abortedAt).format('L') : '';
    },
  },
  methods: {
    setAlternativeImg(event) {
      event.target.src = require('@/assets/img/default-avatar.svg');
    },
    getUserInitials(follower) {
      return `${follower.firstName?.charAt(0)}${follower.lastName?.charAt(0)}`;
    },
    getLastAuthor() {
      return `${this.author.firstName?.charAt(0)}${this.author.lastName?.charAt(0)}`;
    },
    openLinkedinTab() {
      const linkedinUrl = this.cardContent._coder?.social?.linkedin;
      if (linkedinUrl) {
        window.open(this.cardContent._coder?.social?.linkedin, '_blank');
      }
    },
    openPdfTab() {
      const document = this.cardContent._coder?._documents?.[0];
      if (document) {
        router.push({
          name: 'ApplicationsPanel',
          query: {
            type: 'coders',
            category: 'documents',
          },
          params: {
            id: this.cardContent._id,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-coders {
  height: 58px;

  &:hover {
    cursor: pointer;
  }

  &__container {
    height: 100%;
    padding: 10px 30px;
    flex-direction: column;
    align-items: center;

    @include bp('tablet') {
      flex-direction: row;
    }
  }

  &__wrapper {
    margin: 10px;
    max-height: 100%;
    width: 100%;

    @include bp('tablet') {
      margin: 0 10px;
      width: initial;
    }
  }

  &__id {
    @include coders-grid-name;
  }

  &__avatar {
    margin-right: 10px;
  }

  &__name {
    max-width: calc(100% - 50px);
  }

  &__divider {
    margin: 0.75em 0;
    border-top: 1px solid $color-grey-2;
  }

  &__link {
    @include coders-grid-cv;
    color: $color-success-light;

    a {
      color: $color-success-light;

      &:hover {
        color: $color-secondary-dark;
      }
    }
  }

  &__professions {
    @include coders-grid-domain;
  }

  &__stack {
    @include coders-grid-stack;
  }

  &__skills {
    @include coders-grid-skills;
  }

  &__locations {
    @include coders-grid-locations;
  }

  &__rating {
    @include coders-grid-rating;

    &--count {
      background: $color-blue-medium;
      color: $color-tertiary;
      border-radius: 100%;
      padding: 3px 6px;
      font-size: 11px;
    }
  }

  &__text {
    max-width: 100%;
  }

  &__tag-wrapper {
    width: 100%;
  }

  &__tag {
    max-width: 100%;
    border-radius: 30px;
    color: #3d5476;
    font-weight: 500;
    padding: 4px 8px;
    font-size: 13px;

    &.is-unshrink {
      max-width: initial;
    }
  }

  &__rating-avatar {
    margin-right: 5px;
  }

  &__followers {
    flex-grow: 1;
    @include coders-grid-lists;
  }

  &__follower {
    margin-left: -5px;

    &:first-of-type {
      margin-left: 0;
    }
  }

  &__follower-initials {
    margin-left: -5px;
  }

  &__last-author-initials {
    margin-right: 10px;
  }

  &__icon {
    height: 20px;

    &:hover {
      filter: brightness(0.7);
    }
  }

  &__status {
    height: 5px;
    background: $color-grey-4;
    z-index: 2;

    &--processed {
      background: $color-success;
    }
  }

  &__count {
    background: $color-grey-3;
    color: $color-grey-5;
    height: 28px;
    width: 28px;
  }

  &__buttons {
    align-items: center;
    height: calc(100% - 5px);
    z-index: 1;

    &--pool {
      right: 55%;
    }

    &--pipe {
      right: 5%;
    }
  }

  &__date {
    font-size: 14px;
    color: #1d395e;
  }

  &:deep() {
    .ratings {
      margin: 0;
    }
  }
}
</style>
