<template>
  <div class="panel-process-custom">
    <ProcessesColumnsCustom
      :card-type="cardType" :isDragging="isDragging"
      :processes="processes"
      class="overflow-auto" @dragging="isDragging = $event"
      @card-dragged="getEditableProcessColumn"
      @accept-process="acceptProcess" @reject-process="rejectProcess"
      @move-process="moveProcess"
      @delete-process="deleteProcess" @process-update="updateProcess"
      @abort-process="abortProcess"
      @unabort-process="unabortProcess"
      @profile-updated="$emit('profile-updated', $event)">
      <template #content="{ categoryIndex, stepIndex }">
        <bc-button
          v-if="categoryIndex === 0 && getCreateBtnPosition(0, stepIndex) && showCreateProcess.includes(kanbanType)
          " class="panel-process-custom__button" color="secondary" icon-left="plus-circle"
          type="outlined"
          @click="createProcess">
          {{ $t('process-create.add-a-process') }}
        </bc-button>
      </template>
    </ProcessesColumnsCustom>
    <ModalProcessDeal
      :data="dealModalData" :is-modal-open="!!dealModalData"
      @close-modal="closeModal"
      @move-process="moveProcess({ ...$event, confirm: true })"/>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { mapStores } from 'pinia';
  import { useProcessesStore } from '@/store/pinia/processes';

  import BcButton from '@/ui-kit/components/BcButton';
  import ProcessesColumnsCustom from '../Processes/ProcessesColumnsCustom';

  import ModalProcessDeal from '@/components/Modal/ModalProcess/ModalProcessDeal.vue';

  import moment from 'moment/min/moment-with-locales';
  import { processCustomController } from '@/managers/processes-custom/controller';
  import { getStepNameByProcessStatus } from '@/managers/processes/formatters/processDeserializers';

  export default {
    name: 'panel-process-custom',
    components: {
      BcButton,
      ModalProcessDeal,
      ProcessesColumnsCustom,
    },
    props: {
      profile: {
        default: () => ({
          _id: '',
        }),
        type: Object,
      },
      type: {
        type: String,
        required: true,
        validator(value) {
          return ['coder', 'hunt', 'company'].includes(value);
        },
      },
      shouldRefetch: {
        type: Boolean,
      },
    },
    data() {
      return {
        isDragging: false,
        isDraggingForbidden: false,
        dealModalData: undefined,
        modalProcess: {},
        processes: [],
        showCreateProcess: ['coders', 'suggestions-coders', 'companies', 'hunt'],

      };
    },
    computed: {
      ...mapGetters({
        cardActive: 'cardActive',
        configurationProcess: 'user/configurationProcess',
        isPL: 'user/isPL',
      }),
      ...mapStores(useProcessesStore),
      shouldSendMailOnReject() {
        return this.$store.state.user.profile?._organization?.configuration?.multiposting?.sendEmailAfterApplicationTreated;
      },
      shouldSendMailOnFailure() {
        return this.$store.state.user.profile?._organization?.configuration?.process?.failureEmail;
      },
      options() {
        return {
          ...(this.$route.query['process-starts'] && {
            from: moment(this.$route.query['process-starts'], 'DD-MM-YYYY')
            .startOf('day')
            .toISOString(),
          }),
          ...(this.$route.query['process-ends'] && {
            to: moment(this.$route.query['process-ends'], 'DD-MM-YYYY')
            .endOf('day')
            .toISOString(),
          }),
          ...(this.$route.query['selected-coaches'] && {
            coachIds: this.$route.query['selected-coaches'].join(','),
          }),
        };
      },
      kanbanType() {
        return this.$route.query.type === 'process-kanban' ? this.$route.query.subtype : this.$route.query.type;
      },
      cardType() {
        return `process-card-${this.kanbanType}`;
      },
    },
    methods: {
      ...mapActions({
        setProcessLastUpdate: 'setProcessLastUpdate',
        updatePanelProfile: 'updatePanelProfile',
        updateCard: 'updateCard',
        unshiftCard: 'unshiftCard',
        removeCard: 'removeCard',
        updateCustomJob: 'customJobs/updateCustomJob',
        setModal: 'app/setModal',
        setParams: 'app/setParams',
        setIsPanel: 'app/setIsPanel',
      }),
      async getProcesses() {
        const options = {
          resource: this.type,
          ...this.options,
        };
        if (this.type === 'coder') {
          options.coderId = this.profile._id || this.$route.params.id;
        }
        if (this.type === 'hunt') {
          options.customJobId = this.$route.params.id;
        }
        if (this.type === 'company') {
          options.companyId = this.$route.params.id;
        }
        const { data } = await processCustomController.get(options);
        this.processes = data;
      },
      getStepByQuery(query) {
        const [stepName] = Object.entries(this.steps).find(([, values]) => {
          return values.query.includes(query);
        });

        return this.steps[stepName];
      },
      getCreateBtnPosition(categoryIndex, stepIndex) {
        const categorySteps = this.configurationProcess.categories[categoryIndex]?.steps || [];
        if (categorySteps.length > 1) {
          return stepIndex === 1;
        }
        return stepIndex === 0;
      },
      getEditableProcessColumn(process) {
        if (this.isDragging && process?.item?.className?.includes('forbidden')) {
          this.isDraggingForbidden = true;
        }
      },
      createProcess() {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            type: 'create-process',
            from: this.kanbanType,
            suggestionId: this.kanbanType === 'suggestions-coders' ? this.$route.params.id : undefined,
          },
          ...(this.kanbanType === 'suggestions-coders' && {
            params: {
              id: this.profile?._id,
            },
          }),
        });
      },
      updateProcess({ updatedProcess }) {
        this.setProcessLastUpdate();

        // // Get process by id in step processes
        const processIndex = this.processes.findIndex(process => process._id === updatedProcess._id);
        if (processIndex !== -1) {
          this.$set(this.processes, processIndex, updatedProcess);
        }

        this.updatePanelProfile({
          processState: updatedProcess._coder.processState,
          coachProcessState: updatedProcess._coder.coachProcessState,
        });
        this.$emit('profile-updated', {
          processState: updatedProcess._coder.processState,
          coachProcessState: updatedProcess._coder.coachProcessState,
        });

        if (this.cardActive >= 0) {
          this.updateCard({ index: this.cardActive, content: updatedProcess._coder });
        }
      },
      async acceptProcess({ process, step }) {
        const accept = async() => {
          await this.moveProcess({
            processId: process._id,
            step: {
              categoryKey: this.configurationProcess.categories[0].key,
              stepKey: this.configurationProcess.categories[0].steps[1].key,
            },
          });
        };

        console.log('step', step);
        if (step.email) {
          await this.$store.dispatch('emails/setEmailSentCallback', accept);
          this.openEmailPanel({
            coder: process._coder,
            customJob: process._customJob,
            actionId: process?.action?._id,
            isApplication: true,
          });
        } else {
          await accept();
        }
      },
      async rejectProcess({ process, step }) {
        const reject = async() => {
          try {
            const data = await this.processesStore.onReject({ processId: process._id });
            this.updateProcess({
              updatedProcess: {
                ...process,
                rejectedAt: data.rejectedAt,
                abortedAt: data.abortedAt,
              },
            });
          } catch (e) {
            this.$toast.show({
              type: 'error',
              message: e.message,
            });
          }
        };

        if (this.shouldSendMailOnReject || this.shouldSendMailOnFailure || step.email) {
          await this.$store.dispatch('emails/setEmailSentCallback', reject);
          this.openEmailPanel({
            coder: process._coder,
            customJob: process._customJob,
            actionId: process?.action?._id,
            isApplication: true,
          });
        } else {
          await reject();
        }
      },
      async deleteProcess(processId) {
        this.processes = [...this.processes.filter(process => process._id?.toString() !== processId?.toString())];
      },
      getStepConfig({ categoryKey, stepKey }) {
        let stepConfig = {};
        const category = this.configurationProcess.categories.find(category => category.key === categoryKey);
        if (category) {
          stepConfig = category.steps.find(step => step.key === stepKey);
        }
        return stepConfig;
      },
      async moveProcess({ processId, step, confirm }) {
        try {
          const process = this.processes.find(process => process._id === processId);

          const shouldConfirmDealFirst =
            this.configurationProcess?.processSuccessValidation?.activated &&
            step.categoryKey === 'afterProcessRecruitment' &&
            !process.dealAcceptedAt && !confirm;

          if (shouldConfirmDealFirst) {
            this.dealModalData = { process, step };
            return;
          }

          const stepConfig = this.getStepConfig({
            categoryKey: step.categoryKey,
            stepKey: step.stepKey,
          });

          const updatedProcess = await this.processesStore.onUpdateStep({
            processId,
            categoryKey: step.categoryKey,
            stepKey: step.stepKey,
          });
          if (step.categoryKey === 'afterProcessRecruitment') {
            await this.getProcesses();
          }
          if (updatedProcess._customJob) {
            const customJob = await this.updateCustomJob({
              id: updatedProcess._customJob?._id,
              nbActiveProcesses: updatedProcess._customJob?.nbActiveProcesses,
              withActions: false,
            });
            this.$emit('on-update', customJob);
          }
          this.updateProcess({ updatedProcess });
          if (stepConfig.email) {
            this.openEmailPanel({
              coder: process._coder,
              customJob: updatedProcess._customJob,
              actionId: updatedProcess?.action?._id,
              isApplication: true,
            });
          }
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      openEmailPanel({ coder, customJob, actionId, isApplication }) {
        this.setParams({
          coder,
          customJob,
          actionId,
          emails: [coder.email, ...(coder.emails || [])].filter(Boolean),
          isCompany: false,
          isApplication,
        });
        this.setIsPanel(true);
        this.setModal('modal-email-send-form');
      },
      closeModal() {
        this.dealModalData = undefined;
        this.$router.replace({
          query: {
            ...this.$route.query,
            typing: undefined,
          },
        });
      },
      async failedProcess(id) {
        const step = getStepNameByProcessStatus(this.process.status);
        const updatedProcess = await processCustomController.move(id, 'failed');

        this.updatePanel(updatedProcess, step);
      },
      async unfailedProcess(id) {
        const step = getStepNameByProcessStatus(this.process.status);
        const updatedProcess = await processCustomController.move(id, 'unfailed');

        this.updatePanel(updatedProcess, step);
      },
      updatePanel(updatedProcess, step) {
        this.$emit('profile-updated', {
          processState: updatedProcess._coder.processState,
          coachProcessState: updatedProcess._coder.coachProcessState,
        });
        this.$emit('process-update', { updatedProcess: updatedProcess, stepName: step });
      },
      abortProcess(processId) {
        const index = this.processes.findIndex(process => process._id === processId);
        if (index !== -1) {
          const processes = [...this.processes];
          processes[index] = { ...processes[index], abortedAt: new Date() };
          this.processes = processes;
        }
      },
      unabortProcess(processId) {
        const index = this.processes.findIndex(process => process._id === processId);
        if (index !== -1) {
          const processes = [...this.processes];
          processes[index] = { ...processes[index], abortedAt: undefined };
          this.processes = processes;
        }
      },
    },
    watch: {
      'profile._id': {
        handler() {
          this.getProcesses();
        },
        immediate: true,
      },
      'shouldRefetch': {
        handler() {
          this.getProcesses();
        },
        immediate: false,
      },
      '$route.query': {
        handler(to, from = {}) {
          const isDifferentProcessStarts = to['process-starts'] !== from['process-starts'];
          const isDifferentProcessEnds = to['process-ends'] !== from['process-ends'];
          const isDifferentSelectedCoaches = to['selected-coaches'] !== from['selected-coaches'];

          if (isDifferentProcessEnds || isDifferentProcessStarts || isDifferentSelectedCoaches) {
            this.getProcesses();
          }
        },
        deep: true,
      },
      isDragging() {
        if (!this.isDragging && this.isDraggingForbidden) {
          this.isDraggingForbidden = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-process-custom {
    flex-direction: column;
    flex: 1;
    max-height: calc(100% - 57px);

    & > div {
      height: 100%;
    }

    &__button {
      background-color: white;
      width: calc(100% - 20px);
      margin: 10px;
      font-size: $font-size-s;
      color: $color-primary;
      border: 1px solid $color-blue-medium;

      &:hover {
        color: $color-primary;
        background-color: $color-blue-light;
      }
    }

    &__modal {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
</style>
