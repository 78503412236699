// export default router;
import store from '@/store';

import Vue from 'vue';
import Router from 'vue-router';

import Home from '@/views/Home';
import Dashboard from '@/views/Dashboard';
import Auth from '@/views/Auth';
import AuthLogout from '@/views/AuthLogout';
import Panel from '@/components/Panel/Panel.vue';
// pending routes
import Inbox from '@/views/Inbox';
import Timeline from '@/views/Timeline';
// hunt routes
import FavoriteLists from '@/views/FavoriteLists';
import FavoriteList from '@/views/FavoriteList';
// pipe
import CandidatePipe from '@/views/CandidatePipe';
import Pipe from '@/views/Pipe';
// database routes
import Coders from '@/views/DatabaseCoders';
import Contacts from '@/views/DatabaseContacts';
import FailureCoders from '@/views/FailureCoders';
import Jobs from '@/views/DatabaseJobs';
import Partners from '@/views/DatabasePartners';
import AccountManagement from '@/views/AccountManagement';
import Technos from '@/views/Technos';
import Values from '@/views/Values';
import InboxCardList from '@/components/Inbox/InboxCardList';
import Processes from '@/views/Processes';
import ProcessesStatistics from '@/views/ProcessesStatistics';
import ProspectionPipe from '@/views/ProspectionPipe';
import LogAs from '@/views/LogAs';
import CandidatesReminders from '@/views/CandidatesReminders';
import ContactsReminders from '@/views/ContactsReminders';
import Organizations from '@/views/Organizations';
import Industries from '@/views/Industries';
import Integrations from '@/views/Integrations';
import DatabaseTags from '@/views/DatabaseTags';
import DatabaseLogActivities from '@/views/DatabaseLogActivities';
import HistoryActions from '@/views/HistoryActions';
import CustomJobs from '@/views/CustomJobs';
import Configuration from '@/views/Configuration';
import Onboarding from '@/views/Onboarding';
import Applications from '@/views/Applications';

import ConfigurationMyCompany from '@/components/Configuration/ConfigurationMyCompany';
import ConfigurationCareerWebsite from '@/components/Configuration/ConfigurationCareerWebsite';
import ConfigurationJobboards
  from '@/components/Configuration/ConfigurationJobboards/ConfigurationJobboards';
import ConfigurationTemplatesEmail
  from '@/components/Configuration/ConfigurationTemplates/ConfigurationTemplatesEmail/ConfigurationTemplatesEmail.vue';
import ConfigurationJobboardsXtramile
  from '@/components/Configuration/ConfigurationJobboardsXtramile/ConfigurationJobboards';
import ConfigurationTeam from '@/components/Configuration/ConfigurationTeam';
import ConfigurationShareProcesses from '@/components/Configuration/ConfigurationShareProcesses';
import ConfigurationLinkedinExtension
  from '@/components/Configuration/ConfigurationLinkedinExtension';
import ConfigurationMultiposting from '@/components/Configuration/ConfigurationMultiposting';
import { marvinRecruiterOrganizationId } from '@/macros/utils';
import { setToken } from '@/managers/token/useCases/setToken';
import ConfigurationTemplatesNotes
  from '@/components/Configuration/ConfigurationTemplates/ConfigurationTemplatesNotes/ConfigurationTemplatesNotes.vue';
import DatabaseExtension from '@/views/DatabaseExtension.vue';
import DatabaseEmails from '@/views/DatabaseEmails.vue';
import DatabaseCampaigns from '@/views/DatabaseCampaigns.vue';
import DatabaseIncomingActions from '@/views/DatabaseIncomingActions.vue';
import ConfigurationTemplatesLinkedin
  from '@/components/Configuration/ConfigurationTemplates/ConfigurationTemplatesLinkedin/ConfigurationTemplatesLinkedin.vue';
import CampaignProspects from '@/views/CampaignProspects.vue';
import AdminMigrations from '@/views/AdminMigrations.vue';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
// Lazy imports

// Configuration
const ConfigurationProcess = () => import('@/components/Configuration/ConfigurationProcess');
const ConfigurationCompany = () => import('@/components/Configuration/ConfigurationCompany');
const ConfigurationRecruitingSector = () => import('@/components/Configuration/RecruitingSector');
const ConfigurationSmtpImap = () => import('@/components/Configuration/ConfigurationSmtpImap');
const ConfigurationMailSignature = () => import('@/components/Configuration/ConfigurationMailSignature');
const HiddenConfiguration = () => import('@/views/HiddenConfiguration');
const ConfigurationSubscription = () => import('@/components/Configuration/ConfigurationSubscription');
const ConfigurationLanguage = () => import('@/components/Configuration/ConfigurationLanguage');
const ConfigurationOther = () => import('@/components/Configuration/ConfigurationOther');
const ConfigurationKaspr = () => import('@/components/Configuration/ConfigurationKaspr');
const ConfigurationUnipile = () => import('@/components/Configuration/ConfigurationUnipile');
// const ConfigurationZapier = () => import('@/components/Configuration/ConfigurationZapier');
const IconsView = () => import('@/views/IconsView');

if (!process || import.meta.env.MODE !== 'test') {
  Vue.use(Router);
}

const router = new Router({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  silentNavigationErrors: true,
  routes: [
    {
      path: '*',
      redirect: (route, d) => {
        const isMarvin = store.state.user.profile?._organization?._id === marvinRecruiterOrganizationId;
        // const profileId = store.state.user.profile?._id
        // const hasPaid = store.state.user.profile?._organization?.subscription?.type === 2

        if (isMarvin) {
          return '/organizations';
        }

        // if (hasPaid) {
        //   return {
        //     path: '/pipe/hunt',
        //     query: {
        //       coachId: profileId,
        //       'selected-pipe': 'personal-pipe',
        //       'job-active': 'true',
        //     }
        //   };
        // }

        return '/onboarding';
      },
    },
    {
      name: 'Home',
      component: Home,
      path: '/home',
      children: [
        {
          path: ':id',
          component: Panel,
          name: 'HomePanel',
        },
      ],
    },
    {
      name: 'Onboarding',
      component: Onboarding,
      path: '/onboarding',
      children: [
        {
          path: 'create-list',
          component: Panel,
          name: 'FavoriteListsCodersPanelCreate',
        },
        {
          path: ':id',
          name: 'OnboardingCustomJobsPanel',
          component: Panel,
        },
        {
          path: ':id',
          component: Panel,
          name: 'OnboardingPanel',
        },
      ],
    },
    {
      name: 'Dashboard',
      component: Dashboard,
      path: '/dashboard',
      children: [
        {
          path: ':id',
          component: Panel,
          name: 'DashboardPanel',
        },
      ],
    },
    {
      name: 'CandidatePipe',
      component: CandidatePipe,
      path: '/candidate-pipe',
      children: [
        {
          path: ':id',
          name: 'CandidatePipePanel',
          component: Panel,
        },
      ],
    },
    {
      // TO REMOVE AFTER 21-05-2025
      name: 'legacy-reminders-link',
      path: '/reminders',
      redirect: '/candidates/reminders',
    },
    {
      name: 'CandidatesReminders',
      component: CandidatesReminders,
      path: '/candidates/reminders',
      children: [
        {
          path: ':id',
          name: 'CandidatesRemindersPanel',
          component: Panel,
        },
      ],
    },
    {
      name: 'Coders',
      component: Coders,
      path: '/coders',
      children: [
        {
          path: ':id',
          component: Panel,
          name: 'CodersPanel',
        },
      ],
    },
    {
      name: 'Campaigns',
      component: DatabaseCampaigns,
      path: '/campaigns',
      children: [
        {
          path: ':id',
          component: Panel,
          name: 'CampaignsPanel',
        },
      ],
    },
    {
      name: 'Prospects',
      component: CampaignProspects,
      path: '/prospects',
      children: [
        {
          path: ':id',
          component: Panel,
          name: 'ProspectsPanel',
        },
      ],
    },
    {
      name: 'IncomingActions',
      component: DatabaseIncomingActions,
      path: '/incoming-actions',
      children: [
        {
          path: ':id',
          component: Panel,
          name: 'IncomingActionsPanel',
        },
      ],
    },
    {
      name: 'ContactsReminders',
      component: ContactsReminders,
      path: '/contacts/reminders',
      children: [
        {
          path: ':id',
          name: 'ContactsRemindersPanel',
          component: Panel,
        },
      ],
    },
    {
      name: 'Contacts',
      component: Contacts,
      path: '/contacts',
      children: [
        {
          path: ':id',
          component: Panel,
          name: 'ContactsPanel',
        },
      ],
    },
    {
      name: 'FailureCoders',
      component: FailureCoders,
      path: '/failure-coders',
      children: [
        {
          path: ':id',
          component: Panel,
          name: 'FailureCodersPanel',
        },
      ],
    },
    {
      name: 'Partners',
      component: Partners,
      path: '/partners',
      children: [
        {
          path: ':id',
          component: Panel,
          name: 'PartnersPanel',
        },
      ],
    },
    {
      name: 'AccountManagement',
      component: AccountManagement,
      path: '/account-management',
      children: [
        {
          path: ':id',
          component: Panel,
          name: 'AccountManagementPanel',
        },
      ],
    },
    {
      path: '/auth/login',
      name: 'Auth',
      component: Auth,
      children: [
        {
          path: 'oauth/:social',
          name: 'AuthSocialLoader',
        },
      ],
    },
    {
      path: '/technos',
      name: 'Technos',
      component: Technos,
    },
    {
      path: '/values',
      name: 'Values',
      component: Values,
    },
    {
      path: '/auth',
      redirect: '/auth/login',
    },
    {
      path: '/auth/logout',
      name: 'AuthLogout',
      component: AuthLogout,
    },
    {
      path: '/jobs',
      name: 'Jobs',
      component: Jobs,
      children: [
        {
          path: ':id',
          component: Panel,
          name: 'JobsPanel',
        },
      ],
    },
    {
      path: '/favorite-lists',
      name: 'FavoriteLists',
      component: FavoriteLists,
      children: [
        {
          path: 'create',
          component: Panel,
          name: 'FavoriteListsPanelCreate',
        },
        {
          path: ':id',
          component: Panel,
          name: 'FavoriteListsPanelEdit',
        },
        {
          path: ':id',
          component: Panel,
          name: 'FavoriteListsPanel',
        },
      ],
    },
    {
      path: '/favorite-lists-coders',
      name: 'FavoriteListsCoders',
      component: FavoriteLists,
      children: [
        {
          path: 'create',
          component: Panel,
          name: 'FavoriteListsCodersPanelCreate',
        },
        {
          path: ':id',
          component: Panel,
          name: 'FavoriteListsCodersPanelEdit',
        },
        {
          path: ':id',
          component: Panel,
          name: 'FavoriteListsCodersPanel',
        },
      ],
    },
    {
      path: '/favorite-list/:name',
      component: FavoriteList,
      name: 'FavoriteList',
      children: [
        {
          path: '/favorite-list/:name/:id',
          component: Panel,
          name: 'FavoriteListPanel',
        },
      ],
    },
    {
      path: '/inbox',
      name: 'Inbox',
      component: Inbox,
      redirect: '/inbox/coders',
      children: [
        {
          path: ':type',
          name: 'InboxCardList',
          component: InboxCardList,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'InboxCardListPanel',
            },
          ],
        },
      ],
    },
    {
      path: '/configuration',
      name: 'Configuration',
      component: Configuration,
      children: [
        {
          path: 'process',
          component: ConfigurationProcess,
          name: 'ConfigurationProcess',
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'ConfigurationProcessPanel',
            },
          ],
        },
        {
          path: 'my-company',
          component: ConfigurationMyCompany,
          name: 'ConfigurationMyCompany',
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'ConfigurationCompanyPanel',
            },
          ],
        },
        {
          path: 'company',
          component: ConfigurationCompany,
          name: 'ConfigurationCompany',
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'ConfigurationCompanyPanel',
            },
          ],
        },
        {
          path: 'recruiting-sector',
          component: ConfigurationRecruitingSector,
          name: 'ConfigurationRecruitingSector',
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'ConfigurationRecruitingSectorPanel',
            },
          ],
        },
        {
          path: 'smtp-imap',
          component: ConfigurationSmtpImap,
          name: 'ConfigurationSmtpImap',
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'ConfigurationSmtpImapPanel',
            },
          ],
        },
        {
          path: 'mail-signature',
          component: ConfigurationMailSignature,
          name: 'ConfigurationMailSignature',
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'ConfigurationMailSignaturePanel',
            },
          ],
        },
        {
          path: 'team',
          name: 'Team',
          component: ConfigurationTeam,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'TeamPanel',
            },
          ],
        },
        {
          path: 'subscription',
          name: 'Subscription',
          component: ConfigurationSubscription,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'SubscriptionPanel',
            },
          ],
        },
        {
          path: 'language',
          name: 'Language',
          component: ConfigurationLanguage,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'LanguagePanel',
            },
          ],
        },
        {
          path: 'career-website',
          name: 'CareerWebsite',
          component: ConfigurationCareerWebsite,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'CareerWebsitePanel',
            },
          ],
        },
        {
          path: 'jobboards',
          name: 'Jobboards',
          component: ConfigurationJobboards,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'JobboardsPanel',
            },
          ],
        },
        {
          path: 'xtramile',
          name: 'JobboardsXtramile',
          component: ConfigurationJobboardsXtramile,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'XtramilePanel',
            },
          ],
        },
        {
          path: 'kaspr',
          name: 'Kaspr',
          component: ConfigurationKaspr,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'JobboardsPanel',
            },
          ],
        },
        // {
        //   path: 'zapier',
        //   name: 'Zapier',
        //   component: ConfigurationZapier,
        //   children: [
        //     {
        //       path: ':id',
        //       component: Panel,
        //       name: 'JobboardsPanel',
        //     },
        //   ],
        // },
        {
          path: 'unipile',
          name: 'Unipile',
          component: ConfigurationUnipile,
        },
        {
          path: 'other',
          name: 'ConfigurationOther',
          component: ConfigurationOther,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'ConfigurationOtherPanel',
            },
          ],
        },
        {
          path: 'share-processes',
          name: 'ConfigurationShareProcesses',
          component: ConfigurationShareProcesses,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'ConfigurationShareProcessesPanel',
            },
          ],
        },
        {
          path: 'linkedin-extension',
          name: 'ConfigurationLinkedinExtension',
          component: ConfigurationLinkedinExtension,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'ConfigurationLinkedinExtensionPanel',
            },
          ],
        },
        {
          path: 'multiposting',
          name: 'ConfigurationMultiposting',
          component: ConfigurationMultiposting,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'ConfigurationMultipostingPanel',
            },
          ],
        },
        {
          path: 'templates/email',
          name: 'TemplatesEmail',
          component: ConfigurationTemplatesEmail,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'TemplatesEmailPanel',
            },
          ],
        },
        {
          path: 'templates/notes',
          name: 'TemplatesNotes',
          component: ConfigurationTemplatesNotes,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'TemplatesTemplatesNotes',
            },
          ],
        },
        {
          path: 'templates/linkedin',
          name: 'TemplatesLinkedin',
          component: ConfigurationTemplatesLinkedin,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'TemplatesLinkedinPanel',
            },
          ],
        },
        {
          path: ':id',
          component: Panel,
          name: 'ConfigurationPanel',
        },
      ],
    },
    {
      path: '/hidden-configuration',
      name: 'HiddenConfiguration',
      component: HiddenConfiguration,
    },
    {
      path: '/timeline',
      name: 'Timeline',
      component: Timeline,
      children: [
        {
          path: ':id',
          component: Panel,
          name: 'TimelinePanel',
        },
      ],
    },
    {
      path: '/pipe',
      name: 'Pipe',
      component: Pipe,
      children: [
        {
          path: 'processes',
          name: 'Processes',
          component: Processes,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'ProcessesPanel',
            },
          ],
        },
        {
          path: 'processes-statistics',
          name: 'ProcessesStatistics',
          component: ProcessesStatistics,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'ProcessesStatisticsPanel',
            },
          ],
        },
        {
          path: 'prospects',
          name: 'ProspectionPipe',
          component: ProspectionPipe,
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'ProspectionPipePanel',
            },
          ],
        },
        {
          path: 'hunt',
          name: 'CustomJobs',
          component: CustomJobs,
          children: [
            {
              path: ':id',
              name: 'CustomJobsPanel',
              component: Panel,
            },
          ],
        },
        {
          path: 'applications',
          name: 'Applications',
          component: Applications,
          children: [
            {
              path: ':id',
              name: 'ApplicationsPanel',
              component: Panel,
            },
          ],
        },
        {
          name: 'AccountManagement',
          component: AccountManagement,
          path: 'account-management',
          children: [
            {
              path: ':id',
              component: Panel,
              name: 'AccountManagementPanel',
            },
          ],
        },
      ],
    },
    {
      name: 'LogAs',
      path: '/log-as',
      component: LogAs,
    },
    {
      path: '/organizations',
      name: 'Organizations',
      component: Organizations,
      children: [
        {
          path: ':id',
          component: Panel,
          name: 'OrganizationsPanel',
        },
      ],
    },
    {
      path: '/industries',
      name: 'Industries',
      component: Industries,
    },
    {
      path: '/integrations',
      name: 'Integrations',
      component: Integrations,
    },
    {
      path: '/tags',
      name: 'Tags',
      component: DatabaseTags,
      children: [
        {
          path: ':id',
          component: Panel,
          name: 'TagsPanel',
        },
        {
          path: ':id',
          component: Panel,
          name: 'TagsReplacePanel',
        },
        {
          path: ':id',
          component: Panel,
          name: 'TagsCreatePanel',
        },
        {
          path: ':id',
          component: Panel,
          name: 'TagsPushPanel',
        },
      ],
    },
    {
      path: '/logs-activities',
      name: 'logs-activities',
      component: DatabaseLogActivities,
    },
    {
      path: '/extension',
      name: 'Extension',
      component: DatabaseExtension,
    },
    {
      path: '/admin-emails',
      name: 'AdminEmails',
      component: DatabaseEmails,
    },
    {
      path: '/migrations',
      name: 'AdminMigrations',
      component: AdminMigrations,
    },
    {
      path: '/history-actions',
      name: 'HistoryActions',
      component: HistoryActions,
      children: [
        {
          path: ':id',
          component: Panel,
          name: 'HistoryActionsPanel',
        },
      ],
    },
    {
      path: '/icons-view',
      name: 'IconsView',
      component: IconsView,
      beforeEnter(_, __, next) {
        if (location.hostname === 'localhost') {
          return next();
        }
        next('/dashboard');
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  sessionStorage.setItem('marvin-from-path', from.path);
  if (to.query.token) {
    setToken(to.query.token);
    next(async() => {
      await store.dispatch('user/getProfile');
      await store.dispatch('getCoaches');
      await store.dispatch('setCoachFavoriteLists', store.state.user.profile._id);
      next('/');
    });
  } else {
    if (to.fullPath === from.fullPath) {
      next({
        ...to,
        query: {
          ...to.query,
          refresh: Date.now(),
        },
        replace: true,
      });
    }
  }

  next();
});

export const routeTabs = {
  configurationMyCompany: {
    mycompany: '/configuration/my-company',
  },
  configurationAccount: {
    smtpImap: '/configuration/smtp-imap',
    mailSignature: '/configuration/mail-signature',
  },
  configurationCustomColumns: {
    b2c: '/configuration/process',
    company: '/configuration/company',
  },
  configurationMulticast: {
    career: '/configuration/career-website',
    xtramile: '/configuration/xtramile',
    jobboards: '/configuration/jobboards',
    multiposting: '/configuration/multiposting',
  },
  configurationTemplates: {
    email: '/configuration/templates/email',
  },
  configurationIntegration: {
    kaspr: '/configuration/kaspr',
    zapier: '/configuration/zapier',
    unipile: '/configuration/unipile',
  },
  configurationPreferences: {
    shareProcesses: '/configuration/share-processes',
    linkedinExtension: '/configuration/linkedin-extension',
    timeline: '/configuration/timeline',
  },
  admin: {
    organizations: '/organizations',
    industries: '/industries',
    tags: '/tags',
    emails: '/admin-emails',
  },
  dashboard: {
    dashboard: '/dashboard',
  },
  onboarding: {
    onboarding: '/onboarding',
  },
  companies: {
    database: '/partners',
    prospects: '/pipe/prospects',
    accountManagement: '/pipe/account-management',
    contacts: '/contacts',
  },
  opportunities: {
    database: '/pipe/hunt',
    processes: '/pipe/processes',
    applications: '/pipe/applications',
  },
  candidates: {
    database: '/coders',
  },
  statistics: {
    statistics: '/pipe/processes-statistics',
  },
  campaigns: {
    campaigns: '/campaigns',
    incomingActions: '/incoming-actions',
    prospects: '/prospects',
  },
  billing: {
    billing: '/billing',
  },
  lists: {
    favoriteList: '/favorite-lists',
    favoriteListCoders: '/favorite-lists-coders',
  },
  reminders: {
    candidateReminders: '/candidates/reminders',
    reminders: '/contacts/reminders',
  }
};

export default router;
