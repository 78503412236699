import processes from "@/api/processes";

export const edit = (id, formattedFormDeal) => {
  try {
    return processes.updateFormDeal(id, formattedFormDeal);
  } catch (error) {
    if ((error || {}).response) {
      throw new Error(`API: ${error.response.data.message}`);
    }

    throw new Error(error);
  }
};

export default {
  edit,
};
