<template>
  <views class="favorite-lists col-xs-12 is-align-items-center">
    <ListsTabs>
      <div class="favorite-lists__buttons">

        <bc-dropdown
          :options="suggestions"
          :is-dropdown-full-width="false"
          position="bottom-left"
          :is-scrollable="false"
          :force-direction="true"
        >
          <template #label="{ isDropdownOpen }">
            <bc-button
              class="mr-2"
              icon-left="addtolist"
            >
              {{ $t('companies.account-management.create-a-list') }}
            </bc-button>
          </template>
          <template #option="{ option }">
            <bc-dropdown-item
              :text="option.value"
              :is-selected="false"
              @click.native.stop="openCreateListPanel(option.type)"
              class="min-w-[170px]"
            >
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
      </div>
    </ListsTabs>
    <views-container>
      <div class="favorite-lists__labels font-size-s">
        <p>{{ $t('generics.identity') }}</p>
        <p>{{ $t('lists.contains') }}</p>
        <p>{{ $t('generics.last-modifications') }}</p>
        <p>{{ $t('generics.owner') }}</p>
        <p>{{ $t('generics.shared-with') }}</p>
        <p>{{ $t('generics.edit') }}</p>
      </div>
      <div
        v-if="isLoading"
        class="favorite-lists__spinner-container"
      >
        <bc-spinner class="favorite-lists__spinner"></bc-spinner>
      </div>
      <card-list-draggable
        :cards="lists"
        :is-loaded="!isLoading && isLoaded"
        @list-order-updated="updateListOrder"
        @list-updated="updateList"
      >
      </card-list-draggable>
    </views-container>
    <router-view></router-view>
  </views>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import axios from 'axios';
// components
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import CardListDraggable from '@/components/CardList/CardListDraggable';
// http
import favoriteLists from '@/api/favoriteLists';
//
import favoriteListsMacros from '@/macros/favoriteLists/favoriteLists';
//
import debounce from 'debounce';
//
import ToastMixin from '@/mixins/toast';
import ViewsContainer from '@/components/Views/ViewsContainer';
import Views from '@/components/Views/Views';
import ListsTabs from '@/views/Tabs/ListsTabs';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';

export default {
  name: 'favorite-lists',
  components: {
    ViewsContainer,
    CardListDraggable,
    Views,
    ListsTabs,
    BcButton,
    BcSpinner,
    BcDropdown,
    BcDropdownItem,
  },
  mixins: [ToastMixin],
  beforeDestroy() {
    this.cancelTokenSource.cancel('manualCancel');
  },
  mounted() {
    this.cancelTokenSource = axios.CancelToken.source();
    this.getFavoriteLists();
  },
  data() {
    return {
      isLoaded: false,
      isLoading: false,
      activeTabIndex: 0,
      cancelTokenSource: undefined,
    };
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
      lists: state => state.card.cards,
      metadata: state => state.user.metadata,
    }),
    count() {
      return (this.lists || []).length || 0;
    },
    isCandidateListsScreen() {
      return this.$route.query['list-type'] === 'coder';
    },
    suggestions() {
      return [
        {
          value: this.$t('topbar.list-of-candidates'),
          type: 'coder',
        },
        {
          value: this.$t('topbar.list-of-companies'),
          type: 'company',
        },
        {
          value: this.$t('topbar.list-of-contacts'),
          type: 'contact',
        },
      ];
    },
  },
  methods: {
    ...mapActions(['resetCards', 'setCards', 'eraseCards']),
    async getFavoriteLists() {
      try {
        this.isLoading = true;
        if (this.lists.length > 0) {
          await this.resetCards();
        }

        const type = this.$route.query?.['list-type'] ? favoriteListsMacros.types[this.$route.query?.['list-type']] : favoriteListsMacros.types.company;
        const response = await favoriteLists.getFavoriteLists({
          ...(this.$route.query.search && { query: this.$route.query.search }),
          type,
          cancelTokenSource: this.cancelTokenSource,
        });

        const lists = response.data.filter(list => !list.hidden);

        this.setCards(lists);
        this.isLoading = false;
        this.isLoaded = true;
      } catch (e) {
        this.isLoading = false;
        if (e.message === 'manualCancel') {
          return;
        }
        this.$_displayErrorToast(e);
      }
    },
    async updateListOrder($event) {
      await favoriteLists.updateCoachFavoriteListRank({
        lists: {
          listToReorderId: $event.moved.element._id,
          listBeforeId: $event.moved.newIndex ? this.lists[$event.moved.newIndex - 1]._id : '',
        },
        coachId: this.profile._id,
      });
    },
    async updateList($event) {
      const lists = this.lists;

      try {
        this.eraseCards($event);
      } catch (error) {
        this.$_displayErrorToast(error);

        this.eraseCards(lists);
      }
    },
    updateSearchQuery: debounce(function (value) {
      this.$router.push({
        query: {
          ...this.$route.query,
          search: value || undefined,
        },
      });
    }, 500),
    openCreateListPanel(type) {
      this.$router.push({
        path: '/favorite-lists/create',
        query: {
          ...this.$route.query,
          type: `favorite-${type}-list`,
          subtype: 'create-favorite-list',
        },
      });
    },
    handleActiveTab(event) {
      this.activeTabIndex = event;
    },
  },

  watch: {
    '$route.query': {
      handler(newQuery, oldQuery) {
        if (
          newQuery['list-type'] !== oldQuery['list-type'] ||
          newQuery.search !== oldQuery.search
        ) {
          this.getFavoriteLists();
        }
      },
    },
    activeTabIndex() {
      this.getFavoriteLists();
    },
  },
};
</script>

<style lang="scss" scoped>
.favorite-lists {
  @include page_grid();

  &__title {
    min-height: 30px;
  }

  &__spinner {
    margin: 100px 0;

    &-container {
      justify-content: center;
    }
  }

  &__subtitle {
    position: relative;
    top: 1px;
    color: #3d5476;
  }

  &__labels {
    padding: 20px 10px;
    padding-left: 67px;
    margin-bottom: 10px;
    color: $color-blue-heavy-dark;
    text-transform: uppercase;

    &>p {
      flex: 1;
    }

    & p:first-child {
      flex: 2;
    }
  }

  &__buttons {
    margin-left: auto;
  }

  &__searchbar {
    margin-bottom: 35px;
  }
}
</style>
