
export default {
  methods: {
    addTag(tagObject) {
      this.$store.dispatch('pushSearchbarFiltersTags', tagObject);
    },
    removeTag(index) {
      this.$store.dispatch('spliceSearchbarFiltersTags', index);
    },
    replaceTag(tagObject) {
      this.$store.dispatch('replaceSearchbarFiltersTags', tagObject);
    },
    replaceCustomFieldTag(tagObject) {
      this.$store.dispatch('replaceSearchbarFiltersCustomFieldTags', tagObject);
    },
    setTags(tagsArray) {
      this.$store.dispatch('setSearchbarFiltersTags', tagsArray);
    },
  }
};
