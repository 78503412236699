import { mapActions, mapGetters, mapState } from "vuex";
import { pipeStatus } from "@/common-old/macros/coders";

export default {
  computed: {
    ...mapGetters([
      'myOldProcesses',
    ]),
    ...mapState({
      coders: state => state.card.cards,
    }),
  },
  methods: {
    ...mapActions([
      'unshiftCard',
      'removeCard',
    ]),
    hasGardenState(coder) {
      return coder.coachProcessState.status === pipeStatus.nothing || coder.coachProcessState.status === pipeStatus.garden;
    },
    $_manageCoderInMyOldProcessesList(updatedProcess) {
      const isMyOldProcessesPage = this.$route.query['list-id'] = this.myOldProcesses._id;
      const isCoderInMyOldProcessesList = this.coders.find(coder => coder._id === updatedProcess._coder._id);
      const shouldAddCoderToMyOldProcessesList = this.hasGardenState(updatedProcess._coder) && isMyOldProcessesPage && !isCoderInMyOldProcessesList;
      const shouldRemoveCoderFromMyOldProcessesList = !this.hasGardenState(updatedProcess._coder) &&
        isMyOldProcessesPage && isCoderInMyOldProcessesList;

      if (this.hasGardenState(updatedProcess._coder)) {
        this.$store.dispatch('incrementElementListCount', this.myOldProcesses._id);
      }

      if (!this.hasGardenState(updatedProcess._coder) && isCoderInMyOldProcessesList) {
        this.$store.dispatch('decrementElementListCount', this.myOldProcesses._id);
      }

      if (shouldAddCoderToMyOldProcessesList) {
        this.unshiftCard(updatedProcess._coder);
      }

      if (shouldRemoveCoderFromMyOldProcessesList) {
        this.removeCard(updatedProcess._coder._id);
      }
    },
  },
};
