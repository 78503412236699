<template>
  <div class="configuration-templates-item" @click="onClickItem">
    <div
      class="configuration-templates-item__name items-center w-3/12"
    >
      <p class="w-fit text-ellipsis">{{ template.name }}</p>
      <div
        v-if="template.default"
        class="mr-2 min-w-[135px] rounded-[30px] bg-blue-100 px-[10px] py-[1px] text-xs font-medium text-[#375276]"
      >
        {{ $t('email.default-template') }}
      </div>
    </div>
    <div
      class="w-7/12"
    >
      <p class="leading-6 items-start truncate">
        <span v-if="template.subject" class="font-medium block">{{ template.subject }}</span>
        <span>{{ template.body }}</span>
      </p>
    </div>
    <div
      class="w-1/12"
      @mouseenter="isHover = true"
      @mouseleave="isHover = false"
    >
      <bc-toggle
        :id="template._id"
        type="default"
        :value="template.activated"
        :disabled="disabled"
        @input="onToggleActivated">
      </bc-toggle>
    </div>
    <div
      class="w-1/12"
    >
      <bc-avatar
        v-if="template.coach"
        :src="template.coach.pictureUrl"
        class="is-unshrink"
        size="s"
        @error="setAlternativeImg">
      </bc-avatar>
      <div v-if="template.default" >
        <bc-avatar
          class="size-[30px]"
          :src="require('@/assets/logo/marvin-bg.jpeg')"
        >
        </bc-avatar>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue'
import BcToggle from '@/ui-kit/components/BcToggle/BcToggle.vue'

const props = defineProps({
  template: {
    type: Object,
    default: () => ({})
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['click', 'toggle-activated'])
const isHover = ref(false)

const setAlternativeImg = (event) => {
  event.target.src = require('@/assets/img/default-avatar.svg')
}

const onClickItem = () => {
  if (isHover.value) {
    return
  }
  emit('click', props.template)
}

const onToggleActivated = () => {
  emit('toggle-activated', props.template)
}
</script>

<style lang="scss" scoped>
.configuration-templates-item {
  width: 100%;
  border-radius: 5px;
  border: 1px solid $color-blue-medium;
  background: $color-neutre-4;
  height: 60px;
  padding: 5px 30px;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &__name {
    align-items: center;
    gap: 10px;

    & p {
      color: $color-tertiary;
      font-weight: 700;
      width: 55%;
    }
  }

  &__logo {
    width: 27px;
    height: 27px;
    background: white;
    border-radius: 100px;
    border: 1px solid #F2F4FD;
  }

  &__tag {
    border-radius: 30px;
    color: #3D5476;
    background: $color-blue-light;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 12px;

    &--error {
      background: #F5DFE0;
      color: $color-red-mandy;
    }

    &--success {
      background: #EEF9F4;
      color: $color-green-shamrock;
    }
  }

}
</style>
