<template>
  <div
    :class="{
      'ratings--disabled': disabled,
    }"
    class="ratings is-relative flex w-full justify-between">
    <div class="ratings__container">
      <div
        v-for="star in starsCount"
        :key="star"
        :class="{
          'ratings__wrapper--unavailable': rating === 0,
          'ratings__wrapper--active': rating >= star
        }"
        :style="{ color: starColor }"
        class="is-align-items-center is-relative ratings__wrapper"
        @click="handleClick(star)">
        <i
          :class="[`font-size-${starSize}`, 'icon-star ratings__star is-align-items-center is-justify-content-center is-flex' ]"></i>
      </div>
    </div>
    <button
      v-if="isButtonDisplayed"
      :class="{ 'ratings__button--disabled border-radius-s': rating === 0 }"
      class="ratings__button font-size-s is-underline is-align-items-center"
      data-button-not-enough-informations
      @click="handleClick(0)">
      {{ $t('companies.panel-view-timeline.not-enough-infos') }}
    </button>
  </div>
</template>

<script>
  import colors from '@/ui-kit/assets/scss/exports/_colors.module.scss';

  export default {
    name: 'ratings',
    props: {
      rating: {
        type: Number,
        default: null,
      },
      starsCount: {
        type: Number,
        default: 5,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      isHiddenButton: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        default: 'primary-base',
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      starSize: {
        type: String,
        default: 'xl',
      },
    },
    computed: {
      isButtonDisplayed() {
        if (this.isHiddenButton) {
          return false;
        }

        return !this.disabled || this.disabled && this.rating === 0 && !this.isHiddenButton;
      },
      starColor() {
        return colors[this.color];
      },
    },
    methods: {
      handleClick(star) {
        this.$emit('rating-set', star);
      },
    },
  };
</script>

<style lang=scss scoped>
  @import '@/ui-kit/assets/scss/elements/_icons.scss';

  %fill-star {
    .icon-star {
      @extend .icon-star-full;
    }
  }

  %empty-star {
    .icon-star {
      @extend .icon-star
    }
  }

  .ratings {
    &--disabled {
      pointer-events: none;
      cursor: initial;
    }

    &__wrapper {
      color: $color-grey-4;
      cursor: pointer;

      &--unavailable {
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          height: 2px;
          left: 0;
          right: 0;
          background: $color-grey-4;
        }

        &:hover {
          &:before {
            opacity: 0;
          }
        }
      }

      &--active, .ratings__container:hover & {
        &:before {
          opacity: 0;
        }

        color: inherit;

        @extend %fill-star;
      }

      &:hover ~ & {
        color: $color-grey-4;

        @extend %empty-star;
      }

      &:hover ~ & .ratings__animation {
        display: none;
      }
    }

    &__button {
      color: $color-primary;

      @include bp("tablet") {
        margin-bottom: 0;
      }

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }

      .ratings__wrapper {
        background: $color-error;
      }

      &--disabled {
        background: rgba($color-secondary, 0.1);
        text-decoration: none;
        color: rgba($color-secondary, 0.5);
        pointer-events: none;
      }
    }
  }
</style>
