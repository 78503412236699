import { buildDomainTag, buildProfessionsTag, buildSearchTag, buildTechnosTag } from '@/helpers/searchbar/factories';

const searchbarAutocompleteEventsMixin = {
  methods: {
    $_openPanel({ tag, type, context }) {
      this.$router.push({
        name: `${this.$route.name}Panel`,
        params: {
          id: tag._id,
        },
        query: {
          ...this.$route.query,
          type,
          ...context && { context },
        },
      });
    },
    $_inputAutocompleteEvent(tag) {
      this.$emit('on-select', buildSearchTag(tag.name));
      this.clearInput();
      this.closeSuggestionsList();
    },
    $_technoAutocompleteEvent(tag) {
      this.$emit('on-select', buildTechnosTag(tag));
      this.clearInput();
      this.closeSuggestionsList();
    },
    $_coderAutocompleteEvent(tag) {
      this.addToHistory(tag);
      this.$_openPanel({ tag, type: 'coders', context: this.isFocusedSearchbar && !this.searchbarInput ? 'search-dbcoders-history' : 'search-dbcoders-autocomplete' });
    },
    $_jobAutocompleteEvent(tag) {
      this.$_openPanel({ tag, type: 'jobs' });
    },
    $_partnerAutocompleteEvent(tag) {
      this.$_openPanel({ tag, type: 'companies' });
    },
    $_domainAutocompleteEvent(tag) {
      tag.queries.forEach(query => this.$emit('on-select', buildDomainTag(query)));
      this.clearInput();
      this.closeSuggestionsList();
    },
    $_professionAutocompleteEvent(tag) {
      this.$emit('on-select', buildProfessionsTag(tag));
      this.clearInput();
      this.closeSuggestionsList();
    },
    triggerAutocompleteEvent(tag) {
      const events = [
        { type: 'input', function: this.$_inputAutocompleteEvent },
        { type: 'techno', function: this.$_technoAutocompleteEvent },
        { type: 'coder', function: this.$_coderAutocompleteEvent },
        { type: 'job', function: this.$_jobAutocompleteEvent },
        { type: 'partner', function: this.$_partnerAutocompleteEvent },
        { type: 'domain', function: this.$_domainAutocompleteEvent },
        { type: 'profession', function: this.$_professionAutocompleteEvent },
      ];
      const event = events.find(event => event.type === tag.type);

      if (event) {
        event.function(tag);
      }
    },
  },
};

export default searchbarAutocompleteEventsMixin;
