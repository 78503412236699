<template>
  <div class="companies-grid is-full-width">
    <div class="companies-grid__container is-full-width">
      <bc-checkbox
        id="checkbox"
        :value="isChecked"
        class="companies-grid__text companies-grid__checkbox is-uppercase font-size-s"
        @input="$emit('checked', $event)">
      </bc-checkbox>
      <grid-sort-select
        class="companies-grid__name companies-grid__text"
        :label="`${$t('generics.identity')}`"
        @sort="handleSort('name', $event)"
      />
      <p class="companies-grid__text companies-grid__jobs is-uppercase font-size-s">
        {{ $t('companies.account-management.opportunities-upper') }}
      </p>
      <p
        class="companies-grid__text companies-grid__link is-uppercase font-size-s hidden-xs hidden-sm">
        {{ $t('companies.account-management.links') }}
      </p>
      <p class="companies-grid__text companies-grid__collaborators is-uppercase font-size-s">
        {{ $tc('generics.contact', 2) }}
      </p>
      <grid-sort-select
        class="companies-grid__text companies-grid__locations"
        :label="`${$t('companies.account-management.locations-upper')}`"
        @sort="handleSort('location', $event)"
      />
      <p
        v-if="isOnSubscriptionsPage"
        class="companies-grid__text companies-grid__time-slot is-uppercase font-size-s hidden-xs hidden-sm">
        {{ $t('companies.account-management.slot-upper') }}
      </p>
      <p
        v-if="!isOnSubscriptionsPage"
        class="companies-grid__text companies-grid__activity is-uppercase font-size-s hidden-xs">
        {{ $t('companies.account-management.status-upper') }}
      </p>
      <p
        v-if="!isOnSubscriptionsPage"
        class="companies-grid__text companies-grid__prospecting is-uppercase font-size-s hidden-xs">
        {{ $t('companies.account-management.owner-upper') }}
      </p>
      <p
        v-else
        class="companies-grid__text companies-grid__button is-uppercase font-size-s hidden-xs">
      </p>
    </div>
  </div>
</template>

<script>
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger.vue';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
  import GridSortSelect from '@/components/Grid/GridSortSelect.vue';

  export default {
    name: 'companies-grid',
    components: { GridSortSelect, BcDropdown, BcDropdownTrigger, BcDropdownItem, BcCheckbox },
    props: {
      isChecked: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isOnSubscriptionsPage() {
        return this.$route.name === 'InboxCardList';
      },
    },
    methods: {
      async handleSort(field, sortOption) {
        await this.$store.dispatch('setSort', {
          field,
          direction: sortOption.value
        })
      },
    }
  };
</script>

<style lang=scss scoped>
  .companies-grid {
    padding: 25px 15px;

    @include bp("desktop") {
      padding: 20px 15px;
    }

    &__text {
      color: $color-blue-heavy-dark;
      margin: 0 10px;
    }

    &__name {
      @include companies-grid-name;
    }

    &__jobs {
      @include companies-grid-jobs;
    }

    &__link {
      @include companies-grid-socials;
    }

    &__locations {
      @include companies-grid-locations;
    }

    &__collaborators {
      @include companies-grid-collaborators;
    }

    &__activity {
      @include companies-grid-activity;
    }

    &__prospecting {
      @include companies-grid-prospecting;
    }

    &__button {
      @include companies-grid-button;
    }

    &__followers {
      @include companies-grid-lists;
      text-align: end;
    }

    &__time-slot {
      @include companies-grid-time-slot;
    }
  }
</style>
