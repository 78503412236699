<template>
  <div class="panel-carousel-new">
    <panel-carousel-arrow
      v-if="cardActive !== null && $route.name !== 'PopularityPanel'"
      class="panel-carousel-new__navigation"
      @click.native="changeCardActive('previous')">
    </panel-carousel-arrow>
    <div
      v-if="$slots.default"
      class="panel-carousel-new__container col-xs-12">
      <slot></slot>
    </div>
    <panel-carousel-arrow
      v-if="cardActive !== null && $route.name !== 'PopularityPanel'"
      @click.native="changeCardActive('next')"
      class="panel-carousel-new__navigation"
      :reverted="true">
    </panel-carousel-arrow>
  </div>
</template>

<script>
  import PanelCarouselArrow from '@/components/Panel/PanelCarousel/PanelCarouselArrow';
  import { mapGetters, mapState } from 'vuex';
  // TODO Refacto ce composant à cause des futurs nouveaux style de panel
  /**
   * It's the carousel which will be used inside the panel.
   * There are arrow on each side of this component and, between there is a slot which will be replaced when we will call the component
   */
  export default {
    name: 'panel-carousel-new',
    components: {
      PanelCarouselArrow
    },
    computed: {
      ...mapState({
        cards: state => state.card.cards
      }),
      ...mapGetters({
        cardActive: 'cardActive'
      })
    },
    methods: {
      /**
       * Change the value of the state cardActive
       * @param direction ('previous', 'next')
       */
      changeCardActive(direction) {
        if (direction === 'previous' && this.cardActive > 0) {
          /**
           * We decrement the value of the cardActive state if the direction is previous
           */
          this.$router.push({
            name: this.$route.name,
            params: {
              id: this.cards[this.cardActive - 1]._id
            },
            query: {
              ...this.$route.query
            }
          });
          /**
           * We change the route
           */
        } else if (direction === 'next' && this.cardActive < this.cards.length - 1) {
          /**
           * We increment the value of the cardActive state if the direction is next
           */
          this.$router.push({
            name: this.$route.name,
            params: {
              id: this.cards[this.cardActive + 1]._id
            },
            query: {
              ...this.$route.query
            }
          });
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .panel-carousel-new {
    background: $color-white;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow: auto;
    min-height: 20px;
    @include bp('tablet') {
      flex-shrink: 0;
      height: auto;
    }
    &__container {
      justify-content: center;
      @include bp('tablet') {
        padding: 0 70px;
      }
    }
    &__navigation {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      &:last-of-type {
        right: 0px;
      }
    }
  }
</style>
