<script setup lang="ts">
import { computed, ref, defineEmits, withDefaults, defineProps } from "vue";
import debounce from "debounce";
import { getEmailsAutocomplete } from '@/api/emails';
import { validateEmail } from '@/helpers/strings';

import { useToast } from "@/ui-kit/components/BcToast";
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
import BcAutocomplete from '@/ui-kit/components/BcAutocomplete';
import Tag from '@/components/Tag/Tag';
import DEFAULT_AVATAR from "@/assets/img/default-avatar.svg";

type Option = {
  key: string;
  address: string;
}

interface Props {
  cc: Option[];
}

withDefaults(defineProps<Props>(), {
  cc: () => [],
});

const emits = defineEmits<{
  (e: 'on-select', option: Option): void,
  (e: 'on-remove', option: Option): void,
}>();

// composables
const toast = useToast();

// refs
const ccValue = ref('');
const ccOptions = ref([]);

// computed
const ccOptionsComputed = computed(() => {
  if (ccOptions.value.length) {
    return ccOptions.value;
  }
  return [{ address: ccValue.value }]
});

// methods
const getCcSuggestions = debounce(async function(value: string) {
  ccOptions.value = [];
  if (value.length < 3) return;
  try {
    const { data: results } = await getEmailsAutocomplete(value);
    ccOptions.value = results;
  } catch (err: any) {
    toast.show({
      type: 'error',
      message: err?.response?.body?.message || err?.message,
    });
  }
}, 500, false);

const onSelectCc = (option: Option) => {
  if (!validateEmail(option.address)) {
    toast.show({
      type: 'error',
      message: 'email invalid',
    });
    return;
  }

  // this.form.cc.push(option);
  emits('on-select', option);
  ccValue.value = '';
};

const onRemoveCc = (option: Option) => {
  emits('on-remove', option);
}

const setAlternativeImg = (event: any) => {
  event.target.src = DEFAULT_AVATAR;
}

</script>

<template>
  <div class="mt-2 block">
    <bc-autocomplete
      v-model="ccValue"
      @input="getCcSuggestions"
      :is-scrollable="false"
      :options="ccOptionsComputed"
      class="is-full-width"
      name="cc"
      type="email"
      :label-placeholder="false"
      label="CC"
      @select="onSelectCc"
      v-bind="$attrs">
      <template #option="{ option }">
        <div class="gap-3 p-3">
          <div>
            <bc-avatar :src="option.logo" @error="setAlternativeImg" />
          </div>
          <div class="is-column">
            <p v-if="option.name">{{ option.name }}</p>
            <p class="text-sm">{{ option.address }}</p>
          </div>
        </div>
      </template>
    </bc-autocomplete>
    <div class="mt-2 gap-2">
      <tag
        v-for="email in cc"
        :key="email.key"
        :text="email.address"
        :can-close="true"
        @click="onRemoveCc(email)"
      />
    </div>
  </div>
</template>
