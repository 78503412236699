<template>
  <div
    v-click-outside="closePanel"
    class="panel-poke-profile">
    <panel-poke-header class="panel-poke-profile__header"></panel-poke-header>
    <panel-categories-new-style
      :categories="categories">
    </panel-categories-new-style>
    <div class="panel-poke-profile__view">
      <component
        :is="panelView"
        :is-loading="isLoading"
        :key="panelView">
      </component>
    </div>
    <div class="panel-poke-profile__nav">
      <panel-carousel-new class="panel-poke-profile__carousel">
        <panel-poke-caption v-if="windowWidth > 767"></panel-poke-caption>
      </panel-carousel-new>
    </div>
  </div>
</template>

<script>
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation.js';
  //
  // TODO remove because unused (with unused children).
  import { mapState } from 'vuex';
  import PanelPokeHeader from '@/components/Panel/PanelPoke/PanelPokeHeader';
  import PanelCarousel from '@/components/Panel/PanelCarousel';
  import PanelPokeCaption from '@/components/Panel/PanelPoke/PanelPokeCaption';
  import PanelCategories from '@/components/Panel/PanelCategoriesOldStyle';
  import PanelView from '@/components/Panel/PanelView';
  import PanelPokeTimeline from '@/components/Panel/PanelPoke/PanelPokeTimeline';
  import PanelCategoriesNewStyle from '@/components/Panel/PanelCategoriesNewStyle';
  import PanelCarouselNew from '@/components/Panel/PanelCarouselNew';
  // dependencies
  import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

  /**
   * The panel which will call all the elements when the user wants to see a poke profile
   */
  export default {
    name: 'panel-poke-profile',
    components: {
      PanelCarouselNew,
      PanelCategoriesNewStyle,
      'my-notes': PanelPokeTimeline,
      PanelView,
      PanelCategories,
      PanelCarousel,
      PanelPokeHeader,
      PanelPokeCaption,
      infos: PanelPokeCaption,
    },
    mixins: [PanelNavigation],
    created() {
      this.getPokeProfile();
    },
    data() {
      return {
        isLoading: true,
        panelPoke: null
      };
    },
    computed: {
      ...mapState({
        profile: state => state.panel.profile,
        windowWidth: state => state.panel.windowWidth
      }),
      /**
       * The view of the panel
       */
      panelView() {
        return this.$route.query.category && this.categories.find(category => category.url === this.$route.query.category) ? this.$route.query.category : this.categories[0].url;
      },
      categories() {
        const categoriesColor = [
          '',
          'blue-dark-cello',
          'blue-dark-cello',
          'blue-dark-cello',
          'green-bright',
          'yellow-sunglow',
          'yellow-sunglow'
        ];
        const categories = [
          {
            name: 'infos',
            url: 'infos',
            textColor: 'blue-cornflower',
            textColorActive: 'white',
            borderColor: 'blue-cornflower',
            borderColorActive: 'transparent',
            backgroundColor: 'white',
            backgroundColorActive: 'blue-cornflower',
            textSize: '14px'
          },
          {
            name: 'Mes notes',
            url: 'my-notes',
            textColor: this.profile.status && this.windowWidth > 767 ? categoriesColor[this.profile.status] : 'blue-dark-cello',
            textColorActive: 'white',
            backgroundColor: 'white',
            backgroundColorActive: this.$route.query.category === 'my-notes' && this.profile.status && this.windowWidth > 767 ? categoriesColor[this.profile.status] : 'blue-dark-cello',
            borderColor: this.profile.status && this.windowWidth > 767 ? categoriesColor[this.profile.status] : 'blue-dark-cello',
            borderColorActive: 'transparent',
            textSize: '14px'
          },
        ];
        return this.windowWidth > 767 ? categories.filter(category => category.name !== 'infos') : categories;
      }
    },
    mounted() {
      this.panelPoke = document.querySelector('.panel-poke-profile__view');

            disableBodyScroll(this.panelPoke);
        },
        methods: {
            /**
             * The method to get the poke profile
             */
            async getPokeProfile() {
                try {
                    const timer = setTimeout(() => {
                        this.isLoading = true;
                    }, 1500);
                    await this.$store.dispatch('getPokeProfile', this.$route.params.id);
                    clearTimeout(timer);
                    this.isLoading = false;
                } catch (error) {
                  this.$toast.show({
                    type: 'error',
                    message: this.$t('toast.error-occured'),
                  });
                }
            }
        },
        watch: {
            $route() {
                this.getPokeProfile();
            },
            windowWidth(to, from) {
                if (to > 767 && from <= 767) {
                    this.$router.replace({
                        ...this.$route,
                        query: {
                            ...this.$route.query,
                            category: this.categories[0].url
                        }
                    });
                }
            }
        },
        beforeDestroy() {
            enableBodyScroll(this.panelPoke);
        }
    };
</script>

<style scoped lang="scss">
  .panel-poke-profile {
    flex-direction: column;
    height: 100%;

    &__header {
      order: -2;
      min-height: 50px;
    }

    &__categories {
      order: -1;
      @include bp('tablet') {
        order: 0;
      }
    }

    &__view {
      background: $color-white;
      height: 100%;
      overflow-y: auto;
      z-index: 1;
    }

    &__nav {
      background: $color-white;
      justify-content: center;
      padding: 10px 0;
      border-top: 1px solid rgba($color-blue-cornflower, 0.3);
      @include bp('tablet') {
        min-height: 200px;
        order: -1;
      }
    }

    &__carousel {
      width: 100%;
      max-width: 70px;
      @include bp('tablet') {
        max-width: initial;
      }
    }
  }
</style>
