<template>
  <div
    class="card-admin-log-activities is-full-width is-column is-overflow-hidden border-radius-m is-relative"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false">
    <div
      class="card-admin-log-activities__container">
      <div
        class="card-admin-log-activities__wrapper card-admin-log-activities__action">
        <div>
          <div><div>Type : </div><div>{{ cardContent.type }}</div></div>
          <div><div>Action : </div><div>{{ displayAction }}</div></div>
        </div>
      </div>
      <div
        class="card-admin-log-activities__wrapper card-admin-log-activities__description">
        <div>
          Description :
        </div>
        <div>
          <div v-html="cardContent.description"></div>
        </div>
      </div>
      <div
        class="card-admin-log-activities__wrapper card-admin-log-activities__author">
        <div>
          Auteur :
        </div>
        <div>
          <p
            v-tooltip="cardContent.author.email">
            {{ `${cardContent.author.firstName} ${cardContent.author.lastName}` }}
            {{ cardContent.author.companyName !== "" ? `(${cardContent.author.companyName})` : "" }}
          </p>
        </div>
        <div style="margin-top: 10px;">
          Date :
        </div>
        <div style="margin-top: 10px;font-weight: 400;">
          <p>
            {{ displayDate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from "moment";

  export default {
    name: 'card-admin-log-activities',
    mixins: [],
    components: {
    },
    props: {
      cardContent: {
        type: Object,
        default: () => ({}),
      },
      types: {
        type: Array,
        default: () => ([]),
      },
    },
    data() {
      return {
        isHover: false,
      };
    },
    computed: {
      displayDate() {
        if (this.cardContent.createdAt) {
          return moment(this.cardContent.createdAt).format("DD MMM YYYY à HH:mm:ss");
        }
        return '';
      },
      displayAction() {
        const action = this.cardContent.action;
        const actionValue = this.types.flatMap(element => element?.children).find(e => e.value === action);
        
        return actionValue.name || null;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .card-admin-log-activities {
    &:hover {
      cursor: auto;
      max-height: initial;
    }


    &__container {
      padding: 15px;
      flex-direction: column;
      justify-content: start;

      @include bp('tablet') {
        flex-direction: row;
      }

      @include bp('desktop') {
        // padding: 25px 15px;
      }
    }

    &__wrapper {
      margin: 10px;
      max-height: 100%;
      min-height: 37px;
      width: 100%;

      @include bp('tablet') {
        margin: 0 10px;
        width: initial;
      }

      align-items: center;
    }

    &__action {
      min-width: 13%;
      max-width: 13%;
      color: $color-secondary-dark;
      font-weight: bold;
      font-size: $font-size-m;
      text-transform: capitalize;

      > div {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        > div:nth-child(1) {
          margin-bottom: 20px;
        }

        > div {
          display: flex;
          width: 100%;
          justify-content: flex-start;

          div:nth-child(1) {
            padding: 5px;
          }

          div:nth-child(2) {
            padding: 5px 15px;
            margin-left: 5px;
            border-radius: 25px;
            border: 1px solid lightgray;
            color: white;
            font-size: 13px;
          }
        }

        > div:nth-child(1) {
          div:nth-child(2) {
            background-color: $color-secondary;
          }
        }

        > div:nth-child(2) {
          div:nth-child(2) {
            background-color: $color-primary;
          }
        }
      }
    }

    &__description {
      min-width: 60%;
      max-width: 60%;
      color: $color-secondary-dark;
      font-weight: bold;
      font-size: $font-size-m;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > div:nth-child(1) {
        padding: 5px 5px 15px 0px;
      }

      > div:nth-child(2) {
        font-weight: 400;
        > div {
          display: flex;
          flex-direction: column;
          min-height: 80px;
          justify-content: space-around;
        }
      }
    }

    &__author {
      color: $color-secondary-dark;
      font-weight: bold;
      font-size: $font-size-m;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > div:nth-child(1) {
        padding: 5px 5px 15px 0px;
      }

      > div:nth-child(2) {
        font-weight: 400;
      }
    }
  }
</style>
