export default {
  data() {
    return {
      isExpanded: false,
      textareaNode: {},
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setTextareaNode();
    });
  },
  computed: {
    formattedContent: {
      get() {
        return this.content;
      },
      set(value) {
        this.content = value;
      },
    },
    canBeExpanded() {
      return this.textareaNode.scrollHeight > this.textareaNode.clientHeight || false;
    },
  },
  methods: {
    setTextareaNode() {
      if (this.$refs.textarea) {
        this.textareaNode = this.$refs.textarea.$refs.reference.$el;
      }
    },
    formatContent(text) {
      const formattedText = `<p>${text.replace(/\r?\n|\rro/g, '</p><p>')}`;

      formattedText.replace(/^\s*<p\s*\/?>|<\/p\s*\/?>\s*$/g, '');

      return formattedText
    },
  },
};
