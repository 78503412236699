import { pipeStatus } from '@/common-old/macros/coders';

export default {
  methods: {
    $_updateCandidatePipeMetadatas(oldCoachProcessState = {}, newCoachProcessState = {}) {
      const metadataToDecrement = Object.keys(pipeStatus).find(key => pipeStatus[key] === oldCoachProcessState.status);
      const metadataToIncrement = Object.keys(pipeStatus).find(key => pipeStatus[key] === newCoachProcessState.status);

      if (this.$route.name.includes('CandidatePipe')) {
        this.$store.dispatch('user/decrementMetadataByKey', 'newCoders');
        this.$store.dispatch('user/incrementMetadataByKey', metadataToIncrement);
      } else {
        this.$store.dispatch('user/incrementMetadataByKey', metadataToIncrement);
        this.$store.dispatch('user/decrementMetadataByKey', metadataToDecrement);
      }
    },
  },
};
