<template>
  <div class="panel-view-description col-xs-12" v-if="description">
    <title-section
      :backgroundColor="'white'"
      :color="'blue'"
      :position="'left'"
      :title="$t('companies.account-management.what-we-propose-to-you')"
      class="panel-view-description__title">
    </title-section>
    <placeholder-lines
      :rows-number="4"
      v-if="isLoading">
    </placeholder-lines>
    <div
      class="panel-view-description__text"
      v-else
      v-html="description">
    </div>
  </div>
</template>

<script>
  import TitleSection from '@/components/Title/TitleSection';
  import PlaceholderLines from '@/components/UIKit/Placeholder/PlaceholderLines';

  export default {
    name: 'panel-view-description',
    components: {
      PlaceholderLines,
      TitleSection,
    },
    props: {
      description: String,
      /**
       * Define if the content-loading should appear or not
       */
      isLoading: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-view-description {
    $line-height: 20px;

    &__title {
      margin-bottom: 30px;
    }

    &__text {
      flex-grow: 1;
      min-height: 100%;
      text-align: left;
      font-size: $font-size-m;
      color: $color-secondary;
      margin: -15px -15px 50px -15px;
      line-height: 1.5;
      display: block;
      @include bp('tablet') {
        width: 80%;
      }

      :deep() {
        & > div {
          display: block;
          line-height: 1.5em;
          width: 100%;
          font-size: $font-size-m;
          color: $color-secondary;
        }

        p {
          line-height: 1.5em;
          min-height: 1.5em;
        }

        @include default-css;
      }
    }
  }
</style>
