<template>
  <panel-process-details-card :title="$t('process.summary-process')" icon="repeat">
    <div v-for="input of inputs" :key="input.id" class="input-item-container">
      <div class="input-item" v-if="handleShowCompanies(input.data)">
        <div class="flex-1">
          <label :for="input.data">{{ input.label }} : </label>
        </div>
        <div class="input-container flex-1">
          <bc-input
            :id="input.data"
            :inputStyle="{ width: `${input.width}px`, padding: '10px 5px', fontWeight: '700' }"
            :name="input.data"
            :type="input.type"
            :value="formData[input.data]"
            class="input"
            @blur="updateDetailsAsync($event, input.data)"
            @input="updateDetails($event, input.data)"
          />
          <span class="unit">{{ input.unit }}</span>
        </div>
      </div>
    </div>
  </panel-process-details-card>
</template>

<script>
  import { mapGetters } from 'vuex';
  import PanelProcessDetailsCard from './PanelProcessDetailsCard.vue';
  import BcInput from '@/ui-kit/components/BcInput';

  import { updateProcessCustom } from '@/api/processes-custom';

  export default {
    name: 'panel-process-details-recap',
    components: {
      PanelProcessDetailsCard,
      BcInput,
    },
    props: {
      process: {
        type: Object,
        required: true,
      },
    },
    created() {
      Object.keys(this.formData).forEach(key => {
        let value = this.process[key];

        if (typeof value === 'string' && Date.parse(value)) {
          value = new Date(value).toLocaleDateString('en-CA');
        }

        this.formData = { ...this.formData, [key]: value?.toString() };
        this.previousFormData = { ...this.formData, [key]: value?.toString() };
      });
    },
    data() {
      return {
        formData: {
          dealAcceptedAt: '',
          startsAt: '',
          amountBilled: '0',
          estimatedBilledAmount: '0',
        },
        previousFormData: {
          dealAcceptedAt: '',
          startsAt: '',
          amountBilled: '0',
          estimatedBilledAmount: '0',
        },
        inputs: [
          {
            id: 1,
            label: this.$t('process.estimated-billed-amount'),
            data: 'estimatedBilledAmount',
            type: 'number',
            width: 84,
            unit: '€',
          },
          {
            id: 2,
            label: this.$t('process.deal-accepted-at'),
            data: 'dealAcceptedAt',
            type: 'date',
            width: 140,
          },
          {
            id: 3,
            label: this.$t('process.amount-billed'),
            data: 'amountBilled',
            type: 'number',
            width: 84,
            unit: '€',
          },
          {
            id: 4,
            label: this.$t('process.job-starts-at'),
            data: 'startsAt',
            type: 'date',
            width: 140,
          },
        ],
      };
    },
    computed: {
      ...mapGetters({
        configurationFramework: 'user/configurationFramework',
      }),
    },
    methods: {
      updateDetails(newValue, key) {
        if (!(key in this.formData)) {
          return;
        }
        this.formData[key] = newValue;
      },
      updateDetailsAsync(newValue, key) {
        if (!(key in this.formData) || this.previousFormData[key] === newValue) {
          return;
        }

        updateProcessCustom({ id: this.process._id, [key]: newValue })
        .then(() => {
          this.previousFormData[key] = newValue;
          const label = this.inputs.find(input => input.data === key).label;
          this.$toast.show({
            type: 'success',
            title: this.$t('toast.congratulations'),
            message: this.$t('toast.update-process-success', { 'field': label.toLowerCase() }),
          });
        })
        .catch(() => {
          this.formData[key] = this.previousFormData[key];
          this.$toast.show({
            type: 'error',
            message: 'Erreur lors de la mise à jour',
          });
        });
      },
      handleShowCompanies(key) {
        if (!this.configurationFramework.showCompanies) {
          return !['estimatedBilledAmount', 'amountBilled'].includes(key);
        }
        return true;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .input-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
  }

  label {
    color: $color-tertiary;
    font-size: 14px;
    font-weight: 400;
  }

  .input-item-container {
    flex-direction: column;
  }

  .input-container {
    display: flex;
    align-items: center;
  }

  .flex-1 {
    flex: 1;
  }

  .input {
    margin-top: 0;
  }

  .unit {
    margin-left: 10px;
  }
</style>
