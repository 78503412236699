<template>
  <div class="searchbar-filters-result">
    <p>{{ label }}</p>
    <button @click="onReset">
      <i class="icon-refresh-cw"></i>
      <span>&nbsp;&nbsp;{{ $t('generics.reinitialize') }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'searchbar-filters-result',
  props: {
    label: {
      type: String,
    }
  },
  methods: {
    onReset(ev) {
      this.$emit('on-reset', ev);
    },
  },
}
</script>

<style lang="scss" scoped>
.searchbar-filters-result {
  margin-top: 10px;
  gap: 10px;
  align-items: center;

  & > p {
    color: $color-tertiary-2;
    font-weight: $font-weight-bold;
  }
  & > button {
    text-decoration: underline;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    & i {
      font-size: 12px;
      margin-top: 1px;
    }
  }
}
</style>
