<template>
  <views style="overflow: hidden">
    <div class="container flex size-full min-w-full">
      <topbar-config class="configuration-topbar max-h-[84vh]"></topbar-config>
      <router-view></router-view>
    </div>
  </views>
</template>

<script>
  import Views from '@/components/Views/Views.vue';
  import TopbarConfig from '@/components/Topbar/TopbarConfig/TopbarConfig.vue';
  import ConfigurationTeamTopbar from '@/components/Configuration/ConfigurationTeamTopbar.vue';
  import { mapActions } from 'pinia';
  import { useNotesTemplatesStore } from '@/store/pinia/notesTemplates';

  export default {
    name: 'configuration',
    components: {
      Views,
      TopbarConfig,
    },
    data() {
      return {
        routeOptions: {
          '/configuration/team': {
            showCreateBtn: false,
            topbarComponent: ConfigurationTeamTopbar,
          },
        },
      };
    },
    computed: {
      showCreate() {
        if (this.routeOptions[this.$route.path]) {
          return this.routeOptions[this.$route.path].showCreateBtn;
        }
        return false;
      },
      topbarComponent() {
        if (this.routeOptions[this.$route.path]) {
          return this.routeOptions[this.$route.path].topbarComponent;
        }
        return undefined;
      },
    },
    async created() {
      await this.fetchNotesTemplates({ coachId: this.$store.state.user.profile._id });
    },
    methods: {
      ...mapActions(useNotesTemplatesStore, ['fetchNotesTemplates']),
    },
  };
</script>

<style lang="scss" scoped>
  .container {
    width: 100%;
    min-height: 84vh;
    display: flex;
    position: relative;
  }

  .configuration-topbar {
    margin-top: 20px;
    margin-left: 30px;

    // &::after {
    //   content: '';
    //   width: 100%;
    //   height: 1px;
    //   right: 100px;
    //   top: 45px;
    //   background: $color-blue-light;
    //   position: absolute;
    // }
  }
</style>
