<template>
  <BcModal :active="isModalOpen" class="modal-process-deal" @close="$emit('close-modal')">
    <div
    class="modal-process-deal__container"
    >
      <canvas
          ref="confetti-canvas"
          :class="{ 'modal-process-deal__canvas--p': showConffeti }"
          class="modal-process-deal__canvas"
          height="500" width="500"
      />
      <p class="modal-process-deal__title"> {{ $t('process.congrat-recruitment') }} 🎉 </p>
      <div class="modal-process-deal__form">
          <v-date-picker
            v-model="acceptanceDate"
            :locale="locale"
            :popover="{ visibility: 'click', positionFixed: true }"
            :required="true"
            class="modal-process-deal__dates-input"
            @input="onUpdateAcceptanceDate">
            <template v-slot="{ togglePopover }">
              <bc-input
                :label="$t('generics.acceptance-date')"
                :labelPlaceholder="false"
                :placeholder="$t('generics.acceptance-date')"
                :required="true"
                :valid="!!acceptanceDate"
                :value="acceptanceDateFormat"
                class="modal-process-deal__dates-input"
                icon="calendar"
                icon-left="calendar"
                name="acceptanceDate"
                @focus="togglePopover"/>
            </template>
          </v-date-picker>
          <bc-input
            v-model="dealAmount"
            :label="$t('process.amount-deal')"
            :labelPlaceholder="false"
            :placeholder="$t('process.amount-deal')"
            :required="true"
            :valid="!!dealAmount"
            class="modal-process-deal__dates-input"
            name="dealAmount"
            type="number"/>
          <bc-input
            v-model="financialProposal"
            :label="$t('generics.financial-proposal')"
            :labelPlaceholder="false"
            :placeholder="$t('generics.financial-proposal')"
            :required="true"
            :valid="!!financialProposal"
            class="modal-process-deal__dates-input"
            name="financialProposal"/>

        <v-date-picker
          v-model="estimatedStartDate"
          :locale="locale"
          :popover="{ visibility: 'click', positionFixed: true }"
          class="modal-process-deal__dates-input"
          @input="onUpdateEstimatedStartDate">
          <template v-slot="{ togglePopover }">
            <bc-input
              :label="$t('generics.estimated-start-date')"
              :labelPlaceholder="false"
              :placeholder="$t('generics.estimated-start-date')"
              :valid="!!estimatedStartDate"
              :value="estimatedStartDateFormat"
              class="modal-process-deal__dates-input"
              icon="calendar"
              icon-left="calendar"
              name="estimatedStartDate"
              @focus="togglePopover"/>
          </template>
        </v-date-picker>
      </div>
      <div class="modal-process-deal__footer">
        <bc-button
          class="modal-process-deal__footer--btn"
          type="outlined"
          @click="$emit('close-modal')">
          {{ $t('generics.cancel') }}
        </bc-button>
        <bc-button
          :disabled="!isFormValid || isLoading"
          :is-loading="isLoading"
          class="modal-process-deal__footer--btn"
          color="success"
          @click="onValidate">
          {{ validateLabel }}
        </bc-button>
      </div>
    </div>
  </BcModal>
</template>

<script>
  import moment from 'moment/min/moment-with-locales';
  import { mapGetters } from 'vuex';
  import locale from '@/mixins/i18n/locale';
  import { dealProcessCustom } from '@/api/processes-custom';

  import BcModal from '@/ui-kit/components/BcModal/BcModal.vue';
  import BcInput from '@/ui-kit/components/BcInput/BcInput';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';

  export default {
    name: 'modal-process-deal',
    components: {
      BcModal,
      BcInput,
      BcButton,
    },
    mixins: [locale],
    props: {
      data: {
        type: Object,
      },
      isModalOpen: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      this.resetForm();
    },
    data() {
      return {
        acceptanceDate: new Date(),
        dealAmount: undefined,
        financialProposal: '',
        estimatedStartDate: '',
        isLoading: false,
        showConffeti: false,
      };
    },
    computed: {
      ...mapGetters({
        configurationProcess: 'user/configurationProcess',
      }),
      acceptanceDateFormat() {
        if (!this.acceptanceDate) return undefined;
        return moment(this.acceptanceDate).format('L');
      },
      estimatedStartDateFormat() {
        if (!this.estimatedStartDate) return undefined;
        return moment(this.estimatedStartDate).format('L');
      },
      validateLabel() {
        return this.configurationProcess?.processSuccessValidation?.validationLabel;
      },
      redirectUrl() {
        return this.configurationProcess?.processSuccessValidation?.redirectUrl;
      },
      isFormValid() {
        return this.acceptanceDate && this.dealAmount && this.financialProposal;
      },
    },
    methods: {
      confetti() {
        const confetti = this.$refs['confetti-canvas'];
        if (confetti) {
          this.showConffeti = true;
          this.$confetti.start({
            canvasElement: confetti,
            type: 'rect',
            particles: 1,
            defaultDropRate: 8,
          });
          setTimeout(() => {
            this.$confetti.stop({ canvasElement: confetti });
            this.showConffeti = false;
          }, 1500);
        }
      },
      resetForm() {
        this.acceptanceDate = new Date();
        this.dealAmount = undefined;
        this.financialProposal = '';
        this.estimatedStartDate = '';

        this.$nextTick(() => {
          this.confetti();
        });
      },
      onUpdateEstimatedStartDate(value) {
        this.estimatedStartDate = value;
      },
      onUpdateAcceptanceDate(value) {
        this.acceptanceDate = value;
      },
      async onValidate() {
        try {
          this.isLoading = true;

          const processId = this.data.process._id;
          await dealProcessCustom({
            id: processId,
            acceptanceDate: this.acceptanceDate,
            dealAmount: this.dealAmount,
            financialProposal: this.financialProposal,
            estimatedStartDate: this.estimatedStartDate,
          });
          this.$emit('move-process', { processId, step: this.data.step });

          this.isLoading = false;
          if (this.redirectUrl) {
            window.open(this.redirectUrl, '_blank');
          }
          this.$emit('close-modal');
        } catch (err) {
          console.error(err);
          this.isLoading = false;
          this.$toast.show({
            type: 'error',
            message: err.message,
          });
        }
      },
    },
    watch: {
      isModalOpen(value) {
        if (value) {
          this.resetForm();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-process-deal {
    &__container {
      min-width: 530px;
      min-height: 400px;
      padding: 10%;
      align-items: center;
      padding-top: 55px;
      flex-direction: column;
      position: relative;
    }

    &__title {
      text-align: center;
      color: $color-tertiary;
      font-size: 24px;
      font-weight: bold;
    }

    &__form {
      width: 100%;
      margin-top: 20px;
      flex-direction: column;
      gap: 20px;
    }

    &__dates-input {
      width: 100%;
    }

    &__footer {
      width: 100%;
      margin-top: 20px;
      gap: 20px;

      &--btn {
        width: 100%;
      }
    }

    &__canvas {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;

      &--p {
        z-index: 999999;
      }
    }

    & :deep() {
      .bc-input {
        & label {
          font-size: 12px;
        }
      }
    }
  }
</style>
