<template>
  <views class="organizations">
    <admin-tabs :organizations-count="count">
      <bc-button
        class="ml-auto mr-3"
        icon-left="companies-plus"
        size="small"
        @click="goCreateOrganization">
        Créer une organisation
      </bc-button>
    </admin-tabs>
    <router-view></router-view>
    <views-container>
      <bc-pagination
        :current.sync="currentPage"
        :total="count"
        class="organizations__pagination">
      </bc-pagination>
      <div>
        <mv-searchbar
          v-model="searchbarInput"
          title="Organizations"
          placeholder="Rechercher une organization"
          :is-loading="false"
          :is-scrollable="true"
          :show-results="false"
        ></mv-searchbar>
      </div>
      <bc-spinner
        v-if="!isLoaded"
        class="organizations__spinner">
      </bc-spinner>
      <div
        v-else
        class="is-align-items-center is-column">
        <organizations-grid></organizations-grid>
        <card-list
          ref="cardList"
          :cards="cards"
          :is-loaded="true"
          class="pagination__card-list">
          <template #card="{ card }">
            <card :card-content="card">
              <card-organizations :card-content="card"></card-organizations>
            </card>
          </template>
        </card-list>
      </div>
      <bc-pagination
        :current.sync="currentPage"
        :total="count"
        class="organizations__pagination">
      </bc-pagination>
    </views-container>
    <bc-modal
      :active="isModalOpen"
      @close="cancelCreate">
      <organizations-modal-create
        @close-modal="cancelCreate"
        @company-created="confirmCreate">
      </organizations-modal-create>
    </bc-modal>
  </views>
</template>

<script>
  import debounce from 'debounce';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import BcPagination from '@/ui-kit/components/BcPagination';
  import CardList from '@/components/CardList/CardList';
  import Card from '@/components/Card/Card';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import CardOrganizations from '@/components/Card/CardOrganizations';
  import OrganizationsGrid from '@/components/Organizations/OrganizationsGrid';
  import BcModal from '@/ui-kit/components/BcModal/BcModal';
  import OrganizationsModalCreate
    from '@/components/Organizations/OrganizationsModal/OrganizationsModalCreate';
  import { mapActions, mapState } from 'vuex';
  import ViewsContainer from '@/components/Views/ViewsContainer';
  import Views from '@/components/Views/Views';
  import AdminTabs from '@/views/Tabs/AdminTabs';
  import MvSearchbar from '@/ui-kit/components/MvSearchbar/MvSearchbar';

  export default {
    name: 'organizations',
    components: {
      Views,
      ViewsContainer,
      OrganizationsModalCreate,
      BcModal,
      OrganizationsGrid,
      CardOrganizations,
      BcButton,
      Card,
      BcSpinner,
      BcPagination,
      CardList,
      AdminTabs,
      MvSearchbar,
    },
    data() {
      return {
        isLoaded: true,
        currentPage: parseInt(this.$route.query.page) || 1,
        isModalOpen: false,
        searchbarInput: '',
      };
    },
    computed: {
      ...mapState({
        cards: state => state.card.cards,
        count: state => state.card.count,
      }),
    },
    created() {
      this.getOrganizations();
    },
    watch: {
      currentPage(newPage) {
        this.$router.push({ ...this.$route, query: { ...this.$route.query, page: newPage } });

        this.getOrganizations();
      },
      searchbarInput: debounce(async function(value) {
        await this.getOrganizations(value);
      }, 500),
    },
    methods: {
      ...mapActions([
        'resetCards',
        'getCards',
        'setCount',
        'unshiftCard',
      ]),
      goCreateOrganization() {
        this.isModalOpen = true;
      },
      cancelCreate() {
        this.isModalOpen = false;
      },
      confirmCreate(organization) {
        this.unshiftCard(organization);

        this.setCount(this.count + 1);

        this.isModalOpen = false;
      },
      async getOrganizations(search = '') {
        this.isLoaded = false;

        try {
          this.resetCards();
          await this.getCards({
            page: this.currentPage,
            call: 'organizations',
            parameters: {
              search,
            },
            responseKeyName: 'organizations',
          });


          this.isLoaded = true;
        } catch (error) {
          this.$emit('error', error);

          this.isLoading = true;
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (vm.$store.getters['user/isMarvinRecruiter']) {
          next();
        } else {
          next('/');
        }
      });
    },
  };
</script>

<style lang="scss" scoped>
  .organizations {
    &__title {
      min-height: 30px;
      margin-bottom: 15px;
    }

    &__searchbar {
      margin-bottom: 20px;
    }

    &__filters {
      margin-bottom: 20px;
    }

    &__button {
      position: absolute;
      right: 0;
      flex-shrink: 0;
    }

    &__pagination {
      margin: 20px 0;
    }

    &__spinner {
      margin: 100px 0;
    }

    &__card-list {
      margin-bottom: 100px;
    }

    &__spinner, &__pagination, &__card-list {
      justify-content: center;
    }
  }
</style>
