<template>
  <div
    @click="$refs[name].focus()"
    :class="{'bc-input--in-row': inRow}"
    class="bc-input">
    <label
      v-if="label"
      :style="labelStyle"
      :class="{'bc-input__label--in-row': inRow}"
      class="bc-input__label">
      <span
        v-if="labelIcon"
        class="bc-input__label-icon"
        :class="`icon-${labelIcon}`">
      </span>
      {{ label }}
      <span
        v-if="validate && validate.required"
        class="bc-input__label-star">
        *
      </span>
    </label>
    <input
      v-validate="validate"
      :name="name"
      :ref="name"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @focus="$emit('focus'), focusX=true"
      @blur="$emit('blur'), focusX=false"
      @keydown.down.prevent="$emit('keypressDown')"
      @keydown.up.prevent="$emit('keypressUp')"
      @keydown.delete="$emit('keypressDelete')"
      @keydown.enter="$emit('keypressEnter')"
      :class="[ { 'bc-input__input': true, 'bc-input__input--error invalid': errors.has(name), 'bc-input__input--in-row': inRow}, `bc-input__input bc-input__input--${name}`]"
      :style="inputStyle"
      :type="type"
      :placeholder="placeholder"
      :disabled="isDisabled"
      autocomplete="off">
    <span
      v-if="labelPlaceholder"
      class="bc-input__placeholder"
      :class="{'bc-input__placeholder--noPlaceholderLabel': value.length > 0 ? true: focusX}"
      :style="{ 'color': placeHolderDynamicColor }">
      {{ labelPlaceholder }}
    </span>
    <span
      v-show="errors.has(name)"
      class="bc-input__help is-danger">
      {{ errors.first(name) }}
    </span>
  </div>
</template>

<script>
  import Vue from 'vue';
  import VeeValidate from 'vee-validate';
  import fr from 'vee-validate/dist/locale/fr';

  // TODO Rendre cet input adaptable et le renommer en input-default

  Vue.use(VeeValidate, {
    inject: false,
    locale: 'fr',
    dictionary: {
      fr
    }
  });

  export default {
    inject: ['$validator'],
    name: 'legacy-bc-input',
    data() {
      return {
        focusX: false,
      };
    },
    computed: {
      placeHolderDynamicColor() {
        if (this.focusX) {
          return 'black';
        }
        return this.labelColor;
      },
      inputStyle() {
        return {
          backgroundColor: this.backgroundColor,
          borderColor: this.borderColor,
          color: this.color,
          transition: this.transition,
          height: this.height,
          ...this.bcFontSize && { fontSize: this.bcFontSize }
        };
      },
      labelStyle() {
        return {
          color: this.labelColor,
          ...this.bcLabelTextAlign && { textAlign: this.bcLabelTextAlign }
        };
      }
    },
    props: {
      /**
       * Define the input name
       */
      name: {
        type: String,
        required: true
      },
      /**
       * Define the input type
       */
      type: {
        type: String,
        required: true
      },
      /**
       * Define the input height
       */
      height: {
        type: String,
        default: '40px'
      },
      /**
       * Define the input background color
       */
      backgroundColor: {
        type: String,
        default: '#fff'
      },
      /**
       * Define the input border color
       */
      borderColor: {
        type: String
      },
      /**
       * Define the input color
       */
      color: {
        type: String,
        default: '#333333'
      },
      /**
       * Define the transition property
       */
      transition: {
        type: String,
        required: false,
        default: ''
      },
      /**
       * Define the label color
       */
      labelColor: {
        type: String,
        default: '#333333'
      },
      /**
       * Define the label
       */
      label: {
        type: String,
        default: ''
      },
      /**
       * Define the label icon
       */
      labelIcon: String,
      /**
       * Define the placeholder
       */
      placeholder: {
        type: String,
        default: ''
      },
      /**
       * Define the field inside the object which will be modified
       */
      value: {
        type: [String, Number]
      },
      /**
       * Define the validate rules
       */
      validate: {
        type: Object
      },
      /***
       *
       * Define the value of the animated placeholder
       */
      labelPlaceholder: {
        type: String,
        default: '',
        required: false
      },
      /**
       * Considering the following input display as a row from tablet device to desktop
       */
      inRow: {
        type: Boolean,
        default: false
      },
      isDisabled: {
        type: Boolean,
        default: false
      },
      bcFontSize: {
        type: String
      },
      bcLabelTextAlign: {
        type: String
      }
    },
  };
</script>

<style lang="scss" scoped>
  .bc-input {
    position: relative;
    flex-direction: column;
    width: 100%;
    &--in-row {
      @include bp('tablet') {
        flex-direction: row;
        align-items: center;
        width: 100%;
      }
    }
    &__input {
      min-height: 36px;
      border-radius: 4px;
      padding: 0 20px;
      width: auto;
      border: solid $color-gray-geyser 1px;
      flex-shrink: 0;
      &--in-row {
        @include bp('tablet') {
          width: 70%;
          margin-left: auto;
          flex-shrink: initial;
        }
      }
      @include font-size(12px);

      &:active,
      &:focus {
        border: solid $color-blue-dodger 1px;
      }
      &--error {
        border: solid $color-red 1px;
      }
      &::placeholder {
        color: $color-gray-regent;
      }
    }
    &__placeholder {
      position: absolute;
      top: 15px;
      left: 20px;
      transition: all 0.3s ease;
      @include font-size(12px);
      &--noPlaceholderLabel {
        top: -20px;
        @include font-size(11px);
      }
    }
    &__help {
      @include font-light(12px);
      color: $color-red;
      margin: 5px 0 0;
    }
    &__label {
      @include font-light(16px);
      margin-bottom: 10px;
      align-items: center;
      &--in-row {
        @include bp('tablet') {
          margin: 0 15px 0 0;
        }
      }
    }
    &__label-icon {
      @include font-size(19px);
      color: $color-blue-dodger;
      margin-right: 10px;
    }
    &__label-star {
      @include font-size(14px);
      color: $color-blue;
      margin-left: 5px;
    }
  }
</style>
