<script lang="ts" setup>

  import { computed, inject } from 'vue';
  import { Company } from '@/domains/models/Company';
  import PanelCompanyModalMergePreviewTitle
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalMerge/PanelCompanyModalMergePreview/PanelCompanyModalMergePreviewTitle.vue';
  import PanelCompanyModalMergePreviewLine
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalMerge/PanelCompanyModalMergePreview/PanelCompanyModalMergePreviewLine.vue';
  import Tag from '@/components/Tag/Tag.vue';
  import { formatPlaceDetailsToBaseLocation } from '@/formatters/locations';
  import { Location } from '@/domains/models/Location';
  import { useI18n } from '@/i18n/i18n';
  import { useStore } from '@/store';
  import PanelCompanyModalMergeButton
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalMerge/PanelCompanyModalMergeButton.vue';
  import ProvideKeys from '@/components/Panel/PanelCompany/PanelCompanyModal/ProvideKeys';
  import { SelectedFields } from '@/components/Panel/PanelCompany/PanelCompanyModal/types';

  const props = defineProps<{
    company: Company;
    toDelete: boolean;
  }>();

  const i18n = useI18n();
  const store = useStore();
  const user = store.state.user.profile;

  type LocationTag = Pick<Location, 'place_id' | 'completeAddress' | 'fullAddress'> & {
    text: string
  };
  const locations = computed<LocationTag[]>(() => {
    return props.company?.locations?.map(location => formatPlaceDetailsToBaseLocation(location, 'locality')) || [''];
  });
  const selectedLocations = computed<LocationTag[]>(() => {
    if (props.toDelete || !selectedFieldsProfile?.value?.locations) {
      return [];
    }

    return selectedFieldsProfile?.value?.locations?.filter(location => !props.company?.locations.some(l => l.placeId === location.placeId)).map(location => formatPlaceDetailsToBaseLocation(location, 'locality')) || [''];
  });
  const size = computed<string>(() => {
    return i18n.t(`panel-company-edit.size-options-${selectedFieldsProfile?.value?.size >= 0 ? selectedFieldsProfile?.value?.size : props.company?.size || 0}`);
  });

  const customFields = user._organization.configuration?.company?.customFields?.filter((customField: {
    activated: boolean,
    key: string,
    icon: string,
    name: string
  }) => customField.activated) || [];
  const newCustomFields = computed(() => customFields.filter(field => !getCustomFieldValue(field, props.company) && getCustomFieldValue(field, selectedFieldsProfile.value)));

  function getCustomFieldValue(customField: {
    key: string,
    unit?: string
  }): string {
    const profile = selectedFieldsProfile.value?.customFields?.[customField.key] ? selectedFieldsProfile.value : props.company;

    return customField.key === 'experience'
      ? `${profile?.customFields?.[customField.key]} ${i18n.tc(
        'global.year',
        profile?.customFields?.[customField.key],
      )}`
      : Array.isArray(profile?.customFields?.[customField.key])
        ? profile?.customFields?.[customField.key]?.map((value: string | number) => `${value} ${customField.unit ? customField.unit : ''}`)?.join(' - ')
        : profile?.customFields?.[customField.key];
  }

  const locationsImportedStatus = computed<'imported' | 'import'>(() => selectedFields.value.includes('locations') ? 'imported' : 'import');
  const sizeImportedStatus = computed<'imported' | 'erase' | 'import'>(() => selectedFields.value.includes('size') ? 'imported' : sizeMergeButtonLabel.value);

  const { rightProfile } = inject(ProvideKeys.mergeProfiles);
  const sizeMergeButtonLabel = computed(() => {
    return rightProfile.value?.size > 0 ? 'erase' : 'import';
  });

  function getCustomFieldImportedStatus(customField: { key: string }): 'imported' | 'erase' {
    return selectedFields.value.includes(`customFields.${customField.key}`) ? 'imported' : 'erase';
  }

  const {
    selectedFields,
    setSelectedFields,
    selectedFieldsProfile,
  } = inject(ProvideKeys.selectedFields) as SelectedFields;
</script>

<template>
  <div class="mt-5 flex w-full flex-col gap-5">
    <PanelCompanyModalMergePreviewTitle :title="$t('generics.details')"/>
    <div class="flex flex-col">
      <PanelCompanyModalMergePreviewLine
        v-if="!toDelete && selectedFieldsProfile?.uniqueid"
        :disabled="true"
        :title="$t('generics.uniqueid')"
        icon="companies">
        <div class="wrap flex justify-end gap-[5px]">
          <Tag :text="selectedFieldsProfile.uniqueid"/>
          <div
            class="icon-check flex size-5 items-center justify-center rounded-full bg-green-550 text-[10px] text-white">
          </div>
        </div>
      </PanelCompanyModalMergePreviewLine>
      <PanelCompanyModalMergePreviewLine
        v-else-if="company?.uniqueid"
        :disabled="true"
        :title="$t('generics.uniqueid')"
        icon="companies">
        <div class="wrap flex justify-end gap-[5px]">
          <Tag :text="company.uniqueid"/>
        </div>
      </PanelCompanyModalMergePreviewLine>
      <PanelCompanyModalMergePreviewLine
        v-if="locations?.length"
        :title="$t('generics.location')"
        icon="map-pin">
        <div class="flex flex-wrap justify-end gap-[5px]">
          <Tag
            v-for="location in locations"
            :key="location.place_id"
            :text="location.completeAddress ? location.completeAddress : location.fullAddress"
            type="location"
          />
          <Tag
            v-for="location in selectedLocations"
            :key="location.place_id"
            :text="location.completeAddress ? location.completeAddress : location.fullAddress"
            class="!bg-green-550 !text-white"
            type="location"
          />
          <PanelCompanyModalMergeButton
            v-if="toDelete"
            :status="locationsImportedStatus"
            @click.native="setSelectedFields('locations')"/>
        </div>
      </PanelCompanyModalMergePreviewLine>
      <PanelCompanyModalMergePreviewLine
        :title="$t('panel-company-edit.size')"
        icon="users">
        <div class="flex flex-wrap justify-end gap-[5px]">
          <Tag
            :text="size"
            type="size"
          />
          <PanelCompanyModalMergeButton
            v-if="toDelete"
            :status="sizeImportedStatus"
            @click.native="setSelectedFields('size')"
          />
          <div
            v-else-if="sizeImportedStatus === 'imported'"
            class="icon-check flex size-5 items-center justify-center rounded-full bg-green-550 text-[10px] text-white">
          </div>
        </div>
      </PanelCompanyModalMergePreviewLine>
      <PanelCompanyModalMergePreviewLine
        v-for="(customField, index) in customFields"
        v-show="company?.customFields?.[customField.key] !== null"
        :key="`custom-field-${index}`"
        :class="{'hidden': !getCustomFieldValue(customField, company)}"
        :icon="customField.icon"
        :title="customField.name">
        <div class="flex flex-wrap justify-end gap-[5px]">
          <Tag
            v-tooltip.bottom="customField.name"
            :text="getCustomFieldValue(customField, company)"
            :type="customField.key"/>
          <PanelCompanyModalMergeButton
            v-if="toDelete"
            :status="getCustomFieldImportedStatus(customField)"
            @click.native="setSelectedFields(`customFields.${customField.key}`)"
          />
          <div
            v-else-if="getCustomFieldImportedStatus(customField) === 'imported'"
            class="icon-check flex size-5 items-center justify-center rounded-full bg-green-550 text-[10px] text-white">
          </div>
        </div>
      </PanelCompanyModalMergePreviewLine>
      <PanelCompanyModalMergePreviewLine
        v-for="(customField, index) in newCustomFields"
        v-show="!toDelete && selectedFieldsProfile?.customFields?.[customField.key] !== null"
        :key="`selected-custom-field-${index}`"
        :class="{'hidden': !getCustomFieldValue(customField)}"
        :icon="customField.icon"
        :title="customField.name">
        <div class="flex flex-wrap justify-end gap-[5px]">
          <Tag
            v-tooltip.bottom="customField.name"
            :text="getCustomFieldValue(customField)"
            :type="customField.key"/>
          <div
            v-if="getCustomFieldImportedStatus(customField) === 'imported'"
            class="icon-check flex size-5 items-center justify-center rounded-full bg-green-550 text-[10px] text-white">
          </div>
        </div>
      </PanelCompanyModalMergePreviewLine>
    </div>
  </div>
</template>
