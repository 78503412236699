<script lang="ts" setup>
  import { Company } from '@/domains/models/Company';
  import { defineEmits, defineProps, ref, watch, withDefaults } from 'vue';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import PanelEditAvatar from '@/components/Panel/PanelEdit/PanelEditAvatar.vue';
  import companyApi from '@/api/company';
  import companyHttp from '@/api/company';
  import { useStore } from '@/store';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete';
  import BcAvatar from '@/ui-kit/components/BcAvatar';
  import { debounce } from '@/common-old/mixins/Debounce/debounce';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { useI18n } from '@/i18n/i18n';
  import { setAlternativeImg } from '@/mixins/imgFallback';

  interface Props {
    company: Company;
    isCreating?: boolean;
  }

  interface EditedProfile {
    logoUrl: string;
    name: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    isCreating: false,
  });
  const emit = defineEmits<{
    (e: 'edit-company', value: boolean): void,
    (e: 'update-company', value: EditedProfile): void
    (e: 'create-company'): void
  }>();

  const store = useStore();
  const route = useRoute();
  const toast = useToast();
  const router = useRouter();
  const i18n = useI18n();
  const cardActive = store.getters.cardActive;

  const observer = ref(null);

  const editedProfile = ref<EditedProfile>({
    name: props.company?.name || '',
    logoUrl: props.company?.logoUrl || '',
  });
  const setEditedProfile = (data) => {
    editedProfile.value = {
      ...editedProfile.value,
      ...data,
    };
  };
  const closeEdit = () => {
    emit('edit-company', false);
  };

  const errors = ref([]);
  const partnerSuggestions = ref([]);

  const getPartnerSuggestions = debounce(async function() {
    try {
      const { data } = await companyHttp.partnersAutocomplete(editedProfile.value.name);

      partnerSuggestions.value = data.filter(partner => partner._id !== route.params.id);
    } catch (error) {
      toast.show({
        type: 'error',
        message: i18n.t('toast.error-occured'),
      });
    }
  }, 500);

  const saveCompany = (async() => {
    if (!props.isCreating) {
      const { data } = await companyApi.updatePartner({
        id: props.company._id,
        obj: editedProfile.value,
      });

      if (cardActive >= 0) {
        await store.dispatch('updateCard', { index: cardActive, content: data });
      }
      emit('update-company', editedProfile.value);
      closeEdit();
    } else {
      emit('create-company');
    }
  });

  // creating case
  watch(() => ({ name: editedProfile.value.name, logoUrl: editedProfile.value.logoUrl }), (value) => {
    if (props.isCreating) {
      emit('update-company', value);
    }
  }, { deep: true });

  watch(() => editedProfile.value.name, () => {
    getPartnerSuggestions();
  });

  const goToPartner = (option) => {
    router.push({
      name: 'PartnersPanel',
      params: {
        id: option._id,
      },
      query: {
        type: 'companies',
      },
    });
  };
</script>

<template>
  <div class="relative flex shrink-0 flex-row items-start gap-x-8 pt-5">
    <PanelEditAvatar
      :edited-profile="editedProfile"
      :profile="company"
      :is-company="true"
      @update-value="setEditedProfile"/>
    <div class="relative flex grow flex-row flex-wrap gap-y-2.5">
      <BcAutocomplete
        v-model="editedProfile.name"
        :error="errors[0]"
        :is-scrollable="false"
        icon="companies"
        :label="$t('panel-company-edit.company-name')"
        :label-placeholder="false"
        :options="partnerSuggestions"
        :valid="editedProfile.name !== ''"
        class="panel-company-edit-name__input"
        name="Nom"
        required
        @select="goToPartner">
        <template #prepend>
          <div
            v-if="errors.length"
            class="panel-company-edit-name__conflict">
            <i class="panel-company-edit-name__conflict-icon icon-alert"/>
          </div>
        </template>
        <template #option="{ option }">
          <router-link
            :to="{
              name: 'PartnersPanel',
              params: { id: option._id },
              query: { type: 'companies' }
            }"
            class="panel-company-edit-name__link">
            <BcAvatar
              :src="option?.logoUrl"
              @error="setAlternativeImg({event: $event, type: 'company'})"
              class="panel-company-edit-name__img"
              size="xs">
            </BcAvatar>
            <p class="panel-company-edit-name__text">
              {{ option.name }}
            </p>
          </router-link>
        </template>
      </BcAutocomplete>
      <div
        class="z-0 ml-auto flex flex-row gap-x-2.5">
        <BcButton
          icon-left="cross"
          size="small"
          type="outlined"
          @click="closeEdit">
          {{ $t('global.cancel') }}
        </BcButton>
        <BcButton
          color="primary"
          icon-left="check"
          size="small"
          @click.prevent="saveCompany">
          {{ $t('global.save') }}
        </BcButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .panel-company-edit-name {
    width: calc(50% - 10px);
    flex-wrap: wrap;
    justify-content: space-between;

    &__title {
      width: 100%;
    }

    &__asterisk {
      color: $color-red-mandy;
      margin-right: 10px;
    }

    &__input {
      width: 100%;
    }

    &__not-found {
      align-items: center;
      width: 100%;
      height: 46px;
      padding: 0 20px;
    }

    &__link {
      display: flex;
      width: 100%;
      height: 46px;
      text-decoration: none;
      color: inherit;
      align-items: center;
      padding: 0 20px;

      &--active {
        background-color: $color-blue-dodger;
        color: white;
      }
    }

    &__item {
      display: flex;
      list-style: none;
    }

    &__img {
      overflow: hidden;
      margin-right: 10px;
    }

    &__text {
      @include font-size(16px);
    }

    &__conflict {
      justify-content: center;
      align-items: center;
      background-color: $color-red-mandy;
      color: white;
      border-radius: $border-radius-rounded;
      padding: 5px;
      margin: 0 -10px 0 20px;

      &-icon {
        font-size: $font-size-xs;
        height: 13px;
      }
    }

  }
</style>
