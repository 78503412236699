import { defineStore } from 'pinia';
import {
  createNotesTemplate,
  deleteNotesTemplate,
  editNotesTemplate,
  getNotesTemplates,
} from '@/api/notesTemplates';
import {
  NotesTemplate,
  NotesTemplateDraft,
  NotesTemplatePatch,
} from '@/domains/models/NotesTemplate';
import Vue from 'vue';

type State = {
  items: NotesTemplate[];
  isLoading: boolean;
}
export const useNotesTemplatesStore = defineStore('notesTemplates', {
  state: (): State => {
    return {
      items: [],
      isLoading: false,
    };
  },
  actions: {
    async fetchNotesTemplates({ coachId, all, sorted = false }: {
      coachId: string | null
      all: boolean | null
      sorted: boolean
    }): Promise<void> {
      this.isLoading = true;
      this.items = [];
      try {
        const { data } = await getNotesTemplates({ coachId, all });

        this.items = sorted ? data : data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        throw new Error('Could not fetch notes templates');
      }
    },
    async createTemplate(template: NotesTemplateDraft): Promise<void> {
      try {
        const { data } = await createNotesTemplate({ name: template.name, body: template.body });

        this.items = [data, ...this.items];
      } catch (e) {
        throw new Error('Could not create notes template');
      }
    },
    async editTemplate(template: NotesTemplatePatch): Promise<void> {
      try {
        const { data } = await editNotesTemplate({
          _id: template._id,
          ...template.name && { name: template.name },
          ...template.body && { body: template.body },
          ...template.activated !== undefined && { activated: template.activated },
        });

        const index = this.items.findIndex((item) => item._id === template._id);

        // legacy code to make array reactive with Vue 2
        Vue.set(this.items, index, { ...this.items[index], ...data });
      } catch (e) {
        throw new Error('Could not edit notes template');
      }
    },
    async deleteTemplate(templateId: string): Promise<void> {
      try {
        await deleteNotesTemplate({ id: templateId });

        const index = this.items.findIndex((item) => item._id === templateId);
        this.items.splice(index, 1);
      } catch (e) {
        throw new Error('Could not delete notes template');
      }
    },
  },
});
