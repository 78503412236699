<template>
  <div
    ref="container"
    :class="{ 'notes-editor--expanded': isExpanded || isEditing || images.length }"
    class="notes-editor is-column is-relative gap-3"
  >
    <button
      v-if="isEditing"
      class="icon-cross absolute right-4 top-4 z-10 p-1 text-xs text-blue-800 hover:text-blue-500"
      @click="cancelEdit">
    </button>
    <div class="overflow-hidden p-[20px]">
      <bc-rich-text-editor-bubble
        v-show="!images.length || formattedContent !== '-' || isEditing"
        ref="textarea"
        :key="`${action._id}-${randomKey}`"
        v-model="formattedContent"
        v-autosize="formattedContent"
        :is-editable="isEditing"
        :mention-suggestions="mentionCoaches"
        :placeholder="$t('companies.panel-view-timeline.start-writing')"
        class="notes-editor__editor"
        :class="{'notes-editor__editor--expanded': isExpanded}"
        position="absolute"
        @on-mention-select="onMentionSelect"
        @show-suggestions="showSuggestions = $event"
      >
      </bc-rich-text-editor-bubble>
    </div>
    <a
      v-show="canSeeMore && !isExpanded && !isEditing"
      class="notes-editor__see-more is-underline font-size-s px-[20px] pb-[20px]"
      @click="isExpanded = true"
    >
      {{ $t('companies.panel-view-timeline.see-more') }}
    </a>
    <NotesImages
      :images="images"
      :is-read-only="!isEditing"
      class="notes-editor__footer -mt-4 px-[20px]"
      @remove-image="handleRemoveImage"
    />
    <qualification-item-coders-view
      v-if="isCandidate && !isEditing"
      :open-to-work="openToWork"
      :rating="rating"
      class="notes-editor__footer px-[20px]">
    </qualification-item-coders-view>
    <qualification-item-coders-edit
      v-if="isCandidate && isEditing && isEditable"
      :open-to-work="openToWork"
      :rating="rating"
      class="notes-editor__footer px-[20px]"
      @update:rating="rating = $event"
      @update:open-to-work="openToWork = $event"
    >
    </qualification-item-coders-edit>
    <NotesEditorFooter
      v-if="isEditing"
      :can-pin="true"
      :container-size="containerSize"
      :images="images"
      :is-valid="isValid"
      :pin="pin"
      :templates="templates"
      :tooltip-content="tooltipContent"
      :user-profile="user"
      class="px-[20px] pb-[20px]"
      @send-note="editNote"
      @add-image="handleAddImage"
      @select-template="handleSelectTemplate"
      @update:pin="pin = $event"
    />
    <ModalConfirm
      :cancel-text="$t('generics.no-cancel-this-action')"
      :confirm-text="$t('generics.yes')"
      :content="$t('templates.your-entire-input-will-be-deleted')"
      :is-open="isTemplateModalOpen"
      :title="$t('templates.do-you-want-to-use-this-template')"
      color="success"
      icon="📝"
      @close="handleCloseTemplateModal"
      @on-confirm="handleConfirmTemplate">
    </ModalConfirm>
  </div>
</template>

<script>
  import BcRichTextEditorBubble from '@/ui-kit/components/BcRichTextEditor/BcRichTextEditorBubble';
  import TimelineItemContent from '@/mixins/Timeline/timelineItemContent';
  import NotesEditorMixin from '@/mixins/Notes/notes';
  import { noteController } from '@/managers/notes/controller';
  import { mapGetters, mapState } from 'vuex';
  import notes from '@/common-old/macros/notes';
  import { getTypeNameFromApiValue } from '@/managers/notes/formatters/noteDeserializers';
  import QualificationItemCodersEdit
    from '@/components/Qualifications/QualificationsItem/QualificationItemCodersEdit';
  import locale from '@/mixins/i18n/locale';
  import { useElementSize } from '@vueuse/core';
  import NotesImages from '@/components/Notes/NotesImages.vue';
  import QualificationItemCodersView
    from '@/components/Qualifications/QualificationsItem/QualificationItemCodersView.vue';
  import NotesEditorFooter from '@/components/Notes/NotesEditor/NotesEditorFooter.vue';
  import { mapState as piniaMapState } from 'pinia';
  import { useNotesTemplatesStore } from '@/store/pinia/notesTemplates';
  import ModalConfirm from '@/components/Modal/ModalConfirm.vue';

  export default {
    name: 'notes-editor',
    components: {
      ModalConfirm,
      NotesEditorFooter,
      QualificationItemCodersView,
      NotesImages,
      QualificationItemCodersEdit,
      BcRichTextEditorBubble,
    },
    mixins: [TimelineItemContent, NotesEditorMixin, locale],
    props: {
      action: {
        type: Object,
        default: () => ({}),
      },
      isNewNote: {
        type: Boolean,
        default: false,
      },
      isEditing: {
        type: Boolean,
        default: false,
      },
      profile: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        content: this?.action?._note?.content || '',
        images: this?.action?._note?.images || [],
        pin: false, // false by default because timeline item
        isModalOpen: false,
        minDate: undefined,
        selectedMentions: [],
        showSuggestions: false,
        randomKey: Math.random(),
        isTemplateModalOpen: false,
        awaitingSelectedTemplate: '',
      };
    },
    mounted() {
      const tomorrow = new Date();

      tomorrow.setDate(tomorrow.getDate() + 1);

      this.minDate = tomorrow;
    },
    computed: {
      ...mapGetters({
        mentionCoaches: 'mentionCoaches',
      }),
      ...mapState({
        user: state => state.user.profile,
      }),
      ...piniaMapState(useNotesTemplatesStore, { notesTemplates: 'items' }),
      templates() {
        return this.notesTemplates.map(template => ({
          ...template,
          author: template._coach,
        }));
      },
      containerHeight() {
        return useElementSize(this.$refs.textarea)?.height;
      },
      contentHeight() {
        return useElementSize(this.$refs.textarea?.$refs?.reference)?.height;
      },
      canSeeMore() {
        return this.containerHeight.value < this.contentHeight.value;
      },
      isCandidate() {
        return (this.$route.query.type === 'coders' || this.$route.query.type === 'suggestions-coders');
      },
      containerSize() {
        return useElementSize(this.$refs['container']);
      },
      userId() {
        return this.$store.state.user.profile._id;
      },
      isEditable() {
        return this.userId === ((this.action || {})._coach || {})._id;
      },
      linkedProcesses() {
        return this.action._linkedProcesses || [];
      },
      actionNote() {
        return this?.action?._note;
      },
      isContentFilled() {
        return this.hasOwnProperty('content')
          ? !!this.getPlainText(this.content)
          : !!this.getPlainText(this.actionNote?.content);
      },
      isRatingFilled() {
        return this.hasOwnProperty('rating')
          ? this.rating >= 0
          : this.actionNote?.rating >= 0;
      },
      isDateToContactFilled() {
        return this.hasOwnProperty('dateToContact')
          ? !!this.dateToContact
          : !!this.actionNote?.dateToContact;
      },
      isOpenToWorkFilled() {
        return this.hasOwnProperty('openToWork')
          ? !!this.openToWork
          : !!this.actionNote?.openToWork;
      },
      isSalaryFilled() {
        return this.hasOwnProperty('salary')
          ? !!this.salary
          : !!this.actionNote?.salary;
      },
      isSalaryWantedFilled() {
        return this.hasOwnProperty('salaryWanted')
          ? !!this.salaryWanted
          : !!this.actionNote?.salaryWanted;
      },
      isNoteBecomesQualification() {
        return (
          this.type !==
          getTypeNameFromApiValue({ type: notes.type.qualification.api }) &&
          this.isContentFilled &&
          this.isRatingFilled &&
          (this.isDateToContactFilled || this.isOpenToWorkFilled) &&
          (this.isSalaryFilled || this.isSalaryWantedFilled)
        );
      },
    },
    watch: {
      content() {
        this.saveNoteToLocalStorage();
      },
      isEditing(to) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            typing: to || undefined,
          },
        });
      },
    },
    methods: {
      handleRemoveImage(index) {
        this.images.splice(index, 1);
      },
      handleAddImage(image) {
        this.images.push(image);
      },
      handleSelectTemplate(template) {
        if (this.content) {
          this.awaitingSelectedTemplate = template.body;
          this.isTemplateModalOpen = true;
        } else {
          this.content = template.body;
          ++this.randomKey;
        }
      },
      handleConfirmTemplate() {
        this.content = this.awaitingSelectedTemplate;
        ++this.randomKey;
        this.handleCloseTemplateModal();
      },
      handleCloseTemplateModal() {
        this.awaitingSelectedTemplate = '';
        this.isTemplateModalOpen = false;
      },
      onMentionSelect(mention) {
        this.selectedMentions = [...this.selectedMentions, mention];
      },
      syncWithDomMentions() {
        let domMentions = [];
        if (this.$refs['textarea']?.$el) {
          const mentionsNodes = Array.from(this.$refs['textarea'].$el.querySelectorAll('.mention'));
          domMentions = mentionsNodes.map(node => node.dataset.mentionId);
          const selectedCoaches = this.mentionCoaches.filter(coach => domMentions.includes(coach.id));
          this.selectedMentions = selectedCoaches;
        }
      },
      getPlainText(text) {
        return text.replace(/<[a-z]\s*\/?>|<\/[a-z]\s*\/?>/gm, '');
      },
      setIsEditing(value) {
        this.$emit('update:isEditing', value);
      },
      cancelEdit() {
        this.content = this.action?._note?.content ?? '';
        this.rating = this.action?._note?.rating ?? null;
        this.openToWork = this.action?._note?.openToWork ?? false;
        this.pin = this.action?._note?.pin ?? false;
        this.images = this.action?._note?.images ?? [];

        this.clearSavedNote();

        this.setIsEditing(false);
      },
      setDefaultNoteToSavedNote() {
        if (localStorage.getItem(`note-${(this.action || {})._id}`)) {
          const { content } = JSON.parse(
            localStorage.getItem(`note-${(this.action || {})._id}`),
          );

          this.content = content || '';
        }
      },
      saveNoteToLocalStorage() {
        localStorage.setItem(
          `note-${(this.action || {})._id}`,
          JSON.stringify({
            content: this.content,
          }),
        );
      },
      clearSavedNote() {
        localStorage.removeItem(`note-${(this.action || {})._id}`);
      },
      async editNote() {
        this.syncWithDomMentions();

        try {
          const note = {
            content: this.content,
            ...(this.type && { type: this.type }),
            ...(this.rating && { rating: this.rating }),
            openToWork: this.openToWork ?? undefined,
            pin: this.pin ?? undefined,
            images: this.images ?? undefined,
          };

          await noteController.editNote({
            id: this.action?._note?._id,
            note,
            mentions: this.selectedMentions.map(mention => mention.id),
          });

          if (this.pin) {
            this.$emit('note-pinned');
          }

          if (this.selectedMentions.length) {
            this.$toast.show({
              type: 'success',
              message: this.$t('notifications.send-with-mentions', {
                mentions: this.selectedMentions.map(mention => mention.name).join(', '),
              }),
            });
          }

          this.setIsEditing(false);

          /**
           * We emit the edited event to the parent to update the timeline
           */
          this.$emit('note-edited', {
            _id: this.action._id,
            note,
          });

        } catch (error) {
          this.$toast.show({
            message: error?.response?.data?.message || error,
            type: 'error',
            icon: 'cross',
          });
        }
      },
      unlinkProcess($event) {
        this.$emit('unlink-process', {
          noteId: this.action._note._id,
          processId: $event,
        });
      },
    },
  };
</script>

<style lang=scss scoped>
  .notes-editor {


    &--expanded {
      max-height: initial;
    }

    &__footer {
      &:last-of-type {
        padding-bottom: 20px;
      }
    }

    &__editor {
      resize: none;
      max-height: 4.6em;
      color: $color-secondary-light;
      font-family: $font-family;
      overflow: hidden;
      margin-bottom: 0;

      &--expanded {
        max-height: initial;
      }

      &:deep(.bc-rich-text-editor-bubble) {
        max-height: initial;
      }

      &:deep(.bc-rich-text-editor-bubble__container) {
        overflow: hidden;
      }

      &:deep(.bc-rich-text-editor-bubble__textarea) {
        overflow: hidden;
      }

      &:deep(.bc-rich-text-editor-bubble) {
        max-height: initial;
      }

      &:deep(.bc-rich-text-editor-bubble) {
        max-height: initial;
      }
    }

    &__see-more {
      color: $color-primary;
      margin-right: auto;
    }

    &__container {
      .new--note--echange {
        flex-direction: column;
        padding: 0;

        .skills {
          margin-bottom: 16px;
          flex-direction: column;
          width: 100%;

          .bc-autocomplete {
            margin-bottom: 8px;
            max-width: 300px;
          }

          section {
            display: flex;
            flex-direction: row;
            padding: 0;
            max-width: 100%;
            flex-wrap: wrap;

            .bc-tag {
              margin-right: 8px;
              width: max-content;
              flex: 0 0 auto;
              margin-bottom: 8px;
            }
          }
        }

        .salary {
          margin-bottom: 16px;
          width: 100%;

          p {
            margin-right: 8px;
          }

          div {
            margin-right: 16px;
          }

          input {
            padding: 8px;
          }
        }

        .additional {
          align-items: center;
          width: 100%;

          .bc-checkbox {
            margin: 0;
          }

          p {
            margin: 0 16px;
          }

          .bc-dropdown {
            min-width: 20%;

            .bc-dropdown__input {
              button {
                border-color: #3c80f74d;
                color: #3c80f7;
              }
            }
          }
        }
      }

      .new--note {
        margin-bottom: 16px;
        align-items: center;
        width: 100%;

        p {
          margin-left: 1.15rem;
          margin-right: 8px;
        }

        .bc-dropdown {
          width: 16%;

          .bc-dropdown__input {
            button {
              border-color: #3c80f74d;
              color: #3c80f7;
            }
          }
        }
      }

      .timeline-editor__checkbox {
        margin: 0.5em 1.5em;
      }
    }

    &__wrapper {
      margin-bottom: 25px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__title {
      color: $color-secondary;
      margin: 0;
    }

    &__types {
      margin-top: 15px;
      background: rgba($color-primary, 0.1);
    }

    &__type {
      padding: 5px;
      color: $color-primary;
      border: 1px solid transparent;
      @include bp('tablet') {
        padding: 10px 20px;
      }

      &:hover {
        border: 1px solid $color-primary;
      }

      &--active {
        border: 1px solid $color-primary;
        background: $color-white;
      }
    }

    &__checkboxes {
      margin: 0.5em -1.5em;
    }

    &__checkbox {
      margin: 0.5em 1.5em;
    }

    &__label {
      cursor: pointer;

      &:before {
        margin-right: 5px;
        font-size: 18px;
      }
    }

    &__button {
      margin-right: 10px;
      height: 25px;
      width: 25px;
      color: $color-white;

      &:last-of-type {
        margin-right: 0;
      }

      &--red {
        background: $color-error;
      }

      &--blue {
        background: $color-primary;
      }
    }

    &__modal {
      padding: 30px;
      color: $color-secondary;
    }

    &__modal-wrapper {
      margin-top: 20px;
    }

    &__modal-title {
      margin-top: 0;
    }

    &__modal-button {
      margin: 0 10px;
    }

    &__link {
      margin-right: 10px;
    }
  }
</style>
