<template>
  <div
    :ref="reference"
    class="card-tags is-unshrink">
    <div
      :key="index"
      class="card-tags__tag"
      v-for="(tag, index) in tagsLimited">
      <slot
        :tag="tag"
        name="tag">
      </slot>
    </div>
    <div
      class="card-tags__tag"
      v-if="tagsRemaining.length > 0"
      v-tooltip="tooltip">
      <slot
        :remaining="tagsRemaining.length"
        name="remaining">
      </slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'card-tags',
    props: {
      tags: {
        type: Array,
        default: () => [],
      },
      isVisible: {
        type: Boolean,
        default: false,
      },
      reference: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        tagsContainerWidth: 0,
        tagsLimited: [],
        tagsRemaining: [],
      };
    },
    computed: {
      tooltip() {
        return this.tagsRemaining.length ? this.tagsRemaining?.map(t => t.name).join(', ') : '';
      },
    },
    created() {
      window.addEventListener('resize', this.setTagsContainerWidth);
    },
    mounted() {
      this.setTagsContainerWidth();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.setTagsContainerWidth);
    },
    watch: {
      tagsContainerWidth: {
        handler() {
          this.setTagsArrays();
        },
        immediate: true,
      },
      tags() {
        this.setTagsArrays();
      },
    },
    methods: {
      setTagsContainerWidth() {
        this.tagsContainerWidth = this.$refs[this.reference].offsetWidth;
      },
      setTagsArrays() {
        const margin = 5 * 2;

        const tags = this.tags.length > 0 ? [...this.tags] : [];
        const tagsLimited = [];
        let tagsContentWidth = this.$refs[this.reference]?.$el?.clientWidth + margin || 0;
        for (let i = 0;
             tagsContentWidth < this.tagsContainerWidth && tagsLimited.length < tags.length;
             i++) {
          tagsLimited.push(tags[i]);
          tagsContentWidth = tagsContentWidth + tags[i]?.name?.length * 8.5 + 60;
        }
        if (tagsContentWidth > this.tagsContainerWidth) {
          if (tagsLimited.length > 1) {
            tagsLimited.pop();
          }

          this.tagsLimited = tagsLimited;
          this.tagsRemaining = tags.length > 1 ? tags.splice(tagsLimited.length) : [];
        } else {
          this.tagsLimited = tags;
          this.tagsRemaining = tags.length > 1 ? tags.splice(tagsLimited.length) : [];

        }
      },
    },
  };
</script>

<style lang=scss scoped>
  .card-tags {
    max-width: 100%;
    width: 100%;
    margin: -5px;

    &__tag {
      justify-content: center;
      align-items: center;
      max-width: 100%;
      margin: 5px;
    }

    &__tag:first-child {
      overflow: hidden;
    }
  }
</style>
