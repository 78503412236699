<template>
  <div
    class="panel-error col-xs-12">
    <panel-error-header></panel-error-header>
    <panel-error-body></panel-error-body>
  </div>
</template>

<script>
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation.js';
  //
  import PanelErrorHeader from '@/components/Panel/PanelError/PanelErrorHeader';
  import PanelErrorBody from '@/components/Panel/PanelError/PanelErrorBody';

  export default {
    components: {
      PanelErrorBody,
      PanelErrorHeader
    },
    name: 'panel-error',
    mixins: [PanelNavigation]
  }
</script>

<style scoped lang="scss">
  .panel-error {
    height: 100%;
    background: $color-white;
  }
</style>
