import { apiRole } from '@/macros/coders/roles';
import { apiContract } from '@/macros/coders/contracts';
import { apiPersonality } from '@/macros/coders/personalities';
import { apiRemote } from '@/common-old/macros/remote';

export const getRoleApiValueFromRoleNumber = role => Object.keys(apiRole).find(key => apiRole[key] === role);

export const getLocationsPlaceIdFromLocationsArray = locations => locations.map(location => location.place_id || location.placeId);

export const getSkillsIdsFromSkillsArray = skills => skills.map(skill => skill._id);

export const getContractApiValueFromContractNumber = contract => {
  return Object.keys(apiContract).find(key => apiContract[key] === contract);
};

export const getContractApiValueFromContractArray = contracts => contracts.map(contract => getContractApiValueFromContractNumber(contract));

export const getPersonalityApiValueFromPersonalityNumber = personality => Object.keys(apiPersonality).find(key => apiPersonality[key] === personality);

export const getHobbiesIdsFromHobbiesArray = hobbies => hobbies.map(hobby => hobby._id);

export const getValuesIdsFromValuesArray = values => values.map(value => value._id);

export const getRoleApiValuesFromRoleArray = roles => roles.map(role => getRoleApiValueFromRoleNumber(role));

export const getRemoteApiValueFromRemoteNumber = apiValue => Object.keys(apiRemote).find(key => apiRemote[key] === apiValue);

export const getRemoteApiValuesFromRemoteArray = remotes => remotes.map(remote => getRemoteApiValueFromRemoteNumber(remote));

export const serializeCoder = coder => {
  const serializeMap = {
    role: getRoleApiValueFromRoleNumber,
    locations: getLocationsPlaceIdFromLocationsArray,
    _technos3: getSkillsIdsFromSkillsArray,
    _technos2: getSkillsIdsFromSkillsArray,
    _technos1: getSkillsIdsFromSkillsArray,
    contracts: getContractApiValueFromContractArray,
    personality: getPersonalityApiValueFromPersonalityNumber,
    _hobbies: getHobbiesIdsFromHobbiesArray,
    _values: getValuesIdsFromValuesArray,
    roleWanted: getRoleApiValuesFromRoleArray,
    remote: getRemoteApiValuesFromRemoteArray,
    locationsWanted: getLocationsPlaceIdFromLocationsArray,
    _skillsWanted: getSkillsIdsFromSkillsArray,
    _domains: getSkillsIdsFromSkillsArray,
    _professions: getSkillsIdsFromSkillsArray,
  };

  const formattedCoder = { ...coder };

  Object.keys(serializeMap).forEach(key => {
    if (formattedCoder[key]) {
      formattedCoder[key] = serializeMap[key](formattedCoder[key]);
    }
  });

  return formattedCoder;
};
