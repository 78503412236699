<template>
  <transition
    mode="out-in"
    name="fade">
    <slot></slot>
  </transition>
</template>

<script>
  export default {
    name: 'transition-fade'
  };
</script>

<style lang="scss" scoped>
  .fade-enter-active, .fade-leave-active {
    @include transition(opacity .5s);
  }

  .fade-enter, .fade-leave-to {
    @include opacity(0);
  }
</style>
