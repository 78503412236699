import tags from '@/api/tags';

export const deleteTag = id => {
  try {
    return tags.deleteTag(id);
  } catch (error) {
    if ((error || {}).response) {
      throw new Error(`API: ${error.response.data.message}`);
    }

    throw new Error(error);
  }
};

export default {
  deleteTag,
};
