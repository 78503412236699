<template>
  <div class="process-card-custom-tags flex flex-wrap">
    <bc-tag v-if="isAborted && !process.rejectedAt"
      class="process-card-custom-tags__tag process-card-custom-tags__tag--failed">
      {{ $t('config.failedProcessRecruitment') }}
    </bc-tag>
    <bc-tag v-else-if="process.rejectedAt" class="process-card-custom-tags__tag process-card-custom-tags__tag--failed">
      {{ $t('generics.rejected') }}
    </bc-tag>
    <bc-tag v-if="amount && showCommission"
      class="process-card-custom-tags__tag process-card-custom-tags__tag--commission">
      <div v-tooltip.bottom="{ content: $t('generics.forecast-amount') }" class="flex items-center gap-1">
        <i class="icon-coin-alt"></i>
        <span>
          {{ amount }} €
        </span>
      </div>
    </bc-tag>
    <bc-tag v-if="displayNextInterview && process.introducedAt"
      v-tooltip.bottom="{ content: $t('process.next-presentation-date') }"
      class="process-card-custom-tags__tag process-card-custom-tags__tag--commission" icon="clock">
      {{ getShorthandDate(process.introducedAt) }}
    </bc-tag>
    <bc-tag v-if="displayNextInterview && process.interviewedAt"
      v-tooltip.bottom="{ content: $t('process.next-interview-date') }"
      class="process-card-custom-tags__tag process-card-custom-tags__tag--commission" icon="headphones">
      {{ getShorthandDate(process.interviewedAt) }}
    </bc-tag>
    <bc-tag v-if="displayNextInterview && process.integratedAt"
      v-tooltip.bottom="{ content: $t('process.integration-date') }"
      class="process-card-custom-tags__tag process-card-custom-tags__tag--commission" icon="partners">
      {{ getShorthandDate(process.integratedAt) }}
    </bc-tag>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BcTag from '@/ui-kit/components/BcTag/BcTag.vue';
import { getShorthandDate } from '../../../helpers/date.js';

export default {
  name: 'process-custom-card-tags',
  methods: { getShorthandDate },
  components: {
    BcTag,
  },
  props: {
    process: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      configurationProcess: 'user/configurationProcess',
    }),
    showCommission() {
      return this.configurationProcess?.hasAmount;
    },
    displayNextInterview() {
      return this.configurationProcess?.displayNextInterview;
    },
    isAborted() {
      return this.process.abortedAt;
    },
    amount() {
      if (this.process.dealAcceptedAt) {
        return this.process.amountBilled?.toLocaleString();
      }
      if (this.process.commissionCustom) {
        return this.process.commissionCustom;
      }
      if (this.process.commission) {
        return this.process.commission;
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.process-card-custom-tags {
  align-items: center;
  gap: 5px;

  &__tag {
    color: $color-white;
    font-size: $font-size-xs;
    padding: 3px 10px;
    border-radius: $border-radius-l;
    margin-top: 5px;

    &--commission {
      color: $color-tertiary-2;
      font-weight: 500;
    }

    &--failed {
      background: #F5DFE0;
      color: $color-red-mandy;
      font-weight: 500;
    }
  }
}
</style>
