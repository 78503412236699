<template>
  <div class="configuration-jobboards-item">
    <div
      class="configuration-jobboards-item__name"
      style="width: 40%;"
    >
      <JobboardLogo :logoUrl="jobboard.logoUrl" :connectorName="jobboard.connectorName" />
      <p>{{ channelSubscription.title }}</p>
    </div>
    <div
      class="configuration-jobboards-item__status"
      style="width: 20%;"
    >
      <div v-if="isConnected" class="configuration-jobboards-item__tag configuration-jobboards-item__tag--success">
        {{ $t('generics.connected') }}
      </div>
      <div v-else class="configuration-jobboards-item__tag configuration-jobboards-item__tag--error">
        {{ $t('generics.disconnected') }}
      </div>
    </div>
    <div
      class="configuration-jobboards-item__subscription"
      style="width: 30%;"
    >
      <div class="configuration-jobboards-item__tag">
        {{ subscriptionsModelsLabels[subscriptionModel] || '-' }}
      </div>
    </div>
    <div
      class="configuration-jobboards-item__edit"
      style="width: 10%;"
    >
      <bc-button
        :disabled="disabled"
        icon-center="edit-3"
        size="extra-small"
        @click="$emit('edit', channelSubscription)"
      ></bc-button>
    </div>
  </div>
</template>

<script>
import { subscriptionsModelsLabels } from '@/api/xtramile';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import JobboardLogo from '@/components/Jobboard/JobboardLogo';

export default {
  name: 'configuration-jobboards-item',
  components: {
    BcButton,
    JobboardLogo,
  },
  props: {
    jobboard: {
      type: Object,
      default: () => ({}),
    },
    channelSubscription: {
      type: Object,
      default: () => ({}),
    },
    channelSubscriptionConfig: {
      type: Object,
      default: () => ({}),
    },
    configurationIntegrations:{
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subscriptionsModelsLabels,
    }
  },
  computed: {
    isInternalIntegration() {
      return this.jobboard?.isInternal;
    },
    internalConfiguration() {
      return this.configurationIntegrations?.job_multiposting?.[this.jobboard?.connectorName];
    },
    isConnected() {
      if (this.isInternalIntegration) {
        return this.internalConfiguration?.enabled;
      }
      return this.channelSubscriptionConfig?.response?.id;
    },
    subscriptionModel() {
      return this.channelSubscriptionConfig?.params?.subscriptionModel;
    },
  }
}
</script>

<style lang="scss" scoped>
.configuration-jobboards-item {
  width: 100%;
  border-radius: 5px;
  border: 1px solid $color-blue-medium;
  background: $color-neutre-4;
  height: 60px;
  padding: 17px 30px;
  align-items: center;

  &__name {
    align-items: center;
    gap: 10px;

    & p {
      color: $color-tertiary;
      font-weight: 700;
    }
  }

  &__logo {
    width: 27px;
    height: 27px;
    background: white;
    border-radius: 100px;
    border: 1px solid #F2F4FD;
  }

  &__tag {
    border-radius: 30px;
    color: #3D5476;
    background: $color-blue-light;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 12px;

    &--error {
      background: #F5DFE0;
      color: $color-red-mandy;
    }

    &--success {
      background: #EEF9F4;
      color: $color-green-shamrock;
    }
  }

}
</style>
