<template>
  <div class="panel-job-pokes col-xs-12">
    <card-list
      :cards="cards"
      :is-loaded="isLoaded"
      :response-obj="'pokes'"
      ref="cardList">
    </card-list>
    <infinite-scroll
      :params="params"
      @changeIsLoaded="changeIsLoaded($event)"
      @response="setCards($event), setPage($event)"
      ref="infiniteScroll">
    </infinite-scroll>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import InfiniteScroll from '@/components/UIKit/InfiniteScroll';
  import CardList from '@/components/CardList/CardList';

  /**
   * This component is the home page of the app. He calls the Api to get all pokes and pass them to the card-list.
   * <br><br>
   * data:<br>
   *     - page (Number): the number of pages (each pages contains 12 cards) you want to load when the card-list is loaded.<br>
   *     - busy (Boolean): the infinite-scroll will be disabled if the value of this attribute is true.
   */
  export default {
    name: 'panel-job-pokes',
    components: {
      CardList,
      InfiniteScroll
    },
    computed: {
      ...mapState({
        cards: state => state.panel.viewList
      }),
      /**
       * The Object that contains the params for the Api call <br><br>
       *     isLoaded: the Boolean that will display or not the 'no data found' if they're are no pages to load <br>
       *     callFn: the String that define the action to call <br>
       *     call: The String that define the right Api route <br>
       *     responseObj: The String that define the type of the response ('jobs' or 'pokes') <br>
       *     status: The Integer that define the status of the resource (0, 1, 2, 3)
       */
      params() {
        return {
          callFn: 'getJobResources',
          call: 'pipe',
          parameters: {
            resources: 'pokes',
            id: this.$route.params.id,
            page: this.page
          },
          responseObj: 'pokes'
        };
      }
    },
    data() {
      return {
        isLoaded: false,
        page: 1
      };
    },
    methods: {
      changeIsLoaded(value) {
        this.isLoaded = value;
      },
      setCards(response) {
        this.$store.dispatch('setViewList', response.data.pokes);
      },
      setPage(response) {
        if (response.data.next) {
          ++this.page;
        }
      }
    },
    beforeCreate() {
      this.$store.dispatch('emptyViewList');
    },
    beforeDestroy() {
      this.$store.dispatch('emptyViewList');
    },
    watch: {
      $route(to, from) {
        if (to.params.id !== from.params.id) {
          this.$store.dispatch('emptyViewList');
          this.$refs.infiniteScroll?.$refs?.infiniteLoading.$emit('$InfiniteLoading:reset');
          this.page = 1;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .panel-job-pokes {
    width: 100%;
    padding: 30px;
  }
</style>
