<template>
  <div class="banner">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'banner',
}
</script>

<style lang="scss" scoped>
.banner {
  display: block;
  border-radius: 5px;
  background: $color-neutre-3;
  color: $color-tertiary;
  background-image: url('@/assets/img/marvin_banner.svg');
  background-position: center;
  background-position-x: 0;
  background-repeat: space;
}
</style>
