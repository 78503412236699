export const validateName = content => {
  if (content) {
    return;
  }

  throw new Error('Le nom du tag ne contient pas suffisamment de caractères');
};

export default {
  validateName,
};
