import jobs from '@/common-old/macros/jobs';

export default {
  methods: {
    computeJobSalary(obj) {
      let res = '';

      const contractsSalaryMap = [
        {
          contract: jobs.contracts.cdi.api,
          suffix: this.$i18n.t('companies.account-management.pay-year-simple'),
          value: obj.salary
        },
        {
          contract: jobs.contracts.freelance.api,
          suffix: this.$i18n.t('companies.account-management.pay-day'),
          value: obj.salary
        },
        {
          contract: jobs.contracts.stage.api,
          value: null
        },
        {
          contract: jobs.contracts.alternance.api,
          value: null
        }
      ];

      contractsSalaryMap.forEach(contract => {
        const valueNotNull = contract.value && contract.value !== 0 && contract.value !== null;
        if (obj.contract === contract.contract && valueNotNull) {
          res += `${contract.value}${contract.suffix}`;
        }
      });

      return res;
    },
    getJobField(object, value, apiValue) {
      for (let property in jobs[object]) {
        if (jobs[object][property].api === apiValue) {
          return jobs[object][property][value];
        }
      }
    }
  }
};
