export const CONTRACT_TYPES = [
  { id: "71", name: "CDI" },
  { id: "70", name: "CDD" },
  { id: "72", name: "Stage" },
  { id: "73", name: "Travail temporaire" },
  { id: "77", name: "Franchise" },
  { id: "76", name: "Indépendant" },
  { id: "75", name: "Alternance" },
]

export const EXPERIENCES = [
  { id: "63", name: "+ 7 ans" },
  { id: "61", name: "- 1 an" },
  { id: "62", name: "1 à 7 ans" },
]

export const SECTORS = [
  { id: "146", name: "Industrie Agro-alimentaire" },
  { id: "136", name: "Service public d'état" },
  { id: "137", name: "BTP" },
  { id: "138", name: "Distribution/Commerce de gros" },
  { id: "140", name: "Industrie high-tech/Telecom" },
  { id: "141", name: "Média/Internet/Communication" },
  { id: "144", name: "Restauration" },
  { id: "143", name: "Banque/Assurance/Finance" },
  { id: "139", name: "Industrie Auto/Meca/Navale" },
  { id: "142", name: "Santé/Social/Association" },
  { id: "188", name: "Secteur Energie/Environnement" },
  { id: "189", name: "Services aux Personnes/Particuliers" },
  { id: "190", name: "Tourisme/Hôtellerie/Loisirs" },
  { id: "187", name: "Industrie Pharmaceutique/Biotechn./Chimie" },
  { id: "186", name: "Industrie Pétrolière/Pétrochimie" },
  { id: "185", name: "Industrie Manufacturière" },
  { id: "184", name: "Industrie Aéronautique/Aérospatial" },
  { id: "183", name: "Enseignement/Formation" },
  { id: "182", name: "Transport/Logistique" },
  { id: "181", name: "Immobilier" },
  { id: "180", name: "Agriculture/Pêche" },
  { id: "152", name: "Services aux Entreprises" },
  { id: "145", name: "Secteur informatique/ESN" },
  { id: "192", name: "Service public hospitalier" },
  { id: "193", name: "Service public autres" },
  { id: "191", name: "Service public des collectivités territoriales" },
]

export const QUALIFICATIONS_LEVELS = [
  { id: "33", name: "BEP/CAP" },
  { id: "159", name: "Agent de maîtrise/Bac +3/4" },
  { id: "31", name: "Employé/Opérateur/Ouvrier Spe/Bac" },
  { id: "30", name: "Technicien/Employé Bac +2" },
  { id: "28", name: "Cadre dirigeant" },
  { id: "29", name: "Ingénieur/Cadre/Bac +5" },
]

export const PROFESSIONS = [
  { id: "47", name: "BTP - Gros Oeuvre/Second Oeuvre" },
  { id: "42", name: "Achat" },
  { id: "130", name: "Compta/Gestion/Finance/Audit" },
  { id: "46", name: "Informatique - Développement" },
  { id: "43", name: "Commercial-Vendeur en magasin" },
  { id: "123", name: "Informatique - Systèmes d'Information" },
  { id: "124", name: "Informatique - Systèmes/Réseaux" },
  { id: "125", name: "Ingénierie - Agro/Agri" },
  { id: "126", name: "Ingénierie - Chimie/Pharmacie/Bio." },
  { id: "127", name: "Ingénierie - Electro-tech./Automat." },
  { id: "128", name: "Ingénierie - Mécanique/Aéron." },
  { id: "129", name: "Ingénierie - Telecoms/Electronique" },
  { id: "45", name: "Marketing/Communication/Graphisme" },
  { id: "131", name: "Production - Gestion/Maintenance" },
  { id: "133", name: "Qualité/Hygiène/Sécurité/Environnement" },
  { id: "134", name: "RH/Personnel/Formation" },
  { id: "135", name: "Assistanat/Adm.ventes/Accueil" },
  { id: "153", name: "Import/Export/International" },
  { id: "154", name: "Informatique - Dével. Hardware" },
  { id: "49", name: "Commercial auprès des professionnels" },
  { id: "50", name: "Commercial auprès des particuliers" },
  { id: "53", name: "SAV/Hotline/Téléconseiller" },
  { id: "194", name: "Logistique/Métiers du Transport" },
  { id: "195", name: "Bureau d'Etudes/R&amp;D/BTP archi/conception" },
  { id: "196", name: "Direction/Resp. Co. et Centre de Profit" },
  { id: "197", name: "Commercial - Technico-Commercial" },
  { id: "198", name: "Métiers de la Fonction Publique" },
  { id: "199", name: "Production - Opérateur/Manoeuvre" },
  { id: "200", name: "Services à la personne/aux entreprises" },
  { id: "201", name: "Négociation/Gestion immobilière" },
  { id: "202", name: "Juridique/Droit" },
  { id: "203", name: "Métiers de la distribution - Management/Resp." },
  { id: "162", name: "Santé/Social" },
  { id: "163", name: "Restauration/Tourisme/Hôtellerie/Loisirs" },
]

export const SALARY_PERIODS = [
  { id: 'YEAR', name: 'Année' },
  { id: 'MONTH', name: 'Mois' },
  { id: 'WEEK', name: 'Semaine' },
  { id: 'DAY', name: 'Jour' },
  { id: 'HOUR', name: 'Heure' },
]
