<template>
  <div class="panel-poke-caption">
    <div class="panel-poke-caption__container">
      <panel-poke-caption-pokers :card-content="profile"></panel-poke-caption-pokers>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import PanelPokeCaptionPokers from '@/components/Panel/PanelPoke/PanelPokeCaption/PanelPokeCaptionPokers';

  export default {
    /**
     * The caption of the panel poke when the user will see all the poke details
     */
    name: 'panel-poke-caption',
    components: {
      PanelPokeCaptionPokers
    },
    props: {
      /**
       * Determinate if it should display the placeholder component
       */
      isLoading: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapState({
        profile: state => state.panel.profile
      })
    }
  };
</script>

<style scoped lang="scss">
  .panel-poke-caption {
    position: relative;
    width: 100%;
    &__container {
      width: 100%;
      flex-direction: column;
      align-items: center;
      display: block;
      @include bp('tablet') {
        flex-shrink: 0;
        position: initial;
        padding: 10px 0;
        width: 100%;
        flex-direction: row;
      }
    }
    &__wrapper {
      max-width: 550px;
      width: 100%;
      position: relative;
      margin: 0 auto;
      justify-content: center;
      @include bp('tablet') {
        width: 100%;
        max-width: initial;
      }
    }
    &__step {
      width: 50%;
      z-index: 1;
      @include bp('tablet') {
        width: 140px;
        &:first-child {
          margin-left: 55px;
        }
      }
    }
  }
</style>
