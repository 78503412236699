<template>
  <div class="panel-job-edit-contracts col-xs-12 is-column">
    <title-section
      :position="'left'"
      :title="$t('companies.account-management.contract-type')"

      class="panel-job-edit-contracts__title">
    </title-section>
    <div class="panel-job-edit-contracts__container">
      <radio-default
        :key="contractIndex"
        :label="$t(contract.name)"
        :radio-value="contract.query"
        class="panel-job-edit-contracts__item"
        v-for="(contract, contractIndex) in contracts"
        v-model="selectedContract">
      </radio-default>
    </div>
    <p class="panel-job-edit-contracts__label is-secondary">
      {{ salaryConfig.label }}
    </p>
    <bc-slider
      :key="selectedContract"
      :max="salaryConfig.max"
      :min="salaryConfig.min"
      :step="salaryConfig.interval"
      class="panel-job-edit-contracts__filter"
      data-salary-slider
      v-if="salaryConfig.isDisplayed"
      v-model="salary">
    </bc-slider>
  </div>
</template>

<script>
  import TitleSection from '@/components/Title/TitleSection';
  import RadioDefault from '@/components/UIKit/Radio/RadioDefault';
  import { contracts } from '@/common-old/macros/contracts';
  import salary from '@/common-old/macros/salary';
  import BcSlider from '@/ui-kit/components/BcSlider/BcSlider';

  export default {
    name: 'panel-job-edit-contracts',
    components: {
      BcSlider,
      RadioDefault,
      TitleSection,
    },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
      editedProfile: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      contracts: [
        {
          name: contracts.cdi.name,
          query: contracts.cdi.api,
        },
        {
          name: contracts.freelance.name,
          query: contracts.freelance.api,
        },
        {
          name: contracts.stage.name,
          query: contracts.stage.api,
        },
        {
          name: contracts.alternance.name,
          query: contracts.alternance.api,
        },
      ],
    }),
    computed: {
      selectedContract: {
        get() {
          return typeof this.editedProfile.contract === 'number' && this.editedProfile.contract !== this.profile.contract ? this.editedProfile.contract : this.profile.contract;
        },
        set(value) {
          this.$emit('update-value', {
            contract: value,
          });
        },
      },
      salary: {
        get() {
          return typeof this.editedProfile.salary === 'number' && this.editedProfile.salary !== this.profile.salary ? this.editedProfile.salary : this.profile.salary;
        },
        set(value) {
          this.$emit('update-value', {
            salary: value,
          });
        },
      },
      salaryConfig() {
        return {
          isDisplayed: this.selectedContract === contracts.freelance.api || this.selectedContract === contracts.cdi.api,
              label: this.selectedContract === contracts.freelance.api ? `${ this.$i18n.t('companies.account-management.about-salary')} ? ${ this.$i18n.t('companies.account-management.pay-day')}` : `${ this.$i18n.t('companies.account-management.about-salary')}  ? ${ this.$i18n.t('companies.account-management.pay-year')}`,
          interval: this.selectedContract === contracts.freelance.api ? 50 : 1,
          max: this.selectedContract === contracts.freelance.api ? salary.salaryMaxFreelanceEdit : salary.salaryMaxCDIEdit,
          min: 0,
        };
      },
    },
    watch: {
      selectedContract(to) {
        if (to === contracts.freelance.api || to === contracts.cdi.api) {
          this.$emit('update-value', {
            salary: 0,
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-job-edit-contracts {
    margin-top: 50px;
    margin-bottom: 50px;

    &__title {
      margin-bottom: 20px;
      flex-basis: 100%;
    }

    &__container {
      flex-wrap: wrap;
    }

    &__item {
      margin-right: 30px;
      margin-bottom: 15px;
      @include bp('tablet') {
        margin-bottom: 0;
      }
    }

    &__label {
      margin: 20px 0 10px;
    }
  }

</style>
