<template>
  <div class="panel-carousel">
    <i
      v-if="isPreviousNextEnabled"
      class="panel-carousel__navigation icon-chevron-left"
      @click="changeCardActive('previous')"
    >
    </i>
    <div
      :class="{ 'panel-carousel__container--new-style': isNewStyle }"
      class="panel-carousel__container col-xs-12"
    >
      <slot></slot>
    </div>
    <i
      v-if="isPreviousNextEnabled"
      class="panel-carousel__navigation icon-chevron-right"
      @click="changeCardActive('next')"
    >
    </i>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

/**
 * It's the carousel which will be used inside the panel.
 * There are arrow on each side of this component and, between there is a slot which will be replaced when we will call the component
 */
export default {
  name: 'panel-carousel',
  props: {
    carouselElements: {
      type: Array,
      default: () => [],
      validator: array => array.every(value => value.resource)
    },
    refreshWhenCardsLengthChange: {
      type: Boolean,
      default: true
    },
    isNewStyle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      direction: '',
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      cards: state => state.card.cards,
      next: state => state.card.next,
      modal: state => state.app.modal,
    }),
    ...mapGetters({
      cardActive: 'cardActive'
    }),
    carouselActiveElement() {
      if (this.carouselElements.length === 0) {
        return;
      }

      return this.carouselElements.findIndex(e => e._id === this.$route.params.id);
    },
    isPanelCandidateOrCompanyOrContactOnRespectiveView() {
      return (
        (this.$route.name === 'ApplicationsPanel' && this.$route.query.type === 'coders') ||
        (this.$route.name === 'CodersPanel' && this.$route.query.type === 'coders') ||
        (this.$route.name === 'PartnersPanel' && this.$route.query.type === 'companies') ||
        (this.$route.name === 'ContactsPanel' && this.$route.query.type === 'coders') ||
        (this.$route.name === 'FavoriteListPanel' && this.$route.query?.['list-type'] === 'coder' && this.$route.query.type === 'coders') ||
        (this.$route.name === 'FavoriteListPanel' && this.$route.query?.['list-type'] === 'company' && this.$route.query.type === 'companies') ||
        (this.$route.name === 'FavoriteListPanel' && this.$route.query?.['list-type'] === 'contact' && this.$route.query.type === 'coders')
      );
    },
    isPreviousNextEnabled() {
      return !this.$route.query.quicksearch && this.isPanelCandidateOrCompanyOrContactOnRespectiveView;
    }
  },
  methods: {
    /**
     * Change the value of the state cardActive
     * @param direction ('previous', 'next')
     */
    changeCardActive(direction) {
      if (direction === 'previous') {
        this.handlePreviousCase();
      } else if (direction === 'next') {
        this.handleNextCase();
      }
    },
    changePage(direction) {
      const currentPage = parseInt(this.$route.query.page) || 1;

      this.$router
        .push({
          ...this.$route,
          query: {
            ...this.$route.query,
            page: direction === 'previous' ? currentPage - 1 : currentPage + 1
          }
        })
        .catch(() => { });
    },
    changeRoute(index) {
      const carouselElements =
        this.carouselElements.length > 0 ? this.carouselElements : this.cards;

      this.$router
        .push({
          name: this.$route.name,
          params: {
            id: carouselElements[index]?._id
          },
          query: {
            ...this.$route.query,
            type: carouselElements[index].resource || this.$route.query.type
          }
        })
        .catch(() => { });
    },
    handleKeypressEvent(e) {
      if (this.modal !== '') {
        return;
      }

      if (!this.isLoading && !this.$route.query.typing && this.isPreviousNextEnabled) {
        if (e.key === 'ArrowLeft') {
          this.changeCardActive('previous');
        } else if (e.key === 'ArrowRight') {
          this.changeCardActive('next');
        }
      }
    },
    handlePreviousCase() {
      this.direction = 'previous';

      if (!this.isLoading && this.cardActive === 0 && Number(this.$route.query.page) > 1) {
        this.changePage('previous');
      } else if (this.cardActive > 0 || this.carouselActiveElement > 0) {
        const activeElement =
          this.carouselElements.length > 0 ? this.carouselActiveElement : this.cardActive;

        this.changeRoute(activeElement - 1);
      }
    },
    handleNextCase() {
      this.direction = 'next';

      if (!this.isLoading && this.cardActive === this.cards.length - 1 && this.next) {
        this.changePage('next');
      } else if (
        (this.cardActive < this.cards.length - 1 && this.carouselElements.length === 0) ||
        this.carouselActiveElement < this.carouselElements.length - 1
      ) {
        const activeElement =
          this.carouselElements.length > 0 ? this.carouselActiveElement : this.cardActive;

        this.changeRoute(activeElement + 1);
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeypressEvent);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeypressEvent);
  },
  watch: {
    'cards.length': {
      handler() {
        if (this.refreshWhenCardsLengthChange) {
          this.isLoading = true;
          if (this.cards.length > 0 && this.direction) {
            this.$router.replace({
              name: this.$route.name,
              params: {
                id:
                  this.direction === 'previous'
                    ? this.cards[this.cards.length - 1]._id
                    : this.cards[0]._id
              },
              query: {
                ...this.$route.query,
                type:
                  this.direction === 'previous'
                    ? this.cards[this.cards.length - 1].resource || this.$route.query.type
                    : this.cards[this.cards.length - 1].resource || this.$route.query.type
              }
            });

            this.isLoading = false;
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-carousel {
  background: $color-white;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;

  @include bp('tablet') {
    flex-shrink: 0;
    height: auto;
    overflow: initial;
  }

  &__container {
    padding: 0 50px;
    height: 100%;
    justify-content: center;

    @include bp('tablet') {
      padding: 0 70px;
    }

    &--new-style {
      padding: 0 20px;
    }
  }

  &__navigation {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    font-size: 20px;
    height: 20px;
    color: $color-blue-dark;

    &:hover {
      color: $color-primary;
    }

    &:last-of-type {
      right: 15px;
    }
  }
}
</style>
