<template>
  <div class="process-card-options">
    <bc-dropdown
      :close-dropdown-at-select="true"
      :is-open="isOpen"
      :options="menuOptions"
      class="process-card-options__dropdown flex items-center"
      position="bottom-left">
      <template #label="{ isDropdownOpen }">
        <button
          :class="{
            'process-card-options__button--open': isDropdownOpen,
          }"
          class="process-card-options__button gap-1"
        >
          <i class="icon-settings"></i>
          <p
            class="text-xs">
            Actions
          </p>
        </button>
      </template>
      <template #option="{ activeIndex, index, option }">
        <div
          v-tooltip="option.tooltip">
          <bc-dropdown-item
            :class="option.class"
            :color="option.color"
            :icon="option.icon"
            :is-focus="activeIndex === index"
            :is-selected="false"
            :text="option.name"
            :title="option.name"
            class="process-card-options__dropdown--item"
            @click.native="handleClickItem(option)">
          </bc-dropdown-item>
        </div>
      </template>
    </bc-dropdown>
  </div>
</template>

<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import { useI18n } from '@/i18n/i18n';

  const { t } = useI18n();

  type ActionsKey = 'pin' | 'edit' | 'delete' | 'unpin';

  type MenuOption = {
    key: ActionsKey;
    name: string;
    icon: string;
    color?: string;
  };

  const props = defineProps<{
    isPin: boolean;
    isDisabled: boolean;
  }>();

  const isOpen = ref<boolean>(true);
  const emit = defineEmits<{
    (e: ActionsKey): void;
  }>();

  const menuOptions = computed<(MenuOption)[]>(() => {
    return [
      props.isPin ? {
        key: 'unpin',
        name: t('generics.unpin-the-note'),
        icon: 'pin',
      } : {
        key: 'pin',
        name: t('generics.pin-the-note'),
        icon: 'pin',
      },
      {
        key: 'edit',
        name: t('generics.edit'),
        icon: 'edit-3',
        tooltip: props.isDisabled ? t('notes.you-cannot-edit-notes-where-you-are-not-the-author') : '',
        class: props.isDisabled ? 'cursor-default opacity-50 grayscale !bg-white pointer-events-none' : '',
      },
      {
        key: 'delete',
        name: t('generics.delete'),
        icon: 'trash',
        color: 'danger',
        tooltip: props.isDisabled ? t('notes.you-cannot-delete-notes-where-you-are-not-the-author') : '',
        class: props.isDisabled ? 'cursor-default opacity-50 !bg-white pointer-events-none' : '',
      },
    ];
  });

  const handleClickItem = (option: MenuOption) => {
    isOpen.value = true;
    emit(option.key);
  };

</script>

<style lang="scss" scoped>
  .process-card-options {
    &__button {
      color: $color-blue-heavy-dark;

      &--open {
        color: $color-primary;
      }

      &:hover {
        color: $color-primary;
      }

      &--l {
        font-size: 18px;
      }
    }

    &__dropdown {
      &--item {
        min-width: 207px;;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $color-tertiary;

        &--color:hover {
          :deep() {
            .bc-dropdown-item__text {
              color: white;
            }
          }
        }
      }
    }
  }
</style>
