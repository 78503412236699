<script lang="ts" setup>
import { ProfileType, NewCandidate } from '@/domains/models/Candidate';
import { Ref, computed, defineProps, ref, watch } from 'vue';
import MvAccordion from '@/ui-kit/components/MvAccordion/MvAccordion.vue';
import MvAccordionTab from '@/ui-kit/components/MvAccordionTab/MvAccordionTab.vue';
import { useStore } from '@/store';
import { useRoute, useRouter } from 'vue-router/composables';
import PanelCoderDocuments from '@/components/Panel/PanelCoder/PanelCoderDocuments.vue';
import PanelCoderTopEdit from '@/components/Panel/PanelCoder/PanelCoderTop/PanelCoderTopEdit.vue';
import { useMutation, useQuery } from '@tanstack/vue-query';
import { useI18n } from '@/i18n/i18n';
import { useToast } from '@/ui-kit/components/BcToast';
import { coderController } from '@/managers/coders/controller';
import { createEmployee } from '@/api/employees';
import PanelCoderContactsEdit from '@/components/Panel/PanelCoder/PanelCoderContacts/PanelCoderContactsEdit.vue';
import PanelCoderDetailsEdit from '@/components/Panel/PanelCoder/PanelCoderDetails/PanelCoderDetailsEdit.vue';
import PanelCoderCompanyEdit from '@/components/Panel/PanelCoder/PanelCoderCompany/PanelCoderCompanyEdit.vue';
import documentsController from '@/managers/documents/controller';
import companyApi from '@/api/company';
import { usePanelNavigation } from '@/composables/usePanelNavigation';
import { searchParamToString } from '@/utils/typeConversion';
const props = defineProps<{
  profileTypes: ProfileType[];
}>();

const { closePanel } = usePanelNavigation();
const route = useRoute();
const editedProfile: Ref<NewCandidate> = ref({
  ...(route.query?.contactInput && {
    firstName: searchParamToString(route.query.contactInput).split(' ')[0],
    lastName: searchParamToString(route.query.contactInput).split(' ')[1],
  }),
  profileTypes: props.profileTypes,
});

watch(
  () => props.profileTypes,
  profileTypes => {
    editedProfile.value = { ...editedProfile.value, profileTypes };
  }
);

useQuery({
  queryKey: ['GET_CODER_CREATE_COMPANY', route.query.companyId],
  queryFn: async () => {
    if (route.query.companyId) {
      const { data } = await companyApi.getPartnerProfile(route.query.companyId);

      editedProfile.value = {
        ...editedProfile.value,
        employeeCompany: data,
        _employees: [
          {
            _company: data,
            active: true,
          },
          ...(editedProfile.value._employees || []),
        ],
      };

      return data;
    }

    return {};
  },
  enabled: !!route.query.companyId,
});
const setEditedProfile = (data: Partial<NewCandidate>) => {
  editedProfile.value = {
    ...editedProfile.value,
    ...data,
  };
};

const activeTabs = ref<number[]>([]);

const i18n = useI18n();
const toast = useToast();
const store = useStore();
const router = useRouter();

const organization = store.state.user?.profile?._organization;
const isProfessionRequired = computed(() => props.profileTypes.includes('candidate') && !!organization.configuration?.extension?.requiredProfession);
const requiredFields = organization.configuration?.candidate?.requiredFields || {};

const documentsCount = computed(() => {
  return editedProfile.value?._documents?.length || 0;
});
const uploadDocument = ref<HTMLInputElement | null>(null);
const triggerUploadButton = () => {
  uploadDocument.value?.click();
};
const addDocument = async e => {
  if (e.target.files[0]) {
    const url = URL.createObjectURL(e.target.files[0]);

    setEditedProfile({
      _documents: [{ url, file: e.target.files[0] }, ...(editedProfile.value._documents || [])],
    });
    // const { data } = await documentsController.createCoderDocument({
    //   id: route.params.id,
    //   file: e.target.files[0],
    // });
    //
    // emits('update-candidate', { _documents: data._documents });
  }
};

const redirectToCoderPanel = id => {
  router.push({
    name: route.name?.includes('Panel') ? route.name : `${route.name}Panel`,
    params: {
      id,
    },
    query: {
      ...route?.query,
      type: 'coders',
    },
  });
};
const createCandidateMutation = useMutation({
  mutationKey: ['create-candidate', editedProfile.value],
  mutationFn: async() => {
    if (isProfessionRequired.value && !editedProfile.value?._professions?.length) {
      toast.show({
        type: 'error',
        title: 'Error',
        message: i18n.t('global.profession-required'),
      });

      throw Error('Profession required');
    }

    if (props.profileTypes?.includes('candidate')) {
      if (requiredFields.location?.required && !editedProfile.value.locations?.length) {
        toast.show({
          type: 'error',
          message: i18n.t('global.location-required'),
        });
        throw Error('Locations required');
      }

      const skills = [
        ...editedProfile.value?._technos1?.length ? editedProfile.value._technos1 : [],
        ...editedProfile.value?._technos2?.length ? editedProfile.value._technos2 : [],
        ...editedProfile.value?._technos3?.length ? editedProfile.value._technos3 : [],
      ];

      if (requiredFields.skills?.required && !skills?.length) {
        toast.show({
          type: 'error',
          message: i18n.t('global.skills-required'),
        });
        throw Error('Skills required');
      }
    }


    const { data } = await coderController.createProfile({
      data: editedProfile.value,
    });

    if (editedProfile.value.employeeCompany?._id) {
      await createEmployee({
        companyId: editedProfile.value.employeeCompany._id,
        coderId: data._id,
      });
    } else if (Array.isArray(editedProfile.value.employeeCompany) && editedProfile.value.employeeCompany?.length) {
      for (const company of editedProfile.value.employeeCompany) {
        await createEmployee({
          companyId: company._id,
          coderId: data._id,
        });
      }
    }

    if (editedProfile.value._documents?.length) {
      await Promise.all(
        editedProfile.value._documents.map(async document => {
          await documentsController.createCoderDocument({
            id: data._id,
            file: document.file,
          });
        })
      );
    }

    return data;
  },
  onSuccess: async data => {
    if (route?.name?.includes('Coders')) {
      await store.dispatch('unshiftCard', data);
    }

    await store.dispatch('user/updateMetadataByKey', {
      key: 'coders',
      value: store.state.user.metadata.coders + 1,
    });

    if (store.state.user.onboarding) {
      toast.show({
        type: 'success',
        message: i18n.t('toast.candidate-created'),
      });
    } else {
      await store.dispatch('user/updateOnboarding', {
        key: 'coder',
        value: true,
      });
    }

    if (route.query.from === 'contact-autocomplete') {
      return router.go(-1);
    }

    redirectToCoderPanel(data._id);
  },
});

const initialActiveTabs = [1, 1, 1, 1];
activeTabs.value = initialActiveTabs
  .map((_, index) => index)
  .filter(index => initialActiveTabs[index] > 0);


const tabs = {
    contacts: 'contacts',
    details: 'details',
    company: 'company',
    documents: 'documents',
  } as const;

const candidateTabsOrder = [tabs.contacts, tabs.details, tabs.company, tabs.documents]
const contactTabsAndOrder = [tabs.contacts, tabs.company,  tabs.details];
const currentTabsAndOrder = computed(() => editedProfile.value.profileTypes?.includes('candidate') ? [...candidateTabsOrder] : [...contactTabsAndOrder]);

function toOrderClass(index: number) {
return `order-${index + 1}`;
}
const tabsCssOrderObject = computed(() => currentTabsAndOrder.value.reduce((acc, tab, index) => ({
    ...acc,
    [tab]: toOrderClass(index),
  }), {}));
</script>

<template>
  <div class="flex max-h-full min-h-full flex-col overflow-auto bg-white pb-5">
    <div class="flex flex-col gap-[0.625rem] px-5 pt-3.5">
      <PanelCoderTopEdit
        :candidate="editedProfile"
        :is-creating="true"
        @create-candidate="createCandidateMutation.mutate"
        @edit-candidate="closePanel"
        @update-candidate="setEditedProfile" />
    </div>
    <MvAccordion :active-index.sync="activeTabs" class="flex flex-col px-5" multiple>
      <MvAccordionTab
        v-if="currentTabsAndOrder.includes(tabs.contacts)"
        :class="tabsCssOrderObject.contacts"
      >
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('panel-coder-edit.contact-information') }}
            </b>
          </div>
        </template>
        <PanelCoderContactsEdit
          :candidate="editedProfile"
          :is-creating="true"
          @update-candidate="setEditedProfile" />
      </MvAccordionTab>
      <MvAccordionTab
        v-if="currentTabsAndOrder.includes(tabs.details)"
        :class="tabsCssOrderObject.details"
      >
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('generics.details') }}
            </b>
          </div>
        </template>
        <PanelCoderDetailsEdit
          :candidate="editedProfile"
          :is-creating="true"
          @update-candidate="setEditedProfile" />
      </MvAccordionTab>
      <MvAccordionTab
        v-if="currentTabsAndOrder.includes(tabs.company)"
        :class="tabsCssOrderObject.company"
      >
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('generics.associated-company') }}
            </b>
          </div>
        </template>
        <PanelCoderCompanyEdit
          :candidate="editedProfile"
          :key="editedProfile._employees?.length"
          :is-creating="true"
          @update-candidate="setEditedProfile" />
      </MvAccordionTab>
      <MvAccordionTab
        v-if="currentTabsAndOrder.includes(tabs.documents)"
        :class="[
          { 'panel-coder-profile__documents': documentsCount > 0 },
           tabsCssOrderObject.documents
        ]"
      >
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold"> Documents </b>
            <div class="absolute right-0 flex gap-2.5 bg-white pl-2.5" @click.stop>
              <button
                class="flex size-[30px] items-center justify-center rounded-full border-2 border-blue-400 bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="triggerUploadButton">
                <i class="icon-plus font-xs" />
              </button>
              <input
                ref="uploadDocument"
                accept=".doc,.docx,application/pdf"
                class="hidden"
                name="document"
                type="file"
                @change="addDocument" />
            </div>
          </div>
        </template>
        <PanelCoderDocuments :candidate="editedProfile" @update-candidate="setEditedProfile" />
      </MvAccordionTab>
    </MvAccordion>
  </div>
</template>

<style lang="scss">
.panel-coder-profile__documents > .p-toggleable-content {
  min-height: 640px;
}
</style>
