import VueI18n from 'vue-i18n';

export function capitalize(text: string | VueI18n.TranslateResult): string {
  const arg = text as string;
  return arg.charAt(0).toUpperCase() + arg.slice(1);
}

export function transformNewlinesToParagraphsTag(body: string): string {
  return body
  .split('\n') // Sépare chaque ligne
  .map((line) => `<p>${line.trim()}</p>`) // Enveloppe chaque ligne dans des <p>
  .join(''); // Concatène les lignes transformées
}
