<template>
  <div
    class="bc-dropdown">
    <label
      class="bc-dropdown__label"
      ref="label"
      v-if="bcLabel">
      <span
        :class="`icon-${bcLabelIcon}`"
        class="bc-dropdown__label-icon"
        v-if="bcLabelIcon">
      </span>
      {{ bcLabel }}
    </label>
    <div class="bc-dropdown__container">
      <input
        :class="{
          'bc-dropdown__input--active': isDropdownOpen && !bcDropup,
          'bc-dropdown__input--dropup-active': isDropdownOpen && bcDropup
        }"
        :placeholder="($attrs.placeholder || bcPlaceholder) && bcIsLabelPlaceholder ? null : bcPlaceholder || $attrs.placeholder"
        :readonly="!bcAutocomplete"
        :style="inputStyle"
        @blur="isFocus = false"
        @click.stop="setOpenAtClickBehavior()"
        @focus="isTouched = true, isFocus = true"
        @keydown.down.prevent="incrementPointer"
        @keydown.enter.prevent="setOptionSelected(), setFocusBehaviorAtEnter(), setDropdownBehaviorAtEnter()"
        @keydown.esc.stop.prevent="setIsDropdownOpen(false)"
        @keydown.up.prevent="decrementPointer"
        class="bc-dropdown__input"
        ref="input"
        type="text"
        v-bind="$attrs"
        v-model="dropdownValue"
        v-on="listeners">
      <transition name="placeholder">
        <span
          :style="placeholderStyle"
          @click="$refs.input.focus(), setOpenAtClickBehavior()"
          class="bc-dropdown__placeholder"
          ref="bcDropdownPlaceholder"
          v-if="bcIsLabelPlaceholder && $attrs.placeholder && !bcLabel">
          {{ $attrs.placeholder }}
        </span>
      </transition>
      <spinner
        :bc-border-color="bcSpinnerColor"
        bc-size="15px"
        class="bc-dropdown__loader"
        v-if="bcIsAsynchronous && bcIsLoading">
      </spinner>
      <i
        :class="[`icon-${bcIconCaretName}`, {'bc-dropdown__icon--active': isDropdownOpen}]"
        :style="iconCaretStyle"
        @click="$refs.input.focus(), setOpenAtClickBehavior()"
        class="bc-dropdown__icon"
        v-if="!bcIsAsynchronous && bcIconCaretDisplayed && !$attrs.disabled">
      </i>
      <transition name="fade-select">
        <div
          :style="optionsStyle"
          class="bc-dropdown__options"
          ref="options"
          v-show="isDropdownOpen && !bcIsLoading && !$attrs.disabled">
          <ul class="bc-dropdown__list">
            <li
              :class="{
                'bc-dropdown__item--active': optionActive === index,
                'bc-dropdown__item--focus': pointer === index,
                'bc-dropdown__item--category': option.type && option.type === 'category',
              }"
              :key="index"
              :style="itemStyle(option.name, index)"
              @click.stop="setDropdownItemBehaviorAtClick()"
              @mousedown="setDropdownItemBehaviorAtMousedown($event), setOptionSelected()"
              @mouseout="setPointer(null)"
              @mouseover="setPointer(index)"
              class="bc-dropdown__item"
              v-for="(option, index) in options">
              <div
                class="bc-dropdown__category-wrapper"
                v-if="option.type && option.type === 'category'">
                <span
                  :class="`icon-${option.icon}`"
                  class="bc-dropdown__category-icon">
                </span>
                <p class="bc-dropdown__category-name">
                  {{ option.name }}
                </p>
              </div>
              <input
                :ref="option.name"
                :value="option.name"
                class="bc-dropdown__item-name"
                type="button"
                v-if="!option.type || option.type !== 'category'">
            </li>
            <p
              :style="noResultsTextStyle"
              class="bc-dropdown__text"
              v-if="inputValue && bcOptions.length <= 0 && bcIsNoResults">
              {{ bcNoResultsText }}
            </p>
          </ul>
        </div>
      </transition>
    </div>
    <p
      :style="errorStyle"
      class="bc-input__error"
      v-if="!isFocus && isTouched && bcIsError && bcErrorMessage">
      {{ bcErrorMessage }}
    </p>
  </div>
</template>

<script>
  import colors_helpers from '@/legacy/ui-kit/utils/colors_helpers';
  import Spinner from '@/legacy/ui-kit/components/Spinner/BcSpinner';

  export default {
    name: 'legacy-bc-dropdown',
    components: { Spinner },
    props: {
      /**
       * The value props of the v-model
       */
      value: {
        type: [String, Number, Object, Array]
      },
      /**
       * Determinate if the options are already selectable or not
       */
      bcIsAsynchronous: {
        type: Boolean,
        default: false
      },
      /**
       * Determinate if the dropdown is loading
       */
      bcIsLoading: {
        type: Boolean,
        default: false
      },
      /**
       * The label of the select
       */
      bcLabel: {
        type: String
      },
      /**
       * Empty the input on click
       */
      bcEmptyInputOnClick: {
        type: Boolean,
        default: false
      },
      /**
       * The text displayed when no results was found
       */
      bcNoResultsText: {
        type: String,
        default: 'Aucun résultat'
      },
      /**
       * The color of the no results text
       */
      bcNoResultsTextColor: {
        type: String,
        default: 'blue-dark-cello'
      },
      /**
       * The opacity of the no results text
       */
      bcNoResultsTextOpacity: {
        type: Number,
        default: 0.5
      },
      /**
       * The font-size of the no-results text
       */
      bcNoResultsTextFontSize: {
        type: String
      },
      /**
       * The padding of the no results text
       */
      bcNoResultsTextPadding: {
        type: String,
        default: '10px'
      },
      /**
       * Will show the no results text if true
       */
      bcIsNoResults: {
        type: Boolean,
        default: false
      },
      /**
       * The icon of the label
       */
      bcLabelIcon: {
        type: String
      },
      /**
       * Placeholder
       */
      bcPlaceholder: {
        type: String,
        default: 'Default placeholder'
      },
      /**
       * The icon name of the caret icon
       */
      bcIconCaretName: {
        type: String,
        default: 'chevron-down'
      },
      /**
       * The icon name of the caret icon
       */
      bcIconCaretDisplayed: {
        type: Boolean,
        default: true
      },
      /**
       * The color of the caret icon
       */
      bcIconCaretColor: {
        type: String,
        default: 'blue-dodger'
      },
      /**
       * The list of options for the dropdown
       */
      bcOptions: {
        type: Array
      },
      /**
       * You can define a different width for the options list
       */
      bcOptionsWidth: {
        type: String
      },
      /**
       * You can set a different border color for the options list
       */
      bcOptionsBorderColor: {
        type: String,
        default: 'blue-dodger'
      },
      /**
       * You can set an opacity for the border color
       */
      bcOptionsBorderColorOpacity: {
        type: Number,
        default: 1
      },
      bcSpinnerColor: {
        type: String,
        default: 'blue-dodger'
      },
      bcIsLabelPlaceholder: {
        default: null,
        type: Boolean
      },
      /**
       * The max-height of the options
       */
      bcOptionsMaxHeight: {
        type: String,
        default: 'initial'
      },
      /**
       * The margin of the options
       */
      bcOptionsMargin: {
        type: String
      },
      /**
       * The box shadow properties
       */
      bcOptionsBoxShadowConfig: {
        type: String,
        default: '0px 5px 20px -5px'
      },
      /**
       * The box-shadow color
       */
      bcOptionsBoxShadowColor: {
        type: String,
        default: '#000000'
      },
      /**
       * The box-shadow opacity
       */
      bcOptionsBoxShadowOpacity: {
        type: Number,
        default: 0.1
      },
      /**
       * The border bottom radius of the options list
       */
      bcOptionsBorderBottomRadius: {
        type: String
      },
      /**
       * The border bottom radius of the options list
       */
      bcOptionsBorderTopRadius: {
        type: String
      },
      /**
       * The boolean to say if the dropdown will have the autocomplete function
       */
      bcAutocomplete: {
        type: Boolean,
        default: false
      },
      /**
       * The radius of the border of the dropdown
       */
      bcDropdownBorderRadius: {
        type: String,
        default: '4px'
      },
      /**
       * The border color of the dropdown
       */
      bcDropdownBorderColor: {
        type: String,
        default: 'blue-dodger'
      },
      /**
       * The border opacity of the dropdown
       */
      bcDropdownBorderColorOpacity: {
        type: Number,
        default: 1
      },
      /**
       * The border color of the dropdown
       */
      bcDropdownBorderColorFocus: {
        type: String
      },
      /**
       * The border opacity of the dropdown
       */
      bcDropdownBorderColorOpacityFocus: {
        type: Number,
        default: 1
      },
      /**
       * Input colors & size configuration
       */
      bcInputFontColor: {
        type: String,
        default: 'blue-dodger'
      },
      /**
       * Activate the box-shadow on the input when it's focus and when it's true
       */
      bcInputBoxShadowActive: {
        type: Boolean,
        default: false
      },
      bcInputBackgroundColor: {
        type: String,
        default: 'white'
      },
      bcInputBackgroundColorActive: {
        type: String,
        default: 'white'
      },
      bcInputBackgroundColorOpacityActive: {
        type: Number,
        default: 1
      },
      bcInputBackgroundOpacity: {
        type: Number,
        default: 1
      },
      bcInputMinHeight: {
        type: String
      },
      /**
       * The font-size of the input
       */
      bcInputFontSize: {
        type: String,
        default: '16px'
      },
      /**
       * The padding of the input
       */
      bcInputPadding: {
        type: String,
        default: '10px 30px'
      },
      /**
       * The padding of the input
       */
      bcInputPaddingFocus: {
        type: String
      },
      /**
       * The padding of the item
       */
      bcItemPadding: {
        type: String,
        default: '10px 30px'
      },
      /**
       * Item colors & size configuration
       */
      bcItemFontSize: {
        type: String,
        default: '16px'
      },
      bcItemFontColor: {
        type: String,
        default: 'blue-dodger'
      },
      bcItemBackgroundColor: {
        type: String,
        default: 'white'
      },
      /**
       * The color of the border of each item
       */
      bcItemBorderColor: {
        type: String,
        default: 'transparent'
      },
      /**
       * Active item colors & size configuration
       */
      bcItemFontColorActive: {
        type: String,
        default: 'white'
      },
      bcItemBackgroundColorActive: {
        type: String,
        default: 'blue-dodger'
      },
      bcItemBackgroundColorHover: {
        type: String,
        default: 'blue-dodger'
      },
      bcItemBackgroundColorHoverOpacity: {
        type: Number,
        default: 0.25
      },
      bcOptionsLimit: {
        type: Number
      },
      bcOpenAtClick: {
        type: Boolean,
        default: true
      },
      bcCloseDropdownAtItemClick: {
        type: Boolean,
        default: false
      },
      bcInputFocusAtEnter: {
        type: Boolean,
        default: false
      },
      bcCloseDropdownAtEnter: {
        type: Boolean,
        default: false
      },
      bcIsError: {
        type: Boolean,
        default: false
      },
      bcErrorMessage: {
        type: [Boolean, String]
      },
      bcErrorColor: {
        type: String,
        default: 'red-mandy'
      },
      bcErrorFontSize: {
        type: String,
        default: '13px'
      },
      bcErrorMargin: {
        type: String,
        default: '5px 0 0 0'
      },
      bcPlaceholderFontSize: {
        type: String,
        default: '16px'
      },
      bcPlaceholderFontSizeFocus: {
        type: String,
        default: '14px'
      },
      bcPlaceholderTranslateFocus: {
        type: String,
        default: 'translate(-10px, -75%)'
      },
      bcPlaceholderMargin: {
        type: String
      },
      bcPlaceholderColor: {
        type: String,
        default: 'blue-cello'
      },
      bcPlaceholderOpacity: {
        type: Number,
        default: 0.5
      },
      bcPlaceholderColorFocus: {
        type: String,
        default: 'blue-dodger'
      },
      bcPlaceholderOpacityFocus: {
        type: Number,
        default: 1
      },
      bcDropup: {
        type: Boolean,
        default: false
      },
      bcInputWidth: {
        type: String,
        default: '100%'
      },
      bcIsPropsValue: {
        type: Boolean,
        default: false
      },
      bcIsMultiple: {
        type: Boolean,
        default: false
      },
      bcEmptyInputOnBlur: {
        type: Boolean,
        default: true
      },
      bcOptionsTop: {
        type: String,
        default: 'calc(100% - 1px)'
      },
      bcOptionsBottom: {
        type: String,
        default: '100%'
      },
      bcScrollToItem: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        inputValue: '',
        pointer: null,
        isDropdownOpen: false,
        optionSelected: '',
        isFocus: false,
        isTouched: false
      };
    },
    computed: {
      dropdownValue: {
        get() {
          const value = this.bcIsPropsValue ? this.value : this.inputValue;

          return this.bcIsMultiple && Array.isArray(value) ? value.join(', ') : value;
        },
        set(value) {
          if (this.bcIsPropsValue) {
            this.$emit('input', value);
          } else {
            this.inputValue = value;
          }
        }
      },
      listeners() {
        return {
          ...this.$listeners,
          input: () => {
            this.$emit('input', this.inputValue);
          },
          blur: event => {
            this.$emit('blur', event);
            this.setIsDropdownOpen(false);
            this.setIsFocus(false);
          },
          focus: event => {
            this.$emit('focus', event);
            this.setIsFocus(true);
          },
          change: () => {
          }
        };
      },
      options() {
        return this.bcOptionsLimit ? this.bcOptions.slice(0, this.bcOptionsLimit) : this.bcOptions;
      },
      inputStyle() {
        return {
          color: this.bcAutocomplete ? colors_helpers.$_checkValidColor(this.bcInputFontColor) : 'transparent',
          'text-shadow': this.bcAutocomplete ? 'none' : `0 0 0 ${colors_helpers.$_checkValidColor(this.bcInputFontColor)}`,
          'font-size': this.bcInputFontSize,
          padding: this.bcInputPaddingFocus && (this.isFocus || this.value) ? this.bcInputPaddingFocus : this.bcInputPadding ? this.bcInputPaddingFocus : this.bcInputPadding,
          background: this.isDropdownOpen ? colors_helpers.$_checkValidColor(this.bcInputBackgroundColorActive, this.bcInputBackgroundColorOpacityActive) : colors_helpers.$_checkValidColor(this.bcInputBackgroundColor, this.bcInputBackgroundOpacity),
          'border-radius': this.bcDropdownBorderRadius,
          width: this.bcInputWidth,
          border: this.bcDropdownBorderColorFocus && this.isFocus ? `1px solid ${colors_helpers.$_checkValidColor(this.bcDropdownBorderColorFocus, this.bcDropdownBorderColorOpacityFocus)}` :
            this.bcIsError && this.isTouched ? `1px solid ${colors_helpers.$_checkValidColor(this.bcErrorColor, this.bcDropdownBorderColorOpacity)}` :
              `1px solid ${colors_helpers.$_checkValidColor(this.bcDropdownBorderColor, this.bcDropdownBorderColorOpacity)}`,
          ...this.bcInputMinHeight && { 'min-height': this.bcInputMinHeight },
          ...this.bcAutocomplete && { cursor: 'initial' },
          ...this.bcInputBoxShadowActive && this.isDropdownOpen && { 'box-shadow': `${this.bcOptionsBoxShadowConfig} ${colors_helpers.$_checkValidColor(this.bcOptionsBoxShadowColor, this.bcOptionsBoxShadowOpacity)}` },
          ...this.bcAutocomplete && { opacity: 'initial' }
        };
      },
      optionsStyle() {
        return {
          border: this.bcDropdownBorderColorFocus && this.isFocus ? `1px solid ${colors_helpers.$_checkValidColor(this.bcDropdownBorderColorFocus, this.bcDropdownBorderColorOpacityFocus)}` : `1px solid ${colors_helpers.$_checkValidColor(this.bcDropdownBorderColor, this.bcDropdownBorderColorOpacity)}`,
          'max-height': this.bcOptionsMaxHeight,
          overflow: this.bcOptionsMaxHeight !== 'initial' ? 'auto' : 'initial',
          'box-shadow': `${this.bcOptionsBoxShadowConfig} ${colors_helpers.$_checkValidColor(this.bcOptionsBoxShadowColor, this.bcOptionsBoxShadowOpacity)}`,
          ...this.bcOptionsMargin && { margin: this.bcOptionsMargin },
          ...this.bcOptionsBorderBottomRadius && {
            borderBottomLeftRadius: this.bcOptionsBorderBottomRadius,
            borderBottomRightRadius: this.bcOptionsBorderBottomRadius
          },
          ...this.bcOptionsBorderTopRadius && {
            borderTopLeftRadius: this.bcOptionsBorderTopRadius,
            borderTopRightRadius: this.bcOptionsBorderTopRadius
          },
          ...this.bcOptionsWidth && { width: this.bcOptionsWidth },
          ...!this.bcDropup && { top: this.bcOptionsTop, right: 0 },
          ...this.bcDropup && { bottom: this.bcOptionsBottom }
        };
      },
      placeholderStyle() {
        return {
          color: this.isFocus ? colors_helpers.$_checkValidColor(this.bcPlaceholderColorFocus, this.bcPlaceholderOpacityFocus) : colors_helpers.$_checkValidColor(this.bcPlaceholderColor, this.bcPlaceholderOpacity),
          padding: this.bcInputPadding,
          fontSize: this.isFocus || this.value ? this.bcPlaceholderFontSizeFocus : this.bcPlaceholderFontSize,
          cursor: this.bcAutocomplete ? 'text' : 'pointer',
          ...this.bcPlaceholderMargin && { margin: this.bcPlaceholderMargin },
          ...((this.isFocus || this.value) && this.bcIsLabelPlaceholder) && { transform: this.bcPlaceholderTranslateFocus }
        };
      },
      errorStyle() {
        return {
          fontSize: this.bcErrorFontSize,
          margin: this.bcErrorMargin,
          color: colors_helpers.$_checkValidColor(this.bcErrorColor)
        };
      },
      iconCaretStyle() {
        return {
          color: colors_helpers.$_checkValidColor(this.bcIconCaretColor)
        };
      },
      noResultsTextStyle() {
        return {
          color: colors_helpers.$_checkValidColor(this.bcNoResultsTextColor, this.bcNoResultsTextOpacity),
          padding: this.bcNoResultsTextPadding,
          ...this.bcNoResultsTextFontSize && { fontSize: this.bcNoResultsTextFontSize }
        };
      },
      optionActive() {
        return this.bcOptions.findIndex(option => option.name === this.dropdownValue);
      }
    },
    methods: {
      itemStyle(name, index) {
        const maps = {
          default: {
            color: colors_helpers.$_checkValidColor(this.bcItemFontColor),
            'font-size': this.bcItemFontSize,
            background: colors_helpers.$_checkValidColor(this.bcItemBackgroundColor),
            padding: this.bcItemPadding,
            'border-color': colors_helpers.$_checkValidColor(this.bcItemBorderColor)
          },
          hover: {
            color: colors_helpers.$_checkValidColor(this.bcItemFontColor),
            'font-size': this.bcItemFontSize,
            background: this.options[index].type === 'category' ? '' : colors_helpers.$_checkValidColor(this.bcItemBackgroundColorHover, this.bcItemBackgroundColorHoverOpacity),
            padding: this.bcItemPadding,
            'border-color': colors_helpers.$_checkValidColor(this.bcItemBorderColor)
          },
          active: {
            color: colors_helpers.$_checkValidColor(this.bcItemFontColorActive),
            'font-size': this.bcItemFontSize,
            background: colors_helpers.$_checkValidColor(this.bcItemBackgroundColorActive),
            padding: this.bcItemPadding,
            'border-color': colors_helpers.$_checkValidColor(this.bcItemBorderColor)
          }
        };

        const isActive = this.bcIsMultiple && Array.isArray(this.value) ? this.value.includes(name) : name === this.dropdownValue;

        if (index === this.pointer && !isActive) {
          return maps.hover;
        }

        return isActive ? maps.active : maps.default;
      },
      setIsDropdownOpen(value) {
        if (!this.bcIsAsynchronous || (this.bcIsAsynchronous && this.inputValue !== '' && this.inputValue !== this.value) || !value) {
          this.isDropdownOpen = value;
        }
      },
      setPointer(index) {
        this.pointer = index;
      },
      incrementPointer() {
        if (this.pointer < this.bcOptions.length - 1 || (this.bcOptionsLimit && this.pointer < this.bcOptionsLimit - 1)) {
          ++this.pointer;

          if (this.bcScrollToItem) {
            this.$scrollTo('.bc-dropdown__item--focus', 30, {
              container: '.bc-dropdown__list',
              offset: -50
            });
          }
          if (this.options[this.pointer].type === 'category') {
            this.incrementPointer();
          }
        }
      },
      decrementPointer() {
        if (this.pointer > 0) {
          --this.pointer;

          if (this.bcScrollToItem) {
            this.$scrollTo('.bc-dropdown__item--focus', 30, {
              container: '.bc-dropdown__list',
              offset: -50
            });
          }
          if (this.options[this.pointer].type === 'category') {
            this.decrementPointer();
          }
        }
      },
      setOptionSelected() {
        if (this.pointer !== null && this.options.length > 0 && this.options[this.pointer].type !== 'category') {
          this.$emit('change', this.options[this.pointer]);
          this.inputValue = this.bcEmptyInputOnClick ? '' : this.options[this.pointer].name;
          if (this.bcAutocomplete || this.bcIsMultiple) {
            this.optionSelected = this.options[this.pointer].name;
          }
        }
      },
      setOpenAtClickBehavior() {
        if (this.bcOpenAtClick) {
          this.setIsDropdownOpen(!this.isDropdownOpen);
        }
      },
      setIsFocus(value) {
        this.isFocus = value;
      },
      setFocusBehaviorAtEnter() {
        if (this.bcInputFocusAtEnter) {
          this.$refs.input.focus();
        } else {
          this.$refs.input.blur();
        }
      },
      setDropdownBehaviorAtEnter() {
        if (this.bcCloseDropdownAtEnter) {
          this.setIsDropdownOpen(false);
        }
        this.setPointer(0);
      },
      setDropdownItemBehaviorAtClick() {
        if (this.bcCloseDropdownAtItemClick) {
          this.setIsDropdownOpen(false);
        }
      },
      setDropdownItemBehaviorAtMousedown(event) {
        if (this.bcAutocomplete || this.bcIsMultiple) {
          event.preventDefault();
        }
      }
    },
    mounted() {
      if (!this.bcIsMultiple) {
        this.inputValue = this.value;
      }
    },
    watch: {
      isDropdownOpen(to) {
        if (this.isDropdownOpen && !this.bcIsAsynchronous) {
          this.pointer = 0;
        }
        if ((this.bcAutocomplete || this.bcIsMultiple) && !to && this.inputValue !== this.optionSelected && this.bcEmptyInputOnBlur && !this.isFocus) {
          this.inputValue = '';
        }
      },
      inputValue() {
        if ((this.bcAutocomplete || this.bcIsMultiple) && this.inputValue) {
          this.setIsDropdownOpen(true);
        } else if ((this.bcAutocomplete || this.bcIsMultiple) && !this.inputValue && (!this.bcEmptyInputOnClick)) {
          this.setIsDropdownOpen(false);
        }
      },
      bcIsLoading() {
        if (!this.bcIsLoading && this.bcOptions.length > 0) {
          this.setPointer(0);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .bc-dropdown {
    position: relative;
    width: 100%;
    flex-direction: column;

    &__container {
      width: 100%;
      position: relative;
      align-items: center;
    }

    &__icon {
      position: absolute;
      cursor: pointer;
      color: inherit;
      right: 20px;
      font-size: 15px;
      transition: all .2s ease;

      &--active {
        transform: rotate(-180deg);
      }
    }

    &__loader {
      position: absolute;
      right: 20px;
      transition: all .2s ease;
      width: auto;
    }

    &__input {
      cursor: pointer;
      position: relative;
      text-overflow: ellipsis;
      transition: 0.3s;

      &::placeholder {
        @include font-light;
      }

      &--active {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      &--dropup-active {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }

      &:disabled {
        pointer-events: none;
        cursor: initial;
        background: rgba($color-blue-dark-cello, 0.05) !important;
      }
    }

    &__options {
      box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.1);
      position: absolute;
      z-index: 2;
      width: 100%;
      background: rgb(255, 255, 255);
      overflow: hidden;
    }

    &__list {
      overflow: auto;
      padding: 0;
      margin: 0;
      width: 100%;
      flex-direction: column;
    }

    &__category-wrapper {
      align-items: center;
    }

    &__category-icon {
      font-size: 15px;
      margin-right: 10px;
    }

    &__category-name {
      font-size: 15px;
    }

    &__item {
      color: rgba(0, 0, 0, 0.8);
      position: relative;
      transition: all .3s ease;
      margin: 0;
      flex-shrink: 0;
      border-bottom: 1px solid;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover, &:focus {
        cursor: pointer;
        background: rgb(245, 245, 245)
      }

      &--active {
        background: red;
      }

      &--category {
        cursor: default !important;
        border-bottom: 1px solid rgba($color-gray-dove, 0.1) !important;
      }
    }

    &__item-name {
      display: flex;
      border: none;
      background: none;
      font-size: inherit;
      align-items: center;
      color: inherit;
      cursor: pointer;
      padding: 0;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__placeholder {
      transition: 0.3s;
      position: absolute;
      height: 100%;
      align-items: center;
      user-select: none;
    }
  }

  .fade-select-enter-active {
    transition: transform .2s, opacity .2s;
  }

  .fade-select-leave-active {
    transition: transform .150s, opacity .150s;
  }

  .fade-select-enter, .fade-select-leave-to {
    opacity: 0;
    transform: translate(0, 0px) scale(0.950);
    box-shadow: 0 10px 0 -5px rgba(0, 0, 0, 0);
  }
</style>
