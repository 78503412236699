import colors from '@/ui-kit/assets/scss/exports/_colors.module.scss';
import { processController } from '@/managers/processes/controller';
import { apiStatus, modalTypes } from '@/macros/processes/process';
import { getStepNameByProcessStatus } from '@/managers/processes/formatters/processDeserializers';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
       steps: {
        introduced: {
          name: this.$i18n.t('companies.panel-hunt-process.presentation'),
          query: ['pending', 'introduced'],
          color: colors['secondary-light'],
          processes: [],
        },
        processed: {
          name: this.$i18n.t('generics.process'),
          query: ['processed'],
          color: colors['secondary'],
          processes: [],
        },
        finalised: {
          name: this.$i18n.t('companies.panel-hunt-process.final'),
          query: ['finalised', 'offered'],
          color: colors['primary-dark'],
          processes: [],
        },
        dealt: {
          name: this.$i18n.t('process.waiting-to-start'),
          query: ['dealt'],
          color: colors['primary'],
          processes: [],
        },
        onboarding: {
          name: this.$i18n.t('generics.onboarding'),
          query: ['onboarding'],
          color: colors['primary-light'],
          processes: [],
        },
        validated: {
          name: this.$i18n.t('companies.panel-hunt-process.validate'),
          query: ['validated'],
          color: colors['deal'],
          processes: [],
        },
      },
      cards: {
        pending: {
          color: colors['secondary-light'],
          text: this.$i18n.t('companies.panel-hunt-process.pending'),
          options: [
            {
              text: this.$i18n.t('companies.panel-hunt-process.confirm-presentation'),
              action: async id => {
                const updatedProcess = await processController.move(id, 'introduced');

                if (this.updatePanelProfile) {
                  this.updatePanelProfile({ processState: updatedProcess._coder.processState });
                }
                this.$emit('profile-updated', { processState: updatedProcess._coder.processState });
                this.$emit('process-update', {
                  updatedProcess: updatedProcess,
                  stepName: 'pending',
                });
              },
            },
            {
              text: this.$i18n.t('companies.panel-hunt-process.failure'),
              action: async id => this.failedProcess(id),
            },
          ],
        },
        introduced: {
          color: colors['secondary-light'],
          text: this.$i18n.t('companies.panel-hunt-process.present'),
          options: [
            {
              text: this.$i18n.t('companies.panel-hunt-process.cancel-presentation'),
              action: async id => {
                const updatedProcess = await processController.move(id, 'pending');

                if (this.updatePanelProfile) {
                  this.updatePanelProfile({ processState: updatedProcess._coder.processState });
                }
                this.$emit('profile-updated', { processState: updatedProcess._coder.processState });
                this.$emit('process-update', {
                  updatedProcess: updatedProcess,
                  stepName: 'introduced',
                });
              },
            },
            {
              text: this.$i18n.t('generics.failure'),
              action: async id => this.failedProcess(id),
            },
          ],
        },
        processed: {
          color: colors['secondary'],
          text: this.$i18n.t('generics.processing-cap'),
        },
        finalised: {
          color: colors['primary-dark'],
          text: this.$i18n.t('companies.panel-hunt-process.no-offer-yet'),
          options: [
            {
              text: this.$i18n.t('companies.panel-hunt-process.an-offer-has-been-received'),
              action: () => {
                this.$emit('set-modal-type', {
                  process: this.process,
                  type: modalTypes['offered'].modalName,
                });
              },
            },
            {
              text: this.$i18n.t('generics.failure'),
              action: async id => this.failedProcess(id),
            },
          ],
        },
        offered: {
          color: colors['primary-dark'],
          text: this.$i18n.t('companies.panel-hunt-process.offer'),
          options: [
            {
              text: this.$i18n.t('companies.panel-hunt-process.edit-an-offer'),
              action: () => {
                this.$emit('set-modal-type', {
                  process: this.process,
                  type: modalTypes['offered'].modalName,
                });
              },
            },
            {
              text: this.$i18n.t('generics.failure'),
              action: async id => this.failedProcess(id),
            },
          ],
        },
        dealt: {
          color: colors['primary'],
          text: this.isSuperUser ? this.$i18n.t('companies.panel-hunt-process.not-started') : 'Deal',
          options: this.isSuperUser ? [
            {
              text: this.$i18n.t('companies.panel-hunt-process.validate-start'),
              action: () => {
                this.$emit('set-modal-type', {
                  process: this.process,
                  type: modalTypes['onboarding'].modalName,
                });
              },
            },
            {
              text: this.$i18n.t('validate.start'),
              action: async id => this.failedProcess(id),
            },
          ] : [],
        },
        onboarding: {
          color: colors['primary-light'],
          text: this.$i18n.t('companies.panel-hunt-process.risk-period'),
          options: [
            {
              text: this.$i18n.t('companies.panel-hunt-process.validate-onboarding'),
              action: () => {
                this.$emit('set-modal-type', {
                  process: this.process,
                  type: modalTypes['validated'].modalName,
                });
              },
            },
            {
              text: this.$i18n.t('companies.panel-hunt-process.drop'),
              action: async id => this.failedProcess(id),
            },
          ],
        },
        validated: {
          color: colors['deal'],
          text: this.$i18n.t('generics.success'),
        },
        failed: {
          color: colors['error'],
          text: this.$i18n.t('generics.failure'),
          options: [
            {
                text: this.$i18n.t('companies.panel-hunt-process.cancel-process-failure'),
              action: async id => this.unfailedProcess(id),
            },
          ],
        },
        drop: {
          color: colors['error'],
          text: this.$i18n.t('companies.panel-hunt-process.drop'),
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      myOldProcesses: 'myOldProcesses',
      isSuperUser: 'user/isSuperUser',
    }),
    isDrop() {
      return this.process.failedAt && this.process.dealtAt;
    },
    isFailed() {
      return this.process.failedAt && !this.process.dealtAt;
    },
    stepsCard() {
      // Get status name depending of the process status value
      const step = getStepNameByProcessStatus(this.process.status);

      const specialCards = [
        {
          cond: this.isDrop,
          card: this.cards.drop,
        },
        {
          cond: this.isFailed,
          card: this.cards.failed,
        },
      ];

      for (let i = 0; i < specialCards.length; i++) {
        const { cond, card } = specialCards[i];

        if (cond) {
          return card;
        }
      }

      return this.cards[step];
    },
  },
  methods: {
    async failedProcess(id) {
      const step = getStepNameByProcessStatus(this.process.status);
      const updatedProcess = await processController.move(id, 'failed');

      this.updatePanel(updatedProcess, step);

      if (this.process.status >= apiStatus.dealt) {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSck7JJ96JkDyubQCryJmyETQmCpHp1w8d9XGthf4VY7Brzs-Q/viewform', '_blank');
      }
    },
    async unfailedProcess(id) {
      const step = getStepNameByProcessStatus(this.process.status);
      const updatedProcess = await processController.move(id, 'unfailed');

      this.updatePanel(updatedProcess, step);
    },
    updatePanel(updatedProcess, step) {
      this.$emit('profile-updated', {
        processState: updatedProcess._coder.processState,
        coachProcessState: updatedProcess._coder.coachProcessState,
      });
      this.$emit('process-update', { updatedProcess: updatedProcess, stepName: step });
    },
  },
};
