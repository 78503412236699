<template>
  <button
    :class="[
      `border-radius-${radiusSize}`,
      {
        [`bc-button__${color}--negative`]: type === 'negative',
        [`bc-button__${color}--flat`]: type === 'flat',
        [`bc-button__${color}--outlined`]: type === 'outlined',
        [`bc-button__${color}`]: type === 'default',
        'bc-button--extra-small': size === 'extra-small' && !onlyIcon,
        'bc-button--small': size === 'small' && !onlyIcon,
        'bc-button--large': size === 'large' && !onlyIcon,
        'bc-button--only-icon': onlyIcon,
        'bc-button--only-icon--default': onlyIcon && size === 'default',
        'bc-button--only-icon--extra-small': onlyIcon && size === 'extra-small',
        'bc-button--only-icon--small': onlyIcon && size === 'small',
        'bc-button--only-icon--large': onlyIcon && size === 'large',
        'bc-button--disabled': $attrs.disabled || isLoading
      }
    ]"
    :disabled="$attrs.disabled || isLoading"
    :type="nativeType"
    class="bc-button"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <i
      v-if="iconLeft"
      :class="[
        `icon-${iconLeft}`,
        {
          'bc-button__icon-left font-size-s': !onlyIcon
        }]"
      class="is-flex">
    </i>

    <i
      v-if="iconCenter"
      :class="[
        `icon-${iconCenter}`,
        {
          'bc-button__icon-center font-size-l': !onlyIcon
        }]"
      class="is-flex">
    </i>

    <span>
      <!-- @slot The Content of the button. Can be text, icons... -->
      <slot v-if="!isLoading"></slot>
      <bc-spinner v-else color="white"></bc-spinner>
    </span>
    <i
      v-if=" iconRight"
      :class="[
        `icon-${iconRight}`,
        {
          'bc-button__icon-right font-size-l': !onlyIcon
        }]"
      class="is-flex">
    </i>
  </button>
</template>

<script>
  import BcSpinner from '../BcSpinner';

  export default {
    name: 'bc-button',
    components: {
      BcSpinner,
    },
    props: {
      /**
       * The Color of the button
       * Attention les variantes des couleurs ne marchent pas pour le type default (avec des gradients).
       *
       * @values primary, secondary, tertiary, info, success, error, warning, coach, process, offer, deal
       */
      color: {
        type: String,
        default: 'primary',
        validator: value => {
          return [
            'primary',
            'primary-base',
            'primary-light',
            'primary-dark',
            'secondary-light',
            'secondary',
            'tertiary',
            'info',
            'success',
            'error',
            'error-base',
            'warning',
            'warning-dark',
            'warning-gradient',
            'coach',
            'process',
            'offer',
            'deal',
            'grey-5',
          ].indexOf(value) >= 0;
        },
      },
      /**
       * The Size of the button
       * @values default, extra-small, small, large
       */
      size: {
        type: String,
        default: 'default',
        validator: value => {
          return [
            'default',
            'extra-small',
            'small',
            'large',
          ].indexOf(value) >= 0;
        },
      },
      /**
       * The Icon set to the left of the button
       */
      iconLeft: {
        type: String,
        default: '',
      },
      /**
       * The Icon set to the center of the button
       */
      iconCenter: {
        type: String,
        default: '',
      },
      /**
       * The Icon set to the left of the button
       */
      iconRight: {
        type: String,
        default: '',
      },
      /**
       * The bluecoders Type of the button
       * @values default, outlined
       */
      type: {
        type: String,
        default: 'default',
        validator: value => {
          return [
            'default',
            'outlined',
            'flat',
            'negative',
            'transparent',
          ].indexOf(value) >= 0;
        },
      },
      /**
       * The html Type of the button
       * @values button, submit, reset
       */
      nativeType: {
        type: String,
        default: 'button',
        validator: value => ['button', 'submit', 'reset'].indexOf(value) >= 0,
      },
      radiusSize: {
        type: String,
        default: 'l',
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      onlyIcon() {
        return !this.$slots.default;
      },
    },
  };
</script>

<style lang=scss scoped>
  @import "../../assets/scss/exports/colors.module";
  @import "../../assets/scss/variables/colors";
  @import "../../assets/scss/variables/typography";
  @import "../../assets/scss/variables/border-radius";

  @function get_color($color) {
    @return map_get($colors, $color);
  }

  @mixin gradient($color) {
    @if get_color(#{$color}-light) {
      background: linear-gradient(90deg, get_color(#{$color}-light), get_color($color));
    }
  }

  @mixin default-button($color) {
    background: get_color($color);
    color: $color-white;
    transition: all 300ms ease;
    x
    &:hover {
      opacity: 1;
      @if get_color(#{$color}-hover) {
        background: get_color(#{$color}-hover);
      }
    }

    &:active {
      opacity: 0.7;
    }

    &--activated {
      background: rgba($color-offer, 0.4);
      color: get_color(#{$color}-dark);
      border: 1px solid get_color(#{$color}-dark)
    }
  }

  @mixin outlined-button($color) {
    padding: 11px 20px;
    background-color: $color-neutre-4;
    color: get_color($color);
    border: 1px solid get_color($color);

    &:hover {
      background-color: get_color($color);
      color: $color-white;
    }

    &:active {
      background-color: $color-white;
      color: get_color($color);
      border: 2px solid get_color($color);
    }
  }

  @mixin flat-button($color) {
    background: get_color($color);
    border: 1px solid get_color($color);
    color: $color-white;
    transition: all 300ms ease;

    &:hover {
      background: $color-white;
      color: get_color($color);
    }

    &:active {
      background-color: $color-white;
      color: get_color($color);
      border: 2px solid get_color($color);
    }
  }

  @mixin negative-button($color) {
    background: $color-white;
    color: get_color($color);
    border: 1px solid transparent;

    &:hover, &:active {
      background: transparent;
      color: $color-white;
      border: 1px solid $color-white;
    }
  }

  @mixin setup-style($args...) {
    @each $name, $color in keywords($args) {
      &__#{$name} {
        @include default-button($color);

        &--outlined {
          @include outlined-button($color);
        }

        &--flat {
          @include flat-button($color);
        }

        &--negative {
          @include negative-button($color);
        }

        &--outlined.bc-button--extra-small,
        &--flat.bc-button--extra-small,
        &--negative.bc-button--extra-small {
          padding-top: 7px;
          padding-bottom: 7px;

          &:active {
            padding-top: 6px;
            padding-bottom: 6px;
          }
        }

        &--outlined.bc-button--small,
        &--flat.bc-button--small,
        &--negative.bc-button--small {
          padding: 9px 20px;

          &:active {
            padding: 8px 20px;
          }
        }

        &--outlined.bc-button--large,
        &--flat.bc-button--large,
        &--negative.bc-button--large {
          padding: 13px 20px;

          &:active {
            padding: 12px 20px;
          }
        }

        &--outlined.bc-button--only-icon,
        &--flat.bc-button--only-icon,
        &--negative.bc-button--only-icon {
          padding: 0;
        }
      }
    }
  }

  .bc-button {
    $this: &;
    position: relative;
    padding: 12px 20px;
    width: fit-content;
    justify-content: center;
    align-items: center;
    font-family: $font-family;
    font-size: $font-size-s;
    white-space: nowrap;

    span, i {
      display: flex;
      align-items: center;
      line-height: 0.9rem;
      z-index: 3;
    }

    &__primary {
      background: linear-gradient(90deg, #4A3AFF 0%, #3865FD 51.04%, #4793F0 100%);
      overflow: hidden;

      &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0.3em;
        background-image: linear-gradient(90deg, #4A3AFF 0%, #4A3AFF 51.04%, #4A3AFF 100%);
        transition: opacity 0.1s ease-out;
        z-index: 2;
        opacity: 0;
      }

      &:hover:after {
        opacity: 1;
      }

      &-base {
        background: $color-primary;
        color: white;
      }

      &--outlined {
        color: $color-primary !important;
        border: 1px solid $color-blue-medium !important;
        background: $color-neutre-4 !important;
      }
    }

    &__error {
      background: $color-error-gradient !important;
      overflow: hidden;

      &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0.3em;
        background-image: linear-gradient(90deg, #FF7178 0%, #FF7178 51.04%, #FF7178 100%);
        transition: opacity 0.1s ease-out;
        z-index: 2;
        opacity: 0;
      }

      &:hover:after {
        opacity: 1;
      }

      &--outlined {
        color: $color-red-mandy !important;
        border: 1px solid #F5DFE0 !important;

        &:hover {
          background: #F5DFE0 !important;
        }

        &:focus {
          background: $color-red-mandy !important;
          color: $color-white !important;
        }
      }

      &-base {
        background: $color-error;
        color: $color-white;
      }
    }

    &__tertiary {
      background: $color-blue-gradient-4 !important;
    }

    &__warning-gradient {
      background: $color-warning-gradient !important;
      color: white;

      &:hover {
        background: $color-warning-gradient-dark !important;
      }
    }

    &__icon-left, &__icon-right {
      margin: -1px 0;
    }

    &__icon-left {
      margin-right: 10px;
    }

    &__icon-center {
      margin-right: auto;
    }

    &__icon-right {
      margin-left: 10px;
    }

    &--extra-small {
      padding: 8px 20px;
      font-size: $font-size-xs;
      line-height: 14px;

      #{$this}__icon-left {
        margin-right: 10px;
      }

      #{$this}__icon-right {
        margin-left: 10px;
      }

      #{$this}__icon-left, #{$this}__icon-right {
        font-size: $font-size-xs;
        height: 12px;
      }
    }

    &--small {
      padding: 10px 20px;

      #{$this}__icon-left, #{$this}__icon-right {
        font-size: $font-size-s;
        height: 14px;
      }
    }

    &--large {
      padding: 14px 20px;
      text-transform: uppercase;
    }

    &--only-icon {
      padding: 0;

      &--default {
        width: 40px;
        height: 40px;
        font-size: $font-size-m;
      }

      &--extra-small {
        width: 30px;
        height: 30px;
        font-size: $font-size-xs;
      }

      &--small {
        width: 36px;
        height: 36px;
        font-size: $font-size-s;
      }

      &--large {
        width: 44px;
        height: 44px;
        font-size: $font-size-l;
      }
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    @include setup-style(
      $primary: "primary",
      $primary-light: "primary-light",
      $primary-dark: "primary-dark",
      $secondary: "secondary",
      $secondary-light: "secondary-light",
      $tertiary: "tertiary",
      $info: "info",
      $success: "success",
      $error: "error",
      $warning: "warning",
      $warning-dark: "warning-dark",
      $coach: "coach",
      $process: "process",
      $offer: "offer",
      $deal: "deal",
      $grey: "grey-5"
    )
  }

  [class^="icon-"] {
    width: 18px;
    height: 18px;
  }
</style>
