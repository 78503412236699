import { apiStatus } from '@/macros/processes/process';

export const getStepNameByProcessStatus = status => {
  const stepNames = Object.keys(apiStatus);
  return stepNames.find(key => apiStatus[key] === status);
};

export default {
  getStepNameByProcessStatus,
};
