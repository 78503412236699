<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
  import { useStore } from '@/store';
  import { useI18n } from '@/i18n/i18n';
  import { User } from '@/domains/models/User';
  import DuplicateIcon from '@/ui-kit/assets/icons/duplicate.svg?component';
  import ArchiveIcon from '@/ui-kit/assets/icons/archive.svg?component';
  import PauseIcon from '@/ui-kit/assets/icons/pause-2.svg?component';

  defineProps<{
    cardContent: Object
    isSelected: Boolean
    context: String
  }>();

  const { t } = useI18n();

  defineEmits(['checked']);

  const store = useStore();

  const isHover = ref(false);

  const profile = computed<User>(() => store.state.user.profile);
  const settingsOptions = [
    {
      key: 'duplicate',
      label: t('generics.duplicate'),
      icon: DuplicateIcon,
    },
    {
      key: 'archive',
      label: t('generics.to-archive'),
      icon: ArchiveIcon,
    },
    {
      key: 'pause',
      label: t('generics.to-pause'),
      icon: PauseIcon,
    },
  ];

  const userInitials = computed(() => {
    return `${profile.value.firstName?.charAt(0)}${profile.value.lastName?.charAt(0)}`;
  });

  function handleEdit() {
    console.log('edit');
  }
</script>

<template>
  <div
    class="incoming-actions-card is-full-width is-column border-radius-m is-relative px-[30px] py-[20px]"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
  >
    <div
      class="incoming-actions-card__container relative flex w-full items-center justify-start">
      <div
        class="incoming-actions-card__wrapper incoming-actions-card__checkbox is-align-items-center mr-2.5"
        @click.stop>
        <bc-checkbox
          :id="cardContent._id"
          :value="isSelected"
          @input="$emit('checked', $event)"
        />
      </div>

      <!-- Name and Title -->
      <div
        class="incoming-actions-card__id is-secondary-dark is-align-items-center is-full-width mx-2.5 flex items-center">
        <p
          v-tooltip="cardContent.name"
          class="incoming-actions-card__text body-l--medium is-ellipsis is-full-width"
        >
          {{ cardContent.name }}
        </p>
      </div>

      <div
        class="incoming-actions-card__wrapper incoming-actions-card__name mx-2.5 flex shrink-0 justify-start">
        Ingénieur DATA
      </div>

      <div
        class="incoming-actions-card__wrapper incoming-actions-card__cv mx-2.5 flex shrink-0 justify-start">
        <a
          v-if="cardContent.linkedin"
          v-tooltip="$t('companies.panel-company-contracts.open-the-linkedin-profile')"
          :href="cardContent.linkedin"
          target="_blank"
          @click.stop>
          <img
            alt="logo linkedin"
            class="card-coders__icon"
            src="@/assets/logo/logo_linkedin.svg"/>
        </a>
        <a
          v-else-if="cardContent.cvUrl"
          v-tooltip="$t('companies.panel-company-contracts.open-the-cv')"
          :href="cardContent.cvUrl"
          class="font-weight-bold font-size-xl"
          target="_blank"
          @click.stop>
          {{ $t('panel-coder-edit.cv') }}
        </a>
      </div>

      <div
        class="incoming-actions-card__wrapper is-full-width incoming-actions-card__status mx-2.5 flex shrink-0 justify-start">
        <p class="flex shrink-0 rounded-2xl bg-blue-100 px-2.5 py-[1px] text-xs text-blue-800">
          En sourcing
        </p>
      </div>
      <div
        class="incoming-actions-card__wrapper is-full-width incoming-actions-card__sources mx-2.5 flex justify-start">
        <p class="flex shrink-0 rounded-2xl bg-blue-100 px-2.5 py-[1px] text-xs text-blue-800">
          2 sources
        </p>
      </div>

      <div
        class="incoming-actions-card__wrapper is-full-width incoming-actions-card__actions mx-2.5 flex justify-start gap-2">
        <p class="flex shrink-0 rounded-2xl bg-blue-100 px-2.5 py-[1px] text-xs text-[#0B66C2]">
          Invitation Linkedin
        </p>
      </div>

      <div
        class="incoming-actions-card__wrapper is-full-width incoming-actions-card__scheduled mx-2.5 flex justify-start gap-2">
        <p class="flex shrink-0 rounded-2xl bg-blue-100 px-2.5 py-[1px] text-xs text-blue-800">
          dans 2 jours
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .incoming-actions-card {
    &__id {
      @include incoming-actions-grid-id;
    }

    &__name {
      @include incoming-actions-grid-name;
    }

    &__text {
      font-size: 14px;
    }

    &__cv {
      @include incoming-actions-grid-cv;
    }

    &__status {
      @include incoming-actions-grid-status;
    }

    &__sources {
      @include incoming-actions-grid-sources;
    }

    &__actions {
      @include incoming-actions-grid-actions;
    }

    &__scheduled {
      @include incoming-actions-grid-scheduled;
    }

    &__button {
      background: $color-blue-gradient-1;
      font-weight: 500;
      font-size: 15px;

      &:hover {
        background: $color-blue-gradient-2;
      }

      &--open {
        background: $color-neutre-4;
        color: $color-primary;

        &:hover {
          color: white;
          background: $color-neutre-4 !important;
        }

        &:deep() {
          i {
            font-size: 14px;
          }
        }
      }
    }
  }
</style>
