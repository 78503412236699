<template>
  <component
    :is="panelCompanyHeaderProspectionStatusComponent"
    v-bind="$attrs"
    v-on="$listeners">
  </component>
</template>

<script>
  import PanelCompanyHeaderProspectionStatusRpo
    from '@/components/Panel/PanelCompany/PanelCompanyHeader/PanelCompanyHeaderProspectionStatus/PanelCompanyHeaderProspectionStatusRpo';

  export default {
    name: 'panel-company-profile',
    inheritAttrs: false,
    components: {
      PanelCompanyHeaderProspectionStatusRpo,
    },
    computed: {
      panelCompanyHeaderProspectionStatusComponent() {
        return 'panel-company-header-prospection-status-rpo';
      },
    },
  };
</script>
