<template>
  <div class="custom-jobs-share-form">
    <custom-jobs-form-section :title="!isIndependant ? $t('custom-jobs.share-with') : undefined">
      <div>
        <ValidationProvider>
          <bc-dropdown
            :close-dropdown-at-select="true"
            :label="$t('custom-jobs.share-with')"
            :options="coachesOptions"
            class="custom-jobs-share-form__dropdown"
            is-expanded>
            <template #label="{ isDropdownOpen }">
              <bc-dropdown-trigger
                :is-arrow="true"
                :is-dropdown-open="isDropdownOpen"
                :label="$t('custom-jobs.share-with')"
                :text="$t('custom-jobs.select-team-member')">
              </bc-dropdown-trigger>
            </template>
            <template #option="{ activeIndex, index, option }">
              <bc-dropdown-item
                :avatar="option?.pictureUrl || 'default'"
                @error="setAlternativeImg({event: $event, type: 'coders'})"
                :is-focus="activeIndex === index"
                :is-selected="isSelected(option._id)"
                :text="`${option.firstName} ${option.lastName}`"
                :title="`${option.firstName} ${option.lastName}`"
                class="font-size-s"
                @click.native.stop="setSelectedCoach(option)">
              </bc-dropdown-item>
            </template>
          </bc-dropdown>
        </ValidationProvider>
      </div>
      <div class="custom-jobs-share-form__selected-coaches">
        <div
          v-for="(selectedCoach, index) in selectedCoaches"
          :key="selectedCoach._id"
          :class="{ 'custom-jobs-share-form__item--divider': index < selectedCoaches.length - 1 }"
          class="custom-jobs-share-form__item"
        >
          <bc-avatar
            :alt="selectedCoach.firstName"
            :src="selectedCoach.pictureUrl || require('@/assets/img/default-avatar.svg')"/>
          <div class="custom-jobs-share-form__item--content">
            <h3>{{ selectedCoach.firstName }} {{ selectedCoach.lastName }}</h3>
            <p class="custom-jobs-share-form__text">{{ selectedCoach.job }}</p>
          </div>
          <div class="custom-jobs-share-form__item--close">
            <button
              v-if="selectedCoaches.length > 1"
              @click.stop="removeSelectedCoach(selectedCoach._id)"
            >
              <i class="icon-cross"></i>
            </button>
          </div>
        </div>
      </div>
    </custom-jobs-form-section>
    <slot :formData="formData" :isFormValid="true" name="footer"></slot>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { ValidationProvider } from 'vee-validate';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import CustomJobsFormSection from '@/components/CustomJobs/CustomJobsForm/CustomJobsFormSection';
  import { getFilterableBusinessCoach } from '@/macros/prospectionPipe/dropdowns';
  import defaultAvatar from '@/assets/img/default-avatar.svg'
  import { setAlternativeImg } from '@/mixins/imgFallback.js';

  export default {
    name: 'custom-jobs-share-form',
    components: {
      CustomJobsFormSection,
      BcDropdown,
      BcDropdownItem,
      BcDropdownTrigger,
      BcAvatar,
      ValidationProvider,
    },
    props: {
      defaultFormData: {
        type: Object,
        default: () => {
        },
      },
      defaultOwners: {
        type: Array,
        default: () => [],
      },
      isIndependant: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      if (this.defaultFormData?.owners?.length) {
        this.selectedCoaches = this.coachesOptions.filter(coach =>
          this.defaultFormData.owners.includes(coach._id),
        );

        const hasMe = this.defaultFormData.owners.find(coachId => coachId === this.profile._id);
        const hasNotMeInSelected = this.selectedCoaches.find(coach => coach._id === this.profile._id);
        if (hasMe && !hasNotMeInSelected) {
          this.selectedCoaches = [this.profile, ...this.selectedCoaches];
        }
      }

      if (this.defaultOwners.length) {
        this.selectedCoaches = this.defaultOwners;
      } else if (!this.selectedCoaches.length) {
        this.selectedCoaches = [this.profile];
      }
    },
    data() {
      return {
        selectedCoaches: [],
      };
    },
    computed: {
      ...mapState({
        coaches: state => state.coaches.coaches,
        profile: state => state.user.profile,
      }),
      coachesOptions() {
        const coaches = this.coaches.filter(coach => getFilterableBusinessCoach(coach));

        return [...coaches.sort((a, b) => a.firstName.localeCompare(b.firstName))];
      },
      selectedCoachesIds() {
        return this.selectedCoaches.map(coach => coach._id);
      },
      formData() {
        return { owners: this.selectedCoachesIds };
      },
    },
    methods: {
      setAlternativeImg,
      removeSelectedCoach(id) {
        this.selectedCoaches = this.selectedCoaches.filter(coach => coach._id !== id && coach._id);
      },
      isSelected(id) {
        return this.selectedCoaches.find(coach => coach._id === id) !== undefined;
      },
      setSelectedCoach(option) {
        if (this.isSelected(option._id)) {
          this.removeSelectedCoach(option._id);
          return;
        }
        this.selectedCoaches.push(option);
      },
    },
    watch: {
      selectedCoaches: {
        handler(data) {
          this.$emit('update-value', data);
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .custom-jobs-share-form {
    width: 100%;
    flex-direction: column;

    &__selected-coaches {
      display: block;
      max-height: 500px;
      overflow: auto;
    }

    &__item {
      height: 70px;
      align-items: center;
      gap: 20px;

      & > img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        object-fit: cover;
      }

      &--divider {
        border-bottom: 1px solid $color-blue-medium;
      }

      &--content {
        flex-direction: column;

        & > h3 {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: $color-blue-dark-cello;
        }
      }

      &--close {
        flex: 1;
        justify-content: flex-end;
        margin-right: 20px;

        & > button {
          color: $color-blue-dark-cello;
          opacity: 0.5;
        }
      }
    }

    &__dropdown {
      margin-bottom: 20px;
      width: 500px;
    }

    &__text {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $color-blue-dark-cello;
    }
  }
</style>
