<template>
  <modal-feature
    feature="addTeamMember"
    :title="$t('features.invite-new-users')"
    @close-modal="$emit('close-modal')"
  >
    <template #content>
      <p>{{ $t('features.work-in-group') }}</p>
    </template>
    <template #logo>
      <img src="@/assets/logo/feature-add-team-member.png" alt="add team member feature logo" />
    </template>
  </modal-feature>
</template>

<script>
import ModalFeature from './ModalFeature.vue';

export default {
  name: 'modal-feature-add-team-member',
  components: {
    ModalFeature,
  },
}
</script>
