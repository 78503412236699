<script lang="ts" setup>
  import MvSearchbar from '@/ui-kit/components/MvSearchbar/MvSearchbar.vue';
  import { ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { useDebounceFn } from '@vueuse/core';

  const router = useRouter();
  const route = useRoute();

  const searchbarInput = ref<string>('');
  const isLoading = ref<boolean>(false);
  const suggestions = ref<any[]>([]);
  const triggerAutocompleteEvent = () => {
    console.log('triggerAutocompleteEvent');
  };

  const setRouteSearchQuery = useDebounceFn(() => {
    if (searchbarInput.value) {
      router.push({
        ...route,
        query: {
          ...route.query,
          'campaigns-search': searchbarInput.value,
        },
      });
    } else {
      router.push({
        ...route,
        query: {
          ...route.query,
          'campaigns-search': undefined,
        },
      });
    }

    isLoading.value = false;
  }, 1000);

  watch(() => isLoading.value, setRouteSearchQuery);
  watch(() => searchbarInput.value, () => {
    isLoading.value = true;
  });
</script>

<template>
  <MvSearchbar
    v-model="searchbarInput"
    :is-loading="isLoading"
    :placeholder="$t('generics.search-placeholder', { object: $t('generics.a-campaign') } )"
    :show-results="false"
    :suggestions="suggestions"
  />
</template>

<style lang="scss" scoped>

</style>
