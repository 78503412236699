import coders from '@/api/coders';
import { validateId } from '@/managers/coders/validators/validateId';
import { validateName } from '@/managers/coders/validators/validateName';
import { serializeCoder } from '@/managers/coders/formatters/codersSerializers';

export const editCoderProfile = (id, data) => {
  const validate = coder => {
    try {
      const mapValidator = {
        _id: validateId,
        firstName: validateName,
        lastName: validateName,
      };

      const keysToValidate = Object.keys(coder);

      keysToValidate.forEach(key => mapValidator[key]);
    } catch (error) {
      throw error;
    }
  };
  const execute = (id, data) => {
    try {
      return coders.updateCoderProfile({
        id,
        updatedObj: data,
      });
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    validate(data);

    const formattedCoder = serializeCoder(data);

    return execute(id, formattedCoder);
  } catch (error) {
    throw error;
  }
};

export default {
  editCoderProfile,
};
