<template>
  <views class="database-tags">
    <admin-tabs :tags-count="countTags">
      <bc-button
        class="database-tags__button"
        icon-left="plus"
        @click.native="openTagsCreate">
        {{ $t('tags.create-a-tag') }}
      </bc-button>
    </admin-tabs>
    <router-view></router-view>
    <views-container>
      <div class="database-tags__filters">
        <div>
          <bc-input
            class="database-tags__input"
            v-model="searchbarInput"
            label="Name"
            name="tag_name"
            @input="inputEvent"
          >
          </bc-input>
          <div class="database-tags__filters-label">Type</div>
          <bc-dropdown
            :is-scrollable="false"
            :options="tagTypesOptions"
            label="Visible"
            class="custom-jobs-filters__dropdown"
            is-expanded>
            <template #label="{ isDropdownOpen }">
              <bc-dropdown-trigger
                :is-arrow="true"
                :is-dropdown-open="isDropdownOpen"
                :text="tagTypesOptions.find(e => e.value === tagTypeId).name"
                icon="arrow-right"
              >
              </bc-dropdown-trigger>
            </template>
            <template #option="{ activeIndex, index, option }">
              <bc-dropdown-item
                :is-focus="activeIndex === index"
                :text="option.name"
                :title="option.name"
                class="hunt__dropdown-item"
                @click.native="setDropdown(`tagTypeId`, option)">
              </bc-dropdown-item>
            </template>
          </bc-dropdown>
          <div class="database-tags__filters-label">{{ $t("generics.sort-by") }}</div>
          <bc-dropdown
            :is-scrollable="false"
            :options="sortKeyOptions"
            class="custom-jobs-filters__dropdown"
            is-expanded>
            <template #label="{ isDropdownOpen }">
              <bc-dropdown-trigger
                :is-arrow="true"
                :is-dropdown-open="isDropdownOpen"
                :text="sortKeyOptions.find(e => e.value === sortKey).name"
                :is-filter="true"
                :icon="sortKeyOptions.find(e => e.value === sortKey).icon"
              >
              </bc-dropdown-trigger>
            </template>
            <template #option="{ activeIndex, index, option }">
              <bc-dropdown-item
                :is-focus="activeIndex === index"
                :text="option.name"
                :title="option.name"
                class="hunt__dropdown-item"
                @click.native="setDropdown(`sortKey`, option)">
              </bc-dropdown-item>
            </template>
          </bc-dropdown>
          <div class="database-tags__filters-label">{{ $t("generics.order") }}</div>
          <bc-dropdown
            :is-scrollable="false"
            :options="sortValueOptions"
            class="custom-jobs-filters__dropdown"
            is-expanded>
            <template #label="{ isDropdownOpen }">
              <bc-dropdown-trigger
                :is-arrow="true"
                :is-dropdown-open="isDropdownOpen"
                :text="sortValueOptions.find(e => e.value === sortValue).name"
                :icon="sortValueOptions.find(e => e.value === sortValue).icon"
              >
              </bc-dropdown-trigger>
            </template>
            <template #option="{ activeIndex, index, option }">
              <bc-dropdown-item
                :is-focus="activeIndex === index"
                :text="option.name"
                :title="option.name"
                class="hunt__dropdown-item"
                @click.native="setDropdown(`sortValue`, option)">
              </bc-dropdown-item>
            </template>
          </bc-dropdown>
        </div>
        <div>
          <div class="database-tags__filters-label">{{ $t("generics.display") }}</div>
          <bc-dropdown
            :is-scrollable="false"
            :options="limitOptions"
            class="custom-jobs-filters__dropdown"
            is-expanded>
            <template #label="{ isDropdownOpen }">
              <bc-dropdown-trigger
                :is-arrow="true"
                :is-dropdown-open="isDropdownOpen"
                :text="limitOptions.find(e => e.value === limit).name"
                icon="arrow-right"
              >
              </bc-dropdown-trigger>
            </template>
            <template #option="{ activeIndex, index, option }">
              <bc-dropdown-item
                :is-focus="activeIndex === index"
                :text="option.name"
                :title="option.name"
                class="hunt__dropdown-item"
                @click.native="setDropdown(`limit`, option)">
              </bc-dropdown-item>
            </template>
          </bc-dropdown>
        </div>
      </div>
      <div class="database-tags__filters-actions">
        <div>
          <bc-button
            class="database-tags__button ml10"
            radius-size="s"
            size="extra-small"
            icon-left="cross"
            @click.native="reInit">
            {{ $t('generics.reset') }}
          </bc-button>
          <bc-button
            class="database-tags__button ml10"
            radius-size="s"
            size="extra-small"
            icon-left="arrow-right"
            :disabled="checkedTags.length === 0"
            @click.native="openTagsReplace">
            {{ $t('generics.replace') }}
          </bc-button>
          <bc-button
            class="database-tags__button ml10"
            radius-size="s"
            size="extra-small"
            icon-left="scissors"
            :disabled="checkedTags.length === 0"
            @click.native="openTagsPush">
            {{ $t('generics.push') }}
          </bc-button>
        </div>
        <div>
          <div class="database-tags__filters-label">Visible</div>
          <bc-dropdown
            :is-scrollable="false"
            :options="visibleOptions"
            label="Visible"
            class="custom-jobs-filters__dropdown"
            is-expanded>
            <template #label="{ isDropdownOpen }">
              <bc-dropdown-trigger
                :is-arrow="true"
                :is-dropdown-open="isDropdownOpen"
                :text="visibleOptions.find(e => e.value === visible).name"
                icon="arrow-right"
              >
              </bc-dropdown-trigger>
            </template>
            <template #option="{ activeIndex, index, option }">
              <bc-dropdown-item
                :is-focus="activeIndex === index"
                :text="option.name"
                :title="option.name"
                class="hunt__dropdown-item"
                @click.native="setDropdown(`visible`, option)">
              </bc-dropdown-item>
            </template>
          </bc-dropdown>
          <div class="database-tags__filters-label">Vérifié</div>
          <bc-dropdown
            :is-scrollable="false"
            :options="verifiedOptions"
            label="Visible"
            class="custom-jobs-filters__dropdown"
            is-expanded>
            <template #label="{ isDropdownOpen }">
              <bc-dropdown-trigger
                :is-arrow="true"
                :is-dropdown-open="isDropdownOpen"
                :text="verifiedOptions.find(e => e.value === verified).name"
                icon="arrow-right"
              >
              </bc-dropdown-trigger>
            </template>
            <template #option="{ activeIndex, index, option }">
              <bc-dropdown-item
                :is-focus="activeIndex === index"
                :text="option.name"
                :title="option.name"
                class="hunt__dropdown-item"
                @click.native="setDropdown(`verified`, option)">
              </bc-dropdown-item>
            </template>
          </bc-dropdown>
          <div class="database-tags__filters-label">{{ $t('topbar.organizations-cap') }}</div>
          <bc-dropdown
            :is-scrollable="true"
            :options="organizationsWithTagsOptions"
            label="Organization"
            class="custom-jobs-filters__dropdown w-[200px]"
            is-expanded>
            <template #label="{ isDropdownOpen }">
              <bc-dropdown-trigger
                :is-arrow="true"
                :is-dropdown-open="isDropdownOpen"
                :text="organizationsWithTagsOptions.find(e => e.value === organization).name"
                icon="arrow-right"
              >
              </bc-dropdown-trigger>
            </template>
            <template #option="{ activeIndex, index, option }">
              <bc-dropdown-item
                :is-focus="activeIndex === index"
                :text="option.name"
                :title="option.name"
                class="hunt__dropdown-item"
                @click.native="setDropdown(`organization`, option)">
              </bc-dropdown-item>
            </template>
          </bc-dropdown>
        </div>
      </div>
      <div
        class="database-tags__cards">
        <bc-spinner
          v-if="!isLoaded"
          class="database-tags__spinner">
        </bc-spinner>
        <div
          v-if="isLoaded"
          class="is-column is-align-items-center"
        >
          <card-list
            ref="cardList"
            :cards="tags"
            :is-loaded="true"
            class="pagination__card-list">
            <template #card="{ card }">
              <card
                :card-content="card"
                card-type="partners">
                <card-display-tag
                  :card-content="card"
                  :is-checked="checkedTags.includes(card._id)"
                  @checked="checkTag($event, card)"
                >
                </card-display-tag>
              </card>
            </template>
          </card-list>
          <bc-pagination
            :current.sync="currentPage"
            :total="countTags"
            :per-page="limit"
            class="database-tags__pagination">
          </bc-pagination>
        </div>
      </div>
    </views-container>
  </views>
</template>

<script>
  import debounce from 'debounce';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import BcInput from '@/ui-kit/components/BcInput/BcInput.vue';
  import BcPagination from '@/ui-kit/components/BcPagination/BcPagination';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
  import Card from '@/components/Card/Card';
  import CardList from '@/components/CardList/CardList';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import CardDisplayTag from '@/components/Card/CardDisplayTag';
  import { searchTags, countSearchTags } from '@/api/tags';
  import { mapActions, mapState } from 'vuex';
  import Views from '@/components/Views/Views';
  import ViewsContainer from '@/components/Views/ViewsContainer';
  import AdminTabs from '@/views/Tabs/AdminTabs';
  import PanelNavigationMixin from '@/common-old/mixins/Panel/panelNavigation';

  export default {
    name: 'tags',
    mixins: [PanelNavigationMixin],
    components: {
      ViewsContainer,
      Views,
      BcInput,
      BcPagination,
      BcDropdown,
      BcDropdownItem,
      BcDropdownTrigger,
      CardDisplayTag,
      BcButton,
      Card,
      CardList,
      BcSpinner,
      AdminTabs,
    },
    data() {
      return {
        isLoaded: false,
        currentPage: parseInt(this.$route.query.page) || 1,
        limit: 10,
        visible: null,
        verified: null,
        tagTypeId: null,
        organization: null,
        tags: [],
        checkedTags: [],
        countTags: 0,
        searchbarInput: this.$route.query.name || '',
        sortKey: "_id",
        sortValue: "desc",
        sortKeyOptions: [
          {
            name: this.$t("generics.last-added"),
            value: '_id',
            icon: 'calendar',
          },
          {
            name: this.$t("generics.number-of-profiles"),
            value: 'count.coders',
            icon: 'users',
          },
          {
            name: this.$t("generics.number-of-jobs"),
            value: 'count.jobs',
            icon: 'briefcase',
          },
        ],
        sortValueOptions: [
          {
            name: this.$t("generics.increasing"),
            value: 'asc',
            icon: 'arrow-down',
          },
          {
            name: this.$t("generics.decreasing"),
            value: 'desc',
            icon: 'arrow-up',
          },
        ],
        limitOptions: [
          {
            name: "10",
            value: 10,
          },
          {
            name: "50",
            value: 50,
          },
        ],
        visibleOptions: [
        {
            name: "Tous",
            value: null,
          },
          {
            name: this.$t("generics.yes"),
            value: true,
          },
          {
            name: this.$t("generics.no"),
            value: false,
          },
        ],
        verifiedOptions: [
          {
            name: "Tous",
            value: null,
          },
          {
            name: this.$t("generics.yes"),
            value: true,
          },
          {
            name: this.$t("generics.no"),
            value: false,
          },
        ],
        typesOptions2: [
          {
            name: "Tous",
            value: null,
          }
        ],
      };
    },
    created() {
      this.getTagTypes();
      this.getOrganizationsWithTags();
      this.searchTags();
    },
    watch: {
      $route: {
        handler(to = {}, from = {}) {
          if (from.name.includes('Panel') && !to.name.includes('Panel')) {
            this.searchTags();
            this.checkedTags = [];
          }
        },
        deep: true,
      },
      currentPage(newPage) {
        this.$router.push({ ...this.$route, query: { ...this.$route.query, page: newPage } });

        this.searchTags();
        this.checkedTags = [];
      },
    },
    computed: {
      ...mapState({
        tagTypes: state => state.tags.tagTypes,
        tagTypesOptions: state => state.tags.tagTypesOptions,
        organizationsWithTags: state => state.tags.organizationsWithTags,
        organizationsWithTagsOptions: state => state.tags.organizationsWithTagsOptions,
      }),
    },
    methods: {
      ...mapActions({
        getTagTypes: 'tags/getTagTypes',
        getOrganizationsWithTags: 'tags/getOrganizationsWithTags',
      }),
      setDropdown(field, name) {
        this[field] = name.value;
        this.$router.push({ ...this.$route, query: { ...this.$route.query, page: 1 } });
        this.currentPage = 1;

        this.searchTags();
      },
      resetTags() {
        this.tags = [];
        this.checkedTags = [];
      },
      inputEvent: debounce(async function() {
        if (this.searchbarInput.length > 2) {
          this.currentPage = 1;
          this.$router.push({ ...this.$route, query: { ...this.$route.query, page: this.currentPage, name: this.searchbarInput } });
          this.searchTags();
        }
      }),
      async searchTags() {
        this.isLoaded = false;

        try {
          this.resetTags();

          const { data } = await searchTags({
            page: this.currentPage,
            visible: this.visible,
            limit: this.limit,
            sortKey: this.sortKey,
            sortValue: this.sortValue,
            name: this.searchbarInput,
            verified: this.verified,
            tagTypeId: this.tagTypeId,
            _organization: this.organization,
          });

          if (this.currentPage === 1) {
            const { data } = await countSearchTags({
              name: this.searchbarInput,
              visible: this.visible,
              verified: this.verified,
              tagTypeId: this.tagTypeId,
              _organization: this.organization,
            });

            this.countTags = data;
          }

          this.tags = data;

          this.isLoaded = true;
        } catch (error) {
          this.$emit('error', error);

          this.isLoaded = false;
        }
      },
      checkTag(event, tag) {
        if (this.checkedTags.includes(tag)) {
          this.checkedTags = this.checkedTags.filter(e => e !== tag);
        } else {
          this.checkedTags.push(tag);
        }
      },
      openTagsReplace() {
        this.openPanel({ type: 'tags-replace', origins: this.checkedTags.map(e => e._id) })
      },
      openTagsCreate() {
        this.openPanel({ type: 'tags-create' })
      },
      openTagsPush() {
        if (this.checkedTags.length > 1) {
          this.$toast.show({
            type: 'error',
            message: "Vous devez sélectionné qu'un seul tag !",
          });
        } else {
          this.openPanel({ type: 'tags-push', origin: this.checkedTags[this.checkedTags.length - 1]._id })
        }
      },
      reInit() {
        this.searchbarInput = '';
        this.currentPage = 1;
        this.sortKey = "_id";
        this.sortValue = "desc";
        this.limit = 10;
        this.$router.push({ ...this.$route, query: { ...this.$route.query, page: 1, name: "" } });
        this.checkedTags = [];
        this.searchTags();
      },
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (vm.$store.getters['user/isMarvinRecruiter']) {
          next();
        } else {
          next('/');
        }
      });
    },
  };
</script>

<style lang="scss" scoped>
  .database-tags {
    @include page_grid();
    &__cards {
      flex-wrap: wrap;
      flex-direction: column;
    }

    &__filters {
      margin: 20px 0px 20px;
      // align-self: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      &-label {
        align-items: center;
        padding: 0 10px 0 20px;
      }
    }

    &__filters-actions {
      margin: 0px 0px 20px;
      // align-self: flex-start;
      justify-content: space-between;

      &-label {
        align-items: center;
        padding: 0 10px 0 20px;
      }
    }

    &__input {
      margin: 0 10px;
    }

    &__dropdown {
      margin: 0 10px;
    }

    &__button {
      flex-shrink: 0;
    }

    &__pagination {
      margin: 20px 0;
      justify-content: right;
    }

    &__spinner {
      margin: 100px 0;
      justify-content: center;
    }

    .ml10 {
      margin-left: 10px;
    }
  }

  .bc-dropdown {
    min-width: 150px;
    justify-content: center;
    margin: 0 5px;
  }
</style>
