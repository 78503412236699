<template>
  <keep-alive>
    <div class="topbar-dropdown">
      <div
        ref="panel"
        class="topbar-dropdown__panel"
        v-bind="$listeners">
        <slot name="header"></slot>
      </div>
      <div
        ref="content"
        :style="contentStyle"
        class="topbar-dropdown__content">
        <div
          ref="wrapper"
          class="topbar-dropdown__wrapper">
          <slot></slot>
        </div>
      </div>
    </div>
  </keep-alive>
</template>

<script>
  export default {
    name: 'topbar-dropdown',
    inheritAttrs: false,
    data() {
      return {
        contentHeight: 0,
        maxContentHeight: 0,
      };
    },
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },
      isHover: {
        type: Boolean,
        default: false,
      },
      routerLinkActiveSelector: {
        type: String,
        default: '.router-link-active'
      }
    },
    computed: {
      contentStyle() {
        return {
          height: this.contentHeight > this.maxContentHeight ? `${this.maxContentHeight}px` : `${this.contentHeight}px`,
        };
      },
    },
    methods: {
      setMaxContentHeight() {
        this.maxContentHeight = ((this.$refs.content?.scrollHeight || 0) * 2);
      },
      setContentHeight() {
        if (this.maxContentHeight) {
          this.contentHeight = this.maxContentHeight;
        }
        this.contentHeight = this.$refs.content && this.isActive ? (this.$refs.content.scrollHeight * 2) : 0;
      },
      setActiveState() {
        this.$nextTick(() => {
          if (this.$refs.wrapper.querySelector('.router-link-active') || this.$refs.wrapper.querySelector(this.routerLinkActiveSelector) ) {
            this.$emit('set-active-status', true);
          } else {
            this.$emit('set-active-status', false);
          }
        });
      },
    },
    watch: {
      isActive: {
        handler() {
          this.setContentHeight();
        },
      },
      isHover: {
        handler() {
          this.setActiveState();
        },
      },
      $route: {
        handler() {
          this.setActiveState();
        },
        deep: true,
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.setMaxContentHeight()
        this.setContentHeight();
        this.setActiveState();
      });
    },
  };
</script>

<style lang="scss" scoped>
  .topbar-dropdown {
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    border: 1px solid transparent;
    flex-direction: column;

    &__text {
      user-select: none;
    }

    &__panel {
      cursor: pointer;
      width: 100%;
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    &__content {
      min-height: 0;
      transition: 0.3s;
      overflow: hidden;
      width: 100%;
    }

    &__wrapper {
      flex-shrink: 0;
      height: auto;
      min-height: 0;
      align-items: center;
      width: 100%;
      white-space: nowrap;
    }
  }
</style>
