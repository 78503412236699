import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      selectedCards: state => state.card.selectedCards,
      cards: state => state.card.cards,
    }),
    isAllCardsSelected() {
      return this.cards.length === this.selectedCards.length;
    },
  },
  methods: {
    setSelectedCards(value, card) {
      const selectedCardsIds = this.selectedCards.map(card => card._id);

      if (!value && selectedCardsIds.includes(card._id)) {
        this.$store.dispatch('setSelectedCards', this.selectedCards.filter(c => c._id !== card._id));
      } else if (value && !selectedCardsIds.includes(card._id)) {
        this.$store.dispatch('setSelectedCards', [...this.selectedCards, card]);
      }
    },
    getIsSelectedStatus(id) {
      return this.selectedCards.map(card => card._id).includes(id);
    },
    selectAllCards(value) {
      const selectedCards = value ? this.cards : [];

      this.$store.dispatch('setSelectedCards', selectedCards);
    },
    resetSelectedCards() {
      this.$store.dispatch('setSelectedCards', []);
    },
  },
};
