<template>
  <div class="modal-process-abort-reason">
    <div class="modal-process-abort-reason__content">
      <h2>✋❌</h2>
      <h2>{{ $t('process.failure-reason-title') }}</h2>
      <textarea
        class="modal-process-abort-reason__textarea"
        :placeholder="`${$t('process.failure-reason-placeholder')} (${$t('generics.min-chars', { nb: reasonMinLength })})`"
        v-model="reason"
        rows="25"
      >
      </textarea>
    </div>
    <div class="modal-process-abort-reason__footer">
      <bc-button class="flex-1" @click="$emit('cancel-action', $event)" type="outlined">
        {{ $t('generics.cancel') }}
      </bc-button>
      <bc-button class="flex-1" :disabled="isLoading || reason.length < reasonMinLength" @click="onConfirm">
        {{ $t('generics.validate') }}
      </bc-button>
    </div>
  </div>
</template>

<script>
import BcButton from '@/ui-kit/components/BcButton/BcButton';

export default {
  name: 'modal-process-abort-reason',
  components: {
    BcButton,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reasonMinLength: 5,
      reason: ''
    }
  },
  methods: {
    onConfirm() {
      if (this.isLoading || this.reason.length < this.reasonMinLength) {
        return;
      }
      this.$emit('confirm-action', { confirm: true, reason: this.reason })
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-process-abort-reason {
  width: 540px;
  display: flex;
  flex-direction: column;
  padding: 50px;

  & h2 {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: $color-tertiary;
  }

  &__content {
    flex-direction: column;
  }

  &__textarea {
    height: 200px;
    width: 100%;
    margin-top: 10px;
    border: 1px solid $color-blue-medium;
    border-radius: 5px;
    padding: 11px 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    &::placeholder {
      color: $color-blue-heavy-dark;
    }
  }

  &__footer {
    margin-top: 30px;
    justify-content: center;
    gap: 10px;
  }
}

.flex-1 {
  flex: 1;
}
</style>
