import { http } from '@/api/index';

export const getCoachFavoriteLists = coachId => {
  return http.get(`/coaches/${coachId}/lists`);
};

export const getFavoriteLists = ({
  query = '',
  coderId = '',
  jobId = '',
  companyId = '',
  type,
  cancelTokenSource = {},
}) => {
  return http.get('/lists', {
    params: {
      ...query && { query },
      ...coderId && { coderId },
      ...jobId && { jobId },
      ...companyId && { companyId },
      type,
    },
    cancelToken: cancelTokenSource.token,
  });
};

export const getFavoriteListsExistence = ({
  ids,
  type,
}) => {
  return http.get('/lists/existence', {
    params: {
      ids,
      type,
    },
  });
};


export const getMultipleFavoriteLists = ({
  query = '',
  coderId = '',
  jobId = '',
  companyId = '',
  types,
  cancelTokenSource = {},
}) => {
  return http.get('/lists/multiple', {
    params: {
      ...query && { query },
      ...coderId && { coderId },
      ...jobId && { jobId },
      ...companyId && { companyId },
      types,
    },
    cancelToken: cancelTokenSource.token,
  });
};

export const createFavoriteList = ({ name, coachId, subscribers, type }) => {
  return http.post('/lists', {
    name,
    coachId,
    subscribers,
    type,
  });
};

export const editFavoriteList = ({
                                   name,
                                   listId,
                                   addedSubscribers,
                                   changedSubscribers,
                                   removedSubscribers,
                                 }) => {
  return http.patch(`/lists/${listId}`, {
    name,
    addedSubscribers,
    changedSubscribers,
    removedSubscribers,
  });
};

export const deleteFavoriteList = listId => {
  return http.delete(`/lists/${listId}`);
};

export const addCoderToFavoriteList = ({ listId, coderId }) => {
  return http.post(`/lists/${listId}/coder`, {
    coderId,
  });
};

export const addJobToFavoriteList = ({ listId, jobId }) => {
  return http.post(`/lists/${listId}/job`, {
    jobId,
  });
};

export const addCompanyToFavoriteList = ({ listId, companyId }) => {
  return http.post(`/lists/${listId}/company`, {
    companyId,
  });
};


export const removeCoderFromFavoriteList = ({ listId, coderId }) => {
  return http.delete(`/lists/${listId}/coder`, {
    params: {
      coderId,
    },
  });
};

export const removeJobFromFavoriteList = ({ listId, jobId }) => {
  return http.delete(`/lists/${listId}/job`, {
    params: {
      jobId,
    },
  });
};

export const removeCompanyFromFavoriteList = ({ listId, companyId }) => {
  return http.delete(`/lists/${listId}/company`, {
    params: {
      companyId,
    },
  });
};

export const getFavoriteListById = listId => {
  return http.get(`/lists/${listId}`);
};

export const updateCoachFavoriteListRank = ({ lists, coachId }) => {
  return http.patch(`/coaches/${coachId}`, {
    lists,
  });
};

export const getFavoriteListContactExported = id => {
  return http.get(`/lists/${id}/export`);
};

export const updateFavoriteListNotifications = (id, notifications) => {
  return http.patch(`/lists/${id}/notifications`, {
    notifications,
  });
};

export default {
  getCoachFavoriteLists,
  getFavoriteLists,
  getFavoriteListsExistence,
  getMultipleFavoriteLists,
  createFavoriteList,
  editFavoriteList,
  deleteFavoriteList,
  addCoderToFavoriteList,
  addJobToFavoriteList,
  addCompanyToFavoriteList,
  removeCoderFromFavoriteList,
  removeJobFromFavoriteList,
  removeCompanyFromFavoriteList,
  getFavoriteListById,
  updateCoachFavoriteListRank,
  getFavoriteListContactExported,
  updateFavoriteListNotifications,
};
