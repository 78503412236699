<template>
  <mv-filter
    :label="$t('generics.companies')"
    icon="certificate"
    :count="selected.length"
  >
    <filter-content
      class="filter-companies__content"
      :label="$t('generics.companies')"
    >
      <companies-autocomplete
        :selected="selected"
        :show-label="false"
        :filter-options="filterOptions"
        :selected-filter="selectedFilter"
        @select="handleSelect"
        @select-option="onSelectOption"
      ></companies-autocomplete>
    </filter-content>
  </mv-filter>
</template>

<script>
import MvFilter from '@/ui-kit/components/MvFilter/MvFilter';
import FilterContent from '@/components/Filter/FilterContent';
import CompaniesAutocomplete from '@/components/Companies/NewCompaniesAutocomplete';

export default {
  name: 'filter-companies',
  components: {
    MvFilter,
    CompaniesAutocomplete,
    FilterContent,
  },
  mounted() {
    let selected = this.filterOptions[0];
    if (this.$route.query.companiesOperator) {
      selected = this.filterOptions.find(option => option.key === this.$route.query.companiesOperator);
    }
    this.selectedFilter = selected;
  },
  data() {
    return {
      filterOptions: [
        { key: 'and', name: this.$t('generics.and').toUpperCase() },
        { key: 'or', name: this.$t('generics.or').toUpperCase() },
      ],
      selectedFilter: {},
    }
  },
  computed: {
    companies() {
      return this.$route.query.companies;
    },
    companiesNames() {
      return this.$route.query.companiesNames;
    },
    selected() {
      if (!this.companies) {
        return [];
      }
      const companies = this.companies.split(',');
      const companiesNames = this.companiesNames.split(',');
      return companies.map((id, index) => ({
        _id: id,
        name: companiesNames[index],
      }))
    },
    selectedOperator() {
      return this.selectedFilter?.key || this.filterOptions[0]?.key;
    },
  },
  methods: {
    handleSelect(companies) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          companies: companies.length ? companies.map(p => p._id).join(',') : undefined,
          companiesNames: companies.length ? companies.map(p => p.name).join(',') : undefined,
          ...this.selectedOperator && { companiesOperator: this.selectedOperator },
        },
      }).catch(() => {});
    },
    onSelectOption(filter = {}) {
      this.selectedFilter = filter;
      if (this.$route.query.companies) {
        this.$router.push({
          query: {
            ...this.$route.query,
            companiesOperator: filter.key,
          }
        }).catch(() => {})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.filter-companies {
  &__content {
    min-width: 574px;;

    &:deep() {
      .bc-input {
        margin-top: 0;
      }
    }
  }
}
</style>
