<template>
  <td>
    <ratings
      :rating="rating"
      :isHiddenButton="true"
      :disabled="!canEdit"
      @rating-set="setRating"></ratings>
  </td>
</template>

<script>
import Ratings from '../../../components/Toolbox/Ratings';

export default {
  name: 'urgencycell',
  components: {
    Ratings,
  },
  props: {
    job: {
      type: Object,
    },
    columnType: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      rating: 0,
    };
  },
  methods: {
    setRating(value) {
      this.rating = value;

      const optionCell = {
        columnName: this.columnType.toLocaleLowerCase(),
        data: this.rating,
      };

      if (!this.job) {
        return this.$emit('update-cell-data', optionCell);
      }

      this.$emit('update-cell-data', { ...optionCell, jobId: this.job._id });
    },
  },
  created() {
    if (this.job) {
      this.rating = this.job.urgence;
    }
  },
  watch: {
    'job.urgence'() {
      this.rating = this.job.urgence;
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  max-width: 20vw;
  width: 10%;
  .ratings {
    display: flex;
    margin: 0;
    padding: 0 10px;
  }
}
</style>
