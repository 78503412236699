<template>
  <div class="panel-tag-view">
    <div class="panel-tag-view__column">
      <div class="panel-tag-view__card">
        <div class="panel-tag-view__card-label">
          {{ $t('generics.edit') }}
        </div>
        <div class="panel-tag-view__card-content">
          <bc-input
            label="Nom"
            @input="inputEvent"
            v-model="tagName"
          >
          </bc-input>
          <bc-dropdown
            :is-scrollable="false"
            :options="options"
            is-expanded>
            <template #label="{ isDropdownOpen }">
              <bc-dropdown-trigger
                :is-arrow="true"
                :is-dropdown-open="isDropdownOpen"
                :text="options.find(e => e.value === visible).name"
                label="Visible"
              >
              </bc-dropdown-trigger>
            </template>
            <template #option="{ activeIndex, index, option }">
              <bc-dropdown-item
                :is-focus="activeIndex === index"
                :text="option.name"
                :title="option.name"
                class="hunt__dropdown-item"
                @click.native="visible = option.value">
              </bc-dropdown-item>
            </template>
          </bc-dropdown>
          <bc-dropdown
            :is-scrollable="false"
            :options="options"
            is-expanded>
            <template #label="{ isDropdownOpen }">
              <bc-dropdown-trigger
                :is-arrow="true"
                :is-dropdown-open="isDropdownOpen"
                :text="options.find(e => e.value === verified).name"
                label="Vérifié"
              >
              </bc-dropdown-trigger>
            </template>
            <template #option="{ activeIndex, index, option }">
              <bc-dropdown-item
                :is-focus="activeIndex === index"
                :text="option.name"
                :title="option.name"
                class="hunt__dropdown-item"
                @click.native="verified = option.value">
              </bc-dropdown-item>
            </template>
          </bc-dropdown>
          <bc-dropdown
            :is-scrollable="false"
            :options="tagTypesOptions"
            :disabled="true"
            is-expanded>
            <template #label="{ isDropdownOpen }">
              <bc-dropdown-trigger
                :is-arrow="true"
                :is-dropdown-open="isDropdownOpen"
                :text="tagTypesOptions.find(e => e.value === tagTypeId).name"
                label="Type"
              >
              </bc-dropdown-trigger>
            </template>
            <template #option="{ activeIndex, index, option }">
              <bc-dropdown-item
                :is-focus="activeIndex === index"
                :text="option.name"
                :title="option.name"
                class="hunt__dropdown-item"
                @click.native="tagTypeId = option.value">
              </bc-dropdown-item>
            </template>
          </bc-dropdown>
          <bc-dropdown
            :is-scrollable="false"
            :options="organizationsWithTagsOptions"
            is-expanded>
            <template #label="{ isDropdownOpen }">
              <bc-dropdown-trigger
                :is-arrow="true"
                :is-dropdown-open="isDropdownOpen"
                :text="organizationsWithTagsOptions.find(e => e.value === organization).name"
                :label="$t('topbar.organizations-cap').replace('ns', 'n')"
              >
              </bc-dropdown-trigger>
            </template>
            <template #option="{ activeIndex, index, option }">
              <bc-dropdown-item
                :is-focus="activeIndex === index"
                :text="option.name"
                :title="option.name"
                class="hunt__dropdown-item"
                @click.native="organization = option.value">
              </bc-dropdown-item>
            </template>
          </bc-dropdown>
        </div>
        <div class="panel-tag-view__card-content is-align-items-end">
          <bc-button
            radius-size="s"
            size="extra-small"
            color="primary"
            :disabled="isButtonEditDisabled"
            @click="updateTag()">
            {{ $t('generics.edit') }}
          </bc-button>
        </div>
      </div>
      <div class="panel-tag-view__card">
        <div class="panel-tag-view__card-label">
          {{ $t("generics.details") }}
        </div>
        <div class="panel-tag-view__card-content">
          <div>{{ `Créée le ${ formattedDate("createdAt") }` }}</div>
          <div>{{ `Édité le ${ formattedDate("updatedAt") }` }}</div>
        </div>
      </div>
      <div class="panel-tag-view__card">
        <div class="panel-tag-view__card-label">
          {{ $t("generics.statistics") }}
        </div>
        <div class="panel-tag-view__card-content">
          <div>{{ `${ $t("generics.number-of-profiles") } : ${tag.count.coders}` }}</div>
          <div>{{ `${ $t("generics.number-of-jobs") } : ${tag.count.jobs}` }}</div>
        </div>
      </div>
    </div>
    <div class="panel-tag-view__column">
      <panel-tag-link
        :tagToLinks="tagToLinks"
        :tagTypeLink="tagTypeLink"
        :tagTypeSlug="tagTypeSlug"
        @update-tag-to-links="handleSelectTagToLink"
        @update-tag-type-link="setTagTypeLink"
        @create="createTagToLink"
        @clear="clear">
      </panel-tag-link>
      <div
        class="panel-tag-view__card"
        v-for="(item, index) in tagTypeLinkOptions"
        :key="index">
        <div class="panel-tag-view__card-label">
          {{ getLinkNameBySlug(item.slug) }}
        </div>
        <div class="panel-tag-view__card-content">
          <div class="panel-tag-view__card-content-list" v-if="getLinkOnTag(item.slug).length">
            <div
              v-for="(itemLink, indexLink) in getLinkOnTag(item.slug)"
              :key="indexLink">
              <div>{{ itemLink.name }}</div>
              <div>{{ `Profils : ${itemLink.count.coders}` }}</div>
              <div>{{ `Jobs : ${itemLink.count.jobs}` }}</div>
              <div>
                <bc-button
                  radius-size="s"
                  size="extra-small"
                  color="error"
                  type="flat"
                  @click="deleteTagLink(itemLink._idTagLink)">
                  {{ $t('generics.delete') }}
                </bc-button>
              </div>
            </div>
          </div>
          <div class="panel-tag-view__card-content-list" v-if="getLinkOnTag(item.slug).length === 0">
            {{ $t("generics.no-result") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment/min/moment-with-locales';
  import debounce from 'debounce';
  import PanelTagLink from '@/components/Panel/PanelTag/PanelTagLink';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import BcInput from '@/ui-kit/components/BcInput/BcInput.vue';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
  import { getTagTypeLink, createTagLink, deleteTagLink, editTag, searchTags } from '@/api/tags';
  import { mapState } from 'vuex';

  export default {
    name: 'panel-tag-view',
    components: {
      BcButton,
      PanelTagLink,
      BcInput,
      BcDropdown,
      BcDropdownItem,
      BcDropdownTrigger,
    },
    props: {
      tag: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        isLoaded: false,
        unique: true,
        tagName: this.tag.name,
        tagTypeId: this.tag._tagType || null,
        organization: this.tag._organization || null,
        tagToLinks: [],
        tagTypeLink: null,
        tagTypeLinkOptions: [],
        visible: this.tag.visible !== null ? this.tag.visible : true,
        verified: this.tag.verified !== null ? this.tag.verified : false,
        options: [
          {
            name: this.$t("generics.yes"),
            value: true,
          },
          {
            name: this.$t("generics.no"),
            value: false,
          },
        ],
      };
    },
    created() {
      this.getTagTypeLink();
    },
    methods: {
      async getTagTypeLink() {
        const { data } = await getTagTypeLink();

        this.tagTypeLinkOptions = data.map((typeLink) => ({
          name: typeLink.name,
          slug: typeLink.slug,
          value: typeLink._id,
        }));
      },
      formattedDate(field) {
        return moment(this.tag[field]).format('L');
      },
      clear() {
        this.tagToLinks = [];
        this.tagTypeLink = null;
      },
      async createTagToLink(tagToLinks, tagTypeLink) {
        if (tagToLinks.length > 0 && tagTypeLink !== null) {
          // Generate promises
          const promises = tagToLinks.map((item) => {
            const data = {
              origin: item._id,
              target: this.tag._id,
              typeLink: tagTypeLink.value,
            };

            return createTagLink(data)
          });

          // Execute promises to create tag link
          await Promise.all(promises);

          // Reset data
          this.tagToLinks = [];
          this.tagTypeLink = null;

          // Refresh tag
          this.refresh();

          // Display success message
          this.$toast.show({
            type: 'success',
            message: this.$t('tags.the-tag-has-been-linked-successfully'),
          })
        }
      },
      handleSelectTagToLink(skills) {
        this.tagToLinks.push(...skills);

        const uniqueArray = this.tagToLinks.filter((obj, index, self) =>
          index === self.findIndex((t) => t._id.toString() === obj._id.toString())
        );

        this.tagToLinks = uniqueArray;
      },
      clearTagToLinks() {
        this.tagToLinks = [];
      },
      async deleteTagLink(id) {
        // Delete tag link
        await deleteTagLink(id);

        // Refresh tag
        this.refresh();

        // Display success message
        this.$toast.show({
          type: 'success',
          message: this.$t('tags.the-tag-link-has-been-deleted-successfully'),
        })
      },
      setTagTypeLink(item) {
        this.tagTypeLink = item.value ? item.value : item;
      },
      getLinkNameBySlug(slug) {
        const typeLink = this.tagTypeLinkOptions.find((item) => item.slug === slug);
        return typeLink ? typeLink.name : '';
      },
      getLinkOnTag(slug) {
        const link = this.tag[slug];
        return link ? link : [];
      },
      refresh() {
        this.$emit('get-tag');
      },
      async updateTag() {
        const data = {
          id: this.tag._id,
          name: this.tagName,
          visible: this.visible,
          verified: this.verified,
          _organization: this.organization !== null ? this.organization : "null",
        };
        await editTag(data);

        // Refresh tag
        this.refresh();

        // Display success message
        this.$toast.show({
          type: 'success',
          message: this.$t('tags.the-tag-has-been-edited-successfully'),
        })
      },
      inputEvent: debounce(async function() {
        this.isLoaded = true;
        if (this.tagName.length > 2) {
          const { data } = await searchTags({
            name: this.tagName,
            limit: 5
          });

          const dataExceptMe = data
            .filter(s => s._id.toString() !== this.tag._id.toString() && s._tagType.toString() === this.tagTypeId.toString())
            .filter(e => e["count.coders"] > 0 || e["count.jobs"] > 0);
          const unique = dataExceptMe.length > 0 ? !dataExceptMe.every(s => s.name.toLowerCase() === this.tagName.trim().toLowerCase()) : true;
          this.setUnique(unique);
          this.isLoaded = false;
        }
      }),
      setUnique(unique) {
        if (unique === false && unique != this.unique) {
          this.$toast.show({
              type: 'error',
              message: this.$t('tags.create-tag-alert-unique'),
          });
        }

        this.unique = unique;
      },
    },
    computed: {
      ...mapState({
        tagTypes: state => state.tags.tagTypes,
        tagTypesOptions: state => state.tags.tagTypesOptions,
        organizationsWithTags: state => state.tags.organizationsWithTags,
        organizationsWithTagsOptions: state => state.tags.organizationsWithTagsOptions,
      }),
      getTagTypeLinkName() {
        const tagTypeLink = this.tagTypeLinkOptions.find((item) => item.value === this.tagTypeLink);
        return tagTypeLink ? tagTypeLink.name : '';
      },
      isButtonEditDisabled() {
        if (this.isLoaded) {
          return true;
        } else if (this.unique) {
          const visible = this.tag.visible != null ? this.tag.visible : true;
          const verified = this.tag.verified != null ? this.tag.verified : false;
          const orga = this.tag._organization != null ? this.tag._organization : null;
          return this.tagName === this.tag.name && this.visible === visible && this.verified === verified && this.organization === orga;
        } else {
          return true;
        }
      },
      tagTypeSlug() {
        const slug = this.tagTypes.find((item) => item._id === this.tagTypeId).slug;
        return slug;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .panel-tag-view {
    display: flex;
    width: 100%;
    height: 100%;

    &__column {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &__column:first-child {
      border-right: 1px solid lightgrey;
    }

    &__column:last-child {
      border-left: none;
    }

    &__card {
      width: 100%;
      border-bottom: 1px solid lightgrey;
      display: flex;
      flex-direction: column;
    }

    &__card-label {
      width: 100%;
      padding: 20px 20px 0 20px;
      color: $color-secondary-dark;
    }

    &__card-content {
      width: 100%;
      padding: 20px 40px;
      display: flex;
      flex-direction: column;

      > div.bc-input {
        padding: 15px 0px;
      }

      &-list {
        display: flex;
        flex-direction: column;
        padding: 0px 10px !important;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
      }

      &-list > div {
        margin: 5px 0px;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid lightgrey;
        border-radius: 5px;
      }

      &-list > div > div:first-child {
        width: 30%;
      }
    }
    .button-action-right {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      & > * {
        margin: 0px 5px;
      }
    }

    &__card-content > div {
      width: 100%;
      padding: 10px 0px;
    }

    &__card-column {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__card-column > div:first-child {
      flex: 0 0 20%;
    }

    &__card-column > div:last-child {
      flex: 1;
    }
  }
</style>
