<script setup lang="ts">
import { ref, watch } from 'vue';
import BcCompactToggle from '@/ui-kit/components/BcToggle/BcCompactToggle';

type ProfileType = 'candidate' | 'contact';

const props = defineProps<{
  value: ProfileType[];
}>();

const emit = defineEmits<{
  (e: 'input', value: ProfileType[]): void;
}>();

const isCandidate = ref(props.value.includes('candidate'));
const isContact = ref(props.value.includes('contact'));

watch(
  () => [isCandidate.value, isContact.value],
  ([newCandidate, newContact], [oldCandidate, oldContact]) => {
    if (!newCandidate && !newContact) {
      isCandidate.value = !oldCandidate;
      isContact.value = !oldContact;
      return;
    }

    const newProfileTypes = [
      ...(isCandidate.value ? ['candidate' as ProfileType] : []),
      ...(isContact.value ? ['contact' as ProfileType] : []),
    ];
    emit('input', newProfileTypes);
  }
);

watch(() => props.value, (newValue) => {
  isCandidate.value = newValue.includes('candidate');
  isContact.value = newValue.includes('contact');
});
</script>

<template>
  <div class="flex items-center gap-2.5 rounded-[0.3125rem] p-2.5">
    <BcCompactToggle
      id="candidate-type-toggle"
      ref="candidate-type-toggle"
      v-model="isCandidate"
      :label="$tc('generics.candidate-cap')"
      color="blue" />
    <BcCompactToggle
      id="contact-type-toggle"
      ref="contact-type-toggle"
      v-model="isContact"
      :label="$tc('generics.contact-cap')"
      color="purple" />
  </div>
</template>
