<template>
  <div class="searchbar-item">
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'searchbar-item',
}
</script>

<style lang="scss" scoped>
.searchbar-item {
  display: block;
  padding: 10px 20px;
  width: 100%;
}
</style>
