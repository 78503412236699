<template>
  <router-link
    v-slot="{ href }"
    :to="to"
    class="is-full-width"
  >
    <router-link :to="href">
      <topbar-dropdown class="topbar-item is-secondary is-full-width">
        <template #header>
          <topbar-trigger
            @mouseenter.native="isHover = true"
            @mouseleave.native="isHover = false"
            :is-active="isActive"
            :icon="icon"
            :icon-class="iconClass"
            :label="label"
          >
            <template
              #icon
              v-if="iconComponent"
            >
              <MvIcon
                :class="{'topbar-item__icon--active': isHover }"
                :icon="iconComponent"
                :size="18"
              />
            </template>
          </topbar-trigger>
        </template>
      </topbar-dropdown>
    </router-link>
  </router-link>
</template>

<script>
import TopbarDropdown from '@/components/Topbar/TopbarDropdown';
import TopbarTrigger from '@/components/Topbar/TopbarTrigger';

export default {
  name: 'topbar-item',
  components: {
    TopbarDropdown,
    TopbarTrigger,
  },
  props: {
    to: {
      type: [Object, String],
    },
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
    iconComponent: {
      type: String,
    },
    tabsPath: {
      type: Array,
      default: () => [],
    },
    iconClass: {
      type: String,
    },
  },
  computed: {
    isActive() {
      return this.tabsPath.includes(this.$route.path);
    }
  },
  data() {
    return {
      isHover: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.topbar-item {
  max-width: 100%;

  &__wrapper {
    justify-content: space-around;
    flex-grow: 1;
  }

  &__options {
    width: 100%;
    padding: 10px 0 0 55px;
    font-size: $font-size-s;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      margin: 0 auto;
      height: calc(100% - 20px);
      top: 15px;
      background: rgba($color-secondary, 0.4);
      left: 30px;
      z-index: -1;
    }
  }

  &__link {
    color: $color-secondary;
    padding: 5px;
    cursor: pointer;

    &--error {
      color: $color-error;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .router-link-active {
    color: $color-marvin;
    font-weight: $font-weight-medium;
  }

  .topbar-item__icon--active {
    transform: scale(1.2);
    transition: 0.15s;
  }
}
</style>
