import documents from '@/api/documents';

export const createCoderDocument = ({ id, file }) => {
  const serializeParams = ({ file }) => {
    const dataToSend = new FormData();

    dataToSend.append('file', file);

    return dataToSend;
  };

  const execute = ({ id, data }) => {
    try {
      return documents.createCoderDocument({
        id,
        data,
      });
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    const formattedData = serializeParams({
      file,
    });

    return execute({
      id,
      data: formattedData,
    });
  } catch (error) {
    throw error;
  }
};

export default {
  createCoderDocument,
};
