import { defineStore } from 'pinia';
import { UnipileAccount } from '@/domains/UnipileAccount';
import { ref, computed } from 'vue';
import { useUnipileAccountsApi } from '@/composables/api/useUnipileAccountsApi';
export const useUnipileAccountsStore = defineStore('unipileAccounts', () => {
  const accounts = ref<UnipileAccount[]>([]);
  const unipileAccountsApi = useUnipileAccountsApi();
  // Mutations
  function setAccounts(data: UnipileAccount[]) {
    accounts.value = data;
  }

  async function getAccounts({ syncWithUnipile }: { syncWithUnipile: boolean } = { syncWithUnipile: false }) {
    if (syncWithUnipile) {
      const data = await unipileAccountsApi.syncAndFetchAccounts();
      setAccounts(data);
      return data;
    } else {
      const data = await unipileAccountsApi.fetchAccounts();
      setAccounts(data);
      return data;
    }
  }

  async function removeAccount(id: string) {
    await unipileAccountsApi.deleteAccount(id);
    accounts.value = accounts.value.filter(account => account._id !== id);
  }

  // Getters
  function getAccountById(id: string) {
    return accounts.value.find(account => account._id === id);
  }

  const linkedInAccount = computed(() => {
    return accounts.value.find(account => account.type === 'LINKEDIN');
  });

  return {
    // State
    accounts,
    
    // Mutations
    getAccounts,
    removeAccount,

    loading: unipileAccountsApi.loading,
    requestError: unipileAccountsApi.requestError,
    
    // Getters
    getAccountById,
    linkedInAccount,
  };
}); 