import contracts from '@/api/contracts';
import { validateId } from '@/managers/coders/validators/validateId';
import { serializeContract } from '@/managers/contracts/formatters/contractsSerializers';

export const createCompanyContract = ({ id, type, file }) => {
  const validate = contract => {
    try {
      const mapValidator = {
        type: validateId,
      };

      const keysToValidate = Object.keys(contract);

      keysToValidate.forEach(key => mapValidator[key]);
    } catch (error) {
      throw error;
    }
  };

  const serializeParams = ({ type, file }) => {
    const dataToSend = new FormData();

    dataToSend.append('file', file);
    dataToSend.append('type', type);

    return dataToSend;
  };

  const execute = ({ id, data }) => {
    try {
      return contracts.createCompanyContract({
        id,
        data,
      });
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    validate({ type });

    const serializedContract = serializeContract({ id, type, file });
    const formattedData = serializeParams({
      file: serializedContract.file,
      type: serializedContract.type,
    });

    return execute({
      id: serializedContract.id,
      data: formattedData,
    });
  } catch (error) {
    throw error;
  }
};

export default {
  createCompanyContract,
};
