<template>
  <div class="panel-tag-graph">
    <div class="panel-tag-graph__content">
    </div>
  </div>
</template>

<script>
export default {
  name: 'panel-tag-graph',
  components: {
  },
  props: {
    profile: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
  },
  data() {
    return {
      features: {},
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.panel-tag-graph {
  flex-direction: column;

  &__title {
    font-weight: bold;
    margin: 10px 0px;
  }

  &__content {
    padding: 20px;
    flex-direction: column;
  }

  &__item {
    margin: 10px 0px;
    flex-direction: row;
    justify-content: space-between;

    &--container {
      flex-direction: column;
    }

    & > div {
      margin-right: 50px;
    }
  }

  .warning {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    margin-bottom: 15px;
    color: $color-blue-dark-cello;
  }

  .config-label {
    margin: 10px 0;
    gap: 10px;
    align-items: center;
  }
}
</style>
