<template>
  <mv-filter
    class="filter-customjobs-more"
    label="+ de critères"
    align="left"
    :count="totalCount"
  >
    <div class="filter-customjobs-more__container">
      <div class="filter-customjobs-more__divider">
        <FilterUniqueid @count="updateCount" />
      </div>
      <div class="filter-customjobs-more__divider">
        <FilterLocations :isSelect="false" />
      </div>
      <div class="filter-customjobs-more__divider">
        <FilterCustomJobsName :isSelect="false" @count="updateCount" />
      </div>
      <div class="filter-customjobs-more__divider">
        <filter-custom-jobs-custom-fields
          @count="updateCount"
          :custom-fields-filters="customFieldsFilters"
        ></filter-custom-jobs-custom-fields>
      </div>
    </div>
  </mv-filter>
</template>

<script>
import { mapGetters } from 'vuex';

import MvFilter from '@/ui-kit/components/MvFilter/MvFilter';
import FilterCustomJobsCustomFields from '@/components/Filter/CustomJobs/FilterCustomJobsCustomFields.vue';
import FilterLocations from '@/components/Filter/FilterLocations';
import FilterUniqueid from '@/components/Filter/FilterUniqueid.vue';
import FilterCustomJobsName from './FilterCustomJobsName.vue';

export default {
  name: 'filter-customjobs-more',
  components: {
    MvFilter,
    FilterLocations,
    FilterCustomJobsCustomFields,
    FilterUniqueid,
    FilterCustomJobsName,
  },
  data() {
    return {
      count: {},
    };
  },
  computed: {
    ...mapGetters({
      configurationCustomJob: 'user/configurationCustomJob',
    }),
    totalCount() {
      return Object.keys(this.count).reduce((acc, key) => {
        return acc + this.count[key];
      }, 0);
    },
    salaryType() {
      return this.$route.query.salaryType;
    },
    customFieldsFilters() {
      return this.configurationCustomJob.customFields?.filter(
        customField => customField.showFilter,
      );
    },
  },
  methods: {
    updateCount(count) {
      this.count = {
        ...this.count,
        [count.key]: count.count,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-customjobs-more {
  &__container {
    flex-direction: column;
    min-width: 581px;
  }

  &__divider {
    border-bottom: 1px solid $color-blue-light;
    width: 100%;
  }
}
</style>
