<script setup>
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
  import { useStore } from '@/store.js';
  import GridSortSelect from '@/components/Grid/GridSortSelect.vue';

  // Props
  defineProps({
    isChecked: {
      type: Boolean,
      default: false,
    },
    showArrivalDate: {
      type: Boolean,
      default: false,
    },
  });

  // Store
  const store = useStore();

  // Methods
  const handleSort = async(field, sortOption) => {
    await store.dispatch('setSort', {
      field,
      direction: sortOption.value,
    });
  };
</script>

<template>
  <div class="incoming-actions-grid is-full-width">
    <div class="incoming-actions-grid__container is-full-width">
      <BcCheckbox
        id="checkbox"
        :value="isChecked"
        class="incoming-actions-grid__text incoming-actions-grid__checkbox is-uppercase font-size-s !ml-0"
        @input="$emit('checked', $event)"
      />
      <grid-sort-select
        :label="`Id, ${$t('avatar-dropdown.job-title')}`"
        class="incoming-actions-grid__text incoming-actions-grid__id is-uppercase font-size-s"
        @sort="handleSort('firstName', $event)"
      />
      <p
        class="incoming-actions-grid__text incoming-actions-grid__name is-uppercase font-size-s">
        {{ $t('campaigns.name') }}
      </p>
      <p class="incoming-actions-grid__text incoming-actions-grid__cv is-uppercase font-size-s">
        {{ $t('companies.account-management.in-cv-upper') }}
      </p>
      <p class="incoming-actions-grid__text incoming-actions-grid__status is-uppercase font-size-s">
        {{ $t('generics.status') }}
      </p>
      <p class="incoming-actions-grid__text incoming-actions-grid__sources is-uppercase font-size-s">
        sources
      </p>
      <p class="incoming-actions-grid__text incoming-actions-grid__actions is-uppercase font-size-s">
        actions
      </p>
      <p class="incoming-actions-grid__text incoming-actions-grid__actions is-uppercase font-size-s">
        {{ $t('generics.scheduled') }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .incoming-actions-grid {
    padding: 25px 30px 10px 30px;

    &__checkbox {
      background: $color-white;
    }

    &__text {
      color: $color-blue-heavy-dark;
      margin: 0 10px;
    }

    &__name {
      @include incoming-actions-grid-name;
    }

    &__id {
      @include incoming-actions-grid-id;
    }

    &__status {
      @include incoming-actions-grid-status;
    }

    &__cv {
      @include incoming-actions-grid-cv;
    }

    &__sources {
      @include incoming-actions-grid-sources;
    }

    &__actions {
      @include incoming-actions-grid-actions;
    }
  }
</style>
