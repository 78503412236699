import { getProcesses, getProcessesByColumn } from '@/managers/processes/useCases/getProcesses';
import {
  failProcess,
  moveProcess,
  unfailProcess,
} from '@/managers/processes/useCases/moveProcesses';
import { edit } from '@/managers/processes/useCases/updateFormDeal';
import { updateProcessPayment } from '@/managers/processes/useCases/updateProcessPayment';

// validators
import {
  validateDateToSend,
  validateDealSources,
  validateMaxDealtAt,
  validatePassType,
} from '@/managers/processes/validators/validateFormDeal';
// formatters
import { formatFormDeal } from '@/managers/processes/formatters/formDealSerializers';


const get = ({ resource, id, steps, from, to, coachIds, teamsIds, jobIds }) => {
  if (steps) {
    const options = { resource, id, steps, from, to, coachIds, teamsIds, jobIds };

    return getProcessesByColumn(options);
  } else {
    const options = { from, to, coachIds, teamsIds };

    return getProcesses(options);
  }
};

const move = (id, step, params = {}) => {
  const specialMoveType = {
    failed: failProcess,
    unfailed: unfailProcess,
  };

  if (specialMoveType[step]) {
    return specialMoveType[step](id);
  }

  return moveProcess(id, step, params);
};

export const updateFormDeal = (id, options = {}) => {
  try {
    const fieldToValidate = {
      'dealtAt': {
        value: options.dealtAt,
        validator: validateMaxDealtAt,
      },
      'dateToSend': {
        value: (options.billing || {}).dateToSend,
        validator: validateDateToSend,
      },
      'dealSources': {
        value: (options.billing || {}).dealSources,
        validator: validateDealSources,
      },
      'coderPassType': {
        value: (options.coderPass || {}).type,
        validator: validatePassType,
      },
      'jobPassType': {
        value: (options.jobPass || {}).type,
        validator: validatePassType,
      },

    };
    const validateFormDeal = () => {
      for (const field in fieldToValidate) {
        const fieldValue = fieldToValidate[field].value;
        const fieldValidator = fieldToValidate[field].validator;

        if (fieldValue) {
          fieldValidator(fieldValue);
        }
      }
    };

    validateFormDeal();

    return edit(id, formatFormDeal(options));
  } catch (error) {
    if ((error || {}).response) {
      throw new Error(`API: ${error.response.data.message}`);
    }

    throw new Error(error);
  }
};

export const updatePayment = (id) => {
  try {
    return updateProcessPayment(id);
  } catch (error) {
    if ((error || {}).response) {
      throw new Error(`API: ${error.response.data.message}`);
    }

    throw new Error(error);
  }
};

export const processController = {
  get,
  move,
};

export default {
  processController,
  updateFormDeal,
  updatePayment,
};
