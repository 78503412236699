<script lang="ts" setup>
import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav.vue';
import { usePanelStore } from '@/store/pinia/panel';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import AlgoliaAdvancedSearchProfessions from '@/components/Algolia/AlgoliaAdvancedSearch/AlgoliaAdvancedSearchProfessions.vue';
import AlgoliaAdvancedSearchSkills from '@/components/Algolia/AlgoliaAdvancedSearch/AlgoliaAdvancedSearchSkills.vue';
import AlgoliaAdvancedSearchJob from '@/components/Algolia/AlgoliaAdvancedSearch/AlgoliaAdvancedSearchJob.vue';
import AlgoliaAdvancedSearchLocations from '@/components/Algolia/AlgoliaAdvancedSearch/AlgoliaAdvancedSearchLocations.vue';
import FilterCodersContractType from '@/components/Filter/Coders/FilterCodersContractType.vue';
import FilterCodersContact from '@/components/Filter/Coders/FilterCodersContact.vue';
import FilterCodersCareer from '@/components/Filter/Coders/FilterCodersCareer.vue';
import FilterCodersLastActivity from '@/components/Filter/Coders/FilterCodersLastActivity.vue';
import FilterCodersMinEvaluation from '@/components/Filter/Coders/FilterCodersMinEvaluation.vue';
import FilterCodersSalary from '@/components/Filter/Coders/FilterCodersSalary.vue';
import FilterCodersCustomFields from '@/components/Filter/Coders/FilterCodersCustomFields.vue';
import FilterCodersRemote from '@/components/Filter/Coders/FilterCodersRemote.vue';
import FilterCodersUniqueid from '@/components/Filter/Coders/FilterCodersUniqueid.vue';
import FilterCodersCompany from '@/components/Filter/Coders/FilterCodersCompany.vue';
import BcButton from '@/ui-kit/components/BcButton';

const panelStore = usePanelStore();
const route = useRoute();
const router = useRouter();

const props = defineProps<{
  onSearch: () => void;
}>();

function handleClose() {
  panelStore.closeModal();
}

const query = ref<Record<string, any>>({});

function updateFilters(filters: Record<string, any>) {
  query.value = {
    ...query.value,
    ...filters,
  };
}

function handleSearch() {
  router
    .push({
      ...route,
      query: {
        ...route.query,
        ...query.value,
        page: 1,
        // remove page and q from query (algolia results)
        p: undefined,
        q: undefined,
      },
    })
    .then(() => {
      if (props.onSearch) {
        props.onSearch();
      }
      panelStore.closeModal();
    });
}

function handleCancel() {
  panelStore.closeModal();
}
</script>

<template>
  <div class="flex w-[640px] max-w-[640px] flex-col">
    <div
      class="flex min-h-[50px] w-full shrink-0 items-center justify-center gap-6 border-b border-b-[#EDF0FF] bg-neutral-200 px-[18px] py-2">
      <PanelHeaderNav
        :on-back="handleClose"
        :on-close="handleClose"
        class="absolute left-5 flex shrink-0" />
      <div class="flex shrink-0 items-center gap-6">
        <h2 class="shrink-0 text-2xl font-bold first-letter:capitalize">
          {{ $tc('generics.advanced-search', 2) }}
        </h2>
      </div>
    </div>
    <div class="flex flex-col overflow-auto grow">
      <AlgoliaAdvancedSearchProfessions
        :emit-only="true"
        @update:filters="updateFilters" />
      <hr class="border-blue-100" />
      <AlgoliaAdvancedSearchSkills
        :emit-only="true"
        @update:filters="updateFilters" />
      <hr class="border-blue-100" />
      <AlgoliaAdvancedSearchJob
        :emit-only="true"
        @update:filters="updateFilters" />
      <hr class="border-blue-100" />
      <AlgoliaAdvancedSearchLocations
        :emit-only="true"
        @update:filters="updateFilters" />
      <hr class="border-blue-100" />
      <FilterCodersContractType
        :emit-only="true"
        @update:filters="updateFilters" />
      <hr class="border-blue-100" />
      <FilterCodersCompany
        :label="$t('generics.associated-company')"
        :with-select="false"
        :with-tags="true"
        :emit-only="true"
        @update:filters="updateFilters" />
      <hr class="border-blue-100" />
      <FilterCodersSalary
        :emit-only="true"
        @update:filters="updateFilters" />
      <hr class="border-blue-100" />
      <FilterCodersCustomFields
        :emit-only="true"
        @update:filters="updateFilters" />
      <hr class="border-blue-100" />
      <FilterCodersRemote
        :emit-only="true"
        @update:filters="updateFilters" />
      <hr class="border-blue-100" />
      <FilterCodersContact
        :emit-only="true"
        @update:filters="updateFilters" />
      <hr class="border-blue-100" />
      <FilterCodersCareer
        :emit-only="true"
        @update:filters="updateFilters" />
      <hr class="border-blue-100" />
      <FilterCodersLastActivity
        class="max-w-[630px]"
        :emit-only="true"
        @update:filters="updateFilters" />
      <hr class="border-blue-100" />
      <FilterCodersMinEvaluation
        class="max-w-[630px]"
        :emit-only="true"
        @update:filters="updateFilters" />
      <hr class="border-blue-100" />
      <FilterCodersUniqueid
        :emit-only="true"
        @update:filters="updateFilters" />
    </div>
    <div class="flex items-center gap-3 mt-auto py-[15px] justify-center w-full">
      <BcButton size="small" type="outlined" @click="handleCancel">
        {{ $t('generics.cancel') }}
      </BcButton>
      <BcButton size="small" @click="handleSearch">
        {{ $t('generics.launch-search') }}
      </BcButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.filter-coders-more {
  &__container {
    flex-direction: column;
    min-width: 581px;
  }

  &__divider {
    border-bottom: 1px solid $color-blue-light;
  }
}
</style>
