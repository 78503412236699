<script lang="ts" setup>
  import PanelProcessKanbanCompanyHeader
    from '@/components/Panel/PanelProcessKanban/PanelProcessKanbanCompanyHeader.vue';
  import PanelProcessCustom from '@/components/Panel/PanelProcessCustom.vue';
  import { useQuery } from '@tanstack/vue-query';
  import { useRoute } from 'vue-router/composables';
  import { getCustomJobsById } from '@/api/custom-jobs';
  import PanelProcessKanbanOpportunityHeader
    from '@/components/Panel/PanelProcessKanban/PanelProcessKanbanOpportunityHeader.vue';

  const route = useRoute();

  const { data: opportunity } = useQuery({
    queryKey: ['GET_OPPORTUNITY_PROFILE', route.params.id],
    queryFn: async() => {
      const { data } = await getCustomJobsById(route.params.id);

      return data;
    },
  });
</script>

<template>
  <div class="flex grow flex-col">
    <PanelProcessKanbanOpportunityHeader :opportunity="opportunity"/>
    <PanelProcessCustom
      :profile="opportunity"
      class="flex max-h-full grow"
      type="hunt"
    />
  </div>
</template>
