<template>
  <div class="panel-favorite-list-create-coach">
    <panel-header>
      <panel-favorite-list-header
        :current-coach="currentCoach"
        :list="list">
      </panel-favorite-list-header>
    </panel-header>
    <ValidationObserver
      @submit.prevent
      class="panel-favorite-list-create-coach__wrapper"
      ref="observer"
      tag="form"
      v-slot="{ valid }">
      <div class="panel-favorite-list-create-coach__body">
        <panel-favorite-list-name v-model="list.name"></panel-favorite-list-name>

        <panel-favorite-list-type
          :list-placeholder="typePlaceholder"
          @select="updateListType">
        </panel-favorite-list-type>

        <panel-favorite-list-share
          :current-coach="currentCoach"
          :current-owner="currentOwner"
          :edited-list-subscribers="list.subscribers"
          @add-subscriber="pushSubscriber"
          @remove-subscriber="removeSubscriber"
          @set-to-admin="updateSubscriberRole($event, 'admin')"
          @set-to-owner="updateSubscriberRole($event, 'owner')"
          @set-to-reader="updateSubscriberRole($event, 'reader')">
        </panel-favorite-list-share>
      </div>
      <panel-favorite-list-footer
        :edited-list="editedList"
        :is-valid-form="valid">
      </panel-favorite-list-footer>
    </ValidationObserver>
    <modal
      @close-modal="closeModal"
      v-if="isModalDisplayed">
      <panel-favorite-list-modal-new-owner
        @close-modal="closeModal"
        @on-validate="setCurrentOwnerToAdmin">
      </panel-favorite-list-modal-new-owner>
    </modal>
  </div>
</template>

<script>
  import { ValidationObserver } from 'vee-validate';
  //
  import Modal from '@/components/Modal/Modal';
  import PanelFavoriteListType from "@/components/Panel/PanelFavoriteList/PanelFavoriteListType";
  import PanelFavoriteListHeader from '@/components/Panel/PanelFavoriteList/PanelFavoriteListHeader';
  import PanelFavoriteListName from '@/components/Panel/PanelFavoriteList/PanelFavoriteListName';
  import PanelFavoriteListFooter from '@/components/Panel/PanelFavoriteList/PanelFavoriteListFooter';
  import PanelHeader from '@/components/Panel/PanelHeader';
  import PanelFavoriteListShare from '@/components/Panel/PanelFavoriteList/PanelFavoriteListShare';
  import PanelFavoriteListModalNewOwner
    from '@/components/Panel/PanelFavoriteList/PanelFavoriteListModal/PanelFavoriteListModalNewOwner';
  // macros
  import favoriteListsMacros from '@/macros/favoriteLists/favoriteLists';

  export default {
    name: 'panel-favorite-list-create-coach',
    components: {
      PanelFavoriteListType,
      PanelFavoriteListModalNewOwner,
      Modal,
      PanelFavoriteListShare,
      PanelHeader,
      PanelFavoriteListFooter,
      PanelFavoriteListName,
      PanelFavoriteListHeader,
      ValidationObserver
    },
    created() {
      this.setupList();
      this.setListType(this.mapRouteQuery[this.$route.query.type]);
    },
    data() {
      return {
        list: {
          name: '',
          type: favoriteListsMacros.types.coder,
          subscribers: []
        },
        newOwner: {},
        mapRouteQuery: {
          'favorite-coder-list': 1,
          'favorite-job-list': 2,
          'favorite-company-list': 3,
          'favorite-contact-list': 4
        },
        isModalDisplayed: false,
      };
    },
    computed: {
      currentOwner() {
        return this.list.subscribers.find(subscriber => subscriber.role === favoriteListsMacros.apiRights.owner) || {};
      },
      currentCoach() {
        return this.list.subscribers.find(subscriber => subscriber._id === this.userId) || {};
      },
      userId() {
        return this.$store.state.user.profile._id;
      },
      formattedSubscribers() {
        return this.list.subscribers.map(subscriber => ({
          _coach: subscriber._id,
          role: subscriber.role
        }));
      },
      editedList() {
        return {
          ...this.list.name && { name: this.list.name },
          coachId: this.list.coachId,
          ...this.formattedSubscribers.length > 0 && { subscribers: this.formattedSubscribers },
          type: this.list.type,
        };
      },
      typePlaceholder() {
        return favoriteListsMacros.listTypeOptions.find(option => option.type === this.list.type).name;
      },
    },
    methods: {
      async setupList() {
        const newOwner = {
          ...this.$store.state.user.profile,
          role: favoriteListsMacros.apiRights.owner
        };

        this.list.coachId = this.userId;
        this.list.subscribers.push(newOwner);
      },
      pushSubscriber($event) {
        $event.isAdded = true;
        this.list.subscribers.push($event);
      },
      updateSubscriberRole($event, role) {
        const subIndex = this.list.subscribers.findIndex(sub => sub._id === $event._id);

        if (role === 'owner') {
          this.newOwner = $event;
          this.openNewOwnerModal();
        } else {
          this.list.subscribers[subIndex].role = favoriteListsMacros.apiRights[role];
        }
      },
      openNewOwnerModal() {
        this.isModalDisplayed = true;
      },
      setCurrentOwnerToAdmin() {
        const currentOwnerIndex = this.list.subscribers.findIndex(subscriber => subscriber.role === favoriteListsMacros.apiRights.owner);
        const newOwnerIndex = this.list.subscribers.findIndex(subscriber => subscriber._id === this.newOwner._id);

        this.list.subscribers[currentOwnerIndex].role = favoriteListsMacros.apiRights.admin;
        this.list.subscribers[newOwnerIndex].role = favoriteListsMacros.apiRights.owner;

        this.newOwner = {};

        this.isModalDisplayed = false;
      },
      removeSubscriber($event) {
        const subIndex = this.list.subscribers.findIndex(subscriber => subscriber._id === $event._id);

        this.list.subscribers.splice(subIndex, 1);
      },
      closeModal() {
        this.isModalDisplayed = false;
      },
      setListType() {
        this.list.type = this.mapRouteQuery[this.$route.query.type];
      },
      updateListType(list) {
        this.list.type = list.type;
      },
    },
    beforeDestroy() {
      delete this.$route.query['list-id'];
    }
  };
</script>

<style lang=scss scoped>
  .panel-favorite-list-create-coach {
    position: relative;
    flex-direction: column;
    height: 100%;
    background: white;

    &__container {
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
    }

    &__wrapper {
      height: calc(100% - 56px);
      flex-direction: column;
      flex-grow: 1;
    }

    &__body {
      padding: 20px;
      flex-direction: column;
      flex-grow: 1;
      overflow: auto;
      @include bp('tablet') {
        padding: 0 50px;
      }
    }

  }
</style>
