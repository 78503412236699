<template>
  <!-- Coach avatars -->
  <div class="owners-list__owners-container">
    <v-popover
      v-for="owner in owners.slice(0, 3)"
      :key="owner._id"
      :placement="'bottom'"
      :trigger="'hover click'">
      <bc-avatar
        :src="owner.pictureUrl"
        class="owners-list__owners overlap"
        @error="setCoachAlternativeImg"
        size="s" />
      <template slot="popover">
        <div class="flex m-2.5 gap-[5px]">
          <bc-avatar
            :src="owner.pictureUrl"
            class="h-5 w-5 bg-white"
            @error="setCoachAlternativeImg"
            size="s" />
          <p>{{ owner.firstName }} {{ owner.lastName }}</p>
        </div>
      </template>
    </v-popover>
    <v-popover
      v-if="owners.length > 3"
      :placement="'bottom'"
      :trigger="'hover click'"
      class="owners-list__owners--list-counter overlap">
      +{{ owners.slice(3).length }}
      <template slot="popover">
        <div class="m-2.5 flex flex-col">
          <div v-for="owner in owners.slice(3)" :key="owner._id" class="flex gap-[5px]">
            <bc-avatar
              :src="owner.pictureUrl"
              class="h-5 w-5 bg-white"
              @error="setCoachAlternativeImg"
              size="s" />
            <p>{{ owner.firstName }} {{ owner.lastName }}</p>
          </div>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script setup lang="ts">
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';

  defineProps<{
    owners: {
      _id: string;
      firstName: string;
      lastName: string;
      pictureUrl: string;
    }[];
  }>();

  function setCoachAlternativeImg(event) {
    event.target.src = require('@/assets/img/default-avatar.svg');
  }
</script>

<style lang="scss" scoped>
  .owners-list__owners {
    background: white;

    &-container {
      width: 100px;
    }

    &--list-counter {
      width: 30px;
      height: 30px;
      background: $color-tertiary;
      color: white;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      position: relative;
    }
  }

  .overlap {
    margin-left: -6px;
  }
</style>
