<template>
  <mv-button-rounded
    class="button-find-candidates"
    :title="$t('generics.see-candidates')"
    icon="stroke"
    v-on="$listeners"
  >
    <div
      class="button-find-candidates__content--btn"
      :class="{ 'button-find-candidates__separator': index < options.length - 1 }"
      v-for="(option, index) in options"
      :key="option.key"
      @click="option.action">
      <img v-if="option.iconType === 'file'" :src="option.icon" />
      <i v-else :class="`icon-${option.icon}`"></i>
      <span>{{ option.text }}</span>
    </div>
  </mv-button-rounded>
</template>

<script>
import PanelNavigationMixin from '@/common-old/mixins/Panel/panelNavigation';
import MvButtonRounded from '@/ui-kit/components/MvButtonRounded/MvButtonRounded';

export default {
  name: 'button-find-candidates',
  mixins: [PanelNavigationMixin],
  components: {
    MvButtonRounded,
  },
  props: {
    job: {
      type: Object,
    },
  },
  data() {
    return {
      isOpen: false,
      options: [
        {
          key: 'linkedin',
          icon: require('@/assets/logo/linkedin.png'),
          iconType: 'file',
          text: 'Sur Linkedin',
          action: this.matchingLinkLinkedin,
        },
        {
          key: 'marvin',
          icon: require('@/assets/logo/marvin.png'),
          iconType: 'file',
          text: 'Sur Marvin Recruiter',
          action: this.matchingLinkMarvin,
        },
        {
          key: 'publish',
          icon: 'share-3',
          text: this.$t('generics.publish-job'),
          action: this.publishLink,
        },
      ],
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    matchingLinkMarvin() {
      this.$router.push({
        name: 'Coders',
        query: {
          ...(this.job._professions.length && {
            professions: this.job._professions.map(p => p._id).join(','),
            professionsNames: this.job._professions.map(p => p.name).join(','),
            professionsOperator: 'or',
          }),
          ...(this.job._skills.length && {
            technos: this.job._skills.map(s => s._id).join(','),
            technosNames: this.job._skills.map(s => s.name).join(','),
            skillsOperator: 'or',
          }),
        },
      });
    },
    matchingLinkLinkedin() {
      let keywords = '';
      const urlSearchParams = new URLSearchParams();
      if (this.job.locations.length) {
        keywords += this.job.locations[0].addressComponents[0].shortName + ' ';
      }
      if (this.job._professions.length) {
        keywords += this.job._professions.map(profession => profession.name).join(' ') + ' ';
      }
      if (this.job._skills.length) {
        keywords += this.job._skills.map(skill => skill.name).join(' ') + ' ';
      }
      urlSearchParams.set('keywords', keywords.trim());
      window.open(
        `https://www.linkedin.com/search/results/people/?${urlSearchParams.toString()}`,
        '_blank'
      );
    },
    publishLink() {
      this.openPanel({
        id: this.job._id,
        type: 'hunt',
        category: 'distribution',
        'company-id': this.job._company._id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.button-find-candidates {
  min-width: 210px;

  &__content {
    &--btn {
      padding: 10px 15px;
      gap: 8px;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      & > img {
        width: auto;
        height: 14px;
        background-size: contain;
      }
    }
  }
}
</style>
