<template>
  <div class="card-coaches-informations flex-col items-center px-[1.875rem] max-[996px]:my-2">
    <bc-avatar
      v-if="displayedColumns.avatar"
      :placeholder="userInitials"
      :src="getPicture"
      alt="coach avatar"
      class="mr-4 min-[996px]:mr-0 shrink-0"
      size="s"
      @error="setAlternativeImg">
    </bc-avatar>
    <p v-if="displayedColumns.name" class="card-coaches-informations__name truncate card-coaches-informations__column">
      {{ cardContent.firstName }} {{ cardContent.lastName }}
      <span v-if="isCurrentUser" class="font-weight-light"> ({{ $t('generics.me') }}) </span>
    </p>
    <p v-if="displayedColumns.email" class="card-coaches-informations__email truncate card-coaches-informations__column">
      {{ cardContent.email }}
    </p>

    <div v-if="displayedColumns.logAs && showHiddenFeatures" class="card-coaches-informations__login card-coaches-informations__column" :class="{'invisible': isCurrentUser}">
      <CardCoachesLogAs
        :card-content="cardContent"
        :disabled="disabled" />
    </div>
    <div v-if="displayedColumns.toggle && showHiddenFeatures" class="card-coaches-informations__toggle card-coaches-informations__column" :class="{'invisible': isCurrentUser}">
      <CardCoachesToggleAccount
        :id="cardContent._id"
        :value="cardContent.enabled"
        @switch-card-toggle="switchToggle" />
    </div>
  </div>
</template>

<script setup lang="ts">
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
import CardCoachesLogAs from '@/components/Card/CardCoaches/CardCoachesLogAs.vue';
import CardCoachesToggleAccount from '@/components/Card/CardCoaches/CardCoachesToggleAccount.vue';
import { useStore } from '@/store';
import { computed, withDefaults, defineProps, ref } from 'vue';
import { apiRoles } from '@/macros/coaches/roles';

type DisplayedColumns = {
  avatar: boolean;
  name: boolean;
  email: boolean;
  logAs: boolean;
  toggle: boolean;
};

const props = withDefaults(
  defineProps<{
    cardContent: any;
    disabled?: boolean;
    displayedColumns: DisplayedColumns;
  }>(),
  {
    disabled: false,
  }
);

const isPictureError = ref(false);

const showHiddenFeatures = computed(() =>
  [apiRoles.product, apiRoles.businessManager].includes(profile.value.role)
);

const store = useStore();
const profile = computed(() => store.state.user.profile);
const isCurrentUser = computed(() => props.cardContent._id === profile.value._id);

const userInitials = computed(
  () => `${props.cardContent.firstName?.charAt(0)}${props.cardContent.lastName?.charAt(0)}`
);
const getPicture = computed(() => props.cardContent.pictureUrl);

function setAlternativeImg(event: Event) {
  const target = event.target as HTMLImageElement;
  target.src = require('@/assets/img/default-avatar.svg');
  isPictureError.value = true;
}
function switchToggle() {
  props.cardContent.enabled = !props.cardContent.enabled;
}
</script>

<style lang="scss" scoped>
.card-coaches-informations {
  min-height: 60px;
  gap: 0.3rem;
  box-sizing: content-box;

  @include bp('desktop') {
    flex-direction: row;
    gap: 0.625rem;
  }

  &__column {
    text-align: center;
    justify-content: left;
    @include bp('desktop') {
      text-align: initial;
    }
  }

  &__name {
    color: $color-blue-dark-cello;
    @include bp('desktop') {
      @include team-grid-name;
    }
  }

  &__email {
    color: $color-blue-dark-cello;
    @include bp('desktop') {
      @include team-grid-email;
    }
  }

  &__login {
    @include bp('desktop') {
      @include team-grid-login;
    }
  }

  &__toggle {
    @include bp('desktop') {
      @include team-grid-toggle;
    }
  }
}
</style>
