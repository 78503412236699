<script lang="ts" setup>
  import { Company } from '@/domains/models/Company';
  import { computed, ref, watch } from 'vue';
  import MvAccordion from '@/ui-kit/components/MvAccordion/MvAccordion.vue';
  import MvAccordionTab from '@/ui-kit/components/MvAccordionTab/MvAccordionTab.vue';
  import { useStore } from '@/store';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { useMutation, useQuery } from '@tanstack/vue-query';
  import { useI18n } from '@/i18n/i18n';
  import { useToast } from '@/ui-kit/components/BcToast';
import { usePanelNavigation } from '@/composables/usePanelNavigation';
import PanelCompanyDocuments from '@/components/Panel/PanelCompany/PanelCompanyDocuments.vue';
import PanelOpportunityTopEdit
from '@/components/Panel/PanelOpportunity/PanelOpportunityTop/PanelOpportunityTopEdit.vue';
import { createCustomJob, descriptionTypes } from '@/api/custom-jobs';
import PanelOpportunityDetailsEdit
from '@/components/Panel/PanelOpportunity/PanelOpportunityDetails/PanelOpportunityDetailsEdit.vue';
import postsController from '@/managers/posts/controller';
import PanelCommonDescriptionEdit
from '@/components/Panel/PanelCommon/PanelCommonDescription/PanelCommonDescriptionEdit.vue';
import MvSelect from '@/ui-kit/components/MvSelect/MvSelect.vue';
import companyApi from '@/api/company';


const route = useRoute();
const { closePanel } = usePanelNavigation();
  const i18n = useI18n();
  const toast = useToast();
  const store = useStore();
  const router = useRouter();

  const organization = store.state.user?.profile?._organization;
  const userProfile = store.state.user?.profile;
  const requiredFields = organization.configuration?.job?.requiredFields || {};
  const isProfessionRequired = organization.configuration?.extension?.requiredProfession || false;

  const configurationFramework = computed(() => {
    return store.getters['user/configurationFramework'];
  });

  const editedProfile = ref<Company>({});
  const setEditedProfile = (data) => {
    editedProfile.value = {
      ...editedProfile.value,
      ...data,
    };
  };

  const observer = ref(null);
  const activeTabs = ref<number[]>([]);

  useQuery({
    queryKey: ['GET_OPPORTUNITY_CREATE_COMPANY', route.query.companyId],
    queryFn: async() => {
      if (route.query.companyId) {
        const { data } = await companyApi.getPartnerProfile(route.query.companyId);

        editedProfile.value = {
          ...editedProfile.value,
          _company: data,
        };

        return data;
      }

      return {};
    },
    enabled: !!route.query.companyId,
  });

  const serializeOpportunity = (opportunity) => {
    const {
      title,
      _company: company,
      salaryType,
      contract,
      locations,
      professionIds,
      skillIds,
      customFields,
      contractDuration,
      budget,
      employeeId,
      description,
      profileDescription,
      customCompanyDescription,
    } = opportunity;

    return {
      ...title && { title },
      ...company?._id && { companyId: company._id },
      salaryType: salaryType || 1,
      employeeId: employeeId || undefined,
      customFields: customFields || {},
      description: description || '',
      descriptionType: descriptionType.value || '',
      profileDescription: profileDescription || '',
      customCompanyDescription: customCompanyDescription || '',
      budget: budget || {},
      contractDuration: contractDuration || 1,
      contract: contract || 1,
      professionIds: professionIds || [],
      skillIds: skillIds || [],
      locations: locations || [],
      active: false,
      adPublished: false,
      jobMultiposting: {},
      ...descriptionType.value === descriptionTypes.custom && { customCompanyDescription: customCompanyDescription || '' },
    };
  };

  const descriptionCount = computed(() => {
    return editedProfile.value.description ? 1 : 0;
  });
  const profileDescriptionCount = computed(() => {
    return editedProfile.value.profileDescription ? 1 : 0;
  });
  const companyDescriptionCount = computed(() => {
    if (editedProfile.value?.descriptionType === descriptionTypes.client) {
      return editedProfile.value?._company.description ? 1 : 0;
    } else if (editedProfile.value?.descriptionType === descriptionTypes.custom) {
      return editedProfile.value?.customCompanyDescription ? 1 : 0;
    }
    return editedProfile.value?._organization?.description ? 1 : 0;
  });

  const createOpportunityMutation = useMutation({
    mutationKey: ['CREATE_OPPORTUNITY', editedProfile.value],
    mutationFn: async() => {
      const formattedOpportunity = serializeOpportunity(editedProfile.value);

      if (isProfessionRequired && !formattedOpportunity.professionIds?.length) {
        throw Error(i18n.t('global.profession-required'));
      }
      if (requiredFields?.location?.required && !formattedOpportunity?.locations?.length) {
        throw Error(i18n.t('global.location-required'));
      }
      if (requiredFields?.skills?.required && !formattedOpportunity?.skillIds?.length) {
        throw Error(i18n.t('global.skills-required'));
      }

      const { data } = await createCustomJob(formattedOpportunity);

      if (documents.value?.length) {
        await Promise.all(documents.value.map(async(document) => {
          await postsController.createCustomJobPost({
            id: data._id,
            file: document.file,
          });
        }));
      }

      if (descriptionType.value === descriptionTypes.client) {
        await companyApi.updatePartner({
          id: editedProfile.value?._company?._id,
          obj: {
            description: editedProfile.value.customCompanyDescription,
          },
        });
      } else if (descriptionType.value === descriptionTypes.organization) {
        await store.dispatch('user/updateOrganization', {
          description: editedProfile.value.customCompanyDescription,
        });
      }

      return data;
    },
    onSuccess: async(data) => {
      toast.show({
        type: 'success',
        message: i18n.t('toast.opportunity-successfully-created'),
      });

      if (route.query.from === 'create-process') {
        sessionStorage.setItem(`selected-custom-job-${userProfile._id}`, JSON.stringify(data));
        router.go(-1);
      } else if (route.query.from === 'panel-company') {
        router.go(-1);
      } else {
        redirectToOpportunityPanel(data._id);
      }
    },
    onError: (e) => {
      toast.show({
        title: e.message === 'companyId is required' ? i18n.t('generics.info-is-missing') : '',
        type: 'error',
        message: e.message === 'companyId is required' ? i18n.t('custom-jobs.select-company') : e.message,
      });
    },
  });

  const uploadDocument = ref<HTMLInputElement | null>(null);
  const documents = ref<Document[]>([]);
  const documentsCount = computed(() => {
    return documents?.value?.length || 0;
  });
  const triggerUploadButton = () => {
    uploadDocument.value?.click();
  };
  const addContract = async(e) => {
    if (e.target.files[0]) {
      const url = URL.createObjectURL(e.target.files[0]);

      documents.value = [
        { url, file: e.target.files[0] },
        ...documents.value || [],
      ];
    }
  };

  const employees = ref([]);
  const redirectToOpportunityPanel = (id) => {
    router.push({
      name: route?.name.includes('Panel') ? route?.name : `${route?.name}Panel`,
      params: {
        id,
      },
      query: {
        ...route?.query,
        type: 'hunt',
      },
    });
  };

  const descriptionType = ref(descriptionTypes.organization);
  const handleSelectCompanyDescription = (value) => {
    descriptionType.value = value;
  };
  const companyDescriptionOptions = computed(() => {
    return [
      { label: i18n.t('generics.description-of', { name: organization?.name }), value: descriptionTypes.organization },
      { label: i18n.t('generics.description-of', { name: editedProfile.value?._company?.name }), value: descriptionTypes.client },
      { label: i18n.t('generics.custom-description'), value: descriptionTypes.custom },
    ];
  });
  const companyDescription = computed(() => {
    if (descriptionType.value === descriptionTypes.client) {
      return editedProfile.value?._company?.description || '';
    } else if (descriptionType.value === descriptionTypes.custom) {
      return editedProfile.value?.customCompanyDescription;
    }
    return organization?.description;
  });

  watch(() => editedProfile.value?._company?._id, async() => {
    // Only when RH mode is off (showCompanies) to avoid errors
    if (editedProfile.value?._company?._id && configurationFramework.value?.showCompanies) {
      const { data } = await companyApi.getPartnerProfile(editedProfile.value?._company?._id);

      editedProfile.value._company = {
        ...editedProfile.value._company,
        ...data,
      };
    }
  });
</script>

<template>
  <div class="flex max-h-full min-h-full flex-col overflow-auto bg-white pb-5">
    <div
      class="flex flex-col gap-5 px-5 pt-3.5"
    >
      <PanelOpportunityTopEdit
        :key="editedProfile._company?._id"
        :is-creating="true"
        :opportunity="editedProfile"
        :show-companies="configurationFramework.showCompanies"
        @create-opportunity="createOpportunityMutation.mutate"
        @edit-opportunity="closePanel"
        @update-opportunity="setEditedProfile"/>
    </div>
    <MvAccordion
      :active-index.sync="activeTabs"
      class="flex flex-col px-5"
      multiple
    >
      <MvAccordionTab>
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('generics.details') }}
            </b>
          </div>
        </template>
        <PanelOpportunityDetailsEdit
          :is-creating="true"
          :opportunity="editedProfile"
          @update-opportunity="setEditedProfile"
        />
      </MvAccordionTab>
      <MvAccordionTab
        :class="{ 'panel-company-profile__documents': documentsCount > 0 }">
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              Documents
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white"
            >
              {{ documentsCount }}
            </div>
            <div
              class="absolute right-0 flex gap-2.5 bg-white pl-2.5"
              @click.stop
            >
              <button
                class="flex size-[30px] items-center justify-center rounded-full border-2 border-blue-400 bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="triggerUploadButton"
              >
                <i class="icon-plus font-xs"/>
              </button>
              <input
                ref="uploadDocument"
                accept=".doc,.docx,application/pdf"
                class="hidden"
                name="document"
                type="file"
                @change="addContract"
              />
            </div>
          </div>
        </template>
        <PanelCompanyDocuments
          :documents="documents"
          @update-company="$emit('update-company', $event)"
        />
      </MvAccordionTab>
      <MvAccordionTab>
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('companies.panel-company.company-description') }}
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white"
            >
              {{ companyDescriptionCount }}
            </div>
          </div>
        </template>
        <div class="flex flex-col items-start">
          <MvSelect
            :is-absolute="true"
            :items="companyDescriptionOptions"
            :label="`${$t('generics.to-use')} :`"
            :value="descriptionType"
            class="mb-2 max-w-[300px]"
            @select="handleSelectCompanyDescription"
          >
          </MvSelect>
          <PanelCommonDescriptionEdit
            :key="descriptionType"
            :is-disabled="descriptionType !== descriptionTypes.custom"
            :is-creating="true"
            :text="companyDescription"
            :title="$t('companies.panel-company.company-description')"
            @close-edit="$emit('close-edit')"
            @update-text="setEditedProfile({ customCompanyDescription: $event })"
          />
        </div>
      </MvAccordionTab>
      <MvAccordionTab>
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('custom-jobs.description-job-offer') }}
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white"
            >
              {{ descriptionCount }}
            </div>
          </div>
        </template>
        <PanelCommonDescriptionEdit
          :is-creating="true"
          :text="editedProfile.description"
          :title="$t('custom-jobs.description-job-offer')"
          @close-edit="$emit('close-edit')"
          @update-text="setEditedProfile({ description: $event })"
        />
      </MvAccordionTab>
      <MvAccordionTab>
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $tc('generics.wanted-profile-cap') }}
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white"
            >
              {{ profileDescriptionCount }}
            </div>
          </div>
        </template>
        <PanelCommonDescriptionEdit
          :is-creating="true"
          :text="editedProfile.profileDescription"
          :title="$tc('generics.wanted-profile-cap', 1)"
          @close-edit="$emit('close-edit')"
          @update-text="setEditedProfile({ profileDescription: $event })"
        />
      </MvAccordionTab>
    </MvAccordion>
  </div>
</template>

<style lang="scss" scoped>
  .panel-company-profile__documents > .p-toggleable-content {
    min-height: 640px;
  }
</style>
