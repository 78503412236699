import timeline from '@/api/timeline';
import { serializeNote } from '@/managers/notes/formatters/noteSerializers';

export const createEchange = async({ userType, id, note, mentions, images }) => {
  const execute = ({ userType, id, note, mentions, images }) => {
    try {
      return timeline.createNote({
        userType,
        id,
        note: {
          type: note.type,
          ...note.hasOwnProperty('rating') && note.rating >= 0 && { rating: note.rating },
          ...note.hasOwnProperty('urgency') && note.urgency >= 0 && { urgency: note.urgency },
          ...note.hasOwnProperty('_tags') && { _tags: note._tags },
          ...note.hasOwnProperty('salary') && { salary: note.salary },
          ...note.hasOwnProperty('salaryWanted') && { salaryWanted: note.salaryWanted },
          ...note.hasOwnProperty('dateToContact') && { dateToContact: note.dateToContact },
          ...note.hasOwnProperty('reminderType') && { reminderType: note.reminderType },
          ...note.hasOwnProperty('openToWork') && { openToWork: note.openToWork },
          ...note.hasOwnProperty('pin') && { pin: note.pin },
          ...note.hasOwnProperty('content') && { content: note.content },
          mentions,
          images,
        },
      });
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    const serializedNote = serializeNote(note);

    const { data } = await execute({
      userType,
      id,
      note: serializedNote,
      mentions,
      images,
    });

    return data;
  } catch (error) {
    throw error;
  }
};
