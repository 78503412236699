<template>
  <div
    class="panel-job-edit col-xs-12"
    v-click-outside="cancelEdit">
    <div class="panel-job-edit__header">
      {{ panelTitle }}
    </div>
    <ValidationObserver
      @submit.prevent
      class="panel-job-edit__form"
      ref="observer"
      tag="form">
      <div
        class="panel-job-edit__loader"
        v-if="isLoading">
        <spinner></spinner>
      </div>
      <div
        class="panel-job-edit__body"
        v-else>
        <panel-job-edit-avatar
          :picture-url="(profile._company || {}).logoUrl"
          class="panel-job-edit__wrapper">
        </panel-job-edit-avatar>
        <panel-job-edit-caption
          :edited-profile="editedProfile"
          :profile="profile"
          :required-job="requiredFields.name"
          @update-value="setEditedProfile"
          class="panel-job-edit__wrapper">
        </panel-job-edit-caption>
        <panel-job-edit-contracts
          :edited-profile="editedProfile"
          :profile="profile"
          @update-value="setEditedProfile"
          class="panel-job-edit__wrapper">
        </panel-job-edit-contracts>
        <panel-job-edit-remote
          :edited-profile="editedProfile"
          :profile="profile"
          @update-value="setEditedProfile"
          class="panel-job-edit__wrapper">
        </panel-job-edit-remote>
        <panel-job-edit-infos
          :edited-profile="editedProfile"
          :profile="profile"
          @update-value="setEditedProfile"
          class="panel-job-edit__wrapper">
        </panel-job-edit-infos>
        <panel-job-edit-stack
          :edited-profile="editedProfile"
          :profile="profile"
          :required-stack="false"
          @update-value="setEditedProfile"
          class="panel-job-edit__wrapper">
        </panel-job-edit-stack>
        <panel-job-edit-description
          :edited-profile="editedProfile"
          :profile="profile"
          @update-value="setEditedProfile"
          class="panel-job-edit__wrapper">
        </panel-job-edit-description>
      </div>
      <div class="panel-job-edit__footer">
        <bc-button
          @click.native="cancelEdit"
          bc-background-color="red-amaranth"
          bc-padding="10px 25px"
          bc-radius="30px"
          bc-text-size="14px"
          class="panel-job-edit__button">
          {{ $t('generics.cancel') }}
        </bc-button>
        <bc-button
          :disabled="!isEditedProfile"
          @click.native="saveEdit"
          bc-background-color="blue-dodger"
          bc-padding="10px 25px"
          bc-radius="30px"
          bc-text-size="14px"
          class="panel-job-edit__button">
          {{ $t('generics.validate') }}
        </bc-button>
      </div>
    </ValidationObserver>
    <modal
      @close-modal="continueToEdit"
      v-if="isModalOpen">
      <modal-leave-edit-confirmation
        @continue-edit="continueToEdit"
        @leave-edit="confirmCancelEdit">
      </modal-leave-edit-confirmation>
    </modal>
  </div>
</template>

<script>
  import Spinner from '@/legacy/ui-kit/components/Spinner/BcSpinner';
  import jobs from '@/api/jobs';
  import PanelJobEditAvatar from '@/components/Panel/PanelJob/PanelJobEdit/PanelJobEditAvatar';
  import PanelJobEditCaption from '@/components/Panel/PanelJob/PanelJobEdit/PanelJobEditCaption';
  import PanelJobEditContracts from '@/components/Panel/PanelJob/PanelJobEdit/PanelJobEditContracts';
  import PanelJobEditRemote from '@/components/Panel/PanelJob/PanelJobEdit/PanelJobEditRemote';
  import PanelJobEditInfos from '@/components/Panel/PanelJob/PanelJobEdit/PanelJobEditInfos';
  import PanelJobEditStack from '@/components/Panel/PanelJob/PanelJobEdit/PanelJobEditStack';
  import PanelJobEditDescription from '@/components/Panel/PanelJob/PanelJobEdit/PanelJobEditDescription';
  import BcButton from '@/legacy/ui-kit/components/Button/BcButton';
  import { mapActions, mapGetters } from 'vuex';
  import { getContractNameFromApiValue } from '@/common-old/macros/contracts';
  import { ValidationObserver } from 'vee-validate';
  import Modal from '@/components/Modal/Modal';
  import ModalLeaveEditConfirmation from '@/components/Modal/ModalLeaveEditConfirmation/ModalLeaveEditConfirmation';
  import panelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import { getRemoteNameFromApiValue } from '@/common-old/macros/remote';

  export default {
    name: 'panel-job-edit',
    components: {
      ModalLeaveEditConfirmation,
      Modal,
      BcButton,
      PanelJobEditDescription,
      PanelJobEditStack,
      PanelJobEditInfos,
      PanelJobEditRemote,
      PanelJobEditContracts,
      PanelJobEditCaption,
      PanelJobEditAvatar,
      Spinner,
      ValidationObserver
    },
    mixins: [panelNavigation],
    data: () => ({
      profile: {},
      editedProfile: {},
      isLoading: true,
      isModalOpen: false
    }),
    created() {
      if (this.$route.query.type === 'edit-job') {
        this.getJobProfile();
      } else {
        this.isLoading = false;
      }
    },
    computed: {
      ...mapGetters(['cardActive']),
      metadata() {
        return this.$store.state.user.metadata;
      },
      panelTitle() {
        return this.$route.query.type.includes('create') ? this.$i18n.t('companies.account-management.add-creation') : this.$i18n.t('companies.account-management.add-edition');
      },
      isEditedProfile() {
        return Object.entries(this.editedProfile).length > 0;
      },
      requiredFields() {
        const isEditPanel = this.$route.query.type === 'edit-job';

        return {
          name: true,
          salary: isEditPanel,
          experience: isEditPanel,
          location: isEditPanel,
          stack: false,
          description: isEditPanel
        };
      }
    },
    methods: {
      ...mapActions([
        'updateMetadataByKey',
        'updateCard',
        'unshiftCard'
      ]),
      async getJobProfile() {
        try {
          const response = await jobs.getJobProfile(this.$route.params.id);

          this.profile = response.data;

          this.isLoading = false;
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });

          this.isLoading = false;
        }
      },
      setEditedProfile(profile) {
        this.editedProfile = { ...this.editedProfile, ...profile };
      },
      redirectToJobPanel(id) {
        this.$router.replace({
          params: {
            id
          },
          query: {
            ...this.$route.query,
            type: 'jobs',
            ...this.$route.query['company-id'] && { 'company-id': undefined },
            'unsaved-changes': undefined
          }
        });
      },
      async editJob(updatedObj) {
        try {
          const response = await jobs.updateJob({
            id: this.$route.params.id,
            obj: updatedObj
          });

          if (this.$route.name.includes('Jobs') && this.cardActive >= 0) {
            this.updateCard({ index: this.cardActive, content: response.data });
          }

          this.redirectToJobPanel(this.$route.params.id);
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      async createJob(updatedObj) {
        try {
          const response = await jobs.createJob({
            id: this.$route.query['company-id'] ? this.$route.query['company-id'] : this.$route.params.id,
            obj: updatedObj
          });

          if (this.$route.name.includes('Jobs')) {
            this.unshiftCard(response.data);
          }

          this.updateMetadataByKey({
            key: 'jobs',
            value: this.metadata.jobs + 1
          });

          this.redirectToJobPanel(response.data._id);
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      async saveEdit() {
        const validator = await this.$refs.observer.validate();

        if (validator) {
          const { name, contract, salary, remote, domains, experience, locations, role, description } = this.editedProfile;

          const getTechnosId = technosArray => technosArray.map(techno => techno._id).join(',') || [];

          const updatedObj = {
            ...locations && { locations },
            ...name !== undefined && { name },
            ...salary !== undefined && { salary },
            ...remote !== undefined && { remote: remote.map(value => getRemoteNameFromApiValue(value)) },
            ...domains !== undefined && { domains },
            ...experience !== undefined && { experience },
            ...role !== undefined && { role },
            ...description !== undefined && { description },
            ...this.$route.params !== undefined &&  {_hunt: this.$route.params.id},
            ...contract !== undefined && { contract: getContractNameFromApiValue(contract) },
            ...Array.isArray(this.editedProfile._technosPrimary) && { _technosPrimary: getTechnosId(this.editedProfile._technosPrimary) },
            ...Array.isArray(this.editedProfile._technosSecondary) && { _technosSecondary: getTechnosId(this.editedProfile._technosSecondary) }
          };

          if (this.$route.query.type.includes('edit')) {
            this.editJob(updatedObj);
          } else {
            this.createJob(updatedObj);
          }
        } else {
          this.$scrollTo('.invalid', 100, {
            container: '.panel-job-edit__body',
            offset: -100
          });
        }
      },
      cancelEdit(event = {}) {
        if (this.isEditedProfile) {
          this.clickOutsideTarget = (event.target || {}).className || '';
          this.isModalOpen = true;
        } else {
          this.$router.go(-1);
        }
      },
      confirmCancelEdit() {
        if (this.clickOutsideTarget === 'overlay') {
          this.closePanel();
        } else {
          this.$router.push({
            path: this.$route.path,
            params: this.$route.params,
            query: {
              type: 'hunt',
              category: 'annonces'
            }
          });
        }
      },
      continueToEdit() {
        this.clickOutsideTarget = '';
        this.isModalOpen = false;
      }
    },
    beforeDestroy() {
      if (this.$route.query['company-id']) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            'company-id': undefined
          }
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .panel-job-edit {
    height: 100%;
    padding-bottom: 61px;
    @include bp('tablet') {
      padding-bottom: 0;
    }

    &__header {
      min-height: 50px;
      width: 100%;
      justify-content: center;
      align-items: center;
      background: $color-white;
      font-size: $font-size-xl;
      text-transform: uppercase;
      color: $color-blue-dark-cello;
      border-bottom: 1px solid rgba($color-blue-dark-cello, 0.25);
    }

    &__form {
      flex-direction: column;
      align-items: center;
      background: $color-white;
      flex-grow: 1;
      max-height: calc(100% - 50px);
    }

    &__loader {
      background: rgba($color-blue-dark-cello, 0.1);
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      width: 100%;
    }

    &__body {
      display: block;
      overflow: auto;
      width: 100%;
      padding: 40px 20px;
      @include bp('tablet') {
        padding: 40px 50px;
      }
    }

    &__footer {
      position: fixed;
      bottom: 0;
      padding: 10px;
      border-top: 1px solid rgba($color-blue-dark-cello, 0.25);
      width: 100%;
      justify-content: center;
      @include bp('tablet') {
        position: initial;
      }
    }

    &__button {
      max-width: 150px;
      min-width: 100px;
      justify-content: center;

      &:first-of-type {
        margin-right: 20px;
      }

      @include bp('tablet') {
        min-width: 150px;
      }
    }

    &__wrapper {
      margin-bottom: $panel-container-margin-bottom;
    }
  }
</style>
