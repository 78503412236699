<template>
  <div class="container">
    <configuration-templates-tabs>
      <ConfigurationTemplatesHeader />
    </configuration-templates-tabs>
    <views-container>
      <div class="configuration-templates">
        <router-view></router-view>
        <div class="configuration-templates__titles">
          <p style="width: 40%;" class="pl-2">{{ $t('email.template-name') }}</p>
          <p style="width: 40%;">{{ $t('generics.object') }}</p>
          <p style="width: 10%;">{{ $t('generics.active') }}</p>
          <p style="width: 10%;">{{ $t('generics.author') }}</p>
        </div>
        <div v-if="isLoading" class="mt-5 justify-center">
          <bc-spinner></bc-spinner>
        </div>
        <div v-else class="configuration-templates__items">
          <configuration-templates-email-item
            v-for="template in templates" :key="template._id"
            :template="template"
            :disabled="template._coach !== profile._id && !template.default"
            @click="onClickTemplate"
            @toggle-activated="onToggleActivated"
          ></configuration-templates-email-item>
          <div v-if="!templates.length" class="configuration-templates__empty">
            <p>Empty</p>
          </div>
        </div>
      </div>
    </views-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
import ViewsContainer from '@/components/Views/ViewsContainer.vue';
import ConfigurationTemplatesTabs from '@/components/Configuration/Tabs/ConfigurationTemplatesTabs.vue';
import ConfigurationTemplatesEmailItem from '@/components/Configuration/ConfigurationTemplates/ConfigurationTemplatesEmail/ConfigurationTemplatesEmailItem.vue';
import ConfigurationTemplatesHeader from '@/components/Configuration/ConfigurationTemplates/ConfigurationTemplatesHeader.vue';

export default {
  name: 'configuration-templates',
  components: {
    BcSpinner,
    ViewsContainer,
    ConfigurationTemplatesTabs,
    ConfigurationTemplatesEmailItem,
    ConfigurationTemplatesHeader,
  },
  mounted() {
    this.getEmailTemplates({ coachId: this.profile._id });
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
    }),
    ...mapGetters({
      templates: 'emails/templatesList',
    }),
  },
  methods: {
    ...mapActions({
      getTemplates: 'emails/getTemplates',
      editTemplate: 'emails/editTemplate',
      setModal: 'app/setModal',
      setParams: 'app/setParams',
      setIsPanel: 'app/setIsPanel',
    }),
    onClickTemplate(template) {
      this.setParams({
        emailTemplate: template,
        isDefaultTemplate: template.default,
        disabled: template._coach !== this.profile._id,
      });
      this.setIsPanel(true);
      this.setModal('modal-email-template-form');
    },
    async getEmailTemplates({ coachId, all }) {
      this.isLoading = true;
      try {
        await this.getTemplates({ coachId, all });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err?.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async onToggleActivated(template) {
      try {
        await this.editTemplate({
          id: template._id,
          activated: !(template.activated === true),
        })
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err?.response?.data?.message || err.message,
        });
      }
    },
  },
  watch: {
    '$route.query': {
      handler(query, prevQuery) {
        if (query?.coachId !== prevQuery?.coachId || query?.all !== prevQuery?.all) {
          this.getEmailTemplates({ coachId: query.coachId, all: query.all });
        }
      },
      immediate: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  flex-direction: column;
}

.configuration-templates {
  width: 100%;
  flex-direction: column;
  gap: 10px;
  position: relative;

  &__titles {
    padding: 5px 30px;

    & > p {
      color: $color-blue-heavy-dark;
      font-size: 13px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  &__items {
    position: relative;
    flex-direction: column;
    gap: 5px;

    &-loader {
      justify-content: center;
      align-items: center;
      min-height: 100px;
    }
  }

  &__block-feature {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4fd 0%, #fafbff 100%);
    opacity: 0.5;
  }

  &__warning {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    margin-bottom: 15px;
    color: $color-blue-dark-cello;

    &-container {
      width: 100%;
      justify-content: flex-end;
      margin-bottom: 5px;
    }
  }

  &__empty {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    color: $color-tertiary;
  }
}
</style>

<style lang="scss">
  .tooltip-custom {
    height: 60vh;

    .tooltip-inner {
      max-width: 100%;
      height: 100%;
    }
  }
</style>
