import {
  createTemplate,
  deleteTemplate,
  editTemplate,
  getConfiguration,
  getTemplatePreview,
  getTemplates,
  getTemplatesVariables,
} from '@/api/emails';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    configuration: {},
    templates: [],
    templatesVariables: [],
    templatePreview: {},
    selectedCoders: [],
    selectedCompanies: [],
    selectedCustomJobs: [],
    emailSentCallback: null,
  },
  getters: {
    templatesList(state) {
      const templates = [...state.templates];
      templates.sort((a, b) => {
        if (a.default && a.activated) {
          return -1;
        }
        return b.activated - a.activated;
      });
      return templates;
    },
    selectedCoders(state) {
      return state.selectedCoders;
    },
    selectedCompanies(state) {
      return state.selectedCompanies;
    },
    selectedCustomJobs(state) {
      return state.selectedCustomJobs;
    },
    isLoading(state) {
      return state.isLoading;
    },
  },
  actions: {
    async getConfiguration({ commit }) {
      const { data } = await getConfiguration();
      commit('setConfiguration', data);
      return data;
    },
    async getTemplatesVariables({ commit }) {
      const { data } = await getTemplatesVariables();
      commit('setTemplatesVariables', data);
      return data;
    },
    async getTemplatePreview({ commit }, { id, coachId, coderId, customJobId, companyId }) {
      const { data } = await getTemplatePreview({
        id,
        coachId,
        coderId,
        customJobId,
        companyId,
      });
      commit('setTemplatePreview', data);
      return data;
    },
    async getTemplates({ commit }, { coachId, all } = {}) {
      const { data } = await getTemplates({ coachId, all });

      commit('setTemplates', data);
    },
    async createTemplate({ commit }, {
      name,
      senderName,
      subject,
      body,
      cc,
      custom,
      attachments,
    } = {}) {
      const { data } = await createTemplate({
        name,
        senderName,
        subject,
        body,
        cc,
        custom,
        attachments,
      });

      if (!data.custom) {
        commit('createTemplate', data);
      }
    },
    async editTemplate({ commit }, {
      id,
      name,
      senderName,
      subject,
      body,
      cc,
      custom,
      activated,
      attachments,
    } = {}) {
      const { data } = await editTemplate({
        id,
        name,
        senderName,
        subject,
        body,
        cc,
        custom,
        activated,
        attachments,
      });

      commit('editTemplate', data);
    },
    async deleteTemplate({ commit }, { id } = {}) {
      const { data } = await deleteTemplate({ id });
      if (data.deletedAt) {
        commit('deleteTemplate', { id });
      }
    },
    setSelectedCoders({ commit }, coders) {
      commit('setSelectedCoders', coders);
    },
    setSelectedCompanies({ commit }, companies) {
      commit('setSelectedCompanies', companies);
    },
    setSelectedCustomJobs({ commit }, customJobs) {
      commit('setSelectedCustomJobs', customJobs);
    },
    setSelectedProcesses({ commit }, processes) {
      commit('setSelectedProcesses', processes);
    },
    setIsLoading({ commit }, isLoading) {
      commit('setIsLoading', isLoading);
    },
    setEmailSentCallback({ commit }, callback) {
      commit('setEmailSentCallback', callback);
    },
  },
  mutations: {
    setConfiguration(state, configuration) {
      state.configuration = configuration;
    },
    setTemplatesVariables(state, templatesVariables) {
      state.templatesVariables = templatesVariables;
    },
    setTemplatePreview(state, templatePreview) {
      state.templatePreview = templatePreview;
    },
    setTemplates(state, templates) {
      state.templates = [...templates];
    },
    createTemplate(state, template) {
      state.templates = [...state.templates, template];
    },
    editTemplate(state, templateEdited) {
      const templates = [...state.templates];
      const findIndex = templates.findIndex(template => templateEdited._id === template._id);
      if (findIndex !== -1) {
        templates[findIndex] = {
          ...templates[findIndex],
          ...templateEdited,
        };
        state.templates = [...templates];
      }
    },
    deleteTemplate(state, { id }) {
      state.templates = state.templates.filter(template => template._id !== id);
    },
    setSelectedCoders(state, coders) {
      state.selectedCoders = coders;
    },
    setSelectedCompanies(state, companies) {
      state.selectedCompanies = [...companies];
    },
    setSelectedCustomJobs(state, customJobs) {
      state.selectedCustomJobs = customJobs;
    },
    setSelectedProcesses(state, processes) {
      state.selectedCoders = processes.map(process => process._coder);
      state.selectedCustomJobs = processes.map(process => process._customJob);
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setEmailSentCallback(state, callback) {
      state.emailSentCallback = callback;
    },
  },
};
