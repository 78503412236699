import { getOrganizationRessourceCount, getCoachRessourceCount } from "@/api/metadata";

export default {
  namespaced: true,
  state() {
    return {
      ressourceCount: {},
      coachRessourceCount: {},
    };
  },
  getters: {
    ressourceCount(state) {
      return state.ressourceCount || {};
    },
    coachRessourceCount(state) {
      return state.coachRessourceCount || {};
    },
  },
  actions: {
    async getOrganizationRessourceCount({ commit }, { organizationId, ...params }) {
      try {
        const { data } = await getOrganizationRessourceCount({ organizationId, ...params });
        commit('setOrganizationRessourceCount', data)
      } catch (err) {
        throw err;
      }
    },
    async getCoachRessourceCount({ commit }, { coachId }) {
      try {
        const { data } = await getCoachRessourceCount({ coachId });
        for (const ressourceType in data) {
          commit('setCoachRessourceCount', { ressourceType, count: data[ressourceType] });
        }
      } catch (err) {
        throw err;
      }
    },
    setCoachRessourceCount({ commit }, params) {
      commit('setCoachRessourceCount', params)
    }
  },
  mutations: {
    setOrganizationRessourceCount(state, ressourceCount) {
      state.ressourceCount = ressourceCount;
    },
    setCoachRessourceCount(state, { ressourceType, count }) {
      state.coachRessourceCount = {
        ...state.coachRessourceCount,
        [ressourceType]: count,
      }
    }
  }
}
