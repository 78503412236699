<template>
  <views-tabs :tabs="tabs">
    <template #content>
      <slot></slot>
    </template>
  </views-tabs>
</template>

<script>
import { mapState } from 'vuex';
import ViewsTabs from '@/components/Views/ViewsTabs';

export default {
  name: 'candidates-failed-tabs',
  components: {
    ViewsTabs,
  },
  computed: {
    ...mapState({
      queryCount: state => state.card.count,
    }),
    tabs() {
      return [
        {
          key: 'coders-failed',
          label: this.$i18n.t('hunt.candidates-in-failure'),
          to: { path: '/failure-coders', query: { page: '1' } },
          count: this.queryCount,
        },
      ];
    },
  },
}
</script>
