<template>
  <views class="database-logs-activities">
    <admin-tabs>
    </admin-tabs>
    <router-view></router-view>
    <views-container>
      <div class="database-logs-activities__filters">
        <div class="database-logs-activities__filters-label">Type</div>
        <bc-dropdown
          :is-scrollable="false"
          :options="typesOptions"
          class="custom-jobs-filters__dropdown"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="typesOptions.find(e => e.value === type).name"
              :is-filter="true"
            >
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              :title="option.name"
              class="hunt__dropdown-item"
              @click.native="setDropdown(`type`, option)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
        <div class="database-logs-activities__filters-label">Action</div>
        <bc-dropdown
          :is-scrollable="false"
          :options="actionsOptions"
          class="custom-jobs-filters__dropdown"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="actionsOptions.find(e => e.value === action).name"
              :is-filter="true"
            >
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              :title="option.name"
              class="hunt__dropdown-item"
              @click.native="setDropdown(`action`, option)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
        <div class="database-logs-activities__filters-label">{{ $t("generics.order") }}</div>
        <bc-dropdown
          :is-scrollable="false"
          :options="sortValueOptions"
          class="custom-jobs-filters__dropdown"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="sortValueOptions.find(e => e.value === sortValue).name"
              :icon="sortValueOptions.find(e => e.value === sortValue).icon"
            >
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              :title="option.name"
              class="hunt__dropdown-item"
              @click.native="setDropdown(`sortValue`, option)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
        <div class="database-logs-activities__filters-label">{{ $t("generics.display") }}</div>
        <bc-dropdown
          :is-scrollable="false"
          :options="limitOptions"
          class="custom-jobs-filters__dropdown"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="limitOptions.find(e => e.value === limit).name"
              icon="arrow-right"
            >
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              :title="option.name"
              class="hunt__dropdown-item"
              @click.native="setDropdown(`limit`, option)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
      </div>
      <div
        class="database-logs-activities__cards">
        <bc-spinner
          v-if="!isLoaded"
          class="database-logs-activities__spinner">
        </bc-spinner>
        <div
          v-if="isLoaded"
          class="is-column is-align-items-center"
        >
          <card-list
            ref="cardList"
            :cards="logsActivities"
            :is-loaded="true"
            class="pagination__card-list">
            <template #card="{ card }">
              <card
                :card-content="card"
                card-type="partners">
                <card-display-log-activities
                  :card-content="card"
                  :types="types"
                >
                </card-display-log-activities>
              </card>
            </template>
          </card-list>
          <bc-pagination
            :current.sync="currentPage"
            :total="countLogsActivities"
            :per-page="limit"
            class="database-logs-activities__pagination">
          </bc-pagination>
        </div>
      </div>
    </views-container>
  </views>
</template>

<script>
  import debounce from 'debounce';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import BcPagination from '@/ui-kit/components/BcPagination/BcPagination';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
  import Card from '@/components/Card/Card';
  import CardList from '@/components/CardList/CardList';
  import CardDisplayLogActivities from '@/components/Card/CardDisplayLogActivities';
  import { searchLogsActivities, countLogsActivities, getTypeLogsActivities } from '@/api/logs';
  import Views from '@/components/Views/Views';
  import ViewsContainer from '@/components/Views/ViewsContainer';
  import AdminTabs from '@/views/Tabs/AdminTabs';

  export default {
    name: 'logs-activities',
    components: {
      ViewsContainer,
      Views,
      BcPagination,
      BcDropdown,
      BcDropdownItem,
      BcDropdownTrigger,
      CardDisplayLogActivities,
      Card,
      CardList,
      BcSpinner,
      AdminTabs,
    },
    data() {
      return {
        isLoaded: false,
        currentPage: parseInt(this.$route.query.page) || 1,
        limit: 10,
        logsActivities: [],
        types: [],
        countLogsActivities: 0,
        searchbarInput: this.$route.query.name || '',
        sortKey: "_id",
        type: "",
        action: "",
        sortValue: "desc",
        sortValueOptions: [
          {
            name: this.$t("generics.increasing"),
            value: 'asc',
            icon: 'arrow-down',
          },
          {
            name: this.$t("generics.decreasing"),
            value: 'desc',
            icon: 'arrow-up',
          },
        ],
        limitOptions: [
          {
            name: "10",
            value: 10,
          },
          {
            name: "50",
            value: 50,
          },
        ],
        typesOptions: [
          {
            name: "Tous",
            value: ""
          }
        ],
        actionsOptions: [
          {
            name: "Tous",
            value: ""
          }
        ],
      };
    },
    created() {
      this.searchLogsActivities();
      this.getTypeLogsActivities();
    },
    watch: {
      currentPage(newPage) {
        this.$router.push({ ...this.$route, query: { ...this.$route.query, page: newPage } });

        this.getTypeLogsActivities();
        this.searchLogsActivities();
      },
    },
    methods: {
      setDropdown(field, name) {
        this[field] = name.value;
        this.$router.push({ ...this.$route, query: { ...this.$route.query, page: 1 } });
        this.currentPage = 1;

        this.searchLogsActivities();

        if (this.type == name.value) {
          this.action = "";
          this.setActionsOptions();
        }
      },
      capitalizeString(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      },
      setActionsOptions() {
        let children = []
        if (this.type) {
          children = this.types.find(e => e.type === this.type)?.children
        } else {
          this.types.forEach(element => {
            children.push(...element.children)
          });
        }

        this.actionsOptions = [{name: "Tous", value: ""}, ...children.map(e => ({name: this.capitalizeString(e.name), value: e.value}))]
      },
      resetLogsActivities() {
        this.logsActivities = [];
      },
      inputEvent: debounce(async function() {
        if (this.searchbarInput.length > 2) {
          this.currentPage = 1;
          this.$router.push({ ...this.$route, query: { ...this.$route.query, page: this.currentPage, name: this.searchbarInput } });
          this.searchLogsActivities();
        }
      }),
      async getTypeLogsActivities() {
        const { data } = await getTypeLogsActivities();
        this.types = data;
        this.typesOptions = [{name: "Tous", value: ""}, ...data.map(item => ({
          name: item.title,
          value: item.type
        }))];
        this.setActionsOptions();
      },
      async searchLogsActivities() {
        this.isLoaded = false;

        try {
          this.resetLogsActivities();

          const { data } = await searchLogsActivities({
            page: this.currentPage,
            type: this.type,
            action: this.action,
            limit: this.limit,
            sortKey: this.sortKey,
            sortValue: this.sortValue,
          });

          if (this.currentPage === 1) {
            const { data } = await countLogsActivities({
            });

            this.countLogsActivities = data;
          }

          this.logsActivities = data;

          this.isLoaded = true;
        } catch (error) {
          this.$emit('error', error);

          this.isLoaded = false;
        }
      },
      reInit() {
        this.currentPage = 1;
        this.sortKey = "_id";
        this.sortValue = "desc";
        this.type = "";
        this.limit = 10;
        this.$router.push({ ...this.$route, query: { ...this.$route.query, page: 1, name: "" } });
        this.searchLogsActivities();
        this.getTypeLogsActivities();
      },
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (vm.$store.getters['user/isMarvinRecruiter']) {
          next();
        } else {
          next('/');
        }
      });
    },
  };
</script>

<style lang="scss" scoped>
 
  .database-logs-activities {
    @include page_grid();
    &__cards {
      flex-wrap: wrap;
      flex-direction: column;
    }

    &__filters {
      margin: 20px 0px 20px;
      align-self: flex-start;
      justify-content: left;

      &-label {
        align-items: center;
        padding: 0 10px 0 20px;
      }
    }

    &__filters-actions {
      margin: 0px 0px 20px;
      align-self: flex-start;
      justify-content: left;

      &-label {
        align-items: center;
        padding: 0 10px 0 20px;
      }
    }

    &__input {
      margin: 0 10px;
    }

    &__dropdown {
      margin: 0 10px;
    }

    &__button {
      flex-shrink: 0;
    }

    &__pagination {
      margin: 20px 0;
      justify-content: right;
    }

    &__spinner {
      margin: 100px 0;
      justify-content: center;
    }

    .ml10 {
      margin-left: 10px;
    }
  }

  .bc-dropdown {
    min-width: 150px;
    justify-content: center;
    margin: 0 5px;
  }
</style>
