<script lang="ts" setup>
  import { computed, defineEmits, defineProps, ref, watch, withDefaults } from 'vue';
  import PanelCoderEditLocation
    from '@/components/Panel/PanelCoder/PanelCoderEdit/PanelCoderEditLocation.vue';
  import { useI18n } from '@/i18n/i18n';
  import { ValidationObserver } from 'vee-validate';
  import ProfessionsAutocomplete from '@/components/Professions/ProfessionsAutocomplete.vue';
  import SkillsAutocomplete from '@/components/Professions/SkillsAutocomplete.vue';
  import { Skill } from '@/domains/models/Skill';
  import CustomJobsContractSelect from '@/components/CustomJobs/CustomJobsContractSelect.vue';
  import SalaryInput from '@/components/Salary/SalaryInput.vue';
  import { useStore } from '@/store';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import BcCustomField from '@/ui-kit/components/BcCustomField/BcCustomField.vue';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { Opportunity } from '@/domains/models/Opportunity';
  import { updateCustomJob } from '@/api/custom-jobs';
  import CustomJobsContractDurationSelect
    from '@/components/CustomJobs/CustomJobsContractDurationSelect.vue';
  import CustomJobsContractDates from '@/components/CustomJobs/CustomJobsContractDates.vue';
  import CompanyContactsSelect from '@/components/Companies/CompanyContactsSelect.vue';

  interface Props {
    opportunity: Opportunity;
    isCreating?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    isCreating: false,
  });
  const emit = defineEmits<{
    (e: 'close-edit'): void,
    (e: 'update-opportunity', value: Opportunity): void
  }>();
  const { t } = useI18n();
  const store = useStore();
  const toast = useToast();

  const organization = store.state.user?.profile?._organization;
  const customFields = organization.configuration?.job?.customFields?.filter(customField => customField.activated) || [];
  const isProfessionRequired = organization.configuration?.extension?.requiredProfession || false;
  const cardActive = store.getters.cardActive;
  const requiredFields = organization.configuration?.job?.requiredFields || {};

  const closeEdit = () => {
    emit('close-edit');
  };

  const editedProfile = ref({
    locations: props.opportunity.locations ?? [],
    _professions: props.opportunity._professions ?? [],
    contract: props.opportunity.contract,
    contractDuration: props.opportunity.contractDuration,
    contractStartDate: props.opportunity.contractStartDate,
    contractEndDate: props.opportunity.contractEndDate,
    budget: props.opportunity.budget || {},
  });
  const setEditedProfile = (data) => {
    editedProfile.value = {
      ...editedProfile.value,
      ...data,
    };
  };

  // skills
  const selectedSkills = ref<Skill[]>([
    ...(props.opportunity._skills?.length ? props.opportunity._skills : []),
  ]);
  const updateCurrentSalary = (data = []) => {
    const currentSalary = data
    .map(value => ({
      salaryType: value.salaryType,
      salary: value.value_0,
      variable: value.value_1,
      benefit: value.benefit,
    }))
    .filter(({ salary, variable, benefit }) => !(!salary && !variable && !benefit));

    setEditedProfile({
      currentSalary,
    });
  };
  const updateSalaryWanted = (data = []) => {
    const salaryWanted = data
    .map(value => ({
      salaryType: value.salaryType,
      min: value.value_0,
      max: value.value_1,
      benefit: value.benefit,
    }))
    .filter(({ min, max, benefit }) => !(!min && !max && !benefit));

    setEditedProfile({
      salaryWanted,
    });
  };

  const customFieldsValues = ref(props.opportunity.customFields || {});
  const setCustomFields = (data) => {
    // For the 'remote' key, toggle the value (remove if same, add/update if different)
    if (data.key === 'remote' && data.value === customFieldsValues.value[data.key]) {
      // Create a new object without the property at data.key
      const newValues = { ...customFieldsValues.value };
      delete newValues[data.key];
      customFieldsValues.value = newValues;
    } else {
      // For all other cases, just update/add the property
      customFieldsValues.value = {
        ...customFieldsValues.value,
        [data.key]: data.value,
      };
    }
  };

  const onSelectContractDuration = (contractDuration) => {
    editedProfile.value.selectedContractDuration = contractDuration;
    editedProfile.value.contractDuration = contractDuration.apiCode;
  };

  const selectedEmployee = ref(props.opportunity?._employee || null);
  const handleSelectEmployee = (employee) => {
    selectedEmployee.value = employee;
  };

  const purgedProfile = computed(() => ({
    contract: editedProfile.value.contract || null,
    locations: editedProfile.value.locations?.map(location => location?.placeId || location?.place_id) || [],
    professionIds: editedProfile.value._professions?.map(profession => profession._id) || [],
    skillIds: selectedSkills.value?.map(skill => skill._id) || [],
    customFields: customFieldsValues.value || {},
    ...editedProfile.value.contractDuration && { contractDuration: editedProfile.value.contractDuration },
    budget: editedProfile.value.budget,
    employeeId: selectedEmployee.value?._id || null,
    contractStartDate: editedProfile.value.contractStartDate || null,
    contractEndDate: editedProfile.value.contractEndDate || null,
  }));
  const saveOpportunity = async() => {
    if (isProfessionRequired && !editedProfile.value._professions?.length) {
      toast.show({
        type: 'error',
        message: t('global.profession-required'),
      });
      throw Error('Profession required');
    }

    if (requiredFields?.location?.required && !purgedProfile.value?.locations?.length) {
      toast.show({
        type: 'error',
        message: t('global.location-required'),
      });
      throw Error('Locations required');
    }

    if (requiredFields?.skills?.required && !purgedProfile.value?.skillIds?.length) {
      toast.show({
        type: 'error',
        message: t('global.skills-required'),
      });
      throw Error('Skills required');
    }

    const { data } = await updateCustomJob({
      id: props.opportunity._id,
      ...purgedProfile.value,
    });

    if (cardActive >= 0) {
      await store.dispatch('updateCard', { index: cardActive, content: data });
    }
    emit('update-opportunity', data);
    closeEdit();
  };

  watch(() => purgedProfile.value, (value) => {
    if (props.isCreating) {
      emit('update-opportunity', purgedProfile.value);
    }
  }, { deep: true });
  const setSelectedSkills = e => {
    selectedSkills.value = e;
  };
  const selectedBudget = computed(() => {
    return [{
      salaryType: editedProfile.value.budget?.salaryType || '',
      value_0: editedProfile.value.budget?.min || 0,
      value_1: editedProfile.value.budget?.max || 0,
      value_optional: editedProfile.value.budget?.variable || 0,
      ...editedProfile.value.budget.variableType && { variableType: editedProfile.value.budget.variableType },
    }];
  });
  const handleUpdateBudget = (data) => {
    const [budget] = data;

    setEditedProfile({
      budget: {
        ...editedProfile.value.budget,
        salaryType: budget.salaryType,
        min: budget.value_0,
        max: budget.value_1,
        variable: budget.value_optional,
        ...budget.variableType && { variableType: budget.variableType },
      },
    });
  };
</script>

<template>
  <div class="relative flex w-full flex-row items-start gap-8 rounded-md bg-neutral-200 px-5 py-4">
    <ValidationObserver
      class="flex w-full flex-col gap-y-4"
      tag="form"
      @submit.prevent>
      <PanelCoderEditLocation
        :locations="editedProfile.locations"
        :required="requiredFields?.location?.required"
        class="w-full"
        @click.native.stop
        @update-value="setEditedProfile">
      </PanelCoderEditLocation>
      <ProfessionsAutocomplete
        :profile="editedProfile"
        :required="isProfessionRequired"
        class="flex flex-row flex-wrap"
        for-configuration
        @update-value="setEditedProfile"
        @select-professions="editedProfile._professions = $event">
      </ProfessionsAutocomplete>
      <SkillsAutocomplete
        :required="requiredFields?.skills?.required"
        :selected="selectedSkills"
        class="flex flex-row flex-wrap"
        for-configuration
        @select-skills="setSelectedSkills"
      />
      <CustomJobsContractSelect
        :selected="editedProfile.contract"
        class="mt-4 flex flex-row flex-wrap"
        @select-contract="editedProfile.contract = +$event.apiCode"
      />
      <CustomJobsContractDurationSelect
        :selected="editedProfile.selectedContractDuration"
        class="mt-4 flex flex-row flex-wrap"
        @select-contract-duration="onSelectContractDuration"
      />
      <CustomJobsContractDates
        :endDate="editedProfile.contractEndDate"
        :startDate="editedProfile.contractStartDate"
        @select-date="setEditedProfile"
      >
      </CustomJobsContractDates>
      <SalaryInput
        :has-actions="false"
        :is-aligned="true"
        :is-min-max="true"
        :labels="['min', 'max', $t('custom-jobs.part-of-variable')]"
        :selected="selectedBudget"
        :title="$t('generics.budget')"
        class="mt-1.5"
        @update="handleUpdateBudget"
      >
      </SalaryInput>
      <CompanyContactsSelect
        :company-id="opportunity?._company?._id"
        :label="$t('custom-jobs.partner-contact')"
        :selected="selectedEmployee"
        class="mt-4"
        @select-contact="handleSelectEmployee"/>
      <BcCustomField
        v-for="customField in customFields"
        :key="customField.key"
        :custom-field="customField"
        :name="customField.key === 'emergency' ? $t('generics.priority') : undefined"
        :placeholder="customField.name"
        :selected="customFieldsValues[customField.key]"
        :type="customField.type"
        class="mt-4"
        @on-update="setCustomFields"/>
      <div
        v-if="!isCreating"
        class="ml-auto flex flex-row gap-x-2.5">
        <BcButton
          icon-left="cross"
          size="small"
          type="outlined"
          @click="closeEdit">
          {{ $t('global.cancel') }}
        </BcButton>
        <BcButton
          color="primary"
          icon-left="check"
          native-type="submit"
          size="small"
          @click="saveOpportunity">
          {{ $t('global.save') }}
        </BcButton>
      </div>
    </ValidationObserver>
  </div>
</template>
