<template>
    <views-tabs :tabs="tabs">
        <template #content>
            <slot></slot>
        </template>
    </views-tabs>
</template>

<script setup lang="ts">
import { useI18n } from '@/i18n/i18n';
import { useStore } from '@/store';
import ViewsTabs from '@/components/Views/ViewsTabs';
import { computed } from 'vue';

const i18n = useI18n();
const store = useStore();
const coachRessourceCount = computed(() => store.getters['metadata/coachRessourceCount']);

const tabs = computed(() => [
    {
        key: 'candidates',
        label: i18n.tc('generics.candidate-cap', 2),
        to: { path: '/candidates/reminders', query: { 'filter': 'todo' } },
        exact: true,
        count: coachRessourceCount.value.candidateReminder,
        icon: 'users'
    },
    {
        key: 'contacts',
        label: i18n.tc('generics.contact-cap', 2),
        to: { path: '/contacts/reminders', query: { 'filter': 'todo' } },
        exact: true,
        count: coachRessourceCount.value.contactReminder,
        icon: 'contact',
    },
]);
</script>