<template>
  <div class="panel-coder-edit-location is-column">
    <bc-autocomplete
      v-model="input"
      :label="$t('panel-coder-edit.location')"
      :loading="isLoading"
      :options="suggestions"
      :placeholder="$t('placeholder.example-paris')"
      :required="required"
      class="panel-coder-edit-location__autocomplete is-expanded"
      icon="map-pin"
      name="location-actual"
      @select="selectLocation"
      @keydown.native.delete="removeLastLocation">
      <template #empty>
        <template v-if="!isLoading && debouncedInput">
          <p class="panel-coder-edit-location__option font-size-s">
            {{ $t('toast.no-location-found') }}
          </p>
        </template>
      </template>
      <template #option="{ option }">
        <bc-dropdown-item
          :text="option.name"
          class="panel-coder-edit-location__option font-size-s is-expanded">
        </bc-dropdown-item>
      </template>
    </bc-autocomplete>
    <div
      v-if="locations?.length"
      class="panel-coder-edit-location__wrapper mt-2.5 flex flex-wrap gap-2.5">
      <tag
        v-for="location in locations"
        :key="location._id"
        :can-close="true"
        :text="getLocationName(location)"
        class="panel-coder-edit-location__tag"
        type="location"
        @click="removeLocation(location)">
      </tag>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import Tag from '@/components/Tag/Tag';
  import { Location } from '@/domains/models/Location';
  import { computed, ref } from 'vue';
  import { useQuery } from '@tanstack/vue-query';
  import locationApi from '@/api/location';
  import locationsDeserializers from '@/managers/locations/formatters/locationsDeserializers';
  import { refDebounced } from '@vueuse/core';


  const props = withDefaults(defineProps<{
    locations: Location[],
    required: boolean
  }>(), {
    required: false,
  });
  const emits = defineEmits<{
    (e: 'update-value', value: { locations: Location[] }): void
  }>();

  const input = ref<string>('');
  const debouncedInput = refDebounced(input, 500);
  const { isLoading, data } = useQuery({
    queryKey: ['locations', debouncedInput],
    queryFn: () => fetchLocationSuggestions(input.value),
  });
  const suggestions = computed<Location[]>(() => input.value && input.value === debouncedInput.value ? data.value : []);

  function getLocationName(location: Location) {
    return locationsDeserializers.getLocationNameFromLocationObject(location) || location.name;
  }

  async function fetchLocationSuggestions(value: string) {
    if (value) {
      const { data: { predictions } } = await locationApi.getLocationAutocomplete(value);

      return predictions.map((location: Location) => ({
        ...location,
        name: location.description,
      }));
    } else {
      return [];
    }
  }

  function selectLocation(location: Location) {
    input.value = '';

    emits('update-value', {
      locations: [
        ...props?.locations || [],
        location,
      ],
    });
  }

  function removeLocation(location: Location) {
    emits('update-value', {
      locations: props.locations.filter(l => l.place_id !== location.place_id),
    });
  }

  function removeLastLocation() {
    if (props.locations?.length && !input.value) {
      emits('update-value', {
        locations: [...props.locations.slice(0, -1)],
      });
    }
  }
</script>

<style lang=scss scoped>
  .panel-coder-edit-location {
    &__option {
      line-height: 15px;
      padding: 10px 20px;
    }
  }
</style>
