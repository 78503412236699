<script lang="ts" setup>
import gcalendar from '@/assets/logo/google-calendar.png';
import {
  getGmailLink,
  getGoogleCalendarEventLink,
  getMicrosoftCalendarEventLink,
  getOutlookLink,
  getWhatsappLink,
} from '@/helpers/quicklinks';
import whatsapp from '@/assets/logo/whatsapp.png';
import { useI18n } from '@/i18n/i18n';
import { useRoute } from 'vue-router/composables';
import { useStore } from '@/store';
import outlookcalendar from '@/assets/logo/outlook_calendar.png';
import { computed, defineProps, ref, withDefaults } from 'vue';
import { useClipboard, onClickOutside } from '@vueuse/core';
import TransitionFadeTranslateUp from '@/components/UIKit/Transition/TransitionFadeTranslateUp.vue';

interface Props {
  value: string;
  icon?: string;
  iconUrl?: string;
  isEmail?: boolean;
  isPhone?: boolean;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
}

const i18n = useI18n();
const route = useRoute();
const store = useStore();

const props = withDefaults(defineProps<Props>(), {
  value: '',
  isEmail: false,
});

const isOpen = ref(false);
const widgetAvailability = computed(() => {
  const configurationProcess = store.getters['user/configurationProcess'];
  return {
    hasGoogleWidgets: configurationProcess.hasGoogleWidgets,
    hasMicrosoftWidgets: configurationProcess.hasMicrosoftWidgets,
  };
});
const setIsOpen = (value: boolean): void => {
  isOpen.value = value;
};
const googleCalendarLink = computed(() =>
  getGoogleCalendarEventLink({
    i18n,
    routeFullPath: route.fullPath,
    profileName: `${props.firstName} ${props.lastName}`,
    phone: props.phone,
    email: props.email,
    type: 'invitation',
    organizationName: store.state.user._organization?.name,
  }),
);
const microsoftCalendarLink = computed(() =>
  getMicrosoftCalendarEventLink({
    emailTo: props.email || '',
  }),
);
const gmailLink = computed(() => getGmailLink({ emailTo: props.email }));
const outlookLink = computed(() => {
  return getOutlookLink({ emailTo: props.email });
});
const { copy, copied } = useClipboard();
const target = ref(null);
onClickOutside(target, () => {
  setIsOpen(false);
});
</script>

<template>
  <div class="relative">
    <slot :is-open="isOpen" :set-is-open="setIsOpen" name="trigger"> </slot>
    <TransitionFadeTranslateUp>
      <ul
        ref="target"
        v-if="isOpen && (isEmail || isPhone)"
        class="absolute left-0 top-full z-10 mt-1.5 overflow-hidden rounded-md bg-white text-xs shadow-md flex-shrink-0 min-w-[280px]">
        <li
          class="flex cursor-pointer items-center rounded-t-md border-b border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white shrink-0 w-full"
          @click.stop="
            () => {
              copy(value);
              setIsOpen(false);
            }
          ">
          <span class="icon-copy"></span>
          <p class="ml-2">{{
            isEmail ? $t('companies.account-management.copy-email') : $t('generics.copy')
          }}</p>
        </li>
        <a v-if="isEmail && widgetAvailability.hasGoogleWidgets" :href="gmailLink" class="flex" target="_blank" @click.stop>
          <li
            class="flex cursor-pointer items-center border-b border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white shrink-0 w-full">
            <span class="icon-mail"></span>
            <p class="ml-2">{{ $t('coder.quicklinks.gmail') }}</p>
          </li>
        </a>
        <a
          v-if="isEmail && widgetAvailability.hasMicrosoftWidgets"
          :href="outlookLink"
          target="_blank"  @click.stop>
          <li
            class="flex cursor-pointer items-center border-b border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white">
            <span class="icon-mail"></span>
            <p class="ml-2">{{ $t('coder.quicklinks.outlook') }}</p>
          </li>
        </a>
        <a v-if="isEmail && widgetAvailability.hasGoogleWidgets" :href="googleCalendarLink" target="_blank" @click.stop>
          <li
            class="flex cursor-pointer items-center border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white w-full">
            <img :src="gcalendar" alt="gcalendar" class="size-[13px]" />
            <p class="ml-2">{{ $t('generics.invit') }}</p>
          </li>
        </a>
        <a
          v-if="isEmail && widgetAvailability.hasMicrosoftWidgets"
          :href="microsoftCalendarLink"
          target="_blank" @click.stop>
          <li
            class="flex cursor-pointer items-center border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white">
            <img :src="outlookcalendar" alt="outlookcalendar" class="size-[13px]" />
            <p class="ml-2">{{ $t('generics.invit') }}</p>
          </li>
        </a>
        <a v-if="isPhone" :href="`tel:${value}`" target="_blank" @click.stop>
          <li
            class="flex cursor-pointer items-center border-b border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white w-full">
            <span class="icon-phone"></span>
            <p class="ml-2">{{ $t('generics.phone-call') }}</p>
          </li>
        </a>
        <a v-if="isPhone" :href="getWhatsappLink({ phone: value })" target="_blank" @click.stop>
          <li
            class="flex cursor-pointer items-center border-b-blue-200 p-2.5 px-5 hover:bg-blue-500 hover:text-white w-full">
            <img :src="whatsapp" alt="whatsapp" class="size-[13px]" />
            <p class="ml-2">{{ $t('coder.quicklinks.whatsapp') }}</p>
          </li>
        </a>
      </ul>
    </TransitionFadeTranslateUp>
  </div>
</template>

<style lang="scss" scoped></style>
