import { computed, type Ref } from 'vue';
import { useLinkedinRelationsStore } from '@/store/pinia/linkedinRelations';
import { useLinkedinProfileStore } from '@/store/pinia/linkedinProfile';
import { getLinkedinSlug } from '@/formatters/linkedin';
import { useUnipileAccountsStore } from '@/store/pinia/unipileAccounts';
import { storeToRefs } from 'pinia';

interface Candidate {
  _id: string;
  social?: {
    linkedin?: string;
  };
}

export function useLinkedinCandidate(candidateRef: Ref<Candidate>) {
  const { linkedInAccount } = storeToRefs(useUnipileAccountsStore());
  const { getRelationStatus } = useLinkedinRelationsStore();
  const { hasReceivedMessages } = storeToRefs(useLinkedinProfileStore());

  const linkedinSlug = computed(() => getLinkedinSlug(candidateRef.value));
  const relationStatus = computed(() => {
    return getRelationStatus(linkedinSlug.value);
  });

  const baseLinkedinRequirements = computed(() => {
    return !!linkedInAccount.value && !!linkedinSlug.value;
  });

  const hasReceivedMessagesFromCandidate = computed(() => {
    return hasReceivedMessages.value[linkedinSlug.value as string];
  });

  const hasPremiumFeatures = computed(() => {
    return !!linkedInAccount.value?.premiumFeatures?.length;
  });

  const canMessage = computed(() => {
    if (!baseLinkedinRequirements.value) return false;
    return hasReceivedMessagesFromCandidate.value || relationStatus.value === 'connected';
  });

  const canInvite = computed(() => {
    if (!baseLinkedinRequirements.value) return false;
    return relationStatus.value === 'not-connected'
  }
  );

  const canInmail = computed(() => {
    if (!baseLinkedinRequirements.value) return false;
    return !canMessage.value && hasPremiumFeatures.value;
  });

  return {
    relationStatus,
    canMessage,
    canInvite,
    canInmail
  };
} 