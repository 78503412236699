<template>
  <div class="coders-grid is-full-width">
    <div class="coders-grid__container is-full-width">
      <bc-checkbox
        id="checkbox"
        :value="isChecked"
        class="coders-grid__text coders-grid__checkbox is-uppercase font-size-s"
        @input="$emit('checked', $event)">
      </bc-checkbox>
      <grid-sort-select
        :label="`${$t('generics.identity')}`"
        class="coders-grid__text coders-grid__name"
        @sort="handleSort('firstName', $event)"
      />
      <p class="coders-grid__text coders-grid__link is-uppercase font-size-s hidden-xs hidden-sm">
        {{ $t('companies.account-management.in-cv-upper') }}
      </p>
      <p class="coders-grid__text coders-grid__domains is-uppercase font-size-s hidden-xs">
        {{ $t('companies.account-management.professions-upper') }}
      </p>
      <p class="coders-grid__text coders-grid__skills is-uppercase font-size-s">
        {{ $t('companies.account-management.skills-upper') }}
      </p>
      <grid-sort-select
        class="coders-grid__text coders-grid__locations is-uppercase font-size-s hidden-xs hidden-sm"
        :label="`${$t('companies.account-management.locations-upper')}`"
        @sort="handleSort('location', $event)"
      />
      <p class="coders-grid__text coders-grid__rating is-uppercase font-size-s">
        {{ $t('companies.account-management.eval-dot-upper') }}
      </p>
      <p
        v-if="showArrivalDate"
        class="coders-grid__text coders-grid__date is-uppercase font-size-s hidden-xs hidden-sm">
        {{ $t('companies.account-management.arrival-date') }}
      </p>
    </div>
  </div>
</template>

<script>
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
  import GridSortSelect from '@/components/Grid/GridSortSelect.vue';

  export default {
    name: 'coders-grid',
    components: { GridSortSelect, BcCheckbox },
    props: {
      isChecked: {
        type: Boolean,
        default: false,
      },
      showArrivalDate: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      async handleSort(field, sortOption) {
        await this.$store.dispatch('setSort', {
          field,
          direction: sortOption.value,
        });
      },
    },
  };
</script>

<style lang=scss scoped>
  .coders-grid {
    padding: 25px 30px 10px 30px;

    &__checkbox {
      background: $color-white;
    }

    &__text {
      color: $color-blue-heavy-dark;
      margin: 0 10px;
    }

    &__name {
      @include coders-grid-name;
    }

    &__link {
      @include coders-grid-cv;
    }

    &__domains {
      @include coders-grid-domain;
    }

    &__stack {
      @include coders-grid-stack;
    }

    &__skills {
      @include coders-grid-skills;
    }

    &__locations {
      @include coders-grid-locations;
    }

    &__rating {
      @include coders-grid-rating;
    }

    &__followers {
      @include coders-grid-lists;
      text-align: end;
    }

    &__date {
      width: 100%;
      max-width: 100px;
    }
  }
</style>
