<template>
  <bc-toggle @click.stop.native="toggleAccountState" :value="value" id="toggle-coach-account">
  </bc-toggle>
</template>
<script>
import BcToggle from '@/ui-kit/components/BcToggle/BcToggle';
import coaches from '@/api/coaches';

export default {
  name: 'card-coaches-toggle-account',
  components: { BcToggle },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    async toggleAccountState() {
      try {
        const { data } = await coaches.updateEnableStateAccount(this.id, !this.value);

        this.$store.dispatch('updateCardFromId', {
          id: this.id,
          content: {
            enabled: data.enabled,
          },
        });
        this.switchToggle();
      } catch (error) {
        this.$toast.show({
          type: 'error',
          message: this.$t('toast.error-occured'),
        });
      }
    },
    switchToggle() {
      this.$emit('switch-card-toggle');
    },
  },
};
</script>
