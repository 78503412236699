<template>
  <views-tabs>
    <template #content>
      <configuration-team-topbar></configuration-team-topbar>
    </template>
  </views-tabs>
</template>

<script>
import ViewsTabs from '@/components/Views/ViewsTabs.vue';
import ConfigurationTeamTopbar from '@/components/Configuration/ConfigurationTeamTopbar.vue';

export default {
  name: 'configuration-team-tabs',
  components: {
    ViewsTabs,
    ConfigurationTeamTopbar,
  },
};
</script>
