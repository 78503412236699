<template>
  <div v-click-outside="onClose" class="mv-filter">
    <button
      class="mv-filter__button body-l"
      :class="{ 'mv-filter__button--open': isOpen }"
      @click="handleClick"
    >
      <span v-if="icon">
        <i :class="`icon-${icon}`"></i>
      </span>
      <span class="mv-filter__text first-letter:capitalize">{{ label }}</span>
      <div v-if="count" class="mv-filter__count">
        <span>{{ count }}</span>
      </div>
      <i
        :class="{
          'icon-chevron-down': !isOpen,
          'icon-chevron-up': isOpen,
        }"
        class="mv-filter__chevron is-flex is-align-items-center"
      >
      </i>
    </button>
    <transition name="slide-fade">
      <div
        v-show="isOpen && $slots.default"
        class="mv-filter__content"
        :style="{ [`${align}`]: 0 }"
      >
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'mv-fitler',
  props: {
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
    count: {
      type: Number,
    },
    align: {
      type: String,
      validator(value) {
        return ['left', 'right'].includes(value);
      },
      default: 'left',
    },
    shouldClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  watch: {
    shouldClose: {
      handler(value) {
        if (value) {
          this.onClose();
        }
      },
    }
  },
  methods: {
    handleClick() {
      this.isOpen = !this.isOpen;
    },
    onClose() {
      this.isOpen = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.mv-filter {
  flex-direction: column;
  position: relative;

  &__button {
    border-radius: 5px;
    border: 1px solid $color-blue-medium;
    background: white;
    color: $color-tertiary;
    padding: 10px 12px;
    gap: 10px;
    height: 36px;
    align-items: center;

    & > i {
      width: 12px;
      height: 10px;
      flex-shrink: 0;
    }

    &--open {
      background: $color-blue-light;
      color: $color-primary;
      border: 1px solid $color-blue-light;
    }
  }

  &__chevron {
    font-size: 10px;
  }

  &__count {
    border-radius: 100%;
    background: $color-primary;
    color: white;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-width: 16px;
    min-height: 16px;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 2px;
  }

  &__content {
    flex-direction: column;
    width: fit-content;
    position: absolute;
    top: 100%;
    margin-top: 7px;
    border-radius: 5px;
    border: 1px solid $color-blue-light;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(29, 57, 94, 0.10);
    z-index: 9999;
  }
}


.slide-fade-enter-active {
  transition: all .2s ease-in;
}
.slide-fade-leave-active {
  transition: all .2s ease-out;
}
.slide-fade-leave-to, .slide-fade-enter {
  transform: translateY(-7px);
  opacity: 0;
}
</style>
