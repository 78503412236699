<template>
  <div
    v-click-outside="closePanel"
    class="panel-job-profile col-xs-12"
    @click.stop>
    <panel-job-header class="panel-job-profile__header"></panel-job-header>
    <panel-carousel
      :carousel-elements="companyJobs">
      <panel-job-caption
        :is-loading="isLoading"
        :profile="profile">
      </panel-job-caption>
    </panel-carousel>
    <panel-categories
      :categories="categories"
      class="panel-job-profile__categories">
    </panel-categories>
    <panel-view>
      <component
        :is="panelView"
        :key="panelView"
        :is-loading="isLoading">
      </component>
    </panel-view>
  </div>
</template>

<script>
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation.js';
  //
  import { mapState } from 'vuex';
  import PanelJobHeader from '@/components/Panel/PanelJob/PanelJobHeader';
  import PanelCarousel from '@/components/Panel/PanelCarousel';
  import PanelJobCaption from '@/components/Panel/PanelJob/PanelJobCaption';
  import PanelCategories from '@/components/Panel/PanelCategoriesOldStyle';
  import PanelCategoriesItem from '@/components/Panel/PanelCategories/PanelCategoriesItemOldStyle';
  import PanelView from '@/components/Panel/PanelView';
  import PanelJobInformations from '@/components/Panel/PanelJob/PanelJobInformations';
  import PlaceholderPanelCarousel from '@/components/UIKit/Placeholder/PlaceholderPanel/PlaceholderPanelCarousel';
  import PanelJobPokes from '@/components/Panel/PanelJob/PanelJobPokes';
  import PanelProcess from '@/components/Panel/PanelProcess';
  import PanelJobNotes from '@/components/Panel/PanelJob/PanelJobNotes';
  import { getPartnerJobs } from '@/api/company';

  /**
   * The panel which is called when the user wants to see his own profile
   */
  export default {
    name: 'panel-job-profile',
    components: {
      PlaceholderPanelCarousel,
      PanelView,
      PanelCategoriesItem,
      PanelCategories,
      PanelJobHeader,
      PanelCarousel,
      PanelJobCaption,
      pokes: PanelJobPokes,
      infos: PanelJobInformations,
      process: PanelProcess,
      'my-notes': PanelJobNotes,
    },
    mixins: [PanelNavigation],
    data() {
      return {
        isLoading: true,
        nbActiveProcesses: 0,
        companyJobs: [],
      };
    },
    computed: {
      ...mapState({
        profile: state => state.panel.profile,
        cards: state => state.card.cards,
      }),
      categories() {
        const categories = [
          {
            name: this.$i18n.t('generics.description'),
            url: 'infos',
          },
          {
            name: this.$i18n.t('generics.candidate-activity'),
            url: 'my-notes',
          },
        ];

        return this.profile.hunting ? categories : categories.filter(category => category.name !== 'pokes');
      },
      panelView() {
        return this.$route.query.category ? this.$route.query.category : this.categories[0].url;
      },
      isOnCompaniesResource() {
        return this.cards.some(card => card.resource === 'partners' || card.resource === 'companies');
      },
    },
    methods: {
      async getJobProfile() {
        try {
          this.$store.dispatch('setPanelProfile', {});
          const timer = setTimeout(() => {
            this.isLoading = true;
          }, 1500);
          /**
           * We execute first the call to the API to get the profile with the params.id
           */
          const response = await this.$store.dispatch('getJobProfile', this.$route.params.id);

          this.nbActiveProcesses = response.data.nbActiveProcesses;

          this.$store.dispatch('setPanelProfile', response.data);
          /**
           * We bind a new value to see if the user click on the poke button
           */
          this.$store.dispatch('updateValue', {
            storeName: 'panel',
            stateName: 'profile',
            data: {
              poke: {
                status: false,
                timer: 0,
                countdown: '0d 0h 0mn',
              },
            },
          });

          if (this.isOnCompaniesResource) {
            await this.getCompanyJobs(response.data?._company?._id);
          }

          clearTimeout(timer);
          this.isLoading = false;
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
            icon: 'cross',
          });
        }
      },
      async getCompanyJobs(id) {
        try {
          const { data } = await getPartnerJobs({ call: id });

          this.companyJobs = data.map(job => ({ ...job, resource: 'jobs' }));
        } catch (error) {
          this.$toast.show({
            message: this.$t('toast.error-occured'),
            type: 'error',
            icon: 'cross',
          });
        }
      },
    },
    created() {
      this.getJobProfile();
    },
    watch: {
      $route(to, from) {
        if (to.params.id !== from.params.id) {
          this.getJobProfile();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-job-profile {
    height: 100%;

    &__header {
      order: -2;
    }

    &__categories {
      order: -1;
      @include bp('tablet') {
        order: 0;
      }
    }

    &__modal {
      z-index: 2;
    }
  }
</style>
