<template>
  <div class="picture-chat">
    <div class="picture-chat__container">
      <div class="picture-chat__avatar-container">
        <img
          :src="defaultAvatar"
          alt="user avatar"
          class="picture-chat__img"
          v-if="imgSrc === ''">
        <img
          :src="imgSrc"
          alt="user avatar"
          class="picture-chat__img"
          v-else
          v-img-fallback>
        <img
          :src="companyPicture"
          alt="user avatar"
          class="picture-chat__company-picture"
          v-if="companyPicture"
          v-img-fallback>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'picture-chat',
    props: {
      imgSrc: {
        type: String,
        default: require('@/assets/img/default-avatar.svg')
      },
      companyPicture: {
        type: String,
      }
    },
    data() {
      return {
        tooltipIsDisplayed: false,
        defaultAvatar: require('@/assets/img/default-avatar.svg')
      };
    },
    methods: {
      setTooltipIsDisplayed(val) {
        this.tooltipIsDisplayed = val;
      }
    }
  };
</script>

<style scoped lang="scss">
  .picture-chat {
    @include transition(all 0.3s ease);

    &__img {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      object-fit: cover;
    }

    &__container {
      position: relative;
      width: 38px;
      flex-direction: column;
    }

    &__company-picture {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      object-fit: cover;
    }

    &__date {
      width: 30px;
    }
  }
</style>
