<template>
  <div
    :class="[`avatar-${size}`, { 'bc-avatar--placeholder': (isError || !$attrs.src) && $attrs.placeholder }]"
    class="bc-avatar">
    <img
      v-if="!isError || !$attrs.placeholder"
      :src="$attrs.src && $attrs.src.startsWith('data:') ? '' : $attrs.src || ''"
      alt="bc-avatar"
      class="bc-avatar__picture"
      v-bind="$attrs"
      @error="handleError">
    <div
      v-if="(isError || !$attrs.src) && $attrs.placeholder"
      :class="{
        'font-size-xxl': size !== 's' && size !== 'xs',
        'font-size-xxs': size === 'xs',
        'bc-avatar__picture--placeholder': size === 's'
      }"
      class="bc-avatar__picture is-justify-content-center is-align-items-center font-weight-bold">
      {{ $attrs.placeholder }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'bc-avatar',
    inheritAttrs: false,
    props: {
      size: {
        type: String,
        default: 'm',
      },
    },
    data() {
      return {
        isError: false,
      };
    },
    methods: {
      handleError(event) {
        this.isError = true;

        this.$emit('error', event);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .bc-avatar {
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &--placeholder {
      border: 1px solid $color-secondary;
      color: $color-secondary;
      background: $color-white;
    }

    &__picture {
      width: inherit;
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;

      &--placeholder {
        position: relative;
        top: 1px;
      }
    }
  }
</style>
