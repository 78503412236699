<template>
  <div class="panel-custom-jobs-details">
    <div v-if="!customJob.isSpontaneous" class="panel-custom-jobs-details__buttons">
      <bc-button @click="goEdit" iconLeft="edit-3">{{
        $t('generics.edit')
      }}</bc-button>
    </div>
    <div class="panel-custom-jobs-details__items">
      <panel-profile-item v-if="professionsList.length" icon="award" :title="$t('generics.professions')">
        <template #header>
          <tag
            v-for="profession in professionsList"
            :key="profession._id"
            type="professions"
            :text="profession.name" />
        </template>
        <template #content>
          <div
            v-if="
              customJob._professions && customJob._professions.length > 3 && !showAllProfessions
            "
            class="panel-custom-jobs-details__more">
            <div class="panel-custom-jobs-details__separator"></div>
            <button @click.stop="showAllProfessions = true">
              {{ $t('custom-jobs.view-all-professions') }} ({{ customJob._professions.length }})
            </button>
          </div>
        </template>
      </panel-profile-item>
      <panel-profile-item v-if="skillsList.length" icon="tool" :title="$tc('generics.skill', 2)">
        <template #header>
          <tag v-for="skill in skillsList" :key="skill._id" type="technos" :text="skill.name" />
        </template>
        <template #content>
          <div
            v-if="customJob._skills && customJob._skills.length > 5 && !showAllSkills"
            class="panel-custom-jobs-details__more">
            <div class="panel-custom-jobs-details__separator"></div>
            <button @click.stop="showAllSkills = true">
              {{ $t('custom-jobs.view-all-skills') }} ({{ customJob._skills.length }})
            </button>
          </div>
        </template>
      </panel-profile-item>
      <panel-profile-item
        icon="users"
        :title="$t('companies.panel-company.share-with')">
        <template v-if="customJob._owners" #header>
          <div class="is-align-items-center">
            <bc-avatar
              v-for="coach in customJob._owners.slice(0, 5)"
              :key="coach._id"
              v-tooltip="`${coach.firstName} ${coach.lastName}`"
              :src="coach.pictureUrl"
              class="panel-custom-jobs-details__coach-shared"
              @error="setAlternativeImg"
              size="s">
            </bc-avatar>
            <bc-avatar
              v-if="customJob._owners.length > 5"
              v-tooltip="{ content: customJob._owners.slice(5).map(coach => `${coach.firstName} ${coach.lastName}`).join(', ') }"
              :src="''"
              class="panel-custom-jobs-details__coach-shared"
              @error="setAlternativeImg"
              size="s">
            </bc-avatar>
          </div>
        </template>
      </panel-profile-item>
      <panel-profile-item
        icon="file"
        :title="$t('companies.account-management.contract-type')">
        <template #header>
          <tag type="contracts" :text="contractsLabels[customJob.contract]" />
        </template>
      </panel-profile-item>
      <panel-profile-item
        icon="file"
        :title="$t('companies.account-management.contract-duration')">
        <template #header>
          <tag type="contracts" :text="contractDurationLabels[customJob.contractDuration]" />
          <div class="panel-custom-jobs-details__contract-dates">
            <tag v-if="customJob.contractStartDate" type="contracts" :text="contractStartDate" />
            <tag v-if="customJob.contractEndDate" type="contracts" :text="contractEndDate" />
          </div>
        </template>
      </panel-profile-item>
      <panel-profile-item v-if="budget.min && budget.max" icon="salary" :title="$t('generics.budget')">
        <template #header>
          <tag icon="salary" :text="`${getSalaryWithType(budget.min + '-' + budget.max, budget.salaryType)}${getSalaryWithType(budget.variable, budget.variableType, ' +')}`" />
        </template>
      </panel-profile-item>
      <!-- <panel-profile-item icon="dollars" :title="$t('custom-jobs.salary-type')">
        <template #header>
          <tag type="salaryType" :text="salaryLabels[customJob.salaryType]" />
        </template>
      </panel-profile-item>
      <panel-profile-item v-if="customJob.salaryMin" icon="salary" :title="$t('generics.remuneration', { type: salaryLabelsShort[customJob.salaryType] })">
        <template #header>
          <tag :text="salaryRange" type="salary" />
        </template>
      </panel-profile-item> -->
      <panel-profile-item
        v-if="configurationFramework.showCompanies && customJob._employee"
        icon="user"
        :title="$t('custom-jobs.partner-contact')"
      >
        <template v-if="customJob._employee._coder" #header>
          <div class="is-align-items-center">
            <bc-avatar
              :src="customJob._employee._coder.pictureUrl"
              class="card-coders__avatar is-unshrink"
              size="s"
              @error="setAlternativeImg" />
            <p class="ml-2 font-size-s">
              {{ customJob._employee._coder.lastName }} {{ customJob._employee._coder.firstName }}
            </p>
          </div>
        </template>
      </panel-profile-item>
      <panel-profile-item
        v-for="customField in configurationJob.customFields"
        :key="customField.key"
        :icon="customField.icon"
        :title="customField.key === 'emergency' ? $t('generics.priority') : customField.name">
        <template #header>
          <tag
            v-if="customJob.customFields && customJob.customFields[customField.key]"
            :text="
              Array.isArray(customJob.customFields[customField.key])
                ? customJob.customFields[customField.key].map(value => `${value} ${customField.unit}`).join(' - ')
                : customJob.customFields[customField.key]
            "
            :icon="customField.icon"
            :type="customField.key" />
        </template>
      </panel-profile-item>
      <panel-profile-item
        v-if="companyDescription"
        style="width: 100%"
        icon="clipboard"
        :title="$t('companies.panel-company.company-description')"
        titleStyle="font-weight: bold;">
        <template #content>
          <div
            class="panel-custom-jobs-details__job-description"
            v-html="companyDescription"></div>
        </template>
      </panel-profile-item>
      <panel-profile-item
        v-if="customJob.description"
        style="width: 100%"
        icon="clipboard"
        :title="$t('custom-jobs.description-job-offer')"
        titleStyle="font-weight: bold;">
        <template #content>
          <div
            class="panel-custom-jobs-details__job-description"
            v-html="customJob.description"></div>
        </template>
      </panel-profile-item>
      <panel-profile-item
        v-if="customJob.profileDescription"
        style="width: 100%"
        icon="clipboard"
        :title="$tc('generics.wanted-profile-cap')"
        titleStyle="font-weight: bold;">
        <template #content>
          <div
            class="panel-custom-jobs-details__job-description"
            v-html="customJob.profileDescription"></div>
        </template>
      </panel-profile-item>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { contractsLabels, salaryLabels, salaryLabelsShort, salaryTypes, contractDurationLabels, salaryTypesLabels, descriptionTypes } from '@/api/custom-jobs';
import Tag from '@/components/Tag/Tag';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import PanelProfileItem from '@/components/Panel/PanelProfile/PanelProfileItem';
import moment from 'moment/min/moment-with-locales';

export default {
  name: 'panel-custom-jobs-details',
  components: {
    PanelProfileItem,
    BcAvatar,
    BcButton,
    Tag,
  },
  props: {
    customJob: {
      type: Object,
      default: () => ({}),
    },
    refetch: {
      type: Function,
    },
  },
  data() {
    return {
      showAllProfessions: false,
      showAllSkills: false,
      contractsLabels,
      salaryLabels,
      salaryLabelsShort,
      contractDurationLabels,
    };
  },
  computed: {
    ...mapGetters('user', ['configurationJob', 'configurationFramework']),
    budget() {
      return this.customJob.budget || {};
    },
    professionsList() {
      if (!this.customJob._professions) {
        return [];
      }
      if (this.showAllProfessions) {
        return this.customJob._professions;
      }
      return this.customJob._professions.slice(0, 3);
    },
    skillsList() {
      if (!this.customJob._skills) {
        return [];
      }
      if (this.showAllSkills) {
        return this.customJob._skills;
      }
      return this.customJob._skills.slice(0, 5);
    },
    salaryRange() {
      if (!this.customJob.salaryMin) {
        return;
      }
      if (this.customJob.salaryType === salaryTypes.fixed) {
        return `${this.customJob.salaryMin} €/${this.salaryLabelsShort[this.customJob.salaryType].toLowerCase()}`;
      }
      return `${this.customJob.salaryMin}-${this.customJob.salaryMax} €/${
        this.salaryLabelsShort[this.customJob.salaryType]
      }`;
    },
    contractStartDate() {
      return `${this.$i18n.t('generics.start')} ${moment(this.customJob.contractStartDate).format('L')}`;
    },
    contractEndDate() {
      return `${this.$i18n.t('generics.end')} ${moment(this.customJob.contractEndDate).format('L')}`;
    },
    companyDescription() {
      if (this.customJob.descriptionType === descriptionTypes.client) {
        return this.customJob._company.description;
      } else if (this.customJob.descriptionType === descriptionTypes.custom) {
        return this.customJob.customCompanyDescription;
      }
      return this.customJob._organization?.description;
    },
  },
  methods: {
    setAlternativeImg(event) {
      event.target.src = require('@/assets/img/default-avatar.svg');
    },
    goEdit() {
      this.$router.push({
        ...this.$route,
        params: {
          id: this.customJob._id,
          from: 'panel',
        },
        query: {
          ...this.$route.query,
          display: 'panel',
          type: 'edit-custom-jobs',
        },
      });
    },
    getSalaryWithType(salary, type, prefix = '') {
      if (!salary) return ''
      return `${prefix} ${salary} ${salaryTypesLabels[type] || ''}`
    }
  },
};
</script>

<style lang="scss" scoped>
.panel-custom-jobs-details {
  width: 100%;
  flex-direction: column;
  padding: 20px;
  overflow: auto;

  &__buttons {
    justify-content: flex-end;
    margin-right: 15px;
    margin-bottom: 10px;
  }

  &__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 10px;

    & > * {
      width: 49%;
    }
  }

  &__separator {
    margin-top: 5px;
    border: 1px solid $color-blue-medium;
    opacity: 0.3;
  }

  &__more {
    flex-direction: column;

    & > button {
      margin: auto;
      padding: 15px;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: $color-blue-dark-cello;
    }
  }

  &__coach-shared {
    margin-left: -10px;
  }

  &__job-description {
    margin-top: 20px;
    flex-direction: column;
  }

  &__modal-content {
    flex-direction: column;
    min-height: 800px;
    width: 80vw;
  }

  &__contract-dates {
    gap: 10px;
  }
}
</style>
