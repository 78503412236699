<template>
  <div class="is-column">
    <div
      :class="{'searchbar-partners--open': isActiveFilter}"
      class="searchbar-partners">
      <div class="searchbar-partners__wrapper">
        <searchbar-autocomplete-partners
          class="searchbar-partners__autocomplete"
          :list-id="listId"
          @on-delete="updateRouteQueries"
          @on-select="addTag($event), updateRouteQueries()">
        </searchbar-autocomplete-partners>
      </div>
      <div>
        <searchbar-partners-filters></searchbar-partners-filters>
      </div>
    </div>
    <searchbar-filters-result
      v-if="hasFilters"
      :label="$t('generics.companies-in-db', { count: resultsCount })"
      @on-reset="handleReset"
    ></searchbar-filters-result>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  // helpers
  import SearchbarDispatcher from '@/helpers/searchbar/dispatcher';
  import { mapLocationQuery, splitQuery } from '@/helpers/url/decode_url';
  import { buildDomainTag, buildLocationsTag, buildRemoteTag, buildSearchTag, buildCustomFieldTag } from '@/helpers/searchbar/factories';
  import { buildCompanyPartnership } from '@/helpers/searchbar/factories/companiesTag';
  // components
  import SearchbarAutocompletePartners from '@/components/Searchbar/SearchbarPartners/SearchbarAutocompletePartners';
  import SearchbarPartnersFilters from '@/components/Searchbar/SearchbarPartners/SearchbarPartnersFilters';
  import SearchbarFiltersResult from '@/components/Searchbar/SearchbarFiltersResult';

  export default {
    name: 'searchbar-partners',
    components: {
      SearchbarAutocompletePartners,
      SearchbarPartnersFilters,
      SearchbarFiltersResult,
    },
    mixins: [SearchbarDispatcher],
    props: {
      resultsCount: {
        type: Number,
      },
      listId: {
        type: String,
      },
    },
    data() {
      return {
        mapComplexQuery: {
          search: {
            queryBuilder: splitQuery,
            tagBuilder: buildSearchTag,
          },
          locations: {
            queryBuilder: mapLocationQuery,
            tagBuilder: buildLocationsTag,
          },
          status: {
            queryBuilder: splitQuery,
            tagBuilder: buildCompanyPartnership,
          },
          domains: {
            queryBuilder: splitQuery,
            tagBuilder: buildDomainTag,
          },
          remotes: {
            queryBuilder: splitQuery,
            tagBuilder: buildRemoteTag,
          },
        },
      };
    },
    computed: {
      ...mapState({
        isActiveFilter: state => state.searchbar.isActiveFilter,
        technos: state => state.technos.technos,
      }),
      ...mapGetters({
        searchQueries: 'searchbarFiltersComputed',
        customFieldsSelected: 'customFieldsSelected',
      }),
      routeQueries() {
        return {
          ...this.searchQueries,
        };
      },
      filtersQuery() {
        return ['locations', 'status', 'remotes', 'ownerIds'];
      },
      hasFilters() {
        return Object.keys(this.$route.query)
        .filter(key => this.$route.query[key])
        .some(key => this.filtersQuery.includes(key) || key.startsWith('cf-'));
      },
    },
    mounted() {
      this.setupTags();
    },
    methods: {
      setupTags() {
        const mapQueries = Object.keys(this.$route.query);
        const filteredMapQueries = mapQueries.filter(key => this.$route.query[key]);

        filteredMapQueries.forEach(value => {
          if (this.mapComplexQuery[value]) {
            const queries = this.mapComplexQuery[value].queryBuilder(this.$route.query[value]);

            queries.forEach(q => {
              this.addTag(this.mapComplexQuery[value].tagBuilder(q));
            });
          }
        });
      },
      updateRouteQueries() {
        const checkedQuery = this.checkCustomFieldsQuery(query, this.routeQueries);
        const query = {
          ...checkedQuery,
          ...this.routeQueries,
          page: 1,
        };

        this.$router.push({
          name: this.$route.name,
          query,
        });
      },
      checkCustomFieldsQuery(query, routeQueries) {
        const q = {...query};
        const hasCfInRouteQueries = Object.keys(routeQueries).some(routeQuery => routeQuery.startsWith('cf-'));
        Object.keys(q).forEach(key => {
          if (!hasCfInRouteQueries && key.startsWith('cf-')) {
            q[key] = undefined;
          }
        });
        return q;
      },
      onSelectCustomFields(customField) {
        const hasKey = this.customFieldsSelected.find(filter => filter.key === customField.key);
        if (customField.replace && hasKey) {
          return this.replaceCustomFieldTag(buildCustomFieldTag(customField));
        }
        this.addTag(buildCustomFieldTag(customField));
      },
      onUnselectCustomFields(customField) {
        let index = this.customFieldsSelected.findIndex(filter => filter.name === customField.value);
        if (Array.isArray(customField.data)) {
          const finded = this.customFieldsSelected.find(filter => !customField.data.includes(filter.name));
          if (finded) {
            index = this.customFieldsSelected.findIndex(filter => filter.name === finded.name);
          }
        }
        this.removeTag(index);
      },
      closeSearchbar() {
        this.$store.dispatch('resetAndCloseSearchBar');
      },
      handleReset() {
        const query = { ...this.$route.query, page: 1 };
        for (const key in query) {
          if (this.filtersQuery.includes(key) || key.startsWith('cf-')) {
            Reflect.deleteProperty(query, key);
          }
        }
        this.$router.push({
          query,
        })
      },
    },
    beforeDestroy() {
      this.closeSearchbar();
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-partners {
    flex-direction: row;
    align-items: center;
    gap: 20px;

    @include bp('tablet') {
      position: relative;
    }

    &__icon {
      position: absolute !important;
      top: 15px;
      right: 15px;
    }

    &--open {
      display: block;
      position: fixed;
      background: $color-white;
      overflow: auto;
      top: 0;
      left: 0;
      height: 100vh;
      min-height: 100vh;
      width: 100%;
      padding: 85px 15px 50px;
      @include bp('tablet') {
        display: flex;
        position: initial;
        overflow: initial;
        height: initial;
        min-height: initial;
        padding: initial;
        background: initial;
      }
    }

    &__wrapper {
      @include bp('tablet') {
        padding: 0;
      }
    }

    &__input {
      width: 100%;
    }

    &__filters {
      margin: 10px -15px 50px;
      @include bp('tablet') {
        margin: 0;
        border-radius: 4px;
      }
    }
  }
</style>
