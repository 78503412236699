<script lang="ts" setup>
import { Candidate } from '@/domains/models/Candidate';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PanelCoderContactsSocial
  from '@/components/Panel/PanelCoder/PanelCoderContacts/PanelCoderContactsSocial.vue';
import PanelCoderContactsLinkedin
  from '@/components/Panel/PanelCoder/PanelCoderContacts/PanelCoderContactsLinkedin.vue';
import { computed, defineEmits, defineProps, withDefaults } from 'vue';

interface Props {
  candidate: Candidate;
}

const props = withDefaults(defineProps<Props>(), {
  candidate: () => ({}) as Candidate,
});
defineEmits<{ (e: 'edit-candidate', value: boolean): void }>();

const emails = computed<string[]>(() => {
  return [props.candidate.email, ...(props.candidate.emails?.length ? props.candidate.emails : [])].filter(e => e);
});
const phones = computed<string[]>(() => {
  return [props.candidate.phone, ...(props.candidate.phones?.length ? props.candidate.phones : [])].filter(e => e).map(phone => parsePhoneNumberFromString(phone, 'FR')?.formatInternational() || phone);
});

const linkedinUrl = computed(() => {
  return props.candidate?.social?.linkedin ? decodeURI(props.candidate.social.linkedin) : null;
});
</script>

<template>
  <div class="relative flex w-full flex-row flex-wrap gap-[5px] rounded-md bg-neutral-200 p-5 text-sm">
    <PanelCoderContactsSocial v-for="(email, index) in emails" :key="email" :candidate="candidate" :is-email="true"
      :is-pro="index > 0" :value="email" class="max-w-[47.5%] px-2.5 py-[2px]" icon="mail" />
    <PanelCoderContactsSocial v-for="(phone, index) in phones" :key="phone" :candidate="candidate" :is-phone="true"
      :is-pro="index > 0" :value="phone" class="max-w-[47.5%] px-2.5 py-[2px]" icon="phone" />
    <PanelCoderContactsLinkedin class="px-2.5 py-[2px]" v-if="linkedinUrl" :candidate="candidate" :linkedin-url="linkedinUrl" />
  </div>
</template>
