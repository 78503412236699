<template>
  <div class="flex min-w-[640px] max-w-[640px] flex-col">
    <div class="relative w-full items-center justify-center px-[10px] py-[15px]">
      <ButtonClose
        class="absolute left-[10px] top-[15px] border-0"
        @click="panelStore.closeModal"/>
      <h3
        class="max-w-[475px] text-center text-xl font-bold"
        v-html="$t('coder.duplicate-warning') + ' :'">
      </h3>
    </div>
    <hr class="w-full border-blue-100"/>
    <div class="flex w-full grow flex-col gap-[5px] overflow-auto px-[20px] py-[15px]">
      <PanelCompanyModalDeduplicationCard
        v-for="company in companies"
        :key="company._id"
        :checked="getSelectedStatus(company._id)"
        :company="company"
        :draft-company="draftCompany"
        class="w-full"
        @on-check="selectedCompanyId = company._id"
      />
      <div class="mt-[5px] flex items-center gap-[5px]">
        <div class="size-[9px] rounded-full bg-red-500"></div>
        <p class="text-[10px] text-blue-400">{{ $t('merge.conflict-informations') }}</p>
      </div>
    </div>
    <div class="flex gap-[20px] py-[30px]">
      <bc-button
        class="flex-1"
        icon-left="user-plus"
        type="outlined"
        @click="createNewProfileCallback">
        {{ $t('generics.create-a-new-profile') }}
      </bc-button>
      <bc-button
        :disabled="!selectedCompanyId"
        class="flex-1"
        icon-left="repeat"
        @click="goMergeProfile"
      >
        {{ $t('generics.update-info') }}
      </bc-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import ButtonClose from '@/components/Button/ButtonClose.vue';
  import { Company } from '@/domains/models/Company';
  import { usePanelStore } from '@/store/pinia/panel';
  import PanelCompanyModalDeduplicationCard
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalDeduplication/PanelCompanyModalDeduplicationCard.vue';
  import { ref } from 'vue';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import PanelCompanyModalMerge
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalMerge.vue';
  import { useRoute, useRouter } from 'vue-router/composables';

  const props = defineProps<{
    companies: Company[]
    createCompanyCallback: () => void
    createNewProfileCallback: () => void
    draftCompany: Company
  }>();

  const panelStore = usePanelStore();
  const router = useRouter();
  const route = useRoute();

  const selectedCompanyId = ref<string | null>(null);

  function getSelectedStatus(id: string) {
    return selectedCompanyId.value === id;
  }

  function mergeCallback(id: string) {
    router.push({
      name: route?.name.includes('Panel') ? route?.name : `${route?.name}Panel`,
      params: {
        id,
      },
      query: {
        ...route?.query,
        type: 'companies',
      },
    });
  }

  async function goMergeProfile() {
    const createdCompany = await props.createCompanyCallback();
    const selectedCompany = props.companies.find(company => company._id === selectedCompanyId.value);

    panelStore.openModal<{
      company: Company
      draftCompany: Company
      mergeCallback: () => void
    }>(PanelCompanyModalMerge, {
      company: selectedCompany,
      draftCompany: createdCompany,
      mergeCallback: () => mergeCallback(selectedCompany._id),
    });
  };
</script>
