<script lang="ts" setup>
import { computed } from 'vue';
import { CampaignStatusKey } from '@/domains/models/Campaign';
import moment from 'moment/min/moment-with-locales';
import { Campaign } from '@/domains/models/Campaign';
import { useI18n } from '@/i18n/i18n';

const { t, locale } = useI18n();

const props = defineProps<{
  campaign: Campaign
}>();

const campaignStatusStyle: Record<CampaignStatusKey, Record<string, string>> = {
  ONGOING: {
    color: 'text-blue-500',
    bg: 'bg-blue-100',
  },
  PAUSED: {
    color: 'text-orange-500',
    bg: 'bg-orange-100',
  },
  DRAFT: {
    color: 'text-blue-400',
    bg: 'bg-blue-100',
  },
  ARCHIVED: {
    color: 'text-gray-500',
    bg: 'bg-gray-100',
  },
  FAILED: {
    color: 'text-red-500',
    bg: 'bg-red-100',
  },
  FINISHED: {
    color: 'text-green-550',
    bg: 'bg-green-100',
  },
  SCHEDULED: {
    color: 'text-purple-600',
    bg: 'bg-purple-100',
  },
};

const formatDateTime = (date: Date) => {
  return moment(date).format('L');
};

const status = computed<string>(() => {
  const { status, scheduledAt, createdAt, updatedAt } = props.campaign;

  const campaignStatusMap: Record<CampaignStatusKey, string> = {
    ONGOING: t('campaigns.status.ongoing'),
    PAUSED: t('campaigns.status.paused'),
    DRAFT: t('campaigns.status.draft'),
    ARCHIVED: t('campaigns.status.archived'),
    FAILED: t('campaigns.status.failed'),
    FINISHED: t('campaigns.status.finished'),
    SCHEDULED: t('campaigns.status.scheduled'),
  };
  switch (status) {
    case 'SCHEDULED':
      return scheduledAt ? `${campaignStatusMap[status]} ${moment(scheduledAt).format('LLL')}` : campaignStatusMap[status];
    case 'ONGOING':
      return `${campaignStatusMap[status]} ${t('campaigns.status.since')} ${formatDateTime(createdAt)}`;
    case 'PAUSED':
    case 'ARCHIVED':
      return `${campaignStatusMap[status]} ${t('campaigns.status.at')} ${formatDateTime(updatedAt)}`;
    case 'DRAFT':
      return `${campaignStatusMap[status]} ${t('campaigns.status.created_at')} ${formatDateTime(createdAt)}`;
    default:
      return `${campaignStatusMap[status]} ${t('campaigns.status.at')} ${formatDateTime(createdAt)}`;
  }
});

const statusStyle = computed<Record<string, string>>(() => {
  return campaignStatusStyle[props.campaign.status];
});
</script>

<template>
  <p :class="`${statusStyle.color} ${statusStyle.bg} flex shrink-0 rounded-2xl px-2.5 py-[1px] text-xs font-medium`">
    {{ status }}
  </p>
</template>
