<template>
  <div
    :class="{ 'card-jobs--list': cardProps && cardProps.isFromPanel }"
    class="card-jobs">
    <card-informations
      :card-content="cardContent"
      :card-type="resource"
      :is-click-disabled="isClickDisabled"
      @click.meta.native.exact="metaClickOnCard"
      @click.native.exact="clickOnCard"
      class="card-jobs__item">
    </card-informations>
    <card-jobs-informations
      :card-content="cardContent"
      :card-index="cardIndex"
      :card-props="cardProps"
      class="card-jobs__informations">
    </card-jobs-informations>
  </div>
</template>

<script>
  import CardInformations from '@/components/Card/CardInformations/CardInformations';
  import CardJobsInformations from '@/components/Card/CardJobs/CardJobsInformations';
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';

  export default {
    name: 'card-jobs',
    components: {
      CardJobsInformations,
      CardInformations,
    },
    mixins: [PanelNavigation],
    props: {
      cardContent: Object,
      cardIndex: Number,
      cardProps: {
        type: [Object, Boolean],
        default: () => ({}),
      },
      isClickDisabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        resetedNewPoke: {
          coderId: '',
          jobId: '',
        },
        newPoke: {
          coderId: this.$route.params.id,
          jobId: this.cardContent._id,
        },
        resource: 'jobs',
      };
    },
    methods: {
      clickOnCard() {
        if (this.$route.params.id && (this.$route.query.type === 'jobs-list' || this.$route.query.type === 'create-coder-poke')) {
          this.selectJob();
        } else if (!this.isClickDisabled) {
          this.openPanel({ id: this.cardContent._id, type: 'jobs', category: 'infos' });
        }
      },
      metaClickOnCard() {
        if (this.$route.params.id && (this.$route.query.type === 'jobs-list' || this.$route.query.type === 'create-coder-poke')) {
          this.selectJob();
        } else if (!this.isClickDisabled) {
          this.openPanelInNewTab({ id: this.cardContent._id, type: 'jobs', category: 'infos' });
        }
      },
      selectJob() {
        this.$store.dispatch('setNewPoke', this.newPoke);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card-jobs {
    position: relative;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;

    @include bp('tablet') {
      flex-direction: column;

      &--views {
        flex-direction: row;
      }
    }

    &__item {
      height: 100%;
      width: 100%;
      overflow: hidden;

      @include bp('tablet') {
        min-height: 120px;
        overflow: initial;
      }
    }

    &__informations {
      height: 100%;
      flex-shrink: 0;

      @include bp('tablet') {
        height: auto;
      }
    }

    &__actions {
      position: absolute;
      top: 20px;
      left: 110px;
      flex-direction: column;
      z-index: 1;

      @include bp('tablet') {
        left: initial;
        right: 100px;
      }
    }

    &__button {
      margin-bottom: 7.5px;
    }

    &__views {
      height: 100%;

      @include bp('tablet') {
        height: auto;
        width: 25%;
      }

      @include bp('desktop') {
        width: 33.333%;
      }
    }
  }
</style>
