<template>
  <div class="searchbar-filters-cv">
    <p class="searchbar-filters-cv__title">
      {{ $t('generics.career') }}
    </p>
    <div class="searchbar-filters-cv__wrapper">
      <bc-checkbox
        class="searchbar-filters-cv__checkbox"
        id="cv"
        v-model="cv">
        {{ $t('hunt.has-a-resume') }}
      </bc-checkbox>
      <bc-checkbox
        class="searchbar-filters-cv__checkbox"
        id="searchbar-filters-linkedin"
        v-model="linkedin">
        {{ $t('hunt.has-linkedin') }}
      </bc-checkbox>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { buildCVTag, buildLinkedinTag } from '@/helpers/searchbar/factories';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';

  export default {
    components: {
      BcCheckbox,
    },
    name: 'searchbar-filters-ref',
    computed: {
      ...mapState({
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags,
      }),
      cv: {
        get() {
          return this.searchbarFiltersTags.some(tag => tag.type === 'cv');
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildCVTag());
          } else {
            const index = this.searchbarFiltersTags.findIndex(tag => tag.type === 'cv');

            if (index >= 0) {
              this.$emit('on-unselect', index);
            }
          }
        },
      },
      linkedin: {
        get() {
          return this.searchbarFiltersTags.some(tag => tag.type === 'linkedin');
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildLinkedinTag());
          } else {
            const index = this.searchbarFiltersTags.findIndex(tag => tag.type === 'linkedin');

            if (index >= 0) {
              this.$emit('on-unselect', index);
            }
          }
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-filters-cv {
    flex-direction: column;
    padding: 20px 20px -20px;
    @include bp('tablet') {
      flex-direction: row;
    }

    &__title {
      margin-bottom: 20px;
      font-size: $font-size-s;
      color: $color-blue-dark-cello;
      line-height: 18px;
      @include bp('tablet') {
        width: 100%;
        max-width: 85px;
        margin-right: 35px;
        text-align: left;
        margin-bottom: 0;
      }
    }

    &__wrapper {
      position: relative;
      flex-wrap: wrap;
      gap: 16px;
    }
  }
</style>
