<template>
  <div class="prospection-pipe-column is-column">
    <div class="prospection-pipe-column__header">
      <p class="body-m--medium prospection-pipe-column__title">
        {{ step.name }}
      </p>
      <BcSpinner size="small" color="white" class="items-center" v-if="countLoading" />
      <p v-else class="body-m--medium prospection-pipe-column__title">
        {{ countIncludingDragged }}
      </p>
    </div>
    <div
      class="prospection-pipe-column__body">
      <slot />
      <draggable
        :value="companies"
        @input="setProspectionPipeColumn"
        animation="200"
        class="prospection-pipe-column__step"
        ghost-class="prospection-pipe--ghost"
        group="company"
        handle=".is-draggable"
      >
        <ProspectionPipeCard
          v-for="company in companiesWithId"
          :key="company._id"
          :company="company"
          class="prospection-pipe-column__card list-group-item"
          @set-owner="setOwner" />
      </draggable>
      <InfiniteLoading
        :identifier="identifier"
        @infinite="loadMoreCompanies"
        style="justify-content: center">
        <BcSpinner slot="spinner" :style="{ marginTop: companiesWithId.length ? '2rem' : 0 }" />
        <span slot="no-more" />
        <span slot="no-results" />
      </InfiniteLoading>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, defineProps, onMounted } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useToast } from '@/ui-kit/components/BcToast';
import draggable from 'vuedraggable';
import ProspectionPipeCard from '@/components/ProspectionPipe/ProspectionPipeCard.vue';
import { setPartnerProspectingStatus } from '@/api/company';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
import { prospectionStatusCategoryColor } from '@/macros/companies/prospectionStatus';
import InfiniteLoading from 'vue-infinite-loading';
import { prospectionPipeController } from '@/managers/prospectionPipe/controller';
import { useI18n } from '@/i18n/i18n';

const props = defineProps<{
  step: {
    name: string;
    category: any;
    status: any;
  };
}>();

const route = useRoute();
const toast = useToast();
const { t } = useI18n();

const currentPage = ref(1);
const identifier = ref(+new Date());
const count = ref(0);
const draggingCount = ref(0);
const companies = ref([]);
const countLoading = ref(false);

const selectedCoaches = computed(() => route.query['coach-ids']);
const selectedTeams = computed(() => route.query['team-ids']);
const selectedPipe = computed(() => route.query['selected-pipe']);
const companiesWithId = computed(() => companies.value.filter(company => company._id));
const color = computed(() => prospectionStatusCategoryColor[props.step.category].color);
const backgroundColor = computed(
  () => prospectionStatusCategoryColor[props.step.category].backgroundColor
);
const countIncludingDragged = computed(() => count.value + draggingCount.value);


async function loadMoreCompanies($state: any) {
  try {
    const { data: paginatedCompanies } = await prospectionPipeController.get({
      ...(selectedCoaches.value && { coachIds: selectedCoaches.value }),
      ...(selectedTeams.value && { teamIds: selectedTeams.value }),
      status: props.step.status,
      sort: 'desc',
      page: currentPage.value,
    });
    currentPage.value++;
    companies.value = [...companies.value, ...paginatedCompanies];

    if (companies.value.length) {
      $state?.loaded();
    }
    if (!paginatedCompanies.length) {
      $state?.complete();
    }
  } catch (error) {
    toast.show({
      type: 'error',
      title: 'Error',
      message: error.message,
    });
  }
}

async function getCount() {
  try {
    countLoading.value = true;
    const { data } = await prospectionPipeController.getCount({
      ...(selectedCoaches.value && { coachIds: selectedCoaches.value }),
      ...(selectedTeams.value && { teamIds: selectedTeams.value }),
      status: props.step.status,
    });
    count.value = data ?? 0;
  } catch (error) {
    toast.show({
      type: 'error',
      title: 'Error',
      message: error.message,
    });
    count.value = 0;
  } finally {
    countLoading.value = false;
  }
}

async function setProspectionPipeColumn(updatedCompaniesFromDragging) {
  const isSameColumnDragging = updatedCompaniesFromDragging.length === companies.value.length;
  if (isSameColumnDragging) return;
  const isDraggingTarget = updatedCompaniesFromDragging.length > companies.value.length;

  const addedCompany = updatedCompaniesFromDragging.find(
    updatedCompanyFromDragging => !companies.value.includes(updatedCompanyFromDragging)
  );

  try {
    if (addedCompany) {
      const { data: updatedCompany } = await setPartnerProspectingStatus({
        partnerId: addedCompany._id,
        status: props.step.status,
      });
      companies.value = [
        updatedCompany,
        ...companies.value
      ];
      draggingCount.value++;
    } else {
      companies.value = updatedCompaniesFromDragging;
      draggingCount.value--;
    }
  } catch (error) {
    toast.show({
      title: t('companies.pipe-card.error-while-loaded-company-prospection-status'),
      message: t('toast.error-occured'),
      type: 'error',
      icon: 'cross',
    });
  }
}

function setOwner($event) {
  const companyIndex = companies.value.findIndex(c => c._id === $event._id);

  companies.value[companyIndex] = $event;
  companies.value = [...companies.value];
}

function resetInfiniteLoading() {
  currentPage.value = 1;
  identifier.value = +new Date();
  companies.value = [];
    draggingCount.value = 0;
  getCount();
}

watch(
  () => [selectedCoaches.value, selectedTeams.value, selectedPipe.value],
  () => resetInfiniteLoading()
);

onMounted(() => getCount());
</script>

<style lang="scss" scoped>
.prospection-pipe-column {

  &__header {
    padding: 15px;
    justify-content: space-between;
    text-transform: uppercase;
    background: v-bind('color');
  }

  &__title {
    color: $color-white;
  }

  &__body {
    flex-grow: 1;
    transition: 0.15s;
    flex-direction: column;
    padding: 5px 0 50px 0;
    overflow: auto;
    background: v-bind('backgroundColor');
  }

  &__step {
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
  }

  &__card {
    margin: 5px 10px;
  }
}

.prospection-pipe {
  &--ghost {
    display: none;
  }
}
</style>
