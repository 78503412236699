<template>
  <mv-filter
    v-if="withSelect"
    :label="$t('generics.companies')"
    icon="certificate"
    :count="selected.length"
  >
    <filter-content
      class="filter-coders-companies__content"
      :label="$t('generics.companies')"
      icon="certificate"
    >
      <companies-autocomplete
        :selected="selected"
        :show-label="false"
        :is-filter="true"
        @select="handleSelect"
      ></companies-autocomplete>
    </filter-content>
  </mv-filter>
  <filter-content
    v-else
    class="filter-coders-companies__content"
    :label="label"
    icon="companies"
  >
    <companies-autocomplete
      :selected="selected"
      :show-label="false"
      :is-filter="true"
      :with-tags="withTags"
      @select-company="handleSelect"
      @select-tag="handleSelectTag"
    ></companies-autocomplete>
    <slot name="content"></slot>
  </filter-content>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import MvFilter from '@/ui-kit/components/MvFilter/MvFilter.vue';
import FilterContent from '@/components/Filter/FilterContent.vue';
import CompaniesAutocomplete from '@/components/Companies/CompaniesAutocomplete.vue';

interface Company {
  _id: string;
  name: string;
}

const props = defineProps<{
  label?: string;
  withSelect?: boolean;
  withTags?: boolean;
  emitOnly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'count', value: { key: string; count: number }): void;
  (e: 'update:filters', filters: Record<string, any>): void;
}>();

const route = useRoute();
const router = useRouter();

const localSelected = ref<Company[]>([]);

const companies = computed(() => props.emitOnly ? undefined : route.query.companies);
const companiesNames = computed(() => props.emitOnly ? undefined : route.query.companiesNames);

const selected = computed(() => {
  if (props.emitOnly) {
    return localSelected.value;
  }
  
  if (!companies.value) {
    return [];
  }
  const companyIds = companies.value.toString().split(',');
  const companyNames = companiesNames.value?.toString().split(',') || [];
  return companyIds.map((id, index) => ({
    _id: id,
    name: companyNames[index],
  }));
});

function updateCompanies(newCompanies: Company[]) {
  const companiesQuery = newCompanies.length ? newCompanies.map(p => p._id).join(',') : undefined;
  const companiesNamesQuery = newCompanies.length ? newCompanies.map(p => p.name).join(',') : undefined;

  if (props.emitOnly) {
    localSelected.value = newCompanies;
    emit('update:filters', {
      companies: companiesQuery,
      companiesNames: companiesNamesQuery,
    });
  } else {
    router.push({
      query: {
        ...route.query,
        page: 1,
        companies: companiesQuery,
        companiesNames: companiesNamesQuery,
      },
    });
  }
}

function handleSelect(company: Company) {
  const newCompanies = [...selected.value, company];
  updateCompanies(newCompanies);
}

function handleSelectTag(tag: Company) {
  const newCompanies = selected.value.filter(company => company._id !== tag._id);
  updateCompanies(newCompanies);
}

onMounted(() => {
  emit('count', { key: 'company', count: selected.value.length ? 1 : 0 });
});

watch(selected, (value) => {
  emit('count', { key: 'company', count: value.length ? 1 : 0 });
});
</script>

<style lang="scss" scoped>
.filter-coders-companies {
  &__content {
    min-width: 574px;;

    &:deep() {
      .bc-input {
        margin-top: 0;
      }
    }
  }
}
</style>
