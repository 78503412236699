<template>
  <div class="title-pokes">
    <div class="title-pokes__container">
      <p class="title-pokes__name title-pokes__name--left">
        {{ textLeft }}
      </p>
      <slot
        name="icon">
      </slot>
    </div>
    <p class="title-pokes__name title-pokes__name--right">
      {{ textRight }}
    </p>
  </div>
</template>

<script>
  export default {
    name: 'title-pokes',
    props: {
      textLeft: {
        type: String
      },
      textRight: {
        type: String
      }
    }
  };
</script>

<style lang="scss" scoped>
  .title-pokes {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: left;
    white-space: nowrap;
    flex-shrink: 0;

    &__container {
      align-items: flex-start;
      flex-shrink: 0;
    }

    &__name {
      display: block;
      line-height: 18px;

      &--left {
        font-size: $font-size-s;
        line-height: 18px;
        color: $color-blue-dodger;
      }

      &--right {
        font-weight: $font-weight-medium;
        font-size: $font-size-xs;
        line-height: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .icon-home {
      font-size: $font-size-l;
      margin-left: 10px;
      color: $color-blue-dodger;
    }
  }
</style>
