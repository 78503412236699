<template>
  <div class="panel-favorite-list-name">
    <title-section
      class="panel-favorite-list-name__title"
      position="left"
      :title="$t('companies.panel-company.list-name')">
    </title-section>
    <ValidationProvider
      :rules="{ required: true, min: 2 }"
      name="Nom de la liste"
      v-slot="{ errors }">
      <bc-input
        :class="{ 'invalid': errors.length > 0 }"
        :disabled="!isEditable"
        class="panel-favorite-list-name__input"
        name="name-list"
        :label="$t('companies.panel-company.list-name')"
        :labelPlaceholder="false"
        :required="true"
        :valid="name.length"
        :error="errors[0]"
        type="text"
        v-model="name"
        v-tooltip="tooltip">
      </bc-input>
    </ValidationProvider>
  </div>
</template>

<script>
  import TitleSection from '@/components/Title/TitleSection';
  import BcInput from '@/ui-kit/components/BcInput/BcInput';
  import { ValidationProvider } from 'vee-validate';
  import favoriteLists from '@/macros/favoriteLists/favoriteLists';

  export default {
    name: 'panel-favorite-list-name',
    components: {
      BcInput,
      TitleSection,
      ValidationProvider
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      currentCoach: {
        type: Object,
        default: () => ({})
      },
      lockedList: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      name: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      isEditable() {
        return (this.currentCoach.role === favoriteLists.apiRights.admin
          || this.currentCoach.role === favoriteLists.apiRights.owner
          || (this.$route.query.subtype || '').includes('create')) &&
          !this.lockedList;
      },
      tooltip() {
        return {
          content: !this.isEditable ? this.$i18n.t('companies.panel-company.only-administrators-can-rename-list') : ''
        };
      }
    },
    watch: {
      value() {
        if (!this.$route.query['unsaved-changes']) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              'unsaved-changes': true
            }
          });
        }
      }
    }
  };
</script>

<style lang=scss scoped>
  .panel-favorite-list-name {
    flex-direction: column;

    &__title {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    &__input {
      @include bp('tablet') {
        width: 544px;
      }
    }
  }
</style>
