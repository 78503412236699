<script lang="ts" setup>
  import { defineEmits, defineProps } from 'vue';
  import { Process } from '@/domains/models/Process';
  import PanelProcessesItem from '@/components/Panel/PanelProcessesItem.vue';
  import { useRoute } from 'vue-router/composables';

  interface Props {
    processes: Process[];
  }

  const props = defineProps<Props>();
  const emits = defineEmits<{
    'update-process': (value: Process) => void,
    'remove-process': [{ value: string }]
  }>();
  const route = useRoute();

  const updateProcess = ({ index, process }) => {
    // processes.value[index] = { ...processes.value[index], ...process };
    emits('update-process', { index, process });
  };

  const removeProcessFromProcesses: (id: string) => void = (id: string) => {
    // processes.value = processes.value.filter(process => process._id !== id);
    emits('remove-process', { id });
  };
</script>

<template>
  <div class="flex w-full flex-row flex-wrap gap-2.5 text-sm">
    <PanelProcessesItem
      v-for="(process, index) in processes"
      :key="process._id"
      :process="process"
      @remove-process="removeProcessFromProcesses"
      @update-process="updateProcess({ index, process: $event })"
    />
    <p
      v-if="!processes.length"
      class="flex w-full grow items-center rounded-md bg-neutral-200 px-5 py-4 text-blue-800"
    >
      {{ $t('processes.nothing') }}
    </p>
  </div>
</template>
