<script lang="ts" setup>
  import { computed } from 'vue';
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  const props = withDefaults(
    defineProps<{
      text: string;
      isError: boolean;
      hasLimit?: boolean;
    }>(),
    {
      hasLimit: true,
    },
  );

  const emit = defineEmits<{
    (e: 'update-text', value: string): void;
  }>();

  // Regex pour détecter les variables {{...}}
  const variableRegex = /{{(.*?)}}/g;

  // Variable pour stocker l'instance de CKEditor
  let editorInstance = null;
  
  // Texte formaté avec les balises <span>
  const formattedText = computed(() => {
    return props.text.replace(
      variableRegex,
      (_, variable) => `<strong>{{${variable.trim()}}}</strong>`,
    );
  });

  // Configuration de CKEditor
  const editorConfig = {
    toolbar: {
      items: [],
    },
  };
  const editor = ClassicEditor;

  // Émettre les changements depuis CKEditor
  const onEditorChange = (data: string) => {
    emit('update-text', data.replace(/<strong.*?>|<\/strong>/g, '')); // Nettoie les balises <span> avant d'émettre
  };

  const onEditorReady = (editor: any) => {
    editorInstance = editor;
  };
</script>

<template>
  <div class="campaigns-rich-text-editor relative">
    <ckeditor
      :config="editorConfig"
      :editor="editor"
      :value="formattedText"
      class="w-full rounded-[5px] border border-blue-100 p-[20px] focus:border-blue-500 focus:outline-none"
      v-bind="$attrs"
      @input="onEditorChange($event)"
      @ready="onEditorReady">
    </ckeditor>
    <p class="absolute bottom-2 right-2 text-xs text-blue-400">
      {{ text.length }}/200 {{ $t('generics.characters') }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
  .campaigns-rich-text-editor {
    display: block;

    & :deep() {
      --ck-color-toolbar-border: #d9dffd;
      --ck-color-base-border: #d9dffd;
      --ck-border-radius: 5px;

      strong {
        @apply text-blue-400 font-normal;
      }

      div {
        display: block;
      }

      p {
        display: block;
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }

      .ck-editor__top {
        display: none;
      }

      .ck-editor__main {
        @apply rounded;
      }

      .ck-editor__editable {
        overflow: auto;
        @apply px-[20px] pt-[5px] pb-[15px];
      }

      .ck-rounded-corners {
        border-radius: 5px !important;
        border-top-left-radius: 5px !important;
        border-top-right-radius: 5px !important;
      }
    }
  }
</style>
