import notes from '@/common-old/macros/notes';

export const validateType = type => {
  const types = Object.values(notes.type);

  if (types.some(note => note.name === type)) {
    return;
  }

  throw new Error(`Le type spécifié n\'existe pas : ${type}`);
};

export default {
  validateType,
};
