<template>
  <mv-filter
    :label="$t('avatar-dropdown.job-title')"
    icon="users"
    :count="selected.length"
    align="left">
    <filter-content
      class="filter-coders-job-title__content"
      :label="$t('avatar-dropdown.job-title')"
      icon="users">
      <div class="is-align-items-center is-full-width">
        <bc-autocomplete
          :options="suggestions"
          :is-dropdown-full-width="false"
          @input="getSuggestions"
          class="filter-coders-job-title__input is-expanded"
          name="filter-coders-job-title"
          :placeholder="$t('placeholder.example-recruiter')"
          v-model="search">
          <template #option="{ option }">
            <bc-dropdown-item
              :text="option.value"
              :is-selected="false"
              @click.native.stop="onSelect(option)"
              class="filter-coders-job-title__option font-size-s is-expanded">
            </bc-dropdown-item>
          </template>
        </bc-autocomplete>
      </div>
      <div v-if="selected && selected.length" class="filter-coders-job-title__tags">
        <tag
          v-for="selectedValue in selected"
          :key="selectedValue"
          :text="selectedValue"
          @click.native.stop="onPressTag(selectedValue)"
          can-close />
      </div>
    </filter-content>
  </mv-filter>
</template>

<script>
import MvFilter from '@/ui-kit/components/MvFilter/MvFilter';
import FilterContent from '@/components/Filter/FilterContent';
import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete.vue';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import Tag from '@/components/Tag/Tag';

export default {
  name: 'filter-coders-job-title',
  components: {
    BcAutocomplete,
    BcDropdownItem,
    Tag,
    MvFilter,
    FilterContent,
  },
  data() {
    return {
      search: '',
      suggestions: [],
    }
  },
  computed: {
    selected() {
      if (!this.$route.query.search) {
        return [];
      }
      return this.$route.query.search.split(',')
    },
  },
  methods: {
    getSuggestions(value) {
      this.suggestions = [{ value }];
    },
    onSelect(option) {
      this.search = '';
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          search: [...this.selected, option.value].join(',')
        },
      });
    },
    isSelected() {
      return false;
    },
    onPressTag(tag) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          search: [...this.selected.filter((value) => value !== tag)].join(',') || undefined
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.filter-coders-job-title {
  &__content {
    min-width: 574px;

    &:deep() {
      .bc-input {
        margin-top: 0;
      }
    }
  }

  &__tags {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
}
</style>
