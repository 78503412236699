<template>
  <div
    ref="dropdown"
    class="bc-dropdown is-column is-relative"
    @mouseleave="handleMouseLeave">
    <div
      ref="reference"
      :class="{ 'bc-dropdown__input--disable': disabled }"
      class="bc-dropdown__input is-align-items-center border-radius-s"
      @click="toggleDropdown"
      @mouseover="handleMouseEnter">
      <!-- @slot The slot for the Label of the dropdown -->
      <slot
        :is-dropdown-open="isDropdownOpen"
        name="label">
      </slot>
    </div>
    <transition :name="isPopper ? 'fade' : 'fade-select'">
      <div
        v-show="isDropdownOpen"
        ref="menu"
        :class="{
          'bc-dropdown__menu--opened-top': isReactive && !isListInViewportVertically && !isPopper || position.includes('top') && !isPopper || !isReactive && position.includes('top'),
          'bc-dropdown__menu--opened-bottom': isReactive && isListInViewportVertically && position.includes('bottom') && !isPopper || !isReactive && position.includes('bottom'),
          'bc-dropdown__menu--scrollable': isScrollable,
          'is-left': position.includes('right') || position.includes('center'),
          'is-right': position.includes('left') || position.includes('center'),
          'bc-dropdown__menu--full': isExpanded,
          'border-radius-s': isPopper
        }"
        class="bc-dropdown__menu is-absolute border-radius-s">
        <div
          ref="dropdown"
          :class="{
            'bc-dropdown__content--scrollable': isScrollable,
          }"
          class="bc-dropdown__content is-flex is-column border-radius-s shadow-default">
          <div
            v-for="(option, index) in options"
            :key="index"
            class="bc-dropdown__option is-flex is-align-items-center"
            @click="handleSelect"
            @mouseenter="activeIndex = index">
            <!-- @slot The slot for the Options of the dropdown -->
            <slot
              :active-index="activeIndex"
              :index="index"
              :option="option"
              name="option">
            </slot>
          </div>
          <div
            v-if="options.length === 0 && ($slots.custom || $scopedSlots.custom)"
            class="is-align-items-center is-column border-radius-s shadow-default"
            @click="handleSelect">
            <!-- @slot The slot for a Custom content of the dropdown -->
            <slot
              :close-dropdown="closeDropdown"
              :is-dropdown-open="isDropdownOpen"
              name="custom">
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import Popper from 'popper.js';

  import dropdown from '../../mixins/dropdown';
  import { onClickOutside } from '@vueuse/core';

  export default {
    name: 'bc-dropdown',
    mixins: [dropdown],
    inheritAttrs: false,
    props: {
      /**
       * Disable the label
       */
      disabled: {
        type: [Boolean, String],
        default: false,
      },

      /**
       * Hide the dropdown arrow
       */
      isArrow: {
        type: Boolean,
        default: true,
      },

      /**
       * Enable the dropdown menu open on hover
       */
      isHoverable: {
        type: Boolean,
        default: false,
      },

      /**
       * The Array of the options of the dropdown
       */
      options: {
        type: Array,
        default: () => [],
      },

      /**
       * Set manually the active option (it overrides activeIndex)
       */
      isActiveOption: {
        type: Number,
        default: -1,
      },

      /**
       * The opened Position of the dropdown menu
       */
      position: {
        type: String,
        default: 'bottom-right',
        validator: value => ['bottom-left', 'bottom-right', 'top-right', 'top-left', 'top-center', 'bottom-center'].indexOf(value) >= 0,
      },

      /**
       * Force the dropdown menu to close after option select
       */
      closeDropdownAtSelect: {
        type: Boolean,
        default: true,
      },

      /**
       * Set the dropdown menu has the width of the input
       */
      isExpanded: {
        type: Boolean,
        default: false,
      },

      /**
       * Switch the $emit mode of the option: emit the option Object instead of his Index
       */
      emitObject: {
        type: Boolean,
        default: false,
      },

      /**
       * Make the dropdown menu scrollable with a max height
       */
      isScrollable: {
        type: Boolean,
        default: true,
      },

      /**
       * Enable fixed position of dropdown with popper plugin
       */
      isPopper: {
        type: Boolean,
        default: false,
      },

      /**
       * Set a boundary to force the dropdown menu to open inside the boundary. Prevent the dropdown menu to open outside the screen for example.
       */
      boundariesElement: {
        type: [String, Object],
        default: 'viewport',
        validator: value => ['viewport', 'scrollParent', 'window', 'portal'].indexOf(value) >= 0 || typeof value === 'object',
      },

      isReactive: {
        type: Boolean,
        default: true,
      },

      /**
       * Set manually the dropdown to toggle (in cases we need special event from the parent).
       */
      isOpen: {
        type: Boolean,
        default: false,
      },
      /**
       * Force dropdown's position.
       */
      forceDirection: {
        type: Boolean,
        default: false,
      },
      isFilter: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        activeIndex: this.isActiveOption || -1,
        isDropdownOpen: false,
        isListInViewportVertically: true,
        popperInstance: {},
      };
    },
    watch: {
      isOpen(value) {
        this.isDropdownOpen = value;
      },
      isDropdownOpen(value) {
        this.calcDropdownInViewportVertical();

        if (value) {
          document.addEventListener('keydown', this.handleKeypressEventDefault);
          document.addEventListener('keyup', this.handleKeyPressEsc);

          onClickOutside(this.$refs.dropdown, () => {
            this.isDropdownOpen = false;
          });

          if (this.isPopper) {
            this.$nextTick(() => {
              this.popperInstance = new Popper(this.$refs.reference, this.$refs.menu, {
                placement: 'bottom-start',
                positionFixed: true,
                removeOnDestroy: true,
                modifiers: {
                  preventOverflow: {
                    boundariesElement: this.boundariesElement,
                    padding: 0,
                  },
                  offset: {
                    offset: '0, 5',
                  },
                },
              });
            });
          }
        } else {
          document.removeEventListener('keydown', this.handleKeypressEventDefault);
          document.removeEventListener('keyup', this.handleKeyPressEsc);
        }
        this.$emit('on-toggle', value);
      },
    },
    methods: {
      toggleDropdown() {
        if (this.disabled) {
          return;
        }

        this.isDropdownOpen = !this.isDropdownOpen;
      },
      handleSelect() {
        if (!this.isDropdownOpen || !this.closeDropdownAtSelect) {
          return;
        }

        this.isDropdownOpen = false;
      },
      closeDropdown() {
        if (!this.isDropdownOpen) {
          return;
        }

        this.isDropdownOpen = false;
        /**
         * v-click-outside -> Emit that the dropdown has been closed
         *
         * @event close-dropdown
         * @type {Boolean}
         */
        this.$emit('close-dropdown', false);
      },
      handleMouseLeave() {
        if (!this.isHoverable) {
          return;
        }

        if (this.isDropdownOpen) {
          this.isDropdownOpen = false;
        }
      },
      handleMouseEnter() {
        if (!this.isHoverable) {
          return;
        }

        if (!this.isDropdownOpen) {
          this.isDropdownOpen = true;
        }
      },
      handleKeypressEventDefault(event) {
        const selector = 'div.bc-dropdown__option';

        if (event.key === 'ArrowUp' && this.activeIndex > 0) {
          --this.activeIndex;
          this.setScroll(this.activeIndex, selector);
          event.preventDefault();
        } else if (event.key === 'ArrowDown' && this.activeIndex < this.options.length - 1) {
          ++this.activeIndex;
          this.setScroll(this.activeIndex, selector);
          event.preventDefault();
        } else if (event.key === 'Enter') {
          const data = this.emitObject ? this.options[this.activeIndex] : this.activeIndex;

          /**
           * keypressEnter -> Emit the index of the option (or the Object thanks to emitObject prop)
           *
           * @event change
           * @type {Number, Object}
           */
          this.$emit('change', data);

          if (this.closeDropdownAtSelect) {
            setTimeout(() => {
              this.isDropdownOpen = false;
            });
          }
        }
      },
      handleKeyPressEsc(event) {
        if (this.isDropdownOpen && event.key === 'Escape') {
          this.isDropdownOpen = false;
        }
      },
      calcDropdownInViewportVertical() {
        if (!this.forceDirection) {
          this.isListInViewportVertically = true;

          this.$nextTick(() => {
            if (!this.$refs.hasOwnProperty('menu')) {
              return;
            }

            const rect = this.$refs.menu.getBoundingClientRect();

            this.isListInViewportVertically = (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight));
          });
        }
      },
      clickOutside() {
        this.isDropdownOpen = false;
      },
    }
  };
</script>

<style lang="scss" scoped>
  $dropdown-color: $color-secondary;

  .bc-dropdown {
    color: $dropdown-color;

    &__input {
      background: transparent;
      font-family: inherit;

      &--disabled {
        pointer-events: none;
      }
    }

    &__menu {
      z-index: 5;
      cursor: pointer;

      &--scrollable {
        max-height: 260px;
      }

      &--opened-bottom {
        top: 100%;
        padding-top: 5px;
      }

      &--opened-top {
        bottom: 100%;
        padding-bottom: 5px;
      }

      &--full {
        width: 100%;
      }
    }

    &__content {
      flex-grow: 1;
      background: $color-white;
      overflow: hidden;

      &--scrollable {
        overflow: auto;
      }
    }

    &__option {
      flex-shrink: 0;

      &:not(:last-child) {
        border-bottom: 1px solid $color-grey;
      }
    }
  }
</style>
