<template>
  <div class="container">
    <configuration-multicast-tabs></configuration-multicast-tabs>
    <views-container>
      <div class="configuration-jobboards">
        <router-view></router-view>
        <div v-if="!careerActivated" class="configuration-jobboards__warning-container">
          <p class="configuration-jobboards__warning"></p>⚠️ {{ $t('features.should-activate-career') }}
        </div>
        <div class="configuration-jobboards__titles">
          <p style="width: 40%;" class="pl-2">ID</p>
          <p style="width: 20%;">{{ $t('generics.status') }}</p>
          <p style="width: 30%;">{{ $t('generics.subscription-models') }}</p>
          <p style="width: 10%;">{{ $t('generics.edit-2') }}</p>
        </div>
        <!-- <div v-if="isLoading && !activatedChannels.length && configurationFeatures.multidiffusion" class="configuration-jobboards__items-loader">
          <bc-spinner></bc-spinner>
        </div> -->
        <div class="configuration-jobboards__items">
          <configuration-jobboards-item
            v-for="jobboard in jobboardsFiltred" :key="jobboard.id"
            :jobboard="jobboard"
            :channel-subscription="jobboard"
            :channel-subscription-config="getJobboardConfig(jobboard.connectorName)"
            :configuration-integrations="configurationIntegrations"
            :disabled="!careerActivated"
            @edit="onEdit"
          ></configuration-jobboards-item>
          <div v-if="!jobboardsFiltred.length" class="configuration-jobboards__empty">
            <p>{{ $t('jobboards.empty') }}</p>
          </div>
        </div>
        <div v-if="!configurationFeatures.multidiffusion" class="configuration-jobboards__block-feature"></div>
      </div>
      <bc-modal
        :active="isModalOpen"
        @close="isModalOpen = false">
        <JobboardConfigChannel
          :channel="selectedJobboard"
          :config="selectedConfig"
          @submit="onSubmitChannel"
          @cancel="isModalOpen = false"
        ></JobboardConfigChannel>
      </bc-modal>
    </views-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { signIn } from '@/api/job-multipost';

import BcModal from '@/ui-kit/components/BcModal/BcModal';
import ViewsContainer from '@/components/Views/ViewsContainer';
import ConfigurationMulticastTabs from '@/components/Configuration/Tabs/ConfigurationMulticastTabs';
import ConfigurationJobboardsItem from '@/components/Configuration/ConfigurationJobboardsXtramile/ConfigurationJobboardsItem';
import JobboardConfigChannel from '@/components/Jobboard/JobboardConfigChannel/JobboardConfigChannel';

export default {
  name: 'configuration-jobboards',
  components: {
    BcModal,
    ViewsContainer,
    ConfigurationMulticastTabs,
    ConfigurationJobboardsItem,
    JobboardConfigChannel,
  },
  data() {
    return {
      isModalOpen: false,
      selectedJobboard: {},
      jobboards: [
        {
          id: 'hellowork',
          connectorName: 'hellowork',
          title: 'Hellowork',
          isInternal: true,
        },
        {
          id: 'indeed',
          connectorName: 'indeed',
          title: 'Indeed',
          isInternal: true,
        },
        {
          id: 'website',
          connectorName: 'website',
          title: 'Site web',
          isInternal: true,
        },
      ]
    }
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
    }),
    ...mapGetters({
      configurationFeatures: 'user/configurationFeatures',
    }),
    jobboardsFiltred() {
      return this.jobboards.filter(jobboard => this.getJobboardConfig(jobboard.connectorName)?.activated);
    },
    configurationIntegrations() {
      return this.profile?._organization?.configuration?.integrations || {};
    },
    careerActivated() {
      return this.profile?._organization?.configuration?.career?.activated;
    },
    selectedConfig() {
      return this.configurationIntegrations?.job_multiposting?.[this.selectedJobboard?.connectorName] || {}
    },
  },
  methods: {
    ...mapActions({
      updateConfigurationMultiposting: 'user/updateConfigurationMultiposting',
    }),
    onEdit(jobboard) {
      this.selectedJobboard = jobboard;
      this.isModalOpen = true;
    },
    getJobboardConfig(name) {
      return this.configurationIntegrations?.job_multiposting?.[name] || {}
    },
    async onSubmitChannel({ jobboard, params, credentials }) {
      const { data: config } = await signIn({
        jobboard,
        credentials,
        params,
      });
      this.updateConfigurationMultiposting({ key: jobboard, ...config });
      this.isModalOpen = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  flex-direction: column;
}

.configuration-jobboards {
  width: 100%;
  flex-direction: column;
  gap: 10px;
  position: relative;

  &__titles {
    padding: 5px 30px;

    & > p {
      color: $color-blue-heavy-dark;
      font-size: 13px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  &__items {
    position: relative;
    flex-direction: column;
    gap: 5px;

    &-loader {
      justify-content: center;
      align-items: center;
      min-height: 100px;
    }
  }

  &__block-feature {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4fd 0%, #fafbff 100%);
    opacity: 0.5;
  }

  &__warning {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    margin-bottom: 15px;
    color: $color-blue-dark-cello;

    &-container {
      width: 100%;
      justify-content: flex-end;
      margin-bottom: 5px;
    }
  }

  &__empty {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    color: $color-tertiary;
  }
}
</style>

<style lang="scss">
  .tooltip-custom {
    height: 60vh;

    .tooltip-inner {
      max-width: 100%;
      height: 100%;
    }
  }
</style>
