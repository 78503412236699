<template>
  <div>
    <BcDropdown
      :close-dropdown-at-select="true"
      :is-hoverable="true"
      :is-scrollable="false"
      :options="laterOptions"
      position="bottom-left"
      class="reminders-buttons-unarchive__dropdown is-expanded">
      <template #label>
        <BcButton
          class="reminders-buttons-unarchive__button mx-[5px]"
          color="warning"
          icon-left="refresh"
          size="large"
          data-reminders-later-button
          @click="emitUnarchive">
        </BcButton>
      </template>
      <template #option="{ option }">
        <v-date-picker
          v-if="option.code === 'customNbDays'"
          v-model="customDate"
          :locale="locale"
          :min-date="minDate"
          :popover="{ visibility: 'hover', positionFixed: true, placement: 'left' }"
          class="w-full">
          <template #default="{ togglePopover }">
            <BcDropdownItem
              class="reminders-buttons-unarchive__dropdown-item"
              :text="option.text"
              @click.native="
                () => {
                  togglePopover();
                  emitUnarchive(option);
                }
              " />
          </template>
        </v-date-picker>
        <BcDropdownItem
          v-else
          class="reminders-buttons-unarchive__dropdown-item"
          :text="option.text"
          @click.native="emitUnarchive(option)" />
      </template>
    </BcDropdown>
  </div>
</template>

<script setup lang="ts">
import moment from 'moment/min/moment-with-locales';
import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
import { onMounted, ref, watch, defineEmits } from 'vue';
import { useI18n } from '@/i18n/i18n';;
import { reminderOptions } from '@/macros/reminders/reminderOptions'

const emit = defineEmits<{
  (e: 'unarchive', value: { nbDays: number }): void;
}>();

const { t, locale } = useI18n();

const customDate = ref();
const minDate = ref();
const laterOptions = ref(reminderOptions.map(option => ({
  ...option,
  text: t(option.localeString),
})));

function emitUnarchive(option) {
  if (option.code === 'customNbDays') {
    return;
  }
  emit('unarchive', { nbDays: option.nbDays });
}

onMounted(() => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  minDate.value = tomorrow;
});

watch(customDate, date => {
  const nbDays = moment(date).diff(new Date(), 'days') + 1;
  emit('unarchive', { nbDays });
});
</script>
<style scoped lang="scss">
.reminders-buttons-unarchive {
  &__button {
    margin-left: 5px;
    margin-right: 5px;
    border-radius: $border-radius-rounded;
    width: 36px;
    height: 36px;
  }

  &__dropdown-item {
    &:hover,
    &--focus {
      background-color: $color-secondary;
    }
  }
}
</style>
