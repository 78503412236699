<template>
  <div
    :class="{'panel-chat-messages-box--reversed': isFromUser}"
    class="panel-chat-messages-box">
    <p
      v-if="!isFromUser && interlocutorName !== ''"
      class="panel-chat-messages-box__author">
      {{ interlocutorName }}
    </p>
    <div
      :class="{
        'panel-chat-messages-box__container--blue': isFromUser && (message.state !== 'pending' && message.state !== 'error'),
        'panel-chat-messages-box__container--light-blue': isFromUser && message.state && (message.state === 'pending' || message.state === 'error')
      }"
      class="panel-chat-messages-box__container">
      <p
        :class="{
          'panel-chat-messages-box__text--white': isFromUser,
          'panel-chat-messages-box__text--reversed': isFromUser
        }"
        class="panel-chat-messages-box__text"
        v-html="text">
      </p>
    </div>
    <p
      v-if="message.state === 'error'"
      :class="{'panel-chat-messages-box__error--reversed': isFromUser}"
      class="panel-chat-messages-box__error">
      Une erreur est survenue. Cliquez sur le message pour le renvoyer.
    </p>
  </div>
</template>

<script>
  /**
   * The chat text box
   */
  export default {
    name: 'panel-chat-messages-box',
    props: {
      /**
       * The text which will be displayed
       */
      text: {
        type: String,
        default: ''
      },
      /**
       * Determinate if the message is from user
       */
      isFromUser: {
        type: Boolean,
        default: false
      },
      interlocutorName: {
        type: String
      },
      message: {
        type: Object
      }
    }
  };
</script>

<style scoped lang="scss">
  .panel-chat-messages-box {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    &--reversed {
      align-items: flex-end;
    }
    @include bp('tablet') {
      max-width: 450px;
    }
    &__container {
      background: #1D395E1A;
      border-radius: 15px;
      padding: 10px;
      width: auto;
      flex-shrink: 0;
      justify-content: flex-start;
      max-width: 250px;
      &--light-blue {
        background: #3C80F7B3;
        justify-content: flex-end;
        &:hover {
          cursor: pointer;
        }
      }
      &--blue {
        background: $color-blue;
        justify-content: flex-end;
      }
    }
    &__text {
      font-weight: $font-weight-light;
      font-size: $font-size-xs;
      line-height: 15px;
      color: $color-blue-dark-cello;
      text-align: left;
      line-break: auto;
      white-space: pre-wrap;
      word-break: break-word;
      &--reversed {
        text-align: right;
      }
      &--white {
        color: $color-white;
      }
    }
    &__error {
      font-weight: $font-weight-light;
      font-size: $font-size-xxs;
      color: $color-red-mandy;
      word-break: break-word;
      text-align: left;
      margin-top: 5px;
      &--reversed {
        text-align: right;
      }
    }
    &__author {
      position: absolute;
      top: -20px;
      margin-left: 10px;
      font-weight: $font-weight-light;
      font-size: $font-size-xxs;
      line-height: 13px;
      color: $color-blue-dark-cello;
      opacity: 0.8;
    }
  }
</style>
