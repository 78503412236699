<template>
  <div class="bc-custom-field-string">
    <bc-input
      class="bc-custom-field-string__input"
      :value="text"
      :name="customField.key"
      :valid="text !== ''"
      type="text"
      @input="handleInput">
    </bc-input>
  </div>
</template>

<script>
import BcInput from '@/ui-kit/components/BcInput/BcInput';

export default {
  name: 'bc-custom-field-string',
  components: {
    BcInput,
  },
  props: {
    customField: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (this.selected) {
      this.text = this.selected;
    } else if (this.customField.defaultValue?.length) {
      this.text = this.customField.defaultValue;
    }
  },
  data() {
    return {
      text: '',
    };
  },
  methods: {
    handleInput(value) {
      this.text = value;
      this.$emit('on-update', { key: this.customField.key, value });
    },
  },
  watch: {
    selected(value) {
      this.text = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.bc-custom-field-string {
  width: 100%;

  @include bp('tablet') {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    flex-basis: 100%;
  }

  &__input {
    flex-basis: 100%;
    width: 100%;
    margin-top: 0;
  }

  & :deep() {
    .bc-input__input {
      width: 100%;
      padding-left: 15px;
    }
  }

  &__prepend {
    background: $color-grey-4;
    color: $color-white;
    height: 40px;
    padding: 0 10px;
    align-items: center;
    margin-right: -15px;
    font-size: $font-size-s;
    flex-shrink: 0;

    &--focus {
      background: $color-primary;
    }

    &--valid {
      background: $color-secondary;
    }
  }

  &__chevron {
    font-size: 10px;
    right: 0;
  }
}
</style>
