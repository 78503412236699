import timeline from '@/api/timeline';
import { serializeNote } from '@/managers/notes/formatters/noteSerializers';

export const createDraft = async({ userType, id, note }) => {
  const execute = ({ userType, id, note }) => {
    try {
      return timeline.createDraft({
        userType,
        id,
        note,
      });
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    const serializedNote = serializeNote(note);

    const { data } = await execute({
      userType,
      id,
      note: serializedNote,
    });

    return data;
  } catch (error) {
    throw error;
  }
};
