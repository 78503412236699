export default {
  methods: {
    $_updateCoder(coder) {
      const coderIndex = this.$store.state.card.cards.findIndex(card => card._id === coder._id);

      this.$emit('profile-updated', {
        processState: coder.processState,
        coachProcessState: coder.coachProcessState,
      });

      if (coderIndex >= 0) {
        this.$store.dispatch('updateCard', {
          index: coderIndex,
          content: {
            processState: coder.processState,
            coachProcessState: coder.coachProcessState,
          },
        });
      }
    },
  },
};
