<template>
  <div class="linkedin">
    <JobboardConfigItem
      :channel="channel"
      :subscriptionModel="subscriptionModel"
      :applicationMode="applicationMode"
      :applicationForwardingType="applicationForwardingType"
      :disabledSubscriptions="disabledSubscriptions"
      :disabledApplications="disabledApplications"
      :is-valid="isValid"
      @update-subscription-model="updateSubscriptionModel"
      @update-application-mode="updateApplicationMode"
      @update-application-forwarding-type="updateApplicationForwardingType"
      @cancel="$emit('cancel', $event)"
      @submit="onSubmit">
      <template #subscriptionContent>
        <div v-if="subscriptionModel && hasCompanyId" class="is-full-width mt-5 pt-3">
          <ContractIdField
            :label="'COMPANY_ID'"
            :placeholder="`Linkedin COMPANY_ID`"
            :warning="$t('jobboards.warning-login-linkedin')"
            v-model="COMPANY_ID"
          />
        </div>
      </template>
    </JobboardConfigItem>
  </div>
</template>

<script>
import { subscriptionsModels, applicationModes } from '@/api/xtramile';
import ContractIdField from '@/components/Jobboard/JobboardConfigChannel/Fields/ContractIdField';
import JobboardConfigItem from '@/components/Jobboard/JobboardConfig/JobboardConfigItem';

export default {
  name: 'linkedin',
  components: {
    ContractIdField,
    JobboardConfigItem,
  },
  props: {
    channel: {
      type: Object,
      default: () => ({}),
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    disabledSubscriptions: {
      type: Array,
      default: () => [],
    },
    disabledApplications: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    if (this.values?.params) {
      this.subscriptionModel = this.values.params.subscriptionModel;
      this.applicationMode = this.values.params.applicationMode;
      this.applicationForwardingType = this.values.params.applicationForwardingType;
      this.COMPANY_ID = this.values.params.accountParams.COMPANY_ID;
    }
  },
  data() {
    return {
      subscriptionsModels,
      applicationModes,
      COMPANY_ID: '',
      subscriptionModel: '',
      applicationMode: '',
      applicationForwardingType: '',
    }
  },
  computed: {
    hasCompanyId() {
      return [this.subscriptionsModels.organic, this.subscriptionsModels.credits].includes(this.subscriptionModel);
    },
    isSubscriptionValid() {
      if (!this.subscriptionModel) return false;
      if (this.hasCompanyId) {
        return !!this.COMPANY_ID;
      }
      return true;
    },
    isApplicationValid() {
      if (!this.applicationMode) return false;
      return true;
    },
    isValid() {
      return this.isSubscriptionValid && this.isApplicationValid;
    }
  },
  methods: {
    updateSubscriptionModel(key) {
      this.subscriptionModel = key;
    },
    updateApplicationMode(key) {
      this.applicationMode = key;
    },
    updateApplicationForwardingType(value) {
      this.applicationForwardingType = value;
    },
    onSubmit() {
      this.$emit('submit', {
        channelId: this.channel.id,
        subscriptionModel: this.subscriptionModel,
        applicationMode: this.applicationMode,
        applicationForwardingType: this.applicationForwardingType,
        accountParams: {
          COMPANY_ID: this.COMPANY_ID,
        }
      })
    }
  }
}
</script>
