<script lang="ts" setup>
  import { computed, defineEmits, defineProps, withDefaults } from 'vue';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import { TranslateResult } from 'vue-i18n';

  interface Props {
    title: TranslateResult;
    canDelete: boolean;
    disabled: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    title: '',
    canDelete: false,
    disabled: false,
  });

  const emits = defineEmits<{
    (e: 'on-close'): void,
    (e: 'on-delete'): void,
  }>();

  const hasActions = computed(() => {
    return props.canDelete;
  });

  const onClose = () => emits('on-close');
</script>

<template>
  <div class="modal-templates-note-header">
    <button @click="onClose">
      <span class="icon-cross"></span>
    </button>
    <p>{{ title }}</p>
    <div v-if="hasActions" class="modal-templates-note-header__actions">
      <div v-tooltip="{ content: disabled ? $t('templates.cannot-modify') : ''}">
        <BcButton
          v-if="canDelete"
          :disabled="disabled"
          color="error"
          icon-center="trash"
          size="small"
          @click="$emit('on-delete')"
        ></BcButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .modal-templates-note-header {
    min-height: 50px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $color-blue-light;

    & > button {
      position: absolute;
      left: 18px;
      align-items: center;
      color: $color-blue-heavy-dark;
    }

    & > p {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      color: $color-blue-dark-cello;
    }

    &__actions {
      position: absolute;
      right: 20px;
      gap: 10px;
    }
  }
</style>
