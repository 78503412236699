export default {
  data() {
    return {
      /**
       * It sets which element of the list is focused
       */
      pointer: 0,
      itemsLimit: 5
    };
  },
  methods: {
    /**
     * Define the input in the store which the matches will be calculated to
     */
    setAutocompleteInputValue(value) {
      this.$store.dispatch('setAutocompleteInputValue', value);
    },
    /**
     * Define the array that the matches will be calculated to
     */
    setSuggestions(suggestions) {
      this.$store.dispatch('setSuggestions', suggestions);
    },
    /**
     * When this method is called, it delete the last element of the indicated array
     */
    deleteLastTag(property, input) {
      if (input.length === 0) {
        property.pop();
      }
    },
    /**
     * It suppress the tag
     */
    deleteTag(property, index) {
      property.splice(index, 1);
    },
    /**
     * It add a tag if the result of matches is > 0 and if there is something written in the input
     */
    addTag(property, input, object) {
      if (this.matches.length > 0 && input.length > 0) {
        property.push(object);
      }
    },
    /**
     * It add a tag but without the condition
     */
    clickSuggestion(property, object) {
      property.push(object);
    },
    /**
     * It sets which element of the dropdown is highlighted
     */
    setPointer(index) {
      this.pointer = index;
    },
    /**
     * It increment the value of the pointer
     */
    incrementPointer(matches) {
      const matchesArray = matches || this.matches;
      if (this.pointer < matchesArray.length - 1) {
        ++this.pointer;
      }
    },
    /**
     * It decrement the value of the pointer
     */
    decrementPointer() {
      if (this.pointer > 0) {
        --this.pointer;
      }
    }
  }
};
