export const linkedinCompanyUrl = 'https://www.linkedin.com/company/';
export const linkedinSchoolUrl = 'https://www.linkedin.com/school/';
export const linkedinPersonUrl = 'https://www.linkedin.com/in/';
export const wttjCompanyUrl = 'https://www.welcometothejungle.com/fr/companies/';
export const twitterProfileUrl = 'https://twitter.com/';
export const societeDotComCompanyProfileUrl = 'https://societe.com/societe/';

export default {
  linkedinCompanyUrl,
  linkedinSchoolUrl,
  linkedinPersonUrl,
  wttjCompanyUrl,
  twitterProfileUrl,
  societeDotComCompanyProfileUrl,
};
