import { http } from '@/api/index';

export const getCompanyContracts = id => {
  return http.get(`/partners/${id}/contracts`);
};

export const createCompanyContract = ({ id, data }) => {
  return http.post(`/partners/${id}/contracts`, data);
};

export const removeCompanyContract = id => {
  return http.delete(`/contracts/${id}`);
};

export default {
  getCompanyContracts,
  createCompanyContract,
  removeCompanyContract,
};
