<template>
  <div
    v-if="logoUrl"
    :style="`background-image: url(${logoUrl}); background-position: center; background-size: contain; background-repeat: no-repeat;`"
    class="jobboard-logo"
    :class="{ 'jobboard-logo--lg': size === 'lg' }">
  </div>
  <div v-else-if="staticLogoUrl" class="jobboard-logo" :class="{ 'jobboard-logo--lg': size === 'lg' }">
    <img :alt="connectorName" :src="staticLogoUrl" />
  </div>
  <div v-else-if="connectorName" class="jobboard-logo items-center justify-center font-bold text-blue-800" :class="{ 'jobboard-logo--lg': size === 'lg' }">
    <span>{{ connectorName.charAt(0) }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';

const props = defineProps<{
  logoUrl?: string | null;
  connectorName?: string;
  size?: 'm' | 'lg',
}>();
const staticLogoUrl = computed(() => {
  if (!props.connectorName) return undefined;
  try {
    const possibleLogoUrl = new URL(`../../assets/logo/jobboards/${props.connectorName?.toLowerCase()}.png`, import.meta.url).href;
    if (possibleLogoUrl.endsWith('undefined')) return undefined;
    return possibleLogoUrl;
  } catch (err) {
    return undefined;
  }
});
</script>

<style lang="scss" scoped>
.jobboard-logo {
  width: 27px;
  height: 27px;
  background: white;
  border-radius: 100%;
  border: 1px solid #f2f4fd;

  &--lg {
    width: 32px !important;
    height: 32px !important;
  }
}
</style>
