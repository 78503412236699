const hostname = window.location.hostname;

const urlMap = {
  'app.marvinrecruiter.com': 'PROD',
  'preprod.marvinrecruiter.com': 'PREPROD',
  'review.marvinrecruiter.com': 'PREPROD',
}

export function getEnv() {
  return urlMap[hostname] ? urlMap[hostname] : 'LOCAL';
}
