<script setup lang="ts">
import { defineProps, withDefaults, computed, ref, defineEmits } from 'vue';
import { useStore } from '@/store';

import { Process } from '@/domains/models/Process';
import { useRouter } from 'vue-router/composables';
import BcButton from '@/ui-kit/components/BcButton';
import Tag from '@/components/Tag/Tag';
import JobboardLogo from '@/components/Jobboard/JobboardLogo';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';

const LINKEDIN_LOGO = require('@/assets/logo/linkedin.png');
const PDF_LOGO = require('@/assets/logo/files/pdf.png');
const DEFAULT_AVATAR = require('@/assets/img/default-avatar.svg');
const DEFAULT_COMPANY_AVATAR = require('@/assets/img/default-avatar-company.svg');

const props = withDefaults(
  defineProps<{
    process: Process;
    filter: 'new' | 'treated' | undefined;
    selected: boolean;
  }>(),
  {
    process: {},
    filter: undefined,
    selected: false,
  }
);

const emit = defineEmits<{
  (e: 'checked', { checked: boolean, process: Process }): void;
  (e: 'on-accept', process: Process, coder: any): void;
  (e: 'on-reject', process: Process, coder: any): void;
}>();

const store = useStore();
const router = useRouter();
const isHover = ref<boolean>(false);

const phone = computed(() => props.process?._coder?.phone || props.process?._coder?.phones?.[0]);
const email = computed(() => props.process?._coder?.email || props.process?._coder?.emails?.[0]);
const hasDocument = computed(() => (props.process?._coder?._documents?.length || 0) > 0);
const careerLogoUrl = computed(() => store?.state?.user?.profile?._organization?.configuration?.career?.logoUrl);

const onChecked = (checked: boolean) => {
  emit('checked', { checked, process: props.process });
}

const openLinkedinTab = () => {
  const linkedinUrl = props.process?._coder?.social?.linkedin;
  if (linkedinUrl) {
    window.open(props.process?._coder?.social?.linkedin, '_blank');
  }
}

const onReject = () => {
  emit('on-reject', props.process, props.process._coder);
}

const onAccept = () => {
  emit('on-accept', props.process, props.process._coder);
}

const onOpenCandidatePanel = () => {
  if (isHover.value) return;

  router.push({
    name: 'ApplicationsPanel',
    query: {
      type: 'coders',
      category: 'my-notes',
      processId : props.process._id,
    },
    params: {
      id: props.process._coder._id,
      from: 'panel',
    }
  });
}

const openPdfTab = () => {
  const document = props.process._coder?._documents?.[0];
  if (document) {
    router.push({
      name: 'ApplicationsPanel',
      query: {
        type: 'coders',
        category: 'documents',
      },
      params: {
        id: props.process._coder._id,
      }
    });
  }
}

const onAvatarError = (event: any) => {
  event.target.src = DEFAULT_AVATAR;
}

const onCompanyAvatarError = (event: any) => {
  event.target.src = DEFAULT_COMPANY_AVATAR;
}

const getFullName = (firstName: string, lastName: string) => {
  const fullName = `${firstName} ${lastName}`;
  if (fullName.length > 20) {
    return fullName.substring(0, 20) + '...';
  }
  return fullName;
}

</script>

<template>
  <div class="flex items-center rounded border border-blue-200 bg-neutral-100 p-4 hover:cursor-pointer" @click="onOpenCandidatePanel">
    <div
      class="mr-2 flex w-[2%] max-w-[2%] justify-center"
      @mouseenter="isHover = true"
      @mouseleave="isHover = false"
    >
      <BcCheckbox
        :id="`candidate_${process._id}`"
        :value="selected"
        class="is-uppercase font-size-s"
        @input="onChecked">
      </BcCheckbox>
    </div>
    <div class="flex w-1/6 flex-col">
      <p class="font-medium">{{ process._coder?.firstName }} {{ process._coder?.lastName }}</p>
      <p class="max-w-[12rem] truncate">{{ process._coder?.title }}</p>
    </div>
    <div class="w-[5%]">
      <JobboardLogo
        v-if="process.source"
        v-tooltip="process.source?.name"
        size="lg"
        :logoUrl="process.source?.name === 'career' ? careerLogoUrl : undefined"
        :connectorName="process.source?.name && process.source?.name !== 'career' ? process.source.name  : undefined"
      />
    </div>
    <div class="flex w-1/5 flex-col">
      <p class="font-bold">{{ process._customJob?.title?.length > 40 ? `${process._customJob?.title.substring(0, 40)}...` : process._customJob?.title }}</p>
      <div class="mt-1">
        <Tag
          type="company"
          :text="process._company?.name?.length > 22 ? `${process._company?.name?.substring(0, 22)}...` : process._company?.name"
          :avatar="process._company?.logoUrl || DEFAULT_COMPANY_AVATAR"
          @error="onCompanyAvatarError"
        />
      </div>
    </div>
    <div
      class="ml-3 flex w-[10%] items-center gap-2"
      @mouseenter="isHover = true"
      @mouseleave="isHover = false"
    >
      <div
        class="size-[30px] items-center justify-center rounded-full bg-blue-100 hover:cursor-pointer"
        :class="{ 'opacity-50': !process?._coder?.social?.linkedin }"
        @click="openLinkedinTab"
      >
        <img class="size-[15px] object-contain" :src="LINKEDIN_LOGO" />
      </div>
      <v-popover
        class="z-20"
        :disabled="!hasDocument"
        :placement="'bottom'"
        :trigger="'click hover'"
        popover-base-class="z-20"
        popover-inner-class="p-5 h-[60vh]"
      >
        <div
          class="size-[30px] items-center justify-center rounded-full bg-red-50 hover:cursor-pointer"
          :class="{ 'opacity-50': !hasDocument }"
          @click="openPdfTab"
        >
          <img class="size-[15px] object-contain" :src="PDF_LOGO" />
        </div>
        <template #popover>
          <iframe
            v-if="process._coder?._documents?.[0]?.url?.endsWith('.docx') || process._coder?._documents?.[0]?.url?.endsWith('.doc')"
            class="h-[70vh] w-[500px]"
            frameborder="0"
            :src="`https://docs.google.com/gview?url=${process._coder?._documents?.[0]?.url}&embedded=true`"
          />
          <iframe
            v-else
            class="h-[70vh] w-[500px]"
            frameborder="0"
            :src="`${process._coder?._documents?.[0]?.url}`"
          />
        </template>
      </v-popover>
    </div>
    <div class="flex w-1/6 flex-col gap-2">
      <div>
        <Tag v-if="phone" icon="phone" :text="phone" />
      </div>
      <div>
        <Tag v-if="email" icon="mail" :text="email" />
      </div>
    </div>
    <div v-if="filter === 'new'" class="flex flex-1" @mouseenter="isHover = true" @mouseleave="isHover = false">
      <div class="flex w-full justify-end">
        <BcButton type="transparent" class="font-bold text-blue-500" @click="onReject">
          {{ $t('global.reject') }}
        </BcButton>
        <BcButton iconLeft="check" @click="onAccept">
          {{ $t('generics.accept') }}
        </BcButton>
      </div>
    </div>
    <div
      v-if="filter === 'treated'"
      class="flex flex-1"
      @mouseenter="isHover = true"
      @mouseleave="isHover = false"
    >
      <div class="ml-20 flex w-full">
        <div class="flex w-fit flex-col justify-end gap-1">
          <div class="gap-1">
            <Tag v-if="process?.acceptedAt" :text="'Accepté'" icon="check" type="success" />
            <Tag v-if="process?.rejectedAt" :text="'Rejeté'" icon="cross" type="danger" />
          </div>
          <Tag
            v-if="process._coach" :avatar="process._coach?.pictureUrl || DEFAULT_AVATAR"
            :text="getFullName(process._coach.firstName, process._coach.lastName)"
            @error="onAvatarError"
          />
        </div>
      </div>
    </div>
  </div>
</template>
