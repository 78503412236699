export const validateRating = rating => {
  if (rating >= 0 && rating <= 5) {
    return;
  }

  throw new Error('La note spécifiée est invalide');
};

export default {
  validateRating,
};
