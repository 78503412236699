<template>
  <div class="is-column is-align-items-center candidate-pipe-card-list is-full-width">
    <bc-pagination
      :current="Number($route.query.page)"
      :total="candidatesCount"
      @change="changePage"
      v-show="shouldDisplayPagination">
    </bc-pagination>
    <bc-spinner
      class="candidate-pipe-card-list__spinner"
      v-if="isLoading">
    </bc-spinner>
    <div
      class="is-align-items-center is-column is-full-width"
      v-else-if="shouldDisplayList">
      <coders-grid
        :is-checked="isAllCardsSelected"
        @checked="selectAllCards">
      </coders-grid>
      <card-list
        :cards="candidates"
        class="candidate-pipe-card-list__wrapper">
        <template #card="{ card }">
          <card
            :card-content="card"
            card-type="coders">
            <card-coders
              :card-content="card"
              :is-selected="getIsSelectedStatus(card._id)"
              @checked="setSelectedCards($event, card)"
              context="inbox">
            </card-coders>
          </card>
        </template>
      </card-list>
    </div>
    <p
      class="candidate-pipe-card-list__wrapper candidate-pipe-card-list__text"
      v-else>
      {{ $t('companies.panel-company.no-candidates-in-this-stage-of-your-pipe') }}
    </p>
    <bc-pagination
      :current="Number($route.query.page)"
      :total="candidatesCount"
      @change="changePage"
      v-show="shouldDisplayPagination">
    </bc-pagination>
    <multiselect-footer
      class="candidate-pipe-card-list__footer is-fixed is-bottom is-left is-full-width is-justify-content-center is-align-items-center is-column"
      v-if="selectedCards.length">
    </multiselect-footer>
  </div>
</template>

<script>
  import { mapState } from 'vuex'; //
  //
  import BcPagination from '@/ui-kit/components/BcPagination/BcPagination';
  import Card from '@/components/Card/Card';
  import CardList from '@/components/CardList/CardList';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import CodersGrid from '@/components/Coders/CodersGrid';
  import CardCoders from '@/components/Card/CardCoders';
  import MultiselectMixin from '@/mixins/Multiselect/multiselect';
  import MultiselectFooter from '@/components/Multiselect/MultiselectFooterLegacy';

  export default {
    name: 'candidate-pipe-card-list',
    components: { MultiselectFooter, Card, CardCoders, CodersGrid, BcSpinner, CardList, BcPagination },
    mixins: [MultiselectMixin],
    props: {
      candidatesCount: {
        type: Number,
        default: 0,
        required: true,
      },
      isLoading: {
        type: Boolean,
        default: false,
        required: true,
      },
    },
    computed: {
      ...mapState({
        candidates: state => state.card.cards,
      }),
      shouldDisplayList() {
        return !this.isLoading && this.candidates.length;
      },
      shouldDisplayPagination() {
        return this.candidates.length;
      },
    },
    beforeDestroy() {
      this.resetSelectedCards();
    },
    methods: {
      changePage(page) {
        this.$router.push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page,
          },
        });
      },
    },
  };
</script>

<style lang=scss scoped>
  .candidate-pipe-card-list {
    margin-top: 40px;

    &__wrapper {
      margin: 0 0 30px;
    }

    &__spinner {
      margin: 100px 0;
    }

    &__text {
      color: $color-secondary;
    }
  }
</style>
