import macrosRoles from '../../macros/coaches/roles';

export default {
    business: {
        type: 1,
        roles: [
            macrosRoles.apiRoles.coach, 
            macrosRoles.apiRoles.mentor, 
            macrosRoles.apiRoles.presales, 
            macrosRoles.apiRoles.businessManager, 
            macrosRoles.apiRoles.lead
        ]
    },
    gestion: {
        type: 2,
        roles: [
            macrosRoles.apiRoles.administration, 
            macrosRoles.apiRoles.humanResources
        ]
    }
};