export const sizeOptions = [
  {
    api: 0,
    name: 'panel-company-edit.size-options-0', 
  },
  {
    api: 1,
    name: 'panel-company-edit.size-options-1', 
  },
  {
    api: 2,
    name: 'panel-company-edit.size-options-2', 
  },
  {
    api: 3,
    name: 'panel-company-edit.size-options-3', 
  },
  {
    api: 4,
    name: 'panel-company-edit.size-options-4', 
  },
  {
    api: 5,
    name: 'panel-company-edit.size-options-5', 
  },
  {
    api: 6,
    name: 'panel-company-edit.size-options-6', 
  },
  {
    api: 7,
    name: 'panel-company-edit.size-options-7', 
  },
  {
    api: 8,
    name: 'panel-company-edit.size-options-8', 
  },
  {
    api: 9,
    name: 'panel-company-edit.size-options-9', 
  },
];

export default {
  sizeOptions,
};
