<script lang="ts" setup>
  import { computed, onMounted, onUnmounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { useStore } from '@/store';
  import { useMultiselect } from '@/composables/useMultiselect';
  import { useI18n } from '@/i18n/i18n';
  import { Process } from '@/domains/models/Process';
  import { useProcessesStore } from '@/store/pinia/processes';

  import Views from '@/components/Views/Views';
  import OpportunitiesTabs from '@/views/Tabs/OpportunitiesTabs';
  import ViewsContainer from '@/components/Views/ViewsContainer';
  import ApplicationsFilters from '@/components/Applications/ApplicationsFilters';
  import ApplicationsList from '@/components/Applications/ApplicationsList';
  import ButtonGroup from '@/components/Button/ButtonGroup';
  import ModalProcessActionConfirmation
    from '@/components/Modal/ModalProcess/ModalProcessActionConfirmation.vue';
  import BcModal from '@/ui-kit/components/BcModal/BcModal.vue';
  import { Candidate } from '@/domains/models/Candidate';
  import { Opportunity } from '@/domains/models/Opportunity';
  import { Company } from '@/domains/models/Company';

  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const toast = useToast();
  const { t } = useI18n();

  const { selectedCards, resetSelectedCards } = useMultiselect();
  const processesStore = useProcessesStore();

  const loading = computed(() => processesStore.isLoading);
  const list = computed(() => processesStore.applicationsList);
  const total = computed(() => processesStore.applicationsTotal);

  const modalState = ref('');
  const processesToDelete = ref<string[]>([]);
  const profile = computed(() => store.state.user.profile);
  const shouldSendMailOnReject = computed(() => profile.value?._organization?.configuration?.multiposting?.sendEmailAfterApplicationTreated);
  const filter = ref(route.query.filter ? route.query.filter : 'new');

  const filterItems = [
    { key: 'new', label: t('candidates.treat'), icon: 'inbox' },
    { key: 'treated', label: t('candidates.already-treated'), icon: 'download-file' },
  ];

  const handleClickFilter = async(_value) => {
    filter.value = _value.key;
    const value = _value.key;

    const query = { ...route.query };
    if (value !== 'treated' && query.coachIds) {
      delete query.coachIds;
    }

    router.push({
      name: route.name,
      query: { ...query, filter: value === 'treated' ? 'treated' : undefined, page: 1 },
    });
    await getData();
  };

  const onPageChange = async(page: number) => {
    router.push({ name: route.name, query: { ...route.query || {}, page } });
    await getData();
  };

  const onPageLimitChange = async(limit: number) => {
    router.push({ name: route.name, query: { ...route.query || {}, pageSize: limit, page: 1 } });
    await getData();
  };

  const codersFromProcesses = (processes) => {
    return processes.reduce((acc, process) => {
      return {
        ...acc,
        [process._coder._id]: process._coder,
      };
    }, {});
  };

  const getData = async() => {
    try {
      const query = {
        isApplication: true,
        isNewApplication: route.query?.filter !== 'treated',
        isApplicationTreated: route.query?.filter === 'treated',
        withPagination: true,
        page: Number(route.query.page || 1),
        pageSize: 20,
        ...route.query || {},
        coachIds: route.query?.filter === 'treated' ? route.query.coachIds : undefined,
        customJobOwnersIds: route.query?.filter !== 'treated' ? route.query.coachIds : undefined,
      };
      const data = await processesStore.fetchApplications(query);

      store.dispatch('resetCards');
      store.dispatch('setCards', Object.values(codersFromProcesses(data?.[0] || [])));
      sessionStorage.setItem('savedQuery', JSON.stringify(route.query));
    } catch (err) {
      toast.show({
        type: 'error',
        message: err?.response?.body?.message || err?.message,
      });
    }
  };

  const onSendEmails = async(coder: any, customJob: any) => {
    await store.dispatch('app/setIsPanel', true);
    store.dispatch('app/setParams', { isCompany: false, isApplication: true, });
    if (selectedCards.value?.length) {
      await store.dispatch('emails/setSelectedProcesses', selectedCards.value);
    } else {
      await store.dispatch('emails/setSelectedCoders', [coder]);
      await store.dispatch('emails/setSelectedProcesses', [{
        _coder: coder,
        _customJob: customJob,
      }]);
      await store.dispatch('app/setParams', {
        customJob,
      });
    }
    await store.dispatch('app/setModal', 'modal-email-send-form');
  };

  const onAccept = async(process: Process, coder: any) => {
    try {
      const category = profile.value?._organization?.configuration?.process?.categories?.[0];
      const step = category?.steps?.[1];

      if (step.email) {
        await onSendEmails(coder, process._customJob);
        await store.dispatch('emails/setEmailSentCallback', async(approvedProcess: {
          coder: Candidate,
          customJob: Opportunity,
          company: Company
        }) => {
          const cards = selectedCards.value.length ? selectedCards.value : list.value;
          const sentEmailProcess = cards.find((selectedProcess) => selectedProcess._coder._id === approvedProcess.coder._id && selectedProcess._customJob._id === approvedProcess.customJob._id && selectedProcess._company._id === approvedProcess.company._id);

          if (sentEmailProcess) {
            await processesStore.onAccept({
              processId: sentEmailProcess._id,
              categoryKey: category?.key,
              stepKey: step?.key,
            });
          }
        });
      } else {
        await processesStore.onAccept({
          processId: process?._id,
          categoryKey: category?.key,
          stepKey: step?.key,
        });
      }
    } catch (err) {
      toast.show({
        type: 'error',
        message: err?.response?.body?.message || err?.message,
      });
    }
  };

  const onReject = async(process: Process, coder: any) => {
    try {
      if (shouldSendMailOnReject.value) {
        await onSendEmails(coder, process._customJob);
        await store.dispatch('emails/setEmailSentCallback', async(rejectedProcess: {
          coder: Candidate,
          customJob: Opportunity,
          company: Company
        }) => {
          const cards = selectedCards.value.length ? selectedCards.value : list.value;
          const sentEmailProcess = cards.find((selectedProcess) => selectedProcess._coder._id === rejectedProcess.coder._id && selectedProcess._customJob._id === rejectedProcess.customJob._id && selectedProcess._company._id === rejectedProcess.company._id);

          if (sentEmailProcess) {
            await processesStore.onReject({ processId: sentEmailProcess._id });
          }
        });
      } else {
        await processesStore.onReject({ processId: process?._id });
      }
    } catch (err) {
      toast.show({
        type: 'error',
        message: err?.response?.body?.message || err?.message,
      });
    }
  };

  const onDelete = async(process: Partial<Process>, isLast: boolean) => {
    if (process._id) {
      processesToDelete.value = [...processesToDelete.value, process._id];
    }
    if (isLast) {
      modalState.value = 'deleteProcesses';
    }
  };

  const confirmDeleteProcesses = async() => {
    try {
      await processesStore.onDeleteMultiple({ processes: processesToDelete.value });
      closeModal();
    } catch (err) {
      toast.show({
        type: 'error',
        message: err?.response?.body?.message || err?.message,
      });
    }
  };

  const closeModal = () => {
    modalState.value = '';
    processesToDelete.value = [];
  };

  onMounted(async() => {
    getData();
  });

  onUnmounted(() => {
    sessionStorage.removeItem('savedQuery');

    store.dispatch('emails/setEmailSentCallback', null);
  });
</script>

<template>
  <Views>
    <OpportunitiesTabs>
      <ButtonGroup
        :active-key="filter"
        :items="filterItems"
        class="h-[36px]"
        @click="handleClickFilter"
      ></ButtonGroup>
    </OpportunitiesTabs>
    <ViewsContainer>
      <div class="flex gap-5">
        <ApplicationsFilters @on-submit="getData"/>
        <ApplicationsList
          :filter="filter"
          :is-deleted-finished="processesToDelete.length === 0"
          :list="list"
          :loading="loading"
          :total="total"
          @on-accept="onAccept"
          @on-reject="onReject"
          @on-delete="onDelete"
          @on-filter="handleClickFilter"
          @on-page-change="onPageChange"
          @on-page-limit-change="onPageLimitChange"
        />
      </div>
      <BcModal
        :active="!!modalState"
        :display-close-button="false"
        @close="closeModal">
        <ModalProcessActionConfirmation
          v-if="modalState === 'deleteProcesses'"
          :text="processesToDelete.length > 1 ? $t('generics.delete-processes-warning-content') : $t('generics.delete-process-warning-content')"
          :title="processesToDelete.length > 1 ? $t('generics.delete-processes-warning') : $t('generics.delete-process-warning')"
          type="delete"
          @confirm-action="confirmDeleteProcesses"
          @cancel-action="closeModal"
        >
        </ModalProcessActionConfirmation>
      </BcModal>
    </ViewsContainer>
    <router-view></router-view>
  </Views>
</template>
