<template>
  <div
    :class="{
      'document-item--selected': isSelected,
    }"
    class="document-item is-relative">
    <a :href="document.url" class="hidden-sm hidden-md is-absolute is-full-width"> </a>
    <img
      :class="{ 'document-item__logo--selected': isSelected }"
      :src="documentImg"
      alt="logo pdf"
      class="document-item__logo" />
    <div
      :class="{
        'document-item__title--selected': isSelected,
      }"
      class="document-item__title is-text-align-center body-l--bold">
      {{ document.name || 'Document' }}
    </div>
    <div
      :class="{
        'document-item__date--selected': isSelected,
      }"
      class="document-item__date is-text-align-center">
      {{ formattedDate }}
    </div>
    <i
      v-if="isSelected"
      class="document-item__delete icon-trash"
      @click="deleteDocument">
    </i>
  </div>
</template>

<script>
import moment from 'moment/min/moment-with-locales';

const imgMap = {
  pdf: require('@/assets/img/pdf.svg'),
  doc: require('@/assets/img/doc.svg'),
  docx: require('@/assets/img/docx.svg'),
};

export default {
  name: 'document-item',
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    document: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    formattedDate() {
      return moment(this.document.createdAt).fromNow();
    },
    documentExtension() {
      return this.document?.url?.split('.')?.pop() || 'pdf';
    },
    documentImg() {
      return imgMap[this.documentExtension] || imgMap.pdf;
    },
  },
  methods: {
    async deleteDocument() {
      this.$emit('delete-document');
    },
  },
};
</script>

<style lang="scss" scoped>
.document-item {
  $item: &;

  min-height: 191px;
  margin-top: 20px;
  max-width: 160px;
  flex-direction: column;
  align-items: center;
  color: $color-tertiary;
  min-width: 160px;
  padding: 20px 10px;
  border-radius: $border-radius-m;
  border: 1px solid $color-blue-heavy-dark;

  &:hover {
    cursor: pointer;
  }

  &--selected {
    background: $color-blue-light;

    &#{$item}--resignation {
      border-color: $color-error-dark;
      background-color: rgba($color-error-dark, 0.15);
    }
  }

  &__logo {
    height: 60px;
    width: 60px;
    opacity: 0.8;

    &--selected {
      opacity: 1;
    }
  }

  &__title {
    margin-top: 10px;
    max-width: 130px;
    word-break: break-word;

    &--selected {
      color: $color-tertiary;
    }

    &--resignation {
      color: $color-error-dark !important;
    }
  }

  &__date {
    margin-top: 5px;
    font-size: $font-size-xs;

    &--selected {
      color: $color-blue-dark-cello;
    }

    &--resignation {
      color: $color-error-dark !important;
    }
  }

  &__delete {
    margin-top: 15px;
    padding: 8px;
    border-radius: 100%;
    color: $color-white;
    background: $color-red-mandy;

    &:hover {
      color: $color-white;
    }
  }

  .is-absolute {
    height: 100%;
    margin-top: -20px;
    z-index: 1;
  }
}
</style>
