<template>
  <div class="bc-custom-field-container">
    <label class="font-size-xs text-blue-400" v-if="formatLabel" for="$attrs.id">
      <i :class="`icon-${icon}`"></i>
      {{ formatLabel }}
    </label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'bc-custom-field-container',
  props: {
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
    unit: {
      type: String,
    },
  },
  computed: {
    formatLabel() {
      let label = this.label;
      if (this.unit) {
        label += ` (${this.unit})`;
      }
      return label;
    }
  }
};
</script>

<style lang="scss" scoped>
.bc-custom-field-container {
  position: relative;

  & > label {
    position: absolute;
    left: 10px;
    top: -20px;

    & > i {
      margin-right: 5px;
    }
  }
}
</style>
