<template>
  <div class="filter-coders-salary__main">
    <div class="filter-coders-salary__salary">
      <salary-input
        class="filter-coders-salary__salary-input"
        :hasActions="false"
        :title="$t('generics.current-salary')"
        :labels="['min', 'max']"
        :selected="currentSalary"
        @update="updateCurrentSalary"
      ></salary-input>
    </div>
    <div class="filter-coders-salary__salary">
      <salary-input
        class="filter-coders-salary__salary-input"
        :hasActions="false"
        :title="$t('generics.desired-salary')"
        :labels="['min', 'max']"
        :selected="salaryWanted"
        @update="updateSalaryWanted"
      ></salary-input>
    </div>
  </div>

</template>

<script setup lang="ts">
import { computed, defineProps, defineEmits } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import debounce from 'debounce';
import { salaryLabels, salaryTypesNew } from '@/api/custom-jobs';
import SalaryInput from '@/components/Salary/SalaryInput';

const props = defineProps<{
  emitOnly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:filters', filters: Record<string, any>): void;
  (e: 'count', value: { key: string; count: number }): void;
}>();

const route = useRoute();
const router = useRouter();

const selected = computed(() => route.query.salaryType || undefined);

const salaryTypes = computed(() => 
  Object.entries(salaryLabels).map(([apiCode, label]) => ({
    query: apiCode,
    name: label,
  }))
);

const currentSalary = computed(() => {
  const q = route.query;
  return [{ 
    salaryType: q.currentSalaryType || salaryTypesNew.grossPerYear, 
    value_0: q.currentSalaryMin || 0, 
    value_1: q.currentSalaryMax || 0 
  }];
});

const salaryWanted = computed(() => {
  const q = route.query;
  return [{ 
    salaryType: q.salaryWantedType || salaryTypesNew.grossPerYear, 
    value_0: q.salaryWantedMin || 0, 
    value_1: q.salaryWantedMax || 0 
  }];
});

const updateCurrentSalary = debounce((data: any[]) => {
  const [currentSalary] = data;
  const min = currentSalary.value_0 ? currentSalary.value_0 : undefined;
  const max = currentSalary.value_1 ? currentSalary.value_1 : undefined;

  if (min || max) {
    if (props.emitOnly) {
      emit('update:filters', {
        currentSalaryType: currentSalary.salaryType,
        currentSalaryMin: min,
        currentSalaryMax: max,
      });
    } else {
      router.push({
        query: {
          ...route.query,
          page: 1,
          currentSalaryType: currentSalary.salaryType,
          currentSalaryMin: min,
          currentSalaryMax: max,
        },
      });
    }
    emit('count', { key: 'current-salary', count: min || max ? 1 : 0 });
  }
}, 500);

const updateSalaryWanted = debounce((data: any[]) => {
  const [salaryWanted] = data;
  const min = salaryWanted.value_0 ? salaryWanted.value_0 : undefined;
  const max = salaryWanted.value_1 ? salaryWanted.value_1 : undefined;
  
  if (min || max) {
    if (props.emitOnly) {
      emit('update:filters', {
        salaryWantedType: salaryWanted.salaryType,
        salaryWantedMin: min,
        salaryWantedMax: max,
      });
    } else {
      router.push({
        query: {
          ...route.query,
          page: 1,
          salaryWantedType: salaryWanted.salaryType,
          salaryWantedMin: min,
          salaryWantedMax: max,
        },
      });
    }
    emit('count', { key: 'salary-wanted', count: min || max ? 1 : 0 });
  }
}, 500);
</script>

<style lang="scss" scoped>
.filter-coders-salary {
  &__main {
    flex-direction: column;
    width: 100%;
  }

  &__container {
    gap: 15px;
    margin-top: 5px;
  }

  &__salary {
    width: 100%;
    padding: 17px;

    &-input {
      width: 100%;
    }
  }
}
</style>
