<template>
  <div class="select-container">
    <label class="font-size-xs flex items-center" v-if="label" for="$attrs.id">
      <i :class="`icon-${icon}`"></i>
      {{ label }} <span v-if="required" class="ml-1 text-red-500">*</span>
    </label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'select-container',
  props: {
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.select-container {
  position: relative;

  & > label {
    position: absolute;
    color: $color-blue-heavy-dark;
    left: 10px;
    top: -20px;

    & > i {
      margin-right: 5px;
    }
  }
}
</style>
