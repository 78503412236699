<template>
  <div class="panel-process-create-coder">
    <h2 class="panel-process-create-coder__title">
      <span class="panel-process-create-coder__icon icon-user"></span>{{ $t('generics.the-candidate') }} <span class="ml-1 text-red-500">*</span>
    </h2>
    <div v-if="selectedCoders.length > 1" class="coders-container is-align-items-center is-column is-full-width">
      <panel-process-create-selected
        v-for="(_, index) of new Array(2)"
        :key="selectedCoders[index]._id"
        :show-close-btn="false"
        @on-close="$emit('on-close')"
      >
        <bc-avatar
          :src="selectedCoders[index].pictureUrl"
          alt="coder picture"
          class="panel-process-create-coder__logo"
          size="s"
          @error="setAlternativeImg"
        >
        </bc-avatar>
        <p class="panel-process-create-coder__name">
          {{ selectedCoders[index].firstName }} {{ selectedCoders[index].lastName }}
        </p>
      </panel-process-create-selected>
      <div v-if="selectedCoders.length > 2" class="selected-coders-count">
        +{{ selectedCoders.length - 2 }}
      </div>
    </div>
    <div v-else-if="selectedCoder._id" class="coders-container is-align-items-center is-column is-full-width">
      <panel-process-create-selected :show-close-btn="!fromCoders" @on-close="$emit('on-close')">
        <bc-avatar
          :src="selectedCoder.pictureUrl"
          alt="coder picture"
          class="panel-process-create-coder__logo"
          size="s"
          @error="setAlternativeImg"
        >
        </bc-avatar>
        {{ selectedCoder.firstName }} {{ selectedCoder.lastName }}
      </panel-process-create-selected>
      <!-- <div
        class="panel-process-create-coder__wrapper is-align-items-center is-full-width is-justify-content-center mb-3">
        <div class="panel-process-create-coder__subwrapper">
          <bc-tag
            v-for="domain in domains"
            :key="domain._id"
            v-tooltip="domain.name"
            class="panel-process-create-coder__tag panel-process-create-coder__tag--domain"
            ellipsis>
            {{ domain.name }}
          </bc-tag>
          <bc-tag
            v-for="location in locations"
            :key="location._id"
            v-tooltip="location.name"
            class="panel-process-create-coder__tag panel-process-create-coder__tag--location"
            ellipsis
            icon="map-pin">
            {{ location.name }}
          </bc-tag>
        </div>
      </div> -->
      <!-- <div class="panel-process-create-coder__wrapper is-full-width">
        <card-tags
          :reference="'stack'"
          :tags="stack"
          class="card-coders__tag-wrapper is-justify-content-center">
          <template #tag="{ tag }">
            <bc-tag
              v-tooltip="tag.name"
              class="card-coders__tag is-unshrink">
              {{ tag.name }}
            </bc-tag>
          </template>
          <template #remaining="{ remaining }">
            <bc-tag class="card-coders__tag is-unshrink">
              +{{ remaining }}
            </bc-tag>
          </template>
        </card-tags>
      </div> -->
    </div>
    <bc-autocomplete
      v-else
      ref="coderDropdown"
      placeholder="Écrivez le nom d’un candidat"
      v-model="autocomplete"
      :is-scrollable="false"
      :options="options"
      class="panel-process-create-coder__dropdown is-full-width"
      keep-first
      name="autocomplete"
      @input="getSuggestions"
      @select="
        event => {
          emptySuggestions();
          $emit('coder-selected', event);
        }
      "
      @click.stop.native
    >
      <template #prepend>
        <div
          v-tooltip="$t('process.create-candidate-infos')"
          class="panel-process-create-coder__tooltip is-absolute is-right icon-info is-bottom is-top is-align-items-center mr-3 is-secondary"></div>
      </template>
      <template #option="{ option, isActive }">
        <bc-dropdown-item
          :class="{ 'panel-process-create-coder__option--active': isActive }"
          class="panel-process-create-coder__option"
        >
          <p
            class="panel-process-create-coder__text is-ellipsis"
            v-html="getTextHint(getOptionName(option))"
          >
          </p>
        </bc-dropdown-item>
      </template>
      <template #footer>
        <button
          v-if="isValidFirstNameAndLastName && autocomplete.length >= 2"
          class="panel-process-create-company__item is-full-width pt-3 pb-3 font-size-m is-secondary is-justify-content-center mr-auto ml-auto"
          @click="createCoderAndSetSelectedCoder"
        >
          <span class="icon-candidates-plus mr-2"></span>
          {{ $t('process.create-the-coder', { candidate: autocomplete }) }}
        </button>
      </template>
    </bc-autocomplete>
  </div>
</template>

<script>
import debounce from 'debounce';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import coders from '@/api/coders';
import locationsDeserializers from '@/managers/locations/formatters/locationsDeserializers';
import { coderController } from '@/managers/coders/controller';
import PanelProcessCreateSelected from './PanelProcessCreateSelected';
// import BcTag from '@/ui-kit/components/BcTag/BcTag';
// import CardTags from '@/components/Card/CardTags';

export default {
  name: 'panel-process-create-coder',
  components: {
    // CardTags,
    // BcTag,
    BcDropdownItem,
    BcAutocomplete,
    BcAvatar,
    PanelProcessCreateSelected
  },
  props: {
    selectedCoder: {
      type: Object,
      default: () => ({})
    },
    selectedCoders: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      isLoading: false,
      isNoResults: false,
      options: [],
      autocomplete: ''
    };
  },
  computed: {
    stack() {
      return [
        ...(this.selectedCoder?._technos3 || []),
        ...(this.selectedCoder?._technos2 || []),
        ...(this.selectedCoder?._technos1 || [])
      ];
    },
    locations() {
      return this.selectedCoder?.locations?.map(location => ({
        name: locationsDeserializers.getLocationNameFromLocationObject(location)
      }));
    },
    domains() {
      return this.selectedCoder?._domains || [];
    },
    isValidFirstNameAndLastName() {
      return this.autocomplete?.split(' ')?.length >= 2;
    },
    fromCoders() {
      return this.$route.query.from === 'coders'
    }
  },
  methods: {
    setAlternativeImg(event) {
      event.target.src = require('@/assets/img/default-avatar.svg');
    },
    getSuggestions: debounce(async function(value) {
      if (value) {
        this.setIsLoading(true);
        try {
          const { data } = await coders.candidatesAutocomplete(value);

          this.options = data;

          this.isNoResults = data.length === 0;
        } catch (error) {
          this.isNoResults = true;

          this.options = [];
        }
        this.setIsLoading(false);
      }
    }, 500),
    setIsLoading(value) {
      this.isLoading = value;
    },
    emptySuggestions() {
      this.autocomplete = '';

      this.options = [];
    },
    setFocusOnDropdown() {
      if (this.$route.query?.from === 'hunt') {
        this.$refs?.coderDropdown?.$refs?.input?.$refs?.input?.focus();
      }
    },
    formatString(string = '') {
      return string
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getTextHint(text) {
      return `${
        this.splitHighlightValue(text)[0]
      }<span class="panel-process-create-coder__text--hint">${
        this.splitHighlightValue(text)[1]
      }</span>${this.splitHighlightValue(text)[2]}`;
    },
    getOptionName(option) {
      return option.title
        ? `${option.firstName} ${option.lastName} - ${option.title}`
        : `${option.firstName} ${option.lastName}`;
    },
    splitHighlightValue(name) {
      const startHighlight = this.formatString(name).indexOf(this.formatString(this.autocomplete));
      const endHighlight = startHighlight + this.autocomplete.length;

      if (startHighlight === -1) {
        return [name, '', ''];
      }

      return [
        name.substring(0, startHighlight),
        name.substring(startHighlight, endHighlight),
        name.substring(endHighlight)
      ];
    },
    async createCoderAndSetSelectedCoder() {
      try {
        const [firstName, ...lastName] = this.autocomplete.trim().split(' ');

        const { data } = await coderController.createProfile({
          data: {
            firstName,
            ...(lastName && { lastName: lastName.join(' ') })
          }
        });

        this.$emit('coder-selected', data);

          this.$toast.show({
            type: 'success',
            title: this.$i18n.t('toast.congratulations'),
            message: this.$i18n.t('toast.candidate-successfully-added-to-your-data-basis'),
          });
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: error?.response?.data?.message || error,
          });
        }
      },
    },
    mounted() {
      this.setFocusOnDropdown();
      if (this.selectedCoders.length === 1) {
        this.$emit('coder-selected', this.selectedCoders[0]);
        this.selectedCoder = this.selectedCoders[0]
      }
    },
  };
</script>

<style lang="scss" scoped>
.panel-process-create-coder {
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 15px 0;

  &__wrapper {
    background: red;
  }

  &__title {
    display: flex;
    color: #344f74;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    font-size: $font-size-s;
    line-height: 17px;
    align-items: center;
    margin-bottom: 15px;
  }

  &__icon {
    display: inline-flex;
    font-size: $font-size-l;
    align-items: center;
    margin-right: 10px;
  }

  &__logo {
    overflow: hidden;
    margin-right: 10px;
  }

  &__name {
    color: $color-secondary;
  }

  &__text {
    :deep() {
      .panel-process-create-coder__text--hint {
        color: $color-primary;
      }

      .panel-process-create-coder__text--disabled {
        color: $color-grey-4;
      }
    }
  }

  &__option {
    color: #1d385e;

    &:hover,
    &--active {
      color: $color-white;

      :deep() {
        .panel-process-create-coder__text--hint {
          color: $color-secondary;
        }
      }
    }
  }

  &__subwrapper {
    margin: -5px;
  }

  &__tag {
    max-width: 100%;
    margin: 5px;

    &.is-unshrink {
      max-width: initial;
    }

    &--location {
      background: $color-success-dark;
      color: $color-white;
    }

    &--domain {
      background: $color-primary-dark;
      color: $color-white;
    }
  }

  &__tooltip {
    cursor: pointer;
  }
}

.coders-container {
  gap: 5px;
}

.selected-coders-count {
  background: $color-blue-dark-cello;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  margin-top: 3%;
}
</style>
