<template>
  <div @click.stop class="panel-custom-jobs-notes">
    <panel-view-timeline @qualification-created="qualificationCreated"></panel-view-timeline>
  </div>
</template>

<script>
import PanelViewTimeline from '@/components/Panel/PanelView/PanelViewTimeline';

export default {
  components: {
    PanelViewTimeline,
  },
  name: 'panel-custom-jobs-notes',

  methods: {
    qualificationCreated(note) {
      this.$emit('urgency-updated', note.urgency);
    },
  },
};
</script>

<style scoped lang="scss">
.panel-custom-jobs-notes {
  width: 100%;
}
</style>
