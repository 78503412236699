<template>
  <div class="filter-content">
    <div class="filter-content__title body-s">
      <i v-if="icon" :class="`icon-${icon}`"></i>
      <span>{{ label }}</span>
    </div>
    <div class="filter-content__children">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'filter-content',
  props: {
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
  }
}
</script>

<style lang="scss" scoped>
.filter-content {
  width: 100%;
  padding: 15px;
  flex-direction: column;

  &__title {
    color: $color-blue-heavy-dark;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
  }

  &__children {
    display: block;
  }
}
</style>
