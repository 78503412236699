<template>
  <div class="processes-filters">
    <div class="processes-filters__wrapper mr-2">
      <filters-dropdown-periods
        v-if="displayNextInterview"
        :is-default-selected-period="false"
        :is-future-date="true"
        :has-reset-button="true"
        class="mr-2.5"
        custom-period-query-end="processes-ends"
        custom-period-query-start="processes-starts"
        query-name="processes"
        @select="setSelectedPeriod">
      </filters-dropdown-periods>
      <processes-view-filter></processes-view-filter>
      <bc-dropdown
        :options="coachesOptions"
        class="processes-filters__dropdown"
        is-expanded>
        <template #label="{ isDropdownOpen }">
          <bc-dropdown-trigger
            :is-arrow="true"
            :is-dropdown-open="isDropdownOpen"
            :is-filter="true"
            :text="selectedCoaches"
            icon="user">
          </bc-dropdown-trigger>
        </template>
        <template #option="{ activeIndex, index, option }">
          <bc-dropdown-item
            :is-focus="activeIndex === index"
            :text="option.name"
            :title="option.name"
            class="processes-filters__dropdown-item font-size-s"
            @click.native="setSelectedCoaches(option)">
          </bc-dropdown-item>
        </template>
      </bc-dropdown>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';

  import moment from 'moment/min/moment-with-locales';
  import BcDropdown from '@/ui-kit/components/BcDropdown';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
  import ProcessesViewFilter from '@/components/Processes/ProcessesViewFilter';
  import { getFilterablePoolCoach } from '@/macros/prospectionPipe/dropdowns';
  import locale from '@/mixins/i18n/locale';
  import FiltersDropdownPeriods
    from '@/components/Filters/FiltersDropdown/FiltersDropdownPeriods.vue';

  export default {
    name: 'processes-filters',
    mixins: [locale],
    components: {
      FiltersDropdownPeriods,
      BcDropdownTrigger,
      BcDropdownItem,
      BcDropdown,
      ProcessesViewFilter,
    },
    props: {
      isCurrentPeriodDefault: {
        type: Boolean,
        default: false,
      },
      displayNextInterview: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        range: {
          start: moment().startOf('month').toDate(),
          end: moment().toDate(),
        },
      };
    },
    computed: {
      ...mapState({
        coaches: state => state.coaches.coaches,
        profile: state => state.user.profile,
      }),
      ...mapGetters({
        configurationProcess: 'user/configurationProcess',
      }),
      coachesOptions() {
        const coaches = this.coaches
        .filter(coach => getFilterablePoolCoach(coach))
        .map(coach => ({
          name: `${coach.firstName} ${coach.lastName}`,
          query: coach._id,
        }));

        return [
          {
            name: this.$i18n.t('process.everyone'),
            type: 'global-pipe',
            query: null,
          },
          ...coaches.sort((a, b) => a.name.localeCompare(b.name)),
        ];
      },
      selectedCoach() {
        return this.coaches.find(coach => coach._id === this.$route.query['processes-selected-coaches']);
      },
      selectedCoaches() {
        return this.selectedCoach ? `${this.selectedCoach.firstName} ${this.selectedCoach.lastName}` : this.$i18n.t('process.everyone');
      },
      periodOptions() {
        return [
          {
            name: this.$i18n.t('process.week'),
            from: moment().startOf('week').format('DD-MM-YYYY'),
            to: moment().endOf('week').format('DD-MM-YYYY'),
            query: 'week',
          },
          {
            name: this.$i18n.t('process.month'),
            from: moment().startOf('month').format('DD-MM-YYYY'),
            to: moment().endOf('month').format('DD-MM-YYYY'),
            query: 'months',
          },
          {
            name: this.$i18n.t('process.trimester'),
            from: moment().startOf('quarter').format('DD-MM-YYYY'),
            to: moment().endOf('quarter').format('DD-MM-YYYY'),
            query: 'quarter',
          },
        ];
      },
      selectedPeriod() {
        if (this.$route.query['processes-starts'] && this.$route.query['processes-ends']) {
          const { 'processes-starts': startDate, 'processes-ends': endDate } = this.$route.query;
          const selectedPeriod = this.periodOptions.find(period => period.from === startDate && period.to === endDate);
          return selectedPeriod?.name || this.$i18n.t('process.period');
        }
        return this.$i18n.t('process.period');
      },
      selectedDate() {
        return `${moment(this.$route.query['processes-starts'], 'DD-MM-YYYY').format('L')} - ${moment(this.$route.query['processes-ends'], 'DD-MM-YYYY').format('L')}`;
      },
    },
    mounted() {
      if (this.$route.query['processes-starts'] && this.$route.query['processes-ends']) {
        this.range.start = moment(this.$route.query['processes-starts'], 'DD-MM-YYYY').toDate();
        this.range.end = moment(this.$route.query['processes-ends'], 'DD-MM-YYYY').toDate();
      }
    },
    methods: {
      setSelectedPipe(pipe) {
        if (pipe.type === 'personal-pipe') {
          this.$router.push({
            query: {
              ...this.$route.query,
              'selected-pipe': pipe.type,
              'processes-selected-coaches': pipe.query ? pipe.query : undefined,
            },
          });
        } else {
          this.$router.push({
            query: {
              ...this.$route.query,
              'selected-pipe': pipe.type,
              'processes-selected-coaches': undefined,
            },
          });
        }
      },
      setSelectedPeriod(period) {
        this.$router.push({
          query: {
            ...this.$route.query,
            'processes-starts': period.from ? period.from : undefined,
            'processes-ends': period.to ? period.to : undefined,
          },
        });
      },
      setProcessDateRange(date) {
        if (date) {
          this.$router.push({
            ...this.$route,
            query: {
              ...this.$route.query,
              'processes-starts': moment(date.start).format('DD-MM-YYYY'),
              'processes-ends': moment(date.end).format('DD-MM-YYYY'),
            },
          });
        }
      },
      resetProcessDateRange() {
        const start = moment().isoWeekday(1);
        const end = moment().isoWeekday(7);
        const date = {
          start: start.toDate(),
          end: end.toDate(),
        };

        this.range = date;

        this.setProcessDateRange(date);
      },
      setSelectedCoaches(coach) {
        if (coach.query === 'all') {
          this.$router.push({
            query: {
              ...this.$route.query,
              'processes-selected-coaches': undefined,
            },
          });
        } else {
          this.$router.push({
            query: {
              ...this.$route.query,
              'processes-selected-coaches': coach.query,
            },
          });
        }
      },
    },
  };
</script>

<style lang=scss scoped>
  .processes-filters {
    &__dropdown {
      min-width: 137px;
      margin-right: 10px;
    }

    &__button {
      margin-right: 10px;
    }

    &__date-picker {
      min-width: 220px;

      & :deep() {
        input {
          height: 100%;
          outline: none;
          box-shadow: none;
          border: 1px solid rgba($color-blue-dodger, 0.3);
          text-align: center;
          color: $color-blue-dodger;
          font-size: $font-size-s;
          padding: 5px 10px;
          transition: 0.3s;

          &:focus {
            border-color: $color-blue-dodger;
          }
        }

        .vc-grid-cell {
          display: block;
        }

        .bc-dropdown-trigger--success {
          justify-content: center;
        }
      }
    }
  }
</style>
