<template>
  <views class="timeline is-full-width is-column">
    <views-topbar :title="$t('activities.general-timeline')"></views-topbar>
    <views-container>
      <timeline-list
        :timeline="timeline"
        class="is-full-width"
        @note-deleted="deleteNote">
      </timeline-list>
      <infinite-loading
        :distance="400"
        :identifier="timelineIdentifier"
        class="timeline__infinite-scroll is-full-width is-justify-content-center"
        @infinite="getTimeline">
        <bc-spinner
          slot="spinner"
          size="large">
        </bc-spinner>
        <p
          slot="no-more"
          class="timeline__text font-size-m is-secondary-dark">
        </p>
        <p
          slot="no-results"
          class="timeline__text font-size-m is-secondary-dark">
          {{ $t('timeline.no-result') }}
        </p>
        <p
          slot="error"
          class="timeline__text font-size-m is-secondary-dark">
          {{ $t('timeline.error-during-request') }}
        </p>
      </infinite-loading>
    </views-container>
    <router-view></router-view>
  </views>
</template>

<script>
  import TimelineList from '@/components/Timeline/TimelineList';
  import { getGlobalTimeline } from '@/api/timeline';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import InfiniteLoading from 'vue-infinite-loading';
  import ViewsTopbar from '@/components/Views/ViewsTopbar';
  import Views from '@/components/Views/Views';
  import ViewsContainer from '@/components/Views/ViewsContainer';

  export default {
    name: 'timeline',
    components: { ViewsContainer, Views, ViewsTopbar, BcSpinner, TimelineList, InfiniteLoading },
    data() {
      return {
        timeline: [],
        timelinePage: 1,
        timelineIdentifier: +new Date(),
      };
    },
    methods: {
      async getTimeline($state) {
        try {
          try {
            const { data: { timeline, next } } = await getGlobalTimeline({
              page: this.timelinePage,
              coachesActivities: true,
            });
            this.timeline = [...this.timeline, ...timeline];

            if (next) {
              $state.loaded();

              this.incrementPage();
            } else {
              $state.loaded();

              $state.complete();
            }
          } catch (error) {
            $state.error();
          }
        } catch (error) {
          this.$toast.show({
            message: this.$t('toast.error-occured'),
            type: 'error',
            icon: 'cross',
          });
        }
      },
      incrementPage() {
        ++this.timelinePage;
      },
      deleteNote(id) {
        const index = this.timeline.findIndex(action => action?._note?._id === id);

        this.timeline.splice(index, 1);
      },
      refreshInfiniteLoading() {
        this.timelinePage = 1 ;
        this.timeline = [] 
        this.timelineIdentifier+= 1 
      },
    },
};
</script>
