<template>
  <div class="is-column candidate-pipe">
    <h1 class="candidate-pipe__title is-uppercase">
      Inbox
    </h1>
    <candidate-pipe-card-list
      :candidates-count="count"
      :is-loading="isLoading">
    </candidate-pipe-card-list>
    <router-view></router-view>
  </div>
</template>

<script>
  import coderHttp from '@/api/coders';
  //
  import { mapState } from 'vuex';
  //
  import ToastMixin from '@/mixins/toast';
  //
  import CandidatePipeCardList from '@/components/CandidatePipe/CandidatePipeCardList';


  export default {
    name: 'candidate-pipe',
    components: { CandidatePipeCardList },
    mixins: [ToastMixin],
    data() {
      return {
        count: 0,
        isLoading: false,
      };
    },
    created() {
      this.getCandidates();
    },
    watch: {
      '$route.query'(newQueries, oldQueries) {
        if (newQueries.step !== oldQueries.step ||
          newQueries.page !== oldQueries.page) {
          this.getCandidates();
          this.$store.dispatch('setSelectedCards', []);
        }
      },
    },
    computed: {
      ...mapState({
        metadata: state => state.user.metadata,
        coachId: state => state.user.profile._id,
      }),
    },
    methods: {
      async getCandidates() {
        try {
          this.$store.dispatch('resetCards');
          this.isLoading = true;

          const { data: { coders, count } } = await coderHttp.coderSubscriptions(this.$route.query.page || 1);

          this.count = count;
          this.$store.dispatch('setCards', this.formatCandidates(coders));

          this.isLoading = false;
        } catch (e) {
          this.$_displayErrorToast(e);
        }
      },
      formatCandidates(coders) {
        return coders.map(c => ({ ...c, resource: 'coders' }));
      },
    },
  };
</script>

<style lang=scss scoped>
  .candidate-pipe {
    position: relative;
    width: 100%;
    @include page_grid();
    padding: 0 15px 125px;

    &__title {
      @include font-bold(24px);
      line-height: 28px;
      color: $color-secondary;
      margin-bottom: 15px;
      text-align: center;
    }

    &__filter {
      margin-right: 30px;
    }

    &__text {
      margin-left: 10px;
      font-size: $font-size-s;
    }

    &__preferences {
      display: flex;
      padding: 10px 20px;
      border-radius: $border-radius-xl;
      background-color: rgba($color-secondary-dark, 0.1);
      color: $color-secondary-dark;

      @include bp('tablet') {
        position: absolute;
        right: 0;
      }
    }
  }
</style>
