<template>
  <div class="panel-header-date border-radius-s is-align-items-center is-flex">
    <p class="panel-header-date__text">
      <span class="hidden-xs">{{ text }}</span>&nbsp;{{ formattedDate }}
    </p>
  </div>
</template>

<script>
  import moment from 'moment/min/moment-with-locales';

  export default {
    name: 'panel-header-date',
    props: {
      text: {
        type: String,
        default: '',
      },
      date: {
        type: [Date, String],
        default: () => moment().toDate(),
      },
    },
    computed: {
      formattedDate() {
        return moment(this.date).format('L');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-header-date {
    flex-shrink: 0;
    padding: 2px 10px;
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    color: #3D5476;
    border-radius: $border-radius-l;
    background: $color-blue-light;
  }
</style>
