<template>
  <div class="panel-edit-coach-caption-rpo is-full-width is-flex-wrap is-justify-content-space-between">
    <title-section
      class="panel-edit-coach-caption-rpo__title is-full-width"
      position="left"
      :title="$t('panel-company-edit.infos')">
    </title-section>
    <ValidationProvider
      v-slot="{ errors, valid }"
      :rules="{ min: 2, regex: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u }"
      :name="$t('contacts.lastname')"
      slim>
      <bc-input
        ref="last-name"
        v-model="lastName"
        :class="{ 'invalid': errors.length > 0 }"
        :error="errors[0]"
        :valid="valid"
        class="panel-edit-coach-caption-rpo__input"
        icon="user"
        :label="$t('contacts.lastname')"
        name="last-name"
        :placeholder="$t('contacts.lastname')"
        type="text">
      </bc-input>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, valid }"
      :rules="{ min: 2, regex: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u }"
      :name="$t('contacts.firstname')"
      slim>
      <bc-input
        ref="first-name"
        v-model="firstName"
        :class="{ 'invalid': errors.length > 0 }"
        :error="errors[0]"
        :valid="valid"
        class="panel-edit-coach-caption-rpo__input"
        icon="user"
        :label="$t('contacts.firstname')"
        name="first-name"
        :placeholder="$t('contacts.firstname')"
        type="text">
      </bc-input>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, valid }"
      :rules="{ min: 3 }"
      :name="$t('avatar-dropdown.job-title')"
      slim>
      <bc-input
        ref="job"
        v-model="job"
        :class="{ 'invalid': errors.length > 0 }"
        :error="errors[0]"
        :valid="valid"
        class="panel-edit-coach-caption-rpo__input"
        icon="users"
        :label="$t('avatar-dropdown.job-title')"
        name="job"
        placeholder="Job"
        type="text">
      </bc-input>
    </ValidationProvider>
    <div style="width: 100vw;"></div>
    <ValidationProvider
      v-slot="{ errors }"
      :rules="{ min: 8, max: 20, regex: /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i }"
      :name="$t('contacts.phone')"
      slim>
      <bc-input-phone
        ref="phone"
        v-model="mobile"
        :class="{ 'invalid': errors.length > 0 }"
        class="panel-edit-coach-caption-rpo__input"
        icon="phone"
        name="phone"
        :placeholder="$t('contacts.phone')"
        @error="isPhoneValid = false"
        @valid="isPhoneValid = true"
        @keydown.native.enter.prevent>
      </bc-input-phone>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, valid }"
      :rules="{ email: true }"
      name="Email"
      slim>
      <bc-input
        ref="email"
        v-model="email"
        :class="{ 'invalid': errors.length > 0 }"
        :error="errors[0]"
        :valid="valid"
        :disabled="!isMarvin"
        class="panel-edit-coach-caption-rpo__input"
        icon="mail"
        label="Email"
        name="email"
        placeholder="Email"
        type="text">
      </bc-input>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, valid }"
      name="LinkedIn"
      slim>
      <bc-input
        ref="linkedin"
        v-model="linkedin"
        :class="{ 'invalid': errors.length > 0 }"
        :error="errors[0]"
        :valid="valid"
        class="panel-edit-coach-caption-rpo__input"
        icon="linkedin"
        label="LinkedIn"
        name="linkedin"
        placeholder="LinkedIn"
        type="text">
      </bc-input>
    </ValidationProvider>
  </div>
</template>

<script>
  import BcInput from '@/ui-kit/components/BcInput/BcInput';
  import { ValidationProvider } from 'vee-validate';
  import TitleSection from '@/components/Title/TitleSection';
  import BcInputPhone from '@/ui-kit/components/BcInputPhone/BcInputPhone';
  import { mapState } from 'vuex';
  import { marvinRecruiterOrganizationId } from '@/macros/utils';

  export default {
    name: 'panel-edit-coach-caption-rpo',
    components: {
      BcInputPhone,
      TitleSection,
      BcInput,
      ValidationProvider,
    },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
      editedProfile: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      ...mapState({
        coaches: state => state.coaches?.coaches,
        user: state => state.user?.profile,
      }),
      isMarvin() {
        return this.$store.state.user.profile?._organization?._id === marvinRecruiterOrganizationId;
      },
      lastName: {
        get() {
          return this.editedProfile.hasOwnProperty('lastName') && this.editedProfile.lastName !== this.profile.lastName ? this.editedProfile.lastName : this.profile.lastName;
        },
        set(value) {
          this.$emit('update-value', {
            lastName: value,
          });
        },
      },
      firstName: {
        get() {
          return this.editedProfile.hasOwnProperty('firstName') && this.editedProfile.firstName !== this.profile.firstName ? this.editedProfile.firstName : this.profile.firstName;
        },
        set(value) {
          this.$emit('update-value', {
            firstName: value,
          });
        },
      },
      job: {
        get() {
          return this.editedProfile.hasOwnProperty('job') && this.editedProfile.job !== this.profile.job ? this.editedProfile.job : this.profile.job;
        },
        set(value) {
          this.$emit('update-value', {
            job: value,
          });
        },
      },
      location: {
        get() {
          return this.editedProfile.hasOwnProperty('location') && this.editedProfile.location !== this.profile.location ? this.editedProfile.location : this.profile.location;
        },
        set(value) {
          this.$emit('update-value', {
            location: value,
          });
        },
      },
      mobile: {
        get() {
          return this.editedProfile.hasOwnProperty('mobile') && this.editedProfile.mobile !== this.profile.mobile ? this.editedProfile.mobile : this.profile.mobile;
        },
        set(value) {
          this.$emit('update-value', {
            mobile: value,
          });
        },
      },
      email: {
        get() {
          return this.editedProfile.hasOwnProperty('email') && this.editedProfile.email !== this.profile.email ? this.editedProfile.email : this.profile.email;
        },
        set(value) {
          this.$emit('update-value', {
            email: value,
          });
        },
      },
      linkedin: {
        get() {
          return this.editedProfile.hasOwnProperty('linkedin') && this.editedProfile.linkedin !== this.profile.linkedin ? this.editedProfile.linkedin : this.profile.linkedin;
        },
        set(value) {
          this.$emit('update-value', {
            linkedin: value,
          });
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-edit-coach-caption-rpo {
    &__title {
      margin-bottom: 20px;
    }

    &__input {
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }

      @include bp('tablet') {
        flex-basis: calc(50% - 5px);
        &:last-of-type {
          flex-basis: 100%;
        }
      }
    }
  }
</style>
