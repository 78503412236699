<template>
  <infinite-loading
    class="infinite-scroll col-xs-12"
    :distance="400"
    :identifier="identifier"
    @infinite="loadMore"
    ref="infiniteLoading">
    <spinner
      slot="spinner"
      :spinning="true">
    </spinner>
    <div slot="no-more"></div>
    <div slot="no-results"></div>
    <div slot="error"></div>
  </infinite-loading>
</template>

<script>
  import Spinner from '@/components/UIKit/Spinner';
  import InfiniteLoading from 'vue-infinite-loading';
  // TODO Déplacer à un endroit plus approprié
  /**
   * This contains the infinite scroll with the spinner included
   */
  export default {
    name: 'infinite-scroll',
    components: {
      Spinner,
      InfiniteLoading
    },
    props: {
      /**
       * The Object that contains all the necessary informations needed to call the Api
       */
      params: Object
    },
    data() {
      return {
        page: 1,
        identifier: +new Date()
      };
    },
    watch: {
      /**
       * The watcher that call the loadMore methods when the route change. <br>
       *     Check if the route where there is the CardList is not a nested route and that you don't come from a nested route.
       */
      $route: {
        handler: function(to, from) {
          /**
           * When the $route change, we check if the route is nested or not to determinate if the request must be reset
           * If there is no modal, or if we are not coming from panel, of if we are inside the panel or the tunnel, we will do the following
           */
          if (!to.params.id && !from.params.id && this.$route.name !== 'Tunnel' && (!to.query.modal && !from.query.modal)) {
            /**
             * We empty the array
             */
            this.$store.dispatch('resetCards');
            /**
             * We set the page to 1
             * @type {number}
             */
            this.page = 1;
            /**
             * We launch the attemptLoad method
             */
            this.$nextTick(() => {
              this.resetInfiniteScroll();
            });
          }
        },
        deep: true
      }
    },
    methods: {
      resetInfiniteScroll() {
        this.$emit('changeIsLoaded', false);
        this.identifier += 1;
      },
      /**
       * 'getCards' will send a request GET to the Api<br>
       * `page: Number,<br>
       * filter: String ('desc', 'asc'),<br>
       * filterType: String ('date', 'xp', 'salary'),<br>
       * status: Number (0, 1, 2, 3),<br>
       * technos: String,<br>
       * query: String`
       */
      async loadMore($state) {
        /**
         * We emit to the parent that changeIsLoaded is false
         */
        this.$emit('changeIsLoaded', false);
        try {
          const res = await this.$store.dispatch(this.params.callFn, {
            ...this.$route.name === 'HuntJobsPanel' || this.$route.name === 'PartnersPanel' && { huntId: this.$route.params.id },
            call: this.params.call ? this.params.call : '',
            responseObj: this.params.responseObj ? this.params.responseObj : '',
            parameters: this.params.parameters ? this.params.parameters : '',
            page: this.page,
            order: this.$route.query.order ? this.$route.query.order : 'desc',
            sort: this.$route.query.sort ? this.$route.query.sort : 'date',
            // TODO : remove the query 'all'
            status: this.$route.query.status ? this.$route.query.status : this.params.status,
            technos: this.$route.query.technos ? this.$route.query.technos : '',
            query: this.$route.query.search ? this.$route.query.search : '',
            salaryMin: this.$route.query.salaryMin,
            salaryMax: this.$route.query.salaryMax,
            xpMin: this.$route.query.xpMin,
            xpMax: this.$route.query.xpMax
          });
          /**
           * We emit to the parent that the changeIsLoaded is false
           */
          this.$emit('changeIsLoaded', true);
          /**
           * We tell to the infinite load that the datas are loaded
           */
          $state.loaded();
          /**
           * We emit the response to the parent
           */
          this.$emit('response', res);
          /**
           * If there is next page, we increment the page for the next request
           * Otherwise, we tell to the infinite-scroll that there will be no more request
           */
          if (res.next ||
              (res.body && res.body.next) ||
              (res.data && res.data.next)) { // TODO -> enlever le deuxième `OR` sur res.body lorsque tout sera migré sur axios
            ++this.page;
          } else {
            $state.complete();
          }
        } catch (err) {
          this.$emit('error', err);
          /**
           *  If we get a 403,
           *  main.js triggers the toaster
           *  and we stop calling the api
           */
          if (err.status === 409) {
            /**
             * If the user get a 409 request, we redirect to the default route to set the default params
             */
            this.$router.push({
              path: this.$route.path,
              query: { ...this.$route.query }
            });
            this.$emit('changeIsLoaded', true);
          }
          $state.error();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .infinite-scroll {
    justify-content: center;
    align-items: center;
  }
</style>
