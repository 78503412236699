<template>
  <div class="home-statistics-kpi">
    <div class="home-statistics-kpi__rows">
      <div class="home-statistics-kpi__entity">
        <p class="home-statistics-kpi__entity-title">
          {{ $tc('generics.candidate-cap', 2) }}
        </p>
        <div class="home-statistics-kpi__entity-wrapper">
          <home-kpi-container
            :key="performance.name"
            :loading="loading"
            :performance="performance"
            class="home-statistics-kpi__kpi"
            v-for="performance in performances.codersPerformances">
          </home-kpi-container>
        </div>
      </div>
      <div class="home-statistics-kpi__entity">
        <p class="home-statistics-kpi__entity-title">
          {{ $t('activities.partners') }}
        </p>
        <div class="home-statistics-kpi__entity-wrapper">
          <home-kpi-container
            :key="performance.name"
            :loading="loading"
            :performance="performance"
            class="home-statistics-kpi__kpi"
            v-for="performance in performances.partnersPerformances">
          </home-kpi-container>
        </div>
      </div>
      <div class="home-statistics-kpi__entity">
        <p class="home-statistics-kpi__entity-title">
          {{ $t('activities.jobs') }}
        </p>
        <div class="home-statistics-kpi__entity-wrapper">
          <home-kpi-container
            :key="performance.name"
            :loading="loading"
            :performance="performance"
            class="home-statistics-kpi__kpi"
            v-for="performance in performances.jobsPerformances">
          </home-kpi-container>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import HomeKpiContainer from '@/components/Home/HomeStatistics/HomeStatisticsKpi/HomeStatisticsKpiContainer';

  export default {
    components: { HomeKpiContainer },
    name: 'home-statistics-kpi',
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      performances: {
        type: Object,
        default: () => {
          return {
            codersPerformances: {},
            partnersPerformances: {},
            jobsPerformances: {},
          };
        },
      },
    },
  };
</script>
<style lang="scss" scoped>
  .home-statistics-kpi {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1340px;
    margin-top: 20px;

    &__rows {
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
    }

    &__entity {
      flex-direction: column;
      align-items: center;
      font-style: normal;
      font-weight: $font-weight-medium;
      font-size: x-large;
      letter-spacing: 0.14px;

      &-title {
        font-size: $font-size-xl;
        font-weight: $font-weight-bold;
        color: $color-secondary;
      }
    }

    &__entity-wrapper {
      flex-wrap: wrap;
      min-height: 195px;
      justify-content: space-between;
      margin: 20px 0 10px;
      padding: 15px;
      background: $color-white;
      border: 1px solid $color-grey-1;
      border-radius: $border-radius-s;

      @include bp('desktop') {
        flex-wrap: nowrap;
      }
    }

    &__kpi {
      height: 132px;
      width: 165px;
      margin: 15px;
    }
  }
</style>
