<template>
  <div class="onboarding-upload-data">
    <div v-if="isLoading">
      <bc-spinner></bc-spinner>
    </div>
    <upload-container
      v-else
      class="onboarding-upload-data__upload"
      accept="file/*"
      @on-select="handleSelect"
      :supportedFormats="['xls', 'csv', 'json']"
      :useCropper="false"
      :withLogo="true">
    </upload-container>
  </div>
</template>

<script>
import { upload } from '@/api/upload';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import UploadContainer from '@/components/UploadContainer/UploadContainer';

export default {
  name: 'onboarding-upload-data',
  components: {
    BcSpinner,
    UploadContainer,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    profile() {
      return this.$store.state.user.profile;
    },
  },
  methods: {
    async handleSelect(file) {
      this.isLoading = true;
      try {
        const dataToSend = new FormData();
        dataToSend.append('file', file);
        dataToSend.append('uploadType', 'onboarding_data');
        const { data } = await upload({
          id: this.profile._id,
          file: dataToSend,
        });
        if (data.fileUrl) {
          this.$toast.show({
            type: 'success',
            message: this.$i18n.t('onboarding.upload-data-success'),
          })
        }
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.$toast.show({
          type: 'error',
          message: this.$i18n.t('onboarding.upload-data-failed'),
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding-upload-data {
  align-items: center;
  justify-content: center;

  &__upload {
    min-width: 600px;
    min-height: 380px;
    background: white;
  }
}
</style>
