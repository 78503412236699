<template>
  <div class="card-favorite-lists-infos">
    <div class="card-favorite-lists-infos__id is-ellipsis flex gap-[7px]">
      <div class="flex w-5 justify-center">
        <i :class="iconClass" class="icon-companies card-favorite-lists-infos__icon"/>
      </div>
      {{ listName }}
    </div>
    <div class="card-favorite-lists-infos__elements-count">
      {{ listTypeAndCount }}
    </div>
    <div class="card-favorite-lists-infos__updates body-s--medium">
      <span>{{ updates }}</span>
    </div>
    <div>
      <bc-avatar
        v-tooltip="{ content: `${author.firstName} ${author.lastName}` }"
        :src="author.pictureUrl"
        class="card-favorite-lists-infos__avatar"
        @error="setAlternativeImg"
      ></bc-avatar>
    </div>
    <OwnersList
      :owners="sharedCoaches"
      class=""/>
    <card-favorite-lists-settings
      :id="id"
      :listType="listType"
      :locked="locked">
    </card-favorite-lists-settings>
  </div>
</template>

<script>
  import moment from 'moment/min/moment-with-locales';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import { myJobsPipeName, types } from '@/macros/favoriteLists/favoriteLists';
  import CardFavoriteListsSettings
    from '@/components/Card/CardFavoriteLists/CardFavoriteListsSettings';
  import OwnersList from '@/components/UIKit/OwnersList.vue';

  export default {
    name: 'card-favorite-lists-infos',
    components: {
      OwnersList,
      BcAvatar,
      CardFavoriteListsSettings,
    },
    props: {
      id: {
        type: String,
      },
      name: {
        type: String,
        default: '',
      },
      elementsCount: {
        type: Number,
        default: 0,
      },
      updatedAt: {
        type: String,
        default: '',
      },
      listType: {
        type: Number,
        default: types.coder,
      },
      author: {
        type: Object,
        default: () => ({}),
      },
      subscribers: {
        type: Array,
        default: () => [],
      },
      locked: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      localeForListTypeCount() {
        const mapper = {
          1: 'generics.candidates-count',
          2: 'generics.jobs-count',
          3: 'generics.companies-count',
          4: 'generics.contacts-count',
        };
        return mapper[this.listType];
      },
      listTypeAndCount() {
        return this.$i18n.tc(this.localeForListTypeCount, this.elementsCount);
      },
      iconClass() {
        const mapper = {
          1: 'icon-users',
          3: 'icon-companies',
          4: 'icon-contact',
        };
        return mapper[this.listType];
      },
      user() {
        return this.$store.state.user.profile || {};
      },
      isListTypeJob() {
        return this.listType === types.job;
      },
      isListNameAJobPipe() {
        return this.name === myJobsPipeName;
      },
      isUserTheAuthor() {
        return this.author._id === this.user._id;
      },
      listName() {
        return this.isListTypeJob && this.isListNameAJobPipe && !this.isUserTheAuthor ? `${this.name} (${this.author.firstName} ${this.author.lastName})` : this.name;
      },
      updates() {
        return moment(this.updatedAt).fromNow();
      },
      sharedCoaches() {
        return this.subscribers.filter((subscriber) => subscriber._id !== this.author._id);
      },
      sharedCoachesSlice() {
        return this.sharedCoaches.slice(0, 4);
      },
      sharedCoachesSliceEnd() {
        return this.sharedCoaches.slice(4);
      },
    },
    methods: {
      setAlternativeImg(event) {
        event.target.src = require('@/assets/img/default-avatar.svg');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card-favorite-lists-infos {
    width: 100%;
    flex-direction: row;
    align-items: center;

    & > div {
      flex: 1;
    }

    &__id {
      flex: 2 !important;
      font-weight: $font-weight-medium;
      color: $color-blue-dark-cello;
    }

    &__icon {
      font-size: 1.125rem;
    }

    &__elements-count {
      color: $color-primary;
    }

    &__updates {
      color: #3D5476;

      & > span {
        border-radius: 30px;
        padding: 1px 10px;
        background: $color-blue-light;
      }
    }

    &__avatar {
      align-items: center;
      width: 30px;
      height: 30px;
    }

    &__shared-coaches {
      gap: 5px;

      &--plus {
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        background: $color-blue-dark-cello;
        color: white;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
      }
    }
  }
</style>
