<template>
  <div class="panel-edit-location">
    <label
      :style="labelStyle"
      class="panel-edit-location__label"
      v-if="label">
      <span
        :class="`icon-${labelIcon}`"
        :style="labelIconStyle"
        class="panel-edit-location__icon"
        v-if="labelIcon">
      </span>
      {{ label }}
      <span
        :style="labelStarStyle"
        class="panel-edit-location__required"
        v-if="validate && validate.required">
        *
      </span>
    </label>
    <bc-dropdown
      :bc-autocomplete="true"
      :bc-dropdown-border-color-opacity="0.5"
      :bc-icon-caret-displayed="false"
      :bc-is-asynchronous="true"
      :bc-is-loading="isLoading"
      :bc-is-no-results="isNoResults"
      :bc-options="locationsSuggestions"
      :bc-placeholder="placeholder"
      :value="value"
      @blur="clearLocationsSuggestions"
      @change="$emit('change', $event), locationSet = $event.name, clearLocationsSuggestions()"
      @input="getSuggestions($event), checkValue($event), emptyLocation($event)"
      bc-dropdown-border-color="blue-dark-cello"
      bc-dropdown-border-color-focus="blue-dodger"
      bc-icon-caret-color="blue-dark-cello"
      bc-input-background-color-active="gray-light"
      bc-input-font-color="blue-dark-cello"
      bc-input-font-size="12px"
      bc-input-min-height="40px"
      bc-input-padding="10px 20px"
      bc-input-padding-focus="10px 20px"
      bc-item-background-color-active="blue-dodger"
      bc-item-background-color-hover="blue-dodger"
      bc-item-font-color="blue-dark-cello"
      bc-item-font-size="12px"
      bc-options-border-bottom-radius="5px"
      bc-options-max-height="300px"
      class="panel-edit-location__dropdown"
      v-bind="$attrs"
      v-on="$listeners">
    </bc-dropdown>
  </div>
</template>

<script>
  import BcDropdown from '@/legacy/ui-kit/components/Dropdown/BcDropdown';
  import colors_helpers from '@/legacy/ui-kit/utils/colors_helpers';
  import debounce from 'debounce';
  import { getLocationAutocomplete } from '@/api/location';

  export default {
    name: 'panel-edit-location',
    inheritAttrs: false,
    components: { BcDropdown },
    props: {
      value: {
        type: String
      },
      placeholder: {
        type: String
      },
      label: {
        type: String
      },
      labelIcon: {
        type: String
      },
      /**
       * Define the label color
       */
      labelColor: {
        type: String,
        default: 'black'
      },
      labelFontSize: {
        type: String,
        default: '12px'
      },
      labelIconSize: {
        type: String,
        default: '12px'
      },
      labelIconColor: {
        type: String,
        default: 'black'
      },
      labelStarColor: {
        type: String,
        default: 'blue-dodger'
      },
      labelStarFontSize: {
        type: String,
        default: '14px'
      },
      validate: {
        type: Object
      }
    },
    data: () => ({
      isLoading: false,
      isNoResults: false,
      locationsSuggestions: [],
      locationSet: ''
    }),
    methods: {
      getSuggestions: debounce(async function(value) {
        if (value && value !== this.locations && value !== this.locationSet) {
          this.setIsLoading(true);
          try {
            const response = await getLocationAutocomplete(value);

            const locationsMapped = response.data.predictions.map(location => ({
              ...location,
              placeId: location.place_id,
              name: location.description,
            }));

            this.locationsSuggestions = locationsMapped;

            this.isNoResults = false;
          } catch (error) {
            this.isNoResults = true;

            this.locationsSuggestions = [];
          }
          this.setIsLoading(false);
        }
      }, 500),
      clearLocationsSuggestions() {
        this.locationsSuggestions = [];
      },
      setIsLoading(value) {
        this.isLoading = value;
      },
      checkValue(value) {
        if (value === '') {
          this.locationsSuggestions = [];
        }
      },
      emptyLocation(value) {
        if (!value) {
          this.$emit('change', {});
        }
      }
    },
    computed: {
      labelIconStyle() {
        return {
          fontSize: this.labelIconSize,
          color: colors_helpers.$_checkValidColor(this.labelIconColor)
        };
      },
      labelStyle() {
        return {
          color: colors_helpers.$_checkValidColor(this.labelColor),
          fontSize: this.labelFontSize
        };
      },
      labelStarStyle() {
        return {
          fontSize: this.labelStarFontSize,
          color: colors_helpers.$_checkValidColor(this.labelStarColor)
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .panel-edit-location {
    flex-direction: column;

    &__label {
      font-weight: $font-weight-light;
      margin-bottom: 10px;
      align-items: center;
    }

    &__icon {
      margin-right: 10px;
    }

    &__required {
      margin-left: 5px;
    }
  }
</style>
