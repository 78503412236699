<template>
  <div
    class="panel-chat-messages-bubble">
    <div class="panel-chat-messages-bubble__container">
      <panel-chat-messages-box
        :interlocutor-name="interlocutorName"
        :is-from-user="isFromUser"
        :message="message"
        :text="text"
        @click.native="message.state === 'error' ? resendMessage() : (() => {})">
      </panel-chat-messages-box>
    </div>
  </div>
</template>

<script>
  import PanelChatMessagesBox
    from '@/components/Panel/PanelChat/PanelChatMessages/PanelChatMessagesBox';
  import { mapState } from 'vuex';

  /**
   * The container of the message item
   */
  export default {
    components: {
      PanelChatMessagesBox
    },
    name: 'panel-chat-messages-bubble',
    props: {
      /**
       * The message object to display
       */
      text: {
        type: String
      },
      isFromUser: {
        type: Boolean,
        default: false
      },
      interlocutorName: {
        type: String
      },
      message: {
        type: Object
      }
    },
    computed: {
      ...mapState({
        messages: state => state.chat.messages,
        tabId: state => state.chat.tabId,
        profile: state => state.user.profile
      })
    },
    methods: {
      repushMessage() {
        const newMessagesArray = this.messages;

        newMessagesArray.push(this.message);

        this.$store.dispatch('setMessages', newMessagesArray);
      },

      async resendMessage() {
        try {
          const index = this.messages.findIndex(msg => msg.tabId === this.message.tabId);

          this.$store.dispatch('spliceMessages', index);

          this.repushMessage();

          await this.$store.dispatch('postMessage', {
            id: this.$route.params.id,
            text: this.message.text,
            type: 'text',
            tabId: this.message.tabId,
            state: 'pending'
          });
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .panel-chat-messages-bubble {
    &__container {
      flex-direction: column;
      position: relative;
    }
  }
</style>
