<template>
  <div class="process-card-options">
    <bc-dropdown
      :close-dropdown-at-select="true"
      :is-open="isOpen"
      :options="menuOptions"
      class="process-card-options__dropdown"
      position="bottom-left">
      <template #label="{ isDropdownOpen }">
        <button
          :class="{
            'process-card-options__button--open': isDropdownOpen,
            'process-card-options__button--l': size === 'l',
          }"
          class="process-card-options__button"
        >
          <i class="icon-settings"></i>
        </button>
      </template>
      <template #option="{ activeIndex, index, option }">
        <bc-dropdown-item
          :color="option.color"
          :icon="option.icon"
          :is-focus="activeIndex === index"
          :is-selected="false"
          :text="option.name"
          :title="option.name"
          class="process-card-options__dropdown--item"
          @click.native.prevent.stop="handleClickItem(option)">
        </bc-dropdown-item>
      </template>
    </bc-dropdown>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';

  export default {
    name: 'process-card-options',
    components: {
      BcDropdown,
      BcDropdownItem,
    },
    props: {
      isAbort: {
        type: Boolean,
        default: false,
      },
      isApplication: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: 'm',
      },
    },
    data() {
      return {
        isOpen: true,
      };
    },
    computed: {
      ...mapGetters({
        configurationProcess: 'user/configurationProcess',
      }),
      menuOptions() {

        if (this.isApplication) {
          return [
            { key: 'reject-process', name: this.$t('global.reject'), icon: 'x-circle' },
            { key: 'delete', name: this.$t('generics.delete'), icon: 'trash', color: 'danger' },
          ];
        }

        let options = [
          { key: 'abort-process', name: this.$t('generics.set-to-failed'), icon: 'x-circle' },
          { key: 'delete', name: this.$t('generics.delete'), icon: 'trash', color: 'danger' },
        ];
        if (this.isAbort) {
          options.unshift({
            key: 'unabort-process',
            name: this.$t('generics.cancel-failed'),
            icon: 'x-circle',
          });
          options = options.filter(opt => opt.key !== 'abort-process');
        }
        if (this.configurationProcess?.hasAmount) {
          options.unshift({
            key: 'commission-custom',
            name: this.$t('generics.edit-amount'),
            icon: 'coin-alt',
          });
        }
        if (this.configurationProcess?.displayNextInterview) {
          options = [
            {
              key: 'next-presentation',
              name: this.$t('process.next-presentation-date'),
              icon: 'clock',
            },
            {
              key: 'next-interview',
              name: this.$t('process.interview-date'),
              icon: 'headphones',
            },
            {
              key: 'integration-date',
              name: this.$t('process.integration-date'),
              icon: 'partners',
            },
            ...options
          ];
        }

        return options;
      },
    },
    methods: {
      handleClickItem(option) {
        this.isOpen = true;
        this.$emit('on-action', option.key);
        this.$nextTick(() => {
          this.isOpen = false;
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .process-card-options {
    height: 20px;

    &__button {
      color: $color-blue-heavy-dark;

      &--open {
        color: $color-primary;
      }

      &:hover {
        color: $color-primary;
      }

      &--l {
        font-size: 18px;
      }
    }

    &__dropdown {
      &--item {
        min-width: 207px;;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $color-tertiary;

        &--color:hover {
          :deep() {
            .bc-dropdown-item__text {
              color: white;
            }
          }
        }
      }
    }
  }
</style>
