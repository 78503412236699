<template>
  <div class="panel-process-details-job-validated">
    <panel-process-details-job-validated-form
      :deal="process.deal"
      :process-starts-at="startsAt()"
      :process-validated-at="process.validatedAt"
      class="panel-process-details-job-validated__form"
      v-if="isEditing">
    </panel-process-details-job-validated-form>
    <div
      class="panel-process-details-job-validated__container"
      v-else>
      <div class="panel-process-details-job-validated__wrapper">
        <div class="panel-process-details-job-validated__subwrapper">
          <p
            :class="{ 'panel-process-details-job-validated__title--red': process.failedAt }"
            class="panel-process-details-job-validated__title icon-arrow-left">
            {{ $t('candidates.deal-validate') }}
          </p>
          <bc-tag
            bc-background-color="red-mandy"
            class="panel-process-details-job-validated__tag"
            v-if="process.failedAt">
            {{ $t('candidates.drop') }}
          </bc-tag>
        </div>
        <p class="panel-process-details-job-validated__text">
          {{ $t('candidates.start-of-onboarding') }} :
          <span class="panel-process-details-job-validated__text--bold">
            {{ formattedStartsAt }}
          </span>
        </p>
        <p class="panel-process-details-job-validated__text">
          {{ $t('candidates.end-of-onboarding') }} :
          <span class="panel-process-details-job-validated__text--bold">
            {{ validatedAt }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment/min/moment-with-locales';
  import BcTag from '@/legacy/ui-kit/components/Tag/BcTag';
  import PanelProcessDetailsJobValidatedForm from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsJob/PanelProcessDetailsJobDeal/PanelProcessDetailsJobValidatedForm';

  export default {
    name: 'panel-process-details-job-validated',
    components: { PanelProcessDetailsJobValidatedForm, BcTag },
    props: {
      process: {
        type: Object,
        default: () => ({
          deal: {},
        }),
      },
      isEditing: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      validatedAt() {
        return moment(this.process?.validatedAt).format(
          'L'
        );
      },
      formattedStartsAt() {
        return moment(this.startsAt()).format('L');
      },
    },
    methods: {
      startsAt() {
        let date = '';

        if (this.process.onboarding?.startsAt) {
          date = this.process.onboarding.startsAt;
        } else {
          date = this.process.deal?.startsAt;
        }

        return date;
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-process-details-job-validated {
    flex-direction: column;
    padding: 15px 20px;

    &__name {
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      line-height: 17px;
      color: $color-blue-dark-cello;
      margin-bottom: 15px;
    }

    &__title {
      text-transform: uppercase;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      color: $color-green-bright;
      display: flex;
      align-items: center;

      &--gold {
        color: $color-blue-dark-cello;

        &:before {
          color: $color-green-bright;
        }
      }

      &--red {
        color: $color-red-mandy;
      }

      &:before {
        margin-right: 10px;
        font-size: $font-size-l;
        transform: rotate(180deg);
      }
    }

    &__subwrapper {
      align-items: center;
      margin-bottom: 5px;
    }

    &__tag {
      border-radius: 4px;
      font-size: $font-size-xs;
      padding: 3px 10px;
      margin-left: 10px;
    }

    &__text {
      display: flex;
      color: $color-blue-dark-cello;
      font-size: $font-size-s;
      line-height: 17px;
      padding-left: 28px;

      &--bold {
        font-weight: $font-weight-medium;
        font-size: $font-size-s;
        display: flex;
        margin: 2px 0 2px 5px;
      }
    }

    &__container {
      justify-content: space-between;
      align-items: center;
    }

    &__wrapper {
      flex-direction: column;
    }
  }
</style>
