<template>
  <div class="views-tab">
    <div class="views-tab__menu gap-11">
      <slot name="header"></slot>
      <div v-for="item in tabs" :key="item.key">
        <router-link
          v-if="forRoute"
          :class="{ 'views-tab__menu-item--active': isActive(item) }"
          :exact="item.exact"
          :to="item.to ? item.to : ''"
          class="views-tab__menu-item flex items-center gap-[5px]"
          @click="item.onClick ? item.onClick() : () => null">
          <i
            v-if="item.icon"
            :class="`icon-${item.icon}`"
            :style="item.iconFontSize ? `font-size: ${item.iconFontSize};` : ''"/>
          <span class="first-letter:capitalize">{{ item.label }}</span>
          <div
            v-if="item.beta"
            class="ml-1 rounded-full bg-orange-500 px-1.5 text-xs font-medium text-white">
            Beta
          </div>
          <div
            :class="{
            'views-tab__count--active': isActive(item),
            invisible: item.count === undefined,
          }"
            class="views-tab__count">
            <span>{{ item.count ?? 0 }}</span>
          </div>
        </router-link>
        <button
          v-else
          :class="{
            'views-tab__menu-item--active': isActive(item),
            'pointer-events-none !text-[#8e9bae]': item.disabled
          }"
          class="views-tab__menu-item flex items-center gap-[5px]"
          @click="item.onClick">
          <i
            v-if="item.icon"
            :class="`icon-${item.icon}`"
            :style="item.iconFontSize ? `font-size: ${item.iconFontSize};` : ''"/>
          <span class="first-letter:capitalize">{{ item.label }}</span>
          <div
            v-if="item.beta"
            class="ml-1 rounded-full bg-orange-500 px-1.5 text-xs font-medium text-white">
            Beta
          </div>
          <div
            :class="{
              'views-tab__count--active': isActive(item),
              invisible: item.count === undefined || item.count === null,
            }"
            class="views-tab__count">
            <span>{{ item.count ?? 0 }}</span>
          </div>
        </button>
        <div
          :class="{
            'views-tab__menu-bar--left': isActive(item) && direction === 'left',
            'views-tab__menu-bar--right': isActive(item) && direction === 'right',
          }"
          class="views-tab__menu-bar"></div>
      </div>
    </div>
    <div v-if="$slots.content" class="views-tab__content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed, defineProps, onMounted, ref, withDefaults } from 'vue';
  import { useRoute } from 'vue-router/composables';

  type Tab = {
    key: string;
    label: string;
    to?: string | { path: string };
    count?: number;
    exact?: boolean;
    icon?: string;
    iconFontSize?: string;
    onClick?: () => void;
    disabled?: boolean;
    beta?: boolean;
  };
  const props = withDefaults(defineProps<{
    tabs?: Tab[];
    forRoute?: boolean;
    active?: string;
  }>(), {
    tabs: () => [],
    forRoute: true,
    active: '',
  });

  const route = useRoute();

  const direction = ref('');

  const activeIndex = computed(() => {
    if (props.forRoute) {
      return props.tabs.findIndex(item => route.path === getPath(item));
    }
    return props.tabs.findIndex(item => item.key === props.active);
  });

  function getPath(item: Tab) {
    if (!item.to) return;

    if (typeof item.to === 'string') {
      return item.to.split('?')[0];
    }
    return item.to.path.split('?')[0];
  }

  function isActive(item: Tab) {
    if (props.forRoute) {
      return route.path === getPath(item);
    }
    return item.key === props.active;
  }

  function setDirection(path: string) {
    const nextIndex = props.tabs.findIndex(tab => path === getPath(tab));
    direction.value = nextIndex > activeIndex.value ? 'right' : 'left';
  }

  onMounted(() => {
    if (props.forRoute) {
      const prevPath = sessionStorage.getItem('marvin-from-path');
      setDirection(prevPath);
    } else {
      direction.value = 'left';
    }
  });
</script>

<style lang="scss" scoped>
  .views-tab {
    width: 100%;
    height: 67px;
    border-bottom: 1px solid $color-blue-light;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;

    &__menu {
      height: 100%;
      align-items: end;

      & > div {
        flex-direction: column;
      }

      &-item {
        padding-bottom: 10px;
        color: $color-tertiary;
        font-weight: 400;
        font-size: 16px;

        &--active {
          color: $color-primary;
        }
      }

      &-bar {
        height: 4px;
      }

      &-bar--left {
        width: 100%;
        background: $color-primary;
        border-radius: 10px 10px 0px 0px;
        animation: left-slide 0.2s ease-in;
      }

      &-bar--right {
        width: 100%;
        background: $color-primary;
        border-radius: 10px 10px 0px 0px;
        animation: right-slide 0.2s ease-in;
      }
    }

    &__count {
      background: $color-blue-medium;
      border-radius: 20px;
      align-items: center;
      justify-content: center;
      min-width: 20px;
      min-height: 20px;
      padding: 0 3px;

      & > span {
        color: $color-tertiary;
        font-size: $font-size-xs;
      }

      &--active {
        background: $color-primary;

        & > span {
          color: white;
        }
      }
    }
  }
</style>
