<template>
  <div class="searchbar-filters-role">
    <p class="searchbar-filters-role__title">
      {{ $t('panel-coder-edit.function') }}
    </p>
    <div class="searchbar-filters-role__wrapper">
      <bc-checkbox
        class="searchbar-filters-role__checkbox"
        id="operationnel"
        v-model="operationnel">
        {{ $t('table-header.operational') }}
      </bc-checkbox>
      <bc-checkbox
        class="searchbar-filters-role__checkbox"
        id="lead"
        v-model="lead">
        {{ $t('table-header.lead') }}
      </bc-checkbox>
      <bc-checkbox
        class="searchbar-filters-role__checkbox"
        id="manager"
        v-model="manager">
        {{ $t('table-header.manager') }}
      </bc-checkbox>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { buildRoleTag } from '@/helpers/searchbar/factories';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';

  export default {
    components: { BcCheckbox },
    name: 'searchbar-filters-role',
    computed: {
      ...mapState({
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags,
      }),
      operationnel: {
        get() {
          return this.searchbarFiltersTags.some(
            tag => tag.type === 'role' && tag.query === 'operationnel'
          );
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildRoleTag('operationnel'));
          } else {
            const tagIndex = this.searchbarFiltersTags.findIndex(
              tag => tag.type === 'role' && tag.query === 'operationnel'
            );
            if (tagIndex >= 0) {
              this.$emit('on-unselect', tagIndex);
            }
          }
        },
      },
      lead: {
        get() {
          return this.searchbarFiltersTags.some(
            tag => tag.type === 'role' && tag.query === 'lead'
          );
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildRoleTag('lead'));
          } else {
            const tagIndex = this.searchbarFiltersTags.findIndex(
              tag => tag.type === 'role' && tag.query === 'lead'
            );
            if (tagIndex >= 0) {
              this.$emit('on-unselect', tagIndex);
            }
          }
        },
      },
      manager: {
        get() {
          return this.searchbarFiltersTags.some(
            tag => tag.type === 'role' && tag.query === 'manager'
          );
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildRoleTag('manager'));
          } else {
            const tagIndex = this.searchbarFiltersTags.findIndex(
              tag => tag.type === 'role' && tag.query === 'manager'
            );
            if (tagIndex >= 0) {
              this.$emit('on-unselect', tagIndex);
            }
          }
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-filters-role {
    flex-direction: column;
    @include bp('tablet') {
      flex-direction: row;
    }

    &__title {
      margin-bottom: 20px;
      font-size: $font-size-s;
      justify-content: center;
      color: $color-blue-dark-cello;
      line-height: 18px;
      @include bp('tablet') {
        justify-content: flex-start;
        text-align: left;
        margin-bottom: 0;
        width: 85px;
        margin-right: 35px;
      }
    }

    &__wrapper {
      position: relative;
      flex-wrap: wrap;
      margin: -5px -15px;
      width: 100%;
      @include bp('tablet') {
        min-width: 305px;
        width: initial;
        justify-content: initial;
      }
    }

    &__checkbox {
      justify-content: center;
      margin: 5px 15px;
      @include bp('tablet') {
        flex: initial;
      }
    }
  }
</style>
