import { defineStore } from 'pinia';
import { Component, DefineComponent } from 'vue';

type State = {
  modal: DefineComponent | Component | null;
  modalProps: Record<string, any>
};

export const usePanelStore = defineStore('panel', {
  state: (): State => {
    return {
      modal: null,
      modalProps: {},
    };
  },
  actions: {
    openModal<T extends Record<string, any> = {}>(modalComponent: DefineComponent | Component, props: T = {} as T) {
      this.modal = modalComponent;
      this.modalProps = props;
    },
    closeModal() {
      this.modal = null;
      this.modalProps = {};
    },
  },
});
