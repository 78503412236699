<template>
  <div class="bc-text-editor mv-text-editor-preview">
    <label
      v-if="label"
      class="bc-text-editor__label"
    >
      {{ label }} <span v-if="required" class="bc-text-editor__required">*</span>
    </label>
    <ckeditor
      v-model="content"
      :config="editorConfig"
      :editor="editor"
      v-bind="$attrs">
    </ckeditor>
  </div>
</template>

<script>
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {
    name: 'bc-text-editor',
    props: {
      value: {
        type: String,
        required: true,
      },
      config: {
        type: Object,
        default: () => ({}),
      },
      label: {
        type: String,
      },
      required: {
        type: Boolean,
      },
    },
    data() {
      return {
        editor: ClassicEditor,
        editorConfig: {
          toolbar: {
            items: ['heading', '|', 'bold', 'italic', 'underline', 'strikethrough', 'link', '|', 'undo', 'redo', '|'],
          },
          ...this.config,
        },
      };
    },
    computed: {
      content: {
        get() {
          return this.value;
        },
        set(content) {
          this.$emit('input', content);
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .bc-text-editor {
    display: block;


    &__label {
      color: $color-blue-heavy-dark;
      font-size: $font-size-xs;
      font-weight: 400;
      margin-bottom: 5px;
      margin-left: 10px;
    }

    & :deep() {
      --ck-color-toolbar-border: #D9DFFD;
      --ck-color-base-border: #D9DFFD;
      --ck-border-radius: 5px;

      div {
        display: block;
      }

      p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }

      .ck-editor__editable {
        min-height: 250px;
        max-height: 400px;
        overflow: auto;
        padding: 0px 30px;
      }

      .ck-editor__top {
        border-top-left-radius: 25px !important;
        border-top-right-radius: 25px !important;
      }
    }

    &__required {
      color: $color-red-mandy;
      margin-left: 3px;
      font-size: 12px;
    }
  }
</style>
