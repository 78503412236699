<template>
  <div class="panel-poke-caption-status">
    <panel-poke-caption-step
      :color="step.color"
      :date="step.date"
      :icon="step.icon"
      :key="index"
      :locked="step.locked"
      :status="step.status"
      :timer="step.timer"
      :timer-background-color="step.timerBackgroundColor"
      :timer-color="step.timerColor"
      :tooltip="step.tooltip"
      class="panel-poke-caption-status__step"
      v-for="(step, index) in steps">
      {{ step.text }}
    </panel-poke-caption-step>
  </div>
</template>

<script>
  import PanelPokeCaptionStep from '@/components/Panel/PanelPoke/PanelPokeCaption/PanelPokeCaptionStep';
  import colors_helpers from '@/legacy/ui-kit/utils/colors_helpers';
  import moment from 'moment/min/moment-with-locales';
  import { mapState } from 'vuex';
  import pokes from '@/common-old/mixins/Pokes/pokes';

  export default {
    name: 'panel-poke-caption-status',
    components: { PanelPokeCaptionStep },
    props: {
      /**
       * The Object that contains the content of the response that will be displayed by the card
       */
      cardContent: Object,
      /**
       * The index of the object
       */
      cardIndex: Number
    },
    data: () => ({
      /**
       * The timer before the request will be send
       */
      timer: 0,
      completedSteps: 100
    }),
    mixins: [pokes],
    computed: {
      ...mapState({
        config: state => state.config.config
      }),
      /**
       * Determinate if the poke is a received poke
       */
      isReceivedPoke() {
        return this.cardContent.status === 2 && this.cardContent.direction === 1;
      },
      /**
       * The button style depending of the request status
       */
      buttonStyle() {
        const maps = {
          initial: {
            color: 'green-bright',
            type: 'filled-border',
            textValue: 'Chatter',
            fn: this.acceptPoke
          },
          loading: {
            color: 'green-bright',
            type: 'filled-border',
            textValue: 'Chargement'
          },
          pending: {
            color: 'red-amaranth',
            type: 'filled-border',
            textValue: 'Annuler',
            fn: this.cancelPokeBack
          },
          error: {
            color: 'red-amaranth',
            type: 'filled-border',
            textValue: 'Erreur',
            fn: this.acceptPoke
          }
        };
        const status = Object.keys(this.requestStatus).find(key => {
          return this.requestStatus[key] === true;
        });
        return maps[status];
      },
      /**
       * Determinate which status should be showed
       * @returns {*}
       */
      status() {
        return this.cardContent.status ? this.$_pokeStatus[this.cardContent.status] : 'poked';
      },
      /**
       * It's an array which will indicate which step the poke is
       * @returns {*[]}
       */
      steps() {
        const steps = [
          {
            status: this.cardContent.direction === 1 ? 'Demande reçue' : 'Demande envoyée',
            icon: this.cardContent.direction === 1 ? 'envelop' : 'plane',
            color: 'blue',
            date: this.cardContent.startsAt ? moment(this.cardContent.startsAt).format('L') : 'En attente d\'un poke',
            timer: this.cardContent.status <= 2 ? this.completedSteps : 100,
            timerColor: colors_helpers.$_checkValidColor('blue'),
            timerBackgroundColor: colors_helpers.$_checkValidColor('gray-mystic'),
            tooltip: {
              content: this.cardContent.status <= 2 ? `${moment(this.cardContent.expiresAt).locale('fr').fromNow(true)} avant expiration` : '',
              placement: 'top'
            }
          }
        ];
        return steps;
      }
    },
    methods: {
      getCompletedSteps() {
        /**
         * The function that return a percentage of the expiration of the poke to apply the animation
         */
        setTimeout(() => {
          const currentTime = new Date().getTime();
          const expiresAt = new Date(this.cardContent.expiresAt).getTime();
          const startsAt = new Date(this.cardContent.startsAt).getTime();
          this.completedSteps = 100 - (((currentTime - startsAt) / (expiresAt - startsAt)) * 100);
        }, 200);
      }
    },
    mounted() {
      this.getCompletedSteps();
    }
  };
</script>

<style lang="scss" scoped>
  .panel-poke-caption-status {
    width: 100%;
    justify-content: stretch;
    align-items: center;
    padding: 10px 0;
    @include bp('tablet') {
      max-width: 170px;
      justify-content: center;
      flex-direction: column;
      min-height: 160px;
      margin: 0 10px;
      padding: 0;
    }

    &__step {
      width: 100%;
      height: 100%;
      min-height: 120px;
      @include bp('tablet') {
        min-height: 70px;
      }
    }

    &__wrapper {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &__button {
      font-size: $font-size-l;
      text-transform: uppercase;
      width: calc(100% - 10px);
      justify-content: center;
      text-align: center;
      margin: 20px auto 10px 10px;
      box-shadow: $shadow-default;
      @include bp('tablet') {
        margin: 0 0 10px;
        width: 100%;
      }

      &-timer {
        margin-right: 5px;
      }
    }

    &__text {
      text-decoration: underline;
      font-size: $font-size-xs;
      color: $color-blue-dark-cello;
      line-height: 15px;

      &:hover {
        cursor: pointer;
        text-decoration: initial;
      }
    }
  }
</style>
