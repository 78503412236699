<template>
  <div class="views-topbar-create-dropdown">
    <div
      ref="panel"
      class="views-topbar-create-dropdown__panel"
      v-bind="$listeners">
      <slot name="header"></slot>
    </div>
    <div
      ref="content"
      class="views-topbar-create-dropdown__content">
      <div
        ref="wrapper"
        class="views-topbar-create-dropdown__wrapper">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'views-topbar-create-dropdown',
    inheritAttrs: false,
    data() {
      return {
        contentHeight: 0,
      };
    },
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      setContentHeight() {
        this.contentHeight = this.$refs.content && this.isActive ? (this.$refs.content.scrollHeight * 2) : 0;
      },
    },
    watch: {
      isActive: {
        handler() {
          this.setContentHeight();
        },
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.setContentHeight();
      });
    },
  };
</script>

<style lang="scss" scoped>
  .views-topbar-create-dropdown {
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    border: 1px solid transparent;
    flex-direction: column;
    overflow: hidden;

    &__panel {
      cursor: pointer;
      width: 100%;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding-left: 20px;
      padding-top: 8px;
      margin-bottom: 5px;
    }

    &__content {
      min-height: 0;
      transition: 0.3s;
      overflow: hidden;
      width: 100%;
    }

    &__wrapper {
      flex-shrink: 0;
      height: auto;
      min-height: 0;
      align-items: center;
      width: 100%;
      white-space: nowrap;
    }
  }
</style>
