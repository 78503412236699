/*
    This helpers contains all function to decode url return
    array or object ready to be used to build tag
 */
export const splitQuery = (query) => query.split(',');
export const mapLocationQuery = (query) => {
  return query
    .split('|')
    .map(location => location.split('+'))
    .map(location => ({
      name: location[0],
      placeId: location[1],
    }));
};
export const decodeMatchingQuery = (query) => `${decodeURIComponent(query['job-name'])} - ${decodeURIComponent(query['company-name'])}`;
export const decodeSalaryQuery = (query) => ({
  'salaryMin': query['salary-min'],
  'salaryMax': query['salary-max']
});

export default {
  splitQuery,
  mapLocationQuery,
  decodeMatchingQuery,
  decodeSalaryQuery,
};

