<template>
  <td
    v-click-outside="setEditableToFalse"
    :class="`${columnType.toLowerCase()} ${
      showModal ? `modal--${columnType.toLowerCase()}` : ''
    }`"
    @click="setEditableToTrue">
    <template v-if="canEdit">
      <p v-if="!isEditable">
        {{ $t(content) }}
      </p>
      <bc-dropdown
        v-else
        :options="columnType === 'Xp' ? dropdownXp : dropdownContacts">
        <template #label="{ isDropdownOpen }">
          <bc-dropdown-trigger
            :is-arrow="true"
            :is-dropdown-open="isDropdownOpen"
            :text="$t(content)">
          </bc-dropdown-trigger>
        </template>
        <template #option="{ activeIndex, index, option }">
          <bc-dropdown-item
            :is-focus="activeIndex === index"
            :text="$t(option)"
            @click.native.stop="dataInput(option, index)">
          </bc-dropdown-item>
        </template>
      </bc-dropdown>
    </template>
    <template v-else>
      <p>
        {{ $t(content) }}
      </p>
    </template>
  </td>
</template>

<script>
import BcDropdown from '../../../ui-kit/components/BcDropdown/BcDropdown.vue';
import BcDropdownItem from '../../../ui-kit/components/BcDropdown/BcDropdownItem';
import BcDropdownTrigger from '../../../ui-kit/components/BcDropdown/BcDropdownTrigger';
import experiences from '@/macros/hunt/experiences';

export default {
  $inheritAttrs: false,
  name: 'dropdowncell',
  components: {
    BcDropdown,
    BcDropdownItem,
    BcDropdownTrigger,
  },
  data() {
    return {
      isEditable: false,
      inputValue: '',
    };
  },
  props: {
    job: {
      type: Object,
    },
    columnType: {
      type: String,
      required: true,
    },
    companyContacts: {
      type: Array,
    },
    canEdit: {
      type: Boolean,
    },
    editFromModal: {
      type: Boolean,
    },
    showModal: {
      type: Boolean,
    },
  },
  methods: {
    populateData() {
      if (this.columnType.toLowerCase() === 'contact' && this.job?.interlocuteur) {
        return `${this.job.interlocuteur.firstName} ${this.job.interlocuteur.lastName}`;
      }
      if (
        !this.job[
          this.columnType.toLowerCase() === 'xp'
            ? 'experience'
            : this.columnType.toLowerCase()
        ]
      ) {
        if (this.editFromModal) {
          if (this.inputValue.length) return this.inputValue;
          return (this.inputValue = this.$i18n.t('generics.none'));
        }

        if (this.columnType.toLowerCase() === 'contact') {
          this.$i18n.t('generics.none');
        }
        return this.inputValue;
      }

      if (this.editFromModal) {
        if (this.inputValue.length) return this.inputValue;
      }

      if (this.columnType.toLowerCase() === 'xp') {
        if (this.job.experience) {
          const experience = experiences.find(
            experience => experience.api === this.job.experience
          );
          return experience ? experience.name : '';
        }

        return '';
      }

      return this.inputValue;
    },
    dataInput(value, xpIndex) {
      this.inputValue = value;
      this.isEditable = false;

      const optionCell = {
        columnName: this.columnType.toLocaleLowerCase(),
        data: null,
      };

      if (this.columnType.toLowerCase() === 'contact') {
        const arrayToLoop = this.job._id
          ? this.job.companyCollab
          : this.companyContacts;

        arrayToLoop.forEach(
          function(collab) {
            if (this.inputValue === `${collab.firstName} ${collab.lastName}`) {
              optionCell.data = collab._id;
            }
          }.bind(this)
        );
      }

      if (this.columnType.toLowerCase() === 'xp') {
        optionCell.data = experiences[xpIndex].api;
      }

      if (!this.job._id) {
        return this.$emit('update-cell-data', optionCell);
      }

      this.$emit('update-cell-data', { ...optionCell, jobId: this.job._id });
      this.populateData();
    },
    setEditableToFalse() {
      this.isEditable = false;
    },
    setEditableToTrue() {
      setTimeout(() => {
        this.isEditable = true;
      }, 50);
    },
  },
  computed: {
    dropdownXp: () => {
      return experiences?.map(experience => experience.name) || [];
    },
    dropdownContacts() {
      if (this.job?._id) {
        return [
         this.$i18n.t('generics.none'),
          ...(this.job.companyCollab?.map(c => `${c.firstName} ${c.lastName}`) || []),
        ];
      }

      return [
        this.$i18n.t('generics.none'),
        ...(this.companyContacts?.map(
          contact => `${contact.firstName} ${contact.lastName}`
        ) || []),
      ];
    },
    content() {
      return this.populateData();
    },
  },
};
</script>

<style lang="scss">
.modal--xp {
  width: 9% !important;
  max-height: 10%;
  height: 10%;

  div {
    div {
      button {
        width: 100% !important;
      }
    }
  }
}

td {
  padding: 14px;

  .bc-dropdown {
    width: 100%;

    div {
      border: none;

      .bc-dropdown-trigger--success {
        border: none;
        color: $color-primary;

        span {
          max-width: none;
        }
      }
    }
  }
}

@media (min-width: 1440px) and (max-width: 1680px) {
  .xp {
    width: 16%;
  }
}

@media (min-width: 1680px) {
  .xp {
    width: 14%;
  }
}
</style>
