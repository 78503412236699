<template>
  <div class="panel-favorite-list-modal-new-owner">
    <p class="panel-favorite-list-modal-new-owner__text panel-favorite-list-modal-new-owner__text--large">
      {{ $t('companies.panel-company.you-sure-name-new-owner') }}
    </p>
    <p class="panel-favorite-list-modal-new-owner__text panel-favorite-list-modal-new-owner__text--small">
      {{ $t('companies.panel-company.you-will-become-an-administrator-list') }}
    </p>
    <div class="panel-favorite-list-new-owner__cta">
      <bc-button
        @click.native="goCancel"
        bc-backgroud-color-hover="red-mandy"
        bc-background-color="red-mandy"
        bc-radius="54px"
        bc-text-color="white"
        class="panel-favorite-list-modal-new-owner__validate">
        {{ $t('generics.cancel') }}
      </bc-button>
      <bc-button
        @click.native="goValidate"
        bc-background-color="blue-dodger"
        bc-background-color-hover="blue-dodger"
        bc-radius="54px"
        bc-text-color="white"
        class="panel-favorite-list-modal-new-owner__validate">
        {{ $t('generics.validate') }}
      </bc-button>
    </div>
  </div>
</template>

<script>
  import BcButton from '@/legacy/ui-kit/components/Button/BcButton';
  // import mixin
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';

  export default {
    name: 'panel-favorite-list-modal-new-owner',
    components: { BcButton },
    mixins: [PanelNavigation],
    methods: {
      goValidate() {
        this.$emit('on-validate', 'setOwner');
      },
      goCancel() {
        this.$emit('close-modal');
      }
    }
  };
</script>

<style lang=scss scoped>
  @import '@/assets/scss/layouts/panel-favorite-list-modal';

  .panel-favorite-list-modal-new-owner {
    @include panel-favorite-list-modal
  }
</style>
