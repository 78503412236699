<template>
  <div class="panel-poke-caption-pokers-card">
    <div class="panel-poke-caption-pokers-card__main-wrapper">
      <div
        class="panel-poke-caption-pokers-card__caption">
        <picture-avatar
          :img-src="cardContent.pictureUrl ? cardContent.pictureUrl : ''"
          class="panel-poke-caption-pokers-card__picture">
        </picture-avatar>
        <card-informations-pokes-content-contracts
          :contracts="cardContent.contracts">
        </card-informations-pokes-content-contracts>
      </div>
      <!-- -->
      <title-pokes
        :text-left="cardContent.name"
        :text-right="cardContent.title"
        class="panel-poke-caption-pokers-card__title">
        <div
          class="panel-poke-caption-pokers-card__remote-container"
          slot="icon"
          v-if="cardContent.remote">
          <div class="icon-home"></div>
        </div>
      </title-pokes>
      <!-- -->
    </div>
  </div>
</template>

<script>
  import PictureAvatar from '@/components/UIKit/Picture/PictureAvatar';
  import TitlePokes from '@/components/UIKit/Title/TitlePokes';
  import countdown from '@/common-old/mixins/Timer/countdown';
  import CardInformationsPokesContentContracts
    from '@/components/Card/CardInformations/CardInformationsPokes/CardInformationsPokesContent/CardInformationsPokesContentContracts';
  // state

  export default {
    name: 'panel-poke-caption-pokers-card',
    components: {
      CardInformationsPokesContentContracts,
      PictureAvatar,
      TitlePokes
    },
    props: {
      side: {
        type: String,
        default: 'left'
      },
      cardContent: {
        type: Object
      }
    },
    mixins: [countdown],
    computed: {
      isOnlineFrom() {
        return this.$_onlineSince(this.cardContent.startsAt);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .panel-poke-caption-pokers-card {
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    min-height: 160px;
    @include bp('tablet') {
      align-items: flex-end;
    }
    &__main-wrapper {
      width: 100%;
      height: 100%;
      flex-direction: column;
    }
    &__title {
      padding: 15px;
    }
    &__tooltip-container {
      position: absolute;
      top: -60px;
      left: 50%;
      transform: translateX(-50%);
      width: 120px;
      height: 50px;
    }
    &__tooltip {
      bottom: 75%;
    }
    &__caption {
      position: relative;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      transition: 0.3s;
      overflow: hidden;
      padding: 20px 15px 0;
      &:before {
        position: absolute;
        background-color: $color-blue-hawkes;
        content: "";
        width: calc(100% + 1px);
        height: 100%;
        clip-path: polygon(0 0, 100% 0, 100% 47%, 0 81%);
        left: 0;
        top: 0;
      }
    }
    &__picture {
      max-height: 50px;
      max-width: 50px;
      z-index: 1;
    }
    &__contract {
      border-radius: 4px;
      padding: 5px;
      z-index: 1;
      justify-content: center;
      @include bp('tablet') {
        position: initial;
        z-index: 2;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
      }
    }
    &__contract-text {
      font-size: $font-size-xxs;
      line-height: 14px;
      color: $color-white;
    }
  }

  .tooltip {
    &-wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: $font-size-xxs;
    }
    &-text {
      text-align: center;
      line-height: 14px;
    }
  }
</style>
