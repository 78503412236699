import { getCoaches } from '@/api/coaches';

export default {
  state: {
    coaches: [],
  },
  getters: {
    mentionCoaches(state) {
      return state.coaches
        .filter(coach => coach.enabled)
        .map(coach => ({ id: coach._id, name: `${coach.firstName} ${coach.lastName}`, pictureUrl: coach.pictureUrl }));
    },
  },
  actions: {
    async getCoaches({ commit }, query) {
      const response = await getCoaches(query);

      commit('setCoaches', response.data.coaches);

      return response;
    },
    updateCoach({ commit }, data) {
      commit('updateCoach', data);
    },
  },
  mutations: {
    setCoaches(state, data) {
      state.coaches = data;
    },
    updateCoach(state, data) {
      const index = state.coaches.findIndex(coach => coach._id === data._id);

      state.coaches.splice(index, 1, data);
    },
  },
};
