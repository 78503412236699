<template>
  <div class="searchbar-filters-jobs-status">
    <p class="searchbar-filters-jobs-status__title">
      {{ $t('generics.status') }}
    </p>
    <div class="searchbar-filters-jobs-status__wrapper">
      <bc-radio
        :checked="all"
        class="searchbar-filters-jobs-status__checkbox"
        id="all"
        name="all"
        v-model="all">
        Tous
      </bc-radio>
      <bc-radio
        :checked="online"
        class="searchbar-filters-jobs-status__checkbox"
        id="online"
        name="online"
        v-model="online">
        Publié
      </bc-radio>
      <bc-radio
        :checked="offline"
        class="searchbar-filters-jobs-status__checkbox"
        id="offline"
        name="offline"
        v-model="offline">
        Non publié
      </bc-radio>
      <bc-radio
        :checked="draft"
        class="searchbar-filters-jobs-status__checkbox"
        id="draft"
        name="draft"
        v-model="draft">
        Brouillon
      </bc-radio>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { buildJobStatusTag } from '@/helpers/searchbar/factories/jobsTag';
  import BcRadio from '@/ui-kit/components/BcRadio/BcRadio';

  export default {
    name: 'searchbar-filters-jobs-status',
    components: {
      BcRadio,
    },
    computed: {
      ...mapState({
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags,
      }),
      all: {
        get() {
          return !this.searchbarFiltersTags.some(tag => tag.type === 'status');
        },
        set() {
          const filteredTags = this.searchbarFiltersTags.filter(tag => tag.type !== 'status');

          this.$emit('on-unselect', filteredTags);
        },
      },
      online: {
        get() {
          return this.searchbarFiltersTags.some(tag => tag.query === 'online' && tag.type === 'status');
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildJobStatusTag('online'));
          } else {
            const tagIndex = this.searchbarFiltersTags.findIndex(tag => tag.type === 'status' && tag.query === 'online');

            if (tagIndex >= 0) {
              this.$emit('on-unselect', tagIndex);
            }
          }
        },
      },
      offline: {
        get() {
          return this.searchbarFiltersTags.some(tag => tag.type === 'status' && tag.query === 'offline');
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildJobStatusTag('offline'));

          } else {
            const tagIndex = this.searchbarFiltersTags.findIndex(tag => tag.type === 'status' && tag.query === 'offline');

            if (tagIndex >= 0) {
              this.$emit('on-unselect', tagIndex);
            }
          }
        },
      },
      draft: {
        get() {
          return this.searchbarFiltersTags.some(tag => tag.type === 'status' && tag.query === 'draft');
        },
        set(value) {
          if (value) {
            this.$emit('on-select', buildJobStatusTag('draft'));
          } else {
            const tagIndex = this.searchbarFiltersTags.findIndex(tag => tag.type === 'status' && tag.query === 'draft');

            if (tagIndex >= 0) {
              this.$emit('on-unselect', tagIndex);
            }
          }
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-filters-jobs-status {
    flex-direction: column;
    padding: 20px 20px -20px;
    @include bp('tablet') {
      flex-direction: row;
    }

    &__title {
      margin-bottom: 20px;
      font-size: $font-size-s;
      color: $color-blue-dark-cello;
      line-height: 18px;
      justify-content: center;
      @include bp('tablet') {
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 0;
        max-width: 85px;
        margin-right: 35px;
      }
    }

    &__wrapper {
      position: relative;
      flex-wrap: wrap;
      margin: -5px -15px;
    }

    &__checkbox {
      justify-content: center;
      margin: 5px 15px;
      @include bp('tablet') {
        flex: initial;
        margin-right: 30px;
      }
    }
  }
</style>
