<template>
  <div
    :class="{'searchbar-contacts--open': isActiveFilter}"
    class="searchbar-contacts">
    <button-default
      v-if="isActiveFilter"
      bc-background-color="transparent"
      bc-icon="cross"
      bc-icon-size="20px"
      bc-padding="0px"
      bc-text-color="blue-cadet"
      class="searchbar-contacts__icon hidden-md hidden-sm"
      @click.native="closeSearchbar">
    </button-default>
    <div class="searchbar-contacts__wrapper">
      <searchbar-autocomplete-contacts
        class="searchbar-contacts__autocomplete"
        @on-delete="updateRouteQueries"
        @on-reset="resetSearchbar"
        @on-select="addTag($event), updateRouteQueries()">
      </searchbar-autocomplete-contacts>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import SearchbarCoderDispatcher from '@/helpers/searchbar/dispatcher';
  import { mapLocationQuery, splitQuery } from '@/helpers/url/decode_url';
  import { buildCoderStatusTag } from '@/helpers/searchbar/factories/codersTag';
  import {
    buildContractTag,
    buildCVTag,
    buildDomainTag,
    buildEmailTag,
    buildExperienceTag,
    buildCompaniesTag,
    buildLinkedinTag,
    buildLocationsTag,
    buildPhoneTag,
    buildProfessionsTag,
    buildRatingTag,
    buildRemoteTag,
    buildRoleTag,
    buildSearchTag,
    buildCustomFieldTag,
  } from '@/helpers/searchbar/factories';
  // components
  import ButtonDefault from '@/legacy/ui-kit/components/Button/BcButton';
  import SearchbarAutocompleteContacts
    from '@/components/Searchbar/SearchbarContacts/SearchbarAutocompleteContacts';

  export default {
    name: 'searchbar-contacts',
    components: {
      SearchbarAutocompleteContacts,
      ButtonDefault,
    },
    mixins: [SearchbarCoderDispatcher],
    props: {
      resultsCount: {
        type: Number,
      },
      listId: {
        type: String,
      },
    },
    data() {
      return {
        identifier: +new Date(),
        mapComplexQuery: {
          search: {
            queryBuilder: splitQuery,
            tagBuilder: buildSearchTag,
          },
          locations: {
            queryBuilder: mapLocationQuery,
            tagBuilder: buildLocationsTag,
          },
          status: {
            queryBuilder: splitQuery,
            tagBuilder: buildCoderStatusTag,
          },
          experiences: {
            queryBuilder: splitQuery,
            tagBuilder: buildExperienceTag,
          },
          roles: {
            queryBuilder: splitQuery,
            tagBuilder: buildRoleTag,
          },
          domains: {
            queryBuilder: splitQuery,
            tagBuilder: buildDomainTag,
          },
          remotes: {
            queryBuilder: splitQuery,
            tagBuilder: buildRemoteTag,
          },
          lastCoachActivity: {},
        },
        mapSimpleQuery: {
          contracts: {
            tagBuilder: buildContractTag,
          },
          phone: {
            tagBuilder: buildPhoneTag,
          },
          cv: {
            tagBuilder: buildCVTag,
          },
          email: {
            tagBuilder: buildEmailTag,
          },
          linkedin: {
            tagBuilder: buildLinkedinTag,
          },
          rating: {
            tagBuilder: buildRatingTag,
          },
        },
        salaryType: undefined,
      };
    },
    computed: {
      ...mapState({
        isActiveFilter: state => state.searchbar.isActiveFilter,
        technos: state => state.tags.technos,
        domains: state => state.domains.domains,
        isAppReady: state => !state.app.isLoading,
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags,
      }),
      ...mapGetters({
        searchQueries: 'searchbarFiltersComputed',
        customFieldsSelected: 'customFieldsSelected',
      }),
      routeQueries() {
        return {
          ...this.searchQueries,
        };
      },
      filtersQuery() {
        return [
          'professions',
          'professionsNames',
          'technos',
          'technosNames',
          'locations',
          'companies',
          'contracts',
          'phone',
          'email',
          'cv',
          'linkedin',
          'last-coach-activity-from',
          'last-coach-activity-to',
          'rating',
          'currentSalaryMin',
          'currentSalaryMax',
          'salaryWantedMin',
          'salaryWantedMax',
          'search',
        ]
      },
      hasFilters() {
        return Object.keys(this.$route.query)
        .filter(key => this.$route.query[key])
        .some(key => this.filtersQuery.includes(key) || key.startsWith('cf-'));
      },
    },
    watch: {
      isAppReady: {
        handler(to) {
          if (to) {
            this.setupTags();
          }
        },
        immediate: true,
      },
      hasFilters: {
        handler(value) {
          this.$emit('has-filter', value);
        },
        immediate: true,
      }
    },
    methods: {
      buildProfessionsTag,
      resetSearchbar() {
        this.identifier = +new Date();
        this.updateRouteQueries();
      },
      setupTags() {
        const mapQueries = Object.keys(this.$route.query);
        const filteredMapQueries = mapQueries.filter(key => this.$route.query[key]);


        if (filteredMapQueries.includes('professions')) {
          const professionsIds = splitQuery(this.$route.query.professions);
          const professionsNames = splitQuery(this.$route.query.professionsNames);

          professionsIds.forEach((professionId, i) => {
            this.addTag(buildProfessionsTag({
              _id: professionId,
              name: professionsNames[i],
            }));
          });
        }

        if (filteredMapQueries.includes('companies')) {
          const companiesIds = splitQuery(this.$route.query.companies);
          const companiesNames = splitQuery(this.$route.query.companiesNames);

          companiesIds.forEach((professionId, i) => {
            this.addTag(buildCompaniesTag({
              _id: professionId,
              name: companiesNames[i],
            }));
          });
        }

        filteredMapQueries.forEach(value => {
          if (this.mapComplexQuery[value]) {
            const queries = this.mapComplexQuery[value].queryBuilder(this.$route.query[value]);
            queries.forEach(q => {
              this.addTag(this.mapComplexQuery[value].tagBuilder(q));
            });
          }

          if (this.mapSimpleQuery[value]) {
            this.addTag(this.mapSimpleQuery[value].tagBuilder(this.$route.query[value]));
          }
        });
      },
      updateRouteQueries() {
        const checkedQuery = this.checkCustomFieldsQuery(query, this.routeQueries);
        const query = {
          ...checkedQuery,
          ...this.routeQueries,
          page: 1,
        };

        this.$router.push({
          name: this.$route.name,
          query,
        }).catch(() => {
        });
      },
      closeSearchbar() {
        this.$store.dispatch('resetAndCloseSearchBar');
      },
      onSelectCustomFields(customField) {
        const hasKey = this.customFieldsSelected.find(filter => filter.key === customField.key);
        if (customField.replace && hasKey) {
          return this.replaceCustomFieldTag(buildCustomFieldTag(customField));
        }
        this.addTag(buildCustomFieldTag(customField));
      },
      onUnselectCustomFields(customField) {
        let index = this.customFieldsSelected.findIndex(filter => filter.name === customField.value);
        if (Array.isArray(customField.data)) {
          const finded = this.customFieldsSelected.find(filter => !customField.data.includes(filter.name));
          if (finded) {
            index = this.customFieldsSelected.findIndex(filter => filter.name === finded.name);
          }
        }
        this.removeTag(index);
      },
      checkCustomFieldsQuery(query, routeQueries) {
        const q = {...query};
        const hasCfInRouteQueries = Object.keys(routeQueries).some(routeQuery => routeQuery.startsWith('cf-'));
        Object.keys(q).forEach(key => {
          if (!hasCfInRouteQueries && key.startsWith('cf-')) {
            q[key] = undefined;
          }
        });
        return q;
      },
      handleReset() {
        const query = { ...this.$route.query, page: 1 };
        for (const key in query) {
          if (this.filtersQuery.includes(key) || key.startsWith('cf-') || key === 'salaryType') {
            Reflect.deleteProperty(query, key);
          }
        }
        this.$router.push({
          query,
        })
      },
    },
    beforeDestroy() {
      this.closeSearchbar();
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-contacts {
    flex-direction: column;
    z-index: 3;
    @include bp('tablet') {
      position: relative;
    }

    &__icon {
      position: absolute !important;
      top: 15px;
      right: 15px;
    }

    &--open {
      display: block;
      position: fixed;
      background: $color-white;
      overflow: auto;
      top: 0;
      left: 0;
      height: 100vh;
      min-height: 100vh;
      width: 100%;
      padding: 85px 15px 50px;
      @include bp('tablet') {
        display: flex;
        position: initial;
        overflow: initial;
        height: initial;
        min-height: initial;
        padding: initial;
        background: initial;
      }
    }

    &__wrapper {
      // margin-bottom: -10px;
      @include bp('tablet') {
        padding: 0;
      }
    }

    &__input {
      width: 100%;
    }

    &__filters {
      margin: 10px -15px 50px;
      @include bp('tablet') {
        margin: 0;
        border-radius: 4px;
      }
    }
  }
</style>
