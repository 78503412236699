<script lang="ts" setup>
  import { Opportunity } from '@/domains/models/Opportunity';
  import { defineEmits, defineProps, Ref, ref } from 'vue';
  import PanelOpportunityTopView
    from '@/components/Panel/PanelOpportunity/PanelOpportunityTop/PanelOpportunityTopView.vue';
  import PanelOpportunityTopEdit
    from '@/components/Panel/PanelOpportunity/PanelOpportunityTop/PanelOpportunityTopEdit.vue';

  interface Props {
    opportunity: Opportunity;
  }

  defineProps<Props>();
  defineEmits<{ (e: 'edit-opportunity', value: boolean): void, (e: 'update-opportunity', value: Opportunity): void }>();

  const isEditing: Ref<boolean> = ref(false);
</script>

<template>
  <PanelOpportunityTopView
    v-if="!isEditing"
    :opportunity="opportunity"
    @edit-opportunity="isEditing = true"
  />
  <PanelOpportunityTopEdit
    v-else
    :opportunity="opportunity"
    class="-mb-5"
    @update-opportunity="$emit('update-opportunity', $event)"
    @edit-opportunity="isEditing = false"/>
</template>

<style scoped></style>
