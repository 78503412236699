import { validateType } from '@/managers/tags/validators/validateType';
import tags from '@/api/tags';
import { validateName } from '@/managers/tags/validators/validateName';
import { getTagApiValueFromTypeNumber } from '@/managers/tags/formatters/tagsSerializers';

export const createTag = async({ type, name }) => {
  const validate = ({ type, name }) => {
    try {
      validateType(type);
      validateName(name);
    } catch (error) {
      throw error;
    }
  };

  const execute = ({ type, name }) => {
    try {
      return tags.createTag({ name, type });
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    validate({ type, name });

    const serializedType = getTagApiValueFromTypeNumber(type);

    const { data } = await execute({
      name,
      type: serializedType,
    });

    return data;
  } catch (error) {
    throw error;
  }
};
