<template>
  <p class="panel-chat-messages-date">
    {{ date }}
  </p>
</template>

<script>
  import moment from 'moment/min/moment-with-locales';

  /**
   * The date of the chat item
   */
  export default {
    name: 'panel-chat-messages-hour',
    props: {
      /**
       * The date of creation of the message
       */
      createdAt: {
        type: String,
      },
    },
    computed: {
      date() {
        return moment(this.createdAt).format('LT');
      }
    }
  };
</script>

<style scoped lang="scss">
  .panel-chat-messages-date {
    font-size: $font-size-xxs;
    color: $color-blue-dodger;
  }
</style>
