<template>
  <div class="modal-feature">
    <div class="modal-feature__content">
      <div class="modal-feature__text">
        <div class="is-column">
          <div class="modal-feature__subtitle">
            <span :style="`color: ${mainColor}; border: 1.2px solid ${mainColor};`">
              <i class="icon-arrow-up"></i>
            </span>
            <h3>{{ $t('features.premium-update') }}</h3>
          </div>
          <h2 class="modal-feature__title">
            {{ title }}
          </h2>
        </div>
        <div class="is-column">
          <slot name="content"></slot>
          <p v-if="showFooter" class="mt-2">
            {{ $t('features.access-functionality') }}
          </p>
        </div>
        <div class="is-column">
          <bc-button @click="sendSlackNotification" class="modal-feature__btn" :style="{ 'background': mainColor }">
            {{ $t('features.premium-update-btn') }}
          </bc-button>
          <bc-button
            @click="openContactPage"
            class="modal-feature__btn mt-2"
            type="outlined"
            :style="{ color: mainColor, borderColor: mainColor }"
          >
            {{ $t('features.contact-sales-team') }}
          </bc-button>
        </div>
      </div>
      <div class="modal-feature__logo">
        <slot name="logo"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { sendSlackNotification } from '@/api/notifications';
import { mapFeatureNames } from '@/api/organizations';
import BcButton from '@/ui-kit/components/BcButton/BcButton';

export default {
  name: 'modal-feature',
  components: {
    BcButton,
  },
  props: {
    feature: {
      type: String,
    },
    title: {
      type: String,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mainColor: '#F78F00',
    }
  },
  methods: {
    handleClose() {
      this.$emit('close-modal');
    },
    openContactPage() {
      window.open('https://www.marvinrecruiter.com/demande-de-demo', '_blank');
    },
    async sendSlackNotification() {
      const profile = this.$store.state.user.profile;
      const organizationName = profile._organization.name;
      const userName = (profile.firstName || '') + ' ' + (profile.lastName || '');
      const email = profile.email || '';
      const mobile = profile.mobile || '';

      try {
        await sendSlackNotification({
          title: '-',
          body: `NomOrga: ${organizationName}, \nNomUser: ${userName}, \nAdresseMail: ${email}, \nTéléphone: ${mobile}, \nFeature demandée: ${mapFeatureNames[this.feature]}
          `,
        });
        this.$toast.show({
          type: 'success',
          message: this.$t('features.feature-request'),
          duration: 7000,
        });
        this.handleClose();
      } catch(err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-feature {
  width: 686px;
  height: 400px;
  background: white;
  border-radius: 10px;
  padding: 20px;
  flex-direction: column;

  &__close {
    justify-content: flex-end;
    opacity: 0.5;

    & i {
      font-size: 16px;
    }
  }

  &__content {
    height: 100%;
    padding: 12px 15px;

    & > div {
      width: 290px;
      flex-direction: column;
      flex: 1;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-top: 10px;
  }

  &__subtitle {
    align-items: center;

    & > span {
      border-radius:100px;
      padding: 2.5px;
      width: 16px;
      height: 16px;

      & > i {
        font-size: 9px;
      }
    }

    & > h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      margin-left: 12px;
    }
  }

  &__text {
    font-family: 'Poppins', sans-serif;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $color-blue-dark-cello;
  }

  &__logo {
    align-items: center;
    justify-content: center;

    & img {
      max-width: 256px;
      max-height: 324px;
    }
  }

  &__btn {
    width: 95%;

    &:hover {
      background: transparent;
    }
  }
}
</style>
