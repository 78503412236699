<template>
  <div
    class="panel-organization-header bg-neutral-200 is-full-width is-justify-content-space-between is-align-items-center">
    <panel-header-nav></panel-header-nav>
    <panel-header-date
      :date="profile.createdAt"
      class="panel-organization-header__date"
      text="Créée le">
    </panel-header-date>
    <bc-button
      class="panel-organization-header__button"
      color="error"
      icon-left="trash"
      radius-size="s"
      size="extra-small"
      type="flat"
      @click="setIsDeletingOrganization(true)">
      Supprimer l'organisation
    </bc-button>
    <bc-modal
      :active="isEditingOrganization || isDeletingOrganization"
      @close="closeModal">
      <organizations-modal-edit
        v-if="isEditingOrganization"
        :profile="profile"
        @close-modal="cancelEdit"
        @profile-updated="confirmEdit">
      </organizations-modal-edit>
      <organizations-modal-delete
        v-if="isDeletingOrganization"
        :profile="profile"
        @close-modal="cancelDelete"
        @profile-deleted="confirmDelete">
      </organizations-modal-delete>
    </bc-modal>
  </div>
</template>

<script>
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import PanelHeaderDate from '@/components/Panel/PanelHeader/PanelHeaderDate';
  import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import BcModal from '@/ui-kit/components/BcModal/BcModal';
  import OrganizationsModalEdit
    from '@/components/Organizations/OrganizationsModal/OrganizationsModalEdit';
  import OrganizationsModalDelete
    from '@/components/Organizations/OrganizationsModal/OrganizationsModalDelete';

  export default {
    name: 'panel-organization-header',
    components: {
      OrganizationsModalDelete,
      OrganizationsModalEdit,
      BcModal,
      BcButton,
      PanelHeaderNav,
      PanelHeaderDate,
    },
    mixins: [PanelNavigation],
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        isEditingOrganization: false,
        isDeletingOrganization: false,
      };
    },
    methods: {
      setIsEditingOrganization(value) {
        this.isEditingOrganization = value;
      },
      setIsDeletingOrganization(value) {
        this.isDeletingOrganization = value;
      },
      closeModal() {
        this.cancelDelete();
        this.cancelEdit();
      },
      cancelEdit() {
        this.setIsEditingOrganization(false);
      },
      confirmEdit(data) {
        this.$emit('profile-updated', data);

        this.setIsEditingOrganization(false);
      },
      cancelDelete() {
        this.setIsDeletingOrganization(false);
      },
      confirmDelete() {
        this.$emit('profile-deleted');

        this.setIsDeletingOrganization(false);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-organization-header {
    padding: 10px 15px;
    flex-shrink: 0;
    border-bottom: 1px solid $color-grey-4;
    height: 50px;

    &__date {
      margin-left: 20px;
      margin-right: auto;
    }
  }
</style>
