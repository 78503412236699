export const macros = {
  dateToSend: {
    start: {
      apiValue: 1,
      frontValue: 'start',
    },
    acceptation: {
      apiValue: 2,
      frontValue: 'acceptation',
    },
  },
  dealSources: {
    huntBC: {
      apiValue: 1,
      frontValue: 'huntBC',
    },
    huntLinkedIn: {
      apiValue: 2,
      frontValue: 'huntLinkedIn',
    },
    subscriptionBC: {
      apiValue: 3,
      frontValue: 'subscriptionBC',
    },
    jobBoard: {
      apiValue: 4,
      frontValue: 'jobBoard',
    },
    cooptation: {
      apiValue: 5,
      frontValue: 'cooptation',
    },
    network: {
      apiValue: 6,
      frontValue: 'network',
    },
  },
  passTypes: {
    in: {
      apiValue: 1,
      frontValue: 'in',
    },
    out: {
      apiValue: 2,
      frontValue: 'out',
    },
  },
};

export default {
  macros,
};
