<template>
  <div
    v-click-outside="manageClosePanel"
    class="panel-organization"
    @click.stop>
    <panel-organization-header
      :profile="profile"
      class="panel-organization__header"
      @profile-deleted="deleteProfile"
      @profile-updated="updateProfile">
    </panel-organization-header>
    <panel-organization-body :profile="profile"></panel-organization-body>
    <panel-categories
      :categories="categories"
      class="panel-organization__categories">
    </panel-categories>
    <panel-view class="panel-organization__timeline is-full-width">
      <component
        v-if="!isLoading"
        :is="categoryComponent"
        :profile="profile"
        @profile-updated="updateProfile"
      />
    </panel-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';

  import PanelCategories from '@/components/Panel/PanelCategoriesOldStyle';
  import PanelView from '@/components/Panel/PanelView';
  // http
  import { getOrganizationProfile } from '@/api/organizations';
  import PanelOrganizationHeader
    from '@/components/Panel/PanelOrganization/PanelOrganizationHeader';
  import PanelOrganizationBody from '@/components/Panel/PanelOrganization/PanelOrganizationBody';
  import PanelOrganizationView from '@/components/Panel/PanelOrganization/PanelOrganizationView';
  import PanelOrganizationConfiguration from '@/components/Panel/PanelOrganization/PanelOrganizationConfiguration';

  export default {
    name: 'panel-organization',
    components: {
      PanelOrganizationView,
      PanelOrganizationBody,
      PanelOrganizationHeader,
      PanelView,
      PanelCategories,
      PanelOrganizationConfiguration,
    },
    mixins: [PanelNavigation],
    data() {
      return {
        categoryComponents: {
          users: 'panel-organization-view',
          configuration: 'panel-organization-configuration',
        },
        profile: {},
        isLoading: true,
      };
    },
    computed: {
      ...mapGetters({
        isMarvinRecruiter: 'user/isMarvinRecruiter',
      }),
      categories() {
        const categories = [
          {
            name: this.$i18n.t('generics.users'),
            url: 'users',
          },
        ]
        if (!this.isMarvinRecruiter) {
          return categories;
        }
        return [
          ...categories,
          {
            name: this.$i18n.t('generics.configuration'),
            url: 'configuration',
          },
        ];
      },
      categoryComponent() {
        if (this.categoryComponents[this.$route.query.category]) {
          return this.categoryComponents[this.$route.query.category];
        }
        return 'panel-organization-view';
      }
    },
    methods: {
      async getProfile() {
        try {
          const { data } = await getOrganizationProfile(this.$route.params.id);

          this.profile = data;
          this.isLoading = false;
        } catch (error) {
          this.$toast.show({
            title: 'Accès refusé',
            message: 'Seul les utilisateurs avec le role god sont autorisés à accéder à la ressource',
            type: 'error',
            icon: 'cross',
          });
          this.isLoading = false;
        }
      },
      updateProfile(data) {
        this.profile = {
          ...this.profile,
          ...data,
        };
      },
      async deleteProfile() {
        await this.$store.dispatch('removeCard', this.profile._id);

        this.closePanel()
      },
    },
    watch: {
      $route: {
        handler() {
          this.getProfile();
        },
        immediate: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-organization {
    height: 100%;
    flex-direction: column;

    &__header {
      order: -2;
    }

    &__categories {
      order: -1;
      @include bp('tablet') {
        order: 0
      }
    }

    &__timeline {
      padding: 0 30px;
    }
  }
</style>
