<template>
  <div
    class="spinner"
    v-if="spinning"
    :style="{ 'margin': margin }">
    <div
      class="spinner__item">
      <!-- Here the loader -->
    </div>
  </div>
</template>

<script>
  // Remplacer ce spinner
  export default {
    name: 'spinner',
    props: {
      /**
       * The Boolean that determine if the spinner is visible
       */
      spinning: Boolean,

      /**
       * The custom margin of the spinner
       */
      margin: String
    }
  };
</script>

<style lang="scss" scoped>
  // Spinning div
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    z-index: 1;
    // Loader style and spinning effect
    &__item {
      border: 2px solid $color-white;
      /* Light grey */
      border-top: 2px solid $color-blue;
      /* Blue */
      border-radius: 50%;
      width: 33px;
      height: 33px;
      animation: spin 1s linear infinite;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    //
  }
</style>
