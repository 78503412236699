export default {
    props: {
        job: {
            type: Object
        },
        columnType: {
            type: String,
            required: true
        },
        canEdit: {
            type: Boolean,
            required: true
        }
    },
    directives: {
        focus: {
            inserted: function(el) {
                el.focus();
            },
        },
    },
    methods: {
        formatDataForApi(cellData) {
            const dataParsed = {};

            if (cellData.columnName === 'table-header.salary') {
                dataParsed.salary = cellData.data;
            }

            if (cellData.columnName === 'table-header.nb-profils') {
                dataParsed.profils = cellData.data;
            }

            if (cellData.columnName === 'table-header.on-off') {
                dataParsed.status = cellData.data;
            }

            if (cellData.columnName === 'table-header.contact') {
                dataParsed.interlocuteur === 'table-header.contact';
            }

            dataParsed[cellData.columnName] = cellData.data;
        }
    },
};
