<script>
import PanelOrganizationConfigurationItem from './PanelOrganizationConfigurationItem';
import { addCustomField, removeCustomField } from '@/api/organizations';
import BcCheckbox from '@/legacy/ui-kit/components/Checkbox/BcCheckbox.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm';

export default {
  name: 'panel-organization-configuration-customfields',
  components: {
    PanelOrganizationConfigurationItem,
    BcCheckbox,
    ModalConfirm,
  },
  props: {
    profile: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showAddForm: false,
      isOpenDeleteItem: false,
      customFields: {
        candidate: [],
        job: [],
        company: [],
      },
      ressourceType: 'candidate',
      selectedCfType: 'string',
      showFilter: true,
      name: '',
      key: '',
      icon: '',
      defaultValue: '',
      options: '',
      baseUri: '',
      min: undefined,
      max: undefined,
      increment: undefined,
      unit: '',
      toDeleteCf: {},
    };
  },
  mounted() {
    this.customFields = {
      candidate: this.profile?.configuration?.candidate?.customFields || [],
      job: this.profile?.configuration?.job?.customFields || [],
      company: this.profile?.configuration?.company?.customFields || [],
    };
  },
  methods: {
    async addCustomField() {
      try {
        const { data } = await addCustomField({
          organizationId: this.profile._id,
          ressourceType: this.ressourceType,
          type: this.selectedCfType,
          name: this.name,
          icon: this.icon,
          defaultValue: this.defaultValue,
          options: this.options,
          baseUri: this.baseUri,
          min: this.min,
          max: this.max,
          increment: this.increment,
          unit: this.unit,
          showFilter: this.showFilter,
        });
        this.$toast.show({
          type: 'success',
          message: 'Ajout avec succès',
        });

        this.customFields = {
          ...this.customFields,
          [data.ressourceType]: data.customFields,
        }

        this.closeForm();
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    resetFields() {
      this.selectedCfType = 'string';
      this.ressourceType = 'candidate';
      this.name = '';
      this.icon = '';
      this.defaultValue = '';
      this.options = '';
      this.baseUri = '';
      this.min = undefined;
      this.max = undefined;
      this.increment = undefined;
      this.unit = '';
      this.showFilter = true;
    },
    onSelect(evt) {
      this.selectedCfType = evt.target.value;
    },
    onSelectRessourceType(evt) {
      this.ressourceType = evt.target.value;
    },
    closeForm() {
      this.showAddForm = false;
      this.resetFields();
    },
    onDelete(ressourceType, customField) {
      this.toDeleteCf = {
        ressourceType,
        customField,
      }
      this.isOpenDeleteItem = true;
    },
    async onConfirmDelete() {
      try {
        const { data } = await removeCustomField({
          organizationId: this.profile._id,
          ressourceType: this.toDeleteCf.ressourceType,
          key: this.toDeleteCf.customField.key,
        });

        this.$toast.show({
          type: 'success',
          message: 'Supprimé avec succès',
        });

        this.customFields = {
          ...this.customFields,
          [data.ressourceType]: data.customFields,
        }

        this.closeConfirmDelete();
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err?.message,
        });
      }
    },
    closeConfirmDelete() {
      this.toDeleteCf = {}
      this.isOpenDeleteItem = false;
    }
  },
};
</script>

<template>
  <div class="panel-organization-configuration-customfields">

    <panel-organization-configuration-item title="Custom Fields">
      <div class="w-full flex-col gap-5 p-3">
        <div class="flex w-full justify-end">
          <button
            class="rounded bg-blue-500 p-2 text-white"
            @click="showAddForm = true"
          >
            + Ajouter un custom field
          </button>
        </div>

        <div v-if="showAddForm" class="w-full flex-col">
          <div class="flex-col gap-2">
            <select @change="onSelectRessourceType">
              <option value="candidate">candidate</option>
              <option value="company">company</option>
              <option value="job">job</option>
            </select>

            <select @change="onSelect">
              <option>string</option>
              <option>single-select</option>
              <option>multi-select</option>
              <option>cursor</option>
              <option>range</option>
              <option>number</option>
              <option>number-with-unit</option>
              <option>link</option>
            </select>

            <input v-model="name" class="rounded p-2" placeholder="name" name="name" />
            <input v-model="icon" class="rounded p-2" placeholder="icon" name="icon" />
            <input v-model="defaultValue" class="rounded p-2" placeholder="defaultValue" name="defaultValue" />

            <input
              v-if="['single-select', 'multi-select'].includes(selectedCfType)"
              v-model="options"
              class="rounded p-2"
              placeholder="options (ex: fraise,pomme,poire)"
              name="Options" />
            <input
              v-if="['link'].includes(selectedCfType)"
              v-model="baseUri"
              class="rounded p-2"
              placeholder="baseUri"
              name="baseUri" />

            <input
              v-if="['number', 'range', 'cursor', 'number-with-unit'].includes(selectedCfType)"
              v-model="min"
              class="rounded p-2"
              placeholder="min"
              label="min"
              name="min"
              type="number" />
            <input
              v-if="['number', 'range', 'cursor', 'number-with-unit'].includes(selectedCfType)"
              v-model="max"
              class="rounded p-2"
              placeholder="max"
              label="max"
              name="max"
              type="number" />
            <input
              v-if="['number', 'range', 'cursor', 'number-with-unit'].includes(selectedCfType)"
              v-model="increment"
              class="rounded p-2"
              placeholder="increment"
              label="increment"
              name="increment"
              type="number" />
            <input
              v-if="['number-with-unit'].includes(selectedCfType)"
              v-model="unit"
              class="rounded p-2"
              placeholder="unit"
              name="unit"
              type="number" />

            <div class="gap-2">
              <label for="showFilter">showFilter</label>
              <bc-checkbox id="showFilter" v-model="showFilter"></bc-checkbox>
            </div>
          </div>

          <div class="mt-5 justify-center gap-2">
            <button class="rounded bg-red-500 p-2 text-white" @click="closeForm"> Annuler </button>
            <button class="rounded bg-green-500 p-2 text-white" @click="addCustomField">
              Valider
            </button>
          </div>
        </div>

        <div class="flex-col gap-2">
          <div class="font-bold">Candidats</div>
          <div v-for="cf in customFields.candidate" :key="cf.key">
            <span>{{ cf.name }}</span> <button class="ml-3 text-red-500" @click="onDelete('candidate', cf)"><i class="icon-trash"></i></button>
          </div>
        </div>
        <div class="flex-col gap-2">
          <div class="font-bold">Opportunités</div>
          <div v-for="cf in customFields.job" :key="cf.key">
            <span>{{ cf.name }}</span> <button class="ml-3 text-red-500" @click="onDelete('job', cf)"><i class="icon-trash"></i></button>
          </div>
        </div>
        <div class="flex-col gap-2">
          <div class="font-bold">Entreprises</div>
          <div v-for="cf in customFields.company" :key="cf.key">
            <span>{{ cf.name }}</span> <button class="ml-3 text-red-500" @click="onDelete('company', cf)"><i class="icon-trash"></i></button>
          </div>
        </div>
      </div>
    </panel-organization-configuration-item>

    <modal-confirm
      :is-open="isOpenDeleteItem"
      icon="❌👋"
      :title="'Voulez-vous vraiment supprimer ce custom field ?'"
      :cancelText="'Non'"
      :confirmText="'Oui'"
      @close="closeConfirmDelete"
      @on-confirm="onConfirmDelete"
    ></modal-confirm>
  </div>
</template>
