<template>
  <div class="panel-error-body">
    <div class="panel-error-body__container col-xs-12">
      <p class="panel-error-body__text">
        {{ $t('generics.no-result-found') }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'panel-error-body',
  };
</script>

<style scoped lang="scss">
  $color-background: linear-gradient(#4a617e, #4a617e);

  .panel-error-body {
    font-size: $font-size-m;
    height: 100%;
    align-items: center;
    &__container {
      color: $color-white;
      @include angle(before, $color-background);
      padding-top: 100px;
      padding-bottom: 140px;
    }
    &__text {
      text-align: center;
    }
  }
</style>
