<template>
  <td :class="showModal ? 'not--active--cell' : 'active--cell'">
    <template v-if="canEdit">
      <bc-dropdown :options="[this.$i18n.t('hunt.edit-upper'), this.$i18n.t('hunt.delete-upper')]" :position="'bottom-left'">
        <template #label="{ isDropdownOpen }">
          <dropdown-options :is-dropdown-open="isDropdownOpen" icon="more-vertical">
          </dropdown-options>
        </template>
        <template #option="{ activeIndex, index, option }">
          <bc-dropdown-item
            :is-focus="activeIndex === index"
            :text="option"
            @click.native="$emit('jobOption', { action: option, jobId: job._id })"
          >
          </bc-dropdown-item>
        </template>
      </bc-dropdown>
    </template>
    <template v-else>
      <span class="bc-dropdown-trigger__label is-flex is-absolute font-size-m">
        <i class="bc-dropdown-trigger__label-icon is-flex is-align-items-center icon-more-vertical"></i>
      </span>
    </template>
  </td>
</template>

<script>
import BcDropdown from '../../../ui-kit/components/BcDropdown/BcDropdown.vue';
import BcDropdownItem from '../../../ui-kit/components/BcDropdown/BcDropdownItem';
import DropdownOptions from './DropdownOptions.vue';
import tableMixin from  '../../../mixins/Table/tableCell';

export default {
  mixins: [tableMixin],
  name: 'options-cell',
  components: {
    BcDropdown,
    BcDropdownItem,
    DropdownOptions
  },
   props: {
    showModal: {
      type: Boolean
    }
  },
};
</script>

<style lang="scss" scoped>
  .not--active--cell {
    display: none;
  }
  .active--cell {
    display: revert;
  }
  td {
    div {
      width: 38px;
    }
  }
</style>
