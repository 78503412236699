<template>
  <div class="block">
    <div class="jobboard-config-list-item w-full">
      <div class="jobboard-config-list-item__name">
        <JobboardLogo :logoUrl="logoUrl" :connectorName="name" />
        <p>{{ title }}</p>
      </div>
      <div class="jobboard-config-list-item__actions">
        <!-- <button @click="onEdit">
          <i class="icon-edit-3"></i>
        </button> -->
        <bc-toggle
          :id="`${title}-activation`"
          v-model="activated"
        ></bc-toggle>
        <button @click="onAdd">
          <i class="icon-plus"></i>
        </button>
      </div>
    </div>
    <div class="flex flex-col justify-center gap-2 divide-y-[1px]">
      <div
        v-for="subscription in subscriptionsList"
        :key="subscription.id"
        class="flex w-full justify-between px-4 py-2">
        <div class="flex flex-col pl-10 w-full">
          <div><span class="font-bold">ID</span> : {{ subscription.id }}</div>
          <div class="mt-2 flex flex-col">
            <p class="mb-2 font-bold">applicationForwardingType : <span class="font-normal text-blue-500">{{ subscription.applicationForwardingType }}</span></p>

            <div class="flex justify-between gap-6">
              <p>usesCpc : <span :class="{ 'text-green-600': subscription.usesCpc, 'text-red-600': !subscription.usesCpc }">{{ subscription.usesCpc }}</span></p>
              <p>usesCredit : <span :class="{ 'text-green-600': subscription.usesCredit, 'text-red-600': !subscription.usesCredit }">{{ subscription.usesCredit }}</span></p>
            </div>
            <div class="flex justify-between gap-6">
              <p>usesDirectApply : <span :class="{ 'text-green-600': subscription.usesDirectApply, 'text-red-600': !subscription.usesDirectApply }">{{ subscription.usesDirectApply }}</span></p>
              <p>usesEmailApply : <span :class="{ 'text-green-600': subscription.usesEmailApply, 'text-red-600': !subscription.usesEmailApply }">{{ subscription.usesEmailApply }}</span></p>
            </div>
            <div class="flex justify-between gap-6">
              <p>usesOrganic : <span :class="{ 'text-green-600': subscription.usesOrganic, 'text-red-600': !subscription.usesOrganic }">{{ subscription.usesOrganic }}</span></p>
              <p>usesSecondaryLocations : <span :class="{ 'text-green-600': subscription.usesSecondaryLocations, 'text-red-600': !subscription.usesSecondaryLocations }">{{ subscription.usesSecondaryLocations }}</span></p>
            </div>
            <div class="flex justify-between gap-6">
              <p>usesSponsored : <span :class="{ 'text-green-600': subscription.usesSponsored, 'text-red-600': !subscription.usesSponsored }">{{ subscription.usesSponsored }}</span></p>
              <p>usesUrlApply : <span :class="{ 'text-green-600': subscription.usesUrlApply, 'text-red-600': !subscription.usesUrlApply }">{{ subscription.usesUrlApply }}</span></p>
            </div>
          </div>
        </div>
        <div class="flex gap-4">
          <button @click="onEdit(subscription.id)">
            <i class="icon-edit"></i>
          </button>
          <button @click="onDelete(subscription.id)">
            <i class="icon-trash text-red-500"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BcToggle from '@/ui-kit/components/BcToggle/BcToggle';
import { addChannelSubscriptions, getChannelSubscriptions } from '@/api/xtramile';
import JobboardLogo from '@/components/Jobboard/JobboardLogo';

export default {
  name: 'jobboard-config-list-item',
  components: {
    BcToggle,
    JobboardLogo,
  },
  data() {
    return {
      activated: this.isActivated,
      isLoading: false,
    };
  },
  props: {
    id: {
      type: String,
    },
    profile: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    logoUrl: {
      type: String,
    },
    isActivated: {
      type: Boolean,
      default: false,
    },
    isXtramileEnabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    subscriptionsList: {
      type: Array,
      default: () => ([]),
    },
  },
  mounted() {
    if (this.isXtramileEnabled) {
      this.getChannelSubscriptions();
    }
  },
  methods: {
    onEdit(channelSubscriptionId) {
      this.$emit('on-edit', { channelId: this.id, channelSubscriptionId });
    },
    onAdd() {
      this.$emit('on-add', this.id);
    },
    // async onAdd() {
    //   try {
    //     this.isLoading = true;
    //     const { data } = await addChannelSubscriptions({
    //       organizationId: this.profile._id,
    //       channelId: this.id,
    //     });

    //     this.$emit('add-channel-subscription', { channelId: this.id, channelSubscription: data });

    //     this.isLoading = false;
    //   } catch (err) {
    //     this.isLoading = false;
    //     this.$toast.show({
    //       type: 'error',
    //       message: err.message,
    //     });
    //   }
    // },
    async onDelete(channelSubscriptionId) {
      this.$emit('on-delete', channelSubscriptionId);
    },
    async getChannelSubscriptions() {
      try {
        const { data } = await getChannelSubscriptions({
          organizationId: this.profile._id,
          channelId: this.id,
        });

        if (data.results?.length) {
          this.$emit('set-channel-subscriptions', { channelId: this.id, subscriptionsList: data.results });
        }
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
  },
  watch: {
    activated(newValue) {
      this.$emit('on-toggle', { id: this.id, activated: newValue });
    },
  },
};
</script>

<style lang="scss" scoped>
.jobboard-config-list-item {
  padding: 10px 15px;
  align-items: center;
  justify-content: space-between;

  &__name {
    align-items: center;
    gap: 10px;

    & > p {
      color: $color-tertiary;
      font-weight: $font-weight-bold;
    }
  }

  &__actions {
    gap: 30px;

    & .icon-edit-3 {
      color: $color-blue-heavy-dark;

      &:hover {
        color: $color-secondary;
      }
    }
  }
}
</style>
