<template>
  <div class="companies-autocomplete">
    <bc-autocomplete
      v-model="name"
      icon="companies"
      @input="getSuggestions"
      :is-scrollable="false"
      :options="companies"
      class="companies-autocomplete__input"
      name="Nom"
      :label-placeholder="false"
      :label="showLabel ? $t('panel-company-edit.company-name') : ''"
      :placeholder="$t('panel-company-edit.company-name')"
      @select="onSelect"
      v-bind="$attrs">
      <template #prepend>
        <div v-if="error" class="companies-autocomplete__conflict">
          <i class="companies-autocomplete__conflict-icon icon-alert" />
        </div>
      </template>
      <template #option="{ option }">
        <div class="companies-autocomplete__link" @select="onSelect">
          <bc-avatar
            :src="option.logoUrl"
            @error="setAlternativeImg({event: $event, type: 'companies'})"
            class="companies-autocomplete__img"
            size="xs">
          </bc-avatar>
          <p class="companies-autocomplete__text">
            {{ option.name }}
          </p>
        </div>
      </template>
    </bc-autocomplete>

    <div v-if="withTags" class="mt-2 flex gap-2">
      <tag
        v-for="tag of selected"
        :key="tag._id" type="company"
        :text="tag.name"
        canClose
        @click="onSelectTag(tag)"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'debounce';
import companyHttp from '@/api/company';
import BcAutocomplete from '@/ui-kit/components/BcAutocomplete';
import BcAvatar from '@/ui-kit/components/BcAvatar';
import Tag from '@/components/Tag/Tag';
import { setAlternativeImg } from '@/mixins/imgFallback.js';

export default {
  name: 'companies-autocomplete',
  components: {
    BcAutocomplete,
    BcAvatar,
    Tag,
  },
  props: {
    selected: {
      type: Array,
      default: () => ([]),
    },
    error: {
      type: Boolean,
      default: false,
    },
    forceValue: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    withTags: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.forceValue) {
      this.search = this.forceValue;
    }
  },
  data() {
    return {
      search: '',
      companies: [],
    }
  },
  computed: {
    name: {
      get() {
        return this.search;
      },
      set(value) {
        this.search = value;
        this.$emit('update-value', {
          name: value,
        });
      },
    },
  },
  methods: {
    setAlternativeImg,
    getSuggestions: debounce(async function() {
      try {
        const { data } = await companyHttp.partnersAutocomplete(this.name);

        this.companies = data.filter(partner => partner._id !== this.$route.params.id);
      } catch (error) {
        this.$toast.show({
          type: 'error',
          message: this.$t('toast.error-occured'),
        });
      }
    }, 500),
    onSelect(option) {
      this.$emit('select-company', option);
      this.search = '';
    },
    onSelectTag(tag) {
      this.$emit('select-tag', tag);
    },
  },
  watch: {
    forceValue(value) {
      this.search = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.companies-autocomplete {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  &__title {
    width: 100%;
  }

  &__asterisk {
    color: $color-red-mandy;
    margin-right: 10px;
  }

  &__input {
    width: 100%;
  }

  &__not-found {
    align-items: center;
    width: 100%;
    height: 46px;
    padding: 0 20px;
  }

  &__link {
    display: flex;
    width: 100%;
    height: 46px;
    text-decoration: none;
    color: inherit;
    align-items: center;
    padding: 0 20px;

    &--active {
      background-color: $color-blue-dodger;
      color: white;
    }
  }

  &__item {
    display: flex;
    list-style: none;
  }

  &__img {
    overflow: hidden;
    margin-right: 10px;
  }

  &__text {
    @include font-size(16px);
  }

  &__conflict {
    justify-content: center;
    align-items: center;
    background-color: $color-red-mandy;
    color: white;
    border-radius: $border-radius-rounded;
    padding: 5px;
    margin: 0 -10px 0 20px;

    &-icon {
      font-size: $font-size-xs;
      height: 13px;
    }
  }
}
</style>
