<template>
  <div class="searchbar-buttons-text">
    <button-default
      :bc-background-color="button.color"
      :bc-background-color-opacity="button.colorOpacity"
      :bc-box-shadow-color-opacity-hover="0.15"
      :bc-icon="button.icon"
      :bc-padding="button.angle ? '15px 40px 15px 20px' : '15px 20px'"
      :bc-text-color="button.colorText"
      :bc-text-color-opacity="1"
      :key="index"
      @click.native="button.fn"
      bc-box-shadow-color="black"
      bc-icon-margin="10px"
      bc-icon-size="24px"
      class="searchbar-buttons-text__button"
      v-for="(button, index) in buttons">
      <div class="searchbar-buttons-text__text">
        {{ button.text }}
        <div
          :class="{'searchbar-buttons-text__icon--active': button.isActive}"
          class="searchbar-buttons-text__icon icon-chevron-right"
          v-if="button.angle">
        </div>
      </div>
    </button-default>
  </div>
</template>

<script>
  // TODO move or refacto to ui-kit
  import ButtonDefault from '@/legacy/ui-kit/components/Button/BcButton';
  import { mapState } from 'vuex';

  export default {
    name: 'searchbar-buttons-text',
    components: { ButtonDefault },
    computed: {
      ...mapState({
        isActiveFilter: state => state.searchbar.isActiveFilter
      }),
      buttons() {
        return [
          {
            isActive: this.isActiveFilter,
            color: this.windowWidth > 767 || this.isActiveFilter ? 'blue-dark-cello' : 'blue-dodger',
            colorOpacity: this.isActiveFilter ? 0.05 : 1,
            colorText: this.isActiveFilter ? 'blue-dodger' : 'white',
            text: this.$i18n.t('companies.account-management.filter-by'),
            icon: '',
            fn: this.setIsActiveFilter,
            angle: true
          }
        ];
      }
    },
    data: () => ({
      windowWidth: window.innerWidth
    }),
    methods: {
      setWindowWidth() {
        this.windowWidth = window.innerWidth;
      },
      /**
       * The method to display the advanced filters
       */
      async setIsActiveFilter() {
        this.$store.dispatch('setIsActiveFilter', !this.isActiveFilter);
      },
      /**
       * The method to display the advanced filters
       */
      async setIsActiveMatching() {
        this.$store.dispatch('setIsActiveFilter', false);
      }
    },
    mounted() {
      window.addEventListener('resize', this.setWindowWidth);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.setWindowWidth);
    }
  };
</script>

<style lang="scss" scoped>
  .searchbar-buttons-text {
    justify-content: center;
    position: relative;
    width: 100%;
    @include bp('tablet') {
      width: auto;
      flex-shrink: 0;
    }

    &__button {
      font-size: $font-size-s;
      line-height: 18px;
      width: 100%;
      justify-content: center;
      margin-right: 20px;
      align-items: center;
      transition: 0.15s ease-in-out;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &__text {
      justify-content: center;
      align-items: center;
      font-size: $font-size-s;
    }

    &--active {
      background: $color-white;
      color: $color-blue-san-juan;
      @include box-shadow-default;
    }

    &__icon {
      transition: 0.3s;
      line-height: 18px;
      position: absolute;
      right: 15px;

      &--active {
        transform: rotate(90deg);
      }
    }
  }
</style>
