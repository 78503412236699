import PhoneNumber from 'awesome-phonenumber';

export const formatPhoneNumber = (phone = '', country) => phone ? PhoneNumber(phone, country) : phone;

export const canPhoneNumberBeInternationallyDialled = (phone = {}) => phone ? phone.canBeInternationallyDialled() : false;

export const checkPhoneNumberValidity = (phone = {}) => phone ? phone.isValid() : false;

export const getPhoneCountryCode = (phone = '') => phone.startsWith('+') ? PhoneNumber(phone).getRegionCode() : 'FR';

export const getFormattedPhoneNumber = (phone = '') => {
  const countryCode = getPhoneCountryCode(phone);
  const formattedPhoneNumber = formatPhoneNumber(phone, countryCode);

  const isPhoneValid = checkPhoneNumberValidity(formattedPhoneNumber);
  const canPhoneBeInternationallyDialled = canPhoneNumberBeInternationallyDialled(formattedPhoneNumber);

  return isPhoneValid && canPhoneBeInternationallyDialled ? formattedPhoneNumber.getNumber('international') : phone;
};

export default {
  formatPhoneNumber,
  canPhoneNumberBeInternationallyDialled,
  checkPhoneNumberValidity,
  getPhoneCountryCode,
  getFormattedPhoneNumber,
};
