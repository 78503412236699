<script lang="ts" setup>
  import { usePanelStore } from '@/store/pinia/panel';
  import { Company } from '@/domains/models/Company';
  import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav.vue';
  import PanelCompanyModalMergeSearch
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalMerge/PanelCompanyModalMergeSearch.vue';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { computed, ComputedRef, onUnmounted, provide, ref } from 'vue';
  import PanelCompanyModalMergePreview
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalMerge/PanelCompanyModalMergePreview.vue';
  import ProvideKeys from '@/components/Panel/PanelCompany/PanelCompanyModal/ProvideKeys';
  import {
    DetailsCount,
    SelectedFields,
  } from '@/components/Panel/PanelCompany/PanelCompanyModal/types';
  import useFetchCompanyProfile
    from '@/components/Panel/PanelCompany/PanelCompanyModal/useFetchCompanyProfile';
  import { useQuery, useQueryClient } from '@tanstack/vue-query';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { useI18n } from '@/i18n/i18n';
  import companyApi from '@/api/company';

  const props = defineProps<{
    company: Company;
    draftCompany?: Company
    mergeCallback?: () => void
  }>();
  const toast = useToast();
  const { t } = useI18n();

  const panelStore = usePanelStore();
  const closeMerge = () => {
    panelStore.closeModal();
  };

  const selectedProfile = ref<Company | null>(props.draftCompany ? props.draftCompany : null);

  function setSelectedProfile(company: Company): void {
    selectedProfile.value = company;
  }

  const selectedFields = ref<string[]>([
    'count.contacts',
    'count.opportunities',
    'count.lists',
    'count.processes',
    'count.documents',
    'count.notes',
  ]);

  function setSelectedFields(field: keyof Company): void {
    if (selectedFields.value.includes(field)) {
      selectedFields.value = selectedFields.value.filter(key => key !== field);
    } else {
      selectedFields.value.push(field);
    }
  }

  const selectedEmails = ref<string[]>([]);

  function setSelectedEmails(value) {
    if (selectedEmails.value.includes(value)) {
      selectedEmails.value = selectedEmails.value.filter(key => key !== value);
    } else {
      selectedEmails.value.push(value);
    }
  }

  const selectedPhones = ref<string[]>([]);

  function setSelectedPhones(value) {
    if (selectedPhones.value.includes(value)) {
      selectedPhones.value = selectedPhones.value.filter(key => key !== value);
    } else {
      selectedPhones.value.push(value);
    }
  }

  const erasedFields: string[] = [
    'logoUrl',
    'size',
    'customFields',
    'social',
    'uniqueid',
  ];
  const selectedFieldsProfile = computed(() => {
    if (!selectedFields.value) {
      return;
    }

    return selectedFields.value.reduce((acc, key) => {
      if (key === 'phones') {
        acc.phones = [...leftProfile.value?.phone ? [leftProfile.value?.phone] : [], ...leftProfile.value?.phones ?? []];
      } else if (key === 'emails') {
        acc.emails = [...leftProfile.value?.email ? [leftProfile.value?.email] : [], ...leftProfile.value?.emails ?? []];
      } else if (key.includes('.')) {
        const [parent, child] = key.split('.');
        acc[parent] = acc[parent] ?? {};
        acc[parent][child] = leftProfile.value?.[parent]?.[child];
      } else if (erasedFields.some(k => key.includes(k))) {
        acc[key] = leftProfile.value?.[key];
      } else {
        acc[key] = [
          ...rightProfile.value?.[key] ? rightProfile.value?.[key] : [],
          ...leftProfile.value?.[key] ? leftProfile.value?.[key] : [],
        ];
      }

      return acc;
    }, {});
  });

  const enabled = computed(() => !!selectedProfile.value);
  const { data: fetchedProfile } = useQuery({
    queryKey: ['FETCHED_PROFILE', selectedProfile.value?._id ?? ''],
    queryFn: () => useFetchCompanyProfile({
      companyId: selectedProfile.value?._id,
    }),
    enabled,
  });

  const { data: originalProfile } = useQuery({
    queryKey: ['FETCHED_PROFILE', props.company._id ?? ''],
    queryFn: () => useFetchCompanyProfile({
      companyId: props?.company?._id,
    }),
  });

  const isSwitched = ref(false);

  function switchProfile() {
    isSwitched.value = !isSwitched.value;
    selectedFields.value = [];
    selectedPhones.value = [];
    selectedEmails.value = [];
  }

  const leftProfile = computed(() => {
    return isSwitched.value ? originalProfile.value : fetchedProfile.value;
  });
  const rightProfile = computed(() => isSwitched.value ? fetchedProfile.value : originalProfile.value);

  const queryClient = useQueryClient();

  provide<SelectedFields>(ProvideKeys.selectedFields, {
    selectedFields,
    setSelectedFields,
    selectedFieldsProfile,
    selectedPhones,
    setSelectedPhones,
    selectedEmails,
    setSelectedEmails,
  });

  provide<{
    leftProfile: ComputedRef<Company & { count: DetailsCount }> | null,
    rightProfile: Company,
  }>(ProvideKeys.mergeProfiles, { leftProfile, rightProfile });

  const mergeCompanies = async() => {
    try {
      await companyApi.mergeCompanies({
        companyToKeep: rightProfile?.value._id,
        companyToDelete: leftProfile?.value._id,
        fieldsToMerge: {
          ...selectedEmails.value?.length && { emails: selectedEmails.value },
          ...selectedPhones.value?.length && { phones: selectedPhones.value },
          ...selectedFields.value?.includes('locations') && { locations: leftProfile.value?.locations.map(location => location.placeId) },
          ...selectedFields.value?.includes('social.linkedin') && { linkedin: true },
          ...selectedFields.value?.includes('social.website') && { website: true },
          ...selectedFields.value?.includes('social.welcomeToTheJungle') && { welcomeToTheJungle: true },
          ...selectedFields.value?.includes('size') && { size: true },
          ...selectedFields.value?.includes('uniqueid') && { uniqueid: true },
          ...selectedFields.value?.includes('count.description') && { description: true },
          ...selectedFields.value?.includes('count.contacts') && { contacts: true },
          ...selectedFields.value?.includes('count.opportunities') && { opportunities: true },
          ...selectedFields.value?.includes('count.lists') && { lists: true },
          ...selectedFields.value?.includes('count.processes') && { processes: true },
          ...selectedFields.value?.includes('count.documents') && { documents: true },
          ...selectedFields.value?.includes('count.notes') && { notes: true },
          ...selectedFields.value?.includes('logoUrl') && { logoUrl: true },
          ...selectedFields.value?.some(field => field.includes('customFields')) && {
            customFields: selectedFields.value.filter(field => field.includes('customFields')).map(field => field.split('.')[1]),
          },
        },
      });

      queryClient.invalidateQueries(['GET_COMPANY_OPPORTUNITIES', props.company._id]);
      queryClient.invalidateQueries(['GET_COMPANY_CONTACTS', props.company._id]);
      queryClient.invalidateQueries(['GET_CONTRACTS', props.company._id]);
      queryClient.invalidateQueries(['GET_COMPANY_PROFILE', props.company._id]);
      queryClient.invalidateQueries(['FETCHED_PROFILE', props.company._id]);
      queryClient.invalidateQueries(['FETCHED_PROFILE', selectedProfile.value._id]);

      toast.show({
        type: 'success',
        title: t('merge.title'),
        message: t('merge.message'),
      });

      if (props.mergeCallback) {
        props.mergeCallback();
      } else {
        panelStore.closeModal();
      }
    } catch (e) {
      toast.show({
        type: 'error',
        message: e?.response?.body?.message || e?.message,
      });
    }
  };

  onUnmounted(() => {
    queryClient.removeQueries({ queryKey: ['FETCHED_PROFILE'] });
    selectedProfile.value = null;
  });
</script>

<template>
  <div class="flex w-screen max-w-[1280px] shrink-0 flex-col justify-start overflow-auto pb-10">
    <div
      class="panel-company-header flex min-h-[50px] w-[1280px] items-center justify-start gap-6 border-b border-b-[#EDF0FF] bg-neutral-200 px-[18px] py-2">
      <PanelHeaderNav
        class="flex shrink-0"
        :on-back="closeMerge"
      />
      <div class="flex shrink-0 items-center gap-6">
        <h2 class="shrink-0 text-2xl font-bold">
          {{ $t('companies.merge-those-two-companies') }}
        </h2>
        <p>{{ $t('companies.about-to-merge') }}</p>
      </div>
    </div>
    <div class="flex w-[1280px] grow items-center gap-5 px-8 pb-[20px] pt-8">
      <PanelCompanyModalMergeSearch
        v-if="!selectedProfile?._id || !fetchedProfile?._id"
        :company="company"
        class="size-full grow p-5"
        @select="setSelectedProfile"/>
      <PanelCompanyModalMergePreview
        v-else
        :company="leftProfile"
        :to-delete="true"
        class="w-full"/>
      <BcButton
        v-tooltip="$t('merge.switch-profiles')"
        :disabled="!selectedProfile?._id || !fetchedProfile"
        class="shrink-0"
        icon-left="repeat"
        @click="switchProfile"
      />
      <PanelCompanyModalMergePreview
        :company="rightProfile ?? {}"
        :to-delete="false"
        class="w-full"/>
    </div>
    <div class="flex w-[1280px] items-end justify-center gap-5">
      <BcButton
        :label="$t('global.cancel')"
        class="min-w-[280px]"
        icon-left="cross"
        size="small"
        type="outlined"
        @click="closeMerge">
        {{ $t('global.cancel') }}
      </BcButton>
      <div class="flex flex-col items-center">
        <p class="text-sm font-normal text-blue-800">
          ⚠️ {{ $t('candidates.merge-action-is') }} <b
          class="font-bold">{{ $t('generics.irreversible') }}</b>
        </p>
        <BcButton
          :disabled="!fetchedProfile?._id"
          class="min-w-[280px]"
          icon-left="merge"
          native-type="submit"
          size="small"
          @click="mergeCompanies"
        >
          ️ {{ $t('generics.merge') }}
        </BcButton>
      </div>
    </div>
  </div>
</template>
