<script lang="ts" setup>
  import BcModal from '@/ui-kit/components/BcModal/BcModal.vue';
  import { usePanelStore } from '@/store/pinia/panel';
  import { storeToRefs } from 'pinia';
  import { onUnmounted } from 'vue';

  const panelStore = usePanelStore();
  const { modal, modalProps } = storeToRefs(panelStore);

  onUnmounted(() => {
    panelStore.closeModal();
  });
</script>

<template>
  <BcModal
    :active="!!modal"
    :is-panel="true">
    <template #default>
      <component
        :is="modal"
        v-bind="modalProps">
      </component>
    </template>
  </BcModal>
</template>
