<template>
  <div class="onboarding-chapters">
    <div class="onboarding-chapters__items">
      <onboarding-chapter-item
        v-for="(chapter, index) in chapters"
        :key="chapter.key"
        :chapter="chapter"
        :complete="index < selectedChapterIndex"
        :selected="selectedChapterKey === chapter.key"
        @click="updateChapter">
        <div v-if="index < chapters.length - 1" class="onboarding-chapters__items--separator">
          <i class="icon-chevron-right"></i>
        </div>
      </onboarding-chapter-item>
    </div>
    <onboarding-chapter-sections
      :next-chapter="nextChapter"
      :sections="selectedChapter.sections"
      :title="selectedChapter.title"
      class="mt-5">
    </onboarding-chapter-sections>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import OnboardingChapterItem from '@/components/Onboarding/OnboardingChapterItem';
  import OnboardingChapterSections from '@/components/Onboarding/OnboardingChapterSections';
  import OnboardingUploadData from './OnboardingUploadData';
  import { Modals } from '@/store/modules/app';
  import { getCustomJobs } from '@/api/custom-jobs';
  import { getCards } from '@/api/cards';
  import PanelNavigationMixin from '@/common-old/mixins/Panel/panelNavigation';

  export default {
    name: 'onboarding-chapters',
    components: {
      OnboardingChapterItem,
      OnboardingChapterSections,
    },
    mixins: [PanelNavigationMixin],
    mounted() {
      if (!this.$route.query?.chapter) {
        this.updateChapter(this.chapters[0].key);
      }
    },
    data() {
      return {
        chapters: [
          {
            key: 'chapter-1',
            title: this.$t('onboarding.chapter-1.title'),
            subtitle: this.$t('onboarding.chapter-1.subtitle'),
            icon: 'users',
            sections: [
              {
                key: 'section-1',
                title: this.$t('onboarding.chapter-1.section-1-title'),
                text: this.$t('onboarding.chapter-1.section-1-text'),
                content: 'https://storage.googleapis.com/bluecoders-medias-dev/download_extension.mp4',
                contentType: 'video',
                buttonAction: {
                  label: this.$t('onboarding.chapter-1.section-1-title'),
                  action: () => {
                    window.open('https://chrome.google.com/webstore/detail/marvin-recruiter/fbpkffmhbppefhogokhaglmkalfcmaai', '__blank');
                  },
                },
              },
              {
                key: 'section-2',
                title: this.$t('onboarding.chapter-1.section-2-title'),
                text: this.$t('onboarding.chapter-1.section-2-text'),
                content: 'https://storage.googleapis.com/bluecoders-medias-dev/add_candidate.mp4',
                contentType: 'video',
                buttonAction: {
                  label: this.$t('onboarding.chapter-1.section-2-title'),
                  action: () => {
                    // const limitReached = this.checkLimit();
                    // if (limitReached) return;

                    this.$router.push({
                      name: `${this.$route.name}Panel`,
                      params: {
                        id: 'new-coder',
                      },
                      query: {
                        ...this.$route.query,
                        type: 'create-coder',
                      },
                    });
                  },
                },
              },
              {
                key: 'section-3',
                title: this.$t('onboarding.chapter-1.section-3-title'),
                text: this.$t('onboarding.chapter-1.section-3-text'),
                content: 'https://storage.googleapis.com/bluecoders-medias-dev/create_list.mp4',
                contentType: 'video',
                buttonAction: {
                  label: this.$t('generics.create-my-list'),
                  action: () => {
                    this.$router.push({
                      name: 'FavoriteListsCodersPanelCreate',
                      query: {
                        ...this.$route.query,
                        type: 'favorite-coder-list',
                        subtype: 'create-favorite-list',
                        'list-type': 'coder',
                      },
                    });
                  },
                },
              },
            ],
          },
          {
            key: 'chapter-2',
            title: this.$t('onboarding.chapter-2.title'),
            subtitle: this.$t('onboarding.chapter-2.subtitle'),
            icon: 'screen',
            sections: [
              {
                key: 'section-1',
                title: this.$t('onboarding.chapter-2.section-1-title'),
                text: this.$t('onboarding.chapter-2.section-1-text'),
                content: new URL(`../../assets/img/onboarding/career.png`, import.meta.url),
                contentType: 'img',
                buttonAction: {
                  label: this.$t('generics.personalize'),
                  action: () => {
                    this.$router.push({
                      name: 'CareerWebsite',
                    });
                  },
                },
              },
              {
                key: 'section-2',
                title: this.$t('onboarding.chapter-2.section-2-title'),
                text: this.$t('onboarding.chapter-2.section-2-text'),
                content: 'https://storage.googleapis.com/bluecoders-medias-dev/create_opportunities.gif',
                contentType: 'gif',
                buttonAction: {
                  label: this.$t('generics.create'),
                  action: () => {
                    // const limitReached = this.checkLimit();
                    // if (limitReached) return;

                    this.$router.push({
                      ...this.$route,
                      name: `${this.$route.name}Panel`,
                      params: {
                        id: 'new-custom-jobs',
                      },
                      query: {
                        ...this.$route.query,
                        type: 'create-custom-jobs',
                      },
                    });
                  },
                },
              },
              {
                key: 'section-3',
                title: this.$t('onboarding.chapter-2.section-3-title'),
                text: this.$t('onboarding.chapter-2.section-3-text'),
                content: 'https://storage.googleapis.com/bluecoders-medias-dev/publish_opportunity.gif',
                contentType: 'gif',
                buttonAction: {
                  label: this.$t('generics.publish-my-ad'),
                  action: async() => {
                    const { data } = await getCustomJobs({
                      coachId: this.profile._id,
                      active: true,
                      page: 1,
                    });

                    const job = data?.jobs?.find(job => !job.isSpontaneous);
                    if (!job) {
                      this.$toast.show({
                        type: 'error',
                        message: this.$i18n.t('onboarding.job-not-found'),
                      });
                      return;
                    }

                    this.$router.push({
                      name: 'OnboardingCustomJobsPanel',
                      query: {
                        page: 1,
                        type: 'hunt',
                        category: 'distribution',
                        coachId: this.profile._id,
                        'company-id': job._company._id,
                        ...this.$route.query,
                        'job-active': true,
                        display: 'panel',
                      },
                      params: {
                        id: job._id,
                      },
                    });
                  },
                },
              },
              {
                key: 'section-4',
                title: this.$t('onboarding.chapter-2.section-4-title'),
                text: this.$t('onboarding.chapter-2.section-4-text'),
                content: new URL(`../../assets/img/onboarding/share-candidate.png`, import.meta.url),
                contentType: 'img',
              },
            ],
          },
          {
            key: 'chapter-3',
            title: this.$t('onboarding.chapter-3.title'),
            subtitle: this.$t('onboarding.chapter-3.subtitle'),
            icon: 'loop',
            sections: [
              {
                key: 'section-1',
                title: this.$t('onboarding.chapter-3.section-1-title'),
                text: this.$t('onboarding.chapter-3.section-1-text'),
                content: new URL(`../../assets/img/onboarding/edit-process.png`, import.meta.url),
                contentType: 'img',
                buttonAction: {
                  label: this.$t('generics.edit-2'),
                  action: () => {
                    this.$router.push({
                      name: 'ConfigurationProcess',
                    });
                  },
                },
              },
              {
                key: 'section-2',
                title: this.$t('onboarding.chapter-3.section-2-title'),
                text: this.$t('onboarding.chapter-3.section-2-text'),
                content: 'https://storage.googleapis.com/bluecoders-medias-dev/follow-activities.gif',
                contentType: 'gif',
                buttonAction: {
                  label: this.$t('generics.track-activities'),
                  action: () => {
                    this.$router.push({
                      name: 'ConfigurationProcess',
                    });
                  },
                },
              },
              {
                key: 'section-3',
                title: this.$t('onboarding.chapter-3.section-3-title'),
                text: this.$t('onboarding.chapter-3.section-3-text'),
                content: new URL(`../../assets/img/onboarding/create-process.png`, import.meta.url),
                contentType: 'img',
                buttonAction: {
                  label: this.$t('process.create-a-process'),
                  action: () => {
                    this.$router.push({
                      ...this.$route,
                      name: `${this.$route.name}Panel`,
                      params: {
                        id: 'panel',
                      },
                      query: {
                        ...this.$route.query,
                        type: 'create-process',
                      },
                    });
                  },
                },
              },
            ],
          },
          {
            key: 'chapter-4',
            title: this.$t('onboarding.chapter-4.title'),
            subtitle: this.$t('onboarding.chapter-4.subtitle'),
            icon: 'magic-wand',
            sections: [
              {
                key: 'section-1',
                title: this.$t('onboarding.chapter-4.section-1-title'),
                text: this.$t('onboarding.chapter-4.section-1-text'),
                content: new URL(`../../assets/img/onboarding/invit-collegue.png`, import.meta.url),
                contentType: 'img',
                buttonAction: {
                  label: this.$t('generics.invit'),
                  action: () => {
                    this.$router.push({
                      name: 'Team',
                    });
                  },
                },
              },

              {
                key: 'section-2',
                title: this.$t('onboarding.chapter-4.section-2-title'),
                text: this.$t('onboarding.chapter-4.section-2-text'),
                content: new URL(`../../assets/img/onboarding/sync-mail.png`, import.meta.url),
                contentType: 'img',
                buttonAction: {
                  label: this.$t('generics.to-synchronize'),
                  action: () => {
                    this.$router.push({
                      name: 'ConfigurationMessaging',
                    });
                  },
                },
              },
              {
                key: 'section-3',
                title: this.$t('onboarding.chapter-4.section-3-title'),
                text: this.$t('onboarding.chapter-4.section-3-text'),
                content: 'https://storage.googleapis.com/bluecoders-medias-dev/share-processes.gif',
                contentType: 'gif',
                buttonAction: {
                  label: this.$t('generics.share'),
                  action: async() => {
                    const { data } = await getCards({
                      call: 'partners/lookup',
                      responseKeyName: 'companies',
                      parameters: {
                        page: 1,
                      },
                      page: 1,
                    });

                    if (!data?.companies?.length) {
                      this.$toast.show({
                        type: 'error',
                        message: this.$i18n.t('onboarding.company-not-found'),
                      });
                      return;
                    }

                    this.openPanel({
                      id: data.companies[0]._id,
                      type: 'companies',
                      category: 'my-notes',
                    });
                  },
                },
              },
              {
                key: 'section-4',
                title: this.$t('onboarding.chapter-4.section-4-title'),
                text: this.$t('onboarding.chapter-4.section-4-text'),
                content: 'https://storage.googleapis.com/bluecoders-medias-dev/drop-cv.gif',
                contentType: 'gif',
                buttonAction: {
                  label: this.$t('generics.parse-cvs'),
                  action: () => {
                    // const limitReached = this.checkLimit();
                    // if (limitReached) return;

                    if (!this.configurationFeatures.candidateDropCV) {
                      this.setModal(Modals['modal-feature-drop-cv']);
                    } else {
                      this.setModal(Modals.uploadCodersResume);
                    }
                  },
                },
              },
              {
                key: 'section-5',
                title: this.$t('onboarding.chapter-4.section-5-title'),
                text: this.$t('onboarding.chapter-4.section-5-text'),
                content: 'onboarding-upload-data',
                contentType: 'component',
                component: OnboardingUploadData,
              },
              {
                key: 'section-6',
                title: this.$t('onboarding.chapter-4.section-6-title'),
                text: this.$t('onboarding.chapter-4.section-6-text'),
                content: 'https://storage.googleapis.com/bluecoders-medias-dev/reminders.mov',
                contentType: 'video',
                buttonAction: {
                  label: this.$t('generics.organize-my-sourcing'),
                  action: () => {
                    this.$router.push({
                      name: 'Coders',
                    });
                  },
                },
              },
            ],
          },
        ],
      };
    },
    computed: {
      ...mapState({
        profile: state => state.user.profile,
      }),
      ...mapGetters({
        configurationFeatures: 'user/configurationFeatures',
      }),
      selectedChapterKey() {
        return this.$route.query?.chapter ? this.$route.query?.chapter : this.chapters[0].key;
      },
      selectedChapterIndex() {
        const index = this.chapters.findIndex(chapter => chapter.key === this.selectedChapterKey);
        return index !== -1 ? index : 0;
      },
      selectedChapter() {
        return this.chapters[this.selectedChapterIndex];
      },
      nextChapter() {
        return this.chapters[this.selectedChapterIndex + 1];
      },
    },
    methods: {
      ...mapActions({
        setModal: 'app/setModal',
      }),
      updateChapter(chapterKey) {
        this.$router.push({
          query: {
            ...this.$route.query,
            chapter: chapterKey,
            section: this.selectedChapter?.sections?.[0]?.key,
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .onboarding-chapters {
    width: 100%;
    height: 100%;
    flex-direction: column;

    &__items {
      width: 100%;
      justify-content: space-between;

      &--separator {
        padding: 0 10px;

        & i {
          color: $color-primary;
        }
      }
    }
  }
</style>
