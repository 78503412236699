<template>
  <panel-process-details-card :title="$t('toast.the-opportunity')" icon="briefcase">
    <panel-process-details-job-introduced
      :is-editing="isEditing"
      :process="process"
      class="panel-process-details-job__step"
    >
    </panel-process-details-job-introduced>
    <panel-process-details-job-offer
      :is-editing="isEditing"
      :process="process"
      class="panel-process-details-job__step"
      v-if="process.offeredAt || (process.dealtAt && isEditing)"
    >
    </panel-process-details-job-offer>
    <panel-process-details-job-deal
      :is-editing="isEditing"
      :process="process"
      class="panel-process-details-job__step"
      v-if="process.dealtAt"
    >
    </panel-process-details-job-deal>
    <panel-process-details-job-validated
      :is-editing="isEditing"
      :process="process"
      class="panel-process-details-job__step"
      v-if="process.validatedAt"
    >
    </panel-process-details-job-validated>
    <panel-process-details-job-footer
      :is-editing.sync="isEditing"
      :process="process"
      @process-updated="updateProcess"
      v-if="isEditing"
    >
    </panel-process-details-job-footer>
  </panel-process-details-card>
</template>

<script>
import PanelProcessDetailsJobIntroduced from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsJob/PanelProcessDetailsJobIntroduced';
import PanelProcessDetailsJobFooter from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsJob/PanelProcessDetailsJobFooter';
import PanelProcessDetailsJobOffer from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsJob/PanelProcessDetailsJobOffer';
import PanelProcessDetailsJobDeal from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsJob/PanelProcessDetailsJobDeal';
import PanelProcessDetailsJobValidated from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsJob/PanelProcessDetailsJobValidated';
import PanelProcessDetailsCard from './PanelProcessDetailsCard.vue';

export default {
  name: 'panel-process-details-job',
  components: {
    PanelProcessDetailsJobValidated,
    PanelProcessDetailsJobDeal,
    PanelProcessDetailsJobOffer,
    PanelProcessDetailsJobFooter,
    PanelProcessDetailsJobIntroduced,
    PanelProcessDetailsCard
  },
  props: {
    process: {
      type: Object,
      default: () => ({
        _customJob: {}
      })
    }
  },
  data: () => ({
    isEditing: false
  }),
  methods: {
    setIsEditing(value) {
      this.isEditing = value;
    },
    updateProcess(process) {
      this.$emit('process-updated', process);
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-process-details-job {
  flex-direction: column;

  &__container {
    align-items: center;
    margin-bottom: 10px;
  }

  &__icon {
    color: $color-blue-dodger;
    background-color: rgba($color-primary, 0.2);
    opacity: 0.5;
    font-size: $font-size-l;
    padding: 5px;
    border-radius: $border-radius-s;
    margin-left: 10px;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  &__step {
    border-bottom: 1px solid rgba($color-blue-dark-cello, 0.2);

    &:last-of-type {
      border-bottom: none;
    }
  }
}
</style>
