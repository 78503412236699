<template>
  <div class="searchbar-filters-last-coach-activity">
    <p class="searchbar-filters-last-coach-activity__title is-secondary">
      {{ $t('hunt.last-activity') }} <i v-if="!configurationFeatures.searchByLastActivity" class="icon-lock"></i>
    </p>
    <div class="searchbar-filters-last-coach-activity__wrapper is-full-width is-column">
      <bc-slider
        :is-input-displayed="false"
        :max="max"
        :min="0"
        :step="1"
        class="searchbar-filters-last-coach-activity__slider is-full-width"
        type="range"
        is-revert
        v-model="period">
        <bc-slider-tick
          :value="0"
          class="searchbar-filters-last-coach-activity__tick">
          {{ $t('hunt.today') }}
        </bc-slider-tick>
        <bc-slider-tick
          :value="1"
          class="searchbar-filters-last-coach-activity__tick">
          {{ $t('hunt.three-months') }}
        </bc-slider-tick>
        <bc-slider-tick
          :value="2"
          class="searchbar-filters-last-coach-activity__tick">
          {{ $t('hunt.six-months') }}
        </bc-slider-tick>
        <bc-slider-tick
          :value="3"
          class="searchbar-filters-last-coach-activity__tick">
          {{ $t('hunt.one-year') }}
        </bc-slider-tick>
        <bc-slider-tick
          :value="4"
          class="searchbar-filters-last-coach-activity__tick">
          {{ $t('hunt.more-than-a-year') }}
        </bc-slider-tick>
      </bc-slider>
      <p
        class="searchbar-filters-last-coach-activity__text"
        v-if="range[0] > 0 || range[1] < max">
        {{ label }}
      </p>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';
  import moment from 'moment/min/moment-with-locales';

  import { Modals } from '@/store/modules/app';
  import { buildLastCoachActivityTag } from '@/helpers/searchbar/factories';
  import BcSlider from '@/ui-kit/components/BcSlider/BcSlider';
  import BcSliderTick from '@/ui-kit/components/BcSlider/BcSliderTick';

  export default {
    name: 'searchbar-filters-last-coach-activity',
    components: { BcSliderTick, BcSlider },
    data() {
      return {
        range: [0, 4],
        options: [
          {
            labelLeft: this.$i18n.t('hunt.no-limit-determined'),
            labelRight: '',
            labelLast: '',
            query: undefined,
          },
          {
            labelLeft: this.$i18n.t('hunt.more-than-3-months'),
            labelRight: this.$i18n.t('hunt.less-than-3-months'),
            labelLast: this.$i18n.t('hunt.the-last-3-months'),
            query: moment().subtract(3, 'months'),
          },
          {
            labelLeft: this.$i18n.t('hunt.more-than-6-months'),
            labelRight: this.$i18n.t('hunt.less-than-6-months'),
            labelLast: this.$i18n.t('hunt.the-last-6-months'),
            query: moment().subtract(6, 'months'),
          },
          {
            labelLeft: this.$i18n.t('hunt.more-than-1-year'),
            labelRight: this.$i18n.t('hunt.less-than-1-year'),
            labelLast: this.$i18n.t('hunt.the-last-year'),
            query: moment().subtract(12, 'months'),
          },
          {
            labelLeft: '',
            labelRight: this.$i18n.t('hunt.no-limit-determined'),
            labelLast: '',
            query: undefined,
          },
        ],
      };
    },
    computed: {
      ...mapState({
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags,
      }),
      ...mapGetters({
        configurationFeatures: 'user/configurationFeatures',
      }),
      max() {
        return this.options.length - 1;
      },
      period: {
        get() {
          return this.range;
        },
        set(value) {
          if (!this.configurationFeatures.searchByLastActivity) {
            this.setModal(Modals['modal-feature-last-activity']);
            // this.range = value;
            return;
          }
          if (value[0] < value[1]) {
            this.range = value;
          }

          if (this.range[0] > 0 || this.range[1] < this.max) {
            this.$emit('on-select', buildLastCoachActivityTag({
              from: this.options[this.range[0]].query,
              to: this.options[this.range[1]].query,
            }));
          } else {
            const tagIndex = this.searchbarFiltersTags.findIndex(tag => tag.type === 'lastCoachActivity');

            if (tagIndex >= 0) {
              this.$emit('on-unselect', tagIndex);
            }
          }
        },
      },
      label() {
        if (this.range[0] > 0 && this.range[1] === this.max) {
          return `${this.$i18n.t('hunt.no-action-for')} ${this.options[this.range[0]].labelLeft}`;
        }

        return this.range[0] === 0 && this.range[1] < this.max ? `${this.$i18n.t('hunt.no-action-on')}  ${this.options[this.range[1]].labelLast}` : `${this.$i18n.t('hunt.no-action-for')} ${this.options[this.range[0]].labelLeft} ${this.$i18n.t('hunt.but')} ${this.options[this.range[1]].labelRight}`;
      },
    },
    methods: {
      ...mapActions({
        setModal: 'app/setModal',
      }),
      handleClick() {
        alert("hello")
      }
    }
  };
</script>

<style lang="scss" scoped>
  .searchbar-filters-last-coach-activity {
    flex-direction: column;
    @include bp('tablet') {
      flex-direction: row;
    }

    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      font-size: $font-size-s;
      justify-content: center;
      line-height: 18px;
      @include bp('tablet') {
        justify-content: flex-start;
        text-align: left;
        margin-bottom: 0;
        width: 85px;
        margin-right: 35px;
      }
    }

    &__wrapper {
      position: relative;
      margin-right: -30px;
      justify-content: center;
      @include bp('tablet') {
        min-width: 305px;
        margin-top: -5px;
      }
    }

    &__text {
      margin-top: 15px;
    }

    &__slider {
      flex-shrink: 0;
      min-height: 55px;
    }

    &__tick {
      :deep() {
        .bc-slider-tick__label {
          min-width: 100px;
          text-align: center;
        }
      }
    }
  }
</style>
