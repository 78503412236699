<template>
  <div class="organizations-modal-delete is-column is-align-items-center">
    <p class="organizations-modal-delete__title font-size-xl mb-4 font-weight-bold">
      {{ $t('tags.delete-tag') }}
    </p>
    <div class="organizations-modal-delete__body is-full-width is-column">
      <p class="database-coders-modal-data-import__text is-secondary is-text-align-center mb-1">
        {{ $t('tags.confirm-delete-tag') }}
      </p>
      <p
        class="database-coders-modal-data-import__text is-error font-weight-bold is-text-align-center">
        {{ $t("timeline.this-action-is-irreversible") }}
      </p>
    </div>
    <hr class="organizations-modal-delete__separator mt-5 mb-5 full-width"/>
    <div class="organizations-modal-delete__wrapper is-full-width is-justify-content-center">
      <bc-button
        class="organizations-modal-delete__button mr-4"
        data-button-cancel
        type="outlined"
        @click="$emit('close-modal')">
        {{ $t('generics.cancel') }}
      </bc-button>
      <bc-button
        class="organizations-modal-delete__button"
        color="success"
        data-button-validate
        @click="deleteTag">
        {{ $t('tags.delete-tag') }}
      </bc-button>
    </div>
  </div>
</template>

<script>
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import { deleteTag } from '@/api/tags';

  export default {
    name: 'organizations-modal-delete',
    components: { BcButton },
    props: {
      tag: {
        type: Object,
        default: () => ({}),
      },
    },
    methods: {
      async deleteTag() {
        try {
          await deleteTag(this.tag._id);

          this.$emit('tag-deleted');
        } catch (error) {
          this.$toast.show({
            title: 'Erreur lors de la suppression de la compétence',
            type: 'error',
            message: error?.response?.data?.message || error,
          });
        }
      },
    },
  };
</script>

<style lang=scss scoped>
  .organizations-modal-delete {
    padding: 30px;
    width: 100%;
    max-width: 600px;

    @include bp('tablet') {
      min-width: 600px;
    }

    &__title {
      color: $color-secondary;
    }

    &__separator {
      width: 100%;
      border: 0;
      border-top: 1px solid rgba($color-secondary, 0.2);
    }
  }
</style>
