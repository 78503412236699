export const apiRole = {
  operationnel: 1,
  lead: 2,
  manager: 3,
  director: 4,
  ceo: 5,
};
export const roles = {
  operationnel: {
    api: apiRole.operationnel,
    value: 'panel-coder-edit.function-dropdown-operating',
    query: 'operationnel',
  },
  lead: {
    api: apiRole.lead,
    value: 'panel-coder-edit.function-dropdown-lead',
    query: 'lead',
  },
  manager: {
    api: apiRole.manager,
    value: 'panel-coder-edit.function-dropdown-manager',
    query: 'manager',
  },
  director: {
    api: apiRole.director,
     value:  'panel-coder-edit.function-dropdown-director',
    query: 'director',
  },
  ceo: {
    api: apiRole.ceo,
    value: 'panel-coder-edit.function-dropdown-ceo',
    query: 'ceo',
  },
};

export const getRoleNameFromApiValue = apiValue => {
  if (apiValue) {
    const rolesArray = Object.entries(roles);
    const [, selectedRole] = rolesArray.find(([, values]) => values.api === apiValue);

    return selectedRole?.value;
  }

  return ''
};
export const getRoleItemsList = () => Object.entries(roles).flat().filter(role => typeof role === 'object');

export default { roles, apiRole };
