import moment from 'moment/min/moment-with-locales';
let language = window.localStorage.getItem("mv-locale")

if(language === "fr"){
moment.updateLocale('fr', {
  relativeTime: {
    future: 'dans %s',
    past: 'il y a %s',
    s: 'quelques secondes',
    ss: '%d secondes',
    m: '1 minute',
    mm: '%d minutes',
    h: 'une heure',
    hh: '%d heures',
    d: 'un jour',
    dd: '%d jours',
    M: 'un mois',
    MM: '%d mois',
    y: 'un an',
    yy: '%d ans'
  }
});
}else{
moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'few seconds',
    ss: '%d seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: 'one hour',
    hh: '%d hours',
    d: 'one day',
    dd: '%d days',
    M: 'one month',
    MM: '%d months',
    y: 'one year',
    yy: '%d years'
  }
});
}

const onlineSince = (startsAt,language) => {
  if(language === "fr"){
    return moment(startsAt, 'YYYYMMDD').locale('fr').fromNow();
  }else if(language === "en") {
    return moment(startsAt, 'YYYYMMDD').locale('en').fromNow();
  }
}


// TODO Vérifier que cette mixin est encore utilisé
export default {
  methods: {
    $_setTimer(expiresAt, startsAt) {
      const currentTime = new Date().getTime();
      const pExpiresAt = new Date(expiresAt).getTime();
      const pStartsAt = new Date(startsAt).getTime();
      return 100 - (((currentTime - pStartsAt) / (pExpiresAt - pStartsAt)) * 100);
    },
    $_setRemainingTime(pExpiresAt) {
      const expiresAt = moment(pExpiresAt, 'YYYY-MM-DD HH:mm:ss');
      let remainingTime = expiresAt.fromNow();
      return remainingTime;
    },
    $_onlineSince(startsAt, language) {
      onlineSince(startsAt,language)
    }
  }
};
