<script setup lang="ts">
import { defineProps, defineEmits, withDefaults, computed } from 'vue';
import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
import { formatBytes } from '@/helpers/strings';

const PDF_LOGO = require('@/assets/logo/files/pdf.png');
const DOC_LOGO = require('@/assets/logo/files/doc.png');
const IMAGE_LOGO = require('@/assets/logo/files/image.png');
const XML_LOGO = require('@/assets/logo/files/xml.png');

const filesInfos: Record<string, { logo: string; class: string; }> = {
  pdf: {
    logo: PDF_LOGO,
    class: 'bg-[#F5DFE0]',
  },
  doc: {
    logo: DOC_LOGO,
    class: 'bg-[#D9DFFD]',
  },
  docx: {
    logo: DOC_LOGO,
    class: 'bg-[#D9DFFD]',
  },
  csv: {
    logo: XML_LOGO,
    class: 'bg-[#DDF4E9]',
  },
  xls: {
    logo: XML_LOGO,
    class: 'bg-[#DDF4E9]',
  },
  jpg: {
    logo: IMAGE_LOGO,
    class: 'bg-[#DDF4E9]',
  },
  jpeg: {
    logo: IMAGE_LOGO,
    class: 'bg-[#DDF4E9]',
  },
  png: {
    logo: IMAGE_LOGO,
    class: 'bg-[#DDF4E9]',
  }
}

interface Props {
  file?: File;
  attachment?: { filename: string; path: string; };
  canRemove?: boolean;
}

export type EventData = {
  file?: File;
  attachment?: { filename: string; path: string; };
}

const props = withDefaults(defineProps<Props>(), {
  file: undefined,
  canRemove: true,
});
const emits = defineEmits<{
  (e: 'on-remove', data: EventData): void,
  (e: 'on-download', data: EventData): void
  (e: 'on-open', data: EventData): void
}>();

const fileName = computed(() => {
  const name = props.attachment?.filename ? props.attachment.filename : props.file?.name || '';
  if (name.length > 25) {
    return name.substring(0, 25) + '...';
  }
  return name;
});

const onRemove = () => {
  emits('on-remove', { file: props.file, attachment: props.attachment });
}

const onDownload = () => {
  emits('on-download', { file: props.file, attachment: props.attachment });
}

const onOpen = () =>{
  emits('on-open', { file: props.file, attachment: props.attachment });
}

const fileInfo = computed(() => {
  const fileName = props?.attachment ? props.attachment?.filename : props.file?.name || '';
  const fileExtension = fileName.split('.');
  const extension = fileExtension[fileExtension.length - 1];
  return filesInfos[extension] || {
    logo: DOC_LOGO,
    class: 'bg-[#D9DFFD]',
  };
});
</script>

<template>
  <div class="h-[51px] w-[280px] items-center rounded-md bg-neutral-200 p-2">
    <div class="size-[30px] items-center justify-center rounded-md" :class="fileInfo.class">
      <img v-if="fileInfo.logo" class="size-[20px] object-contain" :src="fileInfo.logo" />
    </div>
    <div class="ml-2 w-full items-center justify-between">
      <div class="is-column">
        <p class="text-ellipsis text-xs font-medium">{{ fileName }}</p>
        <p v-if="file?.size" class="text-xs font-normal text-blue-400">{{ formatBytes(file.size) }}</p>
      </div>
      <div class="gap-1">
        <BcButton
          v-if="canRemove"
          size="extra-small"
          style="width: 24px; height: 24px;"
          color="error"
          icon-center="trash"
          @click="onRemove"
        />
        <BcButton
          size="extra-small"
          style="width: 24px; height: 24px;"
          icon-center="download"
          @click="onDownload"
        />
        <BcButton
          v-if="!canRemove"
          size="extra-small"
          style="width: 24px; height: 24px;"
          icon-center="external-link"
          @click="onOpen"
        />
      </div>
    </div>
  </div>
</template>
