import Vue, { computed, getCurrentInstance } from 'vue';
import VueI18n from 'vue-i18n';
import formationWording from './formation-wording';
import en from '../translations/en.json';
import fr from '../translations/fr.json';

const formationOrganizations = ['638dbf11035e1700bac908c2'];

Vue.use(VueI18n);

function getLocale() {
    const LSLocale = localStorage.getItem('mv-locale');
    if (LSLocale) return LSLocale;
    let locale = import.meta.env.VITE_I18N_LOCALE || 'en';
    const userLocale = navigator?.languages?.[0] || navigator?.language;
    locale = userLocale ? userLocale.split('-')?.[0] : locale;
    document.querySelector('html').setAttribute('lang', locale);
    return locale;
}


function loadLocaleMessages() {
    const locales = [{ en }, { fr }];
    const messages = {};
    locales.forEach(lang => {
        const key = Object.keys(lang);
        messages[key] = lang[key];
    });
    return messages;
}

function checkTextTransform(text) {
    let txt = text.trim();
    let isUpperCase = false;
    let isCapitalize = false;
    if (txt.toUpperCase() === txt) {
        isUpperCase = true;
    }
    if (!isUpperCase && txt.toUpperCase()[0] === txt[0]) {
        isCapitalize = true;
    }
    return { isUpperCase, isCapitalize };
}


const numberFormats = {
    'en': {
        currency: {
            style: 'currency', currency: 'USD',
        },
    },
    'fr': {
        currency: {
            style: 'currency', currency: 'EUR',
        },
    },
};

let vueI18n = VueI18n;
if (process && import.meta.env.MODE === 'test') {
    vueI18n = {};
} else {
    vueI18n = new VueI18n({
        locale: getLocale(),
        fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'en',
        messages: loadLocaleMessages(),
        numberFormats,
        silentTranslationWarn: true,
    });
    vueI18n.postTranslation = (translated) => {
        const instance = getCurrentInstance();
        const vm = instance?.proxy || instance || new Vue({});

        if (!vm.$store || !vm.$store?.state?.user?.profile?._organization?._id) {
            return translated;
        }
        const organizationId = vm.$store?.state?.user?.profile?._organization?._id;
        if (formationOrganizations.includes(organizationId)) {
            const { isUpperCase, isCapitalize } = checkTextTransform(translated);
            let formated = translated;
            const locale = getLocale();
            const replaceWords = formationWording[locale];
            for (const word in replaceWords) {
                if (formated.toLowerCase().match(new RegExp(`\\b${word}\\b`, ''))) {
                    formated = formated.toLowerCase().replace(word, replaceWords[word]);
                }
            }
            if (isUpperCase) {
                return formated.toUpperCase();
            }
            if (isCapitalize) {
                return formated.substring(0, 1).toUpperCase() + formated.substring(1);
            }
            return formated;
        }
        return translated;
    };
}

export function useI18n() {
    if (!vueI18n) throw new Error('vue-i18n not initialized');

    const i18n = vueI18n;

    const instance = getCurrentInstance();
    const vm = instance?.proxy || instance || new Vue({});

    const locale = computed({
        get() {
            return i18n.locale;
        },
        set(v) {
            i18n.locale = v;
        },
    });

    return {
        locale,
        t: vm.$t.bind(vm),
        tc: vm.$tc.bind(vm),
        d: vm.$d.bind(vm),
        te: vm.$te.bind(vm),
        n: vm.$n.bind(vm),
    };
}

export const i18n = vueI18n;
