<template>
  <div class="organizations-grid w-full">
    <div class="organizations-grid__container w-full">
      <p class="organizations-grid__text organizations-grid__name is-uppercase font-size-s ml-3">
        ID
      </p>
      <p v-tooltip="$t('organizations.activeUsers')"
      class="organizations-grid__text  organizations-grid__active-users uppercase truncate font-size-s hidden-xs">
        {{ $t('organizations.activeUsers') }}
      </p>
      <p class="organizations-grid__text  organizations-grid__tags  uppercase font-size-s hidden-xs">
        INDUSTRIES
      </p>
      <p class="organizations-grid__text  organizations-grid__status  uppercase font-size-s hidden-xs">
        STATUS
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'organizations-grid',
    props: {
      isChecked: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang=scss scoped>
  .organizations-grid {
    padding: 25px 15px;

    @include bp("desktop") {
      padding: 20px 15px;
    }

    &__text {
      color: $color-grey-4;
      width: 100%;
      margin: 0 10px;
    }



    &__name {
      max-width: calc(100% - 35px);
      padding-left: 30px;
    }

    &__active-users {
      max-width: 7rem;
    }

    &__tags {
      max-width: 11rem;
    }

    &__status {
      max-width: 11.5rem;
    }
  }
</style>
