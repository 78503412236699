<template>
  <form-modal-container name="Hellowork" :is-loading="isLoading">
    <ValidationObserver
      class="hellowork-form-modal"
      ref="hellowork-form-modal"
      tag="form"
      v-slot="{ valid: isFormValid }">
      <title-section position="left" :title="'INFOS OBLIGATOIRE'"></title-section>

      <bc-input
        required
        ref="company-title"
        :value="(company || {}).name"
        icon="companies"
        :label="$tc('generics.company', 1)"
        name="company-title"
        :disabled="true"
        type="text">
      </bc-input>

      <!-- TITLE -->
      <ValidationProvider
        v-slot="{ errors, valid }"
        :rules="{ min: 2 }"
        :name="$t('avatar-dropdown.job-title')"
        slim>
        <bc-input
          required
          ref="title"
          :value="customJob.title"
          :class="{ invalid: errors.length > 0 }"
          :error="errors[0]"
          :valid="valid && additionalInfos.title.length > 0"
          class="panel-coder-edit-informations__input panel-coder-edit-informations__input--half"
          icon="suitcase"
          :label="$t('avatar-dropdown.job-title')"
          name="title"
          :disabled="true"
          type="text">
        </bc-input>
      </ValidationProvider>


      <!-- ANONYMOUS -->
      <select-container class="hellowork-form-modal__select" :label="$t('generics.anonymous')">
        <bc-dropdown
          class="font-size-s"
          :is-scrollable="true"
          :limit="3"
          :options="[{ name: $t('generics.yes'), value: true }, { name: $t('generics.no'), value: false }]"
          :required="true"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              type="button"
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="additionalInfos.anonymous ? $t('generics.yes') : $t('generics.no')">
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              @click.native="selectAnonymous(option, index)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
      </select-container>

      <div v-if="customJob.contract && customJob.contract !== 1">
        <bc-input
          :label="$t('custom-jobs.contract-duration-in-months')"
          :labelPlaceholder="false"
          v-model="additionalInfos.contractDuration"
          name="contractDuration"
          type="number"
        ></bc-input>
      </div>

      <!-- SECTOR -->
      <select-container class="hellowork-form-modal__select" :label="$t('generics.sector')" icon="award">
        <bc-dropdown
          class="font-size-s"
          :is-scrollable="true"
          :limit="3"
          :options="SECTORS"
          :required="true"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              type="button"
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="additionalInfos.sector">
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              @click.native="selectSector(option, index)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
      </select-container>

      <!-- QUALIFICATIONS -->
      <select-container class="hellowork-form-modal__select" :label="$tc('generics.qualification', 1)" icon="award">
        <bc-dropdown
          class="font-size-s"
          :is-scrollable="true"
          :limit="3"
          :options="QUALIFICATIONS_LEVELS"
          :required="true"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              type="button"
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="additionalInfos.qualification">
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              @click.native="selectQualification(option, index)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
      </select-container>

      <!-- EMAIL -->
      <div class="is-full-width">
        <bc-input name="emailContact" class="is-full-width" label="Email" :labelPlaceholder="false" v-model="additionalInfos.emailContact"></bc-input>
      </div>

      <custom-jobs-details :custom-job="customJob" />

      <div class="hellowork-form-modal__button-container">
        <bc-button
          @click="$emit('cancel', $event)"
          :disabled="isFormLoading"
          type="outlined"
        >
          {{ $t('generics.cancel') }}
        </bc-button>
        <bc-button
          color="success"
          :disabled="!checkValues"
          :is-loading="isFormLoading"
          @click="onSubmit"
        >
          {{ isEdit ? $t('jobboards.edit-offer', { jobboard: 'Hellowork' }) : $t('jobboards.publish-offer', { jobboard: 'Hellowork' }) }}
        </bc-button>
      </div>
    </ValidationObserver>
  </form-modal-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { publish } from '@/api/job-multipost';
import { CONTRACT_TYPES, EXPERIENCES, SECTORS, QUALIFICATIONS_LEVELS, PROFESSIONS, SALARY_PERIODS } from '@/api/jobboards/hellowork';
import { apiContracts } from '@/api/custom-jobs';
import FormModalContainer from './FormModalContainer';
import BcInput from '@/ui-kit/components/BcInput/BcInput';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
import SelectContainer from '@/components/Select/SelectContainer';
import TitleSection from '@/components/Title/TitleSection';
import CustomJobsDetails from '@/components/CustomJobs/CustomJobsDetails';

export default {
  name: 'hellowork-form-modal',
  components: {
    ValidationObserver,
    ValidationProvider,
    BcInput,
    BcButton,
    BcDropdown,
    BcDropdownItem,
    BcDropdownTrigger,
    FormModalContainer,
    SelectContainer,
    TitleSection,
    CustomJobsDetails,
  },
  props: {
    customJob: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    this.isLoading = true;

    if (this.customJob?.jobMultiposting?.hellowork?.additionalInfos) {
      this.additionalInfos = {
        ...this.additionalInfos,
        ...this.customJob.jobMultiposting.hellowork.additionalInfos,
      };
    } else {
      this.additionalInfos = {
        ...this.additionalInfos,
        sector: this.SECTORS[0].name,
        qualification: this.QUALIFICATIONS_LEVELS[0].name,
        salaryPeriod: this.SALARY_PERIODS[0],
        emailContact: this.profile.email,
        anonymous: this.customJob._organization?.configuration?.career?.anonymize,
      }
    }

    if (!this.additionalInfos.emailContact) {
      this.additionalInfos = {
        ...this.additionalInfos,
        emailContact: this.profile.email,
      }
    }

    this.isLoading = false;
  },
  data() {
    return {
      apiContracts,
      isLoading: false,
      isFormLoading: false,
      CONTRACT_TYPES,
      EXPERIENCES,
      SECTORS,
      QUALIFICATIONS_LEVELS,
      PROFESSIONS,
      SALARY_PERIODS,
      additionalInfos: {
        anonymous: false,
        emailContact: '',
        contractDuration: 1,
        sector: '',
        qualification: '',
      },
    }
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
    }),
    ...mapGetters({
      configurationJobMultiposting: 'user/configurationJobMultiposting',
      configurationCareer: 'user/configurationCareer',
    }),
    company() {
      if (this.configurationCareer.anonymize) {
        return this.customJob._organization;
      }
      return this.customJob._company;
    },
    checkValues() {
      for (const key in this.additionalInfos) {
        if (typeof this.additionalInfos[key] === 'string' && !this.additionalInfos[key]) {
          return false;
        }
        if (Array.isArray(this.additionalInfos[key]) && !this.additionalInfos[key].length) {
          return false;
        } else if (typeof this.additionalInfos[key] === 'object' && !Object.keys(this.additionalInfos[key]).length) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    async onSubmit() {
      this.isFormLoading = true;
      try {
        const { data } = await publish({
          jobboard: 'hellowork',
          customJobId: this.customJob._id,
          additionalInfos: this.additionalInfos,
        });
        this.isFormLoading = false;
        this.$emit('update', data, 'hellowork');
      } catch (err) {
        console.log(err);
        this.isFormLoading = false;
        this.$toast.show({
          type: 'error',
          message: this.$t('jobboards.published-error'),
        });
      }
    },
    selectSector(option) {
      this.additionalInfos = {
        ...this.additionalInfos,
        sector: option.name,
      };
    },
    selectQualification(option) {
      this.additionalInfos = {
        ...this.additionalInfos,
        qualification: option.name,
      };
    },
    selectAnonymous(option) {
      this.additionalInfos = {
        ...this.additionalInfos,
        anonymous: option.value,
      };
    },
  },
  watch: {
    isLoading(value) {
      if (!value) {
        this.$nextTick(() => {
          this.$refs['hellowork-form-modal']?.validate?.();
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hellowork-form-modal {
  flex-direction: column;
  gap: 35px;
  padding: 20px 0px;

  &__select {
    flex-direction: column;
  }

  &__button-container {
    justify-content: center;
    gap: 10px;
  }

  &__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-blue-dark-cello;
    margin-bottom: 10px;

    &--required {
      color: $color-red-mandy;
    }
  }
}
</style>
