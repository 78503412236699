<script lang="ts" setup>
  import { formatPlaceDetailsToBaseLocation } from '@/formatters/locations';
  import { computed, defineEmits, defineProps } from 'vue';
  import { useI18n } from '@/i18n/i18n';
  import { useStore } from '@/store';
  import PanelOpportunityDetailsLine
    from '@/components/Panel/PanelOpportunity/PanelOpportunityDetails/PanelOpportunityDetailsLine.vue';
  import Tag from '@/components/Tag/Tag';
  import { Opportunity } from '@/domains/models/Opportunity';
  import { Profession } from '@/domains/models/Profession';
  import { Skill } from '@/domains/models/Skill';
  import { getContractObjectFromApiValue } from '@/managers/coders/formatters/codersDeserializers';
  import { contractDurationLabels, salaryTypesLabels } from '@/api/custom-jobs';
  import moment from 'moment/moment';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
  import { setAlternativeImg } from '@/mixins/imgFallback';

  const i18n = useI18n();
  const store = useStore();

  interface Props {
    opportunity: Opportunity;
  }

  const props = defineProps<Props>();
  const user = store.state.user.profile;
  const customFields = user._organization.configuration?.job?.customFields?.filter(customField => customField.activated) || [];

  defineEmits<{
    (e: 'edit-company', value: boolean): void
  }>();

  const locations = computed<string[]>(() => {
    return props.opportunity.locations?.map(location => formatPlaceDetailsToBaseLocation(location, 'locality')) || [''];
  });
  const professions = computed<Profession[]>(() => {
    return props.opportunity._professions || [];
  });
  const skills = computed<Skill[]>(() => {
    return props.opportunity._skills || [];
  });
  const contract = computed(() => {
    return getContractObjectFromApiValue(props.opportunity.contract) ? i18n.t(getContractObjectFromApiValue(props.opportunity.contract)?.name) : '';
  });
  const contractStartDate = computed(() => {
    return props.opportunity.contractStartDate ? `${i18n.t('generics.start')} ${moment(props.opportunity.contractStartDate).format('L')}` : '';
  });
  const contractEndDate = computed(() => {
    return props.opportunity.contractEndDate ? `${i18n.t('generics.end')} ${moment(props.opportunity.contractEndDate).format('L')}` : '';
  });

  const getSalaryWithType = ({ salary, type, prefix = '' }) => {
    if (!salary) return '';

    return `${prefix} ${salary} ${salaryTypesLabels[type] || ''}`;
  };
  const budget = computed(() => {
    return `${getSalaryWithType({
      salary: props.opportunity?.budget?.min + '-' + props.opportunity?.budget?.max,
      type: props.opportunity?.budget?.salaryType,
    })}${getSalaryWithType({
      salary: props.opportunity?.budget?.variable,
      type: props.opportunity?.budget?.variableType,
      prefix: ' +',
    })}`;
  });

  const hasDefaultValue = (customField, customFieldValue) => {
    if (customField.defaultValue && Array.isArray(customField.defaultValue)) {
      return customField.defaultValue?.toString() === customFieldValue?.toString();
    }
    return false;
  };
</script>

<template>
  <div class="relative flex w-full flex-col flex-wrap rounded-md bg-neutral-200 p-2.5 text-sm">
    <PanelOpportunityDetailsLine
      v-if="opportunity.uniqueid"
      :title="$t('generics.uniqueid')"
      icon="briefcase"
    >
        <div class="wrap flex justify-end gap-2.5">
          <tag :text="opportunity.uniqueid" icon="briefcase"/>
        </div>
      </PanelOpportunityDetailsLine>
    <PanelOpportunityDetailsLine
      v-if="locations.length"
      :title="$t('generics.location')"
      icon="map-pin"
    >
      <div class="flex flex-wrap justify-end gap-2.5">
        <Tag
          v-for="location in locations"
          :key="location.placeId"
          :text="location.completeAddress ? location.completeAddress : location.fullAddress"
          type="location"
        />
      </div>
    </PanelOpportunityDetailsLine>
    <PanelOpportunityDetailsLine
      v-if="professions.length"
      :title="$t('generics.professions')"
      icon="award"
    >
      <div class="flex flex-wrap justify-end gap-2.5">
        <Tag
          v-for="profession in professions.slice(0, 5)"
          :key="profession._id"
          :text="profession.name"
          icon="tool"
          type="professions"
        />
        <Tag
          v-if="professions.length > 5"
          v-tooltip.bottom="{
            content: professions?.slice(5).map(profession => profession.name).join(', ')
          }"
          :text="`+${professions?.slice(5).length}`"
          icon="tool"
          type="professions"
        />
      </div>
    </PanelOpportunityDetailsLine>
    <PanelOpportunityDetailsLine
      v-if="skills.length"
      :title="$t('panel-coder-edit.skills')"
      icon="tool"
    >
      <div class="flex flex-wrap justify-end gap-2.5">
        <Tag
          v-for="skill in skills.slice(0, 5)"
          :key="skill._id"
          :text="skill.name"
          icon="tool"
          type="skills"
        />
        <Tag
          v-if="skills.length > 5"
          v-tooltip.bottom="{
            content: skills?.slice(5).map(skill => skill.name).join(', '),
            autoHide: false,
          }"
          :text="`+${skills?.slice(5).length}`"
          icon="tool"
          type="skills"
        />
      </div>
    </PanelOpportunityDetailsLine>
    <PanelOpportunityDetailsLine
      v-if="contract"
      :title="$t('companies.account-management.contract-type')"
      icon="file"
    >
      <div class="flex flex-wrap justify-end gap-2.5">
        <Tag
          :text="contract"
          type="contracts"
        />
      </div>
    </PanelOpportunityDetailsLine>
    <PanelOpportunityDetailsLine
      :title="$t('companies.account-management.contract-duration')"
      icon="file"
    >
      <div class="flex flex-wrap justify-end gap-2.5">
        <Tag
          v-if="opportunity.contractDuration"
          :text="contractDurationLabels[opportunity.contractDuration]"
          type="contracts"
        />
        <Tag
          v-if="contractStartDate"
          :text="contractStartDate"
          type="contracts"
        />
        <Tag
          v-if="contractEndDate"
          :text="contractEndDate"
          type="contracts"
        />
      </div>
    </PanelOpportunityDetailsLine>
    <PanelOpportunityDetailsLine
      v-if="opportunity.budget?.min !== 0 && opportunity.budget?.max !== 0"
      :title="$t('generics.budget')"
      icon="salary"
    >
      <div class="flex flex-wrap justify-end gap-2.5">
        <Tag
          v-if="opportunity.budget"
          :text="budget"
          type="salary"
        />
      </div>
    </PanelOpportunityDetailsLine>
    <PanelOpportunityDetailsLine
      v-if="opportunity?._employee"
      :title="$t('custom-jobs.partner-contact')"
      icon="user"
    >
      <div class="flex flex-wrap items-center justify-end gap-1">
        <BcAvatar
          :src="opportunity?._employee?._coder?.pictureUrl"
          class="size-[20px] shrink-0 rounded-full border-2 border-blue-300"
          @error="setAlternativeImg({event: $event, type: 'coders'})"/>
        <p class="text-blue-800">
          {{ opportunity?._employee?._coder?.lastName }}
          {{ opportunity?._employee?._coder?.firstName }}
        </p>
      </div>
    </PanelOpportunityDetailsLine>
    <div
      v-for="customField in customFields"
      :key="customField.key"
    >
      <PanelOpportunityDetailsLine
        v-if="
          opportunity.customFields &&
            opportunity.customFields[customField.key] &&
            !hasDefaultValue(customField, opportunity.customFields[customField.key])
        "
        :icon="customField.icon"
        :title="customField.key === 'emergency' ? $t('generics.priority') : customField.name"
      >
        <div class="flex flex-wrap justify-end gap-2.5">
          <Tag
            :icon="customField.icon"
            :text="
              Array.isArray(opportunity.customFields[customField.key])
                ? opportunity.customFields[customField.key].map(value => `${value} ${customField.unit ? customField.unit : ''}`).join(' - ')
                : opportunity.customFields[customField.key]
            "
            :type="customField.key"/>
        </div>
      </PanelOpportunityDetailsLine>
    </div>
  </div>
</template>
