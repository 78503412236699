const PDF_LOGO = require('@/assets/logo/files/pdf.png');
const DOC_LOGO = require('@/assets/logo/files/doc.png');
const IMAGE_LOGO = require('@/assets/logo/files/image.png');
const XML_LOGO = require('@/assets/logo/files/xml.png');

export const FILES_INFOS: Record<string, { logo: string; class: string; }> = {
  pdf: {
    logo: PDF_LOGO,
    class: 'bg-[#F5DFE0]',
  },
  doc: {
    logo: DOC_LOGO,
    class: 'bg-[#D9DFFD]',
  },
  docx: {
    logo: DOC_LOGO,
    class: 'bg-[#D9DFFD]',
  },
  csv: {
    logo: XML_LOGO,
    class: 'bg-[#DDF4E9]',
  },
  xls: {
    logo: XML_LOGO,
    class: 'bg-[#DDF4E9]',
  },
  jpg: {
    logo: IMAGE_LOGO,
    class: 'bg-[#DDF4E9]',
  },
  jpeg: {
    logo: IMAGE_LOGO,
    class: 'bg-[#DDF4E9]',
  },
  png: {
    logo: IMAGE_LOGO,
    class: 'bg-[#DDF4E9]',
  }
}
