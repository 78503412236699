<template>
  <div class="button-group">
    <button
      v-for="item in items"
      :key="item.key"
      :class="{ 'button-group--active': isActive(item.key) }"
      @click="handleClick(item)">
      <i v-if="item.icon" :class="`icon-${item.icon}`"></i>
      {{ item.label }}
      <span
        v-if="item.count"
        class="button-group__count"
        :class="{ 'button-group__count--active': isActive(item.key) }"
      >
        {{ item.count }}
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'button-group',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activeKey: {
      type: [String, Array],
    },
  },
  methods: {
    handleClick(item) {
      this.$emit('click', item);
    },
    isActive(key) {
      if (Array.isArray(this.activeKey)) {
        return this.activeKey.includes(key);
      }
      return this.activeKey === key;
    }
  },
};
</script>

<style lang="scss" scoped>
.button-group {
  background-color: $color-blue-light;
  color: $color-blue-heavy-dark;
  border-radius: 5px;
  overflow: hidden;

  & > button {
    padding: 12px 16px;
    align-items: center;
    gap: 5px;
  }

  &--active {
    border-radius: 5px;
    border: 1px solid $color-blue-medium;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(29, 57, 94, 0.10);
    color: $color-tertiary;
  }

  &__count {
    width: 16px;
    height: 16px;
    background: $color-blue-medium;
    font-size: $font-size-xxs;
    border-radius: $border-radius-m;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    &--active {
      background: $color-primary;
      color: white;
    }
  }
}
</style>
