<script lang="ts" setup>
  import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav.vue';
  import { usePanelStore } from '@/store/pinia/panel';
  import CampaignsCreateTabs from '@/components/Campaigns/CampaignsCreate/CampaignsCreateTabs.vue';
  import { computed, provide, ref } from 'vue';
  import { useI18n } from '@/i18n/i18n';
  import CampaignsCreateSettings
    from '@/components/Campaigns/CampaignsCreate/CampaignsCreateSettings.vue';
  import { Campaign, CampaignTemplate } from '@/domains/models/Campaign';
  import ProvideKeys from '@/components/Campaigns/CampaignsProvideKeys';
  import { Tab, TabKey } from '@/components/Campaigns/CampaignsTypes';
  import CampaignsCreateAddSteps
    from '@/components/Campaigns/CampaignsCreate/CampaignsCreateAddSteps.vue';
  import CampaignsCreateAddProspects
    from '@/components/Campaigns/CampaignsCreate/CampaignsCreateAddProspects.vue';
  import { useMutation, useQuery } from '@tanstack/vue-query';
  import { deleteCampaign, getCampaign } from '@/api/campaigns';
  import { AxiosResponse } from 'axios';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import BcModal from '@/ui-kit/components/BcModal/BcModal.vue';
  import ModalDeleteItem from '@/components/Modal/ModalDeleteItem.vue';
  import { capitalize } from '@/utils/stringTransform';
  import { useStore } from '@/store';
  import { useCampaignsStore } from '@/store/pinia/campaigns';

  const { t } = useI18n();
  const panelStore = usePanelStore();
  const campaignsStore = useCampaignsStore();
  const store = useStore();

  const props = defineProps<{
    campaign?: Partial<Campaign>;
    selectedTemplate?: CampaignTemplate;
  }>();

  function handleClose() {
    panelStore.closeModal();
  }

  const templateTypes = computed(() => campaignsStore.configuration.types);
  const localSelectedTemplate = ref<CampaignTemplate | null>(props.selectedTemplate || null);
  const templateDraft = ref<Partial<Campaign>>({
    name: '',
    campaignTypes: props.selectedTemplate?.key && templateTypes.value.find(type => type._id === props.selectedTemplate?._id) ? [templateTypes.value.find(type => type._id === props.selectedTemplate?._id)] : [],
  });
  const hasTemplateId = computed(() => !!props.campaign?._id || !!templateDraft.value._id);

  const { refetch: refetchTemplate } = useQuery({
    queryKey: ['fetch-campaign', templateDraft.value._id],
    queryFn: async() => {
      const templateId = props.campaign?._id || templateDraft.value._id as string;

      const response: AxiosResponse<Campaign> = await getCampaign(templateId);
      templateDraft.value = response.data;

      return response.data;
    },
    enabled: hasTemplateId,
  });

  function setTemplateDraftValue<K extends keyof Campaign>(campaign: Record<K, Campaign[K]>) {
    templateDraft.value = {
      ...templateDraft.value,
      ...campaign,
    };
  }

  const tabs = computed<Tab[]>(() => [
    {
      key: 'settings',
      label: t('generics.settings'),
      icon: 'settings',
      onClick: () => {
        setActiveTabKey('settings');
      },
      component: CampaignsCreateSettings,
      disabled: false,
    },
    {
      key: 'add-steps',
      label: t('campaigns.add-steps'),
      icon: 'list',
      onClick: () => {
        setActiveTabKey('add-steps');
      },
      component: CampaignsCreateAddSteps,
      disabled: !templateDraft.value.name || !templateDraft.value.campaignTypes?.length || !templateDraft.value._id,
    },
    {
      key: 'add-prospects',
      label: t('campaigns.add-prospects'),
      icon: 'prospects',
      onClick: () => {
        setActiveTabKey('add-prospects');
      },
      component: CampaignsCreateAddProspects,
      disabled: !templateDraft.value.name || !templateDraft.value.campaignTypes?.length || !templateDraft.value._id,
    },
  ]);

  const panelHistory = ref<TabKey[]>([
    tabs.value[0].key,
  ]);

  function handleBack() {
    if (panelHistory.value.length) {
      activeTabKey.value = panelHistory.value[0];
      panelHistory.value.shift();
    }
  }

  const activeTabKey = ref<TabKey | null>(tabs.value[0].key);
  const activeTabIndex = computed<number>(() => tabs.value.findIndex(tab => tab.key === activeTabKey.value) || 0);
  const nextTab = computed<Tab>(() => tabs.value[activeTabIndex.value + 1] ?? tabs.value[0]);
  const activeTab = computed<Tab>(() => tabs.value.find(tab => tab.key === activeTabKey.value) ?? tabs.value[0]);

  const isModalOpen = ref<boolean>(false);

  const headerTitle = computed(() => {
    if (templateDraft.value._id) {
      return templateDraft.value.name;
    }
    return t('generics.create-a-resource', { resource: t('generics.a-campaign') });
  });

  function setActiveTabKey(tabKey: TabKey): void {
    refetchTemplate();
    activeTabKey.value = tabKey;
    panelHistory.value.unshift(tabKey);
  }

  async function goNextTab() {
    refetchTemplate();
    if (activeTabIndex.value === tabs.value.length - 1) {
      panelStore.closeModal();
    }

    if (activeTabIndex.value < tabs.value.length - 1) {
      activeTabKey.value = tabs.value[activeTabIndex.value + 1].key;
      panelHistory.value.unshift(tabs.value[activeTabIndex.value].key);
    }
  }

  const deleteCampaignMutation = useMutation({
    mutationKey: ['delete-campaign', templateDraft.value._id],
    mutationFn: async() => {
      await deleteCampaign(templateDraft.value._id as string);

      await store.dispatch('removeCard', templateDraft.value._id);
    },
  });

  function openModal() {
    isModalOpen.value = true;
  }

  function closeModal() {
    isModalOpen.value = false;
  }

  async function confirmDelete() {
    await deleteCampaignMutation.mutateAsync();

    panelStore.closeModal();
  }

  function clearSelectedTemplate() {
    localSelectedTemplate.value = null;
  }


  provide(ProvideKeys.templateDraftKey, {
    templateDraft,
    setTemplateDraftValue,
  });
</script>

<template>
  <div class="flex w-[1280px] max-w-[100vw] flex-col">
    <div
      class="flex min-h-[50px] w-full max-w-[100vw] items-center gap-6 border-b border-b-[#EDF0FF] bg-neutral-200 px-[18px] py-2">
      <PanelHeaderNav
        :class="{'absolute': !templateDraft._id}"
        :is-back-visible="panelHistory.length > 0"
        :on-back="handleBack"
        :on-close="handleClose"
        class="flex shrink-0"
      />
      <div
        :class="{'mx-auto': !templateDraft._id}"
        class="flex shrink-0 items-center gap-6">
        <h2 class="shrink-0 text-2xl font-bold first-letter:capitalize">
          {{ headerTitle }}
        </h2>
      </div>
      <BcButton
        v-if="hasTemplateId"
        class="ml-auto"
        color="error"
        icon-left="trash"
        size="extra-small"
        @click.native="openModal">
      </BcButton>
    </div>
    <div class="flex max-h-full grow flex-col overflow-auto pt-[20px]">
      <CampaignsCreateTabs
        :active-tab-key="activeTabKey"
        :tabs="tabs"
        class="pl-[30px]"/>
      <component
        :is="activeTab.component"
        :active-tab="activeTab"
        :active-tab-index="activeTabIndex"
        :campaign="templateDraft"
        :next-tab="nextTab"
        :selected-template="localSelectedTemplate"
        :tabs="tabs"
        @next="goNextTab"
        @clear-selected-template="clearSelectedTemplate"
      />
    </div>
    <BcModal
      :active="isModalOpen"
      @close="closeModal">
      <ModalDeleteItem
        :only-content="true"
        :title="capitalize($t('generics.do-you-really-want-to-delete-this-resource', { resource: $t('generics.this-campaign') }))"
        @close="closeModal"
        @on-confirm="confirmDelete"
      >
      </ModalDeleteItem>
    </BcModal>
  </div>
</template>

<style lang="scss" scoped>

</style>
