import timeline from '@/api/timeline';

export const getProcess = ({ userType, id, page }) => {
  const execute = (id, page) => {
    try {
      return timeline.getProcess({ userType, id, page });
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    return execute(id, page);
  } catch (error) {
    throw error;
  }
};

export default {
  getProcess,
};
