import notes from '@/common-old/macros/notes';

import { createQualification } from '@/managers/notes/useCases/createQualification';
import { createEchange } from '@/managers/notes/useCases/createEchange';
import { editEchange } from "@/managers/notes/useCases/editEchange";
import { createCommentary } from '@/managers/notes/useCases/createCommentary';
import { createSentMessage } from '@/managers/notes/useCases/createSentMessage';
import { getQualifications } from '@/managers/notes/useCases/getQualifications';
import { getProcess } from '@/managers/notes/useCases/getProcess';
import { deleteNote } from '@/managers/notes/useCases/deleteNote';
import { editDraft } from '@/managers/notes/useCases/editDraft';
import { createDraft } from '@/managers/notes/useCases/createDraft';
import { getDraft } from '@/managers/notes/useCases/getDraft';
import { editQualification } from '@/managers/notes/useCases/editQualification';
import { editSentMessage } from '@/managers/notes/useCases/editSentMessage';
import { editCommentary } from '@/managers/notes/useCases/editCommentary';

const create = async({ userType, id, note }) => {
  return createDraft({ userType, id, note });
};

const createNote = async({ userType, id, note, isNewNote, mentions, images }) => {
  const options = { userType, id, note, isNewNote, mentions, images };

  const orchestrator = {
    [notes.type.echange.name]: createEchange,
    [notes.type.qualification.name]: createQualification,
    [notes.type.message.name]: createSentMessage,
    [notes.type.other.name]: createCommentary,
    [notes.type.process.name]: createCommentary,
  };

  if (orchestrator[note.type]) {
    return orchestrator[note.type](options);
  }

  return createEchange(options);
};

const get = ({ userType, id, type = 'notes', page = 1 }) => {
  const options = { userType, id, page };

  if (type === 'qualifications') {
    return getQualifications(options);
  }

  if (type === 'process') {
    return getProcess(options);
  }

  if (type === 'processCustom') {
    return getProcess(options);
  }
};

const getResourceDraft = id => {
  return getDraft(id);
};

const editADraft = ({ id, note }) => {
  return editDraft({ id, note });
};

const editANote = ({ id, note, userType, mentions, images }) => {
  const options = { id, note, userType, mentions, images };

  return editEchange(options)
};

const remove = ({ id, actionId }) => {
  return deleteNote({ id, actionId });
};

export const noteController = {
  createDraft: create,
  createNote,
  get,
  editDraft: editADraft,
  editNote: editANote,
  remove,
  getDraft: getResourceDraft,
};


export default {
  noteController,
};
