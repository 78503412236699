import { http } from '@/api/index';

export const sendTokenMagicLinkEmail = (email) => {
  return http.post(`/users/magic-link`, {
    email,
  });
};

export const performSocialConnect = (media, tokenOrCode, redirectURI) => {
  return http.post(`/auth/${media}`, {
    [media === 'microsoft' ? 'code' : 'token']: tokenOrCode,
    redirectURI,
  });
};

export const googleConnectPortal = (redirectURI) => {
  const googleURL = 'https://accounts.google.com/o/oauth2/v2/auth';
  const clientId = '163634611738-06fhaj2qqqtkj2r5g0mrb8ng5fjod6gv.apps.googleusercontent.com';
  const state = 'DCEeFWf45A53sdfKef424';
  const scope = 'profile email';
  const encodedUri = encodeURIComponent(redirectURI);

  return `${googleURL}?response_type=token&client_id=${clientId}&state=${state}&scope=${scope}&redirect_uri=${encodedUri}`;
};

export const linkedinConnectPortal = (redirectURI) => {
  const linkedinURL = 'https://www.linkedin.com/oauth/v2/authorization';
  const clientId = '78whahvg18tvlx';
  const state = 'DCEeFWf45A53sdfKef424';
  const scope = 'r_liteprofile%20r_emailaddress';

  return `${linkedinURL}?response_type=code&client_id=${clientId}&redirect_uri=${redirectURI}&state=${state}&scope=${scope}`;
};

export const signUpAfterSocialConnect = (media, profile) => {
  return http.post(`auth/${media}/signup`, {
    firstName: profile.firstName,
    lastName: profile.lastName,
    phone: profile.mobile,
    email: profile.email,
    company: profile.company,
    teamSize: profile.teamSize,
    type: profile.organizationType,
  });
};

export const microsoftConnectPortal = (redirectURI) => {
  const microsoftURL = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
  const clientId = '7f8dfc4e-0cef-4808-803b-379a9115efe4';
  const state = 'DCEeFWf45A53sdfKef424';
  const scope = 'https://graph.microsoft.com/user.read offline_access https://graph.microsoft.com/Mail.Send';
  const encodedUri = encodeURIComponent(redirectURI);

  return `${microsoftURL}?response_type=code&client_id=${clientId}&state=${state}&scope=${scope}&redirect_uri=${encodedUri}&access_type=offline&prompt=consent`;
};

export default {
  sendTokenMagicLinkEmail,
  googleConnectPortal,
  linkedinConnectPortal,
  performSocialConnect,
  signUpAfterSocialConnect,
  microsoftConnectPortal,
};
