<template>
  <div class="pipe is-full-width is-column">
    <router-view/>
  </div>
</template>

<script>

  export default {
    name: 'pipe',
  };
</script>

<style lang=scss scoped>
  .pipe {
    @include page_grid();
  }
</style>
