<template>
  <div class="salary-input">
    <div
      :class="[
        'flex w-full flex-row',
        { 'pointer-events-none cursor-default select-none opacity-60': disabled }, // Condition
      ]">
      <p
        :class="[
          'after:my-auto after:ml-2.5 after:flex after:h-px after:w-full after:border-0 after:bg-blue-200',
          'relative flex w-full cursor-pointer select-none items-center no-underline', // Alignments
          'rounded-t-md transition-shadow duration-200 ease-in-out', // Padding and transition
          'text-md text-blue-800', // Borders and colors
          'focus:text-blue-800 focus:outline-none focus:outline-offset-0', // Focus
        ]"
      >
        <span
          :class="[
            `flex shrink-0 items-center leading-none`,
          ]"
          class="icon-salary flex gap-2.5"
        >
          {{ title }}
        </span>
      </p>
    </div>
    <div v-for="(input, idx) of inputs" :key="`input-${idx}`" class="salary-input__content">
      <div class="salary-input__salary w-full flex flex-col gap-7">
        <div class="flex w-full justify-between">
          <salary-types-select
            :class="{
                'max-w-[calc(50%-10px)]': isAligned,
            }"
            :label="$t('custom-jobs.salary-type')"
            :selected="input.salaryType"
            class="w-full mr-4"
            @select="onSelectSalaryType($event, { index: idx, type: 'salaryType' })">
          </salary-types-select>
          <div
            :class="{
              'max-w-[calc(50%-10px)]': isAligned
            }"
            class="salary-input__content-inputs w-full">
            <bc-input
              v-model="input.value_0"
              :class="{
                'max-w-[calc(50%-5px)]': isAligned,
              }"
              :label="labels[0]"
              :labelPlaceholder="false"
              :name="labels[0]"
              class="w-full"
              icon="salary"
              placeholder="0"
              type="number"
            ></bc-input>
            <bc-input
              v-if="isAligned"
              v-model="input.value_1"
              :label="labels[1]"
              :labelPlaceholder="false"
              :name="labels[1]"
              class="w-full max-w-[calc(50%-5px)]"
              icon="salary"
              placeholder="0"
              type="number"
            ></bc-input>
          </div>
        </div>
        <div
          class="flex w-full justify-between">
          <salary-types-select
            :is-variable="true"
            :label="$t('custom-jobs.salary-variable-type')"
            :selected="input.variableType || input.salaryType"
            class="max-w-[calc(50%-10px)] w-full"
            @select="onSelectSalaryType($event, { index: idx, type: 'variableType' })">
          </salary-types-select>
          <div class="salary-input__content-inputs max-w-[calc(50%-10px)] w-full">
            <bc-input
              v-model="input.value_optional"
              :label="labels[labels.length - 1]"
              :labelPlaceholder="false"
              :name="labels[labels.length - 1]"
              class="w-full"
              icon="salary"
              placeholder="0"
              type="number"
            ></bc-input>
          </div>
        </div>
      </div>
      <div v-if="hasActions" class="salary-input__actions">
        <button
          v-if="!input.showBenefit && !input.benefit"
          class="salary-input__benefit body-m"
          @click.stop="showBenefit(idx)">
          + {{ $t('generics.add-another-benefit') }}
        </button>
        <bc-input
          v-else
          v-model="input.benefit"
          :label="$t('generics.others-benefits')"
          :labelPlaceholder="false"
          :placeholder="$t('generics.ex-company-car')"
          class="salary-input__benefit-input"
          icon="plus"
          name="others-benefits">
        </bc-input>
      </div>
    </div>
    <bc-button v-if="hasActions" type="outlined" @click="addInput">+
      {{ $t('generics.add-a-new-salary') }}
    </bc-button>
    <slot name="actions"></slot>
  </div>
</template>

<script>
  import BcInput from '@/ui-kit/components/BcInput/BcInput';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import SalaryTypesSelect from '@/components/Salary/SalaryTypesSelect';

  const INPUT = {
    salaryType: '',
    value_0: '',
    value_1: '',
    value_optional: '',
    benefit: '',
    showBenefit: false,
  };

  export default {
    name: 'salary-input',
    components: {
      BcInput,
      BcButton,
      SalaryTypesSelect,
    },
    props: {
      selected: {
        type: Array,
        default: () => [],
      },
      title: {
        type: String,
      },
      hasActions: {
        type: Boolean,
        default: true,
      },
      labels: {
        type: Array,
        default: () => [],
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      isAligned: {
        type: Boolean,
        default: false,
      },
      isMinMax: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      if (this.selected.length) {
        this.inputs = this.selected;
      }
    },
    data() {
      return {
        inputs: [
          INPUT,
        ],
      };
    },
    methods: {
      onSelectSalaryType(salaryType, { index, type }) {
        const inputsCopy = [...this.inputs];
        inputsCopy[index] = {
          ...inputsCopy[index],
          [type]: salaryType.apiCode,
        };
        this.inputs = [...inputsCopy];
      },
      showBenefit(index) {
        const inputsCopy = [...this.inputs];
        inputsCopy[index] = {
          ...inputsCopy[index],
          showBenefit: true,
        };
        this.inputs = [...inputsCopy];
      },
      addInput() {
        this.inputs.push(INPUT);
      },
    },
    watch: {
      inputs: {
        handler(value) {
          this.$emit('update', value);
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .salary-input {
    flex-direction: column;

    &__content {
      flex-direction: column;

      &-inputs {
        gap: 10px;

        &:deep() {
          .bc-input {
            margin-top: 0;
          }
        }
      }

    }

    &__salary {
      margin-top: 35px;
      align-items: center;
    }

    &__actions {
      flex-direction: column;
    }

    &__benefit {
      color: $color-primary;
      margin: 10px 0px;

      &-input {
        margin-top: 30px;
        margin-bottom: 15px;
      }
    }

    &__content-inputs {
      &:deep() {
        input {
          padding: 10px;
        }
      }
    }
  }
</style>
