<template>
  <div>
    <div ref="reference">
      <slot name="trigger" />
    </div>
    <transition name="fade">
      <div 
        v-if="isOpen" 
        v-click-outside="() => $emit('close')" 
        ref="menu"
        v-bind="$attrs"
        :style="floatingStyles">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, reactive, nextTick } from 'vue';
import {
  computePosition,
  flip,
  shift,
  offset,
  type Placement,
} from '@floating-ui/dom';

interface Props {
  isOpen: boolean;
  placement?: Placement;
  offsetDistance?: number;
}

const props = withDefaults(defineProps<Props>(), {
  isOpen: false,
  placement: 'bottom-start',
  offsetDistance: 5,
});

interface FloatingStyles {
  position: 'absolute';
  top: string;
  left: string;
  width: string;
  transform: string;
  zIndex: number;
}

const reference = ref<HTMLElement | null>(null);
const menu = ref<HTMLElement | null>(null);
const floatingStyles = reactive<FloatingStyles>({
  position: 'absolute',
  top: '0',
  left: '0',
  width: 'max-content',
  transform: 'translate(0px, 0px)',
  zIndex: 200,
});

async function updatePosition() {
  if (!reference.value || !menu.value) return;

  const { x, y } = await computePosition(reference.value, menu.value, {
    placement: props.placement,
    middleware: [
      offset(props.offsetDistance),
      flip(),
      shift({ padding: 8 }),
    ],
  });

  Object.assign(floatingStyles, {
    transform: `translate(${Math.round(x)}px,${Math.round(y)}px)`,
  });
}

// Update position when dropdown opens
watch(() => props.isOpen, async (newValue) => {
  if (newValue) {
    await nextTick();
    await updatePosition();
  }
});

// Update position when window is resized
let resizeObserver: ResizeObserver | null = null;
watch(() => props.isOpen, (newValue) => {
  if (newValue && reference.value) {
    resizeObserver = new ResizeObserver(updatePosition);
    resizeObserver.observe(reference.value);
    window.addEventListener('scroll', updatePosition, true);
    window.addEventListener('resize', updatePosition);
  } else if (resizeObserver) {
    resizeObserver.disconnect();
    window.removeEventListener('scroll', updatePosition, true);
    window.removeEventListener('resize', updatePosition);
  }
}, { immediate: true });

// Cleanup
watch(() => props.isOpen, (newValue) => {
  if (!newValue && resizeObserver) {
    resizeObserver.disconnect();
    window.removeEventListener('scroll', updatePosition, true);
    window.removeEventListener('resize', updatePosition);
  }
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style> 