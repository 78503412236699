<template>
  <div class="panel-process-timeline is-column">
    <timeline-editor
      v-if="isCreatingNote"
      :current-formatted-process="currentFormattedProcess"
      @close="setIsCreatingNote(false)"
      @link-process="linkProcess"
      @note-saved="addNewNote">
    </timeline-editor>
    <div v-else class="panel-process-timeline__buttons">
      <bc-button
        class="panel-process-timeline__button"
        color="primary"
        data-button-create-note
        @click="setIsCreatingNote(true)"
      >
        {{ $t('companies.panel-view-timeline.create-note') }}
      </bc-button>
      <bc-button
        v-if="hasGoogleWidgets"
        class="panel-process-timeline__button"
        color="secondary"
        data-button-create-note
        iconLeft="calendar"
        @click="setCalendar(true)"
      >
        {{ $t('companies.panel-view-timeline.appointment-creation') }}
      </bc-button>
    </div>

    <template v-if="isCalendar">
      <div class="panel-process-header__reminder">
        <reminders-panel
          v-click-outside="closeDropdown"
          :coder="process._coder"
          :company="process._company"
          class="is-full-width"
          @close-dropdown="closeDropdown">
        </reminders-panel>
      </div>
    </template>
    <p class="panel-process-timeline__title icon-clock">
      {{ $t('companies.panel-view-timeline.timeline') }}
    </p>
    <div class="panel-process-timeline__body">
      <timeline-list
        :is-writeable="true"
        :timeline="timeline"
        @link-process="linkProcess"
        @note-created="addNewNote"
        @note-deleted="deleteNote"
        @note-edited="editNote"
        @unlink-process="unlinkProcess">
      </timeline-list>
      <infinite-loading
        :distance="400"
        :identifier="identifier"
        class="panel-process-timeline__infinite-scroll"
        @infinite="getTimeline">
        <spinner slot="spinner"></spinner>
        <p
          slot="no-more"
          class="panel-process-timeline__text">
        </p>
        <p
          slot="no-results"
          class="panel-process-timeline__text">
          {{ $t('timeline.no-result') }}
        </p>
        <p
          slot="error"
          class="panel-process-timeline__text">
          {{ $t('timeline.error-during-request') }}
        </p>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
  import TimelineList from '@/components/Timeline/TimelineList';
  import TimelineEditor from '@/components/Timeline/TimelineEditorProcess';
  import Spinner from '@/components/UIKit/Spinner/Spinner';
  import InfiniteLoading from 'vue-infinite-loading';
  import { getProcessCustomTimeline } from '@/api/processes-custom';
  import { linkNote, unlinkNote } from '@/api/timeline';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import RemindersPanel from '@/components/RemindersPanel/RemindersPanel';

  export default {
    name: 'panel-process-timeline',
    components: {
      BcButton,
      Spinner,
      TimelineList,
      InfiniteLoading,
      TimelineEditor,
      RemindersPanel,
    },
    props: {
      actionIdentifier: {
        type: Number,
        default: +new Date(),
      },
      currentFormattedProcess: {
        type: Object,
        default: () => ({}),
      },
      process: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        timeline: [],
        page: 1,
        identifier: +new Date(),
        isCreatingNote: false,
        isCalendar: false,
      };
    },
    computed: {
      hasGoogleWidgets() {
        const configurationProcess = this.$store.getters['user/configurationProcess'];
        if (!configurationProcess) {
          return false;
        }
        return configurationProcess.hasGoogleWidgets;
      },
    },
    async created() {
      // try {
      //   await this.$store.dispatch('getLinkableProcesses', this.$route.params.id);
      // } catch (error) {
      //   this.$toast.show({
      //     type: 'error',
      //     message: this.$t('toast.error-occured'),
      //   });
      // }
    },
    methods: {
      setIsCreatingNote(value) {
        this.isCreatingNote = value;
      },
      setCalendar(value) {
        this.isCalendar = value;
      },
      closeDropdown() {
        this.isCalendar = false;
      },
      async getTimeline($state) {
        try {
          const response = await getProcessCustomTimeline({
            id: this.$route.params.id,
            page: this.page,
          });

          $state.loaded();

          this.timeline = [...this.timeline, ...response.data.timeline];

          if ((response.data || {}).next) {
            ++this.page;
          } else {
            $state.complete();
          }
        } catch (error) {
          $state.error();
        }
      },
      resetInfiniteScroll() {
        this.page = 1;
        this.identifier += 1;
      },
      addNewNote(note) {
        this.timeline.unshift(note);
      },
      editNote(action) {
        const editedNoteIndex = this.timeline.findIndex(a => a._id === action._id);

        this.timeline[editedNoteIndex]._note = { ...this.timeline[editedNoteIndex]._note, ...action.note };
      },
      deleteNote(id) {
        this.timeline = this.timeline.filter(timeline => (timeline._note || {})._id !== id);
      },
      async updateTimeline() {
        const response = await getProcessCustomTimeline({
          id: this.$route.params.id,
          page: 1,
        });

        const timeline = [...response.data.timeline, ...this.timeline];
        const timelineFiltered = new Set();

        this.timeline = [...timeline.filter(action => {
          const duplicate = timelineFiltered.has(action._id);

          timelineFiltered.add(action._id);

          return !duplicate;
        })];
      },
      async linkProcess($event) {
        try {
          const _linkedProcesses = $event.processId ? [$event.processId] : $event.processIds;
          const res = await linkNote($event.noteId, _linkedProcesses);
          const editedNoteIndex = this.timeline.findIndex(action => action._id === res.data._id);

          this.timeline[editedNoteIndex]._linkedProcesses = res.data._linkedProcesses;
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      async unlinkProcess($event) {
        try {
          const _linkedProcesses = $event.processId ? [$event.processId] : $event.processIds;
          const res = await unlinkNote($event.noteId, _linkedProcesses);
          const editedNoteIndex = this.timeline.findIndex(action => action._id === res.data._id);

          this.timeline[editedNoteIndex]._linkedProcesses = res.data._linkedProcesses;
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
    },
    watch: {
      '$route.params.id'() {
        this.timeline = [];
        this.resetInfiniteScroll();
      },
      actionIdentifier() {
        this.updateTimeline();
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-process-timeline {
    background: $color-white;
    flex-grow: 1;
    padding: 30px;
    overflow: auto;

    &__title {
      display: flex;
      align-items: center;
      color: rgba($color-blue-dodger, 0.8);
      text-transform: uppercase;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      margin: 20px auto -10px 10px;

      &:before {
        margin-right: 10px;
      }
    }

    &__body {
      width: 100%;
      flex-direction: column;
    }

    &__infinite-scroll {
      justify-content: center;
      padding: 25px 0;
      margin-top: -25px;
    }

    &__text {
      font-size: $font-size-xs;
      color: $color-blue-dark-cello;
    }

    &__buttons {
      button {
        margin-right: 15px;
      }
    }
  }

  .panel-process-header__reminder {
    background: white;
    position: fixed;
    width: 42%;
    z-index: 2;
    will-change: transform;
    top: 24px;
    right: 60%;
    transform: translate3d(560px, 52px, 0px);
    border: 1px solid #3C80F7;
    border-radius: 5px;
  }
</style>
