<template>
  <form
    @submit.prevent="submitNewPage"
    class="bc-pagination-modal is-column is-align-items-center">
    <p class="bc-pagination-modal__title font-size-xl">
      {{ $t('avatar-dropdown.choose-page-number') }}
    </p>
    <bc-input-number
      :max="max"
      :min="1"
      class="bc-pagination-modal__input"
      name="page"
      ref="input"
      v-model="inputValue">
    </bc-input-number>
    <div class="bc-pagination-modal__wrapper">
      <bc-button
        :disabled="isDisabled"
        class="bc-pagination-modal__button"
        color="error"
        native-type="submit">
        {{ $t('generics.cancel') }}
      </bc-button>
      <bc-button
        :disabled="isDisabled"
        class="bc-pagination-modal__button"
        native-type="submit">
        {{ $t('avatar-dropdown.go-to-page') }}
      </bc-button>
    </div>
  </form>
</template>

<script>
  import BcInputNumber from '../BcInputNumber';
  import BcButton from '../BcButton';

  export default {
    name: 'bc-pagination-modal',
    components: { BcInputNumber, BcButton },
    props: {
      current: {
        type: [String, Number],
        default: 1
      },
      max: {
        type: Number,
        default: 1
      }
    },
    data() {
      return {
        inputValue: this.current || 1
      };
    },
    computed: {
      isDisabled() {
        return this.inputValue < 1 || this.inputValue > this.max;
      }
    },
    mounted() {
      this.$refs.input.$refs.input.focus();
      this.$refs.input.$refs.input.select();
    },
    methods: {
      submitNewPage() {
        this.$emit('submit', this.inputValue);

        this.$parent.close();
      }
    }
  };
</script>

<style lang=scss scoped>
  .bc-pagination-modal {
    padding: 30px 20px;
    min-width: 350px;

    &__title {
      color: $color-primary;
      margin-bottom: 30px;
    }

    &__input {
      margin-bottom: 20px;
    }

    &__button {
      margin: 0 10px;
      min-width: 125px;
    }
  }
</style>
