<template>
  <div
    :class="{ 'card-informations--clickable': !isClickDisabled }"
    class="card-informations">
    <div class="card-informations__caption">
      <picture-avatar
        :imgSrc="cardContent._company ? cardContent._company.logoUrl : ''"
        class="card-informations__picture">
      </picture-avatar>
      <div
        :style="{ backgroundColor: getJobField('contracts', 'color', cardContent.contract) }"
        class="card-informations__contract"
        v-if="cardContent.contract">
        <p class="card-informations__contract-text">
          {{ $t(getJobField('contracts', 'name', cardContent.contract)) }}
        </p>
      </div>
    </div>
    <title-resource
      :textLeft="cardContent._company ? cardContent._company.name : ''"
      :textRight="textRight"
      class="card-informations__title hidden-sm hidden-md">
      <div
        class="card-informations__remote"
        slot="icon"
        v-if="(cardContent.remote || []).length > 0">
        <div
          class="card-informations__icon icon-remote-available"
          v-if="(cardContent.remote || []).some(value => value === remote.available)">
        </div>

        <div
          class="card-informations__icon icon-remote-partial"
          v-if="(cardContent.remote || []).some(value => value === remote.partial)">
        </div>

        <div
          class="card-informations__icon icon-remote-full"
          v-if="(cardContent.remote || []).some(value => value === remote.full)">
        </div>
      </div>
    </title-resource>
    <div class="card-informations">
    </div>
    <div
      class="card-informations__jobs-infos-container"
      ref="cardInfos">
      <div class="card-informations__jobs-infos-caption">
        <title-resource
          :textLeft="cardContent._company ? cardContent._company.name : ''"
          :text-right="textRight"
          class="card-informations__text hidden-xs">
          <div
            v-if="(cardContent.remote || []).length > 0"
            class="card-informations__remote"
            slot="icon">
            <div
              class="card-informations__icon icon-remote-available"
              v-if="(cardContent.remote || []).some(value => value === remote.available)"
              v-tooltip.top="$i18n.t('panel-coder-edit.possible-remote')">
            </div>

            <div
              class="card-informations__icon icon-remote-partial"
              v-if="(cardContent.remote || []).some(value => value === remote.partial)"
              v-tooltip.top="$i18n.t('panel-coder-edit.partial-remote')">
            </div>

            <div
              class="card-informations__icon icon-remote-full"
              v-if="(cardContent.remote || []).some(value => value === remote.full)"
              v-tooltip.top="$i18n.t('panel-coder-edit.full-remote')">
            </div>
          </div>
        </title-resource>
        <bc-tag
          bc-background-color="blue-eastern"
          bc-text-color="white"
          class="card-informations__jobs-infos-role"
          v-if="role">
          {{ $t(role) }}
        </bc-tag>
        <bc-tag
          bc-background-color="purple-dark"
          bc-text-color="white"
          class="card-informations__jobs-infos-domains"
          v-if="domains && domains.length > 0">
          {{ $t(domains[0]) }}
        </bc-tag>
        <bc-tag
          bc-background-color="purple-dark"
          bc-text-color="white"
          class="card-informations__jobs-infos-domains"
          v-if="domains && domains.length > 1"
          v-tooltip="{ content: domainsRemaining }">
          +{{ domains.length - 1 }}
        </bc-tag>
      </div>
      <div class="card-informations__jobs-infos-wrapper">
        <div
          :key="index"
          class="card-informations__jobs-infos-subwrapper"
          v-for="(info, index) in filteredInfos">
          <div
            class="card-informations__li-divider"
            v-if="index > 0 && filteredInfos[index - 1].value"></div>
          <icon-labeled
            :icon="info.icon"
            :icon-color="'#3370ea'"
            :label="info.label"
            :value="info.value"
            icon-size="15px"
            label-font-size="12px">
          </icon-labeled>
        </div>
      </div>
      <card-informations-tags
        :tags="technos"
        class="card-coders-informations-content__tags">
      </card-informations-tags>
    </div>
  </div>
</template>

<script>
  import IconLabeled from '@/components/UIKit/Icon/IconLabeled';
  import PictureAvatar from '@/components/UIKit/Picture/PictureAvatar';
  import TitleResource from '@/components/Title/TitleResource';
  import CardInformationsTags
    from '@/components/Card/CardInformations/CardInformationsUtils/CardInformationsTags';
  // helpers
  import JobHelpers from '@/common-old/mixins/Jobs/jobHelpers';
  import BcTag from '@/legacy/ui-kit/components/Tag/BcTag';
  import { roles } from '@/common-old/macros/roles';
  import { apiRemote } from '@/common-old/macros/remote';
  import { getDomainFromApiValue } from '@/common-old/macros/market';
import { getCompleteAddressOrTag } from '@/formatters/locations';

  /**
   * This component contains the informations of the jobs
   */

  export default {
    name: 'card-informations',
    components: {
      BcTag,
      IconLabeled,
      PictureAvatar,
      TitleResource,
      CardInformationsTags,
    },
    mixins: [JobHelpers],
    props: {
      /**
       * The Object that contains the content of the response that will be displayed by the card
       */
      cardContent: {
        type: Object,
        default: () => {
          return {
            _technosPrimary: [{}],
            _technosSecondary: [{}]
          };
        }
      },
      cardType: {
        type: String,
        default: '',
        required: true,
      },
      isClickDisabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        technosContainerWidth: null,
        remote: {
          available: apiRemote.available,
          partial: apiRemote.partial,
          full: apiRemote.full
        },
      };
    },
    created() {
      window.addEventListener('resize', this.setTechnosContainerWidth);
    },
    computed: {
      infos() {
        return [
          {
            icon: 'map-pin',
            value: getCompleteAddressOrTag(this.cardContent.locations?.[0]) || ''
          },
          {
            icon: 'diploma',
            value: this.cardContent.experience && this.cardContent.experience !== 0 ? this.getJobField('experience', 'value', this.cardContent.experience) : ''
          },
          {
            icon: 'dollars',
            value: this.cardContent.contract ? this.computeJobSalary(this.cardContent) : ''
          }
        ];
      },
      textRight() {
        return this.cardContent.name ? this.cardContent.name : '';
      },
      role() {
        return this.cardContent.role ? Object.entries(roles).find(m => m[1].api === this.cardContent.role)[1].value : '';
      },
      domains() {
        return this.cardContent.domains ? this.cardContent.domains.map(domain => getDomainFromApiValue(domain).name) : [];
      },
      domainsRemaining() {
        return this.domains.slice(1).join(', ');
      },
      filteredInfos() {
        return this.infos.filter(info => info.value);
      },
      technos() {
        return this.cardContent ? [
          ...this.cardContent._technosPrimary || [],
          ...this.cardContent._technosSecondary || []
        ] : [];
      }
    },
    methods: {
      setTechnosContainerWidth() {
        this.$refs.cardInfos ? this.technosContainerWidth = this.$refs.cardInfos.offsetWidth : this.technosContainerWidth = 0;
      }
    },
    mounted() {
      this.setTechnosContainerWidth();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.setTechnosContainerWidth);
    }
  };
</script>

<style lang="scss" scoped>
  .card-informations {

    flex-direction: column;
    padding-bottom: 20px;

    @include bp('tablet') {
      padding: 0;
      flex-direction: row;
      overflow: initial;
    }

    &--clickable {
      &:hover {
        cursor: pointer;
      }
    }

    &__title {
      padding-left: 15px;
      z-index: 1;
    }

    &__contract {
      position: absolute;
      top: 30px;
      right: 15px;
      height: 24px;
      border-radius: 4px;
      padding: 5px;
      width: fit-content;
      justify-content: center;
      @include bp('tablet') {
        position: initial;
        margin-top: 10px;
      }
    }

    &__icons-labeled {
      margin: 15px 0;
      flex-wrap: wrap;
    }

    &__icon {
      font-size: $font-size-s;
      height: 30px;
      width: 30px;
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      background: $color-blue-hawkes;
      color: $color-blue-cello;
      margin-left: -5px;
      border: 1px solid $color-white;

      &:first-of-type {
        margin-left: 0;
      }
    }

    &__contract-text {
      font-size: $font-size-xxs;
      line-height: 14px;
      color: $color-white;
    }

    &__caption {
      position: relative;
      justify-content: space-between;
      align-items: center;
      padding: 20px 15px 0;
      transition: 0.3s;
      margin-bottom: 15px;
      width: 146px;

      &:before {
        position: absolute;
        background-color: $color-blue-hawkes;
        content: "";
        width: 100%;
        height: 100%;
        clip-path: polygon(0 0, 100% 0%, 100% 74%, 0% 100%);
        left: 0;
        top: -15px;
        border-radius: 4px 0 0 0;
      }

      @include bp('tablet') {
        padding: 0;
        flex-direction: column;
        margin-bottom: 0;
        justify-content: center;

        &:before {
          position: absolute;
          background-color: $color-blue-hawkes;
          content: "";
          width: 146px;
          height: 100%;
          clip-path: polygon(0 0, 100% 0, 73% 100%, 0% 100%);
          left: 0;
          top: 0;
        }
      }
    }

    &__jobs-infos {
      &-container {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 0 15px;

        @include bp('tablet') {
          width: calc(100% - 146px);
          padding: 20px 0 20px 20px;
          align-items: flex-start;
        }
      }

      &-name {
        @include bp('tablet') {
          margin-right: 10px;
        }
      }

      &-role, &-domains {
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        padding: 7.5px 15px;
        border-radius: 20px;
        white-space: nowrap;
        font-size: $font-size-xs;

        &:first-child {
          margin-left: 0;
        }
      }

      &-caption {
        width: 100%;
        align-items: center;
      }

      &-wrapper {
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        max-width: 300px;
        margin-top: 10px;

        @include bp('tablet') {
          max-width: 500px;
          flex-wrap: nowrap;
          justify-content: flex-start;
        }
      }

      &-subwrapper {
        align-items: center;
        justify-content: center;
        width: 50%;
        margin: 5px 0;

        @include bp('tablet') {
          margin: 8px 10px 8px 5px;
          &:first-child {
            margin-left: 0;
          }
          width: auto;
        }
      }
    }

    &__technos-list {
      width: 100%;
      margin: 15px 15px 0;
      justify-content: flex-start;
      flex-shrink: 0;
      @include bp('tablet') {
        margin: 15px 0 0;
      }
    }

    &__li-divider {
      margin: 0 15px;

      &:before {
        content: "•";
        color: $color-blue-dodger;
        align-items: center;
      }
    }

    &__technos-item {
      align-items: center;
      margin: 0 5px;

      &:first-child {
        margin-left: 0;
      }
    }

    &__tag {
      white-space: nowrap;
    }

    &__favorite {
      height: 100%;
      border-left: 1px solid $color-blue-hawkes;
      @include bp("desktop") {
        margin-left: auto;
      }
    }

    &__remote {
      margin-left: 10px;
    }
  }
</style>
