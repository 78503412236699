import { defineStore } from 'pinia';
import { getCampaignsConfiguration } from '@/api/campaigns';
import { CampaignType } from '@/domains/models/Campaign';

type State = {
  configuration: {
    types: CampaignType[];
    status: string[];
  }
};

export const useCampaignsStore = defineStore('campaigns', {
  state: (): State => {
    return {
      configuration: {
        types: [],
        status: [],
      },
    };
  },
  actions: {
    async getCampaignsConfiguration() {
      const response = await getCampaignsConfiguration();

      this.configuration = response.data;
    },
  },
});
