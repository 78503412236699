<template>
  <tr>
    <th
      v-for="(columnsName, index) in columnsDataToDisplay"
      :key="index"
      :class="`column--${columnsName.name}`">
      {{ $t(columnsName.headerName) }} <span v-if="editable && columnsName.required">*</span>
    </th>
  </tr>
</template>

<script>
export default {
  props: {
    columnsData: {
      type: Array,
      required: true,
    },
    showModal: {
      type: Boolean,
    },
    editable: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    columnsDataToDisplay() {
      return !this.showModal
        ? this.columnsData
        : this.columnsData.filter(column => !column.disabledWhenEditing);
    },
  },
};
</script>

<style lang="scss">
th {
  border-right: 1px solid #caddfd;
  padding: 15px;
  padding-right: 20px;
  font-family: 'Roboto';
  text-align: left;
  padding-left: 10px;
  line-height: 22px;
  font-weight: 500;
  color: $color-blue-dark-cello;
}

.profils {
  width: 103%;
  display: table-caption;
  display: -ms-grid;
  -ms-grid-columns: min-content;
}

tr {
  border: 1px solid #caddfd;
}
</style>
