<template>
  <TimelineItemWrapper :action="{ icon: 'plus' }">
    <div class="flex gap-2.5 pb-[30px]">
      <v-popover
        :delay="{ hide: 100 }"
        :handle-resize="true"
        class="z-10"
        placement="bottom-end"
        popoverArrowClass="tooltip-arrow white-popover-arrow"
        popoverInnerClass="white-popover-inner"
        trigger="click"
      >
        <BcButton
          class="h-[36px]"
          color="primary"
          icon-left="calendar-circle-plus"
          size="small">
          {{ $t('notes.set-a-reminder') }}
        </BcButton>
        <template #popover>
          <div class="flex flex-col bg-white">
            <div
              v-show="profileTypes?.length > 1"
              class="m-5 mb-0 flex-row gap-4 font-inter text-sm">
              <BcRadio
                id="candidate"
                :checked="reminderType === 'candidate'"
                :disabled="profileTypes?.length === 1"
                :value="reminderType"
                name="candidate"
                @input="reminderType = 'candidate'">
                {{ tc('generics.candidate', 1) }}
              </BcRadio>
              <BcRadio
                id="contact"
                :checked="reminderType === 'contact'"
                :disabled="profileTypes?.length === 1"
                :value="reminderType"
                name="contact"
                @input="reminderType = 'contact'">
                {{ tc('generics.contact-cap', 1) }}
              </BcRadio>
            </div>
            <div class="m-5 mt-4 flex-col items-center gap-4">
              <v-date-picker
                v-if="['coders', 'suggestions-coders'].includes(type)"
                v-model="reminderDate"
                :locale="locale"
                :min-date="minDate"
                :select-attribute="selectedStyleProps"
              />
              <div class="font-xs w-full flex-row justify-between">
                <BcButton
                  v-close-popover
                  class="h-7"
                  color="primary"
                  size="small"
                  type="outlined"
                  @click="() => (reminderDate = undefined)">
                  {{ t('generics.cancel') }}
                </BcButton>
                <BcButton
                  v-close-popover
                  :disabled="!reminderDate"
                  class="h-7"
                  color="primary"
                  size="small"
                  @click="handleReminderSet">
                  {{ t('notes.set-reminder') }}
                </BcButton>
              </div>
            </div>
          </div>
        </template>
      </v-popover>
    </div>
  </TimelineItemWrapper>
</template>

<script lang="ts" setup>
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import BcRadio from '@/ui-kit/components/BcRadio/BcRadio.vue';
  import { defineEmits, ref, watch } from 'vue';
  import { useI18n } from '@/i18n/i18n';
  import { ProfileType } from '@/domains/models/Candidate';
  import TimelineItemWrapper from '@/components/Timeline/TimelineItemWrapper.vue';
  import { noteController } from '@/managers/notes/controller';

  const { locale, t, tc } = useI18n();

  const props = defineProps<{
    type: string;
    profileTypes: ProfileType[];
    id: string;
  }>();

  const emits = defineEmits<{
    (e: 'reminder-date'): void;
  }>();

  const selectedStyleProps = ref({
    highlight: {
      start: {
        style: {
          backgroundColor: '#3865FD',
        },
        contentStyle: {
          color: 'white',
        },
      },
    },
  });

  const reminderType = ref<'contact' | 'candidate'>();

  watch(
    () => props.profileTypes,
    profileTypes => {
      reminderType.value = profileTypes.length === 1 ? profileTypes[0] : 'contact';
    },
    { immediate: true },
  );

  const reminderDate = ref<Date | null>(null);

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const minDate = ref(tomorrow);

  const handleReminderSet = async() => {
    const draft = await noteController.createDraft({
      userType: 'coders',
      id: props.id,
      note: {
        dateToContact: reminderDate.value,
        reminderType: reminderType.value,
      },
    });

    const reminder = await noteController.createNote({
      userType: 'coders',
      id: draft._id,
      note: {
        content: '',
        dateToContact: reminderDate.value,
        reminderType: reminderType.value,
      },
    });

    emits('reminder-date');
    reminderDate.value = null;
  };
</script>

<style lang="scss" scoped>
  :deep(.vc-pane) {
    flex-direction: column;
  }

  :deep(.vc-container) {
    border: 0;
    --text-sm: 16px;
  }

  :deep(.vc-header) {
    padding-bottom: 1rem !important;
  }

  :deep(.vc-weekday) {
    color: $color-primary !important;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
  }

  :deep(.vc-weeks) {
    grid-gap: 0.6rem;
  }

  :deep(.vc-focusable:focus) {
    background-color: $color-primary !important;
  }

  :deep(.vc-arrow) {
    color: $color-primary;
  }
</style>
