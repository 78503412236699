import { http } from '@/api/index';

export const INTERNAL_INTEGRATIONS = ['hellowork', 'indeed']

export const signIn = ({ jobboard, credentials, organizationId, params }) => {
  return http.post(`/job-multipost/signin`, {
    jobboard,
    credentials,
    organizationId,
    params,
  });
}

export const publish = ({ jobboard, customJobId, additionalInfos }) => {
  return http.post(`/job-multipost/${customJobId}/publish`, {
    jobboard,
    additionalInfos,
  });
}

export const syncJob = (customJobId) => {
  return http.put(`/job-multipost/${customJobId}/sync`);
}

/**
 * @param {{
 *  jobboard: string
 *  customJobId: string
 *  status: 'published' | 'draft' | 'archived'
 * }} param0
 * @returns
 */
export const updateStatus = ({ jobboard, customJobId, status }) => {
  return http.put(`/job-multipost/${customJobId}/status`, {
    jobboard,
    status,
  })
}

export const remove = ({ jobboard, customJobId }) => {
  return http.delete(`/job-multipost/${customJobId}`, {
    data: { jobboard },
  });
}
