<template>
  <div class="panel-custom-jobs-distribution">
    <custom-jobs-form-section
      class="panel-custom-jobs-distribution__section mt-2"
      :title="$t('custom-jobs.career-website')"
    >
      <div class="panel-custom-jobs-distribution__content">
        <p class="body-l--bold">{{ $t('custom-jobs.my-career-website') }}</p>
        <div class="panel-custom-jobs-distribution__link">
          <div>
            <a class="is-ellipsis" :href="careerWebsiteUrl" target="_blank">{{ careerWebsiteUrl }}</a>
          </div>
          <button
            v-tooltip="handleTooltip"
            v-clipboard:copy="careerWebsiteUrl"
            v-clipboard:success="setIsCopiedUrl">
            <i class="icon-clipboard-2"></i>
          </button>
        </div>
        <div @click="checkFeatureActive" class="panel-custom-jobs-distribution__toggle" style="flex: 1;">
          <i v-if="!isFeatureActive" style="color: #F78F00;" class="icon-lock mr-3"></i>
          <label
            for="panel-custom-jobs-distribution"
            class="panel-custom-jobs-distribution__label"
            :class="{
              'panel-custom-jobs-distribution__label--disabled': isDisabled || !isFeatureActive,
              'panel-custom-jobs-distribution__label--active': publishCustomJob,
              'panel-custom-jobs-distribution__label--inactive': !publishCustomJob,
            }">
            {{ $t('custom-jobs.distribute-my-offer') }}
          </label>
          <bc-toggle
            id="panel-custom-jobs-distribution"
            :ref="`panel-custom-jobs-distribution-${id}`"
            type="default"
            v-model="publishCustomJob"
            :disabled="isDisabled || !isFeatureActive || hasJobboardPublished"></bc-toggle>
          <custom-job-publish-warning v-if="!customJob.active || isDisabled" class="ml-2" />
        </div>
      </div>
      <p v-if="!isCareerActive" class="panel-custom-jobs-distribution__warning"> ⚠️ {{ $t('custom-jobs.career-website-warning') }} </p>
    </custom-jobs-form-section>
    <custom-jobs-form-section
      v-if="Object.keys(customJob).length && configurationFeatures.multidiffusion && multidiffusionAvailable"
      class="panel-custom-jobs-distribution__section"
      :title="$t('generics.multicast')"
    >
      <jobboard-publish-list
        :custom-job="customJob"
        :jobboard-modal="jobboardModal"
        @on-autopublish="onAutopublish"
        @on-update="$emit('on-update', $event)"
        @failed-status="multidiffusionAvailable = false"
      />
    </custom-jobs-form-section>
    <custom-jobs-form-section
      v-if="Object.keys(customJob).length && configurationFeatures.multidiffusion && multidiffusionAvailable && showStats"
      class="panel-custom-jobs-distribution__section panel-custom-jobs-distribution__section--last"
      :title="$t('generics.statistics')"
    >
      <jobboard-stats></jobboard-stats>
    </custom-jobs-form-section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getIntegrationByName, XTRAMILE_INTEGRATION_KEY } from '@/api/integrations';
import { Modals } from '@/store/modules/app';

import BcToggle from '@/ui-kit/components/BcToggle/BcToggle';
import CustomJobPublishWarning from '@/components/CustomJobs/CustomJobPublishWarning';
import CustomJobsFormSection from '@/components/CustomJobs/CustomJobsForm/CustomJobsFormSection';
import JobboardPublishList from '@/components/Jobboard/JobboardPublishList';
import { getCareerUri } from '@/helpers/url/career_url';
import JobboardStats from '@/components/Jobboard/JobboardStats';

export default {
  name: 'panel-custom-jobs-distribution',
  components: {
    BcToggle,
    CustomJobPublishWarning,
    CustomJobsFormSection,
    JobboardPublishList,
    JobboardStats,
  },
  props: {
    customJob: {
      type: Object,
      default: () => ({}) ,
    }
  },
  async created() {
    if (!this.organization?.configuration?.multiposting?.showStats) {
      this.showStats = false;
      return
    }
    let showStats = true;
    const { data } = await getIntegrationByName(XTRAMILE_INTEGRATION_KEY);
    if (data.config?.limitedOrganizations && !data.config.limitedOrganizations.includes(this.organization._id)) {
      showStats = false;
    }
    this.showStats = showStats;
  },
  mounted() {
    this.publishCustomJob = this.customJob.adPublished;
    if (this.organization.configuration?.career?.activated) {
      this.publishCustomJobDisabled = false;
    }
  },
  data() {
    return {
      publishCustomJob: false,
      publishCustomJobDisabled: true,
      isCopiedUrl: false,
      showStats: false,
      multidiffusionAvailable: true,
      jobboardModal: '',
    };
  },
  computed: {
    ...mapGetters({
      organization: 'user/organization',
      configurationFeatures: 'user/configurationFeatures',
      configurationJobMultiposting: 'user/configurationJobMultiposting',
    }),
    id() {
      return Date.now() + '--panel-job-distribution';
    },
    isFeatureActive() {
      return this.configurationFeatures.career;
    },
    isSuperUser() {
      return this.$store.getters['user/isSuperUser'];
    },
    profile() {
      return this.$store.state.user.profile;
    },
    careerWebsiteUrl() {
      return getCareerUri(this.organization.name, this.customJob._id);
    },
    hasDescription() {
      return this.customJob.description || this.customJob.profileDescription || this.customJob._company.description;
    },
    isDisabled() {
      return this.publishCustomJobDisabled || !this.customJob.active || !this.hasDescription || !this.customJob.locations?.length;
    },
    isCareerActive() {
      return this.organization?.configuration?.career?.activated || false;
    },
    handleTooltip() {
      return {
        content: this.isCopiedUrl ? this.$t('generics.copied') : this.$t('companies.account-management.copy-link'),
        hideOnTargetClick: false,
        trigger: 'hover manual',
        show: this.isCopiedUrl,
      }
    },
    displayJobboards() {
      return this.multidiffusionAvailable && !this.isLoadingJobboards;
    },
    hasJobboardPublished() {
      if (!this.customJob.xtramilePublications && !this.customJob.jobMultiposting) {
        return false;
      }
      return Object.keys(this.customJob.jobMultiposting || {}).some(jobboard => this.customJob.jobMultiposting[jobboard]?.status === 'published' && !this.hasAutopublish(jobboard))
        || Object.keys(this.customJob.xtramilePublications || {}).some(channelId => this.customJob.xtramilePublications[channelId]?.publicationId);
    },
    autopublishJobboard() {
      return Object.keys(this.customJob.jobMultiposting || {}).find(jobboard => this.hasAutopublish(jobboard) && this.customJob.jobMultiposting[jobboard]?.status !== 'published');
    }
  },
  methods: {
    ...mapActions({
      updateCustomJob: 'customJobs/updateCustomJob',
      setModal: 'app/setModal',
    }),
    hasAutopublish(jobboard) {
      return this.configurationJobMultiposting?.[jobboard]?.params?.autoPublish;
    },
    checkFeatureActive() {
      if (!this.isFeatureActive) {
        this.setModal(Modals['modal-feature-career']);
        return false;
      }
      return true;
    },
    setIsCopiedUrl() {
      this.isCopiedUrl = true;
      setTimeout(() => {
        this.isCopiedUrl = false;
      }, 2300);
    },
    async updateCustomJobPublised() {
      if (!this.customJob._id) {
        return;
      }
      try {
        await this.updateCustomJob({ id: this.customJob._id, adPublished: this.publishCustomJob });
        this.$emit('on-update', { adPublished: this.publishCustomJob });
      } catch (err) {
        this.publishCustomJob = false;
        this.$toast.show({
          type: 'error',
          message: err.response?.data?.message || err.message,
        })
      }
    },
    handleChecked(newValue) {
      const refId = `panel-custom-jobs-distribution-${this.id}`;
      if (!this.$refs[refId]) {
        return;
      }
      this.$refs[refId].$el.children[1].value = newValue;
      this.$refs[refId].$el.children[1].checked = newValue;
    },
    onAutopublish() {
      this.publishCustomJob = true;
    },
  },
  watch: {
    publishCustomJob(value) {
      if (this.autopublishJobboard && value) {
        this.jobboardModal = '';
        this.$nextTick(() => {
          this.handleChecked(false);
          this.publishCustomJob = false;
          this.jobboardModal = this.autopublishJobboard;
        });
        return;
      }
      if (!this.isDisabled) {
        this.updateCustomJobPublised();
      }
    },
    'customJob.adPublished'(value) {
      this.publishCustomJob = value;
    },
    hasJobboardPublished(value) {
      if (value && !this.publishCustomJob) {
        this.publishCustomJob = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-custom-jobs-distribution {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px 30px;
  flex-direction: column;
  padding-bottom: 10%;

  &__section {
    padding: 0;
    margin-top: 40px;

    &--last {
      padding-bottom: 200px;
    }
  }

  &__content {
    gap: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid $color-blue-medium;
    background: $color-neutre-4;
    justify-content: space-between;
    align-items: center;
  }

  &__warning {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-top: 15px;
    color: $color-blue-dark-cello;
  }

  &__toggle {
    flex: 1;
    justify-content: flex-end;

    &:hover {
      cursor: pointer;
    }
  }

  &__label {
    font-size: 14px;
    margin-right: 10px;
    &--disabled {
      color: $color-blue-cadet;
      opacity: 0.5;
    }

    &--active {
      color: $color-green-shamrock;
    }

    &--inactive {
      color: $color-red-mandy;
    }

    &:hover {
      cursor: pointer;
    }
  }

  & p {
    line-height: 19px;
    color: $color-tertiary;
  }

  &__link {
    border: 1px solid $color-blue-medium;
    border-radius: 5px;
    & > div {
      overflow: auto;
      padding: 10px;
      padding-right: 30px;
      max-width: 600px;

      & > a {
        color: $color-primary;
        text-decoration-line: underline;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
      }
    }
    & > button {
      border-left: 1px solid $color-blue-medium;
      padding: 10px;
    }
  }
}
</style>
