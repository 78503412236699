const FULL_ADDRESS_FILTERS = ['locality', 'administrative_area_level_1', 'country'];

function completeAddress(addressComponents) {
  const streetNumber = addressComponents.find((component) => component.types.includes('street_number'))?.longName || '';
  const route = addressComponents.find((component) => component.types.includes('route'))?.longName || '';
  const locality = addressComponents.find((component) => component.types.includes('locality'))?.longName;
  return `${streetNumber} ${route}, ${locality}`.trim();
}

function getCompleteAddress(addressComponents) {
  const hasStreetDetails = addressComponents?.some((component) => component.types?.includes('street_address') || component.types?.includes('route'));
  return hasStreetDetails && addressComponents ? completeAddress(addressComponents) : '';
}

function getFullAddress(addressComponents = []) {
  return addressComponents
    .filter(address => address.types.some(type => FULL_ADDRESS_FILTERS.includes(type)))
    .map(address => address.longName).join(', ');
}

export function getCompleteAddressOrTag(placeDetailsLocation, filter) {
  const {addressComponents} = placeDetailsLocation || {};
  if (!addressComponents) return '';

  if (filter) {
    const hasFilter = addressComponents[0]?.types?.includes(filter);
    if (hasFilter) {
      return addressComponents[0]?.longName || '';
    } else {
      const findedFilter = addressComponents.find(address => address?.types?.includes(filter)) || addressComponents[0];
      return findedFilter?.longName || '';
    }
  }

  const hasStreetDetails = addressComponents?.some((component) => component.types?.includes('street_address') || component.types?.includes('route'));
  return hasStreetDetails && addressComponents ? completeAddress(addressComponents) : addressComponents[0]?.longName || '';
}

export function formatPlaceDetailsToBaseLocation(location, filter) {
  return {
    place_id: location.place_id || location.placeId,
    text: getCompleteAddressOrTag(location, filter),
    completeAddress: getCompleteAddress(location.addressComponents),
    fullAddress: getFullAddress(location.addressComponents),
  };
}

export function formatAutocompleteLocationToBaseLocation(location) {
  return {
    place_id: location.place_id || location.placeId,
    text: location.description,
  };
}
