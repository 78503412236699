import { http } from '@/api/index';

export const createCoderDocument = ({ id, data }) => {
  return http.post(`/coders/${id}/documents`, data);
};

export const removeDocument = id => {
  return http.delete(`/documents/${id}`);
};

export default {
  createCoderDocument,
  removeDocument,
};
