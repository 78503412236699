import Vue from 'vue';

export default {
  state: {
    profile: {},
    editedProfile: {},
    viewList: [],
    windowWidth: window.innerWidth,
    activeCategory: false
  },
  actions: {
    setViewListItem({ commit }, { index, content }) {
      commit('setViewListItem', { index, content });
    },
    updatePanelProfile({ commit }, data) {
      commit('updatePanelProfile', data);
    },
    setEditedProfile({ commit }, data) {
      commit('setEditedProfile', data);
    },
    setActiveCategory({ commit }, data) {
      commit('setActiveCategory', data);
    },
    setPanelProfile({ commit }, data) {
      commit('setPanelProfile', data);
    },
    setWindowWidth({ commit }, data) {
      commit('setWindowWidth', data);
    },
    emptyViewList({ commit }) {
      commit('emptyViewList')
    }
  },
  mutations: {
    setPanelProfile(state, data) {
      state.profile = data;
    },
    updatePanelProfile(state, data) {
      state.profile = {
        ...state.profile,
        ...data
      }
    },
    setEditedProfile(state, data) {
      state.editedProfile = data;
    },
    setWindowWidth(state, data) {
      state.windowWidth = data;
    },
    setActiveCategory(state, data) {
      state.activeCategory = data;
    },
    setViewList(state, data) {
      state.viewList = [...state.viewList, ...data];
    },
    setViewListItem(state, params) {
      Vue.set(state.viewList, params.index, { ...state.viewList[params.index], ...params.content });
    },
    emptyViewList(state) {
      state.viewList = [];
    },
    unshiftViewList(state, data) {
      state.viewList.unshift(data);
    },
    deleteViewListItem(state, id) {
      state.viewList = state.viewList.filter(item => item._id !== id);
    }
  }
};
