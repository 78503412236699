<template>
  <label class="panel-edit-domains-label__label">
    <span class="icon-tag panel-edit-domains-label__icon">
    </span>
    Métier
    <span
      class="panel-edit-domains-label__required"
      v-if="requiredDomains">
      *
    </span>
  </label>
</template>

<script>
  export default {
    name: 'panel-edit-domains-label',
    props: {
      requiredDomains: {
        type: Boolean,
        default: false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .panel-edit-domains-label {
    &__label {
      font-weight: $font-weight-light;
      margin-bottom: 10px;
      align-items: center;
    }

    &__icon {
      margin-right: 10px;
      color: $color-blue-dodger,
    }

    &__required {
      margin-left: 5px;
    }
  }
</style>
