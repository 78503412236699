<template>
  <div class="contract-id-field">
    <bc-input
      class="is-full-width"
      :name="label"
      :label="label"
      :placeholder="placeholder"
      :labelPlaceholder="false"
      :value="value"
      @input="handleInput"
      :valid="value.length > 0"
      v-bind="$attrs"
    ></bc-input>
    <div>
      <WarningCercle :content="warning" />
    </div>
  </div>
</template>

<script>
import BcInput from '@/ui-kit/components/BcInput/BcInput';
import WarningCercle from '@/components/WarningCercle/WarningCercle';

export default {
  name: 'contract-id-field',
  components: {
    BcInput,
    WarningCercle,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    warning: {
      type: String,
    }
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.contract-id-field {
  width: 100%;
  align-items: center;
  gap: 10px;

  &:deep() {
    .bc-input {
      margin-top: 0;
    }
  }
}
</style>
