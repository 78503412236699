export let timelinePartners = {
  101: {
    description: 'A contact created a note for a company',
    icon: 'note',
    author: {
      type: 'contact',
      after: 'timeline.created-a-note-for',
    },
    directObject: {
      type: 'company',
    },
  },
  102: {
    description: 'A contact created a note for himself',
    icon: 'note',
    author: {
      type: 'contact',
      after:  'timeline.created-a-note',
    },
  },
  103: {
    description: 'A contact edited his company',
    icon: 'edit',
    author: {
      type: 'contact',
      after: 'timeline.edited',
    },
    directObject: {
      type: 'company',
    },
  },
  104: {
    description: 'A contact invited another person',
    icon: 'mail',
    author: {
      type: 'contact',
      after: 'timeline.invited',
    },
    directObject: {
      type: 'company',
    },
  },
  105: {
    description: 'A contact signed partnership',
    icon: 'star',
    author: {
      type: 'contact',
      after: 'timeline.has-signed-the-terms-of-use',
    },
  },
  106: {
    description: 'A contact saw a coder',
    icon: 'eye',
    author: {
      type: 'contact',
      after: 'timeline.saw',
    },
    directObject: {
      type: 'coder',
    },

  },
  107: {
    description: 'A contact favorited a coder',
    icon: 'star',
    author: {
      type: 'contact',
      after: 'timeline.has-favorized',
    },
    directObject: {
      type: 'coder',
    },
  },
  108: {
    description: 'A contact created a job for a company',
    icon: 'plus',
    author: {
      type: 'contact',
      after: 'timeline.created',
    },
    directObject: {
      type: 'job',
      after: 'generics.for',
    },
    for: {
      type: 'company',
    },
  },
  109: {
    description: 'A contact edited a job',
    icon: 'edit',
    author: {
      type: 'contact',
      after: 'timeline.edited',
    },
    directObject: {
      type: 'job',
    },
  },
  110: {
    description: 'A contact deleted a job',
    icon: 'trash',
    author: {
      type: 'contact',
      after: 'timeline.deleted',
    },
    directObject: {
      type: 'job',
      after: 'pour',
    },
    for: {
      type: 'company',
    },
  },
  111: {
    description: 'A contact switched a job status',
    icon: 'edit',
    author: {
      type: 'contact',
       after: 'timeline.has-modified',
    },
    directObject: {
      type: 'job',
      after: 'statut',
    },
  },
  112: {
    description: 'A contact switched a job status to Premium',
    icon: 'star',
    author: {
      type: 'contact',
       after: 'timeline.has-modified',
    },
    directObject: {
      type: 'job',
      after: 'timeline.status-for-premium',
    },
  },
  113: {
    description: 'A contact created a poke to a coder with a job',
    icon: 'arrow-right',
    author: {
      type: 'contact',
      after: 'timeline.sent-a',
    },
    directObject: {
      type: 'poke',
      name: 'poke',
      after: 'timeline.to',
    },
    to: {
      type: 'coder',
      after: 'generics.with',
    },
    with: {
      type: 'job',
      name: 'nom',
    },
  },
  114: {
    description: 'A contact created a note for a coder',
    icon: 'note',
    author: {
      type: 'contact',
      after: 'timeline.created-a-note-for',
    },
    directObject: {
      type: 'coder',
    },
  },
  115: {
    description: 'A contact created an offer for a coder',
    icon: 'dollars',
    author: {
      type: 'contact',
      after: 'timeline.created-a-note-for',
    },
    directObject: {
      type: 'coder',
    },
  },
  116: {
    description: 'A contact created a company',
    icon: 'plus',
    author: {
      type: 'contact',
      after: 'timeline.created',
    },
    directObject: {
      type: 'company',
    },
  },
  117: {
    description: 'A contact poked back a coder',
    icon: 'arrow-left',
    author: {
      type: 'contact',
      after: 'timeline.a-poke-back',
    },
    directObject: {
      type: 'coder',
    },
  },
  118: {
    description: 'A contact added another contact',
    icon: 'user-plus',
    author: {
      type: 'data',
      after: 'timeline.added',
    },
    directObject: {
      type: 'contact',
    },
  },
  119: {
    description: 'A contact gave a feedback',
    icon: 'note',
    author: {
      type: 'contact',
      after: 'timeline.gave-feedback-two',
    },
  },
  120: {
    description: 'A contact created a note for a poke between',
    icon: 'note',
    author: {
      type: 'contact',
    after: 'timeline.created-a-note-for-a-poke-between',
    },
    directObject: {
      type: 'job',
      after: '&',
    },
    from: {
      type: 'coder',
    },
  },
  121: {
    description: 'A contact created a note for a job',
    icon: 'note',
    author: {
      type: 'contact',
      after: 'timeline.created-a-note-for-the-job',
    },
    directObject: {
      type: 'job',
    },
  },
  122: {
    description: 'A contact has create an account for an existing company',
    icon: 'note',
    author: {
      type: 'contact',
      after: 'timeline.joined-the-platform-and-was-added-to',
    },
    directObject: {
      type: 'company',
    },
  },
  123: {
    description: 'A contact has aborted a poke',
    icon: 'note',
    author: {
      type: 'contact',
      after: 'timeline.refused-a',
    },
    directObject: {
      type: 'poke',
      after: 'generics.of',
    },
    from: {
      type: 'coder',
    },
  },
  124: {
    description: 'A contact has set his password',
    icon: 'note',
    author: {
      type: 'contact',
            after: 'timeline.has-set-their-password-for-the-platform',
    },
  },
  125: {
    description: 'A company merged his account with an already existing company',
    icon: 'note',
    author: {
      type: 'company',
      after: 'timeline.registered-when-his-profile-already-existed',
    },
  },
  126: {
    description: 'A contact merged his account with an already existing contact',
    icon: 'note',
    author: {
      type: 'contact',
      after: 'timeline.registered-when-his-profile-already-existed'
    },
  },
  127: {
    description: 'A contact has seen a linkedin coder page',
    icon: 'eye',
    author: {
      type: 'contact',
      after: 'timeline.visited-the-linkedin-of'
    },
    beforeObject: {
      type: 'coder',
      after: 'generics.of'
    },
    from: {
      type: 'coder',
    },
  },
  128: {
    description: 'A contact received an email invitation after subscribing',
    icon: 'eye',
    author: {
      type: 'contact',
      after: 'timeline.received-an-invitation-by-email-after-registering-via-the-tunnel'
    },
  },
  129: {
    description: 'A contact has been deleted',
    icon: 'trash',
    author: {
      type: 'contact',
      after:  'timeline.deleted-a-contact'
    },
  },
  130: {
    description: 'A contact has edited company informations',
    icon: 'edit',
    author: {
      type: 'contact',
      after:  'timeline.edited-information-from'
    },
    directObject: {
      type: 'company',
    },
  },
  131: {
    description: 'A contact has archived a chat',
    icon: 'trash',
    author: {
      type: 'contact',
      after: 'timeline.archived-a'
    },
    directObject: {
      type: 'poke',
    },
  },
  132: {
    description: 'A contact has unarchived a chat',
    icon: 'trash',
    author: {
      type: 'contact',
      after: 'timeline.unarchived-a'
    },
    directObject: {
      type: 'poke',
    },
  },
  133: {
    description: 'A contact has deleted a chat',
    icon: 'trash',
    author: {
      type: 'contact',
      after: 'timeline.deleted-this'
    },
    directObject: {
      type: 'poke',
    },
  },
  134: {
    description: 'A contact visited bluecoders',
    icon: 'eye',
    author: {
      type: 'contact',
      after:  'timeline.has-visited-bluecoders-since'
    },
  },
  135: {
    description: 'A contact has set a time slot with calendly',
    icon: 'clock',
  },
  136: {
    description: 'A contact updated an existing company',
    icon: 'edit',
    author: {
      type: 'contact',
      after: 'timeline.updated-the-already-existing-company'
    },
    directObject: {
      type: 'company',
    },
  },
  137: {
    description: 'A contact completed the subscription typeform',
    icon: 'tiptap-checklist',
    author: {
      type: 'contact',
      after: 'timeline.has-completed-the-registration-typeform'
    },
  },
};
