import { getMessages, postMessage } from '@/api/chat';

export default {
  state: {
    messages: [],
    chat: {
      input: ''
    },
    tabId: '',
    requestQueue: [],
    requesting: false
  },
  actions: {
    // TODO Refacto sur Coders et Partners les dispatch utilisant POST_MESSAGE et GET_MESSAGES
    async postMessage({ commit }, { id, text, type, tabId, job }) {
      commit('shiftChatRequest');

      return await postMessage(id, {
        text,
        type,
        tabId,
        ...job && { _job: job }
      });

    },
    async getMessages({ commit }, { id, page, messages }) {
      const response = await getMessages({ id, page });

      /**
       * We set the new array thanks to spread operator
       */
      let newMessagesArray = messages;

      newMessagesArray.unshift(...((response.data || {}).messages || []).reverse());

      commit('setMessages', newMessagesArray);

      return response;
    },
    requestChatManager({ state, dispatch, commit }) {
      let i = 0;
      while (state.requestQueue.length > i) {
        commit('setRequesting', true);

        try {
          dispatch('postMessage', state.requestQueue[i]);
          i++;
        } catch (e) {
          return Promise.reject(e);
        }
      }
      commit('setRequesting', false);
    },
    setTabId({ commit }) {
      commit('setTabId');
    },
    setMessages({ commit }, data) {
      commit('setMessages', data);
    },
    spliceMessages({ commit }, data) {
      commit('spliceMessages', data);
    }
  },
  mutations: {
    addRequest(state, req) {
      state.requestQueue.push(req);
    },
    shiftChatRequest(state) {
      state.requestQueue.shift();
    },
    setRequesting(state, data) {
      state.requesting = data;
    },
    setMessages(state, data) {
      state.messages = data;
    },
    setTabId(state) {
      state.tabId = (new Date().getTime() + Math.floor((Math.random() * 10000) + 1)).toString(16);
    },
    setStateMessage(state, params) {
      state.messages[params.index].state = params.data;
    },
    spliceMessages(state, index) {
      state.messages.splice(index, 1);
    },
    setErrorStateMessage(state, index) {
      state.messages[index].state = 'error';
    }
  }
};
