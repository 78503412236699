<template>
  <div class="panel-favorite-list-footer">
    <bc-button
      class="panel-favorite-list-footer__button panel-favorite-list-footer__cancel"
      type="outlined"
      @click.native="goPrevious">
      {{ $t('generics.cancel') }}
    </bc-button>
    <bc-button
      :disabled="isDisabled"
      class="panel-favorite-list-footer__button panel-favorite-list-footer__validate"
      native-type="submit"
      @click.native="checkValidator">
      {{ $t('generics.validate') }}
    </bc-button>
  </div>
</template>

<script>
  // mixins
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  // vuex
  import { mapActions, mapGetters, mapState } from 'vuex';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';

  export default {
    name: 'panel-favorite-list-footer',
    components: { BcButton },
    mixins: [PanelNavigation],
    props: {
      list: {
        type: Object,
        default: () => ({}),
      },
      editedList: {
        type: Object,
        default: () => ({}),
      },
      isValidForm: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters(['cardActive']),
      ...mapState({
        selectedCards: state => state.card.selectedCards,
      }),
      addedSubscribers() {
        return (this.editedList.subscribers || []).filter(addedSubscriber => !(this.list.subscribers || []).some(subscriber => subscriber._id === addedSubscriber._id));
      },
      removedSubscribers() {
        return (this.list.subscribers || []).filter(removedSubscriber => !(this.editedList.subscribers || []).some(subscriber => subscriber._id === removedSubscriber._id));
      },
      changedSubscribers() {
        return (this.editedList.subscribers || []).filter(changedSubscriber => (this.list.subscribers || []).some(subscriber => subscriber._id === changedSubscriber._id && subscriber.role !== changedSubscriber.role));
      },
      isDisabled() {
        return !this.isValidForm && this.addedSubscribers.length === 0 && this.changedSubscribers.length === 0 && this.removedSubscribers.length === 0;
      },
    },
    methods: {
      ...mapActions([
        'editFavoriteList',
        'createFavoriteList',
      ]),
      async createList() {
        try {
          const mapAddResourceDispatcher = ['addCoderToFavoriteList', 'addJobToFavoriteList', 'addCompanyToFavoriteList', 'addCoderToFavoriteList'];
          const mapfavoriteListTypeQuery = ['coder', 'job', 'company', 'contact'];

          const response = await this.createFavoriteList({
            name: this.editedList.name,
            type: this.editedList.type,
            coachId: this.editedList.coachId,
            subscribers: this.editedList.subscribers,
          });

          if (this.selectedCards.length > 0) {
            this.addMultipleResourcesToList(response.data._id, this.editedList.type);
          } else if (this.$route.params.id) {
            await this.$store.dispatch(mapAddResourceDispatcher[response.data.type - 1], {
              listId: response.data._id,
              resourceId: this.$route.params.id,
              cardIndex: this.cardActive,
            });
          }

          if (this.$route.name.includes('FavoriteLists') &&
            mapfavoriteListTypeQuery[response.data.type - 1] === this.$route.query['list-type']) {
            this.$store.dispatch('unshiftCard', response.data);
          }
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      async addMultipleResourcesToList(listId, listType) {
        const mapAddResourceDispatcher = ['addCoderToFavoriteList', 'addJobToFavoriteList', 'addCompanyToFavoriteList', 'addCoderToFavoriteList'];
        const selectedCardsToAdd = this.selectedCards.filter(card => !card._lists?.some(list => list._id === listId));

        for (const card of selectedCardsToAdd) {
          try {
            const { data } = await this.$store.dispatch(mapAddResourceDispatcher[listType - 1], {
              listId: listId,
              resourceId: card._id,
            });

            this.updateBackgroundCards(card, data);
          } catch (error) {
            this.$toast.show({
              type: 'error',
              message: error?.response?.data?.message || error,
            });
          }
        }
      },
      updateBackgroundCards(card, data) {
        this.$store.dispatch('updateCardFromId', {
          id: card._id,
          content: data,
        });

        this.$store.dispatch('updateSelectedCardFromId', {
          id: card._id,
          content: data,
        });
      },
      async editList() {
        const currentId = this.$route.query['list-id'] ? this.$route.query['list-id'] : this.$route.params.id;

        const response = await this.editFavoriteList({
          listId: currentId,
          ...this.editedList.name !== this.list.name && { name: this.editedList.name },
          ...(this.addedSubscribers || []).length > 0 && {
            addedSubscribers: this.addedSubscribers.map(subscriber => ({
              _coach: subscriber._id,
              role: subscriber.role,
              typeList: this.editedList.type,
            })),
          },
          ...(this.changedSubscribers || []).length > 0 && {
            changedSubscribers: this.changedSubscribers.map(subscriber => ({
              _coach: subscriber._id,
              role: subscriber.role,
            })),
          },
          ...(this.removedSubscribers || []).length > 0 && {
            removedSubscribers: this.removedSubscribers.map(subscriber => ({
              _coach: subscriber._id,
              role: subscriber.role,
            })),
          },
        });

        if (this.cardActive >= 0) {
          this.$store.dispatch('updateCard', {
            index: this.cardActive,
            content: {
              name: response.data.name,
              subscribers: response.data.subscribers,
            },
          });
        }
      },
      async checkValidator() {
        if (this.isDisabled) {
          this.$scrollTo('.invalid', 100, {
            container: (this.$route.query || {}).subtype.includes('edit') ? '.panel-favorite-list-update__body' : '.panel-favorite-list-create__body',
            offset: -100,
          });
        } else {
          this.redirectToAction();
        }
      },
      async redirectToAction() {
        try {
          if (this.$route.query.subtype === 'create-favorite-list') {
            await this.createList();

            this.$toast.show({
              type: 'info',
              message: this.$i18n.t('toast.list-created-successfully')
            });
          } else {
            await this.editList();

            this.$toast.show({
              type: 'info',
              message: this.$i18n.t('toast.list-edited-successfully')
            });
          }

          this.goPrevious();
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      setIsEdited() {
        this.$router.replace({
          query: {
            ...this.$route.query,
            'unsaved-changes': true,
          },
        });
      },
    },
    watch: {
      addedSubscribers() {
        if (!this.$route.query['unsaved-changes']) {
          this.setIsEdited();
        }
      },
      removedSubscribers() {
        if (!this.$route.query['unsaved-changes']) {
          this.setIsEdited();
        }
      },
      changedSubscribers() {
        if (!this.$route.query['unsaved-changes']) {
          this.setIsEdited();
        }
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-favorite-list-footer {
    flex-shrink: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid $color-blue-light;
    justify-content: center;
    align-items: center;
    padding: 20px;

    &__button {
      margin: 0 10px;
    }
  }

</style>
