import company from '@/api/company';

export const getProspectionPipe = async options => {
  const execute = async options => {
    try {
      return company.getPartnersProspectingPipe(options);
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    return execute(options);
  } catch (error) {
    throw error;
  }
};
