<template>
  <label
    :class="[
      `bc-toggle__${type}`,
      {
        [`bc-toggle__${type}--disabled`]: disabled,
        [`bc-toggle__${type}--checked`]: value,
        [`bc-toggle__${type}--checked-and-disabled`]: disabled && value,
        'is-row-reverse': reversed,
      },
    ]"
    :for="id"
    class="bc-toggle font-size-m"
  >
    <span
      :class="{
        'bc-toggle__content': $slots.default,
        'bc-toggle__content--reversed': reversed,
      }"
    >
      <!-- @slot The Label for the toggle -->
      <slot></slot>
    </span>
    <input
      :checked="value"
      :disabled="disabled"
      :id="id"
      @click="clickEmitter(value)"
      class="bc-toggle__slider"
      type="checkbox"
    />
  </label>
</template>

<script>
export default {
  name: 'bc-toggle',
  props: {
    /**
     * The id is required for html semantic
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * The value of the checkbox
     */
    value: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable the label
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Set the label to the left
     */
    reversed: {
      type: Boolean,
      default: false,
    },
    /**
     * The type of the toggle
     * @values default, colored
     */
    type: {
      type: String,
      default: 'default',
      validator: value => {
        return ['default', 'colored'].indexOf(value) >= 0;
      },
    },
  },
  methods: {
    clickEmitter(value) {
      /**
       * click -> Emit the opposite of the value
       *
       * @event input
       * @type {boolean}
       */
      this.$emit('input', !value);
    },
  },
};
</script>

<style lang="scss" scoped>
.bc-toggle {
  $this: &;

  $colors: (
    default-color-off: $color-red-mandy,
    default-color-on: $color-green-shamrock,
    // default-color-hover-off: $color-grey-5,
    // default-color-hover-on: $color-primary-dark,
    colored-color-off: $color-error,
    colored-color-on: $color-success,
    colored-color-hover-off: $color-error-dark,
    colored-color-hover-on: $color-success-dark,
  );

  @function get_color($color) {
    @return map_get($colors, $color);
  }

  @mixin selectColor($type) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: get_color(#{$type}-color-off);
    cursor: pointer;
    opacity: 1;

    #{$this}__slider {
      height: 19px;
      width: 34px;
      border-radius: 50px;
      transition-duration: 0.2s;
      background-color: get_color(#{$type}-color-off);
      cursor: pointer;
      opacity: 1;
      position: relative;

      &:checked {
        background-color: get_color(#{$type}-color-on);
        opacity: 1;
      }

      &:disabled {
        cursor: default;
        background-color: get_color(#{$type}-color-off);
        opacity: 0.5;

        &:checked {
          background-color: get_color(#{$type}-color-on);
          opacity: 0.5;
        }
      }
    }

    #{$this}__slider:before {
      position: absolute;
      content: '';
      height: 15px;
      width: 15px;
      left: 2px;
      bottom: 2px;
      background-color: $color-white;
      border-radius: $border-radius-rounded;
      box-shadow: $shadow-default;
      transition: 0.5s cubic-bezier(0.17, 0.67, 0, 1);
    }

    #{$this}__slider:checked {
      &:before {
        transform: translateX(15px);
      }
    }

    #{$this}__content {
      display: flex;
      align-items: center;
      margin-right: 10px;
      color: inherit;

      &--reversed {
        margin-right: 0;
        margin-left: 10px;
      }
    }

    &:hover {
      color: get_color(#{$type}-color-hover-off);

      #{$this}__slider {
        background-color: get_color(#{$type}-color-hover-off);

        &:checked {
          background-color: get_color(#{$type}-color-hover-on);
          opacity: 1;
        }

        &:disabled {
          cursor: default;
          background-color: get_color(#{$type}-color-off);
          opacity: 0.5;

          &:checked {
            background-color: get_color(#{$type}-color-on);
            opacity: 0.5;
          }
        }
      }
    }

    &--checked {
      color: get_color(#{$type}-color-on);

      &:hover {
        color: get_color(#{$type}-color-hover-on);
      }
    }

    &--disabled {
      cursor: default;
      color: get_color(#{$type}-color-off);
      opacity: 0.5;

      &:hover {
        color: get_color(#{$type}-color-off);
      }
    }

    &--checked-and-disabled {
      color: get_color(#{$type}-color-on);

      &:hover {
        color: get_color(#{$type}-color-on);
        opacity: 0.5;
      }
    }
  }

  &__default {
    @include selectColor('default');
  }

  &__colored {
    @include selectColor('colored');
  }
}
</style>
