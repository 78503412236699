<template>
  <router-link
    v-bind="$attrs"
    class="filter-tab is-flex is-row is-align-items-center">
    <p
      :class="{'filter-tab__text--selected': isSelected }"
      class="filter-tab__text">
      {{ text }}
    </p>
    <bc-tag
      :class="{'filter-tab__tag--selected': isSelected }"
      class="filter-tab__tag"
      v-if="tag >= 0"
      size="small"
      type="badge">
      {{ tag }}
    </bc-tag>
  </router-link>
</template>

<script>
  import BcTag from "@/ui-kit/components/BcTag";

  export default {
    name: 'filter-tab',
    components: { BcTag },
    props: {
      text: {
        type: String,
        default: '',
        required: true
      },
      /**
       * Default value is null because we should render tag number if equal to 0
       */
      tag: {
        type: Number,
        default: undefined
      },
      isSelected: {
        type: Boolean,
        default: false,
        required: true,
      },
    }
  };
</script>

<style scoped lang="scss">
  .filter-tab {
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
    &__text {
      position: relative;
      @include font-regular(16px);
      color: $color-secondary;
      text-align: left;
      line-height: 19px;

      &--selected {
        color: $color-primary;
        &:before {
          position: absolute;
          left: 0;
          bottom: -5px;
          border-bottom: 2px solid $color-primary;
          width: 50px;
          height: 1px;
          content: '';
        }
      }
    }

    &__tag {
      @include font-bold(11px);
      background-color: $color-grey-4;
      color: $color-secondary;
      margin-left: 10px;

      &--selected {
        background-color: $color-primary;
        color: white;
      }
    }
  }
</style>
