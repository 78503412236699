<script setup lang="ts">
import { ref, computed, defineProps, withDefaults } from 'vue';

import { useStore } from '@/store';
import TasksResumeParsingItem from '@/components/Tasks/TasksResumeParsingItem.vue';
import { useParsingResumeHistoryStore } from '@/store/pinia/parsingHistories';

type Props = {
    hasActiveMessages?: boolean;
}

withDefaults(defineProps<Props>(), {
    hasActiveMessages: false,
});

const store = useStore();
const isCollapse = ref(false);
const tasksResumeParsingStore = useParsingResumeHistoryStore();

const profileId = computed(() => store.state.user.profile?._id);
const resumeHistoriesList = computed(() => tasksResumeParsingStore.list || []);
const activeParsedResumeList = computed(() => resumeHistoriesList.value?.filter(resume => resume.active))

const progressCount = computed(() => activeParsedResumeList.value?.filter(resume => resume.status !== 'PENDING').length);
const isTasksDone = computed(() => activeParsedResumeList.value?.every(resume => resume.status !== 'PENDING'));

const toggleCollapse = () => {
    isCollapse.value = !isCollapse.value;
}

const setClosedAt = async () => {
    activeParsedResumeList.value?.forEach(async resume => {
        await tasksResumeParsingStore.setResumeClosedAt({
            coachId: profileId.value,
            groupId: resume.groupId,
        });
    })
}
</script>

<template>
    <div
        class="tasks-resume-parsing fixed bottom-0 right-0 min-w-[351px] rounded-t-[10px]"
        :class="{ 'min-h-[380px]': !isCollapse, 'h-auto': isCollapse, 'mr-[455px]': hasActiveMessages }"
        style="box-shadow: 0px 4px 30px 0px rgba(29, 57, 94, 0.10);"
    >
        <div class="flex flex-col w-full">
            <div class="flex items-center justify-between px-4 py-5 bg-neutral-200 w-full h-[55px] rounded-t-[10px]">
                <div class="color-blue-800">Enrichissements des candidats {{ progressCount }}/{{
                    activeParsedResumeList.length }}</div>
                <div class="flex gap-5">
                    <button @click="toggleCollapse"><i
                            :class="`icon-chevron-${isCollapse ? 'up' : 'down'} text-blue-400`"
                        ></i></button>
                    <button
                        v-if="isTasksDone"
                        @click="setClosedAt"
                    ><i class="icon-cross text-blue-400"></i></button>
                </div>
            </div>
            <div
                class="flex flex-col bg-white overflow-auto w-full h-full min-w-[351px] max-h-[400px]"
                :class="{ 'hidden': isCollapse }"
            >
                <div
                    :key="item.id"
                    v-for="(item, index) in activeParsedResumeList"
                    class="flex flex-col"
                >
                    <TasksResumeParsingItem :item="item"></TasksResumeParsingItem>
                    <div
                        v-if="index < activeParsedResumeList.length - 1"
                        class="w-full h-[1px] bg-blue-100"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.tasks-resume-parsing {
    z-index: 1000000000000 !important;
}
</style>