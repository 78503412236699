<template>
  <div v-click-outside="handleClose" class="mv-searchbar">
    <div
      class="mv-searchbar__container">
      <label
        :class="{ 'mv-searchbar__icon--open': isOpen }"
        class="mv-searchbar__icon icon-search"
        for="input">
      </label>
      <input
        ref="input"
        :class="{
          'mv-searchbar__input--hover': isHover,
          'mv-searchbar__input--open': isOpen && showResults,
        }"
        :placeholder="placeholder"
        :style="inputStyle"
        :value="value"
        class="mv-searchbar__input body-m"
        spellcheck="false"
        type="text"
        @input="handleInput"
      />
      <bc-spinner
        v-if="isInputLoading"
        class="absolute right-4"
        color="secondary">
      </bc-spinner>
      <button
        v-else-if="!isInputLoading && isOpen"
        class="mv-searchbar__close"
        @click="handleClose">
        <i class="icon-cross"></i>
      </button>
    </div>
    <transition v-if="showResults" name="slide-fade">
      <div
        v-show="isOpen"
        :style="{ width: `${position.width}px` }"
        class="mv-searchbar__options"
      >
        <div
          v-show="isLoading"
          class="mv-searchbar__wrapper">
          <bc-spinner></bc-spinner>
        </div>
        <div
          v-show="!isLoading"
          class="mv-searchbar__wrapper">
          <div
            :class="{
              'max-h-[240px] overflow-y-auto': isScrollable,
            }"
            v-if="!$slots.customContent" class="mv-searchbar__list is-column is-full-width">
            <div v-if="title" class="mv-searchbar__title body-m--medium">
              {{ title }}
            </div>
            <div
              v-for="(suggestion, index) in suggestions"
              :key="`${suggestion._id}-${index}`"
              :class="{
                'mv-searchbar__item--active':
                  selectedSuggestion._id === suggestion._id,
              }"
              class="mv-searchbar__item body-m"
              @click="handleSelectItem(suggestion)"
            >
              <slot
                :index="index"
                :suggestion="suggestion"
                name="results">
              </slot>
            </div>
            <p
              v-if="!suggestions.length"
              class="mv-searchbar__item mv-searchbar__item--disabled mv-searchbar__item-text font-size-s py-4">
              {{ $t('generics.no-result-found') }}
            </p>
          </div>
          <div v-else class="is-full-width">
            <slot name="customContent"></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';

  export default {
    name: 'mv-searchbar',
    components: {
      BcSpinner,
    },
    props: {
      value: {
        type: String,
        default: '',
      },
      suggestions: {
        type: Array,
        default: () => [],
      },
      placeholder: {
        type: String,
      },
      title: {
        type: String,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      inputStyle: {
        type: Object,
        default: () => ({}),
      },
      isInputLoading: {
        type: Boolean,
        default: false,
      },
      isScrollable: {
        type: Boolean,
        default: false,
      },
      showResults: {
        type: Boolean,
        default: true,
      },
    },
    mounted() {
      const input = this.$refs['input'];
      if (input) {
        this.position = input.getBoundingClientRect();
      }
    },
    data() {
      return {
        input: '',
        isHover: false,
        isInputFocus: false,
        selectedSuggestion: {},
        position: {},
      };
    },
    computed: {
      isOpen() {
        return this.value.length > 1;
      },
    },
    methods: {
      blurInputFocus() {
        this.isInputFocus = false;
      },
      handleSelectItem(item) {
        this.$emit('select', item);
        this.value = '';
      },
      handleInput(el) {
        this.$emit('input', el.target.value);
      },
      handleClose() {
        if (this.showResults) {
          this.$emit('input', '');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .mv-searchbar {
    position: relative;

    &__title {
      padding: 10px 20px;
    }

    &__container {
      width: 100%;
      align-items: center;
    }

    &__options {
      position: absolute;
      z-index: 999;
      top: 100%;
      border: 1px solid $color-blue-light;
      background: #FFF;
      box-shadow: 0px 2px 4px 0px rgba(29, 57, 94, 0.10);
      border-bottom-left-radius: 17px;
      border-bottom-right-radius: 17px;
      min-height: 50px;
      border-top: none;
    }

    &__input {
      position: relative;
      width: 375px;
      height: 40px;
      border-radius: 50px;
      border: 1px solid $color-blue-medium;
      background: $color-neutre-4;
      display: flex;
      align-items: center;
      padding-left: 40px;
      padding-right: 20px;
      color: $color-tertiary;
      transition: 0.4s all;

      &--open {
        border-color: $color-blue-light;
        border-top-left-radius: 17px;
        border-top-right-radius: 17px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        transition: 0.4s all;
        background: $color-white;
        box-shadow: 0px 0px 8px 1px rgba(29, 57, 94, 0.10);

        &:hover {
          border: 1px solid $color-blue-medium;
        }
      }

      &:hover {
        border: 1px solid #3865FD;
      }

      &:focus {
        border: 1px solid $color-blue-medium;
      }

      &::placeholder {
        color: $color-blue-heavy-dark;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &__icon {
      font-size: 16px;
      justify-content: center;
      position: absolute;
      left: 0;
      z-index: 1;
      align-items: center;
      margin-left: 18px;
      color: $color-blue-heavy-dark;

      &--open::before {
        color: $color-tertiary;
      }
    }

    &__wrapper {
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    &__item {
      width: 100%;
      height: 100%;
      text-align: center;

      &:hover {
        cursor: pointer;
        background: $color-blue-light;
      }
    }

    &__close {
      position: absolute;
      right: 20px;
    }

  }

  .icon-cross {
    color: $color-blue-heavy-dark;
    font-size: 12px;
  }

  .slide-fade-enter-active {
    transition: all .2s ease-in;
  }

  .slide-fade-leave-active {
    transition: all .2s ease-out;
  }

  .slide-fade-leave-to {
    transform: translateY(-10px);
    opacity: 0;
  }

  .slide-fade-enter {
    transform: translateY(-10px);
    opacity: 1;
  }
</style>
