<template>
  <div class="technos is-full-width">
    <div class="technos__container">
      <title-section
        :background-color="'white'"
        :color="'blue'"
        :title="`Technos (${technos.length})`"
        class="technos__title">
      </title-section>
      <div class="technos__wrapper">
        <technos-tag
          :is-creating="true"
          class="technos__tag">
        </technos-tag>
        <technos-tag
          :key="techno._id"
          :techno="techno"
          class="technos__tag"
          v-for="techno in technos">
        </technos-tag>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleSection from '@/components/Title/TitleSection';
  import { mapState } from 'vuex';
  import TechnosTag from '@/components/Technos/TechnosTag';

  export default {
    name: 'technos',
    components: {
      TechnosTag,
      TitleSection
    },
    computed: {
      ...mapState({
        technos: state => state.tags.technos
      })
    }
  };
</script>

<style scoped lang="scss">
  .technos {
    margin-top: 65px;
    padding: 0 15px;
    @include page_grid();
    &__container {
      flex-direction: column;
      width: 100%;
    }
    &__title {
      width: 100%;
      margin-bottom: 10px;
      @include bp('tablet') {
        margin-bottom: 15px;
      }
    }
    &__wrapper {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__tag {
      margin-bottom: 10px;
      width: 100%;
      @include bp('tablet') {
        width: calc(50% - 10px);
      }
    }
  }
</style>
