<template>
  <bc-slider
    class="is-full-width"
    type="simple"
    isInputDisplayed
    :value="selectedValue"
    :placeholder="placeholder"
    :min="customField.min"
    :max="customField.max"
    :step="increment"
    ticks
    @input="handleInput"
  />
</template>

<script>
import BcSlider from '@/ui-kit/components/BcSlider/BcSlider';

export default {
  name: 'bc-custom-field-cursor',
  components: {
    BcSlider,
  },
  props: {
    customField: {
      type: Object,
      required: true,
    },
    selected: {
      type: [String, Number],
      default: 0,
    },
    placeholder: {
      type: String,
    },
  },
  mounted() {
    if (this.selected != null) {
      this.selectedValue = this.selected;
    }
  },
  data() {
    return {
      selectedValue: [],
    };
  },
  computed: {
    increment() {
      return this.customField.increment || Math.floor(this.customField.max * 0.05);
    }
  },
  methods: {
    handleInput(value) {
      this.selectedValue = value;
      this.$emit('on-update', { key: this.customField.key, value });
    },
  }
}
</script>
