<template>
  <div class="team col-xs-12">
    <router-view></router-view>
    <configuration-team-tabs />
    <views-container>
      <bc-spinner v-if="!isLoaded" class="justify-center" size="large"></bc-spinner>
      <div v-else class="flex-col items-center">
        <team-grid :displayed-columns="displayedColumns" />
        <card-list ref="cardList" :cards="enabledUsers" :is-loaded="true">
          <template #card="{ card }">

            <Card
              :card-content="card"
            >
              <CardCoaches
                :card-content="card"
                class="card-list__card"
                :displayed-columns="displayedColumns"
              />
            </Card>
          </template>
        </card-list>
        <hr v-if="disabledUsers.length" class="mb-8 mt-4 w-full border border-solid border-blue-100" />
        <card-list v-if="disabledUsers.length" ref="cardList" :cards="disabledUsers" :is-loaded="true">
          <template #card="{ card }">

            <Card
              :card-content="card"
            >
              <CardCoaches
                :card-content="card"
                class="card-list__card"
                :displayed-columns="displayedColumns"
              />
            </Card>
          </template>
        </card-list>
      </div>
    </views-container>
  </div>
</template>

<script setup lang="ts">
import CardList from '@/components/CardList/CardList.vue';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
import ViewsContainer from '@/components/Views/ViewsContainer.vue';
import TeamGrid from '@/components/Team/TeamGrid.vue';
import ConfigurationTeamTabs from '@/components/Configuration/Tabs/ConfigurationTeamTabs.vue';
import { useStore } from '@/store';
import { watch, ref, computed } from 'vue';
import { apiRoles } from '@/macros/coaches/roles';
import CardCoaches from '@/components/Card/CardCoaches.vue';
import Card from '@/components/Card/Card.vue';

const isLoaded = ref(false);

const store = useStore();
const coaches = computed(() => store.state.coaches.coaches);
const profile = computed(() => store.state.user.profile);
const cards = computed(() => store.state.card.cards);

const enabledUsers = computed(() => cards.value.filter(account => account.enabled));
const disabledUsers = computed(() => cards.value.filter(account => !account.enabled));

const showHiddenFeatures = computed(() =>
  [apiRoles.product, apiRoles.businessManager].includes(profile.value.role)
);

const displayedColumns = computed(() => ({
  avatar: true,
  name: true,
  email: true,
  logAs: false,
  toggle: showHiddenFeatures.value,
}));

function resetCards() {
  store.dispatch('resetCards');
}
function setCards(cards: any[]) {
  store.dispatch('setCards', cards);
}

watch(
  coaches,
  () => {
    resetCards();
    setCards(coaches.value);

    isLoaded.value = true;
  },
  { immediate: true }
);

watch(cards, async() => {
  await store.dispatch('user/getProfile');
});
</script>
