<script setup>
import { usePanelNavigation } from '@/composables/usePanelNavigation';
import { useRoute } from 'vue-router/composables';
import { watch } from 'vue';
import PanelCoderHeader from '@/components/Panel/PanelCoder/PanelCoderHeader.vue';
import { useQuery } from '@tanstack/vue-query';
import { coderController } from '@/managers/coders/controller';
import PanelCoderNotes from '@/components/Panel/PanelCoder/PanelCoderNotes.vue';
import PanelCoderProfile from '@/components/Panel/PanelCoder/PanelCoderProfile.vue';
import { useLinkedinRelationsApi } from '@/composables/api/useLinkedinRelationsApi';
import { useLinkedinMessagesApi } from '@/composables/api/useLinkedinMessagesApi';
import { useLinkedinProfileStore } from '@/store/pinia/linkedinProfile';
import { getLinkedinSlug } from '@/formatters/linkedin';
import { useUnipileAccountsStore } from '@/store/pinia/unipileAccounts';
import { useStore } from '@/store';
const route = useRoute();
const { fetchRelationStatus } = useLinkedinRelationsApi();
const { checkHasReceivedMessages } = useLinkedinMessagesApi();
const { setHasReceivedMessages } = useLinkedinProfileStore();
const { closePanel } = usePanelNavigation();
const unipileAccountsStore = useUnipileAccountsStore();
const store = useStore();

const manageClosePanel = (event) => {
  if (event.target?.className === 'icon-close' || event.target?.className === 'overlay') {
    closePanel();
  }
};

const { data: profile, refetch: refetchProfile } = useQuery({
  queryKey: ['GET_CODER_PROFILE', route.params.id],
  queryFn: async() => {
    try {
      const coderId = route.query.coderId || route.params.id;
      const context = route.query.context || 'direct-link';

      const { data } = await coderController.getProfile(coderId, context);

      return data;
    } catch (e) {
      console.error(e);
    }
  },
});
watch(
  () => route.params.id,
  async() => {
    refetch();
  },
);

const refetch = async () => {
  try {
    await refetchProfile();

    await store.dispatch('updateCardFromId', {
      id: profile.value?._id,
      content: profile.value,
    });
  } catch (e) {
    console.error(e);
  }
}

watch(() => [profile.value?._id, profile.value?.social?.linkedin, unipileAccountsStore.linkedInAccount], async ([candidateId, candidateLinkedinUrl, linkedInAccount]) => {
  if (candidateId && candidateLinkedinUrl && linkedInAccount) {
    await fetchRelationStatus(candidateId);
    const hasReceivedMessages = await checkHasReceivedMessages(candidateId);
    setHasReceivedMessages(getLinkedinSlug(candidateLinkedinUrl), hasReceivedMessages);
  }
}, { immediate: true });
</script>

<template>
  <div
    v-click-outside="manageClosePanel"
    class="flex max-h-full shrink-0 grow flex-col items-center justify-center"
  >
    <PanelCoderHeader
      :profile="profile"
      @profile-updated="refetch"
      class="shrink-0"
    />
    <div class="flex max-h-[calc(100%-50px)] w-full grow">
      <div class="flex w-1/2 flex-col">
        <PanelCoderProfile
          :profile="profile"
          @update-candidate="refetch"/>
      </div>
      <div class="flex w-1/2 flex-col overflow-y-auto bg-neutral-100">
        <PanelCoderNotes :profile="profile"/>
      </div>
    </div>
  </div>
</template>

