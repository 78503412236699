<template>
  <div class="panel-error-header col-xs-12">
    <bc-button
      class="panel-error-header__button"
      bc-icon="cross"
      bc-icon-size="16px"
      bc-padding="0"
      @click.native="closePanel"
      bc-text-color="gray-silver-chalice"
      bc-text-color-hover="black"
      bc-background-color="transparent">
    </bc-button>
    <bc-button
      class="panel-error-header__button"
      bc-icon="arrow-left"
      bc-icon-size="16px"
      bc-padding="0"
      @click.native="goPrevious"
      bc-text-color="gray-silver-chalice"
      bc-text-color-hover="black"
      bc-background-color="transparent">
    </bc-button>
  </div>
</template>

<script>
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import BcButton from '@/legacy/ui-kit/components/Button/BcButton';

  export default {
    components: { BcButton },
    name: 'panel-error-header',
    mixins: [PanelNavigation]
  };
</script>

<style scoped lang="scss">
  .panel-error-header {
    flex-direction: row;
    background: $color-white;
    min-height: 50px;
    padding: 17px 15px;
    &__button {
      width: auto;
      margin-right: 10px;
    }
  }
</style>
