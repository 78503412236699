<template>
  <div class="card-jobs-informations">
    <p class="card-jobs-informations__date">
      {{ $t('generics.creat') }} {{ date }}
    </p>
    <div class="card-jobs-informations__dropdown-container">
      <button-dropdown
        :dropdown-active="dropdownActiveIndex"
        :dropdown-items="Object.values(dropdownItems)"
        :dropdown-text="dropdownTextContent"
        @select="updateJobStatus($event)"
        class="card-jobs-informations__dropdown">
      </button-dropdown>
    </div>
  </div>
</template>

<script>
import ButtonDropdown from '@/components/UIKit/Button/ButtonDropdown';
import moment from 'moment/min/moment-with-locales';
import { mapActions, mapState } from 'vuex';
// mixins
import panelNavigation from '@/common-old/mixins/Panel/panelNavigation.js';
import { updateJobStatus } from '@/api/jobs';
import { status } from '@/common-old/macros/jobs';

export default {
  components: {
    ButtonDropdown,
  },
  name: 'card-jobs-informations',
  props: {
    cardContent: {
      type: Object,
    },
    cardIndex: {
      type: Number,
    },
    cardProps: {
      type: [Object, Boolean],
    },
  },
  mixins: [panelNavigation],
  data() {
    return {
      actionParameter: '',
      dropdownItems: {
        [status.online]: {
          name: this.$i18n.t('generics.publish'),
          apiStatus: 'online',
          apiValue: status.online,
          activeBackgroundColor: '#5892F6',
          backgroundColor: '#fff',
          textColor: '#333333',
          action: () => {},
        },
        [status.offline]: {
          name: this.$i18n.t('generics.non-publish'),
          apiStatus: 'offline',
          apiValue: status.offline,
          activeBackgroundColor: '#e6525a',
          backgroundColor: '#fff',
          textColor: '#e6525a',
          action: () => {},
        },
      },
    };
  },
  computed: {
    ...mapState({
      cards: state => state.card.cards,
    }),
    date() {
      // let locale = localStorage.locale;
      // moment.locale(locale);
      return moment(this.cardContent.createdAt).fromNow();
    },
    dropdownActiveStatus() {
      return this.dropdownItems[this.cardContent.status];
    },
    dropdownTextContent() {
      return this.dropdownActiveStatus.name;
    },
    dropdownActiveIndex() {
      if (this.dropdownActiveStatus.apiValue === 2) {
        return 0;
      }

      return 1;
    },
  },
  methods: {
    ...mapActions(['setViewListItem', 'updateCard']),
    async updateJobStatus(status) {
      try {
        await updateJobStatus({
          id: this.cardContent._id,
          status: status.item.apiStatus,
        });
        this.setActiveStatus(status);
      } catch (error) {
        this.$toast.show({
          type: 'error',
          message: error.message,
          duration: 4000,
        });
      }
    },
    setActiveStatus(item) {
      if (this.$route.params.id) {
        this.setViewListItem({
          index: this.cardIndex,
          content: { status: item.item.apiValue },
        });
        this.updateCard({
          index: this.cards.findIndex(
            card => card._id === this.cardContent._id
          ),
          content: { status: item.item.apiValue },
        });
      } else {
        this.updateCard({
          index: this.cardIndex,
          content: { status: item.item.apiValue },
        });
      }
    },
  },

  watch: {
    actionParameter(val) {
      this.action(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-jobs-informations {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  flex-shrink: 0;
  background-color: $color-blue-hawkes;
  border-top: 1px solid white;
  padding: 15px;
  border-radius: 0 0 4px 4px;

  @include bp('tablet') {
    flex-direction: row;
    align-items: center;
    padding: 5px 20px;
    height: 50px;
  }

  &__date {
    margin-bottom: 15px;
    font-size: $font-size-xxs;
    text-align: center;
    line-height: 14px;
    color: $color-blue-dark-cello;

    @include bp('tablet') {
      margin-bottom: 0;
      margin-right: auto;
    }
  }

  &__dropdown-container {
    width: 100%;
    height: 40px;

    @include bp('tablet') {
      margin-left: 20px;
      width: 150px;
    }
  }

  &__dropdown {
    width: 100%;
    z-index: 4;
  }
}

.tooltip {
  &-wrapper {
    flex-direction: column;
    align-items: flex-start;
    font-size: $font-size-xxs;
    line-height: 14px;
  }

  &-text {
    text-align: center;
  }
}
</style>
