<script setup lang="ts">
import { ref, computed } from 'vue';

import { useStore } from '@/store';
import MessagingPendingItem from './MessagingPendingItem.vue'

const store = useStore();
const isCollapse = ref(false);

const profileId =  computed(() => store.state.user.profile?._id);
const messages = computed(() => store.getters['messageHistories/messageList']);
const messagesList = computed(() => {
  if (messages.value?.messages) {
    return Object.keys(messages.value.messages).map(messageId => messages.value?.messages[messageId])
  }
  return [];
});

const progressCount = computed(() => store.getters['messageHistories/progressCount']);
const isUploadDone = computed(() => store.getters['messageHistories/isDone']);

const toggleCollapse = () => {
  isCollapse.value = !isCollapse.value;
}

const setClosedAt = () => {
  store.dispatch('messageHistories/setClosedAt', {
    coachId: profileId.value,
    groupId: messages.value?.groupId,
  });
}
</script>

<template>
  <div
    class="fixed bottom-0 right-0  w-[450px] rounded-t-[10px]"
    :class="{ 'min-h-[380px]': !isCollapse, 'h-auto': isCollapse }"
    style="z-index: 10000000000!important; box-shadow: 0px 4px 30px 0px rgba(29, 57, 94, 0.10);">
    <!-- for hide crisp z-index(1000000) -->
    <div class="flex w-full flex-col">
      <div class="flex h-[55px] w-full items-center justify-between rounded-t-[10px] bg-neutral-200 px-4 py-5">
        <div class="color-blue-800">Envoi de mails {{progressCount}}/{{ messagesList.length }}</div>
        <div class="flex gap-5">
          <button @click="toggleCollapse"><i :class="`icon-chevron-${isCollapse ? 'up' : 'down'} text-blue-400`"></i></button>
          <button v-if="isUploadDone" @click="setClosedAt"><i class="icon-cross text-blue-400"></i></button>
        </div>
      </div>
      <div class="flex size-full max-h-[400px] min-w-[351px] flex-col overflow-auto bg-white" :class="{ 'hidden': isCollapse }">
        <div v-for="(item, index) in messagesList" :key="item.messageId" class="flex flex-col">
          <MessagingPendingItem :data="item"></MessagingPendingItem>
          <div v-if="index < messagesList.length - 1" class="h-[1px] w-full bg-blue-100" />
        </div>
      </div>
    </div>
  </div>
</template>
