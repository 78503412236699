<script setup lang="ts">
import { defineProps, defineEmits, withDefaults, ref, watch, computed } from 'vue';
const props = withDefaults(
  defineProps<{
    label?: string;
    icon?: string;
    disabled?: boolean;
    isOpen?: boolean;
    isSelected?: boolean;
  }>(),
  {
    label: '',
    icon: '',
    disabled: false,
    isOpen: false,
    isSelected: false,
  }
);

const emits = defineEmits<{
  (e: 'click', value: boolean): void,
  (e: 'mouseenter', value: any): void,
}>();

const _isSelected = ref(props.isSelected);
const isSelectedComp = computed(() => props.isSelected);
const isForcedOpen = computed(() => {
  return props.isOpen;
});

const onClick = () => {
  const newValue = !_isSelected.value
  _isSelected.value = newValue;
  emits('click', newValue);
}

const onMouseEnter = (e: any) => {
  emits('mouseenter', e);
};

watch(isForcedOpen, (value: boolean) => {
  _isSelected.value = value;
});

watch(isSelectedComp, (value: boolean) => {
  _isSelected.value = value;
})

</script>

<template>
  <button
    class="flex items-center gap-2 rounded-md border border-blue-200 px-3 py-2 text-blue-800"
    :class="{ 'bg-blue-500 text-white': _isSelected, 'opacity-50': disabled }"
    :disabled="disabled"
    @mouseenter="onMouseEnter"
    @click="onClick"
  >
    <span v-if="$slots.left">
      <slot name="left"></slot>
    </span>
    <span class="flex items-center gap-2">
      {{ label }} <i :class="`icon-${_isSelected ? 'cross' : 'plus'} text-xs`"></i>
    </span>
  </button>
</template>
