<template>
  <div class="panel-edit-dropdown">
    <label
      :style="labelStyle"
      class="panel-edit-dropdown__label"
      v-if="label">
      <span
        :class="`icon-${labelIcon}`"
        :style="labelIconStyle"
        class="panel-edit-dropdown__icon"
        v-if="labelIcon">
      </span>
      {{ label }}
      <span
        :style="labelStarStyle"
        class="panel-edit-dropdown__required"
        v-if="validate && validate.required">
        *
      </span>
    </label>
    <dropdown-select
      :bc-dropdown-border-color-opacity="0.5"
      :bc-options="options"
      :bc-placeholder="placeholder"
      :value="$t(value)"
      @change="$emit('change', $event.name), $emit('input', $event)"
      bc-dropdown-border-color="blue-dark-cello"
      bc-dropdown-border-color-focus="blue-dodger"
      bc-icon-caret-name="chevron-down"
      bc-icon-caret-color="blue-dark-cello"
      bc-input-background-color-active="gray-light"
      bc-input-font-color="blue-dark-cello"
      bc-input-font-size="12px"
      bc-input-min-height="40px"
      bc-input-padding="10px 20px"
      bc-input-padding-focus="10px 20px"
      bc-item-background-color-active="blue-dodger"
      bc-item-background-color-hover="blue-dodger"
      bc-item-font-color="blue-dark-cello"
      bc-item-font-size="12px"
      bc-options-border-bottom-radius="5px"
      bc-options-max-height="300px"
      class="panel-edit-dropdown__dropdown"
      v-bind="$attrs"
      v-on="$listeners">
    </dropdown-select>
  </div>
</template>

<script>
  import DropdownSelect from '@/legacy/ui-kit/components/Dropdown/BcDropdown';
  import colors_helpers from '@/legacy/ui-kit/utils/colors_helpers';

  export default {
    name: 'panel-edit-dropdown',
    inheritAttrs: false,
    components: { DropdownSelect },
    props: {
      value: {
        type: String
      },
      options: {
        type: Array,
        default: () => []
      },
      placeholder: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      labelIcon: {
        type: String,
        default: ''
      },
      /**
       * Define the label color
       */
      labelColor: {
        type: String,
        default: 'black'
      },
      labelFontSize: {
        type: String,
        default: '12px'
      },
      labelIconSize: {
        type: String,
        default: '12px'
      },
      labelIconColor: {
        type: String,
        default: 'black'
      },
      labelStarColor: {
        type: String,
        default: 'blue-dodger'
      },
      labelStarFontSize: {
        type: String,
        default: '14px'
      },
      validate: {
        type: Object
      }
    },
    computed: {
      labelIconStyle() {
        return {
          fontSize: this.labelIconSize,
          color: colors_helpers.$_checkValidColor(this.labelIconColor)
        };
      },
      labelStyle() {
        return {
          color: colors_helpers.$_checkValidColor(this.labelColor),
          fontSize: this.labelFontSize
        };
      },
      labelStarStyle() {
        return {
          fontSize: this.labelStarFontSize,
          color: colors_helpers.$_checkValidColor(this.labelStarColor)
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .panel-edit-dropdown {
    flex-direction: column;

    &__label {
      font-weight: $font-weight-light;
      margin-bottom: 10px;
      align-items: center;
    }

    &__icon {
      margin-right: 10px;
    }

    &__required {
      margin-left: 5px;
    }
  }
</style>
