<script setup lang="ts">
import { ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import BcModal from '@/ui-kit/components/BcModal/BcModal';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcInput from '@/ui-kit/components/BcInput/BcInput';
import MvSelect from '@/ui-kit/components/MvSelect/MvSelect';
import Views from '@/components/Views/Views';
import ViewsContainer from '@/components/Views/ViewsContainer';
import AdminTabs from '@/views/Tabs/AdminTabs';
import { useToast } from '@/ui-kit/components/BcToast';

import { getTemplatesVariables, postTemplatesVariable, updateTemplatesVariable } from '@/api/emails';

const defaultTemplateVariable = {
  slug: '',
  value: '',
  source: '',
};

const toast = useToast();

const modalActive = ref(false);
const isLoading = ref(false);
const isEdit = ref(false);
const templateVariable = ref(defaultTemplateVariable);

const sourcesOptions = [
  {
    id: 'coder',
    label: 'coder',
    value: 'coder',
  },
  {
    id: 'customJob',
    label: 'customJob',
    value: 'customJob',
  },
  {
    id: 'company',
    label: 'company',
    value: 'company',
  },
]

const { data: templatesVariables, refetch } = useQuery({
  queryKey: ['GET_EMAIL_TEMPLATES_VARIABLES'],
  queryFn: async() => {
    const response = await getTemplatesVariables();
    return response.data;
  },
});

const openModal = () => {
  modalActive.value = true;
}

const closeModal = () => {
  isEdit.value = false;
  modalActive.value = false;
}

const onSelectSource = (value: string) => {
  templateVariable.value = {
    ...templateVariable.value,
    source: value,
  };
}

const onSave = () => {
  if (isEdit.value) {
    editTemplatesVariable()
  } else {
    createTemplatesVariable();
  }
}

const createTemplatesVariable = async () => {
  try {
    if (Object.values(templateVariable.value).some(val => !val)) {
      throw new Error('Tous les champs sont obligatoires');
    }

    isLoading.value = true;
    await postTemplatesVariable({
      slug: templateVariable.value.slug,
      name: templateVariable.value.value,
      source: templateVariable.value.source,
    });
    isLoading.value = false;
    refetch();
    closeModal();
    toast.show({
      message: 'Ajout avec succès',
    });
  } catch (err) {
    isLoading.value = false;
    toast.show({
      type: 'error',
      message: err?.message,
    });
  }
}

const editTemplatesVariable = async () => {
  try {
    if (Object.values(templateVariable.value).some(val => !val)) {
      throw new Error('Tous les champs sont obligatoires');
    }

    isLoading.value = true;
    await updateTemplatesVariable({
      id: templateVariable.value._id,
      slug: templateVariable.value.slug,
      name: templateVariable.value.value,
      source: templateVariable.value.source,
    });
    isLoading.value = false;
    refetch();
    closeModal();
    toast.show({
      message: 'Edité avec succès',
    });
  } catch (err) {
    isLoading.value = false;
    toast.show({
      type: 'error',
      message: err?.message,
    });
  }
}

const onCreate = () => {
  isEdit.value = false;
  templateVariable.value = { ...defaultTemplateVariable };
  openModal();
}

const onEdit = (selectedTemplateVariable) => {
  isEdit.value = true;
  templateVariable.value = { ...selectedTemplateVariable, value: selectedTemplateVariable.name };
  openModal();
}

</script>

<template>
  <views class="database-emails">
    <admin-tabs></admin-tabs>
    <router-view></router-view>
    <views-container>
      <div class="flex flex-col gap-3">
        <div class="flex items-center justify-between">
          <div class="text-lg font-bold">Email Variables</div>
          <div>
            <bc-button icon-left="plus" @click="onCreate">Ajouter une variable</bc-button>
          </div>
        </div>
        <div
          v-for="templatesVariable in templatesVariables"
          :key="templatesVariable._id"
          class="card p-5"
          @click="onEdit(templatesVariable)"
        >
          {{ templatesVariable.slug }} - {{ templatesVariable.name }} / {{ templatesVariable.source }}
        </div>
      </div>
    </views-container>

    <bc-modal :active="modalActive" @close="closeModal">
      <div class="h-auto w-[600px]">
        <div class="w-full px-10 py-14">
          <div class="flex w-full flex-col">
            <p class="text-center">
              {{ isEdit ? 'Editer une variable' : 'Ajouter une variable' }}
            </p>
            <div class="my-5 flex flex-col gap-3">
              <BcInput v-model="templateVariable.slug" label="Slug" name="slug"></BcInput>
              <BcInput v-model="templateVariable.value" label="Valeur" name="valeur"></BcInput>
              <MvSelect
                class="mt-4"
                placeholder="Sélectionner une source"
                :items="sourcesOptions"
                :is-absolute="true"
                :value="templateVariable.source"
                @select="onSelectSource"
              ></MvSelect>
            </div>
            <div class="mt-5 flex justify-end">
              <BcButton :disabled="isLoading" @click="onSave" >
                {{ isEdit ? 'Editer' : 'Sauvegarder' }}
              </BcButton>
            </div>
          </div>
        </div>
      </div>
    </bc-modal>
  </views>
</template>
