import { useApi } from '@/composables/api/useApi';
import { useI18n } from '@/i18n/i18n';
import { useToast } from '@/ui-kit/components/BcToast';

export interface LinkedinMessage {
  subject?: string;
  body: string;
  attachments?: Array<{
    filename: string;
    path: string;
  }>;
  templateId?: string;
}

interface ReceivedMessagesResponse {
  hasReceivedMessages: boolean;
}

export function useLinkedinMessagesApi() {
  const { get, post, loading, requestError } = useApi();
  const { t } = useI18n();
  const toast = useToast();

  async function checkHasReceivedMessages(coderId: string) {
    const response = await get<ReceivedMessagesResponse>('/linkedin/has-received-messages', { coderId });
    return response?.hasReceivedMessages ?? false;
  }

  return {
    checkHasReceivedMessages,
    loading,
    requestError,
  };
} 