<template>
  <div
    v-click-outside="manageClosePanel"
    class="panel-tag-push"
    @click.stop>
    <div
      class="panel-tag-push-header bg-neutral-200 is-full-width is-justify-content-space-between is-align-items-center">
      <panel-header-nav></panel-header-nav>
      <div>{{ $t("generics.push") }}</div>
      <bc-spinner
        v-if="isValidateLoading"
        class="panel-tag-push__validate-spinner">
      </bc-spinner>
      <bc-button
        radius-size="s"
        size="extra-small"
        color="primary"
        :disabled="isValidateLoading"
        @click="acceptTagPush()">
        {{ $t('generics.validate') }}
      </bc-button>
    </div>
    <div class="panel-tag-push__label">
      {{ $t("tags.push-tag") }}
    </div>
    <div class="panel-tag-push__card-content" v-if="origin">
      <div class="panel-tag-push__card-content-list">
        <div class="p10">
          <div>{{ origin.name }}</div>
          <div>{{ `Profils : ${origin.count.coders}` }}</div>
          <div>{{ `Jobs : ${origin.count.jobs}` }}</div>
        </div>
      </div>
    </div>
    <div class="panel-tag-push__label">
      {{ $t("generics.by") }}
    </div>
    <div
      class="panel-tag-push-container">
      <div class="panel-tag-push-container__wrapper">
        <panel-tag-autocomplete
          :selected="targetTag"
          :showLabel="false"
          :tagTypeSlug="tagTypeSlug"
          @select-tags="handleSelectTargetTag"
        ></panel-tag-autocomplete>
      </div>
    </div>
    <bc-spinner
      v-if="isLoading"
      class="panel-tag-push__spinner">
    </bc-spinner>
    <div class="panel-tag-push__card-content">
      <div class="panel-tag-push__card-content-list">
        <div
          v-for="(item, index) in targets"
          :key="index">
          <div>{{ item.name }}</div>
          <div>{{ `Profils : ${item.count.coders}` }}</div>
          <div>{{ `Jobs : ${item.count.jobs}` }}</div>
          <div>
            <bc-button
              radius-size="s"
              size="extra-small"
              type="flat"
              color="grey-5"
              iconCenter="cross"
              @click="removeFromTarget(item._id)">
            </bc-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getTagById, searchTags, pushTag, deleteTag } from '@/api/tags';
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav';
  import PanelTagAutocomplete from '@/components/Panel/PanelTag/PanelTagAutocomplete';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import { mapState } from 'vuex';

  export default {
    name: 'panel-tag-push',
    components: {
      PanelHeaderNav,
      PanelTagAutocomplete,
      BcButton,
      BcSpinner,
    },
    mixins: [PanelNavigation],
    data() {
      return {
        tag: {},
        isLoading: true,
        isValidateLoading: false,
        targetTag: null,
        origin: null,
        tagTypeId: null,
        organization: null,
        targets: [],
      };
    },
    computed: {
      ...mapState({
        tagTypes: state => state.tags.tagTypes,
      }),
      tagTypeSlug() {
        return this.tagTypes.find(t => t._id === this.tagTypeId)?.slug;
      },
    },
    methods: {
      async getOriginTagAndTargets() {
        try {
          // Get all origins tags
          const id = this.$route.query.origin;
          const { data } = await getTagById(id);

          this.origin = data;

          this.tagTypeId = this.origin._tagType;

          this.organization = this.origin._organization;

          // Get all targets tags
          const targets = this.splitAndTrim(data.name);

          const promises = targets.map((item) => searchTags({ name: item, limit: 1, tagTypeId: this.tagTypeId, _organization: this.organization }));
          const results = await Promise.all(promises).then(res => res.map(item => item.data[0]));

          // Suppression des doublons par _id + filtre sur origine
          const uniqueResults = [];
          const seenIds = new Set();

          for (const item of results) {
            if (item && item._id !== this.origin._id && !seenIds.has(item._id)) {
              seenIds.add(item._id);
              uniqueResults.push(item);
            }
          }

          this.targets = uniqueResults;
          this.isLoading = false;
        } catch (error) {
          this.$toast.show({
            title: 'Accès refusé',
            message: 'Seul les utilisateurs avec le role god sont autorisés à accéder à la ressource',
            type: 'error',
            icon: 'cross',
          });
          this.isLoading = false;
        }
      },
      splitAndTrim(text) {
        // Split by comma, slash, or dash
        const tokens = text.split(/[,\/-\s\(\)]/).map(token => token.trim());

        // Filter out empty strings or tokens that are just whitespace
        return tokens.filter(token => token.length > 0);
      },
      removeFromTarget(id) {
        this.targets = this.targets.filter(item => item._id !== id);
      },
      async handleSelectTargetTag(tag) {
        tag = tag[tag.length - 1];

        if (this.targets.findIndex(item => item._id === tag._id) === -1) {
          this.targets.push(tag);
        }

        this.targetTag = [];
      },
      async acceptTagPush() {
        // Display loading
        this.isValidateLoading = true;

        // Push tag
        await pushTag({
          origin: this.origin._id,
          target: this.targets.map(item => item._id).join(","),
          tagTypeSlug: this.tagTypeSlug,
        });

        // Delete origin tag
        await deleteTag(this.origin._id);

        // Display success message
        this.$toast.show({
          type: 'success',
          message: this.$t('tags.the-tag-has-been-pushed-successfully'),
        });

        this.closePanel();
      },
    },
    watch: {
      $route: {
        handler() {
          this.getOriginTagAndTargets();
        },
        immediate: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-tag-push {
    height: 100%;
    flex-direction: column;
    background-color: white;

    &__label {
      margin: 40px 20px 0px 20px;
      font-weight: 500;
      font-size: 14px;
    }

    &__spinner {
      margin: 50px 0px;
      justify-content: center;
      width: 100%;
    }

    &__validate-spinner {
      width: 30px;
    }

    &__card-content {
      width: 100%;
      padding: 20px 10px;
      display: flex;
      flex-direction: column;

      &-list {
        display: flex;
        flex-direction: column;
        padding: 0px 10px !important;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
      }

      &-list > div {
        margin: 5px 0px;
        padding: 3px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid lightgrey;
        border-radius: 5px;
      }

      &-list > div > div:first-child {
        width: 50%;
      }
    }

    .p10 {
      padding: 10px;
    }
  }

  .panel-tag-push-header {
    padding: 10px 15px;
    flex-shrink: 0;
    border-bottom: 1px solid $color-grey-4;
    height: 50px;
  }

  .panel-tag-push-container {
    margin: 20px;

    &__wrapper {
      // margin: 5px 0px;
      // padding: 5px 10px;
      // border: 2px solid lightgrey;
      width: 100%;
      // display: flex;
      // justify-content: space-between;
      // flex-direction: row;
      // align-items: center;
      // border-radius: 5px;
      // min-height: 40px;
      // width: 100%;

      > div {
        width: 100%;
      }
    }
  }
</style>
