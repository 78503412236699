<template>
  <div class="filter-companies-custom-fields">
    <filter-content
      v-for="customFieldsFilter in customFieldsFilters"
      :key="customFieldsFilter.key"
      :label="customFieldsFilter.name"
      :icon="customFieldsFilter.icon">
      <bc-custom-field
        placeholder="--"
        :class="[
          customFieldsFilter.type !== 'single-select'
            ? 'is-full-width'
            : 'searchbar-coders-custom-fields__item',
        ]"
        :type="customFieldsFilter.type"
        :is-filter="true"
        :custom-field="customFieldsFilter"
        :selected="selected[customFieldsFilter.key] || []"
        :with-label="false"
        @on-update="onUpdateCustomField($event, customFieldsFilter)" />
    </filter-content>
  </div>
</template>

<script>
import debounce from 'debounce';

import BcCustomField from '@/ui-kit/components/BcCustomField/BcCustomField';
import { CUSTOM_FIELDS_MULTISELECT_FILTERS } from '@/ui-kit/utils/customFields';
import FilterContent from '@/components/Filter/FilterContent';

export default {
  name: 'filter-companies-custom-fields',
  components: {
    BcCustomField,
    FilterContent,
  },
  props: {
    customFieldsFilters: {
      type: Array
    },
  },
  data() {
    return {
      selected: {},
    };
  },
  methods: {
    onUpdateCustomField: debounce(function({ key, value }, customFieldsFilter) {
      if (CUSTOM_FIELDS_MULTISELECT_FILTERS.includes(customFieldsFilter.type)) {
        return this.handleMultiselect({ key, value, icon: customFieldsFilter.icon });
      }
      this.selected = {
        ...this.selected,
        [key]: value,
      };
      this.onSelect({ key, data: value, icon: customFieldsFilter.icon, replace: true });
    }, 300),
    onSelect({ key, data, replace }) {
      const queries = {}
      const cfKey = `cf-${key}`;
      if (replace) {
        queries[cfKey] = Array.isArray(data) ? data.join('-r-') : data;
      }
      this.$emit('count', { key: cfKey, count: 1 });
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          ...queries,
        },
      })
    },
    resetFilter(key) {
      this.$emit('count', { key, count: 0 });
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          [key]: undefined,
        },
      })
    },
    handleMultiselect({ key, value, icon }) {
      this.selected = {
        ...this.selected,
        [key]: [value],
      };
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          [`cf-${key}`]: value,
        },
      })
      this.$emit('on-select', { key, data: value, icon, replace: true });
    },
    formatQueryValue(queryValue = '') {
      let value = queryValue;
      if (queryValue.includes(',')) {
        value = queryValue.split(',');
      } else if (queryValue.includes('-r-')) {
        value = queryValue.split('-r-');
      }
      return value;
    },
    checkHasCfInQuery(query) {
      return Object.keys(query).some(key => key.startsWith('cf-'));
    },
    syncSelectedWithQuery(query) {
      Object.keys(query).forEach(key => {
        if (key.startsWith('cf-')) {
          let value = query[key];
          value = this.formatQueryValue(value);

          const customField = this.customFieldsFilters.find(cf => cf.key === key.replace('cf-', ''));
          if (customField?.showFilter && customField?.type === 'cursor') {
            if (+value[0] === customField.min && +value[1] === customField.max) {
              return this.resetFilter(key);
            }
          }

          this.$emit('count', { key, count: value ? 1 : 0 });
          this.selected = {
            ...this.selected,
            [`${key.replace('cf-', '')}`]: value || undefined,
          };
        }
      });
      Object.keys(this.selected).forEach(key => {
        if (!query[`cf-${key}`]) {
          this.$emit('count', { key, count: 0 });
          this.selected = {
            ...this.selected,
            [key]: undefined,
          };
        }
      });
    },
  },
  watch: {
    '$route.query': {
      handler(query, prevQuery) {
        if (this.checkHasCfInQuery(query)) {
          this.syncSelectedWithQuery(query);
        } else if (this.checkHasCfInQuery(prevQuery)) {
          Object.keys(prevQuery).forEach(key => {
            this.$emit('count', { key, count: 0 });
            this.selected = {};
          });
        } else {
          this.selected = {};
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-companies-custom-fields {
  flex-direction: column;
  width: 100%;

  &__wrapper {
    padding: 20px 50px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  }

  &__item {
    width: 420px;
  }
}
</style>
