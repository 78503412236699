<template>
  <div class="bc-menu">
    <div
      class="bc-menu__container"
      @click="handleClick"
    >
      <slot></slot>
    </div>
    <transition name="slide-fade">
      <div
        v-if="isOpen"
        class="bc-menu__content"
        :class="`bc-menu__content--${position}`"
        :style="{ left: contentStyle.left, top: contentStyle.top, bottom: contentStyle.bottom }"
        v-click-outside="handleClickOutside"
      >
        <slot name="content" :is-open="isOpen"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'bc-menu',
  props: {
    position: {
      type: String,
      default: 'right',
    },
    show: {
      type: Boolean,
      default: false,
    },
    contentStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    if (this.show) {
      this.isOpen = this.show;
    }
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    handleClick() {
      this.isOpen = !this.isOpen;
      this.$emit('click', this.isOpen)
    },
    handleClickOutside() {
      this.isOpen = false;
      this.$emit('click', this.isOpen)
    }
  },
  watch: {
    show(newValue) {
      this.isOpen = newValue;
    }
  }
};
</script>

<style lang="scss" scoped>

@mixin arrow {
  content: '';
  display: block;
  width: 18px;
  height: 15px;
  position: absolute;
  background: white;
  top: -2px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  z-index: 0;
}

.bc-menu {
  flex: 1;
  position: relative;

  &__container {
    flex: 1;

    &:hover {
      cursor: pointer;
    }
  }

  &__content {
    position: absolute;
    top: calc(100% + 15px);
    background: white;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(29, 57, 94, 0.1);

    &--left {
      left: -50px;

      &:after {
        @include arrow;
        left: 62px;
      }
    }

    &--right {
      right: -50px;

      &:after {
        @include arrow;
        right: 56px;
      }
    }
  }
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .4s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
