/**
 *
 * @param oldRemote
 * @param newRemote
 * @returns {boolean|*}
 *
 * Check if two profile got same remote values
 * by checking remote array length and check if one value is not in the two arrays
 */
const isDifferentRemote = (oldRemote, newRemote) => {
  return oldRemote.length !== newRemote.length || oldRemote.some(remote => !newRemote.includes(remote));
};

/**
 *
 * @param oldLocations
 * @param newLocations
 * @returns {*|boolean}
 *
 * Check locations is set in both profile and
 * first location name are identical
 */
const isDifferentLocations = (oldLocations, newLocations) => {
  return (oldLocations.length > 0 && !newLocations.length) ||
  (oldLocations.length > 0 && newLocations.length > 0 && oldLocations[0].addressComponents[0].longName !== newLocations[0].addressComponents[0].longName);
};

const isDifferentSocial = (oldSocial, newSocial) => {
  return oldSocial.linkedin !== newSocial.linkedin;
};

const complexChecker = {
  remote: isDifferentRemote,
  locations: isDifferentLocations,
  social: isDifferentSocial,
};



const shouldAddComplexConflict = (profileToDelete, profileToUpdate, field) => {
  return (profileToDelete[field] && !profileToUpdate[field]) || complexChecker[field](profileToDelete[field], profileToUpdate[field]);
};

const complexKeyToCheck = ['remote', 'locations', 'social'];

export const keyToCheck = {
  coder: [
    "pictureUrl",
    "remote",
    "title",
    "experience",
    "social",
  ],
};

/**
 *
 * @param profileType
 * @param profileToDelete
 * @param profileToUpdate
 * @returns {*}
 *
 * Iterate on some profile key and call some function to check if theres a difference
 * then return a array of string with each different key btw profile
 */
export const getProfileConflictsHelper = ({ profileType = "", profileToDelete = {}, profileToUpdate = {} }) => {
  return keyToCheck[profileType].reduce((acc, curr) => {
    if (complexKeyToCheck.includes(curr) && profileToDelete[curr]) {
      if (shouldAddComplexConflict(profileToDelete, profileToUpdate, curr)) {
        acc.push(curr);
      }

      return acc;
    }

    if (profileToDelete[curr] && profileToDelete[curr] !== profileToUpdate[curr]) {
      acc.push(curr);

      return acc;
    }

    return acc;
  }, []);
};
