<script lang="ts" setup>
  import { computed, defineEmits, defineProps, onUnmounted, ref, watch, withDefaults } from 'vue';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { useI18n } from '@/i18n/i18n';
  import moment from 'moment/min/moment-with-locales';
  import { Process } from '@/domains/models/Process';
  import { useMultiselect } from '@/composables/useMultiselect';

  import ApplicationsCard from '@/components/Applications/ApplicationsCard';
  import BcPagination from '@/ui-kit/components/BcPagination/BcPagination';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import MvSelect from '@/ui-kit/components/MvSelect/MvSelect';
  import { useApplicationsStore } from '@/store/pinia/applications';
  import { storeToRefs } from 'pinia';
  import { useStore } from '@/store';

  const props = withDefaults(
    defineProps<{
      list: Process[];
      loading: boolean;
      total: number;
      filter: string;
      isDeletedFinished: boolean;
    }>(),
    {
      list: () => [],
      loading: false,
      total: 0,
      filter: 'new',
      isDeletedFinished: true,
    },
  );

  const emit = defineEmits<{
    (e: 'on-filter', value: string | undefined): void;
    (e: 'on-page-change', page: number): void;
    (e: 'on-accept', processId: string, coder: any): void;
    (e: 'on-reject', processId: string, coder: any): void;
    (e: 'on-delete', process: Partial<Process>, isLast: boolean): void;
    (e: 'on-page-limit-change', limit: number): void;
  }>();

  const route = useRoute();
  const router = useRouter();
  const {
    resetSelectedCards,
    setSelectedCards,
  } = useMultiselect();
  const { t } = useI18n();

  const pageLimit = ref<number>(20);
  const applicationsStore = useApplicationsStore();
  const store = useStore();

  const {
    selectedApplications,
    selectedApplicationsCount,
  } = storeToRefs(applicationsStore);

  const pageLimitOptions = [
    { label: '20 candidatures', value: 20 },
    { label: '50 candidatures', value: 50 },
    { label: '100 candidatures', value: 100 },
    { label: '200 candidatures', value: 200 },
  ];

  const hasActiveResumeUpload = computed(() => store.getters['resumeHistories/hasActiveResumeUpload']);
  const hasActiveMessages = computed(() => store.getters['messageHistories/hasActiveMessages']);
  const listByDate = computed(() => {
    return Object.values(props.list.reduce((acc, val) => {
      const date = new Date(val.createdAt).toISOString().split('T')[0];
      if (acc[date]) {
        return {
          ...acc,
          [date]: {
            date,
            data: [...acc[date].data, val],
          },
        };
      } else {
        return {
          ...acc,
          [date]: {
            date,
            data: [val],
          },
        };
      }
    }, {}));
  });

  const onSelectPageLimit = (limit: number) => {
    pageLimit.value = limit;
    emit('on-page-limit-change', limit);
  };

  const changePage = async(page: number) => {
    emit('on-page-change', page);
  };

  const onAccept = (processId: string, coder: any) => {
    emit('on-accept', processId, coder);
  };

  const onReject = (processId: string, coder: any) => {
    emit('on-reject', processId, coder);
  };

  const onDelete = (process: Partial<Process>, isLast: boolean) => {
    emit('on-delete', process, isLast);
  };

  const onCheckedCard = ({ checked, process }: { checked: boolean; process: Process }) => {
    if (checked) {
      applicationsStore.setSelectedApplications({
        _id: process._id,
        _coder: process._coder,
      });
    } else {
      applicationsStore.removeApplicationFromSelectedApplications(process._id);
    }
    setSelectedCards(checked, process);
  };

  const unSelectProcesses = () => {
    applicationsStore.resetApplications()
    resetSelectedCards();
  };

  const acceptProcesses = () => {
    Object.keys(selectedApplications.value).forEach((processId) => {
      onAccept(selectedApplications.value[processId]);
    });
    // unSelectProcesses();
  };

  const rejectProcesses = () => {
    Object.keys(selectedApplications.value).forEach((processId) => {
      onReject(selectedApplications.value[processId]);
    });
    // unSelectProcesses();
  };

  const deleteProcesses = () => {
    Object.keys(selectedApplications.value).forEach((processId, index) => {
      onDelete(selectedApplications.value[processId], index === (Object.keys(selectedApplications).length - 1));
    });
  };

  function openFavoriteListsPanel() {
    router.push({
      name: `${route.name}Panel`,
      params: {
        id: 'favorite-lists',
      },
      query: {
        ...route.query,
        type: 'favorite-coder-list',
        subtype: 'add-resources',
      },
    });
  }

  watch(() => props.isDeletedFinished, (value) => {
    if (value) {
      unSelectProcesses();
      resetSelectedCards();
    }
  });

  onUnmounted(() => {
    unSelectProcesses();
    resetSelectedCards();
  });
</script>

<template>
  <div class="flex w-full flex-col pb-3">
    <div v-if="loading" class="mx-auto">
      <BcSpinner></BcSpinner>
    </div>
    <div v-else class="relative flex w-full flex-col gap-5">
      <p
        v-if="!listByDate.length"
        class="mx-auto mt-5 flex gap-2"
      >
        <span class="icon-search"></span>
        {{ $t('generics.no-result-found') }}
      </p>
      <div
        v-for="processesByDate in listByDate"
        :key="processesByDate.date"
        class="flex w-full flex-col gap-1"
      >
        <p class="text-xs capitalize text-blue-400">
          {{ moment(processesByDate.date).format('dddd DD MMMM YYYY') }}
        </p>
        <ApplicationsCard
          v-for="process in processesByDate.data"
          :key="process._id"
          :filter="filter"
          :process="process"
          :selected="Boolean(selectedApplications[process._id])"
          class="w-full"
          @checked="onCheckedCard"
          @on-accept="onAccept"
          @on-reject="onReject"
        />
      </div>
      <div
        v-if="selectedApplicationsCount"
        class="applications-list__footer fixed bottom-0 z-50 mx-auto flex w-[80%] flex-1 flex-col justify-center py-4 pr-32"
        :class="{ 'left-[-100px]': hasActiveResumeUpload || hasActiveMessages }">
        <p class="mb-2 text-center font-bold">
          Sélection de {{ selectedApplicationsCount }} profils :
        </p>
        <div class="flex justify-center gap-3">
          <BcButton color="error" iconLeft="trash" @click="deleteProcesses">{{
              $t('generics.delete')
            }}
          </BcButton>
          <BcButton iconLeft="cross" type="outlined" @click="rejectProcesses">{{
              $t('global.reject')
            }}
          </BcButton>
          <BcButton iconLeft="check" @click="acceptProcesses">{{ $t('generics.accept') }}</BcButton>
          <BcButton icon-left="addtolist" @click="openFavoriteListsPanel">
            {{ t('companies.panel-company.add-to-a-list') }}
          </BcButton>
          <BcButton type="outlined" @click="unSelectProcesses">{{
              $t('generics.deselect')
            }}
          </BcButton>
        </div>
      </div>
    </div>

    <div class="mt-10 flex items-center justify-center gap-10">
      <BcPagination
        :current="Number($route.query.page || 1)"
        :per-page="Number($route.query.pageSize || 20)"
        :total="total"
        @change="changePage"
      >
      </BcPagination>
      <div class="flex w-fit items-center">
        <div class="mr-2 w-fit text-xs">Affichage par page</div>
        <MvSelect
          :is-absolute="true"
          :items="pageLimitOptions"
          :selected-label="pageLimit.toString()"
          :value="pageLimit"
          direction="top"
          size="s"
          @select="onSelectPageLimit"
        ></MvSelect>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .applications-list {
    &__footer {
      background: linear-gradient(0deg, white 75%, transparent);
    }
  }
</style>
