<template>
  <views class="section--pipe">
    <opportunities-tabs></opportunities-tabs>
    <main class="is-full-width">
      <views-container>
        <custom-jobs-filters
          :results-count="totalJobs"
        />
        <custom-jobs-list
          class="mt-5"
          :items="jobs"
          :show-status="showStatus"
          :refetch="resetJobs"
          :loadMore="loadJobs"
          :is-draggable="isDraggable"
          :is-loading="isLoading"
          :identifier="customJobsIdentifier"
        />
      </views-container>
    </main>
    <router-view></router-view>
  </views>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import ViewsContainer from '@/components/Views/ViewsContainer';
import CustomJobsFilters from '@/components/CustomJobs/CustomJobsFilters';
import CustomJobsList from '@/components/CustomJobs/CustomJobsList.vue';
import Views from '@/components/Views/Views';
import OpportunitiesTabs from '@/views/Tabs/OpportunitiesTabs';
import { getCustomJobs } from '@/api/custom-jobs';
import { Modals } from '@/store/modules/app';
import locations_helpers from '@/common-old/utils/locations_helpers';

export default {
  name: 'custom-jobs',
  components: {
    ViewsContainer,
    CustomJobsFilters,
    CustomJobsList,
    Views,
    OpportunitiesTabs,
  },
  beforeRouteEnter(to, from, next) {
    if (!to.query['selected-pipe']) {
      next({
        path: to.path,
        query: {
          ...to.query,
          'selected-pipe': 'personal-pipe',
        },
      });
    } else {
      next();
    }
  },
  async beforeRouteUpdate(to, from, next) {
    if (from.name === 'CustomJobs' && to.name !== 'CustomJobs') {
      localStorage.setItem('queries', JSON.stringify({ path: from.path, query: from.query }));
    }
    if (from.name !== 'CustomJobs' && to.name === 'CustomJobs') {
      const item = localStorage.getItem('queries');
      if (item) {
        localStorage.removeItem('queries');
        const parsedItem = JSON.parse(item);
        return next({ path: parsedItem.path, query: {...parsedItem.query} })
      }
    }
    return next();
  },
  data() {
    return {
      isLoading: false,
      jobToEdit: {},
      editToast: false,
      currentPage: 1,
      totalJobs: 0,
      jobs: [],
      customJobsIdentifier: Date.now()
    };
  },
  computed: {
    ...mapState({
      coaches: state => state.coaches.coaches,
      profile: state => state.user.profile,
    }),
    ...mapGetters({
      configurationFeatures: 'user/configurationFeatures',
      configurationDb: 'user/configurationDb',
      ressourceCount: 'metadata/ressourceCount',
    }),
    isCreationLocked() {
      return !this.configurationFeatures.jobsLimitUnlocked && this.ressourceCount.customJob >= this.configurationDb.jobsLimit;
    },
    isSuperUser() {
      return this.$store.getters['user/isSuperUser'];
    },
    isIndividualFilterSelected() {
      return this.$route.query['selected-pipe'] === 'personal-pipe';
    },
    showStatus() {
      return this.$route.query['job-active'] === undefined;
    },
    isDraggable() {
      return this.isIndividualFilterSelected && this.profile._id === this.$route.query.coachId;
    },
  },
  watch: {
    '$route.query'(newQuery, oldQuery) {
      const hasDiffCustomFields = Object.keys(newQuery)
        .filter(key => key.startsWith('cf-'))
        .some(cfKey => newQuery[cfKey] !== oldQuery[cfKey]);

      const hasDiffOldCustomFields = Object.keys(oldQuery)
        .filter(key => key.startsWith('cf-'))
        .some(cfKey => newQuery[cfKey] !== oldQuery[cfKey]);

      if (
        newQuery.coachId !== oldQuery.coachId ||
        newQuery.teamId !== oldQuery.teamId ||
        newQuery['job-active'] !== oldQuery['job-active'] ||
        newQuery['professions'] !== oldQuery['professions'] ||
        newQuery['job-active'] !== oldQuery['job-active'] ||
        newQuery['technos'] !== oldQuery['technos'] ||
        newQuery['priority'] !== oldQuery['priority'] ||
        newQuery['professionsOperator'] !== oldQuery['professionsOperator'] ||
        newQuery['skillsOperator'] !== oldQuery['skillsOperator'] ||
        newQuery.locations !== oldQuery.locations ||
        newQuery.distance !== oldQuery.distance ||
        hasDiffCustomFields ||
        hasDiffOldCustomFields ||
        newQuery['uniqueids'] !== oldQuery['uniqueids'] ||
        newQuery['searchInput'] !== oldQuery['searchInput']
      ) {
        this.resetJobs();
      }
    },
  },
  mounted() {
    this.getActivatedChannels();
    this.getActivatedChannelsSubscriptions();
  },
  methods: {
    ...mapMutations('user', ['setProfile']),
    ...mapActions({
      setModal: 'app/setModal',
      setParams: 'app/setParams',
      setCoachRessourceCount: 'metadata/setCoachRessourceCount',
      getActivatedChannels: 'xtramile/getActivatedChannels',
      getActivatedChannelsSubscriptions: 'xtramile/getActivatedChannelsSubscriptions',
    }),
    checkLimit() {
      if (!this.isCreationLocked) {
        return false;
      }
      this.setParams({ feature: 'jobsLimitUnlocked' });
      this.setModal(Modals['modal-feature-db-limit']);
      return true;
    },
    async loadJobs($state, page = this.currentPage) {
      this.isLoading = true;
      try {
        const customFieldsQueries = this.getCustomFieldsQueries();
        const params = {
          searchInput: this.$route.query['searchInput'],
          coachId: this.$route.query.coachId || undefined,
          active: this.$route.query['job-active'],
          professionIds: this.$route.query['professions']?.split(','),
          skillIds: this.$route.query['technos']?.split(','),
          priority: this.$route.query['priority'],
          professionsOperator: this.$route.query['professionsOperator'],
          skillsOperator: this.$route.query['skillsOperator'],
          ...this.$route.query.locations && { locations: JSON.stringify(locations_helpers.getLocationsId(this.$route.query.locations)) },
          distance: this.$route.query.distance,
          page,
          ...customFieldsQueries && { customFields: customFieldsQueries },
          ...this.$route.query.uniqueids && { uniqueids: this.$route.query.uniqueids.split(',').map(id => +id) },
        };
        const { data: { jobs: res, next, count } } = await getCustomJobs(params);
        this.currentPage++;
        this.totalJobs = count;
        if (page > 1) {
          this.jobs = [...this.jobs, ...res];
        } else {
          this.jobs = res;
        }
        if (this.jobs.length) {
          $state?.loaded();
        }
        if (!next) {
          $state?.complete();
        }
        this.setCoachRessourceCount({ ressourceType: 'customJob', count });
        this.isLoading = false;
      } catch (error) {
        this.$toast.show({
          type: 'error',
          title: 'Error',
          message: error.message,
        });
      }
    },
    getCustomFieldsQueries() {
     const customFieldsQuery = Object.keys(this.$route.query).filter((key) => key.startsWith('cf-'));
      if (customFieldsQuery.length) {
        const obj = {};
        customFieldsQuery.forEach(key => {
          obj[key.split('cf-')[1]] = this.$route.query[key];
        });
        return JSON.stringify(obj);
      }
      return null;
    },
    async resetJobs() {
      this.customJobsIdentifier = Date.now();
      this.currentPage = 1;
      this.jobs = [];
    },
    openCreateOpportunityPanel() {
      const limitReached = this.checkLimit();
      if (limitReached) return;

      this.$router.push({
        ...this.$route,
        name: `${this.$route.name}Panel`,
        params: {
          id: 'new-custom-jobs',
        },
        query: {
          ...this.$route.query,
          type: 'create-custom-jobs',
        },
      });
    },
  },
};
</script>

<style lang="scss">
.section--pipe {
  padding: 0 0 4% 0;
}

.filter-dropdown {
  min-width: 180px;
}

.hunt {
  &__subtitle {
    position: relative;
    top: 1px;
    color: #3D5476;
  }

  &__dropdown {
    min-width: 170px;
  }
}

section {
  overflow: initial;

  .table--header--options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .bc-dropdown {
      width: 180px;

      .bc-dropdown__input {
        .bc-dropdown-trigger {
          border: 1px solid #3c80f7;
          color: $color-primary;
        }
      }
    }

    .left--options {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0 10px;
      }

      .is--closed--displayed {
        color: #3dcf8e;
      }

      .is--closed--not--displayed {
        color: #e6525a;
      }
    }

    .right--options {
      margin-left: auto;

      p {
        margin-right: 28px;
      }
    }
  }

  .edit--toast {
    color: $color-success;
    padding: 5px 10px;
    background-color: rgba($color-success, 0.08);
    transition: 0.2s ease-out;
    margin-right: -10px;
  }
}
</style>
