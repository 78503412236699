import coders from '@/api/coders';

export const getCoderProfile = (id, context) => {
  const execute = (id, context) => {
    try {
      return coders.getCoderProfile(id, context);
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    return execute(id, context);
  } catch (error) {
    throw error;
  }
};

export default {
  getCoderProfile,
};
