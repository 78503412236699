import { contracts } from '@/common-old/macros/contracts';

export const buildLocationsTag = (location) => ({
  name: location.name,
  query: {
    placeId: location.placeId,
  },
  type: 'location',
});
export const buildPhoneTag = () => ({
  name: 'generics.phone-tag',
  type: 'phone',
});
export const buildCVTag = () => ({
  name: 'generics.resume',
  type: 'cv',
});
export const buildEmailTag = () => ({
  name: 'contacts.email',
  type: 'email',
});
export const buildLinkedinTag = () => ({
  name: 'contacts.linkedin',
  type: 'linkedin',
});
export const buildSearchTag = (query) => ({
  name: query,
  type: 'search',
});

export const buildRemoteTag = (query) => {
  const remoteMap = {
    available: 'panel-coder-edit.possible-remote',
    partial: 'panel-coder-edit.partial-remote',
    full: 'panel-coder-edit.full-remote',
  };

  return {
    name: remoteMap[query],
    query,
    type: `remote-${query}`,
  };
};

export const buildSalaryTag = ({ range, unit }) => ({
  name: `${range[0]}${unit} - ${range[1]}${unit}`,
  query: {
    salaryMin: range[0],
    salaryMax: range[1],
  },
  type: 'salary',
});
export const buildTechnosTag = techno => ({
  name: techno.name,
  _id: techno._id,
  type: 'technos',
});
export const buildProfessionsTag = profession => ({
  name: profession.name,
  prefix: profession.industryName,
  _id: profession._id,
  type: 'professions',
});

export const buildCompaniesTag = profession => ({
  name: profession.name,
  _id: profession._id,
  type: 'companies',
});
export const buildExperienceTag = (query) => {
  const experienceMap = {
    student: 'table-header.student',
    junior: 'table-header.junior',
    confirmed: 'table-header.confirmed',
    expert: 'table-header.expert',
  };

  return {
    name: experienceMap[query],
    query,
    type: 'experience',
  };
};
export const buildRoleTag = (query) => {
  const roleMap = {
    operationnel: 'panel-coder-edit.function-dropdown-operating',
    lead: 'panel-coder-edit.function-dropdown-lead',
    manager: 'panel-coder-edit.function-dropdown-manager',
  };

  return {
    name: roleMap[query],
    query,
    type: 'role',
  };
};
export const buildContractTag = (query) => {
  return {
    name: contracts[query].name,
    query,
    type: 'contracts',
  };
};

export const buildRatingTag = (rating) => {
  return {
    name: 'panel-coder-edit.eval-dot',
    query: rating,
    type: 'rating',
  };
};

export const buildDomainTag = (domain) => {
  return {
    name: domain.name,
    _id: domain._id,
    type: 'domains',
  };
};

export const buildLastCoachActivityTag = ({ from, to }) => {
  const formattedFrom = from ? from.format('D-MM-YYYY') : '';
  const formattedTo = to ? to.format('D-MM-YYYY') : '';
  const fromLabel = from ? from.fromNow() : 'timeline.no-start-determined';
  const toLabel = to ? to.fromNow() : 'timeline.no-end-determined';

  return {
    name: 'timeline.inactivity-period',
    query: {
      fromLabel,
      toLabel,
      ...from && { lastCoachActivityFrom: formattedFrom },
      ...to && { lastCoachActivityTo: formattedTo },
    },
    type: 'lastCoachActivity',
  };
};

export const buildCustomFieldTag = ({ key, data, icon }) => {
  return {
    name: Array.isArray(data) ? data.join('-r-') : data,
    key,
    icon,
    type: 'customField',
  }
}


export default {
  buildLocationsTag,
  buildPhoneTag,
  buildSearchTag,
  buildSalaryTag,
  buildTechnosTag,
  buildExperienceTag,
  buildRoleTag,
  buildRemoteTag,
  buildContractTag,
  buildDomainTag,
  buildCVTag,
  buildLastCoachActivityTag,
  buildRatingTag,
  buildProfessionsTag,
  buildCompaniesTag,
  buildEmailTag,
  buildLinkedinTag,
  buildCustomFieldTag,
};
