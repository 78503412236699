<template>
  <td>
    <div>
      <switcher
        :value="isChecked"
        :bcBackgroundColorActive="'#3DCF8E'"
        :bcBackgroundColorInactive="'#E6525A'"
        :bcBackgroundColorOpacityInactive="1"
        @input="switchValue"
        :disabled="!canEdit"></switcher>
    </div>
  </td>
</template>

<script>
import Switcher from '@/legacy/ui-kit/components/Switcher/BcSwitcher.vue';

export default {
  name: 'switchcell',

  components: {
    Switcher,
  },

  props: {
    job: {
      type: Object,
    },
    columnType: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      isChecked: true,
    };
  },

  mounted() {
    if (this.job._id) {
      this.isChecked = this.job.status;
    }
  },

  methods: {
    switchValue(isChecked) {
      const cellData = {
        columnName: this.columnType.toLowerCase(),
        data: isChecked,
      };

      this.isChecked = isChecked;

      if (this.job) {
        return this.$emit('update-cell-data', {
          ...cellData,
          jobId: this.job._id,
        });
      }

      this.$emit('update-cell-data', cellData);
    },
  },
  watch: {
    'job.status'() {
      this.isChecked = this.job.status;
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  div {
    display: flex;
    justify-content: center;
  }
}
</style>
