import { http } from '@/api/index';
import axios from 'axios';

http.CancelToken = axios.CancelToken;

let source;

export const getCards = ({ call, params, cancelTokenSource = {} }) => {
  // If there is a pending token, it will cancel the pending request
  if (source) {
    source.cancel('Operation canceled due to new request.');
  }

  source = http.CancelToken.source();

  return http.get(`/${call}`, {
    params,
    cancelToken: cancelTokenSource.token || source.token
  });
};

export default {
  getCards
};
