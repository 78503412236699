<script lang="ts" setup>
  import CampaignsFiltersSearchbar
    from '@/components/Campaigns/CampaignsFilters/CampaignsFiltersSearchbar.vue';
  import { usePanelStore } from '@/store/pinia/panel';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger.vue';
  import ProspectsModal from '@/components/Prospects/ProspectsModal.vue';
  import ProspectsModalFilters
    from '@/components/Prospects/ProspectsModal/ProspectsModalFilters.vue';

  const panelStore = usePanelStore();

  function goFilters() {
    panelStore.openModal<{}>(ProspectsModalFilters, {});
  }
</script>

<template>
  <div class="flex items-center gap-2">
    <CampaignsFiltersSearchbar/>
    <BcDropdownTrigger
      :text="$tc('generics.filters', 2)"
      class="trigger !p-[10px] capitalize"
      icon="equalizer"
      @click.native="goFilters">
    </BcDropdownTrigger>
    <ProspectsModal/>
  </div>
</template>

<style lang="scss" scoped>
  .trigger {
    &:deep(.bc-dropdown-trigger__label-icon) {
      @apply mr-0;
    }
  }
</style>
