import { http } from '@/api/index';

export const createEmployee = data => {
  return http.post(`employees`, data);
};

export const editEmployee = ({id, data}) => {
  return http.patch(`employees/${id}`, data);
};

export const getCompaniesFavoriteEmployees = (companyIds) => {
  return http.get(`partners/employees/favorite`, {
    params: {
      companyIds,
    }
  });
}

export default {
  createEmployee,
  editEmployee,
};
