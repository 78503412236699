<template>
  <div class="panel-job-header">
    <div class="panel-job-header__nav">
      <bc-button
        @click.native="goPrevious"
        bc-background-color="transparent"
        bc-icon="arrow-left"
        bc-icon-size="16px"
        bc-padding="0"
        bc-text-color="gray-silver-chalice"
        class="panel-job-header__nav-button"
      >
      </bc-button>
      <router-link
        :to="huntLink"
        class="panel-job-header__nav-link"
      >
        {{ linkText }}
      </router-link>
    </div>
    <div class="panel-job-header__dropdowns">
      <button-dropdown
        :dropdown-active="profile.status ? profile.status - 2 : 0"
        :dropdown-items="dropdownItems"
        :dropdown-text="
          profile.status ? dropdownItems[profile.status - 2].name : dropdownItems[0].name
        "
        @select="updateJobStatus($event)"
        class="panel-job-header__dropdown panel-job-header__button"
        v-if="profile.status"
      >
      </button-dropdown>
      <modal @close-modal="closeModal" v-if="displayModal">
        <panel-job-modal-delete @close-modal="closeModal" @confirm-delete="confirmJobDelete">
        </panel-job-modal-delete>
      </modal>
      <button-dropdown
        :dropdown-active="profile.status ? profile.status - 2 : 0"
        :dropdown-items="settingsItems"
        :icon-type="'settings'"
        @select="settingsItems[$event.index].action"
        class="panel-job-header__settings panel-job-header__button"
      >
      </button-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
import ButtonDropdown from '@/components/UIKit/Button/ButtonDropdown';
import Modal from '@/components/Modal/Modal';
import PanelJobModalDelete from '@/components/Panel/PanelJob/PanelJobModal/PanelJobModalDelete';
import BcButton from '@/legacy/ui-kit/components/Button/BcButton';
import { updateJobStatus } from '@/api/jobs';

/**
 * The panel header when you click on my profile
 */
export default {
  name: 'panel-job-header',
  components: {
    BcButton,
    PanelJobModalDelete,
    Modal,
    ButtonDropdown
  },
  /**
   * We import the closePanel function to give it to the button cross
   */
  mixins: [PanelNavigation],
  data() {
    return {
      resource: 'jobs',
      subObjectResource: 'job',
      displayModal: false
    };
  },
  computed: {
    ...mapState({
      profile: state => state.panel.profile
    }),
    ...mapGetters({
      cardActive: 'cardActive'
    }),
    dropdownItems() {
      return [
        {
          name: this.$i18n.t('generics.publish'),
          apiStatus: 'online',
          activeBackgroundColor: '#3C80F7',
          backgroundColor: '#fff',
          textColor: '#333333',
          action: () => {}
        },
        {
          name: this.$i18n.t('generics.non-publish'),
          apiStatus: 'offline',
          activeBackgroundColor: '#e6525a',
          backgroundColor: '#fff',
          textColor: '#e6525a',
          action: () => {}
        }
      ];
    },
    settingsItems() {
      return [
        {
          name: this.$i18n.t('companies.account-management.edit-add'),
          activeBackgroundColor: '#3c80f6',
          backgroundColor: '#fff',
          action: this.goEditJob,
          textColor: '#333333'
        },
        {
          name: this.$i18n.t('companies.account-management.delete-add'),
          activeBackgroundColor: '#3c80f6',
          backgroundColor: '#fff',
          action: this.goDeleteJob,
          textColor: '#e6525a'
        }
      ];
    },
    linkText() {
      return  this.$i18n.t('companies.account-management.back-to-job');
    },
    huntLink() {
      return {
        name: this.$route.name,
        params: {
          id: this.profile._hunt?._id,
        },
        query: {
          ...this.$route.query,
          'company-id': this.profile._company?._id,
          coachId: this.$route.query.coachId,
          type: 'hunt',
          category: 'annonces'
        },
      };
    }
  },
  methods: {
    async updateJobStatus(status) {
      try {
        await updateJobStatus({
          id: this.profile._id,
          status: status.item.apiStatus
        });

        this.setActiveStatus(status);
      } catch (error) {
        this.$toast.show({
          type: 'error',
            message: error.message,
          duration: 4000
        });
      }
    },
    setActiveStatus(item) {
      this.$store.dispatch('updateValue', {
        storeName: 'panel',
        stateName: 'profile',
        data: {
          status: item.index + 2
        }
      });
      if (this.cardActive >= 0) {
        this.$store.dispatch('updateCard', {
          index: this.cardActive,
          content: { status: item.index + 2 }
        });
      }
    },
    goMatching() {
      this.$router.push({
        name: 'Coders',
        query: {
          page: 1,
          'job-id': encodeURIComponent(this.profile._id),
          'job-name': encodeURIComponent(this.profile.name),
          'company-name': encodeURIComponent((this.profile._company || {}).name)
        }
      });
    },
    goNewJob() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          type: 'create-job',
          category: undefined,
          'company-id': this.profile._company._id
        }
      });
    },
    goEditJob() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          type: 'edit-job',
          category: undefined
        }
      });
    },
    goDeleteJob() {
      this.displayModal = true;
    },
    closeModal() {
      this.displayModal = false;
    },
    async confirmJobDelete() {
      this.$store.dispatch('deleteJob', this.$route.params.id);

      try {
        if (this.profile.status === 2) {
          await updateJobStatus({
            id: this.profile._id,
            status: 'offline'
          });
        }
      } catch (error) {
        this.$toast.show({
          type: 'error',
          message: this.$t('toast.error-occured')
        });
      }

      this.closePanel();
    }
  },
  watch: {
    actionParameter(val) {
      this.updateJobStatus(val);
    },
  }
};
</script>

<style lang="scss" scoped>
.panel-job-header {
  display: flex;
  justify-content: space-between;
  position: relative;
  background: $color-white;
  padding: 10px 15px;
  width: 100%;
  border-bottom: 1px solid $color-gray-mystic;
  align-items: stretch;
  flex-shrink: 0;

  &__link {
    width: fit-content;
    color: $color-blue;
    font-size: $font-size-m;
    margin: 10px 5px 5px 65%;
    justify-content: center;
    align-items: center;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__nav {
    align-items: center;

    &:hover {
      a {
        cursor: pointer;
        color: rgb(51, 51, 51);
        box-shadow: transparent(0 8px 25px -8px);
        background: transparent;
        padding: 0px;
        overflow: hidden;
        transition: all 300ms ease .2s;
      }

      button {
        color: rgb(51, 51, 51)!important;
      }
    }
  }

  &__nav-button {
    margin-right: 7.5px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__nav-link {
    color: rgb(178, 178, 178);
    margin-left: 7.5px;
  }

  &__date {
    margin-left: 15px;
  }

  &__dropdown {
    padding: 10px 30px;
    border-radius: 4px;
    z-index: 1;
    @include bp('tablet') {
      min-width: 150px;
    }
  }

  &__settings {
    padding: 2.5px 5px;
    border-radius: 4px;
    z-index: 1;
  }

  &__button {
    margin-left: 10px;
  }

  &__list {
    margin-left: auto;
    @include bp('tablet') {
      margin-left: 20px;
      margin-right: auto;
    }
  }
}
</style>
