import { roleOptions } from '@/macros/coaches/roles';

export const getRoleObjectFromApiValue = apiValue => Object.values(roleOptions || []).find(role => role.api === apiValue);

export const getRoleNameFromApiValue = apiValue => (getRoleObjectFromApiValue(apiValue) || {}).name;

export default {
  getRoleNameFromApiValue,
  getRoleObjectFromApiValue,
};
