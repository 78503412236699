import useCases from '@/managers/posts/useCases';

const getCustomJobPosts = id => {
  return useCases.getCustomJobPosts(id);
};

const createCustomJobPost = ({ id, type, file }) => {
  return useCases.createCustomJobPost({ id, type, file });
};

const removeCustomJobPost = id => {
  return useCases.removeCustomJobPost(id);
};

export default {
  getCustomJobPosts,
  createCustomJobPost,
  removeCustomJobPost,
};
