<template>
  <modal-delete-item
    :only-content="true"
    :title="$t('generics.delete-company-warning')"
    @on-confirm="confirmDelete"
    @close="cancelDelete">
    <template #content>
      <p>{{ $t('generics.delete-company-warning-content') }}</p>
    </template>
  </modal-delete-item>
</template>

<script>
import ModalDeleteItem from '@/components/Modal/ModalDeleteItem';

/**
 * The modal to make the user confirm his action of deleting his account
 */
export default {
  name: 'panel-company-modal-delete',
  components: {
    ModalDeleteItem,
  },
  methods: {
    /**
     * The method to send the delete action to the API and changing the modal to the confirm-modal
     */
    confirmDelete() {
      this.$emit('confirm-delete');
    },
    cancelDelete() {
      this.$emit('cancel-delete');
    },
  },
};
</script>
