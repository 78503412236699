<template>
  <div
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
    @keydown.left.right.stop
    class="bc-input border-radius-s is-relative is-column is-justify-content-center">
    <div
      :class="{
        'bc-input__wrapper--hover' : isHover,
        'bc-input__wrapper--focus' : isFocus,
        'bc-input__wrapper--error': error,
        'bc-input__wrapper--success': valid,
        'bc-input__wrapper--disabled': $attrs.disabled,
        'bc-input__wrapper--prepend': $scopedSlots.prepend,
      }"
      @click.self="$refs.input.focus()"
      class="bc-input__wrapper border-radius-s is-relative is-align-items-center">
      <!-- @slot The slot for left content inside the input -->
      <slot
        :is-focus="isFocus"
        name="prepend">
      </slot>
      <label
        :class="{
          'bc-input__label--hover' : isHover,
          'bc-input__label--focus font-size-xs' : isFocus || value || error || !labelPlaceholder || $scopedSlots.prepend,
        }"
        :disabled="$attrs.disabled"
        :for="name"
        class="bc-input__label is-absolute font-size-m"
        v-if="$attrs.label">
        <i
          :class="[
            `icon-${icon}`,
            { 'font-size-xs': isFocus }
          ]"
          class="bc-input__label-icon is-flex is-align-items-center"
          v-if="icon">
        </i>
        {{ $attrs.label }}
        <span
          class="bc-input__label-required"
          v-if="required">
          *
        </span>
      </label>
      <div v-if="iconLeft" class="bc-input__icon-left is-absolute is-align-items-center">
        <i
          :class="`icon-${iconLeft}`"
          class="is-flex">
        </i>
      </div>
      <input
        v-show="!hideInput"
        :class="{
          'bc-input__input--label-placeholder': labelPlaceholder,
          'bc-input__input--icon-left': iconLeft,
        }"
        :id="name"
        :type="type"
        @blur="blur"
        @focus="focus"
        class="bc-input__input border-radius-s font-size-m"
        :style="inputStyle"
        ref="input"
        v-bind="$attrs"
        v-model="input">
      <div class="bc-input__infos is-absolute is-align-items-center">
        <transition name="fade">
          <bc-spinner v-if="loading"></bc-spinner>
        </transition>
        <i
          :class="{
            'icon-eye': !isPasswordVisible,
            'icon-eye-off': isPasswordVisible,
          }"
          @click="togglePasswordVisibility"
          class="bc-input__password is-flex"
          v-if="$attrs.type === 'password' && value">
        </i>
        <i
          :class="{
            'bc-input__validate--success icon-check': valid,
            'bc-input__validate--error icon-cross': error,
          }"
          @mouseup="setFocus"
          class="bc-input__validate is-flex ml-2"
          v-if="valid || error">
        </i>
      </div>
      <slot
        :is-focus="isFocus"
        name="append">
      </slot>
    </div>
    <p
      class="bc-input__error font-size-s"
      v-if="error && typeof error === 'string'">
      {{ error }}
    </p>
  </div>
</template>

<script>
  import BcSpinner from '../BcSpinner/BcSpinner';

  export default {
    name: 'bc-input',
    components: { BcSpinner },
    inheritAttrs: false,
    props: {
      /**
       * The value of the input
       */
      value: {
        type: [String, Date, Number],
        default: '',
      },
      /**
       * The name of the input
       */
      name: {
        type: String,
        required: true,
      },
      /**
       * The icon of the label
       */
      icon: {
        type: String,
        default: '',
      },
      /**
       * Icon left for the input
       */
       iconLeft: {
        type: String,
        default: '',
      },
      /**
       * Set the input as required
       */
      required: {
        type: Boolean,
        default: false,
      },
      /**
       * Set the input as valid
       */
      valid: {
        type: Boolean,
        default: false,
      },
      /**
       * Set the input as wrong and display the error message
       */
      error: {
        type: [String, Boolean],
        default: '',
      },
      /**
       * Display a loader in the input
       */
      loading: {
        type: Boolean,
        default: false,
      },
      /**
       * Set the label as placeholder
       */
      labelPlaceholder: {
        type: Boolean,
        default: true,
      },
      /**
       * Custom style for the input text
       */
      inputStyle: {
        type: [String, Object],
        default: '',
      },
      /**
       * Custom style for the input text
       */
       hideInput: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        isPasswordVisible: false,
        isFocus: false,
        isHover: false,
      };
    },
    computed: {
      input: {
        get() {
          return this.value;
        },
        set(value) {
          /**
           * v-model -> Emit the value of the input
           *
           * @event input
           * @type {string}
           */
          return this.$emit('input', value);
        },
      },
      type() {
        return this.$attrs.type === 'password' && this.isPasswordVisible ? 'text' : this.$attrs.type;
      },
    },
    methods: {
      togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible;

        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      },
      setFocus() {
        this.$refs.input.focus();
      },
      focus() {
        this.isFocus = true;

        /**
         * focus -> Emit the focus event
         *
         * @event focus
         */
        this.$emit('focus');
      },
      blur($event) {
        this.isFocus = false;

        /**
         * blur -> Emit the blur event
         *
         * @event blur
         */
        this.$emit('blur', $event.target.value);
      },
    },
  };
</script>

<style lang=scss scoped>
  .bc-input {
    margin-top: 15px;

    & * {
      cursor: text;
    }

    &__wrapper {
      border: 1px solid $color-blue-medium;
      color: $color-secondary-dark;
      background: white;

      &--hover {
        border-color: $color-primary;
        transition: 0.3s;

        .bc-input__input {
          &::placeholder {
            color: $color-blue-heavy-dark;
          }
        }
      }

      #input--company {
        width: 100%;
        padding: 10px 2px 10px 2px;
      }

      &--focus {
        border-color: $color-primary;
        background: rgba($color-primary, 0.05);

        &.bc-input__wrapper--success {
          border-color: $color-success;
          background: rgba($color-success, 0.05);
        }

        &.bc-input__wrapper--error {
          border-color: $color-error;
          background: rgba($color-error, 0.05);
        }
      }

      &--success {
        border-color: $color-blue-medium;
      }

      &--error {
        border-color: $color-error;
        background: rgba($color-error, 0.05);
      }

      &--disabled {
        pointer-events: none;
        background-color: $color-neutre-3;
        border-color: $color-blue-medium;


        .bc-input__label {
          color: $color-blue-medium;
        }
      }

      //&--prepend, &--append {
      //  flex-wrap: wrap;
      //}
    }

    &__label {
      color: $color-blue-heavy-dark;
      transition: font-size 0.3s, transform 0.3s;
      left: 20px;

      &--hover {
        color: $color-blue-heavy-dark;
      }

      &--focus {
        color: $color-blue-heavy-dark;
        transform: translate(-10px, -32.5px);
      }
    }

    &__label-icon {
      margin-right: 5px;
    }

    &__label-required {
      color: $color-error;
      margin-left: 5px;
    }

    &__input {
      flex-grow: 1;
      padding: 10px 30px 10px 10px;
      background: transparent;
      margin: 0 5px;
      color: $color-tertiary;
      max-height: 40px;
      width: 100%;

      &::placeholder {
        color: $color-blue-heavy-dark;
        font-weight: normal;
        font-size: $font-size-m;
        opacity: 1;
      }

      &:focus {
        color: $color-tertiary;
        outline: none;

        &::placeholder {
          opacity: 0;
        }
      }

      &:disabled {
        color: $color-tertiary;

        &::placeholder {
          opacity: 0.5;
        }
      }

      &--label-placeholder::placeholder {
        opacity: 0;
      }

      &--icon-left {
        padding-left: 40px;
      }
    }

    &__infos {
      right: 10px;
    }

    &__icon-left {
      left: 20px;
    }

    &__password {
      cursor: pointer;

      &:hover {
        color: $color-primary;
      }
    }

    &__validate {
      font-size: 12px;
      right: 10px;
      padding: 14px 0;

      &--error {
        color: $color-error;
      }

      &--success {
        color: $color-success;
      }
    }
    &__error {
      margin-top: 5px;
      color: $color-error;
    }
  }
</style>
