<template>
  <div class="custom-jobs-form-section">
    <title-section v-if="title" class="mb-4" position="left" :title="title" :icon="icon"></title-section>
    <slot></slot>
  </div>
</template>

<script>
import TitleSection from '@/components/Title/TitleSection';

export default {
  name: 'custom-jobs-form-section',
  components: {
    TitleSection,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-jobs-form-section {
  width: 100%;
  padding: 12px 20px;
  flex-direction: column;
}
</style>
