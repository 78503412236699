<template>
  <div class="notifications-list">
    <notifications-list-header
      :has-new="newNotificationsList.length > 0"
      @click="onMarkAsRead"
    ></notifications-list-header>
    <notifications-list-tabs></notifications-list-tabs>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NotificationsListHeader from './NotificationsListHeader';
import NotificationsListTabs from './NotificationsListTabs';

export default {
  name: 'notifications-list',
  components: {
    NotificationsListHeader,
    NotificationsListTabs,
  },
  computed: {
    ...mapGetters({
      notificationsList: 'mainNotifications/notificationsList',
    }),
    profileId() {
      return this.$store.state.user.profile._id;
    },
    newNotificationsList() {
      return this.notificationsList.filter(notif => notif.read === false);
    },
  },
  methods: {
    ...mapActions({
      markNotificationAsRead: 'mainNotifications/markNotificationAsRead',
    }),
    onMarkAsRead() {
      if (!this.newNotificationsList.length) {
        return
      }
      Promise.all(this.notificationsList.map(notif => this.markNotificationAsRead({ notificationId: notif.id, coachId: this.profileId })))
    },
  }
}
</script>

<style lang="scss" scoped>
.notifications-list {
  flex-direction: column;
  background: white;
  // width: 430px;
  min-height: 394px;
  border-radius: 5px;
  color: black;
}
</style>
