import { http } from '@/api/index';
import type { CreateLinkedinTemplateInput, EditLinkedinTemplateInput } from '@/domains/models/LinkedinTemplate';

export const getLinkedinTemplates = ({ coachId, all }: {
  coachId?: string,
  all?: boolean
}) => {
  return http.get<any[]>('linkedin/templates', {
    params: {
      coachId,
      all,
    },
  });
};

export const createLinkedinTemplate = (createTemplateInput: CreateLinkedinTemplateInput) => {
  return http.post<any>('/linkedin/templates', createTemplateInput);
};

export const editLinkedinTemplate = (id, editTemplateInput: EditLinkedinTemplateInput) => {
  return http.patch<any>(`/linkedin/templates/${id}`, editTemplateInput);
};

export const deleteLinkedinTemplate = ({ id }: { id: string }) => {
  return http.delete<{ message: string }>(`/linkedin/templates/${id}`);
};
