export const removeToken = () => {
  localStorage.removeItem('Token');

  // extension related
  window.dispatchEvent(new Event('mv-extension-storage'));
};

export default {
  removeToken,
};
