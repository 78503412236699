<template>
  <div class="organization-logo">
    <img
      v-bind="$attrs"
      :src="logoSrc"
      alt="organization logo"
      :class="{
        'organization-logo__custom-height': customLogo,
        'organization-logo__pipe': customLogo && isTopbarOpen,
      }" />
    <div v-if="customLogo && isTopbarOpen" class="organization-logo__custom">
      <p>Powered by</p>
      <img :src="marvinLogo" alt="marvin logo" />
    </div>
    <div v-if="customLogo && !isTopbarOpen" class="organization-logo__custom--simple">
      <img :src="marvinSimpleLogo" alt="marvin simple logo" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'organization-logo',
  props: {
    organizationId: {
      type: String,
    },
    isTopbarOpen: {
      type: Boolean,
    },
  },
  data() {
    return {
      marvinLogo: require('@/assets/logo/logo_marvin2.svg'),
      marvinSimpleLogo: require('@/assets/logo/marvin.png'),
      customLogos: {
        '636916925ec77d02747057e5': require('@/assets/logo/managerim_logo.png'),
      },
    };
  },
  computed: {
    customLogo() {
      return this.customLogos[this.organizationId];
    },
    logoSrc() {
      if (this.customLogo) {
        return this.customLogo;
      }
      return this.marvinLogo;
    },
  },
};
</script>

<style lang="scss" scoped>
.organization-logo {
  position: relative;

  & > img {
    height: 35.50px;
  }

  &__custom-height {
    height: 40px!important;
  }

  &__pipe {
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #23395b6d;
  }
  &__custom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > p {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: rgba(29, 57, 94, 0.3);
    }
    & > img {
      width: 59px;
    }

    &--simple {
      position: absolute;
      bottom: 1px;
      right: 1px;
      width: 17px;
      height: 17px;
      background: #ffffff;
      border-radius: 100px;

      & > img {
        width: 100%;
        padding: 2.5px;
        object-fit: contain;
        height: 32px;
      }
    }
  }
}
</style>
