<template>
  <div class="coders-empty is-full-width is-justify-content-center is-column is-align-items-center">
    <h3 class="coders-empty__title">{{ title }}</h3>
    <database-coders-button-create v-if="showCreateButton"></database-coders-button-create>
  </div>
</template>

<script>
import DatabaseCodersButtonCreate from '@/components/DatabaseCoders/DatabaseCodersButton/DatabaseCodersButtonCreate';

export default {
  name: 'coders-empty',
  components: { DatabaseCodersButtonCreate },
  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    showCreateButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.coders-empty {
  display: flex;
  width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $color-blue-dark-cello;
    margin-bottom: 15px;
  }
}
</style>
