import * as processesCustom from '@/api/processes-custom';

export const createProcessCustom = (process) => {
  try {
    return processesCustom.createProcessCustom(process);
  } catch (error) {
    if ((error || {}).response) {
      throw new Error(`API: ${error.response.data.message}`);
    }
    throw new Error(error);
  }
}
