import notes from '@/common-old/macros/notes';

export const getTypeNameFromApiValue = note => {
  const typeSelected = Object.values(notes.type).find(noteType => noteType.api === note.type);

  return typeSelected.name;
};

export const getMediaNameFromApiValue = note => {
  const references = Object.values(notes.media);

  return note.media.map(medium => references.find(m => m.api === medium).name);
};

export const deserializeNote = note => {
  const deserializeMap = {
    type: getTypeNameFromApiValue,
    media: getMediaNameFromApiValue,
  };

  Object.keys(deserializeMap).forEach(key => {
    if (note[key]) {
      note[key] = deserializeMap[key](note);
    }
  });

  return note;
};

export default {
  getTypeNameFromApiValue,
  deserializeNote,
};
