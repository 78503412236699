<template>
  <modal-feature
    feature="editProcessesB2C"
    :title="$t('features.create-custom-columns')"
    @close-modal="$emit('close-modal')"
  >
    <template #content>
      <p>{{ $t('features.add-processes-columns') }}</p>
    </template>
    <template #logo>
      <img src="@/assets/logo/feature-edit-processes-B2C.png" alt="edit processes b2c feature logo" />
    </template>
  </modal-feature>
</template>

<script>
import ModalFeature from './ModalFeature.vue';

export default {
  name: 'modal-feature-edit-processes-b2c',
  components: {
    ModalFeature,
  },
}
</script>
