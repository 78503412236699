<template>
  <div
    :class="{
      'process-card-coder--pending' : isPending,
      'process-card-coder--filtered' : isOld,
      'process-card-coder--failed': isFailed,
      'process-card-coder--warning': warningOnCard,
      'is-draggable process-card-coder--draggable': !isFailed && !isDealt && isAuthorized && !isAborted && isDraggable
    }"
    class="process-card-coder">
    <div
      v-if="isPending"
      class="process-card-coder__mask">
    </div>
    <p v-if="configurationFramework.showCompanies" class="process-card-coder__title body-m--medium">
      {{ (process._company || {}).name }} <process-card-source-logo :source="process.source" />
    </p>
    <p v-else class="process-card-coder__title body-m--medium">
      {{ (process._coder || {}).firstName }} {{ (process._coder || {}).lastName }} <process-card-source-logo :source="process.source" />
    </p>
    <router-link :to="opportunityUrl">
      <p
        class="process-card-coder__name">
        {{ jobName }} <process-card-source-logo v-if="!configurationFramework.showCompanies" :source="process.source" />
      </p>
    </router-link>
    <process-custom-card-tags :process="process"></process-custom-card-tags>
    <slot />
    <div class="is-primary font-size-xs is-flex is-align-items-center is-full-width">
      <p class="process-card-coder__date">
        {{ date }}
      </p>
      <span class="process-card-coder__divider"></span>
    </div>
    <div class="process-card-coder__icon icon-dragdrop"></div>
    <bc-avatar
      v-if="process._coach"
      v-tooltip="coachTooltip"
      :alt="(process._coach || {}).firstName"
      :src="(process._coach || {}).pictureUrl"
      :placeholder="getCoachInitials()"
      bc-avatar-radius="100%"
      size="xs"
      class="process-card-coder__avatar">
    </bc-avatar>
    <div
      style="position: absolute; bottom: 10px; right: 10px;"
      @mouseenter="$emit('on-options-hover', true)"
      @mouseleave="$emit('on-options-hover', false)"
    >
      <process-card-options
        :is-abort="process.abortedAt !== undefined"
        @on-action="$emit('on-action', $event)"
      ></process-card-options>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import ProcessCustomCardTags from '@/components/Process/ProcessCard/ProcessCustomCardTags';
  import ProcessCardOptions from '@/components/Process/ProcessCard/ProcessCardOptions';
  import ProcessCardSourceLogo from '@/components/Processes/ProcessCardSourceLogo';
  import processCard from '@/mixins/Processes/processCard';
  import { apiStatus } from '@/macros/processes/process';
  import { bluecodersCompanyId } from '@/macros/utils';

  export default {
    name: 'process-card-coder',
    components: {
      BcAvatar,
      ProcessCustomCardTags,
      ProcessCardSourceLogo,
      ProcessCardOptions,
    },
    mixins: [processCard],
    props: {
      isDraggable: {
        type: Boolean,
        default: true,
      }
    },
    computed: {
      ...mapGetters({
        configurationFramework: 'user/configurationFramework',
      }),
      isSuperUser() {
        return this.$store.getters['user/isSuperUser'];
      },
      warningOnCard() {
        return this.isSuperUser && !(this.process.formDeal || {}).sentAt &&
          this.process.status === apiStatus.dealt &&
          !this.process.failedAt &&
          this.process?._company?._id !== bluecodersCompanyId;
      },
      jobName() {
        return this.process._customJob?.title || '';
      },
      coachTooltip() {
        return {
          content: `${this.process._coach?.firstName} ${this.process._coach?.lastName}`,
          classes: 'tooltip-pre',
        }
      },
      opportunityUrl() {
        if (this.process?._customJob?._id) {
          return {
            ...this.$route,
            params: {
              id: this.process._customJob._id
            },
            query: {
              ...this.$route.query,
              type: 'hunt',
            },
          }
        } else {
          return {
            ...this.$route,
            params: {
              id: this.process._company._id
            },
            query: {
              ...this.$route.query,
              type: 'companies',
            },
          }
        }
      },
    },
    methods: {
      getCoachInitials() {
        return `${(this.process._coach || {}).firstName?.charAt(0)}${(
          this.process._coach || {}
        ).lastName?.charAt(0)}`.trim();
      },
    }
  };
</script>

<style lang="scss" scoped>
  .process-card-coder {
    flex-direction: column;
    background: $color-white;
    padding: 15px;
    border-radius: $border-radius-m;
    position: relative;
    align-items: flex-start;

    &--pending {
      border: 1px solid $color-primary;
    }

    &--filtered {
      border: 1px solid rgba($color-secondary, 0.3);
      background: transparent;
    }

    &--warning {
      border: 1px solid $color-warning;
    }

    &--draggable {
      &:hover {
        .process-card-coder__icon {
          opacity: 1;
        }
      }
    }

    &__mask {
      animation-name: pending;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      border-radius: $border-radius-m;
    }

    &__title {
      color: $color-secondary-light;
      margin-bottom: 2px;
    }

    &__name {
      display: flex;
      font-size: $font-size-s;
      color: $color-primary;
      line-height: 17px;

      &:hover {
        color: $color-primary-hover;
      }
    }


    &__date {
      color: $color-primary;
      font-size: $font-size-xs;
      width: 150%;
      line-height: 17px;
      margin-top: 8px;
      flex-grow: 1;
    }

    &__avatar {
      position: absolute;
      right: 10px;
      top: 15px;
      overflow: hidden;
    }

    &__icon {
      transition: 0.3s;
      color: rgba($color-secondary, 0.5);
      position: absolute;
      right: 10px;
      bottom: 15px;
      opacity: 0;
    }

    &__divider {
      line-height: 1em;
      position: relative;
      outline: 0;
      border: 0;
      width: 80%;
      align-items: center;
      margin-left: 5px;
      margin-right: 20px;
      margin-top: 8px;

      &:before {
        content: '';
        background: $color-primary;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
      }
    }
  }

  @keyframes pending {
    0% {
      background: rgba($color-primary, 0.2);
    }
    50% {
      background: rgba($color-primary, 0.05);
    }
    100% {
      background: rgba($color-primary, 0.2);
    }
  }
</style>
