<template>
  <div class="panel-caption-contacts">
    <div
      :key="index"
      class="panel-caption-contacts__wrapper"
      v-for="(contact, index) in computedContacts">

      <panel-caption-contact-item
        v-if="contact.field === 'phone' && isMounted"
        :item="contact"
        :list="contact.others"
        @on-delete="onDeleteContactItem(contact.field, $event)"
      ></panel-caption-contact-item>

      <panel-caption-contact-item
        v-else-if="contact.field === 'email' && isMounted"
        :item="contact"
        :list="contact.others"
        @on-delete="onDeleteContactItem(contact.field, $event)"
      ></panel-caption-contact-item>

      <div
        v-else-if="contact.type === 'tooltip' || contact.type === 'copy'"
        class="panel-caption-contacts__icons-wrapper"
        v-clipboard:copy="contact.data"
        v-clipboard:success="setIsCopiedState"
        v-tooltip.bottom="{
          content: contact.data,
          hideOnTargetClick: false
        }">
        <div
          :class="[
            `icon-${contact.icon}`,
            { 'panel-caption-contacts__icons--activated': contact.data }
          ]"
          class="panel-caption-contacts__icons">
        </div>
      </div>
      <a
        :href="formatUrl(contact.data)"
        class="panel-caption-contacts__icons-wrapper"
        target="_blank"
        v-else-if="contact.type === 'link'">
        <div
          :class="[`icon-${contact.icon}`, { 'panel-caption-contacts__icons--activated': contact.data }]"
          class="panel-caption-contacts__icons">
        </div>
      </a>
      <a
        :href="formatUrl(contact.data)"
        class="panel-caption-contacts__icons-wrapper"
        target="_blank"
        v-else-if="contact.type === 'link-png'"
        v-tooltip.bottom="{
          content: tooltips[contact.icon],
          hideOnTargetClick: false
        }" >
        <img
          :src="sourceImg(contact.icon)"
          :alt="'Quick link to ' + contact.icon"

          :class="[{ 'panel-caption-contacts__icons-png--activated': contact.data }]"
          class="panel-caption-contacts__icons-png" />
      </a>
    </div>
  </div>
</template>

<script>
  import { updateCoderProfile } from '@/api/coders';
  import { updateContact } from '@/api/contacts';
  import PanelCaptionContactItem from './PanelCaptionContactItem';

  const linksLogos = {
    'gmail': require('@/assets/logo/gmail.png'),
    'google-calendar': require('@/assets/logo/google-calendar.png'),
    'outlook-calendar': require('@/assets/logo/outlook_calendar.png'),
    'linkedin': require('@/assets/logo/logo_linkedin.svg'),
    'whatsapp': require('@/assets/logo/whatsapp.png'),
    'welcome': require('@/assets/logo/wttj.png'),
    'outlook': require('@/assets/logo/outlook_messaging.png'),
  }

  export default {
    name: 'panel-caption-contacts',
    components: {
      PanelCaptionContactItem,
    },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
      contacts: {
        type: Array,
        default: () => [],
      },
      isContact: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      setTimeout(() => {
        this.isMounted = true;
      }, 1000)
    },
    data() {
      return {
        isMounted: false,
        icons: this.contacts.map(() => ({ isCopied: false })),
        tooltips: {
          'gmail': this.$t('coder.quicklinks.gmail'),
          'google-calendar': this.$t('coder.quicklinks.google-calendar'),
          'outlook-calendar': this.$t('coder.quicklinks.outlook-calendar'),
          'linkedin': this.$t('coder.quicklinks.linkedin'),
          'whatsapp': this.$t('coder.quicklinks.whatsapp'),
          'outlook': this.$t('coder.quicklinks.outlook'),
        },
        tooltipShow: {
          phone: false,
          email: false,
        },
        deleted: {},
      };
    },
    computed: {
      computedContacts() {
        return this.contacts.map((contact, index) => {
          const others = contact.others || [];
          return {
            ...contact,
            data: this.icons[index].isCopied ? this.$t('generics.copied') : contact.data,
            others: others.filter(o =>
              !this.deleted[contact.field] || (this.deleted[contact.field] && !this.deleted[contact.field].includes(o))
            ),
          }
        });
      },
    },
    methods: {
      formatUrl(url) {
        if (url) {
          return url.indexOf('http') > -1 ? url : `https://${url}`;
        }
      },
      setIsCopiedState(e) {
        const index = this.contacts.findIndex(contact => contact.data === e.text);

        this.icons[index].isCopied = true;

        setTimeout(() => {
          this.icons[index].isCopied = false;
        }, 1000);
      },
      sourceImg(logoName) {
        return linksLogos[logoName];
      },
      async onDeleteContactItem(field, value) {
        try {
          const fields = `${field}s`;

          if (this.isContact) {
            await updateContact({
              id: this.profile._id,
              obj: {
                [`${fields}`]: this.profile[fields].filter(f => f !== value),
              },
            });
          } else {
            await updateCoderProfile({
              id: this.profile._id,
              updatedObj: {
                [`${fields}`]: this.profile[fields].filter(f => f !== value),
              },
            });
          }

          this.deleted = {
            ...this.deleted,
            [field]: this.deleted[field] ? [...this.deleted[field], value] : [value],
          }
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err.message,
          })
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-caption-contacts {
    $this: &;
    cursor: pointer;

    &__wrapper {
      position: relative;
      margin-right: 2px;
    }

    &__icons {
      font-size: $font-size-l;
      padding: 5px;
      border-radius: 5px;
      color: rgba($color-blue-dark-cello, 0.3);
      cursor: unset;

      &--activated {
        color: $color-primary;

        &:hover {
          background-color: rgba($color-primary, 0.15);
        }
      }
    }

    &__icons-png {
      width: 28px;
      height: 28px;
      padding: 5px;
      vertical-align: middle;
      border-radius: 5px;

      // if disabled
      opacity: 0.5;
      cursor: default;

      &--activated {
        opacity: 1.0;
        cursor: pointer;

        &:hover {
          background-color: rgba($color-primary, 0.15);
        }
      }
    }

    &__tooltip {
      top: calc(100% + 10px);
      transform: translateX(calc(-50% + 8.5px));
    }

    &__tooltip-text {
      white-space: nowrap;
    }
  }
</style>
