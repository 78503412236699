<template>
  <modal-delete-item
    :only-content="true"
    :title="$t('generics.delete-company-warning')"
    @on-confirm="deleteProcess"
    @close="cancelProcessDelete">
    <template #content>
      <p>{{ $t('generics.delete-company-warning-content') }}</p>
    </template>
  </modal-delete-item>
</template>

<script>
import ModalDeleteItem from '@/components/Modal/ModalDeleteItem';

export default {
  name: 'modal-process-delete-confirmation',
  components: {
    ModalDeleteItem,
  },
  methods: {
    deleteProcess() {
      this.$emit('delete-process');
    },
    cancelProcessDelete() {
      this.$emit('close-modal');
    },
  },
};
</script>
