<template>
  <div class="custom-jobs-card-stat" :class="{ 'custom-jobs-card-stat__border': withBorder }">
    <div class="custom-jobs-card-stat__icon-container">
      <slot v-if="$slots.icon" name="icon"></slot>
      <div v-else>
        <i :class="`icon-${icon}`"></i>
      </div>
    </div>
    <div class="custom-jobs-card-stat__label">
      <slot v-if="$slots.content" name="content"></slot>
      <div v-else class="custom-jobs-card-stat__label--content">
        <p><b class="custom-jobs-card-stat__count">{{ count }}</b> {{ label }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'custom-jobs-card-stat',
  props: {
    icon: {
      type: String,
    },
    count: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
    },
    withBorder: {
      type: Boolean,
      default: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.custom-jobs-card-stat {

  &__border {
    border-left: 1px solid $color-blue-medium;
  }


  &__icon-container {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: white;
    box-shadow: 0px 4px 30px 0px rgba(29, 57, 94, 0.10);
    align-items: center;
    justify-content: center;
  }

  &__count {
    font-weight: $font-weight-bold;
  }

  &__label {
    margin-left: 10px;

    &--content {
      max-width: 110px;
      word-wrap: break-word;
      text-align: left;
      font-size: 14px;
    }
  }

  i {
    font-size: $font-size-xs;
    color: $color-primary;
  }
}
</style>
