import { createTag } from '@/managers/tags/useCases/createTag';
import { editTag } from '@/managers/tags/useCases/editTag';
import { deleteTag } from '@/managers/tags/useCases/deleteTag';

const create = ({ type, name }) => {
  return createTag({ type, name });
};

const edit = ({ id, name }) => {
  return editTag({ id, name });
};

const remove = id => {
  return deleteTag(id);
};

export const tagController = {
  create,
  edit,
  remove,
};

export default {
  tagController,
};
