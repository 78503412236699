<template>
  <div
    v-if="icon"
    class="icon-labeled">
    <div
      class="icon-labeled__icon"
      :class="`icon-${icon}`"
      :style="{ 'color': iconColor, fontSize: iconSize }">
    </div>
    <p
      v-if="value && !isClickable"
      class="icon-labeled__label"
      :style="{ 'color': color, fontSize: labelFontSize}">
      {{ $t(value) }}{{ label }}
    </p>
    <a
      :href="link"
      target="_blank"
      v-if="value && isClickable"
      class="icon-labeled__label icon-labeled__label--blue"
      :style="{ 'color': color }">
      {{ value }}{{ label }}
    </a>
  </div>
</template>

<script>
  // TODO Renommer en icon-default ou label-default ?
  export default {
    name: 'icon-labeled',
    props: {
      /**
       * Define the icon
       * Refer to icon library to see possibilities
       */
      icon: {
        type: String
      },
      /**
       * Define the value which will be next to the icon
       */
      value: {
        type: [String, Number]
      },
      /**
       * Define the label which will be next to the value
       */
      label: {
        type: [String, Number],
        default: ''
      },
      /**
       * Define the color of the text
       */
      color: {
        type: String,
      },
      /**
       * Define the color of the icon
       */
      iconColor: {
        type: String,
      },
      isClickable: {
        type: Boolean,
        default: false,
      },
      link: {
        type: String,
        required: false
      },
      iconSize: {
        type: String,
        default: '19px'
      },
      labelFontSize: {
        type: String,
        default: '16px'
      }
    }
  };
</script>

<style scoped lang="scss">
  .icon-labeled {
    align-items: center;

    flex-shrink: 0;
    padding: 2px 10px;
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    border-radius: $border-radius-l;
    background: $color-blue-light;

    &__icon {
      color: $color-blue-dodger;
      margin-right: 10px;
    }
    &__label {
      line-height: 15px;
      text-align: left;
      align-items: center;
      color: $color-blue-dark-cello;
      &--blue {
        color: $color-blue-dodger;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
</style>
