<template>
  <div class="panel-caption-name">
    <div class="panel-caption-name__before">
      <p class="panel-caption-name__before-text">
        {{ beforeSeparator }}
      </p>
      <div
        class="panel-caption-name__wrapper"
        v-if="remote || contracts || singleContract">
        <div
          class="panel-caption-name__remote"
          slot="icon"
          v-if="(remote || []).length > 0">
          <div
            class="panel-caption-name__icon icon-remote-available"
            v-if="(remote || []).some(value => value === remoteApiKeys.available)"
            v-tooltip.top="$i18n.t('panel-coder-edit.possible-remote')">
          </div>

          <div
            class="panel-caption-name__icon icon-remote-partial"
            v-if="(remote || []).some(value => value === remoteApiKeys.partial)"
            v-tooltip.top="$i18n.t('panel-coder-edit.partial-remote')">
          </div>

          <div
            class="panel-caption-name__icon icon-remote-full"
            v-if="(remote || []).some(value => value === remoteApiKeys.full)"
            v-tooltip.top="$i18n.t('panel-coder-edit.full-remote')">
          </div>
        </div>
        <div
          class="panel-caption-name__contract-container"
          v-if="contracts">
          <div
            :key="index"
            :style="{ backgroundColor: getCoderField('contracts', 'color', contract) }"
            class="panel-caption-name__contract"
            v-for="(contract, index) in contracts">
            <p class="panel-caption-name__contract-text">
              {{ getCoderField('contracts', 'name', contract) }}
            </p>
          </div>
        </div>
        <div
          :style="{ backgroundColor: getJobField('contracts', 'color', singleContract)}"
          class="panel-caption-name__contract"
          v-if="singleContract">
          <p class="panel-caption-name__contract-text">
            {{ $t(getJobField('contracts', 'name', singleContract)) }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="panel-caption-name__links"
      v-if="afterSeparator">
      <div
        :class="{'panel-caption-name__after--hover': link}"
        @click="link"
        class="panel-caption-name__after"
        v-if="link">
        {{ afterSeparator }}
      </div>
      <div
        :class="{'panel-caption-name__after--hover': link}"
        class="panel-caption-name__after"
        v-else>
        {{ afterSeparator }}
      </div>
    </div>
    <div
      class="panel-caption-name__buttons"
      v-if="buttons">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  // TODO move or refacto to ui-kit
  import { mapState } from 'vuex';
  import { apiRemote } from '@/common-old/macros/remote';
  // Helpers
  import CoderHelpers from '@/common-old/mixins/Coders/coderHelpers';
  import JobHelpers from '@/common-old/mixins/Jobs/jobHelpers';
  // Components
  export default {
    name: 'panel-caption-name',
    mixins: [CoderHelpers, JobHelpers],
    props: {
      /**
       * This is what is displayed before the separator
       */
      beforeSeparator: String,
      /**
       * This is what is displayed after the separator
       */
      afterSeparator: String,
      /**
       * If it exists, the link after the separator will be clickable
       */
      link: [String, Function],
      /**
       * If it exists, there will be an other separator to display the social buttons
       */
      buttons: Boolean,
      remote: {
        type: [Array, Boolean],
      },
    },
    data() {
      return {
        tooltipIsDisplayed: false,
        remoteApiKeys: {
          available: apiRemote.available,
          partial: apiRemote.partial,
          full: apiRemote.full,
        },
      };
    },
    computed: {
      ...mapState({
        contracts: state => state.panel.profile.contracts,
        singleContract: state => state.panel.profile.contract,
      }),
    },
    methods: {
      setTooltipIsDisplayed(val) {
        this.tooltipIsDisplayed = val;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-caption-name {
    align-items: center;
    flex-direction: column;
    @include bp('tablet') {
      align-items: flex-start;
    }

    &__before {
      font-size: $font-size-s;
      line-height: 18px;
      font-weight: $font-weight-medium;
      color: $color-black;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;

      @include bp('tablet') {
        line-height: 24px;
        justify-content: flex-start;
        flex-direction: row;
        margin-bottom: 0;
      }
    }

    &__before-text {
      align-items: center;
      margin-right: 10px;
    }

    &__after {
      font-size: $font-size-s;
      color: $color-blue;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      @include bp('tablet') {
        justify-content: flex-start;
        text-align: left;
      }

      &--hover {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &__buttons {
      margin-top: 10px;
      @include bp('tablet') {
        margin-top: 0;
      }
    }

    &__links {
      justify-content: center;
      margin-top: 10px;
      @include bp('tablet') {
        justify-content: flex-start;
        margin-top: 0;
      }
    }

    &__contract {
      width: fit-content;
      height: 24px;
      justify-content: center;
      align-items: center;
      padding: 5px;
      border-radius: 4px;
      margin-right: 5px;

      &-container {
        padding-left: 10px;
        @include bp('tablet') {
          padding: 0;
        }
      }
    }

    &__contract-text {
      font-size: $font-size-xxs;
      line-height: 14px;
      color: $color-white;
    }

    &__remote-container {
      position: relative;
    }

    &__tooltip-container {
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
      width: 166px;
      height: 30px;
      z-index: 2;
    }

    &__wrapper {
      margin: 10px 0 0;
      align-items: center;
      @include bp('tablet') {
        margin: 0;
      }
    }

    &__icon {
      @include font-size(15px);
      height: 30px;
      width: 30px;
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      background: $color-blue-hawkes;
      color: $color-blue-cello;
      margin-left: -5px;
      border: 1px solid $color-white;

      &:first-of-type {
        margin-left: 0;
      }
    }

    &__remote {
      margin-left: 10px;
    }
  }

  .tooltip {
    &-wrapper {
      flex-direction: column;
      align-items: flex-start;
      font-size: $font-size-xxs;
      line-height: 14px;
    }

    &-text {
      text-align: center;
    }
  }

  .icon-home {
    font-size: $font-size-l;
    color: $color-blue-dodger;
    @include bp('tablet') {
      margin-right: 10px;
    }
  }
</style>
