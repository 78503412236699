<template>
  <views class="home">
    <component :is="page"></component>
    <router-view></router-view>
  </views>
</template>
<script>
  import HomeStatistics from '@/components/Home/HomeStatistics';
  import FilterTab from '@/components/Filter/FilterTab';
  import moment from 'moment/min/moment-with-locales';
  import Views from '@/components/Views/Views';

  export default {
    name: 'home',
    components: {
      Views,
      FilterTab,
      performances: HomeStatistics,
    },
    computed: {
      title() {
        return 'Performances';
      },
      page() {
        return this.$route.query.page || 'performances';
      },
    },
    beforeRouteEnter(to, from, next) {
      if ((!to.query.page || to.query.page === 'performances') && !to.query['start-date'] && !to.query['end-date']) {
        next({
          path: to.path,
          query: {
            ...to.query,
            ...{
              period: 'week',
              'start-date': moment().startOf('week').format('YYYY-MM-DD'),
              'end-date': moment().endOf('week').format('YYYY-MM-DD'),
            },
          },
        });
      } else {
        next();
      }
    },
  };
</script>
<style lang="scss" scoped>
  .home {
    &__title {
      align-self: center;
      margin-top: 50px;
      margin-bottom: 20px;
    }

    &__filters {
      margin-bottom: 50px;
    }

    &__tab {
      margin: 0 10px;
    }
  }
</style>
