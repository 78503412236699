import { apiContracts } from '@/common-old/macros/contracts';

export const apiContract = {
  cdi: 1,
  freelance: 2,
  stage: 3,
  alternance: 4,
  cdd: 5,
  consultant: 6,
  interim: 7,
  intermittent: 8,
};

export const contractOptions = {
  cdi: {
    api: apiContracts.cdi,
    name: 'CDI',
    query: 'cdi',
  },
  freelance: {
    api: apiContracts.freelance,
    name: 'Freelance',
    query: 'freelance',
  },
  stage: {
    api: apiContracts.stage,
    name: 'Stage',
    query: 'stage',
  },
  alternance: {
    api: apiContracts.alternance,
    name: 'Alternance',
    query: 'alternance',
  },
};

export default {
  apiContract,
  contractOptions,
};
