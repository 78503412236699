import { http } from '@/api/index';

export const getRefreshRemindersStatus = () => {
  return http.get('/refresh-pool');
};


export const requestRefreshReminders = () => {
  return http.post('/refresh-pool');
};


export default {
  getRefreshRemindersStatus,
  requestRefreshReminders,
};
