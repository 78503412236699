<script setup lang="ts">
import { computed } from 'vue';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
import { defineProps, defineEmits } from 'vue';
import Tag from '@/components/Tag/Tag.vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { setAlternativeImg } from '@/mixins/imgFallback';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox.vue';

import PhoneNumber from 'awesome-phonenumber';

type Props = {
  candidate: any;
  isSelected?: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'toggle-selection'): void;
}>();

const route = useRoute();
const router = useRouter();

const highlightResult = computed(() => props.candidate?._highlightResult || props.candidate);
const snippetResult = computed(() => props.candidate?._snippetResult || props.candidate);
const firstName = computed(
  () => highlightResult?.value?.firstName?.value || highlightResult?.value?.firstName,
);
const lastName = computed(
  () => highlightResult?.value?.lastName?.value || highlightResult?.value?.lastName,
);
const name = computed(() => `${firstName.value} ${lastName.value}`);
const pictureUrl = computed(() => props.candidate?.pictureUrl ?? '');

const title = computed(() => highlightResult?.value?.title?.value || highlightResult?.value?.title);

const hasMatching = (text: string) => text?.includes('<mark>');

const decodeHtml = (html: string) => {
  if (!html) return '';
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

const rawSkills = computed(
  () => highlightResult?.value?.skills?.map((skill: any) => decodeHtml(skill.value || skill)) ?? [],
);

const skills = computed(() =>
  [...(rawSkills.value || [])].sort(
    (a: string, b: string) => Number(hasMatching(b)) - Number(hasMatching(a)),
  ),
);

const rawProfessions = computed(
  () =>
    highlightResult?.value?.professions?.map((profession: any) =>
      decodeHtml(profession.value || profession),
    ) ?? [],
);

const professions = computed(() =>
  [...(rawProfessions.value || [])].sort(
    (a: string, b: string) => Number(hasMatching(b)) - Number(hasMatching(a)),
  ),
);

const location = computed(() => highlightResult?.value?.city?.value?.trim() ?? '');
const notesHighlightResult = computed(
  () => snippetResult?.value?.notes?.map(note => note?.content || note) || [],
);
const rawNotes = computed(
  () => notesHighlightResult?.value?.map((note: any) => decodeHtml(note?.value || note)) ?? [],
);
const notes = computed(() =>
  [...(rawNotes.value || [])]
    .filter((note: string) => hasMatching(note))
    .sort((a: string, b: string) => Number(hasMatching(b)) - Number(hasMatching(a))),
);

const rawDocumentsTexts = computed(
  () =>
    snippetResult?.value?.documentTexts
      ?.filter(
        (documentText: any) =>
          (documentText?.text?.value ?? documentText?.text) &&
          hasMatching(decodeHtml(documentText?.text?.value ?? documentText?.text)),
      )
      ?.map((documentText: any) => decodeHtml(documentText?.text?.value ?? documentText?.text)) ??
    [],
);

const rawExperiences = computed(
  () =>
    snippetResult?.value?.experiences
      ?.filter((exp: any) => {
        const description = exp?.description?.value ?? exp?.description;
        const title = exp?.title?.value ?? exp?.title;
        return (
          (description && hasMatching(decodeHtml(description))) ||
          (title && hasMatching(decodeHtml(title)))
        );
      })
      ?.map((exp: any) => ({
        title: decodeHtml(exp?.title?.value ?? exp?.title),
        description: decodeHtml(exp?.description?.value ?? exp?.description),
      })) ?? [],
);

const shouldDisplayDocumentsTexts = computed(
  () =>
    (rawDocumentsTexts?.value?.length &&
      snippetResult?.value?.documentTexts?.some(
        document => document?.text?.matchLevel !== 'none',
      )) ||
    rawExperiences.value?.length > 0,
);

const documentsTexts = computed(() =>
  [
    ...(rawDocumentsTexts.value || []),
    ...(rawExperiences.value || []).map(
      exp => `${exp.title ? `${exp.title}` : ''}${exp.description}`,
    ),
  ].sort((a: string, b: string) => Number(hasMatching(b)) - Number(hasMatching(a))),
);

const link = computed(
  () =>
    router.resolve({
      ...route,
      name: `${route.name}Panel`,
      params: { id: props.candidate?.objectID },
      query: {
        ...route.query,
        type: 'coders',
      },
    }).href,
);

const defaultAvatar = new URL('@/assets/img/default-avatar.svg', import.meta.url).href;

const phone = computed(() => {
  const phoneData = highlightResult.value?.phone;
  if (!phoneData) return '';

  // 1. Chercher d'abord les matches complets dans l'ordre de priorité
  const fullMatchPriority = ['international', 'national', 'raw', 'e164', 'significant', 'searchable'];
  for (const format of fullMatchPriority) {
    if (phoneData[format]?.matchLevel === 'full') {
      return phoneData[format].value;
    }
  }

  // 2. Chercher ensuite les matches partiels dans le même ordre
  for (const format of fullMatchPriority) {
    if (phoneData[format]?.matchLevel === 'partial') {
      return phoneData[format].value;
    }
  }

  // 3. Par défaut, retourner le format international
  return phoneData.international?.value || '';
});

const email = computed(() =>
  highlightResult.value?.email?.value ||
    highlightResult.value?.emailPro?.value ||
    highlightResult.value?.email,
);
</script>

<template>
  <router-link
    :to="link"
    class="algolia-cards-candidate flex w-full flex-col px-[30px] py-[15px] text-blue-800 bg-neutral-100 border border-blue-200 rounded-lg">
    <div class="flex gap-[20px]">
      <div class="h-[30px] items-center flex">
        <BcCheckbox
          :id="candidate.objectID"
          @click.native.stop
          @input="emit('toggle-selection')"
          :value="isSelected" />
      </div>
      <div class="flex flex-col gap-2.5 justify-center">
        <div class="flex items-center gap-2.5 flex-wrap text-blue-800">
          <BcAvatar
            @error="setAlternativeImg({ event: $event, type: 'coders' })"
            class="w-[30px] h-[30px]"
            :src="pictureUrl || defaultAvatar" />
          <p class="font-bold shrink-0" v-html="decodeHtml(name)" />
          <p class="shrink-0" v-html="decodeHtml(title)" />
          <Tag v-if="phone" icon="phone" type="phone">
            <span v-html="decodeHtml(phone)" />
          </Tag>
          <Tag v-if="email" icon="mail" type="email">
            <span v-html="decodeHtml(email)" />
          </Tag>
          <Tag v-if="location" icon="map-pin" type="location">
            <span v-html="decodeHtml(location)" />
          </Tag>
          <Tag
            v-for="(profession, index) in professions?.slice(0, 5)"
            :key="`${profession}-${index}-${Math.random()}`"
            icon="award"
            type="professions">
            <span v-html="decodeHtml(profession)" />
          </Tag>
          <Tag
            v-if="professions.length > 5"
            v-tooltip.bottom="{
              content: professions
                ?.slice(5)
                .map(profession => profession)
                .join(', '),
            }"
            :text="`+${professions?.slice(5).length}`"
            icon="award"
            type="professions" />
          <Tag
            v-for="(skill, index) in skills.slice(0, 5)"
            :key="`skill-${index}-${Math.random()}`"
            :text="skill.name"
            icon="tool"
            type="skills">
            <span v-html="decodeHtml(skill)" />
          </Tag>
          <Tag
            v-if="skills.length > 5"
            v-tooltip.bottom="{
              content: skills
                ?.slice(5)
                .map(skill => skill)
                .join(', '),
              autoHide: false,
            }"
            :text="`+${skills?.slice(5).length}`"
            icon="tool"
            type="skills" />
        </div>
        <div v-if="notes.length" class="flex text-blue-800 gap-2.5 w-full">
          <i class="icon-pen-square text-[16px]"></i>
          <div class="[display:-webkit-box] [-webkit-box-orient:vertical] grow">
            <p v-for="(note, index) in notes" :key="`${index}-${Math.random()}`" v-html="note" />
          </div>
        </div>
        <div v-if="shouldDisplayDocumentsTexts" class="flex text-blue-800 gap-2.5 w-full">
          <p class="text-blue-500 font-medium">CV</p>
          <div class="line-clamp-3 [display:-webkit-box] [-webkit-box-orient:vertical] grow">
            <p
              v-for="(documentText, index) in documentsTexts"
              :key="`${index}-${Math.random()}`"
              v-html="documentText" />
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
.algolia-cards-candidate {
  &:deep(mark) {
    @apply bg-orange-100 text-orange-500 not-italic;
  }
}
</style>
