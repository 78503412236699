<template>
  <div class="timeline-item-wrapper">
    <timeline-item-icon
      :action="action"
      class="timeline-item-wrapper__icon">
    </timeline-item-icon>
    <slot></slot>
  </div>
</template>

<script>
  import TimelineItemIcon from '@/components/Timeline/TimelineItem/TimelineItemIcon';

  export default {
    name: 'timeline-item-wrapper',
    components: {
      TimelineItemIcon,
    },
    props: {
      action: {
        type: Object,
        default: () => ({
          type: '',
        }),
      },
    },
  };
</script>

<style lang="scss" scoped>
  .timeline-item-wrapper {
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    @include bp('tablet') {
      flex-direction: row;
      align-items: flex-start;
    }

    &:last-of-type {
      &:before {
        height: calc(100% - 30px);
        @include bp('tablet') {
          height: calc(100% - 50px);
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 4px;
      margin: 0 auto;
      height: calc(100% + 30px);
      left: calc(50% - 1px);
      background: $color-blue-medium;
      z-index: -1;
      @include bp('tablet') {
        height: calc(100% - 45px);
        top: 45px;
        left: 15.5px;
        right: auto;
        z-index: initial;
      }
    }

    &__icon {
      z-index: 1;
      margin-bottom: 10px;
      @include bp('tablet') {
        margin-bottom: 0;
        margin-right: 20px;
      }
    }

    &:deep(.timeline-item-icon) {
      background: #2A67BC;
      color: white;
    }
  }
</style>
