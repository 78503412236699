<template>
  <div class="processes-billing is-column">
    <p class="processes-billing__text">
      {{ $t('candidates.billing-information') }}:
    </p>
    <div class="processes-billing__body">
      <div class="processes-billing__body-content is-column">
        <ValidationProvider
          class="processes-billing__input-number-container"
          :rules="{ required: true }"
          name="percent"
          v-slot="{ errors }">
          <bc-input-number
            :disabled="isSent"
            :error="errors[0]"
            :label-placeholder="false"
            :min="0"
            :required="true"
            :should-display-incrementer="false"
            :valid="true"
            @blur="autoSaveBilling('percent', $event)"
            class="processes-billing__input-number"
            data-percent
            :label="$t('candidates.in-effect')"
            name="% en vigueur"
            placeholder="Ex: 20.5"
            unity="%"
            v-model="percent">
          </bc-input-number>
        </ValidationProvider>
        <ValidationProvider
          class="processes-billing__input-number-container"
          :rules="{ required: true }"
          name="amount"
          v-slot="{ errors }">
          <bc-input-number
            :disabled="isSent"
            :error="errors[0]"
            :label-placeholder="false"
            :min="0"
            :required="true"
            :should-display-incrementer="false"
            :valid="true"
            @blur="autoSaveBilling('amount', $event)"
            class="processes-billing__input-number processes-billing__input-number--large"
            data-amount
            :label="$t('candidates.deal-amount')"
            name="Deal amount"
            placeholder="20 000"
            step="2500"
            unity="€"
            v-model="amount">
          </bc-input-number>
        </ValidationProvider>
        <div class="is-column">
          <p class="processes-billing__label">
            {{ $t('candidates.freelance') }}
            <span class="processes-billing__label--required">*</span>
          </p>
          <div class="is-column">
            <div class="is-row is-justify-content-start">
              <ValidationProvider
                :rules="{ required: true }"
                name="freelance"
                v-slot="{ errors }">
                <bc-radio
                  :disabled="isSent"
                  :checked="billing.freelance"
                  :error="errors[0]"
                  class="processes-billing__radio"
                  data-freelance
                  id="oui"
                  name="Oui"
                  v-model="freelance"
                  value="freelance">
                  {{ $t('candidates.yes') }}
                </bc-radio>
              </ValidationProvider>
              <ValidationProvider
                :rules="{ required: true }"
                name="nonFreelance"
                v-slot="{ errors }">
                <bc-radio
                  :disabled="isSent"
                  :checked="billing.hasOwnProperty('freelance') && !billing.freelance"
                  :error="errors[0]"
                  data-non-freelance
                  id="non"
                  name="Non"
                  v-model="nonFreelance"
                  value="nonFreelance">
                  {{ $t('candidates.no') }}
                </bc-radio>
              </ValidationProvider>
            </div>
            <p
              class="processes-billing__error"
              v-if="!billing.hasOwnProperty('freelance') && isErrorBagTriggered">
              {{ $t('candidates.please-fill-in-the-status-of-the-candidate') }}
            </p>
          </div>
        </div>
        <div class="processes-billing__locations is-column">
          <p class="processes-billing__label">
            {{ $t('candidates.partner-address-head-office') }}
            <span class="processes-billing__label--required">*</span>
          </p>
          <ValidationProvider
            :rules="{ required: true }"
            name="location"
            v-slot="{ errors }">
            <bc-autocomplete
              :disabled="isSent"
              :error="errors[0]"
              :is-scrollable="false"
              :options="locations"
              @select="selectLocation"
              class="processes-billing__autocomplete"
              name="Autocomplete localisation"
              placeholder="Ex: 29 rue du Faubourg Poissonnière, 75000 Paris"
              v-model="location">
              <template #option="{ option }">
                <bc-dropdown-item :text="option.name"></bc-dropdown-item>
              </template>
            </bc-autocomplete>
          </ValidationProvider>
        </div>
        <div class="is-column">
          <p class="processes-billing__label">
            {{ $t('candidates.sending-the-invoice-to-the-partner') }}
            <span class="processes-billing__label--required">*</span>
          </p>
          <div class="is-column">
            <div class="is-row">
              <ValidationProvider
                :rules="{ required: true }"
                name="acceptationDateToSend"
                v-slot="{ errors }">
                <bc-radio
                  :disabled="isSent"
                  :checked="billing.dateToSend === formDealMacros.dateToSend.start.apiValue"
                  :error="errors[0]"
                  class="processes-billing__radio"
                  data-start-date
                  id="date-de-start"
                  name="Date de start"
                  v-model="startDateToSend">
                  {{ $t('candidates.start-date') }}
                </bc-radio>
              </ValidationProvider>
              <ValidationProvider
                :rules="{ required: true }"
                name="startDateToSend"
                v-slot="{ errors }">
                <bc-radio
                  :disabled="isSent"
                  :checked="billing.dateToSend === formDealMacros.dateToSend.acceptation.apiValue"
                  :error="errors[0]"
                  id="date-d'acceptation"
                  name="Date d'acceptation"
                  v-model="acceptationDateToSend">
                  {{ $t('candidates.acceptance-date') }}
                </bc-radio>
              </ValidationProvider>
            </div>
            <p
              class="processes-billing__error"
              v-if="!billing.dateToSend && isErrorBagTriggered">
              {{ $t('candidates.please-fill-in-the-date-the-invoice-was-sent') }}
            </p>
          </div>
        </div>
        <div class="processes-billing__bill is-column">
          <div class="is-column">
            <ValidationProvider
              name="paymentDaysAfter"
              v-slot="{ errors }">
              <bc-input-number
                disabled
                :error="errors[0]"
                :label-placeholder="false"
                :min="0"
                :should-display-incrementer="false"
                :valid="true"
                @blur="autoSaveBilling('paymentDaysAfter', $event)"
                class="processes-billing__input-number"
                data-payment-days-after
                :label="$t('candidates.billing')"
                name="Day billing"
                placeholder="30"
                :unity="$t('candidates.days-after-sending-the-invoice')"
                v-model="paymentDaysAfter">
              </bc-input-number>
            </ValidationProvider>
          </div>
        </div>
        <div class="processes-billing__sources is-column">
          <p class="processes-billing__label">
            {{ $t('candidates.deal-origin') }}:
            <span class="processes-billing__label--required">*</span>
          </p>
          <div class="is-column">
            <div class="is-row">
              <div class="processes-billing__sources-column is-column is-align-items-start">
                <ValidationProvider
                  :rules="{ required: true }"
                  name="hunt"
                  v-slot="{ errors }">
                  <bc-radio
                    :disabled="isSent"
                    :checked="billing.dealSource === formDealMacros.dealSources.huntBC.apiValue"
                    :error="errors[0]"
                    class="processes-billing__sources-item"
                    data-deal-source-coach
                    id="chasse"
                    name="chasse"
                    v-model="huntBc">
                    {{ $t('candidates.platform-coach-hunting') }}
                  </bc-radio>
                </ValidationProvider>
                <ValidationProvider
                  :rules="{ required: true }"
                  name="linkedin"
                  v-slot="{ errors }">
                  <bc-radio
                    :disabled="isSent"
                    :checked="billing.dealSource === formDealMacros.dealSources.huntLinkedIn.apiValue"
                    :error="errors[0]"
                    class="processes-billing__sources-item"
                    data-deal-source-linkedin
                    id="linkedin"
                    name="linkedin"
                    v-model="huntLinkedIn">
                    {{ $t('candidates.linkedin-hunting') }}
                  </bc-radio>
                </ValidationProvider>
                <ValidationProvider
                  :rules="{ required: true }"
                  name="bluecoders"
                  v-slot="{ errors }">
                  <bc-radio
                    :disabled="isSent"
                    :checked="billing.dealSource === formDealMacros.dealSources.subscriptionBC.apiValue"
                    :error="errors[0]"
                    class="processes-billing__sources-item"
                    data-deal-source-apply
                    id="bluecoders"
                    name="bluecoders"
                    v-model="subscriptionBC">
                    {{ $t('candidates.bluecoders-registration') }}
                  </bc-radio>
                </ValidationProvider>
              </div>
              <div class="processes-billing_sources-column is-column is-align-items-start">
                <ValidationProvider
                  :rules="{ required: true }"
                  name="jobboard"
                  v-slot="{ errors }">
                  <bc-radio
                    :disabled="isSent"
                    :error="errors[0]"
                    :checked="billing.dealSource === formDealMacros.dealSources.jobBoard.apiValue"
                    class="processes-billing__sources-item"
                    data-deal-source-jobboard
                    id="jobboard"
                    name="jobboard"
                    v-model="jobBoard">
                    {{ $t('candidates.jobboard-application') }}
                  </bc-radio>
                </ValidationProvider>
                <ValidationProvider
                  :rules="{ required: true }"
                  name="cooptation"
                  v-slot="{ errors }">
                  <bc-radio
                    :disabled="isSent"
                    :error="errors[0]"
                    :checked="billing.dealSource === formDealMacros.dealSources.cooptation.apiValue"
                    class="processes-billing__sources-item"
                    data-deal-source-cooptation
                    id="cooptation"
                    name="cooptation"
                    v-model="cooptation">
                    {{ $t('candidates.co-option') }}
                  </bc-radio>
                </ValidationProvider>
                <ValidationProvider
                  :rules="{ required: true }"
                  name="network"
                  v-slot="{ errors }">
                  <bc-radio
                    :disabled="isSent"
                    :error="errors[0]"
                    :checked="billing.dealSource === formDealMacros.dealSources.network.apiValue"
                    class="processes-billing__sources-item"
                    data-deal-source-network
                    id="network"
                    name="network"
                    v-model="network">
                    {{ $t('candidates.network') }}
                  </bc-radio>
                </ValidationProvider>
              </div>
            </div>
            <p
              class="processes-billing__error"
              v-if="!billing.dealSource && isErrorBagTriggered">
              {{ $t('candidates.please-fill-in-the-source-of-the-deal') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  //
  import { debounce } from "@/common-old/mixins/Debounce/debounce";
  //
  import { macros } from "@/macros/processes/formDeal";
  //
  import { ValidationProvider, Validator } from 'vee-validate';
  import BcRadio from "@/ui-kit/components/BcRadio/BcRadio";
  import BcAutocomplete from "@/ui-kit/components/BcAutocomplete/BcAutocomplete";
  import BcDropdownItem from "@/ui-kit/components/BcDropdown/BcDropdownItem";
  import BcInputNumber from "@/ui-kit/components/BcInputNumber/BcInputNumber";

  const dict = {
    custom: {
      percent: {
        required: ('candidates.please-enter-the-commission-rate'),
      },
      amount: {
        required: ('candidates.please-fill-in-the-amount-of-the-deal'),
      },
      location: {
        required: ('candidates.please-fill-in-the-location-of-your-partner'),
      },
      paymentDaysAfter: {
        required: ('candidates.please-fill-in-the-payment-date'),
      },
    },
  };
  Validator.localize('fr', dict);

  export default {
    name: 'processes-billing',
    components: { BcInputNumber, BcDropdownItem, BcAutocomplete, BcRadio, ValidationProvider },
    props: {
      billing: {
        type: Object,
        default: () => ({
          percent: 0,
          amount: 0,
          partnerAddress: '',
          dateToSend: 0,
          paymentDaysAfter: 30,
          dealSource: 0,
        }),
      },
      isSent: {
        type: Boolean,
        default: false,
      },
      isErrorBagTriggered: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        location: '',
        formDealMacros: macros,
      };
    },
    computed: {
      ...mapState({
        locations: state => state.location.locationsSuggestions,
      }),
      percent: {
        get() {
          return this.billing.percent || 0;
        },
        set(value) {
          this.updateDealBilling('percent', value);
        },
      },
      amount: {
        get() {
          return this.billing.amount || 0;
        },
        set(value) {
          this.updateDealBilling('amount', value);
        },
      },
      paymentDaysAfter: {
        get() {
          return this.billing.paymentDaysAfter ? this.billing.paymentDaysAfter : null;
        },
        set(value) {
          this.updateDealBilling('paymentDaysAfter', value);
        },
      },
      freelance: {
        get() {
          return this.billing.hasOwnProperty('freelance') ? this.billing.freelance : '';
        },
        set() {
          this.updateDealBilling('freelance', true);
          this.autoSaveBilling('freelance', true);
        },
      },
      nonFreelance: {
        get() {
          return this.billing.hasOwnProperty('freelance') ? this.billing.freelance : '';
        },
        set() {
          this.updateDealBilling('freelance', false);
          this.autoSaveBilling('freelance', false);
        },
      },
      startDateToSend: {
        get() {
          return this.billing.dateToSend ? this.billing.dateToSend : '';
        },
        set() {
          this.updateDealBilling('dateToSend', this.formDealMacros.dateToSend.start.apiValue);
          this.autoSaveBilling('dateToSend', this.formDealMacros.dateToSend.start.frontValue);
        },
      },
      acceptationDateToSend: {
        get() {
          return this.billing.dateToSend ? this.billing.dateToSend : '';
        },
        set() {
          this.updateDealBilling('dateToSend', this.formDealMacros.dateToSend.acceptation.apiValue);
          this.autoSaveBilling('dateToSend', this.formDealMacros.dateToSend.acceptation.frontValue);
        },
      },
      huntBc: {
        get() {
          return this.billing.dealSource ? this.billing.dealSource : '';
        },
        set() {
          this.updateDealBilling('dealSource', this.formDealMacros.dealSources.huntBC.apiValue);
          this.autoSaveBilling('dealSource', this.formDealMacros.dealSources.huntBC.frontValue);
        },
      },
      huntLinkedIn: {
        get() {
          return this.billing.dealSource ? this.billing.dealSource : '';
        },
        set() {
          this.updateDealBilling('dealSource', this.formDealMacros.dealSources.huntLinkedIn.apiValue);
          this.autoSaveBilling('dealSource', this.formDealMacros.dealSources.huntLinkedIn.frontValue);
        },
      },
      subscriptionBC: {
        get() {
          return this.billing.dealSource ? this.billing.dealSource : '';
        },
        set() {
          this.updateDealBilling('dealSource', this.formDealMacros.dealSources.subscriptionBC.apiValue);
          this.autoSaveBilling('dealSource', this.formDealMacros.dealSources.subscriptionBC.frontValue);
        },
      },
      jobBoard: {
        get() {
          return this.billing.dealSource ? this.billing.dealSource : '';
        },
        set() {
          this.updateDealBilling('dealSource', this.formDealMacros.dealSources.jobBoard.apiValue);
          this.autoSaveBilling('dealSource', this.formDealMacros.dealSources.jobBoard.frontValue);
        },
      },
      cooptation: {
        get() {
          return this.billing.dealSource ? this.billing.dealSource : '';
        },
        set() {
          this.updateDealBilling('dealSource', this.formDealMacros.dealSources.cooptation.apiValue);
          this.autoSaveBilling('dealSource', this.formDealMacros.dealSources.cooptation.frontValue);
        },
      },
      network: {
        get() {
          return this.billing.dealSource ? this.billing.dealSource : '';
        },
        set() {
          this.updateDealBilling('dealSource', this.formDealMacros.dealSources.network.apiValue);
          this.autoSaveBilling('dealSource', this.formDealMacros.dealSources.network.frontValue);
        },
      },
    },
    methods: {
      getLocationSuggestions: debounce(async function(query) {
        await this.$store.dispatch('getLocationsAutocomplete', { query, types: 'address' });
      }, 300),
      selectLocation(location) {
        this.location = location.description;

        this.updateDealBilling('partnerAddress', this.location);
        this.autoSaveBilling('partnerAddress', this.location);

        this.$store.dispatch('emptyLocationsSuggestions');
      },
      updateDealBilling(field, value) {
        this.$emit('change-billing', { field, value });
      },
      autoSaveBilling(field, value) {
        this.$emit('auto-save', { field, value });
      },
    },
    watch: {
      location(newValue) {
        if (newValue) {
          this.getLocationSuggestions(newValue);

          this.updateDealBilling('partnerAddress', newValue);
          this.autoSaveBilling('partnerAddress', newValue);
        } else {
          this.$store.dispatch('emptyLocationsSuggestions');
        }
      },
      'billing.partnerAddress': {
        handler() {
          this.location = this.billing.partnerAddress;
        },
        immediate: true,
      },
    },
  };
</script>

<style lang=scss scoped>
  .processes-billing {
    width: 100%;
    height: auto;

    @include bp('desktop') {
      width: 50%;
      height: 100%;
    }

    &__body {
      padding: 20px 0;
    }

    &__body-content {
      padding: 0 35px;
    }

    &__text {
      @include font-medium(20px);
      line-height: 24px;
      color: $color-primary;
      padding-left: 30px;
      background-color: $color-white;
    }

    &__label {
      font-size: $font-size-s;
      line-height: 14px;
      text-align: left;
      color: $color-grey-4;
      margin-bottom: 10px;
      margin-left: 10px;

      &--required {
        color: $color-error;
      }
    }

    &__item,
    &__input {
      margin-bottom: 15px;
    }

    &__input {
      width: 160px;
      background-color: $color-white;
    }

    &__input-number-container {
      margin-bottom: 15px;
    }

    &__input-number {
      margin-top: 20px;

      & :deep() {
        .bc-input-number {
          &__input {
            width: 70px;
            height: 40px;
          }
        }
      }

      &--large {
        & :deep() {
          .bc-input-number {
            &__input {
              width: 160px;
              height: 40px;
            }
          }
        }
      }
    }

    &__radio {
      width: 180px;
      justify-content: start;
    }

    &__locations {
      margin: 15px 0;
    }

    &__autocomplete {
      & :deep() {
        .bc-input {
          margin-top: 0;

          &__wrapper {
            background-color: $color-white;

            &--disabled {
              pointer-events: none;
              background-color: $color-grey-2;
              border-color: $color-grey-4;
              opacity: 0.5;
            }
          }
        }

        .bc-dropdown-item {
          color: $color-secondary;
        }
      }
    }

    &__bill {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    &__bill-label {
      margin-left: 5px;
    }

    &__sources {
      width: 100%;
    }

    &__sources-column {
      &:first-child {
        margin-right: 40px;
      }
    }

    &__sources-item {
      margin-bottom: 10px;
    }

    &__error {
      font-size: 14px;
      margin-top: 5px;
      color: $color-error;
    }
  }
</style>
