<template>
  <filter-content class="filter-career-contact" :label="$t('generics.career')">
    <div class="filter-career-contact__container">
      <bc-checkbox
        class="filter-career-contact__checkbox is-justify-content-center"
        id="cv"
        :value="Boolean(selected['cv'])"
        @input="handleCheck('cv')">
        {{ $t('hunt.has-a-resume') }}
      </bc-checkbox>
      <bc-checkbox
        class="filter-career-contact__checkbox is-justify-content-center"
        id="linkedin"
        :value="Boolean(selected['linkedin'])"
        @input="handleCheck('linkedin')">
        {{ $t('hunt.has-linkedin') }}
      </bc-checkbox>
    </div>
  </filter-content>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox.vue';
import FilterContent from '@/components/Filter/FilterContent.vue';

interface CareerState {
  cv: boolean;
  linkedin: boolean;
}

const props = defineProps<{
  emitOnly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:filters', filters: Record<string, any>): void;
  (e: 'count', value: { key: string; count: number }): void;
}>();

const route = useRoute();
const router = useRouter();

const localSelected = ref<CareerState>({
  cv: false,
  linkedin: false,
});

onMounted(() => {
  if (props.emitOnly) {
    localSelected.value = {
      cv: Boolean(route.query.cv),
      linkedin: Boolean(route.query.linkedin),
    };
  }
});

const selected = computed(() => {
  if (props.emitOnly) {
    return localSelected.value;
  }
  return {
    cv: Boolean(route.query.cv),
    linkedin: Boolean(route.query.linkedin),
  };
});

const handleCheck = (key: 'cv' | 'linkedin') => {
  const newValue = !selected.value[key];

  if (props.emitOnly) {
    localSelected.value = {
      ...localSelected.value,
      [key]: newValue,
    };
    emit('update:filters', {
      [key]: newValue || undefined,
    });
  } else {
    router.push({
      query: {
        ...route.query,
        page: 1,
        [key]: newValue || undefined,
      },
    });
  }
};

watch(selected, (value) => {
  const nb = Object.keys(value).filter(k => value[k as keyof CareerState]);
  emit('count', { key: 'career', count: nb.length });
}, { immediate: true });
</script>

<style lang="scss" scoped>
.filter-career-contact {
  &__container {
    gap: 20px;
  }
}
</style>
