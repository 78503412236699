<template>
  <div class="email-send-form">
    <div v-if="verified" class="is-column">
      <div class="email-send-form__content">
        <!-- Template select -->
        <div class="w-full justify-end">
          <bc-dropdown
            :is-scrollable="true"
            :options="templatesOptions"
            position="bottom-left">
            <template #label="{ isDropdownOpen }">
              <mv-select
                :as-button="true"
                :selected-label="selectedTemplate?.name || $t('generics.select-a-template')"
                :value="selectedTemplate?.value"
                icon="file"
                label-size="l"
                required
              >
              </mv-select>
            </template>
            <template #option="{ activeIndex, index, option }">
              <bc-dropdown-item
                :is-focus="activeIndex === index"
                class="relative flex w-full"
                @click.native="onSelectTemplate(option.slug)">
                <div class="flex w-full">
                  <p class="w-full truncate">{{ option.name }}</p>
                  <bc-avatar
                    v-tooltip="{ trigger: 'hover', content: option.default ? `${$t('generics.created-by')} ${profile._organization?.name}` :`${$t('generics.created-by')} ${option?.coach?.firstName} ${option?.coach?.lastName}` }"
                    :placeholder="getUserInitials(option?.coach)"
                    :src="option.default ? profile._organization?.logoUrl : option?.coach?.pictureUrl"
                    class="ml-3"
                    size="xs"
                    @error="setAlternativeImg"/>
                </div>
              </bc-dropdown-item>
            </template>
          </bc-dropdown>
        </div>

        <EmailVariablesList :variables="templatesVariablesFiltred" class="mt-2"/>

        <!-- Recipient -->
        <div v-if="!selectedCards.length">
          <mv-select
            :items="recipientEmailsOptions"
            :value="form.recipient"
            is-absolute
            label="Destinataire"
            label-size="l"
            required
            @select="onSelectRecipient"
          ></mv-select>
        </div>
        <div v-else class="is-column">
          <bc-input
            :disabled="true"
            :placeholder="$tc('generics.recipient', selectedCodersMapped.length).toLowerCase()"
            :valid="true"
            class="is-full-width"
            name="selectedCards"
            required></bc-input>
          <div class="mt-[5px] flex flex-wrap gap-x-3 gap-y-[7px]">
            <tag
              v-for="card of selectedCodersMapped.slice(0, 4)"
              :key="card._id"
              :can-close="true"
              :text="card.email"
              @click="removeEmailCard(card._id)"
            />
            <div v-click-outside="closeTooltip" @mouseover="onShowTooltip">
              <tag
                v-if="selectedCodersMapped.length > 4"
                v-tooltip.bottom="{ trigger: 'manual', show: showTooltip, html: true, content: tooltipContent }"
                :text="`+${selectedCodersMapped.slice(4).length}`"
              />
            </div>
          </div>
        </div>

        <!-- Sender's name -->
        <div class="mt-2">
          <bc-input
            v-model="form.senderName"
            :label="$t('email.sender-name')"
            :label-placeholder="false"
            :valid="!!form.senderName"
            class="is-full-width"
            name="sender"
            required></bc-input>
        </div>

        <!-- CC -->
        <div class="mt-2 block">
          <bc-autocomplete
            v-model="ccValue"
            :is-scrollable="false"
            :label-placeholder="false"
            :options="ccOptionsComputed"
            class="is-full-width"
            label="CC"
            name="cc"
            type="email"
            v-bind="$attrs"
            @input="getCcSuggestions"
            @select="onSelectCc">
            <template #option="{ option }">
              <div class="gap-3 p-3">
                <div>
                  <bc-avatar :src="option.logo" @error="setAlternativeImg"/>
                </div>
                <div class="is-column">
                  <p v-if="option.name">{{ option.name }}</p>
                  <p class="text-sm">{{ option.address }}</p>
                </div>
              </div>
            </template>
          </bc-autocomplete>
          <div class="mt-2 gap-2">
            <tag
              v-for="email in form.cc"
              :key="email.key"
              :can-close="true"
              :text="email.address"
              @click="onRemoveCc(email)"
            />
          </div>
        </div>

        <!-- Mail object -->
        <div class="mt-2">
          <bc-input
            v-model="form.object"
            :label="$t('email.object')"
            :label-placeholder="false"
            :valid="!!form.object"
            class="is-full-width"
            name="mail-object"
            required></bc-input>
        </div>

        <!-- Mail body -->
        <div class="is-column mt-2">
          <bc-text-editor
            v-model="form.body"
            :label="$t('email.body')"
            class="mv-text-editor-preview is-full-width"
            required></bc-text-editor>
          <div class="is-column">
            <bc-button
              class="mt-2"
              icon-left="attachment"
              type="outlined"
              @click="onUpload"
            >
              {{ $t('email.add-an-attachment') }}
            </bc-button>
            <input
              ref="uploadInput"
              multiple
              style="display: none;"
              type="file"
              @change="onUploadSelect"
            />
            <EmailAttachmentList
              :attachments="attachments"
              :files="files"
              class="mt-3"
              @on-remove="onRemoveFile"
            />
          </div>
        </div>
      </div>
      <div class="email-send-form__content">
        <EmailPreview
          :attachments="attachments"
          :body="form.body"
          :cc="form.cc"
          :files="files"
          :object="form.object"
          :recipientEmail="form.recipient"
          :recipientName="`${coder.firstName} ${coder.lastName}`"
          :selectedCards="selectedCodersMapped"
          :senderEmail="emailConfig.email"
          :senderName="form.senderName"
          :signature="emailConfig.signature"
          :templatePreview="templatePreview"
          @on-empty-variables="onEmptyVariables"
          @on-select-card="onSelectCard"
        />
      </div>
      <div class="email-send-form__footer">
        <bc-button
          :disabled="isSubmitting"
          icon-left="cross"
          type="outlined"
          @click="$emit('on-close')"
        >
          {{ $t('generics.cancel') }}
        </bc-button>
        <bc-button
          v-if="isContinueButtonVisible"
          icon-left="check"
          @click="continueWithoutSending">
          {{ $t('generics.continue-without-sending') }}
        </bc-button>
        <bc-button
        color="success"
          :disabled="isSendingDisabled"
          icon-left="plane"
          @click="onSendMail">
          {{ $t('generics.send') }}
        </bc-button>
      </div>
      <div
        v-if="invalidCardsPreview.length"
        class="is-column mb-[100px] gap-2 py-2 text-center">
        ⚠️ {{ $t('email.not-sending-because-missing-variables') }}
        <ul>
          <li
            v-for="invalidCard in invalidCardsPreview"
            :key="invalidCard._id"
            class="block px-5">
            <b>{{ invalidCard.firstName }} {{ invalidCard.lastName }}</b> -
            {{ $t('email.unresolved-variable') }} :
            <span class="text-red-500">{{ invalidCard.invalidVariables.join(', ') }}</span>
          </li>
        </ul>
      </div>
      <div
        v-if="!recipientEmailsOptions.length && !selectedCodersMapped.length"
        class="is-column mb-[100px] gap-2 py-2 text-center">
        <p class="text-sm">
          ⚠️ {{ $t('email.empty-recipient-email') }}
        </p>
      </div>
    </div>
    <div v-if="!verified && !isLoading" class="email-send-form__warning">
      Please check your email configuration
    </div>
  </div>
</template>

<script>
  import debounce from 'debounce';
  import { mapActions, mapState } from 'vuex';

  import {
    createHistory,
    createTemplate,
    getConfiguration,
    getEmailsAutocomplete,
    getVariablesPreview,
    sendMail,
    sendMailGroup,
  } from '@/api/emails';
  import { uploadFile } from '@/api/upload';
  import { validateEmail } from '@/helpers/strings';

  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import BcButton from '@/ui-kit/components/BcButton';
  import BcInput from '@/ui-kit/components/BcInput';
  import BcTextEditor from '@/ui-kit/components/BcTextEditor/BcTextEditor';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete';
  import MvSelect from '@/ui-kit/components/MvSelect/MvSelect';
  import Tag from '@/components/Tag/Tag';
  import EmailPreview from '@/components/Email/EmailPreview';
  import EmailAttachmentList from '@/components/Email/EmailAttachmentList';
  import EmailVariablesList from '@/components/Email/EmailVariablesList';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';

  export default {
    name: 'email-send-form',
    components: {
      BcDropdownItem,
      BcDropdown,
      BcAvatar,
      BcButton,
      BcInput,
      BcTextEditor,
      BcAutocomplete,
      MvSelect,
      Tag,
      EmailPreview,
      EmailAttachmentList,
      EmailVariablesList,
    },
    props: {
      recipientEmails: {
        type: Array,
        default: () => [],
      },
      actionId: {
        type: String,
      },
      coder: {
        type: Object,
        default: () => ({}),
      },
      customJob: {
        type: Object,
        default: () => ({}),
      },
      warningMessage: {
        type: String,
      },
      selectedCards: {
        type: Array,
        default: () => [],
      },
      selectedCustomJobs: {
        type: Array,
        default: () => [],
      },
      isApplication: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        ccValue: '',
        ccOptions: [],
        verified: false,
        isSubmitting: false,
        isLoading: true,
        emailConfig: {},
        files: [],
        attachments: [],
        selectedCodersMapped: [],
        selectedCard: {},
        MAX_SIZE: 25_164_007,
        selectedTemplate: {},
        emptyVariables: [],
        templatePreview: {},
        showTooltip: false,
        selectedCustomJob: {},
        form: {
          recipient: '',
          object: '',
          body: '',
          senderName: '',
          cc: [],
        },
        cardVariablesPreviews: [],
      };
    },
    async mounted() {
      this.form.recipient = this.recipientEmails[0];
      if (this.selectedCustomJobs?.length) {
        this.selectedCustomJob = this.selectedCustomJobs[0];
      }

      await this.getConfiguration();
      await this.getTemplatesVariables();
      await this.getTemplates({ all: true });
      await this.setTemplatePreview();
      await this.getTemplatesPreview();

      this.setSelectedCodersMapped();

      document.addEventListener('click', this.handleRemoveButtonClick);
    },
    beforeDestroy() {
      this.setSelectedCoders([]);
      this.setSelectedCustomJobs([]);
      document.removeEventListener('click', this.handleRemoveButtonClick);
    },
    computed: {
      ...mapState({
        profile: state => state.user.profile,
        templates: state => state.emails.templates,
        templatesVariables: state => state.emails.templatesVariables,
        emailSentCallback: state => state.emails.emailSentCallback,
      }),
      isSendingDisabled() {
        return (!this.isFormValid || this.isSubmitting || (!this.recipientEmailsOptions.length && !this.selectedCodersMapped.length) || (this.cardVariablesPreviews.length === this.invalidCardsPreview.length && this.invalidCardsPreview.length !== 0));
      },
      isContinueButtonVisible() {
        if (!this.isApplication) {
          return false;
        }
        return this.profile._organization?.configuration?.multiposting?.sendEmailAfterApplicationTreated || this.profile._organization?.configuration?.process?.failureEmail && this.emailSentCallback;
      },
      templatesVariablesFiltred() {
        if (!this.customJob?._id && !this.selectedCustomJob?._id) {
          return this.templatesVariables.filter(template => template.source === 'coder');
        }
        return this.templatesVariables;
      },
      templatesOptions() {
        const templates = this.templates.reduce((acc, template) => {
          if (template.activated) {
            if (template.default) {
              acc.defaultTemplates.push({
                label: template.name,
                value: template.slug,
                ...template,
              });
            } else if (template._coach === this.profile._id) {
              acc.userCustomTemplates.push({
                label: template.name,
                value: template.slug,
                ...template,
              });
            } else {
              acc.othersCustomTemplates.push({
                label: template.name,
                value: template.slug,
                ...template,
              });
            }
          }
          return acc;
        }, { userCustomTemplates: [], defaultTemplates: [], othersCustomTemplates: [] });

        return [
          ...templates.userCustomTemplates,
          ...templates.defaultTemplates,
          ...templates.othersCustomTemplates.sort((a, b) => a.coach?.lastName?.localeCompare(b.coach?.lastName)),
        ];
      },
      recipientEmailsOptions() {
        return this.recipientEmails.map(email => ({
          label: email,
          value: email,
        }));
      },
      isFormValid() {
        return this.form.object !== '' && this.form.body !== '' && this.form.recipient !== '' && this.form.senderName !== '';
      },
      isTemplateModified() {
        if (!this.selectedTemplate?._id) return true;

        return this.form.cc?.length ||
          this.form.object !== this.selectedTemplate.subject ||
          this.form.body !== this.selectedTemplate.body ||
          this.form.senderName !== this.selectedTemplate.senderName;
      },
      ccOptionsComputed() {
        if (this.ccOptions.length) {
          return this.ccOptions;
        }
        return [{ address: this.ccValue }];
      },
      tooltipContent() {
        return `
        <div id="email-send-form__tooltip" class='is-column w-fit max-h-[200px] overflow-auto divide-y'>
          ${this.selectedCodersMapped.slice(4).map(card => {
          return `
              <div class='p-2 justify-between items-center'>
                <span>${card.firstName} ${card.lastName} &lsaquo;${card.email}&rsaquo;</span>
                <button class='remove-button mx-3' data-id="${card._id}">
                  <i class='icon-cross' style='font-size: 10px;'></i>
                </button>
              </div>
            `;
        }).join('')}
        </div>
      `;
      },
      usedTemplatesVariables() {
        return this.templatesVariables.reduce((acc, template) => {
          if (this.form.body.includes(template.name) || this.form.object.includes(template.name)) {
            acc.push(template.name);
          }
          return acc;
        }, []);
      },
      invalidCardsPreview() {
        return this.cardVariablesPreviews.reduce((acc, card) => {
          const preview = {
            _id: card.coderId,
            firstName: card.firstName,
            lastName: card.lastName,
            invalidVariables: [],
          };
          for (const variable of this.usedTemplatesVariables) {
            if (!card[variable]) {
              preview.invalidVariables.push(variable);
            }
          }

          if (preview.invalidVariables.length) {
            acc.push(preview);
          }

          return acc;
        }, []);
      },
    },
    methods: {
      ...mapActions({
        getTemplatesVariables: 'emails/getTemplatesVariables',
        getTemplatePreview: 'emails/getTemplatePreview',
        getTemplates: 'emails/getTemplates',
        setSelectedCoders: 'emails/setSelectedCoders',
        setSelectedCustomJobs: 'emails/setSelectedCustomJobs',
      }),
      getUserInitials(user) {
        if (!user) return '';
        return `${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`;
      },
      onShowTooltip() {
        const tooltip = document.querySelector('#email-send-form__tooltip');
        if (tooltip) {
          tooltip.parentElement.style.padding = 0;
          tooltip.parentElement.style.maxWidth = 'fit-content';
        }
        this.showTooltip = true;
      },
      closeTooltip() {
        this.showTooltip = false;
      },
      handleRemoveButtonClick(event) {
        if (event.target.closest('.remove-button')) {
          const cardId = event.target.closest('.remove-button').getAttribute('data-id');
          this.removeEmailCard(cardId);
        }
      },
      async onSelectCard(card, index) {
        this.selectedCard = card;
        if (this.selectedCustomJobs?.length) {
          this.selectedCustomJob = this.selectedCustomJobs[index || 0];
        }
        await this.setTemplatePreview();
      },
      setSelectedCodersMapped() {
        if (!this.selectedCards.length) return;

        this.selectedCodersMapped = this.selectedCards.map((selected) => {
          const isContact = selected.profileTypes?.includes('contact');
          let email = selected.email;
          if ((!email || isContact) && selected.emails?.length) {
            email = selected.emails[0];
          }

          return {
            _id: selected._id,
            value: selected._id,
            firstName: selected.firstName,
            lastName: selected.lastName,
            email,
            label: `${selected.firstName} ${selected.lastName} <${email}>`,
          };
        }).filter(selected => {
          return !!selected.email;
        });
      },
      onSelectRecipient(recipient) {
        this.form.recipient = recipient;
      },
      async onSelectTemplate(templateSlug) {
        const template = this.templates.find(template => template.slug === templateSlug);
        if (!template) {
          return;
        }
        this.selectedTemplate = template;
        this.attachments = template?.attachments || [];

        this.form.senderName = `${this.profile.firstName} ${this.profile.lastName}`;
        this.form.object = template.subject;
        this.form.body = template.body;
        this.form.cc = template.cc || [];

        await this.setTemplatePreview();
      },
      onUpload() {
        this.$refs.uploadInput.click();
      },
      async getTemplatesPreview() {
        if (this.selectedCards?.length) {
          const promisesMap = [];

          for (const index in this.selectedCards) {
            const coder = this.selectedCards[index];
            const customJobId = this.selectedCustomJobs[index]?._id;
            const companyId = this.selectedCustomJobs[index]?._company?._id || this.selectedCustomJobs[index]?._company;

            const params = {
              coderId: coder._id,
              ...customJobId && { customJobId },
              ...companyId && { companyId },
            };

            promisesMap.push(new Promise(resolve => {
              getVariablesPreview(params).then(result => {
                const preview = Object.values(result.data).reduce((acc, value) => {
                  acc[value.label] = value.value;

                  return acc;
                }, {});

                resolve({
                  ...preview,
                  firstName: coder.firstName,
                  lastName: coder.lastName,
                  coderId: coder._id,
                  customJobId,
                  companyId,
                });
              });
            }));
          }

          this.cardVariablesPreviews = await Promise.all(promisesMap);
        }
      },
      async setTemplatePreview() {
        const coderId = this.selectedCard?._id ? this.selectedCard._id : this.coder._id;
        const index = this.selectedCodersMapped.findIndex(coder => coder._id === coderId);
        const customJob = this.selectedCustomJobs[index] || this.customJob;

        const params = {
          coderId,
          ...customJob && {
            customJobId: customJob._id,
          },
          ...customJob._company && {
            companyId: customJob?._company?._id ? customJob?._company._id : customJob?._company,
          },
        };
        const { data } = await getVariablesPreview({
          ...params,
        });
        this.templatePreview = { result: data };

        const preview = Object.values(data).reduce((acc, value) => {
          acc[value.label] = value.value;

          return acc;
        }, {});
        this.cardVariablesPreviews = [{
          ...preview,
          firstName: this.coder.firstName,
          lastName: this.coder.lastName,
          coderId: this.coder._id,
          customJobId: this.customJob._id,
          companyId: this.customJob?._company?._id ?? this.customJob?._company,
        }];
      },
      onUploadSelect(e) {
        const file = e.target.files[0];
        if (!file) return;

        if (file.size > this.MAX_SIZE) {
          this.$toast.show({
            type: 'error',
            message: this.$t('email.attachment-limit'),
          });
          return;
        }
        this.files = [...this.files, file];
      },
      onRemoveFile({ file, attachment }) {
        if (file) {
          this.files = this.files.filter(f => f.name !== file.name && f.size !== file.size && f.lastModified !== file.lastModified);
        }
        if (attachment) {
          this.attachments = this.attachments.filter(a => a.path !== attachment.path);
        }
      },
      getCcSuggestions: debounce(async function(value) {
        this.ccOptions = [];
        if (value.length < 3) return;

        try {
          const { data: results } = await getEmailsAutocomplete(value);
          this.ccOptions = results;
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: this.getErrorMessage(err),
          });
        }
      }, 500),
      onSelectCc(option) {
        if (!validateEmail(option.address)) {
          this.$toast.show({
            type: 'error',
            message: 'email invalid', // TODO: update message
          });
          return;
        }
        this.form.cc.push(option);
        this.ccValue = '';
      },
      onRemoveCc(option) {
        this.form.cc = this.form.cc.filter(email => email.address !== option.address);
      },
      onEmptyVariables(emptyVariables) {
        const warnings = this.templatePreview.result?.warning?.value || {};
        this.emptyVariables = Object.keys(emptyVariables).map(key => {
          const warning = warnings[key.replace('object_', '').replace('body_', '')];
          return {
            ...emptyVariables[key],
            ...warning && { warning },
          };
        });
      },
      removeEmailCard(cardId) {
        this.selectedCodersMapped = this.selectedCodersMapped.filter(card => card._id !== cardId);
      },
      async getAttachments() {
        let attachments = [...this.attachments];
        if (this.files.length) {
          const promises = this.files.map(file => {
            const dataToSend = new FormData();
            dataToSend.append('file', file);
            return uploadFile({ file: dataToSend });
          });

          const results = await Promise.all(promises);
          attachments = [...attachments, ...results.map(result => ({
            filename: result.data.fileName,
            path: result.data.fileUrl,
          }))];
        }
        return attachments;
      },
      async continueWithoutSending() {
        if (this.emailSentCallback) {
          if (this.selectedCodersMapped.length) {
            this.selectedCodersMapped.forEach((coder, index) => {
              this.emailSentCallback({
                coder,
                customJob: this.selectedCustomJobs[index] || this.customJob,
                company: this.selectedCustomJobs[index]?._company?._id ? this.selectedCustomJobs[index]._company._id : { _id: this.selectedCustomJobs[index]?._company || this.customJob._company?._id || this.customJob._company },
              });
            });
          } else {
            this.emailSentCallback({
              coder: this.coder,
              customJob: this.customJob,
              company: this.customJob._company,
            });
          }
        }

        this.$emit('on-close');
      },
      getErrorMessage(err) {
        if (err?.response?.data?.message) {
          return err.response.data.message;
        }
        if (err?.response?.body?.message) {
          return err.response.body.message;
        }
        return err.message;
      },
      async onSendMail() {
        if (this.selectedCards?.length) {
          await this.onSendMailGroup();
          return;
        }

        try {
          this.isSubmitting = true;

          const attachments = await this.getAttachments();

          let template = this.selectedTemplate?._id ? this.selectedTemplate : undefined;
          if (!template || this.isTemplateModified) {
            const { data } = await createTemplate({
              name: `Custom mail send by ${this.form.senderName}`,
              subject: this.form.object,
              senderName: this.form.senderName,
              body: this.form.body,
              cc: this.form.cc,
              custom: true,
            });
            template = data;
          }

          if (!template?._id) {
            throw new Error('Template creation failure');
          }

          const { data: history } = await createHistory({
            coderId: this.coder?._id,
            customJobId: this.customJob?._id,
            companyId: this.customJob?._company?._id || this.customJob?._company,
            actionId: this.actionId,
            emailTemplateId: template._id,
            emailTemplateSlug: template.slug,
            overrideEmail: this.form.recipient,
            attachments,
          });

          if (history.status === 'success') {
            const { data: sendMailData } = await sendMail({
              historyId: history.id,
            });
            if (!sendMailData.messageId) {
              throw new Error(sendMailData.error);
            }
          } else {
            throw new Error('History creation failure');
          }
          this.isSubmitting = false;

          if (this.emailSentCallback) {
            this.emailSentCallback({
              coder: this.coder,
              customJob: this.customJob,
              company: this.customJob._company,
            });
          }

          this.$toast.show({
            type: 'success',
            message: this.$t('email.sent-success'),
          });
          this.$emit('on-close');
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: this.getErrorMessage(err),
          });
          this.isSubmitting = false;
        }
      },
      async onSendMailGroup() {
        try {
          this.isSubmitting = true;

          const attachments = await this.getAttachments();
          let template = this.selectedTemplate?._id ? this.selectedTemplate : undefined;
          if (!template || this.isTemplateModified) {
            const { data } = await createTemplate({
              name: `Custom mail send by ${this.form.senderName}`,
              subject: this.form.object,
              senderName: this.form.senderName,
              body: this.form.body,
              cc: this.form.cc,
              custom: true,
            });
            template = data;
          }

          const validCards = this.selectedCodersMapped.filter(card => {
            return !this.invalidCardsPreview.find(invalidCard => invalidCard._id === card._id);
          });

          const { data } = await sendMailGroup({
            group: validCards.map((coder, index) => ({
              coder,
              customJob: this.selectedCustomJobs[index] || this.customJob,
              company: this.selectedCustomJobs[index]?._company?._id ? this.selectedCustomJobs[index]._company._id : { _id: this.selectedCustomJobs[index]?._company || this.customJob._company?._id || this.customJob._company },
            })),
            groupType: 'coder',
            attachments,
            template,
            isAsync: true,
          });

          if (data.success) {
            if (this.emailSentCallback) {
              validCards.forEach((coder, index) => {
                this.emailSentCallback({
                  coder,
                  customJob: this.selectedCustomJobs[index] || this.customJob,
                  company: this.selectedCustomJobs[index]?._company?._id ? this.selectedCustomJobs[index]._company._id : { _id: this.selectedCustomJobs[index]?._company || this.customJob._company?._id || this.customJob._company },
                });
              });
            }

            this.$emit('on-close');
          }

          this.isSubmitting = false;

        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: this.getErrorMessage(err),
          });
          this.isSubmitting = false;
        }

      },
      async getConfiguration() {
        try {
          const { data: config } = await getConfiguration();
          this.form.senderName = config.fullname;
          this.verified = config.verified;
          this.emailConfig = config;
          this.isLoading = false;
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: this.getErrorMessage(err),
          });
          this.isLoading = false;
        }
      },
      setAlternativeImg(event) {
        event.target.src = require('@/assets/img/default-avatar.svg');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .email-send-form {
    flex-direction: column;
    width: 100%;
    overflow: auto;

    &__content {
      flex-direction: column;
      height: 100%;
      padding: 20px;
      gap: 15px;
    }

    &__footer {
      width: 100%;
      min-height: 75px;
      gap: 10px;
      align-items: center;
      justify-content: center;
      border-top: 1px solid $color-blue-light;
    }

    &__warning {
      justify-content: center;
      align-items: center;
      margin-top: 5%;
    }

    &:deep() {
      .bc-dropdown-item__text {
        width: 100%;
      }
    }
  }
</style>
