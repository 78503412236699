<template>
  <div
    class="bc-rich-text-editor-bubble__container">
    <div
      class="bc-rich-text-editor-bubble is-full-width is-column">
      <editor-menu-bubble
        v-show="isEditable"
        :editor="editor">
        <template #default="{ commands, isActive, menu }">
          <div
            ref="menu"
            :class="{
              'bc-rich-text-editor-bubble__menu--active': menu.isActive,
              'is-fixed': position === 'fixed',
              'is-absolute': position === 'absolute'
            }"
            :style="`left: ${menu.left}px; top: calc(${menu.top}px - 4em);`"
            class="bc-rich-text-editor-bubble__menu border-radius-s">
            <button
              :class="{ 'bc-rich-text-editor-bubble__button--active': isActive.bold() }"
              class="bc-rich-text-editor-bubble__button is-align-items-center is-justify-content-center border-radius-s font-size-s"
              @click.prevent="commands.bold">
              <span class="bc-rich-text-editor-bubble__icon icon-tiptap-bold"></span>
            </button>
            <button
              :class="{ 'bc-rich-text-editor-bubble__button--active': isActive.italic() }"
              class="bc-rich-text-editor-bubble__button is-align-items-center is-justify-content-center border-radius-s font-size-s"
              @click.prevent="commands.italic">
              <span class="bc-rich-text-editor-bubble__icon icon-tiptap-italic"></span>
            </button>
            <button
              :class="{ 'bc-rich-text-editor-bubble__button--active': isActive.strike() }"
              class="bc-rich-text-editor-bubble__button is-align-items-center is-justify-content-center border-radius-s font-size-s"
              @click.prevent="commands.strike">
              <span class="bc-rich-text-editor-bubble__icon icon-tiptap-strike"></span>
            </button>
            <button
              :class="{ 'bc-rich-text-editor-bubble__button--active': isActive.underline() }"
              class="bc-rich-text-editor-bubble__button is-align-items-center is-justify-content-center border-radius-s font-size-s"
              @click.prevent="commands.underline">
              <span class="bc-rich-text-editor-bubble__icon icon-tiptap-underline"></span>
            </button>
            <button
              :class="{ 'bc-rich-text-editor-bubble__button--active': isActive.paragraph() }"
              class="bc-rich-text-editor-bubble__button is-align-items-center is-justify-content-center border-radius-s font-size-s"
              @click.prevent="commands.paragraph">
              <span class="bc-rich-text-editor-bubble__icon icon-tiptap-paragraph"></span>
            </button>
            <button
              :class="{ 'bc-rich-text-editor-bubble__button--active': isActive.heading({ level: 1 }) }"
              class="bc-rich-text-editor-bubble__button is-align-items-center is-justify-content-center border-radius-s font-size-xs"
              @click.prevent="commands.heading({ level: 1 })">
              H1
            </button>
            <button
              :class="{ 'bc-rich-text-editor-bubble__button--active': isActive.heading({ level: 2 }) }"
              class="bc-rich-text-editor-bubble__button is-align-items-center is-justify-content-center border-radius-s font-size-xs"
              @click.prevent="commands.heading({ level: 2 })">
              H2
            </button>
            <button
              :class="{ 'bc-rich-text-editor-bubble__button--active': isActive.heading({ level: 3 }) }"
              class="bc-rich-text-editor-bubble__button is-align-items-center is-justify-content-center border-radius-s font-size-xs"
              @click.prevent="commands.heading({ level: 3 })">
              H3
            </button>
          </div>
        </template>
      </editor-menu-bubble>
      <editor-content
        ref="reference"
        :editor="editor"
        class="bc-rich-text-editor-bubble__textarea"
        spellcheck="false"
        v-bind="$attrs"
        v-on="listeners">
      </editor-content>
    </div>
    <div
      v-show="showSuggestions" ref="suggestions"
      class="bc-rich-text-editor-bubble__suggestion-list">
      <template v-if="hasResults">
        <div
          v-for="(user, index) in filteredUsers"
          :key="user.id"
          :class="{ 'is-selected': navigatedUserIndex === index }"
          class="bc-rich-text-editor-bubble__suggestion-list__item"
          @click="selectUser(user)"
        >
          <bc-avatar
            :src="user.pictureUrl"
            class="bc-rich-text-editor-bubble__avatar is-unshrink"
            size="s"
            @error="setAlternativeImg"
          ></bc-avatar>
          <span>{{ user.name }}</span>
        </div>
      </template>
      <div v-else class="bc-rich-text-editor-bubble__suggestion-list__item is-empty">
        No users found
      </div>
    </div>
  </div>
</template>

<script>
  import Fuse from 'fuse.js';
  import tippy, { sticky } from 'tippy.js';
  import { Editor, EditorContent, EditorMenuBubble } from 'tiptap';
  import {
    Bold,
    BulletList,
    HardBreak,
    Heading,
    History,
    Italic,
    Link,
    ListItem,
    Mention,
    OrderedList,
    Placeholder,
    Strike,
    Underline,
  } from 'tiptap-extensions';

  import BcAvatar from '../BcAvatar';

  export default {
    name: 'bc-rich-text-editor-bubble',
    components: {
      EditorMenuBubble,
      EditorContent,
      BcAvatar,
    },
    inheritAttrs: false,
    props: {
      /**
       * Define the field inside the object which will be modified
       */
      value: {
        type: [String, Number],
      },
      isEditable: {
        type: Boolean,
        default: true,
      },
      position: {
        type: String,
        default: 'fixed',
      },
      mentionSuggestions: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        popperInstance: {},
        query: null,
        suggestionRange: null,
        filteredUsers: [],
        navigatedUserIndex: 0,
        insertMention: () => {
        },
        editor: new Editor({
          content: this.value,
          extensions: [
            new BulletList(),
            new HardBreak(),
            new Heading({ levels: [1, 2, 3] }),
            new ListItem(),
            new OrderedList(),
            new Bold(),
            new Italic(),
            new Link(),
            new Strike(),
            new Underline(),
            new History(),
            new Placeholder({
              emptyEditorClass: 'is-editor-empty',
              emptyNodeClass: 'is-empty',
              emptyNodeText: this.$attrs.placeholder || '',
              showOnlyWhenEditable: true,
              showOnlyCurrent: true,
            }),
            new Mention({
              items: async() => {
                return this.mentionSuggestions;
              },
              onEnter: ({
                          items, query, range, command, virtualNode,
                        }) => {
                this.query = query;
                this.filteredUsers = items;
                this.suggestionRange = range;
                this.renderPopup(virtualNode);
                this.insertMention = command;
              },
              onChange: ({
                           items, query, range, virtualNode,
                         }) => {
                this.query = query;
                this.filteredUsers = items;
                this.suggestionRange = range;
                this.navigatedUserIndex = 0;
                this.renderPopup(virtualNode);
              },
              onExit: () => {
                this.query = null;
                this.filteredUsers = [];
                this.suggestionRange = null;
                this.navigatedUserIndex = 0;
                this.destroyPopup();
              },
              onKeyDown: ({ event }) => {
                if (event.key === 'ArrowUp') {
                  this.upHandler();
                  return true;
                }

                if (event.key === 'ArrowDown') {
                  this.downHandler();
                  return true;
                }

                if (event.key === 'Enter') {
                  this.enterHandler();
                  return true;
                }

                return false;
              },
              onFilter: async(items, query) => {
                if (!query) {
                  return items;
                }

                const fuse = new Fuse(items, {
                  threshold: 0.6,
                  isCaseSensitive: false,
                  includeMatches: true,
                  keys: ['name'],
                });

                return fuse.search(query).map(item => item.item);
              },
            }),
          ],
          editable: this.isEditable,
          onUpdate: event => {
            this.getValue(event);
          },
          onFocus: () => {
            this.$emit('focus');
          },
          onBlur: () => {
            this.$emit('blur');
          },
        }),
      };
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
        };
      },
      hasResults() {
        return this.filteredUsers.length;
      },
      showSuggestions() {
        return this.query || this.hasResults;
      },
    },
    watch: {
      isEditable: {
        handler() {
          this.editor.setOptions({
            editable: this.isEditable,
          });

          this.editor.setContent(this.value);

          if (this.isEditable) {
            this.editor.focus();
          }
        },
        immediate: true,
      },
      showSuggestions(value) {
        this.$emit('show-suggestions', !!value);
      },
    },
    beforeDestroy() {
      this.editor.destroy();
      this.destroyPopup();
    },
    methods: {
      setAlternativeImg(event) {
        event.target.src = 'https://storage.googleapis.com/bluecoders-medias-dev/default-avatar.svg';
      },
      getValue({ getHTML }) {
        this.$emit('input', getHTML());
      },
      upHandler() {
        this.navigatedUserIndex = ((this.navigatedUserIndex + this.filteredUsers.length) - 1) % this.filteredUsers.length;
      },
      downHandler() {
        this.navigatedUserIndex = (this.navigatedUserIndex + 1) % this.filteredUsers.length;
      },
      enterHandler() {
        const user = this.filteredUsers[this.navigatedUserIndex];

        if (user) {
          this.selectUser(user);
        }
      },
      selectUser(user) {
        this.insertMention({
          range: this.suggestionRange,
          attrs: {
            id: user.id,
            label: user.name,
          },
        });
        this.editor.focus();
        this.$emit('on-mention-select', { id: user.id, name: user.name });
      },
      renderPopup(node) {
        if (!node) {
          return;
        }
        const { x, y } = node.getBoundingClientRect();

        if (x === 0 && y === 0) {
          return;
        }

        if (this.popup) {
          return;
        }

        this.popup = tippy('.page', {
          getReferenceClientRect: () => node.getBoundingClientRect(),
          appendTo: () => document.body,
          interactive: true,
          sticky: true, // make sure position of tippy is updated when content changes
          plugins: [sticky],
          content: this.$refs.suggestions,
          trigger: 'mouseenter', // manual
          showOnCreate: true,
          theme: 'dark',
          placement: 'top-start',
          inertia: true,
          duration: [400, 200],
        });
      },
      destroyPopup() {
        if (this.popup && this.popup.length) {
          this.popup[0].destroy();
          this.popup = null;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .bc-rich-text-editor-bubble {
    max-height: 100%;

    &__container {
      flex-direction: column;
      overflow: auto;
      width: 100%;

      margin-bottom: 15px;
      @include bp('phone') {
        margin-top: 10px;
        margin-left: -15px;
      }
      @include bp('tablet') {
        margin-bottom: 25px;
      }
    }

    &__menu {
      z-index: 1;
      background: $color-white;
      padding: 0.3rem;
      margin-bottom: 0.5rem;
      transform: translateX(-50%);
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.2s, visibility 0.2s;
      box-shadow: 0 3px 6px rgba($color-secondary, 0.15);
      max-height: 40px;

      &--active {
        opacity: 1;
        visibility: visible;
      }
    }

    &__textarea {
      display: flex;
      flex-grow: 1;
      min-height: 100%;
      overflow: auto;
      text-align: left;
      word-break: break-word;

      :deep() {
        * {
          color: $color-tertiary;
        }

        & > div {
          display: block;
          line-height: 1.5;
          width: 100%;
        }

        p {
          font-size: $font-size-s;
        }

        ul, ol {
          padding-left: 1rem;

          li {
            &::marker {
              caret-color: currentColor;
            }
          }
        }

        p {
          display: block;
        }

        i {
          font-style: italic;
        }

        ul {
          display: block;
          list-style-type: disc;
          padding-inline-start: 40px;
          unicode-bidi: isolate;
        }

        li {
          margin-left: 15px;
        }

        ol {
          display: block;
          list-style-type: decimal;
          padding-inline-start: 40px;
        }

        blockquote {
          display: block;
          unicode-bidi: isolate;
          border-left: 5px solid #ccc;
          font-style: italic;
          margin-left: 0;
          margin-right: 0;
          overflow: hidden;
          padding-left: 1.5em;
          padding-right: 1.5em;
        }

        h2 {
          display: block;
          font-size: 1.5em;
          font-weight: bold;
        }

        h3 {
          display: block;
          font-size: 1.17em;
          font-weight: bold;
        }

        h4 {
          display: block;
          font-weight: bold;
        }
      }
    }

    &__button {
      font-weight: bold;
      background: transparent;
      border: 0;
      color: $color-secondary;
      padding: 0.2rem 0.5rem;
      margin-right: 0.2rem;
      cursor: pointer;
      white-space: initial !important;
      word-break: initial !important;

      &:hover {
        background-color: rgba($color-secondary, 0.05);
      }

      &--active {
        background-color: rgba($color-secondary, 0.1);
      }
    }

    :deep() {
      .is-empty:first-child::before {
        content: attr(data-empty-text);
        color: rgba($color-secondary, 0.3);
        pointer-events: none;
        height: 0;
        font-size: $font-size-s;
        float: left;
      }

      .mention {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #3C80F7 !important;
      }
    }

    &__suggestion-list {
      width: 300px;
      max-height: 200px;
      overflow: auto;
      display: block;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px rgba(29, 57, 94, 0.1);
      border-radius: 5px;
      align-self: flex-start;

      &__no-results {
        padding: 0.2rem 0.5rem;
      }

      &__item {
        align-items: center;
        gap: 10px;
        padding: 15px 20px;
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $color-blue-dark-cello;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          background: #3C80F7;
          color: white;
        }

        &.is-empty {
          opacity: 0.5;
        }
      }
    }
  }
</style>
