<template>
  <div class="panel-process-details-job-offer">
    <div class="panel-process-details-job-offer__wrapper" v-if="isEditing">
      <p class="panel-process-details-job-offer__title icon-arrow-left">
        {{ $t('companies.panel-hunt-process.offer-received') }}
      </p>
      <div class="panel-process-details-job-offer__subwrapper">
        <label
          class="panel-process-details-job-offer__label"
          for="salary-fixed">
          {{ $t('companies.panel-hunt-process.gross-fixed-salary') }}
        </label>
        <bc-input-number
          :bc-input-border-color-opacity-blurred="0.5"
          bc-input-border-radius="2px"
          bc-input-padding="0"
          class="panel-process-details-job-offer__input"
          id="salary-fixed"
          max="200"
          min="0"
          v-model.number="salaryFixed">
        </bc-input-number>
        <p class="panel-process-details-job-offer__label">
          {{ $t('hunt.k-currency-year') }}
        </p>
      </div>
      <div class="panel-process-details-job-offer__subwrapper">
        <label
          class="panel-process-details-job-offer__label"
          for="salary-variable">
          + {{ $tc('generics.variable') }}
        </label>
        <bc-input-number
          :bc-input-border-color-opacity-blurred="0.5"
          bc-input-border-radius="2px"
          bc-input-padding="0"
          class="panel-process-details-job-offer__input"
          id="salary-variable"
          max="200"
          min="0"
          v-model.number="salaryVariable">
        </bc-input-number>
        <p class="panel-process-details-job-offer__label">
          {{ $t('hunt.k-currency-year') }}
        </p>
      </div>
    </div>
    <div class="panel-process-details-job-offer__container" v-else>
      <div class="panel-process-details-job-offer__wrapper">
        <div class="panel-process-details-job-offer__header">
          <p
            :class="{
              'panel-process-details-job-offer__title--red': process.failedAt,
            }"
            class="panel-process-details-job-offer__title icon-arrow-left">
            {{ $t('companies.panel-hunt-process.offer-received') }}
          </p>
          <bc-tag
            bc-background-color="red-mandy"
            class="panel-process-details-job-offer__tag"
            v-if="isFailed">
            {{ $t('generics.failure') }}
          </bc-tag>
        </div>
        <p class="panel-process-details-job-offer__text">
          {{ $tc('generics.variable', 2) }} :&nbsp;
          <span class="panel-process-details-job-offer__text--bold">
            {{ (process.companyOffer || {}).fixed
            }}{{ $t('hunt.k-currency-year') }} +
            {{ (process.companyOffer || {}).variable || 0
            }}{{ $t('hunt.k-currency-year') }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import BcInputNumber from '@/legacy/ui-kit/components/Input/BcInputNumber';
import BcTag from '@/legacy/ui-kit/components/Tag/BcTag';

export default {
  name: 'panel-process-details-job-offer',
  components: { BcTag, BcInputNumber },
  props: {
    process: {
      type: Object,
      default: () => ({
        _job: {},
      }),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    salaryFixed: {
      get() {
        return this.$route.query['new-offer-fixed'] >= 0
          ? this.$route.query['new-offer-fixed']
          : (this.process.companyOffer || {}).fixed;
      },
      set(value) {
        this.$router.replace({
          query: { ...this.$route.query, 'new-offer-fixed': value },
        });
      },
    },
    salaryVariable: {
      get() {
        return this.$route.query['new-offer-variable'] >= 0
          ? this.$route.query['new-offer-variable']
          : (this.process.companyOffer || {}).variable;
      },
      set(value) {
        this.$router.replace({
          query: { ...this.$route.query, 'new-offer-variable': value },
        });
      },
    },
    isFailed() {
      return this.process.failedAt && !this.process.dealtAt;
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-process-details-job-offer {
  flex-direction: column;
  padding: 15px 20px;

  &__name {
    font-weight: $font-weight-medium;
    font-size: $font-size-s;
    line-height: 17px;
    color: $color-secondary;
    margin-bottom: 15px;
  }

  &__title {
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    font-size: $font-size-s;
    color: $color-primary-dark;
    display: flex;
    align-items: center;

    &--red {
      color: $color-error;
    }

    &:before {
      margin-right: 10px;
      font-size: $font-size-l;
      transform: rotate(180deg);
    }
  }

  &__text {
    display: flex;
    color: $color-secondary;
    font-size: $font-size-s;
    line-height: 17px;
    padding-left: 28px;

    &--bold {
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      display: flex;
    }
  }

  &__container {
    justify-content: space-between;
    align-items: center;
  }

  &__header {
    align-items: center;
    margin-bottom: 5px;
  }

  &__tag {
    border-radius: 4px;
    font-size: $font-size-xs;
    padding: 3px 10px;
    margin-left: 10px;
  }

  &__wrapper {
    flex-direction: column;
  }

  &__subwrapper {
    align-items: center;
    padding-left: 28px;
    margin: 2.5px 0;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__label {
    color: $color-secondary;
    font-size: $font-size-s;
    line-height: 17px;
  }

  &__input {
    margin: 0 5px;
    max-width: 40px;
    text-align: center;
    min-height: 30px;
    font-size: $font-size-s;
  }
}
</style>
