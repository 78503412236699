<template>
  <div
    :class="timelineClass"
    :style="{ ...iconStyle, fontSize: '16px' }"
    class="timeline-item-icon">
    <img
      v-if="timelineClass.startsWith('img-')"
      class="timeline-item-img"
      :src="sourceIcon"
    />
  </div>
</template>

<script>
  import {
    processSteps,
    processCustomSteps,
    processCustomAborted,
    processActions,
    processCustomActions,
    timeline,
    timelineType,
    messagingActions,
  } from '@/common-old/macros/timeline/timeline';
  import timelineMacros from '@/managers/timeline/timelineMacros';
  import colors from '@/ui-kit/assets/scss/exports/_colors.module.scss';
  import { ProcessCustomCategoriesEnum } from '@/macros/processesCustom/process'
  import { mapGetters } from 'vuex';
  import messagingIcons from '@/components/Messaging/messagingIcons'
  import { sourceIcons } from '@/macros/sourceIcons';

  export default {
    name: 'timeline-item-icon',
    props: {
      action: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      timeline,
      timelineMacros,
      colors,
    }),
    computed: {
      ...mapGetters({
        configProcess: 'user/configurationProcess'
      }),
      isProcessStepIcon() {
        return processSteps.includes(this.action.type) || processCustomSteps.includes(this.action.type) || processCustomAborted.includes(this.action.type);
      },
      isProcessActionIcon() {
        return processActions.includes(this.action.type) || processCustomActions.includes(this.action.type)
      },
      timelineClass() {
        const maps = {
          pending: 'timeline-introduced',
          introduced: 'timeline-introduced',
          processed: 'timeline-processed',
          finalised: 'timeline-finalised',
          offered: 'timeline-finalised',
          dealt: 'timeline-dealt',
          onboarding: 'timeline-onboarding',
          validated: 'timeline-validated',
        };
        let iconName;
        const source = this.action.source || this.action?.data?.source
        if (source && sourceIcons[source]) {
          return 'img-source'
        } else if (Object.keys(messagingActions).includes(this.action.type?.toString())) {
          return 'img-messaging'
        } else if (this.action.type === timelineType.failedProcess || this.action.type === timelineType.unfailedProcess) {
          iconName = maps[this.action.data];
        } else if (processCustomActions.includes(this.action.type)) {
          iconName = 'target2'
        } else if (this.action.icon) {
          return `icon-${this.action.icon}`;
        } else {
          iconName = this.action._note && !this.action._suggestion ? this.timeline[this.action.type]?.icon?.[this.action._note.type] : this.timeline[this.action.type]?.icon;
          if (!iconName) {
            for (const timeline of this.timelineMacros) {
              if (timeline[this.action.type]?.icon) {
                iconName = timeline[this.action.type].icon;
                break;
              }
            }
          }
        }
        return `icon-${iconName}`;
      },
      sourceIcon() {
        if (this.timelineClass === 'img-messaging' && messagingActions[this.action.type]) {
          return messagingIcons[messagingActions[this.action.type]];
        }
        if (this.timelineClass === 'img-source' && sourceIcons[this.action.source || this.action.data?.source]) {
          return sourceIcons[this.action.source || this.action.data?.source];
        }
        return false
      },
      actionColor() {
        return this.timeline[this.action.type]?.iconColor || 'primary';
      },
      processColor() {
        const categoryKey = this.action?.data?.categoryKey
        let processColor = 'secondary'
        if (categoryKey === ProcessCustomCategoriesEnum.afterProcessRecruitment) {
          processColor = 'success'
        }
        if (this.action?.type === timelineType.processCustomAborted) {
          processColor = 'error'
        }
        return processColor;
      },
      iconStyle() {
        const normalStyle = {
          color: colors[this.actionColor],
          border: `2px solid ${colors[this.actionColor]}`,
        };

        const processStyle = {
          color: colors[this.processColor],
          fontSize: this.isProcessActionIcon ? '32px' : '18px',
          // border: `2px solid ${colors[this.processColor]}`,
        };

        const processAction = {
          color: colors[this.actionColor],
          fontSize: '32px',
          border: `2px solid ${colors[this.actionColor]}`,
        };


        const stylesMap = [
          {
            cond: this.isProcessStepIcon,
            style: processStyle,
          },
          {
            cond: this.isProcessActionIcon,
            style: processAction,
          },
        ];

        for (let i = 0; i < stylesMap.length; i++) {
          const { cond, style } = stylesMap[i];

          if (cond) {
            return style;
          }
        }

        return normalStyle;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .timeline-item-icon {
    font-size: $font-size-xs;
    border-radius: 100%;
    color: $color-primary;
    background: $color-blue-medium;
    align-items: center;
    justify-content: center;
    min-height: 35px;
    min-width: 35px;
    max-height: 35px;
    max-width: 35px;

    @include bp('tablet') {
      font-size: $font-size-m;
    }
  }

  .timeline-item-img {
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
  }
</style>
