<template>
  <mv-filter
    :label="$t('generics.professions')"
    icon="certificate"
    :count="selected.length"
  >
    <filter-content
      class="filter-professions__content"
      :label="$t('generics.professions')"
      icon="certificate"
    >
      <professions-autocomplete
        :selected="selected"
        :show-label="false"
        :filter-options="filterOptions"
        :selected-filter="selectedFilter"
        @select="handleSelect"
        @select-option="onSelectOption"
      ></professions-autocomplete>
    </filter-content>
  </mv-filter>
</template>

<script>
import MvFilter from '@/ui-kit/components/MvFilter/MvFilter';
import FilterContent from '@/components/Filter/FilterContent';
import ProfessionsAutocomplete from '@/components/Professions/ProfessionsAutocomplete';

export default {
  name: 'filter-professions',
  components: {
    MvFilter,
    ProfessionsAutocomplete,
    FilterContent,
  },
  mounted() {
    let selected = this.filterOptions[0];
    if (this.$route.query.professionsOperator) {
      selected = this.filterOptions.find(option => option.key === this.$route.query.professionsOperator);
    }
    this.selectedFilter = selected;
  },
  data() {
    return {
      filterOptions: [
        { key: 'and', name: this.$t('generics.and').toUpperCase() },
        { key: 'or', name: this.$t('generics.or').toUpperCase() },
      ],
      selectedFilter: {},
    }
  },
  computed: {
    professions() {
      return this.$route.query.professions;
    },
    professionsNames() {
      return this.$route.query.professionsNames;
    },
    selected() {
      if (!this.professions) {
        return [];
      }
      const professions = this.professions.split(',');
      const professionsNames = this.professionsNames.split(',');
      return professions.map((id, index) => ({
        _id: id,
        name: professionsNames[index],
      }))
    },
    selectedOperator() {
      return this.selectedFilter?.key || this.filterOptions[0]?.key;
    },
  },
  methods: {
    handleSelect(professions) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          professions: professions.length ? professions.map(p => p._id).join(',') : undefined,
          professionsNames: professions.length ? professions.map(p => p.name).join(',') : undefined,
          ...this.selectedOperator && { professionsOperator: this.selectedOperator },
        },
      }).catch(() => {});
    },
    onSelectOption(filter = {}) {
      this.selectedFilter = filter;
      if (this.$route.query.professions) {
        this.$router.push({
          query: {
            ...this.$route.query,
            professionsOperator: filter.key,
          }
        }).catch(() => {})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.filter-professions {
  &__content {
    min-width: 574px;;

    &:deep() {
      .bc-input {
        margin-top: 0;
      }
    }
  }
}
</style>
