export const actionsWithContent = [7, 16, 21, 29, 45, 66, 72, 101, 102, 114, 121, 135, 223, 137];
export const processActionsWithContent = {
  60: 'process-timeline-item-onboarding',
  61: 'process-timeline-item-validated',
};

export default {
  actionsWithContent,
  processActionsWithContent,
};
