import processes from '@/api/processes';
import { getStepParams } from '@/managers/processes/formatters/processSerializers';

export const moveProcess = async(id, step, params) => {
  const execute = async(id, step, params) => {
    try {
      const steParams = getStepParams(step, params);

      const { data: process } = await processes.updateProcessState({
        id,
        step,
        params: steParams,
      });

      return process;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  try {
    return execute(id, step, params);
  } catch (error) {
    throw error;
  }
};

export const failProcess = async id => {
  const execute = async id => {
    try {
      const { data: process } = await processes.postProcessFailed(id);

      return process;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  try {
    return execute(id);
  } catch (error) {
    throw error;
  }
};

export const unfailProcess = async id => {
  const execute = async id => {
    try {
      const { data: process } = await processes.postProcessUnfailed(id);

      return process;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  try {
    return execute(id);
  } catch (error) {
    throw error;
  }
};
