<template>
  <div class="panel-job-edit-caption col-xs-12">
    <title-section
      background-color="white"
      class="panel-job-edit-caption__title"
      color="#1D395E"
      position="left"
      :title="$t('companies.account-management.ad-name')">
    </title-section>
    <div class="panel-job-edit-caption__container">
      <ValidationProvider
        :rules="{ required: requiredJob, min: 2 }"
        :name="$t('companies.account-management.ad-name')"
        slim
        v-slot="{ errors }">
        <bc-input
          :bc-error-message="errors[0]"
          :bc-is-error="errors.length > 0"
          :class="{ 'invalid': errors.length > 0 }"
          bc-input-font-size="14px"
          class="panel-job-edit-caption__input"
          name="name"
          :placeholder="$t('companies.account-management.ad-placeholder-ex')"
          ref="name"
          type="text"
          v-model="name">
        </bc-input>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
  import BcInput from '@/legacy/ui-kit/components/Input/BcInputNew';
  import TitleSection from '@/components/Title/TitleSection';
  import { ValidationProvider } from 'vee-validate';

  export default {
    name: 'panel-job-edit-caption',
    components: {
      TitleSection,
      BcInput,
      ValidationProvider
    },
    props: {
      profile: {
        type: Object,
        default: () => ({})
      },
      editedProfile: {
        type: Object,
        default: () => ({})
      },
      requiredJob: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      name: {
        get() {
          return typeof this.editedProfile.name === 'string' && this.editedProfile.name !== this.profile.name ? this.editedProfile.name : this.profile.name;
        },
        set(value) {
          this.$emit('update-value', {
            name: value
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .panel-job-edit-caption {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    &__container {
      flex-direction: column;
      @include bp('tablet') {
        flex-direction: row;
      }
    }

    &__input {
      width: 100%;
    }

    &__title {
      margin-bottom: $panel-title-margin-bottom;
    }
  }
</style>
