<template>
  <div
    class="onboarding-section-item"
    :class="{ 'onboarding-section-item--active': selected }"
    @click="$emit('click', $event)">
    <div
      class="onboarding-section-item__count"
      :class="{ 'onboarding-section-item__count--active': selected }"
    >
      {{ number }}
    </div>
    <div class="onboarding-section-item__titles">
      <p
        class="onboarding-section-item__title body-l--bold"
        :class="{ 'onboarding-section-item__title--active': selected }"
      >
        {{ title }}
      </p>
      <p class="onboarding-section-item__text body-m">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'onboarding-section-item',
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    number: {
      type: Number,
    },
    selected: {
      type: Boolean,
    }
  }
}
</script>

<style lang="scss" scoped>
.onboarding-section-item {
  min-height: 80px;
  padding: 14px;

  &:hover {
    cursor: pointer;
    background: $color-neutre-4;
  }

  &--active {
    border-radius: 8px;
    background: $color-neutre-3;
  }

  &__count {
    width: 26px;
    height: 26px;
    padding: 10px;
    background: rgba(56, 101, 253, 0.10);
    color: $color-blue-heavy-dark;
    justify-content: center;
    border-radius: 100%;
    margin-right: 14px;
    align-items: center;
    font-weight: $font-weight-bold;
    font-size: $font-size-s;

    &--active {
      color: white;
      background: $color-primary;
    }
  }

  &__titles {
    flex-direction: column;
    gap: 3.5px;
  }

  &__title {
    color: $color-tertiary;

    &--active {
      color: $color-primary;
    }
  }

  &__text {
    max-width: 450px;
    color: $color-tertiary;
  }
}
</style>
