import { listenMessageHistories, getMessageHistories, setMessageClosedAt } from "../../../firebase"

export default {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {
    messageList(state) {
      return state.list[0];
    },
    hasActiveMessages(state) {
      return state.list[0]?.active || false;
    },
    isDone(state) {
      if (state.list.length) {
        return Object.keys(state.list[0]?.messages || {}).every(key => {
          const file = state.list[0].messages[key];
          return file.status !== 'PENDING';
        });
      }
      return false;
    },
    progressCount(state) {
      if (state.list.length) {
        let count = 0;
        Object.keys(state.list[0]?.messages || {}).forEach(key => {
          const file = state.list[0].messages[key];
          if (file.status !== 'PENDING') {
            count++;
          }
        });
        return count;
      }
      return 0;
    },
  },
  actions: {
    async getMessageHistories({ commit }, { coachId }) {
      try {
        const list = await getMessageHistories({ coachId });
        commit('setList', list);
      } catch (err) {
        console.log('err getMessageHistories', err)
      }
    },
    async listenMessageHistories({ commit }, { coachId }) {
      try {
        const listener = await listenMessageHistories({ coachId }, ({ type, data }) => {
          console.log('listenMessageHistories', { type, data });
          if (type === 'modified') {
            commit('updateList', data);
          }
          if (type === 'added') {
            commit('setList', [data]);
          }
        });
        return listener;
      } catch (err) {
        console.log('errrr', err);
      }
    },
    async setClosedAt({ commit }, { coachId, groupId }) {
      await setMessageClosedAt({ coachId, groupId });
    }
  },
  mutations: {
    setList(state, list) {
      state.list = list;
    },
    updateList(state, item) {
      const findedIndex = state.list.findIndex(it => it.groupId === item.groupId);
      if (findedIndex !== -1) {
        const list = [...state.list];
        list[findedIndex] = item;
        state.list = [...list];
      }
    }
  },
}
