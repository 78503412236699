<script setup lang="ts">
import { ref, watch, computed, defineEmits, watchEffect, onMounted } from 'vue';
import debounce from 'debounce';
import { useRoute, useRouter } from 'vue-router/composables';

import BcInput from '@/ui-kit/components/BcInput/BcInput';
import FilterContent from '@/components/Filter/FilterContent';
import Tag from '@/components/Tag/Tag';

const props = defineProps<{
  emitOnly?: boolean;
}>();

const emits = defineEmits<{
  (e: 'count', count: { key: string; count: number }): void,
  (e: 'update:filters', filters: Record<string, any>): void,
}>();

const search = ref('');
const route = useRoute();
const router = useRouter();

const localUniqueIds = ref<string[]>([]);

onMounted(() => {
  if (props.emitOnly && route.query.uniqueids) {
    localUniqueIds.value = route.query.uniqueids.toString().split(',');
  }
});

const uniqueIdTags = computed(() => {
  if (props.emitOnly) {
    return localUniqueIds.value;
  }
  return route.query.uniqueids?.split(',');
});

const searchUniqueId = debounce(function(value: string) {
  let uniqueIds: string[] = [];

  if (props.emitOnly) {
    uniqueIds = [...localUniqueIds.value];
  } else if (route.query.uniqueids) {
    uniqueIds = route.query.uniqueids.toString().split(',');
  }

  if (uniqueIds.includes(value)) {
    uniqueIds = uniqueIds.filter(uniqueid => uniqueid !== value);
  } else {
    uniqueIds.push(value);
  }

  const uniqueidsQuery = uniqueIds.length ? uniqueIds.join(',') : undefined;

  if (props.emitOnly) {
    localUniqueIds.value = uniqueIds;
    emits('update:filters', {
      uniqueids: uniqueidsQuery,
    });
  } else {
    router.push({
      query: {
        ...route.query,
        uniqueids: uniqueidsQuery,
      }
    });
  }

  search.value = '';
}, 500, false);

const onRemoveTag = (uniqueid: string) => {
  const uniqueIds = uniqueIdTags.value?.filter(id => id !== uniqueid) || [];
  const uniqueidsQuery = uniqueIds.length ? uniqueIds.join(',') : undefined;

  if (props.emitOnly) {
    localUniqueIds.value = uniqueIds;
    emits('update:filters', {
      uniqueids: uniqueidsQuery,
    });
  } else {
    router.push({
      query: {
        ...route.query,
        uniqueids: uniqueidsQuery,
      }
    });
  }
};

watch(search, (value) => {
  if (value) {
    searchUniqueId(value);
  }
});

watchEffect(() => {
  emits('count', uniqueIdTags.value?.length ? { key: 'uniqueids', count: 1 } : { key: 'uniqueids', count: 0 });
})

</script>

<template>
  <FilterContent class="filter-coders-min-evaluation">
    <BcInput
      name="uniqueid-filter"
      label="Recherche candidat par numéro d’identification"
      icon="user"
      :labelPlaceholder="false"
      placeholder="Exemple : 1234567"
      v-model="search"
    />
    <div class="mt-2 gap-2">
      <tag
        v-for="uniqueid in uniqueIdTags"
        icon="user"
        :key="uniqueid"
        :text="uniqueid"
        :canClose="true"
        @click="onRemoveTag(uniqueid)"
      />
    </div>
  </FilterContent>
</template>
