<template>
  <content-loader
    class="placeholder-lines"
    :height="totalHeight"
    :width="width"
    :primary-color="primaryColor"
    :secondary-color="secondaryColor">
    <template v-for="(row, i) in rows">
      <rect
        v-for="(placeholder, index) in row"
        :key="`${i}-${index}`"
        :x="placeholder.xPosition"
        :y="getYPos(i, 3)"
        :rx="radius"
        :ry="radius"
        :width="placeholder.width"
        :height="height" />
    </template>
  </content-loader>
</template>

<script>
  import { ContentLoader } from 'vue-content-loader';

  /**
   * The component which will be displayed instead of an empty carousel
   */
  export default {
    name: 'placeholder-lines',
    components: {
      ContentLoader
    },
    props: {
      /**
       * The primary color
       */
      primaryColor: {
        type: String,
        default: '#727a89'
      },
      /**
       * The reflect color
       */
      secondaryColor: {
        type: String,
        default: '#929db0'
      },
      /**
       * The number of line that the component should show
       */
      rowsNumber: {
        type: Number,
        default: 1
      },
      /**
       * The height of the element
       */
      width: {
        type: Number,
        default: 500
      },
      /**
       * The height of the element
       */
      height: {
        type: Number,
        default: 5
      },
      /**
       * The radius of the element
       */
      radius: {
        type: Number,
        default: 2
      },
      /**
       * Space between rows
       */
      rowsMargin: {
        type: Number,
        default: 15
      },
      /**
       * The minimum elements that should be present
       */
      minElements: {
        type: Number,
        default: 2
      },
      /**
       * The maximum elements that should be present
       */
      maxElements: {
        type: Number,
        default: 4
      },
      /**
       * The min-width of the elements
       */
      minWidth: {
        type: Number,
        default: 30
      },
      /**
       * The max-width of the elements
       */
      maxWidth: {
        type: Number,
        default: 100
      }
    },
    methods: {
      /**
       * Method to calculate the Y position of the element
       * @param row
       * @param plus
       * @returns {*}
       */
      getYPos(row, plus) {
        // eslint-disable-next-line
        return plus + ((row) * this.rowsMargin);
      },
      /**
       * Method to generate placeholders with random width
       * @returns {any[]}
       */
      setRandomPlaceholders() {
        /**
         * We create an array of a random length and fill it with a random width
         * @type {{width: number}[]}
         */
        const placeholders = Array.from(
          {
            length: Math.floor(Math.random() * this.maxElements) + this.minElements
          }, () => (
            {
              width: Math.floor(Math.random() * this.maxWidth) + this.minWidth
            }
          ));
        /**
         * The margin between all elements
         * @type {number}
         */
        const margin = 5;
        /**
         * We declare a variable to calculate the X position of the element
         * @type {number}
         */
        let distance = 0;
        /**
         * We return our array with the position and the width of each element
         */
        return placeholders.map((placeholder, index) => {
          // eslint-disable-next-line
          distance = index === 0 ? 0 : (distance + placeholders[index - 1].width + margin);
          return {
            width: placeholder.width,
            xPosition: distance
          };
        });
      }
    },
    computed: {
      /**
       * We calculate the total height of the placeholder
       * @returns {number}
       */
      totalHeight() {
        return this.rowsNumber * this.rowsMargin + 5;
      },
      /**
       * We create an array of rows with random elements depending of the rowNumber props
       * @returns {Array}
       */
      rows() {
        const rows = [];
        for (let i = 0; i < this.rowsNumber; i++) {
          rows.push(this.setRandomPlaceholders());
        }
        return rows;
      },
    }
  };
</script>

<style scoped lang="scss">
  .placeholder-lines {
    height: 100%;
    & :deep() {
      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
</style>
