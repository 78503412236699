<script lang="ts" setup>
  import { defineEmits, defineProps } from 'vue';
  import { Opportunity } from '@/domains/models/Opportunity';
  import PanelCommonDescriptionView
    from '@/components/Panel/PanelCommon/PanelCommonDescription/PanelCommonDescriptionView.vue';
  import PanelCommonDescriptionEdit
    from '@/components/Panel/PanelCommon/PanelCommonDescription/PanelCommonDescriptionEdit.vue';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { updateCustomJob } from '@/api/custom-jobs';

  interface Props {
    opportunity: Opportunity;
    isEditing: boolean;
  }

  const props = defineProps<Props>();
  const emits = defineEmits<{
    (e: 'update-opportunity', value: Opportunity): void,
    (e: 'close-edit'): void
    (e: 'save-text', value: string): void
  }>();

  const toast = useToast();

  const saveDescription = async(value) => {
    try {
      await updateCustomJob({
        id: props.opportunity._id,
        profileDescription: value,
      });
      emits('update-opportunity', { profileDescription: value });
    } catch (error) {
      toast.show({
        message: error.message,
        type: 'error',
      });
    }
  };
</script>

<template>
  <PanelCommonDescriptionView
    v-if="!isEditing"
    :text="opportunity.profileDescription"
  />
  <PanelCommonDescriptionEdit
    v-else
    :text="opportunity.profileDescription"
    :title="$tc('generics.wanted-profile-cap', 1)"
    @close-edit="$emit('close-edit')"
    @save-text="saveDescription"
  />
</template>

<style scoped>

</style>
