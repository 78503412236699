<script lang="ts" setup>
  import { Candidate } from '@/domains/models/Candidate';
  import { computed, defineEmits, defineProps } from 'vue';
  import { useStore } from '@/store';
  import fallbackImg from '@/assets/img/default-avatar-company.svg';
  import linkedinImg from '@/assets/logo/linkedin.png';
  import BcAvatar from '@/ui-kit/components/BcAvatar';
  import { RouterLink } from 'vue-router';
  import { useRoute } from 'vue-router/composables';

  interface Props {
    candidate: Candidate;
  }

  const props = defineProps<Props>();
  const emits = defineEmits<{
    (e: 'update:is-editing', value: boolean): void,
    (e: 'update-candidate', value: Candidate): void
  }>();

  const route = useRoute();

  const activeEmployees = computed(() => props.candidate._employees?.filter((employee) => employee.active) || []);
  const activeCompanies = computed(() => activeEmployees.value?.map(employee => employee._company) || []);

  // company status
  const store = useStore();
  const organization = store.state.user?.profile?._organization;
  const prospectionCategories = organization.configuration.prospection?.categories || [];
  const prospectionStatus = computed(() => prospectionCategories?.reduce((acc, category) => {
    return [...acc, ...category.steps.map(step => ({ ...step, categoryKey: category.key }))];
  }, []));
  const getCompanyStatus = company => prospectionStatus.value?.find(step => step.key === company.customStatus?.id);


  const setAlternativeImg = (event: any) => {
    event.target.src = fallbackImg;
  };
</script>

<template>
  <div
    v-if="activeCompanies.length"
    class="flex flex-col w-full">
    <RouterLink
      v-for="activeCompany in activeCompanies"
      :key="activeCompany._id"
      :to="{ name: route.name, params: { id: activeCompany?._id }, query: { ...route.query, type: 'companies'} }"
      class="flex w-full items-center rounded-md bg-neutral-200 px-5 py-4 hover:opacity-75"
    >
      <BcAvatar
        :src="activeCompany.logoUrl"
        size="s"
        @error="setAlternativeImg"
      />
      <p class="ml-2.5 text-sm font-semibold text-blue-500 truncate shrink-0">
        {{ activeCompany?.name }}
      </p>
      <div class="flex flex-row gap-2 shrink-0 ml-auto">
        <a
          :class="{ 'grayscale': !activeCompany.social?.linkedin }"
          :href="activeCompany.social?.linkedin || '#'"
          class="text-sm"
          target="_blank"
          @click.stop
        >
          <div class="flex items-center justify-center">
            <img
              :src="linkedinImg"
              alt="linkedin"
              class="size-5"
            />
          </div>
        </a>
        <div class="flex items-center justify-center text-blue-800 hover:text-blue-500 mr-2">
          <a
            :class="{ 'grayscale': !activeCompany.social?.website }"
            :href="activeCompany.social?.website"
            class="text-sm"
            target="_blank"
            @click.stop
          >
            <i class="icon-website text-[20px]"/>
          </a>
        </div>
      </div>
      <div class="min-w-[120px]">
        <p
          :class="[
        'inline-flex min-h-[20px] items-center rounded-[16px] px-2.5 text-xs shrink-0 ml-auto',
        {
          'bg-[#DDF4E9] text-green-500': getCompanyStatus(activeCompany)?.categoryKey === prospectionCategories[1]?.key,
          'bg-red-50 text-red-500': getCompanyStatus(activeCompany)?.categoryKey === prospectionCategories[2]?.key,
          'bg-[#D8E0FC] text-blue-500': getCompanyStatus(activeCompany)?.categoryKey !== prospectionCategories[1]?.key && getCompanyStatus(activeCompany)?.categoryKey !==
            prospectionCategories[2]?.key
        }
      ]">
          {{ getCompanyStatus(activeCompany)?.name }}
        </p>
      </div>
    </RouterLink>
  </div>
  <div
    v-else
    class="flex w-full items-center rounded-md bg-neutral-200 p-2.5"
  >
    <p class="text-sm text-blue-800">
      {{ $t('generics.no-company', 0) }} {{ $t('generics.associated') }}
    </p>
  </div>
</template>

<style scoped></style>
