<template>
  <div class="contacts-empty is-full-width is-justify-content-center is-column is-align-items-center">
    <h1 class="contacts-empty__title font-size-xl is-secondary mb-3">
      {{ title }}
    </h1>
    <database-contacts-button-create v-if="showCreateButton"></database-contacts-button-create>
  </div>
</template>

<script>
  import DatabaseContactsButtonCreate
    from '@/components/DatabaseContacts/DatabaseContactsButton/DatabaseContactsButtonCreate';

  export default {
    name: 'contacts-empty',
    components: { DatabaseContactsButtonCreate },
    props: {
      isChecked: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: ''
      },
      showCreateButton: {
        type: Boolean,
        default: false
      }
    },
  };
</script>

<style lang=scss scoped>
  .contacts-empty {
    &__text {
      max-width: 800px;
    }

  }
</style>
