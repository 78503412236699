<template>
  <div class="searchbar-filters">
    <div class="searchbar-filters__container">
      <searchbar-filters-tags
        @on-delete="emitDeleteTag"
        class="searchbar-filters__tags">
      </searchbar-filters-tags>
      <slot></slot>
      <searchbar-filters-buttons class="searchbar-filters__wrapper">
      </searchbar-filters-buttons>
    </div>
    <searchbar-buttons-reset
      @on-delete="emitReset"
      class="searchbar-filters__button">
    </searchbar-buttons-reset>
  </div>
</template>

<script>
  // TODO move or refacto to ui-kit
  import SearchbarFiltersButtons
    from '@/components/Searchbar/SearchbarFilters/SearchbarFiltersButtons';
  import SearchbarFiltersTags from '@/components/Searchbar/SearchbarFilters/SearchbarFiltersTags';
  import SearchbarButtonsReset from '@/components/Searchbar/SearchbarButtons/SearchbarButtonsReset';

  export default {
    name: 'searchbar-filters',
    components: {
      SearchbarButtonsReset,
      SearchbarFiltersTags,
      SearchbarFiltersButtons,
    },
    methods: {
      emitDeleteTag() {
        this.$emit('on-delete');
      },
      emitReset() {
        this.$emit('on-reset');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .searchbar-filters {
    flex-direction: column;

    &__container {
      border-radius: 4px;
      color: $color-blue-san-juan;
      font-size: $font-size-s;
      background: $color-white;
      flex-direction: column;
      @include bp('tablet') {
        @include box-shadow-default;
      }
    }

    &__button {
      margin: 20px auto 0;
    }

    &__wrapper {
      padding: 20px;
      border-bottom: 1px solid rgba($color-gray-dove, 0.1);
      width: 100%;
      @include bp('tablet') {
        padding: 20px 50px;
      }

      &:last-of-type {
        border-bottom: 0;
      }
    }

    &__tags {
      padding: 20px;
      border-bottom: 1px solid rgba($color-gray-dove, 0.1);
      width: 100%;
    }

    &__text {
      justify-content: center;
      text-align: center;
    }
  }
</style>
