<script lang="ts" setup>
  import { Company } from '@/domains/models/Company';
  import { defineEmits, defineProps, Ref, ref } from 'vue';
  import PanelCompanyTopView from '@/components/Panel/PanelCompany/PanelCompanyTop/PanelCompanyTopView.vue';
  import PanelCompanyTopEdit from '@/components/Panel/PanelCompany/PanelCompanyTop/PanelCompanyTopEdit.vue';
  import { Employee } from '@/domains/models/Employee';

  interface Props {
    company: Company;
    employees?: Employee[];
  }

  defineProps<Props>();
  defineEmits<{ (e: 'edit-company', value: boolean): void, (e: 'update-company', value: Company): void }>();

  const isEditing: Ref<boolean> = ref(false);
</script>

<template>
  <PanelCompanyTopView
    v-if="!isEditing"
    :company="company"
    :employees="employees"
    @edit-company="isEditing = true"
  />
  <PanelCompanyTopEdit
    v-else
    :company="company"
    class="px-4"
    @update-company="$emit('update-company', $event)"
    @edit-company="isEditing = false"/>
</template>

<style scoped></style>
