<template>
  <div class="title-section">
    <span
      :class="[{ 'title-section__divider--left': position === 'left' }, `title-section__divider--${color}`]"
      class="title-section__divider">
    </span>
    <h1
      class="title-section__title"
      :class="[
        {'title-section__title--left': position === 'left'},
        {'title-section__title--decorated': $slots.decoration },
        `title-section__title--${color}`]"
      :style="titleStyle">
      <i v-if="icon && iconLeft" :class="`icon-${icon}`"></i> {{ title }} <i v-if="icon && !iconLeft" :class="`icon-${icon}`"></i>
    </h1>
    <slot name="decoration"></slot>
    <span
      :class="[`title-section__divider--${color}`]"
      class="title-section__divider">
    </span>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'title-section',
    props: {
      /**
       * Set the title
       */
      title: {
        type: String,
        default: 'Title'
      },
      /**
       * Set the title position
       */
      position: {
        type: String,
        default: 'center'
      },
      /**
       * Set the color of the title and the divider
       */
      color: {
        type: String,
      },
      /**
       * Set the background of the title and will apply the class &-bg-$backgroundColor
       * 'gray'
       */
      backgroundColor: {
        type: String
      },
      icon: {
        type: String,
      },
      iconLeft: {
        type: Boolean,
      },
      titleStyle: {
        type: String,
      }
    }
  };
</script>

<style lang="scss" scoped>
  .title-section {
    width: 100%;
    position: relative;
    align-items: center;
    &__title {
      display: flex;
      gap: 10px;
      align-items: center;
      flex-shrink: 0;
      align-items: center;
      padding: 0 20px;
      font-size: $font-size-m;
      font-weight: $font-weight-bold;
      color: $color-tertiary;
      text-align: center;
      max-width: 100%;

      &--left {
        padding-left: 0;
        margin: 0;
      }
      &--blue {
        color: $color-blue;
      }
      &--regular {
        color: $color-secondary-dark;
      }
      &--decorated {
        padding-right: 0;
      }
    }
    &__divider {
      line-height: 1em;
      position: relative;
      outline: 0;
      border: 0;
      width: 100%;
      align-items: center;
      &:before {
        content: '';
        background: $color-blue-medium;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
      }
      &--blue {
        color: $color-blue;
        &:before {
          background: $color-blue;
        }
        &:after {
          color: $color-blue;
        }
      }
      &--regular {
        color: $color-secondary-dark;
        &:before {
          background: $color-secondary-dark;
        }
        &:after {
          color: $color-secondary-dark;
        }
      }
      &--left {
        width: 0;
      }
    }
  }
</style>
