<template>
  <div class="associated-company-autocomplete is-column">
    <div class="is-align-items-center is-full-width">
      <bc-autocomplete
        v-model="searchInput"
        :is-scrollable="false"
        :keep-first="true"
        :label="$t('form.associated-company')"
        :loading="isAutocompleteLoading"
        :options="companiesList"
        :placeholder="$t('companies.account-management.companies-data-basis')"
        class="associated-company-autocomplete__autocomplete is-full-width"
        icon="tool"
        is-dropdown-full-width
        name="associated-companu"
        @dropdown="onDropdown"
        @input="getSuggestions"
        @select="handleSelect">
        <template #empty>
          <bc-dropdown-item
            :text="$t('topbar.no-company-found')"
            class="associated-company-autocomplete__option font-size-s is-expanded">
          </bc-dropdown-item>
        </template>
        <template #option="{ option }">
          <bc-dropdown-item
            :text="option.name"
            class="associated-company-autocomplete__option font-size-s is-expanded">
          </bc-dropdown-item>
        </template>
      </bc-autocomplete>
    </div>
    <div v-if="selectedCompanies.length" class="associated-company-autocomplete__tags">
      <bc-tag
        v-for="company in selectedCompanies"
        :key="company._id"
        :can-close="true"
        deletable
        size="small"
        type="rounded"
        @click="removeCompany(company)">
        <div class="flex is-align-items-center">
          <img
            :src="company.logoUrl"
            class="associated-company-autocomplete__logo"/>
          {{ company.name }}
        </div>
      </bc-tag>
    </div>
  </div>
</template>

<script>
  import debounce from 'debounce';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import searchbar from '@/common-old/mixins/Searchbar/searchbar';
  import { getPartnerProfile, partnersAutocomplete } from '@/api/company';
  import BcTag from '@/ui-kit/components/BcTag/BcTag.vue';

  export default {
    name: 'associated-company-autocomplete',
    components: {
      BcTag,
      BcDropdownItem,
      BcAutocomplete,
    },
    mixins: [searchbar],
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
      editedProfile: {
        type: Object,
        default: () => ({}),
      },
      selected: {
        type: Array,
        default: () => [],
      },
      showLabel: {
        type: Boolean,
        default: true,
      },
      selectedCompanyId: {
        type: String,
      },
    },
    async mounted() {
      if (this.selected.length) {
        this.selectedCompanies = this.selected.filter(employee => employee.active)?.map(e => e._company);
      }
    },
    data() {
      return {
        searchInput: '',
        selectedCompanies: [],
        isAutocompleteLoading: false,
        isDisabled: false,
        companiesList: [],
        isDropdownOpen: false,
      };
    },
    methods: {
      getSuggestions: debounce(async function() {
        try {
          this.isAutocompleteLoading = true;
          const { data } = await partnersAutocomplete(this.searchInput);

          this.companiesList = data;
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err.message,
          });
        }
        this.isAutocompleteLoading = false;
      }, 300),
      onDropdown(isOpen) {
        this.isDropdownOpen = isOpen;
      },
      handleSelect(company) {
        this.$emit('select-company', [...this.selectedCompanies, company]);
        if (!this.selectedCompanies.some(c => c._id === company._id)) {
          this.selectedCompanies.push(company);
        }
        this.searchInput = '';
      },
      removeCompany(company) {
        this.$emit('select-company', this.selectedCompanies.filter(c => c._id !== company._id));
        this.selectedCompanies = this.selectedCompanies.filter(c => c._id !== company._id);
      },
      async setSelectedCompany(id) {
        const { data: company } = await getPartnerProfile(id);
        this.handleSelect(company);
      },
    },
    watch: {
      selectedCompanyId(id) {
        this.setSelectedCompany(id);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .associated-company-autocomplete {
    &__create_industry {
      width: 100%;
      flex-direction: column;
      position: absolute;
      background: white;
      border-radius: 5px;

      &-header {
        padding: 15px;
        border-bottom: 1px solid gray;
        width: 100%;

        & > p {
          width: 100%;
          text-align: center;
        }
      }

      &-content {
        flex-direction: column;
        padding: 15px;
      }
    }

    &__autocomplete {
      margin-bottom: 10px;
    }

    &__option {
      line-height: 15px;
      padding: 10px 20px;
    }

    &__wrapper {
      margin: -5px;
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    &__tag {
      border-radius: 30px;
      margin: 2px;
      font-size: $font-size-xxs;
      font-weight: $font-weight-bold;
      background: #ecf1fa;

      &:hover {
        cursor: pointer;
      }
    }

    &__disabled {
      opacity: 0.6;

      &:hover {
        cursor: not-allowed;
      }
    }

    &__logo {
      width: 13px;
      border-radius: 100%;
      margin-right: 5px;
    }

    :deep() .bc-input__wrapper {
      display: flex;
      flex-wrap: wrap;
      min-height: 41px;
    }

    :deep() .bc-input__label--focus {
      top: -20px;
      left: 5px;
      transform: none;
    }

    :deep() .bc-tag {
      background: #D8E0FC;
      color: #375276;
      font-weight: 500;
    }
  }
</style>
