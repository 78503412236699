import { http } from '@/api/index';

export const upload = ({ id, file }) => {
  return http.post(`/users/${id}/upload`, file);
};

interface UploadResult {
  data: {
    fileName: string;
    fileUrl: string;
  };
}

export async function uploadFile({ file }: { file: FormData }): Promise<UploadResult> {
  return http.post('/documents/upload', file);
}

export default { upload };
