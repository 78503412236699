<template>
  <div class="container">
    <configuration-multicast-tabs></configuration-multicast-tabs>
    <views-container>
      <div class="configuration-career-website">
        <router-view></router-view>
        <!-- Mon site carrière -->
        <div>
          <h2>{{ $t('custom-jobs.my-career-website') }}</h2>
          <div class="configuration-career-website__content">
            <bc-toggle
              id="activate-career-website"
              ref="activate-career-website"
              type="default"
              :value="configurationFeatures.career ? config.activated : false"
              @input="handleClickActivated"></bc-toggle>
            <label for="activate-career-website">{{
              $t('custom-jobs.activate-career-website')
            }}</label>
          </div>
        </div>

        <!-- Multidiff -->
        <div>
          <h2>{{ $t('multiposting.sorting-application') }}</h2>
          <div class="configuration-career-website__content">
            <bc-checkbox id="multiposting.notifyApplication" v-model="multiposting.notifyApplication">
              {{ $t('multiposting.notify-application') }}
            </bc-checkbox>
          </div>
          <div class="configuration-career-website__content">
            <bc-checkbox id="multiposting.sendEmailAfterApplicationTreated" v-model="multiposting.sendEmailAfterApplicationTreated">
              {{ $t('multiposting.send-email-process') }}
            </bc-checkbox>
          </div>
        </div>

        <!-- Mes options de diffusion -->
        <div>
          <h2>{{ $t('custom-jobs.my-distribution-options') }}</h2>
          <div class="configuration-career-website__content">
            <bc-checkbox id="anonymize-my-offers" v-model="config.anonymize">
              {{ $t('custom-jobs.anonymize-my-offers') }}
            </bc-checkbox>
            <div
              :class="{
                'icon-alert-circle-full': isHoveringAnonymize,
                'icon-alert-circle': !isHoveringAnonymize,
              }"
              v-tooltip.bottom="{ content: $t('custom-jobs.anonymize-my-offers-explanation') }"
              @mouseout="isHoveringAnonymize = false"
              @mouseover="isHoveringAnonymize = true">
            </div>
            <bc-checkbox
              class="ml-5"
              id="activate-spontaneous-applications"
              v-model="config.activateSpontaneousApplications">
              {{ $t('custom-jobs.activate-spontaneous-applications') }}
            </bc-checkbox>
            <div
              :class="{
                'icon-alert-circle-full': isHoveringSpontaneous,
                'icon-alert-circle': !isHoveringSpontaneous,
              }"
              v-tooltip.bottom="{
                content: $t('custom-jobs.activate-spontaneous-applications-explanation'),
              }"
              @mouseout="isHoveringSpontaneous = false"
              @mouseover="isHoveringSpontaneous = true">
            </div>
          </div>
        </div>

        <h2>{{ $t('custom-jobs.customizing-my-career-site') }}</h2>
        <!-- URL de mon site carrière -->
        <div>
          <h3 class="body-m--medium">{{ $t('custom-jobs.url-career-website') }}</h3>
          <div class="configuration-career-website__content">
            <div class="configuration-career-website__url">
              <input :value="careerWebsiteUrl" :disabled="true" />
              <button
                v-tooltip="{
                  content: isCopiedUrl ? $t('generics.copied') : undefined,
                  hideOnTargetClick: false,
                  trigger: 'manual',
                  show: isCopiedUrl,
                }"
                v-clipboard:copy="careerWebsiteUrl"
                v-clipboard:success="setIsCopiedUrl">
                <i class="icon-clipboard-2"></i>
              </button>
            </div>
          </div>

          <!-- Personnalisation de mon site carrière -->
          <div class="is-column mt-5">
            <h3 class="body-m--medium">{{ $t('generics.btn-color') }}</h3>
            <div class="mt-2 configuration-career-website__btn-color">
              <input type="color" v-model="config.btnColor" />
              <input type="text" v-model="config.btnColor" />
            </div>
            <h3 class="mt-5 body-m--medium">{{ $t('generics.logo') }}</h3>
            <div class="configuration-career-website__logo" v-if="config.logoUrl || isUploading">
              <div class="configuration-career-website__logo--loader" v-if="isUploading">
                <bc-spinner></bc-spinner>
              </div>
              <div v-if="config.logoUrl">
                <img :src="config.logoUrl" />
                <div>
                  <bc-button
                    @click="config.logoUrl = ''"
                    iconLeft="trash"
                    color="error"></bc-button>
                </div>
              </div>
            </div>
            <upload-container
              v-else
              class="mt-2"
              accept="image/*"
              @on-cropped="handleCroppedImage"
              :supportedFormats="['png', 'jpg', 'jpeg']"
              :useCropper="true"
              :cropperOptions="{
                aspectRatio: 15 / 6,
              }"
              :croppedImageSize="{
                imageSmoothingQuality: 'high',
                width: 600,
                height: 240,
              }">
              <p style="font-size: 12px"> max 200x80px </p>
            </upload-container>
          </div>
        </div>

        <div>
          <h3 class="body-m--medium">{{ $t('custom-jobs.logo-redirection-url') }}</h3>
          <div class="configuration-career-website__content">
            <div class="configuration-career-website__url">
              <input v-model="config.logoRedirectionUrl" placeholder="http://jobs.marvinrecruiter.com/nomOrga" />
              <button
                v-tooltip="{
                  content: isCopiedLogoRedirectionUrl ? $t('generics.copied') : undefined,
                  hideOnTargetClick: false,
                  trigger: 'manual',
                  show: isCopiedLogoRedirectionUrl,
                }"
                v-clipboard:copy="config.logoRedirectionUrl"
                v-clipboard:success="setIsCopiedLogoRedirectionUrl">
                <i class="icon-clipboard-2"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </views-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { upload } from '@/api/upload';
import { Modals } from '@/store/modules/app';

import BcToggle from '@/ui-kit/components/BcToggle/BcToggle';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import UploadContainer from '@/components/UploadContainer/UploadContainer';
import ViewsContainer from '@/components/Views/ViewsContainer';
import ConfigurationMulticastTabs from './Tabs/ConfigurationMulticastTabs';
import { getCareerUri } from '@/helpers/url/career_url';

export default {
  name: 'configuration-career-website',
  components: {
    BcButton,
    BcToggle,
    BcCheckbox,
    BcSpinner,
    UploadContainer,
    ViewsContainer,
    ConfigurationMulticastTabs,
  },
  mounted() {
    if (this.organization.configuration?.career) {
      this.config = {
        ...this.config,
        ...this.organization.configuration.career,
      };
    }
    if (this.organization.configuration?.multiposting) {
      this.multiposting = {
        ...this.multiposting,
        ...this.organization.configuration.multiposting,
      };
    }
  },
  data() {
    return {
      isHoveringAnonymize: false,
      isHoveringSpontaneous: false,
      isCopiedUrl: false,
      isCopiedLogoRedirectionUrl: false,
      isUploading: false,
      config: {
        activated: false,
        anonymize: false,
        activateSpontaneousApplications: false,
        btnColor: '#000000',
        logoUrl: '',
        logoRedirectionUrl: '',
      },
      multiposting: {
        showStats: false,
        sendEmailAfterApplicationTreated: false,
        notifyApplication: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      organization: 'user/organization',
      configurationFeatures: 'user/configurationFeatures',
    }),
    careerWebsiteUrl() {
      return getCareerUri(this.organization.name) || 'http://jobs.marvinrecruiter.com/nomOrga';
    },
    profile() {
      return this.$store.state.user.profile;
    },
  },
  methods: {
    ...mapActions({
      updateConfigurationOrganizationByKey: 'user/updateConfigurationOrganizationByKey',
      setModal: 'app/setModal',
    }),
    handleClickActivated() {
      if (!this.configurationFeatures.career) {
        this.$refs['activate-career-website'].$el.children[1].value = false;
        this.$refs['activate-career-website'].$el.children[1].checked = false;
        this.setModal(Modals['modal-feature-career']);
        return;
      }
      this.config.activated = !this.config.activated;
    },
    async updateConfig() {
      try {
        await this.updateConfigurationOrganizationByKey({ key: 'career', ...this.config });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    async updateMultiposting() {
      try {
        await this.updateConfigurationOrganizationByKey({ key: 'multiposting', ...this.multiposting });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
    setIsCopiedUrl() {
      this.isCopiedUrl = true;
      setTimeout(() => {
        this.isCopiedUrl = false;
      }, 2300);
    },
    setIsCopiedLogoRedirectionUrl() {
      this.isCopiedLogoRedirectionUrl = true;
      setTimeout(() => {
        this.isCopiedLogoRedirectionUrl = false;
      }, 2300);
    },
    async handleCroppedImage(file) {
      this.isUploading = true;
      try {
        const dataToSend = new FormData();
        dataToSend.append('file', file);
        dataToSend.append('uploadType', 'picture');
        const { data } = await upload({
          id: this.profile._id,
          file: dataToSend,
        });
        this.config.logoUrl = data.fileUrl;
        this.isUploading = false;
        this.$toast.show({
          type: 'success',
          title: this.$i18n.t('toast.congratulations'),
          message: this.$i18n.t('toast.file-received-for-bdd-integration'),
        });
      } catch (err) {
        this.isUploading = false;
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
  },
  watch: {
    config: {
      async handler(newValue) {
        await this.updateConfig(newValue);
      },
      deep: true,
    },
    multiposting: {
      async handler(newValue) {
        await this.updateMultiposting(newValue);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
}
.configuration-career-website {
  width: 100%;
  flex-direction: column;
  gap: 30px;

  & > div {
    display: block;
  }

  & h2 {
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: 19px;
    color: $color-blue-dark-cello;
  }

  & label {
    font-size: 15px;
    line-height: 18px;
    color: $color-blue-dark-cello;
  }

  & p {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: $color-blue-dark-cello;
  }

  &__content {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
  }

  &__btn-color {
    background: white;
    border: 1px solid $color-blue-medium;
    border-radius: 5px;
    padding: 5px;
    width: 150px;
    align-items: center;
    display: flex;

    & > input {
      width: 50%;
      margin: 0 5px;
    }
  }

  &__url {
    background: white;
    border: 1px solid $color-blue-medium;
    border-radius: 5px;
    overflow: hidden;
    & > span,
    & > input {
      padding: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $color-blue-dark-cello;
      opacity: 0.5;
      padding-right: 50px;

      &::placeholder, &:disabled {
        color: $color-blue-heavy-dark;
        background: white;
      }
    }
    & > input {
      width: 500px;
      padding-right: 10px;
      opacity: 1;
    }
    & > button {
      border-left: 1px solid $color-blue-medium;
      padding: 10px;
    }
  }

  &__logo {
    margin-top: 10px;
    & > div {
      position: relative;
      align-items: center;
      justify-content: space-between;
      width: 405px;
      min-height: 110px;

      & > img {
        border: 1px solid rgba(29, 57, 94, 0.5);
        border-radius: 4px;
        width: 360px;
      }

      & button {
        width: 32px;
        height: 32px;
      }
    }

    &--loader {
      justify-content: center !important;
    }
  }
}
.icon-alert-circle-full:hover {
  cursor: pointer;
}

:deep() {
  .bc-checkbox__label {
    font-size: 15px;
    line-height: 18px;
    color: $color-blue-dark-cello;
  }
}
</style>
