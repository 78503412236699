export const buildCoderStatusTag = (query) => {
  const codersStatus = {
    all: 'Tous',
    pending: 'À traiter',
    coached: 'Coaché',
    online: 'En ligne',
    inactive: 'Inactif',
    banned: 'Banni'
  };

  return {
    name: codersStatus[query],
    query,
    type: 'status'
  };
};


export default {
  buildCoderStatusTag,
};
