<template>
  <views-tabs :tabs="tabs">
    <template #content>
      <slot></slot>
    </template>
  </views-tabs>
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from 'vue';
import { useStore } from '@/store';
import ViewsTabs from '@/components/Views/ViewsTabs.vue';
import { useNotesTemplatesStore } from '@/store/pinia/notesTemplates';
import { useI18n } from '@/i18n/i18n';
import { useLinkedinTemplatesStore } from '@/store/pinia/linkedinTemplates';

const store = useStore();
const emailsTemplates = computed(() => store.state.emails.templates);
const notesTemplatesStore = useNotesTemplatesStore();
const linkedinTemplatesStore = useLinkedinTemplatesStore();
const { t } = useI18n();

const profile = computed(() => store.state.user.profile);

const tabs = computed(() => [
  {
    key: 'email',
    label: t('timeline.emails'),
    to: '/configuration/templates/email',
    count: emailsTemplates.value.length,
  },
  {
    key: 'note',
    label: 'Notes',
    to: '/configuration/templates/notes',
    count: notesTemplatesStore.items.length,
  },
  {
    key: 'linkedin',
    label: 'Linkedin',
    to: '/configuration/templates/linkedin',
    count: linkedinTemplatesStore.items.length,
  },
]);

onBeforeMount(() => {
  linkedinTemplatesStore.fetchLinkedinTemplates({ coachId: profile.value._id, all: null })
})
</script>
