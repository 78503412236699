<template>
  <div class="organizations-modal-create is-column is-align-items-center">
    <p class="organizations-modal-create__title font-size-xl mb-4 font-weight-bold">
      Créer une organisation
    </p>
    <div class="is-column is-align-items-center is-full-width">
      <div
        v-if="selectedCompany._id"
        class="organizations-modal-create__wrapper is-align-items-center is-column mb-3">
        <bc-avatar
          v-if="selectedCompany.logoUrl"
          :alt="selectedCompany.name"
          :src="selectedCompany.logoUrl"
          class="organizations-modal-create__logo mb-2">
        </bc-avatar>
        <p class="organizations-modal-create__name is-secondary font-weight-medium">
          {{ selectedCompany.name }}
        </p>
      </div>
      <bc-input label="Nom de l'organisation" name="organizationName" v-model="organizationName"></bc-input>
    </div>
    <hr class="organizations-modal-create__separator mt-5 mb-5 full-width"/>
    <div class="organizations-modal-create__wrapper is-full-width is-justify-content-center">
      <bc-button
        class="organizations-modal-create__button mr-4"
        data-button-cancel
        type="outlined"
        @click="$emit('close-modal')">
        {{ $t('generics.cancel') }}
      </bc-button>
      <bc-button
        :disabled="!organizationName || isLoading"
        class="organizations-modal-create__button"
        color="success"
        data-button-validate
        @click="createOrganization">
        Créer l'organisation
      </bc-button>
    </div>
  </div>
</template>

<script>
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import BcInput from '@/ui-kit/components/BcInput/BcInput';
  import { createOrganization } from '@/api/organizations';
  import { createPartner, deletePartner } from '@/api/company';

  export default {
    name: 'organizations-modal-create',
    components: { BcButton, BcAvatar, BcInput },
    data() {
      return {
        isLoading: false,
        isNoResults: false,
        options: [],
        autocomplete: '',
        selectedCompany: {},
        organizationName: '',
        companyId: '',
      };
    },
    methods: {
      setIsLoading(value) {
        this.isLoading = value;
      },
      checkValue(value) {
        if (value === '') {
          this.options = [];
        }
      },
      emptySuggestions() {
        this.options = [];
      },
      setSelectedCompany(company) {
        this.emptySuggestions();
        this.autocomplete = company.name;
        this.selectedCompany = company;
      },
      async createOrganization() {
        this.setIsLoading(true);
        try {
          const { data: company } = await createPartner({
            name: this.organizationName,
          });
          this.companyId = company._id;
          const { data } = await createOrganization({ companyId: company._id, name: company.name });

          this.$emit('company-created', data);
          this.companyId = undefined;
          this.setIsLoading(false);
        } catch (error) {
          this.$toast.show({
            title: 'Erreur lors de la création de l\'organisation',
            type: 'error',
            message: error?.response?.data?.message || error,
          });
          if (this.companyId) {
            await deletePartner(this.companyId);
          }
          this.setIsLoading(false);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .organizations-modal-create {
    padding: 30px;
    width: 100%;
    max-width: 600px;

    @include bp('tablet') {
      min-width: 600px;
    }

    &__title {
      color: $color-secondary;
    }

    &__separator {
      width: 100%;
      border: 0;
      border-top: 1px solid rgba($color-secondary, 0.2);
    }
  }
</style>
