<template>
  <div v-if="!isSyncLoading" class="jobboard-publish-list">
    <jobboard-publish-item
      v-for="(jobboard, index) in list"
      :class="{ 'jobboard-publish-list--separator': index !== list.length - 1 }"
      :key="jobboard.key"
      :value="jobboard.published"
      :label="jobboard.label"
      :logo="jobboard.logo"
      :available="jobboard.available"
      :enabled="jobboard.enabled"
      :disabled="jobboard.disabled"
      :is-loading="isLoading[jobboard.key]"
      @input="handleTogglePublish($event, jobboard)"
      @edit="handleEdit(jobboard)"
      @delete="handleDelete(jobboard)"
    />
    <bc-modal :active="modalSubmitForm !== ''" @close="closeModal" :closable="false">
      <component
        :is="`${modalSubmitForm}-form-modal`"
        :custom-job="customJob"
        :is-edit="isEdit"
        @update="handleSubmitUpdate"
        @cancel="closeModal"
      />
    </bc-modal>
    <modal-delete-item
      :is-open="warningModal.title !== ''"
      :title="warningModal.title"
      :confirm-text="warningModal.confirmText"
      @on-confirm="warningModal.confirmAction"
      @close="closeWarningModal"
    >
      <template #content>
        <p>{{ warningModal.content }}</p>
      </template>
    </modal-delete-item>
    <!-- <div
      v-if="!configurationFeatures.multidiffusion"
      class="jobboard-publish-list__feature-lock"
      @click="showFeatureModal"
    ></div> -->
  </div>
  <div v-else class="jobboard-publish-list__sync-loader">
    <bc-spinner></bc-spinner>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { syncJob, remove, updateStatus } from '@/api/job-multipost';
import { Modals } from '@/store/modules/app';

import ModalDeleteItem from '@/components/Modal/ModalDeleteItem';
import BcModal from '@/ui-kit/components/BcModal/BcModal';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import WttjFormModal from '@/components/Jobboard/FormModal/WttjFormModal';
import JobboardPublishItem from './JobboardPublishItemOld';

export default {
  name: 'jobboard-publish-list',
  components: {
    BcModal,
    BcSpinner,
    ModalDeleteItem,
    JobboardPublishItem,
    WttjFormModal,
  },
  props: {
    customJob: {
      type: Object,
      default: () => ({}),
    },
  },
  async mounted() {
    if (!this.configurationFeatures.multidiffusion) {
      return;
    }
    this.isSyncLoading = true;
    try {
      const { data: customJobSync } = await syncJob(this.customJob._id);
      this.isSyncLoading = false;
      this.$emit('on-update', customJobSync);
    } catch (err) {
      this.isSyncLoading = false;
    }
  },
  data() {
    return {
      customJobSync: undefined,
      warningModal: {
        title: '',
        content: '',
        confirmText: '',
        confirmAction: () => {},
      },
      modalSubmitForm: '',
      isLoading: {},
      isSyncLoading: false,
      isEdit: false,
    }
  },
  computed: {
    ...mapGetters({
      organization: 'user/organization',
      configurationFeatures: 'user/configurationFeatures',
      configurationJobMultiposting: 'user/configurationJobMultiposting',
    }),
    list() {
      return [
        {
          key: 'wttj',
          label: 'Welcome to the jungle',
          logo: require('@/assets/logo/wttj_logo.png'),
          available: true,
          enabled: this.configurationJobMultiposting?.wttj?.enabled || false,
          published: this.customJob?.jobMultiposting?.wttj?.status === 'published' || false,
          disabled: this.isDisabled || !this.careerWebsiteActivated,
        },
        // {
        //   key: 'apec',
        //   label: 'APEC',
        //   logo: require('@/assets/logo/apec_logo.png'),
        //   available: false,
        //   enabled: false,
        //   published: false,
        // },
        // {
        //   key: 'jobteaser',
        //   label: 'Jobteaser',
        //   logo: require('@/assets/logo/jobteaser_logo.png'),
        //   available: false,
        //   enabled: false,
        //   published: false,
        // },
        // {
        //   key: 'monster',
        //   label: 'Monster',
        //   logo: require('@/assets/logo/monster_logo.png'),
        //   available: false,
        //   enabled: false,
        //   published: false,
        // },
        // {
        //   key: 'clever_connect',
        //   label: 'CleverConnect (ex MeteoJob)',
        //   logo: require('@/assets/logo/clever_connect_logo.png'),
        //   available: false,
        //   enabled: false,
        //   published: false,
        // },
        // {
        //   key: 'cadre_emploi',
        //   label: 'CadreEmploi',
        //   logo: require('@/assets/logo/cadre_emploi_logo.png'),
        //   available: false,
        //   enabled: false,
        // },
        // {
        //   key: 'pole_emploi',
        //   label: 'Pole Emploi',
        //   logo: require('@/assets/logo/pole_emploi_logo.png'),
        //   available: false,
        //   enabled: false,
        //   published: false,
        // },
        // {
        //   key: 'linkedin',
        //   label: 'Linkedin',
        //   logo: require('@/assets/logo/linkedin_logo.png'),
        //   available: false,
        //   enabled: false,
        //   published: false,
        // },
        // {
        //   key: 'indeed',
        //   label: 'Indeed',
        //   logo: require('@/assets/logo/indeed_logo.png'),
        //   available: false,
        //   enabled: false,
        //   published: false,
        // },
        // {
        //   key: 'hellowork',
        //   label: 'HelloWork',
        //   logo: require('@/assets/logo/hellowork_logo.png'),
        //   available: false,
        //   enabled: false,
        //   published: false,
        // },
      ]
    },
    careerWebsiteActivated() {
      return this.organization.configuration?.career?.activated || false;
    },
    isDisabled() {
      return !this.customJob.active || !this.customJob.description;
    },
  },
  methods: {
    ...mapActions({
      setModal: 'app/setModal',
    }),
    handleEdit(jobboard) {
      this.modalSubmitForm = jobboard.key;
      this.isEdit = true;
    },
    handleDelete(jobboard) {
      this.warningModal.confirmAction = this.removeJobPost.bind(this, jobboard.key);
      this.warningModal.title = this.$t('jobboards.remove-from', { job: this.customJob.title, from: jobboard.label });
    },
    handleTogglePublish(active, jobboard) {
      if (!this.configurationFeatures.multidiffusion) {
        return;
      }
      if (active) {
        this.modalSubmitForm = jobboard.key;
      } else {
        this.warningModal.confirmAction = this.updateStatus.bind(this, jobboard.key, 'archived');
        this.warningModal.confirmText = this.$t('jobboards.yes-disable');
        this.warningModal.title = this.$t('jobboards.disable-from', { job: this.customJob.title, from: jobboard.label });
        this.closeModal();
      }
    },
    handleSubmitUpdate(updatedCustomJob, jobboard) {
      this.$emit('on-update', updatedCustomJob);
      this.closeModal();
      this.$toast.show({
        type: 'success',
        message: this.$t('jobboards.published-success', { jobboard }),
      })
    },
    async updateStatus(jobboard, status) {
      this.isLoading = { [jobboard]: true }
      try {
        const { data } = await updateStatus({ customJobId: this.customJob._id, jobboard, status });
        this.isLoading = { [jobboard]: false }
        this.closeWarningModal();
        this.$emit('on-update', data);
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
        this.isLoading = { [jobboard]: false }
      }
    },
    async removeJobPost(jobboard) {
      this.isLoading = { [jobboard]: true }
      try {
        const { data } = await remove({ customJobId: this.customJob._id, jobboard });
        this.isLoading = { [jobboard]: false }
        this.closeWarningModal();
        this.$emit('on-update', data);
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
        this.isLoading = { [jobboard]: false }
      }
    },
    closeModal() {
      this.modalSubmitForm = '';
      this.isEdit = false;
    },
    closeWarningModal() {
      this.warningModal = { title: '', confirmText: '', content: '', confirmAction: () => {} }
    },
    showFeatureModal() {
      this.setModal(Modals['modal-feature-multipost']);
    }
  }
}
</script>

<style lang="scss" scoped>
.jobboard-publish-list {
  position: relative;
  flex-direction: column;
  height: 100%;
  flex: 1;
  border-radius: 5px;
  border: 1px solid $color-blue-medium;
  background: $color-neutre-4;


  &--separator {
    border-bottom: 1px solid $color-blue-light;
  }

  &__feature-lock {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #f2f4fd 0%, #fafbff 100%);
    opacity: 0.5;
  }

  &__sync-loader {
    margin-top: 20px;
    justify-content: center;
    align-items: center;
  }
}
</style>
