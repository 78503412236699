<template>
  <div
    :class="{ 'is-draggable': true }"
    class="card prospection-pipe-card is-column">
    <router-link :to="panelRoute">
      <div class="prospection-pipe-card__wrapper is-align-items-center is-full-width">
        <bc-avatar
          :src="(company || {}).logoUrl"
          class="prospection-pipe-card__logo mr-1 is-unshrink"
          size="s"
          @error="setAlternativeImg">
        </bc-avatar>
        <p class="prospection-pipe-card__name font-size-s font-weight-medium">
          {{ company.name }}
        </p>
        <bc-avatar
          v-if="isCompanySupported && !isSelectedCoach"
          :src="picture"
          class="prospection-pipe-card__coach ml-auto is-unshrink"
          size="s"
          @error="setAlternativeImgCoach">
        </bc-avatar>
      </div>
      <div
        v-if="!isCompanySupported"
        class="mt-2 is-align-items-center">
        <bc-button
          v-if="!isCompanySigned"
          class="prospection-pipe-card__button mr-2"
          size="small"
          @click.prevent="setOwner">
          {{ $t('companies.pipe-card.take-care-of') }}
        </bc-button>
      </div>
      <p
        v-if="text"
        :class="{
          'is-success': isCompanySigned,
          'is-secondary-dark': !isCompanySigned
        }"
        class="prospection-pipe-card__text font-size-xs mt-2">
        {{ text }}
      </p>
      <div class="mt-2 is-primary font-size-xs is-flex is-align-items-center">
        <p class="prospection-pipe-card__date body-s">
          {{ date }}
        </p>
        <span class="prospection-pipe-card__divider"></span>
      </div>
    </router-link>
  </div>
</template>

<script>
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import moment from 'moment/min/moment-with-locales';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import company from '@/api/company';
  import { mapGetters } from 'vuex';
  import { prospectionStatusCategoryApi, getProspectingStatusCategory } from '@/macros/companies/prospectionStatus';

  export default {
    name: 'prospection-pipe-card',
    components: {
      BcButton,
      BcAvatar,
    },
    props: {
      company: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      ...mapGetters('user', ['configurationProspection']),
      panelRoute() {
        return {
          name: this.$route.name.includes('Panel')
            ? this.$route.name
            : `${this.$route.name}Panel`,
          params: {
            id: this.company._id,
          },
          query: {
            ...this.$route.query,
            type: 'companies',
          },
        };
      },
      prospectingStatusCategory() {
        return getProspectingStatusCategory(this.configurationProspection?.categories, this.company?.customStatus?.id);
      },
      user() {
        return this.$store.state.user.profile;
      },
      date() {
        return moment(this.company.customStatus?.updatedAt || this.company.prospecting?.updatedAt).fromNow();
      },
      picture() {
        return this.company._owner?.pictureUrl;
      },
      isCompanySupported() {
        return this.company._owner || this.company._signedBy;
      },
      isCompanySigned() {
        return this.prospectingStatusCategory === prospectionStatusCategoryApi.signed
      },
      text() {
        // if (this.isCompanySigned) {
        //   return `${this.$i18n.t(
        //     'companies.pipe-card.agreement-signed-on'
        //   )} ${moment(this.company.signedAt).format(
        //     'L'
        //   )}`;
        // }

        return '';
      },
      isSelectedCoach() {
        return !!this.$route.query['coach-ids'];
      },
    },
    methods: {
      async setOwner() {
        try {
          const { data } = await company.setPartnerOwner(
            this.company._id,
            this.$store.state.user.profile._id
          );

          this.$emit('set-owner', data);
        } catch (error) {
          this.$toast.show({
            title: this.$i18n.t(
              'companies.pipe-card.error-while-modifying-owner'
            ),
            message: this.$t('toast.error-occured'),
            type: 'error',
            icon: 'cross',
          });
        }
      },
      setAlternativeImg(event) {
        event.target.src = require('@/assets/img/default-avatar-company.svg');
      },
      setAlternativeImgCoach(event) {
        event.target.src = require('@/assets/img/default-avatar.svg');
      },
    },
  };
</script>

<style lang=scss scoped>
  .prospection-pipe-card {
    background: $color-white;
    padding: 15px;
    border-radius: 10px;
    color: $color-primary !important;

    &__coach {
      margin-left: auto;
    }

    &__name, &__date {
      color: $color-primary;
    }

    &__divider {
      line-height: 1em;
      position: relative;
      outline: 0;
      border: 0;
      width: 100%;
      align-items: center;
      margin-left: 10px;
      margin-top: 8px;

      &:before {
        content: '';
        background: $color-primary;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
      }
    }

    &__text {
      &--trigger {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__date {
      flex-shrink: 0;
    }

    :deep() {
      .prospection-pipe-card__button {
        font-size: $font-size-xs;
      }
    }
  }
</style>
