import { http } from '@/api/index';

export const XTRAMILE_INTEGRATION_KEY = 'xtramile';

export const getIntegrationByName = (name) => {
  return http.get('/integrations/byname', {
    params: {
      name,
    }
  })
}

export const getIntegrations = () => {
  return http.get(`/integrations`);
};

export const addIntegration = ({ name, config }) => {
  return http.post(`/integrations`, {
    name,
    config,
  });
};

export const updateIntegration = ({ name, config }) => {
  return http.patch(`/integrations`, {
    name,
    config,
  });
};

export const deleteIntegration = (id) => {
  return http.delete(`/integrations/${id}`);
}

export default {
  XTRAMILE_INTEGRATION_KEY,
  getIntegrations,
  getIntegrationByName,
  addIntegration,
  updateIntegration,
  deleteIntegration,
};
