import { getFullName } from './timelineMacros';
import router from '@/router';
import moment from 'moment/min/moment-with-locales';

function getLink(id, type) {
  const link = {
    params: { id },
    query: { type, category: 'my-notes' },
    name: 'DashboardPanel'
  }
  return router.resolve(link).href;
}

function getCreatedDate(date) {
  return moment(date).calendar();
}

export default {
  78(props) {
    const { createdAt, _coach, _company, data } = props;
    const companyLink = getLink(_company._id, 'companies');

    return {
      name: 'prospectingStatusSet',
      createdAt: createdAt,
      pictureUrl: _company.logoUrl,
      content: `
        <router-link class="link" tag="b" to="${companyLink}">
          ${_company.name}
        </router-link>
        <span
          v-tooltip.bottom="{ content: '${getCreatedDate(createdAt)}' }"
          v-html="$t('dashboard.small-wins-prospection', { status: '${data.status}', coach: '${getFullName(_coach, 'coach')}' })"
        >
        </span>
      `,
      isCompany: true,
    }
  },
  91(props) {
    const { createdAt, _coder, _customJob, _processCustom, data } = props;
    const corderLink = getLink(_coder._id, 'coders');

    return {
      name: 'processCustomStep',
      createdAt: createdAt,
      pictureUrl: _coder.pictureUrl,
      content: `
        <router-link class="link" tag="b" to="${corderLink}">
          ${getFullName(_coder, 'coder')}
        </router-link>
        <span
        v-tooltip.bottom="{ content: '${getCreatedDate(createdAt)}' }"
          v-html="${data?.isApply ? `
              $t('dashboard.small-wins-apply', {
                offer: '${_customJob?.title}',
                name: '${getFullName(_processCustom?._coach, 'coach')}'
              })
            ` : `
              $t('dashboard.small-wins-process', {
                step: '${data?.stepLabel}',
                name: '${getFullName(_processCustom?._coach, 'coach')}'
              })
            `
          }"
        ></span>
      `
    }
  },
}
