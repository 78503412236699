import jobs from '@/api/jobs';

export default {
  actions: {
    //TODO Changer les paramètres envoyés lors du dispatch sur Partners
    async createJob(context, data) {
      return await jobs.createJob({ id: data.id, obj: data.obj });
    },
    async updateJob(context, data) {
      return await jobs.updateJob({ id: data.id, obj: data.obj });
    },
    async getJobProfile(context, id) {
      return await jobs.getJobProfile(id);
    },
    //TODO Changer les paramètres reçu lors de la refacto de l'infinite-scroll
    async getJobResources(context, params) {
      return await jobs.getJobResources({
        id: params.parameters.id,
        resources: params.parameters.resources,
        page: params.parameters.page
      });
    },
    async deleteJob({ commit, dispatch, rootState }, id) {
      const response = await jobs.deleteJob(id);

      dispatch('user/updateMetadataByKey', {
        key: 'jobs',
        value: rootState.user.metadata.jobs - 1
      });

      commit('removeCard', id);

      return response;
    }
  }
};
