<script lang="ts" setup>
  import moment from 'moment/min/moment-with-locales';
  import { computed, defineProps, onMounted, ref, watch } from 'vue';
  import PanelViewPDF from './PanelView/PanelViewPDF.vue';
  import { Document } from '@/domains/models/Document';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';

  const props = defineProps<{
    documents?: Document[];
  }>();

  const emits = defineEmits({
    'remove-document': (document: Document) => true,
    'open-delete-modal': () => true,
  });

  const documents = computed(() => {
    return props.documents?.sort((a, b) => new moment(b.createdAt).valueOf() - new moment(a.createdAt).valueOf()) || [];
  });
  const selectedDocumentIndex = ref(0);
  const selectedDocument = computed(() => props.documents?.[selectedDocumentIndex.value] || {});
  const isIframeLoaded = ref(false);
  const iframeCheckInterval = ref<ReturnType<typeof setTimeout> | undefined>(undefined);

  const selectedDocumentUrl = computed(() => {
    return props.documents?.length > 0 ? selectedDocument.value?.url : '';
  });
  const selectedDocumentType = computed(() => {
    return selectedDocumentUrl.value?.split('.')?.pop() || '';
  });

  const docIframe = ref<HTMLInputElement | null>(null);
  const checkIframe = () => {
    if (!selectedDocumentType.value?.includes('doc')) {
      return;
    }
    const iframe = docIframe.value;
    if (iframe && !isIframeLoaded.value) {
      iframeCheckInterval.value = setInterval(() => {
        iframe.src = `https://docs.google.com/gview?url=${selectedDocumentUrl.value}&embedded=true`;
        if (isIframeLoaded.value) {
          clearInterval(iframeCheckInterval.value);
        }
      }, 2000);
    }
  };

  const handleIframeLoad = (): void => {
    isIframeLoaded.value = true;
    clearInterval(iframeCheckInterval.value);
  };
  const nextDocument = () => {
    if (selectedDocumentIndex.value < documents.value.length - 1) {
      selectedDocumentIndex.value++;
    }
  };
  const previousDocument = () => {
    if (selectedDocumentIndex.value > 0) {
      selectedDocumentIndex.value--;
    }
  };

  watch(() => documents.value.length, () => {
    selectedDocumentIndex.value = 0;
  });

  onMounted(() => {
    checkIframe();
  });
</script>

<template>
  <div class="relative flex items-center">
    <div
      v-if="documents.length > 1"
      class="absolute z-10 flex w-full items-center justify-between px-5">
      <button
        v-show="selectedDocumentIndex > 0"
        class="icon-arrow-left mr-right flex size-8 items-center justify-center rounded-full border border-blue-200 bg-white text-xs text-blue-800 hover:text-blue-500"
        @click="previousDocument"></button>
      <button
        v-show="selectedDocumentIndex < documents.length - 1"
        class="icon-arrow-right ml-auto flex size-8 items-center justify-center rounded-full border border-blue-200 bg-white text-xs text-blue-800 hover:text-blue-500"
        @click="nextDocument"></button>
    </div>
    <div
      v-if="documents.length"
      class="absolute bottom-5 right-5 flex gap-2.5 z-10">
      <a
        :href="selectedDocumentUrl"
        download
        target="_blank">
        <BcButton
          icon-left="file-download"
          size="extra-small"/>
      </a>
      <BcButton
        color="error"
        icon-left="trash"
        size="extra-small"
        @click.native="$emit('open-delete-modal', selectedDocument)"/>
    </div>
    <p
      v-if="!documents.length"
      class="flex w-full grow items-center rounded-md bg-neutral-200 px-5 py-4 text-sm text-blue-800">
      {{ $t('hunt.no-document-for-the-moment') }}
    </p>
    <div
      v-else-if="selectedDocumentType.includes('doc')"
      class="is-full-width min-h-[640px] w-full relative"
    >
      <bc-spinner
        v-if="!isIframeLoaded"
        class="absolute top-0 left-0 right-0 bottom-0 m-auto justify-center items-center"
        size="large"
      />
      <iframe
        :key="selectedDocumentUrl"
        ref="docIframe"
        :src="`https://docs.google.com/gview?url=${selectedDocumentUrl}&embedded=true`"
        class="w-full"
        frameborder="0"
        @load="handleIframeLoad"
      >
      </iframe>
    </div>
    <PanelViewPDF
      v-else-if="selectedDocumentType.includes('pdf') || documents.length === 0"
      :empty-message="documents.length > 0 ? $t('hunt.select-a-document') : $t('hunt.no-document-for-the-moment')"
      :pdf-url="selectedDocumentUrl"
      class="flex grow"
    />
    <img
      v-else
      :src="selectedDocumentUrl" alt="document" class="w-full"/>
  </div>
</template>
