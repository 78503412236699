<script lang="ts" setup>
  import { useRoute } from 'vue-router/composables';
  import { computed } from 'vue';

  import PanelProcessKanbanCoder
    from '@/components/Panel/PanelProcessKanban/PanelProcessKanbanCoder.vue';
  import PanelProcessKanbanCompany
    from '@/components/Panel/PanelProcessKanban/PanelProcessKanbanCompany.vue';
  import PanelProcessKanbanOpportunity
    from '@/components/Panel/PanelProcessKanban/PanelProcessKanbanOpportunity.vue';
import { usePanelNavigation } from '@/composables/usePanelNavigation';

const route = useRoute();
const { closePanel } = usePanelNavigation();

  const panelKanbanMap = {
    coders: PanelProcessKanbanCoder,
    companies: PanelProcessKanbanCompany,
    hunt: PanelProcessKanbanOpportunity,
  };

  const manageClosePanel = (event) => {
    if (event.target?.className === 'icon-close' || event.target?.className === 'overlay') {
      closePanel();
    }
  };

  const panelComponent = computed(() => {
    return panelKanbanMap[route.query.subtype];
  });

</script>

<template>
  <component
    class="max-h-screen"
    :is="panelComponent"
    v-click-outside="manageClosePanel">
  </component>
</template>
