import { Ref, computed, ref } from 'vue';
import { useStore } from '@/store';
import { useI18n } from '@/i18n/i18n';
import { ConfigurationProcessCategoryStep } from '@/domains/models/Organization';
import { useProcessesApi } from '@/composables/api/useProcessesApi';

const processStatusFilter = ref();

export const useProcessesFilter = (options?: { displayFailedByDefault: boolean}) => {
  const { displayFailedByDefault = true } = options ?? {};
  const store = useStore();
  const organization = computed(() => store.getters['user/organization']);
  const { processes } = useProcessesApi();
  const { t } = useI18n();

  function sortAbortedAtLast(a, b) {
    const aAborted = !!a.abortedAt;
    const bAborted = !!b.abortedAt;
    if (aAborted === bAborted) {
      return 0;
    }
    return aAborted ? 1 : -1;
  }
  function getStepKey(process) {
    return process.historySteps.at(-1)?.stepKey;
  }
  function sortByStatusOrder(a, b) {
    const flatStatusOptionsSteps = statusOptions.value.flatMap(status => status.steps)
    const aIndex = flatStatusOptionsSteps?.findIndex(status => status.key === getStepKey(a));
    const bIndex = flatStatusOptionsSteps?.findIndex(status => status.key === getStepKey(b));
    return aIndex > bIndex ? 1 : -1;
  }
  const filteredProcesses = computed(() => {
    let activeProcesses = [];
    if (!processStatusFilter.value?.key) {
      activeProcesses = processes.value;
      if (!displayFailedByDefault) {
        activeProcesses = processes.value.filter(process => !process.abortedAt);
      }
    } else if (processStatusFilter.value?.key === 'failed') {
      activeProcesses = processes.value.filter(process => process.abortedAt);
    } else {
      activeProcesses = processes.value.filter(process => getStepKey(process) === processStatusFilter.value?.key && !process.abortedAt);
    }

    return activeProcesses.sort((a,b) => sortAbortedAtLast(a,b) || sortByStatusOrder(a,b));
  });

  const processesCount = computed(() => filteredProcesses.value.length);

  const statusOptions = computed(() => {
    return (
      organization.value.configuration?.process?.categories?.map(
        (category) => ({
          ...category,
          steps: category.steps.map((step) => ({
            ...step,
            categoryKey: category.key,
          })),
        })
      ) ?? []
    );
  });

  const defaultOption = {
    name: t('processes.all-processes'),
    key: undefined,
    categoryKey: 'all',
  };

  const failedOption = {
    name: t('processes.failed'),
    key: 'failed',
    categoryKey: 'failed',
  };

  const statusFilterOptions = computed<ConfigurationProcessCategoryStep[]>(() => [
    {
      key: 'all',
      steps: [defaultOption],
    },
    ...statusOptions.value,
    {
      key: 'failed',
      steps: [failedOption],
    },
  ]);

  return {
    processStatusFilter,
    filteredProcesses,
    statusOptions,
    statusFilterOptions,
    processesCount,
  }
}
