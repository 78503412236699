<template>
  <modal-delete-item
    :only-content="true"
    :title="title"
    :confirmText="confirmText"
    :type="type"
    @on-confirm="confirmAction"
    @close="cancelAction">
    <template #content>
      <p>{{ text }}</p>
    </template>
  </modal-delete-item>
</template>

<script>
import ModalDeleteItem from '@/components/Modal/ModalDeleteItem';

export default {
  name: 'modal-process-action-confirmation',
  components: { ModalDeleteItem },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    confirmText: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  methods: {
    confirmAction() {
      this.$emit('confirm-action');
    },
    cancelAction() {
      this.$emit('cancel-action');
    },
  },
};
</script>
