<template>
  <div
    class="icon-alert-circle-red"
    :class="{
      'icon-alert-circle-full': isHover,
      'icon-alert-circle': !isHover,
    }"

    v-tooltip.bottom="{
      content: `
      <div style='display: block; padding:5px; text-align: left;'>
        <p>${$t('custom-jobs.mandatory-to-publish')} :</p><br/>
        <p>1. ${$t('custom-jobs.a-description')}</p>
        <p>2. ${$t('custom-jobs.already-open-opportunity')}</p>
        <p>3. ${$t('generics.location')}</p>
      </div>
      `,
    }"
    @mouseout="isHover = false"
    @mouseover="isHover = true">
  </div>
</template>

<script>
export default {
  name: 'custom-job-publish-warning',
  data() {
    return {
      isHover: false,
    };
  },
};
</script>

<style scoped lang="scss">
.icon-alert-circle-red {
  color: $color-red-mandy;
}
</style>
