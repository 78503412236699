<template>
  <modal-delete-item
    :confirm-text="$t('custom-jobs.close-warning-validate')"
    :is-open="isOpen"
    :is-submit-disabled="isSubmitDisabled"
    :title="$t('custom-jobs.close-warning-title')"
    @close="$emit('close', $event)"
    @on-confirm="$emit('on-confirm', $event)"
  >
    <template #content>
      <div class="custom-jobs-close-warning-modal__content">
        <p>{{ $t('custom-jobs.close-warning-content') }}</p>
        <div
          v-if="selectedCustomJob.nbActiveProcesses || selectedCustomJob.adPublished"
          class="custom-jobs-close-warning-modal__content-list"
        >
          <div v-if="selectedCustomJob.nbActiveProcesses">
            <bc-checkbox
              id="close-warning-processes"
              :value="closeOptions.closeProcesses"
              @input="handleCheck('closeProcesses')">
              <span class="pt-2">
                {{ $t('custom-jobs.close-warning-processes', { nbProcesses: selectedCustomJob.nbActiveProcesses }) }}
              </span>
            </bc-checkbox>
          </div>
          <div v-if="selectedCustomJob.adPublished">
            <bc-checkbox
              id="close-warning-career"
              :value="closeOptions.removeFromCareer"
              @input="handleCheck('removeFromCareer')">
              <span>{{ $t('custom-jobs.close-warning-career', { nbJobboards }) }}</span>
            </bc-checkbox>
          </div>
        </div>
      </div>
    </template>
  </modal-delete-item>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
  import ModalDeleteItem from '@/components/Modal/ModalDeleteItem';

  export default {
    name: 'custom-jobs-close-warning-modal',
    components: {
      BcCheckbox,
      ModalDeleteItem,
    },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
      isOpen: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      this.getPublishedJobboardsList({
        customJob: this.selectedCustomJob,
        configurationJobMultiposting: this.configurationJobMultiposting,
      });
    },
    computed: {
      ...mapGetters({
        selectedCustomJobStore: 'customJobs/selectedCustomJob',
        closeOptions: 'customJobs/closeOptions',
        configurationJobMultiposting: 'user/configurationJobMultiposting',
        publishedJobboardsList: 'xtramile/publishedJobboardsList',
      }),
      selectedCustomJob() {
        return this.profile._id ? this.profile : this.selectedCustomJobStore;
      },
      ...mapState({
        activatedChannels: state => state.xtramile.activatedChannels,
      }),
      isSubmitDisabled() {
        // TODO: to clarify with Benjamin later
        // return Object.keys(this.options).every(key => !this.options[key])
        return false;
      },
      nbJobboards() {
        return this.publishedJobboardsList?.length;
      },
      activatedChannelsMap() {
        return this.activatedChannels.reduce((acc, val) => {
          return {
            ...acc,
            [val.id]: val,
          };
        }, {});
      },
    },
    methods: {
      ...mapActions({
        setCloseOptions: 'customJobs/setCloseOptions',
        resetCloseOptions: 'customJobs/resetCloseOptions',
        getPublishedJobboardsList: 'xtramile/getPublishedJobboardsList',
      }),
      handleCheck(key) {
        if (!key) return;
        this.setCloseOptions({ key, value: !this.closeOptions[key] });
      },
    },
    watch: {
      isOpen(value) {
        if (!value) {
          this.resetCloseOptions();
        } else {
          if (!this.selectedCustomJob.nbActiveProcesses) {
            this.setCloseOptions({ key: 'closeProcesses', value: false });
          }
          if (!this.selectedCustomJob.adPublished) {
            this.setCloseOptions({ key: 'removeFromCareer', value: false });
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .custom-jobs-close-warning-modal {
    display: flex;
    max-width: 550px;
    flex-direction: column;
    padding: 20px;

    & > h3 {
      text-align: center;
      text-transform: uppercase;
      color: $color-blue-dodger;
      font-weight: 700;
      font-size: 16px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-weight: 400;
      font-size: 14px;
      color: $color-blue-dark-cello;
      padding: 10px 0px;

      & > p {
        color: $color-tertiary-2;
        font-size: $font-size-m;
        font-weight: $font-weight-medium;
      }

      &-list {
        width: 100%;
        margin-top: 10px;
        flex-direction: column;
        gap: 10px;
      }
    }

    &__footer {
      margin: 5px;
      display: flex;
      justify-content: space-around;
    }
  }
</style>
