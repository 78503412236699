<script lang="ts" setup>
  import { Candidate } from '@/domains/models/Candidate';
  import linkedinImg from '@/assets/logo/linkedin.png';
  import welcomeToTheJungleImg from '@/assets/logo/wttj.png';
  import { parsePhoneNumberFromString } from 'libphonenumber-js';
  import PanelCompanyContactsSocial
    from '@/components/Panel/PanelCompany/PanelCompanyContacts/PanelCompanyContactsSocial.vue';
  import { computed, defineEmits, defineProps, withDefaults } from 'vue';

  interface Props {
    company: Candidate;
  }

  const props = withDefaults(defineProps<Props>(), {
    company: () => ({}) as Candidate,
  });
  defineEmits<{ (e: 'edit-company', value: boolean): void }>();

  const emails = computed<string[]>(() => {
    return [props.company.email, ...(props.company.emails?.length ? props.company.emails : [])].filter(e => e);
  });
  const phones = computed<string[]>(() => {
    return [props.company.phone, ...(props.company.phones?.length ? props.company.phones : [])].filter(e => e).map(phone => parsePhoneNumberFromString(phone, 'FR')?.formatInternational() || phone);
  });
</script>

<template>
  <div class="relative flex w-full flex-row flex-wrap gap-2.5 rounded-md bg-neutral-200 px-5 py-4 text-sm">
    <PanelCompanyContactsSocial
      v-for="(email, index) in emails"
      :key="email"
      :company="company"
      :is-email="true"
      :is-pro="index > 0"
      :value="email"
      class="max-w-[47.5%]"
      icon="mail"
    />
    <PanelCompanyContactsSocial
      v-for="(phone, index) in phones"
      :key="phone"
      :company="company"
      :is-pro="index > 0"
      :value="phone"
      class="max-w-[47.5%]"
      icon="phone"
    />
    <a
      v-if="company?.social?.linkedin"
      :href="company.social.linkedin"
      class="hover:text-underline max-w-[47.5%] basis-1/2"
      target="_blank"
    >
      <PanelCompanyContactsSocial
        :company="company"
        :icon-url="linkedinImg"
        :value="company.social?.linkedin"
      />
    </a>
    <a
      v-if="company?.social?.website"
      :href="company.social.website"
      class="hover:text-underline max-w-[47.5%] basis-1/2"
      target="_blank"
    >
      <PanelCompanyContactsSocial
        :company="company"
        :value="company.social?.website"
        icon="website"
      />
    </a>
    <a
      v-if="company?.social?.welcomeToTheJungle"
      :href="company.social.welcomeToTheJungle"
      class="hover:text-underline max-w-[47.5%] basis-1/2"
      target="_blank"
    >
      <PanelCompanyContactsSocial
        :company="company"
        :icon-url="welcomeToTheJungleImg"
        :value="company.social?.welcomeToTheJungle"
      />
    </a>
  </div>
</template>
