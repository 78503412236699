<template>
  <location-autocomplete
    class="px-[20px] pb-[15px] pt-[15px]"
    :selected="selected"
    :selected-distance="selectedDistance"
    :name="$t('generics.location')"
    icon="map-pin"
    :with-distance="true"
    @select-locations="onSelect"
    @select-distance="onSelectDistance"
    @close="onClose"
    :show-buttons="false"
    :emit-only="true"
    placeholder="Exemple: Paris" />
</template>

<script setup lang="ts">
import { computed, ref, defineProps, defineEmits } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { useI18n } from '@/i18n/i18n';
import LocationAutocomplete from '@/components/Location/LocationAutocomplete.vue';

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

interface Location {
  description: string;
  place_id: string;
}

const shouldClose = ref(false);

const props = defineProps<{
  emitOnly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:filters', filters: Record<string, any>): void;
}>();

const selected = computed<Location[]>(() => {
  if (!route.query.locations) {
    return [];
  }
  const locations = (route.query.locations as string).split('|');
  return locations.map(location => {
    const details = location.split('+');
    return {
      description: details[0],
      place_id: details[1],
    };
  });
});

const selectedDistance = computed<number>(() => {
  return Number(route.query.distance) || 0;
});

function onSelect(value: Location[]) {
  if (props.emitOnly) {
    emit('update:filters', {
      locations: value.length
        ? value.map(v => `${v.description}+${v.place_id}`).join('|')
        : undefined,
    });
  } else {
    router
      .push({
        query: {
          ...route.query,
          page: 1,
          locations: value.length
            ? value.map(v => `${v.description}+${v.place_id}`).join('|')
            : undefined,
        },
      })
      .catch(() => {});
  }
}

function onSelectDistance(value: number) {
  if (props.emitOnly) {
    emit('update:filters', {
      distance: value,
    });
  } else {
    router
      .push({
        query: {
          ...route.query,
          page: 1,
          distance: value,
        },
      })
      .catch(() => {});
  }
}

function onClose() {
  shouldClose.value = true;
  setTimeout(() => {
    shouldClose.value = false;
  }, 100);
}
</script>
