import { useStore } from '@/store';
import { useI18n } from '@/i18n/i18n';
import { getProcessesCustom } from '@/api/processes-custom';
import type { LinkedinTemplateTypeEnum } from '@/macros/linkedin';
import type { Candidate } from '@/domains/models/Candidate';

interface Process {
  _id: string;
  abortedAt?: string | null;
  rejectedAt?: string | null;
  deletedAt?: string | null;
  _customJob?: any;
}

export function useLinkedinPanel() {
  const store = useStore();
  const { t } = useI18n();

  const openLinkedinPanel = async (candidate: Candidate, type: LinkedinTemplateTypeEnum) => {
    if (!candidate.social?.linkedin) {
      return;
    }

    try {
      const { data: processes } = await getProcessesCustom({ resource: 'coder', coderId: candidate?._id });
      const activeProcesses = (processes as Process[]).filter(process => !process.abortedAt && !process.rejectedAt && !process.deletedAt);

      store.dispatch('app/setIsPanel', true);
      store.dispatch('app/setParams', {
        coder: candidate,
        linkedin: candidate.social?.linkedin,
        linkedinMessageType: type,
        ...activeProcesses.length === 1 && { customJob: activeProcesses[0]._customJob },
        ...activeProcesses.length > 1 && { warningMessage: t('email.not-have-obvious-variables') }
      });
      store.dispatch('app/setModal', 'modal-linkedin-send-form');
    } catch (err) {
      console.log('err', err);
    }
  };

  return {
    openLinkedinPanel
  };
} 