<script lang="ts" setup>
import { defineEmits, defineProps } from 'vue';
import { Process } from '@/domains/models/Process';
import PanelProcessesItem from '@/components/Panel/PanelProcessesItem.vue';
import { useI18n } from '@/i18n/i18n';
import InfiniteLoadingViewport from '@/components/InfiniteLoading/InfiniteLoadingViewport.vue';

defineProps<{
  processes: Process[];
  identifier: number;
}>();

const emit = defineEmits<{
  (e: 'update-process', data: {index: number, process: Partial<Process>}): void,
  (e: 'remove-process', data: { id: string }): void,
  (e: 'load-more-processes', $state: any): void;
}>();

const { t } = useI18n();


function updateProcess({ index, process }) {
  // processes.value[index] = { ...processes.value[index], ...process };
  emit('update-process', { index, process });
};

function loadMoreProcesses($state) {
  emit('load-more-processes', $state);
};

</script>

<template>
  <div class="h-min-4 flex w-full flex-col gap-2.5 text-sm">
    <PanelProcessesItem
v-for="(process, index) in processes" :key="process._id" :process="process" parent="company"
      @remove-process="(id) => $emit('remove-process', { id })"
      @update-process="updateProcess({ index, process: $event })" />
    <InfiniteLoadingViewport :identifier="identifier" @infinite="loadMoreProcesses">
      <template #no-results>
        <p class="flex w-full grow items-center rounded-md bg-neutral-200 px-5 py-4 text-blue-800">
          {{ t('processes.nothing') }}
        </p>
      </template>
    </InfiniteLoadingViewport>
  </div>
</template>
