<template>
  <div class="container">
    <p
      class="title font-weight-bold mb-4">
      {{ $t('dashboard.get-started-in-marvin') }}
    </p>
    <ValidationObserver v-slot="{ valid: formValid }">
      <form @submit.prevent="onSubmit" class="form">
        <ValidationProvider
          :name="$t('contacts.firstname')"
          ref="firstName"
          :rules="{ required: true, min: 2, regex: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u }"
          v-slot="{ errors, valid }"
          slim
        >
          <bc-input
            ref="first-name"
            v-model="formData.firstName"
            :class="{ 'invalid': errors.length > 0 }"
            :error="errors[0]"
            :valid="valid"
            class="input"
            icon="user"
            :label="$t('contacts.firstname')"
            name="first-name"
            :placeholder="$t('contacts.firstname')"
            required
            type="text">
          </bc-input>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors, valid }"
          :rules="{ required: true, min: 2, regex: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u }"
          :name="$t('contacts.lastname')"
          ref="lastName"
          slim>
          <bc-input
            ref="last-name"
            v-model="formData.lastName"
            :class="{ 'invalid': errors.length > 0 }"
            :error="errors[0]"
            :valid="valid"
            class="input"
            icon="user"
            :label="$t('contacts.lastname')"
            name="last-name"
            :placeholder="$t('contacts.lastname')"
            required
            type="text">
          </bc-input>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors, valid }"
          :rules="{ required: true, email: true }"
          :name="$t('contacts.email')"
          ref="email"
          slim>
          <bc-input
            ref="email"
            v-model="formData.email"
            :class="{ 'invalid': errors.length > 0 }"
            :error="errors[0]"
            :valid="valid"
            class="input"
            icon="mail"
            :label="$t('contacts.email')"
            name="email"
            :placeholder="$t('contacts.email')"
            required
            type="text">
          </bc-input>
        </ValidationProvider>

        <div class="is-full-width">
          <ValidationProvider
            v-slot="{ errors }"
            :rules="{ required: true, min: 8, max: 20, regex: /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i }"
            :name="$t('contacts.phone')"
            slim>
            <bc-input-phone
              ref="phone"
              v-model="formData.mobile"
              :class="{ 'invalid': errors.length > 0 }"
              class="input"
              icon="phone"
              name="phone"
              :placeholder="$t('contacts.phone')"
              required
              @error="isPhoneValid = false"
              @valid="isPhoneValid = true"
              @keydown.native.enter.prevent>
            </bc-input-phone>
          </ValidationProvider>
        </div>

        <ValidationProvider
          v-slot="{ errors, valid }"
          rules="required"
          name="company"
          slim>
          <bc-input
            ref="company"
            v-model="formData.company"
            :class="{ 'invalid': errors.length > 0 }"
            :error="errors[0]"
            :valid="valid"
            class="input"
            icon="company"
            :label="$tc('generics.company', 1)"
            name="company"
            :placeholder="$tc('generics.company', 1)"
            required
            type="text">
          </bc-input>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors, valid }"
          rules="required"
          name="teamSize"
          slim>
          <bc-input
            ref="teamSize"
            v-model="formData.teamSize"
            :class="{ 'invalid': errors.length > 0 }"
            :error="errors[0]"
            :valid="valid"
            class="input"
            icon="team"
            :label="$t('dashboard.team-size')"
            name="team"
            :placeholder="$t('dashboard.team-size')"
            required
            type="number">
          </bc-input>
        </ValidationProvider>

        <industries-autocomplete forConfiguration />

        <bc-dropdown
          :is-scrollable="false"
          :options="organizationTypeOptions"
          class="organization-type__dropdown"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              type="button"
              :placeholder="$t('generics.who-are-you')"
              :required="true"
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="organizationTypeName">
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              :title="option.name"
              class="organization-type__dropdown--item"
              @click.native="setSelectedOrganizationType(option)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>

        <p class="text" v-html="$t('dashboard.marvin-privacy-tos')"></p>

        <bc-button
          class="submit-btn mt-5 is-full-width"
          :class="{ 'btn-disabled': !formValid || !formData.organizationType || isLoading }"
          :disabled="!formValid || !formData.organizationType"
          native-type="submit"
        >
          {{ $t('dashboard.get-started-in-marvin') }}
        </bc-button>

      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import BcInput from '@/ui-kit/components/BcInput/BcInput';
  import BcInputPhone from '@/ui-kit/components/BcInputPhone/BcInputPhone';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
  import { signUpAfterSocialConnect } from '@/api/auth';
  import { organizationTypes } from '@/api/organizations';
  import IndustriesAutocomplete from '@/components/Industries/IndustriesAutocomplete';
  import { setToken } from '@/managers/token/useCases/setToken';

  export default {
    name: 'dashboard-register',
    components: {
      ValidationProvider,
      ValidationObserver,
      BcButton,
      BcInput,
      BcInputPhone,
      BcDropdown,
      BcDropdownItem,
      BcDropdownTrigger,
      IndustriesAutocomplete,
    },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
      editedProfile: {
        type: Object,
        default: () => ({}),
      },
    },
    async mounted() {
      this.formData.firstName = this.$route.query.firstName ? this.$route.query.firstName : '';
      this.formData.lastName = this.$route.query.lastName ? this.$route.query.lastName : '';
      this.formData.email = this.$route.query.email ? this.$route.query.email : '';
      this.$nextTick(() => {
        this.$refs.firstName.validate();
        this.$refs.lastName.validate();
        this.$refs.email.validate();
      });
    },
    data() {
      return {
        isOpenRegisterModal: true,
        organizationTypeName: '',
        isLoading: false,
        formData: {
          lastName: '',
          firstName: '',
          mobile: '',
          email: '',
          company: '',
          teamSize: '',
          organizationType: '',
        },
      };
    },
    computed: {
      organizationTypeOptions() {
        return [
          {
            name: this.$t('generics.independent'),
            value: organizationTypes.independent,
          },
          {
            name: this.$t('generics.recruiting-firm'),
            value: organizationTypes.recruiting_firm,
          },
          {
            name: this.$t('generics.hr-department'),
            value: organizationTypes.hr_department,
          },
          {
            name: this.$t('generics.consulting-firm-esn'),
            value: organizationTypes.consulting_firm_esn,
          },
        ]
      },
    },
    methods: {
      async onSubmit() {
        if (!this.formData.organizationType) {
          return;
        }
        this.isLoading = true;
        try {
          const authSource = this.$route.query.authSource ? this.$route.query.authSource : 'unknown';
          const { data } = await signUpAfterSocialConnect(authSource, this.formData);
          setToken(data.token);

          this.$store.dispatch('user/cleanProfile');
          const url = window.location.href
          window.location.href = url.slice(0, url.indexOf('?')).replace('dashboard', 'onboarding');
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$toast.show({
            type: 'error',
            message: error?.response?.data?.message || error,
          });
        }
      },
      setSelectedOrganizationType(option) {
        this.formData.organizationType = option.value;
        this.organizationTypeName = option.name;
      }
    },
  };

</script>
<style lang="scss" scoped>
  .container {
    max-width: 600px;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 100vh;
    overflow: auto;
  }

  .title {
    color: $color-blue-dark-cello;
    font-size: 25px;
    line-height: 29px;
    text-align: center;
  }

  .form {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @include bp('desktop') {
      padding: 0% 15%;
    }
  }

  .input {
    width: 100%;
    margin-bottom: 15px;
  }

  .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #1D395E;
    margin: 5px 0;
  }

  .submit-btn {
    background: linear-gradient(180deg, #6E2AE8 0%, #5910DD 100%);
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    opacity: 1;
  }

  .btn-disabled {
    opacity: 0.5;
  }

  .organization-type {
    &__dropdown {
      width: 100%;
      margin: 12px 0px;

      &--item {
        width: 100%;
      }
    }
  }
</style>
