<template>
  <div class="app-bar-notifications-count">
    <bc-menu :show="show" @click="show = !show">
      <app-bar-item icon="bell" :count="unreadNotifications.length" :active="show"></app-bar-item>
      <template #content>
        <notifications-list></notifications-list>
      </template>
    </bc-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppBarItem from './AppBarItem';
import BcMenu from '@/ui-kit/components/BcMenu';
import NotificationsList from '@/components/Notifications/NotificationsList';

export default {
  name: 'app-bar-notifications-count',
  components: {
    AppBarItem,
    BcMenu,
    NotificationsList,
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    ...mapGetters({
      unreadNotifications: 'mainNotifications/unreadNotifications',
    }),
  },
}
</script>
