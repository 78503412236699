<template>
  <div class="multiselect-footer" :class="{ 'left-[-330px]': hasActiveResumeUpload && hasActiveMessages }">
    <p class="multiselect-footer__text font-weight-bold is-secondary-dark mb-3">
      {{ footerText }}
    </p>
    <div class="is-align-items-center gap-3">
      <BcButton
        color="error"
        icon-left="trash"
        @click="deleteCampaigns">
        {{ capitalize(t('campaigns.delete-selected-campaigns')) }}
      </BcButton>
      <BcButton type="outlined" @click="resetSelectedCards">
        {{ t('generics.cancel') }}
      </BcButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { computed, ref } from 'vue';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { useStore } from '@/store';
  import { useI18n } from '@/i18n/i18n';
  import { useMutation } from '@tanstack/vue-query';
  import { deleteCampaign } from '@/api/campaigns';
  import { Campaign } from '@/domains/models/Campaign';
  import { capitalize } from '@/utils/stringTransform';


  const store = useStore();
  const toast = useToast();

  const { t, tc } = useI18n();

  const modal = ref<string>();

  const selectedCards = computed(() => store.state.card.selectedCards);
  const hasActiveResumeUpload = computed(() => store.getters['resumeHistories/hasActiveResumeUpload']);
  const hasActiveMessages = computed(() => store.getters['messageHistories/hasActiveMessages']);

  const footerText = computed(() => {
    return selectedCards.value.length > 1
      ? ` ${t('companies.account-management.selection-of')} ${selectedCards.value.length} ${tc('generics.profile', 2)} :`
      : `${t('companies.account-management.selection-of')} ${selectedCards.value.length} ${tc('generics.profile', 1)} :`;
  });

  function closeModal() {
    modal.value = undefined;
  }

  async function updateSelectedCards({
                                       call,
                                       params,
                                       errorMessage,
                                       successMessage,
                                       informationMessage,
                                       removeSelectedCards,
                                     }) {
    let count = 0;

    await store.dispatch('setIsRequestProcessing', true);

    for (const index in selectedCards.value) {
      try {
        await call(index, params);

        if (removeSelectedCards) {
          removeSelectedCards(selectedCards.value[index]);
        }
        ++count;
      } catch (error) {
        toast.show({
          title: errorMessage,
          message: `${error?.response?.data?.message} - id: ${selectedCards.value[index]._id}`,
          type: 'error',
          icon: 'cross',
        });
      }
    }

    await store.dispatch('setIsRequestProcessing', false);

    closeModal();

    if (count) {
      toast.show({
        title: t('generics.success'),
        message: `${count} ${successMessage}`,
        type: 'success',
        icon: 'check',
      });
    } else {
      toast.show({
        title: tc('generics.information', 0),
        message: informationMessage,
        type: 'info',
        icon: 'info-full',
      });
    }
  }

  const deleteCampaignMutation = useMutation({
    mutationKey: ['delete-campaign'],
    mutationFn: async(id) => {
      await deleteCampaign(id);

      return id;
    },
  });

  async function deleteCampaignItem(index: number) {
    const campaign = selectedCards.value[index];

    await deleteCampaignMutation.mutateAsync(campaign._id);

    return campaign;
  }

  function removeSelectedCampaignFromCardsList(campaign: Campaign) {
    store.dispatch('removeCard', campaign._id);
  }

  async function deleteCampaigns() {
    closeModal();
    await updateSelectedCards({
      call: deleteCampaignItem,
      errorMessage: t('generics.resource-error-deleting', { resource: t('generics.this-campaign') }),
      successMessage: t('generics.resource-successfully-deleted', { resource: t('generics.campaign') }),
      informationMessage: t('generics.resource-unable-to-delete', { resource: t('generics.campaigns') }),
      removeSelectedCards: removeSelectedCampaignFromCardsList,
    });

    resetSelectedCards();
  }

  function resetSelectedCards() {
    store.dispatch('setSelectedCards', []);
  }

</script>

<style lang="scss" scoped>
  .multiselect-footer {
    background: linear-gradient(0deg, white 70%, transparent);
    z-index: 2;
    padding: 20px 0 20px;
  }
</style>
