<template>
  <div
    class="panel-view col-xs-12"
    :class="{'panel-view--hidden': !isActive && !isNotMobile}">
    <panel-view-header class="hidden-sm hidden-md"></panel-view-header>
    <div class="panel-view__component">
      <transition-fade-translate-up>
        <slot></slot>
      </transition-fade-translate-up>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import PanelViewHeader from '@/components/Panel/PanelView/PanelViewHeader';
  import TransitionFadeTranslateUp from '@/components/UIKit/Transition/TransitionFadeTranslateUp';
  // dependencies
  import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

  export default {
    /**
     * This component contains the Panel view which will be show depending of the route
     * This component contains the panel view which will be show depending of the route
     */
    name: 'panel-view',
    data() {
      return {
        panelView: null
      }
    },
    components: {
      TransitionFadeTranslateUp,
      PanelViewHeader
    },
    computed: {
      ...mapState({
        activeCategory: state => state.panel.activeCategory,
        windowWidth: state => state.panel.windowWidth
      }),
      /**
       * Check if a category is active to determinate if it should hide the panel-view in mobile
       */
      isActive() {
        return !!this.activeCategory && this.$route.query.category;
      },
      /**
       * Check if the user is in mobile or not, to determinate which style should be applied
       */
      isNotMobile() {
        return this.windowWidth > 768;
      }
    },
    mounted() {
      this.panelView = document.querySelector('.panel-view');

      disableBodyScroll(this.panelView);
    },
    beforeDestroy() {
      enableBodyScroll(this.panelView);
    }
  };
</script>

<style scoped lang="scss">
  .panel-view {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    overflow: auto;
    z-index: 3;
    background: white;
    // background: linear-gradient(0, rgba($color-grey, 0.5), $color-white);
    flex-grow: 1;
    @include bp('tablet') {
      position: initial;
      height: auto;
      z-index: 1;
    }
    &--hidden {
      z-index: -1;
    }
    &__component {
      margin-top: 50px;
      min-height: calc(100% - 50px);
      flex-grow: 1;
      height: 74vh;
      @include bp('tablet') {
        margin-top: initial;
        min-height: 100%;
      }
    }
  }
</style>
