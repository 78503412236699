<template>
  <div class="panel-edit-coach-caption-coach is-full-width is-flex-wrap is-justify-content-space-between">
    <title-section
      class="panel-edit-coach-caption-coach__title is-full-width"
      position="left"
      :title="$t('panel-company-edit.infos')"
    >
    </title-section>
    <ValidationProvider
      v-slot="{ errors, valid }"
      :rules="{ min: 2, regex: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u }"
      :name="$t('contacts.lastname')"
      slim
    >
      <bc-input
        ref="last-name"
        v-model="lastName"
        :class="{ 'invalid': errors.length > 0 }"
        :error="errors[0]"
        :valid="valid"
        class="panel-edit-coach-caption-coach__input"
        icon="user"
        :label="$t('contacts.lastname')"
        name="last-name"
        :placeholder="$t('contacts.lastname')"
        type="text"
      >
      </bc-input>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, valid }"
      :rules="{ min: 2, regex: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u }"
      :name="$t('contacts.firstname')"
      slim
    >
      <bc-input
        ref="first-name"
        v-model="firstName"
        :class="{ 'invalid': errors.length > 0 }"
        :error="errors[0]"
        :valid="valid"
        class="panel-edit-coach-caption-coach__input"
        icon="user"
        :label="$t('contacts.firstname')"
        name="first-name"
        :placeholder="$t('contacts.firstname')"
        type="text"
      >
      </bc-input>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, valid }"
      :rules="{ min: 3 }"
      :name="$t('avatar-dropdown.job-title')"
      slim
    >
      <bc-input
        ref="job"
        v-model="job"
        :class="{ 'invalid': errors.length > 0 }"
        :error="errors[0]"
        :valid="valid"
        class="panel-edit-coach-caption-coach__input"
        icon="users"
        :label="$t('avatar-dropdown.job-title')"
        name="job"
        :placeholder="$t('companies.panel-company.job')"
        type="text"
      >
      </bc-input>
    </ValidationProvider>
    <bc-dropdown
      ref="role"
      :close-dropdown-at-select="true"
      :disabled="!canUpdateRole"
      :options="roleOptions"
      class="panel-edit-coach-caption-coach__input"
      emit-object
      is-expanded
      @change="updateRole"
    >
      <template #label="{ isDropdownOpen }">
        <bc-dropdown-trigger
          :disabled="!canUpdateRole"
          :is-arrow="true"
          :is-dropdown-open="isDropdownOpen"
          :text="role"
          icon="diploma-2"
          :label="$t('avatar-dropdown.choose-profile')"
        >
        </bc-dropdown-trigger>
      </template>
      <template #option="{ activeIndex, index, option }">
        <bc-dropdown-item
          :is-focus="activeIndex === index"
          :text="option.name"
          @click.native="updateRole(option)"
        >
        </bc-dropdown-item>
      </template>
    </bc-dropdown>
    <bc-dropdown
      :close-dropdown-at-select="true"
      :disabled="!canUpdateManager"
      :options="teamOptions"
      class="panel-edit-coach-caption-coach__input is-full-width col-xs-12"
    >
      <template #label="{ isDropdownOpen }">
        <bc-dropdown-trigger
          :disabled="!canUpdateManager"
          :is-arrow="true"
          :is-dropdown-open="isDropdownOpen"
          :text="selectedTeam.name"
          icon="users"
          :label="$t('activities.team-cap')"
        >
        </bc-dropdown-trigger>
      </template>
      <template #option="{ activeIndex, index, option }">
        <bc-dropdown-item
          :is-focus="activeIndex === index"
          :text="option.name"
          @click.native="updateTeam(option)"
        >
        </bc-dropdown-item>
      </template>
    </bc-dropdown>
    <div style="width: 100vw;"></div>
    <ValidationProvider
      v-slot="{ errors }"
      :rules="{ min: 8, max: 20, regex: /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i }"
      :name="$t('contacts.phone')"
      slim
    >
      <bc-input-phone
        ref="phone"
        v-model="mobile"
        :class="{ 'invalid': errors.length > 0 }"
        class="panel-edit-coach-caption-coach__input"
        icon="phone"
        name="phone"
        :placeholder="$t('contacts.phone')"
        @error="isPhoneValid = false"
        @valid="isPhoneValid = true"
        @keydown.native.enter.prevent
      >
      </bc-input-phone>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, valid }"
      :rules="{ email: true }"
      name="Email"
      slim
    >
      <bc-input
        ref="email"
        v-model="email"
        :class="{ 'invalid': errors.length > 0 }"
        :error="errors[0]"
        :valid="valid"
        :disabled="!isMarvin"
        class="panel-edit-coach-caption-coach__input"
        icon="mail"
        label="Email"
        name="email"
        placeholder="Email"
        type="text"
      >
      </bc-input>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, valid }"
      name="LinkedIn"
      slim
    >
      <bc-input
        ref="linkedin"
        v-model="linkedin"
        :class="{ 'invalid': errors.length > 0 }"
        :error="errors[0]"
        :valid="valid"
        class="panel-edit-coach-caption-coach__input"
        icon="linkedin"
        label="LinkedIn"
        name="linkedin"
        placeholder="LinkedIn"
        type="text"
      >
      </bc-input>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, valid }"
      :name="$t('configuration.hide-home-page')"
      class="mt-[30px]"
    >
      <BcCheckbox
        id="hide-homeboarding-page"
        v-model="hideOnboardingPage"
      >
        {{ $t('configuration.hide-home-page') }}
      </BcCheckbox>
    </ValidationProvider>
  </div>
</template>

<script>
import BcInput from '@/ui-kit/components/BcInput/BcInput';
import { ValidationProvider } from 'vee-validate';
import TitleSection from '@/components/Title/TitleSection';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
import { apiRoles, roleOptions } from '@/macros/coaches/roles';
import BcInputPhone from '@/ui-kit/components/BcInputPhone/BcInputPhone';
import { getRoleNameFromApiValue } from '@/managers/coaches/formatters/coachesDeserializers';
import { mapState } from 'vuex';
import { marvinRecruiterOrganizationId } from '@/macros/utils';

export default {
  name: 'panel-edit-coach-caption-coach',
  components: {
    BcInputPhone,
    BcDropdownItem,
    BcDropdownTrigger,
    BcDropdown,
    TitleSection,
    BcInput,
    ValidationProvider,
    BcCheckbox,
  },
  data() {
    return {
      roleOptions,
    };
  },
  props: {
    profile: {
      type: Object,
      default: () => ({}),
    },
    editedProfile: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState({
      coaches: state => state.coaches?.coaches,
      user: state => state.user?.profile,
      teams: state => state.teams.teams,
    }),
    isMarvin() {
      return this.$store.state.user.profile?._organization?._id === marvinRecruiterOrganizationId;
    },
    teamOptions() {
      const teams = this.teams.map(team => ({
        name: team.name,
        query: team._id,
      }));

      return [
        {
          name: 'Aucune',
          query: null,
        },
        ...teams.sort((a, b) => a.name.localeCompare(b.name))];
    },
    canUpdateRole() {
      return (this.user.role === apiRoles.product || this.user.role === apiRoles.businessManager) && this.user._id !== this.profile._id;
    },
    canUpdateManager() {
      return true;
      // return this.user.role === apiRoles.businessManager;
    },
    lastName: {
      get() {
        return this.editedProfile.hasOwnProperty('lastName') && this.editedProfile.lastName !== this.profile.lastName ? this.editedProfile.lastName : this.profile.lastName;
      },
      set(value) {
        this.$emit('update-value', {
          lastName: value,
        });
      },
    },
    firstName: {
      get() {
        return this.editedProfile.hasOwnProperty('firstName') && this.editedProfile.firstName !== this.profile.firstName ? this.editedProfile.firstName : this.profile.firstName;
      },
      set(value) {
        this.$emit('update-value', {
          firstName: value,
        });
      },
    },
    job: {
      get() {
        return this.editedProfile.hasOwnProperty('job') && this.editedProfile.job !== this.profile.job ? this.editedProfile.job : this.profile.job;
      },
      set(value) {
        this.$emit('update-value', {
          job: value,
        });
      },
    },
    location: {
      get() {
        return this.editedProfile.hasOwnProperty('location') && this.editedProfile.location !== this.profile.location ? this.editedProfile.location : this.profile.location;
      },
      set(value) {
        this.$emit('update-value', {
          location: value,
        });
      },
    },
    mobile: {
      get() {
        return this.editedProfile.hasOwnProperty('mobile') && this.editedProfile.mobile !== this.profile.mobile ? this.editedProfile.mobile : this.profile.mobile;
      },
      set(value) {
        this.$emit('update-value', {
          mobile: value,
        });
      },
    },
    email: {
      get() {
        return this.editedProfile.hasOwnProperty('email') && this.editedProfile.email !== this.profile.email ? this.editedProfile.email : this.profile.email;
      },
      set(value) {
        this.$emit('update-value', {
          email: value,
        });
      },
    },
    linkedin: {
      get() {
        return this.editedProfile.hasOwnProperty('linkedin') && this.editedProfile.linkedin !== this.profile.linkedin ? this.editedProfile.linkedin : this.profile.linkedin;
      },
      set(value) {
        this.$emit('update-value', {
          linkedin: value,
        });
      },
    },
    hideOnboardingPage: {
      get() {
        return this.editedProfile.onboarding?.hasOwnProperty('hideOnboardingPage') && this.editedProfile.onboarding.hideOnboardingPage !== this.profile.onboarding?.hideOnboardingPage ? this.editedProfile.onboarding.hideOnboardingPage : this.profile.onboarding?.hideOnboardingPage;
      },
      set(value) {
        this.$emit('update-value', {
          onboarding: {
            hideOnboardingPage: value,
          },
        });
      },
    },
    role() {
      return this.editedProfile.hasOwnProperty('role') && this.editedProfile.role !== this.profile.role ? getRoleNameFromApiValue(this.editedProfile.role) : getRoleNameFromApiValue(this.profile.role);
    },
    selectedTeam() {
      if (this.editedProfile._teams?.length) {
        const selectedTeam = this.teams.find(team => this.editedProfile._teams.includes(team._id));

        return {
          name: selectedTeam?.name,
          query: selectedTeam?._id,
        };
      } else if (this.profile._teams) {
        const selectedTeam = this.teams.find(team => this.profile._teams.includes(team._id));

        return {
          name: selectedTeam?.name,
          query: selectedTeam?._id,
        };
      } else {
        return {
          name: 'Aucune',
          query: null,
        };
      }
    },
  },
  methods: {
    updateRole(role) {
      this.$emit('update-value', {
        role: role.api,
      });
    },
    updateTeam(team) {
      this.$emit('update-value', {
        _teams: team.query ? [team.query] : [],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-edit-coach-caption-coach {
  &__title {
    margin-bottom: 20px;
  }

  &__input {
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include bp('tablet') {
      flex-basis: calc(50% - 5px);

      &:last-of-type {
        flex-basis: 100%;
      }
    }
  }
}
</style>
