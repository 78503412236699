<template>
  <div class="onboarding-chapter-item">
    <button
      class="onboarding-chapter-item__button"
      :class="{ 'onboarding-chapter-item__button--active': selected }"
      @click="handleClick(chapter.key)">
      <div
        class="onboarding-chapter-item__icon"
        :class="{ 'onboarding-chapter-item__icon--success': complete }">
        <i :class="`icon-${complete ? 'check' : chapter.icon}`"></i>
      </div>
      <div class="onboarding-chapter-item__titles">
        <p
          class="onboarding-chapter-item__title body-l--bold"
          :class="{ 'onboarding-chapter-item__title--active': selected }">
          {{ chapter.title }}
        </p>
        <p class="onboarding-chapter-item__subtitle body-s">{{ chapter.subtitle }}</p>
      </div>
    </button>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'onboarding-chapter-item',
  props: {
    chapter: {
      type: Object,
    },
    selected: {
      type: Boolean,
    },
    complete: {
      type: Boolean,
    }
  },
  methods: {
    handleClick(chapterKey) {
      this.$emit('click', chapterKey);
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding-chapter-item {
  align-items: center;
  width: 100%;
  min-width: fit-content;

  &__button {
    border-radius: 10px;
    padding: 14px 20px;
    border: 1px solid $color-blue-medium;
    background: white;
    align-items: center;
    width: 100%;

    &--active {
      border-color: $color-primary;
    }
  }

  &__titles {
    flex-direction: column;
    text-align: left;
  }

  &__title {
    color: $color-tertiary-2;

    &--active {
      color: $color-primary;
    }
  }

  &__subtitle {
    color: $color-blue-heavy-dark;
  }

  &__icon {
    background: rgba(56, 101, 253, 0.1);
    padding: 10px;
    border-radius: 100%;
    margin-right: 10px;

    & i {
      color: $color-primary;
    }

    &--success {
      background: #6ED69A1A;

      & i {
        color: $color-success-light;
      }
    }
  }
}
</style>
