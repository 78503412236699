<template>
  <transition :name="isPanel ? 'panel' : 'zoom-out'">
    <div
      class="bc-modal is-top is-bottom is-left is-right"
      :class="{ 'is-align-items-center is-justify-content-center': !isPanel }"
      v-if="active">
      <div
        @click.stop="close"
        class="bc-modal__overlay">
      </div>
      <div
        class="bc-modal__content is-relative"
        :class="{
          'border-radius-l': !isPanel,
          'bc-modal__content--panel-right': isPanel,
        }">
        <slot v-if="$slots.default"></slot>
        <button v-if="!isPanel" @click.prevent="close" class="bc-modal__close"><i class="icon-cross"></i></button>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'bc-modal',
    props: {
      active: {
        type: Boolean,
        default: false,
      },
      closable: {
        type: Boolean,
        default: true,
      },
      isPanel: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      active: {
        handler() {
          this.handleScroll();
        },
        immediate: true,
      },
    },
    created() {
      if (typeof window !== 'undefined') {
        document.addEventListener('keyup', this.keyPress);
      }
    },
    beforeDestroy() {
      document.documentElement.classList.remove('is-clipped');
    },
    methods: {
      close() {
        if (!this.closable) {
          return;
        }
        this.$emit('close');
        this.$emit('update:active', false);
      },
      handleScroll() {
        if (typeof window === 'undefined') {
          return;
        }

        if (this.active) {
          document.documentElement.classList.add('is-clipped');
        } else {
          document.documentElement.classList.remove('is-clipped');
        }
      },
      keyPress(event) {
        if (this.active && event.key === 'Escape') {
          this.close();
        }
      },
    },
  };
</script>

<style lang=scss scoped>
  .bc-modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 99999;

    &__overlay {
      background: $color-grey-5;
      position: absolute;
      opacity: 0.5;
      width: 100%;
      height: 100%;
    }

    &__content {
      background: $color-white;
      z-index: 1;

      &--panel-right {
        position: absolute;
        width: fit-content;
        height: 100%;
        right: 0;
      }
    }

    &__close {
      position: absolute;
      top: 30px;
      right: 30px;

      & > i {
        color: $color-blue-heavy-dark;
      }
    }
  }

  .zoom-out-enter-active,
  .zoom-out-leave-active {
    transition: opacity 0.3s;

    .bc-modal__content,
    .bc-modal__content {
      transition: transform 0.3s;
    }
  }

  .zoom-out-enter,
  .zoom-out-leave-active {
    opacity: 0;

    .bc-modal__content,
    .bc-modal__content {
      transform: scale(1.05);
    }
  }

  .panel-enter-active,
  .panel-leave-active {
    transition: opacity 0.3s;

    .bc-modal__content,
    .bc-modal__content {
      transition: transform 0.3s;
    }
  }

  .panel-enter,
  .panel-leave-active {
    opacity: 0;

    .bc-modal__content,
    .bc-modal__content {
      transform: translateX(100%);
    }
  }
</style>
