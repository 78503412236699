<script lang="ts" setup>
  import { Company } from '@/domains/models/Company';
  import { formatPlaceDetailsToBaseLocation } from '@/formatters/locations';

  import { computed, defineEmits, defineProps } from 'vue';
  import { useI18n } from '@/i18n/i18n';
  import { useStore } from '@/store';
  import PanelCompanyDetailsLine from './PanelCompanyDetailsLine.vue';
  import Tag from '@/components/Tag/Tag';

  const i18n = useI18n();
  const store = useStore();

  interface Props {
    company: Company;
  }

  const props = defineProps<Props>();
  const user = store.state.user.profile;
  const customFields = user._organization.configuration?.company?.customFields?.filter(customField => customField.activated) || [];

  defineEmits<{
    (e: 'edit-company', value: boolean): void
  }>();

  const locations = computed<string[]>(() => {
    return props.company.locations?.map(location => formatPlaceDetailsToBaseLocation(location, 'locality')) || [''];
  });
  const size = computed<string>(() => {
    return i18n.t(`panel-company-edit.size-options-${props.company.size || 0}`);
  });
</script>

<template>
  <div class="relative flex w-full flex-col flex-wrap rounded-md bg-neutral-200 p-2.5 text-sm">
    <PanelCompanyDetailsLine v-if="company.uniqueid" :title="$t('generics.uniqueid')" icon="companies">
      <div class="wrap flex justify-end gap-2.5">
        <Tag :text="company.uniqueid" icon="companies"/>
      </div>
    </PanelCompanyDetailsLine>
    <PanelCompanyDetailsLine
      :title="$t('generics.location')"
      icon="map-pin"
    >
      <div class="flex flex-wrap justify-end gap-2.5">
        <Tag
          v-for="location in locations"
          :key="location.placeId"
          :text="location.completeAddress ? location.completeAddress : location.fullAddress"
          type="location"
        />
      </div>
    </PanelCompanyDetailsLine>
    <PanelCompanyDetailsLine
      :title="$t('panel-company-edit.size')"
      icon="users"
    >
      <div class="wrap flex justify-end gap-2.5">
        <Tag
          :text="size"
          type="size"
        />
      </div>
    </PanelCompanyDetailsLine>
    <PanelCompanyDetailsLine
      v-for="(customField, index) in customFields"
      v-show="company?.customFields?.[customField.key] != null"
      :key="`custom-field-${index}`"
      :icon="customField.icon"
      :title="customField.name">
      <div class="flex flex-wrap justify-end gap-2.5">
        <Tag
          v-tooltip.bottom="customField.name"
          :text="
            customField.key === 'experience'
              ? `${company?.customFields?.[customField.key]} ${$tc(
                  'global.year',
                  company?.customFields?.[customField.key]
                )}`
              : Array.isArray(company?.customFields?.[customField.key])
                ? company?.customFields?.[customField.key]?.map(value => `${value} ${customField.unit ? customField.unit : ''}`)?.join(' - ')
                : company?.customFields?.[customField.key]
          "
          :type="customField.key" />
      </div>
    </PanelCompanyDetailsLine>
  </div>
</template>
