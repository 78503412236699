export const apiStatus = {
  pending: 1,
  introduced: 2,
  processed: 3,
  finalised: 4,
  offered: 5,
  dealt: 6,
  onboarding: 7,
  validated: 8,
};

export const processStatus = {
  pending: 'pending',
  introduced: 'introduced',
  processed: 'processed',
  finalised: 'finalised',
  offered: 'offered',
  dealt: 'dealt',
  onboarding: 'onboarding',
  validated: 'validated',
};


export const limitDateToSetOld = 999;
export const dealCommission = 0.25;

export const modalTypes = {
  offered: {
    modalName: 'modal-process-new-offer',
    isBlocking: true,
  },
  dealt: {
    modalName: 'modal-process-form-deal',
    isBlocking: false,
  },
  onboarding: {
    modalName: 'modal-process-start-validation',
    isBlocking: true,
  },
  validated: {
    modalName: 'modal-process-onboarding-validation',
    isBlocking: true,
  },
};

export default {
  apiStatus,
  limitDateToSetOld,
  dealCommission,
  modalTypes,
};
