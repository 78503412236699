<template>
  <router-link v-slot="{ href }" :to="to" class="is-full-width">
    <router-link :to="href">
      <topbar-dropdown
        class="topbar-config-item is-full-width"
        @set-active-status="isDropdownOpen = $event">
        <template #header>
          <topbar-config-trigger
            :is-active="isActive"
            class="topbar-config-item__trigger"
            :class="{ 'topbar-config-item__trigger-active': isActive }"
            :text="label">
          </topbar-config-trigger>
        </template>
      </topbar-dropdown>
    </router-link>
  </router-link>
</template>

<script>
import TopbarDropdown from '@/components/Topbar/TopbarDropdown';
import TopbarConfigTrigger from '@/components/Topbar/TopbarConfig/TopbarConfigTrigger';

export default {
  name: 'topbar-config-item',
  components: {
    TopbarDropdown,
    TopbarConfigTrigger,
  },
  props: {
    isHover: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    to: {
      type: [String, Object]
    },
    tabsPath: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  computed: {
    isActive() {
      return this.tabsPath.includes(this.$route.path);
    }
  },
}
</script>

<style lang="scss" scoped>
.topbar-config-item {
  border: none;

  &:deep() {
    .topbar-trigger {
      &--active {
        background: #EDF0FF;
        border-radius: inherit;
        & p {
          color: $color-primary;
          font-weight: 400 !important;
          font-size: 15px;
          line-height: 18px;
        }
      }
    }
    .topbar-trigger__text {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
    }
  }
}
</style>
