<template>
  <div class="industries-modal-edit-skills is-column">
    <div class="is-align-items-center is-full-width">
      <bc-autocomplete
        v-model="input"
        :keep-first="true"
        :options="matches"
        class="industries-modal-edit-skills__autocomplete is-full-width"
        icon="tool"
        label="Skills"
        name="skills"
        placeholder="Créer un nouveau skill"
        @keydown.native.enter="addSkill">
        <template #option="{ option }">
          <bc-dropdown-item
            :text="option.name"
            class="industries-modal-edit-skills__option font-size-s is-expanded">
          </bc-dropdown-item>
        </template>
      </bc-autocomplete>
    </div>
    <div class="industries-modal-edit-skills__wrapper is-flex-wrap">
      <bc-tag
        v-for="(skill, index) in skills"
        :key="index"
        class="industries-modal-edit-skills__tag"
        deletable
        @click="removeSkill(skill)">
        {{ skill.name }}
      </bc-tag>
    </div>
  </div>
</template>

<script>
  import BcTag from '@/ui-kit/components/BcTag/BcTag';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';

  export default {
    name: 'industries-modal-edit-skills',
    components: { BcDropdownItem, BcAutocomplete, BcTag },
    props: {
      skills: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        input: '',
      };
    },
    computed: {
      matches() {
        if (!this.input) {
          return this.skills;
        }

        return this.skills.filter(skill => skill.name.toLowerCase().indexOf(this.input.toLowerCase()) === 0);
      },
    },
    methods: {
      addSkill() {
        this.$emit('update-value', {
          skills: [{ name: this.input }, ...this.skills],
        });

        this.input = '';
      },
      removeSkill(skill) {
        this.$emit('update-value', {
          skills: this.skills.filter(l => l.name.toLowerCase() !== skill.name.toLowerCase()),
        });

        this.$emit('skill-deleted', skill)
      },
    },
  };
</script>

<style lang="scss" scoped>
  .industries-modal-edit-skills {
    &__autocomplete {
      margin-bottom: 10px;
    }

    &__option {
      line-height: 15px;
      padding: 10px 20px;
    }

    &__wrapper {
      margin: -5px;
    }

    &__tag {
      margin: 5px;
    }
  }
</style>
