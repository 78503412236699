<script lang="ts" setup>
  import MvSelect from '@/ui-kit/components/MvSelect/MvSelect.vue';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
  import BcInput from '@/ui-kit/components/BcInput/BcInput.vue';
  import { computed, ref } from 'vue';

  const props = defineProps<{
    templatesOptions: any[];
    selectedTemplate: any;
  }>();

  const emit = defineEmits<{
    (e: 'select-template', value: string): void;
  }>();

  function onSelectTemplate(template) {
    emit('select-template', template);
  };

  const searchInput = ref('');
  const filteredOptions = computed(() => {
    if (!searchInput.value) {
      return props.templatesOptions;
    }

    return props.templatesOptions.filter((option) => {
      return option.name.toLowerCase().includes(searchInput.value.toLowerCase());
    });
  });
</script>

<template>
  <BcDropdown
    :close-dropdown-at-select="false"
    :is-scrollable="true"
    position="bottom-left">
    <template #label="{ isDropdownOpen }">
      <MvSelect
        :as-button="true"
        :selected-label="selectedTemplate?.name || $t('generics.select-a-template')"
        :value="selectedTemplate?.value"
        class="flex shrink-0"
        icon="file"
        label-size="l"
        required
      />
    </template>
    <template #custom="{ closeDropdown }">
      <div class="is-full-width flex w-full min-w-[350px] flex-col">
        <div class="w-full p-[15px]">
          <BcInput
            v-model="searchInput"
            :label="$t('generics.search-placeholder', {object: $t('generics.a-template')})"
            :label-placeholder="false"
            :placeholder="$t('campaigns.template-search-placeholder')"
            class="w-full"
            name="search"/>
        </div>
        <BcDropdownItem
          v-for="(option) in filteredOptions"
          :key="option._id"
          :is-focus="selectedTemplate?._id === option._id"
          class="relative flex"
          @click.native="() => {
            closeDropdown()
            onSelectTemplate(option)
          }">
          <div class="flex w-full">
            <p class="w-full truncate">{{ option.name }}</p>
          </div>
        </BcDropdownItem>
      </div>
    </template>
  </BcDropdown>
</template>
