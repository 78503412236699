<template>
  <div
    class="companies-empty is-full-width is-justify-content-center is-column is-align-items-center">
    <h1 class="companies-empty__title font-size-xl is-secondary mb-3">
      {{ $t('generics.no-result-found') }}
    </h1>
  </div>
</template>

<script>
  export default {
    name: 'companies-empty',
    props: {
      isChecked: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang=scss scoped>
  .companies-empty {
    &__text {
      max-width: 800px;
    }

  }
</style>
