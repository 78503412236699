<template>
  <div
    :class="{
      'bg-red-500/5': !cardContent.enabled,
    }"
    class="card-coaches flex w-full flex-col">
    <card-coaches-informations
      :card-content="cardContent"
      :displayed-columns="displayedColumns"
      :disabled="!cardContent.enabled"
      class="overflow-hidden hover:cursor-pointer"
      @click.meta.native.exact="openPanelInNewTab({ id: cardContent._id, type: 'coaches' })"
      @click.native.exact="openPanel({ id: cardContent._id, type: 'coaches' })" />
  </div>
</template>

<script setup lang="ts">
import { defineProps, withDefaults } from 'vue';
import CardCoachesInformations from '@/components/Card/CardCoaches/CardCoachesInformations.vue';
import { usePanelNavigation } from '@/composables/usePanelNavigation';


type DisplayedColumns = {
  avatar: boolean;
  name: boolean;
  email: boolean;
  logAs: boolean;
  toggle: boolean;
};

withDefaults(defineProps<{
  cardContent: any;
  displayedColumns?: DisplayedColumns;
}>(), {
  displayedColumns: () => ({
    avatar: true,
    name: true,
    email: true,
    logAs: true,
    toggle: true,
  }),
});


const { openPanel, openPanelInNewTab } = usePanelNavigation();
</script>
