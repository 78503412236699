export const buildCompanyPartnership = (query) => {
  return {
    name: '',
    query,
    type: 'status',
  };
};

export default {
  buildCompanyPartnership,
};
