<template>
  <div class="searchbar-autocomplete-jobs">
    <div class="searchbar-autocomplete-jobs__bar">
      <div
        :class="{ 'searchbar-autocomplete-jobs__wrapper--active': searchbarInput !== '' && isFocusedSearchbar }"
        class="searchbar-autocomplete-jobs__wrapper">
        <div class="searchbar-autocomplete-jobs__icon icon-search"></div>
        <input-model
          :border-color="'#FFFFFF'"
          :height="'40px'"
          :name="'technos'"
          :placeholder="$t('companies.account-management.search-by-keywords-jobs-skills-etc')"
          :ref="'searchbar-autocomplete-jobs-input'"
          :type="'text'"
          :value="searchbarInput"
          @blur="closeSuggestionsList"
          @focus="openSuggestionsList()"
          @keypressDown="incrementPointer(suggestions)"
          @keypressEnter="triggerAutocompleteEvent(suggestionSelected), setPointer(0)"
          @keypressUp="decrementPointer"
          @keypressDelete="deleteInputQuery()"
          bc-font-size="14px"
          class="searchbar-autocomplete-jobs__input"
          v-model="searchbarInput">
        </input-model>
        <searchbar-autocomplete-list
          :item-value="'name'"
          :list="suggestions"
          :pointer="pointer"
          :value="inputValue"
          :last-indexes-of-categories="lastIndexesOfCategories"
          @click="triggerAutocompleteEvent(suggestionSelected), pointer = 0"
          @mouseenter="setPointer($event)"
          class="searchbar-autocomplete-jobs__autocomplete-list"
          v-if="suggestions.length > 0 && isFocusedSearchbar">
        </searchbar-autocomplete-list>
      </div>
      <searchbar-buttons-text
        class="searchbar-autocomplete-jobs__button">
      </searchbar-buttons-text>
    </div>
    <keep-alive>
      <searchbar-tags
        @on-delete="emitOnDelete"
        class="searchbar-autocomplete-jobs__tag-container"
        v-if="!isActiveFilter">
      </searchbar-tags>
    </keep-alive>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import debounce from 'debounce';
  import { getTagsAutocomplete } from '@/api/tags';
  import partnersApi from '@/api/company';
  // mixins
  import AutocompleteMixin from '@/common-old/mixins/Autocomplete/autocomplete';
  import TechnosMixin from '@/mixins/Technos';
  import SearchbarAutocompleteEventsMixin from '@/mixins/SearchbarAutocomplete/searchbarAutocompleteEvents';

  //
  import InputModel from '@/legacy/ui-kit/components/Input/BcInput';

  // components
  import SearchbarAutocompleteList
    from '@/components/Searchbar/SearchbarAutocomplete/SearchbarAutocompleteList';
  import SearchbarButtonsText from '@/components/Searchbar/SearchbarButtons/SearchbarButtonsText';
  import SearchbarTags from '@/components/Searchbar/SearchbarTags';
  import { jobsAutocomplete } from '@/api/jobs';

  export default {
    name: 'searchbar-autocomplete-jobs',
    components: {
      SearchbarTags,
      SearchbarButtonsText,
      SearchbarAutocompleteList,
      InputModel
    },
    mixins: [AutocompleteMixin, TechnosMixin, SearchbarAutocompleteEventsMixin],
    data() {
      return {
        isFocusedSearchbar: false,
        searchbarInput: '',
        matchesLimit: 5,
        suggestions: [],
        lastIndexesOfCategories: []
      };
    },
    mounted() {
      const input = this.$refs['searchbar-autocomplete-jobs-input'].$el.children[0];
      if (input) {
        input.focus();
      }
    },
    computed: {
      ...mapState({
        inputValue: state => state.autocomplete.inputValue,
        isActiveFilter: state => state.searchbar.isActiveFilter,
        searchbarTags: state => state.searchbar.searchbarFiltersTags,
      }),
      suggestionSelected() {
        if (typeof this.pointer === 'number') {
          return this.suggestions[this.pointer || 0];
        }
        return this.pointer;
      }
    },
    methods: {
      /***
       *
       * Clear the input field
       */
      clearInput() {
        this.searchbarInput = '';
      },
      emitOnDelete() {
        this.$emit('on-delete');
      },
      /**
       *
       * Delete inside the searchbarTags array, the last tag
       */
      deleteInputQuery() {
        if (this.searchbarInput.length === 0 && this.searchbarTags.length > 0) {
          this.$store.dispatch('popSearchbarFiltersTags');

          this.$emit('on-delete');
        }
      },
      updateLastIndexesOfCategories() {
        this.lastIndexesOfCategories = [];

        for (let i = 0; i < this.suggestions.length - 1; i++) {
          if (this.suggestions[i].type !== this.suggestions[i + 1].type) {
            this.lastIndexesOfCategories.push(i + 1);
          }
        }
      },
      async getJobsSuggestions() {
        const jobsPromise = jobsAutocomplete(this.searchbarInput);
        const partnersPromise = partnersApi.partnersAutocomplete(this.searchbarInput);
        const skillsPromise = getTagsAutocomplete({ name: this.searchbarInput });

        const [jobs, partners, skills] = await Promise.all([jobsPromise, partnersPromise, skillsPromise]);

        if (!this.isFocusedSearchbar) {
          this.openSuggestionsList();
        }

        this.jobsSuggestions = jobs.data.map(job => ({
          type: 'job',
          _id: job._id,
          name: job.name,
          info: job.companyName,
          icon: 'suitcase'
        }));

        this.partnersSuggestions = partners.data.map(partner => ({
          type: 'partner',
          _id: partner._id,
          name: partner.name,
          picture: partner.logoUrl || '@/assets/img/default-avatar.svg'
        }));

        const skillsSuggestions = skills.data.map(skill => ({
          type: 'techno',
          icon: 'tool',
          ...skill,
        }))

        this.suggestions = [...this.jobsSuggestions, ...this.partnersSuggestions, ...skillsSuggestions];

        this.suggestions.push({ name: this.searchbarInput, type: 'input' });
      },
      clearSuggestions() {
        this.suggestions = [];
      },
      openSuggestionsList() {
        this.isFocusedSearchbar = true;
      },
      closeSuggestionsList() {
        this.isFocusedSearchbar = false;
      }
    },
    watch: {
      /***
       * Watch the input value to set the autocomplete input value
       */
      searchbarInput: debounce(async function(newVal) {
        this.$store.dispatch('setAutocompleteInputValue', this.searchbarInput);

        if (newVal) {
          await this.getJobsSuggestions();
        } else {
          this.clearSuggestions();
        }

        this.updateLastIndexesOfCategories();
      }, 400),
    }
  };
</script>

<style scoped lang="scss">
  .searchbar-autocomplete-jobs {
    flex-direction: column;
    width: 100%;

    &__bar {
      flex-direction: column;
      width: 100%;
      align-items: center;
      justify-content: space-around;
      min-height: 50px;
      position: relative;
      @include bp('tablet') {
        flex-direction: row;
        align-items: stretch;
      }
    }

    &__wrapper {
      position: relative;
      justify-content: center;
      width: 100%;
      padding: 0 0 0 55px;
      height: 100%;
      align-items: center;
      border-radius: 4px;
      background: $color-white;
      border: 1px solid transparent;
      box-shadow: 0 3px 18px rgba(0, 0, 0, 0.15);
      @include bp('tablet') {
        height: auto;
      }
    }

    &__icon {
      font-size: $font-size-xxl;
      color: rgba($color-blue-san-juan, 0.4);
      position: absolute;
      left: 15px;
      transition: 0.3s;

      &--active {
        transform: rotate(90deg);
      }
    }

    &__button {
      margin-top: 10px;
      @include bp('tablet') {
        margin-top: 0;
        margin-left: 10px;
      }
    }

    &__autocomplete-list {
      position: absolute;
      z-index: 3;
      width: 100%;
    }

    &__input {
      @include bp('tablet') {
        min-width: 260px;
        margin-left: -30px;
      }
    }

    &__tag-container {
      margin-top: 10px;
      flex-wrap: wrap;
      margin-bottom: -10px;
    }

    &__tag {
      margin-right: 10px;
      max-height: 30px;
      margin-bottom: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
</style>
