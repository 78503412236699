<template>
  <div class="bc-checkbox is-column">
    <label
      :for="computedId"
      class="bc-checkbox__label font-size-m is-relative is is-flex is-align-items-center is-justify-content-center"
      :class="{
        'bc-checkbox--disabled': disabled,
        'bc-checkbox--checked': value,
        'bc-checkbox--checked-and-disabled': disabled && value,
        'is-row-reverse': reversed
      }"
    >
      <span class="bc-checkbox__wrapper is-justify-content-center is-align-items-center is-flex">
        <input
          :checked="value"
          :disabled="disabled"
          :id="computedId"
          @click="clickEmitter($event, value)"
          class="bc-checkbox__box"
          :class="{ 'bc-checkbox__box--checked': value }"
          type="checkbox"
          v-bind="$attrs">
        <span class="bc-checkbox__checked-icon font-size-s icon-checkbox is-flex is-absolute"></span>
      </span>
      <span
        :class="{ 'bc-checkbox__content--reversed': reversed }"
        class="bc-checkbox__content is-flex is-align-items-center"
        v-if="$slots.default">
        <!-- @slot The Label for the checkbox -->
        <slot></slot>
      </span>
    </label>
    <p
      class="bc-checkbox__error font-size-s"
      v-if="error">
      {{ error }}
    </p>
  </div>
</template>

<script>
  export default {
    name: 'bc-checkbox',
    inheritAttrs: false,
    props: {
      /**
       * The id is required for html semantic
       */
      id: {
        type: String,
        required: true,
      },
      /**
       * The value of the checkbox
       */
      value: {
        type: Boolean,
        default: false,
      },
      /**
       * Disable the label
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Set the label to the left
       */
      reversed: {
        type: Boolean,
        default: false,
      },
      error: {
        type: String,
        default: '',
      },
    },
    computed: {
      computedId() {
        return `${+new Date()}-${this.id}`;
      },
    },
    methods: {
      clickEmitter($event, value) {
        /**
         * click -> Emit the opposite of the value
         *
         * @event input
         * @type {boolean}
         */
        this.$emit('input', !value, $event);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .bc-checkbox {
    $this: &;

    &__label {
      color: $color-blue-heavy-dark;
      cursor: pointer;
      opacity: 1;
    }

    &__box {
      height: 18px;
      width: 18px;
      border-radius: $border-radius-xs;
      transition: 0.3s;
      border: 1px solid $color-blue-heavy-dark;
      cursor: pointer;

      &--checked {
        border: 1px solid $color-primary;
        background-color: $color-primary;
      }

      &:disabled {
        border: 1px solid $color-grey-4;
        background-color: $color-grey-2;
        cursor: default;
        opacity: 0.5;

        &--checked {
          border: 1px solid $color-primary;
          background-color: $color-primary;
        }
      }
    }

    &__checked-icon {
      border: 0;
      color: $color-white;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      font-size: 10px;
    }

    &__box--checked + &__checked-icon {
      opacity: 1;
    }

    &__content {
      margin-left: 10px;
      @include bp('phone'){
        font-size: 0.9rem;
        margin-left: 5px;
      }
      &--reversed {
        margin-left: 0;
        margin-right: 10px;
      }
    }

    &:hover {
      color: $color-primary;

      #{$this}__box {
        border: 1px solid $color-primary;
        background-color: $color-grey;

        &--checked {
          border: 1px solid $color-primary-dark;
          background-color: $color-primary-dark;
          opacity: 1;
        }

        &:disabled {
          border: 1px solid $color-grey-4;
          background-color: $color-grey-2;
          cursor: default;
          opacity: 0.5;

          &:checked {
            border: 1px solid $color-primary;
            background-color: $color-primary;
            opacity: 0.5;
          }

          &--checked {
            border: 1px solid $color-primary;
            background-color: $color-primary;
            opacity: 0.5;
          }
        }
      }
    }

    &--checked {
      color: $color-primary;

      &:hover {
        color: $color-primary-dark;
      }
    }

    &--disabled {
      color: $color-grey-4;
      opacity: 0.5;
      cursor: default;

      &:hover {
        color: $color-grey-4;
        opacity: 0.5;
      }
    }

    &--checked-and-disabled {
      color: $color-primary;

      &:hover {
        color: $color-primary;
        opacity: 0.5;
      }
    }

    &__error {
      margin-top: 5px;
      color: $color-error;
    }
  }
</style>
