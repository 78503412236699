<template>
  <div
    :class="{
      'processes-card--warning': warningOnCard,
      'is-draggable processes-card--draggable': isDraggable,
    }"
    class="processes-card">
    <div class="processes-card__coach">
      <bc-avatar
        :key="currentCoach._id + Math.random() + Date.now()"
        v-tooltip.bottom="coachTooltip"
        :alt="currentCoach.firstName"
        :placeholder="getCoachInitials()"
        :src="currentCoach.pictureUrl"
        bc-avatar-size="20px"
        size="xs"
      >
      </bc-avatar>
    </div>
    <div class="gap-1 flex flex-col">
      <div
        v-if="configurationFramework.showCompanies"
        class="processes-card__company"
      >
        <bc-avatar-legacy
          :bc-img-alt="process._company.name"
          :bc-img-src="getCompanyLogo(process._company)"
          bc-avatar-size="20px"
          class="processes-card__logo"
          @error="setAlternativeImg"
        >
        </bc-avatar-legacy>
        <p class="processes-card__company">
          {{ companyName }}
        </p>
      </div>
      <div class="is-align-items-center">
        <p class="processes-card__name ">
          {{ jobTitle }}
        </p>
      </div>
    </div>
    <div class="flex flex-col gap-[5px] items-start">
      <router-link
        v-for="coder in isExpanded ? coders : coders.slice(0, 4)"
        :key="coder._id + Math.random() + Date.now()"
        :to="{ name: `${$route.name}Panel`, params: { id: coder._id }, query: { ...$route.query, type: 'coders' } }"
        class="bg-[#D8E0FC] rounded-xl px-2.5 py-0.5 gap-0.5 items-center flex hover:bg-blue-100"
        @click.prevent
      >
        <bc-avatar
          :alt="coder.firstName"
          :src="coder.pictureUrl"
          class="!h-[13px] w-[13px]"
          @error="setAlternativeImgCoder"
        >
        </bc-avatar>
        <p class="text-xs text-blue-500 font-medium truncate max-w-[155px]">
          {{ coder.firstName }} {{ coder.lastName }}
        </p>
      </router-link>
      <input
        v-if="coders.length > 4"
        :value="isExpanded ? $t('timeline.see-less') : $t('timeline.see-more')"
        class="text-xs underline text-blue-500 cursor-pointer hover:no-underline"
        type="button"
        @click.prevent="setIsExpanded"/>
    </div>
    <div class="is-primary font-size-xs is-flex is-align-items-center">
      <p class="processes-card__date">
        {{ daysSinceLastActivityLabel }}
      </p>
      <span class="processes-card__divider"></span>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import BcAvatarLegacy from '@/legacy/ui-kit/components/Avatar/BcAvatar';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import moment from 'moment/min/moment-with-locales';
  import { apiStatus } from '@/macros/processes/process';
  import { bluecodersCompanyId } from '@/macros/utils';
  import ProcessCardSourceLogo from '@/components/Processes/ProcessCardSourceLogo';

  export default {
    name: 'processes-card-custom-job',
    components: { BcAvatarLegacy, BcAvatar, ProcessCardSourceLogo },
    props: {
      process: {
        type: Object,
        default: () => ({}),
      },
      step: {
        type: Object,
        default: () => ({}),
      },
      isDraggable: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isExpanded: false,
      };
    },
    computed: {
      ...mapGetters({
        configurationFramework: 'user/configurationFramework',
      }),
      currentCoach() {
        let finded;
        if (this.$route.query['selected-pipe'] === 'personal-pipe') {
          finded = this.process._customJob._owners.find(owner => owner._id === this.$route.query['processes-selected-coaches']);
        }
        return finded ? finded : this.process._customJob._owners[0];
      },
      ownersRest() {
        return this.process._customJob._owners.filter(owner => owner._id !== this.currentCoach._id);
      },
      coders() {
        return this.process.codersActiveProcesses || [];
      },
      companyName() {
        return this.process._company.name;
      },
      hasAmount() {
        return this.$store.getters['user/configurationProcess'].hasAmount;
      },
      isSuperUser() {
        return this.$store.getters['user/isSuperUser'];
      },
      isOrganizationException() {
        return this.$store.getters['user/isOrganizationException'];
      },
      jobTitle() {
        return this.process._customJob.title;
      },
      amount() {
        if (this.process.amountBilled) {
          return this.process.amountBilled.toLocaleString();
        }
        return this.process.estimatedBilledAmount?.toLocaleString() || 0;
      },
      coachTooltip() {
        const coaches = [this.currentCoach, ...this.ownersRest].map(owner => `<li>${owner.firstName} ${owner.lastName}`).join('</li>');
        return {
          content: `<ul style="list-style-type: none;">${coaches}</ul>`,
          classes: 'tooltip-pre',
        };
      },
      today() {
        return moment().startOf('day');
      },
      date() {
        if (this.process.status === apiStatus.dealt) {
          return this.process.formDeal?.sentAt
            ? moment(this.process.formDeal?.dealInfo?.acceptedAt).add(1, 'day')
            : moment(this.process.dealtAt).add(1, 'day');
        }

        return moment(this.process.updatedAt);
      },
      daysSinceLastActivityNumber() {
        return this.today.diff(moment(this.process.lastCoachActivity), 'days') + 1;
      },
      daysSinceLastActivityLabel() {
        return moment(this.process.lastCoachActivity).fromNow();
      },
      warningOnCard() {
        return (
          !(this.process.formDeal || {}).sentAt &&
          this.process.status === apiStatus.dealt &&
          this.step.name === 'attente de start' &&
          !this.process.failedAt &&
          this.process?._company?._id !== bluecodersCompanyId
        );
      },
    },
    methods: {
      getCompanyLogo(company) {
        if (!(company || {}).logoUrl) {
          return require('@/assets/img/default-avatar-company.svg');
        }
        return company.logoUrl;
      },
      setAlternativeImg(event) {
        event.target.src = require('@/assets/img/default-avatar-company.svg');
      },
      setAlternativeImgCoder(event) {
        event.target.src = require('@/assets/img/default-avatar.svg');
      },
      getCoachInitials() {
        return `${(this.currentCoach || {}).firstName?.charAt(0)}${(
          this.currentCoach || {}
        ).lastName?.charAt(0)}`.trim();
      },
      setIsExpanded() {
        this.isExpanded = !this.isExpanded;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .processes-card {
    border-radius: 10px;
    padding: 15px;
    gap: 8px;
    background: $color-white;
    flex-direction: column;
    position: relative;
    max-width: 220px;
    // height: 123px;

    &__coders {
      gap: 3.5px;

      &--number {
        width: 20px;
        height: 20px;
        background: $color-blue-dark-cello;
        border-radius: 30px;
        color: white;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        align-items: center;
        justify-content: center;
      }
    }

    &__company {
      align-items: center;
      gap: 5px;

      & > p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $color-secondary-light;
        text-overflow: ellipsis;
        max-width: calc(100% - 40px);
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &__job {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      padding: 5px;
      color: $color-primary;

      &--counter {
        background: $color-blue-dark-cello;
        align-items: center;
        justify-self: center;
        width: 20px;
        height: 20px;
        color: white;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        border-radius: 30px;

        &:hover {
          background: #3B80F7;
        }

        & > span {
          width: 100%;
          text-align: center;
        }
      }
    }

    &__name {
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      line-height: 17px;
      color: $color-primary;
      max-width: calc(100% - 20px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-transform: capitalize;
    }

    &__company {
      color: $color-tertiary-2;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
    }

    &__text {
      display: flex;
      font-size: $font-size-s;
      color: $color-blue-dark-cello;
      line-height: 17px;
      align-items: center;

      &--small {
        font-size: $font-size-xs;
        margin-right: 5px;
      }
    }

    &__container {
      margin-top: 5px;
      flex-wrap: wrap;
      gap: 5px;
    }

    &__wrapper {
      align-items: center;
      flex-wrap: wrap;
      margin: 0 0 0 -5px;
    }

    &__coach {
      position: absolute;
      right: 10px;
      top: 12.5px;
    }

    &__logo {
      border-radius: 100%;
      overflow: hidden;
    }

    &__count {
      text-align: center;
      background: rgba($color-purple-medium, 0.2);
      font-size: $font-size-xxs;
      color: $color-blue-dark-cello;
      line-height: 12px;
      height: 20px;
      width: 20px;
      border-radius: 100%;
      overflow: hidden;
      align-items: center;
      justify-content: center;
    }

    &__tooltip {
      white-space: pre;
    }

    &__divider {
      line-height: 1em;
      position: relative;
      outline: 0;
      border: 0;
      width: 100%;
      align-items: center;
      margin-left: 10px;

      &:before {
        content: '';
        background: $color-primary;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
      }
    }

    &__date {
      flex-shrink: 0;
      line-height: 1.5em;
      color: $color-primary;
    }

    &--warning {
      border: 1px solid $color-warning;
    }

    &__status {
      margin-top: -1px;
      height: 10px;
      width: 10px;

      &--caution {
        background: $color-favorite;
      }

      &--warning {
        background: $color-error;
      }

      &--ok {
        background: $color-success;
      }
    }
  }
</style>
