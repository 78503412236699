<script setup>
  import { computed } from 'vue';
  import ViewsTabs from '@/components/Views/ViewsTabs';
  import { useI18n } from '@/i18n/i18n.js';

  const { tc, t } = useI18n();

  const tabs = computed(() => [
    {
      key: 'campaigns',
      label: tc('campaigns.campaigns', 2),
      to: {
        path: '/campaigns',
      },
      count: undefined,
      icon: 'kanban',
    },
    // {
    //   key: 'incoming-actions',
    //   label: t('campaigns.incoming-actions'),
    //   to: { path: '/incoming-actions' },
    //   count: null,
    //   icon: 'file-cv', //TODO Change icon
    // },
  ]);
</script>

<template>
  <views-tabs :tabs="tabs">
    <template #content>
      <slot></slot>
    </template>
  </views-tabs>
</template>
