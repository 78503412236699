<template>
  <div class="panel-organization-view is-column">
    <div class="panel-organization-view__wrapper is-justify-content-space-between is-align-items-center mb-4">
      <p class="panel-organization-view__text is-secondary">
        {{ activeUsers.length }} utilisateurs actifs, {{ inactiveUsers.length }} utilisateurs inactifs
      </p>
      <bc-button
        icon-left="plus"
        @click="goCreateUser">
        Ajouter un utilisateur
      </bc-button>
    </div>
    <card-list
      ref="cardList"
      :cards="activeUsers"
      :is-loaded="true"
      response-obj="coaches">
    </card-list>
    <hr v-if="inactiveUsers.length" class="mb-8 mt-4 w-full border border-solid border-blue-100" />
    <card-list
      ref="cardList"
      :cards="inactiveUsers"
      :is-loaded="true"
      response-obj="coaches">
    </card-list>
    <bc-modal
      :active="isCreatingUser"
      @close="cancelCreate">
      <organizations-modal-create-user
        :profile="profile"
        @close-modal="cancelCreate"
        @profile-updated="confirmCreate">
      </organizations-modal-create-user>
    </bc-modal>
  </div>
</template>

<script>
  import CardList from '@/components/CardList/CardList';
  import { getOrganizationUsers } from '@/api/organizations';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import BcModal from '@/ui-kit/components/BcModal/BcModal';
  import OrganizationsModalCreateUser from '@/components/Organizations/OrganizationsModal/OrganizationsModalCreateUser';

  export default {
    name: 'panel-organization-view',
    components: { OrganizationsModalCreateUser, BcModal, BcButton, CardList },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        users: [],
        isCreatingUser: false,
      };
    },
    computed: {
      activeUsers() {
        return this.users.filter(user => user.enabled);
      },
      inactiveUsers() {
        return this.users.filter(user => !user.enabled);
      },
    },
    methods: {
      async getUsers() {
        const { data } = await getOrganizationUsers(this.$route.params.id);

        this.users = data;
      },
      goCreateUser() {
        this.isCreatingUser = true;
      },
      cancelCreate() {
        this.isCreatingUser = false;
      },
      confirmCreate(user) {
        this.users.unshift(user);

        this.isCreatingUser = false;
      },
    },
    watch: {
      $route: {
        handler() {
          this.getUsers();
        },
        immediate: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-organization-view {
    justify-content: center;
    background-color: white;
    flex-shrink: 0;
    flex-grow: 1;
    @include bp('tablet') {
      justify-content: flex-start;
      height: 110px;
      max-height: 110px;
      padding: 15px;
    }

    &__picture {
      margin-bottom: 20px;
      @include bp('tablet') {
        margin-bottom: 0;
        margin-left: 70px;
        margin-right: 20px;
      }
    }

    &__text {
      line-height: 1.5em;
    }

    &__link {
      &:hover {
        color: $color-primary;
        text-decoration: underline;
      }
    }

    &__tag-wrapper {
      margin: -5px
    }

    &__tag {
      margin: 5px;
    }
  }
</style>
