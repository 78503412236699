<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useStore } from '@/store';
import { useI18n } from '@/i18n/i18n';
import Chart from 'chart.js';
import { getDashboardCandidatesMultiposting } from '@/api/dashboard';

import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import StatisticsItem from './StatisticsItem';

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const kpis = ref({
  nbCandidates: 0,
  nbAcceptedCandidates: 0,
  nbWaitingCandidates: 0,
  nbRejectedCandidates: 0,
  nbRecruitedCandidates: 0,
  candidatesOrigins: {},
});
const isLoading = ref(false);

const activatedChannels = computed(() => [{
  id: 'career',
  title: t('custom-jobs.career-website'),
  connectorName: 'career',
}, ...store.state.xtramile?.activatedChannels]);
const colors = computed(() => store.state.xtramile?.colors);
const activityCoachId = computed(() => route.query.activityCoachId);
const activityStart = computed(() => route.query.activityStart);
const activityEnd = computed(() => route.query.activityEnd);

const steps = computed(() => {
  return [
    {
      name: 'candidatures au total',
      icon: 'presentation',
      value: kpis.value.nbCandidates,
      total: kpis.value.nbCandidates,
      topPerformers: [],
    },
    {
      name: 'candidatures acceptées',
      icon: 'arrow-dot-down',
      value: kpis.value.nbAcceptedCandidates,
      total: kpis.value.nbAcceptedCandidates,
      topPerformers: [],
    },
    {
      name: 'candidatures en attente',
      icon: 'arrow-dot-down',
      value: kpis.value.nbWaitingCandidates,
      total: kpis.value.nbWaitingCandidates,
      topPerformers: [],
    },
    {
      name: 'candidatures refusées',
      icon: 'arrow-dot-down',
      value: kpis.value.nbRejectedCandidates,
      total: kpis.value.nbRejectedCandidates,
      topPerformers: [],
    },
    {
      name: 'candidats recrutés',
      icon: 'partners',
      value: kpis.value.nbRecruitedCandidates,
      total: kpis.value.nbRecruitedCandidates,
      topPerformers: [],
    },
  ];
});

onMounted(async () => {
  await store.dispatch('xtramile/getActivatedChannels');
  await loadData();
  loadChart();
});

const loadChart = () => {
  var ctx = document.getElementById('myChart')?.getContext?.('2d');
  if (!ctx) return;

  const data = Object.keys(kpis.value.candidatesOrigins);

  new Chart(ctx, {
    type: 'doughnut',
    data: {
      labels: data?.length ? data.map(key => key) : [''],
      datasets: [{
        data: data?.length ? data.map(key => kpis.value.candidatesOrigins[key]?.length) : [1],
        backgroundColor: data.map(key => colors.value[key]),
        borderWidth: 1
      }],
    },
    options: {
      legend: {
        display: false,
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  });
}

const loadData = async () => {
  try {
    isLoading.value = true;
    const { data } = await getDashboardCandidatesMultiposting({
      start: activityStart.value,
      end: activityEnd.value,
      coachId: activityCoachId.value,
    });

    kpis.value.nbCandidates = data.nbCandidates;
    kpis.value.nbAcceptedCandidates = data.nbAcceptedCandidates;
    kpis.value.nbWaitingCandidates = data.nbWaitingCandidates;
    kpis.value.nbRejectedCandidates = data.nbRejectedCandidates;
    kpis.value.nbRecruitedCandidates = data.nbRecruitedCandidates;
    kpis.value.candidatesOrigins = data.candidatesOrigins;
    isLoading.value = false;
    loadChart();
  } catch (err) {
    isLoading.value = false;
  }
};

watch(activityCoachId, value => {
  loadData();
});

watch(activityStart, value => {
  loadData();
});

watch(activityEnd, value => {
  loadData();
});

</script>

<template>
  <StatisticsItem
    class="statistics-jobboards"
    :title="$t('generics.multicast')"
    :steps="steps"
    :is-loading="isLoading">
    <div>
      <div class="flex flex-col min-h-[130px]">
        <b class="mb-3 text-blue-800"> Origine des candidatures </b>
        <div v-show="isLoading" class="h-[100px] flex w-full justify-center items-center">
          <BcSpinner></BcSpinner>
        </div>
        <div v-show="!isLoading" class="flex items-center">
          <div style="width: 210px; height: 130px;">
            <canvas id="myChart"></canvas>
          </div>
          <div class="flex flex-wrap w-[50%] gap-x-8 gap-y-2 items-center">
            <div v-for="jobboard in activatedChannels" :key="jobboard.id" class="w-[45%]">
              <div class="flex items-center gap-1">
                <span
                  class="size-[8px] rounded-full"
                  :style="{ background: `${colors[jobboard.connectorName]}!important` }"></span>
                <p class="capitalize">{{ jobboard.title }}</p>
              </div>
              <div class="ml-auto">
                {{
                  kpis.candidatesOrigins[jobboard.connectorName]
                    ? kpis.candidatesOrigins[jobboard.connectorName].length
                    : kpis.candidatesOrigins[jobboard.title]
                    ? kpis.candidatesOrigins[jobboard.title].length
                    : 0
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </StatisticsItem>
</template>
