import pokes from '@/api/pokes';

export default {
  state: {
    newPoke: {
      coderId: '',
      jobId: ''
    }
  },
  actions: {
    async getPokeProfile({ commit }, id) {
      const response = await pokes.getPokeProfile(id);

      commit('setPanelProfile', response.data);

      return response;
    },
    async deletePoke({ commit }, id) {
      const response = await pokes.deletePokeProfile(id);

      commit('removeCard', id);

      return response;
    },
    setNewPoke({ commit }, data) {
      commit('setNewPoke', data);
    }
  },
  mutations: {
    setNewPoke(state, val) {
      state.newPoke.coderId = val.coderId;
      state.newPoke.jobId = val.jobId;
    }
  }
};
