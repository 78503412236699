import { defineStore } from 'pinia';
import { Process } from '@/domains/models/Process';

type State = {
  selectedApplications: Record<string, Partial<Process>>;
}

export const useApplicationsStore = defineStore('applications', {
  state: (): State => {
    return {
      selectedApplications: {},
    };
  },
  getters: {
    selectedApplicationsCount: (state) => Object.keys(state.selectedApplications).length,
  },
  actions: {
    setSelectedApplications(application: Pick<Process, '_id' | '_coder'>): void {
      this.selectedApplications = {
        ...this.selectedApplications,
        [application._id]: {
          _id: application._id,
          _coder: application._coder,
        },
      };
    },
    removeApplicationFromSelectedApplications(applicationId: string): void {
      const obj = { ...this.selectedApplications };
      Reflect.deleteProperty(obj, applicationId);

      this.selectedApplications = obj;
    },
    resetApplications(): void {
      this.selectedApplications = {};
    }
  }
});
