<template>
  <bc-modal
    :active="isModalOpen"
    @close="closeModal"
    class="modal-process-new-offer">
    <div class="modal-process-new-offer__content">
      <div class="modal-process-new-offer__header">
        <p class="modal-process-new-offer__title">
          {{ title }}
        </p>
        <p class="modal-process-new-offer__subtitle">
          <span class="modal-process-new-offer__name">{{ coderFullName }}</span>
          {{ $t('generics.for') }}
          <span class="modal-process-new-offer__name">{{ jobName }}</span>
          {{ $t('timeline.among') }}
          <span class="modal-process-new-offer__name">{{ (process._company || {}).name }}</span>
        </p>
      </div>
      <bc-divider
        :bc-divider-color-opacity="0.2"
        bc-divider-color="blue-dark-cello"
        class="modal-process-new-offer__divider">
      </bc-divider>
      <div class="modal-process-new-offer__body">
        <div class="modal-process-new-offer__wrapper">
          <label
            class="modal-process-new-offer__label"
            for="salary-fixed">
            <span class="modal-process-new-offer__salary-icon icon-arrow-left"></span>
            {{ $t('companies.panel-hunt-process.gross-fixed-salary') }}
          </label>
          <bc-input-number
            :bc-input-border-color-opacity-blurred="0.5"
            bc-input-border-radius="2px"
            class="modal-process-new-offer__input"
            id="salary-fixed"
            max="999"
            min="0"
            v-model.number="fixed">
          </bc-input-number>
          <p class="modal-process-new-offer__unit">
            K €/an
          </p>
        </div>
        <div class="modal-process-new-offer__wrapper">
          <label
            class="modal-process-new-offer__label"
            for="salary-variable">
            + {{ $tc('generics.variable', 2) }}
          </label>
          <bc-input-number
            :bc-input-border-color-opacity-blurred="0.5"
            bc-input-border-radius="2px"
            class="modal-process-new-offer__input"
            id="salary-variable"
            max="999"
            min="0"
            v-model.number="variable">
          </bc-input-number>
          <p class="modal-process-new-offer__unit">
            K €/an
          </p>
        </div>
      </div>
      <bc-divider
        :bc-divider-color-opacity="0.2"
        bc-divider-color="blue-dark-cello"
        class="modal-process-new-offer__divider">
      </bc-divider>
      <div class="modal-process-new-offer__footer">
        <bc-button
          @click="closeModal"
          class="modal-process-new-offer__button"
          size="small"
          type="outlined">
          {{ $t('generics.cancel') }}
        </bc-button>
        <bc-button
          @click.stop="setOffer"
          class="modal-process-new-offer__button"
          color="success"
          size="small">
          {{ $t('generics.validate') }}
        </bc-button>
      </div>
    </div>
  </bc-modal>
</template>

<script>
  import { mapActions } from 'vuex';

  import BcButton from '@/ui-kit/components/BcButton';
  import BcInputNumber from '@/legacy/ui-kit/components/Input/BcInputNumber';
  import BcDivider from '@/legacy/ui-kit/components/Dividers/BcDivider';
  import BcModal from '@/ui-kit/components/BcModal/BcModal';

  import { processController } from '@/managers/processes/controller';
  import processModal from '@/mixins/Processes/processModal';

  export default {
    name: 'modal-process-new-offer',
    components: { BcModal, BcDivider, BcInputNumber, BcButton },
    props: {
      process: {
        type: Object,
        default: () => ({}),
      },
      isModalOpen: {
        type: Boolean,
        default: false
      },
    },
    mixins: [processModal],
    data() {
      return {
        fixed: ((this.process || {}).companyOffer || {}).fixed || 0,
        variable: ((this.process || {}).companyOffer || {}).variable || 0,
      };
    },
    computed: {
      title() {
        return this.fixed === 0 ? this.$i18n.t('companies.panel-hunt-process.edit-an-offer') : this.$i18n.t('companies.panel-hunt-process.new-offer');
      },
      jobName() {
        return this.process._hunt ? this.process._hunt.poste : (this.process._job || {}).name;
      },
    },
    methods: {
      ...mapActions([
        'updatePanelProfile'
      ]),
      async createOffer() {
        try {
          const newStatut = 'offered';

          const updatedProcess = await processController.move(
            this.process._id,
            newStatut,
            {
              fixed: this.fixed,
              variable: this.variable,
            });

          this.updatePanelProfile({ processState: updatedProcess._coder.processState });
          this.$emit('profile-updated', { processState: updatedProcess._coder.processState });

          this.$emit('process-update', {
            updatedProcess: updatedProcess,
            stepName: newStatut,
          });

          this.closeModal();
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
      editOffer() {
        this.$emit('process-update', {
          updatedProcess: {
            ...this.process,
            companyOffer: {
              fixed: this.fixed,
              variable: this.variable,
            },
          },
          stepName: 'offered',
        });

        this.closeModal();
      },
      async setOffer() {
        if (this.fixed === 0) {
          this.editOffer();
        } else {
          this.createOffer();
        }
      },
    },
    watch: {
      process: {
        handler() {
          this.fixed = (this.process.companyOffer || {}).fixed ? (this.process.companyOffer || {}).fixed : this.process.introductionSalary;

          if ((this.process.companyOffer || {}).variable) {
            this.variable = (this.process.companyOffer || {}).variable;
          }
        },
        immediate: true,
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-process-new-offer {
    &__content {
      width: 405px;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      padding: 30px 0;
      min-width: 320px;

    }

    &__header {
      flex-direction: column;
      align-items: center;
      padding: 0 40px;
    }

    &__divider {
      margin: 20px 0;
    }

    &__title {
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      text-transform: uppercase;
      line-height: 17px;
      color: $color-primary;
      margin-bottom: 15px;
    }

    &__label {
      align-items: center;
    }

    &__subtitle {
      width: 300px;
      text-align: center;
      font-size: $font-size-s;
      color: $color-secondary;
    }

    &__name {
      display: initial;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      color: $color-primary;
    }

    &__wrapper {
      align-items: center;
      font-size: $font-size-s;
      line-height: 17px;
      color: $color-secondary;
      justify-content: flex-end;
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__salary-icon {
      margin-right: 10px;
      transform: rotate(180deg);
      color: $color-offer;
      font-size: $font-size-l;
    }

    &__input {
      border: 2px solid;
      max-width: 50px;
      margin: 0 5px 0 10px;
      text-align: center;
    }

    &__body {
      flex-direction: column;
    }

    &__button {
      font-size: $font-size-s;
      margin: 0 5px;
    }
  }
</style>
