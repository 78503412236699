import { listenCoachNotification, markNotificationAsRead, getCoachNotifications } from "../../../firebase";

export default {
  namespaced: true,
  state() {
    return {
      isOpen: false,
      notifications: {},
      lastVisibleCandidatesList: undefined,
      lastVisibleWithoutCandidatesList: undefined,
    };
  },
  getters: {
    notificationsList(state) {
      return Object.keys(state.notifications).map((key) => state.notifications[key]).sort((a, b) => b.createdAt - a.createdAt);
    },
    notificationsListCandidates(state) {
      return Object.keys(state.notifications).map((key) => state.notifications[key]).filter(notif => notif.type === 'apply-from-career').sort((a, b) => b.createdAt - a.createdAt);
    },
    notificationsListWithoutCandidates(state) {
      return Object.keys(state.notifications).map((key) => state.notifications[key]).filter(notif => notif.type !== 'apply-from-career').sort((a, b) => b.createdAt - a.createdAt);
    },
    unreadNotifications(state) {
      return Object.keys(state.notifications).filter(key => !state.notifications[key].read);
    },
    unreadInboxNotifications(state) {
      return Object.keys(state.notifications).filter(key => state.notifications[key]?.type !== 'apply-from-career' && !state.notifications[key].read);
    },
  },
  actions: {
    async getCoachNotifications({ commit, state }, { coachId, filter } = { coachId: '', filter: ['candidates', 'withoutCandidates'] }) {
      const notifsList = {};
      const {
        notificationsCandidatesList,
        notificationsWithoutCandidatesList,
        lastVisibleCandidatesList,
        lastVisibleWithoutCandidatesList,
      } = await getCoachNotifications({
        coachId,
        lastVisibleCandidatesList: state.lastVisibleCandidatesList,
        lastVisibleWithoutCandidatesList: state.lastVisibleWithoutCandidatesList,
        filter,
      });

      if (notificationsCandidatesList?.length) {
        notificationsCandidatesList.forEach(notif => {
          notifsList[notif.id] = notif;
        });
      }

      if (notificationsWithoutCandidatesList?.length) {
        notificationsWithoutCandidatesList.forEach(notif => {
          notifsList[notif.id] = notif;
        });
      }

      commit('initCoachNotifications', notifsList);
      commit('setLastVisibles', { lastVisibleCandidatesList, lastVisibleWithoutCandidatesList });

      return notifsList;
    },
    async listenCoachNotification({ commit }, { coachId }) {
      const listener = listenCoachNotification({ coachId }, ({ id, type, data }) => {
        if (type === 'added') {
          commit('setCoachNotification', { id, ...data });
        }
        if (type === 'modified') {
          commit('setCoachNotification', { id, ...data });
        }
      });
      return listener;
    },
    async markNotificationAsRead({ commit }, { coachId, notificationId }) {
      try {
        await markNotificationAsRead({ coachId, notificationId });
        commit('markNotificationAsRead', notificationId);
      } catch (err) {
        console.warning('markNotificationAsRead err', err);
      }
    },
    toggle({ commit }) {
      commit('toggle');
    },
    resetLastVisibles({ commit }) {
      commit('resetLastVisibles');
    }
  },
  mutations: {
    initCoachNotifications(state, notifications) {
      state.notifications = {
        ...state.notifications,
        ...notifications,
      };
    },
    setCoachNotification(state, notification) {
      state.notifications = {
        ...state.notifications,
        [notification.id]: notification,
      };
    },
    markNotificationAsRead(state, notificationId) {
      state.notifications = {
        ...state.notifications,
        [notificationId]: {
          ...state.notifications[notificationId],
          read: true,
        }
      }
    },
    toggle(state) {
      state.isOpen = !state.isOpen;
    },
    setLastVisibles(state, { lastVisibleCandidatesList, lastVisibleWithoutCandidatesList }) {
      state.lastVisibleCandidatesList = lastVisibleCandidatesList;
      state.lastVisibleWithoutCandidatesList = lastVisibleWithoutCandidatesList;
    },
    resetLastVisibles(state) {
      state.lastVisibleCandidatesList = undefined;
      state.lastVisibleWithoutCandidatesList = undefined;
    },
  }
}
