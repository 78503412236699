<template>
  <div
    v-click-outside="manageClosePanel"
    class="panel-tag-create"
    @click.stop>
    <div
      class="panel-tag-create-header bg-neutral-200 is-full-width is-justify-content-space-between is-align-items-center">
      <panel-header-nav></panel-header-nav>
      <div>{{ $t("tags.create-a-tag") }}</div>
      <bc-button
        radius-size="s"
        size="extra-small"
        color="primary"
        :disabled="suggestions.length > 0 || notUnique || name.length < 3 || tagTypeId == null"
        @click="acceptTagCreate()">
        {{ $t('generics.addition') }}
      </bc-button>
    </div>
    <div class="panel-tag-create__label">
      Tag
    </div>
    <div
      class="panel-tag-create-container">
      <div class="panel-tag-create-container__wrapper">
        <bc-dropdown
          :is-scrollable="false"
          :options="tagTypesOptions"
          label="Type"
          class="custom-jobs-filters__dropdown"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="tagTypesOptions.find(e => e.value === tagTypeId).name"
              label="Type"
            >
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              :title="option.name"
              class="hunt__dropdown-item"
              @click.native="setDropdown(`tagTypeId`, option)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
      </div>
    </div>
    <div class="panel-tag-create-container">
      <div class="panel-tag-create-container__wrapper">
        <bc-dropdown
          :is-scrollable="false"
          :options="organizationsWithTagsOptions"
          label="Organization"
          class="custom-jobs-filters__dropdown"
          is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="organizationsWithTagsOptions.find(e => e.value === organization).name"
              label="Organization"
            >
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              :title="option.name"
              class="hunt__dropdown-item"
              @click.native="setDropdown(`organization`, option)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
      </div>
    </div>
    <div
      class="panel-tag-create-container borderBottom">
      <div class="panel-tag-create-container__wrapper">
        <bc-input
          @input="inputEvent"
          :disabled="tagTypeId === null && organization === null"
          v-model="name"
          name="name"
          label="Nom"
        >
        </bc-input>
      </div>
    </div>
    <div
      class="panel-tag-create-container" v-if="suggestions.length > 0">
      <div>
        {{ $t("tags.create-tag-alert") }}
      </div>
    </div>
    <div
      class="panel-tag-create-container">
      <div class="panel-tag-create__card-content-suggestion" v-if="suggestions.length > 0">
        <div class="panel-tag-create__card-content-suggestion-list">
          <div
            v-for="(itemLink, indexLink) in suggestions"
            :key="indexLink">
            <div>{{ itemLink.name }}</div>
            <div>
              <bc-button
                radius-size="s"
                size="extra-small"
                color="primary"
                iconLeft="check"
                @click="redirectToTag(itemLink._id)">
                {{ $t('generics.edit') }}
              </bc-button>
            </div>
          </div>
          <!--<div class="button-action-right">
            <div>
              {{ $t("generics.addition-anyway") }} :
            </div>
            <bc-button
              radius-size="s"
              size="extra-small"
              color="primary"
              iconLeft="plus"
              @click="addAnyway()">
              {{ $t('generics.addition') }}
            </bc-button>
          </div>-->
        </div>
      </div>
    </div>
    <panel-tag-link
      v-if="suggestions.length == 0 && !notUnique && tagTypeId !== null"
      :tagToLinks="tagToLinks"
      :tagTypeLink="tagTypeLink"
      :tagTypeSlug="tagTypeSlug"
      labelButtonAction="addition"
      @update-tag-to-links="handleSelectTagToLink"
      @update-tag-type-link="setTagTypeLink"
      @create="createTagToLink"
      @clear="clear">
    </panel-tag-link>
    <div
      class="panel-tag-create-link__card"
      v-for="(item, index) in tagTypeLinkOptions"
      :key="index">
      <div class="panel-tag-create-link__card-label" v-if="item.links.length > 0">
        {{ item.name }}
      </div>
      <div class="panel-tag-create-link__card-content" v-if="item.links.length > 0">
        <div class="panel-tag-create-link__card-content-list">
          <div
            v-for="(itemLink, indexLink) in item.links"
            :key="indexLink">
            <div>{{ itemLink.name }}</div>
            <div>
              <bc-button
                radius-size="s"
                size="extra-small"
                type="flat"
                color="grey-5"
                iconCenter="cross"
                @click="removeFromLink(itemLink._id, item.value)">
              </bc-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import debounce from 'debounce';
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';
  import PanelTagLink from '@/components/Panel/PanelTag/PanelTagLink';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav';
  import BcInput from '@/ui-kit/components/BcInput/BcInput.vue';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
  import { createTagAll, createTagLink, searchTags } from '@/api/tags';
  import { mapState } from 'vuex';

  export default {
    name: 'panel-tag-create',
    components: {
      PanelHeaderNav,
      BcButton,
      BcInput,
      PanelTagLink,
      BcDropdown,
      BcDropdownItem,
      BcDropdownTrigger,
    },
    mixins: [PanelNavigation],
    data() {
      return {
        name: "",
        tagToLinks: [],
        suggestions: [],
        notUnique: false,
        tagTypeLink: null,
        tagTypeLinkOptions: [],
        tagTypeId: null,
        organization: null,
      };
    },
    computed: {
      ...mapState({
        tagTypes: state => state.tags.tagTypes,
        tagTypesOptions: state => state.tags.tagTypesOptions,
        organizationsWithTags: state => state.tags.organizationsWithTags,
        organizationsWithTagsOptions: state => state.tags.organizationsWithTagsOptions,
      }),
      tagTypeSlug() {
        return this.tagTypes.find(t => t._id === this.tagTypeId)?.slug;
      },
    },
    methods: {
      async acceptTagCreate() {
        const data = {
          name: this.name,
          _organization: this.organization,
          _tagType: this.tagTypeId,
        };

        // Create tag
        const { data: origin } = await createTagAll(data);

        // Create tag link
        if (origin._id != null && this.tagTypeLinkOptions.length > 0) {
          let promises = [];

          // Generate promises
          for (const item of this.tagTypeLinkOptions) {
            for (const target of item.links) {
              const data = {
                origin: origin._id,
                target: target._id,
                typeLink: item.value,
              };

              promises.push(createTagLink(data));
            }
          }

          // Execute promises to create tag link
          if (promises.length > 0) {
            await Promise.all(promises);
          }
        }

        // Display success message
        this.$toast.show({
          type: 'success',
          message: this.$t('tags.the-tag-has-been-created-successfully'),
        })

        this.closePanel();
      },
      handleSelectTagToLink(skills) {
        this.tagToLinks.push(...skills);

        const uniqueArray = this.tagToLinks.filter((obj, index, self) =>
          index === self.findIndex((t) => t._id.toString() === obj._id.toString())
        );

        this.tagToLinks = uniqueArray;

        if (this.tagTypeLink !== null) {
          this.buttonIsActive = true;
        }
      },
      setTagTypeLink(item) {
        this.tagTypeLink = item.value ? item.value : item;

        if (this.tagToLinks.length > 0) {
          this.buttonIsActive = true;
        }
      },
      setDropdown(field, name) {
        this[field] = name.value;
        this.name = "";
        this.notUnique = false;
        this.suggestions = [];
      },
      removeFromLink(idTag, typeLink) {
        // Remove from tagTypeLinkOptions
        const index = this.tagTypeLinkOptions.findIndex((item) => item.value === typeLink);
        const indexLink = this.tagTypeLinkOptions[index].links.findIndex((item) => item._id === idTag);
        this.tagTypeLinkOptions[index].links.splice(indexLink, 1);
      },
      async createTagToLink(tagToLinks, tagTypeLink) {
        this.tagTypeLinkOptions.push({
          ...tagTypeLink,
          links: tagToLinks
        });

        this.clear();
      },
      clear() {
        this.tagToLinks = [];
        this.tagTypeLink = null;
      },
      inputEvent: debounce(async function() {
        if (this.name.length > 2 && this.tagTypeId !== null) {
          const { data } = await searchTags({
            name: this.name,
            tagTypeId: this.tagTypeId,
            _organization: this.organization,
            limit: 5
          });

          const notUnique = data.length == 0 || data.every(s => s.name.toLowerCase() === this.name.trim().toLowerCase());

          if (notUnique) {
            this.suggestions = data
            this.setNotUnique(false);
          } else {
            this.suggestions = []
            this.setNotUnique(true);
          }

        }
      }),
      addAnyway() {
        this.suggestions = [];
      },
      setNotUnique(notUnique) {
        if (notUnique === true && notUnique != this.notUnique) {
          this.$toast.show({
              type: 'error',
              message: this.$t('tags.create-tag-alert-unique'),
          });
        }

        this.notUnique = notUnique;
      },
      redirectToTag(id) {
        this.openPanel({ id, type: 'tags', size: 'large' })
      }
    },
  };
</script>

<style lang="scss" scoped>
  .panel-tag-create {
    height: 100%;
    flex-direction: column;
    background-color: white;

    &__label {
      margin: 40px 20px 0px 20px;
      font-size: 16px;
    }

    &__card-content {
      width: 100%;
      padding: 20px 10px;
      display: flex;
      flex-direction: column;

      &-list {
        display: flex;
        flex-direction: column;
        padding: 0px 10px !important;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
      }

      &-list > div {
        margin: 5px 0px;
        padding: 10px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid lightgrey;
        border-radius: 5px;
      }
    }

    &__card-content-suggestion {
      width: 100%;
      display: flex;
      flex-direction: column;

      &-list {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
      }

      &-list > div {
        margin: 5px 0px;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid lightgrey;
        border-radius: 5px;
      }

      &-list > div.button-action-right {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        border: none;

        & > * {
          margin: 0px 5px;
        }
      }
    }
  }

  .panel-tag-create-header {
    padding: 10px 15px;
    flex-shrink: 0;
    border-bottom: 1px solid $color-grey-4;
    height: 50px;
  }

  .panel-tag-create-container {
    padding: 20px 40px 20px 40px;

    &__wrapper {
      width: 100%;

      > div {
        width: 100%;
      }
    }
  }

  .panel-tag-create-container.borderBottom {
    margin-bottom: 20px;
    padding-bottom: 40px;
    border-bottom: 1px solid lightgrey;
  }

  .panel-tag-create-link {
    display: flex;
    width: 100%;
    height: 100%;

    &__column {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &__column:first-child {
      border-right: 1px solid lightgrey;
    }

    &__column:last-child {
      border-left: none;
    }

    &__card {
      width: 100%;
      border-bottom: 1px solid lightgrey;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }

    &__card-label {
      width: 100%;
      padding: 20px 20px 0 20px;
      color: $color-secondary-dark;
    }

    &__card-content {
      width: 100%;
      padding: 20px 40px;
      display: flex;
      flex-direction: column;

      &-list {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
      }

      &-list > div {
        margin: 5px 0px;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid lightgrey;
        border-radius: 5px;

        button {
          width: 25px;
          height: 25px;
        }
      }
    }
    .button-action-right {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      & > * {
        margin: 0px 5px;
      }
    }

    &__card-content > div {
      width: 100%;
      padding: 10px 0px;
    }

    &__card-column {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__card-column > div:first-child {
      flex: 0 0 20%;
    }

    &__card-column > div:last-child {
      flex: 1;
    }
  }
</style>
