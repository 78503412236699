<template>
  <div class="app-bar">
    <div class="app-bar__logo-container">
      <router-link to="/">
        <organization-logo
          :is-topbar-open="true"
          :organization-id="profile._organization?._id"
          class="app-bar__logo"
        />
      </router-link>
    </div>
    <separator class="app-bar__separator" />
    <div class="app-bar__content gap-2">
      <h5 class="title">{{ pageTitle }}</h5>
      <h4
        v-if="pageCount !== null"
        class="app-bar__count flex items-center"
      >
        <span class="flex items-center text-sm">{{ pageCount }}</span>
      </h4>
      <div class="ml-auto">
        <topbar-searchbar :is-hover="true"></topbar-searchbar>
      </div>
    </div>
    <separator class="app-bar__separator" />
    <views-topbar-create></views-topbar-create>
    <separator class="app-bar__separator" />
    <app-bar-notifications-count></app-bar-notifications-count>
    <separator class="app-bar__separator" />
    <app-bar-avatar
      :is-hover="true"
      :profile="profile"
    ></app-bar-avatar>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import OrganizationLogo from '@/components/Organizations/OrganizationLogo';
import Separator from '@/components/Separator/Separator';
import TopbarSearchbar from '@/components/Topbar/TopbarSearchbar';
import ViewsTopbarCreate from '@/components/Views/ViewsTopbar/ViewsTopbarCreate';
import AppBarNotificationsCount from './AppBarNotificationsCount';
import AppBarAvatar from './AppBarAvatar';
import routeNames from '../../macros/router';
import { capitalize } from '@/utils/stringTransform';

export default {
  name: 'app-bar',
  components: {
    OrganizationLogo,
    Separator,
    TopbarSearchbar,
    ViewsTopbarCreate,
    AppBarNotificationsCount,
    AppBarAvatar,
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
    }),
    ...mapGetters({
      ressourceCount: 'metadata/ressourceCount',
    }),
    routeTitles() {
      return {
        [routeNames.Onboarding]: this.$i18n.t('activities.home'),
        [routeNames.Dashboard]: this.$i18n.t('topbar.dashboard'),
        [routeNames.Partners]: this.$i18n.tc('generics.company', 2),
        [routeNames.Contacts]: this.$i18n.tc('generics.company', 2),
        [routeNames.ProspectionPipe]: this.$i18n.tc('generics.company', 2),
        [routeNames.AccountManagement]: this.$i18n.tc('generics.company', 2),
        [routeNames.FavoriteLists]: this.$i18n.t('generics.lists'),
        [routeNames.CustomJobs]: this.$i18n.tc('generics.opportunity', 2),
        [routeNames.Coders]: this.$i18n.tc('generics.candidate-cap', 2),
        [routeNames.FavoriteListsCoders]: this.$i18n.tc('generics.candidate-cap', 2),
        [routeNames.CandidatesReminders]: this.$i18n.t('topbar.reminders'),
        [routeNames.ContactsReminders]: this.$i18n.t('topbar.reminders'),
        [routeNames.Statistics]: this.$i18n.t('generics.statistics'),
        [routeNames.Organizations]: 'Admin',
        [routeNames.Industries]: 'Admin',
        [routeNames.Campaigns]: capitalize(this.$i18n.t('generics.my-campaigns')),
      };
    },
    routeCount() {
      return {
        [routeNames.Coders]: this.ressourceCount.candidate,
      };
    },
    pageTitle() {
      return this.routeTitles[this.$route.name] || '';
    },
    pageCount() {
      return this.routeCount[this.$route.name] || null;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-bar {
  width: 100%;
  height: var(--app-bar-height);
  background: $color-blue-light;
  position: fixed;
  align-items: center;
  z-index: 150;

  &__logo {
    &-container {
      width: 195px;
      justify-content: center;
    }
  }

  &__content {
    flex: 3;
    align-items: center;
  }

  &__separator {
    padding: 0 20px;
  }


  &__count {
      background: $color-primary;
      border-radius: 20px;
      align-items: center;
      justify-content: center;
      min-width: 20px;
      min-height: 20px;
      padding: 0 5px;

      & > span {
          color: white;
        }
    }
}
</style>
