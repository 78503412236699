<template>
  <div class="card-informations-pokes-content-contracts">
    <div
      v-for="(contract, index) in contracts"
      :key="index"
      :style="{ backgroundColor: getCoderField('contracts', 'color', contract) }"
      class="card-informations-pokes-content-contracts__tag">
      <p class="card-informations-pokes-content-contracts__text">
        {{ getCoderField('contracts', 'name', contract) }}
      </p>
    </div>
  </div>
</template>

<script>
  import CoderHelpers from '@/common-old/mixins/Coders/coderHelpers';

  export default {
    mixins: [CoderHelpers],
    name: 'card-informations-pokes-content-contracts',
    props: {
      /**
       * The array of contracts
       */
      contracts: {
        type: Array,
        default: () => []
      }
    },
  };
</script>

<style scoped lang="scss">
  .card-informations-pokes-content-contracts {
    flex-wrap: wrap;
    max-width: 150px;
    justify-content: flex-end;
    margin: -5px;
    &__tag {
      border-radius: 4px;
      padding: 5px;
      z-index: 1;
      margin: 5px;
      justify-content: center;
      @include bp('tablet') {
        position: initial;
        z-index: 2;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
      }
    }
    &__text {
      font-size: $font-size-xxs;
      line-height: 14px;
      color: $color-white;
    }
  }
</style>
