<template>
  <div
    ref="container"
    class="flex w-full flex-col">
    <div
      v-show="!isLoading"
      class="timeline-editor is-column is-full-width is-relative border-radius-m gap-2"
    >
      <button
        class="is-absolute icon-cross font-size-s timeline-editor__cross"
        data-button-close
        @click="closeTimelineEditor"
      >
      </button>
      <div class="relative">
        <bc-rich-text-editor-bubble
          :key="`${draft._id}-${randomKey}`"
          ref="textarea"
          v-model="content"
          v-autosize="content"
          :is-editable="true"
          :mention-suggestions="mentionCoaches"
          :placeholder="$t('companies.panel-view-timeline.start-writing')"
          class="timeline-editor__textarea is-full-width font-size-s border-radius-m"
          position="absolute"
          @blur="setIsTyping()"
          @focus="setIsTyping(true)"
          @on-mention-select="onMentionSelect"
          @show-suggestions="showSuggestions = $event"
        >
        </bc-rich-text-editor-bubble>
        <p
          v-if="isNewNote"
          :class="{ 'timeline-editor__save--disabled': !isSaved }"
          class="timeline-editor__save is-flex is-align-items-center border-radius-s absolute bottom-0 right-[20px]"
        >
          {{ $t('companies.panel-view-timeline.note-saved') }}
          <i class="timeline-editor__save-icon icon-check is-flex"></i>
        </p>
      </div>
      <NotesImages
        v-if="images.length > 0"
        :images="images"
        :is-read-only="false"
        class="px-[20px] py-2"
        @remove-image="handleRemoveImage"
      />
      <div
        v-if="isRatingVisible"
        class="flex items-center px-[20px]">
        <BcCheckbox
          id="open-to-work"
          v-model="openToWork">
          <p
            class="text-sm">
            {{ $t('hunt.listening') }}
          </p>
        </BcCheckbox>
      </div>
      <div
        v-if="isRatingVisible"
        class="flex items-center px-[20px]">
        <h2
          class="font-weight-bold font-size-m is-uppercase font-size-s text-blue-800">
          {{ $t('hunt.candidate-evaluation') }}
        </h2>
        <Ratings :rating="rating" @rating-set="setRating"></Ratings>
      </div>
      <NotesEditorFooter
        :can-pin="true"
        :container-size="containerSize"
        :images="images"
        :is-valid="isValid"
        :note-type="noteType"
        :pin="pin"
        :templates="templates"
        :tooltip-content="tooltipContent"
        :user-profile="userProfile"
        class="px-[20px] pb-[20px]"
        @remove-image="handleRemoveImage"
        @send-note="sendNote"
        @add-image="handleAddImage"
        @update:pin="pin = $event"
        @select-template="handleSelectTemplate"
      />
    </div>
    <div v-if="isLoading" class="timeline-editor__spinner">
      <bc-spinner></bc-spinner>
    </div>
    <ModalConfirm
      :cancel-text="$t('generics.no-cancel-this-action')"
      :confirm-text="$t('generics.yes')"
      :content="$t('templates.your-entire-input-will-be-deleted')"
      :is-open="isTemplateModalOpen"
      :title="$t('templates.do-you-want-to-use-this-template')"
      color="success"
      icon="📝"
      @close="handleCloseTemplateModal"
      @on-confirm="handleConfirmTemplate">
    </ModalConfirm>
  </div>

</template>

<script>
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import BcRichTextEditorBubble from '@/ui-kit/components/BcRichTextEditor/BcRichTextEditorBubble';
  import TimelineEditorMixin from '@/mixins/Timeline/timelineEditor';
  import { noteController } from '@/managers/notes/controller';
  import NotesEditorMixin from '@/mixins/Notes/notes';
  import { mapGetters, mapState } from 'vuex';
  import locale from '@/mixins/i18n/locale';
  import { getNotesTemplates } from '@/api/notesTemplates';
  import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
  import { useElementSize } from '@vueuse/core';
  import Ratings from '@/components/Toolbox/Ratings.vue';
  import NotesEditorFooter from '@/components/Notes/NotesEditor/NotesEditorFooter.vue';
  import NotesImages from '@/components/Notes/NotesImages.vue';
  import notesMacros from '@/common-old/macros/notes';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox.vue';

  const routes = {
    companies: 'partners',
    coders: 'coders',
    contacts: 'contacts',
    hunt: 'custom-jobs',
    jobs: 'jobs',
    pokes: 'pokes',
    process: 'processes',
    'suggestions-coders': 'coders',
  };

  export default {
    name: 'timeline-editor',
    components: {
      BcCheckbox,
      NotesImages,
      NotesEditorFooter,
      Ratings,
      ModalConfirm,
      BcRichTextEditorBubble,
      BcSpinner,
    },
    mixins: [TimelineEditorMixin, NotesEditorMixin, locale],
    props: {
      isNewNote: {
        type: Boolean,
        default: false,
      },
      noteType: {
        type: String,
      },
      noteTypeTracking: {
        type: Boolean,
      },
      profile: {
        type: Object,
        default: () => ({}),
      },
      // reminderData: {
      //   type: Object,
      // },
      // reminderType: {
      //   type: String,
      // },
      timelineId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        draft: {
          content: '',
          rating: undefined,
          type: '',
        },
        selectedMentions: [],
        showSuggestions: false,
        pin: false,
        openToWork: false,
        isLoading: false,
        images: [], // base64
        templates: [],
        randomKey: Math.random(),
        isTemplateModalOpen: false,
        awaitingSelectedTemplate: '',
      };
    },
    created() {
      this.getNotesTemplates();
    },
    mounted() {
      const tomorrow = new Date();

      tomorrow.setDate(tomorrow.getDate() + 1);

      this.minDate = tomorrow;
    },
    computed: {
      ...mapState({
        tags: state => [].concat(...Object.values(state.tags)),
        userProfile: state => state.user.profile,
      }),
      ...mapGetters({
        mentionCoaches: 'mentionCoaches',
      }),
      containerSize() {
        return useElementSize(this.$refs['container']);
      },
      isCandidate() {
        return routes[this.$route.query.type] === 'coders';
      },
      isRatingVisible() {
        return this.noteType === notesMacros.type.call.name && this.isCandidate;
      },
    },
    methods: {
      handleRemoveImage(index) {
        this.images.splice(index, 1);
      },
      handleAddImage(image) {
        this.images.push(image);
      },
      handleSelectTemplate(template) {
        if (this.content) {
          this.awaitingSelectedTemplate = template.body;
          this.isTemplateModalOpen = true;
        } else {
          this.content = template.body;
          ++this.randomKey;
        }
      },
      handleConfirmTemplate() {
        this.content = this.awaitingSelectedTemplate;
        ++this.randomKey;
        this.handleCloseTemplateModal();
      },
      handleCloseTemplateModal() {
        this.awaitingSelectedTemplate = '';
        this.isTemplateModalOpen = false;
      },
      async getNotesTemplates() {
        try {
          const { data } = await getNotesTemplates({
            coachId: null,
            all: true,
          });

          this.templates = data.map(template => ({
            ...template,
            author: template._coach,
          }));
        } catch (e) {
          console.error(e);
        }
      },
      closeTimelineEditor() {
        this.$emit('close');
      },
      onMentionSelect(mention) {
        this.selectedMentions = [...this.selectedMentions, mention];
      },
      syncWithDomMentions() {
        let domMentions = [];
        if (this.$refs['textarea']?.$el) {
          const mentionsNodes = Array.from(this.$refs['textarea'].$el.querySelectorAll('.mention'));
          domMentions = mentionsNodes.map(node => node.dataset.mentionId);
          const selectedCoaches = this.mentionCoaches.filter(coach => domMentions.includes(coach.id));
          this.selectedMentions = selectedCoaches;
        }
      },
      async sendNote() {
        this.syncWithDomMentions();

        let note = {
          type: this.noteType,
          content: this.content || '-',
          ...({ rating: this.rating }),
          pin: this.pin,
          openToWork: this.openToWork,
        };

        try {
          const params = {
            userType: routes[this.$route.query.type],
            id: this.draft._id,
            note,
            isNewNote: true,
            mentions: this.selectedMentions.map(mention => mention.id),
            images: this.images,
          };
          if (!params.id) {
            await this.$_createDraft();
            params.id = this.draft._id;
          }

          const response = await noteController.createNote(params);

          if (this.selectedMentions.length) {
            this.$toast.show({
              type: 'success',
              message: this.$t('notifications.send-with-mentions', {
                mentions: this.selectedMentions.map(mention => mention.name).join(', '),
              }),
            });
          }

          this.$emit('note-saved', response);

          this.closeTimelineEditor();
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: error.message || error.response.data,
          });
        }
      },
    },
    watch: {
      showSuggestions(show) {
        this.disableDraftNote = show;
      },
      'draft._id'() {
        this.$refs['timeline-editor-submit']?.$el?.click();
      },
    },
  };
</script>

<style lang=scss scoped>
  .timeline-editor {
    background: $color-white;

    &__cross {
      z-index: 2;
      right: 20px;
      top: 15px;
      color: $color-blue-heavy-dark;

      &:hover {
        cursor: pointer
      }
    }

    &__spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__textarea {
      padding: 20px;
      resize: none;
      min-height: 220px;
      color: $color-secondary-dark;
      font-family: $font-family;
    }

    &__container {
      padding: 15px 20px;
      border-top: 1px solid $color-blue-medium;
    }

    &__wrapper {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
      width: 100%;

      .new--note {
        margin-bottom: 16px;
        align-items: center;
        width: 100%;

        p {
          margin-left: 1.15rem;
          margin-right: 8px;
        }

        .bc-dropdown {
          width: 16%;

          .bc-dropdown__input {
            button {
              border-color: #3c80f74d;
              color: #3c80f7;
            }
          }
        }
      }

      .skills {
        margin-bottom: 16px;
        flex-direction: column;
        width: 100%;

        .bc-autocomplete {
          width: 25%;
          margin-bottom: 8px;
        }

        section {
          display: flex;
          flex-direction: row;
          padding: 0;
          max-width: 100%;
          flex-wrap: wrap;

          .bc-tag {
            margin-right: 8px;
            width: max-content;
            flex: 0 0 auto;
            margin-bottom: 8px;
          }
        }
      }

      .salary {
        margin-bottom: 16px;
        width: 100%;

        p {
          margin-right: 8px;
        }

        div {
          margin-right: 16px;
        }

        input {
          padding: 8px;
        }
      }

      .additional {
        align-items: center;
        margin-bottom: 16px;
        width: 100%;

        .bc-checkbox {
          margin: 0;
        }

        p {
          margin: 0 16px;
        }

        .bc-dropdown {
          width: 16%;

          .bc-dropdown__input {
            button {
              border-color: #3c80f74d;
              color: #3c80f7;
            }
          }
        }
      }

      .rating {
        align-items: center;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__title {
      color: $color-secondary;
      margin: 0;
    }

    &__save {
      color: $color-success;
      padding: 5px 10px;
      background-color: rgba($color-success, 0.08);
      transition: 0.2s ease-out;

      &-icon {
        margin-left: 5px;
      }

      &--disabled {
        opacity: 0;
      }
    }

    &__types {
      margin-top: 15px;
      background: rgba($color-primary, 0.1);
    }

    &__type {
      padding: 5px;
      color: $color-primary;
      border: 1px solid transparent;
      @include bp('tablet') {
        padding: 10px 20px;
      }

      &:hover {
        border: 1px solid $color-primary;
      }

      &--active {
        border: 1px solid $color-primary;
        background: $color-white;
      }
    }

    &__checkboxes {
      margin: 0.5em -1.5em;
    }

    &__checkbox {
      margin: 0.5em 1.5em;
    }

    &__label {
      cursor: pointer;

      &:before {
        margin-right: 5px;
        font-size: 18px;
      }
    }
  }
</style>
