<template>
  <div class="bc-custom-field-number-with-unit">
    <bc-input
      v-model="selectedValue"
      type="number"
      :name="customField.key"
    />
  </div>
</template>

<script>
import BcInput from '@/ui-kit/components/BcInput/BcInput';

export default {
  name: 'bc-custom-field-number-with-unit',
  components: {
    BcInput,
  },
  props: {
    customField: {
      type: Object,
      required: true,
    },
    selected: {
      type: [Number, String, Array],
    },
    isFilter: {
      type: Boolean,
    },
  },
  mounted() {
    this.handleSelectValue(this.selected);
  },
  data() {
    return {
      selectedValue: '0',
    };
  },
  methods: {
    handleSelectValue(selected) {
      if (selected?.toString?.()?.length) {
        this.selectedValue = selected.toString();
      } else if (!this.isFilter && this.customField.defaultValue) {
        this.selectedValue = this.customField.defaultValue.toString();
      } else {
        this.selectedValue = '0';
      }
    }
  },
  watch: {
    selected(value) {
      this.handleSelectValue(value);
    },
    selectedValue(value) {
      this.$emit('on-update', { key: this.customField.key, value: Number(value) });
    },
  },
};
</script>

<style lang="scss" scoped>
.bc-custom-field-number-with-unit {
  width: 100%;

  :deep() {
    .bc-input {
      margin-top: 0;
      width: 100%;
    }
  }
}
</style>
