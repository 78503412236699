<template>
  <label
    :class="{
      'bc-radio--disabled': disabled,
      'bc-radio--checked': checked,
      'bc-radio--checked-and-disabled': disabled && checked,
      'is-row-reverse': reversed,
    }"
    :for="computedId"
    class="bc-radio is-relative is-flex is-align-items-center is-justify-content-center font-size-m">
    <input
      :checked="checked"
      :disabled="disabled"
      :id="computedId"
      :name="name"
      :value="value"
      @blur="$emit('blur')"
      @click="$emit('input', $event.target.value)"
      class="bc-radio__input"
      type="radio" />
    <span :class="{ 'bc-radio__check--error': error }" class="bc-radio__check"></span>
    <span
      v-if="$slots.default"
      :class="{
        'bc-radio__content--reversed': reversed,
        'bc-radio__content--error': error,
      }"
      class="bc-radio__content is-flex is-align-items-center">
      <slot></slot>
    </span>
  </label>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    id: string;
    name: string;
    value?: string | number | boolean;
    checked?: boolean;
    disabled?: boolean;
    reversed?: boolean;
    error?: string;
  }>(),
  {
    checked: false,
    disabled: false,
    reversed: false,
    error: '',
  },
);
defineEmits<{
  (e: 'input', value: string | number | boolean): void;
  (e: 'blur'): void;
}>();

const computedId = computed(() => `${+new Date()}-${props.id}`);
</script>

<style lang="scss" scoped>
.bc-radio {
  $this: &;

  color: $color-blue-heavy-dark;
  cursor: pointer;

  &:hover {
    color: $color-primary;
  }

  &__input {
    position: absolute;
    left: 0;
    opacity: 0;
    outline: none;
    z-index: -1;
    color: $color-blue-heavy-dark;

    &:checked + .bc-radio__check {
      border: 1px solid $color-primary;
      background-color: $color-primary;
      opacity: 1;
      box-shadow: inset 0 0 0 2px $color-white;
    }

    &:disabled {
      + .bc-radio__check {
        border: 1px solid $color-grey-4;
        background-color: $color-grey-2;
        cursor: default;
        opacity: 0.5;
      }

      &:checked + .bc-radio__check {
        border: 1px solid $color-primary;
        background-color: $color-primary;
      }
    }

    &:hover {
      + .bc-radio__check {
        border: 1px solid $color-primary;
        background-color: $color-grey;
      }

      &:checked + .bc-radio__check {
        border: 1px solid $color-primary;
        background-color: $color-primary;
        opacity: 1;
        box-shadow: inset 0 0 0 2px $color-white;
      }

      &:disabled {
        + .bc-radio__check {
          border: 1px solid $color-grey-4;
          background-color: $color-grey-2;
          cursor: default;
          opacity: 0.5;
        }

        &:checked + .bc-radio__check {
          border: 1px solid $color-primary;
          background-color: $color-primary;
          opacity: 0.5;
        }
      }
    }
  }

  &__check {
    height: 18px;
    width: 18px;
    border-radius: $border-radius-rounded;
    border: 1px solid $color-blue-heavy-dark;
    cursor: pointer;
    background-color: white;
    margin: 0;
    flex-shrink: 0;

    &--error {
      border: 1px solid $color-error;
    }
  }

  &__content {
    margin-left: 10px;

    &--reversed {
      margin-left: 0;
      margin-right: 10px;
    }

    &--error {
      color: $color-error;
    }
  }

  &--checked {
    color: $color-primary;

    &:hover {
      color: $color-primary-dark;
    }
  }

  &--disabled {
    color: $color-grey-4;
    opacity: 0.5;
    cursor: default;

    &:hover {
      color: $color-grey-4;
      opacity: 0.5;
    }
  }

  &--checked-and-disabled {
    color: $color-primary;

    &:hover {
      color: $color-primary;
      opacity: 0.5;
    }
  }
}
</style>
