<template>
  <td @click="openPanelJob" :class="showModal ? 'not--active--cell' : 'active--cell'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 32 32" fill="#1D395E">
      <title>external-link</title>
      <path d="M4.813 32c-2.688 0-4.813-2.125-4.813-4.813v-17.563c0-2.688 2.125-4.813 4.813-4.813h9.563c0.938 0 1.625 0.625 1.625 1.563s-0.688 1.625-1.625 1.625h-9.563c-0.938 0-1.625 0.688-1.625 1.625v17.563c0 0.938 0.688 1.625 1.625 1.625h17.563c0.938 0 1.625-0.688 1.625-1.625v-9.563c0-0.938 0.688-1.625 1.625-1.625s1.563 0.688 1.563 1.625v9.563c0 2.688-2.125 4.813-4.813 4.813zM11.75 20.25c-0.688-0.625-0.688-1.563 0-2.25l14.813-14.813h-5.75c-0.938 0-1.625-0.625-1.625-1.563s0.688-1.625 1.625-1.625h9.563c0.188 0 0.563 0 0.688 0.125 0.688 0.438 0.938 0.813 0.938 1.5v9.563c0 0.938-0.688 1.625-1.625 1.625s-1.563-0.688-1.563-1.625v-5.75l-14.938 14.813c-0.563 0.688-1.625 0.688-2.125 0z"></path>
    </svg>
  </td>
</template>

<script>
export default {
  name: 'linkcell',
  props: {
    job: {
      type: Object
    },
    showModal: {
      type: Boolean
    }
  },
  methods: {
    openPanelJob() {
      this.$router.push({
        name: 'HuntJobsPanel',
        query: {
          page: 1,
          type: 'hunt',
          category: 'my-notes',
          coachId: this.$route.query.coachId,
          "company-id": this.job.companyId._id
        },
        params: {
          id: this.job._id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .not--active--cell {
    display: none;
  }
  .active--cell {
    display: revert;
  }
  td {
    text-align: center;
    svg {
      margin-top: 4px;
    }
  }
</style>
