export default {
    computed: {
        /**
         * The array map which will indicate the color of the tag
         */
        tagsStyleMap() {
            return {
                match: {
                    backgroundColor: 'green-lima',
                    backgroundColorOpacity: 1,
                    icon: 'matching',
                    iconColor: 'white',
                    crossColor: 'white',
                    textColor: 'white',
                    textColorOpacity: 1,
                },
                search: {
                    backgroundColor: 'gray-athens',
                    backgroundColorOpacity: 1,
                    icon: 'search',
                    iconColor: 'blue-dark-cello',
                    crossColor: 'blue-dark-cello',
                    textColor: 'blue-dark-cello',
                    textColorOpacity: 0.8,
                },
                technos: {
                    backgroundColor: '#EDF0FF',
                    backgroundColorOpacity: 1,
                    icon: 'tool',
                    iconColor: '#375276',
                    crossColor: '#375276',
                    textColor: '#375276',
                    textColorOpacity: 1,
                },
                status: {
                    backgroundColor: 'blue-hawkes',
                    backgroundColorOpacity: 1,
                    iconColor: 'blue-dark-cello',
                    crossColor: 'blue-dark-cello',
                    textColor: 'blue-dark-cello',
                    textColorOpacity: 0.8,
                },
                location: {
                    backgroundColor: '#D8E0FC',
                    icon: 'map-pin',
                    backgroundColorOpacity: 1,
                    iconColor: 'primary',
                    crossColor: 'primary',
                    textColor: 'primary',
                    textColorOpacity: 1,
                },
                salary: {
                    backgroundColor: '#EDF0FF',
                    icon: 'salary',
                    backgroundColorOpacity: 1,
                    iconColor: '#375276',
                    crossColor: '#375276',
                    textColor: '#375276',
                    textColorOpacity: 1,
                },
                benefits: {
                    backgroundColor: '#EDF0FF',
                    icon: 'plus',
                    backgroundColorOpacity: 1,
                    iconColor: '#375276',
                    crossColor: '#375276',
                    textColor: '#375276',
                    textColorOpacity: 1,
                },
                salaryType: {
                    backgroundColor: '#EDF0FF',
                    icon: 'dollars',
                    backgroundColorOpacity: 1,
                    iconColor: '#375276',
                    crossColor: '#375276',
                    textColor: '#375276',
                    textColorOpacity: 1,
                },
                experience: {
                    backgroundColor: '#EDF0FF',
                    icon: 'diploma',
                    backgroundColorOpacity: 1,
                    iconColor: '#375276',
                    crossColor: '#375276',
                    textColor: '#375276',
                    textColorOpacity: 1,
                },
                role: {
                    backgroundColor: 'blue-hawkes',
                    icon: 'suitcase',
                    backgroundColorOpacity: 1,
                    iconColor: 'blue-dark-cello',
                    crossColor: 'blue-dark-cello',
                    textColor: 'blue-dark-cello',
                    textColorOpacity: 0.8,
                },
                partnership: {
                    backgroundColor: 'blue-hawkes',
                    icon: 'file-text',
                    backgroundColorOpacity: 1,
                    iconColor: 'blue-dark-cello',
                    crossColor: 'blue-dark-cello',
                    textColor: 'blue-dark-cello',
                    textColorOpacity: 0.8,
                },
                domains: {
                    backgroundColor: 'blue-hawkes',
                    icon: 'tag',
                    backgroundColorOpacity: 1,
                    iconColor: 'blue-dark-cello',
                    crossColor: 'blue-dark-cello',
                    textColor: 'blue-dark-cello',
                    textColorOpacity: 0.8,
                },
                contracts: {
                    backgroundColor: '#EDF0FF',
                    icon: 'file',
                    backgroundColorOpacity: 1,
                    iconColor: '#375276',
                    crossColor: '#375276',
                    textColor: '#375276',
                    textColorOpacity: 1,
                },
                lastCoachActivity: {
                    backgroundColor: '#EDF0FF',
                    icon: '',
                    backgroundColorOpacity: 1,
                    iconColor: 'blue-dark-cello',
                    crossColor: 'blue-dark-cello',
                    textColor: 'blue-dark-cello',
                    textColorOpacity: 0.8,
                },
                'remote': {
                    backgroundColor: '#EDF0FF',
                    icon: 'home',
                    backgroundColorOpacity: 1,
                    iconColor: '#375276',
                    crossColor: '#375276',
                    textColor: '#375276',
                    textColorOpacity: 1,
                },
                'remote-partial': {
                    backgroundColor: '#EDF0FF',
                    icon: 'remote-partial',
                    backgroundColorOpacity: 1,
                    iconColor: '#375276',
                    crossColor: '#375276',
                    textColor: '#375276',
                    textColorOpacity: 1,
                },
                'remote-available': {
                    backgroundColor: '#EDF0FF',
                    icon: 'remote-available',
                    backgroundColorOpacity: 1,
                    iconColor: '#375276',
                    crossColor: '#375276',
                    textColor: '#375276',
                    textColorOpacity: 1,
                },
                'remote-full': {
                    backgroundColor: '#EDF0FF',
                    icon: 'remote-full',
                    backgroundColorOpacity: 1,
                    iconColor: '#375276',
                    crossColor: '#375276',
                    textColor: '#375276',
                    textColorOpacity: 1,
                },
                phone: {
                    backgroundColor: 'blue-hawkes',
                    icon: 'phone',
                    backgroundColorOpacity: 1,
                    iconColor: 'blue-dark-cello',
                    crossColor: 'blue-dark-cello',
                    textColor: 'blue-dark-cello',
                    textColorOpacity: 0.8,
                },
                cv: {
                    backgroundColor: 'blue-hawkes',
                    icon: 'diploma-2',
                    backgroundColorOpacity: 1,
                    iconColor: 'blue-dark-cello',
                    crossColor: 'blue-dark-cello',
                    textColor: 'blue-dark-cello',
                    textColorOpacity: 0.8,
                },
                email: {
                    backgroundColor: 'blue-hawkes',
                    icon: 'mail',
                    backgroundColorOpacity: 1,
                    iconColor: 'blue-dark-cello',
                    crossColor: 'blue-dark-cello',
                    textColor: 'blue-dark-cello',
                    textColorOpacity: 0.8,
                },
                linkedin: {
                    backgroundColor: 'blue-hawkes',
                    icon: 'linkedin',
                    backgroundColorOpacity: 1,
                    iconColor: 'blue-dark-cello',
                    crossColor: 'blue-dark-cello',
                    textColor: 'blue-dark-cello',
                    textColorOpacity: 0.8,
                },
                rating: {
                    backgroundColor: 'blue-hawkes',
                    icon: 'star',
                    backgroundColorOpacity: 1,
                    iconColor: 'blue-dark-cello',
                    crossColor: 'blue-dark-cello',
                    textColor: 'blue-dark-cello',
                    textColorOpacity: 0.8,
                },
                professions: {
                    backgroundColor: '#DAD9FC',
                    icon: 'award',
                    backgroundColorOpacity: 1,
                    iconColor: 'secondary',
                    crossColor: 'secondary',
                    textColor: 'secondary',
                    textColorOpacity: 1,
                },
                'danger': {
                    backgroundColor: '#E6525A',
                    icon: 'cross',
                    backgroundColorOpacity: 0.2,
                    iconColor: '#E6525A',
                    crossColor: '#E6525A',
                    textColor: '#E6525A',
                    textColorOpacity: 1,
                },
                'success': {
                    backgroundColor: '#3DCF8E',
                    icon: 'check',
                    backgroundColorOpacity: 0.2,
                    iconColor: '#3DCF8E',
                    crossColor: '#3DCF8E',
                    textColor: '#3DCF8E',
                    textColorOpacity: 1,
                },
                'priority-high': {
                    backgroundColor: '#E6525A',
                    icon: 'emergency',
                    backgroundColorOpacity: 0.2,
                    iconColor: '#E6525A',
                    crossColor: '#E6525A',
                    textColor: '#E6525A',
                    textColorOpacity: 1,
                },
                'priority-middle': {
                    backgroundColor: '#F78F00',
                    icon: 'emergency',
                    backgroundColorOpacity: 0.2,
                    iconColor: '#F78F00',
                    crossColor: '#F78F00',
                    textColor: '#F78F00',
                    textColorOpacity: 1,
                },
                'priority-low': {
                    backgroundColor: '#FFC100',
                    icon: 'emergency',
                    backgroundColorOpacity: 0.2,
                    iconColor: '#FFC100',
                    crossColor: '#FFC100',
                    textColor: '#FFC100',
                    textColorOpacity: 1,
                },
                customField: {
                    backgroundColor: '#EDF0FF',
                    backgroundColorOpacity: 1,
                    iconColor: '#375276',
                    crossColor: '#375276',
                    textColor: '#375276',
                    textColorOpacity: 1,
                },
                size: {
                    backgroundColor: '#EDF0FF',
                    backgroundColorOpacity: 1,
                    icon: 'users',
                    iconColor: '#375276',
                    crossColor: '#375276',
                    textColor: '#375276',
                    textColorOpacity: 1,
                },
                company: {
                    backgroundColor: '#D8E0FC',
                    backgroundColorOpacity: 1,
                    icon: '',
                    iconColor: '#3865FD',
                    crossColor: '#3865FD',
                    textColor: '#3865FD',
                    textColorOpacity: 1,
                }
            };
        },
        emergencyMap() {
            return {
                'Basse': 'priority-low',
                'Moyenne': 'priority-middle',
                'Forte': 'priority-high',
            };
        },
    },
};
