<template>
  <div class="process-timeline-item-onboarding">
    <div class="is-column is-full-width">
      <p class="process-timeline-item-onboarding__start">
        {{ $t('candidates.the-candidate-to-start-on') }} {{ startDate }}
      </p>
      <div
        :class="{ 'process-timeline-item-onboarding__container--expanded': isExpanded }"
        class="process-timeline-item-onboarding__container is-full-width">
        <bc-rich-text-editor-bubble
          :is-editable="false"
          class="process-timeline-item-onboarding__editor is-full-width font-size-s border-radius-m"
          placeholder="Commencer à écrire"
          ref="textarea"
          v-autosize="formattedContent"
          v-model="formattedContent"
          v-if="formattedContent">
        </bc-rich-text-editor-bubble>
      </div>
      <a
        @click="isExpanded = true"
        class="process-timeline-item-onboarding__see-more is-underline font-size-s"
        v-show="canBeExpanded && !isExpanded">
        {{ $t('companies.panel-view-timeline.see-more') }}
      </a>
    </div>
  </div>
</template>

<script>
  import moment from 'moment/min/moment-with-locales';
  import timelineItemContent from '@/mixins/Timeline/timelineItemContent';
  import BcRichTextEditorBubble
    from '@/ui-kit/components/BcRichTextEditor/BcRichTextEditorBubble';

  export default {
    name: 'process-timeline-item-onboarding',
    components: { BcRichTextEditorBubble },
    props: {
      action: {
        type: Object,
        default: () => ({}),
      },
    },
    mixins: [timelineItemContent],
    data() {
      return {
        content: (this.action._process.onboarding || {}).content || '',
      };
    },
    computed: {
      startDate() {
        return moment(this.action._process.onboarding.startsAt).format('L');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .process-timeline-item-onboarding {
    padding: 10px 15px;
    border-top: 1px solid $color-gray-geyser;

    &__container {
      max-height: calc(3em + 2.5em);
      margin-bottom: 15px;

      &--expanded {
        max-height: initial;
      }
    }

    &__start {
      font-size: $font-size-s;
      color: $color-secondary;
      font-style: italic;
      font-weight: $font-weight-bold;
      margin-bottom: 8px;
    }

    &__editor {
      resize: none;
      min-height: 3em;
      color: $color-secondary-light;
      font-family: $font-family;

      & :deep() {
        .bc-rich-text-editor-bubble__textarea {
          overflow: hidden;
        }
      }
    }

    &__see-more {
      margin-top: -15px;
      color: $color-primary;
      margin-right: auto;
    }
  }
</style>
