<script setup lang="ts">
import { onMounted, ref, computed, defineEmits, watch } from 'vue';
import { useStore } from '@/store';
import { useI18n } from '@/i18n/i18n';
import { useRoute, useRouter } from 'vue-router/composables';
import debounce from 'debounce';
import moment from 'moment/min/moment-with-locales';

import BcButton from '@/ui-kit/components/BcButton';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import BcAutocomplete from '@/ui-kit/components/BcAutocomplete';
import MvSelect from '@/ui-kit/components/MvSelect/MvSelect';
import ApplicationsFilterLabel from './ApplicationsFilterLabel.vue';
import ApplicationsFilterButton from './ApplicationsFilterButton';
import JobboardLogo from '@/components/Jobboard/JobboardLogo';
import Tag from '@/components/Tag/Tag';
import partnersApi from '@/api/company';
import { getCustomJobs } from '@/api/custom-jobs';
import { getFilterablePoolCoach } from '@/macros/prospectionPipe/dropdowns';

const DEFAULT_COACH_AVATAR = require('@/assets/img/default-avatar.svg');
const DEFAULT_COMPANY_AVATAR = require('@/assets/img/default-avatar-company.svg');

type DateFilter = 'today' | 'week' | 'month' | 'custom' | undefined;

const emit = defineEmits<{
  (e: 'on-submit', value?: any): void;
}>();

const store = useStore();
const route = useRoute();
const router = useRouter();
const coaches = computed(() => store.state.coaches?.coaches);
const { t } = useI18n();

const dateFilter = ref<DateFilter>(route.query.dateFilter ? route.query.dateFilter as DateFilter : undefined);
const searchCompany = ref('');
const searchOpportunity = ref('');
const companiesFilter = ref<any[]>([]);
const companiesSuggestions = ref([]);
const opportunitiesFilter = ref<any[]>([]);
const opportunitiesSuggestions = ref([]);
const expandJobboards = ref(false);
const coachesFilter = ref<any[]>([]);
const jobboardFilter = ref<string[]>([]);
const customDateRange = ref();

const careerLogoUrl = computed(() => store?.state?.user?.profile?._organization?.configuration?.career?.logoUrl);
const isTreated = computed(() => route.query.filter === 'treated');
const routeQuery = computed(() => route.query);

function coachFullName(coach) {
  let fullName = coach.name || `${coach.firstName} ${coach.lastName}`;

  return coach.enabled
    ? fullName
    : `${coach.lastName === 'Désactivé' ? coach.email : fullName} - ${t('generics.disabled')}`;
}

const coachesFilterIds = computed(() => coachesFilter.value.map(coach => coach._id));

const coachOptions = computed<{ name: string; query: string; enabled?: boolean }[]>(() => {
  const filteredCoaches = coaches.value
    .filter(coach => getFilterablePoolCoach(coach, false))
    .map(coach => ({
      _id: coach._id,
      label: coachFullName(coach),
      value: coach._id,
      enabled: false,
      avatar: coach.pictureUrl || DEFAULT_COACH_AVATAR,
      selected: coachesFilterIds.value.includes(coach._id),
    }));

  return [
    ...filteredCoaches.sort((a, b) => a.label.localeCompare(b.label) && b.enabled - a.enabled),
  ];
});

const locale = localStorage.locale;
const activatedChannels = computed(() => store.state.xtramile?.activatedChannels);
const limitedActivatedChannels = computed(() => {
  if (expandJobboards.value) {
    return activatedChannels.value;
  }
  return activatedChannels.value.slice(0, 3)
});

const onSelectDateFilter = (isSelected, filter) => {
  if (isSelected) {
    dateFilter.value = filter;
  } else {
    dateFilter.value = undefined;
  }
}

const onSelectJobboard = (isSelected, jobboard) => {
  let formatedJobboard = jobboard;
  if (formatedJobboard === 'talent') {
    formatedJobboard = 'talent.com';
  }
  if (isSelected) {
    jobboardFilter.value = [...jobboardFilter.value, formatedJobboard];
  } else {
    jobboardFilter.value = jobboardFilter.value.filter(source => source !== formatedJobboard);
  }
}

const toggleExpandJobboards = () => {
  expandJobboards.value = !expandJobboards.value;
}

const onSearchCompany = debounce(async function() {
  try {
    const { data } = await partnersApi.partnersAutocomplete(searchCompany.value);
    companiesSuggestions.value = data;
  } catch (err) {
    companiesSuggestions.value = [];
  }
}, 600, false);

const onSearchOpportunity = debounce(async function() {
  try {
    const companiesIds = companiesFilter.value.map(company => company._id);
    const { data } = await getCustomJobs({ companiesIds, searchInput: searchOpportunity.value });
    opportunitiesSuggestions.value = data?.jobs || [];
  } catch (err) {
    opportunitiesSuggestions.value = [];
  }
}, 600, false);

const onSelectCompany = (company) => {
  companiesFilter.value = [...companiesFilter.value, company];
  searchCompany.value = '';
}

const onSelectOpportunity = (opportunity) => {
  opportunitiesFilter.value = [...opportunitiesFilter.value, opportunity];
  searchOpportunity.value = '';
}

const onRemoveCompany = (company) => {
  if (company._id) {
    companiesFilter.value = companiesFilter.value.filter(data => data._id !== company._id);
  }
}

const onRemoveOpportunity = (opportunity) => {
  if (opportunity._id) {
    opportunitiesFilter.value = opportunitiesFilter.value.filter(data => data._id !== opportunity._id);
  }
}

const onSelectCoach = (coach = {}) => {
  if (coachesFilterIds.value.includes(coach._id)) {
    coachesFilter.value = coachesFilter.value.filter(c => c._id !== coach._id);
  } else {
    coachesFilter.value = [...coachesFilter.value, coach];
  }
}

const onRemoveCoach = (coachId) => {
  if (coachId) {
    coachesFilter.value = coachesFilter.value.filter(data => data.value !== coachId);
  }
}

const onRemoveFilters = () => {
  companiesFilter.value = [];
  opportunitiesFilter.value = [];
  coachesFilter.value = [];
  jobboardFilter.value = [];

  router.push({
    query: {
      page: '1',
    },
  });

  emit('on-submit');
}

const onSubmit = () => {
  const query = {
    ...route.query || {},
    page: 1,
  };

  if (companiesFilter.value?.length) {
    query['companyIds'] = companiesFilter.value.map(company => company._id).join(',');
  } else {
    query['companyIds'] = undefined;
  }
  if (opportunitiesFilter.value?.length) {
    query['customJobIds'] = opportunitiesFilter.value.map(opportunity => opportunity._id).join(',');
  } else {
    query['customJobIds'] = undefined;
  }
  if (coachesFilter.value?.length) {
    query['coachIds'] = coachesFilter.value.map(coach => coach._id).join(',');
  } else {
    query['coachIds'] = undefined;
  }
  if (jobboardFilter.value?.length) {
    query['sources'] = jobboardFilter.value.join(',');
  } else {
    query['sources'] = undefined;
  }

  const today = moment().format('YYYY-MM-DD');
  const tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');
  let from;
  let to;
  if (dateFilter.value === 'today') {
    from = today;
    to = tomorrow;
  } else if (dateFilter.value === 'week') {
    from = moment(today).subtract(7, 'd').format('YYYY-MM-DD');
    to = tomorrow;
  } else if (dateFilter.value === 'month') {
    from = moment(today).subtract(1, 'M').format('YYYY-MM-DD');
    to = tomorrow;
  } else if (dateFilter.value === 'custom') {
    from = moment(customDateRange?.value?.start).format('YYYY-MM-DD');
    to = moment(customDateRange?.value?.end).add(1, 'd').format('YYYY-MM-DD');
  }

  if (to && from) {
    query['from'] = from;
    query['to'] = to;
    query['dateFilter'] = dateFilter.value;
  } else {
    query['from'] = undefined;
    query['to'] = undefined;
    query['dateFilter'] = undefined;
  }
  router.push({
    query,
  });

  emit('on-submit');
}

const onCustomDateInput = () => {
  onSelectDateFilter(true, 'custom')
};

const onAvatarError = (event: any) => {
  event.target.src = DEFAULT_COACH_AVATAR;
}

const onCompanyAvatarError = (event: any) => {
  event.target.src = DEFAULT_COMPANY_AVATAR;
}

onMounted(async () => {
  await store.dispatch('xtramile/getActivatedChannels');
});

watch(isTreated, (value) => {
  if (!value) {
    coachesFilter.value = [];
  }
});

watch(routeQuery, async (query = {}) => {
  if (query.coachIds) {
    const ids = route.query.coachIds.split(',');
    const coachesFinded = coaches.value.filter(coach => ids.includes(coach._id));
    coachesFilter.value = coachesFinded.map(coach => ({
      _id: coach._id,
      value: coach._id,
      label: `${coach.firstName} ${coach.lastName}`,
      avatar: coach.pictureUrl || DEFAULT_COACH_AVATAR,
    }));
  } else {
    coachesFilter.value = [];
  }

  if (query.dateFilter) {
    dateFilter.value = query.dateFilter as DateFilter;
  } else {
    dateFilter.value = undefined;
  }

  if (query.companyIds) {
    const results = await Promise.all(query.companyIds.split(',').map(companyId => partnersApi.getPartnerProfile(companyId)));
    console.log('results', results);
    companiesFilter.value = results.map(result => result.data);
  } else {
    companiesFilter.value = [];
    opportunitiesFilter.value = [];
  }

  if (query.customJobIds) {
    const { data } = await getCustomJobs({ ids: query.customJobIds.split(',') });
    opportunitiesFilter.value = data.jobs;
  } else {
    opportunitiesFilter.value = [];
  }

}, { immediate: true });

</script>

<template>
  <div class="sticky top-24 flex max-h-[78vh] min-h-[78vh] w-[307px] min-w-[307px] flex-col overflow-auto rounded-[10px] border border-blue-200 bg-neutral-100">
    <div class="relative flex h-fit max-h-[78vh] flex-col overflow-auto">
      <div class="p-5">
        <p class="font-bold text-blue-800">Filtrer les candidatures</p>
      </div>

      <hr class="my-1 border-blue-200" />

      <div class="flex flex-col gap-5 p-5">
        <div class="flex flex-col">
          <ApplicationsFilterLabel icon="calendar" label="Date" />
          <div class="mt-2 flex flex-wrap gap-x-3 gap-y-2">
            <ApplicationsFilterButton
              label="Aujourd'hui"
              :disabled="dateFilter && dateFilter !== 'today'"
              :is-selected="dateFilter === 'today'"
              @click="(isSelected) => onSelectDateFilter(isSelected, 'today')"
            />
            <ApplicationsFilterButton
              label="Semaine"
              :disabled="dateFilter && dateFilter !== 'week'"
              :is-selected="dateFilter === 'week'"
              @click="(isSelected) => onSelectDateFilter(isSelected, 'week')"
            />
            <ApplicationsFilterButton
              label="Mois"
              :disabled="dateFilter && dateFilter !== 'month'"
              :is-selected="dateFilter === 'month'"
              @click="(isSelected) => onSelectDateFilter(isSelected, 'month')"
            />
            <v-date-picker
              v-model="customDateRange"
              :locale="locale"
              :popover="{ visibility: 'click', positionFixed: true, placement: 'bottom' }"
              is-range
              @input="onCustomDateInput"
            >
              <template v-slot="{ togglePopover }">
                <ApplicationsFilterButton
                  :label="t('generics.custom')"
                  :is-open="dateFilter && dateFilter === 'custom'"
                  :disabled="dateFilter && dateFilter !== 'custom'"
                  :is-selected="dateFilter === 'custom'"
                  @click="(isSelected) =>  {
                    onSelectDateFilter(isSelected, 'custom');
                    togglePopover();
                  }"
                />
              </template>
            </v-date-picker>
          </div>
        </div>

        <div class="flex flex-col">
          <ApplicationsFilterLabel
            icon="external-link"
            label="Jobboards"
          />
          <div class="mt-2 flex flex-wrap gap-x-3 gap-y-2">
            <ApplicationsFilterButton
              :label="t('custom-jobs.career-website')"
              :is-selected="route.query?.sources?.includes('career')"
              class="h-[36px]"
              @click="(isSelected) => onSelectJobboard(isSelected, 'career')"
            >
              <template #left>
                <JobboardLogo :logoUrl="careerLogoUrl" />
              </template>
            </ApplicationsFilterButton>
            <ApplicationsFilterButton
              v-for="channel in limitedActivatedChannels"
              :key="channel.connectorName"
              :label="channel.title"
              :is-selected="route.query?.sources?.includes(channel.connectorName)"
              class="h-[36px]"
              @click="(isSelected) => onSelectJobboard(isSelected, channel.connectorName)"
            >
              <template #left>
                <JobboardLogo :connectorName="channel.connectorName" />
              </template>
            </ApplicationsFilterButton>
            <div v-if="activatedChannels.length > 3">
              <button v-if="!expandJobboards" class="text-blue-500" @click="toggleExpandJobboards">+ Voir tous les jobboards</button>
              <button v-else class="text-blue-500" @click="toggleExpandJobboards"> - Voir moins de jobboards</button>
            </div>
          </div>
        </div>

        <div class="flex flex-col">
          <ApplicationsFilterLabel icon="companies" label="Entreprises" />
          <BcAutocomplete
            v-model="searchCompany"
            :limit="5"
            :loading="false"
            :options="companiesSuggestions"
            :labelPlaceholder="false"
            placeholder="Rechercher une entreprise"
            name="companies"
            @input="onSearchCompany"
            @select="onSelectCompany"
            @on-dropdown-close="() => {}">
            <template #option="{ option }">
              <BcDropdownItem
                :text="`${option.name}`"
                :avatar="option.logoUrl || DEFAULT_COMPANY_AVATAR"
                @error="onCompanyAvatarError"
                class="font-size-s is-expanded">
              </BcDropdownItem>
            </template>
          </BcAutocomplete>
          <div v-if="companiesFilter.length" class="mt-2 flex flex-wrap gap-2">
            <Tag
              v-for="company in companiesFilter"
              :key="company._id"
              :text="company.name"
              :canClose="true"
              :avatar="company.logoUrl || DEFAULT_COMPANY_AVATAR"
              @error="onCompanyAvatarError"
              @click="onRemoveCompany(company)"
            />
          </div>
        </div>

        <div class="flex flex-col">
          <ApplicationsFilterLabel icon="suitcase" label="Opportunités" />
          <BcAutocomplete
            v-model="searchOpportunity"
            :disabled="!companiesFilter.length"
            :limit="5"
            :loading="false"
            :options="opportunitiesSuggestions"
            :labelPlaceholder="false"
            placeholder="Rechercher une opportunité"
            name="companies"
            @input="onSearchOpportunity"
            @select="onSelectOpportunity"
            @on-dropdown-close="() => {}">
            <template #option="{ option }">
              <BcDropdownItem
                :text="`${option.title}`"
                :avatar="option._company.logoUrl || DEFAULT_COMPANY_AVATAR"
                @error="onCompanyAvatarError"
                class="font-size-s is-expanded">
              </BcDropdownItem>
            </template>
          </BcAutocomplete>
          <div v-if="opportunitiesFilter.length" class="mt-2 flex flex-wrap gap-2">
            <Tag
              v-for="opportunity in opportunitiesFilter"
              :key="opportunity._id"
              :text="opportunity.title"
              :canClose="true"
              :avatar="opportunity._company.logoUrl || DEFAULT_COMPANY_AVATAR"
              @error="onCompanyAvatarError"
              @click="onRemoveOpportunity(opportunity)"
            />
          </div>
        </div>

        <div class="flex flex-col">
          <ApplicationsFilterLabel
            icon="user"
            label="Responsable"
            class="mb-2"
          />
          <div>
            <MvSelect
              icon="user"
              placeholder="Sélectionner un utilisateur"
              direction="top"
              :items="coachOptions"
              :select-full-item="true"
              :with-avatar="true"
              :is-absolute="true"
              :with-checkbox="true"
              @select="onSelectCoach"
              @error="onAvatarError"
            />
          </div>
          <div v-if="coachesFilter.length" class="mt-2 flex flex-wrap gap-2">
            <Tag
              v-for="coach in coachesFilter"
              :key="coach.value"
              :text="coach.label"
              :canClose="true"
              :avatar="coach.avatar || DEFAULT_COACH_AVATAR"
              @error="onAvatarError"
              @click="onRemoveCoach(coach.value)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="sticky bottom-3 mb-5 ml-4 flex items-center gap-4 bg-neutral-100 pt-2">
      <button class="min-w-fit text-sm font-bold text-blue-500" @click="onRemoveFilters">
        {{ t('generics.clean-all') }}
      </button>
      <BcButton size="extra-small" iconLeft="search" @click="onSubmit">
        {{ t('companies.account-management.display-results') }}
      </BcButton>
    </div>
  </div>
</template>
