import contractsController from '@/managers/contracts/controller';
import { getCustomJobs } from '@/api/custom-jobs';
import { getCompanyContacts, getPartnerProfile } from '@/api/company';
import { getUserTimeline } from '@/api/timeline';
import { getFavoriteListsExistence } from '@/api/favoriteLists';
import { useToast } from '@/ui-kit/components/BcToast';
import { useI18n } from '@/i18n/i18n';
import { useProcessesApi } from '@/composables/api/useProcessesApi';
import { ref } from 'vue';
import { Company } from '@/domains/models/Company';
import { DetailsCount } from '@/components/Panel/PanelCompany/PanelCompanyModal/types';

export default async({ companyId }: { companyId: string }) => {
  const toast = useToast();
  const { t } = useI18n();
  const processesApi = useProcessesApi();

  const profile = ref<Company & { count: DetailsCount } | null>(null);
  const fetchProfile = async(): Promise<void> => {
    try {
      const { data } = await getPartnerProfile(companyId);

      return data;
    } catch (e) {
      toast.show({
        message: t('companies.error-while-fetching-company'),
        type: 'error',
      });
    }
  };
  const fetchContractsCount = async() => {
    const { data } = await contractsController.getCompanyContracts(companyId);

    return data.length || 0;
  };
  const fetchOpportunitiesCount = async() => {
    const { data } = await getCustomJobs({ companyId: companyId });

    return data.jobs?.length || 0;
  };
  const fetchContactsCount = async() => {
    const { data } = await getCompanyContacts(companyId);

    return data.length || 0;
  };
  const fetchNotesCount = async() => {
    const { data } = await getUserTimeline({
      userType: 'companies',
      id: companyId,
      page: 1,
      type: 'all',
    });

    return data.count || 0;
  };
  const fetchListsCount = async() => {
    const { data } = await getFavoriteListsExistence({
      ids: companyId,
      type: 3,
    });

    return data.lists?.length || 0;
  };

  profile.value = await fetchProfile();

  const finaleProfileProcessesCount = await processesApi.getProcessesCountByResource({
    companyId: companyId,
    onlyActive: true,
  });
  const finaleProfileContractsCount = await fetchContractsCount();
  const finaleProfileOpportunitiesCount = await fetchOpportunitiesCount();
  const finaleProfileContactsCount = await fetchContactsCount();
  const finaleProfileNotesCount = await fetchNotesCount();
  const finaleProfileListsCount = await fetchListsCount();


  return {
    ...profile.value ?? {},
    count: {
      processes: finaleProfileProcessesCount,
      documents: finaleProfileContractsCount,
      opportunities: finaleProfileOpportunitiesCount,
      contacts: finaleProfileContactsCount,
      notes: finaleProfileNotesCount,
      lists: finaleProfileListsCount,
    },
  };
}
