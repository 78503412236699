<template>
  <div class="organizations-modal-delete is-column is-align-items-center">
    <p class="organizations-modal-delete__title font-size-xl mb-4 font-weight-bold">
      Supprimer une organisation
    </p>
    <div class="organizations-modal-delete__body is-full-width is-column">
      <p class="database-coders-modal-data-import__text is-secondary is-text-align-center mb-1">
        Voulez-vous vraiment supprimer cette organisation ?
      </p>
      <p class="database-coders-modal-data-import__text is-secondary is-text-align-center mb-4">
        Toutes les données associées à cette organisation seront supprimées.
      </p>
      <p
        class="database-coders-modal-data-import__text is-error font-weight-bold is-text-align-center">
        Cette action est irréversible.
      </p>
    </div>
    <hr class="organizations-modal-delete__separator mt-5 mb-5 full-width"/>
    <div class="organizations-modal-delete__wrapper is-full-width is-justify-content-center">
      <bc-button
        class="organizations-modal-delete__button mr-4"
        data-button-cancel
        type="outlined"
        @click="$emit('close-modal')">
        {{ $t('generics.cancel') }}
      </bc-button>
      <bc-button
        class="organizations-modal-delete__button"
        color="success"
        data-button-validate
        @click="deleteOrganization">
        Supprimer l'organisation
      </bc-button>
    </div>
  </div>
</template>

<script>
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import { deleteOrganization } from '@/api/organizations';

  export default {
    name: 'organizations-modal-delete',
    components: { BcButton },
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
    },
    methods: {
      async deleteOrganization() {
        try {
          await deleteOrganization({
            id: this.profile._id,
          });

          this.$emit('profile-deleted');
        } catch (error) {
          this.$toast.show({
            title: 'Erreur lors de la suppression de l\'organisation',
            type: 'error',
            message: error?.response?.data?.message || error,
          });
        }
      },
    },
  };
</script>

<style lang=scss scoped>
  .organizations-modal-delete {
    padding: 30px;
    width: 100%;
    max-width: 600px;

    @include bp('tablet') {
      min-width: 600px;
    }

    &__title {
      color: $color-secondary;
    }

    &__separator {
      width: 100%;
      border: 0;
      border-top: 1px solid rgba($color-secondary, 0.2);
    }
  }
</style>
