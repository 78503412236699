<template>
  <div
    @keydown.left.right.stop
    class="bc-input-number is-column is-relative is-justify-content-center">
    <div
      :class="{ 'bc-input-number__container--label': $attrs.label }"
      class="bc-input-number__container is-relative">
      <label
        v-if="$attrs.label"
        :disabled="disabled"
        :for="name"
        class="bc-input-number__label is-absolute font-size-s">
        <i
          v-if="icon"
          :class="[ `icon-${icon}` ]"
          class="bc-input-number__label-icon is-flex is-align-items-center font-size-s">
        </i>
        {{ $attrs.label }}
        <span
          v-if="required"
          class="bc-input-number__label-required">
          *
        </span>
      </label>
      <div
        :class="{ 'bc-input-number__wrapper--no-label': !shouldDisplayIncrementer }"
        class="bc-input-number__wrapper is-row is-align-items-center">
        <input
          :id="name"
          ref="input"
          v-model.number="computedValue"
          :class="{
            'bc-input-number__input--success': valid,
            'bc-input-number__input--error': error
          }"
          :disabled="disabled"
          :max="max"
          :min="min"
          :step="step"
          class="bc-input-number__input border-radius-s font-size-m"
          type="number"
          v-bind="$attrs"
          @blur="onBlur"
          @focus="onFocus"/>
        <p
          v-if="unity"
          class="bc-input-number__unity">
          {{ unity }}
        </p>
        <button
          v-if="shouldDisplayIncrementer"
          :disabled="disabled || disabledMin"
          class="bc-input-number__control border-radius-rounded is-flex is-align-items-center is-justify-content-center icon-minus"
          type="button"
          @click="onControlClick($event, false)"
          @mousedown="onStartLongPress($event, false)"
          @mouseleave="onStopLongPress(false)"
          @mouseup="onStopLongPress(false)"
          @touchcancel="onStopLongPress(false)"
          @touchend="onStopLongPress(false)"
          @touchstart.prevent="onStartLongPress($event, false)">
        </button>
        <button
          v-if="shouldDisplayIncrementer"
          :disabled="disabled || disabledMax"
          class="bc-input-number__control border-radius-rounded is-flex is-align-items-center is-justify-content-center icon-plus"
          type="button"
          @click="onControlClick($event, true)"
          @mousedown="onStartLongPress($event, true)"
          @mouseleave="onStopLongPress(true)"
          @mouseup="onStopLongPress(true)"
          @touchcancel="onStopLongPress(true)"
          @touchend="onStopLongPress(true)"
          @touchstart.prevent="onStartLongPress($event, true)">
        </button>
      </div>
    </div>
    <p
      v-if="error"
      class="bc-input-number__error font-size-s">
      {{ error }}
    </p>
  </div>
</template>

<script>
  export default {
    name: 'bc-input-number',
    inheritAttrs: false,
    props: {
      /**
       * The value of the input
       */
      value: {
        type: Number,
        default: 0,
      },
      /**
       * The name of the input
       */
      name: {
        type: String,
        default: '',
      },
      /**
       * The icon for the label
       */
      icon: {
        type: String,
        default: '',
      },
      /**
       * The min value of the input
       */
      min: {
        type: [Number, String],
        default: undefined,
      },
      /**
       * The max value of the input
       */
      max: {
        type: [Number, String],
        default: undefined,
      },
      /**
       * Define the value of step
       */
      step: {
        type: [Number, String],
        default: 1,
      },
      /**
       * Set the input as valid
       */
      valid: {
        type: Boolean,
        default: false,
      },
      /**
       * Set the input as wrong and display the error message
       */
      error: {
        type: String,
        default: '',
      },
      /**
       * Set the input as required
       */
      required: {
        type: Boolean,
        default: false,
      },
      shouldDisplayIncrementer: {
        type: Boolean,
        default: true,
      },
      unity: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        defaultValue: !isNaN(this.value) ? this.value : parseFloat(this.min) || 0,
        newValue: !isNaN(this.value) ? this.value : parseFloat(this.min) || 0,
        newStep: this.step || 1,
      };
    },
    computed: {
      computedValue: {
        get() {
          return this.newValue;
        },
        set(value) {
          let newValue = value === '' ? undefined : value;

          this.newValue = newValue;

          /**
           * v-model -> Emit the value
           *
           * @event input
           * @type {number}
           */
          this.$emit('input', newValue);
        },
      },
      minNumber() {
        return typeof this.min === 'string' ? parseFloat(this.min) : this.min;
      },
      maxNumber() {
        return typeof this.max === 'string' ? parseFloat(this.max) : this.max;
      },
      disabledMin() {
        return (this.computedValue - this.stepNumber) < this.minNumber;
      },
      disabledMax() {
        return (this.computedValue + this.stepNumber) > this.maxNumber;
      },
      stepNumber() {
        return typeof this.newStep === 'string' ? parseFloat(this.newStep) : this.newStep;
      },
      stepDecimals() {
        const step = this.stepNumber.toString();
        const index = step.indexOf('.');
        if (index >= 0) {
          return step.substring(index + 1).length;
        }
        return 0;
      },
    },
    watch: {
      value(value) {
        this.newValue = value;
      },
    },
    methods: {
      decrement() {
        if (typeof this.minNumber === 'undefined' || (this.computedValue - this.stepNumber) >= this.minNumber) {
          const value = this.computedValue - this.stepNumber;

          this.computedValue = parseFloat(value.toFixed(this.stepDecimals));
        }
      },
      increment() {
        if (typeof this.maxNumber === 'undefined' || (this.computedValue + this.stepNumber) <= this.maxNumber) {
          const value = this.computedValue + this.stepNumber;

          this.computedValue = parseFloat(value.toFixed(this.stepDecimals));
        }
      },
      onControlClick(event, inc) {
        if (event.detail !== 0 || event.type === 'click') {
          return;
        }

        if (inc) {
          this.increment();
        } else {
          this.decrement();
        }
      },
      onStartLongPress(event, inc) {
        if (event.button !== 0 && event.type !== 'touchstart') {
          return;
        }

        this._$intervalTime = new Date();

        clearInterval(this._$intervalRef);

        this._$intervalRef = setInterval(() => {
          if (inc) {
            this.increment();
          } else {
            this.decrement();
          }
        }, 200);
      },
      onStopLongPress(inc) {
        if (!this._$intervalRef) {
          return;
        }

        const d = new Date();

        if (d - this._$intervalTime < 250) {
          if (inc) {
            this.increment();
          } else {
            this.decrement();
          }
        }

        clearInterval(this._$intervalRef);

        this._$intervalRef = null;
      },
      onBlur($event) {
        this.$emit('blur', Number($event.target.value));
      },
      onFocus() {
        this.$emit('focus');
      },
    },
  };
</script>

<style lang=scss scoped>
  .bc-input-number {
    input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    &__wrapper {
      margin: 0 -5px;

      &--no-label {
        margin: 0;
      }
    }

    &__control {
      padding: 13px;
      min-width: 39px;
      min-height: 39px;
      background: $color-white;
      color: $color-grey-4;
      font-size: $font-size-s;
      margin: 0 5px;

      &:first-of-type {
        order: -1;
      }

      &:first-child {
        margin-right: 5px;

        &:active {
          background: $color-primary;
          color: $color-white;
        }
      }

      &:last-child {
        margin-left: 5px;

        &:active {
          background: $color-primary;
          color: $color-white;
        }
      }

      &:hover {
        background: $color-grey-2;
      }

      &:disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    &__input {
      border: 1px solid $color-blue-medium;
      color: $color-tertiary;
      text-align: center;
      flex-grow: 1;
      width: 70px;
      height: 100%;

      &--success {
        color: $color-secondary-dark;
      }

      &--error {
        border-color: $color-error;
        background: rgba($color-error, 0.05);
      }

      &:hover {
        border-color: $color-primary;
      }

      &:focus {
        border-color: $color-primary;
        color: $color-primary;
        background: rgba($color-primary, 0.05);
      }

      &:disabled {
        border-color: $color-grey-4;
        background: $color-grey-2;
        color: $color-grey-4;
        opacity: 0.5;

        ~ .bc-input-number__control {
          pointer-events: none;
          opacity: 0.5;
        }
      }

      &::placeholder {
        color: $color-blue-heavy-dark;
      }

      &:focus::placeholder {
        opacity: 0;
      }
    }

    &__label {
      color: $color-grey-4;
      transition: font-size 0.3s, transform 0.3s;
      left: 20px;
      transform: translate(-10px, -22.5px);

      &--hover {
        color: $color-grey-5;
      }
    }

    &__label-icon {
      margin-right: 5px;
    }

    &__label-required {
      color: $color-error;
      margin-left: 5px;
    }

    &__error {
      color: $color-error;
      margin-top: 5px;
    }

    &__unity {
      font-size: 17px;
      color: $color-grey-4;
      margin-left: 5px;
    }
  }
</style>
