import { defineStore } from 'pinia';
import { ref } from 'vue';
import * as ldClient from 'launchdarkly-js-client-sdk';
import { LDClient } from 'launchdarkly-js-client-sdk';

export const useLaunchDarklyStore = defineStore('launchDarkly', () => {
  const client = ref<LDClient | null>(null);
  const flags = ref({});
  const isReady = ref(false);

  async function initLaunchDarkly(user) {
    if (!user) {
      console.warn('⚠️ Pas de profil utilisateur, LaunchDarkly ne sera pas initialisé.');
      return;
    }

    client.value = ldClient.initialize(import.meta.env?.VITE_LAUNCHDARKLY_CLIENT_ID, user);
    return new Promise((resolve) => {
      client.value.on('ready', () => {
        flags.value = client.value.allFlags();

        isReady.value = true;

        client.value.on('change', (updates) => {
          Object.keys(updates).forEach((flagKey) => {
            flags.value[flagKey] = updates[flagKey].current;
          });
        });

        resolve();
      });
    });
  }

  async function setUser(user) {
    if (!client.value) {
      console.warn('⚠️ LaunchDarkly n\'est pas encore initialisé. Tentative d\'initialisation...');
      await initLaunchDarkly(import.meta.env.LAUNCHDARKLY_CLIENT_ID, user);
      return;
    }

    client.value.identify(user, (err) => {
      if (err) {
        console.error('❌ Erreur lors de la mise à jour de l’utilisateur LaunchDarkly', err);
      } else {
        console.log('✅ Utilisateur mis à jour dans LaunchDarkly 🎯', user);
        flags.value = client.value?.allFlags();
      }
    });
  }

  function getFlag(flagKey: string) {
    return flags.value[flagKey] ?? false;
  }

  return { client, flags, isReady, initLaunchDarkly, setUser, getFlag };
});
