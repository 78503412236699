<script lang="ts" setup>
  import { setAlternativeImg } from '@/mixins/imgFallback';
  import { Company } from '@/domains/models/Company';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
  import PanelCompanyModalMergeButton
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalMerge/PanelCompanyModalMergeButton.vue';
  import ProvideKeys from '@/components/Panel/PanelCompany/PanelCompanyModal/ProvideKeys';
  import { computed, inject } from 'vue';
  import { SelectedFields } from '@/components/Panel/PanelCompany/PanelCompanyModal/types';

  const props = defineProps<{
    company: Company;
    toDelete: boolean;
  }>();

  const {
    selectedFields,
    setSelectedFields,
    selectedFieldsProfile,
  } = inject(ProvideKeys.selectedFields) as SelectedFields;
  const { rightProfile } = inject(ProvideKeys.mergeProfiles);

  const isAvatarSelected = computed(() => selectedFields.value.includes('logoUrl'));
  const logoUrl = computed(() => {
    if (props.toDelete) {
      return props.company?.logoUrl ?? '';
    }

    return isAvatarSelected.value ? selectedFieldsProfile.value.logoUrl : props.company?.logoUrl;
  });
  const mergeButtonLabel = computed(() => {
    return rightProfile.value?.logoUrl ? 'erase' : 'import';
  });
</script>

<template>
  <div class="w-full items-center gap-2.5 px-2.5 pt-5">
    <div class="relative flex flex-col items-center">
      <BcAvatar
        :src="logoUrl"
        class="size-[66px] shrink-0 rounded-full border border-blue-400"
        @error="setAlternativeImg({event: $event, type: 'companies'})"
      />
      <div class="relative bottom-[10px]">
        <PanelCompanyModalMergeButton
          v-if="toDelete"
          :status="isAvatarSelected ? 'imported' : mergeButtonLabel"
          @click.native="setSelectedFields('logoUrl')"/>
      </div>
    </div>
    <div class="flex flex-col items-start gap-1">
      <p class="text-blue-500">{{ company?.name }}</p>
      <div
        class="flex h-[18px] flex-col items-center gap-1 rounded-3xl bg-[#DDF4E9] px-2.5 text-xs text-green-500">
        Signé
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
