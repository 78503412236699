<script setup lang="ts">
import { ref, computed, defineProps, withDefaults } from 'vue';

import { useStore } from '@/store';
import ResumeUploadItem from './ResumeUploadItem'

type Props = {
  hasActiveMessages?: boolean;
}

withDefaults(defineProps<Props>(), {
  hasActiveMessages: false,
});

const store = useStore();
const isCollapse = ref(false);

const profileId =  computed(() => store.state.user.profile?._id);
const resumeUpload = computed(() => store.getters['resumeHistories/resumeUpload']);
const resumeUploadList = computed(() => {
  if (resumeUpload.value?.files) {
    return Object.keys(resumeUpload.value.files).map(fileId => resumeUpload.value?.files[fileId])
  }
  return [];
});

const progressCount = computed(() => store.getters['resumeHistories/progressCount']);
const isUploadDone = computed(() => store.getters['resumeHistories/isDone']);

const toggleCollapse = () => {
  isCollapse.value = !isCollapse.value;
}

const setClosedAt = () => {
  store.dispatch('resumeHistories/setClosedAt', {
    coachId: profileId.value,
    groupId: resumeUpload.value?.groupId,
  });
}
</script>

<template>
  <div
    class="fixed bottom-0 right-0  min-w-[351px] rounded-t-[10px]"
    :class="{ 'min-h-[380px]': !isCollapse, 'h-auto': isCollapse, 'mr-[455px]': hasActiveMessages }"
    style="z-index: 10000000000!important; box-shadow: 0px 4px 30px 0px rgba(29, 57, 94, 0.10);">
    <div class="flex flex-col w-full">
      <div class="flex items-center justify-between px-4 py-5 bg-neutral-200 w-full h-[55px] rounded-t-[10px]">
        <div class="color-blue-800">Création des candidats {{progressCount}}/{{ resumeUploadList.length }}</div>
        <div class="flex gap-5">
          <button @click="toggleCollapse"><i :class="`icon-chevron-${isCollapse ? 'up' : 'down'} text-blue-400`"></i></button>
          <button v-if="isUploadDone" @click="setClosedAt"><i class="icon-cross text-blue-400"></i></button>
        </div>
      </div>
      <div class="flex flex-col bg-white overflow-auto w-full h-full min-w-[351px] max-h-[400px]" :class="{ 'hidden': isCollapse }">
        <div v-for="(item, index) in resumeUploadList" class="flex flex-col">
          <ResumeUploadItem :item="item"></ResumeUploadItem>
          <div v-if="index < resumeUploadList.length - 1" class="w-full h-[1px] bg-blue-100" />
        </div>
      </div>
    </div>
  </div>
</template>
