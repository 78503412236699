<template>
  <bc-dropdown
    :close-dropdown-at-select="false"
    :is-scrollable="false"
    class="views-topbar-create"
    position="bottom-left"
    :force-direction="true"
  >
    <template #label="{ isDropdownOpen }">
      <div class="views-topbar-create__wrapper is-justify-content-end">
        <!-- <button
          v-show="isDropdownOpen"
          ref="open"
          class="icon-cross views-topbar-create__button border-radius-rounded is-align-items-center is-justify-content-center is-unshrink">
        </button> -->
        <bc-button
          ref="closed"
          class="views-topbar-create__button-create is-unshrink h-[42px]"
          :class="{ 'views-topbar-create__button-create--open': isDropdownOpen }"
          color="coach"
          :icon-left="isDropdownOpen ? 'cross' : 'plus'">
          {{ $t('generics.create') }}
        </bc-button>
      </div>
    </template>
    <template #custom="{ isDropdownOpen }">
      <div v-if="isDropdownOpen" class="views-topbar-create__options is-column">
        <views-topbar-create-coders class="views-topbar-create__item is-full-width mb-2" />
        <views-topbar-create-companies
          v-if="configurationFramework.showCompanies"
          class="views-topbar-create__item is-full-width mb-2"
        />
        <views-topbar-create-contacts class="views-topbar-create__item is-full-width mb-2" />
        <views-topbar-create-opportunities class="views-topbar-create__item is-full-width mb-2 pb-4" />
        <views-topbar-create-processes class="is-full-width mb-2 pb-3" />
      </div>
    </template>
  </bc-dropdown>
</template>
<script>
import { mapGetters } from 'vuex';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
import ViewsTopbarCreateCompanies from '@/components/Views/ViewsTopbar/ViewsTopbarCreate/ViewsTopbarCreateCompanies';
import ViewsTopbarCreateCoders from '@/components/Views/ViewsTopbar/ViewsTopbarCreate/ViewsTopbarCreateCoders';
import ViewsTopbarCreateContacts from '@/components/Views/ViewsTopbar/ViewsTopbarCreate/ViewsTopbarCreateContacts';
import ViewsTopbarCreateOpportunities from '@/components/Views/ViewsTopbar/ViewsTopbarCreate/ViewsTopbarCreateOpportunities';
import ViewsTopbarCreateProcesses from '@/components/Views/ViewsTopbar/ViewsTopbarCreate/ViewsTopbarCreateProcesses';

export default {
  name: 'views-topbar-create',
  components: {
    ViewsTopbarCreateProcesses,
    ViewsTopbarCreateOpportunities,
    ViewsTopbarCreateCoders,
    ViewsTopbarCreateContacts,
    ViewsTopbarCreateCompanies,
    BcDropdown,
    BcButton
  },
  computed: {
    ...mapGetters({
      configurationFramework: 'user/configurationFramework',
    }),
  }
};
</script>
<style lang="scss" scoped>
.views-topbar-create {
  z-index: 5;

  &__wrapper {
    min-width: 103px;
  }

  &__button {
    width: 40px;
    height: 40px;
    border: 2px dashed $color-secondary;
    color: $color-secondary;

    &:hover {
      border-style: solid;
      color: $color-white;
      background: $color-secondary;
    }

    &-create {
      background: $color-blue-gradient-1;
      font-weight: 500;
      font-size: 15px;
      width: 105px;

      &:hover {
        background: $color-blue-gradient-2;
      }

      &--open {
        background: $color-neutre-4;
        border: 1px solid $color-blue-medium;
        color: $color-primary;

        &:hover {
          background: $color-neutre-4;;
        }

        &:deep() {
          i {
            font-size: 14px;
          }
        }
      }
    }
  }

  &__options {
    min-width: 255px;
    cursor: default;
    color: $color-tertiary;
    overflow: auto;
  }

  &__item {
    border-bottom: 1px solid $color-blue-medium;
  }
}
</style>
