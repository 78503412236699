import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { LinkedinRelationStatus } from '@/domains/models/Linkedin';
export const useLinkedinRelationsStore = defineStore('linkedinRelations', () => {

  const relations = ref<string[]>([]);
  const pendingInvitations = ref<string[]>([]);

  function setRelations(newRelations: string[]) {
    relations.value = [...new Set(newRelations)];
  }

  function setPendingInvitations(newPendingInvitations: string[]) {
    pendingInvitations.value = [...new Set(newPendingInvitations)];
  }


  function setAsRelation(publicId: string) {
    if (!relations.value.includes(publicId)) {
      relations.value = [...relations.value, publicId];
    }
    pendingInvitations.value = pendingInvitations.value.filter(id => id !== publicId);
  }

  function setAsPendingInvitation(publicId: string) {
    if (!pendingInvitations.value.includes(publicId)) {
      pendingInvitations.value = [...pendingInvitations.value, publicId];
    }
    relations.value = relations.value.filter(id => id !== publicId);
  }

  function removeRelation(publicId: string) {
    relations.value = relations.value.filter(id => id !== publicId);
  }


  function addPendingInvitations(newPendingInvitations: string[]) {
    newPendingInvitations.forEach(publicId => setAsPendingInvitation(publicId));
  }

  function setAsNotConnected(publicId: string) {
    relations.value = relations.value.filter(id => id !== publicId);
    pendingInvitations.value = pendingInvitations.value.filter(id => id !== publicId);
  }

  function addOrRemoveRelation(publicId: string, isRelation: boolean) {
    if (isRelation === undefined) return;
    if (isRelation) {
      setAsRelation(publicId);
    } else {
      removeRelation(publicId);
    }
  }

  function getRelationStatus(publicId?: string): LinkedinRelationStatus | undefined {
    if (!publicId) return;
    const isRelation = relations.value.includes(publicId);
    const isPendingInvitation = pendingInvitations.value.includes(publicId);
    return isRelation ? LinkedinRelationStatus.Connected : isPendingInvitation ? LinkedinRelationStatus.Pending : LinkedinRelationStatus.NotConnected;
  }

  return {
    relations,
    pendingInvitations,
    setAsRelation,
    setAsPendingInvitation,
    setAsNotConnected,
    setRelations,
    setPendingInvitations,
    addPendingInvitations,
    addOrRemoveRelation,
    getRelationStatus,
  };
}); 