import { http } from '@/api/index';
import axios, { CancelTokenSource } from 'axios';
import { TimelineType } from '@/domains/models/Timeline';
import macros from '@/macros/macros';

http.CancelToken = axios.CancelToken;

let source: CancelTokenSource | null = null;

type RemindersUserType = 'coders' | 'partners' | 'custom-jobs';

const routes = {
  companies: 'partners',
  partners: 'partners',
  coders: 'coders',
  contacts: 'contacts',
  hunt: 'custom-jobs',
  customJobs: 'custom-jobs',
  jobs: 'jobs',
  pokes: 'pokes',
  process: 'processes',
  coaches: 'coaches',
  'suggestions-coders': 'coders',
} as const;

type RoutesMapType = keyof typeof routes;

export const getGlobalTimeline = ({
                                    page,
                                    coachesActivities,
                                    usersActivities,
                                    processesActivities,
                                    meeseeksActivities,
                                  }) => {
  stopTimelineRequest();

  source = http.CancelToken.source();

  return http.get('/timeline', {
    params: {
      page,
      ...coachesActivities && { coachesActivities },
      ...usersActivities && { usersActivities },
      ...processesActivities && { processesActivities },
      ...meeseeksActivities && { meeseeksActivities },
    },
    cancelToken: source?.token,
  });
};

export const getUserTimeline = ({
                                  userType,
                                  id,
                                  page,
                                  // filter,
                                  // coachesActivities,
                                  // usersActivities,
                                  // processesActivities,
                                  // meeseeksActivities,
                                  // smallWins,
                                  type = macros.timeline.types.notes,
                                }: {
  userType: RoutesMapType,
  id: string,
  page: number,
  type: TimelineType,
}) => {
  // If there is a pending token, it will cancel the pending request
  stopTimelineRequest();

  source = http.CancelToken.source();

  return http.get(`/${routes[userType]}/${id}/timeline`, {
    params: {
      page,
      type,
    },
    cancelToken: source?.token,
  });
};

export const getUserRemindersCount = ({
                                        userType,
                                        id,
                                      }: {
  userType: RemindersUserType,
  id: string,
}) => {
  return http.get(`/${userType}/${id}/reminders/count`);
};

export const getQualifications = ({ userType, id, page }) => {
  return http.get(`/${userType}/${id}/qualifications`, {
    params: {
      page,
    },
  });
};

export const getProcess = ({ userType, id, page }) => {
  return http.get(`/${userType}/${id}/recruitment`, {
    params: {
      page,
    },
  });
};

export const getProcessCustom = ({ userType, id, page }) => {
  return http.get(`/${userType}/${id}/recruitment`, {
    params: {
      page,
    },
  });
};

export const getDraft = id => {
  return http.get(`/draft/${id}`);
};

export const createDraft = ({ userType, id }) => {
  if (userType === 'hunt') {
    userType = 'custom-jobs';
  }
  return http.post(`/${userType}/${id}/note`);
};

export const createNote = ({ userType, id, note }) => {
  // If there is a pending token, it will cancel the pending request
  stopTimelineRequest();

  source = http.CancelToken.source();

  return http.patch(`${userType}/notes/${id}/validate`, note, {
    cancelToken: source?.token,
  });
};

export const linkNote = (noteId, linkedProcesses) => {
  return http.patch(`/notes/${noteId}/link`, {
    linkedProcesses,
  });
};

export const unlinkNote = (noteId, linkedProcesses) => {
  return http.patch(`/notes/${noteId}/unlink`, {
    linkedProcesses,
  });
};

export const editDraft = ({ id, note }) => {
  return http.patch(`/notes/${id}`, {
    ...(note.content || '').trim() && { content: note.content },
    ...note.rating >= 0 && { rating: note.rating },
    ...note.urgency >= 0 && { urgency: note.urgency },
    ...note.type && { type: note.type },
    ...note.media && { media: note.media },
    ...(note.hasOwnProperty('images')) && { images: note.images },
    ...(note.hasOwnProperty('openToWork')) && { openToWork: note.openToWork },
    ...(note.hasOwnProperty('pin') && { pin: note.pin }),
  });
};

export const editNote = ({ id, note }) => {
  return http.patch(`/notes/${id}`, {
    ...(note.content || '').trim() && { content: note.content },
    ...note.rating >= 0 && { rating: note.rating },
    ...note.type && { type: note.type },
    ...(note.hasOwnProperty('dateToContact')) && { dateToContact: note.dateToContact },
    ...(note.hasOwnProperty('images')) && { images: note.images },
    ...(note.hasOwnProperty('openToWork')) && { openToWork: note.openToWork },
    ...(note.hasOwnProperty('pin') && { pin: note.pin }),
    mentions: note.mentions,
  });
};

export const deleteNote = ({ id, actionId }) => {
  return http.delete(`/notes/${id}?actionId=${actionId}`);
};

export const stopTimelineRequest = () => {
  if (source) {
    source.cancel('Operation canceled due to new request.');
  }
};

export const getSmallWinsTimeline = ({ page }) => {
  source = http.CancelToken.source();

  return http.get('/dashboard/smallwins/timeline', {
    params: { page },
    cancelToken: source?.token,
  });
};

export default {
  getGlobalTimeline,
  getUserTimeline,
  createDraft,
  editDraft,
  deleteNote,
  stopTimelineRequest,
  linkNote,
  unlinkNote,
  getQualifications,
  getProcess,
  createNote,
  getDraft,
  editNote,
  getSmallWinsTimeline,
};
