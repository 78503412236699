export const apiRoles = {
  guest: 1,
  coach: 2,
  mentor: 3,
  presales: 4,
  product: 5,
  businessManager: 6,
  market: 7,
  administration: 8,
  humanResources: 9,
  lead: 10,
};

export const roleOptions = [
  {
    name: 'Business Manager',
    api: apiRoles.businessManager,
  },
  {
    name: 'Team Produit',
    api: apiRoles.product,
  },
  {
    name: 'Pre-sales',
    api: apiRoles.presales,
  },
  {
    name: 'Mentor',
    api: apiRoles.mentor,
  },
  {
    name: 'Coach',
    api: apiRoles.coach,
  },
  {
    name: 'Invité',
    api: apiRoles.guest,
  },
  {
    name: 'Market',
    api: apiRoles.market,
  },
  {
    name: 'Gestion',
    api: apiRoles.administration,
  },
  {
    name: 'Ressources Humaines',
    api: apiRoles.humanResources,
  },
  {
    name: 'Lead',
    api: apiRoles.lead,
  },
];

export default {
  roleOptions,
  apiRoles,
};
