import { http } from '@/api/index';

export const mapFeatureNames = {
  candidateDropCV: 'Drop CV',
  career: 'Site Carrière',
  shareProcesses: 'Partage le process client',
  editProcessesB2C: 'Edition des colonnes B2C',
  searchByLastActivity: 'Recherche par date de dernière activité',
  addTeamMember: 'Ajout un nouveau membre',
  multidiffusion: 'Multidiffusion',
  customNotes: 'Notes personnalisées',
  customFields: 'Champs personnalisés',
  extensionSkillsCrawl: 'Activer la récupération de compétences dans l’extension',
  candidatesLimitUnlocked: 'Base de données candidat débloqué',
  companiesLimitUnlocked: 'Base de données entreprise débloquée',
  jobsLimitUnlocked: 'Base de données opportunité débloquée',
  editCompanyProspection: 'Edition des colonnes Entreprise',
}

export const organizationTypes = {
  independent: 'independent',
  recruiting_firm: 'recruiting_firm',
  hr_department: 'hr_department',
  consulting_firm_esn: 'consulting_firm_esn',
}

export const getOrganizations = (params) => {
  return http.get('/organizations', { params });
};

export const createOrganization = ({ companyId, name }) => {
  return http.post('/organizations', { companyId, name });
};

export const editOrganization = ({ id, data }) => {
  return http.patch(`/organizations/${id}`, data);
};

export const deleteOrganization = ({ id }) => {
  return http.delete(`/organizations/${id}`);
};

export const getOrganizationProfile = id => {
  return http.get(`/organizations/${id}`);
};

export const getOrganizationUsers = id => {
  return http.get(`/organizations/${id}/users`);
};

export const getOrganizationDomains = id => {
  return http.get(`/organizations/${id}/domains`);
};
export const subscribe = (id) => {
  return http.post(`/organizations/${id}/subscribe`);
}

export const updateConfigurationOrganization = (configuration) => {
  return http.put(`/organizations/configuration`, configuration)
}

export const updateConfigurationOrganizationByKey = (configuration) => {
  return http.put(`/organizations/configuration/bykey`, configuration)
}

export const deleteConfigurationStep = ({ categoryKey, stepKey }) => {
  return http.put('/organizations/configuration/remove-step', {
    categoryKey,
    stepKey,
  });
}

export const deleteConfigurationProspectionStep = ({ categoryKey, stepKey }) => {
  return http.put('/organizations/configuration/remove-prospection-step', {
    categoryKey,
    stepKey,
  });
}

export const syncAlgolia = (id) => {
  return http.put(`/organizations/${id}/algolia/sync`)
}

export const generateAlgoliaCredentials = (id) => {
  return http.put(`/organizations/${id}/algolia/credentials`)
}

export const extractDocuments = (id) => {
  return http.put(`/organizations/${id}/algolia/extract-text-documents`)
}

export const addCustomField = ({
  organizationId,
  ressourceType,
  type,
  name,
  icon,
  defaultValue,
  options,
  baseUri,
  min,
  max,
  increment,
  unit,
  showFilter,
}) => {
  return http.post(`/organizations/${organizationId}/customfield`, {
    ressourceType,
    type,
    name,
    icon,
    defaultValue,
    options,
    baseUri,
    min,
    max,
    increment,
    unit,
    showFilter,
  });
}

export const removeCustomField = ({ organizationId, ressourceType, key }) => {
  return http.delete(`/organizations/${organizationId}/customfield`, {
    params: {
      ressourceType,
      key,
    }
  });
}

export default {
  getOrganizations,
  createOrganization,
  getOrganizationProfile,
  getOrganizationUsers,
  editOrganization,
  deleteOrganization,
  subscribe,
  updateConfigurationOrganization,
  updateConfigurationOrganizationByKey,
  syncAlgolia,
  generateAlgoliaCredentials,
  extractDocuments,
  deleteConfigurationProspectionStep,
  addCustomField,
  removeCustomField,
};
