<script lang="ts" setup>
  import {
    Candidate,
    CandidateContractInfo,
    CandidateCustomFieldsInfo,
    CandidateLocationsInfo,
    CandidateProfessionsInfo,
    CandidateSalaryInfo,
    CandidateSkillsInfo,
  } from '@/domains/models/Candidate';
  import { computed, defineEmits, defineProps, ref, watch, withDefaults } from 'vue';
  import PanelCoderEditLocation
    from '@/components/Panel/PanelCoder/PanelCoderEdit/PanelCoderEditLocation.vue';
  import { useI18n } from '@/i18n/i18n';
  import { ValidationObserver } from 'vee-validate';
  import ProfessionsAutocomplete from '@/components/Professions/ProfessionsAutocomplete.vue';
  import SkillsAutocomplete from '@/components/Professions/SkillsAutocomplete.vue';
  import { Skill } from '@/domains/models/Skill';
  import SalaryInput from '@/components/Salary/SalaryInput.vue';
  import { useStore } from '@/store';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { coderController } from '@/managers/coders/controller';
  import BcCustomField from '@/ui-kit/components/BcCustomField/BcCustomField.vue';
  import BcInput from '@/ui-kit/components/BcInput/BcInput.vue';
  import { useToast } from '@/ui-kit/components/BcToast';
  import PanelCoderEditContracts
    from '@/components/Panel/PanelCoder/PanelCoderEdit/PanelCoderEditContracts.vue';

  interface Props {
    candidate: Candidate;
    isCreating?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    isCreating: false,
  });
  const emit = defineEmits<{
    (e: 'close-edit'): void,
    (e: 'update-candidate', value: CandidateLocationsInfo & CandidateProfessionsInfo & CandidateContractInfo & CandidateSkillsInfo & CandidateCustomFieldsInfo & CandidateSalaryInfo): void
  }>();
  const { t } = useI18n();
  const store = useStore();
  const toast = useToast();

  const isCandidate = computed(() => props.candidate?.profileTypes?.includes('candidate'));

  const organization = store.state.user?.profile?._organization;
  const isProfessionRequired = computed(() => props.candidate.profileTypes?.includes('candidate') && !!organization.configuration?.extension?.requiredProfession);
  const customFields = organization.configuration?.candidate?.customFields?.filter(customField => customField.activated) || [];
  const requiredFields = organization.configuration?.candidate?.requiredFields || {};

  const closeEdit = () => {
    emit('close-edit');
  };

  const editedProfile = ref({
    locations: props.candidate.locations,
    _professions: props.candidate._professions,
    contracts: props.candidate.contracts,
    currentSalary: props.candidate.currentSalary?.length ? props.candidate.currentSalary.map(salary => ({
      ...salary,
      value_0: salary.salary,
      value_1: salary.variable,
      value_optional: salary.variable,
      showBenefit: !!salary.benefit,
    })) : [],
    salaryWanted: props.candidate.salaryWanted?.length ? props.candidate.salaryWanted.map(salary => ({
      ...salary,
      value_0: salary.min,
      value_1: salary.max,
      value_optional: salary.variable,
      showBenefit: !!salary.benefit,
    })) : [],
  });
  const setEditedProfile = (data) => {
    editedProfile.value = {
      ...editedProfile.value,
      ...data,
    };
  };

  // skills
  const selectedSkills = ref<Skill[]>([
    ...(props.candidate._technos1?.length ? props.candidate._technos1 : []),
    ...(props.candidate._technos2?.length ? props.candidate._technos2 : []),
    ...(props.candidate._technos3?.length ? props.candidate._technos3 : []),
  ]);
  const updateCurrentSalary = (data = []) => {
    const currentSalary = data
    .map(value => ({
      salaryType: value.salaryType,
      salary: Number(value.value_0),
      variable: Number(value.value_optional),
      benefit: value.benefit,
      ...value.variableType && { variableType: value.variableType },
    }))
    .filter(({ salary, variable, benefit }) => !(!salary && !variable && !benefit));

    setEditedProfile({
      currentSalary,
    });
  };
  const updateSalaryWanted = (data = []) => {
    const salaryWanted = data
    .map(value => ({
      salaryType: value.salaryType,
      min: Number(value.value_0),
      max: Number(value.value_1),
      variable: Number(value.value_optional),
      benefit: value.benefit,
      ...value.variableType && { variableType: value.variableType },
    }))
    .filter(({ min, max, benefit }) => !(!min && !max && !benefit));

    setEditedProfile({
      salaryWanted,
    });
  };

  const handleSelectContract = (data: number[]) => {
    setEditedProfile({
      contracts: data,
    });
  };

  const customFieldsValues = ref(props.candidate.customFields || {});
  const setCustomFields = (data) => {
    // For the 'remote' key, toggle the value (remove if same, add/update if different)
    if (data.key === 'remote' && data.value === customFieldsValues.value[data.key]) {
      // Create a new object without the property at data.key
      const newValues = { ...customFieldsValues.value };
      delete newValues[data.key];
      customFieldsValues.value = newValues;
    } else {
      // For all other cases, just update/add the property
      customFieldsValues.value = {
        ...customFieldsValues.value,
        [data.key]: data.value,
      };
    }
  };

  const purgedProfile = computed(() => ({
    contracts: editedProfile.value.contracts || [],
    locations: editedProfile.value.locations || [],
    _professions: editedProfile.value._professions || [],
    _technos3: selectedSkills.value || [],
    _technos2: [],
    _technos1: [],
    currentSalary: editedProfile.value.currentSalary || null,
    salaryWanted: editedProfile.value.salaryWanted || null,
    customFields: customFieldsValues.value || {},
  }));
  const saveCandidate = async() => {
    if (isProfessionRequired.value && !editedProfile.value._professions?.length) {
      toast.show({
        type: 'error',
        message: t('global.profession-required'),
      });
      throw Error('Profession required');
    }

    if (props.candidate.profileTypes?.includes('candidate')) {
      if (requiredFields.location?.required && !editedProfile.value.locations?.length) {
        toast.show({
          type: 'error',
          message: t('global.location-required'),
        });
        throw Error('Locations required');
      }

      const skills = [
        ...purgedProfile.value?._technos1?.length ? purgedProfile.value._technos1 : [],
        ...purgedProfile.value?._technos2?.length ? purgedProfile.value._technos2 : [],
        ...purgedProfile.value?._technos3?.length ? purgedProfile.value._technos3 : [],
      ];

      if (requiredFields.skills?.required && !skills?.length) {
        toast.show({
          type: 'error',
          message: t('global.skills-required'),
        });
        throw Error('Skills required');
      }

      if (requiredFields.contractType?.required && !editedProfile.value.contracts) {
        toast.show({
          type: 'error',
          message: t('global.contract-type-required'),
        });
        throw Error('Contract type required');
      }
    }

    const { data } = await coderController.editProfile({
      id: props.candidate._id,
      data: purgedProfile.value,
    });
    emit('update-candidate', data);
    closeEdit();
  };

  watch(() => purgedProfile.value, (value) => {
    if (props.isCreating) {
      emit('update-candidate', purgedProfile.value);
    }
  }, { deep: true });
  const setSelectedSkills = e => {
    selectedSkills.value = e;
  };
</script>

<template>
  <div class="relative flex w-full flex-row items-start gap-8 rounded-md bg-neutral-200 px-5 py-4">
    <ValidationObserver
      class="flex w-full flex-col gap-y-2.5"
      tag="form"
      @submit.prevent>
      <BcInput
        v-if="candidate.uniqueid"
        :disabled="true"
        :label="$t('generics.uniqueid')"
        :labelPlaceholder="false"
        :placeholder="candidate.uniqueid"
        :valid="true"
        class="mb-2"
        icon="user"
        name="uniqueid"
        type="number"
      ></BcInput>
      <PanelCoderEditLocation
        :locations="editedProfile.locations"
        :required="requiredFields.location?.required && candidate.profileTypes?.includes('candidate')"
        class="w-full"
        @click.native.stop
        @update-value="setEditedProfile">
      </PanelCoderEditLocation>
      <ProfessionsAutocomplete
        :profile="editedProfile"
        :required="isProfessionRequired"
        class="mt-2.5 flex flex-row flex-wrap"
        for-configuration
        @update-value="setEditedProfile"
        @select-professions="editedProfile._professions = $event">
      </ProfessionsAutocomplete>
      <template v-if="isCandidate">
        <SkillsAutocomplete
          :required="requiredFields.skills?.required && candidate.profileTypes?.includes('candidate')"
          :selected="selectedSkills"
          class="mt-2.5 flex flex-row flex-wrap"
          for-configuration
          @select-skills="setSelectedSkills"
        />
        <PanelCoderEditContracts
          :required="requiredFields.contractType?.required && candidate.profileTypes?.includes('candidate')"
          :selected-contracts="editedProfile.contracts"
          class="mt-5 flex flex-row flex-wrap"
          @select-contracts="handleSelectContract"
        />
        <SalaryInput
          :labels="[$t('generics.salary-cap'), $tc('generics.variable', 1)]"
          :selected="editedProfile.currentSalary"
          :title="$t('generics.current-salary')"
          class="mt-1.5"
          @update="updateCurrentSalary"
        >
        </SalaryInput>
        <SalaryInput
          :is-aligned="true"
          :is-min-max="true"
          :labels="['min', 'max', $tc('generics.variable', 1)]"
          :selected="editedProfile.salaryWanted"
          :title="$t('generics.desired-salary')"
          class="mt-1.5"
          @update="updateSalaryWanted"
        >
        </SalaryInput>
        <div v-if="customFields.length" class="mt-2.5 flex flex-col">
          <BcCustomField
            v-for="customField in customFields"
            :key="customField.key"
            :custom-field="customField"
            :name="customField.key === 'emergency' ? $t('generics.priority') : undefined"
            :placeholder="customField.defaultValue?.toString()"
            :selected="customFieldsValues[customField.key]"
            :type="customField.type"
            class="my-4"
            @on-update="setCustomFields"/>
        </div>
      </template>
      <div
        v-if="!isCreating"
        class="ml-auto flex flex-row gap-x-2.5">
        <BcButton
          icon-left="cross"
          size="small"
          type="outlined"
          @click="closeEdit">
          {{ $t('global.cancel') }}
        </BcButton>
        <BcButton
          color="primary"
          icon-left="check"
          native-type="submit"
          size="small"
          @click="saveCandidate">
          {{ $t('global.save') }}
        </BcButton>
      </div>
    </ValidationObserver>
  </div>
</template>
