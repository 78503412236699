<template>
  <div class="searchbar-filters-buttons">
    <button-default
      @click.native="applySearchQueries"
      bc-background-color="blue-dodger"
      bc-padding="10px 30px"
      bc-radius="50px"
      bc-size-text="13px"
      class="searchbar-filters-buttons__button">
      {{ $t('companies.account-management.display-results') }}
    </button-default>
  </div>
</template>

<script>
  // TODO move or refacto to ui-kit
  import { mapGetters } from 'vuex';
  import ButtonDefault from '@/legacy/ui-kit/components/Button/BcButton';

  export default {
    name: 'searchbar-filters-buttons',
    components: { ButtonDefault },
    computed: {
      ...mapGetters({
        searchQueries: 'searchbarFiltersComputed'
      })
    },
    methods: {
      /**
       * This methods will push a new $route and trigger the search
       */
      async applySearchQueries() {
        this.$router.push({
          path: this.$route.path,
          query: { ...this.$route.query, ...this.searchQueries }
        });
        this.$store.dispatch('setIsActiveFilter', false);
      }
    }
  };
</script>

<style scoped lang="scss">
  .searchbar-filters-buttons {
    flex-direction: column;
    align-items: center;
    @include bp('tablet') {
      flex-direction: row;
      justify-content: space-around;
    }
    &__button {
      margin-bottom: 10px;
      font-size: $font-size-xs;
      text-transform: uppercase;
      font-weight: $font-weight-light;
      &:last-of-type {
        margin-bottom: 0;
      }
      @include bp('tablet') {
        margin-bottom: 0;
      }
    }
  }
</style>
