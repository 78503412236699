<template>
  <component
    :is="panelProcessDetailsComponent"
    v-bind="$attrs"
    v-on="$listeners">
  </component>
</template>

<script>
  import PanelProcessDetailsCoach from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsCoach';
  import PanelProcessDetailsRpo from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsRpo';

  export default {
    name: 'panel-process-details',
    components: { PanelProcessDetailsRpo, PanelProcessDetailsCoach },
    inheritAttrs: false,
    computed: {
      panelProcessDetailsComponent() {
        return this.$store.getters['user/isSuperUser'] ? 'panel-process-details-coach' : 'panel-process-details-rpo';
      },
    },
  };
</script>

<style lang=scss scoped>
  .panel-process-details {
    background: rgba($color-blue-dark-cello, 0.05);
    flex-grow: 1;
    overflow: auto;

    &__container {
      width: 100%;
      flex-direction: column;
      padding: 30px;
      height: fit-content;
    }

    &__wrapper {
      margin-bottom: 20px;
      justify-content: space-between;

      &:last-of-type {
        margin-top: 15px;
        margin-bottom: 0;
      }
    }

    &__notes {
      width: calc(50% - 10px);
    }
  }
</style>
