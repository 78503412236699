export let timelineCoaches = {
  1: {
    description: 'A coach has joined Bluecoders',
    icon: 'user-plus',
    author: {
      type: 'coach',
      after: 'timeline.joined-bluecoders',
    },
  },
  2: {
    description: 'A coach created a company',
    icon: 'partners',
    author: {
      type: 'coach',
      after: 'timeline.created',
    },
    directObject: {
      type: 'company',
    },
  },
  3: {
    description: 'A coach edited a company',
    icon: 'edit',
    author: {
      type: 'coach',
      after: 'timeline.edited',
    },
    directObject: {
      type: 'company',
    },
  },
  4: {
    description: 'A coach deleted a company',
    icon: 'trash',
    author: {
      type: 'coach',
      after: 'timeline.deleted',
    },
    directObject: {
      type: 'company',
    },
  },
  5: {
    description: 'A coach changed a company status',
    icon: 'edit',
    author: {
      type: 'coach',
      after: 'timeline.changed-the-status-of',
    },
    directObject: {
      type: 'company',
    },
  },
  6: {
    description: 'A coach set as favorite a company',
    icon: 'star-full',
    author: {
      type: 'coach',
      after: 'timeline.has-favorited',
    },
    directObject: {
      type: 'company',
    },

  },
  7: {
    description: 'A coach created a note for a company',
    icon: ['note', 'certificate', 'message-circle', 'plus-circle'],
    author: {
      type: 'coach',
      after: ['timeline.created-a-note-for', 'timeline.has-qualifiate', 'timeline.left-a-message-for', 'timeline.created-a-note-for'],
    },
    directObject: {
      type: 'company',
    },
  },
  8: {
    description: 'A coach created a contact for a company',
    icon: 'user-check',
    author: {
      type: 'coach',
      after: 'timeline.created',
    },
    directObject: {
      type: 'contact',
      after: 'generics.for',
    },
    for: {
      type: 'company',
    },
  },
  9: {
    description: 'A coach deleted a contact',
    icon: 'trash',
    author: {
      type: 'coach',
      after: 'timeline.deleted',
    },
    directObject: {
      type: 'contact',
      after: 'generics.of',
    },
    for: {
      type: 'company',
    },
  },
  10: {
    description: 'A coach set as favorite a contact',
    icon: 'star-full',
    author: {
      type: 'coach',
      after: 'timeline.has-favorited',
    },
    directObject: {
      type: 'contact',
      after: 'generics.for',
    },
    for: {
      type: 'company',
    },
  },
  11: {
    description: 'A coach created a job',
    icon: 'suitcase',
    author: {
      type: 'coach',
      after: 'timeline.created',
    },
    directObject: {
      type: 'job',
      after: 'generics.for',
    },
    for: {
      type: 'company',
    },
  },
  12: {
    description: 'A coach edited a job',
    icon: 'note',
    author: {
      type: 'coach',
      after: 'timeline.edited',
    },
    directObject: {
      type: 'job',
      after: 'generics.for',
    },
    for: {
      type: 'company',
    },
  },
  13: {
    description: 'A coach deleted a job',
    icon: 'trash',
    author: {
      type: 'coach',
      after: 'timeline.deleted',
    },
    directObject: {
      type: 'job',
      after: 'generics.for',
    },
    for: {
      type: 'company',
    },
  },
  14: {
    description: 'A coach changed a job status',
    icon: 'edit',
    author: {
      type: 'coach',
      after: 'timeline.changed-the-status-of',
    },
    directObject: {
      type: 'job',
    },
  },
  15: {
    description: 'A coach favorited a job',
    icon: 'star-full',
    author: {
      type: 'coach',
      after: 'timeline.has-favorited',
    },
    directObject: {
      type: 'job',
    },
  },
  16: {
    description: 'A coach created a note for a job',
    icon: ['note', 'certificate', 'message-circle', 'plus-circle'],
    author: {
      type: 'coach',
      after: ['timeline.created-a-note-for', 'timeline.has-qualifiate', 'timeline.left-a-message-for', 'timeline.created-a-note-for'],
    },
    directObject: {
      type: 'job',
    },
  },
  17: {
    description: 'A coach edited a coder',
    icon: 'edit',
    author: {
      type: 'coach',
      after: 'timeline.edited',
    },
    directObject: {
      type: 'coder',
    },
  },
  18: {
    description: 'A coach deleted a coder',
    icon: 'trash',
    author: {
      type: 'coach',
      after: 'timeline.deleted',
    },
    directObject: {
      type: 'coder',
    },
  },
  19: {
    description: 'A coach changed a coder status',
    icon: 'edit',
    author: {
      type: 'coach',
      after: 'timeline.changed-the-status-of',
    },
    directObject: {
      type: 'coder',
    },
  },
  20: {
    description: 'A coach favorited a coder',
    icon: 'star-full',
    author: {
      type: 'coach',
      after: 'timeline.has-favorited',
    },
    directObject: {
      type: 'coder',
    },
  },
  21: {
    description: 'A coach created a note for a coder',
    icon: ['note', 'certificate', 'message-circle', 'plus-circle', 'message-circle', 'message-circle'],
    author: {
      type: 'coach',
      after: ['timeline.created-a-note-for', 'timeline.has-qualifiate', 'timeline.left-a-message-to', 'timeline.created-a-note-for', 'timeline.traded-with', 'timeline.traded-with'],
    },
    directObject: {
      type: 'coder',
    },
  },
  99: {
    description: 'A coach created a reminder for a coder',
    icon: 'calendar-circle-plus',
    author: {
      type: 'coach',
      after: 'timeline.for',
    },
    directObject: {
      type: 'coder',
    },
  },
  22: {
    description: 'A coach created a poke',
    icon: 'plus',
    author: {
      type: 'coach',
      after: 'timeline.created-one',
    },
    directObject: {
      type: 'poke',
      name: 'poke',
      after: 'timeline.between',
    },
    to: {
      type: 'coder',
      after: '&',
    },
    with: {
      type: 'job',
      name: 'name',
    },
  },
  23: {
    description: 'A coach approved a poke',
    icon: 'check',
    author: {
      type: 'coach',
      after: 'timeline.approved-a',
    },
    directObject: {
      type: 'poke',
    },
  },
  24: {
    description: 'A coach declined a poke',
    icon: 'cross',
    author: {
      type: 'coach',
      after: 'timeline.refused-a',
    },
    directObject: {
      type: 'poke',
    },
  },
  25: {
    description: 'A coach reset a poke',
    icon: 'refresh',
    author: {
      type: 'coach',
      after: 'timeline.reset-a',
    },
    directObject: {
      type: 'poke',
    },
  },
  26: {
    description: 'A coach forced a match for a poke',
    icon: 'arrow-right',
    author: {
      type: 'coach',
      after: 'timeline.passes-a',
    },
    directObject: {
      type: 'poke',
      after: 'timeline.in-game',
    },
  },
  27: {
    description: 'A coach created an offer for a poke',
    icon: 'plus',
    author: {
      type: 'coach',
      after: 'timeline.created-an-offer-for',
    },
    directObject: {
      type: 'poke',
    },
  },
  28: {
    description: 'A coach added a note for a poke',
    icon: 'edit',
    author: {
      type: 'coach',
      after: 'timeline.added-a-note-for',
    },
    directObject: {
      type: 'poke',
    },
  },
  29: {
    description: 'A coach added a note on a contact',
    icon: ['note', 'certificate', 'message-circle', 'plus-circle'],
    author: {
      type: 'coach',
      after: ['timeline.created-a-note-for', 'timeline.has-qualifiate', 'timeline.left-a-message-to', 'timeline.created-a-note-for'],
    },
    directObject: {
      type: 'contact',
    },
  },
  30: {
    description: 'A coach created a coder',
    icon: 'user-check',
    author: {
      type: 'coach',
      after: 'timeline.created',
    },
    directObject: {
      type: 'coder',
    },
  },
  31: {
    description: 'A coach invited a contact',
    icon: 'mail',
    author: {
      type: 'coach',
      after: 'timeline.invited',
    },
    directObject: {
      type: 'contact',
    },
  },
  // Removed the action 32 as the feedback in coach doesn't exist anymore
  33: {
    description: 'A coach invited a hunted coder',
    icon: 'plus',
    author: {
      type: 'coach',
      after: 'timeline.invited',
    },
    directObject: {
      type: 'coder',
      after: 'hunted',
    },
  },
  34: {
    description: 'A coach changed a company partnership',
    icon: 'edit',
    author: {
      type: 'coach',
      after: 'timeline.changed-the-status-of',
    },
    directObject: {
      type: 'company',
    },
  },
  35: {
    description: 'A coach reset a pending timer',
    icon: 'refresh',
    author: {
      type: 'coach',
      after: 'timeline.restarted-the-processing-timer-of',
    },
    directObject: {
      type: 'coder',
    },
  },
  36: {
    description: 'A coach has unaborted a poke',
    icon: 'arrow-right',
    author: {
      type: 'coach',
      after: 'timeline.raised-a-poke',
    },
    directObject: {
      type: 'poke',
    },
  },
  37: {
    description: 'A coach has edited a contact',
    icon: 'edit',
    author: {
      type: 'coach',
      after: 'timeline.edited',
    },
    directObject: {
      type: 'contact',
    },
  },
  38: {
    description: 'A coach has edited a partner plan',
    icon: 'edit',
    author: {
      type: 'coach',
      after: 'timeline.edited-the-plan-of',
    },
    directObject: {
      type: 'company',
    },
  },
  39: {
    description: 'A coach has reset a job online',
    icon: 'refresh',
    author: {
      type: 'coach',
      after: 'timeline.restarted-the-timer',
    },
    directObject: {
      type: 'job',
    },
  },
  40: {
    description: 'A coach has deleted a poke of a coder',
    icon: 'trash',
    author: {
      type: 'coach',
      after: 'timeline.removed-poke-from',
    },
    directObject: {
      type: 'coder',
    },
  },
  41: {
    description: 'Mr. Meeseeks invited a coder to join Webjobs',
    icon: 'mail',
    author: {
      type: 'coach',
      after: 'timeline.invited-to-join-Webjobs',
    },
    directObject: {
      type: 'coder',
    },
  },
  42: {
    description: 'A coach has uncoached a coder',
    icon: 'cross',
    author: {
      type: 'coach',
      after: 'timeline.canceled-the-coaching-of',
    },
    directObject: {
      type: 'coder',
    },
  },
  43: {
    description: 'A coach has reseted a coder status',
    icon: 'refresh',
    author: {
      type: 'coach',
      after: 'timeline.reset-the-default-status-to',
    },
    directObject: {
      type: 'coder',
    },
  },
  44: {
    description: 'A coach has coached a coder',
    icon: 'addedtolist',
    author: {
      type: 'coach',
      after: 'timeline.had-put',
    },
    directObject: {
      type: 'coder',
    },
    to: {
      after: 'timeline.in-his-coaching-list',
    },
  },
  45: {
    description: 'A coach has created a note for a process',
    icon: ['note', 'certificate', 'message-circle', 'plus-circle'],
    author: {
      type: 'coach',
      after: ['timeline.created-a-note-for', 'timeline.has-qualifiate', 'timeline.left-a-message-to', 'timeline.created-a-note-for'],
    },
    directObject: {
      type: 'process',
    },
  },
  46: {
    description: 'A coach has created a process',
    icon: 'plus',
    author: {
      type: 'coach',
      after: 'timeline.has-created-a-process-for',
    },
    directObject: {
      type: 'coder',
    },
  },
  47: {
    description: 'A coach has failed a process',
    icon: 'cross',
    iconColor: 'error',
    author: {
      type: 'process',
      text: 'timeline.process-failure',
      after: 'timeline.blank-of',
    },
    directObject: {
      type: 'coder',
    },
  },
  48: {
    description: 'A coach has canceled a failed process',
    icon: 'cross',
    iconColor: 'grey-4',
    author: {
      type: 'process',
      after: 'timeline.process-failure',
    },
    from: {
      type: 'process',
      text: 'timeline.cancel',
    },
  },
  49: {
    description: 'A coach has introduced a coder',
    icon: 'timeline-introduced',
    iconColor: 'secondary-light',
    author: {
      type: 'coder',
      after: 'timeline.has-been',
    },
    directObject: {
      type: 'process',
      text: 'timeline.present',
    },
  },
  50: {
    description: 'A coach has put in process a coder',
    icon: 'timeline-processed',
    iconColor: 'secondary',
    author: {
      type: 'coder',
      after: 'timeline.is-in',
    },
    directObject: {
      type: 'process',
      text: 'timeline.process-course',
    },
  },
  51: {
    description: 'A coach has finalised a process',
    icon: 'timeline-finalised',
    iconColor: 'primary-dark',
    author: {
      type: 'coder',
      after: 'timeline.is-in',
    },
    directObject: {
      type: 'process',
      text: 'timeline.final-interview',
    },
  },
  52: {
    description: 'A coach has put an offer on a process',
    icon: 'timeline-finalised',
    iconColor: 'primary-dark',
    author: {
      type: 'coder',
      after: 'timeline.final-interview',
    },
    directObject: {
      type: 'process',
      text: 'timeline.an-offer',
    },
  },
  53: {
    description: 'A coach has made a deal on a process',
    icon: 'timeline-dealt',
    iconColor: 'primary',
    author: {
      type: 'coder',
      after: 'timeline.is-in',
    },
    directObject: {
      type: 'process',
      text: 'process.waiting-to-start',
    },
  },
  54: {
    description: 'A coach has dropped a deal',
    icon: 'timeline-drop',
    iconColor: 'error',
    author: {
      type: 'process',
      after: 'timeline.drop-of',
    },
    from: {
      type: 'process',
      text: 'process.deal',
    },
  },
  55: {
    description: 'A coach has canceled a drop',
    icon: 'timeline-drop',
    iconColor: 'grey-4',
    author: {
      type: 'process',
      after: 'timeline.drop',
    },
    from: {
      type: 'process',
      text: 'timeline.cancel',
    },
  },
  56: {
    description: 'A coach has canceled a introduce',
    icon: 'timeline-introduced',
    iconColor: 'grey-4',
    author: {
      type: 'process',
      after: 'timeline.presentation',
    },
    from: {
      type: 'process',
      text: 'timeline.cancel',
    },
  },
  57: {
    description: 'A coach saw a coder',
    icon: 'eye',
    iconColor: 'primary',
    author: {
      type: 'coach',
      after: 'timeline.saw',
    },
    directObject: {
      type: 'coder',
    },
  },
  58: {
    description: 'A coach moved a coder in pipe',
    icon: 'arrow-right',
    author: {
      type: 'coach',
      after: 'timeline.moved-in-his-pipe',
    },
    directObject: {
      type: 'coder',
    },
  },
  59: {
    description: 'A coach kicked a coder from pipe',
    icon: 'trash',
    author: {
      type: 'coach',
      after: 'timeline.removed-from-his-pipe',
    },
    directObject: {
      type: 'coder',
    },
  },
  60: {
    description: 'A coach validated the coder start',
    icon: 'timeline-onboarding',
    iconColor: 'primary-light',
    author: {
      type: 'coder',
      after: 'timeline.has',
    },
    directObject: {
      type: 'process',
      text: 'timeline.started',
    },
  },
  61: {
    description: 'A coach validated the coder onboarding',
    icon: 'timeline-validated',
    iconColor: 'deal',
    author: {
      type: 'coder',
      after: 'timeline.finished-his',
    },
    directObject: {
      type: 'process',
      text: 'timeline.risk-period',
    },
  },
  62: {
    description: 'A coach has exported a list to get contacts emails',
    icon: 'upload',
    author: {
      type: 'coach',
      after: 'timeline.exported-the-list-candidates',
    },
    directObject: {
      type: 'list',
    },
  },
  63: {
    description: 'A coach logged as another coach',
    icon: 'eye',
    author: {
      type: 'coach',
      after: 'timeline.logged-into-the-account-of',
    },
    directObject: {
      type: 'secondCoach',
    },
  },
  64: {
    description: 'An another coach has logged on this account',
    icon: 'eye',
    author: {
      type: 'secondCoach',
      after: 'timeline.logged-into-your-account',
    },
  },
  65: {
    description: 'A coach hooked a coder',
    icon: 'eye',
    author: {
      type: 'coder',
      after: 'timeline.was-found-by',
    },
    directObject: {
      type: 'coach',
      after: 'timeline.from',
    },
  },
  66: {
    description: 'A coach asked for a company contacts info',
    icon: 'cpu',
    author: {
      type: 'coach',
      after: 'timeline.asked-for-the-contact-details-of',
    },
    directObject: {
      type: 'company',
    },
  },
  67: {
    description: 'A coach took ownership of a company',
    icon: 'flag',
    author: {
      type: 'coach',
      after: 'timeline.took-charge',
    },
    directObject: {
      type: 'company',
    },
  },
  68: {
    description: 'A coach removed ownership of a company',
    icon: 'flag',
    author: {
      type: 'coach',
      after: 'timeline.took-charge',
    },
    directObject: {
      type: 'company',
    },
  },
  69: {
    description: 'A coach created an opportunity for a company from Marvin Recruiter app',
    icon: 'suitcase',
    author: {
      type: 'coach',
      after: 'timeline.created',
    },
    directObject: {
      type: 'hunt',
      after: 'generics.for',
    },
    for: {
      type: 'company',
      after: 'timeline.from-marvin-recruiter',
    },
  },
  70: {
    description: 'A coach edited a hunt',
    icon: 'note',
    author: {
      type: 'coach',
      after: 'timeline.edited',
    },
    directObject: {
      type: 'hunt',
      after: 'generics.for',
    },
    for: {
      type: 'company',
    },
  },
  71: {
    description: 'A coach deleted a hunt',
    icon: 'trash',
    author: {
      type: 'coach',
      after: 'timeline.deleted',
    },
    directObject: {
      type: 'hunt',
      after: 'generics.for',
    },
    for: {
      type: 'company',
    },
  },
  72: {
    description: 'A coach created a note for a hunt',
    icon: ['note', 'certificate', 'message-circle', 'plus-circle', 'certificate'],
    author: {
      type: 'coach',
      after: ['timeline.created-a-note-for', 'timeline.has-qualifiate', 'timeline.left-a-message-for', 'timeline.created-a-note-for', 'timeline.updated-the-recruitment-process-for'],
    },
    directObject: {
      type: 'hunt',
    },
  },
  74: {
    description: 'A coach asked for a coder contacts info',
    icon: 'cpu',
    author: {
      type: 'coach',
      after: 'timeline.asked-for-contact-details',
    },
    directObject: {
      type: 'coder',
    },
  },
  75: {
    description: 'A coach updated a hunt status',
    icon: 'note',
    author: {
      type: 'coach',
      after: 'timeline.changed-the-status-of',
    },
    directObject: {
      type: 'hunt',
    },
  },
  76: {
    description: 'A process payment status has been updated',
    icon: 'dollars',
    author: {
      type: 'company',
      after: 'timeline.changed-the-status-of',
    },
  },
  77: {
    description: 'A process payment status has been updated',
    icon: 'clock',
    author: {
      type: 'company',
      after: 'timeline.changed-the-status-of',
    },
  },
  78: {
    description: 'A process payment status has been updated',
    icon: 'edit',
    author: {
      type: 'company',
      after: 'timeline.changed-the-status-of',
    },
  },
  79: {
    description: 'A coach created a suggestion for a coder',
    icon: 'plus',
    author: {
      type: 'coach',
      after: 'timeline.added',
    },
    directObject: {
      type: 'coder',
      after: 'timeline.to-his-pond',
    },
  },
  82: {
    description: 'A coach accept a suggestion for a coder',
    icon: 'plus',
    author: {
      type: 'coach',
      after: 'timeline.has-accept-candidate-suggestion',
    },
    directObject: {
      type: 'coder',
    },
  },
  83: {
    description: 'A coach reject a suggestion for a coder',
    icon: 'trash',
    author: {
      type: 'coach',
      after: 'timeline.has-reject-candidate-suggestion',
    },
    directObject: {
      type: 'coder',
    },
  },
  84: {
    description: 'A coach post pone a suggestion for a coder',
    icon: 'clock',
    author: {
      type: 'coach',
    },
    directObject: {
      type: 'coder',
    },
  },
  85: {
    description: 'A coach transfer a suggestion',
    icon: 'corner-up-right',
    author: {
      type: 'coach',
    },
    directObject: {
      type: 'coder',
    },
  },
  80: {
    description: 'A coach uploaded a document for a coder',
    icon: 'upload',
    author: {
      type: 'coach',
      after: 'timeline.added',
    },
    directObject: {
      type: 'coder',
      after: 'timeline.to-his-pond',
    },
  },
  81: {
    description: 'A coach uploaded a document for a custom job',
    icon: 'upload',
    author: {
      type: 'coach',
      after: 'timeline.added',
    },
    directObject: {
      type: 'coder',
      after: 'timeline.to-his-pond',
    },
  },
  86: {
    description: 'A coach removed a note on resource',
    icon: 'trash',
    author: {
      type: 'coach',
      after: 'timeline.has-removed-note',
    },
  },
  87: {
    description: 'A coach edited a coder as contact',
    icon: 'edit',
    author: {
      type: 'coach',
      after: 'timeline.edited',
    },
    directObject: {
      type: 'coder',
      after: 'timeline.as-contact',
    },
  },
  88: {
    description: 'A coach edited a coder as candidate',
    icon: 'edit',
    author: {
      type: 'coach',
      after: 'timeline.edited',
    },
    directObject: {
      type: 'coder',
      after: 'timeline.as-candidate',
    },
  },
  89: {
    description: 'A coach edited a coder as contact and candidate',
    icon: 'edit',
    author: {
      type: 'coach',
      after: 'timeline.edited',
    },
    directObject: {
      type: 'coder',
      after: 'timeline.as-contact-and-candidate',
    },
  },
  90: {
    description: 'A coach has created a process',
    icon: 'plus',
    author: {
      type: 'coach',
      after: 'timeline.has-created-a-process-for',
    },
    directObject: {
      type: 'coder',
    },
  },
  91: {
    description: 'A coach update process of a coder',
    icon: 'timeline-processed',
    iconColor: 'secondary',
    author: {
      type: 'coder',
      after: 'activities.is-at-step',
    },
    directObject: {
      type: 'processCustom',
      text: '',
    },
  },
  92: {
    description: 'A coach abort process of a coder',
    icon: 'timeline-processed-aborted',
    iconColor: 'secondary',
    author: {
      type: 'coder',
      after: 'timeline.process-failure',
    },
    directObject: {
      type: 'processCustom',
      text: '',
    },
  },
  93: {
    description: 'A coach unabort process of a coder',
    icon: 'timeline-processed',
    iconColor: 'secondary',
    author: {
      type: 'coder',
      after: 'timeline.cancel-process-failure',
    },
    directObject: {
      type: 'processCustom',
      text: '',
    },
  },
  94: {
    description: 'A coach deal a process of a coder',
    icon: 'target2',
    iconColor: 'deal',
    author: {
      type: 'coder',
      after: 'timeline.cancel-process-failure',
    },
    directObject: {
      type: 'processCustom',
      text: '',
    },
  },
  95: {
    description: 'A coach transfer a suggestion',
    author: {
      type: 'coach',
    },
  },
  96: {
    description: 'A coach transfer a suggestion',
    author: {
      type: 'coach',
    },
  },
  221: {
    description: 'A coder deleted his profile',
    icon: 'trash',
    author: {
      type: 'coder',
      after: 'timeline.deleted-his-profile',
    },
  },
  222: {
    description: 'Marvin suggested a coder',
    icon: 'crosshair',
    author: {
      type: 'coach',
      after: 'timeline.suggested',
    },
    directObject: {
      type: 'coder',
    },
  },
  1000: {
    description: 'A coach send an email',
    icon: 'mail',
    author: {
      type: 'coach',
      after: 'timeline.has-favorited',
    },
    directObject: {
      type: 'coder',
    },
  },
  1001: {
    description: 'A coach send a group emails for companies',
    icon: 'mail',
    author: {
      type: 'coach',
      after: 'timeline.has-favorited',
    },
    directObject: {
      type: 'coder',
    },
  },
  250: {
    description: 'A coach created a coder from LinkedIn',
    icon: 'user-check',
    author: {
      type: 'coach',
      after: 'timeline.created',
    },
    directObject: {
      type: 'coder',
      after: 'timeline.from-linkedin',
    },
  },
  251: {
    description: 'A coach created a coder from Marvin Recruiter',
    icon: 'user-check',
    author: {
      type: 'coach',
      after: 'timeline.created',
    },
    directObject: {
      type: 'coder',
      after: 'timeline.from-marvin-recruiter',
    },
  },
  252: {
    description: 'A coach created a coder from their resume',
    icon: 'user-check',
    author: {
      type: 'coach',
      after: 'timeline.created',
    },
    directObject: {
      type: 'coder',
      after: 'timeline.from-their-resume',
    },
  },
  253: {
    description: 'A coach created a company from LinkedIn',
    icon: 'partners',
    author: {
      type: 'coach',
      after: 'timeline.created',
    },
    directObject: {
      type: 'company',
      after: 'timeline.from-linkedin',
    },
  },
  254: {
    description: 'A coach created a company from Marvin Recruiter app',
    icon: 'partners',
    author: {
      type: 'coach',
      after: 'timeline.created',
    },
    directObject: {
      type: 'company',
      after: 'timeline.from-marvin-recruiter',
    },
  },

};
