import colors from '@/common-old/assets/scss/helpers/_colors.module.scss';

export default {
  $_getColor(color, alpha = 1) {
    if (/^[#]/.test(color)) {
      let rgbColor = this.$_convertHexToRgb(color);
      color = `rgba(${rgbColor.red},${rgbColor.green},${rgbColor.blue}, ${alpha})`;
    } else {
      color = color.replace(/\)$/, `,${alpha})`);
    }
    return color;
  },
  $_convertHexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      red: parseInt(result[1], 16),
      green: parseInt(result[2], 16),
      blue: parseInt(result[3], 16)
    } : null;
  },
  $_checkValidColor(value, alpha = 1) {
    const computedColors = colors[value] ? colors[value] : value;
    return this.$_getColor(computedColors, alpha);
  },
};
