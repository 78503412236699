<script setup lang="ts">
import { ref, watch, computed, defineEmits, watchEffect } from 'vue';
import debounce from 'debounce';
import { useRoute, useRouter } from 'vue-router/composables';

import BcInput from '@/ui-kit/components/BcInput/BcInput';
import FilterContent from '@/components/Filter/FilterContent';
import Tag from '@/components/Tag/Tag';

const emits = defineEmits<{
  (e: 'count', count: { key: string; count: number }): void,
}>();

const search = ref('');
const route = useRoute();
const router = useRouter();

const uniqueIdTags = computed(() => {
  return route.query.uniqueids?.split(',');
});

const searchUniqueId = debounce(function(value: string) {
  let uniqueIds = [];
  if (route.query.uniqueids) {
    uniqueIds = route.query.uniqueids?.split(',');
  }

  if (uniqueIds.includes(value)) {
    uniqueIds = uniqueIds.filter(uniqueid => uniqueid !== value);
  } else {
    uniqueIds.push(value);
  }

  router.push({
    query: {
      ...route.query,
      uniqueids: uniqueIds.length ? uniqueIds.join(',') : undefined,
    }
  });

  search.value = '';
}, 500, false);

const onRemoveTag = (uniqueid: string) => {
  const uniqueIds = uniqueIdTags.value.filter(id => id !== uniqueid);
  router.push({
    query: {
      ...route.query,
      uniqueids: uniqueIds.length ? uniqueIds.join(',') : undefined,
    }
  });
}

watch(search, (value) => {
  if (value) {
    searchUniqueId(value);
  }
});

watchEffect(() => {
  emits('count', uniqueIdTags.value?.length ? { key: 'uniqueids', count: 1 } : { key: 'uniqueids', count: 0 });
})

</script>

<template>
  <FilterContent class="filter-uniqueid">
    <BcInput
      name="uniqueid-filter"
      label="Recherche par numéro d’identification"
      icon="search"
      :labelPlaceholder="false"
      placeholder="Exemple : 1234567"
      v-model="search"
    ></BcInput>
    <div class="mt-2 gap-2">
      <tag
        v-for="uniqueid in uniqueIdTags"
        :key="uniqueid"
        :text="uniqueid"
        :canClose="true"
        @click="onRemoveTag(uniqueid)"
      />
    </div>
  </FilterContent>
</template>
