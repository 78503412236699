<template>
  <div class="panel-organization-configuration-item">
    <p class="panel-organization-configuration-item__title">{{ title }}</p>
    <div class="panel-organization-configuration-item__content" :style="contentStyle">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'panel-organization-configuration-item',
  props: {
    title: {
      type: String,
      default: '',
    },
    contentStyle: {
      type: String,
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-organization-configuration-item {
  flex-direction: column;
  width: 100%;

  &__title {
    color: $color-primary;
    font-weight: 700;
  }

  &__content {
    width: 100%;
    padding: 22px 12px;
    background: $color-neutre-3;
    border-radius: 5px;
    margin-top: 10px;
    color: $color-blue-dark-cello;
  }
}
</style>
