<script lang="ts" setup>
  import { capitalize } from '@/utils/stringTransform';

  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger.vue';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
  import {
    useCampaignsNextConditions,
  } from '@/components/Campaigns/composables/useCampaignsNextConditions';
  import CampaignsConditionsDelay
    from '@/components/Campaigns/CampaignsConditions/CampaignsConditionsDelay.vue';
  import {
    CampaignNextCondition,
    CampaignNextConditionKey,
  } from '@/components/Campaigns/CampaignsTypes';
  import { DelayData } from '@/domains/models/Campaign';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { computed } from 'vue';

  const props = defineProps<{
    availableConditions: CampaignNextConditionKey[];
    selectedConditions: CampaignNextCondition[];
  }>();

  const {
    conditionsOptions: nextConditionsOptions,
  } = useCampaignsNextConditions({
    conditions: props.availableConditions,
  });

  const filteredConditionsOptions = computed(() => {
    return nextConditionsOptions.value.filter(condition => !props.selectedConditions.some(selectedCondition => selectedCondition.key === condition.key));
  });

  const emit = defineEmits<{
    (e: 'update-condition', value: { condition: CampaignNextCondition, index: number }): void
    (e: 'add-condition', value: CampaignNextCondition): void
    (e: 'remove-condition', value: CampaignNextCondition): void
  }>();

  function setSelectedCondition(condition: CampaignNextCondition, index: number) {
    if (condition.key === 'DELAY') {
      emit('update-condition', {
        condition: {
          ...condition,
          delay: condition.delay,
        },
        index,
      });
    } else {
      emit('update-condition', { condition, index });
    }
  }

  function handleDelayChange({ condition, value, index }: { 
    condition: CampaignNextCondition; 
    value: DelayData; 
    index: number 
  }) {
    emit('update-condition', {
      condition: {
        ...condition,
        delay: value,
      },
      index,
    });
  }

  function addCondition() {
    emit('add-condition', filteredConditionsOptions.value[0]);
  }

  function removeCondition(condition: CampaignNextCondition) {
    emit('remove-condition', condition);
  }
</script>

<template>
  <div class="flex min-w-[500px] items-end">
    <div class="flex w-full flex-col gap-2.5">
      <p class="text-lg font-bold text-blue-800 first-letter:capitalize">
        {{ $t('campaigns.condition-for-next-step') }}
      </p>
      <div class="flex flex-col">
        <div
          v-for="(selectedCondition, selectedConditionIndex) in selectedConditions"
          :key="selectedCondition.key"
          class="relative flex w-full">
          <div
            v-if="selectedConditions.length > 1"
            :class="{ 'invisible': selectedConditionIndex === selectedConditions.length - 1 }"
            class="relative top-[20px] flex gap-1">
            <p class="relative top-[65px] uppercase text-blue-400">{{ $t('generics.or') }}</p>
            <span
              class="relative top-[35px] h-[75px] w-[20px] rounded-l border border-r-0 border-blue-200"/>
          </div>
          <div class="flex w-full items-end gap-2.5">
            <BcDropdown
              :disabled="!availableConditions.length"
              :options="filteredConditionsOptions"
              class="w-full min-w-[300px]"
              is-expanded>
              <template #label="{ isDropdownOpen }">
                <BcDropdownTrigger
                  :is-arrow="true"
                  :is-dropdown-open="isDropdownOpen"
                  :is-label-placeholder="false"
                  :label="capitalize('condition')"
                  :text="capitalize($t(selectedCondition.name))">
                </BcDropdownTrigger>
              </template>
              <template #option="{ option, index, activeIndex }">
                <BcDropdownItem
                  :is-focus="activeIndex === index"
                  :text="capitalize($t(option.name))"
                  class="is-expanded"
                  @click.native="setSelectedCondition(option, selectedConditionIndex)">
                </BcDropdownItem>
              </template>
            </BcDropdown>
            <CampaignsConditionsDelay
              v-if="selectedCondition.key === 'DELAY'"
              :model-value="selectedCondition.delay ?? {}"
              class="shrink-0"
              @update:modelValue="handleDelayChange({
                condition: selectedCondition,
                value: $event,
                index: selectedConditionIndex
              })"
            />
            <BcButton
              v-if="selectedConditionIndex < selectedConditions.length - 1 || !filteredConditionsOptions.length"
              class="ml-auto shrink-0"
              icon-left="minus"
              type="outlined"
              @click.native="removeCondition(selectedCondition)"
            />
            <BcButton
              v-else-if="filteredConditionsOptions.length"
              class="ml-auto shrink-0"
              icon-left="plus"
              type="outlined"
              @click.native="addCondition"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
