<template>
  <div style="overflow:auto;">
    <div class="processes-columns-custom" ref="processescolumns">
      <div
        class="processes-columns-custom__wrapper"
        ref="columnWrapper"
        v-for="(category, categoryIndex) in configurationProcess.categories"
        :key="category.key"
      >
        <div class="processes-columns-custom__body">
          <ProcessColumnCustom
            v-for="(step, stepIndex) in category.steps"
            class="processes-columns-custom__step"
            :key="step.key"
            :categoryIndex="categoryIndex"
            :is-dragging="isDragging"
            :read-only="readonly"
            :step="{
              name: step.name,
              categoryKey: category.key,
              stepKey: step.key,
              email: step.email,
              processes: getProcesses(category.key, step.key),
              style: headerStyle
            }"
            :step-name="step.name"
            :style="{ color: categoriesColor[category.key] }"
            :card-type="cardType"
            :total-amount="getTotalAmount(category.key, step.key)"
            :show-amount="configurationProcess.hasAmount && showAmount"
            :is-first="stepIndex === 0"
            :is-last="stepIndex === category.steps.length - 1"
            @dragging="$emit('dragging', $event)"
            @input="$emit('input', $event)"
            @accept-process="$emit('accept-process', $event)"
            @reject-process="$emit('reject-process', $event)"
            @card-dragged="$emit('card-dragged', $event)"
            @move-process="$emit('move-process', $event)"
            @delete-process="$emit('delete-process', $event)"
            @abort-process="$emit('abort-process', $event)"
            @unabort-process="$emit('unabort-process', $event)"
            @process-update="$emit('process-update', $event)"
            @profile-updated="$emit('profile-updated', $event)"
            @set-modal-type="$emit('set-modal-type', $event)"
            @dragover="dragover"
            @drag-warning="$emit('drag-warning', $event)"
          >
            <slot name="content" :categoryIndex="categoryIndex" :stepIndex="stepIndex"></slot>
          </ProcessColumnCustom>
          <slot name="body"></slot>
        </div>
      </div>

      <!-- FAILED PROCESSES -->
      <div
        v-if="showFailed"
        class="processes-columns-custom__wrapper"
      >
        <div class="processes-columns-custom__body">
          <ProcessColumnCustom
            class="processes-columns-custom__step"
            :read-only="readonly"
            :step="{
              name: $t('config.failedProcessRecruitment'),
              categoryKey: 'failed',
              stepKey: 'failed-step',
              processes: failedProcesses,
              style: headerStyle,
              icon: openInNew,
              iconLink: `/failure-coders?page=1${selectedCoach._id ? '&coachId='+selectedCoach._id : '' }`
            }"
            step-name="failedddd"
            :style="{ color: categoriesColor.failed }"
            :card-type="cardType"
            :total-amount="getFailedTotalAmount()"
            :show-amount="configurationProcess.hasAmount && showAmount"
          />
        </div>
      </div>
      <!-- FAILED PROCESSES -->
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

import ProcessColumnCustom from '@/components/Process/ProcessColumnCustom.vue';
import colors from '@/ui-kit/assets/scss/exports/_colors.module.scss';
import openInNew from '@/assets/img/open_in_new.svg';

export default {
  name: 'processes-columns-custom',
  components: {
    // BcButton,
    ProcessColumnCustom
  },
  props: {
    isDragging: {
      type: Boolean,
      default: false
    },
    processes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    failedProcesses: {
      type: Array,
      default: () => {
        return [];
      }
    },
    readonly: {
      type: Boolean,
      default: false
    },
    cardType: {
      type: String,
      default: 'process-card-coders'
    },
    showAmount: {
      type: Boolean,
      default: false,
    },
    showFailed: {
      type: Boolean,
      default: false,
    },
    selectedCoach: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.calculateHeaderHeight();
  },
  data() {
    return {
      headerHeight: undefined,
      previousDragId: undefined,
      categoriesColor: {
        beforeCandidatePresentation: colors['secondary'],
        beforeProcessRecruitment: colors['primary'],
        afterProcessRecruitment: colors['green-bright'],
        failed: colors['red-mandy']
      },
      openInNew: openInNew
    };
  },
  computed: {
    ...mapGetters({
      configurationProcess: 'user/configurationProcess'
    }),
    headerStyle() {
      return {
        header: {
          height: this.headerHeight,
          alignItems: 'center'
        }
      };
    },
    processesBySteps() {
      return this.processes.reduce((acc, process) => {
        const currentStep = process.historySteps[process.historySteps.length - 1];
        if (typeof currentStep !== 'object') {
          return acc;
        }
        const abortedAtKey = process.abortedAt ? '/aborted' : '';
        const key = `${currentStep.categoryKey}/${currentStep.stepKey}${abortedAtKey}`;
        const amount = process.commissionCustom || process.commission || 0;
        if (!acc[key]) {
          return {
            ...acc,
            [key]: { processes: [process], totalAmount: amount }
          };
        }
        return {
          ...acc,
          [key]: {
            processes: [...acc[key].processes, process],
            totalAmount: acc[key].totalAmount + amount
          }
        };
      }, {});
    },
  },
  methods: {
    calculateHeaderHeight() {
      if (!this.$refs.columnWrapper) {
        return;
      }
      let maxOffsetHeight = 0;
      for (const wrapper of this.$refs.columnWrapper) {
        for (const column of wrapper.children[0].children) {
          const header = column.children[0];
          if (header.offsetHeight > maxOffsetHeight) {
            maxOffsetHeight = header.offsetHeight;
          }
        }
      }
      this.headerHeight = maxOffsetHeight + 'px';
    },
    getProcesses(categoryKey, stepKey) {
      let processes = this.processesBySteps[`${categoryKey}/${stepKey}`]?.processes || []
      const abortedProcesses = this.processesBySteps[`${categoryKey}/${stepKey}/aborted`]?.processes || []
      processes.sort(this.sortProcesses)
      abortedProcesses.sort(this.sortProcesses)

      if (abortedProcesses) {
        processes = processes.concat(abortedProcesses)
      }
      return processes;
    },
    sortProcesses(a, b) {
      return new Date(a.lastCoachActivity).getTime() - new Date(b.lastCoachActivity).getTime()
    },
    getTotalAmount(categoryKey, stepKey) {
      return this.processesBySteps[`${categoryKey}/${stepKey}`]?.totalAmount || 0
    },
    getFailedTotalAmount() {
      return this.failedProcesses.reduce((acc, process) => {
        const amount = process.commissionCustom || process.commission || 0;
        return acc + amount
      }, 0)
    },
    dragover(event) {
      if (this.readonly || !this.isDragging) {
        return;
      }
      if (event.target.id !== this.previousDragId && event.screenX <= 700) {
        event.target.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"})
        this.previousDragId = event.target.id
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$border: 1px solid $color-blue-light;

.processes-columns-custom {
  border-radius: $border-radius-s;
  padding: 20px 0px 10px 0px;

  &__wrapper {
    flex-direction: column;
    border: $border;
    width: 100%;
    border-radius: $border-radius-s;
    height: 100%;
    margin: 0 15px;
  }

  &__body {
    height: 100%;
    border-bottom: $border;
    border-radius: $border-radius-s;
  }

  &__step {
    width: 100%;
    border-right: $border;
    min-width: 240px;

    &:last-of-type {
      border-right: 0;
    }
  }

  &__button {
    background-color: transparent;
    width: calc(100% - 20px);
    margin: 10px;
  }

  &__modal {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
