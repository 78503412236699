import { isSupported } from './firebase.js';

function requestPermission(cb) {
  if (!isSupported()) return;

  Notification?.requestPermission().then((permission) => {
    if (permission === 'granted') {
      cb();
    } else {
      console.log('Do not have permission!');
    }
  });
}

if (isSupported()) {
  requestPermission(() => {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then(reg => {
      console.log(`Service Worker Registration (Scope: ${reg.scope})`);
    })
    .catch(error => {
      const msg = `Service Worker Error (${error})`;
      console.error(msg);
    });
  });
} else {
  // happens when the app isn't served over HTTPS or if the browser doesn't support service workers
  console.warn('Service Worker not available');
}
