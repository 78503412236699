export const apiTypes = {
  contract: 1,
  amendment: 2,
  resignation: 3,
};

export const typeOptions = [
  {
    name: 'panel-coder-edit.contract',
    api: apiTypes.contract,
    query: 'contract',
  },
  {
    name: 'panel-coder-edit.addendum',
    api: apiTypes.amendment,
    query: 'amendment',
  },
  {
    name: 'panel-coder-edit.letter-of-resignation',
    api: apiTypes.resignation,
    query: 'resignation',
  },
];

export default {
  typeOptions,
  apiTypes,
};
