<script lang="ts" setup>
  import { Company } from '@/domains/models/Company';
  import { createProcess } from '@/managers/processes-custom/controller';
  import { computed, defineEmits, defineProps, Ref, ref, watch } from 'vue';
  import { Opportunity } from '@/domains/models/Opportunity';
  import { useQuery } from '@tanstack/vue-query';
  import { getCustomJobs, salaryLabelsShort } from '@/api/custom-jobs';
  import { useRoute, useRouter } from 'vue-router/composables';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete.vue';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { useI18n } from '@/i18n/i18n';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import fallbackImg from '@/assets/img/default-avatar.svg';
  import { Process } from '@/domains/models/Process';
  import { Candidate } from '@/domains/models/Candidate';
  import { debounce } from '@/common-old/mixins/Debounce/debounce';
  import companyHttp from '@/api/company';

  interface Props {
    candidate: Candidate;
  }

  const props = defineProps<Props>();
  const emit = defineEmits<{
    (e: 'close-edit', value: false): void;
    (e: 'create-process', value: Process): void;
  }>();

  const router = useRouter();
  const route = useRoute();
  const toast = useToast();
  const i18n = useI18n();

  // companies
  const companiesModelValue = ref<string>('');
  const companiesOptions: Ref<Opportunity[]> = ref([]);
  const selectedCompany = ref<Company | {}>({});
  const setSelectedCompany = (value: Company) => {
    selectedCompany.value = value;
  };
  const removeCompany = () => {
    selectedCompany.value = {};
  };

  const getCompaniesSuggestions = debounce(async function() {
    try {
      const { data } = await companyHttp.partnersAutocomplete(companiesModelValue.value);

      companiesOptions.value = data;
    } catch (error) {
      toast.show({
        type: 'error',
        message: i18n.t('toast.error-occured'),
      });
    }
  }, 500);

  watch(() => companiesModelValue.value, () => {
    getCompaniesSuggestions();
  });

  // opportunities
  const opportunitiesModelValue = ref<string>('');
  const opportunitiesOptions: Ref<Opportunity[]> = ref([]);
  const selectedOpportunity: Ref<Partial<Opportunity>> = ref({});

  const filteredCustomJobs = computed(() => {
    if (!opportunitiesModelValue.value) {
      let jobs = [...opportunitiesOptions.value];
      jobs.sort((a, b) => a.nbActiveProcesses - b.nbActiveProcesses);
      if (jobs.length > 5) {
        jobs = jobs.slice(0, 5);
      }
      return jobs;
    }
    return opportunitiesOptions.value?.filter(job => job.title?.toLowerCase().includes(opportunitiesModelValue.value.toLowerCase()));
  });

  const getOpportunitiesSuggestions = async() => {
    try {
      const { data } = await getCustomJobs({ companyId: selectedCompany.value?._id, active: true });
      opportunitiesOptions.value = data.jobs;
    } catch (error) {
      console.error(error);
    }
  }

  watch(() => selectedCompany.value._id, () => {
    if (selectedCompany.value._id) {
      getOpportunitiesSuggestions();
    } else {
      companiesOptions.value = [];
    }
  });
  const setSelectedOpportunity = (value: Opportunity) => {
    selectedOpportunity.value = value;
  };
  const removeOpportunity = () => {
    selectedOpportunity.value = {};
  };

  // process create
  const createNewProcess = async() => {
    try {
      const { data } = await createProcess({
        companyId: selectedCompany.value?._id,
        customJobId: selectedOpportunity.value._id,
        coderId: props.candidate._id,
      });
      emit('create-process', data);
      emit('close-edit', false);
    } catch (error) {
      console.error(error);
    }
  };
  const openCustomJobCreatePanel = () => {
    router.push({
      ...route,
      params: {
        id: 'new-custom-jobs',
        from: 'panel',
        initData: {
          companyId: selectedCompany.value._id,
          selectedCompany: selectedCompany.value,
        },
      },
      query: {
        ...route.query,
        type: 'create-custom-jobs',
        from: 'create-process',
      },
    });
  };

  const setAlternativeImg = (event: any) => {
    event.target.src = fallbackImg;
  };
</script>

<template>
  <div class="flex w-full flex-col gap-5 rounded-md bg-neutral-200 px-5 py-3.5">
    <div class="flex flex-col items-center gap-5 bg-white px-5 py-8">
      <div class="flex items-center">
        <i
          class="icon-companies mr-2.5 size-[16px] text-blue-800"
        />
        <h2 class="uppercase text-blue-800">
          {{ $tc('generics.company', 1) }} <span class="ml-1 text-red-500">*</span>
        </h2>
      </div>
      <BcAutocomplete
        v-if="!selectedCompany._id"
        v-model="companiesModelValue"
        :is-scrollable="false"
        :label="$t('companies.account-management.companies-data-basis')"
        :label-placeholder="true"
        :open-on-focus="true"
        :options="companiesOptions"
        class="w-full"
        name="company-search"
        v-bind="$attrs"
        @select="setSelectedCompany">
        <template #option="{ option, isActive }">
          <div
            :class="{ 'panel-process-create-custom-job__search--item-active': isActive }"
            class="panel-process-create-custom-job__search--item"
          >
            {{ option.name }}&nbsp;
          </div>
        </template>
        <template #footer>
          <button
            v-if="opportunitiesModelValue"
            class="panel-process-create-company__item is-full-width font-size-m is-secondary is-justify-content-center mx-auto py-3"
            @click="openCustomJobCreatePanel"
          >
            <span class="icon-opportunities-plus mr-2"></span>
            {{ $t('process.create-the-job', { job: opportunitiesModelValue }) }}
          </button>
        </template>
      </BcAutocomplete>
      <button
        v-else-if="selectedCompany._id"
        class="relative flex w-full items-center justify-center rounded-md bg-blue-100 px-4 py-3 text-blue-800"
        @click="removeCompany">
        {{ selectedCompany.name }}
        <i
          class="icon-cross absolute right-2.5 text-xs text-blue-800"
          @click="removeCompany"/>
      </button>
    </div>
    <div class="flex flex-col items-center gap-5 bg-white px-5 py-8">
      <div class="flex items-center">
        <i
          class="icon-job-position mr-2.5 size-[16px] text-blue-800"
        />
        <h2 class="uppercase text-blue-800">
          {{ $t('global.job') }}
        </h2>
      </div>
      <p
        v-if="!selectedCompany._id"
        class="panel-process-create-custom-job__text mb-2">
        {{ $t('process-create.select-company-access-opportunities') }}
      </p>
      <BcAutocomplete
        v-else-if="!selectedOpportunity._id"
        v-model="opportunitiesModelValue"
        :is-scrollable="false"
        :label="$t('generics.search-opportunity')"
        :label-placeholder="true"
        :open-on-focus="true"
        :options="filteredCustomJobs"
        class="w-full"
        name="customjob-search"
        v-bind="$attrs"
        @select="setSelectedOpportunity">
        <template v-if="!opportunitiesModelValue" #header>
          <div class="panel-process-create-custom-job__search--header">
            {{ $t('generics.most-viewed') }}
          </div>
        </template>
        <template #option="{ option, isActive }">
          <div
            :class="{ 'panel-process-create-custom-job__search--item-active': isActive }"
            class="panel-process-create-custom-job__search--item"
          >
            {{ option.title }}&nbsp;
            <span v-if="option.salaryMin">
              ({{ option.salaryMin }} - {{
                option.salaryMax
              }} {{ $t('custom-jobs.currency', { type: salaryLabelsShort[option.salaryType] }) }})
            </span>
          </div>
        </template>
        <template #footer>
          <button
            v-if="opportunitiesModelValue"
            class="panel-process-create-company__item is-full-width font-size-m is-secondary is-justify-content-center mx-auto py-3"
            @click="openCustomJobCreatePanel"
          >
            <span class="icon-opportunities-plus mr-2"></span>
            {{ $t('process.create-the-job', { job: opportunitiesModelValue }) }}
          </button>
        </template>
      </BcAutocomplete>
      <button
        v-else-if="selectedOpportunity._id"
        class="relative flex w-full items-center justify-center rounded-md bg-blue-100 px-4 py-3 text-blue-800"
        @click="removeOpportunity">
        {{ selectedOpportunity.title }}
        <i
          class="icon-cross absolute right-2.5 text-xs text-blue-800"
          @click="removeOpportunity"/>
      </button>
    </div>
    <div class="flex flex-row justify-end gap-2.5">
      <BcButton
        icon-left="cross"
        size="small"
        type="outlined"
        @click="$emit('close-edit')">
        {{ $t('global.cancel') }}
      </BcButton>
      <BcButton
        :disabled="!selectedCompany._id"
        color="primary"
        icon-left="check"
        native-type="submit"
        size="small"
        @click="createNewProcess">
        {{ $t('global.validate') }}
      </BcButton>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .panel-process-create-custom-job {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 15px 0;

    &__modal {
      min-width: 1000px;
      flex-direction: column;
      padding-top: 15px;
    }

    &__title {
      display: flex;
      color: #344f74;
      text-transform: uppercase;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      line-height: 17px;
      align-items: center;
      margin-bottom: 20px;
    }

    &__icon {
      display: inline-flex;
      font-size: $font-size-l;
      align-items: center;
      margin-right: 10px;
    }

    &__wrapper {
      flex-direction: column;
      width: 100%;
      margin: -2.5px 0;
    }

    &__option {
      width: 100%;
      margin: 2.5px 0;
      justify-content: center;
    }

    &__text {
      font-size: $font-size-s;
      color: $color-tertiary;

      &--black {
        color: $color-black;
      }
    }

    &__button {
      margin-top: 30px;
    }

    &__search {
      align-items: center;

      &--input {
        flex: 1;
        margin: 0;
      }

      &--header {
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: $color-blue-dark-cello;
        opacity: 0.7;
        padding: 14px 10px;
      }

      &--item {
        padding: 14px 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $color-blue-dark-cello;

        &-active {
          color: $color-primary;
        }
      }
    }
  }
</style>
