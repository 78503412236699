<template>
  <p
    class="tag"
    :class="[`tag--${color}`, { 'tag--cross': cross }]">
    {{ text }}
    <span
      v-if="cross"
      class="tag__icon icon-cross">
    </span>
  </p>
</template>

<script>
  // TODO Remplacer Tag par Tag-Default
  export default {
    name: 'tag',
    props: {
      /**
       Define the text inside the tag
       */
      text: {
        type: String,
        default: 'Text',
        required: true
      },
      /**
       Define the background color
       */
      color: {
        type: String,
        default: 'blue-light'
      },
      /**
       If it's a function, add a cross next to the value and define the function @click
       */
      cross: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  };
</script>

<style scoped lang="scss">
  .tag {
    border-radius: 4px;
    padding: 8px 16px;
    color: $color-white;
    position: relative;
    font-size: $font-size-xs;
    align-items: center;
    flex-shrink: 0;
    @include transition(all 0.1s);
    &--blue-light {
      background-color: $color-blue-hawkes;
      color: $color-blue-kashmir;
    }
    &--blue {
      background-color: $color-blue;
    }
    &--green {
      background-color: $color-green-lima;
    }
    &--purple {
      background-color: $color-purple-wisteria;
    }
    &--orange {
      background-color: $color-orange-hot-cinnamon;
    }
    &--blue-hover {
      background-color: $color-blue-san-juan;
      border: 1px solid $color-blue-san-juan;
      &:hover {
        background-color: $color-white;
        color: $color-blue-san-juan;
        border-color: $color-blue-san-juan;
      }
    }
    &--red {
      background-color: $color-red-amaranth;
    }
    &--cross {
      padding-right: 30px;
      cursor: pointer;
    }
    &__icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      @include transition(all 0.2s ease);
      font-size: $font-size-xxs;
      border-radius: 5px;
    }
  }

</style>
