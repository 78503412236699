<template>
  <div class="panel-process-details-job-deal">
    <panel-process-details-job-deal-form
      :deal="process.deal"
      :process-dealt-at="process.dealtAt"
      :process-starts-at="startsAt()"
      class="panel-process-details-job-deal__form"
      v-if="isEditing">
    </panel-process-details-job-deal-form>
    <div class="panel-process-details-job-deal__container" v-else>
      <div class="panel-process-details-job-deal__wrapper">
        <div class="panel-process-details-job-deal__subwrapper">
          <p
            :class="{
              'panel-process-details-job-deal__title--red': process.failedAt,
            }"
            class="panel-process-details-job-deal__title icon-arrow-left">
            {{ $t('process.in-waiting-to-start') }}
          </p>
          <bc-tag
            bc-background-color="red-mandy"
            class="panel-process-details-job-deal__tag"
            v-if="process.failedAt">
            {{ $t('generics.drop') }}
          </bc-tag>
        </div>
        <p class="panel-process-details-job-deal__text">
          {{ $t('companies.panel-hunt-process.gross-fixed-salary') }} :
          <span class="panel-process-details-job-deal__text--bold">
            {{ (process.deal || {}).fixed || 0 }}K
            {{ $t('companies.account-management.currency-year') }} +
            {{ (process.deal || {}).variable || 0 }}K
            {{ $t('companies.account-management.currency-year') }}
          </span>
        </p>
        <p class="panel-process-details-job-deal__text">
          {{ $t('companies.panel-hunt-process.deal-amount') }} :
          <span class="panel-process-details-job-deal__text--bold">
            {{ ((process.deal || {}).amount || 0).toLocaleString() }} €
          </span>
        </p>
        <p class="panel-process-details-job-deal__text">
          % {{ $t('companies.panel-hunt-process.in-effect') }} :
          <span class="panel-process-details-job-deal__text--bold">
            {{ (process.deal || {}).percent || 0 }}%
          </span>
        </p>
        <p class="panel-process-details-job-deal__text">
          {{ $t('companies.panel-hunt-process.deal-date') }} :
          <span class="panel-process-details-job-deal__text--bold">
            {{ dealtAt }}
          </span>
        </p>
        <p
          v-if="formattedStartsAt"
          class="panel-process-details-job-deal__text">
          {{ $t('companies.panel-hunt-process.start-on') }} :
          <span class="panel-process-details-job-deal__text--bold">
            {{ formattedStartsAt }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment/min/moment-with-locales';
import PanelProcessDetailsJobDealForm from '@/components/Panel/PanelProcess/PanelProcessDetails/PanelProcessDetailsJob/PanelProcessDetailsJobDeal/PanelProcessDetailsJobDealForm';
import BcTag from '@/legacy/ui-kit/components/Tag/BcTag';

export default {
  name: 'panel-process-details-job-deal',
  components: { BcTag, PanelProcessDetailsJobDealForm },
  props: {
    process: {
      type: Object,
      default: () => ({
        deal: {},
      }),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dealtAt() {
      return moment(this.process?.dealtAt).format('LL');
    },
    formattedStartsAt() {
      return this.startsAt() ? moment(this.startsAt()).format('LL') : '';
    },
  },
  methods: {
    startsAt() {
      let date = '';

      if (this.process.onboarding?.startsAt) {
        date = this.process.onboarding.startsAt;
      } else {
        date = this.process.deal?.startsAt;
      }

      return date;
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-process-details-job-deal {
  flex-direction: column;
  padding: 15px 20px;

  &__name {
    font-weight: $font-weight-medium;
    font-size: $font-size-s;
    line-height: 17px;
    color: $color-secondary;
    margin-bottom: 15px;
  }

  &__title {
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    font-size: $font-size-s;
    color: $color-primary;
    display: flex;
    align-items: center;

    &--gold {
      color: $color-secondary;

      &:before {
        color: $color-primary;
      }
    }

    &--red {
      color: $color-error;
    }

    &:before {
      margin-right: 10px;
      font-size: $font-size-l;
      transform: rotate(180deg);
    }
  }

  &__subwrapper {
    align-items: center;
    margin-bottom: 5px;
  }

  &__tag {
    border-radius: 4px;
    font-size: $font-size-xs;
    padding: 3px 10px;
    margin-left: 10px;
  }

  &__text {
    display: flex;
    color: $color-secondary;
    font-size: $font-size-s;
    line-height: 17px;
    padding-left: 28px;

    &--bold {
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
      display: flex;
      margin: 2px 0 2px 5px;
    }
  }

  &__container {
    justify-content: space-between;
    align-items: center;
  }

  &__wrapper {
    flex-direction: column;
  }
}
</style>
