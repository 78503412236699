<template>
  <div class="timeline-item-header gap-2">
    <bc-avatar
      v-if="actionObject.author || action.pictureUrl"
      :alt="(actionObject.author || {}).name"
      :placeholder="userInitials"
      :src="pictureUrl"
      class="timeline-item-header__avatar"
      size="s"
      @error="setAlternativeImg">
    </bc-avatar>
    <div class="timeline-item-header__container w-full">
      <div class="timeline-item-header__wrapper">
        <p
          v-if="action.type === timelineType.contactCalendly"
          class="timeline-item-header__text font-weight-bold">
          {{ $t('companies.panel-view-timeline.recruitment-need') }}
        </p>
      </div>
      <div v-if="action.header" class="timeline-item-header__wrapper">
        <div>
          <component :is="transformedHeader"></component>
        </div>
      </div>
      <p v-else class="overflow-hidden">
        <span
          v-for="(link, index) in filteredTimelineLinks"
          :key="index"
          class="timeline-item-header__text">
          <router-link
            v-if="
              actionObject[link].name &&
                actionObject[link].link.params.id !== profile._id
            "
            :to="actionObject[link].link"
            class="timeline-item-header__link shrink-0">
            {{ actionObject[link].name }}
          </router-link>
          <span
            v-else-if="actionObject[link].name"
            class="timeline-item-header__link timeline-item-header__link--not-clickable shrink-0">
            {{ actionObject[link].name }}
          </span>
          <b
            v-else-if="actionObject[link].type === 'process'"
            class="text-bold shrink-0">
            {{ $t(actionObject[link].text) }}
          </b>
          <b
            v-else-if="actionObject[link].type === 'processCustom'"
            class="text-bold shrink-0">
            {{ $t(actionObject[link].text) }}
          </b>
          <span
            v-if="actionObject[link].after">
            {{ $t(getAfter(actionObject[link])) }}
          </span>
          <b
            v-if="
              action.type === timelineType.coderVisitedBluecoders ||
                action.type === timelineType.contactVisitedBluecoders ||
                (action.type === timelineType.hookedCoder &&
                  index === filteredTimelineLinks.length - 1)
            ">
            {{ action.data }}
          </b>
          <span
            v-if="
              action.type === timelineType.coachCreateJob &&
                index === filteredTimelineLinks.length - 1 &&
                action.data
            ">
            {{ $t('companies.panel-view-timeline.wttj') }}
          </span>
          <span
            v-if="action.type === timelineType.suggestionCreate && action.data">
            {{ $t('companies.panel-view-timeline.throught') }} {{ action.data }}
          </span>
        </span>
      </p>
    </div>
    <DateBadgeWithTooltip :date="action.createdAt" />
    <div v-if="isEditable">
      <NotesOptionsDropdown
        v-if="(action.type !== timelineType.deleteNote && action.type !== timelineType.emailSentFromMarvin) && action.type !== timelineType.reminderSet && action._note"
        :is-disabled="action._coach?._id !== userProfile._id"
        :is-pin="false"
        class="mr-2.5"
        @delete="goDeleteNote"
        @edit="goEditNote"
        @pin="pinNote"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import { timeline, timelineType } from '@/common-old/macros/timeline/timeline';
  import { getInitials } from '@/helpers/getIntials';
  import { enUS, fr } from 'date-fns/locale';
  import { format, formatDistanceToNowStrict, parseISO } from 'date-fns';

  import BcAvatar from '@/ui-kit/components/BcAvatar';
  import NotesOptionsDropdown from '@/components/Notes/NotesOptionsDropdown.vue';
  import DateBadgeWithTooltip from '@/components/Badge/DateBadgeWithTooltip.vue';

  export default {
    name: 'timeline-item-header',
    components: {
      NotesOptionsDropdown,
      BcAvatar,
      DateBadgeWithTooltip,
    },
    props: {
      action: {
        type: Object,
        default: () => ({
          type: '',
        }),
      },
      isEditable: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      timeline,
      timelineType,
      favoriteListType: ['coder', 'job', 'company'],
    }),
    computed: {
      ...mapGetters({
        configurationProcess: 'user/configurationProcess',
      }),
      ...mapState({
        profile: state => state.panel.profile || {},
        userProfile: state => state.user.profile || {},
      }),
      userInitials() {
        return this.actionObject?.author?.type === 'coach' && this.actionObject?.author.enabled ? getInitials(this.actionObject?.author?.name) : '';
      },
      transformedHeader() {
        if (!this.action.header) {
          return {};
        }

        return {
          name: 'timeline-item-header-transformed',
          template: `<p class="timeline-item-header__text">${this.action.header}</p>`,
          props: this.$options.props,
        };
      },
      actionTooltip() {
        return {
          content: `${this.$i18n.t('companies.panel-view-timeline.the')} ${
            this.actionCreationDate
          }`,
        };
      },
      actionCreationDate() {
        // let locale = localStorage.locale;
        // moment.locale(locale);
        return format(parseISO(this.action.createdAt), 'd MMMM yyyy', { locale: this.$i18n.locale === 'fr' ? fr : enUS });
      },
      actionDateFromNow() {
        // let locale = localStorage.locale;
        // moment.locale(locale);
        // return format(parseISO(this.action.createdAt), 'd MMMM yyyy', { locale: this.$i18n.locale === 'fr' ? fr : enUS });
        return formatDistanceToNowStrict(parseISO(this.action.createdAt), {
          addSuffix: true,
          locale: this.$i18n.locale === 'fr' ? fr : enUS,
        });
        // return moment(this.action.createdAt).fromNow();
      },
      timelineLinks() {
        /**
         * The differents types of possible object inside a timeline object
         * @type {string[]}
         */
        return ['author', 'directObject', 'from', 'to', 'with', 'for'];
      },
      filteredTimelineLinks() {
        return this.timelineLinks.filter(link => this.actionObject[link]);
      },
      actionObject() {
        /**
         * If the action is a poke or not, the base object will change
         * @type {props.action|{type, default}}
         */
        let action;

        if (this.action._process) {
          /**
           * If the action is a process and if it's not a note
           */
          action = this.action._coach && this.action.type !== timelineType.coachCreateProcess ? this.action._process : this.action;
        } else if (this.action._processCustom) {
          action = this.action;
        } else {
          action = this.action._poke && !this.action._coach ? this.action._poke : this.action;
        }
        /**
         * The map to determinate which name should be showed
         * @type {{contact: {type: string, name: string, id: string}, coder: {type: string, name: string, id: string}, partner: {type: string, name: string, id: string}, job: {type: string, name: string, id: string}}}
         */
        let routeName;
        if (this.$route.name === 'HuntJobsPanel' || this.$route.name === 'HuntJobs' || this.$route.name === 'HuntJobsPanel') {
          routeName = 'HuntJobsPanel';
        } else if (this.$route.name.indexOf('Panel') > 0) {
          routeName = this.$route.name;
        } else {
          routeName = `${this.$route.name}Panel`;
        }
        const maps = {
          contact: {
            type: 'contact',
            pictureUrl: this.action._contact ? this.action._contact.pictureUrl : '',
            name: this.action._contact ? `${this.action._contact.firstName} ${this.action._contact.lastName}` : '',
            id: this.action._contact ? this.action._contact._id : '',
            link: {
              name: routeName,
              params: { id: this.action._contact ? this.action._contact._id : '' },
              query: {
                ...this.$route.query,
                type: 'contacts',
              },
            },
          },
          coach: {
            type: 'coach',
            pictureUrl: action._coach ? action._coach.pictureUrl : '',
            name: action._coach ? `${action._coach.firstName} ${action._coach.lastName}` : '',
            id: action._coach ? action._coach._id : '',
            link: {
              name: routeName,
              params: { id: action._coach ? action._coach._id : '' },
              query: {
                ...this.$route.query,
                type: 'coaches',
              },
            },
            enabled: true,
          },
          secondCoach: {
            type: 'secondCoach',
            pictureUrl: action._secondCoach ? action._secondCoach.pictureUrl : '',
            name: action._secondCoach ? `${action._secondCoach.firstName} ${action._secondCoach.lastName}` : '',
            id: action._secondCoach ? action._secondCoach._id : '',
            link: {
              name: routeName,
              params: { id: action._secondCoach ? action._secondCoach._id : '' },
              query: {
                ...this.$route.query,
                type: 'coaches',
              },
            },
          },
          coder: {
            type: 'coder',
            pictureUrl: action._coder ? action._coder.pictureUrl : '',
            name: action._coder ? `${action._coder.firstName} ${action._coder.lastName}` : '',
            id: action._coder ? action._coder._id : '',
            link: {
              name: routeName,
              params: { id: action._coder ? action._coder._id : '' },
              query: {
                ...this.$route.query,
                type: 'coders',
                context: 'timeline',
              },
            },
          },
          company: {
            type: 'company',
            pictureUrl: action._company ? action._company.logoUrl : '',
            name: action._company ? action._company.name : '',
            id: action._company ? action._company._id : '',
            link: {
              name: routeName,
              params: { id: action._company ? action._company._id : '' },
              query: {
                ...this.$route.query,
                type: 'companies',
              },
            },
          },
          job: {
            type: 'job',
            pictureUrl: action._job && action._job._company ? action._job._company.logoUrl : '',
            name: action._job ? action._job.name : '',
            id: action._job ? action._job._id : '',
            link: {
              name: routeName,
              params: { id: action._job ? action._job._id : '' },
              query: {
                ...this.$route.query,
                type: 'jobs',
                category: 'infos',
              },
            },
          },
          hunt: {
            type: 'hunt',
            pictureUrl: action.hunt && action._company ? action._company.logoUrl : '',
            name: action._customJob ? action._customJob.title : '',
            id: action._customJob ? action._customJob._id : '',
            link: {
              name: routeName,
              params: { id: action._customJob ? action._customJob._id : '' },
              query: {
                ...this.$route.query,
                type: 'hunt',
              },
            },
          },
          poke: {
            type: 'poke',
            pictureUrl: '',
            name: 'poke',
            id: action._poke ? action._poke._id : action._id,
            link: {
              name: routeName,
              params: { id: action._poke ? action._poke._id : action._id },
              query: {
                ...this.$route.query,
                type: 'pokes',
              },
            },
          },
          user: {
            type: 'user',
            pictureUrl: action._user && action._user.pictureUrl ? action._user.pictureUrl : '',
          },
          data: {
            type: 'contact',
            pictureUrl: action.data ? action.data.logoUrl : '',
            name: action.data ? action.data.name : '',
            id: action.data ? action.data._id : '',
            link: {
              name: routeName,
              params: { id: action.data ? action.data._id : '' },
              query: {
                ...this.$route.query,
                type: 'contacts',
              },
            },
          },
          process: {
            type: 'process',
            ...action.type === 45 && {
              id: action._id,
              name: 'process',
              link: {
                name: routeName,
                params: { id: (action._process || {})._id },
                query: {
                  ...this.$route.query,
                  type: 'process',
                },
              },
            },
          },
          processCustom: {
            type: 'processCustom',
            ...action.type === timelineType.processCustomCreate && {
              id: action._id,
              name: 'processCustom',
              link: {
                name: routeName,
                params: { id: (action._processCustom || {})._id },
                query: {
                  ...this.$route.query,
                  type: 'processCustom',
                },
              },
            },
          },
          list: {
            type: 'list',
            pictureUrl: '',
            name: action?._list?.name,
            id: action._list ? action._list._id : action._id,
            link: {
              name: 'FavoriteList',
              params: {
                id: action?._list?._id,
                name: action?._list?.name?.toLowerCase().replace(/ /g, '-'),
              },
              query: {
                page: 1,
                'list-type': this.favoriteListType[action?._list?.type - 1],
                'list-id': action?._list?._id,
              },
            },
          },
        };
        /**
         * The object of the timeline which match the timeline type number
         */
        const timelineObject = this.timeline[this.action.type] ? this.timeline[this.action.type] : {};
        /**
         * The object which will be used to set the timeline text
         * @type {{}}
         */
        let renderedObject = {};
        /**
         * We check if the object contain any of the timelineLinks value
         * If yes, an object will be created to be used in the timeline text
         */
        this.timelineLinks.forEach(element => {
          if (timelineObject.hasOwnProperty(element)) {
            renderedObject[element] = {
              ...maps[timelineObject[element].type],
              ...timelineObject[element],
            };
          }
        });

        if (action._processCustom && action?.data?.stepLabel) {
          renderedObject['directObject'].text = action.data.stepLabel;
        }

        return renderedObject;
      },
      pictureUrl() {
        if (!this.actionObject.author) {
          return require('@/assets/img/default-avatar.svg');
        }
        if (
          this.actionObject.author.type === 'process' ||
          this.actionObject.author.type === 'processCustom'
        ) {
          return this.action?._coach?.enabled ? this.action?._coach?.pictureUrl : require('@/assets/img/default-avatar.svg');
        }

        return this.actionObject.author.pictureUrl;
      },
    },
    methods: {
      getAfter(linkedAction) {
        return this.action._note
          ? linkedAction.after[this.action._note.type]
          : linkedAction.after;
      },
      setAlternativeImg(event) {
        event.target.src = require('@/assets/img/default-avatar.svg');
      },
      goDeleteNote() {
        this.$emit('delete-note', this.action);
      },
      goEditNote() {
        this.$emit('edit-note', this.action);
      },
      pinNote() {
        this.$emit('pin-note', this.action);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .timeline-item-header {
    padding: 5px 10px;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    p {
      line-height: 15px;
    }

    &__avatar {
      overflow: hidden;
      flex-shrink: 0;
    }

    &__container {
      align-items: center;
      flex-wrap: wrap;
      margin-right: auto;
      max-width: calc(100% - 160px);
    }

    &__wrapper {
      margin-right: 5px;
      width: 100%;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &__text {
      margin: 0 auto;
      color: $color-blue-dark-cello;
      // overflow: hidden;
      // text-overflow: ellipsis;
      align-items: center;
      font-size: $font-size-xs;
      // white-space: nowrap;

      &:first-child {
        margin-left: 0;
      }

      &--bold {
        font-weight: $font-weight-medium;
        font-size: $font-size-xs;
      }
    }

    &__link {
      color: $color-primary;
      font-size: $font-size-xs;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:first-of-type {
        margin-left: 0;
      }

      &--disabled {
        cursor: initial;
      }

      &--not-clickable {
        cursor: initial;
        color: $color-blue-dark-cello;
      }
    }

    & :deep() {
      b {
        color: $color-primary;
        font-size: $font-size-xs;
        font-weight: initial;
        align-items: center;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:first-of-type {
          margin-left: 0;
        }

        &--disabled {
          cursor: initial;
        }

        &--not-clickable {
          cursor: initial;
          color: $color-blue-dark-cello;
        }
      }
    }
  }
</style>
