<template>
  <div class="card-favorite-lists-settings items-center gap-2">
    <bc-button
      class="card-favorite-lists-settings__btn"
      :class="{ 'card-favorite-lists-settings__btn--disabled': locked }"
      icon-center="edit-3"
      @click.native.stop="openFavoriteListEdit"
    ></bc-button>
    <ButtonEmail
      v-if="isCoderOrContact"
      class="card-favorite-lists-settings__btn"
      :class="{ 'card-favorite-lists-settings__btn--disabled': locked }"
      :simple="true"
      v-tooltip.bottom="{ content: $t('email.send-a-mail') }"
      @click.native.stop="setListId"
    />
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { codersLookup, contactsLookup } from '@/api/coders';

  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import ButtonEmail from '@/components/Button/ButtonEmail';
  import { types } from '@/macros/favoriteLists/favoriteLists';

  export default {
    name: 'card-favorite-lists-settings',
    props: {
      id: {
        type: String,
        default: '',
      },
      locked: {
        type: Boolean,
        default: false,
      },
      listType: {
        type: Number,
      },
    },
    components: {
      BcButton,
      ButtonEmail,
    },
    data() {
      return {
        isHover: false,
      }
    },
    computed: {
      isCoderOrContact() {
        return this.listType === types.coder || this.listType === types.contact;
      },
      listTypeMap() {
        return {
          [types.coder]: 'coder',
          [types.job]: 'job',
          [types.company]: 'company',
          [types.contact]: 'contact',
        };
      }
    },
    methods: {
      ...mapActions({
        setSelectedCoders: 'emails/setSelectedCoders',
        setEmailIsLoading: 'emails/setIsLoading',
      }),
      openFavoriteListEdit() {
        if (this.locked) {
          return;
        }
        this.$router.push({
          name: `${this.$route.name}PanelEdit`,
          params: {
            id: this.id,
          },
          query: {
            ...this.$route.query,
            type: `favorite-${this.listTypeMap[this.listType]}-list`,
            subtype: 'edit-favorite-list',
            ['list-type']: this.listTypeMap[this.listType],
          },
        });
      },
      async setListId() {
        try {
          this.setEmailIsLoading(true);

          let response = null;
          if (this.listType === types.contact) {
            response = await contactsLookup({ listId: this.id, withoutPagination: true });
          } else if (this.listType === types.coder) {
            response = await codersLookup({ listId: this.id, withoutPagination: true });
          }
          const list = response?.data;
          if (list?.coders?.length) {
            this.setSelectedCoders(list.coders)
          }

          this.setEmailIsLoading(false);
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err?.message,
          });
          this.setEmailIsLoading(false);
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .card-favorite-lists-settings {
    &__btn {
      width: 36px !important;
      height: 36px !important;

      &--disabled {
        opacity: 0.5;
      }
    }
  }
</style>
