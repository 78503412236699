<template>
  <div class="panel-chat-messages-date">
    <p class="panel-chat-message-date__text">
      {{ formattedDate }}
    </p>
  </div>
</template>

<script>
  import moment from 'moment/min/moment-with-locales';

  export default {
    name: 'panel-chat-messages-date',
    props: {
      date: {
        type: String,
      },
    },
    computed: {
      formattedDate() {
        return moment(this.date).calendar();
      },
    },
  };
</script>

<style scoped lang="scss">
  .panel-chat-message-date {
    width: 100%;
    justify-content: center;
    &__text {
      font-weight: $font-weight-medium;
      font-size: $font-size-xxs;
      text-align: center;
      line-height: 14px;
      color: $color-blue-dark-cello;
    }
  }
</style>
