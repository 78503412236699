<template>
  <views class="database-contacts col-xs-12 is-align-items-center">
    <CompaniesTabs/>
    <views-container>
      <searchbar-contacts
        :results-count="queryCount"
        class="database-contacts__searchbar"
        coders-searchbar
        @has-filter="hasFilter = $event"/>
      <router-view/>
      <ContactsEmpty
        v-if="isLoaded && cards.length === 0 && !globalMetadata.coders"
        :showCreateButton="!hasFilter"
        :title="
          hasFilter
            ? $t('generics.no-result-found')
            : $t('companies.panel-company.your-contact-database-is-empty')
        "
        class="database-contacts__empty"
        @import="isModalOpen = true"/>
      <div v-else class="is-column">
        <bc-spinner v-if="!isLoaded" class="database-contacts__spinner"/>
        <div v-else class="is-align-items-center is-column">
          <ContactsGrid
            :is-checked="cards.length === selectedCards.length"
            class="hidden-xs"
            @checked="boolean => store.dispatch('setSelectedCards', boolean ? cards : [])"/>
          <card-list ref="cardList" :cards="cards" :is-loaded="true" class="pagination__card-list">
            <template #card="{ card }">
              <card :card-content="card" card-type="contacts">
                <card-contacts
                  :card-content="card"
                  :is-selected="getIsSelectedStatus(card._id)"
                  context="search-dbcoders"
                  @checked="setSelectedCards($event, card)"/>
              </card>
            </template>
          </card-list>
        </div>
        <bc-pagination
          :current.sync="currentPage"
          :total="queryCount"
          class="database-contacts__pagination"/>
        <multiselect-footer
          v-if="selectedCards.length"
          class="database-contacts__footer is-fixed is-bottom is-left is-full-width is-justify-content-center is-align-items-center is-column"/>
      </div>
    </views-container>
    <bc-modal :active="isModalOpen" @close="isModalOpen = false">
      <DatabaseCodersModalDataImport @close-modal="isModalOpen = false"/>
    </bc-modal>
  </views>
</template>

<script lang="ts" setup>
  import axios, { CancelTokenSource } from 'axios';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import BcPagination from '@/ui-kit/components/BcPagination';
  import CardList from '@/components/CardList/CardList';
  import locations_helpers from '@/common-old/utils/locations_helpers';
  import moment from 'moment/min/moment-with-locales';
  import ContactsGrid from '@/components/Contacts/ContactsGrid';
  import CardContacts from '@/components/Card/CardContacts';
  import Card from '@/components/Card/Card';
  import MultiselectFooter from '@/components/Multiselect/MultiselectFooterLegacy';
  import ViewsContainer from '@/components/Views/ViewsContainer';
  import Views from '@/components/Views/Views';
  import BcModal from '@/ui-kit/components/BcModal/BcModal';
  import DatabaseCodersModalDataImport
    from '@/components/DatabaseCoders/DatabaseCodersModal/DatabaseCodersModalDataImport';
  import ContactsEmpty from '@/components/Contacts/ContactsEmpty';
  import CompaniesTabs from '@/views/Tabs/CompaniesTabs';
  import SearchbarContacts from '@/components/Searchbar/SearchbarContacts/SearchbarContacts';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { useStore } from '@/store.js';
  import { searchParamToNumber, searchParamToString } from '@/utils/typeConversion';
  import { computed, defineEmits, onUnmounted, ref, watch } from 'vue';
  import { Route } from 'vue-router';

  const route = useRoute();
  const router = useRouter();

  const store = useStore();

  const isLoaded = ref(false);
  const currentPage = ref(searchParamToNumber(route.query.page, 1));
  const isModalOpen = ref(false);
  const cancelTokenSource = ref<CancelTokenSource>();
  const hasFilter = ref(false);

  const emit = defineEmits(['error']);

  const cards = computed(() => store.state.card.cards);
  const selectedCards = computed(() => store.state.card.selectedCards);
  const cardsToRemoveFromList = computed(() => store.state.card.cardsToRemoveFromList);
  const queryCount = computed(() => store.state.card.count);
  const globalMetadata = computed(() => store.state.user.metadata);
  const sort = computed(() => store.state.card.sort);

  const parameters = computed(() => {
    const lastActivityFrom = searchParamToString(route.query['last-coach-activity-from']);
    const lastActivityTo = searchParamToString(route.query['last-coach-activity-to']);

    const customFieldsQuery = Object.keys(route.query).filter(key => key.startsWith('cf-'));

    const customFields = customFieldsQuery.reduce((acc, key) => {
      acc[key.split('cf-')[1]] = route.query[key];
      return acc;
    }, {} as Record<string, any>);

    const params = {
      page: currentPage.value,
      ...(route.query.search && { query: route.query.search }),
      ...(route.query.roles && { roles: route.query.roles }),
      ...(route.query.professions && { professions: route.query.professions }),
      ...(route.query.companies && { companies: route.query.companies }),
      ...(route.query.locations && {
        locations: JSON.stringify(locations_helpers.getLocationsId(route.query.locations)),
      }),
      ...(route.query.distance && { distance: route.query.distance }),
      ...(route.query.phone && { phone: true }),
      ...(route.query.cv && { cv: true }),
      ...(route.query.email && { email: true }),
      ...(route.query.linkedin && { linkedin: true }),
      ...(lastActivityFrom && {
        lastCoachActivityFrom: moment(lastActivityFrom, 'D-MM-YYYY').toDate(),
      }),
      ...(lastActivityTo && { lastCoachActivityTo: moment(lastActivityTo, 'D-MM-YYYY').toDate() }),
      ...(Object.keys(customFields).length && { customFields: JSON.stringify(customFields) }),
      ...sort.value.field !== 'default' && sort.value.direction !== 'default' && {
        'sortField': sort.value.field,
        'sortDirection': sort.value.direction,
      },
    };

    return params;
  });

  store.dispatch('setCount', 1);
  handleGetContacts();

  onUnmounted(() => {
    cancelGetContacts();
    store.dispatch('setSelectedCards', []);
  });

  watch(
    route,
    (to, from) => {
      handleQueriesChange(to, from);
    },
    { deep: true },
  );

  watch(currentPage, newPage => {
    router.push({ ...route, query: { ...route.query, page: newPage } });
    store.dispatch('setSelectedCards', []);
  });

  watch(sort, async() => {
    currentPage.value = 1;
    await handleGetContacts();
  });

  function getIsSelectedStatus(id) {
    return selectedCards.value.map(card => card._id).includes(id);
  }

  function setSelectedCards(value: boolean, card: any) {
    const selectedCardsIds = selectedCards.value.map(card => card._id);

    if (!value && selectedCardsIds.includes(card._id)) {
      store.dispatch(
        'setSelectedCards',
        selectedCards.value.filter(c => c._id !== card._id),
      );
    } else if (value && !selectedCardsIds.includes(card._id)) {
      store.dispatch('setSelectedCards', [...selectedCards.value, card]);
    }
  }

  function cancelGetContacts() {
    cancelTokenSource.value?.cancel('manualCancel');
  }

  function handleQueriesChange(to: Route, from: Route) {
    // const isDifferentQueries = Object.keys(to.query).some(key => from.query[key] !== to.query[key]);
    const isOpeningPanel = Boolean(!(from.params || {}).id && !!(to.params || {}).id);
    const isClosingPanel = Boolean((from.params || {}).id && !(to.params || {}).id);
    const isInPanel = Boolean((from.params || {}).id && (to.params || {}).id);
    const pageChange = from.query?.page !== to.query?.page;

    if (!isOpeningPanel && !isClosingPanel && (!isInPanel || (isInPanel && pageChange))) {
      cancelGetContacts();
      handleGetContacts();
      currentPage.value = parseInt(searchParamToString(to.query.page)) || 1;
    }

    if (isClosingPanel && cardsToRemoveFromList.value.length) {
      for (const cardToRemoveFromList of cardsToRemoveFromList.value) {
        store.dispatch('removeCardFromList', cardToRemoveFromList);
      }
    }
  }

  async function handleGetContacts() {
    cancelTokenSource.value = axios.CancelToken.source();
    isLoaded.value = false;
    try {
      store.dispatch('resetCards', []);

      const response = await store.dispatch('getCards', {
        page: currentPage.value,
        call: 'contacts/lookup',
        parameters: parameters.value,
        responseKeyName: 'coders',
        cancelTokenSource: cancelTokenSource.value,
      });

      if (response.data?.coders.length > 0) {
        const currentCount = response.data?.count;
        if (currentPage.value === 1 || queryCount.value <= currentCount) {
          await store.dispatch('getCountCards', {
            page: currentPage.value,
            call: 'contacts/lookup/count',
            parameters: parameters.value,
            responseKeyName: 'coders',
            cancelTokenSource: cancelTokenSource.value,
          });
        }
      }
      isLoaded.value = true;
    } catch (error) {
      if (axios.isCancel(error)) {
        isLoaded.value = false;
        return;
      }
      emit('error', error);
      isLoaded.value = true;
    }
  }
</script>

<style lang="scss" scoped>
  .database-contacts {
    @include page_grid();

    &__title {
      min-height: 30px;
    }

    &__subtitle {
      position: relative;
      top: 1px;
      color: #3d5476;
    }

    &__filters {
      margin-bottom: 20px;
    }

    &__pagination {
      margin: 20px 0;
    }

    &__spinner {
      margin: 100px 0;
    }

    &__card-list {
      margin-bottom: 100px;
    }

    &__spinner,
    &__pagination,
    &__card-list {
      justify-content: center;
    }
  }
</style>
