<template>
  <div class="card-suggestions__external-container card">
    <div class="card-suggestions__wrapper" @click.stop>
      <bc-checkbox
        :value="isSelected"
        @input="$emit('checked', $event)"
        class="card-suggestions__text card-suggestions__checkbox is-uppercase font-size-s"
        :id="cardContent._id">
      </bc-checkbox>
    </div>
    <div
      :class="[cardType === 'coders' || cardType === 'companies' ? 'card-suggestions__container--tightened' : '', cardContent.deletedAt ? 'card-suggestions__container--deleted' : '' ]"
      class="card-suggestions__container">
      <div
        class="card-suggestions__container--overlay"
        v-if="isHighlighted">
      </div>
      <slot
        :class="{
          'card-suggestions__container--highlighted': isHighlighted,
        }"
        v-if="$slots.default">
      </slot>
      <component
        :card-content="cardContent"
        :card-index="cardIndex"
        :card-props="cardProps"
        :card-type="cardType"
        :class="{
          'card-suggestions__container--highlighted': isHighlighted,
          'card-suggestions__container--bordered': isSelectedIntoTheJobList,
        }"
        :is="currentView"
        v-else>
      </component>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import CardRemindersCoders from '@/components/Card/CardReminders/CardRemindersCoders';
  import CardPokes from '@/components/Card/CardPokes';
  import CardJobs from '@/components/Card/CardJobs';
  import CardContacts from '@/components/Card/CardContacts';
  import CardCompanies from '@/components/Card/CardCompanies';
  import CardCoaches from '@/components/Card/CardCoaches';
  import CardChat from '@/components/Card/CardChat';
  import CardFavoriteLists from '@/components/Card/CardFavoriteLists';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';

  /**
   * This component contains the content of each type of card.
   */

  export default {
    name: 'card-reminders',
    components: {
      CardCoaches,
      CardCompanies,
      CardContacts,
      CardRemindersCoders,
      'card-job-list': CardJobs,
      CardJobs,
      CardPokes,
      CardChat,
      CardFavoriteLists,
      BcCheckbox,
    },
    props: {
      /**
       * The Object that contains the content of the response that will be displayed by the card
       */
      cardContent: Object,
      /**
       * The type of the card
       * `jobs, pokes, receivedPokes`
       */
      cardType: String,
      /**
       * The index of the card
       */
      cardIndex: Number,
      /**
       * Determinate if the placeholder should be visible instead of the card content
       */
      isLoading: {
        type: Boolean,
        default: true,
      },
      /**
       * A props in case we need to give to the card a props from the parent
       */
      cardProps: [Boolean, Object, String, Array, Number],
      isSelected: {
        type: Boolean,
        default: false,
      },
    },
    /**
     * We import the closePanel Function to give it to the overlay props
     */
    computed: {
      ...mapState({
        viewList: state => state.panel.viewList,
        jobIdFromTheNewPoke: state => state.pokes.newPoke.jobId,
      }),
      currentView() {
        const cardType = {
          coders: 'card-coders',
          'jobs-list': 'card-job-list',
          jobs: 'card-jobs',
          pokes: this.pokeType,
          contacts: 'card-contacts',
          views: 'card-views',
          coaches: 'card-coaches',
          companies: 'card-companies',
          partners: 'card-companies',
          favorites: this.favoriteType,
          'favorite-lists': 'card-favorite-lists',
        };
        return cardType[this.cardType];
      },
      pokeType() {
        if (this.cardContent.forced) {
          return 'card-pokes';
        }
        return 'card-chat';
      },
      /***
       * @returns {string}
       * Determine which card resource should be called
       * depending of favorite type
       */
      favoriteType() {
        const cardResource = ['card-coders', 'card-jobs', 'card-companies', 'card-contacts', 'card-pokes'];

        return cardResource[this.cardContent.type - 1];
      },
      /**
       * Determinate if the card should be highlighted or not by comparing the propsId and the routeId
       * @returns {boolean}
       */
      isHighlighted() {
        return this.cardContent._id && this.cardContent._id === this.$route.params.id;
      },
      isSelectedIntoTheJobList() {
        if (this.cardType === 'jobs-list') {
          return this.viewList[this.cardIndex]._id === this.jobIdFromTheNewPoke;
        }
        return false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card-suggestions__container--deleted {
    display: none;
  }

  .card-suggestions {
    &__external-container {
      align-items: center;
      height: 79px;
      width: 100%;
      margin-bottom: 5px;
    }

    &__container {
      align-items: center;
      width: 95%;
      border-radius: 10px;
      flex-shrink: 0;
      transition: 0.3s;
      @include bp('tablet') {
        align-items: stretch;
      }

      &--overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.25);
        @include transition(all 0.3s ease);
        z-index: 3;
      }

      &--highlighted {
        z-index: 3;
        background-color: $color-white;
      }

      &--bordered {
        border: 2px solid $color-blue-havelock;
        border-radius: 4px;
      }
    }

    &__wrapper {
      margin: 10px;
      width: 100%;

      @include bp("tablet") {
        margin: 0 10px;
        width: initial;
      }
    }

    &__checkbox {
      background: $color-white;
      margin-left: 15px;
      margin-bottom: 15px;
    }
  }
</style>
