export default {
  auth: {
    loginUser() {
      return `${import.meta.env.VITE_API_URL}/users/token`;
    },
    requestResetPassword() {
      return `${import.meta.env.VITE_API_URL}/request-password-reset`;
    },
    resetPasswordTest() {
      return `${import.meta.env.VITE_API_URL}/reset-password`;
    },
    socialLogin(service) {
      return `${import.meta.env.VITE_API_URL}/oauth/${service}`;
    },
    confirmCoder() {
      return `${import.meta.env.VITE_API_URL}/confirm-coder`;
    }
  },
  contact: {
    getContactProfile(id) {
      return `${import.meta.env.VITE_API_URL}/contacts/${id}`;
    },
    getLinkContact() {
      return `${import.meta.env.VITE_API_URL}/link-contact`;
    }
  },
  coaches: {
    getCoachProfile(id) {
      return `${import.meta.env.VITE_API_URL}/coaches/${id}`;

    },
    getCoaches() {
      return `${import.meta.env.VITE_API_URL}/coaches`;
    }
  },
  pipe: {
    getPipe({ params, page }) {
      return `${import.meta.env.VITE_API_URL}/${params.userType}/${params.id}/pipe?page=${page}`;
    },
  },
  partners: {
    getPartners() {
      return `${import.meta.env.VITE_API_URL}/partners`;
    },
    getPartnerProfile(id) {
      return `${import.meta.env.VITE_API_URL}/partners/${id}`;
    },
    getPartnerJobs(id) {
      return `${import.meta.env.VITE_API_URL}/partners/${id}/jobs`;
    },
    getWebJobs() {
      return `${import.meta.env.VITE_API_URL}/services/scrapper/company-jobs`;
    },
    getPartnerContacts(id) {
      return `${import.meta.env.VITE_API_URL}/partners/${id}/contacts`;
    },
    updateJobsStatus(id, status) {
      return `${import.meta.env.VITE_API_URL}/jobs/${id}/${status}`;
    },
    updatePartnerPartnership(id) {
      return `${import.meta.env.VITE_API_URL}/partners/${id}/partnership`;
    }
  },
  popularity: {
    getPopularityViews(params) {
      return `${import.meta.env.VITE_API_URL}/${params.userType}/${params.id}/views`;
    },
    getPopularityGraph(params) {
      return `${import.meta.env.VITE_API_URL}/${params.userType}/${params.id}/views/graph`;
    },
    getPopularityReputation(params) {
      return `${import.meta.env.VITE_API_URL}/${params.userType}/${params.id}/reputation`;
    }
  },
  top: {
    getTop() {
      return `${import.meta.env.VITE_API_URL}/top`;
    }
  },
  user: {
    getUserProfile() {
      return `${import.meta.env.VITE_API_URL}/profile`;
    },
    logAs() {
      return `${import.meta.env.VITE_API_URL}/log-as`;
    }
  },
  cards: {
    getCards(params) {
      return `${import.meta.env.VITE_API_URL}/${params.call}`;
    }
  },
  chat: {
    getChat(id) {
      return `${import.meta.env.VITE_API_URL}/pokes/${id}/messages`;
    },
    getNewMessages(id, date) {
      return `${import.meta.env.VITE_API_URL}/pokes/${id}/new-messages?dateFrom=${date}`;
    }
  },
  jobs: {
    getJobs() {
      return `${import.meta.env.VITE_API_URL}/jobs`;
    },
    getJobProfile(id) {
      return `${import.meta.env.VITE_API_URL}/jobs/${id}`;
    },
    getJobsResources(params) {
      return `${import.meta.env.VITE_API_URL}/jobs/${params.parameters.id}/${params.parameters.resources}?page=${params.parameters.page}`;
    }
  },
  favorites: {
    getCoachFavoriteLists(id) {
      return `${import.meta.env.VITE_API_URL}/coaches/${id}/lists`;
    },
    getFavoriteLists() {
      return `${import.meta.env.VITE_API_URL}/lists`;
    },
    getFavoriteListById(id) {
      return `${import.meta.env.VITE_API_URL}/lists/${id}`;
    },
    addCoderToFavoriteList(id) {
      return `${import.meta.env.VITE_API_URL}/lists/${id}/coder`;
    },
    deleteCoderFromFavoriteList(id) {
      return `${import.meta.env.VITE_API_URL}/lists/${id}/coder`;
    },
    favoriteListRank(id) {
      return `${import.meta.env.VITE_API_URL}/lists/${id}/rank`;
    }
  },
  recap: {
    getRecap() {
      return `${import.meta.env.VITE_API_URL}/recap`;
    }
  },
  pokes: {
    getPokes(status) {
      return `${import.meta.env.VITE_API_URL}/pokes?status=${status}`;
    },
    setPoke(resource, id) {
      return `${import.meta.env.VITE_API_URL}/${resource}/${id}/poke`;
    },
    pokeACoderAsAPartner(id) {
      return `${import.meta.env.VITE_API_URL}/coders/${id}/poke`;
    },
    getPokeProfile(id) {
      return `${import.meta.env.VITE_API_URL}/pokes/${id}`;
    },
    abortPoke(id) {
      return `${import.meta.env.VITE_API_URL}/pokes/${id}/abort`;
    },
    unabortPoke(id) {
      return `${import.meta.env.API_URL}/pokes/${id}/unabort`;
    },
    archivePoke(id) {
      return `${import.meta.env.VITE_API_URL}/pokes/${id}/archive`;
    },
    unarchivePoke(id) {
      return `${import.meta.env.VITE_API_URL}/pokes/${id}/unarchive`;
    }
  },
  upload: {
    uploadFile(id) {
      return `${import.meta.env.VITE_API_URL}/users/${id}/medias`;
    },
    uploadCV(id) {
      return `${import.meta.env.VITE_API_URL}/coders/${id}/cv`;
    }
  },
  technos: {
    getTechnos() {
      return `${import.meta.env.VITE_API_URL}/technos`;
    },
    getTechnoObject(id) {
      return `${import.meta.env.VITE_API_URL}/technos/${id}`;
    }
  },
  confidentiality: {
    block(id) {
      return `${import.meta.env.VITE_API_URL}/partners/${id}/block`;
    }
  },
  timeline: {
    getGlobalTimeline() {
      return `${import.meta.env.VITE_API_URL}/timeline`;
    },
    getUserTimeline(params) {
      return `${import.meta.env.VITE_API_URL}/${params.userType}/${params.id}/timeline`;
    }
  },
  search: {
    searchPartners(name) {
      return `${import.meta.env.VITE_API_URL}/search/partner?name=${name}`;
    }
  },
  coders: {
    getCoders() {
      return `${import.meta.env.VITE_API_URL}/coders`;
    },
    getCoderProfile(id) {
      return `${import.meta.env.VITE_API_URL}/coders/${id}`;
    },
    getLinkCoder() {
      return `${import.meta.env.VITE_API_URL}/link-coder`;
    },
    getCodersResources(params) {
      return `${import.meta.env.VITE_API_URL}/coders/${params.parameters.id}/${params.parameters.resources}?page=${params.parameters.page}`;
    },
    confirmCoderEmail(id) {
      return `${import.meta.env.VITE_API_URL}/coders/${id}/request-confirm`;
    }
  },
  config: {
    getConfig() {
      return `${import.meta.env.VITE_API_URL}/config`;
    },
    getDefaultPlan() {
      return `${import.meta.env.VITE_API_URL}/config/default-plan`;
    }
  },
  notes: {
    getSingleNote(id) {
      return `${import.meta.env.VITE_API_URL}/notes/${id}`;
    },
    getNotesForUser(params) {
      return `${import.meta.env.VITE_API_URL}/${params.user}/${params.id}/note`;
    }
  },
  location: {
    getLocationAutocomplete() {
      return `${import.meta.env.VITE_API_LOCATION_AUTOCOMPLETE_URL}`;
    },
    getLocationReverseGeocoding() {
      return `${import.meta.env.VITE_API_LOCATION_REVERSE_GEOCODING_URL}`;
    },
    getLocationSearch() {
      return `${import.meta.env.VITE_API_LOCATION_SEARCH_URL}`;
    }
  },
};
