import user from '@/api/user';
import {
  deleteConfigurationProspectionStep,
  deleteConfigurationStep,
  editOrganization,
  updateConfigurationOrganization,
  updateConfigurationOrganizationByKey,
} from '@/api/organizations';
import {
  bluecodersAcademyOrganizationId,
  marvinRecruiterOrganizationId,
  organizationExceptions,
  plOrganizationId,
  shappersOrganizationId,
} from '@/macros/utils';
import { useLaunchDarklyStore } from '@/store/pinia/launchDarkly';

export default {
  namespaced: true,
  state() {
    return {
      metadata: {},
      profile: {},
      onboarding: {},
      loading: false,
    };
  },
  getters: {
    profileId: state => state.profile._id || '',
    isSuperUser: state => state.profile?._organization?.superUser,
    isMarvinRecruiter: state => state.profile?._organization?._id === marvinRecruiterOrganizationId,
    isOrganizationException: state => organizationExceptions.includes(state.profile?._organization?._id),
    isPL: state => state.profile?._organization?._id === plOrganizationId,
    isShappers: state => state.profile?._organization?._id === shappersOrganizationId,
    isAcademy: state => state.profile?._organization?._id === bluecodersAcademyOrganizationId,
    configurationCareer: state => state.profile?._organization?.configuration?.career || {},
    configurationFramework: state => state.profile?._organization?.configuration?.framework || {},
    configurationFeatures: state => state.profile?._organization?.configuration?.features || {},
    configurationProcess: state => state.profile?._organization?.configuration?.process || {},
    configurationProcessWithoutCandidates: state => {
      const process = { ...state.profile?._organization?.configuration?.process || {} };
      const steps = process.categories[0].steps.filter((_, index) => index !== 0);
      process.categories[0].steps = steps;
      return process;
    },
    configurationProspection: state => state.profile?._organization?.configuration?.prospection || {},
    configurationJob: state => state.profile?._organization?.configuration?.job || {},
    configurationCandidate: state => state.profile?._organization?.configuration?.candidate || {},
    configurationCompany: state => state.profile?._organization?.configuration?.company || {},
    configurationCustomJob: state => state.profile?._organization?.configuration?.job || {},
    configurationIntegrations: state => state.profile?._organization?.configuration?.integrations || {},
    configurationJobMultiposting: state => state.profile?._organization?.configuration?.integrations?.job_multiposting || {},
    configurationDb: state => state.profile?._organization?.configuration?.db || {},
    configuration: state => state.profile?._organization?.configuration || {},
    organization: state => state.profile?._organization,
  },
  actions: {
    async getProfile({ commit }) {
      const { data } = await user.getProfile();

      const launchDarklyStore = useLaunchDarklyStore();
      await launchDarklyStore.initLaunchDarkly({
        kind: 'multi',
        user: {
          key: data.profile._id,
          _id: data.profile._id,
          firstName: data.profile.firstName,
          lastName: data.profile.lastName,
          email: data.profile.email,
        },
        organization: {
          key: data.profile._organization?._id,
          _id: data.profile._organization?._id,
          name: data.profile._organization?.name,
        },
      });

      commit('setMeta', data.metadata);
      commit('setProfile', data.profile);
      commit('setOnboarding', data.onboarding);
    },
    updateMetadataByKey({ commit }, { key, value }) {
      commit('updateMetadata', { key, value });
    },
    updateOnboarding({ commit }, { key, value }) {
      commit('updateOnboarding', { key, value });
    },
    updateOnboardingProgress({ commit }, params) {
      commit('updateOnboardingProgress', params);
    },
    updateLoading({ commit }) {
      commit('updateLoading');
    },
    incrementMetadataByKey({ commit }, key) {
      commit('incrementMetadataByKey', key);
    },
    decrementMetadataByKey({ commit }, key) {
      commit('decrementMetadataByKey', key);
    },
    cleanProfile({ commit }) {
      commit('cleanProfile');
    },
    updateProfile({ commit }, profile) {
      commit('setProfile', profile);
    },
    async updateOrganization({ commit, state }, data) {
      const { data: organization } = await editOrganization({
        id: state.profile?._organization?._id,
        data,
      });
      commit('updateOrganization', organization);
    },
    async updateOrganizationIndustries({ commit, state }, industries) {
      const { data: organization } = await editOrganization({
        id: state.profile?._organization?._id,
        data: { _industries: industries.map(industry => industry._id) },
      });
      commit('updateOrganizationIndustries', organization);
    },
    async updateConfigurationOrganization({ commit, state }, config) {
      const configuration = {
        organizationId: state.profile?._organization?._id,
        ...(state.profile?._organization?.configuration || {}),
        ...config,
      };
      try {
        await updateConfigurationOrganization({ configuration });
        commit('updateConfigurationOrganization', configuration);
      } catch (err) {
        console.error('err action updateConfigurationOrganization', err);
      }
    },
    async updateConfigurationOrganizationByKey({ commit }, config) {
      try {
        await updateConfigurationOrganizationByKey(config);
        commit('updateConfigurationOrganizationByKey', config);
      } catch (err) {
        console.error('err action updateConfigurationOrganizationByKey', err);
      }
    },
    async updateConfigurationMultiposting({ commit }, config) {
      commit('updateConfigurationMultiposting', config);
    },
    async updateConfigurationKaspr({ commit }, config) {
      commit('updateConfigurationKaspr', config);
    },
    async updateConfigurationXtramile({ commit }, config) {
      commit('updateConfigurationXtramile', config);
    },
    async deleteConfigurationStep({ commit }, { categoryKey, stepKey }) {
      try {
        const { data: config } = await deleteConfigurationStep({ categoryKey, stepKey });
        commit('updateConfigurationOrganization', config);
      } catch (err) {
        console.log('err action deleteConfigurationStep', err);
      }
    },
    async deleteConfigurationProspectionStep({ commit }, { categoryKey, stepKey }) {
      try {
        const { data: config } = await deleteConfigurationProspectionStep({ categoryKey, stepKey });
        commit('updateConfigurationOrganization', config);
      } catch (err) {
        console.log('err action deleteConfigurationProspectionStep', err);
      }
    },
  },
  mutations: {
    setMeta(state, metadata) {
      state.metadata = {
        ...state.metadata,
        ...metadata,
      };
    },
    setProfile(state, profile) {
      state.profile = {
        ...state.profile,
        ...profile,
      };
    },
    setOnboarding(state, onboarding) {
      state.onboarding = {
        ...state.onboarding,
        ...onboarding,
      };
    },
    incrementMetadataByKey(state, key) {
      ++state.metadata[key];
    },
    updateMetadata(state, { key, value }) {
      state.metadata[key] = value;
    },
    updateOnboarding(state, { key, value }) {
      state.onboarding[key] = value;
    },
    updateOnboardingProgress(state, { section, chapter }) {
      state.profile = {
        ...state.profile,
        onboardingProgress: {
          section,
          chapter,
        },
      };
    },
    updateConfigurationOrganization(state, config) {
      state.profile._organization.configuration = config;
    },
    updateConfigurationOrganizationByKey(state, { key, ...config }) {
      state.profile._organization.configuration[key] = config;
    },
    updateConfigurationMultiposting(state, { key, ...config }) {
      if (!state.profile._organization.configuration?.integrations?.job_multiposting) {
        state.profile._organization.configuration = {
          ...state.profile._organization.configuration,
          integrations: {
            ...state.profile._organization.configuration.integrations || {},
            job_multiposting: {
              [key]: config,
            },
          },
        };
        return;
      }
      state.profile._organization.configuration.integrations.job_multiposting = {
        ...state.profile._organization.configuration.integrations.job_multiposting,
        [key]: config,
      };
    },
    updateConfigurationKaspr(state, config) {
      if (!state.profile._organization.configuration?.integrations?.kaspr) {
        state.profile._organization.configuration = {
          ...state.profile._organization.configuration,
          integrations: {
            ...state.profile._organization.configuration.integrations || {},
            kaspr: config,
          },
        };
        return;
      }
      state.profile._organization.configuration.integrations.kaspr = config;
    },
    updateConfigurationXtramile(state, config) {
      if (!state.profile._organization.configuration?.integrations?.xtramile) {
        state.profile._organization.configuration = {
          ...state.profile._organization.configuration,
          integrations: {
            ...state.profile._organization.configuration.integrations || {},
            xtramile: config,
          },
        };
        return;
      }
      state.profile._organization.configuration.integrations.xtramile = config;
    },
    cleanProfile(state) {
      state.profile = {};
      state.metadata = {};
    },
    decrementMetadataByKey(state, key) {
      --state.metadata[key];
    },
    updateLoading(state) {
      state.loading = !state.loading;
    },
    updateOrganization(state, organization) {
      state.profile._organization = organization;
    },
    updateOrganizationIndustries(state, organization) {
      state.profile._organization._industries = organization._industries;
    },
  },
};
