<template>
  <div class="technos-tag">
    <div
      class="technos-tag__container technos-tag__container-input"
      v-if="isEditing || isCreating">
      <input
        class="technos-tag__input"
        v-model="technoName">
      <div
        class="technos-tag__wrapper technos-tag__wrapper--show"
        v-if="isCreating">
        <div
          @click="createTechno"
          class="technos-tag__icon">
          <div
            :class="{ 'technos-tag__icon--green': technoName.length > 0 }"
            class="technos-tag__icon technos-tag__icon--gray icon-plus">
          </div>
        </div>
      </div>
      <div
        class="technos-tag__wrapper technos-tag__wrapper--show"
        v-else>
        <div
          @click="isEditing = false"
          class="technos-tag__icon">
          <div class="technos-tag__icon technos-tag__icon--red icon-cross"></div>
        </div>
        <div
          @click="editTechnoName"
          class="technos-tag__icon">
          <div class="technos-tag__icon technos-tag__icon--green icon-check"></div>
        </div>
      </div>
    </div>
    <div
      class="technos-tag__container technos-tag__container-tag"
      v-else>
      <p class="technos-tag__text">
        {{ techno.name }}
      </p>
      <div class="technos-tag__wrapper">
        <div
          @click="isEditing = true"
          class="technos-tag__icon">
          <div class="technos-tag__icon icon-edit"></div>
        </div>
        <div
          @click="goDeleteTechno"
          class="technos-tag__icon">
          <div class="technos-tag__icon icon-trash"></div>
        </div>
      </div>
    </div>
    <bc-modal :active.sync="isModalOpen">
      <technos-modal-delete
        :techno-name="techno.name"
        @close-modal="closeModal"
        @delete-techno="deleteTechno">
      </technos-modal-delete>
    </bc-modal>
  </div>
</template>

<script>
  import TechnosModalDelete from '@/components/Technos/TechnosModal/TechnosModalDelete';
  import { apiType } from '@/macros/tags/types';
  import BcModal from '@/ui-kit/components/BcModal/BcModal';

  export default {
    components: {
      BcModal,
      TechnosModalDelete,
    },
    name: 'technos-tag',
    props: {
      techno: {
        type: Object,
        default: () => ({
          name: '',
        }),
      },
      isCreating: {
        type: Boolean,
        default: false,
      },
      tagType: {
        type: Number,
        default: apiType.techno,
      },
    },
    data() {
      return {
        isEditing: false,
        technoName: this.techno.name || '',
        isModalOpen: false,
      };
    },
    methods: {
      createTechno() {
        if (this.technoName.length > 0) {
          /**
           * We send the request to create a Techno to API
           */
          this.$store.dispatch('tags/createTag', {
            name: this.technoName,
            type: this.tagType,
          });
        }
      },
      editTechnoName() {
        /**
         * We send the request to the API to edit the name
         */
        this.$store.dispatch('tags/editTag', {
          id: this.techno._id,
          name: this.technoName,
          type: this.tagType,
        });
        /**
         * We set the editing to false
         */
        this.isEditing = false;
      },
      goDeleteTechno() {
        this.isModalOpen = true;
      },
      deleteTechno() {
        this.$store.dispatch('tags/deleteTag', {
          id: this.techno._id,
          type: this.tagType,
        });

        this.closeModal();
      },
      closeModal() {
        this.isModalOpen = false;
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.technoName = this.techno.name;
      });
    },
  };
</script>

<style lang="scss" scoped>
  .technos-tag {
    border-radius: 4px;
    font-size: $font-size-s;
    text-align: center;
    min-height: 50px;
    max-height: 50px;
    @include bp('tablet') {
      &:hover {
        .technos-tag__wrapper {
          display: flex;
        }
      }
    }

    &__container {
      position: relative;
      justify-content: center;
      align-items: center;
      width: 100%;

      &-tag {
        background: $color-blue-cello;
        padding: 15px 20px;
        color: $color-white;
      }
    }

    &__input {
      width: 100%;
      height: 100%;
      color: $color-blue;
      padding: 15px 20px;
      text-align: center;
      font-size: $font-size-s;
    }

    &__wrapper {
      position: absolute;
      right: 10px;
      @include bp('tablet') {
        display: none;
      }

      &--show {
        display: flex;
        right: 10px;
      }
    }

    &__icon {
      margin-right: 10px;
      @include bp('tablet') {
        color: rgba($color-white, 0.8);
        transition: 0.2s;
      }

      &:hover {
        cursor: pointer;
        color: rgba($color-white, 1);
      }

      &--red {
        color: $color-red-amaranth;

        &:hover {
          color: $color-red-amaranth;
        }
      }

      &--gray {
        color: $color-gray-regent;

        &:hover {
          color: $color-gray-regent;
        }
      }

      &--green {
        color: $color-green-lima;

        &:hover {
          color: $color-green-lima;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
</style>
