<template>
  <div
    class="modal-coders-item card"
    :class="{'modal-coders-item--checked': checked}"
    @click="$emit('on-check', coder)"
  >
    <div class="modal-coders-item__infos--container">
      <bc-checkbox
        class="modal-coders-item__checkbox"
        :id="coder._id"
        :value="checked"
        @input="$emit('on-check', coder)"
      ></bc-checkbox>
      <bc-avatar
        class="modal-coders-item__avatar"
        :src="coder.pictureUrl"
        @error="handleAvatarError">
      </bc-avatar>
      <div class="modal-coders-item__infos">
        <p
          class="modal-coders-item__infos--name"
          :class="{ 'modal-coders-item__infos--match': deduplication.fullNameMatch }"
        >
          {{ coder.firstName }} {{ coder.lastName }}
        </p>
        <div
          class="modal-coders-item__infos--item"
          :class="{ 'modal-coders-item__infos--match': deduplication.phoneMatch }">
          <i class="icon-phone"></i>
          <p>{{ coder.phone || '-' }}</p>
        </div>
        <div
          class="modal-coders-item__infos--item"
          :class="{ 'modal-coders-item__infos--match': deduplication.emailMatch }"
        >
          <i class="icon-mail"></i>
          <p>{{ coder.email || '-' }}</p>
        </div>
        <div
          class="modal-coders-item__infos--item"
          :class="{ 'modal-coders-item__infos--match': deduplication.linkedinMatch }"
        >
          <img src="@/assets/logo/linkedin.png" alt="linkedin icon" />
          <p>{{ linkedinUri }}</p>
        </div>
      </div>
    </div>
    <div class="modal-coders-item__buttons">
      <bc-button @click="handleClickProfile">
        {{ $t('companies.panel-hunt-process.see-the-profile') }}
      </bc-button>
    </div>
  </div>
</template>

<script>
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
import BcButton from '@/ui-kit/components/BcButton/BcButton';

export default {
  name: 'modal-coders-item',
  components: {
    BcCheckbox,
    BcAvatar,
    BcButton,
  },
  props: {
    coder: {
      type: Object,
      default: () => ({}),
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    coderUri() {
      const baseUri = `${window.location.protocol}//${window.location.host}`;
      return `${baseUri}/coders/${this.coder._id}?page=1&type=coders&category=my-notes&processes-activities=true&coaches-activities=true&users-activities=true&all-activities=true`;
    },
    deduplication() {
      return this.coder?.deduplication || {};
    },
    linkedinUri() {
      const linkedin = this.coder?.social?.linkedin;
      if (!linkedin) {
        return '-';
      }
      return linkedin.length > 42 ? linkedin.substring(0, 42) + '...' : linkedin;
    },
  },
  methods: {
    handleAvatarError(evt) {
      evt.target.src = require('@/assets/img/default-avatar.svg');
    },
    handleClickProfile() {
      window.open(this.coderUri, '_blank');
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-coders-item {
  min-height: 132px;
  background: $color-neutre-4;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin: 5px;

  &:hover {
    cursor: pointer;
  }

  &--checked {
    background: #E1EBFD;
    box-shadow: 0px 0px 0px 1px #3C80F7;
    backdrop-filter: blur(2px);
    border-radius: 10px;
  }

  &__checkbox {
    margin-right: 10px;
    :deep() {
      input {
        border-radius: 100%;
      }
    }
  }

  &__infos {
    flex-direction: column;

    &--container {
      align-items: center;
      gap: 10px;
    }

    &--name {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $color-blue-dodger;
      margin-bottom: 7px;
    }

    &--profession {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: $color-blue-dark-cello;
    }

    &--item {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $color-secondary-light;
      gap: 7px;
      margin: 2px 0;

      & > i, img {
        color: #3C80F7;
        font-size: initial;
        width: 16px;
        height: 16px;
      }
    }

    &--match {
      color: $color-red-mandy;
      & > i {
        color: $color-red-mandy;
      }
    }
  }

  &__avatar {
    width: 70px;
    height: 70px;
  }
}
</style>
