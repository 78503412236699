<template>
  <div class="processes-commission is-column">
    <p class="processes-commission__text">
      {{ $t('process.coach-commission-payment-info') }}
    </p>
    <div class="processes-commission__body">
      <div class="is-row processes-commission__body-content">
        <div class="is-column processes-commission__border">
          <p class="processes-commission__label">
            {{ $t('process.is-this-a-candidate-pass') }}
            <span class="processes-commission__label--required">*</span>
          </p>
          <div class="is-column">
            <div class="is-row">
              <ValidationProvider
                name="isPass"
                rules="included:1,2"
                v-slot="{ errors }">
                <bc-radio
                  :checked="isPass === 1"
                  :disabled="isSent"
                  :error="errors[0]"
                  class="processes-commission__radio"
                  id="pass"
                  name="Passe"
                  v-model="setIsPass">
                  {{ $t('generics.yes') }}
                </bc-radio>
              </ValidationProvider>
              <ValidationProvider
                name="isPass"
                rules="included:1,2"
                v-slot="{ errors }">
                <bc-radio
                  :checked="isPass === 2"
                  :disabled="isSent"
                  :error="errors[0]"
                  id="not-pass"
                  name="Pas une passe"
                  v-model="setIsNotPass">
                  {{ $t('generics.no') }}
                </bc-radio>
              </ValidationProvider>
            </div>
            <p
              class="processes-commission__error"
              v-if="!isPass && isErrorBagTriggered">
              {{ $t('process.please-inquire-if-this-is-a-candidate-pass') }}
            </p>
          </div>
          <div
            class="processes-commission__wrapper is-column"
            v-if="isPass === 1">
            <div class="processes-commission__autocomplete-container">
              <ValidationProvider
                :rules="{ required: true }"
                name="coachName"
                v-slot="{ errors }">
                <bc-autocomplete
                  :disabled="isSent"
                  :error="errors[0]"
                  :label-placeholder="false"
                  :options="filteredCoachesForCoderPasser"
                  :required="true"
                  @select="updateCoderPass('_coach', $event), autoSave('_coach', $event, 'coderPass')"
                  class="is-full-width processes-commission__autocomplete"
                  data-coder-pass-autocomplete
                  keep-first
                  :label="$t('process.if-so-who-is-the-ferryman')"
                  name="Coder coach passer"
                  open-on-focus
                  placeholder="Ex: Clément Gobet"
                  v-model="coderPasser">
                  <template #option="{ option }">
                    <bc-dropdown-item
                      :text="`${option.firstName} ${option.lastName}`"
                      class="fade-select-leave-active__option font-size-s is-full-width">
                    </bc-dropdown-item>
                  </template>
                </bc-autocomplete>
              </ValidationProvider>
            </div>
            <ValidationProvider
              :rules="{ required: true}"
              name="passPercent"
              v-slot="{ errors }">
              <bc-input-number
                :disabled="isSent"
                :error="errors[0]"
                :label-placeholder="false"
                :min="0"
                :required="true"
                :should-display-incrementer="false"
                :valid="true"
                @blur="autoSave('percent', $event, 'coderPass')"
                class="processes-commission__input-number"
                data-coder-pass-percent
                :label="$t('process.what-is-the-rate-applied-to-the-pass')"
                name="Taux de la passe"
                unity="%"
                v-model="coderPassPercent">
              </bc-input-number>
            </ValidationProvider>
            <div class="is-column">
              <p class="processes-commission__label">
                {{ $t('process.nature-of-the-pass?') }}
                <span class="processes-commission__label--required">*</span>
              </p>
              <div class="is-row">
                <ValidationProvider
                  :rules="{ required: true }"
                  name="passType"
                  v-slot="{ errors }">
                  <bc-radio
                    :checked="coderPass.type === formDealMacros.passTypes.in.apiValue"
                    :disabled="isSent"
                    :error="errors[0]"
                    class="processes-commission__radio"
                    data-coder-pass-source
                    id="in-pass"
                    name="Passe entrante"
                    v-model="coderPassTypeIn">
                    {{ $t('process.pass-in') }}
                  </bc-radio>
                </ValidationProvider>
                <ValidationProvider
                  :rules="{ required: true }"
                  name="passType"
                  v-slot="{ errors }">
                  <bc-radio
                    :checked="coderPass.type === formDealMacros.passTypes.out.apiValue"
                    :disabled="isSent"
                    :error="errors[0]"
                    id="out-pass"
                    name="Passe sortante"
                    v-model="coderPassTypeOut">
                    {{ $t('process.pass-out') }}
                  </bc-radio>
                </ValidationProvider>
              </div>
              <p
                class="processes-commission__error"
                v-if="!coderPass.hasOwnProperty('type') && isErrorBagTriggered">
                {{ $t('process.please-fill-in-the-type-of-pass') }}
              </p>
            </div>
          </div>
        </div>
        <div class="is-column">
          <p class="processes-commission__label">
            {{ $t('process.is-it-a-job-pass') }}
            <span class="processes-commission__label--required">*</span>
          </p>
          <div class="is-column">
            <div class="is-row">
              <ValidationProvider
                name="isJobPass"
                rules="included:1,2"
                v-slot="{ errors }">
                <bc-radio
                  :checked="isJobPass === 1"
                  :disabled="isSent"
                  :error="errors[0]"
                  class="processes-commission__radio"
                  id="passJob"
                  name="Passe job"
                  v-model="setIsJobPass">
                  {{ $t('generics.yes') }}
                </bc-radio>
              </ValidationProvider>
              <ValidationProvider
                name="isJobPass"
                rules="included:1,2"
                v-slot="{ errors }">
                <bc-radio
                  :checked="setIsJobPass === 2"
                  :disabled="isSent"
                  :error="errors[0]"
                  id="notPassJob"
                  name="Pas une passe job"
                  v-model="setIsNotJobPass">
                  {{ $t('generics.no') }}
                </bc-radio>
              </ValidationProvider>
            </div>
            <p
              class="processes-commission__error"
              v-if="!isJobPass && isErrorBagTriggered">
              {{ $t('process.please-inform-if-it-is-a-job-pass') }}
            </p>
          </div>
          <div
            class="processes-commission__wrapper is-column"
            v-if="isJobPass === 1">
            <div class="processes-commission__autocomplete-container">
              <ValidationProvider
                :rules="{ required: true }"
                name="coachName"
                v-slot="{ errors }">
                <bc-autocomplete
                  :disabled="isSent"
                  :error="errors[0]"
                  :label-placeholder="false"
                  :options="filteredCoachesForJobPasser"
                  :required="true"
                  @select="updateJobPass('_coach', $event), autoSave('_coach', $event, 'jobPass'), coachInput = ''"
                  class="is-full-width processes-commission__autocomplete"
                  data-job-pass-autocomplete
                  keep-first
                  :label="$t('process.if-so-who-is-the-passer-the-second')"
                  name="Job coach passer"
                  open-on-focus
                  placeholder="Ex: Clément Gobet"
                  v-model="jobPasser">
                  <template #option="{ option }">
                    <bc-dropdown-item
                      :text="`${option.firstName} ${option.lastName}`"
                      class="fade-select-leave-active__option font-size-s is-full-width">
                    </bc-dropdown-item>
                  </template>
                </bc-autocomplete>
              </ValidationProvider>
            </div>
            <ValidationProvider
              :rules="{ required: true }"
              name="passPercent"
              v-slot="{ errors }">
              <bc-input-number
                :disabled="isSent"
                :error="errors[0]"
                :label-placeholder="false"
                :min="0"
                :required="true"
                :should-display-incrementer="false"
                :valid="true"
                @blur="autoSave('percent', $event, 'jobPass')"
                class="processes-commission__input-number"
                data-job-pass-percent
                :label="$t('process.what-is-the-rate-applied-to-the-pass')"
                name="Taux de la passe job"
                placeholder="Ex: 10"
                unity="%"
                v-model="jobPassPercent">
              </bc-input-number>
            </ValidationProvider>
            <div class="is-column">
              <p class="processes-commission__label">
                {{ $t('process.nature-of-the-pass?') }}
                <span class="processes-commission__label--required">*</span>
              </p>
              <div class="is-row">
                <ValidationProvider
                  :rules="{ required: true }"
                  name="jobPassTypeIn"
                  v-slot="{ errors }">
                  <bc-radio
                    :checked="jobPass.type === formDealMacros.passTypes.in.apiValue"
                    :disabled="isSent"
                    :error="errors[0]"
                    class="processes-commission__radio"
                    data-job-pass-source
                    id="in-job-pass"
                    name="Passe job entrante"
                    v-model="jobPassTypeIn">
                    {{ $t('process.pass-in') }}
                  </bc-radio>
                </ValidationProvider>
                <ValidationProvider
                  :rules="{ required: true }"
                  name="jobPassTypeOut"
                  v-slot="{ errors }">
                  <bc-radio
                    :checked="jobPass.type === formDealMacros.passTypes.out.apiValue"
                    :disabled="isSent"
                    :error="errors[0]"
                    id="out-job-pass"
                    name="Passe job sortante"
                    v-model="jobPassTypeOut">
                    {{ $t('process.pass-out') }}

                  </bc-radio>
                </ValidationProvider>
              </div>
              <p
                class="processes-commission__error"
                v-if="!jobPass.hasOwnProperty('type') && isErrorBagTriggered">
                {{ $t('process.please-fill-in-the-type-of-pass') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  //
  import { macros } from "@/macros/processes/formDeal";
  //
  import { ValidationProvider, Validator } from 'vee-validate';
  import BcRadio from "@/ui-kit/components/BcRadio/BcRadio";
  import BcInputNumber from "@/ui-kit/components/BcInputNumber/BcInputNumber";
  import BcAutocomplete from "@/ui-kit/components/BcAutocomplete/BcAutocomplete";
  import BcDropdownItem from "@/ui-kit/components/BcDropdown/BcDropdownItem";

  const dict = {
    custom: {
      coachName: {
        required: 'Veuillez renseigner le nom du passeur',
      },
      passPercent: {
        required: 'Veuillez renseigner le pourcentage de la passe',
      },
    },
  };

  Validator.localize('fr', dict);

  export default {
    name: 'processes-commission',
    components: {
      BcDropdownItem,
      BcAutocomplete,
      BcInputNumber,
      BcRadio,
      ValidationProvider,
    },
    props: {
      coderPass: {
        type: Object,
        default: () => ({}),
      },
      jobPass: {
        type: Object,
        default: () => ({}),
      },
      isSent: {
        type: Boolean,
        default: false,
      },
      isErrorBagTriggered: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isPass: 0,
        isJobPass: 0,
        coderPasser: '',
        jobPasser: '',
        formDealMacros: macros,
      };
    },
    computed: {
      ...mapState({
        coaches: state => state.coaches.coaches,
      }),
      filteredCoachesForCoderPasser() {
        return (this.coaches || []).filter(coach => coach.email.indexOf(this.coderPasser.toLowerCase()) === 0);
      },
      filteredCoachesForJobPasser() {
        return (this.coaches || []).filter(coach => coach.email.indexOf(this.jobPasser.toLowerCase()) === 0);
      },
      coderPassPercent: {
        get() {
          return this.coderPass.percent;
        },
        set(value) {
          this.updateCoderPass('percent', value);
        },
      },
      jobPassPercent: {
        get() {
          return this.jobPass.percent;
        },
        set(value) {
          this.updateJobPass('percent', value);
        },
      },
      coderPassTypeIn: {
        get() {
          return this.coderPass.hasOwnProperty('type') ? this.coderPass.type : '';
        },
        set() {
          this.updateCoderPass('type', macros.passTypes.in.apiValue);
          this.autoSave('type', macros.passTypes.in.frontValue, 'coderPass');
        },
      },
      coderPassTypeOut: {
        get() {
          return this.coderPass.hasOwnProperty('type') ? this.coderPass.type : '';
        },
        set() {
          this.updateCoderPass('type', macros.passTypes.out.apiValue);
          this.autoSave('type', macros.passTypes.out.frontValue, 'coderPass');
        },
      },
      jobPassTypeIn: {
        get() {
          return this.jobPass.hasOwnProperty('type') ? this.jobPass.type : '';
        },
        set() {
          this.updateJobPass('type', macros.passTypes.in.apiValue);
          this.autoSave('type', macros.passTypes.in.frontValue, 'jobPass');
        },
      },
      jobPassTypeOut: {
        get() {
          return this.jobPass.hasOwnProperty('type') ? this.jobPass.type : '';
        },
        set() {
          this.updateJobPass('type', macros.passTypes.out.apiValue);
          this.autoSave('type', macros.passTypes.out.frontValue, 'jobPass');
        },
      },
      setIsPass: {
        get() {
          this.disableSend();
          return this.isPass;
        },
        set() {
          this.isPass = 1;
          this.disableSend();
        },
      },
      setIsNotPass: {
        get() {
          return this.isPass;
        },
        set() {
          this.isPass = 2;
          this.emptyCoderPass();
        },
      },
      setIsJobPass: {
        get() {
          this.disableSend();
          return this.isJobPass;
        },
        set() {
          this.isJobPass = 1;
        },
      },
      setIsNotJobPass: {
        get() {
          return this.isJobPass;
        },
        set() {
          this.isJobPass = 2;
          this.emptyJobPass();
        },
      },
    },
    methods: {
      emptyCoderPass() {
        this.$emit('empty-coder-pass');
        this.coderPasser = '';
      },
      emptyJobPass() {
        this.$emit('empty-job-pass');
        this.jobPasser = '';
      },
      updateCoderPass(field, value) {
        this.$emit('change-coder-pass', { field, value });
      },
      updateJobPass(field, value) {
        this.$emit('change-job-pass', { field, value });
      },
      autoSave(field, value, passName) {
        this.$emit('auto-save', { field, value }, passName);
      },
      disableSend() {
        this.$emit('disable-send');
      },
    },
    watch: {
      coderPass: {
        handler() {
          if (Object.keys(this.coderPass).length > 0) {
            this.isPass = 1;
          }

          this.coderPasser = this.coderPass._coach ? `${this.coderPass._coach.firstName} ${this.coderPass._coach.lastName}` : '';
        },
        immediate: true,
        deep: true,
      },
      jobPass: {
        handler() {
          if (Object.keys(this.jobPass).length > 0) {
            this.isJobPass = 1;
          }
          this.jobPasser = this.jobPass._coach ? `${this.jobPass._coach.firstName} ${this.jobPass._coach.lastName}` : '';
        },
        immediate: true,
        deep: true,
      },
    },
  };
</script>

<style lang=scss scoped>
  .processes-commission {
    margin: 15px 0;
    color: $color-secondary;

    &__text {
      @include font-medium(20px);
      line-height: 24px;
      color: $color-primary;
      margin-left: 30px;
    }

    &__body {
      padding: 20px 0;
      background-color: #F7F9FC;
      height: auto;
    }

    &__body-content {
      flex-wrap: wrap;
      flex-grow: 0;
      width: 100%;

      & > div {
        width: 50%;
        height: 100%;
        padding: 0 35px;
      }
    }

    &__border {
      border-right: 2px solid $color-grey-4;
    }

    &__radio {
      width: 180px;
      justify-content: start;
    }

    &__label {
      font-size: $font-size-s;
      line-height: 14px;
      text-align: left;
      color: $color-grey-4;
      margin-bottom: 10px;
      margin-left: 10px;

      &--required {
        color: $color-error;
      }
    }

    &__input {
      margin-bottom: 15px;
      width: 350px;
      background-color: $color-white;
    }

    &__autocomplete-container {
      margin: 10px 0;
    }

    &__autocomplete {
      margin-top: 5px;

      & :deep() {
        .bc-input {
          width: 350px;
          margin-top: 0;

          &__wrapper {
            background-color: white;

            &--disabled {
              pointer-events: none;
              background-color: $color-grey-2;
              border-color: $color-grey-4;
              opacity: 0.5;
            }
          }
        }
      }
    }

    &__wrapper {
      margin-top: 15px;
    }

    &__input-number {
      margin-top: 20px;
      margin-bottom: 10px;

      & :deep() {
        .bc-input-number {
          &__input {
            width: 90px;
            height: 40px;
          }
        }
      }
    }

    &__error {
      font-size: 14px;
      margin-top: 5px;
      color: $color-error;
    }
  }
</style>
