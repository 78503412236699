<template>
  <div class="views-container col-xs-12 is-align-items-center">
    <div class="views-container__container col-xs-12">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'views-container',
  };
</script>

<style lang="scss" scoped>
  .views-container {
    padding: 20px 30px;
  }
</style>
