<template>
  <div class="reminders-panel border-radius-s is-relative is-column">
    <button
      class="reminders-panel__cross icon-cross is-absolute font-size-s"
      @click="closeDropdown"></button>
    <div class="reminders-panel__container is-column">
      <h2 class="reminders-panel__title font-weight-regular font-size-xxl">
        {{ $t('companies.account-management.appointment-creation') }}
      </h2>
      <label class="reminders-panel__title-label is-relative">
        <input
          v-model="title"
          :placeholder="placeholder"
          class="reminders-panel__title-input font-size-m is-full-width"
          type="text">
        <span class="reminders-panel__title-input-border"></span>
      </label>
      <div class="reminders-panel__types is-row is-flex-wrap">
        <button
          class="reminders-panel__type border-radius-s reminders-panel__type--active">
          {{ $t('companies.account-management.exchange') }}
        </button>
      </div>
    </div>
    <div class="reminders-panel__wrapper is-row is-relative">
      <span
        class="reminders-panel__icon is-absolute icon-clock font-size-xxl is-align-items-center is-flex"></span>
      <v-date-picker
        v-model="date"
        :locale="locale"
        :min-date="new Date()"
        :popover="{ positionFixed: true }"
        is-required>
        <template v-slot="{ togglePopover }">
          <button
            class="reminders-panel__date-picker"
            @click="togglePopover">
            {{ formattedDate }}
          </button>
        </template>
      </v-date-picker>
      <bc-dropdown
        :options="timeOptions"
        class="reminders-panel__dropdown reminders-panel__dropdown"
        is-popper
        @change="setTime">
        <template #label="{ isDropdownOpen }">
          <bc-dropdown-trigger
            :is-arrow="true"
            :is-dropdown-open="isDropdownOpen"
            :text="formattedTime"
            class="reminders-panel__time-picker">
          </bc-dropdown-trigger>
        </template>
        <template #option="{ option }">
          <bc-dropdown-item
            :text="option"
            class="reminders-panel__item"
            @click.native="setTime(option)">
          </bc-dropdown-item>
        </template>
      </bc-dropdown>
    </div>
    <div class="reminders-panel__wrapper is-row is-relative">
      <span
        class="reminders-panel__icon is-absolute icon-user-plus font-size-xxl is-align-items-center is-flex"></span>
      <div class="is-column">
        <bc-dropdown
          :close-dropdown-at-select="false"
          class="reminders-panel__dropdown reminders-panel__dropdown--contacts"
          is-popper>
          <template #label="{ isDropdownOpen }">
            <button class="reminders-panel__input">
              {{ $t('companies.account-management.add-guests') }}
            </button>
          </template>
          <template #custom>
            <div class="reminders-panel__options is-column">
              <bc-checkbox
                v-for="contact in company._contacts"
                :id="contact._id"
                :key="contact._id"
                :reversed="true"
                :value="getContactsToAddCheckboxValue(contact)"
                class="reminders-panel__checkbox is-justify-content-space-between is-full-width"
                @input="setContactsToAdd(contact)">
                <p class="reminders-panel__checkbox-text font-weight-bold">
                  {{ contact.firstName }} {{ contact.lastName }}
                </p>
                <p class="reminders-panel__checkbox-text">
                  {{ contact.job }}
                </p>
              </bc-checkbox>
            </div>
          </template>
        </bc-dropdown>
        <div
          v-for="contact in contactsToAdd"
          :key="contact._id"
          class="reminders-panel__list">
          <p class="reminders-panel__list-text font-weight-medium font-size-s">
            {{ contact.firstName }} {{ contact.lastName }}
          </p>
          <p class="reminders-panel__list-text font-size-s">
            {{ contact.job }}
          </p>
          <button
            class="reminders-panel__list-icon icon-cross font-size-xs is-flex is-align-items-center"
            @click.stop="setContactsToAdd(contact)">
          </button>
        </div>
      </div>
    </div>
    <div class="is-justify-content-end">
      <bc-button
        class="reminders-panel__button"
        type="outlined"
        @click="closeDropdown">
        {{ $t('generics.cancel') }}
      </bc-button>
      <bc-button
        class="reminders-panel__button"
        color="success"
        @click="goCreateReminder">
        {{ $t('companies.account-management.end-the-appointment') }}
      </bc-button>
    </div>
  </div>
</template>

<script>
  import moment from 'moment/min/moment-with-locales';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
  import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';

  import { timeOptions } from '@/macros/reminders/reminders';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
  import locale from '@/mixins/i18n/locale';

  export default {
    name: 'reminders-panel',
    mixins: [locale],
    components: {
      BcCheckbox,
      BcDropdownItem,
      BcButton,
      BcDropdownTrigger,
      BcDropdown,
    },
    props: {
      company: {
        type: Object,
        default: () => ({}),
      },
      coder: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        title: '',
        placeholder: `Échange ${this.coder.firstName} ${this.coder.lastName} @ ${this.company.name}`,
        date: new Date(),
        time: {
          hour: '12',
          minutes: '00',
        },
        timeOptions: timeOptions(),
        contactsToAdd: [],
      };
    },
    computed: {
      formattedDate() {
        return moment(this.date).format('LL');
      },
      formattedTime() {
        return `${this.time.hour}:${this.time.minutes}`;
      },
    },
    methods: {
      getContactsToAddCheckboxValue(contact) {
        return this.contactsToAdd.some(
          contactToAdd => contactToAdd._id === contact._id,
        );
      },
      setTime(time) {
        const [hour, minutes] = time.split(':');

        this.time.hour = hour;
        this.time.minutes = minutes;
      },
      setContactsToAdd(contact) {
        const isContactAlreadyInContactsToAdd = this.contactsToAdd.some(
          contactToAdd => contactToAdd._id === contact._id,
        );

        if (isContactAlreadyInContactsToAdd) {
          this.contactsToAdd = this.contactsToAdd.filter(
            contactToAdd => contactToAdd._id !== contact._id,
          );
        } else {
          this.contactsToAdd.push(contact);
        }
      },
      goCreateReminder() {
        const title = this.title || this.placeholder;

        const contactsDescription = this.contactsToAdd.map(
          contact =>
            `${contact.firstName} ${contact.lastName} : ${contact.mobile}`,
        );
        const coderDescription = `${this.coder.firstName} ${this.coder.lastName} : ${this.coder.phone}`;
        const description = [...contactsDescription, coderDescription].join(
          '%0D%0A',
        );

        const startDate = moment(this.date).format('YYYYMMDD');

        const hour = this.time.hour - 2; // Timezone
        const minutes = this.time.minutes;

        const contactsEmail = this.contactsToAdd.map(contact => contact.email);
        const coderEmail = this.coder.email;
        const invites = [...contactsEmail, coderEmail].join(',');

        window.open(
          `
              https://calendar.google.com/calendar/r/eventedit?text=${title}&details=${description}&location=location&dates=${startDate}T${hour}${minutes}00Z/${startDate}T${
            hour + 1
          }${minutes}00Z&add=${invites}`,
          '_blank',
        );

        this.closeDropdown();
      },
      closeDropdown() {
        this.$emit('close-dropdown');
      },
    },
  };
</script>

<style lang=scss scoped>
  .reminders-panel {
    cursor: initial;
    overflow: auto;
    padding: 20px;

    @include bp('tablet') {
      padding: 20px 50px 20px 100px;
    }

    &__cross {
      right: 50px;
    }

    &__container {
      margin-bottom: 50px;
    }

    &__wrapper {
      margin-bottom: 20px;
    }

    &__title-label {
      margin-bottom: 10px;
    }

    &__title-input {
      border-bottom: 1px solid $color-secondary;
      color: $color-secondary;
      padding: 5px 0;

      &::placeholder {
        color: $color-secondary;
      }

      &:focus ~ .reminders-panel__title-input-border {
        width: 100%;
      }
    }

    &__title-input-border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: $color-primary;
      transition: 0.4s;
    }

    &__types {
      margin: -5px -15px;
    }

    &__type {
      padding: 5px 10px;
      transition: 0.3s;
      margin: 5px 15px;
      color: $color-secondary;

      &--active {
        background: rgba($color-primary, 0.2);
        color: $color-primary;
      }

      &:hover {
        background: rgba($color-primary, 0.2);
        color: $color-primary;
      }
    }

    &__icon {
      left: -50px;
    }

    &__date-picker {
      line-height: 22px;
      margin-right: 30px;
    }

    &__time-picker {
      color: $color-secondary !important;
      background: none !important;
      border: 0 !important;
      min-width: 80px;
      height: 22px;
      padding: 0;
    }

    &__item {
      min-width: 180px;
      padding: 7.5px 15px;
    }

    &__input {
      color: rgba($color-secondary, 0.5);
      padding: 2.5px 0;
    }

    &__button {
      &:first-of-type {
        margin-right: 15px;
      }
    }

    &__options {
      min-width: 350px;
      padding: 15px 10px;
    }

    &__checkbox {
      padding: 10px;
    }

    &__list-icon {
      opacity: 0;
      margin-left: auto;
    }

    &__list {
      margin: 5px 0;
      min-width: 250px;

      &:hover {
        .reminders-panel__list-icon {
          opacity: 0.5;
        }
      }
    }

    &__checkbox-text,
    &__list-text {
      &:last-of-type {
        margin: 0 20px 0 5px;
      }
    }

    &__dropdown {
      &--contacts {
        margin-bottom: 10px;
      }
    }
  }
</style>
