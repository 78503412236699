<template>
  <views class="account-management col-xs-12">
    <companies-tabs>
      <div
        class="account-management__wrapper is-relative is-justify-content-center is-align-items-center ml-5 mr-2">
        <bc-dropdown :options="coachesOptions" class="account-management__dropdown" is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen"
              :text="selectedCoachName"
              class="account-management__filter"
              :is-filter="true"
              icon="user">
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="option.name"
              @change="selectCoach(option)"
              @click.native="selectCoach(option)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
      </div>
    </companies-tabs>
    <router-view></router-view>
    <views-container>
      <bc-spinner v-if="!isLoaded" class="account-management__spinner"> </bc-spinner>
      <div v-else class="is-align-items-center is-column">
        <companies-grid
          :is-checked="isAllCardsSelected"
          class="hidden-xs"
          @checked="selectAllCards">
        </companies-grid>
        <card-list ref="cardList" :cards="cards" :is-loaded="true" class="pagination__card-list">
          <template #card="{ card }">
            <card :card-content="card" card-type="partners">
              <card-companies
                :card-content="card"
                :is-selected="getIsSelectedStatus(card._id)"
                @checked="setSelectedCards($event, card)">
              </card-companies>
            </card>
          </template>
        </card-list>
      </div>
      <bc-pagination
        :current.sync="currentPage"
        :total="queryCount"
        class="account-management__pagination">
      </bc-pagination>
      <multiselect-footer
        v-if="selectedCards.length"
        class="account-management__footer is-fixed is-bottom is-left is-full-width is-justify-content-center is-align-items-center is-column">
      </multiselect-footer>
    </views-container>
  </views>
</template>

<script>
import axios from 'axios';
import { mapActions, mapState } from 'vuex';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
import BcPagination from '@/ui-kit/components/BcPagination';
import CardList from '@/components/CardList/CardList';
import CompaniesGrid from '@/components/Companies/CompaniesGrid';
import Card from '@/components/Card/Card';
import CardCompanies from '@/components/Card/CardCompanies';
import MultiselectMixin from '@/mixins/Multiselect/multiselect';
import MultiselectFooter from '@/components/Multiselect/MultiselectFooterLegacy';
import { getFilterablePoolCoach } from '@/macros/prospectionPipe/dropdowns';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown';
import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import Views from '@/components/Views/Views';
import ViewsContainer from '@/components/Views/ViewsContainer';
import CompaniesTabs from '@/views/Tabs/CompaniesTabs';
import { prospectionStatusCategoryApi } from '@/macros/companies/prospectionStatus';

export default {
  name: 'account-management',
  components: {
    ViewsContainer,
    Views,
    BcDropdownItem,
    BcDropdownTrigger,
    BcDropdown,
    MultiselectFooter,
    CardCompanies,
    Card,
    CompaniesGrid,
    BcSpinner,
    BcPagination,
    CardList,
    CompaniesTabs,
  },
  mixins: [MultiselectMixin],
  data() {
    return {
      isLoaded: true,
      currentPage: parseInt(this.$route.query.page) || 1,
      cancelTokenSource: undefined,
    };
  },
  computed: {
    ...mapState({
      cards: state => state.card.cards,
      queryCount: state => state.card.count,
      profile: state => state.user.profile,
      coaches: state => state.coaches?.coaches,
    }),
    parameters() {
      return {
        owner: this.$route.query.owner,
        category: prospectionStatusCategoryApi.signed
      };
    },
    coachesOptions() {
      const coaches = this.coaches
        .filter(coach => getFilterablePoolCoach(coach))
        .map(coach => ({
          name: `${coach.firstName} ${coach.lastName}`,
          query: coach._id,
        }));

      return [
        {
          name: this.$i18n.t('generics.none'),
          query: 'none',
        },
        ...coaches.sort((a, b) => a.name.localeCompare(b.name)),
      ];
    },
    selectedCoach() {
      return this.coaches.find(coach => coach._id === this.$route.query.owner);
    },
    selectedCoachName() {
      return this.selectedCoach
        ? `${this.selectedCoach.firstName} ${this.selectedCoach.lastName}`
        : this.$i18n.t('generics.none');
    },
  },
  created() {
    this.cancelTokenSource = axios.CancelToken.source();
    this.setCount(1);
    this.getPartners();
  },
  beforeDestroy() {
    this.cancelTokenSource.cancel('manualCancel');
    this.resetSelectedCards();
  },
  methods: {
    ...mapActions({
      resetCards: 'resetCards',
      getCards: 'getCards',
      setCount: 'setCount',
      setCoachRessourceCount: 'metadata/setCoachRessourceCount',
    }),
    selectCoach(option) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          owner: option.query,
        },
      });
    },
    goNewCompany() {
      this.$router.push({
        name: `${this.$route.name}Panel`,
        params: {
          id: 'new-company',
        },
        query: {
          ...this.$route.query,
          type: 'create-company',
        },
      });
    },
    async getPartners() {
      this.isLoaded = false;

      try {
        this.resetCards();
        const { data } = await this.getCards({
          page: this.$route.query.page,
          call: 'partners/lookup',
          parameters: this.parameters,
          responseKeyName: 'companies',
          cancelTokenSource: this.cancelTokenSource,
        });

        this.setCoachRessourceCount({ ressourceType: 'client', count: data.count })
        this.isLoaded = true;
      } catch (error) {
        this.$emit('error', error);

        this.isLoading = true;
      }
    },
  },
  watch: {
    $route: {
      handler(to = {}, from = {}) {
        const pageChange = from.query.page !== to.query.page;
        const ownerChange = from.query.owner !== to.query.owner;

        if (pageChange || ownerChange) {
          this.getPartners();
          this.currentPage = parseInt(to.query.page) || 1;
        }
      },
      deep: true,
    },
    currentPage(newPage) {
      this.$router.push({
        ...this.$route,
        query: { ...this.$route.query, page: newPage },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.account-management {
  margin-top: 0;

  @include bp('desktop') {
    margin-top: 0;
  }

  &__title {
    @include font-bold(22px);
    line-height: 25px;
    color: $color-secondary;
    margin-bottom: 0;
    text-align: center;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 19px;
    color: $color-secondary;
    text-align: center;
  }

  &__pagination {
    margin: 20px 0;
  }

  &__spinner {
    margin: 100px 0;
  }

  &__card-list {
    margin-bottom: 100px;
  }

  &__spinner,
  &__pagination,
  &__card-list {
    justify-content: center;
  }

  &__filter {
    min-width: 180px;
  }

  &__dropdown {
    z-index: 3;
  }
}
</style>
