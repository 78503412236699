<template>
  <modal-feature
    feature="customNotes"
    :title="$t('features.create-note-templates')"
    @close-modal="$emit('close-modal')"
  >
    <template #content>
      <p>{{ $t('features.faster-with-note-templates') }}</p>
    </template>
    <template #logo>
      <img src="@/assets/logo/feature-custom-notes.png" alt="custom notes feature logo" />
    </template>
  </modal-feature>
</template>

<script>
import ModalFeature from './ModalFeature.vue';

export default {
  name: 'modal-feature-custom-notes',
  components: {
    ModalFeature,
  },
}
</script>
