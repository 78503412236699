import { apiRoles } from '@/macros/coaches/roles';

export const rolesVisible = [
  apiRoles.businessManager,
  apiRoles.humanResources,
  apiRoles.administration,
  apiRoles.presales,
  apiRoles.lead,
  apiRoles.coach,
  apiRoles.mentor,
];

export const rolesBusinessVisible = [
  apiRoles.businessManager,
  apiRoles.presales,
  apiRoles.lead,
  apiRoles.coach,
  apiRoles.mentor,
];

export const rolesPoolVisible = [
  apiRoles.businessManager,
  apiRoles.presales,
  apiRoles.lead,
  apiRoles.coach,
  apiRoles.mentor,
  apiRoles.humanResources,
];

export const teamRoles = {
  business: 1,
  humanResources: 2,
};

export const getBusinessTeams = coaches => {
  return coaches.reduce((acc, coach) => {
    const isHumanResourcesCoach = rolesPoolVisible.includes(coach.role) && coach.enabled && coach.role === apiRoles.humanResources;

    if (isHumanResourcesCoach) {
      const humanResourcesTeamIndex = acc.findIndex(team => team.role === teamRoles.humanResources);

      if (humanResourcesTeamIndex >= 0) {
        acc[humanResourcesTeamIndex].team?.push(coach);
      } else {
        acc.push({
          name: 'Équipe RH',
          manager: coach,
          team: [coach],
          role: teamRoles.humanResources,
        });
      }
    } else if (rolesPoolVisible.includes(coach.role) && coach.enabled && coach?._manager?._id) {
      const managerIndex = acc.findIndex(team => team.manager._id === coach?._manager?._id);

      if (managerIndex >= 0) {
        acc[managerIndex].team?.push(coach);
      } else {
        acc.push({
          name: `Équipe de ${coach._manager.firstName}`,
          manager: coach._manager,
          team: [coach._manager, coach],
          role: teamRoles.business,
        });
      }
    }

    return acc;
  }, []);
};

export const getFilterableCoach = coach => coach.enabled && rolesVisible.includes(coach.role);
export const getFilterableBusinessCoach = coach => coach.enabled && rolesBusinessVisible.includes(coach.role);
export const getFilterablePoolCoach = (coach, withInactive = false) => {
  if (!withInactive) {
    return coach.enabled && rolesPoolVisible.includes(coach.role);
  }
  return rolesPoolVisible.includes(coach.role);
}

export default {
  rolesVisible,
  rolesBusinessVisible,
  rolesPoolVisible,
  getFilterableCoach,
  getFilterableBusinessCoach,
  getBusinessTeams,
};
