<template>
  <div class="container">
    <ConfigurationTemplatesTabs>
      <ConfigurationTemplatesHeader :title="$t('email.template-creation')" modal-type="modal-templates-linkedin" />
    </ConfigurationTemplatesTabs>
    <ViewsContainer>
      <div class="configuration-templates">
        <RouterView />
        <div v-if="linkedinTemplatesStore.isLoading" class="mt-5 justify-center">
          <BcSpinner />
        </div>
        <div v-else-if="!linkedinTemplatesStore.items.length" class="configuration-templates__empty">
          <p>🔍 {{ $t('templates.no-templates') }}</p>
        </div>
        <div
          v-else
          class="flex w-full flex-col gap-2.5">
          <div class="configuration-templates__titles w-full">
            <p class="w-3/12">{{ $t('email.template-name') }}</p>
            <p class="w-7/12">{{ $t('linkedin.preview') }}</p>
            <p class="w-1/12">{{ $t('generics.active') }}</p>
            <p class="w-1/12">{{ $t('generics.author') }}</p>
          </div>
          <div class="flex w-full flex-col gap-1">
            <ConfigurationTemplatesLinkedinItem
              v-for="template in linkedinTemplatesStore.items"
              :key="template._id"
              :is-disabled="template._coach._id !== profile._id"
              :template="template"
              @click="onClickTemplate"
              @toggle-activated="onToggleActivated" />
          </div>
        </div>
      </div>
    </ViewsContainer>
  </div>
</template>

<script lang="ts" setup>
import ViewsContainer from '@/components/Views/ViewsContainer.vue';
import ConfigurationTemplatesTabs
  from '@/components/Configuration/Tabs/ConfigurationTemplatesTabs.vue';
import ConfigurationTemplatesHeader
  from '@/components/Configuration/ConfigurationTemplates/ConfigurationTemplatesHeader.vue';
import { useStore } from '@/store.js';
import { useLinkedinTemplatesStore } from '@/store/pinia/linkedinTemplates';
import { onBeforeMount, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
import ConfigurationTemplatesLinkedinItem
  from '@/components/Configuration/ConfigurationTemplates/ConfigurationTemplatesLinkedin/ConfigurationTemplatesLinkedinItem.vue';
import type { LinkedinTemplate } from '@/domains/models/LinkedinTemplate';
import { useToast } from '@/ui-kit/components/BcToast';
import { AxiosError } from 'axios';
import { RouterView } from 'vue-router';

const store = useStore();
const profile = store.state.user.profile;

const route = useRoute();
const toast = useToast();

const linkedinTemplatesStore = useLinkedinTemplatesStore();

watch(() => route.query, async (query, prevQuery) => {
  if (!prevQuery || query?.coachId !== prevQuery?.coachId || query?.all !== prevQuery?.all) {
    await linkedinTemplatesStore.fetchLinkedinTemplates({
      coachId: query.coachId,
      all: query.all,
    });
  }
});

async function onClickTemplate(template: LinkedinTemplate) {
  store.dispatch('app/setParams', ({
    linkedinTemplate: template,
    disabled: (template._coach?._id || template._coach) !== profile._id,
  }));
  store.dispatch('app/setIsPanel', true);
  store.dispatch('app/setModal', 'modal-templates-linkedin');
};

async function onToggleActivated(template: LinkedinTemplate) {
  try {
    await linkedinTemplatesStore.editTemplate(template._id, {
      activated: !template.activated,
    });
  } catch (err) {
    if ((err as AxiosError).response) {
      const error = err as AxiosError;
      toast.show({
        type: 'error',
        message: error.response?.data?.message,
      });
    }
    toast.show({
      type: 'error',
      message: err,
    });
  }
};
</script>

<style lang="scss" scoped>
.container {
  flex-direction: column;
}

.configuration-templates {
  width: 100%;
  flex-direction: column;
  gap: 10px;
  position: relative;

  &__titles {
    padding: 5px 30px;

    &>p {
      color: $color-blue-heavy-dark;
      font-size: 13px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  &__items {
    position: relative;
    flex-direction: column;
    gap: 5px;

    &-loader {
      justify-content: center;
      align-items: center;
      min-height: 100px;
    }
  }

  &__block-feature {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4fd 0%, #fafbff 100%);
    opacity: 0.5;
  }

  &__warning {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    margin-bottom: 15px;
    color: $color-blue-dark-cello;

    &-container {
      width: 100%;
      justify-content: flex-end;
      margin-bottom: 5px;
    }
  }

  &__empty {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    color: $color-tertiary;
  }
}
</style>

<style lang="scss">
.tooltip-custom {
  height: 60vh;

  .tooltip-inner {
    max-width: 100%;
    height: 100%;
  }
}
</style>
