<template>
  <div class="panel-reminder-coder-notes is-full-width">
    <panel-reminder-coder-timeline
      :profile="profile._coder"
      user-type="suggestions-coders"
      @echange-created="handleEchangeCreated"
      @reminder-created="handleReminderCreated"
      @qualification-created="handleQualificationCreated"
    >
    </panel-reminder-coder-timeline>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import PanelReminderCoderTimeline from '@/components/Panel/PanelReminder/PanelReminderCoder/PanelReminderCoderTimeline';
import suggestionsHttp from '@/api/suggestions';
import ToastMixin from '@/mixins/toast';
import suggestionsMacros from '@/macros/suggestions';
import moment from 'moment/min/moment-with-locales';

export default {
  name: 'panel-reminder-coder-notes',
  components: {
    PanelReminderCoderTimeline
  },
  mixins: [ToastMixin],
  props: {
    profile: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      shouldCardBeRemoved: false
    };
  },
  computed: {
    ...mapState({
      cards: state => state.card.cards
    }),
    ...mapGetters({
      cardActive: 'cardActive'
    }),
    isModalOpen() {
      return !!this.$route.query.synthesis;
    }
  },
  created() {
    const { query } = this.$route;
    const queryMap = [
      'processes-activities',
      'coaches-activities',
      'users-activities',
      'all-activities'
    ];

    if (!queryMap.every(q => query[q])) {
      this.setProcessesActivities();
    }
  },
  beforeDestroy() {
    if (this.shouldCardBeRemoved) {
      this.removeCardFromList(this.profile._id);

      this.shouldCardBeRemoved = false;
    }
  },
  methods: {
    setProcessesActivities() {
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          'processes-activities': true,
          'coaches-activities': true,
          'users-activities': true,
          'all-activities': true
        }
      });
    },
    async handleQualificationCreated(event) {
      try {
        if (event.dateToContact) {
          this.$emit('profile-updated', {
            _coder: event._coder,
            status: suggestionsMacros.status.postponed.value
          });
        } else {
          this.$emit('profile-updated', {
            _coder: event._coder,
            status: suggestionsMacros.status.qualified.value
          });
        }

        this.shouldCardBeRemoved = false;
      } catch (error) {
        this.$_displayErrorToast(error);
      }
    },
    async handleEchangeCreated(event) {
      try {
        await suggestionsHttp.acceptSuggestion(this.profile._id);
        this.$emit('profile-updated', {
          _coder: event._coder,
          status: suggestionsMacros.status.accepted.value
        });
      } catch (error) {
        this.$_displayErrorToast(error);
      }
    },
    async handleReminderCreated(event) {
      try {
        const today = moment().startOf('day');
        const dateToContact = moment(event?.dateToContact)
          .add(4, 'hours')
          .startOf('day');

        const postponedDays = dateToContact.diff(today, 'days');

        await suggestionsHttp.postponeSuggestion(this.profile._id, postponedDays, false);

        this.$emit('profile-updated', {
          _coder: event._coder,
          status: suggestionsMacros.status.postponed.value
        });

        this.shouldCardBeRemoved = true;
      } catch (error) {
        this.$_displayErrorToast(error);
      }
    },
    removeCardFromList(id) {
      this.$store.dispatch('removeCard', id);
      this.$store.dispatch('removeSuggestionFromToDoSuggestions', id);
    }
  },
  watch: {
    'profile._id'(to, from) {
      if (this.shouldCardBeRemoved) {
        this.removeCardFromList(from);

        this.shouldCardBeRemoved = false;
      }
    }
  }
};
</script>
