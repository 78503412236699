<template>
  <div class="email-send-form-companies">
    <div v-if="verified" class="is-column">
      <div class="email-send-form-companies__content">
        <!-- Template select -->
        <div class="w-full justify-end">
          <bc-dropdown
            :is-scrollable="true"
            :options="templatesOptions"
            position="bottom-left">
            <template #label="{ isDropdownOpen }">
              <mv-select
                :as-button="true"
                :selected-label="selectedTemplate?.name || $t('generics.select-a-template')"
                :value="selectedTemplate?.value"
                icon="file"
                label-size="l"
                required
              >
              </mv-select>
            </template>
            <template #option="{ activeIndex, index, option }">
              <bc-dropdown-item
                :is-focus="activeIndex === index"
                class="relative flex w-full"
                @click.native="onSelectTemplate(option.slug)">
                <div class="flex w-full">
                  <p class="w-full truncate">{{ option.name }}</p>
                  <bc-avatar
                    v-tooltip="{ trigger: 'hover', content: option.default ? `${$t('generics.created-by')} ${profile._organization?.name}` :`${$t('generics.created-by')} ${option?.coach?.firstName} ${option?.coach?.lastName}` }"
                    :src="option.default ? profile._organization?.logoUrl : option?.coach?.pictureUrl"
                    class="ml-3"
                    :placeholder="getUserInitials(option?.coach)"
                    size="xs"
                    @error="setAlternativeImg"/>
                </div>
              </bc-dropdown-item>
            </template>
          </bc-dropdown>
        </div>

        <EmailVariablesList :variables="templatesVariablesFiltred" class="mt-2"/>

        <div class="flex flex-col">
          <p class="font-size-xs mb-2 ml-2 text-blue-400">Compte client ciblé <span class="text-red-500">*</span></p>
          <div class="flex flex-wrap gap-2">
            <tag
              v-for="card of selectedCompaniesMapped.slice(0, 4)"
              :key="card._id"
              :can-close="true"
              :text="card.name"
              :avatar="card.logoUrl"
              type="company"
              @click="removeEmailCard(card._id)"
            />
            <div v-click-outside="closeTooltip" @mouseover="onShowTooltip">
              <tag
                v-if="selectedCompaniesMapped.length > 4"
                v-tooltip.bottom="{ trigger: 'manual', show: showTooltip, html: true, content: tooltipContent }"
                :text="`+${selectedCompaniesMapped.slice(4).length}`"
                type="company"
              />
            </div>
          </div>
        </div>

        <!-- Recipient -->
        <div>
          <mv-select
            :items="recipientEmailsOptions"
            :value="form.recipient"
            is-absolute
            label="Destinataire"
            label-size="l"
            required
            @select="onSelectRecipient"
          ></mv-select>
        </div>

        <!-- Sender's name -->
        <div class="mt-2">
          <bc-input
            v-model="form.senderName"
            :label="$t('email.sender-name')"
            :label-placeholder="false"
            :valid="!!form.senderName"
            class="is-full-width"
            name="sender"
            required></bc-input>
        </div>

        <!-- CC -->
        <div class="mt-2 block">
          <bc-autocomplete
            v-model="ccValue"
            :is-scrollable="false"
            :label-placeholder="false"
            :options="ccOptionsComputed"
            class="is-full-width"
            label="CC"
            name="cc"
            type="email"
            v-bind="$attrs"
            @input="getCcSuggestions"
            @select="onSelectCc">
            <template #option="{ option }">
              <div class="gap-3 p-3">
                <div>
                  <bc-avatar :src="option.logo" @error="setAlternativeImg"/>
                </div>
                <div class="is-column">
                  <p v-if="option.name">{{ option.name }}</p>
                  <p class="text-sm">{{ option.address }}</p>
                </div>
              </div>
            </template>
          </bc-autocomplete>
          <div class="mt-2 gap-2">
            <tag
              v-for="email in form.cc"
              :key="email.key"
              :can-close="true"
              :text="email.address"
              @click="onRemoveCc(email)"
            />
          </div>
        </div>

        <!-- Mail object -->
        <div class="mt-2">
          <bc-input
            v-model="form.object"
            :label="$t('email.object')"
            :label-placeholder="false"
            :valid="!!form.object"
            class="is-full-width"
            name="mail-object"
            required></bc-input>
        </div>

        <!-- Mail body -->
        <div class="is-column mt-2">
          <bc-text-editor
            v-model="form.body"
            :label="$t('email.body')"
            class="mv-text-editor-preview is-full-width"
            required></bc-text-editor>
          <div class="is-column">
            <bc-button
              class="mt-2"
              icon-left="attachment"
              type="outlined"
              @click="onUpload"
            >
              {{ $t('email.add-an-attachment') }}
            </bc-button>
            <input
              ref="uploadInput"
              multiple
              style="display: none;"
              type="file"
              @change="onUploadSelect"
            />
            <EmailAttachmentList
              :attachments="attachments"
              :files="files"
              class="mt-3"
              @on-remove="onRemoveFile"
            />
          </div>
        </div>
      </div>
      <div class="email-send-form-companies__content">
        <EmailPreviewCompanies
          :attachments="attachments"
          :body="form.body"
          :cc="form.cc"
          :files="files"
          :object="form.object"
          :recipientType="form.recipient"
          :recipientName="`${coder.firstName} ${coder.lastName}`"
          :companies="selectedCompaniesMapped"
          :contacts="selectedContacts"
          :senderEmail="emailConfig.email"
          :senderName="form.senderName"
          :signature="emailConfig.signature"
          :templatePreview="templatePreview"
          @on-empty-variables="onEmptyVariables"
          @on-select-company="onSelectCompany"
          @on-select-contact="onSelectContact"
        ></EmailPreviewCompanies>
      </div>
      <div class="email-send-form-companies__footer">
        <bc-button
          :disabled="isSubmitting"
          icon-left="cross"
          type="outlined"
          @click="$emit('on-close')"
        >
          {{ $t('generics.cancel') }}
        </bc-button>
        <bc-button
          :disabled="!isFormValid || isSubmitting || emptyVariables.length || (!recipientEmailsOptions.length && !selectedCompaniesMapped.length)"
          icon-left="check"
          @click="onSendMail">
          {{ $t('generics.send') }}
        </bc-button>
      </div>
      <div v-if="emptyVariables.length" class="is-column mb-[100px] gap-2 py-2 text-center">
        <p v-for="emptyVariable in emptyVariables" :key="emptyVariable.label" class="px-5 text-sm">
          ⚠️ {{ $t('email.empty-variable-warning') }} <span
          class="text-red-500">{{ emptyVariable.label }}*</span> {{ emptyVariable.warning === 'warning-multiple-values' ? $t('email.has-multiple-values') : $t('email.has-no-value') }}
        </p>
        <p v-if="warningMessage && emptyVariables.length" class="px-5 text-sm">{{ warningMessage }}</p>
      </div>
      <div
        v-if="!recipientEmailsOptions.length && !selectedCompaniesMapped.length"
        class="is-column mb-[100px] gap-2 py-2 text-center"
      >
        <p class="text-sm">
          ⚠️ {{ $t('email.empty-recipient-emails') }}
        </p>
      </div>
    </div>
    <div v-if="!verified && !isLoading" class="email-send-form-companies__warning">
      Please check your email configuration
    </div>
  </div>
</template>

<script>
  import debounce from 'debounce';
  import { mapActions, mapState } from 'vuex';

  import {
    createHistory,
    createTemplate,
    getConfiguration,
    getEmailsAutocomplete,
    getVariablesPreview,
    sendMail,
    sendMailGroup,
  } from '@/api/emails';
  import { uploadFile } from '@/api/upload';
  import { getCompaniesFavoriteEmployees } from '@/api/employees';
  import { validateEmail } from '@/helpers/strings';

  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import BcButton from '@/ui-kit/components/BcButton';
  import BcInput from '@/ui-kit/components/BcInput';
  import BcTextEditor from '@/ui-kit/components/BcTextEditor/BcTextEditor';
  import BcAutocomplete from '@/ui-kit/components/BcAutocomplete';
  import MvSelect from '@/ui-kit/components/MvSelect/MvSelect';
  import Tag from '@/components/Tag/Tag';
  import EmailPreviewCompanies from '@/components/Email/EmailPreviewCompanies';
  import EmailAttachmentList from '@/components/Email/EmailAttachmentList';
  import EmailVariablesList from '@/components/Email/EmailVariablesList';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';

  export default {
    name: 'email-send-form-companies',
    components: {
      BcDropdownItem,
      BcDropdown,
      BcAvatar,
      BcButton,
      BcInput,
      BcTextEditor,
      BcAutocomplete,
      MvSelect,
      Tag,
      EmailPreviewCompanies,
      EmailAttachmentList,
      EmailVariablesList,
    },
    props: {
      recipientEmails: {
        type: Array,
        default: () => [],
      },
      actionId: {
        type: String,
      },
      coder: {
        type: Object,
        default: () => ({}),
      },
      customJob: {
        type: Object,
        default: () => ({}),
      },
      warningMessage: {
        type: String,
      },
      selectedCompanies: {
        type: Array,
        default: () => [],
      },
      selectedCustomJobs: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        ccValue: '',
        ccOptions: [],
        verified: false,
        isSubmitting: false,
        isLoading: true,
        emailConfig: {},
        files: [],
        attachments: [],
        selectedCompaniesMapped: [],
        selectedCompany: {},
        MAX_SIZE: 25_164_007,
        selectedTemplate: {},
        emptyVariables: [],
        favoriteEmployees: [],
        templatePreview: {},
        showTooltip: false,
        selectedCustomJob: {},
        form: {
          recipient: '',
          object: '',
          body: '',
          senderName: '',
          cc: [],
        },
      };
    },
    async mounted() {
      document.addEventListener('click', this.handleRemoveButtonClick);

      this.form.recipient = this.recipientEmailsOptions[0].value;
      this.setSelectedCompaniesMapped();
      this.selectedCompany = this.selectedCompanies?.[0] || {};

      await this.getFavoritesEmployees();

      await this.getConfiguration();
      await this.getTemplatesVariables();
      await this.getTemplates({ all: true });
    },
    beforeDestroy() {
      this.setSelectedCoders([]);
      this.setSelectedCompanies([]);
      document.removeEventListener('click', this.handleRemoveButtonClick);
    },
    computed: {
      ...mapState({
        profile: state => state.user.profile,
        templates: state => state.emails.templates,
        templatesVariables: state => state.emails.templatesVariables,
        appParams: state => state.app.params,
      }),
      recipientEmailsOptions() {
        if (this.appParams?.fromPanel) {
          return [
            { label: 'Email de la fiche entreprise', value: 'company' },
            { label: 'Contacts favoris', value: 'favorite' },
          ];
        }

        return [
          { label: 'Contacts sélectionnés', value: 'selected' },
          { label: 'Email de la fiche entreprise', value: 'company' },
          { label: 'Contacts favoris', value: 'favorite' },
        ];
      },
      templatesVariablesFiltred() {
        if (!this.customJob?._id && !this.selectedCustomJob?._id) {
          return this.templatesVariables.filter(template => template.source === 'coder');
        }
        return this.templatesVariables;
      },
      templatesOptions() {
        const templates = this.templates.reduce((acc, template) => {
          if (template.activated) {
            if (template.default) {
              acc.defaultTemplates.push({
                label: template.name,
                value: template.slug,
                ...template,
              });
            } else if (template._coach === this.profile._id) {
              acc.userCustomTemplates.push({
                label: template.name,
                value: template.slug,
                ...template,
              });
            } else {
              acc.othersCustomTemplates.push({
                label: template.name,
                value: template.slug,
                ...template,
              });
            }
          }
          return acc;
        }, { userCustomTemplates: [], defaultTemplates: [], othersCustomTemplates: [] });

        return [
          ...templates.userCustomTemplates,
          ...templates.defaultTemplates,
          ...templates.othersCustomTemplates.sort((a, b) => a.coach?.lastName?.localeCompare(b.coach?.lastName)),
        ];
      },
      isFormValid() {
        return this.form.object !== '' && this.form.body !== '' && this.form.recipient !== '' && this.form.senderName !== '';
      },
      isTemplateModified() {
        if (!this.selectedTemplate?._id) return true;

        return this.form.cc?.length ||
          this.form.object !== this.selectedTemplate.subject ||
          this.form.body !== this.selectedTemplate.body ||
          this.form.senderName !== this.selectedTemplate.senderName;
      },
      ccOptionsComputed() {
        if (this.ccOptions.length) {
          return this.ccOptions;
        }
        return [{ address: this.ccValue }];
      },
      tooltipContent() {
        return `
          <div id="email-send-form-companies__tooltip" class='is-column w-fit max-h-[200px] overflow-auto divide-y'>
            ${this.selectedCompaniesMapped.slice(4).map(card => {
            return `
                <div class='p-2 justify-between items-center'>
                  <div>
                    <img src="${card.logoUrl}" style="width: 17px; height: 17px; border-radius: 100%;" />
                    <div class="ml-2">
                      <span>${card.name.substring(0, 20)}</span>
                    </div>
                  </div>
                  <button class='remove-button mx-3' data-id="${card._id}">
                    <i class='icon-cross' style='font-size: 10px;'></i>
                  </button>
                </div>
              `;
          }).join('')}
          </div>
        `;
      },
      selectedContacts() {
        if (this.form.recipient === 'selected') {
          return this.selectedCompany?._contacts.filter(contact => {
            const selectedList = Object.values(this.appParams[`selected_contacts_${this.selectedCompany._id}`] || {});
            return selectedList.includes(contact._id)
          });
        }

        if (this.form.recipient === 'favorite') {
          return this.selectedCompany?._contacts.filter(contact => this.favoriteEmployees.find(employee => employee._coder?._id === contact?._id && employee._company?._id === this.selectedCompany._id));
        }

        return this.selectedCompany?._contacts;
      }
    },
    methods: {
      ...mapActions({
        getTemplatesVariables: 'emails/getTemplatesVariables',
        getTemplatePreview: 'emails/getTemplatePreview',
        getTemplates: 'emails/getTemplates',
        setSelectedCoders: 'emails/setSelectedCoders',
        setSelectedCompanies: 'emails/setSelectedCompanies',
      }),
      async getFavoritesEmployees() {
        try {
          const { data: favoriteEmployees } = await getCompaniesFavoriteEmployees(this.selectedCompanies.map(company => company._id));
          this.favoriteEmployees = favoriteEmployees;
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: err.message,
          })
        }
      },
      getUserInitials(user) {
        if (!user) return '';
        return `${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`;
      },
      onShowTooltip() {
        const tooltip = document.querySelector('#email-send-form-companies__tooltip');
        if (tooltip) {
          tooltip.parentElement.style.padding = 0;
          tooltip.parentElement.style.maxWidth = 'fit-content';
        }
        this.showTooltip = true;
      },
      closeTooltip() {
        this.showTooltip = false;
      },
      handleRemoveButtonClick(event) {
        if (event.target.closest('.remove-button')) {
          const cardId = event.target.closest('.remove-button').getAttribute('data-id');
          this.removeEmailCard(cardId);
        }
      },
      async onSelectCompany(company) {
        this.selectedCompany = company;
        await this.setTemplatePreview();
      },
      async onSelectContact(contactId) {
        await this.setTemplatePreview({ contactId });
      },
      setSelectedCompaniesMapped() {
        if (!this.selectedCompanies.length) return;
        this.selectedCompaniesMapped = this.selectedCompanies;
      },
      async onSelectRecipient(recipient) {
        this.form.recipient = recipient;
        await this.getTemplatesVariables();
        await this.setTemplatePreview();
      },
      async onSelectTemplate(templateSlug) {
        const template = this.templates.find(template => template.slug === templateSlug);
        if (!template) {
          return;
        }

        this.selectedTemplate = template;
        this.attachments = template?.attachments || [];

        this.form.senderName = `${this.profile.firstName} ${this.profile.lastName}`;
        this.form.object = template.subject;
        this.form.body = template.body;
        this.form.cc = template.cc || [];

        await this.setTemplatePreview();
      },
      onUpload() {
        this.$refs.uploadInput.click();
      },
      async setTemplatePreview({ contactId } = {}) {
        try {
          const firstContact = this.selectedContacts?.[0] || this.selectedCompany?._contacts?.[0] || {};
          const companyId = this.selectedCompany?._id;
          let coderId = firstContact?._coder?._id ? firstContact._coder._id : firstContact._id;
          if (contactId) {
            coderId = contactId;
          }

          if (companyId || coderId) {
            const { data } = await getVariablesPreview({
              coderId: this.form.recipient !== 'company' ? coderId : undefined,
              companyId,
            });
            this.templatePreview = { result: data };
          }
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: this.getErrorMessage(err),
          });
        }
      },
      onUploadSelect(e) {
        const file = e.target.files[0];
        if (!file) return;

        if (file.size > this.MAX_SIZE) {
          this.$toast.show({
            type: 'error',
            message: this.$t('email.attachment-limit'),
          });
          return;
        }
        this.files = [...this.files, file];
      },
      onRemoveFile({ file, attachment }) {
        if (file) {
          this.files = this.files.filter(f => f.name !== file.name && f.size !== file.size && f.lastModified !== file.lastModified);
        }
        if (attachment) {
          this.attachments = this.attachments.filter(a => a.path !== attachment.path);
        }
      },
      getCcSuggestions: debounce(async function(value) {
        this.ccOptions = [];
        if (value.length < 3) return;

        try {
          const { data: results } = await getEmailsAutocomplete(value);
          this.ccOptions = results;
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: this.getErrorMessage(err),
          });
        }
      }, 500),
      onSelectCc(option) {
        if (!validateEmail(option.address)) {
          this.$toast.show({
            type: 'error',
            message: 'email invalid', // TODO: update message
          });
          return;
        }
        this.form.cc.push(option);
        this.ccValue = '';
      },
      onRemoveCc(option) {
        this.form.cc = this.form.cc.filter(email => email.address !== option.address);
      },
      onEmptyVariables(emptyVariables) {
        const warnings = this.templatePreview.result?.warning?.value || {};
        this.emptyVariables = Object.keys(emptyVariables).map(key => {
          const warning = warnings[key.replace('object_', '').replace('body_', '')];
          return {
            ...emptyVariables[key],
            ...warning && { warning },
          }
        });
      },
      removeEmailCard(cardId) {
        this.selectedCompaniesMapped = this.selectedCompaniesMapped.filter(card => card._id !== cardId);
      },
      async getAttachments() {
        let attachments = [...this.attachments];
        if (this.files.length) {
          const promises = this.files.map(file => {
            const dataToSend = new FormData();
            dataToSend.append('file', file);
            return uploadFile({ file: dataToSend });
          });

          const results = await Promise.all(promises);
          attachments = [...attachments, ...results.map(result => ({
            filename: result.data.fileName,
            path: result.data.fileUrl,
          }))];
        }
        return attachments;
      },
      getErrorMessage(err) {
        if (err?.response?.data?.message) {
          return err.response.data.message;
        }
        if (err?.response?.body?.message) {
          return err.response.body.message;
        }
        return err.message;
      },
      async onSendMail() {
        if (this.selectedCompanies?.length) {
          this.onSendMailGroup();
          return;
        }

        try {
          this.isSubmitting = true;

          const attachments = await this.getAttachments();

          let template = this.selectedTemplate?._id ? this.selectedTemplate : undefined;
          if (!template || this.isTemplateModified) {
            const { data } = await createTemplate({
              name: `Custom mail send by ${this.form.senderName}`,
              subject: this.form.object,
              senderName: this.form.senderName,
              body: this.form.body,
              cc: this.form.cc,
              custom: true,
            });
            template = data;
          }

          if (!template?._id) {
            throw new Error('Template creation failure');
          }

          const { data: history } = await createHistory({
            coderId: this.coder?._id,
            customJobId: this.customJob?._id,
            companyId: this.customJob?._company,
            actionId: this.actionId,
            emailTemplateId: template._id,
            emailTemplateSlug: template.slug,
            overrideEmail: this.form.recipient,
            attachments,
          });

          if (history.status === 'success') {
            const { data: sendMailData } = await sendMail({
              historyId: history.id,
            });
            if (!sendMailData.messageId) {
              throw new Error(sendMailData.error);
            }
          } else {
            throw new Error('History creation failure');
          }
          this.isSubmitting = false;
          this.$toast.show({
            type: 'success',
            message: this.$t('email.sent-success'),
          });
          this.$emit('on-close');
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: this.getErrorMessage(err),
          });
          this.isSubmitting = false;
        }
      },
      async onSendMailGroup() {
        try {
          this.isSubmitting = true;

          const attachments = await this.getAttachments();
          let template = this.selectedTemplate?._id ? this.selectedTemplate : undefined;
          if (!template || this.isTemplateModified) {
            const { data } = await createTemplate({
              name: `Custom mail send by ${this.form.senderName}`,
              subject: this.form.object,
              senderName: this.form.senderName,
              body: this.form.body,
              cc: this.form.cc,
              custom: true,
            });
            template = data;
          }

          let selectedContacts = this.selectedCompaniesMapped;
          if (this.form.recipient === 'favorite') {
            selectedContacts = this.selectedCompaniesMapped
              .flatMap(company => company._contacts.filter(contact => this.favoriteEmployees.find(employee => employee._coder?._id === contact?._id && employee._company?._id === company._id)).map(coder => ({ coder, company })));
          }
          if (this.form.recipient === 'selected') {
            selectedContacts = this.selectedCompaniesMapped.flatMap(company => company._contacts.filter(contact => {
              const selectedList = Object.values(this.appParams[`selected_contacts_${company._id}`] || {});
              if (contact._coder) {
                return selectedList.includes(contact._coder._id);
              }
              return selectedList.includes(contact._id);
            }).map(coder => ({ coder, company })));
          }
          if (this.form.recipient === 'company') {
            selectedContacts = selectedContacts.map(company => ({ ...company, company: { id: company._id } }));
          }

          const { data } = await sendMailGroup({
            group: selectedContacts,
            groupType: this.form.recipient === 'company' ? 'company' : 'coder',
            isContact: true,
            isAsync: true,
            companies: this.selectedCompaniesMapped.map(company => ({ _id: company._id, name: company.name, logoUrl: company.logoUrl })),
            attachments,
            template,
          });
          const response = data;

          if (response.success) {
            this.$emit('on-close');
          }

          this.isSubmitting = false;
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: this.getErrorMessage(err),
          });
          this.isSubmitting = false;
        }

      },
      async getConfiguration() {
        try {
          const { data: config } = await getConfiguration();
          this.form.senderName = config.fullname;
          this.verified = config.verified;
          this.emailConfig = config;
          this.isLoading = false;
        } catch (err) {
          this.$toast.show({
            type: 'error',
            message: this.getErrorMessage(err),
          });
          this.isLoading = false;
        }
      },
      setAlternativeImg(event) {
        event.target.src = require('@/assets/img/default-avatar.svg');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .email-send-form-companies {
    flex-direction: column;
    width: 100%;
    overflow: auto;

    &__content {
      flex-direction: column;
      height: 100%;
      padding: 20px;
      gap: 15px;
    }

    &__footer {
      width: 100%;
      min-height: 75px;
      gap: 10px;
      align-items: center;
      justify-content: center;
      border-top: 1px solid $color-blue-light;
    }

    &__warning {
      justify-content: center;
      align-items: center;
      margin-top: 5%;
    }

    &__label {
      color: $color-blue-heavy-dark;
      font-size: $font-size-xs;
      font-weight: 400;
    }

    &:deep() {
      .bc-dropdown-item__text {
        width: 100%;
      }
    }
  }
</style>
