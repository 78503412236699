<template>
  <div class="configuration-linkedin-extension">
    <configuration-share-processes-tabs></configuration-share-processes-tabs>
    <div class="configuration-linkedin-extension__container">
      <h3 class="body-l--bold mb-2">{{ $t('generics.extension') }}</h3>
      <div class="configuration-other__content">
        <bc-checkbox id="activate-coordinate-details" v-model="extension.withAdvancedInfos">
          {{ $t('config.activate-coordinate-details') }}
        </bc-checkbox>
      </div>
      <div class="configuration-other__content">
        <bc-checkbox id="activate-skills-details" v-model="extension.withSkillsInfos">
          {{ $t('config.activate-skills-details') }}
        </bc-checkbox>
      </div>
      <div class="configuration-other__content">
        <bc-checkbox id="activate-extension-auto-open" v-model="extension.openAuto">
          {{ $t('config.activate-extension-auto-open') }}
        </bc-checkbox>
      </div>
      <div class="configuration-other__content">
        <bc-checkbox id="activate-extension-required-profession" v-model="extension.requiredProfession">
          {{ $t('config.required-profession') }}
        </bc-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
import ConfigurationShareProcessesTabs from '@/components/Configuration/Tabs/ConfigurationPreferencesTabs';

export default {
  name: 'configuration-linkedin-extension',
  components: {
    BcCheckbox,
    ConfigurationShareProcessesTabs,
  },
  mounted() {
    if (this.configuration.extension) {
      this.extension = this.configuration.extension;
    }
  },
  data() {
    return {
      extension: {
        withAdvancedInfos: false,
        withSkillsInfos: false,
        openAuto: false,
        requiredProfession: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      configuration: 'user/configuration',
    }),
  },
  methods: {
    ...mapActions({
      updateConfigurationOrganizationByKey: 'user/updateConfigurationOrganizationByKey',
    }),
    async updateConfiguration(key, data) {
      try {
        await this.updateConfigurationOrganizationByKey({ key, ...data });
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    },
  },
  watch: {
    extension: {
      handler(data) {
        this.updateConfiguration('extension', data);
      },
      deep: true,
    },
  }
};
</script>

<style lang="scss" scoped>
.configuration-linkedin-extension {
  flex-direction: column;

  &__container {
    flex-direction: column;
    gap: 10px;
    padding: 20px 30px;
  }
}
</style>
