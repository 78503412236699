<template>
  <div class="searchbar-filters-domains">
    <p class="searchbar-filters-domains__title">
      Métier
    </p>
    <div class="searchbar-filters-domains__wrapper">
      <domains-dropdown
        class="searchbar-filters-domains__dropdown"
        :values="selectedDomains"
        :options="domainsCategories"
        :multi-choice="true"
        @click="setSelectedDomains">
      </domains-dropdown>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import DomainsDropdown from '@/components/DomainsDropdown/DomainsDropdown';
  import { getDomainsCategories } from '@/common-old/macros/market';
  import { buildDomainTag } from '@/helpers/searchbar/factories';

  export default {
    components: { DomainsDropdown },
    name: 'searchbar-filters-domains',
    data: () => ({}),
    computed: {
      ...mapState({
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags
      }),
      selectedDomains() {
        return this.searchbarFiltersTags.filter(tag => tag.type === 'domains').map(domain => domain.name);
      },
      domainsCategories() {
        return getDomainsCategories();
      }
    },
    methods: {
      isDomainSelected(domain) {
        return this.searchbarFiltersTags.some(tag => tag.type === 'domains' && tag.query === domain.query);
      },
      selectDomain(domain) {
        this.$emit('on-select', buildDomainTag(domain.query));
      },
      unselectDomain(domain) {
        const tagIndex = this.searchbarFiltersTags.findIndex(tag => tag.type === 'domains' && tag.query === domain.query);
        if (tagIndex >= 0) {
          this.$emit('on-unselect', tagIndex);
        }
      },
      toggleSelectedDomain(domain) {
        if (this.isDomainSelected(domain)) {
          this.unselectDomain(domain);
        } else {
          this.selectDomain(domain);
        }
      },
      selectCategoryDomains(domains) {
        if (domains.every(domain => this.isDomainSelected(domain))) {
          domains.forEach(domain => this.unselectDomain(domain));
        } else {
          domains.filter(domain => !this.isDomainSelected(domain)).forEach(domain => this.selectDomain(domain));
        }
      },
      setSelectedDomains(domains) {
        if (domains && domains.length === 1) {
          this.toggleSelectedDomain(domains[0]);
        } else {
          this.selectCategoryDomains(domains);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .searchbar-filters-domains {
    flex-direction: column;
    @include bp('tablet') {
      flex-direction: row;
    }

    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      font-size: $font-size-s;
      color: $color-blue-dark-cello;
      line-height: 18px;
      @include bp('tablet') {
        justify-content: flex-start;
        text-align: left;
        margin-bottom: 0;
        width: 85px;
        margin-right: 35px;
      }
    }

    &__wrapper {
      position: relative;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      @include bp('tablet') {
        min-width: 305px;
        width: initial;
        justify-content: initial;
      }
    }

    &__dropdown {
      max-width: 100%;
    }
  }
</style>
