<template>
  <views class="industries">
    <admin-tabs :industries-count="cards.length">
      <bc-button
        class="industries__button ml-auto mr-3"
        icon-left="plus"
        @click.native="goCreateIndustry">
        Créer une industrie
      </bc-button>
    </admin-tabs>
    <router-view></router-view>
    <views-container>
      <bc-spinner
        v-if="!isLoaded"
        class="industries__spinner">
      </bc-spinner>
      <div
        v-else
        class="industries__cards is-justify-content-center">
        <card
          v-for="card in cards"
          :key="card._id"
          :card-content="card"
          :is-full-width="false"
          class="industries__card">
          <card-industries
            @go-to-edit-industry="goEditIndustry(card)"
            @go-to-link-domains-and-professions="goLinkDomainsAndProfessions(card)"
            :card-content="card">
          </card-industries>
        </card>
      </div>
    </views-container>
    <bc-modal
      :active="isModalOpen"
      @close="cancelModal">
      <industries-modal-create
        v-if="modal === 'create'"
        @close-modal="cancelModal"
        @industry-created="confirmCreate">
      </industries-modal-create>
      <industries-modal-edit
        v-else-if="modal === 'edit'"
        :profile="selectedCard"
        @close-modal="cancelModal"
        @industry-edited="confirmEdit">
      </industries-modal-edit>
      <industries-modal-link
        v-else-if="modal === 'link'"
        :profile="selectedCard"
        @close-modal="cancelModal"
        @industry-edited="confirmEdit">
      </industries-modal-link>
    </bc-modal>
  </views>
</template>

<script>
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import Card from '@/components/Card/Card';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import CardIndustries from '@/components/Card/CardIndustries';
  import BcModal from '@/ui-kit/components/BcModal/BcModal';
  import IndustriesModalCreate from '@/components/Industries/IndustriesModal/IndustriesModalCreate';
  import { getIndustries } from '@/api/industries';
  import IndustriesModalEdit from '@/components/Industries/IndustriesModal/IndustriesModalEdit';
  import Views from '@/components/Views/Views';
  import ViewsContainer from '@/components/Views/ViewsContainer';
  import IndustriesModalLink from '@/components/Industries/IndustriesModal/IndustriesModalLink';
  import AdminTabs from '@/views/Tabs/AdminTabs';

  export default {
    name: 'industries',
    components: {
      IndustriesModalLink,
      ViewsContainer,
      Views,
      IndustriesModalEdit,
      IndustriesModalCreate,
      BcModal,
      CardIndustries,
      BcButton,
      Card,
      BcSpinner,
      AdminTabs,
    },
    data() {
      return {
        isLoaded: true,
        currentPage: parseInt(this.$route.query.page) || 1,
        cards: [],
        isModalOpen: false,
        modal: '',
        selectedCard: {},
      };
    },
    created() {
      this.getIndustries();
    },
    watch: {
      currentPage(newPage) {
        this.$router.push({ ...this.$route, query: { ...this.$route.query, page: newPage } });

        this.getIndustries();
      },
    },
    methods: {
      resetCards() {
        this.cards = [];
      },
      goEditIndustry(card) {
        this.selectedCard = card;

        this.modal = 'edit';

        this.isModalOpen = true;
      },
      goLinkDomainsAndProfessions(card) {
        this.selectedCard = card;

        this.modal = 'link';

        this.isModalOpen = true;
      },
      goCreateIndustry() {
        this.modal = 'create';

        this.isModalOpen = true;
      },
      cancelModal() {
        this.modal = '';

        this.isModalOpen = false;

        this.selectedCard = {};
      },
      confirmCreate(industry) {
        this.cards.unshift(industry);

        this.cancelModal();
      },
      confirmEdit(industry) {
        this.cards.unshift(industry);

        this.cancelModal();
      },
      async getIndustries() {
        this.isLoaded = false;

        try {
          this.resetCards();

          const { data } = await getIndustries();

          this.cards = data;

          this.isLoaded = true;
        } catch (error) {
          this.$emit('error', error);

          this.isLoading = true;
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (vm.$store.getters['user/isMarvinRecruiter']) {
          next();
        } else {
          next('/');
        }
      });
    },
  };
</script>

<style lang="scss" scoped>
  .industries {
    &__title {
      min-height: 30px;
      margin-bottom: 15px;
    }

    &__searchbar {
      margin-bottom: 20px;
    }

    &__filters {
      margin-bottom: 20px;
    }

    &__pagination {
      margin: 20px 0;
    }

    &__spinner {
      margin: 100px 0;
    }

    &__card-list {
      margin-bottom: 100px;
    }

    &__spinner, &__pagination, &__card-list {
      justify-content: center;
    }

    &__cards {
      margin: -10px;
      flex-wrap: wrap;
    }

    &__card {
      min-width: 250px;
      margin: 10px;
    }
  }
</style>
