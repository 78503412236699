import { http } from '@/api/index';
import moment from 'moment/min/moment-with-locales';

export const getPerformances = (start, end, coachId, team) => {
  return http.get('/performances', {
    params: {
      start,
      end: moment(end).add(1, 'd').format('YYYY-MM-DD'),
      ...coachId && { coachId },
      ...team && { team }
    },
  });
};

export default {
  getPerformances,
};
