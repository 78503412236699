// import moment from 'moment/min/moment-with-locales';

import { http } from '@/api/index';
import {
  Campaign,
  CampaignActionKey,
  CampaignActionOperator,
  CampaignCondition,
  CampaignProspect,
  CampaignProspectSource,
} from '@/domains/models/Campaign';

export type GetCampaignsParams = {
  status?: string;
  coach?: string;
  name?: string;
  campaignType?: string;
};

// Campaigns
export const createCampaign = (campaign: { name: string, _campaignTypes: string[] }) => {
  return http.post<Campaign>(`/campaigns`, campaign);
};

export const getCampaigns = ({ status, coach, name, campaignType }: {
  status?: string,
  coach?: string,
  name?: string,
  campaignType?: string
}) => {
  return http.get<Campaign[]>(`/campaigns`, {
    params: {
      ...status && { status },
      ...coach && { coach },
      ...name && { name },
      ...campaignType && { campaignType },
    },
  });
};

export const getCampaign = (campaignId: string) => {
  return http.get<Campaign>(`/campaigns/${campaignId}`);
};

export const editCampaign = (campaignId: string, update: { name: string }) => {
  return http.patch<Campaign>(`/campaigns/${campaignId}`, update);
};

export const deleteCampaign = (campaignId: string) => {
  return http.delete<Campaign>(`/campaigns/${campaignId}`);
};

// Campaigns Configuration
export const getCampaignsConfiguration = () => {
  return http.get<{ types: string[], status: string[] }>(`/campaigns/configuration`);
};

// Campaigns Actions
export const createCampaignAction = (campaignId: string, action: {
  type: CampaignActionKey,
  nextValidation: {
    operator: CampaignActionOperator,
    conditions: CampaignCondition[]
  },
  body?: string,
  object?: string,
}) => {
  return http.post<Campaign>(`/campaigns/${campaignId}/actions`, action);
};

export const editCampaignAction = (campaignActionId: string, action: {
  type: CampaignActionKey,
  nextValidation: {
    operator: CampaignActionOperator,
    conditions: CampaignCondition[]
  },
  body?: string,
  object?: string,
}) => {
  return http.patch<Campaign>(`/campaigns/actions/${campaignActionId}`, action);
};

export const deleteCampaignAction = (campaignId: string, campaignActionId: string) => {
  return http.patch<Campaign>(`/campaigns/actions/${campaignActionId}/delete`, {
    campaignId,
  });
};

// Campaigns Prospect Sources
export const createCampaignProspectSource = (campaignId: string, prospectSource: CampaignProspectSource) => {
  return http.post<Campaign>(`/campaigns/${campaignId}/prospect-sources`, prospectSource);
};

export const resolveProspectsFromSources = (campaignId: string) => {
  return http.post<Campaign>(`/campaigns/${campaignId}/prospect-sources/resolve`);
};

export const editCampaignProspectSource = (campaignId: string, sourceId: string, update: {
  name: string
}) => {
  return http.patch<Campaign>(`/campaigns/prospect-sources/${sourceId}`, update);
};

export const deleteCampaignProspectSource = (sourceId: string) => {
  return http.delete<void>(`/campaigns/prospect-sources/${sourceId}`);
};

// Campaigns Prospects
export const editCampaignProspectStatus = (prospectId: string, status: string) => {
  return http.patch<void>(`/campaigns/prospects/${prospectId}`, { status });
};

export const getCampaignProspects = ({ campaignId, currentActionId }: {
  campaignId?: string,
  currentActionId?: string
}) => {
  return http.get<CampaignProspect[]>(`/campaigns/${campaignId}/prospects`, {
    params: {
      currentActionId,
    },
  });
};

export const getCampaignProspectsCount = (campaignId: string) => {
  return http.get<{
    total: number,
    finished: number
  } & Record<string, number>>(`/campaigns/${campaignId}/prospects/count`);
};

// Campaigns Status
export const archiveCampaign = (campaignId: string) => {
  return http.post<Campaign>(`/campaigns/${campaignId}/archive`);
};

export const pauseCampaign = (campaignId: string) => {
  return http.post<Campaign>(`/campaigns/${campaignId}/pause`);
};

export const startCampaign = (campaignId: string) => {
  return http.post<Campaign>(`/campaigns/${campaignId}/start`);
};

export const resumeCampaign = (campaignId: string) => {
  return http.post<Campaign>(`/campaigns/${campaignId}/resume`);
};

export const scheduleCampaign = (campaignId: string, scheduledAt: Date) => {
  return http.post<Campaign>(`/campaigns/${campaignId}/schedule`, { scheduledAt });
};

