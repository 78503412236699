<template>
  <div class="location-autocomplete is-column">
    <div class="is-align-items-center is-full-width">
      <bc-autocomplete
        :loading="isLoading"
        :options="suggestions"
        :is-dropdown-full-width="false"
        :disabled="withDistance && selectedLocations.length && distance > 0 ? true : false"
        @input="getSuggestions"
        class="location-autocomplete__input is-expanded"
        name="location"
        icon="map-pin"
        :label="showLabel ? $t('generics.location') : undefined"
        v-model="searchLocation"
        v-bind="$attrs"
        v-on="$listeners">
        <template #empty>
          <template v-if="isComplete && !isLoading">
            <p class="location-autocomplete__option font-size-s">
              {{ $t('toast.no-location-found') }}
            </p>
          </template>
        </template>
        <template #option="{ option }">
          <bc-dropdown-item
            :text="option.description"
            :is-selected="isSelected(option)"
            @click.native.stop="selectLocation(option)"
            class="location-autocomplete__option font-size-s is-expanded">
          </bc-dropdown-item>
        </template>
      </bc-autocomplete>
    </div>
    <div v-if="baseLocations && baseLocations.length" class="location-autocomplete__tags mb-3">
      <tag
        v-for="location in baseLocations"
        :key="location.place_id"
        :text="location.text"
        @click.native.stop="selectLocation(location)"
        type="location"
        can-close />
    </div>
    <div v-if="withDistance" class="is-column mt-2">
      <div class="is-column">
        <p class="mb-1 text-xs font-normal text-blue-400">
          {{ $t('generics.location-radius') }}
        </p>
        <bc-slider
          class="is-full-width"
          type="simple"
          isInputDisplayed
          :disabled="isDistanceDisabled"
          :value="Number(distance)"
          :max="100"
          @input="handleInputDistance"></bc-slider>
      </div>
      <div v-if="showButtons" class="mt-5 justify-end gap-3">
        <bc-button type="outlined" @click="resetWithDistance">
          {{ $t('generics.clean') }}
        </bc-button>
        <bc-button @click="confirmWithDistance">
          {{ $t('generics.confirm') }}
        </bc-button>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce';
import locationApi from '@/api/location';

import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete.vue';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import BcSlider from '@/ui-kit/components/BcSlider/BcSlider';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import Tag from '@/components/Tag/Tag';
import {
  formatAutocompleteLocationToBaseLocation,
  formatPlaceDetailsToBaseLocation,
} from '@/formatters/locations';

export default {
  name: 'location-autocomplete',
  components: {
    BcAutocomplete,
    BcDropdownItem,
    BcSlider,
    BcButton,
    Tag,
  },
  props: {
    showButtons: {
      type: Boolean,
      default: true,
    },
    wantedLocations: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    selectedDistance: {
      type: [String, Number],
      default: 0,
    },
    job: {
      type: Object,
    },
    canEdit: {
      type: Boolean,
    },
    editFromModal: {
      type: Boolean,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    withDistance: {
      type: Boolean,
      default: false,
    },
    emitOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchLocation: '',
      selectedLocations: [],
      suggestions: [],
      isLoading: false,
      isComplete: false,
      distance: 0,
      isForcedDistanceDisabled: false,
    };
  },
  mounted() {
    if (this.selected) {
      this.setSelectedLocations(this.selected);
    }

    if (this.selectedDistance) {
      this.distance = this.selectedDistance;
    }
  },
  computed: {
    baseLocations() {
      return this.selectedLocations.map(location =>
        location.addressComponents
          ? formatPlaceDetailsToBaseLocation(location)
          : formatAutocompleteLocationToBaseLocation(location),
      );
    },
    isDistanceDisabled() {
      return (
        !this.baseLocations.length || this.baseLocations.length > 1 || this.isForcedDistanceDisabled
      );
    },
  },
  methods: {
    getSuggestions: debounce(async function (value) {
      if (value) {
        try {
          const {
            data: { predictions },
          } = await locationApi.getLocationAutocomplete(value);

          this.suggestions = predictions;

          this.isLoading = false;
          this.isComplete = true;
        } catch (error) {
          this.isLoading = false;
          this.isComplete = true;
        }
      } else {
        this.isLoading = false;
        this.isComplete = true;
      }
    }, 500),
    selectLocation(location) {
      const isSelected = this.isSelected(location);

      if (isSelected && this.selectedLocations.length === 1) {
        this.resetWithDistance();
        return;
      } else if (isSelected) {
        this.selectedLocations = this.selectedLocations.filter(l => {
          const currentPlaceId = l.place_id || l.placeId;
          const placeId = location.place_id || location.placeId;
          return currentPlaceId !== placeId;
        });
      } else {
        this.selectedLocations.push(location);
      }
      this.searchLocation = '';

      if (!this.withDistance || this.emitOnly) {
        this.$emit('select-locations', this.selectedLocations);
      }
    },
    isSelected(location) {
      return this.selectedLocations.find(l => l.place_id === location.place_id) !== undefined;
    },
    setSelectedLocations(locations) {
      this.selectedLocations = locations.map(location => {
        return {
          ...location,
          ...((location.addressComponents || {}).length && {
            description: location.addressComponents[0].longName,
          }),
        };
      });
    },
    handleInputDistance(value) {
      this.distance = value;

      if (this.emitOnly) {
        this.$emit('select-distance', this.distance > 0 ? this.distance : undefined);
      }
    },
    confirmWithDistance() {
      this.$emit('select-locations', this.selectedLocations);
      this.$emit('select-distance', this.distance > 0 ? this.distance : undefined);
      this.$emit('close', true);
    },
    resetDistance() {
      this.distance = 0;
      this.$emit('select-distance', undefined);
    },
    resetWithDistance() {
      this.distance = 0;
      this.selectedLocations = [];
      this.$emit('select-distance', undefined);
      this.$emit('select-locations', []);
    },
  },
  watch: {
    selected(newValues) {
      if (this.selected) {
        this.setSelectedLocations(newValues);
      }
    },
    selectedDistance(newValue) {
      this.distance = newValue || 0;
    },
    isDistanceDisabled(disabled) {
      if (disabled) {
        this.resetDistance();
      }
    },
    baseLocations: {
      async handler(locations) {
        if (locations.length === 1) {
          const location = locations[0];
          const placeId = location?.place_id || location?.placeId;
          if (location?.types?.includes('locality')) {
            return (this.isForcedDistanceDisabled = false);
          }
          if (placeId) {
            const { data } = await locationApi.getLocationByPlaceId(placeId);
            if (data?.types?.includes('locality')) {
              return (this.isForcedDistanceDisabled = false);
            }
          }
          return (this.isForcedDistanceDisabled = true);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.location-autocomplete {
  &__input {
    margin-bottom: 10px;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  :deep() {
    .bc-autocomplete__dropdown {
      max-width: 100%;
      width: 100%;
    }
  }
}
</style>
