<template>
  <div class="custom-jobs-details">
    <accordion title="Détails" :count="11" :open="true">
      <div class="custom-jobs-details__content-details">
        <panel-profile-item :withBorder="false" icon="map-pin" :title="$t('generics.location')">
          <template #header>
            <tag
              v-for="location in baseLocations"
              :key="location.place_id"
              :text="location.text"
              type="location" />
          </template>
        </panel-profile-item>
        <panel-profile-item :withBorder="false" icon="award" :title="$t('generics.professions')">
          <template #header>
            <tag
              v-for="profession in professionsList"
              :key="profession._id"
              type="professions"
              :text="profession.name" />
          </template>
        </panel-profile-item>
        <panel-profile-item :withBorder="false" icon="tool" :title="$tc('generics.skill', 2)">
          <template #header>
            <tag v-for="skill in skillsList" :key="skill._id" type="technos" :text="skill.name" />
          </template>
        </panel-profile-item>
        <!-- <panel-profile-item
            :withBorder="false"
            icon="users"
            :title="$t('companies.panel-company.share-with')">
            <template v-if="customJob._owners" #header>
              <div class="is-align-items-center">
                <bc-avatar
                  v-for="coach in customJob._owners.slice(0, 5)"
                  :key="coach._id"
                  v-tooltip="`${coach.firstName} ${coach.lastName}`"
                  :src="coach.pictureUrl"
                  class="panel-custom-jobs-details__coach-shared"
                  @error="setAlternativeImg"
                  size="s">
                </bc-avatar>
                <bc-avatar
                  v-if="customJob._owners.length > 5"
                  v-tooltip="{ content: customJob._owners.slice(5).map(coach => `${coach.firstName} ${coach.lastName}`).join(', ') }"
                  :src="''"
                  class="panel-custom-jobs-details__coach-shared"
                  @error="setAlternativeImg"
                  size="s">
                </bc-avatar>
              </div>
            </template>
          </panel-profile-item> -->
        <panel-profile-item
          :withBorder="false"
          icon="file"
          :title="$t('companies.account-management.contract-type')">
          <template #header>
            <tag type="contracts" :text="contractsLabels[customJob.contract]" />
          </template>
        </panel-profile-item>
        <panel-profile-item
          :withBorder="false"
          icon="file"
          :title="$t('companies.account-management.contract-duration')">
          <template #header>
            <tag type="contracts" :text="contractDurationLabels[customJob.contractDuration]" />
            <div class="panel-custom-jobs-details__contract-dates">
              <tag v-if="customJob.contractStartDate" type="contracts" :text="contractStartDate" />
              <tag v-if="customJob.contractEndDate" type="contracts" :text="contractEndDate" />
            </div>
          </template>
        </panel-profile-item>
        <panel-profile-item :withBorder="false" icon="salary" :title="$t('generics.budget')">
          <template #header>
            <tag
              icon="salary"
              :text="`${getSalaryWithType(
                customJob.budget.min + '-' + customJob.budget.max,
                customJob.budget.salaryType
              )}${getSalaryWithType(
                customJob.budget.variable,
                customJob.budget.variableType,
                ' +'
              )}`" />
          </template>
        </panel-profile-item>
        <panel-profile-item
          v-if="configurationFramework.showCompanies"
          :withBorder="false"
          icon="user"
          :title="$t('custom-jobs.partner-contact')">
          <template v-if="customJob._contact" #header>
            <div class="is-align-items-center">
              <bc-avatar
                :src="customJob._contact.pictureUrl"
                class="card-coders__avatar is-unshrink"
                size="s"
                @error="setAlternativeImg" />
              <p class="ml-2 font-size-s">
                {{ customJob._contact.lastName }} {{ customJob._contact.firstName }}
              </p>
            </div>
          </template>
        </panel-profile-item>
        <panel-profile-item
          :withBorder="false"
          v-for="customField in configurationJob.customFields"
          :key="customField.key"
          :icon="customField.icon"
          :title="customField.key === 'emergency' ? $t('generics.priority') : customField.name">
          <template #header>
            <tag
              v-if="customJob.customFields && customJob.customFields[customField.key]"
              :text="
                Array.isArray(customJob.customFields[customField.key])
                  ? customJob.customFields[customField.key]
                    .map(value => `${value} ${customField.unit}`)
                    .join(' - ')
                  : customJob.customFields[customField.key]
              "
              :icon="customField.icon"
              :type="customField.key" />
          </template>
        </panel-profile-item>
      </div>
    </accordion>
    <accordion v-if="customJob.description" title="Description de l'offre" :count="1" :open="true">
      <div class="custom-jobs-details__description" v-html="customJob.description"></div>
    </accordion>
    <accordion v-if="customJob.profileDescription" title="Profil recherché" :count="1">
      <div class="custom-jobs-details__description" v-html="customJob.profileDescription"></div>
    </accordion>
    <accordion title="Description de l’entreprise" :count="companyDescription ? 1 : 0">
      <div class="custom-jobs-details__description" v-html="companyDescription"></div>
    </accordion>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  contractsLabels,
  salaryLabels,
  salaryLabelsShort,
  contractDurationLabels,
  salaryTypesLabels,
  descriptionTypes,
} from '@/api/custom-jobs';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
import Accordion from '@/components/Accordion/Accordion';
import Tag from '@/components/Tag/Tag';
import PanelProfileItem from '@/components/Panel/PanelProfile/PanelProfileItem';
import { formatPlaceDetailsToBaseLocation } from '@/formatters/locations';

export default {
  name: 'custom-jobs-details',
  components: {
    BcAvatar,
    Accordion,
    Tag,
    PanelProfileItem,
  },
  props: {
    customJob: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      contractsLabels,
      salaryLabels,
      salaryLabelsShort,
      contractDurationLabels,
    };
  },
  computed: {
    ...mapGetters({
      configurationFramework: 'user/configurationFramework',
      configurationJob: 'user/configurationJob',
    }),
    professionsList() {
      if (!this.customJob._professions) {
        return [];
      }
      return this.customJob._professions;
    },
    skillsList() {
      if (!this.customJob._skills) {
        return [];
      }
      return this.customJob._skills;
    },
    baseLocations() {
      return this.customJob.locations.map(formatPlaceDetailsToBaseLocation);
    },
    companyDescription() {
      if (this.customJob.descriptionType === descriptionTypes.client) {
        return this.customJob._company.description;
      } else if (this.customJob.descriptionType === descriptionTypes.custom) {
        return this.customJob.customCompanyDescription;
      }
      return this.customJob._organization?.description;
    },
  },
  methods: {
    getSalaryWithType(salary, type, prefix = '') {
      if (!salary) return '';
      return `${prefix} ${salary} ${salaryTypesLabels[type] || ''}`;
    },
    setAlternativeImg(event) {
      event.target.src = require('@/assets/img/default-avatar.svg');
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-jobs-details {
  flex-direction: column;
  gap: 10px;

  &__content {
    flex-direction: column;
    gap: 10px;

    &-details {
      flex-direction: column;
      width: 100%;
      padding: 10px 0px;

      &:deep() {
        .panel-profile-item {
          padding: 3px 20px;
          min-height: inherit;
        }
      }
    }
  }

  &__description {
    flex-direction: column;
    padding: 20px 40px;
  }
}
</style>
