import { http } from '@/api/index';
import {
  NotesTemplate,
  NotesTemplateDraft,
  NotesTemplatePatch,
} from '@/domains/models/NotesTemplate';

export const getNotesTemplates = ({ coachId, all }: {
  coachId: string | null,
  all: boolean | null
}) => {
  return http.get<NotesTemplate[]>('/templates/notes', {
    params: {
      coachId,
      all,
    },
  });
};

export const createNotesTemplate = ({ name, body }: NotesTemplateDraft) => {
  return http.post<NotesTemplate>('/templates/notes', {
    name,
    body,
  });
};

export const editNotesTemplate = ({ name, body, activated, _id: id }: NotesTemplatePatch) => {
  return http.patch<NotesTemplate>(`/templates/notes/${id}`, {
    ...name && { name },
    ...body && { body },
    ...activated !== undefined && { activated },
  });
};

export const deleteNotesTemplate = ({ id }: { id: string }) => {
  return http.delete<{ message: string }>(`/templates/notes/${id}`);
};
