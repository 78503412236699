<template>
  <div class="configuration-templates-notes-item" @click="onClickItem">
    <div
      class="configuration-templates-notes-item__name w-10/12 items-center"
    >
      <p class="w-fit text-ellipsis">{{ template.name }}</p>
    </div>
    <div class="configuration-templates-notes-item__subscription w-1/12">
      <bc-toggle
        :id="template._id"
        v-tooltip="{ content: isDisabled ? $t('templates.cannot-modify') : '' }"
        :disabled="isDisabled"
        :value="template.activated"
        type="default"
        @input="onToggleActivated"
        @click.native.stop>
      </bc-toggle>
    </div>
    <div
      class="configuration-templates-notes-item__edit w-1/12"
    >
      <bc-avatar
        v-if="template._coach"
        v-tooltip="{ content: `${template._coach.firstName} ${template._coach.lastName}` }"
        :placeholder="getInitials(`${template._coach.firstName} ${template._coach.lastName}`)"
        :src="template._coach.pictureUrl"
        class="is-unshrink"
        size="s"
        @error="setAlternativeImg({event: $event})">
      </bc-avatar>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
  import BcToggle from '@/ui-kit/components/BcToggle/BcToggle.vue';
  import { setAlternativeImg } from '@/mixins/imgFallback.js';
  import { defineProps } from 'vue';
  import { NotesTemplate } from '@/domains/models/NotesTemplate';
  import { getInitials } from '@/helpers/getIntials';

  type Props = {
    template: NotesTemplate;
    isDisabled: boolean;
  }

  const emits = defineEmits<{
    (e: 'click', value: NotesTemplate): void,
    (e: 'toggle-activated', value: NotesTemplate): void
  }>();

  const props = defineProps<Props>();

  const onClickItem = () => {
    emits('click', props.template);
  };

  const onToggleActivated = () => {
    if (props.isDisabled) {
      return;
    }
    emits('toggle-activated', props.template);
  };
</script>

<style lang="scss" scoped>
  .configuration-templates-notes-item {
    width: 100%;
    border-radius: 5px;
    border: 1px solid $color-blue-medium;
    background: $color-neutre-4;
    height: 60px;
    padding: 17px 30px;
    align-items: center;

    &:hover {
      cursor: pointer;
      background: $color-neutre-3;
    }

    &__name {
      align-items: center;
      gap: 10px;

      & p {
        color: $color-tertiary;
        font-weight: 700;
        width: 55%;
      }
    }

    &__logo {
      width: 27px;
      height: 27px;
      background: white;
      border-radius: 100px;
      border: 1px solid #F2F4FD;
    }

    &__tag {
      border-radius: 30px;
      color: #3D5476;
      background: $color-blue-light;
      padding: 3px 10px;
      font-weight: 500;
      font-size: 12px;

      &--error {
        background: #F5DFE0;
        color: $color-red-mandy;
      }

      &--success {
        background: #EEF9F4;
        color: $color-green-shamrock;
      }
    }

  }
</style>
