<template>
  <div class="auth is-justify-content-center is-column">
    <img
      alt="logo"
      class="auth__logo"
      src="@/assets/logo/logo_marvin2.svg"/>
    <auth-magic></auth-magic>
  </div>
</template>

<script>
  import AuthMagic from '@/components/Auth/AuthMagic';

  export default {
    name: 'auth',
    components: {
      AuthMagic,
    },
    metaInfo: {
      title: 'Connexion | Marvin Recruiter',
      description: 'login.footer-text',
    },
    beforeRouteEnter(to, from, next) {
      if (localStorage.getItem('Token')) {
        next('/');
      }

      next();
    },
  };
</script>

<style lang="scss" scoped>
  .auth {
    width: 100%;
    position: relative;
    min-height: 100vh;
    background: $color-neutre-3;
    align-items: center;
    @include bp('tablet') {
      padding: 0 50px;
      min-height: 550px;
       background: $color-neutre-3;
    }

    &__logo {
      max-width: 140px;
      position: absolute;
      left: 50px;
      top: 10px;
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s ease;
    }

    .fade-enter, .fade-leave-to {
      opacity: 0;
    }
  }
</style>
