<template>
  <div class="reminders-card-list-refresh">
    <BcSpinner v-if="isLoading" class="reminders-card-list__spinner" size="large" />
    <div v-else class="reminders-card-list-refresh__refresh is-column is-align-items-center">
      <p class="reminders-card-list-refresh__text is-secondary mb-5">
        {{ $t('avatar-dropdown.no-candidates-in-your-pool') }}
      </p>
      <BcButton :disabled="!hasNextRecommendations" icon-left="refresh" @click="refreshReminders">
        {{ $t('avatar-dropdown.refill-my-pool') }}
      </BcButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
import { getRefreshRemindersStatus, requestRefreshReminders } from '@/api/refresh-pool';
import { useToast } from '@/ui-kit/components/BcToast';
import { onMounted, ref, defineEmits } from 'vue';

const emit = defineEmits<{
  (e: 'refresh-pool'): void;
}>();
const hasNextRecommendations = ref(false);
const isLoading = ref(true);
const toast = useToast();

onMounted(() => {
  verifyNextSuggestions();
});

async function verifyNextSuggestions() {
  try {
    isLoading.value = true;

    const {
      data: { canRefresh },
    } = await getRefreshRemindersStatus();

    hasNextRecommendations.value = canRefresh;

    isLoading.value = false;
  } catch (error) {
    toast.show({
      type: 'error',
      message: error?.response?.data?.message || error,
    });
  }
}

async function refreshReminders() {
  try {
    const {
      data: { added },
    } = await requestRefreshReminders();

    toast.show({
      type: 'success',
      message: `Votre vivier a été rechargé de ${added} suggestion(s).`,
    });

    emit('refresh-pool');
  } catch (error) {
    toast.show({
      type: 'error',
      message: error?.response?.data?.message || error,
    });
  }
}
</script>
