<template>
  <component
    :is="tag"
    :name="buttonName"
    :style="buttonStyle"
    :type="buttonType"
    class="bc-button"
    ref="button"
    v-bind="$attrs"
    v-on="listeners">
    <i
      :class="[`icon-${bcIcon}`]"
      :style="iconStyle"
      class="bc-button__icon"
      ref="icon"
      v-if="bcIcon && !bcIsLoading">
    </i>
    <span
      :style="extraStyle"
      class="bc-button__extra"
      v-if="$slots.extra">
      <slot name="extra"></slot>
    </span>
    <span
      :class="[{'bc-button__text--hidden-xs': bcTextHiddenMobile, 'bc-button__text--uppercase': bcTextUppercase}]"
      :style="textStyle"
      class="bc-button__text"
      key="text"
      ref="text"
      v-if="$slots.default && !bcIsLoading">
      <slot></slot>
    </span>
    <spinner
      :bc-border-color="bcLoaderColor"
      :bc-size="bcLoaderSize"
      key="spinner"
      slot="spinner"
      v-else-if="bcIsLoading">
    </spinner>
  </component>
</template>

<script>
  import colors_helpers from '@/legacy/ui-kit/utils/colors_helpers';
  import Spinner from '../Spinner/BcSpinner';
  import { Expo, TweenMax } from 'gsap';

  export default {
    name: 'legacy-bc-button',
    components: { Spinner },
    inheritAttrs: false,
    props: {
      tag: {
        type: String,
        default: 'button',
      },
      bcType: {
        type: String,
        default: 'regular',
      },
      bcOpacity: {
        type: [Number, String],
        default: '',
      },
      bcRadius: {
        type: String,
        default: '',
      },
      bcPadding: {
        type: String,
        default: '',
      },
      bcBackgroundColor: {
        type: String,
        default: 'blue-dodger',
      },
      bcBackgroundColorOpacity: {
        type: Number,
        default: 1,
      },
      bcBackgroundColorHover: {
        type: String,
        default: '',
      },
      bcBackgroundColorOpacityHover: {
        type: Number,
        default: 1,
      },
      bcBackgroundColorPressed: {
        type: String,
        default: '',
      },
      bcBackgroundColorOpacityPressed: {
        type: Number,
        default: 1,
      },
      bcTextColor: {
        type: String,
        default: 'white',
      },
      bcTextColorOpacity: {
        type: Number,
        default: 1,
      },
      bcTextColorHover: {
        type: String,
        default: '',
      },
      bcTextColorOpacityHover: {
        type: Number,
        default: 1,
      },
      bcTextColorPressed: {
        type: String,
        default: '',
      },
      bcTextColorOpacityPressed: {
        type: Number,
        default: 1,
      },
      bcTextSize: {
        type: String,
        default: '',
      },
      bcTextUppercase: {
        type: Boolean,
        default: false,
      },
      bcTextHiddenMobile: {
        type: Boolean,
        default: false,
      },
      bcBorder: {
        type: String,
        default: '1px solid',
      },
      bcBorderColor: {
        type: String,
        default: 'transparent',
      },
      bcBorderColorOpacity: {
        type: Number,
        default: 1,
      },
      bcBorderColorHover: {
        type: String,
        default: '',
      },
      bcBorderColorOpacityHover: {
        type: Number,
        default: 1,
      },
      bcBoxShadow: {
        type: String,
        default: '0 0 0',
      },
      bcBoxShadowColor: {
        type: String,
        default: 'transparent',
      },
      bcBoxShadowColorOpacity: {
        type: Number,
        default: 0.15,
      },
      bcBoxShadowHover: {
        type: String,
        default: '0px 8px 25px -8px',
      },
      bcBoxShadowColorHover: {
        type: String,
        default: '',
      },
      bcBoxShadowColorOpacityHover: {
        type: Number,
        default: 0.5,
      },
      bcBoxShadowPressed: {
        type: String,
        default: '0 0 0',
      },
      bcBoxShadowColorPressed: {
        type: String,
        default: 'transparent',
      },
      bcBoxShadowColorOpacityPressed: {
        type: Number,
        default: 0.15,
      },
      bcIcon: {
        type: String,
        default: '',
      },
      bcIconSize: {
        type: String,
        default: '14px',
      },
      bcIconAfter: {
        type: Boolean,
        default: false,
      },
      bcIconMargin: {
        type: String,
        default: '5px',
      },
      bcExtraAfter: {
        type: Boolean,
        default: false,
      },
      bcAnimated: {
        type: Boolean,
        default: false,
      },
      bcIsLoading: {
        type: Boolean,
        default: false,
      },
      bcLoaderColor: {
        type: String,
        default: 'blue-dodger',
      },
      bcLoaderSize: {
        type: String,
        default: '25px',
      },
      bcGradientBackgroundColorPrimary: {
        type: String,
        default: 'blue-dodger',
      },
      bcGradientBackgroundColorSecondary: {
        type: String,
        default: 'blue-dodger',
      },
      bcGradientBackgroundColorTertiary: {
        type: String,
        default: '',
      },
      bcGradientDirection: {
        type: String,
        default: '90deg',
      },
      bcGradientBackgroundSize: {
        type: String,
        default: '200% auto',
      },
      bcGradientBackgroundPosition: {
        type: String,
        default: 'right center',
      },
      bcGradientBackgroundPositionHoverEffect: {
        type: Boolean,
        default: false,
      },
      bcTextJustifyContent: {
        type: String,
        default: '',
      },
      bcTextWidth: {
        type: String,
        default: '',
      },
      bcOverflow: {
        type: String,
        default: 'hidden',
      },
      bcTransition: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      states: {
        initial: true,
        hover: false,
        pressed: false,
      },
    }),
    computed: {
      buttonName() {
        if (this.tag === 'a') {
          return;
        }

        return 'button';
      },
      buttonType() {
        if (this.tag === 'a') {
          return;
        }

        return this.$attrs.type ? this.$attrs.type : 'button';
      },
      buttonState() {
        if (this.states.pressed) {
          return 'pressed';
        } else {
          return this.states.hover ? 'hover' : 'initial';
        }
      },
      buttonStyle() {
        const customBoxShadowColorHover = this.bcBoxShadowColorHover ? this.bcBoxShadowColorHover : this.bcBackgroundColor;

        const customGradientBoxShadowColorHover = this.bcBoxShadowColorHover ? this.bcBoxShadowColorHover : this.bcGradientBackgroundColorPrimary;
        const customGradientBackgroundColor = this.bcGradientBackgroundColorTertiary ? `${colors_helpers.$_checkValidColor(this.bcGradientBackgroundColorPrimary)} 0%, ${colors_helpers.$_checkValidColor(this.bcGradientBackgroundColorSecondary)} 51%, ${colors_helpers.$_checkValidColor(this.bcGradientBackgroundColorTertiary)} 100%)` : `${colors_helpers.$_checkValidColor(this.bcGradientBackgroundColorPrimary)}, ${colors_helpers.$_checkValidColor(this.bcGradientBackgroundColorSecondary)})`;
        const customGradientBoxShadowColorPressed = this.bcBoxShadowColorPressed ? this.bcBoxShadowColorPressed : this.bcGradientBackgroundColorPrimary;

        const boxShadow = (state) => {
          const states = {
            initial: `${this.bcBoxShadow} ${colors_helpers.$_checkValidColor(this.bcBoxShadowColor, this.bcBoxShadowColorOpacity)}`, ...this.bcOpacity && { opacity: this.bcOpacity },
            hover: `${this.bcBoxShadowHover} ${colors_helpers.$_checkValidColor(customGradientBoxShadowColorHover, this.bcBoxShadowColorOpacityHover)}`,
            pressed: `${this.bcBoxShadowPressed} ${colors_helpers.$_checkValidColor(customGradientBoxShadowColorPressed, this.bcBoxShadowColorOpacityPressed)}`,
          };

          return states[state];
        };

        const style = {
          regular: {
            color: this.bcTextColorHover && (this.buttonState !== 'initial') ?
              colors_helpers.$_checkValidColor(this.bcTextColorHover, this.bcTextColorOpacityHover) :
              colors_helpers.$_checkValidColor(this.bcTextColor, this.bcTextColorOpacity),
            background: this.bcBackgroundColorHover && (this.buttonState !== 'initial') ?
              colors_helpers.$_checkValidColor(this.bcBackgroundColorHover, this.bcBackgroundColorOpacityHover) :
              colors_helpers.$_checkValidColor(this.bcBackgroundColor, this.bcBackgroundColorOpacity),
            boxShadow: this.states.hover ?
              `${this.bcBoxShadowHover} ${colors_helpers.$_checkValidColor(customBoxShadowColorHover, this.bcBoxShadowColorOpacityHover)}` :
              `${this.bcBoxShadow} ${colors_helpers.$_checkValidColor(this.bcBoxShadowColor, this.bcBoxShadowColorOpacity)}`,
            border: this.bcBorderColorHover && this.states.hover ?
              `${this.bcBorder} ${colors_helpers.$_checkValidColor(this.bcBorderColorHover, this.bcBorderColorOpacityHover)}` :
              `${this.bcBorder} ${colors_helpers.$_checkValidColor(this.bcBorderColor, this.bcBorderColorOpacity)}`,
          },
          gradient: {
            color: this.bcTextColorHover && this.states.hover ?
              colors_helpers.$_checkValidColor(this.bcTextColorHover, this.bcTextColorOpacityHover) :
              colors_helpers.$_checkValidColor(this.bcTextColor, this.bcTextColorOpacity),
            background: `linear-gradient(${colors_helpers.$_checkValidColor(this.bcGradientDirection)}, ${customGradientBackgroundColor}`,
            boxShadow: boxShadow(this.buttonState),
            backgroundSize: this.bcGradientBackgroundSize,
            ...this.bcGradientBackgroundPositionHoverEffect && this.states.hover && { backgroundPosition: this.bcGradientBackgroundPosition },
          },
        };
        return {
          ...style[this.bcType] ? style[this.bcType] : style.regular,
          padding: this.bcPadding ? this.bcPadding : '7.5px',
          borderRadius: this.bcRadius ? this.bcRadius : '4px',
          overflow: this.bcOverflow,
          ...this.bcOpacity && { opacity: this.bcOpacity },
          ...this.bcTextSize && { fontSize: this.bcTextSize },
          ...this.bcTransition && { transition: '300ms' },
        };
      },
      iconStyle() {
        return {
          fontSize: this.bcIconSize,
          order: this.bcIconAfter ? 2 : 0,
          ...this.bcTransition && { transition: '300ms' },
        };
      },
      textStyle() {
        return {
          'margin-right': this.$slots.default && this.bcIcon && this.bcIconAfter ? this.bcIconMargin : '0px',
          'margin-left': this.$slots.default && this.bcIcon && !this.bcIconAfter ? this.bcIconMargin : '0px',
          ...this.bcTextJustifyContent && { 'justify-content': this.bcTextJustifyContent },
          ...this.bcTextWidth && { width: this.bcTextWidth },
        };
      },
      extraStyle() {
        return {
          order: this.bcExtraAfter ? 3 : -1,
          'margin-right': this.$slots.extra && !this.bcExtraAfter ? this.bcIconMargin : '0px',
          'margin-left': this.$slots.extra && this.bcExtraAfter ? this.bcIconMargin : '0px',
        };
      },
      listeners() {
        return {
          ...this.$listeners,
          click: event => this.clickButton(event),
          blur: event => this.blurButton(event),
          mousedown: event => this.pressedButton(event),
          mouseup: event => this.unpressedButton(event),
          mouseover: event => this.mouseoverX(event),
          mouseout: event => this.mouseoutX(event),
        };
      },
    },
    watch: {
      states: {
        handler() {
          if (this.bcAnimated && this.bcIcon && this.$slots.default && this.$refs) {
            this.$nextTick(() => {
              if (this.states.hover) {
                this.animateIn();
              } else {
                this.animateOut();
              }
            });
          }
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      setState(state, value) {
        this.states[state] = value;
      },
      clickButton(event) {
        this.$emit('click', event);
      },
      blurButton(event) {
        this.$emit('blur', event);
      },
      pressedButton(event) {
        this.$emit('mousedown', event);
        this.setState('pressed', true);
      },
      unpressedButton(event) {
        this.$emit('mouseup', event);
        this.setState('pressed', false);
      },
      mouseoverX(event) {
        this.$emit('mouseover', event);
        this.setState('hover', true);
      },
      mouseoutX(event) {
        this.$emit('mouseout', event);
        this.setState('hover', false);
      },
      animateIn() {
        TweenMax.to(this.$refs.text, 0.7, {
          width: 'auto',
          opacity: 1,
          ease: Expo.easeOut,
        });
        TweenMax.to(this.$refs.icon, 0.7, {
          'margin-right': this.$slots.default && !this.bcIconAfter ? 5 : 0,
          'margin-left': this.$slots.default && this.bcIconAfter ? 5 : 0,
          ease: Expo.easeOut,
        });
      },
      animateOut() {
        TweenMax.to(this.$refs.text, 0.7, {
          width: 0,
          opacity: 0,
          ease: Expo.easeOut,
        });
        TweenMax.to(this.$refs.icon, 0.7, {
          'margin-right': 0,
          'margin-left': 0,
          ease: Expo.easeOut,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .bc-button {
    align-items: center;
    position: relative;

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:not(:disabled):hover {
      cursor: pointer;
    }

    &__icon {
      align-items: center;
      display: flex;
    }

    &__text {
      display: flex;
      font-size: inherit;
      white-space: nowrap;

      &--uppercase {
        text-transform: uppercase;
      }

      &--hidden-xs {
        display: none;
        @include bp('tablet') {
          display: flex;
        }
      }
    }
  }
</style>
