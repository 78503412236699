import { getSuggestionsByParams } from '@/api/suggestions';

export default {
  state: {
    suggestionsInbox: [],
    suggestionsRecommendations: [],
    suggestionsReminder: [],
  },
  actions: {
    async getRemindersSuggestions({ commit }, { coachId, reminderType }) {
      const [
        responseReminder,
        responseRecommendations,
        responseInbox,
      ] = await Promise.all([
        getSuggestionsByParams({ filter: 'todo', coachId, reminderType }),
        getSuggestionsByParams({ filter: 'postponed', coachId, reminderType }),
        getSuggestionsByParams({ filter: 'archived', coachId, reminderType }),
      ]);

      commit('setInboxPoolSuggestions', responseInbox.data);
      commit('setRecommendationsPoolSuggestions', responseRecommendations.data);
      commit('setReminderPoolSuggestions', responseReminder.data);
    },
    removeSuggestionFromToDoSuggestions({ commit, dispatch }, id) {
      commit('removeSuggestionFromToDoSuggestions', id);
      dispatch('user/decrementMetadataByKey', 'suggestions')
    },
  },
  mutations: {
    setInboxPoolSuggestions(state, data) {
      state.suggestionsInbox = data;
    },
    setRecommendationsPoolSuggestions(state, data) {
      state.suggestionsRecommendations = data;
    },
    setReminderPoolSuggestions(state, data) {
      state.suggestionsReminder = data;
    },
    removeSuggestionFromToDoSuggestions(state, suggestionId) {
      if (state.suggestionsInbox.some(suggestion => suggestion._id === suggestionId)) {
        state.suggestionsInbox.splice(state.suggestionsInbox.findIndex(suggestion => suggestion._id === suggestionId), 1);
      } if (state.suggestionsRecommendations.some(suggestion => suggestion._id === suggestionId)) {
        state.suggestionsRecommendations.splice(state.suggestionsRecommendations.findIndex(suggestion => suggestion._id === suggestionId), 1);
      } if (state.suggestionsReminder.some(suggestion => suggestion._id === suggestionId)) {
        state.suggestionsReminder.splice(state.suggestionsReminder.findIndex(suggestion => suggestion._id === suggestionId), 1);
      }
    },
  },
};
