
export const apiExperience = {
  junior: 1,
  confirmed: 2,
  expert: 3,
};

export const experienceOptions = {
  junior: {
    api: apiExperience.junior,
    name: ('table-header.junior'),
    query: 'junior',
    range: [0, 2],
  },
  confirmed: {
    api: apiExperience.confirmed,
    name: ('table-header.confirmed'),
    query: 'confirmed',
    range: [3, 5],
  },
  expert: {
    api: apiExperience.expert,
    name: ('table-header.expert'),
    query: 'expert',
    range: [6, 99],
  },
};

export default {
  apiExperience,
  experienceOptions,
};
