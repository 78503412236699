import contracts from '@/api/contracts';

export const getCompanyContracts = id => {
  const execute = (id) => {
    try {
      return contracts.getCompanyContracts(id);
    } catch (error) {
      if ((error || {}).response) {
        throw new Error(`API: ${error.response.data.message}`);
      }

      throw new Error(error);
    }
  };

  try {
    return execute(id);
  } catch (error) {
    throw error;
  }
};

export default {
  getCompanyContracts,
};
