<template>
  <div class="form-modal-container">
    <h2 class="form-modal-container__title mb-1">
      {{ $t('jobboards.publish-missing-form') }}
    </h2>
    <p class="form-modal-container__subtitle mt-5">
      ⚠️ {{ $t('jobboards.form-duplication-infos', { name }) }}
    </p>
    <div v-if="isLoading" class="form-modal-container__content--loading">
      <bc-spinner></bc-spinner>
    </div>
    <div v-else class="form-modal-container__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BcSpinner from '@/ui-kit/components/BcSpinner';

export default {
  name: 'form-modal-container',
  components: {
    BcSpinner,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
    },
  }
}
</script>

<style lang="scss" scoped>
.form-modal-container {
  padding: 5%;
  flex-direction: column;
  max-width: 800px;

  &__title {
    padding: 0px 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: $color-blue-dodger;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $color-blue-dark-cello;
  }

  &__content {
    flex-direction: column;
    overflow: auto;
    max-height: 75vh;
    margin-top: 20px;

    &--loading {
      justify-content: center;
      align-items: center;
      min-height: 100px;
    }
  }
}
</style>
