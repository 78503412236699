import { http } from '@/api/index';

export const createJob = ({ id, obj }) => {
  return http.post(`/partners/${id}/jobs`, obj);
};

export const updateJob = ({ id, obj }) => {
  return http.patch(`/jobs/${id}`, obj);
};

export const getJobProfile = id => {
  return http.get(`/jobs/${id}`);
};

export const updateJobStatus = ({ id, status }) => {
  return http.put(`/jobs/${id}/${status}`);
};

export const getJobResources = ({ id, resources, page }) => {
  return http.get(`/jobs/${id}/${resources}`, { params: { page } });
};

export const deleteJob = id => {
  return http.delete(`/jobs/${id}`);
};

export const jobsAutocomplete = query => {
  return http.get('/jobs/autocomplete', { params: { query }});
};

export default {
  createJob,
  updateJob,
  getJobProfile,
  getJobResources,
  updateJobStatus,
  deleteJob,
  jobsAutocomplete,
};
