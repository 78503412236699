import { http } from '@/api/index';

export const searchLogsActivities = ({ page, sortKey, sortValue, limit, type, action }) => {
  return http.get('/logs/activities', {
    params: {
      page,
      sortKey,
      sortValue,
      limit,
      type,
      action,
    }
  });
};

export const countLogsActivities = () => {
  return http.get('/logs/activities/count', {
    params: {
    }
  });
};

export const getTypeLogsActivities = () => {
  return http.get(`/logs/activities/type`);
};

export default {
  searchLogsActivities,
  countLogsActivities,
  getTypeLogsActivities,
};
