<template>
  <div
    v-click-outside="closePanel"
    class="panel-chat">
    <!-- Panel Header -->
    <panel-chat-header
      :company-name="companyName"
      :last-seen="lastSeen"
      class="panel-chat__header">
    </panel-chat-header>
    <!-- Panel body -->
    <panel-chat-messages
      class="panel-chat__body">
    </panel-chat-messages>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation.js';
  import PanelChatHeader from '@/components/Panel/PanelChat/PanelChatHeader';
  // dependencies
  import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
  import PanelChatMessages from '@/components/Panel/PanelChat/PanelChatMessages';
  //
  import moment from 'moment/min/moment-with-locales';

  /**
   * The panel which will call all the elements when the user wants to see a poke profile
   */
  export default {
    name: 'panel-chat',
    components: {
      PanelChatMessages,
      PanelChatHeader,
    },
    mixins: [PanelNavigation],
    created() {
      this.getPokeProfile();
    },
    computed: {
      ...mapState({
        profile: state => state.panel.profile,
        messages: state => state.chat.messages,
        user: state => state.user.profile,
        cards: state => state.card.cards
      }),
      lastSeen() {
        if (this.profile._company) {
          return moment(this.profile._company.lastSeen).fromNow();
        }
        return '';
      },
      companyName() {
        return this.profile._company ? this.profile._company.name : '';
      },
    },
    mounted() {
      this.panelPoke = document.querySelector('.panel-chat__view');

      disableBodyScroll(this.panelPoke);
    },
    methods: {
      /**
       * The method to get the poke profile
       */
      async getPokeProfile() {
        try {
          const timer = setTimeout(() => {
            this.isLoading = true;
          }, 1500);

          this.$store.dispatch('getPokeProfile', this.$route.params.id);

          clearTimeout(timer);

          this.isLoading = false;
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: this.$t('toast.error-occured'),
          });
        }
      },
    },
    beforeDestroy() {
      enableBodyScroll(this.panelPoke);
    }
  };
</script>

<style scoped lang="scss">
  .panel-chat {
    flex-direction: column;
    height: 100%;

    &__header {
      width: 100%;
      min-height: 50px;
    }

    &__body {
      height: 100%;
    }

    &__footer {
      width: 100%;
      min-height: 120px;
      max-height: 300px;
      @include bp('tablet') {
        position: initial;
      }
    }
  }
</style>
