<template>
  <div class="searchbar-tags">
    <searchbar-tags-container @on-delete="emitOnDelete">
    </searchbar-tags-container>
    <searchbar-tags-buttons
      @on-delete="emitOnDelete"
      @on-reset="emitOnReset"
      class="searchbar-tags__buttons"
      v-if="searchbarTags.length > 0">
    </searchbar-tags-buttons>
  </div>
</template>

<script>
  // TODO move or refacto to ui-kit
  import SearchbarTagsButtons from '@/components/Searchbar/SearchbarTags/SearchbarTagsButtons';
  import SearchbarTagsContainer from '@/components/Searchbar/SearchbarTags/SearchbarTagsContainer';
  import { mapState } from 'vuex';

  export default {
    components: {
      SearchbarTagsContainer,
      SearchbarTagsButtons
    },
    name: 'searchbar-tags',
    computed: {
      ...mapState({
        searchbarTags: state => state.searchbar.searchbarFiltersTags
      })
    },
    methods: {
      emitOnDelete() {
        this.$emit('on-delete');
      },
      emitOnReset() {
        this.$emit('on-reset');
      },
    }
  };
</script>

<style scoped lang="scss">
  .searchbar-tags {
    flex-direction: column;
    &__buttons {
      margin-top: 10px;
    }
  }
</style>
