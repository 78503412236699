<template>
  <div class="searchbar-filters-rating">
    <p class="searchbar-filters-rating__title is-secondary">
      {{ $t('hunt.minimum-rating') }}
    </p>
    <div class="searchbar-filters-rating__wrapper is-full-width is-column">
      <bc-slider
        v-model="rating"
        :is-input-displayed="false"
        :max="5"
        :min="0"
        :step="1"
        is-revert
        class="searchbar-filters-rating__slider is-full-width"
        @end-changing="setRatingFilter">
        <bc-slider-tick
          v-for="(r, index) in values"
          :key="index"
          :value="r"
          class="searchbar-filters-rating__tick">
          {{ r }}
        </bc-slider-tick>
      </bc-slider>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import BcSlider from '@/ui-kit/components/BcSlider/BcSlider';
  import BcSliderTick from '@/ui-kit/components/BcSlider/BcSliderTick';
  import { buildRatingTag } from '@/helpers/searchbar/factories';

  export default {
    name: 'searchbar-filters-rating',
    components: { BcSliderTick, BcSlider },
    data() {
      return {
        rating: 0,
      };
    },
    computed: {
      ...mapState({
        searchbarFiltersTags: state => state.searchbar.searchbarFiltersTags,
      }),
      values() {
        return Array.from(Array(6).keys()).reverse()
      }
    },
    methods: {
      setRatingFilter(value) {
        if (value) {
          this.$emit('on-select', buildRatingTag(value));
        } else {
          const tagIndex = this.searchbarFiltersTags.findIndex(tag => tag.type === 'rating');

          if (tagIndex >= 0) {
            this.$emit('on-unselect', tagIndex);
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-filters-rating {
    flex-direction: column;
    @include bp('tablet') {
      flex-direction: row;
    }

    &__title {
      margin-bottom: 20px;
      font-size: $font-size-s;
      justify-content: center;
      line-height: 18px;
      @include bp('tablet') {
        justify-content: flex-start;
        text-align: left;
        margin-bottom: 0;
        width: 85px;
        margin-right: 35px;
      }
    }

    &__wrapper {
      position: relative;
      margin-right: -30px;
      justify-content: center;
      @include bp('tablet') {
        min-width: 305px;
        margin-top: -5px;
      }
    }

    &__text {
      margin-top: 15px;
    }

    &__slider {
      flex-shrink: 0;
      min-height: 55px;
    }

    &__tick {
      :deep() {
        .bc-slider-tick__label {
          min-width: 100px;
          text-align: center;
        }
      }
    }
  }
</style>
