<template>
  <div class="notifications-list-header">
    <div class="notifications-list-header__title">
      <i class="icon-bell mr-3"></i>
      <p>{{ $t('generics.notifications') }}</p>
    </div>
    <button
      class="notifications-list-header__button"
      :class="{ 'notifications-list-header__button--empty': !hasNew }"
      @click="$emit('click')"
    >
      <i class="icon-check mr-2"></i> {{ $t('notifications.mark-all-as-reads') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'notifications-list-header',
  props: {
    hasNew: {
      type: Boolean,
    }
  }
}
</script>

<style lang="scss" scoped>
.notifications-list-header {
  width: 100%;
  height: 44px;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    color: $color-blue-dark-cello;
  }

  &__button {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: $color-primary;
    padding: 5px;

    &:hover {
      border-radius: 5px;
      background: $color-blue-light;
      z-index: 10;
    }

    &--empty {
      opacity: 0.5;
      &:hover {
        background: none;
      }
    }
  }
}
</style>
