<template>
  <filter-content class="filter-coders-last-activity" :label="$t('hunt.last-activity')">
    <div class="filter-coders-last-activity__wrapper is-full-width is-column">
      <bc-slider
        v-model="range"
        class="filter-coders-last-activity__slider is-full-width"
        :is-input-displayed="false"
        :max="max"
        :min="0"
        :step="1"
        type="range"
        is-revert
        @end-changing="onChange">
        <bc-slider-tick :value="0" class="filter-coders-last-activity__tick">
          {{ $t('hunt.today') }}
        </bc-slider-tick>
        <bc-slider-tick :value="1" class="filter-coders-last-activity__tick">
          {{ $t('hunt.three-months') }}
        </bc-slider-tick>
        <bc-slider-tick :value="2" class="filter-coders-last-activity__tick">
          {{ $t('hunt.six-months') }}
        </bc-slider-tick>
        <bc-slider-tick :value="3" class="filter-coders-last-activity__tick">
          {{ $t('hunt.one-year') }}
        </bc-slider-tick>
        <bc-slider-tick :value="4" class="filter-coders-last-activity__tick">
          {{ $t('hunt.more-than-a-year') }}
        </bc-slider-tick>
      </bc-slider>
    </div>
  </filter-content>
</template>

<script>
import moment from 'moment/min/moment-with-locales';
import { mapGetters, mapActions } from 'vuex';
import BcSlider from '@/ui-kit/components/BcSlider/BcSlider';
import BcSliderTick from '@/ui-kit/components/BcSlider/BcSliderTick';
import FilterContent from '@/components/Filter/FilterContent';
import { Modals } from '@/store/modules/app';

export default {
  name: 'filter-coders-last-activity',
  components: {
    BcSlider,
    BcSliderTick,
    FilterContent,
  },
  props: {
    emitOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      range: [0, 4],
      options: [
        {
          labelLeft: this.$i18n.t('hunt.no-limit-determined'),
          labelRight: '',
          labelLast: '',
          query: undefined,
        },
        {
          labelLeft: this.$i18n.t('hunt.more-than-3-months'),
          labelRight: this.$i18n.t('hunt.less-than-3-months'),
          labelLast: this.$i18n.t('hunt.the-last-3-months'),
          query: moment().subtract(3, 'months').format('D-MM-YYYY'),
        },
        {
          labelLeft: this.$i18n.t('hunt.more-than-6-months'),
          labelRight: this.$i18n.t('hunt.less-than-6-months'),
          labelLast: this.$i18n.t('hunt.the-last-6-months'),
          query: moment().subtract(6, 'months').format('D-MM-YYYY'),
        },
        {
          labelLeft: this.$i18n.t('hunt.more-than-1-year'),
          labelRight: this.$i18n.t('hunt.less-than-1-year'),
          labelLast: this.$i18n.t('hunt.the-last-year'),
          query: moment().subtract(12, 'months').format('D-MM-YYYY'),
        },
        {
          labelLeft: '',
          labelRight: this.$i18n.t('hunt.no-limit-determined'),
          labelLast: '',
          query: undefined,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      configurationFeatures: 'user/configurationFeatures',
    }),
    max() {
      return this.options.length - 1;
    },
    label() {
      if (this.range[0] > 0 && this.range[1] === this.max) {
        return `${this.$i18n.t('hunt.no-action-for')} ${this.options[this.range[0]].labelLeft}`;
      }

      return this.range[0] === 0 && this.range[1] < this.max
        ? `${this.$i18n.t('hunt.no-action-on')}  ${this.options[this.range[1]].labelLast}`
        : `${this.$i18n.t('hunt.no-action-for')} ${
            this.options[this.range[0]].labelLeft
          } ${this.$i18n.t('hunt.but')} ${this.options[this.range[1]].labelRight}`;
    },
  },
  methods: {
    ...mapActions({
      setModal: 'app/setModal',
    }),
    setQuery(range) {
      const [from, to] = range;
      const newQuery = {
        'last-coach-activity-from': from ? this.options[+from]?.query : undefined,
        'last-coach-activity-to': to ? this.options[+to]?.query : undefined,
      };

      if (this.emitOnly) {
        this.$emit('update:filters', newQuery);
      } else {
        this.$router.push({
          query: {
            ...this.$route.query,
            page: this.$route.query?.page ? this.$route.query.page : 1,
            ...newQuery,
          },
        });
      }
    },
    onChange(range) {
      if (!this.configurationFeatures.searchByLastActivity) {
        this.setModal(Modals['modal-feature-last-activity']);
        return;
      }
      this.setRange(range)
    },
    setRange(range) {
      this.setQuery(range);
      this.$emit('count', { key: 'last-activity', count: range[0] !== 0 || range[1] !== 4 ? 1 : 0 });
      this.range = range;
    },
  },
  watch: {
    '$route.query': {
      handler(query) {
        const from = query['last-coach-activity-from'];
        const to = query['last-coach-activity-to'];

        const fromRange = this.options.findIndex(opt => from && opt.query === from)
        const toRange = this.options.findIndex(opt => to && opt.query === to)

        if (fromRange !== -1 && toRange !== -1) {
          this.setRange([fromRange, toRange]);
        } else if (fromRange !== -1) {
          this.setRange([fromRange, 4]);
        } else if (toRange !== -1) {
          this.setRange([0, toRange])
        } else {
          this.setRange([0, 4])
        }
      },
      immediate: true,
    },
  }
};
</script>

<style lang="scss" scoped>
.filter-coders-last-activity {
  min-height: 90px;

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: $font-size-s;
    justify-content: center;
    line-height: 18px;
    @include bp('tablet') {
      justify-content: flex-start;
      text-align: left;
      margin-bottom: 0;
      width: 85px;
      margin-right: 35px;
    }
  }

  &__wrapper {
    position: relative;
    margin-right: -30px;
    justify-content: center;
    @include bp('tablet') {
      min-width: 305px;
      margin-top: -5px;
    }
  }

  &__text {
    margin-top: 15px;
  }

  &__slider {
    flex-shrink: 0;
    min-height: 55px;
  }

  &__tick {
    :deep() {
      .bc-slider-tick__label {
        min-width: 100px;
        text-align: center;
      }
    }
  }
}
</style>
