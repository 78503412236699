<template>
  <div class="reminders-transfer-modal border-radius-s is-column">
    <div class="reminders-transfer-modal__header">
      <p class="reminders-transfer-modal__text"> {{ $t('candidates.transfer-to-recruiter') }} : </p>
    </div>
    <div class="is-column">
      <p class="reminders-transfer-modal__label mb-2">
        {{ $t('candidates.recruiter-name') }}
      </p>
      <BcDropdown
        :options="coachesOptions"
        class="reminders-transfer-modal__dropdown reminders-transfer-modal__wrapper"
        is-expanded>
        <template #label="{ isDropdownOpen }">
          <BcDropdownTrigger
            class="reminders-transfer-modal__filter"
            :is-arrow="true"
            :is-dropdown-open="isDropdownOpen"
            :text="`${selectedCoach.firstName} ${selectedCoach.lastName}`" />
        </template>
        <template #option="{ activeIndex, index, option }">
          <BcDropdownItem
            :is-focus="activeIndex === index"
            :text="`${option.firstName} ${option.lastName}`"
            @change="selectCoach(option)"
            @click.native="selectCoach(option)" />
        </template>
      </BcDropdown>
    </div>
    <div class="reminders-transfer-modal__buttons">
      <BcButton
        class="reminders-transfer-modal__button"
        type="outlined"
        @click="$emit('cancel')">
        {{ $t('candidates.cancel-transfer') }}
      </BcButton>
      <BcButton
        class="reminders-transfer-modal__button"
        color="success"
        @click="$emit('confirm', selectedCoach)">
        {{ $t('candidates.forward-to-this-recruiter') }}
      </BcButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
import BcDropdownTrigger from '@/ui-kit/components/BcDropdown/BcDropdownTrigger.vue';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
import { getFilterableCoach } from '@/macros/prospectionPipe/dropdowns';
import { ref, computed } from 'vue';
import { useStore } from '@/store';

const emit = defineEmits<{
  (e: 'confirm', coach: any): void;
  (e: 'cancel'): void;
}>();

const selectedCoachId = ref();
const store = useStore();

const coaches = computed(() => store.state.coaches.coaches);

const coachesOptions = computed(() =>
  coaches.value
    .filter(coach => getFilterableCoach(coach))
    .sort((a, b) => a.firstName.localeCompare(b.firstName)),
);

const selectedCoach = computed(
  () =>
    coachesOptions.value.find(coach => coach._id === selectedCoachId.value) ||
    coachesOptions.value[0],
);

function selectCoach(coach) {
  selectedCoachId.value = coach._id;
}
</script>

<style lang="scss" scoped>
.reminders-transfer-modal {
  padding: 50px;
  min-width: 540px;
  height: 259px;
  gap: 10px;

  &__header {
    flex-direction: column;
  }

  &__text {
    color: $color-tertiary;
    font-weight: $font-weight-bold;
    font-size: $font-size-xl;
    line-height: 138.9%;
  }

  &__label {
    color: $color-blue-heavy-dark;
    font-size: $font-size-xs;
  }

  &__buttons {
    margin-top: 30px;
    width: 100%;
    gap: 20px;
  }

  &__button {
    font-size: $font-size-s;
    flex: 1;
  }

  &__filter {
    justify-content: space-between;
    align-items: center;
    min-width: 215px;
    color: $color-primary;
    transition: 0.3s;
    border-radius: 4px;
    font-size: $font-size-m;
    text-align: center;
    height: 40px;
    background: $color-white;

    &:hover {
      cursor: pointer;
      transition: 0.3s;
    }

    &--active {
      background-color: $color-primary-dark;
      color: $color-white;

      &:hover {
        background-color: $color-primary-dark;
      }
    }

    &-chevron {
      font-size: 14px;
      height: 12px;
      margin-left: 10px;
    }
  }

  &__wrapper {
    justify-content: center;
  }

  &__dropdown {
    width: 188px;
    height: 36px;
  }
}
</style>
