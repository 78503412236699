/**
 * @mixin
 */

export default {
  methods: {
    /**
     * Method to open the Panel
     */
    openPanel({ id, type, category, subtype, context, ...query }) {
      this.$router.push({
        name: this.$route.name.includes('Panel') ? this.$route.name : `${this.$route.name}Panel`,
        params: {
          ...this.$route.params,
          id,
        },
        query: {
          ...this.$route.query,
          ...type && { type },
          ...category && { category },
          ...subtype && { subtype },
          ...context && { context },
          ...query,
        },
      });
    },

    /**
     * Method to open the Panel
     */
    openPanelInNewTab({ id, type, category, subtype, context, ...query }) {
      let routeName;
      if (this.$route.name === 'HuntJobsPanel' || this.$route.name === 'HuntJobs' || this.$route.name === 'HuntJobsPanel') {
        routeName = 'HuntJobsPanel';
      } else if (this.$route.name.includes('Panel')) {
        routeName = this.$route.name;
      } else {
        routeName = `${this.$route.name}Panel`;
      }
      const route = this.$router.resolve({
        name: routeName,
        params: {
          ...this.$route.params,
          id,
        },
        query: {
          ...this.$route.query,
          ...type && { type },
          ...category && { category },
          ...subtype && { subtype },
          ...context && { context },
          ...query,
        },
      });

      window.open(route.href, '_blank');
    },
    /**
     * Method to close the Panel, it change the url for the last '/'
     */
    closePanel() {
      console.log('closePanel')
      if (this.$store.state.app.modal) {
        return;
      }
      delete this.$route.query.type;
      delete this.$route.query.category;
      delete this.$route.query.modal;
      delete this.$route.query.subtype;
      delete this.$route.query.context;
      delete this.$route.query['coaches-activities'];
      delete this.$route.query['users-activities'];
      delete this.$route.query['processes-activities'];
      delete this.$route.query['all-activities'];
      delete this.$route.query['unsaved-changes'];
      delete this.$route.query['timeline-type'];
      delete this.$route.query['view-tab'];
      delete this.$route.query.display;
      delete this.$route.query.quicksearch;

      if (this.$route.query.from === 'contact-autocomplete') {
        delete this.$route.query.contactInput;
        delete this.$route.query.from;
        delete this.$route.query.companyId;
      }

      if (!this.$route.params.id || this.$route.params.id === 'panel' || this.$route.params.from === 'panel' || this.$route.params.id === 'new-custom-jobs') {
        this.goPrevious();
      } else {
        this.$router.push({
          path: this.$route.path.substr(0, this.$route.path.lastIndexOf('/')),
          query: {
            ...this.$route.query,
          },
        });
      }
    },
    /**
     * Method to make the $route to go to the previous $route
     */
    goPrevious() {
      this.$router.go(-1);
    },
    manageClosePanel(event = {}) {
      if ((event.target || {}).className === 'icon-close' || (event.target || {}).className === 'overlay') {
        this.closePanel();
      }
    },
  },
};
