import { Component, InjectionKey, Ref } from 'vue';
import { Campaign } from '@/domains/models/Campaign';

export type TemplateDraftInjection = {
  templateDraft: Ref<Partial<Campaign>>;
  setTemplateDraftValue: <K extends keyof Campaign>(campaign: Record<keyof Campaign, Campaign[K]>) => void;
};

export type Tab = {
  key: string;
  label: string;
  icon: string;
  onClick: () => void;
  component: Component;
};

const templateDraftKey = Symbol() as InjectionKey<TemplateDraftInjection>;

export default {
  templateDraftKey,
};
