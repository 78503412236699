<template>
  <section class="modal__container" @click.stop>
    <header>
      <h1 class="table-modal-title">{{ title }}</h1>
    </header>
    <main :class="showModal ? 'main--modal' : ''">
      <table>
        <thead>
          <table-header
            class="tr--modal"
            :columnsData="tableColumnsHeader"
            :showModal="showModal"
            :editable="true"
          >
          </table-header>
        </thead>
        <tbody>
          <tr>
            <component
              :is="col.component"
              v-for="(col, i) in tableColumnsHeader"
              :key="i"
              :canEdit="true"
              :columnParams="col.params"
              :columnType="col.name"
              :placeholder="col.placeholder"
              :companyContacts="companyContacts"
              :editFromModal="true"
              :job="jobToEdit"
              :showModal="true"
              @update-cell-data="updateCellData">
            </component>
          </tr>
        </tbody>
      </table>
      <section class="dropdown--coaches">
        <h1 class="table-modal-title">{{ $t('table-modal.share-with') }}</h1>
        <bc-dropdown :options="coachesOptions" is-expanded>
          <template #label="{ isDropdownOpen }">
            <bc-dropdown-trigger
              :is-arrow="true"
              :is-dropdown-open="isDropdownOpen">
            </bc-dropdown-trigger>
          </template>
          <template #option="{ activeIndex, index, option }">
            <bc-dropdown-item
              :is-focus="activeIndex === index"
              :text="`${option.name}`"
              @click.native="selectedCoach(option)">
            </bc-dropdown-item>
          </template>
        </bc-dropdown>
        <template>
          <div class="dropdown--coaches--btn--name">
            <bc-tag
              v-for="(coach, index) in dataToPost.coachId"
              :key="index"
              :deletable="dataToPost.coachId && dataToPost.coachId.length > 1"
              class="bc-dropdown-item__tag"
              size="medium"
              @click.native="deleteCoach(index)">
              {{ `${coach.coachName}` }}
            </bc-tag>
          </div>
        </template>
      </section>
    </main>
    <footer>
      <bc-button type="outlined" @click.native="$emit('closeModal')">
        {{ $t('generics.cancel') }}
      </bc-button>
      <bc-button color="success" @click="jobApi"> {{ $t('generics.validate') }} </bc-button>
    </footer>
  </section>
</template>

<script>
import TableHeader from '../TableEditableHeader';
import Linkcell from '../Cell/LinkCell';
import CompanyCell from '../Cell/CompanyCell';
import StringCell from '../Cell/StringCell';
import SalaryCell from '../Cell/SalaryCell';
import SwitchCell from '../Cell/SwitchCell';
import UrgencyCell from '../Cell/UrgencyCell';
import NumberCell from '../Cell/NumberCell';
import LocationCell from '../Cell/LocationCell';
import DropdownCell from '../Cell/DropdownCell';
import OptionsCell from '../Cell/OptionsCell';
import { http } from '@/api/index.js';
import { mapState } from 'vuex';
import BcDropdown from '../../../ui-kit/components/BcDropdown/BcDropdown';
import BcDropdownItem from '../../../ui-kit/components/BcDropdown/BcDropdownItem';
import BcDropdownTrigger from '../../../ui-kit/components/BcDropdown/BcDropdownTrigger';
import BcTag from '../../../ui-kit/components/BcTag/BcTag';
import { getFilterableCoach } from '@/macros/prospectionPipe/dropdowns';
import BcButton from '@/ui-kit/components/BcButton/BcButton';

export default {
  name: 'tablemodal',
  components: {
    BcButton,
    TableHeader,
    Linkcell,
    CompanyCell,
    StringCell,
    SalaryCell,
    SwitchCell,
    UrgencyCell,
    NumberCell,
    LocationCell,
    DropdownCell,
    OptionsCell,
    BcDropdown,
    BcDropdownItem,
    BcDropdownTrigger,
    BcTag,
  },
  props: {
    jobToEdit: {
      type: Object,
    },
    tableColumnsHeader: {
      type: Array,
      required: true,
    },
    fromPanel: {
      type: Boolean,
    },
    showModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      companyContacts: [],
      error: false,
      dataToPost: {
        coachId: [],
      },
    };
  },
  created() {
    if (!this.jobToEdit._id) {
      this.dataToPost.status = true;
    }

    if (this.fromPanel) {
      this.companyContacts = this.jobToEdit.companyId._contacts;
    }

    if (this.jobToEdit._id) {
      this.jobToEdit.coachId.forEach(coach => {
        this.dataToPost.coachId.push({
          coachId: coach._id,
          coachName: `${coach.firstName} ${coach.lastName}`,
        });
      });
      return;
    }

    this.dataToPost.coachId.push({
      coachId: this.profile._id,
      coachName: `${this.profile.firstName} ${this.profile.lastName}`,
    });
  },
  computed: {
    title() {
      if (this.jobToEdit._id) return  this.$i18n.t('table-modal.job-modification');

      return this.$i18n.t('table-modal.new-opportunity');

    },
    btnContent() {
      if (this.jobToEdit._id) this.$i18n.t('hunt.edit');
      return this.$i18n.t('table-modal.btn-create-the-new-opportunity');
    },
    ...mapState({
      coaches: state => state.coaches.coaches,
      profile: store => store.user.profile,
    }),
    coachesOptions() {
      let coaches;
      if (this.$store.getters['user/isSuperUser']) {
        coaches = this.coaches
          .filter(coach => getFilterableCoach(coach))
          .map(coach => ({
            name: `${coach.firstName} ${coach.lastName}`,
            query: coach._id,
          }));
      } else {
        coaches = this.coaches.map(coach => ({
          name: `${coach.firstName} ${coach.lastName}`,
          query: coach._id,
        }));
      }

      return [...coaches.sort((a, b) => a.name.localeCompare(b.name))];
    },
  },
  async mounted() {
    if (this.jobToEdit.companyId) {
      const { data } = await http.get(
        `/partners/${this.jobToEdit?.companyId?._id}/contacts`
      );

      this.companyContacts = data;
      this.dataToPost.companyId = this.jobToEdit.companyId._id;
    }

    this.setIsTyping(true);
    this.$on('hook:beforeDestroy', () => {
      this.setIsTyping();
    });
  },
  methods: {
    async updateCellData(cellData) {
      if (cellData.columnName === 'entreprise') {
        try {
          const { data } = await http.get(
            `/partners/${cellData.data}/contacts`
          );

          this.companyContacts = data;
          this.dataToPost.companyId = cellData.data;
          return;
        } catch (error) {
          this.error = true;
        }
      } else if (cellData.columnName === 'salaire') {
        this.dataToPost.salary = cellData.data;
      } else if (cellData.columnName === 'xp') {
        this.dataToPost.experience = cellData.data;
      } else if (cellData.columnName === 'nb. profils') {
        this.dataToPost.profils = cellData.data;
      } else if (cellData.columnName === 'localisation') {
        this.dataToPost.locations = [cellData.data];
      } else if (cellData.columnName === 'o/f') {
        this.dataToPost.status = cellData.data;
      } else if (cellData.columnName === 'contact') {
        this.dataToPost.interlocuteur = cellData.data;
      } else {
        this.dataToPost[cellData.columnName] = cellData.data;
      }
    },

    selectedCoach(coach) {
      const coachId = this.dataToPost.coachId.map(c => c.coachId);

      if (!coachId.includes(coach.query)) {
        this.dataToPost.coachId.push({
          coachId: coach.query,
          coachName: coach.name,
        });
      }
    },

    deleteCoach(coachIndex) {
      if (this.dataToPost.coachId.length > 1)
        this.dataToPost.coachId.splice(coachIndex, 1);
    },
    jobApi() {
      if (this.fromPanel) {
        if (!this.dataToPost) return false;

        return this.$emit('jobApi', this.dataToPost);
      }

      if (this.jobToEdit._id) {
        return this.$emit('jobApi', this.dataToPost);
      }

      if (!this.dataToPost.poste) {
        this.$toast.show({
          type: 'error',
          title: 'Error',
          message: this.$i18n.t('table-modal.job-required'),
        });

        return false;
      }

      if (!this.dataToPost.companyId) {
        this.$toast.show({
          type: 'error',
          title: 'Error',
          message: this.$i18n.t('table-modal.company-required'),
        });

        return false;
      }

      this.$emit('jobApi', this.dataToPost);
    },

    setIsTyping(value) {
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          typing: value,
        },
      });
    },
  },
}
</script>

<style lang="scss">
.modal__container {
  min-width: 30vw;
}
section {
  padding-left: 0;
  font-family: 'Roboto';
  flex-direction: column;
  padding: 2%;
  width: 100%;

  header {
    justify-content: center;
  }

  table {
    .tr--modal {
      background-color: #e1ebfe;
      line-height: 12px;
    }

    td {
      font-family: 'Roboto';
      border: solid 1px #caddfd;
      padding: 0;

      .bc-dropdown {
        .bc-dropdown__input {
          .bc-dropdown-trigger {
            border-radius: 0;
            border-color: $color-primary;
            width: 5vw;
          }
        }
      }
    }
  }

  .dropdown--coaches {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: initial;
    margin-top: 2%;
    border-top: 1px solid #e5ecf0;
    border-bottom: 1px solid #e5ecf0;
    margin-bottom: 20px;

    h2 {
      color: $color-primary;
      opacity: 0.7;
    }

    .bc-dropdown {
      width: 18%;
      margin-bottom: 12px;
    }

    &--btn--name {
      width: 100%;
      justify-content: center;
      margin-right: 6px;

      button {
        margin-right: 16px;
      }
    }
  }

  footer {
    display: flex;
    justify-content: center;

    button {
      margin-right: 2%;
      padding: 10px 25px !important;
    }
  }

  @media (min-width: 1440px) and (max-width: 1660px) {
    .modal__container__edit {
      height: 70vh;
      width: 90vw;
    }
  }
}
.table-modal-title {
  color: $color-blue-dodger;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 2%;
}
</style>
