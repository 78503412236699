<template>
  <div
    :class="[
      {
        'bc-dropdown-item--focus': isFocus,
        'bc-dropdown-item--selected': isSelected && !isFocus,
      },
      color,
    ]"
    class="bc-dropdown-item">
    <span v-if="icon" :class="`icon-${icon}`" class="bc-dropdown-item__icon">
    </span>
    <div v-if="prefix">
      <p class="bc-dropdown-item__prefix">{{ prefix }}</p>
      &nbsp;&nbsp;|&nbsp;&nbsp;
    </div>
    <div v-if="avatar">
      <bc-avatar
        :src="avatar"
        class="mr-2"
        size="s"
        @error="$emit('error', $event)"/>
    </div>
    <div v-if="img">
      <img :src="img" alt="icon" class="mr-3"/>
    </div>
    <div
      v-if="svgComponent"
      class="mr-3">
      <component :is="svgComponent"/>
    </div>
    <p
      v-if="text"
      :class="{
        'bc-dropdown-item__text--cropped': info || tag,
      }"
      class="bc-dropdown-item__text">
      {{ text }}
    </p>
    <p
      v-if="$slots.default"
      :class="{
        'bc-dropdown-item__text--cropped': info || tag,
      }"
      class="bc-dropdown-item__text">
      <slot></slot>
    </p>
    <p v-if="info" class="bc-dropdown-item__info">
      {{ info }}
    </p>
    <span v-if="notification" class="bc-dropdown-item__notification-dot">
    </span>
    <bc-tag
      v-if="tag !== null"
      class="bc-dropdown-item__tag"
      size="small"
      type="rounded">
      {{ tag }}
    </bc-tag>
    <i
      v-if="!isMultiSelect && isSelected"
      class="bc-dropdown-item__check icon-check">
    </i>
    <div v-else-if="isMultiSelect" class="bc-dropdown-item__multi">
      <i v-if="isSelected" class="icon-check"></i>
    </div>
  </div>
</template>

<script>
  import BcTag from '../BcTag/BcTag';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';

  export default {
    name: 'bc-dropdown-item',
    components: { BcTag, BcAvatar },
    props: {
      icon: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: '',
      },
      info: {
        type: String,
        default: '',
      },
      notification: {
        type: Boolean,
        default: false,
      },
      svgComponent: {
        type: [Object, String],
        required: false,
        default: '',
      },
      tag: {
        type: [String, Number],
        default: null,
      },
      isFocus: {
        type: Boolean,
        default: false,
      },
      isSelected: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        default: 'tertiary',
      },
      isMultiSelect: {
        type: Boolean,
        default: false,
      },
      prefix: {
        type: String,
      },
      avatar: {
        type: [String, Object],
      },
      img: {
        type: [String, Object],
      },
    },
  };
</script>

<style lang="scss" scoped>
  $colors: (
    'tertiary': (
      $color-tertiary,
    ),
    'primary': (
      $color-primary,
    ),
    'secondary': (
      $color-secondary,
    ),
    'danger': (
      $color-red-mandy,
    )
  );

  .bc-dropdown-item {
    $this: &;
    position: relative;
    width: 100%;
    color: inherit;
    padding: 14px 20px;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
    }

    @each $name, $pair in $colors {
      &.#{$name} {
        & p, span {
          color: nth($pair, 1);
        }

        &:hover,
        &--focus {
          background-color: $color-blue-light;

          #{$this}__info {
            color: $color-grey-3;
          }

          #{$this}__prefix {
            color: $color-white;
          }

          #{$this}__tag {
            color: $color-white;
            background-color: rgba($color-white, 0.2);
          }

          & p, span {
            @if ($name == 'tertiary') {
              color: $color-primary;
            }
          }
        }
      }
    }

    &__danger {
      & p, span {
        color: $color-red-mandy !important;
      }
    }

    &--selected {
      background-color: $color-blue-light;

      & p, span, i {
        color: $color-primary !important;
      }

      & .bc-dropdown-item__prefix {
        color: $color-white;
      }
    }

    &__icon {
      height: $font-size-m;
      margin-right: 10px;
    }

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &--cropped {
        max-width: calc(100% - 50px);
      }
    }

    &__prefix {
      color: rgba(29, 57, 94, 0.5);
      overflow: hidden;
    }

    &__info {
      color: $color-grey-4;
      margin: 0 10px 0 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__notification-dot {
      border-radius: $border-radius-rounded;
      width: 6px;
      height: 6px;
      background-color: $color-error;
      margin-left: 5px;
    }

    &__tag {
      position: absolute;
      right: 20px;
      padding: 5px 7px;
      font-size: $font-size-s;
      border-radius: $border-radius-l;
      background-color: rgba($color-secondary, 0.2);
      margin-left: auto;
    }

    &__check {
      margin-left: auto;
    }

    &__multi {
      margin-left: auto;
      background: white;
      border: 1px solid gray;
      width: 25px;
      height: 25px;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      & > i {
        color: $color-primary-dark;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
</style>
