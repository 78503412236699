import { http } from '@/api/index';
import { flip } from '@/helpers/object';
import { apiContracts } from './custom-jobs';

export const getCoderProfile = (id, context) => {
  return http.get(`/coders/${id}`, {
    params: {
      ...context && { context },
    },
  });
};

export const getCoderActivity = id => {
  return http.get(`/coders/${id}/activity`);
};

export const getDeduplication = ({ firstName, lastName, email, phone, linkedin }) => {
  return http.get(`/coders/deduplication`, {
    params: {
      firstName,
      lastName,
      email,
      phone,
      linkedin,
    }
  })
}

export const updateCoderProfile = ({ id, updatedObj }) => {
  if (updatedObj.contractCurrent && typeof updatedObj.contractCurrent === 'number') {
    updatedObj.contractCurrent = flip(apiContracts)[updatedObj.contractCurrent];
  }
  return http.patch(`/coders/${id}`, updatedObj);
};

export const deleteCoderProfile = id => {
  return http.delete(`/coders/${id}`);
};

export const updateCoderStatus = ({ id, status }) => {
  return http.put(`/coders/${id}/${status}`);
};

export const getCodersPokes = ({ id, page }) => {
  return http.get(`/coders/${id}/pokes`, { params: { page } });
};

export const createCoder = coderInfo => {
  if (coderInfo.contractCurrent && typeof coderInfo.contractCurrent === 'number') {
    coderInfo.contractCurrent = flip(apiContracts)[coderInfo.contractCurrent];
  }
  return http.post('/coders', {
    ...coderInfo,
    source: 'coach',
  });
};

export const inviteCoder = ({ id, email }) => {
  return http.post(`/coders/${id}/invite`, { email, target: 'bluecoders' });
};

export const getCoderLinkedIn = id => {
  return http.get(`/coders/${id}/linkedin`);
};

export const resetCoderStatus = id => {
  return http.put(`/coders/${id}/status/reset`);
};

export const resetCoderPendingTimer = id => {
  return http.put(`/coders/${id}/timer/reset`);
};

export const codersAutocomplete = (query, listId) => {
  return http.get('/coders/autocomplete', { params: { query, listId } });
};

export const candidatesAutocomplete = (query, listId) => {
  return http.get('coders/autocomplete/candidates', { params: { query, listId } });
};

export const contactsAutocomplete = (query, listId) => {
  return http.get('coders/autocomplete/contacts', { params: { query, listId } });
};

export const coderMerge = ({ id, coderToDelete, fieldsToMerge }) => {
  return http.put(`/coders/${id}/merge`, {
    coderToDelete,
    fieldsToMerge,
  });
};

export const coderInterest = (id, isInteresting) => {
  return http.put(`/coders/${id}/interest`, {
    isInteresting,
  });
};

export const coderSubscriptions = page => {
  return http.get('/coders/subscriptions', { params: { page } });
};

export const codersLookup = (params) => {
  return http.get('/coders/lookup', { params: { page: 1, ...params } });
};

export const contactsLookup = (params) => {
  return http.get('/contacts/lookup', { params: { page: 1, ...params } });
};

export const getCoderContracts = id => {
  return http.get(`/coders/${id}/documents`);
};

export const createCoderContract = ({ id, data }) => {
  return http.post(`/coders/${id}/documents`, data);
};

export const uploadCoderResume = (formData, onUploadProgress) => {
  return http.post(
    `/coders/upload_resume`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress,
    }
  )
}

export const uploadMultipleCodersResume = (formData) => {
  return http.post(
    `/coders/upload_resume/multiple`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    }
  )
}

export const parseCoderResume = (coderId) => {
  return http.put(
    `/coders/${coderId}/from-resume`,
  )
}

export const getCodersFromAlgolia = (query) => {
  return http.get('coders/algolia/search', { params: { query } });
}

export const extractCodersTextDocuments = (id) => {
  return http.put(`/coders/${id}/extract-text-document`);
}

export default {
  getCoderProfile,
  updateCoderProfile,
  deleteCoderProfile,
  updateCoderStatus,
  getCodersPokes,
  createCoder,
  inviteCoder,
  getCoderLinkedIn,
  resetCoderStatus,
  resetCoderPendingTimer,
  getCoderActivity,
  coderMerge,
  coderInterest,
  codersAutocomplete,
  candidatesAutocomplete,
  contactsAutocomplete,
  coderSubscriptions,
  codersLookup,
  uploadCoderResume,
  getDeduplication,
  uploadMultipleCodersResume,
  parseCoderResume,
  getCodersFromAlgolia,
  extractCodersTextDocuments,
};
