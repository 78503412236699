import { getDomains } from '@/api/domains';
import { domainController } from '@/managers/domains/controller';

export default {
  namespaced: true,
  state: {
    domains: [],
  },
  actions: {
    async getDomains({ commit }) {
      const { data } = await getDomains();

      commit('setDomains', data);
    },
    async createDomain({ commit }, { type, name }) {
      const domain = await domainController.create({ type, name });

      commit('setDomains', [domain]);

      return domain;
    },
    async editDomain({ commit }, { id, name }) {
      await domainController.edit({ id, name });

      commit('updateDomain', { _id: id, name });
    },
    async deleteDomain({ commit }, { id }) {
      await domainController.remove(id);

      commit('deleteDomain', id);
    },
  },
  mutations: {
    setDomains(state, domains) {
      state.domains = [...domains, ...state.domains];
    },
    updateDomain(state, domain) {
      const index = state.domains.findIndex(t => t._id === domain._id);

      state.domains[index].name = domain.name;
    },
    deleteDomain(state, id) {
      state.domains = state.domains.filter(domain => domain._id !== id);
    },
  },
};
