<template>
  <mv-filter
    class="filter-companies-remote"
    icon="remote-partial"
    :label="$t('generics.remote')"
    :count="count"
    align="right">
    <filter-content :label="$t('generics.remote')" icon="remote-partial">
      <div class="filter-companies-remote__radios">
        <bc-checkbox
          id="full-time"
          name="full-time"
          @input="handleCheck('full')"
          :value="selectedTab.includes('full')">
          {{ $t('panel-coder-edit.full-time') }}
        </bc-checkbox>
        <bc-checkbox
          id="partial"
          name="partial"
          @input="handleCheck('partial')"
          :value="selectedTab.includes('partial')">
          {{ $t('panel-coder-edit.partial') }}
        </bc-checkbox>
        <bc-checkbox
          id="possible"
          name="possible"
          @input="handleCheck('available')"
          :value="selectedTab.includes('available')">
          {{ $t('panel-coder-edit.possible') }}
        </bc-checkbox>
      </div>
    </filter-content>
  </mv-filter>
</template>

<script>
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
import MvFilter from '@/ui-kit/components/MvFilter/MvFilter';
import FilterContent from '@/components/Filter/FilterContent';

export default {
  name: 'filter-companies-remote',
  components: {
    BcCheckbox,
    MvFilter,
    FilterContent,
  },
  computed: {
    selected() {
      return this.$route.query.remotes;
    },
    selectedTab() {
      if (!this.selected) return [];
      return this.selected.split(',');
    },
    count() {
      return (
        this.selectedTab.includes('full') +
        this.selectedTab.includes('partial') +
        this.selectedTab.includes('available')
      );
    },
  },
  methods: {
    updateValue(value) {
      let results = this.selectedTab;
      if (results.includes(value)) {
        results = results.filter(v => v !== value);
      } else {
        results = [...results, value];
      }
      return results.join(',');
    },
    handleCheck(value) {
      const results = this.updateValue(value);
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          remotes: results ? results : undefined,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-companies-remote {
  &__radios {
    display: flex;
    gap: 15px;
    margin-top: 5px;
  }
}
</style>
