export default {
  methods: {
    /**
     * A method to set the scroll to the active option in dropdowns.
     * @param index
     */
    setScroll(index, selector) {
      const list = this.$refs.dropdown;
      const element = list.querySelectorAll(selector)[index];

      if (!element) {
        return;
      }

      const visMin = list.scrollTop;
      const visMax = list.scrollTop + list.clientHeight - element.clientHeight;

      if (element.offsetTop < visMin) {
        list.scrollTop = element.offsetTop;
      } else if (element.offsetTop >= visMax) {
        list.scrollTop = (element.offsetTop - list.clientHeight + element.clientHeight);
      }
    }
  }
}
