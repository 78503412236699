<template>
  <topbar-container
    class="topbar-config--active topbar-config is-unshrink"
    @mouseenter.native="isHover = true"
    @mouseleave.native="isHover = false">
    <topbar-config-item
      :isHover="isActive"
      :label="$t('activities.team-cap')"
      :tabs-path="['/configuration/team']"
      to="/configuration/team"
    ></topbar-config-item>
    <topbar-config-item
      :isHover="isActive"
      :label="$t('topbar.my-company')"
      :tabs-path="Object.values(routeTabs.configurationMyCompany)"
      to="/configuration/my-company"
    ></topbar-config-item>
    <topbar-config-item
      :isHover="isActive"
      :label="$t('global.email')"
      :tabs-path="Object.values(routeTabs.configurationAccount)"
      to="/configuration/smtp-imap"
    ></topbar-config-item>
    <topbar-config-item
      :isHover="isActive"
      :label="$t('generics.custom-columns')"
      :tabs-path="Object.values(routeTabs.configurationCustomColumns)"
      to="/configuration/process"
    ></topbar-config-item>
    <topbar-config-item
      :isHover="isActive"
      :label="$t('generics.multicast')"
      :tabs-path="Object.values(routeTabs.configurationMulticast)"
      to="/configuration/career-website"
    ></topbar-config-item>
    <topbar-config-item
      :isHover="isActive"
      :label="$t('generics.templates')"
      :tabs-path="Object.values(routeTabs.configurationTemplates)"
      to="/configuration/templates/email"
    ></topbar-config-item>
    <topbar-config-item
      :isHover="isActive"
      :label="$t('generics.integration')"
      :tabs-path="Object.values(routeTabs.configurationIntegration)"
      to="/configuration/kaspr"
    ></topbar-config-item>
    <topbar-config-item
      :isHover="isActive"
      :label="$t('generics.preferences')"
      :tabs-path="Object.values(routeTabs.configurationPreferences)"
      to="/configuration/share-processes"
    ></topbar-config-item>
    <topbar-config-item
      :isHover="isActive"
      :label="$t('topbar.language')"
      :tabs-path="['/configuration/language']"
      to="/configuration/language"
    ></topbar-config-item>
  </topbar-container>
</template>

<script>
import TopbarContainer from '@/components/Topbar/TopbarContainer';
import TopbarConfigItem from '@/components/Topbar/TopbarConfig/TopbarConfigItem';
import { routeTabs } from '@/router';

export default {
  name: 'topbar-config',
  components: {
    TopbarContainer,
    TopbarConfigItem,
  },
  data() {
    return {
      isHover: false,
      windowWidth: 0,
      routeTabs,
    }
  },
  computed: {
    isTopbarLocked() {
      return this.windowWidth >= 800;
    },
    isActive() {
      return this.isHover || this.isTopbarLocked;
    },
  },
  created() {
    this.setWindowWidth();

    window.addEventListener('resize', this.setWindowWidth);
    this.$on('hook:destroyed', () => {
      window.removeEventListener('resize', this.setWindowWidth);
    });
  },
  methods: {
    setWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar-config {
  height: 100%;
  max-width: 85px;
  width: 100%;
  overflow: auto;
  background: $color-neutre-4;
  border: 1px solid $color-blue-medium;
  border-radius: 10px;
  padding: 0;
  padding-top: 26px;
  position: relative;


  @media (min-width: 1600px) {
    max-width: 219px;
  }

  &--active {
    max-width: 219px;
  }

  &__logo {
    overflow: hidden;
  }

  &__img {
    height: 35px;
  }

  &__help {
    margin-top: auto;
    bottom: 85px;
    right: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 2.3em;
    font-weight: bold;

    &:focus,
    &:visited {
      color: $color-secondary;
    }

    &:hover {
      background: $color-secondary;
      color: $color-white;
      cursor: pointer;
    }

    @include bp('tablet') {
      display: flex;
    }
  }
}
</style>
