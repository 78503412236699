export const getStepParams = (step, { fixed, variable, amount, dealtAt, startsAt, content, rating }) => {
  const paramsByStep = {
    offered: { fixed, variable },
    dealt: { fixed, variable, amount, dealtAt, startsAt },
    onboarding: { startsAt, content },
    validated: { content, rating },
  };

  return paramsByStep[step];
};

export default {
  getStepParams,
};
