import notesMacros, { hasQualificationsWithRating, resourceType } from '@/common-old/macros/notes';
import {
  getMediaNameFromApiValue,
  getTypeNameFromApiValue,
} from '@/managers/notes/formatters/noteDeserializers';
import moment from 'moment/min/moment-with-locales';

export default {
  data() {
    return {
      rating: this.action?._note?.rating || undefined,
      urgency: this.action?._note?.urgency || undefined,
      type: this.action?._note?.type ? getTypeNameFromApiValue(this.action._note) : '',
      media: this.action?._note?.media?.length > 0 ? getMediaNameFromApiValue(this.action._note) : [],
      macros: {
        types: notesMacros.type,
        media: notesMacros.media,
      },
      input: '',
      skills: this.qualification?._tags || this.action?._note?._tags || [],
      salary: this.qualification?.salary || this.action?._note?.salary || 0,
      salaryWanted: this.qualification?.salaryWanted || this.action?._note?.salaryWanted || 0,
      dateToContact: this.qualification?.dateToContact || this.action?._note?.dateToContact || '',
      openToWork: this.qualification?.openToWork || this.action?._note?.openToWork || false,
    };
  },
  computed: {
    dateToContactCoder() {
      return [
        {
          content: this.$i18n.t('companies.panel-view-timeline.none'),
          date: this.$i18n.t('companies.panel-view-timeline.none-min'),
        },
        {
          content: `${this.$i18n.t('hunt.in-two-days')} (${moment().add(2, 'days').format('L')})`,
          date: moment().add(2, 'days').toDate(),
        },
        {
          content: `${this.$i18n.t('hunt.in-one-week')} (${moment().add(1, 'weeks').format('L')})`,
          date: moment().add(1, 'weeks').toDate(),
        },
        {
          content: `${this.$i18n.t('hunt.in-three-months')} (${moment().add(3, 'months').format('L')})`,
          date: moment().add(3, 'months').toDate(),
        },
        {
          content: `${this.$i18n.t('hunt.in-six-months')} (${moment().add(6, 'months').format('L')})`,
          date: moment().add(6, 'months').toDate(),
        },
        {
          content: `${this.$i18n.t('hunt.in-nine-months')} (${moment().add(9, 'months').format('L')})`,
          date: moment().add(9, 'months').toDate(),
        },
      ];
    },
    isQualificationWithRating() {
      return hasQualificationsWithRating.includes(this.$route.query.type);
    },
    isValidQualificationWithRating() {
      return (this.type === notesMacros.type.qualification.name || this.type === '') && this.rating >= 0 && this.isQualificationWithRating;
    },
    isValidQualificationWithUrgency() {
      return this.type === notesMacros.type.qualification.name && this.urgency >= 0 && this.isQualificationWithRating;
    },
    isValidQualificationWithoutRating() {
      return !this.isQualificationWithRating && this.type === notesMacros.type.qualification.name && !!this.content.replace(/<[^>]*>?/gm, '');
    },
    isValidQualification() {
      return this.isValidQualificationWithRating || this.isValidQualificationWithoutRating || this.isValidQualificationWithUrgency;
    },
    isValidMessage() {
      return this.type === notesMacros.type.message.name;
    },
    isValidType() {
      return !!this.type;
    },
    isValid() {
      if (this.content.replace(/<[^>]*>?/gm, '').length) {
        return true;
      }
      return false;
    },
    resourceToQualify() {
      return resourceType[this.$route.query.type];
    },
    tooltipOptions() {
      return [
        // {
        //   error: !this.isValidType,
        //   text: this.$i18n.t('notes.please-select-a-note-type'),
        // },
        {
          error: this.type === notesMacros.type.message.name && !this.isValidMessage,
          text: this.$i18n.t('notes.please-select-media'),
        },
        {
          error: this.type === notesMacros.type.qualification.name && !this.isValidQualification,
          text: `${this.$i18n.t('notes.please-rate-the')} ${this.resourceToQualify}`,
        },
        {
          error: this.type === notesMacros.type.other.name && !this.isValidOther,
          text: this.$i18n.t('notes.please-add-content-to-the-note'),
        },
      ];
    },
    tooltipContent() {
      if (this.isNewNote) {
        return '';
      }

      return ((this.tooltipOptions || []).find(option => option.error) || {}).text || '';
    },
    linkedin: {
      get() {
        return this.getMediaValue('linkedin');
      },
      set(value) {
        this.setMediaValue('linkedin', value);
      },
    },
    sms: {
      get() {
        return this.getMediaValue('sms');
      },
      set(value) {
        this.setMediaValue('sms', value);
      },
    },
    call: {
      get() {
        return this.getMediaValue('call');
      },
      set(value) {
        this.setMediaValue('call', value);
      },
    },
    email: {
      get() {
        return this.getMediaValue('email');
      },
      set(value) {
        this.setMediaValue('email', value);
      },
    },
  },
  methods: {
    setOpenToWork() {
      this.openToWork = !this.openToWork;
    },
    setDateToContact(option) {
      this.dateToContact = option.date;
    },
    getMediaValue(key) {
      return this.media.some(medium => medium === notesMacros.media[key].name);
    },
    setMediaValue(key, value) {
      if (value) {
        this.media.push(notesMacros.media[key].name);
      } else {
        this.media = this.media.filter(medium => medium !== notesMacros.media[key].name);
      }
    },
    setRating(value) {
      this.rating = value;
    },
    setUrgency(value) {
      this.urgency = value;
    },
  },
};
