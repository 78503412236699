<template>
  <div class="searchbar-autocomplete-coders">
    <div class="searchbar-autocomplete-coders__bar">
      <div class="searchbar-autocomplete-coders__wrapper">
        <mv-searchbar
          v-model="searchbarInput"
          :title="$tc('generics.candidate-cap', 2)"
          :is-loading="isLoading"
          :suggestions="suggestions"
          :placeholder="$t('generics.search-candidate')"
          @select="triggerAutocompleteEvent"
        >
          <template #results="{ suggestion, index }">
            <searchbar-coders-item
              :suggestion="suggestion"
              :search="searchbarInput"
              :show-label="index === 0"
            ></searchbar-coders-item>
          </template>
        </mv-searchbar>
      </div>
    </div>
    <searchbar-coders-filters></searchbar-coders-filters>
    <!-- <keep-alive>
      <searchbar-tags
        v-if="!isActiveFilter"
        class="searchbar-autocomplete-coders__tag-container"
        @on-reset="emitOnReset"
        @on-delete="emitOnDelete">
      </searchbar-tags>
    </keep-alive> -->
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import debounce from 'debounce';
  import codersApi from '@/api/coders';
  // import { getProfessionsAutocomplete } from '@/api/professions';
  // import { getTagsAutocomplete } from '@/api/tags';

  import AutocompleteMixin from '@/common-old/mixins/Autocomplete/autocomplete';
  import TechnosMixin from '@/mixins/Technos';
  import SearchbarAutocompleteEventsMixin
    from '@/mixins/SearchbarAutocomplete/searchbarAutocompleteEvents';
  // import SearchbarTags from '@/components/Searchbar/SearchbarTags';
  import SearchbarCodersItem from '@/components/Searchbar/SearchbarCoders/SearchbarCodersItem';
  import MvSearchbar from '@/ui-kit/components/MvSearchbar/MvSearchbar';
  import SearchbarCodersFilters from './SearchbarCodersFilters.vue';


  export default {
    name: 'searchbar-autocomplete-coders',
    components: {
    // SearchbarTags,
    SearchbarCodersItem,
    MvSearchbar,
    SearchbarCodersFilters,
  },
    mixins: [AutocompleteMixin, TechnosMixin, SearchbarAutocompleteEventsMixin],
    data() {
      return {
        isLoading: false,
        isFocusedSearchbar: false,
        searchbarInput: '',
        matchesLimit: 5,
        suggestions: [],
        lastIndexesOfCategories: [],
      };
    },
    computed: {
      ...mapState({
        inputValue: state => state.autocomplete.inputValue,
        isActiveFilter: state => state.searchbar.isActiveFilter,
        searchbarTags: state => state.searchbar.searchbarFiltersTags,
      }),
      suggestionSelected() {
        if (typeof this.pointer === 'number') {
          return this.suggestions[this.pointer || 0];
        }
        return this.pointer;
      },
    },
    methods: {
      /***
       *
       * Clear the input field
       */
      clearInput() {
        this.searchbarInput = '';
      },
      emitOnDelete() {
        this.$emit('on-delete');
      },
      emitOnReset() {
        this.$emit('on-reset');
      },
      /**
       *
       * Delete inside the searchbarTags array, the last tag
       */
      deleteInputQuery() {
        if (this.searchbarInput.length === 0 && this.searchbarTags.length > 0) {
          this.$store.dispatch('popSearchbarFiltersTags');

          this.$emit('on-delete');
        }
      },
      updateLastIndexesOfCategories() {
        this.lastIndexesOfCategories = [];

        for (let i = 0; i < this.suggestions.length - 1; i++) {
          if (this.suggestions[i].type !== this.suggestions[i + 1].type) {
            this.lastIndexesOfCategories.push(i + 1);
          }
        }
      },
      getHistory() {
        const searchbarHistory = JSON.parse(localStorage.getItem('searchbarHistory') || '[]');
        const listId = this.$route.query['list-id'];

        this.suggestions = listId ? [...searchbarHistory].filter(coder => coder._lists?.includes?.(listId)) : [...searchbarHistory];
      },

      addToHistory(tag) {
        const searchbarHistory = JSON.parse(localStorage.getItem('searchbarHistory') || '[]');
        const indexInHistory = searchbarHistory.findIndex(coder => coder._id === tag._id);
        if (indexInHistory >= 0) {
          searchbarHistory.splice(indexInHistory, 1);
        }
        searchbarHistory.unshift(tag);
        localStorage.setItem('searchbarHistory', JSON.stringify(searchbarHistory.slice(0, 10)));
      },
      async getCodersSuggestions() {
        this.isLoading = true;
        const codersResponse = await codersApi.codersAutocomplete(this.searchbarInput, this.$route.query['list-id']);
        // const professionsPromise = getProfessionsAutocomplete({ name: this.searchbarInput });
        // const skillsPromise = getTagsAutocomplete({ name: this.searchbarInput });
        // const [codersResponse, professionsResponse, skillsResponse] = await Promise.all([codersPromise, professionsPromise, skillsPromise])

        // if (!this.isFocusedSearchbar) {
        //   this.openSuggestionsList();
        // }

        this.suggestions = codersResponse.data.map(coder => ({
          type: 'coder',
          _id: coder._id,
          name: `${coder.firstName} ${coder.lastName}`,
          info: coder.title,
          picture: coder.pictureUrl || '@/assets/img/default-avatar.svg',
          _lists: coder._lists,
        }));

        // const professionSuggestions = professionsResponse.data.map(profession => ({
        //   type: 'profession',
        //   icon: 'award',
        //   ...profession,
        // }))

        // const skillsSuggestions = skillsResponse.data.map(skill => ({
        //   type: 'techno',
        //   icon: 'tool',
        //   ...skill,
        // }))

        // this.suggestions = [...this.suggestions, ...professionSuggestions, ...skillsSuggestions];

        // this.suggestions.push({
        //   name: this.searchbarInput,
        //   type: 'input',
        // });

        this.isLoading = false;
      },
      openSuggestionsList() {
        this.isFocusedSearchbar = true;
      },
      closeSuggestionsList() {
        this.isFocusedSearchbar = false;
      },
    },
    watch: {
      isFocusedSearchbar(newVal) {
        if (newVal && !this.searchbarInput) {
          this.getHistory();
        }
      },
      /***
       * Watch the input value to set the autocomplete input value
       */
      searchbarInput: debounce(async function(newVal) {
        this.$store.dispatch('setAutocompleteInputValue', this.searchbarInput);

        if (newVal) {
          await this.getCodersSuggestions();
        } else {
          this.getHistory();
        }

        this.updateLastIndexesOfCategories();
      }, 400),
    },
  };
</script>

<style lang="scss" scoped>
  .searchbar-autocomplete-coders {
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 20px;

    &__bar {
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      min-height: 50px;
      position: relative;
      @include bp('tablet') {
        flex-direction: row;
      }
    }

    &__wrapper {
      position: relative;
    }

    &__icon {
      font-size: $font-size-xxl;
      color: rgba($color-blue-san-juan, 0.4);
      position: absolute;
      left: 15px;
      transition: 0.3s;

      &--active {
        transform: rotate(90deg);
      }
    }

    &__button {
      margin-top: 10px;
      @include bp('tablet') {
        margin-top: 0;
        margin-left: 10px;
      }
    }

    &__autocomplete-list {
      position: absolute;
      width: 100%;
      z-index: 3;
    }

    &__input {
      @include bp('tablet') {
        min-width: 260px;
        margin-left: -30px;
      }
    }

    &__tag-container {
      margin-top: 10px;
      flex-wrap: wrap;
      margin-bottom: -10px;
    }

    &__tag {
      margin-right: 10px;
      max-height: 30px;
      margin-bottom: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
</style>
