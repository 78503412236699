<template>
  <div class="panel-chat-messages-job-mobile">
    <div class="panel-chat-messages-job-mobile__caption">
      <picture-avatar
        :img-src="logoUrl"
        width="50px"
        height="50px">
      </picture-avatar>
    </div>
    <div class="panel-chat-messages-job-mobile__body">
      <title-resource
        :text-left="companyName"
        :text-right="jobName"
        class="panel-chat-messages-job-mobile__text">
        <div
          class="icon-home"
          slot="icon"
          v-if="remote">
        </div>
      </title-resource>
      <p class="panel-chat-messages-job-mobile__link">
        {{ $t('companies.panel-hunt-process.see-the-job') }}
      </p>
    </div>
  </div>
</template>

<script>
  import PictureAvatar from '@/components/UIKit/Picture/PictureAvatar';
  import TitleResource from '@/components/Title/TitleResource';

  export default {
    name: 'panel-chat-messages-job-mobile',
    components: { TitleResource, PictureAvatar },
    props: {
      logoUrl: {
        type: String,
      },
      companyName: {
        type: String,
      },
      jobName: {
        type: String,
      },
      remote: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style scoped lang=scss>
  .panel-chat-messages-job-mobile {
    width: 100%;
    height: 100%;
    border: 1px solid #EEEEEE;
    align-items: center;
    border-radius: 5px;
    &__caption {
      position: relative;
      justify-content: space-between;
      align-items: center;
      transition: 0.3s;
      height: 100%;
      padding: 20px;
      width: 70px;
      margin-right: 10px;
      &:before {
        position: absolute;
        background-color: $color-blue-hawkes;
        content: "";
        width: 100%;
        height: 100%;
        clip-path: polygon(0 0, 94% 0, 70% 100%, 0% 100%);
        top: 0;
        left: 0;
        border-radius: 4px 0 0 0;
      }
    }
    &__body {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
    &__link {
      font-size: $font-size-s;
      text-align: left;
      color: $color-blue-dodger;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
