<template>
  <statistics-item
    class="statistics-companies"
    :title="$t('generics.customer-portfolio')"
    :steps="steps"
    :is-loading="isLoading">
  </statistics-item>
</template>

<script>
import {
  getDashboardNewNotesSigned,
  getDashboardNewNotesUnsigned,
  getDashboardNewSigned,
} from '@/api/dashboard';

import StatisticsItem from './StatisticsItem';

export default {
  name: 'statistics-companies',
  components: {
    StatisticsItem,
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      isLoading: false,
      steps: [
        {
          name: this.$t('statistics.notes-unsigned'),
          icon: 'companies-plus',
          value: 0,
          total: 0,
          topPerformers: [],
        },
        {
          name: this.$t('statistics.notes-signed'),
          icon: 'headphones',
          value: 0,
          total: 0,
          topPerformers: [],
        },
        {
          name: this.$t('statistics.signed'),
          icon: 'handshake',
          value: 0,
          total: 0,
          topPerformers: [],
        },
      ],
    };
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      await Promise.all([
        this.getKPIs(0, 'notesUnsigned'),
        this.getKPIs(1, 'notesSigned'),
        this.getKPIs(2, 'newSigned'),
      ]);
      this.isLoading = false;
    },
    async getKPIs(index, name) {
      const handlers = {
        notesSigned: getDashboardNewNotesSigned,
        notesUnsigned: getDashboardNewNotesUnsigned,
        newSigned: getDashboardNewSigned,
      };

      const { data } = await handlers[name]({
        start: this.$route.query.activityStart,
        end: this.$route.query.activityEnd,
        coachId: this.$route.query.activityCoachId,
      });

      this.steps[index].value = data.value;
      this.steps[index].total = data.total;
      this.steps[index].topPerformers = data.topPerformers;
    },
  },
  watch: {
    '$route.query.activityCoachId'() {
      this.fetchData();
    },
    '$route.query.activityStart'() {
      this.fetchData();
    },
    '$route.query.activityEnd'() {
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-companies {
}
</style>
