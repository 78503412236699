<script lang="ts" setup>
  import { Candidate } from '@/domains/models/Candidate';
import { usePanelNavigation } from '@/composables/usePanelNavigation';
import { useRouter } from 'vue-router/composables';
import { defineProps } from 'vue';
import { setAlternativeImg } from '@/mixins/imgFallback';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';

const router = useRouter();
const { closePanel } = usePanelNavigation();
  defineProps<{
    candidate?: Candidate;
  }>();
</script>

<template>
  <div
    class="flex h-[50px] w-full items-center justify-between gap-4 border-b border-b-[#EDF0FF] bg-white px-[18px] py-2">
    <div class="flex items-center gap-4">
      <button
        class="icon-cross text-sm text-blue-400 hover:text-blue-500 p-1.5"
        @click="closePanel"
      >
      </button>
      <button class="flex items-center gap-2.5 text-blue-800 hover:text-blue-500" @click="router.go(-1)">
        <div
          class="icon-chevron-left size-[32px] items-center justify-center rounded-md border border-blue-200 p-1 text-xs "
        >
        </div>
        <p class="flex items-center text-sm leading-[16.21px]">{{ $t('companies.account-management.back-to-profile') }}
          {{ $t('generics.of') }} {{
            candidate?.firstName
          }} {{ candidate?.lastName }}</p>
      </button>
    </div>
    <div class="flex h-full items-center gap-2.5 rounded-[5px] border border-blue-200 px-2.5 opacity-50">
      <i class="icon-equalizer text-xs text-blue-800"/>
      <div v-if="candidate?.firstName" class="flex items-center gap-[5px]">
        <BcAvatar
          :alt="`${candidate.firstName} ${candidate.lastName}`"
          :src="candidate.pictureUrl"
          class="size-[20px] shrink-0"
          @error="setAlternativeImg({event: $event, type: 'coders'})"
        />
        <p class="text-blue-800">{{ candidate.firstName }} {{ candidate.lastName }}</p>
      </div>
    </div>
  </div>
</template>
