<template>
  <div
    v-click-outside="closePanel"
    class="panel-tag-body">
    <div class="panel-tag-body__container">
      <div class="panel-tag-body__wrapper">
        <p class="panel-tag-body__text">
          {{ tag.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import PanelNavigation from '@/common-old/mixins/Panel/panelNavigation';

  export default {
    name: 'panel-tag-body',
    components: {},
    mixins: [PanelNavigation],
    props: {
      tag: {
        type: Object,
        default: () => ({}),
      },
    },
    methods: {
    },
  };
</script>

<style lang="scss" scoped>
  .panel-tag-body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    flex-shrink: 0;
    flex-grow: 1;
    padding: 50px;
    @include bp('tablet') {
      flex-direction: row;
      justify-content: flex-start;
      height: 110px;
      max-height: 110px;
      padding: 50px;
    }

    &__container {
      align-items: start;
    }

    &__text {
      line-height: 1.5em;
      color: $color-secondary;
      font-size: $font-size-l;
      font-weight: $font-weight-medium;
    }
  }
</style>
