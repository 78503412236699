<script lang="ts" setup>
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { usePanelNavigation } from '@/composables/usePanelNavigation';
  import { useStore } from '@/store';
  import { useRoute, useRouter } from 'vue-router/composables';
  import BcModal from '@/ui-kit/components/BcModal/BcModal.vue';
  import type { Component } from 'vue';
  import { computed, defineProps, ref, withDefaults } from 'vue';
  import PanelModalMergeProfile from '@/components/Panel/PanelModal/PanelModalMergeProfile.vue';
  import PanelCompanyHeaderProspectionStatusRpo
    from '@/components/Panel/PanelCompany/PanelCompanyHeader/PanelCompanyHeaderProspectionStatus/PanelCompanyHeaderProspectionStatusRpo.vue';
  import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
  import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
  import { useI18n } from '@/i18n/i18n';
  import { getFilterableBusinessCoach } from '@/macros/prospectionPipe/dropdowns';
  import { setPartnerAccountManager } from '@/api/company';
  import { useToast } from '@/ui-kit/components/BcToast';
  import PanelHeaderLinkGenerator from '@/components/Panel/PanelHeaderLinkGenerator.vue';
  import PanelCompanyModalDelete
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalDelete.vue';
  import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav.vue';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
  import { setAlternativeImg } from '@/mixins/imgFallback';
  import { usePanelStore } from '@/store/pinia/panel';
  import PanelCompanyModalMerge
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalMerge.vue';
  import { Company } from '@/domains/models/Company';

  const store = useStore();
  const route = useRoute();
  const { closePanel } = usePanelNavigation();
  const router = useRouter();
  const i18n = useI18n();
  const toast = useToast();
  const panelStore = usePanelStore();

  const props = withDefaults(defineProps<{
    profile?: any;
    isCreating?: boolean;
  }>(), {
    isCreating: false,
  });
  const emits = defineEmits({
    'profile-updated': (profile: any) => profile,
  });

  const openFavoriteListsPanel = () => {
    router.push({
      name: route.name,
      params: {
        id: props.profile._id,
      },
      query: {
        ...route.query,
        type: 'favorite-company-list',
        subtype: 'add-resources',
      },
    });
  };

  // modal
  const modalsMap: {
    component: Component,
    key: string
  }[] = [
    {
      component: PanelCompanyModalDelete,
      key: 'delete',
    },
    {
      component: PanelModalMergeProfile,
      key: 'merge',
    },
  ];
  const modalType = ref('');
  const setModalType = (type: string) => {
    modalType.value = type;
  };
  const closeModal = () => {
    modalType.value = '';
  };
  const modal = computed(() => {
    return modalsMap.find(modal => modal.key === modalType.value)?.component || null;
  });
  const isModalOpen = computed(() => !!modal.value);

  const selectedAccountManager = computed(() => {
    return props.profile?._owner?._id ? `${props.profile?._owner?.firstName} ${props.profile?._owner?.lastName}` : i18n.t('generics.none');
  });

  const coachesOptions = computed(() => {
    const coaches = store.state.coaches?.coaches?.filter(coach => getFilterableBusinessCoach(coach));

    return [...coaches.sort((a, b) => a.firstName.localeCompare(b.firstName))];
  });

  const getOwnerInitials = computed<string>(() => `${props.profile?._owner?.firstName?.charAt(0) ?? ''}${props.profile?._owner?.lastName?.charAt(0) ?? ''}`);
  const setAccountManager = async(option) => {
    try {
      const { data } = await setPartnerAccountManager(props.profile._id, option._id);

      await store.dispatch('updateValue', {
        storeName: 'panel',
        stateName: 'profile',
        data: {
          ...data,
          _owner: data._owner,
        },
      });

      await store.dispatch('updateCardFromId', {
        id: props.profile._id,
        content: {
          ...data,
          _owner: data._owner,
        },
      });

      emits('profile-updated', data);
    } catch (error) {
      toast.show({
        title: 'Erreur lors de la modification de l\'owner',
        message: i18n.t('toast.error-occured'),
        type: 'error',
        icon: 'cross',
      });
    }
  };

  const confirmDeleteCompany = () => {
    store.dispatch('deletePartner', route.params.id);

    closePanel();
  };

  const goMergeProfile = () => {
    panelStore.openModal<{
      company: Company
    }>(PanelCompanyModalMerge, {
      company: props.profile,
    });
  };
</script>

<template>
  <div
    class="panel-company-header flex min-h-[50px] w-full items-center justify-between border-b border-b-[#EDF0FF] bg-neutral-200 px-[18px] py-2">
    <div class="flex items-center gap-5">
      <PanelHeaderNav/>
      <bc-dropdown
        v-if="!isCreating"
        :close-dropdown-at-select="true"
        :options="coachesOptions"
      >
        <template #label="{ isDropdownOpen }">
          <button
            :is-arrow="true"
            :is-dropdown-open="isDropdownOpen"
            class="h-[32px] min-w-[183px] items-center rounded-full border border-blue-200 bg-white !px-[10px] text-sm text-blue-800"
          >
            <bc-avatar
              :alt="selectedAccountManager"
              :placeholder="getOwnerInitials"
              :src="profile?._owner?.pictureUrl"
              class="mr-1.5 size-5"
              size="xs"
              @error="setAlternativeImg({event: $event})"
            />
            <p>{{ selectedAccountManager }}</p>
            <i
              :class="[isDropdownOpen ? 'icon-chevron-up' : 'icon-chevron-down']"
              class="ml-auto text-xs"/>
          </button>
        </template>
        <template #option="{ activeIndex, index, option }">
          <bc-dropdown-item
            :is-focus="activeIndex === index"
            :text="`${option.firstName} ${option.lastName}`"
            class="w-full"
            @click.native="setAccountManager(option)"
          >
          </bc-dropdown-item>
        </template>
      </bc-dropdown>
      <panel-header-link-generator :company="profile"></panel-header-link-generator>
    </div>
    <div
      v-if="!isCreating"
      class="flex h-[32px] items-center gap-2.5">
      <BcButton
        icon-left="copy"
        size="small"
        @click="goMergeProfile"
      >
        {{ $t('generics.merge') }}
      </BcButton>
      <BcButton
        icon-left="addtolist"
        size="small"
        @click="openFavoriteListsPanel"
      >
        {{ $t('companies.panel-company.add-to-a-list') }}
      </BcButton>
      <PanelCompanyHeaderProspectionStatusRpo
        :key="profile?.customStatus?._id"
        :profile="profile"
        class="panel-company-header-coach__status max-h-[32px]"
        @profile-updated="$emit('profile-updated', $event)">
      </PanelCompanyHeaderProspectionStatusRpo>
      <bc-button
        color="error"
        icon-left="trash"
        size="small"
        @click.native="setModalType('delete')">
        {{ $t('generics.delete') }}
      </bc-button>
    </div>
    <bc-modal
      :active="isModalOpen"
      class="panel-coder-header-coach__modal"
      @close="closeModal">
      <component
        :is="modal"
        :profile="profile"
        @close-modal="closeModal"
        @confirm-delete="confirmDeleteCompany"
        @profile-updated="$emit('profile-updated', $event)">
      </component>
    </bc-modal>
  </div>
</template>

<style lang="scss" scoped>
  .panel-company-header {
    &:deep() {
      .bc-dropdown-trigger__text {
        max-width: initial;
      }

      .mv-select__btn {
        @apply max-h-8;

        span {
          font-size: 14px;
        }
      }
    }
  }
</style>
