<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import { useI18n } from '@/i18n/i18n';
  import BcInput from '@/ui-kit/components/BcInput/BcInput.vue';
  import moment from 'moment/min/moment-with-locales';
  import BcButton from '@/ui-kit/components/BcButton';

  const i18n = useI18n();
  const locale = i18n.locale;
  const emits = defineEmits<{
    (e: 'cancel-action'): void,
    (e: 'confirm-action', value: null | { confirm: boolean }): void
  }>();
  const props = defineProps<{
    type: 'interview' | 'presentation' | 'integration'
  }>();

  const startDateValue = ref<string | null>(null);
  const startDateFormat = computed(() => {
    if (!startDateValue.value) return null;
    return moment(startDateValue.value).format('L');
  });
  const onUpdateStartDate = (date) => {
    startDateValue.value = moment(date).format().split('T')[0];
  };
  const onUpdateStartDateInput = (date) => {
    if (!date) {
      startDateValue.value = undefined;
    }
  };
  const handleCancel = () => {
    emits('confirm-action', { confirm: true, date: null });
  };
  const handleConfirm = () => {
    emits('confirm-action', { confirm: true, date: startDateValue.value });
  };

  const textMap = {
    'interview': i18n.t('process.next-interview-date'),
    'presentation': i18n.t('process.next-presentation-date'),
    'integration': i18n.t('process.integration-date'),
  }

  const text = textMap[props.type];
</script>

<template>
  <div
    class="rounded-2xl bg-white border border-blue-200 p-[50px] w-[540px] flex flex-col gap-[30px] relative"
    @click.prevent>
    <button
      class="absolute right-[40px] top-[40px] text-blue-400 hover:text-blue-500"
      @click="$emit('cancel-action')">
      <i class="icon-cross text-md"></i>
    </button>
    <div
      class="flex flex-col gap-2.5">
      <h2 class="font-bold text-blue-800 text-2xl">
        {{ text }}
      </h2>
      <v-date-picker
        v-model="startDateValue"
        :locale="locale"
        :popover="{ visibility: 'click', positionFixed: true }"
        class="flex basis-1/2-gap-3.5" @input="onUpdateStartDate"
        @click.prevent
      >
        <template
          #default="{togglePopover}"
          @click.prevent>
          <BcInput
            :label="text"
            :label-placeholder="false"
            :placeholder="text"
            :valid="!!startDateValue"
            :value="startDateFormat"
            class="custom-jobs-contract-dates__input w-full"
            icon="calendar"
            icon-left="calendar"
            name="contract-start-date"
            @focus="togglePopover"
            @click.prevent
            @input.prevent="onUpdateStartDateInput"
          />
        </template>
      </v-date-picker>
    </div>
    <div class="flex gap-5">
      <bc-button
        class="flex-1"
        type="outlined"
        @click.native.stop="handleCancel">
        {{ $t('process.no-date-for-now') }}
      </bc-button>
      <bc-button
        :disabled="!startDateValue"
        class="flex-1"
        color="success"
        @click.native.stop="handleConfirm"
      >
        {{ $t('generics.validate') }}
      </bc-button>
    </div>
  </div>
</template>
