<script lang="ts" setup>
  import { defineProps, ref } from 'vue';
  import { Company } from '@/domains/models/Company';
  import { Employee } from '@/domains/models/Employee';
  import linkedinImg from '@/assets/logo/linkedin.png';
  import { editEmployee } from '@/api/employees';

  import fallbackImg from '@/assets/img/default-avatar.svg';
  import BcAvatar from '@/ui-kit/components/BcAvatar';
  import Tag from '@/components/Tag/Tag';

  import { useRoute, useRouter } from 'vue-router/composables';
  import { parsePhoneNumberFromString } from 'libphonenumber-js';
  import ContactSelect from '@/components/UIKit/ContactSelect.vue';
  const START_SVG = require('@/assets/svg/star.svg');
  const START_SVG_PLAIN = require('@/assets/svg/star_plain.svg');

  interface Props {
    company: Company;
    employees: Employee[];
  }

  const props = defineProps<Props>();
  const emits = defineEmits<{
    (e: 'update-employees', value: Employee[]): void
  }>();

  const router = useRouter();
  const route = useRoute();
  const isStarHover = ref<string | undefined>(undefined);

  const setAlternativeImg = (event: any) => {
    event.target.src = fallbackImg;
  };
  const getContactUrl = (employee: any) => {
    return router.resolve({
      name: route.name,
      query: { ...route.query, type: 'coders' },
      params: { id: employee._coder?._id },
    })?.href;
  };

  const openContractUrl = (employee: any) => {
    if (isStarHover.value) {
      return {};
    }
    router.push(getContactUrl(employee));
  }

  const handleFavorite = async (employee: any) => {
    try {
      const employeesToEmit = [];

      const { data: updated } = await editEmployee({ id: employee._id, data: { favorite: !employee.favorite } });

      for (const employee of props.employees) {
        if (updated._id === employee._id) {
          employeesToEmit.push({ ...employee, favorite: updated.favorite });
          continue;
        }
        employeesToEmit.push(employee);
      }

      emits('update-employees', employeesToEmit);
    } catch (err) {
      console.log(err);
    }
  }
</script>

<template>
  <div class="relative flex w-full flex-row flex-wrap gap-2.5 text-sm">
    <div
      v-if="!employees?.length"
      class="flex w-full grow items-center rounded-md bg-neutral-200 p-2.5 text-blue-800">
      <p>
        {{ $t('global.no-employees') }}
      </p>
    </div>
    <div
      v-for="employee in employees"
      :key="employee._id"
      class="flex w-full grow flex-col rounded-md bg-neutral-200 p-4 hover:cursor-pointer hover:bg-blue-100"
      @click="openContractUrl(employee)">
      <div>
        <div class="flex w-full items-center">
          <div class="flex flex-col">
            <div class="flex flex-row items-center">
              <BcAvatar
                :src="employee._coder?.pictureUrl"
                class="size-[30px]"
                @error="setAlternativeImg"/>
              <div class="ml-2 flex flex-col text-blue-800">
                <p class="text-md font-semibold">{{ employee._coder?.firstName }}
                  {{ employee._coder?.lastName }}</p>
                <div class="flex items-center gap-2">
                  <p
                    v-if="employee._coder?.title"
                    v-tooltip.bottom="employee._coder?.title"
                    class="text-md max-w-[200px] truncate">{{ employee._coder?.title }}</p>
                  <a
                    :class="{ 'grayscale': !employee._coder?.social?.linkedin }"
                    :href="employee._coder?.social?.linkedin || '#'"
                    class="text-sm"
                    @click.stop
                    target="_blank">
                    <img
                      :src="linkedinImg"
                      alt="linkedin"
                      class="h-5"/>
                  </a>
                </div>
              </div>
            </div>
            <div class="mt-2 flex flex-wrap gap-2">
              <ContactSelect
                :key="employee._coder?.phone"
                :phone="employee._coder?.phone"
                :value="employee._coder?.phone"
                is-phone>
                <template #trigger="{setIsOpen, isOpen}">
                  <Tag
                    v-if="employee._coder?.phone"
                    :text="parsePhoneNumberFromString(employee._coder?.phone, 'FR')?.formatInternational() || employee._coder?.phone"
                    class="panel-company-employees-view__tag !bg-blue-100 !text-[#3D5476]"
                    type="phone"
                    @click.stop="setIsOpen(!isOpen)"/>
                </template>
              </ContactSelect>
              <ContactSelect
                :key="employee._coder?.email"
                :email="employee._coder?.email"
                :first-name="employee._coder?.firstName"
                :last-name="employee._coder?.lastName"
                :phone="employee._coder?.phone"
                :value="employee._coder?.email"
                is-email>
                <template #trigger="{setIsOpen, isOpen}">
                  <Tag
                    v-if="employee._coder?.email"
                    :text="employee._coder?.email"
                    class="panel-company-employees-view__tag !bg-blue-100 !text-[#3D5476]"
                    type="email"
                    @click.stop="setIsOpen(!isOpen)"/>
                </template>
              </ContactSelect>
              <ContactSelect
                v-for="(phone, phoneIndex) in employee._coder?.phones"
                :key="`${phone}-${Math.random()}`"
                :email="employee._coder?.email"
                :first-name="employee._coder?.firstName"
                :last-name="employee._coder?.lastName"
                :phone="employee._coder?.phone"
                :value="employee._coder?.phones[phoneIndex]"
                is-phone>
                <template #trigger="{setIsOpen, isOpen}">
                  <Tag
                    :text="phone"
                    class="panel-company-employees-view__tag !bg-blue-100 !text-[#3D5476]"
                    type="phone"
                    @click.stop="setIsOpen(!isOpen)"/>
                </template>
              </ContactSelect>
              <ContactSelect
                v-for="(email, index) in employee._coder?.emails"
                :key="`${email}-${Math.random()}`"
                :email="employee._coder?.email"
                :first-name="employee._coder?.firstName"
                :last-name="employee._coder?.lastName"
                :phone="employee._coder?.phone"
                :value="employee._coder?.emails[index]"
                is-email>
                <template #trigger="{setIsOpen, isOpen}">
                  <Tag
                    :text="email"
                    class="panel-company-employees-view__tag !bg-blue-100 !text-[#3D5476]"
                    type="email"
                    @click.stop="setIsOpen(!isOpen)"/>
                </template>
              </ContactSelect>
            </div>
          </div>
        </div>
        <div>
          <button @click="handleFavorite(employee)" @mouseenter="isStarHover = employee._id" @mouseleave="isStarHover = undefined">
            <img
              v-tooltip.bottom="{ content: employee.favorite ? 'Supprimer favori' : 'Ajouter favori' }"
              :src="employee.favorite ? START_SVG_PLAIN:  START_SVG"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .panel-company-employees-view__tag {
    &:hover {
      @apply !bg-blue-200 !text-blue-500;
    }
  }
</style>
