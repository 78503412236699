<template>
  <div class="views-topbar-create-opportunities">
    <views-topbar-create-trigger
      class="views-topbar-create-opportunities__trigger"
      icon="opportunities-plus"
      :text="$tc('generics.opportunity', 2)"
      @click.native="openCreateOpportunityPanel">
      <i v-if="isCreationLocked" class="icon-lock ml-2"></i>
    </views-topbar-create-trigger>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ViewsTopbarCreateTrigger from '@/components/Views/ViewsTopbar/ViewsTopbarCreate/ViewsTopbarCreateTrigger';
import { Modals } from '@/store/modules/app';

export default {
  name: 'views-topbar-create-opportunities',
  components: {
    ViewsTopbarCreateTrigger
  },
  computed: {
    ...mapGetters({
      configurationFeatures: 'user/configurationFeatures',
      configurationDb: 'user/configurationDb',
      ressourceCount: 'metadata/ressourceCount',
    }),
    isCreationLocked() {
      return !this.configurationFeatures.jobsLimitUnlocked && this.ressourceCount.customJob >= this.configurationDb.jobsLimit;
    },
    onboarding() {
      return this.$store.state.user.onboarding;
    },
    metadata() {
      return this.$store.state.user.metadata;
    },
  },
  methods: {
    ...mapActions('app', ['setModal', 'setParams']),
    checkLimit() {
      if (!this.isCreationLocked) {
        return false;
      }
      this.setParams({ feature: 'jobsLimitUnlocked' });
      this.setModal(Modals['modal-feature-db-limit']);
      return true;
    },
    openCreateOpportunityPanel() {
      const limitReached = this.checkLimit();
      if (limitReached) return;

      this.$router.push({
        ...this.$route,
        name: `${this.$route.name}Panel`,
        params: {
          id: 'new-custom-jobs',
        },
        query: {
          ...this.$route.query,
          type: 'create-custom-jobs',
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.views-topbar-create-opportunities {
  max-width: 100%;
  padding-left: 20px;
  padding-top: 8px;
  align-items: center;

  &__trigger {
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }

    &--disabled {
      pointer-events: none;
    }
  }

  .icon-lock {
    color: $color-warning-dark;
  }
}
</style>
