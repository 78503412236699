<script lang="ts" setup>
  import moment from 'moment/min/moment-with-locales';
  import { computed, defineProps } from 'vue';
  import { Document } from '@/domains/models/Document';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { formatBytes } from '@/helpers/strings';

  const PDF_LOGO = require('@/assets/logo/files/pdf.png');
  const DOC_LOGO = require('@/assets/logo/files/doc.png');
  const IMAGE_LOGO = require('@/assets/logo/files/image.png');
  const XML_LOGO = require('@/assets/logo/files/xml.png');

  const filesInfos: Record<string, { logo: string; class: string; }> = {
    pdf: {
      logo: PDF_LOGO,
      class: 'bg-[#F5DFE0]',
    },
    doc: {
      logo: DOC_LOGO,
      class: 'bg-[#D9DFFD]',
    },
    docx: {
      logo: DOC_LOGO,
      class: 'bg-[#D9DFFD]',
    },
    csv: {
      logo: XML_LOGO,
      class: 'bg-[#DDF4E9]',
    },
    xls: {
      logo: XML_LOGO,
      class: 'bg-[#DDF4E9]',
    },
    jpg: {
      logo: IMAGE_LOGO,
      class: 'bg-[#DDF4E9]',
    },
    jpeg: {
      logo: IMAGE_LOGO,
      class: 'bg-[#DDF4E9]',
    },
    png: {
      logo: IMAGE_LOGO,
      class: 'bg-[#DDF4E9]',
    }
  }

  const props = defineProps<{
    documents?: Document[];
  }>();

  const emits = defineEmits({
    'remove-document': (document: Document) => true,
    'open-delete-modal': () => true,
  });

  const documents = computed(() => {
    return props.documents?.map(doc => {
      const urlSplit = (doc.fileName ? doc.fileName : doc.url).split('.');
      const extension = urlSplit[urlSplit.length - 1];
      return {
        ...doc,
        extension,
      }
    }).sort((a, b) => new moment(b.createdAt).valueOf() - new moment(a.createdAt).valueOf()) || [];
  });

  const onDownload = (fileUrl, name) => {
    download(fileUrl, name);
  }

  const download = (url: string, name: string) => {
    const a = document.createElement('a')
    a.href = url
    a.download = name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const getFileInfo = (extension: string = '') => {
    return filesInfos[extension.toLowerCase()] || {};
  }

</script>

<template>
  <div class="flex items-center">
    <div v-if="documents.length" class="flex w-full flex-wrap items-center gap-4">
      <div
        v-for="(document, index) in documents" :key="document._id"
        class="h-[52px] w-[48%] min-w-[48%] items-center rounded-[5px] bg-neutral-200 px-4 py-3"
      >
      <div class="size-[30px] min-w-[30px] items-center justify-center rounded-md" :class="getFileInfo(document.extension).class">
        <img v-if="getFileInfo(document.extension).logo" class="size-[20px] object-contain" :src="getFileInfo(document.extension).logo" />
      </div>
      <div class="ml-2 w-full items-center justify-between">
        <div class="is-column">
          <p
            v-tooltip="{ content: document?.fileName?.length > 17 ? document.fileName : '' }"
            class="max-w-[120px] truncate text-xs font-medium"
          >
            {{ document.fileName ? document.fileName : `Document ${index + 1}` }}
          </p>
          <p v-if="document?.fileSize" class="text-xs font-normal text-blue-400">{{ formatBytes(document.fileSize) }}</p>
        </div>
        <div class="gap-1">
          <BcButton
            color="error"
            icon-left="trash"
            size="extra-small"
            @click.native="$emit('open-delete-modal', document)"
          />
          <BcButton
            icon-center="download"
            size="extra-small"
            @click.native="onDownload(document.url, document.fileName ? document.fileName : `Document ${index + 1}`)"
          />
          <a
            :href="document.url"
            download
            target="_blank">
            <BcButton
              icon-left="external-link"
              size="extra-small"/>
          </a>
        </div>
      </div>
      </div>
    </div>
    <p
      v-if="!documents.length"
      class="flex w-full grow items-center rounded-md bg-neutral-200 px-5 py-4 text-sm text-blue-800">
      {{ $t('hunt.no-document-for-the-moment') }}
    </p>
  </div>
</template>
