<template>
  <div :class="{ 'processes-card--warning': warningOnCard }" class="processes-card">
    <div class="processes-card__coach">
      <div
        v-if="(process.coaches || []).length > 1"
        v-tooltip="coachTooltip"
        class="processes-card__count"
      >
        +{{ (process.coaches || []).length }}
      </div>
      <bc-avatar
        v-else
        :src="(process.coaches[0] || {}).pictureUrl"
        :key="(process._coder || {}).firstName"
        :alt="(process._coder || {}).firstName"
        :placeholder="getCoachInitials()"
        v-tooltip="`${(process.coaches[0] || {}).firstName} ${(process.coaches[0] || {}).lastName}`"
        size="xs"
        bc-avatar-size="20px"
      >
      </bc-avatar>
    </div>
    <div class="is-align-items-center">
      <span
        v-if="isStatusDisplayed"
        :class="{
          'processes-card__status--caution':
            daysSinceLastActivityNumber >= 5 && daysSinceLastActivityNumber <= 10,
          'processes-card__status--warning': daysSinceLastActivityNumber >= 10,
          'processes-card__status--ok': daysSinceLastActivityNumber < 5
        }"
        class="processes-card__status border-radius-rounded mr-1"
      ></span>
      <p class="processes-card__name">
        {{ (process._coder || {}).firstName }} {{ (process._coder || {}).lastName }}
      </p>
    </div>
    <p v-if="isSuperUser" class="processes-card__text">
      {{ amount.toLocaleString() }} €
    </p>
    <div v-if="process.companies.length > 0" class="processes-card__container">
      <p class="processes-card__text processes-card__text--small">
        {{ $t('process.current-process') }} :&nbsp;
      </p>
      <div class="processes-card__wrapper">
        <bc-avatar-legacy
          v-for="(company, index) in process.companies"
          :key="`${company._id}-${index}`"
          v-tooltip="company.name"
          :bc-img-alt="company.name"
          :bc-img-src="getCompanyLogo(company)"
          bc-avatar-size="20px"
          class="processes-card__logo"
          @error="isImgFallback = true"
        >
        </bc-avatar-legacy>
      </div>
    </div>
    <div class="is-primary font-size-xs is-flex is-align-items-center">
      <p class="processes-card__date">
        {{ daysSinceLastActivityLabel }}
      </p>
      <span class="processes-card__divider"></span>
    </div>
  </div>
</template>

<script>
import BcAvatarLegacy from '@/legacy/ui-kit/components/Avatar/BcAvatar';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
import moment from 'moment/min/moment-with-locales';
import { apiStatus } from '@/macros/processes/process';
import { bluecodersCompanyId } from '@/macros/utils';

const isHiddenStatus = [apiStatus.dealt, apiStatus.onboarding, apiStatus.validated];

export default {
  name: 'processes-card',
  components: { BcAvatarLegacy, BcAvatar },
  props: {
    process: {
      type: Object,
      default: () => ({})
    },
    step: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isImgFallback: false
    };
  },

  computed: {
    isSuperUser() {
      return this.$store.getters['user/isSuperUser'];
    },
    isOrganizationException() {
        return this.$store.getters['user/isOrganizationException'];
      },isStatusDisplayed() {
      return (this.isSuperUser || this.isOrganizationException)&& !isHiddenStatus.includes(this.process.status);
    },
    name() {
      return `${(this.process._coder || {}).firstName} ${(this.process._coder || {}).lastName}`;
    },
    amount() {
      return this.process.potentialDeal * 1000;
    },
    coachTooltip() {
      return {
        content: (this.process.coaches || [])
          .map(coach => `${coach.firstName} ${coach.lastName}`)
          .join('\n'),
        classes: 'tooltip-pre'
      };
    },
    today() {
      return moment().startOf('day');
    },
    date() {
      if (this.process.status === apiStatus.dealt) {

        return this.process.formDeal?.sentAt
          ? moment(this.process.formDeal?.dealInfo?.acceptedAt).add(1, 'day')
          : moment(this.process.dealtAt).add(1, 'day');
      }
      return moment(this.process.updatedAt);
    },
    daysSinceLastActivityNumber() {
      return this.today.diff(this.date, 'days') + 1;
    },
    daysSinceLastActivityLabel() {
      return this.date.fromNow();
    },
    warningOnCard() {
      return (
        !(this.process.formDeal || {}).sentAt &&
        this.process.status === apiStatus.dealt &&
        this.step.name === this.$i18n.t('process.waiting-to-start') &&
        !this.process.failedAt &&
        this.process?._company?._id !== bluecodersCompanyId
      );
    }
  },
  methods: {
    getCompanyLogo(company) {
      if (this.isImgFallback || !(company || {}).logoUrl) {
        return require('@/assets/img/default-avatar-company.svg');
      }

      return company.logoUrl;
    },
    getCoachInitials() {
      return `${(this.process.coaches[0] || {}).firstName?.charAt(0)}${(
        this.process.coaches[0] || {}
      ).lastName?.charAt(0)}`.trim();
    }
  },

};
</script>

<style lang="scss" scoped>
.processes-card {
  border-radius: 10px;
  padding: 15px;
  background: $color-white;
  flex-direction: column;
  position: relative;
  min-width: 190px;

  &__name {
    font-weight: $font-weight-medium;
    font-size: $font-size-s;
    line-height: 17px;
    color: $color-blue-dodger;
    max-width: calc(100% - 20px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__text {
    display: flex;
    font-size: $font-size-s;
    color: $color-blue-dark-cello;
    line-height: 17px;
    align-items: center;

    &--small {
      font-size: $font-size-xs;
      margin-right: 5px;
    }
  }

  &__container {
    margin-top: 5px;
    flex-wrap: wrap;
  }

  &__wrapper {
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 0 -5px;
  }

  &__coach {
    position: absolute;
    right: 10px;
    top: 12.5px;
  }

  &__logo {
    border-radius: 100%;
    overflow: hidden;
    margin: 2.5px;
  }

  &__count {
    text-align: center;
    background: rgba($color-purple-medium, 0.2);
    font-size: $font-size-xxs;
    color: $color-blue-dark-cello;
    line-height: 12px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }

  &__tooltip {
    white-space: pre;
  }

  &__divider {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    width: 100%;
    align-items: center;
    margin-left: 10px;

    &:before {
      content: '';
      background: $color-primary;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }
  }

  &__date {
    flex-shrink: 0;
    line-height: 1.5em;
  }

  &--warning {
    border: 1px solid $color-warning;
  }

  &__status {
    margin-top: -1px;
    height: 10px;
    width: 10px;

    &--caution {
      background: $color-favorite;
    }

    &--warning {
      background: $color-error;
    }

    &--ok {
      background: $color-success;
    }
  }
}
</style>
