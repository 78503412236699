<template>
  <div class="processes-deal-info is-column">
    <p class="processes-deal-info__text">
      {{ $t('process.deal-info') }}:
    </p>
    <div class="processes-deal-info__body">
      <div class="processes-deal-info__body-content is-column">
        <ValidationProvider
          :rules="{ required: true }"
          :name="$t('process.add-date-deal')"
          v-slot="{ errors }">
          <date-picker
            :disabled="isSent"
            :error="errors[0]"
            :max-date="new Date()"
            :fieldRequired="true"
            class="processes-deal-info__date-picker"
            info-content="t"
            :label="$t('process.add-date-deal')"
            v-model="dealtAt">
            <div class="is-column">
              <p
                class="processes-deal-info__tooltip-text processes-deal-info__tooltip-text--bolded">
                {{ $t('process.date-deal-is-validated') }}
              </p>
              <p class="processes-deal-info__tooltip-text">
                {{ $t('process.date-on-which-the-candidate-accepts-the-offer-made-by-the-partner') }}
              </p>
              <p class="processes-deal-info__tooltip-text">
                {{ $t('process.date-on-which-the-candidate-will-work-with-the-partner') }}
              </p>
            </div>
          </date-picker>
        </ValidationProvider>
        <ValidationProvider
          :rules="{ required: true }"
          :name="$t('process.proposal-acceptance-date-official')"
          v-slot="{ errors }">
          <date-picker
            :disabled="isSent"
            :error="errors[0]"
            :fieldRequired="true"
            class="processes-deal-info__date-picker"
            :label="$t('process.proposal-acceptance-date-official')"
            v-model="acceptedAt">
            <div class="is-column">
              <p class="processes-deal-info__tooltip-text processes-deal-info__tooltip-text--bolded">
                {{ $t('process.date-deal-is-validated') }}
              </p>
              <p
                class="processes-deal-info__tooltip-text processes-deal-info__tooltip-text--bolded">
                {{ $t('process.date-on-which-the-candidate-accepts-the-offer-made-by-the-partner') }}
              </p>
              <p class="processes-deal-info__tooltip-text">
                {{ $t('process.date-on-which-the-candidate-will-work-with-the-partner') }}
              </p>
            </div>
          </date-picker>
        </ValidationProvider>
        <ValidationProvider
          :rules="{ required: true }"
          name="startsAt"
          v-slot="{ errors }">
          <date-picker
            :disabled="isSent"
            :error="errors[0]"
            :fieldRequired="true"
            :min-date="dealInfo.acceptedAt"
            class="processes-deal-info__date-picker"
            :label="$t('process.start-date-planned-final')"
            v-model="startsAt">
            <div class="is-column">
              <p class="processes-deal-info__tooltip-text">
                {{ $t('process.when-music-ringing') }}
              </p>
              <p class="processes-deal-info__tooltip-text">
                {{ $t('process.date-on-which-the-candidate-accepts-the-offer-made-by-the-partner') }}
              </p>
              <p
                class="processes-deal-info__tooltip-text processes-deal-info__tooltip-text--bolded">
                {{ $t('process.date-on-which-the-candidate-will-work-with-the-partner') }}
              </p>
            </div>
          </date-picker>
        </ValidationProvider>
        <ValidationProvider
          :rules="{ required: true }"
          :silent="true"
          :name="$t('process.partner')"
          v-slot="{ errors }">
          <bc-input
            :disabled="isSent"
            :error="errors[0]"
            :label-placeholder="false"
            :required="true"
            @blur="autoSaveEditedDealInfo('partnerName', $event)"
            class="processes-deal-info__input"
            :label="$t('process.partner')"
            name="Partenaire"
            placeholder="Bluecoders"
            v-model="partnerName">
          </bc-input>
        </ValidationProvider>
        <ValidationProvider
          :rules="{ required: true }"
          :name="$t('contacts.firstname')"
          v-slot="{ errors }">
          <bc-input
            :disabled="isSent"
            :error="errors[0]"
            :label-placeholder="false"
            :required="true"
            @blur="autoSaveEditedDealInfo('candidateFirstName', $event)"
            class="processes-deal-info__input"
            :label="$t('contacts.firstname')"
            name="Prénom"
            placeholder="Amaury"
            v-model="candidateFirstName">
          </bc-input>
        </ValidationProvider>
        <ValidationProvider
          :rules="{ required: true }"
          :name="$t('contacts.lastname')"
          v-slot="{ errors }">
          <bc-input
            :disabled="isSent"
            :error="errors[0]"
            :label-placeholder="false"
            :required="true"
            @blur="autoSaveEditedDealInfo('candidateLastName', $event)"
            class="processes-deal-info__input"
            :label="$t('contacts.lastname')"
            name="Nom"
            placeholder="BEHAGUE"
            v-model="candidateLastName">
          </bc-input>
        </ValidationProvider>
        <ValidationProvider
          :rules="{ required: true }"
          :name="$t('process.fixed-annual-salary')"
          v-slot="{ errors }">
          <bc-input-number
            :disabled="isSent"
            :error="errors[0]"
            :label-placeholder="false"
            :min="0"
            :required="true"
            :should-display-incrementer="false"
            :valid="true"
            @blur="autoSaveEditedDealInfo('fixed', $event)"
            class="processes-deal-info__input-number"
            data-fixed-salary
            :label="$t('process.fixed-annual-salary')"
            name="Salaire annuel fixe"
            placeholder="0"
            unity="k€"
            v-model="fixed">
          </bc-input-number>
        </ValidationProvider>
        <ValidationProvider
          :rules="{ required: true }"
          :name="$t('process.variable-part')"
          v-slot="{ errors }">
          <bc-input-number
            :disabled="isSent"
            :error="errors[0]"
            :label-placeholder="false"
            :min="0"
            :required="true"
            :should-display-incrementer="false"
            :valid="true"
            @blur="autoSaveEditedDealInfo('variable', $event)"
            class="processes-deal-info__input-number"
            data-variable-salary
            :label="$t('process.variable-part')"
            name="Part variable"
            placeholder="0"
            unity="k€"
            v-model="variable">
          </bc-input-number>
        </ValidationProvider>
        <div class="is-column">
          <p class="processes-deal-info__label">
            {{ $t('table-header.remote-upper') }}
            <span class="processes-deal-info__label--required">*</span>
          </p>
          <div class="is-row is-justify-content-start">
            <ValidationProvider
              :rules="{ required: true }"
              name="remote"
              v-slot="{ errors }">
              <bc-radio
                :disabled="isSent"
                :checked="dealInfo.hasOwnProperty('remote') && dealInfo.remote"
                :error="errors[0]"
                class="processes-deal-info__radio"
                data-remote
                id="remote"
                name="OuiRemote"
                v-model="remote"
                value="remote">
                {{ $t('generics.yes') }}
              </bc-radio>
            </ValidationProvider>
            <ValidationProvider
              :rules="{ required: true }"
              name="nonRemote"
              v-slot="{ errors }">
              <bc-radio
                :disabled="isSent"
                :checked="dealInfo.hasOwnProperty('remote') && !dealInfo.remote"
                :error="errors[0]"
                data-non-remote
                id="nonRemote"
                name="NonRemote"
                v-model="nonRemote"
                value="nonRemote">
                {{ $t('generics.no') }}
              </bc-radio>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  //
  import DatePicker from "@/components/DatePicker/DatePicker";
  import BcRadio from "@/ui-kit/components/BcRadio/BcRadio";
  import BcInput from "@/ui-kit/components/BcInput/BcInput";
  import BcInputNumber from "@/ui-kit/components/BcInputNumber/BcInputNumber";
  //
  import moment from 'moment/min/moment-with-locales';
  //
  import { ValidationProvider } from 'vee-validate';

//   const dict = {
//     custom: {
//       dealtAt: {
//         required: ('process.please-fill-in-the-deal-date'),
//       },
//       acceptedAt: {
//         required: ('process.please-fill-in-the-acceptance-date'),
//       },
//       startsAt: {
//        required: ('process.please-fill-in-the-start-date'),
//       },
//       partnerName: {
//         required: ('process.please-fill-in-the-name-of-the-partner'),
//       },
//       candidateFirstName: {
//         required: ('process.please-fill-in-the-first-name-of-the-candidate'),
//       },
//       candidateLastName: {
//        required:
// ('process.please-fill-in-the-name-of-the-partner'),
//       },
//       fixed: {
//         required: ('process.please-fill-in-the-fixed-annual-salary-of-the-candidate'),
//       },
//       variable: {
//         required:
// ('process.please-fill-in-the-variable-of-the-candidate'),
//       },
//       remote: {
//        required:
//  ('process.please-inform-if-the-remote-is-possible'),
//       },
//     },
//   };

  // Validator.localize('fr', dict);

  export default {
    name: 'processes-deal-info',
    components: {
      BcInputNumber,
      BcInput,
      BcRadio,
      DatePicker,
      ValidationProvider,
    },
    props: {
      dealInfo: {
        type: Object,
        default: () => ({
          candidateFirstName: null,
          candidateLastName: null,
          partnerName: null,
          fixed: 0,
          variable: 0,
          dealtAt: null,
          acceptedAt: null,
          startsAt: null,
          remote: null,
        }),
      },
      isSent: {
        type: Boolean,
        default: false,
      },
    },
computed: {
      dealtAt: {
        get() {
          return this.formatDate(this.dealInfo.dealtAt);
        },
        set(value) {
          this.updateDealInfo('dealtAt', value);
          this.autoSaveEditedDealInfo('dealtAt', value);
        },
      },
      acceptedAt: {
        get() {
          return this.formatDate(this.dealInfo.acceptedAt);
        },
        set(value) {
          this.updateDealInfo('acceptedAt', value);
          this.autoSaveEditedDealInfo('acceptedAt', value);
        },
      },
      startsAt: {
        get() {
          return this.formatDate(this.dealInfo.startsAt);
        },
        set(value) {
          this.updateDealInfo('startsAt', value);
          this.autoSaveEditedDealInfo('startsAt', value);
        },
      },
      partnerName: {
        get() {
          return this.dealInfo.partnerName;
        },
        set(value) {
          this.updateDealInfo('partnerName', value);
        },
      },
      candidateFirstName: {
        get() {
          return this.dealInfo.candidateFirstName;
        },
        set(value) {
          this.updateDealInfo('candidateFirstName', value);
        },
      },
      candidateLastName: {
        get() {
          return this.dealInfo.candidateLastName;
        },
        set(value) {
          this.updateDealInfo('candidateLastName', value);
        },
      },
      fixed: {
        get() {
          return this.dealInfo.fixed || 0;
        },
        set(value) {
          this.updateDealInfo('fixed', value);
        },
      },
      variable: {
        get() {
          return this.dealInfo.variable || 0;
        },
        set(value) {
          this.updateDealInfo('variable', value);
        },
      },
      remote: {
        get() {
          return this.dealInfo.hasOwnProperty('remote') ? this.dealInfo.remote : '';
        },
        set() {
          this.updateDealInfo('remote', true);
          this.autoSaveEditedDealInfo('remote', true);
        },
      },
      nonRemote: {
        get() {
          return this.dealInfo.hasOwnProperty('remote') ? this.dealInfo.remote : '';
        },
        set() {
          this.updateDealInfo('remote', false);
          this.autoSaveEditedDealInfo('remote', false);
        },
      },
    },
    methods: {
      updateDealInfo(field, value) {
        this.$emit('change-deal-info', { field, value });
      },
      autoSaveEditedDealInfo(field, value) {
        this.$emit('auto-save', { field, value });
      },
      formatDate(date) {
        if (date) {
          return moment(date).format('L');
        }
        return '';
      },
    },
  };
</script>

<style lang=scss scoped>
  .processes-deal-info {
    width: 100%;
    height: auto;

    @include bp('desktop') {
      width: 50%;
      height: 100%;
    }

    &__body {
      padding: 20px 0;
    }

    &__body-content {
      width: 100%;
      padding: 0 35px;

      @include bp('desktop') {
        border-right: 2px solid $color-grey-4;
      }
    }

    &__text {
      @include font-medium(20px);
      line-height: 24px;
      color: $color-primary;
      padding-left: 30px;
      background-color: $color-white;
    }

    &__date-picker,
    &__input {
      margin: 15px 0;
    }

    &__input {
      width: 360px;

      & :deep() {
        .bc-input {
          &__wrapper {
            background-color: $color-white;

            &--disabled {
              pointer-events: none;
              background-color: $color-grey-2;
              border-color: $color-grey-4;
              opacity: 0.5;
            }
          }
        }
      }

      &--small {
        width: 160px;
      }
    }

    &__input-number {
      margin-top: 15px;
      margin-bottom: 15px;

      & :deep() {
        .bc-input-number {
          &__input {
            width: 90px;
            height: 40px;
          }
        }
      }
    }

    &__date-picker {
      & :deep() {
        .date-picker__input {
          width: 360px;
        }

        .bc-input {
          margin-top: 0;

          &__wrapper {
            background-color: white;

            &--disabled {
              pointer-events: none;
              background-color: $color-grey-2;
              border-color: $color-grey-4;
              opacity: 0.5;
            }
          }
        }
      }
    }

    &__tooltip-text {
      @include font-regular(14px);
      color: $color-grey-2;
      line-height: 16px;
      max-width: 270px;
      margin-bottom: 10px;
      text-align: left;

      &--bolded {
        @include font-bold(14px);
        color: white;
      }
    }

    &__radio {
      width: 180px;
      justify-content: start;
    }

    &__label {
      font-size: $font-size-s;
      line-height: 14px;
      text-align: left;
      color: $color-grey-4;
      margin-bottom: 10px;
      margin-left: 10px;

      &--required {
        color: $color-error;
      }
    }
  }
</style>
