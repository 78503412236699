<script lang="ts" setup>
  import { Company } from '@/domains/models/Company';
  import PanelCompanyModalMergePreviewTitle
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalMerge/PanelCompanyModalMergePreview/PanelCompanyModalMergePreviewTitle.vue';
  import { computed, inject, Ref } from 'vue';
  import { useI18n } from '@/i18n/i18n';
  import PanelCompanyModalMergeButton
    from '@/components/Panel/PanelCompany/PanelCompanyModal/PanelCompanyModalMerge/PanelCompanyModalMergeButton.vue';
  import {
    DetailsCount,
    SelectedFields,
  } from '@/components/Panel/PanelCompany/PanelCompanyModal/types';
  import ProvideKeys from '@/components/Panel/PanelCompany/PanelCompanyModal/ProvideKeys';

  const props = defineProps<{
    company: Company & { count: DetailsCount };
    toDelete: boolean;
  }>();

  const { t, tc } = useI18n();

  const {
    selectedFields,
    setSelectedFields,
  } = inject(ProvideKeys.selectedFields) as SelectedFields;

  const { leftProfile, rightProfile } = inject(ProvideKeys.mergeProfiles);

  const elements = computed<{
    key: string,
    name: () => string,
    value: Ref<number> | number,
    status: 'import' | 'imported' | 'erase'
  }[]>(() => [
    {
      key: 'description',
      name: t('generics.description'),
      value: props.company?.description ? 1 : 0,
      status: leftProfile?.value?._id && selectedFields.value.includes(`count.description`) ? 'imported' : 'erase',
    },
    {
      key: 'processes',
      name: t('generics.process'),
      value: props.company?.count?.processes || 0,
      status: leftProfile?.value?._id && selectedFields.value.includes(`count.processes`) ? 'imported' : 'import',
    },
    {
      key: 'contacts',
      name: tc('generics.contact', 2),
      value: props.company?.count?.contacts || 0,
      status: leftProfile?.value?._id && selectedFields.value.includes(`count.contacts`) ? 'imported' : 'import',
    },
    {
      key: 'documents',
      name: t('generics.documents'),
      value: props.company?.count?.documents || 0,
      status: leftProfile?.value?._id && selectedFields.value.includes(`count.documents`) ? 'imported' : 'import',
    },
    {
      key: 'opportunities',
      name: tc('generics.opportunity', 2),
      value: props.company?.count?.opportunities || 0,
      status: leftProfile?.value?._id && selectedFields.value.includes(`count.opportunities`) ? 'imported' : 'import',
    },
    {
      key: 'notes',
      name: t('generics.notes'),
      value: props.company?.count?.notes || 0,
      status: leftProfile?.value?._id && selectedFields.value.includes(`count.notes`) ? 'imported' : 'import',
    },
    {
      key: 'lists',
      name: t('generics.lists'),
      value: props.company?.count?.lists || 0,
      status: leftProfile?.value?._id && selectedFields.value.includes(`count.lists`) ? 'imported' : 'import',
    },
  ]);
</script>

<template>
  <div class="mt-5 flex w-full flex-col gap-5">
    <PanelCompanyModalMergePreviewTitle :title="`${$t('generics.others-elements')}`"/>
    <div class="flex flex-wrap gap-y-2">
      <div
        v-for="element in elements"
        :key="element.key"
        class="flex grow basis-1/2 gap-1.5">
        <p class="text-sm capitalize">{{ element.name }}</p>
        <v-popover
          :disabled="element.value === 0 || element.key !== 'description'"
          popover-inner-class="popover-inner max-w-[400px]"
          trigger="click hover"
        >
          <div
            class="flex min-h-5 min-w-5 items-center justify-center rounded-3xl bg-blue-200 px-1 text-xs text-blue-800">
            {{ element.value }}
          </div>
          <template #popover>
            <div
              class="flex flex-col p-2.5"
              v-html="company.description">
            </div>
          </template>
        </v-popover>
        <PanelCompanyModalMergeButton
          v-if="toDelete && element.value > 0"
          :status="element.status"
          @click.native="setSelectedFields(`count.${element.key}`)"/>
        <div
          v-else-if="!toDelete && element.status === 'imported' && leftProfile.count?.[element.key] > 0"
          class="flex h-5 min-w-5 items-center justify-center rounded-full bg-green-550 px-1 text-xs text-white">
          +{{ leftProfile.count[element.key] || element.value }}
        </div>
      </div>
    </div>
  </div>
</template>
