<template>
  <div v-if="sourceIcon" class="jobboard-logo" v-tooltip.bottom="{ content: tooltipContent }">
    <img :src="sourceIcon" />
  </div>
  <JobboardLogo
    v-else-if="jobboard"
    class="jobboard-logo text-xs"
    :logo-url="jobboard.logoUrl"
    :connector-name="jobboard.connectorName"
    v-tooltip.bottom="{ content: tooltipContent }" />
  <JobboardLogo
    v-else-if="sourceName === 'career' && careerWebsiteLogoUrl"
    class="jobboard-logo text-xs"
    :logo-url="careerWebsiteLogoUrl"
    v-tooltip.bottom="{ content: tooltipContent }" />
</template>

<script setup lang="ts">
import { sourceIcons } from '@/macros/sourceIcons';
import { trialChannelsList } from '@/components/Configuration/ConfigurationJobboardsXtramile/data';
import JobboardLogo from '@/components/Jobboard/JobboardLogo.vue';
import { computed, defineProps } from 'vue';
import { useStore } from '@/store';
import { useI18n } from '@/i18n/i18n';

const props = defineProps<{
  source?: {
    name?: string;
  };
}>();

const store = useStore();
const { t } = useI18n();
const profile = computed(() => store.state.user.profile);
const careerWebsiteLogoUrl = computed(
  () => profile.value?._organization.configuration.career.logoUrl
);

const tooltipContent = computed(() =>
  sourceName.value === 'career'
    ? `Source: ${t('custom-jobs.career-website').toUpperCase()}`
    : `Source: ${sourceName.value.toUpperCase()}`
);

const sourceName = computed(() => props.source?.name ?? '');
const sourceIcon = computed(() => sourceIcons[sourceName.value]);

const jobboard = computed(() => {
  return trialChannelsList.find(
    jobboard =>
      jobboard.connectorName === props.source?.name || jobboard.title === props.source?.name
  );
});
</script>
<style lang="scss" scoped>
.jobboard-logo {
  width: 17px;
  height: 17px;
  margin-left: 6px;
}
</style>
