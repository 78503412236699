<script setup lang="ts">
import { ref } from 'vue';

import Views from '@/components/Views/Views';
import ViewsContainer from '@/components/Views/ViewsContainer';
import AdminTabs from '@/views/Tabs/AdminTabs';
import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcInput from '@/ui-kit/components/BcInput/BcInput.vue';
import { useToast } from '@/ui-kit/components/BcToast';

import { cleanCompaniesLinkedinUrl, syncXtramileJobs } from '@/api/migrations';

const isLoading = ref(false);
const organizationId = ref('');
const channelSubscriptionId = ref('');

const toast = useToast();

const startCompanyLinkedinUrlClean = async () => {
  try {
    isLoading.value = true;
    await cleanCompaniesLinkedinUrl();
    isLoading.value = false;
  } catch (err: any) {
    isLoading.value = false;
    console.log('LOGS-- startCompanyLinkedinUrlClean err', err);

    toast.show({
      type: 'error',
      message: err.message,
    });
  }
}

const startSyncXtramile = async () => {
  try {
    isLoading.value = true;

    await syncXtramileJobs({
      organizationId: organizationId.value,
      channelSubscriptionId: channelSubscriptionId.value,
    });

    isLoading.value = false;
  } catch (err: any) {
    isLoading.value = false;
    console.log('LOGS-- startSyncXtramile err', err);

    toast.show({
      type: 'error',
      message: err.message,
    });
  }
}

</script>

<template>
  <views class="admin-migrations">
    <admin-tabs></admin-tabs>
    <router-view></router-view>
    <views-container>
      <div class="card items-center justify-between p-5">
        <p>Clean DB URL Linkedin entreprise</p>
        <BcButton :isLoading="isLoading" @click="startCompanyLinkedinUrlClean">Lancer la migration</BcButton>
      </div>
      <div class="card mt-3 items-center justify-between p-5">
        <p>Sync xtramile avec site carrière</p>
        <div class="flex items-center gap-2">
          <BcInput
            v-model="organizationId"
            placeholder="organizationId"
            :required="true"
            :label-placeholder="false"
            class="!mt-0 w-full"
            name="organizationId"/>
          <BcInput
            v-model="channelSubscriptionId"
            placeholder="channelSubscriptionId"
            :label-placeholder="false"
            :required="true"
            class="!mt-0 w-full"
            name="channelSubscriptionId"/>
        </div>
        <BcButton
          :isLoading="isLoading"
          @click="startSyncXtramile">
          Lancer la migration
        </BcButton>
      </div>
    </views-container>
  </views>
</template>
