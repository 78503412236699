<script lang="ts" setup>
  import {
    Candidate,
    CandidateBasicSocialInfo,
    CandidateContactInfo,
  } from '@/domains/models/Candidate';
  import { computed, defineEmits, defineProps, ref, withDefaults } from 'vue';
  import fallbackImg from '@/assets/img/default-avatar-company.svg';
  import AssociatedCompanyAutocomplete
    from '@/components/Professions/AssociatedCompanyAutocomplete.vue';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { Company } from '@/domains/models/Company';
  import { createEmployee, editEmployee } from '@/api/employees';


  interface Props {
    candidate: Candidate;
    isCreating?: boolean;
    foundCompany?: Company;
  }

  const props = withDefaults(defineProps<Props>(), {
    isCreating: false,
  });
  const emits = defineEmits<{
    (e: 'update:is-editing', value: boolean): void,
    (e: 'update-candidate', value: Candidate): void
  }>();

  const activeEmployees = computed(() => {
    return props.candidate._employees?.filter((employee) => employee.active) || [];
  });
  const editedProfile = ref<CandidateContactInfo & CandidateBasicSocialInfo>({
    _employees: props.candidate._employees || [],
  });
  const selectedCompanies = ref(activeEmployees.value?.map(employee => employee._company) || []);
  const closeEdit = () => {
    emits('close-edit', false);
  };
  const employeesToCreate = computed(() => selectedCompanies.value.filter(company => !activeEmployees.value.some(employee => employee._company._id === company._id)));
  const employeesToRemove = computed(() => activeEmployees.value.filter(employee => !selectedCompanies.value.some(company => employee._company._id === company._id)));

  const saveCandidate = async() => {
    for (const company of employeesToCreate.value) {
      const response = await createEmployee({
        companyId: company._id,
        coderId: props.candidate._id,
      });
      const employee = response.data;
      emits('update-candidate', {
        _employees: [employee, ...props.candidate._employees?.map(e => ({
          ...e,
          active: false,
        })) || []],
      });
    }

    for (const employee of employeesToRemove.value) {
      const response = await editEmployee({
        id: employee._id,
        data: {
          active: false,
        },
      });
      emits('update-candidate', {
        _employees: [response.data, ...props.candidate._employees?.map(e => ({
          ...e,
          active: false,
        })) || []],
      });
    }
    closeEdit();
  };

  const setAlternativeImg = (event: any) => {
    event.target.src = fallbackImg;
  };
  const setCompanies = (value: Company[]) => {
    selectedCompanies.value = value;
    if (props.isCreating) {
      emits('update-candidate', { employeeCompany: value });
    }
  };
</script>

<template>
  <div class="flex w-full flex-col items-start gap-2.5 rounded-md bg-neutral-200 px-5 py-3.5">
    <AssociatedCompanyAutocomplete
      :profile="candidate"
      :selected="editedProfile._employees"
      class="w-full"
      @select-company="setCompanies"
    />
    <div
      v-if="!isCreating"
      class="ml-auto mt-2.5 flex flex-row gap-2.5">
      <BcButton
        :label="$t('global.cancel')"
        icon-left="cross"
        size="small"
        type="outlined"
        @click="closeEdit">
        {{ $t('global.cancel') }}
      </BcButton>
      <BcButton
        icon-left="check"
        native-type="submit"
        size="small"
        @click="saveCandidate">
        {{ $t('global.save') }}
      </BcButton>
    </div>
  </div>
</template>

<style scoped></style>
