export const validateName = content => {
  if (content) {
    return;
  }

  throw new Error('Le nom précisé est invalide');
};

export default {
  validateName,
};
