<script lang="ts" setup>
  import { defineEmits, defineProps } from 'vue';
  import { Company } from '@/domains/models/Company';
  import PanelCommonDescriptionView
    from '@/components/Panel/PanelCommon/PanelCommonDescription/PanelCommonDescriptionView.vue';
  import PanelCommonDescriptionEdit
    from '@/components/Panel/PanelCommon/PanelCommonDescription/PanelCommonDescriptionEdit.vue';
  import { useToast } from '@/ui-kit/components/BcToast';
  import companyApi from '@/api/company';

  interface Props {
    company: Company;
    isEditing: boolean;
  }

  const props = defineProps<Props>();
  const emits = defineEmits<{
    (e: 'update-company', value: Company): void,
    (e: 'close-edit'): void
    (e: 'save-text', value: string): void
  }>();

  const toast = useToast();

  const saveDescription = async(value) => {
    try {
      const { data } = await companyApi.updatePartner({
        id: props.company?._id,
        obj: {
          description: value,
        },
      });
      emits('update-company', { description: value });
      emits('close-edit');
    } catch (error) {
      toast.show({
        message: error.message,
        type: 'error',
      });
    }
  };
</script>

<template>
  <PanelCommonDescriptionView
    v-if="!isEditing"
    :text="company.description"
  />
  <PanelCommonDescriptionEdit
    v-else
    :text="company.description"
    :title="$t('companies.panel-company.company-description')"
    @close-edit="$emit('close-edit')"
    @save-text="saveDescription"
  />
</template>

<style scoped>

</style>
