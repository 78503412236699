import { computed, ref } from 'vue';
import { linkedinMacros } from '@/macros/linkedin';
import { 
  useLinkedinBaseForm, 
  type BaseForm, 
  type LinkedinTemplateTypeEnum 
} from './useLinkedinBaseForm';

export type LinkedinSendForm = BaseForm;

interface MessageVariable {
  _id: string;
  name: string;
  slug: string;
  source: string;
  createdAt: string;
  updatedAt: string;
  value?: string;
}

function previewText(text: string | undefined, variables: MessageVariable[]): string {
  if (!text) return '';
  
  let previewText = text;
  for (const variable of variables) {
    if (variable.value) {
      previewText = previewText.replaceAll(variable.name, variable.value);
    }
  }
  return previewText;
}

export function useLinkedinSendForm(
  initialForm?: Partial<LinkedinSendForm>,
  messageVariables: Ref<MessageVariable[]>
) {
  const form = ref<LinkedinSendForm>({
    name: '',
    type: linkedinMacros.templates.type.message,
    subject: '',
    body: '',
    attachmentsToUpload: [],
    ...initialForm
  });

  const preview = computed(() => ({
    subject: previewText(form.value.subject, messageVariables.value),
    body: previewText(form.value.body, messageVariables.value)
  }));

  const exactBodyLength = computed(() => preview.value.body.length);
  const exactSubjectLength = computed(() => preview.value.subject.length);

  const { 
    currentRules,
    fieldErrors: baseFieldErrors,
    fieldWarnings,
  } = useLinkedinBaseForm(
    form,
    exactBodyLength,
    exactSubjectLength
  );


  const fieldErrors = computed(() => ({
    ...baseFieldErrors.value,
    ...fieldWarnings.value,
  }));

  const errors = computed(() => Object.values(fieldErrors.value));

  const missingVariables = computed(() => {
    const missing: string[] = [];
    const matches = [...(form.value.subject?.match(/{{[^}]+}}/g) || []), ...(form.value.body?.match(/{{[^}]+}}/g) || [])];
    
    for (const match of matches) {
      const variableName = match;
      const variable = messageVariables.value.find(v => v.name === variableName);
      if (!variable?.value) {
        missing.push(variableName);
      }
    }
    
    return missing;
  });

  const missingUnknownVariables = computed(() => {
    const subjectMatches = preview.value.subject?.match(/{{[^}]+}}/g) || [];
    const bodyMatches = preview.value.body?.match(/{{[^}]+}}/g) || [];

    return [...subjectMatches, ...bodyMatches].filter(variable => !missingVariables.value.includes(variable));
  });

  // A message is valid if:
  // 1. It has no validation errors
  // 2. All variables have values
  // 3. There are no unknown variables
  const isValid = computed(() => 
    errors.value.length === 0 && 
    missingVariables.value.length === 0
  );

  return {
    form,
    isValid,
    fieldErrors: fieldErrors,
    errors,
    currentRules,
    preview,
    missingVariables,
    missingUnknownVariables,
  };
} 