<template>
  <div
    :class="{ 'topbar-trigger--active': isActive }"
    class="topbar-trigger is-align-items-center is-full-width border-radius-m flex-col justify-center gap-[5px]"
  >
    <div
      :class="{ 'topbar-trigger__wrapper--active': isActive }"
      class="topbar-trigger__wrapper size-[40px] flex items-center justify-center rounded-lg"
    >
      <i
        v-if="icon"
        :class="[`icon-${icon}`, iconClass ? iconClass : 'font-size-s']"
        class="topbar-trigger__icon is-flex is-align-items-center is-relative text-[18px]"
      >
        <span
          v-if="count"
          class="topbar-trigger__count"
        >
          {{ count }}
        </span>
      </i>
      <slot name="icon"></slot>
      <p
        :style="{ 'font-weight': isActive ? 500 : undefined }"
        class="topbar-trigger__text font-size-s font-weight-bold"
      >
        {{ text }}
      </p>
      <i
        v-if="isChevron"
        :class="{
          'icon-chevron-down': isActive,
          'icon-chevron-right': !isActive,
        }"
        class="topbar-trigger__chevron is-flex font-size-xxs is-align-items-center ml-auto"
      >
      </i>
    </div>
    <p class="text-xs topbar-trigger__label font-medium">
      {{ label }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'topbar-trigger',
  props: {
    text: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    isChevron: {
      type: Boolean,
      required: false,
      default: false,
    },
    textClass: {
      type: String,
    },
    textStyle: {
      type: String,
    },
    count: {
      type: Number,
    },
    iconClass: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar-trigger {
  color: $color-blue-dark-cello;

  &:hover {
    .topbar-trigger__wrapper {
      @apply bg-blue-100
    }
  }


  &__wrapper {
    padding: 10px 15px;
    transition: 0.2s;
    align-items: center;

    &--active {
      @apply bg-blue-100 text-blue-800;
      border-radius: 10px;

    }
  }

  &__count {
    position: absolute;
    top: -10px;
    left: 8px;
    font-style: normal;
    border-radius: 100%;
    background: #E6525A;
    color: white;
    font-size: 10px;
    padding: 2px;
    min-width: 15px;
  }
}
</style>