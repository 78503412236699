<template>
  <div class="panel-process-details-contact">
    <div class="panel-process-details-contact__wrapper">
      <p class="panel-process-details-contact__name">
        {{ name }}
      </p>
      <p class="panel-process-details-contact__job">
        {{ title }}
      </p>
    </div>
    <div class="panel-process-details-contact__wrapper">
      <bc-button
        :bc-background-color-opacity="0"
        :bc-text-color-opacity="0.8"
        :bc-text-color-opacity-hover="1"
        @mouseout.native="isHoverEmail = false"
        @mouseover.native="isHoverEmail = true"
        bc-box-shadow-hover="none"
        bc-icon="mail"
        bc-icon-size="24px"
        bc-padding="0"
        bc-text-color="primary"
        bc-text-color-hover="primary"
        bc-overflow="visible"
        class="panel-process-details-contact__icon"
        v-clipboard:copy="email"
        v-clipboard:success="setIsCopiedEmail"
        v-if="email"
        v-tooltip="emailTooltip">
      </bc-button>
      <bc-button
        :bc-background-color-opacity="0"
        :bc-text-color-opacity="0.8"
        :bc-text-color-opacity-hover="1"
        @mouseout.native="isHoverPhone = false"
        @mouseover.native="isHoverPhone = true"
        bc-box-shadow-hover="none"
        bc-icon="phone"
        bc-icon-size="20px"
        bc-padding="0"
        bc-text-color="primary"
        bc-text-color-hover="primary"
        bc-overflow="visible"
        class="panel-process-details-contact__icon"
        v-clipboard:copy="phone"
        v-clipboard:success="setIsCopiedPhone"
        v-if="phone"
        v-tooltip="phoneTooltip">
      </bc-button>
    </div>
  </div>
</template>

<script>
  import BcButton from '@/legacy/ui-kit/components/Button/BcButton';

  export default {
    name: 'panel-process-details-contact',
    components: { BcButton },
    props: {
      name: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      phone: {
        type: String,
        default: ''
      },
      email: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      isCopiedPhone: false,
      isCopiedEmail: false,
      isHoverEmail: false,
      isHoverPhone: false
    }),
    computed: {
      phoneTooltip() {
        return {
          content: this.isCopiedPhone ? this.$t('generics.copied') : this.phone,
          hideOnTargetClick: false,
          show: this.isHoverPhone,
          trigger: 'manual'
        };
      },
      emailTooltip() {
        return {
          content: this.isCopiedEmail ? this.$t('generics.copied') : this.email,
          hideOnTargetClick: false,
          show: this.isHoverEmail,
          trigger: 'manual'
        };
      }
    },
    methods: {
      setIsCopiedPhone() {
        this.isCopiedPhone = true;
        setTimeout(() => {
          this.isCopiedPhone = false;
        }, 2300);
      },
      setIsCopiedEmail() {
        this.isCopiedEmail = true;
        setTimeout(() => {
          this.isCopiedEmail = false;
        }, 2300);
      }
    }
  };
</script>

<style lang=scss scoped>
  .panel-process-details-contact {
    justify-content: space-between;
    flex-grow: 1;

    &__wrapper {
      align-items: center;
    }

    &__name {
      font-weight: $font-weight-bold;
      font-size: $font-size-s;
      color: $color-tertiary;
      margin-right: 10px;
      min-width: 70px;
    }

    &__icon {
      margin-right: 10px;
      color: $color-primary !important;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &__job {
      font-size: $font-size-s;
      color: $color-tertiary;
      padding: 0px 10px;
    }
  }
</style>
