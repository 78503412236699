<template>
  <div class="container">
    <configuration-templates-tabs>
      <ConfigurationTemplatesHeader
        :title="$t('email.template-creation')"
        modal-type="modal-templates-note"/>
    </configuration-templates-tabs>
    <views-container>
      <div class="configuration-templates">
        <router-view></router-view>
        <div v-if="isLoading" class="mt-5 justify-center">
          <bc-spinner></bc-spinner>
        </div>
        <div v-else-if="!items.length" class="configuration-templates__empty">
          <p>🔍 {{ $t('templates.no-templates') }}</p>
        </div>
        <div
          v-else
          class="flex w-full flex-col gap-2.5">
          <div class="configuration-templates__titles w-full">
            <p class="flex w-10/12">{{ $t('email.template-name') }}</p>
            <p class="flex w-1/12">{{ $t('generics.active') }}</p>
            <p class="flex w-1/12">{{ $t('generics.author') }}</p>
          </div>
          <div class="flex w-full flex-col gap-1">
            <ConfigurationTemplatesNotesItem
              v-for="template in items"
              :key="template._id"
              :is-disabled="template._coach._id !== profile._id"
              :template="template"
              @click="onClickTemplate"
              @toggle-activated="onToggleActivated">
            </ConfigurationTemplatesNotesItem>
          </div>
        </div>
      </div>
    </views-container>
  </div>
</template>

<script lang="ts" setup>
  import ViewsContainer from '@/components/Views/ViewsContainer.vue';
  import ConfigurationTemplatesTabs
    from '@/components/Configuration/Tabs/ConfigurationTemplatesTabs.vue';
  import ConfigurationTemplatesHeader
    from '@/components/Configuration/ConfigurationTemplates/ConfigurationTemplatesHeader.vue';
  import { useStore } from '@/store.js';
  import { useNotesTemplatesStore } from '@/store/pinia/notesTemplates';
  import { onBeforeMount, watch } from 'vue';
  import { useRoute } from 'vue-router/composables';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
  import ConfigurationTemplatesNotesItem
    from '@/components/Configuration/ConfigurationTemplates/ConfigurationTemplatesNotes/ConfigurationTemplatesNotesItem.vue';
  import { storeToRefs } from 'pinia';
  import { NotesTemplate } from '@/domains/models/NotesTemplate';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { AxiosError } from 'axios';

  const store = useStore();
  const profile = store.state.user.profile;

  const route = useRoute();
  const toast = useToast();

  const notesTemplatesStore = useNotesTemplatesStore();
  const { isLoading, items } = storeToRefs(notesTemplatesStore);

  watch(() => route.query, async(query, prevQuery) => {
    if (!prevQuery || query?.coachId !== prevQuery?.coachId || query?.all !== prevQuery?.all) {
      await notesTemplatesStore.fetchNotesTemplates({
        coachId: query.coachId,
        all: query.all,
      });
    }
  });

  const onClickTemplate = (template: NotesTemplate): void => {
    store.dispatch('app/setParams', ({
      noteTemplate: template,
      disabled: template._coach._id !== profile._id,
    }));
    store.dispatch('app/setIsPanel', true);
    store.dispatch('app/setModal', 'modal-templates-note');
  };

  const onToggleActivated = async(template: NotesTemplate): Promise<void> => {
    try {
      await notesTemplatesStore.editTemplate({
        _id: template._id,
        activated: !template.activated,
      });
    } catch (err) {
      if ((err as AxiosError).response) {
        const error = err as AxiosError;
        toast.show({
          type: 'error',
          message: error.response?.data?.message,
        });
      }
      toast.show({
        type: 'error',
        message: err,
      });
    }
  };

  onBeforeMount(async() => {
    await notesTemplatesStore.fetchNotesTemplates({ coachId: profile._id, all: null });
  });
</script>

<style lang="scss" scoped>
  .container {
    flex-direction: column;
  }

  .configuration-templates {
    width: 100%;
    flex-direction: column;
    gap: 10px;
    position: relative;

    &__titles {
      padding: 5px 30px;

      & > p {
        color: $color-blue-heavy-dark;
        font-size: 13px;
        font-weight: 400;
        text-transform: uppercase;
      }
    }

    &__items {
      position: relative;
      flex-direction: column;
      gap: 5px;

      &-loader {
        justify-content: center;
        align-items: center;
        min-height: 100px;
      }
    }

    &__block-feature {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, #f2f4fd 0%, #fafbff 100%);
      opacity: 0.5;
    }

    &__warning {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: right;
      margin-bottom: 15px;
      color: $color-blue-dark-cello;

      &-container {
        width: 100%;
        justify-content: flex-end;
        margin-bottom: 5px;
      }
    }

    &__empty {
      width: 100%;
      justify-content: center;
      margin-top: 20px;
      color: $color-tertiary;
    }
  }
</style>

<style lang="scss">
  .tooltip-custom {
    height: 60vh;

    .tooltip-inner {
      max-width: 100%;
      height: 100%;
    }
  }
</style>
