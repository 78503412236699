<template>
  <views class="processes-statistics">
    <statistics-tabs>
      <dashboard-modules-filters module-name="activity"></dashboard-modules-filters>
    </statistics-tabs>
    <views-container>
      <div class="processes-statistics__container">
        <div class="processes-statistics__clients">
          <statistics-candidates style="width: fit-content;"></statistics-candidates>
          <statistics-companies></statistics-companies>
        </div>
        <statistics-processes></statistics-processes>
        <statistics-jobboards></statistics-jobboards>
      </div>
    </views-container>
    <router-view></router-view>
  </views>
</template>

<script>
import Views from '@/components/Views/Views';
import ViewsContainer from '@/components/Views/ViewsContainer';
import DashboardModulesFilters from '@/components/Dashboard/DashboardModules/DashboardModulesFilters';
import StatisticsTabs from '@/views/Tabs/StatisticsTabs';
import StatisticsCandidates from '@/components/Statistics/StatisticsCandidates';
import StatisticsCompanies from '@/components/Statistics/StatisticsCompanies';
import StatisticsProcesses from '@/components/Statistics/StatisticsProcesses';
import StatisticsJobboards from '@/components/Statistics/StatisticsJobboards';

export default {
  name: 'processes-statistics',
  components: {
    Views,
    ViewsContainer,
    DashboardModulesFilters,
    StatisticsTabs,
    StatisticsCandidates,
    StatisticsCompanies,
    StatisticsProcesses,
    StatisticsJobboards,
    // DashboardModulesProcesses,
    // DashboardModulesActivity,
  },
};
</script>

<style lang="scss" scoped>
.processes-statistics {
  &__container {
    flex-direction: column;
    gap: 36px;
  }

  &__clients {
    gap: 20px;
  }
}
</style>
