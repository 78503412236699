<script setup lang="ts">
import { computed } from 'vue';
import MvIcon from '@/ui-kit/components/MvIcon/MvIcon.vue';

interface Props {
  value: string;
  label?: string;
  disabled?: boolean;
  showError?: boolean;
  error?: string;
  rows?: number;
  maxLength?: number;
  required?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  value: '',
  label: undefined,
  disabled: false,
  showError: false,
  error: undefined,
  rows: 15,
  maxLength: undefined,
  required: false,
});

const emit = defineEmits<{
  (e: 'input', value: string): void;
}>();

const hasError = computed(() => {
  return props.showError && !!props.error;
});

const updateValue = (event: Event) => {
  emit('input', event.target?.value || '');
};
</script>

<template>
  <div class="flex-col">
    <label v-if="label" class="font-size-xs ml-2.5 text-blue-400 mb-[5px]">
      {{ label }}
      <span v-if="required" class="text-red-500 ml-[5px]">*</span>
    </label>
    <div class="relative">
      <textarea
        :value="value"
        @input="updateValue"
        :rows="rows"
        class="w-full p-[15px] border border-blue-200 rounded-[5px] resize-none"
        :class="{ 'border-red-500': hasError }"
        :disabled="disabled"
      />
      <MvIcon
        v-if="hasError"
        name="cross"
        class="absolute size-4 right-4 top-4 text-red-500 bg-white/20 rounded-full pointer-events-none"
      />
    </div>
  </div>
</template> 