<script setup lang="ts">
import { ref, onMounted, computed, defineEmits } from "vue";
import { useStore } from '@/store';

import BcButton from '@/ui-kit/components/BcButton/BcButton';
import BcInput from '@/ui-kit/components/BcInput/BcInput';

const emits = defineEmits<{
  (e: 'confirm-action', params: { confirm: boolean; commissionCustom?: number; }): void,
  (e: 'cancel-action', params: any): void;
}>();

const store = useStore();
const commissionCustom = ref(undefined);

const appParams = computed(() => {
  return store.state.app.params;
});

onMounted(() => {
  if (appParams.value?.processCustom?.commissionCustom) {
    commissionCustom.value = appParams.value.processCustom.commissionCustom;
  }
});

const onConfirm = () => {
  emits('confirm-action', { confirm: true, commissionCustom: commissionCustom.value });
}

</script>

<template>
  <div class="modal-process-commission-custom w-full flex-col p-[50px]">
    <div class="flex-col">
      <h2 class="mb-5 text-2xl font-bold">
        {{ $t('generics.changing-amount') }}
      </h2>
      <BcInput
        class="mt-2"
        type="number"
        name="commissionCustom"
        v-model="commissionCustom"
        icon="coin-alt"
        :label="$t('generics.forecast-amount-exclude-tax')"
        :labelPlaceholder="false"
      />
    </div>
    <div class="mt-5 gap-3">
      <BcButton class="flex-1" @click="$emit('cancel-action', $event)" type="outlined">
        {{ $t('generics.cancel') }}
      </BcButton>
      <BcButton class="flex-1" color="success" :disabled="!commissionCustom" @click="onConfirm">
        {{ $t('generics.validate') }}
      </BcButton>
    </div>
  </div>
</template>
