<template>
  <div class="panel-hunt-process-footer">
    <div class="is-column">
      <div class="is-row">
        <bc-dropdown
          :bc-dropdown-border-color-opacity="0.3"
          :bc-dropup="true"
          :bc-is-multiple="true"
          :bc-is-props-value="true"
          :bc-options="coachesOptions"
          :value="selectedCoaches"
          @change="setSelectedCoaches"
          bc-dropdown-border-color="blue-dodger"
          bc-dropdown-border-radius="4px"
          bc-input-font-size="14px"
          bc-input-padding="10px 30px"
          bc-input-padding-focus="10px 40px 10px 30px"
          bc-item-font-color="blue-cello"
          bc-item-font-size="13px"
          bc-options-border-top-radius="5px"
          bc-options-max-height="300px"
          bc-placeholder="Nom du coach"
          class="panel-hunt-process-footer__dropdown"
          data-dropdown-coach>
        </bc-dropdown>
      </div>
      <processes-form-deal-alerts :form-deal-to-do="formDealToDo"></processes-form-deal-alerts>
    </div>
  </div>
</template>

<script>
  import BcDropdown from '@/legacy/ui-kit/components/Dropdown/BcDropdown';

  import { mapState } from 'vuex';
  import ProcessesFormDealAlerts from "@/components/Processes/ProcessesFormDealAlerts";

  export default {
    name: 'panel-hunt-process-footer',
    components: { ProcessesFormDealAlerts, BcDropdown },
    props: {
      formDealToDo: {
        type: Array,
        default: () => ([]),
      },
    },
    computed: {
      ...mapState({
        coaches: state => state.coaches.coaches
      }),
      coachesOptions() {
        const coaches = this.coaches.map(coach => ({
          name: coach.firstName,
          query: coach._id
        }));

        return [
          {
            name: 'Tous les membres',
            query: 'all'
          },
          ...coaches.sort((a, b) => a.name.localeCompare(b.name))
        ];
      },
      selectedCoaches() {
        const selectedCoaches = this.coaches.filter(coach => (this.$route.query['selected-coaches'] || []).some(id => id === coach._id));

        return selectedCoaches.length > 0 ? selectedCoaches.map(coach => coach.firstName) : 'Tous les membres';
      },
    },
    methods: {
      setSelectedCoaches(coach) {
        if (coach.query === 'all') {
          this.$router.push({
            query: {
              ...this.$route.query,
              'selected-coaches': undefined
            }
          });
        } else if ((this.$route.query['selected-coaches'] || []).includes(coach.query)) {
          this.$router.push({
            query: {
              ...this.$route.query,
              'selected-coaches': (this.$route.query['selected-coaches'] || []).filter(id => id !== coach.query)
            }
          });
        } else {
          this.$router.push({
            query: {
              ...this.$route.query,
              'selected-coaches': [...(this.$route.query['selected-coaches'] || []), coach.query]
            }
          });
        }
      },
    },
    destroyed() {
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          'selected-coaches': undefined
        }
      });
    }
  };
</script>

<style lang="scss" scoped>
  .panel-hunt-process-footer {
    padding: 10px 20px;
    background: $color-white;

    &__dropdown {
      max-width: 200px;
      margin-right: 20px;
    }

    &__date-picker {
      & :deep() {
        input {
          outline: none;
          box-shadow: none;
          border: 1px solid rgba($color-blue-dodger, 0.3);
          text-align: center;
          color: $color-blue-dodger;
          font-size: $font-size-s;
          padding: 5px 10px;
          transition: 0.3s;

          &:focus {
            border-color: $color-blue-dodger;
          }
        }

        .vc-grid-cell {
          display: block;
        }
      }
    }

    &__button {
      margin-left: 20px;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
    }
  }
</style>
