<template>
  <div class="panel-edit-technos">
    <div
      class="panel-edit-technos__stars"
      v-if="starsBlue.length > 0">
      <technos-star
        :key="`star-${starIndex}`"
        class="panel-edit-technos__icon"
        v-for="(star, starIndex) in starsBlue">
      </technos-star>
      <technos-star
        :key="`no-star-${starIndex}`"
        class="panel-edit-technos__icon"
        color="transparent"
        v-for="(star, starIndex) in starsTransparent">
      </technos-star>
    </div>
    <div
      :class="{'panel-edit-technos__autocomplete--focus': isFocus, 'panel-edit-technos__autocomplete--error': errorsArray.length > 0}"
      @click="setFocus"
      class="panel-edit-technos__autocomplete">
      <bc-tag
        :bc-background-color-opacity="0.25"
        :bc-cross="true"
        :key="technoIndex"
        @click.stop.native="deleteTechno(techno)"
        bc-cross-color="blue-dark-cello"
        bc-cross-margin="7.5px"
        bc-text-color="blue-dark-cello"
        class="panel-edit-technos__tag"
        v-for="(techno, technoIndex) in tags">
        {{ techno.name }}
      </bc-tag>
      <bc-dropdown
        :bc-autocomplete="true"
        :bc-dropdown-border-color-opacity="0"
        :bc-empty-input-on-click="true"
        :bc-icon-caret-displayed="false"
        :bc-input-box-shadow-active="true"
        :bc-input-focus-at-enter="true"
        :bc-options="matches"
        :bc-options-limit="5"
        @blur="isFocus = false, $emit('blur')"
        @change="setTechnos"
        @focus="isFocus = true"
        bc-input-font-size="13px"
        bc-input-padding-focus="7.5px"
        bc-item-font-size="13px"
        :bc-placeholder="$t('panel-coder-edit.enter-your-stacks')"
        bc-placeholder-font-size="13px"
        class="panel-edit-technos__dropdown"
        ref="input"
        v-model="input"
        v-on:[deleteEvent].native.delete="deleteTechno(tags[tags.length - 1])">
      </bc-dropdown>
    </div>
  </div>
</template>

<script>
  import TechnosStar from '@/components/UIKit/Technos/TechnosStar';
  import BcTag from '@/legacy/ui-kit/components/Tag/BcTag';
  import BcDropdown from '@/legacy/ui-kit/components/Dropdown/BcDropdown';
  import { mapState } from 'vuex';

  export default {
    name: 'panel-edit-technos',
    components: {
      BcDropdown,
      BcTag,
      TechnosStar
    },
    props: {
      starsBlue: {
        type: Number,
        default: 3
      },
      starsTransparent: {
        type: Number,
        default: 0
      },
      tags: {
        type: Array,
        default: () => []
      },
      totalTechnos: {
        type: Array,
        default: () => ({})
      },
      errorsArray: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      input: '',
      isFocus: false
    }),
    computed: {
      ...mapState({
        technos: state => state.tags.technos
      }),
      technosFiltered() {
        return this.technos.filter(a1 => this.totalTechnos.map(a2 => a2.name).indexOf(a1.name) < 0);
      },
      matches() {
        return this.technosFiltered.filter(techno => techno.name.toLowerCase().indexOf(this.input.toLowerCase()) === 0);
      },
      deleteEvent() {
        return this.input.length === 0 ? 'keydown' : null;
      }
    },
    methods: {
      setFocus() {
        this.$refs.input.$refs.input.focus();
        this.isFocus = true;
      },
      setTechnos(techno) {
        this.$emit('techno-added', techno);
      },
      deleteTechno(techno) {
        this.$emit('techno-deleted', techno);
      }
    }
  };
</script>

<style lang=scss scoped>
  .panel-edit-technos {
    &__stars {
      align-items: center;
      margin-bottom: 10px;
      margin-right: 15px;
      @include bp('tablet') {
        margin-right: 45px;
        margin-bottom: 0;
      }
    }

    &__icon {
      margin-right: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &__autocomplete {
      border: 1px solid rgba($color-blue-dark-cello, 0.5);
      border-radius: 5px;
      flex-wrap: wrap;
      padding: 2.5px 0;
      flex-grow: 1;
      min-height: 40px;

      &--focus {
        border-color: $color-blue-dodger;
      }

      &--error {
        border-color: $color-red-mandy;
      }
    }

    &__tag {
      padding: 7.5px;
      border-radius: 5px;
      margin: 2.5px 5px;
      font-size: $font-size-s;

      &:hover {
        cursor: pointer;
      }
    }

    &__dropdown {
      max-width: 200px;
      justify-content: center;
      margin-left: 15px;
    }
  }
</style>
