import router from '@/router';

export default () => {
    router.beforeEach((to, from, next) => {
        const token = localStorage.getItem('Token');

        if ((!token || token === '') && !to.name.includes('Auth') && !to.query.needRegister) {
            next('/auth');
        }

        next();
    });
}
